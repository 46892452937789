import { useCallback } from 'react';
import type { StateValue } from 'xstate';

import {
  useReorderLineItemMutation,
  useReorderOrderMutation,
} from '../../GraphQL/Reorder.generated';
import type { ReorderEvent } from '../../machines/Reorder.machine';
import { cameFromLocationSearch } from '../utils';

export const useExecuteReorder = () => {
  const [, reorderOrder] = useReorderOrderMutation();
  const [, reorderLineItem] = useReorderLineItemMutation();

  return useCallback(
    async (value: StateValue, context: ReorderEvent, event?: ReorderEvent) => {
      const orderId = event?.orderId ?? context.orderId ?? '';
      const lineItemId = event?.lineItemId ?? context.lineItemId ?? '';
      const restaurantId = event?.restaurantId ?? context.restaurantId ?? '';
      const deliveryOrderDetail = cameFromLocationSearch(value)
        ? event?.deliveryOrderDetail
        : event?.deliveryOrderDetail ?? context.deliveryOrderDetail;

      const operation = lineItemId ? reorderLineItem : reorderOrder;

      return operation({
        input: {
          ignoreConflicts: event?.remainOnCheckout ?? false,
          id: lineItemId ? lineItemId : orderId,
          destinationRestaurantId: restaurantId,
          deliveryOrderDetails: deliveryOrderDetail?.addressId
            ? {
                addressId: deliveryOrderDetail.addressId ?? '',
                deliveryFee: deliveryOrderDetail.deliveryFee ?? 0,
                tip: deliveryOrderDetail.tip ?? 0,
                vendorRestaurantId:
                  deliveryOrderDetail.vendorRestaurantId ?? '',
                vendor: deliveryOrderDetail.vendor ?? '',
              }
            : undefined,
        },
      });
    },
    [reorderLineItem, reorderOrder],
  );
};

import { useCallback } from 'react';
import {
  type RouteProp,
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

import {
  type LoyaltyTabStackParamList,
  type SweetpassHomeScreenProps,
} from '../../../../../navigation/AppNavigation.props';
import { useChallengeOptIn } from '../../../hooks';
import { checkIfChallengeStarted } from '../../../utils';

// ─────────────────────────────────────────────────────────────────────────────

export function useOptInChallengeOnFocus() {
  const isOptInOnFocusEnabled = useFeatureFlag(
    'cels-2138-challenge-opt-in-url-query-params-poc',
  );

  const { params = {} } =
    useRoute<RouteProp<LoyaltyTabStackParamList, 'SweetpassHome'>>();
  const { setParams } = useNavigation<SweetpassHomeScreenProps['navigation']>();

  const applyOptIn = useChallengeOptIn();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { t } = useLocalizationContext();

  const { challengeId, optIn } = params;

  const optInChallenge = useCallback(async () => {
    if (!challengeId || optIn !== 'true' || !isOptInOnFocusEnabled) return;

    setParams({ challengeId: undefined, optIn: undefined });

    const { data } = await applyOptIn({ challengeId });

    if (checkIfChallengeStarted(data)) {
      addNoticeBanner({
        palette: 'success',
        text: t('challenges.opt-in.success'),
      });

      return;
    }

    addNoticeBanner({
      palette: 'caution',
      text: t('challenges.opt-in.generic-error'),
    });
  }, [
    addNoticeBanner,
    applyOptIn,
    challengeId,
    optIn,
    setParams,
    t,
    isOptInOnFocusEnabled,
  ]);

  useFocusEffect(
    useCallback(() => {
      void optInChallenge();
    }, [optInChallenge]),
  );
}

import { useCallback } from 'react';

import { useSubmitPromoOrGiftCardCodeMutation } from './graphql/SubmitPromoCodeOrGiftCard.generated';

export const useSubmitPromoOrGiftCardCode = () => {
  const [submitCodeResponse, submitPromoOrGiftCardCodeMutation] =
    useSubmitPromoOrGiftCardCodeMutation();

  const submitCode = useCallback(
    async (code: string) => {
      const { data, error } = await submitPromoOrGiftCardCodeMutation({
        input: { code },
      });

      const typename = data?.submitPromoOrGiftCardCode?.__typename;
      const isSubmitSuccess =
        typename === 'SubmitPromoCodeSuccessV2' ||
        typename === 'SubmitGiftCardCodeSuccess';

      const isAlreadyApplied =
        data?.submitPromoOrGiftCardCode?.__typename ===
        'PromoCodeAlreadyApplied';

      return { isSubmitSuccess, data, typename, error, isAlreadyApplied };
    },
    [submitPromoOrGiftCardCodeMutation],
  );

  return { submitCodeResponse, submitCode };
};

import React, { useCallback } from 'react';
import { Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useNoticeBannersStackContext, useWebKeyListener } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { Bag } from '@order/features/ordering';
import { useLastInteractedStore } from '@order/LastInteractedStore';
import { useLocalizationContext } from '@order/Localization';

import { BagContents } from '../../components/Bag/Bag.contents';
import { useCart } from '../../hooks/useCart';
import type { BagScreenProps } from '../../navigation/AppNavigation.props';
import { useCloseBag } from './hooks';

export const BagScreenV1 = ({ navigation }: BagScreenProps) => {
  const isLoggedIn = useIsLoggedIn();
  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const lastInteractedStore = useLastInteractedStore();
  const { cart, registerCartView } = useCart();

  useFocusEffect(
    useCallback(() => {
      if (!cart?.id) return;

      registerCartView(cart.id);
    }, [registerCartView, cart?.id]),
  );

  // ––– HANDLERS ––––––––––––––––––––––––––––––––––––––––––––––––

  const { closeBag } = useCloseBag();

  // – Navigate to Checkout (on pressing 'Check out' and successfully applying rewards)

  const navigateToCheckout = React.useCallback(() => {
    if (isLoggedIn) {
      navigation.navigate('Checkout');

      return;
    }

    navigation.navigate('Auth', {
      screen: 'Login',
      params: { redirect: 'checkout' },
    });
  }, [isLoggedIn, navigation]);

  // – On press 'View Menu'

  const navigateToMenu = React.useCallback(() => {
    const { addressId, restaurantSlug } = lastInteractedStore ?? {};

    if (addressId) {
      navigation.navigate('MainTabs', {
        screen: 'MenuTab',
        params: {
          screen: 'DeliveryMenu',
          params: { addressId },
        },
      });
    } else if (restaurantSlug) {
      navigation.navigate('MainTabs', {
        screen: 'MenuTab',
        params: {
          screen: 'Menu',
          params: { restaurantSlug },
        },
      });
    } else {
      navigation.navigate('MainTabs', {
        screen: 'MenuTab',
        params: {
          screen: 'Locations',
        },
      });
    }
  }, [navigation, lastInteractedStore]);

  const dismissAndNavigateToMenu = React.useCallback(() => {
    if (Platform.OS === 'web') {
      // Workaround for a bug in the web implementation that was causing the address bar to display
      // an incorrect URL when navigating from a modal to a screen contained in a stack nested within a tab.
      // It requires us to first dismiss the modal, then navigate after a timeout,
      // otherwise the calls would be debounced.
      navigation.getParent()?.goBack();

      setTimeout(() => {
        navigateToMenu();
      });
    } else {
      navigateToMenu();
    }
  }, [navigation, navigateToMenu]);

  // – Can change location? If signed out, start sign-in and redirect to Locations

  const canChangeLocation = React.useCallback(() => {
    if (isLoggedIn) return true;

    const text = t('bag.reorder.error-logged-out');

    addNoticeBanner({ text, palette: 'neutral' });

    navigation.navigate('Auth', {
      screen: 'Login',
      params: { redirect: 'locations' },
    });

    return false;
  }, [isLoggedIn, t, addNoticeBanner, navigation]);

  useWebKeyListener('Escape', closeBag);

  // ─────────────────────────────────────────────────────────────

  return (
    <Bag.DynamicModal onClose={closeBag}>
      <BagContents
        navigateToMenu={dismissAndNavigateToMenu}
        navigateToCheckout={navigateToCheckout}
        canChangeLocation={canChangeLocation}
      />
    </Bag.DynamicModal>
  );
};

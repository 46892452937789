/* istanbul ignore file */

import type {
  LocationSearchMachineContext,
  MapBounds,
  PositionCoordinates,
} from '../../LocationSearch.types';

// ─── Actions ─────────────────────────────────────────────────────────────────

/**
 * Returns the active location based on the provided restaurant slug and context (if available)
 */
export function getActiveLocation(
  locations: LocationSearchMachineContext['locations'],
  event: SelectLocationEvent,
) {
  const { restaurantSlug: activeRestaurantSlug } = event;

  const pickupLocations = locations?.pickup ?? [];
  const outpostLocations = locations?.outpost ?? [];
  const allLocations = [...pickupLocations, ...outpostLocations];

  return allLocations.find(
    ({ restaurantSlug, addressId }) =>
      !addressId && restaurantSlug === activeRestaurantSlug,
  );
}

/**
 * Returns the active location based on the provided restaurant slug and context (if available)
 */
export function getActiveRecentLocation(
  context: LocationSearchMachineContext,
  event: SelectLocationEvent,
) {
  const { recentLocations = [], nearbyLocations } = context;
  const nearbyPickupLocations = nearbyLocations?.pickup ?? [];
  const nearbyOutpostLocations = nearbyLocations?.outpost ?? [];

  const { restaurantSlug: activeRestaurantSlug } = event;

  const allLocations = [
    ...recentLocations,
    ...nearbyPickupLocations,
    ...nearbyOutpostLocations,
  ];

  return allLocations.find(
    ({ restaurantSlug, isDelivery }) =>
      !isDelivery && restaurantSlug === activeRestaurantSlug,
  );
}

/**
 * Returns the active recent location based on the provided address ID
 */
export function getActiveRecentDeliveryLocation(
  context: LocationSearchMachineContext,
  event: SelectDeliveryLocationEvent,
) {
  const { recentLocations = [] } = context;
  const { addressId } = event;

  return recentLocations.find((location) => location.addressId === addressId);
}

/**
 * Returns the search area (map bounds) based on the current position and delta radius (in miles).
 */
export function getSearchAreaBasedOnCurrentPosition(
  props: GetSearchAreaBasedOnCurrentPositionProps,
): MapBounds {
  const { currentPositionCoordinates, radiusInMiles = 5 } = props;

  const deltaRadius = radiusInMiles * ONE_MILE_DELTA;

  const topLeftCoordinate = {
    latitude: currentPositionCoordinates.latitude + deltaRadius,
    longitude: currentPositionCoordinates.longitude - deltaRadius,
  };
  const bottomRightCoordinate = {
    latitude: currentPositionCoordinates.latitude - deltaRadius,
    longitude: currentPositionCoordinates.longitude + deltaRadius,
  };

  return {
    topLeft: topLeftCoordinate,
    bottomRight: bottomRightCoordinate,
  };
}

// ─── Constants ───────────────────────────────────────────────────────────────

// eslint-disable-next-line unicorn/numeric-separators-style
const ONE_MILE_DELTA = 0.01153;

// ─── Types ───────────────────────────────────────────────────────────────────

type GetSearchAreaBasedOnCurrentPositionProps = Readonly<{
  currentPositionCoordinates: PositionCoordinates;
  radiusInMiles?: number;
}>;

type SelectLocationEvent = Readonly<{
  restaurantSlug: string;
}>;

type SelectDeliveryLocationEvent = Readonly<{
  addressId: string | undefined;
}>;

import React, { type ComponentProps, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ScrollView, StyleSheet } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import {
  ButtonGroup,
  theme,
  useContainerSpacing,
  useResponsive,
} from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';

import type {
  GiftCardCheckoutFormFieldRules,
  GiftCardCheckoutFormReturn,
} from '../../../../../../form';
import { giftCardCheckoutAmountMessages as messages } from '../../GiftCardCheckoutAmount.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutAmountOptionPicker = (
  props: GiftCardCheckoutAmountOptionPickerProps,
) => {
  const { form, rules } = props;
  const { options: amountOptions } = rules.amount;

  const { formatMessage, formatNumber } = useIntl();
  const { match } = useResponsive();
  const { track } = useTelemetry();

  const { isSubmitting } = form.formState;

  // ─── Derived Data ────────────────────────────────────────────────────

  const amount = form.watch('amount');
  const amountVariation = form.watch('amountVariation');
  const amountOptionValue =
    amountVariation === 'custom' ? 'custom' : `${amount}`;

  const formattedAmountOptions = useMemo<ButtonGroupItems>(() => {
    const formattedOptions = amountOptions.map((optionAmount) => {
      const optionAmountInCents = optionAmount / 100;

      // NOTE: We use split instead of `trailingZeroDisplay` and
      //      `maximumFractionDigits` since they are working on Android.
      const optionAmountLabel = formatNumber(optionAmountInCents, {
        style: 'currency',
        currency: 'USD',
      }).split('.')?.[0];

      return { value: `${optionAmount}`, label: optionAmountLabel };
    });

    return [
      ...formattedOptions,
      { value: 'custom', label: formatMessage(messages.customOptionTitle) },
    ];
  }, [amountOptions, formatMessage, formatNumber]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onAmountChange = useCallback(
    async (value: string) => {
      if (value === 'custom') {
        track('gift.amount_tapped', { isCustomOptionSelected: true });

        form.setValue('amountVariation', 'custom');
        form.setValue('amount', 0);

        return;
      }

      const selectedAmount = Number(value);

      track('gift.amount_tapped', { giftCardAmount: selectedAmount });

      form.setValue('amountVariation', 'standard');
      form.setValue('amount', selectedAmount);
      await form.trigger('amount');
    },
    [form, track],
  );

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerSpacing = useContainerSpacing();

  const amountOptionsOuterContainerDynamicStyles = useStyle(
    () => ({ marginHorizontal: match([-containerSpacing, 0]) }),
    [containerSpacing, match],
  );
  const amountOptionsOuterContainerStyles = [
    styles.amountOptionsOuterContainer,
    amountOptionsOuterContainerDynamicStyles,
  ];
  const amountOptionsContainerStyles = useStyle(
    () => ({ paddingHorizontal: match([containerSpacing, 0]) }),
    [containerSpacing, match],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ScrollView
      horizontal
      style={amountOptionsOuterContainerStyles}
      contentContainerStyle={amountOptionsContainerStyles}
      showsHorizontalScrollIndicator={false}
    >
      <ButtonGroup
        value={amountOptionValue}
        gap="2"
        itemsHorizontalPadding={match(['3', '4'] as const)}
        size="large"
        items={formattedAmountOptions}
        isDisabled={isSubmitting}
        onChange={onAmountChange}
      />
    </ScrollView>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  amountOptionsOuterContainer: {
    paddingTop: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutAmountOptionPickerProps = {
  form: GiftCardCheckoutFormReturn;
  rules: GiftCardCheckoutFormFieldRules;
};

type ButtonGroupItems = ComponentProps<typeof ButtonGroup>['items'];

/* eslint-disable functional/no-loop-statements */

/**
 * NOTE: This code is adapted from `react-native-qrcode-svg`
 * https://github.com/awesomejerry/react-native-qrcode-svg
 */

export default (matrix: MatrixType, size: number) => {
  const cellSize = size / matrix.length;
  let path = '';

  for (const [i, row] of matrix.entries()) {
    let needDraw = false;

    for (const [j, column] of row.entries()) {
      if (column) {
        if (!needDraw) {
          path += `M${cellSize * j} ${cellSize / 2 + cellSize * i} `;
          needDraw = true;
        }

        if (needDraw && j === matrix.length - 1) {
          path += `L${cellSize * (j + 1)} ${cellSize / 2 + cellSize * i} `;
        }
      } else if (needDraw) {
        path += `L${cellSize * j} ${cellSize / 2 + cellSize * i} `;
        needDraw = false;
      }
    }
  }

  return {
    cellSize,
    path,
  };
};

type MatrixType = ReadonlyArray<readonly any[]>;

import { useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useBraze } from '@order/Braze';
import { useCustomer } from '@order/Customer';
import {
  useCheckoutBrazeLogPurchase,
  useOrderStatusLiveActivity,
} from '@order/features/ordering';
import {
  useCart,
  useInAppReview,
  useLocalOrderHistory,
  useOrderFeedbackNotifications,
  usePermanentNoticeBanner,
} from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { useOneSignal } from '@order/OneSignal';
import { useHasOrdersInCache } from '@order/Order';
import { usePlacedOrdersCounter } from '@order/PlacedOrdersCounter';
import { useTelemetry } from '@order/Telemetry';

import type { ModalStackParamList } from '../../../../navigation/AppNavigation.props';
import type { CheckoutPayload } from '../../Checkout.utils';
import { triggerCheckoutHapticFeedback } from '../../Checkout.utils';
import type { useCheckoutLedger } from '../useCheckoutLedger';
import { useCheckoutTelemetryEvents } from '../useCheckoutTelemetry';
import { usePostOrderSubmitCallback } from '../usePostOrderSubmitCallback';
import { useSubmitOrderMutation } from '../useSubmitOrderMutation';
import { useValidateRewardException } from '../useValidateRewardException';

export const useSubmitOrder = (params: UseSubmitOrderParams) => {
  const { cartData, ledger, payload } = params;

  const navigation =
    useNavigation<NativeStackNavigationProp<ModalStackParamList>>();

  const { addDataTag } = useOneSignal();
  const { startOrderStatusLiveActivity } = useOrderStatusLiveActivity({
    addDataTag,
  });
  const { scheduleOrderFeedbackNotification } = useOrderFeedbackNotifications();
  const { incrementPlacedOrdersCounter } = usePlacedOrdersCounter();

  const { logPurchase } = useBraze();
  const logBrazePurchase = useCheckoutBrazeLogPurchase({ logPurchase });

  // ───── Mutation ─────────────────────────────────────────────────

  const { isSubmittingOrder, rewardFailureCode, submitOrderMutation } =
    useSubmitOrderMutation();

  // ───── Reward Exception ─────────────────────────────────────────

  const validateRewardException = useValidateRewardException(rewardFailureCode);

  // ───── Cart Data ────────────────────────────────────────────────

  const { minimumDeliveryAmount, fetchCart, cart, ...telemetryCart } =
    useCartData();

  const {
    restaurant,
    deliveryOrderDetail,
    canTrackOrderStatus = false,
  } = cart ?? {};

  // ───── Telemetry ────────────────────────────────────────────────

  const { customer } = useCustomer();
  const { hasOrdersInCache } = useHasOrdersInCache();
  const telemetry = useCheckoutTelemetryEvents({
    customer,
    cart,
    ...telemetryCart,
    payload,
    hasPreviousOrders: hasOrdersInCache,
  });

  // ───── Error Handling ───────────────────────────────────────────

  const { t } = useLocalizationContext();
  const handleCheckoutError = useCheckoutErrorHandling();

  // ───── In App Review ────────────────────────────────────────────

  const requestInAppReview = useInAppReview();

  // ───── Local Order History ──────────────────────────────────────

  const { addToLocalOrderHistory } = useLocalOrderHistory();

  // ───── Placing Order Notice ─────────────────────────────────────

  const dismissPlacingOrderNotice = usePlacingOrderNotice(isSubmittingOrder);

  // ───── Handling Post-Order Requests ─────────────────────────────

  const handlePostOrderSubmit = usePostOrderSubmitCallback();

  // ───── Return Callback ──────────────────────────────────────────

  const submitOrder = useCallback(
    async ({ input, handleInvalidWantedTime }: SubmitOrderProps) => {
      const { orderId, wantedTime } = input;

      // ───── Started Submit ───────────────────────────────────────

      telemetry.checkoutStart();

      // ───── Submit Request ───────────────────────────────────────

      const { isSuccess, typename, userError } =
        await submitOrderMutation(input);

      // ───── Haptic Feedback ──────────────────────────────────────

      void triggerCheckoutHapticFeedback(isSuccess);

      // ───── Dismiss Loading Feedback ─────────────────────────────

      dismissPlacingOrderNotice();

      // ───── Checkout Failed - Reward Not Validated ───────────────
      // Custom behavior - refresh session to get updated rewards.

      if (typename === 'RewardNotValidated') {
        fetchCart();

        return;
      }

      // ───── Checkout Failed - Timeslot Unavailable ───────────────
      // Custom behavior - select the next timeslot available.

      if (typename === 'TimeslotUnavailable') {
        handleInvalidWantedTime(wantedTime);

        return;
      }

      // ───── Checkout Failed - Minimum Delivery Not Met ───────────
      // Custom behavior - include minimum delivery amount in the error message.

      if (typename === 'MinimumDeliverySubtotalNotMet') {
        const message = t('bag.delivery-minimum', {
          minimum: minimumDeliveryAmount,
        });

        handleCheckoutError(typename, message);

        return;
      }

      // ───── Checkout Failed - Other Errors ───────────────────────
      // Default behavior - show an error message in a notice banner.

      if (!isSuccess) {
        handleCheckoutError(typename, userError);

        return;
      }

      // ─── Live Activity ──────────────────────────────────────────

      const pickupOrDelivery =
        deliveryOrderDetail?.id ?? deliveryOrderDetail?.address?.id
          ? 'delivery'
          : 'pickup';
      const orderChannel = restaurant?.isOutpost ? 'outpost' : pickupOrDelivery;
      const locationName =
        deliveryOrderDetail?.address?.name ?? restaurant?.name ?? 'sweetgreen';

      startOrderStatusLiveActivity({
        orderId,
        orderChannel,
        canTrackOrderStatus,
        locationName,
        wantedTime,
      });

      // ─── Braze ──────────────────────────────────────────────────

      const restaurantId = restaurant?.id ?? '';
      const { lineItemsCount } = cartData;
      const subtotal = ledger?.subtotal ?? 0;
      const discountTotal = ledger?.discountsTotal ?? 0;
      const creditsTotal = ledger?.creditsTotal ?? 0;
      const total = telemetryCart.total - creditsTotal;

      logBrazePurchase({
        restaurantId,
        lineItemsCount,
        orderChannel,
        total,
        subtotal,
        discountTotal,
      });

      // ───── Checkout Success ─────────────────────────────────────

      handlePostOrderSubmit();
      void scheduleOrderFeedbackNotification({ orderId, wantedTime });
      incrementPlacedOrdersCounter();
      navigation.replace('OrderStatus', { orderId });
      telemetry.checkoutSuccess();
      requestInAppReview();
      addToLocalOrderHistory({ orderId });
    },
    [
      telemetry,
      submitOrderMutation,
      dismissPlacingOrderNotice,
      startOrderStatusLiveActivity,
      canTrackOrderStatus,
      restaurant,
      deliveryOrderDetail,
      cartData,
      ledger?.subtotal,
      ledger?.discountsTotal,
      ledger?.creditsTotal,
      telemetryCart,
      handlePostOrderSubmit,
      scheduleOrderFeedbackNotification,
      incrementPlacedOrdersCounter,
      navigation,
      requestInAppReview,
      addToLocalOrderHistory,
      logBrazePurchase,
      fetchCart,
      t,
      minimumDeliveryAmount,
      handleCheckoutError,
    ],
  );

  // ───── Result ───────────────────────────────────────────────────

  return { isSubmittingOrder, validateRewardException, submitOrder };
};

// ─── Hooks ───────────────────────────────────────────────────────────────────

const useCartData = () => {
  const { cart, cartOrderType, total, deliveryDetails, fetchCart } = useCart();
  const { formatPrice } = useLocalizationContext();

  const minimumDeliveryAmount = formatPrice(
    deliveryDetails?.minSubtotal ?? 0,
    'USD',
  );

  return useMemo(
    () => ({ cart, cartOrderType, total, minimumDeliveryAmount, fetchCart }),
    [cart, cartOrderType, total, minimumDeliveryAmount, fetchCart],
  );
};

const usePlacingOrderNotice = (placingOrder: boolean) => {
  const { t } = useLocalizationContext();
  const text = t('checkout.placing-order-banner');

  const { dismiss } = usePermanentNoticeBanner(
    placingOrder,
    text,
    'placing-order-notice',
  );

  return useCallback(() => {
    dismiss('placing-order-notice');
  }, [dismiss]);
};

/**
 * Checkout errors, shown to the customer and sent to Mixpanel.
 * Using the following priority:
 * 1. Localized messages based on typename.
 * 2. User error provided from the GraphQL response.
 * 3. Something went wrong, try again in a few moments...
 */
const useCheckoutErrorHandling = () => {
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { track } = useTelemetry();
  const { t } = useLocalizationContext();

  return useCallback(
    (typename: CheckoutError, userError?: string) => {
      const localizedMessage = getCheckoutErrorMessageLocalization(typename, t);
      const message = localizedMessage || userError || t('general.error');

      addNoticeBanner({ text: message, palette: 'caution' });
      track('checkout.failure', { userError: message });
    },
    [t, addNoticeBanner, track],
  );
};

/** Given a typescript constrained typename, return a localized message. */
function getCheckoutErrorMessageLocalization(
  typename: CheckoutError,
  formatMessage: ReturnType<typeof useLocalizationContext>['formatMessage'],
) {
  switch (typename) {
    case 'InvalidBillingAccount': {
      return formatMessage('checkout.billing-account.invalid');
    }

    case 'IncorrectPaymentMethod': {
      return formatMessage('checkout.incorrect-payment-method');
    }

    case 'DeclinedPaymentMethod': {
      return formatMessage('checkout.payment-method-declined');
    }

    case 'InvalidCustomerPhone': {
      return formatMessage('checkout.customer-phone.invalid');
    }

    case 'InvalidTip': {
      return formatMessage('checkout.tip.invalid');
    }

    case 'DeliveryAlreadyInFlight': {
      return formatMessage('checkout.delivery-in-flight');
    }

    case 'DeliverySpecifyAddress': {
      return formatMessage('checkout.delivery-specify-address');
    }

    default: {
      return '';
    }
  }
}

// ─── Types ───────────────────────────────────────────────────────────────────

type UseSubmitOrderParams = Readonly<{
  payload: CheckoutPayload;
  cartData: ReturnType<typeof useCart>;
  ledger: ReturnType<typeof useCheckoutLedger>['ledger'];
}>;

type SubmitOrderProps = Readonly<{
  input: Parameters<
    ReturnType<typeof useSubmitOrderMutation>['submitOrderMutation']
  >['0'];
  handleInvalidWantedTime: (wantedTime?: string) => void;
}>;

type CheckoutError = Awaited<
  ReturnType<ReturnType<typeof useSubmitOrderMutation>['submitOrderMutation']>
>['typename'];

import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';

import { Icon } from '../../../../Icon';
import { BodyText } from '../../../../Text';
import {
  type ButtonFluidIconPalette,
  paletteStyles,
} from './ButtonFluidIcon.pallets';

// ─────────────────────────────────────────────────────────────────────────────

export const ButtonFluidIcon = (props: ButtonFluidIconProps) => {
  const {
    palette = 'lime',
    iconText,
    iconTextOffset,
    disabled,
    ...restProps
  } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const iconTextContainerDynamicStyles = useStyle(
    () => ({ top: iconTextOffset?.top ?? 0, left: iconTextOffset?.left ?? 0 }),
    [iconTextOffset?.top, iconTextOffset?.left],
  );
  const iconTextContainerStyles = [
    styles.textContainer,
    iconTextContainerDynamicStyles,
  ];

  const iconColor = disabled
    ? paletteStyles.disabled.iconColor
    : paletteStyles[palette].iconColor;
  const iconTextStyles = disabled
    ? paletteStyles.disabled.iconText
    : paletteStyles[palette].iconText;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <Icon width={32} height={32} color={iconColor} {...restProps} />

      {iconText ? (
        <View style={iconTextContainerStyles}>
          <BodyText
            sizeMatch={['18']}
            style={iconTextStyles}
            maxFontSizeMultiplier={1}
          >
            {iconText}
          </BodyText>
        </View>
      ) : null}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ButtonFluidIconProps = ComponentProps<typeof Icon> & {
  iconText?: string | number;
  iconTextOffset?: { top?: number; left?: number };
  disabled?: boolean | null;

  /**
   * @default "lime"
   */
  palette?: ButtonFluidIconPalette;
};

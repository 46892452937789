import { useCallback } from 'react';
import type { NavigationProp } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';

import type {
  MainTabName,
  MainTabsParamList,
  MenuTabStackParamList,
} from '../../AppNavigation.props';
import { useActiveRouteName } from '../../hooks/useActiveRouteName';
import { getMenuStackScreensNames, getModalScreensNames } from '../../utils';

/**
 * If we are in a modal screen and we navigate to the main tabs,
 * We need first to dismiss the modal screen, then navigate to the main tabs,
 * But since react-navigation debounces our calls, only considering the last one,
 * We also need the setTimeout to ensure it actually does the intended steps,
 * Native uses useLinking.native.tsx which doesn't have all of this code,
 * So we only need to do this on web for useLinking.tsx.
 */
export const useNavigateToMainTabs = (
  passedNavigation?: ReturnType<typeof useNavigation>,
) => {
  const fallbackNavigation = useNavigation<NavigationProp<MainTabsParamList>>();
  const menuNavigation = useNavigation<NavigationProp<MenuTabStackParamList>>();
  const typedNavigation = passedNavigation as typeof fallbackNavigation;
  const navigation = typedNavigation ?? fallbackNavigation;
  const activeRouteName = useActiveRouteName();

  return useCallback(
    async (
      screen: MainTabName,
      params: MainTabsParamList[MainTabName] = defaultScreenParams,
    ): Promise<void> => {
      return new Promise((resolve) => {
        // Navigation from the Modal Stack to one of the Main Stacks must
        // first dismiss the Modal Stack and then navigate to the Main Stack.

        const isOnModalScreen = getModalScreensNames().has(activeRouteName);
        const shouldDismissTheCurrentStack =
          isOnModalScreen && navigation?.getParent;

        if (shouldDismissTheCurrentStack) {
          navigation.getParent()?.goBack();

          // We need to timeout the next navigation so it isn't debounced.
          setTimeout(() => {
            // @ts-expect-error TS(2345): Argument of type '["HomeTab" | "MenuTab" | "Loyalt... Remove this comment to see the full error message
            navigation.navigate(screen, params);
            resolve();
          });

          return;
        }

        // Navigation from within the menu stack should happen on the stack.
        // Otherwise it will result in incorrect browser url behavior.

        const menuStackScreens = getMenuStackScreensNames();
        const isInMenuStack = menuStackScreens.has(activeRouteName);
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        const isGoingToMenuStack = menuStackScreens.has(params?.screen);

        if (isInMenuStack && isGoingToMenuStack) {
          // @ts-expect-error TS(2345): Argument of type '["Home" | "Locations" | "MenuWit... Remove this comment to see the full error message
          menuNavigation.navigate(params?.screen, params?.params);
          resolve();

          return;
        }

        // @ts-expect-error TS(2345): Argument of type '["HomeTab" | "MenuTab" | "Loyalt... Remove this comment to see the full error message
        navigation.navigate(screen, params);
        resolve();
      });
    },
    [activeRouteName, navigation, menuNavigation],
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const defaultScreenParams = {
  params: undefined,
  screen: undefined,
  state: undefined,
};

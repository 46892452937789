/**
 * Calculates the horizontal scroll offset for a navigation item to ensure
 * that the active item is visible within the scrollable area.
 */
export function getNavItemScrollOffset(params: GetNavItemScrollOffsetParams) {
  const {
    activeTargetId,
    navItemsMeasurements,
    navScrollViewSize,
    navScrollOffsetX,
  } = params;

  const activeTarget = navItemsMeasurements?.[activeTargetId];

  if (!activeTarget) return;

  const activeTargetStartX = activeTarget.x;
  const activeTargetEndX = activeTarget.x + activeTarget.width;

  const navScrollViewWidth = navScrollViewSize?.width ?? 0;
  const scrollEndX = navScrollOffsetX + navScrollViewWidth;

  const isNavItemEndNotVisible = activeTargetEndX >= scrollEndX;
  const isNavItemStartNotVisible = navScrollOffsetX > activeTargetStartX;

  if (isNavItemEndNotVisible) {
    return (
      navScrollOffsetX + (activeTargetEndX - scrollEndX) + EXTRA_SCROLL_OFFSET
    );
  }

  if (isNavItemStartNotVisible) {
    return activeTarget.x - EXTRA_SCROLL_OFFSET;
  }

  // If the target nav item is already visible, we do nothing.
  return null;
}

// ─── Constants ───────────────────────────────────────────────────────────────

// NOTE: We use extra offset to make the next/previous item partially visible in order to hint users about other items.
const EXTRA_SCROLL_OFFSET = 64;

// ─── Types ───────────────────────────────────────────────────────────────────

type GetNavItemScrollOffsetParams = {
  navScrollViewSize: { width: number; height: number } | null;
  navItemsMeasurements: Record<string, TargetMeasurements> | null;
  activeTargetId: string;
  navScrollOffsetX: number;
};

type TargetMeasurements = {
  x: number;
  y: number;
  width: number;
  height: number;
};

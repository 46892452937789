import type { CombinedError } from 'urql';

import { useTrackEventEffect } from '@order/Telemetry';
import { getUrqlError } from '@order/utils';

/**
 * The sweetpass account has been successfully viewed if the loyalty profile has loaded.
 * @see: https://sweetgreen.atlassian.net/wiki/spaces/CEL/pages/4608294913/Mixpanel+Events+for+Loyalty
 */
export const useSweetpassMembershipSuccessEvent = (
  props: UseSweetpassMembershipSuccessEventProps,
) => {
  const { isLoading, hasLoyaltyProfile } = props;

  // Feature flag is enabled and the loyalty profile has loaded.
  const skip = isLoading || !hasLoyaltyProfile;

  useTrackEventEffect({
    name: 'sweetpass_account.view.success',
    skip,
  });
};

/**
 * The sweetpass account has been failed if the feature flag is disabled or the loyalty profile failed to load.
 * @see: https://sweetgreen.atlassian.net/wiki/spaces/CEL/pages/4608294913/Mixpanel+Events+for+Loyalty
 */
export const useSweetpassMembershipFailureEvent = (
  props: UseSweetpassMembershipFailureEvent,
) => {
  const { isLoading, hasLoyaltyProfile, loyaltyProfileError } = props;

  // Feature flag is disabled or the loyalty profile failed to load.
  const skip = isLoading || hasLoyaltyProfile;

  const nonGraphError = 'Missing Loyalty Profile';

  useTrackEventEffect({
    name: 'sweetpass_account.view.failure',
    skip,
    payload: {
      systemError: getUrqlError(loyaltyProfileError) ?? nonGraphError,
    },
  });
};

// ─── Types ──────────────────────────────────────────────────────────────────

type UseSweetpassMembershipSuccessEventProps = Readonly<{
  isLoading: boolean;
  hasLoyaltyProfile: boolean;
}>;

type UseSweetpassMembershipFailureEvent = Readonly<{
  isLoading: boolean;
  hasLoyaltyProfile: boolean;
  loyaltyProfileError?: CombinedError;
}>;

import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, HStack, Modal, ModalDialogue, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import {
  telemetryProductFromInput,
  useTrackEventEffect,
} from '@order/Telemetry';

import { useProductDetailsScreenContext } from '../../ProductDetailsScreen.provider';
import { checkIfBaseKind } from '../../state';

// ────────────────────────────────────────────────────────────────────────────────

export const ProductModals = () => {
  return (
    <View style={styles.wrapper}>
      <NoDressingModal />
      <TooFewBasesModal />
    </View>
  );
};

// ─── MODALS ─────────────────────────────────────────────────────────────────────

const NoDressingModal = () => {
  const { t } = useLocalizationContext();
  const {
    product,
    shouldShowNoDressingModal,
    continueCustomization,
    finishCustomization,
  } = useProductDetailsScreenContext();

  // ─── Effects ─────────────────────────────────────────────────────────

  useTrackEventEffect({
    name: 'pdp.view.modal.no-dressing',
    payload: {
      selectedProduct: product ? telemetryProductFromInput(product) : undefined,
    },
    skip: !shouldShowNoDressingModal,
  });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ModalDialogue
      testID="pdp-modals.no-dressing"
      visible={shouldShowNoDressingModal}
      size="medium"
      accessibilityLabel={t('pdp.modifications.no-dressing-modal.heading')}
      headline={t('pdp.modifications.no-dressing-modal.heading')}
      body={t('pdp.modifications.no-dressing-modal.text')}
      onRequestClose={continueCustomization}
    >
      <Modal.Footer withoutTopBorder>
        <HStack
          itemsPerRow={2}
          gap={theme.spacing['4']}
          style={styles.noDressingCtaWrapper}
        >
          <Button
            size="large"
            palette="secondary"
            onPress={continueCustomization}
            testID="pdp-modals.no-dressing.go-back"
          >
            {t('general.go-back')}
          </Button>

          <Button
            size="large"
            palette="primary"
            onPress={finishCustomization}
            testID="pdp-modals.no-dressing.continue"
          >
            {t('general.continue')}
          </Button>
        </HStack>
      </Modal.Footer>
    </ModalDialogue>
  );
};

const TooFewBasesModal = () => {
  const { t } = useLocalizationContext();
  const {
    product,
    modifications,
    shouldShowTooFewBasesModal,
    continueCustomization,
    finishCustomization,
  } = useProductDetailsScreenContext();

  const activeBaseCount = useMemo(() => {
    return modifications?.active.filter(checkIfBaseKind).length;
  }, [modifications?.active]);

  const heading = t('pdp.modifications.too-few-bases-modal.heading', {
    activeBaseCount,
  });

  // ─── Effects ─────────────────────────────────────────────────────────

  useTrackEventEffect({
    name: 'pdp.view.modal.too-few-bases',
    payload: {
      selectedProduct: product ? telemetryProductFromInput(product) : undefined,
    },
    skip: !shouldShowTooFewBasesModal,
  });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ModalDialogue
      testID="pdp-modals.too-few-bases"
      visible={shouldShowTooFewBasesModal}
      size="medium"
      accessibilityLabel={heading}
      headline={heading}
      body={t('pdp.modifications.too-few-bases-modal.text')}
      onRequestClose={continueCustomization}
    >
      <Modal.Footer withoutTopBorder>
        <HStack
          itemsPerRow={2}
          gap={theme.spacing['4']}
          style={styles.noDressingCtaWrapper}
        >
          <Button
            size="large"
            palette="secondary"
            onPress={continueCustomization}
            testID="pdp-modals.too-few-bases.go-back"
          >
            {t('general.go-back')}
          </Button>

          <Button
            size="large"
            palette="primary"
            onPress={finishCustomization}
            testID="pdp-modals.too-few-bases.continue"
          >
            {t('general.continue')}
          </Button>
        </HStack>
      </Modal.Footer>
    </ModalDialogue>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flex: 0,
  },

  noDressingCtaWrapper: {
    paddingTop: theme.spacing['2'],
  },
});

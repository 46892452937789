import React from 'react';
import type { ImageRequireSource } from 'react-native';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { Image } from '../../../Image';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanCardIllustration = (props: ScanCardIllustrationProps) => {
  const { source, accessibilityLabel } = props;

  return (
    <Image
      source={source}
      aria-label={accessibilityLabel}
      style={styles.illustration}
      contentFit="contain"
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  illustration: {
    width: 263,
    height: 160,
    marginBottom: theme.spacing['10'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanCardIllustrationProps = Readonly<{
  source: ImageRequireSource;
  accessibilityLabel: string;
}>;

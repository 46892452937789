/* istanbul ignore file */

import { defineMessages } from 'react-intl';

export const giftCardsSignedOutModalMessages = defineMessages({
  modalTitle: {
    defaultMessage: 'Sign in to continue gifting',
    description: 'Gift Cards Screen | Signed-out view | Modal title',
  },
  modalLabel: {
    defaultMessage: '"Sign-in or continue as guest" modal',
    description: 'Gift Cards Screen | Signed-out view | Modal label',
  },
  continueAsGuest: {
    defaultMessage: 'Continue as guest',
    description: 'Gift Cards Screen | Signed-out view | Continue as guest',
  },
});

import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Button, IllusEmployeeBag, Image, theme, TitleText } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { BagContentBlock } from './subcomponents/BagContentBlock';

export const BagEmpty = ({ onPressViewMenu }: BagEmptyProps) => {
  const { t } = useLocalizationContext();

  return (
    <BagContentBlock testID="bag.empty-view" style={styles.container}>
      <View style={styles.info}>
        <Image source={IllusEmployeeBag} style={styles.image} />
        <TitleText
          style={styles.titleText}
          size={6}
          accessibilityRole="header"
          // @ts-expect-error TS(2322): Type '{ children: string; style: { width: number; ... Remove this comment to see the full error message
          accessibilityLevel={1}
        >
          {t('bag.empty')}
        </TitleText>
      </View>
      <Button
        testID="bag.view-menu"
        style={styles.button}
        palette="secondary"
        onPress={onPressViewMenu}
      >
        {t('bag.view-menu')}
      </Button>
    </BagContentBlock>
  );
};

type BagEmptyProps = Readonly<{
  onPressViewMenu: () => void;
}>;

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    alignItems: Platform.OS === 'web' ? 'center' : undefined,
    justifyContent: 'center',
  },
  info: {
    marginTop: theme.spacing['10'],
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    height: 242,
    width: 242,
  },
  button: {
    width: Platform.OS === 'web' ? 120 : undefined,
    marginTop: theme.spacing['6'],
  },
  titleText: {
    width: 220,
    textAlign: 'center',
  },
});

import type { LayoutMeasures } from '../TabNav.types';

type UseMeasureLayoutArgs = Readonly<{
  children: LayoutMeasures;
  separator: number;
  containerSpacing: number;
}>;

type UseMeasureLayout = (p: UseMeasureLayoutArgs) => Readonly<{
  measures: LayoutMeasures;
}>;

const isFirst = (index: number) => index === 0;

export const useMeasureLayout: UseMeasureLayout = (props) => {
  const { children, separator, containerSpacing = 0 } = props;

  if (!children?.length || !separator) {
    return { measures: [] };
  }

  return {
    measures: children.map((item, index) =>
      isFirst(index)
        ? { ...item, x: containerSpacing }
        : {
            ...item,
            x:
              containerSpacing +
              separator * index +
              children
                .slice(0, index)
                .map(({ width }) => width)
                .reduce((a: number, v: number) => a + v, 0),
          },
    ),
  };
};

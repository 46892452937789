import { format, isValid } from 'date-fns';

import {
  TierName,
  TierStatusName,
  useSweetpassInfoForAccountDeletionQuery,
} from '@order/graphql';
import { getPreferredSubscription } from '@order/hooks';

export const useSweetpassAccountDeletionData = () => {
  const [response] = useSweetpassInfoForAccountDeletionQuery({
    requestPolicy: 'network-only',
  });

  const isFetchingSweetpassData = response?.fetching;

  // ─── Subscriptions ───────────────────────────────────────────────────

  const subscriptions = response?.data?.getSubscriptions;
  const subscription = getPreferredSubscription(subscriptions);
  const nextBillingDate = subscription?.nextBillingDate ?? '';
  const subscriptionId = subscription?.id ?? '';

  // ─── Tier ────────────────────────────────────────────────────────────

  const loyaltyProfile = response?.data?.loyaltyProfile;
  const { tier, tierStatus } = loyaltyProfile ?? {};
  const isSweetpassPlus = tier?.name === TierName.SweetpassPlus;
  const isSubscriptionFreeTrial = tierStatus?.name === TierStatusName.FreeTrial;
  const isCancelling = tierStatus?.name === TierStatusName.CancellationPending;
  const isSubscriptionPastDue = tierStatus?.name === TierStatusName.PastDue;
  const canCancelSweetpass = isSweetpassPlus && !isCancelling;

  const formattedNextBillingDate = isValid(new Date(nextBillingDate))
    ? format(new Date(nextBillingDate), 'MM/dd/yyyy')
    : '';

  return {
    isFetchingSweetpassData,
    canCancelSweetpass,
    nextBillingDate: formattedNextBillingDate,
    subscriptionId,
    isSubscriptionFreeTrial,
    isSubscriptionPastDue,
  };
};

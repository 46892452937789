import { Platform } from 'react-native';
import {
  datadog,
  extendEventWithGraphQLResourceData,
  isGraphQLResource,
  SessionReplay,
  SessionReplayPrivacy,
  shouldExcludeResourceFromPageLoadActivity,
} from '@sg/expo-datadog-client';

import { getEnvVars } from '@order/utils';

const environment = getEnvVars();

const TRACING_ORIGINS = [
  'sweetgreen.com',
  'https://order.sweetgreen.com',
  'https://order-development.sweetgreen.com',
  environment.GRAPHQL_ENDPOINT,
];

export const initDatadog = async () => {
  await datadog.init(
    {
      applicationId: environment.DD_RUM_APPLICATION_ID,
      clientToken: environment.DD_RUM_CLIENT_TOKEN,
      env: environment.DD_RUM_ENVIRONMENT,
      serviceName: `order-app-${Platform.OS}`, // RN alias of `service`
      version: environment.APP_VERSION_DATADOG, // this corresponds to APP_BUILD_VERSION which is like 1.2.3-abc654d2; TODO: explore versionSuffix to support EAS Updates
    },
    {
      rumNative: {
        firstPartyHosts: TRACING_ORIGINS,
        sessionSamplingRate: 90,
        resourceTracingSamplingRate: 90,
        telemetrySampleRate: 90,
      },
      rumWeb: {
        allowedTracingUrls: TRACING_ORIGINS,
        sessionSampleRate: 90,
        sessionReplaySampleRate: 90,
        telemetrySampleRate: 90,
        excludedActivityUrls: [shouldExcludeResourceFromPageLoadActivity],
        enableExperimentalFeatures: [
          'writable_resource_graphql',
          'feature_flags',
        ],

        // NOTE: this is only supported for web at the moment
        // This method works by mutating the event and context objects, depends on the `writable_resource_graphql`
        beforeSend(event, context) {
          if (isGraphQLResource(event, context)) extendEventWithGraphQLResourceData(event, context); // prettier-ignore

          return true; // only return false when you want to block something
        },
      },
    },
  );

  // TODO: adapt these environment variables to feature flags
  if (environment.ENABLE_NATIVE_SESSION_REPLAY) {
    // TODO: add feature flag? (we'd need to move it out of this init file)
    await SessionReplay.enable({
      replaySampleRate: environment.NATIVE_SESSION_REPLAY_SAMPLE_RATE ?? 0,
      defaultPrivacyLevel: SessionReplayPrivacy.MASK_USER_INPUT,
    });
  }
};

import { logger as LOG } from '@garnish/logger';

import { type GoogleAnalyticsEventPayload } from './google-analytics.types';

// ─────────────────────────────────────────────────────────────────────────────

export const googleAnalyticsLogger = LOG.extend('GoogleAnalytics');

// Uncomment the next line to see the related logs.
// LOG.enable('GoogleAnalytics');

// ─── Helpers ─────────────────────────────────────────────────────────────────

export function logInitialize() {
  googleAnalyticsLogger.info('initialize');
}

export function logTrackEventPayload(payload: GoogleAnalyticsEventPayload) {
  googleAnalyticsLogger.info(`track(${JSON.stringify(payload)})`);
}

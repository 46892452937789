import React, { useCallback, useMemo, useState } from 'react';
import {
  type RouteProp,
  useFocusEffect,
  useRoute,
} from '@react-navigation/native';

import { useIsLoggedIn } from '@order/AuthMachine';
import {
  LoadingAnimation,
  NotFoundView,
  RefreshErrorState,
  SweetpassSignedOut,
  TierUpgradeCelebrationModal,
} from '@order/components';
import { type SubscriptionCreditCard, TierName } from '@order/graphql';
import {
  useSweetpassCheckoutNavigation,
  useSweetpassCreditCards,
  useSweetpassTier,
} from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';

import type { ModalStackParamList } from '../../navigation/AppNavigation.props';
import { SweetpassCheckoutScreenContent } from './SweetpassCheckoutScreenContent';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders Sweetpass Checkout screen with support for single/multiple plans.
 */
export const SweetpassCheckoutScreen = () => {
  const isLoggedIn = useIsLoggedIn();

  // ─── Navigation ──────────────────────────────────────────────────────

  const {
    navigateToHomeScreen,
    navigateAfterUpgrade,
    navigateToSweetpassHomeScreen,
    redirectToLoginScreen,
    redirectToJoinScreen,
  } = useSweetpassCheckoutNavigation();

  const route = useRoute<RouteProp<ModalStackParamList, 'SweetpassCheckout'>>();

  const { planId = '' } = route.params ?? {};

  // ─── Feature FLags ───────────────────────────────────────────────────

  const isAnnualSubscriptionEnabled = useFeatureFlag(
    'CELS-1260-enable-annual-subscriptions',
  );

  const isSweetpassPlusDisabled = useFeatureFlag(
    'CELS-2877-disable-sweetpass-upgrade',
  );

  // ─── State ───────────────────────────────────────────────────────────

  const [hasCustomerBeenUpgraded, setHasCustomerBeenUpgraded] = useState(false);
  const [selectedCreditCard, selectCreditCard] =
    useState<Partial<SubscriptionCreditCard>>();

  // ─── Current Tier ────────────────────────────────────────────────────

  const currentTierData = useSweetpassTier();

  const {
    isSweetpassTierLoading,
    sweetpassTierName,
    sweetpassNextTierName,
    hasUnknownTierError,
  } = currentTierData;

  // ─── Credit Cards ────────────────────────────────────────────────────

  const { creditCards, isFetchingSweetpassCreditCards } =
    useSweetpassCreditCards();

  const creditCardsData = useMemo(
    () => ({
      selectedCreditCard,
      creditCards,
      onSelectedCreditCardChange: selectCreditCard,
    }),
    [creditCards, selectedCreditCard],
  );

  // ─── Flags ───────────────────────────────────────────────────────────

  const isNextTierSweetpassPlus =
    sweetpassNextTierName === TierName.SweetpassPlus;
  const isSweetpassPlusMember = sweetpassTierName === TierName.SweetpassPlus;
  const isFetchingBaseData = isFetchingSweetpassCreditCards;

  // ─── Existing Sweetpass Plus Subscriber ──────────────────────────────
  /**
   * Note:
   * If the customer is an existing Sweetpass Plus user, (rather than just upgraded subscriber),
   * we should redirect them because they have already been upgraded and this screen should be
   * inaccessible for them, unless they are switching the subscription.
   */

  const shouldRedirectExistingSweetpassPlusSubscriber =
    isSweetpassPlusMember &&
    !hasCustomerBeenUpgraded &&
    !isSweetpassTierLoading;

  const redirectExistingSweetpassPlusSubscriber = useCallback(() => {
    if (!shouldRedirectExistingSweetpassPlusSubscriber) {
      return;
    }

    navigateToSweetpassHomeScreen();
  }, [
    shouldRedirectExistingSweetpassPlusSubscriber,
    navigateToSweetpassHomeScreen,
  ]);

  // ─── Effects ─────────────────────────────────────────────────────────

  /**
   * Redirects existing Sweetpass Plus subscribers to the Sweetpass Home screen.
   */
  useFocusEffect(redirectExistingSweetpassPlusSubscriber);

  // ─────────────────────────────────────────────────────────────────────

  if (isSweetpassPlusDisabled && isNextTierSweetpassPlus) {
    return <NotFoundView />;
  }

  if (!isLoggedIn) {
    return (
      <SweetpassSignedOut
        onPressSignIn={redirectToLoginScreen}
        onPressJoinNow={redirectToJoinScreen}
      />
    );
  }

  // NOTE: We display a loading state for a brief second before redirecting
  //       the existing Sweetpass Plus subscriber.
  if (shouldRedirectExistingSweetpassPlusSubscriber) {
    return <LoadingAnimation />;
  }

  if (hasUnknownTierError) {
    return <RefreshErrorState />;
  }

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <SweetpassCheckoutScreenContent
        planId={planId}
        currentTierData={currentTierData}
        creditCardsData={creditCardsData}
        isFetchingBaseData={isFetchingBaseData}
        isAnnualSubscriptionEnabled={isAnnualSubscriptionEnabled}
        onUpgradeSuccess={setHasCustomerBeenUpgraded}
      />

      <TierUpgradeCelebrationModal
        testID="sweetpass-checkout-celebration-modal"
        onDismiss={navigateToHomeScreen}
        onExplore={navigateAfterUpgrade}
        visible={hasCustomerBeenUpgraded}
      />
    </>
  );
};

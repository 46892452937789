export const sweetpassMembership = {
  // ─── Membership Level ────────────────────────────────────────────────

  'account.sweetpass-membership.title': 'Sweetpass membership',
  'account.sweetpass-membership.membership-level.title':
    'Your membership level',

  // ─── Sweetpass+ ──────────────────────────────────────────────────────

  'account.sweetpass-membership.membership-level.sweetpass-plus.heading':
    'Sweetpass+ member',
  'account.sweetpass-membership.membership-level.sweetpass-plus.body':
    'Auto-renews on {nextBillingDate} (${nextBillingPeriodAmount}/mo + applicable taxes.',

  // ─── Sweetpass+ Free Trial ───────────────────────────────────────────

  'account.sweetpass-membership.membership-level.sweetpass-plus-free-trial.name':
    'Sweetpass+ free trial',

  // ─── Sweetpass+ Cancelled ────────────────────────────────────────────

  'account.sweetpass-membership.membership-level.sweetpass-plus-canceled.heading':
    'Sweetpass member',
  'account.sweetpass-membership.membership-level.sweetpass-plus-canceled.body':
    'Sweetpass+ auto-renewal canceled. Benefits valid through {paidThroughDate}.',
  'account.sweetpass-membership.membership-level.sweetpass-plus-canceled.cta.title':
    'Re-join Sweetpass+',
  'account.sweetpass-membership.membership-level.rejoin-sweetpass-plus.notice.success':
    'Welcome back! Your Sweetpass+ membership is now active.',

  // ─── Sweetpass ───────────────────────────────────────────────────────

  'account.sweetpass-membership.membership-level.sweetpass.heading':
    'Sweetpass member',
  'account.sweetpass-membership.membership-level.sweetpass.body':
    'Member since {joinDate}',
  'account.sweetpass-membership.membership-level.sweetpass.cta.title':
    'Upgrade to Sweetpass+ for ${planPrice}/{billingFrequencyUnit}',
  'account.sweetpass-membership.membership-level.sweetpass.cta-free-trial.title':
    'Try Sweetpass+',
  'account.sweetpass-membership.membership-level.sweetpass.cta-switch-subscription-monthly.title':
    'Change to Monthly',
  'account.sweetpass-membership.membership-level.sweetpass.cta-switch-subscription-annual.title':
    'Save {savingAmount} with Annual',
  'account.sweetpass-membership.membership-level.sweetpass.cta-switch-subscription.a11y':
    'Change subscription billing frequency',
  'account.sweetpass-membership.membership-level.sweetpass.cta-switch-back-subscription.title':
    'Keep {billingRecurrence} plan',
  'account.sweetpass-membership.membership-level.sweetpass.cta-switch-back-subscription.a11y':
    'Undo subscription billing frequency',
  'account.sweetpass-membership.membership-level.monthly': 'Monthly',
  'account.sweetpass-membership.membership-level.annual': 'Annual',
  'account.sweetpass-membership.membership-level.monthly-plan': 'Monthly plan',
  'account.sweetpass-membership.membership-level.annual-plan': 'Annual plan',
  'account.sweetpass-membership.membership-level.month': 'month',
  'account.sweetpass-membership.membership-level.year': 'year',
  'account.sweetpass-membership.membership-level.change-error':
    'Something went wrong changing your subscription, please try again later.',
  'account.sweetpass-membership.membership-level.undo-success':
    'Plan change to {requestedPlanRecurrence} has been canceled. Your {currentPlanRecurrence} plan has resumed.',

  // ─── Sweetpass Downgraded ────────────────────────────────────────────

  'account.sweetpass-membership.membership-level.sweetpass-downgraded.heading':
    'Sweetpass member',
  'account.sweetpass-membership.membership-level.sweetpass-downgraded.body': '',
  'account.sweetpass-membership.membership-level.sweetpass-downgraded.cta.title':
    'Re-join Sweetpass+',

  // ─── Payment Methods ─────────────────────────────────────────────────

  'account.sweetpass-membership.payment-methods.title': 'Payment methods',
  'account.sweetpass-membership.payment-methods.item.card-ending-in':
    '{cardType} ending in {lastFour}',
  'account.sweetpass-membership.payment-methods.item.accessibility-label.active':
    '{title}; Active',
  'account.sweetpass-membership.payment-methods.item.accessibility-label.inactive':
    '{title}; Inactive',
  'account.sweetpass-membership.payment-methods.item.accessibility-hint':
    'Shows this payment method details',
  'account.sweetpass-membership.payment-methods.item.tag.active': 'Active',
  'account.sweetpass-membership.payment-methods.item.notice.expired':
    'Card expired',
  'account.sweetpass-membership.payment-methods.item.notice.expiring':
    'Card will expire soon',
  'account.sweetpass-membership.payment-methods.add-item.title':
    'Add payment method',
  'account.sweetpass-membership.payment-methods.add-item.accessibility-label':
    'Add payment method',
  'account.sweetpass-membership.payment-methods.add-item.accessibility-hint':
    'Adds a new payment method',
  'account.sweetpass-membership.payment-methods.method-active-message':
    'This is your active membership payment method and cannot be removed.',
  'account.sweetpass-membership.payment-methods.free-trial-message':
    'This payment method will be charged for your Sweetpass+ membership when your trial ends. To remove this payment method, please select a different payment method for membership or cancel your trial.',
  'account.sweetpass-membership.payment-methods.set-active-cta.title':
    'Use for membership payment',
  'account.sweetpass-membership.payment-methods.remove-cta.title': 'Remove',
  'account.sweetpass-membership.payment-methods.set-active.a11y':
    'Set this currently unused payment method for subscription payments.',
  'account.sweetpass-membership.payment-methods.remove.a11y':
    'Remove this currently unused payment method from your account.',
  'account.sweetpass-membership.payment-methods.credit-card':
    '• • • •  {lastFour} (Expires {expiresAt})',

  // ─── Billing History ─────────────────────────────────────────────────

  'account.sweetpass-membership.billing-history.header': 'Billing history',
  'account.sweetpass-membership.billing-history.title':
    'Membership billing history',
  'account.sweetpass-membership.billing-history.view-item.title':
    'See full billing history',
  'account.sweetpass-membership.billing-history.view-item.accessibility-label':
    'See full billing history',
  'account.sweetpass-membership.billing-history.view-item.accessibility-hint':
    'Shows the full billing history',
  'account.sweetpass-membership.billing-history.credit-card.description':
    '{cardType} ending in {lastFour}',
  'account.sweetpass-membership.billing-history.no-transactions':
    'There have been no transactions yet.',

  // ─── Contentful Fallbacks ────────────────────────────────────────────

  'membershipData.title': 'Your membership level',
  'membershipData.header': '{membershipLevel} member',
  'membershipData.body.sweetpassPlus':
    'Next billing period starts after {nextBillingDate} for (${nextBillingPeriodAmount}/{nextBillingPeriodRecurrence})',
  'membershipData.body.sweetpassPlus.changeSubscription':
    'Your plan is scheduled to change to {changeTo} after {nextBillingDate} for (${nextBillingPeriodAmount}/{nextBillingPeriodRecurrence}) + applicable taxes.',
  'membershipData.body.sweetpassPlusCancelled':
    'Sweetpass+ auto-renewal canceled. Benefits valid through {paidThroughDate}.',
  'membershipData.body.sweetpassPlusFreeTrial':
    '{timeRemaining} days left in your trial. Starting {nextBillingDate}, you will be billed ${price}/{billingFrequencyUnit} + applicable taxes.',
  'membershipData.body.sweetpass': 'Member since {joinDate}',
  'membershipData.body.sweetpassDowngraded': '',
  'cancellationData.title': 'Cancellation',
  'cancellationData.body':
    'By clicking “Cancel {tierName}” below, your {tierName} membership will end on {nextBillingDate}. Keep your membership active to keep all-access to your delicious benefits and keep saving.',
  'cancellationData.cancellationCta': 'Cancel {tierName}',
  'cancellationData.genericSuccessMessage':
    'Your {tierName} membership will end on {nextBillingDate}.',
  'paymentMethodsData.title': 'Payment methods',
  'billingHistoryData.title': 'Membership billing history',

  // ─── Sweetpass Membership Errors ─────────────────────────────────────

  'sweetpass-membership.membership.error':
    'Something went wrong loading your membership, try again in a few moments...',
  'sweetpass-membership.membership-level.rejoin.error':
    'Your {tierName} membership could not be reactivated.',
  'sweetpass-membership.cancellation.error':
    'Your {tierName} membership could not be canceled.',
  'sweetpass-membership.payment-methods.update.error':
    'Cannot update active payment method.',
  'sweetpass-membership.payment-methods.remove.error':
    'Cannot remove active payment method.',
};

/* eslint-disable functional/immutable-data */

import React, {
  type ComponentProps,
  forwardRef,
  type MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';

import type { HostedFrameProps, HostedFrameRef } from './HostedFrame.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Wrapper around `iframe` to connect with React in a similar way to WebView.
 */
export const HostedFrame = forwardRef<HostedFrameRef, HostedFrameProps>(
  (props, ref) => {
    const {
      frameId,
      frameHeight,
      source,
      style,
      containerStyle,
      title,
      isLoading,
      scrolling,
      onMessage,
    } = props;

    // ─── Derived Data ────────────────────────────────────────────────────

    // @ts-expect-error TS(2339): Property 'uri' does not exist on type 'WebViewSour... Remove this comment to see the full error message
    const sourceURI = source?.uri ?? 'about:blank';
    // @ts-expect-error TS(2339): Property 'html' does not exist on type 'WebViewSou... Remove this comment to see the full error message
    const sourceHTML = source?.html;
    const shouldUseSourceURI = sourceURI !== undefined;

    // ─────────────────────────────────────────────────────────────────────

    const frameRef = useRef<FrameType>(null);

    // ─── Helpers ─────────────────────────────────────────────────────────

    const postMessage = useCallback((message: string) => {
      const contentWindow = frameRef.current?.contentWindow;

      if (!contentWindow) return;

      contentWindow.postMessage(message, '*');
    }, []);

    // ─── Effects ─────────────────────────────────────────────────────────

    useMessageListener(frameRef, onMessage);

    /**
     * Assign internal controls to the provided ref for more controls
     * over the hosted frame.
     */
    useEffect(() => {
      const externalRef = ref as MutableRefObject<HostedFrameRef> | undefined;

      if (!externalRef) return;

      externalRef.current = {
        frame: frameRef.current,
        postMessage,
      };
    }, [postMessage, ref]);

    // ─── Styles ──────────────────────────────────────────────────────────

    const frameDynamicStyles = useStyle(
      () => ({ height: frameHeight }),
      [frameHeight],
    );

    const frameStyles = {
      ...IFRAME_BASE_STYLES,
      ...(isLoading
        ? IFRAME_LOADING_STYLES
        : {
            ...(style as IframeProps['style']),
            ...frameDynamicStyles,
          }),
    };

    // ─────────────────────────────────────────────────────────────────────

    return (
      <View testID={frameId} style={containerStyle}>
        {/* eslint-disable-next-line react/iframe-missing-sandbox */}
        <iframe
          ref={frameRef}
          id={frameId}
          style={frameStyles}
          title={title}
          allow="payment"
          scrolling={scrolling}
          sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
          srcDoc={shouldUseSourceURI ? sourceHTML : undefined}
          src={sourceURI}
        />
      </View>
    );
  },
);

// ─── Hooks ───────────────────────────────────────────────────────────────────

export const useMessageListener = (
  frameRef: React.RefObject<FrameType>,
  onMessage?: HostedFrameProps['onMessage'],
) => {
  const processMessage = useCallback(
    (message: MessageEvent) => {
      if (!frameRef.current || !onMessage) return;

      const isMessageComingFromTheTargetedIframe =
        message.source === frameRef.current.contentWindow;
      const isMessageComingFromHostedFrame =
        message.data.includes?.('fromHostedFrame');

      const shouldIgnoreMessage =
        !isMessageComingFromTheTargetedIframe ||
        !isMessageComingFromHostedFrame;

      if (shouldIgnoreMessage) return;

      onMessage({ nativeEvent: { data: message.data } });
    },
    [frameRef, onMessage],
  );

  // ─── Listener ────────────────────────────────────────────────────────

  useEffect(() => {
    window.addEventListener('message', processMessage, false);

    return () => {
      window.removeEventListener('message', processMessage, false);
    };
  }, [processMessage]);
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const IFRAME_BASE_STYLES = { border: 0 };
const IFRAME_LOADING_STYLES = { opacity: 0, pointerEvents: 'none' } as const;

// ─── Types ───────────────────────────────────────────────────────────────────

type FrameType = HTMLIFrameElement;

type IframeProps = ComponentProps<'iframe'>;

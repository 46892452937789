import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { Button, theme } from '@sg/garnish';
import { type Ingredient } from '@sg/graphql-schema';

import { checkMissingBaseIngredientsState } from './helpers';
import { orderAgainConflictReviewCTAsMessages as messages } from './OrderAgainConflictReviewCTAs.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainConflictReviewCTAs = (
  props: OrderAgainConflictReviewCTAsProps,
) => {
  const {
    productIngredients,
    unavailableIngredients,
    isReordering,
    customize,
    addToBag,
  } = props;

  // ─────────────────────────────────────────────────────────────────────

  const missingBaseIngredientsState = checkMissingBaseIngredientsState({
    productIngredients,
    unavailableIngredients,
  });
  const isMissingAllBases =
    missingBaseIngredientsState === 'all-bases-unavailable';

  // ─────────────────────────────────────────────────────────────────────

  if (isMissingAllBases) {
    return (
      <View style={styles.container}>
        <Button onPress={customize} size="large" palette="secondary">
          <FormattedMessage {...messages.chooseNewBase} />
        </Button>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Button
        onPress={addToBag}
        size="large"
        palette="primary"
        isLoading={isReordering}
      >
        <FormattedMessage {...messages.addToBagAnyway} />
      </Button>

      <Button
        onPress={customize}
        size="large"
        palette="secondary"
        disabled={isReordering}
      >
        <FormattedMessage {...messages.customize} />
      </Button>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    rowGap: theme.spacing['2'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderAgainConflictReviewCTAsProps = {
  productIngredients: readonly UnavailableIngredient[] | undefined;
  unavailableIngredients: readonly UnavailableIngredient[] | undefined;
  isReordering: boolean;
  customize: () => void;
  addToBag: () => void;
};

type UnavailableIngredient = Pick<Ingredient, 'id' | 'name' | 'kind'>;

import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { DisplayText } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuDietaryPreferencesHeading = () => {
  return (
    <DisplayText sizeMatch={['32']}>
      <FormattedMessage {...messages.heading} />
    </DisplayText>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  heading: {
    defaultMessage: 'Dietary Preferences',
    description: 'Menu | Dietary preferences | Heading',
  },
});

export const FIELD_HEIGHT = 34;
export const FLOATING_LABEL_OFFSET = 12;

export const CLEAR_BTN_LABEL = 'Clear';
export const CLEAR_BTN_A11Y_HINT = 'Clears entered text';

export const FIELD_OPTION_BTN_LABEL = 'Field option';
export const FIELD_OPTION_BTN_A11Y_HINT = 'Triggers the field option';

export const SUBMIT_BTN_A11Y_LABEL = 'Submit';
export const SUBMIT_A11Y_HINT = 'Submits entered text';
export const SUBMIT_BUTTON_SIZE = 24;

export const SHOW_PASSWORD_LABEL = 'Show';
export const HIDE_PASSWORD_LABEL = 'Hide';

export const LABEL_IDLE_SCALE = 0.75;
export const LABEL_BOTTOM_OFFSET = 8;
export const LABEL_ANIMATION_CONFIG = { duration: 200 };

/* eslint-disable functional/immutable-data */

import React, {
  type ContextType,
  type PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { type Client, Provider } from 'urql';

import { createClient } from '../client';
import { offlineStorage } from '../storage';
import type { UrqlErrorHandler } from './UrqlContext';
import { UrqlContext } from './UrqlContext';

// ─────────────────────────────────────────────────────────────────────────────

export const UrqlProvider = (props: PropsWithChildren) => {
  //
  // ─── Refs ────────────────────────────────────────────────────────────

  const errorHandlerReference = useRef<UrqlErrorHandler | null>(null);
  const logOutHandlerReference = useRef<LogOutHandler>(null);

  // ─── State ───────────────────────────────────────────────────────────

  const [client, setClient] = useState<Client>(() =>
    createClient({ errorHandlerReference, logOutHandlerReference }),
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  /**
   * Resets/recreates the URQL client.
   *
   * Can be used to invalidate local state and cache.
   */
  const resetClient = useCallback(async () => {
    await offlineStorage?.clear();
    setClient(createClient({ errorHandlerReference, logOutHandlerReference }));
  }, []);

  const registerUrqlErrorTracker = useCallback(
    (trackUrqlError: UrqlErrorHandler) => {
      errorHandlerReference.current = trackUrqlError;
    },
    [],
  );

  const registerUrqlLogOutHandler = useCallback(
    (logOutHandler: NonNullable<LogOutHandler>) => {
      logOutHandlerReference.current = logOutHandler;
    },
    [],
  );

  // ─────────────────────────────────────────────────────────────────────

  const value = useMemo<UrqlContextValue>(
    () => ({
      registerUrqlErrorTracker,
      registerUrqlLogOutHandler,
      resetClient,
    }),
    [registerUrqlErrorTracker, registerUrqlLogOutHandler, resetClient],
  );

  return (
    <UrqlContext.Provider value={value}>
      <Provider value={client}>{props.children}</Provider>
    </UrqlContext.Provider>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type LogOutHandler = (() => Promise<void>) | null;

type UrqlContextValue = ContextType<typeof UrqlContext>;

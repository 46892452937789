import type { LegacyRef } from 'react';
import React, { forwardRef } from 'react';
import { StyleSheet, View } from 'react-native';
import { Link } from '@react-navigation/native';
import { A } from '@expo/html-elements';

import {
  checkIfInternalUrl,
  formatUniversalURL,
  getAppAssociatedDomains,
} from '../../utils';
import type { PressableLinkProps } from './PressableLink.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders an anchor element (`a`) that handles universal
 * (internal and external) URLs.
 *
 * @see {@link https://sweetgreen.atlassian.net/wiki/spaces/TD/pages/4764663963/How+to+Handling+universal+links}
 * @see {@link https://reactnavigation.org/docs/link/}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a}
 */
export const PressableLink = forwardRef<View, PressableLinkProps>(
  (props, ref) => {
    const { to, children, style, onPress, ...restProps } = props;

    const formattedURL = formatUniversalURL(to, APP_ASSOCIATED_DOMAINS);
    const isInternalURL = checkIfInternalUrl(formattedURL);

    // ─────────────────────────────────────────────────────────────────────

    // In the case of an internal URL, the `Link` component will be rendered
    // to handle in-app navigation without reloading the app or creating
    // a new tab on a web platform.

    if (isInternalURL) {
      return (
        // NOTE: We use an additional `View` component, since `Link` component
        //       doesn't support passing `ref`s.

        <View ref={ref}>
          <Link
            to={formattedURL}
            style={style}
            accessibilityRole="link"
            onPress={onPress}
            {...restProps}
          >
            {children}
          </Link>
        </View>
      );
    }

    // For external URL, we use an anchor element (`a`) to open it in a new
    // tab with assigning the relevant `rel` attributes to improve sandboxing.

    return (
      <A
        ref={ref as LegacyRef<typeof View>}
        href={formattedURL}
        style={[styles.anchorDefaultStyles, style]}
        onPress={onPress}
        target={EXTERNAL_LINK_ATTRS.hrefAttrs.target}
        rel={EXTERNAL_LINK_ATTRS.hrefAttrs.rel}
        {...EXTERNAL_LINK_ATTRS}
        {...restProps}
      >
        {children}
      </A>
    );
  },
);

// ─── Constants ───────────────────────────────────────────────────────────────

const APP_ASSOCIATED_DOMAINS = getAppAssociatedDomains();

const EXTERNAL_LINK_ATTRS = {
  hrefAttrs: { target: '_blank', rel: 'noopener noreferrer' },
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  // To match the styles of other components, we must add `default` flex styles
  // to the web-only `A` component.
  anchorDefaultStyles: {
    display: 'flex',
    flexDirection: 'column',
  },
});

import type { ComponentProps } from 'react';
import React from 'react';
import type { ViewStyle } from 'react-native';
import { Platform, StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../hooks';
import type { IconName } from '../Icon';
import { Icon, IconLink } from '../Icon';
import { WithTooltip } from '../WithTooltip';

export const ShareIcon = ({
  shareTooltipLabel = 'Copied link',
  shareBtnLabel = 'Share',
  iconName = 'IconShare2',
  placement,
  style,
  onShare,
}: ShareIconProps) => {
  const iconLinkRef = React.useRef(null);
  const { isHovered, isFocused } = usePressableState(iconLinkRef);

  const iconProps = {
    width: 24,
    height: 24,
    name: iconName,
    color: theme.colors.BLACK,
  } as const;

  const iconLinkStyle = [
    styles.shareIconLink,
    (isHovered || isFocused) && styles.shareIconLinkHover,
  ];

  if (!onShare) return null;

  if (Platform.OS === 'web') {
    return (
      <WithTooltip
        testID="product-share-web"
        text={shareTooltipLabel}
        toggleAccessibilityLabel={shareBtnLabel}
        onShow={onShare}
        autoDismissTimeout={1000}
        placement={placement}
      >
        <View ref={iconLinkRef} style={[iconLinkStyle, style]}>
          <Icon {...iconProps} />
        </View>
      </WithTooltip>
    );
  }

  return (
    <IconLink
      {...iconProps}
      style={style}
      testID="product-share"
      onPress={onShare}
    />
  );
};

type ShareIconProps = Readonly<{
  shareTooltipLabel?: string;
  shareBtnLabel?: string;
  iconName?: IconName;
  style?: ViewStyle;
  placement?: ComponentProps<typeof WithTooltip>['placement'];
  onShare?: () => void;
}>;

const styles = StyleSheet.create({
  shareIconLink: {
    borderRadius: theme.radius.xxxlarge,
  },
  shareIconLinkHover: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
});

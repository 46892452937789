import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { openUrl } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { ShareOption } from './ShareOption';
import type { ShareOptionProps } from './types';

export const WhatsappShareOption = ({ shareLink }: ShareOptionProps) => {
  const { t } = useLocalizationContext();

  const handleShare = useCallback(async () => {
    const shareUrl = `https://wa.me?text=${encodeURI(
      t('account.refer-friend.share.content', { shareLink }),
    )}`;

    await openUrl(shareUrl, t('general.error'));
  }, [t, shareLink]);

  return (
    <ShareOption
      testID="account.refer-friend.share.whatsapp"
      hint={t('account.refer-friend.share.whatsapp')}
      iconName="IconWhatsapp"
      iconSize={40}
      bgColor="#23D366"
      style={styles.whatsappIconPadding}
      handleShare={handleShare}
    />
  );
};

const styles = StyleSheet.create({
  whatsappIconPadding: {
    paddingTop: 2,
    paddingRight: 2,
  },
});

import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import type { RequestPolicy } from 'urql';

import { useIsLoggedIn } from '@order/AuthMachine';

import { useDeliveryInFlightQuery } from './DeliveryInFlight.generated';

export const useDeliveryOrderInFlight = (
  props?: UseDeliveryOrderInFlightProps,
) => {
  const { pause, requestPolicy = 'cache-and-network' } = props ?? {};
  const isLoggedIn = useIsLoggedIn();

  // ─── Delivery Order In Flight Query ─────────────────────────────
  const [response] = useDeliveryInFlightQuery({
    variables: { input: { page: 1, take: 3 } },
    requestPolicy,
    pause: Boolean(pause) || !isLoggedIn,
  });

  // ─── Delivery Order In Flight Id ────────────────────────────────
  const ordersResponseData = response.data?.orders;
  const isSuccess = ordersResponseData?.__typename === 'OrdersResponseSuccess';
  const orders = isSuccess ? ordersResponseData.orders : [];
  const inFlightDeliveryOrderId = orders.find(
    ({ isInFlightDelivery }) => isInFlightDelivery,
  )?.id;

  // ─── Navigate To Delivery Order In Flight Screen ────────────────
  const navigation = useNavigation();
  const navigateToDeliveryOrderInFlightScreen = useCallback(() => {
    if (!inFlightDeliveryOrderId) return;

    navigation.navigate('Modal', {
      screen: 'DeliveryOrderInFlight',
      params: { orderId: inFlightDeliveryOrderId },
    });
  }, [inFlightDeliveryOrderId, navigation]);

  return { inFlightDeliveryOrderId, navigateToDeliveryOrderInFlightScreen };
};

type UseDeliveryOrderInFlightProps = Readonly<{
  requestPolicy?: RequestPolicy;
  pause?: boolean;
}>;

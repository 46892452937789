import type { ComponentProps } from 'react';
import React from 'react';

import { useResponsive } from '../../hooks';
import { ScrollViewWithHeaderTitleSM } from './ScrollViewWithHeaderTitle.sm';
import { ScrollViewWithHeaderTitleXS } from './ScrollViewWithHeaderTitle.xs';

// ────────────────────────────────────────────────────────────────────────────────

export const ScrollViewWithHeaderTitle = React.forwardRef(
  (props: ScrollViewWithHeaderTitleProps, ref) => {
    const { minWidth } = useResponsive();

    if (minWidth.isSM) {
      // @ts-expect-error TS(2769): No overload matches this call.
      return <ScrollViewWithHeaderTitleSM ref={ref} {...props} />;
    }

    // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
    return <ScrollViewWithHeaderTitleXS ref={ref} {...props} />;
  },
);

// ────────────────────────────────────────────────────────────────────────────────

type ScrollViewWithHeaderTitleProps = ComponentProps<
  typeof ScrollViewWithHeaderTitleXS
> &
  ComponentProps<typeof ScrollViewWithHeaderTitleSM>;

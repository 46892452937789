import React from 'react';
import { StyleSheet, type TextStyle, View, type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';
import { useResponsive } from '@sg/garnish';

import { LoyaltyHomeProgressBarReward } from './LoyaltyHomeProgressBarReward';

export const LoyaltyHomeProgressBarMilestone = (
  props: LoyaltyHomeProgressBarMilestoneProps,
) => {
  const { availablePoints, pointsReward, palette } = props;

  const points = pointsReward?.points ?? 0;
  const hasReachedMilestone =
    availablePoints > 0 && availablePoints >= (points ?? 0);

  const paletteStyle = milestonePalette[palette];
  const reachedStyle = hasReachedMilestone
    ? milestoneReachedPalette[palette]
    : null;

  const { minWidth } = useResponsive();

  return (
    <View style={styles.container}>
      <View style={[styles.milestone, paletteStyle, reachedStyle]} />

      {minWidth.isSM ? (
        <LoyaltyHomeProgressBarReward
          points={pointsReward?.points ?? 0}
          assetUrl={pointsReward?.assetUrl}
          displayName={pointsReward?.displayName}
          name={pointsReward?.name}
          textStyle={textPalette[palette]}
        />
      ) : null}
    </View>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    gap: theme.spacing['6'],
    marginTop: -theme.spacing['6'],
  },
  milestone: {
    width: 10,
    height: 10,
    borderRadius: 10,
  },
  darkMilestone: {
    backgroundColor: theme.colors.SPINACH,
  },
  lightMilestone: {
    backgroundColor: theme.colors.OPACITY.KALE.LIGHT,
  },
  darkMilestoneReached: {
    backgroundColor: theme.colors.KALE,
  },
  lightMilestoneReached: {
    backgroundColor: theme.colors.WHITE,
  },
  darkText: {
    color: theme.colors.WHITE,
  },
  lightText: {
    color: theme.colors.TEXT_COLOR,
  },
});

// ─── Palettes ───────────────────────────────────────────────────────────────

const milestonePalette: Record<
  LoyaltyHomeProgressBarMilestoneProps['palette'],
  ViewStyle
> = {
  dark: styles.darkMilestone,
  light: styles.lightMilestone,
};

const milestoneReachedPalette: Record<
  LoyaltyHomeProgressBarMilestoneProps['palette'],
  ViewStyle
> = {
  dark: styles.darkMilestoneReached,
  light: styles.lightMilestoneReached,
};

const textPalette: Record<
  LoyaltyHomeProgressBarMilestoneProps['palette'],
  TextStyle
> = {
  dark: styles.darkText,
  light: styles.lightText,
};

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyHomeProgressBarMilestoneProps = {
  availablePoints: number;
  pointsReward?: PointsReward;
  palette: 'dark' | 'light';
};

type PointsReward = {
  name: string;
  points?: number | null;
  assetUrl?: string | null;
  displayName?: string | null;
};

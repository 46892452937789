import React, { useCallback, useMemo, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { noop } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import {
  RefreshErrorState,
  SweetpassCheckoutMultiplePlans,
  SweetpassSignedOut,
} from '@order/components';
import { type SubscriptionCreditCard, TierName } from '@order/graphql';
import {
  useSweetpassCheckoutNavigation,
  useSweetpassCreditCards,
  useSweetpassNextBillingDate,
  useSweetpassTier,
} from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';

// ─────────────────────────────────────────────────────────────────────────────

export const SweetpassSwitchSubscriptionScreen = () => {
  const isLoggedIn = useIsLoggedIn();

  // ─── Navigation ──────────────────────────────────────────────────────

  const {
    navigateToSweetpassHomeScreen,
    redirectToLoginScreen,
    redirectToJoinScreen,
  } = useSweetpassCheckoutNavigation();

  // ─── Feature FLags ───────────────────────────────────────────────────

  const isAnnualSubscriptionEnabled = useFeatureFlag(
    'CELS-1260-enable-annual-subscriptions',
  );

  // ─── State ───────────────────────────────────────────────────────────

  const [selectedCreditCard, selectCreditCard] =
    useState<Partial<SubscriptionCreditCard>>();

  // ─── Current Tier ────────────────────────────────────────────────────

  const currentTierData = useSweetpassTier();

  const { isSweetpassTierLoading, sweetpassTierName, hasUnknownTierError } =
    currentTierData;

  // ─── Credit Cards ────────────────────────────────────────────────────

  const { creditCards, isFetchingSweetpassCreditCards } =
    useSweetpassCreditCards();

  const creditCardsData = useMemo(
    () => ({
      selectedCreditCard,
      creditCards,
      onSelectedCreditCardChange: selectCreditCard,
    }),
    [creditCards, selectedCreditCard],
  );

  // ─── Next Billing Date ───────────────────────────────────────────────

  const { nextBillingDate, isFetchingNextBillingDate } =
    useSweetpassNextBillingDate(!isLoggedIn || !isAnnualSubscriptionEnabled);

  // ─── Flags ───────────────────────────────────────────────────────────

  const isSweetpassPlusMember = sweetpassTierName === TierName.SweetpassPlus;
  const isFetchingBaseData =
    isFetchingSweetpassCreditCards || isFetchingNextBillingDate;

  const redirectNonSweetpassPlusMember = useCallback(() => {
    if (!isSweetpassPlusMember && !isSweetpassTierLoading) {
      navigateToSweetpassHomeScreen();
    }
  }, [
    isSweetpassPlusMember,
    isSweetpassTierLoading,
    navigateToSweetpassHomeScreen,
  ]);

  // ─── Effects ─────────────────────────────────────────────────────────

  /**
   * Redirects non Sweetpass Plus subscribers to the Sweetpass Home screen.
   */
  useFocusEffect(redirectNonSweetpassPlusMember);

  // ─────────────────────────────────────────────────────────────────────

  if (!isLoggedIn) {
    return (
      <SweetpassSignedOut
        onPressSignIn={redirectToLoginScreen}
        onPressJoinNow={redirectToJoinScreen}
      />
    );
  }

  if (hasUnknownTierError) {
    return <RefreshErrorState />;
  }

  // ─────────────────────────────────────────────────────────────────────

  return (
    <SweetpassCheckoutMultiplePlans
      isSwitchingPlans
      currentTierData={currentTierData}
      creditCardsData={creditCardsData}
      nextBillingDate={nextBillingDate}
      isFetchingBaseData={isFetchingBaseData}
      onUpgradeSuccess={noop}
    />
  );
};

import { type RefObject, useEffect } from 'react';
import { type HostedFrameRef } from '@sg/garnish';

/**
 * Keeps the amount to be used in the Stripe payment request in sync.
 */
export const useUpdatePaymentAmount = (
  stripeReference: RefObject<HostedFrameRef>,
  amount: number,
  isShowingExpressPayments: boolean,
) => {
  useEffect(() => {
    if (!isShowingExpressPayments) return;

    stripeReference?.current?.postMessage?.(
      JSON.stringify({ type: 'UPDATE_PAYMENT_AMOUNT', amount }),
    );
  }, [amount, isShowingExpressPayments, stripeReference]);
};

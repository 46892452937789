import { useCallback, useEffect, useState } from 'react';
import { AccessibilityInfo } from 'react-native';

export const useReduceMotionStatus = () => {
  const [reduceMotionStatus, setReduceMotionStatus] =
    useState<ReduceMotionStatus>('undetermined');

  const toggleSetting = useCallback((reduceMotionEnabled: boolean) => {
    setReduceMotionStatus(reduceMotionEnabled ? 'enabled' : 'disabled');
  }, []);

  useEffect(() => {
    void AccessibilityInfo.isReduceMotionEnabled()?.then(toggleSetting);

    const listener = AccessibilityInfo.addEventListener(
      'reduceMotionChanged',
      toggleSetting,
    );

    return () => {
      listener?.remove?.();
    };
  }, [toggleSetting]);

  return reduceMotionStatus;
};

type ReduceMotionStatus = 'undetermined' | 'enabled' | 'disabled';

import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  CollapseIndicatorIcon,
  Collapsible,
  formatPhoneNumber,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type { RestaurantQuery } from '../../../GraphQL/Restaurant.query.generated';
import { formatAddress, formatWorkingHours } from '../MenuHero.utils';

// ────────────────────────────────────────────────────────────────────────────────

export const MenuHeroInfo = (props: MenuHeroRestaurantInfoProps) => {
  const { minWidth } = useResponsive();
  const [infoExpanded, setInfoExpanded] = useState(false);

  // large screens (or delivery) display restaurant information as a static element
  // and smaller screens display it as a collapsible element.

  if (minWidth.isMD || props.deliveryETA) {
    return (
      <MenuHeroRestaurantInfoContent
        restaurant={props.restaurant}
        deliveryAddress={props.deliveryAddress}
        deliveryETA={props.deliveryETA}
      />
    );
  }

  return (
    <View style={styles.collapsibleWrapper}>
      <Collapsible options={{ hideIcon: true }} onChange={setInfoExpanded}>
        <Collapsible.Summary>
          <MenuHeroRestaurantInfoToggle expanded={infoExpanded} />
        </Collapsible.Summary>

        <Collapsible.Details>
          <MenuHeroRestaurantInfoContent
            restaurant={props.restaurant}
            deliveryETA={props.deliveryETA}
          />
        </Collapsible.Details>
      </Collapsible>
    </View>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const MenuHeroRestaurantInfoContent = (props: MenuHeroRestaurantInfoProps) => {
  const { restaurant, deliveryAddress, deliveryETA } = props;
  const { t } = useLocalizationContext();
  const { minWidth } = useResponsive();
  const isDeliveryMenu = Boolean(deliveryETA);

  const wrapperStyles = [
    styles.storeInfoCollapseBlock,
    minWidth.isMD && styles.storeInfoCollapseBlockDesktop,
  ];

  // @ts-expect-error TS(2345): Argument of type '{ readonly __typename?: "Restaur... Remove this comment to see the full error message
  const menuLocation = deliveryAddress ?? formatAddress(restaurant);
  const restaurantPhoneNumber = formatPhoneNumber({
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    phoneNumber: restaurant.phone,
    shouldMinify: true,
  }).national;
  const restaurantWorkingHours = formatWorkingHours(
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    restaurant.hours.formatted ?? '',
  );

  // ─────────────────────────────────────────────────────────────────

  return (
    <View style={wrapperStyles}>
      {menuLocation ? (
        <BodyText style={styles.contactsTextWithSpacer} size={3}>
          {menuLocation}
        </BodyText>
      ) : null}

      {restaurantPhoneNumber && !isDeliveryMenu ? (
        <BodyText size={3}>{restaurantPhoneNumber}</BodyText>
      ) : null}

      {restaurantWorkingHours.length > 0 && !isDeliveryMenu ? (
        <View style={styles.storeInfoBlockGroup}>
          {restaurantWorkingHours.map((hoursRow, index) => (
            <BodyText
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              style={styles.contactsTextWithSpacer}
              size={3}
            >
              {hoursRow}
            </BodyText>
          ))}
        </View>
      ) : null}

      {isDeliveryMenu ? (
        <BodyText size={3}>
          {t('menu.hero.estimate', { ETA: deliveryETA })}
        </BodyText>
      ) : null}
    </View>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

export const MenuHeroRestaurantInfoToggle = (
  props: MenuHeroRestaurantInfoToggleProps,
) => {
  const { expanded } = props;
  const { t } = useLocalizationContext();

  return (
    <View style={styles.toggleWrapper}>
      <BodyText size={5}>{t('menu.hero.store-information')}</BodyText>

      <View style={styles.toggleIconWrapper}>
        <CollapseIndicatorIcon
          expanded={expanded}
          width={16}
          height={16}
          color={theme.colors.GREEN_1}
        />
      </View>
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  storeInfoCollapseBlock: {
    marginTop: theme.spacing['6'],
    paddingTop: theme.spacing['4'],
  },
  storeInfoCollapseBlockDesktop: {
    paddingTop: 0,
  },
  storeInfoBlockGroup: {
    paddingTop: theme.spacing['4'],
  },

  collapsibleWrapper: {
    paddingTop: theme.spacing['4'],
  },

  contactsTextWithSpacer: {
    marginBottom: theme.spacing['1'],
  },

  toggleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  toggleIconWrapper: {
    marginLeft: 4,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type MenuHeroRestaurantInfoProps = Readonly<{
  restaurant: RestaurantQuery['restaurant'];
  deliveryAddress?: string;
  deliveryETA?: string;
}>;

type MenuHeroRestaurantInfoToggleProps = Readonly<{
  expanded: boolean;
}>;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { ChallengeCardVariants } from './ChallengeCard.constants';
import { useChallengeCardVariantStyles } from './ChallengeCard.hooks';
import type {
  ChallengeCardCompletedProps,
  ChallengeCardNewProps,
  ChallengeCardOngoingProps,
  ChallengeCardProps,
} from './ChallengeCard.types';
import { ChallengeContent } from './subcomponents/ChallengeContent';
import { ChallengeExpirationBadge } from './subcomponents/ChallengeExpirationBadge';
import {
  FooterCompleted,
  FooterNew,
  FooterOngoing,
} from './subcomponents/ChallengeFooter';
import { ChallengeImage } from './subcomponents/ChallengeImage';

// ────────────────────────────────────────────────────────────────────────────────

export const ChallengeCardBase = (props: ChallengeCardProps) => {
  const { variant, expirationDate, title, children, index, testID } = props;

  const cardStyles = useChallengeCardVariantStyles({ variant });

  return (
    <View testID={testID} style={[styles.cardContainer, cardStyles]}>
      <View style={styles.innerContainer}>
        <ChallengeExpirationBadge
          expirationDate={expirationDate}
          isCompleted={variant === 'Completed'}
        />
        <ChallengeImage index={index} />
        <ChallengeContent title={title}>{children}</ChallengeContent>
      </View>
    </View>
  );
};

// ─── VARIATIONS ─────────────────────────────────────────────────────────────────

export const ChallengeCardNew = (props: ChallengeCardNewProps) => {
  const {
    onStartChallenge,
    children,
    disabled,
    isLoading,
    ...challengeCardProps
  } = props;

  return (
    <ChallengeCardBase
      variant={ChallengeCardVariants.New}
      {...challengeCardProps}
    >
      {children}

      <FooterNew
        testID={`${challengeCardProps.testID}-footer`}
        onPress={onStartChallenge}
        disabled={disabled}
        isLoading={isLoading}
      />
    </ChallengeCardBase>
  );
};

export const ChallengeCardOngoing = (props: ChallengeCardOngoingProps) => {
  const {
    totalSteps,
    completedSteps,
    children,
    footerText,
    ...challengeCardProps
  } = props;

  return (
    <ChallengeCardBase
      variant={ChallengeCardVariants.Ongoing}
      {...challengeCardProps}
    >
      {children}

      <FooterOngoing
        testID={`${challengeCardProps.testID}-footer`}
        totalSteps={totalSteps}
        completedSteps={completedSteps}
        footerText={footerText}
      />
    </ChallengeCardBase>
  );
};

export const ChallengeCardCompleted = (props: ChallengeCardCompletedProps) => {
  const { children, ...challengeCardProps } = props;

  return (
    <ChallengeCardBase
      variant={ChallengeCardVariants.Completed}
      {...challengeCardProps}
    >
      {children}

      <FooterCompleted testID={`${challengeCardProps.testID}-footer`} />
    </ChallengeCardBase>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cardContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['4'],
    borderWidth: 1,
    borderColor: theme.colors.OPACITY.TRANSPARENT,
    borderRadius: theme.radius.large,
  },
  innerContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
});

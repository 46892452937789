import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ButtonGroupItem } from './ButtonGroupItem';

// ─────────────────────────────────────────────────────────────────────────────

export const ButtonGroup = (props: ButtonGroupProps) => {
  const {
    value,
    items,
    size = 'medium',
    gap = '3',
    fluid,
    itemsHorizontalPadding = '4',
    isDisabled,
    onChange,
  } = props;

  if (items.length === 0) return null;

  return (
    <View style={styles.container} accessibilityRole="radiogroup">
      {items.map((item, index) => {
        const {
          value: itemValue,
          label,
          helperText,
          accessibilityLabel,
          accessibilityHint,
          disabled,
        } = item;

        const isActive = itemValue === value;
        const isLast = index === items.length - 1;

        return (
          <ButtonGroupItem
            key={itemValue}
            value={itemValue}
            setValue={onChange}
            label={label}
            helperText={helperText}
            isActive={isActive}
            size={size}
            accessibilityLabel={accessibilityLabel}
            accessibilityHint={accessibilityHint}
            disabled={isDisabled ?? disabled}
            gap={isLast ? undefined : gap}
            fluid={fluid}
            horizontalPadding={itemsHorizontalPadding}
          />
        );
      })}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ButtonRadioGroupItemProps = ComponentProps<typeof ButtonGroupItem>;

type ButtonGroupProps = Readonly<{
  value: string;
  items: readonly ButtonGroupItemData[];
  onChange: (value: string) => void;

  /**
   * Horizontal padding applied to each ButtonGroupItem.
   * @default "medium"
   */
  itemsHorizontalPadding?: ButtonRadioGroupItemProps['horizontalPadding'];

  /**
   * Button size for the ButtonGroupItems.
   * @default "medium"
   */
  size?: ButtonRadioGroupItemProps['size'];

  /**
   * Right margin applied to every ButtonGroupItem but the last.
   * @default "default"
   */
  gap?: ButtonRadioGroupItemProps['gap'];

  /**
   * Whether the ButtonGroupItems will have { flex: 1 }.
   * @default false
   */
  fluid?: ButtonRadioGroupItemProps['fluid'];

  /**
   * Disables all buttons.
   */
  isDisabled?: boolean;
}>;

type ButtonGroupItemData = Readonly<{
  value: string;
  label: string;
  accessibilityLabel?: string;
  accessibilityHint?: string;
  helperText?: string;
  disabled?: boolean;
}>;

import React, { useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { ButtonFluid, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationCTA = (props: CustomizationCTAProps) => {
  const {
    variation,
    cost,
    calories,
    quantity,
    withSafeAreaEdge,
    disabled,
    isLoading,
    onPress,
  } = props;

  const { formatNumber, formatMessage } = useIntl();

  // ─── Derived Data ────────────────────────────────────────────────────

  const formattedCost = useMemo(() => {
    const formattedValue = formatNumber(cost / 100, {
      style: 'currency',
      currency: 'USD',
    });

    if (quantity > 1) {
      return formatMessage(messages.costPerProduct, { cost: formattedValue });
    }

    return formattedValue;
  }, [formatNumber, cost, quantity, formatMessage]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ButtonFluid.Pressable
      onPress={onPress}
      style={styles.pressable}
      disabled={disabled}
      isLoading={isLoading}
      safeAreaEdge={withSafeAreaEdge ? 'bottom' : undefined}
    >
      <ButtonFluid.Label disabled={disabled}>
        {variation === 'continue' ? (
          <FormattedMessage {...messages.continueLabel} />
        ) : null}

        {variation === 'add-to-bag' ? (
          <FormattedMessage {...messages.addToBagLabel} />
        ) : null}

        {variation === 'update' ? (
          <FormattedMessage {...messages.updateLabel} />
        ) : null}
      </ButtonFluid.Label>

      <View style={styles.textContainer}>
        <ButtonFluid.Text disabled={disabled}>
          <FormattedMessage {...messages.calories} values={{ calories }} />
        </ButtonFluid.Text>

        <ButtonFluid.Text disabled={disabled} sizeMatch={['22']}>
          {formattedCost}
        </ButtonFluid.Text>
      </View>
    </ButtonFluid.Pressable>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  continueLabel: {
    defaultMessage: 'Continue',
    description: 'Customization | CTA | "Continue" label',
  },
  addToBagLabel: {
    defaultMessage: 'Add to bag',
    description: 'Customization | CTA | "Add to bag" label',
  },
  updateLabel: {
    defaultMessage: 'Update',
    description: 'Customization | CTA | "Update" label',
  },
  calories: {
    defaultMessage: '{calories} cal',
    description: 'Customization | CTA | Calories',
  },
  costPerProduct: {
    defaultMessage: '{cost}/ea',
    description: 'Customization | CTA | Cost per product',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    columnGap: theme.spacing['3'],
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    columnGap: theme.spacing['3'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationCTAProps = {
  variation: 'add-to-bag' | 'continue' | 'update';
  cost: number;
  calories: number;
  quantity: number;
  disabled?: boolean;
  isLoading?: boolean;
  withSafeAreaEdge?: boolean;
  onPress: () => void;
};

import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, theme } from '@sg/garnish';

/**
 * View menu button to be used in the top header.
 */
export const BagViewMenuCta = (props: BagViewMenuCtaProps) => {
  return (
    <Button
      palette="muted"
      style={styles.cta}
      accessibilityLabel={props.a11yLabel}
      disabled={props.isDisabled}
      onPress={props.onPress}
    >
      {props.cta}
    </Button>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cta: {
    borderRadius: theme.radius.xxxlarge,
    paddingHorizontal: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BagViewMenuCtaProps = {
  cta: string;
  a11yLabel: string;
  isDisabled: boolean;
  onPress: () => void;
};

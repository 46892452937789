/* istanbul ignore file */

import type { ComponentProps } from 'react';
import React, { memo } from 'react';
import { IllusEmpty_1, IllusLocation } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type { LocationSearchMachineContext } from '../../../../machine';
import {
  LocationResults,
  LocationResultsListsContainer,
} from '../../../LocationResults';
import { LocationResultsListInfoMessage } from '../../../LocationResultsListInfoMessage';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationSearchLocationsResults = memo(
  (props: LocationSearchLocationsResultsProps) => {
    const {
      locationSearchType,
      locations,
      focusedLocationId,
      isLoading,
      isUsingSearchField,
      interactedLocationRestaurantSlug,
      withSafeAreaInsets,
      onLocationCardPress,
      onLocationCardFocus,
      onLocationResultsExpandCollapse,
    } = props;

    const { t } = useLocalizationContext();

    const { pickup: pickupLocations = [], outpost: outpostLocations = [] } =
      locations ?? {};

    const activeLocations =
      locationSearchType === 'outpost' ? outpostLocations : pickupLocations;
    const otherLocations =
      locationSearchType === 'outpost' ? pickupLocations : outpostLocations;

    const hasResolvedLocations = locations !== undefined;
    const noLocationsFound =
      hasResolvedLocations &&
      activeLocations.length + otherLocations.length === 0;

    const shouldRenderIntroMessage = !isLoading && !hasResolvedLocations;
    const shouldRenderNoLocationsFoundMessage = !isLoading && noLocationsFound;

    // ─────────────────────────────────────────────────────────────────────

    if (shouldRenderIntroMessage) {
      return (
        <LocationResultsListInfoMessage
          illustration={IllusLocation}
          illustrationAriaLabel={t('location.location-illustration.alt')}
          text={t('location.enable-location-services')}
        />
      );
    }

    if (shouldRenderNoLocationsFoundMessage) {
      return (
        <LocationResultsListInfoMessage
          illustration={IllusEmpty_1}
          illustrationAriaLabel={t('location.empty-results-illustration.alt')}
          title={t('location.no-results.banner')}
          text={t('location.try-another-search')}
        />
      );
    }

    return (
      <LocationResultsListsContainer>
        <LocationResults
          locationSearchType={locationSearchType}
          locations={activeLocations}
          otherLocations={otherLocations}
          activePinLocationId={focusedLocationId}
          interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
          isLoading={isLoading}
          isUsingSearchField={isUsingSearchField}
          withSafeAreaInsets={withSafeAreaInsets}
          shouldHideDetailsModal={false}
          onCardPress={onLocationCardPress}
          onCardFocus={onLocationCardFocus}
          onLocationResultsExpandCollapse={onLocationResultsExpandCollapse}
        />
      </LocationResultsListsContainer>
    );
  },
);

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationSearchLocationsResultsProps = Readonly<{
  locationSearchType: LocationSearchMachineContext['locationSearchType'];
  locations: LocationSearchMachineContext['locations'];
  isLoading: boolean;
  isUsingSearchField: boolean;
  interactedLocationRestaurantSlug: string | undefined;
  focusedLocationId?: string;
  withSafeAreaInsets?: boolean;
  onLocationCardPress: ComponentProps<typeof LocationResults>['onCardPress'];
  onLocationCardFocus: ComponentProps<typeof LocationResults>['onCardFocus'];
  onLocationResultsExpandCollapse: ComponentProps<
    typeof LocationResults
  >['onLocationResultsExpandCollapse'];
}>;

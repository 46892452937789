import { boxStyles, iconStyles, textStyles } from '../styles';
import { createUseButtonStylesHook } from './createUseButtonStylesHook';

/**
 * The complexity of button is largely in the mapping of...
 *   (a) design library props (palette, size, disabled); and
 *   (b) UI event state (hovered/pressed, focused)
 * ...to corresponding style definitions.
 *
 * This is accomplished by having structured style definitions that are consumed
 * by hooks that accept the parameters of the Button component API.
 */
export const useBoxStyles = createUseButtonStylesHook(boxStyles);
export const useTextStyles = createUseButtonStylesHook(textStyles);
export const useIconStyles = createUseButtonStylesHook(iconStyles);

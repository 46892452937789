import type { ContextFrom, EventFrom } from 'xstate';
import { assign } from 'xstate';
import { createModel } from 'xstate/lib/model';

// ────────────────────────────────────────────────────────────────────────────────

export const modalModel = createModel(
  {},
  {
    events: {
      START_SHOW: () => ({}),
      SHOW: () => ({}),
      START_DISMISS: () => ({}),
      DISMISS: () => ({}),
    },
  },
);

//
// ─── VISIBILITY ─────────────────────────────────────────────────────────────────
//

const visibilityStateNode = {
  initial: 'dismissed',
  states: {
    shown: {
      meta: { description: 'The modal is fully shown.' },
      on: {
        DISMISS: 'dismissed',
      },
      entry: assign({ active: true }),
    },

    dismissed: {
      meta: { description: 'The modal is fully dismissed.' },
      on: {
        SHOW: 'shown',
      },
      entry: assign({ active: false }),
    },
  },
};

//
// ─── ANIMATION ──────────────────────────────────────────────────────────────────
//

const animationStateNode = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        START_SHOW: {
          target: 'showing',
          cond: 'checkIfDismissed',
        },
        START_DISMISS: {
          target: 'dismissing',
          cond: 'checkIfShown',
        },
      },
    },

    showing: {
      meta: { description: 'Show animation in progress.' },
      on: {
        '*': 'idle',
        START_DISMISS: 'dismissing',
      },
    },

    dismissing: {
      meta: { description: 'Dismiss animation in progress.' },
      on: {
        '*': 'idle',
        START_SHOW: 'showing',
      },
    },
  },
};

//
// ─── MACHINE ────────────────────────────────────────────────────────────────────
//

export const createModalMachine = (props: ModalMachineCreatorProps) =>
  modalModel.createMachine(
    {
      predictableActionArguments: true, // https://xstate.js.org/docs/guides/actions.html
      id: 'sgGarnishModalMachine',
      context: modalModel.initialContext,
      type: 'parallel',
      states: {
        visibility: { ...visibilityStateNode, initial: props.initial },
        animation: animationStateNode,
      },
    },
    {
      guards: {
        checkIfDismissed(ctx, event, machine) {
          return machine.state.matches('visibility.dismissed');
        },
        checkIfShown(ctx, event, machine) {
          return machine.state.matches('visibility.shown');
        },
      },
    },
  );

//
// ─── TYPES ──────────────────────────────────────────────────────────────────────
//

export type ModalMachineCreatorProps = Readonly<{
  initial: 'shown' | 'dismissed';
}>;

/**
 * @see https://xstate.js.org/docs/guides/models.html#extracting-types-from-model
 */

export type ModalMachineContext = ContextFrom<typeof modalModel>;
export type ModalMachineEvents = EventFrom<typeof modalModel>;

import { type AddressType } from '@sg/garnish';

import { type FeatureFlags, useFeatureFlag } from '@order/LaunchDarkly';

/**
 * Returns whether this order has tipping enabled.
 */
export const useTippingEnabled = (params: UseTippingEnabledParams): boolean => {
  const { restaurantId, orderChannel } = params;

  const locationsWithEnabledTipping = useFeatureFlag(
    'CELS-1149-short-target-tipping-by-location-enabled',
  );
  const dynamicTips =
    useFeatureFlag('CELS-1191-permanent-dynamic-tip-values') ?? {};

  const availableTips = typeof dynamicTips === 'object' ? dynamicTips : {};

  const listOfLocationsWithEnabledTipping =
    getListOfLocationsWithEnabledTipping(locationsWithEnabledTipping);

  const isTippingEnabledForAllLocations = checkIfTippingEnabledForAllStores(
    listOfLocationsWithEnabledTipping,
  );

  const isTippingEnabledForLocation =
    isTippingEnabledForAllLocations ||
    listOfLocationsWithEnabledTipping.includes(restaurantId);

  const {
    pickup: pickupTips = [],
    delivery: deliveryTips = [],
    outpost: outpostTips = [],
  } = availableTips;

  // ─────────────────────────────────────────────────────────────────────

  if (orderChannel === 'pickup') {
    return isTippingEnabledForLocation && pickupTips.length > 0;
  }

  if (orderChannel === 'delivery') {
    return deliveryTips.length > 0;
  }

  if (orderChannel === 'outpost') {
    return outpostTips.length > 0;
  }

  return false;
};

// ─── Helpers ────────────────────────────────────────────────────────────────

function getListOfLocationsWithEnabledTipping(
  maybeTargetTippingStores: unknown,
): TargetTippingStores {
  const isValidListProvided = Array.isArray(maybeTargetTippingStores);

  if (!isValidListProvided) {
    return [];
  }

  return maybeTargetTippingStores.map(String);
}

/**
 * Returns a `boolean` indicating whether tipping is enabled for all stores,
 * by checking if the first list item is equal to 'all'.
 */
function checkIfTippingEnabledForAllStores(
  targetTippingStores: readonly string[],
): boolean {
  return targetTippingStores[0] === 'all';
}

// ─── Types ──────────────────────────────────────────────────────────────────

type UseTippingEnabledParams = {
  restaurantId: string;
  orderChannel: AddressType;
};

type TargetTippingStores =
  FeatureFlags['CELS-1149-short-target-tipping-by-location-enabled'];

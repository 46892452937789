import React, { type ComponentProps, forwardRef } from 'react';
import type { Text as RNText } from 'react-native';
import { StyleSheet } from 'react-native';
import { TYPE_CONFIG, WEIGHT_MAP } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { Text } from '../Text';
import { useFluidTextStyles } from '../useFluidTextStyles';

// ─────────────────────────────────────────────────────────────────────────────

export const TitleText = forwardRef<RNText, TitleTextProps>((props, ref) => {
  const { children, size, sizeMatch = [], ...restProps } = props;

  if (sizeMatch.length > 0) {
    return (
      <TitleTextStatic ref={ref} sizeMatch={sizeMatch} {...restProps}>
        {children}
      </TitleTextStatic>
    );
  }

  return (
    <TitleTextFluid ref={ref} size={size} {...restProps}>
      {children}
    </TitleTextFluid>
  );
});

// ─────────────────────────────────────────────────────────────────────────────

const TitleTextStatic = forwardRef<RNText, TitleTextStaticProps>(
  (props, ref) => {
    const { children, sizeMatch, bold, style, ...restProps } = props;

    const { match } = useResponsive();

    const fontSize = match(sizeMatch);
    const textStyles =
      TITLE_TEXT_STYLES[fontSize] ?? TITLE_TEXT_DEFAULT_FONT_STYLES;
    const boldTextStyles = bold ? styles.boldText : undefined;
    const allStyles = [textStyles, boldTextStyles, style];

    return (
      <Text ref={ref} style={allStyles} bold={bold} {...restProps}>
        {children}
      </Text>
    );
  },
);

const TitleTextFluid = forwardRef<RNText, TitleTextFluidProps>((props, ref) => {
  const {
    children,
    size = TITLE_TEXT_DEFAULT_ORDINAL_SIZE,
    bold,
    style,
    ...restProps
  } = props;

  const fontSize = TITLE_TEXT_PRESET_MAP[size];
  const textStyles =
    TITLE_TEXT_STYLES[fontSize] ?? TITLE_TEXT_DEFAULT_FONT_STYLES;
  const fluidTextStyles = useFluidTextStyles(
    TITLE_TEXT_PRESET_MAP[size],
    'TITLE',
  );
  const boldTextStyles = bold ? styles.boldText : undefined;
  const allStyles = [textStyles, boldTextStyles, fluidTextStyles, style];

  return (
    <Text ref={ref} style={allStyles} bold={bold} {...restProps}>
      {children}
    </Text>
  );
});

// ─── Constants ───────────────────────────────────────────────────────────────

const TITLE_TEXT_STYLES = TYPE_CONFIG.TITLE;

const TITLE_TEXT_PRESET_MAP: Record<number, TitleTextFontSize> = {
  0: '120',
  1: '80',
  2: '64',
  3: '48',
  4: '40',
  5: '32',
  6: '24',
  7: '18',
};

const TITLE_TEXT_DEFAULT_ORDINAL_SIZE: TitleTextSizeOrdinal = 1;
const TITLE_TEXT_DEFAULT_FONT_SIZE: TitleTextFontSize = '80';

const TITLE_TEXT_DEFAULT_FONT_STYLES =
  TITLE_TEXT_STYLES[TITLE_TEXT_DEFAULT_FONT_SIZE];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  boldText: {
    fontWeight: WEIGHT_MAP.bold,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type TitleTextProps = Readonly<{
  size?: TitleTextSizeOrdinal;
  sizeMatch?: TitleTextFontSize[];
}> &
  TitleTextBaseProps;

type TitleTextStaticProps = Readonly<{
  sizeMatch: TitleTextFontSize[];
}> &
  TitleTextBaseProps;

type TitleTextFluidProps = Readonly<{
  size?: TitleTextSizeOrdinal;
}> &
  TitleTextBaseProps;

type TitleTextBaseProps = ComponentProps<typeof Text>;

type TitleTextSizeOrdinal = keyof typeof TITLE_TEXT_PRESET_MAP;

type TitleTextFontSize = keyof typeof TITLE_TEXT_STYLES;

import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';

import { useResponsive } from '../../../../../hooks';

// ────────────────────────────────────────────────────────────────────────────────

export const NoticeBannersStackWrapper = (props: ViewProps) => {
  const { style, children, ...restProps } = props;

  const { match } = useResponsive();

  return (
    <View
      style={[style, match([styles.wrapperXs, undefined])]}
      pointerEvents="none"
      {...restProps}
    >
      {children}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapperXs: {
    flexDirection: 'column-reverse',
  },
});

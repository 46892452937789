import { type Ingredient } from '@sg/graphql-schema';

/**
 * Returns ingredient modifications including substitution and filtered added and removed ingredients.
 */
export function getIngredientModifications(
  params: GetModifiedIngredientsListParams,
) {
  const { addedIngredients, removedIngredients } = params;

  const substitutions = getSubstitutionsList({
    addedIngredients,
    removedIngredients,
  });
  const filteredAddedIngredients = filterAddedSubstitutions(
    addedIngredients,
    substitutions,
  );
  const filteredRemovedIngredients = filterRemovedSubstitutions(
    removedIngredients,
    substitutions,
  );

  return {
    substitutions,
    filteredAddedIngredients,
    filteredRemovedIngredients,
  };
}

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns a substitution list based on the provided added and removed ingredients.
 */
function getSubstitutionsList(
  params: GetSubstitutionsListParams,
): Substitutions {
  const { addedIngredients, removedIngredients } = params;

  return addedIngredients.reduce<Substitutions>((substitutions, ingredient) => {
    const maybeSubstitution = findSubstitution(ingredient, removedIngredients);

    if (!maybeSubstitution) return substitutions;

    return withSubstitution(substitutions, ingredient, maybeSubstitution);
  }, []);
}

function withSubstitution(
  substitutions: Substitutions,
  addedIngredient: PartialIngredient,
  removedIngredient: PartialIngredient,
) {
  const { id: addedIngredientId } = addedIngredient;
  const { id: removedIngredientId } = removedIngredient;

  return [...substitutions, { addedIngredientId, removedIngredientId }];
}

function findSubstitution(
  addedIngredient: PartialIngredient,
  removedIngredients: Ingredients,
) {
  return removedIngredients.find(
    (ingredient) => ingredient.kind === addedIngredient.kind,
  );
}

function filterAddedSubstitutions(
  ingredients: Ingredients,
  substitutions: Substitutions,
) {
  return ingredients.filter(
    (ingredient) =>
      !substitutions.some(
        ({ addedIngredientId }) => addedIngredientId === ingredient.id,
      ),
  );
}

function filterRemovedSubstitutions(
  ingredients: Ingredients,
  substitutions: Substitutions,
) {
  return ingredients.filter(
    (ingredient) =>
      !substitutions.some(
        ({ removedIngredientId }) => removedIngredientId === ingredient.id,
      ),
  );
}

// ─── Types ───────────────────────────────────────────────────────────────────

type GetModifiedIngredientsListParams = {
  addedIngredients: Ingredients;
  removedIngredients: Ingredients;
};

type GetSubstitutionsListParams = {
  addedIngredients: Ingredients;
  removedIngredients: Ingredients;
};

type Substitution = {
  addedIngredientId: string;
  removedIngredientId: string;
};

type Substitutions = Substitution[];

type Ingredients = ReadonlyArray<PartialIngredient>;

type PartialIngredient = Pick<Ingredient, 'id' | 'kind'>;

import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { LabelText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductCardV2AllergenNotice = (props: PropsWithChildren) => {
  return (
    <View style={styles.container}>
      <LabelText sizeMatch={['12']} style={styles.text}>
        {props.children}
      </LabelText>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexShrink: 1,
    alignItems: 'center',
    alignSelf: 'flex-start',
    gap: theme.spacing['2'],
    backgroundColor: theme.colors.PEACH,
    borderRadius: theme.radius.medium,
    marginTop: theme.spacing['3'],
    paddingVertical: theme.spacing['1'],
    paddingHorizontal: theme.spacing['2'],
  },
  text: {
    color: theme.colors.CAUTION,
  },
});

import type { ComponentProps } from 'react';
import React, { useMemo } from 'react';

import { useResponsive } from '../../hooks';
import { Picker } from '../Pickers/Picker';
import { PickersGroup } from '../Pickers/PickersGroup';
import { Select } from '../Select';

export const ResponsiveSelect = (props: ResponsiveSelectProps) => {
  const {
    label,
    modalTitle,
    value,
    options,
    submitBtnLabel,
    isLoading,
    accessibilityLabel,
    accessibilityLabelLeftGroup,
    accessibilityLabelRightGroup,
    onSelect,
  } = props;

  const { currentBreakpoint } = useResponsive();
  const shouldUsePicker = currentBreakpoint.isXS;
  const optionsHasGroups = !Array.isArray(options);

  // ────────────────────────────────────────────────────────────────────

  const normalizedOptions = useMemo(() => {
    const optionsAsGroups = options as PickersGroupOptions;

    if (shouldUsePicker) {
      return optionsHasGroups
        ? removeDisabledOptionsFromGroups(optionsAsGroups)
        : removeDisabledOptions(options);
    }

    return optionsHasGroups
      ? extractOptionsFromGroups(optionsAsGroups)
      : Object.values(options).flat();
  }, [options, optionsHasGroups, shouldUsePicker]);

  // ────────────────────────────────────────────────────────────────────

  if (shouldUsePicker) {
    if (optionsHasGroups) {
      return (
        <PickersGroup
          optionsGroups={normalizedOptions as PickersGroupOptions}
          submitBtnLabel={submitBtnLabel}
          label={label}
          modalTitle={modalTitle}
          accessibilityLabel={accessibilityLabel}
          accessibilityLabelLeft={accessibilityLabelLeftGroup}
          accessibilityLabelRight={accessibilityLabelRightGroup}
          value={value}
          onSubmit={onSelect}
        />
      );
    }

    return (
      <Picker
        options={normalizedOptions as PickerOptions}
        submitBtnLabel={submitBtnLabel}
        label={label}
        modalTitle={modalTitle}
        accessibilityLabel={accessibilityLabel}
        value={value}
        onSubmit={onSelect}
      />
    );
  }

  // ────────────────────────────────────────────────────────────────────

  return (
    <Select
      label={label}
      value={value}
      options={normalizedOptions as SelectOptions}
      isLoading={isLoading}
      accessibilityLabel={accessibilityLabel}
      onSelect={onSelect}
    />
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────────

type ResponsiveSelectProps = Readonly<{
  label?: string;
  modalTitle?: string;
  value: string | number;
  options: PickersGroupOptions | PickerOptions | SelectOptions;
  submitBtnLabel?: string;
  isLoading?: boolean;
  accessibilityLabel: string;
  accessibilityLabelLeftGroup?: string;
  accessibilityLabelRightGroup?: string;
  onSelect: PickerOnSubmit | SelectOnSelect;
}>;

type PickersGroupOptions = ComponentProps<typeof PickersGroup>['optionsGroups'];
type PickerOptions = ComponentProps<typeof Picker>['options'];
type PickerOnSubmit = ComponentProps<typeof Picker>['onSubmit'];

type SelectOptions = ComponentProps<typeof Select>['options'];
type SelectOnSelect = ComponentProps<typeof Select>['onSelect'];

// ─── Utils ──────────────────────────────────────────────────────────────────────

export const extractOptionsFromGroups = (
  optionsGroups: PickersGroupOptions,
) => {
  const groups = Object.entries(optionsGroups);

  return groups.reduce<PickerOptions>((allOptions, [groupLabel, options]) => {
    const formattedOptions = options.map((option) => ({
      ...option,
      label: option.label ? `${groupLabel}, ${option.label}` : groupLabel,
    }));

    return [...allOptions, ...formattedOptions];
  }, []);
};

export const removeDisabledOptionsFromGroups = (
  optionsGroups: PickersGroupOptions,
) => {
  return Object.entries(optionsGroups).reduce(
    (groups, [groupName, options]) => {
      return {
        ...groups,
        [groupName]: (options as SelectOptions).filter(
          (option) => !option.disabled,
        ),
      };
    },
    {},
  );
};

const removeDisabledOptions = (options: SelectOptions) => {
  return options.filter((option) => !option.disabled);
};

/* eslint-disable @typescript-eslint/consistent-type-imports, @typescript-eslint/consistent-type-assertions */

import { createMachine } from 'xstate';
import { assign } from 'xstate/lib/actions';

import {
  FavoritesMachineContext,
  FavoritesMachineEvents,
  FavoritesMachineServices,
  FavoriteWithId,
} from './favorites-machine.types';
import {
  favoritesMachineLogger,
  sortFavoritesById,
  withoutDuplicateFavorites,
} from './favorites-machine.utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A state machine to manage the user's favorites with pagination support.
 *
 * NOTE: The machine is also "restartable", which means it can be restarted
 *       at any point during its flow by resetting the context and fetching
 *       the initial favorites.
 *
 * NOTE: The machine requires external services to retrieve favorites.
 *
 * NOTE: The initial state can be passed to the machine as an argument, however,
 *       it is usually used for testing.
 */
export const createFavoritesMachine = <Favorite extends FavoriteWithId>(
  initialState:
    | 'waiting'
    | 'idle'
    | 'fetchingInitialFavorites'
    | 'fetchingMoreFavorites' = 'waiting',
) => {
  return createMachine(
    {
      tsTypes: {} as import('./favorites-machine.typegen').Typegen0,
      predictableActionArguments: true,
      preserveActionOrder: true,
      id: 'favorites-state-machine',
      schema: {
        context: {} as FavoritesMachineContext<Favorite>,
        events: {} as FavoritesMachineEvents,
        services: {} as FavoritesMachineServices<Favorite>,
      },
      context: {
        favorites: [],
        page: 0,
        canFetchMoreFavorites: false,
      },
      initial: initialState,
      states: {
        waiting: {
          description: 'An empty state used for the initial setup.',
        },
        idle: {
          id: 'idle',
          description: 'An idle state that awaits the next event.',
          on: {
            REFETCH_FAVORITES: {
              description: 'Refetch current favorites.',
              target: '#refetching-favorites',
            },
            FETCH_MORE_FAVORITES: {
              description: 'Fetch more favorites if possible.',
              target: '#fetching-more-favorites',
              actions: ['increasePage'],
              cond: 'checkIfCanFetchMoreFavorites',
            },
            TOGGLE_FAVORITE: {
              target: '#toggling-favorite',
            },
          },
        },
        fetchingInitialFavorites: {
          id: 'fetching-initial-favorites',
          initial: 'favorites',
          states: {
            favorites: {
              description: 'A state for fetching initial favorites.',
              invoke: {
                src: 'fetchFavorites',
                onDone: {
                  target: '#idle',
                  actions: ['onFetchFavoritesDone'],
                },
              },
            },
          },
        },
        refetchingFavorites: {
          id: 'refetching-favorites',
          initial: 'favorites',
          states: {
            favorites: {
              description: 'A state for refetching current favorites.',
              invoke: {
                src: 'fetchFavorites',
                onDone: {
                  target: '#idle',
                  actions: ['onFetchFavoritesDone'],
                },
              },
            },
          },
        },
        fetchingMoreFavorites: {
          id: 'fetching-more-favorites',
          initial: 'favorites',
          states: {
            favorites: {
              description: 'A state for fetching more favorites.',
              invoke: {
                src: 'fetchFavorites',
                onDone: {
                  target: '#idle',
                  actions: ['onFetchFavoritesDone'],
                },
              },
            },
          },
        },
        togglingFavoriteState: {
          id: 'toggling-favorite',
          invoke: {
            src: 'toggleFavorite',
            onDone: {
              target: '#idle',
              actions: ['onToggleFavoriteDone'],
            },
            onError: '#idle',
          },
        },
      },
      on: {
        START: {
          description:
            'Starts/restarts the machine by fetching the initial favorites and clearing the state.',
          target: '#fetching-initial-favorites',
          actions: ['resetContext'],
        },
      },
    },
    {
      actions: {
        /**
         * Stores the resolved favorites in the context.
         */
        onFetchFavoritesDone: assign({
          favorites(context, event) {
            const { favorites: existingFavorites } = context;
            const { favorites } = event.data;

            const updatedFavorites = [...existingFavorites, ...favorites];

            return sortFavoritesById(
              withoutDuplicateFavorites(updatedFavorites),
            );
          },
          canFetchMoreFavorites(_context, event) {
            const { favorites } = event.data;

            return favorites.length === 10;
          },
        }),

        onToggleFavoriteDone: assign({
          favorites(context, event) {
            if (!event.data) return context.favorites;

            const { id, isFavorite } = event.data;

            return [...context.favorites].map((favorite) => {
              const isTargetFavorite = favorite.id === id;

              return isTargetFavorite
                ? { ...favorite, favorited: isFavorite }
                : favorite;
            });
          },
        }),

        increasePage: assign({
          page: (context) => context.page + 1,
        }),

        /**
         * Resets the context of the machine.
         *
         * Can be used to clear the state before restarting the machine.
         */
        resetContext: assign({
          favorites: [],
          page: 0,
          canFetchMoreFavorites: false,
        }),
      },
      services: {
        async fetchFavorites(_context) {
          favoritesMachineLogger.debug(
            'Please provide the `fetchFavorites` service.',
          );

          return { favorites: [] };
        },
        async toggleFavorite(_context) {
          favoritesMachineLogger.debug(
            'Please provide the `toggleFavorite` service.',
          );

          return { id: '', isFavorite: true };
        },
      },
      guards: {
        checkIfCanFetchMoreFavorites: (context) =>
          context.canFetchMoreFavorites,
      },
    },
  );
};

import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { uniqBy } from 'lodash';

import type { Order, OrdersResponseSuccess } from '@order/graphql';

import { useOrderHistoryQuery } from '../../GraphQL/Orders.generated';

/**
 * Here we use a local `paginatedOrders` state to handle pagination.
 * It is easier (and recommended) to manage a state variable rather than graphcache.
 */
export const usePaginatedOrders = () => {
  // ─── Pagination State ────────────────────────────────────────────────

  const [paginatedOrders, setPaginatedOrders] = useState<readonly Order[]>([]);
  const [page, setPage] = useState(1);

  // ─── Query ───────────────────────────────────────────────────────────

  const variables = { input: { take: 10, page } };

  const orderHistoryQuery = useOrderHistoryQuery({
    variables,
    pause: true,
    requestPolicy: 'cache-and-network',
  });

  const [response, fetchOrderHistory] = orderHistoryQuery;

  // ─── Response ────────────────────────────────────────────────────────

  const isLoading = response.fetching;
  const successResponse = response.data?.orders as OrdersResponseSuccess;

  // ─── Pagination ──────────────────────────────────────────────────────

  const loadedOrders = paginatedOrders.length;
  const newOrders = successResponse?.orders;
  const paginationTotal = successResponse?.pagination?.total ?? 0;
  const canLoadMore = !paginationTotal || loadedOrders < paginationTotal;

  useOrderPageConcatenation(newOrders, setPaginatedOrders);

  // ─── Loading More Increments Page & Re-Queries ───────────────────────

  const handleLoadMore = useCallback(() => {
    setPage((currentPage) => currentPage + 1);
    fetchOrderHistory();
  }, [setPage, fetchOrderHistory]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const resetOrderHistory = useCallback(() => {
    setPage(1);
    setPaginatedOrders([]);
  }, []);

  const refetchOrderHistory = useCallback(
    (_orderId: string) => {
      resetOrderHistory();
      fetchOrderHistory();
    },
    [resetOrderHistory, fetchOrderHistory],
  );

  // ─── Reset Pagination on Focus ───────────────────────────────────────

  useFocusEffect(resetOrderHistory);

  // ─── Re-Query On Focus ───────────────────────────────────────────────

  useFocusEffect(fetchOrderHistory);

  return {
    orders: paginatedOrders,
    canLoadMore,
    isLoading,
    handleLoadMore,
    refetchOrderHistory,
  };
};

/**
 * This effect will concatenate previous pages of orders with the new resolved page.
 * It will also remove duplicates and sort them decreasingly by id.
 */
function useOrderPageConcatenation(
  newOrders: readonly Order[] | undefined,
  setPaginatedOrders: Dispatch<SetStateAction<readonly Order[]>>,
) {
  useEffect(() => {
    setPaginatedOrders((previousOrders) => {
      const jointOrders = [...previousOrders, ...(newOrders ?? [])];
      const uniqueOrders = uniqBy(jointOrders, 'id');

      // This is modifying a local array that was already cloned.
      // eslint-disable-next-line functional/immutable-data
      uniqueOrders.sort(
        (order1, order2) => Number(order2.id) - Number(order1.id),
      );

      return uniqueOrders;
    });
  }, [newOrders, setPaginatedOrders]);
}

import { useValidOrders } from './useValidOrders';

/**
 * Returns last placed valid* order from cache
 *
 * valid = either a pickup or outpost order or a delivery order with active delivery address.
 */
export const useLastValidOrder = () => {
  const {
    orders: validOrders,
    fetchOrders,
    isFetchingOrders,
  } = useValidOrders();

  return {
    lastValidOrder: validOrders[0],
    fetchLastValidOrder: fetchOrders,
    isFetchingOrders,
  };
};

/* cSpell:ignore regexify */

import React, { type ComponentProps } from 'react';
import { StyleSheet, Text } from 'react-native';
import regexifyString from 'regexify-string';

import { PhoneLink } from '../../PhoneLink';
import { TextLink } from '../../TextLink';

export const TextLinkify = (props: TextLinkifyProps) => (
  <Text>{linkifyText(props.children, props.regex)}</Text>
);

export const TextLinkifyTags = ({
  children,
  links,
  palette,
  ...rest
}: TextLinkifyTagsProps) => (
  <Text {...rest}>
    {linkifyTags({
      text: children,
      links,
      size: rest.size,
      sizeMatch: rest.sizeMatch,
      style: rest.style,
      palette,
    })}
  </Text>
);

// ─── UTILS ──────────────────────────────────────────────────────────────────────

function linkifyText(text: string, urlRegex = /(((https?:\/\/)|(www\.))\S+)/g) {
  return regexifyString({
    pattern: urlRegex,
    decorator: (url, index) => (
      <TextLink key={index} href={withHttpsProtocol(url)}>
        {url}
      </TextLink>
    ),
    input: text,
  }).filter(withoutEmptyStrings);
}

function linkifyTags(linkifyTagsParams: LinkifyTagsParams) {
  const { text, links, palette, size, sizeMatch, style } = linkifyTagsParams;

  return regexifyString({
    pattern: /{(.+?)}|<b>(.+?)<\/b>/g,
    decorator(tag, index) {
      if (HIGHLIGHT_PATTERN.test(tag)) {
        return (
          <Text key={index} style={[style, styles.highlight]}>
            {tag.replace('<b>', '').replace('</b>', '')}
          </Text>
        );
      }

      const currentTag = links.find((link) => link.tag === tag.slice(1, -1));

      if (!currentTag) {
        return '';
      }

      // Phone links requires special treatment for native devices.
      if (currentTag.href?.startsWith('tel')) {
        <PhoneLink
          key={index}
          protocol="tel"
          size={size}
          sizeMatch={sizeMatch}
          phoneNumber={currentTag.href.split('tel:')[1]}
        >
          {currentTag.description}
        </PhoneLink>;
      }

      return (
        <TextLink
          key={index}
          size={size}
          sizeMatch={sizeMatch}
          style={style}
          href={currentTag.href}
          newTab={currentTag.newTab}
          underline={currentTag.underline}
          palette={palette}
          onPress={currentTag.onPress}
        >
          {currentTag.description}
        </TextLink>
      );
    },
    input: text,
  }).filter(withoutEmptyStrings);
}

function withoutEmptyStrings(chunk: string | React.ReactElement) {
  return chunk !== '';
}

export function withHttpsProtocol(url: string) {
  if (url.startsWith('http')) {
    return url;
  }

  return `https://${url}`;
}

// ─── Constants ───────────────────────────────────────────────────────────────

const HIGHLIGHT_PATTERN = /<b>(.+?)<\/b>/;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  highlight: {
    fontWeight: '700',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type TextLinkifyProps = React.ComponentProps<typeof TextLink> &
  Readonly<{
    children: string;
    regex?: RegExp;
  }>;

type TextLinkifyTagsProps = React.ComponentProps<typeof TextLink> &
  Readonly<{
    children: string;
    links: ReadonlyArray<
      Readonly<{
        tag: string;
        description: string;
        href?: string;
        underline?: boolean;
        newTab?: boolean;
        onPress?: () => void;
      }>
    >;
  }>;

type LinkifyTagsParams = {
  text: string;
  links: TextLinkifyTagsProps['links'];
} & Pick<
  ComponentProps<typeof TextLink>,
  'size' | 'sizeMatch' | 'style' | 'palette'
>;

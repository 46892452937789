import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useCart } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { getUrqlError } from '@order/utils';

import { useAddressesTelemetry } from '../../AddressForm.telemetry';
import type { AddressForm } from '../../AddressForm.types';
import {
  useAddAddressMutation,
  useDeleteAddressMutation,
  useUpdateAddressMutation,
} from '../../GraphQL/Address.generated';

/**
 * Handlers for adding, updating and removing addresses.
 * This manages telemetry, error banners and updating the cart.
 *
 * The requirement to update the cart post address update is because
 * if you update the address for your current delivery order, it gets
 * invalidated in gravy, so we must refetch to get the latest version.
 */

export const useAddressHandlers = (props?: AddressHandlersProps) => {
  const { editingAddressId, checkExistingBag, onClose } = props ?? {};

  // ─── Mutations ──────────────────────────────────────────────────

  const [addAddressResponse, addAddress] = useAddAddressMutation();
  const [updateAddressResponse, updateAddress] = useUpdateAddressMutation();
  const [deleteAddressResponse, deleteAddress] = useDeleteAddressMutation();

  // ─── Loading States ─────────────────────────────────────────────

  const isAddingAddress = addAddressResponse.fetching;
  const isUpdatingAddress = updateAddressResponse.fetching;
  const isDeletingAddress = deleteAddressResponse.fetching;

  // ─── Active Bag Address Edit ────────────────────────────────────

  const { cart, isCartEmpty, fetchCart } = useCart();
  const currentBagAddressId = cart?.deliveryOrderDetail?.address?.id;
  const isEditingBagAddress = currentBagAddressId === editingAddressId;
  const shouldUpdateCart =
    checkExistingBag && !isCartEmpty && isEditingBagAddress;

  // ─── Telemetry ──────────────────────────────────────────────────

  const { monitoredOnFail } = useAddressesTelemetry({});

  // ─── Locale ─────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const genericSaveFailure = t('addresses.error.save.failure');
  const genericDeleteFailure = t('addresses.error.delete.failure');
  const zipCodeFailure = t('addresses.error.zip.invalid');
  const streetNumberFailure = t('addresses.error.address.invalid');

  // ─── Notice Banners ─────────────────────────────────────────────

  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const notifyError = useCallback(
    (errorMessage: string) => {
      addNoticeBanner({
        text: errorMessage,
        palette: 'caution',
      });
    },
    [addNoticeBanner],
  );

  // ─── Address Validation ─────────────────────────────────────────

  const validateAddress = useCallback(
    (form: AddressForm) => {
      if (!form?.zipCode) {
        monitoredOnFail(streetNumberFailure);
        notifyError(zipCodeFailure);

        return false;
      }

      if (form?.street?.includes('undefined')) {
        monitoredOnFail(streetNumberFailure);
        notifyError(streetNumberFailure);

        return false;
      }

      return true;
    },
    [monitoredOnFail, notifyError, streetNumberFailure, zipCodeFailure],
  );

  // ─── Callbacks ──────────────────────────────────────────────────

  const handleOnSave = useCallback(
    (form: AddressForm) => {
      (async () => {
        if (!validateAddress(form)) return;

        const input = { ...form, addressType: undefined };
        const response = await addAddress({ input });
        const data = response?.data?.addAddress;

        if (data?.__typename !== 'AddAddressSuccess') {
          monitoredOnFail(
            genericSaveFailure,
            getUrqlError(response.error, response.data as never),
          );
          notifyError(genericSaveFailure);

          return;
        }

        if (shouldUpdateCart) fetchCart();
        onClose?.();
      })();
    },
    [
      genericSaveFailure,
      shouldUpdateCart,
      onClose,
      validateAddress,
      fetchCart,
      addAddress,
      monitoredOnFail,
      notifyError,
    ],
  );

  const handleOnEdit = useCallback(
    (form: AddressForm) => {
      (async () => {
        if (!editingAddressId) return;

        if (!validateAddress(form)) return;

        const input = { ...form, addressType: undefined, id: editingAddressId };
        const response = await updateAddress({ input });
        const data = response.data?.updateAddress;

        if (data?.__typename !== 'UpdateAddressSuccess') {
          monitoredOnFail(
            genericSaveFailure,
            getUrqlError(response.error, response.data as never),
          );
          notifyError(genericSaveFailure);

          return;
        }

        if (shouldUpdateCart) fetchCart();
        onClose?.();
      })();
    },
    [
      genericSaveFailure,
      editingAddressId,
      shouldUpdateCart,
      validateAddress,
      updateAddress,
      fetchCart,
      onClose,
      monitoredOnFail,
      notifyError,
    ],
  );

  const handleOnDelete = useCallback(() => {
    (async () => {
      if (!editingAddressId) return;

      const response = await deleteAddress({
        id: editingAddressId,
      });

      const data = response?.data?.deleteAddress;

      if (data?.__typename !== 'DeleteAddressSuccess') {
        monitoredOnFail(
          genericDeleteFailure,
          getUrqlError(response.error, response.data as never),
        );
        notifyError(genericDeleteFailure);

        return;
      }

      if (shouldUpdateCart) fetchCart();
      onClose?.();
    })();
  }, [
    genericDeleteFailure,
    editingAddressId,
    shouldUpdateCart,
    fetchCart,
    onClose,
    deleteAddress,
    monitoredOnFail,
    notifyError,
  ]);

  return {
    isAddingAddress,
    isUpdatingAddress,
    isDeletingAddress,
    handleOnSave,
    handleOnDelete,
    handleOnEdit,
  };
};

type AddressHandlersProps = Readonly<{
  editingAddressId?: string;
  checkExistingBag?: boolean;
  onClose?: (() => void) | null;
}>;

import { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import type { Address } from '@order/graphql';
import { useTelemetry } from '@order/Telemetry';

import { logger } from '../utils';
import {
  useCartForReorder,
  useDeliveryDetailsForReorder,
  useMemoizedConfirmReorderLocation,
  useRestaurantForReorder,
} from './hooks';
import type { ReorderRestaurant } from './useReorderIfRequired.types';
import {
  checkIfShouldStartReorder,
  getBaseReorderDetails,
  getDeliveryReorderDetails,
  getPickupOutpostReorderDetails,
} from './useReorderIfRequired.utils';

/**
 * Initiates the reorder flow on screen focus if necessary.
 *
 * The location confirmation/change modal should be displayed and
 * the reorder flow should be initiated if the restaurant or/and order type
 * on the current screen differ from those in the active cart.
 */
export const useReorderIfRequired = (
  restaurantSlug?: ReorderRestaurant['slug'],
  addressId?: Address['id'],
) => {
  const confirmReorderLocationRef = useMemoizedConfirmReorderLocation();
  const { track } = useTelemetry();

  // ─── Data ────────────────────────────────────────────────────────

  const activeCart = useCartForReorder();
  const activeRestaurant = useRestaurantForReorder(restaurantSlug, addressId);
  const activeDeliveryDetails = useDeliveryDetailsForReorder(addressId);

  // ─── Helpers ─────────────────────────────────────────────────────

  const startReorderFlowIfNecessary = useCallback(() => {
    const { current: confirmReorderLocation } = confirmReorderLocationRef;
    const shouldStartReorder = checkIfShouldStartReorder({
      activeCart,
      activeRestaurant,
    });

    if (!shouldStartReorder) return;

    logger.info('Start Reorder from Menu', activeCart, activeRestaurant);

    track('reorder.start.from_menu', {
      bagRestaurantId: activeCart.restaurantId,
      bagOrderType: activeCart.orderType,
      menuRestaurantId: activeRestaurant?.id,
      menuOrderType: activeRestaurant?.isDelivery
        ? 'delivery'
        : 'pickup-or-outpost',
    });

    // ─────────────────────────────────────────────────────────────────

    const isActiveOrderTypeIsDelivery = activeRestaurant?.isDelivery;

    const baseReorderDetails = getBaseReorderDetails({
      activeCart,
      activeRestaurant,
    });
    const reorderDetails = isActiveOrderTypeIsDelivery
      ? getDeliveryReorderDetails(activeRestaurant, activeDeliveryDetails)
      : getPickupOutpostReorderDetails(activeRestaurant);

    // ─────────────────────────────────────────────────────────────────

    if (!reorderDetails) return;

    confirmReorderLocation({
      ...baseReorderDetails,
      ...reorderDetails,
    });
  }, [
    activeCart,
    activeDeliveryDetails,
    activeRestaurant,
    confirmReorderLocationRef,
    track,
  ]);

  // ─── Effects ─────────────────────────────────────────────────────

  useFocusEffect(startReorderFlowIfNecessary);
};

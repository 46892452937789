import type { ComponentProps } from 'react';
import React, { useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Container,
  Icon,
  LoadingPlaceholder,
  PressableLink,
  theme,
  usePressableState,
  useResponsive,
  webOnlyStyles,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const ReorderRailHeader = (props: RailHeaderProps) => {
  const { hasOrders, isLoading } = props;
  const { match } = useResponsive();
  const { t } = useLocalizationContext();

  const headerTextSize: ComponentProps<typeof BodyText>['size'] = match([
    4, 3, 2,
  ]);

  // ────────────────────────────────────────────────────────────────────

  return (
    <Container style={styles.wrapper}>
      <BodyText size={headerTextSize} style={styles.title}>
        {t('home.reorder-rail-title')}
      </BodyText>

      <RailHeaderAllOrdersLink
        hasOrders={hasOrders}
        isLoading={isLoading}
        fontSize={headerTextSize}
      />
    </Container>
  );
};

const RailHeaderAllOrdersLink = (props: RailHeaderAllOrdersLinkProps) => {
  const { fontSize, isLoading, hasOrders } = props;

  if (isLoading) {
    return (
      <View style={styles.allOrdersLinkPlaceholder}>
        <LoadingPlaceholder
          palette="lightGreen"
          rowHeight={24}
          borderRadius={theme.spacing['4']}
        />
      </View>
    );
  }

  if (!hasOrders) return null;

  return <RailHeaderAllOrdersPressableLink fontSize={fontSize} />;
};

const RailHeaderAllOrdersPressableLink = (
  props: Pick<RailHeaderAllOrdersLinkProps, 'fontSize'>,
) => {
  const { fontSize } = props;

  const { t } = useLocalizationContext();
  const pressableRef = useRef(null);
  const { isHovered } = usePressableState(pressableRef);

  const allOrdersLinkStyles = [
    allOrdersLinkWebStyles,
    isHovered ? styles.allOrdersLinkHover : undefined,
  ];

  return (
    <PressableLink
      ref={pressableRef}
      style={allOrdersLinkStyles}
      to="/account/orders"
    >
      <View style={styles.allOrdersLinkInnerContent}>
        <BodyText size={fontSize} style={styles.allOrdersLinkText}>
          {t('home.reorder-rail-all-orders')}
        </BodyText>

        <Icon name="IconArrowRight" />
      </View>
    </PressableLink>
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────────

type RailHeaderProps = Readonly<{
  hasOrders?: boolean;
  isLoading: boolean;
}>;

type RailHeaderAllOrdersLinkProps = Readonly<{
  hasOrders?: boolean;
  fontSize: ComponentProps<typeof BodyText>['size'];
  isLoading?: boolean;
}>;

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing['4'],
  },
  title: {
    textTransform: 'uppercase',
  },
  allOrdersLinkHover: {
    opacity: 0.6,
  },
  allOrdersLinkInnerContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  allOrdersLinkPlaceholder: {
    width: 124,
  },
  allOrdersLinkText: {
    paddingRight: theme.spacing['1'],
  },
});

const allOrdersLinkWebStyles = webOnlyStyles({
  transition: `opacity ${theme.transitions.base}ms`,
});

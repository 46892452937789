import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  StyleSheet,
  type TextProps,
  View,
  type ViewProps,
  type ViewStyle,
} from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';
import { BodyText, Collapsible, TitleText } from '@sg/garnish';

import { LoyaltyIconSgReward } from '../../LoyaltyIconSgReward';

const LoyaltyHomeProgressBenefitXs = (
  props: LoyaltyHomeProgressBenefitPropsXs & ViewProps,
) => {
  const {
    progressBenefit,
    benefitsCount,
    palette,
    children,
    onBenefitsOpened,
  } = props;

  const minHeight = useStyle(
    () => ({
      minHeight: theme.spacing['15'] * benefitsCount,
    }),
    [benefitsCount],
  );

  const handleOnChange = useCallback(
    (expanded: boolean) => {
      if (expanded) onBenefitsOpened();
    },
    [onBenefitsOpened],
  );

  return (
    <Collapsible
      onChange={handleOnChange}
      options={{
        summaryStyle: [styles.summaryContainer, summaryPalettes[palette]],
        summaryStyleExpanded: styles.summaryContainerExpanded,
        iconName: 'IconCaretDown',
        iconColor: theme.colors.WHITE,
        iconStrokeWidth: 1.5,
      }}
    >
      <Collapsible.Summary>
        <View style={styles.summaryContent}>
          <LoyaltyIconSgReward palette="white-outline" />

          <View>
            <BodyText style={styles.benefitLabel} sizeMatch={['14']} bold>
              <FormattedMessage {...messages.progressBenefitTitle} />
            </BodyText>

            <BodyText style={styles.benefitLabel} sizeMatch={['14']}>
              {progressBenefit}
            </BodyText>
          </View>
        </View>
      </Collapsible.Summary>

      <Collapsible.Details style={minHeight}>{children}</Collapsible.Details>
    </Collapsible>
  );
};

const LoyaltyHomeProgressBenefitSm = (
  props: LoyaltyHomeProgressBenefitPropsSm,
) => {
  const { progressBenefit, style } = props;

  return (
    <TitleText sizeMatch={['24']} style={[styles.progressBenefitSm, style]}>
      {progressBenefit}
    </TitleText>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  progressBenefitTitle: {
    defaultMessage: 'Order to earn',
    description: 'Loyalty | Home | Progress Benefit | Title',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  summaryContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: theme.radius.medium,
    padding: theme.spacing['4'],
    marginTop: theme.spacing['4'],
    marginHorizontal: theme.spacing['4'],
  },
  summaryDark: {
    backgroundColor: theme.colors.DARK_KALE,
    borderColor: theme.colors.WHITE,
  },
  summaryLight: {
    backgroundColor: theme.colors.BASIL,
    borderColor: theme.colors.KALE,
  },
  summaryContainerExpanded: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  summaryContent: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['2'],
  },
  loyaltyInfoBenefits: {
    marginTop: 0,
    borderTopWidth: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  benefitLabel: {
    color: theme.colors.WHITE,
  },
  progressBenefitSm: {
    color: theme.colors.KALE,
    textAlign: 'center',
    marginBottom: theme.spacing['16'],
  },
});

// ─── Palettes ───────────────────────────────────────────────────────────────

const summaryPalettes: Record<
  LoyaltyHomeProgressBenefitPropsXs['palette'],
  ViewStyle
> = {
  dark: styles.summaryDark,
  light: styles.summaryLight,
};

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyHomeProgressBenefitPropsSm = {
  progressBenefit: string;
  style?: TextProps['style'];
};

type LoyaltyHomeProgressBenefitPropsXs = ViewProps & {
  progressBenefit: string;
  benefitsCount: number;
  palette: 'dark' | 'light';
  onBenefitsOpened: () => void;
};

// ─── Compound Components ─────────────────────────────────────────────────────

export const LoyaltyHomeProgressBenefit = {
  BenefitsStyle: styles.loyaltyInfoBenefits,
  Xs: LoyaltyHomeProgressBenefitXs,
  Sm: LoyaltyHomeProgressBenefitSm,
};

import {
  resetCheckoutFieldValidationState,
  setCheckoutFieldsValidationState,
} from './checkoutFieldsValidation.helpers';
import { checkoutFieldsValidationModel } from './checkoutFieldsValidation.model';

// ─── Machine ────────────────────────────────────────────────────────────────────

/**
 * This machine encapsulates the validation state of necessary checkout fields
 * and offers the corresponding helpers for interacting with it.
 */

export const checkoutFieldsValidationMachine =
  checkoutFieldsValidationModel.createMachine({
    predictableActionArguments: true, // https://xstate.js.org/docs/guides/actions.html
    context: checkoutFieldsValidationModel.initialContext,
    on: {
      VALIDATE: {
        actions: checkoutFieldsValidationModel.assign(
          (currentState, { payload }) =>
            setCheckoutFieldsValidationState(currentState, payload),
          'VALIDATE',
        ),
      },
      RESET_VALIDATION: {
        actions: checkoutFieldsValidationModel.assign(
          (currentState, { fieldName }) =>
            resetCheckoutFieldValidationState(currentState, fieldName),
          'RESET_VALIDATION',
        ),
      },
    },
  });

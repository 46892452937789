import type { AddressType } from '@sg/garnish';

import type { Order } from '@order/graphql';

/**
 * The `outpost` type is derived from the order restaurant.
 */
export const getOrderType = (order?: Order): AddressType => {
  if (order?.restaurant?.isOutpost) return 'outpost';

  return (order?.orderType?.toLowerCase() ?? 'pickup') as AddressType;
};

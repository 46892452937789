import React, { type ComponentProps } from 'react';
import { BodyText } from '@sg/garnish';

export const HowItWorksCardText = (props: HowItWorksCardTextProps) => {
  const { children, style } = props;
  return (
    <BodyText sizeMatch={['22']} style={style}>
      {children}
    </BodyText>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type HowItWorksCardTextProps = ComponentProps<typeof BodyText>;

import React from 'react';
import { View } from 'react-native';

import { JumpNavigationContext } from './JumpNavigation.context';
import type { JumpTargetProps } from './JumpNavigation.types';

export const JumpTarget = ({ id, children }: JumpTargetProps) => {
  const jumpContext = React.useContext(JumpNavigationContext);
  const { registerJumpTarget, unregisterJumpTarget } = jumpContext;
  const target = React.useRef(null);

  React.useEffect(() => {
    registerJumpTarget(id, target);

    return () => {
      unregisterJumpTarget(id);
    };
  }, [id, registerJumpTarget, unregisterJumpTarget]);

  return <View ref={target}>{children}</View>;
};

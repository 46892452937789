import type { ComponentProps } from 'react';
import React from 'react';

import { useResponsive } from '../../hooks';
import type { HorizontalScrollRail } from '../HorizontalScrollRail';
import type { LoyaltyChallenge } from '../LoyaltyChallengeCard/LoyaltyChallengeCard.types';
import type {
  LoyaltyChallengeCardsRailBaseProps,
  LoyaltyChallengeCardsRailEmptyStateProps,
} from './subcomponents';
import { LoyaltyChallengesEmptyState } from './subcomponents';
import { LoyaltyChallengeCardItem } from './subcomponents';
import { LoyaltyChallengeCardsRailBase } from './subcomponents';

export const LoyaltyChallengeCardsRail = (
  props: LoyaltyChallengeCardsRailProps,
) => {
  const {
    isLoading,
    headerText,
    headerVariation,
    withoutHeaderBorder,
    subtitle,
    challenges,
    emptyStateDescription,
    emptyStateImage,
  } = props;

  if (isLoading) {
    return (
      <LoyaltyChallengeCardsRailLoading
        headerText={headerText}
        headerVariation={headerVariation}
        withoutHeaderBorder={withoutHeaderBorder}
        subtitle={subtitle}
      />
    );
  }

  if (challenges.length === 0) {
    return (
      <LoyaltyChallengesEmptyState
        {...props}
        image={emptyStateImage}
        description={emptyStateDescription}
      />
    );
  }

  return <LoyaltyChallengeCardsRailFulfilled {...props} />;
};

const LoyaltyChallengeCardsRailLoading = (
  props: LoyaltyChallengeCardsRailBaseProps,
) => {
  return (
    <LoyaltyChallengeCardsRailBase
      scrollEnabled={false}
      showNavControls={false}
      {...props}
    >
      <LoyaltyChallengeCardItem isLoading={true} />
      <LoyaltyChallengeCardItem isLoading={true} />
      <LoyaltyChallengeCardItem isLoading={true} />
    </LoyaltyChallengeCardsRailBase>
  );
};

const LoyaltyChallengeCardsRailFulfilled = (
  props: Omit<LoyaltyChallengeCardsRailProps, 'isLoading'>,
) => {
  const {
    availableCount,
    headerText,
    footerText,
    challenges,
    badgeTexts,
    onStartChallenge,
    onPressTermsAndConditions,
    terms,
    deadlinePrefixText,
    completionText,
    applyText,
    termsUrl,
    ...restProps
  } = props;

  const { minWidth } = useResponsive();

  return (
    <LoyaltyChallengeCardsRailBase
      headerText={headerText}
      footerText={footerText}
      showNavControls={minWidth.isSM}
      count={availableCount}
      {...restProps}
    >
      {challenges.map((challenge) => {
        const challengeTheme = challenge.theme ?? 'DEFAULT';

        return (
          <LoyaltyChallengeCardItem
            key={challenge.id}
            isLoading={false}
            challenge={challenge}
            termsUrl={termsUrl}
            badgeText={badgeTexts[challengeTheme] ?? badgeTexts.DEFAULT}
            completionText={completionText}
            deadlinePrefixText={deadlinePrefixText}
            onStartChallenge={onStartChallenge}
            onPressTermsAndConditions={onPressTermsAndConditions}
            terms={terms}
            applyText={applyText}
          />
        );
      })}
    </LoyaltyChallengeCardsRailBase>
  );
};

// ─── TYPES ─────────────────────────────────────────────────────────────────

export type LoyaltyChallengeCardsRailProps = Readonly<{
  availableCount?: number;
  isLoading?: boolean;
  testID?: string;
  terms: string;
  termsUrl: string;
  badgeTexts: Record<NonNullable<LoyaltyChallenge['theme']>, string>;
  deadlinePrefixText: string;
  completionText: string;
  applyText: string;
  emptyStateImage: LoyaltyChallengeCardsRailEmptyStateProps['image'];
  emptyStateDescription: LoyaltyChallengeCardsRailEmptyStateProps['description'];
  challenges: ReadonlyArray<
    Omit<LoyaltyChallenge, 'onStartChallenge' | 'terms' | 'applyText'>
  >;
}> &
  Pick<LoyaltyChallengeCardsRailBaseProps, 'headerText' | 'footerText'> &
  Pick<LoyaltyChallenge, 'onStartChallenge' | 'onPressTermsAndConditions'> &
  Pick<
    ComponentProps<typeof HorizontalScrollRail>,
    'headerVariation' | 'withoutHeaderBorder' | 'subtitle'
  >;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CategoryUpsellsQueryVariables = Types.Exact<{
  restaurantId: Types.Scalars['String']['input'];
  costChannel?: Types.InputMaybe<Types.CostChannel>;
}>;

export type CategoryUpsellsQuery = {
  readonly __typename?: 'Query';
  readonly categoryUpsells: ReadonlyArray<{
    readonly __typename?: 'UpsellCategory';
    readonly id: string;
    readonly name: string;
    readonly categoryRanking: number;
    readonly products: ReadonlyArray<{
      readonly __typename?: 'UpsellCategoryProduct';
      readonly id: string;
      readonly name: string;
      readonly cost: number;
      readonly channelCost: number;
      readonly imageUrl: string;
      readonly outOfStock: boolean;
      readonly menuId: number;
    }>;
  }>;
};

export const CategoryUpsellsDocument = gql`
  query CategoryUpsells($restaurantId: String!, $costChannel: CostChannel) {
    categoryUpsells(restaurantId: $restaurantId) {
      id
      name
      categoryRanking
      products {
        id
        name
        cost
        channelCost(costChannel: $costChannel)
        imageUrl
        outOfStock
        menuId
      }
    }
  }
`;

export function useCategoryUpsellsQuery(
  options: Omit<Urql.UseQueryArgs<CategoryUpsellsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CategoryUpsellsQuery, CategoryUpsellsQueryVariables>({
    query: CategoryUpsellsDocument,
    ...options,
  });
}

import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { BodyText, Switch } from '@sg/garnish';

import { FeatureFlagButton } from '../FeatureFlagButton';

// ─────────────────────────────────────────────────────────────────────────────

export const FeatureFlagToggleButton = (
  props: FeatureFlagToggleButtonProps,
) => {
  const { name, onChange, value, isOverwritten } = props;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onPress = useCallback(() => {
    onChange(!value);
  }, [onChange, value]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <FeatureFlagButton name={name} onPress={onPress} isSelected={value}>
      <Switch accessibilityLabel={name} value={value} onChange={onChange} />
      <BodyText bold={isOverwritten} style={styles.name}>
        {name}
      </BodyText>
    </FeatureFlagButton>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  name: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type FeatureFlagToggleButtonProps = {
  name: string;
  onChange: (value: boolean) => void;
  value: boolean;
  isOverwritten: boolean;
};

import type { ReactNode } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const BenefitCardContainer = (props: BenefitCardImgProps) => {
  const { children, isUnavailable } = props;

  const { match } = useResponsive();

  const containerResponsiveStyles = match([
    styles.containerXS,
    styles.containerSM,
    styles.containerMD,
  ]);
  const containerStyles = [
    styles.container,
    containerResponsiveStyles,
    isUnavailable ? styles.containerUnavailable : undefined,
  ];

  return <View style={containerStyles}>{children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  containerUnavailable: {
    opacity: 0.64,
  },
  containerXS: {
    flexDirection: 'row',
    paddingVertical: theme.spacing['4'],
  },
  containerSM: {
    paddingVertical: theme.spacing['8'],
    paddingHorizontal: theme.spacing['4'],
  },
  containerMD: {
    padding: theme.spacing['8'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BenefitCardImgProps = Readonly<{
  children: ReactNode;
  isUnavailable?: boolean;
}>;

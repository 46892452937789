import {
  type AuthConfig,
  authExchange as urqlAuthExchange,
} from '@urql/exchange-auth';
import { type Exchange } from 'urql';

import { AuthStorage } from '@order/AuthMachine';
import {
  AZURE_API_ACCESS_TOKEN_HEADER,
  refreshHybridFlowApiAccessToken,
} from '@order/AzureAuth';

/**
 * Urql Auth Exchange
 *
 * Reads from storage and adds authorization headers to the outgoing request.
 *
 * @see {@link: https://formidable.com/open-source/urql/docs/advanced/authentication/}
 * @see {@link: https://formidable.com/open-source/urql/docs/api/auth-exchange/}
 */
// @ts-expect-error TS(2322): Type 'import("/Users/gregorywestneat/projects/work... Remove this comment to see the full error message
export const authExchange: Exchange = urqlAuthExchange(
  async (utils): Promise<AuthConfig> => {
    const authState = await getAuthState();

    /**
     * Mutable token references that should store fresh tokens.
     *
     * @see {@link https://commerce.nearform.com/open-source/urql/docs/advanced/authentication/#configuring-refreshauth-triggered-after-an-auth-error-has-occurred}
     */

    let csrfToken = authState?.csrfToken;
    let azureAdAccessToken = authState?.azureAdAccessToken;

    // ─────────────────────────────────────────────────────────────────────

    return {
      /**
       * Appends corresponding authentication header/s to the outgoing request.
       */
      addAuthToOperation(operation) {
        const csrfTokenHeader = csrfToken
          ? { [CSRF_HEADER_KEY]: csrfToken }
          : undefined;
        const azureAdAccessTokenHeader = azureAdAccessToken
          ? { [AZURE_API_ACCESS_TOKEN_HEADER]: azureAdAccessToken }
          : undefined;

        return utils.appendHeaders(operation, {
          ...csrfTokenHeader,
          ...azureAdAccessTokenHeader,
        });
      },

      /**
       * Runs before a network request is made.
       *
       * Returning `true` forces the `refreshAuth` to re-read from storage
       * every request (assumes that the logout workflow clears/updates the token from storage)
       */
      willAuthError() {
        return true;
      },

      /**
       * Gets fresh auth state from the async storage and assigns it to the
       * mutable reference.
       */
      async refreshAuth() {
        const updatedAuthState = await getAuthState();

        csrfToken = updatedAuthState?.csrfToken;
        azureAdAccessToken = updatedAuthState?.azureAdAccessToken;
      },

      /**
       * We set `didAuthError` to a no-op because it's required by the `AuthConfig`.
       */
      didAuthError() {
        return false;
      },
    };
  },
);

// ─── Helpers ─────────────────────────────────────────────────────────────────

async function getAuthState(): Promise<AuthState> {
  try {
    const csrfToken = await AuthStorage.getToken();
    const azureAdAccessToken = await refreshHybridFlowApiAccessToken();

    return {
      csrfToken,
      azureAdAccessToken,
    };
  } catch {
    return {};
  }
}

// ─── Constants ───────────────────────────────────────────────────────────────

// For outgoing GraphQL API requests.
const CSRF_HEADER_KEY = 'x-csrf-token';

// ─── Types ───────────────────────────────────────────────────────────────────

type AuthState = {
  csrfToken?: string | null;
  azureAdAccessToken?: string | null;
};

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import type { ScrollView, ScrollViewProps } from 'react-native';
import { Animated } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { theme } from '@garnish/constants';

import { HeaderShadow, HeaderTitle } from './subcomponents';

// ────────────────────────────────────────────────────────────────────────────────

export const ScrollViewWithHeaderTitleXS = React.forwardRef<
  ScrollView,
  ScrollViewWithHeaderTitleXsProps
>((props, ref) => {
  const {
    fadeOffset = DEFAULT_FADE_OFFSET,
    fadeDistance = DEFAULT_FADE_DISTANCE,
    isHeaderAlwaysVisible,
    borderBottomColor = theme.colors.GRAY,
    children,
    onScroll,
    testID = 'sg-scrollview.header.scroll',
    ...scrollViewProps
  } = props;

  const navigation = useNavigation();

  // ─── Animated Styles ─────────────────────────────────────────────

  const animatedScrollValueStart = fadeOffset;
  const animatedScrollValueEnd = fadeOffset + fadeDistance;

  const { current: animatedScrollValue } = useRef(
    new Animated.Value(animatedScrollValueStart),
  );

  const fadeStyle = useMemo(
    () => ({
      borderBottomColor,
      opacity: isHeaderAlwaysVisible
        ? 1
        : animatedScrollValue.interpolate({
            inputRange: [animatedScrollValueStart, animatedScrollValueEnd],
            outputRange: [0, 1],
            extrapolate: 'clamp',
          }),
    }),
    [
      borderBottomColor,
      isHeaderAlwaysVisible,
      animatedScrollValue,
      animatedScrollValueStart,
      animatedScrollValueEnd,
    ],
  );

  // ─────────────────────────────────────────────────────────────────

  const renderHeaderTitle = useCallback(
    (headerTitleProps: HeaderTitleProps) => (
      <Animated.View style={fadeStyle}>
        <HeaderTitle {...headerTitleProps} />
      </Animated.View>
    ),
    [fadeStyle],
  );

  useEffect(() => {
    navigation.setOptions({ headerTitle: renderHeaderTitle });
  }, [fadeStyle, navigation, renderHeaderTitle]);

  // ─── Helpers ─────────────────────────────────────────────────────

  const handleOnScroll = useMemo(
    () =>
      Animated.event(
        [{ nativeEvent: { contentOffset: { y: animatedScrollValue } } }],
        { useNativeDriver: true, listener: onScroll },
      ),
    [animatedScrollValue, onScroll],
  );

  // ─────────────────────────────────────────────────────────────────

  return (
    <>
      <Animated.ScrollView
        ref={ref}
        scrollEventThrottle={16}
        onScroll={handleOnScroll}
        testID={testID}
        {...scrollViewProps}
      >
        {children}
      </Animated.ScrollView>

      <HeaderShadow style={fadeStyle} />
    </>
  );
});

// ─── Types ──────────────────────────────────────────────────────────────────────

type ScrollViewWithHeaderTitleXsProps = Readonly<{
  fadeOffset?: number;
  fadeDistance?: number;
  isHeaderAlwaysVisible?: boolean;
  borderBottomColor?: string;
}> &
  ScrollViewProps;

type HeaderTitleProps = React.ComponentProps<typeof HeaderTitle>;

// ─── Constants ──────────────────────────────────────────────────────────────────

const DEFAULT_FADE_OFFSET = 0;
const DEFAULT_FADE_DISTANCE = 64;

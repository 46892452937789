import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import Animated from 'react-native-reanimated';
import { theme } from '@garnish/constants';

import { Container } from '../../Container';
import type { IconName } from '../../Icon';
import { IconLink } from '../../Icon';
import { BodyText } from '../../Text';

// ────────────────────────────────────────────────────────────────────────────────

export const PageHeader = (props: CollapsedHeaderProps) => {
  const {
    children,
    headingStyle,
    style,
    testID = 'sg-page-header',
    accessibilityHidden,
    leftBtnIconName = 'IconCaretLeft',
    rightBtnIconName = 'IconCaretRight',
    leftBtnAccessibilityLabel,
    rightBtnAccessibilityLabel,
    onLeftBtnPress,
    onRightBtnPress,
  } = props;

  const wrapperStyles = [styles.wrapper, style];
  const headingStyles = [styles.heading, headingStyle];

  return (
    <Animated.View style={wrapperStyles} testID={testID}>
      <Container style={styles.container}>
        <View style={styles.column}>
          {onLeftBtnPress ? (
            <CollapsedHeaderButton
              icon={leftBtnIconName}
              onPress={onLeftBtnPress}
              accessibilityLabel={leftBtnAccessibilityLabel}
              testID={`${testID}.left-btn`}
            />
          ) : null}
        </View>

        <AnimatedBodyText
          size={1}
          style={headingStyles}
          numberOfLines={1}
          // @ts-expect-error TS(2322): Type '{ children: string; size: number; style: ({ ... Remove this comment to see the full error message
          accessibilityHidden={accessibilityHidden}
        >
          {children}
        </AnimatedBodyText>

        <View style={styles.column}>
          {onRightBtnPress ? (
            <CollapsedHeaderButton
              icon={rightBtnIconName}
              onPress={onRightBtnPress}
              accessibilityLabel={rightBtnAccessibilityLabel}
              testID={`${testID}.right-btn`}
            />
          ) : null}
        </View>
      </Container>
    </Animated.View>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const CollapsedHeaderButton = (props: CollapsedHeaderButtonProps) => {
  const { icon, accessibilityLabel, testID, onPress } = props;

  return (
    <IconLink
      name={icon}
      onPress={onPress}
      accessibilityLabel={accessibilityLabel}
      testID={testID}
    />
  );
};

const AnimatedBodyText = Animated.createAnimatedComponent(BodyText);

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    zIndex: theme.zIndex.sticky,
    borderBottomWidth: 1,
    backgroundColor: theme.colors.OATMEAL,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40,
  },
  heading: {
    textAlign: 'center',
    paddingHorizontal: theme.spacing['2'],
  },
  column: {
    minWidth: 30,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type CollapsedHeaderProps = Readonly<{
  style?: ViewProps['style'];
  headingStyle?: ViewProps['style'];
  children: string;
  leftBtnIconName?: IconName;
  rightBtnIconName?: IconName;
  leftBtnAccessibilityLabel?: ViewProps['accessibilityLabel'];
  rightBtnAccessibilityLabel?: ViewProps['accessibilityLabel'];
  onLeftBtnPress?: () => void;
  onRightBtnPress?: () => void;
}> &
  // @ts-expect-error TS(2344): Type '"style" | "testID" | "accessibilityHidden"' ... Remove this comment to see the full error message
  Pick<ViewProps, 'style' | 'testID' | 'accessibilityHidden'>;

export type CollapsedHeaderButtonProps = Readonly<{
  icon: IconName;
  accessibilityLabel?: string;
  onPress?: () => void;
  testID?: string;
}>;

import { useSelector } from '@xstate/react';

import { useGlobalAppState } from '../../GlobalAppState';

// ─── AuthMachine Hook ───────────────────────────────────────────────────────────────────
export const useHasLoggedOut = () => {
  const { authMachineRef } = useGlobalAppState();

  const hasLoggedOut = useSelector(authMachineRef, (state) => {
    const { matches, history } = state;

    return matches('session.loggedOut') && history?.matches('session.loggedIn');
  });

  return Boolean(hasLoggedOut);
};

import type { ComponentProps } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { NotificationFeedbackType } from 'expo-haptics';
import type { LoyaltyChallengeCard } from '@sg/garnish';
import {
  LoyaltyChallengeCardsRail,
  theme,
  triggerHapticNotificationFeedback,
} from '@sg/garnish';

import type { ChallengesQuery } from '@order/graphql';
import { useChallengesTelemetry } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import {
  type SweetpassHomeChallengesRailContent,
  useChallengeOptIn,
} from '../../hooks';
import { checkIfChallengeStarted } from '../../utils';
import { serializeChallenge } from './SweetpassHomeChallengesRail.utils';

export const SweetpassHomeChallengesRail = ({
  content,
  challenges,
  availableCount,
  isFetching,
}: SweetpassHomeChallengesRailProps) => {
  const { t } = useLocalizationContext();
  const { handleChallengesStartTelemetry } = useChallengesTelemetry();

  // ─── Challenge Opt-in Mutation ───────────────────────────────

  const applyChallengeOptInMutation = useChallengeOptIn();

  const { handleChallengesTermsViewTelemetry } = useChallengesTelemetry();

  const [loadingChallengeId, setLoadingChallengeId] = useState<string | null>(
    null,
  );

  // ─────────────────────────────────────────────────────────────────────

  const challengeCardsBadgeTexts = useMemo<Record<LoyaltyCardTheme, string>>(
    () => ({
      DEFAULT: t('sweetpass.home.challenges-rail.badge.new'),
      FLASH: t('sweetpass.home.challenges-rail.badge.flash'),
    }),
    [t],
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onStartChallenge = useCallback(
    async (id: string) => {
      setLoadingChallengeId(id);
      handleChallengesStartTelemetry(id);

      // ─────────────────────────────────────────────────────────────────

      const { data: responseData } = await applyChallengeOptInMutation({
        challengeId: id,
      });

      setLoadingChallengeId(null);

      const isSuccessfullyStartedChallenge =
        checkIfChallengeStarted(responseData);

      // ─── Haptic feedback ─────────────────────────────────────────────

      if (!isSuccessfullyStartedChallenge) {
        await triggerHapticNotificationFeedback(
          NotificationFeedbackType.Warning,
        );

        return;
      }

      await triggerHapticNotificationFeedback(NotificationFeedbackType.Success);
    },
    [handleChallengesStartTelemetry, applyChallengeOptInMutation],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <LoyaltyChallengeCardsRail
        headerText={content.titleText}
        headerVariation="display"
        subtitle={content.bodyText}
        emptyStateDescription={content.emptyContentText}
        emptyStateImage={{ uri: content.emptyContentImage.url }}
        isLoading={isFetching}
        termsUrl="https://www.sweetgreen.com/terms"
        availableCount={availableCount}
        onStartChallenge={onStartChallenge}
        badgeTexts={challengeCardsBadgeTexts}
        deadlinePrefixText={t('sweetpass.home.challenges-rail.deadline-prefix')}
        completionText={t('sweetpass.home.challenges-rail.completion-text')}
        terms={t('general.terms-and-conditions')}
        applyText={t('general.apply').toLowerCase()}
        challenges={
          challenges?.map(serializeChallenge(loadingChallengeId)) ?? []
        }
        onPressTermsAndConditions={handleChallengesTermsViewTelemetry}
      />
    </View>
  );
};

// ––– STYLES –––––––––––––––––––––––––––––

const styles = StyleSheet.create({
  container: {
    marginBottom: theme.spacing['6'],
  },
});

// ––– TYPES –––––––––––––––––––––––––––––

type SweetpassHomeChallengesRailProps = Readonly<{
  content: SweetpassHomeChallengesRailContent;
  challenges: ChallengesQuery['challenges'];
  availableCount: number;
  isFetching: boolean;
}>;

type LoyaltyCardTheme = NonNullable<
  ComponentProps<typeof LoyaltyChallengeCard>['theme']
>;

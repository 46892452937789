/* eslint-disable react-native/no-unused-styles */

import type { ReactNode } from 'react';
import React from 'react';
import type { ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanCardContainer = (props: ScanCardContainerProps) => {
  const { children, palette = 'quinoa', style } = props;

  const containerStyles = [styles.container, paletteStyles[palette], style];

  return <View style={containerStyles}>{children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    borderRadius: theme.radius.large,
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['6'],
  },
});

const paletteStyles = StyleSheet.create<
  Record<ScanCardContainerPalette, ViewStyle>
>({
  quinoa: {
    backgroundColor: theme.colors.QUINOA,
  },
  cucumber: {
    backgroundColor: theme.colors.CUCUMBER,
  },
  kale: {
    backgroundColor: theme.colors.KALE,
  },
  darkGreen: {
    backgroundColor: theme.colors['GREEN_1.5'],
  },
  premium: {
    backgroundColor: theme.colors.OLIVE,
    borderColor: theme.colors.NEUTRAL_6,
    borderWidth: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanCardContainerProps = Readonly<{
  children: ReactNode;
  palette?: ScanCardContainerPalette;
  style?: ViewStyle;
}>;

type ScanCardContainerPalette =
  | 'quinoa'
  | 'cucumber'
  | 'kale'
  | 'darkGreen'
  | 'premium';

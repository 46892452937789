/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ChallengeCelebrationQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ChallengeCelebrationQuery = {
  readonly __typename?: 'Query';
  readonly challengeCelebration: {
    readonly __typename?: 'ChallengeCelebration';
    readonly title: string | null;
    readonly celebrationCopy: string;
    readonly rewardUsageDisclaimer: string;
    readonly expirationDate: string | null;
    readonly challengesIds: ReadonlyArray<string>;
  } | null;
};

export const ChallengeCelebrationDocument = gql`
  query ChallengeCelebration {
    challengeCelebration {
      title
      celebrationCopy
      rewardUsageDisclaimer
      expirationDate
      challengesIds
    }
  }
`;

export function useChallengeCelebrationQuery(
  options?: Omit<
    Urql.UseQueryArgs<ChallengeCelebrationQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ChallengeCelebrationQuery,
    ChallengeCelebrationQueryVariables
  >({ query: ChallengeCelebrationDocument, ...options });
}

import type {
  TelemetryBag,
  TelemetryEntryPoint,
  TelemetryFailure,
  TelemetryGraphQLError,
  TelemetryLocation,
  TelemetryOrder,
  TelemetryReorder,
  TelemetrySelectedProduct,
  TelemetrySelectedRecommendation,
  TelemetrySweetpassSubscription,
} from './TelemetryEvent.types';

type TelemetryEvents = Readonly<{
  // ––– APP –––

  // App
  'app.error-boundary.crash': {
    error: string;
    stack?: string;
    info: { componentStack: string };
  };
  'app.session': {
    userId?: string;
    lastSession?: string;
  };
  'app.first_open': { userId?: string };
  'app.not_found': { path?: string };

  // Used for tracking generic page view for title/url changes (gtm).
  'app.page_view': {
    pageTitle?: string;
    pageLocation?: string;
  };

  // @see /apps/order/src/context/Urql/hooks/useRegisterUrqlErrorTracker/useRegisterUrqlErrorTracker.ts
  'app.graphql_error': TelemetryGraphQLError;

  // Modal
  'modal.close': undefined;

  // ––– AUTH –––

  // Sign In
  'sign-in.view': undefined;
  'sign-in.success': { userId: string };
  'sign-in.failure': TelemetryFailure;

  // Logout
  'logout.success': undefined;
  'logout.failure': TelemetryFailure;

  // Join
  'join.success': {
    name?: string;
    email?: string;
    date?: string;
    referred?: boolean;
  };
  'join.failure': TelemetryFailure;

  // Join or Sign In

  'join-or-sign-in.view': {
    referrer?: string;
  };
  'join-or-sign-in.failure': TelemetryFailure & {
    flow?: 'embedded' | 'hybrid';
    reason?: string;
  };
  'join-or-sign-in.embedded-frame.debug': { data: string };
  'join-or-sign-in.open-email-btn.press': undefined;

  // ––– HOME –––

  // Home
  'home.view': undefined;
  'home.primary_channel': {
    channel?: string;
    locationPrePopulated?: boolean;
  };
  'home.secondary_channel': {
    channel?: string;
    locationPrePopulated?: boolean;
  };
  'home.evergreen_channel': undefined;
  'home.hero_module': undefined;
  'home.cta.unrecognized-order-status': {
    status: string;
    orderType: string;
  };

  'home.hero.cta_tapped': undefined;
  'home.account.cta_tapped': undefined;
  'home.reorder.cta_tapped': undefined;
  'home.bag.cta_tapped': undefined;
  'home.banner.cta_tapped': { destination: string };
  'home.promo.cta_tapped': undefined;
  'home.orderstatus.cta_tapped': undefined;

  // Scan to Pay
  'scan-to-pay.view': undefined;
  'scan-to-pay.shake': undefined;
  'scan-to-pay.apple-wallet.view': undefined;
  'scan-to-pay.apple-wallet.success': { response: string };
  'scan-to-pay.apple-wallet.failure': { systemError: string };

  // Scan In Store
  'scan_viewchallenges_cta.press': undefined;
  'scan_addgiftcard_cta.press': undefined;
  'scan.seerewards.cta_tapped': undefined;
  'seerewards.sgrewards.cta_tapped': undefined;

  // ––– MENU –––

  // Location
  'location.view': undefined;
  'location.search': {
    resultsReturned?: boolean;
    geolocationEnabled?: boolean;
    searchMethod?: 'area-button' | 'search-field';
    displayedChannels?: readonly string[];
  };
  'location.details': {
    locationName?: string;
  };
  'location.channel_changed': {
    channel?: string;
  };
  'location.delivery_unavailable': undefined;
  'location.pin_selected': undefined;
  'location.bottom_sheet': {
    state?: 'collapsed' | 'expanded';
  };
  'location.open_menu': {
    location?: TelemetryLocation;
    bottomSheetOpen?: boolean;
    entryPoint?:
      | 'pin'
      | 'card'
      | 'details'
      | 'delivery-cta'
      | 'location-warning';
  };
  'location.close': undefined;
  'location.mapview.tapped': undefined;
  'location.listview.tapped': undefined;

  // Menu
  'menu.view': {
    restaurant?: TelemetryLocation;
    entryPoint?: string;
  };
  'menu.view_collection': {
    collectionId?: string;
  };
  'menu.jump_nav': {
    id?: string;
    tabName?: string;
  };
  'menu.locations_selected': {
    collectionName?: string;
  };
  'menu.change_location': undefined;
  'menu.quick_add_to_bag': {
    selectedProduct?: TelemetrySelectedProduct;
  };
  'menu.one_click_add_to_bag': undefined;
  'menu.one_click_add_to_bag_submit': {
    selectedProduct?: TelemetrySelectedProduct;
  };
  'menu.one_click_add_to_bag_fail': {
    message?: string;
  };
  'menu.one_click_add_to_bag_quantity_increase': undefined;
  'menu.one_click_add_to_bag_quantity_decrease': undefined;

  'menu.top-down-categories.view': undefined;
  'menu.top-down-categories.back-button-press': undefined;
  'menu.top-down-categories.close-button-press': undefined;
  'menu.top-down-categories.navigate-to-reorder-button-press': undefined;
  'menu.top-down-categories.category-press': {
    categoryName: string;
    categoryIndex: number;
  };
  'menu.top-down-categories.assets-loaded': undefined;

  // Add to Bag
  'add_to_bag.failed': TelemetryFailure;

  // ––– ORDER FLOW –––

  // PDP

  'pdp.view': {
    selectedProduct?: TelemetrySelectedProduct;
    entryPoint?: 'menu' | 'bag';
  };
  'pdp.add_to_bag': TelemetryBag & {
    selectedProduct?: TelemetrySelectedProduct;
  };
  'pdp.close': undefined;
  'pdp.adjust_quantity': {
    quantity?: number;
  };
  '86ingredient_error': {
    restaurant?: string;
    product?: string;
    ingredient?: string;
  };

  'pdp.view.modal.too-few-bases': {
    selectedProduct?: TelemetrySelectedProduct;
  };
  'pdp.view.modal.no-dressing': {
    selectedProduct?: TelemetrySelectedProduct;
  };

  // Modify
  'modify.view': {
    selectedProduct?: TelemetrySelectedProduct;
    entryPoint?: 'pdp' | 'bag';
  };
  'modify.ingredient_added': {
    ingredient?: string;
  };
  'modify.ingredient_removed': {
    ingredient?: string;
  };
  'modify.cancel': undefined;
  'modify.done': undefined;
  'modify.max_modifications': undefined;
  'modify.max_kind_limit_exceeded': { kind?: string };

  // Reorder
  'reorder.start': TelemetryReorder;
  'reorder.start.from_menu': {
    bagRestaurantId?: string;
    bagOrderType?: string;
    menuRestaurantId?: string;
    menuOrderType?: string;
  };
  'reorder.existing_active_bag': undefined;
  'reorder.return_to_bag_selected': undefined;
  'reorder.new_order_selected': undefined;
  'reorder.continue_bag': TelemetryReorder;
  'reorder.change_location': TelemetryReorder;
  'reorder.conflict_review': TelemetryReorder;
  'reorder.cannot_reorder': undefined;
  'reorder.max_delivery': undefined;
  'reorder.dressing_unavailable': undefined;
  'reorder.missing_menu': undefined;
  'reorder.view_menu': undefined;
  'reorder.missing_restaurant_id': undefined;
  'reorder.customize.tapped': undefined;
  'reorder.favorites.tapped': undefined;
  'reorder.addtobag.tapped': undefined;
  'reorder.orders.tab.tapped': undefined;
  'reorder.favorites.tab.tapped': undefined;

  // Order Product Deep Linking
  'deep-linking.continue_pdp_flow': undefined;
  'deep-linking.order_now_flow': undefined;
  'deep-linking.start': undefined;

  // Category Upsells
  'category_upsells.viewed': undefined;
  'category_upsells.continue.pressed': undefined;
  'category_upsells.close.pressed': undefined;
  'category_upsells.one_click_add_to_bag': undefined;
  'category_upsells.one_click_add_to_bag_submit': {
    selectedProduct?: TelemetrySelectedProduct;
  };
  'category_upsells.one_click_add_to_bag_fail': {
    message?: string;
  };

  // Bag
  'bag.view': {
    entryPoint?: string;
  } & TelemetryBag;
  'bag.add_on': {
    selectedProduct?: TelemetrySelectedProduct;
  };
  'bag.add_on.scroll': {
    viewableAddOns?: readonly number[];
  };
  'bag.remove': {
    selectedProduct?: TelemetrySelectedProduct;
  };
  'bag.remove.failure': TelemetryFailure;
  'bag.adjust_quantity': {
    selectedProduct?: TelemetrySelectedProduct;
  };
  'bag.checkout': {
    total?: number;
  };
  'bag.location-name.pressed': undefined;
  'bag.wanted-time.pressed': undefined;
  'bag.wanted-time.changed': undefined;
  'bag.delivery-preferences.pressed': undefined;
  'bag.dropoff-location.changed': undefined;
  'bag.view-menu.pressed': undefined;
  'bag.line-item.pressed': undefined;
  'bag.line-item.quantity.changed': undefined;
  'bag.line-item.removed': undefined;
  'bag.reward-info.pressed': undefined;
  'bag.loyalty-signed-out.pressed': undefined;
  'bag.apply-reward.pressed': undefined;
  'bag.remove-reward.pressed': undefined;
  'bag.apply-reward.failed': TelemetryFailure;
  'bag.promo-code.add.pressed': undefined;
  'bag.utensils-usage.pressed': undefined;
  'bag.continue.pressed': undefined;
  'bag.express-checkout.pressed': undefined;
  'bag.express-checkout.start': { isApplePay: boolean; isGooglePay: boolean };
  'bag.change-payment-method.pressed': undefined;
  'bag.credit.pressed': undefined;
  'bag.payment-method.pressed': undefined;
  'bag.add-payment-method.pressed': undefined;
  'bag.add-payment-method.submitted': undefined;
  'bag.redeem-gift-card.pressed': undefined;
  'bag.redeem-gift-card.submitted': undefined;

  // Checkout
  'checkout.view': {
    entryPoint?: 'bag' | 'join';
  } & TelemetryBag;
  'checkout.time_changed': {
    timeChanged: number;
  };
  'checkout.add_card_success': undefined;
  'checkout.promo_success': undefined;
  'checkout.tip_added': {
    added?: boolean;
    custom?: boolean;
  };
  'checkout.failure': TelemetryFailure;
  'checkout.start': TelemetryBag;
  'checkout.success': {
    order?: TelemetryOrder;
  } & TelemetryBag;
  'checkout.apple-pay': TelemetryBag;
  'checkout.pickup_selector_changed': {
    pickupChanged: string | null;
  };

  // Order Status
  'order_status.view': { entryPoint: string };
  'order_status.contact-courier': { courierPhone: string };
  'order_status.contact-courier-unsupported': undefined;

  // Order Cancel

  'order_cancel.tapped': undefined;
  'order_cancel.confirmed': undefined;
  'order_cancel.success': undefined;
  'order_cancel.async-cancelation.start': undefined;
  'order_cancel.async-cancelation.failure': undefined;
  'order_cancel.failure': { reason: string };

  // Rate Order
  'rate_order.view': { entryPoint?: string };
  'rate_order.success': {
    rating?: number;
    selectedChips?: readonly string[];
  };
  'rate_order.failure': TelemetryFailure;
  'rate_order.in_store.view': undefined;
  'rate_order.in_store.press': undefined;

  // ––– Personalization –––

  'menu.personalization.recommendations.success': {
    numRecommendations?: number;
    recommendations?: readonly TelemetrySelectedRecommendation[];
    cardVariant?: string;
  };
  'menu.personalization.recommendations.failure': TelemetryFailure;
  'menu.personalization.recommendations.selected': {
    recommendation: TelemetrySelectedRecommendation;
    cardVariant: string;
  };
  'menu.personalization.recommendations.missing': {
    missingIngredientIds: readonly string[];
    outOfStockIngredientIds: readonly string[];
    numberOfMissingIngredients: number;
  };

  // ─── Loyalty ─────────────────────────────────────────────────────────

  'loyalty.how_it_works.view': undefined;
  'loyalty.home.view': undefined;
  'loyalty.home.benefits.open': undefined;
  'loyalty.home.points_history.open': undefined;
  'loyalty.home.how_it_works.open': undefined;

  'loyalty.offer.modal.start.cta_tapped': { offerId: string };
  'loyalty.offer.modal.order.cta_tapped': { offerId: string };
  'loyalty.offer.modal.exit.cta_tapped': { activeOfferId: string };
  'loyalty.offer.modal.multiple_swipe': undefined;

  // Sweetpass, see https://sweetgreen.atlassian.net/wiki/spaces/CEL/pages/4608294913/Mixpanel+Events+for+Loyalty
  'sweetpass.view': undefined;

  // Success / failure for home screen content.
  'sweetpass_home.view.success': undefined;
  'sweetpass_home.view.failure': TelemetryFailure;
  'sweetpass_home.hero_stats.success': undefined;
  'sweetpass_home.hero_stats.failure': TelemetryFailure;
  'sweetpass_home.action_card.success': undefined;
  'sweetpass_home.action_card.failure': TelemetryFailure;

  // Actions for home screen.
  'sweetpass_home.action_card.tapped': {
    action: string;
    actionUrl?: string;
  };
  'sweetpass_home.benefits_cta.tapped': undefined;
  'sgrewards.optedout.join.cta_tapped': undefined;

  // Success / failure for benefits screen content.
  'sweetpass_benefits.view.success': undefined;
  'sweetpass_benefits.view.failure': TelemetryFailure;

  // Actions for benefits screen.
  'sweetpass_benefits.membership_cta.tapped': undefined;
  'sweetpass_benefits.faq_cta.tapped': undefined;
  'sweetpass_benefits.upgrade_cta.tapped': undefined;

  // Success / failure for account screen content.
  'sweetpass_account.view.success': undefined;
  'sweetpass_account.view.failure': TelemetryFailure;

  // Actions for account screen.
  'sweetpass_account.billing_history.tapped': undefined;
  'sweetpass_account.opt_in.tapped': undefined;
  'sweetpass_account.upgrade_cta.tapped': undefined;
  'sweetpass_account.cancel_cta.tapped': TelemetrySweetpassSubscription;
  'sweetpass_account.rejoin_cta.tapped': undefined;
  'sweetpass_account.add_payment_cta.tapped': undefined;
  'sweetpass_account.change_cta.tapped': undefined;
  'sweetpass_account.change.successful': undefined;
  'sweetpass_account.change.failure': TelemetryFailure;
  'sweetpass_account.terms_cta.tapped': undefined;

  // Interstitials for account screen.
  'sweetpass_account.billing_history.viewed': undefined;
  'sweetpass_account.cancellation_modal.viewed': undefined;
  'sweetpass_account.review_modal.viewed': undefined;
  'sweetpass_account.terms.viewed': undefined;

  // Request to upgrade sweetpass.
  'sweetpass.checkout.view': TelemetrySweetpassSubscription;
  'sweetpass_account.upgrade.success': TelemetrySweetpassSubscription;
  'sweetpass_account.upgrade.failure': TelemetryFailure;

  // View + Actions for celebration modal.
  'sweetpass_celebration.view.success': undefined;
  'sweetpass_celebration.cta.tapped': undefined;

  // ─── Account | Gift card ─────────────────────────────────────────────

  'account.gift.redeem_form_submit': undefined;
  'account.gift.redeem_success': undefined;
  'account.gift.redeem_failure': { userError?: string; systemError?: string };

  // ─── Sweetpass Upsell CTA ────────────────────────────────────────────

  'sweetpass_upsell_cta.press':
    | {
        ctaLocation:
          | 'Home'
          | 'Order Status'
          | 'Bag | Zero Rewards Rail'
          | 'Bag | Below Rewards Rail'
          | 'Bag | Above Sub-ledger';
      }
    | undefined;

  // Challenges
  'challenges.view': undefined;
  'challenges.reward_details': { rewardId: string };
  'challenges.reward_link': undefined;
  'challenges.reward_selected': { rewardId: string };
  'challenges.my_rewards': undefined;
  'challenges.reward_error': TelemetryFailure;
  'challenges.terms_view': undefined;
  'challenges.start': { challengeId: string };
  'challenges.start_failed': TelemetryFailure;
  'challenges.completed': {
    celebrationCopy?: string;
    challengesIds: readonly string[];
    expirationDate?: string;
    title?: string | null;
  };
  'challenges.closed': undefined;

  // ––– ACCOUNT –––

  // Account
  'account.logged_out_view': undefined;
  'account.view': { screen: string };

  // Profile
  'account.profile_saved': {
    changedFields: readonly string[];
  };
  'account.profile_save_failed': TelemetryFailure;
  'account.profile_request_deletion_tapped': undefined;
  'account.profile_request_deletion_confirmed': undefined;

  // Addresses
  'addresses.add': undefined;
  'addresses.view': undefined;
  'addresses.save': {
    dropoffMethod: string;
    type: string;
  };
  'addresses.remove': undefined;
  'addresses.save_failed': TelemetryFailure;
  'addresses.active_bag': undefined;

  // Payment & Credit
  'payment_credit.add_card': undefined;
  'payment_credit.view_card': undefined;
  'payment_credit.save_card': undefined;
  'payment_credit.remove_card': undefined;
  'payment_credit.save_card_failed': TelemetryFailure;
  'payment_credit.set_default': undefined;
  'payment_credit.view_credit': undefined;
  'payment_credit.view_promo_code': undefined;
  'payment_credit.camera_opened': undefined;

  // Payment & Promo Codes
  'payment_promo.view': TelemetryEntryPoint<string>;
  'payment_promo.entered': TelemetryEntryPoint<string>;
  'payment_promo.apply_attempted': TelemetryEntryPoint<string>;
  'payment_promo.code_applied': TelemetryEntryPoint<string> & {
    codeType: string;
  };
  'payment_promo.codes_failed': TelemetryFailure & TelemetryEntryPoint<string>;

  // Payment & Tipping
  'payment_tip.changed': TelemetryEntryPoint<string> & {
    percentage: string;
    amount: number;
    subtotal: number;
  };

  // Dietary Restrictions
  'dietary-restrictions.view': undefined;
  'dietary-restrictions.select': {
    route?: string;
    restriction?: string;
    selected?: boolean;
  };

  // Contact Us
  contact_us: {
    source: 'account' | 'org-links' | 'order-status' | 'order-history';
    destination: 'kustomer' | 'website';
  };

  // Social Media Links
  social_media_links: {
    link: 'instagram' | 'twitter' | 'tiktok' | 'spotify' | 'facebook';
  };

  // In-App Review
  in_app_review: {
    orderCount?: number;
    featureDisabled?: boolean;
    missingNativeModule?: boolean;
    missingOsSupport?: boolean;
    incorrectOrderCount?: boolean;
    hasLowRatingOrder?: boolean;
    requestedInAppReview?: boolean;
  };

  // Force Update
  'forced_update.viewed': { appVersion: string };
  'forced_update.cta_tapped': { appVersion: string };

  // ─── OTA Update ──────────────────────────────────────────────────────

  'ota_update.apply_update.pressed': {
    updateId?: string;
    targetVersion?: string;
  };
  'ota_update.update_later.pressed': {
    updateId?: string;
    targetVersion?: string;
  };

  // ─── Debug Menu ──────────────────────────────────────────────────────

  'debug-menu.view': { email?: string; isLoggedIn?: boolean };

  // ─── Auth ────────────────────────────────────────────────────────────

  'azure-ad.logout.success': undefined;
  'azure-ad.logout.failure': TelemetryFailure | void;

  // ─── Live Activity ───────────────────────────────────────────────────

  'live_tracking.enabled': undefined;
  'live_tracking.disabled': undefined;
  'live_tracking.not_supported': undefined;
  'live_tracking.press': undefined;
  'live_tracking.start-attempt':
    | { shouldStart: true }
    | {
        shouldStart: false;
        reason:
          | 'DISABLED_FF'
          | 'UNSUPPORTED_OS'
          | 'RESTAURANT_CANNOT_TRACK_ORDER_STATUS'
          | 'INCOMPATIBLE_WANTED_TIME'
          | 'DISABLED_IN_SETTINGS';
      };
  'live_tracking.log_info': { message: string };
  'live_tracking.log_error': { message: string };

  // ─── In-app Gift Cards ───────────────────────────────────────────────

  'gift.jump_nav': undefined;
  'gift.view.success': undefined;
  'gift.view.failure': undefined;
  'gift.filter_tapped': { filterName: string };
  'gift.skin_tapped': { skinID: string; skinURL: string };
  'gift.redeem_cta_tapped': undefined;
  'gift.abandon_modal': undefined;
  'gift.abandon_modal_cta_tapped': undefined;

  'gift.customize_load.success': undefined;
  'gift.customize_load.failure': { error: string };
  'gift.amount_tapped': {
    giftCardAmount?: number;
    isCustomOptionSelected?: boolean;
  };
  'gift.purchase_cta_tapped': undefined;

  'gift.confirmation_success': undefined;
  'gift.confirmation_failure': { error: string };
  'gift.confirmation_copy_cta_pressed': undefined;
  'gift.confirmation_send_cta_pressed': undefined;

  'gift.purchase_success': undefined;
  'gift.purchase_failure': {
    userError?: string;
    systemError?: unknown;
  };

  'gift.card_autopopulated.success': undefined;
  'gift.card_autopopulated.failure': { error: unknown };

  // ─── In-app Gift Cards | Purchase History ────────────────────────────

  'gift.history_viewed': undefined;
  'gift.history_success': undefined;
  'gift.history_failure': { userError?: string; systemError?: unknown };
  'gift.history_cta_tapped': undefined;

  // ─── Tab Bar | Navigation ────────────────────────────────────────────

  'tab-bar.home': undefined;
  'tab-bar.scan': undefined;
  'tab-bar.menu': undefined;
  'tab-bar.loyalty': undefined;
  'tab-bar.account': undefined;
  'tab-bar.gift': undefined;
}>;

// ––––––––––––––––––––––––––––––––––––––––––––––

export type TelemetryEventName = keyof TelemetryEvents;
export type TelemetryEventPayload<Name extends TelemetryEventName> =
  TelemetryEvents[Name];

export type TelemetryEvent<Name extends TelemetryEventName> =
  TelemetryEventPayload<Name> extends undefined
    ? Readonly<{ name: Name; payload?: TelemetryEventPayload<Name> }>
    : Readonly<{ name: Name; payload: TelemetryEventPayload<Name> }>;

export type TelemetryTrackParams<Name extends TelemetryEventName> =
  TelemetryEventPayload<Name> extends undefined
    ? readonly [name: Name, payload?: TelemetryEventPayload<Name>]
    : readonly [name: Name, payload: TelemetryEventPayload<Name>];

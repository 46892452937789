import React, { useCallback, useEffect } from 'react';
import { Modal, useResponsive, useToggleState } from '@sg/garnish';

import type { DeliveryPreferenceType } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { DeliveryPreferencesForm } from '../DeliveryPreferencesForm';
import { DeliveryPreferencesItem } from './DeliveryPreferencesItem';

export const DeliveryPreferencesSelector = (
  props: DeliveryPreferencesSelectorProps,
) => {
  const { preferences, submitting, onSave } = props;

  const { match } = useResponsive();
  const { t } = useLocalizationContext();

  const {
    value: isShowingModal,
    toggleOn: openModal,
    toggleOff: closeModal,
  } = useToggleState();

  const saveForm = useCallback(
    (payload: DeliveryPreferences) => {
      onSave(payload);
    },
    [onSave],
  );

  useEffect(() => {
    if (submitting) return;
    closeModal();
  }, [submitting, closeModal]);

  return (
    <>
      <DeliveryPreferencesItem preferences={preferences} onPress={openModal} />

      <Modal
        animationType="fade"
        size={match(['full', 'small'])}
        accessibilityLabel={t('addresses.preferences.a11y')}
        visible={isShowingModal}
        onRequestClose={closeModal}
      >
        <DeliveryPreferencesForm
          preferences={preferences}
          submitting={submitting}
          onSave={saveForm}
          onClose={closeModal}
        />
      </Modal>
    </>
  );
};

export type DeliveryPreferences = Readonly<{
  type: DeliveryPreferenceType;
  notes?: string;
}>;

type DeliveryPreferencesSelectorProps = Readonly<{
  preferences: DeliveryPreferences;
  submitting?: boolean;
  onSave: (preferences: DeliveryPreferences) => void;
}>;

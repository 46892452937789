import type { ComponentProps } from 'react';
import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { Button } from '../../Button';
import { Icon } from '../../Icon';
import { LinearProgressBar } from '../../LinearProgressBar';
import { BodyText } from '../../Text';
import { ChallengeCardVariants } from '../ChallengeCard.constants';

type VoidCallback = () => void;

type TestProps = Readonly<{
  testID?: string;
}>;

export const ChallengeFooter = ({
  variant,
  completedSteps,
  totalSteps,
  onStartChallenge,
  footerText,
  testID,
}: ChallengeFooterProps) => {
  switch (variant) {
    case ChallengeCardVariants.New: {
      return <FooterNew onPress={onStartChallenge} testID={testID} />;
    }

    case ChallengeCardVariants.Completed: {
      return <FooterCompleted testID={testID} />;
    }

    case ChallengeCardVariants.Ongoing: {
      return (
        <FooterOngoing
          totalSteps={totalSteps}
          completedSteps={completedSteps}
          testID={testID}
          footerText={footerText}
        />
      );
    }

    default: {
      throw new Error(`Unknown Challenge variant: ${variant}`);
    }
  }
};

const Footer = (props: ViewProps) => {
  return <View style={styles.footer}>{props.children}</View>;
};

export const FooterNew = ({
  onPress,
  testID,
  isLoading,
  disabled,
}: Readonly<{
  onPress?: VoidCallback;
}> &
  Pick<ComponentProps<typeof Button>, 'disabled' | 'isLoading'> &
  TestProps) => {
  const label = 'Start challenge';

  return (
    <Footer>
      <Button
        palette="secondary"
        onPress={onPress}
        accessibilityLabel="Start Challenge"
        accessibilityRole="button"
        testID={testID}
        isLoading={isLoading}
        disabled={disabled}
        style={styles.startChallengeBtn}
      >
        <BodyText>{label}</BodyText>
      </Button>
    </Footer>
  );
};

export const FooterOngoing = ({
  totalSteps,
  completedSteps,
  footerText,
  testID,
}: Pick<ChallengeFooterProps, 'completedSteps' | 'totalSteps' | 'footerText'> &
  TestProps) => {
  return (
    <Footer>
      <View style={styles.progressBarContainer} testID={testID}>
        <LinearProgressBar
          progress={(completedSteps / totalSteps) * 100}
          animated
          testID={`${testID}-linear-progress`}
        />

        {footerText ? (
          <BodyText size={4} style={styles.completedInfo}>
            {footerText}
          </BodyText>
        ) : null}
      </View>
    </Footer>
  );
};

export const FooterCompleted = ({ testID }: TestProps) => {
  return (
    <Footer>
      <View style={styles.completedFooter} testID={testID}>
        <Icon name="IconBadgeCheck" width={24} height={24} />
        <BodyText style={styles.completedText}>Challenge completed</BodyText>
      </View>
    </Footer>
  );
};

const styles = StyleSheet.create({
  footer: {
    paddingTop: theme.spacing['4'],
  },
  progressBarContainer: {
    width: '100%',
    minHeight: 6,
    paddingTop: theme.spacing['2'],
  },
  completedInfo: {
    marginTop: theme.spacing['2'],
  },
  completedFooter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  completedText: {
    marginLeft: theme.spacing['2'],
    color: theme.colors.DARK_KALE,
  },
  startChallengeBtn: {
    minWidth: 155,
    alignSelf: 'flex-start',
  },
});

export type ChallengeFooterProps = Readonly<{
  variant: 'New' | 'Ongoing' | 'Completed';
  totalSteps: number;
  completedSteps: number;
  testID?: string;
  footerText?: string;
  onStartChallenge: () => void;
}>;

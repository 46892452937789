import React from 'react';
import type { AddressType } from '@sg/garnish';
import { LocationCard } from '@sg/garnish';

import type { Restaurant } from '@order/graphql';

export const RestaurantLocationCard = ({
  restaurant,
  orderType,
}: RestaurantLocationCardProps) => {
  if (!restaurant) return null;

  return (
    <LocationCard
      id={restaurant.id}
      acceptingOrders={restaurant.isAcceptingOrders}
      notAcceptingOrdersReason={restaurant.notAcceptingOrdersReason}
      restaurantSlug={restaurant.slug}
      address={restaurant.address}
      city={restaurant.city}
      state={restaurant.state}
      zipCode={restaurant.zipCode}
      name={restaurant.name}
      flexMessage={restaurant.flexMessage ?? ''}
      lat={restaurant.latitude}
      lng={restaurant.longitude}
      storeHours={restaurant.hours?.formatted ?? ''}
      imageUrl={orderType === 'outpost' ? undefined : restaurant.asset?.url}
    />
  );
};

export type RestaurantLocationCardProps = Readonly<{
  restaurant?: Pick<
    Restaurant,
    | 'id'
    | 'isAcceptingOrders'
    | 'notAcceptingOrdersReason'
    | 'slug'
    | 'address'
    | 'city'
    | 'state'
    | 'zipCode'
    | 'name'
    | 'flexMessage'
    | 'latitude'
    | 'longitude'
    | 'hours'
    | 'asset'
  >;
  orderType: AddressType;
}>;

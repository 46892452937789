import React from 'react';
import { useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { AddressForm, useAddressHandlers } from '../Address';
import { Modal } from '../Modal';

export const AddressAddModal = (props: AddressAddModalProps) => {
  const { visible, onRequestClose } = props;

  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  const { isAddingAddress, handleOnSave, handleOnEdit, handleOnDelete } =
    useAddressHandlers({ checkExistingBag: true, onClose: onRequestClose });

  return (
    <Modal
      fitHeight
      visible={visible}
      accessibilityLabel={t('addresses.headline.add')}
      presentationStyle="pageSheet"
      size={match(['full', 'small'])}
      onRequestClose={onRequestClose}
    >
      <Modal.Header onClose={onRequestClose}>
        {t('addresses.headline.add')}
      </Modal.Header>

      <AddressForm
        visible={visible}
        loading={isAddingAddress}
        submitCta={t('addresses.save')}
        onSave={handleOnSave}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
      />
    </Modal>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type AddressAddModalProps = Readonly<{
  visible: boolean;
  onRequestClose: () => void;
}>;

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useCart, useChallengesTelemetry, useNewException } from '@order/hooks';

import { useIsBagOpenFromReference } from '../../../components/Bag/hooks';
import {
  type RewardsQuery,
  useRemoveRewardMutation,
  useRewardsQuery,
} from '../GraphQL/Rewards.generated';
import { useApplyReward } from './useApplyReward';

export const useRewards = () => {
  const isLoggedIn = useIsLoggedIn();

  const { handleRewardErrorTelemetry } = useChallengesTelemetry();
  const isBagOpen = useIsBagOpenFromReference();

  const { cart } = useCart();
  const { id: orderId = '', ledger } = cart ?? {};
  const skipRewardsQuery = !isLoggedIn || !isBagOpen || !orderId;

  // ─── Query ───────────────────────────────────────────────────────

  const [rewardsResponse, fetchRewards] = useRewardsQuery({
    requestPolicy: 'network-only',
    pause: true,
  });

  const rewards = rewardsResponse.data?.rewards;
  const isFetchingRewards = rewardsResponse.fetching;
  const rewardsError = rewardsResponse.error;

  // ─── State ───────────────────────────────────────────────────────────

  const cartReward = useCartReward(rewards);
  const [selectedRewardId, setSelectedRewardId] =
    useState<SelectedRewardId>(cartReward);

  const selectedRewardIndex = findSelectedRewardIndex(
    rewards,
    selectedRewardId,
  );

  // ─── Mutations ───────────────────────────────────────────────────────

  const { applyReward, isApplyingReward, applyRewardErrorMessage } =
    useApplyReward(orderId, selectedRewardId);

  const [removeRewardResponse, removeRewardMutation] =
    useRemoveRewardMutation();
  const isRemovingReward = removeRewardResponse.fetching;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const fetchRewardsOnOrderIdChange = useCallback(() => {
    if (skipRewardsQuery) return;
    fetchRewards();
  }, [fetchRewards, skipRewardsQuery]);

  const clearSelectedReward = useCallback(() => {
    setSelectedRewardId('');
  }, []);

  const handleApplyReward = useCallback(async () => {
    if (!selectedRewardId) {
      const cartHasAppliedReward = ledger?.discounts?.length;

      if (cartHasAppliedReward) {
        await removeRewardMutation({ input: { orderId } });
      }

      return { hasApplyRewardFailed: false };
    }

    const response = await applyReward();
    const { errorMessage, hasApplyRewardFailed } = response;

    if (hasApplyRewardFailed) {
      clearSelectedReward();
      fetchRewards();
      handleRewardErrorTelemetry(errorMessage);
    }

    return response;
  }, [
    orderId,
    ledger?.discounts?.length,
    selectedRewardId,
    applyReward,
    clearSelectedReward,
    fetchRewards,
    handleRewardErrorTelemetry,
    removeRewardMutation,
  ]);

  // ─── Effects ─────────────────────────────────────────────────────────

  const {
    exceptionMessage: applyRewardExceptionMessage,
    hasNewException: applyRewardHasNewException,
  } = useNewException(
    applyRewardErrorMessage ? { message: applyRewardErrorMessage } : undefined,
  );

  useEffect(() => {
    setSelectedRewardId((currentSelectedRewardId) => {
      const selectedRewardIsStillAvailable = rewards?.some(
        (reward) => reward.id === currentSelectedRewardId,
      );

      return selectedRewardIsStillAvailable ? currentSelectedRewardId : '';
    });
  }, [rewards, selectedRewardId]);

  useEffect(() => {
    setSelectedRewardId(cartReward);
  }, [cartReward]);

  useEffect(() => {
    if (!isBagOpen) return;
    setSelectedRewardId(cartReward);
  }, [cartReward, isBagOpen]);

  useEffect(fetchRewardsOnOrderIdChange, [fetchRewardsOnOrderIdChange]);

  // ─────────────────────────────────────────────────────────────────

  return useMemo(
    () => ({
      applyRewardExceptionMessage,
      applyRewardHasNewException,
      isApplyingReward,
      isFetchingRewards,
      isRemovingReward,
      rewards,
      rewardsError,
      selectedRewardId,
      selectedRewardIndex,
      applyReward: handleApplyReward,
      clearSelectedReward,
      fetchRewards,
      setSelectedRewardId,
    }),
    [
      applyRewardExceptionMessage,
      applyRewardHasNewException,
      isApplyingReward,
      isFetchingRewards,
      isRemovingReward,
      rewards,
      rewardsError,
      selectedRewardId,
      selectedRewardIndex,
      handleApplyReward,
      clearSelectedReward,
      fetchRewards,
    ],
  );
};

// ─── HOOKS ──────────────────────────────────────────────────────────────────────

const useCartReward = (rewards: Rewards) => {
  const { cart } = useCart();
  const cartDiscounts = cart?.ledger?.discounts ?? [];
  const cartRewardId = rewards?.find((reward) => {
    return cartDiscounts.some((discount) => discount.name === reward.name);
  })?.id;

  return cartRewardId ?? '';
};

// ─── UTILS ──────────────────────────────────────────────────────────────────────

const findSelectedRewardIndex = (
  rewards: Rewards,
  selectedRewardId: string,
) => {
  const selectedRewardIndex =
    rewards?.findIndex((reward) => reward.id === selectedRewardId) ?? -1;

  return selectedRewardIndex >= 0 ? selectedRewardIndex : undefined;
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type Rewards = RewardsQuery['rewards'] | undefined;

type SelectedRewardId = string;

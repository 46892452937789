/* istanbul ignore file */ /* plugin extension code */

import { useCallback, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { useResponsive } from '@sg/garnish';

import { checkIfAccountTabScreen } from '../../../navigation';
import { useKustomerAvailability } from '../hooks/useKustomerAvailability';
import { useKustomerToken } from '../hooks/useKustomerToken';
import {
  useCloseKustomer,
  useLoginKustomer,
  useOpenKustomer,
  useStartKustomer,
  useStopKustomer,
  useStopKustomerOnMobileClose,
} from './kustomer-web-integration';
import {
  addRegionRoleToKustomerFrame,
  useLoadKustomer,
} from './kustomer-web-scripts';

export { useLogoutKustomer } from './kustomer-web-integration';

export const useKustomer = () => {
  const isKustomerEnabled = useKustomerAvailability();
  const kustomerToken = useKustomerToken();

  // ─── State ───────────────────────────────────────────────────────────
  // These states are used to map the JS Kustomer SDK to the React world.

  const [openingKustomer, setOpeningKustomer] = useState(false); // loading indicator
  const [isLoggedInKustomer, setIsLoggedInKustomer] = useState(false); // prevent repeated logins
  const [isKustomerReady, setIsKustomerReady] = useState(false); // only login after sdk is ready

  // ─── Kustomer Integration ────────────────────────────────────────────

  const loadKustomer = useLoadKustomer();
  const startKustomer = useStartKustomer();
  const openKustomer = useOpenKustomer();
  const loginKustomer = useLoginKustomer();
  const stopKustomerOnMobileClose = useStopKustomerOnMobileClose();

  // ─── Callbacks ───────────────────────────────────────────────────────
  // Kustomer will auto open and not resolve if it was previously opened.
  // To prevent getting stuck, we trigger open again on the open callback.

  const onKustomerOpen = useCallback(() => {
    setOpeningKustomer(false);
    addRegionRoleToKustomerFrame();
    stopKustomerOnMobileClose();
    void openKustomer();
  }, [openKustomer, stopKustomerOnMobileClose]);

  const loadAndStartKustomer = useCallback(async () => {
    await loadKustomer();
    await startKustomer();
    setIsKustomerReady(true);
  }, [loadKustomer, startKustomer]);

  const loadAndOpenKustomer = useCallback(async () => {
    setOpeningKustomer(true);
    void loadAndStartKustomer().then(onKustomerOpen);
    void openKustomer().then(onKustomerOpen);
  }, [loadAndStartKustomer, onKustomerOpen, openKustomer]);

  const loginToKustomer = useCallback(() => {
    if (!kustomerToken || isLoggedInKustomer || !isKustomerReady) return;

    (async () => {
      try {
        await loginKustomer({ kustomerToken });
        setIsLoggedInKustomer(true);
      } catch {
        setIsLoggedInKustomer(false);
      }
    })();
  }, [isLoggedInKustomer, kustomerToken, isKustomerReady, loginKustomer]);

  useFocusEffect(loginToKustomer);

  return {
    isKustomerEnabled,
    openingKustomer,
    start: loadAndStartKustomer,
    open: loadAndOpenKustomer,
  };
};

export const usePauseKustomer = () => {
  const isKustomerEnabled = useKustomerAvailability();
  const { currentBreakpoint } = useResponsive();

  const closeKustomer = useCloseKustomer();
  const stopKustomer = useStopKustomer();

  return useCallback(
    (route: string) => {
      const isInAccountTab = checkIfAccountTabScreen(route);
      const shouldRemove =
        !isInAccountTab || currentBreakpoint.isXS || !isKustomerEnabled;

      if (!shouldRemove) return;

      void closeKustomer();
      void stopKustomer();
    },
    [currentBreakpoint.isXS, isKustomerEnabled, closeKustomer, stopKustomer],
  );
};

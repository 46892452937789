import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';

import { logger } from '../../logger';
import { CONFIG_WEB } from '../config';

export const makeStorage = () => {
  logger.debug('initialized storage adapter');

  return makeDefaultStorage(CONFIG_WEB);
};

// ---------------------------------------------------------
// ---- Web platform utils
// ---------------------------------------------------------

import loadScript from 'load-scripts';

import { PLACES_API_CONFIG, PLACES_LIBRARY_URL } from './utils.common';
import type { FetchPredictionsProps } from './utils.types';

/**
 * Gets autocomplete predictions using Google Places Autocomplete API.
 */
export const fetchPredictions = async ({
  input,
  apiKey,
}: FetchPredictionsProps): Promise<google.maps.places.AutocompleteResponse> => {
  const placesApi = window.google?.maps?.places;

  if (!placesApi) {
    await loadScript(`${PLACES_LIBRARY_URL}&key=${apiKey}`);
  }

  const service = new window.google.maps.places.AutocompleteService();

  // Google Places API config:
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  const config = {
    input,
    types: [PLACES_API_CONFIG.type],
    componentRestrictions: { country: PLACES_API_CONFIG.country },
  };
  const data = await service.getPlacePredictions(config);

  return data ?? { predictions: [] };
};

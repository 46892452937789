import React, {
  Children,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';

import { useFluidSize, useReduceMotionStatus } from '../../../hooks';
import { Container, useContainerSpacing } from '../../Container';
import { List, ListItem } from '../../List';
import { useMeasureLayout } from '../hooks';
import type {
  LayoutMeasure,
  LayoutMeasures,
  TabNavBoxProps,
} from '../TabNav.types';
import type { TabNavClonedElement } from '../utils/transformedChildren';
import { getTransformedChildren } from '../utils/transformedChildren';
import { Indicator } from './Indicator';

export const Box = React.memo((props: TabNavBoxProps) => {
  const {
    value,
    children,
    onChange,
    disableAnimation = false,
    ...rest
  } = props;

  const [childMeasures, setChildMeasures] = useState<LayoutMeasures>([]);

  const reduceMotionStatus = useReduceMotionStatus();
  const gridGutter = useFluidSize()(24, 40);
  const containerSpacing = useContainerSpacing();
  const containerStyle = useStyle(
    () => ({ paddingHorizontal: containerSpacing }),
    [containerSpacing],
  );

  const { measures } = useMeasureLayout({
    separator: gridGutter,
    children: childMeasures,
    containerSpacing,
  });

  const flatChildren = useMemo<React.ReactChild[]>(
    () => flattenChildren(children),
    [children],
  );

  // Monitoring Category Switches for horizontal scroll.
  const horizontalRef = React.useRef<ScrollView>(null);

  useEffect(() => {
    const targetItemScrollPosition = getTabItemHorizontalScrollPosition({
      gridGutter,
      itemsMeasurements: childMeasures,
      targetItemIndex: value,
    });
    const scrollOptions = { x: targetItemScrollPosition };

    if (reduceMotionStatus === 'disabled') {
      horizontalRef.current?.scrollTo?.({ ...scrollOptions, animated: true });

      return;
    }

    horizontalRef.current?.scrollTo?.(scrollOptions);
  }, [value, reduceMotionStatus, childMeasures, gridGutter]);

  const handleChildLayout = useCallback((v: LayoutMeasure, i: number) => {
    setChildMeasures((m) => [...m.slice(0, i), v, ...m.slice(i + 1)]);
  }, []);

  return (
    <Container {...rest} style={styles.container}>
      <ScrollView
        ref={horizontalRef}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        horizontal
        contentContainerStyle={containerStyle}
      >
        <List style={styles.listBase}>
          {Children.map(flatChildren, (child, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ListItem style={styles.listItem} key={`list-item${index}`}>
              {index === 0 ? null : <Spacer space={gridGutter} />}

              {getTransformedChildren({
                child: child as TabNavClonedElement,
                onChangeMeasures: handleChildLayout,
                onChange,
                index,
                value,
              })}
            </ListItem>
          ))}
        </List>

        {measures.length > 0 ? (
          <View style={styles.indicatorWrapper}>
            <Indicator
              disableAnimation={disableAnimation}
              currentIndex={value}
              measures={measures}
            />
          </View>
        ) : undefined}
      </ScrollView>
    </Container>
  );
});

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

export const Spacer = ({ space }: Readonly<{ space: number }>) => {
  return <View style={{ width: space }} />;
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
  },
  listBase: {
    flexDirection: 'row',
  },
  listItem: {
    flexDirection: 'row',
  },
  indicatorWrapper: {
    position: 'absolute',
    marginTop: -10,
    left: 0,
    bottom: 0,
  },
});

// ─── UTILS ──────────────────────────────────────────────────────────────────────

export function getTabItemHorizontalScrollPosition(
  props: Readonly<{
    itemsMeasurements: LayoutMeasures;
    targetItemIndex: number;
    gridGutter: number;
  }>,
) {
  const { itemsMeasurements, targetItemIndex, gridGutter } = props;
  const targetItemMeasurements = itemsMeasurements[targetItemIndex];

  if (!targetItemMeasurements) return 0;

  const targetItemScrollPosition = itemsMeasurements.reduce<number>(
    (position, measurement, index) => {
      if (index < targetItemIndex) return position + measurement.width;

      return position;
    },
    0,
  );
  const gridGutterOffset = targetItemIndex * gridGutter;

  return targetItemScrollPosition + gridGutterOffset;
}

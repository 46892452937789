/* eslint-disable functional/immutable-data */

import * as React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import Svg, { Circle } from 'react-native-svg';
import { theme } from '@garnish/constants';

export const AnimatedPin = ({
  size,
  cx,
  cy,
  radius,
  shadow,
  children,
}: ViewProps & AnimatedPinProps) => {
  const animatedSize = useSharedValue(1);
  const shadowSize = useSharedValue(1.1);
  const shadowOpacity = useSharedValue(0);

  React.useEffect(() => {
    animatedSize.value = withRepeat(withTiming(1.1, TIMING_CONFIG), -1, true);
    shadowSize.value = withRepeat(withTiming(1.2, TIMING_CONFIG), -1, true);
    shadowOpacity.value = withRepeat(withTiming(0.2, TIMING_CONFIG), -1, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        { scaleX: animatedSize.value },
        { scaleY: animatedSize.value },
      ],
    };
  });
  const shadowStyle = useAnimatedStyle(() => {
    return {
      opacity: shadowOpacity.value,
      transform: [{ scaleX: shadowSize.value }, { scaleY: shadowSize.value }],
    };
  });

  return (
    <>
      {shadow ? (
        <Animated.View style={[styles.absolute, shadowStyle]}>
          <Svg width={size} height={size} viewBox="0 0 36 46">
            <Circle
              cx={cx}
              cy={cy}
              r={radius}
              stroke={theme.colors.GREEN_1}
              fill={theme.colors.GREEN_1}
              stroke-width="2"
            />
          </Svg>
        </Animated.View>
      ) : null}
      <Animated.View style={animatedStyle}>{children}</Animated.View>
    </>
  );
};

type AnimatedPinProps = Readonly<{
  size: number;
  cx: number;
  cy: number;
  radius: number;
  shadow?: boolean;
}>;

const TIMING_CONFIG = { duration: 1000 };

const styles = StyleSheet.create({
  absolute: {
    position: 'absolute',
  },
});

import React, { useCallback } from 'react';
import { Alert, Linking, StyleSheet, View } from 'react-native';
import type { AddressType } from '@sg/garnish';
import { Button, HighlightWords, IllusDelivery_1, Image } from '@sg/garnish';

import type { CourierDetails } from '@order/graphql';
import { FlattenedOrderStatuses } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

export const CourierInfo = ({
  orderType,
  orderStatus,
  courierName,
  courierPhone,
}: CourierInfoProps) => {
  const { t } = useLocalizationContext();
  const { track } = useTelemetry();

  const handlePress = useCallback(async () => {
    track('order_status.contact-courier', { courierPhone: courierPhone ?? '' });

    const url = `tel:${courierPhone}`;
    const isSupported = await Linking.canOpenURL(url);

    if (!isSupported) {
      track('order_status.contact-courier-unsupported');
      Alert.alert(t('general.error'));

      return;
    }

    await Linking.openURL(url);
  }, [courierPhone, t, track]);

  if (orderType !== 'delivery') return null;

  if (orderStatus !== FlattenedOrderStatuses.Delivering) return null;

  if (!courierName || !courierPhone) return null;

  return (
    <View style={styles.row}>
      <Image source={IllusDelivery_1} style={styles.illustration} />
      <View>
        <View style={styles.courier}>
          <HighlightWords
            size={4}
            text={t('order-status-info.courier', { courierName })}
            highlights={[courierName]}
          />
        </View>
        <Button
          testID="order-status-info.contact-courier"
          accessibilityLabel={t('order-status-info.courier-cta')}
          accessibilityHint={t('order-status-info.courier-cta')}
          accessibilityRole="button"
          onPress={handlePress}
        >
          {t('order-status-info.courier-cta')}
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  courier: {
    flexDirection: 'row',
    width: 180,
    marginBottom: 8,
  },
  illustration: {
    width: 144,
    height: 144,
  },
});

export type CourierInfoProps = Readonly<{
  orderType?: AddressType;
  orderStatus?: FlattenedOrderStatuses;
  courierName?: CourierDetails['name'];
  courierPhone?: CourierDetails['phone'];
}>;

import { logger as LOG } from '@garnish/logger';
import { decode } from 'base-64';
import { fromUnixTime, isPast, subMinutes } from 'date-fns';

/**
 * Checks if the provided access token is expired.
 *
 * NOTE: Will return `true` if the provided access token cannot be parsed or it's missing
 *       valid expiration date.
 *
 * @param params.accessToken      - The JWT access token to check
 * @param params.safeGuardMinutes - Minutes which will be substituted from expiration date
 *                                  to ensure that token won't expire too soon.
 */
export function checkIfAccessTokenHasExpired(
  params: CheckIfAccessTokenHasExpiredParams,
) {
  const { accessToken, safeGuardMinutes = 1 } = params;

  try {
    const parsedTokenPayload = parseTokenPayload(accessToken);
    const expirationDateTimestamp = parsedTokenPayload?.exp;
    const hasValidExpirationDate = typeof expirationDateTimestamp === 'number';

    if (!hasValidExpirationDate) {
      logger.debug(
        `Invalid expiration date (received timestamp: ${expirationDateTimestamp})`,
      );

      return true;
    }

    const normalizedExpirationDate = normalizeTokenExpirationDate(
      expirationDateTimestamp,
      safeGuardMinutes,
    );

    return isPast(normalizedExpirationDate);
  } catch (error) {
    logger.debug(`Failed to check the provided access token (error: ${error})`);

    return true;
  }
}

// ─── Helpers ──────────────────────────────────────────────────────── ─────────

/**
 * @param accessToken - The JWT access token to check
 */
function parseTokenPayload(accessToken: string): Record<string, unknown> {
  const tokenPayload = accessToken.split('.')?.[1];

  const encodedTokenPayload = decode(tokenPayload);

  return JSON.parse(encodedTokenPayload);
}

/**
 * Converts the provided UNIX timestamp to a standard date and subtracts
 * the provided safeguard minutes from it.
 *
 * @param expirationDate   - UNIX timestamp
 * @param safeGuardMinutes - Minutes which will be substituted from expiration date
 *                           to ensure that token won't expire too soon.
 */
function normalizeTokenExpirationDate(
  expirationDate: number,
  safeGuardMinutes: number,
) {
  const normalizedExpirationDate = fromUnixTime(expirationDate);

  return subMinutes(normalizedExpirationDate, safeGuardMinutes);
}

// ─── Logger ──────────────────────────────────────────────────────────────────

LOG.enable('CHECK_IF_ACCESS_TOKEN_HAS_EXPIRED');
const logger = LOG.extend('CHECK_IF_ACCESS_TOKEN_HAS_EXPIRED');

// ─── Types ───────────────────────────────────────────────────────────────────

type CheckIfAccessTokenHasExpiredParams = {
  accessToken: string;
  safeGuardMinutes?: number;
};

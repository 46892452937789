import React from 'react';
import { useIntl } from 'react-intl';

import { type PaymentMethod } from '../../machines/ordering-machine.types';
import { APPLE_PAY, getPaymentInfo, GOOGLE_PAY } from '../../utils';
import {
  BagPaymentMethodInfo,
  BagPaymentMethodLink,
} from './BagPaymentMethodInfo';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * The selected payment method for the bag.
 * Either the selected payment method info or the add new payment method link.
 */
export const BagSelectedPaymentMethod = (
  props: BagSelectedPaymentMethodProps,
) => {
  const {
    paymentMethodId,
    paymentMethods,
    isDisabled,
    requestPaymentMethodChange,
    requestPaymentMethodForm,
  } = props;

  const paymentMethod = paymentMethods.find((pm) => pm.id === paymentMethodId);

  const { formatMessage } = useIntl();

  const paymentInfo = getPaymentInfo({
    paymentMethod,
    paymentMethods,
    isApplePay: paymentMethodId === APPLE_PAY,
    isGooglePay: paymentMethodId === GOOGLE_PAY,
    formatMessage,
  });

  if (paymentInfo) {
    return (
      <BagPaymentMethodInfo
        icon={paymentInfo.icon}
        name={paymentInfo.name}
        isDisabled={isDisabled}
        onPress={requestPaymentMethodChange}
      />
    );
  }

  return (
    <BagPaymentMethodLink
      isDisabled={isDisabled}
      onPress={requestPaymentMethodForm}
    />
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────

type BagSelectedPaymentMethodProps = Readonly<{
  paymentMethodId: string | undefined;
  paymentMethods: readonly PaymentMethod[];
  isDisabled: boolean;
  requestPaymentMethodChange: () => void;
  requestPaymentMethodForm: () => void;
}>;

import {
  OrderAgainConflictReviewCloseButton,
  OrderAgainConflictReviewContainer,
  OrderAgainConflictReviewCTAs,
  OrderAgainConflictReviewImage,
  OrderAgainConflictReviewLineItemDetails,
  OrderAgainConflictReviewText,
} from './components';

export const OrderAgainConflictReview = {
  Container: OrderAgainConflictReviewContainer,
  CloseButton: OrderAgainConflictReviewCloseButton,
  Text: OrderAgainConflictReviewText,
  Image: OrderAgainConflictReviewImage,
  LineItemDetails: OrderAgainConflictReviewLineItemDetails,
  CTAs: OrderAgainConflictReviewCTAs,
};

import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { Footer } from '@expo/html-elements';
import { theme } from '@garnish/constants';

export const ScanAtCheckoutFooter = (props: ScanAtCheckoutFooterProps) => {
  const { children } = props;

  return <Footer style={styles.container}>{children}</Footer>;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanAtCheckoutFooterProps = ComponentProps<typeof Footer>;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors['GREEN_1.5'],
    alignItems: 'center',
    padding: 24,
    gap: 24,
  },
});

import React, { useMemo } from 'react';

import type { SweetpassHomeActionCardItem } from '../../hooks';
import type { SweetpassHomeActionCardLayoutVariantProps } from './SweetpassHomeActionCardLayoutVariantProps';
import { getSweetpassHomeActionCardTheme } from './SweetpassHomeActionCardTheme';
import { SweetpassHomeActionCardLayoutFullBleed } from './Variants/SweetpassHomeActionCardLayoutFullBleed';
import { SweetpassHomeActionCardLayoutRectangleFrame } from './Variants/SweetpassHomeActionCardLayoutRectangleFrame';

export const SweetpassHomeActionCardLayout = (
  props: SweetpassHomeActionCardLayoutProps,
) => {
  const {
    testID: baseTestID,
    layout: layoutName,
    theme: themeName,
    ...rest
  } = props;

  const theme = getSweetpassHomeActionCardTheme(themeName);
  const testID = baseTestID?.concat(`.${layoutName}`);

  const variantProps: SweetpassHomeActionCardLayoutVariantProps = useMemo(
    () => ({
      testID,
      theme,
      ...rest,
    }),
    [testID, theme, rest],
  );

  if (layoutName === 'rectangle_frame') {
    return <SweetpassHomeActionCardLayoutRectangleFrame {...variantProps} />;
  }

  if (layoutName === 'full_bleed') {
    return <SweetpassHomeActionCardLayoutFullBleed {...variantProps} />;
  }

  return null;
};

export type SweetpassHomeActionCardLayoutProps = Omit<
  SweetpassHomeActionCardLayoutVariantProps,
  'theme'
> &
  Pick<SweetpassHomeActionCardItem, 'layout' | 'theme'>;

import { refreshAsync } from 'expo-auth-session';

import { config } from '../../config';

/**
 * Retrieves fresh access tokens for our and vendor's APIs, using the locally stored refresh token.
 *
 * @see https://docs.expo.dev/versions/latest/sdk/auth-session/#authsessionfetchdiscoveryasyncissuer
 * @see https://docs.expo.dev/versions/latest/sdk/auth-session/#authsessionrefreshasyncconfig-discovery
 */
export async function refreshAccessTokens(
  refreshToken: string | undefined,
): RefreshAccessTokensResolveType {
  if (!refreshToken) {
    throw new Error('No refresh token was found');
  }

  const { clientId, scopes, discovery } = config;

  const apiLoginRequestScope = scopes.apiLoginRequest.scopes;
  const vendorApiLoginRequestScope = scopes.vendorApiLoginRequest.scopes;

  // ─── Requests ────────────────────────────────────────────────────────

  const request = { clientId, refreshToken };

  const apiAccessTokenRequest = {
    ...request,
    scopes: apiLoginRequestScope,
  };
  const vendorApiAccessTokenRequest = {
    ...request,
    scopes: vendorApiLoginRequestScope,
  };

  const { accessToken: apiAccessToken } = await refreshAsync(
    apiAccessTokenRequest,
    discovery,
  );
  const { accessToken: vendorApiAccessToken } = await refreshAsync(
    vendorApiAccessTokenRequest,
    discovery,
  );

  if (!apiAccessToken || !vendorApiAccessToken) {
    throw new Error('Failed to retrieve access tokens');
  }

  // ─────────────────────────────────────────────────────────────────────

  return { apiAccessToken, vendorApiAccessToken };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type RefreshAccessTokensResolveType = Promise<{
  apiAccessToken: string;
  vendorApiAccessToken: string;
}>;

import React, { type ComponentProps } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Scrollspy } from '@sg/garnish';

import { messages as globalMessages } from '../../CustomizationIngredientsGrid.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationIngredientsGridNavItem = (
  props: CustomizationIngredientsGridNavItemProps,
) => {
  const { ingredientKindName, ...restProps } = props;

  const { formatMessage } = useIntl();

  return (
    <Scrollspy.NavItem
      accessibilityLabel={formatMessage(messages.a11yLabel, {
        ingredient_kind: ingredientKindName,
      })}
      {...restProps}
    >
      <FormattedMessage
        {...globalMessages.ingredientKind}
        values={{ ingredient_kind: ingredientKindName }}
      />
    </Scrollspy.NavItem>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  a11yLabel: {
    defaultMessage: 'Scroll to "{ingredient_kind}"',
    description:
      'Customization | Ingredients grid | Nav | Nav Item | a11y label',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationIngredientsGridNavItemProps = {
  ingredientKindName: string;
} & Omit<
  ComponentProps<typeof Scrollspy.NavItem>,
  'children' | 'accessibilityLabel'
>;

import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationContainer = (props: ComponentProps<typeof View>) => {
  const { children, style, ...restProps } = props;

  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    match([undefined, styles.containerSM]),
    style,
  ];
  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles} {...restProps}>
      {children}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.QUINOA,
  },
  containerSM: {
    flexDirection: 'row',
  },
});

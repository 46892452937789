import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  DisplayText,
  HStack,
  slugify,
  theme,
  useResponsive,
} from '@sg/garnish';

import type { DeliveryOrderDetailInput, RestaurantQuery } from '@order/graphql';

import { MenuCategoryProduct } from './MenuCategoryProduct';

// ────────────────────────────────────────────────────────────────────────────────

export const MenuCategory = (props: MenuCategoryProps) => {
  const { category, deliveryDetails, restaurantSlug } = props;

  const { match, minWidth } = useResponsive();

  // ─────────────────────────────────────────────────────────────────

  const titleFontSize = minWidth.isSM ? 3 : 2;
  const cardsPerRow = match([1, 2, 3, 4]);

  const headerTestID = slugify(`menupage.${category.name}-category-header`);
  const stackTestID = slugify(`menupage.${category.name}-items`);

  // ─────────────────────────────────────────────────────────────────

  return (
    <>
      <DisplayText size={titleFontSize} testID={headerTestID}>
        {category.name}
      </DisplayText>

      <View style={styles.cardGroup}>
        <HStack itemsPerRow={cardsPerRow} testID={stackTestID}>
          {category.products.map((product) => (
            <MenuCategoryProduct
              key={product.id}
              product={product}
              restaurantSlug={restaurantSlug}
              deliveryDetails={deliveryDetails}
            />
          ))}
        </HStack>
      </View>
    </>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cardGroup: {
    paddingTop: theme.spacing['4'],
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type MenuCategoryProps = Readonly<{
  category: MenuCategories[number];
  restaurantSlug: string;
  deliveryDetails?: DeliveryOrderDetailInput;
}>;

type MenuCategories = NonNullable<
  RestaurantQuery['restaurant']
>['menu']['categories'];

import { useCallback } from 'react';
import { useResponsive } from '@sg/garnish';

import { useNavigateToMainTabs } from '../../../../navigation/hooks/';
import { useCancelReorder } from '../useCancelReorder';
import { useChangeLocation } from '../useChangeLocation';
import { useConfirmLocation } from '../useConfirmLocation';
import { useConflictReview } from '../useConflictReview';
import { useReorderState } from '../useReorderState';

// ─────────────────────────────────────────────────────────────────────────────

export const useNavigationActions = () => {
  const navigateToMainTabs = useNavigateToMainTabs();

  const handleGoHome = useCallback(() => {
    void navigateToMainTabs('HomeTab', { screen: 'Home' });
  }, [navigateToMainTabs]);

  const { currentBreakpoint } = useResponsive();

  // ─── Reordering ──────────────────────────────────────────────────────

  const { currentStep, previousStep } = useReorderState();
  const changeLocation = useChangeLocation();
  const confirmLocation = useConfirmLocation();
  const conflictReview = useConflictReview();
  const cancelReorder = useCancelReorder();

  const isReordering = currentStep !== 'idle';
  const canGoBack = isReordering && Boolean(previousStep);
  const canClose = isReordering || currentBreakpoint.isXS;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onBack = useCallback(() => {
    if (!canGoBack) return;

    if (previousStep === 'locationSearch') changeLocation();

    if (previousStep === 'locationConfirmation') confirmLocation();

    if (previousStep === 'conflictReview') void conflictReview();
  }, [
    canGoBack,
    previousStep,
    changeLocation,
    confirmLocation,
    conflictReview,
  ]);

  const onClose = useCallback(() => {
    if (isReordering) {
      cancelReorder();

      return;
    }

    handleGoHome();
  }, [isReordering, handleGoHome, cancelReorder]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    onBack: canGoBack ? onBack : undefined,
    onClose: canClose ? onClose : undefined,
  };
};

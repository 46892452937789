import { createModel } from 'xstate/lib/model';

export const networkMonitorModel = createModel(
  {},
  {
    events: {
      CONNECT: () => ({}),
      DISCONNECT: () => ({}),
    },
  },
);

export const networkMonitorMachine = networkMonitorModel.createMachine({
  predictableActionArguments: true, // https://xstate.js.org/docs/guides/actions.html
  id: 'sgNetworkMonitorMachine',
  context: networkMonitorModel.initialContext,
  initial: 'unknown',
  states: {
    unknown: {
      on: { CONNECT: 'connected', DISCONNECT: 'disconnected' },
    },
    connected: {
      on: { DISCONNECT: 'disconnected' },
    },
    disconnected: {
      on: { CONNECT: 'connected' },
    },
  },
});

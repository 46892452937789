import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { BodyText, Icon, theme } from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';

import { locationResultsListsV2Messages as messages } from './LocationResultsListsV2ViewMap.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsListsV2ViewMap = (
  props: LocationResultsListsV2ViewMapProps,
) => {
  const { onPress } = props;
  const { track } = useTelemetry();

  const handleOnPress = useCallback(() => {
    onPress?.();

    track('location.mapview.tapped');
  }, [onPress, track]);

  return (
    <TouchableOpacity
      accessibilityRole="button"
      style={styles.container}
      onPress={handleOnPress}
    >
      <Icon name="IconMapPin" />

      <BodyText sizeMatch={['14']}>
        <FormattedMessage {...messages.viewMapButton} />
      </BodyText>
    </TouchableOpacity>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing['1'],
    backgroundColor: theme.colors.LINEN,
    borderRadius: theme.radius.xlarge,
    minHeight: 40,
    paddingVertical: theme.spacing[1],
    paddingHorizontal: theme.spacing[4],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsListsV2ViewMapProps = {
  onPress?: () => void;
};

import React from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const RadioContainerItemSubtitle = (
  props: RadioContainerItemSubtitleProps,
) => {
  const { children, isDisabled } = props;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <BodyText
      sizeMatch={['14']}
      style={isDisabled ? styles.subtitleDisabled : undefined}
    >
      {children}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  subtitleDisabled: {
    color: theme.colors.CHARCOAL,
  },
});
// ─── Types ───────────────────────────────────────────────────────────────────

type RadioContainerItemSubtitleProps = Readonly<{
  children: string;
  isDisabled?: boolean;
}>;

import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { NumberField, theme } from '@sg/garnish';

import type {
  GiftCardCheckoutFormFieldRules,
  GiftCardCheckoutFormReturn,
} from '../../../../../../form';
import { giftCardCheckoutAmountMessages as messages } from '../../GiftCardCheckoutAmount.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutAmountCustomAmount = (
  props: GiftCardCheckoutAmountCustomAmountProps,
) => {
  const { form, rules } = props;
  const { min: minAmount, max: maxAmount } = rules.amount;

  const { formatMessage, formatNumber } = useIntl();

  // ─── Derived Data ────────────────────────────────────────────────────

  const amount = form.watch('amount');
  const { errors, isSubmitting } = form.formState;

  const amountInDollars = amount ? amount / 100 : 0;

  // NOTE: We use split instead of `trailingZeroDisplay` and
  //      `maximumFractionDigits` since they are working on Android.
  const minAmountFormatted = formatNumber(minAmount / 100, {
    currency: 'USD',
    style: 'currency',
  }).split('.')[0];
  const maxAmountFormatted = formatNumber(maxAmount / 100, {
    currency: 'USD',
    style: 'currency',
  }).split('.')[0];

  const customAmountFieldLabel = formatMessage(
    messages.customOptionFieldLabel,
    { min: minAmountFormatted, max: maxAmountFormatted },
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onCustomAmountChange = useCallback(
    async (value: string) => {
      const amountInCents = Number(value) * 100;

      form.setValue('amount', amountInCents);
      await form.trigger('amount');
    },
    [form],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      {/*
        NOTE: We are using a custom field here (not the `Form.NumberField`)
              because we need to convert `string` `amountOptionValue` to
              a `number`.

              React Hook Form internal helpers `valueAsNumber` and
              `setValue` are not working correctly.
      */}
      <NumberField
        autoFocus
        keyboardType="numeric"
        textPrepend="$"
        label={customAmountFieldLabel}
        maxDecimals={0}
        returnKeyType="done"
        notice={errors?.amount?.message}
        invalid={errors?.amount?.message !== undefined}
        value={amountInDollars === 0 ? undefined : amountInDollars}
        disabled={isSubmitting}
        onChangeText={onCustomAmountChange}
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingTop: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutAmountCustomAmountProps = {
  form: GiftCardCheckoutFormReturn;
  rules: GiftCardCheckoutFormFieldRules;
};

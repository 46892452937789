import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  BoundScrollProvider,
  JumpNavigationProvider,
  theme,
  useResponsive,
} from '@sg/garnish';

import type { PartialProduct } from '../../../../graphql/partials';
import { useFilteredIngredientsModifications } from '../../hooks';
import { useProductDetailsScreenContext } from '../../ProductDetailsScreen.provider';
import type { FilteredIngredientModificationsKinds } from '../../types';
import {
  ProductModificationsIngredientsGrid,
  ProductModificationsNav,
} from './subcomponents';

// ────────────────────────────────────────────────────────────────────────────────

export const ProductModifications = (props: ProductModificationsProps) => {
  const { product } = props;
  const ingredientModifications = product?.ingredientModifications;

  const { isCustomizationActive } = useProductDetailsScreenContext();
  const { ingredientsModificationsKinds } = useFilteredIngredientsModifications(
    ingredientModifications,
  );

  if (!isCustomizationActive || !ingredientModifications) return null;

  return (
    <BoundScrollProvider>
      <View style={styles.wrapper} testID="product-modifications">
        <JumpNavigationProvider jumpTargetIds={ingredientsModificationsKinds}>
          {ingredientsModificationsKinds.length > 1 ? (
            <ProductModificationsNav
              ingredientsModificationsKinds={ingredientsModificationsKinds}
            />
          ) : null}

          <ProductModificationsIngredientsGrid
            ingredientsModificationsKinds={
              ingredientsModificationsKinds as FilteredIngredientModificationsKinds
            }
          />
        </JumpNavigationProvider>
      </View>
    </BoundScrollProvider>
  );
};

type ProductModificationsProps = Readonly<{
  product?: PartialProduct | null;
}>;

// ─── RESPONSIVE COMPONENTS ──────────────────────────────────────────────────────

export const ProductModificationsMD = (props: ProductModificationsMDProps) => {
  const { product } = props;
  const { minWidth } = useResponsive();

  if (!minWidth.isMD) return null;

  return <ProductModifications product={product} />;
};

type ProductModificationsMDProps = Readonly<{
  product?: PartialProduct | null;
}>;

export const ProductModificationsXS = () => {
  const { product } = useProductDetailsScreenContext();
  const { minWidth } = useResponsive();

  if (minWidth.isMD) return null;

  // @ts-expect-error TS(2322): Type '{ readonly __typename?: "Product" | undefine... Remove this comment to see the full error message
  return <ProductModifications product={product} />;
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'center',
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
});

import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { List } from '../List';

// ────────────────────────────────────────────────────────────────────────────

export const LedgerContainer = (
  props: ComponentProps<typeof List> & { hasTopBorder?: boolean },
) => {
  const { children, style, hasTopBorder, ...restProps } = props;

  return (
    <List
      style={[styles.ledger, hasTopBorder && styles.ledgerWithTopBorder, style]}
      {...restProps}
    >
      {children}
    </List>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  ledger: {
    paddingTop: theme.spacing['6'],
    paddingBottom: theme.spacing['4'],
    gap: theme.spacing['2'],
  },
  ledgerWithTopBorder: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.TEXT_COLOR,
  },
});

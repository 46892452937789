import React, {
  type ComponentProps,
  type MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  type LayoutChangeEvent,
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  ScrollView,
  type ScrollViewProps,
} from 'react-native';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders a `ScrollView` component with additional props to support "Scrollspy" functionality.
 * Should be used with the relevant `useScrollspy` hook.
 *
 * @example
 * const { helpers } = useScrollspy();
 *
 * return (
 *  <ScrollSpy.ScrollView
 *      isScrollingToTarget={state.scrollView.isScrollingToTarget}
 *      register={helpers.scrollView.register}
 *      deregister={helpers.scrollView.deregister}
 *      trackScroll={helpers.scrollView.trackScroll}
 *      storeSize={helpers.scrollView.storeSize}
 *      storeContentSize={helpers.scrollView.storeContentSize}
 *  >
 *      {props.children}
 *  </ScrollSpy.ScrollView>
 */
export const ScrollspyScrollView = (props: ScrollSpyScrollViewProps) => {
  const {
    children,
    register,
    deregister,
    trackScroll,
    storeSize,
    storeContentSize,
    onScroll,
    onLayout,
    onContentSizeChange,
    ...scrollViewProps
  } = props;

  // ─── Refs ────────────────────────────────────────────────────────────

  const ref = useRef<ScrollView>(null);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnLayout = useCallback(
    (event: LayoutChangeEvent) => {
      storeSize?.(event);
      onLayout?.(event);
    },
    [onLayout, storeSize],
  );

  const handleOnScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      trackScroll?.(event);
      onScroll?.(event);
    },
    [onScroll, trackScroll],
  );

  const handleOnContentSizeChange = useCallback(
    (width: number, height: number) => {
      storeContentSize?.(width, height);
      onContentSizeChange?.(width, height);
    },
    [onContentSizeChange, storeContentSize],
  );

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    register(ref);

    return () => {
      deregister();
    };
  }, [deregister, register]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ScrollView
      ref={ref}
      onScroll={handleOnScroll}
      onLayout={handleOnLayout}
      onContentSizeChange={handleOnContentSizeChange}
      scrollEventThrottle={1}
      {...scrollViewProps}
    >
      {children}
    </ScrollView>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ScrollSpyScrollViewProps = {
  register: (ref: MutableRefObject<ScrollView | null>) => void;
  deregister: () => void;
  trackScroll: ScrollViewProps['onScroll'];
  storeSize: ScrollViewProps['onLayout'];
  storeContentSize: ScrollViewProps['onContentSizeChange'];
} & Omit<ComponentProps<typeof ScrollView>, 'style' | 'ref' | 'horizontal'>;

import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme, VStack } from '@sg/garnish';

import { type ChangeLineItemQuantityParams } from '@order/features/ordering';
import type { PartialLineItem, PartialProduct } from '@order/graphql';
import { telemetryProductFromInput, useTelemetry } from '@order/Telemetry';

import { useNavigateToEditProductDetails } from '../../hooks';
import { BagLineItem } from './BagLineItem';

/**
 * This component is a list of {BagLineItem}.
 * It also binds the navigation callback for when a bag line item is pressed.
 */
export const BagLineItems = (props: BagLineItemsProps) => {
  const {
    lineItems = [],
    restaurantSlug,
    addressId,
    isDelivery,
    isDisabled,
    trackLineItemPressed,
    startChangingLineItemQuantity,
    stopChangingLineItemQuantity,
    changeLineItemQuantity,
  } = props;

  const { track } = useTelemetry();

  const { navigateToEditProductDetails } = useNavigateToEditProductDetails({
    restaurantSlug,
    addressId,
    isDelivery,
  });

  const handleOnPress = useCallback(
    (lineItemId: string, product: PartialProduct) => {
      trackLineItemPressed();
      track('pdp.view', {
        entryPoint: 'bag',
        selectedProduct: telemetryProductFromInput({
          ...product,
          location: [restaurantSlug, addressId].join(' | '),
        }),
      });

      navigateToEditProductDetails({
        lineItemId,
        product,
      });
    },
    [
      restaurantSlug,
      addressId,
      track,
      trackLineItemPressed,
      navigateToEditProductDetails,
    ],
  );

  return (
    <VStack
      hasDivider
      dividerColor={theme.colors.LIGHT}
      gap={theme.spacing['4']}
      style={styles.container}
    >
      {lineItems.map((lineItem) => (
        <View key={lineItem.id} style={styles.content}>
          <BagLineItem
            lineItem={lineItem}
            isDisabled={isDisabled}
            startChangingLineItemQuantity={startChangingLineItemQuantity}
            stopChangingLineItemQuantity={stopChangingLineItemQuantity}
            changeLineItemQuantity={changeLineItemQuantity}
            onPress={handleOnPress}
          />
        </View>
      ))}
    </VStack>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingVertical: theme.spacing['2'],
    marginHorizontal: -theme.spacing['4'],
    borderBottomColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    borderBottomWidth: theme.spacing['2'],
  },
  content: {
    paddingHorizontal: theme.spacing['2'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BagLineItemsProps = {
  lineItems?: readonly PartialLineItem[];
  restaurantSlug?: string;
  addressId?: string;
  isDelivery?: boolean;
  isDisabled: boolean;
  trackLineItemPressed: () => void;
  startChangingLineItemQuantity: () => void;
  stopChangingLineItemQuantity: () => void;
  changeLineItemQuantity: (
    params: ChangeLineItemQuantityParams['params'],
  ) => void;
};

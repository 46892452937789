import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import {
  JumpNavigator,
  TabNav,
  theme,
  useContainerSpacing,
  useJumpNavigationCtx,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type {
  FilteredIngredientModifications,
  FilteredIngredientModificationsKind,
} from '../../../types';
import { ProductModificationsContainer } from './ProductModificationsContainer';

// ────────────────────────────────────────────────────────────────────────────────

export const ProductModificationsNav = (
  props: ProductModificationsNavProps,
) => {
  const { ingredientsModificationsKinds } = props;

  const { t } = useLocalizationContext();
  const { minWidth } = useResponsive();
  const jumpNavigationContext = useJumpNavigationCtx();
  const {
    currentTarget: activeSectionId,
    setCurrentTarget: setActiveSectionId,
  } = jumpNavigationContext;

  // ────────────────────────────────────────────────────────────────────

  const [activeTabInd, setActiveTabInd] = useState(0);
  const onTabChange = useCallback(
    (index: number) => {
      setActiveTabInd(index);
      setActiveSectionId(ingredientsModificationsKinds[index] ?? '');
    },
    [ingredientsModificationsKinds, setActiveSectionId],
  );

  // ────────────────────────────────────────────────────────────────────

  useEffect(() => {
    if (activeSectionId === '') return;

    setActiveTabInd(ingredientsModificationsKinds.indexOf(activeSectionId));
  }, [activeSectionId, ingredientsModificationsKinds, setActiveSectionId]);

  // ────────────────────────────────────────────────────────────────────

  const containerSpacing = useContainerSpacing();
  const tabNavResetStyle = useStyle(
    () => ({ marginHorizontal: -containerSpacing }),
    [containerSpacing],
  );

  // ────────────────────────────────────────────────────────────────────

  if (!minWidth.isMD) return null;

  return (
    <View style={styles.tabsNavWrapper}>
      <JumpNavigator jumpContext={jumpNavigationContext}>
        <ProductModificationsContainer>
          <View style={tabNavResetStyle}>
            <TabNav
              disableAnimation
              value={activeTabInd}
              onChange={onTabChange}
            >
              {ingredientsModificationsKinds.map((kind) => {
                if (!kind) return null;

                const label = t(
                  `pdp.modifications.${
                    kind as keyof FilteredIngredientModifications
                  }`,
                );
                const accessibilityLabel = t('general.scrollTo', {
                  target: label,
                });

                return (
                  <TabNav.NavItem
                    accessibilityLabel={accessibilityLabel}
                    key={kind}
                  >
                    {label}
                  </TabNav.NavItem>
                );
              })}
            </TabNav>
          </View>
        </ProductModificationsContainer>
      </JumpNavigator>
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  tabsNavWrapper: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.OPACITY.DARK_KALE.DARK,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ProductModificationsNavProps = Readonly<{
  ingredientsModificationsKinds: readonly FilteredIngredientModificationsKind[];
}>;

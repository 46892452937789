import { defineMessages } from 'react-intl';

export const giftCardCheckoutDetailsMessages = defineMessages({
  sectionTitle: {
    defaultMessage: 'Details',
    description: 'Gift Card Checkout Form | Details | Section title',
  },
  recipientFieldLabel: {
    defaultMessage: 'Who’s this gift for?',
    description: 'Gift Card Checkout Form | Details | Recipient field label',
  },
  senderFieldLabel: {
    defaultMessage: 'Who’s this gift from?',
    description: 'Gift Card Checkout Form | Details | Sender field label',
  },
  messageFieldLabel: {
    defaultMessage: 'Add a message (optional)',
    description:
      'Gift Card Checkout Form | Details | Optional message field label',
  },
});

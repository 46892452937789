import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

/**
 * We are rendering the Notice Banners both inside and outside the modal at the same time.
 * When we close the modal, we need to dismiss the notice banners so they don't persist in the overlaid screen.
 */
export function useModalClearNoticeBannersOnClose<CloseEvent>(
  onClose?: (event: CloseEvent) => void,
) {
  const { clear: clearNoticeBanners } = useNoticeBannersStackContext();

  return useCallback(
    (event: CloseEvent) => {
      clearNoticeBanners();
      onClose?.(event);
    },
    [clearNoticeBanners, onClose],
  );
}

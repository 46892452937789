import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { BodyText } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoHeading = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <BodyText sizeMatch={['22']} style={styles.heading}>
      {children}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  heading: {
    flex: 1,
    textAlign: 'center',
  },
});

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { IconLink } from '@sg/garnish';

export const HowItWorksHeaderClose = (props: HowItWorksHeaderCloseProps) => {
  const { onPress } = props;
  const { formatMessage } = useIntl();

  return (
    <IconLink
      style={styles.closeButton}
      name="IconClose"
      palette="muted"
      accessibilityLabel={formatMessage(message.closeButtonA11yLabel)}
      width={40}
      height={40}
      onPress={onPress}
    />
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const message = defineMessages({
  closeButtonA11yLabel: {
    defaultMessage: 'Go back',
    description: 'Loyalty | How it works | a11y label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  closeButton: {
    position: 'absolute',
    right: 0,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type HowItWorksHeaderCloseProps = {
  onPress: () => void;
};

import { createModel } from 'xstate/lib/model';

export const reCaptchaModel = createModel(
  {},
  {
    events: {
      LOAD: () => ({}),
      EXECUTE: () => ({}),
      VERIFY: () => ({}),
      ERROR: () => ({}),
      RESET: () => ({}),
    },
  },
);

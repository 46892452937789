import React from 'react';

import { type TierName } from '@order/graphql';

import { SweetpassUpgradeScreenContent } from '../../SweetpassUpgradeScreenContent';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders Sweetpass Upgrade Screen with generic content (without certain plan
 * details like price, trial duration, etc.)
 */
export const SweetpassUpgradeGeneric = (
  props: SweetpassUpgradeGenericProps,
) => {
  const {
    campaignId,
    nextSweetpassTierName,
    isCustomerEligibleForFreeTrial,
    isConfirmingOptIn,
    optInSweetpass,
  } = props;

  return (
    <SweetpassUpgradeScreenContent
      shouldUseGenericContent
      campaignId={campaignId}
      sweetpassNextTierName={nextSweetpassTierName}
      isFreeTrialAvailable={isCustomerEligibleForFreeTrial}
      isNextTierSweetpassPlus
      isConfirmingOptIn={isConfirmingOptIn}
      onCtaConfirmation={optInSweetpass}
    />
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassUpgradeGenericProps = Readonly<{
  isCustomerEligibleForFreeTrial: boolean;
  nextSweetpassTierName: TierName;
  isConfirmingOptIn: boolean;
  optInSweetpass: (action?: string) => void;

  campaignId?: string;
}>;

import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardFooter = (props: PropsWithChildren) => {
  return <View style={styles.container}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing['4'],
    marginTop: 'auto',
    paddingTop: theme.spacing['4'],
    paddingBottom: theme.spacing['6'],
    paddingHorizontal: theme.spacing['4'],
  },
});

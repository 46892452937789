import React, { memo } from 'react';

import type { KeyboardAvoiderScrollViewProps } from './KeyboardAvoider.types';

// Stubbed version for web.
export const KeyboardAvoiderScrollView = memo(
  ({
    ExistingScrollView,
    children,
    ...rest
  }: KeyboardAvoiderScrollViewProps) => {
    return <ExistingScrollView {...rest}>{children}</ExistingScrollView>;
  },
);

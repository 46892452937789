import type { ReactNode } from 'react';
import React, { forwardRef, memo } from 'react';
import type {
  StyleProp,
  TextProps as RNTextProps,
  TextStyle,
} from 'react-native';
import { StyleSheet, Text as RNText } from 'react-native';
import { COLORS, TYPE_CONFIG } from '@garnish/constants';

import { getAdjustedFontFamilyStyles } from './BaseText.helpers';
import type { SGTextStyle } from './BaseText.types';

// ─────────────────────────────────────────────────────────────────────────────

export const BaseText = memo(
  forwardRef<RNText, BaseTextProps>((props, ref) => {
    const { style, children, ...rest } = props;

    const fontFamStyles = getAdjustedFontFamilyStyles([styles.base, style]);

    return (
      <RNText ref={ref} style={fontFamStyles as StyleProp<TextStyle>} {...rest}>
        {children}
      </RNText>
    );
  }),
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  base: {
    ...TYPE_CONFIG.BODY['18'],
    color: COLORS.DARK_KALE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

export type BaseTextProps = Readonly<{
  children: ReactNode;
  style?: StyleProp<SGTextStyle | TextStyle>;
}> &
  Omit<RNTextProps, 'style'>;

import type { ComponentProps, ReactNode } from 'react';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { nanoid } from 'nanoid/non-secure';

import type { NoticeBanner } from '../NoticeBanner';

//
// ─── CONTEXT ────────────────────────────────────────────────────────────────────
//

const NoticeBannersStackContext = createContext<
  NoticeBannersStackContextValue | undefined
>(undefined);

export const NoticeBannersStackProvider = (
  props: NoticeBannersStackProviderProps,
) => {
  const { children, initialBanners = [] } = props;

  const [isEnabled, setIsEnabled] = useState(true);
  const [stack, setStack] =
    useState<NoticeBannersStackContextValue['stack']>(initialBanners);

  // ────────────────────────────────────────────────────────────────────

  const push = useCallback<NoticeBannersStackContextValue['push']>(
    (banner, preventDuplicates) => {
      if (!isEnabled) return;

      setStack((currentStack) => {
        const bannerAlreadyExists =
          preventDuplicates &&
          checkIfBannerAlreadyExists(currentStack, banner.id);

        if (bannerAlreadyExists) return currentStack;

        return [{ id: nanoid(), ...banner }, ...currentStack];
      });
    },
    [isEnabled],
  );

  const pop = useCallback<NoticeBannersStackContextValue['pop']>(() => {
    setStack((currentStack) => currentStack.slice(1));
  }, []);

  const clear = useCallback<NoticeBannersStackContextValue['clear']>(() => {
    setStack([]);
  }, []);

  const remove = useCallback<NoticeBannersStackContextValue['remove']>(
    (id: string) => {
      setStack((currentStack) =>
        currentStack.filter((banner) => banner.id !== id),
      );
    },
    [],
  );

  const enable = useCallback<NoticeBannersStackContextValue['enable']>(() => {
    setIsEnabled(true);
  }, []);

  const disable = useCallback<NoticeBannersStackContextValue['disable']>(() => {
    setIsEnabled(false);
  }, []);

  // ────────────────────────────────────────────────────────────────────

  const providerValue = useMemo(
    () => ({ stack, push, pop, remove, clear, enable, disable }),
    [stack, push, pop, remove, clear, enable, disable],
  );

  return (
    <NoticeBannersStackContext.Provider value={providerValue}>
      {children}
    </NoticeBannersStackContext.Provider>
  );
};

// ─── HOOKS ──────────────────────────────────────────────────────────────────────

export const useNoticeBannersStackContext = () => {
  const context = useContext(NoticeBannersStackContext);

  if (context === undefined) {
    throw new Error(
      'useNoticeBannersStackContext must be used within a <NoticeBannersStackProvider>',
    );
  }

  return context;
};

// ─── UTILS ──────────────────────────────────────────────────────────────────────

const checkIfBannerAlreadyExists = (
  currentStack: NoticeBannersStackItems,
  bannerId = '',
) => {
  return currentStack.some((stackBanner) => stackBanner.id === bannerId);
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type NoticeBannersStackProviderProps = Readonly<{
  children: ReactNode;
  initialBanners?: NoticeBannersStackItems;
}>;

type NoticeBannersStackContextValue = Readonly<{
  stack: NoticeBannersStackItems;
  push: (banner: NoticeBannerStackItem, preventDuplicates?: boolean) => void;
  pop: () => void;
  remove: (id: string) => void;
  clear: () => void;
  disable: () => void;
  enable: () => void;
}>;

type NoticeBannerStackItem = Readonly<{
  targetId?: string;
  id?: string;
}> &
  NoticeBannersStackItemProps;

type NoticeBannersStackItemProps = ComponentProps<typeof NoticeBanner>;

export type NoticeBannersStackItems = readonly NoticeBannerStackItem[];

import { useCallback, useEffect } from 'react';
import type { CombinedError, Operation } from 'urql';

import { useTelemetry } from '@order/Telemetry';
import { useUrqlContext } from '@order/Urql';
import { getUrqlError } from '@order/utils';

/**
 * In order to automatically track network error information such as:
 * - 'x-trace-id' response header.
 * - 'operationName' of the request.
 * - 'systemError' extracted from the error payload.
 *
 * We are using an `errorTrackingExchange` on the URQL Client,
 * But, to connect it with the telemetry provider, we need to use a reference.
 *
 * This reference is immediately passed to the URQL Client on its creation,
 * Later it's updated by the `TelemetryProvider` to leverage its `track` function.
 * This reference will then be used to execute `trackUrqlError`, which handles tracking.
 */

export function useRegisterUrqlErrorTracker() {
  const { track } = useTelemetry();
  const { registerUrqlErrorTracker } = useUrqlContext();

  const trackUrqlError = useCallback(
    (error: CombinedError, operation: Operation) => {
      const traceId = getTraceId(error);
      const systemError = getUrqlError(error);
      const operationName = getOperationName(operation);

      track('app.graphql_error', { traceId, systemError, operationName });
    },
    [track],
  );

  useEffect(() => {
    registerUrqlErrorTracker(trackUrqlError);
  }, [registerUrqlErrorTracker, trackUrqlError]);
}

// ─── Helpers ───────────────────────────────────────────────────────────

function getOperationName(operation: Operation) {
  const definitions = operation?.query?.definitions;

  const definition = definitions?.find(
    ({ kind }) => kind === 'OperationDefinition',
  );

  if (definition?.kind === 'OperationDefinition') {
    return definition?.name?.value;
  }
}

function getTraceId(error: CombinedError) {
  return error?.response?.headers?.get?.('x-trace-id');
}

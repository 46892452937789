import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';

export const useNavigatePostOrder = () => {
  const navigation = useNavigation();

  const navigatePostOrder = useCallback(() => {
    navigation.navigate('MainTabs', {
      screen: 'HomeTab',
      params: { screen: 'Home' },
    });
  }, [navigation]);

  return { navigatePostOrder };
};

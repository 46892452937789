import React from 'react';
import { FormattedNumber } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, theme } from '@sg/garnish';

/**
 * Discount for this order.
 */
export const BagDiscount = (props: BagDiscountProps) => {
  const { name, amount } = props;

  return (
    <View style={styles.container}>
      <BodyText style={styles.label} sizeMatch={['18']}>
        {name}
      </BodyText>

      <BodyText style={styles.label} sizeMatch={['18']}>
        <FormattedNumber
          value={-amount / 100}
          currency="USD"
          style="currency" // eslint-disable-line react/style-prop-object
        />
      </BodyText>
    </View>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing['6'],
  },
  label: {
    color: theme.colors.SUCCESS,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagDiscountProps = {
  name: string;
  amount: number;
};

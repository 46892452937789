export const sweetpassMembershipPlan = {
  'sweetpass.membership-plan.heading': 'Membership plan',

  'sweetpass.membership-plan.annual.title': 'Annual',
  'sweetpass.membership-plan.annual.title-plan': 'Annual plan',
  'sweetpass.membership-plan.annual.title-active': 'Annual (Current plan)',
  'sweetpass.membership-plan.annual.subtitle':
    '{price}/year + applicable taxes\n(value of {pricePerMonth}/month)',

  'sweetpass.membership-plan.monthly.title': 'Monthly',
  'sweetpass.membership-plan.monthly.title-plan': 'Monthly plan',
  'sweetpass.membership-plan.monthly.title-active': 'Monthly (Current plan)',
  'sweetpass.membership-plan.monthly.subtitle':
    '{price}/month + applicable taxes',

  'sweetpass.membership-plan.save-label': 'Save {amount}',
  'sweetpass.membership-plan.saving-label': 'Saving {amount}',
};

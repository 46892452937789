import type { ComponentProps, ReactNode } from 'react';
import React, { forwardRef, memo } from 'react';
import type { AccessibilityRole, ViewProps } from 'react-native';
import { Platform, Pressable, View } from 'react-native';

/**
 * ListBox, not supported in native, fallback to list.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/listbox_role}
 */
export const ListBox = memo(
  forwardRef<View, ListBoxProps>((props, ref) => {
    const { children, selectedOption, ...rest } = props;

    return (
      <View
        ref={ref}
        accessibilityRole={A11Y_ROLE_LIST}
        {...{ accessibilityActiveDescendant: selectedOption }}
        {...rest}
      >
        {children}
      </View>
    );
  }),
);

/**
 * ListBox Option, not supported in native, fallback to button.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/listbox_role}
 */
export const ListBoxOption = memo(
  forwardRef<View, ListBoxOptionProps>((props, ref) => {
    const { children, isSelected, ...rest } = props;

    return (
      <Pressable
        ref={ref}
        accessibilityRole={A11Y_ROLE_OPTION}
        {...{ accessibilitySelected: isSelected }}
        {...rest}
      >
        {children}
      </Pressable>
    );
  }),
);

// ─── Types ───────────────────────────────────────────────────────────────────

type ListBoxProps = Readonly<{
  children: ReactNode;
  selectedOption: string;
}> &
  ViewProps;

type ListBoxOptionProps = Readonly<{
  children: ReactNode;
  isSelected: boolean;
}> &
  ComponentProps<typeof Pressable>;

// ─── Constants ───────────────────────────────────────────────────────────────

const A11Y_ROLE_LIST: AccessibilityRole = Platform.select({
  web: 'listbox' as AccessibilityRole,
  default: 'list',
});

const A11Y_ROLE_OPTION: AccessibilityRole = Platform.select({
  web: 'option' as AccessibilityRole,
  default: 'button',
});

import React, { useCallback, useState } from 'react';
import { useToggleState } from '@sg/garnish';

import {
  AccountScreenContainer,
  AccountScreenSection,
  AccountScreenTitle,
  ActiveBagWarning,
  AddressAddModal,
  AddressEditModal,
  AddressList,
  useActiveBagWarning,
} from '@order/components';
import { useCustomer } from '@order/Customer';
import { useLocalizationContext } from '@order/Localization';

export const AddressesScreen = () => {
  const { t } = useLocalizationContext();
  const { customer } = useCustomer();
  const { addresses } = customer;

  // ─── State ───────────────────────────────────────────────────────────

  const [selectedAddressId, selectAddressId] = useState('');

  // ─── Toggles ─────────────────────────────────────────────────────────

  const {
    value: isShowingAddAddressModal,
    toggleOn: showAddAddressModal,
    toggleOff: hideAddAddress,
  } = useToggleState();

  const showEditAddressModal = useCallback((addressId: string) => {
    selectAddressId(addressId);
  }, []);

  const hideEditAddressModal = useCallback(() => {
    selectAddressId('');
  }, []);

  const {
    activeBagWarning,
    closeActiveBagWarning,
    confirmEditAddress,
    onEdit,
  } = useActiveBagWarning(showEditAddressModal);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <AccountScreenContainer screen="addresses">
      <AccountScreenTitle title={t('addresses.title')} />

      <AccountScreenSection>
        <AddressList
          items={addresses ?? []}
          onSelectItem={onEdit}
          onSelectAdd={showAddAddressModal}
        />
      </AccountScreenSection>

      <ActiveBagWarning
        visible={Boolean(activeBagWarning)}
        onClose={closeActiveBagWarning}
        onConfirm={confirmEditAddress}
      />

      <AddressAddModal
        visible={isShowingAddAddressModal}
        onRequestClose={hideAddAddress}
      />

      <AddressEditModal
        addressId={selectedAddressId}
        visible={Boolean(selectedAddressId)}
        onRequestClose={hideEditAddressModal}
      />
    </AccountScreenContainer>
  );
};

import React, { type ComponentProps } from 'react';

import { IconLink } from '../../../../../Icon';

// ─────────────────────────────────────────────────────────────────────────────

export const ActiveIngredientQuantityStepperButton = (
  props: ComponentProps<typeof IconLink>,
) => {
  return (
    <IconLink
      iconSize={30}
      width={40}
      height={40}
      palette="oatmeal-almost-transparent"
      {...props}
    />
  );
};

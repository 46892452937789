import { useEffect } from 'react';
import type { UseMutationState, UseQueryState } from 'urql';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const useNetworkErrorNotice = (
  request: UseQueryState | UseMutationState,
  errorMessage?: string,
) => {
  const hasError = request.error !== undefined;
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { t } = useLocalizationContext();
  const defaultErrorMessage = t('general.error');
  const text = errorMessage ?? defaultErrorMessage;

  useEffect(() => {
    if (hasError) {
      addNoticeBanner({ text, palette: 'caution' });
    }
  }, [hasError, text, addNoticeBanner]);
};

import React from 'react';
import { useIsFocused } from '@react-navigation/native';

import { LoadingAnimation, NotFoundView } from '@order/components';
import { useCustomScreenTitle } from '@order/hooks';
import { useResetLastInteractedMenuStore } from '@order/LastInteractedStore';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

import { useHeaderRight } from '../../components/Header/Header.hooks';
import type { HeaderIconItems } from '../../components/Header/Header.props';
import type { MenuScreenProps } from '../../navigation/AppNavigation.props';
import { useRestaurantQuery } from './GraphQL/Restaurant.query.generated';
import { MenuScreenContent } from './MenuScreen.content';
import { useMenuNavigation, useMenuScreenReset } from './MenuScreen.navigation';
import { MenuScreenV2 } from './MenuScreenV2';
import { MenuHeaderTitle } from './subcomponents';

// ────────────────────────────────────────────────────────────────────────────────

export const MenuScreen = (props: MenuScreenProps) => {
  const isMenuRedesignEnabled = useFeatureFlag(
    'CELS-2852-redesign-menu-enabled',
  );

  if (isMenuRedesignEnabled) {
    return <MenuScreenV2 />;
  }

  return <MenuScreenLegacy {...props} />;
};

const MenuScreenLegacy = ({ navigation, route }: MenuScreenProps) => {
  const { t } = useLocalizationContext();
  const { handlePressDietaryRestrictions } = useMenuNavigation();
  const resetLastInteractedMenuStore = useResetLastInteractedMenuStore();

  const isScreenFocused = useIsFocused();

  useMenuScreenReset(navigation);

  const { restaurantSlug } = route.params ?? {};
  const [restaurantQueryResponse] = useRestaurantQuery({
    variables: { id: restaurantSlug },
    requestPolicy: 'cache-and-network',
    pause: !isScreenFocused || !restaurantSlug,
  });

  const { fetching, stale, data } = restaurantQueryResponse;
  const restaurantData = data?.restaurant;
  const shouldRedirectToNotFoundScreen = !fetching && !stale && !restaurantData;

  const headerLabel = restaurantData?.isOutpost
    ? t('menu.hero.title.delivery')
    : t('menu.hero.title.standard');

  const headerLocationName = restaurantData?.name;

  // ─── Helpers ─────────────────────────────────────────────────────

  const navigateToLocationsScreen = React.useCallback(() => {
    navigation.navigate('Locations');
  }, [navigation]);

  const menuHeaderTitleFn = React.useMemo(
    // eslint-disable-next-line react/no-unstable-nested-components -- @REVIEW
    () => () => (
      <MenuHeaderTitle
        label={headerLabel}
        locationName={headerLocationName}
        onPress={navigateToLocationsScreen}
      />
    ),
    [headerLabel, headerLocationName, navigateToLocationsScreen],
  );

  const headerRightItems = React.useMemo<HeaderIconItems>(
    () => [
      {
        testID: 'nav-bar.dietary-restrictions',
        key: 'dietary-restrictions',
        icon: 'IconFilter',
        accessibilityLabel: t(
          'nav-bar.dietary-restrictions.accessibility-label',
        ),
        accessibilityHint: t('nav-bar.dietary-restrictions.accessibility-hint'),
        onPress: handlePressDietaryRestrictions,
      },
    ],
    [t, handlePressDietaryRestrictions],
  );

  // ─── Navigation options ──────────────────────────────────────────

  const headerRight = useHeaderRight({ items: headerRightItems });

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: menuHeaderTitleFn,
      headerRight,
    });
  }, [navigation, menuHeaderTitleFn, headerRight]);

  useCustomScreenTitle(
    t('page-title.account.menu', {
      restaurantName: headerLocationName,
    }),
  );

  // ─────────────────────────────────────────────────────────────────

  if (fetching) return <LoadingAnimation />;

  if (shouldRedirectToNotFoundScreen) {
    return (
      <NotFoundView
        btnLabel={t('not-found-view.select-another-location')}
        onBtnPress={navigateToLocationsScreen}
        onMount={resetLastInteractedMenuStore}
      />
    );
  }

  if (!restaurantData) return null;

  return (
    <MenuScreenContent
      restaurantSlug={restaurantSlug}
      restaurantData={restaurantData}
      navigation={navigation}
    />
  );
};

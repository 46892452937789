import React, { useCallback, useRef } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../hooks';
import { webOnlyStyles } from '../../utils';
import { Icon } from '../Icon';
import { BodyText } from '../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const CheckboxButton = (props: CheckboxButtonProps) => {
  const { isChecked, isDisabled, heading, subheading, onChange } = props;

  const ref = useRef(null);
  const { isInteracting } = usePressableState(ref);

  // ─── Styles ──────────────────────────────────────────────────────────

  const interactiveStyle =
    isInteracting && !isDisabled ? styles.checkerHover : null;

  const iconStyle = getIconStyle(isDisabled, isChecked);
  const iconColor = getIconColor(isDisabled, isChecked);

  const contentContainerStyles = [
    styles.contentContainer,
    contentContainerWebStyles,
    interactiveStyle,
  ];

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnChange = useCallback(() => {
    onChange(!isChecked);
  }, [isChecked, onChange]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      ref={ref}
      accessibilityRole="checkbox"
      accessibilityState={{ checked: isChecked }}
      disabled={isDisabled}
      onPress={isDisabled ? undefined : handleOnChange}
    >
      <View style={contentContainerStyles}>
        <View style={styles.textContent}>
          <BodyText sizeMatch={['16']}>{heading}</BodyText>

          {subheading ? (
            <BodyText sizeMatch={['14']}>{subheading}</BodyText>
          ) : null}
        </View>

        <View style={[styles.checkerBase, iconStyle]}>
          <Icon
            name="IconCheck"
            width={CHECK_ICON_SIZE}
            height={CHECK_ICON_SIZE}
            color={iconColor}
          />
        </View>
      </View>
    </Pressable>
  );
};

// ─── Helpers ────────────────────────────────────────────────────────────────

function getIconStyle(isChecked: boolean, isDisabled: boolean) {
  if (isChecked) return styles.checkerDisabled;
  if (isDisabled) return styles.checkerChecked;

  return styles.checkerUnchecked;
}

function getIconColor(isChecked: boolean, isDisabled: boolean) {
  if (isChecked) return theme.colors.OPACITY.DARK_KALE.DARK;
  if (isDisabled) return theme.colors.WHITE;

  return theme.colors.OPACITY.TRANSPARENT;
}

// ─── Constants ──────────────────────────────────────────────────────────────

const CHECK_ICON_SIZE = 24;
const CHECK_WRAPPER_SIZE = 32;

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  contentContainer: {
    paddingHorizontal: theme.spacing['2'],
    paddingVertical: theme.spacing['6'],
    gap: theme.spacing['4'],
    borderRadius: theme.radius.medium,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textContent: {
    flex: 1,
  },
  checkerBase: {
    width: CHECK_WRAPPER_SIZE,
    height: CHECK_WRAPPER_SIZE,
    borderRadius: theme.radius.xxxlarge,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkerChecked: {
    backgroundColor: theme.colors.SPINACH,
  },
  checkerUnchecked: {
    borderColor: theme.colors.SPINACH,
    borderWidth: 1,
    backgroundColor: theme.colors.OPACITY.TRANSPARENT,
  },
  checkerDisabled: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
  },
  checkerHover: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
});

const contentContainerWebStyles = webOnlyStyles({
  transition: theme.transitions.presets.colors,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CheckboxButtonProps = {
  heading: string;
  subheading?: string;
  isChecked: boolean;
  isDisabled: boolean;
  onChange: (isChecked: boolean) => void;
};

import React, { type PropsWithChildren, useMemo } from 'react';
import { ScrollView, StyleSheet, View, type ViewStyle } from 'react-native';
import { UL } from '@expo/html-elements';
import {
  ShadowDivider,
  theme,
  useContainerSpacing,
  useResponsive,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsCategoriesNavList = (props: PropsWithChildren) => {
  const { children } = props;

  const { match } = useResponsive();
  const containerSpacing = useContainerSpacing();

  // ─── Styles ──────────────────────────────────────────────────────────

  const navContainerDynamicStyles = useMemo<ViewStyle>(
    () => ({ paddingHorizontal: containerSpacing }),
    [containerSpacing],
  );
  const navContainerStyles = [styles.navContainer, navContainerDynamicStyles];
  const navListStyles = [
    styles.navList,
    match([styles.navListXS, styles.navListSM]),
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.innerContainer}>
      <ScrollView
        contentContainerStyle={navContainerStyles}
        horizontal
        showsHorizontalScrollIndicator={false}
      >
        <UL style={navListStyles}>{children}</UL>
      </ScrollView>

      <View style={styles.shadowContainer}>
        <ShadowDivider
          height={theme.spacing['2']}
          color={theme.colors.APP_BACKGROUND}
        />
      </View>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  innerContainer: {
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  navContainer: {
    minWidth: '100%',
    alignItems: 'center',
  },
  navList: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    columnGap: theme.spacing['4'],
    paddingVertical: theme.spacing['4'],
  },
  navListXS: {
    columnGap: theme.spacing['2'],
  },
  navListSM: {
    justifyContent: 'center',
    columnGap: theme.spacing['4'],
  },
  shadowContainer: {
    position: 'absolute',
    right: 0,
    bottom: -theme.spacing['2'],
    left: 0,
  },
});

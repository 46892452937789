import type {
  CheckoutFieldName,
  CheckoutFieldsInvalidValues,
  CheckoutFieldsValidationState,
  CheckoutPartialPayload,
} from './checkoutFieldsValidation.types';

// ─── Helpers ────────────────────────────────────────────────────────────────────

/**
 * Returns checkout fields validation state based on the current state and payload
 */
export function setCheckoutFieldsValidationState(
  currentState: CheckoutFieldsValidationState,
  payload: CheckoutPartialPayload,
) {
  const { fieldsValidation } = currentState;

  return Object.keys(fieldsValidation).reduce(
    getCheckoutFieldsValidationState(payload),
    { ...currentState },
  );
}

/**
 * Returns updated checkout fields validation state with reset field validation state
 */
export function resetCheckoutFieldValidationState(
  currentState: CheckoutFieldsValidationState,
  fieldName: CheckoutFieldName,
) {
  const { fieldsValidation } = currentState;
  const fieldValidationState = fieldsValidation[fieldName];

  if (fieldValidationState === undefined) return {};

  return {
    fieldsValidation: {
      ...fieldsValidation,
      [fieldName]: true,
    },
  };
}

// ────────────────────────────────────────────────────────────────────────────────

function getCheckoutFieldsValidationState(payload: CheckoutPartialPayload) {
  return (context: CheckoutFieldsValidationState, fieldName: string) => {
    const { fieldsValidation, invalidValues } = context;

    const checkoutFieldName = fieldName as CheckoutFieldName;
    const isValid = checkIfCheckoutFieldHasValidValue(
      checkoutFieldName,
      payload,
      invalidValues,
    );

    return {
      ...context,
      fieldsValidation: { ...fieldsValidation, [checkoutFieldName]: isValid },
    };
  };
}

function checkIfCheckoutFieldHasValidValue(
  fieldName: CheckoutFieldName,
  payload: CheckoutPartialPayload,
  invalidValues: CheckoutFieldsInvalidValues,
) {
  const currentValue = payload[fieldName];
  const invalidCases = invalidValues[fieldName];

  if (!invalidCases) return true;

  return !invalidCases.some((currentInvalidCase) =>
    Object.is(currentValue, currentInvalidCase),
  );
}

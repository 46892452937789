/* eslint-disable @typescript-eslint/brace-style */
import { useCallback } from 'react';
import { useActor } from '@xstate/react';

import { useGlobalAppState } from '@order/GlobalAppState';
import { useDeliveryOrderInFlight } from '@order/hooks';

import type { ReorderEvent } from '../../machines/Reorder.machine';
import { useExecuteReorder } from '../useExecuteReorder';
import { useReorderNavigation } from '../useReorderNavigation';
import { useReorderTelemetry } from '../useReorderTelemetry';
import {
  cameFromLocationConfirmation,
  getValidEventKeys,
  handleConflictTelemetry,
  justStartedReorder,
  logger,
  parseConflictResults,
} from '../utils';

export const useConflictReview = () => {
  const { reorderMachineRef } = useGlobalAppState();
  const [state, sendReorderEvent] = useActor(reorderMachineRef);
  const executeReorder = useExecuteReorder();
  const { navigateToConflictReview, replaceWithConflictReview } =
    useReorderNavigation();
  const { inFlightDeliveryOrderId, navigateToDeliveryOrderInFlightScreen } =
    useDeliveryOrderInFlight({ requestPolicy: 'cache-only' });

  // ─── Telemetry ──────────────────────────────────────────────────
  const {
    startReorderTelemetry,
    continueToBagTelemetry,
    conflictReviewTelemetry,
    cannotReorderTelemetry,
    maxDeliveryTelemetry,
    dressingUnavailableTelemetry,
    missingMenuTelemetry,
  } = useReorderTelemetry();

  return useCallback(
    async (event?: ReorderEvent) => {
      logger.info('Conflict Review', event);

      // Reorder can start as delivery and then change to other types.
      const wasStartedAsDelivery = Boolean(
        state?.context?.deliveryOrderDetail?.addressId,
      );
      const isReorderingToPickup = event?.orderType === 'pickup';
      const isReorderingToOutpost = event?.orderType === 'outpost';
      const wasStartedAsDeliveryAndNotChanged =
        wasStartedAsDelivery && !isReorderingToPickup && !isReorderingToOutpost;

      // Reorder can start as other types and then change to delivery.
      const isReorderingToDelivery = Boolean(
        event?.deliveryOrderDetail?.addressId,
      );

      // block delivery reorder if there is an in-flight delivery order.
      const isDeliveryReorder =
        isReorderingToDelivery || wasStartedAsDeliveryAndNotChanged;

      // blocking reorder should also cancel the reorder flow.
      if (isDeliveryReorder && inFlightDeliveryOrderId) {
        sendReorderEvent('CANCEL_REORDER');
        navigateToDeliveryOrderInFlightScreen();

        return;
      }

      // ─── Extra Telemetry Event ──────────────────────────────────
      if (cameFromLocationConfirmation(state.value)) {
        continueToBagTelemetry(event ?? state.context);
      }

      // ─── State Machine ──────────────────────────────────────────
      sendReorderEvent({
        ...getValidEventKeys(event),
        type: 'SET_REORDERING',
      });

      // ─── Reorder Mutation ───────────────────────────────────────
      const response = await executeReorder(state.value, state.context, event);

      // ─── Conflict Results ───────────────────────────────────────
      const {
        reorderResult,
        conflictType,
        isCannotReorder,
        isMaxDelivery,
        isDressingUnavailable,
        isMissingMenu,
      } = parseConflictResults(response.data);

      logger.info('Conflict Results', { reorderResult, conflictType });

      // ─── State Machine ──────────────────────────────────────────
      sendReorderEvent({
        ...getValidEventKeys(event),
        type: 'CONFLICT_REVIEW',
        response: response.data as never,
      });

      // ─── Conflict Review Telemetry ──────────────────────────────
      conflictReviewTelemetry({
        ...(event ?? state.context),
        reorderResult,
        conflictType,
      });
      handleConflictTelemetry({
        isCannotReorder,
        isMaxDelivery,
        isDressingUnavailable,
        isMissingMenu,
        cannotReorderTelemetry,
        maxDeliveryTelemetry,
        dressingUnavailableTelemetry,
        missingMenuTelemetry,
      });

      // ─── Just Started Reorder ───────────────────────────────────
      if (justStartedReorder(state.value)) {
        // ─── Start Reorder Telemetry ──────────────────────────────
        startReorderTelemetry(
          event ?? state.context,
          'conflict_review',
          event?.userInitiated,
        );
        navigateToConflictReview();
      }

      // ─── Continuing Reorder ─────────────────────────────────────
      else {
        replaceWithConflictReview();
      }
    },
    [
      state.value,
      state.context,
      inFlightDeliveryOrderId,
      sendReorderEvent,
      executeReorder,
      conflictReviewTelemetry,
      cannotReorderTelemetry,
      maxDeliveryTelemetry,
      dressingUnavailableTelemetry,
      missingMenuTelemetry,
      replaceWithConflictReview,
      navigateToDeliveryOrderInFlightScreen,
      continueToBagTelemetry,
      startReorderTelemetry,
      navigateToConflictReview,
    ],
  );
};

import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  parseIncompletePhoneNumber,
  parsePhoneNumber as parseCompletePhoneNumber,
} from 'libphonenumber-js';
import * as yup from 'yup';

import { useLocalizationContext } from '@order/Localization';

import type { JoinFormData } from '../utils';

export const useJoinForm = (config: JoinFormConfig) => {
  const { t } = useLocalizationContext();

  const requiredFirstNameMessage = t('form.first-name-required');
  const requiredLastNameMessage = t('form.last-name-required');
  const requiredEmailMessage = t('form.email-required');
  const requiredPhoneNumberMessage = t('form.phone-number-required');
  const requiredPasswordMessage = t('form.password-required');
  const requiredZipCodeMessage = t('form.zip-code-required');
  const requiredBirthdayMessage = t('form.birthday-required');
  const invalidPhoneNumberMessage = t('form.validation-phone-number');
  const invalidPasswordLengthMessage = t('form.min-password-length');
  const invalidCheckboxValue = t('form.validation-checkbox');
  const invalidEmail = t('form.validation-email');

  const firstName = yup
    .string()
    .required(requiredFirstNameMessage)
    .max(
      50,
      t('form.max-name-length', {
        fieldName: 'First',
      }),
    )
    .matches(
      /^[\sA-Za-z]+$/,
      t('form.invalid-characters', { fieldName: 'First' }),
    );

  const lastName = yup
    .string()
    .required(requiredLastNameMessage)
    .max(
      50,
      t('form.max-name-length', {
        fieldName: 'Last',
      }),
    )
    .matches(
      /^[\sA-Za-z]+$/,
      t('form.invalid-characters', { fieldName: 'Last' }),
    );

  const email = yup.string().email(invalidEmail).required(requiredEmailMessage);

  const phoneNumber = yup
    .string()
    .required(requiredPhoneNumberMessage)
    .test('test-valid-phone-number', function (number) {
      const { path, createError } = this;
      const parsedPhoneNumber = parseIncompletePhoneNumber(number ?? '');
      const isValidNumber =
        parsedPhoneNumber.length === US_PHONE_NUMBER_MAX_LENGTH
          ? parseCompletePhoneNumber(
              parsedPhoneNumber,
              US_COUNTRY_CODE,
            ).isValid()
          : true;

      return (
        isValidNumber ||
        createError({ path, message: invalidPhoneNumberMessage })
      );
    })
    .matches(/^\d+$/, invalidPhoneNumberMessage)
    .min(10, invalidPhoneNumberMessage)
    .max(10, invalidPhoneNumberMessage);

  const password = yup
    .string()
    .required(requiredPasswordMessage)
    .min(8, invalidPasswordLengthMessage);

  const zipCode = yup
    .string()
    .required(requiredZipCodeMessage)
    .min(5, t('form.validation.zip-code'))
    .max(5, t('form.validation.zip-code'));

  const birthday = yup.string().required(requiredBirthdayMessage);

  const terms = yup.bool().oneOf([true], invalidCheckboxValue);

  const validationSchema = useMemo(
    () =>
      yup
        .object({
          firstName,
          lastName,
          email,
          phoneNumber,
          ...(config.hasZipCode ? { zipCode } : {}),
          ...(config.hasPassword ? { password } : {}),
          ...(config.isBirthdayRequired ? { birthday } : {}),
          terms,
        })
        .required(),
    [
      firstName,
      lastName,
      email,
      phoneNumber,
      config.hasZipCode,
      config.hasPassword,
      config.isBirthdayRequired,
      zipCode,
      password,
      birthday,
      terms,
    ],
  );

  // ────────────────────────────────────────────────────────────────────

  const form = useForm<JoinFormData>({
    defaultValues: {
      firstName: config.firstName ?? '',
      lastName: config.lastName ?? '',
      email: config.email ?? '',
      phoneNumber: config.phoneNumber ?? '',
      zipCode: config.zipCode ?? '',
      password: config.password ?? '',
      birthday: config.birthday ?? '',
      terms: false,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  // ────────────────────────────────────────────────────────────────────

  return { form };
};

// ─── Types ────────────────────────────────────────────────────────────

type JoinFormConfig = Partial<JoinFormData> & {
  isBirthdayRequired: boolean;
  hasPassword: boolean;
  hasZipCode: boolean;
};

// ─── Constants ────────────────────────────────────────────────────────

const US_PHONE_NUMBER_MAX_LENGTH = 10;
const US_COUNTRY_CODE = 'US';

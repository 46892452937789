import React from 'react';

import { useFeatureFlag } from '@order/LaunchDarkly';

import type { BagScreenProps } from '../../navigation/AppNavigation.props';
import { BagScreenV1 } from './BagScreenV1';
import { BagScreenV2 } from './V2/BagScreenV2';

export const BagScreen = (props: BagScreenProps) => {
  const shouldUseBagV2 = useFeatureFlag(
    'CELS-2627-order-ordering-redesign-enabled',
  );

  if (shouldUseBagV2) {
    return <BagScreenV2 />;
  }

  return <BagScreenV1 {...props} />;
};

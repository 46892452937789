import { useEffect, useState } from 'react';

import type {
  mapAvailableTimes,
  SelectedTimeProps,
} from '../../Checkout.utils';
import { isTimeAvailable } from '../../Checkout.utils';

export const useFirstAvailableOption = ({
  timeOptionsGroups,
  selectedTime,
  setSelectedTime,
}: FirstAvailableOptionProps) => {
  const [firstAvailableOption, setFirstAvailableOption] = useState('');

  useEffect(() => {
    const timeOptions = Object.values(timeOptionsGroups).flat();

    setFirstAvailableOption(timeOptions.find(isTimeAvailable)?.value ?? '');
  }, [timeOptionsGroups, setFirstAvailableOption]);

  useEffect(() => {
    const isStaleSelectedTime =
      selectedTime &&
      firstAvailableOption &&
      new Date(selectedTime) < new Date(firstAvailableOption);

    if (Boolean(!selectedTime && firstAvailableOption) || isStaleSelectedTime) {
      setSelectedTime(firstAvailableOption);
    }
  }, [firstAvailableOption, selectedTime, setSelectedTime]);
};

type FirstAvailableOptionProps = Readonly<{
  timeOptionsGroups: ReturnType<typeof mapAvailableTimes>;
}> &
  SelectedTimeProps;

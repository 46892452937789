import type { ComponentProps } from 'react';
import type { ImageRequireSource } from 'react-native';

import {
  IllusCredit_1,
  IllusEmpty_1,
  IllusMushroom,
  IllusRewardCardCarrotPremium,
  IllusRewardCardMushroomPremium,
  IllusRewardCardPeasPremium,
  IllusRewardCardTomatoPremium,
} from '../../assets';
import type { RewardCard } from '../RewardCard';
import { IllusBerry, IllusFlower, IllusSprout } from './assets';

// ────────────────────────────────────────────────────────────────────────────────

export function getRewardIllustrationByPalette(
  palette: RewardCardPalette,
  index: number,
) {
  const illustrations = illustrationForPalette[palette];

  return illustrations[Math.abs(index % illustrations.length)];
}

// ─────────────────────────────────────────────────────────────────────────────

const illustrationForPalette: IllustrationsForPalettes = {
  quinoa: [IllusBerry, IllusFlower, IllusSprout],
  kale: [
    IllusRewardCardCarrotPremium,
    IllusRewardCardPeasPremium,
    IllusRewardCardMushroomPremium,
    IllusRewardCardTomatoPremium,
  ],
  cucumber: [IllusCredit_1, IllusEmpty_1, IllusMushroom],
};

// ─── Types ───────────────────────────────────────────────────────────────────

type IllustrationsForPalettes = Record<
  RewardCardPalette,
  readonly ImageRequireSource[]
>;

type RewardCardPalette = NonNullable<
  ComponentProps<typeof RewardCard>['palette']
>;

import { useContentfulContentTypeEntry } from '@order/Contentful';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Fetches the OTA update dialog content.
 *
 * @param params.targetVersion - The target version of the app used as a
 * Contentful Tag to get the correct OTA update content (e.g. `'6.200.3'`).
 *
 * @see {@link https://www.contentful.com/help/tags}
 */
export const useOtaUpdateDialogContent = (
  params: UseOtaUpdateDialogContentParams,
) => {
  const { targetVersion = '' } = params;

  const { data, fetching } = useContentfulContentTypeEntry<OtaUpdateContent>({
    contentType: 'otaUpdate',
    tags: [targetVersion],
    pause: !targetVersion,
  });

  const title = data?.fields?.title;
  const description = data?.fields?.description;

  return {
    data: { title, description },
    isFetching: fetching,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseOtaUpdateDialogContentParams = {
  targetVersion?: string;
};

type OtaUpdateContent = Readonly<{
  title?: string;
  description?: string;
}>;

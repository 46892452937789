import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

// ────────────────────────────────────────────────────────────────────────────────

export const Subtitle = (props: SubtitleProps) => {
  const { children, direction = 'column' } = props;
  const wrapperStyle = [styles.wrapper, { flexDirection: direction }];

  return <View style={wrapperStyle}>{children}</View>;
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type SubtitleProps = Readonly<{
  children?: React.ReactNode;
  direction?: 'row' | 'column';
}>;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    paddingBottom: theme.spacing['10'],
  },
});

/* eslint-disable no-console */
import React from 'react';
import { datadog } from '@sg/expo-datadog-client';

import { useOrderAppFonts } from './useOrderAppFonts';

// ─────────────────────────────────────────────────────────────────────────────
// This hook provides visibility into a known issue around expo-font loading:
// https://github.com/expo/expo/issues/21885
export function useAppFonts() {
  const [fontsLoaded, fontError] = useOrderAppFonts();
  const [hasPrevError, setHasPrevError] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (fontError && !hasPrevError) {
      void datadog.logError(JSON.stringify(fontError));
      console.error(getErrorMessage(fontError));
      setHasPrevError(true);
    }

    if (hasPrevError && !fontError) {
      console.info(`Font error resolved! ${fontsLoaded && 'Fonts loaded'}`);
      setHasPrevError(false);
    }
  }, [fontsLoaded, fontError, hasPrevError, setHasPrevError]);

  return fontsLoaded || fontError;
}

function getErrorMessage(fontError: Error | undefined) {
  return `
Doh! An error loading the font.
${fontError}
You may want to restart the app, by hitting "r" in the terminal or devclient.
More info about this issue here: https://github.com/expo/expo/issues/21885`;
}

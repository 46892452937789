import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { useResponsive } from '@sg/garnish';

import { PaymentMethodSelectorModal } from '@order/components';
import { getCreditCardIcon } from '@order/constants';
import { type useCreditCardOptions } from '@order/hooks';

import { type GiftCardCheckoutFormReturn } from '../../../../form';
import { GiftCardCheckoutFormGroup } from '../../components';
import { giftCardCheckoutPaymentMethodMessages as messages } from './GiftCardCheckoutPaymentMethod.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutPaymentMethod = (
  props: GiftCardCheckoutPaymentMethodProps,
) => {
  const { form, paymentMethods } = props;

  const paymentMethodId = form.watch('paymentMethodId');

  const paymentMethodIdError = form.formState.errors.paymentMethodId?.message;

  const { match } = useResponsive();

  // ─────────────────────────────────────────────────────────────────────

  const selectedPaymentMethod = useMemo(() => {
    return paymentMethods.find(
      (paymentMethod) => paymentMethod?.value === paymentMethodId,
    );
  }, [paymentMethods, paymentMethodId]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onPaymentMethodSelected = useCallback(
    (selectedPaymentMethodId: string) => {
      form.clearErrors('paymentMethodId');
      form.setValue('paymentMethodId', selectedPaymentMethodId);
    },
    [form],
  );

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = match([styles.containerXS, styles.containerSM]);

  // ─────────────────────────────────────────────────────────────────────

  const selectedPaymentMethodLabel = selectedPaymentMethod?.label ?? '';
  const creditCardIcon = getCreditCardIcon(selectedPaymentMethod?.cardType);

  return (
    <GiftCardCheckoutFormGroup.Container style={containerStyles}>
      <View style={styles.titleContainer}>
        <GiftCardCheckoutFormGroup.Title>
          <FormattedMessage {...messages.sectionTitle} />
        </GiftCardCheckoutFormGroup.Title>
      </View>

      <PaymentMethodSelectorModal
        selectedPaymentMethodId={paymentMethodId}
        selectedPaymentMethodIcon={creditCardIcon}
        selectedPaymentMethodLabel={selectedPaymentMethodLabel}
        onPaymentMethodSelected={onPaymentMethodSelected}
        paymentMethodOptions={paymentMethods}
        paymentMethodSelectionNotice={paymentMethodIdError}
        paymentMethodSelectionNoticePalette="caution"
        isApplePayReady={false}
        hasBoldLabel={false}
      />
    </GiftCardCheckoutFormGroup.Container>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    paddingBottom: 0,
  },
  containerSM: {
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderBottomStartRadius: theme.radius.large,
    borderBottomEndRadius: theme.radius.large,
    paddingBottom: theme.spacing['4'],
  },
  titleContainer: {
    marginBottom: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutPaymentMethodProps = {
  sectionTitle?: string;
  form: GiftCardCheckoutFormReturn;
  paymentMethods: ReturnType<typeof useCreditCardOptions>['creditCardOptions'];
};

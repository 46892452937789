import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, theme, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const ErrorMessage = ({ minimalStyles = false }) => {
  const { currentBreakpoint } = useResponsive();
  const { t } = useLocalizationContext();

  const wrapperStyles = [
    styles.wrapper,
    currentBreakpoint.isXS ? styles.wrapperMobile : styles.wrapperDesktop,
    minimalStyles && styles.wrapperMinimal,
  ];

  return (
    <View style={wrapperStyles} testID="location-results-error-message">
      <BodyText>{t('general.connection-issue-message')}</BodyText>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    right: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: theme.colors.PINK_3,
    textAlign: 'center',
    alignItems: 'center',
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['4'],
    borderColor: theme.colors.GREEN_1,
  },
  wrapperMinimal: {
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  wrapperMobile: {
    bottom: 0,
    borderTopWidth: 1,
  },
  wrapperDesktop: {
    top: 0,
    borderBottomWidth: 1,
  },
});

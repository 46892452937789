import { useCallback, useRef, useState } from 'react';
import { type View } from 'react-native';

// ─────────────────────────────────────────────────────────────────────────────

export const useActiveIngredientFloatingStepper = () => {
  //
  // ─── Refs ────────────────────────────────────────────────────────────

  const triggerRef = useRef<View>(null);

  // ─── State ───────────────────────────────────────────────────────────

  const [triggerCoordinates, setTriggerCoordinates] = useState({ x: 0, y: 0 });

  // ─── Helpers ─────────────────────────────────────────────────────────

  const updateStepperTriggerCoordinates = useCallback(() => {
    // To ensure that the trigger exists in the native view hierarchy (Android),
    // pass `collapsable={false}` prop to avoid having `undefined` values.
    //
    // See: https://reactnative.dev/docs/view#collapsable-android
    // See: https://github.com/facebook/react-native/issues/3282#issuecomment-201934117
    triggerRef.current?.measure((_x, _y, width, height, pageX, pageY) => {
      setTriggerCoordinates({
        y: pageY + height,
        x: pageX + width / 2,
      });
    });
  }, []);

  // ─────────────────────────────────────────────────────────────────────

  return {
    triggerRef,
    triggerCoordinates,
    updateStepperTriggerCoordinates,
  };
};

import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductCardV2UnavailableLabel = (props: PropsWithChildren) => {
  return (
    <BodyText
      sizeMatch={['14']}
      style={styles.text}
      maxFontSizeMultiplier={1.2}
    >
      {props.children}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    color: theme.colors.CAUTION,
  },
});

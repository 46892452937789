import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

import { CUSTOM_NAVIGATION_HEADER_HORIZONTAL_PADDING } from '../../CustomNavigationHeader.constants';

// ─────────────────────────────────────────────────────────────────────────────

const CustomNavigationHeaderItemRight = (props: PropsWithChildren) => {
  return <CustomNavigationHeaderItemTemplate position="right" {...props} />;
};

const CustomNavigationHeaderItemLeft = (props: PropsWithChildren) => {
  return <CustomNavigationHeaderItemTemplate position="left" {...props} />;
};

const CustomNavigationHeaderItemTemplate = (
  props: CustomNavigationHeaderItemTemplateProps,
) => {
  const { position, children } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    position === 'right' ? styles.containerRight : styles.containerLeft,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return <View style={containerStyles}>{children}</View>;
};

// ─────────────────────────────────────────────────────────────────────────────

export const CustomNavigationHeaderItem = {
  Right: CustomNavigationHeaderItemRight,
  Left: CustomNavigationHeaderItemLeft,
  Template: CustomNavigationHeaderItemTemplate,
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerRight: {
    right: CUSTOM_NAVIGATION_HEADER_HORIZONTAL_PADDING,
  },
  containerLeft: {
    left: CUSTOM_NAVIGATION_HEADER_HORIZONTAL_PADDING,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomNavigationHeaderItemTemplateProps = PropsWithChildren<{
  position: 'right' | 'left';
}>;

import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ingredientKind: {
    defaultMessage: `{ingredient_kind, select,
        bases {Bases}
        toppings {Toppings}
        premiums {Premiums}
        dressings {Dressings}
        bread {Bread}
        other {{ingredient_kind}}
    }`,
    description: 'Customization | Ingredients grid | Ingredient kinds',
  },
  ingredientKindHelperText: {
    defaultMessage: `{ingredient_kind, select,
        bases {Select up to two}
        other {}
    }`,
    description:
      'Customization | Ingredients grid | Ingredient kinds helper text',
  },
});

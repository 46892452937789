import { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import { useIsCustomerAddress } from '@order/hooks';
import { useLastInteractedStore } from '@order/LastInteractedStore';

import type { MenuWithoutSlugScreenProps } from '../../navigation/AppNavigation.props';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A "placeholder" route that will always redirect users to the menu or
 * delivery menu route if the location slug or address ID is available,
 * or to the location selection route if it is not.
 */
export const MenuWithoutSlug = (props: MenuWithoutSlugScreenProps) => {
  const { navigation } = props;

  const lastInteractedStore = useLastInteractedStore();
  const { restaurantSlug, addressId } = lastInteractedStore ?? {};

  const isCustomerAddress = useIsCustomerAddress(addressId);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const redirectToMenuOrLocation = useCallback(() => {
    const hasValidMenuRouteSlug = restaurantSlug !== undefined;
    const hasValidDeliveryMenuRouteSlug = addressId && isCustomerAddress;

    // if the last interacted store is a delivery location, navigate to the
    // associated delivery menu screen
    if (hasValidDeliveryMenuRouteSlug) {
      navigation.replace('DeliveryMenu', { addressId });

      return;
    }

    // if the last interacted store is a pickup or outpost location, navigate
    // to the associated menu screen
    if (hasValidMenuRouteSlug) {
      navigation.replace('Menu', { restaurantSlug });

      return;
    }

    // otherwise navigate to the location search screen
    navigation.replace('Locations');
  }, [addressId, isCustomerAddress, navigation, restaurantSlug]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useFocusEffect(redirectToMenuOrLocation);

  // ─────────────────────────────────────────────────────────────────────

  return null;
};

export type UseGooglePlacesSearchProps = Readonly<{
  apiKey: string;
  minChars?: number;
}>;

export type Predictions = readonly google.maps.places.AutocompletePrediction[];

export type FetchPredictionsProps = Readonly<{
  input: string;
  apiKey: string;
}>;

export type FetchGeocodeProps = Readonly<{
  placeId: string;
  apiKey: string;
}>;

export type GenerateQueryParamsProps = Record<string, string>;

export type PredictionsCacheItem = Readonly<{
  predictions: Predictions;
}>;

import type { ImageURISource } from 'react-native';

import { useContentfulContentTypeEntry } from '@order/Contentful';
import { useLocalizationContext } from '@order/Localization';

export function useUpgradeCelebrationModalContentfulData() {
  const { t } = useLocalizationContext();

  // ─── Fallback ────────────────────────────────────────────────────────

  const fallback = {
    headingText: t('sweetpass-celebration.title'),
    footerText: t('sweetpass-celebration.subtitle'),
    buttonText: t('sweetpass-celebration.button-text'),
    cloudinaryImageUrl: FALLBACK_IMAGE,
  };

  // ─── Contentful Data ─────────────────────────────────────────────────

  const { data: contentfulData, fetching: isLoadingContentfulData } =
    useContentfulContentTypeEntry<ContentfulSweetpassUpgradeCelebrationModal>({
      contentType: CONTENT_TYPE,
      include: 2,
    });

  // ─── Contentful Media ────────────────────────────────────────────────

  const contentfulImageUri = contentfulData?.fields?.image?.fields?.file?.url;
  const image: ImageURISource | undefined = contentfulImageUri
    ? { uri: contentfulImageUri }
    : undefined;

  // ─── Data Selection ──────────────────────────────────────────────────

  return {
    isLoadingContentfulData,
    image,
    headingText: contentfulData?.fields?.headingText ?? fallback.headingText,
    footerText: contentfulData?.fields?.footerText ?? fallback.footerText,
    buttonText: contentfulData?.fields?.buttonText ?? fallback.buttonText,
    cloudinaryImageUrl: fallback.cloudinaryImageUrl,
  };
}

// ─── Types ─────────────────────────────────────────────────────────────

type ContentfulSweetpassUpgradeCelebrationModal = Readonly<{
  name: string;
  headingText: string;
  footerText: string;
  buttonText: string;
  image: {
    fields: {
      file: {
        url: string;
      };
    };
  };
}>;

// ─── Constants ─────────────────────────────────────────────────────────

const CONTENT_TYPE = 'sweetpassUpgradeCelebrationModal';
const FALLBACK_IMAGE =
  'https://res.cloudinary.com/sweetgreen/image/upload/v1680031154/Card_Angle_5_Sweetpass_plus_Drop_Shadow_rr7kw8.png';

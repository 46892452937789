import React, { type ComponentProps, useCallback, useRef } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  Pressable,
  type PressableStateCallbackType,
  type StyleProp,
  StyleSheet,
  type ViewStyle,
} from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { BodyText, theme, webOnlyStyles } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuOnlineOrderingUnavailableButton = (
  props: MenuOnlineOrderingUnavailableButtonProps,
) => {
  const { style, ...restProps } = props;

  const ref = useRef(null);
  const isHovered = useHover(ref);

  // ─── Styles ──────────────────────────────────────────────────────────

  const pressableStyles = useCallback(
    (state: PressableStateCallbackType): StyleProp<ViewStyle> => {
      const { pressed } = state;

      return [
        styles.pressable,
        pressableWebStyles,
        isHovered ? styles.pressableHover : undefined,
        pressed ? styles.pressablePressed : undefined,
        style,
      ];
    },
    [isHovered, style],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable ref={ref} style={pressableStyles} {...restProps}>
      <BodyText style={styles.label} sizeMatch={['18']}>
        <FormattedMessage {...messages.label} />
      </BodyText>
    </Pressable>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  label: {
    defaultMessage: 'View other locations',
    description: 'Menu | Online ordering unavailable | Button label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: theme.spacing['2'],
    paddingHorizontal: theme.spacing['6'],
    borderWidth: 1,
    borderColor: theme.colors.KALE,
    backgroundColor: theme.colors.OPACITY.SPINACH.NEAR_LIGHTEST,
    borderRadius: 64,
    width: '100%',
    maxWidth: 285,
    minHeight: 64,
    userSelect: 'none',
  },
  pressableHover: {
    backgroundColor: theme.colors.OPACITY.SPINACH.LIGHT,
  },
  pressablePressed: {
    opacity: 0.7,
  },
  label: {
    textAlign: 'center',
  },
});

const pressableWebStyles = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: theme.spacing['1'],
  outlineWidth: 2,
  transition: `background-color ${theme.transitions.base}ms, border-color ${theme.transitions.base}ms, opacity ${theme.transitions.base}ms`,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuOnlineOrderingUnavailableButtonProps = {
  style?: ViewStyle;
} & Omit<ComponentProps<typeof Pressable>, 'children' | 'ref' | 'style'>;

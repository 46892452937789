import type { useSweetpassTier } from '@order/hooks';

/**
 * A helper to return the matching content tag, based on the current customer's
 * Sweetpass subscription tier and whether they have an active order.
 */
export function getHomeScreenHeroCardContentTag(
  params: GetHeroCardContentTagParams,
) {
  const { hasActiveOrder, sweetpassTierName } = params;

  const isSweetpassSubscriber = sweetpassTierName === 'SWEETPASS';
  const isSweetpassPlusSubscriber = sweetpassTierName === 'SWEETPASS_PLUS';

  const isSweetpassSubscriberWithoutActiveOrder =
    isSweetpassSubscriber && !hasActiveOrder;
  const isSweetpassSubscriberWithActiveOrder =
    isSweetpassSubscriber && hasActiveOrder;

  const isSweetpassPlusSubscriberWithoutActiveOrder =
    isSweetpassPlusSubscriber && !hasActiveOrder;
  const isSweetpassPlusSubscriberWithActiveOrder =
    isSweetpassPlusSubscriber && hasActiveOrder;

  // ─── Sweetpass Subscribers ───────────────────

  if (isSweetpassSubscriberWithoutActiveOrder) {
    return TAGS.WITHOUT_ACTIVE_ORDER.SWEETPASS;
  }

  if (isSweetpassSubscriberWithActiveOrder) {
    return TAGS.WITH_ACTIVE_ORDER.SWEETPASS;
  }

  if (isSweetpassPlusSubscriberWithoutActiveOrder) {
    return TAGS.WITHOUT_ACTIVE_ORDER.SWEETPASS_PLUS;
  }

  if (isSweetpassPlusSubscriberWithActiveOrder) {
    return TAGS.WITH_ACTIVE_ORDER.SWEETPASS_PLUS;
  }

  // ─── Other customers ─────────────────────────

  if (hasActiveOrder) return TAGS.WITH_ACTIVE_ORDER.DEFAULT;

  return TAGS.WITHOUT_ACTIVE_ORDER.DEFAULT;
}

// ─── Constants ───────────────────────────────────────────────────────────────

/**
 * For hero card content, we use six different content entries.
 *
 * Sweetpass subscribers will see the special content entries based on
 * their tiers and other users will see the default one.
 *
 * NOTE: Customers who have cancelled their Sweetpass subscription will
 *       see the default content.
 */
const TAGS = {
  WITHOUT_ACTIVE_ORDER: {
    DEFAULT: 'homeScreenHeroCardNoActiveOrder',
    SWEETPASS: 'homeScreenHeroCardNoActiveOrderSweetpass',
    SWEETPASS_PLUS: 'homeScreenHeroCardNoActiveOrderSweetpassPlus',
  },
  WITH_ACTIVE_ORDER: {
    DEFAULT: 'homeScreenHeroCardActiveOrder',
    SWEETPASS: 'homeScreenHeroCardActiveOrderSweetpass',
    SWEETPASS_PLUS: 'homeScreenHeroCardActiveOrderSweetpassPlus',
  },
} as const;

// ─── Types ───────────────────────────────────────────────────────────────────

type GetHeroCardContentTagParams = Readonly<{
  sweetpassTierName: SweetpassTierName;
  hasActiveOrder: boolean;
}>;

type SweetpassTierName = ReturnType<
  typeof useSweetpassTier
>['sweetpassTierName'];

export const personalData = {
  'personal-data.title': 'Sweetgreen Data Subject Requests',
  'personal-data.body': `Thank you for visiting our webform where residents of California, Virginia, and other states where privacy laws applicable to us grant their residents certain rights can submit requests regarding their personal information. Visit our {privacy_policy} for more information on these rights.

Please choose from the requests below and click Submit (if needed) to submit your request. Please note that if you request that sweetgreen delete your data, sweetgreen will need to delete your sweetgreen account entirely and that we will be unable to provide any online or digital services to you or support for past products or services that you have purchased.

If you would like to opt out of certain data sharing by sweetgreen, please visit us at {opt_out}.

If you would like to request information on how we handle your personal information, request that we correct inaccurate personal information or if you have any other questions about submitting requests, please call us at {phone}, or email us at {email}.`,
  'personal-data.access-cta': 'Request to access your data',
  'personal-data.delete-cta': 'Delete your data & account',

  'personal-data.opt-out.description':
    'https://www.sweetgreen.com/privacy-policy/ca-data-opt-out',
  'personal-data.opt-out.href':
    'https://www.sweetgreen.com/privacy-policy/ca-data-opt-out',

  'personal-data.email.description': 'hello@sweetgreen.com',
  'personal-data.email.href': 'mailto:hello@sweetgreen.com',

  'personal-data.phone.description': '(833) 309-9300',
  'personal-data.phone.href': 'tel:+18333099300',

  'personal-data.privacy-policy.description': 'privacy policy',
  'personal-data.privacy-policy.href':
    'https://www.sweetgreen.com/privacy-policy',
};

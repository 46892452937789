import {
  MenuOnlineOrderingUnavailableButton,
  MenuOnlineOrderingUnavailableContainer,
  MenuOnlineOrderingUnavailableDialog,
  MenuOnlineOrderingUnavailableMessage,
} from './components';

export const MenuOnlineOrderingUnavailable = {
  Container: MenuOnlineOrderingUnavailableContainer,
  Dialog: MenuOnlineOrderingUnavailableDialog,
  Message: MenuOnlineOrderingUnavailableMessage,
  Button: MenuOnlineOrderingUnavailableButton,
};

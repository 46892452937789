import type { ReactNode } from 'react';
import React, { forwardRef, memo } from 'react';
import type {
  Text as RNText,
  TextProps as RNTextProps,
  TextStyle,
} from 'react-native';
import { DEFAULT_FONT_FAMILY, WEIGHT_MAP } from '@garnish/constants';

import { BaseText } from '../BaseText';

// ─────────────────────────────────────────────────────────────────────────────

export const Text = memo(
  forwardRef<RNText, TextProps>((props, ref) => {
    const {
      family = DEFAULT_FONT_FAMILY,
      bold = false,
      underline = false,
      style,
      children,
      ...restProps
    } = props;

    const textStyles: TextStyle = {
      fontWeight: bold ? WEIGHT_MAP.bold : WEIGHT_MAP.normal,
      textDecorationLine: underline ? 'underline' : 'none',
      fontFamily: family,
    };

    return (
      <BaseText ref={ref} style={[textStyles, style]} {...restProps}>
        {children}
      </BaseText>
    );
  }),
);

// ─── Types ───────────────────────────────────────────────────────────────────

export type TextProps = Readonly<{
  family?: string;
  bold?: boolean;
  underline?: boolean;
  children: ReactNode;
}> &
  RNTextProps;

import { StyleSheet } from 'react-native';

import { useResponsive } from '../../../../hooks';
import {
  HERO_CARD_HEIGHT_MD,
  HERO_CARD_HEIGHT_SM,
  HERO_CARD_HEIGHT_XS,
} from '../../HeroCard.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const useHeroCardResponsiveStyles = () => {
  const { match } = useResponsive();

  return match(HERO_CARD_RESPONSIVE_STYLES);
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cardXS: {
    minHeight: HERO_CARD_HEIGHT_XS,
  },
  cardSM: {
    minHeight: HERO_CARD_HEIGHT_SM,
  },
  cardLG: {
    minHeight: HERO_CARD_HEIGHT_MD,
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const HERO_CARD_RESPONSIVE_STYLES = [
  styles.cardXS,
  styles.cardSM,
  styles.cardSM,
  styles.cardLG,
];

import { useCallback } from 'react';

import { useIsCustomerAddress } from '@order/hooks';

import { useNavigateToMainTabs } from '../../navigation';
import { useLastInteractedStore } from './useLastInteractedStore';

/**
 * Navigates to the last interacted store if available.
 * Fallback is to navigate to the menu tab itself.
 */
export const useNavigateToLastInteractedStore = () => {
  const navigateToMainTabs = useNavigateToMainTabs();

  const lastInteractedStore = useLastInteractedStore();
  const { restaurantSlug = '', addressId } = lastInteractedStore ?? {};

  const isCustomerAddress = useIsCustomerAddress(addressId);

  return useCallback(() => {
    if (restaurantSlug) {
      void navigateToMainTabs('MenuTab', {
        screen: 'Menu',
        params: { restaurantSlug },
      });

      return;
    }

    if (addressId && isCustomerAddress) {
      void navigateToMainTabs('MenuTab', {
        screen: 'DeliveryMenu',
        params: { addressId },
      });

      return;
    }

    void navigateToMainTabs('MenuTab');
  }, [restaurantSlug, addressId, isCustomerAddress, navigateToMainTabs]);
};

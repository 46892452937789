import { useCallback } from 'react';

import { type EditAddressInput } from '@order/graphql';

import { useUpdateBagDeliveryPreferencesMutation } from '../../GraphQL/BagDeliveryPreferences.generated';

export const useUpdateDeliveryPreferences = () => {
  const [updateDeliveryPreferencesResponse, updateDeliveryPreferencesMutation] =
    useUpdateBagDeliveryPreferencesMutation();

  const isUpdatingDeliveryPreferences =
    updateDeliveryPreferencesResponse?.fetching;

  const updateDeliveryPreferences = useCallback(
    async (input: EditAddressInput) => {
      return updateDeliveryPreferencesMutation({ input });
    },
    [updateDeliveryPreferencesMutation],
  );

  return {
    isUpdatingDeliveryPreferences,
    updateDeliveryPreferences,
  };
};

import React, { useCallback } from 'react';
import { theme, VStack } from '@sg/garnish';

import { NotFoundView } from '@order/components';
import { useTelemetry, useTrackEventEffect } from '@order/Telemetry';

import {
  GiftCardOrder,
  GiftCardOrdersLoadingPlaceholder,
  GiftCardOrdersNoHistoryView,
} from './components';
import { useGiftCardPurchaseHistoryQuery } from './graphql/GiftCardPurchaseHistory.generated';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardOrders = () => {
  const { track } = useTelemetry();

  // ─── Remote Data ─────────────────────────────────────────────────────

  const [{ data, fetching, stale, error }] = useGiftCardPurchaseHistoryQuery({
    requestPolicy: 'cache-and-network',
  });

  const response = data?.giftCardPurchaseHistory;
  const responseTypename = response?.__typename;
  const giftCardOrders = response?.giftCardOrders ?? [];

  // ─── Flags ───────────────────────────────────────────────────────────

  const hasSuccessfulResponse = responseTypename === 'GiftCardPurchaseHistory';
  const hasError = error || !hasSuccessfulResponse;
  const hasNoGiftCardOrders = giftCardOrders.length === 0;

  const shouldRenderLoadingView = fetching || stale;
  const shouldRenderNotFoundView = !shouldRenderLoadingView && hasError;
  const shouldRenderEmptyView = !shouldRenderLoadingView && hasNoGiftCardOrders;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onGiftCardPurchaseHistoryFail = useCallback(() => {
    track('gift.history_failure', {
      userError: responseTypename,
      systemError: error,
    });
  }, [error, responseTypename, track]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useTrackEventEffect({ name: 'gift.history_viewed' });

  // ─────────────────────────────────────────────────────────────────────

  if (shouldRenderLoadingView) {
    return <GiftCardOrdersLoadingPlaceholder />;
  }

  if (shouldRenderNotFoundView) {
    return (
      <NotFoundView
        withoutFooter
        withoutCta
        onMount={onGiftCardPurchaseHistoryFail}
      />
    );
  }

  if (shouldRenderEmptyView) {
    return <GiftCardOrdersNoHistoryView />;
  }

  return <GiftCardOrdersReady giftCardOrders={giftCardOrders} />;
};

// ─────────────────────────────────────────────────────────────────────────────

const GiftCardOrdersReady = (props: GiftCardOrdersReadyProps) => {
  const { giftCardOrders } = props;

  // ─── Effects ─────────────────────────────────────────────────────────

  useTrackEventEffect({ name: 'gift.history_success' });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <VStack
      gap={theme.spacing['10']}
      hasDivider
      dividerColor={theme.colors.LIGHT}
    >
      {giftCardOrders.map((order) => {
        const { id, purchasedAt, deliveryDetail, userGiftCards } = order;

        const mainGiftCard = userGiftCards?.[0];
        const { recipientName, balance } = mainGiftCard ?? {};

        return (
          <GiftCardOrder
            key={id}
            id={id}
            recipientName={recipientName}
            deliveryMethods={deliveryDetail.deliveryMethods}
            balance={balance}
            purchaseDate={purchasedAt}
          />
        );
      })}
    </VStack>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardOrdersReadyProps = {
  giftCardOrders: ReadonlyArray<{
    id: string;
    purchasedAt: string;
    deliveryDetail: {
      deliveryMethods: ReadonlyArray<string>;
    };
    userGiftCards: ReadonlyArray<{
      id: string;
      balance: number;
      recipientEmail: string | null;
      recipientName: string;
    }>;
  }>;
};

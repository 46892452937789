/* istanbul ignore file */

import React from 'react';
import type { FocusScopeProps } from '@react-aria/focus';
import { FocusScope } from '@react-aria/focus';

// ────────────────────────────────────────────────────────────────────────────────

export const BagFocusScope = (props: FocusScopeProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, autoFocus, ...restProps } = props;

  return (
    <FocusScope
      autoFocus={false} // the `autoFocus` feature can result in unexpected scroll jumps and non-selectable content
      restoreFocus
      contain
      {...restProps}
    >
      {children}
    </FocusScope>
  );
};

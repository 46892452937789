import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IllusEmployeeBag, IllusEmpty_2 } from '@sg/garnish';

import { OrderAgainEmptyViewContainer } from './components';
import { orderAgainEmptyViewMessages as messages } from './OrderAgainEmptyView.messages';

// ─── Variations ──────────────────────────────────────────────────────────────

const OrderAgainEmptyViewOrders = () => {
  const { formatMessage } = useIntl();

  return (
    <OrderAgainEmptyViewContainer
      illustrationSrc={IllusEmployeeBag}
      illustrationAlt={formatMessage(messages.ordersEmptyViewIllustrationAlt)}
    >
      <FormattedMessage {...messages.ordersEmptyViewText} />
    </OrderAgainEmptyViewContainer>
  );
};

const OrderAgainEmptyViewFavorites = () => {
  const { formatMessage } = useIntl();

  return (
    <OrderAgainEmptyViewContainer
      illustrationSrc={IllusEmpty_2}
      illustrationAlt={formatMessage(
        messages.favoritesEmptyViewIllustrationAlt,
      )}
    >
      <FormattedMessage {...messages.favoritesEmptyViewText} />
    </OrderAgainEmptyViewContainer>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainEmptyView = {
  Orders: OrderAgainEmptyViewOrders,
  Favorites: OrderAgainEmptyViewFavorites,
};

import {
  HowItWorksCard,
  HowItWorksCardsContainer,
  HowItWorksContainer,
  HowItWorksCta,
  HowItWorksFooter,
  HowItWorksHeader,
  HowItWorksHeaderClose,
  HowItWorksHeaderTitle,
} from './components';

export const LoyaltyHowItWorks = {
  Container: HowItWorksContainer,
  CardsContainer: HowItWorksCardsContainer,
  Header: HowItWorksHeader,
  HeaderTitle: HowItWorksHeaderTitle,
  HeaderClose: HowItWorksHeaderClose,
  Card: HowItWorksCard,
  Footer: HowItWorksFooter,
  Cta: HowItWorksCta,
};

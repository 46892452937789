import type { ComponentProps } from 'react';
import React, { useMemo } from 'react';
import type {
  ImageSourcePropType,
  PressableProps,
  ViewProps,
} from 'react-native';
import { ImageBackground, StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../hooks';
import { Button } from '../Button';
import { BodyText, TitleText } from '../Text';

// ────────────────────────────────────────────────────────────────────────────────

export const ProductBannerCardWithOverlay = (
  props: ProductBannerCardWithOverlayProps,
) => {
  const {
    title,
    description,
    onCtaPress,
    ctaLabel = 'Get started',
    isDisabled,
    backgroundImage,
    backgroundOverlayColor = 'rgba(0, 0, 0, 0.3)',
    textColor = theme.colors.NEUTRAL_7,
    ctaPalette = 'light',
    ...viewProps
  } = props;

  const { minWidth, match } = useResponsive();

  // ─────────────────────────────────────────────────────────────────────

  const ctaSize: ComponentProps<typeof Button>['size'] = minWidth.isMD
    ? 'large'
    : 'medium';

  // ─── Styles ──────────────────────────────────────────────────────────

  const wrapperStyle = [
    styles.wrapper,
    minWidth.isLG ? styles.wrapperLG : styles.wrapperXS,
  ];
  const imageOverlayDynamicStyle = useStyle(
    () => ({ backgroundColor: backgroundOverlayColor }),
    [backgroundOverlayColor],
  );
  const imageOverlayStyle = [styles.imgOverlay, imageOverlayDynamicStyle];
  const textContentWrapper = [
    styles.textContentWrapper,
    match([
      styles.textContentWrapperXS,
      styles.textContentWrapperSM,
      styles.textContentWrapperMD,
    ]),
  ];
  const textDynamicStyle = useStyle(() => ({ color: textColor }), [textColor]);
  const titleStyle = [
    styles.title,
    match([styles.titleXS, undefined]),
    textDynamicStyle,
  ];
  const descriptionStyle = [
    styles.description,
    match([styles.descriptionXS, undefined]),
    textDynamicStyle,
  ];

  // ─────────────────────────────────────────────────────────────────────

  const imgBgSource = useMemo<ImageSourcePropType>(
    () => ({ uri: backgroundImage }),
    [backgroundImage],
  );

  return (
    <View style={wrapperStyle} {...viewProps}>
      {backgroundImage ? (
        <ImageBackground
          source={imgBgSource}
          style={StyleSheet.absoluteFill}
          aria-label={title}
        >
          <View style={imageOverlayStyle} />
        </ImageBackground>
      ) : null}

      <View style={styles.textContentOuterWrapper}>
        <View style={textContentWrapper}>
          <TitleText style={titleStyle} sizeMatch={['40', '48', '56', '64']}>
            {title}
          </TitleText>

          <BodyText size={1} style={descriptionStyle}>
            {description}
          </BodyText>

          <Button
            size={ctaSize}
            rightIcon="IconArrowRight"
            onPress={onCtaPress}
            disabled={isDisabled}
            palette={ctaPalette}
          >
            {ctaLabel}
          </Button>
        </View>
      </View>
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    overflow: 'hidden',
    flex: 1,
    backgroundColor: theme.colors.KALE,
  },
  wrapperXS: {
    minHeight: 340,
    borderRadius: theme.radius.medium,
  },
  wrapperLG: {
    minHeight: 432,
    borderRadius: theme.radius.large,
  },

  imgOverlay: {
    width: '100%',
    height: '100%',
  },

  textContentOuterWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  textContentWrapper: {
    width: '100%',
    alignItems: 'flex-start',
  },
  textContentWrapperXS: {
    padding: theme.spacing['6'],
    alignItems: 'center',
    flexShrink: 1,
  },
  textContentWrapperSM: {
    paddingVertical: theme.spacing['8'],
    paddingHorizontal: theme.spacing['16'],
  },
  textContentWrapperMD: {
    alignItems: 'flex-start',
    paddingHorizontal: theme.spacing['30'],
    paddingVertical: theme.spacing['10'],
  },

  title: {
    marginBottom: theme.spacing['6'],
  },
  titleXS: {
    textAlign: 'center',
  },

  // ─── DESCRIPTION ─────────────────────────────────────────────────

  description: {
    marginBottom: theme.spacing['6'],
  },
  descriptionXS: {
    textAlign: 'center',
    marginBottom: theme.spacing['4'],
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ProductBannerCardWithOverlayProps = Readonly<{
  title: string;
  description: string;
  onCtaPress: PressableProps['onPress'];
  ctaLabel?: string;
  ctaPalette?: ComponentProps<typeof Button>['palette'];
  isDisabled?: boolean;
  backgroundImage?: string;
  backgroundOverlayColor?: string;
  textColor?: string;
}> &
  Omit<ViewProps, 'children'>;

import { logger as LOG, logger } from '@garnish/logger';
import { uniqBy } from 'lodash';

import { type FavoriteWithId } from './favorites-machine.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A tiny utility that removes potential duplicates from the favorites list,
 * which can occur if the user loads more favorites with a delay.
 */
export function withoutDuplicateFavorites<Favorite extends FavoriteWithId>(
  favorites: Favorite[],
): Favorite[] {
  return uniqBy(favorites, 'id');
}

/**
 * A utility for sorting favorites lists based on the IDs of the favorite.
 */
export function sortFavoritesById<Favorite extends FavoriteWithId>(
  favorites: Favorite[],
): Favorite[] {
  return [...favorites].sort(
    (currentFavorite, nextFavorite) =>
      Number(nextFavorite.id) - Number(currentFavorite.id),
  );
}

// ─── Logger ──────────────────────────────────────────────────────────────────

LOG.enable('FAVORITES STATE MACHINE');

export const favoritesMachineLogger = logger.extend('FAVORITES STATE MACHINE');

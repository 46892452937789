import React from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../hooks';
import { usePressableState } from '../../hooks/usePressableState';
import { Icon } from '../Icon';
import type { StarIconProps, StarProps } from './Rating.types';
import { getAccessibilityLabel } from './Rating.utils';

export const Star = React.memo((props: StarProps) => {
  const ref = React.useRef(null);
  const { starValue, checked, onHover, onChange } = props;
  const { isInteracting } = usePressableState(ref);
  const accessibilityLabel = getAccessibilityLabel(props);
  const { currentBreakpoint } = useResponsive();

  // ──────── Sync Hovered Rating ─────────
  React.useEffect(() => {
    onHover(isInteracting ? starValue : 0);
  }, [starValue, isInteracting, onHover]);

  // ──────── Callbacks ─────────
  const handleRating = React.useCallback(() => {
    onChange(starValue);
  }, [starValue, onChange]);

  return (
    <Pressable
      testID={`sg-rating-star-${starValue}`}
      ref={ref}
      style={[styles.star, currentBreakpoint.isXS && styles.mobileStar]}
      accessibilityRole="radio"
      accessibilityLabel={accessibilityLabel}
      accessibilityState={{ checked }}
      onPress={handleRating}
    >
      <StarIcon active={checked} onPress={handleRating} />
    </Pressable>
  );
});

const STAR_ICON_SIZE = 32;
const StarIcon = React.memo(({ active, onPress }: StarIconProps) => {
  const size = { width: STAR_ICON_SIZE, height: STAR_ICON_SIZE };
  const color = active ? theme.colors.KALE : theme.colors.DARK_KALE;
  const name = active ? 'IconStarFill' : 'IconStarStroke';

  return <Icon onTouchStart={onPress} {...size} color={color} name={name} />;
});

const styles = StyleSheet.create({
  star: {
    paddingHorizontal: theme.spacing['1'],
    alignItems: 'center',
  },
  mobileStar: {
    paddingHorizontal: 0,
    marginHorizontal: theme.spacing['1'],
  },
});

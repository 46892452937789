import React from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';
import { theme } from '@sg/garnish';

export const HomeFloatingButtons = (props: ViewProps) => {
  return (
    <View pointerEvents="box-none" style={styles.floatingButtons}>
      {props.children}
    </View>
  );
};

const styles = StyleSheet.create({
  floatingButtons: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing['4'],
  },
});

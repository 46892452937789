import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ButtonSearch, theme, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationsMapSearchArea = (props: SearchAreaProps) => {
  const { match } = useResponsive();
  const { t } = useLocalizationContext();

  const wrapperStyle = [styles.wrapper, match([undefined, styles.wrapperSM])];

  return (
    <View style={wrapperStyle} pointerEvents="box-none">
      <ButtonSearch
        accessibilityLabel={t('location.map-search-area')}
        accessibilityHint={t('location.map-search-area-a11y-hint')}
        isLoading={props.isLoading}
        disabled={props.disabled}
        onPress={props.onPress}
      >
        {t('location.map-search-area')}
      </ButtonSearch>
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    ...StyleSheet.absoluteFillObject,
    bottom: 'auto',
    zIndex: 1,
    flexDirection: 'row',
    paddingTop: theme.spacing['4'],
    paddingHorizontal: theme.spacing['4'],
  },
  wrapperSM: {
    justifyContent: 'center',
    paddingTop: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type SearchAreaProps = Pick<
  React.ComponentProps<typeof ButtonSearch>,
  'onPress' | 'isLoading' | 'disabled'
>;

import React from 'react';

/* eslint-disable functional/immutable-data */

// Runs effect only once.
// The effect should return true if it shouldn't be run again.
export const useEffectOnce = (effect: () => boolean, deps: any[]) => {
  const guard = React.useRef(false);

  React.useEffect(() => {
    if (guard.current) return;

    if (effect()) {
      guard.current = true;
    }
  }, [effect, deps]);
};

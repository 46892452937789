import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';

import { useIsLoggedIn } from '@order/AuthMachine';
import {
  LoadingAnimation,
  SweetpassLandingPage,
  useSweetpassLandingPageContent,
} from '@order/components';
import { useLocalizationContext } from '@order/Localization';

import { useSweetpassHomeScreenContentFallback } from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const SweetpassHomeScreenSignedOut = () => {
  const isLoggedIn = useIsLoggedIn();
  const { navigate } = useNavigation();
  const { t } = useLocalizationContext();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToLoginScreen = useCallback(() => {
    navigate('Auth', {
      screen: 'Login',
      params: { redirect: 'sweetpass' },
    });
  }, [navigate]);

  // ─── Content ─────────────────────────────────────────────────────────

  const contentFallback = useSweetpassHomeScreenContentFallback({
    onCtaPress: navigateToLoginScreen,
  });

  const { content, fetching } = useSweetpassLandingPageContent({
    tag: 'sweetpassSignedOutHomepage',
    pause: isLoggedIn,
    onCtaPress: navigateToLoginScreen,
    contentFallback,
  });

  // ─────────────────────────────────────────────────────────────────────

  if (fetching) return <LoadingAnimation />;

  return (
    <SweetpassLandingPage
      title={t('sweetpass')}
      headerContent={content.header}
      benefitsContent={content.benefits}
      ctaContent={content.cta}
      withTopPadding
      withSafeAreaInset={false}
    />
  );
};

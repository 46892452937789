/* eslint-disable react/iframe-missing-sandbox */
import React, { useCallback, useLayoutEffect } from 'react';
import { Platform } from 'react-native';
import { Modal, TransparentModal, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type { PersonalDataDetailsScreenProps } from '../../navigation/AppNavigation.props';

export const PersonalDataDetailsScreen = ({
  navigation,
  route,
}: PersonalDataDetailsScreenProps) => {
  const { match, currentBreakpoint } = useResponsive();
  const { t } = useLocalizationContext();

  // Personal data request can be for access or deletion.
  const isAccessRequest = route?.params?.action === 'access';

  // Dismissing modal just goes back on navigation.
  const handleDismiss = useCallback(() => {
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.getParent()?.goBack();
  }, [navigation]);

  // Dynamic title based on received navigation parameter.
  const headerTitle = t(
    isAccessRequest ? 'personal-data.access-cta' : 'personal-data.delete-cta',
  );

  // Mobile title.
  useLayoutEffect(() => {
    navigation.setOptions({ headerTitle });
  }, [navigation, headerTitle]);

  // Dynamic iFrame URL based on received navigation parameter.
  const oneTrustUrl = isAccessRequest
    ? ONE_TRUST_ACCESS_URL
    : ONE_TRUST_DELETE_URL;

  // This is just in case, it is not possible to navigate here directly.
  if (Platform.OS !== 'web') return null; // disabled on native platforms.

  return (
    <TransparentModal size={match(['full', 'large'])} onClose={handleDismiss}>
      {currentBreakpoint.isXS ? null : (
        <Modal.Header onClose={handleDismiss}>{headerTitle}</Modal.Header>
      )}
      <iframe
        src={oneTrustUrl}
        style={match([frameStyleSM, frameStyleLG])}
        id="one-trust-ca"
        title="one-trust-ca"
      />
    </TransparentModal>
  );
};

// ─── Constants ──────────────────────────────────────────────────────
const ONE_TRUST_ACCESS_URL =
  'https://privacyportal.onetrust.com/webform/21a1d4f5-76a6-4640-87ad-0e9b616c1963/61a7235b-359e-4316-8c79-03620685bd69';
const ONE_TRUST_DELETE_URL =
  'https://privacyportal.onetrust.com/webform/21a1d4f5-76a6-4640-87ad-0e9b616c1963/b9ce941b-7bf5-4377-8ef0-ddb32fa17a89';

// ─── Styles ─────────────────────────────────────────────────────────
const frameStyleSM = { border: 0, height: '100%' };
const frameStyleLG = { border: 0, height: 460, maxHeight: '100%' };

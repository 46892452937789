import { useCallback } from 'react';
import type { AddressType } from '@sg/garnish';

import { useCart } from '@order/hooks';

import { useBagRestaurantIds } from '../../../../components/Bag/Bag.hooks';
import type { DeliveryOrderDetail } from '../../../../graphql/types';
import { useNavigateToMenu } from '../../../../hooks/useNavigateToMenu';
import { useConflictReview } from '../../../ReorderingScreen/hooks/useConflictReview';

// Selecting a featured channel to go to a menu should reorder if the bag menu is different.
// If there is an in-flight delivery order, should not reorder to delivery.
export const useHomeFeaturedChannelsNavigation = () => {
  const navigateToMenu = useNavigateToMenu();
  const conflictReview = useConflictReview();
  const { cart } = useCart();
  const { bagOrderType, bagRestaurantSlug, bagAddressId } =
    useBagRestaurantIds();

  return useCallback(
    (
      payload: Readonly<{
        orderType: AddressType;
        restaurantId?: string;
        restaurantSlug?: string;
        restaurantName?: string;
        deliveryOrderDetail?: Partial<DeliveryOrderDetail>;
      }>,
    ) => {
      const isSameOrderType = payload.orderType === bagOrderType;
      const isSameRestaurantSlug =
        Boolean(bagRestaurantSlug) &&
        payload.restaurantSlug === bagRestaurantSlug;
      const isSameAddressId =
        Boolean(bagAddressId) &&
        payload.deliveryOrderDetail?.address?.id === bagAddressId;
      const hasBag = Boolean(bagRestaurantSlug) || Boolean(bagAddressId);
      const isBagDifferent =
        !isSameOrderType || !(isSameRestaurantSlug || isSameAddressId);
      const needsToReorder = hasBag && isBagDifferent;

      // default behavior without reorder is to open the menu.
      if (!needsToReorder) {
        navigateToMenu(payload);

        return;
      }

      // if your bag belongs to a different restaurant, start reorder flow
      void conflictReview({
        orderId: cart?.id,
        orderType: payload.orderType,
        deliveryAddressName: payload.deliveryOrderDetail?.address?.name ?? '',
        deliveryOrderDetail: payload.deliveryOrderDetail?.address?.id
          ? {
              addressId: payload.deliveryOrderDetail?.address?.id,
              ...payload.deliveryOrderDetail,
            }
          : undefined,
        restaurantId: payload.restaurantId,
        restaurantSlug: payload.restaurantSlug,
        restaurantName: payload.restaurantName,
        finishOnMenu: true,
      });
    },
    [
      cart?.id,
      bagAddressId,
      bagOrderType,
      bagRestaurantSlug,
      conflictReview,
      navigateToMenu,
    ],
  );
};

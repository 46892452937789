import React from 'react';
import { useNoticeBannersStackContext, useUrqlPolling } from '@sg/garnish';

import type { AvailableWantedTime } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { getWantedTimeFormatting } from '../../Checkout.utils';
import { useGetAvailableWantedTimesQuery } from '../../graphql/Checkout.generated';

export const ONE_MINUTE_IN_MILLISECONDS = 1000 * 60;

// Wanted times has local state so it can be overwritten on checkout error.
export const useAvailableWantedTimes = () => {
  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  const [availableWantedTimes, setAvailableWantedTimes] = React.useState<
    readonly AvailableWantedTime[]
  >([]);

  const { response, reexecuteQuery } = useUrqlPolling(
    ONE_MINUTE_IN_MILLISECONDS,
    undefined,
    useGetAvailableWantedTimesQuery,
  );

  const { data } = response;
  const wantedTimesResults = data?.cart?.availableWantedTimes;

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setAvailableWantedTimes(wantedTimesResults ?? []);
  }, [wantedTimesResults]);

  // Upon invalid wanted time error, remove the used wanted time && refetch.
  const handleInvalidWantedTime = React.useCallback(
    (wantedTime?: string) => {
      setAvailableWantedTimes((wantedTimes) => {
        const selectedWantedTime = wantedTime ?? wantedTimes[0].time;
        const timeSlot = getWantedTimeFormatting(selectedWantedTime, t);
        const message = timeSlot
          ? t('checkout.timeslot-unavailable-with-timeslot', {
              timeslot: timeSlot,
            })
          : t('checkout.timeslot-unavailable-with-timeslot-static');

        addNoticeBanner({ text: message, palette: 'caution' });

        return selectedWantedTime
          ? wantedTimes.filter((slot) => slot.time !== selectedWantedTime)
          : wantedTimes.slice(1);
      });
      reexecuteQuery();
    },
    [addNoticeBanner, reexecuteQuery, t],
  );

  return {
    loadingAvailableWantedTimes: !wantedTimesResults,
    availableWantedTimes,
    handleInvalidWantedTime,
  };
};

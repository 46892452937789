import type { ComponentProps } from 'react';
import React, { useMemo } from 'react';
import type { ViewProps, ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';
import { BODY, DISPLAY_WIDE } from '@garnish/constants';

import { useResponsive } from '../../hooks';
import { Container } from '../Container';
import type { CloudinaryTransformConfig } from '../Image';
import { CloudinaryImage } from '../Image';
import { BodyText, DisplayWideText, LabelText } from '../Text';
import {
  CollectionHeroLoadingPlaceholder,
  useCollectionHeroLoadingPlaceholder,
} from './CollectionHeroLoadingPlaceholder';

// ────────────────────────────────────────────────────────────────────────────────

export const CollectionHero = (props: CollectionHeroProps) => {
  const {
    collectionsLabel = 'Collections',
    heading,
    description,
    imgUrl,
    backgroundColor,
    isLoading,
    ...viewProps
  } = props;

  return (
    <CollectionHeroWrapper
      backgroundColor={backgroundColor}
      isLoading={isLoading}
      {...viewProps}
    >
      <CollectionHeroImageSection imgUrl={imgUrl} isLoading={isLoading} />

      <CollectionHeroDetailsSection
        collectionsLabel={collectionsLabel}
        heading={heading}
        description={description}
        isLoading={isLoading}
      />
    </CollectionHeroWrapper>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const CollectionHeroWrapper = (props: CollectionHeroWrapperProps) => {
  const {
    children,
    backgroundColor = theme.colors.SPINACH,
    isLoading,
    testID,
  } = props;
  const { minWidth } = useResponsive();

  const wrapperBackgroundColor = useStyle(
    () => ({ backgroundColor }),
    [backgroundColor],
  );
  const wrapperStyle = [
    wrapperBackgroundColor,
    minWidth.isMD && styles.wrapperMD,
    minWidth.isMD && isLoading && styles.wrapperLoadingMD,
  ];

  return (
    <View style={wrapperStyle} testID={testID}>
      {children}
    </View>
  );
};

const CollectionHeroImageSection = (props: CollectionHeroImageSectionProps) => {
  const { imgUrl, isLoading } = props;
  const { match } = useResponsive();
  const { wrapperHeight, updateWrapperHeight } =
    useCollectionHeroLoadingPlaceholder();

  // ─── Styles ──────────────────────────────────────────────────────

  const wrapperStyle = match([
    styles.imageSectionXS,
    styles.imageSectionSM,
    styles.imageSectionMD,
  ]);

  // ─── Image ───────────────────────────────────────────────────────

  const imageWidth = match([400, 800, 700, 900]);
  const imageTransformConfig = useMemo<CloudinaryTransformConfig>(
    () => ({ width: imageWidth }),
    [imageWidth],
  );

  // ─────────────────────────────────────────────────────────────────

  return (
    <View style={wrapperStyle} onLayout={updateWrapperHeight}>
      {isLoading ? (
        <CollectionHeroLoadingPlaceholder height={wrapperHeight} />
      ) : (
        <CloudinaryImage
          testID="sg-collection-hero.img"
          baseUrl={imgUrl}
          resizeMode="cover"
          style={styles.image}
          config={imageTransformConfig}
        />
      )}
    </View>
  );
};

const CollectionHeroDetailsSection = (
  props: CollectionHeroDetailsSectionProps,
) => {
  const {
    collectionsLabel = 'Collections',
    heading,
    description,
    isLoading,
  } = props;
  const { minWidth, match } = useResponsive();
  const { wrapperHeight, updateWrapperHeight } =
    useCollectionHeroLoadingPlaceholder();

  const shouldShowCollectionsLabel = minWidth.isSM;

  const collectionsLabelStyle = [styles.detailsBody, styles.collectionsLabel];
  const wrapperLoadingStyle = match([
    styles.detailsSectionLoadingXS,
    styles.detailsSectionLoadingSM,
    {},
  ]);
  const wrapperStyle = [
    minWidth.isMD && styles.detailsSectionMD,
    isLoading && wrapperLoadingStyle,
  ];
  const containerStyle = match([
    styles.detailsContainerXS,
    styles.detailsContainerSM,
  ]);
  const headingStyle = [
    styles.detailsBody,
    match([styles.headingXS, styles.headingSM]),
  ];
  const bodyStyle = [
    styles.detailsBody,
    match([styles.detailsBodyXS, styles.detailsBodySM]),
  ];

  return (
    <View style={wrapperStyle} onLayout={updateWrapperHeight}>
      {isLoading ? (
        <CollectionHeroLoadingPlaceholder height={wrapperHeight} speed={1.6} />
      ) : (
        <Container style={containerStyle}>
          {shouldShowCollectionsLabel ? (
            <LabelText size={1} style={collectionsLabelStyle}>
              {collectionsLabel}
            </LabelText>
          ) : null}

          <DisplayWideText style={headingStyle}>{heading}</DisplayWideText>

          <BodyText style={bodyStyle}>{description}</BodyText>
        </Container>
      )}
    </View>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  //
  // ─── WRAPPER ─────────────────────────────────────────────────────

  wrapperMD: {
    flexDirection: 'row-reverse',
    minHeight: 484,
  },
  wrapperLoadingMD: {
    height: 484,
  },

  // ─── DETAILS ─────────────────────────────────────────────────────

  detailsSectionMD: {
    justifyContent: 'center',
    width: '50%',
  },
  detailsSectionLoadingXS: {
    height: 140,
  },
  detailsSectionLoadingSM: {
    height: 270,
  },
  detailsContainerXS: {
    paddingVertical: theme.spacing['6'],
  },
  detailsContainerSM: {
    paddingVertical: theme.spacing['10'],
  },
  detailsBody: {
    color: theme.colors.OATMEAL,
  },
  detailsBodyXS: {
    ...BODY['16'],
    fontWeight: '400',
  },
  detailsBodySM: {
    ...BODY['24'],
    fontWeight: '400',
  },
  collectionsLabel: {
    paddingBottom: theme.spacing['4'],
  },
  headingXS: {
    ...DISPLAY_WIDE['32'],
    paddingBottom: theme.spacing['2'],
  },
  headingSM: {
    paddingBottom: theme.spacing['4'],
  },

  // ─── IMAGE ───────────────────────────────────────────────────────

  imageSectionXS: {
    height: 260,
  },
  imageSectionSM: {
    height: 460,
  },
  imageSectionMD: {
    width: '50%',
  },
  image: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type CollectionHeroProps = CollectionHeroWrapperProps &
  CollectionHeroDetailsSectionProps &
  CollectionHeroImageSectionProps;

type CollectionHeroWrapperProps = Readonly<{
  backgroundColor?: ViewStyle['backgroundColor'];
  isLoading?: boolean;
}> &
  ViewProps;

type CollectionHeroDetailsSectionProps = Readonly<{
  collectionsLabel?: string;
  heading: string;
  description?: string;
  isLoading?: boolean;
}>;

type CollectionHeroImageSectionProps = Readonly<{
  imgUrl: ComponentProps<typeof CloudinaryImage>['baseUrl'];
  isLoading?: boolean;
}>;

import { DisplayText } from '@sg/garnish';

import {
  JoinSgRewards,
  LoyaltyBag,
  LoyaltyFAQ,
  LoyaltyFAQEntry,
  LoyaltyHome,
  LoyaltyHowItWorks,
  LoyaltyIconSgReward,
  LoyaltyInfoBenefits,
  LoyaltyOfferCard,
  LoyaltyOffers,
  LoyaltyPointsHistory,
  LoyaltyReward,
  LoyaltyRewardTerms,
  ScanAtCheckoutFooter,
  ScanAtCheckoutFooterText,
  ScanAtCheckoutHeader,
  ScanAtCheckoutHowItWorks,
  ScanAtCheckoutRewardOptionsModal,
} from './components';
export * from './utils';

export const Loyalty = {
  Bag: LoyaltyBag,
  Home: LoyaltyHome,
  FAQ: LoyaltyFAQ,
  FAQEntry: LoyaltyFAQEntry,
  HowItWorks: LoyaltyHowItWorks,
  InfoBenefits: LoyaltyInfoBenefits,
  PointsHistory: LoyaltyPointsHistory,
  IconSgReward: LoyaltyIconSgReward,
  Reward: LoyaltyReward,
  RewardTerms: LoyaltyRewardTerms,
  JoinSgRewards,
  Offers: LoyaltyOffers,
  OfferCard: LoyaltyOfferCard,
};

export const ScanAtCheckout = {
  Header: ScanAtCheckoutHeader,
  HeaderTitle: DisplayText,
  Footer: ScanAtCheckoutFooter,
  FooterText: ScanAtCheckoutFooterText,
  HowItWorks: ScanAtCheckoutHowItWorks,
  RewardOptionsModal: ScanAtCheckoutRewardOptionsModal,
};

export {
  useInitLoyaltyOffersModalMachine,
  useLoyaltyOffersModalMachine,
} from './components';

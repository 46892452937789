import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { differenceInHours } from 'date-fns';
import { Button, LabelText, theme, TYPE_CONFIG } from '@sg/garnish';

/**
 * Cta for applying the loyalty reward.
 */
export const LoyaltyRewardApplyCta = (props: LoyaltyRewardApplyCtaProps) => {
  const {
    redeemableAt,
    isApplied = false,
    isLoading,
    isDisabled,
    onPress,
  } = props;

  const { formatMessage } = useIntl();

  const palette = isApplied ? ('spinach' as const) : ('spinachMuted' as const);
  const accessibilityLabel = isApplied
    ? formatMessage(messages.removeA11yLabel)
    : formatMessage(messages.applyA11yLabel);

  if (redeemableAt) {
    return (
      <LabelText style={styles.redeemableDate} sizeMatch={['14']}>
        {formatMessage(messages.redeemableDate, {
          date: differenceInHours(new Date(redeemableAt), new Date()),
        })}
      </LabelText>
    );
  }

  return (
    <Button
      size="large-wide"
      style={styles.button}
      palette={palette}
      accessibilityLabel={accessibilityLabel}
      isLoading={isLoading}
      disabled={isDisabled}
      onPress={onPress}
    >
      {isApplied ? (
        <FormattedMessage {...messages.removeCta} />
      ) : (
        <FormattedMessage {...messages.applyCta} />
      )}
    </Button>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  applyA11yLabel: {
    defaultMessage: 'Applies this reward to your order',
    description: 'Loyalty | Reward | Apply CTA a11y',
  },
  removeA11yLabel: {
    defaultMessage: 'Removes this reward from your order',
    description: 'Loyalty | Reward | Apply CTA a11y',
  },
  applyCta: {
    defaultMessage: 'Apply',
    description: 'Loyalty | Reward | Apply CTA',
  },
  removeCta: {
    defaultMessage: 'Applied',
    description: 'Loyalty | Reward | Remove CTA',
  },
  redeemableDate: {
    defaultMessage: 'Use again in {date} hrs',
    description: 'Loyalty | Reward | Redeemable date',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  redeemableDate: {
    color: theme.colors.CHARCOAL,
  },
  button: {
    alignSelf: 'flex-start',
    paddingHorizontal: theme.spacing['6'],
    height: 32,
    ...TYPE_CONFIG.BODY['18'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyRewardApplyCtaProps = {
  redeemableAt: string | undefined | null;
  isApplied: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  onPress: () => void;
};

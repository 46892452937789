import React, { memo } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { Button, Icon, Text, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

/**
 * This is *not* the pay with Apple Pay button.
 * Instead it is a button that triggers the Apple Pay payment sheet.
 * The card will be captured and processed in the backend, not in the frontend.
 */
export const ApplePayCheckoutButton = memo(
  (props: ApplePayCheckoutButtonProps) => {
    const { isLoading, disabled, onPress } = props;

    const { t } = useLocalizationContext();
    const checkoutApplePay = t('checkout.place-order.apple-pay');
    const accessibilityLabel = t('checkout.place-order.apple-pay.a11y');

    return (
      <Button
        palette="black"
        size="large"
        style={[
          styles.applePayButton,
          disabled ? styles.applePayDisabled : null,
        ]}
        isLoading={isLoading}
        disabled={disabled}
        accessibilityLabel={accessibilityLabel}
        onPress={onPress}
      >
        <Text style={styles.applePayText}>
          {checkoutApplePay}
          <Icon
            name="IconApplePay"
            width={48}
            height={Platform.select({ ios: 30, web: 24 })}
          />
        </Text>
      </Button>
    );
  },
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  applePayButton: {
    borderRadius: theme.radius.xxxlarge,
  },
  applePayDisabled: {
    backgroundColor: theme.colors.CHARCOAL,
  },
  applePayText: {
    display: 'flex',
    lineHeight: 22,
    color: theme.colors.WHITE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ApplePayCheckoutButtonProps = {
  isLoading?: boolean;
  disabled?: boolean;
  onPress?: () => void;
};

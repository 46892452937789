export const sweetpassLandingPageSignedOutContentfulFallback = {
  fields: {
    name: 'Sweetpass signed out homepage',
    content: [
      {
        metadata: {
          tags: [],
        },
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'wme4s8lvzccr',
            },
          },
          id: '3u9DttaNPTK2nIeZwAIw4O',
          type: 'Entry',
          createdAt: '2023-05-03T19:38:32.538Z',
          updatedAt: '2023-05-03T20:10:55.936Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 2,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'sweetpassUpgradeHeader',
            },
          },
          locale: 'en-US',
        },
        fields: {
          name: 'Sweetpass signed out homepage | Header',
          heroImage: {
            metadata: {
              tags: [],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              id: 'ggzHVvUXMBObRChNdT3bp',
              type: 'Asset',
              createdAt: '2023-05-03T19:37:54.257Z',
              updatedAt: '2023-05-03T19:37:54.257Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              revision: 1,
              locale: 'en-US',
            },
            fields: {
              title: 'Sweetpass',
              description: 'Sweetpass illustration',
              file: {
                url: '//images.ctfassets.net/wme4s8lvzccr/ggzHVvUXMBObRChNdT3bp/fc650350d57d9b5a56aa54c896156c81/sweetpass_2x.png',
                details: {
                  size: 99_620,
                  image: {
                    width: 1400,
                    height: 662,
                  },
                },
                fileName: 'sweetpass@2x.png',
                contentType: 'image/png',
              },
            },
          },
          headingText: 'A delicious life begins here.',
          headingTextXs: 'A delicious life\nbegins here.',
          headingTextV2: 'A delicious life\nbegins here',
          bodyTextGeneric:
            'Join Sweetpass—our new, free loyalty program. Explore the 2023 benefits.',
          bodyText:
            'Join Sweetpass—our new, free loyalty program. Explore the 2023 benefits.',
          cta: {
            metadata: {
              tags: [],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              id: '1r6LraTr16yQ9tk2CzAAmC',
              type: 'Entry',
              createdAt: '2023-05-03T19:38:00.172Z',
              updatedAt: '2023-06-23T19:31:36.666Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              revision: 5,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'sweetpassUpgradeCTA',
                },
              },
              locale: 'en-US',
            },
            fields: {
              name: 'Sweetpass signed out homepage | Header CTA',
              titleGeneric: 'Sign in to use Sweetpass',
              titleV3: 'Sign in to use Sweetpass',
              action: 'join_sweetpass',
              palette: 'lime',
            },
          },
          palette: 'kale',
        },
      },
      {
        metadata: {
          tags: [],
        },
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'wme4s8lvzccr',
            },
          },
          id: '5SVxgypVPH2JBd7FQ18If',
          type: 'Entry',
          createdAt: '2023-05-03T19:38:41.996Z',
          updatedAt: '2023-05-03T19:38:41.996Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 1,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'sweetpassUpgradeBenefitList',
            },
          },
          locale: 'en-US',
        },
        fields: {
          name: 'Sweetpass signed out homepage | Benefits',
          itemsPerRow: 2,
          benefitList: [
            {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'wme4s8lvzccr',
                  },
                },
                id: '62Cd4q6SpystNCvJjnp1rW',
                type: 'Entry',
                createdAt: '2022-10-27T18:52:43.875Z',
                updatedAt: '2023-03-27T15:51:34.667Z',
                environment: {
                  sys: {
                    id: 'master',
                    type: 'Link',
                    linkType: 'Environment',
                  },
                },
                revision: 5,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'sweetpassUpgradeBenefit',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                name: 'Sweetpass Upgrade Benefit: Rewards and challenges',
                image: {
                  metadata: {
                    tags: [],
                  },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'wme4s8lvzccr',
                      },
                    },
                    id: '4P532Rt0MgDIYmgwwQdlQ3',
                    type: 'Asset',
                    createdAt: '2022-10-27T18:52:34.628Z',
                    updatedAt: '2022-10-27T18:58:54.525Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 2,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Sweetpass Upgrade Benefit: Rewards and challenges',
                    description: '',
                    file: {
                      url: '//images.ctfassets.net/wme4s8lvzccr/4P532Rt0MgDIYmgwwQdlQ3/9f2c3edf8202c4cbe7c95ad21ba04ee3/upgrade-benefit.rewards-and-challenges.png',
                      details: {
                        size: 4_095_845,
                        image: {
                          width: 2048,
                          height: 2048,
                        },
                      },
                      fileName: 'upgrade-benefit.rewards-and-challenges.png',
                      contentType: 'image/png',
                    },
                  },
                },
                titleText: 'Rewards and Challenges',
                bodyText:
                  'Opt in and complete delicious challenges to savor more rewards.',
              },
            },
            {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'wme4s8lvzccr',
                  },
                },
                id: 'Xe6EmYuliAWP3jHNHIepl',
                type: 'Entry',
                createdAt: '2022-10-27T18:51:22.273Z',
                updatedAt: '2023-03-27T15:58:24.149Z',
                environment: {
                  sys: {
                    id: 'master',
                    type: 'Link',
                    linkType: 'Environment',
                  },
                },
                revision: 6,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'sweetpassUpgradeBenefit',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                name: 'Sweetpass Upgrade Benefit: Birthday gift',
                image: {
                  metadata: {
                    tags: [],
                  },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'wme4s8lvzccr',
                      },
                    },
                    id: '2Cm6rx9eiqunKy7uj28ORZ',
                    type: 'Asset',
                    createdAt: '2022-10-27T18:51:02.247Z',
                    updatedAt: '2022-10-27T18:58:54.533Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 2,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Sweetpass Upgrade Benefit: Birthday gift',
                    description: '',
                    file: {
                      url: '//images.ctfassets.net/wme4s8lvzccr/2Cm6rx9eiqunKy7uj28ORZ/7d050c8df32495b8a110118e5ebdbf83/upgrade-benefit.birthday-gift.png',
                      details: {
                        size: 3_615_154,
                        image: {
                          width: 2048,
                          height: 2048,
                        },
                      },
                      fileName: 'upgrade-benefit.birthday-gift.png',
                      contentType: 'image/png',
                    },
                  },
                },
                titleText: 'Birthday\nGift',
                bodyText:
                  'Celebrate your special day with something sweet from us.',
              },
            },
            {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'wme4s8lvzccr',
                  },
                },
                id: '6E71U9CJDJSsQgPLJyj6KQ',
                type: 'Entry',
                createdAt: '2022-10-27T18:56:29.819Z',
                updatedAt: '2023-03-17T18:00:31.508Z',
                environment: {
                  sys: {
                    id: 'master',
                    type: 'Link',
                    linkType: 'Environment',
                  },
                },
                revision: 6,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'sweetpassUpgradeBenefit',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                name: 'Sweetpass Upgrade Benefit: Sweetgreen Bowl Drops',
                image: {
                  metadata: {
                    tags: [],
                  },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'wme4s8lvzccr',
                      },
                    },
                    id: '6FLiQI94jm6LTfCoq2TnWE',
                    type: 'Asset',
                    createdAt: '2022-10-27T18:56:19.479Z',
                    updatedAt: '2022-10-27T18:58:54.540Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 2,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Sweetpass Upgrade Benefit: Sweetgreen exclusives',
                    description: '',
                    file: {
                      url: '//images.ctfassets.net/wme4s8lvzccr/6FLiQI94jm6LTfCoq2TnWE/8925221ac656c4b84adf662a4c9dcf2e/upgrade-benefit.sweetgreen-exclusives.png',
                      details: {
                        size: 4_991_048,
                        image: {
                          width: 2048,
                          height: 2048,
                        },
                      },
                      fileName: 'upgrade-benefit.sweetgreen-exclusives.png',
                      contentType: 'image/png',
                    },
                  },
                },
                titleText: 'Sweetpass Bowl Drops',
                bodyText:
                  'Get special access to online-only menu drops exclusive to Sweetpass members.',
              },
            },
            {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'wme4s8lvzccr',
                  },
                },
                id: '5obDlUdNvhiBut9ltLMPBC',
                type: 'Entry',
                createdAt: '2022-10-27T18:57:37.924Z',
                updatedAt: '2023-05-24T20:50:39.277Z',
                environment: {
                  sys: {
                    id: 'master',
                    type: 'Link',
                    linkType: 'Environment',
                  },
                },
                revision: 7,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'sweetpassUpgradeBenefit',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                name: 'Sweetpass Upgrade Benefit: merch drops',
                image: {
                  metadata: {
                    tags: [],
                  },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'wme4s8lvzccr',
                      },
                    },
                    id: '6uJ1H7kwsPrQfIBNfv9WP1',
                    type: 'Asset',
                    createdAt: '2022-10-27T18:57:32.612Z',
                    updatedAt: '2022-10-27T18:58:54.554Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 2,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Sweetpass Upgrade Benefit: Limited edition drops',
                    description: '',
                    file: {
                      url: '//images.ctfassets.net/wme4s8lvzccr/6uJ1H7kwsPrQfIBNfv9WP1/125f29f4e6ed23d482244b32ddff66e8/upgrade-benefit.limited-edition-drops.png',
                      details: {
                        size: 2_819_758,
                        image: {
                          width: 2048,
                          height: 2048,
                        },
                      },
                      fileName: 'upgrade-benefit.limited-edition-drops.png',
                      contentType: 'image/png',
                    },
                  },
                },
                titleText: 'Merch Drops',
                bodyText: 'Get access to limited-edition sweetgreen merch.',
              },
            },
          ],
        },
      },
    ],
    cta: {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'wme4s8lvzccr',
          },
        },
        id: 'sDumzLcQyYAhfszErExqh',
        type: 'Entry',
        createdAt: '2023-05-03T19:38:48.464Z',
        updatedAt: '2023-06-23T19:32:15.230Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 3,
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: 'sweetpassUpgradeCTA',
          },
        },
        locale: 'en-US',
      },
      fields: {
        name: 'Sweetpass signed out homepage | CTA',
        titleGeneric: 'Sign in to use Sweetpass',
        titleV3: 'Sign in to use Sweetpass',
        action: 'join_sweetpass',
        palette: 'lime',
      },
    },
  },
};

import { useCallback } from 'react';

import { useTelemetry } from '@order/Telemetry';

import { useNavigateBack } from '../../../../navigation';

// ─────────────────────────────────────────────────────────────────────────────
// Closes the bag and tracks the `modal.close` event.
// ─────────────────────────────────────────────────────────────────────────────

export const useCloseBag = () => {
  const { track } = useTelemetry();
  const handleNavigateBack = useNavigateBack();

  const closeBag = useCallback(() => {
    track('modal.close');
    handleNavigateBack();
  }, [track, handleNavigateBack]);

  return { closeBag };
};

/* eslint-disable @typescript-eslint/consistent-type-assertions, @typescript-eslint/consistent-type-imports */

import { logger as LOG } from '@garnish/logger';
import { assign, createMachine } from 'xstate';

import { removeTokens, storeEmbeddedFlowTokens } from '@order/AzureAuth';

export const joinOrSignInStateMachine = createMachine(
  {
    id: 'join-or-sign-in-state-machine',
    predictableActionArguments: true,
    tsTypes: {} as import('./join-or-sign-in-machine.typegen').Typegen0,
    schema: {
      context: {} as JoinOrSignInStateMachineContext,
      events: {} as JoinOrSignInStateMachineEvents,
      services: {} as JoinOrSignInStateMachineServices,
    },
    initial: 'waiting',
    states: {
      waiting: {
        entry: ['removeRefreshToken'], // NOTE: Remove any stale refresh token
        on: {
          /**
           * When a user reloads the app after completing the Azure user verification phase,
           * the hosted frame will have the preserved session from an already existing account.
           * In that situation, we recover the authentication state so that the user can
           * proceed from the previous point.
           */
          TOKEN_RECEIVED: {
            target: 'signing-in',
            actions: ['storeToken', 'storeEmail'],
          },
          AUTH_ERROR: {
            target: 'authentication-failed',
            actions: ['onInitError'],
          },
        },
        after: {
          EMBEDDED_FRAME_LOADING_TIMEOUT: {
            target: 'authentication-failed',
            actions: ['onInitTimeoutError'],
          },
        },
      },
      'entering-email-address': {
        on: {
          LOG_IN: {
            target: 'verification-code-loading',
          },

          // NOTE: Playwright and other test runners are able to hit the
          //       "Continue" button even when the relevant listener is not
          //       even registered.
          //
          //       We listen to the `VERIFICATION_CODE_LOADED` event in case
          //       the initial click was not handled and the user is already on
          //       the next screen to better manage such circumstances.
          VERIFICATION_CODE_LOADED: {
            target: 'entering-verification-code',
            actions: ['storeEmail'],
          },
        },
      },
      'verification-code-loading': {
        on: {
          VERIFICATION_CODE_LOADED: {
            target: 'entering-verification-code',
            actions: ['storeEmail'],
          },
          ERROR: {
            target: 'entering-email-address',
            actions: ['onErrorMessage'],
          },
        },
        after: {
          EMBEDDED_FRAME_LOADING_TIMEOUT: {
            target: 'authentication-failed',
            actions: ['onVerificationScreenLoadingTimeout'],
          },
        },
      },
      'entering-verification-code': {
        on: {
          VERIFY_CODE: {
            target: 'verification',
          },
          ERROR: {
            actions: ['onErrorMessage'],
          },
          SUCCESS: {
            actions: ['onSuccessMessage'],
          },
        },
      },
      verification: {
        on: {
          ERROR: {
            target: 'entering-verification-code',
            actions: ['onErrorMessage'],
          },
          SUCCESS: {
            actions: ['onSuccessMessage'],
          },
          TOKEN_RECEIVED: {
            target: 'signing-in',
            actions: ['storeToken'],
          },
        },
        after: {
          EMBEDDED_FRAME_LOADING_TIMEOUT: {
            target: 'authentication-failed',
            actions: ['onVerificationTimeout'],
          },
        },
      },
      'signing-in': {
        invoke: {
          src: 'signIn',
          onDone: [
            {
              target: 'redirect',
              cond: 'checkIfSignedIn',
            },
            'filling-account-data',
          ],
          onError: {
            target: 'authentication-failed',
            actions: ['onSignInSystemError'],
          },
        },
      },
      'filling-account-data': {
        on: {
          SUBMIT_ACCOUNT_DATA: [
            {
              cond: 'checkIfNeedToRefreshTokens',
              target: 'refreshing-access-tokens',
              actions: ['storeAccountInput'],
            },
            {
              target: 'creating-account',
              actions: ['storeAccountInput'],
            },
          ],
          RESTART: 'waiting',
        },
      },
      'refreshing-access-tokens': {
        description:
          'A state in which a machine attempts to refresh access tokens using the stored refresh token.',
        invoke: {
          src: 'refreshAccessTokens',
          onDone: {
            target: 'creating-account',
            actions: 'storeAccessTokens',
          },
          onError: {
            target: 'authentication-failed',
            actions: ['onRefreshAccessTokensSystemError'],
          },
        },
      },
      'creating-account': {
        invoke: {
          src: 'createAccount',
          onDone: [
            {
              target: 'redirect',
              cond: 'checkIfSignedUp',
            },
            'filling-account-data',
          ],
          onError: {
            target: 'filling-account-data',
            actions: ['onCreateAccountSystemError'],
          },
        },
      },
      'authentication-failed': {
        on: {
          RESTART: 'waiting',
        },
      },
      redirect: {
        entry: ['storeTokens'],
        on: {
          RESTART: 'waiting',
        },
      },
    },
    on: {
      DEBUG_INFO: {
        actions: ['onDebugMessage'],
      },

      /**
       * When a customer uses the back button on a Web platform, the browser
       * navigates within the 'iframe' rather than to the previous page in
       * the parent app.
       *
       * This can cause a stuck condition and lead to a timeout.
       *
       * To prevent this, we listen globally for the "Email"
       * template loading completion event and restart both the embedded
       * flow and the state machine, allowing for email changes or/and a
       * complete restart.
       */
      EMAIL_ADDRESS_LOADED: {
        target: 'entering-email-address',
      },
    },
  },
  {
    actions: {
      //
      // ─── Debug Message Loggers ───────────────────────────

      onDebugMessage(_context) {
        logger.debug('`onDebugMessage` action is not provided.');
      },

      // ─── Error Handlers ──────────────────────────────────

      onInitError(_context) {
        logger.debug('`onInitError` action is not provided.');
      },
      onInitTimeoutError(_context) {
        logger.debug('`onInitTimeoutError` action is not provided.');
      },
      onVerificationScreenLoadingTimeout(_context) {
        logger.debug(
          '`onVerificationScreenLoadingTimeout` action is not provided.',
        );
      },
      onVerificationTimeout(_context) {
        logger.debug('`onVerificationTimeout` action is not provided.');
      },
      onSignInSystemError(_context) {
        logger.debug('`onSignInSystemError` action is not provided.');
      },
      onRefreshAccessTokensSystemError(_context) {
        logger.debug(
          '`onRefreshAccessTokensSystemError` action is not provided.',
        );
      },
      onCreateAccountSystemError(_context) {
        logger.debug('`onCreateAccountSystemError` action is not provided.');
      },

      // ─── Post Message Handlers ───────────────────────────

      onErrorMessage(_context, _event) {
        logger.debug('`onErrorMessage` action is not provided.');
      },
      onSuccessMessage(_context, _event) {
        logger.debug('`onSuccessMessage` action is not provided.');
      },

      // ─────────────────────────────────────────────────────

      storeTokens(context) {
        const { refreshToken } = context;

        if (!refreshToken) return;

        void storeEmbeddedFlowTokens({ refreshToken });
      },
      removeRefreshToken() {
        void removeTokens();
      },
      storeEmail: assign((_context, event) => ({
        email: event.email,
      })),
      storeToken: assign((_context, event) => ({
        accessToken: event.apiAccessToken,
        refreshToken: event.apiRefreshToken,
        vendorAccessToken: event.vendorApiAccessToken,
        email: event.email,
      })),
      storeAccessTokens: assign((_context, event) => {
        const { data } = event;

        if (!data) return {};

        const { apiAccessToken, vendorApiAccessToken } = data;

        return {
          accessToken: apiAccessToken,
          vendorAccessToken: vendorApiAccessToken,
        };
      }),
      storeAccountInput: assign((_context, event) => ({
        createAccountInput: event.input,
      })),
    },
    services: {
      async signIn() {
        logger.debug('`signIn` service is not provided.');

        return { hasSignedIn: false };
      },
      async createAccount() {
        logger.debug('`createAccount` service is not provided.');

        return { hasCreatedAccount: false };
      },
      async refreshAccessTokens() {
        logger.debug('`refreshAccessTokens` service is not provided.');

        return undefined;
      },
    },
    guards: {
      checkIfSignedIn(_context, event) {
        return event.data.hasSignedIn;
      },
      checkIfSignedUp(_context, event) {
        return event.data.hasCreatedAccount;
      },
      checkIfNeedToRefreshTokens() {
        logger.debug('`checkIfNeedToRefreshTokens` guard is not provided.');

        return true;
      },
    },
    delays: {
      EMBEDDED_FRAME_LOADING_TIMEOUT: 40_000,
    },
  },
);

// ─── Logger ──────────────────────────────────────────────────────────────────

LOG.enable('JOIN OR SIGN IN STATE MACHINE');

const logger = LOG.extend('JOIN OR SIGN IN STATE MACHINE');

// ─── Types ───────────────────────────────────────────────────────────────────

export type JoinOrSignInStateMachineContext = {
  email: string | undefined;
  accessToken: string | undefined;
  vendorAccessToken: string | undefined;
  refreshToken: string | undefined;
  createAccountInput: CreateAccountInput | undefined;
};

export type JoinOrSignInStateMachineServices = {
  signIn: { data: { hasSignedIn: boolean } };
  createAccount: { data: { hasCreatedAccount: boolean } };
  refreshAccessTokens: {
    data:
      | { apiAccessToken?: string; vendorApiAccessToken?: string }
      | undefined;
  };
  storeRefreshToken: {
    data: undefined;
  };
};

export type JoinOrSignInStateMachineEvents =
  | { type: 'EXISTING_SESSION_FOUND'; email: string }
  | { type: 'EMAIL_ADDRESS_LOADED' }
  | { type: 'VERIFICATION_CODE_LOADED'; email: string }
  | { type: 'LOG_IN' }
  | { type: 'VERIFY_CODE' }
  | {
      type: 'TOKEN_RECEIVED';
      apiAccessToken: string;
      apiRefreshToken: string;
      vendorApiAccessToken: string;
      email: string;
    }
  | { type: 'AUTH_ERROR'; data?: string }
  | { type: 'ERROR'; text?: string }
  | { type: 'SUCCESS'; text?: string }
  | { type: 'SUBMIT_ACCOUNT_DATA'; input: CreateAccountInput }
  | { type: 'DEBUG_INFO'; data?: Record<string, unknown> }
  | { type: 'RESTART' };

type CreateAccountInput = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  birthday: string;
};

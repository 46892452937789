import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, DisplayText, Radio, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationDetailsDressingMode = (
  props: CustomizationDetailsDressingModeProps,
) => {
  const {
    dressingMode,
    maxDressingsPortions,
    isDisabled,
    setDressingModeToOnTheSide,
    setDressingModeToMixedIn,
  } = props;

  const { formatMessage } = useIntl();

  return (
    <View style={styles.container}>
      <DisplayText sizeMatch={['24']} style={styles.title}>
        <FormattedMessage {...messages.title} />
      </DisplayText>

      <Radio.Group itemsPerRow={2}>
        <Radio.Item
          value="onTheSide"
          isSelected={dressingMode === 'onTheSide'}
          isDisabled={isDisabled}
          onPress={setDressingModeToOnTheSide}
          label={formatMessage(messages.label, { mode: 'onTheSide' })}
          aria-label={formatMessage(messages.a11yLabel, { mode: 'onTheSide' })}
        />

        <Radio.Item
          value="mixedIn"
          isSelected={dressingMode === 'mixedIn'}
          isDisabled={isDisabled}
          onPress={setDressingModeToMixedIn}
          label={formatMessage(messages.label, { mode: 'mixedIn' })}
          aria-label={formatMessage(messages.a11yLabel, { mode: 'mixedIn' })}
        />
      </Radio.Group>

      <BodyText sizeMatch={['14']} style={styles.helperText}>
        <FormattedMessage
          {...messages.helperText}
          values={{ mode: dressingMode, max_portions: maxDressingsPortions }}
        />
      </BodyText>
    </View>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'How would you like your dressing?',
    description: 'Customization | Dressing details | Dressing mode title',
  },
  label: {
    defaultMessage: `{mode, select,
      onTheSide {On the side}
      mixedIn {Mixed in}
      other {}
    }`,
    description: 'Customization | Dressing details | Dressing mode label',
  },
  a11yLabel: {
    defaultMessage: `{mode, select,
      onTheSide {Select dressing on the side}
      mixedIn {Select dressing mixed in}
      other {}
    }`,
    description: 'Customization | Dressing details | Dressing mode a11y label',
  },
  helperText: {
    defaultMessage: `{mode, select,
      onTheSide {You may have up to three ({max_portions}) portions of dressing on the side.}
      mixedIn {For optimal freshness eat within 15 minutes of pickup time.}
      other {}
    }`,
    description: 'Customization | Dressing details | Dressing helper text',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    rowGap: theme.spacing['2'],
  },
  title: {
    marginBottom: theme.spacing['2'],
  },
  helperText: {
    minHeight: 36,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationDetailsDressingModeProps = {
  dressingMode: 'onTheSide' | 'mixedIn' | string;
  maxDressingsPortions: number;
  setDressingModeToOnTheSide: () => void;
  setDressingModeToMixedIn: () => void;
  isDisabled: boolean;
};

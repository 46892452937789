import { useFeatureFlag } from '@order/LaunchDarkly';

import { type Location } from '../../../../machine';

/**
 * A small hook that returns a boolean indicating if fee disclosure should
 * be displayed.
 */
export const useLocationResultsFeeDisclosure = (
  params: UseLocationResultsFeeDisclosureParams,
) => {
  const { location } = params;

  // ─── Flags ───────────────────────────────────────────────────────────

  const isDeliveryPriceDifferentiationEnabled = useFeatureFlag(
    'CELS-2439-enable-delivery-price-differentiation-disclosure',
  );
  const isOutpostPriceDifferentiationEnabled = useFeatureFlag(
    'CELS-2489-enable-outpost-price-differentiation-disclosure',
  );

  // ─────────────────────────────────────────────────────────────────────

  const {
    showDeliveryPriceDifferenciationDisclosure = false,
    outpostPriceDifferentiationEnabled = false,
    isOutpost: isOutpostLocation = false,
    isDelivery: isDeliveryLocation = false,
  } = location ?? {};

  const shouldShowDeliveryFeeDisclaimer =
    isDeliveryPriceDifferentiationEnabled &&
    isDeliveryLocation &&
    showDeliveryPriceDifferenciationDisclosure;
  const shouldShowOutpostFeeDisclaimer =
    isOutpostPriceDifferentiationEnabled &&
    isOutpostLocation &&
    outpostPriceDifferentiationEnabled;

  // ─────────────────────────────────────────────────────────────────────

  return {
    shouldShowDeliveryFeeDisclaimer,
    shouldShowOutpostFeeDisclaimer,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseLocationResultsFeeDisclosureParams = {
  location?: Pick<
    Location,
    | 'showDeliveryPriceDifferenciationDisclosure'
    | 'outpostPriceDifferentiationEnabled'
    | 'isDelivery'
    | 'isOutpost'
    | 'addressId'
  >;
  locationSearchType?: string;
};

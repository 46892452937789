import React from 'react';

import { useUniqueNativeID } from '../../../hooks';
import { usePickerState } from './Picker.hooks';
import type { PickerProps } from './Picker.types';
import { PickerContainer, PickerControl } from './subcomponents';

export const Picker = (props: PickerProps) => {
  const {
    options,
    value,
    label,
    modalTitle,
    submitBtnLabel,
    variation,
    accessibilityLabel,
    triggerIconLeft,
    triggerIconRight,
    boldTriggerText,
    onSubmit,
  } = props;

  const labelNativeID = useUniqueNativeID('picker-label');

  const {
    selectedOptionValue,
    selectedOptionLabel,
    setSelectedOptionValue,
    handleSubmit,
    resetSelectedOptionValue,
  } = usePickerState({ value, options, onSubmit });

  // ────────────────────────────────────────────────────────────────────

  return (
    <PickerContainer
      label={label}
      labelNativeID={labelNativeID}
      modalTitle={modalTitle}
      submitBtnLabel={submitBtnLabel}
      selectedOptionLabel={selectedOptionLabel}
      variation={variation}
      accessibilityLabel={accessibilityLabel}
      triggerIconLeft={triggerIconLeft}
      triggerIconRight={triggerIconRight}
      boldTriggerText={boldTriggerText}
      onSubmitBtnPress={handleSubmit}
      onDismiss={resetSelectedOptionValue}
    >
      <PickerControl
        value={selectedOptionValue}
        options={options}
        accessibilityLabel={accessibilityLabel}
        accessibilityDescribedBy={label ? labelNativeID : undefined}
        onChange={setSelectedOptionValue}
      />
    </PickerContainer>
  );
};

import AsyncStorage from '@react-native-async-storage/async-storage';

export const getUserId = async () => {
  const userDataFromStorage = await AsyncStorage.getItem('order-user');
  const parsedUserData = userDataFromStorage
    ? JSON.parse(userDataFromStorage)
    : {};

  return parsedUserData.id ?? 'anonymous';
};

import React from 'react';
import type ReactNative from 'react-native';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';
import { useModalBottomSpacingStyle } from '../../hooks';
import { ModalRow } from '../ModalRow';

/**
 * Note that this footer already provides bottom padding.
 * Therefore the parent container should not provide it.
 */
export const ModalFooter = (props: ModalFooterProps) => {
  const { children, style, withoutTopBorder, ...restProps } = props;
  const { match } = useResponsive();

  const responsiveStyles = match([styles.footerMobile, styles.footerTabletUp]);
  const modalDialogBottomSpacingStyle = useModalBottomSpacingStyle();

  return (
    <ModalRow
      style={[
        responsiveStyles,
        modalDialogBottomSpacingStyle,
        withoutTopBorder
          ? styles.footerWithoutBorder
          : styles.footerWithTopBorder,
        style,
      ]}
      {...restProps}
    >
      {children}
    </ModalRow>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type ModalFooterProps = ReactNative.ViewProps &
  Readonly<{
    withoutTopBorder?: boolean;
  }>;

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  footerMobile: {
    paddingTop: theme.spacing['4'],
  },
  footerTabletUp: {
    paddingTop: theme.spacing['6'],
  },
  footerWithTopBorder: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.LIGHT,
  },
  footerWithoutBorder: {
    paddingTop: 0,
  },
});

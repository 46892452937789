/**
 * Returns the correct loyalty information based on customer state.
 */
export const useBagLoyalty = (props: UseBagLoyaltyProps) => {
  const {
    isLoggedIn,
    isNewCustomer,
    loyaltyTitleLoggedOut,
    loyaltyTitleNewUser,
    loyaltyTitleRecurringUser,
    availablePoints,
  } = props;

  if (!isLoggedIn) return { title: loyaltyTitleLoggedOut };

  if (isNewCustomer) return { title: loyaltyTitleNewUser };

  return {
    title: loyaltyTitleRecurringUser.replaceAll(
      '{points}',
      String(availablePoints),
    ),
  };
};

// ─── Types ──────────────────────────────────────────────────────────────────

type UseBagLoyaltyProps = {
  isLoggedIn: boolean;
  isNewCustomer: boolean;
  loyaltyTitleLoggedOut: string;
  loyaltyTitleNewUser: string;
  loyaltyTitleRecurringUser: string;
  availablePoints: number;
};

import { useCallback } from 'react';

import { useTelemetry } from '@order/Telemetry';

import { decodeGiftCardRedemptionHash } from './helpers';

// ─────────────────────────────────────────────────────────────────────────────

export const useRedeemGiftCardDefaultValues = (
  params: UseRedeemGiftCardDefaultValuesParams,
): GiftCardRedemptionDecodedData => {
  const { giftCardRedemptionEncodedData } = params;

  const { track } = useTelemetry();

  // ─── Telemetry ───────────────────────────────────────────────────────

  const onSuccessfulDecode = useCallback(() => {
    track('gift.card_autopopulated.success');
  }, [track]);

  const onFailedDecode = useCallback(
    (error: unknown) => {
      track('gift.card_autopopulated.failure', { error });
    },
    [track],
  );

  // ─────────────────────────────────────────────────────────────────────

  return giftCardRedemptionEncodedData
    ? decodeGiftCardRedemptionHash({
        redemptionHash: giftCardRedemptionEncodedData,
        onSuccess: onSuccessfulDecode,
        onError: onFailedDecode,
      })
    : null;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseRedeemGiftCardDefaultValuesParams = {
  giftCardRedemptionEncodedData: string | undefined;
};

type GiftCardRedemptionDecodedData = {
  giftCardCode: string;
  giftCardRegistrationCode?: string;
} | null;

import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button, Modal, ModalDialogue } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const RewardExceptionModal = (props: RewardExceptionModalProps) => {
  const { visible, message } = props;

  const { t } = useLocalizationContext();

  const [isVisible, setIsVisible] = useState(visible);

  const handleDismiss = useCallback(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    if (!visible) return;

    setIsVisible(visible);
  }, [visible]);

  // ────────────────────────────────────────────────────────────────────────────────

  return (
    <View>
      <ModalDialogue
        visible={isVisible}
        size="small"
        onRequestClose={handleDismiss}
        testID="reward-exception-modal"
        accessibilityLabel={t('bag.rewards.error.modal.headline')}
        headline={t('bag.rewards.error.modal.headline')}
        body={message}
      >
        <Modal.Footer withoutTopBorder>
          <Button
            testID="reward-exception-modal.close-btn"
            size="large"
            palette="primary"
            onPress={handleDismiss}
          >
            {t('general.got-it')}
          </Button>
        </Modal.Footer>
      </ModalDialogue>
    </View>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type RewardExceptionModalProps = Readonly<{
  visible: boolean;
  message: string;
}>;

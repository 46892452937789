import React, { Children, memo, useMemo } from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import type { ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { LI, UL } from '@expo/html-elements';
import { LG, MD, SM, theme, XS } from '@garnish/constants';

import { useResponsive } from '../../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * VStack component that renders the provided children components in
 * a vertical stack using optional gap and divider styles.
 */
export const VStack = memo((props: VStackProps) => {
  const { gap, children, ...stackProps } = props;

  // NOTE: To unwrap fragments
  const flattenedChildren = flattenChildren(children);

  if (gap === undefined) {
    return (
      <VStackWithDefaultGap {...stackProps}>
        {flattenedChildren}
      </VStackWithDefaultGap>
    );
  }

  return (
    <VStackWithCustomGap gap={gap} {...stackProps}>
      {flattenedChildren}
    </VStackWithCustomGap>
  );
});

// ─── Variations ──────────────────────────────────────────────────────────────

const VStackWithDefaultGap = memo((props: VStackBaseProps) => {
  const { match } = useResponsive();

  const spacing = match([XS.GUTTER, SM.GUTTER, MD.GUTTER, LG.GUTTER]);

  return <VStackContent gap={spacing} {...props} />;
});

const VStackWithCustomGap = memo((props: VStackWithGapProps) => {
  const { gap, hasDivider, ...restProps } = props;

  const dividerAwareGap = hasDivider ? gap / 2 : gap;

  return (
    <VStackContent
      gap={dividerAwareGap}
      hasDivider={hasDivider}
      {...restProps}
    />
  );
});

// ─── Components ──────────────────────────────────────────────────────────────

const VStackContent = memo((props: VStackWithGapProps) => {
  const {
    gap,
    hasDivider,
    dividerColor = theme.colors.GRAY,
    children,
    style,
    ...restProps
  } = props;

  const itemsCount = Children.count(children);

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerDynamicStyles = useStyle(() => ({ gap }), [gap]);
  const containerStyles = useMemo(
    () => StyleSheet.flatten([containerDynamicStyles, style]),
    [containerDynamicStyles, style],
  );

  const itemBorderedStyle = useStyle(() => {
    if (!hasDivider) return;

    return {
      borderBottomWidth: 1,
      borderBottomColor: dividerColor,
      paddingBottom: gap,
    };
  }, [gap, hasDivider, dividerColor]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <UL style={containerStyles} {...restProps}>
      {Children.map(children, (child, index) => {
        const shouldApplyDividerStyles =
          itemsCount > 0 && index < itemsCount - 1;

        return (
          <LI style={shouldApplyDividerStyles ? itemBorderedStyle : undefined}>
            {child}
          </LI>
        );
      })}
    </UL>
  );
});

// ─── Types ───────────────────────────────────────────────────────────────────

type VStackProps = Readonly<{
  gap?: number;
}> &
  VStackBaseProps;

type VStackWithGapProps = {
  gap: number;
} & VStackBaseProps;

type VStackBaseProps = Readonly<{
  hasDivider?: boolean;
  dividerColor?: string;
}> &
  ViewProps;

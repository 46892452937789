import { type ComponentProps, useMemo } from 'react';
import { type TextLinkifyTags } from '@sg/garnish';

import { DurationUnit } from '@order/graphql';

import { useSweetpassCheckoutContentfulInterpolation } from './useSweetpassCheckoutContentfulInterpolation';
import { useSweetpassCheckoutContentfulQuery } from './useSweetpassCheckoutContentfulQuery';

export const useSweetpassCheckoutContentfulData = (
  props: ContentfulDataProps,
) => {
  const {
    isFreeTrialEnabled = false,
    isSwitchingPlans = false,
    planPrice,
    trialDuration = '',
    trialDurationSingular = '',
    billingFrequencyUnit = DurationUnit.Month,
    billingFrequencyMembershipName = '',
    nextBillingDate = '',
    requestedPlanRecurrence = '',
  } = props;

  const {
    isLoading,
    title: standardTitle,
    termsAndConditions: standardTermsAndConditions,
    agreementText: standardAgreementText,
    sweetpassProgramAgreementUrlDisplayName,
    sweetpassProgramAgreementUrl,
    cta,
    freeTrialTitle,
    freeTrialTermsAndConditions,
    freeTrialAgreementText,
    freeTrialCta,
    freeTrialNote,
    planSwitchTitle,
    planSwitchTermsAndConditions,
    planSwitchCta,
    subscriptionSwitchSuccessBanner,
  } = useSweetpassCheckoutContentfulQuery();

  const ctaLabel = selectContent({
    isFreeTrialEnabled,
    isSwitchingPlans,
    regularContent: cta,
    freeTrialContent: freeTrialCta,
    switchingPlansContent: planSwitchCta,
    switchingFreeTrialPlansContent: planSwitchCta,
  });

  const interpolateContentfulValues =
    useSweetpassCheckoutContentfulInterpolation({
      planPrice,
      trialDuration,
      trialDurationSingular,
      nextBillingDate,
      switchDate: nextBillingDate,
      ctaLabel,
      requestedPlanRecurrence,
      billingFrequencyUnit,
      billingFrequencyMembershipName,
    });

  const content = useMemo(() => {
    const title = interpolateContentfulValues(
      selectContent({
        isFreeTrialEnabled,
        isSwitchingPlans,
        regularContent: standardTitle,
        freeTrialContent: freeTrialTitle,
        switchingPlansContent: planSwitchTitle,
        switchingFreeTrialPlansContent: planSwitchTitle,
      }),
    );

    const termsAndConditions = interpolateContentfulValues(
      selectContent({
        isFreeTrialEnabled,
        isSwitchingPlans,
        regularContent: standardTermsAndConditions,
        freeTrialContent: freeTrialTermsAndConditions,
        switchingPlansContent: planSwitchTermsAndConditions,
        switchingFreeTrialPlansContent: freeTrialTermsAndConditions,
      }),
    );

    const agreementText = interpolateContentfulValues(
      selectContent({
        isFreeTrialEnabled,
        isSwitchingPlans,
        regularContent: standardAgreementText,
        freeTrialContent: freeTrialAgreementText,
        switchingPlansContent: freeTrialAgreementText,
        switchingFreeTrialPlansContent: freeTrialAgreementText,
      }),
    );

    const agreementTextLinks: TextLinkifyTagsLinks = [
      {
        tag: 'sweetpassProgramAgreementUrl',
        description: sweetpassProgramAgreementUrlDisplayName,
        href: sweetpassProgramAgreementUrl,
        newTab: true,
        underline: true,
      },
    ];

    const interpolatedFreeTrialNote =
      isFreeTrialEnabled && trialDuration
        ? interpolateContentfulValues(freeTrialNote)
        : undefined;

    return {
      title,
      ctaLabel,
      termsAndConditions,
      agreementText,
      agreementTextLinks,
      freeTrialNote: interpolatedFreeTrialNote,
      subscriptionSwitchSuccessBanner: interpolateContentfulValues(
        subscriptionSwitchSuccessBanner,
      ),
    };
  }, [
    isFreeTrialEnabled,
    isSwitchingPlans,
    standardTitle,
    freeTrialTitle,
    planSwitchTitle,
    standardTermsAndConditions,
    freeTrialTermsAndConditions,
    planSwitchTermsAndConditions,
    standardAgreementText,
    freeTrialAgreementText,
    sweetpassProgramAgreementUrlDisplayName,
    sweetpassProgramAgreementUrl,
    trialDuration,
    freeTrialNote,
    ctaLabel,
    subscriptionSwitchSuccessBanner,
    interpolateContentfulValues,
  ]);

  return { content, isLoading };
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function selectContent(props: ContentSelectionProps) {
  const {
    isFreeTrialEnabled,
    isSwitchingPlans,
    regularContent,
    freeTrialContent,
    switchingPlansContent,
    switchingFreeTrialPlansContent,
  } = props;

  if (isFreeTrialEnabled && isSwitchingPlans) {
    return switchingFreeTrialPlansContent;
  }

  if (isFreeTrialEnabled) {
    return freeTrialContent;
  }

  if (isSwitchingPlans) {
    return switchingPlansContent;
  }

  return regularContent;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type ContentfulDataProps = Readonly<{
  isFreeTrialEnabled?: boolean;
  isSwitchingPlans?: boolean;
  planPrice: number;
  trialDuration?: string;
  trialDurationSingular?: string;
  billingFrequencyUnit?: DurationUnit;
  billingFrequencyMembershipName?: string;
  nextBillingDate?: string;
  requestedPlanRecurrence?: string;
}>;

type ContentSelectionProps = Readonly<{
  isFreeTrialEnabled: boolean;
  isSwitchingPlans: boolean;
  regularContent: string;
  freeTrialContent: string;
  switchingPlansContent: string;
  switchingFreeTrialPlansContent: string;
}>;

type TextLinkifyTagsLinks = ComponentProps<typeof TextLinkifyTags>['links'];

import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { DisplayText, Image, useResponsive } from '@sg/garnish';

import { IllusSgRewardsKale } from '../../../assets';

export const LoyaltyPointsHistoryHeader = (
  props: LoyaltyPointsHistoryHeaderProps,
) => {
  const { availablePoints } = props;
  const { currentBreakpoint } = useResponsive();
  const { formatMessage } = useIntl();

  if (currentBreakpoint.isXS) {
    return (
      <View style={styles.container}>
        <View style={styles.sgRewards}>
          <Image
            contentFit="contain"
            style={styles.logo}
            source={IllusSgRewardsKale}
            aria-label={formatMessage(messages.sgRewardsLogo)}
          />
        </View>

        <DisplayText sizeMatch={['32']}>
          <FormattedMessage
            {...messages.subtitle}
            values={{ points: availablePoints }}
          />
        </DisplayText>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <DisplayText sizeMatch={['32']}>
        <FormattedMessage {...messages.pointsHistory} />
      </DisplayText>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  sgRewardsLogo: {
    defaultMessage: 'SG Rewards logo',
    description: 'Loyalty | Point History Header | Rewards',
  },
  subtitle: {
    defaultMessage: '{points} points',
    description: 'Loyalty | Point History Header | Subtitle',
  },
  pointsHistory: {
    defaultMessage: 'Points history',
    description: 'Loyalty | Point History Header | Points history',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing['6'],
    gap: theme.spacing['4'],
  },
  sgRewards: {
    flex: 1,
    flexDirection: 'row',
    gap: theme.spacing['1'],
  },
  logo: {
    width: 80,
    height: 24,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyPointsHistoryHeaderProps = {
  availablePoints: number;
};

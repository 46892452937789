import type { Region as RegionRN } from 'react-native-maps';
import { logger } from '@garnish/logger';
import type { LocationObjectCoords } from 'expo-location';
import { getBounds, getCenterOfBounds } from 'geolib';

import type { Coordinate, PinData, Region, ViewportBounds } from './types';
import type { MapProps } from './types';

export const DEFAULT_ZOOM_LEVEL = 13;
export const MAX_ZOOM_LEVEL = 16;
export const EMPTY_REGION = { lat: 0, lng: 0, zoom: DEFAULT_ZOOM_LEVEL };

// google-map-react uses zoom while react-native-maps uses delta values.
export function convertZoomToDelta(zoom?: number): Region {
  if (!zoom) return { lat: 0, lng: 0, zoom };
  const lng = Math.exp(Math.log(360) - zoom * Math.LN2);
  const lat = (lng * 2) / 5;

  return { lat, lng, zoom };
}

// google-map-react uses zoom while react-native-maps uses delta values.
export function convertDeltaToZoom(delta: number): number {
  return Math.round(Math.log2(360 / delta));
}

// Given a react-native-maps region, return a {Region}
export const convertRegionChange = (change: RegionRN): Region => {
  const lat = change.latitude;
  const lng = change.longitude;
  const latDelta = change.latitudeDelta;
  const lngDelta = change.longitudeDelta;
  const zoom = convertDeltaToZoom(change.longitudeDelta);

  return { lat, lng, latDelta, lngDelta, zoom, ...findRegionBounds(change) };
};

// Given a {Region} array, return its center/middle {Region}
export function findCenterRegion(
  locations: readonly Region[],
  mapDimensions?: Readonly<{ width: number; height: number }>,
): Region {
  if (locations.length === 0) return EMPTY_REGION;
  const coordinates = getCoordinates(locations);

  return {
    ...findCenterPoint(coordinates),
    zoom: findZoomLevel(coordinates, mapDimensions),
  };
}

// Given a {Region} array, return a {Coordinate} array.
export function getCoordinates(
  locations: readonly Region[],
): readonly Coordinate[] {
  return locations
    .filter((location) => location.lat && location.lng)
    .map((location) => ({
      latitude: location.lat,
      longitude: location.lng,
    }));
}

// Given a {Coordinate} array, return its center/middle {Region}
export function findCenterPoint(coordinates: readonly Coordinate[]): Region {
  const center =
    coordinates.length > 0 ? getCenterOfBounds([...coordinates]) : null;

  if (!center) return { lat: 0, lng: 0 };

  return {
    lat: center.latitude,
    lng: center.longitude,
  };
}

// Given a {Coordinate} array, return its zoom level {number}
// Reference: https://stackoverflow.com/a/13274361
export function findZoomLevel(
  coordinates: readonly Coordinate[],
  mapDimensions?: Readonly<{ width: number; height: number }>,
): number {
  if (coordinates.length === 0) return 16;

  const { width: mapWidth = 0, height: mapHeight = 0 } = mapDimensions ?? {};

  const WORLD_DIM = { width: 256, height: 256 };
  const SCREEN_DIM = {
    width: mapWidth > 0 ? mapWidth : 800,
    height: mapHeight > 0 ? mapHeight : 400,
  };
  const ZOOM_MAX = 21;

  function latRad(lat: number) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;

    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx: number, worldPx: number, fraction: number) {
    return Math.floor(Math.log2(mapPx / worldPx / fraction));
  }

  const { minLat, maxLat, minLng, maxLng } = getBounds([...coordinates]);

  const latFraction = (latRad(maxLat) - latRad(minLat)) / Math.PI;

  const lngDiff = maxLng - minLng;
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(SCREEN_DIM.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(SCREEN_DIM.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

export function findRegionBounds(region: Partial<RegionRN>): ViewportBounds {
  const {
    latitude = 0,
    longitude = 0,
    latitudeDelta = 0.5,
    longitudeDelta = 0.5,
  } = region;

  return {
    topLeft: {
      latitude: latitude + latitudeDelta / 2,
      longitude: longitude - longitudeDelta / 2,
    },
    bottomRight: {
      latitude: latitude - latitudeDelta / 2,
      longitude: longitude + longitudeDelta / 2,
    },
  };
}

// ─── Pin Helpers ────────────────────────────────────────────────────────────────

/**
 * Returns an object with pin data the current customer geolocation
 *
 * NOTE: If the provided geolocation is invalid, `undefined` will be returned.
 */
export const getCustomerGeolocationPin = (
  props: GetCustomerGeolocationPinProps,
): PinData | undefined => {
  const { geolocation, customerName = '', selectedPin } = props;
  const { latitude, longitude } = geolocation ?? {};

  const isActivePin = selectedPin === 'customer';

  if (longitude === undefined || latitude === undefined) return;

  return {
    id: 'customer',
    name: customerName,
    active: isActivePin,
    lat: latitude,
    lng: longitude,
    isCustomer: true,
  };
};

/**
 * When the bounds contain only a single pin, the `fitBounds` (Web) and `fitCoordinates` (Android)
 * methods does not correctly center the map. To fix this, we add two fake coordinates.
 *
 * @see {@link https://stackoverflow.com/a/30376482 StackOverflow}
 */
export const withSinglePinBoundsOffsetsCoordinates = (
  pins: MapProps['pins'] = [],
): MapProps['pins'] => {
  const shouldAddOffsetPins = pins.length === 1;

  if (!shouldAddOffsetPins) return pins;

  const firstPin = pins[0];
  const offset = 0.002;

  const northEastOffsetCoordinate = {
    id: 'north-east-offset-coordinate',
    name: 'North East Offset Coordinate',
    lat: firstPin.lat + offset,
    lng: firstPin.lng + offset,
  };
  const southWestOffsetCoordinate = {
    id: 'south-west-offset-coordinate',
    name: 'South West Offset Coordinate',
    lat: firstPin.lat - offset,
    lng: firstPin.lng - offset,
  };

  return [...pins, northEastOffsetCoordinate, southWestOffsetCoordinate];
};

export const pinToCoordinate = (pin: PinData): Coordinate => {
  const { lat: latitude, lng: longitude } = pin;

  return { latitude, longitude };
};

// ────────────────────────────────────────────────────────────────────────────────

export const log = logger.extend('MAP');

// ─── Types ──────────────────────────────────────────────────────────────────────

type GetCustomerGeolocationPinProps = Readonly<{
  geolocation?: Partial<LocationObjectCoords>;
  customerName?: string;
  selectedPin?: string;
}>;

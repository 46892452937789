import type { ReactElement } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { webOnlyStyles } from '../../../utils';
import type { BoxProps } from '../Button.types';
import { useBoxStyles } from '../styles';

export const Box = (props: BoxProps): ReactElement => {
  const { children, palette, size, hasDisabledStyle, uiEventState, style } =
    props;

  const useStyleState = useBoxStyles(palette, size);
  const webOnlyStyle = webOnlyStyles({
    transition: `background-color ${theme.transitions.base}ms, color ${theme.transitions.base}ms, border-color ${theme.transitions.base}ms`,
  });

  return (
    <View
      style={[
        styles.buttonBox,
        useStyleState(hasDisabledStyle, uiEventState),
        webOnlyStyle,
        style,
      ]}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  buttonBox: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: theme.radius.xxxlarge,
  },
});

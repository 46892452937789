import { useCallback, useEffect, useRef } from 'react';
import { Animated } from 'react-native';

import type { UseCollapsibleAnimation } from '../Collapsible.types';

export const useCollapsibleAnimation: UseCollapsibleAnimation = (props) => {
  const { sendExpandEvent, sendCollapseEvent, expanded, animation } = props;
  const { duration = 200 } = animation ?? {};

  const { current } = useRef(new Animated.Value(0));

  const startAnimation = useCallback(() => {
    Animated.timing(current, {
      toValue: Number(expanded),
      useNativeDriver: false,
      duration,
    }).start(() => {
      if (expanded && sendCollapseEvent) sendCollapseEvent();
      else if (sendExpandEvent) sendExpandEvent();
    });
  }, [expanded, sendCollapseEvent, sendExpandEvent, current, duration]);

  useEffect(() => {
    startAnimation();
  }, [expanded, startAnimation]);

  return current;
};

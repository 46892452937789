import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { type AddressType, BodyText, theme } from '@sg/garnish';

/**
 * Disclaimers for this order
 */
export const BagDisclaimers = (props: BagDisclaimersProps) => {
  const { orderChannel, isDisabled } = props;
  const disabledStyle = isDisabled ? styles.disabled : null;

  return (
    <View style={[styles.container, disabledStyle]}>
      <BodyText bold sizeMatch={['16']}>
        <FormattedMessage {...messages.allergensTitle} />
      </BodyText>

      <BodyText sizeMatch={['16']} style={styles.message}>
        <FormattedMessage {...messages.allergensMessage} />
      </BodyText>

      {orderChannel === 'delivery' ? (
        <>
          <BodyText bold sizeMatch={['16']}>
            <FormattedMessage {...messages.deliveryTitle} />
          </BodyText>

          <BodyText sizeMatch={['16']} style={styles.message}>
            <FormattedMessage {...messages.deliveryMessage} />
          </BodyText>
        </>
      ) : null}

      <BodyText bold sizeMatch={['16']}>
        <FormattedMessage {...messages.cancellationTitle} />
      </BodyText>

      <BodyText sizeMatch={['16']}>
        <FormattedMessage {...messages.cancellationMessage} />
      </BodyText>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  allergensTitle: {
    defaultMessage: 'Allergens Disclaimer',
    description: 'Bag | Disclaimers | Allergens Title',
  },
  allergensMessage: {
    defaultMessage:
      'At sweetgreen we use all major allergens in our kitchens, so we cannot guarantee that our food is completely free of any allergen. If you have a severe allergy, we recommend not ordering from our restaurant.',
    description: 'Bag | Disclaimers | Allergens Message',
  },
  deliveryTitle: {
    defaultMessage: 'Delivery Provider',
    description: 'Bag | Disclaimers | Delivery Title',
  },
  deliveryMessage: {
    defaultMessage:
      'You authorize sweetgreen to share your contact and location data with our delivery service provider.',
    description: 'Bag | Disclaimers | Delivery Message',
  },
  cancellationTitle: {
    defaultMessage: 'Cancellation Policy',
    description: 'Bag | Disclaimers | Cancellation Title',
  },
  cancellationMessage: {
    defaultMessage:
      'No changes to the order can be made after placing your order.',
    description: 'Bag | Disclaimers | Cancellation Message',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.LINEN,
  },
  disabled: {
    backgroundColor: theme.colors.LIGHT,
  },
  message: {
    marginBottom: theme.spacing['8'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagDisclaimersProps = {
  orderChannel: AddressType;
  isDisabled?: boolean;
};

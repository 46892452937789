import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';

import type { PartialProduct } from '@order/graphql';

import type { BagScreenProps } from '../../../../navigation/AppNavigation.props';

// ────────────────────────────────────────────────────────────────────────────────

export const useBagLineItemsNavigation = (
  props: UseBagLineItemsNavigationProps,
) => {
  const { addressId, isDelivery, restaurantSlug } = props;

  const navigation = useNavigation<BagScreenProps['navigation']>();

  const navigateToEditProductDetails = useCallback(
    (helperProps: NavigateEoEditProductDetailsProps) => {
      const { lineItemId, product, onNavigate } = helperProps;
      const productSlug = product?.slug ?? '';

      // ─────────────────────────────────────────────────────────────────

      onNavigate?.();

      if (isDelivery) {
        navigation.replace('DeliveryEditProductDetails', {
          addressId,
          productSlug,
          lineItemId,
        });

        return;
      }

      navigation.replace('EditProductDetails', {
        restaurantSlug,
        productSlug,
        lineItemId,
      });
    },
    [addressId, isDelivery, navigation, restaurantSlug],
  );

  return { navigateToEditProductDetails };
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type UseBagLineItemsNavigationProps = Readonly<{
  isDelivery: boolean;
  addressId: string;
  restaurantSlug: string;
}>;

type NavigateEoEditProductDetailsProps = Readonly<{
  lineItemId: string;
  product: PartialProduct;
  onNavigate?: () => void;
}>;

import { defineMessages } from 'react-intl';

export const locationResultCardContentMessages = defineMessages({
  orderChannel: {
    defaultMessage: `{channel, select,
      pickup {Pickup}
      delivery {Delivery}
      outpost {Outpost}
      other {}
    }`,
    description: 'Location Screen | Location Result Card | Order channel',
  },
});

import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { Button, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanInStoreCta = (props: ScanInStoreCtaProps) => {
  const { containerStyle, onPress, children } = props;

  const containerStyles = [styles.container, containerStyle];

  return (
    <View style={containerStyles}>
      <Button
        size="small"
        palette="secondary"
        style={styles.cta}
        onPress={onPress}
      >
        {children}
      </Button>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  cta: {
    paddingHorizontal: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanInStoreCtaProps = Readonly<{
  children: string;
  onPress: () => void;
  containerStyle?: ViewProps['style'];
}>;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { LoadingPlaceholder, theme } from '@sg/garnish';

export const LoadingResults = () => (
  <View style={styles.wrapper} testID="location-loading-results">
    <LoadingPlaceholder rows={2} rowHeight={[300]} palette="cream" />
  </View>
);

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: theme.spacing['4'],
  },
});

import { type RequestPolicy } from 'urql';

import { useIsLoggedIn } from '@order/AuthMachine';

import { useKustomerTokenQuery } from '../../graphql/kustomer.generated';
import { useKustomerAvailability } from '../useKustomerAvailability';

/**
 * GraphQL query to fetch Kustomer token from user.
 */
export const useKustomerToken = (
  requestPolicy: RequestPolicy = 'cache-first',
) => {
  const isLoggedIn = useIsLoggedIn();
  const isKustomerEnabled = useKustomerAvailability();

  const [response] = useKustomerTokenQuery({
    requestPolicy,
    pause: !isLoggedIn || !isKustomerEnabled,
  });

  return response?.data?.kustomerToken?.token;
};

import { defineMessages } from 'react-intl';

export const giftCardConfirmationScreenMessages = defineMessages({
  screenTitle: {
    defaultMessage: 'Order confirmation',
    description: 'Gift Card Confirmation Screen | Title',
  },
  screenCustomDocumentTitle: {
    defaultMessage: 'Order confirmation | Gift Card | sweetgreen',
    description: 'Gift Card Confirmation Screen | Custom Document Title',
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import {
  BodyText,
  Button,
  IllusPickup_1,
  Image,
  theme,
  useFluidSize,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const EvergreenChannel = (props: EvergreenChannelProps) => {
  const { containerStyle, infoStyle, titleStyle, buttonStyle, imageStyle } =
    useDynamicStyles();
  const { minWidth } = useResponsive();
  const { titleCopy, buttonCopy, illustrationAlt } = useEvergreenCopy();

  return (
    <View style={containerStyle} testID="featured-channel.evergreen.card">
      <Image
        style={imageStyle}
        source={IllusPickup_1}
        aria-label={illustrationAlt}
      />
      <View style={infoStyle}>
        <BodyText style={titleStyle}>{titleCopy}</BodyText>
        <View style={buttonStyle}>
          <Button
            testID="featured-channel.evergreen.button"
            onPress={props.onOrderNow}
            aria-label={buttonCopy}
            size={minWidth.isMD ? 'large' : 'medium'}
          >
            {buttonCopy}
          </Button>
        </View>
      </View>
    </View>
  );
};

// ────────────────── Hooks ──────────────────

const useDynamicStyles = () => {
  const fluid = useFluidSize({ min: 768, max: 1440 });

  const containerMargin = fluid(24, 40);
  const containerVertical = fluid(16, 24);
  const containerHorizontal = fluid(32, 48);
  const infoSpacing = fluid(8, 24);
  const titleSize = fluid(14, 20);
  const titleHeight = fluid(18, 24);
  const buttonSpacing = fluid(16, 24);
  const imageSize = fluid(134, 268);

  return {
    containerStyle: [
      styles.container,
      useStyle(
        () => ({
          width: '100%',
          marginTop: containerMargin,
          paddingVertical: containerVertical,
          paddingHorizontal: containerHorizontal,
        }),
        [containerMargin, containerVertical, containerHorizontal],
      ),
    ],
    infoStyle: [
      styles.info,
      useStyle(
        () => ({
          marginLeft: infoSpacing,
        }),
        [infoSpacing],
      ),
    ],
    titleStyle: [
      styles.title,
      useStyle(
        () => ({
          fontSize: titleSize,
          lineHeight: titleHeight,
        }),
        [titleSize, titleHeight],
      ),
    ],
    buttonStyle: [
      styles.button,
      useStyle(
        () => ({
          marginTop: buttonSpacing,
        }),
        [buttonSpacing],
      ),
    ],
    imageStyle: useStyle(
      () => ({
        width: imageSize,
        height: imageSize,
      }),
      [imageSize],
    ),
  };
};

const useEvergreenCopy = () => {
  const { t } = useLocalizationContext();

  return {
    titleCopy: t('featured-channel.evergreen.title'),
    buttonCopy: t('featured-channel.evergreen.button'),
    illustrationAlt: t('featured-channel.evergreen.illustration.alt'),
  };
};

// ────────────────── Styles ──────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: theme.colors.QUINOA,
    borderRadius: theme.radius.large,
  },
  info: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
  },
  button: {
    width: '100%',
    maxWidth: 240,
  },
});

// ────────────────── Types ──────────────────

type EvergreenChannelProps = Readonly<{
  onOrderNow: () => void;
}>;

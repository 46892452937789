import React, { useLayoutEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { AddressTypes, Map, ModalRow, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { DeliveryLocationCard } from '../DeliveryLocationCard';
import { RestaurantLocationCard } from '../RestaurantLocationCard';
import type { ConfirmLocationLayoutProps } from './ConfirmLocationLayout.types';
import { ConfirmLocationLayoutFooter } from './ConfirmLocationLayoutFooter';

export const ConfirmLocationLayoutXS = (props: ConfirmLocationLayoutProps) => {
  const {
    deliveryAddress,
    isLoading,
    onContinue,
    onMoreLocations,
    orderType,
    pins,
    region,
    restaurant,
  } = props;

  const navigation = useNavigation();
  const { t } = useLocalizationContext();
  const isDelivery = orderType === AddressTypes.delivery;

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: t('location.confirm-location', { channel: orderType }),
      headerShown: true,
    });
  }, [navigation, orderType, t]);

  return (
    <View testID="confirm-location.container-xs" style={styles.container}>
      <View style={styles.contentContainer}>
        <ModalRow style={styles.mapRow}>
          <View style={styles.mapContainer}>
            <Map
              draggable={false}
              showControls={false}
              region={region}
              pins={pins}
            />
          </View>
        </ModalRow>

        <ModalRow style={styles.locationContainer}>
          {isDelivery && deliveryAddress ? (
            <DeliveryLocationCard deliveryAddress={deliveryAddress} />
          ) : (
            <RestaurantLocationCard
              orderType={orderType}
              restaurant={restaurant}
            />
          )}
        </ModalRow>
      </View>

      <ConfirmLocationLayoutFooter
        isLoading={isLoading}
        onMoreLocations={onMoreLocations}
        onContinue={onContinue}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  contentContainer: {
    flex: 1,
  },
  mapContainer: {
    flex: 1,
    borderRadius: theme.radius.medium,
    borderWidth: 1,
    borderColor: theme.colors.NEUTRAL_3,
    overflow: 'hidden',
  },
  mapRow: {
    flex: 0.5,
    minHeight: 192,
  },
  locationContainer: {
    paddingVertical: theme.spacing['4'],
  },
});

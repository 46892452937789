import type { ReactNode } from 'react';
import React from 'react';
import { StyleSheet, View, type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const FloatingButtonsContainer = (
  props: FloatingButtonsContainerProps,
) => {
  const { children, anchor, style } = props;

  return (
    <View
      pointerEvents="box-none"
      style={[styles.container, style, getAnchorStyle(anchor)]}
    >
      {children}
    </View>
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

const getAnchorStyle = (
  anchor: FloatingButtonsContainerProps['anchor'] = 'right',
) => {
  return {
    left: styles.anchorLeft,
    right: styles.anchorRight,
    full: styles.anchorFull,
  }[anchor];
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    padding: theme.spacing['4'],
    gap: theme.spacing['4'],
  },
  anchorLeft: {
    left: 0,
  },
  anchorRight: {
    right: 0,
  },
  anchorFull: {
    left: 0,
    right: 0,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type FloatingButtonsContainerProps = Readonly<{
  style?: ViewStyle;
  anchor?: 'left' | 'right' | 'full';
  children: ReactNode;
}>;

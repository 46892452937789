import { createModel } from 'xstate/lib/model';

import type {
  CheckoutFieldName,
  CheckoutFieldsValidationState,
  CheckoutPartialPayload,
} from './checkoutFieldsValidation.types';

// ─── Validation Context ─────────────────────────────────────────────────────────

const checkoutFieldsValidationState: CheckoutFieldsValidationState = {
  /**
   * The field validations context is represented as an object, with the keys representing
   * the names of the required checkout fields and the values reflecting
   * their current validation status ('true' === valid, 'false' === invalid).
   */
  fieldsValidation: {
    dropoffLocationId: true,
    paymentMethodId: true,
  },

  /**
   * This object contains required fields' invalid values, allowing the validator
   * to determine the validity state depending on the current value.
   */
  invalidValues: {
    dropoffLocationId: [null],
    paymentMethodId: ['ADD'],
  },
};

// ─── Model ──────────────────────────────────────────────────────────────────────

export const checkoutFieldsValidationModel = createModel(
  checkoutFieldsValidationState,
  {
    events: {
      VALIDATE: (payload: CheckoutPartialPayload) => ({ payload }),
      RESET_VALIDATION: (fieldName: CheckoutFieldName) => ({ fieldName }),
    },
  },
);

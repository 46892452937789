import { switchcase } from '@sg/garnish';

import type { DeliveryPreferenceType } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

export const usePreferencesText = (type: DeliveryPreferenceType): string => {
  const { t } = useLocalizationContext();

  return switchcase({
    LEAVE_AT_DOOR: t('delivery.preferences.leave-at-door'),
    DOOR: t('delivery.preferences.meet-at-door'),
    CURBSIDE: t('delivery.preferences.meet-outside'),
  })(t('delivery.preferences.leave-at-door'))(type);
};

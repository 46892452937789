import React from 'react';
import { TransparentModal as GarnishTransparentModal } from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';

// Wrapper around garnish transparent modal to hook into telemetry.
export const TransparentModal = (
  props: React.ComponentProps<typeof GarnishTransparentModal>,
) => {
  // Hooks close event into telemetry.
  const { track } = useTelemetry();
  const onClose = React.useCallback(() => {
    track('modal.close');
    props.onClose?.();
  }, [track, props]);

  return <GarnishTransparentModal {...props} onClose={onClose} />;
};

import { useContentfulContentTypeEntry } from '@order/Contentful';

// ─────────────────────────────────────────────────────────────────────────────

export const useGiftCardCheckoutContent = (params: UseGiftCardParams) => {
  const { pause } = params;

  // ─── Remote Data ─────────────────────────────────────────────────────

  const { data, error, fetching } =
    useContentfulContentTypeEntry<GiftCardCheckoutContent>({
      contentType: 'giftCardCheckoutScreen',
      include: 1,
      pause,
    });

  // ─────────────────────────────────────────────────────────────────────

  return {
    error,
    fetching,
    data: data?.fields,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutContent = {
  screenTitle: string;
  amountMinAmount: number;
  amountMaxAmount: number;
  amountOptions: string[];
  messageMaxCharacters: number;
  sectionTitleAmount: string;
  sectionTitleDetails: string;
  sectionTitleDeliveryDate: string;
  sectionTitleDeliveryMethod: string;
  sectionTitleRecipientEmail: string;
  sectionTitlePaymentMethod: string;
};

type UseGiftCardParams = {
  pause: boolean;
};

import { useState } from 'react';

import type { CheckoutPayload } from '../../Checkout.utils';

export const useCheckoutPayload = (initial: Partial<CheckoutPayload>) => {
  const [payload, setPayload] = useState<CheckoutPayload>({
    wantedTime: initial.wantedTime ?? fallback.wantedTime,
    paymentMethodId: initial.paymentMethodId ?? fallback.paymentMethodId,
    paymentMethodType: initial.paymentMethodType ?? fallback.paymentMethodType,
    useCredit: initial.useCredit ?? fallback.useCredit,
    dropoffLocationId: initial.dropoffLocationId ?? fallback.dropoffLocationId,
    contactNumber: initial.contactNumber ?? fallback.contactNumber,
    includeUtensils: initial.includeUtensils ?? fallback.includeUtensils,
    pickupTip: initial.pickupTip ?? fallback.pickupTip,
  });

  return { payload, setPayload };
};

const fallback: CheckoutPayload = {
  wantedTime: '',
  paymentMethodId: 'ADD',
  paymentMethodType: 'PaymentMethodCard',
  useCredit: true,
  dropoffLocationId: null,
  contactNumber: null,
  includeUtensils: false,
  pickupTip: 0,
};

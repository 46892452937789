import AsyncStorage from '@react-native-async-storage/async-storage';

import type { CounterMachineContext } from './counter-machine.types';
import {
  getDecrementedCounterValue,
  getIncrementedCounterValue,
} from './counter-machine.utils';

// ─────────────────────────────────────────────────────────────────────────────

export function createGetCounterValueService(asyncStorageKey: string) {
  return async function getCounterValue() {
    return AsyncStorage.getItem(asyncStorageKey);
  };
}

export function createIncrementCounterService(asyncStorageKey: string) {
  return async function incrementCounter(context: CounterMachineContext) {
    const { value: currentValue } = context;
    const { value, valueAsString } = getIncrementedCounterValue(currentValue);

    await AsyncStorage.setItem(asyncStorageKey, valueAsString);

    return value;
  };
}

export function createDecrementCounterService(asyncStorageKey: string) {
  return async function decrementCounter(context: CounterMachineContext) {
    const { value: currentValue } = context;
    const { value, valueAsString } = getDecrementedCounterValue(currentValue);

    await AsyncStorage.setItem(asyncStorageKey, valueAsString);

    return value;
  };
}

export function createResetCounterService(asyncStorageKey: string) {
  return async function resetCounter(_context: CounterMachineContext) {
    await AsyncStorage.setItem(asyncStorageKey, '0');

    return 0;
  };
}

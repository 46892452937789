import React, { type ReactNode } from 'react';
import { StyleSheet } from 'react-native';
import { Container, HStack, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsGridContainer = (
  props: GiftCardsCategoriesGridContainerProps,
) => {
  const { children, shouldJustifyCenter } = props;

  const { match } = useResponsive();

  const stackStyles = [
    shouldJustifyCenter ? styles.containerCentered : undefined,
    match([styles.containerXS, styles.containerSM]),
  ];

  return (
    <Container>
      <HStack
        style={stackStyles}
        itemsPerRow={match([1, 2, 3])}
        gap={match([theme.spacing['6'], theme.spacing['10']])}
      >
        {children}
      </HStack>
    </Container>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    paddingTop: theme.spacing[4],
    paddingBottom: theme.spacing[20],
  },
  containerSM: {
    paddingTop: theme.spacing[10],
    paddingBottom: theme.spacing[20],
  },
  containerCentered: {
    justifyContent: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardsCategoriesGridContainerProps = {
  children: ReactNode;
  shouldJustifyCenter?: boolean;
};

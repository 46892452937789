import React, { useMemo } from 'react';
import type { StyleProp, TextStyle, ViewProps } from 'react-native';
import { ScrollView, StyleSheet } from 'react-native';
import regexifyString from 'regexify-string';
import {
  Container,
  LargeHeaderTitle,
  theme,
  TitleText,
  useResponsive,
  VStack,
} from '@sg/garnish';

import { AppFooter } from '@order/components';
import { useFeatureFlag } from '@order/LaunchDarkly';

import type {
  SweetpassBenefitsPage,
  SweetpassBenefitsPageBenefitCtaHandler,
  SweetpassBenefitsPageCardCtaHandler,
  SweetpassBenefitsPageUpgradeCtaHandler,
} from './hooks';
import { SweetpassBenefitsInnerContent } from './SweetpassBenefitsInnerContent';

// ────────────────────────────────────────────────────────────────────────────────

export const SweetpassBenefitsScreenContent = (
  props: SweetpassBenefitsScreenContentProps,
) => {
  const { testID, content, handleUpgradeCta, handleBenefitCta, handleCardCta } =
    props;

  const isSweetpassPlusUpgradeDisabled = useFeatureFlag(
    'CELS-2877-disable-sweetpass-upgrade',
  );

  const benefits = useMemo(
    () =>
      isSweetpassPlusUpgradeDisabled
        ? content.content.filter(filterOutUpgradeCta)
        : content.content,
    [content.content, isSweetpassPlusUpgradeDisabled],
  );

  const { match } = useResponsive();
  const { currentBreakpoint } = useResponsive();

  const contentWrapperStyle = [
    styles.contentWrapper,
    match([styles.contentWrapperXS, styles.contentWrapperSM]),
  ];

  return (
    <ScrollView testID={testID}>
      <LargeHeaderTitle style={styles.titlePadding}>
        {grenettefyText(content.title, /(sweetpass|sweetpass\+)/i)}
      </LargeHeaderTitle>

      <Container style={styles.container} wrapperStyle={contentWrapperStyle}>
        <VStack>
          {benefits.map((contentItem) => (
            <SweetpassBenefitsInnerContent
              key={contentItem.id}
              content={contentItem}
              upgradeCtaHandler={handleUpgradeCta}
              benefitCtaHandler={handleBenefitCta}
              cardCtaHandler={handleCardCta}
            />
          ))}
        </VStack>
      </Container>
      {currentBreakpoint.isXS ? null : <AppFooter />}
    </ScrollView>
  );
};

/**
 * Design calls for specific font to the "sweetpass" text
 * @see {@link: https://sweetgreen.atlassian.net/browse/CELS-661}
 *
 * @param text is the string that will be processed.
 * @param regex is a regular expression that will be used to match parts of the input string.
 * @returns a processed string with regex-matched parts wrapped in a <span/> with the font Grenette.
 */
function grenettefyText(text: string, regex: RegExp) {
  const granetteFont: StyleProp<TextStyle> = {
    fontFamily: 'GrenettePro-Regular',
  };

  return regexifyString({
    pattern: regex,
    decorator: (chunk, index) => (
      <TitleText key={index} style={granetteFont} size={3}>
        {chunk}
      </TitleText>
    ),
    input: text,
  }).filter((chunk) => chunk !== '');
}

const filterOutUpgradeCta = (
  benefit: SweetpassBenefitsPage['content'][number],
) => benefit.type !== 'sweetpassBenefitsCta';

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  contentWrapper: {
    flexGrow: 1,
  },
  contentWrapperXS: {
    paddingBottom: theme.spacing['6'],
  },
  contentWrapperSM: {
    paddingBottom: theme.spacing['10'],
  },
  titlePadding: {
    paddingBottom: 0,
  },
  container: {
    flexGrow: 1,
  },
});

// ─── TYPES ─────────────────────────────────────────────────────────────────────

export type SweetpassBenefitsScreenContentProps = Readonly<{
  content: SweetpassBenefitsPage;
  handleUpgradeCta: SweetpassBenefitsPageUpgradeCtaHandler;
  handleBenefitCta: SweetpassBenefitsPageBenefitCtaHandler;
  handleCardCta: SweetpassBenefitsPageCardCtaHandler;
}> &
  Pick<ViewProps, 'testID'>;

import { type ComponentProps, useCallback, useLayoutEffect } from 'react';
import type { Modal } from 'react-native';
import { useSelector } from '@xstate/react';

import { useModalMachine } from '../../modal-machine';

/**
 * Wrapper around the Modal Machine integration.
 */
export function useModalMachineIntegration(
  props: ModalMachineIntegrationProps,
) {
  const {
    visible,
    animationType,
    onShowing,
    onShown,
    onDismissing,
    onDismissed,
  } = props;

  const modalMachineInitialState = visible ? 'shown' : 'dismissed';
  const { modalService, helpers: modalServiceHelpers } = useModalMachine({
    initial: modalMachineInitialState,
    onShowing,
    onShown,
    onDismissing,
    onDismissed,
  });

  const isShowing = useSelector(modalService, ({ matches }) =>
    matches('animation.showing'),
  );
  const isShown = useSelector(modalService, ({ matches }) =>
    matches('visibility.shown'),
  );
  const isDismissing = useSelector(modalService, ({ matches }) =>
    matches('animation.dismissing'),
  );
  const isDismissed = useSelector(modalService, ({ matches }) =>
    matches('visibility.dismissed'),
  );

  const syncAnimationState = useCallback(() => {
    if (animationType === 'none') return;

    if (visible) {
      modalServiceHelpers.sendStartShowEvent();

      return;
    }

    modalServiceHelpers.sendStartDismissEvent();
  }, [animationType, modalServiceHelpers, visible]);

  useLayoutEffect(syncAnimationState, [syncAnimationState]);

  return {
    modalState: {
      isShowing,
      isShown,
      isDismissing,
      isDismissed,
    },
    modalServiceHelpers,
  };
}

// ─── Types ─────────────────────────────────────────────────────────────

type ModalMachineIntegrationProps = Readonly<{
  visible?: boolean;
  animationType: ComponentProps<typeof Modal>['animationType'];
  onShowing?: () => void;
  onShown?: () => void;
  onDismissing?: () => void;
  onDismissed?: () => void;
}>;

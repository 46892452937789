import React, { useCallback, useRef } from 'react';
import type { AddressType } from '@sg/garnish';
import {
  AddressOrder,
  ListActionPressable,
  usePressableState,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type { PartialRestaurant } from '../../graphql/partials';
import type { Address, DeliveryOrderDetailInput } from '../../graphql/types';
import { useActiveRouteName } from '../../navigation';
import { useChangeLocation } from '../../screens/ReorderingScreen/hooks';
import { useShouldFinishReorderInMenu } from '../../screens/ReorderingScreen/ReorderingScreen.navigation';

export const DeliveryAddressListAction = (
  props: DeliveryAddressListActionProps,
) => {
  const {
    orderId,
    orderType,
    deliveryAddress,
    deliveryOrderDetail,
    restaurant,
    canChangeLocation = () => true,
  } = props;

  // ─── Context ─────────────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const isOnMenuScreens = useShouldFinishReorderInMenu();
  const isOnCheckout = useActiveRouteName() === 'Checkout';
  const changeLocation = useChangeLocation();
  const { currentBreakpoint } = useResponsive();

  // ─── Pressable Interaction ───────────────────────────────────────────

  const ref = useRef(null);
  const { isInteracting } = usePressableState(ref);

  // ─── Restaurant Info ─────────────────────────────────────────────────

  const {
    id: restaurantId,
    slug: restaurantSlug,
    name: restaurantName,
  } = restaurant ?? {};

  // ─── Callbacks ───────────────────────────────────────────────────────

  const handlePress = useCallback(() => {
    if (!canChangeLocation()) return;
    changeLocation({
      remainOnCheckout: isOnCheckout,
      finishOnMenu: isOnMenuScreens,
      orderId,
      orderType,
      deliveryOrderDetail,
      restaurantId,
      restaurantSlug,
      restaurantName,
      userInitiated: true,
    });
  }, [
    isOnMenuScreens,
    isOnCheckout,
    orderId,
    orderType,
    deliveryOrderDetail,
    restaurantId,
    restaurantSlug,
    restaurantName,
    changeLocation,
    canChangeLocation,
  ]);

  return (
    <ListActionPressable
      ref={ref}
      key={deliveryAddress?.id}
      withCaret={currentBreakpoint.isXS}
      onPress={handlePress}
      accessibilityRole="button"
      accessibilityLabel={t('addresses.address-label', {
        address: deliveryAddress?.name,
      })}
      accessibilityHint={t('addresses.address-hint')}
      testID={`addresses.${deliveryAddress?.name}-card-pressable`}
    >
      <AddressOrder
        isInteracting={isInteracting}
        addressType="delivery"
        {...deliveryAddress}
      />
    </ListActionPressable>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type DeliveryAddressListActionProps = Readonly<{
  orderId?: string;
  deliveryAddress?: Partial<Address>;
  deliveryOrderDetail?: Partial<DeliveryOrderDetailInput>;
  orderType: AddressType;
  restaurant?: Partial<PartialRestaurant>;
  canChangeLocation?: () => boolean;
}>;

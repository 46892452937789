import React, { createContext, useContext, useMemo } from 'react';
import type { ViewProps } from 'react-native';

import { useChallenges, useRewards } from './hooks';

// ────────────────────────────────────────────────────────────────────────────────

const ChallengesAndRewardsContext = createContext<
  ChallengesAndRewardsContextType | undefined
>(undefined);

export const ChallengesAndRewardsProvider = (
  props: Pick<ViewProps, 'children'>,
) => {
  const rewardsContext = useRewards();
  const challengesContext = useChallenges();

  const value = useMemo(
    () => ({
      ...rewardsContext,
      ...challengesContext,
    }),
    [rewardsContext, challengesContext],
  );

  return (
    <ChallengesAndRewardsContext.Provider value={value}>
      {props.children}
    </ChallengesAndRewardsContext.Provider>
  );
};

// ─── HOOKS ──────────────────────────────────────────────────────────────────────

export const useChallengesAndRewards = () => {
  const context = useContext(ChallengesAndRewardsContext);

  if (context === undefined) {
    throw new Error(
      'useChallengesAndRewards must be used within a <ChallengesAndRewardsProvider>',
    );
  }

  return context;
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ChallengesAndRewardsContextType = ReturnType<typeof useRewards> &
  ReturnType<typeof useChallenges>;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { LoadingPlaceholder } from '../../../LoadingPlaceholder';

// ─────────────────────────────────────────────────────────────────────────────

export const UpsellCtaContentLoading = () => {
  return (
    <View style={styles.container}>
      <View style={styles.textContentLoadingPlaceholderContainer}>
        <UpsellCtaContentLoadingPlaceholder />
      </View>

      <View style={styles.iconLoadingPlaceholderContainer}>
        <UpsellCtaContentLoadingPlaceholder />
      </View>
    </View>
  );
};

const UpsellCtaContentLoadingPlaceholder = () => {
  return (
    <LoadingPlaceholder
      rowHeight={iconPlaceholderSize}
      borderRadius={theme.spacing['2']}
      palette="cream"
    />
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const iconPlaceholderSize = 48;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  textContentLoadingPlaceholderContainer: {
    flex: 1,
  },
  iconLoadingPlaceholderContainer: {
    width: iconPlaceholderSize,
    marginLeft: theme.spacing['4'],
  },
});

import React, { type ComponentProps } from 'react';

import {
  SweetpassCheckoutMultiplePlans,
  SweetpassCheckoutSinglePlan,
} from '@order/components';
import { useSweetpassPlusPlans } from '@order/hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const SweetpassCheckoutScreenContent = (
  props: SweetpassCheckoutContentProps,
) => {
  const { planId, isAnnualSubscriptionEnabled } = props;

  // ─── Membership Plans ────────────────────────────────────────────────

  const { plans: membershipPlans } = useSweetpassPlusPlans();
  const { monthly: monthlyPlan, annual: annualPlan } = membershipPlans;

  const annualPlanId = annualPlan?.id;
  const monthlyPlanId = monthlyPlan?.id;

  // ─── Flags ───────────────────────────────────────────────────────────

  const isProvidedPlanIdMatchesOneOfTheMultiplePlans =
    planId && (annualPlanId === planId || monthlyPlanId === planId);

  const shouldRenderMultiplePlanCheckoutScreen =
    (isAnnualSubscriptionEnabled && !planId) ||
    (isAnnualSubscriptionEnabled &&
      isProvidedPlanIdMatchesOneOfTheMultiplePlans);

  // ─────────────────────────────────────────────────────────────────────

  if (shouldRenderMultiplePlanCheckoutScreen) {
    return <SweetpassCheckoutMultiplePlans {...props} />;
  }

  return <SweetpassCheckoutSinglePlan {...props} />;
};

type SweetpassCheckoutContentProps = ComponentProps<
  typeof SweetpassCheckoutMultiplePlans
> &
  Readonly<{
    planId: string;
    isAnnualSubscriptionEnabled: boolean;
  }>;

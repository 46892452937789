import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { theme, TYPE_CONFIG } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const BenefitCardText = (props: BenefitCardImgProps) => {
  const { children } = props;

  const { match } = useResponsive();

  const textResponsiveStyles = match([
    styles.textXS,
    styles.textSM,
    styles.textMD,
  ]);
  const textStyles = [styles.text, textResponsiveStyles];

  return <Text style={textStyles}>{children}</Text>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    color: theme.colors.KALE,
    textAlign: 'center',
  },
  textXS: {
    ...TYPE_CONFIG.BODY['14'],
    textAlign: 'left',
  },
  textSM: {
    ...TYPE_CONFIG.BODY['14'],
  },
  textMD: {
    ...TYPE_CONFIG.BODY['18'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BenefitCardImgProps = Readonly<{
  children: string;
}>;

import React, { useLayoutEffect } from 'react';
import { Platform } from 'react-native';
import { useResponsive } from '@sg/garnish';

import { useIsLoggedIn, useIsLoggingIn } from '@order/AuthMachine';
import { LoadingAnimation } from '@order/components';

import { AccountSignedOutMessage } from '../../components/Account';
import type { AccountMenuScreenProps } from '../../navigation/AppNavigation.props';
import { ProfileScreen } from '../ProfileScreen';
import { AccountScreenContent } from './AccountScreen.content';

export const AccountScreen = ({ navigation }: AccountMenuScreenProps) => {
  const { currentBreakpoint } = useResponsive();

  const isLoggedIn = useIsLoggedIn();
  const isLoggingIn = useIsLoggingIn();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: !currentBreakpoint.isXS,
    });
  }, [navigation, currentBreakpoint.isXS]);

  if (isLoggingIn) return <LoadingAnimation />;

  if (!isLoggedIn && Platform.OS === 'web') {
    return <AccountSignedOutMessage />;
  }

  if (!currentBreakpoint.isXS && isLoggedIn) {
    return <ProfileScreen />;
  }

  return <AccountScreenContent />;
};

import React, { useCallback } from 'react';
import { type RouteProp } from '@react-navigation/native';
import { useRoute } from '@react-navigation/native';
import { RecommendationProductCard } from '@sg/garnish';

import type { RecommendedItemFragment } from '@order/graphql';
import { useNavigateToPersonalizedProductDetails } from '@order/hooks';
import { useTelemetry } from '@order/Telemetry';

import type { PartialProduct } from '../../../../../graphql/partials';
import { type MenuTabStackParamList } from '../../../../../navigation/AppNavigation.props';
import { usePersonalizedRecommendationCardContentfulData } from '../../../hooks/usePersonalizedRecommendationCardContentfulData';

// ────────────────────────────────────────────────────────────────────────────────

export const MenuRecommendationItem = (props: MenuRecommendationItemProps) => {
  const { product, recommendation } = props;
  const { track } = useTelemetry();

  const { params } =
    useRoute<RouteProp<MenuTabStackParamList, 'MenuCollection'>>();
  const { restaurantSlug, addressId } = params ?? {};

  const navigateToRecommendation = useNavigateToPersonalizedProductDetails({
    restaurantSlug,
    deliveryAddressId: addressId,
    recommendation,
  });

  const contentfulRecommendationData =
    usePersonalizedRecommendationCardContentfulData({
      pause: false,
    });

  const onRecommendationCardPressTelemetry = useCallback(() => {
    track('menu.personalization.recommendations.selected', {
      recommendation,
      cardVariant: contentfulRecommendationData?.variant,
    });
  }, [contentfulRecommendationData?.variant, recommendation, track]);

  const onCardPress = useCallback(() => {
    onRecommendationCardPressTelemetry();
    navigateToRecommendation(product);
  }, [navigateToRecommendation, product, onRecommendationCardPressTelemetry]);

  // ─────────────────────────────────────────────────────────────────

  return (
    <RecommendationProductCard
      name={contentfulRecommendationData.name}
      description={recommendation.description}
      imageSrc={contentfulRecommendationData.productImageUrl}
      label={contentfulRecommendationData.tag}
      onCardPress={onCardPress}
    />
  );
};

type MenuRecommendationItemProps = Readonly<{
  product: PartialProduct;
  recommendation: Recommendation;
}>;

type Recommendation = RecommendedItemFragment;

import type { ComponentProps } from 'react';
import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { Button } from '../../Button';
import { Icon } from '../../Icon';
import { LinearProgressBar } from '../../LinearProgressBar';
import { BodyText } from '../../Text';

const LoyaltyChallengeFooterBase = (props: ViewProps) => {
  return <View style={styles.footer}>{props.children}</View>;
};

// -------------------------- VARIATIONS --------------------------

export const LoyaltyChallengeFooterNew = (props: FooterNewProps) => {
  const { disabled, isLoading, onPress, testID } = props;
  const label = 'Start challenge';

  const { match } = useResponsive();

  return (
    <LoyaltyChallengeFooterBase>
      <Button
        palette="primary"
        onPress={onPress}
        accessibilityLabel="Start Challenge"
        accessibilityRole="button"
        testID={testID}
        size={match(['medium', 'large'])}
        isLoading={isLoading}
        disabled={disabled}
        style={styles.startChallengeBtn}
      >
        <BodyText style={styles.textColorWhite}>{label}</BodyText>
      </Button>
    </LoyaltyChallengeFooterBase>
  );
};

export const LoyaltyChallengeFooterOngoing = (props: FooterOngoingProps) => {
  const { completedSteps, progressCopy, totalSteps, testID, footerText } =
    props;

  const { match } = useResponsive();

  return (
    <LoyaltyChallengeFooterBase>
      <View style={styles.progressBarContainer} testID={testID}>
        <LinearProgressBar
          progress={(completedSteps / totalSteps) * 100}
          label={progressCopy}
          size={match(['medium', 'large'])}
          animated
        />

        {footerText ? (
          <BodyText size={4} style={styles.completedInfo}>
            {footerText}
          </BodyText>
        ) : null}
      </View>
    </LoyaltyChallengeFooterBase>
  );
};

export const LoyaltyChallengeFooterCompleted = ({
  completionText,
  testID,
}: FooterCompletedProps) => {
  return (
    <LoyaltyChallengeFooterBase>
      <View style={styles.completedFooter} testID={testID}>
        <Icon
          name="IconBadgeCheck"
          color={theme.colors.KALE}
          width={24}
          height={24}
        />
        <BodyText style={styles.completedText}>{completionText}</BodyText>
      </View>
    </LoyaltyChallengeFooterBase>
  );
};

// ---------------------------- STYLES ----------------------------

const styles = StyleSheet.create({
  footer: {
    paddingTop: theme.spacing['4'],
  },
  progressBarContainer: {
    width: '100%',
  },
  textColorWhite: {
    color: theme.colors.WHITE,
  },
  completedInfo: {
    marginTop: theme.spacing['2'],
  },
  completedFooter: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  completedText: {
    marginLeft: theme.spacing['2'],
    color: theme.colors.KALE,
  },
  startChallengeBtn: {
    width: '100%',
    alignSelf: 'flex-start',
  },
});

// ---------------------------- TYPES ----------------------------

type FooterNewProps = Pick<
  ComponentProps<typeof Button>,
  'onPress' | 'disabled' | 'isLoading'
> &
  Pick<ViewProps, 'testID'>;

type FooterOngoingProps = Readonly<{
  totalSteps: number;
  completedSteps: number;
  progressCopy: string;
  footerText: string;
}> &
  Pick<ViewProps, 'testID'>;

type FooterCompletedProps = Readonly<{
  completionText: string;
}> &
  Pick<ViewProps, 'testID'>;

import React from 'react';
import type { ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';
import { theme } from '@garnish/constants';

import { useUniqueNativeID } from '../../hooks';

// ────────────────────────────────────────────────────────────────────────────────

export const ShadowDivider = (props: ShadowDividerProps) => {
  const {
    orientation = 'down',
    height = 12,
    color = DEFAULT_GRADIENT_COLOR,
    offset,
  } = props;

  const isUpOrientation = orientation === 'up';

  // To avoid problems when there are several `ShadowDivider` components
  // on the same screen, we must use unique IDs for each component.
  const gradientId = useUniqueNativeID('shadow-divider-gradient');

  const gradientStartOpacity = isUpOrientation
    ? GRADIENT_OPACITY_LIGHT
    : GRADIENT_OPACITY;
  const gradientEndOpacity = isUpOrientation
    ? GRADIENT_OPACITY
    : GRADIENT_OPACITY_LIGHT;

  const offsetStyle = useStyle(() => {
    if (!offset) return;

    return { transform: [{ translateY: offset }] };
  }, [offset]);

  // ─────────────────────────────────────────────────────────────────

  return (
    <View pointerEvents="none" style={styles.wrapper}>
      {/* NOTE: we cannot alter the gradient direction using the 'rotation' property, because not all platforms fully support it */}
      {/* @ts-expect-error TS(2322): Type 'DimensionValue' is not assignable to type 'N... Remove this comment to see the full error message */}
      <Svg height={height} width="100%" style={offsetStyle}>
        <Defs>
          <LinearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
            <Stop
              offset="0"
              stopColor={color}
              stopOpacity={gradientStartOpacity}
            />
            <Stop
              offset="1"
              stopColor={color}
              stopOpacity={gradientEndOpacity}
            />
          </LinearGradient>
        </Defs>

        {/* @ts-expect-error TS(2322): Type 'DimensionValue' is not assignable to type 'N... Remove this comment to see the full error message */}
        <Rect height={height} width="100%" fill={`url(#${gradientId})`} />
      </Svg>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    overflow: 'hidden',
  },
});

// ────────────────────────────────────────────────────────────────────────────────

const DEFAULT_GRADIENT_COLOR = theme.colors.GRAY;
const GRADIENT_OPACITY = 1;
const GRADIENT_OPACITY_LIGHT = 0.05;

// ────────────────────────────────────────────────────────────────────────────────

type ShadowDividerProps = Readonly<{
  orientation?: 'up' | 'down';
  height?: ViewStyle['height'];
  offset?: number;
  color?: string;
}>;

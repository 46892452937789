import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const AccountScreenSection = (props: AccountScreenSectionProps) => {
  const { children, style, testID = 'account.screen-section' } = props;

  const { match } = useResponsive();

  const sectionStyle = [
    styles.section,
    match([undefined, styles.sectionSM]),
    style,
  ];

  return (
    <View testID={testID} style={sectionStyle}>
      {children}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  section: {
    flexGrow: 1,
  },
  sectionSM: {
    borderRadius: theme.radius.large,
    backgroundColor: theme.colors.APP_BACKGROUND,
    width: '100%',
    maxWidth: 664,
    marginBottom: theme.spacing['10'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type AccountScreenSectionProps = Pick<
  ViewProps,
  'children' | 'style' | 'testID'
>;

import { useSweetpassBillingHistoryQuery } from '../graphql/SweetpassBillingHistory.generated';

// ─────────────────────────────────────────────────────────────────────────────

export const useSweetpassBillingHistory = (
  params: UseSweetpassBillingHistoryParams,
) => {
  const { pause } = params;

  const [{ data, fetching: isFetching }] = useSweetpassBillingHistoryQuery({
    requestPolicy: 'cache-and-network',
    pause,
  });

  const result = data?.paymentSubscriptionProfile;
  const billingHistory = result?.transactionHistory ?? [];

  return { billingHistory, isFetching };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseSweetpassBillingHistoryParams = {
  pause: boolean;
};

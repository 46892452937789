import React, { type ReactNode } from 'react';
import { HStack, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationIngredientsGridSectionStack = (
  props: CustomizationIngredientsGridSectionStackProps,
) => {
  const { children } = props;

  const { match } = useResponsive();

  return <HStack itemsPerRow={match([3, 2, 4, 5])}>{children}</HStack>;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationIngredientsGridSectionStackProps = {
  children: ReactNode;
};

import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import {
  Button,
  theme,
  useNoticeBannersStackContext,
  useResponsive,
} from '@sg/garnish';
import type { DietaryPropertyKind } from '@sg/graphql-schema';

import {
  AccountScreenContainer,
  AccountScreenSection,
  AccountScreenTitle,
} from '@order/components';
import { MenuDietaryPreferences } from '@order/components/menu';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

import {
  DietaryRestrictions,
  useDietaryRestrictionsMachine,
} from '../../components';

export const AccountDietaryRestrictionsScreen = () => {
  const { t } = useLocalizationContext();
  const { minWidth, currentBreakpoint } = useResponsive();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const {
    sortedRestrictionsNames,
    restrictions,
    isModified,
    confirmRestrictions,
    toggleRestriction,
    revertRestrictions,
  } = useDietaryRestrictionsMachine();

  // ─── Flags ───────────────────────────────────────────────────────

  const isMenuRedesignEnabled = useFeatureFlag(
    'CELS-2852-redesign-menu-enabled',
  );

  // ─────────────────────────────────────────────────────────────────

  const containerStyle = [
    styles.container,
    minWidth.isSM && styles.containerSM,
  ];
  const wrapperStyle = minWidth.isSM && styles.wrapper;

  // ─────────────────────────────────────────────────────────────────

  const confirmDietaryRestrictions = useCallback(() => {
    confirmRestrictions();
    addNoticeBanner({
      text: t('dietary-restrictions.successful-change'),
      palette: 'success',
    });
  }, [addNoticeBanner, confirmRestrictions, t]);

  // revert unconfirmed dietary restrictions on screen blur
  useFocusEffect(useCallback(() => revertRestrictions, [revertRestrictions]));

  // ─────────────────────────────────────────────────────────────────

  if (isMenuRedesignEnabled) {
    return (
      <AccountScreenContainer screen="dietary-restrictions">
        <AccountScreenTitle title={t('account.dietary-restrictions.title')} />

        <AccountScreenSection>
          <MenuDietaryPreferences.Container withoutHorizontalSpacing>
            <MenuDietaryPreferences.Description />

            <MenuDietaryPreferences.ButtonsContainer>
              {sortedRestrictionsNames.map((restriction) => (
                <MenuDietaryPreferences.Button<DietaryPropertyKind>
                  key={restriction}
                  restriction={restriction}
                  isChecked={restrictions[restriction]}
                  toggleRestriction={toggleRestriction}
                />
              ))}
            </MenuDietaryPreferences.ButtonsContainer>

            <MenuDietaryPreferences.Notices />
            <MenuDietaryPreferences.SubmitButton
              onPress={confirmDietaryRestrictions}
            />
          </MenuDietaryPreferences.Container>
        </AccountScreenSection>
      </AccountScreenContainer>
    );
  }

  return (
    <AccountScreenContainer screen="dietary-restrictions">
      <AccountScreenTitle title={t('account.dietary-restrictions.title')} />

      <AccountScreenSection>
        <View style={containerStyle}>
          <View style={wrapperStyle}>
            <DietaryRestrictions
              shouldHideIllustration={currentBreakpoint.isXS}
            />
          </View>

          <View style={styles.ctaWrapper}>
            <Button
              testID="dietary.restrictions.confirm"
              onPress={confirmDietaryRestrictions}
              size="large"
              style={styles.cta}
              // @ts-expect-error TS(2322): Type '{ children: string; testID: string; onPress:... Remove this comment to see the full error message
              focusOutlineColor={theme.colors.GRAY}
              disabled={!isModified}
            >
              {t('general.confirm')}
            </Button>
          </View>
        </View>
      </AccountScreenSection>
    </AccountScreenContainer>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  containerSM: {
    borderRadius: theme.radius.large,
    padding: theme.spacing['4'],
    borderWidth: 1,
    borderColor: theme.colors.GRAY,
  },
  wrapper: {
    padding: theme.spacing['4'],
  },
  ctaWrapper: {
    paddingTop: theme.spacing['6'],
    paddingBottom: theme.spacing['4'],
  },
  cta: {
    minWidth: 207,
  },
});

import React from 'react';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { ImageBackground } from 'expo-image';

export const HomeHeroImageXS = (props: HomeHeroImageXSProps) => {
  return (
    <ImageBackground
      contentFit="cover"
      accessibilityLabel={props.imageA11yLabel}
      source={props.imageUrl}
      style={styles.imageBackground}
    >
      <SafeAreaView edges={['top']}>{props.children}</SafeAreaView>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  imageBackground: { flexGrow: 1 },
});

type HomeHeroImageXSProps = {
  children: React.ReactNode;
  imageUrl: string;
  imageA11yLabel: string;
};

import React, { type ComponentProps } from 'react';

import { IconLink } from '../../../Icon';
import { CustomNavigationHeaderItem } from '../CustomNavigationHeaderItem';

// ─────────────────────────────────────────────────────────────────────────────

const CustomNavigationHeaderButtonRight = (props: IconProps) => {
  return <CustomNavigationHeaderButtonTemplate position="right" {...props} />;
};

const CustomNavigationHeaderButtonLeft = (props: IconProps) => {
  return <CustomNavigationHeaderButtonTemplate position="left" {...props} />;
};

const CustomNavigationHeaderButtonTemplate = (
  props: CustomNavigationHeaderButtonTemplateProps,
) => {
  const { position, name, ...restProps } = props;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <CustomNavigationHeaderItem.Template position={position}>
      <IconLink
        palette="muted"
        name={name}
        iconSize={24}
        width={40}
        height={40}
        {...restProps}
      />
    </CustomNavigationHeaderItem.Template>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

export const CustomNavigationHeaderButton = {
  Right: CustomNavigationHeaderButtonRight,
  Left: CustomNavigationHeaderButtonLeft,
};

// ─── Types ───────────────────────────────────────────────────────────────────

type PositionProps = { position: 'right' | 'left' };
type IconProps = ComponentProps<typeof IconLink>;
type CustomNavigationHeaderButtonTemplateProps = IconProps & PositionProps;

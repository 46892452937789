import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { ScanCard } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { EarnAndRedeemIllustration } from '../../../../assets';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanInStoreEarnAndRedeemCardWithoutSubscription = () => {
  const { t } = useLocalizationContext();
  const { navigate } = useNavigation();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToSweetpass = useCallback(() => {
    navigate('MainTabs', {
      screen: 'LoyaltyTab',
      params: { screen: 'SweetpassHome' },
    });
  }, [navigate]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ScanCard.Container palette="cucumber">
      <ScanCard.Illustration
        source={EarnAndRedeemIllustration}
        accessibilityLabel={t(
          'scan-in-store.tabs.earn-and-redeem.card.illustration-alt',
        )}
      />

      <ScanCard.Text>
        {t('scan-in-store.tabs.earn-and-redeem.card.without-subscription.text')}
      </ScanCard.Text>

      <ScanCard.Cta onPress={navigateToSweetpass}>
        {t(
          'scan-in-store.tabs.earn-and-redeem.card.without-subscription.cta.title',
        )}
      </ScanCard.Cta>
    </ScanCard.Container>
  );
};

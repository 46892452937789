import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { useRequestAccountDeletionMutation } from '../../graphql/RequestAccountDeletion.generated';

export const useAccountDeletion = (props: UseAccountDeletionProps) => {
  const { onClose, onSuccess } = props;

  const { track } = useTelemetry();
  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  const [deleteAccountResponse, deleteAccount] =
    useRequestAccountDeletionMutation();
  const isDeletingAccount = deleteAccountResponse.fetching;

  const requestAccountDeletion = useCallback(async () => {
    track('account.profile_request_deletion_confirmed');
    // @ts-expect-error TS(2554): Expected 1-2 arguments, but got 0.
    const { data } = await deleteAccount();
    const result = data?.requestAccountDeletion?.__typename;

    if (result !== 'RequestAccountDeletionSuccess') {
      addNoticeBanner({ text: t('account.delete.error'), palette: 'caution' });

      return;
    }

    addNoticeBanner({ text: t('account.delete.success'), palette: 'success' });
    onClose();
    onSuccess();
  }, [track, t, deleteAccount, addNoticeBanner, onClose, onSuccess]);

  return { isDeletingAccount, requestAccountDeletion };
};

type UseAccountDeletionProps = Readonly<{
  onSuccess: () => void;
  onClose: () => void;
}>;

import { useCallback } from 'react';
import { useClient } from 'urql';

import {
  BagCreditsDocument,
  type BagCreditsQuery,
} from '../../GraphQL/BagCredits.generated';

// ────────────────────────────────────────────────────────────────────────────

/**
 * A hook for fetching the available credit amount.
 */
export const useCredits = () => {
  const client = useClient();

  // ─── Queries ──────────────────────────────────────────────────────────────

  const fetchCredits = useCallback(async () => {
    const queryCredits = client.query<BagCreditsQuery>;

    const response = await queryCredits(
      BagCreditsDocument,
      {},
      { requestPolicy: 'network-only' },
    ).toPromise();

    return response.data?.currentCustomer?.availableCredit ?? 0;
  }, [client.query]);

  // ──────────────────────────────────────────────────────────────────────────

  return { fetchCredits };
};

import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';

import type { GiftCardsTabStackParamList } from '../../../../../navigation/AppNavigation.props';
import {
  type SubmitGiftCardOrderUsingPaymentMethodMutationVariables,
  useSubmitGiftCardOrderUsingPaymentMethodMutation,
} from '../../../graphql/GiftCardCheckout.generated';
import { giftCardCheckoutMessages as messages } from './useGiftCardCheckout.messages';

// ────────────────────────────────────────────────────────────────────────────────

export const useGiftCardCheckout = () => {
  const [, submitGiftCard] = useSubmitGiftCardOrderUsingPaymentMethodMutation();

  const { track } = useTelemetry();
  const { formatMessage } = useIntl();
  const { push: addNoticeBanner, remove: removeNoticeBanner } =
    useNoticeBannersStackContext();
  const { navigate } =
    useNavigation<NativeStackNavigationProp<GiftCardsTabStackParamList>>();

  // ─── Helpers ─────────────────────────────────────────────────────────

  /**
   * Displays a custom error notice banner with predefined options.
   */
  const onSubmissionError = useCallback(
    (payload: OnSubmissionErrorPayload) => {
      const { userError, systemError } = payload;

      track('gift.purchase_failure', { userError, systemError });

      addNoticeBanner(
        {
          id: SUBMISSION_ERROR_NOTICE_BANNER_ID,
          text: formatMessage(messages.errorBannerText),
          palette: 'caution',
        },
        true,
      );
    },
    [addNoticeBanner, formatMessage, track],
  );

  /**
   * Removes a pending error notice banner.
   */
  const removeErrorNoticeBanner = useCallback(() => {
    removeNoticeBanner(SUBMISSION_ERROR_NOTICE_BANNER_ID);
  }, [removeNoticeBanner]);

  const onSubmissionSuccess = useCallback(
    (orderId: string) => {
      track('gift.purchase_success');
      navigate('GiftCardConfirmation', { orderId });
    },
    [navigate, track],
  );

  const checkoutGiftCard = useCallback(
    async (
      variables: SubmitGiftCardOrderUsingPaymentMethodMutationVariables,
    ) => {
      removeErrorNoticeBanner();

      const { data, error } = await submitGiftCard(variables);

      const response = data?.submitGiftCardOrderUsingPaymentMethod;
      const responseTypename = response?.__typename;

      // ─────────────────────────────────────────────────────────────────────

      const hasNetworkError = error !== undefined;
      const hasSubmittedSuccessfully =
        responseTypename === 'GiftCardOrderDetail' &&
        response?.id !== undefined;
      const hasFailedToSubmit = hasNetworkError || !hasSubmittedSuccessfully;

      // ─────────────────────────────────────────────────────────────────────

      if (hasFailedToSubmit) {
        onSubmissionError({ userError: responseTypename, systemError: error });

        return;
      }

      if (hasSubmittedSuccessfully) {
        onSubmissionSuccess(response.id);
      }

      return data;
    },
    [
      removeErrorNoticeBanner,
      submitGiftCard,
      onSubmissionError,
      onSubmissionSuccess,
    ],
  );

  return { checkoutGiftCard };
};

// ─── Constants ───────────────────────────────────────────────────────────────

const SUBMISSION_ERROR_NOTICE_BANNER_ID = 'gift-card-checkout-submit-error';

// ─── Types ───────────────────────────────────────────────────────────────────

type OnSubmissionErrorPayload = {
  systemError: unknown | undefined;
  userError: string | undefined;
};

import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

export const LOYALTY_PALETTES = StyleSheet.create({
  premiumBgColor: {
    backgroundColor: theme.colors.DARK_KALE,
  },
  premiumFgColor: {
    color: theme.colors.CREAM,
  },
});

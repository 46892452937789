import type { Ref } from 'react';
import React, { forwardRef, useCallback, useEffect } from 'react';
import type { LayoutChangeEvent, Text } from 'react-native';
import { StyleSheet } from 'react-native';
import { useLayout } from 'react-native-web-hooks';
import { COLORS } from '@garnish/constants';

import { BodyText } from '../../Text';
import type { TabNavTextProps } from '../TabNav.types';

export const TabText = forwardRef((props: TabNavTextProps, ref: Ref<Text>) => {
  const { children, active, onChangeMeasures } = props;
  const { onLayout, width, height, x, y } = useLayout();

  useEffect(() => {
    if (onChangeMeasures) {
      onChangeMeasures({ width, height, x, y });
    }
  }, [width, height, x, y, onChangeMeasures]);

  const style = React.useMemo(
    () => [{ fontSize: 17 }, active ? styles.active : styles.inactive],
    [active],
  );

  const handleLayout = useCallback(
    (event: LayoutChangeEvent) => {
      onLayout(event);
    },
    [onLayout],
  );

  return (
    <BodyText onLayout={handleLayout} ref={ref} style={style}>
      {children}
    </BodyText>
  );
});

const styles = StyleSheet.create({
  active: { color: COLORS.GREEN_2 },
  inactive: { color: COLORS.OPACITY.DARK_KALE.DARKER },
});

import type { ComponentProps } from 'react';
import React, { useEffect, useRef } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { type Image } from '@sg/garnish';

import type { NotFoundScreenProps } from '../../navigation/AppNavigation.props';
import { usePermanentRedirects } from '../../screens/NotFoundScreen/hooks';
import { LoadingAnimation } from '../LoadingAnimation';
import {
  NotFoundViewCta,
  NotFoundViewDescription,
  NotFoundViewImage,
  NotFoundViewTitle,
  NotFoundViewWrapper,
} from './NotFoundView.components';

// ────────────────────────────────────────────────────────────────────────────────

export const NotFoundView = (props: NotFoundViewProps) => {
  const {
    illustration,
    title,
    description,
    btnLabel,
    withoutFooter,
    withoutCta,
    onBtnPress,
    onMount,
  } = props;

  const onMountCallbackRef = useRef<NotFoundViewProps['onMount']>(onMount);

  // eslint-disable-next-line functional/immutable-data
  onMountCallbackRef.current = onMount;

  const navigation = useNavigation();
  const route = useRoute();

  // ─── Effects ─────────────────────────────────────────────────────

  // triggers the `onMount` callback on component mount (if provided)
  useEffect(() => {
    const { current: onMountCallback } = onMountCallbackRef;

    if (onMountCallback) onMountCallback();
  }, []);

  // ─────────────────────────────────────────────────────────────────

  const hasPermanentRedirect = usePermanentRedirects({
    navigation,
    route,
  } as unknown as NotFoundScreenProps);

  // ─────────────────────────────────────────────────────────────────

  if (hasPermanentRedirect) {
    return <LoadingAnimation />;
  }

  return (
    <NotFoundViewWrapper withoutFooter={withoutFooter}>
      <NotFoundViewImage illustration={illustration} />
      <NotFoundViewTitle title={title} />
      <NotFoundViewDescription description={description} />
      {withoutCta ? null : (
        <NotFoundViewCta btnLabel={btnLabel} onBtnPress={onBtnPress} />
      )}
    </NotFoundViewWrapper>
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────────

type NotFoundViewProps = Readonly<{
  illustration?: ComponentProps<typeof Image>['source'];
  title?: string;
  description?: string;
  btnLabel?: string;
  withoutCta?: boolean;
  withoutFooter?: boolean;
  onBtnPress?: () => void;
  onMount?: () => void;
}>;

// ─── Compound Components ─────────────────────────────────────────────────────

NotFoundView.Wrapper = NotFoundViewWrapper; // eslint-disable-line functional/immutable-data
NotFoundView.Image = NotFoundViewImage; // eslint-disable-line functional/immutable-data
NotFoundView.Title = NotFoundViewTitle; // eslint-disable-line functional/immutable-data
NotFoundView.Description = NotFoundViewDescription; // eslint-disable-line functional/immutable-data
NotFoundView.Cta = NotFoundViewCta; // eslint-disable-line functional/immutable-data

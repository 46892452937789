import { type AccountInfo } from '@azure/msal-browser';

import { clientInstance } from '../../client';
import { config } from '../../config';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Handles Azure AD redirect response.
 *
 * 1. Handle the redirect response if it is available.
 * 2. Otherwise, it will return the default `undefined` value which will signal
 *    consumer to start a new auth session flow.
 *
 * @see {@link https://learn.microsoft.com/en-us/entra/identity-platform/scenario-spa-sign-in?tabs=javascript2#sign-in-with-redirect}
 */
export async function handleRedirect() {
  const staleActiveAccount = clientInstance?.getActiveAccount();

  if (staleActiveAccount) {
    await resetStaleSession(staleActiveAccount);
  }

  const redirectResult = await clientInstance?.handleRedirectPromise();
  const fetchedAccount = clientInstance?.getAllAccounts()?.[0] ?? null;

  // ─── Reset a Possible Stale Session ──────────────────────────────────

  if (!redirectResult && fetchedAccount) {
    await resetStaleSession(fetchedAccount);
  }

  // ─── Set Active Account ──────────────────────────────────────────────

  clientInstance?.setActiveAccount(fetchedAccount);

  // ─── Handle Redirect Result ──────────────────────────────────────────

  if (redirectResult && fetchedAccount) {
    const apiAccessToken = redirectResult.accessToken;
    const vendorApiAccessTokenResult = await fetchVendorApiAccessToken();
    const vendorApiAccessToken = vendorApiAccessTokenResult?.accessToken;

    const { homeAccountId } = redirectResult.account ?? {};
    const apiRefreshToken = extractRefreshTokenFromStorage(homeAccountId);
    const email = fetchedAccount?.idTokenClaims?.[
      'signInNames.emailAddress'
    ] as string | undefined;

    if (!apiAccessToken || !email || !vendorApiAccessToken) {
      throw new Error('Failed to fetch access tokens and email');
    }

    return { apiAccessToken, apiRefreshToken, vendorApiAccessToken, email };
  }
}

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * Returns an access token that can be used for making protected Vendor API requests
 * by using an active refresh token.
 */
async function fetchVendorApiAccessToken() {
  const account = clientInstance?.getActiveAccount();

  if (!account) {
    throw new Error('No active account was found.');
  }

  const request = { ...config.scopes.vendorApiLoginRequest, account };

  return clientInstance?.acquireTokenSilent(request);
}

/**
 * Returns the stored refresh token from the local storage.
 */
function extractRefreshTokenFromStorage(
  homeAccountId: string,
): string | null | undefined {
  const { cacheLocation, clientId } = config;

  const storage = window[cacheLocation];
  const keys = JSON.parse(
    storage?.getItem(`${MSAL_TOKEN_KEYS}.${clientId}`) ?? '{}',
  );
  const refreshTokenKeys = (keys?.refreshToken ?? []) as string[] | undefined;
  const refreshTokenKey = refreshTokenKeys?.find((key) =>
    key.startsWith(homeAccountId),
  );

  const refreshTokenResult = refreshTokenKey
    ? JSON.parse(storage?.getItem(refreshTokenKey) ?? '{}')
    : {};

  return refreshTokenResult?.secret;
}

// ─── Constants ───────────────────────────────────────────────────────────────

const MSAL_TOKEN_KEYS = 'msal.token.keys';

// ─── Helpers ─────────────────────────────────────────────────────────────────

async function resetStaleSession(account?: AccountInfo | null | undefined) {
  // NOTE: This will force MSAL to invalidate any stale state
  sessionStorage.removeItem('msal.interaction.status');

  return clientInstance?.logoutRedirect({
    account,
    postLogoutRedirectUri: config.redirectUri,
  });
}

import React, { type ComponentProps, useCallback } from 'react';
import { useResponsive } from '@sg/garnish';

import { useAddSweetpassPaymentMethod } from '@order/hooks';
import { BraintreeCreditCardForm } from '@order/integrations';
import { useLocalizationContext } from '@order/Localization';

import { Modal } from '../Modal';

// ─────────────────────────────────────────────────────────────────────────────

export const SweetpassPaymentMethodAddModal = (
  props: SweetpassPaymentMethodAddModalProps,
) => {
  const { visible, onRequestClose } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  // ─── Mutation ────────────────────────────────────────────────────────

  const { isLoading: isSubmitting, addSweetpassPaymentMethod } =
    useAddSweetpassPaymentMethod();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnSave = useCallback(
    async (
      payload: Parameters<
        ComponentProps<typeof BraintreeCreditCardForm>['handleOnSave']
      >[0],
    ) => {
      return addSweetpassPaymentMethod({
        nonce: payload.id,
        onSuccess: onRequestClose,
      });
    },
    [addSweetpassPaymentMethod, onRequestClose],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Modal
      fitHeight
      visible={visible}
      accessibilityLabel={t('sweetpass.signed-in.add-payment-method-title')}
      presentationStyle="pageSheet"
      size={match(['full', 'small'])}
      onRequestClose={onRequestClose}
    >
      <Modal.Header onClose={onRequestClose}>
        {t('sweetpass.signed-in.add-payment-method-title')}
      </Modal.Header>

      <BraintreeCreditCardForm
        vault="subscription"
        excludedFields={['isDefault', 'nickname']}
        isSubmitting={isSubmitting}
        handleOnSave={handleOnSave}
      />
    </Modal>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type SweetpassPaymentMethodAddModalProps = Readonly<{
  visible: boolean;
  onRequestClose: () => void;
}>;

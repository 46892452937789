/**
 * Returns the length of decimals places in the provided numeric value.
 */
export function getDecimalsPlacesLength(numericValue: string) {
  return numericValue.split('.')?.[1]?.length ?? 0;
}

/**
 * Returns the value of the given percentage of the subtotal.
 */
export const getRoundedValueOfPercentage = (
  percentage: number,
  subtotal: number,
) => {
  return Math.round((percentage / 100) * subtotal);
};

import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../hooks';
import { PickersGroup } from '../Pickers/PickersGroup';
import { Select } from '../Select';
import { HStack } from '../Stack';
import { BodyText } from '../Text';

// ────────────────────────────────────────────────────────────────────────────────

export const BirthdayPicker = (props: BirthdayPickerProps) => {
  const {
    value,
    helperText,
    label,
    monthLabel = 'Month',
    dayLabel = 'Day',
    monthNames = generateMonthNamesList(),
    accessibilityLabel,
    onChange,
  } = props;
  const { currentBreakpoint } = useResponsive();

  // ─── HELPERS ────────────────────────────────────────────────────────

  const optionsGroups = useMemo<OptionsGroup>(
    () => ({
      [monthLabel]: [{ value: '', label: dayLabel }],
      ...generateMonthsAndDatesGroups(monthNames),
    }),
    [dayLabel, monthNames, monthLabel],
  );

  // ────────────────────────────────────────────────────────────────────

  return (
    <View>
      {label ? (
        <BodyText size={5} style={styles.label}>
          {label}
        </BodyText>
      ) : null}

      {currentBreakpoint.isXS ? (
        <MobileControl
          value={value}
          monthLabel={monthLabel}
          dayLabel={dayLabel}
          optionsGroups={optionsGroups}
          accessibilityLabel={accessibilityLabel}
          onChange={onChange}
        />
      ) : (
        <DesktopControl
          value={value}
          monthLabel={monthLabel}
          dayLabel={dayLabel}
          optionsGroups={optionsGroups}
          accessibilityLabel={accessibilityLabel}
          onChange={onChange}
        />
      )}

      {helperText ? (
        <BodyText size={5} style={styles.helperText}>
          {helperText}
        </BodyText>
      ) : null}
    </View>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const MobileControl = (props: ControlProps) => {
  const {
    value = '',
    accessibilityLabel,
    monthLabel,
    dayLabel,
    optionsGroups,
    onChange,
  } = props;

  return (
    <PickersGroup
      variation="box"
      optionsGroups={optionsGroups}
      value={value}
      accessibilityLabel={accessibilityLabel}
      accessibilityLabelLeft={`${accessibilityLabel}, ${monthLabel}`}
      accessibilityLabelRight={`${accessibilityLabel}, ${dayLabel}`}
      onSubmit={onChange}
    />
  );
};

const DesktopControl = (props: ControlProps) => {
  const {
    value = '',
    accessibilityLabel,
    monthLabel,
    dayLabel,
    optionsGroups,
    onChange,
  } = props;

  // ────────────────────────────────────────────────────────────────────

  // extract month options from month and dates groups
  const monthsOptions = useMemo(
    () => Object.keys(optionsGroups).map((label) => ({ value: label, label })),
    [optionsGroups],
  );

  const [selectedMonthValue] = (value as string).split('/');
  const selectedMonthIndex = Number(selectedMonthValue || 0);
  const selectedMonthLabel = monthsOptions[selectedMonthIndex].label;
  const selectedMonthDates = optionsGroups[selectedMonthLabel];

  // ────────────────────────────────────────────────────────────────────

  const onMonthChange = useCallback(
    (label: string | number) => {
      const firstAvailableDate = optionsGroups[label][0];

      onChange(firstAvailableDate.value);
    },
    [onChange, optionsGroups],
  );

  // ────────────────────────────────────────────────────────────────────

  return (
    <HStack itemsPerRow={2}>
      <Select
        variation="box"
        value={selectedMonthLabel}
        options={monthsOptions}
        accessibilityLabel={`${accessibilityLabel}, ${monthLabel}`}
        onSelect={onMonthChange}
      />

      <Select
        variation="box"
        value={value}
        options={selectedMonthDates}
        accessibilityLabel={`${accessibilityLabel}, ${dayLabel}`}
        onSelect={onChange}
      />
    </HStack>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type BirthdayPickerProps = Readonly<{
  helperText?: string;
  label?: string;
  monthLabel?: string;
  dayLabel?: string;
  monthNames?: readonly string[];
  accessibilityLabel: string;
}> &
  Pick<ControlProps, 'value' | 'onChange'>;

type ControlProps = Readonly<{
  value: string | number;
  optionsGroups: OptionsGroup;
  accessibilityLabel: string;
  monthLabel: string;
  dayLabel: string;
  onChange: (value?: string | number) => void;
}>;

type OptionsGroup = Record<
  string,
  ReadonlyArray<Readonly<{ value: string; label: string }>>
>;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  label: {
    paddingBottom: theme.spacing['4'],
  },
  helperText: {
    paddingTop: theme.spacing['4'],
  },
});

// ─── UTILS ──────────────────────────────────────────────────────────────────────

export const generateMonthsAndDatesGroups = (
  monthNames: readonly string[],
): OptionsGroup => {
  return Object.fromEntries(
    monthNames.map((monthName, index) => [
      monthName,
      generateMonthDatesOptions(index),
    ]),
  );
};

export const generateMonthDatesOptions = (monthIndex: number) => {
  const monthMaxDate = getMonthLastDay(monthIndex);

  return Array.from({ length: monthMaxDate }).map((_, index) => {
    const month = `${monthIndex + 1}`;
    const monthValue = month.padStart(2, '0');
    const date = `${index + 1}`;
    const dateValue = date.padStart(2, '0');

    return { value: `${monthValue}/${dateValue}`, label: date };
  });
};

export const getMonthLastDay = (monthIndex: number) => {
  const leapYear = 1752;

  return new Date(leapYear, monthIndex + 1, 0).getDate();
};

export const generateMonthNamesList = () => {
  return Array.from({ length: 12 }).map((_, index) =>
    new Date(0, index).toLocaleString('en', { month: 'long' }),
  );
};

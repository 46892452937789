import { useCallback, useEffect } from 'react';
import { AccessibilityInfo, Platform } from 'react-native';

import { useTelemetry } from '@order/Telemetry';

/**
 * Tracking whether or not the screen reader is enabled.
 * For knowing if users ever used it, we only set it to true on the customer property, but don't unset it.
 * For knowing if events are happening while using it, we set it to either true or false on the super property.
 */
export function useAccessibilityTracking() {
  const { setCustomerProperty, setSuperProperty } = useTelemetry();

  const trackScreenReaderEnabledState = useCallback(
    (isScreenReaderEnabled: boolean) => {
      setSuperProperty('isScreenReaderEnabled', isScreenReaderEnabled);

      if (isScreenReaderEnabled) {
        setCustomerProperty('hasEverUsedScreenReader', isScreenReaderEnabled);
      }
    },
    [setSuperProperty, setCustomerProperty],
  );

  useEffect(() => {
    if (Platform.OS === 'web') return; // not supported on web.

    const screenReaderChangedSubscription = AccessibilityInfo.addEventListener(
      'screenReaderChanged',
      trackScreenReaderEnabledState,
    );

    return () => {
      screenReaderChangedSubscription.remove();
    };
  }, [trackScreenReaderEnabledState]);
}

import type { ComponentProps } from 'react';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { theme, TYPE_CONFIG } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';
import { useContainerSpacing } from '../../../Container';
import { IconLink } from '../../../Icon';
import { DisplayText } from '../../../Text';
import type { PaletteProps } from '../../Modal.types';

export const ModalHeader = (props: ModalHeaderProps) => {
  const {
    children,
    palette,
    accessibilityLabel,
    onClose,
    onGoBack,
    ...restProps
  } = props;

  const { match } = useResponsive();
  const containerSpacing = useContainerSpacing();

  // ─── Styles ──────────────────────────────────────────────────────────

  const paletteSpecificTextStyles = palette
    ? paletteHeadingTextStyles[palette]
    : undefined;
  const textStyles = [styles.fontSizeDisplay32, paletteSpecificTextStyles];
  const insetStyle = useTopInsetStyle();

  const headerDynamicStyles = useStyle(
    () => ({ paddingHorizontal: match([containerSpacing, headerPadding]) }),
    [containerSpacing],
  );
  const headerStyles = [styles.header, headerDynamicStyles, insetStyle];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={headerStyles}>
      {onGoBack ? (
        <IconLink
          name="IconArrowLeft"
          width={24}
          height={24}
          accessibilityRole="button"
          accessibilityLabel={accessibilityLabel}
          style={styles.closeButton}
          onPress={onGoBack}
        />
      ) : null}

      <DisplayText style={textStyles} {...restProps}>
        {children}
      </DisplayText>

      <IconLink
        name="IconClose"
        width={24}
        height={24}
        accessibilityRole="button"
        accessibilityLabel={accessibilityLabel}
        style={styles.closeButton}
        onPress={onClose}
      />
    </View>
  );
};

// ─── Hooks ───────────────────────────────────────────────────────────────────

/**
 * Android is missing the top inset spacing for the Modal headers.
 */
function useTopInsetStyle() {
  const { top: topInset } = useSafeAreaInsets();
  const insetStyle = useStyle(
    () => ({ paddingTop: headerPadding + topInset }),
    [headerPadding, topInset],
  );

  return Platform.OS === 'android' ? insetStyle : null;
}

// ─── Styles ────────────────────────────────────────────────────────────

const headerPadding = theme.spacing['6'];
const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: headerPadding,
  },
  headlineSecondary: {
    color: theme.colors.WHITE,
  },
  fontSizeDisplay32: {
    ...TYPE_CONFIG.DISPLAY['32'],
  },
  closeButton: {
    justifyContent: 'flex-start',
  },
});

// ─── Palette Styles ────────────────────────────────────────────────────

const paletteHeadingTextStyles = {
  primary: undefined,
  neutral: undefined,
  secondary: styles.headlineSecondary,
  lime: styles.headlineSecondary,
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ModalHeaderProps = Readonly<{
  onClose: (event?: unknown) => void;
  onGoBack?: (event?: unknown) => void;
}> &
  ComponentProps<typeof DisplayText> &
  PaletteProps;

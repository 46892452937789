/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrderCancelationStatusQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type OrderCancelationStatusQuery = {
  readonly __typename?: 'Query';
  readonly orderStatus:
    | { readonly __typename?: 'InvalidOrder' }
    | {
        readonly __typename: 'OrderStatus';
        readonly canceledStatus: string | null;
      }
    | null;
};

export const OrderCancelationStatusDocument = gql`
  query OrderCancelationStatus($id: ID!) {
    orderStatus(orderId: $id) {
      ... on OrderStatus {
        __typename
        canceledStatus
      }
    }
  }
`;

export function useOrderCancelationStatusQuery(
  options: Omit<
    Urql.UseQueryArgs<OrderCancelationStatusQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    OrderCancelationStatusQuery,
    OrderCancelationStatusQueryVariables
  >({ query: OrderCancelationStatusDocument, ...options });
}

import { getEnvVars } from '@order/utils';

export type BraintreeVault = 'order' | 'subscription';

export const getBraintreeTokenizationKey = (vault: BraintreeVault) => {
  const {
    BRAINTREE_TOKENIZATION_KEY,
    BRAINTREE_SUBSCRIPTION_TOKENIZATION_KEY,
  } = getEnvVars();

  return vault === 'subscription'
    ? BRAINTREE_SUBSCRIPTION_TOKENIZATION_KEY
    : BRAINTREE_TOKENIZATION_KEY;
};

import type { ReactElement } from 'react';
import React from 'react';
import { StyleSheet } from 'react-native';

import { BodyText as Text } from '../../Text';
import { useTextStyles } from '../styles';
import type {
  PaletteTypes,
  SizeTypes,
  TagAlertTextProps,
} from '../TagAlert.types';
import { TagAlertPalette, TagAlertSize } from '../TagAlert.types';

export const TextParent = (props: TagAlertTextProps): ReactElement => {
  const {
    palette = TagAlertPalette.success as PaletteTypes,
    size = TagAlertSize.medium as SizeTypes,
    children,
  } = props;

  const useStyleState = useTextStyles(palette, size);

  return <Text style={[styles.tagAlertText, useStyleState()]}>{children}</Text>;
};

const styles = StyleSheet.create({
  tagAlertText: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    position: 'relative',
  },
});

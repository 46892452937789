import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  ListActionCollapsible,
  theme,
  useNoticeBannersStackContext,
} from '@sg/garnish';

import { RedeemGiftCardForm } from '@order/components';
import { useLocalizationContext } from '@order/Localization';

import type { useCheckoutContext } from '../../../../CheckoutProvider';

export const CheckoutPaymentBlockGiftCardInput = (
  props: Pick<ReturnType<typeof useCheckoutContext>, 'refetchCredits'>,
) => {
  const { refetchCredits } = props;
  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  const handleRedeemSuccess = useCallback(() => {
    refetchCredits();
    addNoticeBanner({
      text: t('account.notice.credit-applied'),
      palette: 'success',
    });
  }, [addNoticeBanner, refetchCredits, t]);

  return (
    <ListActionCollapsible
      iconName="IconCreditCard"
      text={t('checkout.payment.gift-card.label')}
    >
      <View style={styles.giftCardWrapper}>
        <RedeemGiftCardForm
          shouldWrapInlineCta
          shouldDisplayCtaInline
          withHelperText={false}
          onRedeemSuccess={handleRedeemSuccess}
        />
      </View>
    </ListActionCollapsible>
  );
};

const styles = StyleSheet.create({
  giftCardWrapper: {
    paddingBottom: theme.spacing['4'],
  },
});

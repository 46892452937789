import type { ComponentProps } from 'react';
import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Container, HStack, theme, useResponsive } from '@sg/garnish';

import {
  FeaturedChannels,
  FloatingButtons,
  PageWrapper,
  ReorderRail,
  SweetpassUpsellCta,
} from '@order/components';
import { useFeatureFlag } from '@order/LaunchDarkly';

import type { useHomeData, useHomeScreenLoadedContent } from '../../hooks';
import {
  useHomeFeaturedChannelsNavigation,
  useHomeNavigation,
} from '../../hooks';
import { HomeScreenHero } from '../HomeScreenHero';
import { HomeScreenHeroCard } from '../HomeScreenHeroCard';
import { HomeScreenMobileHeader } from '../HomeScreenMobileHeader';
import { HomeScreenWelcomePane } from '../HomeScreenWelcomePane';

export const HomeScreenContent = memo((props: HomeScreenContentProps) => {
  const {
    orders,
    status,
    deliveryRestaurant,
    deliveryVendor,
    cartOrderType,
    isCartEmpty,
    isLoggedIn,
    isLoading,
    isLoadingOrders,
    isLoadingStatus,
    isLoadingInitialOrderStatus,
    hasErrors,
    isLastOrderActive,
    loadedContent,
    inStoreOrderPendingFeedbackId,
    inStoreOrderPendingFeedbackWantedTime,
    onPressHeroCTA,
  } = props;

  const { currentBreakpoint, match } = useResponsive();
  const gridColumnsNumber = currentBreakpoint.isXS ? 1 : 2;
  const gridGap = match([0, theme.spacing['6'], theme.spacing['10']]);
  const { handleGoToDefaultLocations } = useHomeNavigation();
  const onFeaturedChannelSelection = useHomeFeaturedChannelsNavigation();

  const isSweetpassUpsellEnabled = useFeatureFlag(
    'CELS-683-sweetpass-upsell-in-homescreen-enabled',
  );
  const shouldShowSweetpassUpsell = !isLoggedIn && isSweetpassUpsellEnabled;

  const isShortHeroCardEnabled = useFeatureFlag(
    'CELS-953-short-homescreen-action-card-enabled',
  );

  // ─── STYLES ─────────────────────────────────────────────────────────

  const containerStyles = [
    styles.containerWithGutters,
    match([styles.containerXS, styles.containerSM]),
  ];

  return (
    <>
      <PageWrapper
        testID="home.container"
        style={styles.wrapper}
        contentContainerStyle={styles.scrollContainer}
      >
        <SafeAreaView edges={['top']} style={styles.containerSafeAreaWrapper}>
          <View style={containerStyles}>
            <Container>
              {currentBreakpoint.isXS ? <HomeScreenMobileHeader /> : null}

              <HStack itemsPerRow={gridColumnsNumber} gap={gridGap}>
                <View style={styles.column}>
                  {currentBreakpoint.isXS ? (
                    <HomeScreenWelcomePane
                      isLoading={isLoading}
                      isLoadingStatus={isLoadingStatus}
                      hasCart={!isCartEmpty}
                      cartOrderType={cartOrderType}
                      data={status}
                      inStoreOrderPendingFeedbackId={
                        inStoreOrderPendingFeedbackId
                      }
                      inStoreOrderPendingFeedbackWantedTime={
                        inStoreOrderPendingFeedbackWantedTime
                      }
                      seasonalHeroHeadlineText={loadedContent.heroHeadlineText}
                    />
                  ) : null}
                  {isShortHeroCardEnabled ? (
                    <HomeScreenHeroCard
                      isLoadingMostRecentOrderStatus={
                        isLoadingInitialOrderStatus
                      }
                      hasActiveOrder={Boolean(isCartEmpty && isLastOrderActive)}
                    />
                  ) : (
                    <HomeScreenHero
                      isLoading={isLoading}
                      ctaText={loadedContent.heroCTA?.text}
                      imageURL={loadedContent.heroImageURL}
                      onPressCTA={onPressHeroCTA}
                    />
                  )}
                </View>

                <View style={styles.column}>
                  {!currentBreakpoint.isXS && (
                    <HomeScreenWelcomePane
                      isLoading={isLoading}
                      isLoadingStatus={isLoadingStatus}
                      hasCart={!isCartEmpty}
                      cartOrderType={cartOrderType}
                      data={status}
                      inStoreOrderPendingFeedbackId={
                        inStoreOrderPendingFeedbackId
                      }
                      inStoreOrderPendingFeedbackWantedTime={
                        inStoreOrderPendingFeedbackWantedTime
                      }
                      seasonalHeroHeadlineText={loadedContent.heroHeadlineText}
                    />
                  )}
                  {shouldShowSweetpassUpsell ? (
                    <View
                      style={match([
                        styles.upsellContainer,
                        styles.upsellContainer,
                        styles.upsellContainerMD,
                      ])}
                    >
                      <SweetpassUpsellCta
                        telemetryPayload={
                          SWEETPASS_UPSELL_CTA_TELEMETRY_PAYLOAD
                        }
                      />
                    </View>
                  ) : null}
                  <FeaturedChannels
                    isLoadingOrders={isLoadingOrders}
                    deliveryRestaurant={deliveryRestaurant}
                    deliveryVendor={deliveryVendor}
                    onFeaturedChannelSelection={onFeaturedChannelSelection}
                    onNavigateToLocations={handleGoToDefaultLocations}
                  />
                </View>
              </HStack>
            </Container>
          </View>

          <View>
            <ReorderRail
              isLoading={isLoadingOrders}
              orders={orders}
              hasError={hasErrors}
            />
          </View>
        </SafeAreaView>
      </PageWrapper>

      <FloatingButtons.Container>
        <FloatingButtons.Bag />
      </FloatingButtons.Container>
    </>
  );
});

// ─── Constants ───────────────────────────────────────────────────────────────

const SWEETPASS_UPSELL_CTA_TELEMETRY_PAYLOAD: SweetpassUpsellCtaTelemetryPayload =
  { ctaLocation: 'Home' };

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  scrollContainer: {
    flexGrow: 1,
  },
  wrapper: {
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  containerWithGutters: {
    flexGrow: 1,
    marginBottom: 'auto',
  },
  containerXS: {
    paddingTop: theme.spacing['4'],
    paddingBottom: theme.spacing['6'],
  },
  containerSM: {
    paddingVertical: theme.spacing['10'],
  },
  containerSafeAreaWrapper: {
    flex: 1,
  },
  upsellContainer: {
    marginTop: theme.spacing['6'],
  },
  upsellContainerMD: {
    marginTop: theme.spacing['10'],
  },
  column: {
    flexGrow: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoadedContent = ReturnType<typeof useHomeScreenLoadedContent>;

type HomeScreenContentProps = ReturnType<typeof useHomeData> &
  Readonly<{
    loadedContent: LoadedContent;
    onPressHeroCTA: () => void;
  }>;

type SweetpassUpsellCtaTelemetryPayload = ComponentProps<
  typeof SweetpassUpsellCta
>['telemetryPayload'];

import type { NotificationClickEvent } from 'react-native-onesignal';

import {
  ORDER_STATUS_NOTIFICATION_ACTION_KEY,
  ORDER_STATUS_NOTIFICATION_ACTION_NAME,
  ORDER_STATUS_NOTIFICATION_ID_KEY,
} from '../../useOrderStatusPushNotifications.constants';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A small utility that returns order ID from the provided notification data
 * object if it has order status properties.
 *
 * It checks for the `action` (with the value of `order_status`) and
 * `order_id` properties, which are utilized in One Signal order status
 * push notifications.
 */
export function getOrderIdFromNotification(
  orderStatusNotification: Pick<
    NotificationClickEvent['notification'],
    'additionalData'
  >,
): string | undefined {
  const additionalData = orderStatusNotification.additionalData ?? {};
  const maybeNotificationWithOrderStatusData =
    additionalData as Partial<OrderStatusDataObject>;

  const action =
    maybeNotificationWithOrderStatusData[ORDER_STATUS_NOTIFICATION_ACTION_KEY];
  const orderId =
    maybeNotificationWithOrderStatusData[ORDER_STATUS_NOTIFICATION_ID_KEY];

  const hasValidAction = action === ORDER_STATUS_NOTIFICATION_ACTION_NAME;
  const hasValidOrderId = orderId !== undefined;

  if (!hasValidAction || !hasValidOrderId) return;

  return orderId;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderStatusDataObject = Readonly<{
  [ORDER_STATUS_NOTIFICATION_ACTION_KEY]: typeof ORDER_STATUS_NOTIFICATION_ACTION_NAME;
  [ORDER_STATUS_NOTIFICATION_ID_KEY]: string; // numeric value
}>;

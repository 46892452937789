import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { theme } from '@garnish/constants';

export const OffersScreen = (): React.ReactElement => (
  <View style={styles.container}>
    <Text style={styles.title}>Offers screen</Text>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    paddingBottom: theme.spacing['10'],
  },
});

import React, { useCallback } from 'react';
import { View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';

// ────────────────────────────────────────────────────────────────────────────────

const HorizontalScrollRailItemSeparator = (
  props: HorizontalScrollRailItemSeparatorProps,
) => {
  const { width = 16 } = props;
  const style = useStyle(() => ({ width }), [width]);

  return <View style={style} />;
};

export const useRailItemSeparator = (gap: number) => {
  return useCallback(
    () => <HorizontalScrollRailItemSeparator width={gap} />,
    [gap],
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type HorizontalScrollRailItemSeparatorProps = Readonly<{
  width: number;
}>;

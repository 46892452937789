import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DisplayText } from '@sg/garnish';

import { orderAgainRailHeadingMessages as messages } from './OrderAgainRailHeading.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainRailHeading = () => {
  return (
    <DisplayText bold sizeMatch={['32']}>
      <FormattedMessage {...messages.heading} />
    </DisplayText>
  );
};

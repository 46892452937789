/* istanbul ignore file */

import AsyncStorage from '@react-native-async-storage/async-storage';
import { addSeconds } from 'date-fns';
import { maxTime } from 'date-fns/constants';

import {
  getLocationWarningAsyncStorageKey,
  stringifyLocationWarningExpirationDate,
} from '../../services/location-warning/location-warning.helpers';

// ─────────────────────────────────────────────────────────────────────────────

const { setItem } = AsyncStorage;

/**
 * Stores location warning expiration information in the application's storage for future reference.
 */
export function setLocationWarningStorageRecord(
  location: MaybeLocationWithWarning,
) {
  if (!location) return;

  const { id, warningDialogTimeout: warningDialogTimeoutInSeconds = 0 } =
    location;

  const asyncStorageKey = getLocationWarningAsyncStorageKey(id);

  const shouldAlwaysShowLocationWarning = warningDialogTimeoutInSeconds === 0;
  const shouldShowWarningOnlyOnce = warningDialogTimeoutInSeconds === -1;

  // if location warning should be shown every time, there is no need to store a record
  if (shouldAlwaysShowLocationWarning) return;

  // if location warning should be shown once, we create a record with the maximum possible date
  if (shouldShowWarningOnlyOnce) {
    void setItem(
      asyncStorageKey,
      stringifyLocationWarningExpirationDate(new Date(maxTime)),
    );

    return;
  }

  // otherwise we set the expiration date based on the provided timeout
  void setItem(
    asyncStorageKey,
    stringifyLocationWarningExpirationDate(
      addSeconds(new Date(), warningDialogTimeoutInSeconds),
    ),
  );
}

// ─── Types ───────────────────────────────────────────────────────────────────

type MaybeLocationWithWarning =
  | Readonly<{
      id: string;
      showWarningDialog?: boolean;
      warningDialogTitle?: string;
      warningDialogTimeout?: number;
    }>
  | undefined;

import React from 'react';
import { useResponsive } from '@sg/garnish';

import { useCustomer } from '@order/Customer';
import { useLocalizationContext } from '@order/Localization';

import { AddressForm, useAddressHandlers } from '../Address';
import { Modal } from '../Modal';

export const AddressEditModal = (props: AddressEditModalProps) => {
  const { addressId, visible, onRequestClose } = props;

  const { t } = useLocalizationContext();
  const { match } = useResponsive();
  const { customer } = useCustomer();

  const { addresses } = customer;
  const address = addresses?.find((item) => item.id === addressId);

  const {
    isUpdatingAddress,
    isDeletingAddress,
    handleOnSave,
    handleOnEdit,
    handleOnDelete,
  } = useAddressHandlers({
    editingAddressId: addressId,
    checkExistingBag: true,
    onClose: onRequestClose,
  });

  return (
    <Modal
      fitHeight
      visible={visible}
      accessibilityLabel={t('addresses.headline.edit')}
      presentationStyle="pageSheet"
      size={match(['full', 'small'])}
      onRequestClose={onRequestClose}
    >
      <Modal.Header onClose={onRequestClose}>
        {t('addresses.headline.edit')}
      </Modal.Header>

      <AddressForm
        visible
        editing
        address={address}
        loading={isUpdatingAddress}
        deleting={isDeletingAddress}
        editingAddressId={addressId}
        submitCta={t('addresses.save')}
        onSave={handleOnSave}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
      />
    </Modal>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type AddressEditModalProps = Readonly<{
  addressId: string;
  visible: boolean;
  onRequestClose: () => void;
}>;

import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { NumberField } from '../../NumberField';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Wrapper for the NumberField component.
 * Adds a background color with some padding.
 * Removes the bottom border and the floating label.
 */
export const NumberFieldWithBackground = (
  props: ComponentProps<typeof NumberField>,
) => {
  return (
    <NumberField
      wrapperStyle={[styles.wrapper, props.wrapperStyle]}
      style={[styles.field, props.style]}
      {...props}
    />
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    borderRadius: theme.radius.medium,
    paddingVertical: theme.spacing['4'],
    paddingHorizontal: theme.spacing['3'],
    borderBottomWidth: 0,
  },
  field: {
    height: 'auto',
  },
});

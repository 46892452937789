import type { ComponentProps } from 'react';
import React from 'react';
import { TextField } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

// TextField wrapper for auto complete configuration.
export const AddressSearchField = (props: AddressSearchFieldProps) => {
  const { value, isRequired, shouldFocusInput, hasResults } = props;
  const { t } = useLocalizationContext();

  // ──── Invalid address notice ───────────────────────────────
  const hasTypedValidAddress = (value ?? '').length > 3;
  const isInvalidAddress = hasTypedValidAddress && !hasResults;
  const invalidNotice = t('location.input-invalid-address-notice');

  return (
    <TextField
      autoFocus={shouldFocusInput}
      hideSubmitButton
      testID="address-search-autocomplete"
      autoComplete="street-address"
      clearButtonType="text"
      label={t('location.delivery-search-placeholder')}
      clearBtnLabel={t('general.clear')}
      clearBtnAccessibilityHint={t('general.clear-a11y-hint')}
      submitBtnAccessibilityLabel={t('general.submit')}
      submitAccessibilityHint={t('general.submit-a11y-hint')}
      invalid={isRequired ? isInvalidAddress : undefined}
      notice={isInvalidAddress ? invalidNotice : ''}
      {...props}
    />
  );
};

// ──── Types ──────────────────────────────────────────────────

type AddressSearchFieldProps = Readonly<{
  isRequired?: boolean;
  shouldFocusInput?: boolean;
  hasResults?: boolean;
}> &
  ComponentProps<typeof TextField>;

import React from 'react';
import { PixelRatio, StyleSheet, View } from 'react-native';
import {
  BoundScrollView,
  DisplayText,
  HStack,
  JumpTarget,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { useLargeDesktopBreakpoint } from '../../../hooks';
import { useProductDetailsScreenContext } from '../../../ProductDetailsScreen.provider';
import type { FilteredIngredientModificationsKinds } from '../../../types';
import { IngredientModificationCard } from '../../IngredientModificationCard';
import { ProductModificationsContainer } from './ProductModificationsContainer';

// ────────────────────────────────────────────────────────────────────────────────

export const ProductModificationsIngredientsGrid = (
  props: ProductModificationsIngredientsGridProps,
) => {
  const { ingredientsModificationsKinds } = props;
  const {
    product,
    addIngredientModification,
    removeIngredientModification,
    activeIngredientsModificationsMap,
    stale,
  } = useProductDetailsScreenContext();

  // ────────────────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const { match } = useResponsive();
  const isLargeDesktop = useLargeDesktopBreakpoint();
  const deviceFontScale = PixelRatio.getFontScale();

  const itemsPerRowXS = deviceFontScale >= 1.1 ? 2 : 3;
  const itemsPerRow = isLargeDesktop ? 4 : match([itemsPerRowXS, 4, 3, 5]);

  // ────────────────────────────────────────────────────────────────────

  if (!product) return null;

  return (
    <BoundScrollView style={styles.wrapper}>
      <ProductModificationsContainer>
        {ingredientsModificationsKinds.map((kind) => {
          const label = t(`pdp.modifications.${kind}`);

          const ingredientModificationKind =
            product?.ingredientModifications[kind] ?? [];

          return (
            <JumpTarget key={kind} id={kind}>
              <View
                style={styles.section}
                testID={`modifications-ingredient-category-${kind}`}
              >
                <DisplayText size={4} style={styles.sectionTitle}>
                  {label}
                </DisplayText>

                <HStack itemsPerRow={itemsPerRow} gap={theme.spacing['4']}>
                  {ingredientModificationKind.modifications.map(
                    (ingredientModification) => {
                      const { ingredient, outOfStock } = ingredientModification;
                      const isSelected = activeIngredientsModificationsMap.has(
                        ingredient.id,
                      );

                      return (
                        <IngredientModificationCard
                          key={ingredient.id}
                          // @ts-expect-error TS(2322): Type '{ readonly __typename?: "IngredientModificat... Remove this comment to see the full error message
                          ingredientModification={ingredientModification}
                          isDisabled={stale}
                          isUnavailable={outOfStock}
                          isSelected={isSelected}
                          onPress={addIngredientModification}
                          onDecreaseQuantity={removeIngredientModification}
                        />
                      );
                    },
                  )}
                </HStack>
              </View>
            </JumpTarget>
          );
        })}
      </ProductModificationsContainer>
    </BoundScrollView>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
  },
  section: {
    paddingVertical: theme.spacing['10'],
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.LIGHT,
  },
  sectionTitle: {
    paddingBottom: theme.spacing['4'],
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ProductModificationsIngredientsGridProps = Readonly<{
  ingredientsModificationsKinds: FilteredIngredientModificationsKinds;
}>;

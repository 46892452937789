import { ORDER_AUTH_BROADCAST_CHANNEL } from './authBroadcastChannel.constants';

/**
 * We use the Broadcast Web API to communicate between browser tabs (log in, log out, etc.),
 * when the current auth status changes.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/BroadcastChannel
 */
export function initAuthBroadcastChannel() {
  return new BroadcastChannel(ORDER_AUTH_BROADCAST_CHANNEL);
}

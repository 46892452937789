import { Platform } from 'react-native';

import { useFeatureFlag } from '@order/LaunchDarkly';

export const useIsApplePaySupported = () => {
  const isApplePayEnabled = useFeatureFlag('permanent-apple-pay-enabled');

  if (!isApplePayEnabled) return false;

  return Platform.select({
    ios: true,
    android: false,
    web: 'ApplePaySession' in window,
  });
};

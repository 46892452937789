import React from 'react';
import { StyleSheet } from 'react-native';
import { H2 } from '@expo/html-elements';
import { DisplayText, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardRedemptionBalance = (
  props: GiftCardRedemptionBalanceProps,
) => {
  return (
    <H2 style={styles.heading}>
      <DisplayText
        style={styles.headingText}
        bold
        sizeMatch={['24']}
        maxFontSizeMultiplier={1.2}
      >
        {props.children}
      </DisplayText>
    </H2>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  heading: {
    textAlign: 'center',

    // NOTE: Resets default styles

    display: 'flex',
    justifyContent: 'center',
    marginVertical: 0,
  },
  headingText: {
    color: theme.colors.KALE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardRedemptionBalanceProps = {
  children: string;
};

import React, { useCallback } from 'react';

import type { CardType } from '@order/graphql';
import { useCreditCardForm } from '@order/hooks';
import { useTelemetry } from '@order/Telemetry';

import { CreditCardFormControlledContent } from './CreditCardFormControlledContent';

export const CreditCardFormEdit = (props: FormProps) => {
  const {
    paymentMethodId,
    paymentMethodType,
    cardType,
    description,
    nickname,
    isDefault,
    isSaving,
    isDeleting,
    excludedFields = [],
    onEdit,
    onDelete,
  } = props;

  // ─── Controlled Form State ───────────────────────────────────────────

  const { form, formData, updateForm } = useCreditCardForm({
    nickname,
    default: isDefault,
  });

  // ─── Submission Results ──────────────────────────────────────────────

  const { track } = useTelemetry();

  const submitForm = useCallback(() => {
    const formValues = form.getValues();

    track('payment_credit.save_card');
    onEdit({
      id: paymentMethodId,
      nickname: formValues.nickname,
      isDefault: formValues.default,
      paymentMethodType,
    });
  }, [paymentMethodId, paymentMethodType, form, track, onEdit]);

  const deleteCreditCard = useCallback(() => {
    track('payment_credit.remove_card');
    onDelete();
  }, [track, onDelete]);

  return (
    <CreditCardFormControlledContent
      mode="edit"
      description={description}
      cardType={cardType}
      isSaving={Boolean(isSaving)}
      isDeleting={isDeleting}
      canSubmitForm={form.formState.isDirty}
      excludedFields={excludedFields}
      form={formData}
      onFormChange={updateForm}
      onSubmitForm={submitForm}
      onDelete={deleteCreditCard}
    />
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type FormProps = Readonly<{
  paymentMethodId: string;
  paymentMethodType: 'PAYMENT_METHOD_CARD' | 'BILLING_ACCOUNT';
  cardType?: CardType;
  nickname?: string;
  description?: string;
  isDefault?: boolean;
  isSaving: boolean;
  isDeleting: boolean;
  excludedFields?: ReadonlyArray<'nickname' | 'isDefault'>;
  onEdit: (form: CreditCardForm) => void;
  onDelete: () => void;
}>;

type CreditCardForm = Readonly<{
  id: string;
  paymentMethodType: 'PAYMENT_METHOD_CARD' | 'BILLING_ACCOUNT';
  nickname: string;
  isDefault: boolean;
}>;

import React from 'react';
import type { ViewProps } from 'react-native';
import { Platform, StyleSheet, View } from 'react-native';
import type { IconName } from '@sg/garnish';
import { BodyText, Icon, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { CreditCardFormLineItem } from './CreditCardFormLineItem';

export const CreditCardFormBlockedFields = (props: BlockedFieldsProps) => {
  if (!props.show) return null;

  return (
    <>
      <CardNumber {...props} />
      <CreditCardFormLineItem>
        <View style={styles.row}>
          <ExpirationDate />
          <CVV />
        </View>
      </CreditCardFormLineItem>
      <PostalCode />
    </>
  );
};

const CardNumber = ({
  description,
  cardType,
}: Pick<BlockedFieldsProps, 'description' | 'cardType'>) => {
  const { t } = useLocalizationContext();

  return (
    <CreditCardFormLineItem>
      <BodyText style={styles.blockedLabel} size={4}>
        {t('credit-card-form.card-number')}
      </BodyText>
      <DashedBorderFrame>
        <View style={[styles.row, styles.center, styles.blocked]}>
          <View style={styles.icon}>
            <Icon name={cardType} />
          </View>
          <BodyText style={styles.blockedLabel}>
            {getHiddenCardNumber(description ?? '')}
          </BodyText>
        </View>
      </DashedBorderFrame>
    </CreditCardFormLineItem>
  );
};

const ExpirationDate = () => {
  const { t } = useLocalizationContext();

  return (
    <View style={[styles.column, styles.expirationDate]}>
      <BodyText style={styles.blockedLabel} size={4}>
        {t('credit-card-form.expiration-date')}
      </BodyText>
      <DashedBorderFrame>
        <BodyText style={styles.blocked}>••/••</BodyText>
      </DashedBorderFrame>
    </View>
  );
};

const CVV = () => {
  const { t } = useLocalizationContext();

  return (
    <View style={[styles.column, styles.cvv]}>
      <BodyText style={styles.blockedLabel} size={4}>
        {t('credit-card-form.cvv')}
      </BodyText>
      <DashedBorderFrame>
        <BodyText style={styles.blocked}>•••</BodyText>
      </DashedBorderFrame>
    </View>
  );
};

const PostalCode = () => {
  const { t } = useLocalizationContext();

  return (
    <CreditCardFormLineItem>
      <View style={[styles.row, styles.center, styles.postalCode]}>
        <View style={styles.column}>
          <BodyText style={styles.blockedLabel} size={4}>
            {t('credit-card-form.zip-code')}
          </BodyText>
          <DashedBorderFrame>
            <BodyText style={styles.blocked}>•••••</BodyText>
          </DashedBorderFrame>
        </View>
      </View>
    </CreditCardFormLineItem>
  );
};

// https://github.com/facebook/react-native/issues/24224#issuecomment-849496947
const DashedBorderFrame = ({ children }: ViewProps) => {
  if (Platform.OS === 'web') {
    return <View style={styles.webBorder}>{children}</View>;
  }

  return (
    <View style={styles.borderWrapper}>
      <View style={styles.border}>{children}</View>
    </View>
  );
};

const getHiddenCardNumber = (description: string) => {
  const number = description.slice(Math.max(0, description.length - 4));

  return `•••• •••• •••• ${number || '••••'}`;
};

type BlockedFieldsProps = Readonly<{
  show: boolean;
  description?: string;
  cardType: IconName;
}>;

const styles = StyleSheet.create({
  row: {
    color: theme.colors.CHARCOAL,
    flexDirection: 'row',
  },
  center: {
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing['2'],
  },
  column: {
    flex: 1,
  },
  blockedLabel: {
    color: theme.colors.CHARCOAL,
  },
  blocked: {
    color: theme.colors.CHARCOAL,
    paddingVertical: theme.spacing['2'],
  },
  borderWrapper: {
    overflow: 'hidden',
  },
  border: {
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: theme.colors.CHARCOAL,
    margin: -2,
    marginBottom: 0,
  },
  webBorder: {
    borderStyle: 'dashed',
    borderBottomWidth: 1,
    borderColor: theme.colors.CHARCOAL,
    margin: -2,
    marginBottom: 0,
  },
  expirationDate: {
    marginRight: theme.spacing['3'],
  },
  cvv: {
    marginLeft: theme.spacing['3'],
  },
  postalCode: {
    marginBottom: theme.spacing['2'],
  },
});

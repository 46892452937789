import { type ElementRef, type MutableRefObject } from 'react';
import { type HostComponent, type ScrollView, type View } from 'react-native';

/**
 * An asynchronous function to measure provided targets based on parent scroll view
 * and current scroll offset.
 */
export async function getTargetsMeasurements(
  params: GetTargetMeasurementsParams,
): Promise<TargetsMeasurements | null> {
  const { scrollView, scrollOffset, targets } = params;

  if (!scrollView) return null;

  const targetRefs = Object.values(targets);
  const measureScrollViewTarget = measureTarget({ scrollView, scrollOffset });

  const measurePromises = targetRefs.map(measureScrollViewTarget);
  const resolvedPromises = await Promise.all(measurePromises);

  const targetKeys = Object.keys(targets);

  return targetKeys.reduce((allMeasurements, id, index) => {
    return { ...allMeasurements, [id]: resolvedPromises[index] };
  }, {});
}

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns a promise with measurements of provided target.
 */
function measureTarget(params: MeasureTargetParams) {
  const { scrollView, scrollOffset } = params;

  return async (ref: TargetRef) => {
    const targetRef = ref as MutableRefObject<View>;
    const targetParent =
      (scrollView?.current as unknown as TargetParentRef) || null;

    return new Promise<TargetMeasurements>((resolve, reject) => {
      const hasValidElements =
        targetParent && typeof targetRef.current?.measureLayout === 'function';

      if (!hasValidElements) {
        reject();
      }

      targetRef.current.measureLayout(
        targetParent,
        (x, y, width, height) => {
          const { x: xOffset, y: yOffset } = scrollOffset;

          const xWithOffset = x + xOffset;
          const yWithOffset = y + yOffset;

          resolve({ x: xWithOffset, y: yWithOffset, width, height });
        },
        reject,
      );
    });
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type GetTargetMeasurementsParams = {
  scrollView: ScrollViewRef | null;
  scrollOffset: { x: number; y: number };
  targets: Record<string, TargetRef>;
};

type MeasureTargetParams = {
  scrollView: NonNullable<ScrollViewRef>;
  scrollOffset: { x: number; y: number };
};

type TargetRef = MutableRefObject<unknown>;

type TargetsMeasurements = Record<string, TargetMeasurements>;

type TargetMeasurements = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type TargetParentRef = ElementRef<HostComponent<unknown>>;

type ScrollViewRef = MutableRefObject<ScrollView | null>;

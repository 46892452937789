import React, { type ReactNode } from 'react';
import { TransparentModal, useResponsive } from '@sg/garnish';

/**
 * Component that renders either a drawer or a modal depending on viewport.
 */
export const BagDynamicModal = (props: BagDynamicModalProps) => {
  const { children, onClose } = props;

  const { match } = useResponsive();

  return (
    <TransparentModal
      size={match(['full', 'drawer'])}
      mobileEdges={['right', 'left']}
      onClose={onClose}
    >
      {children}
    </TransparentModal>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type BagDynamicModalProps = {
  children: ReactNode;
  onClose: () => void;
};

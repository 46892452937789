import { useIsLoggedIn } from '@order/AuthMachine';
import { useContentfulContentTypeEntry } from '@order/Contentful';
import { useSweetpassTier } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

export const useSweetpassUpsellCtaContentType = (
  params: UseSweetpassUpsellCtaContentTypeParams,
) => {
  const { contentTag = 'sweetpassUpsellCtaDefault', contentFallback } = params;

  const { t } = useLocalizationContext();
  const { sweetpassTierName } = useSweetpassTier();

  const isLoggedIn = useIsLoggedIn();
  const isUpgradableTier = sweetpassTierName === 'SWEETPASS';

  const shouldPauseQuery = isLoggedIn && !isUpgradableTier;

  // ─────────────────────────────────────────────

  const fallbackValues: SweetpassUpsellCtaContentType = contentFallback ?? {
    text: t('sweetpass.upsell.cta.fallback.text'),
    textHighlights: [t('sweetpass.upsell.cta.fallback.text-highlight')],
    actionText: t('sweetpass.upsell.cta.fallback.action-text'),
    iconText: t('sweetpass.upsell.cta.fallback.icon-text'),
    accessibilityLabel: t('sweetpass.upsell.cta.fallback.a11y-label'),
  };

  const { data, fetching } =
    useContentfulContentTypeEntry<SweetpassUpsellCtaContentType>({
      contentType: 'sweetpassUpsellCta',
      tags: [contentTag],
      pause: shouldPauseQuery,
    });

  const fields = data?.fields ?? fallbackValues;

  // ─────────────────────────────────────────────

  return { fields, fetching };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseSweetpassUpsellCtaContentTypeParams = Readonly<{
  contentTag?: SweetpassUpsellCtaContentTypeTag;
  contentFallback?: SweetpassUpsellCtaContentType;
}>;

type SweetpassUpsellCtaContentType = Readonly<{
  text: string;
  textHighlights?: readonly string[];
  actionText?: string;
  iconText?: string;
  accessibilityLabel?: string;
  accessibilityHint?: string;
}>;

type SweetpassUpsellCtaContentTypeTag =
  | 'sweetpassUpsellCtaDefault'
  | 'sweetpassUpsellCtaActiveOrder'
  | 'sweetpassUpsellCtaOrderStatus';

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoyaltyProfileDataFragment = {
  readonly __typename?: 'LoyaltyProfile';
  readonly customerId: string;
  readonly joinDate: string;
  readonly hasPaidTierHistory: boolean;
  readonly tierStatus: {
    readonly __typename?: 'TierStatus';
    readonly id: string;
    readonly name: Types.TierStatusName;
  } | null;
  readonly trialEligible: ReadonlyArray<{
    readonly __typename?: 'TrialEligibility';
    readonly duration: number;
    readonly durationUnit: Types.DurationUnit;
  }> | null;
  readonly tier: {
    readonly __typename?: 'Tier';
    readonly id: string;
    readonly name: Types.TierName;
    readonly nextTier: {
      readonly __typename?: 'Tier';
      readonly id: string;
      readonly name: Types.TierName;
    } | null;
  } | null;
};

export type SubscriptionCreditCardFragmentFragment = {
  readonly __typename?: 'SubscriptionCreditCard';
  readonly id: string;
  readonly cardType: Types.BraintreeCardType;
  readonly lastFour: string;
  readonly expirationMonth: string | null;
  readonly expirationYear: string | null;
  readonly isActive: boolean;
};

export type PaymentSubscriptionProfileDataFragment = {
  readonly __typename?: 'PaymentSubscriptionProfile';
  readonly id: string;
  readonly creditCards: ReadonlyArray<{
    readonly __typename?: 'SubscriptionCreditCard';
    readonly expirationState: Types.CardExpirationState;
    readonly id: string;
    readonly cardType: Types.BraintreeCardType;
    readonly lastFour: string;
    readonly expirationMonth: string | null;
    readonly expirationYear: string | null;
    readonly isActive: boolean;
  }> | null;
};

export type SweetpassMembershipQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SweetpassMembershipQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyProfile: {
    readonly __typename?: 'LoyaltyProfile';
    readonly customerId: string;
    readonly joinDate: string;
    readonly hasPaidTierHistory: boolean;
    readonly tierStatus: {
      readonly __typename?: 'TierStatus';
      readonly id: string;
      readonly name: Types.TierStatusName;
    } | null;
    readonly trialEligible: ReadonlyArray<{
      readonly __typename?: 'TrialEligibility';
      readonly duration: number;
      readonly durationUnit: Types.DurationUnit;
    }> | null;
    readonly tier: {
      readonly __typename?: 'Tier';
      readonly id: string;
      readonly name: Types.TierName;
      readonly nextTier: {
        readonly __typename?: 'Tier';
        readonly id: string;
        readonly name: Types.TierName;
      } | null;
    } | null;
  } | null;
  readonly getSubscriptions: ReadonlyArray<{
    readonly __typename?: 'PaymentSubscription';
    readonly id: string;
    readonly planId: string;
    readonly status: Types.PaymentSubscriptionStatus;
    readonly paidThroughDate: string | null;
    readonly nextBillingDate: string;
    readonly nextBillingPeriodAmount: string;
    readonly firstBillingDate: string | null;
    readonly billingFrequencyUnit: Types.DurationUnit | null;
    readonly price: string | null;
  }>;
  readonly paymentSubscriptionProfile: {
    readonly __typename?: 'PaymentSubscriptionProfile';
    readonly id: string;
    readonly creditCards: ReadonlyArray<{
      readonly __typename?: 'SubscriptionCreditCard';
      readonly expirationState: Types.CardExpirationState;
      readonly id: string;
      readonly cardType: Types.BraintreeCardType;
      readonly lastFour: string;
      readonly expirationMonth: string | null;
      readonly expirationYear: string | null;
      readonly isActive: boolean;
    }> | null;
  } | null;
};

export const LoyaltyProfileDataFragmentDoc = gql`
  fragment LoyaltyProfileData on LoyaltyProfile {
    customerId
    joinDate
    hasPaidTierHistory
    tierStatus {
      id
      name
    }
    trialEligible {
      duration
      durationUnit
    }
    tier {
      id
      name
      nextTier {
        id
        name
      }
    }
  }
`;
export const SubscriptionCreditCardFragmentFragmentDoc = gql`
  fragment SubscriptionCreditCardFragment on SubscriptionCreditCard {
    id
    cardType
    lastFour
    expirationMonth
    expirationYear
    isActive
  }
`;
export const PaymentSubscriptionProfileDataFragmentDoc = gql`
  fragment PaymentSubscriptionProfileData on PaymentSubscriptionProfile {
    id
    creditCards {
      ...SubscriptionCreditCardFragment
      expirationState
    }
  }
  ${SubscriptionCreditCardFragmentFragmentDoc}
`;
export const SweetpassMembershipDocument = gql`
  query SweetpassMembership {
    loyaltyProfile {
      ...LoyaltyProfileData
    }
    getSubscriptions(status: [ACTIVE, PENDING, PAST_DUE]) {
      id
      planId
      status
      paidThroughDate
      nextBillingDate
      nextBillingPeriodAmount
      firstBillingDate
      billingFrequencyUnit
      price
    }
    paymentSubscriptionProfile {
      ...PaymentSubscriptionProfileData
    }
  }
  ${LoyaltyProfileDataFragmentDoc}
  ${PaymentSubscriptionProfileDataFragmentDoc}
`;

export function useSweetpassMembershipQuery(
  options?: Omit<Urql.UseQueryArgs<SweetpassMembershipQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    SweetpassMembershipQuery,
    SweetpassMembershipQueryVariables
  >({ query: SweetpassMembershipDocument, ...options });
}

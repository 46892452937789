import React, { useCallback } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import {
  Button,
  ModalCloseBtn,
  theme,
  useNoticeBannersStackContext,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import {
  DietaryRestrictions,
  useDietaryRestrictionsMachine,
} from '../../components';
import { TransparentModal } from '../../components/TransparentModal';
import { useNavigateBack } from '../../navigation';

export const DietaryRestrictionsScreen = () => {
  const { t } = useLocalizationContext();
  const { minWidth, currentBreakpoint } = useResponsive();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { isModified, confirmRestrictions, revertRestrictions } =
    useDietaryRestrictionsMachine();

  const handleClose = useNavigateBack();

  const confirmDietaryRestrictions = useCallback(() => {
    confirmRestrictions();
    handleClose();

    addNoticeBanner({
      text: t('dietary-restrictions.successful-change'),
      palette: 'success',
    });
  }, [addNoticeBanner, confirmRestrictions, handleClose, t]);

  // revert unconfirmed dietary restrictions on screen blur
  useFocusEffect(useCallback(() => revertRestrictions, [revertRestrictions]));

  // ─────────────────────────────────────────────────────────────────
  return (
    <TransparentModal
      testID="dietary-restrictions"
      size="large"
      onClose={handleClose}
    >
      {currentBreakpoint.isXS ? null : <ModalCloseBtn onPress={handleClose} />}

      <ScrollView
        style={minWidth.isSM ? styles.containerSM : undefined}
        contentContainerStyle={styles.grow}
      >
        <View style={styles.wrapper}>
          <DietaryRestrictions
            shouldHideIllustration={currentBreakpoint.isXS}
          />
        </View>

        <View style={styles.ctaWrapper}>
          <Button
            testID="dietary.restrictions.confirm"
            onPress={confirmDietaryRestrictions}
            size="large"
            style={styles.cta}
            // @ts-expect-error TS(2322): Type '{ children: string; testID: string; onPress:... Remove this comment to see the full error message
            focusOutlineColor={theme.colors.GRAY}
            disabled={!isModified}
          >
            {t('general.confirm')}
          </Button>
        </View>
      </ScrollView>
    </TransparentModal>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  grow: {
    flexGrow: 1,
    alignItems: 'center',
  },
  containerSM: {
    padding: theme.spacing['4'],
  },
  wrapper: {
    padding: theme.spacing['4'],
  },
  ctaWrapper: {
    paddingTop: theme.spacing['6'],
    paddingBottom: theme.spacing['4'],
  },
  cta: {
    minWidth: 207,
  },
});

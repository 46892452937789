import { defineMessages, useIntl } from 'react-intl';
import { capitalizeFirstLetter } from '@sg/garnish';

/**
 * The subtitle of the header will change if the customer has less points than the first points reward.
 */
export const useDynamicHeaderSubtitle = (
  props: UseDynamicHeaderSubtitleProps,
) => {
  const { availablePoints, benefits, defaultSubtitle } = props;
  const { formatMessage } = useIntl();

  const firstPointsBenefit = benefits.find(
    (reward) => (reward.points ?? 0) > 0,
  );

  // Only needed for when the customer has points benefits.
  if (!firstPointsBenefit) return defaultSubtitle;

  const pointsNeededForFirstBenefit = firstPointsBenefit.points ?? 0;
  const canAffordFirstBenefit = availablePoints >= pointsNeededForFirstBenefit;

  // Only needed for when the customer cannot afford the first benefit.
  if (canAffordFirstBenefit) return defaultSubtitle;

  const points = pointsNeededForFirstBenefit - availablePoints;
  const name = firstPointsBenefit.title
    .split(' ')
    .map(capitalizeFirstLetter)
    .join(' ');

  return formatMessage(messages.dynamicSubtitle, {
    points,
    name,
  });
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  dynamicSubtitle: {
    defaultMessage: "You're {points} pts from a Free {name}!",
    description: 'Loyalty | Bag | Header | Dynamic subtitle',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type UseDynamicHeaderSubtitleProps = {
  availablePoints: number;
  defaultSubtitle: string;
  benefits: Benefit[];
};

type Benefit = {
  points: number;
  title: string;
};

export const CONTENTFUL_TAGS = {
  Tier: {
    OptedOut: 'OPTED_OUT',
    Sweetpass: 'SWEETPASS',
    SweetpassPlus: 'SWEETPASS_PLUS',
    SweetpassPlusFreeTrial: 'SWEETPASS_PLUS_FREE_TRIAL',
    AllTiers: 'ALL_TIERS',
  },
  Status: {
    Cancelled: 'CANCELLED_STATUS',
    Downgraded: 'DOWNGRADED_STATUS',
  },
};

export const SWEETPASS_BRAZE_CUSTOM_EVENTS = {
  UserNavigatedToSweetpassHome: 'user_navigated_to_sweetpass_home',
  UserNavigatedToSweetpassPlusHome: 'user_navigated_to_sweetpass_plus_home',
  UserCancelledSweetpassSubscription: 'user_cancelled_sweetpass_subscription',
  UserCancelledSweetpassPlusSubscription:
    'user_cancelled_sweetpass_plus_subscription',
};

import React from 'react';

import { useLocalizationContext } from '@order/Localization';

import {
  AccountScreenContainer,
  AccountScreenSection,
  AccountScreenTitle,
} from '../AccountScreenContainer';
import {
  SkeletonLoadingContentLG,
  SkeletonLoadingContentSM,
  SkeletonLoadingHeaderXS,
} from './AccountSkeletonLoading';

export const AccountSweetpassMembershipLoading = () => {
  const { t } = useLocalizationContext();

  return (
    <AccountScreenContainer screen="sweetpass-membership">
      <AccountScreenTitle
        visibility="COMPACT"
        title={t('account.sweetpass-membership.title')}
      />

      <AccountScreenSection>
        {/* Membership Level */}
        <SkeletonLoadingHeaderXS />
        <SkeletonLoadingContentSM />

        {/* Cancellation */}
        <SkeletonLoadingHeaderXS />
        <SkeletonLoadingContentLG />

        {/* Payment Methods */}
        <SkeletonLoadingHeaderXS />
        <SkeletonLoadingContentSM />
        <SkeletonLoadingContentSM />

        {/* Billing History */}
        <SkeletonLoadingHeaderXS />
        <SkeletonLoadingContentSM />
      </AccountScreenSection>
    </AccountScreenContainer>
  );
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import {
  Button,
  DisplayText,
  IllusEmployeeBag,
  Image,
  theme,
} from '@sg/garnish';

/**
 * Empty state for the bag.
 */
export const BagEmptyState = (props: BagEmptyStateProps) => {
  return (
    <View style={styles.container}>
      <Image
        source={IllusEmployeeBag}
        style={styles.illustration}
        contentFit="contain"
      />

      <DisplayText sizeMatch={['32']} style={styles.message}>
        <FormattedMessage
          defaultMessage="Fill up your bag with the good stuff"
          description="Bag | Empty State | Message"
        />
      </DisplayText>

      <Button onPress={props.onViewMenu}>
        <FormattedMessage
          defaultMessage="View menu"
          description="Bag | Empty State | CTA"
        />
      </Button>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: theme.spacing['6'],
    paddingHorizontal: theme.spacing['12'],
    alignItems: 'center',
    justifyContent: 'center',
  },
  illustration: {
    width: 242,
    height: 242,
  },
  message: {
    textAlign: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BagEmptyStateProps = {
  onViewMenu: () => void;
};

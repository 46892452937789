import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, ListActionCollapsible, TextField, theme } from '@sg/garnish';

import { usePromoCode } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import type { useCheckoutContext } from '../../../../CheckoutProvider';

export const CheckoutPaymentBlockPromoCodeInput = (
  props: Pick<ReturnType<typeof useCheckoutContext>, 'refetchCredits'>,
) => {
  const { refetchCredits } = props;

  const { t } = useLocalizationContext();

  const {
    promoCode,
    isSubmittingPromoCode,
    promoCodeInvalid: hasPromoCodeError,
    isPromoCodeApplied,
    setPromoCode,
    submitPromoCode: onPromoCodeSubmit,
  } = usePromoCode(refetchCredits);

  const shouldDisableApplyBtn = promoCode.trim() === '';
  const promoCodeNotice = getPromoCodeNotice({
    t,
    hasPromoCodeError,
    isPromoCodeApplied,
  });

  return (
    <ListActionCollapsible
      iconName="IconPromo"
      text={t('checkout.payment.promo-code.label')}
    >
      <View style={styles.promoCodeWrapper}>
        <TextField
          placeholder={t('checkout.payment.promo-code.placeholder')}
          accessibilityLabel={t('checkout.payment.promo-code.placeholder')}
          noticePalette="caution"
          value={promoCode}
          notice={promoCodeNotice}
          invalid={hasPromoCodeError}
          onChangeText={setPromoCode}
        />

        <View style={styles.applyButtonWrapper}>
          <Button
            accessibilityLabel={t('checkout.payment.promo-code.apply')}
            palette="primary"
            size="small"
            style={styles.applyButton}
            disabled={shouldDisableApplyBtn}
            isLoading={isSubmittingPromoCode}
            onPress={onPromoCodeSubmit}
          >
            {t('general.apply')}
          </Button>
        </View>
      </View>
    </ListActionCollapsible>
  );
};

function getPromoCodeNotice(props: PromoCodeNoticeProps) {
  const { t, isPromoCodeApplied, hasPromoCodeError } = props;

  if (isPromoCodeApplied) {
    return t('account.notice.credit-already-applied');
  }

  if (hasPromoCodeError) {
    return t('account.notice.credit-invalid');
  }
}

type PromoCodeNoticeProps = Readonly<{
  t: ReturnType<typeof useLocalizationContext>['t'];
  isPromoCodeApplied: boolean;
  hasPromoCodeError: boolean;
}>;

const styles = StyleSheet.create({
  promoCodeWrapper: {
    paddingVertical: theme.spacing['4'],
  },
  applyButtonWrapper: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing['2'],
  },
  applyButton: {
    minWidth: 123,
  },
});

import { StyleSheet, type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const paletteStyles = StyleSheet.create<AlertContainerPaletteStyles>({
  info: {
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTEST,
  },
  success: {
    backgroundColor: theme.colors.CUCUMBER,
  },
  caution: {
    backgroundColor: theme.colors.PEACH,
  },
});

// ─────────────────────────────────────────────────────────────────────────────

export type AlertContainerPalette = 'success' | 'info' | 'caution';

type AlertContainerPaletteStyles = Record<AlertContainerPalette, ViewStyle>;

import React from 'react';
import type { ViewProps } from 'react-native';
import { Pressable, StyleSheet, View } from 'react-native';
import type { IconName } from '@sg/garnish';
import { Icon, ListAction, theme, usePressableState } from '@sg/garnish';

export type AddressListItemProps = Readonly<{
  iconName: IconName;
  title?: string | null;
  subtitle?: string | null;
  onPress: () => void;
}> &
  ViewProps;

export const AddressListItem = ({
  testID,
  iconName,
  title,
  subtitle,
  onPress,
  accessibilityLabel,
  accessibilityHint,
}: AddressListItemProps) => {
  const ref = React.useRef(null);
  const { isInteracting } = usePressableState(ref);

  return (
    <Pressable
      testID={testID}
      accessibilityRole="link"
      accessibilityLabel={accessibilityLabel}
      accessibilityHint={accessibilityHint}
      ref={ref}
      onPress={onPress}
    >
      <ListAction.Wrapper>
        <View style={styles.container}>
          <ListAction.Icon iconName={iconName} />
          <View style={styles.content}>
            <ListAction.Title underline={isInteracting}>
              {title}
            </ListAction.Title>
            <ListAction.Text>{subtitle}</ListAction.Text>
          </View>
          <Icon name="IconCaretRight" width={18} height={18} />
        </View>
      </ListAction.Wrapper>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    paddingVertical: theme.spacing['2'],
  },
  content: {
    flex: 1,
  },
});

/* eslint-disable react-native/no-unused-styles */

import React from 'react';
import { StyleSheet, type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';

import type { TabItems } from '../../Tabs.types';
import { Tab } from '../Tab';
import { TabBarContainer } from '../TabBarContainer';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders the controlled "tablist" component along with the relevant "tab"
 * components to change between tab panels.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tablist_role ARIA: tablist role}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role ARIA: tab role}
 */
export const TabBar = (props: TabBarProps) => {
  const { tabs, activeTabId, onChange, palette = 'primary' } = props;

  const containerStyles = containerPaletteStyles[palette];

  return (
    <TabBarContainer style={containerStyles}>
      {tabs.map((tab) => {
        const {
          id,
          panelId,
          label,
          'aria-label': ariaLabel,
          isDisabled,
          isFluid,
        } = tab;

        return (
          <Tab
            key={id}
            id={id}
            panelId={panelId}
            label={label}
            isActive={activeTabId === id}
            aria-label={ariaLabel}
            isDisabled={isDisabled}
            isFluid={isFluid}
            onPress={onChange}
            palette={palette}
          />
        );
      })}
    </TabBarContainer>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const containerPaletteStyles = StyleSheet.create<
  Record<TabBarPalette, ViewStyle>
>({
  primary: {
    borderBottomColor: theme.colors.GRAY,
  },
  premium: {
    borderBottomColor: theme.colors.GREEN_2,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type TabBarProps = Readonly<{
  tabs: TabItems;
  activeTabId: string;
  onChange: (value: string) => void;
  palette: 'primary' | 'premium';
}>;

type TabBarPalette = 'primary' | 'premium';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const FeatureFlagsClearButton = (
  props: FeatureFlagToggleButtonProps,
) => {
  const { onPress, isDisabled } = props;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Button palette="caution" onPress={onPress} disabled={isDisabled}>
      <FormattedMessage
        defaultMessage="Clear feature flag overrides"
        description="Clear feature flag overrides CTA"
      />
    </Button>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type FeatureFlagToggleButtonProps = {
  onPress: () => void;
  isDisabled: boolean;
};

import { useCallback, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { HAS_OPENED_DIETARY_PREFERENCES_MODAL_STORAGE_KEY } from './DietaryRestrictions.constants';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A small hook that provides state helpers to check whether the user has already
 * opened the dietary preferences modal to show or hide the related toggle.
 */
export const useDietaryPreferencesState = () => {
  //
  // ─── State ───────────────────────────────────────────────────────────

  const [isResolving, setIsResolving] = useState(true);
  const [hasOpenedDietaryPreferences, setHasOpenedDietaryPreferences] =
    useState<string | null>(null);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const getHasOpenedDietaryPreferencesState = useCallback(async () => {
    const value = await AsyncStorage.getItem(
      HAS_OPENED_DIETARY_PREFERENCES_MODAL_STORAGE_KEY,
    );

    setHasOpenedDietaryPreferences(value);
    setIsResolving(false);
  }, []);

  const setHasOpenedDietaryPreferencesState = useCallback(async () => {
    await AsyncStorage.setItem(
      HAS_OPENED_DIETARY_PREFERENCES_MODAL_STORAGE_KEY,
      'true',
    );

    setHasOpenedDietaryPreferences('true');
  }, []);

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    void getHasOpenedDietaryPreferencesState();
  }, [getHasOpenedDietaryPreferencesState]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    isResolvingDietaryPreferencesState: isResolving,
    hasOpenedDietaryPreferences: hasOpenedDietaryPreferences === 'true',
    setHasOpenedDietaryPreferencesState,
  };
};

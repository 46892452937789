import React, { type PropsWithChildren } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainConflictReviewContainer = (props: PropsWithChildren) => {
  return (
    <SafeAreaView edges={['top', 'bottom']} style={styles.outerContainer}>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        {props.children}
      </ScrollView>
    </SafeAreaView>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  contentContainer: {
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['6'],
  },
});

import type { TextStyle } from 'react-native';
import { RESPONSIVE_TYPE_SIZE_MAP, TYPE_CONFIG } from '@garnish/constants';

import { useFluidSize } from '../../../hooks';

export const useFluidTextStyles = (size: string, kind: TextKind) => {
  const fluid = useFluidSize();

  const [min, max] = getStylePresets(size, kind);

  const fluidStyles = {
    fontSize: fluid(min.fontSize, max.fontSize),
    lineHeight: fluid(min.lineHeight, max.lineHeight),
    letterSpacing: fluid(min.letterSpacing, max.letterSpacing),
  };

  return fluidStyles;
};

//
// ─── HELPERS ─────────────────────────────────────────────────────────────────
//

export const getStylePresets = (size: string, kind: TextKind) => {
  const [sizeKeyMin, sizeKeyMax] = getResponsiveSizeRange(size);
  const TextKindConfig = TYPE_CONFIG[kind];
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const min = TextKindConfig[sizeKeyMin];
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const max = TextKindConfig[sizeKeyMax];

  return min
    ? [min as FluidTextProps, max as FluidTextProps]
    : [max as FluidTextProps, max as FluidTextProps];
};

export const getResponsiveSizeRange = (size: string) => {
  const breakpointSizingArray = RESPONSIVE_TYPE_SIZE_MAP[size as ValidSizes];

  return [breakpointSizingArray[0], breakpointSizingArray[3]];
};

//
// ─── TYPES ───────────────────────────────────────────────────────────────────
//

type FluidTextProps = Required<
  Pick<TextStyle, 'lineHeight' | 'fontSize' | 'letterSpacing'>
>;
type TextKind = keyof typeof TYPE_CONFIG;

type ValidSizes = keyof typeof RESPONSIVE_TYPE_SIZE_MAP;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, HStack, Modal, theme, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const LocationWarningModal = ({
  locations,
  restaurantSlug,
  onClose,
  onConfirm,
}: LocationWarningModalProps) => {
  const location = locations.find((el) => el.restaurantSlug === restaurantSlug);

  return (
    <Modal animationType="fade" size="medium" visible onRequestClose={onClose}>
      <LocationWarningContent
        locationWarningTitle={location?.warningDialogTitle ?? ''}
        locationWarningDescription={location?.warningDialogDescription ?? ''}
        restaurantSlug={restaurantSlug}
        restaurantId={location?.id ?? ''}
        restaurantName={location?.name ?? ''}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    </Modal>
  );
};

export const LocationWarningContent = ({
  locationWarningTitle,
  locationWarningDescription,
  restaurantSlug,
  restaurantId,
  restaurantName,
  onClose,
  onConfirm,
}: LocationWarningContentProps) => {
  const { t } = useLocalizationContext();
  const { currentBreakpoint, match } = useResponsive();

  const onChangeLocation = React.useCallback(() => {
    onClose();
  }, [onClose]);

  const handleConfirm = React.useCallback(() => {
    if (!onConfirm || !restaurantSlug) return;
    onConfirm({
      restaurantId: restaurantId ?? '',
      restaurantSlug: restaurantSlug ?? '',
      restaurantName: restaurantName ?? '',
    });
  }, [onConfirm, restaurantSlug, restaurantId, restaurantName]);

  // ─── Styles ──────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    match([undefined, styles.containerSM]),
  ];
  const infoBlockStyles = match([styles.infoXS, styles.infoSM]);
  const buttonSize = match(['medium', 'large'] as const);
  const buttonStyles = match([undefined, styles.buttonSM]);

  // ─────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles} testID="location-warning-modal">
      <View style={infoBlockStyles}>
        <Modal.Headline>{locationWarningTitle}</Modal.Headline>
        <Modal.BodyText>{locationWarningDescription}</Modal.BodyText>
      </View>

      <Modal.Footer withoutTopBorder>
        <HStack
          style={styles.buttonsStack}
          itemsPerRow={currentBreakpoint.isXS ? 2 : undefined}
        >
          <Button
            style={buttonStyles}
            size={buttonSize}
            palette="secondary"
            onPress={onChangeLocation}
            accessibilityLabel={t('location.warning-modal.cta-change')}
            accessibilityHint={t('location.warning-modal.cta-change')}
          >
            {t('location.warning-modal.cta-change')}
          </Button>

          <Button
            style={buttonStyles}
            size={buttonSize}
            onPress={handleConfirm}
            accessibilityLabel={t('location.warning-modal.cta-confirm')}
            accessibilityHint={t('location.warning-modal.cta-confirm')}
            testID="location-warning-modal.confirm"
          >
            {t('location.warning-modal.cta-confirm')}
          </Button>
        </HStack>
      </Modal.Footer>
    </View>
  );
};

type LocationWarningModalProps = Readonly<{
  locations: ReadonlyArray<
    Partial<
      Readonly<{
        id: string;
        name: string;
        restaurantSlug: string;
        warningDialogTitle?: string;
        warningDialogDescription?: string;
      }>
    >
  >;
  restaurantSlug: string;
  onClose: () => void;
  onConfirm: (props: {
    restaurantId: string;
    restaurantSlug: string;
    restaurantName: string;
  }) => void;
}>;

type LocationWarningContentProps = Readonly<{
  locationWarningTitle: string;
  locationWarningDescription: string;
  restaurantSlug: string;
  restaurantId: string;
  restaurantName: string;
  onClose: () => void;
  onConfirm: (props: {
    restaurantId: string;
    restaurantSlug: string;
    restaurantName: string;
  }) => void;
}>;

const styles = StyleSheet.create({
  container: {
    paddingTop: theme.spacing['2'],
    paddingBottom: theme.spacing['2'],
  },
  containerSM: {
    paddingTop: theme.spacing['6'],
  },
  infoXS: {
    marginTop: theme.spacing['4'],
  },
  infoSM: {
    marginVertical: theme.spacing['4'],
  },
  buttonsStack: {
    justifyContent: 'center',
  },
  buttonSM: {
    minWidth: 187,
    paddingHorizontal: theme.spacing['2'],
  },
});

import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

type Props = Readonly<{
  children: ComponentProps<typeof View>['children'];
  style?: ComponentProps<typeof View>['style'];
}>;

export const Card = ({ children, style }: Props) => {
  return <View style={[styles.card, style]}>{children}</View>;
};

const styles = StyleSheet.create({
  card: {
    padding: theme.spacing['10'],
    backgroundColor: theme.colors.APP_BACKGROUND,
    borderRadius: theme.radius.large,
    flexGrow: 1,
    maxWidth: 500,
  },
});

import { useMemo } from 'react';
import debounce from 'lodash.debounce';

/**
 * Returns a memoized debounced function that delays invoking callback until
 * the provided interval in milliseconds has elapsed.
 *
 * @param callback: Function that needs to be invoked after the interval
 * @param wait: Interval in milliseconds
 */
export const useDebounceFn = (
  callback: (...args: readonly any[]) => unknown,
  wait = 300,
) => {
  return useMemo(() => debounce(callback, wait), [callback, wait]);
};

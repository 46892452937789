import type { CoordinatePair, Point } from './useFluidSize.types';
import type { LockRange } from './useFluidSize.types';
import { DEFAULT_LOCK_RANGE } from './useFluidSize.types';

type GetLockRange = Readonly<{
  [x: number]: string;
  min: number;
  max: number;
}>;

export const getSlope = (p1: Point, p2: Point): number =>
  (p2.y - p1.y) / (p2.x - p1.x);

export const getIntercept = (point: Point, slope: number): number =>
  point.y - slope * point.x;

export const makeQuadraticFn = (slope: number) => {
  return (intercept: number) => {
    return (x: number): number => {
      const val = slope * x + intercept;

      return Math.round(val);
    };
  };
};

export const getPointSlopeFn = (
  coordinatePair: CoordinatePair,
): ((x: number) => number) => {
  const { p1, p2 } = coordinatePair;
  const slope = getSlope(p1, p2);
  const intercept = getIntercept(p1, slope);

  return makeQuadraticFn(slope)(intercept);
};

export const makeLockedWidthFn = (lockRange: LockRange) => {
  return (viewWidth: number): number => {
    if (viewWidth <= lockRange.min) return lockRange.min;

    if (viewWidth > lockRange.max) return lockRange.max;

    return viewWidth;
  };
};

export const makeCoordinatePairFn =
  (lockRange: LockRange) =>
  (sizeMin: number, sizeMax: number): CoordinatePair => {
    return {
      p1: { x: lockRange.min, y: sizeMin },
      p2: { x: lockRange.max, y: sizeMax },
    };
  };

export const getLockRange = (userConfig?: LockRange): GetLockRange => ({
  ...DEFAULT_LOCK_RANGE,
  ...userConfig,
});

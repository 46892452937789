import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { requestTrackingPermission } from 'react-native-tracking-transparency';
import { useCallbackIfMounted } from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';

/**
 * Asks customer to approve data tracking.
 * This should be used to prevent data tracking in the app.
 * @returns boolean indicating approval.
 * - true: "approved" if the feature is not supported or the customer approves it.
 * - false: "denied" if it is supported and the customer doesn't approve it.
 */
export const useAppTrackingTransparency = () => {
  const isEnabled = useIsAppTrackingTransparencyEnabled();
  const [isTrackingAllowed, setTrackingAllowed] = useState(false);

  const updateTrackingStatus = useCallbackIfMounted((allowed: boolean) => {
    setTrackingAllowed(allowed);
  });

  useEffect(() => {
    (async () => {
      if (!isEnabled) return;

      const trackingStatus = await requestTrackingPermission();

      updateTrackingStatus(trackingStatus === 'authorized');
    })();
  }, [isEnabled, updateTrackingStatus]);

  return !isEnabled || isTrackingAllowed;
};

const useIsAppTrackingTransparencyEnabled = () => {
  const isFeatureEnabled = useFeatureFlag(
    'permanent-app-tracking-transparency-enabled',
  );
  const isIos = Platform.OS === 'ios';

  return isIos && isFeatureEnabled;
};

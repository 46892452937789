import type { RefObject } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import type { ScrollView, View } from 'react-native';
import { measureLayoutPosition } from '@sg/garnish';

/**
 * Returns helpers to control locations results scrolling
 */
export const useLocationResultsScrolling = (
  props: UseLocationResultsScrollingProps,
) => {
  const { activePinLocationId = '', isExpanded, locationSearchType } = props;

  // ─── State ───────────────────────────────────────────────────────────

  // NOTE: To support the "scroll to list item" functionality, we save location result cards scroll positions.
  const listItemsPositionsRef = useRef(new Map<string, number>());
  const listScrollViewRef = useRef<ScrollView>(null);

  // ─── Scroll Positions ────────────────────────────────────────────────

  const setListItemScrollPosition = useCallback(
    async (listItemRef: RefObject<View>, listItemId: string) => {
      const { current: listScrollView } = listScrollViewRef;
      const { current: listItemsPositions } = listItemsPositionsRef;

      if (!listScrollView) return;

      const { y: listItemScrollY } = await measureLayoutPosition(
        listScrollViewRef,
        listItemRef,
      );

      listItemsPositions.set(listItemId, listItemScrollY);
    },
    [],
  );

  const deleteListItemScrollPosition = useCallback((listItemId: string) => {
    const { current: listItemsPositions } = listItemsPositionsRef;

    listItemsPositions.delete(listItemId);
  }, []);

  const getActiveListItemPosition = useCallback(() => {
    const { current: listItemsPositions } = listItemsPositionsRef;

    return listItemsPositions.get(activePinLocationId) ?? 0;
  }, [activePinLocationId]);

  // ─── `ScrollView` helpers ────────────────────────────────────────────

  const scrollToTop = useCallback((animated = true) => {
    const { current: listScrollView } = listScrollViewRef;

    listScrollView?.scrollTo({ y: 0, animated });
  }, []);

  const scrollToActiveListItem = useCallback(() => {
    const { current: listScrollView } = listScrollViewRef;
    const listItemScrollY = getActiveListItemPosition();

    listScrollView?.scrollTo({ y: listItemScrollY, animated: true });
  }, [getActiveListItemPosition]);

  // ─── Effects ─────────────────────────────────────────────────────────

  // Scroll to the top, on location search type change.
  useEffect(() => {
    if (!locationSearchType) return;

    scrollToTop();
  }, [locationSearchType, scrollToTop]);

  // Reset the scroll position on the expand/collapse state of the results.
  useEffect(() => {
    if (!isExpanded) return;

    scrollToActiveListItem();

    return scrollToActiveListItem;
  }, [isExpanded, scrollToActiveListItem]);

  // Scroll to the active location card, on active pin change.
  useEffect(() => {
    if (!activePinLocationId) return;

    scrollToActiveListItem();

    return () => {
      scrollToTop(false);
    };
  }, [activePinLocationId, scrollToActiveListItem, scrollToTop]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    setListItemScrollPosition,
    deleteListItemScrollPosition,
    listScrollViewRef,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseLocationResultsScrollingProps = Readonly<{
  activePinLocationId: string | undefined;
  isExpanded: boolean;
  locationSearchType?: string;
}>;

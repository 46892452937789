import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardContentContainer = (props: PropsWithChildren) => {
  return <View style={styles.container}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    rowGap: theme.spacing['2'],
    alignItems: 'flex-start',
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['4'],
  },
});

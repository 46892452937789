import { Platform } from 'react-native';

/**
 * Returns cross-platform location address link
 */
export function getLocationAddressGeolocationLink(address: string) {
  return Platform.select({
    ios: `https://maps.apple.com/?daddr=${address}`,
    default: `https://www.google.com/maps/dir/?api=1&destination=${address}`,
  });
}

import type { ReactElement } from 'react';
import React from 'react';
import { Animated, StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { Dot } from './subcomponents/Dot';
import { useDotAnimations } from './useDotAnimations';

type LoadingDotsProps = Readonly<{
  color?: string;
}>;

export const LoadingDots = (props: LoadingDotsProps): ReactElement => {
  const animations = useDotAnimations();
  const color = props.color ?? theme.colors.DARK_KALE;

  return (
    <Animated.View style={[styles.loading, {}]}>
      {animations.map((animation, index) => {
        const animatedStyle = { transform: [{ translateY: animation }] };

        return (
          <Animated.View
            nativeID={getDotIndex(index)}
            key={getDotIndex(index)}
            style={animatedStyle}
            testID={`dot.${color}.${index}`}
          >
            <Dot color={color} />
          </Animated.View>
        );
      })}
    </Animated.View>
  );
};

const getDotIndex = (index: number): string => `dot-anim-${index}`;

const styles = StyleSheet.create({
  loading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
});

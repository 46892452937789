import { useCallback, useMemo, useRef } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import { useLocalizationContext } from '@order/Localization';
import { telemetryLocationFromInput, useTelemetry } from '@order/Telemetry';

import type { RestaurantQuery } from './GraphQL/Restaurant.query.generated';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * Checks if the selected restaurant/store is not accepting orders and why.
 */
export const useRestaurantDisabledCheck = (
  restaurantData: RestaurantQuery['restaurant'] | undefined,
) => {
  const { t } = useLocalizationContext();

  const restaurantName = restaurantData?.name ?? '';

  const isRestaurantDisabled = !restaurantData?.isAcceptingOrders;
  const notAcceptingOrdersReason =
    restaurantData?.notAcceptingOrdersReason ?? t('error.store.unavailable');
  const disabledRestaurantNotice = `${
    restaurantName ? `(${restaurantName})` : ''
  } ${notAcceptingOrdersReason}`.trim();

  return useMemo(
    () => ({
      isRestaurantDisabled,
      disabledRestaurantNotice,
    }),
    [isRestaurantDisabled, disabledRestaurantNotice],
  );
};

// ────────────────────────────────────────────────────────────────────────────────

export const useMenuScreenMountTelemetry = (
  restaurantData: Parameters<typeof telemetryLocationFromInput>[0],
) => {
  const { track } = useTelemetry();
  const restaurantDataRef =
    useRef<Parameters<typeof telemetryLocationFromInput>[0]>();

  // eslint-disable-next-line functional/immutable-data
  restaurantDataRef.current = restaurantData;

  useFocusEffect(
    useCallback(() => {
      track('menu.view', {
        restaurant: telemetryLocationFromInput(restaurantDataRef.current),
      });
    }, [track]),
  );
};

import type { ContextFrom } from 'xstate';

import type { AuthModel } from '../../AuthMachine.model';
import { BROADCAST_EVENTS } from './broadcastEvent.constants';

/*
  We use the Broadcast Web API to communicate between browser tabs (log in, log out, etc.),
  when the current auth status changes.

  Documentation: https://developer.mozilla.org/en-US/docs/Web/API/BroadcastChannel
 */

// ─── Send Event Actions  ────────────────────────────────────────────────────────

export function broadcastLogInEvent(context: AuthContext) {
  const { authBroadcastChannel } = context;

  authBroadcastChannel.postMessage(BROADCAST_EVENTS.LOG_IN);
}

export function broadcastLogOutEvent(context: AuthContext) {
  const { authBroadcastChannel } = context;

  authBroadcastChannel.postMessage(BROADCAST_EVENTS.LOG_OUT);
}

// ─── Event Listeners ────────────────────────────────────────────────────────────

export function addBroadcastEventsListener(context: AuthContext) {
  const { authBroadcastChannel } = context;

  authBroadcastChannel.addEventListener('message', onAuthBroadcastEvent);
}

export function removeBroadcastEventsListener(context: AuthContext) {
  const { authBroadcastChannel } = context;

  authBroadcastChannel.removeEventListener('message', onAuthBroadcastEvent);
}

// ─── Event Handlers ─────────────────────────────────────────────────────────────

function onAuthBroadcastEvent(event: BroadcastMessageEvent) {
  const { data: eventName } = event;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (!checkIsAuthEvent(eventName)) return;

  handleAuthChange();
}

function handleAuthChange() {
  window.location.reload();
}

// ────────────────────────────────────────────────────────────────────────────────

function checkIsAuthEvent(eventName: string) {
  return Object.values(BROADCAST_EVENTS).includes(eventName);
}

// ─── Types ──────────────────────────────────────────────────────────────────────

type AuthContext = ContextFrom<typeof AuthModel>;

type BroadcastMessageEvent = BroadcastChannelEventMap['message'];

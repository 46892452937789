import { useIsLoggedIn } from '@order/AuthMachine';
import { DurationUnit, useFetchPlansByTierIdQuery } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { useSweetpassTier } from '../useSweetpassTier';
import { useSubscriptionPlanByIdQuery } from './graphql/Plan.generated';

/**
 * Returns the planId and planPrice.
 * Accepts an optional {requestedPlanId} to specify which plan.
 * Otherwise defaults to the plan for the next available tier.
 */
export function useSweetpassPlan(props?: UseSweetpassPlanProps) {
  const { requestedTierId, requestedPlanId, pause } = props ?? {};

  const { t } = useLocalizationContext();

  const isLoggedIn = useIsLoggedIn();
  const { sweetpassNextTierId } = useSweetpassTier();

  const nextTierId = requestedTierId ?? sweetpassNextTierId;

  const [plansByTierId] = useFetchPlansByTierIdQuery({
    variables: { tierId: nextTierId ?? '' },
    pause:
      Boolean(pause) || !nextTierId || Boolean(requestedPlanId) || !isLoggedIn,
  });

  const planIdToRequest =
    requestedPlanId ??
    plansByTierId.data?.fetchPlansByTierId?.[0]?.braintreePlanId ??
    '';

  const [planResponse] = useSubscriptionPlanByIdQuery({
    variables: { planId: planIdToRequest },
    pause: Boolean(pause) || !planIdToRequest || !isLoggedIn,
  });
  const plan = planResponse.data?.subscriptionPlanById;

  const planId = plan?.id;
  const planPrice = Number(plan?.price);
  const billingFrequencyUnit = plan?.billingFrequencyUnit ?? DurationUnit.Month;
  const billingFrequencyMembershipName = t(
    'sweetpass.checkout.billing-frequency.membership-plan-name',
    { billingFrequency: billingFrequencyUnit.toLowerCase() },
  );
  const hasFreeTrial = Boolean(plan?.hasFreeTrial);

  const isLoadingPlan = plansByTierId?.fetching || planResponse?.fetching;
  const hasUnknownPlanError = Boolean(
    plansByTierId?.error ?? planResponse?.error,
  );

  return {
    planId,
    planPrice,
    billingFrequencyUnit,
    billingFrequencyMembershipName,
    hasFreeTrial,
    isLoadingPlan,
    hasUnknownPlanError,
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type UseSweetpassPlanProps = {
  requestedTierId?: string;
  requestedPlanId?: string;
  pause?: boolean;
};

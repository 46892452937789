import React from 'react';
import { Controller } from 'react-hook-form';

import { Switch as GarnishSwitch } from '../Switch';
import type { ControlledProps, FormProps } from './types';

type ComponentProps = React.ComponentProps<typeof GarnishSwitch>;
type OmittedProps = ControlledProps;
type Props = FormProps & Omit<ComponentProps, OmittedProps>;

export const Switch = ({ name, control, rules, ...rest }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <GarnishSwitch
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          {...rest}
        />
      )}
    />
  );
};

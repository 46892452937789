import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive } from '@sg/garnish';

export const AccountScreenBorderedSection = ({
  children,
  style,
}: ViewProps) => {
  const { currentBreakpoint } = useResponsive();

  return (
    <View
      testID="account.bordered-section"
      style={[!currentBreakpoint.isXS && styles.container, style]}
    >
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: theme.radius.large,
    borderColor: theme.colors.GRAY,
    borderStyle: 'solid',
    borderWidth: 1,
    paddingHorizontal: theme.spacing['6'],
  },
});

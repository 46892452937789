import { Platform } from 'react-native';
import {
  logger as rnLogger,
  type transportFunctionType,
} from 'react-native-logs';
import Constants from 'expo-constants';
import { datadog } from '@sg/expo-datadog-client';

/**
 * ───────────────────────────────────────────────────────────────────────────
 * "What's the deal with the transports + conditioning??!?"
 * ───────────────────────────────────────────────────────────────────────────
 *
 * The main issue is that, for NATIVE, we DON'T want `console.*`, because it has
 * perf issues.
 *
 * And so for NATIVE we have this custom `datadogTransport` that sends logs to
 * Datadog over the network.
 *
 * But we DON'T want that for WEB, because Datadog is ALREADY collecting
 * `console.*` AUTOMATICALLY.
 *
 * In other worse, for WEB, the `mapConsoleTransport` is sufficient and
 * `datadogTransport` only generates DUPLICATE logs.
 *
 * When we're doing NATIVE development locally, we might want to override this
 * behavior, so we can see logs in the console, so there's an env var for that.
 *
 * ───────────────────────────────────────────────────────────────────────────
 * NOTE: There's an override var to enable console logging (local dev only)
 * ───────────────────────────────────────────────────────────────────────────
 * NOTE: Relevant web + logging options: `forwardErrorsToLogs`,
 * `forwardConsoleLogs`, `forwardReports`
 * https://docs.datadoghq.com/logs/log_collection/javascript/#initialization-parameters
 */

// ─────────────────────────────────────────────────────────────────────────────
const shouldIncludeDatadogTransport = Platform.OS !== 'web' || !Constants.expoConfig?.extra?.GARNISH_LOGGER_ENABLE_NATIVE_CONSOLE_LOGGING_OVERRIDE; // prettier-ignore
const shouldIncludeConsoleTransport = Platform.OS === 'web' || Boolean(Constants.expoConfig?.extra?.GARNISH_LOGGER_ENABLE_NATIVE_CONSOLE_LOGGING_OVERRIDE); // prettier-ignore
// ─────────────────────────────────────────────────────────────────────────────

// NOTE: There's this annoying typescript error from the react-native-logs
// library, that isn't disabled with `@ts-ignore` or `@ts-expect-error`.
// https://github.com/mowispace/react-native-logs/issues/102
export const logger = rnLogger.createLogger({
  printLevel: false,
  printDate: false,
  transport(props) {
    shouldIncludeConsoleTransport && mapConsoleTransport(props);
    shouldIncludeDatadogTransport && datadogTransport(props);
  },
});

// ─────────────────────────────────────────────────────────────────────────────
// ── TRANSPORTS ───────────────────────────────────────────────────────────────

const mapConsoleTransport: transportFunctionType = (props) => {
  const consoleMethod = (console as any)[props.level.text] || console.log; // eslint-disable-line no-console

  consoleMethod(props.msg);

  return true;
};

const datadogTransport: transportFunctionType = (props) => {
  const logLevel = props.level.text;

  // TODO: add support for different types
  const message = `${props.msg}`;

  switch (logLevel) {
    case 'info': {
      datadog.logger.info(message);
      break;
    }

    case 'debug': {
      datadog.logger.debug(message);
      break;
    }

    case 'warn': {
      datadog.logger.warn(message);
      break;
    }

    case 'error': {
      datadog.logger.error(message);
      break;
    }

    default: {
      datadog.logger.info(message);
    }
  }

  return true;
};

import React, { useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { Button } from '@sg/garnish';

/**
 * Continue button to be used in the upsells modal.
 */
export const BagContinueToBagCta = (props: BagContinueToBagCtaProps) => {
  const { isDisabled, onPress } = props;

  const ref = useRef(null);
  const { formatMessage } = useIntl();

  return (
    <View style={styles.wrapper}>
      <Button
        ref={ref}
        size="large-wide"
        palette="primaryLime"
        style={styles.cta}
        accessibilityLabel={formatMessage(messages.continueToBag)}
        disabled={isDisabled}
        onPress={onPress}
      >
        {formatMessage(messages.continueToBag)}
      </Button>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  continueToBag: {
    defaultMessage: 'Continue to bag',
    description: 'Bag | Floating CTAs | To Bag',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  cta: {
    ...theme.elevations[6],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagContinueToBagCtaProps = {
  isDisabled: boolean;
  onPress: () => void;
};

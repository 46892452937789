import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import type { IconName } from '../Icon';
import { Icon } from '../Icon';
import { Image } from '../Image/Image';
import { BodyText } from '../Text/BodyText';

export const AddressTypes = {
  delivery: 'delivery',
  outpost: 'outpost',
  pickup: 'pickup',
};

const DeliveryAddressTypesIconMap: Record<string, IconName> = {
  home: 'IconHomeStrokeSm',
  work: 'IconWorkStrokeSm',
};

export type AddressType = Required<keyof typeof AddressTypes>;

export type AddressOrderProps = Readonly<{
  addressType: AddressType;
  hideIcon?: boolean;
  name?: string | null;
  url?: string;
  street?: string;
  secondaryStreet?: string | null;
  state?: string;
  city?: string;
  zipCode?: string;
  isInteracting?: boolean;
}>;

type AddressTypeProps = Omit<AddressOrderProps, 'addressType'>;

type AddressComponentsMapType = Record<
  string,
  (p: AddressTypeProps) => React.ReactElement
>;

type AddressLayoutProps = Readonly<{
  children: ViewProps['children'];
}>;

type AddressTextProps = Readonly<{
  text: string;
  isInteracting?: boolean;
}>;

const AddressComponentsMap: AddressComponentsMapType = {
  [AddressTypes.pickup]: (props: AddressTypeProps) => (
    <AddressLayout>
      {props.hideIcon ? null : (
        <AddressMedia>
          <Image
            style={styles.mediaImage}
            contentFit="contain"
            source={{ uri: props.url ?? '' }}
          />
        </AddressMedia>
      )}
      <AddressDetails>
        <AddressTitle
          isInteracting={props.isInteracting}
          text={props.name ?? ''}
        />
        <AddressDescription text={props.street ?? ''} />
        <AddressDescription
          text={`${props.city ?? ''}, ${props.state ?? ''} ${
            props.zipCode ?? ''
          }`}
        />
      </AddressDetails>
    </AddressLayout>
  ),
  [AddressTypes.outpost]: (props: AddressTypeProps) => (
    <AddressLayout>
      {props.hideIcon ? null : (
        <AddressMedia>
          <Icon name="IconOutpostStrokeSm" color={theme.colors.DARK_KALE} />
        </AddressMedia>
      )}
      <AddressDetails>
        <AddressTitle
          isInteracting={props.isInteracting}
          text={props.name ?? ''}
        />
        <AddressDescription text={props.street ?? ''} />
        <AddressDescription
          text={`${props.city ?? ''}, ${props.state ?? ''} ${
            props.zipCode ?? ''
          }`}
        />
      </AddressDetails>
    </AddressLayout>
  ),
  [AddressTypes.delivery](props: AddressTypeProps) {
    const deliveryIcon = DeliveryAddressNameMap(props.name);

    return (
      <AddressLayout>
        {props.hideIcon ? null : (
          <AddressMedia>
            <Icon name={deliveryIcon} color={theme.colors.DARK_KALE} />
          </AddressMedia>
        )}
        <AddressDetails>
          <AddressTitle
            isInteracting={props.isInteracting}
            text={props.name ?? props.street ?? ''}
          />
          {props.name ? <AddressDescription text={props.street ?? ''} /> : null}
          {props.secondaryStreet ? (
            <AddressDescription text={props.secondaryStreet} />
          ) : null}
          <AddressDescription
            text={`${props.city ?? ''}, ${props.state ?? ''} ${
              props.zipCode ?? ''
            }`}
          />
        </AddressDetails>
      </AddressLayout>
    );
  },
};

const AddressTitle = ({ text, isInteracting }: AddressTextProps) => (
  <BodyText underline={isInteracting} bold size={4}>
    {text}
  </BodyText>
);

const AddressDescription = ({ text }: AddressTextProps) => (
  // @ts-expect-error TS(2322): Type '{ children: string; dataSet: { ddPrivacy: st... Remove this comment to see the full error message
  <BodyText dataSet={{ ddPrivacy: 'mask' }} size={4}>
    {text}
  </BodyText>
);

export const AddressOrder = ({
  addressType: type,
  ...rest
}: AddressOrderProps) => {
  if (!AddressTypes[type]) return null;
  const Component = AddressComponentsMap[type];

  return <Component {...rest} />;
};

const AddressLayout = ({ children }: AddressLayoutProps) => {
  return <View style={styles.layout}>{children}</View>;
};

const AddressDetails = ({ children }: AddressLayoutProps) => {
  return <View>{children}</View>;
};

const AddressMedia = ({ children }: AddressLayoutProps) => {
  return <View style={styles.media}>{children}</View>;
};

const DeliveryAddressNameMap = (addressName?: string | null): IconName => {
  // eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10
  return addressName
    ? DeliveryAddressTypesIconMap[addressName]
      ? DeliveryAddressTypesIconMap[addressName]
      : 'IconCustomStrokeSm'
    : 'IconPickupStrokeSm';
};

const styles = StyleSheet.create({
  layout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  media: {
    marginRight: 20,
  },
  mediaImage: {
    width: 20,
    height: 20,
  },
});

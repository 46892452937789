import type { PathConfig } from '@react-navigation/native';

import { appNavigationLinking } from '../../AppNavigation.linking';
import type { ModalStackParamList } from '../../AppNavigation.props';

/**
 * @returns List of screens that are modals.
 * e.g.: ['ProductDetails', 'DeliveryOrderInFlight', 'ReorderChangeLocation']
 */
export const getModalScreensNames = () => {
  const stackScreens = appNavigationLinking.config?.screens?.Modal;
  const modalScreens = (stackScreens as PathConfig<ModalStackParamList>)
    ?.screens;

  return new Set(Object.keys(modalScreens ?? {}));
};

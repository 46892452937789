import React, { useCallback, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  BodyText,
  Icon,
  LoadingAnimation,
  ModalDialogue,
  useResponsive,
  useToggleState,
} from '@sg/garnish';
import { RedemptionChannel, TierName } from '@sg/graphql-schema';

import { useIsLoggedIn } from '@order/AuthMachine';
import { AppFooter, SignedOutView } from '@order/components';
import { Loyalty, LOYALTY_PALETTES } from '@order/features/loyalty';
import {
  useLoyaltyContent,
  useLoyaltyInfoBenefits,
  useNavigateToLastOrderMenu,
} from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry, useTrackEffect } from '@order/Telemetry';

import { LoyaltyOfferCard } from '../../components/LoyaltyOffers/components';
import { JoinSgRewardsScreen } from '../JoinSgRewardsScreen';
import {
  LoyaltyHomeFaqXs,
  LoyaltyHowItWorksModal,
  LoyaltyPointHistoryModal,
} from './components';
import { useLoyaltyInfo, useTermsBeingShown } from './hooks';
import { useHomeProgressBenefit } from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyHomeScreen = () => {
  const { currentBreakpoint, minWidth, match } = useResponsive();
  const isLoggedIn = useIsLoggedIn();

  // ─── Premium ──────────────────────────────────────────────────────────────

  const isPremium = useFeatureFlag('CELS-3031-sg-rewards-premium-enabled');
  const premiumBgColor = isPremium ? LOYALTY_PALETTES.premiumBgColor : null;
  const premiumFgColor = isPremium ? LOYALTY_PALETTES.premiumFgColor : null;

  // ─── GraphQL ──────────────────────────────────────────────────────────────

  const {
    isLoading,
    firstName,
    availablePoints,
    pendingPoints,
    totalPoints,
    challenges,
    rewards,
    pointsRewards,
    customerTier,
  } = useLoyaltyInfo(isLoggedIn);

  // ─── Contentful ───────────────────────────────────────────────────────────

  const { homeProgressBenefits } = useLoyaltyContent({ availablePoints });

  // ─── Progress Benefit ─────────────────────────────────────────────────────

  const progressBenefit = useHomeProgressBenefit({
    availablePoints,
    pendingPoints,
    homeProgressBenefits,
  });

  // ─── Loyalty Info Benefits ────────────────────────────────────────────────

  const { benefits: loyaltyInfoBenefits, fetching: isFetchingBenefits } =
    useLoyaltyInfoBenefits();

  // ─── Points History Modal ─────────────────────────────────────────────────

  const {
    value: isShowingPointsHistoryModal,
    toggleOn: showPointsHistoryModalToggleOn,
    toggleOff: hidePointsHistoryModal,
  } = useToggleState();

  // ─── How it Works Modal ───────────────────────────────────────────────────

  const {
    value: isShowingHowItWorksModal,
    toggleOn: showHowItWorksModalToggleOn,
    toggleOff: hideHowItWorksModal,
  } = useToggleState();

  // ─── Telemetry ────────────────────────────────────────────────────────────

  useTrackEffect('loyalty.home.view');
  const { track } = useTelemetry();

  const showPointsHistoryModal = useCallback(() => {
    track('loyalty.home.points_history.open');
    showPointsHistoryModalToggleOn();
  }, [track, showPointsHistoryModalToggleOn]);

  const showHowItWorksModal = useCallback(() => {
    track('loyalty.home.how_it_works.open');
    showHowItWorksModalToggleOn();
  }, [track, showHowItWorksModalToggleOn]);

  const trackBenefitsOpened = useCallback(() => {
    track('loyalty.home.benefits.open');
  }, [track]);

  // ─── Terms Modal ──────────────────────────────────────────────────────────

  const { termsBeingShown, showTerms, hideTerms } = useTermsBeingShown();

  // ─── Navigation ───────────────────────────────────────────────────────────

  const navigation = useNavigation();
  const navigateToLastOrderMenu = useNavigateToLastOrderMenu();

  const clearModals = useCallback(() => {
    hideTerms();
    hidePointsHistoryModal();
    hideHowItWorksModal();
  }, [hideHowItWorksModal, hidePointsHistoryModal, hideTerms]);

  const startOrder = useCallback(() => {
    clearModals();
    navigateToLastOrderMenu();
  }, [clearModals, navigateToLastOrderMenu]);

  useEffect(() => {
    navigation.setOptions({ headerShown: !currentBreakpoint.isXS });
  }, [navigation, currentBreakpoint.isXS]);

  useFocusEffect(
    useCallback(() => {
      return clearModals;
    }, [clearModals]),
  );

  // ──────────────────────────────────────────────────────────────────────────

  if (!isLoggedIn) return <SignedOutView />;

  if (isLoading) return <LoadingAnimation />;

  if (customerTier === TierName.RewardsOptedOut) {
    return <JoinSgRewardsScreen />;
  }

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <Loyalty.Home.Container
      availablePoints={availablePoints}
      palette={isPremium ? 'dark-kale' : 'cream'}
      style={premiumBgColor}
    >
      <Loyalty.Home.Header.Container>
        <Loyalty.Home.Header.Content>
          <Loyalty.Home.Header.FirstName
            firstName={firstName}
            style={premiumFgColor}
          />

          <Loyalty.Home.Header.AvailablePoints
            availablePoints={availablePoints}
            style={premiumFgColor}
          />
        </Loyalty.Home.Header.Content>

        {currentBreakpoint.isXS && isPremium ? (
          <Icon name="IconGoat" width={60} height={60} />
        ) : null}
      </Loyalty.Home.Header.Container>

      {progressBenefit && minWidth.isSM ? (
        <Loyalty.Home.ProgressBenefit.Sm
          progressBenefit={progressBenefit}
          style={premiumFgColor}
        />
      ) : null}

      {pointsRewards.length > 0 ? (
        <Loyalty.Home.ProgressBar
          palette={isPremium ? 'dark' : 'light'}
          availablePoints={availablePoints}
          totalPoints={totalPoints}
          pointsRewards={pointsRewards}
        />
      ) : null}

      {progressBenefit && !minWidth.isSM ? (
        <Loyalty.Home.ProgressBenefit.Xs
          palette={isPremium ? 'dark' : 'light'}
          progressBenefit={progressBenefit}
          benefitsCount={loyaltyInfoBenefits.length}
          onBenefitsOpened={trackBenefitsOpened}
        >
          <Loyalty.InfoBenefits
            availablePoints={availablePoints}
            loyaltyInfoBenefits={loyaltyInfoBenefits}
            isLoading={isFetchingBenefits}
            style={Loyalty.Home.ProgressBenefit.BenefitsStyle}
          />
        </Loyalty.Home.ProgressBenefit.Xs>
      ) : null}

      <Loyalty.Home.Body>
        {minWidth.isSM ? (
          <Loyalty.PointsHistory.CtaButton
            palette={isPremium ? 'light' : 'secondary'}
            onPress={showPointsHistoryModal}
          />
        ) : null}

        {currentBreakpoint.isXS ? (
          <>
            <Loyalty.PointsHistory.CtaLink
              style={isPremium ? LOYALTY_PALETTES.premiumFgColor : undefined}
              onPress={showPointsHistoryModal}
            />

            <BodyText
              style={isPremium ? LOYALTY_PALETTES.premiumFgColor : undefined}
              sizeMatch={['14']}
            >
              •
            </BodyText>

            <Loyalty.HowItWorks.Cta
              style={isPremium ? LOYALTY_PALETTES.premiumFgColor : undefined}
              onPress={showHowItWorksModal}
            />
          </>
        ) : null}
      </Loyalty.Home.Body>

      {challenges.length > 0 ? (
        <Loyalty.Home.Challenges.Container style={premiumBgColor}>
          <Loyalty.Home.Challenges.Rail>
            {challenges.map((offer) => (
              <View
                key={offer.id}
                style={match([undefined, styles.offerCardContainerSM])}
              >
                <LoyaltyOfferCard
                  offer={offer}
                  rounded={true}
                  layout="compact"
                />
              </View>
            ))}
          </Loyalty.Home.Challenges.Rail>
        </Loyalty.Home.Challenges.Container>
      ) : (
        <Loyalty.Home.ChallengesEmptyState />
      )}

      {rewards.length > 0 ? (
        <Loyalty.Home.Rewards.Container style={premiumBgColor}>
          {minWidth.isSM ? (
            <Loyalty.Home.Rewards.Title style={premiumFgColor} />
          ) : null}

          <Loyalty.Home.Rewards.Rail>
            {rewards.map((reward) => {
              const isInStoreOnly =
                reward.redemptionChannel === RedemptionChannel.InStore;
              const onShowTerms = reward.terms ? showTerms(reward) : undefined;
              const onStartOrder = isInStoreOnly ? undefined : startOrder;

              return (
                <Loyalty.Reward
                  key={reward.id}
                  id={reward.id}
                  title={reward.name}
                  imageUrl={reward.assetUrl}
                  expirationDate={reward.expirationDate}
                  redeemableAt={reward.redeemableAt}
                  isInStoreOnly={isInStoreOnly}
                  onShowTerms={onShowTerms}
                  onStartOrder={onStartOrder}
                />
              );
            })}
          </Loyalty.Home.Rewards.Rail>
        </Loyalty.Home.Rewards.Container>
      ) : (
        <Loyalty.Home.RewardsEmptyState />
      )}

      {currentBreakpoint.isXS ? (
        <LoyaltyHomeFaqXs
          palette={isPremium ? 'white' : 'kale'}
          showHowItWorksModal={showHowItWorksModal}
        />
      ) : null}

      <Loyalty.Home.Footer style={premiumBgColor} />

      {minWidth.isSM ? <AppFooter /> : null}

      <LoyaltyPointHistoryModal
        availablePoints={availablePoints}
        visible={isShowingPointsHistoryModal}
        startOrder={startOrder}
        onRequestClose={hidePointsHistoryModal}
      />

      <LoyaltyHowItWorksModal
        visible={isShowingHowItWorksModal}
        onRequestClose={hideHowItWorksModal}
      />

      <ModalDialogue
        visible={Boolean(termsBeingShown)}
        onRequestClose={hideTerms}
      >
        {termsBeingShown?.terms ? (
          <Loyalty.RewardTerms
            title={termsBeingShown.name}
            terms={termsBeingShown?.terms}
            expirationDate={termsBeingShown?.expirationDate}
          />
        ) : null}
      </ModalDialogue>
    </Loyalty.Home.Container>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  offerCardContainerSM: {
    width: 343,
    flexGrow: 1,
  },
});

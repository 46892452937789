import { theme } from '@sg/garnish';

export const ORDER_LINE_ITEM_CONTAINER_VERTICAL = {
  PADDING: theme.spacing['2'],
};

export const ORDER_LINE_ITEM_IMAGE = {
  SIZE: {
    SM: 80,
    MD: 160,
  },
};

export { TYPE_CONFIG } from './typography-config';

export const FONTS = {
  GRENETTE_PRO: 'Grenette Pro',
  GRENETTE_PRO_LIGHT: 'GrenettePro-Light',
  GRENETTE_PRO_REGULAR: 'GrenettePro-Regular',

  SWEET_SANS: 'SweetSans',
  SWEET_SANS_REGULAR: 'SweetSans-Regular',
  SWEET_SANS_BOLD: 'SweetSans-Bold',

  SWEET_SANS_TEXT: 'SweetSans Text',
  SWEET_SANS_TEXT_REGULAR: 'SweetSansText-Regular',
  SWEET_SANS_TEXT_BOLD: 'SweetSansText-Bold',

  SWEET_SANS_NARROW: 'SweetSans Narrow',
  SWEET_SANS_NARROW_REGULAR: 'SweetSansNarrow-Regular',
  SWEET_SANS_NARROW_BOLD: 'SweetSansNarrow-Bold',

  SWEET_SANS_WIDE: 'SweetSans Wide',
  SWEET_SANS_WIDE_REGULAR: 'SweetSansWide-Regular',
  SWEET_SANS_WIDE_BOLD: 'SweetSansWide-Bold',

  SWEET_SANS_CONDENSED: 'SweetSans Condensed',
  SWEET_SANS_CONDENSED_BOLD: 'SweetSansCondensed-Bold',
} as const;

/**
 * Font Weights (Web)
 */
export const WEIGHT_MAP = {
  thin: '100',
  hairline: '100',
  'extra light': '200',
  'ultra light': '200',
  light: '300',
  normal: '400',
  regular: '400',
  medium: '500',
  'semi bold': '600',
  'demi bold': '600',
  bold: '700',
  'extra bold': '800',
  'ultra bold': '800',
  black: '900',
  heavy: '900',
  //
  // Consider 'ultra black': '950', 'extra black': '950',
} as const;

export const FONT_FACE_MAP = {
  [FONTS.GRENETTE_PRO]: {
    [WEIGHT_MAP.light]: FONTS.GRENETTE_PRO_LIGHT,
    [WEIGHT_MAP.normal]: FONTS.GRENETTE_PRO_REGULAR,
    [WEIGHT_MAP.bold]: FONTS.GRENETTE_PRO_REGULAR,
  },

  [FONTS.SWEET_SANS]: {
    [WEIGHT_MAP.normal]: FONTS.SWEET_SANS_REGULAR,
    [WEIGHT_MAP.bold]: FONTS.SWEET_SANS_BOLD,
  },

  [FONTS.SWEET_SANS_TEXT]: {
    [WEIGHT_MAP.normal]: FONTS.SWEET_SANS_TEXT_REGULAR,
    [WEIGHT_MAP.bold]: FONTS.SWEET_SANS_TEXT_BOLD,
  },

  [FONTS.SWEET_SANS_NARROW]: {
    [WEIGHT_MAP.normal]: FONTS.SWEET_SANS_NARROW_REGULAR,
    [WEIGHT_MAP.bold]: FONTS.SWEET_SANS_NARROW_BOLD,
  },

  [FONTS.SWEET_SANS_WIDE]: {
    [WEIGHT_MAP.normal]: FONTS.SWEET_SANS_WIDE_REGULAR,
    [WEIGHT_MAP.bold]: FONTS.SWEET_SANS_WIDE_BOLD,
  },

  [FONTS.SWEET_SANS_CONDENSED]: {
    [WEIGHT_MAP.bold]: FONTS.SWEET_SANS_CONDENSED_BOLD,
  },
};

// Default because it's the font family for body text
export const DEFAULT_FONT_FAMILY = FONTS.SWEET_SANS_TEXT;

/**
 * This is subject to change as we iterate on our figma workflows
 */
export const RESPONSIVE_TYPE_SIZE_MAP = {
  '120': ['48', '64', '80', '120'],
  '80': ['40', '64', '80', '80'],
  '64': ['32', '40', '64', '64'],
  '56': ['40', '40', '56', '56'],
  '48': ['32', '40', '48', '48'],
  '40': ['24', '32', '40', '40'],
  '32': ['24', '24', '32', '32'],
  '24': ['18', '18', '24', '24'],
  '18': ['16', '16', '18', '18'],
  '16': ['14', '16', '16', '16'],
  '14': ['14', '14', '14', '14'],
  '12': ['12', '12', '12', '12'],
  '10': ['10', '10', '10', '10'],
};

/**
 * React Native doesn't have the same customization options as web with @font-face
 *
 * https://medium.com/qeepsake-engineering/custom-font-caveats-in-react-native-c0f6b0eace30
 * >the best approach to fix this discrepancy is to rename your font filename to
 * match the ‘PostScript name’.
 *
 * https://stackoverflow.com/a/38820631
 * > the only font files that RN will find are of the following format:
 * {FontFamilyName}
 * {FontFamilyName}_bold
 * {FontFamilyName}_italic
 * {FontFamilyName}_bold_italic
 *
 *
 * https://github.com/expo/expo/issues/9149#issuecomment-656053950
 * > Unfortunately, React Native has certain limitations to custom
 * fonts. On Android, as you mention, only the normal and bold font weights are
 * supported. Because of this, the best approach is to declare them as a
 * different font family. Here is an example on Stack Overflow and one in our
 * docs, I think it can help you further.
 */

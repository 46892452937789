import { useTrackEventEffect } from '@order/Telemetry';

/**
 * Handles success / failure telemetry events for sweetpass benefits content.
 * @see: https://sweetgreen.atlassian.net/wiki/spaces/CEL/pages/4608294913/Mixpanel+Events+for+Loyalty
 */
export const useSweetpassBenefitsTelemetry = (
  props: SweetpassBenefitsTelemetryProps,
) => {
  const { isLoading, hasContent, shouldRedirectToHome } = props;

  useTrackEventEffect({
    name: 'sweetpass_benefits.view.success',
    skip: isLoading || !hasContent,
  });

  useTrackEventEffect({
    name: 'sweetpass_benefits.view.failure',
    skip: !shouldRedirectToHome,
    payload: { systemError: 'Invalid Contentful Response' },
  });
};

type SweetpassBenefitsTelemetryProps = Readonly<{
  isLoading: boolean;
  hasContent: boolean;
  shouldRedirectToHome: boolean;
}>;

import React from 'react';
import { Controller } from 'react-hook-form';

import { Checkbox as GarnishCheckbox } from '../Checkbox';
import type { ControlledProps, FormProps } from './types';

type ComponentProps = Omit<
  React.ComponentProps<typeof GarnishCheckbox>,
  'checked'
>;
type OmittedProps = ControlledProps;
type Props = FormProps & Omit<ComponentProps, OmittedProps>;

export const Checkbox = ({ name, control, rules, ...rest }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <GarnishCheckbox
          checked={value}
          onBlur={onBlur}
          onChange={(...args) => {
            onChange(...args);
            onBlur();
          }}
          {...rest}
        />
      )}
    />
  );
};

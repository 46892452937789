import { defineMessages } from 'react-intl';

export const lineItemDescriptionMessages = defineMessages({
  dressingModes: {
    defaultMessage: `{dressing_mode, select,
      ON_THE_SIDE       {Dressing on the side}
      MIXED             {Dressing mixed in}
      REMOVED           {No dressing}
      NOT_DRESSABLE     {}
      other {}
    }`,
    description: 'Lite item | Description | Dressing modes',
  },
  plusBreadText: {
    defaultMessage: '+ Bread',
    description: 'Lite item | Description | With bread option text',
  },
});

/* istanbul ignore file */

import type { Client } from 'urql';

import { DeliveryPreferenceType } from '@order/graphql';

import type {
  DeliveryLocationWithoutNearbyStore,
  Location,
} from '../../LocationSearch.types';
import { addDeliveryAddress, addressPredictionsQuery } from '../../queries';
import {
  formatDeliveryAddressPrediction,
  getActiveCartDeliveryLocation,
  getCustomerDeliveryAddresses,
  getCustomerFirstDeliveryLocation,
  getLastInteractedStoreDeliveryLocation,
  getLastPlacedOrderDeliveryLocation,
} from './delivery-location.helpers';

export { getDeliveryLocationByPlaceId } from './delivery-location.helpers';

// ─────────────────────────────────────────────────────────────────────────────

/**
 *  Fetches the default delivery location from the following sources and in the following order:
 *
 * - Delivery Location of the active card.
 * - Last interaction store delivery location.
 * - Last placed order delivery location.
 * - The customer's primary delivery address.
 */
export async function getDefaultDeliveryLocation(
  props: GetDefaultDeliveryLocationProps,
): Promise<Location | DeliveryLocationWithoutNearbyStore | undefined> {
  const {
    client,
    googlePlacesApiKey,
    lastInteractedStoreAddressId = '',
    shouldUseDisclosureFields,
  } = props;

  // ─── Active Card Delivery Location ───────────────────────────────────

  const activeCartDeliveryLocation = await getActiveCartDeliveryLocation({
    client,
    googlePlacesApiKey,
    shouldUseDisclosureFields,
  });

  if (activeCartDeliveryLocation) {
    return activeCartDeliveryLocation;
  }

  // ─── Last Interacted Store Delivery Location ─────────────────────────

  const lastInteractedStoreDeliveryLocation =
    await getLastInteractedStoreDeliveryLocation({
      client,
      lastInteractedStoreAddressId,
      googlePlacesApiKey,
      shouldUseDisclosureFields,
    });

  if (lastInteractedStoreDeliveryLocation) {
    return lastInteractedStoreDeliveryLocation;
  }

  // ─── Last Placed Order Delivery Location ─────────────────────────────

  const lastPlacedOrderDeliveryLocation =
    await getLastPlacedOrderDeliveryLocation({
      client,
      googlePlacesApiKey,
      shouldUseDisclosureFields,
    });

  if (lastPlacedOrderDeliveryLocation) {
    return lastPlacedOrderDeliveryLocation;
  }

  // ─── Customer Primary Delivery Location ──────────────────────────────

  const customerPrimaryDeliveryLocation =
    await getCustomerFirstDeliveryLocation({
      client,
      googlePlacesApiKey,
      shouldUseDisclosureFields,
    });

  if (customerPrimaryDeliveryLocation) {
    return customerPrimaryDeliveryLocation;
  }
}

/**
 * Fetches delivery addresses predictions using Google API.
 */
export async function getDeliveryAddressPredictions(
  client: Client,
  searchString: string,
) {
  const { predictions } = await addressPredictionsQuery(searchString);
  const predictionAddresses = predictions as AutocompletePredictions;

  if (!predictionAddresses) {
    throw new Error('Failed to fetch delivery address predictions');
  }

  const customerDeliveryAddresses = await getCustomerDeliveryAddresses(client);

  return predictionAddresses.map(
    formatDeliveryAddressPrediction(customerDeliveryAddresses),
  );
}

/**
 * Adds new customer delivery address based on the provided delivery location.
 */
export async function addNewDeliveryAddress(
  client: Client,
  deliveryLocation: Location | DeliveryLocationWithoutNearbyStore | undefined,
) {
  if (!deliveryLocation || !deliveryLocation?.restaurantSlug) {
    throw new Error('Delivery location was not provided');
  }

  // Locations lacking a zip code are considered invalid and cannot be delivered to.
  if (!deliveryLocation?.zipCode) {
    throw new Error('Delivery location does not have a zip code');
  }

  const { data } = await addDeliveryAddress(client, {
    input: {
      googlePlaceId: deliveryLocation.id,
      city: deliveryLocation.city ?? '',
      country: deliveryLocation.country ?? '',
      latitude: deliveryLocation.lat,
      longitude: deliveryLocation.lng,
      state: deliveryLocation.state ?? '',
      street: deliveryLocation.street ?? '',
      zipCode: deliveryLocation.zipCode ?? '',
      deliveryPreference:
        deliveryLocation.deliveryPreference ??
        DeliveryPreferenceType.LeaveAtDoor,
      name: deliveryLocation.name ?? '',
      secondaryStreet: deliveryLocation?.secondaryStreet ?? '',
      notes: deliveryLocation?.notes ?? '',
    },
  });

  const hasSuccessfullyAddedAddress =
    data?.addAddress.__typename === 'AddAddressSuccess';

  if (!hasSuccessfullyAddedAddress) {
    throw new Error('Failed to add new delivery address');
  }

  return data.addAddress.address.id;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type AutocompletePredictions =
  // @ts-expect-error TS(2503): Cannot find namespace 'google'.
  readonly google.maps.places.AutocompletePrediction[];

type GetDefaultDeliveryLocationProps = Readonly<{
  client: Client;
  googlePlacesApiKey: string;
  lastInteractedStoreAddressId?: string;

  /**
   * A flag to fetch extra upcharge disclosure fields from BE.
   */
  shouldUseDisclosureFields: boolean;
}>;

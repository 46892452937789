import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardTag = (props: LoyaltyOfferCardTagProps) => {
  const { style, ...restProps } = props;

  return (
    <View style={styles.container}>
      <BodyText
        sizeMatch={['18']}
        style={[styles.text, style]}
        {...restProps}
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.SWEET_CORN,
    borderRadius: 50,
    paddingHorizontal: theme.spacing['2'],
  },
  text: {
    color: theme.colors.DARK_KALE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardTagProps = ComponentProps<typeof BodyText>;

/* eslint-disable functional/immutable-data */
/* cSpell:ignore reaptcha */

import type { ComponentProps, MutableRefObject } from 'react';
import React, { useCallback, useEffect, useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import GRecaptcha from 'reaptcha';

import { useResponsive } from '../../hooks';
import { reCaptchaModel } from './machine';
import { RECAPTCHA_CONFIG } from './ReCaptcha.constants';
import { reCaptchaLogger } from './ReCaptcha.helpers';
import type { ReCaptchaContext, ReCaptchaProps } from './ReCaptcha.types';

// ────────────────────────────────────────────────────────────────────────────────

export const ReCaptcha = React.forwardRef<ReCaptchaContext, ReCaptchaProps>(
  (props, ref) => {
    const { languageCode = 'en', machine, onVerify, onError } = props;

    const [, sendEvent] = machine ?? [];

    const grecaptchaRef = useRef<GRecaptcha>(null);

    const { currentBreakpoint } = useResponsive();

    const badgePosition: BadgePosition = currentBreakpoint.isXS
      ? 'inline'
      : undefined;

    // ─────────────────────────────────────────────────────────────────

    const handleOnLoad = useCallback(async () => {
      sendEvent?.(reCaptchaModel.events.LOAD());
    }, [sendEvent]);

    const executeRecaptcha = useCallback(async () => {
      sendEvent?.(reCaptchaModel.events.EXECUTE());

      await grecaptchaRef.current?.execute();
    }, [sendEvent]);

    const resetRecaptcha = useCallback(async () => {
      await grecaptchaRef.current?.reset();

      sendEvent?.(reCaptchaModel.events.RESET());
    }, [sendEvent]);

    const handleRecaptchaVerify = useCallback(
      (response: string) => {
        sendEvent?.(reCaptchaModel.events.VERIFY());

        onVerify(response);
      },
      [onVerify, sendEvent],
    );

    const handleRecaptchaError = useCallback(() => {
      reCaptchaLogger.error('An error occurred while executing ReCaptcha');

      sendEvent?.(reCaptchaModel.events.ERROR());
      onError?.();
    }, [onError, sendEvent]);

    // ─────────────────────────────────────────────────────────────────

    // synchronize 3rd party and local Recaptcha refs
    useEffect(() => {
      const isInvalidRef = typeof ref !== 'object';

      if (isInvalidRef) return;

      (ref as MutableRefObject<ReCaptchaContext>).current = {
        executeRecaptcha,
        resetRecaptcha,
      };
    }, [executeRecaptcha, ref, resetRecaptcha]);

    // ─────────────────────────────────────────────────────────────────

    return (
      <View testID="sg-recaptcha-web" style={styles.wrapper}>
        <GRecaptcha
          ref={grecaptchaRef}
          sitekey={RECAPTCHA_CONFIG.siteKey}
          badge={badgePosition}
          onLoad={handleOnLoad}
          onVerify={handleRecaptchaVerify}
          onError={handleRecaptchaError}
          size="invisible"
          hl={languageCode}
        />
      </View>
    );
  },
);

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────────

type BadgePosition = ComponentProps<typeof GRecaptcha>['badge'];

import React, { memo, useMemo } from 'react';

import { Image } from '../Image';
import { type ImageProps } from '../Image.types';
import { getCloudinaryUrl } from './getCloudinaryUrl';

// ────────────────────────────────────────────────────────────────────────────────

export const CloudinaryImage = memo((props: CloudinaryImageProps) => {
  const { baseUrl, config, ...rest } = props;

  const transformedSource = useMemo(() => {
    return getCloudinaryUrl(baseUrl, config);
  }, [baseUrl, config]);

  const source = useMemo<ImageProps['source']>(
    () => ({ uri: transformedSource }),
    [transformedSource],
  );

  return <Image source={source} {...rest} />;
});

// ─── Types ──────────────────────────────────────────────────────────────────────

type CloudinaryImageProps = Readonly<{
  baseUrl: string;
  config: CloudinaryTransformConfig;
}> &
  Omit<ImageProps, 'source'>;

export type CloudinaryTransformConfig = Parameters<typeof getCloudinaryUrl>[1];

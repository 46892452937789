import { isValid } from 'date-fns';

/**
 * The `wantedTime` is what the customer selected.
 * - It is in the YYYY-MM-DDThh:mm:ss.mmm-hh:mm format.
 *
 * The `estimatedDeliveryTime` is provided by vendor and more precise.
 * - It is in the YYYY-MM-DD hh:mm:ss -hhmm format.
 *
 * This function returns the estimatedDeliveryTime if available.
 * Otherwise it returns the wantedTime.
 *
 * In both cases it will return it in the wanted time format.
 */
export const getOrderTime = (order?: GetOrderTimeProps) => {
  const wantedTime = order?.wantedTime;
  const estimatedDeliveryTime =
    order?.deliveryOrderDetail?.estimatedDeliveryTime;

  if (estimatedDeliveryTime) return convertTimeFormat(estimatedDeliveryTime);

  return wantedTime;
};

/**
 * Attempts to convert YYYY-MM-DD hh:mm:ss -hhmm into YYYY-MM-DDThh:mm:ss.mmm-hh:mm.
 */
const convertTimeFormat = (estimatedDeliveryTime: string) => {
  const [date, time, timezone] = estimatedDeliveryTime.split(' ');

  if (!date || !time || !timezone) return estimatedDeliveryTime;

  const timezoneHours = timezone.slice(0, 3);
  const timezoneMinutes = timezone.slice(3, 5);
  const convertedTimeFormat = `${date}T${time}.000${timezoneHours}:${timezoneMinutes}`;

  // Ensures that we are still converting to a valid date format.
  if (!isValid(new Date(convertedTimeFormat))) return estimatedDeliveryTime;

  return convertedTimeFormat;
};

type GetOrderTimeProps = Readonly<{
  wantedTime?: string | null;
  deliveryOrderDetail?: {
    estimatedDeliveryTime?: string | null;
  } | null;
}>;

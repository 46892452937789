import type { ComponentProps } from 'react';
import React, { useEffect, useRef } from 'react';
import type { ViewProps, ViewStyle } from 'react-native';
import { Animated, Easing, Platform, StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../hooks/useResponsive';

export const LoadingAnimation = (props: LoadingAnimationProps) => {
  const {
    testID = 'loading-animation',
    size = 'large',
    backgroundColor,
    withNavbarOffset = false,
    topOffset = 0,
    bottomOffset = 0,
  } = props;

  const { width, height } = iconSizeProps[size];

  const { match } = useResponsive();

  const opacity = useRef(new Animated.Value(0));

  // ────────────────────────────────────────────────────────────────────────────────

  useEffect(() => {
    Animated.loop(
      Animated.timing(opacity.current, {
        toValue: 1,
        duration: 2250,
        useNativeDriver: Platform.OS !== 'web',
        easing: Easing.inOut(Easing.sin),
      }),
    ).start();
  }, []);

  // ─── Styles ─────────────────────────────────────────────────────────────────────

  // NOTE: For this component, please do not utilize the 'useStyle' helper.
  //       On native platforms, it produces split-second flickering.

  const wrapperStyles = [styles.wrapper, { backgroundColor }];
  const iconWrapperStyles = {
    paddingTop: withNavbarOffset ? match([0, topOffset]) : 0,
    paddingBottom: withNavbarOffset ? match([bottomOffset, 0]) : 0,
  };

  // ────────────────────────────────────────────────────────────────────────────────

  return (
    <View style={wrapperStyles}>
      <View style={iconWrapperStyles}>
        <Animated.View
          style={{
            opacity: opacity.current.interpolate({
              inputRange: [0, 0.5, 1],
              outputRange: [1, 0, 1],
            }),
          }}
          testID={testID}
        >
          <Svg width={width} height={height} viewBox="0 0 158 178" fill="none">
            <Path
              d="M85.5 1.74167C81.4778 -0.580558 76.5222 -0.580556 72.5 1.74167L6.69168 39.7361C2.66947 42.0583 0.191681 46.35 0.191681 50.9944V126.983C0.191681 131.628 2.66947 135.919 6.69168 138.242L72.5 176.236C76.5222 178.558 81.4778 178.558 85.5 176.236L151.308 138.242C155.331 135.919 157.808 131.628 157.808 126.983V50.9944C157.808 46.35 155.331 42.0583 151.308 39.7361L85.5 1.74167ZM86.5367 84.6414C86.5367 90.4471 90.6432 93.6756 96.194 93.6756C101.773 93.6756 105.851 90.4754 105.851 84.6414C105.851 78.6658 101.83 75.3806 96.194 75.3806C90.5582 75.3806 86.5367 78.6375 86.5367 84.6414ZM80.2495 104.013H87.2447C88.6607 108.657 92.3707 110.64 97.8082 110.611C104.124 110.611 106.163 108.402 106.163 105.74C106.163 103.418 104.69 101.804 97.6949 100.189C88.774 98.1219 79.5132 95.5448 79.5132 84.4715C79.5132 76.0886 86.4234 70.1413 96.1373 70.1413C100.046 70.1413 103.671 71.2458 106.588 73.1716C108.627 70.8776 111.289 69.7165 114.801 69.8298V76.032C113.13 75.7204 111.459 75.7771 109.844 76.1452C111.77 78.4392 112.875 81.2713 112.875 84.4998C112.875 90.3621 109.675 94.8084 103.982 96.4227C109.533 97.952 113.186 100.246 113.186 105.259C113.186 111.631 107.069 115.822 97.9215 115.822C88.774 115.822 81.9771 112.056 80.2495 104.013ZM75.1519 96.9608C75.1519 103.814 68.9214 108.402 60.142 108.402C50.5697 108.402 44.2542 103.956 42.5833 95.1483H49.5218C50.7113 100.642 54.1098 103.191 59.8588 103.191C65.3813 103.191 68.2133 101.096 68.2133 97.6405C68.2133 93.6681 63.2694 92.7144 57.7706 91.6538C50.8815 90.3249 43.1214 88.8281 43.1214 81.0164C43.1214 74.4743 48.9271 70.1413 57.5932 70.1413C67.0522 70.1413 73.3393 74.7859 74.897 82.9988H68.1567C66.7973 77.7878 63.2006 75.324 57.6498 75.324C52.6937 75.324 49.975 77.1648 49.975 80.3933C49.975 84.0228 54.7265 84.953 60.1012 86.0054C67.0984 87.3754 75.1519 88.9521 75.1519 96.9608Z"
              fill={theme.colors.DARK_KALE}
              fillOpacity={0.08}
              fillRule="evenodd"
              clipRule="evenodd"
            />
          </Svg>
        </Animated.View>
      </View>
    </View>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingVertical: theme.spacing['6'],
  },
});

const iconSizeProps: Record<
  AnimatedIconSize,
  Required<Pick<ComponentProps<typeof Svg>, 'width' | 'height'>>
> = {
  small: { width: 79, height: 87 },
  large: { width: 158, height: 178 },
};

// ─── Types ──────────────────────────────────────────────────────────────────────

type LoadingAnimationProps = Readonly<{
  size?: AnimatedIconSize;
  backgroundColor?: ViewStyle['backgroundColor'];
  withNavbarOffset?: boolean;
  topOffset?: number;
  bottomOffset?: number;
}> &
  Pick<ViewProps, 'testID'>;

type AnimatedIconSize = 'small' | 'large';

import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { webOnlyStyles } from '../../utils';
import { IconLink } from '../Icon';
import { BodyText } from '../Text';

export const QuantityStepper = (props: QuantityStepperProps) => {
  const {
    onQuantityIncrease,
    onQuantityDecrease,
    onClose,
    initialQuantity = 1,
    minQuantityReachedBehaviour = 'close',
    increaseQtyTestId = 'quantity-stepper-increase-qty',
    decreaseQtyTestId = 'quantity-stepper-decrease-qty',
    closeStepperTestId = 'quantity-stepper-close',
  } = props;

  // ─────────────────────────────────────────────────────────────────

  const [quantity, setQuantity] = useState(initialQuantity);

  // ─────────────────────────────────────────────────────────────────

  const increaseQuantity = useCallback(() => {
    setQuantity((currentQuantity) => {
      onQuantityIncrease?.(currentQuantity + 1);

      return currentQuantity + 1;
    });
  }, [onQuantityIncrease]);

  const decreaseQuantity = useCallback(() => {
    setQuantity((currentQuantity) => {
      if (currentQuantity > 1) {
        const newQuantity = currentQuantity - 1;

        onQuantityDecrease?.(newQuantity);

        return newQuantity;
      }

      if (minQuantityReachedBehaviour === 'close') {
        onClose?.();
      }

      return currentQuantity;
    });
  }, [onQuantityDecrease, onClose, minQuantityReachedBehaviour]);

  const wrapperStyle = [
    styles.quantityViewWrapper,
    webOnlyStyles({ userSelect: 'none', cursor: 'default' }),
  ];

  // ─────────────────────────────────────────────────────────────────

  return (
    <View style={wrapperStyle}>
      {minQuantityReachedBehaviour === 'close' ? (
        <IconLink
          name={quantity > 1 ? 'IconMinus' : 'IconClose'}
          palette="dark"
          iconSize={ICON_SIZE}
          onPress={decreaseQuantity}
          style={[styles.stepperControl, styles.stepperControlLeft]}
          testID={closeStepperTestId}
        />
      ) : null}

      {minQuantityReachedBehaviour === 'disable' ? (
        <IconLink
          name="IconMinus"
          palette="dark"
          iconSize={ICON_SIZE}
          disabled={quantity === 1}
          onPress={decreaseQuantity}
          style={[styles.stepperControl, styles.stepperControlLeft]}
          testID={decreaseQtyTestId}
        />
      ) : null}

      <BodyText style={styles.stepperControlQuantity} size={3}>
        {quantity}
      </BodyText>

      <IconLink
        name="IconPlus"
        palette="dark"
        iconSize={18}
        onPress={increaseQuantity}
        style={[styles.stepperControl, styles.stepperControlRight]}
        testID={increaseQtyTestId}
      />
    </View>
  );
};

// ─── CONSTANTS ─────────────────────────────────────────────────────────────────────

const ICON_SIZE = 18;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  quantityViewWrapper: {
    flex: 1,
    position: 'relative',
    zIndex: 100,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  stepperControlQuantity: {
    alignSelf: 'center',
    color: theme.colors.OATMEAL,
  },
  stepperControl: {
    width: 40,
    borderRadius: 0,
  },
  stepperControlLeft: {
    paddingLeft: theme.spacing['2'],
    paddingRight: theme.spacing['1'],
  },
  stepperControlRight: {
    paddingRight: theme.spacing['2'],
    paddingLeft: theme.spacing['1'],
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type QuantityStepperProps = Readonly<{
  initialQuantity?: number;
  minQuantityReachedBehaviour?: 'disable' | 'close';
  onClose?: () => void;
  increaseQtyTestId?: string;
  decreaseQtyTestId?: string;
  closeStepperTestId?: string;
  onQuantityIncrease?: (quantity: number) => void;
  onQuantityDecrease?: (quantity: number) => void;
}>;

import React from 'react';

import { FadeView } from '../FadeView';
import { Options } from './Select.options';
import type { SelectContentProps } from './Select.types';

export const SelectContent = (props: SelectContentProps) => {
  const {
    selectId,
    measuredViewStyle,
    opened,
    contentRef,
    value,
    options,
    accessibilityLabel,
    onSelect,
    onLayout,
  } = props;

  return (
    <FadeView show={opened} duration={100}>
      <Options
        ref={contentRef}
        opened={opened}
        selected={value}
        options={options}
        style={measuredViewStyle}
        nativeID={selectId}
        accessibilityLabel={accessibilityLabel}
        onSelect={onSelect}
        onLayout={onLayout}
      />
    </FadeView>
  );
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoyaltyPointHistoryQueryVariables = Types.Exact<{
  page: Types.Scalars['Int']['input'];
  take: Types.Scalars['Int']['input'];
}>;

export type LoyaltyPointHistoryQuery = {
  readonly __typename?: 'Query';
  readonly pointHistoryForUser:
    | {
        readonly __typename?: 'PointHistoryForUserError';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename?: 'PointHistoryForUserSuccess';
        readonly pointHistoryEntries: ReadonlyArray<{
          readonly __typename?: 'PointHistoryEntry';
          readonly id: string;
          readonly date: string;
          readonly type: Types.PointHistoryEntryType | null;
          readonly value: number;
          readonly source: Types.PointIssuanceSource | null;
        }>;
        readonly pointExpirationEntries: ReadonlyArray<{
          readonly __typename?: 'PointExpirationEntry';
          readonly id: number;
          readonly amountExpiring: number;
          readonly numOfDaysUntilExpiration: number;
        }>;
        readonly pagination: {
          readonly __typename?: 'Pagination';
          readonly page: number;
          readonly take: number;
          readonly total: number;
          readonly last: number;
        };
      };
};

export const LoyaltyPointHistoryDocument = gql`
  query LoyaltyPointHistory($page: Int!, $take: Int!) {
    pointHistoryForUser(page: $page, take: $take) {
      ... on PointHistoryForUserSuccess {
        pointHistoryEntries {
          id
          date
          type
          value
          source
        }
        pointExpirationEntries {
          id
          amountExpiring
          numOfDaysUntilExpiration
        }
        pagination {
          page
          take
          total
          last
        }
      }
      ... on PointHistoryForUserError {
        message
        status
      }
    }
  }
`;

export function useLoyaltyPointHistoryQuery(
  options: Omit<Urql.UseQueryArgs<LoyaltyPointHistoryQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    LoyaltyPointHistoryQuery,
    LoyaltyPointHistoryQueryVariables
  >({ query: LoyaltyPointHistoryDocument, ...options });
}

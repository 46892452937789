import { reCaptchaModel } from './reCaptcha.model';

/**
 * State machine for synchronizing callback-based ReCaptcha component events
 */
export const createReCaptchaMachine = (
  options: CreateReCaptchaMachineOptions,
) =>
  reCaptchaModel.createMachine({
    predictableActionArguments: true, // https://xstate.js.org/docs/guides/actions.html
    context: reCaptchaModel.initialContext,
    initial: options.isEnabled ? 'loading' : 'disabled',
    states: {
      loading: {
        on: {
          LOAD: 'ready',
        },
      },
      ready: {
        on: {
          EXECUTE: 'executing',
        },
      },
      executing: {
        on: {
          VERIFY: 'ready',
          ERROR: 'ready',
          RESET: 'ready',
        },
      },
      disabled: {},
    },
  });

// ─── Types ───────────────────────────────────────────────────────────────────

type CreateReCaptchaMachineOptions = {
  /**
   * Determines if Re-Captcha is enabled in the app.
   */
  isEnabled: boolean;
};

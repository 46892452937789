import React from 'react';
import type { NavigationProp } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';

import type { AppStackParamList } from '../../navigation/AppNavigation.props';

export const useRateOrderNavigation = () => {
  const navigation = useNavigation<NavigationProp<AppStackParamList>>();

  const dismissRateOrder = React.useCallback(() => {
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.getParent()?.goBack();
  }, [navigation]);

  return React.useMemo(
    () => ({
      dismissRateOrder,
    }),
    [dismissRateOrder],
  );
};

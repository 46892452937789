import type { ComponentProps } from 'react';
import { useMemo } from 'react';
import {
  useAnimatedStyle,
  useDerivedValue,
  withSequence,
  withTiming,
} from 'react-native-reanimated';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../hooks';
import type { CloudinaryTransformConfig } from '../Image';
import type { BodyText } from '../Text';
import { styles } from './IngredientCard.styles';

// ────────────────────────────────────────────────────────────────────────────────

export const useWrapperAnimatedStyle = (
  props: UseIngredientCardWrapperStyleProps,
) => {
  const { cardAnimation } = props;

  // ────────────────────────────────────────────────────────────────────

  const highlightShake = useDerivedValue(() => {
    if (cardAnimation === 'shake') {
      return withSequence(
        withTiming(2, { duration: theme.transitions.base / 2.7 }),
        withTiming(-2, { duration: theme.transitions.base / 2.7 }),
        withTiming(0, { duration: theme.transitions.base / 3 }),
      );
    }

    return withTiming(0, { duration: theme.transitions.base });
  }, [cardAnimation]);

  const highlightPulse = useDerivedValue(() => {
    if (cardAnimation === 'pulse') {
      return withTiming(1.07, { duration: theme.transitions.base });
    }

    return withTiming(1, { duration: theme.transitions.base });
  }, [cardAnimation]);

  // ────────────────────────────────────────────────────────────────────

  return useAnimatedStyle(() => {
    return {
      transform: [
        { scale: highlightPulse.value },
        { rotate: `${highlightShake.value}deg` },
      ],
    };
  });
};

export const useCardStyles = () => {
  const { match } = useResponsive();

  return [styles.wrapper, match([styles.wrapperXS, styles.wrapperSM])];
};

// ────────────────────────────────────────────────────────────────────────────────

export const useControlsStyle = (props: UseControlsStyleProps) => {
  const { isSelected } = props;
  const { match } = useResponsive();

  const controlsWrapperStyle = [
    styles.controlsWrapper,
    match([styles.controlsWrapperXS, styles.controlsWrapperSM]),
  ];
  const controlsBarStyle = [
    styles.controlsBar,
    match([styles.controlBarXS, styles.controlBarSM]),
  ];
  const controlsBarBtnStyle = [
    styles.controlsBarBtn,
    isSelected ? styles.controlsBarBtnSelected : undefined,
    match([styles.controlsBarBtnXS, styles.controlsBarBtnSM]),
  ];

  // ────────────────────────────────────────────────────────────────────

  const decreaseQuantityBtnStyle = [
    controlsBarBtnStyle,
    styles.decreaseQuantityBtn,
  ];
  const decreaseQuantityIconStyle = styles.decreaseQuantityIcon;

  // ────────────────────────────────────────────────────────────────────

  const countStyle = [controlsBarBtnStyle, styles.count];
  const countTextStyle = styles.countText;

  return {
    controlsWrapperStyle,
    controlsBarStyle,
    decreaseQuantityBtnStyle,
    decreaseQuantityIconStyle,
    countStyle,
    countTextStyle,
  };
};

// ────────────────────────────────────────────────────────────────────────────────

export const useImgStyle = (props: UseImgStyleProps) => {
  const { isUnavailable } = props;
  const { match } = useResponsive();

  const imageWidth = match([IMG_WIDTH_MOBILE, IMG_WIDTH_TABLET]);
  const imageHeight = match([IMG_HEIGHT_MOBILE, IMG_HEIGHT_TABLET]);

  const imgCloudinaryConfig = useMemo<CloudinaryTransformConfig>(
    () => ({ crop: 'scale', width: imageWidth }),
    [imageWidth],
  );

  const imgStyle = [
    useStyle(
      () => ({
        width: imageWidth,
        height: imageHeight,
        maxWidth: '100%',
      }),
      [imageWidth, imageHeight],
    ),
    isUnavailable && styles.imgUnavailable,
  ];

  return { imgStyle, imgCloudinaryConfig };
};

// ────────────────────────────────────────────────────────────────────────────────

export const useTitleStyle = () => {
  const { minWidth } = useResponsive();

  const titleWrapperResponsiveStyle = useStyle(
    () => ({ height: minWidth.isSM ? 32 : 28 }),
    [minWidth.isSM],
  );
  const titleWrapperStyle = [styles.titleWrapper, titleWrapperResponsiveStyle];
  const titleStyle = [
    styles.title,
    minWidth.isSM ? styles.titleTablet : styles.titleMobile,
  ];
  const titleSize: IngredientCardTitleSize = minWidth.isSM ? 4 : 5;

  return { titleWrapperStyle, titleStyle, titleSize };
};

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

const IMG_WIDTH_MOBILE = 70;
const IMG_HEIGHT_MOBILE = 74;
const IMG_WIDTH_TABLET = 90;
const IMG_HEIGHT_TABLET = 107;

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type UseIngredientCardWrapperStyleProps = Readonly<{
  cardAnimation?: 'pulse' | 'shake' | null;
}>;

type UseControlsStyleProps = Readonly<{
  isSelected?: boolean;
}>;

type UseImgStyleProps = Readonly<{
  isUnavailable?: boolean;
}>;

type IngredientCardTitleSize = ComponentProps<typeof BodyText>['size'];

import React, { useCallback, useMemo } from 'react';

import { useResponsive } from '../../hooks';
import { DateAndHourPickerGroup, DateAndHourSelectors } from './components';
import { type DateAndHourPickerProps } from './DateAndHourPicker.types';
import { clampDate, getHoursOfDate } from './utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders available date and hour options pickers within the specified range.
 */
export const DateAndHourPicker = (props: DateAndHourPickerProps) => {
  const {
    fromDate,
    toDate,
    selectedDate,
    onChange,
    labels,
    'aria-label': ariaLabel,
  } = props;

  const { minWidth } = useResponsive();

  // ─── Helpers ─────────────────────────────────────────────────────────

  /**
   * On date change handler with extra guards.
   */
  const onDateChange = useCallback(
    (date: Date | undefined) => {
      if (!date) return;

      onChange(clampDate({ date, fromDate, toDate }));
    },
    [fromDate, onChange, toDate],
  );

  // ─── Derived Data ────────────────────────────────────────────────────

  /**
   * Returns a list of hour options available for the selected date.
   */
  const selectedDateHourOptions = useMemo(() => {
    return getHoursOfDate({
      date: selectedDate,
      minDate: fromDate,
      maxDate: toDate,
    });
  }, [fromDate, selectedDate, toDate]);

  // ─────────────────────────────────────────────────────────────────────

  if (minWidth.isSM) {
    return (
      <DateAndHourSelectors
        toDate={toDate}
        fromDate={fromDate}
        selectedDate={selectedDate}
        selectedDateHourOptions={selectedDateHourOptions}
        labels={labels}
        aria-label={ariaLabel}
        onChange={onDateChange}
      />
    );
  }

  return (
    <DateAndHourPickerGroup
      fromDate={fromDate}
      selectedDate={selectedDate}
      toDate={toDate}
      onChange={onDateChange}
      aria-label={ariaLabel}
      labels={labels}
    />
  );
};

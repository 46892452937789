import {
  interpolate,
  type SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';

// ─────────────────────────────────────────────────────────────────────────────
/**
 * Will add a gradient below the header that show up as the customer scrolls the view.
 */
export const useGradientAnimation = (props: AnimationProps) => {
  const { containerHeightSV, scrollOffsetSV } = props;

  return useAnimatedStyle(() => {
    return {
      opacity: scrollOffsetSV
        ? interpolate(
            scrollOffsetSV.value,
            [0, containerHeightSV.value],
            [0, 1],
          )
        : 0,
    };
  }, [scrollOffsetSV, containerHeightSV]);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type AnimationProps = {
  containerHeightSV: SharedValue<number>;
  scrollOffsetSV?: SharedValue<number>;
};

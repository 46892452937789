import { BREAKPOINTS } from '@garnish/constants';

import { useFluidSize } from '../useFluidSize';

export const useResponsiveGutterSpacing = (): number => {
  const minVal = BREAKPOINTS[0].GUTTER;
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const maxVal = BREAKPOINTS.at(-1).GUTTER;

  const gutter = useFluidSize()(minVal, maxVal as number);

  return gutter;
};

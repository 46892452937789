import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet } from 'react-native';
import { View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { HorizontalScrollRail } from '../../HorizontalScrollRail';

// ────────────────────────────────────────────────────────────────────

export const CollectionsRailBase = (props: CollectionsRailBaseProps) => {
  const { headerText, footerText, children, ...restProps } = props;

  const { minWidth, match } = useResponsive();

  const containerStyles = match([styles.container, styles.containerSM]);

  return (
    <View style={containerStyles}>
      <HorizontalScrollRail
        heading={headerText}
        footerText={footerText}
        showNavControls={minWidth.isSM}
        gap={0}
        itemVisiblePercentThreshold={30}
        {...restProps}
      >
        {children as readonly React.ReactNode[]}
      </HorizontalScrollRail>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingTop: theme.spacing['10'],
  },
  containerSM: {
    paddingTop: theme.spacing['16'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

export type CollectionsRailBaseProps = Readonly<{
  headerText: string;
  footerText?: string;
}> &
  Partial<ComponentProps<typeof HorizontalScrollRail>>;

import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { measureLayoutPosition, withReducedScrollMotion } from '../../utils';
import { noop } from '../../utils/noop';
import { BoundScrollContext } from '../BoundScrollView/BoundScrollView.context';
import type { ScrollEvent } from '../BoundScrollView/BoundScrollView.types';
import type {
  JumpNavigationContextType,
  JumpNavigationProps,
  JumpTarget,
  JumpTargets,
} from './JumpNavigation.types';

// Clicking a JumpOption scrolls the user to just before the JumpTarget, these 10px helps with detecting it.
const EAGER_DETECTION_OFFSET = 10;

export const JumpNavigationContext = createContext<JumpNavigationContextType>({
  offset: 0,
  currentTarget: '',
  hasJumpTargets: false,
  hasScrolledTargetsOnce: false,
  jumpTargets: { current: {} },
  setOffset: noop,
  setCurrentTarget: noop,
  registerJumpTarget: noop,
  unregisterJumpTarget: noop,
});

export const JumpNavigationProvider = (
  props: JumpNavigationProps,
): React.ReactElement => {
  const {
    jumpTargetIds,
    children,
    withSafeAreaOffset,
    isNativePlatformOffsetEnabled = false,
  } = props;
  const firstJumpTarget = jumpTargetIds[0];

  // Extra scroll due to safe area.
  const { top: topSafeAreaInset } = useSafeAreaInsets();
  const extraScrollOffset = withSafeAreaOffset ? -topSafeAreaInset : 0;

  // Scroll View Reference
  const scrollPosition = useRef<number>(0);
  const scrollContext = useContext(BoundScrollContext);
  const { addScrollListener, removeScrollListener, scrollReference } =
    scrollContext;

  // Offset & Current Jump Target
  const [offset, setOffset] = useState(0);
  const [currentTarget, setCurrentTargetState] = useState('');

  // Jump Target Registration
  const jumpTargets = useRef<JumpTargets>({});
  const [hasJumpTargets, setHasJumpTargets] = useState(false);
  const [hasScrolledTargetsOnce, setHasScrolledTargetsOnce] = useState(false);
  const registerJumpTarget = React.useCallback(
    (id: string, section: JumpTarget) => {
      const isRegistered = Object.keys(jumpTargets.current).includes(id);

      if (isRegistered) return;
      // eslint-disable-next-line functional/immutable-data
      jumpTargets.current[id] = section;

      if (Object.keys(jumpTargets.current).length > 0) setHasJumpTargets(true);
    },
    [jumpTargets, setHasJumpTargets],
  );
  const unregisterJumpTarget = React.useCallback(
    (id: string) => {
      const isRegistered = Object.keys(jumpTargets).includes(id);

      if (!isRegistered) return;
      // eslint-disable-next-line functional/immutable-data, @typescript-eslint/no-dynamic-delete
      delete jumpTargets.current[id];

      if (Object.keys(jumpTargets.current).length === 0) {
        setHasJumpTargets(false);
      }
    },
    [jumpTargets, setHasJumpTargets],
  );

  // Jump Target Detection
  const detectCurrentJumpTarget = React.useCallback(
    async (userPosition: number) => {
      const references = Object.keys(jumpTargets.current).map((id) => {
        return jumpTargets.current[id];
      });

      const measurements = await Promise.all(
        references.map(async (reference) => {
          if (!scrollReference?.current || !reference?.current) return 0;
          const { y } = await measureLayoutPosition(scrollReference, reference);

          return y;
        }),
      );
      const targets = Object.keys(jumpTargets.current).map((id, index) => ({
        id,
        position: measurements[index],
      }));
      // eslint-disable-next-line functional/immutable-data
      const sortedTargets = targets.sort((t1, t2) => t1.position - t2.position);

      if (Platform.OS === 'web') {
        // eslint-disable-next-line functional/no-loop-statements
        for (
          let targetIndex = 0;
          targetIndex < sortedTargets.length;
          ++targetIndex
        ) {
          const thisTarget = sortedTargets[targetIndex];
          const nextTarget = sortedTargets[targetIndex + 1];
          const hasScrolledPastCurrentTarget =
            thisTarget.position < EAGER_DETECTION_OFFSET;
          const hasNotScrolledPastNextTarget =
            !nextTarget || nextTarget.position > EAGER_DETECTION_OFFSET;

          if (hasScrolledPastCurrentTarget && hasNotScrolledPastNextTarget) {
            setHasScrolledTargetsOnce(true);
            setCurrentTargetState(thisTarget.id);

            return;
          }
        }

        return;
      }

      // eslint-disable-next-line functional/no-loop-statements
      for (
        let targetIndex = 0;
        targetIndex < sortedTargets.length;
        ++targetIndex
      ) {
        const thisTarget = sortedTargets[targetIndex];
        const nextTarget = sortedTargets[targetIndex + 1];
        const hasScrolledPastCurrentTarget =
          userPosition > thisTarget.position - EAGER_DETECTION_OFFSET;
        const hasNotScrolledPastNextTarget =
          !nextTarget ||
          userPosition < nextTarget.position - EAGER_DETECTION_OFFSET;

        if (hasScrolledPastCurrentTarget && hasNotScrolledPastNextTarget) {
          setCurrentTargetState(thisTarget.id);

          return;
        }
      }
    },
    [
      scrollReference,
      jumpTargets,
      setCurrentTargetState,
      setHasScrolledTargetsOnce,
    ],
  );

  // Scroll Monitoring
  const scrollListener = React.useMemo(
    () => ({
      source: 'JumpNavigation',
      async onScroll(event: ScrollEvent) {
        const userPosition = event.nativeEvent.contentOffset.y;

        // eslint-disable-next-line functional/immutable-data
        scrollPosition.current = userPosition;
        await detectCurrentJumpTarget(userPosition);
      },
    }),
    [scrollPosition, detectCurrentJumpTarget],
  );

  useEffect(() => {
    addScrollListener(scrollListener);

    return () => {
      removeScrollListener(scrollListener);
    };
  }, [scrollListener, addScrollListener, removeScrollListener]);

  // Auto select first target.
  useEffect(() => {
    if (!currentTarget && !Number.isNaN(Number(firstJumpTarget))) {
      setCurrentTargetState(firstJumpTarget);
    }
  }, [currentTarget, firstJumpTarget, setCurrentTargetState]);

  // Auto scrolling when current target changes.
  const setCurrentTarget = React.useCallback(
    (targetId: string) => {
      const target = jumpTargets.current[targetId];

      if (!target || !scrollReference) {
        setCurrentTargetState(targetId);

        return;
      }

      void measureLayoutPosition(scrollReference, target).then(
        ({ y: elementPosition }) => {
          const userPosition =
            scrollPosition.current < offset ? -offset : scrollPosition.current;
          const userOffset = isNativePlatformOffsetEnabled
            ? userPosition
            : getUserOffset(userPosition);
          const scrollOffset =
            validNumberOrZero(userOffset) +
            validNumberOrZero(elementPosition) +
            validNumberOrZero(extraScrollOffset);
          const scrollOptions = { y: scrollOffset };
          const scrollToTarget = scrollReference?.current?.scrollTo;

          void withReducedScrollMotion(scrollOptions).then(scrollToTarget);
        },
      );
    },
    [
      isNativePlatformOffsetEnabled,
      scrollReference,
      offset,
      jumpTargets,
      extraScrollOffset,
      setCurrentTargetState,
    ],
  );

  return (
    <JumpNavigationContext.Provider
      value={React.useMemo(
        () => ({
          offset,
          currentTarget,
          hasJumpTargets,
          hasScrolledTargetsOnce,
          jumpTargets,
          setOffset,
          setCurrentTarget,
          registerJumpTarget,
          unregisterJumpTarget,
        }),
        [
          offset,
          currentTarget,
          hasJumpTargets,
          hasScrolledTargetsOnce,
          jumpTargets,
          setOffset,
          setCurrentTarget,
          registerJumpTarget,
          unregisterJumpTarget,
        ],
      )}
    >
      {children}
    </JumpNavigationContext.Provider>
  );
};

// ─── Utils ───────────────────────────────────────────────────────────────────

function validNumberOrZero(possibleNumber: string | number) {
  const validNumberCandidate = Number(possibleNumber);

  if (Number.isNaN(validNumberCandidate)) return 0;

  return validNumberCandidate;
}

/**
 * The behavior of programatically detecting user scroll position
 * and scrolling differs per platform, if we use the user position on native
 * platforms it will over scroll, so we return `0` instead.
 */
function getUserOffset(userPosition: number) {
  return Platform.OS === 'web' ? userPosition : 0;
}

// ─── Hooks ───────────────────────────────────────────────────────────────────

export const useJumpNavigationCtx = () => {
  const context = useContext(JumpNavigationContext);

  if (context === undefined) {
    throw new Error(
      'useJumpNavigationCtx must be used within a <JumpNavigationProvider>',
    );
  }

  return context;
};

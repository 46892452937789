import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useSweetpassSubscribe, useSweetpassTier } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import type { AppStackParamList } from '../../navigation/AppNavigation.props';
import { useNavigateToMainTabs } from '../../navigation/hooks/useNavigateToMainTabs';

/**
 * Flow for upgrading to sweetpass plus.
 * We call the subscribe mutation with planId and paymentId.
 * If it fails, we track the failure and show an error banner.
 * If it succeeds, we track the success and refetch the sweetpass tier.
 * If we started the flow from the bag, we return to the bag.
 * Otherwise we show the celebration modal.
 */
export const useSubscribeToSweetpassPlus = (
  props: SubscribeToSweetpassPlusProps,
) => {
  const {
    planId,
    paymentId,
    campaignId,
    redirectToAfterUpgrade,
    hasFreeTrial,
    subscriptionSwitchSuccessBanner = '',
    setHasCustomerBeenUpgraded,
  } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const navigateToMainTabs = useNavigateToMainTabs();
  const replaceWithBag = useReplaceWithBag();
  const { t } = useLocalizationContext();
  const { track } = useTelemetry();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const shouldRedirectToBagAfterUpgrade = redirectToAfterUpgrade === 'bag';
  const shouldRedirectToAccountMembershipAfterUpgrade =
    redirectToAfterUpgrade === 'account-membership';

  // ─── Queries ─────────────────────────────────────────────────────────

  const { refetchSweetpassTier } = useSweetpassTier();
  const { subscribe } = useSweetpassSubscribe();

  return useCallback(async () => {
    if (!planId) {
      return;
    }

    const { isSuccess } = await subscribe({ planId, paymentId, campaignId });

    if (!isSuccess) {
      track('sweetpass_account.upgrade.failure', {});
      addNoticeBanner({
        id: 'sweetpass-subscription-error',
        text: t('sweetpass.checkout.join-sweetpass-plus.notice.failure'),
        palette: 'caution',
      });

      return;
    }

    track('sweetpass_account.upgrade.success', {
      isFreeTrial: hasFreeTrial,
      isMonthlySubscription: true,
      isAnnualSubscription: false,
    });
    refetchSweetpassTier({ requestPolicy: 'cache-and-network' });

    if (shouldRedirectToBagAfterUpgrade) {
      replaceWithBag();

      return;
    }

    if (shouldRedirectToAccountMembershipAfterUpgrade) {
      await navigateToMainTabs('AccountTab', { screen: 'SweetpassMembership' });
      addNoticeBanner({
        text: subscriptionSwitchSuccessBanner,
        palette: 'success',
      });
    }

    setHasCustomerBeenUpgraded(true);
  }, [
    planId,
    paymentId,
    campaignId,
    shouldRedirectToBagAfterUpgrade,
    shouldRedirectToAccountMembershipAfterUpgrade,
    hasFreeTrial,
    subscriptionSwitchSuccessBanner,
    t,
    track,
    navigateToMainTabs,
    refetchSweetpassTier,
    setHasCustomerBeenUpgraded,
    addNoticeBanner,
    replaceWithBag,
    subscribe,
  ]);
};

// ─── Hooks ─────────────────────────────────────────────────────────────

/**
 * If the upgrade flow was triggered from the bag, we return to it by skipping
 * the upgrade modal, and we use `replace` here because we don't  want to keep
 * the current screen in history since it should be unavailable post upgrade.
 */
const useReplaceWithBag = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<AppStackParamList>>();

  return useCallback(() => {
    navigation.replace('Modal', {
      screen: 'Bag',
      params: { referrer: 'sweetpass-upgrade' },
    });
  }, [navigation]);
};

// ─── Types ─────────────────────────────────────────────────────────────

type SubscriptionParameters = Readonly<{
  planId?: string;
  paymentId?: string;
  campaignId?: string;
}>;

type SubscribeToSweetpassPlusProps = SubscriptionParameters &
  Readonly<{
    hasFreeTrial: boolean;
    subscriptionSwitchSuccessBanner?: string;
    redirectToAfterUpgrade?: 'bag' | 'order-status' | 'account-membership';
    setHasCustomerBeenUpgraded: (toggle: boolean) => void;
  }>;

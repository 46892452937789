import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { type RouteProp, useRoute } from '@react-navigation/native';
import {
  Container,
  HostedFrame,
  type HostedFrameRef,
  theme,
  useResponsive,
} from '@sg/garnish';

import {
  useIsLoggedIn,
  useIsLoggingIn,
  useSendLoginToAuthMachine,
} from '@order/AuthMachine';
import { getAzureAuthEmbeddedFrameUrl } from '@order/AzureAuth';
import { AppFooter, LoadingAnimation } from '@order/components';
import { useTrackEventEffect } from '@order/Telemetry';

import { useCloseActiveScreensOnAuthStatusChange } from '../../../../navigation';
import type { AuthStackParamList } from '../../../../navigation/AppNavigation.props';
import { JoinOrSignInEmailStepHeader } from '../JoinOrSignInEmailStepHeader';
import { JoinOrSignInErrorView } from '../JoinOrSignInErrorView';
import { JoinOrSignInForm } from '../JoinOrSignInForm';
import { JoinOrSignInLoadingPlaceholder } from '../JoinOrSignInLoadingPlaceholder';
import { JoinOrSignInVerificationStepFooter } from '../JoinOrSignInVerificationStepFooter';
import { JoinOrSignInVerificationStepHeader } from '../JoinOrSignInVerificationStepHeader';
import { useJoinOrSignScreenHeaderButtons } from './hooks';
import { useJoinOrSignIn } from './state';

// ─────────────────────────────────────────────────────────────────────────────

export const JoinOrSignInScreenContentEmbeddedFrameFlow = () => {
  const { match } = useResponsive();
  const isLoggedIn = useIsLoggedIn();
  const isLoggingIn = useIsLoggingIn();
  const sendLoginAction = useSendLoginToAuthMachine();
  const closeActiveScreensOnAuthStatusChange =
    useCloseActiveScreensOnAuthStatusChange();
  const { params: routeParams = {} } =
    useRoute<RouteProp<AuthStackParamList, 'JoinOrSignIn'>>();

  const { minWidth } = useResponsive();

  // ─── Local State ─────────────────────────────────────────────────────

  const [frameHeight, setFrameHeight] = useState(0);

  const {
    states,
    context,
    submitAccountData,
    handleIncomingMessage,
    restartAuthenticationFlow,
  } = useJoinOrSignIn({
    onFrameHeightChange: setFrameHeight,
  });

  const {
    isLoadingEmailAddress,
    isEmailAddressView,
    isLoadingVerificationCode,
    isVerificationCodeView,
    isFillAccountDataView,
    isSigningIn,
    isCreatingAccount,
    isAuthenticationFailed,
    shouldRedirect,
  } = states;

  const { email } = context;

  const isLoading =
    isLoadingEmailAddress || isLoadingVerificationCode || isCreatingAccount;
  const isRedirecting = isLoggedIn && shouldRedirect;
  const isFetchingData = isSigningIn || isLoggingIn;
  const hasFailedToFetchAccountData =
    shouldRedirect && !isLoggingIn && !isLoggedIn;
  const hasError = isAuthenticationFailed || hasFailedToFetchAccountData;

  // ─── Hosted Frame ────────────────────────────────────────────────────

  const frameRef = useRef<HostedFrameRef>(null);

  /**
   * Sends a post-message to the hosted frame to trigger resend code button
   */
  const resendCode = useCallback(() => {
    frameRef.current?.postMessage?.(
      JSON.stringify({ type: 'SEND_NEW_VERIFICATION_CODE' }),
    );
  }, []);

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = match([styles.containerXS, styles.containerSM]);
  const scrollContainerStyles = match([undefined, styles.scrollContainer]);

  const frameContainerDynamicStyles = useStyle(() => {
    const shouldHide = frameHeight === 0;

    return {
      // NOTE: For web views to function properly on Android, their container must be at least 1x1 in size
      height: shouldHide ? 1 : frameHeight,
      opacity: shouldHide ? 0 : 1,
    };
  }, [frameHeight]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useJoinOrSignScreenHeaderButtons({
    isLoading: isRedirecting || isFetchingData || isCreatingAccount,
  });

  /**
   * Inform the auth machine that the user has successfully signed in.
   */
  useLayoutEffect(() => {
    if (!shouldRedirect) return;

    sendLoginAction();
  }, [sendLoginAction, shouldRedirect]);

  /**
   * Reset the initial state of navigation when changing the authentication status.
   */
  useLayoutEffect(() => {
    if (!shouldRedirect || !isLoggedIn) return;

    closeActiveScreensOnAuthStatusChange();
  }, [closeActiveScreensOnAuthStatusChange, isLoggedIn, shouldRedirect]);

  useTrackEventEffect({
    name: 'join-or-sign-in.view',
    payload: { referrer: routeParams.redirect },
  });

  // ─────────────────────────────────────────────────────────────────────

  if (isRedirecting) {
    return null;
  }

  if (hasError) {
    return (
      <JoinOrSignInErrorView
        onTryAgainButtonPress={restartAuthenticationFlow}
      />
    );
  }

  if (isFetchingData) {
    return (
      <Container
        style={styles.loadingContainer}
        wrapperStyle={styles.loadingOuterContainer}
      >
        <LoadingAnimation size="large" />
      </Container>
    );
  }

  if (isFillAccountDataView) {
    return (
      <JoinOrSignInForm
        email={email}
        isLoading={isCreatingAccount}
        onSubmit={submitAccountData}
        onSignInUsingDifferentAccount={restartAuthenticationFlow}
      />
    );
  }

  return (
    <KeyboardAvoidingView
      style={styles.outerContainer}
      behavior="height"
      // NOTE: On iOS we rely on `automaticallyAdjustKeyboardInsets` property.
      enabled={Platform.OS === 'android'}
      keyboardVerticalOffset={80}
    >
      <ScrollView
        contentContainerStyle={scrollContainerStyles}
        automaticallyAdjustKeyboardInsets
        bounces={false}
      >
        <Container size="medium" style={containerStyles}>
          {isEmailAddressView ? <JoinOrSignInEmailStepHeader /> : null}

          {isVerificationCodeView && email ? (
            <JoinOrSignInVerificationStepHeader email={email} />
          ) : null}

          <View style={styles.innerContainer}>
            {isLoading ? <JoinOrSignInLoadingPlaceholder /> : null}

            <View style={frameContainerDynamicStyles}>
              <HostedFrame
                ref={frameRef}
                frameId="azure-auth-frame"
                frameHeight={frameHeight}
                source={AUTH_FRAME_SRC}
                onMessage={handleIncomingMessage}
                scrolling="no"
                scalesPageToFit={false}
                setBuiltInZoomControls={false}
                allowedHosts={AUTH_FRAME_ALLOWED_HOSTS}
              />
            </View>

            {isVerificationCodeView ? (
              <JoinOrSignInVerificationStepFooter
                isLoading={isLoading}
                resendCode={resendCode}
              />
            ) : null}
          </View>
        </Container>

        {minWidth.isSM ? <AppFooter /> : null}
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

const AUTH_FRAME_SRC = {
  uri: getAzureAuthEmbeddedFrameUrl(),
};

/**
 * NOTE: Native-only
 *
 * To track data from all available hosts, we pass an empty array.
 */
const AUTH_FRAME_ALLOWED_HOSTS: string[] = [];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    minHeight: 200,
  },
  scrollContainer: {
    flex: 1,
  },
  loadingOuterContainer: {
    flex: 1,
  },
  innerContainer: {
    // NOTE: To prevent content overflow on Android, we apply a minimum height
    //       to the parent container due to its specific behavior with absolutely
    //       positioned items.
    minHeight: Platform.select({ android: 180 }),
  },
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerXS: {
    paddingVertical: theme.spacing['6'],
  },
  containerSM: {
    minHeight: 700,
    paddingVertical: theme.spacing['20'],
  },
});

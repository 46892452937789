import { useCallback } from 'react';

import { useTelemetry } from '@order/Telemetry';
import { convertPriceToFloat } from '@order/utils';

export const useTipTelemetry = () => {
  const { track } = useTelemetry();

  const monitorPaymentTipChanged = useCallback(
    (percentage: string, amount: number, subtotal: number) => {
      track('payment_tip.changed', {
        percentage,
        amount: convertPriceToFloat(amount),
        subtotal: convertPriceToFloat(subtotal),
      });
    },
    [track],
  );

  return {
    monitorPaymentTipChanged,
  };
};

import React from 'react';
import type { Pressable } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { BodyText, Icon, theme, useResponsive } from '@sg/garnish';

import { useHomeCardStyles } from '../../hooks';

export const HomeScreenCTAContent = (props: HomeCardProps) => {
  const { title, subtitle, palette = 'primary', testID } = props;

  const shouldUseSecondaryPalette = palette === 'secondary';
  const shouldUseKalePalette = palette === 'kale';

  const { match } = useResponsive();

  // ─── Card Styles ────────────────────────────────────────────────

  const cardStyles = useHomeCardStyles();
  const cardContainerStyles = [
    styles.card,
    match([styles.cardXS, styles.cardSM, styles.cardMD]),
    (shouldUseSecondaryPalette || shouldUseKalePalette) && styles.cardKale,
  ];

  // ────────────────────────────────────────────────────────────────

  return (
    <View style={cardContainerStyles} testID={testID}>
      <View style={styles.content}>
        <BodyText
          style={[
            styles.text,
            shouldUseSecondaryPalette && styles.textSecondary,
            shouldUseKalePalette && styles.textKale,
            cardStyles.titleStyle,
          ]}
          numberOfLines={match([2, 1])}
        >
          {title}
        </BodyText>

        {subtitle ? (
          <BodyText
            numberOfLines={match([2, 1])}
            underline={shouldUseKalePalette}
            style={[
              styles.text,
              shouldUseSecondaryPalette && styles.textSecondary,
              shouldUseKalePalette && styles.textKale,
              cardStyles.subtitleStyle,
            ]}
          >
            {subtitle}
          </BodyText>
        ) : null}
      </View>

      <Icon
        name="IconArrowRight"
        width={match([24, 24, 24, 48])}
        height={match([24, 24, 24, 48])}
        color={IconColor[palette]}
      />
    </View>
  );
};

// ─── Styles ───────────────────────────────────────────────────────

const styles = StyleSheet.create({
  card: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: theme.radius.large,
  },
  cardXS: {
    minHeight: 72,
    padding: theme.spacing['4'],
  },
  cardSM: {
    paddingVertical: theme.spacing['4'],
    paddingHorizontal: theme.spacing['6'],
  },
  cardMD: {
    minHeight: 100,
    padding: theme.spacing['6'],
  },
  content: {
    flex: 1,
    paddingRight: theme.spacing['2'],
  },
  text: { color: theme.colors.WHITE },
  textSecondary: {
    color: theme.colors.DARK_KALE,
  },
  textKale: {
    color: theme.colors.KALE,
  },
  cardKale: {
    borderColor: theme.colors.KALE,
    borderWidth: 1,
  },
});

const IconColor = {
  secondary: theme.colors.DARK_KALE,
  kale: theme.colors.KALE,
  primary: theme.colors.WHITE,
};

// ─── Types ────────────────────────────────────────────────────────

type HomeCardProps = Readonly<{
  title: string;
  subtitle?: string;
  palette?: 'primary' | 'secondary' | 'kale';
}> &
  Pick<React.ComponentProps<typeof Pressable>, 'testID'>;

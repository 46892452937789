import { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import type { NotFoundScreenProps } from '../../../../navigation/AppNavigation.props';
import { redirectsMapping } from '../../../../navigation/AppNavigation.redirects';

// Here we handle 301 redirects using the redirects mapping.
export const usePermanentRedirects = ({
  navigation,
  route,
}: NotFoundScreenProps) => {
  const redirectTo = route.path ? redirectsMapping[route.path] : null;

  useFocusEffect(
    useCallback(() => {
      if (redirectTo) {
        navigation.replace(redirectTo.screen, redirectTo.params);
      }
    }, [redirectTo, navigation]),
  );

  return Boolean(redirectTo);
};

import type { ReactElement } from 'react';
import React from 'react';

import { AlertIcon } from './subcomponents/AlertIcon';
import { Box } from './subcomponents/Box';
import { TextParent } from './subcomponents/TextParent';
import type { PaletteTypes, SizeTypes, TagAlertProps } from './TagAlert.types';
import { TagAlertPalette, TagAlertSize } from './TagAlert.types';

export const TagAlert = (props: TagAlertProps): ReactElement => {
  const {
    palette = TagAlertPalette.success as PaletteTypes,
    size = TagAlertSize.small as SizeTypes,
    children,
    testID,
  } = props;

  return (
    <Box testID={testID} palette={palette} size={size}>
      {/* @ts-expect-error TS(2322): Type '{ children: string | number | boolean | Reac... Remove this comment to see the full error message */}
      <AlertIcon testID={testID?.concat('.icon')} {...{ palette, size }}>
        {children}
      </AlertIcon>
      <TextParent testID={testID?.concat('.text')} {...{ palette, size }}>
        {children}
      </TextParent>
    </Box>
  );
};

import React, { useMemo } from 'react';
import type { Control } from 'react-hook-form';
import { Form, TextLinkifyTags } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { type SweetpassFormData } from '../SweetpassCheckoutForm/types';

export const SweetpassCheckoutCheckbox = (
  props: SweetpassCheckoutCheckboxProps,
) => {
  const { termsAndConditions, control } = props;

  const { t } = useLocalizationContext();

  const links: React.ComponentProps<typeof TextLinkifyTags>['links'] = useMemo(
    () => [
      {
        tag: 'sweetpassProgramAgreement',
        description: t('sweetpass.checkout.terms.sweetpass-program-agreement'),
        href: 'https://www.sweetgreen.com/sweetpass-program-agreement',
        newTab: true,
        underline: true,
      },
      {
        tag: 'hello@sweetgreen.com',
        description: 'hello@sweetgreen.com',
        href: 'mailto:hello@sweetgreen.com',
        newTab: true,
        underline: true,
      },
    ],
    [t],
  );

  return (
    <Form.Checkbox
      testID="sweetpass.checkout.accept"
      control={control}
      name="hasAcceptedTerms"
      alignment="start"
      isBoxed
    >
      <TextLinkifyTags links={links} palette="kale" sizeMatch={['16']}>
        {termsAndConditions}
      </TextLinkifyTags>
    </Form.Checkbox>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassCheckoutCheckboxProps = Readonly<{
  control: Control<SweetpassFormData>;
  termsAndConditions: string;
}>;

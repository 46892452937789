/* istanbul ignore file */

import { useCallback } from 'react';

import type { PartialLineItem, PartialProduct } from '@order/graphql';
import { telemetryProductFromInput, useTelemetry } from '@order/Telemetry';

// ────────────────────────────────────────────────────────────────────────────────

export const useBagLineItemsTelemetry = (
  props: UseBagLineItemsTelemetryProps,
) => {
  const { restaurantSlug } = props;

  const { track } = useTelemetry();

  // ─── HELPERS ─────────────────────────────────────────────────────

  const trackNavigateToEditPdpEvent = useCallback(
    (product: PartialProduct) => {
      track('pdp.view', {
        entryPoint: 'bag',
        selectedProduct: telemetryProductFromInput({
          ...product,
          location: restaurantSlug,
        }),
      });
    },
    [restaurantSlug, track],
  );

  const trackRemoveLineItemEvent = useCallback(
    (product: PartialProduct) => {
      track('bag.remove', {
        selectedProduct: telemetryProductFromInput({
          ...product,
          location: restaurantSlug,
        }),
      });
    },
    [restaurantSlug, track],
  );

  const trackModifyLineItemQuantityEvent = useCallback(
    (
      lineItem: Omit<PartialLineItem, 'ingredients'>,
      selectedQuantity: number,
    ) => {
      track('bag.adjust_quantity', {
        selectedProduct: telemetryProductFromInput({
          ...lineItem.product,
          cost: lineItem.cost,
          quantity: selectedQuantity,
          name: lineItem.customName ?? lineItem.product?.name,
        }),
      });
    },
    [track],
  );

  // ─────────────────────────────────────────────────────────────────

  return {
    trackNavigateToEditPdpEvent,
    trackRemoveLineItemEvent,
    trackModifyLineItemQuantityEvent,
  };
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type UseBagLineItemsTelemetryProps = Readonly<{
  restaurantSlug: string;
}>;

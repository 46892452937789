import { useCallback } from 'react';
import { addHours, isPast, setMilliseconds } from 'date-fns';

import { notifee } from '@order/integrations';
import { useLocalizationContext } from '@order/Localization';
import { ignoreTimezone } from '@order/utils';

import {
  ORDER_FEEDBACK_NOTIFICATION_ANDROID_ICON_NAME,
  ORDER_FEEDBACK_NOTIFICATION_PRESS_ACTION_ID,
  ORDER_FEEDBACK_NOTIFICATION_TIMESTAMP_TYPE,
  ORDER_FEEDBACK_NOTIFICATIONS_CHANNEL_ID,
} from './useOrderFeedbackNotifications.constants';
import {
  checkIfOrderFeedbackNotificationsEnabled,
  getOrderFeedbackNotificationId,
} from './useOrderFeedbackNotifications.utils';

// ─────────────────────────────────────────────────────────────────────────────

export const useOrderFeedbackNotifications = () => {
  const { t } = useLocalizationContext();

  /**
   * Creates a local push notification (native only) to collect feedback from the customer,
   * after three hours of the order's wanted time.
   */
  const scheduleOrderFeedbackNotification =
    useCallback<ScheduleOrderFeedbackNotification>(
      async (props) => {
        if (!checkIfOrderFeedbackNotificationsEnabled()) return;

        const { orderId, wantedTime } = props;

        const orderWantedTime = ignoreTimezone(wantedTime);
        const hasValidOrderWantedTime = orderWantedTime !== undefined;
        const shouldCreateNotification =
          hasValidOrderWantedTime && !isPast(orderWantedTime);

        if (!shouldCreateNotification) return;

        const id = getOrderFeedbackNotificationId(orderId);
        const triggerTimestamp = setMilliseconds(
          addHours(orderWantedTime, 3),
          0,
        ).getTime();
        const title = t('checkout.order-feedback.title');
        const body = t('checkout.order-feedback.body');

        await notifee?.createTriggerNotification(
          {
            id,
            title,
            body,
            android: {
              channelId: ORDER_FEEDBACK_NOTIFICATIONS_CHANNEL_ID,
              smallIcon: ORDER_FEEDBACK_NOTIFICATION_ANDROID_ICON_NAME,
              pressAction: { id: ORDER_FEEDBACK_NOTIFICATION_PRESS_ACTION_ID },
            },
            data: { orderId },
          },
          {
            type: ORDER_FEEDBACK_NOTIFICATION_TIMESTAMP_TYPE,
            timestamp: triggerTimestamp,
          },
        );
      },
      [t],
    );

  /**
   * Cancels the local push notification for order feedback, based on the order ID (native only).
   */
  const cancelScheduledOrderFeedbackNotification =
    useCallback<CancelOrderFeedbackNotification>(async (orderId: string) => {
      if (!checkIfOrderFeedbackNotificationsEnabled()) return;

      await notifee?.cancelTriggerNotification(
        getOrderFeedbackNotificationId(orderId),
      );
    }, []);

  // ─────────────────────────────────────────────────────────────────────

  return {
    scheduleOrderFeedbackNotification,
    cancelScheduledOrderFeedbackNotification,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ScheduleOrderFeedbackNotification = (
  input: Readonly<{
    orderId: string;
    wantedTime: string;
  }>,
) => Promise<void>;

type CancelOrderFeedbackNotification = (orderId: string) => Promise<void>;

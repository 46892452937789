import React from 'react';
import type { ViewStyle } from 'react-native';
import { View } from 'react-native';

import { Button as GarnishButton } from '../../Button';
import type { ButtonProps } from '../../Button/Button.types';
import type {
  CollapsibleChildrenProps,
  CollapsibleSummaryProps,
} from '../Collapsible.types';

type CollapsibleButtonProps = Readonly<{
  containerProps?: ViewStyle;
}> &
  ButtonProps;

type PropsType = CollapsibleButtonProps &
  CollapsibleSummaryProps &
  CollapsibleChildrenProps;

export const Button = (props: CollapsibleButtonProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { collapsed, onCollapse, containerProps, ...rest } = props as PropsType;

  return (
    <View style={containerProps}>
      <GarnishButton onPress={onCollapse} {...rest} />
    </View>
  );
};

import { Platform, StyleSheet } from 'react-native';
import { theme, TYPE_CONFIG } from '@garnish/constants';

import {
  FIELD_HEIGHT,
  FLOATING_LABEL_OFFSET,
  SUBMIT_BUTTON_SIZE,
} from './TextField.constants';

export const styles = StyleSheet.create({
  containerWithLabel: {
    paddingTop: FLOATING_LABEL_OFFSET,
  },

  // ─── WRAPPER ────────────────────────────────────────────────────────

  wrapper: {
    position: 'relative',
    width: '100%',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.GRAY,
  },

  multilineWrapper: {
    borderWidth: 1,
    borderColor: theme.colors.GRAY,
    borderRadius: theme.radius.small,
    padding: theme.spacing['2'],
  },

  wrapperActive: {
    borderBottomColor: theme.colors.TEXT_COLOR,
  },

  multilineWrapperActive: {
    borderTopColor: theme.colors.TEXT_COLOR,
    borderColor: theme.colors.TEXT_COLOR,
  },

  multilineWrapperDisabled: {
    borderColor: theme.colors.GRAY,
  },

  multilineWrapperInvalid: {
    borderColor: theme.colors.CAUTION,
  },

  wrapperInvalid: {
    borderBottomColor: theme.colors.CAUTION,
  },

  wrapperDisabled: {
    borderBottomColor: theme.colors.GRAY,

    // see: https://github.com/facebook/react-native/issues/24224
    // NOTE: On iOS, `borderStyle`s other than `solid` do not work properly.
    //       There are some hacky workarounds, but those add too much complexity.
    borderStyle: Platform.select({ default: 'dashed', ios: 'solid' }),

    // So we modify the `opacity` on `iOS` to make it appear disabled.
    opacity: Platform.select({ default: 1, ios: 0.6 }),
  },

  // ─── INPUT ──────────────────────────────────────────────────────────

  input: {
    minWidth: 0,
    flexShrink: 1,
    flexGrow: 1,
    borderWidth: 0,
    borderRadius: 0,
    height: FIELD_HEIGHT,
    color: theme.colors.TEXT_COLOR,
    ...TYPE_CONFIG.BODY['16'],

    // NOTE: On iOS, line height is calculated differently than on other platforms.
    //       We utilize the default value (by passing `undefined`), which
    //       appears to be the best one.
    lineHeight: Platform.select({
      ios: undefined,
      default: TYPE_CONFIG.BODY['16'].lineHeight,
    }),
    fontWeight: 'normal',
  },
  inputMultiline: {
    lineHeight: TYPE_CONFIG.BODY['16'].lineHeight,
  },

  inputPrependContainer: {
    justifyContent: 'center',
  },
  inputPrepend: {
    minWidth: 0,
    flexShrink: 1,
    color: theme.colors.TEXT_COLOR,
    ...TYPE_CONFIG.BODY['16'],
    fontWeight: 'normal',
  },

  // ─── LABEL ──────────────────────────────────────────────────────────

  labelWrapper: {
    position: 'absolute',
    top: '50%',
    alignSelf: 'flex-start',
    maxWidth: '100%',
  },

  // ─── CTA ────────────────────────────────────────────────────────────

  ctaWrapper: {
    flexShrink: 0,
    justifyContent: 'center',
    paddingLeft: theme.spacing['2'],
    ...(Platform.OS === 'web' && { userSelect: 'none' }),
  },
  textCtaWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  textCtaContent: {
    letterSpacing: 1,
    color: theme.colors.CHARCOAL,
    textTransform: 'uppercase',
  },
  submitButton: {
    width: SUBMIT_BUTTON_SIZE,
    height: SUBMIT_BUTTON_SIZE,
    paddingVertical: 0,
    paddingHorizontal: 0,
  },

  // ─── HELPER TEXT ────────────────────────────────────────────────────

  helperText: {
    color: theme.colors.CHARCOAL,
    paddingTop: theme.spacing['2'],
  },

  // ─── NOTICE ─────────────────────────────────────────────────────────

  noticeWrapper: {
    paddingTop: theme.spacing['2'],
    minHeight: theme.spacing['6'],
  },
});

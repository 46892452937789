import React from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import {
  AspectRatioImage,
  getContentfulImageUrl,
  theme,
  useResponsive,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardConfirmationGiftImage = (
  props: GiftCardConfirmationGiftImageProps,
) => {
  const { url, accessibilityLabel } = props;

  const { scale } = useWindowDimensions();
  const { minWidth } = useResponsive();

  const imgWidth = minWidth.isLG ? IMG_WIDTH_LG : IMG_WIDTH_XS;
  const imageUrl = getContentfulImageUrl(url, {
    w: scale === 1 ? imgWidth : imgWidth * 2,
  });

  return (
    <View style={styles.container}>
      <AspectRatioImage
        aria-label={accessibilityLabel}
        source={imageUrl}
        borderRadius={theme.radius.xlarge}
        aspectRatioY={4}
        aspectRatioX={3}
      />
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const IMG_WIDTH_XS = 310;
const IMG_WIDTH_LG = 360;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginBottom: theme.spacing['8'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardConfirmationGiftImageProps = {
  url: string;
  accessibilityLabel?: string;
};

import { defineMessages } from 'react-intl';

export const orderCancellationMessages = defineMessages({
  //
  // ─── Modal ───────────────────────────────────────────────────────────

  modalA11yLabel: {
    defaultMessage: 'Order cancelation modal',
    description: 'Order Cancellation | Modal | a11y label',
  },
  modalHeading: {
    defaultMessage: 'Cancel your order?',
    description: 'Order Cancellation | Modal | Heading',
  },
  modalBody: {
    defaultMessage:
      "As long as your order hasn't been made, we’re able to refund and cancel your order.\n\nAt this time, we are not able to reissue rewards applied to canceled orders. Do you want to proceed with the cancelation?",
    description: 'Order Cancellation | Modal | Body',
  },
  modalGoBackCtaLabel: {
    defaultMessage: 'Go back',
    description: 'Order Cancellation | Modal | "Go back" CTA label',
  },
  modalCancelOrderCtaLabel: {
    defaultMessage: 'Cancel order',
    description: 'Order Cancellation | Modal | "Cancel order" CTA label',
  },

  // ─── Banners ─────────────────────────────────────────────────────────

  bannerSuccessText: {
    defaultMessage:
      'Your order has been successfully cancelled. Your refund will post back to your card within 3-5 business days.',
    description: 'Order Cancellation | Banner | Success',
  },
  bannerSuccessButRefundFailedText: {
    defaultMessage:
      'Your order has been successfully cancelled, but your refund did not process correctly. Please reach out to our customer support team to issue a refund to your account.',
    description: 'Order Cancellation | Banner | "Refund failed" error',
  },
  bannerPendingCancellationText: {
    defaultMessage:
      "We're working to cancel your order. If the cancellation is successful, your refund will be processed to your card within 3-5 business days.",
    description: 'Order Cancellation | Banner | Pending cancelation',
  },
  bannerPendingCancellationFailedText: {
    defaultMessage:
      'Unfortunately, we were unable to cancel your order. Please contact customer support for further assistance.',
    description: 'Order Cancellation | Banner | Pending cancelation failed',
  },
  bannerGenericErrorText: {
    defaultMessage: 'We were unable to cancel your order. Please try again.',
    description: 'Order Cancellation | Banner | Generic error',
  },
  bannerErrorAlreadyPreparingText: {
    defaultMessage: `We're sorry, we're unable to cancel your order as our team is already preparing it. Our support team can be reached at "Contact Us" in the Account menu.`,
    description: 'Order Cancellation | Banner | "Already preparing" error',
  },
  bannerErrorCustomerSupportText: {
    defaultMessage:
      'We were unable to cancel your order. Please reach out to customer support.',
    description:
      'Order Cancellation | Banner | "Reach out to customer support" error',
  },
});

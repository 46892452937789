import React from 'react';
import { AddressTypes, ListActionSelect } from '@sg/garnish';

import { RestaurantListAction } from '@order/components';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { mapAvailableTimes } from '../../../Checkout.utils';
import { useCheckoutContext } from '../../../CheckoutProvider';
import { useFirstAvailableOption } from '../../../hooks';
import { CheckoutBlockVStack } from '../CheckoutBlockVStack';

export const ConnectedPickupOptions = () => {
  const context = useCheckoutContext();

  return <PickupOptions {...context} />;
};

export const PickupOptions = (
  props: Pick<
    ReturnType<typeof useCheckoutContext>,
    | 'orderId'
    | 'cartOrderType'
    | 'cart'
    | 'payload'
    | 'loadingAvailableWantedTimes'
    | 'checkoutFieldsValidation'
    | 'availableWantedTimes'
    | 'handleWantedTimeSelected'
    | 'handleDropoffLocationSelected'
    | 'resetCheckoutFieldValidation'
  >,
) => {
  const {
    orderId,
    cartOrderType,
    cart,
    payload,
    loadingAvailableWantedTimes,
    checkoutFieldsValidation,
    availableWantedTimes: availableWantedTimeOptions,
    handleWantedTimeSelected: setSelectedTime,
    handleDropoffLocationSelected: setDropoffLocation,
    resetCheckoutFieldValidation,
  } = props;
  const restaurant = cart?.restaurant;
  const { wantedTime: selectedTime, dropoffLocationId: dropoffLocation } =
    payload;

  const { t } = useLocalizationContext();
  const timeOptionsGroups = React.useMemo(
    () =>
      mapAvailableTimes({
        t,
        orderType: cartOrderType,
        times: availableWantedTimeOptions,
      }),
    [t, cartOrderType, availableWantedTimeOptions],
  );

  useFirstAvailableOption({
    timeOptionsGroups,
    selectedTime,
    setSelectedTime,
  });

  // ──────── Telemetry ─────────

  const { track } = useTelemetry();

  const onTimeChange = React.useCallback(
    (time: string | number) => {
      const delta = new Date(time).getTime() - new Date(selectedTime).getTime();

      track('checkout.time_changed', { timeChanged: delta / (60 * 1000) });

      setSelectedTime(String(time));
    },
    [selectedTime, setSelectedTime, track],
  );

  const onPickupSelectorChange = React.useCallback(
    (locationId: string | number) => {
      setDropoffLocation(locationId);
      resetCheckoutFieldValidation('dropoffLocationId');

      track('checkout.pickup_selector_changed', {
        pickupChanged: dropoffLocation,
      });
    },
    [dropoffLocation, resetCheckoutFieldValidation, setDropoffLocation, track],
  );

  // ────────────────────────────

  const availableDropOffLocations = React.useMemo(() => {
    return restaurant?.availableDropOffLocations ?? [];
  }, [restaurant?.availableDropOffLocations]);

  // ──────────────────────────── PICKUP SELECTOR ────────────────────────────

  const isDropOffLocationFieldValid =
    checkoutFieldsValidation.dropoffLocationId;
  const dropOffLocationFieldNotice = isDropOffLocationFieldValid
    ? undefined
    : t('checkout.required-field');

  // ────────────────────────────

  if (cartOrderType !== AddressTypes.pickup) return null;

  return (
    <CheckoutBlockVStack testID="checkout-block-pickup">
      <ListActionSelect
        iconName="IconClock"
        title={t('checkout.pickup-time')}
        modalTitle={t('checkout.pickup-time.title')}
        accessibilityLabel={t('checkout.pickup-time')}
        accessibilityLabelLeftGroup={t('checkout.pickup-time.day')}
        accessibilityLabelRightGroup={t('checkout.pickup-time.hour')}
        value={selectedTime}
        options={timeOptionsGroups}
        isLoading={loadingAvailableWantedTimes}
        onSelect={onTimeChange}
      />

      <RestaurantListAction
        orderId={orderId}
        restaurant={restaurant}
        orderType={cartOrderType}
      />

      {availableDropOffLocations.length > 1 ? (
        <ListActionSelect
          iconName="IconFloor"
          title={t('checkout.pickup-location')}
          modalTitle={t('checkout.pickup-location.title')}
          accessibilityLabel={t('checkout.pickup-location')}
          value={dropoffLocation ?? 'placeholder'}
          onSelect={onPickupSelectorChange}
          options={[
            {
              value: 'placeholder',
              label: t('checkout.pickup-location'),
            },
            ...availableDropOffLocations.map((location) => ({
              value: location.id,
              label: location.name,
            })),
          ]}
          notice={dropOffLocationFieldNotice}
          noticePalette="caution"
        />
      ) : null}
    </CheckoutBlockVStack>
  );
};

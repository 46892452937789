import React, { type ComponentProps } from 'react';
import { StyleSheet, Text } from 'react-native';
import { type TextLink, theme } from '@sg/garnish';

/**
 * Link component for the bag header, to be used inside of the header display text.
 */
export const BagHeaderLink = (props: ComponentProps<typeof TextLink>) => {
  return (
    <Text
      style={styles.linkingStyle}
      accessibilityRole="link"
      accessibilityLabel={props.accessibilityLabel}
      onPress={props.onPress}
    >
      {props.children}
    </Text>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  linkingStyle: {
    color: theme.colors.SPINACH,
    textDecorationLine: 'underline',
  },
});

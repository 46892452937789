import { assign } from 'xstate';

import { type CurrentCustomerContextData } from '../../AuthMachine.model';

// ─────────────────────────────────────────────────────────────────────────────

export const setCurrentCustomerData = assign((_context, event) => {
  const eventWithCustomerData = event as MaybeEventWithCustomerData;

  if (eventWithCustomerData.type !== 'done.invoke.fetchCustomer') return;

  const currentCustomer = eventWithCustomerData.data;

  return currentCustomer ? { currentCustomer } : {};
});

export const resetCurrentCustomerData = assign(() => {
  return { currentCustomer: null };
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MaybeEventWithCustomerData = Readonly<{
  type: string;
  data?: CurrentCustomerContextData;
}>;

import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';

/**
 * Goes back within the stack if possible, otherwise goes back to the main stack.
 */
export const useNavigateBack = () => {
  const navigation = useNavigation();

  return useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }

    navigation.getParent()?.goBack();
  }, [navigation]);
};

import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { useRemoveSweetpassPaymentMethodMutation } from './graphql/SweetpassPaymentMethod.generated';

export const useRemoveSweetpassPaymentMethod = () => {
  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  // ─── Mutations ───────────────────────────────────────────────────────

  const [
    { fetching: isRemovingSweetpassPaymentMethod },
    removeSweetpassPaymentMethodMutation,
  ] = useRemoveSweetpassPaymentMethodMutation();

  // ─── Callbacks ───────────────────────────────────────────────────────

  const removeSweetpassPaymentMethod = useCallback(
    async (props: RemoveSweetpassPaymentMethodProps) => {
      const { paymentMethodId, onSuccess } = props;

      const { data } = await removeSweetpassPaymentMethodMutation({
        paymentMethodId,
      });

      const removedSuccessfully =
        data?.removeSubscriptionPaymentMethod.__typename ===
        'DeleteSubscriptionPaymentMethodSuccess';

      if (!removedSuccessfully) {
        addNoticeBanner({
          text: t('sweetpass-membership.payment-methods.remove.error'),
          palette: 'caution',
        });

        return;
      }

      onSuccess();
    },
    [removeSweetpassPaymentMethodMutation, t, addNoticeBanner],
  );

  return {
    isRemovingSweetpassPaymentMethod,
    removeSweetpassPaymentMethod,
  };
};

// ─── Types ─────────────────────────────────────────────────────────────

type RemoveSweetpassPaymentMethodProps = Readonly<{
  paymentMethodId: string;
  onSuccess: () => void;
}>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SubmitPaymentMethodMutationVariables = Types.Exact<{
  input: Types.UpdatePaymentMethodInput;
}>;

export type SubmitPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly updatePaymentMethod: {
    readonly __typename: 'PaymentMethodCard';
    readonly id: string;
    readonly description: string | null;
    readonly nickname: string | null;
    readonly isDefault: boolean;
    readonly cardType: Types.CardType;
    readonly expirationState: Types.CardExpirationState;
  };
};

export const SubmitPaymentMethodDocument = gql`
  mutation submitPaymentMethod($input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(input: $input) {
      id
      description
      nickname
      isDefault
      cardType
      expirationState
      __typename
    }
  }
`;

export function useSubmitPaymentMethodMutation() {
  return Urql.useMutation<
    SubmitPaymentMethodMutation,
    SubmitPaymentMethodMutationVariables
  >(SubmitPaymentMethodDocument);
}

import React, { type ComponentProps, forwardRef } from 'react';
import { StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuTopDownCategoriesContainer = forwardRef<
  Animated.ScrollView,
  MenuTopDownCategoriesContainerProps
>((props, ref) => {
  const { children, contentContainerStyle, ...restProps } = props;

  return (
    <Animated.ScrollView
      ref={ref}
      contentContainerStyle={[styles.contentContainer, contentContainerStyle]}
      {...restProps}
    >
      {children}
    </Animated.ScrollView>
  );
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  contentContainer: {
    paddingHorizontal: theme.spacing['4'],
    paddingBottom: theme.spacing['8'],
    rowGap: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuTopDownCategoriesContainerProps = ComponentProps<
  typeof Animated.ScrollView
>;

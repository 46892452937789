import React from 'react';
import { StyleSheet, View } from 'react-native';
import { HStack, Icon, SectionHeader, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

/**
 * The four credit card icons that indicate a payment block.
 */
export const PaymentBlockCardIcons = () => {
  const { t } = useLocalizationContext();
  const heading = t('general.payment-method');

  return (
    <SectionHeader heading={heading}>
      <View style={styles.iconsWrapper}>
        <HStack gap={8}>
          <Icon name="IconCcVisa" color={theme.colors.POTATO} />
          <Icon name="IconCcMasterCard" color={theme.colors.POTATO} />
          <Icon name="IconCcDiscover" color={theme.colors.POTATO} />
          <Icon name="IconCcAmex" color={theme.colors.POTATO} />
        </HStack>
      </View>
    </SectionHeader>
  );
};

const styles = StyleSheet.create({
  iconsWrapper: {
    marginLeft: 'auto',
    paddingLeft: theme.spacing['2'],
  },
});

import { useCallback } from 'react';

import { useOrderStatusQuery } from '@order/graphql';
import { useOrders } from '@order/Order';

/**
 * After submitting an order we refresh the orders and order status data.
 */
export const usePostOrderSubmitCallback = () => {
  const { fetchOrders } = useOrders();
  // @ts-expect-error TS(2345): Argument of type '{ pause: true; }' is not assigna... Remove this comment to see the full error message
  const [, fetchOrderStatus] = useOrderStatusQuery({ pause: true });

  return useCallback(() => {
    fetchOrders();
    fetchOrderStatus();
  }, [fetchOrderStatus, fetchOrders]);
};

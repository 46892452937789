import React from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';
import { DisplayText, IllusEmpty_2, Image } from '@sg/garnish';

import { AccountScreenContainer, AccountScreenTitle } from '@order/components';
import { useLocalizationContext } from '@order/Localization';

export const AccountSweetpassMembershipError = () => {
  const { t } = useLocalizationContext();

  return (
    <AccountScreenContainer screen="sweetpass-membership">
      <AccountScreenTitle
        visibility="COMPACT"
        title={t('account.sweetpass-membership.title')}
      />

      <Image source={IllusEmpty_2} style={styles.illustration} />

      <DisplayText sizeMatch={['24', '32']} style={styles.heading}>
        {t('sweetpass-membership.membership.error')}
      </DisplayText>
    </AccountScreenContainer>
  );
};

const styles = StyleSheet.create({
  illustration: {
    width: 150,
    height: 150,
    alignSelf: 'center',
  },
  heading: {
    marginTop: theme.spacing['4'],
    textAlign: 'center',
  },
});

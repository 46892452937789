import { useWindowDimensions } from 'react-native';

import type { CheckOrientation } from '../useResponsive/useResponsive.types';

export const useCheckOrientation = (): CheckOrientation => {
  const { width, height } = useWindowDimensions();

  return usePrimedCheckOrientation(width, height);
};

export const usePrimedCheckOrientation = (
  width: number,
  height: number,
): CheckOrientation => {
  return {
    isPortrait: height > width,
    isLandscape: width > height,
  };
};

import React, { useCallback, useLayoutEffect, useState } from 'react';
import type { LayoutChangeEvent } from 'react-native';
import { Animated, View } from 'react-native';

import type {
  CollapsibleChildrenProps,
  CollapsibleDetailsProps,
} from '../Collapsible.types';

type PropsType = CollapsibleDetailsProps & CollapsibleChildrenProps;

export const Details = (props: CollapsibleDetailsProps) => {
  const {
    children,
    transition,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    collapsed,
    onLayout,
    testID = 'sg-collapsible-details',
    ...rest
  } = props as PropsType;

  const [shouldRenderContent, setShouldRenderContent] = useState(false);
  const [collapsibleContentHeight, setCollapsibleContentHeight] = useState(0);

  const animatedStyles = {
    opacity: transition,
    height: transition.interpolate({
      inputRange: [0, 1],
      outputRange: [0, collapsibleContentHeight],
    }),
  };

  //
  // ─── HELPERS ──────────────────────────────────────────────────────────────────
  //

  const trackCollapsibleContentHeight = useCallback(
    (event: LayoutChangeEvent) => {
      onLayout?.(event);
      setCollapsibleContentHeight(event.nativeEvent.layout.height);
    },
    [onLayout],
  );

  //
  // ─── EFFECTS ──────────────────────────────────────────────────────────────────
  //

  // mounting / unmounting content according to height changes for accessibility
  useLayoutEffect(() => {
    const toggleContentRendering: Animated.ValueListenerCallback = ({
      value,
    }) => {
      setShouldRenderContent(value !== 0);
    };

    const listener = transition.addListener(toggleContentRendering);

    return () => {
      transition.removeListener(listener);
    };
  }, [transition]);

  // ──────────────────────────────────────────────────────────────────────────────

  return (
    <Animated.View testID={testID} style={animatedStyles}>
      {shouldRenderContent ? (
        <View
          testID="sg-collapsible-details-inner"
          onLayout={trackCollapsibleContentHeight}
          {...rest}
        >
          {children}
        </View>
      ) : null}
    </Animated.View>
  );
};

/* eslint-disable functional/immutable-data */

import { useCallback, useRef } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import {
  type TelemetryEvent,
  type TelemetryEventName,
} from '../../TelemetryEvent';
import { useTelemetry } from '../../TelemetryProvider';

/**
 * A small hook that tracks provided event on screen focus.
 *
 * @see {@link https://reactnavigation.org/docs/use-focus-effect/}
 */
export const useTrackEventOnFocus = <Name extends TelemetryEventName>(
  event: TrackEventFocusEffectPayload<Name>,
  options?: TrackEventFocusEffectOptions,
) => {
  const { trackEvent } = useTelemetry();

  const { skip } = options ?? {};

  // ─── Memoized payload ────────────────────────────────────────────────

  const trackEventRef = useRef(trackEvent);
  const eventRef = useRef(event);

  trackEventRef.current = trackEvent;
  eventRef.current = event;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const track = useCallback(() => {
    const trackTelemetryEvent = trackEventRef.current;
    const telemetryEvent = eventRef.current;

    if (skip) return;

    trackTelemetryEvent(telemetryEvent);
  }, [skip]);

  // ─────────────────────────────────────────────────────────────────────

  /**
   * Track event on screen focus.
   */
  useFocusEffect(track);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type TrackEventFocusEffectPayload<EventName extends TelemetryEventName> =
  TelemetryEvent<EventName>;

type TrackEventFocusEffectOptions = { skip?: boolean };

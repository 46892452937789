import React from 'react';

import { BodyText } from '../../Text';
import type { ChipTextProps } from '../Chip.types';
import { textStyles } from '../styles';

export const ChipText = (props: ChipTextProps) => {
  const { children, maxFontSizeMultiplier, style, state, palette } = props;

  const dynamicStyles = textStyles[palette][state];
  const styles = [dynamicStyles, style];

  return (
    <BodyText
      size={4}
      style={styles}
      maxFontSizeMultiplier={maxFontSizeMultiplier}
    >
      {children}
    </BodyText>
  );
};

import { defineMessages } from 'react-intl';

export const giftCardOrdersNoHistoryViewMessages = defineMessages({
  illustrationAlt: {
    defaultMessage: 'A person holding SG bag',
    description:
      'Orders Screen | Gift Cards Tab | Empty View | Illustration alternative text',
  },
  title: {
    defaultMessage: 'No history',
    description: 'Orders Screen | Gift Cards Tab | Empty View | Title',
  },
  text: {
    defaultMessage: 'You will see your gifting history here.',
    description: 'Orders Screen | Gift Cards Tab | Empty View | Text',
  },
  sendGiftCardCtaLabel: {
    defaultMessage: 'Send a gift card',
    description:
      'Orders Screen | Gift Cards Tab | Empty View | "Send a gift card" CTA label',
  },
  sendGiftCardCtaA11yLabel: {
    defaultMessage: 'Navigate to the "Gift Cards" screen',
    description:
      'Orders Screen | Gift Cards Tab | Empty View | "Send a gift card" a11y label',
  },
});

import { useMemo } from 'react';

import type { useCart } from '@order/hooks';

/**
 * Extracts the order address from the cart object.
 * Checks if it needs to be completed (must have a name).
 */
export const useOrderAddress = (cart: ReturnType<typeof useCart>['cart']) => {
  const cartDeliveryDetails = cart?.deliveryOrderDetail;

  const orderAddress = cartDeliveryDetails?.address;
  const addressId = orderAddress?.id;
  const hasCompletedOrderAddress = Boolean(orderAddress?.name);

  return useMemo(
    () => ({
      addressId,
      orderAddress,
      hasCompletedOrderAddress,
    }),
    [addressId, orderAddress, hasCompletedOrderAddress],
  );
};

import React from 'react';
import type { Text as RNText } from 'react-native';

import { BodyText } from '../BodyText';
import { highlightWords } from './HighlightWords.helpers';

export const HighlightWords = React.forwardRef(
  (props: HighlightWordsProps, ref: React.Ref<RNText> | undefined) => {
    const { text, highlights = [], numberOfLines, ...restProps } = props;
    const substrings = highlightWords(text, highlights);

    /* -- prevent nested components usage if there is only one substring ---- */

    if (substrings.length === 1) {
      const [{ substring, shouldHighlight }] = substrings;

      return (
        <BodyText
          bold={shouldHighlight}
          numberOfLines={numberOfLines}
          {...restProps}
        >
          {substring}
        </BodyText>
      );
    }

    /* -- use nested components if there are more than one substrings ------- */

    return (
      <BodyText ref={ref} numberOfLines={numberOfLines} {...restProps}>
        {substrings.map(({ key, substring, shouldHighlight }) => {
          return (
            <BodyText key={key} bold={shouldHighlight} {...restProps}>
              {substring}
            </BodyText>
          );
        })}
      </BodyText>
    );
  },
);

/* -- types ----------------------------------------------------------------- */

type HighlightWordsProps = Readonly<{
  /** Text to highlight */
  text: string;

  /** Highlight groups */
  highlights?: readonly string[];
}> &
  Omit<React.ComponentProps<typeof BodyText>, 'children' | 'bold'>;

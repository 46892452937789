import { useIsFocused } from '@react-navigation/native';
import * as DateFns from 'date-fns';
import { type RequestPolicy } from 'urql';

import { useIsLoggedIn } from '@order/AuthMachine';
import { ignoreTimezone } from '@order/utils';

import { useLastOrderDateQuery } from './LastOrderDate.query.generated';

/** The customer is considered to be post order if their last order was less than three hours ago. */
export const useIsPostOrder = (requestPolicy: RequestPolicy) => {
  const isScreenFocused = useIsFocused();
  const isLoggedIn = useIsLoggedIn();

  const [{ data, fetching }] = useLastOrderDateQuery({
    requestPolicy,
    pause: !isScreenFocused || !isLoggedIn,
    variables: {
      input: {
        page: 1,
        take: 1,
      },
    },
  });

  if (fetching) {
    return {
      hasPlacedOrder: false,
      isPostOrder: false,
      isLoadingPostOrder: true,
    };
  }

  if (data?.orders.__typename !== 'OrdersResponseSuccess') {
    return {
      hasPlacedOrder: false,
      isPostOrder: false,
      isLoadingPostOrder: false,
    };
  }

  const orders = data?.orders?.orders ?? [];
  const lastOrder = orders?.[0];
  const wantedTime = lastOrder?.wantedTime;
  const lastOrderDate = wantedTime ? ignoreTimezone(wantedTime) : null;
  const hasPlacedOrder = lastOrder?.id !== undefined;

  if (!lastOrderDate) {
    return {
      hasPlacedOrder,
      isPostOrder: false,
      isLoadingPostOrder: false,
    };
  }

  const threeHoursAgo = DateFns.subHours(new Date(), 3);
  const isOlderThanThreeHours = DateFns.isBefore(lastOrderDate, threeHoursAgo);
  const isPostOrder = !isOlderThanThreeHours;

  return {
    hasPlacedOrder,
    isPostOrder,
    isLoadingPostOrder: false,
  };
};

import React, { type PropsWithChildren } from 'react';
import { Alert } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuHeaderAlert = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <Alert.Container palette="info">
      <Alert.Text sizeMatch={['12']} maxFontSizeMultiplier={1.3}>
        {children}
      </Alert.Text>
    </Alert.Container>
  );
};

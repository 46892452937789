/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LastOrderDateQueryVariables = Types.Exact<{
  input: Types.OrdersInput;
}>;

export type LastOrderDateQuery = {
  readonly __typename?: 'Query';
  readonly orders:
    | {
        readonly __typename: 'OrdersResponseSuccess';
        readonly orders: ReadonlyArray<{
          readonly __typename?: 'Order';
          readonly id: string;
          readonly wantedTime: string;
        }>;
      }
    | { readonly __typename: 'ValidationError' };
};

export const LastOrderDateDocument = gql`
  query LastOrderDate($input: OrdersInput!) {
    orders(input: $input) {
      __typename
      ... on OrdersResponseSuccess {
        orders {
          id
          wantedTime
        }
      }
    }
  }
`;

export function useLastOrderDateQuery(
  options: Omit<Urql.UseQueryArgs<LastOrderDateQueryVariables>, 'query'>,
) {
  return Urql.useQuery<LastOrderDateQuery, LastOrderDateQueryVariables>({
    query: LastOrderDateDocument,
    ...options,
  });
}

import React, { type ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive } from '@sg/garnish';

import { ORDER_LINE_ITEM_CONTAINER_VERTICAL } from '../../OrderLineItem.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderLineItemContainer = (props: OrderLineItemContainerProps) => {
  const { children, variation = 'horizontal' } = props;

  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles =
    variation === 'vertical'
      ? styles.containerVertical
      : [
          styles.containerHorizontal,
          match([styles.containerHorizontalXS, styles.containerHorizontalSM]),
        ];

  // ─────────────────────────────────────────────────────────────────────

  return <View style={containerStyles}>{children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerVertical: {
    width: 216,
    alignItems: 'center',
    padding: ORDER_LINE_ITEM_CONTAINER_VERTICAL.PADDING,
  },
  containerHorizontal: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing['4'],
  },
  containerHorizontalXS: {
    paddingHorizontal: theme.spacing['4'],
  },
  containerHorizontalSM: {
    paddingHorizontal: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderLineItemContainerProps = {
  children: ReactNode;
  variation?: 'vertical' | 'horizontal';
};

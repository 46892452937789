import { Platform } from 'react-native';

import { getAppVersion } from '@order/utils';

import type { LDContext } from './client/launch-darkly.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Creates a Launch Darkly context with the user data + custom attributes.
 *
 * @see {@link https://docs.launchdarkly.com/sdk/features/user-context-config#configuring-context-objects User and context configuration}
 */
export function generateUserContext(customer: Customer): LDContext {
  const key = customer.id ?? '';
  const firstName = customer.firstName ? customer.firstName : undefined;
  const lastName = customer.lastName ? customer.lastName : undefined;
  const email = customer.email ? customer.email : undefined;

  return {
    kind: 'user',
    key,
    firstName,
    lastName,
    email,
    ...generateCustomAttributes(),
  };
}

/**
 * Creates a Launch Darkly context with an anonymous user data + custom attributes.
 *
 * @see {@link https://docs.launchdarkly.com/sdk/features/user-context-config#configuring-context-objects User and context configuration}
 */
export function generateAnonymousUserContext(): LDContext {
  return {
    kind: 'user',
    anonymous: true,
    ...generateCustomAttributes(),
  };
}

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Generates custom attributes for Launch Darkly context to allow more precise targeting.
 */
function generateCustomAttributes(): Partial<LDContext> {
  return {
    platform: Platform.OS,
    appVersion: getAppVersion(),
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type Customer = Readonly<{
  id?: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}>;

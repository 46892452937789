import React from 'react';
import { useIntl } from 'react-intl';
import { Tabs } from '@sg/garnish';

import { ORDER_AGAIN_TABS } from '../../OrderAgain.constants';
import { orderAgainMessages as messages } from '../../OrderAgain.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainTabs = (props: OrderAgainTabsProps) => {
  const { activeTabId, setActiveTabId } = props;

  const { formatMessage } = useIntl();

  return (
    <Tabs.BarContainer>
      <Tabs.Tab
        id={ORDER_AGAIN_TABS.ORDERS.ID}
        panelId={ORDER_AGAIN_TABS.ORDERS.PANEL_ID}
        isActive={activeTabId === ORDER_AGAIN_TABS.ORDERS.ID}
        label={formatMessage(messages.tabOrdersLabel)}
        aria-label={formatMessage(messages.tabOrdersA11yLabel)}
        isFluid={true}
        onPress={setActiveTabId}
      />

      <Tabs.Tab
        id={ORDER_AGAIN_TABS.FAVORITES.ID}
        panelId={ORDER_AGAIN_TABS.FAVORITES.PANEL_ID}
        isActive={activeTabId === ORDER_AGAIN_TABS.FAVORITES.ID}
        label={formatMessage(messages.tabFavoritesLabel)}
        aria-label={formatMessage(messages.tabFavoritesA11yLabel)}
        isFluid={true}
        onPress={setActiveTabId}
      />
    </Tabs.BarContainer>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderAgainTabsProps = {
  activeTabId: string;
  setActiveTabId: (tabId: string) => void;
};

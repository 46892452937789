/* cSpell:ignore ecommerce */

import TagManagerLib from 'react-gtm-module';

import { getEnvVars } from '@order/utils';

import { logInitialize, logTrackEventPayload } from './google-analytics.logger';
import {
  type GoogleAnalyticsEventPayload,
  type GoogleAnalyticsHelpers,
} from './google-analytics.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Helpers for tracking Google Analytics events.
 *
 * NOTE: On the web platform, we use Google Tag Manager.
 * @see {@link https://marketingplatform.google.com/about/tag-manager/}
 */
export const googleAnalytics: GoogleAnalyticsHelpers = {
  /**
   * @see {@link https://github.com/alinemorelli/react-gtm#usage}
   */
  async initialize() {
    const { GTM_AUTH, GTM_CONTAINER_ID, GTM_ENVIRONMENT } = getEnvVars();

    logInitialize();

    TagManagerLib.initialize({
      auth: GTM_AUTH,
      gtmId: GTM_CONTAINER_ID,
      preview: GTM_ENVIRONMENT,
      dataLayerName: DATA_LAYER_NAME,
    });
  },

  /**
   * @see {@link https://github.com/alinemorelli/react-gtm#datalayer}
   */
  async track(payload) {
    logTrackEventPayload(payload);

    TagManagerLib.dataLayer({
      dataLayer: handleEcommerceData(payload),
      dataLayerName: DATA_LAYER_NAME,
    });
  },

  resetAnalyticsData() {
    // eslint-disable-next-line functional/immutable-data
    window[DATA_LAYER_NAME] = [];
  },
};

/**
 * This handles a requirement to clear the ecommerce object and push a new one.
 * It happens on every event that contains items, only needed for the data layer.
 * The payload needs to be duplicated outside and inside the ecommerce field.
 * @see {@link: https://sweetgreen.atlassian.net/browse/CELS-1247?focusedCommentId=109476}
 */
function handleEcommerceData(payload: GoogleAnalyticsEventPayload) {
  if (!payload.items) return payload;

  clearPreviousEcommerceObject();

  return {
    ...payload,
    ecommerce: payload,
  };
}

function clearPreviousEcommerceObject() {
  TagManagerLib.dataLayer({
    dataLayer: { ecommerce: null },
    dataLayerName: DATA_LAYER_NAME,
  });
}

// ─── Constants ───────────────────────────────────────────────────────────────

const DATA_LAYER_NAME = 'dataLayer';

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { RadioContainer, SectionHeader, theme } from '@sg/garnish';

import { type SweetpassMembershipPlans } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

/**
 * Sweetpass subscription selector component.
 */
export const SweetpassMembershipPlanSelector = (
  props: SweetpassMembershipPlanSelectorProps,
) => {
  const {
    selectedPlanId,
    plans,
    plansFeaturedText,
    onSelectedPlanChange,
    shouldDisableAllControls = false,
    shouldHighlightSelectedPlan = false,
    shouldDisableOtherPlanControl = false,
  } = props;

  const { t } = useLocalizationContext();

  // ─── Plans ───────────────────────────────────────────────────────────

  const annualPlan = plans?.annual;
  const annualPlanId = annualPlan?.id;

  const monthlyPlan = plans?.monthly;
  const monthlyPlanId = monthlyPlan?.id;

  // ─── Flags ───────────────────────────────────────────────────────────

  const isAnnualPlanSelected = selectedPlanId === annualPlanId;
  const isMonthlyPlanSelected = selectedPlanId === monthlyPlanId;

  const shouldDisableAnnualPlanControl =
    shouldDisableAllControls ||
    (shouldDisableOtherPlanControl && isMonthlyPlanSelected);
  const shouldDisableMonthlyPlanControl =
    shouldDisableAllControls ||
    (shouldDisableOtherPlanControl && isAnnualPlanSelected);

  const hasValidPlans = annualPlan !== undefined && monthlyPlan !== undefined;

  // ─────────────────────────────────────────────────────────────────────

  if (!hasValidPlans) return null;

  return (
    <View>
      <SectionHeader
        heading={t('sweetpass.membership-plan.heading')}
        style={styles.header}
      />

      <RadioContainer.Group>
        <SweetpassAnnualMembershipPlanSelector
          plan={annualPlan}
          isSelected={isAnnualPlanSelected}
          isDisabled={shouldDisableAnnualPlanControl}
          shouldHighlightSelectedPlan={shouldHighlightSelectedPlan}
          featuredText={plansFeaturedText}
          onPress={onSelectedPlanChange}
        />
        <SweetpassMonthlyMembershipPlanSelector
          plan={monthlyPlan}
          isSelected={isMonthlyPlanSelected}
          isDisabled={shouldDisableMonthlyPlanControl}
          shouldHighlightSelectedPlan={shouldHighlightSelectedPlan}
          featuredText={plansFeaturedText}
          onPress={onSelectedPlanChange}
        />
      </RadioContainer.Group>
    </View>
  );
};

// ─── Components ──────────────────────────────────────────────────────────────

const SweetpassAnnualMembershipPlanSelector = (
  props: SweetpassAnnualMembershipPlanSelectorProps,
) => {
  const {
    plan,
    isSelected,
    isDisabled,
    shouldHighlightSelectedPlan,
    featuredText,
    onPress,
  } = props;
  const { id, price, pricePerMonth, savingAmount } = plan;

  const { t } = useLocalizationContext();

  // ─── Flags ───────────────────────────────────────────────────────────

  const hasValidPricingDetails =
    price !== undefined && pricePerMonth !== undefined;

  // ─── Messages ────────────────────────────────────────────────────────

  const title =
    isSelected || !shouldHighlightSelectedPlan
      ? t('sweetpass.membership-plan.annual.title')
      : t('sweetpass.membership-plan.annual.title-active');
  const subtitle = hasValidPricingDetails
    ? t('sweetpass.membership-plan.annual.subtitle', {
        price: `$${price}`,
        pricePerMonth: `$${pricePerMonth}`,
      })
    : undefined;

  const planSavingLabel =
    !isSelected && shouldHighlightSelectedPlan
      ? t('sweetpass.membership-plan.saving-label', { amount: savingAmount })
      : t('sweetpass.membership-plan.save-label', { amount: savingAmount });
  const conditionalPlanSavingLabel = savingAmount ? planSavingLabel : undefined;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <RadioContainer.Item
      value={id}
      title={title}
      featuredText={featuredText}
      subtitle={subtitle}
      label={conditionalPlanSavingLabel}
      isSelected={isSelected}
      isDisabled={isDisabled}
      onPress={onPress}
    />
  );
};

const SweetpassMonthlyMembershipPlanSelector = (
  props: SweetpassMonthlyMembershipPlanSelectorProps,
) => {
  const {
    plan,
    isSelected,
    isDisabled,
    shouldHighlightSelectedPlan,
    featuredText,
    onPress,
  } = props;
  const { id, price } = plan;

  const { t } = useLocalizationContext();

  // ─── Messages ────────────────────────────────────────────────────────

  const title =
    isSelected || !shouldHighlightSelectedPlan
      ? t('sweetpass.membership-plan.monthly.title')
      : t('sweetpass.membership-plan.monthly.title-active');
  const subtitle = price
    ? t('sweetpass.membership-plan.monthly.subtitle', { price: `$${price}` })
    : undefined;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <RadioContainer.Item
      value={id}
      title={title}
      featuredText={featuredText}
      subtitle={subtitle}
      isSelected={isSelected}
      isDisabled={isDisabled}
      onPress={onPress}
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    marginBottom: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassMembershipPlanSelectorProps = {
  plans: SweetpassMembershipPlans;
  onSelectedPlanChange: (planId: string) => void;

  selectedPlanId?: string;
  plansFeaturedText?: string;
  shouldDisableAllControls?: boolean;
  shouldHighlightSelectedPlan?: boolean;
  shouldDisableOtherPlanControl?: boolean;
};

type SweetpassAnnualMembershipPlanSelectorProps =
  SweetpassMembershipPlanSelectorItemProps<
    NonNullable<SweetpassMembershipPlans['annual']>
  >;

type SweetpassMonthlyMembershipPlanSelectorProps =
  SweetpassMembershipPlanSelectorItemProps<
    NonNullable<SweetpassMembershipPlans['monthly']>
  >;

type SweetpassMembershipPlanSelectorItemProps<Plan> = {
  plan: Plan;
  isSelected: boolean;
  isDisabled: boolean;
  shouldHighlightSelectedPlan: boolean;
  onPress: (planId: string) => void;

  featuredText?: string;
};

import { defineMessages } from 'react-intl';

export const giftCardConfirmationDeliveryInfoCTAsMessages = defineMessages({
  copyLink: {
    defaultMessage: 'Copy link',
    description: 'Gift Card Confirmation Screen | "Copy link" Button Label',
  },
  sendGift: {
    defaultMessage: 'Send gift',
    description: 'Gift Card Confirmation Screen | "Send gift" Button Label',
  },
  urlCopiedMessage: {
    defaultMessage: 'Copied!',
    description:
      'Gift Card Confirmation Screen | "Redemption URL copied" message',
  },
});

import { BRAINTREE_FRAME_ID } from '../utils';

export const injectTokenizeRequest = () => {
  const frame = document.querySelector(`#${BRAINTREE_FRAME_ID}`);

  if (!frame) return;

  const withContentWindow = frame as unknown as Readonly<{
    contentWindow: { tokenizeCreditCard: () => void };
  }>;

  withContentWindow.contentWindow.tokenizeCreditCard();
};

/* eslint-disable react/no-array-index-key */

import type { ReactNode } from 'react';
import React from 'react';
import type { ImageSourcePropType, ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Container,
  HorizontalScrollRail,
  IllusDressing_1,
  IllusEmployeeBag,
  Image,
  theme,
  useContainerSpacing,
  useResponsive,
} from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useLocalizationContext } from '@order/Localization';
import type { useOrders } from '@order/Order';

import {
  ReorderRailCard,
  ReorderRailCardPlaceholder,
  ReorderRailHeader,
} from './components';

export { ReorderRailHeader } from './components';

export const ReorderRail = (props: RailProps) => {
  const isLoggedIn = useIsLoggedIn();
  const { orders, isLoading, hasError } = props;

  if (!isLoggedIn) return null;

  return (
    <View style={styles.wrapper}>
      <ReorderRailHeader hasOrders={orders.length > 0} isLoading={isLoading} />
      <Rail orders={orders} isLoading={isLoading} hasError={hasError} />
    </View>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

export const Rail = (
  props: Pick<RailProps, 'orders' | 'isLoading' | 'hasError'>,
) => {
  const { orders, isLoading, hasError } = props;

  const { t } = useLocalizationContext();
  const shouldShowEmptyState = !isLoading && orders.length === 0;

  // ────────────────────────────────────────────────────────────────────

  if (hasError) {
    return (
      <RailNotice
        text={t('home.reorder-rail-error-text')}
        testID="reorder-rail.error-state"
        illustration={IllusDressing_1}
      />
    );
  }

  if (shouldShowEmptyState) {
    return (
      <RailNotice
        text={t('home.reorder-rail-empty-text')}
        testID="reorder-rail.empty-state"
        illustration={IllusEmployeeBag}
      />
    );
  }

  return (
    <Container style={styles.railContainer} testID="reorder-rail-container">
      {isLoading ? <RailOrdersPlaceholders /> : <RailOrders orders={orders} />}
    </Container>
  );
};

const RailOrders = (props: Pick<RailProps, 'orders'>) => {
  return (
    <RailOrdersList>
      {props.orders.slice(0, 5).map((order) => (
        <ReorderRailCard key={order.id} order={order} orderType="standard" />
      ))}
    </RailOrdersList>
  );
};

const RailOrdersPlaceholders = () => {
  return (
    <RailOrdersList testID="reorder-rail.loading">
      {Array.from({ length: 5 }).map((_, ind) => (
        <ReorderRailCardPlaceholder key={ind} />
      ))}
    </RailOrdersList>
  );
};

const RailOrdersList = (props: RailOrdersListProps) => {
  const { children } = props;

  const slidesGap = useContainerSpacing();

  return (
    <View {...props}>
      <Container>
        <HorizontalScrollRail
          showNavControls={false}
          gap={slidesGap}
          outerOffset={-slidesGap}
        >
          {children}
        </HorizontalScrollRail>
      </Container>
    </View>
  );
};

const RailNotice = (props: RailNoticeProps) => {
  const { minWidth } = useResponsive();
  const { text, illustration, testID } = props;

  const containerStyle = [
    styles.noticeContainer,
    minWidth.isMD && styles.noticeContainerDesktop,
  ];
  const illustrationStyle = minWidth.isSM
    ? styles.noticeIllustrationTablet
    : styles.noticeIllustrationMobile;

  return (
    <View testID={testID} style={containerStyle}>
      <Image source={illustration} style={illustrationStyle} />
      <BodyText style={styles.emptyText} size={2}>
        {text}
      </BodyText>
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  noticeContainer: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: theme.spacing['4'],
    minHeight: 235,
  },
  noticeContainerDesktop: {
    minHeight: 320,
  },

  noticeIllustrationMobile: {
    width: 200,
    height: 200,
  },
  noticeIllustrationTablet: {
    width: 240,
    height: 240,
  },

  wrapper: {
    backgroundColor: theme.colors.AVOCADO,
    paddingVertical: theme.spacing['10'],
  },

  railContainer: {
    paddingHorizontal: 0,
  },

  emptyText: {
    color: theme.colors.DARK_KALE,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type RailProps = Readonly<{
  orders: ReturnType<typeof useOrders>['orders'];
  isLoading: boolean;
  hasError: boolean;
}>;

type RailNoticeProps = Readonly<{
  text: string;
  testID: string;
  illustration: ImageSourcePropType;
}>;

type RailOrdersListProps = ViewProps &
  Readonly<{
    children: readonly ReactNode[];
  }>;

/* eslint-disable functional/immutable-data */

import type { Cache } from '@urql/exchange-graphcache';

import type {
  AddSweetpassPaymentMethodMutation,
  GetPaymentSubscriptionProfileQuery,
  MutationRemoveSubscriptionPaymentMethodArgs,
  MutationUpdateSubscriptionPaymentMethodArgs,
  PaymentSubscriptionProfile,
  // @ts-expect-error TS(2724): '"@order/graphql"' has no exported member named 'R... Remove this comment to see the full error message
  RemoveSubscriptionPaymentMethodMutation,
  UpdateSubscriptionPaymentMethodMutation,
} from '@order/graphql';
import { GetPaymentSubscriptionProfileDocument } from '@order/graphql';
import { SweetpassMembershipDocument } from '@order/graphql';

export function addSubscriptionPaymentMethod(
  result: AddSweetpassPaymentMethodMutation,
  _args: unknown,
  cache: Cache,
) {
  const hasFailed =
    result.addSubscriptionPaymentMethod.__typename !==
    'AddSubscriptionPaymentMethodSuccessResponse';

  if (hasFailed) return;

  const { creditCard } = result.addSubscriptionPaymentMethod;

  cache.updateQuery<GetPaymentSubscriptionProfileQuery>(
    { query: GetPaymentSubscriptionProfileDocument },
    (data) => {
      if (!data?.paymentSubscriptionProfile) return data;

      return {
        ...data,
        paymentSubscriptionProfile: {
          ...data.paymentSubscriptionProfile,
          creditCards: [
            creditCard,
            ...(data.paymentSubscriptionProfile.creditCards ?? []),
          ],
        },
      };
    },
  );
}

export const updateSubscriptionPaymentMethod = (
  result: UpdateSubscriptionPaymentMethodMutation,
  input: MutationUpdateSubscriptionPaymentMethodArgs,
  cache: Cache,
) => {
  const typename = result.updateSubscriptionPaymentMethod.__typename;

  // Ignore unsuccessful mutations.
  if (typename !== 'UpdateSubscriptionPaymentMethodSuccessResponse') return;

  updateCacheWithCreditCardChanges(cache, {
    paymentMethodId: input.paymentMethodId,
  });
};

export const removeSubscriptionPaymentMethod = (
  result: RemoveSubscriptionPaymentMethodMutation,
  input: MutationRemoveSubscriptionPaymentMethodArgs,
  cache: Cache,
) => {
  const typename = result.removeSubscriptionPaymentMethod.__typename;

  // Ignore unsuccessful mutations.
  if (typename !== 'DeleteSubscriptionPaymentMethodSuccess') return;

  updateCacheWithCreditCardChanges(cache, {
    paymentMethodId: input.paymentMethodId,
    removed: true,
  });
};

const updateCacheWithCreditCardChanges = (
  cache: Cache,
  creditCardChanges: CreditCardChanges,
) => {
  cache.updateQuery({ query: SweetpassMembershipDocument }, (data) => {
    if (!data) return data;

    // Get cached credit cards.
    const cachedPaymentSubscriptionProfile =
      data?.paymentSubscriptionProfile as PaymentSubscriptionProfile;
    const creditCards = cachedPaymentSubscriptionProfile?.creditCards ?? [];

    // If the credit card was removed, remove it from the cache.
    if (creditCardChanges.removed) {
      const withoutUpdated = creditCards.filter(
        ({ id }) => id !== creditCardChanges.paymentMethodId,
      );

      data.paymentSubscriptionProfile = {
        ...cachedPaymentSubscriptionProfile,
        creditCards: withoutUpdated,
      };

      return data;
    }

    // If the credit card was added, add it and sort by id.
    data.paymentSubscriptionProfile = {
      ...cachedPaymentSubscriptionProfile,
      creditCards: creditCards.map((creditCard) => ({
        ...creditCard,
        isActive: creditCardChanges.paymentMethodId === creditCard.id,
      })),
    };

    return data;
  });
};

type CreditCardChanges = Readonly<{
  paymentMethodId: string;
  removed?: boolean;
}>;

import React from 'react';
import type { Control } from 'react-hook-form';
import { StyleSheet, Text, View } from 'react-native';
import { BodyText, Form, TagAlert, TextLink, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type { JoinFormData } from '../../utils';

export const TermsAndConditions = (props: TermsAndConditionsProps) => {
  const { control, errorMessage } = props;
  const { t } = useLocalizationContext();

  const inlineTextSize = 4;

  // ────────────────────────────────────────────────────────────────────

  return (
    <View>
      <View style={styles.controlsWrapper}>
        <View style={styles.checkboxWrapper}>
          <Form.Checkbox
            testID="check-terms"
            name="terms"
            control={control}
            rules={{ required: true }}
            accessibilityLabel={t('join.terms-and-conditions-a11y-label')}
          />
        </View>

        <Text style={styles.textWrapper}>
          <BodyText size={inlineTextSize}>
            {t('join.terms-and-conditions-prefix')}{' '}
          </BodyText>

          <TextLink
            size={inlineTextSize}
            href="https://www.sweetgreen.com/terms/"
            testID="link-terms-of-use"
          >
            {t('general.terms-of-use')}
          </TextLink>

          <BodyText size={inlineTextSize}> {t('general.and')} </BodyText>

          <TextLink
            size={inlineTextSize}
            href="https://www.sweetgreen.com/privacy-policy/"
            testID="link-privacy-policy"
          >
            {t('general.privacy-policy')}
          </TextLink>

          <BodyText size={inlineTextSize}>.</BodyText>
        </Text>
      </View>

      {errorMessage ? (
        <View style={styles.errorMessageWrapper}>
          <TagAlert palette="caution">{errorMessage}</TagAlert>
        </View>
      ) : null}
    </View>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type TermsAndConditionsProps = Readonly<{
  control: Control<JoinFormData>;
  errorMessage?: string;
}>;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  controlsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxWrapper: {
    paddingRight: theme.spacing['4'],
  },
  textWrapper: {
    flexShrink: 1,
  },
  errorMessageWrapper: {
    paddingTop: theme.spacing['4'],
  },
});

import { useCallback } from 'react';
import { useIsFocused } from '@react-navigation/native';

import { useChallengeCompletionModal } from '@order/components';
import { useUserLocation } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';
import { checkIfLateOrder, checkIfPastOrder } from '@order/OrderStatus';

import { useReorderFromOrder } from '../../../ReorderingScreen';
import { useOrderHealthKit } from '../useOrderHealthKit';
import { useOrderStatusPolling } from '../useOrderStatusPolling';
import { useOrderStatusRequest } from '../useOrderStatusRequest';

export function useOrderStatusData() {
  const isRenderedOnScreen = useIsFocused();

  // ─── Request ─────────────────────────────────────────────────────────

  const response = useOrderStatusRequest();
  const {
    order,
    courierDetails,
    orderStatus,
    orderId,
    orderType,
    isFetching: isFetchingOrderStatus,
    refetchCanCancelStatus,
  } = response;

  // ─── Order Status Polling ────────────────────────────────────────────

  const {
    status,
    courier,
    refetchOrderStatus: refetchOrderStatusData,
  } = useOrderStatusPolling(orderId, orderType, orderStatus, courierDetails);

  const refetchOrderStatus = useCallback(() => {
    refetchCanCancelStatus();
    refetchOrderStatusData();
  }, [refetchCanCancelStatus, refetchOrderStatusData]);

  // ─── Derived Statuses ────────────────────────────────────────────────

  const isLate = checkIfLateOrder({ order, flattenedOrderStatus: status });
  const isPast = checkIfPastOrder({ order, flattenedOrderStatus: status });

  // ─── Health Kit ──────────────────────────────────────────────────────

  const { isOrderToHealthKitEnabled, orderToHealthKitLineItems } =
    // @ts-expect-error TS(2345): Argument of type '{ readonly __typename?: "Order" ... Remove this comment to see the full error message
    useOrderHealthKit(order);

  // ─── Sweetpass Upsell CTA ────────────────────────────────────────────

  const shouldRenderSweetpassUpsellCta = useFeatureFlag(
    'CELS-683-long-sweetpass-upsell-in-order-status-enabled',
  );

  // ─── User Data ───────────────────────────────────────────────────────

  const customerName = useLocalizationContext().t('general.you');
  const { geolocation } = useUserLocation({
    pollingInterval: GEO_POLLING_INTERVAL,
  });
  const { latitude: userLat, longitude: userLon } = geolocation ?? {};

  // ─── Reorder ─────────────────────────────────────────────────────────

  // @ts-expect-error TS(2345): Argument of type '{ readonly __typename?: "Order" ... Remove this comment to see the full error message
  const onReorder = useReorderFromOrder(order);

  // ─── Challenge Celebration ───────────────────────────────────────────

  const { celebration, fetchingCelebration: isFetchingCelebration } =
    useChallengeCompletionModal(orderId);

  // ─── Data ────────────────────────────────────────────────────────────

  return {
    ...response,
    isFetching: isFetchingOrderStatus || isFetchingCelebration,

    isRenderedOnScreen,

    status,

    orderToHealthKitLineItems,
    isOrderToHealthKitEnabled,

    isLate,
    isPast,

    customerName,
    customerLatitude: userLat ?? 0,
    customerLongitude: userLon ?? 0,

    courierName: courier?.name,
    courierPhone: courier?.phone,
    courierLatitude: courier?.latitude,
    courierLongitude: courier?.longitude,

    celebration,

    shouldRenderSweetpassUpsellCta,

    onReorder,
    refetchOrderStatus,
  };
}

// ─── Constants ─────────────────────────────────────────────────────────

const GEO_POLLING_INTERVAL = 60 * 1000; // 60 seconds.

/* cSpell:ignore libphonenumber */

import React, { createContext, useCallback, useContext } from 'react';
import { IntlProvider, useIntl } from 'react-intl';
import { logger as LOG } from '@garnish/logger';
import {
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  isValid,
} from 'date-fns';
import { formatPhoneNumber } from '@sg/garnish';

import { enUs, es } from './locales';
import type { DateDurationUnit, FormatMessage } from './types';
import { getDurationFromUnit, getDurationUnitName } from './utils';

export type LocaleNames = 'en-US' | 'es';
export type Locale = Readonly<{
  label: LocaleNames;
  translation: typeof enUs | typeof es;
}>;

// NOTE: Purposely separating currency from locale since
// locale should only govern which translation should be used
// and how the values are formatted, but NOT the currency with
// which the transaction is made.
export type SupportedCurrencies = 'USD'; // We only support USD for the time being
export type SupportedLocales = Readonly<Record<string, Locale>>;

export const supportedLocales: SupportedLocales = {
  enUs: {
    label: 'en-US',
    translation: enUs,
  },
  es: {
    label: 'es',
    translation: es,
  },
};

export type LocalizationProviderInterface = Readonly<{
  children?: React.ReactNode;
}>;

export type LocalizationContextInterface = Readonly<{
  setCurrentLocale: React.Dispatch<React.SetStateAction<Locale>>;
  currentLocale: Locale;
}>;

export const LocalizationContext = createContext<LocalizationContextInterface>({
  currentLocale: supportedLocales.enUs,
  setCurrentLocale: () => null,
});

export const useLocalizationContext = () => {
  const { currentLocale, setCurrentLocale } = useContext(LocalizationContext);
  // alias to avoid babel errors
  const {
    formatMessage: fmtMsg,
    formatNumber: fmtNum,
    formatDate: fmtDate,
    formatPlural: fmtPlural,
    formatNumber: fmtNumber,
  } = useIntl();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const formatMessage = useCallback<FormatMessage>(
    (id, values): string => {
      return String(
        fmtMsg({ id, defaultMessage: 'Missing Translation' }, values),
      );
    },
    [fmtMsg],
  );

  const formatPrice = useCallback(
    (
      value: FormatCurrencyParameter,
      currency: SupportedCurrencies,
    ): FormatCurrencyReturn =>
      String(
        fmtNum(Number(value) / 100, {
          style: 'currency',
          currency: String(currency),
        }),
      ),
    [fmtNum],
  );

  const formatDurationFromUnit = useCallback(
    (
      options: Omit<Parameters<typeof getDurationFromUnit>[0], 'formatMessage'>,
    ) => {
      return getDurationFromUnit({ ...options, formatMessage });
    },
    [formatMessage],
  );

  /**
   * Returns a localized string representing a duration unit name.
   *
   * @example
   * formatDurationUnitName(DateDurationUnit.Day); // => 'day'
   * formatDurationUnitName(DateDurationUnit.Month); // => 'month'
   * formatDurationUnitName(Other); // => 'other'
   */
  const formatDurationUnitName = useCallback(
    (durationUnit: DateDurationUnit) =>
      getDurationUnitName({ durationUnit, formatMessage }),
    [formatMessage],
  );

  const formatDate = useCallback(
    (...params: Parameters<typeof fmtDate>) => {
      return fmtDate(...params);
    },
    [fmtDate],
  );

  const getDistanceBetweenDatesInReadableFormat = useCallback(
    (params: GetDatesDifferenceInReadableFormatParams) => {
      const { numericDifference, dateUnit, dateUnitPlural, withoutPrefix } =
        params;

      const shouldUsePluralLabel =
        numericDifference === 0 || numericDifference > 1;
      const formattedDateUnit = shouldUsePluralLabel
        ? dateUnitPlural
        : dateUnit;

      if (withoutPrefix) {
        return formatMessage('date.date-distance', {
          numericDate: numericDifference,
          dateUnit: formattedDateUnit,
        });
      }

      return formatMessage('date.date-distance-with-prefix', {
        numericDate: numericDifference,
        dateUnit: formattedDateUnit,
      });
    },
    [formatMessage],
  );

  /**
   * Returns "distance" between 2 dates in a human-readable format
   * (e.g. "in 10 seconds", "in 1 day" ...).
   *
   * @example
   *
   * const { formatDistanceBetweenDates } = useLocalizationProvider();
   *
   * formatDistanceBetweenDates({
   *   baseDate: new Date(), // current date by default
   *   targetDate: addDays(new Date(), 10)
   * }) => in 10 days
   *
   * NOTE: We use our own implementation, because `IntlDateFormatIntl.DateTimeFormat`
   *       is not completely supported by React Native,
   */
  const formatDistanceBetweenDates = useCallback(
    (props: FormatDistanceBetweenDatesParams): string => {
      const { baseDate = new Date(), targetDate, withoutPrefix } = props;

      // ─── Guards ──────────────────────────────────

      const areValidDatesProvided = isValid(baseDate) && isValid(targetDate);

      if (!areValidDatesProvided) {
        log.debug(
          `Provided valid dates (baseDate: \`${baseDate}\`, targetDate: \`${targetDate}\`)`,
        );

        return '';
      }

      // ─────────────────────────────────────────────

      const [
        diffInYears,
        diffInMonths,
        diffInDays,
        diffInHours,
        diffInMinutes,
        diffInSeconds,
        diffInMilliseconds,
      ] = [
        differenceInYears(targetDate, baseDate),
        differenceInMonths(targetDate, baseDate),
        differenceInDays(targetDate, baseDate),
        differenceInHours(targetDate, baseDate),
        differenceInMinutes(targetDate, baseDate),
        differenceInSeconds(targetDate, baseDate),
        differenceInMilliseconds(targetDate, baseDate),
      ];

      // ─── Years ───────────────────────────────────

      if (diffInYears > 0) {
        return getDistanceBetweenDatesInReadableFormat({
          numericDifference: diffInYears,
          dateUnit: formatMessage('date.year'),
          dateUnitPlural: formatMessage('date.years'),
          withoutPrefix,
        });
      }

      // ─── Months ──────────────────────────────────

      if (diffInMonths > 0) {
        return getDistanceBetweenDatesInReadableFormat({
          numericDifference: diffInMonths,
          dateUnit: formatMessage('date.month'),
          dateUnitPlural: formatMessage('date.months'),
          withoutPrefix,
        });
      }

      // ─── Days ────────────────────────────────────

      if (diffInDays > 0) {
        return getDistanceBetweenDatesInReadableFormat({
          numericDifference: diffInDays,
          dateUnit: formatMessage('date.day'),
          dateUnitPlural: formatMessage('date.days'),
          withoutPrefix,
        });
      }

      // ─── Hours ───────────────────────────────────

      if (diffInHours > 0) {
        return getDistanceBetweenDatesInReadableFormat({
          numericDifference: diffInHours,
          dateUnit: formatMessage('date.hour'),
          dateUnitPlural: formatMessage('date.hours'),
          withoutPrefix,
        });
      }

      // ─── Minutes ─────────────────────────────────

      if (diffInMinutes > 0) {
        return getDistanceBetweenDatesInReadableFormat({
          numericDifference: diffInMinutes,
          dateUnit: formatMessage('date.minute'),
          dateUnitPlural: formatMessage('date.minutes'),
          withoutPrefix,
        });
      }

      // ─── Seconds ─────────────────────────────────

      if (diffInSeconds > 0) {
        return getDistanceBetweenDatesInReadableFormat({
          numericDifference: diffInSeconds,
          dateUnit: formatMessage('date.second'),
          dateUnitPlural: formatMessage('date.seconds'),
          withoutPrefix,
        });
      }

      // ─── Milliseconds ────────────────────────────

      if (diffInMilliseconds > 0) {
        return withoutPrefix
          ? formatMessage('date.seconds')
          : formatMessage('date.in-a-second');
      }

      return '';
    },
    [formatMessage, getDistanceBetweenDatesInReadableFormat],
  );

  /**
   * Returns "distance" between 2 dates in a human-readable day format
   * (e.g. "in 1 day", "1 day", "in 2 days", "2 days" ...).
   *
   * @example
   *
   * const { formatDistanceBetweenDatesInDays } = useLocalizationProvider();
   *
   * formatDistanceBetweenDatesInDays({
   *   baseDate: new Date(), // current date by default
   *   targetDate: addDays(new Date(), 10)
   * }) => in 10 days
   *
   * * formatDistanceBetweenDatesInDays({
   *   baseDate: new Date(), // current date by default
   *   targetDate: addDays(new Date(), 10)
   *   withoutPrefix: true
   * }) => 10 days
   */
  const formatDistanceBetweenDatesInDays = useCallback(
    (props: FormatDistanceBetweenDatesInDaysParams): string => {
      const {
        baseDate = new Date(),
        targetDate,
        withoutPrefix,
        minDays = 0,
        useMidnight,
      } = props;

      const baseDateObj = new Date(baseDate);
      const targetDateObj = new Date(targetDate);

      // ─── Guards ──────────────────────────────────

      const areValidDatesProvided =
        isValid(baseDateObj) && isValid(targetDateObj);

      if (!areValidDatesProvided) {
        log.debug(
          `Provided valid dates (baseDateObj: \`${baseDateObj}\`, targetDateObj: \`${targetDateObj}\`)`,
        );

        return '';
      }

      // ─────────────────────────────────────────────

      if (useMidnight) {
        baseDateObj.setHours(0, 0, 0, 0);
        targetDateObj.setHours(0, 0, 0, 0);
      }

      // ─────────────────────────────────────────────

      const oneDay = 1000 * 60 * 60 * 24;
      const diffInMilliseconds = differenceInMilliseconds(
        targetDateObj,
        baseDateObj,
      );
      const diffInDays = Math.ceil(diffInMilliseconds / oneDay);
      const diffWithFallback = Math.max(diffInDays, minDays);

      return getDistanceBetweenDatesInReadableFormat({
        numericDifference: diffWithFallback,
        dateUnit: formatMessage('date.day'),
        dateUnitPlural: formatMessage('date.days'),
        withoutPrefix,
      });
    },
    [formatMessage, getDistanceBetweenDatesInReadableFormat],
  );

  // ─────────────────────────────────────────────────────────────────────

  return {
    currentLocale,
    formatPlural: fmtPlural,
    formatPrice,
    formatDate,
    formatDistanceBetweenDates,
    formatDistanceBetweenDatesInDays,
    formatDurationFromUnit,
    formatDurationUnitName,
    formatMessage,
    t: formatMessage, // Alias for formatMessage
    formatNumber: fmtNumber,
    formatPhoneNumber,
    setCurrentLocale,
  };
};

export const LocalizationProvider = ({
  children,
}: LocalizationProviderInterface) => {
  const [currentLocale, setCurrentLocale] = React.useState<Locale>(
    supportedLocales.enUs,
  );

  const value = React.useMemo(
    () => ({
      setCurrentLocale,
      currentLocale,
    }),
    [currentLocale],
  );

  return (
    <LocalizationContext.Provider value={value}>
      <IntlProvider
        messages={currentLocale.translation}
        locale={currentLocale.label}
        defaultLocale={supportedLocales.enUs.label}
      >
        {children}
      </IntlProvider>
    </LocalizationContext.Provider>
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

LOG.enable('LOCALIZATION_PROVIDER');
const log = LOG.extend('LOCALIZATION_PROVIDER');

// ─── Types ───────────────────────────────────────────────────────────────────

type FormatDistanceBetweenDatesParams = Readonly<{
  baseDate?: Date | number;
  targetDate: Date | number;
  withoutPrefix?: boolean;
}>;

type FormatDistanceBetweenDatesInDaysParams = FormatDistanceBetweenDatesParams &
  Readonly<{
    /**
     * A minimum number of days as a fallback.
     * @default 0
     */
    minDays?: number;

    /**
     * To obtain the "true" days difference, we can optionally set the
     * corresponding dates time to midnight.
     */
    useMidnight?: boolean;
  }>;

type FormatCurrencyParameter = Parameters<IntlShape['formatNumber']>[0];

type FormatCurrencyReturn = ReturnType<IntlShape['formatNumber']>[0];

type IntlShape = NonNullable<ReturnType<typeof useIntl>>;

type GetDatesDifferenceInReadableFormatParams = Readonly<{
  numericDifference: number;
  dateUnit: string;
  dateUnitPlural: string;
  withoutPrefix?: boolean;
}>;

import React from 'react';

import {
  LoadingAnimation,
  NotFoundView,
  RefreshErrorState,
  SweetpassFreeTrialNotEligible,
} from '@order/components';
import { TierName } from '@order/graphql';
import { useSweetpassPlan } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { SweetpassUpgradeScreenContent } from '../../SweetpassUpgradeScreenContent';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders Sweetpass Upgrade Screen with preselected plan content (with certain plan
 * details like price, trial duration, etc.)
 */
export const SweetpassUpgradeToPlan = (props: SweetpassUpgradeToPlanProps) => {
  const {
    targetPlanId,
    campaignId,
    nextSweetpassTierName,
    nextSweetpassTierId,
    optInSweetpass,
    isConfirmingOptIn,
    isCustomerEligibleForFreeTrial,
    trialDuration,
    trialDurationSingular,
  } = props;

  // ─── Feature FLags ───────────────────────────────────────────────────

  const canIgnoreFreeTrialEligibility = useFeatureFlag(
    'CELS-1260-enable-annual-subscriptions',
  );

  // ─────────────────────────────────────────────────────────────────────

  const {
    planId,
    planPrice,
    isLoadingPlan,
    hasFreeTrial,
    billingFrequencyUnit,
    hasUnknownPlanError,
  } = useSweetpassPlan({ requestedPlanId: targetPlanId });

  // ─── Flags ───────────────────────────────────────────────────────────

  const isFreeTrialEnabled = isCustomerEligibleForFreeTrial && hasFreeTrial;
  const isNextTierSweetpassPlus =
    nextSweetpassTierName === TierName.SweetpassPlus;
  const isMissingPlan = isNextTierSweetpassPlus && !planId;
  const isMissingRequiredData = isMissingPlan || !nextSweetpassTierId;
  const isNotEligible =
    !canIgnoreFreeTrialEligibility &&
    hasFreeTrial &&
    !isCustomerEligibleForFreeTrial;
  const hasUnknownError = hasUnknownPlanError;

  // ─────────────────────────────────────────────────────────────────────

  if (isLoadingPlan) {
    return <LoadingAnimation />;
  }

  if (hasUnknownError) {
    return <RefreshErrorState />;
  }

  if (isMissingRequiredData) {
    return <NotFoundView />;
  }

  if (isNotEligible) {
    return <SweetpassFreeTrialNotEligible />;
  }

  // ─────────────────────────────────────────────────────────────────────

  return (
    <SweetpassUpgradeScreenContent
      campaignId={campaignId}
      sweetpassNextTierName={nextSweetpassTierName}
      isNextTierSweetpassPlus={isNextTierSweetpassPlus}
      isFreeTrialAvailable={isFreeTrialEnabled}
      planPrice={planPrice}
      trialDuration={trialDuration}
      trialDurationSingular={trialDurationSingular}
      billingFrequencyUnit={billingFrequencyUnit}
      isConfirmingOptIn={isConfirmingOptIn}
      onCtaConfirmation={optInSweetpass}
    />
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassUpgradeToPlanProps = Readonly<{
  isCustomerEligibleForFreeTrial: boolean;
  nextSweetpassTierId: string;
  isConfirmingOptIn: boolean;
  optInSweetpass: (action?: string) => void;

  nextSweetpassTierName?: TierName;
  targetPlanId?: string;
  campaignId?: string;
  trialDuration?: string;
  trialDurationSingular?: string;
}>;

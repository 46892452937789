import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';

import type { PartialProduct } from '@order/graphql';

import type { BagScreenProps } from '../../../../../navigation/AppNavigation.props';

/**
 * Hook that allows navigating to the correct edit product details page.
 */
export const useNavigateToEditProductDetails = (
  props: NavigateToEditProductDetailsProps,
) => {
  const { addressId = '', restaurantSlug = '', isDelivery = false } = props;

  const navigation = useNavigation<BagScreenProps['navigation']>();

  const navigateToEditProductDetails = useCallback(
    (params: NavigateToEditProductDetailsParams) => {
      const { lineItemId, product } = params;
      const productSlug = product?.slug ?? '';

      if (isDelivery) {
        navigation.navigate('DeliveryEditProductDetails', {
          addressId,
          productSlug,
          lineItemId,
        });

        return;
      }

      navigation.navigate('EditProductDetails', {
        restaurantSlug,
        productSlug,
        lineItemId,
      });
    },
    [addressId, isDelivery, navigation, restaurantSlug],
  );

  return { navigateToEditProductDetails };
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type NavigateToEditProductDetailsProps = Readonly<{
  isDelivery?: boolean;
  addressId?: string;
  restaurantSlug?: string;
}>;

type NavigateToEditProductDetailsParams = Readonly<{
  lineItemId: string;
  product: PartialProduct;
}>;

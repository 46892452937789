import { STRIPE_FRAME_ID } from '../utils';

export const injectSubmitRequest = () => {
  const frame = document.querySelector(`#${STRIPE_FRAME_ID}`);

  if (!frame) return;

  const withContentWindow = frame as unknown as Readonly<{
    contentWindow: { submitStripe: () => void };
  }>;

  withContentWindow.contentWindow.submitStripe();
};

import React from 'react';
import { StyleSheet } from 'react-native';
import { AspectRatioImage, theme } from '@sg/garnish';

import { GiftCardsGridItemLink } from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsGridItem = (props: GiftCardsCategoriesGridItemProps) => {
  const {
    id,
    accessibilityLabel,
    imgAccessibilityLabel,
    coverSrc,
    isSignedIn,
    onSignedOutCardPress,
  } = props;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <GiftCardsGridItemLink
      id={id}
      coverSrc={coverSrc}
      accessibilityLabel={accessibilityLabel}
      isSignedIn={isSignedIn}
      onSignedOutCardPress={onSignedOutCardPress}
    >
      <AspectRatioImage
        source={coverSrc}
        borderRadius={theme.radius.xlarge}
        aspectRatioY={4}
        aspectRatioX={3}
        aria-label={imgAccessibilityLabel}
        containerStyle={styles.imgContainer}
      />
    </GiftCardsGridItemLink>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  imgContainer: {
    ...theme.elevations['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardsCategoriesGridItemProps = {
  id: string;
  accessibilityLabel: string;
  imgAccessibilityLabel: string;
  coverSrc: string;
  isSignedIn: boolean;
  onSignedOutCardPress: () => void;
};

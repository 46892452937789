import AsyncStorage from '@react-native-async-storage/async-storage';
import type { ContextFrom } from 'xstate';

import type { dietaryRestrictionsModel } from './dietaryRestrictionsMachine';
import { DIETARY_RESTRICTIONS_STORAGE_KEY } from './dietaryRestrictionsMachine.constants';

// ─── Utils ───────────────────────────────────────────────────────────────────

export async function getMachinePersistedState() {
  return AsyncStorage.getItem(DIETARY_RESTRICTIONS_STORAGE_KEY);
}

export function setMachinePersistedState(
  context: ContextFrom<typeof dietaryRestrictionsModel>,
) {
  const jsonContext = JSON.stringify(context.restrictions);

  void AsyncStorage.setItem(DIETARY_RESTRICTIONS_STORAGE_KEY, jsonContext);
}

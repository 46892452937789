import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, TextLinkifyTags } from '@sg/garnish';

import {
  CheckoutLayoutBlock,
  PaymentBlockCardIcons,
  SweetpassCheckoutCardPicker,
} from '@order/components';
import type { SubscriptionCreditCard } from '@order/graphql';

import { SweetpassCheckoutCta } from '../SweetpassCheckoutCta';

export const SweetpassCheckoutCardBlock = (
  props: SweetpassCheckoutCardBlockProps,
) => {
  const {
    isLoading,
    canCheckout,
    agreementText,
    agreementTextLinks,
    ctaLabel,
    handleCta,
    ...restProps
  } = props;

  return (
    <CheckoutLayoutBlock>
      <PaymentBlockCardIcons />
      <SweetpassCheckoutCardPicker {...restProps} />

      <View style={styles.ctaContainer}>
        <BodyText sizeMatch={['16']}>
          <TextLinkifyTags
            palette="kale"
            links={agreementTextLinks}
            sizeMatch={['16']}
          >
            {agreementText}
          </TextLinkifyTags>
        </BodyText>

        <SweetpassCheckoutCta
          testID="sweetpass.checkout.cta-desktop"
          isLoading={isLoading}
          disabled={!canCheckout}
          cta={ctaLabel}
          onPress={handleCta}
        />
      </View>
    </CheckoutLayoutBlock>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  ctaContainer: {
    marginTop: theme.spacing['6'],
    rowGap: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassCheckoutCardBlockProps = Readonly<{
  selectedCreditCard?: Partial<SubscriptionCreditCard>;
  creditCards: ReadonlyArray<Partial<SubscriptionCreditCard>>;
  handleAddCreditCard: () => void;
  handleSelectCreditCard: (creditCard: Partial<SubscriptionCreditCard>) => void;
  agreementText: string;
  agreementTextLinks: ComponentProps<typeof TextLinkifyTags>['links'];
  isLoading: boolean;
  canCheckout: boolean;
  ctaLabel: string;
  handleCta: () => void;
}>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SubscribeMutationVariables = Types.Exact<{
  planId: Types.Scalars['String']['input'];
  paymentId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  campaignId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type SubscribeMutation = {
  readonly __typename?: 'Mutation';
  readonly createSubscriptionV2:
    | {
        readonly __typename: 'CreateSubscriptionResult';
        readonly id: string;
        readonly trialDuration: boolean;
        readonly status: string;
        readonly firstBillingDate: string | null;
        readonly subscriptionAmount: string;
      }
    | {
        readonly __typename: 'FreeTrialNotEligible';
        readonly errorMessage: string;
      }
    | { readonly __typename?: 'UnknownUserError' };
};

export const SubscribeDocument = gql`
  mutation Subscribe(
    $planId: String!
    $paymentId: String
    $campaignId: String
  ) {
    createSubscriptionV2(
      planId: $planId
      paymentId: $paymentId
      campaignId: $campaignId
    ) {
      ... on CreateSubscriptionResult {
        __typename
        id
        trialDuration
        status
        firstBillingDate
        subscriptionAmount
      }
      ... on FreeTrialNotEligible {
        __typename
        errorMessage
      }
    }
  }
`;

export function useSubscribeMutation() {
  return Urql.useMutation<SubscribeMutation, SubscribeMutationVariables>(
    SubscribeDocument,
  );
}

import type { ComponentProps } from 'react';
import React, { useCallback, useMemo, useRef } from 'react';
import type { ViewProps } from 'react-native';
import { Pressable, StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import {
  usePressableState,
  useResponsive,
  useUniqueNativeID,
} from '../../hooks';
import { webOnlyStyles } from '../../utils';
import type { CloudinaryTransformConfig } from '../Image';
import { CloudinaryImage } from '../Image';
import { LoadingPlaceholder } from '../LoadingPlaceholder';
import { BodyText } from '../Text';
import { CARD_HEIGHT_SM, CARD_HEIGHT_XS } from './CollectionCard.constants';

// ────────────────────────────────────────────────────────────────────

export const CollectionCard = (props: CollectionCardProps) => {
  const { id, title, description, imageUrl, testID, onPress } = props;

  const descriptionId = useUniqueNativeID(
    `sg-collection-card-description-${id}`,
  );
  const { match } = useResponsive();

  const pressableRef = useRef<View>(null);
  const { isHovered } = usePressableState(pressableRef);

  const handlePress = useCallback(() => {
    onPress?.(id);
  }, [id, onPress]);

  const pressableStyle = webOnlyStyles({
    outlineColor: theme.colors.GRAY,
    outlineOffset: 4,
  });

  const imageStyle = [
    styles.image,
    match([styles.imageXS, styles.imageSM]),
    webOnlyStyles({ transition: `opacity ${theme.transitions.base}ms` }),
    isHovered && styles.imageHover,
  ];

  const imageWidth = match([250, 480]);
  const imageTransformConfig = useMemo<CloudinaryTransformConfig>(
    () => ({ width: imageWidth }),
    [imageWidth],
  );

  return (
    <Pressable
      ref={pressableRef}
      style={pressableStyle}
      accessibilityLabel={title}
      accessibilityRole="button"
      onPress={handlePress}
      testID={testID}
    >
      <CloudinaryImage
        style={imageStyle}
        baseUrl={imageUrl}
        config={imageTransformConfig}
        resizeMode="cover"
        aria-label={title}
        // @ts-expect-error TS(2322): Type '{ style: (false | { borderRadius: number; ma... Remove this comment to see the full error message
        accessibilityDescribedBy={descriptionId}
        testID={`${testID}-image`}
      />

      <BodyText bold size={2}>
        {title}
      </BodyText>

      <BodyText nativeID={descriptionId} size={3}>
        {description}
      </BodyText>
    </Pressable>
  );
};

export const CollectionCardLoading = () => {
  const { match } = useResponsive();
  const cardRowHeight = match([CARD_HEIGHT_XS, CARD_HEIGHT_SM]);

  return (
    <View style={styles.loadingItem}>
      <LoadingPlaceholder
        rowHeight={[cardRowHeight, theme.spacing['10']]}
        gridGap={theme.spacing['4']}
        rows={2}
        palette="cream"
      />
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  image: {
    borderRadius: theme.radius.medium,
    marginBottom: theme.spacing['4'],
    width: '100%',
  },
  imageHover: {
    opacity: 0.7,
  },
  imageXS: {
    height: CARD_HEIGHT_XS,
  },
  imageSM: {
    height: CARD_HEIGHT_SM,
  },
  loadingItem: {
    width: '100%',
  },
});

// ─── TYPES ─────────────────────────────────────────────────────────────────

export type CollectionCardProps = Readonly<{
  id: string;
  imageUrl: ComponentProps<typeof CloudinaryImage>['baseUrl'];
  title: string;
  description: string;
  testID?: ViewProps['testID'];
  onPress?: (id: string) => void;
}>;

import {
  ActiveIngredientQuantityStepperButton,
  ActiveIngredientQuantityStepperContainer,
  ActiveIngredientQuantityStepperOverlay,
  ActiveIngredientQuantityStepperText,
} from './components';

export const ActiveIngredientQuantityStepper = {
  Overlay: ActiveIngredientQuantityStepperOverlay,
  Container: ActiveIngredientQuantityStepperContainer,
  Text: ActiveIngredientQuantityStepperText,
  Button: ActiveIngredientQuantityStepperButton,
};

import type {
  ProductModificationsModelContext,
  ProductModificationsModelEvent,
} from '../ProductModifications.machine';
import type {
  IngredientModificationLimitations,
  IngredientsModificationsWithQuantities,
} from '../ProductModifications.types';
import {
  withActiveModification,
  withoutActiveModification,
} from './activeModifications';
import { checkIfBreadKind } from './checkIfBreadKind';
import {
  getComputedModifications,
  getNetPriceChange,
} from './computedModifications';

export const getModificationKindLimit = (
  kind: string,
  limitations: IngredientModificationLimitations,
) => {
  const kindMaxLimit = limitations?.[kind]?.max;
  const kindMinLimit = limitations?.[kind]?.min;

  return { kindMaxLimit, kindMinLimit };
};

export const getActiveCountByKind = (
  actives: IngredientsModificationsWithQuantities,
) => {
  return actives.reduce<Record<string, number>>((state, active) => {
    const { kind, quantity } = active;
    const activeQuantity = quantity ?? 0;

    if (!kind) return state;

    return {
      ...state,
      [kind]:
        typeof state[kind] === 'number'
          ? Number(state[kind] ?? '0') + activeQuantity
          : activeQuantity,
    };
  }, {});
};

export const handleIngredientModificationChange =
  (changeType: 'addition' | 'removal' | 'removal-complete') =>
  (
    ctx: Partial<ProductModificationsModelContext>,
    event: ProductModificationsModelEvent,
  ) => {
    const ingredientModification =
      getIngredientModificationFromEventPayload(event);

    if (!ingredientModification) return {};

    const { active: activeIngredientsModifications } = ctx;

    // ─────────────────────────────────────────────────────────────────────

    if (changeType === 'addition') {
      const updatedActiveIngredients = withActiveModification({
        ingredientModification,
        ingredientsModifications: activeIngredientsModifications,
      });

      return generateUpdatedIngredientsContext(ctx, updatedActiveIngredients);
    }

    if (changeType === 'removal-complete') {
      const updatedActiveIngredients = withoutActiveModification({
        ingredientModification,
        ingredientsModifications: activeIngredientsModifications,
        isCompleteRemoval: true,
      });

      return generateUpdatedIngredientsContext(ctx, updatedActiveIngredients);
    }

    const updatedActiveIngredients = withoutActiveModification({
      ingredientModification,
      ingredientsModifications: activeIngredientsModifications,
      isCompleteRemoval: false,
    });

    return generateUpdatedIngredientsContext(ctx, updatedActiveIngredients);
  };

/**
 * Generates new ingredients context based on the current context and updated
 * active ingredients list.
 */
function generateUpdatedIngredientsContext(
  context: ProductModificationsModelContext,
  updatedIngredients: ProductModificationsModelContext['active'],
): Partial<ProductModificationsModelContext> {
  const { defaults, ingredientsModifications } = context;

  if (!ingredientsModifications) {
    return {};
  }

  const { additions, removals, substitutions } = getComputedModifications(
    defaults,
    updatedIngredients,
  );

  return {
    active: updatedIngredients,
    additions,
    removals,
    substitutions,
    netPriceChange: getNetPriceChange(
      ingredientsModifications,
      additions,
      removals,
      substitutions,
    ),
  };
}

export const handleBreadIngredientModificationChange = (
  ctx: ProductModificationsModelContext,
) => {
  const isBreadActive = checkIfBreadIsActive(ctx);
  const ingredientModification = getBreadIngredientModification(ctx);

  if (!ingredientModification) return {};

  return handleIngredientModificationChange(
    isBreadActive ? 'removal' : 'addition',
  )(
    ctx,
    isBreadActive
      ? { type: 'REMOVE_INGREDIENT_MODIFICATION', ingredientModification }
      : { type: 'ADD_INGREDIENT_MODIFICATION', ingredientModification },
  );
};

export const getIngredientModificationFromEventPayload = (
  event: ProductModificationsModelEvent,
) => {
  const eventType = event.type;

  const isAdditionEvent =
    eventType === 'ADD_INGREDIENT_MODIFICATION' ||
    eventType === 'INCREASE_DRESSING_PORTION_NUMBER' ||
    eventType === 'CHANGE_DRESSING_PORTIONS';
  const isRemovalEvent =
    eventType === 'REMOVE_INGREDIENT_MODIFICATION' ||
    eventType === 'REMOVE_INGREDIENT_MODIFICATION_COMPLETELY' ||
    eventType === 'DECREASE_DRESSING_PORTION_NUMBER' ||
    eventType === 'CHANGE_DRESSING_PORTIONS';

  if (!(isAdditionEvent || isRemovalEvent)) return null;

  return event.ingredientModification;
};

export const getBreadIngredientModification = (
  ctx: ProductModificationsModelContext,
) => {
  const breadIngredientModifications = ctx.ingredientsModifications?.bread;

  return breadIngredientModifications?.modifications?.find(
    (ingredientModification) =>
      checkIfBreadKind(String(ingredientModification.kind)),
  );
};

// ─── EXTERNAL UTILS ─────────────────────────────────────────────────────────────

export const getActiveModificationsQty = (
  ctx: ProductModificationsModelContext,
) => {
  const { active: activeModifications } = ctx;

  return activeModifications.reduce((totalNumber, activeModification) => {
    const activeModificationQty = activeModification?.quantity ?? 0;

    return totalNumber + activeModificationQty;
  }, 0);
};

export const getActiveIngredientModification = (
  ctx: ProductModificationsModelContext,
  ingredientModificationId: string,
) => {
  const { active: activeModifications } = ctx;

  return activeModifications.find((activeModification) => {
    const activeModificationIngredientId = activeModification.ingredient.id;

    return activeModificationIngredientId === ingredientModificationId;
  });
};

export const checkIfBreadIsActive = (ctx: ProductModificationsModelContext) => {
  return ctx.active.some((activeIngredientModification) =>
    checkIfBreadKind(String(activeIngredientModification.kind)),
  );
};

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { Icon, useResponsive } from '@sg/garnish';
import { BodyText, IllusEmpty_2, theme, TitleText } from '@sg/garnish';

import { SweetpassHomeActionCardImage } from '../SweetpassHomeActionCardImage';
import type { SweetpassHomeActionCardLayoutVariantProps } from '../SweetpassHomeActionCardLayoutVariantProps';

// TODO: This constant should reflect the maximum dimension of
// the image at which it appears in the layout, rounded up to 1:1 aspect ratio.
// Eg. if the image appears at most w:400,h:300, this should be w:400,h:400
const LOADED_IMAGE_SIZE = {
  width: 600,
  height: 600,
};

export const SweetpassHomeActionCardLayoutRectangleFrame = (
  props: SweetpassHomeActionCardLayoutVariantProps,
) => {
  const {
    testID,
    style,
    theme: themeConfig,
    content: { image, headingText, bodyText },
    hasAction,
    ...otherProps
  } = props;

  const { match, currentBreakpoint } = useResponsive();
  const { isXS, isMD, isLG } = currentBreakpoint;
  const isHorizontalLayout = isXS || isMD || isLG;

  const { foregroundColor, backgroundColor } = themeConfig;

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerDynamicStyles = useStyle(
    () => ({ borderColor: foregroundColor, backgroundColor }),
    [foregroundColor, backgroundColor],
  );
  const containerHorizontalStyles =
    isHorizontalLayout && styles.containerHorizontal;
  const containerStyles = [
    style,
    styles.container,
    match([styles.containerXS, styles.containerSM]),
    containerDynamicStyles,
    containerHorizontalStyles,
  ];

  const imgResponsiveStyle = match([styles.imgXS, styles.imgSM, styles.imgMD]);
  const imgStyle = [styles.img, imgResponsiveStyle];

  const textStyle = useStyle(
    () => ({ color: foregroundColor }),
    [foregroundColor],
  );

  const titleStyles = [textStyle, !isHorizontalLayout && styles.headingText];
  const bodyTextStyles = [styles.bodyText, textStyle];

  const iconName = match([
    'IconArrowRight',
    'IconArrowRight',
    'IconArrowRight',
    'IconArrowRightThin',
  ] as const);
  const iconSize = match([24, 24, 24, 48]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View testID={testID} style={containerStyles} {...otherProps}>
      <View style={styles.imgContainer}>
        <SweetpassHomeActionCardImage
          testID={testID?.concat('.image')}
          width={LOADED_IMAGE_SIZE.width}
          height={LOADED_IMAGE_SIZE.height}
          defaultImage={IllusEmpty_2}
          style={imgStyle}
          image={image}
          theme={themeConfig}
        />
      </View>

      <View style={isHorizontalLayout ? styles.actionCardInfoHorizontal : null}>
        <TitleText
          sizeMatch={['32', '32', '32', '48']}
          style={titleStyles}
          testID={testID?.concat('.heading')}
        >
          {headingText}
        </TitleText>

        <View style={styles.bodyRow}>
          <BodyText
            sizeMatch={['14', '18', '16', '18']}
            style={bodyTextStyles}
            testID={testID?.concat('.body')}
          >
            {bodyText}
          </BodyText>

          {hasAction ? (
            <View style={styles.iconContainer}>
              <Icon
                testID={testID?.concat('.action-indicator')}
                name={iconName}
                color={foregroundColor}
                width={iconSize}
                height={iconSize}
              />
            </View>
          ) : null}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: theme.spacing['4'],
    borderWidth: 1,
  },
  containerXS: {
    padding: theme.spacing['4'],
  },
  containerSM: {
    padding: theme.spacing['6'],
  },
  containerHorizontal: {
    minHeight: 240,
    flexDirection: 'row',
  },
  actionCardInfoHorizontal: {
    flex: 1,
    justifyContent: 'space-between',
    marginLeft: theme.spacing['4'],
  },
  headingText: {
    marginTop: theme.spacing['6'],
  },
  bodyRow: {
    marginTop: theme.spacing['6'],
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['1'],
  },
  bodyText: {
    flex: 1,
  },
  imgContainer: {
    flex: 1,
  },
  img: {
    width: '100%',
    borderRadius: theme.spacing['2'],
    borderWidth: 1,
  },
  imgXS: {
    height: '100%',
  },
  imgSM: {
    minHeight: 168,
    maxHeight: 500,
  },
  imgMD: {
    height: '100%',
  },
  iconContainer: {
    alignSelf: 'flex-end',
  },
});

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { IconLink } from '../../Icon';
import { BodyText } from '../../Text';

// ────────────────────────────────────────────────────────────────────────────

export const LineItemQuantityStepper = (props: ProductFooterProps) => {
  const {
    quantity,
    isDisabled,
    currentQuantityA11y = 'Current product quantity',
    increaseQuantityA11y = 'Increase product quantity',
    decreaseQuantityA11y = 'Decrease product quantity',
    onQuantityChangeStart,
    onQuantityChangeEnd,
    onQuantityChange,
  } = props;

  const { match } = useResponsive();

  // ─── Selected Quantity ────────────────────────────────────────────────────

  const [selectedQuantity, setSelectedQuantity] = useState(quantity);

  const increaseQuantity = useCallback(() => {
    onQuantityChangeStart();
    setSelectedQuantity((currentQuantity) => currentQuantity + 1);
  }, [onQuantityChangeStart]);

  const decreaseQuantity = useCallback(() => {
    onQuantityChangeStart();
    setSelectedQuantity((currentQuantity) =>
      currentQuantity > 0 ? currentQuantity - 1 : currentQuantity,
    );
  }, [onQuantityChangeStart]);

  // ─── Timeout Behavior ─────────────────────────────────────────────────────

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    if (selectedQuantity === quantity) {
      onQuantityChangeEnd();
      return;
    }

    // eslint-disable-next-line functional/immutable-data
    timeoutRef.current = setTimeout(() => {
      onQuantityChange(selectedQuantity);
      onQuantityChangeEnd();
    }, 1000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [quantity, selectedQuantity, onQuantityChange, onQuantityChangeEnd]);

  return (
    <View style={match([styles.wrapperXs, styles.wrapperSm])}>
      <IconLink
        style={match([styles.iconXs, styles.iconSm])}
        name="IconMinus2"
        palette="dark-kale"
        disabled={isDisabled}
        width={match([ICON_SIZE_XS, ICON_SIZE_SM])}
        height={match([ICON_SIZE_XS, ICON_SIZE_SM])}
        accessibilityLabel={decreaseQuantityA11y}
        onPress={decreaseQuantity}
      />

      <BodyText accessibilityLabel={currentQuantityA11y} sizeMatch={['18']}>
        {selectedQuantity}
      </BodyText>

      <IconLink
        style={match([styles.iconXs, styles.iconSm])}
        name="IconPlus2"
        palette="dark-kale"
        disabled={isDisabled}
        width={match([ICON_SIZE_XS, ICON_SIZE_SM])}
        height={match([ICON_SIZE_XS, ICON_SIZE_SM])}
        accessibilityLabel={increaseQuantityA11y}
        onPress={increaseQuantity}
      />
    </View>
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

export const QUANTITY_STEPPER_SIZE_XS = 32;
export const QUANTITY_STEPPER_SIZE_SM = 40;

const ICON_SIZE_XS = 24;
const ICON_SIZE_SM = 30;

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapperXs: {
    position: 'absolute',
    bottom: QUANTITY_STEPPER_SIZE_XS / 2,
    gap: theme.spacing['3'],
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperSm: {
    position: 'absolute',
    bottom: QUANTITY_STEPPER_SIZE_SM / 2,
    gap: theme.spacing['3'],
    flexDirection: 'row',
    alignItems: 'center',
  },

  iconXs: {
    width: QUANTITY_STEPPER_SIZE_XS,
    height: QUANTITY_STEPPER_SIZE_XS,
  },
  iconSm: {
    width: QUANTITY_STEPPER_SIZE_SM,
    height: QUANTITY_STEPPER_SIZE_SM,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type ProductFooterProps = Readonly<{
  quantity: number;
  isDisabled: boolean;
  currentQuantityA11y?: string;
  increaseQuantityA11y?: string;
  decreaseQuantityA11y?: string;
  onQuantityChangeStart: () => void;
  onQuantityChangeEnd: () => void;
  onQuantityChange: (selectedQuantity: number) => void;
}>;

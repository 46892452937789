import React from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';
import { theme } from '@sg/garnish';

/**
 * Container for the bag promo rewards
 */
export const BagPromoRewardsContainer = (props: ViewProps) => {
  return <View style={styles.container}>{props.children}</View>;
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingVertical: theme.spacing['6'],
    gap: theme.spacing['4'],
    borderBottomColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    borderBottomWidth: theme.spacing['2'],
    marginHorizontal: -theme.spacing['4'],
    paddingHorizontal: theme.spacing['4'],
  },
});

import React, { useMemo } from 'react';
import type { AccessibilityProps } from 'react-native';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { theme } from '@garnish/constants';

import { IllusEmpty_2 } from '../../../../assets';
import { useResponsive } from '../../../../hooks';
import { FallbackImage } from '../../../Image';

// ─────────────────────────────────────────────────────────────────────────────

export const BenefitCardImg = (props: BenefitCardImgProps) => {
  const { imgUrl, accessibilityLabel } = props;

  const { match } = useResponsive();
  const { scale } = useWindowDimensions();
  const displayScale = Math.ceil(scale);

  // ─── Illustration ────────────────────────────────────────────────────

  const illustrationSize = match(BENEFIT_ILLUSTRATION_RESPONSIVE_SIZES);
  const illustrationScaledSize = illustrationSize * displayScale;
  const imgStyles = match([styles.imgXS, styles.imgSM, styles.imgMD]);

  const contentfulOptions = useMemo(
    () => ({
      fm: 'webp' as const,
      q: 90,
      w: illustrationScaledSize,
      h: illustrationScaledSize,
    }),
    [illustrationScaledSize],
  );

  return (
    <FallbackImage
      contentFit="contain"
      defaultImage={IllusEmpty_2}
      baseUrl={imgUrl}
      style={imgStyles}
      contentfulOptions={contentfulOptions}
      aria-label={accessibilityLabel}
    />
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const BENEFIT_ILLUSTRATION_RESPONSIVE_SIZES = [80, 120, 200];
const IMG_SIZE_XS = 80;
const IMG_SIZE_SM = 120;
const IMG_SIZE_LG = 200;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  imgXS: {
    width: IMG_SIZE_XS,
    height: IMG_SIZE_XS,
    marginRight: theme.spacing['4'],
  },
  imgSM: {
    width: IMG_SIZE_SM,
    height: IMG_SIZE_SM,
    marginBottom: theme.spacing['4'],
  },
  imgMD: {
    width: IMG_SIZE_LG,
    height: IMG_SIZE_LG,
    marginBottom: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BenefitCardImgProps = Readonly<{
  imgUrl: string;
  accessibilityLabel?: AccessibilityProps['accessibilityLabel'];
}>;

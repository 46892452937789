import React, { useCallback } from 'react';
import { NumberFieldWithBackground } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

export const TipsGroupCustomTip = (props: TipsGroupCustomTipProps) => {
  const { tipAmountInCents, orderSubtotalInCents, onChange } = props;

  const { t, formatPrice } = useLocalizationContext();

  // NOTE: We use cents for tip calculations and dollar values for presentation
  const displayTipAmount = tipAmountInCents / 100;
  const hasExceedMaxTipAmount = tipAmountInCents > orderSubtotalInCents;

  const customTipNoticeText = t('checkout.tip.custom-field-max-notice', {
    maxAmount: formatPrice(orderSubtotalInCents, 'USD'),
  });
  const customTipNotice = hasExceedMaxTipAmount
    ? customTipNoticeText
    : undefined;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onCustomTipAmountChange = useCallback(
    (tipAmountNumericValue: string) => {
      const tipAmountAsNumber = Number(tipAmountNumericValue);
      const isValidTipAmount = validateCustomTipAmount(tipAmountAsNumber);

      const tipAmountAsNumberInCents = tipAmountAsNumber * 100;
      const tipAmountWithFallback = isValidTipAmount
        ? tipAmountAsNumberInCents
        : 0;

      onChange(tipAmountWithFallback);
    },
    [onChange],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <NumberFieldWithBackground
      accessibilityLabel={t('checkout.tip.custom-field')}
      placeholder={t('checkout.tip.custom-field')}
      value={displayTipAmount <= 0 ? undefined : displayTipAmount}
      onChangeText={onCustomTipAmountChange}
      min={0}
      maxDecimals={2}
      invalid={hasExceedMaxTipAmount}
      noticePalette="caution"
      notice={customTipNotice}
      textPrepend="$"
      autoFocus
      returnKeyType="done"
    />
  );
};

// ─── Utils ───────────────────────────────────────────────────────────────────

function validateCustomTipAmount(tipAmount: number) {
  return Number.isFinite(tipAmount) && tipAmount > 0;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type TipsGroupCustomTipProps = Readonly<{
  tipAmountInCents: number;
  orderSubtotalInCents: number;
  onChange: (topAmount: number) => void;
}>;

/* eslint-disable react/no-danger */

import React, {
  type ComponentProps,
  useCallback,
  useRef,
  useState,
} from 'react';
import { DayPicker } from 'react-day-picker';
import {
  type LayoutChangeEvent,
  Pressable,
  StyleSheet,
  View,
} from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { FONTS, theme, TYPE_CONFIG, WEIGHT_MAP } from '@garnish/constants';
import { format } from 'date-fns';
import 'react-day-picker/dist/style.css';

import {
  useBrowserEventListener,
  usePressableState,
  useToggleState,
  useWebKeyListener,
} from '../../../../hooks';
import { webOnlyStyles } from '../../../../utils';
import { Icon } from '../../../Icon';
import { Select } from '../../../Select';
import type { ValueModel as SelectedValue } from '../../../Select/Select.types';
import { HStack } from '../../../Stack';
import { BodyText } from '../../../Text';
import { type DateAndHourSelectorsProps } from './DateAndHourSelectors.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * `react-day-picker` based date picker component.
 *
 * NOTE: `DateAndHourSelectors` is web-only and is used on large breakpoints.
 *       `DateAndHourPickerGroup` is used in other scenarios.
 */
export const DateAndHourSelectors = (props: DateAndHourSelectorsProps) => {
  const {
    fromDate,
    toDate,
    selectedDate,
    selectedDateHourOptions,
    'aria-label': ariaLabel,
    labels,
    onChange,
  } = props;

  // ─── Refs ────────────────────────────────────────────────────────────

  const datePickerContainerRef = useRef<View>(null);
  const datePickerTriggerRef = useRef<View>(null);

  // ─── State ───────────────────────────────────────────────────────────

  const {
    value: isDatepickerShown,
    toggle: toggleIsDatepickerShown,
    toggleOff: hideDatepicker,
  } = useToggleState();
  const [offsetY, setOffsetY] = useState(theme.spacing['10']);

  // ─── Derived Data ────────────────────────────────────────────────────

  const hasSelectedDate = selectedDate !== undefined;
  const dateDynamicLabel = hasSelectedDate
    ? format(selectedDate, 'MM-dd-yyyy')
    : labels.day;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const setDatePickerOffset = useCallback((event: LayoutChangeEvent) => {
    setOffsetY(event.nativeEvent.layout.height);
  }, []);

  const onDateChange = useCallback(
    (value: Date | undefined) => {
      onChange(value);
      hideDatepicker();
    },
    [onChange, hideDatepicker],
  );

  const onHourOptionChange = useCallback(
    (dateIsoString: SelectedValue) => {
      onChange(new Date(dateIsoString));
    },
    [onChange],
  );

  const dismissOnPressOutside = useCallback(
    (event: MouseEvent) => {
      const ref = datePickerContainerRef.current as unknown as HTMLDivElement;
      const targetNode = event.target as Node;
      const shouldCloseDatePicker = !ref?.contains(targetNode);

      if (!shouldCloseDatePicker) return;

      hideDatepicker();
    },
    [hideDatepicker],
  );

  // ─── Styles ──────────────────────────────────────────────────────────

  const datePickerTriggerState = usePressableState(datePickerTriggerRef);

  const datePickerContainerDynamicStyles = useStyle(
    () => ({ top: offsetY + theme.spacing['2'] }),
    [offsetY],
  );
  const datePickerContainerStyles = [
    styles.datePickerContainer,
    datePickerContainerDynamicStyles,
  ];

  const datePickerTriggerStyle = [
    styles.trigger,
    datePickerTriggerState.isHovered && styles.triggerHover,
    datePickerTriggerWebStyles,
  ];
  const labelStyles = hasSelectedDate ? styles.labelSelected : styles.label;

  // ─── Effects ─────────────────────────────────────────────────────────

  useBrowserEventListener(
    window.document,
    'click',
    dismissOnPressOutside,
    CLICK_EVENT_LISTENER_OPTIONS,
  );
  useWebKeyListener('Escape', hideDatepicker);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <View>
        <HStack itemsPerRow={2} gap={theme.spacing['6']}>
          <Pressable
            ref={datePickerTriggerRef}
            accessibilityRole="button"
            role="button"
            style={datePickerTriggerStyle}
            onPress={toggleIsDatepickerShown}
            aria-label={ariaLabel}
            onLayout={setDatePickerOffset}
          >
            <BodyText sizeMatch={['14']} style={labelStyles}>
              {dateDynamicLabel}
            </BodyText>

            <Icon name="IconCalendar" />
          </Pressable>

          <Select
            variation="box"
            value={selectedDate.toISOString()}
            options={selectedDateHourOptions}
            onSelect={onHourOptionChange}
            accessibilityLabel={labels.time}
          />
        </HStack>

        {isDatepickerShown ? (
          <View ref={datePickerContainerRef} style={datePickerContainerStyles}>
            <DayPicker
              mode="single"
              selected={selectedDate}
              onSelect={onDateChange}
              defaultMonth={selectedDate}
              fromDate={fromDate}
              toDate={toDate}
              style={DAY_PICKER_STYLE_VARIABLES}
              formatters={DAY_PICKER_FORMATTERS}
              styles={DAY_PICKER_STYLES}
            />
          </View>
        ) : null}
      </View>

      {/* Because `react-day-picker` does not allow for full customization,
          we have included some inline CSS properties here. */}
      <style dangerouslySetInnerHTML={DAY_PICKER_INLINE_WEB_STYLES} />
    </>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const DAY_PICKER_FORMATTERS: DayPickerProps['formatters'] = {
  formatWeekdayName(date) {
    return format(date, 'E');
  },
};

const CLICK_EVENT_LISTENER_OPTIONS: AddEventListenerOptions = {
  capture: true, // Capture all `click` events, even those that should not be propagated.
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  datePickerContainer: {
    position: 'absolute',
    left: 0,
  },
  trigger: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderWidth: 1,
    borderColor: theme.colors.GRAY,
    paddingLeft: theme.spacing['2'],
    paddingRight: 6,
    borderRadius: theme.radius.small,
    minHeight: 32,
  },
  triggerHover: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
  label: {
    color: theme.colors.CHARCOAL,
  },
  labelSelected: {
    color: theme.colors.BLACK,
  },
});

const datePickerTriggerWebStyles = webOnlyStyles({
  transition: `background-color ${theme.transitions.base}ms`,
});

const DAY_PICKER_BODY_TEXT = TYPE_CONFIG.BODY['16'];
const DAY_PICKER_NAV_BUTTON_STYLES = {
  width: 28,
  height: 28,
  backgroundColor: theme.colors.CREAM,
};

const DAY_PICKER_STYLES: DayPickerStyles = {
  root: {
    margin: 0,
    backgroundColor: theme.colors.WHITE,
    borderRadius: theme.radius.xlarge,
    padding: theme.spacing['4'],
    fontFamily: DAY_PICKER_BODY_TEXT.fontFamily,
    fontSize: DAY_PICKER_BODY_TEXT.fontSize,
    fontWeight: DAY_PICKER_BODY_TEXT.fontWeight,
    letterSpacing: DAY_PICKER_BODY_TEXT.letterSpacing,
    boxShadow: `0 8px 36px 0 ${theme.colors.OPACITY.DARK_KALE.LIGHTEST}`,
  },
  head: {
    fontFamily: FONTS.SWEET_SANS_TEXT_BOLD,
    fontWeight: WEIGHT_MAP['semi bold'],
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.GRAY,
  },
  caption_label: {
    color: theme.colors.SPINACH,
    fontFamily: FONTS.SWEET_SANS_TEXT_BOLD,
    fontWeight: WEIGHT_MAP['semi bold'],
  },
  cell: {
    color: theme.colors.TEXT_COLOR,
  },
  nav: {
    display: 'flex',
    gap: theme.spacing['2'],
  },
  nav_button_next: DAY_PICKER_NAV_BUTTON_STYLES,
  nav_button_previous: DAY_PICKER_NAV_BUTTON_STYLES,
  nav_icon: {
    color: theme.colors.SPINACH,
  },
  caption: {
    marginBottom: theme.spacing['4'],
  },
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const DAY_PICKER_STYLE_VARIABLES = {
  '--rdp-selected-color': theme.colors.WHITE,
  '--rdp-background-color': theme.colors.CUCUMBER,
  '--rdp-accent-color': theme.colors.SPINACH,
  '--rdp-cell-size': '36px',
} as DayPickerProps['style'];

// NOTE: Should be used in an inline `style` component as the
//       `dangerouslySetInnerHTML` property.
const DAY_PICKER_INLINE_WEB_STYLES: ComponentProps<'style'>['dangerouslySetInnerHTML'] =
  {
    __html: `
.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: ${theme.colors.SPINACH} !important;
  color: ${theme.colors.WHITE} !important;
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) > svg {
  color: ${theme.colors.WHITE} !important;
}`,
  };

// ─── Types ───────────────────────────────────────────────────────────────────

type DayPickerStyles = DayPickerProps['styles'];

type DayPickerProps = ComponentProps<typeof DayPicker>;

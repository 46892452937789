import { defineMessages } from 'react-intl';

export const redeemGiftCardFormMessages = defineMessages({
  submitButtonLabel: {
    defaultMessage: 'Redeem',
    description: 'Redeem Gift Card Form | Form submit button label',
  },
  submitButtonLabelLegacy: {
    defaultMessage: 'Add gift card',
    description: 'Add Gift Card Screen | Form submit button legacy label',
  },
});

import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { theme } from '@sg/garnish';

import { TierName } from '@order/graphql';
import { useSweetpassTier } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import {
  ScanInStoreCta,
  ScanInStoreTabContentContainer,
} from '../../components/';
import {
  ScanInStoreEarnAndRedeemCard,
  ScanInStoreEarnAndRedeemEmptyState,
} from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanInStoreEarnAndRedeem = () => {
  const { t } = useLocalizationContext();
  const { navigate } = useNavigation();
  const { track } = useTelemetry();

  // ─── Sweetpass Tier State ────────────────────────────────────────────

  const { sweetpassTierName: tier } = useSweetpassTier();
  const hasSweetpassSubscription = Boolean(tier) && tier !== TierName.OptedOut;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToSweetpassHome = useCallback(() => {
    navigate('MainTabs', {
      screen: 'LoyaltyTab',
      params: { screen: 'SweetpassHome' },
    });
  }, [navigate]);

  const navigateToSweetpassChallenges = useCallback(() => {
    track('scan_viewchallenges_cta.press');

    navigate('MainTabs', {
      screen: 'LoyaltyTab',
      params: {
        screen: 'SweetpassHome',
        params: { target: 'challenges' },
      },
    });
  }, [navigate, track]);

  // ─────────────────────────────────────────────────────────────────────

  if (hasSweetpassSubscription) {
    return (
      <ScanInStoreTabContentContainer style={styles.container}>
        <ScanInStoreEarnAndRedeemCard.WithSubscription />

        <ScanInStoreCta
          containerStyle={styles.ctaContainer}
          onPress={navigateToSweetpassChallenges}
        >
          {t('scan-in-store.tabs.earn-and-redeem.cta.title')}
        </ScanInStoreCta>
      </ScanInStoreTabContentContainer>
    );
  }

  return (
    <ScanInStoreTabContentContainer style={styles.container}>
      <ScanInStoreEarnAndRedeemEmptyState
        onPressCta={navigateToSweetpassHome}
      />
    </ScanInStoreTabContentContainer>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing['8'],
  },
  ctaContainer: {
    marginTop: theme.spacing['6'],
  },
});

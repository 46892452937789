/**
 * @see {@link https://notifee.app/react-native/docs/android/channels Notifee docs | Channels}
 */
export const ORDER_FEEDBACK_NOTIFICATIONS_CHANNEL_ID =
  'orders-feedback-channel';

/**
 * @see {@link https://notifee.app/react-native/docs/events#foreground-events Notifee docs | Foreground Events}
 */
export const ORDER_FEEDBACK_NOTIFICATION_PRESS_EVENT = 1;

/**
 * @see {@link https://notifee.app/react-native/docs/ios/interaction#press-action Notifee docs | Press Action }
 */
export const ORDER_FEEDBACK_NOTIFICATION_PRESS_ACTION_ID = 'default';

/**
 * @see {@link https://notifee.app/react-native/reference/timestamptrigger Notifee docs | TimestampTrigger}
 */
export const ORDER_FEEDBACK_NOTIFICATION_TIMESTAMP_TYPE = 0;

/**
 * @see {@link https://notifee.app/react-native/reference/androidvisibility Notifee docs | AndroidVisibility }
 */
export const ORDER_FEEDBACK_NOTIFICATION_ANDROID_VISIBILITY_PUBLIC = 1;

/**
 * @see {@link https://notifee.app/react-native/docs/android/appearance#high Notifee docs | High importance }
 */
export const ORDER_FEEDBACK_NOTIFICATION_ANDROID_IMPORTANCE_HIGH = 4;

/**
 * @see {@link https://notifee.app/react-native/docs/android/appearance#color Notifee docs | Color }
 */
export const ORDER_FEEDBACK_NOTIFICATION_ANDROID_ICON_NAME =
  'ic_stat_onesignal_default';

import {
  CustomNavigationHeaderButton,
  CustomNavigationHeaderContainer,
  CustomNavigationHeaderContent,
  CustomNavigationHeaderItem,
  CustomNavigationHeaderLink,
  CustomNavigationHeaderText,
} from './components';

export const CustomNavigationHeader = {
  Container: CustomNavigationHeaderContainer,
  Content: CustomNavigationHeaderContent,
  Button: CustomNavigationHeaderButton,
  Item: CustomNavigationHeaderItem,
  Text: CustomNavigationHeaderText,
  Link: CustomNavigationHeaderLink,
};

import { useEffect, useRef } from 'react';
import { Animated, Platform } from 'react-native';

import type { AnimationOptions, UseIndicatorAnimation } from '../TabNav.types';

export const useIndicatorAnimation: UseIndicatorAnimation = (props) => {
  const { currentIndex, measures, disableAnimation } = props;
  const animation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const toValue = currentIndex === -1 ? 0 : currentIndex;
    const ANIMATION_TIMING_CONFIG = {
      toValue,
      useNativeDriver: Platform.OS !== 'web',
      duration: disableAnimation ? 0 : 150,
    };

    Animated.timing(animation, ANIMATION_TIMING_CONFIG).start();
  }, [animation, currentIndex, disableAnimation]);

  const { inputRange, scale, translate } = measures.reduce<AnimationOptions>(
    (measure, { width, x }, index) => ({
      scale: [...measure.scale, width],
      inputRange: [...measure.inputRange, index],
      translate: [...measure.translate, x + width / 2],
    }),
    { inputRange: [], translate: [], scale: [] },
  );

  return {
    scaleX: animation.interpolate({ inputRange, outputRange: scale }),
    translateX: animation.interpolate({ inputRange, outputRange: translate }),
  };
};

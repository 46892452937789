/* eslint-disable functional/immutable-data */

import type { ReactNode } from 'react';
import React from 'react';
import type { ImageSourcePropType, ImageStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  IllusSupplyChain,
  Image,
  theme,
  TitleText,
  TYPE_CONFIG,
  useResponsive,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const LandingContent = (props: LandingContentProps) => {
  const { children } = props;

  return <View style={styles.content}>{children}</View>;
};

// ─────────────────────────────────────────────────────────────────────────────

const LandingContentIllustration = (props: LandingContentIllustrationProps) => {
  const { source = IllusSupplyChain, style } = props;

  return (
    <Image
      // @ts-expect-error TS(2322): Type 'ViewStyle | TextStyle | ImageStyle' is not a... Remove this comment to see the full error message
      style={[styles.image, style]}
      source={source}
      resizeMode="cover"
    />
  );
};

const LandingContentTitle = (props: LandingContentTitleProps) => {
  const { children } = props;

  const { match } = useResponsive();

  return (
    <TitleText
      style={[styles.title, match([undefined, styles.titleSM])]}
      size={5}
    >
      {children}
    </TitleText>
  );
};

const LandingContentSubtitle = (props: LandingContentSubitleProps) => {
  const { children } = props;

  return <BodyText style={styles.subtitle}>{children}</BodyText>;
};

// ─────────────────────────────────────────────────────────────────────────────

LandingContent.Illustration = LandingContentIllustration;
LandingContent.Title = LandingContentTitle;
LandingContent.Subtitle = LandingContentSubtitle;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: theme.spacing['10'],
    paddingBottom: theme.spacing['6'],
  },
  image: {
    width: 280,
    height: 166,
    marginBottom: theme.spacing['4'],
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing['4'],
  },
  // @ts-expect-error TS(2322): Type '{ fontSize: number; fontFamily: string; font... Remove this comment to see the full error message
  titleSM: {
    // We override fluid text styles because we need static 32px styles starting from
    // the `SM` breakpoint, which the present fluid text styles cannot provide.
    ...TYPE_CONFIG.TITLE['32'],
  },
  subtitle: {
    color: theme.colors.CHARCOAL,
    textAlign: 'center',
    marginBottom: theme.spacing['4'],

    // We override fluid text styles because we need static 18px styles,
    // which the present fluid text styles cannot provide.
    ...TYPE_CONFIG.BODY['18'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LandingContentTitleProps = Readonly<{
  children: string;
}>;

type LandingContentSubitleProps = Readonly<{
  children: string;
}>;

type LandingContentIllustrationProps = Readonly<{
  source?: ImageSourcePropType;
  style?: ImageStyle;
}>;

type LandingContentProps = Readonly<{
  children?: ReactNode;
}>;

import { useMemo } from 'react';

import { type useLoyaltyContent } from '@order/hooks';

/**
 * Here are examples of progress benefits:
 *
 * 0 Points State: You’ll earn 10 points for every $1 spent
 * >0 <2500 only pending state: X points are on the way!
 * >0 <500: A free crispy rice treat is X points away (difference between spendable points balance and 500)
 * >500 <700 A free beverage is X points away (difference between spendable points balance and 700)
 * >700 <1000 A free crispy potato is X points away (difference between spendable points balance and 1000)
 * >1000 <1500 50% off your entree is X points away (difference between spendable points balance and 1500)
 * >1500 <2500 A free bowl is X points away (difference between spendable points balance and 500)
 *
 * The hook below will select the appropriate benefit based on the amount of points the customer has.
 * Then replace the {points} placeholder with how many points are left to acquire that benefit.
 */
export const useHomeProgressBenefit = (
  params: UseHomeProgressBenefitParams,
) => {
  const { availablePoints, pendingPoints, homeProgressBenefits } = params;

  return useMemo(() => {
    const isNoPoints = availablePoints === 0 && pendingPoints === 0;
    const isPendingPointsOnly = availablePoints === 0 && pendingPoints > 0;

    if (isNoPoints) {
      const noPointsBenefit = homeProgressBenefits.find(
        (benefit) => benefit.maxPoints === 0,
      );

      return noPointsBenefit?.title;
    }

    if (isPendingPointsOnly) {
      const pendingBenefit = homeProgressBenefits.find(
        (benefit) => benefit.isPendingBenefit,
      );

      return pendingBenefit?.title?.replaceAll(
        '{points}',
        String(pendingPoints),
      );
    }

    const matchingBenefit = homeProgressBenefits.find(
      (benefit) => availablePoints < benefit.maxPoints,
    );

    return matchingBenefit?.title?.replaceAll(
      '{points}',
      String(matchingBenefit.maxPoints - availablePoints),
    );
  }, [availablePoints, pendingPoints, homeProgressBenefits]);
};

// ─── Types ──────────────────────────────────────────────────────────────────

type UseHomeProgressBenefitParams = {
  availablePoints: number;
  pendingPoints: number;
  homeProgressBenefits: ReturnType<
    typeof useLoyaltyContent
  >['homeProgressBenefits'];
};

export type GoogleAnalyticsHelpers = Readonly<{
  initialize: () => void;
  track: (payload: GoogleAnalyticsEventPayload) => void;
  resetAnalyticsData: () => void;
}>;

export type GoogleAnalyticsEventPayload = Readonly<{
  [key: string]: unknown;
  event: string;
}>;

import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

import { type RedeemGiftCardFormData } from '../../RedeemGiftCardForm.types';
import { useRedeemGiftCard } from '../useRedeemGiftCard';

// ────────────────────────────────────────────────────────────────────────────────

export const useRedeemGiftCardForm = (params?: useRedeemGiftCardFormParams) => {
  const { onRedeemSubmit, onRedeemSuccess, onRedeemError } = params ?? {};

  const { t } = useLocalizationContext();
  const { redeemGiftCard } = useRedeemGiftCard();

  const isGiftCardRegCodeEnabled = useFeatureFlag(
    'CELS-1410-enable-gift-card-reg-code',
  );

  // ────────────────────────────────────────────────────────────────────

  const requiredGiftCardMessage = t(
    'redeem-gift-card-form.error.inline.gift-card-number',
  );
  const requiredRegistrationCodeMessage = t(
    'redeem-gift-card-form.error.inline.registration-code',
  );

  const validationSchema = useMemo(() => {
    const giftCardCodeValidation = yup
      .string()
      .required(requiredGiftCardMessage);
    const registrationCodeValidation = yup
      .string()
      .required(requiredRegistrationCodeMessage);

    if (isGiftCardRegCodeEnabled) {
      return yup
        .object({
          giftCardCode: giftCardCodeValidation,
          registrationCode: registrationCodeValidation,
        })
        .required();
    }

    return yup.object({ giftCardCode: giftCardCodeValidation }).required();
  }, [
    isGiftCardRegCodeEnabled,
    requiredGiftCardMessage,
    requiredRegistrationCodeMessage,
  ]);

  // ────────────────────────────────────────────────────────────────────

  const form = useForm<RedeemGiftCardFormData>({
    defaultValues: { giftCardCode: '', registrationCode: '' },
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });
  const { handleSubmit } = form;

  const submitForm = useMemo(() => {
    return handleSubmit(async (formData) => {
      const { giftCardCode, registrationCode } = formData;

      onRedeemSubmit?.();

      const payload = isGiftCardRegCodeEnabled
        ? { code: giftCardCode, regCode: registrationCode }
        : { code: giftCardCode };

      const response = await redeemGiftCard(payload);
      const responseTypename = response?.data?.redeemGiftCard.__typename;
      const hasRedeemedGiftCard = responseTypename === 'GiftCardBalance';

      if (!hasRedeemedGiftCard) {
        onRedeemError?.({
          userError: responseTypename,
          systemError: response?.error,
        });

        return;
      }

      onRedeemSuccess?.();
      form.reset();
    });
  }, [
    form,
    handleSubmit,
    isGiftCardRegCodeEnabled,
    onRedeemError,
    onRedeemSubmit,
    onRedeemSuccess,
    redeemGiftCard,
  ]);

  // ────────────────────────────────────────────────────────────────────

  return { form, submitForm };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type useRedeemGiftCardFormParams = Readonly<{
  onRedeemSubmit?: () => void;
  onRedeemSuccess?: () => void;
  onRedeemError?: (payload: {
    userError?: string;
    systemError?: string;
  }) => void;
}>;

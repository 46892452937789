import { type MutableRefObject, useCallback, useEffect } from 'react';
import {
  type LayoutChangeEvent,
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  type ScrollView,
} from 'react-native';
import { useSharedValue } from 'react-native-reanimated';
import { useInterpret, useSelector } from '@xstate/react';

import { scrollspyMachine } from './machines';

// ─────────────────────────────────────────────────────────────────────

/**
 * A custom hook that gives context and helpers to work with the scrollspy
 * state machine.
 */
export const useScrollspy = (params?: UseScrollspyParams) => {
  const { defaultActiveTargetId, onActiveTargetIdChange } = params ?? {};

  // ─── Machine ─────────────────────────────────────────────────────────

  const scrollSpyMachineActor = useInterpret(scrollspyMachine, {
    context: { defaultActiveTargetId },
  });

  const { send } = scrollSpyMachineActor;

  // ─── Animated Values ─────────────────────────────────────────────────

  // We track scroll view offset with a shared value that may be used to
  // create animated styles based on its value.
  const scrollViewOffsetSV = useSharedValue(0);

  // ─── Context ─────────────────────────────────────────────────────────

  const activeTargetId = useSelector(scrollSpyMachineActor, (snapshot) => {
    return snapshot.context.state.activeTargetId;
  });

  // ─── Helpers | Nav Scroll View ───────────────────────────────────────

  const registerNavScrollView = useCallback(
    (ref: ScrollSpyScrollViewRef) => {
      send({ type: 'nav.register', ref });
    },
    [send],
  );

  const deregisterNavScrollView = useCallback(() => {
    send({ type: 'nav.deregister' });
  }, [send]);

  const storeNavScrollViewSize = useCallback(
    (event: LayoutChangeEvent) => {
      const { width, height } = event.nativeEvent.layout;

      send({ type: 'nav.store-size', width, height });
    },
    [send],
  );

  const trackNavScrollViewScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      send({ type: 'nav.scroll', scrollEvent: event });
    },
    [send],
  );

  // ─── Helpers | Scroll View ───────────────────────────────────────────

  const registerScrollView = useCallback(
    (ref: ScrollSpyScrollViewRef) => {
      send({ type: 'scroll-view.register', ref });
    },
    [send],
  );

  const deregisterScrollView = useCallback(() => {
    send({ type: 'scroll-view.deregister' });
  }, [send]);

  const storeScrollViewSize = useCallback(
    (event: LayoutChangeEvent) => {
      const { width, height } = event.nativeEvent.layout;

      send({ type: 'scroll-view.store-size', width, height });
    },
    [send],
  );

  const storeScrollViewContentSize = useCallback(
    (width: number, height: number) => {
      send({ type: 'scroll-view.store-content-size', width, height });
    },
    [send],
  );

  const trackScrollViewScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      send({ type: 'scroll-view.scroll', scrollEvent: event });

      // eslint-disable-next-line functional/immutable-data
      scrollViewOffsetSV.value = event.nativeEvent.contentOffset.y;
    },
    [scrollViewOffsetSV, send],
  );

  // ─── Helpers | Nav Items ─────────────────────────────────────────────

  const registerNavItem = useCallback(
    (itemId: string, itemRef: ScrollSpyTargetRef) => {
      send({ type: 'nav-item.register', itemId, itemRef });
    },
    [send],
  );

  const deregisterNavItem = useCallback(
    (itemId: string) => {
      send({ type: 'nav-item.deregister', itemId });
    },
    [send],
  );

  // ─── Helpers | Targets ───────────────────────────────────────────────

  const registerTarget = useCallback(
    (targetId: string, targetRef: ScrollSpyNavItemRef) => {
      send({ type: 'target.register', targetId, targetRef });
    },
    [send],
  );

  const deregisterTarget = useCallback(
    (targetId: string) => {
      send({ type: 'target.deregister', targetId });
    },
    [send],
  );

  const setActiveTargetId = useCallback(
    (targetId: string) => {
      send({ type: 'target.set-active', targetId });
    },
    [send],
  );

  // ─── Effects ─────────────────────────────────────────────────────────

  // Track active target ID change
  useEffect(() => {
    if (!activeTargetId) return;

    send({ type: 'nav.scroll-to-active' });
    onActiveTargetIdChange?.(activeTargetId);
  }, [activeTargetId, onActiveTargetIdChange, send]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    activeTargetId,
    setActiveTargetId,
    helpers: {
      navScrollView: {
        register: registerNavScrollView,
        deregister: deregisterNavScrollView,
        storeSize: storeNavScrollViewSize,
        trackScroll: trackNavScrollViewScroll,
      },
      navItem: {
        register: registerNavItem,
        deregister: deregisterNavItem,
      },
      scrollView: {
        register: registerScrollView,
        deregister: deregisterScrollView,
        storeSize: storeScrollViewSize,
        storeContentSize: storeScrollViewContentSize,
        trackScroll: trackScrollViewScroll,
      },
      target: {
        register: registerTarget,
        deregister: deregisterTarget,
      },
    },
    sharedValues: {
      scrollViewOffset: scrollViewOffsetSV,
    },
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseScrollspyParams = {
  defaultActiveTargetId?: string;
  onActiveTargetIdChange?: (targetId: string) => void;
};

type ScrollSpyScrollViewRef = MutableRefObject<ScrollView | null>;

type ScrollSpyNavItemRef = MutableRefObject<unknown>;

type ScrollSpyTargetRef = MutableRefObject<unknown>;

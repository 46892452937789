import React from 'react';
import type { AccessibilityProps } from 'react-native';
import { StyleSheet, View } from 'react-native';

import {
  BenefitCardContainer,
  BenefitCardImg,
  BenefitCardText,
  BenefitCardTitle,
} from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const BenefitCard = (props: BenefitCardProps) => {
  const { title, text, imgUrl, imgA11yLabel, isUnavailable } = props;

  return (
    <BenefitCardContainer isUnavailable={isUnavailable}>
      {imgUrl ? (
        <BenefitCardImg imgUrl={imgUrl} accessibilityLabel={imgA11yLabel} />
      ) : null}

      <View style={styles.textContent}>
        <BenefitCardTitle>{title}</BenefitCardTitle>
        <BenefitCardText>{text}</BenefitCardText>
      </View>
    </BenefitCardContainer>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  textContent: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BenefitCardProps = Readonly<{
  title: string;
  text: string;
  imgUrl?: string;
  imgA11yLabel?: AccessibilityProps['accessibilityLabel'];
  isUnavailable?: boolean;
}>;

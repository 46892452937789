import { useCallback } from 'react';
import { useActor } from '@xstate/react';

import { useGlobalAppState } from '@order/GlobalAppState';

import { useBagRestaurantIds } from '../../../../components/Bag/Bag.hooks';
import { useReorderNavigation } from '../useReorderNavigation';
import { logger } from '../utils';

export const useCancelReorder = () => {
  const { reorderMachineRef } = useGlobalAppState();
  const [state, sendReorderEvent] = useActor(reorderMachineRef);
  const { bagRestaurantSlug, bagAddressId } = useBagRestaurantIds();
  const { dismissReorder, dismissReorderNewMenu } = useReorderNavigation();

  return useCallback(
    (options?: CancelReorderOptions) => {
      // ─── Dismiss Destinations ───────────────────────────────────
      const dismissToSelectedMenu = Boolean(options?.dismissToSelectedMenu);
      const dismissBagMenu = Boolean(state.context.dismissToBagMenu);
      const hasBagIds = Boolean(bagRestaurantSlug) || Boolean(bagAddressId);

      // ─── State Machine ──────────────────────────────────────────
      sendReorderEvent('CANCEL_REORDER');

      // ─── Dismiss to Selected Menu ───────────────────────────────
      if (dismissToSelectedMenu) {
        logger.info('Cancel Reorder to Selected Menu');
        dismissReorderNewMenu(
          state.context.restaurantSlug,
          state.context.deliveryOrderDetail?.addressId,
        );

        return;
      }

      // ─── Dismiss to Bag Menu ────────────────────────────────────
      if (dismissBagMenu && hasBagIds) {
        logger.info('Cancel Reorder to Bag Menu');
        dismissReorderNewMenu(bagRestaurantSlug, bagAddressId);

        return;
      }

      // ─── Dismiss without extra navigation ───────────────────────
      logger.info('Cancel Reorder without extra navigation');
      dismissReorder();
    },
    [
      state.context.dismissToBagMenu,
      state.context.restaurantSlug,
      state.context.deliveryOrderDetail?.addressId,
      bagRestaurantSlug,
      bagAddressId,
      sendReorderEvent,
      dismissReorderNewMenu,
      dismissReorder,
    ],
  );
};

type CancelReorderOptions = Readonly<{
  dismissToSelectedMenu?: boolean;
}>;

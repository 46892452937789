import React, { useCallback, useMemo, useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useResponsive, useToggleState } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { AppFooter, LoadingAnimation, NotFoundView } from '@order/components';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry, useTrackEventOnFocus } from '@order/Telemetry';

import {
  GiftCardsErrorView,
  GiftCardsSignedOutModal,
  GiftCardsWrapper,
} from './components';
import { useGiftCards } from './hooks';
import {
  GiftCardsScreenCategoriesGrid,
  GiftCardsScreenCategoriesNav,
  GiftCardsScreenHeader,
} from './sections';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsScreen = () => {
  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );

  const { data, error, fetching } = useGiftCards();
  const { heading, subheading, subheadingLinkText, giftCardCategories } = data;

  const { track } = useTelemetry();
  const isSignedIn = useIsLoggedIn();
  const { navigate } = useNavigation();
  const { currentBreakpoint } = useResponsive();

  // ─── State ───────────────────────────────────────────────────────────

  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    null,
  );

  const {
    value: isRedeemGiftCardSignedOutModalShown,
    toggle: toggleRedeemGiftCardSignedOutModal,
  } = useToggleState(false);
  const {
    value: isGiftCardSignedOutModalShown,
    toggle: toggleGiftCardSignedOutModal,
  } = useToggleState(false);

  // ─── Derived Data ────────────────────────────────────────────────────

  const filteredCategories = useMemo(
    () =>
      giftCardCategories.filter((category) => {
        const { id: categoryId } = category.fields;

        return !selectedCategoryId || categoryId === selectedCategoryId;
      }),
    [giftCardCategories, selectedCategoryId],
  );

  const hasCategories = filteredCategories.length > 0;
  const shouldRenderGiftCardsList = isInAppGiftCardEnabled && !fetching;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToAddGiftCardScreen = useCallback(() => {
    track('gift.redeem_cta_tapped');

    if (!isSignedIn) {
      toggleRedeemGiftCardSignedOutModal();

      return;
    }

    if (currentBreakpoint.isXS) {
      navigate('Modal', { screen: 'AddGiftCard' });

      return;
    }

    navigate('MainTabs', {
      screen: 'AccountTab',
      params: { screen: 'PaymentAndGiftCards', initial: false },
    });
  }, [
    track,
    isSignedIn,
    currentBreakpoint.isXS,
    navigate,
    toggleRedeemGiftCardSignedOutModal,
  ]);

  const trackScreenLoadingError = useCallback(() => {
    track('gift.view.failure');
  }, [track]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useTrackEventOnFocus(
    { name: 'gift.view.success' },
    { skip: !shouldRenderGiftCardsList },
  );

  // ─────────────────────────────────────────────────────────────────────

  if (!isInAppGiftCardEnabled) {
    return <NotFoundView onMount={trackScreenLoadingError} />;
  }

  if (fetching) {
    return <LoadingAnimation size="large" />;
  }

  return (
    <GiftCardsWrapper>
      <ScrollView
        stickyHeaderIndices={[1]}
        contentContainerStyle={styles.contentContainer}
      >
        <GiftCardsScreenHeader
          heading={heading}
          subheading={subheading}
          subheadingLinkText={subheadingLinkText}
          onSubheadingLinkPress={navigateToAddGiftCardScreen}
        />

        {hasCategories ? (
          <GiftCardsScreenCategoriesNav
            categories={giftCardCategories}
            selectedCategoryId={selectedCategoryId}
            setSelectedCategoryId={setSelectedCategoryId}
          />
        ) : null}

        {error ? <GiftCardsErrorView /> : null}

        {hasCategories ? (
          <GiftCardsScreenCategoriesGrid
            categories={filteredCategories}
            isSignedIn={isSignedIn}
            onSignedOutCardPress={toggleGiftCardSignedOutModal}
          />
        ) : null}

        {currentBreakpoint.isXS ? null : <AppFooter />}
      </ScrollView>

      <GiftCardsSignedOutModal
        isVisible={isRedeemGiftCardSignedOutModalShown}
        toggleModal={toggleRedeemGiftCardSignedOutModal}
        redirectTo="payment-and-gift-cards"
      />
      <GiftCardsSignedOutModal
        isVisible={isGiftCardSignedOutModalShown}
        toggleModal={toggleGiftCardSignedOutModal}
        redirectTo="gift-cards"
      />
    </GiftCardsWrapper>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  contentContainer: {
    minHeight: '100%',
  },
});

/* istanbul ignore file */
/* eslint-disable @typescript-eslint/consistent-type-imports, @typescript-eslint/consistent-type-assertions */

import { Platform } from 'react-native';
import { logger as LOG } from '@garnish/logger';
import Constants from 'expo-constants';
import * as Updates from 'expo-updates';
import { type UpdateEvent, type UpdateFetchResult } from 'expo-updates';
import { assign, createMachine } from 'xstate';

import { checkForInitialUpdate, checkForUpdate } from './services';

// ─────────────────────────────────────────────────────────────────────────────

export const otaUpdateStateMachine = createMachine(
  {
    predictableActionArguments: true,
    tsTypes: {} as import('./ota-update-state-machine.typegen').Typegen0,
    schema: {
      context: {} as OtaUpdateStateMachineContext,
      events: {} as OtaUpdateStateMachineEvents,
      services: {} as OtaUpdateStateMachineServices,
    },

    // ─────────────────────────────────────────────────────────────

    context: {
      availableUpdate: undefined,
      options: {
        initialUpdateEventTimeout: 10_000,
        pollingInterval: 300_000,
      },
    },

    // ─────────────────────────────────────────────────────────────

    initial: 'setup',
    states: {
      setup: {
        description:
          'The initial state where the machine determines the next step based on certain conditions',
        always: [
          {
            target: 'disabled',
            cond: 'checkIfUpdatesDisabled',
          },
          {
            target: '#checking-for-initial-update',
            cond: 'checkIfAutoUpdateEnabled',
          },
          {
            target: '#checking-for-regular-update',
          },
        ],
      },
      disabled: {
        id: 'disabled',
        description:
          'A state that notifies the application that updates are not supported or enabled for the current environment.',
        type: 'final',
      },
      checking: {
        initial: 'forInitialUpdate',
        description: 'An async state that checks for updates.',
        states: {
          forInitialUpdate: {
            id: 'checking-for-initial-update',
            description:
              'An async state that uses the associated service to check for an initial update. If no update is found, polling is triggered.',
            invoke: {
              src: 'checkForInitialUpdate',
              onDone: [
                {
                  target: 'polling',
                  cond: 'checkIfNoInitialUpdateAvailable',
                },
                {
                  target: '#storing-update-context',
                  actions: ['onCheckForUpdateDone'],
                },
              ],
              onError: {
                target: 'forRegularUpdate',
                actions: ['onCheckForUpdateError'],
              },
            },
          },
          forRegularUpdate: {
            id: 'checking-for-regular-update',
            description:
              'An async state that uses the associated service to check for a regular update. If no update is found, polling is triggered.',
            invoke: {
              src: 'checkForUpdate',
              onDone: [
                {
                  target: 'polling',
                  cond: 'checkIfNoNewUpdateIsAvailable',
                },
                {
                  target: '#storing-update-context',
                  actions: ['onCheckForUpdateDone'],
                },
              ],
              onError: {
                target: 'polling',
                actions: ['onCheckForUpdateError'],
              },
            },
          },
          polling: {
            id: 'polling',
            description:
              'A simple polling state that checks for a possible update based on the configurable time interval.',
            after: {
              POLLING_INTERVAL: 'forRegularUpdate',
            },
          },
        },
      },
      storingUpdateContext: {
        id: 'storing-update-context',
        description:
          'An async state that stores the available update context using the provided service.',
        invoke: {
          src: 'storeUpdateContext',
          onDone: '#pending-update',
          onError: '#pending-update',
        },
      },
      pendingUpdate: {
        id: 'pending-update',
        description:
          "An intermediate state in which the app waits for the user's action to apply the update and restart the app or to continue using it (the update will be applied on the next app launch).",
        on: {
          RESTART: '#restarting',
          UPDATE_LATER: '#update-later-polling',
        },
      },
      updateLaterPolling: {
        id: 'update-later-polling',
        description:
          'A simple polling state that trigger another update request based on the configurable time interval.',
        after: {
          POLLING_INTERVAL: '#pending-update',
        },
      },
      restarting: {
        id: 'restarting',
        description:
          'A state in which the app attempts to restart itself. Will enter the "restarting failed" state to tell the user that the restart was unsuccessful and that a manual restart is necessary.',
        invoke: {
          src: 'restartApp',
          onDone: {
            target: '#done',
          },
          onError: {
            target: '#restarting-failed',
            actions: 'onRestartAppError',
          },
        },
      },
      restartingFailed: {
        id: 'restarting-failed',
        description:
          'A state that notifies the user that a manual restart is necessary.',
        type: 'final',
      },
      done: {
        id: 'done',
        description:
          'A state in which the machine has completed all conceivable actions.',
        type: 'final',
      },
    },
  },
  {
    // ─────────────────────────────────────────────────────────────

    services: {
      checkForInitialUpdate,
      checkForUpdate,
      restartApp: Updates.reloadAsync,

      // ─── External Services ───────────────────────────────

      async storeUpdateContext(_context) {
        logger.debug('Please provide a `storeUpdateContext` service');
      },
    },

    // ─────────────────────────────────────────────────────────────

    actions: {
      onCheckForUpdateDone: assign((_, event) => {
        const manifest = event.data?.manifest;
        const appConfig = manifest?.extra?.expoClient;

        const id = manifest?.id;
        const targetVersion = appConfig?.version;

        return {
          availableUpdate: { id, targetVersion },
        };
      }),
      onCheckForUpdateError() {
        logger.debug('Failed to check for an update');
      },
      onRestartAppError() {
        logger.error('Failed to restart the app');
      },
    },

    // ─────────────────────────────────────────────────────────────

    guards: {
      /**
       * Checks whether OTA updates are set to be automatic, which means
       * the app will check for a possible update immediately upon opening.
       *
       * - If automatic updates are enabled, this guard prevents extra checks.
       * - If automatic updates are disabled, we can manually check for updates on launch.
       *
       * @see {@link https://docs.expo.dev/versions/latest/config/app/#updates}
       */
      checkIfAutoUpdateEnabled() {
        const updatesConfig = Constants.expoConfig?.updates;

        // NOTE: When the `updates.checkAutomatically` setting is not specified,
        //       Expo will use the 'ON_LOAD' option as the default.
        const isAutoUpdatesConfigNotSet = !updatesConfig?.checkAutomatically;
        const isAutoUpdatesOnLoadEnabled =
          updatesConfig?.checkAutomatically === 'ON_LOAD';

        return isAutoUpdatesConfigNotSet || isAutoUpdatesOnLoadEnabled;
      },

      /**
       * Checks whether OTA updates are supported in the current environment.
       *
       * Expo supports OTA updates only in the native published builds, which
       * means that OTA updates are not available on the web platform as well as
       * Expo Go and development clients.
       *
       * NOTE: Expo uses `__DEV__` variable to determine development mode.
       *
       * @see {@link https://docs.expo.dev/workflow/development-mode/}
       */
      checkIfUpdatesDisabled() {
        const isUsingDevMode = __DEV__;
        const isWebPlatform = Platform.OS === 'web';

        return isUsingDevMode || isWebPlatform;
      },

      /**
       * Checks for the availability of an initial update.
       */
      checkIfNoInitialUpdateAvailable(_, event) {
        const updateCheckResult = event.data;

        return (
          updateCheckResult?.type !== Updates.UpdateEventType.UPDATE_AVAILABLE
        );
      },

      /**
       * Checks for the availability of an (non-initial) update.
       */
      checkIfNoNewUpdateIsAvailable(_, event) {
        const updateFetchResult = event.data;

        return !updateFetchResult?.isNew;
      },
    },

    // ─────────────────────────────────────────────────────────────

    delays: {
      POLLING_INTERVAL: (context) => context.options.pollingInterval,
    },
  },
);

// ─── Logger ──────────────────────────────────────────────────────────────────

LOG.enable('OTA UPDATE STATE MACHINE');

const logger = LOG.extend('OTA UPDATE STATE MACHINE');

// ─── Types ───────────────────────────────────────────────────────────────────

type OtaUpdateStateMachineContext = {
  availableUpdate: UpdateDetails | undefined;
  options: {
    initialUpdateEventTimeout: number;
    pollingInterval: number;
  };
};

type OtaUpdateStateMachineEvents =
  | { type: 'RESTART' }
  | { type: 'UPDATE_LATER' };

type OtaUpdateStateMachineServices = {
  checkForInitialUpdate: { data: UpdateEvent | undefined };
  checkForUpdate: { data: UpdateFetchResult | undefined };
  storeUpdateContext: { data: any };
  restartApp: { data: void };
};

type UpdateDetails = {
  id?: string;
  targetVersion?: string;
};

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { TitleText } from '../../Text';
import type { ChallengeContentProps } from '../ChallengeCard.types';

export const ChallengeContent = ({
  title,
  children,
}: ChallengeContentProps) => {
  return (
    <View>
      <TitleText size={6}>{title}</TitleText>
      <View style={styles.descriptionWrapper}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  descriptionWrapper: {
    marginTop: theme.spacing['4'],
  },
});

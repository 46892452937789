import { useCallback } from 'react';
import type { NotificationClickEvent } from 'react-native-onesignal';
import { canOpenURL, createURL, openURL } from 'expo-linking';

import { ORDER_STATUS_ROUTE_PATH } from './useOrderStatusPushNotifications.constants';
import { getOrderIdFromNotification } from './utils';

// ─────────────────────────────────────────────────────────────────────────────

export const useOrderStatusPushNotifications = () => {
  /**
   * Navigate to the order status screen, if the corresponding attributes exist.
   **/
  const handleOrderStatusPushNotification = useCallback(
    async (event: NotificationClickEvent) => {
      const orderId = getOrderIdFromNotification(event.notification);

      if (!orderId) return;

      // ─────────────────────────────────────────────

      const orderStatusRoutePath = `${ORDER_STATUS_ROUTE_PATH}/${orderId}`;
      const orderStatusLink = createURL(orderStatusRoutePath);

      // ─────────────────────────────────────────────

      const isValidLink = await canOpenURL(orderStatusLink);

      if (!isValidLink) return;

      void openURL(orderStatusLink);
    },
    [],
  );

  return handleOrderStatusPushNotification;
};

import { useCallback } from 'react';
import { logger as LOG } from '@garnish/logger';
import type { InitialNotification } from '@notifee/react-native';
import { canOpenURL, openURL } from 'expo-linking';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Provides helpers for handling deep linking for Braze push notifications.
 *
 * NOTE: These helpers are required for setups with global level notification handlers (@notifee, etc.).
 *       For common configurations, the Braze library's helpers can be used instead.
 *
 * NOTE: For Android, the Braze Expo config plugin handles deep linking automatically (`androidHandlePushDeepLinksAutomatically`)
 *       at the OS level, removing the need for additional listeners.
 */
export const useBrazePushNotificationsDeepLinking = () => {
  /**
   * Navigated to the deep linking URI, if it can be accessed in the current app
   *
   * @see {@link https://www.braze.com/docs/user_guide/personalization_and_dynamic_content/deep_linking_to_in-app_content/#deep-linking-to-in-app-content Braze | Deep linking to in-app content}
   * @see {@link https://notifee.app/react-native/docs/events#foreground-events Notifee | Events}
   */
  const handleBrazePushNotification = useCallback(
    async (notificationEvent: MaybePushNotificationWithDeepLinkingEvent) => {
      const { notification } = notificationEvent.detail;
      const deepLinkingURI = notification?.data?.ab_uri;
      const hasDeepLinkingURI = typeof deepLinkingURI === 'string';

      // ─────────────────────────────────────────────────────────────────────

      if (!hasDeepLinkingURI) {
        logger.debug('There is no deep link in the initial notification.');

        return;
      }

      // ─────────────────────────────────────────────────────────────────────

      const canOpenDeepLinkingURI = await canOpenURL(deepLinkingURI);

      if (canOpenDeepLinkingURI) {
        await openURL(deepLinkingURI);
        logger.debug(`Navigating to ${deepLinkingURI}`);

        return;
      }

      logger.warn(
        `Provided deep link URI (${deepLinkingURI}) is not supported! Check the app linking configuration to determine the potential routes. `,
      );
    },
    [],
  );

  /**
   * Handles initial (aka app opening) push notification
   *
   * @see {@link https://notifee.app/react-native/docs/events#app-open-events | Notifee docs | App open events}
   */
  const handleInitialBrazePushNotification = useCallback(
    async (initialNotification: InitialNotification) => {
      const { notification } = initialNotification;

      await handleBrazePushNotification({ detail: { notification } });
    },
    [handleBrazePushNotification],
  );

  // ─────────────────────────────────────────────────────────────────────────────

  return {
    handleInitialBrazePushNotification,
    handleBrazePushNotification,
  };
};

// ─── Logger ──────────────────────────────────────────────────────────────────
// LOG.enable('BRAZE DEEP LINKING');
const logger = LOG.extend('BRAZE DEEP LINKING');

// ─── Types ───────────────────────────────────────────────────────────────────

type MaybePushNotificationWithDeepLinkingEvent = Readonly<{
  detail: {
    notification?: {
      data?: { ab_uri?: string };
    };
  };
}>;

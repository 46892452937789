import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { Icon } from '../Icon';

// ────────────────────────────────────────────────────────────────────────────────

export const NavbarLinkBadge = (props: NavbarLinkBadgeProps) => {
  const isMobileType = props.badgeType === 'mobile';
  const style = isMobileType ? styles.mobileBadge : styles.desktopBadge;

  return (
    <View style={style} testID={props.testID}>
      <Icon
        name="IconHexagon"
        color={theme.colors.GRAPEFRUIT}
        width={BADGE_SIZE}
        height={BADGE_SIZE}
      />
    </View>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const BADGE_SIZE = 7;

const styles = StyleSheet.create({
  desktopBadge: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: [
      { rotate: '30deg' },
      { translateX: BADGE_SIZE },
      { translateY: -BADGE_SIZE },
    ],
  },
  mobileBadge: {
    position: 'absolute',
    top: -2,
    right: -2,
  },
});

// ────────────────────────────────────────────────────────────────────────────────

type NavbarLinkBadgeProps = Readonly<{
  testID?: string;
  badgeType?: 'desktop' | 'mobile';
}>;

import { useMemo } from 'react';

import type { useCart, useCustomerCredit } from '@order/hooks';
import {
  getCartLedgerTotal,
  useTippingEnabledForCurrentOrderType,
} from '@order/hooks';

import type { useCheckoutPayload } from '../useCheckoutPayload';

/**
 * The customer can toggle the usage of credit in the checkout.
 * That is handled by the backend and will always use as much credit as possible.
 * This hook is only to show in the frontend the correct amount of credit that will be used.
 *
 * If there is more credit than the order cost, use the order cost.
 * If the order costs more than the available credit, use the credit amount.
 */

export const useCheckoutCredit = (props: CheckoutCreditProps) => {
  const { cart, isCartDelivery, isCartPickup, availableCredit, payload } =
    props;

  const ledger = cart?.ledger;
  const { useCredit, deliveryOrderDetail, pickupTip: orderPickupTip } = payload;

  const isTippingEnabled = useTippingEnabledForCurrentOrderType();

  const shouldEnablePickupTipping = isCartPickup && isTippingEnabled;
  const shouldEnableDeliveryTipping = isCartDelivery && isTippingEnabled;

  const pickupTip = shouldEnablePickupTipping ? orderPickupTip : 0;
  const deliveryTip = shouldEnableDeliveryTipping
    ? deliveryOrderDetail?.tip
    : 0;
  // eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10
  const tip = isCartDelivery ? deliveryTip : isCartPickup ? pickupTip : 0;

  const checkoutCredit = useMemo(() => {
    if (!useCredit) return 0;

    const amountToPay = getCartLedgerTotal({ ...ledger, tip, creditsTotal: 0 });
    const amountToCredit = availableCredit ?? 0;
    const appliedCredit = Math.min(amountToPay, amountToCredit);

    return appliedCredit;
  }, [ledger, availableCredit, tip, useCredit]);

  return { checkoutCredit };
};

type CheckoutCreditProps = Pick<
  ReturnType<typeof useCart> &
    ReturnType<typeof useCheckoutPayload> &
    ReturnType<typeof useCustomerCredit>,
  'cart' | 'isCartDelivery' | 'isCartPickup' | 'availableCredit' | 'payload'
>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreatePaymentIntentMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CreatePaymentIntentMutation = {
  readonly __typename?: 'Mutation';
  readonly createPaymentIntent: string;
};

export const CreatePaymentIntentDocument = gql`
  mutation CreatePaymentIntent {
    createPaymentIntent
  }
`;

export function useCreatePaymentIntentMutation() {
  return Urql.useMutation<
    CreatePaymentIntentMutation,
    CreatePaymentIntentMutationVariables
  >(CreatePaymentIntentDocument);
}

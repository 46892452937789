import { type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const paletteStyles: ButtonFluidPressablePaletteStyles = {
  kale: {
    idle: { backgroundColor: theme.colors.KALE },
    hover: { backgroundColor: theme.colors.KALE_HOVER },
  },
  disabled: {
    idle: { backgroundColor: theme.colors.LIGHT },
    hover: { backgroundColor: theme.colors.LIGHT },
  },
};

// ─────────────────────────────────────────────────────────────────────────────

export type ButtonFluidPressablePalette = 'kale';

type ButtonFluidPressablePaletteStyles = Record<
  ButtonFluidPressablePalette | 'disabled',
  { idle: ViewStyle; hover: ViewStyle }
>;

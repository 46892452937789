import { useCallback } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import type { To } from '@react-navigation/native/lib/typescript/src/useLinkTo';

import { useTelemetry } from '@order/Telemetry';

import {
  type MenuContentDeliveryDetailsData,
  type MenuContentOrderChannel,
} from '../../MenuContent.types';

// ─────────────────────────────────────────────────────────────────────────────

export const useMenuContentNavigation = (
  params: UseMenuContentNavigationParams,
) => {
  const { restaurantSlug, orderChannel, deliveryDetailsData } = params;

  const { navigate, setParams } = useNavigation();
  const { params: routeParams } = useRoute();
  const { trackEvent } = useTelemetry();

  const maybeParamsWithCategoryTarget =
    routeParams as MaybeParamsWithCategoryTarget;
  const defaultActiveTargetId = maybeParamsWithCategoryTarget?.target;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const changeLocation = useCallback(() => {
    trackEvent({ name: 'menu.change_location' });

    navigate('MainTabs', {
      screen: 'MenuTab',
      params: { screen: 'Locations' },
    });
  }, [navigate, trackEvent]);

  const generateProductDetailsLink = useCallback(
    (productSlug: string): To => {
      if (orderChannel === 'delivery') {
        return {
          screen: 'Modal',
          params: {
            screen: 'DeliveryProductDetails',
            params: {
              productSlug,
              addressId: deliveryDetailsData?.addressId ?? '',
            },
          },
        };
      }

      return {
        screen: 'Modal',
        params: {
          screen: 'ProductDetails',
          params: { productSlug, restaurantSlug },
        },
      };
    },
    [deliveryDetailsData?.addressId, orderChannel, restaurantSlug],
  );

  const setTargetParam = useCallback(
    (targetId: string) => {
      setParams({ target: targetId } as never);
    },
    [setParams],
  );

  return {
    defaultActiveTargetId,
    changeLocation,
    generateProductDetailsLink,
    setTargetParam,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseMenuContentNavigationParams = {
  restaurantSlug: string;
  deliveryDetailsData?: MenuContentDeliveryDetailsData;
  orderChannel: MenuContentOrderChannel;
};

type MaybeParamsWithCategoryTarget =
  | ({ target?: string } & Record<string, unknown>)
  | undefined;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, Icon } from '@sg/garnish';

import { FeatureFlagButton } from '../FeatureFlagButton';

// ─────────────────────────────────────────────────────────────────────────────

export const FeatureFlagEditButton = (props: FeatureFlagToggleButtonProps) => {
  const { name, onPress, isOverwritten } = props;

  return (
    <FeatureFlagButton name={name} onPress={onPress}>
      <View style={styles.iconContainer}>
        <Icon name="IconEdit" />
      </View>

      <BodyText bold={isOverwritten} style={styles.name}>
        {name}
      </BodyText>
    </FeatureFlagButton>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  iconContainer: {
    minWidth: 48,
    alignItems: 'center',
  },
  name: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type FeatureFlagToggleButtonProps = {
  name: string;
  onPress: () => void;
  isOverwritten: boolean;
};

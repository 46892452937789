/* istanbul ignore file */

import React, { memo, useCallback, useRef } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { LI, UL } from '@expo/html-elements';
import {
  capitalizeFirstLetter,
  getSubstringsByPositions,
  HighlightWords,
  Icon,
  theme,
  usePressableState,
  useResponsive,
  webOnlyStyles,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationSearchAddressesList = memo(
  (props: LocationSearchAddressesListProps) => {
    const { addresses, onPress } = props;

    const { match } = useResponsive();

    return (
      <View style={match([styles.containerXS, styles.containerSM])}>
        <UL>
          {addresses.map((customerAddress) => (
            <LI key={customerAddress.id}>
              <LocationSearchAddress
                address={customerAddress}
                onPress={onPress}
              />
            </LI>
          ))}
        </UL>
      </View>
    );
  },
);

// ─── Subcomponents ───────────────────────────────────────────────────────────

const LocationSearchAddress = (props: LocationSearchPreviousAddressProps) => {
  const { onPress } = props;
  const { id, name, address, matchedSubstrings } = props.address;

  const fullAddress = name
    ? `${capitalizeFirstLetter(name)} · ${address}`
    : address;
  const highlights = matchedSubstrings
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      getSubstringsByPositions(address, matchedSubstrings)
    : [];

  const { t } = useLocalizationContext();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onAddressBtnPress = useCallback(() => {
    onPress(id);
  }, [id, onPress]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const pressableRef = useRef<View>(null);
  const { isHovered, isFocused, isActive } = usePressableState(pressableRef);

  const pressableStyles = [
    styles.addressPressable,
    isHovered || isFocused ? styles.addressPressableHover : undefined,
    isActive ? styles.addressPressableActive : undefined,
    addressPressableWebStyles,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      ref={pressableRef}
      accessibilityRole="button"
      accessibilityHint={t('addresses.address-hint')}
      accessibilityLabel={t('addresses.address-label', {
        address,
      })}
      style={pressableStyles}
      onPress={onAddressBtnPress}
    >
      <View>
        <LocationSearchAddressIcon name={name} />
      </View>

      <View style={styles.addressPressableContent} pointerEvents="none">
        <HighlightWords
          text={fullAddress}
          highlights={highlights}
          selectable={false}
          numberOfLines={2}
        />
      </View>

      <View>
        <Icon name="IconCaretRight" width={16} height={16} />
      </View>
    </Pressable>
  );
};

const LocationSearchAddressIcon = (props: Pick<Address, 'name'>) => {
  const { name } = props;

  if (name === 'home') return <Icon name="IconHomeStrokeSm" />;

  if (name === 'work') return <Icon name="IconWorkStrokeSm" />;

  return <Icon name="IconCustomStrokeSm" />;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
    backgroundColor: theme.colors.APP_BACKGROUND,
    padding: theme.spacing['2'],
  },
  containerSM: {
    padding: theme.spacing['2'],
  },

  addressPressable: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: theme.spacing['2'],
    paddingVertical: theme.spacing['4'],
    borderRadius: theme.radius.small,
  },
  addressPressableHover: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
  addressPressableActive: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
  },
  addressPressableContent: {
    flex: 1,
    marginHorizontal: theme.spacing['4'],
  },
});

const addressPressableWebStyles = webOnlyStyles({
  transition: `background-color ${theme.transitions.base}ms`,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationSearchAddressesListProps = Readonly<{
  addresses: readonly Address[];
  onPress: (addressId: string) => void;
}>;

type LocationSearchPreviousAddressProps = Readonly<{
  address: Address;
  onPress: (addressId: string) => void;
}>;

type Address = Readonly<{
  id: string;
  name: string;
  address: string;
  // @ts-expect-error TS(2503): Cannot find namespace 'google'.
  matchedSubstrings?: readonly google.maps.places.PredictionSubstring[];
}>;

import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductCardV2Tag = (props: PropsWithChildren) => {
  return (
    <View style={styles.outerContainer}>
      <View style={styles.innerContainer}>
        <BodyText bold style={styles.text} sizeMatch={['12']}>
          {props.children}
        </BodyText>
      </View>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const BORDER_RADIUS = theme.radius.xxlarge;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    position: 'absolute',
    top: theme.spacing['6'],
    left: theme.spacing['4'],
    zIndex: 1,
    width: 81,
    backgroundColor: theme.colors.WHITE,
    borderRadius: BORDER_RADIUS,
    padding: theme.spacing['1'],
    alignSelf: 'flex-start',
    transform: [{ rotate: '-13deg' }],
    overflow: 'hidden',
  },
  innerContainer: {
    borderWidth: 2,
    borderColor: theme.colors.KALE,
    borderRadius: BORDER_RADIUS,
    padding: theme.spacing['2'],
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  text: {
    color: theme.colors.KALE,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 10,
    lineHeight: 12,
  },
});

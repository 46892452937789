import { createStore, delMany, get, getMany, keys, set } from 'idb-keyval';

import {
  CONTENTFUL_CACHE_STORAGE_KEY,
  CONTENTFUL_CACHE_WEB_STORAGE_DB_NAME,
} from '../../contentful-content-type-entries-machine.constants';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * @see {@link https://github.com/jakearchibald/idb-keyval/blob/main/custom-stores.md}
 */
const customStore = createStore(
  CONTENTFUL_CACHE_WEB_STORAGE_DB_NAME,
  CONTENTFUL_CACHE_STORAGE_KEY,
);

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Get a value by its key.
 */
export async function getItem(key: string) {
  return get(key, customStore);
}

/**
 * Get a value by its key.
 */
export async function multiGet(keysList: string[]) {
  const values = await getMany(keysList, customStore);

  return keysList.reduce((entries, key, index) => {
    return [...entries, [key, values[index]]];
  }, []);
}

/**
 * Set a value with a key.
 */
export async function setItem(key: string, value: unknown) {
  return set(key, value, customStore);
}

/**
 * Get all keys in the store.
 */
export async function getAllKeys() {
  return keys(customStore);
}

/**
 * Checks whether the provided key exists in the cache.
 */
export async function hasKey(targetKey: string) {
  const allKeys = await getAllKeys();

  return allKeys.includes(targetKey);
}

/**
 * Delete multiple keys at once.
 */
export async function multiRemove(keysList: string[]) {
  return delMany(keysList, customStore);
}

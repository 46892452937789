import React from 'react';

import { useTelemetry } from '@order/Telemetry';

export const useOrderStatusTelemetry = () => {
  const { track } = useTelemetry();

  const handleOrderStatusEvent = React.useCallback(
    (entryPoint: string) => {
      track('order_status.view', { entryPoint });
    },
    [track],
  );

  return {
    handleOrderStatusEvent,
  };
};

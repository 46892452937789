import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { LI, UL } from '@expo/html-elements';
import { theme } from '@garnish/constants';

import { MenuMoreInfoText } from '../MenuMoreInfoText';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoTimetable = (props: MenuMoreInfoTimetableProps) => {
  const { timetable, isTemporary } = props;

  const { formatMessage } = useIntl();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = isTemporary ? styles.containerTemporary : undefined;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles}>
      {isTemporary ? (
        <MenuMoreInfoText bold>
          <FormattedMessage {...messages.temporaryHoursMessage} />
        </MenuMoreInfoText>
      ) : null}

      <UL style={styles.listContainer}>
        {timetable.map((hourGroup) => {
          const { days, hours } = hourGroup;

          const dayFrom = formatMessage(messages.weekDay, { day: days.from });
          const dayTo = days.to
            ? formatMessage(messages.weekDay, { day: days.to })
            : null;

          return (
            <LI key={dayFrom} style={styles.listItemContainer}>
              <MenuMoreInfoText>
                {dayTo ? (
                  <FormattedMessage
                    {...messages.operatingHoursRange}
                    values={{
                      week_day_from: dayFrom,
                      week_day_to: dayTo,
                      hours_from: hours.from,
                      hours_to: hours.to,
                    }}
                  />
                ) : (
                  <FormattedMessage
                    {...messages.operatingHoursRangeSingleDay}
                    values={{
                      week_day: dayFrom,
                      hours_from: hours.from,
                      hours_to: hours.to,
                    }}
                  />
                )}
              </MenuMoreInfoText>
            </LI>
          );
        })}
      </UL>
    </View>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  weekDay: {
    defaultMessage: `{day, select,
      sun {Sun}
      mon {Mon}
      tue {Tue}
      wed {Wed}
      thu {Thu}
      fri {Fri}
      sat {Sat}
      other {{fallback}}
    }`,
    description: 'Menu | More info | Hours | Weekday',
  },
  operatingHoursRange: {
    defaultMessage: '{week_day_from} - {week_day_to} {hours_from} - {hours_to}',
    description: 'Menu | More info | Hours | Days and hours range',
  },
  operatingHoursRangeSingleDay: {
    defaultMessage: '{week_day} {hours_from} - {hours_to}',
    description: 'Menu | More info | Hours | Days and hours range (Single day)',
  },
  temporaryHoursMessage: {
    defaultMessage: 'This store currently has temporary hours',
    description: 'Menu | More info | Hours | Temporary hours message',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerTemporary: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    borderRadius: theme.radius.medium,
    padding: theme.spacing['4'],
  },
  listContainer: {
    display: 'flex', // required
    flexDirection: 'column', // required
    padding: 0,
  },
  listItemContainer: {
    display: 'flex', // required
    flexDirection: 'row', // required
    columnGap: theme.spacing['2'],
    justifyContent: 'space-between',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuMoreInfoTimetableProps = {
  timetable: {
    days: { from: string; to?: string };
    hours: { from: string; to: string };
  }[];
  isTemporary: boolean;
};

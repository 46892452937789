import { useContentfulContentTypeEntry } from '@order/Contentful';
import { useLocalizationContext } from '@order/Localization';

/**
 * Returns contentful data from 'Account Deletion'.
 * Falls back to localized strings.
 * @see: (@link: https://app.contentful.com/spaces/wme4s8lvzccr/content_types/accountSweetpassPage)
 */
export const useAccountDeletionContentfulData = (
  props: AccountDeletionContentfulProps,
) => {
  const { nextBillingDate } = props;
  const { t } = useLocalizationContext();

  const { data: contentfulData, fetching: isLoadingContentfulData } =
    useContentfulContentTypeEntry<AccountDeletionContentfulData>({
      contentType: ACCOUNT_DELETIION_CONTENTFUL_ID,
      include: 2,
    });

  return {
    isLoadingContentfulData,
    step1: (
      contentfulData?.fields?.step1 ?? t('account.delete.step1')
    )?.replace('{nextBillingDate}', nextBillingDate ?? ''),
    step2: contentfulData?.fields?.step2 ?? t('account.delete.step2'),
  };
};

// ─── Constants ────────────────────────────────────────────────────

const ACCOUNT_DELETIION_CONTENTFUL_ID = 'accountAccountDeletion';

// ─── Types ────────────────────────────────────────────────────────

type AccountDeletionContentfulProps = Readonly<{
  nextBillingDate?: string;
}>;

type AccountDeletionContentfulData = Readonly<{
  step1?: string;
  step2?: string;
}>;

import type { ComponentProps } from 'react';
import React from 'react';
import { theme, VStack } from '@sg/garnish';

export const CheckoutBlockVStack = (props: CheckoutBlockVStackProps) => (
  <VStack
    hasDivider
    dividerColor={theme.colors.LIGHT}
    gap={0}
    testID={props.testID}
  >
    {props.children}
  </VStack>
);

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type CheckoutBlockVStackProps = Pick<
  ComponentProps<typeof VStack>,
  'children' | 'testID'
>;

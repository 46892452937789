import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { AccessibilityInfo, StyleSheet, View } from 'react-native';
import { BodyText, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuOnlineOrderingUnavailableMessage = (
  props: MenuOnlineOrderingUnavailableMessageProps,
) => {
  const { message } = props;

  const { formatMessage } = useIntl();

  const a11yAnnouncement = message
    ? message
    : formatMessage(messages.orderingUnavailableMessageA11y);

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    AccessibilityInfo.announceForAccessibility(a11yAnnouncement);
  }, [a11yAnnouncement]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <BodyText sizeMatch={['24']}>
        <FormattedMessage {...messages.orderingUnavailableMessageFirstPart} />
      </BodyText>

      <BodyText sizeMatch={['24']}>
        {message ? (
          message
        ) : (
          <FormattedMessage
            {...messages.orderingUnavailableMessageSecondPart}
          />
        )}
      </BodyText>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    rowGap: theme.spacing['6'],
  },
});

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  orderingUnavailableMessageFirstPart: {
    defaultMessage: 'Online ordering is currently unavailable at this address.',
    description: 'Menu | Online ordering unavailable | Message (1st part)',
  },
  orderingUnavailableMessageSecondPart: {
    defaultMessage: 'We apologies for the inconvenience.',
    description: 'Menu | Online ordering unavailable | Message (2nd part)',
  },
  orderingUnavailableMessageA11y: {
    defaultMessage:
      'Online ordering is currently unavailable at this address. We apologies for the inconvenience.',
    description: 'Menu | Online ordering unavailable | a11y message',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuOnlineOrderingUnavailableMessageProps = {
  // An optional message that clarifies the reason the store does not accept online orders.
  message?: string;
};

import React, { type PropsWithChildren } from 'react';
import { View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { HStack, theme, useContainerSpacing, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuCategoriesGridProductsStack = (props: PropsWithChildren) => {
  const { children } = props;

  const { match } = useResponsive();
  const containerSpacing = useContainerSpacing();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerDynamicStylesXS = useStyle(
    () => ({ marginHorizontal: -containerSpacing }),
    [containerSpacing],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={match([containerDynamicStylesXS, undefined])}>
      <HStack itemsPerRow={match([1, 2, 3, 4])} gap={theme.spacing['4']}>
        {children}
      </HStack>
    </View>
  );
};

import { useCallback } from 'react';
import { useMachine } from '@xstate/react';
import { AddressTypes } from '@sg/garnish';

import type { CheckoutFieldName, CheckoutPartialPayload } from './machine';
import {
  checkoutFieldsValidationMachine,
  checkoutFieldsValidationModel,
} from './machine';

// ────────────────────────────────────────────────────────────────────────────────

export const useCheckoutFieldsValidation = (
  orderType: keyof typeof AddressTypes,
) => {
  const isPickupOrder = orderType === AddressTypes.pickup;

  const [state, send] = useMachine(checkoutFieldsValidationMachine, {
    context: {
      ...checkoutFieldsValidationMachine.context,
      invalidValues: {
        ...checkoutFieldsValidationMachine.context.invalidValues,

        // We must supply invalid value/s only for the appropriate situation because
        // drop off location is optional and depending on the current order type.
        dropoffLocationId: isPickupOrder ? [null] : [],
      },
    },
  });

  const checkoutFieldsValidation = state.context.fieldsValidation;

  // ─── Helpers ─────────────────────────────────────────────────────

  const validateCheckoutFields = useCallback(
    (payload: CheckoutPartialPayload) => {
      send(checkoutFieldsValidationModel.events.VALIDATE(payload));
    },
    [send],
  );

  const resetCheckoutFieldValidation = useCallback(
    (fieldName: CheckoutFieldName) => {
      send(checkoutFieldsValidationModel.events.RESET_VALIDATION(fieldName));
    },
    [send],
  );

  // ─────────────────────────────────────────────────────────────────

  return {
    checkoutFieldsValidation,
    validateCheckoutFields,
    resetCheckoutFieldValidation,
  };
};

import { type UseFormReturn } from 'react-hook-form';
import { type DefaultValues } from 'react-hook-form/dist/types/form';

export type GiftCardCheckoutFormReturn =
  UseFormReturn<GiftCardCheckoutFormState>;

export type GiftCardCheckoutFormState = {
  assetId: string;
  amount: number;
  amountVariation: 'standard' | 'custom';
  recipientName: string;
  recipientEmail?: string;
  senderName: string;
  message?: string;
  deliveryDateOption: 'NOW' | 'SCHEDULE';
  deliveryDateTime?: string; // ISO date string
  utcOffset?: number; // UTC offset provided in hour format.
  deliveryMethod: DeliveryMethods;
  paymentMethodId: string;
  quantity: number;
};

export type GiftCardCheckoutFormDefaultValues =
  DefaultValues<GiftCardCheckoutFormState>;

export type GiftCardCheckoutFormFieldRules = {
  message: {
    maxChars: number;
  };
  amount: {
    min: number;
    max: number;
    options: number[];
  };
  deliveryDate: {
    fromDate: Date;
    toDate: Date;
  };
};

export enum DeliveryMethods {
  Email = 'EMAIL',
  Message = 'MESSAGE',
}

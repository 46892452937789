import React, { useCallback, useState } from 'react';
import { ImageBackground, ScrollView, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import {
  BodyText,
  Button,
  formatDate,
  LabelText,
  Modal,
  theme,
  TitleText,
  TYPE_CONFIG,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { useNavigateToMainTabs } from '../../navigation';
import { ModalBgSM, ModalBgXS } from './assets';
import { useChallengeCompletionTelemetry } from './hooks';

// ─────────────────────────────────────────────────────────────────────────

export const ChallengeCompletionModal = (
  props: ChallengeCompletionModalProps,
) => {
  const { challengeCompletionDetails } = props;

  const { title, celebrationCopy: description } = challengeCompletionDetails;

  // ─── State ───────────────────────────────────────────────────────────

  const [isModalVisible, setIsModalVisible] = useState(true);

  // ─────────────────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const navigateToMainTabs = useNavigateToMainTabs();
  const { match } = useResponsive();

  const {
    trackNavigateToLoyaltyTab,
    trackChallengeCompletionModalShow,
    trackChallengeCompletionModalDismiss,
  } = useChallengeCompletionTelemetry();

  // ─── Derived data ────────────────────────────────────────────────────

  const expirationDate = challengeCompletionDetails.expirationDate
    ? new Date(challengeCompletionDetails.expirationDate)
    : null;
  const formattedExpirationDate = formatDate(expirationDate);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToLoyaltyTab = useCallback(() => {
    trackNavigateToLoyaltyTab();

    void navigateToMainTabs('LoyaltyTab', {
      screen: 'SweetpassHome',
      params: { target: 'rewards' },
    });
  }, [navigateToMainTabs, trackNavigateToLoyaltyTab]);

  const dismissChallengeCompletionModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleNavigateToLoyaltyTab = useCallback(() => {
    dismissChallengeCompletionModal();
    navigateToLoyaltyTab();
  }, [dismissChallengeCompletionModal, navigateToLoyaltyTab]);

  const onChallengeCompletionModalShown = useCallback(() => {
    trackChallengeCompletionModalShow(challengeCompletionDetails);
  }, [challengeCompletionDetails, trackChallengeCompletionModalShow]);

  const onChallengeCompletionModalDismissed = useCallback(() => {
    trackChallengeCompletionModalDismiss();
  }, [trackChallengeCompletionModalDismiss]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const safeAreaInsets = useSafeAreaInsets();
  const containerSafeInsetStyle = useStyle(
    () => ({
      paddingTop: safeAreaInsets.top,
      paddingBottom: safeAreaInsets.bottom,
    }),
    [safeAreaInsets],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Modal
      size={match(['full', 'small'])}
      fitHeight
      animationType="fade"
      accessibilityLabel={t('challenges.rewards.celebration.a11y-title')}
      visible={isModalVisible}
      onRequestClose={dismissChallengeCompletionModal}
      onShown={onChallengeCompletionModalShown}
      onDismissed={onChallengeCompletionModalDismissed}
      withSafeAreaView={false}
    >
      <ImageBackground
        source={match([ModalBgXS, ModalBgSM])}
        resizeMode="cover"
        style={[styles.container, containerSafeInsetStyle]}
      >
        <Modal.CloseBtn
          palette="neutral"
          onPress={dismissChallengeCompletionModal}
        />

        <ScrollView
          contentContainerStyle={match([
            styles.contentContainerXS,
            styles.contentContainerSM,
          ])}
        >
          <Modal.Row style={styles.contentInnerContainer}>
            {title ? (
              // @ts-expect-error TS(2322): Type '{ marginTop: "auto"; fontSize: number; fontF... Remove this comment to see the full error message
              <TitleText style={[styles.text, styles.title]}>{title}</TitleText>
            ) : null}

            {formattedExpirationDate ? (
              <LabelText
                size={match([2, 1])}
                style={[styles.text, styles.expirationDate]}
              >
                {t('general.use-by', { date: formattedExpirationDate })}
              </LabelText>
            ) : null}

            {description ? (
              <BodyText size={3} style={styles.text}>
                {description}
              </BodyText>
            ) : null}
          </Modal.Row>
        </ScrollView>

        <Modal.Footer
          withoutTopBorder
          style={match([styles.footerXS, styles.footerSM])}
        >
          <Button
            palette="primary"
            style={styles.cta}
            onPress={handleNavigateToLoyaltyTab}
            size="large"
          >
            {t('challenges.rewards.see-my-rewards')}
          </Button>
        </Modal.Footer>
      </ImageBackground>
    </Modal>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainerXS: {
    flex: 1,
    paddingTop: theme.spacing['10'],
  },
  contentContainerSM: {
    flex: 1,
    paddingTop: theme.spacing['20'],
  },
  contentInnerContainer: {
    flex: 1,
  },
  title: {
    ...TYPE_CONFIG.TITLE['48'],
    marginTop: 'auto',
  },
  text: {
    color: theme.colors.KALE,
    marginBottom: theme.spacing['4'],
  },
  expirationDate: {
    textTransform: 'uppercase',
  },
  cta: {
    borderWidth: 0,
  },
  footerXS: {
    marginTop: theme.spacing['6'],
  },
  footerSM: {
    marginTop: theme.spacing['6'],
    marginBottom: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ChallengeCompletionModalProps = Readonly<{
  challengeCompletionDetails: {
    celebrationCopy: string;
    challengesIds: readonly string[];
    expirationDate?: string;
    rewardUsageDisclaimer?: string;
    title: string | null;
  };
}>;

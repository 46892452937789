import React, { useCallback, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { IllusEmpty_1, TextLinkifyTags, TYPE_CONFIG } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { NotFoundView } from '../NotFoundView';

export const SweetpassFreeTrialNotEligible = () => {
  const { t } = useLocalizationContext();
  const navigation = useNavigation();

  const handleLearnMore = useCallback(() => {
    navigation.navigate('Modal', { screen: 'SweetpassUpgrade' });
  }, [navigation]);

  const links = useMemo(
    () => [
      {
        tag: 'learn_more',
        description: t('sweetpass-free-trial-not-eligible-learn-more'),
        onPress: handleLearnMore,
      },
    ],
    [t, handleLearnMore],
  );

  return (
    <NotFoundView.Wrapper>
      <NotFoundView.Image illustration={IllusEmpty_1} />

      <NotFoundView.Title
        title={t('sweetpass-free-trial-not-eligible-title')}
      />

      <TextLinkifyTags links={links} style={styles.text}>
        {t('sweetpass-free-trial-not-eligible-body')}
      </TextLinkifyTags>

      <NotFoundView.Cta />
    </NotFoundView.Wrapper>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  // @ts-expect-error TS(2322): Type '{ fontSize: number; fontFamily: string; font... Remove this comment to see the full error message
  text: {
    textAlign: 'center',

    // We override fluid text styles because we need static 16px styles,
    // which the present fluid text styles cannot provide.
    ...TYPE_CONFIG.BODY['16'],
  },
});

import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';
import { getRoundedValueOfPercentage } from '@sg/garnish';

import type { useCart } from '@order/hooks';

import type { CheckoutPayload } from '../../Checkout.utils';

// Synchronized the delivery order details with the checkout payload.
export const useSyncDeliveryOrderDetails = ({
  cart,
  addressId,
  setPayload,
}: SyncDeliveryOrderDetailsProps) => {
  useEffect(() => {
    setPayload((previous) => {
      const cartSubtotal = cart?.ledger?.subtotal ?? 0;

      const selectedTip = previous.deliveryOrderDetail?.tip;
      const defaultTip = getRoundedValueOfPercentage(
        DEFAULT_DELIVERY_TIP_AMOUNT,
        cartSubtotal,
      );

      return {
        ...previous,
        deliveryOrderDetail: addressId
          ? {
              addressId,
              tip: selectedTip ?? defaultTip,
              deliveryFee: cart?.deliveryOrderDetail?.deliveryFee ?? 0,
              vendor: cart?.deliveryOrderDetail?.vendor ?? '',
              vendorRestaurantId:
                cart?.deliveryOrderDetail?.vendorRestaurantId ?? '',
            }
          : undefined,
      };
    });
  }, [addressId, cart, setPayload]);
};

// ─── Constants ───────────────────────────────────────────────────────────────

const DEFAULT_DELIVERY_TIP_AMOUNT = 15;

// ───── Types ─────────────────────────────────────────────────────────────────

type SyncDeliveryOrderDetailsProps = Readonly<{
  cart?: ReturnType<typeof useCart>['cart'];
  addressId?: string;
  setPayload: Dispatch<SetStateAction<CheckoutPayload>>;
}>;

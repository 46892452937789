import React from 'react';
import { useResponsive } from '@sg/garnish';

import type { ConfirmLocationLayoutProps } from './ConfirmLocationLayout.types';
import { ConfirmLocationLayoutSM } from './ConfirmLocationLayoutSM';
import { ConfirmLocationLayoutXS } from './ConfirmLocationLayoutXS';

export const ConfirmLocationLayout = (props: ConfirmLocationLayoutProps) => {
  const { match } = useResponsive();
  const ConfirmLocationLayoutComponent = match([
    ConfirmLocationLayoutXS,
    ConfirmLocationLayoutSM,
  ]);

  return <ConfirmLocationLayoutComponent {...props} />;
};

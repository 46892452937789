/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateCustomerProfileMutationVariables = Types.Exact<{
  input: Types.UpdateCustomerInput;
}>;

export type UpdateCustomerProfileMutation = {
  readonly __typename?: 'Mutation';
  readonly updateCustomer: {
    readonly __typename: 'UpdateCustomerResponse';
    readonly customer: {
      readonly __typename: 'Customer';
      readonly id: string;
      readonly firstName: string | null;
      readonly lastName: string | null;
      readonly email: string | null;
      readonly phoneNumber: string | null;
      readonly birthday: string | null;
    };
  } | null;
};

export type CustomerProfileFieldsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CustomerProfileFieldsQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename: 'Customer';
    readonly id: string;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly email: string | null;
    readonly phoneNumber: string | null;
    readonly birthday: string | null;
  } | null;
};

export const UpdateCustomerProfileDocument = gql`
  mutation UpdateCustomerProfile($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      __typename
      customer {
        id
        firstName
        lastName
        email
        phoneNumber
        birthday
        __typename
      }
    }
  }
`;

export function useUpdateCustomerProfileMutation() {
  return Urql.useMutation<
    UpdateCustomerProfileMutation,
    UpdateCustomerProfileMutationVariables
  >(UpdateCustomerProfileDocument);
}
export const CustomerProfileFieldsDocument = gql`
  query CustomerProfileFields {
    currentCustomer {
      id
      firstName
      lastName
      email
      phoneNumber
      birthday
      __typename
    }
  }
`;

export function useCustomerProfileFieldsQuery(
  options?: Omit<
    Urql.UseQueryArgs<CustomerProfileFieldsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    CustomerProfileFieldsQuery,
    CustomerProfileFieldsQueryVariables
  >({ query: CustomerProfileFieldsDocument, ...options });
}

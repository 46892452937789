import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';
import { DisplayText } from '../../../Text';
import type { PaletteProps } from '../../Modal.types';
import { ModalRow } from '../ModalRow';

export const ModalHeadline = (
  props: ComponentProps<typeof DisplayText> & PaletteProps,
) => {
  const { children, palette, style, ...restProps } = props;
  const { match } = useResponsive();

  const paletteSpecificTextStyles = palette
    ? paletteHeadingTextStyles[palette]
    : undefined;

  return (
    <ModalRow>
      <DisplayText
        size={match([4, 3])}
        style={[styles.textGap, style, paletteSpecificTextStyles]}
        {...restProps}
      >
        {children}
      </DisplayText>
    </ModalRow>
  );
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  textGap: {
    marginBottom: theme.spacing['2'],
  },
  headlineSecondary: {
    color: theme.colors.WHITE,
  },
});

// ─── Palette Styles ────────────────────────────────────────────────────

const paletteHeadingTextStyles = {
  primary: undefined,
  neutral: undefined,
  secondary: styles.headlineSecondary,
  lime: styles.headlineSecondary,
};

import React from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../Text';
import { TextLink } from '../../TextLink';
import type { LoyaltyChallengeTermsProps } from '../LoyaltyChallengeCard.types';

export const LoyaltyChallengeTerms = (props: LoyaltyChallengeTermsProps) => {
  const {
    testID,
    descriptiveTitle,
    onPressTermsAndConditions,
    terms,
    applyText,
    termsUrl,
  } = props;

  return (
    <BodyText size={3} style={styles.challengeContent} testID={testID}>
      {descriptiveTitle}{' '}
      <TextLink
        style={styles.hyperlink}
        size={3}
        href={termsUrl}
        onPress={onPressTermsAndConditions}
      >
        {terms}
      </TextLink>{' '}
      {applyText}.
    </BodyText>
  );
};

// -------------------------- STYLES --------------------------

const styles = StyleSheet.create({
  hyperlink: {
    color: theme.colors.KALE,
  },
  challengeContent: {
    flex: 1,
    color: theme.colors.KALE,
  },
});

import React, { useMemo } from 'react';
import { getIosModalPresentationConfig } from '@sg/garnish';

import {
  generateStoreHoursTimetable,
  MenuMoreInfo,
} from '@order/components/menu';

import { Modal } from '../../../Modal';
import { type MenuContentRestaurantData } from '../../MenuContent.types';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuContentMoreInfoModal = (
  props: MenuContentMoreInfoModalProps,
) => {
  const { isVisible, onClose, restaurant, locationName } = props;

  const { phone, hours, address, city, state, zipCode } = restaurant;
  const { permanent: permanentHours, store: storeHours } = hours;

  // ─── Derived Data ────────────────────────────────────────────────────

  const { storeOperatingHoursTimetable, permanentOperatingHoursTimetable } =
    useMemo(() => {
      return generateStoreHoursTimetable(permanentHours, storeHours);
    }, [permanentHours, storeHours]);

  const hasTemporaryHours =
    storeOperatingHoursTimetable && storeOperatingHoursTimetable.length > 0;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Modal
      visible={isVisible}
      size="small"
      fitHeight
      onRequestClose={onClose}
      {...getIosModalPresentationConfig()}
    >
      <MenuMoreInfo.Container>
        <MenuMoreInfo.ModalHeader>
          <MenuMoreInfo.Heading>{locationName}</MenuMoreInfo.Heading>
          <MenuMoreInfo.ModalCloseButton onPress={onClose} />
        </MenuMoreInfo.ModalHeader>

        <MenuMoreInfo.ContentContainer>
          {hasTemporaryHours ? (
            <MenuMoreInfo.Timetable
              timetable={storeOperatingHoursTimetable}
              isTemporary={true}
            />
          ) : null}

          <MenuMoreInfo.RestaurantAddress
            shortAddress={address}
            city={city}
            state={state}
            zipCode={zipCode}
          />

          <MenuMoreInfo.Timetable
            timetable={permanentOperatingHoursTimetable}
            isTemporary={false}
          />

          {phone ? (
            <MenuMoreInfo.RestaurantPhoneNumber phoneNumber={phone} />
          ) : null}
        </MenuMoreInfo.ContentContainer>

        <MenuMoreInfo.ButtonsContainer>
          <MenuMoreInfo.Button.Direction restaurantAddress={restaurant} />
          <MenuMoreInfo.Button.CopyAddress restaurantAddress={restaurant} />
        </MenuMoreInfo.ButtonsContainer>
      </MenuMoreInfo.Container>
    </Modal>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuContentMoreInfoModalProps = {
  isVisible: boolean;
  onClose: () => void;
  locationName: string;
  restaurant: Pick<
    MenuContentRestaurantData,
    'phone' | 'hours' | 'flexMessage' | 'address' | 'city' | 'state' | 'zipCode'
  >;
};

import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { type NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { useNavigateToMainTabs } from '../../../../navigation';
import { type ModalStackParamList } from '../../../../navigation/AppNavigation.props';
import { useOptInUserMutation } from '../../GraphQL/SweetpassUpgradeScreen.generated';

export function useSweetpassOptIn(props: UseSweetpassOptInProps) {
  const { planId, campaignId, redirectToAfterUpgrade, redirectToOrderId } =
    props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const { track } = useTelemetry();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const navigateToMainTabs = useNavigateToMainTabs();
  const navigation =
    useNavigation<NativeStackNavigationProp<ModalStackParamList>>();

  // ─── Mutation ────────────────────────────────────────────────────────

  const { isConfirmingOptIn, optInFreeTier } = useSweetpassOptInMutation();

  // ─── Join Sweetpass ──────────────────────────────────────────────────

  const handleJoinSweetpass = useCallback(async () => {
    track('sweetpass_account.opt_in.tapped');

    const hasOptedInToFreeTier = await optInFreeTier();

    if (!hasOptedInToFreeTier) {
      addNoticeBanner({ text: t('general.error'), palette: 'caution' });

      return;
    }

    void navigateToMainTabs('LoyaltyTab', {
      screen: 'SweetpassHome',
    });
  }, [track, optInFreeTier, navigateToMainTabs, addNoticeBanner, t]);

  // ─── Proceed To Checkout ─────────────────────────────────────────────

  const handleProceedToCheckout = useCallback(async () => {
    track('sweetpass_account.upgrade_cta.tapped');

    navigation.navigate('SweetpassCheckout', {
      planId,
      campaignId,
      redirectToAfterUpgrade,
      redirectToOrderId,
    });
  }, [
    navigation,
    campaignId,
    planId,
    redirectToAfterUpgrade,
    redirectToOrderId,
    track,
  ]);

  // ─── Opt-In Callback ─────────────────────────────────────────────────

  const optInSweetpass = useCallback(
    async (action?: string) => {
      if (action === 'join_sweetpass') return handleJoinSweetpass();

      return handleProceedToCheckout();
    },
    [handleJoinSweetpass, handleProceedToCheckout],
  );

  return { isConfirmingOptIn, optInSweetpass };
}

// ─── Helpers ─────────────────────────────────────────────────────────────────

function useSweetpassOptInMutation() {
  const [optInMutationResponse, optInMutation] = useOptInUserMutation();

  const optInFreeTier = useCallback(async () => {
    const response = await optInMutation({});

    return response.data?.optInFreeTierV2?.__typename === 'LoyaltyProfile';
  }, [optInMutation]);

  const isConfirmingOptIn = optInMutationResponse.fetching;

  return { isConfirmingOptIn, optInFreeTier };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type UseSweetpassOptInProps = Readonly<{
  planId?: string;
  campaignId?: string;
  redirectToAfterUpgrade?: 'bag' | 'order-status';
  redirectToOrderId?: string;
}>;

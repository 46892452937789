import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BackButton, Button, TextField, theme } from '@sg/garnish';

import { type useLaunchDarkly } from '@order/LaunchDarkly';

export const FeatureFlagEditor = (props: FeatureFlagEditorProps) => {
  const {
    featureFlagToEdit,
    newFlagValue,
    updateEditValue,
    cancelEdit,
    confirmFeatureFlagEdit,
  } = props;

  const { formatMessage } = useIntl();

  const value =
    typeof newFlagValue === 'string'
      ? newFlagValue
      : JSON.stringify(newFlagValue, null, 2);

  return (
    <View style={styles.contentWrapper}>
      <BackButton
        accessibilityLabel={formatMessage(messages.cancelEdit)}
        label={featureFlagToEdit}
        onPress={cancelEdit}
      />

      <TextField
        multiline
        style={styles.editFeatureFlagField}
        accessibilityLabel={featureFlagToEdit}
        value={value}
        onChangeText={updateEditValue}
      />

      <Button onPress={confirmFeatureFlagEdit} size="large">
        <FormattedMessage
          defaultMessage="Confirm"
          description="Confirm feature flag edit CTA"
        />
      </Button>
    </View>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type FeatureFlagKey = keyof ReturnType<
  typeof useLaunchDarkly
>['initialFeatureFlags'];

type FeatureFlagEditorProps = {
  featureFlagToEdit: FeatureFlagKey;
  newFlagValue: unknown;
  updateEditValue: (value: string) => void;
  confirmFeatureFlagEdit: () => void;
  cancelEdit: () => void;
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  cancelEdit: {
    defaultMessage: 'cancelEdit',
    description: 'Cancel edit CTA',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  contentWrapper: {
    gap: theme.spacing['4'],
    paddingTop: theme.spacing['2'],
  },
  editFeatureFlagField: {
    minHeight: 210,
  },
});

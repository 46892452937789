import React, { useCallback, useRef } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { theme } from '@garnish/constants';
import { ImpactFeedbackStyle } from 'expo-haptics';

import { useUniqueNativeID } from '../../../../hooks';
import { triggerHapticFeedback } from '../../../../utils';
import {
  RadioContainerItemAnimatedContainer,
  RadioContainerItemFeaturedText,
  RadioContainerItemIcon,
  RadioContainerItemLabel,
  RadioContainerItemPressable,
  RadioContainerItemSubtitle,
  RadioContainerItemTitle,
} from './components';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders a `Pressable` component with the `'radio'` role and multiple
 * customization options.
 */
export const RadioContainerItem = (props: RadioContainerItemProps) => {
  const {
    title,
    featuredText,
    subtitle,
    value,
    label,
    isSelected,
    isDisabled,
    'aria-label': ariaLabel,
    onPress,
  } = props;

  const pressableContainerRef = useRef<View>(null);
  const isHovered = useHover(pressableContainerRef);
  const titleNativeID = useUniqueNativeID();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    onPress(value);
    void triggerHapticFeedback(ImpactFeedbackStyle.Light);
  }, [onPress, value]);

  const conditionalOnPress = isSelected || isDisabled ? null : handleOnPress;

  // ─────────────────────────────────────────────────────────────────────
  return (
    <RadioContainerItemPressable
      ref={pressableContainerRef}
      aria-label={ariaLabel}
      aria-labelledby={titleNativeID}
      isSelected={isSelected}
      isDisabled={isDisabled}
      onPress={conditionalOnPress}
    >
      <RadioContainerItemAnimatedContainer
        isSelected={isSelected}
        isHovered={isHovered}
        isDisabled={isDisabled}
      >
        <RadioContainerItemIcon isActive={isSelected} isDisabled={isDisabled} />

        <View style={styles.contentContainer}>
          <View style={styles.header}>
            <RadioContainerItemTitle
              nativeID={titleNativeID}
              isDisabled={isDisabled}
            >
              {title}
            </RadioContainerItemTitle>

            {featuredText ? (
              <RadioContainerItemFeaturedText>
                {featuredText}
              </RadioContainerItemFeaturedText>
            ) : null}

            {label ? (
              <RadioContainerItemLabel isDisabled={isDisabled}>
                {label}
              </RadioContainerItemLabel>
            ) : null}
          </View>

          {subtitle ? (
            <RadioContainerItemSubtitle isDisabled={isDisabled}>
              {subtitle}
            </RadioContainerItemSubtitle>
          ) : null}
        </View>
      </RadioContainerItemAnimatedContainer>
    </RadioContainerItemPressable>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    rowGap: theme.spacing['2'],
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: Platform.select({
      default: 'center',

      // NOTE: Because of differences between bold and standard fonts, we
      //       apply `baseline` style to resolve the vertical alignment
      //       issue on the web.
      web: 'baseline',
    }),
    columnGap: theme.spacing['2'],
    rowGap: theme.spacing['1'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type RadioContainerItemProps = Readonly<{
  title: string;
  value: string;
  isSelected: boolean;
  onPress: (value: string) => void;

  'aria-label'?: string;
  subtitle?: string;
  featuredText?: string;
  label?: string;
  isDisabled?: boolean;
}>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RecommendedItemFragment = {
  readonly __typename?: 'LineItemRecommendation';
  readonly id: string;
  readonly name: string;
  readonly recommendationType: Types.RecommendationType;
  readonly ingredientIds: ReadonlyArray<string>;
  readonly description: string;
};

export type LineItemRecommendationsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type LineItemRecommendationsQuery = {
  readonly __typename?: 'Query';
  readonly lineItemRecommendations: ReadonlyArray<{
    readonly __typename?: 'LineItemRecommendation';
    readonly id: string;
    readonly name: string;
    readonly recommendationType: Types.RecommendationType;
    readonly ingredientIds: ReadonlyArray<string>;
    readonly description: string;
  }>;
};

export const RecommendedItemFragmentDoc = gql`
  fragment RecommendedItem on LineItemRecommendation {
    id
    name
    recommendationType
    ingredientIds
    description
  }
`;
export const LineItemRecommendationsDocument = gql`
  query LineItemRecommendations {
    lineItemRecommendations {
      ...RecommendedItem
    }
  }
  ${RecommendedItemFragmentDoc}
`;

export function useLineItemRecommendationsQuery(
  options?: Omit<
    Urql.UseQueryArgs<LineItemRecommendationsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LineItemRecommendationsQuery,
    LineItemRecommendationsQueryVariables
  >({ query: LineItemRecommendationsDocument, ...options });
}

import React from 'react';
import { ScanCard } from '@sg/garnish';

import { useSweetpassTier } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanInStoreEarnAndRedeemCardWithSubscription = () => {
  const { t } = useLocalizationContext();
  const { loyaltyId } = useSweetpassTier();

  return (
    <ScanCard.Container palette="kale">
      <ScanCard.Title palette="oatmeal">
        {t('scan-in-store.tabs.earn-and-redeem.card.title')}
      </ScanCard.Title>

      {loyaltyId ? <ScanCard.QrCode value={loyaltyId} /> : null}
    </ScanCard.Container>
  );
};

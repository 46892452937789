import { useCallback } from 'react';

import { APPLE_PAY } from '@order/constants';
import { type usePaymentMethods } from '@order/hooks';

import { type PaymentMethodType } from '../../Checkout.utils';

// ────────────────────────────────────────────────────────────────────────────
// If something goes wrong with Apple Pay, switch to a different payment method.
// Customers that cancel the request on web will be prompted to check Apple Wallet.

export const useCancelApplePayCheckout = (props: PlaceOrderProps) => {
  const { paymentMethods, handleCreditCardSelected } = props;

  // If something goes wrong with Apple Pay, switch to a different payment method.
  const cancelApplePayCheckout = useCallback(() => {
    const firstNonApplePayPaymentMethod = paymentMethods.find(
      (paymentMethod) => paymentMethod.id !== APPLE_PAY,
    );

    handleCreditCardSelected({
      paymentMethodId: firstNonApplePayPaymentMethod?.id ?? '',
      paymentMethodType:
        firstNonApplePayPaymentMethod?.__typename ?? 'PaymentMethodCard',
    });
  }, [paymentMethods, handleCreditCardSelected]);

  return { cancelApplePayCheckout };
};

// ─── Types ──────────────────────────────────────────────────────────────────

type PlaceOrderProps = {
  paymentMethods: ReturnType<typeof usePaymentMethods>['paymentMethods'];
  handleCreditCardSelected: (payload: {
    paymentMethodId: string;
    paymentMethodType: PaymentMethodType;
  }) => void;
};

import { requestPolicyExchange as urqlRequestPolicyExchange } from '@urql/exchange-request-policy';

// https://formidable.com/open-source/urql/docs/api/request-policy-exchange/
// > The "time-to-live" until an Operation will be upgraded to the
// > cache-and-network policy in milliseconds. By default, 5 minutes (5 * 60 * 1000 milliseconds) is set.

export const requestPolicyExchange = urqlRequestPolicyExchange({
  // The amount of time in ms that has to go by before upgrading
  ttl: 30 * 1000, // in milliseconds

  // An optional function that allows you to specify whether an operation should be upgraded.
  // shouldUpgrade: (operation) => operation.context.requestPolicy !== 'cache-only',
});

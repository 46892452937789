import type { MeasureProps } from './useMeasuredView.types';

export const measureView = (props: MeasureProps) => {
  const { reference, width, height, initialMeasureOnly } = props;
  const { setWidth, setHeight } = props;
  const hasAlreadyMeasured = Boolean(width) || Boolean(height);
  const shouldSkipMeasuring = initialMeasureOnly && hasAlreadyMeasured;

  if (!reference?.current || shouldSkipMeasuring) return;
  reference.current.measure((_x, _y, _width, _height) => {
    setWidth?.(_width);
    setHeight?.(_height);
  });
};

import React from 'react';
import type { ViewProps } from 'react-native';
import { Pressable, View } from 'react-native';

import { Button } from '../../Button';
import { FadeView } from '../../FadeView';
import { Icon } from '../../Icon';
import { BodyText } from '../../Text';
import { styles } from '../TextField.styles';
import type {
  ClearButtonProps,
  IconButtonProps,
  PasswordToggleProps,
  SubmitButtonProps,
} from '../TextField.types';

export const TextFieldClearButton = React.memo(
  ({
    show,
    onPress,
    variation,
    label,
    accessibilityHint,
  }: ClearButtonProps) => (
    <FadeView show={show} style={styles.ctaWrapper}>
      <Pressable
        onPress={onPress}
        accessibilityRole="button"
        accessibilityLabel={label}
        accessibilityHint={accessibilityHint}
      >
        {variation === 'icon' ? (
          <View testID="text-field-clear-button-icon">
            <Icon name="IconClose" width={16} height={16} />
          </View>
        ) : (
          <TextFieldCtaTextContent testID="text-field-clear-button-text">
            {label}
          </TextFieldCtaTextContent>
        )}
      </Pressable>
    </FadeView>
  ),
);

export const TextFieldSubmitButton = React.memo(
  ({
    show,
    accessibilityLabel,
    accessibilityHint,
    testID,
    icon = 'IconArrowRight',
    isDisabled,
    onPress,
  }: SubmitButtonProps) => (
    <FadeView show={show} style={styles.ctaWrapper}>
      <Button
        testID={testID}
        disabled={isDisabled}
        onPress={onPress}
        centerIcon={icon}
        hitSlop={{ top: 4, bottom: 4 }}
        style={styles.submitButton}
        accessibilityLabel={accessibilityLabel}
        accessibilityHint={accessibilityHint}
      />
    </FadeView>
  ),
);

export const TextFieldPasswordToggle = (props: PasswordToggleProps) => {
  const {
    onPress,
    show,
    showPassword,
    showPasswordLabel,
    hidePasswordLabel,
    toggleTestID,
  } = props;

  if (!show) return null;

  return (
    <View style={styles.ctaWrapper}>
      <Pressable
        onPress={onPress}
        accessibilityRole="button"
        testID={toggleTestID}
      >
        <TextFieldCtaTextContent>
          {showPassword ? hidePasswordLabel : showPasswordLabel}
        </TextFieldCtaTextContent>
      </Pressable>
    </View>
  );
};

const TextFieldCtaTextContent = ({
  children,
  testID,
}: Pick<ViewProps, 'children' | 'testID'>) => {
  return (
    <View style={styles.textCtaWrapper} testID={testID}>
      <BodyText size={5} style={styles.textCtaContent}>
        {children}
      </BodyText>
    </View>
  );
};

export const TextFieldIcon = ({
  show,
  icon,
  accessibilityLabel,
  accessibilityHint,
  onPress,
}: IconButtonProps) => {
  return (
    <FadeView show={show} style={styles.ctaWrapper}>
      <Pressable
        onPress={onPress}
        accessibilityRole="button"
        accessibilityLabel={accessibilityLabel}
        accessibilityHint={accessibilityHint}
      >
        <View testID="text-field-icon-option">
          {icon ? <Icon name={icon} width={24} height={24} /> : null}
        </View>
      </Pressable>
    </FadeView>
  );
};

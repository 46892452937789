import React, { memo } from 'react';

import { LocationResultsListsV2Locations } from '../../components';
import { type LocationResultsListsV2Props } from '../../LocationResultsListsV2.types';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsListsV2SM = memo(
  (props: LocationResultsListsV2SMProps) => {
    const {
      variation,
      selectedLocationSearchType,
      recentLocations = [],
      nearbyLocations = { pickup: [], outpost: [] },
      locations = [],
      isLoading,
      focusedLocationId,
      interactedLocationRestaurantSlug,
      interactedLocationDeliveryAddressId,
      onRecentOrNearbyLocationCardPress,
      onLocationCardFocus,
      onLocationCardPress,
      onDeliveryLocationCardPress,
    } = props;

    // ─────────────────────────────────────────────────────────────────────

    return (
      <LocationResultsListsV2Locations
        variation={variation}
        selectedLocationSearchType={selectedLocationSearchType}
        recentLocations={recentLocations}
        nearbyLocations={nearbyLocations}
        locations={locations}
        focusedLocationId={focusedLocationId}
        isLoading={isLoading}
        interactedLocationDeliveryAddressId={
          interactedLocationDeliveryAddressId
        }
        interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
        onRecentOrNearbyLocationCardPress={onRecentOrNearbyLocationCardPress}
        onLocationCardPress={onLocationCardPress}
        onDeliveryLocationCardPress={onDeliveryLocationCardPress}
        onLocationCardFocus={onLocationCardFocus}
      />
    );
  },
);

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsListsV2SMProps = Omit<
  LocationResultsListsV2Props,
  'isExpandedByDefault'
>;

import React, { useCallback } from 'react';
import { openUrl } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import { getEnvVars } from '@order/utils';

import { ShareOption } from './ShareOption';
import type { ShareOptionProps } from './types';

export const FacebookShareOption = ({ shareLink }: ShareOptionProps) => {
  const { t } = useLocalizationContext();

  const handleShare = useCallback(async () => {
    const shareUrl = `https://www.facebook.com/dialog/share?app_id=${
      getEnvVars().FACEBOOK_APP_ID
    }&quote=${t('account.refer-friend.share.content', { shareLink })}`;

    await openUrl(shareUrl, t('general.error'));
  }, [t, shareLink]);

  return (
    <ShareOption
      testID="account.refer-friend.share.facebook"
      hint={t('account.refer-friend.share.facebook')}
      iconName="IconFacebook"
      iconSize={40}
      bgColor="#3C5998"
      handleShare={handleShare}
    />
  );
};

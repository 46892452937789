/* istanbul ignore file */
/* eslint-disable @typescript-eslint/consistent-type-imports,@typescript-eslint/consistent-type-assertions */

import { assign, createMachine } from 'xstate';
import { hashObject } from '@sg/garnish';

import type {
  ContentfulContentTypeEntries,
  ContentfulContentTypeEntriesMachineContext,
  ContentfulContentTypeEntriesMachineEvents,
  ContentfulContentTypeEntriesMachineServices,
} from './contentful-content-type-entries-machine.types';
import {
  checkIfCacheEntryExists,
  readFromCacheOrFail,
  removeExpiredCacheEntries,
  writeToCacheOrFail,
} from './services';
import { extractContentTypeEntryData } from './utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A state machine to fetch the data from Contentful with caching support.
 */
export const createContentfulContentTypeEntriesMachine = <Fields>() =>
  createMachine(
    {
      tsTypes:
        {} as import('./contentful-content-type-entries-machine.typegen').Typegen0,
      predictableActionArguments: true,
      preserveActionOrder: true,

      schema: {
        context: {} as ContentfulContentTypeEntriesMachineContext<Fields>,
        events: {} as ContentfulContentTypeEntriesMachineEvents,
        services: {} as ContentfulContentTypeEntriesMachineServices<Fields>,
      },

      context: {
        data: undefined,
        error: undefined,
        input: {
          query: undefined,
          ttl: undefined,
        },
        _cacheEntryId: undefined,
        isPreviewMode: false,
      },

      initial: 'waiting',
      states: {
        waiting: {
          on: {
            GET_ENTRIES: [
              {
                target: 'searching',
                cond: 'checkIfShouldReadFromCache',
                actions: 'setInput',
              },
              {
                target: 'fetching',
                actions: 'setInput',
              },
            ],
          },
        },
        idle: {
          on: {
            GET_ENTRIES: [
              {
                target: 'searching',
                cond: 'checkIfShouldReadFromCache',
                actions: 'setInput',
              },
              {
                target: 'fetching',
                actions: 'setInput',
              },
            ],
          },
          exit: ['clearError'],
        },
        searching: {
          invoke: {
            src: async (context) =>
              checkIfCacheEntryExists(context._cacheEntryId),
            onDone: 'reading',
            onError: 'fetching',
          },
        },
        reading: {
          invoke: {
            src: async (context) =>
              readFromCacheOrFail<Fields>(context._cacheEntryId),
            onDone: {
              target: 'idle',
              actions: 'setDataFromCache',
            },
            onError: 'fetching',
          },
        },
        fetching: {
          invoke: {
            src: 'getEntries',
            onDone: [
              {
                target: 'writing',
                actions: 'setData',
                cond: 'checkIfShouldWriteToCache',
              },
              {
                target: 'idle',
                actions: 'setData',
              },
            ],
            onError: {
              target: 'idle',
              actions: 'setError',
            },
          },
        },
        writing: {
          invoke: {
            src: async (context) =>
              writeToCacheOrFail<Fields>({
                data: context.data,
                queryHash: context._cacheEntryId,
                ttl: context.input.ttl,
              }),
            onDone: 'idle',
            onError: 'idle',
          },
        },
      },
    },
    {
      actions: {
        setInput: assign((_context, event) => {
          const hash = hashObject(event.input.query, { shouldSortKeys: true });
          const cacheEntryId = `${hash}`;

          return {
            input: event.input,
            _cacheEntryId: cacheEntryId,
          };
        }),
        setDataFromCache: assign((_context, event) => ({
          // NOTE: For some reason XState type generator cannot correctly infer the type of `data`
          data: event.data as ContentfulContentTypeEntries<Fields>,
        })),
        setData: assign((_context, event) => {
          const entries = event.data?.items ?? [];

          return {
            data: entries.map(extractContentTypeEntryData),
          };
        }),
        setError: assign((_context, event) => ({
          error: event.data,
        })),
        clearError: assign((_context) => ({
          error: undefined,
        })),
      },
      guards: {
        checkIfShouldReadFromCache: (context, event) =>
          !context.isPreviewMode && event.input.ttl > 0,
        checkIfShouldWriteToCache: (context) =>
          !context.isPreviewMode && (context.input?.ttl ?? 0) > 0,
      },
      services: {
        // NOTE: Should be provided externally.
        getEntries: async () => undefined,
      },
    },
  );

// ─────────────────────────────────────────────────────────────────────────────

// We need to remove expired/invalid cache entries as soon as possible.
void removeExpiredCacheEntries();

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetSessionQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSessionQuery = {
  readonly __typename?: 'Query';
  readonly session: {
    readonly __typename?: 'Session';
    readonly csrf: string;
    readonly isLoggedIn: boolean;
  };
};

export const GetSessionDocument = gql`
  query getSession {
    session {
      csrf
      isLoggedIn
    }
  }
`;

export function useGetSessionQuery(
  options?: Omit<Urql.UseQueryArgs<GetSessionQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetSessionQuery, GetSessionQueryVariables>({
    query: GetSessionDocument,
    ...options,
  });
}

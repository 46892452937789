import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';
import {
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsBackdrop = memo(
  (props: LocationResultsBackdropProps) => {
    const { isVisible } = props;

    const backdropBgOpacity = useDerivedValue(() =>
      withTiming(isVisible ? 1 : 0),
    );
    const backdropAnimatedStyle = useAnimatedStyle(() => {
      return {
        opacity: backdropBgOpacity.value,
      };
    });
    const backdropStyles = [styles.backdrop, backdropAnimatedStyle];

    return <Animated.View style={backdropStyles} pointerEvents="none" />;
  },
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.OPACITY.DARK_KALE.DARKER,
    opacity: 0,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsBackdropProps = Readonly<{
  isVisible: boolean;
}>;

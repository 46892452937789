import { useCallback } from 'react';
import type { LocationObject } from 'expo-location';
import { useResponsive } from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';
import type { TelemetryEvent } from '@order/Telemetry';
import { telemetryLocationFromInput, useTelemetry } from '@order/Telemetry';

import type { LocationSearchMachineContext } from '../../machine';

// ─────────────────────────────────────────────────────────────────────────────

export const useLocationSearchTelemetry = () => {
  const { track, setCustomerProperty } = useTelemetry();
  const { currentBreakpoint } = useResponsive();

  const shouldHideOutpostResults = useFeatureFlag(
    'em-888-hide-outpost-from-map-pickup-section',
  );

  const getDisplayedChannels = useCallback(
    (
      locationSearchType: LocationSearchMachineContext['locationSearchType'],
    ) => {
      if (locationSearchType === 'pickup' && shouldHideOutpostResults) {
        return ['pickup'];
      }

      if (locationSearchType === 'delivery') {
        return ['delivery'];
      }

      return ['pickup', 'outpost'];
    },
    [shouldHideOutpostResults],
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  const trackUserGeolocation = useCallback(
    (location: { coords: CurrentCoordinates }) => {
      const lat = location?.coords?.latitude ?? 0;
      const lng = location?.coords?.longitude ?? 0;
      const hasUserLocation = lat !== 0 && lng !== 0;

      if (hasUserLocation) {
        setCustomerProperty?.('lat', lat);
        setCustomerProperty?.('lng', lng);
      }
    },
    [setCustomerProperty],
  );

  const trackLocationSearchType = useCallback(
    (context: LocationSearchMachineContext) => {
      const { locationSearchType } = context;

      track('location.channel_changed', { channel: locationSearchType });
    },
    [track],
  );

  const trackLocationSearchByArea = useCallback(
    (context: LocationSearchMachineContext) => {
      const { locations, currentPosition, locationSearchType } = context;
      const { pickup = [], outpost = [] } = locations ?? {};
      const hasLocations = pickup.length + outpost.length > 0;

      track('location.search', {
        searchMethod: 'area-button',
        resultsReturned: hasLocations,
        geolocationEnabled: currentPosition !== undefined,
        displayedChannels: getDisplayedChannels(locationSearchType),
      });
    },
    [track, getDisplayedChannels],
  );

  const trackLocationSearchByString = useCallback(
    (context: LocationSearchMachineContext) => {
      const { locations, currentPosition, locationSearchType } = context;
      const { pickup = [], outpost = [] } = locations ?? {};
      const hasLocations = pickup.length + outpost.length > 0;

      track('location.search', {
        searchMethod: 'search-field',
        resultsReturned: hasLocations,
        geolocationEnabled: currentPosition !== undefined,
        displayedChannels: getDisplayedChannels(locationSearchType),
      });
    },
    [track, getDisplayedChannels],
  );

  const trackLocationSearchByDeliveryPlaceID = useCallback(
    (context: LocationSearchMachineContext) => {
      const { currentPosition, deliveryLocation, locationSearchType } = context;

      if (!deliveryLocation) return;

      const hasRestaurant = Boolean(deliveryLocation.restaurantId);

      if (!hasRestaurant) {
        track('location.delivery_unavailable');

        return;
      }

      track('location.search', {
        searchMethod: 'search-field',
        resultsReturned: true,
        geolocationEnabled: currentPosition !== undefined,
        displayedChannels: locationSearchType ? [locationSearchType] : [],
      });
    },
    [track],
  );

  const trackFocusedLocationPinId = useCallback(
    (context: LocationSearchMachineContext) => {
      const { focusedPinId } = context;
      const isLocationPin = focusedPinId !== 'customer';

      if (!isLocationPin) return;

      track('location.pin_selected');
    },
    [track],
  );

  const trackNavigateToLocationMenu = useCallback(
    (entryPoint?: LocationNavigationEntryPoint) =>
      (context: LocationSearchMachineContext) => {
        track('location.open_menu', {
          location: telemetryLocationFromInput(context.activeLocation),
          bottomSheetOpen: currentBreakpoint.isXS,
          entryPoint,
        });
      },
    [currentBreakpoint.isXS, track],
  );

  const trackNavigateToDeliveryLocationMenu = useCallback(
    (entryPoint?: LocationNavigationEntryPoint) =>
      (context: LocationSearchMachineContext) => {
        track('location.open_menu', {
          location: telemetryLocationFromInput(context.activeLocation),
          bottomSheetOpen: currentBreakpoint.isXS,
          entryPoint,
        });
      },
    [currentBreakpoint.isXS, track],
  );

  const trackLocationResultsExpandCollapse = useCallback(
    (isExpanded: boolean) => {
      track('location.bottom_sheet', {
        state: isExpanded ? 'expanded' : 'collapsed',
      });
    },
    [track],
  );

  // ─────────────────────────────────────────────────────────────────────

  return {
    trackUserGeolocation,
    trackLocationSearchByArea,
    trackLocationSearchByString,
    trackLocationSearchByDeliveryPlaceID,
    trackLocationResultsExpandCollapse,
    trackNavigateToLocationMenu,
    trackNavigateToDeliveryLocationMenu,
    trackLocationSearchType,
    trackFocusedLocationPinId,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationNavigationEntryPoint =
  TelemetryEvent<'location.open_menu'>['payload']['entryPoint'];

type CurrentCoordinates = Pick<
  LocationObject['coords'],
  'longitude' | 'latitude'
>;

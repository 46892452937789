import React, { useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
  type WithTimingConfig,
} from 'react-native-reanimated';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────
// This is a react-native-reanimated view that behaves like an overlay.
// It works by animating its opacity whenever the `show` prop changes.
// ─────────────────────────────────────────────────────────────────────────────

export const AnimatedDialogOverlay = (props: AnimatedDialogOverlayProps) => {
  const { show, accessibilityLabel = 'Dismiss modal', dismissModal } = props;

  const [shouldRenderChildren, setShouldRenderChildren] = useState(show);

  const overlayOpacity = useDerivedValue(() => {
    runOnJS(setShouldRenderChildren)(true);

    return withTiming(show ? 0.3 : 0, TIMING_CONFIG, (finished) => {
      if (!finished) return;

      runOnJS(setShouldRenderChildren)(show);
    });
  }, [show]);

  const animatedOpacity = useAnimatedStyle(() => ({
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.BLACK,
    opacity: overlayOpacity.value,
  }));

  if (!shouldRenderChildren) return null;

  return (
    <Pressable
      pointerEvents="auto"
      style={StyleSheet.absoluteFill}
      accessibilityRole="button"
      accessibilityLabel={accessibilityLabel}
      onPress={dismissModal}
    >
      <Animated.View style={animatedOpacity} />
    </Pressable>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const TIMING_CONFIG: WithTimingConfig = {
  duration: 300,
};

// ─── Types ───────────────────────────────────────────────────────────────────

type AnimatedDialogOverlayProps = {
  show: boolean;
  accessibilityLabel?: string;
  dismissModal: () => void;
};

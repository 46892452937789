export const sweetpassHomeContentfulDataFixture = {
  name: 'Sweetpass Home Page',
  content: [
    {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'wme4s8lvzccr',
          },
        },
        type: 'Entry',
        id: '3llbOQZ85TZs9GdAwDvhAW',
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: 'sweetpassHomeStats',
          },
        },
        revision: 5,
        createdAt: '2022-12-17T01:40:12.573Z',
        updatedAt: '2023-02-16T17:14:15.748Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        locale: 'en-US',
      },
      fields: {
        name: 'Sweetpass Home Stats',
        infoText: 'Member since {sweetpass_join_date}',
        noticeText: 'You are a beta tester of this loyalty program.',
        ctaText: 'Explore your benefits',
      },
    },
    {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'wme4s8lvzccr',
          },
        },
        type: 'Entry',
        id: '38anNSCJp00LMubqtuz0CO',
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: 'sweetpassHomeActionsRail',
          },
        },
        revision: 45,
        createdAt: '2022-12-17T01:27:53.747Z',
        updatedAt: '2023-02-16T21:18:10.718Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        locale: 'en-US',
      },
      fields: {
        name: 'Sweetpass Home Actions Rail',
        titleText: 'Explore Sweetpass beta',
        actionCards: [
          {
            metadata: {
              tags: [
                {
                  sys: {
                    type: 'Link',
                    linkType: 'Tag',
                    id: 'SWEETPASS_PLUS',
                  },
                },
              ],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              type: 'Entry',
              id: '44kkvLL7lBPr1OS36PlPti',
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'sweetpassHomeActionCard',
                },
              },
              revision: 0,
              createdAt: '2023-02-15T14:56:50.683Z',
              updatedAt: '2023-02-15T15:04:56.689Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              locale: 'en-US',
            },
            fields: {
              name: 'Draft Test',
              headingText: 'Draft Test',
              bodyText: 'This is a test for an example of Draft content.',
              image: {
                metadata: {
                  tags: [],
                },
                sys: {
                  space: {
                    sys: {
                      type: 'Link',
                      linkType: 'Space',
                      id: 'wme4s8lvzccr',
                    },
                  },
                  type: 'Asset',
                  id: '5CZHiZQIKqZBu73Ow2BGjs',
                  revision: 3,
                  createdAt: '2022-10-27T18:45:32.918Z',
                  updatedAt: '2023-01-27T18:07:13.254Z',
                  environment: {
                    sys: {
                      id: 'master',
                      type: 'Link',
                      linkType: 'Environment',
                    },
                  },
                  locale: 'en-US',
                },
                fields: {
                  title: 'Sweetpass Upgrade Benefit: All sweetpass benefits',
                  description: '',
                  file: {
                    url: '//images.ctfassets.net/wme4s8lvzccr/5CZHiZQIKqZBu73Ow2BGjs/44f15982df62cec43c198230b708d9b1/upgrade-benefit.all-sweetpass-benefits.png',
                    details: {
                      size: 1000,
                      image: {
                        width: 2048,
                        height: 2048,
                      },
                    },
                    fileName: 'upgrade-benefit.all-sweetpass-benefits.png',
                    contentType: 'image/png',
                  },
                },
              },
              action: 'explore_menu',
              layout: 'rectangle_frame',
              theme: 'kale_on_lime',
            },
          },
          {
            metadata: {
              tags: [
                {
                  sys: {
                    type: 'Link',
                    linkType: 'Tag',
                    id: 'SWEETPASS',
                  },
                },
              ],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              type: 'Entry',
              id: '2uqKs8B7BriQsPKExHLI4E',
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'sweetpassHomeActionCard',
                },
              },
              revision: 16,
              createdAt: '2023-01-25T20:29:22.288Z',
              updatedAt: '2023-02-07T22:15:43.621Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              locale: 'en-US',
            },
            fields: {
              name: 'Sweetpass Home Action - Upgrade to Sweetpass+',
              headingText: 'Upgrade to Sweetpass+',
              bodyText: 'Save even more',
              image: {
                metadata: {
                  tags: [],
                },
                sys: {
                  space: {
                    sys: {
                      type: 'Link',
                      linkType: 'Space',
                      id: 'wme4s8lvzccr',
                    },
                  },
                  type: 'Asset',
                  id: 'q7iU3F3kuBBiJceCaOLHM',
                  revision: 2,
                  createdAt: '2023-02-02T00:02:47.426Z',
                  updatedAt: '2023-02-02T00:07:02.230Z',
                  environment: {
                    sys: {
                      id: 'master',
                      type: 'Link',
                      linkType: 'Environment',
                    },
                  },
                  locale: 'en-US',
                },
                fields: {
                  title: 'Sweetpass Action Card - Upgrade ',
                  description: 'Upgrade to Sweetpass+',
                  file: {
                    url: '//images.ctfassets.net/wme4s8lvzccr/q7iU3F3kuBBiJceCaOLHM/47d1063776de99d27b187da91f55de20/Upgrade_Sweetpass_.jpg',
                    details: {
                      size: 1000,
                      image: {
                        width: 1200,
                        height: 1200,
                      },
                    },
                    fileName: 'Upgrade_Sweetpass+.jpg',
                    contentType: 'image/jpeg',
                  },
                },
              },
              action: 'upgrade_sweetpass',
              layout: 'full_bleed',
              theme: 'kale_on_cucumber',
            },
          },
          {
            metadata: {
              tags: [
                {
                  sys: {
                    type: 'Link',
                    linkType: 'Tag',
                    id: 'SWEETPASS_PLUS',
                  },
                },
              ],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              type: 'Entry',
              id: '39oUxZYkBe5zzNAZlb0qIj',
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'sweetpassHomeActionCard',
                },
              },
              revision: 4,
              createdAt: '2023-02-08T00:08:48.010Z',
              updatedAt: '2023-02-15T21:53:55.035Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              locale: 'en-US',
            },
            fields: {
              name: 'Sweetpass Home Action - Daily $3 Offer',
              headingText: 'Maximize your greens with $3 off daily',
              bodyText: 'Order now',
              image: {
                metadata: {
                  tags: [],
                },
                sys: {
                  space: {
                    sys: {
                      type: 'Link',
                      linkType: 'Space',
                      id: 'wme4s8lvzccr',
                    },
                  },
                  type: 'Asset',
                  id: 'Z7AOXbYeZE0vJAcWkbR1X',
                  revision: 1,
                  createdAt: '2023-01-25T21:46:26.063Z',
                  updatedAt: '2023-01-25T21:47:32.763Z',
                  environment: {
                    sys: {
                      id: 'master',
                      type: 'Link',
                      linkType: 'Environment',
                    },
                  },
                  locale: 'en-US',
                },
                fields: {
                  title: 'Keyboard with Kale',
                  description:
                    'Keyboard with Kale sprouting out in between keys',
                  file: {
                    url: '//images.ctfassets.net/wme4s8lvzccr/Z7AOXbYeZE0vJAcWkbR1X/cebfe9deb98081d16602c435df788dd9/Order_Image.png',
                    details: {
                      size: 1000,
                      image: {
                        width: 1200,
                        height: 1200,
                      },
                    },
                    fileName: 'Order Image.png',
                    contentType: 'image/png',
                  },
                },
              },
              action: 'explore_menu',
              layout: 'rectangle_frame',
              theme: 'kale_on_lime',
            },
          },
          {
            metadata: {
              tags: [
                {
                  sys: {
                    type: 'Link',
                    linkType: 'Tag',
                    id: 'ALL_TIERS',
                  },
                },
              ],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              type: 'Entry',
              id: '2VYO3xKyhPL1CHxkgRWRXj',
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'sweetpassHomeActionCard',
                },
              },
              revision: 7,
              createdAt: '2023-02-08T23:56:32.361Z',
              updatedAt: '2023-02-14T18:18:56.821Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              locale: 'en-US',
            },
            fields: {
              name: 'Sweetpass Home Action - Explore Challenges',
              headingText: 'Explore Challenges',
              bodyText: 'Complete them to get rewards',
              image: {
                metadata: {
                  tags: [],
                },
                sys: {
                  space: {
                    sys: {
                      type: 'Link',
                      linkType: 'Space',
                      id: 'wme4s8lvzccr',
                    },
                  },
                  type: 'Asset',
                  id: '6FLiQI94jm6LTfCoq2TnWE',
                  revision: 2,
                  createdAt: '2022-10-27T18:55:41.699Z',
                  updatedAt: '2022-10-27T18:58:54.540Z',
                  environment: {
                    sys: {
                      id: 'master',
                      type: 'Link',
                      linkType: 'Environment',
                    },
                  },
                  locale: 'en-US',
                },
                fields: {
                  title: 'Sweetpass Upgrade Benefit: Sweetgreen exclusives',
                  description: '',
                  file: {
                    url: '//images.ctfassets.net/wme4s8lvzccr/6FLiQI94jm6LTfCoq2TnWE/8925221ac656c4b84adf662a4c9dcf2e/upgrade-benefit.sweetgreen-exclusives.png',
                    details: {
                      size: 1000,
                      image: {
                        width: 2048,
                        height: 2048,
                      },
                    },
                    fileName: 'upgrade-benefit.sweetgreen-exclusives.png',
                    contentType: 'image/png',
                  },
                },
              },
              action: 'explore_challenges',
              layout: 'rectangle_frame',
              theme: 'kale_on_cucumber',
            },
          },
          {
            metadata: {
              tags: [
                {
                  sys: {
                    type: 'Link',
                    linkType: 'Tag',
                    id: 'ALL_TIERS',
                  },
                },
                {
                  sys: {
                    type: 'Link',
                    linkType: 'Tag',
                    id: 'SWEETPASS_PLUS',
                  },
                },
              ],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              type: 'Entry',
              id: '7HtPFG9BEwfC7s7gUs86y4',
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'sweetpassHomeActionCard',
                },
              },
              revision: 4,
              createdAt: '2023-02-08T23:51:50.170Z',
              updatedAt: '2023-02-10T20:46:58.433Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              locale: 'en-US',
            },
            fields: {
              name: 'Sweetpass Home Action - Explore Menu',
              headingText: 'Explore Menu',
              bodyText: 'Check out our salads',
              image: {
                metadata: {
                  tags: [],
                },
                sys: {
                  space: {
                    sys: {
                      type: 'Link',
                      linkType: 'Space',
                      id: 'wme4s8lvzccr',
                    },
                  },
                  type: 'Asset',
                  id: '6uJ1H7kwsPrQfIBNfv9WP1',
                  revision: 2,
                  createdAt: '2022-10-27T18:57:12.653Z',
                  updatedAt: '2022-10-27T18:58:54.554Z',
                  environment: {
                    sys: {
                      id: 'master',
                      type: 'Link',
                      linkType: 'Environment',
                    },
                  },
                  locale: 'en-US',
                },
                fields: {
                  title: 'Sweetpass Upgrade Benefit: Limited edition drops',
                  description: '',
                  file: {
                    url: '//images.ctfassets.net/wme4s8lvzccr/6uJ1H7kwsPrQfIBNfv9WP1/125f29f4e6ed23d482244b32ddff66e8/upgrade-benefit.limited-edition-drops.png',
                    details: {
                      size: 1000,
                      image: {
                        width: 2048,
                        height: 2048,
                      },
                    },
                    fileName: 'upgrade-benefit.limited-edition-drops.png',
                    contentType: 'image/png',
                  },
                },
              },
              action: 'explore_menu',
              layout: 'rectangle_frame',
              theme: 'kale_on_cucumber',
            },
          },
          {
            metadata: {
              tags: [
                {
                  sys: {
                    type: 'Link',
                    linkType: 'Tag',
                    id: 'ALL_TIERS',
                  },
                },
              ],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              type: 'Entry',
              id: '5JHljyPssMOqKZMXRAYwS6',
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'sweetpassHomeActionCard',
                },
              },
              revision: 12,
              createdAt: '2022-12-21T22:29:43.283Z',
              updatedAt: '2023-02-07T22:16:08.357Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              locale: 'en-US',
            },
            fields: {
              name: 'Sweetpass Home Action - Free Birthday Treat',
              headingText: 'A free\nbirthday\ntreat\nfrom us\nto you',
              bodyText: 'Apply reward to your order at checkout.',
              image: {
                metadata: {
                  tags: [],
                },
                sys: {
                  space: {
                    sys: {
                      type: 'Link',
                      linkType: 'Space',
                      id: 'wme4s8lvzccr',
                    },
                  },
                  type: 'Asset',
                  id: '1RI7MGIHNyUau1kJQ1xOsT',
                  revision: 2,
                  createdAt: '2022-12-21T22:31:42.878Z',
                  updatedAt: '2022-12-23T19:53:20.899Z',
                  environment: {
                    sys: {
                      id: 'master',
                      type: 'Link',
                      linkType: 'Environment',
                    },
                  },
                  locale: 'en-US',
                },
                fields: {
                  title: 'Sweetpass Home Action - Free Birthday Treat',
                  description: 'An image of a Crispy Rice Treat.',
                  file: {
                    url: '//images.ctfassets.net/wme4s8lvzccr/1RI7MGIHNyUau1kJQ1xOsT/3e22bf088c9feb111dc1dea19d36bc9f/Sweetpass_Home_Action_-_Free_Birthday_Treat.png',
                    details: {
                      size: 1000,
                      image: {
                        width: 686,
                        height: 686,
                      },
                    },
                    fileName: 'Sweetpass Home Action - Free Birthday Treat.png',
                    contentType: 'image/png',
                  },
                },
              },
              layout: 'full_bleed',
              theme: 'lime_on_tangerine',
            },
          },
          {
            metadata: {
              tags: [
                {
                  sys: {
                    type: 'Link',
                    linkType: 'Tag',
                    id: 'ALL_TIERS',
                  },
                },
              ],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              type: 'Entry',
              id: '2WbvfRs0Y6rd72zUfboZsU',
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'sweetpassHomeActionCard',
                },
              },
              revision: 3,
              createdAt: '2023-02-08T23:54:19.272Z',
              updatedAt: '2023-02-09T00:25:30.981Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              locale: 'en-US',
            },
            fields: {
              name: 'Sweetpass Home Action - Explore Benefits',
              headingText: 'Explore Benefits',
              bodyText: 'What you get with Sweetpass',
              image: {
                metadata: {
                  tags: [],
                },
                sys: {
                  space: {
                    sys: {
                      type: 'Link',
                      linkType: 'Space',
                      id: 'wme4s8lvzccr',
                    },
                  },
                  type: 'Asset',
                  id: '4P532Rt0MgDIYmgwwQdlQ3',
                  revision: 2,
                  createdAt: '2022-10-27T18:52:12.070Z',
                  updatedAt: '2022-10-27T18:58:54.525Z',
                  environment: {
                    sys: {
                      id: 'master',
                      type: 'Link',
                      linkType: 'Environment',
                    },
                  },
                  locale: 'en-US',
                },
                fields: {
                  title: 'Sweetpass Upgrade Benefit: Rewards and challenges',
                  description: '',
                  file: {
                    url: '//images.ctfassets.net/wme4s8lvzccr/4P532Rt0MgDIYmgwwQdlQ3/9f2c3edf8202c4cbe7c95ad21ba04ee3/upgrade-benefit.rewards-and-challenges.png',
                    details: {
                      size: 1000,
                      image: {
                        width: 2048,
                        height: 2048,
                      },
                    },
                    fileName: 'upgrade-benefit.rewards-and-challenges.png',
                    contentType: 'image/png',
                  },
                },
              },
              action: 'explore_benefits',
              layout: 'rectangle_frame',
              theme: 'kale_on_cucumber',
            },
          },
          {
            metadata: {
              tags: [
                {
                  sys: {
                    type: 'Link',
                    linkType: 'Tag',
                    id: 'ALL_TIERS',
                  },
                },
              ],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              type: 'Entry',
              id: '7mmeNzRQ2mwwdj5615osof',
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'sweetpassHomeActionCard',
                },
              },
              revision: 3,
              createdAt: '2023-02-08T23:57:24.516Z',
              updatedAt: '2023-02-09T00:25:20.985Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              locale: 'en-US',
            },
            fields: {
              name: 'Sweetpass Home Action - Explore Rewards',
              headingText: 'Explore Rewards',
              bodyText: 'Enjoy your rewards!',
              image: {
                metadata: {
                  tags: [],
                },
                sys: {
                  space: {
                    sys: {
                      type: 'Link',
                      linkType: 'Space',
                      id: 'wme4s8lvzccr',
                    },
                  },
                  type: 'Asset',
                  id: '2Cm6rx9eiqunKy7uj28ORZ',
                  revision: 2,
                  createdAt: '2022-10-27T18:50:18.274Z',
                  updatedAt: '2022-10-27T18:58:54.533Z',
                  environment: {
                    sys: {
                      id: 'master',
                      type: 'Link',
                      linkType: 'Environment',
                    },
                  },
                  locale: 'en-US',
                },
                fields: {
                  title: 'Sweetpass Upgrade Benefit: Birthday gift',
                  description: '',
                  file: {
                    url: '//images.ctfassets.net/wme4s8lvzccr/2Cm6rx9eiqunKy7uj28ORZ/7d050c8df32495b8a110118e5ebdbf83/upgrade-benefit.birthday-gift.png',
                    details: {
                      size: 1000,
                      image: {
                        width: 2048,
                        height: 2048,
                      },
                    },
                    fileName: 'upgrade-benefit.birthday-gift.png',
                    contentType: 'image/png',
                  },
                },
              },
              action: 'explore_rewards',
              layout: 'rectangle_frame',
              theme: 'kale_on_cucumber',
            },
          },
          {
            metadata: {
              tags: [],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              type: 'Entry',
              id: '1WTVE0z8GgH07NBfJnRoPb',
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'sweetpassHomeActionCard',
                },
              },
              revision: 31,
              createdAt: '2022-12-17T01:28:31.701Z',
              updatedAt: '2023-02-15T21:56:03.696Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              locale: 'en-US',
            },
            fields: {
              name: 'Sweetpass Home Action - Free Welcome Beverage',
              headingText: 'Welcome — get a free beverage on us',
              bodyText: 'Apply reward to your first order at checkout.',
              image: {
                metadata: {
                  tags: [],
                },
                sys: {
                  space: {
                    sys: {
                      type: 'Link',
                      linkType: 'Space',
                      id: 'wme4s8lvzccr',
                    },
                  },
                  type: 'Asset',
                  id: '7ivD31zuX1cDCPlZLVFIIR',
                  revision: 3,
                  createdAt: '2022-12-17T01:36:51.146Z',
                  updatedAt: '2022-12-23T19:55:41.337Z',
                  environment: {
                    sys: {
                      id: 'master',
                      type: 'Link',
                      linkType: 'Environment',
                    },
                  },
                  locale: 'en-US',
                },
                fields: {
                  title: 'Sweetpass Home Action - Free Welcome Beverage',
                  description: '',
                  file: {
                    url: '//images.ctfassets.net/wme4s8lvzccr/7ivD31zuX1cDCPlZLVFIIR/4f594f9425418a68dc27aee12f15f041/Sweetpass_Home_Action_-_Free_Welcome_Beverage.png',
                    details: {
                      size: 1000,
                      image: {
                        width: 1000,
                        height: 1000,
                      },
                    },
                    fileName:
                      'Sweetpass Home Action - Free Welcome Beverage.png',
                    contentType: 'image/png',
                  },
                },
              },
              action: 'free_welcome_beverage',
              layout: 'rectangle_frame',
              theme: 'kale_on_cucumber',
            },
          },
        ],
      },
    },
    {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'wme4s8lvzccr',
          },
        },
        type: 'Entry',
        id: '3Zfwl96WOyoc0SuKQWymVa',
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: 'sweetpassHomeChallengesRail',
          },
        },
        revision: 4,
        createdAt: '2022-12-14T20:17:42.915Z',
        updatedAt: '2023-02-15T21:42:49.435Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        locale: 'en-US',
      },
      fields: {
        name: 'Sweetpass Home Challenges Rail',
        titleText: 'Challenges',
        bodyText:
          'Opt in + complete delicious challenges to savor more rewards. ',
        emptyContentText: 'No challenges available at the moment',
        emptyContentImage: {
          metadata: {
            tags: [],
          },
          sys: {
            space: {
              sys: {
                type: 'Link',
                linkType: 'Space',
                id: 'wme4s8lvzccr',
              },
            },
            type: 'Asset',
            id: '3NoYPz4pWvxykxqjMxWV18',
            revision: 3,
            createdAt: '2022-12-17T01:22:23.335Z',
            updatedAt: '2023-02-20T19:53:34.715Z',
            environment: {
              sys: {
                id: 'master',
                type: 'Link',
                linkType: 'Environment',
              },
            },
            locale: 'en-US',
          },
          fields: {
            title: 'Empty Rail - Empty Bowl',
            description: '',
            file: {
              url: '//images.ctfassets.net/wme4s8lvzccr/3NoYPz4pWvxykxqjMxWV18/a5328051693daad1ec0e72fb47b76ca8/Frame_2x-2.png',
              details: {
                size: 1000,
                image: {
                  width: 440,
                  height: 260,
                },
              },
              fileName: 'Frame@2x-2.png',
              contentType: 'image/png',
            },
          },
        },
      },
    },
    {
      metadata: {
        tags: [],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'wme4s8lvzccr',
          },
        },
        type: 'Entry',
        id: '5njs6ZocXqz30DK1YILS27',
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: 'sweetpassHomeRewardsRail',
          },
        },
        revision: 5,
        createdAt: '2022-12-14T20:18:51.448Z',
        updatedAt: '2023-02-15T21:43:06.872Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        locale: 'en-US',
      },
      fields: {
        name: 'Sweetpass Home Rewards Rail',
        titleText: 'Rewards',
        bodyText:
          'At checkout, select your reward so it applies to your order.',
        emptyContentText: 'No rewards available at the moment',
        emptyContentImage: {
          metadata: {
            tags: [],
          },
          sys: {
            space: {
              sys: {
                type: 'Link',
                linkType: 'Space',
                id: 'wme4s8lvzccr',
              },
            },
            type: 'Asset',
            id: '5zaGNq2rbgBq3Rkinj0lxt',
            revision: 2,
            createdAt: '2022-12-17T01:24:32.320Z',
            updatedAt: '2023-02-20T19:55:04.131Z',
            environment: {
              sys: {
                id: 'master',
                type: 'Link',
                linkType: 'Environment',
              },
            },
            locale: 'en-US',
          },
          fields: {
            title: 'Empty Rail - Spring',
            description: '',
            file: {
              url: '//images.ctfassets.net/wme4s8lvzccr/5zaGNq2rbgBq3Rkinj0lxt/53443c5d238392e6694ed28a1a017df7/Frame_2x.png',
              details: {
                size: 1000,
                image: {
                  width: 440,
                  height: 260,
                },
              },
              fileName: 'Frame@2x.png',
              contentType: 'image/png',
            },
          },
        },
      },
    },
  ],
};

import { useMemo } from 'react';
import type { ImageURISource } from 'react-native';
import type { Asset } from 'contentful';
import { IllusEmpty_2 } from '@sg/garnish';

import { useContentfulContentTypeEntry } from '@order/Contentful';
import { useLocalizationContext } from '@order/Localization';

export const useMaintenanceModeData = () => {
  const { t } = useLocalizationContext();

  const {
    data: contentfulData,
    fetching: isLoadingContentfulData,
    error: contentfulError,
  } = useContentfulContentTypeEntry<MaintenanceScreenContent>({
    contentType: MAINTENANCE_SCREEN_CONTENT_ID,
  });

  const fallbackBody = t('general.maintenance-message');
  const fallbackIllustration = IllusEmpty_2;

  const contentfulBody = contentfulData?.fields?.body;
  const contentfulIllustration =
    contentfulData?.fields?.illustration?.fields?.file?.url;

  const body = contentfulBody ?? fallbackBody;
  const illustration: ImageURISource = useMemo(
    () =>
      contentfulIllustration
        ? { uri: contentfulIllustration }
        : fallbackIllustration,
    [contentfulIllustration, fallbackIllustration],
  );

  return useMemo(
    () => ({
      body,
      illustration,
      isLoadingContentfulData,
      contentfulError,
    }),
    [body, illustration, isLoadingContentfulData, contentfulError],
  );
};

type MaintenanceScreenContent = Readonly<{
  body?: string | null;
  illustration?: Asset | null;
}>;

const MAINTENANCE_SCREEN_CONTENT_ID = 'maintenanceScreen';

import React from 'react';
import type { ViewProps } from 'react-native';
import { switchcase, theme, useResponsive, VStack } from '@sg/garnish';

import { AccountScreenTitle } from '@order/components';
import { useLocalizationContext } from '@order/Localization';

import {
  SweetpassBillingHistorySection,
  SweetpassCancellationSection,
  SweetpassMembershipLevelSection,
  SweetpassPaymentMethodsSection,
} from './components';
import type {
  SweetpassBillingHistorySectionProps,
  SweetpassCancellationSectionProps,
  SweetpassMembershipLevelSectionProps,
  SweetpassPaymentMethodsSectionProps,
  useSweetpassMembershipContent,
} from './hooks';
import {
  ACCOUNT_SWEETPASS_BILLING_HISTORY_SECTION,
  ACCOUNT_SWEETPASS_CANCELLATION_SECTION,
  ACCOUNT_SWEETPASS_MEMBERSHIP_SECTION,
  ACCOUNT_SWEETPASS_PAYMENT_METHODS_SECTION,
} from './hooks';

export const SweetpassMembershipScreenContent = (
  props: SweetpassMembershipScreenContentProps,
) => {
  const { testID, sweetpassMembershipContent } = props;
  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  return (
    <>
      <AccountScreenTitle
        testID={testID?.concat('.title')}
        visibility="COMPACT"
        title={t('account.sweetpass-membership.title')}
      />
      <VStack testID={testID} gap={match([theme.spacing['6'], 0])}>
        {sweetpassMembershipContent?.map((section) =>
          switchcase({
            [ACCOUNT_SWEETPASS_MEMBERSHIP_SECTION]: (
              <SweetpassMembershipLevelSection
                key="membership-level-section"
                testID={testID?.concat('.membership-level-section')}
                {...(section as SweetpassMembershipLevelSectionProps)}
              />
            ),
            [ACCOUNT_SWEETPASS_CANCELLATION_SECTION]: (
              <SweetpassCancellationSection
                key="cancellation-section"
                testID={testID?.concat('.cancellation-section')}
                {...(section as SweetpassCancellationSectionProps)}
              />
            ),
            [ACCOUNT_SWEETPASS_PAYMENT_METHODS_SECTION]: (
              <SweetpassPaymentMethodsSection
                key="payment-methods-section"
                testID={testID?.concat('.payment-methods-section')}
                {...(section as SweetpassPaymentMethodsSectionProps)}
              />
            ),
            [ACCOUNT_SWEETPASS_BILLING_HISTORY_SECTION]: (
              <SweetpassBillingHistorySection
                key="billing-history-section"
                testID={testID?.concat('.billing-history-section')}
                {...(section as SweetpassBillingHistorySectionProps)}
              />
            ),
          })(null as unknown as React.ReactElement)(section.name ?? ''),
        )}
      </VStack>
    </>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassMembershipScreenContentProps = Readonly<{
  sweetpassMembershipContent: ReturnType<typeof useSweetpassMembershipContent>;
}> &
  ViewProps;

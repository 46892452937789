import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Button,
  PressableLink,
  theme,
  useResponsive,
  webOnlyStyles,
} from '@sg/garnish';

export const HomeBanner = (props: HomeBannerProps) => {
  const { message, cta, deepLink, onPress } = props;

  const { match } = useResponsive();

  return (
    <View
      style={[
        styles.bannerBackground,
        match([null, webOnlyStyles({ position: 'fixed' as never })]),
        match([styles.bannerXS, styles.bannerSM]),
      ]}
    >
      <View
        style={match([
          styles.bannerMessageWrapperXS,
          styles.bannerMessageWrapperSM,
        ])}
      >
        <BodyText sizeMatch={['16']} style={styles.bannerMessage}>
          {message}
        </BodyText>
      </View>

      <MaybeDeepLinkedButton cta={cta} deepLink={deepLink} onPress={onPress} />
    </View>
  );
};

const MaybeDeepLinkedButton = (props: Omit<HomeBannerProps, 'message'>) => {
  const { cta, deepLink, onPress } = props;

  if (!deepLink) {
    return (
      <Button
        size="small"
        palette="outline"
        style={styles.bannerCta}
        onPress={onPress}
      >
        {cta}
      </Button>
    );
  }

  return (
    <PressableLink
      style={styles.bannerCtaWithDeepLink}
      accessibilityLabel={cta}
      to={deepLink}
      onPress={onPress}
    >
      <BodyText sizeMatch={['14']}>{cta}</BodyText>
    </PressableLink>
  );
};

const styles = StyleSheet.create({
  bannerXS: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: -theme.spacing['4'],
    marginBottom: -theme.spacing['4'],
    padding: theme.spacing['4'],
    gap: theme.spacing['3'],
    borderTopWidth: 1,
    borderTopColor: theme.colors.DARK_KALE,
  },
  bannerSM: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing['4'],
    gap: theme.spacing['3'],
    zIndex: theme.zIndex.fixed,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.DARK_KALE,
  },
  bannerCtaWithDeepLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 32,
    borderWidth: 1,
    borderRadius: theme.radius.xxxlarge,
    borderColor: theme.colors.DARK_KALE,
    backgroundColor: theme.colors.OPACITY.TRANSPARENT,
    color: theme.colors.DARK_KALE,
    paddingHorizontal: theme.spacing['4'],
  },
  bannerCta: {
    paddingHorizontal: theme.spacing['4'],
  },
  bannerMessageWrapperXS: {
    flex: 1,
  },
  bannerMessageWrapperSM: {},
  bannerMessage: {
    color: theme.colors.CHARCOAL,
  },
  bannerBackground: {
    backgroundColor: theme.colors.CUCUMBER,
  },
});

type HomeBannerProps = {
  message: string;
  cta: string;
  deepLink?: string;
  onPress: () => void;
};

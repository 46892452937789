import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanInStoreTabContentContainer = (
  props: ScanInStoreTabContentContainerProps,
) => {
  const { style, children, ...restProps } = props;

  const containerStyles = [styles.container, style];

  return (
    <View style={containerStyles} {...restProps}>
      {children}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    padding: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanInStoreTabContentContainerProps = ComponentProps<typeof View>;

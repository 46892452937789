import { defineMessages, type useIntl } from 'react-intl';
import { type IconName } from '@sg/garnish';

import {
  type PaymentMethod,
  PaymentMethodType,
} from '../machines/ordering-machine.types';

export const getPaymentInfo = (
  params: PaymentInfoParams,
): PaymentInfo | undefined => {
  const {
    paymentMethod,
    paymentMethods,
    isApplePay,
    isGooglePay,
    formatMessage,
  } = params;

  if (isApplePay) {
    return {
      icon: 'IconApplePayPayment',
      name: formatMessage(messages.applePay),
    };
  }

  if (isGooglePay) {
    return { icon: 'IconGooglePay', name: formatMessage(messages.googlePay) };
  }

  if (paymentMethod) {
    return {
      icon: getCardIcon(paymentMethod),
      name: getCardName(formatMessage, paymentMethod),
    };
  }

  if (paymentMethods.length > 0) {
    return {
      icon: getCardIcon(paymentMethods[0]),
      name: getCardName(formatMessage, paymentMethods[0]),
    };
  }
};

export const getCardIcon = (paymentMethod: PaymentMethod) => {
  switch (paymentMethod.cardType) {
    case PaymentMethodType.Amex:
    case PaymentMethodType.AmericanExpress: {
      return 'IconCcAmex';
    }

    case PaymentMethodType.Discover: {
      return 'IconCcDiscover';
    }

    case PaymentMethodType.MasterCard: {
      return 'IconCcMasterCard';
    }

    case PaymentMethodType.Visa: {
      return 'IconCcVisa';
    }

    default: {
      return 'IconCreditCard';
    }
  }
};

export const getCardName = (
  formatMessage: ReturnType<typeof useIntl>['formatMessage'],
  paymentMethod?: PaymentMethod,
) => {
  const type = getReadableCardType(formatMessage, paymentMethod?.cardType);
  const digits = /\d+/g.exec(paymentMethod?.description ?? '')?.[0];
  const description = type && digits ? `${type} ${digits}` : null;

  return description ?? formatMessage(messages.paymentMethod);
};

export const getReadableCardType = (
  formatMessage: ReturnType<typeof useIntl>['formatMessage'],
  type?: PaymentMethodType,
) => {
  switch (type) {
    case PaymentMethodType.MasterCard: {
      return formatMessage(messages.mastercard);
    }

    case PaymentMethodType.Visa: {
      return formatMessage(messages.visa);
    }

    case PaymentMethodType.AmericanExpress: {
      return formatMessage(messages.amex);
    }

    case PaymentMethodType.Discover: {
      return formatMessage(messages.discover);
    }

    default: {
      return formatMessage(messages.unknown);
    }
  }
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  payWith: {
    defaultMessage: 'Pay with ',
    description: 'Bag | Payment method | Hint',
  },
  applePay: {
    defaultMessage: 'Apple Pay',
    description: 'Bag | Selected Payment Method | Apple Pay',
  },
  googlePay: {
    defaultMessage: 'Google Pay',
    description: 'Bag | Selected Payment Method | Google Pay',
  },
  paymentMethod: {
    defaultMessage: 'Card',
    description: 'Bag | Selected Payment Method | Card',
  },
  addNewCard: {
    defaultMessage: 'Add new credit card',
    description: 'Bag | Selected Payment Method | Google Pay',
  },
  linkA11y: {
    defaultMessage: 'Select different payment method',
    description: 'Bag | Selected Payment Method | Select another',
  },
  mastercard: {
    defaultMessage: 'MC',
    description: 'Bag | Checkout CTA | Mastercard',
  },
  visa: {
    defaultMessage: 'Visa',
    description: 'Bag | Checkout CTA | Visa',
  },
  amex: {
    defaultMessage: 'Amex',
    description: 'Bag | Checkout CTA | Amex',
  },
  discover: {
    defaultMessage: 'Disc',
    description: 'Bag | Checkout CTA | Discover',
  },
  unknown: {
    defaultMessage: 'Card',
    description: 'Bag | Checkout CTA | Unknown card',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type PaymentInfoParams = {
  paymentMethod?: PaymentMethod;
  paymentMethods: readonly PaymentMethod[];
  isApplePay: boolean;
  isGooglePay: boolean;
  formatMessage: ReturnType<typeof useIntl>['formatMessage'];
};

type PaymentInfo = {
  icon: IconName;
  name: string;
};

// ─── Constants ──────────────────────────────────────────────────────────────

export const APPLE_PAY = 'APPLE_PAY';
export const GOOGLE_PAY = 'GOOGLE_PAY';

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

// ────────────────────────────────────────────────────────────────────────────────

export const Group = (props: GroupProps) => (
  <View style={styles.wrapper}>{props.children}</View>
);

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type GroupProps = Readonly<{
  children?: React.ReactNode;
}>;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    paddingBottom: theme.spacing['5'],
  },
});

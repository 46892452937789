import React, { useEffect } from 'react';
import type { ViewProps } from 'react-native';
import { Platform, StyleSheet, View } from 'react-native';
import type {
  HeaderBackButtonProps,
  HeaderButtonProps,
} from '@react-navigation/elements';
import { IconLink, theme, useResponsive } from '@sg/garnish';

import { useCustomer } from '@order/Customer';
import { useLastInteractedStore } from '@order/LastInteractedStore';
import { useLocalizationContext } from '@order/Localization';

import { usePickupOutpostRestaurantNameQuery } from './GraphQL/RestaurantName.generated';
import type { HeaderCloseIconItemProps, HeaderIconItems } from './Header.props';

// ———————— HEADER LEFT ————————

type HeaderLeftScreenOption = ((
  props: HeaderBackButtonProps,
) => React.ReactNode) &
  ((props: HeaderBackButtonAppearanceProps) => React.ReactNode);

type HeaderBackButtonAppearanceProps = HeaderButtonAppearanceProps &
  Readonly<{
    labelVisible?: boolean;
  }>;

/**
 * Returns a function, populated with the specified items,
 * which can be passed as the `headerLeft` option
 * of a native stack navigator.
 */
export const useHeaderLeft = (
  props: HeaderLeftProps,
): HeaderLeftScreenOption => {
  return React.useCallback(
    (headerProps) => <HeaderLeft {...headerProps} {...props} />,
    [props],
  );
};

type HeaderLeftProps = Readonly<{
  items?: HeaderIconItems;
}> &
  Pick<ViewProps, 'testID'>;

const HeaderLeft = ({
  testID,
  items = [],
  tintColor,
}: HeaderLeftProps & HeaderButtonProps) => {
  const shouldRenderHeader = items.length > 0;

  if (!shouldRenderHeader) return null;

  return (
    <View testID={testID} style={styles.headerLeft}>
      {items.map(({ key, ref, icon, ...props }) => (
        <IconLink
          key={key}
          // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown> | undefined' is not as... Remove this comment to see the full error message
          ref={ref}
          name={icon}
          style={styles.headerLeftItem}
          color={tintColor}
          {...props}
        />
      ))}
    </View>
  );
};

// ———————— HEADER RIGHT ————————

type HeaderRightScreenOption = ((props: HeaderButtonProps) => React.ReactNode) &
  ((props: HeaderButtonAppearanceProps) => React.ReactNode);

type HeaderButtonAppearanceProps = Readonly<{
  tintColor?: string;
  pressColor?: string;
  pressOpacity?: number;
}>;

/**
 * Returns a function, populated with the specified items,
 * which can be passed as the `headerRight` option
 * of a native stack navigator.
 */
export const useHeaderRight = (
  props: HeaderRightProps,
): HeaderRightScreenOption => {
  return React.useCallback(
    (headerProps) => <HeaderRight {...headerProps} {...props} />,
    [props],
  );
};

type HeaderRightProps = Readonly<{
  items?: HeaderIconItems;
  close?: HeaderCloseIconItemProps;
}> &
  Pick<ViewProps, 'testID'>;

const HeaderRight = ({
  items = [],
  close,
  testID,
  tintColor,
}: HeaderRightProps & HeaderButtonAppearanceProps) => {
  const { currentBreakpoint } = useResponsive();

  const shouldRenderCloseBtn = currentBreakpoint.isXS && close !== undefined;
  const shouldRenderHeader = shouldRenderCloseBtn || items.length > 0;

  if (!shouldRenderHeader) return null;

  return (
    <View testID={testID} style={styles.headerRight}>
      {shouldRenderCloseBtn ? (
        <IconLink
          testID={close.testID ?? testID?.concat('.close')}
          name="IconClose"
          style={styles.headerRightItem}
          accessibilityRole="button"
          accessibilityLabel={close.accessibilityLabel}
          accessibilityHint={close.accessibilityHint}
          onPress={close.onPress}
          color={tintColor}
        />
      ) : null}

      {items.map(({ key, ref, icon, ...props }) => (
        <IconLink
          key={key}
          // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown> | undefined' is not as... Remove this comment to see the full error message
          ref={ref}
          name={icon}
          style={styles.headerRightItem}
          accessibilityRole="button"
          color={tintColor}
          {...props}
        />
      ))}
    </View>
  );
};

// ———————— STYLES ————————

const headerSidePadding = Platform.OS === 'web' ? theme.spacing['4'] : 0;

const styles = StyleSheet.create({
  headerLeft: {
    flexDirection: 'row',
    paddingLeft: headerSidePadding,
  },
  headerRight: {
    flexDirection: 'row-reverse',
    paddingRight: headerSidePadding,
  },
  headerLeftItem: {
    marginRight: theme.spacing['6'],

    // NOTE: We use custom margins based on the platform to match the default
    //       button placement.
    marginLeft: Platform.select({
      ios: -theme.spacing['1'],
      default: 0,
    }),
  },
  headerRightItem: {
    marginLeft: theme.spacing['6'],

    // NOTE: We use custom margins based on the platform to match the default
    //       button placement.
    marginRight: Platform.select({
      ios: -theme.spacing['1'],
      default: 0,
    }),
  },
});

/**
 * Returns the name of the `Menu` tab with optional content:
 *
 * Optional content will be displayed, if there is a restaurant/store that has been looked at before:
 *
 * - Pickup and outpost: add the restaurant/store name.
 * - Delivery: add the customer's delivery address.
 *
 * The optional content will only be shown on large screens (breakpoints of "desktop" and larger)..
 */
export const useMenuTabName = () => {
  const { t } = useLocalizationContext();
  const { minWidth } = useResponsive();
  const { customer } = useCustomer();

  const lastInteractedStore = useLastInteractedStore();
  const { restaurantSlug = '', addressId } = lastInteractedStore ?? {};

  const [{ data: pickupOutpostData }, fetchPickupOutpostRestaurantName] =
    usePickupOutpostRestaurantNameQuery({
      variables: { id: restaurantSlug },
      pause: true,
    });

  const restaurantName = restaurantSlug
    ? pickupOutpostData?.restaurant?.name
    : '';
  const customerSelectedAddress = customer.addresses?.find(
    ({ id }) => id === addressId,
  );
  const deliveryAddress = addressId ? customerSelectedAddress?.name : '';

  const name = restaurantName ? restaurantName : deliveryAddress;
  const shouldUseName = Boolean(minWidth.isMD && name);

  // ─── Effects ─────────────────────────────────────────────────────────

  // NOTE: We use this effect with the 'pause: true' condition to fetch the active
  //       restaurant name because relying only on the urql query hook can
  //       result in an infinite loop (only in the local environment).
  //       This is due to an internal double `useEffect` that happens inside
  //       urql, which began with React 18 (More here: https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar).
  //
  // NOTE: It is not an internal urql issue, but rather a larger problem with
  //       the `Header` component that rerenders "too much" causing mentioned
  //       double effect inside urql.
  //
  //       This is a temporary workaround to avoid those loops during local
  //       development.
  useEffect(() => {
    if (!restaurantSlug) return;

    fetchPickupOutpostRestaurantName();
  }, [fetchPickupOutpostRestaurantName, restaurantSlug]);

  // ─────────────────────────────────────────────────────────────────

  if (shouldUseName) return t('nav-bar.menu.title-named', { name });

  // ─────────────────────────────────────────────────────────────────────

  return t('nav-bar.menu.title');
};

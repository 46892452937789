import { type RefObject, useEffect } from 'react';
import type MapView from 'react-native-maps';

import { DEFAULT_EDGE_PADDING } from '../../constants';
import { getPinsBounds } from '../../helpers';
import type { GoogleMapRef, MapProps } from '../../types';
import { log, withSinglePinBoundsOffsetsCoordinates } from '../../utils';
import { useNotMinimizedPinsRef } from '../useNotMinimizedPinsRef';

// ─────────────────────────────────────────────────────────────────────────────

export const useMapAutoBounds = (props: UseMapOffsetProps) => {
  const { isEnabled, mapRef, edgePadding, region, pins } = props;

  const pinsRef = useNotMinimizedPinsRef(pins);

  // ─────────────────────────────────────────────────────────────────────

  const {
    top: topEdgePadding = DEFAULT_EDGE_PADDING,
    right: rightEdgePadding = DEFAULT_EDGE_PADDING,
    bottom: bottomEdgePadding = DEFAULT_EDGE_PADDING,
    left: leftEdgePadding = DEFAULT_EDGE_PADDING,
  } = edgePadding ?? {};

  // ─── Effects ─────────────────────────────────────────────────────────

  // fit all the pins on the active region change
  useEffect(() => {
    if (!isEnabled) return;

    if (!mapRef) {
      log.error('`map` object was not found!');

      return;
    }

    const bounds = getPinsBounds(
      withSinglePinBoundsOffsetsCoordinates(pinsRef.current),
    );

    if (!bounds || bounds?.isEmpty?.()) return;

    // NOTE: We use try...catch here, because the `fitBounds` method may throw
    //       an error if it receives unknown input.
    try {
      mapRef?.fitBounds?.(bounds, {
        top: topEdgePadding,
        right: rightEdgePadding,
        bottom: bottomEdgePadding,
        left: leftEdgePadding,
      });
    } catch {}
  }, [
    bottomEdgePadding,
    isEnabled,
    leftEdgePadding,
    mapRef,
    pinsRef,
    region,
    rightEdgePadding,
    topEdgePadding,
  ]);
};

// ─── Types ───────────────────────────────────────────────────────────────────

export type UseMapOffsetProps = Readonly<{
  isEnabled: boolean;
  mapRef?: GoogleMapRef['map_'];
  nativeMapRef?: RefObject<MapView>;
}> &
  Pick<MapProps, 'region' | 'edgePadding' | 'pins'>;

import { defineMessages } from 'react-intl';

export const orderAgainConflictReviewTextMessages = defineMessages({
  unfortunately: {
    defaultMessage: 'Unfortunately,',
    description:
      'Order again | Conflict review | Conflict review text | "Unfortunately"',
  },
  is: {
    defaultMessage: 'is',
    description: 'Order again | Conflict review | Conflict review text | "is"',
  },
  are: {
    defaultMessage: 'are',
    description: 'Order again | Conflict review | Conflict review text | "are"',
  },
  and: {
    defaultMessage: 'and',
    description: 'Order again | Conflict review | Conflict review text | "and"',
  },
  currentlyUnavailable: {
    defaultMessage: 'currently unavailable',
    description:
      'Order again | Conflict review | Conflict review text | "currently unavailable"',
  },
  atThe: {
    defaultMessage: 'at the',
    description:
      'Order again | Conflict review | Conflict review text | "at the"',
  },
  store: {
    defaultMessage: 'store.',
    description:
      'Order again | Conflict review | Conflict review text | "store"',
  },
  forDeliveryAddress: {
    defaultMessage: 'for your delivery address.',
    description:
      'Order again | Conflict review | Conflict review text | "for your delivery address"',
  },
});

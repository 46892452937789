/**
 * A simple util that determines whether the provided order status corresponds
 * to the pending order state.
 */
export function checkIfPendingOrder(flattenedOrderStatus: string): boolean {
  return PENDING_ORDER_STATUSES.has(flattenedOrderStatus);
}

// ─── Constants ───────────────────────────────────────────────────────────────

const PENDING_ORDER_STATUSES = new Set([
  'received',
  'preparing',
  'readyForCourier',
  'delivering',
]);

import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Button,
  ButtonGroup,
  FONTS,
  Form,
  HStack,
  Modal,
  TextField,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type {
  AddressField,
  AddressTypeProps,
  DeliveryNoteProps,
  FooterProps,
  FormFieldProps,
} from '../AddressForm.types';
import { useAddressTypes, useDeliveryPreferences } from '../hooks';

export const DeliveryNotes = (props: DeliveryNoteProps) => {
  const { form, notes, maxLength } = props;

  const { t } = useLocalizationContext();

  return (
    <View style={styles.deliveryNotes}>
      <View style={styles.header}>
        <BodyText style={styles.text}>
          {t('addresses.placeholder.delivery-note-label')}
        </BodyText>

        <BodyText
          style={[
            notes?.length === maxLength && styles.cautionText,
            styles.text,
            styles.smallText,
          ]}
        >
          {notes?.length}/{maxLength}
        </BodyText>
      </View>

      <Form.TextField
        name="notes"
        control={form.control}
        testID="account.address-form.delivery-notes"
        style={styles.inputField}
        maxLength={maxLength}
        placeholder={t('addresses.placeholder.delivery-note')}
        accessibilityLabel={t('addresses.delivery-notes.a11y', { maxLength })}
        multiline
        blurOnSubmit
        returnKeyType="done"
      />
    </View>
  );
};

export const DeliveryPreference = ({ form, updateField }: FormFieldProps) => {
  const { t } = useLocalizationContext();
  const { currentBreakpoint, match } = useResponsive();
  const itemsHorizontalPadding = match(['3', '4'] as const);

  const deliveryPreferences = useDeliveryPreferences();

  return (
    <View style={currentBreakpoint.isXS ? styles.mobileSections : undefined}>
      <BodyText style={styles.text}>{t('addresses.drop-off')}</BodyText>

      <ButtonGroup
        fluid
        value={form.deliveryPreference}
        items={deliveryPreferences}
        itemsHorizontalPadding={itemsHorizontalPadding}
        onChange={updateField('deliveryPreference')}
      />
    </View>
  );
};

export const AddressType = (props: AddressTypeProps) => {
  const {
    addressType,
    hasHomeAddress,
    hasWorkAddress,
    updateField,
    setAddressType,
  } = props;

  const handleOnChange = useCallback(
    (type: string) => {
      const validType = ['home', 'work'].includes(type) ? type : '';

      updateField('name')(validType);
      setAddressType(type);
    },
    [setAddressType, updateField],
  );

  const addressTypes = useAddressTypes({
    addressType,
    hasHomeAddress,
    hasWorkAddress,
  });

  return (
    <ButtonGroup
      fluid
      value={addressType}
      items={addressTypes}
      onChange={handleOnChange}
    />
  );
};

export const CustomNameField = (props: CustomNameFieldProps) => {
  const { name, customNameNotice, isCustomNameInvalid, updateField } = props;

  const { t } = useLocalizationContext();

  return (
    <View style={styles.customName}>
      <TextField
        testID="account.address-form.custom-name"
        accessibilityLabel={t('addresses.custom-name')}
        accessibilityHint={t('addresses.custom-name')}
        label={t('addresses.custom-name')}
        maxLength={20}
        value={name}
        invalid={isCustomNameInvalid}
        notice={customNameNotice}
        onChangeText={updateField('name')}
      />
    </View>
  );
};

export const Footer = (props: FooterProps) => {
  const {
    loading,
    editing,
    deleting,
    canSubmit,
    submitCta,
    submitForm,
    onDelete,
  } = props;

  const { t } = useLocalizationContext();
  const canDelete = Boolean(editing && onDelete);

  return (
    <Modal.Footer style={styles.footer} withoutTopBorder>
      <HStack itemsPerRow={canDelete ? 2 : 1} gap={theme.spacing['4']}>
        {canDelete ? (
          <Button
            size="large"
            palette="caution"
            isLoading={deleting}
            onPress={onDelete}
            accessibilityLabel={t('addresses.remove-address.a11y')}
            accessibilityHint={t('addresses.remove-address.a11y')}
            accessibilityRole="button"
          >
            {t('addresses.remove-address.label')}
          </Button>
        ) : null}

        <Button
          size="large"
          isLoading={loading}
          onPress={submitForm}
          disabled={!canSubmit}
          accessibilityLabel={t('addresses.save.a11y')}
          accessibilityHint={t('addresses.save.a11y')}
          accessibilityRole="button"
        >
          {submitCta ?? t('addresses.save')}
        </Button>
      </HStack>
    </Modal.Footer>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomNameFieldProps = Readonly<{
  name?: string;
  customNameNotice?: string;
  isCustomNameInvalid?: boolean;
  updateField: (key: AddressField) => (value: string) => void;
}>;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing['1'],
  },
  inputField: {
    height: 80,
  },
  text: {
    fontSize: 12,
    lineHeight: theme.spacing['4'],
    paddingBottom: theme.spacing['2'],
    fontFamily: FONTS.SWEET_SANS_TEXT,
  },
  deliveryNotes: {
    paddingTop: theme.spacing['8'],
  },
  customName: {
    paddingTop: theme.spacing['4'],
  },
  mobileSections: {
    paddingTop: theme.spacing['4'],
  },
  smallText: {
    fontSize: 10,
  },
  cautionText: {
    color: theme.colors.CAUTION,
  },
  footer: {
    flexDirection: 'row',
    padding: theme.spacing['6'],
    paddingTop: theme.spacing['6'],
  },
});

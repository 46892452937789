import type { ComponentProps } from 'react';
import React from 'react';
import type { ViewProps } from 'react-native';
import { Pressable, StyleSheet, View } from 'react-native';
import {
  BodyText,
  Icon,
  theme,
  usePressableState,
  useResponsive,
} from '@sg/garnish';

export const AccountMenuPrimaryItem = ({
  testID,
  label,
  iconName,
  active,
  disabled,
  children,
  onPress,
}: AccountMenuPrimaryItemProps) => {
  const ref = React.useRef(null);
  const { match } = useResponsive();
  const { isInteracting } = usePressableState(ref);

  const pressableStyles = [
    styles.pressable,
    disabled ? styles.pressableDisabled : undefined,
  ];

  return (
    <Pressable
      testID={testID}
      ref={ref}
      onPress={onPress}
      style={pressableStyles}
      accessibilityRole="link"
      accessibilityLabel={label}
      accessibilityState={{ disabled }}
      disabled={disabled}
    >
      <View style={styles.iconWrapper}>
        <Icon name={iconName} />
      </View>

      <BodyText size={match([2, 3])} underline={isInteracting || active}>
        {label}
      </BodyText>

      {children}
    </Pressable>
  );
};

type AccountMenuPrimaryItemProps = Readonly<{
  label: string;
  iconName: ComponentProps<typeof Icon>['name'];
  onPress: () => void;
  active?: boolean;
  disabled?: boolean;
  testID?: string;
  children?: ViewProps['children'];
}>;

const styles = StyleSheet.create({
  pressable: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.small,
    paddingVertical: theme.spacing['2'],
    marginVertical: theme.spacing['2'],
  },
  pressableDisabled: {
    opacity: 0.4,
  },
  iconWrapper: {
    paddingRight: theme.spacing['4'],
  },
});

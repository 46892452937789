import React, { forwardRef, useCallback } from 'react';
import { type TextInput } from 'react-native';

import { getDecimalsPlacesLength } from '../../utils';
import { TextField } from '../TextField';
import type { TextFieldProps } from '../TextField/TextField.types';

// ─────────────────────────────────────────────────────────────────────────────

export const NumberField = forwardRef<TextInput, NumberFieldProps>(
  (props, ref) => {
    const {
      keyboardType = 'numeric',
      value,
      defaultValue,
      min = Number.NEGATIVE_INFINITY,
      max = Number.POSITIVE_INFINITY,
      maxDecimals = Number.POSITIVE_INFINITY,
      ...restProps
    } = props;

    // ────────────────────────────────────────────────────────────────────────────────

    const validateTextAsNumber = useCallback(
      (numericValue: string) => {
        const textAsNumber = Number(numericValue);
        const containsOnlyNumericChars = Number.isFinite(textAsNumber);
        const hasWhitespace = /\s/g.test(numericValue);
        const startsWithNegate =
          numericValue.length === 1 && numericValue.startsWith('-');
        const decimalsNumber = getDecimalsPlacesLength(numericValue);
        const hasAcceptableDecimalsNumber = decimalsNumber <= maxDecimals;
        const isLargerOrEqualToMin = textAsNumber >= min;
        const isSmallerOrEqualToMax = textAsNumber <= max;

        return (
          !hasWhitespace &&
          (startsWithNegate || containsOnlyNumericChars) &&
          hasAcceptableDecimalsNumber &&
          isLargerOrEqualToMin &&
          isSmallerOrEqualToMax
        );
      },
      [max, maxDecimals, min],
    );

    // ────────────────────────────────────────────────────────────────────────────────

    const convertedValue = value === undefined ? undefined : String(value);
    const convertedDefaultValue =
      defaultValue === undefined ? undefined : String(defaultValue);

    return (
      <TextField
        ref={ref}
        keyboardType={keyboardType}
        value={convertedValue}
        defaultValue={convertedDefaultValue}
        onChangeTextGuard={validateTextAsNumber}
        {...restProps}
      />
    );
  },
);

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type NumberFieldProps = Omit<
  TextFieldProps,
  'keyboardType' | 'value' | 'defaultValue' | 'onChangeTextGuard'
> &
  Readonly<{
    keyboardType?: 'number-pad' | 'decimal-pad' | 'numeric';
    value?: number;
    defaultValue?: number;
    min?: number;
    max?: number;
    maxDecimals?: number;
  }>;

import React, { type ComponentProps } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { BodyText } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationSelectedIngredientsHeading = (
  props: CustomizationSelectedIngredientsHeadingProps,
) => {
  const { productName, ...restProps } = props;

  return (
    <BodyText sizeMatch={['24']} {...restProps}>
      <FormattedMessage
        {...messages.heading}
        values={{ product_name: productName }}
      />
    </BodyText>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  heading: {
    defaultMessage: '{product_name} with...',
    description: 'Customization | Selected ingredients | Heading',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationSelectedIngredientsHeadingProps = {
  productName: string;
} & Omit<ComponentProps<typeof BodyText>, 'children'>;

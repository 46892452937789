import type { ModalBaseProps } from 'react-native';

/**
 * Fallback for non-iOS platforms.
 */
export function getIosModalPresentationConfig(
  overrides: ModalBaseProps = {},
): ModalBaseProps {
  return {
    animationType: 'fade',
    transparent: false,
    ...overrides,
  };
}

import React, { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, IngredientCardV2, Radio, theme } from '@sg/garnish';
import { type IngredientModification } from '@sg/graphql-schema';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationDetailsDressingPortion = (
  props: CustomizationDetailsDressingPortionProps,
) => {
  const {
    ingredientModification,
    activeDressingsDetails,
    maxPortions,
    remainingPortions,
    setDressingPortion,
    isDisabled,
  } = props;

  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const { id, asset, name } = ingredientModification.ingredient;

  const selectedPortion = useMemo(() => {
    const maybeActiveDressingPortion = activeDressingsDetails.find(
      (dressing) => dressing.id === id,
    )?.portionsNumber;

    return maybeActiveDressingPortion ?? 1;
  }, [activeDressingsDetails, id]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onPortionChange = useCallback(
    (portionAsString: string) => {
      setDressingPortion(ingredientModification, Number(portionAsString));
    },
    [ingredientModification, setDressingPortion],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View>
      <View style={styles.header}>
        <View style={styles.imgContainer}>
          <IngredientCardV2.Image
            source={asset.url}
            imageWidth={64}
            accessibilityLabel={name}
          />
        </View>

        <BodyText sizeMatch={['24']} style={styles.heading}>
          {name}
        </BodyText>
      </View>

      <Radio.Group itemsPerRow={3}>
        {Array.from({ length: maxPortions }).map((_value, index) => {
          const portion = index + 1;
          const moreThanRemaining =
            portion > selectedPortion + remainingPortions;

          return (
            <Radio.Item
              key={`dressing-portion-${portion}`}
              value={`${portion}`}
              isSelected={selectedPortion === portion}
              isDisabled={isDisabled || moreThanRemaining}
              onPress={onPortionChange}
              label={
                portion === 1
                  ? formatMessage(messages.labelSingular, { portion })
                  : formatMessage(messages.labelPlural, { portion })
              }
            />
          );
        })}
      </Radio.Group>
    </View>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  labelSingular: {
    defaultMessage: '{portion} portion',
    description:
      'Customization | Dressing details | Dressing portion label | Singular',
  },
  labelPlural: {
    defaultMessage: '{portion} portions',
    description:
      'Customization | Dressing details | Dressing portion label | Plural',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing['2'],
    marginBottom: theme.spacing['2'],
  },
  imgContainer: {
    width: 64,
  },
  heading: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationDetailsDressingPortionProps = {
  ingredientModification: IngredientModification;
  activeDressingsDetails: readonly {
    id: string;
    portionsNumber: number;
  }[];
  maxPortions: number;
  remainingPortions: number;
  setDressingPortion: (
    ingredientModification: IngredientModification,
    dressingPortion: number,
  ) => void;
  isDisabled?: boolean;
};

/* eslint-disable react-native/no-unused-styles */

import React from 'react';
import { StyleSheet, type TextStyle } from 'react-native';
import { theme } from '@garnish/constants';

import { DisplayText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanCardTitle = (props: ScanCardBaseProps) => {
  const { children, palette = 'darkKale' } = props;

  const titleStyles = [styles.title, paletteStyles[palette]];

  return (
    <DisplayText sizeMatch={['32']} style={titleStyles}>
      {children}
    </DisplayText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing['10'],
  },
});

const paletteStyles = StyleSheet.create<
  Record<ScanCardTitlePalette, TextStyle>
>({
  oatmeal: {
    color: theme.colors.OATMEAL,
  },
  darkKale: {
    color: theme.colors.DARK_KALE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanCardBaseProps = Readonly<{
  children: string | React.ReactNode;
  palette?: ScanCardTitlePalette;
}>;

type ScanCardTitlePalette = 'oatmeal' | 'darkKale';

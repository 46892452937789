import { useIsFocused } from '@react-navigation/native';

import { useInStoreGiftCardsQuery } from '../../graphql/InStoreGiftCards.generated';

/**
 * Returns the in-store gift cards for the customer.
 * Also checks if the customer has any gift card balance available.
 */
export function useScanInStoreGiftCards() {
  const isFocused = useIsFocused();
  const pause = !isFocused;
  const requestPolicy = 'cache-and-network';

  const [response] = useInStoreGiftCardsQuery({ pause, requestPolicy });
  const isLoadingGiftCards = response.fetching;
  const hasGiftCardsError = Boolean(response.error);

  const giftCards = response.data?.inStorePaymentMethods ?? [];
  const amounts = giftCards.map(({ remainingAmount }) => remainingAmount);
  const hasGiftCardBalance = amounts.reduce(sumGiftCardBalance, 0) > 0;

  return {
    isLoadingGiftCards,
    hasGiftCardsError,
    giftCards,
    hasGiftCardBalance,
  };
}

/**
 * Used to verify if the customer has any gift card balance available.
 */
function sumGiftCardBalance(sum: number, remainingAmount: number) {
  return sum + remainingAmount;
}

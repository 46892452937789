import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import { useResponsive, useToggleState } from '@sg/garnish';

import { type PaymentMethodCard } from '@order/graphql';
import { type usePaymentMethods } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import {
  AccountScreenBorderedSection,
  AccountScreenSection,
  AccountScreenSectionTitle,
} from '../Account';
import { CreditCardAddModal } from '../CreditCardAddModal';
import { CreditCardEditModal } from '../CreditCardEditModal';
import { PaymentMethodList } from '../PaymentMethod';

// ─────────────────────────────────────────────────────────────────────────────

export const PaymentAccountSection = (props: PaymentAccountSectionProps) => {
  const { paymentMethods } = props;

  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  // ─── State ───────────────────────────────────────────────────────────

  const [selectedPaymentMethodId, selectPaymentMethodId] = useState<string>();

  // ─── Toggles ─────────────────────────────────────────────────────────

  const {
    value: isShowingAddPaymentMethodModal,
    toggleOn: showAddPaymentMethodModal,
    toggleOff: hideAddPaymentMethodModal,
  } = useToggleState();

  const showEditPaymentMethodModal = useCallback(
    (paymentMethod: PaymentMethodCard) => {
      selectPaymentMethodId(paymentMethod.id);
    },
    [],
  );

  const hideEditPaymentMethodModal = useCallback(() => {
    selectPaymentMethodId('');
  }, []);

  // ─────────────────────────────────────────────────────────────────────

  const sectionContainerStyles = match([styles.sectionContainerXS, undefined]);

  return (
    <>
      <AccountScreenSectionTitle title={t('account.payment.title')} />
      <AccountScreenSection style={sectionContainerStyles}>
        <AccountScreenBorderedSection>
          <PaymentMethodList
            items={paymentMethods}
            onSelectAdd={showAddPaymentMethodModal}
            onSelectItem={showEditPaymentMethodModal}
          />
        </AccountScreenBorderedSection>
      </AccountScreenSection>

      <CreditCardAddModal
        visible={isShowingAddPaymentMethodModal}
        onRequestClose={hideAddPaymentMethodModal}
      />

      {selectedPaymentMethodId ? (
        <CreditCardEditModal
          paymentMethodId={selectedPaymentMethodId}
          visible={Boolean(selectedPaymentMethodId)}
          onRequestClose={hideEditPaymentMethodModal}
        />
      ) : null}
    </>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  sectionContainerXS: {
    flexGrow: 0,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type PaymentAccountSectionProps = Readonly<{
  paymentMethods: ReturnType<typeof usePaymentMethods>['paymentMethods'];
}>;

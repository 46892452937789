import React from 'react';
import { StyleSheet, View } from 'react-native';
import { COLORS, theme } from '@garnish/constants';

import { webOnlyStyles } from '../../../utils';
import type { ChipBoxProps } from '../Chip.types';
import { boxStyles } from '../styles';

// ─────────────────────────────────────────────────────────────────────────────

export const ChipBox = (props: ChipBoxProps) => {
  const { focused, state, children, style, palette } = props;

  const dynamicStyles = boxStyles[palette][state];

  const containerStyles = [
    styles.box,
    dynamicStyles,
    chipBoxWebStyles,
    focused ? chipBoxFocusWebStyles : undefined,
    style,
  ];

  return <View style={containerStyles}>{children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  box: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: theme.radius.xlarge,
    borderColor: COLORS.NEUTRAL_3,
    paddingVertical: theme.spacing['2'],
    paddingHorizontal: theme.spacing['4'],
  },
});

const chipBoxWebStyles = webOnlyStyles({
  outlineWidth: 1,
  outlineOffset: theme.spacing['1'],
  outlineColor: theme.colors.GRAY,
  transition: `background-color ${theme.transitions.base}ms, border-color ${theme.transitions.base}ms`,
});

const chipBoxFocusWebStyles = webOnlyStyles({
  outlineStyle: 'solid',
});

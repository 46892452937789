/* eslint-disable unicorn/numeric-separators-style */
import type { TelemetryContext } from './TelemetryContext';
import type { TelemetryEvent, TelemetryEventName } from './TelemetryEvent';

export type TelemetryEventOverride<Name extends TelemetryEventName, Event> = (
  props: TelemetryEventOverrideProps<Name>,
) => TelemetryEventOverrideResult<Event> | undefined;

export type TelemetryEventOverrideProps<Name extends TelemetryEventName> =
  Readonly<{
    context: TelemetryContext;
    event: TelemetryEvent<Name>;
  }>;

export type TelemetryEventOverrideResult<Event> = Readonly<{
  pre?: () => void;
  event?: Event;
  post?: () => void;
}>;

export const shouldHandleAppSessionEvent = (
  event: TelemetryEvent<'app.session'>,
) => {
  const { lastSession: lastSessionString } = event.payload;

  if (!lastSessionString) {
    return true;
  }

  const lastSessionDate = new Date(lastSessionString);

  const secondsSinceLastSession = Date.now() - lastSessionDate.getTime();

  if (Number.isNaN(secondsSinceLastSession)) {
    return false;
  }

  // Only trigger session event at most once per hour.
  return secondsSinceLastSession > 3600000;
};

export const bag = {
  'bag-title': `{orderType, select,
    pickup {Review your pickup order}
    outpost {Review your Outpost order}
    delivery {Review your delivery order}
    other {}
  }`,
  'bag.close': 'Close Bag',
  'bag.empty': 'Fill up your bag with the good stuff',
  'bag.view-menu': 'View menu',
  'bag.delivery-minimum': "You haven't met the {minimum} delivery minimum yet.",
  'bag.upsells.header': 'Great additions',

  // ─── Rewards Rail ────────────────────────────────────────────────────

  'bag.rewards.header': 'Your rewards',
  'bag.rewards.footer': 'Select a reward to be added at checkout',
  'bag.rewards.sweetpass-deadline-prefix': 'Use by',

  // ─── "Signed Out" view ───────────────────────

  'bag.rewards.signed-out.sign-in-label': 'Sign in',
  'bag.rewards.signed-out.sign-in-helper-text':
    ' to use your available rewards.',
  'bag.rewards.signed-out.sign-up-helper-text-start':
    ' Don’t have an account? ',
  'bag.rewards.signed-out.sign-up-label': 'Create one',
  'bag.rewards.signed-out.sign-up-helper-text-end':
    ' and learn more about Rewards + Challenges.',

  // ─── "Empty" view ────────────────────────────

  'bag.rewards.no-rewards.text': 'No rewards are available at the moment',

  // ─── "Error" view ────────────────────────────

  'bag.rewards.error.text': 'There was an issue with your rewards. ',
  'bag.rewards.error.helper-text-start': 'Please ',
  'bag.rewards.error.refresh-cta-label': 'refresh',
  'bag.rewards.error.helper-text-end': ' or try again later.',

  // ─────────────────────────────────────────────

  'bag.sweetpass.post-upgrade-banner.text':
    'Your Sweetpass+ membership payment was successful. Please don’t navigate away while we refresh your rewards.',

  'bag.lineItem.reward': 'Reward',
  'bag.lineItem.reward-calculated-at-checkout': 'Calculated at checkout',
  'bag.lineItem.dressing-on-the-side': 'Dressing on the side',
  'bag.lineItem.dressing-mixed-in': 'Dressing mixed in',
  'bag.lineItem.dressing-removed': 'No dressing',
  'bag.lineItem.with-bread': '+ Bread',
  'bag.lineItem.navigate-to': 'Navigate to "{lineItemName}" details page',
  'bag.rewards.disclaimer':
    '*Reward discounts and final tax will be applied at checkout',
  'bag.rewards.error.modal.headline': 'Oops, reward not applied',
  'bag.reorder.error-logged-out':
    'Please sign in to update your order location.',
  'bag.view-bag': 'View bag',
};

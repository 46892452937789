import type { TextStyle, ViewStyle } from 'react-native';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import type { PageHeroHeaderCtaPalette } from './PageHeroHeader.types';

// ─────────────────────────────────────────────────────────────────────────────

export const ctaPalettes: Record<
  PageHeroHeaderPalette,
  PageHeroHeaderCtaPalette
> = {
  kale: 'limeOutline',
  lime: 'primaryLime',
};

// ─── Styles ──────────────────────────────────────────────────────────────────

export const containerPaletteStyles =
  StyleSheet.create<PageHeroHeaderContainerPaletteStyle>({
    kale: {
      backgroundColor: theme.colors.KALE,
    },
    lime: {
      backgroundColor: theme.colors.LIME,
    },
  });

export const textPaletteStyles =
  StyleSheet.create<PageHeroHeaderTextPaletteStyle>({
    kale: {
      color: theme.colors.LIME,
    },
    lime: {
      color: theme.colors.KALE,
    },
  });

// ─── Types ───────────────────────────────────────────────────────────────────

type PageHeroHeaderPalette = 'kale' | 'lime';

type PageHeroHeaderContainerPaletteStyle = Record<
  PageHeroHeaderPalette,
  ViewStyle
>;

type PageHeroHeaderTextPaletteStyle = Record<PageHeroHeaderPalette, TextStyle>;

import React, { type ReactNode, useCallback } from 'react';
import { Modal, Platform, StyleSheet, View } from 'react-native';
import { FullWindowOverlay } from 'react-native-screens';
import { theme } from '@garnish/constants';
import {
  BodyText,
  Button,
  Illus404,
  IllusEmployeeBag,
  Image,
  LoadingPlaceholder,
  TitleText,
  useNoticeBannersStackContext,
} from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { useOtaUpdateDialogContent } from './hooks';
import { useOtaUpdateMachine } from './machine';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders a "smart" dialog component for displaying OTA update information.
 *
 * NOTE: It uses a guard feature flag.
 */
export const OtaUpdateDialog = () => {
  const isInAppOtaUpdateCheckerEnabled = useFeatureFlag(
    'OTA-update-in-app-checker-enabled',
    { listenForChanges: true },
  );

  if (!isInAppOtaUpdateCheckerEnabled) {
    return null;
  }

  return <OtaUpdateDialogContent />;
};

const OtaUpdateDialogContent = () => {
  const { availableUpdate, updateState, send } = useOtaUpdateMachine();

  const { hasPendingUpdate, isRestartingApp, hasFailedToRestartApp } =
    updateState;

  // ─────────────────────────────────────────────────────────────────────

  const isForceUpdateEnabled = useFeatureFlag(
    'OTA-update-is-force-update-enabled',
    { listenForChanges: true },
  );

  // ─────────────────────────────────────────────────────────────────────

  const shouldRenderDialogContent =
    hasPendingUpdate || isRestartingApp || hasFailedToRestartApp;

  if (!shouldRenderDialogContent) {
    return null;
  }

  return (
    <OtaUpdateDialogContainer>
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          {hasFailedToRestartApp ? (
            <OtaUpdateDialogContentFailedToApply />
          ) : (
            <OtaUpdateDialogContentHasUpdate
              availableUpdate={availableUpdate}
              isRestartingApp={isRestartingApp}
              isForceUpdateEnabled={isForceUpdateEnabled}
              send={send}
            />
          )}
        </View>
      </View>
    </OtaUpdateDialogContainer>
  );
};

// ─── Components ──────────────────────────────────────────────────────────────

/**
 * Renders a different wrapper component based on the platform.
 *
 * - If the platform is iOS, it renders the content wrapped inside a `FullWindowOverlay` component to ensure
 * that it will be rendered on top of all screens/modals.
 * - For other platforms, render the content wrapped inside a `Modal` component.
 */
const OtaUpdateDialogContainer = (props: OtaUpdateDialogContainerProps) => {
  const { children } = props;

  if (Platform.OS === 'ios') {
    return <FullWindowOverlay>{children}</FullWindowOverlay>;
  }

  return <Modal statusBarTranslucent>{children}</Modal>;
};

/**
 * Renders the dialog content when there is an OTA update available.
 */
const OtaUpdateDialogContentHasUpdate = (
  props: OtaUpdateDialogContentHasUpdateProps,
) => {
  const { availableUpdate, isForceUpdateEnabled, isRestartingApp, send } =
    props;

  const { t } = useLocalizationContext();
  const { track } = useTelemetry();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  // ─── Content ─────────────────────────────────────────────────────────

  const { data: content, isFetching: isFetchingContent } =
    useOtaUpdateDialogContent({
      targetVersion: availableUpdate?.targetVersion,
    });

  const updateId = availableUpdate?.id;
  const targetVersion = availableUpdate?.targetVersion;
  const title = content?.title || t('ota-update.title');
  const description = content?.description || t('ota-update.description');

  const restartAppBtnLabel = t('ota-update.restart-and-apply');
  const updateLaterCtaLabel = t('ota-update.update-later');
  const updateLaterMessage = t('ota-update.update-later-message');

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onRestartCtaBtnPress = useCallback(() => {
    track('ota_update.apply_update.pressed', { updateId, targetVersion });
    send('RESTART');
  }, [send, targetVersion, track, updateId]);

  const onUpdateLaterBtnPress = useCallback(() => {
    track('ota_update.update_later.pressed', { updateId, targetVersion });
    send('UPDATE_LATER');
    addNoticeBanner({ palette: 'neutral', text: updateLaterMessage });
  }, [
    track,
    updateId,
    targetVersion,
    send,
    addNoticeBanner,
    updateLaterMessage,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.hasUpdateContainer}>
      <View style={styles.illustrationContainer}>
        <Image
          source={ILLUSTRATION_HAS_UPDATE}
          style={styles.illustration}
          contentFit="contain"
        />
      </View>

      <View style={styles.header}>
        {isFetchingContent ? (
          <LoadingPlaceholder
            rows={2}
            rowHeight={32}
            gridGap={10}
            palette="cream"
          />
        ) : (
          <>
            <TitleText sizeMatch={['32']} style={styles.title}>
              {title}
            </TitleText>

            <BodyText sizeMatch={['16']} style={styles.description}>
              {description}
            </BodyText>
          </>
        )}
      </View>

      <View style={styles.buttonsContainer}>
        <Button
          size="large"
          palette="primary"
          isLoading={isRestartingApp}
          onPress={onRestartCtaBtnPress}
        >
          {restartAppBtnLabel}
        </Button>

        {isForceUpdateEnabled ? null : (
          <Button
            size="large"
            palette="secondary"
            disabled={isRestartingApp}
            onPress={onUpdateLaterBtnPress}
          >
            {updateLaterCtaLabel}
          </Button>
        )}
      </View>
    </View>
  );
};

/**
 * Renders the content for when an OTA update fails to apply.
 */
const OtaUpdateDialogContentFailedToApply = () => {
  const { t } = useLocalizationContext();

  return (
    <>
      <View style={styles.illustrationContainer}>
        <Image
          source={ILLUSTRATION_FAILED_TO_UPDATE}
          style={styles.illustration}
          contentFit="contain"
        />
      </View>

      <BodyText sizeMatch={['18']} style={styles.description}>
        {t('ota-update.restart-failed')}
      </BodyText>
    </>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const ILLUSTRATION_SIZE = 240;
const ILLUSTRATION_HAS_UPDATE = IllusEmployeeBag;
const ILLUSTRATION_FAILED_TO_UPDATE = Illus404;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  innerContainer: {
    ...StyleSheet.absoluteFillObject,
    zIndex: theme.zIndex.overModal,
    justifyContent: 'center',
    backgroundColor: theme.colors.APP_BACKGROUND,
    paddingBottom: theme.spacing['12'],
    paddingHorizontal: theme.spacing['6'],
    borderTopStartRadius: theme.radius.large,
    borderTopEndRadius: theme.radius.large,
  },
  hasUpdateContainer: {
    paddingTop: theme.spacing['10'],
  },
  illustrationContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing['4'],
  },
  illustration: {
    height: ILLUSTRATION_SIZE,
    width: ILLUSTRATION_SIZE,
  },
  header: {
    marginBottom: theme.spacing['8'],
  },
  title: {
    textAlign: 'center',
    marginVertical: theme.spacing['2'],
  },
  description: {
    textAlign: 'center',
    color: theme.colors.CHARCOAL,
  },
  buttonsContainer: {
    rowGap: theme.spacing['3'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OtaUpdateDialogContainerProps = {
  children: ReactNode;
};

type OtaUpdateDialogContentHasUpdateProps = {
  send: ReturnType<typeof useOtaUpdateMachine>['send'];
  isForceUpdateEnabled: boolean;
  availableUpdate?: ReturnType<typeof useOtaUpdateMachine>['availableUpdate'];
  isRestartingApp: boolean;
};

/* istanbul ignore file */

import type { Client } from 'urql';
import { fetchPredictions } from '@sg/garnish';

import { getEnvVars } from '@order/utils';

import type { ActiveCartDeliveryDetailsQuery } from '../../graphql/ActiveCartDeliveryDetails.generated';
import { ActiveCartDeliveryDetailsDocument } from '../../graphql/ActiveCartDeliveryDetails.generated';
import type {
  AddDeliveryAddressMutation,
  AddDeliveryAddressMutationVariables,
} from '../../graphql/AddDeliveryAddress.generated';
import { AddDeliveryAddressDocument } from '../../graphql/AddDeliveryAddress.generated';
import type { CustomerAddressesQuery } from '../../graphql/CustomerDeliveryDetails.generated';
import { CustomerAddressesDocument } from '../../graphql/CustomerDeliveryDetails.generated';
import {
  type DeliveryLocationByAddressQuery,
  type DeliveryLocationByAddressQueryVariables,
  DeliveryLocationByAddressWithDisclosureFieldsDocument,
  type DeliveryLocationByAddressWithDisclosureFieldsQuery,
} from '../../graphql/DeliveryLocationByAddress.generated';
import { DeliveryLocationByAddressDocument } from '../../graphql/DeliveryLocationByAddress.generated';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Queries delivery location details based on provided address identifiers
 */
export async function deliveryLocationByAddressQuery(
  client: Client,
  variables: DeliveryLocationByAddressQueryVariables,
) {
  return client
    .query<DeliveryLocationByAddressQuery>(
      DeliveryLocationByAddressDocument,
      variables,
      { requestPolicy: 'network-only' },
    )
    .toPromise();
}

/**
 * Queries delivery location details (+ extra disclosure fields) based on provided address identifiers
 */
export async function deliveryLocationByAddressWithDisclosureFieldsQuery(
  client: Client,
  variables: DeliveryLocationByAddressQueryVariables,
) {
  return client
    .query<DeliveryLocationByAddressWithDisclosureFieldsQuery>(
      DeliveryLocationByAddressWithDisclosureFieldsDocument,
      variables,
      { requestPolicy: 'network-only' },
    )
    .toPromise();
}

/**
 * Queries active cart delivery details
 */
export async function activeCartDeliveryDetailsQuery(client: Client) {
  return client
    .query<ActiveCartDeliveryDetailsQuery>(
      ActiveCartDeliveryDetailsDocument,
      {},
      { requestPolicy: 'cache-first' },
    )
    .toPromise();
}

/**
 * Adds new delivery address for the current customer
 */
export async function addDeliveryAddress(
  client: Client,
  variables: AddDeliveryAddressMutationVariables,
) {
  return client
    .mutation<AddDeliveryAddressMutation>(
      AddDeliveryAddressDocument,
      variables,
      { requestPolicy: 'cache-and-network' },
    )
    .toPromise();
}

/**
 * Queries current customer delivery addresses
 */
export async function customerAddressesQuery(client: Client) {
  return client
    .query<CustomerAddressesQuery>(
      CustomerAddressesDocument,
      {},
      { requestPolicy: 'cache-first' },
    )
    .toPromise();
}

/**
 * Queries delivery address predictions based on the provided search string
 */
export async function addressPredictionsQuery(searchString: string) {
  const { GOOGLE_API_KEY } = getEnvVars();

  return fetchPredictions({ input: searchString, apiKey: GOOGLE_API_KEY });
}

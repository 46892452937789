import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useRejoinSubscriptionMutation } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

export const useReactivateSweetpassMembership = (
  props: ReactivateSweetpassMembershipProps,
) => {
  const { subscriptionId, refetchMembership } = props;

  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  const [, rejoinSubscriptionMutation] = useRejoinSubscriptionMutation();

  const reactivateSubscription = useCallback(async () => {
    if (!subscriptionId) return;

    const { data: rejoinSubscriptionData } = await rejoinSubscriptionMutation({
      subscriptionId,
    });

    const hasFailedToReactivate =
      rejoinSubscriptionData?.rejoinSubscription?.__typename !==
      'RejoinSubscriptionSuccessResponse';

    const isCannotReactivate =
      rejoinSubscriptionData?.rejoinSubscription?.__typename ===
      'SubscriptionCannotBeReactivatedError';

    if (isCannotReactivate) {
      addNoticeBanner({
        text: t('sweetpass.subscription.cannot-reactivate'),
        palette: 'caution',
      });

      return;
    }

    if (hasFailedToReactivate) {
      addNoticeBanner({
        text: t('sweetpass-membership.membership-level.rejoin.error'),
        palette: 'caution',
      });

      return;
    }

    addNoticeBanner({
      text: t(
        'account.sweetpass-membership.membership-level.rejoin-sweetpass-plus.notice.success',
      ),
      palette: 'success',
    });

    refetchMembership();
  }, [
    subscriptionId,
    rejoinSubscriptionMutation,
    addNoticeBanner,
    refetchMembership,
    t,
  ]);

  return { reactivateSubscription };
};

type ReactivateSweetpassMembershipProps = Readonly<{
  subscriptionId?: string;
  refetchMembership: () => void;
}>;

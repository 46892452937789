import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { webOnlyStyles } from '../../../../utils';
import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const ActiveIngredientText = (props: ActiveIngredientTextProps) => {
  const { children, style, active, hovered, ...restProps } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const textStyles = [
    styles.text,
    textWebStyles,
    active || hovered ? styles.textHover : undefined,
    style,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <BodyText
      sizeMatch={['18']}
      style={textStyles}
      numberOfLines={1}
      {...restProps}
    >
      {children}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    color: theme.colors.KALE,
  },
  textHover: {
    color: theme.colors.CREAM,
  },
});

const textWebStyles = webOnlyStyles({
  transition: `color ${theme.transitions.base}ms`,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ActiveIngredientTextProps = {
  active?: boolean;
  hovered?: boolean;
} & ComponentProps<typeof BodyText>;

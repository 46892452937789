/**
 * A simple helper for parsing values with `JSON.parse`, so that values are
 * converted from strings to the relevant values:
 *
 * 'true' => true
 * 'false' => false
 * 'text-value' => 'text-value'
 * '["1", "2", "text-value]' => ["1", "2", "text-value]
 */
export function safelyParseFeatureFlagValue(
  value: string,
): FeatureFlagValues | undefined {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}

// ─── Types ───────────────────────────────────────────────────────────────────

type FeatureFlagValues =
  | boolean
  | number
  | string
  | readonly unknown[]
  | Record<string, unknown>;

/* istanbul ignore file */
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import type { CombinedError } from 'urql';

/**
 * A small helper that returns environment variables list based on app.config.js "extra" object
 *
 * @usage
 * ```js
 * const { MY_ENV_VARIABLE } = getEnvVars();
 *```
 *
 * @see {@link https://docs.expo.dev/guides/environment-variables/#reading-environment-variables Expo | Reading environment variables}
 */
export function getEnvVars() {
  return Constants.expoConfig?.extra ?? {};
}

export function getAppName() {
  return Constants.expoConfig?.name ?? 'order';
}

export function getCurrentUrl() {
  if (Platform.OS === 'web') return window.location.href;

  return '';
}

// Consider moving this logic to an environment variable instead.
export function getHostName() {
  if (Platform.OS === 'web') return new URL(window.location.href).host;

  return __DEV__ ? 'order-development.sweetgreen.com' : 'order.sweetgreen.com';
}

export function getBaseUrl() {
  if (Platform.OS === 'web') return new URL(window.location.href).origin;

  return __DEV__
    ? 'https://order-development.sweetgreen.com'
    : 'https://order.sweetgreen.com';
}

/**
 * Applies web only styles to fix full height issues on iOS.
 */
export function fixIosFullHeightIssue() {
  if (Platform.OS !== 'web') return;

  const style = document.createElement('style');

  // eslint-disable-next-line functional/immutable-data
  style.textContent = `
    body {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }`;

  document.head.append(style);
}

/**
 * Applies web-only CSS style to prevent layout shift when the keyboard is open (Web/Android).
 *
 * The fix is based on utilizing the viewport's `height` as the `body` element's
 * "min-height" value.
 *
 * We can't use 100vh because sometimes it returns value that is bigger than expected.
 * We do have new CSS viewport units that fix this problem, but at the time
 * this helper was written, browsers didn't support them very well.
 *
 * @see https://css-tricks.com/the-large-small-and-dynamic-viewports/
 * @see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
export function fixAndroidKeyboardLayoutShifts() {
  if (Platform.OS !== 'web') return;

  const isAndroidOS = navigator.userAgent.toLowerCase().includes('android');

  if (!isAndroidOS) return;

  // ─────────────────────────────────────────────────────────────────

  const viewportHeight = window.innerHeight;

  document.body.style.setProperty('--viewport-height', `${viewportHeight}px`);

  // ─────────────────────────────────────────────────────────────────

  const style = document.createElement('style');

  // eslint-disable-next-line functional/immutable-data
  style.textContent = `
    body {
      min-height: var(--viewport-height, 100%);
    }`;

  document.head.append(style);
}

/**
 * Merges the source and updated objects to produce a new object.
 * All properties that are not part of the original are ignored.
 */
export function mergeKnownProperties<
  SourceType = Readonly<Record<string, unknown>>,
  UpdatesType = Readonly<Record<string, unknown>>,
>(source: SourceType, updates: UpdatesType) {
  return Object.entries(updates).reduce((entries, entry) => {
    const [key, value] = entry;
    const validEntry = Object.prototype.hasOwnProperty.call(source, key);

    if (validEntry) return { ...entries, [key]: value };

    return entries;
  }, source);
}

export function checkIfShallowlyEqual(
  firstObject: Record<string, unknown>,
  secondObject: Record<string, unknown>,
) {
  const firstObjectKeys = Object.keys(firstObject);
  const secondObjectKeys = Object.keys(secondObject);

  const haveDifferentLengths =
    firstObjectKeys.length !== secondObjectKeys.length;

  if (haveDifferentLengths) return false;

  return firstObjectKeys.every((key) => firstObject[key] === secondObject[key]);
}

export const getUrqlError = (
  combinedError?: CombinedError,
  typedError?: Readonly<{ message?: string }>,
) => {
  const regularError = combinedError?.message;
  const graphQLError = combinedError?.graphQLErrors?.[0]?.message;
  const networkError = combinedError?.networkError?.message;
  const typedErrorMessage = typedError?.message;

  const urqlError =
    regularError ?? graphQLError ?? networkError ?? typedErrorMessage ?? '';

  return urqlError.replace('[GraphQL]', '').trim();
};

/**
 * Some of our prices are returned as integers such as 1203
 * While actually they are supposed to represent 12.03
 * This helper function converts one into another.
 */
export function convertPriceToFloat(price?: number) {
  return Number(((price ?? 0) / 100).toFixed(2));
}

/**
 * @returns whether this is an end to end testing run.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/webdriver.
 *
 * It defines a standard way for co-operating user agents to inform the document
 * that it is controlled by WebDriver, for example, so that alternate code paths
 * can be triggered during automation.
 *
 */
export function isRunningEndToEnd() {
  if (Platform.OS !== 'web') return false;

  return Boolean(window?.navigator?.webdriver);
}

/**
 * Returns web specific link attributes
 *
 * @see https://necolas.github.io/react-native-web/docs/accessibility/#links
 * @see https://github.com/expo/expo/issues/19106
 */
export function getLinkWebAttributes() {
  if (Platform.OS !== 'web') return {};

  return { hrefAttrs: { target: '_blank', rel: 'noopener noreferrer' } };
}

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { Icon, useResponsive } from '@sg/garnish';
import { BodyText, theme, TitleText } from '@sg/garnish';

import { SweetpassHomeActionCardImage } from '../SweetpassHomeActionCardImage';
import type { SweetpassHomeActionCardLayoutVariantProps } from '../SweetpassHomeActionCardLayoutVariantProps';

// TODO: figure out best size for loaded image
const LOADED_IMAGE_SIZE = {
  width: 600,
  height: 600,
};

export const SweetpassHomeActionCardLayoutFullBleed = (
  props: SweetpassHomeActionCardLayoutVariantProps,
) => {
  const {
    testID,
    style,
    theme: themeConfig,
    content: { image, headingText, bodyText },
    hasAction,
    ...otherProps
  } = props;

  const { foregroundColor, backgroundColor } = themeConfig;

  const { currentBreakpoint } = useResponsive();
  const { isLG } = currentBreakpoint;

  const textStyle = useStyle(
    () => ({ color: foregroundColor }),
    [foregroundColor],
  );

  const backgroundViewStyle = useStyle(
    () => ({ backgroundColor }),
    [backgroundColor],
  );

  return (
    <View
      testID={testID}
      style={[styles.container, style, backgroundViewStyle]}
      {...otherProps}
    >
      <SweetpassHomeActionCardImage
        testID={testID?.concat('.image')}
        width={LOADED_IMAGE_SIZE.width}
        height={LOADED_IMAGE_SIZE.height}
        style={styles.image}
        image={image}
        theme={themeConfig}
      />
      <View style={[styles.content, isLG && styles.contentLG]}>
        <TitleText
          testID={testID?.concat('.heading')}
          size={isLG ? 3 : 5}
          style={[styles.headingText, textStyle]}
        >
          {headingText}
        </TitleText>
        <View style={styles.bodyRow}>
          {hasAction ? <View style={styles.iconSpacer} /> : null}
          <BodyText
            testID={testID?.concat('.body')}
            size={3}
            style={[styles.bodyText, textStyle]}
          >
            {bodyText}
          </BodyText>
          {hasAction ? (
            <Icon
              testID={testID?.concat('.action-indicator')}
              style={styles.icon}
              name="IconArrowRight"
              color={foregroundColor}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: theme.spacing['4'],
  },
  icon: {
    marginLeft: theme.spacing['4'],
  },
  iconSpacer: {
    width: 24,
    height: 24,
    marginRight: theme.spacing['4'],
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing['4'],
  },
  contentLG: {
    padding: theme.spacing['6'],
  },
  headingText: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  bodyText: {
    flexGrow: 1,
    textAlign: 'center',
  },
  bodyRow: {
    marginTop: theme.spacing['6'],
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing['4'],
  },
});

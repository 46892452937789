import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { DisplayText, theme, useResponsive } from '@sg/garnish';

import type { TierName } from '@order/graphql';

import type {
  SweetpassHomeActionCardItem,
  SweetpassHomeActionsRailContent,
} from '../../hooks';
import { useActionsRailHandler } from '../../hooks';
import { SweetpassHomeActionCard } from '../SweetpassHomeActionCard/SweetpassHomeActionCard';

export const SweetpassHomeActionsRail = ({
  content,
  tierLevel,
}: SweetpassHomeActionsRailProps) => {
  const { currentBreakpoint } = useResponsive();

  const handlePressAction = useActionsRailHandler();

  const { titleText, actionCards } = content;

  const actionCardForTier = getActionCardForTier(actionCards, tierLevel);

  return (
    <View style={styles.container}>
      {currentBreakpoint.isXS && titleText ? (
        <View style={styles.titleWrapper}>
          <DisplayText size={4}>{titleText}</DisplayText>
        </View>
      ) : null}

      {actionCardForTier ? (
        <View
          style={[
            styles.cardContainer,
            currentBreakpoint.isXS ? styles.cardSpacing : undefined,
          ]}
        >
          <SweetpassHomeActionCard
            key={actionCardForTier.id}
            style={styles.card}
            actionHandler={handlePressAction}
            {...actionCardForTier}
          />
        </View>
      ) : null}
    </View>
  );
};

const getActionCardForTier = (
  actionCards: readonly SweetpassHomeActionCardItem[],
  tierLevel: TierName,
) => {
  const actionCardForTier = actionCards.find((actionCard) =>
    actionCard.tags?.includes(tierLevel),
  );

  if (actionCardForTier) return actionCardForTier;

  const actionCardForAllTiers = actionCards.find((actionCard) =>
    actionCard.tags?.includes('ALL_TIERS'),
  );

  if (actionCardForAllTiers) return actionCardForAllTiers;
};

type SweetpassHomeActionsRailProps = Readonly<{
  content: SweetpassHomeActionsRailContent;
  tierLevel: TierName;
}> &
  Pick<ViewProps, 'testID'>;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  titleWrapper: {
    paddingBottom: theme.spacing['2'],
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.GRAY,
    marginBottom: theme.spacing['4'],
  },
  cardContainer: {
    flex: 1,
  },
  cardSpacing: {
    marginBottom: theme.spacing['6'],
  },
  card: {
    minHeight: 240,
  },
});

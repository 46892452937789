import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useClient } from 'urql';
import {
  HapticNotificationFeedbackStyle,
  triggerHapticNotificationFeedback,
  useNoticeBannersStackContext,
  useResponsive,
} from '@sg/garnish';
import { type DeliveryOrderDetail } from '@sg/graphql-schema';

import {
  type AddUpsellToBagParams,
  type OrderingContext,
} from '@order/features/ordering';
import { type Order, type PartialLineItem } from '@order/graphql';
import { useTelemetry } from '@order/Telemetry';
import { getUrqlError } from '@order/utils';

import {
  AddUpsellToBagDocument,
  type AddUpsellToBagMutation,
  type AddUpsellToBagMutationVariables,
} from '../../GraphQL/BagUpsells.generated';
import { prepareCart } from '../../utils';

// ────────────────────────────────────────────────────────────────────────────

/**
 * A hook for adding an upsell to the bag through the ordering machine.
 */
export const useAddUpsellToBag = () => {
  const client = useClient();
  const { formatMessage } = useIntl();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { track } = useTelemetry();
  const { currentBreakpoint } = useResponsive();

  // ─── Mutations ────────────────────────────────────────────────────────────

  const addUpsellToBag = useCallback(
    async (
      context: OrderingContext<PartialLineItem, DeliveryOrderDetail>,
      { params }: AddUpsellToBagParams,
    ) => {
      const mutation = client.mutation<
        AddUpsellToBagMutation,
        AddUpsellToBagMutationVariables
      >;

      const response = await mutation(
        AddUpsellToBagDocument,
        {
          input: {
            productId: params.productId,
            deliveryOrderDetails: params.deliveryDetails?.addressId
              ? {
                  addressId: params.deliveryDetails.addressId,
                  deliveryFee: params.deliveryDetails.deliveryFee,
                  tip: params.deliveryDetails.tip,
                  vendor: params.deliveryDetails.vendor,
                  vendorRestaurantId: params.deliveryDetails.vendorRestaurantId,
                }
              : undefined,
            quantity: 1,
            customName: null,
            additions: [],
            removals: [],
            substitutions: [],
            mixedDressingDetails: [],
          },
        },
        { requestPolicy: 'network-only' },
      ).toPromise();

      const result = response.data?.addLineItemToCart;

      if (result?.__typename === 'AddLineItemToCartSuccess') {
        void triggerHapticNotificationFeedback(
          HapticNotificationFeedbackStyle.Success,
        );

        track('category_upsells.one_click_add_to_bag_submit', {
          selectedProduct: {
            id: params.productId,
            name: params.productName,
            restaurantId: context.cart.restaurantId,
            location: context.cart.restaurantSlug,
            quantity: 1,
          },
        });

        if (!currentBreakpoint.isXS) {
          addNoticeBanner({
            palette: 'success',
            text: formatMessage(messages.oneClickSuccess, {
              quantity: 1,
              product_name: params.productName,
            }),
          });
        }

        return { cart: prepareCart(result.cart as Partial<Order>) };
      }

      const possibleError = result as Readonly<{ message?: string }>;
      const errorMessage = getUrqlError(response.error, possibleError);
      const fallbackError = formatMessage(messages.genericError);
      const error = errorMessage ?? fallbackError;

      addNoticeBanner({
        palette: 'caution',
        text: error,
      });

      track('category_upsells.one_click_add_to_bag_fail', { message: error });

      return { error };
    },
    [
      currentBreakpoint.isXS,
      client.mutation,
      addNoticeBanner,
      formatMessage,
      track,
    ],
  );

  // ──────────────────────────────────────────────────────────────────────────

  return { addUpsellToBag };
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  oneClickSuccess: {
    defaultMessage: '{quantity}x {product_name} added to your bag!',
    description: 'Bag | Upsell | One-click add to bag success message',
  },
  genericError: {
    defaultMessage: 'Something went wrong, try again in a few moments...',
    description: 'Bag | Upsell | Generic error',
  },
});

import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';
import {
  BodyText as GarnishBodyText,
  theme,
  useResponsive,
  VStack,
} from '@sg/garnish';

import {
  AccountScreenBorderedSection,
  AccountScreenSection,
  AccountScreenSectionTitle,
} from '@order/components';

export const SweetpassMembershipSection = ({
  testID,
  content,
  title,
  children,
}: SectionProps) => {
  const { currentBreakpoint } = useResponsive();

  return (
    <>
      <AccountScreenSectionTitle
        testID={testID?.concat('.title')}
        title={title}
      />
      <AccountScreenSection
        testID={testID}
        style={currentBreakpoint.isXS ? styles.mobileGrow : null}
      >
        <AccountScreenBorderedSection
          style={
            content === 'list'
              ? styles.listBorderedSection
              : styles.plainBorderedSection
          }
        >
          {children}
        </AccountScreenBorderedSection>
      </AccountScreenSection>
    </>
  );
};

type SectionProps = Readonly<{
  title: string;
  content: 'plain' | 'list';
}> &
  Pick<ViewProps, 'children' | 'testID'>;

// ––– Action List Wrapper ––––––––––––––––––––––––––––––––––––

const ActionList = ({ testID, children }: ActionListProps) => (
  <VStack
    testID={testID}
    gap={0}
    hasDivider={true}
    dividerColor={theme.colors.LIGHT}
  >
    {children}
  </VStack>
);

type ActionListProps = Pick<ViewProps, 'children' | 'testID'>;

// ––– Text Wrappers ––––––––––––––––––––––––––––––––––––

const HeadingText = ({ testID, children }: TextProps) => (
  <GarnishBodyText testID={testID} size={3} style={styles.headingText}>
    {children}
  </GarnishBodyText>
);

const BodyText = ({ testID, children }: TextProps) => (
  <GarnishBodyText testID={testID} size={4} style={styles.bodyText}>
    {children}
  </GarnishBodyText>
);

type TextProps = Pick<ViewProps, 'children' | 'testID'>;

// ––– Style –––––––––––––––––––––––––––––––––

const styles = StyleSheet.create({
  mobileGrow: {
    flexGrow: 0,
  },
  plainBorderedSection: {
    paddingVertical: theme.spacing['6'],
  },
  listBorderedSection: {
    paddingVertical: 0,
  },
  headingText: {
    color: theme.colors.GREEN_1,
  },
  bodyText: {
    color: theme.colors.NEUTRAL_2,
    marginTop: theme.spacing['1'],
  },
});

/* eslint-disable functional/immutable-data */
SweetpassMembershipSection.ActionList = ActionList;
SweetpassMembershipSection.HeadingText = HeadingText;
SweetpassMembershipSection.BodyText = BodyText;

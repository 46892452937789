import * as Braze from './BrazeProvider';

/**
 * Mocks `useBraze` hook using `jest.spyOn`.
 */
export function mockUseBrazeOnce(returnValue: Partial<UseBrazeReturnValue>) {
  // @ts-expect-error TS(2708): Cannot use namespace 'jest' as a value.
  jest
    .spyOn(Braze, 'useBraze')
    .mockReturnValueOnce(returnValue as UseBrazeReturnValue);
}

// ─── Types ───────────────────────────────────────────────────────────────────

type UseBrazeReturnValue = ReturnType<typeof Braze.useBraze>;

import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';

import { useNavigateToMainTabs } from '../../../../navigation';
import type { AppStackParamList } from '../../../../navigation/AppNavigation.props';

export const useHomeNavigation = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<AppStackParamList>>();
  const navigateToMainTabs = useNavigateToMainTabs();

  const handleGoHome = useCallback(() => {
    void navigateToMainTabs('HomeTab', { screen: 'Home' });
  }, [navigateToMainTabs]);

  const handleGoToLocations = useCallback(() => {
    navigation.navigate('MainTabs', {
      screen: 'MenuTab',
      params: {
        screen: 'Locations',
      },
    });
  }, [navigation]);

  const handleGoToDefaultLocations = useCallback(() => {
    navigation.navigate('MainTabs', {
      screen: 'MenuTab',
      params: {
        screen: 'Locations',
      },
    });
  }, [navigation]);

  return {
    handleGoHome,
    handleGoToLocations,
    handleGoToDefaultLocations,
  };
};

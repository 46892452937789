import type { RefObject } from 'react';
import type { HostComponent, ScrollView, View } from 'react-native';

import { noop } from './noop';

export const measureElement = async (elementRef?: RefObject<View> | null) => {
  const element = elementRef?.current;

  if (!element) throw new Error('Missing element reference.');

  return new Promise<Measurements>((resolve) => {
    element.measure((x, y, width, height, pageX, pageY) => {
      resolve({ x, y, width, height, pageX, pageY });
    });
  });
};

export const measureLayoutPosition = async (
  parentRef?: RefObject<ScrollView> | null,
  childRef?: RefObject<View> | null,
): Promise<Readonly<{ x: number; y: number }>> => {
  const parent = parentRef?.current as unknown as HostComponent<unknown>;
  const child = childRef?.current;

  if (!parent || !child) throw new Error('Missing parent or child reference.');

  return new Promise((resolve) => {
    child.measureLayout(
      // @ts-expect-error TS(2345): Argument of type 'HostComponent<unknown>' is not a... Remove this comment to see the full error message
      parent,
      (x, y) => {
        resolve({ x, y });
      },
      noop,
    );
  });
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type Measurements = Readonly<{
  x: number;
  y: number;
  width: number;
  height: number;
  pageX: number;
  pageY: number;
}>;

import React, { useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, Button } from '@sg/garnish';

/**
 * Continue button to be used in the bottom of the bag.
 */
export const BagContinueToCheckoutCta = (
  props: BagContinueToCheckoutCtaProps,
) => {
  const { cta, isDisabled, onPress } = props;

  const ref = useRef(null);
  const { formatMessage } = useIntl();

  const disabledStyle = isDisabled ? styles.ctaDisabled : null;
  const disabledLabelStyle = isDisabled ? styles.ctaLabelDisabled : null;

  return (
    <View style={styles.wrapper}>
      <Button
        ref={ref}
        size="large"
        palette="primaryLime"
        style={[styles.cta, disabledStyle]}
        accessibilityLabel={formatMessage(messages.continueToCheckout)}
        disabled={isDisabled}
        onPress={onPress}
      >
        <BodyText
          style={[styles.ctaLabel, disabledLabelStyle]}
          sizeMatch={['18']}
        >
          {cta}
        </BodyText>
      </Button>
    </View>
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const CTA_HEIGHT = 64;

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  continueToBag: {
    defaultMessage: 'Continue',
    description: 'Bag | Floating CTAs | To Bag',
  },
  continueToCheckout: {
    defaultMessage: 'Continue to checkout',
    description: 'Bag | Floating CTAs | To Checkout',
  },
  viewMenu: {
    defaultMessage: 'Menu',
    description: 'Bag | Floating CTAs | View Menu',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    minHeight: CTA_HEIGHT,
  },
  cta: {
    ...theme.elevations[6],
    minHeight: CTA_HEIGHT,
  },
  ctaLabel: {
    color: theme.colors.LIME,
  },
  ctaDisabled: {
    backgroundColor: theme.colors.LIGHT,
  },
  ctaLabelDisabled: {
    color: theme.colors.CHARCOAL,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagContinueToCheckoutCtaProps = {
  cta: string;
  isDisabled: boolean;
  onPress: () => void;
};

import React, { type ComponentProps, useCallback, useRef } from 'react';
import {
  Pressable,
  ScrollView,
  StyleSheet,
  View,
  type ViewProps,
} from 'react-native';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../hooks';
import { Icon } from '../Icon';
import { BodyText } from '../Text';

const SelectListContainer = (props: ViewProps) => {
  const { children } = props;

  return (
    <ScrollView contentContainerStyle={styles.options}>{children}</ScrollView>
  );
};

const SelectListOption = (props: SelectListOptionProps) => {
  const { value, label, isSelected, children, onSelect } = props;

  const ref = useRef<View>(null);
  const { isInteracting } = usePressableState(ref);

  const selectedStyle = isSelected ? styles.selectedOption : null;
  const interactingStyle = isInteracting ? styles.interactingOption : null;

  const handleOnPress = useCallback(() => {
    onSelect(value);
  }, [value, onSelect]);

  return (
    <Pressable
      ref={ref}
      style={[styles.option, selectedStyle, interactingStyle]}
      accessibilityLabel={label}
      onPress={handleOnPress}
    >
      {children}
    </Pressable>
  );
};

const SelectListOptionLabel = (props: ViewProps) => {
  const { children } = props;

  return <BodyText sizeMatch={['18']}>{children}</BodyText>;
};

const SelectListOptionIcon = (props: ComponentProps<typeof Icon>) => {
  return (
    <View>
      <Icon width={24} height={24} {...props} />
    </View>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type SelectListOptionProps = ViewProps & {
  value: string;
  label: string;
  isSelected: boolean;
  onSelect: (id: string) => void;
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  options: {
    gap: theme.spacing['2'],
  },
  option: {
    flex: 1,
    flexDirection: 'row',
    height: theme.spacing['10'],
    borderRadius: theme.spacing['10'],
    paddingVertical: theme.spacing['2'],
    paddingHorizontal: theme.spacing['4'],
    marginHorizontal: theme.spacing['2'],
    gap: theme.spacing['2'],
  },
  selectedOption: {
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTEST,
  },
  interactingOption: {
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTER,
  },
});

// ─── Compound Components ───────────────────────────────────────────────

export const SelectList = {
  Container: SelectListContainer,
  Option: SelectListOption,
  OptionLabel: SelectListOptionLabel,
  OptionIcon: SelectListOptionIcon,
};

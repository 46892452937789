import { useCallback } from 'react';

import { useApplyRewardMutation } from '../GraphQL/Rewards.generated';
import { useApplyRewardErrorMessage } from './useApplyRewardErrorMessage';

export const useApplyReward = (orderId: string, selectedRewardId: string) => {
  const getApplyRewardErrorMessage = useApplyRewardErrorMessage();

  const [applyRewardResponse, applyRewardMutation] = useApplyRewardMutation();

  const applyReward = useCallback(async () => {
    const response = await applyRewardMutation({
      input: { orderId, rewardId: selectedRewardId },
    });

    const result = response?.data?.applyReward;
    const hasApplyRewardFailed = result?.__typename !== 'ApplyRewardSuccess';
    const errorMessage = getApplyRewardErrorMessage(response);

    return {
      hasApplyRewardFailed,
      errorMessage,
    };
  }, [
    orderId,
    selectedRewardId,
    applyRewardMutation,
    getApplyRewardErrorMessage,
  ]);

  return {
    applyReward,
    applyRewardResponse,
    isApplyingReward: applyRewardResponse.fetching,
    applyRewardErrorMessage: getApplyRewardErrorMessage(applyRewardResponse),
  };
};

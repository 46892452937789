import { format, isValid } from 'date-fns';

import { CONTENTFUL_TAGS } from '@order/constants';
import { useContentfulContentTypeEntry } from '@order/Contentful';

import type { SweetpassHeaderEntryFields } from './SweetpassFloatingHeader';

// ─────────────────────────────────────────────────────────────────────────────

export const useSweetpassTierHeaderData = () => {
  const { data, fetching } =
    useContentfulContentTypeEntry<SweetpassHeaderEntryFields>({
      contentType: 'sweetpassHeader',
      tags: [CONTENTFUL_TAGS.Tier.Sweetpass],
    });

  const { upgradeCtaLabel, upgradeCtaHint, tierIllustration } =
    data?.fields ?? {};
  const tierIllustrationURL = tierIllustration?.fields.file.url;

  // ─────────────────────────────────────────────────────────────────────

  return {
    upgradeCtaLabel,
    upgradeCtaHint,
    tierIllustrationURL,
    hasData: data !== undefined,
    fetching,
  };
};

export const useSweetpassPlusTierHeaderData = (
  props: UseSweetpassPlusTierHeaderDataProps,
) => {
  const { data, fetching } =
    useContentfulContentTypeEntry<SweetpassHeaderEntryFields>({
      contentType: 'sweetpassHeader',
      tags: [CONTENTFUL_TAGS.Tier.SweetpassPlus],
    });
  const { memberSinceInfoText, tierIllustration } = data?.fields ?? {};
  const tierIllustrationURL = tierIllustration?.fields.file.url;

  // ─────────────────────────────────────────────────────────────────────

  const joinDate = new Date(props.joinDate);
  const formattedJoinDate = isValid(joinDate)
    ? format(joinDate, 'MMMM yyyy')
    : '';
  const formattedMemberSinceInfoText = memberSinceInfoText?.replace(
    '{sweetpass_join_date}',
    formattedJoinDate,
  );

  // ─────────────────────────────────────────────────────────────────────

  return {
    formattedMemberSinceInfoText,
    tierIllustrationURL,
    hasData: data !== undefined,
    fetching,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseSweetpassPlusTierHeaderDataProps = Readonly<{
  joinDate: string;
}>;

import React from 'react';
import { Controller } from 'react-hook-form';

import { Select as GarnishSelect } from '../Select';
import type { ControlledProps, FormProps } from './types';

type ComponentProps = React.ComponentProps<typeof GarnishSelect>;
type OmittedProps = ControlledProps | 'onSelect';
type Props = FormProps & Omit<ComponentProps, OmittedProps>;

export const Select = ({ name, control, rules, ...rest }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <GarnishSelect
          value={value}
          onSelect={(selected) => {
            onChange(selected);
            onBlur();
          }}
          {...rest}
        />
      )}
    />
  );
};

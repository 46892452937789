import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  type RouteProp,
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { useResponsive } from '@sg/garnish';

import { type HeaderIconItems, useHeaderLeft } from '@order/components';

import type { GiftCardsTabStackParamList } from '../../../../navigation/AppNavigation.props';
import { handleHardwareBackButtonPress } from './helpers';
import { giftCardConfirmationHeaderButtonsMessages as messages } from './useGiftCardConfirmationHeaderButtons.messages';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A small hook to conditionally render custom navigation header buttons.
 *
 * NOTE: We use custom back button navigation since this screen can be
 *       accessed from the gift card orders screen, which is located in a
 *       different stack, so the default `goBack` function won't work
 *       as expected.
 */
export const useGiftCardConfirmationHeaderButtons = () => {
  const { params } = useRoute<GiftCardConfirmationRouteProps>();
  const { setOptions, navigate } = useNavigation();

  const shouldShowGiftCardOrdersBackButton =
    params?.referrer === 'gift-card-orders';

  const { currentBreakpoint } = useResponsive();
  const { formatMessage } = useIntl();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToGiftCardsScreen = useCallback(() => {
    navigate('MainTabs', {
      screen: 'GiftCardsTab',
      params: { screen: 'GiftCards' },
      initial: true,
    });
  }, [navigate]);

  const navigateToGiftCardOrdersScreen = useCallback(() => {
    navigate('MainTabs', {
      screen: 'AccountTab',
      params: {
        screen: 'Orders',
        params: { tabId: 'gift-cards' },
      },
    });
  }, [navigate]);

  const navigateToPreviousScreen = useCallback(() => {
    if (shouldShowGiftCardOrdersBackButton) {
      navigateToGiftCardOrdersScreen();

      return;
    }

    navigateToGiftCardsScreen();
  }, [
    navigateToGiftCardOrdersScreen,
    navigateToGiftCardsScreen,
    shouldShowGiftCardOrdersBackButton,
  ]);

  const overrideHardwareBackButtonPress = useCallback(() => {
    const { removeListener } = handleHardwareBackButtonPress(
      navigateToPreviousScreen,
    );

    return removeListener;
  }, [navigateToPreviousScreen]);

  // ─── Header Controls ─────────────────────────────────────────────────

  const headerLeftItems = useMemo<HeaderIconItems>(() => {
    return [
      {
        key: 'join-or-sign-in-screen-header-back-btn',
        icon: 'IconArrowLeft',
        accessibilityLabel: formatMessage(messages.backButtonA11yLabel),
        onPress: navigateToPreviousScreen,
      },
    ];
  }, [formatMessage, navigateToPreviousScreen]);

  const shouldHideControls = !currentBreakpoint.isXS;
  const headerLeftXS = useHeaderLeft({ items: headerLeftItems });
  const headerLeft = shouldHideControls ? undefined : headerLeftXS;

  // ─── Effects ─────────────────────────────────────────────────────────

  useFocusEffect(overrideHardwareBackButtonPress);

  /**
   * Set header controls.
   */
  useEffect(() => {
    setOptions({
      headerLeft,

      // NOTE: We use a custom back button, thus we need to hide the default one.
      headerBackVisible: false,
    });
  }, [headerLeft, setOptions]);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardConfirmationRouteProps = RouteProp<
  GiftCardsTabStackParamList,
  'GiftCardConfirmation'
>;

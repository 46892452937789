import React from 'react';
import { useIntl } from 'react-intl';

import { GiftCardsCategoriesNav } from '../../components';
import { giftCardsScreenCategoriesNavMessages } from './GiftCardsScreenCategoriesNav.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsScreenCategoriesNav = (
  props: GiftCardsScreenCategoriesNavProps,
) => {
  const { categories, selectedCategoryId, setSelectedCategoryId } = props;

  const { formatMessage } = useIntl();

  return (
    <GiftCardsCategoriesNav.List>
      {categories.map((category) => {
        const { id } = category.fields;
        const a11yLabel = formatMessage(
          giftCardsScreenCategoriesNavMessages.categoryNavItemAccessibilityLabel,
          { category: category.fields.category },
        );
        const isActive = selectedCategoryId === id;

        return (
          <GiftCardsCategoriesNav.ListItem
            key={id}
            id={id}
            isActive={isActive}
            onPress={setSelectedCategoryId}
            accessibilityLabel={a11yLabel}
          >
            {category.fields.category}
          </GiftCardsCategoriesNav.ListItem>
        );
      })}
    </GiftCardsCategoriesNav.List>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardsScreenCategoriesNavProps = {
  categories: {
    fields: {
      id: string;
      category: string;
    };
  }[];
  selectedCategoryId: string | null;
  setSelectedCategoryId: (categoryId: string | null) => void;
};

import { send } from 'xstate';

import { cartModel } from './Cart.machine.model';
import {
  checkIfFinishedFetching,
  checkIfHasPendingMutation,
  registerCartMutation,
  registerCartView,
  unregisterPendingCartMutation,
} from './Cart.machine.utils';

// ─── MACHINE ────────────────────────────────────────────────────────────────────

export const cartMachine = cartModel.createMachine(
  {
    predictableActionArguments: true, // https://xstate.js.org/docs/guides/actions.html
    context: cartModel.initialContext,
    type: 'parallel',
    states: {
      /*
        The state of 'controls' indicates whether cart controls should be disabled.
        It employs data based on the number of pending mutations to disable
        cart controls in order to prevent race conditions and unwanted user actions.
       */
      controls: {
        initial: 'enabled',
        states: {
          enabled: {},
          disabled: {},
        },
      },
    },
    on: {
      //
      // ─────────────────────────────────────────────────────────────────

      REGISTER_PENDING_MUTATION: {
        actions: [registerCartMutation, send('DISABLE_CART_CONTROLS')],
      },
      UNREGISTER_PENDING_MUTATION: {
        actions: [unregisterPendingCartMutation, send('ENABLE_CART_CONTROLS')],
      },

      // ─────────────────────────────────────────────────────────────────

      ENABLE_CART_CONTROLS: {
        target: 'controls.enabled',
        cond: 'checkIfFinishedFetching',
      },
      DISABLE_CART_CONTROLS: {
        target: 'controls.disabled',
        cond: 'checkIfHasPendingMutation',
      },

      // ─────────────────────────────────────────────────────────────────

      REGISTER_CART_VIEW: {
        actions: [registerCartView],
      },
    },
  },
  {
    guards: {
      checkIfHasPendingMutation,
      checkIfFinishedFetching,
    },
  },
);

import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';

import { AspectRatioImage } from '../../../Image';
import { DefaultImage } from './assets';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardImage = (props: LoyaltyOfferCardImageProps) => {
  const { source = DefaultImage, ...restProps } = props;

  return (
    <View pointerEvents="none" style={styles.container}>
      <AspectRatioImage
        aspectRatioY={2}
        aspectRatioX={1}
        source={source}
        {...restProps}
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardImageProps = {
  source: string | undefined;
} & Omit<
  ComponentProps<typeof AspectRatioImage>,
  'aspectRatioY' | 'aspectRatioX' | 'source'
>;

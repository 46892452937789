import type { ComponentProps } from 'react';
import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { type AddressType, getRoundedValueOfPercentage } from '@sg/garnish';

import type { TipsGroup } from '@order/components';
import type { useCart } from '@order/hooks';
import type { FeatureFlags } from '@order/LaunchDarkly';
import { useFeatureFlag } from '@order/LaunchDarkly';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns checkout tips and helpers based on the active cart,
 * dynamic tip values, checkout payload, and other parameters.
 */
export const useBagTips = (params: UseBagTipsParams) => {
  const { subtotal, orderChannel } = params;

  const dynamicTips = useFeatureFlag('CELS-1191-permanent-dynamic-tip-values');
  const { formatMessage, formatNumber } = useIntl();

  // ─────────────────────────────────────────────

  const tipsForOrderType = useMemo(
    () => getDynamicTipValues(dynamicTips, orderChannel),
    [orderChannel, dynamicTips],
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  /**
   * Returns a formatted tip item after doing multiple safety checks to
   * guarantee that the tip item is valid.
   */
  const safelyGetTip = useCallback(
    (dynamicTip: DynamicTipForOrderType): Tip | undefined => {
      const formatPrice = (value: string | number) =>
        formatNumber(Number(value) / 100, {
          style: 'currency',
          currency: 'USD',
        });

      const { value: tipValue } = dynamicTip ?? {};
      const hasValidTipValue =
        typeof tipValue === 'number' || tipValue === 'CUSTOM';

      if (!hasValidTipValue) return;

      // ─── "No Tip" option ─────────────────────────

      if (tipValue === 0) {
        return {
          value: '0',
          amount: 0,
          label: formatMessage(messages.noTip),
          accessibilityHint: formatMessage(messages.noTipA11yHint),
        };
      }

      // ─── "Custom" tip option ─────────────────────

      if (tipValue === 'CUSTOM') {
        return {
          value: 'custom',
          label: formatMessage(messages.custom),
          accessibilityHint: formatMessage(messages.customTipA11yHint),
        };
      }

      // ─── Standard tip option ─────────────────────

      const value = `${tipValue}`;
      const label = `${tipValue}%`;
      const amount = getRoundedValueOfPercentage(tipValue, subtotal);
      const helperText = formatPrice(amount);
      const accessibilityHint = formatMessage(messages.tipA11yHint, {
        tip: label,
        amount: helperText,
      });

      return { value, label, helperText, amount, accessibilityHint };
    },
    [subtotal, formatMessage, formatNumber],
  );

  /**
   * Returns the default tip value if one is set, or the first available one.
   */
  const defaultTipValue = useMemo<string>(() => {
    const defaultTip =
      tipsForOrderType.find((tip) => tip?.isDefault) ?? tipsForOrderType[0];

    return defaultTip?.value ? `${defaultTip.value}` : '';
  }, [tipsForOrderType]);

  // ─── Derived data ────────────────────────────────────────────────────

  /**
   * Formatted valid tips.
   */
  const tips = useMemo<Tips>(() => {
    const tipsWithExtraDetails = tipsForOrderType.map(safelyGetTip);

    return tipsWithExtraDetails.filter(Boolean) as Tips;
  }, [safelyGetTip, tipsForOrderType]);

  // ─────────────────────────────────────────────────────────────────────

  return { tips, defaultTipValue };
};

// ─── Helpers ────────────────────────────────────────────────────────────────

/**
 * Returns dynamic tips for the current order type (if any are available).
 */
function getDynamicTipValues(
  dynamicTips: DynamicTips,
  orderType: OrderType,
): DynamicTipsForOrderType {
  if (typeof dynamicTips !== 'object') return [];

  // ─────────────────────────────────────────────

  const { pickup: pickupTips, delivery: deliveryTips } = dynamicTips;

  const shouldReturnPickupTips =
    orderType === 'pickup' && pickupTips !== undefined;
  const shouldReturnDeliveryTips =
    orderType === 'delivery' && deliveryTips !== undefined;

  // ─────────────────────────────────────────────

  if (shouldReturnPickupTips) return pickupTips;

  if (shouldReturnDeliveryTips) return deliveryTips;

  return [];
}

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  custom: {
    defaultMessage: 'Custom',
    description: 'Bag | Tipping | Custom',
  },
  customFieldA11y: {
    defaultMessage: 'Enter custom tip',
    description: 'Bag | Tipping | Custom field a11y',
  },
  customField: {
    defaultMessage: 'Enter a custom amount',
    description: 'Bag | Tipping | Custom field',
  },
  noTip: {
    defaultMessage: 'No tip',
    description: 'Bag | Tipping | No tip',
  },
  tipA11yHint: {
    defaultMessage: 'Selects "{tip} ({amount})" tip option',
    description: 'Bag | Tipping | Tip a11y hint',
  },
  noTipA11yHint: {
    defaultMessage: 'Selects "No tip" option',
    description: 'Bag | Tipping | No tip a11y hint',
  },
  customTipA11yHint: {
    defaultMessage: 'Selects "Custom" tip option',
    description: 'Bag | Tipping | Custom tip a11y hint',
  },
  customAmountHint: {
    defaultMessage: 'Enter custom amount',
    description: 'Bag | Tipping | Custom amount hint',
  },
  customFieldMaxNotice: {
    defaultMessage:
      'The maximum tip for this order is 100% or {max}, but our team appreciates your generosity!',
    description: 'Bag | Tipping | Custom field max notice',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type UseBagTipsParams = {
  subtotal: number;
  orderChannel: AddressType;
};

type Tips = ComponentProps<typeof TipsGroup>['tips'];

type Tip = Tips[number];

type OrderType = ReturnType<typeof useCart>['cartOrderType'];

type DynamicTips = FeatureFlags['CELS-1191-permanent-dynamic-tip-values'];

type DynamicTipsForOrderType = NonNullable<
  ValidDynamicTips['pickup'] | ValidDynamicTips['delivery']
>;

type DynamicTipForOrderType = DynamicTipsForOrderType[number];

type ValidDynamicTips = NonNullable<DynamicTips>;

import type { ComponentProps } from 'react';
import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import type { RouteProp } from '@react-navigation/native';
import { useRoute } from '@react-navigation/native';
import { theme } from '@garnish/constants';
import { useResponsive } from '@sg/garnish';

import { SweetpassUpsellCta } from '@order/components';
import { useLocalizationContext } from '@order/Localization';

import type { ModalStackParamList } from '../../../../navigation/AppNavigation.props';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderStatusSweetpassUpgradeCta = () => {
  const { match } = useResponsive();
  const { params } = useRoute<RouteProp<ModalStackParamList, 'OrderStatus'>>();

  const { t } = useLocalizationContext();

  const { orderId } = params;
  const containerStyles = match([styles.containerXS, styles.containerSM]);

  const navigationParams = useMemo<SweetpassUpsellCtaNavigationParams>(
    () => ({
      redirectToOrderId: orderId,
      redirectToAfterUpgrade: 'order-status',
    }),
    [orderId],
  );

  // ─────────────────────────────────────────────────────────────────────

  const contentFallback = useMemo(
    () => ({
      text: t('order-status-info.courier-cta'),
      iconText: t('order-status.sweetpass-upsell-cta.fallback.icon-text'),
      textHighlights: [
        t('order-status.sweetpass-upsell-cta.fallback.text-highlight'),
      ],
    }),
    [t],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <SweetpassUpsellCta
      containerStyle={containerStyles}
      redirectParamsForSweetpassUpgradeScreen={navigationParams}
      contentTag="sweetpassUpsellCtaOrderStatus"
      contentFallback={contentFallback}
      telemetryPayload={UPSELL_CTA_TELEMETRY_PAYLOAD}
      testID="order-status.sweetpass-upsell-cta"
    />
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const UPSELL_CTA_TELEMETRY_PAYLOAD: SweetpassUpsellCtaTelemetryPayload = {
  ctaLocation: 'Order Status',
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    marginTop: theme.spacing['2'],
    marginBottom: theme.spacing['6'],
  },
  containerSM: {
    marginBottom: theme.spacing['10'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassUpsellCtaNavigationParams =
  SweetpassUpsellCtaProps['redirectParamsForSweetpassUpgradeScreen'];

type SweetpassUpsellCtaTelemetryPayload =
  SweetpassUpsellCtaProps['telemetryPayload'];

type SweetpassUpsellCtaProps = ComponentProps<typeof SweetpassUpsellCta>;

import {
  type ChallengeOptInMutation,
  type ChallengeOptInV2Mutation,
} from '@order/graphql';

export const checkIfChallengeStarted = (
  result?: ChallengeOptInV2Mutation | ChallengeOptInMutation,
): boolean => {
  if (!result) return false;

  const isV2 = 'challengeOptInV2' in result;

  if (!isV2) return result.challengeOptIn.status === 'STARTED';

  if (result.challengeOptInV2.__typename === 'LoyaltyOptInFailed') return false;

  return result.challengeOptInV2.status === 'STARTED';
};

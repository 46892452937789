import {
  MenuDietaryPreferencesButton,
  MenuDietaryPreferencesButtonsContainer,
  MenuDietaryPreferencesContainer,
  MenuDietaryPreferencesDescription,
  MenuDietaryPreferencesHeading,
  MenuDietaryPreferencesModalCloseButton,
  MenuDietaryPreferencesModalHeader,
  MenuDietaryPreferencesNotices,
  MenuDietaryPreferencesSubmitButton,
  MenuDietaryPreferencesToggle,
} from './components';

export const MenuDietaryPreferences = {
  Toggle: MenuDietaryPreferencesToggle,
  Container: MenuDietaryPreferencesContainer,
  Heading: MenuDietaryPreferencesHeading,
  Description: MenuDietaryPreferencesDescription,
  ButtonsContainer: MenuDietaryPreferencesButtonsContainer,
  Button: MenuDietaryPreferencesButton,
  Notices: MenuDietaryPreferencesNotices,
  SubmitButton: MenuDietaryPreferencesSubmitButton,
  ModalCloseButton: MenuDietaryPreferencesModalCloseButton,
  ModalHeader: MenuDietaryPreferencesModalHeader,
};

import React, { useCallback } from 'react';

import { openUrl } from '../../utils';
import { TextLink } from '../TextLink';
import type { PhoneLinkProps } from './types';

export const PhoneLink = ({
  phoneNumber,
  protocol,
  alertLabel = 'Something went wrong, try again later.',
  ...rest
}: PhoneLinkProps) => {
  const handlePress = useCallback(async () => {
    const url = `${protocol}:${phoneNumber}`;

    await openUrl(url, alertLabel);
  }, [phoneNumber, protocol, alertLabel]);

  return <TextLink onPress={handlePress} {...rest} />;
};

import { TierName } from '@order/graphql';

import { useSweetpassTier } from '../../../../../hooks/useSweetpassTier';

export const useSweetPassOptions = () => {
  const { sweetpassTierName } = useSweetpassTier();

  const isSweetpassJoinItemVisible =
    Boolean(sweetpassTierName) && sweetpassTierName === TierName.OptedOut;

  const isSweetpassMembershipItemVisible =
    Boolean(sweetpassTierName) && sweetpassTierName !== TierName.OptedOut;

  return { isSweetpassJoinItemVisible, isSweetpassMembershipItemVisible };
};

import type { MixedDressingDetails } from '@order/graphql';

import type {
  IngredientModificationWithQuantity as IngredientModification,
  IngredientModificationWithQuantity,
} from '../../types';
import { getWeightValue } from './dressings';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * Returns the total number of calories using the following criteria:
 *
 * - Determine the calories in ingredients based on their quantity.
 * - Determine the calories in `bases` type non-grain ingredients in relation to the overall quantity of `bases`.
 * - Determine the calories in ingredients that are used in `dressings` based on their `weight`/`portion`.
 * - Round the total calories to the nearest 5.
 *
 * NOTE: If the product cannot be modified (for example sides, drinks), the original calorie value is returned.
 */
export const calculateCalories = (props: CalculateCaloriesProps) => {
  const {
    ingredientsModifications,
    mixedDressingDetails,
    isModifiable,
    productCalories,
    isSumCaloriesEnabled,
  } = props;

  // if the product cannot be modified, return the original calorie value
  if (!isModifiable) return productCalories;

  const baseNonGrainIngredientsCalories =
    calculateBaseNonGrainIngredientsCalories(ingredientsModifications);
  const dressingIngredientsCalories = calculateDressingIngredientsCalories(
    ingredientsModifications,
    mixedDressingDetails,
  );
  const standardIngredientsCalories = calculateStandardIngredientsCalories(
    ingredientsModifications,
  );

  // ─────────────────────────────────────────────────────────────────

  const totalCalories =
    baseNonGrainIngredientsCalories +
    dressingIngredientsCalories +
    standardIngredientsCalories;

  // ─────────────────────────────────────────────────────────────────

  if (isSumCaloriesEnabled) {
    return Math.round(totalCalories);
  }

  return roundNumberToTheNearestFive(totalCalories);
};

// ─── INTERNAL HELPERS BASED ON KIND ─────────────────────────────────────────────

/**
 * Calories for `bases` grain ingredients are determined by their type and portions
 * based on the total number of `bases` kind ingredients added to the final product.
 *
 * Example:
 *
 * The product contains:
 *   1 grain type base ingredient (100cal)
 *   2 non-grain base ingredients (60cal each).
 *
 * We will only be using 1/3 portion of each base non-grain ingredient, where `3` is
 * the total number of `bases` ingredients.
 *
 * Total calories: 100 + 20 + 20 = 140cal (instead of 180cal).
 */
const calculateBaseNonGrainIngredientsCalories = (
  ingredientsModifications: readonly IngredientModification[],
) => {
  const baseNonGrainIngredients = ingredientsModifications.filter(
    checkIfBaseNonGrainKind,
  );
  const allBaseIngredientsNumber =
    ingredientsModifications.filter(checkIfBaseKind).length;

  return baseNonGrainIngredients.reduce(
    (totalCalories, ingredientModification) => {
      const calories =
        calculateIngredientModificationCalories(ingredientModification) /
        allBaseIngredientsNumber;

      return totalCalories + calories;
    },
    0,
  );
};

/**
 * Calories of "dressings" ingredients are determined based on their weight/portion sizes.
 */
const calculateDressingIngredientsCalories = (
  ingredientsModifications: readonly IngredientModification[],
  mixedDressingDetails: readonly MixedDressingDetails[],
) => {
  const dressingIngredientsModifications =
    ingredientsModifications.filter(checkIfDressingKind);

  return dressingIngredientsModifications.reduce(
    (totalCalories, ingredientModification) => {
      const portionSize = getDressingPortionSize(
        ingredientModification,
        mixedDressingDetails,
      );
      const calories =
        calculateIngredientModificationCalories(ingredientModification) *
        portionSize;

      return totalCalories + calories;
    },
    0,
  );
};

const calculateStandardIngredientsCalories = (
  ingredientsModifications: readonly IngredientModification[],
) => {
  const standardModifications =
    ingredientsModifications.filter(checkIfStandardKind);

  return standardModifications.reduce((totalCalories, ingredient) => {
    return totalCalories + calculateIngredientModificationCalories(ingredient);
  }, 0);
};

// ────────────────────────────────────────────────────────────────────────────────

const checkIfBaseKind = (ingredientModification: IngredientModification) =>
  ingredientModification.kind === 'bases';

const checkIfBaseNonGrainKind = (
  ingredientModification: IngredientModification,
) => checkIfBaseKind(ingredientModification) && !ingredientModification.isGrain;

const checkIfDressingKind = (ingredientModification: IngredientModification) =>
  ingredientModification.kind === 'dressings';

const checkIfStandardKind = (ingredientModification: IngredientModification) =>
  !checkIfBaseNonGrainKind(ingredientModification) &&
  !checkIfDressingKind(ingredientModification);

// ─── UTILS ──────────────────────────────────────────────────────────────────────

/**
 * Uses the quantity of an ingredient to figure out how many calories it has in total.
 */
const calculateIngredientModificationCalories = (
  ingredientModification: IngredientModification,
) => {
  const { calories, quantity } = ingredientModification;
  const ingredientQty = quantity ?? 0;

  return calories * ingredientQty;
};

const getDressingPortionSize = (
  ingredientModification: IngredientModification,
  mixedDressingDetails: readonly MixedDressingDetails[],
) => {
  const { ingredient } = ingredientModification;

  const mixedDressingDetail = mixedDressingDetails.find(
    (dressingDetail) => dressingDetail.ingredientId === ingredient.id,
  );

  if (!mixedDressingDetail?.weight) return 1;

  return getWeightValue(mixedDressingDetail.weight);
};

/**
 * Rounds a Number (up or down) to the Nearest 5
 *
 * Examples:
 * 102.4 => 100,
 * 102.5 => 105
 *
 * @see https://bobbyhadz.com/blog/javascript-round-number-to-nearest-five
 */
const roundNumberToTheNearestFive = (num: number) => Math.round(num / 5) * 5;

// ─── Types ──────────────────────────────────────────────────────────────────────

type CalculateCaloriesProps = Readonly<{
  ingredientsModifications: readonly IngredientModificationWithQuantity[];
  mixedDressingDetails: readonly MixedDressingDetails[];
  isModifiable: boolean;
  productCalories: number;
  isSumCaloriesEnabled: boolean;
}>;

import type { ComponentProps } from 'react';
import { useCallback, useMemo } from 'react';
import { isValid } from 'date-fns';
import type { RewardCard } from '@sg/garnish';
import { getFormattedExpirationDate } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Extends the provided rewards by including derived data such as palette,
 * more details, and disabled state.
 */
export const useRewardsWithDerivedData = (rewards: readonly Reward[]) => {
  const { t, formatDistanceBetweenDates } = useLocalizationContext();

  // ─────────────────────────────────────────────────────────────────────

  /**
   * Returns the `palette` for reward cards based on the specified conditions:
   *
   * - Whether the customer has Sweetpass subscription.
   * - Whether it's a premium reward.
   */
  const getRewardPalette = useCallback(
    (isPremiumReward = false): RewardCardPalette => {
      const basePalette = 'cucumber';
      const premiumPalette = 'kale';

      return isPremiumReward ? premiumPalette : basePalette;
    },
    [],
  );

  // ─── Helpers for different reward types ──────────────────────────────

  const getStandardRewardDerivedData = useCallback(
    (reward: RewardWithTag): RewardWithDerivedData => {
      // NOTE: In order to support 2 different API models (`challengesRewards` and `rewards`),
      //       we use 2 possible expiration date properties
      const { expiresAt, expirationDate } = reward;

      const expirationDateWithFallback = expiresAt ?? expirationDate ?? '';
      const rewardExpirationDate = new Date(expirationDateWithFallback);

      const expirationDatePrefix = t(
        'challenges.rewards.sweetpass-deadline-prefix',
      );

      const extraDetails = isValid(rewardExpirationDate)
        ? getFormattedExpirationDate(
            expirationDateWithFallback,
            expirationDatePrefix,
          )
        : '';
      const palette = getRewardPalette();

      return { ...reward, extraDetails, palette };
    },
    [getRewardPalette, t],
  );

  const getPremiumRewardDerivedData = useCallback(
    (reward: RewardWithTag): RewardWithDerivedData => {
      const extraDetails = t('challenges.rewards.premium-label');
      const palette = getRewardPalette(true);

      return { ...reward, extraDetails, palette };
    },
    [getRewardPalette, t],
  );

  const getDisabledRewardDerivedData = useCallback(
    (reward: RewardWithTag): RewardWithDerivedData => {
      const { redeemableAt = '' } = reward;

      const targetDate = redeemableAt ? new Date(redeemableAt) : undefined;
      const distanceToDate = targetDate
        ? formatDistanceBetweenDates({ targetDate })
        : undefined;

      const useAgainText = t('challenges.rewards.use-again', {
        distanceToDate,
      });

      const extraDetails = distanceToDate ? useAgainText : '';
      const palette = getRewardPalette();

      return { ...reward, extraDetails, palette, isDisabled: true };
    },
    [formatDistanceBetweenDates, getRewardPalette, t],
  );

  /**
   * Optionally adds a tag based on the redemption channel.
   */
  const withTag = useCallback(
    (reward: Reward): Reward | RewardWithTag => {
      const { redemptionChannel } = reward;

      if (!redemptionChannel) return reward;

      const redemptionChannelKey = redemptionChannel.toLowerCase();
      const tag = t('challenges.rewards.tag', {
        redemptionChannel: redemptionChannelKey,
      });

      return { ...reward, tag };
    },
    [t],
  );

  // ─────────────────────────────────────────────────────────────────────

  /**
   * Returns an expanded reward object with additional extra fields.
   *
   * NOTE: In order to preserve compatibility with "Challenges",
   *       certain additional features are exclusive to "Sweetpass" customers.
   */
  const withDerivedData = useCallback(
    (reward: Reward): Reward & RewardDerivedData => {
      const { rewardType = 'NON_PREMIUM', redeemable = true } = reward;

      const isDisabledReward = !redeemable;
      const isPremiumReward = rewardType === 'PREMIUM';
      const rewardWithTag = withTag(reward);

      // ─── "Disabled" Reward ───────────────────────

      if (isDisabledReward) {
        return getDisabledRewardDerivedData(rewardWithTag);
      }

      // ─── "Premium" Reward ─────────────────────────

      if (isPremiumReward) {
        return getPremiumRewardDerivedData(rewardWithTag);
      }

      // ─── "Standard" Reward ────────────────────────

      return getStandardRewardDerivedData(rewardWithTag);
    },
    [
      getDisabledRewardDerivedData,
      getPremiumRewardDerivedData,
      getStandardRewardDerivedData,
      withTag,
    ],
  );

  // ─────────────────────────────────────────────────────────────────────

  return useMemo(
    () => rewards.map(withDerivedData),
    [rewards, withDerivedData],
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type Reward = Readonly<{
  id: string;
  name: string;
  rewardType: 'PREMIUM' | 'NON_PREMIUM';
  expirationDate?: string | null;
  terms: string;
  expiresAt: string | null;
  redeemableAt: string | null;
  redeemable: boolean | null;
  redemptionChannel?: string;
}>;

type RewardWithTag = Reward &
  Readonly<{
    tag?: string;
  }>;

type RewardDerivedData = Readonly<{
  extraDetails: string;
  palette: RewardCardPalette;
  isDisabled?: boolean;
  tag?: string;
}>;

type RewardWithDerivedData = Reward & RewardDerivedData;

type RewardCardPalette = NonNullable<
  ComponentProps<typeof RewardCard>['palette']
>;

import React from 'react';

import { useTelemetry, useTrackEffect } from '@order/Telemetry';

import type { AddressForm, FormProps } from './AddressForm.types';

export const useAddressesTelemetry = ({
  editing,
  onSave,
  onEdit,
  onDelete,
}: Pick<FormProps, 'editing' | 'onSave' | 'onEdit' | 'onDelete'>) => {
  const { track } = useTelemetry();

  useTrackEffect(editing ? 'addresses.view' : 'addresses.add');

  const monitoredOnSave = React.useCallback(
    (data: AddressForm) => {
      track('addresses.save', {
        dropoffMethod: data.deliveryPreference?.toLowerCase() ?? '',
        type: ['home', 'work'].includes(data.name?.toLowerCase() ?? '')
          ? data.name?.toLowerCase() ?? 'custom'
          : 'custom',
      });
      onSave?.(data);
    },
    [track, onSave],
  );

  const monitoredOnEdit = React.useCallback(
    (data: AddressForm) => {
      track('addresses.save', {
        dropoffMethod: data.deliveryPreference?.toLowerCase() ?? '',
        type: ['home', 'work'].includes(data.name?.toLowerCase() ?? '')
          ? data.name?.toLowerCase() ?? 'custom'
          : 'custom',
      });
      onEdit?.(data);
    },
    [onEdit, track],
  );

  const monitoredOnFail = React.useCallback(
    (userError?: string, systemError?: string) => {
      track('addresses.save_failed', { userError, systemError });
    },
    [track],
  );

  const monitoredOnDelete = React.useCallback(() => {
    track('addresses.remove');
    onDelete?.();
  }, [onDelete, track]);

  return React.useMemo(
    () => ({
      monitoredOnSave,
      monitoredOnEdit,
      monitoredOnFail,
      monitoredOnDelete,
    }),
    [monitoredOnSave, monitoredOnEdit, monitoredOnFail, monitoredOnDelete],
  );
};

import { useCallback } from 'react';
import { useActor } from '@xstate/react';

import { useSendLogoutToAuthMachine } from '@order/AuthMachine';
import { useCustomer } from '@order/Customer';
import { useGlobalAppState } from '@order/GlobalAppState';
import { useTelemetry } from '@order/Telemetry';

import { removeTokens } from '../../helpers';
import { signOut as signOutFromAzure } from '../../services';
import { useShouldPreferEphemeralSession } from '../useShouldPreferEphemeralSession';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns a helper for signing out the active user from Azure and Sweetgreen.
 */
export const useAzureAuthSignOut = (params: UseAzureAuthSignOutParams) => {
  const { resetAppState } = params;

  const { azureAuthMachineRef } = useGlobalAppState();
  const { track } = useTelemetry();
  const { signOut } = useCustomer();
  const [, send] = useActor(azureAuthMachineRef);
  const sendLogoutAction = useSendLogoutToAuthMachine();

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldPreferEphemeralSession = useShouldPreferEphemeralSession();

  // ─────────────────────────────────────────────────────────────────────

  return useCallback(async () => {
    const hasSignedOut = await signOut();

    if (!hasSignedOut) {
      return;
    }

    await removeTokens();
    sendLogoutAction();
    send('RESTART');
    await resetAppState();

    if (!shouldPreferEphemeralSession) {
      try {
        await signOutFromAzure();
        track('azure-ad.logout.success');
      } catch (error: unknown) {
        track('azure-ad.logout.failure', { systemError: error as string });
      }
    }
  }, [
    resetAppState,
    send,
    sendLogoutAction,
    shouldPreferEphemeralSession,
    signOut,
    track,
  ]);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseAzureAuthSignOutParams = {
  resetAppState: () => Promise<void>;
};

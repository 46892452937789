import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Text } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import {
  ActiveIngredient,
  useActiveIngredientFloatingStepper,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationSelectedIngredient = <
  IngredientType extends IngredientBase,
>(
  props: CustomizationSelectedIngredientProps<IngredientType>,
) => {
  const {
    ingredientModification,
    isActive,
    isDisabled,
    setActive,
    setInactive,
    onIncreaseQuantity,
    onDecreaseQuantity,
    onRemove,
  } = props;

  // ─────────────────────────────────────────────────────────────────────

  const { triggerRef, triggerCoordinates, updateStepperTriggerCoordinates } =
    useActiveIngredientFloatingStepper();

  const isHovered = useHover(triggerRef);

  const { formatMessage } = useIntl();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    updateStepperTriggerCoordinates();
    setActive(ingredientModification.ingredient.id);
  }, [
    updateStepperTriggerCoordinates,
    ingredientModification.ingredient.id,
    setActive,
  ]);

  const handleIncreaseQuantity = useCallback(() => {
    onIncreaseQuantity(ingredientModification);
  }, [ingredientModification, onIncreaseQuantity]);

  const handleDecreaseQuantity = useCallback(() => {
    onDecreaseQuantity(ingredientModification);
  }, [ingredientModification, onDecreaseQuantity]);

  const handleRemove = useCallback(() => {
    onRemove(ingredientModification);
  }, [ingredientModification, onRemove]);

  // ─────────────────────────────────────────────────────────────────────

  const ingredientName = ingredientModification.ingredient.name;
  const quantity = ingredientModification.quantity ?? 1;

  // ─── a11y labels ─────────────────────────────────────────────────────

  const openQuantityStepperA11yLabel = formatMessage(
    messages.openQuantityStepper,
    { ingredient: ingredientName },
  );
  const closeQuantityStepperA11yLabel = formatMessage(
    messages.closeQuantityStepper,
  );
  const increaseQty = formatMessage(messages.increaseQty, {
    ingredient: ingredientName,
    quantity,
  });
  const decreaseQty = formatMessage(messages.decreaseQty, {
    ingredient: ingredientName,
    quantity,
  });
  const removeIngredient = formatMessage(messages.removeIngredient, {
    ingredient: ingredientName,
  });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <ActiveIngredient.Container>
        <ActiveIngredient.PressableContainer ref={triggerRef}>
          <ActiveIngredient.Pressable
            active={isActive}
            hovered={isHovered}
            onPress={handleOnPress}
            accessibilityLabel={openQuantityStepperA11yLabel}
            disabled={isDisabled}
          >
            <ActiveIngredient.Text active={isActive} hovered={isHovered}>
              {quantity > 1 ? (
                <>
                  {ingredientModification.quantity}
                  <Text> </Text>
                </>
              ) : null}

              {ingredientModification.ingredient.name}
            </ActiveIngredient.Text>
          </ActiveIngredient.Pressable>

          <ActiveIngredient.RemoveButton
            active={isActive}
            hovered={isHovered}
            onPress={handleRemove}
            accessibilityLabel={removeIngredient}
          />
        </ActiveIngredient.PressableContainer>
      </ActiveIngredient.Container>

      <ActiveIngredient.QuantityStepper.Overlay
        isVisible={isActive}
        stepperPositionY={triggerCoordinates.y}
        stepperPositionX={triggerCoordinates.x}
        dismiss={setInactive}
        accessibilityLabel={closeQuantityStepperA11yLabel}
      >
        <ActiveIngredient.QuantityStepper.Container isVisible={isActive}>
          <ActiveIngredient.QuantityStepper.Button
            name="IconMinus2"
            onPress={handleDecreaseQuantity}
            accessibilityLabel={quantity === 1 ? removeIngredient : decreaseQty}
          />

          <ActiveIngredient.QuantityStepper.Text>
            {ingredientModification.quantity}
          </ActiveIngredient.QuantityStepper.Text>

          <ActiveIngredient.QuantityStepper.Button
            name="IconPlus2"
            onPress={handleIncreaseQuantity}
            accessibilityLabel={increaseQty}
          />
        </ActiveIngredient.QuantityStepper.Container>
      </ActiveIngredient.QuantityStepper.Overlay>
    </>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  openQuantityStepper: {
    defaultMessage: 'Open quantity stepper for {ingredient}',
    description:
      'Customization | Ingredient | Open quantity stepper a11y label',
  },
  closeQuantityStepper: {
    defaultMessage: 'Close quantity stepper',
    description:
      'Customization | Ingredient | Close quantity stepper a11y label',
  },
  increaseQty: {
    defaultMessage:
      'Increase quantity of {ingredient} (current quantity: {quantity})',
    description: 'Customization | Ingredient | Increase quantity a11y label',
  },
  decreaseQty: {
    defaultMessage:
      'Decrease quantity of {ingredient} (current quantity: {quantity})',
    description: 'Customization | Ingredient | Decrease quantity a11y label',
  },
  removeIngredient: {
    defaultMessage: 'Remove {ingredient}',
    description: 'Customization | Ingredient | Remove ingredient a11y label',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationSelectedIngredientProps<
  IngredientType extends IngredientBase,
> = {
  ingredientModification: IngredientType;
  isActive: boolean;
  isDisabled?: boolean;

  setActive: (ingredientId: string) => void;
  setInactive: () => void;
  onRemove: (ingredient: IngredientType) => void;
  onIncreaseQuantity: (ingredient: IngredientType) => void;
  onDecreaseQuantity: (ingredient: IngredientType) => void;
};

type IngredientBase = {
  ingredient: { id: string; name: string };
  quantity?: number | null;
};

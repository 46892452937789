import { useMemo } from 'react';
import { useStyle } from 'react-native-style-utilities';

export const useRailOffsetStyles = (outerOffset: number) => {
  const containerStyle = useStyle(
    () => ({ paddingHorizontal: Math.abs(outerOffset) }),
    [outerOffset],
  );
  const railStyle = useStyle(
    () => ({ marginHorizontal: outerOffset }),
    [outerOffset],
  );

  return useMemo(
    () => ({ containerStyle, railStyle }),
    [containerStyle, railStyle],
  );
};

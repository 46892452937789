/* istanbul ignore file */

import { useCallback } from 'react';
import { useInterpret, useSelector } from '@xstate/react';
import { type ContextFrom } from 'xstate';

import { useFeatureFlag, useLaunchDarkly } from '@order/LaunchDarkly';

import { otaUpdateStateMachine } from './ota-update-state-machine';

// ─────────────────────────────────────────────────────────────────────────────

export const useOtaUpdateMachine = () => {
  //
  // ─── Feature Flags ───────────────────────────────────────────────────

  const { addContext } = useLaunchDarkly();

  const initialUpdateEventTimeout = useFeatureFlag(
    'OTA-update-in-app-checker-initial-check-timeout-ms',
  );
  const pollingInterval = useFeatureFlag(
    'OTA-update-in-app-checker-polling-interval-ms',
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  const storeUpdateContext = useCallback(
    async (context: ContextFrom<typeof otaUpdateStateMachine>) => {
      const { availableUpdate } = context;

      if (!availableUpdate) {
        return;
      }

      return addContext?.({
        otaUpdateTargetVersion: availableUpdate.targetVersion,
      });
    },
    [addContext],
  );

  // ─── Machine ─────────────────────────────────────────────────────────

  const otaUpdateService = useInterpret(otaUpdateStateMachine, {
    context: {
      options: { initialUpdateEventTimeout, pollingInterval },
    },
    services: {
      storeUpdateContext,
    },
  });

  const { send } = otaUpdateService;

  // ─── Derived Data ────────────────────────────────────────────────────

  const availableUpdate = useSelector(
    otaUpdateService,
    (state) => state.context.availableUpdate,
  );

  // ─── Flags ───────────────────────────────────────────────────────────

  const updateState = useSelector(otaUpdateService, (state) => {
    const { matches } = state;

    return {
      isRestartingApp: matches('restarting'),
      hasFailedToRestartApp: matches('restartingFailed'),
      hasPendingUpdate: matches('pendingUpdate'),
    };
  });

  // ─────────────────────────────────────────────────────────────────────

  return { availableUpdate, updateState, send };
};

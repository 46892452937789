import type { ComponentProps } from 'react';
import React from 'react';
import { theme } from '@garnish/constants';

import { IconLink } from '../../Icon';

// ────────────────────────────────────────────────────────────────────────────────

export const LineItemFavoriteBtn = (props: LineItemFavoriteBtnProps) => {
  const { isActive, onPress } = props;

  const iconName = isActive ? 'IconHeartFill' : 'IconHeartStroke';

  return (
    <IconLink
      color={theme.colors.BLACK}
      name={iconName}
      width={24}
      height={24}
      testID="product-favorite"
      onPress={onPress}
    />
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type LineItemFavoriteBtnProps = Readonly<{
  isActive?: boolean;
}> &
  Pick<ComponentProps<typeof IconLink>, 'onPress' | 'disabled'>;

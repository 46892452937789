import type { ComponentProps } from 'react';
import React, { useRef } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { usePressableState, useResponsive } from '../../hooks';
import { ProductIngredientsList } from '../ProductIngredientsList';
import { BodyText } from '../Text';
import {
  LineItemQuantityStepper,
  LineItemThumbnail,
  QUANTITY_STEPPER_SIZE_SM,
  QUANTITY_STEPPER_SIZE_XS,
  THUMBNAIL_SIZE_SM,
  THUMBNAIL_SIZE_XS,
} from './subcomponents';

// ────────────────────────────────────────────────────────────────────────────

export const ProductLineItemCardV2 = (props: ProductLineItemCardProps) => {
  const {
    cloudinaryImageUrl = '',
    productName,
    quantity = 0,
    description = '',
    price,
    addedIngredients = [],
    removedIngredients = [],
    isCustom = false,
    isDisabled = false,
    showLabels = true,
    accessibilityLabel = 'Open product details',
    addLabel = 'Add',
    removeLabel = 'Remove',
    currentQuantityA11y = 'Current product quantity',
    increaseQuantityA11y = 'Increase product quantity',
    decreaseQuantityA11y = 'Decrease product quantity',
    onPress,
    onQuantityChangeStart,
    onQuantityChangeEnd,
    onQuantityChange,
  } = props;

  const { match } = useResponsive();

  const ref = useRef(null);
  const { isHovered } = usePressableState(ref);

  const shouldUseHoverStyle = !isDisabled && isHovered;
  const hoveredStyle = shouldUseHoverStyle && styles.hoverStyle;

  // ─── Labels ───────────────────────────────────────────────────────────────

  const addedIngredientsLabel = showLabels ? addLabel : '';
  const removedIngredientsLabel = showLabels ? removeLabel : '';

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <View>
      <Pressable
        ref={ref}
        disabled={isDisabled}
        style={[styles.container, hoveredStyle]}
        accessibilityRole="button"
        accessibilityLabel={accessibilityLabel}
        onPress={onPress}
      >
        {cloudinaryImageUrl ? (
          <LineItemThumbnail cloudinaryImageUrl={cloudinaryImageUrl} />
        ) : null}

        <View style={match([styles.detailsColumnXs, styles.detailsColumnSm])}>
          <BodyText size={4} sizeMatch={['18']} numberOfLines={2}>
            {productName}
          </BodyText>

          <ProductIngredientsList
            sizeMatch={['14']}
            shouldCapitalizeFirstIngredient={isCustom}
            addedIngredients={addedIngredients}
            removedIngredients={removedIngredients}
            addedIngredientsLabel={addedIngredientsLabel}
            removedIngredientsLabel={removedIngredientsLabel}
          />

          {description ? (
            <BodyText sizeMatch={['14']} style={styles.description}>
              {description}
            </BodyText>
          ) : null}

          <BodyText style={styles.price} sizeMatch={['14']}>
            {price}
          </BodyText>
        </View>
      </Pressable>

      <View style={match([styles.quantityStepperXs, styles.quantityStepperSm])}>
        <LineItemQuantityStepper
          quantity={quantity}
          isDisabled={isDisabled}
          currentQuantityA11y={currentQuantityA11y}
          increaseQuantityA11y={increaseQuantityA11y}
          decreaseQuantityA11y={decreaseQuantityA11y}
          onQuantityChangeStart={onQuantityChangeStart}
          onQuantityChangeEnd={onQuantityChangeEnd}
          onQuantityChange={onQuantityChange}
        />
      </View>
    </View>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: theme.spacing['1'],
    paddingVertical: theme.spacing['4'],
    gap: theme.spacing['3'],
  },
  hoverStyle: {
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
  detailsColumnXs: {
    flex: 1,
    gap: theme.spacing['1'],
    paddingBottom: QUANTITY_STEPPER_SIZE_XS,
  },
  detailsColumnSm: {
    flex: 1,
    gap: theme.spacing['1'],
    paddingBottom: QUANTITY_STEPPER_SIZE_SM,
  },
  description: {
    flex: 1,
    color: theme.colors.CHARCOAL,
    marginBottom: theme.spacing['1'],
  },
  price: {
    flex: 1,
    paddingBottom: theme.spacing['4'],
  },
  quantityStepperXs: {
    marginLeft: THUMBNAIL_SIZE_XS + theme.spacing['3'] + 2,
  },
  quantityStepperSm: {
    marginLeft: THUMBNAIL_SIZE_SM + theme.spacing['3'] + 2,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type ProductLineItemCardProps = Readonly<{
  productName: string;
  description?: string;
  cloudinaryImageUrl?: string;
  price?: string;
  quantity?: number;
  isDisabled?: boolean;
  isModifiable?: boolean;
  isCustom?: boolean;
  showLabels?: boolean;
  addedIngredients: Ingredients;
  removedIngredients: Ingredients;
  accessibilityLabel?: string;
  addLabel?: string;
  removeLabel?: string;
  currentQuantityA11y?: string;
  increaseQuantityA11y?: string;
  decreaseQuantityA11y?: string;
  onQuantityChangeStart: () => void;
  onQuantityChangeEnd: () => void;
  onQuantityChange: (selectedQuantity: number) => void;
  onPress: () => void;
}>;

type Ingredients = ComponentProps<
  typeof ProductIngredientsList
>['addedIngredients'];

import React from 'react';
import { FormattedDate } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, capitalizeFirstLetter, Icon } from '@sg/garnish';

export const LoyaltyPointsHistoryRow = (
  props: LoyaltyPointsHistoryRowProps,
) => {
  const { value, date, source, type, monthDivider } = props;

  if (monthDivider) {
    return (
      <View style={styles.monthDivider}>
        <BodyText sizeMatch={['14']}>{monthDivider}</BodyText>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.leftColumn}>
        <BodyText sizeMatch={['14']} style={styles.charcoalText}>
          <FormattedDate value={date} month="short" day="2-digit" />
        </BodyText>

        {type ? (
          <BodyText sizeMatch={['14']} style={styles.charcoalText}>
            {formatTypeSource(type)}
          </BodyText>
        ) : null}

        {source ? (
          <BodyText sizeMatch={['18']}>{formatTypeSource(source)}</BodyText>
        ) : null}
      </View>

      <View style={styles.rightColumn}>
        <BodyText
          style={value > 0 ? styles.spinachText : null}
          sizeMatch={['24']}
        >
          {withPositivePrefix(value)}
        </BodyText>

        <Icon
          width={16}
          height={16}
          name={value > 0 ? 'IconSgRewardSpinachFill' : 'IconSgRewardBlackFill'}
        />
      </View>
    </View>
  );
};

// ─── Helpers ────────────────────────────────────────────────────────────────

const withPositivePrefix = (points: number) =>
  points > 0 ? `+${points}` : `${points}`;

const formatTypeSource = (typeOrSource: string) => {
  return capitalizeFirstLetter(typeOrSource.replaceAll('_', ' ').toLowerCase());
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: theme.spacing['6'],
    gap: theme.spacing['4'],
  },
  monthDivider: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    paddingVertical: theme.spacing['3'],
    paddingHorizontal: theme.spacing['4'],
  },
  leftColumn: {
    gap: theme.spacing['1'],
  },
  rightColumn: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['1'],
  },
  charcoalText: {
    color: theme.colors.CHARCOAL,
  },
  spinachText: {
    color: theme.colors.SPINACH,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyPointsHistoryRowProps = {
  value: number;
  date: string;
  monthDivider?: string;
  source?: string | null;
  type?: string | null;
};

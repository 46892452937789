/* eslint-disable functional/immutable-data */

import { useCallback, useEffect, useRef } from 'react';

import { useApplePay } from './useApplePay';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A small wrapper hook for `useApplePay` to provide a promise-based API
 * for Apple Pay payments.
 */
export const useApplePayPromise = () => {
  const {
    applePayHandlersRef,
    applePayHostedFrameRef,
    handleApplePayMessages,
    sendShowPaymentSheetRequest,
    isApplePayReady,
    isApplePayDisabled,
    isSettingUpApplePay,
  } = useApplePay();

  // ─── Refs ────────────────────────────────────────────────────────────

  const paymentPromiseRef = useRef<PaymentPromiseCallbacks | null>(null);
  const promiseTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const resetPromiseTimeout = useCallback(() => {
    if (!promiseTimeoutRef.current) return;

    clearTimeout(promiseTimeoutRef.current);
    promiseTimeoutRef.current = null;
  }, []);

  const resetPaymentPromise = useCallback(() => {
    if (!paymentPromiseRef.current) return;

    paymentPromiseRef.current = null;
  }, []);

  const resolvePaymentPromise = useCallback(
    (payload: PaymentResolvedPayload) => {
      if (!paymentPromiseRef.current) return;

      paymentPromiseRef.current.resolve(payload);
    },
    [],
  );

  // ─── Payment Processor ───────────────────────────────────────────────

  const proceedPayment = useCallback(
    async (amount: number) => {
      return new Promise<PaymentResolvedPayload>((resolve) => {
        paymentPromiseRef.current = { resolve };
        promiseTimeoutRef.current = setTimeout(() => {
          resolve({ isSuccess: false, reason: 'timeout' });
        }, PAYMENT_TIMEOUT);

        sendShowPaymentSheetRequest(amount);
      });
    },
    [sendShowPaymentSheetRequest],
  );

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    applePayHandlersRef.current = {
      async onCapturePaymentMethodId(paymentMethodId) {
        resolvePaymentPromise({
          paymentMethodId,
          isSuccess: true,
        });
        resetPromiseTimeout();
        resetPaymentPromise();
      },
      onApplePayError() {
        resolvePaymentPromise({ isSuccess: false, reason: 'other' });
        resetPromiseTimeout();
        resetPaymentPromise();
      },
      onApplePayDismiss() {
        resolvePaymentPromise({
          isSuccess: false,
          reason: 'sheet-dismissed',
        });
        resetPromiseTimeout();
        resetPaymentPromise();
      },
    };

    return () => {
      resetPromiseTimeout();
      resetPaymentPromise();
    };
  }, [
    applePayHandlersRef,
    resetPaymentPromise,
    resetPromiseTimeout,
    resolvePaymentPromise,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    applePayHandlersRef,
    applePayHostedFrameRef,
    handleApplePayMessages,
    proceedPayment,
    isApplePayReady,
    isApplePayDisabled,
    isSettingUpApplePay,
  };
};

// ─── Constants ───────────────────────────────────────────────────────────────

const PAYMENT_TIMEOUT = 15_000;

// ─── Types ───────────────────────────────────────────────────────────────────

type PaymentResolvedSuccessPayload = {
  paymentMethodId: string;
  isSuccess: true;
};

type PaymentResolvedFailurePayload = {
  isSuccess: false;
  reason: PaymentPromiseRejectionReason;
};

type PaymentPromiseRejectionReason = 'sheet-dismissed' | 'other' | 'timeout';

type PaymentPromiseCallbacks = {
  resolve: (payload: PaymentResolvedPayload) => void;
};

export type PaymentResolvedPayload =
  | PaymentResolvedSuccessPayload
  | PaymentResolvedFailurePayload;

import React, { forwardRef } from 'react';
import type { DimensionValue, ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { BREAKPOINTS } from '@garnish/constants';

import { useFluidSize, useResponsive } from '../../hooks';

/**
 * Container - adds horizontal spacing between the edge of the device, and limits content to a max width
 */
export const Container = forwardRef<View, ContainerProps>((props, ref) => {
  const {
    children,
    style,
    wrapperStyle,
    testID = 'sg-container',
    size = 'large',
    customSpacing,
    removeSpacing,
    ...rest
  } = props;

  // ────────────────────────────────────────────────────────────────────

  const maxWidth = useContainerMaxWidth(size);
  const containerDefaultSpacing = useContainerSpacing();
  const containerSpacing = customSpacing ?? containerDefaultSpacing;
  const horizontalSpacing = useStyle(
    () => ({ paddingHorizontal: removeSpacing ? 0 : containerSpacing }),
    [removeSpacing, containerSpacing],
  );

  const contentWrapperStyle = [styles.wrapper, wrapperStyle];
  const containerStyles = [
    styles.container,
    removeSpacing ? null : maxWidth,
    horizontalSpacing,
    style,
  ];

  // ────────────────────────────────────────────────────────────────────

  return (
    <View style={contentWrapperStyle}>
      <View ref={ref} style={containerStyles} testID={testID} {...rest}>
        {children}
      </View>
    </View>
  );
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ContainerProps = Readonly<{
  size?: ContainerSize;
  wrapperStyle?: ViewProps['style'];
  removeSpacing?: boolean;
  customSpacing?: number;
}> &
  ViewProps;

type ContainerSize = 'medium' | 'large' | 'fluid';

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    alignItems: 'center',
  },
  container: {
    width: '100%',
  },
});

// ─── HELPERS ────────────────────────────────────────────────────────────────────

export const useContainerMaxWidth = (size: ContainerSize) => {
  const { currentBreakpoint } = useResponsive();

  return useStyle(
    () => ({
      maxWidth: currentBreakpoint.isXS
        ? containerSizeMapping.fluid
        : containerSizeMapping[size],
    }),
    [size, currentBreakpoint.isXS],
  );
};

export const useContainerSpacing = () => {
  const fluidSize = useFluidSize();

  return fluidSize(BREAKPOINTS[0].MARGIN, BREAKPOINTS[3].MARGIN);
};

// ────────────────────────────────────────────────────────────────────────────────

const containerSizeMapping: Record<ContainerSize, DimensionValue> = {
  medium: 518,
  large: 1520,
  fluid: '100%',
} as const;

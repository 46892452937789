import { useCallback, useRef } from 'react';
import type { NativeScrollEvent, NativeSyntheticEvent } from 'react-native';
import { Animated } from 'react-native';

/**
 * Returns `scrollDistance` animated value (with the corresponding setter function),
 * to use with `FloatingHeader` component.
 *
 * @example
 *
 * const [scrollDistance, setScrollDistance] = useFloatingHeaderScrollDistance();
 *
 * <View>
 *   <FloatingHeader scrollDistance={scrollDistance} />
 *   <ScrollView onScroll={setScrollDistance}>
 *     ...
 */
export const useFloatingHeaderScrollDistance = (): readonly [
  ScrollDistanceSharedValue,
  ScrollDistanceSharedValueSetter,
] => {
  const { current: scrollDistance } = useRef(new Animated.Value(0));

  const setScrollDistance = useCallback<ScrollDistanceSharedValueSetter>(
    (event) => {
      scrollDistance.setValue(event.nativeEvent.contentOffset.y);
    },
    [scrollDistance],
  );

  return [scrollDistance, setScrollDistance];
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ScrollDistanceSharedValue = Animated.Value;

type ScrollDistanceSharedValueSetter = (
  event: NativeSyntheticEvent<NativeScrollEvent>,
) => void;

import React from 'react';

import { TagLabel } from '../../../../../TagLabel';

// ─────────────────────────────────────────────────────────────────────────────

export const RadioContainerItemLabel = (
  props: RadioContainerItemLabelProps,
) => {
  const { children, isDisabled } = props;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <TagLabel size={3} palette={isDisabled ? 'secondary' : 'primary'}>
      {children}
    </TagLabel>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type RadioContainerItemLabelProps = Readonly<{
  children: string;
  isDisabled?: boolean;
}>;

import { useCallback, useState } from 'react';

export const useMenuContentMoreInfoModal = () => {
  const [isMoreInfoModalVisible, setIsMoreInfoModalVisible] = useState(false);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const showMoreInfoModal = useCallback(() => {
    setIsMoreInfoModalVisible(true);
  }, []);

  const hideMoreInfoModal = useCallback(() => {
    setIsMoreInfoModalVisible(false);
  }, []);

  // ─────────────────────────────────────────────────────────────────────

  return {
    isMoreInfoModalVisible,
    showMoreInfoModal,
    hideMoreInfoModal,
  };
};

import { useMemo } from 'react';

import { CreditType } from '@order/graphql';

import { type useCustomerCredit } from '../useCustomerCredit';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns filtered stipend credits and total amount based on the associated
 * feature flag.
 */
export const useStipendCredits = (
  params: UseStipendCreditsParams,
): UseStipendCreditsReturnType => {
  const { credits } = params;

  // ─────────────────────────────────────────────────────────────────────

  const { filteredCredits, totalCreditAmount } =
    useMemo<UseStipendCreditsReturnType>(() => {
      const creditsList = credits.filter(
        (credit) =>
          credit?.creditType === CreditType.InStoreOnly ||
          credit?.creditType === CreditType.OnlineOnly,
      );
      const totalAmount = creditsList.reduce(
        (total, currentCredit) => total + currentCredit.remainingAmount,
        0,
      );

      return { filteredCredits: creditsList, totalCreditAmount: totalAmount };
    }, [credits]);

  // ─────────────────────────────────────────────────────────────────────

  return { filteredCredits, totalCreditAmount };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseStipendCreditsParams = Readonly<{
  credits: ReturnType<typeof useCustomerCredit>['credits'];
  availableCredit: number;
}>;

type UseStipendCreditsReturnType = Readonly<{
  filteredCredits: ReturnType<typeof useCustomerCredit>['credits'];
  totalCreditAmount: number;
}>;

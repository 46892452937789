import type { ViewProps } from 'react-native';
import type { IconName } from '@sg/garnish';

import { TierName, TierStatusName } from '@order/graphql';

import type { AccountSweetpassBillingHistorySection } from '../../useSweetpassAccountContentfulData';
import type { GetSectionProps, SweetpassSectionProps } from '../types';

/**
 * We must query for `TransactionHistory` to show the billing history.
 * This is only needed if the customer opens the billing history modal.
 *
 * Rather than querying it on every membership page load, we check other flags.
 * - No paid tier history.
 * - Opted out.
 * - Free trial.
 */
export const getBillingHistorySection = (
  props: GetBillingHistorySectionProps,
): SweetpassBillingHistorySectionProps | undefined => {
  const {
    name,
    billingHistoryDataTitle,
    hasPaidTierHistory,
    tier,
    tierStatus,
    viewBillingHistoryHandler,
    formatMessage,
  } = props;

  const shouldNotHaveTransactions =
    !hasPaidTierHistory ||
    tier?.name === TierName.OptedOut ||
    tierStatus === TierStatusName.FreeTrial;

  if (shouldNotHaveTransactions) return;

  return {
    name,
    title: billingHistoryDataTitle,
    viewItem: {
      icon: 'IconClock',
      title: formatMessage(
        'account.sweetpass-membership.billing-history.view-item.title',
      ),
      accessibilityLabel: formatMessage(
        'account.sweetpass-membership.billing-history.view-item.accessibility-label',
      ),
      accessibilityHint: formatMessage(
        'account.sweetpass-membership.billing-history.view-item.accessibility-hint',
      ),
      handler: viewBillingHistoryHandler,
    },
  };
};

type GetBillingHistorySectionProps = Readonly<{
  viewBillingHistoryHandler: () => void;
}> &
  AccountSweetpassBillingHistorySection &
  GetSectionProps;

type SweetpassBillingHistoryViewItem = Readonly<{
  icon: IconName;
  title: string;
  accessibilityLabel: string;
  accessibilityHint: string;
  handler: () => void;
}> &
  ViewProps;

export type SweetpassBillingHistorySectionProps = Readonly<{
  name: string;
  title: string;
  viewItem: SweetpassBillingHistoryViewItem;
}> &
  SweetpassSectionProps;

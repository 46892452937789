import React, { type ComponentProps } from 'react';
import { TitleText } from '@sg/garnish';

export const HowItWorksCardTitle = (props: HowItWorksCardTitleProps) => {
  const { children, style } = props;
  return (
    <TitleText sizeMatch={['48']} family="Grenette Pro" style={style}>
      {children}
    </TitleText>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type HowItWorksCardTitleProps = ComponentProps<typeof TitleText>;

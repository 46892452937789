import { defineMessages } from 'react-intl';

export const giftCardsScreenHeaderMessages = defineMessages({
  heading: {
    defaultMessage: 'Send a sweet gift',
    description: 'Gift Cards Screen | Heading',
  },
  subheading: {
    defaultMessage: 'Have a gift card?',
    description: 'Gift Cards Screen | Subheading',
  },
  redeemNowLink: {
    defaultMessage: 'Redeem now',
    description: 'Gift Cards Screen | Redeem now',
  },
});

import React from 'react';
import { ScrollViewWithHeaderTitle, useResponsive } from '@sg/garnish';

import { AvailableCredit, LoadingAnimation } from '@order/components';
import { useCustomerCredit, useStipendCredits } from '@order/hooks';

import type { CreditDetailsScreenProps } from '../../navigation/AppNavigation.props';

export const CreditDetailsScreen = ({
  navigation,
}: CreditDetailsScreenProps) => {
  const { currentBreakpoint } = useResponsive();
  const { availableCredit, credits, isLoadingCredits } = useCustomerCredit();
  const { filteredCredits, totalCreditAmount } = useStipendCredits({
    credits,
    availableCredit,
  });

  React.useLayoutEffect(() => {
    if (currentBreakpoint.isXS) return;
    navigation.getParent()?.goBack();
  }, [navigation, currentBreakpoint.isXS]);

  if (isLoadingCredits) return <LoadingAnimation />;

  return (
    <ScrollViewWithHeaderTitle testID="account.credit.root.container">
      <AvailableCredit
        creditAmount={totalCreditAmount}
        // @ts-expect-error TS(2322): Type 'readonly { readonly __typename?: "Credit" | ... Remove this comment to see the full error message
        creditDetails={filteredCredits}
      />
    </ScrollViewWithHeaderTitle>
  );
};

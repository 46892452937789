import { defineMessages } from 'react-intl';

export const orderAgainEmptyViewMessages = defineMessages({
  ordersEmptyViewText: {
    defaultMessage:
      'Once you place an order your recent orders will appear here',
    description: 'Order again | Empty View | Orders | Text',
  },
  ordersEmptyViewIllustrationAlt: {
    defaultMessage: 'A person holding SG bag',
    description:
      'Order again | Empty View | Orders | Illustration alternative text',
  },
  favoritesEmptyViewText: {
    defaultMessage:
      'Once you favorite a product your favorites will appear here',
    description: 'Order again | Empty View | Favorites | Text',
  },
  favoritesEmptyViewIllustrationAlt: {
    defaultMessage: 'A person standing below a tall plant with a missing fruit',
    description:
      'Order again | Empty View | Favorites | Illustration alternative text',
  },
});

import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive } from '@sg/garnish';

export const CreditCardFormLineItem = (
  props: Pick<ViewProps, 'style' | 'children'>,
) => {
  const { style, children } = props;

  const { match } = useResponsive();
  const responsiveStyle = match([styles.lineItemXs, styles.lineItemMd]);

  return <View style={[responsiveStyle, style]}>{children}</View>;
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  lineItemXs: {
    paddingHorizontal: theme.spacing['4'],
  },
  lineItemMd: {
    paddingHorizontal: theme.spacing['6'],
  },
});

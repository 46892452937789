/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RestaurantDetailsFragment = {
  readonly __typename?: 'Restaurant';
  readonly id: string;
  readonly name: string;
  readonly slug: string;
  readonly phone: string;
  readonly flexMessage: string | null;
  readonly deliveryFee: number;
};

export type DeliveryLocationByAddressQueryVariables = Types.Exact<{
  input: Types.LocationByAddressInput;
}>;

export type DeliveryLocationByAddressQuery = {
  readonly __typename?: 'Query';
  readonly locationByAddress:
    | { readonly __typename: 'InvalidInput' }
    | {
        readonly __typename: 'Location';
        readonly status: Types.LocationStatus;
        readonly restaurant: {
          readonly __typename?: 'Restaurant';
          readonly id: string;
          readonly name: string;
          readonly slug: string;
          readonly phone: string;
          readonly flexMessage: string | null;
          readonly deliveryFee: number;
        };
        readonly estimateRange: {
          readonly __typename?: 'EstimateRange';
          readonly start: number;
          readonly end: number;
        };
        readonly vendor: {
          readonly __typename?: 'Vendor';
          readonly name: string;
          readonly restaurantId: string;
        };
      }
    | { readonly __typename: 'NoValidRestaurants' }
    | { readonly __typename: 'ValidationError' };
};

export type DeliveryLocationByAddressWithDisclosureFieldsQueryVariables =
  Types.Exact<{
    input: Types.LocationByAddressInput;
  }>;

export type DeliveryLocationByAddressWithDisclosureFieldsQuery = {
  readonly __typename?: 'Query';
  readonly locationByAddress:
    | { readonly __typename: 'InvalidInput' }
    | {
        readonly __typename: 'Location';
        readonly status: Types.LocationStatus;
        readonly restaurant: {
          readonly __typename?: 'Restaurant';
          readonly showDeliveryPriceDifferenciationDisclosure: boolean;
          readonly id: string;
          readonly name: string;
          readonly slug: string;
          readonly phone: string;
          readonly flexMessage: string | null;
          readonly deliveryFee: number;
        };
        readonly estimateRange: {
          readonly __typename?: 'EstimateRange';
          readonly start: number;
          readonly end: number;
        };
        readonly vendor: {
          readonly __typename?: 'Vendor';
          readonly name: string;
          readonly restaurantId: string;
        };
      }
    | { readonly __typename: 'NoValidRestaurants' }
    | { readonly __typename: 'ValidationError' };
};

export const RestaurantDetailsFragmentDoc = gql`
  fragment RestaurantDetails on Restaurant {
    id
    name
    slug
    phone
    flexMessage
    deliveryFee
  }
`;
export const DeliveryLocationByAddressDocument = gql`
  query DeliveryLocationByAddress($input: LocationByAddressInput!) {
    locationByAddress(input: $input) {
      __typename
      ... on Location {
        restaurant {
          ...RestaurantDetails
        }
        estimateRange {
          start
          end
        }
        vendor {
          name
          restaurantId
        }
        status
      }
    }
  }
  ${RestaurantDetailsFragmentDoc}
`;

export function useDeliveryLocationByAddressQuery(
  options: Omit<
    Urql.UseQueryArgs<DeliveryLocationByAddressQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    DeliveryLocationByAddressQuery,
    DeliveryLocationByAddressQueryVariables
  >({ query: DeliveryLocationByAddressDocument, ...options });
}
export const DeliveryLocationByAddressWithDisclosureFieldsDocument = gql`
  query DeliveryLocationByAddressWithDisclosureFields(
    $input: LocationByAddressInput!
  ) {
    locationByAddress(input: $input) {
      __typename
      ... on Location {
        restaurant {
          ...RestaurantDetails
          showDeliveryPriceDifferenciationDisclosure
        }
        estimateRange {
          start
          end
        }
        vendor {
          name
          restaurantId
        }
        status
      }
    }
  }
  ${RestaurantDetailsFragmentDoc}
`;

export function useDeliveryLocationByAddressWithDisclosureFieldsQuery(
  options: Omit<
    Urql.UseQueryArgs<DeliveryLocationByAddressWithDisclosureFieldsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    DeliveryLocationByAddressWithDisclosureFieldsQuery,
    DeliveryLocationByAddressWithDisclosureFieldsQueryVariables
  >({
    query: DeliveryLocationByAddressWithDisclosureFieldsDocument,
    ...options,
  });
}

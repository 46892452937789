/* eslint-disable react-native/no-unused-styles */

import type { ComponentProps, ReactElement } from 'react';
import React from 'react';
import type { TextStyle, ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { LabelText } from '../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const TagLabel = (props: TagLabelProps): ReactElement => {
  const { palette = 'primary', size = 2, children, ...textProps } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [styles.container, containerPaletteStyles[palette]];
  const textStyles = [
    styles.uppercasePadding,
    styles.text,
    textPaletteStyles[palette],
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles}>
      <LabelText size={size} style={textStyles} {...textProps}>
        {children}
      </LabelText>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  /**
   * This small padding top is necessary because of the way fonts work.
   * When we have all uppercase characters, like on tags, we need it so it looks centered.
   * @see {@link https://sweetgreen.atlassian.net/browse/CELS-798?focusedCommentId=102402}
   */
  uppercasePadding: {
    paddingTop: 1,
  },

  container: {
    height: 24,
    paddingHorizontal: theme.spacing['2'],
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.radius.small,
  },
  text: {
    textAlign: 'center',
  },
});

const containerPaletteStyles = StyleSheet.create<
  Record<TagLabelPalette, ViewStyle>
>({
  primary: {
    backgroundColor: theme.colors.LIME,
  },
  secondary: {
    backgroundColor: theme.colors.OPACITY.TRANSPARENT,
    borderWidth: 1,
    borderColor: theme.colors.CHARCOAL,
  },
  tertiary: {
    backgroundColor: theme.colors.LIGHT,
  },
  light: {
    backgroundColor: theme.colors.WHITE,
  },
  kiwi: {
    backgroundColor: theme.colors.KIWI,
  },
});

const textPaletteStyles = StyleSheet.create<Record<TagLabelPalette, TextStyle>>(
  {
    primary: {
      color: theme.colors.DARK_KALE,
    },
    secondary: {
      color: theme.colors.DARK_KALE,
    },
    tertiary: {},
    light: {
      color: theme.colors.KALE,
    },
    kiwi: {
      color: theme.colors.DARK_KALE,
    },
  },
);

// ─── Types ───────────────────────────────────────────────────────────────────

export type TagLabelProps = Readonly<{
  size?: ComponentProps<typeof LabelText>['size'];
  palette?: TagLabelPalette;
}> &
  Omit<ComponentProps<typeof LabelText>, 'size' | 'style'>;

export type TagLabelPalette =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'light'
  | 'kiwi';

/* istanbul ignore file */

import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Button,
  HStack,
  Illus404,
  Image,
  Modal,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type { DeliveryLocationWithoutNearbyStore } from '../../../../../../machine';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationSearchDeliveryResultWithoutNearbyStore = memo(
  (props: LocationSearchDeliveryResultWithoutNearbyStoreProps) => {
    const { match } = useResponsive();

    const ResponsiveComponent = match([
      LocationSearchDeliveryResultWithoutNearbyStoreXS,
      LocationSearchDeliveryResultWithoutNearbyStoreSM,
    ]);

    return <ResponsiveComponent {...props} />;
  },
);

// ─── Responsive Components ───────────────────────────────────────────────────

export const LocationSearchDeliveryResultWithoutNearbyStoreXS = memo(
  (props: LocationSearchDeliveryResultWithoutNearbyStoreProps) => {
    const {
      deliveryLocation,
      onTryNewAddressBtnPress,
      onChangeToPickupSearchTypeBtnPress,
    } = props;

    const { t } = useLocalizationContext();

    return (
      <View style={StyleSheet.absoluteFill}>
        <Modal
          visible
          accessibilityLabel={t('delivery.unavailable-mobile')}
          size="small"
          animationType="fade"
        >
          <View style={styles.modalContent}>
            <Modal.Headline style={styles.modalHeading}>
              {t('delivery.unavailable-mobile')}
            </Modal.Headline>

            <Modal.BodyText>{deliveryLocation.address}</Modal.BodyText>

            <Modal.Footer withoutTopBorder>
              <HStack itemsPerRow={2} gap={theme.spacing['4']}>
                <Button
                  size="large"
                  palette="secondary"
                  onPress={onTryNewAddressBtnPress}
                >
                  {t('general.close')}
                </Button>

                <Button
                  size="large"
                  onPress={onChangeToPickupSearchTypeBtnPress}
                >
                  {t('delivery.order-pickup')}
                </Button>
              </HStack>
            </Modal.Footer>
          </View>
        </Modal>
      </View>
    );
  },
);

export const LocationSearchDeliveryResultWithoutNearbyStoreSM = memo(
  (props: LocationSearchDeliveryResultWithoutNearbyStoreProps) => {
    const {
      deliveryLocation,
      onTryNewAddressBtnPress,
      onChangeToPickupSearchTypeBtnPress,
    } = props;

    const { t } = useLocalizationContext();

    return (
      <View style={styles.container}>
        <Image
          source={Illus404}
          style={styles.illustration}
          contentFit="contain"
        />

        <BodyText size={3} bold style={styles.address}>
          {deliveryLocation.address}
        </BodyText>

        <BodyText size={3} style={styles.text}>
          {t('delivery.unavailable')}
        </BodyText>

        <HStack itemsPerRow={2} gap={theme.spacing['4']}>
          <Button
            size="large"
            palette="secondary"
            style={styles.cta}
            onPress={onTryNewAddressBtnPress}
          >
            {t('delivery.new-address')}
          </Button>

          <Button
            size="large"
            style={styles.cta}
            onPress={onChangeToPickupSearchTypeBtnPress}
          >
            {t('delivery.order-pickup')}
          </Button>
        </HStack>
      </View>
    );
  },
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingHorizontal: theme.spacing['2'],
    paddingBottom: theme.spacing['6'],
  },
  illustration: {
    width: 200,
    height: 200,
  },
  address: {
    textAlign: 'center',
    marginVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['10'],
  },
  text: {
    textAlign: 'center',
    marginBottom: theme.spacing['6'],
    paddingHorizontal: theme.spacing['6'],
  },
  cta: {
    paddingHorizontal: theme.spacing['2'],
  },
  modalContent: {
    paddingTop: theme.spacing['6'],
    paddingHorizontal: theme.spacing['2'],
  },
  modalHeading: {
    marginBottom: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationSearchDeliveryResultWithoutNearbyStoreProps = Readonly<{
  deliveryLocation: DeliveryLocationWithoutNearbyStore;
  onTryNewAddressBtnPress: () => void;
  onChangeToPickupSearchTypeBtnPress: () => void;
}>;

import { useOrdersQuery } from '../GraphQL/Orders.generated';
import { ORDERS_QUERY_VARIABLES } from './constants';

/**
 * Checks whether or not there are orders currently in cache.
 * This will not make any network requests.
 */
export const useHasOrdersInCache = () => {
  const [response] = useOrdersQuery({
    variables: ORDERS_QUERY_VARIABLES,
    requestPolicy: 'cache-only',
  });

  const ordersResponseData = response.data?.orders;
  const isSuccess = ordersResponseData?.__typename === 'OrdersResponseSuccess';
  const orders = isSuccess ? ordersResponseData.orders : [];

  return { hasOrdersInCache: orders.length > 0 };
};

import React, { useCallback, useMemo } from 'react';
import {
  defineMessages,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from 'react-intl';
import { StyleSheet } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import {
  Button,
  openNativeShare,
  ProductCardV2,
  ShareIcon,
  theme,
} from '@sg/garnish';

import { type PartialLineItem } from '@order/graphql';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';
import {
  useLineItemDescription,
  useLineItemIngredients,
  useLineItemName,
} from '@order/shared/hooks';
import { getHostName } from '@order/utils';

import { useReorderState } from '../../screens/ReorderingScreen/hooks';
import { useDietaryRestrictions } from '../DietaryRestrictions';
import { ProductCard } from '../ProductCard';

export const SharedLineItem = ({
  lineItem,
  onAddToBag,
  onFavorite,
}: SharedLineItemProps) => {
  const {
    id,
    slug,
    quantity,
    product,
    removedIngredients,
    addedIngredients,
    favorited = false,
  } = lineItem;

  const { t, formatPrice, formatNumber } = useLocalizationContext();
  const getRestrictionNotice = useDietaryRestrictions();
  const { reordering, lineItemId } = useReorderState();
  const { formatMessage } = useIntl();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isMenuRedesignEnabled = useFeatureFlag(
    'CELS-2852-redesign-menu-enabled',
  );
  const shouldHidePriceOnFavoritesAndShare = useFeatureFlag(
    'CELS-2569-hide-price-on-favorites-and-share',
    { listenForChanges: true },
  );

  const shouldDisableAddToBag = reordering
    ? Number(id) !== Number(lineItemId)
    : undefined;
  const isAddingToBag = reordering
    ? Number(id) === Number(lineItemId)
    : undefined;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleShare = useCallback(() => {
    const nativeText = t('general.share-favorite', {
      productName: product.name,
    });
    const url = `${getHostName()}/share/${slug}`;

    void openNativeShare({
      message: nativeText,
      url,
      options: {
        subject: nativeText,
      },
    });
    void Clipboard.setStringAsync(url);
  }, [t, product.name, slug]);

  // ─────────────────────────────────────────────────────────────────────

  const productName = useLineItemName(product, lineItem);
  const productCostInDollars = shouldHidePriceOnFavoritesAndShare
    ? undefined
    : (product.cost ?? 0) / 100;

  const lineItemIngredients = useLineItemIngredients({
    product,
    addedIngredients,
    removedIngredients,
  });
  const lineItemDescription = useLineItemDescription(lineItem);

  const quantityLabel = t('account.favorites.quantity', {
    quantity,
  });
  const restrictionNotice = getRestrictionNotice(product.dietaryProperties);

  const price = shouldHidePriceOnFavoritesAndShare
    ? undefined
    : formatPrice(product.cost ?? 0, 'USD');

  const description = useMemo(() => {
    const { addedIngredientsText, removedIngredientsText } =
      lineItemIngredients;

    const hasModifications =
      typeof addedIngredientsText === 'string' ||
      typeof removedIngredientsText === 'string';

    if (!hasModifications) {
      return product?.description;
    }

    return [addedIngredientsText, removedIngredientsText]
      .filter(Boolean)
      .join('\n');
  }, [lineItemIngredients, product?.description]);

  const favToggleAccessibilityLabel = favorited
    ? formatMessage(messages.removeFromFavorites, { product: productName })
    : formatMessage(messages.addToFavorites, { product: productName });

  // ─── New Version ─────────────────────────────────────────────────────

  if (isMenuRedesignEnabled) {
    return (
      <ProductCardV2.Container canHover={false}>
        {onFavorite ? (
          <ProductCardV2.FavoriteToggle
            isActive={favorited}
            onPress={onFavorite}
            accessibilityLabel={favToggleAccessibilityLabel}
          />
        ) : null}

        {product.asset?.url ? (
          <ProductCardV2.Image
            source={product.asset.url}
            alt={product.name}
            isUnavailable={product.outOfStock}
          />
        ) : null}

        <ProductCardV2.LabelContainer>
          {product.label?.name ? (
            <ProductCardV2.Label>{product.label.name}</ProductCardV2.Label>
          ) : null}
        </ProductCardV2.LabelContainer>

        <ProductCardV2.Heading>{productName}</ProductCardV2.Heading>

        {description ? (
          <ProductCardV2.Description>{description}</ProductCardV2.Description>
        ) : null}

        {lineItemDescription ? (
          <ProductCardV2.Description>
            {lineItemDescription}
          </ProductCardV2.Description>
        ) : null}

        <ProductCardV2.Footer>
          <ProductCardV2.ProductDetailsContainer>
            {productCostInDollars ? (
              <ProductCardV2.ProductDetail palette="dark-kale">
                <FormattedNumber
                  value={productCostInDollars}
                  // eslint-disable-next-line react/style-prop-object
                  style="currency"
                  currency="USD"
                />
              </ProductCardV2.ProductDetail>
            ) : null}

            {product.calories === undefined ? null : (
              <ProductCardV2.ProductDetail>
                <FormattedMessage
                  {...messages.caloriesText}
                  values={{ calories: product.calories ?? 0 }}
                />
              </ProductCardV2.ProductDetail>
            )}
          </ProductCardV2.ProductDetailsContainer>

          {restrictionNotice ? (
            <ProductCardV2.AllergenNotice>
              {restrictionNotice}
            </ProductCardV2.AllergenNotice>
          ) : null}
        </ProductCardV2.Footer>

        <ProductCardV2.ButtonsContainer>
          {onAddToBag ? (
            <ProductCardV2.AddToBagButton
              disabled={shouldDisableAddToBag}
              isLoading={isAddingToBag}
              onPress={onAddToBag}
            >
              <FormattedMessage {...messages.addToBag} />
            </ProductCardV2.AddToBagButton>
          ) : null}

          {slug ? <ShareIcon onShare={handleShare} /> : null}
        </ProductCardV2.ButtonsContainer>
      </ProductCardV2.Container>
    );
  }

  // ─── Legacy Version ──────────────────────────────────────────────────

  return (
    <ProductCard
      cloudinaryImageSrc={product.asset?.url ?? ''}
      restrictionNotice={restrictionNotice}
      name={productName}
      addedIngredientsLabel={t('product-added-ingredients-label')}
      addedIngredients={addedIngredients}
      removedIngredientsLabel={t('product-removed-ingredients-label')}
      removedIngredients={removedIngredients}
      description={lineItemDescription}
      quantity={quantityLabel}
      label={product.label?.name}
      price={price}
      calories={`${formatNumber(product.calories ?? 0)} cal`}
      onFavorite={onFavorite}
      favorited={favorited}
      onShare={slug ? handleShare : undefined}
      FooterElement={
        onAddToBag ? (
          <Button
            testID="shared-line-item.add-to-bag"
            palette="primary"
            width="100%"
            accessibilityRole="button"
            accessibilityLabel={t('account.favorites.add-to-bag')}
            accessibilityHint={t('account.favorites.add-to-bag')}
            style={styles.addToBagButton}
            disabled={shouldDisableAddToBag}
            isLoading={isAddingToBag}
            onPress={onAddToBag}
          >
            {t('account.favorites.add-to-bag')}
          </Button>
        ) : null
      }
    />
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  caloriesText: {
    defaultMessage: '{calories} cals',
    description: 'Shared lint item | Product card | Calories',
  },
  addToFavorites: {
    defaultMessage: 'Add "{product}" to favorites',
    description: 'Shared lint item | Add to favorites',
  },
  removeFromFavorites: {
    defaultMessage: 'Remove "{product}" from favorites',
    description: 'Shared lint item | Remove from favorites',
  },
  addToBag: {
    defaultMessage: 'Add to bag',
    description: 'Shared lint item | Add to bag',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type SharedLineItemProps = Readonly<{
  lineItem: PartialLineItem;
  onAddToBag?: () => void;
  onFavorite?: () => Promise<void>;
}>;

const styles = StyleSheet.create({
  addToBagButton: {
    marginTop: theme.spacing['4'],
  },
});

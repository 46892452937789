import type { RefObject } from 'react';
import type { View } from 'react-native';

import type {
  ScrollDetectionParams,
  ScrollDetectionResult,
  StickingParams,
} from './Sticky.types';

export const stickComponent = async ({
  lineRef,
  compRef,
  sticky,
  height,
  offset,
  setStickyComponentHeight,
  setSticky,
}: StickingParams): Promise<void> => {
  const { hasScrolledAfterSticky, hasScrolledBeforeSticky } =
    await detectScroll({
      lineRef,
      sticky,
      height,
      offset,
    });

  // Sticking Component
  if (hasScrolledAfterSticky) {
    const measuredHeight = await measureHeight(compRef);

    setStickyComponentHeight(measuredHeight);
    setSticky(true);
  }

  // Unsticking Component
  if (hasScrolledBeforeSticky) {
    setSticky(false);
  }
};

const measureHeight = async (reference: RefObject<View>): Promise<number> => {
  return new Promise((resolve) => {
    const component = reference.current;

    if (!component) {
      resolve(0);

      return;
    }

    component.measure((_x, _y, _w, height) => {
      resolve(height);
    });
  });
};

export const measurePagePosition = async (
  reference: RefObject<View>,
): Promise<number> => {
  return new Promise((resolve) => {
    const component = reference.current;

    if (!component) {
      resolve(0);

      return;
    }

    component.measure((_x, _y, _w, _h, _px, pageY) => {
      resolve(pageY);
    });
  });
};

const detectScroll = async ({
  lineRef,
  sticky,
  height,
  offset,
}: ScrollDetectionParams): Promise<ScrollDetectionResult> => {
  const scrollPosition = await measurePagePosition(lineRef);
  const stickyComponentOffset = sticky ? height : 0;
  const scrollOffset = scrollPosition - stickyComponentOffset;
  const containerOffset = offset || 0;

  return {
    hasScrolledAfterSticky: scrollOffset < containerOffset && !sticky,
    hasScrolledBeforeSticky: scrollOffset > containerOffset && sticky,
  };
};

import {
  interpolateColor,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';

// Use this hook to animate background color transitions on hover/press.
export const useActiveAnimation = (
  isActive: boolean,
  unpressedColor: string,
  pressedColor: string,
) => {
  const animatedValue = useDerivedValue(() => {
    return withTiming(isActive ? 1 : 0, { duration: 200 });
  }, [isActive]);

  return useAnimatedStyle(() => {
    const backgroundColor = interpolateColor(
      animatedValue.value,
      [0, 1],
      [unpressedColor, pressedColor],
    );

    return { backgroundColor };
  }, [unpressedColor, pressedColor]);
};

import type { ReactElement } from 'react';
import React from 'react';
import { Platform, StyleSheet, Text } from 'react-native';
import { useStyle } from 'react-native-style-utilities';

import { Icon } from '../../Icon';
import type { ButtonIconProps } from '../Button.types';
import { useIconStyles } from '../styles';

export const BUTTON_ICON_TEST_ID = 'button-icon';

export const ButtonIcon = (props: ButtonIconProps): ReactElement | null => {
  const {
    centerIcon,
    leftIcon,
    rightIcon,
    palette,
    size,
    hasDisabledStyle,
    uiEventState,
  } = props;

  const useStyleState = useIconStyles(palette, size);
  const styledState = useStyleState(hasDisabledStyle, uiEventState);
  const flatStyle = StyleSheet.flatten(styledState) as IconProps;
  // @ts-expect-error TS(2339): Property 'width' does not exist on type 'StyleProp... Remove this comment to see the full error message
  const { width, color } = useStyle(() => flatStyle, [flatStyle]);
  const name = centerIcon ?? leftIcon ?? rightIcon;
  // eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10
  const positionStyle = centerIcon ? {} : leftIcon ? styles.left : styles.right;
  const style = [styles.button, styledState, positionStyle];

  if (!name) return null;

  return (
    <Text testID={BUTTON_ICON_TEST_ID} style={style}>
      <Icon name={name} color={color} width={width} />
    </Text>
  );
};

type IconProps = Readonly<{
  width: string;
  color: string;
}>;

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: Platform.OS === 'ios' ? 20 : 24,
  },
  left: {
    marginRight: 4,
  },
  right: {
    marginLeft: 4,
  },
});

import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { type ViewProps } from 'react-native';
import type { NotificationClickEvent } from 'react-native-onesignal';

import {
  addDataTag,
  initOneSignal,
  OneSignal as client,
  type OneSignalUser,
  removeDataTag,
  resetOneSignalUserSession as resetCustomer,
  startOneSignalUserSession as startCustomerSession,
} from './client';

// ────────────────────────────────────────────────────────────────────────────────

const OneSignalContext = createContext<OneSignalContextType | undefined>(
  undefined,
);

/**
 * One Signal (@see https://www.onesignal.com/) provider component.
 */
export const OneSignalProvider = (props: Pick<ViewProps, 'children'>) => {
  const { current: notificationOpenedEventHandlers } =
    useRef<NotificationOpenedEventHandlers>(new Set());

  // ─── Helpers ─────────────────────────────────────────────────────────

  /**
   * Handles opened push notifications by forwarding them to the stored event listeners.
   */
  const handleNotificationOpenedEvent = useCallback(
    (event: NotificationClickEvent) => {
      if (!notificationOpenedEventHandlers) return;

      // eslint-disable-next-line unicorn/no-array-for-each
      notificationOpenedEventHandlers.forEach((handler) => {
        handler(event);
      });
    },
    [notificationOpenedEventHandlers],
  );

  const init = useCallback(() => {
    (async () => {
      await initOneSignal(handleNotificationOpenedEvent);
    })();
  }, [handleNotificationOpenedEvent]);

  const syncCustomer = useCallback((customer: OneSignalUser) => {
    (async () => {
      if (!customer.id) return;

      await startCustomerSession(customer);
    })();
  }, []);

  const addNotificationOpenedEventListener =
    useCallback<NotificationOpenedEventListener>(
      (handler) => {
        if (notificationOpenedEventHandlers.has(handler)) return;

        notificationOpenedEventHandlers.add(handler);
      },
      [notificationOpenedEventHandlers],
    );

  const removeNotificationOpenedEventListener =
    useCallback<NotificationOpenedEventListener>(
      (handler) => {
        notificationOpenedEventHandlers.delete(handler);
      },
      [notificationOpenedEventHandlers],
    );

  // ─────────────────────────────────────────────────────────────────────

  const value = useMemo(
    () => ({
      client,
      init,
      syncCustomer,
      resetCustomer,
      addNotificationOpenedEventListener,
      removeNotificationOpenedEventListener,
      addDataTag,
      removeDataTag,
    }),
    [
      addNotificationOpenedEventListener,
      init,
      removeNotificationOpenedEventListener,
      syncCustomer,
    ],
  );

  return (
    <OneSignalContext.Provider value={value}>
      {props.children}
    </OneSignalContext.Provider>
  );
};

/**
 * One Signal (@see https://www.onesignal.com/) client hook.
 * Returns platform specific client and initialized state boolean for future use.
 *
 * ⚠️ Note that the clients APIs can have some differences in case of Web and React Native!
 *    You can use abstract wrapper functions to handle those cases.
 *
 * @example
 * const { client as oneSignalClient } = useOneSignal();
 * const oneSignalUserId = oneSignalClient.getExternalUserId();
 */
export const useOneSignal = () => {
  const context = useContext(OneSignalContext);

  if (context === undefined) {
    throw new Error('useOneSignal must be used within a <OneSignalProvider>');
  }

  return context;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type OneSignalContextType = {
  client: typeof client | undefined;
  init: () => void;
  syncCustomer: (customer: OneSignalUser) => void;
  resetCustomer: () => void;
  addNotificationOpenedEventListener: NotificationOpenedEventListener;
  removeNotificationOpenedEventListener: NotificationOpenedEventListener;
  addDataTag: typeof addDataTag;
  removeDataTag: typeof removeDataTag;
};

type NotificationOpenedEventListener = (
  handler: NotificationOpenedEventHandler,
) => void;

type NotificationOpenedEventHandlers = Set<NotificationOpenedEventHandler>;

type NotificationOpenedEventHandler = (event: NotificationClickEvent) => void;

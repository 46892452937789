import type { ComponentProps } from 'react';
import { theme } from '@garnish/constants';
import type { Button } from '@sg/garnish';

import type { TierName } from '@order/graphql';

export type SweetpassHeroTierName = TierName.Sweetpass | TierName.SweetpassPlus;

type SweetpassHeroStatsPalettes = {
  readonly [Key in SweetpassHeroTierName]: SweetpassHeroStatsPalette;
};

export type SweetpassHeroStatsPalette = Readonly<{
  backgroundColor: string;
  dividerColor: string;
  textColor: string;
  ctaPalette: ButtonPalette;
}>;

export const getSweetpassHeroStatsPaletteForTag = (
  tag: SweetpassHeroTierName | undefined,
) =>
  tag
    ? SWEETPASS_HERO_STATS_PALETTES[tag]
    : SWEETPASS_HERO_STATS_PALETTES.SWEETPASS;

const SWEETPASS_HERO_STATS_PALETTES: SweetpassHeroStatsPalettes = {
  SWEETPASS: {
    backgroundColor: theme.colors.GREEN_5,
    dividerColor: theme.colors.GREEN_4,
    textColor: theme.colors.GREEN_2,
    ctaPalette: 'primary',
  },
  SWEETPASS_PLUS: {
    backgroundColor: theme.colors.GREEN_2,
    dividerColor: theme.colors.GREEN_3,
    textColor: theme.colors.GREEN_6,
    ctaPalette: 'lime',
  },
};

type ButtonPalette = NonNullable<ComponentProps<typeof Button>['palette']>;

import { useResponsive } from '../../../../hooks';
import {
  HERO_CARD_HEIGHT_MD,
  HERO_CARD_HEIGHT_SM,
  HERO_CARD_HEIGHT_XS,
} from '../../HeroCard.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const useHeroCardResponsiveHeight = () => {
  const { match } = useResponsive();

  return match(HERO_CARD_RESPONSIVE_HEIGHT);
};

// ─── Constants ───────────────────────────────────────────────────────────────

const HERO_CARD_RESPONSIVE_HEIGHT = [
  HERO_CARD_HEIGHT_XS,
  HERO_CARD_HEIGHT_SM,
  HERO_CARD_HEIGHT_SM,
  HERO_CARD_HEIGHT_MD,
];

/* eslint-disable functional/immutable-data */

import React, { type PropsWithChildren, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { ANIMATED_DIALOG_ANIMATION_CONFIG, theme } from '@sg/garnish';

/**
 * Animated wrapper for the stripe hosted field.
 */
export const BagStripeOpacityAnimation = (
  props: PropsWithChildren & { show: boolean },
) => {
  const { show, children } = props;

  const opacitySV = useSharedValue(0);

  const hiddenStyle = show ? null : styles.hidden;
  const opacityStyle = useAnimatedStyle(() => {
    return { opacity: opacitySV.value };
  }, []);

  useEffect(() => {
    opacitySV.value = withTiming(
      show ? 1 : 0,
      ANIMATED_DIALOG_ANIMATION_CONFIG,
    );
  }, [opacitySV, show]);

  return (
    <Animated.View style={[styles.container, hiddenStyle, opacityStyle]}>
      {children}
    </Animated.View>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing['4'],
    minHeight: theme.spacing['24'],
  },
  hidden: {
    display: 'none',
  },
});

import React from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';
import { theme } from '@sg/garnish';

// ────────────────────────────────────────────────────────────────────────────

export const BagCheckoutContainer = (props: ViewProps) => {
  return (
    <View style={styles.container} {...props}>
      {props.children}
    </View>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing['4'],
    paddingBottom: theme.spacing['4'],
  },
});

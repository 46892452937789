import React from 'react';
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from 'react-intl';
import { View } from 'react-native';
import { isValid } from 'date-fns';
import { GiftCardOrderCard } from '@sg/garnish';

import { giftCardOrderMessages as messages } from './GiftCardOrder.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardOrder = (props: GiftCardOrderProps) => {
  const {
    id,
    recipientName,
    deliveryMethods,
    balance = 0,
    purchaseDate,
  } = props;

  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const balanceInDollars = balance / 100;
  const formattedDate = new Date(purchaseDate);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View>
      <GiftCardOrderCard.Link
        to={{
          screen: 'MainTabs',
          params: {
            screen: 'GiftCardsTab',
            params: {
              screen: 'GiftCardConfirmation',
              params: { orderId: id, referrer: 'gift-card-orders' },
              initial: false,
            },
          },
        }}
        accessibilityLabel={formatMessage(messages.pressableA11yLabel, { id })}
      />

      <GiftCardOrderCard.Container>
        <GiftCardOrderCard.Icon />

        <GiftCardOrderCard.ContentContainer>
          <GiftCardOrderCard.Text isBold>
            <FormattedMessage {...messages.title} />
          </GiftCardOrderCard.Text>

          <GiftCardOrderCard.Text>
            <FormattedMessage
              {...messages.recipientNameWithPrefix}
              values={{ recipient: recipientName }}
            />
          </GiftCardOrderCard.Text>

          {deliveryMethods.includes(DELIVERY_METHOD_MESSAGE) ? (
            <GiftCardOrderCard.Text>
              <FormattedMessage {...messages.viaMessage} />
            </GiftCardOrderCard.Text>
          ) : null}

          {deliveryMethods.includes(DELIVERY_METHOD_EMAIL) ? (
            <GiftCardOrderCard.Text>
              <FormattedMessage {...messages.viaEmail} />
            </GiftCardOrderCard.Text>
          ) : null}
        </GiftCardOrderCard.ContentContainer>

        <GiftCardOrderCard.ExtraDetailsContainer>
          <GiftCardOrderCard.Text isBold>
            <FormattedNumber
              value={balanceInDollars}
              currency="USD"
              maximumFractionDigits={2}
              // eslint-disable-next-line react/style-prop-object
              style="currency"
            />
          </GiftCardOrderCard.Text>

          <GiftCardOrderCard.Text>{id}</GiftCardOrderCard.Text>

          {isValid(formattedDate) ? (
            <GiftCardOrderCard.Text>
              <FormattedDate
                value={formattedDate}
                year="numeric"
                month="2-digit"
                day="2-digit"
              />
            </GiftCardOrderCard.Text>
          ) : null}
        </GiftCardOrderCard.ExtraDetailsContainer>
      </GiftCardOrderCard.Container>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const DELIVERY_METHOD_MESSAGE = 'MESSAGE';
const DELIVERY_METHOD_EMAIL = 'EMAIL';

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardOrderProps = {
  id: string;
  recipientName: string;
  deliveryMethods: ReadonlyArray<string>;
  balance: number; // in cents
  purchaseDate: string; // ISO date
};

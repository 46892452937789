import { useCallback, useState } from 'react';

import { useTelemetry } from '@order/Telemetry';

export const useRequestAccountDeletionModal = () => {
  const { track } = useTelemetry();

  const [
    isShowingRequestAccountDeletionModal,
    setIsShowingRequestAccountDeletionModal,
  ] = useState(false);

  const showRequestAccountDeletionModal = useCallback(() => {
    track('account.profile_request_deletion_tapped');
    setIsShowingRequestAccountDeletionModal(true);
  }, [track]);

  const hideRequestAccountDeletionModal = useCallback(() => {
    setIsShowingRequestAccountDeletionModal(false);
  }, []);

  return {
    isShowingRequestAccountDeletionModal,
    showRequestAccountDeletionModal,
    hideRequestAccountDeletionModal,
  };
};

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { type AddressType, capitalizeFirstLetter } from '@sg/garnish';

import { BagHeaderLink } from '../BagHeaderLink';

/**
 * Channel selector for the bag header.
 */
export const BagChannelHeader = (props: BagChannelHeaderProps) => {
  const { orderChannel, requestChannelOrLocationChange } = props;

  const { formatMessage } = useIntl();
  const {
    accessibilityLabel = formatMessage(messages.accessibilityLabel, {
      channel: orderChannel,
    }),
  } = props;

  return (
    <BagHeaderLink
      accessibilityLabel={accessibilityLabel}
      onPress={requestChannelOrLocationChange}
    >
      {capitalizeFirstLetter(orderChannel)}
    </BagHeaderLink>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Change channel: {channel}',
    description: 'Bag | Header | Change channel',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagChannelHeaderProps = {
  accessibilityLabel?: string;
  orderChannel: AddressType;
  requestChannelOrLocationChange: () => void;
};

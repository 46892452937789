import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

export const JoinSgRewardsContainer = (props: PropsWithChildren) => {
  const { children } = props;

  return <View style={styles.container}>{children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.FOREST,
  },
});

import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { BodyText, theme } from '@sg/garnish';

export const ScanAtCheckoutHowItWorksTitle = (
  props: ScanAtCheckoutHowItWorksTitleProps,
) => {
  const { children, style } = props;

  return (
    <BodyText style={[styles.text, style]} sizeMatch={['18']}>
      {children}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    paddingBottom: theme.spacing['3'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanAtCheckoutHowItWorksTitleProps = ComponentProps<typeof BodyText>;

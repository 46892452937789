import React, {
  type ComponentProps,
  type MutableRefObject,
  useEffect,
  useRef,
} from 'react';
import { View } from 'react-native';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders a `View` component with additional props to support scrollspy functionality.
 * Should be used with the relevant `useScrollspy` hook and wrapped inside the `ScrollSpy.ScrollView`.
 *
 * @example
 * const { helpers } = useScrollspy();
 *
 * return (
 *  <ScrollSpy.ScrollView ...>
 *   <ScrollSpy.TargetView
 *     targetId="item-1"
 *     register={helpers.target.register}
 *     deregister={helpers.target.deregister}
 *   >
 *    Item #1
 *   </ScrollSpy.TargetView>
 *
 *   <ScrollSpy.TargetView
 *     targetId="item-2"
 *     register={helpers.target.register}
 *     deregister={helpers.target.deregister}
 *   >
 *    Item #2
 *   </ScrollSpy.TargetView>
 *  </ScrollSpy.ScrollView>
 */
export const ScrollspyTargetView = (props: ScrollspyTargetViewProps) => {
  const {
    targetId,
    register: registerRef,
    deregister: deregisterRef,
    children,
    ...viewProps
  } = props;

  // ─── Refs ────────────────────────────────────────────────────────────

  const ref = useRef(null);

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    registerRef(targetId, ref);

    return () => {
      deregisterRef(targetId);
    };
  }, [targetId, deregisterRef, registerRef]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View ref={ref} {...viewProps}>
      {children}
    </View>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ScrollspyTargetViewProps = {
  targetId: string;
  register: (targetId: string, targetRef: MutableRefObject<unknown>) => void;
  deregister: (targetId: string) => void;
} & Omit<ComponentProps<typeof View>, 'ref'>;

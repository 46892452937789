/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BagDefaultPaymentMethodQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type BagDefaultPaymentMethodQuery = {
  readonly __typename?: 'Query';
  readonly paymentProfile: {
    readonly __typename: 'PaymentProfile';
    readonly id: string;
    readonly defaultCardId: string | null;
  } | null;
};

export type BagSetDefaultPaymentMethodMutationVariables = Types.Exact<{
  paymentMethodId: Types.Scalars['String']['input'];
}>;

export type BagSetDefaultPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly setDefaultPaymentMethod: {
    readonly __typename: 'PaymentProfile';
    readonly id: string;
    readonly defaultCardId: string | null;
  };
};

export const BagDefaultPaymentMethodDocument = gql`
  query BagDefaultPaymentMethod {
    paymentProfile {
      __typename
      id
      defaultCardId
    }
  }
`;

export function useBagDefaultPaymentMethodQuery(
  options?: Omit<
    Urql.UseQueryArgs<BagDefaultPaymentMethodQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    BagDefaultPaymentMethodQuery,
    BagDefaultPaymentMethodQueryVariables
  >({ query: BagDefaultPaymentMethodDocument, ...options });
}
export const BagSetDefaultPaymentMethodDocument = gql`
  mutation BagSetDefaultPaymentMethod($paymentMethodId: String!) {
    setDefaultPaymentMethod(paymentMethodId: $paymentMethodId) {
      __typename
      id
      defaultCardId
    }
  }
`;

export function useBagSetDefaultPaymentMethodMutation() {
  return Urql.useMutation<
    BagSetDefaultPaymentMethodMutation,
    BagSetDefaultPaymentMethodMutationVariables
  >(BagSetDefaultPaymentMethodDocument);
}

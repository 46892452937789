import { convert24HoursTo12Hours } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export function generateStoreHoursTimetable(
  permanentOperatingHours: HoursGroup[] | readonly HoursGroup[],
  storeOperatingHours: HoursGroup[] | readonly HoursGroup[],
) {
  const permanentOperatingHoursMap = convertToHoursMap(permanentOperatingHours);
  const storeOperatingHoursMap = convertToHoursMap(storeOperatingHours);

  const hasTemporaryHours = checkIfStoreHasTemporaryHours(
    permanentOperatingHoursMap,
    storeOperatingHoursMap,
  );

  return {
    permanentOperatingHoursTimetable: getFormattedHoursTimetable(
      permanentOperatingHoursMap,
    ),
    storeOperatingHoursTimetable: hasTemporaryHours
      ? getFormattedHoursTimetable(storeOperatingHoursMap)
      : null,
  };
}

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Checks if there are any temporary (store-specific) hours by comparing
 * the permanent hours map with the store hours map for each day of the week.
 */
function checkIfStoreHasTemporaryHours(
  permanentHoursGroups: HoursGroupsMap,
  storeHoursGroups: HoursGroupsMap,
) {
  return [...permanentHoursGroups.entries()].some(([weekDay]) => {
    const permanentHours = permanentHoursGroups.get(weekDay);
    const storeHours = storeHoursGroups.get(weekDay);

    return (
      permanentHours?.start !== storeHours?.start ||
      permanentHours?.end !== storeHours?.end
    );
  });
}

/**
 * Retrieves the store hours timetable by processing each day of the week and
 * creating a timetable based on the corresponding map.
 * It also merges hours from consecutive days if they have the same operating hours.
 */
function getFormattedHoursTimetable(hoursGroupsMap: HoursGroupsMap) {
  return WEEK_DAYS.reduce<FormattedHoursGroup[]>(
    (timetable, weekDay, index) => {
      const hoursGroup = hoursGroupsMap.get(weekDay);

      if (!hoursGroup) {
        return timetable;
      }

      const previousDay = WEEK_DAYS[index - 1];
      const previousDayHoursGroup = hoursGroupsMap.get(previousDay);

      return extendTimetable({
        timetable,
        hoursGroup,
        previousDayHoursGroup,
      });
    },
    [],
  );
}

/**
 * Extends the timetable by checking if the current day's hours can be merged
 * with the previous day's hours if they have the same opening hours.
 * If so, the hours are combined; otherwise, a new entry is added.
 */
function extendTimetable(params: ExtendTimetableParams): FormattedHoursGroup[] {
  const { hoursGroup, previousDayHoursGroup, timetable } = params;

  const shouldMergeWithPreviousGroup = checkIfHasSameHours(
    hoursGroup,
    previousDayHoursGroup,
  );
  const previousHourGroup = timetable.at(-1);
  const shouldUsePreviousDayFrom =
    shouldMergeWithPreviousGroup && previousHourGroup !== undefined;

  const dayFrom = shouldUsePreviousDayFrom
    ? previousHourGroup.days.from
    : hoursGroup.day;
  const dayTo = shouldMergeWithPreviousGroup ? hoursGroup.day : undefined;

  const formattedTimetable = shouldMergeWithPreviousGroup
    ? timetable.slice(0, -1)
    : timetable;

  return [
    ...formattedTimetable,
    {
      days: { from: dayFrom, to: dayTo },
      hours: getFormattedHours(hoursGroup),
    },
  ];
}

/**
 * Checks if two consecutive days have the same operating hours.
 */
function checkIfHasSameHours(
  hoursGroup: HoursGroup,
  targetHoursGroup: HoursGroup | undefined,
) {
  return (
    hoursGroup.start === targetHoursGroup?.start &&
    hoursGroup.end === targetHoursGroup?.end
  );
}

/**
 * Formats the hours of operation by converting 24-hour time strings
 * to 12-hour format.
 */
function getFormattedHours(
  hoursGroup: HoursGroup,
): FormattedHoursGroup['hours'] {
  return {
    from: convert24HoursTo12Hours(hoursGroup.start),
    to: convert24HoursTo12Hours(hoursGroup.end),
  };
}

/**
 * Converts provided hour groups array to a Map.
 */
function convertToHoursMap(hourGroups: readonly HoursGroup[]) {
  return new Map<string, HoursGroup>(
    hourGroups.map((group) => {
      const formattedWeekDay = group.day.toLowerCase();

      return [
        formattedWeekDay,
        { day: formattedWeekDay, start: group.start, end: group.end },
      ];
    }),
  );
}

// ─── Constants ───────────────────────────────────────────────────────────────

const WEEK_DAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

// ─── Types ───────────────────────────────────────────────────────────────────

type ExtendTimetableParams = {
  timetable: FormattedHoursGroup[];
  hoursGroup: HoursGroup;
  previousDayHoursGroup: HoursGroup | undefined;
};

type FormattedHoursGroup = {
  days: { from: string; to?: string };
  hours: { from: string; to: string };
};

type HoursGroupsMap = Map<string, HoursGroup>;

type HoursGroup = {
  day: string;
  start: string;
  end: string;
};

export const sweetpassHomeContentFallback = {
  contentItems: [
    {
      type: 'sweetpassHomeChallengesRail',
      id: '3Zfwl96WOyoc0SuKQWymVa',
      titleText: 'Challenges',
      bodyText:
        'Opt in + complete delicious challenges to savor more rewards. ',
      emptyContentText: 'No challenges available at the moment',
      emptyContentImage: {
        description: '',
        url: '//images.ctfassets.net/wme4s8lvzccr/3NoYPz4pWvxykxqjMxWV18/a5328051693daad1ec0e72fb47b76ca8/Frame_2x-2.png',
      },
    },
    {
      type: 'sweetpassHomeRewardsRail',
      id: '5njs6ZocXqz30DK1YILS27',
      titleText: 'Rewards',
      bodyText: 'At checkout, select your reward so it applies to your order.',
      emptyContentText: 'No rewards available at the moment',
      emptyContentImage: {
        description: '',
        url: '//images.ctfassets.net/wme4s8lvzccr/5zaGNq2rbgBq3Rkinj0lxt/53443c5d238392e6694ed28a1a017df7/Frame_2x.png',
      },
    },
  ],
  sweetpassHomeStats: {
    type: 'sweetpassHomeStats',
    id: '3llbOQZ85TZs9GdAwDvhAW',
    infoText: 'Member since {sweetpass_join_date}',
    ctaText: 'Explore your benefits',
    noticeText: undefined,
    noticeTextLink: undefined,
    noticeTextLinkAction: undefined,
  },
  sweetpassHomeActionsRail: {
    type: 'sweetpassHomeActionsRail',
    id: '38anNSCJp00LMubqtuz0CO',
    actionCards: [
      {
        id: '2uqKs8B7BriQsPKExHLI4E',
        content: {
          headingText: 'Earn Rewards',
          bodyText: 'Savor rewards with Challenges.',
          image: {
            description: '',
            url: '//images.ctfassets.net/wme4s8lvzccr/BzybVeVjaOop6vrS9mBWP/4a0305cf1ccb10962534c9d5082da4a2/230217_SWEETGREEN_SWEETPASS_EVERGREEN_HERO_SAVINGS_0114_B__2_.jpg',
          },
        },
        action: 'explore_challenges',
        layout: 'rectangle_frame',
        theme: 'kale_on_cucumber',
        tags: ['SWEETPASS'],
      },
      {
        id: '39oUxZYkBe5zzNAZlb0qIj',
        content: {
          headingText: '$3 Off Daily',
          bodyText: 'Maximize your greens. ',
          image: {
            description: '',
            url: '//images.ctfassets.net/wme4s8lvzccr/3Qi9MhEDGNI3CEQKfYgD3d/f543a59d6b3eb168f47b3397f2e4dc0e/sweetpass-gab-savings__1_.jpg',
          },
        },
        action: 'explore_menu',
        layout: 'rectangle_frame',
        theme: 'kale_on_cucumber',
        tags: ['SWEETPASS_PLUS'],
      },
    ],
  },
};

import React, { type PropsWithChildren } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Platform, StyleSheet, View } from 'react-native';
import {
  getIosModalPresentationConfig,
  IconLink,
  Modal,
  theme,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A component that renders a modal for displaying loyalty offers.
 */
export const LoyaltyOffersModal = (props: LoyaltyOffersModalProps) => {
  const { visible, children, onRequestClose, onCloseButtonPress } = props;

  const { formatMessage } = useIntl();

  return (
    <Modal
      size="medium"
      fitHeight
      visible={visible}
      onRequestClose={onRequestClose}
      accessibilityLabel={formatMessage(messages.modalA11yLabel)}
      contentContainerStyle={styles.modalContainer}
      {...getIosModalPresentationConfig()}
    >
      <View style={styles.innerContainer}>
        <IconLink
          name="IconClose"
          palette="light-semi-transparent"
          width={40}
          height={40}
          onPress={onCloseButtonPress}
          accessibilityLabel={formatMessage(messages.a11yLabel)}
          style={styles.closeButton}
        />

        {children}
      </View>
    </Modal>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  modalA11yLabel: {
    defaultMessage: 'Loyalty offers modal',
    description: 'Loyalty Offers | Modal | a11y label',
  },
  a11yLabel: {
    defaultMessage: 'Close modal',
    description: 'Loyalty Offers | Modal | Close button a11y label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: theme.colors.KALE,
  },
  innerContainer: {
    paddingBottom: Platform.select({
      ios: 0,
      default: theme.spacing['4'],
    }),
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing['4'],
    right: theme.spacing['4'],
    zIndex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOffersModalProps = PropsWithChildren<{
  visible: boolean;
  onCloseButtonPress: () => void;
  onRequestClose: () => void;
}>;

import { useMemo } from 'react';

import { useCart } from '@order/hooks';

import type { ReorderCart } from '../useReorderIfRequired.types';

/**
 * Extracts cart's necessary fields, to avoid extra rerenders.
 */
export const useCartForReorder = () => {
  const { cart, cartOrderType, isCartEmpty } = useCart();

  const cartId = cart?.id;
  const cartRestaurantId = cart?.restaurant?.id;

  return useMemo<ReorderCart>(() => {
    return {
      id: cartId,
      restaurantId: cartRestaurantId,
      orderType: cartOrderType,
      isEmpty: isCartEmpty,
    };
  }, [cartId, cartRestaurantId, cartOrderType, isCartEmpty]);
};

import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Button,
  Container,
  DisplayText,
  IllusEmployeeFork,
  Image,
  TextLink,
  theme,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

// ────────────────────────────────────────────────────────────────────────────────

export const SweetpassSignedOut = (props: SweetpassSignedOutProps) => {
  const { testID, onPressSignIn, onPressJoinNow } = props;

  const { t } = useLocalizationContext();

  return (
    <Container
      testID={testID}
      style={styles.containerContent}
      wrapperStyle={styles.containerWrapper}
    >
      <Image
        source={IllusEmployeeFork}
        contentFit="contain"
        style={styles.imageSignedOut}
      />

      <DisplayText sizeMatch={['24', '24', '40']} style={styles.text}>
        {t('sweetpass.sign-out.text')}
      </DisplayText>

      <Button
        palette="primary"
        style={styles.cta}
        onPress={onPressSignIn}
        testID={testID?.concat('.sign-in-btn')}
      >
        {t('sweetpass.sign-out.sign-in')}
      </Button>

      <View style={styles.row}>
        <BodyText size={3}>{t('signed-out-view.not-joined')}</BodyText>

        <TextLink
          accessibilityLabel={t('signed-out-view.join-now')}
          accessibilityHint={t('signed-out-view.join-now')}
          onPress={onPressJoinNow}
          style={styles.linkWrapper}
        >
          {t('signed-out-view.join-now')}
        </TextLink>
      </View>
    </Container>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerWrapper: {
    flexGrow: 1,
    minHeight: '100%',
  },
  containerContent: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: theme.spacing['6'],
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  imageSignedOut: {
    width: 242,
    height: 242,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.spacing['6'],
  },
  linkWrapper: {
    marginLeft: theme.spacing['1'],
  },
  text: {
    textAlign: 'center',
    paddingTop: theme.spacing['4'],
    paddingBottom: theme.spacing['6'],
  },
  cta: {
    minWidth: 160,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type SweetpassSignedOutProps = Readonly<{
  onPressSignIn: () => void;
  onPressJoinNow: () => void;
}> &
  Pick<ViewProps, 'testID'>;

import { useCallback } from 'react';

import { useBraze } from '@order/Braze';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns a utility for logging custom events with a modified cart
 * payload (added or deleted item).
 */
export const useLogCartChangeCustomEvent = () => {
  const { logCustomEvent } = useBraze();

  return useCallback(
    (params: LogCartChangeEventParams) => {
      const { cart, action, productName } = params;

      const { id, lineItems, restaurant } = cart;

      const { id: restaurant_id, name: restaurant_name } = restaurant;

      const source: CartChangeEventPayload['source'] =
        action === 'ADD' ? `Added ${productName}` : `Removed ${productName}`;
      const timestamp = new Date().toISOString();

      const items = lineItems.map(serializeCartLineItem);

      const bag_amount = lineItems.reduce(sumCartTotal, 0);

      logCustomEvent(CART_BRAZE_EVENTS.CART_UPDATED, {
        id,
        items,
        bag_amount,
        restaurant_id,
        restaurant_name,
        source,
        timestamp,
      });
    },
    [logCustomEvent],
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function serializeCartLineItem(
  lineItem: Cart['lineItems'][number],
): CartChangeEventPayload['items'][number] {
  const { id, product, quantity } = lineItem;
  const { name } = product;

  return { id, name, quantity };
}

function sumCartTotal(
  total: number,
  lineItem: Cart['lineItems'][number],
): number {
  return total + lineItem.cost;
}

// ─── Constants ───────────────────────────────────────────────────────────────

export const CART_BRAZE_EVENTS = {
  CART_UPDATED: 'cart.updated',
};

// ─── Types ───────────────────────────────────────────────────────────────────

type LogCartChangeEventParams = Readonly<{
  cart: Cart;
  productName: string;
  action: 'ADD' | 'REMOVE';
}>;

type Cart = {
  id: string;
  lineItems: ReadonlyArray<{
    id: string;
    cost: number;
    product: { name: string };
    quantity: number;
  }>;
  restaurant: {
    id: string;
    name: string;
  };
};

type CartChangeEventPayload = Readonly<{
  id: string;
  name: ProductName;
  bag_amount: number;
  items: Array<Readonly<{ id: string; name: string; quantity: number }>>;
  source: `Added ${ProductName}` | `Removed ${ProductName}`;
  restaurant_id: string;
  restaurant_name: string;
  timestamp: string; // Date ISO string
}>;

type ProductName = string;

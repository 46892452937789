export const Z_INDEX = {
  dropdown: 1000,
  sticky: 1020,
  fixed: 1030,
  modalBackdrop: 1040,
  offCanvas: 1050,
  modal: 1060,
  popover: 1070,
  tooltip: 1080,

  /**
   * To resolve issue with the `Modal` component's backdrop covering other components,
   * we use a higher `zIndex` value than the one used in React Native's (for web) `Modal` component.
   *
   * @see {@link https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/exports/Modal/ModalAnimation.js#L94}
   */
  overModal: 99_999,
};

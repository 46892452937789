import { useCallback } from 'react';
import { useInterpret } from '@xstate/react';
import { format } from 'date-fns';
import { useNoticeBannersStackContext } from '@sg/garnish';

import {
  checkIfAccessTokenHasExpired,
  refreshAccessTokens,
} from '@order/AzureAuth';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';
import { getFetchOptions } from '@order/Urql';

import { useCreateAccountMutation } from '../../../graphql/CreateAccount.generated';
import { useSignInMutation } from '../../../graphql/SignIn.generated';
import {
  joinOrSignInStateMachine,
  type JoinOrSignInStateMachineContext,
  type JoinOrSignInStateMachineEvents,
  type JoinOrSignInStateMachineServices,
} from './join-or-sign-in-machine';

// ─────────────────────────────────────────────────────────────────────────────

export const useJoinOrSignInService = () => {
  const { t } = useLocalizationContext();
  const { push: addBanner } = useNoticeBannersStackContext();

  const { track } = useTelemetry();

  // ─── Network Requests ────────────────────────────────────────────────

  const [, executeSignIn] = useSignInMutation();
  const [, executeCreateAccount] = useCreateAccountMutation();

  // ─── Actions ─────────────────────────────────────────────────────────

  const onErrorMessage = useCallback(
    (
      _context: JoinOrSignInStateMachineContext,
      event: Extract<JoinOrSignInStateMachineEvents, { type: 'ERROR' }>,
    ) => {
      if (!event.text) return;

      addBanner({ palette: 'caution', text: event.text });
    },
    [addBanner],
  );

  const onSuccessMessage = useCallback(
    (
      _context: JoinOrSignInStateMachineContext,
      event: Extract<JoinOrSignInStateMachineEvents, { type: 'SUCCESS' }>,
    ) => {
      if (!event.text) return;

      addBanner({ palette: 'success', text: event.text });
    },
    [addBanner],
  );

  // ─── Services ────────────────────────────────────────────────────────

  const signIn = useCallback(
    async (
      context: JoinOrSignInStateMachineContext,
    ): Promise<JoinOrSignInStateMachineServices['signIn']['data']> => {
      const { accessToken, vendorAccessToken } = context;

      if (!accessToken || !vendorAccessToken) {
        throw new Error('Sign in failed | No access tokens');
      }

      const response = await executeSignIn(
        {},
        {
          fetchOptions: getFetchOptions({
            extraHeaders: {
              [API_ACCESS_TOKEN_HEADER]: accessToken,
              [VENDOR_ACCESS_TOKEN_HEADER]: vendorAccessToken,
            },
          }),
        },
      );
      const { data, error } = response;
      const responseType = data?.signIn.__typename;

      const hasUserError =
        responseType === 'ValidationError' || responseType === 'SignInFailed';
      const systemError = hasUserError ? data?.signIn.message : error?.message;

      if (responseType === 'SignInSuccess') {
        return { hasSignedIn: true };
      }

      if (responseType === 'CustomerNotFound') {
        return { hasSignedIn: false };
      }

      throw new Error(systemError);
    },
    [executeSignIn],
  );

  const createAccount = useCallback(
    async (
      context: JoinOrSignInStateMachineContext,
    ): Promise<JoinOrSignInStateMachineServices['createAccount']['data']> => {
      const {
        email,
        accessToken: token,
        vendorAccessToken,
        createAccountInput,
      } = context;
      const input = createAccountInput;

      if (!token || !vendorAccessToken || !input) {
        return { hasCreatedAccount: false };
      }

      const response = await executeCreateAccount(
        { input },
        {
          fetchOptions: getFetchOptions({
            extraHeaders: {
              [API_ACCESS_TOKEN_HEADER]: token,
              [VENDOR_ACCESS_TOKEN_HEADER]: vendorAccessToken,
            },
          }),
        },
      );

      const data = response.data?.createAccount;
      const hasCreatedAccount = data?.__typename === 'CreateAccountSuccess';

      // ─── Error Handling ──────────────────────────────────────────────────

      if (!hasCreatedAccount || response.error) {
        const hasValidationErrors = data?.__typename === 'ValidationError';

        const validationError = hasValidationErrors
          ? data.fieldErrors?.[0]?.message
          : undefined;
        const userError = validationError ?? t('join.error');
        const systemError = response.error?.message;

        addBanner({ text: userError, palette: 'caution' });

        track('join.failure', { userError, systemError });

        return { hasCreatedAccount };
      }

      // ─────────────────────────────────────────────────────────────────────

      track('join.success', {
        name: `${input.firstName} ${input.lastName}`,
        email,
        date: format(new Date(), 'M/dd/yyyy K:mmbbb'),
        referred: false,
      });

      return { hasCreatedAccount };
    },
    [addBanner, executeCreateAccount, t, track],
  );

  // ─────────────────────────────────────────────────────────────────────

  return useInterpret(joinOrSignInStateMachine, {
    actions: {
      //
      // ─── Debug Message Loggers ───────────────────────────

      onDebugMessage(_context, event) {
        track('join-or-sign-in.embedded-frame.debug', {
          data: stringifyPayload(event?.data),
        });
      },

      // ─── Error Handlers ──────────────────────────────────

      onInitError(_context, event) {
        track('join-or-sign-in.failure', {
          flow: 'embedded',
          reason: 'Initialization error',
          systemError: stringifyPayload(event?.data),
        });
      },
      onInitTimeoutError(_context) {
        track('join-or-sign-in.failure', {
          flow: 'embedded',
          reason: 'Initialization timeout error',
        });
      },
      onVerificationScreenLoadingTimeout(_context) {
        track('join-or-sign-in.failure', {
          flow: 'embedded',
          reason: 'Verification screen loading timeout error',
        });
      },
      onVerificationTimeout(_context) {
        track('join-or-sign-in.failure', {
          flow: 'embedded',
          reason: 'Code verification timeout error',
        });
      },
      onSignInSystemError(_context, event) {
        track('join-or-sign-in.failure', {
          flow: 'embedded',
          reason: 'Sign-in mutation error',
          systemError: stringifyPayload(event?.data),
        });
      },
      onRefreshAccessTokensSystemError(_context, event) {
        track('join-or-sign-in.failure', {
          flow: 'embedded',
          reason: 'Refresh access tokens error',
          systemError: stringifyPayload(event?.data),
        });
      },
      onCreateAccountSystemError(_context, event) {
        track('join-or-sign-in.failure', {
          flow: 'embedded',
          reason: 'Create account mutation error',
          systemError: stringifyPayload(event?.data),
        });
      },

      // ─── Post Message Handlers ───────────────────────────

      onErrorMessage,
      onSuccessMessage,
    },
    services: {
      createAccount,
      signIn,
      async refreshAccessTokens(context) {
        return refreshAccessTokens(context.refreshToken);
      },
    },
    guards: {
      checkIfNeedToRefreshTokens(context) {
        const { accessToken, vendorAccessToken } = context;

        if (!accessToken || !vendorAccessToken) return true;

        const isApiAccessTokenExpired = checkIfAccessTokenHasExpired({
          accessToken,
          safeGuardMinutes: 5,
        });
        const isVendorAccessTokenExpired = checkIfAccessTokenHasExpired({
          accessToken: vendorAccessToken,
          safeGuardMinutes: 5,
        });

        return isApiAccessTokenExpired || isVendorAccessTokenExpired;
      },
    },
  });
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function stringifyPayload(payload: unknown): string {
  try {
    return JSON.stringify(payload, null, 2);
  } catch {
    return payload as string;
  }
}

// ─── Constants ───────────────────────────────────────────────────────────────

const API_ACCESS_TOKEN_HEADER = 'ApiAuthorizationToken';
const VENDOR_ACCESS_TOKEN_HEADER = 'AuthorizationToken';

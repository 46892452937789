import type { ComponentProps } from 'react';
import React, { useCallback } from 'react';
import type { AccessibilityProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import { useUniqueNativeID } from '../../hooks';
import { Button } from '../Button';
import { BodyText } from '../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const ButtonGroupItem = (props: ButtonGroupItemProps) => {
  const {
    value,
    setValue,
    isActive,
    disabled,
    label,
    accessibilityLabel,
    accessibilityHint,
    helperText,
    gap,
    horizontalPadding,
    fluid = false,
    size,
  } = props;

  const helperTextNativeID = useUniqueNativeID('button-group-item-helper-text');

  const handleOnPress = useCallback(() => {
    setValue(value);
  }, [setValue, value]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerGapStyle = useStyle(() => {
    if (!gap) return;

    return { marginRight: theme.spacing[gap] };
  }, [gap]);

  const containerFluidStyle = fluid ? styles.containerFluid : undefined;
  const containerStyles = [containerFluidStyle, containerGapStyle];

  const horizontalPaddingStyle = useStyle(() => {
    if (!horizontalPadding) return;

    return { paddingHorizontal: theme.spacing[horizontalPadding] };
  }, [horizontalPadding]);

  const palette = isActive ? 'primaryOutline' : 'muted';

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles}>
      <Button
        onPress={isActive ? undefined : handleOnPress}
        palette={palette}
        style={horizontalPaddingStyle}
        size={size}
        disabled={disabled}
        accessibilityRole="radio"
        accessibilityLabel={accessibilityLabel}
        accessibilityHint={accessibilityHint}
        accessibilityState={{ checked: isActive, disabled }}
        {...{
          accessibilityChecked: isActive,
          accessibilityDisabled: disabled,
          accessibilityDescribedBy: helperText ? helperTextNativeID : undefined,
        }}
      >
        {label}
      </Button>

      {helperText ? (
        <View style={styles.helperTextContainer}>
          <BodyText
            nativeID={helperTextNativeID}
            size={5}
            style={styles.helperText}
          >
            {helperText}
          </BodyText>
        </View>
      ) : null}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerFluid: {
    flex: 1,
  },
  helperTextContainer: {
    marginTop: theme.spacing['2'],
  },
  helperText: {
    textAlign: 'center',
    color: theme.colors.CHARCOAL,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type Spacing = keyof typeof theme.spacing;

type ButtonGroupItemProps = Readonly<{
  value: string;
  setValue: (value: string) => void;
  label: string;
  isActive?: boolean;
  disabled?: boolean;
  helperText?: string;
  accessibilityLabel?: AccessibilityProps['accessibilityLabel'];
  accessibilityHint?: AccessibilityProps['accessibilityHint'];
  fluid?: boolean;
  size?: ComponentProps<typeof Button>['size'];
  horizontalPadding?: Spacing;
  gap?: Spacing;
}>;

import React, { type ComponentProps, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { ButtonGroup, theme } from '@sg/garnish';

import {
  DeliveryMethods,
  type GiftCardCheckoutFormReturn,
  type GiftCardCheckoutFormState,
} from '../../../../../../form';
import { giftCardCheckoutDeliveryDateMessages as messages } from './GiftCardCheckoutDeliveryDate.messages';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders delivery date option picker with the corresponding handlers.
 */
export const GiftCardCheckoutDeliveryDateOptions = (
  props: GiftCardCheckoutDeliveryDateOptionsProps,
) => {
  const { form, fromDate } = props;

  const { formatMessage } = useIntl();

  // ─── Derived Data ────────────────────────────────────────────────────

  const { isSubmitting } = form.formState;

  const deliveryDateOption = form.watch('deliveryDateOption');
  const deliveryMethod = form.watch('deliveryMethod');

  const deliveryDateOptions = useMemo<ButtonGroupItems>(() => {
    const { nowOptionLabel: now, scheduleOptionLabel: schedule } = messages;

    return [
      { value: DELIVERY_DATE_NOW, label: formatMessage(now) },
      { value: DELIVERY_DATE_SCHEDULE, label: formatMessage(schedule) },
    ];
  }, [formatMessage]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onScheduleOptionSelect = useCallback(async () => {
    const isMessageDeliveryMethodSelected = deliveryMethod === 'MESSAGE';

    form.setValue('deliveryDateOption', 'SCHEDULE');
    form.setValue('deliveryMethod', DeliveryMethods.Email);
    form.setValue('deliveryDateTime', fromDate.toISOString());

    if (isMessageDeliveryMethodSelected) {
      form.setValue('recipientEmail', undefined);
    }

    await form.trigger('deliveryDateTime');
    await form.trigger('deliveryDateOption');
  }, [deliveryMethod, form, fromDate]);

  const onNowOptionSelect = useCallback(async () => {
    form.setValue('deliveryDateOption', 'NOW');
    form.setValue('deliveryMethod', DeliveryMethods.Message);
    form.setValue('deliveryDateTime', undefined);

    await form.trigger('deliveryDateTime');
    await form.trigger('deliveryDateOption');
  }, [form]);

  const onOptionChange = useCallback(
    async (option: string) => {
      const isScheduleOptionSelected = option === DELIVERY_DATE_SCHEDULE;
      const isNowOptionSelected = option === DELIVERY_DATE_NOW;

      if (isNowOptionSelected) {
        await onNowOptionSelect();
      }

      if (isScheduleOptionSelected) {
        await onScheduleOptionSelect();
      }
    },
    [onNowOptionSelect, onScheduleOptionSelect],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <ButtonGroup
        value={deliveryDateOption}
        gap="2"
        itemsHorizontalPadding="4"
        size="large"
        items={deliveryDateOptions}
        onChange={onOptionChange}
        isDisabled={isSubmitting}
      />
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const DELIVERY_DATE_NOW: DeliveryDateOption = 'NOW';
const DELIVERY_DATE_SCHEDULE: DeliveryDateOption = 'SCHEDULE';

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingTop: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutDeliveryDateOptionsProps = {
  form: GiftCardCheckoutFormReturn;
  fromDate: Date;
};

type ButtonGroupItems = ComponentProps<typeof ButtonGroup>['items'];

type DeliveryDateOption = GiftCardCheckoutFormState['deliveryDateOption'];

import React from 'react';
import type { ImageSourcePropType } from 'react-native';

import {
  HeroCardBackSide,
  HeroCardFrontSide,
  HeroCardPressable,
} from './components';
import { HeroCardLoading } from './HeroCardLoading';
import { useHeroCardFlipAnimation } from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const HeroCard = (props: HeroCardProps) => {
  const { title, imageSrc, imageA11yLabel, ctaLabel, isLoading, url, onPress } =
    props;

  if (isLoading) return <HeroCardLoading />;

  return (
    <HeroCardPressable url={url} onPress={onPress}>
      <HeroCardFrontSide
        title={title}
        imageUrl={imageSrc}
        imageA11yLabel={imageA11yLabel}
        ctaLabel={ctaLabel}
      />
    </HeroCardPressable>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

export const HeroCardFlip = (props: HeroCardFlipProps) => {
  const {
    title,
    imageSrc,
    imageA11yLabel,
    ctaLabel,
    isLoading,
    backSideTitle,
    backSideDescription,
    backSideInlineLinkLabel,
    backSideInlineLinkURL,
    backSideCtaLabel,
  } = props;

  const {
    isFlipped,
    toggleFlipState,
    cardFrontSideAnimatedStyle,
    cardBackSideAnimatedStyle,
  } = useHeroCardFlipAnimation();

  if (isLoading) return <HeroCardLoading />;

  return (
    <HeroCardPressable onPress={toggleFlipState}>
      <HeroCardFrontSide
        title={title}
        ctaLabel={ctaLabel}
        imageUrl={imageSrc}
        imageA11yLabel={imageA11yLabel}
        animatedStyle={cardFrontSideAnimatedStyle}
        isVisible={!isFlipped}
      />

      <HeroCardBackSide
        ctaLabel={backSideCtaLabel}
        description={backSideDescription}
        title={backSideTitle}
        animatedStyle={cardBackSideAnimatedStyle}
        inlineLinkLabel={backSideInlineLinkLabel}
        inlineLinkURL={backSideInlineLinkURL}
        isVisible={isFlipped}
      />
    </HeroCardPressable>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type HeroCardProps = Readonly<{
  title: string;
  imageSrc?: ImageSourcePropType;
  imageA11yLabel?: string;
  ctaLabel: string;
  isLoading?: boolean;
  url?: string;
  onPress?: () => void;
}>;

type HeroCardFlipProps = Omit<HeroCardProps, 'onPress'> &
  Readonly<{
    backSideTitle: string;
    backSideDescription: string;
    backSideCtaLabel: string;
    backSideInlineLinkLabel?: string;
    backSideInlineLinkURL?: string;
  }>;

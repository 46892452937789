import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  ListAction,
  ListActionInfo,
  LoadingPlaceholder,
  theme,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardAccountSectionGiftCardTotal = (
  props: GiftCardAccountSectionGiftCardTotalProps,
) => {
  const { isFetching, totalAmount } = props;

  const { t } = useLocalizationContext();

  return (
    <View style={styles.container}>
      <ListActionInfo
        iconName="IconCredit"
        title={t('account.gift-cards.gift-card-total')}
      >
        {isFetching ? (
          <View style={styles.loadingPlaceholderContainer}>
            <LoadingPlaceholder
              palette="cream"
              rowHeight={LOADING_PLACEHOLDER_HEIGHT}
              borderRadius={theme.radius.small}
            />
          </View>
        ) : (
          <ListAction.Text>{totalAmount}</ListAction.Text>
        )}
      </ListActionInfo>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const LOADING_PLACEHOLDER_HEIGHT = 18;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.LIGHT,
  },
  loadingPlaceholderContainer: {
    width: 60,
    height: LOADING_PLACEHOLDER_HEIGHT,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardAccountSectionGiftCardTotalProps = Readonly<{
  isFetching: boolean;
  totalAmount: string;
}>;

import React from 'react';
import { useStyle } from 'react-native-style-utilities';
import { DisplayText } from '@sg/garnish';

export const HomeHeroText = (props: HomeHeroTextProps) => {
  const textStyles = useStyle(
    () => ({
      color: props.fontColor,
    }),
    [props.fontColor],
  );

  return (
    <DisplayText bold sizeMatch={['32', '32', '40']} style={textStyles}>
      {props.text}
    </DisplayText>
  );
};

type HomeHeroTextProps = {
  text: string;
  fontColor: string;
};

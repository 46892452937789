import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { Footer } from '@expo/html-elements';
import { theme } from '@sg/garnish';

/**
 * Footer for the bag.
 */
export const BagFooter = (props: BagFooterProps) => {
  const disabledStyle = props.isDisabled ? styles.disabled : null;

  return (
    <Footer style={[styles.container, disabledStyle]}>{props.children}</Footer>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.LINEN,
    gap: theme.spacing['6'],
    padding: theme.spacing['8'],
    marginHorizontal: -theme.spacing['4'],
    paddingBottom: theme.spacing['30'],
  },
  disabled: {
    backgroundColor: theme.colors.LIGHT,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagFooterProps = PropsWithChildren & {
  isDisabled?: boolean;
};

import React, {
  type PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  type LayoutChangeEvent,
  StyleSheet,
  View,
  type ViewStyle,
} from 'react-native';
import { theme } from '@garnish/constants';

import { FadeView } from '../../../../../FadeView';

// ─────────────────────────────────────────────────────────────────────────────

export const ActiveIngredientQuantityStepperContainer = (
  props: ActiveIngredientQuantityStepperContainerProps,
) => {
  const { isVisible, variation, children } = props;

  // ─── State ───────────────────────────────────────────────────────────

  const [containerWidth, setContainerWidth] = useState(0);

  const storeContainerWidth = useCallback((event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width);
  }, []);

  // ─── Styles ──────────────────────────────────────────────────────────

  // Dynamic styles for the "floating" variation
  const outerContainerDynamicStyle = useMemo<ViewStyle | undefined>(() => {
    if (variation === 'static') {
      return undefined;
    }

    return {
      transform: [{ translateX: -(containerWidth / 2) }],
    };
  }, [variation, containerWidth]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <FadeView
      show={isVisible}
      style={[styles.outerContainer, outerContainerDynamicStyle]}
      onLayout={variation === 'static' ? undefined : storeContainerWidth}
    >
      <View style={styles.arrow} />
      <View style={styles.container}>{children}</View>
    </FadeView>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    alignItems: 'center',
    paddingTop: theme.spacing['1'],
  },
  arrow: {
    width: 16,
    height: 16,
    marginBottom: -10,
    backgroundColor: theme.colors.KALE,
    borderRadius: 2,
    transform: [{ rotate: '45deg' }],
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.KALE,
    borderRadius: 64,
    gap: theme.spacing['2'],
    ...theme.elevations['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ActiveIngredientQuantityStepperContainerProps = PropsWithChildren<{
  isVisible: boolean;
  variation?: 'static' | 'floating';
}>;

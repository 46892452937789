import React from 'react';

import { TextField } from '../../TextField';

export const TextFieldPassword = (
  props: React.ComponentProps<typeof TextField>,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { secureTextEntry, ...restProps } = props;

  return <TextField secureTextEntry clearButtonType="none" {...restProps} />;
};

import React, { useRef } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import {
  BodyText,
  Icon,
  type IconName,
  theme,
  usePressableState,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * The selected payment method info for the bag.
 */
export const BagPaymentMethodInfo = (props: BagPaymentMethodInfoProps) => {
  const { icon, name, isDisabled, onPress } = props;

  const { formatMessage } = useIntl();
  const { ref, linkWrapperStyles, linkStyles } =
    usePaymentMethodLink(isDisabled);

  return (
    <View style={styles.messageWrapper}>
      <BodyText style={styles.message} sizeMatch={['24']}>
        <FormattedMessage {...messages.payWith} />
      </BodyText>

      <Pressable
        ref={ref}
        style={linkWrapperStyles}
        disabled={isDisabled}
        accessibilityRole="button"
        accessibilityLabel={formatMessage(messages.changePaymentMethod)}
        onPress={onPress}
      >
        <Icon
          color={theme.colors.SPINACH}
          name={icon}
          width={ICON_SIZE}
          height={ICON_SIZE}
        />

        <BodyText style={linkStyles} sizeMatch={['24']}>
          {name}
        </BodyText>
      </Pressable>
    </View>
  );
};

/**
 * The add a payment method link.
 */
export const BagPaymentMethodLink = (props: BagPaymentMethodLinkProps) => {
  const { isDisabled, onPress } = props;

  const { formatMessage } = useIntl();
  const { ref, linkStyles } = usePaymentMethodLink(isDisabled);

  return (
    <Text style={styles.messageWrapper}>
      <BodyText
        underline
        ref={ref}
        style={linkStyles}
        sizeMatch={['24']}
        disabled={isDisabled}
        accessibilityRole="button"
        accessibilityLabel={formatMessage(messages.addPaymentMethod)}
        onPress={onPress}
      >
        <FormattedMessage {...messages.addPaymentMethod} />
      </BodyText>
    </Text>
  );
};

// ─── Helpers ────────────────────────────────────────────────────────────────

const usePaymentMethodLink = (isDisabled: boolean) => {
  const ref = useRef(null);
  const { isInteracting } = usePressableState(ref);

  const linkWrapperStyles = [
    styles.linkWrapper,
    isInteracting && styles.linkWrapperInteracting,
    isDisabled && styles.linkWrapperDisabled,
  ];

  const linkStyles = [
    styles.message,
    styles.link,
    isInteracting && styles.linkInteracting,
    isDisabled && styles.linkDisabled,
  ];

  return { ref, linkWrapperStyles, linkStyles };
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  payWith: {
    defaultMessage: 'Pay with',
    description: 'Bag | Payment method | Hint',
  },
  addPaymentMethod: {
    defaultMessage: 'Add a payment method',
    description: 'Bag | Selected Payment Method | Add payment method',
  },
  changePaymentMethod: {
    defaultMessage: 'Change payment method',
    description: 'Bag | Selected Payment Method | Select another',
  },
});

// ─── Constants ──────────────────────────────────────────────────────────────

const ICON_SIZE = 32;

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  messageWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['2'],
    paddingVertical: theme.spacing['4'],
  },
  message: {
    flexDirection: 'row',
    alignItems: 'center',
    lineHeight: ICON_SIZE,
  },
  linkWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['2'],
    borderBottomColor: theme.colors.SPINACH,
    borderBottomWidth: 2,
  },
  linkWrapperInteracting: {
    borderBottomColor: theme.colors.SPINACH,
    borderBottomWidth: 2,
  },
  linkWrapperDisabled: {
    borderBottomColor: theme.colors.SPINACH,
    borderBottomWidth: 2,
  },
  link: {
    color: theme.colors.SPINACH,
  },
  linkInteracting: {
    color: theme.colors.KALE_HOVER,
  },
  linkDisabled: {
    color: theme.colors.GRAY,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagPaymentMethodInfoProps = Readonly<{
  icon: IconName;
  name: string;
  isDisabled: boolean;
  onPress: () => void;
}>;

type BagPaymentMethodLinkProps = Readonly<{
  isDisabled: boolean;
  onPress: () => void;
}>;

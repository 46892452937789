/* eslint-disable functional/immutable-data */

import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { format, parseISO } from 'date-fns';
import { type PointHistoryForUserSuccess } from '@sg/graphql-schema';

import { useLoyaltyPointHistoryQuery } from '../../GraphQL/LoyaltyPointHistory.generated';

/**
 * Here we use a local `paginatedPointHistory` state to handle pagination.
 * It is easier (and recommended) to manage a state variable rather than graphcache.
 */
export const useLoyaltyPointHistory = () => {
  const [page, setPage] = useState(1);
  const [paginatedPointHistory, setPaginatedPointHistory] = useState<
    readonly PointHistoryEntry[]
  >([]);

  // ─── Query ───────────────────────────────────────────────────────────

  const [response, fetchPointHistory] = useLoyaltyPointHistoryQuery({
    variables: { take: 10, page },
    pause: true,
    requestPolicy: 'network-only',
  });

  // ─── Response ────────────────────────────────────────────────────────

  const isLoadingPointHistory = response.fetching;
  const successResponse = response.data
    ?.pointHistoryForUser as PointHistoryForUserSuccess;

  // ─── Expiration ──────────────────────────────────────────────────────

  const pointExpirationEntries = successResponse?.pointExpirationEntries;

  // ─── Pagination ──────────────────────────────────────────────────────

  const loadedPointHistory = paginatedPointHistory.length;
  const newPointHistory = successResponse?.pointHistoryEntries;
  const paginationTotal = successResponse?.pagination?.total ?? 0;
  const canLoadMorePointHistory =
    !paginationTotal || loadedPointHistory < paginationTotal;

  usePointHistoryPageConcatenation(newPointHistory, setPaginatedPointHistory);

  // ─── Loading More Increments Page & Re-Queries ───────────────────────

  const handleLoadMorePointHistory = useCallback(() => {
    setPage((currentPage) => currentPage + 1);
    fetchPointHistory();
  }, [setPage, fetchPointHistory]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const resetPointHistory = useCallback(() => {
    setPage(1);
    setPaginatedPointHistory([]);
  }, []);

  const refetchPointHistory = useCallback(() => {
    resetPointHistory();
    fetchPointHistory();
  }, [resetPointHistory, fetchPointHistory]);

  // ─── Reset Pagination on Focus ───────────────────────────────────────

  useFocusEffect(resetPointHistory);

  // ─── Re-Query On Focus ───────────────────────────────────────────────

  useFocusEffect(fetchPointHistory);

  return {
    pointHistory: paginatedPointHistory,
    pointExpirationEntries,
    canLoadMorePointHistory,
    isLoadingPointHistory,
    handleLoadMorePointHistory,
    refetchPointHistory,
  };
};

/**
 * This effect will concatenate previous pages of point history with the new resolved page.
 * It will also include the month dividers to be used in the point history list.
 */
const usePointHistoryPageConcatenation = (
  newPointHistory: readonly PointHistoryEntry[] | undefined,
  setPaginatedPointHistory: Dispatch<
    SetStateAction<readonly PointHistoryEntry[]>
  >,
) => {
  useEffect(() => {
    setPaginatedPointHistory((previousPointHistory) => {
      const withoutMonthDividers = previousPointHistory.filter(
        ({ monthDivider }) => !monthDivider,
      );

      return includeMonthDividers([
        ...withoutMonthDividers,
        ...(newPointHistory ?? []),
      ]);
    });
  }, [newPointHistory, setPaginatedPointHistory]);
};

/**
 * Given a list of point history entries, insert a month divider in between each month.
 */
const includeMonthDividers = (pointHistory: PointHistoryEntry[]) => {
  return pointHistory.reduce((acc: PointHistoryEntry[], entry) => {
    const currentMonth = format(parseISO(entry.date), 'MMMM yyyy');
    const lastEntry = acc.at(-1);
    const divider = {
      ...entry,
      monthDivider: currentMonth,
      id: entry.id + '-divider',
    };

    if (!lastEntry) {
      acc.push(divider, entry);

      return acc;
    }

    const lastMonth = format(parseISO(lastEntry.date), 'MMMM yyyy');

    if (currentMonth !== lastMonth) {
      acc.push(divider);
    }

    acc.push(entry);

    return acc;
  }, []);
};

type PointHistoryEntry = {
  id: string;
  value: number;
  date: string;
  monthDivider?: string;
  source?: string | null;
  type?: string | null;
};

/* cSpell:ignore whatsup */

import { useCallback, useMemo, useState } from 'react';
import { useRoute } from '@react-navigation/native';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import type {
  FeedbackQuestion,
  OrderStatus,
  SubmitFeedbackMutation,
  SubmitInStoreFeedbackMutation,
} from '@order/graphql';
import {
  useCachedInStoreOrderPendingFeedback,
  useLocalOrderHistory,
  useOrderFeedbackNotifications,
} from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry, useTrackEffect } from '@order/Telemetry';
import { getUrqlError } from '@order/utils';

import type { FeedbackOrderStatusQuery } from './GraphQL/RateOrder.generated';
import { useSubmitInStoreFeedbackMutation } from './GraphQL/RateOrder.generated';
import {
  useFeedbackOrderStatusQuery,
  useInStoreFeedbackQuestionsQuery,
} from './GraphQL/RateOrder.generated';
import {
  useFeedbackQuestionsQuery,
  useSubmitFeedbackMutation,
} from './GraphQL/RateOrder.generated';
import { useRateOrderNavigation } from './RateOrder.navigation';

// ─────────────────────────────────────────────────────────────────────────────

export const useRateOrder = () => {
  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { dismissRateOrder } = useRateOrderNavigation();
  const isLoggedIn = useIsLoggedIn();
  const { params } = useRoute();
  const { orderId } = params as Readonly<{ orderId: string }>;

  // ─── Reviewing In Store Orders ──────────────────────────────────

  const { inStoreOrderId, inStoreOrderWantedTime } =
    useCachedInStoreOrderPendingFeedback();
  const isInStoreOrder = inStoreOrderId === orderId;

  // ─── Query & Mutations ──────────────────────────────────────────

  const { orderStatusResponse, questionsData, questionsResponse } =
    useFeedbackQueries(orderId, isInStoreOrder);
  const { submitFeedback: submitStars } = useFeedbackSubmission(isInStoreOrder);
  const { submitFeedback, executionLoading } =
    useFeedbackSubmission(isInStoreOrder);

  // ─── Internal State ─────────────────────────────────────────────

  const [rating, setRating] = useState(0);
  const [hoverRating, handleHoverRating] = useState(0);
  const [selectedCards, selectCards] = useState<readonly string[]>([]);

  // ─── Data Parsing ───────────────────────────────────────────────

  const titleRating = hoverRating > 0 ? hoverRating : rating;
  const {
    products,
    restaurant,
    wantedTime,
    starsQuestionId,
    starsQuestionText,
    stars,
    cardsQuestionId,
    cardsQuestionText,
    cards,
  } = useData(
    rating,
    selectedCards,
    questionsData,
    orderStatusResponse.data,
    inStoreOrderWantedTime,
  );
  const selectedCardsLabels = useMemo(() => {
    return cards
      .filter(({ id }) => selectedCards.includes(id))
      .map((card) => card.label);
  }, [cards, selectedCards]);

  const { cancelScheduledOrderFeedbackNotification } =
    useOrderFeedbackNotifications();

  // ─── Telemetry ──────────────────────────────────────────────────
  useTrackEffect('rate_order.view', {});
  const { track } = useTelemetry();

  // ─── Local Order History ────────────────────────────────────────
  const { updateLocalOrderHistory } = useLocalOrderHistory();

  // ─── Callbacks ──────────────────────────────────────────────────

  const handleCardSelection = useCallback(
    (id: string, selected: boolean) => {
      const newSelection = [...selectedCards].filter((el) => el !== id);

      // eslint-disable-next-line functional/immutable-data
      if (selected) newSelection.push(id);
      selectCards(newSelection);
    },
    [selectedCards],
  );

  const handleRating = useCallback(
    async (value: number) => {
      if (!isLoggedIn) return;

      setRating(value);
      const becamePositive = rating <= 4 && value === 5;
      const becameNegative = rating === 5 && value <= 4;
      const wasRatingLogicChanged = becamePositive || becameNegative;

      if (wasRatingLogicChanged) selectCards([]);

      const submitStarsResponse = await submitStars({
        input: {
          id: orderId,
          isFeedbackComplete: false,
          questionId: starsQuestionId,
          responseIds: [stars.find((star) => star.value === value)?.id ?? ''],
        },
      });

      if (isFeedbackSuccessful(submitStarsResponse)) {
        updateLocalOrderHistory({ orderId, hasLowRating: value < 4 });

        // given the current order ID, cancel any pending order feedback notification
        void cancelScheduledOrderFeedbackNotification(orderId);
      }
    },
    [
      isLoggedIn,
      rating,
      orderId,
      starsQuestionId,
      stars,
      updateLocalOrderHistory,
      submitStars,
      cancelScheduledOrderFeedbackNotification,
    ],
  );

  const handleSubmit = useCallback(async () => {
    if (!isLoggedIn) return;

    const response = await submitFeedback({
      input: {
        id: orderId,
        isFeedbackComplete: true,
        questionId: cardsQuestionId,
        responseIds: selectedCards,
      },
    });

    if (response.error) {
      const userError = t('rate-order.response.failure');
      const systemError = getUrqlError(response.error);

      track('rate_order.failure', { userError, systemError });

      addNoticeBanner(
        {
          text: userError,
          palette: 'caution',
          targetId: 'rate-order-banner',
          autoHideTimeout: 2000,
        },
        true,
      );

      return;
    }

    if (isFeedbackSuccessful(response)) {
      track('rate_order.success', {
        rating,
        selectedChips: selectedCardsLabels,
      });

      // NOTE: A timeout is used to display the banner after modal dismiss.
      setTimeout(() => {
        addNoticeBanner({
          text: t('rate-order.response.success'),
          palette: 'success',
        });
      }, 500);

      dismissRateOrder();
    }
  }, [
    isLoggedIn,
    submitFeedback,
    orderId,
    cardsQuestionId,
    selectedCards,
    t,
    track,
    addNoticeBanner,
    rating,
    selectedCardsLabels,
    dismissRateOrder,
  ]);

  const isLoading =
    orderStatusResponse.stale ||
    orderStatusResponse.fetching ||
    questionsResponse.stale ||
    questionsResponse.fetching;

  return {
    loading: isLoading,
    submitting: executionLoading,
    orderId,
    landingTitle: starsQuestionText,
    landingSubtitle: useLandingSubtitle(products, restaurant, wantedTime),
    ratingTitle:
      titleRating > 0
        ? t(`rate-order.stars.title.${titleRating}` as never)
        : ' ',
    ratingSubtitle: cardsQuestionText,
    ratingHint: t('rate-order.hint'),
    ratingCta: t('rate-order.call-to-action'),
    cards,
    rating,
    handleRating,
    handleHoverRating,
    handleCardSelection,
    handleSubmit,
  };
};

const useData = (
  rating: number,
  selectedCards: readonly string[],
  questionsData?: readonly FeedbackQuestion[],
  orderStatusData?: FeedbackOrderStatusQuery,
  inStoreOrderWantedTime?: string,
) => {
  return {
    products: useProducts(orderStatusData),
    restaurant: useRestaurant(orderStatusData),
    wantedTime: useWantedTime(orderStatusData, inStoreOrderWantedTime),
    ...useStars(questionsData),
    ...useCards(rating, selectedCards, questionsData),
  };
};

// This is used because in-store orders might not have full order data.
const useLandingSubtitle = (
  products?: string,
  restaurant?: string,
  wantedTime?: string | Date,
) => {
  const { t } = useLocalizationContext();
  const params = { products, restaurant, wantedTime };

  if (products && restaurant && wantedTime) {
    return t('rate-order.subtitle.full', params);
  }

  if (products && restaurant) {
    return t('rate-order.subtitle.no-time', params);
  }

  if (products && wantedTime) {
    return t('rate-order.subtitle.no-restaurant', params);
  }

  if (restaurant && wantedTime) {
    return t('rate-order.subtitle.no-products', params);
  }

  if (products) {
    return t('rate-order.subtitle.only-products', params);
  }

  if (wantedTime) {
    return t('rate-order.subtitle.only-time', params);
  }

  if (restaurant) {
    return t('rate-order.subtitle.only-restaurant', params);
  }

  return '';
};

const useProducts = (data?: FeedbackOrderStatusQuery) => {
  const response = data?.orderStatus as OrderStatus;

  return response?.order?.description;
};

const useRestaurant = (data?: FeedbackOrderStatusQuery) => {
  const response = data?.orderStatus as OrderStatus;

  return response?.order?.restaurant?.name;
};

const useWantedTime = (
  data?: FeedbackOrderStatusQuery,
  inStoreOrderWantedTime?: string,
) => {
  const response = data?.orderStatus as OrderStatus;

  if (inStoreOrderWantedTime) return new Date(inStoreOrderWantedTime);

  return response?.order?.wantedTime
    ? new Date(response?.order?.wantedTime)
    : undefined;
};

const useStars = (feedbackQuestions?: readonly FeedbackQuestion[]) => {
  const { t } = useLocalizationContext();

  const starsResponse = feedbackQuestions?.find((question) =>
    question.slug?.includes(STARS_SLUG),
  );

  return {
    starsQuestionId: starsResponse?.id ?? '',
    starsQuestionText: starsResponse?.text ?? t('general.error'),
    stars:
      starsResponse?.responses?.map((star) => ({
        id: star.id,
        value: Number(star.text),
      })) ?? [],
  };
};

const useCards = (
  rating: number,
  selectedCards: readonly string[],
  feedbackQuestions?: readonly FeedbackQuestion[],
) => {
  const slug = rating === 5 ? POSITIVE_SLUG : NEGATIVE_SLUG;
  const cardsResponse = feedbackQuestions?.find((question) =>
    question.slug?.includes(slug),
  );

  return {
    cardsQuestionId: cardsResponse?.id ?? '',
    cardsQuestionText: cardsResponse?.text ?? '',
    cards:
      cardsResponse?.responses?.map((question) => ({
        id: question.id,
        label: question.text,
        selected: selectedCards.includes(question.id),
      })) ?? [],
  };
};

const useFeedbackQueries = (orderId: string, isInStoreOrder: boolean) => {
  const queryOptions = { variables: { orderId } };
  const [orderStatusResponse] = useFeedbackOrderStatusQuery({
    ...queryOptions,

    // NOTE: Because we fetch order status on the `RateOrder` screen mount,
    //       we can rely on a cached response rather than triggering a new one.
    requestPolicy: 'cache-first',
  });
  const [onlineQuestionsResponse] = useFeedbackQuestionsQuery({
    ...queryOptions,
    pause: isInStoreOrder,
  });
  const [inStoreQuestionsResponse] = useInStoreFeedbackQuestionsQuery({
    ...queryOptions,
    pause: !isInStoreOrder,
  });
  const questionsResponse = isInStoreOrder
    ? inStoreQuestionsResponse
    : onlineQuestionsResponse;
  const questionsData = isInStoreOrder
    ? inStoreQuestionsResponse?.data?.inStoreFeedbackQuestions
    : onlineQuestionsResponse?.data?.feedbackQuestions;

  return {
    orderStatusResponse,
    questionsResponse,
    questionsData,
  };
};

const useFeedbackSubmission = (isInStoreOrder: boolean) => {
  const [onlineExecution, submitOnlineFeedback] = useSubmitFeedbackMutation();
  const [inStoreExecution, submitInStoreFeedback] =
    useSubmitInStoreFeedbackMutation();

  const executionSuccess = isInStoreOrder
    ? inStoreExecution?.data?.submitInStoreFeedback?.success
    : onlineExecution?.data?.submitFeedback?.success;

  const executionError = isInStoreOrder
    ? inStoreExecution?.error
    : onlineExecution?.error;

  const executionLoading = isInStoreOrder
    ? inStoreExecution?.fetching
    : onlineExecution?.fetching;

  const submitFeedback = isInStoreOrder
    ? submitInStoreFeedback
    : submitOnlineFeedback;

  return useMemo(
    () => ({
      executionSuccess,
      executionError,
      executionLoading,
      submitFeedback,
    }),
    [executionSuccess, executionError, executionLoading, submitFeedback],
  );
};

// For both mutation types, checks whether the feedback was successful.
function isFeedbackSuccessful(
  response: Awaited<
    ReturnType<ReturnType<typeof useFeedbackSubmission>['submitFeedback']>
  >,
) {
  const isInStoreSubmitSuccess = (
    response.data as SubmitInStoreFeedbackMutation
  )?.submitInStoreFeedback?.success;

  const isOnlineSubmitSuccess = (response.data as SubmitFeedbackMutation)
    ?.submitFeedback?.success;

  return Boolean(isInStoreSubmitSuccess || isOnlineSubmitSuccess);
}

const STARS_SLUG = 'order-satisfaction';
const POSITIVE_SLUG = 'whatsup-positive';
const NEGATIVE_SLUG = 'whatsup-negative';

import { useRef } from 'react';

import { useConfirmLocation } from '../../../hooks/useConfirmLocation';

/**
 * Returns a reference to reorder `confirmLocation` helper to avoid extra rerenders.
 */
export const useMemoizedConfirmReorderLocation = () => {
  const confirmLocation = useConfirmLocation();
  const confirmLocationRef = useRef(confirmLocation);

  // eslint-disable-next-line functional/immutable-data
  confirmLocationRef.current = confirmLocation;

  return confirmLocationRef;
};

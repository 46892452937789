import React, { useCallback } from 'react';
import { Platform, Pressable, StyleSheet } from 'react-native';
import CrashTester from 'react-native-crash-tester';
import { datadog } from '@sg/expo-datadog-client';
import { BodyText, theme, usePressableState, useResponsive } from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';
import { getEnvVars } from '@order/utils';

/**
 * This component will, if the feature flag is on, render two
 * options in the account menu that allows crashing the app
 * with either a JS error or a native error.
 *
 * To be updated by https://sweetgreen.atlassian.net/browse/XP-3223
 */

export const AccountMenuCrashOptions = () => {
  const { t } = useLocalizationContext();
  const isEnabled = useFeatureFlag('permanent-order-crash-option-enabled');
  const { APP_VERSION_DATADOG: datadogVersion } = getEnvVars();

  const handleJsCrash = useCallback(() => {
    void datadog.logError(`Expected JS Crash #${datadogVersion}`);
    CrashTester.jsCrash(`Expected JS Crash #${datadogVersion}`);
  }, [datadogVersion]);

  const handleNativeCrash = useCallback(() => {
    void datadog.logError(`Expected Native Crash #${datadogVersion}`);
    CrashTester.nativeCrash(`Expected Native Crash #${datadogVersion}`);
  }, [datadogVersion]);

  if (!isEnabled) return null;

  return (
    <>
      <CrashOption
        label={t('account.sidebar.js-crash')}
        onPress={handleJsCrash}
      />
      {Platform.OS === 'web' ? null : (
        <CrashOption
          label={t('account.sidebar.native-crash')}
          onPress={handleNativeCrash}
        />
      )}
    </>
  );
};

const CrashOption = ({ label, onPress }: CrashOptionProps) => {
  const ref = React.useRef(null);
  const { match } = useResponsive();
  const { isInteracting } = usePressableState(ref);

  return (
    <Pressable
      ref={ref}
      style={styles.container}
      accessibilityLabel={label}
      onPress={onPress}
    >
      <BodyText size={match([2, 3])} underline={isInteracting}>
        {label}
      </BodyText>
    </Pressable>
  );
};

type CrashOptionProps = Readonly<{
  label: string;
  onPress: () => void;
}>;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.small,
    paddingVertical: theme.spacing['2'],
    marginVertical: theme.spacing['2'],
  },
});

import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

/**
 * Floating buttons container for the bag screen.
 */
export const BagFloatingCtas = (props: PropsWithChildren) => {
  const { children } = props;

  return <View style={[styles.floatingCta]}>{children}</View>;
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  floatingCta: {
    position: 'absolute',
    bottom: theme.spacing['6'],
    left: 0,
    right: 0,
    zIndex: 1,
    marginHorizontal: theme.spacing['4'],
    gap: theme.spacing['4'],
    flexDirection: 'row',
  },
});

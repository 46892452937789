import React from 'react';
import type { AccessibilityProps, ImageSourcePropType } from 'react-native';

import { useResponsive } from '../../../hooks';
import {
  PageHeroHeaderContainer,
  PageHeroHeaderCta,
  PageHeroHeaderImg,
  PageHeroHeaderText,
  PageHeroHeaderTitle,
} from './components';
import {
  containerPaletteStyles,
  ctaPalettes,
  textPaletteStyles,
} from './PageHeroHeader.styles';
import type {
  PageHeroHeaderCtaPalette,
  PageHeroHeaderPalette,
} from './PageHeroHeader.types';

// ─────────────────────────────────────────────────────────────────────────────

export const PageHeroHeader = (props: PageHeroHeaderProps) => {
  const {
    title,
    text,
    imgSource,
    imgA11yLabel,
    palette = DEFAULT_PALETTE,
    withTopPadding,
    ctaLabel,
    onCtaPress,
  } = props;

  const { minWidth } = useResponsive();

  const containerPaletteStyle =
    containerPaletteStyles[palette] ?? containerPaletteStyles[DEFAULT_PALETTE];
  const textPaletteStyle =
    textPaletteStyles[palette] ?? textPaletteStyles[DEFAULT_PALETTE];
  const ctaPalette: PageHeroHeaderCtaPalette =
    ctaPalettes[palette] ?? DEFAULT_CTA_PALETTE;

  // NOTE: The intention is that the CTA is rendered somewhere other than
  //       inside this component on a smaller breakpoint (mobile devices).
  const shouldRenderCta = minWidth.isSM;

  return (
    <PageHeroHeaderContainer
      paletteStyle={containerPaletteStyle}
      withTopPadding={withTopPadding}
    >
      {imgSource ? (
        <PageHeroHeaderImg
          source={imgSource}
          accessibilityLabel={imgA11yLabel}
        />
      ) : null}

      <PageHeroHeaderTitle paletteStyle={textPaletteStyle}>
        {title}
      </PageHeroHeaderTitle>

      <PageHeroHeaderText paletteStyle={textPaletteStyle}>
        {text}
      </PageHeroHeaderText>

      {shouldRenderCta ? (
        <PageHeroHeaderCta palette={ctaPalette} onPress={onCtaPress}>
          {ctaLabel}
        </PageHeroHeaderCta>
      ) : null}
    </PageHeroHeaderContainer>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const DEFAULT_PALETTE = 'kale';
const DEFAULT_CTA_PALETTE = 'lime';

// ─── Types ───────────────────────────────────────────────────────────────────

type PageHeroHeaderProps = Readonly<{
  title: string;
  text: string;
  ctaLabel: string;
  onCtaPress: () => void;
  withTopPadding?: boolean;
  imgSource?: ImageSourcePropType;
  imgA11yLabel?: AccessibilityProps['accessibilityLabel'];
  palette?: PageHeroHeaderPalette;
}>;

import { useFonts } from 'expo-font';
import { FONTS } from '@sg/garnish';

const FONT_CONFIG = {
  [FONTS.GRENETTE_PRO]: require('../../../assets/fonts/woff2/GrenettePro-Regular.woff2'),
  [FONTS.GRENETTE_PRO_REGULAR]: require('../../../assets/fonts/woff2/GrenettePro-Regular.woff2'),
  [FONTS.GRENETTE_PRO_LIGHT]: require('../../../assets/fonts/woff2/GrenettePro-Light.woff2'),

  [FONTS.SWEET_SANS]: require('../../../assets/fonts/woff2/SweetSans-Regular.woff2'),
  [FONTS.SWEET_SANS_REGULAR]: require('../../../assets/fonts/woff2/SweetSans-Regular.woff2'),
  [FONTS.SWEET_SANS_BOLD]: require('../../../assets/fonts/woff2/SweetSans-Bold.woff2'),

  [FONTS.SWEET_SANS_CONDENSED]: require('../../../assets/fonts/woff2/SweetSansCondensed-Bold.woff2'),
  [FONTS.SWEET_SANS_CONDENSED_BOLD]: require('../../../assets/fonts/woff2/SweetSansCondensed-Bold.woff2'),

  [FONTS.SWEET_SANS_NARROW]: require('../../../assets/fonts/woff2/SweetSansNarrow-Regular.woff2'),
  [FONTS.SWEET_SANS_NARROW_REGULAR]: require('../../../assets/fonts/woff2/SweetSansNarrow-Regular.woff2'),
  [FONTS.SWEET_SANS_NARROW_BOLD]: require('../../../assets/fonts/woff2/SweetSansNarrow-Bold.woff2'),

  [FONTS.SWEET_SANS_TEXT]: require('../../../assets/fonts/woff2/SweetSansText-Regular.woff2'),
  [FONTS.SWEET_SANS_TEXT_REGULAR]: require('../../../assets/fonts/woff2/SweetSansText-Regular.woff2'),
  [FONTS.SWEET_SANS_TEXT_BOLD]: require('../../../assets/fonts/woff2/SweetSansText-Bold.woff2'),

  [FONTS.SWEET_SANS_WIDE]: require('../../../assets/fonts/woff2/SweetSansWide-Regular.woff2'),
  [FONTS.SWEET_SANS_WIDE_REGULAR]: require('../../../assets/fonts/woff2/SweetSansWide-Regular.woff2'),
  [FONTS.SWEET_SANS_WIDE_BOLD]: require('../../../assets/fonts/woff2/SweetSansWide-Bold.woff2'),
};

export const useOrderAppFonts = () => {
  return useFonts(FONT_CONFIG);
};

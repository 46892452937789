import { useMemo } from 'react';

import { useApplePayPromise, useIsApplePaySupported } from '@order/components';
import { APPLE_PAY, PAYMENT_ADD } from '@order/constants';
import { useCreditCardOptions, usePaymentMethods } from '@order/hooks';

import { useGiftCardPurchasePaymentProfileQuery } from '../../graphql/GiftCardCheckout.generated';

// ─────────────────────────────────────────────────────────────────────────────

export const useGiftCardPaymentMethods = (
  params: UseGiftCardPaymentMethodsParams,
) => {
  const { pause } = params;

  const isApplePaySupported = useIsApplePaySupported();
  const {
    applePayHandlersRef,
    isSettingUpApplePay,
    applePayHostedFrameRef,
    handleApplePayMessages,
    proceedPayment,
    isApplePayReady,
  } = useApplePayPromise();

  // ─── Queries ─────────────────────────────────────────────────────────

  const { paymentMethods, isLoadingPaymentMethods } = usePaymentMethods({
    requestPolicy: 'network-only',
    pause,
  });

  const [paymentProfile] = useGiftCardPurchasePaymentProfileQuery({
    requestPolicy: 'network-only',
    pause,
  });

  // ─── Derived Data ────────────────────────────────────────────────────

  const shouldUseApplePay = Boolean(isApplePaySupported && isApplePayReady);

  const { creditCardOptions: paymentMethodOptions } = useCreditCardOptions({
    paymentMethods,
    shouldUseApplePay,
  });

  const defaultCardId = paymentProfile.data?.paymentProfile?.defaultCardId;
  const defaultPaymentMethodId = useMemo(() => {
    return getDefaultPaymentMethodId({
      defaultCardId,
      paymentMethodOptions,
      shouldUseApplePay,
    });
  }, [defaultCardId, paymentMethodOptions, shouldUseApplePay]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    isApplePaySupported,
    isSettingUpApplePay,
    isApplePayReady,

    applePayHandlersRef,
    applePayHostedFrameRef,

    proceedApplePayPayment: proceedPayment,
    handleApplePayMessages,
    paymentMethods: paymentMethodOptions,
    defaultPaymentMethodId,

    isLoadingPaymentMethods: isLoadingPaymentMethods || paymentProfile.fetching,
  };
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function getDefaultPaymentMethodId(
  params: GetDefaultPaymentMethodParams,
): string {
  const { defaultCardId, paymentMethodOptions, shouldUseApplePay } = params;

  if (defaultCardId === APPLE_PAY && !shouldUseApplePay) {
    return paymentMethodOptions[0]?.value;
  }

  const maybeDefaultPaymentMethod = paymentMethodOptions.find((method) => {
    const paymentMethodId = method.value;

    return paymentMethodId === defaultCardId;
  })?.value;
  const maybeFirstAvailablePaymentMethod = paymentMethodOptions[0]?.value;
  const fallbackPaymentMethod = PAYMENT_ADD;

  return (
    maybeDefaultPaymentMethod ??
    maybeFirstAvailablePaymentMethod ??
    fallbackPaymentMethod
  );
}

// ─── Types ───────────────────────────────────────────────────────────────────

type UseGiftCardPaymentMethodsParams = {
  pause: boolean;
};

type GetDefaultPaymentMethodParams = {
  defaultCardId?: string | null;
  paymentMethodOptions: ReadonlyArray<{ value: string }>;
  shouldUseApplePay: boolean;
};

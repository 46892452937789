import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { Main } from '@expo/html-elements';
import { theme } from '@sg/garnish';

import { PageWrapperSafeAreaView } from '@order/components';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsWrapper = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <PageWrapperSafeAreaView style={styles.wrapper} edges={['top']}>
      <Main style={styles.contentContainer}>{children}</Main>
    </PageWrapperSafeAreaView>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  contentContainer: {
    flex: 1,
  },
});

/* cSpell:ignore pageview */

import type { TelemetryEventName } from '../../TelemetryEvent';
import type {
  TelemetryBag,
  TelemetrySelectedProduct,
} from '../../TelemetryEvent.types';
import type { TelemetryEventOverride } from '../../TelemetryEventOverride';
import type { GoogleAnalyticsEventPayload } from '../../vendors';

// ─────────────────────────────────────────────────────────────────────────────

export const googleAnalyticsEventOverrides: GoogleAnalyticsEventOverrides = {
  'app.page_view': ({ context, event: { payload } }) => ({
    event: {
      event: 'pageview',
      userId: context.userId,
      page_title: payload?.pageTitle,
      page_location: payload?.pageLocation,
    },
  }),

  // ─── Auth ────────────────────────────────────────────────────────────

  'sign-in.success': ({ context }) => ({
    event: {
      event: 'sign_in',
      userId: context.userId,
    },
  }),

  'logout.success': ({ context }) => ({
    event: {
      event: 'sign_out',
      userId: context.userId,
    },
  }),

  'join.success': ({ context }) => ({
    event: {
      event: 'registration_completed',
      userId: context.userId,
    },
  }),

  // ─── Sweetpass ───────────────────────────────────────────────────────

  'sweetpass.view': ({ context }) => ({
    event: {
      event: 'view_sweetpass',
      userId: context.userId,
    },
  }),

  'sweetpass_benefits.view.success': ({ context }) => ({
    event: {
      event: 'explore_sweetpass',
      userId: context.userId,
    },
  }),

  'sweetpass.checkout.view': ({ context }) => ({
    event: {
      event: 'add_to_cart_sweetpass+',
      userId: context.userId,
    },
  }),

  'sweetpass_account.upgrade.success': ({ context }) => ({
    event: {
      event: 'upgrade_sweetpass+_success',
      userId: context.userId,
    },
  }),

  // ─── Menu View ───────────────────────────────────────────────────────

  'menu.view': ({ context, event: { payload } }) => ({
    event: {
      event: 'view_restaurant',
      userId: context.userId,
      restaurant_id: payload?.restaurant?.id,
    },
  }),

  // ─── Product View ────────────────────────────────────────────────────

  'pdp.view': ({ context, event: { payload } }) => ({
    event: {
      event: 'view_item',
      userId: context.userId,
      ...formatProductViewForAnalytics(payload?.selectedProduct),
    },
  }),

  // ─── Bag Interactions ────────────────────────────────────────────────

  'bag.view': ({ context, event: { payload } }) => ({
    event: {
      event: 'view_cart',
      userId: context.userId,
      ...formatBagForAnalytics(payload),
    },
  }),

  'pdp.add_to_bag': ({ context, event: { payload } }) => ({
    event: {
      event: 'add_to_cart',
      userId: context.userId,
      ...formatProductViewForAnalytics(payload?.selectedProduct),
    },
  }),

  'menu.quick_add_to_bag': ({ context, event: { payload } }) => ({
    event: {
      event: 'add_to_cart',
      userId: context.userId,
      ...formatProductViewForAnalytics(payload?.selectedProduct),
    },
  }),

  'menu.one_click_add_to_bag_submit': ({ context, event: { payload } }) => ({
    event: {
      event: 'add_to_cart',
      userId: context.userId,
      ...formatProductViewForAnalytics(payload?.selectedProduct),
    },
  }),

  'bag.adjust_quantity': ({ context, event: { payload } }) => ({
    event: {
      event: 'add_to_cart',
      userId: context.userId,
      ...formatProductViewForAnalytics(payload?.selectedProduct),
    },
  }),

  'checkout.view': ({ context, event: { payload } }) => ({
    event: {
      event: 'begin_checkout',
      userId: context.userId,
      ...formatBagForAnalytics(payload),
    },
  }),

  // ─── Purchase ────────────────────────────────────────────────────────

  'checkout.success': ({ context, event: { payload } }) => ({
    event: {
      event: 'purchase',
      userId: context.userId,
      transaction_id: payload?.orderId,
      new_customer: payload?.isNewCustomer,
      ...formatBagForAnalytics(payload),
      ...formatCustomerPersonalInfo(payload),
    },
  }),
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * Google Analytics format for customer info.
 * @see {@link https://support.google.com/google-ads/answer/13262500}
 * @section Identify and define your enhanced conversions variables
 */
function formatCustomerPersonalInfo(payload: TelemetryBag) {
  const info = {
    email: payload?.customer?.email,
    phone_number: payload?.customer?.phoneNumber,
    first_name: payload?.customer?.firstName,
    last_name: payload?.customer?.lastName,
    street: payload?.street,
    city: payload?.city,
    state: payload?.state,
    postal_code: payload?.zipCode,
    country: payload?.country,
  };

  return {
    // enhanced conversion data object version.
    enhanced_conversion_data: info,

    // "dlv_" prefixed version.
    dlv_email: info.email,
    dlv_phone_number: info.phone_number,
    dlv_first_name: info.first_name,
    dlv_last_name: info.last_name,
    dlv_street: info.street,
    dlv_city: info.city,
    dlv_state: info.state,
    dlv_postal_code: info.postal_code,
    dlv_country: info.country,

    // spread out version.
    ...info,
  };
}

/**
 * Helper for formatting a bag for analytics.
 * @see {formatProductForAnalytics}
 * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#view_item}
 */
function formatProductViewForAnalytics(
  viewedProduct?: TelemetrySelectedProduct,
) {
  return {
    currency,
    value: viewedProduct?.cost,
    items: [formatProductForAnalytics(viewedProduct)],
  };
}

/**
 * Helper for formatting a bag for analytics.
 * @see {formatProductForAnalytics}
 * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#begin_checkout}
 */
function formatBagForAnalytics(bag?: TelemetryBag) {
  const items = formatProductsForAnalytics(bag?.products);

  const productIds = items?.map((item) => item.item_id);
  const productNames = items?.map((item) => item.item_name);
  const totalQuantity = items?.reduce(
    (acc, item) => acc + (item?.quantity ?? 0),
    0,
  );

  return {
    currency,
    value: bag?.orderValue,
    order_type: bag?.orderType,
    product_ids: productIds,
    product_names: productNames,
    quantity: totalQuantity,
    items,
  };
}

/**
 * Helper for formatting arrays of products.
 * @see {formatProductForAnalytics}
 */
function formatProductsForAnalytics(
  products?: readonly TelemetrySelectedProduct[],
) {
  return products?.map((product, index) => ({
    index,
    ...formatProductForAnalytics(product),
  }));
}

/**
 * Format needed for Google Analytics e-commerce item.
 * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#add_to_cart_item}
 */
function formatProductForAnalytics(product?: TelemetrySelectedProduct) {
  return {
    currency,
    item_id: product?.id,
    item_name: product?.name,
    price: product?.cost,
    quantity: product?.quantity,
  };
}

// ─── Constants ───────────────────────────────────────────────────────────────

const currency = 'USD'; // Required field, all sweetgreen products are in USD.

// ─── Types ───────────────────────────────────────────────────────────────────

type GoogleAnalyticsEventOverrides = {
  readonly [Name in TelemetryEventName]?: TelemetryEventOverride<
    Name,
    GoogleAnalyticsEventPayload
  >;
};

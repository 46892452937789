import { Platform } from 'react-native';
import type { NativeStackNavigationOptions } from '@react-navigation/native-stack';

/**
 * Returns cross-platform custom back button image source for React Navigation.
 *
 * @see {@link https://reactnavigation.org/docs/native-stack-navigator/#headerbackimagesource headerBackImageSource}
 *
 * ─────────────────────────────────────────────────────────────────────────────
 *
 * NOTE #1: In the case of the Expo Go app, the asset is set during runtime, thus we can
 *          see the standard icon for a brief moment. It will be bundled and displayed
 *          instantly in the case of development (not dev client) and production level builds.
 *
 * NOTE #2: There is a mysterious issue with adaptive images and React Navigation on iOS.
 *
 * It crops the assets in the final (production) bundle instead of using the "contain"
 * approach for the series of local images (back-icon@1x.png, back-icon@2x.png,...),
 * resulting in a "misaligned" final picture.
 *
 * We have two choices here:
 *
 * 1. use merely a low quality (@1x).png image, which looks awful,
 * 2. or use a.psd file with our asset as a smart object, which will render
 *    similar to SVG and look amazing
 *
 * The final alternative is a little "hacky," but it fulfills the criteria.
 */
export const getCustomBackButtonIcon =
  (): NativeStackNavigationOptions['headerBackImageSource'] => {
    return Platform.select({
      ios: require('./assets/back-icon.psd'),
      default: require('./assets/back-icon.png'),
    });
  };

export const pageTitles = {
  'page-title.home': generatePageTitle('Order', 'Home'),
  'page-title.sweetpass': generatePageTitle('Sweetpass'),
  'page-title.sweetpass-benefits': generatePageTitle('Sweetpass', 'Benefits'),
  'page-title.locations': generatePageTitle('Order', 'Locations'),
  'page-title.account.menu': generatePageTitle('Order', 'Account'),
  'page-title.account.info': generatePageTitle('Order', 'Account', 'Info'),
  'page-title.account.sweetpass-membership': generatePageTitle(
    'Order',
    'Account',
    'Sweetpass Membership',
  ),
  'page-title.account.credit-and-promo-codes': generatePageTitle(
    'Order',
    'Account',
    'Credit & promo codes',
  ),
  'page-title.account.payment-and-gift-cards': generatePageTitle(
    'Order',
    'Account',
    'Payment & gift cards',
  ),
  'page-title.account.payments': generatePageTitle(
    'Order',
    'Account',
    'Payments',
  ),
  'page-title.account.addresses': generatePageTitle(
    'Order',
    'Account',
    'Addresses',
  ),
  'page-title.account.orders': generatePageTitle('Order', 'Account', 'Orders'),
  'page-title.account.favorites': generatePageTitle(
    'Order',
    'Account',
    'Favorites',
  ),
  'page-title.account.refer-friend': generatePageTitle(
    'Order',
    'Account',
    'Refer a Friend',
  ),
  'page-title.account.dietary-restrictions': generatePageTitle(
    'Order',
    'Account',
    'Dietary Restrictions',
  ),
  'page-title.scan-in-store': generatePageTitle('Order', 'Scan in store'),
  'page-title.join-or-sign-in': generatePageTitle(
    'Order',
    'Sign in or create an account',
  ),
  'page-title.sign-in': generatePageTitle('Order', 'Sign In'),
  'page-title.join': generatePageTitle('Order', 'Join'),
  'page-title.menu': generatePageTitle('Order', '{restaurantName}', 'Menu'),
  'page-title.cart': generatePageTitle('Order', 'Cart'),
  'page-title.checkout': generatePageTitle('Order', 'Checkout'),
  'page-title.order-status': generatePageTitle('Order', 'Order Complete'),
};

function generatePageTitle(...titleElements: readonly string[]) {
  return [...titleElements, 'sweetgreen'].join(' | ');
}

import * as React from 'react';
import Svg, { Circle } from 'react-native-svg';
import { theme } from '@garnish/constants';

import { AnimatedPin } from './AnimatedPin';
import { PinWrapper } from './PinWrapper';

export const CustomerPin = (props: CustomerPinProps) => {
  const { outerColor = theme.colors.WHITE, innerColor = theme.colors.GREEN_2 } =
    props;

  return (
    <PinWrapper>
      <AnimatedPin size={46} cx={18} cy={23} radius={12}>
        <Svg width="32" height="32">
          <Circle cx="16" cy="16" r="12" fill={outerColor} />
          <Circle cx="16" cy="16" r="8" fill={innerColor} />
        </Svg>
      </AnimatedPin>
    </PinWrapper>
  );
};

type CustomerPinProps = Readonly<{
  outerColor?: string;
  innerColor?: string;
}>;

import {
  getFocusedRouteNameFromRoute,
  useRoute,
} from '@react-navigation/native';

export const useActiveRouteName = () => {
  const route = useRoute();
  const routeName = getFocusedRouteNameFromRoute(route) ?? route.name; // support both top level and nested routes

  return routeName;
};

import React from 'react';
import type { LayoutChangeEvent, TextProps } from 'react-native';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import { BodyText } from '../Text';

export const ProgressBar = ({
  nodes,
  maxFontSizeMultiplier,
}: ProgressBarProps) => {
  const { nodeWidth, measureNodes } = useProgressBarMeasurement(nodes.length);

  return (
    <View
      testID="sg-progress-bar"
      style={styles.progressBar}
      onLayout={measureNodes}
    >
      {nodes.map((node) => (
        <ProgressBarNode
          key={node.id}
          node={node}
          width={nodeWidth}
          maxFontSizeMultiplier={maxFontSizeMultiplier}
        />
      ))}
    </View>
  );
};

const ProgressBarNode = ({
  node,
  width,
  maxFontSizeMultiplier,
}: ProgressBarNodeProps) => {
  const { fontScale } = useWindowDimensions();

  const { id, label, completed, variation = 'hot-spots' } = node;
  const dynamicStyle = useStyle(() => ({ width }));
  const lineStyle = completed ? styles.completedLine : styles.pendingLine;

  const circleStyle = completed ? styles.completedCircle : styles.pendingCircle;
  const [bottom, setTextMarginBottom] = React.useState(-24);
  const onLayout = React.useCallback((event: LayoutChangeEvent) => {
    const isMultipleLines = event.nativeEvent.layout.height >= LINE_HEIGHT * 2;

    setTextMarginBottom(
      -(isMultipleLines ? theme.spacing['10'] : theme.spacing['6']),
    );
  }, []);
  const labelStyle = useStyle(
    () => ({ bottom: bottom * fontScale }),
    [bottom, fontScale],
  );

  return (
    <View
      style={[dynamicStyle, styles.node]}
      testID={`sg-progress-bar-node-${id}`}
    >
      <View style={[styles.absoluteLine, lineStyle]} />
      {variation === 'hot-spots' && (
        <View style={[styles.absoluteCircle, circleStyle]} />
      )}
      <BodyText
        onLayout={onLayout}
        style={[styles.label, labelStyle]}
        size={5}
        maxFontSizeMultiplier={maxFontSizeMultiplier}
      >
        {label}
      </BodyText>
    </View>
  );
};

const useProgressBarMeasurement = (nodeCount: number) => {
  const [progressBarWidth, setProgressBarWidth] = React.useState(640);
  const measureNodes = React.useCallback((event: LayoutChangeEvent) => {
    setProgressBarWidth(event.nativeEvent.layout.height);
  }, []);
  const nodeWidth = React.useMemo(
    () => progressBarWidth / nodeCount,
    [nodeCount, progressBarWidth],
  );

  return { nodeWidth, measureNodes };
};

type ProgressBarProps = Readonly<{
  nodes: readonly ProgressBarNodeType[];
  maxFontSizeMultiplier?: TextProps['maxFontSizeMultiplier'];
}>;

type ProgressBarNodeProps = Readonly<{
  node: ProgressBarNodeType;
  width: number;
  maxFontSizeMultiplier?: TextProps['maxFontSizeMultiplier'];
}>;

export type ProgressBarNodeType = Readonly<{
  id: string;
  label: string;
  completed: boolean;
  variation?: 'linear' | 'hot-spots';
}>;

const LINE_HEIGHT = 16;
const styles = StyleSheet.create({
  progressBar: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  node: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  absoluteLine: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  absoluteCircle: {
    position: 'absolute',
  },
  completedLine: {
    backgroundColor: theme.colors.GREEN_2,
    height: 1,
  },
  pendingLine: {
    height: 1,
    backgroundColor: theme.colors.NEUTRAL_3,
  },
  completedCircle: {
    backgroundColor: theme.colors.GREEN_2,
    borderRadius: 12,
    width: 12,
    height: 12,
  },
  pendingCircle: {
    backgroundColor: theme.colors.NEUTRAL_7,
    borderColor: theme.colors.NEUTRAL_3,
    borderWidth: 1,
    borderRadius: 12,
    width: 12,
    height: 12,
  },
  label: {
    position: 'absolute',
    color: theme.colors.NEUTRAL_2,
    textAlign: 'center',
    lineHeight: LINE_HEIGHT,
  },
});

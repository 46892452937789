import type { SharedValue } from 'react-native-reanimated';
import { useAnimatedStyle } from 'react-native-reanimated';

import {
  calculateCarouselScaleOffset,
  getCarouselSlideWidthWithoutExtraSpace,
} from '../../Carousel.utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns the carousel's animated styles based on the provided animated values.
 */
export const useCarouselAnimatedStyles = (
  params: UseCarouselAnimatedStylesParams,
) => {
  const {
    transitionEffect,
    carouselScale,
    carouselTranslateX,
    carouselActiveSlideIndexSharedValue,
    carouselSlideWidth,
    stagePadding,
    gap,
  } = params;

  // ─── Styles ──────────────────────────────────────────────────────────

  const carouselAnimatedStyles = useAnimatedStyle(() => {
    const { value: activeItemIndex } = carouselActiveSlideIndexSharedValue;
    const { value: scale } = carouselScale;
    const { value: translateX } = carouselTranslateX;

    const itemWidthWithoutExtraSpace = getCarouselSlideWidthWithoutExtraSpace({
      slideWidth: carouselSlideWidth.value,
      stagePadding,
      gap,
    });

    // When the carousel is scaled (while dragging), we need to use a
    // `translate` offset to emulate the `transform-origin: center` behavior,
    // which is not supported by React Native.
    const shouldAddTranslateOffset = scale !== 1;

    const translateOffset = calculateCarouselScaleOffset({
      scale,
      activeSlideIndex: activeItemIndex,
      slideWidth: itemWidthWithoutExtraSpace,
    });

    const translateXOffset = shouldAddTranslateOffset ? translateOffset : 0;
    const translateXWithOffset = translateX + translateXOffset;

    return {
      transform: [
        { translateX: translateXWithOffset },
        ...(transitionEffect === 'scale' ? [{ scale }] : []),
      ],
    };
  });

  const carouselItemAnimatedStyles = useAnimatedStyle(() => {
    const bothSidesStagePadding = stagePadding * 2;

    // To use stage padding, we use a negative right margin by computing
    // stage padding on both sides + a single side gap.
    const marginRight = -(bothSidesStagePadding + gap);
    const paddingHorizontal = stagePadding + gap;

    return { paddingHorizontal, marginRight };
  });

  // ─────────────────────────────────────────────────────────────────────

  return {
    carouselAnimatedStyles,
    carouselItemAnimatedStyles,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseCarouselAnimatedStylesParams = Readonly<{
  transitionEffect: 'scale' | 'none';
  carouselActiveSlideIndexSharedValue: SharedValue<number>;
  carouselScale: SharedValue<number>;
  carouselTranslateX: SharedValue<number>;
  carouselSlideWidth: SharedValue<number>;
  stagePadding: number;
  gap: number;
}>;

import { useCallback } from 'react';
import { useClient } from 'urql';

import {
  BagAvailablePointsDocument,
  type BagAvailablePointsQuery,
} from '../../GraphQL/BagAvailablePoints.generated';

// ────────────────────────────────────────────────────────────────────────────

/**
 * A hook for fetching available points.
 */
export const useBagAvailablePoints = (isLoyaltyV2Enabled: boolean) => {
  const client = useClient();

  // ─── Queries ──────────────────────────────────────────────────────────────

  const fetchBagAvailablePoints = useCallback(async () => {
    if (!isLoyaltyV2Enabled) return { availablePoints: 0 };

    const queryRewards = client.query<BagAvailablePointsQuery>;

    const response = await queryRewards(
      BagAvailablePointsDocument,
      {},
      { requestPolicy: 'network-only' },
    ).toPromise();

    const availablePoints =
      response.data?.loyaltyProfile?.points?.available ?? 0;

    return { availablePoints };
  }, [isLoyaltyV2Enabled, client.query]);

  // ──────────────────────────────────────────────────────────────────────────

  return { fetchBagAvailablePoints };
};

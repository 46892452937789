import type { RouteProp } from '@react-navigation/native';
import { useRoute } from '@react-navigation/native';

import { useChallengesAndRewards } from '@order/ChallengesAndRewards';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { usePlacedOrdersCounter } from '@order/PlacedOrdersCounter';

import type { ModalStackParamList } from '../../../../navigation/AppNavigation.props';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns render flags for Sweetpass Upsell CTA in Bag.
 */
export const useShouldRenderBagSweetpassUpsellCta = () => {
  const { params } = useRoute<RouteProp<ModalStackParamList, 'Bag'>>();
  const { referrer } = params ?? {};

  const { isFetchingRewards, rewards } = useChallengesAndRewards();
  const { placedOrdersCount } = usePlacedOrdersCounter();

  // NOTE: `CELS-683-long-sweetpass-upsell-in-bag-enabled` is utilized as a prerequisite for this short FF.
  //       Please make use of it after this short FF has been deleted.
  const sweetpassUpsellCtaLocation = useFeatureFlag(
    'CELS-683-short-sweetpass-upsell-cta-location-in-bag',
  );

  // ─── Render requirements ─────────────────────

  // Upsell CTA should be displayed, when the user does not have any rewards...
  const hasNoRewards = !isFetchingRewards && rewards?.length === 0;

  // ...or when the user has an acceptable number of orders placed to display the CTA.
  const hasPlacedAcceptableNumberOfOrders =
    placedOrdersCount <= MAX_PLACED_ORDERS_NUMBER_TO_SHOW_CTA;

  // If the user was redirected from the Sweetpass Upgrade flow, the related CTA should have been hidden.
  const shouldHideCta = referrer === 'sweetpass-upgrade';

  const shouldRenderCta =
    !shouldHideCta && (hasPlacedAcceptableNumberOfOrders || hasNoRewards);

  // ─── Render location ─────────────────────────

  const shouldRenderAfterRewards =
    shouldRenderCta && sweetpassUpsellCtaLocation === 'after-rewards';
  const shouldRenderBeforeLedger =
    shouldRenderCta && sweetpassUpsellCtaLocation === 'before-ledger';

  return { shouldRenderAfterRewards, shouldRenderBeforeLedger };
};

// ─── Constants ───────────────────────────────────────────────────────────────

const MAX_PLACED_ORDERS_NUMBER_TO_SHOW_CTA = 5;

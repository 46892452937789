/* cSpell:ignore libphonenumber */

import {
  formatIncompletePhoneNumber,
  getCountryCallingCode,
  parseIncompletePhoneNumber,
} from 'libphonenumber-js';
import type { CountryCode } from 'libphonenumber-js/types';

import { minifyPhoneNumber } from '../../../../utils';

// ────────────────────────────────────────────────────────────────────────────────

export const formatAndMinifyPhoneNumber = (
  value: string,
  countryCode: CountryCode = 'US',
) => {
  return minifyPhoneNumber(formatIncompletePhoneNumber(value, countryCode));
};

export const parsePhoneNumber = (
  phoneNumber: string,
  countryCode: CountryCode = 'US',
) => {
  const countryCallingCode = getCountryCallingCode(countryCode) as string;
  const rawPhoneNumber = parseIncompletePhoneNumber(phoneNumber);
  const rawPhoneNumberWithoutCountryCode = rawPhoneNumber.startsWith(
    countryCallingCode,
  )
    ? rawPhoneNumber.slice(countryCallingCode.length)
    : rawPhoneNumber;

  return {
    formatted: formatAndMinifyPhoneNumber(rawPhoneNumber, countryCode),
    parsed: rawPhoneNumberWithoutCountryCode,
  };
};

export const validatePhoneNumber = (props: ValidatePhoneNumberProps) => {
  const {
    phoneNumber,
    countryCallingCode,
    delimiter = '-',
    maxLength = Number.POSITIVE_INFINITY,
  } = props;

  const parsedPhoneNumber = parseIncompletePhoneNumber(phoneNumber);
  const phoneNumberMaxLength = parsedPhoneNumber.startsWith(countryCallingCode)
    ? maxLength + countryCallingCode.length
    : maxLength;

  // ────────────────────────────────────────────────────────────────────

  const hasRepeatableDelimiters = phoneNumber.includes(
    `${delimiter.repeat(2)}`,
  );
  const hasNotValidChars = Number.isNaN(
    Number(phoneNumber.replaceAll(new RegExp(`${delimiter}`, 'g'), '')),
  );
  const hasValidLength = parsedPhoneNumber.length <= phoneNumberMaxLength;

  // ────────────────────────────────────────────────────────────────────

  return !hasRepeatableDelimiters && !hasNotValidChars && hasValidLength;
};

export const getCurrentCaretPosition = (textFieldElement: HTMLInputElement) => {
  return textFieldElement.selectionStart ?? 0;
};

export const getPhoneNumberSpecialCharsNumber = (value: string) => {
  return [...value].filter((char) => Number.isNaN(Number(char))).length;
};

export const getPhoneNumberChangeType = (prevValue: string, value: string) => {
  return prevValue.length < value.length
    ? PHONE_NUMBER_CHANGE_ADDITION
    : PHONE_NUMBER_CHANGE_SUBTRACTION;
};

export const getAddedPhoneNumberCharsNumber = (
  prevValue: string,
  value: string,
) => {
  return value.length - prevValue.length;
};

export const getAllSpecialCharsNumber = (
  prevFormattedValue: string,
  formattedValue: string,
) => {
  const prevSpecialCharsNumber =
    getPhoneNumberSpecialCharsNumber(prevFormattedValue);
  const specialCharsNumber = getPhoneNumberSpecialCharsNumber(formattedValue);

  const specialCharsNumbersDifference =
    specialCharsNumber - prevSpecialCharsNumber;
  const addedSpecialCharsNumber =
    specialCharsNumbersDifference > 0 ? specialCharsNumbersDifference : 0;

  return {
    prevSpecialCharsNumber,
    specialCharsNumber,
    addedSpecialCharsNumber,
  };
};

/**
 * Checks if the phone number has been reformatted
 *
 * - XXX-XXX-XXXX -> XXXXXXXXXXX
 * - XXXXXXXXXXX -> XXX-XXX-XXX
 */
export const checkIfReformattingHappened = (
  props: Pick<GetCaretPositionProps, 'prevFormattedValue' | 'formattedValue'>,
) => {
  const { prevFormattedValue, formattedValue } = props;
  const { prevSpecialCharsNumber, specialCharsNumber } =
    getAllSpecialCharsNumber(prevFormattedValue, formattedValue);

  return (
    (prevSpecialCharsNumber === 0 && specialCharsNumber > 0) ||
    (prevSpecialCharsNumber > 0 && specialCharsNumber === 0)
  );
};

/**
 * Returns the correct caret position after a "change" or "input" event
 */
export const getCaretPositionAfterChange = (
  props: Pick<
    GetCaretPositionProps,
    'textFieldEl' | 'formattedValue' | 'prevValue' | 'value'
  >,
) => {
  const { textFieldEl, formattedValue, prevValue, value } = props;

  const phoneNumberChangeType = getPhoneNumberChangeType(prevValue, value);
  const currentCaretPosition = getCurrentCaretPosition(textFieldEl);

  // ────────────────────────────────────────────────────────────────────

  if (phoneNumberChangeType === PHONE_NUMBER_CHANGE_ADDITION) {
    const previousChar = formattedValue[currentCaretPosition - 1];
    const isPreviousCharSpecial = previousChar === '-';
    const specialCharOffset = isPreviousCharSpecial ? 1 : 0;

    return currentCaretPosition + specialCharOffset;
  }

  // ────────────────────────────────────────────────────────────────────

  if (phoneNumberChangeType === PHONE_NUMBER_CHANGE_SUBTRACTION) {
    return currentCaretPosition;
  }

  return 0;
};

/**
 * Returns the correct caret position after "reformatting" event
 *
 * - XXX-XXX-XXXX -> XXXXXXXXXXX
 * - XXXXXXXXXXX -> XXX-XXX-XXX
 */
export const getCaretPositionAfterReformatting = (
  props: GetCaretPositionProps,
) => {
  const { textFieldEl, prevValue, prevFormattedValue, value, formattedValue } =
    props;
  const phoneNumberChangeType = getPhoneNumberChangeType(prevValue, value);
  const currentCaretPosition = getCurrentCaretPosition(textFieldEl);

  // ────────────────────────────────────────────────────────────────────

  // In order to determine the correct cursor position after reformat caused by "addition",
  // we need to consider these factors:
  //
  // - Position of the caret before reformatting
  // - Number of special characters before the caret
  // - Number of new special characters after reformatting
  // - and the number of new characters in phone numbers

  if (phoneNumberChangeType === PHONE_NUMBER_CHANGE_ADDITION) {
    const initialCaretPosition = currentCaretPosition - 1;
    const specialCharsCountBeforeCaret = getPhoneNumberSpecialCharsNumber(
      prevFormattedValue.slice(0, initialCaretPosition),
    );
    const { addedSpecialCharsNumber } = getAllSpecialCharsNumber(
      prevFormattedValue,
      formattedValue,
    );
    const addedCharsOffset =
      initialCaretPosition > 0 ? addedSpecialCharsNumber : 0;
    const addedCharsNumber = getAddedPhoneNumberCharsNumber(prevValue, value);

    return (
      initialCaretPosition -
      specialCharsCountBeforeCaret +
      addedCharsOffset +
      addedCharsNumber
    );
  }

  // ────────────────────────────────────────────────────────────────────

  // And when it comes to "subtraction", we must consider only these factors:
  //
  // - The current position of the caret
  // - and the number of new possible special characters.

  if (phoneNumberChangeType === PHONE_NUMBER_CHANGE_SUBTRACTION) {
    const possibleSpecialCharsNumber = getPhoneNumberSpecialCharsNumber(
      formatAndMinifyPhoneNumber(
        prevFormattedValue.slice(0, currentCaretPosition),
      ),
    );

    return currentCaretPosition + possibleSpecialCharsNumber;
  }

  return 0;
};

/**
 * Returns the correct caret position after any text field change event
 */
export const getCaretPositionAfterFormatting = (
  props: GetCaretPositionProps,
) => {
  const ifReformattingHappened = checkIfReformattingHappened({
    prevFormattedValue: props.prevFormattedValue,
    formattedValue: props.formattedValue,
  });

  return ifReformattingHappened
    ? getCaretPositionAfterReformatting(props)
    : getCaretPositionAfterChange(props);
};

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

const PHONE_NUMBER_CHANGE_ADDITION = 'ADDITION';
const PHONE_NUMBER_CHANGE_SUBTRACTION = 'SUBTRACTION';

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ValidatePhoneNumberProps = Readonly<{
  phoneNumber: string;
  countryCallingCode: string;
  delimiter?: string;
  maxLength?: number;
}>;

type GetCaretPositionProps = Readonly<{
  textFieldEl: HTMLInputElement;
  prevValue: string;
  prevFormattedValue: string;
  value: string;
  formattedValue: string;
}>;

import React from 'react';
import { StyleSheet } from 'react-native';
import { logger as LOG } from '@garnish/logger';
import { Button, theme } from '@sg/garnish';

import { clientInstance, config } from '@order/AzureAuth';

// ─────────────────────────────────────────────────────────────────────────────

export const AccountPrimaryMenuTokenCta = () => (
  <Button
    style={styles.btn}
    onPress={async () => {
      const { apiLoginRequest } = config.scopes;

      try {
        await clientInstance?.initialize();

        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        const account = clientInstance?.getActiveAccount()!;
        const apiAccessTokenRequest = { ...apiLoginRequest, account };

        const response = await clientInstance?.acquireTokenSilent(
          apiAccessTokenRequest,
        );

        logger.info(response);
      } catch (error) {
        logger.error(error);
      }
    }}
  >
    Generate new tokens
  </Button>
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  btn: {
    marginVertical: theme.spacing['4'],
  },
});

// ─── Helpers ─────────────────────────────────────────────────────────────────

LOG.enable('TOKEN_CTA');
const logger = LOG.extend('TOKEN_CTA');

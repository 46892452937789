import React, {
  type PropsWithChildren,
  type ReactNode,
  useCallback,
  useMemo,
} from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  View,
  type ViewStyle,
} from 'react-native';
import { FullWindowOverlay } from 'react-native-screens';
import { useFocusEffect } from '@react-navigation/native';
import { Portal } from '@gorhom/portal';

// ─────────────────────────────────────────────────────────────────────────────

export const ActiveIngredientQuantityStepperOverlay = (
  props: ActiveIngredientQuantityStepperOverlayProps,
) => {
  const {
    isVisible,
    children,
    dismiss,
    stepperPositionY,
    stepperPositionX,
    accessibilityLabel,
  } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const stepperContainerStyles = useMemo<ViewStyle>(
    () => ({
      transform: [
        { translateY: stepperPositionY },
        { translateX: stepperPositionX },
      ],
    }),
    [stepperPositionX, stepperPositionY],
  );

  // ─── Effects ─────────────────────────────────────────────────────────

  // Closes modal on screen blur
  useFocusEffect(useCallback(() => dismiss, [dismiss]));

  // ─────────────────────────────────────────────────────────────────────

  if (!isVisible) return;

  return (
    <Portal>
      <ActiveIngredientQuantityStepperOverlayContainer>
        <Pressable
          style={StyleSheet.absoluteFill}
          role="button"
          onPress={dismiss}
          collapsable={false}
          accessibilityLabel={accessibilityLabel}
        />

        <View style={[styles.stepperContainer, stepperContainerStyles]}>
          {children}
        </View>
      </ActiveIngredientQuantityStepperOverlayContainer>
    </Portal>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const ActiveIngredientQuantityStepperOverlayContainer = (
  props: PropsWithChildren,
) => {
  // See: https://github.com/gorhom/react-native-portal?tab=readme-ov-file#react-native-screens-integration
  if (Platform.OS === 'ios') {
    return <FullWindowOverlay>{props.children}</FullWindowOverlay>;
  }

  return <View style={StyleSheet.absoluteFill}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  stepperContainer: {
    position: 'absolute',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ActiveIngredientQuantityStepperOverlayProps = {
  isVisible: boolean;
  dismiss: () => void;
  children: ReactNode;
  accessibilityLabel: string;
  stepperPositionY: number;
  stepperPositionX: number;
};

import React from 'react';
import { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const ActiveIngredientQuantityStepperText = (
  props: ComponentProps<typeof BodyText>,
) => {
  const { children, style, ...restProps } = props;

  return (
    <BodyText
      sizeMatch={['18']}
      style={[styles.text, style]}
      maxFontSizeMultiplier={1.1}
      {...restProps}
    >
      {children}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    color: theme.colors.CREAM,
    textAlign: 'center',
    minWidth: theme.spacing['4'],
  },
});

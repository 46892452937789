/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReorderRestaurantQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type ReorderRestaurantQuery = {
  readonly __typename?: 'Query';
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
    readonly name: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly slug: string;
    readonly address: string;
    readonly city: string;
    readonly state: string;
    readonly zipCode: string;
    readonly isOutpost: boolean;
    readonly phone: string;
    readonly flexMessage: string | null;
    readonly isAcceptingOrders: boolean;
    readonly notAcceptingOrdersReason: string;
    readonly hours: {
      readonly __typename?: 'RestaurantHours';
      readonly formatted: string | null;
    };
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  } | null;
};

export type ReorderRestaurantRequiredDataFragment = {
  readonly __typename?: 'Restaurant';
  readonly id: string;
  readonly name: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly slug: string;
  readonly address: string;
  readonly city: string;
  readonly state: string;
  readonly zipCode: string;
  readonly isOutpost: boolean;
  readonly phone: string;
  readonly flexMessage: string | null;
  readonly isAcceptingOrders: boolean;
  readonly notAcceptingOrdersReason: string;
  readonly hours: {
    readonly __typename?: 'RestaurantHours';
    readonly formatted: string | null;
  };
  readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
};

export const ReorderRestaurantRequiredDataFragmentDoc = gql`
  fragment ReorderRestaurantRequiredData on Restaurant {
    id
    name
    latitude
    longitude
    slug
    address
    city
    state
    zipCode
    isOutpost
    phone
    hours {
      formatted
    }
    flexMessage
    isAcceptingOrders
    notAcceptingOrdersReason
    asset {
      url
    }
  }
`;
export const ReorderRestaurantDocument = gql`
  query ReorderRestaurant($id: ID!) {
    restaurant(id: $id) {
      ...ReorderRestaurantRequiredData
    }
  }
  ${ReorderRestaurantRequiredDataFragmentDoc}
`;

export function useReorderRestaurantQuery(
  options: Omit<Urql.UseQueryArgs<ReorderRestaurantQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ReorderRestaurantQuery, ReorderRestaurantQueryVariables>(
    { query: ReorderRestaurantDocument, ...options },
  );
}

import React, { useCallback, useLayoutEffect } from 'react';

import { useCart } from '@order/hooks';

import type { DeliveryEditProductDetailsScreenProps } from '../../navigation/AppNavigation.props';
import { DeliveryProductDetails } from './components';

// ────────────────────────────────────────────────────────────────────────────────

export const DeliveryEditProductDetailsScreen = ({
  navigation,
  route,
}: DeliveryEditProductDetailsScreenProps) => {
  const { addressId, productSlug, lineItemId } = route.params ?? {};

  const { cart } = useCart();

  // ─────────────────────────────────────────────────────────────────

  const navigateToBag = useCallback(() => {
    const previousRoutes = navigation.getState().routes;
    const previousRoute = previousRoutes.at(-2);

    // When navigating to the bag, if the bag is already in the stack, just return to it.
    if (navigation.canGoBack() && previousRoute?.name === 'Bag') {
      navigation.goBack();

      return;
    }

    navigation.replace('Bag');
  }, [navigation]);

  const navigateToDeliveryProductDetailsScreen = useCallback(() => {
    navigation.replace('DeliveryProductDetails', { addressId, productSlug });
  }, [addressId, navigation, productSlug]);

  // ─────────────────────────────────────────────────────────────────

  // redirect to the Delivery PDP screen if the line item isn't in the cart or has been removed
  useLayoutEffect(() => {
    const lineItems = cart?.lineItems;
    const isLineItemInCart = lineItems?.some(({ id }) => id === lineItemId);

    if (!isLineItemInCart) navigateToDeliveryProductDetailsScreen();
  }, [cart?.lineItems, lineItemId, navigateToDeliveryProductDetailsScreen]);

  // ─────────────────────────────────────────────────────────────────

  return (
    <DeliveryProductDetails
      addressId={addressId}
      productSlug={productSlug}
      lineItemId={lineItemId}
      onEditLineItemInCart={navigateToBag}
      onRemoveLineItemFromCart={navigateToBag}
      isDelivery
    />
  );
};

import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const FloatingButton = (props: HomeScreenQuickActionButtonProps) => {
  const { style, ...restProps } = props;

  const buttonStyles = [styles.button, style];

  return <Button palette="lime" style={buttonStyles} {...restProps} />;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  button: {
    ...theme.elevations['12'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type HomeScreenQuickActionButtonProps = ComponentProps<typeof Button>;

import type { ReactElement } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import type { TagAlertBoxProps as BoxProps } from '../TagAlert.types';

export const Box = (props: BoxProps): ReactElement => {
  const { testID, children } = props;

  return (
    <View testID={testID} style={styles.buttonBox}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  buttonBox: {
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 4,
  },
});

import { useCallback } from 'react';
import { useClient } from 'urql';

import {
  BagTimesPollingDocument,
  type BagTimesPollingQuery,
} from '../../GraphQL/BagTimesPolling.generated';
import { mapWantedTimes } from '../../utils/wantedTimes';

// ────────────────────────────────────────────────────────────────────────────

/**
 * A hook for fetching wanted times for use in machine polling.
 */
export const useFetchWantedTimes = () => {
  const client = useClient();

  // ─── Queries ──────────────────────────────────────────────────────────────

  const fetchWantedTimes = useCallback(async () => {
    const queryRewards = client.query<BagTimesPollingQuery>;

    const response = await queryRewards(
      BagTimesPollingDocument,
      {},
      { requestPolicy: 'network-only' },
    ).toPromise();

    const availableTimes = response.data?.cart?.availableWantedTimes ?? [];
    const wantedTimes = mapWantedTimes(availableTimes);

    return { wantedTimes };
  }, [client.query]);

  return { fetchWantedTimes };
};

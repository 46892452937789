import { useCallback } from 'react';
import { useInterpret } from '@xstate/react';
import { format } from 'date-fns';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';
import { getFetchOptions } from '@order/Urql';

import {
  AZURE_API_ACCESS_TOKEN_HEADER,
  AZURE_VENDOR_ACCESS_TOKEN_HEADER,
} from '../../config';
import { useCreateAccountMutation } from '../../graphql/CreateAccount.generated';
import { useSignInCustomerMutation } from '../../graphql/SignInCustomer.generated';
import { checkIfAccessTokenHasExpired } from '../../helpers';
import {
  azureAuthMachine,
  type AzureAuthStateMachineContext,
  type AzureAuthStateMachineServices,
} from '../../machine/azure-auth-machine';
import {
  authenticate,
  handleRedirect,
  initialize,
  refreshAccessTokens,
} from '../../services';
import { useShouldPreferEphemeralSession } from '../useShouldPreferEphemeralSession';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns a connected Azure auth machine service.
 */
export const useAzureAuthService = () => {
  const { push: addBanner } = useNoticeBannersStackContext();
  const { track } = useTelemetry();

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldPreferEphemeralSession = useShouldPreferEphemeralSession();

  // ─── Network Requests ────────────────────────────────────────────────

  const [, executeSignIn] = useSignInCustomerMutation();
  const [, executeCreateAccount] = useCreateAccountMutation();

  // ─── Services ────────────────────────────────────────────────────────

  /**
   * Returns a "sign in" service that attempts to sign in based on the
   * provided access tokens.
   */
  const signIn = useCallback<SignInService>(
    async (context) => {
      const { apiAccessToken, vendorApiAccessToken } = context;

      if (!apiAccessToken || !vendorApiAccessToken) {
        throw new Error('Sign in failed | No access tokens');
      }

      const response = await executeSignIn(
        {},
        {
          fetchOptions: getFetchOptions({
            extraHeaders: {
              [AZURE_API_ACCESS_TOKEN_HEADER]: apiAccessToken,
              [AZURE_VENDOR_ACCESS_TOKEN_HEADER]: vendorApiAccessToken,
            },
          }),
        },
      );
      const { data, error } = response;
      const responseType = data?.signIn.__typename;

      const hasUserError =
        responseType === 'ValidationError' || responseType === 'SignInFailed';
      const systemError = hasUserError ? data?.signIn.message : error?.message;

      if (responseType === 'SignInSuccess') {
        return { hasSignedIn: true };
      }

      if (responseType === 'CustomerNotFound') {
        return { hasSignedIn: false };
      }

      throw new Error(systemError);
    },
    [executeSignIn],
  );

  /**
   * Returns a "create account" service that attempts to create an account
   * based on the provided access tokens.
   */
  const createAccount = useCallback<CreateAccountService>(
    async (context) => {
      const {
        email,
        apiAccessToken,
        vendorApiAccessToken,
        createAccountInput: input,
      } = context;

      if (!apiAccessToken || !vendorApiAccessToken || !input) {
        return { hasCreatedAccount: false };
      }

      const response = await executeCreateAccount(
        { input },
        {
          fetchOptions: getFetchOptions({
            extraHeaders: {
              [AZURE_API_ACCESS_TOKEN_HEADER]: apiAccessToken,
              [AZURE_VENDOR_ACCESS_TOKEN_HEADER]: vendorApiAccessToken,
            },
          }),
        },
      );

      const data = response.data?.createAccount;
      const hasCreatedAccount = data?.__typename === 'CreateAccountSuccess';

      // ─── Error Handling ──────────────────────────────────────────────────

      if (!hasCreatedAccount || response.error) {
        const hasValidationErrors = data?.__typename === 'ValidationError';

        const validationError = hasValidationErrors
          ? data.fieldErrors?.[0]?.message
          : undefined;
        const userError =
          validationError ??
          'Something went wrong, try again in a few moments...';
        const systemError = response.error?.message;

        addBanner({ text: userError, palette: 'caution' });

        track('join.failure', { userError, systemError });

        return { hasCreatedAccount };
      }

      // ─────────────────────────────────────────────────────────────────────

      track('join.success', {
        name: `${input.firstName} ${input.lastName}`,
        email,
        date: format(new Date(), 'M/dd/yyyy K:mmbbb'),
        referred: false,
      });

      return { hasCreatedAccount };
    },
    [addBanner, executeCreateAccount, track],
  );

  const handleAuthenticate = useCallback(async (): ReturnType<
    typeof authenticate
  > => {
    return authenticate({ shouldPreferEphemeralSession });
  }, [shouldPreferEphemeralSession]);

  const handleRefreshAccessTokens = useCallback(
    async (context: AzureAuthStateMachineContext) => {
      return refreshAccessTokens(context.apiRefreshToken);
    },
    [],
  );

  // ─────────────────────────────────────────────────────────────────────

  return useInterpret(azureAuthMachine, {
    actions: {
      onSystemError(_context, event) {
        track('join-or-sign-in.failure', {
          systemError: event.data as string,
        });
      },
    },
    guards: {
      checkIfNeedToRefreshTokens(context) {
        const { apiAccessToken, vendorApiAccessToken } = context;

        if (!apiAccessToken || !vendorApiAccessToken) return true;

        const isApiAccessTokenExpired = checkIfAccessTokenHasExpired({
          accessToken: apiAccessToken,
          safeGuardMinutes: 5,
        });
        const isVendorAccessTokenExpired = checkIfAccessTokenHasExpired({
          accessToken: vendorApiAccessToken,
          safeGuardMinutes: 5,
        });

        return isApiAccessTokenExpired || isVendorAccessTokenExpired;
      },
    },
    services: {
      createAccount,
      signIn,
      authenticate: handleAuthenticate,
      initialize,
      handleRedirect,
      refreshAccessTokens: handleRefreshAccessTokens,
    },
  });
};

// ─── Types ───────────────────────────────────────────────────────────────────

type SignInService = (
  context: AzureAuthStateMachineContext,
) => Promise<AzureAuthStateMachineServices['signIn']['data']>;

type CreateAccountService = (
  context: AzureAuthStateMachineContext,
) => Promise<AzureAuthStateMachineServices['createAccount']['data']>;

import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Linking, Text } from 'react-native';
import {
  getFormattedExpirationDate,
  LoyaltyOfferCard as OfferCard,
  useResponsive,
} from '@sg/garnish';
import { type Challenge } from '@sg/graphql-schema';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCard = (props: LoyaltyOfferCardProps) => {
  const {
    offer,
    isLoading,
    layout = 'standard',
    rounded,
    onStartButtonPress,
    onOrderButtonPress,
  } = props;

  const { match } = useResponsive();
  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const expirationDate = getFormattedExpirationDate(
    offer.expirationDate,
    formatMessage(messages.endsPrefix),
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  const openTerms = useCallback(() => {
    void Linking.openURL(TERMS_LINK);
  }, []);

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldDisplayTag = checkIfShouldDisplayTag(offer);
  const shouldDisplayFloatingLabel = checkIfShouldDisplayFloatingLabel(offer);
  const shouldDisplayLabel = checkIfShouldDisplayLabel(offer);
  const shouldDisplayProgressBar = checkIfShouldDisplayProgressBar(offer);
  const shouldDisplayStartButton = checkIfShouldDisplayStartButton(offer);
  const shouldDisplayOrderButton = checkIfShouldDisplayOrderButton(offer);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <OfferCard.Container rounded={rounded}>
      <OfferCard.Image source={offer.assetUrl ?? undefined} minHeight={200} />

      {shouldDisplayFloatingLabel ? (
        <OfferCard.FloatingLabel>
          <FormattedMessage {...messages.labelCompleted} />
        </OfferCard.FloatingLabel>
      ) : null}

      <OfferCard.ContentContainer>
        {shouldDisplayTag ? (
          <OfferCard.Tag>
            <FormattedMessage {...messages.tagNew} />
          </OfferCard.Tag>
        ) : null}

        {shouldDisplayLabel ? <LoyaltyOfferCardLabel offer={offer} /> : null}

        {/* ──────────────────────────────────────────────────────────────── */}

        <OfferCard.Title>{offer.title}</OfferCard.Title>

        {/* ─── Description and Terms ────────────────────────────────────── */}

        <OfferCard.Text>
          {offer.descriptiveTitle}

          <Text> </Text>

          {layout === 'standard' ? (
            <OfferCard.PressableText onPress={openTerms}>
              <FormattedMessage {...messages.terms} />
              <Text>.</Text>
            </OfferCard.PressableText>
          ) : null}
        </OfferCard.Text>

        {expirationDate ? (
          <OfferCard.Text>{expirationDate}</OfferCard.Text>
        ) : null}

        {/* ─── Progress Bars ────────────────────────────────────────────── */}

        {shouldDisplayProgressBar ? (
          <OfferCard.ProgressBar
            value={offer.progress}
            maxValue={offer.goal}
            accessibilityLabel={formatMessage(messages.progressBarA11yLabel, {
              progress: offer.percentageCompleted,
            })}
          />
        ) : null}
      </OfferCard.ContentContainer>

      {/* ─── Footer ─────────────────────────────────────────────────────── */}

      {layout === 'compact' ? (
        <OfferCard.Footer>
          <OfferCard.PressableText onPress={openTerms}>
            <FormattedMessage {...messages.terms} />
          </OfferCard.PressableText>

          {shouldDisplayStartButton ? (
            <OfferCard.Button
              size="small"
              onPress={onStartButtonPress}
              isLoading={isLoading}
            >
              <FormattedMessage {...messages.startButtonLabel} />
            </OfferCard.Button>
          ) : null}

          {shouldDisplayOrderButton ? (
            <OfferCard.Button
              size="small"
              onPress={onOrderButtonPress}
              palette="white"
            >
              <FormattedMessage {...messages.orderNowButtonLabel} />
            </OfferCard.Button>
          ) : null}
        </OfferCard.Footer>
      ) : null}

      {layout === 'standard' &&
      (shouldDisplayStartButton || shouldDisplayOrderButton) ? (
        <OfferCard.Footer>
          {shouldDisplayStartButton ? (
            <OfferCard.Button
              fluid
              size={match(['medium', 'large-wide'])}
              onPress={onStartButtonPress}
              isLoading={isLoading}
            >
              <FormattedMessage {...messages.startButtonLabel} />
            </OfferCard.Button>
          ) : null}

          {shouldDisplayOrderButton ? (
            <OfferCard.Button
              fluid
              size={match(['medium', 'large-wide'])}
              onPress={onOrderButtonPress}
              palette="white"
            >
              <FormattedMessage {...messages.orderNowButtonLabel} />
            </OfferCard.Button>
          ) : null}
        </OfferCard.Footer>
      ) : null}
    </OfferCard.Container>
  );
};

// ─── Components ──────────────────────────────────────────────────────────────

const LoyaltyOfferCardLabel = (props: LoyaltyOfferCardLabelProps) => {
  const { offer } = props;

  if (offer.status === 'STARTED') {
    return (
      <OfferCard.Label>
        <FormattedMessage {...messages.labelStarted} />
      </OfferCard.Label>
    );
  }

  if (offer.status === 'IN_PROGRESS') {
    return (
      <OfferCard.Label>
        <FormattedMessage {...messages.labelInProgress} />
      </OfferCard.Label>
    );
  }

  return null;
};

// ─── Utils ───────────────────────────────────────────────────────────────────

function checkIfShouldDisplayTag(offer: Challenge) {
  return offer.status === 'NOT_STARTED';
}

function checkIfShouldDisplayLabel(offer: Challenge) {
  return offer.status === 'STARTED' || offer.status === 'IN_PROGRESS';
}

function checkIfShouldDisplayProgressBar(offer: Challenge) {
  return offer.status === 'STARTED' || offer.status === 'IN_PROGRESS';
}

function checkIfShouldDisplayStartButton(offer: Challenge) {
  return offer.status === 'NOT_STARTED';
}

function checkIfShouldDisplayOrderButton(offer: Challenge) {
  return offer.status === 'STARTED' || offer.status === 'IN_PROGRESS';
}

function checkIfShouldDisplayFloatingLabel(offer: Challenge) {
  return offer.status === 'COMPLETED';
}

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  tagNew: {
    defaultMessage: 'New!',
    description: 'Loyalty | Offer Card | Tag | New',
  },
  terms: {
    defaultMessage: 'Terms',
    description: 'Loyalty | Offer Card | Terms',
  },
  labelStarted: {
    defaultMessage: 'Started',
    description: 'Loyalty | Offer Card | Label | Started',
  },
  labelInProgress: {
    defaultMessage: 'In progress!',
    description: 'Loyalty | Offer Card | Label | In progress',
  },
  labelCompleted: {
    defaultMessage: 'Completed',
    description: 'Loyalty | Offer Card | Label | Completed',
  },
  endsPrefix: {
    defaultMessage: 'Ends',
    description: 'Loyalty | Offer Card | Ends prefix',
  },
  startButtonLabel: {
    defaultMessage: 'Start',
    description: 'Loyalty | Offer Card | Button | Start',
  },
  orderNowButtonLabel: {
    defaultMessage: 'Order now',
    description: 'Loyalty | Offer Card | Button | Order now',
  },
  progressBarA11yLabel: {
    defaultMessage: `Offer completion progress is {progress}%`,
    description: 'Loyalty | Offer Card | Progress bar | a11y label',
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const TERMS_LINK = 'https://www.sweetgreen.com/terms';

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardProps = {
  offer: Challenge;
  isLoading: boolean;
  rounded?: boolean;
  layout?: 'compact' | 'standard';
  onStartButtonPress: () => void;
  onOrderButtonPress: () => void;
};

type LoyaltyOfferCardLabelProps = {
  offer: Challenge;
};

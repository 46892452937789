import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { theme, VStack } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrdersFavoritesListContainer = (props: PropsWithChildren) => {
  return (
    <VStack style={styles.container} gap={theme.spacing['6']}>
      {props.children}
    </VStack>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    marginTop: theme.spacing['10'],
    marginBottom: theme.spacing['30'],
  },
});

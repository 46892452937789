import type { TextInputProps } from 'react-native';
import { Platform } from 'react-native';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * TextInput autocomplete types differ between React Native and React Native Web.
 * For consistency, this helper will return a platform-specific autocomplete
 * value if one exists.
 */
export const usePlatformSpecificAutocomplete = (
  autocomplete: NativeTextInputAutoComplete,
) => {
  if (autocomplete === undefined) return autocomplete;

  // return a web-specific autocomplete if it exists.
  if (Platform.OS === 'web') {
    const webAutocomplete = AUTOCOMPLETE_NATIVE_WEB_MAPPING[
      autocomplete
    ] as NativeTextInputAutoComplete;

    return webAutocomplete ?? autocomplete;
  }

  return autocomplete;
};

// ────────────────────────────────────────────────────────────────────────────────

const AUTOCOMPLETE_NATIVE_WEB_MAPPING: AutocompleteNativeWebMapping = {
  'name-given': 'given-name',
  'name-family': 'family-name',
  password: 'current-password',
  // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'WebTextInputAutocomplete'.
  'username-new': 'new-username',
  'password-new': 'new-password',
};

// ────────────────────────────────────────────────────────────────────────────────

type AutocompleteNativeWebMapping = Partial<
  Record<
    Exclude<NativeTextInputAutoComplete, undefined>,
    WebTextInputAutocomplete
  >
>;

type NativeTextInputAutoComplete = TextInputProps['autoComplete'];

type WebTextInputAutocomplete = HTMLInputElement['autocomplete'];

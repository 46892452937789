import { capitalizeFirstLetter } from '../../utils';

// ────────────────────────────────────────────────────────────────────────────────

export const getProductIngredientsList = (
  ingredients: Ingredients,
  shouldCapitalizeFirstIngredient = true,
) => {
  const formattedIngredients = ingredients.reduce<FormattedIngredients>(
    (allIngredients, ingredient) => {
      const ingredientName = ingredient?.name?.toLowerCase() ?? '';

      const existingIngredientQty = allIngredients[ingredientName];
      const ingredientQty = existingIngredientQty
        ? existingIngredientQty + 1
        : 1;

      return { ...allIngredients, [ingredientName]: ingredientQty };
    },
    {},
  );

  return Object.entries(formattedIngredients)
    .map(([ingredientName, quantity], index) => {
      const shouldCapitalizeIngredientName =
        shouldCapitalizeFirstIngredient && index === 0;

      const formattedIngredientName = shouldCapitalizeIngredientName
        ? capitalizeFirstLetter(ingredientName)
        : ingredientName;
      const formattedQuantity = quantity > 1 ? `${quantity} ` : '';

      return `${formattedQuantity}${formattedIngredientName}`;
    })
    .join(', ');
};

// ────────────────────────────────────────────────────────────────────────────────

type Ingredients = ReadonlyArray<
  Readonly<{
    name?: string;
  }>
>;

type FormattedIngredients = Record<string, number>;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { useHeaderHeight } from '@react-navigation/elements';
import {
  BodyText,
  Button,
  HStack,
  IllusSeasonal,
  Image,
  theme,
  TitleText,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const DeliveryOrderInFlightContent = ({
  onClose,
  onOrderStatus,
}: DeliveryOrderInFlightContentProps) => {
  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();
  const paddingStyle = useHeaderCompensationPadding();

  return (
    <View
      style={[
        styles.container,
        currentBreakpoint.isXS && styles.justifyCenter,
        currentBreakpoint.isXS && paddingStyle,
      ]}
    >
      <View style={styles.alignCenter}>
        {currentBreakpoint.isXS ? (
          <Image
            source={IllusSeasonal}
            style={[styles.image, styles.imageWrapperXS]}
          />
        ) : null}

        <View style={styles.row}>
          {currentBreakpoint.isXS ? null : (
            <Image
              source={IllusSeasonal}
              style={[styles.image, styles.imageWrapperLG]}
            />
          )}

          <View style={styles.info}>
            <TitleText style={styles.title} size={5}>
              {t('delivery.in-flight-modal.title')}
            </TitleText>
            <BodyText>{t('delivery.in-flight-modal.subtitle')}</BodyText>
          </View>
        </View>
      </View>

      <View
        style={currentBreakpoint.isXS ? styles.buttonsXS : styles.buttonsLg}
      >
        <HStack itemsPerRow={2}>
          <Button
            size="large"
            testID="delivery-order-in-flight.close"
            palette="secondary"
            onPress={onClose}
          >
            {t('delivery.in-flight-modal.cta-close')}
          </Button>
          <Button
            size="large"
            testID="delivery-order-in-flight.order-status"
            onPress={onOrderStatus}
          >
            {t('delivery.in-flight-modal.cta-status')}
          </Button>
        </HStack>
      </View>
    </View>
  );
};

const useHeaderCompensationPadding = () => {
  const headerHeight = useHeaderHeight();

  return useStyle(() => ({ paddingBottom: headerHeight }), [headerHeight]);
};

// ─── Types ────────────────────────────────────────────────────────

type DeliveryOrderInFlightContentProps = Readonly<{
  onClose: () => void;
  onOrderStatus: () => void;
}>;

// ─── Styles ───────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: theme.spacing['6'],
  },
  buttonsXS: {
    marginTop: theme.spacing['6'],
  },
  buttonsLg: {
    marginTop: theme.spacing['4'],
  },
  info: {
    flex: 1,
  },
  alignCenter: {
    alignItems: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: theme.spacing['2'],
  },
  image: {
    width: 200,
    height: 200,
  },
  imageWrapperXS: {
    marginBottom: theme.spacing['6'],
  },
  imageWrapperLG: {
    marginRight: theme.spacing['4'],
  },
});

import React, { useCallback, useMemo } from 'react';
import {
  type RouteProp,
  useIsFocused,
  useRoute,
} from '@react-navigation/native';
import { addDays, addHours, startOfHour } from 'date-fns';

import { useIsLoggedIn, useIsLoggingIn } from '@order/AuthMachine';
import {
  LoadingAnimation,
  NotFoundView,
  SignedOutView,
} from '@order/components';
import { APPLE_PAY } from '@order/constants';
import { useCustomer } from '@order/Customer';
import { type useCreditCardOptions } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry, useTrackEventEffect } from '@order/Telemetry';

import { type PaymentResolvedPayload } from '../../components/ApplePay/hooks/useApplePayPromise';
import {
  GiftCardCheckoutApplePay,
  GiftCardCheckoutContent,
  GiftCardCheckoutLeaveScreen,
} from './components';
import {
  type GiftCardCheckoutFormFieldRules,
  useGiftCardCheckoutForm,
} from './form';
import { useGiftCardContent, useGiftCardPaymentMethods } from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders Gift Card Checkout form.
 */
export const GiftCardCheckoutScreen = () => {
  const isLoggedIn = useIsLoggedIn();
  const { track } = useTelemetry();
  const isScreenFocused = useIsFocused();

  const { params } = useRoute<GiftCardCheckoutScreenRouteParams>();
  const assetId = params?.assetId;

  // ─────────────────────────────────────────────────────────────────────

  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );

  // ─── Remote Data ─────────────────────────────────────────────────────

  const shouldPauseContentQueries = !isInAppGiftCardEnabled || !isLoggedIn;

  const { data, isFetching } = useGiftCardContent({
    assetId,
    pause: shouldPauseContentQueries,
  });

  // ─────────────────────────────────────────────────────────────────────

  const {
    paymentMethods,
    defaultPaymentMethodId,
    isLoadingPaymentMethods,
    isApplePayReady,
    isSettingUpApplePay,
    applePayHostedFrameRef,
    handleApplePayMessages,
    proceedApplePayPayment,
  } = useGiftCardPaymentMethods({ pause: shouldPauseContentQueries });

  // ─── Flags ───────────────────────────────────────────────────────────

  const hasGiftCardData = !isFetching && data.imgData !== undefined;
  const shouldRenderNotFoundView = !isInAppGiftCardEnabled || !hasGiftCardData;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const trackScreenLoadingError = useCallback(() => {
    if (!isInAppGiftCardEnabled) {
      track('gift.customize_load.failure', {
        error: 'The corresponding feature flag is disabled',
      });
    }

    if (!isLoggedIn) {
      track('gift.customize_load.failure', { error: 'User is not logged in' });
    }

    if (!hasGiftCardData) {
      track('gift.customize_load.failure', {
        error: 'Unable to fetch required data',
      });
    }
  }, [hasGiftCardData, isInAppGiftCardEnabled, isLoggedIn, track]);

  // ─────────────────────────────────────────────────────────────────────

  if (!isScreenFocused) {
    return null;
  }

  if (!isLoggedIn) {
    return (
      <SignedOutView
        onMount={trackScreenLoadingError}
        redirectTo="gift-cards"
      />
    );
  }

  return (
    <>
      <GiftCardCheckoutScreenReadyWithApplePay
        data={data}
        assetId={assetId}
        isFetching={isFetching}
        shouldRenderNotFoundView={shouldRenderNotFoundView}
        trackScreenLoadingError={trackScreenLoadingError}
        paymentMethods={paymentMethods}
        defaultPaymentMethodId={defaultPaymentMethodId}
        isLoadingPaymentMethods={isLoadingPaymentMethods}
        isSettingUpApplePay={isSettingUpApplePay}
        isApplePayReady={isApplePayReady}
        proceedApplePayPayment={proceedApplePayPayment}
      />

      <GiftCardCheckoutApplePay
        hostedFrameRef={applePayHostedFrameRef}
        onMessage={handleApplePayMessages}
      />
    </>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const GiftCardCheckoutScreenReadyWithApplePay = (
  props: GiftCardCheckoutScreenReadyWithApplePayProps,
) => {
  const {
    data,
    assetId,
    isFetching,
    trackScreenLoadingError,
    shouldRenderNotFoundView,
    defaultPaymentMethodId,
    isApplePayReady,
    isLoadingPaymentMethods,
    isSettingUpApplePay,
    paymentMethods,
    proceedApplePayPayment,
  } = props;

  const { customer } = useCustomer();
  const isLoggingIn = useIsLoggingIn();

  // ─── Flags ───────────────────────────────────────────────────────────

  const inAppGiftCardMaxScheduleDays = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-max-schedule-days',
    { listenForChanges: true },
  );

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldRenderLoadingView =
    isLoggingIn ||
    isFetching ||
    isLoadingPaymentMethods ||
    (!isApplePayReady && isSettingUpApplePay);

  // ─── Helpers ─────────────────────────────────────────────────────────

  /**
   * Payment methods like Apple Pay require additional steps to resolve the
   * actual payment method ID.
   *
   * This function will get the normalized payment method ID for the given
   * payment method.
   */
  const resolveNormalizedPaymentMethodId = useCallback(
    async (
      paymentMethodId: string,
      amount: number,
    ): Promise<PaymentResolvedPayload> => {
      if (paymentMethodId === APPLE_PAY) {
        return proceedApplePayPayment(amount);
      }

      return { paymentMethodId, isSuccess: true };
    },
    [proceedApplePayPayment],
  );

  // ─────────────────────────────────────────────────────────────────────

  if (shouldRenderLoadingView) {
    return <LoadingAnimation size="large" />;
  }

  if (shouldRenderNotFoundView) {
    return <NotFoundView onMount={trackScreenLoadingError} />;
  }

  // ─────────────────────────────────────────────────────────────────────

  return (
    <GiftCardCheckoutScreenReady
      data={data}
      assetId={assetId}
      customerFirstName={customer.firstName ?? undefined}
      maxScheduleDays={inAppGiftCardMaxScheduleDays}
      defaultPaymentMethodId={defaultPaymentMethodId}
      paymentMethods={paymentMethods}
      resolveNormalizedPaymentMethodId={resolveNormalizedPaymentMethodId}
    />
  );
};

const GiftCardCheckoutScreenReady = (
  props: GiftCardCheckoutScreenReadyProps,
) => {
  const {
    data,
    customerFirstName,
    assetId,
    maxScheduleDays,
    paymentMethods,
    defaultPaymentMethodId,
    resolveNormalizedPaymentMethodId,
  } = props;

  // ─── Form ────────────────────────────────────────────────────────────

  const formRules = useMemo<GiftCardCheckoutFormFieldRules>(() => {
    const fromDate = addHours(startOfHour(new Date()), 1);
    const toDate = addDays(fromDate, maxScheduleDays);

    return {
      amount: data.amountData ?? {
        min: 1000,
        max: 20_000,
        options: [2500, 5000, 10_000, 20_000],
      },
      message: { maxChars: data.messageMaxChars ?? 200 },
      deliveryDate: { fromDate, toDate },
    };
  }, [data.amountData, data.messageMaxChars, maxScheduleDays]);

  const { form, submitForm } = useGiftCardCheckoutForm({
    resolveNormalizedPaymentMethodId,
    defaultValues: {
      assetId,
      amount: data.amountData?.options?.[0] ?? 2500,
      senderName: customerFirstName,
      paymentMethodId: defaultPaymentMethodId,
    },
    rules: formRules,
  });

  // ─── Effects ─────────────────────────────────────────────────────────

  useTrackEventEffect({ name: 'gift.customize_load.success' });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <GiftCardCheckoutContent
        form={form}
        rules={formRules}
        paymentMethods={paymentMethods}
        submitForm={submitForm}
        giftCardImage={data.imgData}
        screenTitle={data.screenTitle}
        sectionTitles={data.sectionTitles}
      />

      <GiftCardCheckoutLeaveScreen form={form} />
    </>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutScreenProps = Readonly<{
  params: { assetId: string };
}>;

type GiftCardCheckoutScreenReadyWithApplePayProps = {
  data: ReturnType<typeof useGiftCardContent>['data'];
  assetId: string;
  isFetching: boolean;
  shouldRenderNotFoundView: boolean;
  trackScreenLoadingError: () => void;
} & Pick<
  ReturnType<typeof useGiftCardPaymentMethods>,
  | 'defaultPaymentMethodId'
  | 'isApplePayReady'
  | 'isLoadingPaymentMethods'
  | 'isSettingUpApplePay'
  | 'paymentMethods'
  | 'proceedApplePayPayment'
>;

type GiftCardCheckoutScreenReadyProps = {
  customerFirstName?: string;
  maxScheduleDays: number;
  assetId: string;
  defaultPaymentMethodId?: string;
  paymentMethods: ReturnType<typeof useCreditCardOptions>['creditCardOptions'];
  data: ReturnType<typeof useGiftCardContent>['data'];
  resolveNormalizedPaymentMethodId: (
    paymentMethodId: string,
    amount: number,
  ) => Promise<PaymentResolvedPayload>;
};

type GiftCardCheckoutScreenRouteParams = RouteProp<
  GiftCardCheckoutScreenProps,
  'params'
>;

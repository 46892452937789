import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import {
  CloudinaryImage,
  type CloudinaryTransformConfig,
  Image,
} from '@sg/garnish';

import { assets } from './assets';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuTopDownCategoriesItemImage = (
  props: MenuTopDownCategoriesImageProps,
) => {
  const { source, alt, categoryId, isCustomCategory, onLoad } = props;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnLoad = useCallback(() => {
    onLoad?.(categoryId);
  }, [categoryId, onLoad]);

  // ─────────────────────────────────────────────────────────────────────

  if (isCustomCategory) {
    return (
      <Image
        source={assets.CustomCategoryImage}
        contentFit="contain"
        aria-label={alt}
        style={styles.image}
        priority="high"
        onLoad={handleOnLoad}
      />
    );
  }

  return (
    <CloudinaryImage
      baseUrl={source}
      config={IMAGE_TRANSFORM_CONFIG}
      contentFit="contain"
      aria-label={alt}
      priority="high"
      style={styles.image}
      onLoad={handleOnLoad}
    />
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const IMAGE_SIZE = 120;

const IMAGE_TRANSFORM_CONFIG: CloudinaryTransformConfig = [
  { crop: 'crop', height: 0.6, width: 0.4 },
  { width: IMAGE_SIZE },
];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuTopDownCategoriesImageProps = {
  source: string;
  alt: string;
  categoryId: string;
  isCustomCategory?: boolean;
  onLoad?: (categoryId: string) => void;
};

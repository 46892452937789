import { defineMessages } from 'react-intl';

export const orderAgainMessages = defineMessages({
  headerTitle: {
    defaultMessage: 'Order again',
    description: 'Order again | Header | Title',
  },
  closeButtonA11yLabel: {
    defaultMessage: 'Close',
    description: 'Order again | Close button | a11y label',
  },
  tabOrdersLabel: {
    defaultMessage: 'Orders',
    description: 'Order again | Tabs | Order tab | Label',
  },
  tabOrdersA11yLabel: {
    defaultMessage: 'Open orders tab',
    description: 'Order again | Tabs | Order tab | a11y Label',
  },
  tabFavoritesLabel: {
    defaultMessage: 'Favorites',
    description: 'Order again | Tabs | Favorites tab | Label',
  },
  tabFavoritesA11yLabel: {
    defaultMessage: 'Open favorites tab',
    description: 'Order again | Tabs | Favorites tab | a11y Label',
  },
});

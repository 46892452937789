/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReorderRestaurantDataQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type ReorderRestaurantDataQuery = {
  readonly __typename?: 'Query';
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
    readonly slug: string;
    readonly name: string;
    readonly isOutpost: boolean;
    readonly deliveryFee: number;
  } | null;
};

export type ReorderDeliveryRestaurantDataQueryVariables = Types.Exact<{
  input: Types.LocationByAddressInput;
}>;

export type ReorderDeliveryRestaurantDataQuery = {
  readonly __typename?: 'Query';
  readonly locationByAddress:
    | { readonly __typename: 'InvalidInput' }
    | {
        readonly __typename: 'Location';
        readonly restaurant: {
          readonly __typename?: 'Restaurant';
          readonly id: string;
          readonly slug: string;
          readonly name: string;
          readonly isOutpost: boolean;
          readonly deliveryFee: number;
        };
        readonly vendor: {
          readonly __typename?: 'Vendor';
          readonly name: string;
          readonly restaurantId: string;
        };
      }
    | { readonly __typename: 'NoValidRestaurants' }
    | { readonly __typename: 'ValidationError' };
};

export type ReorderDeliveryAddressDataQueryVariables = Types.Exact<{
  addressId: Types.Scalars['ID']['input'];
}>;

export type ReorderDeliveryAddressDataQuery = {
  readonly __typename?: 'Query';
  readonly address: {
    readonly __typename?: 'Address';
    readonly id: string;
    readonly name: string | null;
  } | null;
};

export const ReorderRestaurantDataDocument = gql`
  query ReorderRestaurantData($id: ID!) {
    restaurant(id: $id) {
      id
      slug
      name
      isOutpost
      deliveryFee
    }
  }
`;

export function useReorderRestaurantDataQuery(
  options: Omit<
    Urql.UseQueryArgs<ReorderRestaurantDataQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ReorderRestaurantDataQuery,
    ReorderRestaurantDataQueryVariables
  >({ query: ReorderRestaurantDataDocument, ...options });
}
export const ReorderDeliveryRestaurantDataDocument = gql`
  query ReorderDeliveryRestaurantData($input: LocationByAddressInput!) {
    locationByAddress(input: $input) {
      __typename
      ... on Location {
        restaurant {
          id
          slug
          name
          isOutpost
          deliveryFee
        }
        vendor {
          name
          restaurantId
        }
      }
    }
  }
`;

export function useReorderDeliveryRestaurantDataQuery(
  options: Omit<
    Urql.UseQueryArgs<ReorderDeliveryRestaurantDataQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ReorderDeliveryRestaurantDataQuery,
    ReorderDeliveryRestaurantDataQueryVariables
  >({ query: ReorderDeliveryRestaurantDataDocument, ...options });
}
export const ReorderDeliveryAddressDataDocument = gql`
  query ReorderDeliveryAddressData($addressId: ID!) {
    address(addressId: $addressId) {
      id
      name
    }
  }
`;

export function useReorderDeliveryAddressDataQuery(
  options: Omit<
    Urql.UseQueryArgs<ReorderDeliveryAddressDataQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ReorderDeliveryAddressDataQuery,
    ReorderDeliveryAddressDataQueryVariables
  >({ query: ReorderDeliveryAddressDataDocument, ...options });
}

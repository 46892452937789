import { getClientIDHeaders } from './getClientIDHeaders';

/**
 * Returns fetch options that can be used with any GraphQL request.
 *
 * Optionally can receive extra headers.
 */
export function getFetchOptions(params?: GetFetchOptionsParams): RequestInit {
  const { extraHeaders } = params ?? {};

  return {
    credentials: 'include',
    headers: {
      ...getClientIDHeaders(),
      ...extraHeaders,
    },
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type GetFetchOptionsParams = {
  extraHeaders?: RequestInit['headers'];
};

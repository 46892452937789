import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { Header } from '@expo/html-elements';
import { theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainRailHeader = (props: PropsWithChildren) => {
  const { children } = props;

  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const headerDynamicStyles = match([
    styles.headerSM,
    styles.headerSM,
    styles.headerSM,
    styles.headerLG,
  ]);
  const headerStyles = [styles.header, headerDynamicStyles];

  // ─────────────────────────────────────────────────────────────────────

  return <Header style={headerStyles}>{children}</Header>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    rowGap: theme.spacing['4'],
    alignItems: 'flex-start',
    maxWidth: '100%',
  },
  headerSM: {
    width: 210,
  },
  headerLG: {
    width: 300,
  },
});

/* eslint-disable functional/immutable-data */

import type { ComponentProps } from 'react';
import React from 'react';
import type { ViewProps } from 'react-native';
import { Pressable, ScrollView, StyleSheet, View } from 'react-native';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import { useReduceMotionStatus, useResponsive } from '../../hooks';
import type { ModalSize } from './Modal.sizes';
import { ModalSizesStyles } from './Modal.sizes';
import type { BaseModalProps } from './Modal.types';

// Transparent variation of modals where it uses react-navigation instead of react-native.
export const TransparentModal = ({
  testID,
  size = 'small',
  dialogMaxHeight,
  fitHeight = false,
  offset,
  children,
  transparent,
  disableAnimation,
  style,
  onClose,
  mobileEdges = ['right', 'left'],
}: ModalProps) => {
  const { currentBreakpoint } = useResponsive();

  if (currentBreakpoint.isXS) {
    return (
      <SafeAreaView
        testID={testID}
        style={styles.nativeDialog}
        edges={mobileEdges}
      >
        {children}
      </SafeAreaView>
    );
  }

  const wrapperSmStyles = [styles.wrapperSM, StyleSheet.absoluteFill, style];

  return (
    <View testID={testID} style={wrapperSmStyles}>
      <Backdrop onClose={onClose} />
      <AnimatedModal
        size={size}
        fitHeight={fitHeight}
        offset={offset}
        disableAnimation={disableAnimation}
        dialogMaxHeight={dialogMaxHeight}
        style={transparent ? styles.transparentDialog : undefined}
      >
        {children}
      </AnimatedModal>
    </View>
  );
};

const AnimatedModal = ({
  size = 'small',
  fitHeight,
  dialogMaxHeight,
  offset,
  style,
  children,
  disableAnimation,
}: AnimatedModalProps) => {
  const { minWidth } = useResponsive();
  const reduceMotionEnabled = useReduceMotionStatus();
  const noAnimation = reduceMotionEnabled === 'disabled' || disableAnimation;
  const distance = size === 'drawer' ? DRAWER_ANIM_DISTANCE : ANIM_DISTANCE;
  const position = useSharedValue(disableAnimation ? 0 : distance);

  React.useEffect(() => {
    if (reduceMotionEnabled === 'disabled') return;

    position.value = withTiming(0, {
      duration: ANIM_DURATION,
      easing: Easing.out(Easing.exp),
    });
  }, [position, noAnimation, reduceMotionEnabled]);

  const initialPosition =
    size === 'drawer' ? styles.animatedDrawer : styles.animatedDialog;

  // ─── Wrapper Styles ──────────────────────────────────────────────────

  const offsetStyle = useStyle(() => {
    if (!offset) return;

    return {
      width: `${100 - Number(/\d+/.exec(offset)?.[0])}%`,
      height: `${100 - Number(/\d+/.exec(offset)?.[0])}%`,
      maxHeight: `${100 - Number(/\d+/.exec(offset)?.[0])}%`,
    };
  }, [offset]);

  const dialogDynamicStyles = useStyle(() => {
    if (!fitHeight && !dialogMaxHeight) return;

    if (dialogMaxHeight) {
      return { height: '100%', maxHeight: dialogMaxHeight };
    }

    return { maxHeight: '100%' };
  }, [dialogMaxHeight]);

  const sizesWithWrapperSpacing: readonly ModalSize[] = [
    'small',
    'medium',
    'large',
  ];
  const shouldAddSpacing =
    minWidth.isSM && sizesWithWrapperSpacing.includes(size);
  const dialogWrapperSpacingStyles = shouldAddSpacing
    ? styles.dialogWrapperWithSpacing
    : {};
  const dialogWrapperStyles = [
    styles.dialogWrapper,
    dialogWrapperSpacingStyles,
  ];

  // ─── Modal Styles ────────────────────────────────────────────────────

  const dialogAnimatedStyle = useAnimatedStyle(() => {
    const translateModal = { translateY: position.value };
    const translateDrawer = { translateX: position.value };
    const translate = size === 'drawer' ? translateDrawer : translateModal;

    return { transform: [translate] };
  });
  const radiusStyle = ['small', 'medium', 'large'].includes(size)
    ? styles.dialogRadius
    : undefined;
  const dialogStyles = [
    styles.dialog,
    radiusStyle,
    ModalSizesStyles[size],
    !noAnimation && initialPosition,
    dialogAnimatedStyle,
    dialogDynamicStyles,
    offsetStyle,
  ];

  // ─────────────────────────────────────────────────────────────────

  return (
    <View style={dialogWrapperStyles} pointerEvents="box-none">
      <Animated.View style={dialogStyles}>
        <ScrollView
          contentContainerStyle={styles.contentContainer}
          style={[styles.dialogColor, style]}
        >
          {children}
        </ScrollView>
      </Animated.View>
    </View>
  );
};

const Backdrop = React.forwardRef<View, BackdropProps>(({ onClose }, ref) => (
  <Pressable
    accessibilityRole="button"
    ref={ref}
    testID="sg-react-navigation-modal-backdrop"
    style={[StyleSheet.absoluteFill, styles.backdrop]}
    onPress={onClose}
  />
));

// ────────────────────────────────────────────────────────────────────────────────

const ANIM_DURATION = 1000; // 1sec
const ANIM_DISTANCE = 15; // 15px
const DRAWER_ANIM_DISTANCE = 480; // 480px

const styles = StyleSheet.create({
  wrapperSM: {
    justifyContent: 'center',
  },

  // ──────── Dialog ─────────
  dialog: {
    overflow: 'hidden',
    maxWidth: '100%',
    margin: 'auto',
    ...theme.elevations['2'],
  },
  dialogRadius: {
    borderRadius: theme.radius.large,
  },
  dialogWrapper: {
    flexGrow: 1,
    maxHeight: '100%',
  },
  dialogWrapperWithSpacing: {
    padding: theme.spacing['10'],
  },
  nativeDialog: {
    flex: 1,
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  animatedDialog: {
    transform: [{ translateY: ANIM_DISTANCE }],
  },
  animatedDrawer: {
    transform: [{ translateX: DRAWER_ANIM_DISTANCE }],
  },
  contentContainer: {
    maxHeight: '100%',
    flexGrow: 1,
  },
  dialogColor: {
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  transparentDialog: {
    backgroundColor: theme.colors.OPACITY.TRANSPARENT,
  },

  // ──────── Backdrop ─────────
  backdrop: {
    width: '100%',
    backgroundColor: theme.colors.OPACITY.DARK_KALE.DARKER,
  },
});

// ────────────────────────────────────────────────────────────────────────────────

type BackdropProps = Readonly<{ onClose: () => void }>;

type ModalProps = ActionProps &
  ViewProps &
  SizeProps & {
    mobileEdges?: ComponentProps<typeof SafeAreaView>['edges'];

    /**
     * Maximum height of the modal dialog.
     */
    dialogMaxHeight?: number;
  };

type SizeProps = BaseModalProps &
  Readonly<{
    transparent?: boolean;
    disableAnimation?: boolean;
  }>;

type AnimatedModalProps = {
  dialogMaxHeight?: number;
} & ViewProps &
  Pick<ModalProps, 'disableAnimation' | 'size' | 'fitHeight' | 'offset'>;

type ActionProps = Readonly<{ onClose: () => void }>;

import type {
  TelemetryLocation,
  TelemetrySelectedProduct,
  TelemetrySelectedRecommendation,
} from './TelemetryEvent.types';

export const telemetryLocationFromInput = (
  input?: TelemetryLocationInput,
): TelemetryLocation => ({
  id: input?.id ?? input?.googlePlaceId ?? '',
  name: input?.name ?? '',
  city: input?.city ?? '',
  state: input?.state ?? '',
  zip: input?.zipCode ?? '',
});

export const telemetryProductFromInput = (
  input?: TelemetryProductInput,
  modifications?: TelemetryIngredientModifications,
  selectedRecommendation?: TelemetrySelectedRecommendation,
): TelemetrySelectedProduct => ({
  id: input?.id ?? '',
  restaurantId: input?.restaurantId ?? '',
  location: input?.location ?? '',
  category: input?.categoryId ?? '',
  name: input?.name ?? '',
  calories: input?.calories ?? 0,
  cost: Number(((input?.cost ?? 0) / 100).toFixed(2)),
  quantity: input?.quantity ?? 0,
  featured: input?.featured ?? false,
  bread: input?.bread ?? false,
  dressingStyle: input?.dressingStyle ?? '',
  tags: input?.tags ?? [],
  customized: isTelemetryProductCustomized(modifications),
  isRecommended:
    Boolean(selectedRecommendation) ||
    Boolean(input?.name?.includes('Personalized')),
  additions:
    modifications?.additions?.map(({ ingredientId }) => ingredientId ?? '') ??
    [],
  removals:
    modifications?.removals?.map(({ ingredientId }) => ingredientId ?? '') ??
    [],
  ingredients:
    input?.ingredients
      ?.map((ingredient) => ingredient?.name ?? '')
      .filter(Boolean) ?? [],
  recommendation: selectedRecommendation ?? null,
});

export const isTelemetryProductCustomized = (
  modifications?: TelemetryIngredientModifications,
): boolean => {
  return (
    (modifications?.additions?.length ?? 0) > 0 ||
    (modifications?.removals?.length ?? 0) > 0 ||
    (modifications?.substitutions?.length ?? 0) > 0
  );
};

type TelemetryIngredientModifications = Readonly<{
  additions: readonly IngredientModificationInput[];
  removals: readonly IngredientModificationInput[];
  substitutions: readonly IngredientSubstitutionModificationInput[];
}>;

type Ingredient = Readonly<{
  asset?: { url?: string };
  dietaryProperties?: readonly DietaryProperty[];
  id?: string;
  kind?: IngredientKind | null;
  name?: string;
}>;

type DietaryProperty = Readonly<{
  id?: string;
  name?: DietaryPropertyKind;
}>;

type DietaryPropertyKind =
  | 'DAIRY'
  | 'FISH'
  | 'GLUTEN'
  | 'MEAT'
  | 'NUTS'
  | 'SOY';

type IngredientKind = 'BASE' | 'DRESSING' | 'PREMIUM' | 'TOPPING';

type IngredientModificationInput = Readonly<{
  ingredientId?: string;
}>;

type IngredientSubstitutionModificationInput = Readonly<{
  addedIngredientId?: string;
  removedIngredientId?: string;
}>;

type TelemetryLocationInput = Readonly<{
  id?: string;
  googlePlaceId?: string;
  name?: string;
  city?: string;
  state?: string;
  zipCode?: string;
}>;

type TelemetryProductInput = Readonly<{
  id?: string;
  categoryId?: string;
  restaurantId?: string;
  calories?: number;
  cost?: number;
  name?: string;
  location?: string;
  quantity?: number;
  featured?: boolean;
  bread?: boolean;
  dressingStyle?: string;
  tags?: readonly string[];
  ingredients?: ReadonlyArray<Ingredient | undefined>;
}>;

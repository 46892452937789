import { useCallback, useMemo } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { useActor, useSelector } from '@xstate/react';
import { type AddressType, useToggleState } from '@sg/garnish';

import { useGlobalAppState } from '@order/GlobalAppState';
import {
  type CategoryUpsellsQuery,
  CostChannel,
  type PartialLineItem,
} from '@order/graphql';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { useCategoryUpsellsQuery } from '../../../GraphQL/CategoryUpsells.generated';

/**
 * To fetch upsells to be used in the {CategoryUpsells} component.
 */
export const useUpsells = (params: UseUpsellsParams) => {
  const { orderId, restaurantId, orderChannel, lineItems = [] } = params;

  const { cartMachineRef: cartService } = useGlobalAppState();
  const [_state, send] = useActor(cartService);

  const cartViewCounts = useSelector(
    cartService,
    (currentState) => currentState.context.cartViewCounts,
  );

  const isCategoryUpsellsEnabled = useFeatureFlag(
    'mnu-5612-short-enable-upsell-categories-modal',
  );

  const { value: isCategoryUpsellsVisible, toggleOff: hideCategoryUpsells } =
    useToggleState(isCategoryUpsellsEnabled);

  useFocusEffect(
    useCallback(() => {
      if (!orderId) return;

      send({ type: 'REGISTER_CART_VIEW', cartId: orderId });
    }, [orderId, send]),
  );

  // ─── Upsells Query ────────────────────────────────────────────────────────

  const [response] = useCategoryUpsellsQuery({
    variables: {
      restaurantId,
      costChannel:
        orderChannel === 'delivery'
          ? CostChannel.DeliveryCostChannel
          : CostChannel.DefaultCostChannel,
    },
    pause: !isCategoryUpsellsEnabled || !restaurantId,
    requestPolicy: 'cache-and-network',
  });

  // ─── Derived Data ─────────────────────────────────────────────────────────

  const categoryUpsells = useMemo(() => {
    if (!response.data?.categoryUpsells) return [];

    const lineItemProductIds = lineItems.map(
      ({ product }) => product?.id ?? '',
    );
    const existingLineItemsProductsIds = new Set(lineItemProductIds);

    return response.data.categoryUpsells
      .map(filterCategoryProducts(existingLineItemsProductsIds))
      .filter(filterEmptyCategories);
  }, [lineItems, response.data?.categoryUpsells]);

  // ─── Flags ────────────────────────────────────────────────────────────────

  const isFirstTimeViewingCart = (cartViewCounts[orderId] ?? 0) <= 1;

  const shouldRenderCategoryUpsells =
    orderId &&
    isFirstTimeViewingCart &&
    isCategoryUpsellsEnabled &&
    isCategoryUpsellsVisible &&
    categoryUpsells !== undefined &&
    categoryUpsells?.length > 0;

  const isLoadingUpsells = isCategoryUpsellsVisible && response.fetching;

  return {
    categoryUpsells,
    isLoadingUpsells,
    shouldRenderCategoryUpsells,
    hideCategoryUpsells,
  };
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function filterCategoryProducts(existingLineItemsProductsIds: Set<string>) {
  return function mapCategory(category: UpsellCategoryObject) {
    const { products } = category;
    const filteredProducts = products.filter(
      filterUnwantedProduct(existingLineItemsProductsIds),
    );

    return { ...category, products: filteredProducts };
  };
}

function filterEmptyCategories(category: UpsellCategoryObject) {
  return category.products.length > 0;
}

function filterUnwantedProduct(existingLineItemsProductsIds: Set<string>) {
  return function filterProduct(product: UpsellCategoryProduct) {
    return !product.outOfStock && !existingLineItemsProductsIds.has(product.id);
  };
}

// ─── Types ──────────────────────────────────────────────────────────────────

type UseUpsellsParams = {
  orderId: string;
  restaurantId: string;
  orderChannel: AddressType;
  lineItems: readonly PartialLineItem[];
};

type UpsellCategoryProduct = UpsellCategoryObject['products'][number];

type UpsellCategoryObject = NonNullable<
  CategoryUpsellsQuery['categoryUpsells']
>[number];

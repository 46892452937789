export const join = {
  'join.title': 'Welcome! Create your account',
  'join.subtitle': 'Already have an account?',
  'join.phone-number-helper-text': 'In case there’s an issue with your order',
  'join.zip-code-helper-text': 'To see your local menu, offers and rewards',
  'join.create-account': 'Create account',
  'join.terms-and-conditions-a11y-label':
    'Toggle "I agree to sweetgreen\'s terms of use and privacy policy." checkbox',
  'join.terms-and-conditions-prefix': "I agree to sweetgreen's",
  'join.birthday-helper-text': '(optional) So we can celebrate your big day',
  'join.birthday-helper-text-required': 'So we can celebrate your big day',

  // ─── Join Errors ─────────────────────────────────────────────────────

  'join.error': 'Something went wrong, try again in a few moments...',
};

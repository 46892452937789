import React, { type ComponentProps, type ReactNode } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { OrderAgain } from '@order/features/orders';

// ─────────────────────────────────────────────────────────────────────────────

export const ReorderTabContainer = (props: ReorderScreenTabContainerProps) => {
  const { children, isActive, shouldShowLoadingIndicator, onScroll } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    isActive ? undefined : styles.containerInactive,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles}>
      <ScrollView onScroll={onScroll} scrollEventThrottle={16}>
        {children}
      </ScrollView>

      {isActive ? (
        <OrderAgain.InfiniteLoadingIndicator
          isVisible={shouldShowLoadingIndicator}
        />
      ) : null}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  // NOTE: We always render screen tabs to enable transitions and prevent data
  //       over-fetching.
  //       To prevent taking up unnecessary space, we virtually "hide" the
  //       corresponding container.
  containerInactive: {
    flex: 0,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ReorderScreenTabContainerProps = {
  children: ReactNode;
  isActive: boolean;
  shouldShowLoadingIndicator: boolean;
  onScroll: ComponentProps<typeof ScrollView>['onScroll'];
};

import React from 'react';
import { type RouteProp, useRoute } from '@react-navigation/native';

import {
  LoadingAnimation,
  NotFoundView,
  RefreshErrorState,
  SweetpassCheckoutForm,
  SweetpassFreeTrialNotEligible,
} from '@order/components';
import {
  useSubscribeToSweetpassPlus,
  useSweetpassCheckoutContentfulData,
  useSweetpassPlan,
} from '@order/hooks';

import type { ModalStackParamList } from '../../../navigation/AppNavigation.props';
import { type SweetpassCheckoutVariationProps } from '../SweetpassCheckoutVariations.types';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * Renders Sweetpass Checkout components for a single membership plan.
 */
export const SweetpassCheckoutSinglePlan = (
  props: SweetpassCheckoutVariationProps,
) => {
  const {
    currentTierData,
    creditCardsData,
    onUpgradeSuccess,
    isFetchingBaseData,
  } = props;

  // ─── Navigation ──────────────────────────────────────────────────────

  const route = useRoute<RouteProp<ModalStackParamList, 'SweetpassCheckout'>>();

  const {
    planId: planIdFromParams,
    campaignId,
    redirectToAfterUpgrade,
  } = route.params ?? {};

  // ─── Membership Plan ─────────────────────────────────────────────────

  const {
    planId,
    planPrice,
    isLoadingPlan: isFetchingPlanData,
    hasFreeTrial,
    billingFrequencyUnit,
    billingFrequencyMembershipName,
    hasUnknownPlanError,
  } = useSweetpassPlan({ requestedPlanId: planIdFromParams });

  const isCustomerEligibleForFreeTrial = currentTierData.isEligibleForFreeTrial;
  const isFreeTrialEnabled = hasFreeTrial && isCustomerEligibleForFreeTrial;

  // ─── Content ─────────────────────────────────────────────────────────

  const { trialDuration, trialDurationSingular, isEligibleForFreeTrial } =
    currentTierData;
  const { content, isLoading: isFetchingContent } =
    useSweetpassCheckoutContentfulData({
      isFreeTrialEnabled,
      planPrice,
      trialDuration,
      trialDurationSingular,
      billingFrequencyUnit,
      billingFrequencyMembershipName,
    });

  const {
    title,
    termsAndConditions,
    ctaLabel,
    agreementText,
    agreementTextLinks,
  } = content;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleCta = useSubscribeToSweetpassPlus({
    planId,
    campaignId,
    redirectToAfterUpgrade,
    hasFreeTrial,
    paymentId: creditCardsData.selectedCreditCard?.id,
    setHasCustomerBeenUpgraded: onUpgradeSuccess,
  });

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldRenderLoadingState =
    isFetchingBaseData || isFetchingPlanData || isFetchingContent;

  const isInvalidPlan = !planId;

  const isCustomerNotEligibleForFreeTrialPlan =
    hasFreeTrial && !isEligibleForFreeTrial;

  // ─────────────────────────────────────────────────────────────────────

  if (shouldRenderLoadingState) {
    return <LoadingAnimation />;
  }

  if (hasUnknownPlanError) {
    return <RefreshErrorState />;
  }

  if (isInvalidPlan) {
    return <NotFoundView />;
  }

  if (isCustomerNotEligibleForFreeTrialPlan) {
    return <SweetpassFreeTrialNotEligible />;
  }

  // ─────────────────────────────────────────────────────────────────────

  return (
    <SweetpassCheckoutForm
      title={title}
      termsAndConditions={termsAndConditions}
      agreementText={agreementText}
      agreementTextLinks={agreementTextLinks}
      ctaLabel={ctaLabel}
      planPrice={planPrice}
      hasFreeTrial={isFreeTrialEnabled}
      creditCardsData={creditCardsData}
      ctaHandler={handleCta}
      billingFrequencyUnit={billingFrequencyUnit}
    />
  );
};

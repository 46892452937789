import React, { useCallback } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Icon, ListAction, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardAccountSectionAddCardCta = () => {
  const { t } = useLocalizationContext();

  const { navigate } = useNavigation();

  const label = t('account.gift-cards.add-gift-card');

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToAddGiftCard = useCallback(() => {
    navigate('Modal', { screen: 'AddGiftCard' });
  }, [navigate]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable accessibilityLabel={label} onPress={navigateToAddGiftCard}>
      <ListAction.Wrapper hasPressableStyles>
        <View style={styles.container}>
          <ListAction.Icon iconName="IconCreditCard" />

          <View style={styles.content}>
            <ListAction.Text>{label}</ListAction.Text>
          </View>

          <Icon
            name="IconCaretRight"
            color={theme.colors.CHARCOAL}
            width={CARET_ICON_SIZE}
            height={CARET_ICON_SIZE}
          />
        </View>
      </ListAction.Wrapper>
    </Pressable>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const CARET_ICON_SIZE = 18;

// ─────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    paddingVertical: theme.spacing['2'],
  },
  content: {
    flex: 1,
  },
});

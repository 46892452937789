import React from 'react';
import type { TextStyle, ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../../hooks';
import { TitleText } from '../../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const PageHeroHeaderTitle = (props: PageHeroHeaderTitleProps) => {
  const { children, paletteStyle } = props;

  const { match } = useResponsive();

  const titleResponsiveStyles = match([
    styles.titleXS,
    styles.titleSM,
    styles.titleSM,
    styles.titleLG,
  ]);
  const titleStyles = [styles.title, titleResponsiveStyles, paletteStyle];

  return (
    <TitleText sizeMatch={['48', '64', '80']} style={titleStyles}>
      {children}
    </TitleText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
  },
  titleXS: {
    marginBottom: theme.spacing['4'],
  },
  titleSM: {
    marginBottom: theme.spacing['6'],
  },
  titleLG: {
    marginBottom: theme.spacing['10'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type PageHeroHeaderTitleProps = Readonly<{
  children: ViewProps['children'];
  paletteStyle: TextStyle;
}>;

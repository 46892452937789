import React from 'react';

import { useLocalizationContext } from '@order/Localization';

import { FeaturedChannel } from '../FeaturedChannel';

export const SecondaryChannel = (props: SecondaryChannelProps) => {
  const { t } = useLocalizationContext();

  return (
    <FeaturedChannel
      theme="quinoa"
      label={t('featured-channel.secondary.optimized.label')}
      title={t('featured-channel.secondary.optimized.title')}
      action={t('featured-channel.secondary.button-medium')}
      accessibilityLabel={t('featured-channel.secondary.a11y')}
      isLoading={false}
      onPress={props.onSeeMoreLocations}
    />
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type SecondaryChannelProps = Readonly<{
  onSeeMoreLocations: () => void;
}>;

import { AddressTypes } from '@sg/garnish';

import type { useCustomer } from '@order/Customer';
import type { useOrders } from '@order/Order';

// ────────────────────────────────────────────────────────────────────────────────

export function checkIfDeliveryOrder(order: Order) {
  const orderType = order.orderType.toLowerCase();
  const deliveryOrderType = AddressTypes.delivery.toLowerCase();

  return orderType === deliveryOrderType;
}

export function checkIfHasValidDeliveryAddress(
  order: Order,
  customerAddressesIds: ReadonlySet<string>,
) {
  const deliveryAddressId = order.deliveryOrderDetail?.address?.id ?? '';

  return customerAddressesIds.has(deliveryAddressId);
}

export function getCustomerAddressesIds(
  customerAddresses: CustomerAddresses = [],
) {
  return new Set(customerAddresses.map((address) => address.id));
}

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type Order = ReturnType<typeof useOrders>['orders'][number];

type CustomerAddresses = ReturnType<
  typeof useCustomer
>['customer']['addresses'];

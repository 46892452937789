import { useCallback, useMemo } from 'react';

import type { RemoveFromCartInput } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';
import { getUrqlError } from '@order/utils';

import { useRemoveFromCartMutation } from '../../../components/Bag/GraphQL/Cart.generated';
import { useNetworkErrorNotice } from '../../useNetworkErrorNotice';
import { useCart } from '../useCart';
import { useCartMutationErrorNotice } from '../useLineItemErrorResponse';
import { useLogCartChangeCustomEvent } from '../useLogCartChangeCustomEvent';

// ────────────────────────────────────────────────────────────────────────────────

export const useRemoveLineItemFromCart = (
  props?: UseRemoveLineItemFromCartProps,
) => {
  const { t } = useLocalizationContext();
  const { onSuccess } = props ?? {};
  const { executeCartMutation, cart } = useCart();
  const logCartChangeCustomEvent = useLogCartChangeCustomEvent();

  const [removeFromCartResponse, removeLineItem] = useRemoveFromCartMutation();
  const { fetching, data } = removeFromCartResponse;

  useCartMutationErrorNotice(data?.removeFromCart);
  useNetworkErrorNotice(removeFromCartResponse);

  // ─── TELEMETRY ───────────────────────────────────────────────────

  const { track } = useTelemetry();

  const onRemoveFromBagFailedTelemetry = useCallback(
    (systemError?: string) => {
      track('bag.remove.failure', {
        userError: t('general.error'),
        systemError,
      });
    },
    [t, track],
  );

  // ─── HELPERS ─────────────────────────────────────────────────────

  const removeLineItemFromCart = useCallback(
    async (lineItemId: RemoveFromCartInput['lineItemId']) => {
      const productName = cart?.lineItems.find(
        (lineItem) => lineItem.id === lineItemId,
      )?.product.name;

      const executeMutation = async () =>
        removeLineItem({ input: { lineItemId } });
      const response = await executeCartMutation(
        executeMutation,
        'remove-line-item',
      );

      const responseData = response.data?.removeFromCart;
      const isSuccessfullyRemoved =
        responseData?.__typename === 'RemoveFromCartSuccess';
      const updatedCart = isSuccessfullyRemoved ? responseData.cart : null;

      if (!isSuccessfullyRemoved) {
        onRemoveFromBagFailedTelemetry(getUrqlError(response.error));

        return;
      }

      if (productName && updatedCart) {
        logCartChangeCustomEvent({
          action: 'REMOVE',
          cart: updatedCart,
          productName,
        });
      }

      onSuccess?.();
    },
    [
      cart?.lineItems,
      executeCartMutation,
      logCartChangeCustomEvent,
      onRemoveFromBagFailedTelemetry,
      onSuccess,
      removeLineItem,
    ],
  );

  // ─────────────────────────────────────────────────────────────────

  return useMemo(
    () => ({ removeLineItemFromCart, fetching }),
    [removeLineItemFromCart, fetching],
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type UseRemoveLineItemFromCartProps = Readonly<{
  onSuccess?: () => void;
}>;

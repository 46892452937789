import type { SweetpassUpgradePageTag } from '@order/components';
import type { TierName } from '@order/graphql';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * Logic for handling the Sweetpass Upgrade Contentful tag.
 * Returns one of:
 * - 'join-sweetpass'
 * - 'upgrade-to-sweetpass-plus'
 * - 'campaign-something'
 */
export function getSweetpassUpgradeContentfulTag(
  props: UseSweetpassUpgradeContentfulTagsProps,
) {
  const { campaignId, sweetpassNextTierName, isFreeTrialAvailable } = props;

  // ─── Tier Tags ───────────────────────────────────────────────────────
  // These are either 'join-sweetpass' or 'upgrade-to-sweetpass-plus'.
  // They are used for querying the content from 'regular' upgrade flows.

  const hasAcceptableTier =
    sweetpassNextTierName === 'SWEETPASS' ||
    sweetpassNextTierName === 'SWEETPASS_PLUS';

  const tierTag = hasAcceptableTier
    ? SWEETPASS_UPGRADE_TAGS[sweetpassNextTierName]
    : undefined;

  // ─── Free Trial Campaign Tags ────────────────────────────────────────
  // These come from the parameters, it will be defaulted if not found.
  // There is no need to include the tier tags in this case,
  // As that's covered by the free trial eligibility check.

  if (isFreeTrialAvailable) {
    const campaignTag = campaignId ? `campaign-${campaignId}` : undefined;

    return campaignTag as SweetpassUpgradePageTag;
  }

  return tierTag;
}

// ─── Constants ───────────────────────────────────────────────────────────────

const SWEETPASS_UPGRADE_TAGS: Record<string, SweetpassUpgradePageTag> = {
  SWEETPASS: 'join-sweetpass',
  SWEETPASS_PLUS: 'upgrade-to-sweetpass-plus',
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseSweetpassUpgradeContentfulTagsProps = Readonly<{
  sweetpassNextTierName?: TierName;
  campaignId?: string;
  isFreeTrialAvailable?: boolean;
}>;

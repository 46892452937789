import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, type TextProps, View, type ViewProps } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, DisplayText, useResponsive } from '@sg/garnish';

const Container = (props: ViewProps) => {
  const { children } = props;
  const { match } = useResponsive();

  return (
    <View style={match([styles.containerXS, styles.containerSM])}>
      {children}
    </View>
  );
};

const Content = (props: ViewProps) => {
  const { children } = props;
  const { match } = useResponsive();

  return (
    <View style={match([styles.contentXs, styles.contentSm])}>{children}</View>
  );
};

const FirstName = (props: FirstNameProps) => {
  const { firstName, style } = props;

  return (
    <BodyText style={style} sizeMatch={['18', '40']}>
      <FormattedMessage {...messages.title} values={{ name: firstName }} />
    </BodyText>
  );
};

const AvailablePoints = (props: AvailablePointsProps) => {
  const { availablePoints, style } = props;

  return (
    <DisplayText style={style} sizeMatch={['32', '80']}>
      <FormattedMessage
        {...messages.subtitle}
        values={{ points: availablePoints }}
      />
    </DisplayText>
  );
};

export const LoyaltyHomeHeader = {
  Container,
  Content,
  FirstName,
  AvailablePoints,
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Hi {name}, you have',
    description: 'Loyalty Home | Header | Title',
  },
  subtitle: {
    defaultMessage: '{points} points',
    description: 'Loyalty Home | Header | Subtitle',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    gap: theme.spacing['2'],
    padding: theme.spacing['4'],
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerSM: {
    gap: theme.spacing['6'],
    padding: theme.spacing['16'],
    alignItems: 'center',
  },
  contentXs: {
    gap: theme.spacing['2'],
  },
  contentSm: {
    gap: theme.spacing['6'],
    alignItems: 'center',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type FirstNameProps = {
  firstName: string;
  style?: TextProps['style'];
};

type AvailablePointsProps = {
  availablePoints: number;
  style?: TextProps['style'];
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { logger as LOG } from '@garnish/logger';
import { type HostedFrameMessageEvent, type HostedFrameRef } from '@sg/garnish';

import { type ApplePayHandlersReference } from '../utils';
import { useApplePayErrorHandling } from './useApplePayErrorHandling';
import { useIsApplePaySupported } from './useIsApplePaySupported';

LOG.enable('ApplePay');
const logger = LOG.extend('ApplePay');

export const useApplePay = () => {
  const { onApplePayError, onApplePayCancellation } =
    useApplePayErrorHandling();
  const isApplePaySupported = useIsApplePaySupported();

  // ─── References ──────────────────────────────────────────────────────

  const applePayHostedFrameRef = useRef<HostedFrameRef>(null);
  const applePayHandlersRef = useRef<ApplePayHandlersReference>(null);

  // ─── State ───────────────────────────────────────────────────────────

  const [applePaySetupStatus, setApplePaySetupStatus] =
    useState<ApplePaySetupStatus>(
      isApplePaySupported ? 'uninitialized' : 'disabled',
    );

  // ─── Effects ─────────────────────────────────────────────────────────
  // Cancel apple pay setup attempt after a certain timeout.

  useEffect(() => {
    if (!isApplePaySupported) return;

    const timeout = setTimeout(() => {
      setApplePaySetupStatus((current) =>
        current === 'uninitialized' ? 'disabled' : current,
      );
    }, APPLE_PAY_SETUP_TIMEOUT);

    return () => {
      clearTimeout(timeout);
    };
  }, [isApplePaySupported]);

  // ─── Callbacks ───────────────────────────────────────────────────────

  const handleApplePayMessages = useCallback(
    async (event: HostedFrameMessageEvent) => {
      const message = parseApplePayMessage(event);
      const {
        paymentMethodId,
        applePaySetupReady,
        applePaySetupFailed,
        cancel,
        error,
      } = message;

      logger.info(message);

      if (error) {
        onApplePayError(error);
        applePayHandlersRef.current?.onApplePayError?.();
      }

      if (cancel) {
        onApplePayCancellation();
        applePayHandlersRef.current?.onApplePayDismiss?.();
      }

      if (applePaySetupReady) {
        setApplePaySetupStatus('ready');
      }

      if (applePaySetupFailed) {
        setApplePaySetupStatus('disabled');
      }

      if (paymentMethodId) {
        await applePayHandlersRef.current?.onCapturePaymentMethodId?.(
          paymentMethodId,
        );
      }
    },
    [applePayHandlersRef, onApplePayError, onApplePayCancellation],
  );

  const sendShowPaymentSheetRequest = useCallback(
    (amount: number) => {
      applePayHostedFrameRef?.current?.postMessage?.(
        JSON.stringify({ type: 'SHOW_PAYMENT_SHEET', amount }),
      );
    },
    [applePayHostedFrameRef],
  );

  return {
    applePayHostedFrameRef,
    applePayHandlersRef,
    isSettingUpApplePay: applePaySetupStatus === 'uninitialized',
    isApplePayReady: applePaySetupStatus === 'ready',
    isApplePayDisabled: applePaySetupStatus === 'disabled',
    handleApplePayMessages,
    sendShowPaymentSheetRequest,
  };
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function parseApplePayMessage(event: HostedFrameMessageEvent) {
  const dataString = event.nativeEvent.data ?? '{}';
  const parsedData = JSON.parse(dataString) as HostedFieldsPayload;

  return parsedData;
}

// ─── Types ─────────────────────────────────────────────────────────────────────

type ApplePaySetupStatus = 'uninitialized' | 'ready' | 'disabled';

type HostedFieldsPayload = Readonly<{
  log?: string;
  paymentMethodId?: string;
  applePaySetupReady?: boolean;
  applePaySetupFailed?: boolean;
  cancel?: boolean;
  error?: { message?: string };
}>;

// ─── Constants ───────────────────────────────────────────────────────────────

const APPLE_PAY_SETUP_TIMEOUT = 5000; // 5 secs

/* eslint-disable react-native/no-unused-styles */
import React from 'react';
import { StyleSheet, type TextStyle } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanCardText = (props: ScanCardBaseProps) => {
  const { children, palette = 'darkKale' } = props;

  return (
    <BodyText
      sizeMatch={['16']}
      style={(styles.container, paletteStyles[palette])}
    >
      {children}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    textAlign: 'center',
    marginBottom: theme.spacing['10'],
  },
});

const paletteStyles = StyleSheet.create<Record<ScanCardTextPalette, TextStyle>>(
  {
    darkKale: {
      color: theme.colors.DARK_KALE,
    },
    neutral: {
      color: theme.colors.NEUTRAL_7,
    },
  },
);

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanCardBaseProps = Readonly<{
  children: string | React.ReactNode;
  palette?: ScanCardTextPalette;
}>;

type ScanCardTextPalette = 'darkKale' | 'neutral';

import type { Address } from '@order/graphql';

/**
 * An address can be a duplicate if its name is the same as an existing one.
 * This is because we don't support multiple addresses with the same name.
 * This however does not apply if it's the same address ID, as that's not a duplicate.
 */
export function isAddressDuplicateByName(props: AddressDuplicateByNameProps) {
  const { addressId, addressName, addresses } = props;

  return addresses.some((address) => {
    const hasSameId = address.id === addressId;
    const hasSameName =
      address.name?.toLowerCase() === addressName?.toLowerCase();

    // Same Id means it's editing the current address, not duplicating it.
    if (hasSameId) return false;

    // Duplicate by name.
    return hasSameName;
  });
}

type AddressDuplicateByNameProps = Readonly<{
  addressId?: string;
  addressName?: string;
  addresses: ReadonlyArray<Partial<Address>>;
}>;

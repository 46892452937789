import { IngredientKind } from '@sg/graphql-schema';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A small helper that returns a state of unavailable base ingredients.
 */
export function checkMissingBaseIngredientsState(
  params: CheckMissingBaseIngredientsStateParams,
) {
  const { productIngredients = [], unavailableIngredients = [] } = params;

  const productBaseIngredients = productIngredients.filter(getBase);
  const unavailableBaseIngredients = unavailableIngredients.filter(getBase);
  const productHasBases = productBaseIngredients.length > 0;

  const areAllBasesUnavailable =
    productHasBases &&
    productBaseIngredients.length === unavailableBaseIngredients.length;
  const someBasesUnavailable =
    productHasBases && unavailableBaseIngredients.length > 0;

  // ─────────────────────────────────────────────────────────────────────

  if (areAllBasesUnavailable) {
    return 'all-bases-unavailable';
  }

  if (someBasesUnavailable) {
    return 'some-bases-unavailable';
  }

  return 'all-bases-available';
}

// ─────────────────────────────────────────────────────────────────────────────

function getBase(ingredient: Ingredient) {
  return ingredient.kind === IngredientKind.Base;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type CheckMissingBaseIngredientsStateParams = {
  productIngredients: readonly Ingredient[] | undefined;
  unavailableIngredients: readonly Ingredient[] | undefined;
};

type Ingredient = { kind: IngredientKind | null };

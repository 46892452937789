import { type TelemetryEventName } from '../../TelemetryEvent';
import { type TelemetryEventOverride } from '../../TelemetryEventOverride';

// ─────────────────────────────────────────────────────────────────────────────

export const launchDarklyEventOverrides: LaunchDarklyOverridesEventOverrides = {
  'join-or-sign-in.open-email-btn.press': () => ({
    event: { name: 'join-or-sign-in.open-email-btn.press' },
  }),
};

// ─── Types ───────────────────────────────────────────────────────────────────

type LaunchDarklyOverridesEventOverrides = {
  readonly [Name in TelemetryEventName]?: TelemetryEventOverride<
    Name,
    LaunchDarklyCustomEvent
  >;
};

export type LaunchDarklyCustomEvent = Readonly<{
  name: string;
  payload?: Record<string, unknown>;
}>;

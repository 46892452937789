import React, { type ComponentProps } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { Button, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuHeaderMoreInfoButton = (
  props: MenuHeaderMoreInfoButtonProps,
) => {
  const { style, ...buttonProps } = props;

  return (
    <Button
      palette="muted"
      size="small"
      style={[styles.button, style]}
      {...buttonProps}
    >
      <FormattedMessage {...messages.label} />
    </Button>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  label: {
    defaultMessage: 'More info',
    description: 'Menu | Header | More info button | Label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuHeaderMoreInfoButtonProps = Omit<
  ComponentProps<typeof Button>,
  'children'
>;

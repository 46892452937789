import { type IngredientModification } from '../../../../graphql/types';
import { type IngredientModificationWithQuantity } from '../../types';
import type { ProductModificationsModelContext } from '../ProductModifications.machine';
import type {
  IngredientModificationsSubKind,
  IngredientsModificationsWithQuantities,
} from '../ProductModifications.types';
import { type ProductIngredientModifications } from '../ProductModifications.types';
import { checkIfBreadKind } from './checkIfBreadKind';
import { getModificationKindLimit } from './common';
import { checkIfBaseKind } from './computedModifications';

// ────────────────────────────────────────────────────────────────────────────────

export const withActiveModification = (
  params: WithActiveModificationParams,
): IngredientsModificationsWithQuantities => {
  const { ingredientsModifications, ingredientModification } = params;

  const duplicatedModification = getDuplicateModification(
    ingredientsModifications,
    ingredientModification,
  );

  const activeModifications = duplicatedModification
    ? withChangedQuantityModification(
        ingredientsModifications,
        ingredientModification,
      )
    : withNewModification(ingredientsModifications, ingredientModification);

  return sortActiveIngredients(activeModifications);
};

export const withoutActiveModification = (
  params: WithoutActiveModificationParams,
) => {
  const {
    isCompleteRemoval,
    ingredientModification,
    ingredientsModifications,
  } = params;

  if (isCompleteRemoval) {
    return ingredientsModifications.filter(
      (currentIngredientModification) =>
        currentIngredientModification.ingredient.id !==
        ingredientModification.ingredient.id,
    );
  }

  const activeIngredientModificationIndex = ingredientsModifications.findIndex(
    (activeIngredientModification) => {
      return (
        ingredientModification.ingredient.id ===
        activeIngredientModification.ingredient.id
      );
    },
  );

  const activeIngredientModification =
    ingredientsModifications[activeIngredientModificationIndex];

  return ingredientsModifications.reduce<
    readonly IngredientModificationWithQuantity[]
  >((activeIngredientModifications, currentIngredientModification) => {
    const isExistingIngredientModification =
      currentIngredientModification.ingredient.id ===
      ingredientModification.ingredient.id;

    if (!isExistingIngredientModification) {
      return [...activeIngredientModifications, currentIngredientModification];
    }

    return activeIngredientModification.quantity === 1
      ? activeIngredientModifications
      : [
          ...activeIngredientModifications,
          {
            ...currentIngredientModification,
            quantity: (currentIngredientModification?.quantity ?? 0) - 1,
          },
        ];
  }, []);
};

export const sortActiveIngredients = (
  ingredientsModifications: IngredientsModificationsWithQuantities,
) => {
  const subKindsSortOrder: Record<IngredientModificationsSubKind, number> = {
    bases: 1,
    toppings: 2,
    superpremiums: 3,
    premiums: 3,
    dressings: 4,
  };

  return [...ingredientsModifications].sort(
    (prevModification, currentModification) => {
      const prevModificationSubKind = prevModification.subKind;
      const currentModificationSubKind = currentModification.subKind;

      if (!prevModificationSubKind || !currentModificationSubKind) return 10;

      return (
        subKindsSortOrder[prevModificationSubKind] -
        subKindsSortOrder[currentModificationSubKind]
      );
    },
  );
};

export const withoutOutOfStockActiveModifications = (
  ingredientsModifications: IngredientsModificationsWithQuantities,
) => {
  return ingredientsModifications.reduce<
    ProductIngredientModifications['active']
  >((modifications, modification) => {
    if (modification.outOfStock) return modifications;

    return [...modifications, modification];
  }, []);
};

// ────────────────────────────────────────────────────────────────────────────────

const getDuplicateModification = (
  ingredientsModifications: IngredientsModificationsWithQuantities,
  ingredientModification: IngredientModificationWithQuantity,
) => {
  return ingredientsModifications.find(
    (activeIngredient) =>
      activeIngredient.ingredient.id === ingredientModification.ingredient.id,
  );
};

const withChangedQuantityModification = (
  ingredientsModifications: IngredientsModificationsWithQuantities,
  ingredientModification: IngredientModificationWithQuantity,
) => {
  return ingredientsModifications.map((activeModification) => {
    const { ingredient, quantity } = activeModification;
    const isDuplicatedModification =
      ingredient.id === ingredientModification.ingredient.id;
    const shouldIncreaseQuantity =
      isDuplicatedModification && typeof quantity === 'number';

    // ────────────────────────────────────────────────────────────────────

    if (shouldIncreaseQuantity) {
      return { ...activeModification, quantity: Number(quantity) + 1 };
    }

    // ────────────────────────────────────────────────────────────────────

    return activeModification;
  });
};

const withNewModification = (
  ingredientsModifications: IngredientsModificationsWithQuantities,
  ingredientModification: IngredientModificationWithQuantity,
) => {
  return [
    ...ingredientsModifications,
    { ...ingredientModification, quantity: 1 },
  ];
};

// ─── EXTERNAL HELPERS ───────────────────────────────────────────────────────────

export const getActiveAndOutOfStockIngredientsModifications = (
  ctx: ProductModificationsModelContext,
) => {
  const { active } = ctx;
  const outOfStockIngredients = getOutOfStockIngredientModifications(ctx);
  const activeWithoutBread = active.filter(
    (ingredientModification) => !checkIfBreadKind(ingredientModification.kind),
  );

  return sortActiveIngredients([
    ...activeWithoutBread,
    ...outOfStockIngredients,
  ]);
};

export function getActiveIngredientsModifications(
  context: ProductModificationsModelContext,
) {
  const { active } = context;

  return active.filter(
    (ingredientModification) => !checkIfBreadKind(ingredientModification.kind),
  );
}

export const getActiveIngredientModificationsMap = (
  activeModifications: IngredientsModificationsWithQuantities,
) => {
  return new Map(
    activeModifications.map((modification) => [
      modification.ingredient.id,
      modification,
    ]),
  );
};

// ─── OUT OF STOCK INGREDIENTS ───────────────────────────────────────────────────

export const getOutOfStockIngredientsNames = (
  ctx: ProductModificationsModelContext,
) => {
  const defaultIngredients = [...ctx.defaults.values()];

  return defaultIngredients.reduce<readonly string[]>(
    (outOfStockIngredients, defaultIngredient) => {
      if (defaultIngredient.outOfStock) {
        return [...outOfStockIngredients, defaultIngredient.ingredient.name];
      }

      return outOfStockIngredients;
    },
    [],
  );
};

const getOutOfStockIngredientModifications = (
  ctx: ProductModificationsModelContext,
) => {
  const defaultIngredients = [...ctx.defaults.values()];

  return defaultIngredients.reduce<readonly IngredientModification[]>(
    (outOfStockIngredientModifications, defaultIngredient) => {
      if (defaultIngredient.outOfStock) {
        return [...outOfStockIngredientModifications, defaultIngredient];
      }

      return outOfStockIngredientModifications;
    },
    [],
  );
};

// ─── LIMITATIONS ─────────────────────────────────────────────────────────────────

export const checkIfTooFewBasesSelected = (
  ctx: ProductModificationsModelContext,
) => {
  const { active, limitations } = ctx;

  const numberOfBasesSelected = active
    .filter(checkIfBaseKind)
    .reduce((total, modification) => total + (modification?.quantity ?? 1), 0);
  const maxBases = getModificationKindLimit('bases', limitations);

  return numberOfBasesSelected < maxBases?.kindMaxLimit;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type WithActiveModificationParams = {
  ingredientsModifications: IngredientsModificationsWithQuantities;
  ingredientModification: IngredientModificationWithQuantity;
};

type WithoutActiveModificationParams = {
  ingredientsModifications: IngredientsModificationsWithQuantities;
  ingredientModification: IngredientModificationWithQuantity;
  isCompleteRemoval?: boolean;
};

import React, { type ComponentProps, forwardRef, useCallback } from 'react';
import {
  Pressable,
  type PressableStateCallbackType,
  type StyleProp,
  StyleSheet,
  type View,
  type ViewProps,
  type ViewStyle,
} from 'react-native';
import { theme } from '@garnish/constants';

import { webOnlyStyles } from '../../../../utils';
import { ACTIVE_INGREDIENT_PRESSABLE } from '../../ActiveIngredient.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const ActiveIngredientPressable = forwardRef<
  View,
  ActiveIngredientPressableProps
>((props, ref) => {
  const { children, style, active, hovered, ...restProps } = props;

  const pressableStyles = useCallback(
    (state: PressableStateCallbackType): StyleProp<ViewStyle> => {
      const { pressed } = state;

      return [
        styles.pressable,
        pressableWebStyles,
        pressed ? styles.pressablePressed : undefined,
        active ? styles.pressableActive : undefined,
        hovered ? styles.pressableHover : undefined,
        style,
      ];
    },
    [active, hovered, style],
  );

  return (
    <Pressable ref={ref} role="button" style={pressableStyles} {...restProps}>
      {children}
    </Pressable>
  );
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['2'],
    paddingVertical: ACTIVE_INGREDIENT_PRESSABLE.PADDING.VERTICAL,
    paddingRight: ACTIVE_INGREDIENT_PRESSABLE.PADDING.HORIZONTAL_WITH_BUTTON,
    paddingLeft: ACTIVE_INGREDIENT_PRESSABLE.PADDING.HORIZONTAL,
    borderRadius: 64,
    userSelect: 'none',
  },
  pressableHover: {
    backgroundColor: theme.colors.KALE,
  },
  pressablePressed: {
    opacity: 0.7,
  },
  pressableActive: {
    backgroundColor: theme.colors.KALE,
  },
});

const pressableWebStyles = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: theme.spacing['1'],
  outlineWidth: 2,
  transition: theme.transitions.presets.colorsAndOpacity,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ActiveIngredientPressableProps = {
  hovered?: boolean;
  active?: boolean;
  style?: ViewProps['style'];
} & Omit<ComponentProps<typeof Pressable>, 'style'>;

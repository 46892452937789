/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OfferDataFragment = {
  readonly __typename: 'Challenge';
  readonly assetUrl: string | null;
  readonly achieved: number;
  readonly celebrationCopy: string;
  readonly descriptiveTitle: string;
  readonly expirationDate: string;
  readonly goal: number;
  readonly id: string;
  readonly legalTerms: string;
  readonly percentageCompleted: number;
  readonly permalink: string;
  readonly progressType: Types.ChallengeProgressType;
  readonly progress: number;
  readonly progressCopy: string;
  readonly status: Types.ChallengeStatus;
  readonly title: string;
};

export type LoyaltyOffersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LoyaltyOffersQuery = {
  readonly __typename?: 'Query';
  readonly challenges: ReadonlyArray<{
    readonly __typename: 'Challenge';
    readonly theme: Types.ChallengeTheme;
    readonly assetUrl: string | null;
    readonly achieved: number;
    readonly celebrationCopy: string;
    readonly descriptiveTitle: string;
    readonly expirationDate: string;
    readonly goal: number;
    readonly id: string;
    readonly legalTerms: string;
    readonly percentageCompleted: number;
    readonly permalink: string;
    readonly progressType: Types.ChallengeProgressType;
    readonly progress: number;
    readonly progressCopy: string;
    readonly status: Types.ChallengeStatus;
    readonly title: string;
  }>;
};

export type LoyaltyOfferOptInMutationVariables = Types.Exact<{
  challengeId: Types.Scalars['String']['input'];
}>;

export type LoyaltyOfferOptInMutation = {
  readonly __typename?: 'Mutation';
  readonly challengeOptInV2:
    | {
        readonly __typename: 'Challenge';
        readonly theme: Types.ChallengeTheme;
        readonly assetUrl: string | null;
        readonly achieved: number;
        readonly celebrationCopy: string;
        readonly descriptiveTitle: string;
        readonly expirationDate: string;
        readonly goal: number;
        readonly id: string;
        readonly legalTerms: string;
        readonly percentageCompleted: number;
        readonly permalink: string;
        readonly progressType: Types.ChallengeProgressType;
        readonly progress: number;
        readonly progressCopy: string;
        readonly status: Types.ChallengeStatus;
        readonly title: string;
      }
    | {
        readonly __typename: 'LoyaltyOptInFailed';
        readonly errorMessage: string;
      };
};

export const OfferDataFragmentDoc = gql`
  fragment OfferData on Challenge {
    assetUrl
    achieved
    celebrationCopy
    descriptiveTitle
    expirationDate
    goal
    id
    legalTerms
    percentageCompleted
    permalink
    progressType
    progress
    progressCopy
    status
    title
    __typename
  }
`;
export const LoyaltyOffersDocument = gql`
  query LoyaltyOffers {
    challenges {
      ...OfferData
      theme
    }
  }
  ${OfferDataFragmentDoc}
`;

export function useLoyaltyOffersQuery(
  options?: Omit<Urql.UseQueryArgs<LoyaltyOffersQueryVariables>, 'query'>,
) {
  return Urql.useQuery<LoyaltyOffersQuery, LoyaltyOffersQueryVariables>({
    query: LoyaltyOffersDocument,
    ...options,
  });
}
export const LoyaltyOfferOptInDocument = gql`
  mutation loyaltyOfferOptIn($challengeId: String!) {
    challengeOptInV2(challengeId: $challengeId) {
      __typename
      ... on Challenge {
        ...OfferData
        theme
      }
      ... on LoyaltyOptInFailed {
        errorMessage
        __typename
      }
    }
  }
  ${OfferDataFragmentDoc}
`;

export function useLoyaltyOfferOptInMutation() {
  return Urql.useMutation<
    LoyaltyOfferOptInMutation,
    LoyaltyOfferOptInMutationVariables
  >(LoyaltyOfferOptInDocument);
}

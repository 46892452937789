import React, { useCallback, useState } from 'react';
import type { LayoutChangeEvent } from 'react-native';
import { View } from 'react-native';
import Animated, {
  useAnimatedStyle,
  withTiming,
} from 'react-native-reanimated';
import { useStyle } from 'react-native-style-utilities';

import { isWebBrowser } from '../../../utils';
import { BodyText } from '../../Text';
import {
  LABEL_ANIMATION_CONFIG,
  LABEL_BOTTOM_OFFSET,
  LABEL_IDLE_SCALE,
} from '../TextField.constants';
import { styles } from '../TextField.styles';
import type {
  LabelMeasurements,
  TextFieldFloatingLabelProps,
} from '../TextField.types';

export const TextFieldFloatingLabel = (props: TextFieldFloatingLabelProps) => {
  const {
    label,
    labelOffset,
    isFieldActive,
    nativeID,
    sizeMatch = ['16'],
  } = props;

  const [labelMeasurements, setLabelMeasurements] = useState<LabelMeasurements>(
    { width: 0, height: 0 },
  );

  // ─── HELPERS ────────────────────────────────────────────────────────

  const trackLabelMeasurements = useCallback((event: LayoutChangeEvent) => {
    setLabelMeasurements(event.nativeEvent.layout);
  }, []);

  const isLabelRendered = labelMeasurements.height !== 0;

  // ─── DYNAMIC STYLES ─────────────────────────────────────────────────

  const labelWrapperDynamicStyles = useStyle(() => {
    // hide initial flickering when label's height has not been calculated
    if (!isLabelRendered) return { opacity: 0 };

    return {
      transform: [{ translateY: -labelMeasurements.height / 2 }],
    };
  }, [isLabelRendered, labelMeasurements.height]);

  // ─── ANIMATED STYLES ────────────────────────────────────────────────

  const labelTranslationAnimatedStyles = useAnimatedStyle(() => {
    if (!isLabelRendered) return {};

    const { top: positionY = 0, left: positionX = 0 } = labelOffset?.idle ?? {};

    const { positionY: activePositionY, positionX: activePositionX } =
      calculatedFloatingLabelPosition(labelMeasurements, labelOffset);

    const translateY = changeValueWithTiming(
      isFieldActive ? activePositionY : positionY,
    );
    const translateX = changeValueWithTiming(
      isFieldActive ? activePositionX : positionX,
    );

    return { transform: [{ translateY }, { translateX }] };
  }, [
    labelOffset?.idle?.top,
    labelOffset?.idle?.left,
    isLabelRendered,
    isFieldActive,
    labelMeasurements.width,
    labelMeasurements.height,
  ]);

  const labelScaleAnimatedStyles = useAnimatedStyle(() => {
    if (!isLabelRendered) return {};

    const scale = changeValueWithTiming(isFieldActive ? LABEL_IDLE_SCALE : 1);

    return { transform: [{ scale }] };
  }, [isLabelRendered, isFieldActive]);

  // ────────────────────────────────────────────────────────────────────

  if (!label) return null;

  return (
    <View
      style={[styles.labelWrapper, labelWrapperDynamicStyles]}
      onLayout={trackLabelMeasurements}
      pointerEvents="none"
    >
      <Animated.View style={labelTranslationAnimatedStyles}>
        <Animated.View
          style={labelScaleAnimatedStyles}
          pointerEvents={isFieldActive ? 'box-none' : 'none'}
        >
          <BodyText
            nativeID={nativeID}
            // @ts-expect-error TS(2322): Type '"label" | undefined' is not assignable to ty... Remove this comment to see the full error message
            accessibilityRole={isWebBrowser() ? 'label' : undefined}
            sizeMatch={sizeMatch}
            numberOfLines={1}
          >
            {label}
          </BodyText>
        </Animated.View>
      </Animated.View>
    </View>
  );
};

// ─── HELPERS ────────────────────────────────────────────────────────────────────

export const calculatedFloatingLabelPosition = (
  labelMeasurements: LabelMeasurements,
  labelOffset?: LabelOffset,
) => {
  'worklet';

  const { width, height } = labelMeasurements;
  const { top: topOffset = 0, left: leftOffset = 0 } =
    labelOffset?.active ?? {};

  const leftPercentage = 1 - LABEL_IDLE_SCALE;
  const widthDifferenceAfterScale = width * leftPercentage;

  const positionX = -Math.ceil(widthDifferenceAfterScale / 2 - leftOffset);
  const positionY = -(height + LABEL_BOTTOM_OFFSET) + topOffset;

  return { positionY, positionX };
};

export const changeValueWithTiming = (value: number) => {
  'worklet';

  return withTiming(value, LABEL_ANIMATION_CONFIG);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type LabelOffset = {
  idle?: { top?: number; left?: number };
  active?: { top?: number; left?: number };
};

import React, { useState } from 'react';
import type { LayoutChangeEvent } from 'react-native';
import { Platform, Pressable, StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { IllusEmpty_2 } from '../../assets';
import { usePressableState, useResponsive } from '../../hooks';
import { IconLink } from '../Icon';
import type { CloudinaryTransformConfig } from '../Image';
import { FallbackImage, Image } from '../Image';
import { LoadingDots } from '../LoadingDots';
import { BodyText } from '../Text';
import type { LocationCardProps } from './LocationCard.types';
import type {
  LocationCardContainerProps,
  LocationCardStoreNameProps,
} from './LocationCard.types';

// ────────────────────────────────────────────────────────────────────────────────

export const LocationCard = ({
  imageUrl,
  containerStyle,
  imageStyle,
  ...props
}: LocationCardProps): React.ReactElement => {
  const cardRef = React.useRef<View>(null);
  const { isHovered, isFocused } = usePressableState(cardRef);

  const isPressable = Boolean(props.onCardPress);
  const isActive = isPressable ? isHovered || isFocused : false;
  const shouldRenderImage = Boolean(!props.isOutpost && imageUrl);

  return (
    <CardContainer cardRef={cardRef} containerStyle={containerStyle} {...props}>
      {shouldRenderImage ? (
        <StoreImage imageUrl={imageUrl} imageStyle={imageStyle} />
      ) : null}

      <InfoContainer {...props}>
        <DescriptionContainer {...props}>
          <StoreName isActive={isActive} {...props} />
          <StoreAddress {...props} />
          <StoreHours {...props} />
          <GeneralNotice {...props} />
          <ClosedNotice {...props} />
        </DescriptionContainer>
      </InfoContainer>
    </CardContainer>
  );
};

// ─── Subcomponents ──────────────────────────────────────────────────────────────

const CardContainer = ({
  children,
  containerStyle,
  id,
  restaurantSlug,
  name,
  cardRef,
  onCardPress,
  onCardFocus,
  onCardLayout,
}: LocationCardContainerProps) => {
  const [isPressing, setIsPressing] = useState(false);

  const handleCardPress = React.useCallback(() => {
    if (!onCardPress || !restaurantSlug) return;

    onCardPress({
      restaurantId: id ?? '',
      restaurantSlug: restaurantSlug ?? '',
      restaurantName: name ?? '',
    });
  }, [id, restaurantSlug, name, onCardPress]);

  const handleCardFocus = React.useCallback(() => {
    if (isPressing) return;

    onCardFocus?.(restaurantSlug);
  }, [isPressing, onCardFocus, restaurantSlug]);

  const handleCardLayout = React.useCallback(
    (event: LayoutChangeEvent) => {
      onCardLayout?.(event, id);
    },
    [id, onCardLayout],
  );

  return onCardPress ? (
    <Pressable
      accessibilityRole="button"
      testID={`location-card-container-${restaurantSlug}`}
      ref={cardRef}
      onPress={handleCardPress}
      onLayout={handleCardLayout}
      onFocus={handleCardFocus}
      onPressIn={() => {
        setIsPressing(true);
      }}
      onPressOut={() => {
        setIsPressing(false);
      }}
      style={containerStyle}
      {...CARD_PRESSABLE_WEB_ONLY_PROPS}
    >
      {children}
    </Pressable>
  ) : (
    <View
      testID={`location-card-container-${restaurantSlug}`}
      ref={cardRef}
      onLayout={handleCardLayout}
      onAccessibilityTap={handleCardFocus}
      style={containerStyle}
    >
      {children}
    </View>
  );
};

const InfoContainer = ({ children }: LocationCardProps) => (
  <View style={styles.infoContainer}>{children}</View>
);

const DescriptionContainer = ({ children }: LocationCardProps) => {
  return <View style={styles.descriptionContainer}>{children}</View>;
};

const StoreImage = React.memo(
  ({
    imageUrl,
    imageStyle,
  }: Pick<LocationCardProps, 'imageUrl' | 'imageStyle'>) => {
    const { match } = useResponsive();

    const imageStyles = [
      styles.storeImage,
      match([styles.storeImageMobile, styles.storeImageDesktop]),
      imageStyle,
    ];

    // ─── Remote Asset ────────────────────────────────────────────────────

    if (typeof imageUrl === 'string') {
      return (
        <FallbackImage
          baseUrl={imageUrl}
          defaultImage={IllusEmpty_2}
          testID="location-card-image"
          resizeMode="cover"
          style={imageStyles}
          cloudinaryConfig={IMAGE_CLOUDINARY_CONFIG}
        />
      );
    }

    // ─── Local Asset ─────────────────────────────────────────────────────

    return (
      <Image
        source={imageUrl}
        testID="location-card-image"
        contentFit="contain"
        style={imageStyles}
      />
    );
  },
);

const StoreName = ({
  id,
  name,
  isLoading,
  isActive,
  onInfoPress,
}: LocationCardStoreNameProps) => {
  return (
    <View style={styles.titleContainer}>
      <BodyText
        accessibilityRole="header"
        aria-level={3}
        style={[styles.title, onInfoPress && styles.titleWidth]}
        size={3}
        bold
        underline={isActive}
      >
        {name}
        {isLoading ? (
          <View style={styles.loadingContainer}>
            <LoadingDots color={theme.colors.DARK_KALE} />
          </View>
        ) : null}
      </BodyText>
      {onInfoPress ? <InfoIcon id={id} onInfoPress={onInfoPress} /> : null}
    </View>
  );
};

const StoreAddress = ({
  distance,
  address,
  city,
  state,
  zipCode,
}: LocationCardProps) => {
  const fullAddress = `${address}\n${city}, ${state} ${zipCode}`;

  if (!distance) {
    return (
      <BodyText
        // @ts-expect-error TS(2322): Type '{ children: string; dataSet: { ddPrivacy: st... Remove this comment to see the full error message
        dataSet={{ ddPrivacy: 'mask' }}
        style={styles.text}
        size={4}
        testID="location-card-address"
      >
        {fullAddress}
      </BodyText>
    );
  }

  return (
    <BodyText
      data-dd-privacy="mask"
      style={styles.text}
      size={4}
      testID="location-card-address"
    >
      {distance.toFixed(1)} miles · {fullAddress}
    </BodyText>
  );
};

const StoreHours = ({ acceptingOrders, storeHours }: LocationCardProps) => {
  if (!acceptingOrders) return null;

  return (
    <BodyText style={styles.text} size={4}>
      {storeHours}
    </BodyText>
  );
};

const GeneralNotice = ({ flexMessage }: LocationCardProps) => {
  if (!flexMessage) return null;

  return (
    <View style={styles.generalNotice}>
      <BodyText style={styles.flexMessage} size={4}>
        {flexMessage}
      </BodyText>
    </View>
  );
};

const ClosedNotice = (props: LocationCardProps) => {
  if (props.acceptingOrders) return null;

  return (
    <>
      <ClosedWarning />
      <ClosedReason {...props} />
    </>
  );
};

const ClosedWarning = () => (
  <BodyText style={styles.closedWarning} size={4}>
    Closed
  </BodyText>
);

const ClosedReason = ({ notAcceptingOrdersReason }: LocationCardProps) => (
  <BodyText style={styles.notAcceptingOrdersReason} size={4}>
    {notAcceptingOrdersReason}
  </BodyText>
);

const InfoIcon = ({
  id,
  onInfoPress,
}: Pick<LocationCardProps, 'id' | 'onInfoPress'>) => {
  const handleInfoPress = React.useCallback(() => {
    onInfoPress?.(id);
  }, [id, onInfoPress]);

  return (
    <IconLink
      testID={`info-icon-${id}`}
      name="IconInfo"
      onPress={handleInfoPress}
    />
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  infoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  descriptionContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  storeImage: {
    width: '100%',
    borderRadius: theme.radius.medium,
    marginBottom: theme.spacing['2'],
  },
  storeImageMobile: {
    height: 150,
  },
  storeImageDesktop: {
    height: 200,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  loadingContainer: {
    marginLeft: theme.spacing['2'],
  },
  title: {
    color: theme.colors.GREEN_1,
  },
  titleWidth: {
    maxWidth: '90%',
  },
  text: {
    color: theme.colors.NEUTRAL_2,
  },
  flexMessage: {
    color: theme.colors.TEXT_COLOR,
  },
  notAcceptingOrdersReason: {
    color: theme.colors.CAUTION,
  },
  generalNotice: {
    marginTop: 4,
  },
  closedWarning: {
    marginTop: 4,
    color: theme.colors.CAUTION,
  },
});

// ─── Constants ──────────────────────────────────────────────────────────────────

const IMAGE_CLOUDINARY_CONFIG: CloudinaryTransformConfig = {
  width: 350,
};

const CARD_PRESSABLE_WEB_ONLY_PROPS =
  Platform.OS === 'web' ? { delayPressIn: 0, delayPressOut: 0 } : {};

import React, { useCallback } from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, Text, View } from 'react-native';
import { theme, TYPE_CONFIG } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { Container } from '../../Container';
import { IconLink } from '../../Icon';
import { BodyText, DisplayText } from '../../Text';

export const StaticProductHeader = (props: StaticProductHeaderProps) => {
  const {
    value,
    price,
    calories,
    description,
    isModifiableProduct,
    isCustomizationActive,
    style,
    onEditIngredients,
  } = props;

  const { textFontSize, descriptionFontSize } = useTextSizes();
  const headerWrapperDynamicStyles = useHeaderWrapperDynamicStyles();
  const headerWrapperStyles = [headerWrapperDynamicStyles, style];

  // ─── HELPERS ────────────────────────────────────────────────────────

  const handleStartEditing = useCallback(() => {
    onEditIngredients?.();
  }, [onEditIngredients]);

  // ────────────────────────────────────────────────────────────────────

  return (
    <Container style={styles.container}>
      <View style={headerWrapperStyles}>
        <View style={styles.headerTitleWrapper}>
          <ProductHeaderTitle
            title={value}
            show={!isCustomizationActive}
            onEditIngredients={handleStartEditing}
            isModifiableProduct={Boolean(isModifiableProduct)}
          />
        </View>

        <Text style={styles.priceAndCaloriesWrapper}>
          <BodyText testID="pdp-product-price" size={textFontSize}>
            {price}
          </BodyText>
          <BodyText size={textFontSize}> - </BodyText>
          <BodyText size={textFontSize}>{calories}</BodyText>
        </Text>
      </View>

      {description ? (
        <View style={styles.descriptionWrapper}>
          <BodyText size={descriptionFontSize}>{description}</BodyText>
        </View>
      ) : null}
    </Container>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const ProductHeaderTitle = (props: ProductHeaderTitleProps) => {
  const {
    title,
    show,
    isModifiableProduct,
    editAccessibilityLabel = 'edit product',
    onEditIngredients,
  } = props;
  const { match } = useResponsive();
  const iconSize = useIconSize();

  const titleStyle = [
    styles.headerTitle,
    match([styles.titleMobile, styles.titleTablet]),
  ];

  return (
    <>
      <DisplayText
        accessibilityRole="header"
        aria-level={1}
        style={titleStyle}
        testID="product-header.name"
      >
        {title}
      </DisplayText>

      {isModifiableProduct && show ? (
        <IconLink
          testID="product-header.btn-edit"
          name="IconEdit"
          accessibilityLabel={editAccessibilityLabel}
          onPress={onEditIngredients}
          iconSize={iconSize}
        />
      ) : null}
    </>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.KHAKI_3,
    paddingBottom: theme.spacing['6'],
  },

  // ─── HEADER ─────────────────────────────────────────────────────────

  headerWrapperXSmall: {
    paddingTop: theme.spacing['6'],
  },
  headerWrapperSmallUp: {
    paddingTop: theme.spacing['10'],
  },
  headerTitleWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 36,
  },
  headerTitle: {
    paddingRight: theme.spacing['4'],
  },
  priceAndCaloriesWrapper: {
    paddingTop: theme.spacing['2'],
  },

  // ─── TITLE ──────────────────────────────────────────────────────────

  titleMobile: {
    ...TYPE_CONFIG.DISPLAY['24'],
  },
  titleTablet: {
    ...TYPE_CONFIG.DISPLAY['32'],
  },

  // ─── DESCRIPTION ────────────────────────────────────────────────────

  descriptionWrapper: {
    paddingTop: theme.spacing['4'],
  },
});

// ─── HELPERS ────────────────────────────────────────────────────────────────────

const useTextSizes = (): ProductHeaderTextSizes => {
  const { currentBreakpoint } = useResponsive();

  return {
    textFontSize: currentBreakpoint.isXS ? 4 : 3,
    descriptionFontSize: 4,
  };
};

const useIconSize = () => {
  const { currentBreakpoint } = useResponsive();

  return currentBreakpoint.isXS ? 24 : 32;
};

const useHeaderWrapperDynamicStyles = () => {
  const { currentBreakpoint } = useResponsive();

  return currentBreakpoint.isXS
    ? styles.headerWrapperXSmall
    : styles.headerWrapperSmallUp;
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type StaticProductHeaderProps = Readonly<{
  value: string;
  price: string;
  calories: string;
  description?: string;
  isModifiableProduct?: boolean;
  isCustomizationActive?: boolean;
  textInputAccessibilityLabel: string;
  style?: ViewProps['style'];
  onEditIngredients?: () => void;
}>;

type ProductHeaderTextSizes = Readonly<{
  textFontSize: 3 | 4;
  descriptionFontSize: 4;
}>;

type ProductHeaderTitleProps = Readonly<{
  title: string;
  show: boolean;
  isModifiableProduct: boolean;
  editAccessibilityLabel?: string;
  onEditIngredients: () => void;
}>;

export const sweetpassLandingPageSweetpassContentfulFallback = {
  fields: {
    name: 'Sweetpass Upgrade: Join Sweetpass',
    content: [
      {
        metadata: {
          tags: [
            {
              sys: {
                type: 'Link',
                linkType: 'Tag',
                id: 'join-sweetpass',
              },
            },
          ],
        },
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'wme4s8lvzccr',
            },
          },
          id: '5OU3Q20JwzatLhfKogNatD',
          type: 'Entry',
          createdAt: '2022-10-27T18:08:35.294Z',
          updatedAt: '2023-06-22T16:19:15.838Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 9,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'sweetpassUpgradeHeader',
            },
          },
          locale: 'en-US',
        },
        fields: {
          name: 'Sweetpass Upgrade: Join Sweetpass: Header',
          heroImage: {
            metadata: {
              tags: [],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              id: 'F34JF2Scf6UHZuIZL0xZT',
              type: 'Asset',
              createdAt: '2023-01-20T16:17:17.477Z',
              updatedAt: '2023-05-03T11:00:19.650Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              revision: 2,
              locale: 'en-US',
            },
            fields: {
              title: 'sweetpass card image new',
              description: 'Sweetpass illustration',
              file: {
                url: '//images.ctfassets.net/wme4s8lvzccr/F34JF2Scf6UHZuIZL0xZT/bd3db2635a18c0df5baa4e91101e3257/Card_Angle_5_Sweetpass_Drop_Shadow.png',
                details: {
                  size: 138_277,
                  image: {
                    width: 640,
                    height: 331,
                  },
                },
                fileName: 'Card_Angle_5_Sweetpass_Drop_Shadow.png',
                contentType: 'image/png',
              },
            },
          },
          headingText: 'A delicious life begins here.',
          headingTextXs: 'A delicious life\nbegins here.',
          headingTextV2: 'A Delicious Life\nBegins Here',
          bodyTextGeneric:
            'Join Sweetpass—our new, free loyalty program. Explore the benefits of 2023.',
          bodyText:
            'Join Sweetpass—our new, free loyalty program. Explore the benefits of 2023.',
          cta: {
            metadata: {
              tags: [],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              id: '37ZnmIbI4uW5sRpbX1IlV7',
              type: 'Entry',
              createdAt: '2022-10-27T17:49:46.345Z',
              updatedAt: '2023-06-22T16:04:34.541Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              revision: 7,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'sweetpassUpgradeCTA',
                },
              },
              locale: 'en-US',
            },
            fields: {
              name: 'Sweetpass Upgrade: Join Sweetpass: CTA',
              titleGeneric: 'Join Sweetpass for free',
              titleV3: 'Join Sweetpass for free',
              action: 'join_sweetpass',
              palette: 'lime',
            },
          },
          palette: 'kale',
        },
      },
      {
        metadata: {
          tags: [
            {
              sys: {
                type: 'Link',
                linkType: 'Tag',
                id: 'join-sweetpass',
              },
            },
          ],
        },
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'wme4s8lvzccr',
            },
          },
          id: '3Jm4d0P1ANx8dJGNmd36RY',
          type: 'Entry',
          createdAt: '2022-10-27T18:57:46.623Z',
          updatedAt: '2023-06-22T16:19:29.596Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 8,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'sweetpassUpgradeBenefitList',
            },
          },
          locale: 'en-US',
        },
        fields: {
          name: 'Sweetpass Upgrade: Join Sweetpass: Benefit List',
          itemsPerRow: 3,
          benefitList: [
            {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'wme4s8lvzccr',
                  },
                },
                id: '62Cd4q6SpystNCvJjnp1rW',
                type: 'Entry',
                createdAt: '2022-10-27T18:52:43.875Z',
                updatedAt: '2023-03-27T15:51:34.667Z',
                environment: {
                  sys: {
                    id: 'master',
                    type: 'Link',
                    linkType: 'Environment',
                  },
                },
                revision: 5,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'sweetpassUpgradeBenefit',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                name: 'Sweetpass Upgrade Benefit: Rewards and challenges',
                image: {
                  metadata: {
                    tags: [],
                  },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'wme4s8lvzccr',
                      },
                    },
                    id: '4P532Rt0MgDIYmgwwQdlQ3',
                    type: 'Asset',
                    createdAt: '2022-10-27T18:52:34.628Z',
                    updatedAt: '2022-10-27T18:58:54.525Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 2,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Sweetpass Upgrade Benefit: Rewards and challenges',
                    description: '',
                    file: {
                      url: '//images.ctfassets.net/wme4s8lvzccr/4P532Rt0MgDIYmgwwQdlQ3/9f2c3edf8202c4cbe7c95ad21ba04ee3/upgrade-benefit.rewards-and-challenges.png',
                      details: {
                        size: 4_095_845,
                        image: {
                          width: 2048,
                          height: 2048,
                        },
                      },
                      fileName: 'upgrade-benefit.rewards-and-challenges.png',
                      contentType: 'image/png',
                    },
                  },
                },
                titleText: 'Rewards and Challenges',
                bodyText:
                  'Opt in and complete delicious challenges to savor more rewards.',
              },
            },
            {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'wme4s8lvzccr',
                  },
                },
                id: 'Xe6EmYuliAWP3jHNHIepl',
                type: 'Entry',
                createdAt: '2022-10-27T18:51:22.273Z',
                updatedAt: '2023-03-27T15:58:24.149Z',
                environment: {
                  sys: {
                    id: 'master',
                    type: 'Link',
                    linkType: 'Environment',
                  },
                },
                revision: 6,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'sweetpassUpgradeBenefit',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                name: 'Sweetpass Upgrade Benefit: Birthday gift',
                image: {
                  metadata: {
                    tags: [],
                  },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'wme4s8lvzccr',
                      },
                    },
                    id: '2Cm6rx9eiqunKy7uj28ORZ',
                    type: 'Asset',
                    createdAt: '2022-10-27T18:51:02.247Z',
                    updatedAt: '2022-10-27T18:58:54.533Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 2,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Sweetpass Upgrade Benefit: Birthday gift',
                    description: '',
                    file: {
                      url: '//images.ctfassets.net/wme4s8lvzccr/2Cm6rx9eiqunKy7uj28ORZ/7d050c8df32495b8a110118e5ebdbf83/upgrade-benefit.birthday-gift.png',
                      details: {
                        size: 3_615_154,
                        image: {
                          width: 2048,
                          height: 2048,
                        },
                      },
                      fileName: 'upgrade-benefit.birthday-gift.png',
                      contentType: 'image/png',
                    },
                  },
                },
                titleText: 'Birthday\nGift',
                bodyText:
                  'Celebrate your special day with something sweet from us.',
              },
            },
            {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'wme4s8lvzccr',
                  },
                },
                id: '6E71U9CJDJSsQgPLJyj6KQ',
                type: 'Entry',
                createdAt: '2022-10-27T18:56:29.819Z',
                updatedAt: '2023-03-17T18:00:31.508Z',
                environment: {
                  sys: {
                    id: 'master',
                    type: 'Link',
                    linkType: 'Environment',
                  },
                },
                revision: 6,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'sweetpassUpgradeBenefit',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                name: 'Sweetpass Upgrade Benefit: Sweetgreen Bowl Drops',
                image: {
                  metadata: {
                    tags: [],
                  },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'wme4s8lvzccr',
                      },
                    },
                    id: '6FLiQI94jm6LTfCoq2TnWE',
                    type: 'Asset',
                    createdAt: '2022-10-27T18:56:19.479Z',
                    updatedAt: '2022-10-27T18:58:54.540Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 2,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Sweetpass Upgrade Benefit: Sweetgreen exclusives',
                    description: '',
                    file: {
                      url: '//images.ctfassets.net/wme4s8lvzccr/6FLiQI94jm6LTfCoq2TnWE/8925221ac656c4b84adf662a4c9dcf2e/upgrade-benefit.sweetgreen-exclusives.png',
                      details: {
                        size: 4_991_048,
                        image: {
                          width: 2048,
                          height: 2048,
                        },
                      },
                      fileName: 'upgrade-benefit.sweetgreen-exclusives.png',
                      contentType: 'image/png',
                    },
                  },
                },
                titleText: 'Sweetpass Bowl Drops',
                bodyText:
                  'Get special access to online-only menu drops exclusive to Sweetpass members.',
              },
            },
            {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'wme4s8lvzccr',
                  },
                },
                id: '5obDlUdNvhiBut9ltLMPBC',
                type: 'Entry',
                createdAt: '2022-10-27T18:57:37.924Z',
                updatedAt: '2023-05-24T20:50:39.277Z',
                environment: {
                  sys: {
                    id: 'master',
                    type: 'Link',
                    linkType: 'Environment',
                  },
                },
                revision: 7,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'sweetpassUpgradeBenefit',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                name: 'Sweetpass Upgrade Benefit: merch drops',
                image: {
                  metadata: {
                    tags: [],
                  },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'wme4s8lvzccr',
                      },
                    },
                    id: '6uJ1H7kwsPrQfIBNfv9WP1',
                    type: 'Asset',
                    createdAt: '2022-10-27T18:57:32.612Z',
                    updatedAt: '2022-10-27T18:58:54.554Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 2,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Sweetpass Upgrade Benefit: Limited edition drops',
                    description: '',
                    file: {
                      url: '//images.ctfassets.net/wme4s8lvzccr/6uJ1H7kwsPrQfIBNfv9WP1/125f29f4e6ed23d482244b32ddff66e8/upgrade-benefit.limited-edition-drops.png',
                      details: {
                        size: 2_819_758,
                        image: {
                          width: 2048,
                          height: 2048,
                        },
                      },
                      fileName: 'upgrade-benefit.limited-edition-drops.png',
                      contentType: 'image/png',
                    },
                  },
                },
                titleText: 'Merch Drops',
                bodyText: 'Get access to limited-edition sweetgreen merch.',
              },
            },
          ],
        },
      },
    ],
    cta: {
      metadata: {
        tags: [
          {
            sys: {
              type: 'Link',
              linkType: 'Tag',
              id: 'join-sweetpass',
            },
          },
        ],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'wme4s8lvzccr',
          },
        },
        id: '6EmFlOMaCqMDu4Q3XskGvI',
        type: 'Entry',
        createdAt: '2023-05-04T15:47:13.002Z',
        updatedAt: '2023-06-22T16:19:53.681Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 3,
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: 'sweetpassUpgradeCTA',
          },
        },
        locale: 'en-US',
      },
      fields: {
        name: 'Sweetpass Upgrade: Join Sweetpass: Page CTA',
        titleGeneric: 'Join Sweetpass for free',
        titleV3: 'Join Sweetpass for free',
        action: 'join_sweetpass',
        palette: 'kale',
      },
    },
  },
};

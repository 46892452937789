import {
  BagFloatingButton,
  FloatingButton,
  FloatingButtonsContainer,
} from './components';

export const FloatingButtons = {
  Default: FloatingButton,
  Container: FloatingButtonsContainer,
  Bag: BagFloatingButton,
} as const;

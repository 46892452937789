import {
  LoyaltyOfferCardButton,
  LoyaltyOfferCardContainer,
  LoyaltyOfferCardContentContainer,
  LoyaltyOfferCardFloatingLabel,
  LoyaltyOfferCardFooter,
  LoyaltyOfferCardImage,
  LoyaltyOfferCardLabel,
  LoyaltyOfferCardPressableText,
  LoyaltyOfferCardProgressBar,
  LoyaltyOfferCardProgressStepper,
  LoyaltyOfferCardTag,
  LoyaltyOfferCardText,
  LoyaltyOfferCardTitle,
} from './components';

export const LoyaltyOfferCard = {
  ContentContainer: LoyaltyOfferCardContentContainer,
  Container: LoyaltyOfferCardContainer,
  Image: LoyaltyOfferCardImage,
  Tag: LoyaltyOfferCardTag,
  Label: LoyaltyOfferCardLabel,
  FloatingLabel: LoyaltyOfferCardFloatingLabel,
  Title: LoyaltyOfferCardTitle,
  Text: LoyaltyOfferCardText,
  PressableText: LoyaltyOfferCardPressableText,
  Button: LoyaltyOfferCardButton,
  ProgressBar: LoyaltyOfferCardProgressBar,
  ProgressStepper: LoyaltyOfferCardProgressStepper,
  Footer: LoyaltyOfferCardFooter,
};

import React from 'react';
import { useResponsive } from '@sg/garnish';

import { type PaymentMethodCard } from '@order/graphql';
import { useSubmitPaymentMethod } from '@order/hooks';
import { StripeCreditCardForm } from '@order/integrations';
import { useLocalizationContext } from '@order/Localization';
import { useTrackEventEffect } from '@order/Telemetry';

import { Modal } from '../Modal';

export const CreditCardAddModal = (props: CreditCardAddModalProps) => {
  const { visible, isApplePayReady, onApplePay, onRequestClose } = props;
  const onSuccess = props.onSuccess ?? onRequestClose;

  useTrackEventEffect({ name: 'payment_credit.add_card', skip: !visible });

  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  const { isSubmitting, submitPaymentMethod } = useSubmitPaymentMethod({
    onSuccess,
  });

  return (
    <Modal
      fitHeight
      visible={visible}
      accessibilityLabel={t('credit-card-form.add')}
      presentationStyle="pageSheet"
      size={match(['full', 'small'])}
      onRequestClose={onRequestClose}
    >
      <Modal.Header onClose={onRequestClose}>
        {t('credit-card-form.add')}
      </Modal.Header>

      <StripeCreditCardForm
        isSubmitting={isSubmitting}
        isApplePayReady={isApplePayReady}
        handleOnSave={submitPaymentMethod}
        handleOnApplePay={onApplePay}
      />
    </Modal>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type CreditCardAddModalProps = Readonly<{
  visible: boolean;

  /**
   * Boolean flag set to true when Stripe confirms that Apple Pay can be used.
   */
  isApplePayReady?: boolean;

  onRequestClose: () => void;

  /**
   * Optional callback to differentiate closing the modal vs submitting the form.
   * If not provided, {onRequestClose} will be used instead.
   */
  onSuccess?: (paymentMethod: PaymentMethodCard) => void;

  /**
   * Optional callback to differentiate closing the modal vs adding an apple pay payment method.
   * If not provided, {onRequestClose} will be used instead.
   */
  onApplePay?: () => void;
}>;

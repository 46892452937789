import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';

import { useLastValidOrder } from '@order/Order';

import { useNavigateToMenu } from '../useNavigateToMenu';

/**
 * Navigates to the menu from the last order.
 * If there is no last order, navigate to the locations screen instead.
 */
export const useNavigateToLastOrderMenu = () => {
  const navigation = useNavigation();
  const { lastValidOrder } = useLastValidOrder();
  const navigateToMenu = useNavigateToMenu();

  // ────────────────────────────────────────────────────────────────────

  const deliveryOrderDetail = lastValidOrder?.deliveryOrderDetail;
  const lastOrderAddressId = deliveryOrderDetail?.address?.id;
  const lastOrderRestaurantSlug = lastValidOrder?.restaurant.slug;

  // ────────────────────────────────────────────────────────────────────

  const handleGoToLocations = useCallback(() => {
    navigation.navigate('MainTabs', {
      screen: 'MenuTab',
      params: {
        screen: 'Locations',
      },
    });
  }, [navigation]);

  // ────────────────────────────────────────────────────────────────────

  return useCallback(() => {
    // Navigating to last order's delivery menu.
    if (lastOrderAddressId) {
      navigateToMenu({
        orderType: 'delivery',
        deliveryOrderDetail,
      });

      return;
    }

    // Navigating to last order's pickup/outpost menu.
    if (lastOrderRestaurantSlug) {
      navigateToMenu({
        orderType: 'pickup',
        restaurantSlug: lastOrderRestaurantSlug,
      });

      return;
    }

    // Navigating to the locations screen.
    handleGoToLocations();
  }, [
    lastOrderAddressId,
    lastOrderRestaurantSlug,
    deliveryOrderDetail,
    navigateToMenu,
    handleGoToLocations,
  ]);
};

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, theme } from '@sg/garnish';

import { useTrackEventOnFocus } from '@order/Telemetry';

import { useGiftCardConfirmationHeaderButtons } from '../../hooks';
import { GiftCardConfirmationContainer } from '../GiftCardConfirmationContainer';
import { GiftCardConfirmationDeliveryInfo } from '../GiftCardConfirmationDeliveryInfo';
import { GiftCardConfirmationGiftCardSummary } from '../GiftCardConfirmationGiftCardSummary';
import { GiftCardConfirmationGiftImage } from '../GiftCardConfirmationGiftImage';
import { GiftCardConfirmationOrderNumber } from '../GiftCardConfirmationOrderNumber';
import { GiftCardConfirmationTitle } from '../GiftCardConfirmationTitle';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardDetailsScreenContent = (
  props: GiftCardConfirmationScreenReadyProps,
) => {
  const {
    orderId,
    date,
    recipientName,
    recipientEmail,
    message,
    balance,
    assetUrl,
    assetA11yLabel,
    redemptionURL,
  } = props;

  // ─── Effects ─────────────────────────────────────────────────────────

  useGiftCardConfirmationHeaderButtons();
  useTrackEventOnFocus({ name: 'gift.confirmation_success' });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <GiftCardConfirmationContainer>
      <GiftCardConfirmationTitle />

      <View style={styles.innerContainer}>
        <GiftCardConfirmationDeliveryInfo
          message={message}
          redemptionUrl={redemptionURL}
          email={recipientEmail}
          date={date}
        />

        <View style={styles.detailsContainer}>
          <View style={styles.detailsInnerContainer}>
            {assetUrl ? (
              <GiftCardConfirmationGiftImage
                url={assetUrl}
                accessibilityLabel={assetA11yLabel}
              />
            ) : null}

            <GiftCardConfirmationGiftCardSummary
              balance={balance}
              recipientName={recipientName}
            />

            {message ? (
              <BodyText sizeMatch={['16']} style={styles.message}>
                {message}
              </BodyText>
            ) : null}
          </View>

          <GiftCardConfirmationOrderNumber orderId={orderId} />
        </View>
      </View>
    </GiftCardConfirmationContainer>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  innerContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    maxWidth: 400,
  },
  detailsContainer: {
    width: '100%',
    overflow: 'hidden',
    borderColor: theme.colors.NEUTRAL_4,
    borderWidth: 1,
    borderRadius: theme.radius.large,
    marginBottom: theme.spacing['6'],
  },
  detailsInnerContainer: {
    padding: theme.spacing['4'],
  },
  message: {
    textAlign: 'center',
    color: theme.colors.CHARCOAL,
    marginTop: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardConfirmationScreenReadyProps = {
  orderId: string;
  recipientName: string;
  recipientEmail: string | undefined | null;
  balance: number; // In cents
  date?: string | null; // ISO Date
  message?: string | null;
  assetUrl: string | undefined;
  assetA11yLabel: string | undefined;
  redemptionURL: string;
};

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { CheckboxButton, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationDetailsBreadToggle = (
  props: CustomizationDetailsBreadToggleProps,
) => {
  const { isActive, isDisabled, onChange } = props;

  const { formatMessage } = useIntl();

  return (
    <View style={styles.container}>
      <View style={styles.separator} />

      <View style={styles.innerContainer}>
        <CheckboxButton
          heading={
            isActive
              ? formatMessage(messages.buttonLabelActive)
              : formatMessage(messages.buttonLabel)
          }
          isChecked={isActive}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      </View>
    </View>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  buttonLabel: {
    defaultMessage: 'Add bread',
    description: 'Customization | Dressing details | Add bread',
  },
  buttonLabelActive: {
    defaultMessage: 'Remove bread',
    description: 'Customization | Dressing details | Remove bread',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    marginHorizontal: -theme.spacing['6'],
  },
  innerContainer: {
    paddingHorizontal: theme.spacing['6'],
  },
  separator: {
    marginVertical: theme.spacing['4'],
    height: 8,
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    borderTopWidth: 1,
    borderTopColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationDetailsBreadToggleProps = {
  isActive: boolean;
  isDisabled: boolean;
  onChange: () => void;
};

import React from 'react';
import { useIntl } from 'react-intl';
import { type ViewProps } from 'react-native';
import { FocusScope, Modal } from '@sg/garnish';

/**
 * Reusable warning modal.
 */
export const BagWarningModal = (props: BagFutureDateWarningProps) => {
  const { visible, children, onClose } = props;

  const { formatMessage } = useIntl();

  const a11yLabel = formatMessage({
    defaultMessage: 'Bag warning',
    description: 'Bag | Warning | a11y label',
  });

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <Modal
      size="small"
      withSafeAreaView={false}
      visible={visible}
      accessibilityLabel={a11yLabel}
      onRequestClose={onClose}
    >
      <FocusScope enabled={visible}>{children}</FocusScope>
    </Modal>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type BagFutureDateWarningProps = {
  visible: boolean;
  children: ViewProps['children'];
  onClose: () => void;
};

import { useForm } from 'react-hook-form';

import { useCustomer } from '@order/Customer';
import type { Address } from '@order/graphql';

import type {
  AddressForm as AddressFormType,
  AddressType,
} from '../../AddressForm.types';
import { defaultAddressForm } from '../../constants';
import { getAddressTypeFromAddress } from '../../utils';

// Preinitialized address form state.
export const useAddressFormState = (address?: Partial<Address>) => {
  const { customer } = useCustomer();
  const customerAddresses = customer?.addresses ?? [];

  // ─── Address Being Edited ───────────────────────────────────────

  const typeBeingEdited = getAddressTypeFromAddress(address);

  // ─── Available Addresses ────────────────────────────────────────

  const availableAddress = getAvailableAddress(customerAddresses);
  const availableAddressType = availableAddress.type;
  const availableAddressName = availableAddress.name;

  // ─── Address Type ───────────────────────────────────────────────
  // If there is an address name different from home/work, it's custom.
  // If there isn't, the type is the first available address type.

  const addressType = address?.name ? typeBeingEdited : availableAddressType;

  // ─── Address Form ───────────────────────────────────────────────

  const form = useForm<AddressFormType>({
    defaultValues: {
      addressType,
      name: address?.name || availableAddressName,
      street: address?.street ?? defaultAddressForm.street,
      secondaryStreet:
        address?.secondaryStreet ?? defaultAddressForm.secondaryStreet,
      city: address?.city ?? defaultAddressForm.city,
      zipCode: address?.zipCode ?? defaultAddressForm.zipCode,
      state: address?.state ?? defaultAddressForm.state,
      country: address?.country ?? defaultAddressForm.country,
      googlePlaceId: address?.googlePlaceId ?? defaultAddressForm.googlePlaceId,
      latitude: address?.latitude ?? defaultAddressForm.latitude,
      longitude: address?.longitude ?? defaultAddressForm.longitude,
      notes: address?.notes ?? defaultAddressForm.notes,
      deliveryPreference:
        address?.deliveryPreference ?? defaultAddressForm.deliveryPreference,
    },
    mode: 'onChange',
  });

  return form;
};

// Returns the available address type and name based on a list of addresses.
// In priority of home > work > custom.
const getAvailableAddress = (
  addresses: ReadonlyArray<Pick<Partial<Address>, 'name'>>,
): AvailableAddress => {
  const isHomeTaken = addresses.some(({ name }) => name === 'home');

  // Home address type has the name of 'home'.
  if (!isHomeTaken) return { type: 'home', name: 'home' };

  const isWorkTaken = addresses.some(({ name }) => name === 'work');

  // Work address type has the name of 'work'.
  if (!isWorkTaken) return { type: 'work', name: 'work' };

  // Custom address type has no name and must be entered by the user.
  return { type: 'custom', name: '' };
};

type AvailableAddress = Readonly<{
  type: AddressType;
  name: string;
}>;

import { useCallback, useState } from 'react';

import { type DeliveryPreferenceType } from '@order/graphql';

export const useDeliveryPreferencesForm = (
  props: UseDeliveryPreferencesForm,
) => {
  const { preferences, onSave } = props;

  const [localType, setLocalType] = useState<string>(preferences.type ?? '');
  const [localNotes, setLocalNotes] = useState<string>(preferences.notes ?? '');

  const submitForm = useCallback(() => {
    onSave({
      type: localType as DeliveryPreferenceType,
      notes: localNotes,
    });
  }, [localNotes, localType, onSave]);

  return {
    localType,
    localNotes,
    setLocalType,
    setLocalNotes,
    submitForm,
  };
};

// ─── Types ──────────────────────────────────────────────────────────────────

type UseDeliveryPreferencesForm = Readonly<{
  preferences: { type: string; notes?: string };
  onSave: (preferences: {
    type: DeliveryPreferenceType;
    notes: string;
  }) => void;
}>;

/* istanbul ignore file */
/* eslint-disable unicorn/no-thenable */

import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { type SchemaOf } from 'yup';

import { PAYMENT_ADD } from '@order/constants';

import { giftCardCheckoutFormMessages as validationMessages } from '../../gift-card-checkout-form.messages';
import {
  type GiftCardCheckoutFormDefaultValues,
  type GiftCardCheckoutFormFieldRules,
  type GiftCardCheckoutFormState,
} from '../../gift-card-checkout-form.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns a validation schema for the gift checkout form.
 *
 * @see {@link https://github.com/jquense/yup}
 * @see {@link https://react-hook-form.com/advanced-usage#CustomHookwithResolver}
 */
export const useGiftCardCheckoutFormValidationSchema = (
  options: UseGiftCardCheckoutFormValidationSchemaOptions,
) => {
  const { rules, defaultValues } = options;

  const { min: minAmount, max: maxAmount } = rules.amount;

  const { formatMessage, formatNumber } = useIntl();

  // ─── Messages ────────────────────────────────────────────────────────

  const messages = useMemo(() => {
    const {
      senderNameRequired,
      recipientNameRequired,
      recipientEmailRequired,
      recipientEmailInvalid,
      invalidAmount,
      paymentMethodRequired,
    } = validationMessages;

    const invalidAmountMessage = formatMessage(invalidAmount, {
      // NOTE: We use split instead of `trailingZeroDisplay` and
      //      `maximumFractionDigits` since they are working on Android.
      min: formatNumber(minAmount / 100, {
        style: 'currency',
        currency: 'USD',
      }).split('.')[0],
      max: formatNumber(maxAmount / 100, {
        style: 'currency',
        currency: 'USD',
      }).split('.')[0],
    });

    return {
      amount: {
        required: invalidAmountMessage,
        invalid: invalidAmountMessage,
      },
      recipientName: {
        required: formatMessage(recipientNameRequired),
      },
      senderName: {
        required: formatMessage(senderNameRequired),
      },
      recipientEmail: {
        required: formatMessage(recipientEmailRequired),
        invalid: formatMessage(recipientEmailInvalid),
      },
      deliveryDateTime: {
        required: formatMessage(recipientEmailRequired),
        invalid: formatMessage(recipientEmailInvalid),
      },
      paymentMethodId: {
        required: formatMessage(paymentMethodRequired),
      },
    };
  }, [formatMessage, formatNumber, maxAmount, minAmount]);

  // ─────────────────────────────────────────────────────────────────────

  /**
   * A validation schema for the gift checkout form.
   */
  return useMemo<SchemaOf<GiftCardCheckoutFormState>>(() => {
    const { number, string, object, mixed } = yup;

    const {
      amount: amountMessages,
      senderName,
      recipientName,
      recipientEmail,
      paymentMethodId,
    } = messages;

    const schema: SchemaOf<GiftCardCheckoutFormState> = object({
      assetId: string().required(),
      amount: number()
        .typeError(amountMessages.invalid)
        .default(defaultValues.amount)
        .min(minAmount, amountMessages.invalid)
        .max(maxAmount, amountMessages.invalid)
        .required(amountMessages.required),
      amountVariation: mixed<GiftCardCheckoutFormState['amountVariation']>()
        .oneOf(['standard', 'custom'])
        .required(),
      recipientName: string().trim().required(recipientName.required),
      recipientEmail: string().when('deliveryMethod', {
        is: 'EMAIL',
        then: yup
          .string()
          .email(recipientEmail.invalid)
          .required(recipientEmail.required)
          .typeError(recipientEmail.invalid),
      }),
      senderName: string().trim().required(senderName.required),
      quantity: number().required(),
      message: string().trim().max(rules.message.maxChars).optional(),
      deliveryDateOption: mixed<
        GiftCardCheckoutFormState['deliveryDateOption']
      >()
        .oneOf(['NOW', 'SCHEDULE'])
        .required(),
      deliveryDateTime: string()
        .optional()
        .when('deliveryDateOption', {
          is: 'SCHEDULE',
          then: string()
            .required(recipientEmail.required)
            .typeError(recipientEmail.invalid),
        }),
      utcOffset: number().required(),
      deliveryMethod: mixed<GiftCardCheckoutFormState['deliveryMethod']>()
        // @ts-expect-error TS(2322): Type '"MESSAGE"' is not assignable to type 'Refere... Remove this comment to see the full error message
        .oneOf(['MESSAGE', 'EMAIL'])
        .required(),
      paymentMethodId: string()
        .required(paymentMethodId.required)
        .notOneOf([PAYMENT_ADD], paymentMethodId.required),
    }).required();

    return schema;
  }, [
    defaultValues.amount,
    maxAmount,
    messages,
    minAmount,
    rules.message.maxChars,
  ]);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseGiftCardCheckoutFormValidationSchemaOptions = {
  rules: GiftCardCheckoutFormFieldRules;
  defaultValues: GiftCardCheckoutFormDefaultValues;
};

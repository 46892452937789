import { useMemo } from 'react';
import type { Asset } from 'contentful';

import { useContentfulContentTypeEntry } from '@order/Contentful';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A simple hook for fetching gift card image (aka skin) source and extra data.
 */
export const useGiftCardRedemptionCardImg = (
  params: UseGiftCardRedemptionCardImgParams,
) => {
  const { assetId, pause } = params;

  // ─── Remote Data ─────────────────────────────────────────────────────

  const searchParams = useMemo(() => {
    return { 'fields.id': assetId };
  }, [assetId]);

  const { data, error, fetching } = useContentfulContentTypeEntry<GiftCard>({
    contentType: 'giftCard',
    include: 3,
    pause,
    searchParams,
  });

  const hasError = error !== undefined;
  const isFetching = fetching;

  // ─────────────────────────────────────────────────────────────────────

  return {
    data: data?.fields,
    isFetching,
    hasError,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCard = {
  id: string;
  title: string;
  cover: Asset;
};

type UseGiftCardRedemptionCardImgParams = {
  assetId: string;
  pause: boolean;
};

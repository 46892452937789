import { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNoticeBannersStackContext, useResponsive } from '@sg/garnish';

import { type DeliveryOrderDetailInput, type Product } from '@order/graphql';
import { useAddLineItemToCart, type useProductCallbacks } from '@order/hooks';
import { telemetryProductFromInput, useTelemetry } from '@order/Telemetry';

export const useAddUpsellToBag = (props: UseAddUpsellToBagProps) => {
  const { restaurantSlug } = props;

  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { track } = useTelemetry();
  const { formatMessage } = useIntl();
  const { currentBreakpoint } = useResponsive();

  const onAddToCartSuccess = useCallback(
    (params: OnAddToCartSuccessParams) => {
      const { product: productToAdd, quantity } = params;

      if (!currentBreakpoint.isXS) {
        addNoticeBanner({
          palette: 'success',
          text: formatMessage(messages.oneClickSuccess, {
            quantity,
            product_name: productToAdd.name,
          }),
        });
      }

      const selectedProduct = telemetryProductFromInput({
        ...productToAdd,
        location: restaurantSlug,
        quantity,
      });

      track('category_upsells.one_click_add_to_bag_submit', {
        selectedProduct,
      });
    },
    [
      restaurantSlug,
      currentBreakpoint.isXS,
      track,
      addNoticeBanner,
      formatMessage,
    ],
  );

  const onAddToCartFail = useCallback(
    ({ message }: OnAddToCartFailParams) => {
      track('category_upsells.one_click_add_to_bag_fail', { message });
    },
    [track],
  );

  const [upsellIdsBeingAdded, setUpsellIdsBeingAdded] = useState<string[]>([]);

  const { addLineItemToCart } = useAddLineItemToCart({
    onSuccess: onAddToCartSuccess,
    onError: onAddToCartFail,
  });

  const addUpsellToBag = useCallback(
    async (payload: AddUpsellToBagPayload) => {
      setUpsellIdsBeingAdded((ids) => [...ids, payload.product.id ?? '']);

      await addLineItemToCart({
        product: payload.product,
        deliveryDetails: payload.deliveryDetails,
        quantity: 1,
      });

      setUpsellIdsBeingAdded([]);
    },
    [addLineItemToCart],
  );

  return {
    upsellIdsBeingAdded,
    addUpsellToBag,
  };
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  oneClickSuccess: {
    defaultMessage: '{quantity}x {product_name} added to your bag!',
    description: 'Upsell Product | One-click add to bag success message',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type UseAddUpsellToBagProps = {
  restaurantSlug: string;
};

type AddUpsellToBagPayload = {
  product: Partial<Product>;
  deliveryDetails?: DeliveryOrderDetailInput;
};

type OnAddToCartSuccessParams = Parameters<
  NonNullable<Parameters<typeof useProductCallbacks>[0]['onSuccess']>
>[0];

type OnAddToCartFailParams = Parameters<
  NonNullable<Parameters<typeof useProductCallbacks>[0]['onError']>
>[0];

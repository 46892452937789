import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useOrganizationLinksAccountMenuContentfulData } from '@order/hooks';

import { OrganizationLink } from '../../../../OrganizationLink';
import { AccountMenuIconRow } from '../AccountMenuIconRow';

export const AccountSecondaryMenu = () => {
  const { links } = useOrganizationLinksAccountMenuContentfulData();

  return (
    <View style={styles.accountSecondaryMenuContainer}>
      {links.map((link) => (
        <OrganizationLink key={link.label} link={link} />
      ))}

      <AccountMenuIconRow />
    </View>
  );
};

const styles = StyleSheet.create({
  accountSecondaryMenuContainer: {
    marginVertical: theme.spacing['4'],
    gap: theme.spacing['4'],
  },
});

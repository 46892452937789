import React from 'react';
import { Button } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const DeliveryPreferencesFormSubmitButton = (
  props: SubmitButtonProps,
) => {
  const { isSubmitting, submitForm } = props;
  const { t } = useLocalizationContext();

  return (
    <Button
      size="large"
      width="100%"
      onPress={submitForm}
      isLoading={isSubmitting}
      testID="delivery.preferences.save-button"
      accessibilityLabel={t('delivery.preferences.save.a11y')}
      accessibilityHint={t('delivery.preferences.save.a11y')}
      accessibilityRole="button"
    >
      {t('delivery.preferences.save')}
    </Button>
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────

type SubmitButtonProps = Readonly<{
  isSubmitting?: boolean;
  submitForm: () => void;
}>;

import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import { IconLink, theme, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const LeftIcon = ({ onPress }: Readonly<{ onPress: () => void }>) => {
  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  return (
    <IconLink
      testID="location.search-bar.back-button"
      name="IconArrowLeft"
      style={
        Platform.OS === 'web' ? match([styles.iconXS, styles.icon]) : undefined
      }
      accessibilityLabel={t('general.back')}
      accessibilityHint={t('home.navigation')}
      onPress={onPress}
    />
  );
};

const styles = StyleSheet.create({
  icon: {
    marginRight: theme.spacing['4'],
  },
  iconXS: {
    marginLeft: theme.spacing['4'],
  },
});

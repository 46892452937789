import React, { createContext, useRef, useState } from 'react';

import { noop } from '../../utils/noop';
import type {
  BoundScrollViewProps,
  ScrollContextProps,
  ScrollEvent,
  ScrollListener,
  ScrollListeners,
  ScrollReference,
} from './BoundScrollView.types';

export const BoundScrollContext = createContext<ScrollContextProps>({
  scrollReference: null,
  addScrollReference: noop,
  clearScrollReference: noop,

  listeners: { current: [] },
  onScroll: noop,
  addScrollListener: noop,
  removeScrollListener: noop,
});

export const BoundScrollProvider = (
  props: BoundScrollViewProps,
): React.ReactElement => {
  const { children } = props;

  // Scroll Reference
  const [scrollReference, setScrollReference] = useState<ScrollReference>(null);
  const addScrollReference = React.useCallback(
    (reference: ScrollReference) => {
      setScrollReference(reference);
    },
    [setScrollReference],
  );
  const clearScrollReference = React.useCallback(() => {
    setScrollReference(null);
  }, [setScrollReference]);

  // Scroll Behavior
  const listeners = useRef<ScrollListeners>([]);
  const onScroll = React.useCallback(
    async (event: ScrollEvent) => {
      await Promise.all(
        listeners.current.map(async (listener) => listener.onScroll(event)),
      );
    },
    [listeners],
  );
  const addScrollListener = React.useCallback(
    (scrollListener: ScrollListener) => {
      const hasListener = listeners.current.includes(scrollListener);

      if (hasListener) return;
      // eslint-disable-next-line functional/immutable-data
      listeners.current = [...listeners.current, scrollListener];
    },
    [listeners],
  );
  const removeScrollListener = React.useCallback(
    (scrollListener: ScrollListener) => {
      const indexToRemove = listeners.current.findIndex(
        (listener) => listener.source === scrollListener.source,
      );

      if (indexToRemove === -1) return;
      // eslint-disable-next-line functional/immutable-data
      listeners.current = listeners.current.filter(
        (_, currentIndex) => currentIndex !== indexToRemove,
      );
    },
    [listeners],
  );

  return (
    <BoundScrollContext.Provider
      value={React.useMemo(
        () => ({
          scrollReference,
          addScrollReference,
          clearScrollReference,
          listeners,
          onScroll,
          addScrollListener,
          removeScrollListener,
        }),
        [
          scrollReference,
          addScrollReference,
          clearScrollReference,
          listeners,
          onScroll,
          addScrollListener,
          removeScrollListener,
        ],
      )}
    >
      {children}
    </BoundScrollContext.Provider>
  );
};

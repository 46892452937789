import { sweetpassLandingPageDefaultCampaignContentfulFallback } from './sweetpassLandingPageDefaultCampaignContentfulFallback';
import { sweetpassLandingPageSignedOutContentfulFallback } from './sweetpassLandingPageSignedOutContentfulFallback';
import { sweetpassLandingPageSweetpassContentfulFallback } from './sweetpassLandingPageSweetpassContentfulFallback';
import { sweetpassLandingPageSweetpassPlusContentfulFallback } from './sweetpassLandingPageSweetpassPlusContentfulFallback';

/**
 * Returns the corresponding fallback depending on the contentful tag.
 */
export const sweetpassLandingPageContentfulFallback = {
  'join-sweetpass': sweetpassLandingPageSweetpassContentfulFallback,
  'upgrade-to-sweetpass-plus':
    sweetpassLandingPageSweetpassPlusContentfulFallback,
  'campaign-default': sweetpassLandingPageDefaultCampaignContentfulFallback,
  sweetpassSignedOutHomepage: sweetpassLandingPageSignedOutContentfulFallback,
} as const;

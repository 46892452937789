import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { Footer } from '@expo/html-elements';
import { BodyText, TextLinkifyTags, theme } from '@sg/garnish';

export const HowItWorksFooter = () => {
  const { formatMessage } = useIntl();
  const links = useMemo(
    () => [
      {
        tag: 'terms',
        description: formatMessage(messages.termsTag),
        href: 'https://www.sweetgreen.com/sweetpass-program-agreement',
        underline: true,
      },
    ],
    [formatMessage],
  );

  return (
    <Footer style={styles.footer}>
      <BodyText style={styles.text} sizeMatch={['12']}>
        <TextLinkifyTags palette="kale" sizeMatch={['12']} links={links}>
          {formatMessage(messages.terms, { terms: '{terms}' })}
        </TextLinkifyTags>
      </BodyText>
    </Footer>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  termsTag: {
    defaultMessage: 'Terms',
    description: 'Loyalty Home | Footer | subtitle',
  },
  terms: {
    defaultMessage: '*See full {terms} for more details',
    description: 'Loyalty Home | Footer | subtitle',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  footer: {
    padding: theme.spacing[4],
  },
  text: {
    textAlign: 'center',
  },
});

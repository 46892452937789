import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, TextField, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const DeliveryPreferencesFormNotesField = (
  props: DeliveryPreferencesFormNotesFieldProps,
) => {
  const { localNotes, setLocalNotes } = props;
  const { t } = useLocalizationContext();

  return (
    <View style={styles.container}>
      <View style={[styles.row, styles.sectionLabel]}>
        <BodyText sizeMatch={['12']}>
          {t('delivery.preferences.note-label')}
        </BodyText>

        <BodyText
          sizeMatch={['12']}
          style={localNotes.length === 128 && styles.cautionText}
        >
          {localNotes.length}/{128}
        </BodyText>
      </View>

      <TextField
        testID="delivery.preferences.notes"
        style={styles.inputField}
        value={localNotes}
        multiline
        maxLength={128}
        placeholder={t('delivery.preferences.note-placeholder')}
        accessibilityLabel={t('delivery.preferences.note-hint')}
        accessibilityHint={t('delivery.preferences.note-hint')}
        onChangeText={setLocalNotes}
      />
    </View>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type DeliveryPreferencesFormNotesFieldProps = Readonly<{
  localNotes: string;
  setLocalNotes: (notes: string) => void;
}>;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  sectionLabel: {
    marginTop: theme.spacing['10'],
    marginBottom: theme.spacing['2'],
  },
  inputField: {
    height: 80,
  },
  cautionText: {
    color: theme.colors.CAUTION,
  },
});

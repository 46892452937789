import React, { type ComponentProps, useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  getIosModalPresentationConfig,
  NoticeBannersStack,
  NoticeBannersStackProvider,
  useResponsive,
} from '@sg/garnish';
import { type Ingredient } from '@sg/graphql-schema';

import { Modal } from '@order/components';
import { OrderAgain } from '@order/features/orders';

import { reorderConflictModalMessages as messages } from './ReorderConflictModal.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const ReorderConflictModal = (props: ReorderConflictModalProps) => {
  const {
    visible,
    lineItem,
    unavailableIngredients,
    targetLocation,
    isReordering,
    navigateToProductDetails,
    addToBagAnyway,
    onRequestClose,
  } = props;

  const { match } = useResponsive();
  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const { id: lineItemId, product, customName } = lineItem;

  const productName = product.name;
  const productAssetUrl = product?.asset?.url;
  const productIngredients = product?.ingredients ?? [];

  const { restaurantSlug, restaurantName, deliveryOrderDetails } =
    targetLocation;
  const deliveryAddressId = deliveryOrderDetails?.address?.id;

  // ─── Derived Data ────────────────────────────────────────────────────

  const isTargetLocationDelivery = deliveryAddressId !== undefined;
  const targetLocationName = restaurantName ?? 'sweetgreen';

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleNavigateToProductDetails = useCallback(() => {
    if (!restaurantSlug) return;

    navigateToProductDetails({
      product,
      lineItemId,
      restaurantSlug,
      addressId: deliveryAddressId ?? undefined,
      customName: customName ?? undefined,
    });
  }, [
    customName,
    deliveryAddressId,
    lineItemId,
    navigateToProductDetails,
    product,
    restaurantSlug,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <NoticeBannersStackProvider>
      <NoticeBannersStack />

      <Modal
        visible={visible}
        fitHeight
        accessibilityLabel={formatMessage(messages.modalA11yLabel)}
        onRequestClose={onRequestClose}
        maxWidth={match([undefined, 400])}
        maxHeight={match([undefined, '90%'])}
        {...getIosModalPresentationConfig()}
      >
        <OrderAgain.ConflictReview.Container>
          <OrderAgain.ConflictReview.CloseButton onPress={onRequestClose} />

          <OrderAgain.ConflictReview.Text
            isDeliveryOrder={isTargetLocationDelivery}
            unavailableIngredients={unavailableIngredients}
            locationName={targetLocationName}
          />

          {productAssetUrl ? (
            <OrderAgain.ConflictReview.Image
              imageUrl={productAssetUrl}
              alt={productName}
            />
          ) : null}

          <OrderAgain.ConflictReview.LineItemDetails lineItem={lineItem} />

          <OrderAgain.ConflictReview.CTAs
            productIngredients={productIngredients}
            unavailableIngredients={unavailableIngredients}
            isReordering={isReordering}
            addToBag={addToBagAnyway}
            customize={handleNavigateToProductDetails}
          />
        </OrderAgain.ConflictReview.Container>
      </Modal>
    </NoticeBannersStackProvider>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ReorderConflictModalProps = {
  visible: boolean;
  lineItem: PartialLineItem;
  unavailableIngredients: readonly Pick<Ingredient, 'id' | 'name' | 'kind'>[];
  targetLocation: {
    restaurantId?: string;
    restaurantName?: string;
    restaurantSlug?: string;
    deliveryOrderDetails?: { address: { id?: string | null } | null } | null;
  };
  isReordering: boolean;

  onRequestClose: () => void;
  navigateToProductDetails: (params: {
    lineItemId: string;
    product: { slug?: string };
    restaurantSlug: string;
    addressId?: string;
    customName?: string;
  }) => void;
  addToBagAnyway: () => void;
};

type PartialLineItem = ComponentProps<
  typeof OrderAgain.ConflictReview.LineItemDetails
>['lineItem'];

/* eslint-disable functional/immutable-data */

import {
  ApplePayCheckoutButton,
  ApplePayHostedFrame,
  ApplePaySetAsDefaultButton,
} from './components';

export const ApplePay = () => {
  return null;
};

ApplePay.Checkout = ApplePayCheckoutButton;
ApplePay.HostedFrame = ApplePayHostedFrame;
ApplePay.SetAsDefaultButton = ApplePaySetAsDefaultButton;

import type { PaymentSubscription } from '@order/graphql';
import { PaymentSubscriptionStatus } from '@order/graphql';

/**
 * If more than one subscription was returned, prefer the active one.
 * Otherwise, return any of the non pending ones.
 */
export function getPreferredSubscription(
  subscriptions: ReadonlyArray<Partial<PaymentSubscription>> | undefined,
) {
  return (
    subscriptions?.find(
      ({ status }) => status === PaymentSubscriptionStatus.Active,
    ) ??
    subscriptions?.find(
      ({ status }) => status !== PaymentSubscriptionStatus.Pending,
    )
  );
}

export const reorder = {
  'reorder.confirm-location': 'Confirm {channel} location',
  'reorder.more-locations': 'More locations',
  'reorder.continue-to-bag': 'Continue to bag',
  'reorder.continue': 'Continue',
  'reorder.conflicts.headline': 'Oops, almost there.',
  'reorder.conflicts.reordering-from': 'Reordering from ',
  'reorder.conflicts.reordering-to': 'Reordering to ',
  'reorder.conflicts.mixing-unsupported':
    'Heads up — your dressings will come on the side.',
  'reorder.conflicts.product-unavailable': 'Product unavailable',
  'reorder.conflicts.ingredients-unavailable': '{ingredients} unavailable',
  'reorder.conflicts.notice':
    'We have to make some changes to your order. Unavailable items will not be added to your bag.',
  'reorder.conflicts.not-on-menu.headline': "Oops, that's not on the menu",
  'reorder.conflicts.not-on-menu.notice':
    "Unfortunately it looks like this order can't be made at your location. Sorry about that.",
  'reorder.conflicts.not-on-menu.ordering-from': 'Ordering from',
  'reorder.conflicts.not-on-menu.ordering-to': 'Ordering to',
  'reorder.change-location': 'Change location',
  'reorder.view-menu': 'View menu',
  'reorder.clear-warning':
    'Choosing another restaurant will clear your current bag.',
  'reorder.active-bag-warning.headline': 'You already have an active order',
  'reorder.active-bag-warning.message':
    'Would you like to continue with a new order or return to your current bag?',
  'reorder.active-bag-warning.return-to-bag': 'Return to bag',
  'reorder.active-bag-warning.new-order': 'New order',
};

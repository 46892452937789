import { logger } from '../logger';

export const SessionReplay = {
  async enable() {
    logger.debug('SessionReplay is not supported on web');
  },
};

export enum SessionReplayPrivacy {
  MASK = 'MASK',
  ALLOW = 'ALLOW',
  MASK_USER_INPUT = 'MASK_USER_INPUT',
}

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { LoadingPlaceholder, useResponsive } from '@sg/garnish';

import {
  REORDER_RAIL_CARD_PLACEHOLDER_SIZE_MD,
  REORDER_RAIL_CARD_PLACEHOLDER_SIZE_XS,
  REORDER_RAIL_CARD_SIZE_LG,
  REORDER_RAIL_CARD_SIZE_XS,
} from './constants';

export const ReorderRailCardPlaceholder = () => {
  const { match } = useResponsive();

  const wrapperStyle = match([styles.wrapperXs, styles.wrapperLg]);
  const placeholderHeight = match([
    REORDER_RAIL_CARD_PLACEHOLDER_SIZE_XS,
    REORDER_RAIL_CARD_PLACEHOLDER_SIZE_MD,
  ]);

  return (
    <View style={wrapperStyle}>
      <LoadingPlaceholder palette="lightGreen" rowHeight={placeholderHeight} />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapperXs: {
    width: REORDER_RAIL_CARD_SIZE_XS,
  },

  wrapperLg: {
    width: REORDER_RAIL_CARD_SIZE_LG,
  },
});

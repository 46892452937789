import { Platform } from 'react-native';

import { useFeatureFlag } from '@order/LaunchDarkly';

// ─────────────────────────────────────────────────────────────────────────────

export const useIsForceUpdateModeEnabled = () => {
  const isForcedAppUpdatedEnabled = useFeatureFlag(
    'permanent-forced-app-update-enabled',
    {
      listenForChanges: false,
    },
  );
  const isApplicablePlatformOS =
    Platform.OS === 'ios' || Platform.OS === 'android';

  return isApplicablePlatformOS && isForcedAppUpdatedEnabled;
};

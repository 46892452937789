import type { Address } from '@order/graphql';

/**
 * An address can be a duplicate if its location is the same as an existing one.
 * This is checked by:
 * - Google Place ID;
 * - Street + Secondary Street + Zip Code;
 * This however does not apply if it's the same address ID, as that's not a duplicate.
 * It also does not apply if they both have the same name, as that's a data issue and not a duplicate.
 */
export function getAddressDuplicateByLocation(
  props: AddressDuplicateByLocationProps,
) {
  const { addressToCheck, addresses } = props;

  return addresses.find((address) => {
    const hasSameId = address.id === addressToCheck?.id;

    // Same Id means it's editing the current address, not duplicating it.
    if (hasSameId) return false;

    const hasSameName =
      address.name &&
      addressToCheck?.name &&
      address.name === addressToCheck.name;

    // Same Name can happen due to previous versions' data issues.
    if (hasSameName) return false;

    const hasSamePlaceId =
      address.googlePlaceId === addressToCheck?.googlePlaceId;

    // Same Place Id means it's the same place (and a duplicate).
    if (hasSamePlaceId) return true;

    const hasSameStreet = address.street === addressToCheck?.street;
    const hasSame2ndStreet =
      address.secondaryStreet === addressToCheck?.secondaryStreet;
    const hasSameZipCode = address.zipCode === addressToCheck?.zipCode;

    // Fallback in case google assigns a different place id for the same address.
    if (hasSameStreet && hasSame2ndStreet && hasSameZipCode) return true;

    // Addresses are not related.
    return false;
  });
}

type AddressDuplicateByLocationProps = Readonly<{
  addresses: ReadonlyArray<Partial<Address>>;
  addressToCheck?: Partial<Address>;
}>;

import React, { type ComponentProps, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { Loyalty } from '@order/features/loyalty';
import { useTelemetry } from '@order/Telemetry';

import { useLoyaltyOfferOptInMutation } from '../../graphql/LoyaltyOffers.generated';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCard = (props: LoyaltyOfferCardProps) => {
  const { offer, rounded = false, layout, onNavigate } = props;

  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const { track } = useTelemetry();

  // ─── Remote Data ─────────────────────────────────────────────────────

  const [{ fetching }, optIn] = useLoyaltyOfferOptInMutation();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToMenu = useCallback(() => {
    track('loyalty.offer.modal.order.cta_tapped', { offerId: offer.id });

    onNavigate?.();
    navigate('MainTabs', {
      screen: 'MenuTab',
      params: { screen: 'MenuWithoutSlug' },
    });
  }, [onNavigate, navigate, offer.id, track]);

  const showErrorBanner = useCallback(() => {
    const text = formatMessage(messages.errorBannerText);
    const shouldPreventDuplicates = true;

    addNoticeBanner(
      { id: ERROR_NOTICE_BANNER_ID, text, palette: 'caution' },
      shouldPreventDuplicates,
    );
  }, [addNoticeBanner, formatMessage]);

  const optInOffer = useCallback(async () => {
    track('loyalty.offer.modal.start.cta_tapped', { offerId: offer.id });

    const { data, error } = await optIn({ challengeId: offer.id });

    const responseTypename = data?.challengeOptInV2?.__typename;
    const hasFailedToOptedIn = responseTypename === 'LoyaltyOptInFailed';

    if (hasFailedToOptedIn || error) {
      showErrorBanner();
    }
  }, [offer.id, optIn, showErrorBanner, track]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Loyalty.OfferCard
      offer={offer}
      isLoading={fetching}
      layout={layout}
      rounded={rounded}
      onStartButtonPress={optInOffer}
      onOrderButtonPress={navigateToMenu}
    />
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  errorBannerText: {
    defaultMessage: 'Failed to opt in to offer. Please try again later.',
    description: 'Loyalty | Offers | Opt in error banner text',
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const ERROR_NOTICE_BANNER_ID = 'LOYALTY_OFFER_OPT_IN_ERROR_BANNER';

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardProps = {
  onNavigate?: () => void;
} & Pick<
  ComponentProps<typeof Loyalty.OfferCard>,
  'offer' | 'rounded' | 'layout'
>;

import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Button, HStack, theme, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { useOrderStatusNavigation } from './OrderStatus.navigation';

// ────────────────────────────────────────────────────────────────────────────────

export const OrderStatusCTAs = (props: OrderStatusCTAsProps) => {
  const { orderId = '', orderTotal = 0, isPendingFeedback, onReorder } = props;

  const { t, formatPrice } = useLocalizationContext();
  const { match } = useResponsive();

  const { navigateToRateOrderScreen } = useOrderStatusNavigation();
  const orderTotalPrice = formatPrice(orderTotal, 'USD');

  // ─── Helpers ─────────────────────────────────────────────────────

  const handleReorder = useCallback(() => {
    if (!orderId) return;

    navigateToRateOrderScreen(orderId);
  }, [orderId, navigateToRateOrderScreen]);

  // ─── Layout ──────────────────────────────────────────────────────

  const stackStyle = match([undefined, styles.stackSM]);
  const buttonsStackItemsPerRow = match([isPendingFeedback ? 2 : 1, undefined]);
  const buttonsSize = match(['large', 'medium'] as const);

  // ─────────────────────────────────────────────────────────────────

  return (
    <SafeAreaView edges={['bottom']}>
      <HStack
        itemsPerRow={buttonsStackItemsPerRow}
        gap={theme.spacing['4']}
        style={stackStyle}
      >
        {isPendingFeedback ? (
          <Button
            testID="rate-order-button"
            palette="secondary"
            size={buttonsSize}
            accessibilityLabel={t('order-status-info.rate-order')}
            accessibilityHint={t('order-status-info.rate-order')}
            onPress={handleReorder}
          >
            {t('order-status-info.rate-order')}
          </Button>
        ) : null}

        <Button
          testID="reorder-button"
          palette="primary"
          size={buttonsSize}
          accessibilityLabel={t('order-status-info.reorder')}
          accessibilityHint={t('order-status-info.reorder')}
          onPress={onReorder}
        >
          {isPendingFeedback
            ? t('order-status-info.reorder')
            : t('order-status-info.reorder-total', { total: orderTotalPrice })}
        </Button>
      </HStack>
    </SafeAreaView>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  stackSM: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────────

type OrderStatusCTAsProps = Readonly<{
  orderId?: string;
  orderTotal?: number;
  isPendingFeedback: boolean;
  onReorder: () => void;
}>;

/* istanbul ignore file */

import { useCallback } from 'react';

import { useCart } from '@order/hooks';

import { useConflictReview } from '../../../../screens/ReorderingScreen';
import type { LocationSearchMachineContext } from '../../machine';
import { useNavigateToMenuFromLocation } from '../useNavigateToMenuFromLocation';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Provides navigation actions and helpers for Location Search.
 */
export const useLocationSearchNavigation = () => {
  const { cart, isCartEmpty } = useCart();
  const conflictReview = useConflictReview();
  const navigateToMenu = useNavigateToMenuFromLocation();

  // ─── Helpers ─────────────────────────────────────────────────────────

  /**
   * Initiates a navigation event for pickup and outpost locations
   * based on the active location context.
   */
  const navigateToLocation = useCallback(
    (context: LocationSearchMachineContext) => {
      const { locationSearchType: orderType, activeLocation } = context;
      const cartRestaurantSlug = cart?.restaurant?.slug;

      if (!activeLocation) return;

      const { restaurantName, restaurantId, restaurantSlug, isOutpost } =
        activeLocation;

      // ─── Possible Reorder ────────────────────────────────────────────────

      const shouldStartReorder =
        !isCartEmpty && cartRestaurantSlug !== restaurantSlug;

      // if your bag belongs to a different restaurant, start reorder flow
      if (shouldStartReorder) {
        void conflictReview({
          orderId: cart?.id,
          orderType: isOutpost ? 'outpost' : 'pickup',
          restaurantId,
          restaurantName,
          restaurantSlug,
          finishOnMenu: true,
        });

        return;
      }

      // ─────────────────────────────────────────────────────────────────────

      navigateToMenu({
        // @ts-expect-error TS(2322): Type 'LocationsSearchType | undefined' is not assi... Remove this comment to see the full error message
        orderType,
        restaurantId,
        restaurantName,
        restaurantSlug,
      });
    },
    [
      cart?.id,
      cart?.restaurant?.slug,
      conflictReview,
      isCartEmpty,
      navigateToMenu,
    ],
  );

  /**
   * Initiates a navigation event for delivery location
   * based on the active location context.
   */
  const navigateToDeliveryLocation = useCallback(
    (context: LocationSearchMachineContext) => {
      const { deliveryLocation } = context;

      if (!deliveryLocation) return;

      const {
        addressId,
        restaurantId,
        restaurantName,
        restaurantSlug,
        restaurantDeliveryFee = 0,
        name: deliveryAddressName,
        deliveryVendorName,
        deliveryVendorRestaurantId,
      } = deliveryLocation;

      navigateToMenu({
        orderType: 'delivery',
        restaurantId,
        restaurantName,
        restaurantSlug,
        deliveryAddressName,
        deliveryOrderDetail: {
          addressId,
          tip: 0,
          deliveryFee: restaurantDeliveryFee,
          vendor: deliveryVendorName,
          vendorRestaurantId: deliveryVendorRestaurantId,
        },
      });
    },
    [navigateToMenu],
  );

  return {
    navigateToLocation,
    navigateToDeliveryLocation,
  };
};

/* istanbul ignore file */

import { useFluidSize } from '@sg/garnish';

export const useHomeCardStyles = () => {
  const titleStyle = useFluidTitleSize();
  const subtitleStyle = useFluidSubtitleSize();

  return {
    titleStyle,
    subtitleStyle,
  };
};

const useFluidTitleSize = () => {
  const fluid = useFluidSize({ min: 375, max: 1440 });

  return {
    fontSize: fluid(16, 24),
    lineHeight: fluid(22, 28),
    letterSpacing: fluid(-0.4, 0),
  };
};

const useFluidSubtitleSize = () => {
  const fluid = useFluidSize({ min: 375, max: 1440 });

  return {
    fontSize: fluid(14, 18),
    lineHeight: fluid(18, 24),
    letterSpacing: fluid(-0.2, -0.1),
  };
};

import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';

import { JoinForm } from '../../../JoinScreen/subcomponents';

// ─────────────────────────────────────────────────────────────────────────────

export const JoinOrSignInForm = (props: JoinOrSignInFormProps) => {
  const { email, isLoading, onSubmit, onSignInUsingDifferentAccount } = props;

  return (
    <View style={styles.outerContainer}>
      <JoinForm
        predefinedEmail={email}
        onPressJoin={onSubmit}
        onPressUseDifferentAccount={onSignInUsingDifferentAccount}
        isLoading={isLoading}
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type JoinOrSignInFormProps = {
  isLoading: boolean;
  email: string | undefined;
  onSubmit: ComponentProps<typeof JoinForm>['onPressJoin'];
  onSignInUsingDifferentAccount?: () => void;
};

import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useClient } from 'urql';
import { useNoticeBannersStackContext } from '@sg/garnish';
import { type DeliveryOrderDetail } from '@sg/graphql-schema';

import {
  type ApplyRewardParams,
  type OrderingContext,
  type RemoveRewardParams,
} from '@order/features/ordering';
import { type Order, type PartialLineItem } from '@order/graphql';
import { useTelemetry } from '@order/Telemetry';

import {
  ApplyBagRewardDocument,
  type ApplyBagRewardMutation,
  type ApplyBagRewardMutationVariables,
  BagRewardsDocument,
  type BagRewardsQuery,
  RemoveBagRewardDocument,
  type RemoveBagRewardMutation,
  type RemoveBagRewardMutationVariables,
} from '../../GraphQL/BagRewards.generated';
import { prepareCart } from '../../utils';
import { useApplyRewardErrorMessage } from './useApplyRewardErrorMessage';

// ────────────────────────────────────────────────────────────────────────────

/**
 * A hook for fetching, apply and removing bag rewards.
 */
export const useRewards = () => {
  const client = useClient();
  const { formatMessage } = useIntl();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { track } = useTelemetry();
  const getApplyRewardErrorMessage = useApplyRewardErrorMessage();

  // ─── Queries ──────────────────────────────────────────────────────────────

  const fetchRewardsV1 = useCallback(async () => {
    const queryRewards = client.query<BagRewardsQuery>;

    const response = await queryRewards(
      BagRewardsDocument,
      {},
      { requestPolicy: 'network-only' },
    ).toPromise();

    if (response.error || response.data?.rewards === undefined) {
      addNoticeBanner({
        text: formatMessage(messages.fetchRewardsError),
        palette: 'caution',
      });
    }

    const rewards = response.data?.rewards ?? [];

    return { rewards };
  }, [client.query, formatMessage, addNoticeBanner]);

  // ─── Mutations ────────────────────────────────────────────────────────────

  const applyReward = useCallback(
    async (
      _context: OrderingContext<PartialLineItem, DeliveryOrderDetail>,
      { params }: ApplyRewardParams,
    ) => {
      const mutation = client.mutation<
        ApplyBagRewardMutation,
        ApplyBagRewardMutationVariables
      >;

      const response = await mutation(
        ApplyBagRewardDocument,
        { input: params },
        { requestPolicy: 'network-only' },
      ).toPromise();

      const result = response.data?.applyReward;

      if (result?.__typename === 'ApplyRewardSuccess') {
        return { cart: prepareCart(result.order as Partial<Order>) };
      }

      const error = getApplyRewardErrorMessage(response);

      track('bag.apply-reward.failed', { userError: error });

      return { error };
    },
    [client.mutation, getApplyRewardErrorMessage, track],
  );

  const removeReward = useCallback(
    async (
      _context: OrderingContext<PartialLineItem, DeliveryOrderDetail>,
      { params }: RemoveRewardParams,
    ) => {
      const mutation = client.mutation<
        RemoveBagRewardMutation,
        RemoveBagRewardMutationVariables
      >;

      const response = await mutation(
        RemoveBagRewardDocument,
        { input: { orderId: params.orderId } },
        { requestPolicy: 'network-only' },
      ).toPromise();

      const result = response.data?.removeReward;

      if (result?.__typename === 'RemoveRewardSuccess') {
        return { cart: prepareCart(result.order as Partial<Order>) };
      }

      return { error: formatMessage(messages.removeRewardError) };
    },
    [client.mutation, formatMessage],
  );

  // ──────────────────────────────────────────────────────────────────────────

  return { fetchRewardsV1, applyReward, removeReward };
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  fetchRewardsError: {
    defaultMessage:
      'There was an issue loading your rewards. Please refresh the app or try again later.',
    description: 'Bag | Rewards | Fetch rewards error',
  },
  removeRewardError: {
    defaultMessage: 'Something went wrong removing your rewards.',
    description: 'Bag | Rewards | Remove reward error',
  },
});

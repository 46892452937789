import { useCallback } from 'react';
import type { NavigationProp } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';

import type { PartialProduct } from '@order/graphql';
import { telemetryProductFromInput, useTelemetry } from '@order/Telemetry';

import type { AppStackParamList } from '../../navigation/AppNavigation.props';
import { useDeliveryOrderInFlight } from '../useDeliveryOrderInFlight';

// ────────────────────────────────────────────────────────────────────────────────

export const useNavigateToProductDetails = (
  props: UseNavigateToProductDetailsProps,
) => {
  const { restaurantSlug, deliveryAddressId } = props;

  const { track } = useTelemetry();
  const { navigate } = useNavigation<NavigationProp<AppStackParamList>>();
  const { inFlightDeliveryOrderId, navigateToDeliveryOrderInFlightScreen } =
    useDeliveryOrderInFlight({ requestPolicy: 'cache-only' });

  // ─── HELPERS ─────────────────────────────────────────────────────

  const sendTelemetryEvent = useCallback(
    (product: PartialProduct) => {
      track('pdp.view', {
        entryPoint: 'menu',
        selectedProduct: telemetryProductFromInput({
          ...product,
          location: restaurantSlug,
        }),
      });
    },
    [restaurantSlug, track],
  );

  const navigateToProductDetails = useCallback(
    (productSlug: string) => {
      if (!restaurantSlug) return;

      navigate('Modal', {
        screen: 'ProductDetails',
        params: { productSlug, restaurantSlug },
      });
    },
    [navigate, restaurantSlug],
  );

  const navigateToDeliveryProductDetails = useCallback(
    (productSlug: string) => {
      if (!deliveryAddressId) return;

      // Block delivery pdp navigation when there is an in-flight delivery order.
      if (inFlightDeliveryOrderId) {
        navigateToDeliveryOrderInFlightScreen();

        return;
      }

      navigate('Modal', {
        screen: 'DeliveryProductDetails',
        params: {
          productSlug,
          addressId: deliveryAddressId,
        },
      });
    },
    [
      deliveryAddressId,
      inFlightDeliveryOrderId,
      navigate,
      navigateToDeliveryOrderInFlightScreen,
    ],
  );

  // ─────────────────────────────────────────────────────────────────

  return useCallback(
    (product: PartialProduct) => {
      const { slug: productSlug = '' } = product;

      sendTelemetryEvent(product);

      if (deliveryAddressId) navigateToDeliveryProductDetails(productSlug);
      else navigateToProductDetails(productSlug);
    },
    [
      sendTelemetryEvent,
      deliveryAddressId,
      navigateToDeliveryProductDetails,
      navigateToProductDetails,
    ],
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type UseNavigateToProductDetailsProps = Readonly<{
  restaurantSlug?: string;
  deliveryAddressId?: string;
}>;

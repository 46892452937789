import type { ReactElement } from 'react';
import { cloneElement } from 'react';

import type { LayoutMeasure, TabNavItemProps } from '../TabNav.types';

export type TabNavClonedElement = ReactElement<
  TabNavItemProps | Readonly<{ ref: React.Ref<unknown> }>
>;

type GetTransformedChildren = (
  p: Readonly<{
    child: TabNavClonedElement;
    index: number;
    value: number;
    onChange: (index: number, child: TabNavClonedElement) => void;
    onChangeMeasures?: (m: LayoutMeasure, i: number) => void;
  }>,
) => TabNavClonedElement;

export const getTransformedChildren: GetTransformedChildren = ({
  child,
  index,
  value,
  onChangeMeasures,
  ...props
}) =>
  cloneElement(child, {
    index,
    onChangeMeasures,
    onPress() {
      props.onChange(index, child);
    },
    active: index === value,
    ...props,
  });

import { useCallback } from 'react';

import { LastInteractedStoreModel } from './machine/LastInteractedStore.model';
import { useLastInteractedStoreMachine } from './useLastInteractedStoreMachine';

/**
 * Returns a helper that resets the last interacted menu store
 */
export const useResetLastInteractedMenuStore = () => {
  const [, send] = useLastInteractedStoreMachine();

  return useCallback(() => {
    send(RESET_LAST_INTERACTED_MENU_STORE());
  }, [send]);
};

// ────────────────────────────────────────────────────────────────────────────────

const { RESET_LAST_INTERACTED_MENU_STORE } = LastInteractedStoreModel.events;

// @ts-expect-error TS(2614): Module '"react-native-svg"' has no exported member... Remove this comment to see the full error message

import type { Styles } from 'react-native-svg';
import { COLORS } from '@garnish/constants';

import { type ChipProps, type ChipState } from '../Chip.types';

// ─────────────────────────────────────────────────────────────────────────────

export const boxStyles: Record<ChipPalette, Record<ChipState, Styles>> = {
  primary: {
    default: {
      backgroundColor: COLORS.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
      borderColor: COLORS.OPACITY.TRANSPARENT,
    },
    hovered: {
      backgroundColor: COLORS.OPACITY.SPINACH.LIGHTER,
    },
    active: {
      backgroundColor: COLORS.KALE,
      borderColor: COLORS.KALE,
    },
    disabled: {
      backgroundColor: COLORS.OPACITY.DARK_KALE.LIGHTER,
      borderColor: COLORS.OPACITY.TRANSPARENT,
    },
  },
  primaryOutline: {
    default: {
      backgroundColor: COLORS.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
      borderColor: COLORS.OPACITY.TRANSPARENT,
    },
    hovered: {
      backgroundColor: COLORS.OPACITY.KALE.LIGHTEST,
      borderColor: COLORS.GREEN_2,
    },
    active: {
      backgroundColor: COLORS.OPACITY.KALE.LIGHTEST,
      borderColor: COLORS.GREEN_2,
    },
    disabled: {
      backgroundColor: COLORS.OPACITY.DARK_KALE.LIGHTER,
      borderColor: COLORS.OPACITY.TRANSPARENT,
    },
  },
};

// ─────────────────────────────────────────────────────────────────────────────

export const textStyles: Record<ChipPalette, Record<ChipState, Styles>> = {
  primary: {
    default: {
      color: COLORS.DARK_KALE,
    },
    hovered: {
      color: COLORS.BLACK,
    },
    active: {
      color: COLORS.OATMEAL,
    },
    disabled: {
      color: COLORS.OPACITY.DARK_KALE.DARK,
    },
  },
  primaryOutline: {
    default: {
      color: COLORS.DARK_KALE,
    },
    hovered: {
      color: COLORS.BLACK,
    },
    active: {
      color: COLORS.DARK_KALE,
    },
    disabled: {
      color: COLORS.OPACITY.DARK_KALE.DARK,
    },
  },
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ChipPalette = NonNullable<ChipProps['palette']>;

import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import type { CloudinaryTransformConfig } from '@sg/garnish';
import { CloudinaryImage } from '@sg/garnish';

export const OrderCardImage = memo((props: OrderCardImageProps) => (
  <CloudinaryImage
    baseUrl={props.assetUrl}
    config={ORDER_CARD_IMG_CONFIG}
    style={styles.imageWrapper}
    aria-label={props.description}
  />
));

// ─── Constants ──────────────────────────────────────────────────────────────────

const IMAGE_WIDTH = 118;
const IMAGE_HEIGHT = 118;

const ORDER_CARD_IMG_CONFIG: CloudinaryTransformConfig = [
  { crop: 'crop', height: 0.6, width: 0.5 },
  { width: 118 },
];

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  imageWrapper: {
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────────

type OrderCardImageProps = Readonly<{
  description: string;
  assetUrl: string;
}>;

import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { BodyText } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuDietaryPreferencesDescription = () => {
  return (
    <BodyText sizeMatch={['16']}>
      <FormattedMessage {...messages.text} />
    </BodyText>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  text: {
    defaultMessage:
      "Select the items you can't or don't want to eat. Items that contain significant amounts of these properties will be flagged.",
    description: 'Menu | Dietary preferences | Text',
  },
});

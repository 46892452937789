import React, { useMemo } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { LoadingAnimation } from '@order/components';
import { useCart, useRemoveLineItemFromCart } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import type {
  CanNotReorder,
  ReorderConflict,
  ReorderSuccess,
} from '../../../../graphql/types';
import type { ModalStackChildProps } from '../../../../navigation/AppNavigation.props';
import type {
  ReorderLineItemMutation,
  ReorderOrderMutation,
} from '../../GraphQL/Reorder.generated';
import {
  useReorderLineItemMutation,
  useReorderOrderMutation,
} from '../../GraphQL/Reorder.generated';
import {
  useCancelReorder,
  useChangeLocation,
  useFinishReorder,
  useReorderState,
} from '../../hooks';
import { useReorderTelemetry } from '../../machines/Reorder.telemetry';
import { NotOnMenu } from './NotOnMenu';
import { ReorderConflictReviewContent } from './ReorderConflictReview.content';

export const ReorderConflictReview = ({
  navigation,
}: ModalStackChildProps<'ReorderConflictReview'>) => {
  const { cart, isFetchingCart } = useCart();
  const { t } = useLocalizationContext();
  const { viewMenuTelemetry } = useReorderTelemetry();
  const { removeLineItemFromCart } = useRemoveLineItemFromCart();
  const [localOrderData, reorderOrder] = useReorderOrderMutation();
  const [localItemData, reorderLineItem] = useReorderLineItemMutation();
  const {
    orderId = '',
    lineItemId = '',
    deliveryAddressName = '',
    deliveryOrderDetail = {},
    restaurantId = '',
    restaurantName = '',
    response: machineResponse,
    currentStep,
  } = useReorderState();
  const cancelReorder = useCancelReorder();
  const changeLocation = useChangeLocation();
  const finishReorder = useFinishReorder();

  // ──────── Response Types ────────
  const memoedMachineResponse = useMemo(
    () => ({ data: machineResponse, fetching: false, stale: false }),
    [machineResponse],
  );
  const hasOrderResponse = localOrderData?.data?.reorderOrder?.__typename;
  const hasItemResponse = localItemData?.data?.reorderLineItem?.__typename;
  const localResponse = hasOrderResponse ? localOrderData : localItemData;
  const hasLocalResponse = hasOrderResponse ?? hasItemResponse;
  const response = hasLocalResponse ? localResponse : memoedMachineResponse;
  const data = response?.data;
  const reorderOrderResult = (data as ReorderOrderMutation)?.reorderOrder;
  const reorderItemResult = (data as ReorderLineItemMutation)?.reorderLineItem;
  const reorderResult = reorderOrderResult ?? reorderItemResult;
  const successResult = reorderResult as ReorderSuccess;
  const reorderType = reorderResult?.__typename;
  const reorderCart = successResult?.cart;
  const isMixingSupported = successResult?.isMixingSupported;
  const hasConflict = conflictingResponseTypes.has(reorderType ?? '');
  const hasBannerConflict = bannerResponseTypes.has(reorderType ?? '');
  const conflictResponse = reorderResult as ReorderConflict;
  const conflictMessage = (reorderResult as CanNotReorder)?.message;

  // If there are no conflicts, we consider it as not on the menu.
  // The "CannotReorder" version of this modal requires at least one conflict.
  const isNotOnMenu =
    hasConflict &&
    (conflictResponse.isNothingAvailable ||
      !conflictResponse.conflicts?.length);

  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  React.useEffect(() => {
    // ──────── Show Banner on Banner Types ────────
    if (hasBannerConflict) {
      addNoticeBanner({
        text: conflictMessage,
        palette: 'caution',
        targetId: 'reorder-conflicts',
      });
    }

    // ──────── Finish Reorder on Success ────────
    if (!hasConflict && reorderCart?.id) {
      if (!isMixingSupported) {
        addNoticeBanner({
          text: t('reorder.conflicts.mixing-unsupported'),
          palette: 'neutral',
        });
      }

      finishReorder();
    }
  }, [
    reorderCart,
    conflictMessage,
    hasBannerConflict,
    hasConflict,
    isMixingSupported,
    finishReorder,
    addNoticeBanner,
    t,
  ]);

  const handleFinishReorder = React.useCallback(() => {
    void (lineItemId ? reorderLineItem : reorderOrder)({
      input: {
        id: lineItemId || orderId,
        ignoreConflicts: true,
        destinationRestaurantId: restaurantId,
        deliveryOrderDetails: deliveryOrderDetail?.addressId
          ? {
              addressId: deliveryOrderDetail.addressId ?? '',
              deliveryFee: deliveryOrderDetail.deliveryFee ?? 0,
              tip: deliveryOrderDetail.tip ?? 0,
              vendorRestaurantId: deliveryOrderDetail.vendorRestaurantId ?? '',
              vendor: deliveryOrderDetail.vendor ?? '',
            }
          : undefined,
      },
    });
  }, [
    orderId,
    lineItemId,
    deliveryOrderDetail,
    restaurantId,
    reorderOrder,
    reorderLineItem,
  ]);
  const handleViewMenu = React.useCallback(async () => {
    const requests =
      cart?.lineItems.map(async ({ id }) => removeLineItemFromCart(id)) ?? [];

    await Promise.all(requests);
    viewMenuTelemetry();
    cancelReorder({ dismissToSelectedMenu: true });
  }, [
    cart?.lineItems,
    viewMenuTelemetry,
    cancelReorder,
    removeLineItemFromCart,
  ]);

  // ──────── Render Control ────────
  if (!hasConflict) return null;

  if (isNotOnMenu) {
    return (
      <NotOnMenu
        navigation={navigation}
        restaurantName={restaurantName}
        deliveryAddressName={deliveryAddressName}
        isLoadingMenu={isFetchingCart}
        cancelReorder={cancelReorder}
        changeLocation={changeLocation}
        viewMenu={handleViewMenu}
      />
    );
  }

  if (isFetchingCart) return <LoadingAnimation />;

  return (
    <ReorderConflictReviewContent
      navigation={navigation}
      reorderType={reorderType}
      restaurantName={restaurantName}
      deliveryAddressName={deliveryAddressName}
      currentStep={currentStep as string}
      conflicts={conflictResponse.conflicts}
      isLoading={localOrderData.fetching || localItemData.fetching}
      shouldShowClearWarning={Boolean(lineItemId)}
      cancelReorder={cancelReorder}
      changeLocation={changeLocation}
      finishReorder={handleFinishReorder}
    />
  );
};

const bannerResponseTypes = new Set([
  'RestaurantMaxDeliveryQuantityExceeded',
  'RestaurantMaxQuantityExceeded',
  'CanNotReorder',
]);
const conflictingResponseTypes = new Set([
  'ReorderConflict',
  'ValidationError',
  ...bannerResponseTypes,
]);

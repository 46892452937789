import { useCallback } from 'react';

import { type ShareParams } from './useShareTextDialog.types';

/**
 * Provides helpers to share text using Web Share API.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share}
 */
export const useShareTextDialog = () => {
  const canShare = typeof navigator.share === 'function';

  // ─── Helpers ─────────────────────────────────────────────────────────

  const share = useCallback(async (params: ShareParams) => {
    const { url, message } = params;

    if (url) {
      await navigator.share({ url });

      return;
    }

    await navigator.share({ text: message });
  }, []);

  // ─────────────────────────────────────────────────────────────────────

  return {
    share,
    canShare,
  };
};

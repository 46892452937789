import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuDietaryPreferencesContainer = (
  props: MenuDietaryPreferencesContainerProps,
) => {
  const { children, withoutHorizontalSpacing } = props;

  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    withoutHorizontalSpacing
      ? undefined
      : match([styles.containerXS, styles.containerSM]),
  ];

  // ─────────────────────────────────────────────────────────────────────

  return <View style={containerStyles}>{children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    rowGap: theme.spacing['6'],
    paddingTop: theme.spacing['2'],
    paddingBottom: theme.spacing['10'],
  },
  containerXS: {
    paddingHorizontal: theme.spacing['4'],
  },
  containerSM: {
    paddingHorizontal: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuDietaryPreferencesContainerProps = PropsWithChildren<{
  withoutHorizontalSpacing?: boolean;
}>;

import React from 'react';
import { View } from 'react-native';
import { Button, Modal, ModalDialogue, TextLinkifyTags } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const SweetpassConfirmationDialog = ({
  visible,
  isLoading,
  onDismiss,
  onConfirm,
}: SweetpassConfirmationDialogProps) => {
  const { t } = useLocalizationContext();

  const [isVisible, setIsVisible] = React.useState(false);

  const handleDismiss = React.useCallback(() => {
    setIsVisible(false);
    onDismiss();
  }, [onDismiss]);

  React.useEffect(() => {
    if (!visible) return;

    setIsVisible(visible);
  }, [visible]);

  // ────────────────────────────────────────────────────────────────────────────────

  return (
    <View>
      <ModalDialogue
        onRequestClose={handleDismiss}
        visible={isVisible}
        accessibilityLabel={t('sweetpass-upgrade.confirm-modal.title')}
        size="medium"
        testID="sweetpass-confirm-modal"
        headline={t('sweetpass-upgrade.confirm-modal.title')}
      >
        <Modal.BodyText>
          <TextLinkifyTags
            links={[
              {
                tag: 'terms',
                description: t('sweetpass-upgrade.confirm-modal.terms'),
                href: t('sweetpass-upgrade.confirm-modal.terms-url'),
                newTab: true,
              },
              {
                tag: 'email',
                description: t('sweetpass-upgrade.confirm-modal.email'),
                href: t('sweetpass-upgrade.confirm-modal.email-url'),
              },
            ]}
          >
            {t('sweetpass-upgrade.confirm-modal')}
          </TextLinkifyTags>
        </Modal.BodyText>

        <Modal.Footer withoutTopBorder>
          <Button
            testID="sweetpass-confirm-modal.confirm-btn"
            size="large"
            palette="primary"
            isLoading={isLoading}
            onPress={onConfirm}
          >
            {t('sweetpass-upgrade.confirm-modal.button')}
          </Button>
        </Modal.Footer>
      </ModalDialogue>
    </View>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type SweetpassConfirmationDialogProps = Readonly<{
  visible: boolean;
  isLoading: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
}>;

import type { ComponentProps } from 'react';
import React from 'react';
import { Animated, StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

export const HeaderShadow = (props: HeaderShadowProps) => {
  const headerShadowStyles = [styles.headerShadow, props.style];

  return <Animated.View style={headerShadowStyles} pointerEvents="none" />;
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  headerShadow: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    opacity: 0,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.GRAY,
  },
});

// ────────────────────────────────────────────────────────────────────────────────

type HeaderShadowProps = Pick<ComponentProps<typeof Animated.View>, 'style'>;

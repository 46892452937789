import type { ResolverConfig } from '@urql/exchange-graphcache';

import { overwriteOrderStatus } from '@order/OrderStatus';

/**
 * Graphcache | Local Resolvers
 * If the schema doesn't use id or _id, or if there's associated data that's not
 * "linked" then we can resolve from cache here.
 *
 * @see {@link: https://formidable.com/open-source/urql/docs/graphcache/local-resolvers/}
 */

export const resolvers: ResolverConfig = {
  Query: {
    currentCustomer(_parent, _args, cache) {
      const currentCustomerCache = cache.resolve('Query', 'currentCustomer');

      return currentCustomerCache;
    },
  },
  OrderStatus: {
    flattenedOrderStatus: overwriteOrderStatus,
  },
};

import React from 'react';

import { type LedgerProps } from './Ledger.types';
import { LedgerContainer } from './LedgerContainer';
import { LedgerLabelText } from './LedgerLabelText';
import { LedgerRow } from './LedgerRow';
import { LedgerRowLabel } from './LedgerRowLabel';

// ────────────────────────────────────────────────────────────────────────────

export const Ledger = (props: LedgerProps) => {
  const {
    items,
    hasTopBorder,
    helperTextModalDismissLabel,
    helperTextToggleA11yLabel,
    sizeMatch,
    ...restProps
  } = props;

  return (
    <LedgerContainer hasTopBorder={hasTopBorder} {...restProps}>
      {items.filter(Boolean).map((item) => (
        <LedgerRow
          {...item}
          key={item.label}
          sizeMatch={sizeMatch}
          helperTextModalDismissLabel={helperTextModalDismissLabel}
          helperTextToggleA11yLabel={helperTextToggleA11yLabel}
        />
      ))}
    </LedgerContainer>
  );
};

// ─── Compound Components ────────────────────────────────────────────────────
/* eslint-disable functional/immutable-data */

Ledger.Container = LedgerContainer;
Ledger.Row = LedgerRow;
Ledger.RowLabel = LedgerRowLabel;
Ledger.LabelText = LedgerLabelText;

import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { theme, VStack } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrdersListContainer = (props: PropsWithChildren) => {
  return (
    <VStack style={styles.container} gap={0}>
      {props.children}
    </VStack>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    marginTop: theme.spacing['4'],
    marginBottom: theme.spacing['30'],
  },
});

import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, Form, theme, useResponsive } from '@sg/garnish';

import {
  type GiftCardCheckoutFormFieldRules,
  type GiftCardCheckoutFormReturn,
} from '../../../../form';
import { GiftCardCheckoutFormGroup } from '../../components';
import { giftCardCheckoutDetailsMessages as messages } from './GiftCardCheckoutDetails.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutDetails = (
  props: GiftCardCheckoutDetailsProps,
) => {
  const { form, rules } = props;

  const { formatMessage } = useIntl();
  const { match } = useResponsive();

  // ─────────────────────────────────────────────────────────────────────

  const { isSubmitting } = form.formState;

  const message = form.watch('message');
  const messageCharsNumber = message?.length ?? 0;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const clearRecipientErrors = useCallback(() => {
    form.clearErrors('recipientName');
  }, [form]);

  const clearSenderErrors = useCallback(() => {
    form.clearErrors('senderName');
  }, [form]);

  const clearMessageErrors = useCallback(() => {
    form.clearErrors('message');
  }, [form]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = match([styles.containerXS, styles.containerSM]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <GiftCardCheckoutFormGroup.Container style={containerStyles}>
      <GiftCardCheckoutFormGroup.Title>
        <FormattedMessage {...messages.sectionTitle} />
      </GiftCardCheckoutFormGroup.Title>

      <View style={styles.fieldsContainer}>
        <GiftCardCheckoutFormGroup.FieldContainer>
          <Form.TextField
            name="recipientName"
            control={form.control}
            hideSubmitButton
            autoComplete="name"
            returnKeyType="done"
            onChangeText={clearRecipientErrors}
            disabled={isSubmitting}
            label={formatMessage(messages.recipientFieldLabel)}
          />
        </GiftCardCheckoutFormGroup.FieldContainer>

        <GiftCardCheckoutFormGroup.FieldContainer>
          <Form.TextField
            name="senderName"
            control={form.control}
            hideSubmitButton
            autoComplete="name"
            returnKeyType="done"
            onChangeText={clearSenderErrors}
            disabled={isSubmitting}
            label={formatMessage(messages.senderFieldLabel)}
          />
        </GiftCardCheckoutFormGroup.FieldContainer>

        <GiftCardCheckoutFormGroup.FieldContainer>
          <View style={styles.textareaDetailsContainer}>
            <BodyText sizeMatch={['12']}>
              <FormattedMessage {...messages.messageFieldLabel} />
            </BodyText>

            <BodyText sizeMatch={['12']}>
              {messageCharsNumber}/{rules.message.maxChars}
            </BodyText>
          </View>

          <Form.TextField
            name="message"
            control={form.control}
            style={styles.textarea}
            maxLength={rules.message.maxChars}
            autoCorrect
            placeholder="Your message"
            multiline
            textAlignVertical="top"
            blurOnSubmit
            onChangeText={clearMessageErrors}
            disabled={isSubmitting}
            returnKeyType="done"
          />
        </GiftCardCheckoutFormGroup.FieldContainer>
      </View>
    </GiftCardCheckoutFormGroup.Container>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    paddingBottom: 0,
  },
  containerSM: {
    paddingBottom: theme.spacing['4'],
  },
  fieldsContainer: {
    paddingTop: theme.spacing['2'],
  },
  textareaDetailsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing['2'],
  },
  textarea: {
    height: 78,
    padding: theme.spacing['1'],
    fontSize: 14,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutDetailsProps = {
  sectionTitle?: string;
  form: GiftCardCheckoutFormReturn;
  rules: GiftCardCheckoutFormFieldRules;
};

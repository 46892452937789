import React, { useCallback, useLayoutEffect } from 'react';

import { useCart } from '@order/hooks';

import type { EditProductDetailsScreenProps } from '../../navigation/AppNavigation.props';
import { ProductDetails } from './components';

// ────────────────────────────────────────────────────────────────────────────────

export const EditProductDetailsScreen = ({
  navigation,
  route,
}: EditProductDetailsScreenProps) => {
  const { restaurantSlug, productSlug, lineItemId } = route.params ?? {};

  const { cart } = useCart();

  // ─────────────────────────────────────────────────────────────────

  const navigateToBag = useCallback(() => {
    const previousRoutes = navigation.getState().routes;
    const previousRoute = previousRoutes.at(-2);

    // When navigating to the bag, if the bag is already in the stack, just return to it.
    if (navigation.canGoBack() && previousRoute?.name === 'Bag') {
      navigation.goBack();

      return;
    }

    navigation.replace('Bag');
  }, [navigation]);

  const navigateToProductDetailsScreen = useCallback(() => {
    navigation.replace('ProductDetails', { restaurantSlug, productSlug });
  }, [navigation, productSlug, restaurantSlug]);

  // ─────────────────────────────────────────────────────────────────

  // redirect to the PDP screen if the line item isn't in the cart or has been removed
  useLayoutEffect(() => {
    const lineItems = cart?.lineItems;
    const isLineItemInCart = lineItems?.some(({ id }) => id === lineItemId);

    if (!isLineItemInCart) navigateToProductDetailsScreen();
  }, [cart?.lineItems, lineItemId, navigateToProductDetailsScreen]);

  // ─────────────────────────────────────────────────────────────────

  return (
    <ProductDetails
      restaurantSlug={restaurantSlug}
      productSlug={productSlug}
      lineItemId={lineItemId}
      onEditLineItemInCart={navigateToBag}
      onRemoveLineItemFromCart={navigateToBag}
    />
  );
};

import { useLocalizationContext } from '@order/Localization';
import { formatDateWithoutTimezone } from '@order/utils';

export function useSweetpassCheckoutContentfulInterpolation(
  values: InterpolateContentfulValues,
) {
  const {
    planPrice,
    trialDuration,
    trialDurationSingular,
    nextBillingDate,
    switchDate,
    ctaLabel,
    requestedPlanRecurrence,
    billingFrequencyUnit,
    billingFrequencyMembershipName,
  } = values;

  const { formatPrice } = useLocalizationContext();

  const price =
    planPrice && !Number.isNaN(planPrice)
      ? formatPrice(planPrice * 100, 'USD')
      : '';

  const now = new Date().toISOString();

  const { formattedDate } = formatDateWithoutTimezone(now);
  const formattedNextBillingDate =
    formatDateWithoutTimezone(nextBillingDate).formattedDate;
  const formattedSwitchDate =
    formatDateWithoutTimezone(switchDate).formattedDate;

  return (field: string) =>
    field
      .replaceAll('{price}', price)
      .replaceAll('{trialDuration}', trialDuration)
      .replaceAll('{trialDurationSingular}', trialDurationSingular)
      .replaceAll('{date}', formattedDate)
      .replaceAll('{nextBillingDate}', formattedNextBillingDate)
      .replaceAll('{switchDate}', formattedSwitchDate)
      .replaceAll('{cta}', ctaLabel)
      .replaceAll('{requestedPlanRecurrence}', requestedPlanRecurrence)
      .replaceAll('{billingFrequencyUnit}', billingFrequencyUnit.toLowerCase())
      .replaceAll(
        '{billingFrequencyMembershipName}',
        billingFrequencyMembershipName,
      );
}

// ─── Types ───────────────────────────────────────────────────────────────────

type InterpolateContentfulValues = Readonly<{
  trialDuration: string;
  trialDurationSingular: string;
  planPrice: number;
  nextBillingDate: string;
  switchDate: string;
  ctaLabel: string;
  requestedPlanRecurrence: string;
  billingFrequencyUnit: string;
  billingFrequencyMembershipName: string;
}>;

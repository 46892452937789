import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { H1 } from '@expo/html-elements';
import { DisplayText, theme, useResponsive } from '@sg/garnish';

export const SweetpassCheckoutHeader = ({ children }: ViewProps) => {
  const { currentBreakpoint } = useResponsive();
  const { match, minWidth } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const headingStyles = [
    styles.heading,
    minWidth.isSM ? styles.headingSM : undefined,
    { color: currentBreakpoint.isXS ? undefined : theme.colors.LIME },
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={match([styles.headerMobile, styles.headerTabletUp])}>
      <H1 style={headingStyles}>
        <DisplayText sizeMatch={['24', '32', '40']} style={headingStyles}>
          {children}
        </DisplayText>
      </H1>
    </View>
  );
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  headerMobile: {
    paddingBottom: theme.spacing['10'],
  },
  headerTabletUp: {
    paddingVertical: theme.spacing['10'],
  },
  heading: {
    marginVertical: 0,
  },
  headingSM: {
    textAlign: 'center',
    marginVertical: 0,
  },
});

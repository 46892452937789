import React, { useCallback, useState } from 'react';

import { type useLoyaltyOffers } from '@order/components';
import { Loyalty } from '@order/features/loyalty';
import { useTelemetry } from '@order/Telemetry';

import { LoyaltyOfferCard } from '../LoyaltyOfferCard';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOffersModal = (props: LoyaltyOffersModalProps) => {
  const { offers, visible, hideOffersModal } = props;

  // ─── State ───────────────────────────────────────────────────────────

  const [seenOfferIds, setSeenOfferIds] = useState<string[]>([]);
  const [activeOfferSlideIndex, setActiveOfferSlideIndex] = useState<number>(0);

  const activeOfferId = offers[activeOfferSlideIndex]?.id;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const { track } = useTelemetry();

  const hideModal = useCallback(() => {
    setSeenOfferIds([]);
    hideOffersModal(seenOfferIds);
  }, [seenOfferIds, hideOffersModal]);

  const onModalCloseButtonPress = useCallback(() => {
    track('loyalty.offer.modal.exit.cta_tapped', { activeOfferId });
    hideModal();
  }, [activeOfferId, hideModal, track]);

  const onActiveSlideChange = useCallback(
    (activeSlideIndex: number) => {
      const activeSlideOfferId = offers[activeOfferSlideIndex]?.id;

      setSeenOfferIds((currentValues) =>
        mergeOfferIds(currentValues, [activeSlideOfferId]),
      );
      setActiveOfferSlideIndex(activeSlideIndex);

      track('loyalty.offer.modal.multiple_swipe');
    },
    [activeOfferSlideIndex, offers, track],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Loyalty.Offers.Modal
      visible={visible}
      onRequestClose={hideModal}
      onCloseButtonPress={onModalCloseButtonPress}
    >
      <Loyalty.Offers.Carousel onActiveSlideIndexChange={onActiveSlideChange}>
        {offers.map((offer) => (
          <LoyaltyOfferCard
            key={offer.id}
            offer={offer}
            onNavigate={hideModal}
          />
        ))}
      </Loyalty.Offers.Carousel>
    </Loyalty.Offers.Modal>
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * Merges two arrays of offer IDs into a single array containing unique offer IDs.
 */
function mergeOfferIds(existingOfferIds: string[], offerIds: string[]) {
  return [...new Set([...existingOfferIds, ...offerIds])];
}

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOffersModalProps = {
  offers: FilteredOffers;
  visible: boolean;
  hideOffersModal: (seenOfferIds: string[]) => void;
};

type FilteredOffers = ReturnType<typeof useLoyaltyOffers>['filteredOffers'];

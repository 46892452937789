import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Container,
  DisplayText,
  theme,
  useResponsive,
} from '@sg/garnish';

export const LoyaltyHomeRewardsEmptyState = () => {
  const { match, minWidth } = useResponsive();

  return (
    <View style={match([null, styles.containerSm])}>
      <Container style={styles.content}>
        {minWidth.isSM ? (
          <DisplayText style={styles.whiteLabel} sizeMatch={['24']}>
            <FormattedMessage {...messages.rewards} />
          </DisplayText>
        ) : null}

        <BodyText
          style={match([styles.centerLabel, styles.whiteLabel])}
          sizeMatch={['18']}
        >
          {minWidth.isSM ? (
            <FormattedMessage {...messages.emptyStateSm} />
          ) : (
            <FormattedMessage {...messages.emptyStateXs} />
          )}
        </BodyText>
      </Container>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  rewards: {
    defaultMessage: 'Rewards',
    description: 'Loyalty | Home | Rewards | Rewards',
  },
  emptyStateXs: {
    defaultMessage: 'No bonus rewards available at the moment.',
    description: 'Loyalty | Home | Rewards | Empty State (XS)',
  },
  emptyStateSm: {
    defaultMessage:
      'No bonus rewards available at the moment. Place an order to earn more points.',
    description: 'Loyalty | Home | Rewards | Empty State (SM+)',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerSm: {
    backgroundColor: theme.colors.FOREST,
  },
  content: {
    paddingVertical: theme.spacing['4'],
    gap: theme.spacing['6'],
  },
  centerLabel: {
    textAlign: 'center',
  },
  whiteLabel: {
    color: theme.colors.WHITE,
  },
});

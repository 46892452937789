import { useCallback, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useNoticeBannersStackContext, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import { getUrqlError } from '@order/utils';

import { useActiveRouteName } from '../../navigation';
import { usePromoCodeTelemetry } from './usePromoCodeTelemetry';
import { useSubmitPromoOrGiftCardCode } from './useSubmitPromoOrGiftCardCode';

export const usePromoCode = (refetchCredits?: () => void) => {
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();
  const navigation = useNavigation();
  const [promoCode, setPromoCode] = useState('');
  const [promoCodePreviouslyEntered, setPromoCodePreviouslyEntered] =
    useState(false);
  const [promoCodeInvalid, setPromoCodeInvalid] = useState(false);
  const [isPromoCodeAlreadyApplied, setIsPromoCodeAlreadyApplied] =
    useState(false);
  const routeName = useActiveRouteName();

  const { submitCodeResponse, submitCode } = useSubmitPromoOrGiftCardCode();
  const { fetching: isSubmittingPromoCode } = submitCodeResponse;

  const {
    monitorPromoCodeApplied,
    monitorPromoCodeFailed,
    monitorPromoCodeEntered,
    monitorPromoCodeAttemptApply,
  } = usePromoCodeTelemetry();

  const handleSetPromoCode = useCallback(
    (input: string) => {
      setPromoCode(input);
      setPromoCodeInvalid(false);

      // Trigger promo code entered event once per session
      if (input.length > 0 && !promoCodePreviouslyEntered) {
        setPromoCodePreviouslyEntered(true);
        monitorPromoCodeEntered(routeName);
      }
    },
    [monitorPromoCodeEntered, promoCodePreviouslyEntered, routeName],
  );

  const submitPromoCode = useCallback(async () => {
    monitorPromoCodeAttemptApply(routeName);
    const { isSubmitSuccess, typename, error, isAlreadyApplied } =
      await submitCode(promoCode);

    setPromoCodeInvalid(!isSubmitSuccess);
    setIsPromoCodeAlreadyApplied(isAlreadyApplied);

    if (!isSubmitSuccess) {
      monitorPromoCodeFailed(
        t('account.notice.credit-invalid'),
        getUrqlError(error),
        routeName,
      );

      return;
    }

    setPromoCode('');
    setPromoCodePreviouslyEntered(false);
    monitorPromoCodeApplied(`${typename}`, routeName);

    if (currentBreakpoint.isXS && navigation.canGoBack()) {
      navigation.goBack();
    }

    addNoticeBanner({
      text: t(
        typename === 'SubmitPromoCodeSuccessV2'
          ? 'account.notice.promo-applied'
          : 'account.notice.credit-applied',
      ),
      palette: 'success',
    });

    refetchCredits?.();
  }, [
    promoCode,
    currentBreakpoint.isXS,
    navigation,
    routeName,
    t,
    addNoticeBanner,
    monitorPromoCodeFailed,
    monitorPromoCodeApplied,
    monitorPromoCodeAttemptApply,
    refetchCredits,
    submitCode,
  ]);

  return {
    promoCode,
    isSubmittingPromoCode,
    isPromoCodeApplied: isPromoCodeAlreadyApplied,
    promoCodeInvalid,
    setPromoCode: handleSetPromoCode,
    submitPromoCode,
  };
};

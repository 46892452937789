/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BagCartDataFragment = {
  readonly __typename?: 'Order';
  readonly id: string;
  readonly orderType: Types.OrderType;
  readonly canTrackOrderStatus: boolean;
  readonly availableWantedTimes: ReadonlyArray<{
    readonly __typename?: 'AvailableWantedTime';
    readonly time: string;
    readonly deliveryOffset: number;
  }>;
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
    readonly slug: string;
    readonly name: string;
    readonly city: string;
    readonly state: string;
    readonly address: string;
    readonly zipCode: string;
    readonly isOutpost: boolean;
    readonly deliveryMinSubtotal: number;
    readonly showDeliveryFeeDisclosure: boolean;
    readonly deliveryFee: number;
    readonly availableDropOffLocations: ReadonlyArray<{
      readonly __typename?: 'DropOffLocation';
      readonly id: string;
      readonly name: string;
    }>;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  };
  readonly deliveryOrderDetail: {
    readonly __typename?: 'DeliveryOrderDetail';
    readonly id: string;
    readonly tip: number;
    readonly deliveryFee: number;
    readonly vendor: string;
    readonly orderId: string;
    readonly vendorRestaurantId: string;
    readonly estimatedDeliveryTime: string | null;
    readonly address: {
      readonly __typename?: 'Address';
      readonly id: string;
      readonly street: string;
      readonly secondaryStreet: string | null;
      readonly city: string;
      readonly state: string;
      readonly country: string;
      readonly zipCode: string;
      readonly deliveryPreference: Types.DeliveryPreferenceType;
      readonly googlePlaceId: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly name: string | null;
      readonly notes: string | null;
    } | null;
  } | null;
  readonly lineItems: ReadonlyArray<{
    readonly __typename?: 'LineItem';
    readonly id: string;
    readonly slug: string;
    readonly quantity: number;
    readonly cost: number;
    readonly customName: string | null;
    readonly perItemCost: number;
    readonly favorited: boolean;
    readonly isCustom: boolean;
    readonly dressingMode: Types.DressingMode;
    readonly addedIngredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly kind: Types.IngredientKind | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    }>;
    readonly removedIngredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly kind: Types.IngredientKind | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    }>;
    readonly product: {
      readonly __typename?: 'Product';
      readonly id: string;
      readonly name: string;
      readonly slug: string;
      readonly isCustom: boolean;
      readonly isModifiable: boolean;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly ingredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: Types.IngredientKind | null;
      }>;
    };
    readonly mixedDressingDetails: ReadonlyArray<{
      readonly __typename?: 'MixedDressingDetails';
      readonly ingredientId: string;
      readonly weight: Types.DressingWeight;
    }>;
  }>;
  readonly ledger: {
    readonly __typename?: 'Ledger';
    readonly tax: number;
    readonly subtotal: number;
    readonly feesTotal: number;
    readonly discountsTotal: number;
    readonly creditsTotal: number;
    readonly tip: number;
    readonly discounts: ReadonlyArray<{
      readonly __typename?: 'LedgerItem';
      readonly name: string;
      readonly amount: number;
      readonly description: string | null;
    }>;
    readonly credits: ReadonlyArray<{
      readonly __typename?: 'LedgerItem';
      readonly name: string;
      readonly amount: number;
      readonly description: string | null;
    }>;
    readonly fees: ReadonlyArray<{
      readonly __typename?: 'LedgerItem';
      readonly name: string;
      readonly amount: number;
      readonly description: string | null;
    }>;
  };
};

export type BagCartQueryVariables = Types.Exact<{ [key: string]: never }>;

export type BagCartQuery = {
  readonly __typename?: 'Query';
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly orderType: Types.OrderType;
    readonly canTrackOrderStatus: boolean;
    readonly availableWantedTimes: ReadonlyArray<{
      readonly __typename?: 'AvailableWantedTime';
      readonly time: string;
      readonly deliveryOffset: number;
    }>;
    readonly restaurant: {
      readonly __typename?: 'Restaurant';
      readonly id: string;
      readonly slug: string;
      readonly name: string;
      readonly city: string;
      readonly state: string;
      readonly address: string;
      readonly zipCode: string;
      readonly isOutpost: boolean;
      readonly deliveryMinSubtotal: number;
      readonly showDeliveryFeeDisclosure: boolean;
      readonly deliveryFee: number;
      readonly availableDropOffLocations: ReadonlyArray<{
        readonly __typename?: 'DropOffLocation';
        readonly id: string;
        readonly name: string;
      }>;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    };
    readonly deliveryOrderDetail: {
      readonly __typename?: 'DeliveryOrderDetail';
      readonly id: string;
      readonly tip: number;
      readonly deliveryFee: number;
      readonly vendor: string;
      readonly orderId: string;
      readonly vendorRestaurantId: string;
      readonly estimatedDeliveryTime: string | null;
      readonly address: {
        readonly __typename?: 'Address';
        readonly id: string;
        readonly street: string;
        readonly secondaryStreet: string | null;
        readonly city: string;
        readonly state: string;
        readonly country: string;
        readonly zipCode: string;
        readonly deliveryPreference: Types.DeliveryPreferenceType;
        readonly googlePlaceId: string;
        readonly latitude: number;
        readonly longitude: number;
        readonly name: string | null;
        readonly notes: string | null;
      } | null;
    } | null;
    readonly lineItems: ReadonlyArray<{
      readonly __typename?: 'LineItem';
      readonly id: string;
      readonly slug: string;
      readonly quantity: number;
      readonly cost: number;
      readonly customName: string | null;
      readonly perItemCost: number;
      readonly favorited: boolean;
      readonly isCustom: boolean;
      readonly dressingMode: Types.DressingMode;
      readonly addedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: Types.IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly removedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: Types.IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly product: {
        readonly __typename?: 'Product';
        readonly id: string;
        readonly name: string;
        readonly slug: string;
        readonly isCustom: boolean;
        readonly isModifiable: boolean;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
        readonly ingredients: ReadonlyArray<{
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly kind: Types.IngredientKind | null;
        }>;
      };
      readonly mixedDressingDetails: ReadonlyArray<{
        readonly __typename?: 'MixedDressingDetails';
        readonly ingredientId: string;
        readonly weight: Types.DressingWeight;
      }>;
    }>;
    readonly ledger: {
      readonly __typename?: 'Ledger';
      readonly tax: number;
      readonly subtotal: number;
      readonly feesTotal: number;
      readonly discountsTotal: number;
      readonly creditsTotal: number;
      readonly tip: number;
      readonly discounts: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly credits: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly fees: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
    };
  } | null;
};

export type UpdateLineItemMutationVariables = Types.Exact<{
  input: Types.EditLineItemInCartInput;
}>;

export type UpdateLineItemMutation = {
  readonly __typename?: 'Mutation';
  readonly editLineItemInCart:
    | {
        readonly __typename: 'EditLineItemInCartSuccess';
        readonly cart: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly canTrackOrderStatus: boolean;
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename?: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: Types.DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: Types.DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly isModifiable: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: Types.IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: Types.DressingWeight;
            }>;
          }>;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
        };
      }
    | {
        readonly __typename: 'MaxModificationsExceeded';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RestaurantMaxDeliveryQuantityExceeded';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RestaurantMaxQuantityExceeded';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type RemoveLineItemMutationVariables = Types.Exact<{
  input: Types.RemoveFromCartInput;
}>;

export type RemoveLineItemMutation = {
  readonly __typename?: 'Mutation';
  readonly removeFromCart:
    | {
        readonly __typename: 'RemoveFromCartSuccess';
        readonly cart: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly canTrackOrderStatus: boolean;
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename?: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: Types.DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: Types.DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly isModifiable: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: Types.IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: Types.DressingWeight;
            }>;
          }>;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export const BagCartDataFragmentDoc = gql`
  fragment BagCartData on Order {
    id
    orderType
    canTrackOrderStatus
    availableWantedTimes {
      time
      deliveryOffset
    }
    restaurant {
      id
      slug
      name
      city
      state
      address
      zipCode
      isOutpost
      deliveryMinSubtotal
      isOutpost
      showDeliveryFeeDisclosure
      deliveryFee
      availableDropOffLocations {
        id
        name
      }
      asset {
        url
      }
    }
    deliveryOrderDetail {
      id
      tip
      deliveryFee
      vendor
      orderId
      vendorRestaurantId
      estimatedDeliveryTime
      address {
        id
        street
        secondaryStreet
        city
        state
        country
        zipCode
        deliveryPreference
        googlePlaceId
        latitude
        longitude
        name
        notes
      }
    }
    lineItems {
      id
      slug
      quantity
      cost
      customName
      perItemCost
      favorited
      isCustom
      addedIngredients {
        id
        name
        kind
        asset {
          url
        }
      }
      removedIngredients {
        id
        name
        kind
        asset {
          url
        }
      }
      product {
        id
        name
        slug
        isCustom
        isModifiable
        asset {
          url
        }
        ingredients {
          id
          name
          kind
        }
      }
      mixedDressingDetails {
        ingredientId
        weight
      }
      dressingMode
    }
    ledger {
      tax
      subtotal
      feesTotal
      discountsTotal
      creditsTotal
      tip
      discounts {
        name
        amount
        description
      }
      credits {
        name
        amount
        description
      }
      fees {
        name
        amount
        description
      }
    }
  }
`;
export const BagCartDocument = gql`
  query BagCart {
    cart {
      ...BagCartData
    }
  }
  ${BagCartDataFragmentDoc}
`;

export function useBagCartQuery(
  options?: Omit<Urql.UseQueryArgs<BagCartQueryVariables>, 'query'>,
) {
  return Urql.useQuery<BagCartQuery, BagCartQueryVariables>({
    query: BagCartDocument,
    ...options,
  });
}
export const UpdateLineItemDocument = gql`
  mutation UpdateLineItem($input: EditLineItemInCartInput!) {
    editLineItemInCart(input: $input) {
      __typename
      ... on EditLineItemInCartSuccess {
        cart {
          ...BagCartData
        }
      }
      ... on RestaurantMaxQuantityExceeded {
        message
        status
      }
      ... on MaxModificationsExceeded {
        message
        status
      }
      ... on RestaurantMaxDeliveryQuantityExceeded {
        message
        status
      }
      ... on ValidationError {
        message
        status
        fieldErrors {
          field
          message
        }
      }
    }
  }
  ${BagCartDataFragmentDoc}
`;

export function useUpdateLineItemMutation() {
  return Urql.useMutation<
    UpdateLineItemMutation,
    UpdateLineItemMutationVariables
  >(UpdateLineItemDocument);
}
export const RemoveLineItemDocument = gql`
  mutation RemoveLineItem($input: RemoveFromCartInput!) {
    removeFromCart(input: $input) {
      __typename
      ... on RemoveFromCartSuccess {
        cart {
          ...BagCartData
        }
      }
      ... on ValidationError {
        message
        status
        fieldErrors {
          field
          message
        }
      }
    }
  }
  ${BagCartDataFragmentDoc}
`;

export function useRemoveLineItemMutation() {
  return Urql.useMutation<
    RemoveLineItemMutation,
    RemoveLineItemMutationVariables
  >(RemoveLineItemDocument);
}

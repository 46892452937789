import React from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import {
  AspectRatioImage,
  getContentfulImageUrl,
  theme,
  useResponsive,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutCardImage = (
  props: GiftCardCheckoutCardImageProps,
) => {
  const { url, accessibilityLabel } = props;

  const { scale } = useWindowDimensions();
  const { match, minWidth } = useResponsive();

  // ─────────────────────────────────────────────────────────────────────

  const imgWidth = minWidth.isLG ? IMG_WIDTH_LG : IMG_WIDTH_XS;
  const imageUrl = getContentfulImageUrl(url, {
    w: scale === 1 ? imgWidth : imgWidth * 2,
  });

  // ─── Styles ──────────────────────────────────────────────────────────

  const imgContainerResponsiveStyles = match([
    styles.imgContainerXS,
    styles.imgContainerSM,
    styles.imgContainerMD,
  ]);
  const imgContainerStyles = [
    styles.imgContainer,
    imgContainerResponsiveStyles,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <View style={imgContainerStyles}>
        <AspectRatioImage
          source={imageUrl}
          borderRadius={theme.radius.xlarge}
          aria-label={accessibilityLabel}
          aspectRatioY={4}
          aspectRatioX={3}
        />
      </View>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const IMG_WIDTH_XS = 400;
const IMG_WIDTH_SM = 342;
const IMG_WIDTH_LG = 480;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    maxWidth: '100%',
    alignItems: 'center',
  },
  imgContainer: {
    maxWidth: '100%',
  },
  imgContainerXS: {
    width: IMG_WIDTH_XS,
  },
  imgContainerSM: {
    width: IMG_WIDTH_SM,
  },
  imgContainerMD: {
    width: IMG_WIDTH_LG,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutCardImageProps = {
  url: string;
  accessibilityLabel?: string;
};

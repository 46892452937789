export const delivery = {
  'delivery.estimate': '{estimate} minutes',
  'delivery.estimate-header': ' · {estimate} min',
  'delivery.closed': 'Nearby stores are currently closed',
  'delivery.unavailable':
    'Either delivery is not available at the moment or we haven’t launched in your area.',
  'delivery.unavailable-mobile':
    'Ah snap peas, we’re not delivering to your address',
  'delivery.new-address': 'Try a new address',
  'delivery.order-pickup': 'Order pickup',
  'delivery.order-now': 'Order now',
  'delivery.order-later': 'Schedule for later',
  'delivery.order-later-hint': 'Nearby stores are currently closed',
  'delivery.preferences.title': 'Edit delivery details',
  'delivery.preferences.leave-at-door': 'Leave at door',
  'delivery.preferences.meet-at-door': 'Meet at door',
  'delivery.preferences.meet-outside': 'Meet outside',
  'delivery.preferences.dropoff-label': 'Drop-Off',
  'delivery.preferences.note-hint': 'Add a note for the courier',
  'delivery.preferences.note-label': 'Delivery notes',
  'delivery.preferences.note-placeholder':
    'Courier notes, e.g. security code, building details...',
  'delivery.preferences.save': 'Save',
  'delivery.preferences.save.a11y': 'Save Delivery Preferences',
  'delivery.complete-form.title': 'Complete your delivery details',
  'delivery.complete-form.cta': 'Save & continue',
  'delivery.in-flight-modal.title': 'You already have an order on the way',
  'delivery.in-flight-modal.subtitle':
    "You cannot place a delivery order when there's an order currently in flight. Sit tight and enjoy  your sweetgreen.",
  'delivery.in-flight-modal.cta-close': 'Close',
  'delivery.in-flight-modal.cta-home': 'Go home',
  'delivery.in-flight-modal.cta-status': 'Order status',
};

import React from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { IconLink, theme } from '@sg/garnish';

import { orderAgainConflictReviewCloseButtonMessages as messages } from './OrderAgainConflictReviewCloseButton.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainConflictReviewCloseButton = (
  props: OrderAgainConflictReviewCloseButtonProps,
) => {
  const { onPress } = props;

  const { formatMessage } = useIntl();

  return (
    <View style={styles.container}>
      <IconLink
        name="IconClose"
        palette="muted"
        accessibilityLabel={formatMessage(messages.close)}
        width={40}
        height={40}
        onPress={onPress}
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-end',
    marginBottom: theme.spacing['8'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderAgainConflictReviewCloseButtonProps = {
  onPress: () => void;
};

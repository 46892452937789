import { theme } from '@sg/garnish';

export const SweetpassHomeActionCardThemes: Record<
  string,
  SweetpassHomeActionCardTheme
> = {
  kale_on_lime: {
    foregroundColor: theme.colors.KALE,
    backgroundColor: theme.colors.LIME,
  },
  kale_on_cucumber: {
    foregroundColor: theme.colors.KALE,
    backgroundColor: theme.colors.CUCUMBER,
  },
  lime_on_tangerine: {
    foregroundColor: theme.colors.LIME,
    backgroundColor: theme.colors.TANGERINE,
  },
  lime_on_kale: {
    foregroundColor: theme.colors.LIME,
    backgroundColor: theme.colors.KALE,
  },
  white_on_blueberry: {
    foregroundColor: theme.colors.WHITE,
    backgroundColor: theme.colors.BLUEBERRY,
  },
  white_on_grapefruit: {
    foregroundColor: theme.colors.WHITE,
    backgroundColor: theme.colors.GRAPEFRUIT,
  },
  white_on_kale: {
    foregroundColor: theme.colors.WHITE,
    backgroundColor: theme.colors.KALE,
  },
  white_on_oatmeal: {
    foregroundColor: theme.colors.WHITE,
    backgroundColor: theme.colors.OATMEAL,
  },
};

const DefaultSweetpassHomeActionCardTheme: SweetpassHomeActionCardTheme =
  SweetpassHomeActionCardThemes.kale_on_cucumber;

export type SweetpassHomeActionCardTheme = Readonly<{
  foregroundColor: string;
  backgroundColor: string;
}>;

export type SweetpassHomeActionCardThemeName =
  keyof typeof SweetpassHomeActionCardThemes;

export const getSweetpassHomeActionCardTheme = (
  name: string,
): SweetpassHomeActionCardTheme =>
  SweetpassHomeActionCardThemes[name] ?? DefaultSweetpassHomeActionCardTheme;

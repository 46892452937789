import type { ComponentProps } from 'react';
import React, { useCallback } from 'react';

import { TextField } from '../../TextField';

export const TextFieldZipCode = (props: ComponentProps<typeof TextField>) => {
  const validateChangedText = useCallback((zipCode: string) => {
    return ONLY_NUMBERS.test(zipCode);
  }, []);

  // ────────────────────────────────────────────────────────────────────

  return (
    <TextField
      onChangeTextGuard={validateChangedText}
      autoCapitalize="none"
      autoCorrect={false}
      textContentType="postalCode"
      keyboardType="number-pad"
      maxLength={5}
      {...props}
    />
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const ONLY_NUMBERS = /^\d*$/;

/* eslint-disable functional/immutable-data */

import type { RefObject } from 'react';
import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  View,
} from 'react-native';
import { Platform, useWindowDimensions } from 'react-native';
import {
  measureElement,
  measureLayoutPosition,
  theme,
  useResponsive,
} from '@sg/garnish';

import { usePrevious } from '../../../hooks';
import type { IngredientsModificationsWithQuantities } from '../state';

export const useScrollToAddedIngredient = (
  props: UseScrollToAddedIngredientProps,
) => {
  const { activeIngredientModifications } = props;
  const { minWidth } = useResponsive();
  const { width: windowWidth } = useWindowDimensions();

  const activeIngredientsIds = useMemo(
    () => activeIngredientModifications.map(({ ingredient }) => ingredient.id),
    [activeIngredientModifications],
  );
  const [activeIngredientsCardsRefs, setActiveIngredientsCardsRefs] = useState(
    generateIngredientsCardsRefsMap(activeIngredientsIds),
  );
  const prevActiveIngredientsRefs = usePrevious(activeIngredientsCardsRefs);

  // ────────────────────────────────────────────────────────────────────

  const ingredientsMobileScrollViewRef = useRef<ScrollView>(null);
  const ingredientsDesktopScrollViewRef = useRef<ScrollView>(null);
  const ingredientsScrollWrappersOffset = useRef({ mobile: 0, desktop: 0 });

  // ─── HELPERS ─────────────────────────────────────────────────────

  const setIngredientsScrollXsWrappersOffset = useCallback(
    (event: ScrollEvent) => {
      const { contentOffset } = event.nativeEvent;
      const scrollWrappersOffset = ingredientsScrollWrappersOffset.current;

      scrollWrappersOffset.mobile = contentOffset.x;
    },
    [],
  );

  const setIngredientsScrollMdWrappersOffset = useCallback(
    (event: ScrollEvent) => {
      const { contentOffset } = event.nativeEvent;
      const scrollWrappersOffset = ingredientsScrollWrappersOffset.current;

      scrollWrappersOffset.desktop = contentOffset.y;
    },
    [],
  );

  const getNewIngredientId = useCallback(() => {
    const shouldScrollToAddedIngredientCard =
      prevActiveIngredientsRefs &&
      activeIngredientsCardsRefs.size - prevActiveIngredientsRefs.size === 1;

    if (!shouldScrollToAddedIngredientCard) return;

    const prevActiveModificationsIdsSet = new Set(
      prevActiveIngredientsRefs?.keys(),
    );

    return activeIngredientsIds.find(
      (id) => !prevActiveModificationsIdsSet.has(id),
    );
  }, [
    activeIngredientsCardsRefs.size,
    activeIngredientsIds,
    prevActiveIngredientsRefs,
  ]);

  const scrollIntoViewIfNeeded = useCallback(
    async (scrollProps: ScrollIntoViewIfNeededProps) => {
      const { scrollView, card, x } = scrollProps;
      const { mobile: mobileWrapperScrollDistance } =
        ingredientsScrollWrappersOffset.current;
      const { width: cardWidth } = await measureElement(card);

      // eslint-disable-next-line no-constant-binary-expression
      const cardStartPosition = x + MOBILE_SCROLL_WRAPPER_OFFSET ?? 0;
      const cardEndPosition = cardStartPosition + cardWidth;

      const isCardInView =
        cardStartPosition >= mobileWrapperScrollDistance &&
        cardEndPosition < mobileWrapperScrollDistance + windowWidth;

      if (!isCardInView) {
        scrollView.scrollTo({ x, animated: true });
      }
    },
    [windowWidth],
  );

  const scrollToAddedIngredientCard = useCallback(
    async (addedIngredientId = '') => {
      const cardRef = activeIngredientsCardsRefs.get(addedIngredientId);

      if (!cardRef?.current) return;

      // ─────────────────────────────────────────────────────────────────

      const {
        mobile: mobileWrapperScrollDistance,
        desktop: desktopWrapperScrollDistance,
      } = ingredientsScrollWrappersOffset.current;

      const isMdBreakpoint = minWidth.isMD;
      const xsScrollWrapper = ingredientsMobileScrollViewRef.current;
      const mdScrollWrapper = ingredientsDesktopScrollViewRef.current;
      const shouldScrollXsWrapper = !isMdBreakpoint && xsScrollWrapper;
      const shouldScrollMdWrapper = isMdBreakpoint && mdScrollWrapper;

      // ─────────────────────────────────────────────────────────────────

      if (shouldScrollXsWrapper) {
        const scrollDistance = await getIngredientCardScrollDistance({
          scrollAxis: 'x',
          wrapperScrollDistance: mobileWrapperScrollDistance,
          wrapperRef: ingredientsMobileScrollViewRef,
          cardRef,
        });

        await scrollIntoViewIfNeeded({
          scrollView: xsScrollWrapper,
          card: cardRef,
          x: scrollDistance,
        });
      }

      // ─────────────────────────────────────────────────────────────────

      if (shouldScrollMdWrapper) {
        const scrollDistance = await getIngredientCardScrollDistance({
          scrollAxis: 'y',
          wrapperScrollDistance: desktopWrapperScrollDistance,
          wrapperRef: ingredientsDesktopScrollViewRef,
          cardRef,
        });

        mdScrollWrapper.scrollTo({ y: scrollDistance, animated: true });
      }
    },
    [activeIngredientsCardsRefs, minWidth.isMD, scrollIntoViewIfNeeded],
  );

  // ─── EFFECTS ─────────────────────────────────────────────────────

  useEffect(() => {
    setActiveIngredientsCardsRefs(
      generateIngredientsCardsRefsMap(activeIngredientsIds),
    );
  }, [activeIngredientsIds]);

  useEffect(() => {
    const addedIngredientId = getNewIngredientId();

    (async () => {
      await scrollToAddedIngredientCard(addedIngredientId);
    })();
  }, [
    getNewIngredientId,
    prevActiveIngredientsRefs,
    scrollToAddedIngredientCard,
  ]);

  // ────────────────────────────────────────────────────────────────────────────────

  return {
    activeIngredientsCardsRefs,
    ingredientsMobileScrollViewRef,
    ingredientsDesktopScrollViewRef,
    setIngredientsScrollXsWrappersOffset,
    setIngredientsScrollMdWrappersOffset,
  };
};

// ─── UTILS ──────────────────────────────────────────────────────────────────────

const generateIngredientsCardsRefsMap = (
  activeIngredientsIds: readonly string[],
) => {
  return new Map<string, RefObject<View>>(
    activeIngredientsIds.map((id) => [id, createRef<View>()]),
  );
};

const getIngredientCardScrollDistance = async (
  props: GetIngredientCardScrollDistanceProps,
) => {
  const { wrapperRef, cardRef, wrapperScrollDistance, scrollAxis } = props;

  const offset =
    scrollAxis === 'x'
      ? MOBILE_SCROLL_WRAPPER_OFFSET
      : DESKTOP_SCROLL_WRAPPER_OFFSET;

  const measurements = await measureLayoutPosition(wrapperRef, cardRef);
  const { x: cardScrollPositionX, y: cardScrollPositionY } = measurements;

  // element measurements are calculated differently on web and native platforms.
  // we need to include wrapper's already scrolled distance in case "web" platform.
  const shouldUseWrapperScrollOffset = Platform.OS === 'web';
  const normalizedWrapperScrollOffset = shouldUseWrapperScrollOffset
    ? wrapperScrollDistance
    : 0;
  const scrollOffset = normalizedWrapperScrollOffset - offset;
  const cardScrollPosition =
    scrollAxis === 'x' ? cardScrollPositionX : cardScrollPositionY;

  return cardScrollPosition + scrollOffset;
};

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

const MOBILE_SCROLL_WRAPPER_OFFSET = theme.spacing['4'];
const DESKTOP_SCROLL_WRAPPER_OFFSET = 10;

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type UseScrollToAddedIngredientProps = Readonly<{
  activeIngredientModifications: IngredientsModificationsWithQuantities;
}>;

type ScrollIntoViewIfNeededProps = Readonly<{
  scrollView: ScrollView;
  card: RefObject<View>;
  x: number;
}>;

type GetIngredientCardScrollDistanceProps = Readonly<{
  scrollAxis: 'x' | 'y';
  wrapperScrollDistance: number;
  wrapperRef: RefObject<ScrollView>;
  cardRef: RefObject<View>;
}>;

type ScrollEvent = NativeSyntheticEvent<NativeScrollEvent>;

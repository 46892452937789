import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import type { AddressType } from '@sg/garnish';
import {
  AddressTypes,
  BoundScrollProvider,
  JumpNavigationProvider,
  StickyScrollView,
  theme,
  useResponsive,
} from '@sg/garnish';

import {
  FloatingButtons,
  LoadingAnimation,
  NotFoundView,
  PageWrapper,
} from '@order/components';
import type { DeliveryOrderDetailInput } from '@order/graphql';
import {
  useResetLastInteractedMenuStore,
  useSetLastInteractedMenuStore,
} from '@order/LastInteractedStore';
import { useLocalizationContext } from '@order/Localization';
import { useDeliveryDisclosure } from '@order/shared/hooks';
import { useTelemetry } from '@order/Telemetry';

import { useHeaderLeft } from '../../components/Header/Header.hooks';
import type { HeaderIconItems } from '../../components/Header/Header.props';
import type {
  DeliveryMenuScreenProps,
  MenuScreenProps,
  MenuTabStackParamList,
} from '../../navigation/AppNavigation.props';
import { useReorderIfRequired } from '../ReorderingScreen';
import { useReorderState } from '../ReorderingScreen/hooks';
import type { RestaurantQuery } from './GraphQL/Restaurant.query.generated';
import {
  useMenuScreenMountTelemetry,
  useRestaurantDisabledCheck,
} from './MenuScreen.hooks';
import { MenuCategories, MenuCategoriesNav, MenuHero } from './subcomponents';

// ────────────────────────────────────────────────────────────────────────────────

export const MenuScreenContent = (props: MenuScreenContentProps) => {
  const { deliveryDetails, restaurantSlug, restaurantData, navigation } = props;

  const {
    addressId,
    location: deliveryLocation,
    deliveryAddress,
    ETA,
  } = deliveryDetails ?? {};
  const locationSelectionType = (
    deliveryLocation // eslint-disable-line no-nested-ternary
      ? AddressTypes.delivery
      : restaurantData?.isOutpost
        ? AddressTypes.outpost
        : AddressTypes.pickup
  ) as AddressType;

  const { t } = useLocalizationContext();
  const { track } = useTelemetry();

  // ─── Navigation ──────────────────────────────────────────────────

  const { navigate } =
    useNavigation<NativeStackNavigationProp<MenuTabStackParamList, 'Menu'>>();
  const { minWidth, currentBreakpoint } = useResponsive();
  const { reordering: isReordering } = useReorderState();

  useSetLastInteractedMenuStore({ restaurantSlug, addressId });
  // @ts-expect-error TS(2345): Argument of type '{ readonly __typename?: "Restaur... Remove this comment to see the full error message
  useMenuScreenMountTelemetry(restaurantData);
  useReorderIfRequired(restaurantSlug, addressId);

  // ─────────────────────────────────────────────────────────────────────

  const isDelivery = Boolean(ETA);

  const { deliveryDisclosure } = useDeliveryDisclosure({
    variation: 'menu',
    restaurantDeliveryFee: restaurantData?.deliveryFee,
  });

  const shouldShowDeliveryFeeDisclosure =
    deliveryDisclosure !== undefined &&
    isDelivery &&
    restaurantData?.showDeliveryFeeDisclosure;
  const deliveryFeeDisclosure = shouldShowDeliveryFeeDisclosure
    ? deliveryDisclosure
    : '';

  // ─── Helpers ───────────────────────────────────────────────────────

  const monitoredGoToLocations = useCallback(() => {
    track('menu.change_location');
    navigate('Locations');
  }, [navigate, track]);

  // ─────────────────────────────────────────────────────────────────

  // Don't display any categories with no products
  const categories = useMemo(() => {
    const allCategories = restaurantData?.menu?.categories ?? [];

    return allCategories.filter(({ products }) => products.length > 0);
  }, [restaurantData?.menu?.categories]);

  const categoriesIds = useMemo(
    () => categories.map((category) => category.id),
    [categories],
  );

  // ─── Navigation options ──────────────────────────────────────────

  const headerLeftItems = useMemo<HeaderIconItems>(
    () => [
      {
        testID: 'nav-bar.change-locations-btn',
        key: 'change-locations-btn',
        icon: 'IconMap',
        accessibilityLabel: t('menu.hero.change-location-label'),
        onPress: monitoredGoToLocations,
      },
    ],
    [monitoredGoToLocations, t],
  );

  const headerLeftXS = useHeaderLeft({ items: headerLeftItems });
  const shouldShowLocationChangeBtn = minWidth.isSM;

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: shouldShowLocationChangeBtn ? undefined : headerLeftXS,
    });
  }, [headerLeftXS, navigation, shouldShowLocationChangeBtn]);

  // ─── Styles ──────────────────────────────────────────────────────

  const wrapperStyle = minWidth.isSM ? styles.wrapperWithBorderSM : {};

  // ─── Disabled Restaurant ─────────────────────────────────────────

  const { isRestaurantDisabled, disabledRestaurantNotice } =
    useRestaurantDisabledCheck(restaurantData);

  const resetLastInteractedMenuStore = useResetLastInteractedMenuStore();

  const handleDisabledStorePress = useCallback(() => {
    navigate('Locations');
  }, [navigate]);

  const shouldDisplayFloatingButtons = currentBreakpoint.isXS;

  if (isRestaurantDisabled) {
    return (
      <NotFoundView
        btnLabel={t('not-found-view.select-another-location')}
        description={disabledRestaurantNotice}
        onMount={resetLastInteractedMenuStore}
        onBtnPress={handleDisabledStorePress}
      />
    );
  }

  // ─────────────────────────────────────────────────────────────────

  if (isReordering) return <LoadingAnimation />;

  return (
    <BoundScrollProvider>
      <StickyScrollView
        testID="menu-content.categories-container"
        unstuckStyle={wrapperStyle}
      >
        <PageWrapper>
          {!currentBreakpoint.isXS && (
            <MenuHero
              deliveryLocation={deliveryLocation}
              deliveryETA={ETA}
              deliveryAddress={deliveryAddress}
              restaurant={restaurantData}
              locationSelectionType={locationSelectionType}
              goToLocations={monitoredGoToLocations}
            />
          )}
          <JumpNavigationProvider jumpTargetIds={categoriesIds}>
            <MenuCategoriesNav
              restaurantSlug={restaurantSlug}
              categories={categories}
              deliveryFeeDisclosure={deliveryFeeDisclosure}
            />

            <MenuCategories
              categories={categories}
              restaurantSlug={restaurantSlug}
              deliveryDetails={deliveryDetails}
            />
          </JumpNavigationProvider>
        </PageWrapper>
      </StickyScrollView>

      {shouldDisplayFloatingButtons ? (
        <FloatingButtons.Container>
          <FloatingButtons.Bag />
        </FloatingButtons.Container>
      ) : null}
    </BoundScrollProvider>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapperWithBorderSM: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.DARK_KALE,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type MenuScreenContentProps = Readonly<{
  restaurantSlug: string;
  restaurantData: RestaurantQuery['restaurant'];
  deliveryDetails?: DeliveryOrderDetailInput & {
    addressId: string;
    ETA: string;
    addressName: string;
    location: string;
    deliveryAddress: string;
  };
  navigation:
    | MenuScreenProps['navigation']
    | DeliveryMenuScreenProps['navigation'];
}>;

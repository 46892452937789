import React, { type ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';
import { Header } from '@expo/html-elements';
import { BodyText, theme, useResponsive } from '@sg/garnish';

import { LocationResultsListsV2GroupStack } from '../LocationResultsListsV2GroupStack';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsListsV2Group = (
  props: LocationResultsListsV2GroupProps,
) => {
  const { heading, children } = props;

  const { currentBreakpoint } = useResponsive();

  return (
    <View style={styles.groupContainer}>
      {heading || currentBreakpoint.isXS ? (
        <View style={styles.headerContainer}>
          {heading ? (
            <Header>
              <BodyText sizeMatch={['18']}>{heading}</BodyText>
            </Header>
          ) : null}
        </View>
      ) : null}

      <LocationResultsListsV2GroupStack gap={theme.spacing['1']} withDivider>
        {children}
      </LocationResultsListsV2GroupStack>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  groupContainer: {
    marginBottom: theme.spacing[10],
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing[2],
    minHeight: 40,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsListsV2GroupProps = {
  children: ReactNode;
  heading?: string;
};

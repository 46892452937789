import { useCallback } from 'react';
import type { NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { useActor } from '@xstate/react';

import { useGlobalAppState } from '@order/GlobalAppState';

import { logger } from '../utils';

// Dismissing the Reorder modals through native navigation should cancel the reorder flow.
export const useDismissReorder = () => {
  const { reorderMachineRef } = useGlobalAppState();
  const [, sendReorderEvent] = useActor(reorderMachineRef);

  // ─── Reorder Callbacks ───────────────────────────────────────────────

  const dismissReorderFromActiveBagWarning = useCallback(() => {
    logger.info('Dismissing Reorder from activeBagWarning.');
    sendReorderEvent({ type: 'CANCEL_REORDER', step: 'activeBagWarning' });
  }, [sendReorderEvent]);

  const dismissReorderFromLocationSearch = useCallback(() => {
    logger.info('Dismissing Reorder from locationSearch.');
    sendReorderEvent({ type: 'CANCEL_REORDER', step: 'locationSearch' });
  }, [sendReorderEvent]);

  const dismissReorderFromLocationConfirmation = useCallback(() => {
    logger.info('Dismissing Reorder from locationConfirmation.');
    sendReorderEvent({ type: 'CANCEL_REORDER', step: 'locationConfirmation' });
  }, [sendReorderEvent]);

  const dismissReorderFromConflictReview = useCallback(() => {
    logger.info('Dismissing Reorder from conflictReview.');
    sendReorderEvent({ type: 'CANCEL_REORDER', step: 'conflictReview' });
  }, [sendReorderEvent]);

  // ─── Navigation Callback ─────────────────────────────────────────────

  const cancelReorderOnModalClose = useCallback(
    (params: CancelReorderOnModalCloseParams) => {
      const { currentRouteName, previousRouteName } = params;

      const shouldDismissReorder =
        !REORDER_SCREENS.has(currentRouteName) &&
        REORDER_SCREENS.has(previousRouteName);

      if (!shouldDismissReorder) {
        return;
      }

      switch (previousRouteName) {
        case 'ReorderConfirmLocation': {
          dismissReorderFromLocationConfirmation();
          break;
        }

        case 'ReorderChangeLocation': {
          dismissReorderFromLocationSearch();
          break;
        }

        case 'ReorderConflictReview': {
          dismissReorderFromConflictReview();
          break;
        }

        case 'ReorderActiveBagWarning': {
          dismissReorderFromActiveBagWarning();
          break;
        }

        default: {
          break;
        }
      }
    },
    [
      dismissReorderFromActiveBagWarning,
      dismissReorderFromLocationSearch,
      dismissReorderFromLocationConfirmation,
      dismissReorderFromConflictReview,
    ],
  );

  return { cancelReorderOnModalClose };
};

// ─── Constants ───────────────────────────────────────────────────────────────

const REORDER_SCREENS = new Set([
  'ReorderConfirmLocation',
  'ReorderChangeLocation',
  'ReorderConflictReview',
  'ReorderActiveBagWarning',
]);

// ─── Types ───────────────────────────────────────────────────────────────────

type CancelReorderOnModalCloseParams = {
  currentRouteName: string;
  previousRouteName: string;
  previousRouteOptions: NativeStackNavigationOptions | undefined;
  currentRouteOptions: NativeStackNavigationOptions | undefined;
};

import React, { useMemo } from 'react';
import { StyleSheet, View, type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardProgressBar = (
  props: LoyaltyOfferCardProgressBarProps,
) => {
  const { value, maxValue, accessibilityLabel } = props;

  // ─────────────────────────────────────────────────────────────────────

  const valueAsPercentage = (value / maxValue) * 100;
  const valueAsPercentageWithLimits = Math.min(
    Math.max(0, valueAsPercentage),
    100,
  );

  // ─── Styles ──────────────────────────────────────────────────────────

  const progressBarStyle = useMemo<ViewStyle>(
    () => ({ width: `${valueAsPercentageWithLimits}%` }),
    [valueAsPercentageWithLimits],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View
      style={styles.container}
      accessible
      accessibilityRole="progressbar"
      accessibilityLabel={accessibilityLabel}
      accessibilityValue={{
        min: 0,
        max: 100,
        now: valueAsPercentageWithLimits,
      }}
    >
      <View style={[styles.progressBar, progressBarStyle]} />
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const PROGRESS_BAR_HEIGHT = 8;
const PROGRESS_BAR_BORDER_RADIUS = 100;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  // eslint-disable-next-line react-native/no-color-literals
  container: {
    width: '100%',
    height: PROGRESS_BAR_HEIGHT,
    borderRadius: PROGRESS_BAR_BORDER_RADIUS,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    marginTop: theme.spacing['4'],
  },
  progressBar: {
    height: PROGRESS_BAR_HEIGHT,
    borderRadius: PROGRESS_BAR_BORDER_RADIUS,
    backgroundColor: theme.colors.SWEET_CORN,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardProgressBarProps = {
  value: number;
  maxValue: number;
  accessibilityLabel: string;
};

/* istanbul ignore file */

import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNoticeBannersStackContext } from '@sg/garnish';
import { ConflictType } from '@sg/graphql-schema';

import { useTelemetry } from '@order/Telemetry';

import {
  type ReorderOrderLineItemMutation,
  useReorderOrderLineItemMutation,
} from './graphql/ReorderOrderLineItem.generated';
import { useReorderOrderLineItemLocation } from './hooks';
import { reorderOrderLineItemMessages as messages } from './useReorderOrderLineItem.messages';
import { type ReorderOrder } from './useReorderOrderLineItem.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A custom hook that provides various helpers that allows to reorder line item.
 */
export const useReorderOrderLineItem = (
  params: UseReorderOrderLineItemParams,
) => {
  const {
    variation,
    mostRecentOrder,
    productName,
    onSuccess,
    onUnavailableIngredientConflict,
  } = params;

  const { formatMessage } = useIntl();
  const { push: addBanner } = useNoticeBannersStackContext();
  const { track } = useTelemetry();

  // ─── Remote Data ─────────────────────────────────────────────────────

  /**
   * NOTE: We use two different mutations to preserve state, which can be used
   *       to display conflict resolution views and custom errors.
   */

  const [responseReorder, reorder] = useReorderOrderLineItemMutation();
  const [responseReorderIgnoreConflicts, reorderIgnoreConflicts] =
    useReorderOrderLineItemMutation();

  const reorderLineItemData = responseReorder.data?.reorderLineItem;

  const isFetching =
    responseReorder.fetching || responseReorderIgnoreConflicts.fetching;

  const unavailableIngredients = getUnavailableIngredients(reorderLineItemData);

  // ─── Derived Data ────────────────────────────────────────────────────

  const targetLocation = useReorderOrderLineItemLocation({ mostRecentOrder });
  const { restaurantId, deliveryOrderDetails } = targetLocation;

  const hasDeliveryDetails = deliveryOrderDetails?.address?.id !== undefined;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const trackReorderLineItem = useCallback(() => {
    if (variation === 'favorite') {
      track('reorder.favorites.tapped');

      return;
    }

    track('reorder.addtobag.tapped');
  }, [track, variation]);

  const reorderLineItem = useCallback(
    async (lineItemId: string, ignoreConflicts = false) => {
      if (!restaurantId) return;

      if (!ignoreConflicts) {
        trackReorderLineItem();
      }

      const reorderMutation = ignoreConflicts
        ? reorderIgnoreConflicts
        : reorder;

      const reorderResponse = await reorderMutation({
        input: {
          id: lineItemId,
          ignoreConflicts,
          destinationRestaurantId: restaurantId,
          ...(hasDeliveryDetails
            ? {
                deliveryOrderDetails: {
                  addressId: deliveryOrderDetails.address.id,
                  tip: deliveryOrderDetails.tip,
                  deliveryFee: deliveryOrderDetails.deliveryFee,
                  vendor: deliveryOrderDetails.vendor,
                  vendorRestaurantId: deliveryOrderDetails.vendorRestaurantId,
                },
              }
            : {}),
        },
      });

      // ─────────────────────────────────────────────────────────────────────

      const data = reorderResponse.data?.reorderLineItem;
      const responseTypename = data?.__typename;
      const hasSuccessfullyReordered = responseTypename === 'ReorderSuccess';

      // ─── Successful Reorder ──────────────────────────────────────────────

      if (hasSuccessfullyReordered) {
        onSuccess?.();

        return;
      }

      // ─── Unavailable Product ─────────────────────────────────────────────

      const isUnavailableProduct = checkIfProductIsUnavailable(data);

      if (isUnavailableProduct) {
        const product = productName || formatMessage(messages.product);

        addBanner({
          palette: 'caution',
          text: formatMessage(messages.unavailableProductMessage, { product }),
          autoHideTimeout: 3500,
        });

        return;
      }

      // ─── Unavailable Ingredients ─────────────────────────────────────────

      const missingIngredients = getUnavailableIngredients(data);
      const hasUnavailableIngredients = missingIngredients.length > 0;

      if (hasUnavailableIngredients) {
        onUnavailableIngredientConflict();

        return;
      }

      // ─── Generic Error ───────────────────────────────────────────────────

      addBanner({
        palette: 'caution',
        text: formatMessage(messages.failedToReorderMessage),
        autoHideTimeout: 3500,
      });
    },
    [
      restaurantId,
      reorderIgnoreConflicts,
      reorder,
      hasDeliveryDetails,
      deliveryOrderDetails,
      addBanner,
      formatMessage,
      trackReorderLineItem,
      onSuccess,
      productName,
      onUnavailableIngredientConflict,
    ],
  );

  // ─────────────────────────────────────────────────────────────────────

  return {
    reorderLineItem,
    reorderLineItemData,
    isFetching,
    targetLocation,
    unavailableIngredients,
  };
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * Returns unavailable ingredients list (if applicable).
 */
function getUnavailableIngredients(
  data: ReorderOrderLineItemMutation['reorderLineItem'] | undefined,
) {
  const hasConflicts = data?.__typename === 'ReorderConflict';
  const conflicts = hasConflicts ? data?.conflicts : [];
  const hasOneConflict = conflicts.length === 1;
  const mainConflict = conflicts[0];

  // ─── "Nothing is available" case ─────────────────────────────────────

  const noIngredientsAreAvailable = Boolean(
    hasConflicts && data?.isNothingAvailable,
  );

  if (noIngredientsAreAvailable) {
    return [];
  }

  // ─── Unavailable ingredients ─────────────────────────────────────────

  const hasUnavailableIngredients =
    hasOneConflict && mainConflict.type === ConflictType.IngredientUnavailable;

  if (hasUnavailableIngredients) {
    return mainConflict.unavailableIngredients;
  }

  // ─────────────────────────────────────────────────────────────────────

  return [];
}

/**
 * Returns a boolean indicating whether the product is temporarily unavailable.
 */
function checkIfProductIsUnavailable(
  data: ReorderOrderLineItemMutation['reorderLineItem'] | undefined,
): boolean {
  const hasConflicts = data?.__typename === 'ReorderConflict';

  if (!hasConflicts) return false;

  return data.isNothingAvailable;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type UseReorderOrderLineItemParams = {
  variation: 'order' | 'favorite';
  productName: string | undefined;
  mostRecentOrder: ReorderOrder | undefined;
  onSuccess?: () => void;
  onUnavailableIngredientConflict: () => void;
};

import React, { type ReactNode } from 'react';
import { TextLink } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsSubheadingLink = (
  props: GiftCardsSubheadingLinkProps,
) => {
  const { children, onPress } = props;

  return (
    <TextLink sizeMatch={['14', '18']} onPress={onPress}>
      {children}
    </TextLink>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardsSubheadingLinkProps = {
  children: ReactNode;
  onPress: () => void;
};

import React, { useCallback } from 'react';
import type { RouteProp } from '@react-navigation/native';
import {
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import type { AddressType } from '@sg/garnish';

import { useCart } from '@order/hooks';
import { useLastValidOrder } from '@order/Order';

import { ConfirmLocation } from '../../../../components/ConfirmLocation';
import type { ModalStackParamList } from '../../../../navigation/AppNavigation.props';
import { useLocationConfirm } from '../../hooks';
import { useOrderProductConfirmLocationScreenData } from './hooks/useOrderProductConfirmLocationScreenData';

export const OrderProductConfirmLocationScreen = () => {
  const {
    params: { productSlug: baseProductSlug },
  } =
    useRoute<RouteProp<ModalStackParamList, 'OrderProductConfirmLocation'>>() ??
    {};
  const navigation = useNavigation();
  const isFocused = useIsFocused();

  const { lastValidOrder: lastPlacedOrder } = useLastValidOrder();
  const { cart, deliveryDetails, cartOrderType, isCartEmpty } = useCart();

  const lastPlacedOrderDeliveryDetail = lastPlacedOrder?.deliveryOrderDetail;
  const hasCart = !isCartEmpty;
  const hasLocationHistory = hasCart || lastPlacedOrder;

  const { region, restaurant, deliveryAddress, pins, isFetching } =
    useOrderProductConfirmLocationScreenData({
      restaurantSlug: hasCart
        ? cart?.restaurant.id
        : lastPlacedOrder?.restaurant.id,
      addressId: hasCart
        ? deliveryDetails?.address?.id
        : lastPlacedOrder?.deliveryOrderDetail?.address.id,
    });

  const deliveryVendorName = hasCart
    ? deliveryDetails.vendor
    : lastPlacedOrderDeliveryDetail?.vendor;
  const deliveryVendorRestaurantId = hasCart
    ? deliveryDetails.vendorRestaurantId
    : lastPlacedOrderDeliveryDetail?.vendorRestaurantId;
  const deliveryFee = hasCart
    ? deliveryDetails.deliveryFee
    : lastPlacedOrderDeliveryDetail?.deliveryFee;
  const orderType = (
    hasCart ? cartOrderType : lastPlacedOrder?.orderType
  )?.toLowerCase() as AddressType;

  // ─────────────────────────────────────────────────────────────────────

  const { isFetchingProduct, isAddingLineItemToCart, handleLocationConfirm } =
    useLocationConfirm({
      baseProductSlug,
      deliveryFee,
      deliveryVendorName,
      deliveryVendorRestaurantId,
      restaurantId: restaurant?.id,
      restaurantSlug: restaurant?.slug,
      deliveryAddressId: deliveryAddress?.id,
    });

  // ─────────────────────────────────────────────────────────────────────

  const navigateToSearchLocation = useCallback(() => {
    navigation.navigate('Modal', {
      screen: 'OrderProductSearchLocation',
      params: { productSlug: baseProductSlug },
    });
  }, [baseProductSlug, navigation]);

  const handleDismiss = useCallback(() => {
    navigation.getParent()?.goBack();
  }, [navigation]);

  // ─────────────────────────────────────────────────────────────────────

  if (!isFocused) return null;

  return (
    <ConfirmLocation
      deliveryAddress={deliveryAddress}
      isFetching={isFetching}
      orderType={orderType === 'delivery' ? 'delivery' : 'pickup'}
      // @ts-expect-error TS(2322): Type '{ readonly __typename?: "Restaurant" | undef... Remove this comment to see the full error message
      restaurant={restaurant}
      region={region}
      pins={pins}
      isLoading={isFetchingProduct || isAddingLineItemToCart}
      onContinue={handleLocationConfirm}
      onClose={handleDismiss}
      onMoreLocations={hasCart ? undefined : navigateToSearchLocation}
      hasError={!region || !restaurant || !hasLocationHistory}
    />
  );
};

import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { Container } from '@sg/garnish';

import {
  useHasFailedToFetchCustomerData,
  useIsLoggedIn,
  useIsLoggingIn,
  useSendLoginToAuthMachine,
} from '@order/AuthMachine';
import { removeTokens, useAzureAuth } from '@order/AzureAuth';
import { LoadingAnimation } from '@order/components';

import { useCloseActiveScreensOnAuthStatusChange } from '../../../../navigation';
import { JoinOrSignInErrorView } from '../JoinOrSignInErrorView';
import { JoinOrSignInForm } from '../JoinOrSignInForm';

// ─────────────────────────────────────────────────────────────────────────────

export const JoinOrSignInScreenContentHybridFlow = () => {
  const isLoggedIn = useIsLoggedIn();
  const isLoggingIn = useIsLoggingIn();
  const hasFailedToFetchCustomerData = useHasFailedToFetchCustomerData();

  const closeActiveScreensOnAuthStatusChange =
    useCloseActiveScreensOnAuthStatusChange();
  const sendLoginAction = useSendLoginToAuthMachine();

  const {
    state,
    initializeAuthenticationFlow,
    resetAuthenticationFlow,
    restartAuthenticationFlow,
    completeAuthenticationFlow,
    submitAccountData,
    context,
  } = useAzureAuth();

  const {
    isFillAccountDataStep,
    isCreatingNewAccount,
    isAuthenticationFailed,
    shouldRedirect,
  } = state;
  const { email } = context;

  // ─── Flags ───────────────────────────────────────────────────────────

  const isRedirecting = isLoggedIn && shouldRedirect;
  const hasError = isAuthenticationFailed || hasFailedToFetchCustomerData;

  // ─── Effects ─────────────────────────────────────────────────────────

  /**
   * Initialize Azure authentication machine on screen focus.
   *
   * NOTE: Resets the machine on blur.
   */
  useFocusEffect(
    useCallback(() => {
      if (isLoggedIn || isLoggingIn) return;

      initializeAuthenticationFlow();

      return () => {
        resetAuthenticationFlow();
      };
    }, [
      initializeAuthenticationFlow,
      isLoggedIn,
      isLoggingIn,
      resetAuthenticationFlow,
    ]),
  );

  /**
   * Inform the auth machine that the user has successfully signed in.
   */
  useLayoutEffect(() => {
    if (!shouldRedirect) return;

    sendLoginAction();
  }, [sendLoginAction, shouldRedirect]);

  /**
   * Reset the initial state of navigation when changing the authentication status.
   */
  useLayoutEffect(() => {
    if (!shouldRedirect || !isLoggedIn) return;

    closeActiveScreensOnAuthStatusChange();
  }, [closeActiveScreensOnAuthStatusChange, isLoggedIn, shouldRedirect]);

  /**
   * Redirect the user after successful authorization
   */
  useEffect(() => {
    if (!shouldRedirect || !isLoggedIn) return;

    completeAuthenticationFlow();
  }, [completeAuthenticationFlow, isLoggedIn, shouldRedirect]);

  /**
   * Cleanup state if the customer data fetching has failed.
   */
  useEffect(() => {
    if (!hasFailedToFetchCustomerData) return;

    void removeTokens();
  }, [hasFailedToFetchCustomerData]);

  // ─────────────────────────────────────────────────────────────────────

  if (isRedirecting) {
    return null;
  }

  if (isFillAccountDataStep) {
    return (
      <JoinOrSignInForm
        email={email}
        isLoading={isCreatingNewAccount}
        // @ts-expect-error TS(2322): Type '(input: SubmitAccountDataInput) => void' is ... Remove this comment to see the full error message
        onSubmit={submitAccountData}
        onSignInUsingDifferentAccount={restartAuthenticationFlow}
      />
    );
  }

  if (hasError) {
    return (
      <JoinOrSignInErrorView
        onTryAgainButtonPress={restartAuthenticationFlow}
      />
    );
  }

  return (
    <Container style={styles.container} wrapperStyle={styles.outerContainer}>
      <LoadingAnimation size="large" />
    </Container>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

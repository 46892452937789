import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { LabelText, theme } from '@sg/garnish';

/**
 * Tag for loyalty rewards only usable in store
 */
export const LoyaltyRewardInStoreOnlyTag = () => {
  return (
    <LabelText sizeMatch={['12']} style={styles.container}>
      <FormattedMessage {...messages.inStoreOnly} />
    </LabelText>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  inStoreOnly: {
    defaultMessage: 'In-store only',
    description: 'Loyalty |ƒ Rewards | In-store only tag',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-start',
    borderWidth: 1,
    borderRadius: theme.radius.medium,
    borderColor: theme.colors.CHARCOAL,
    color: theme.colors.CHARCOAL,
    paddingVertical: theme.spacing['1'],
    paddingHorizontal: theme.spacing['2'],
  },
});

import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, Modal, TextLinkify, theme, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const AccountDeletionSecondStep = (props: SecondStepProps) => {
  const {
    body,
    isDeletingAccount,
    hasCancelledSubscription,
    requestAccountDeletion,
  } = props;

  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  return (
    <>
      <Modal.Headline>{t('account.delete.headline')}</Modal.Headline>
      {hasCancelledSubscription ? (
        <Modal.Subhead>{t('account.delete.subhead.step2')}</Modal.Subhead>
      ) : null}
      <Modal.BodyText>
        <TextLinkify>{body}</TextLinkify>
      </Modal.BodyText>

      <Modal.Footer withoutTopBorder>
        <Button
          testID="account.delete.confirm"
          size={match(['medium', 'large'])}
          style={styles.deleteAccountCTA}
          isLoading={isDeletingAccount}
          onPress={requestAccountDeletion}
        >
          {t('account.delete.confirm')}
        </Button>
      </Modal.Footer>
    </>
  );
};

type SecondStepProps = Readonly<{
  body: string;
  isDeletingAccount: boolean;
  hasCancelledSubscription: boolean;
  requestAccountDeletion: () => void;
}>;

const styles = StyleSheet.create({
  deleteAccountCTA: {
    marginTop: theme.spacing['4'],
  },
});

import React, { useRef } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { Icon, type IconName, theme, usePressableState } from '@sg/garnish';

export const HomeHeroCta = (props: HomeHeroCtaProps) => {
  const ref = useRef(null);
  const { isInteracting } = usePressableState(ref);
  const interactiveStyle = isInteracting
    ? styles.heroCtaInteractiveState
    : null;

  return (
    <Pressable
      ref={ref}
      accessibilityRole="button"
      accessibilityLabel={props.accessibilityLabel}
      style={[styles.heroCta, interactiveStyle]}
      onPress={props.onPress}
    >
      <Icon name={props.iconName} width={40} height={40} />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  heroCta: {
    width: 48,
    height: 48,
    backgroundColor: theme.colors.OATMEAL,
    padding: theme.spacing['1'],
    borderRadius: theme.radius.xxxlarge,
    ...theme.elevations['4'],
  },
  heroCtaInteractiveState: {
    backgroundColor: theme.colors.QUINOA_HOVER,
  },
});

type HomeHeroCtaProps = {
  accessibilityLabel: string;
  iconName: IconName;
  onPress: () => void;
};

import { defineMessages } from 'react-intl';

export const giftCardConfirmationDeliveryInfoMessages = defineMessages({
  messageOptionText: {
    defaultMessage:
      'Send your gift at any time and be sure to confirm that they receive it!',
    description:
      'Gift Card Confirmation Screen | Delivery Info | "Message" option text',
  },
  emailOptionText: {
    defaultMessage:
      'Your gift was sent to {email}! A receipt has been sent to you.',
    description:
      'Gift Card Confirmation Screen | Delivery Info | "Email" option text',
  },
  scheduledEmailOptionText: {
    defaultMessage:
      'Your gift will be sent to {email} on {date} at {time}. A receipt has been sent to you.',
    description:
      'Gift Card Confirmation Screen | Delivery Info | "Scheduled Email" option text',
  },
});

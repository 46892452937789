import React, { useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import {
  IllusBread_2,
  IllusFall,
  IllusFoodOnItsWay,
  IllusSeasonal,
  IllusSummerEarly,
} from '../../../assets';
import { getItemByIndex, getRandomItem } from '../../../utils';
import { Image } from '../../Image';
import type { ChallengeImageProps } from '../ChallengeCard.types';

const images = [
  IllusFall,
  IllusFoodOnItsWay,
  IllusSummerEarly,
  IllusSeasonal,
  IllusBread_2,
];

export const ChallengeImage = ({ index }: ChallengeImageProps) => {
  const image =
    index === undefined ? getRandomItem(images) : getItemByIndex(images, index);

  const imageRef = useRef(image);

  return (
    <View style={styles.wrapper}>
      <Image
        style={styles.image}
        source={imageRef.current}
        contentFit="contain"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    alignSelf: 'center',
    paddingBottom: theme.spacing['4'],
  },
  image: {
    width: 200,
    height: 200,
  },
});

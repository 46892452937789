import React, { useCallback } from 'react';
import type { AddressType, IconName } from '@sg/garnish';
import {
  AddressTypes,
  ListAction,
  ListActionPressable,
  switchcase,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type { PartialRestaurant } from '../../graphql/partials';
import { useActiveRouteName } from '../../navigation';
import { useChangeLocation } from '../../screens/ReorderingScreen/hooks';
import { useShouldFinishReorderInMenu } from '../../screens/ReorderingScreen/ReorderingScreen.navigation';

export const RestaurantListAction = (props: RestaurantListActionProps) => {
  const {
    orderId,
    orderType,
    restaurant,
    canChangeLocation = () => true,
  } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const isOnMenuScreens = useShouldFinishReorderInMenu();
  const isOnCheckout = useActiveRouteName() === 'Checkout';
  const changeLocation = useChangeLocation();
  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();

  // ─── Restaurant Info ─────────────────────────────────────────────────

  const {
    id: restaurantId,
    slug: restaurantSlug,
    name: restaurantName,
    address: restaurantAddress,
    city: restaurantCity,
    state: restaurantState,
    zipCode: restaurantZipCode,
  } = restaurant ?? {};
  const secondAddressLine = `${restaurantCity}, ${restaurantState}, ${restaurantZipCode}`;
  const accessibilityLabel = t('checkout.restaurant-change-location', {
    location: restaurantName,
  });

  // ─── Callbacks ───────────────────────────────────────────────────────

  const handlePress = useCallback(() => {
    if (!canChangeLocation()) return;
    changeLocation({
      remainOnCheckout: isOnCheckout,
      finishOnMenu: isOnMenuScreens,
      orderId,
      orderType,
      deliveryOrderDetail: undefined,
      restaurantId,
      restaurantSlug,
      restaurantName,
      userInitiated: true,
    });
  }, [
    canChangeLocation,
    changeLocation,
    isOnMenuScreens,
    isOnCheckout,
    orderId,
    orderType,
    restaurantId,
    restaurantSlug,
    restaurantName,
  ]);

  return (
    <ListActionPressable
      title={restaurantName}
      accessibilityLabel={accessibilityLabel}
      withCaret={currentBreakpoint.isXS}
      iconName={getIconName(orderType)}
      onPress={handlePress}
    >
      <ListAction.Text>{restaurantAddress}</ListAction.Text>
      <ListAction.Text>{secondAddressLine}</ListAction.Text>
    </ListActionPressable>
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

const getIconName = (orderType: AddressType): IconName => {
  return switchcase({
    [AddressTypes.pickup]: 'IconLocationPin',
    [AddressTypes.outpost]: 'IconLocationPin',
    [AddressTypes.delivery]: 'IconSweetgreen',
  })('IconSweetgreen')(orderType) as IconName;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type RestaurantListActionProps = Readonly<{
  orderId?: string;
  orderType: AddressType;
  restaurant?: Partial<PartialRestaurant>;
  canChangeLocation?: () => boolean;
}>;

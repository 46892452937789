import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { BodyText, Button, IllusEmployeeBag, Image, theme } from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';

import { giftCardOrdersNoHistoryViewMessages as messages } from './GiftCardOrdersNoHistoryView.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardOrdersNoHistoryView = () => {
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const { track } = useTelemetry();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToGiftCardsScreen = useCallback(() => {
    track('gift.history_cta_tapped');

    navigate('MainTabs', {
      screen: 'GiftCardsTab',
      params: { screen: 'GiftCards' },
      initial: true,
    });
  }, [navigate, track]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <Image
        source={IllusEmployeeBag}
        aria-label={formatMessage(messages.illustrationAlt)}
        style={styles.illustration}
        contentFit="contain"
      />

      <View style={styles.innerContainer}>
        <BodyText bold sizeMatch={['16']} style={styles.text}>
          <FormattedMessage {...messages.title} />
        </BodyText>

        <BodyText sizeMatch={['16']} style={styles.text}>
          <FormattedMessage {...messages.text} />
        </BodyText>

        <Button
          size="large"
          aria-label={formatMessage(messages.sendGiftCardCtaA11yLabel)}
          onPress={navigateToGiftCardsScreen}
          style={styles.cta}
        >
          <FormattedMessage {...messages.sendGiftCardCtaLabel} />
        </Button>
      </View>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingVertical: theme.spacing['12'],
  },
  innerContainer: {
    maxWidth: '100%',
    rowGap: theme.spacing['6'],
  },
  illustration: {
    width: 200,
    height: 200,
  },
  text: {
    textAlign: 'center',
  },
  cta: {
    width: 342,
    maxWidth: '100%',
  },
});

import { take, uniqBy } from 'lodash';
import { type Client } from 'urql';

import { type Location } from '../../LocationSearch.types';
import {
  recentLocationsQuery,
  recentLocationsWithDisclosureQuery,
} from '../../queries';
import { mapOrderLocation } from './recent-locations.helpers';

// ─────────────────────────────────────────────────────────────────────────────

export async function getRecentLocations(
  params: GetRecentLocationsParams,
): Promise<{ recentLocations: Location[] }> {
  const {
    client,
    shouldUseDeliveryDisclosureFields,
    shouldUseOutpostDisclosureFields,
  } = params;

  const query =
    shouldUseDeliveryDisclosureFields || shouldUseOutpostDisclosureFields
      ? recentLocationsWithDisclosureQuery
      : recentLocationsQuery;

  const { data } = await query(client);
  const resolvedData = data?.ordersV2;

  const recentOrders =
    resolvedData?.__typename === 'OrdersResponseSuccess'
      ? resolvedData.orders
      : undefined;

  if (!recentOrders) {
    return { recentLocations: [] };
  }

  const recentOrdersList: Location[] = recentOrders
    .filter(checkIfNonNullable)
    .map(mapOrderLocation);

  const uniqueLocations = take(
    uniqBy(recentOrdersList, (order) =>
      order.addressId ? order.addressId : order.restaurantId,
    ),
    3,
  );

  return { recentLocations: uniqueLocations };
}

// ─────────────────────────────────────────────────────────────────────────────

function checkIfNonNullable<Value>(value: Value | null): value is Value {
  return value !== null;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type GetRecentLocationsParams = {
  client: Client;
  shouldUseDeliveryDisclosureFields: boolean;
  shouldUseOutpostDisclosureFields: boolean;
};

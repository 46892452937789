import type { ChallengeCardVariantsType } from './ChallengeCard.types';

export const ChallengeCardVariants: Record<
  ChallengeCardVariantsType,
  ChallengeCardVariantsType
> = {
  New: 'New',
  Ongoing: 'Ongoing',
  Completed: 'Completed',
};

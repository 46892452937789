import React, { type PropsWithChildren } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Pressable, StyleSheet } from 'react-native';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuCategoriesGridProductCardPressable = (
  props: MenuCategoriesGridProductCardPressableProps,
) => {
  const { productName, children, isDisabled, onPress } = props;

  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const a11yLabel = formatMessage(messages.a11yLabel, {
    product_name: productName,
  });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      accessibilityRole="button"
      disabled={isDisabled}
      onPress={onPress}
      aria-label={a11yLabel}
      style={styles.container}
    >
      {children}
    </Pressable>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  a11yLabel: {
    defaultMessage: 'Add 1 {product_name} to your bag',
    description: 'Menu | Categories grid | Product | Pressable card a11y label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuCategoriesGridProductCardPressableProps = PropsWithChildren<{
  productName: string;
  isDisabled?: boolean;
  onPress: () => void;
}>;

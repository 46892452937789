import React, { memo } from 'react';
import { StyleSheet } from 'react-native';

import { IllusEmpty_2 } from '../../../assets';
import { useResponsive } from '../../../hooks';
import type { CloudinaryTransformConfig } from '../../Image';
import { FallbackImage } from '../../Image';

export const ProductCardImage = memo((props: ProductCardImageProps) => {
  const { baseUrl, 'aria-label': ariaLabel } = props;
  const { match } = useResponsive();

  const cloudinaryConfig = CARD_IMG_TRANSFORM_CONFIG;

  return (
    <FallbackImage
      baseUrl={baseUrl}
      defaultImage={IllusEmpty_2}
      contentFit="contain"
      aria-label={ariaLabel}
      style={match([styles.imageXS_SM, styles.imageMD_LG])}
      cloudinaryConfig={cloudinaryConfig}
    />
  );
});

// ─── Constants ───────────────────────────────────────────────────────────────

const CARD_IMG_TRANSFORM_CONFIG: CloudinaryTransformConfig = [
  { crop: 'crop', height: 0.6, width: 0.4 },
  { width: 310 },
];

const IMAGE_SIZE_XS_SM = 311;
const IMAGE_SIZE_MD_LG = 274;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  imageXS_SM: {
    width: '100%',
    height: IMAGE_SIZE_XS_SM,
  },
  imageMD_LG: {
    width: '100%',
    height: IMAGE_SIZE_MD_LG,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ProductCardImageProps = Readonly<{
  baseUrl: string;
  'aria-label': string;
}>;

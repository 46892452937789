import * as DateFns from 'date-fns';

import { ignoreTimezone } from '@order/utils';

import type { OrderStatus } from '../../../graphql/types';
import { FlattenedOrderStatuses } from '../../../graphql/types';
import { getOrderTime } from './getOrderTime';

/**
 * It is possible for an order to be stuck in the received state
 * way passed the time it is supposed to have been completed.
 *
 * In this scenario we choose to show the order as completed
 * so as to cause the least amount of friction for the customer.
 *
 * This helper will overwrite non-failed order statuses to completed
 * if the order wanted time is over three hours past.
 */
export const overwriteOrderStatus = (orderStatus: OrderStatus) => {
  const order = orderStatus?.order ?? {};
  const status = orderStatus?.flattenedOrderStatus;

  const orderTime = order ? getOrderTime(order) ?? '' : '';
  const orderTimeDate = orderTime ? ignoreTimezone(orderTime) : new Date();

  // Don't overwrite order status for invalid wanted times.
  if (!orderTime) return orderStatus.flattenedOrderStatus;

  // checking if the order is overdue (not in a failed state and 3hrs over time)
  const threeHoursAgo = DateFns.subHours(new Date(), 3);
  const isOverdue = orderTimeDate
    ? DateFns.isBefore(orderTimeDate, threeHoursAgo)
    : false;

  const canBeOverdue = status !== FlattenedOrderStatuses.Failed;

  // overwrite the status to completed when the order is overdue.
  if (canBeOverdue && isOverdue) return FlattenedOrderStatuses.Completed;

  // no changes needed to the remote status.
  return orderStatus.flattenedOrderStatus;
};

import React, { type ComponentProps } from 'react';
import { HStack, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoButtonsContainer = (
  props: ComponentProps<typeof HStack>,
) => {
  const { children, ...restProps } = props;

  return (
    <HStack itemsPerRow={2} gap={theme.spacing['4']} {...restProps}>
      {children}
    </HStack>
  );
};

export const rateOrder = {
  'rate-order.title': 'Rate your order',
  'rate-order.subtitle.full':
    '{products} from {restaurant} on {wantedTime, date, medium} at {wantedTime, time, short}',
  'rate-order.subtitle.no-products':
    'From {restaurant} on {wantedTime, date, medium} at {wantedTime, time, short}',
  'rate-order.subtitle.no-restaurant':
    '{products} on {wantedTime, date, medium} at {wantedTime, time, short}',
  'rate-order.subtitle.no-time': '{products} from {restaurant}',
  'rate-order.subtitle.only-products': '{products}',
  'rate-order.subtitle.only-restaurant': 'From {restaurant}',
  'rate-order.subtitle.only-time':
    'On {wantedTime, date, medium} at {wantedTime, time, short}',
  'rate-order.hint': 'Choose all that apply',
  'rate-order.stars.title.1': 'Awful',
  'rate-order.stars.title.2': 'Bad',
  'rate-order.stars.title.3': 'Disappointing',
  'rate-order.stars.title.4': 'Pretty good, but...',
  'rate-order.stars.title.5': 'Perfection',
  'rate-order.call-to-action': 'Submit',
  'rate-order.response.success':
    "Thank you! Your feedback means a lot. We're always hungry to improve your experience.",

  // ─── Not found order view ────────────────────

  'rate.order.not-found-order-view.title': "We couldn't find this order.",
  'rate.order.not-found-order-view.subtitle':
    'Please select another order to rate.',

  // ─── Pending order view ──────────────────────

  'rate.order.pending-order-view.title': 'This order is still in progress.',
  'rate.order.pending-order-view.subtitle':
    'Come back to rate this order once it has been completed.',

  // ─── Rated order view ────────────────────────

  'rate.order.rated-order-view.title': 'You’ve already rated this order.',
  'rate.order.rated-order-view.subtitle':
    'Thank you! Your feedback means a lot.',

  // ─── Rate Order Errors ───────────────────────────────────────

  'rate-order.response.failure':
    'We were unable to submit your feedback. Please try again.',
};

import React, { type ComponentProps, useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { BalanceCard, IllusCredit_2, theme, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { RedeemGiftCardForm } from '../RedeemGiftCardForm';
import {
  GiftCardAccountSectionAddCardCta,
  GiftCardAccountSectionContainer,
  GiftCardAccountSectionGiftCardTotal,
} from './components';
import { useGiftCardBalance } from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardAccountSection = (props: GiftCardAccountSectionProps) => {
  const { giftCardCode, giftCardRegistrationCode } = props;

  const { minWidth } = useResponsive();
  const { balance, isFetching } = useGiftCardBalance();

  // ─────────────────────────────────────────────────────────────────────

  if (minWidth.isSM) {
    return (
      <GiftCardAccountSectionSM
        giftCardCode={giftCardCode}
        giftCardRegistrationCode={giftCardRegistrationCode}
        giftCardBalance={balance}
        isFetching={isFetching}
      />
    );
  }

  return (
    <GiftCardAccountSectionXS
      giftCardCode={giftCardCode}
      giftCardRegistrationCode={giftCardRegistrationCode}
      giftCardBalance={balance}
      isFetching={isFetching}
    />
  );
};

// ─── Responsive Components ───────────────────────────────────────────────────

export const GiftCardAccountSectionXS = (
  props: GiftCardAccountSectionInnerProps,
) => {
  const {
    giftCardCode,
    giftCardRegistrationCode,
    isFetching,
    giftCardBalance,
  } = props;

  return (
    <GiftCardAccountSectionContainer>
      <GiftCardAccountSectionGiftCardTotal
        giftCardCode={giftCardCode}
        giftCardRegistrationCode={giftCardRegistrationCode}
        totalAmount={giftCardBalance}
        isFetching={isFetching}
      />
      <GiftCardAccountSectionAddCardCta />
    </GiftCardAccountSectionContainer>
  );
};

export const GiftCardAccountSectionSM = (
  props: GiftCardAccountSectionInnerProps,
) => {
  const {
    giftCardCode,
    giftCardRegistrationCode,
    isFetching,
    giftCardBalance,
  } = props;

  const { track } = useTelemetry();
  const { t } = useLocalizationContext();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onRedeemSubmit = useCallback(() => {
    track('account.gift.redeem_form_submit');
  }, [track]);

  const onRedeemError = useCallback<OnRedeemError>(
    (payload) => {
      const { systemError, userError } = payload;

      track('account.gift.redeem_failure', { systemError, userError });
    },
    [track],
  );

  const onRedeemSuccess = useCallback(() => {
    track('account.gift.redeem_success');
  }, [track]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <GiftCardAccountSectionContainer>
      <View style={styles.cardContainer}>
        <BalanceCard
          title={t('account.gift-cards.gift-card-total')}
          balance={giftCardBalance}
          illustrationSource={IllusCredit_2}
          isLoading={isFetching}
        />
      </View>

      <View style={styles.formContainer}>
        <RedeemGiftCardForm
          giftCardCode={giftCardCode}
          giftCardRegistrationCode={giftCardRegistrationCode}
          onRedeemSubmit={onRedeemSubmit}
          onRedeemError={onRedeemError}
          onRedeemSuccess={onRedeemSuccess}
        />
      </View>
    </GiftCardAccountSectionContainer>
  );
};

//

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cardContainer: {
    alignItems: 'center',
    paddingTop: theme.spacing['6'],
    paddingBottom: theme.spacing['12'],
  },
  formContainer: {
    paddingBottom: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardAccountSectionProps = {
  giftCardCode?: string;
  giftCardRegistrationCode?: string;
};

type GiftCardAccountSectionInnerProps = {
  giftCardBalance: string;
  isFetching: boolean;
  giftCardCode?: string;
  giftCardRegistrationCode?: string;
};

type OnRedeemError = NonNullable<RedeemGiftCardFormProps['onRedeemError']>;

type RedeemGiftCardFormProps = ComponentProps<typeof RedeemGiftCardForm>;

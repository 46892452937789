/* istanbul ignore file */

import AsyncStorage from '@react-native-async-storage/async-storage';
import { isPast, isValid } from 'date-fns';

// ─────────────────────────────────────────────────────────────────────────────

const { getItem } = AsyncStorage;

/**
 * A small helper to check whether location warning is required.
 *
 * Some locations have warnings that needs to be shown before navigating
 * to its menu screen. Some of those warnings have expiration dates,
 * so we need to check whether the corresponding timeout has been passed or not.
 */
export async function checkIfNewLocationWarningIsRequired(locationId: string) {
  const asyncStorageKey = getLocationWarningAsyncStorageKey(locationId);
  const asyncStorageRecord = (await getItem(asyncStorageKey)) ?? '{}';

  const expirationStorageRecord: Partial<LocationWarningStorageRecord> =
    JSON.parse(asyncStorageRecord);
  const parsedExpirationDate = expirationStorageRecord?.expiration ?? '';
  const expirationDate = new Date(parsedExpirationDate);

  const hasValidExpirationDate = isValid(expirationDate);
  const hasLocationWarningExpired = isPast(expirationDate);

  return !hasValidExpirationDate || hasLocationWarningExpired;
}

export function stringifyLocationWarningExpirationDate(date: Date) {
  const expirationRecord: LocationWarningStorageRecord = {
    expiration: date.toString(),
  };

  return JSON.stringify(expirationRecord);
}

export function getLocationWarningAsyncStorageKey(locationId: string) {
  return `restaurant-warning-dialog-timeout-${locationId}`;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationWarningStorageRecord = Readonly<{
  expiration: string;
}>;

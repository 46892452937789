import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { ViewProps } from 'react-native';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * An optional `Modal` provider for tracking things like active (aka open)
 * modals, etc.
 *
 * Usage:
 *
 * 1. Put the provider at the root of your application.
 * 2. Use the `useModalContext` hook to access the context.
 */
export const ModalProvider = (props: ModalProviderProps) => {
  const { children } = props;

  // ─── State ───────────────────────────────────────────────────────────

  const [activeModal, setActiveModal] = useState<ActiveModal | undefined>(
    undefined,
  );

  const hasActiveModal = activeModal !== undefined;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const { current: resetActiveModal } = useRef(() => {
    setActiveModal(undefined);
  });

  // ─────────────────────────────────────────────────────────────────────

  const contextValue = useMemo(
    () => ({ activeModal, hasActiveModal, setActiveModal, resetActiveModal }),
    [activeModal, hasActiveModal, resetActiveModal],
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);

// ─── Context ─────────────────────────────────────────────────────────────────

export const ModalContext = createContext<ModalContextValue>(undefined);

// ─── Types ───────────────────────────────────────────────────────────────────

type ModalProviderProps = Pick<ViewProps, 'children'>;

type ActiveModal = Readonly<{
  /**
   * Modals can have an optional `id`, which can be used to identify
   * them amongst other modals and perform conditional actions depending on that.
   */
  id?: string;
}>;

type ModalContextValue =
  | Readonly<{
      activeModal: ActiveModal | undefined;
      hasActiveModal: boolean;
      setActiveModal: (activeModal: ActiveModal) => void;
      resetActiveModal: () => void;
    }>
  | undefined;

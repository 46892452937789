/* istanbul ignore file */

import type { ComponentProps } from 'react';
import React from 'react';
import { createPortal } from 'react-dom';
import { NoticeBannersStack } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders `NoticeBannersStack` component in a portal to show notice banner
 * on top of "everything"
 *
 * @see {@link https://react.dev/reference/react-dom/createPortal}
 */
// @ts-expect-error TS(2742): The inferred type of 'NoticeBannersOverlayStack' c... Remove this comment to see the full error message
export const NoticeBannersOverlayStack = (
  props: NoticeBannersOverlayStackProps,
) => createPortal(<NoticeBannersStack {...props} />, document.body);

// ─── Types ───────────────────────────────────────────────────────────────────

type NoticeBannersOverlayStackProps = ComponentProps<typeof NoticeBannersStack>;

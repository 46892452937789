import React from 'react';

import { CheckoutLayoutBlock, TipsGroup } from '@order/components';
import { useTippingEnabledForCurrentOrderType } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import { useCheckoutContext } from '../../../../CheckoutProvider';
import { useCheckoutTips } from '../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const ConnectedCheckoutRestaurantTipBlock = () => {
  const { t } = useLocalizationContext();

  const { payload, handleRestaurantTip } = useCheckoutContext();
  const { tips, defaultTipValue, orderSubtotal } = useCheckoutTips();

  const isTippingEnabled = useTippingEnabledForCurrentOrderType();
  const selectedTipAmount = payload?.pickupTip ?? 0;

  // ─────────────────────────────────────────────

  if (!isTippingEnabled || tips.length === 0) return null;

  return (
    <CheckoutLayoutBlock>
      <TipsGroup
        tips={tips}
        defaultTipValue={defaultTipValue}
        orderSubtotalInCents={orderSubtotal}
        sectionTitle={t('checkout.tips.restaurant-tip.title')}
        sectionDescription={t('checkout.tips.restaurant-tip.description')}
        selectedTipAmountInCents={selectedTipAmount}
        setTipAmount={handleRestaurantTip}
        fluidTipButtons
        tipButtonsHorizontalPadding="1"
      />
    </CheckoutLayoutBlock>
  );
};

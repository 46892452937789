import React from 'react';

import type { DeliveryProductDetailsScreenProps } from '../../navigation/AppNavigation.props';
import { DeliveryProductDetails } from './components';

export const DeliveryProductDetailsScreen = ({
  navigation,
  route,
}: DeliveryProductDetailsScreenProps) => {
  const {
    addressId,
    productSlug,
    ingredientIds,
    name: encodedName,
    reorderLineItemId,
  } = route.params ?? {};

  const name = encodedName ? decodeURIComponent(encodedName) : null;
  const decodedIngredientIds = ingredientIds
    ? decodeURIComponent(ingredientIds)
    : null;

  const handleAddLineItemToCart = React.useCallback(() => {
    navigation.replace('Bag', { referrer: 'DeliveryMenu' });
  }, [navigation]);

  return (
    <DeliveryProductDetails
      addressId={addressId}
      productSlug={productSlug}
      ingredientIds={decodedIngredientIds?.split(',')}
      onAddLineItemToCart={handleAddLineItemToCart}
      reorderLineItemId={reorderLineItemId}
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      name={name}
      isDelivery
    />
  );
};

import { Platform } from 'react-native';

import { useFeatureFlag } from '@order/LaunchDarkly';

export const usePersonalDataAvailability = () => {
  // Disable personal data request per feature flag.
  const isFlagEnabled = useFeatureFlag('OPS-ca-data-request', {
    listenForChanges: true,
  });

  // Disable personal data request for native platforms.
  const isNativePlatform = Platform.OS !== 'web';

  // Feature is enabled when flag is enabled and on the web platform.
  return isFlagEnabled && !isNativePlatform;
};

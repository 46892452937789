import React, { memo } from 'react';
import { BoundScrollProvider, JumpNavigationProvider } from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';

import type { SweetpassHomeScreenProps } from '../../../../navigation/AppNavigation.props';
import type { useSweetpassHomeContentfulData } from '../../hooks';
import {
  useSweetpassChallengesAndRewards,
  useSweetpassHomeBrazeEvents,
  useSweetpassHomeTelemetry,
} from '../../hooks';
import { SweetpassHomeContent } from '../SweetpassHomeContent';
import type { SweetpassHeroTierName } from '../SweetpassHomeHeroStats';
import { getSweetpassHeroStatsPaletteForTag } from '../SweetpassHomeHeroStats';
import { useOptInChallengeOnFocus } from './hooks';

// ────────────────────────────────────────────────────────────────────────────────

export const SweetpassHomeContainer = memo(
  (props: SweetpassHomeContainerProps) => {
    const {
      sweetpassTierName,
      sweetpassHomeActionsRail,
      sweetpassHomeStats,
      contentItems,
      navigateToBenefits,
      navigateToUpgrade,
    } = props;

    const isSweetpassDetailedHeroStatsEnabled = useFeatureFlag(
      'CELS-440-long-sweetpass-detailed-hero-stats-enabled',
    );

    const {
      challenges,
      challengeRewards,
      isFetchingChallenges,
      isFetchingChallengeRewards,
    } = useSweetpassChallengesAndRewards();

    useSweetpassHomeTelemetry({
      hasContentfulData:
        Boolean(sweetpassHomeStats) ||
        Boolean(sweetpassHomeActionsRail) ||
        Boolean(contentItems),
      hasSweetpassHomeStats: Boolean(sweetpassHomeStats),
      hasSweetpassHomeActionsRail: Boolean(sweetpassHomeActionsRail),
    });

    useSweetpassHomeBrazeEvents();

    /**
     * Opt-in to a challenge when:
     * - Screen is focused.
     * - Challenge ID and opt-in flag are present in the route params.
     */
    useOptInChallengeOnFocus();

    return (
      <BoundScrollProvider>
        <JumpNavigationProvider
          jumpTargetIds={['challenges-rail', 'rewards-rail']}
          isNativePlatformOffsetEnabled={true}
          withSafeAreaOffset
        >
          <SweetpassHomeContent
            tierLevel={sweetpassTierName}
            detailedHeroStats={isSweetpassDetailedHeroStatsEnabled}
            sweetpassHomeActionsRail={sweetpassHomeActionsRail}
            sweetpassHomeStats={sweetpassHomeStats}
            contentItems={contentItems}
            challenges={challenges}
            isFetchingChallenges={isFetchingChallenges}
            challengeRewards={challengeRewards}
            isFetchingChallengeRewards={isFetchingChallengeRewards}
            palette={getSweetpassHeroStatsPaletteForTag(sweetpassTierName)}
            navigateToBenefits={navigateToBenefits}
            navigateToUpgrade={navigateToUpgrade}
          />
        </JumpNavigationProvider>
      </BoundScrollProvider>
    );
  },
);

// ─── Types ─────────────────────────────────────────────────────────────

type SweetpassHomeContainerProps = Readonly<{
  sweetpassTierName: SweetpassHeroTierName;
  navigateToBenefits: () => void;
  navigateToUpgrade: () => void;
}> &
  ReturnType<
    typeof useSweetpassHomeContentfulData
  >['sweetpassHomeContentfulData'] &
  SweetpassHomeScreenProps;

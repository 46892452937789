import type { Breakpoint } from '@garnish/constants';
import { LG, MD, SM, XS } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A small helper that returns breakpoints by their min width as a booleans object.
 */
export function getBreakpointsByMinWidth(
  screenWidth: number,
): BreakpointsByMinWidth {
  return {
    isXS: screenWidth >= XS.MIN,
    isSM: screenWidth >= SM.MIN,
    isMD: screenWidth >= MD.MIN,
    isLG: screenWidth >= LG.MIN,
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type BreakpointsByMinWidth = Record<`is${Breakpoint}`, boolean>;

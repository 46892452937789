import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { OpenEmailButton } from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';

// ─────────────────────────────────────────────────────────────────────────────

export const JoinOrSignInOpenEmailButton = () => {
  const { formatMessage } = useIntl();

  const { track } = useTelemetry();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const trackOpenEmailBtnPress = useCallback(() => {
    track('join-or-sign-in.open-email-btn.press');
  }, [track]);

  // ─────────────────────────────────────────────────────────────────────

  const messages = defineMessages({
    dialogTitle: {
      defaultMessage: 'Open mail app',
      description: 'Open mail app dialog | Title',
    },
    dialogMessage: {
      defaultMessage: 'Which app would you like to open?',
      description: 'Open mail app dialog | Message',
    },
  });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <OpenEmailButton
      dialogTitle={formatMessage(messages.dialogTitle)}
      dialogMessage={formatMessage(messages.dialogMessage)}
      onPress={trackOpenEmailBtnPress}
    >
      <FormattedMessage
        defaultMessage="Check email inbox"
        description="Check email inbox button label"
      />
    </OpenEmailButton>
  );
};

import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText, DisplayText } from '../Text';

export const MobileHeader = ({
  testID: baseTestID,
  titleTestID,
  subtitleTestID,
  title,
  subtitle,
  style,
}: MobileHeaderProps) => {
  return (
    <View testID={baseTestID ?? 'sg-mobile-header'} style={style}>
      {title ? (
        <DisplayText
          testID={titleTestID ?? baseTestID?.concat('.title')}
          size={3}
          style={[styles.title, !subtitle && styles.margin]}
        >
          {title}
        </DisplayText>
      ) : null}
      {subtitle ? (
        <BodyText
          testID={subtitleTestID ?? baseTestID?.concat('.subtitle')}
          size={4}
          style={[styles.subtitle, Boolean(subtitle) && styles.margin]}
        >
          {subtitle}
        </BodyText>
      ) : null}
    </View>
  );
};

type MobileHeaderProps = Readonly<{
  title?: string;
  subtitle?: string;
  testID?: string;
  titleTestID?: string;
  subtitleTestID?: string;
  style?: ViewProps['style'];
}>;

const styles = StyleSheet.create({
  title: {
    fontSize: 32,
    paddingVertical: theme.spacing['2'],
  },
  subtitle: {
    paddingVertical: theme.spacing['2'],
  },
  margin: {
    marginBottom: theme.spacing['6'],
  },
});

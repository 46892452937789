import React from 'react';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

// ────────────────────────────────────────────────────────────────────────────────

export const PageWrapperSafeAreaView = (
  props: PageWrapperSafeAreaViewProps,
) => {
  const { children, style, edges, testID } = props;

  // ─────────────────────────────────────────────────────────────────

  return (
    <SafeAreaView
      style={[styles.wrapper, style]}
      edges={getSafeAreaEdgesList(edges)}
      testID={testID}
    >
      {children}
    </SafeAreaView>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flexGrow: 1,
    maxHeight: '100%',
  },
});

// ─── UTILS ──────────────────────────────────────────────────────────────────────

const getSafeAreaEdgesList: GetSafeAreaEdgesList = (edges) => {
  if (!edges) return ['right', 'left'];

  if (edges === 'all') return ['top', 'right', 'bottom', 'left'];

  return edges;
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type PageWrapperSafeAreaViewProps = Readonly<{
  /**
   * By omitting this property, no edges will be inset.
   * To inset all edges, pass `'all'` explicitly.
   *
   * This differs from the `edges` property of `<SafeAreaView />`, which insets all edges if omitted.
   */
  edges?: SafeAreaViewProps['edges'] | 'all';
}> &
  Pick<SafeAreaViewProps, 'testID' | 'style' | 'children'>;

type SafeAreaViewProps = React.ComponentProps<typeof SafeAreaView>;

type GetSafeAreaEdgesList = (
  edges: PageWrapperSafeAreaViewProps['edges'],
) => SafeAreaViewProps['edges'];

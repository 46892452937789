import { useContentfulContentTypeEntries } from '../useContentfulContentTypeEntries';

/**
 * Returns single Contentful entry for the provided content type.
 *
 * Additionally, returns `fetching` and `error` states.
 *
 * @example
 *
 * const { data, fetching, error } = useContentfulContentTypeEntry({
 *   contentType: 'contentfulContentTypeID',
 * });
 * const { fields, tags } = data;
 * const { image, bodyText, headingText } = fields ?? {};
 *
 * @see {@link https://www.contentful.com/developers/docs/javascript/tutorials/using-js-cda-sdk/#retrieve-all-entries-of-a-space Contentful Docs}
 */
export const useContentfulContentTypeEntry = <ContentTypeFields>(
  query: Omit<ContentfulQuery, 'limit'>,
) => {
  const { data, error, fetching } =
    useContentfulContentTypeEntries<ContentTypeFields>({
      ...query,
      limit: 1,
    });

  return { data: data?.[0], error, fetching };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ContentfulQuery = Readonly<{
  contentType: string;

  /**
   * Depth of linked entries.
   */
  include?: number;
  limit?: number;
  order?: 'created-at' | 'updated-at';
  pause?: boolean;
  tags?: readonly string[];

  /**
   * Extra search parameters to narrow down the search results.
   *
   * NOTE: Always "memoize" the parameters object to avoid infinite loops.
   *
   * @example
   * {
   *   searchParams: {
   *     'fields.<field-name>': '<value>',
   *     'fields.<field-name>[within]': '1,2,3,4,5',
   *   }
   * }
   *
   * @see {@link https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters/equality-operator}
   */
  searchParams?: Record<string, string>;

  /**
   * TTL - Time (in milliseconds) to live
   * Specifies how long the data should be cached.
   *
   * @default 60 * 60 * 1000
   */
  ttl?: number;
}>;

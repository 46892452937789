import React, {
  type ComponentProps,
  type MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { Button } from '../../../Button';

// ─────────────────────────────────────────────────────────────────────────────

export const ScrollspyNavItem = (props: ScrollspyNavItemProps) => {
  const {
    children,
    isActive = false,
    targetId,
    register,
    deregister,
    onPress,
    ...buttonProps
  } = props;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    onPress?.(targetId);
  }, [onPress, targetId]);

  // ─── Refs ────────────────────────────────────────────────────────────

  const ref = useRef(null);

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    register(targetId, ref);

    return () => {
      deregister(targetId);
    };
  }, [targetId, register, deregister]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Button
      ref={ref}
      size="large"
      palette={isActive ? 'primary' : 'secondary'}
      style={styles.pressable}
      accessibilityState={{ selected: isActive }}
      onPress={handleOnPress}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    height: 32,
    paddingHorizontal: theme.spacing['3'],
    borderWidth: 0,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScrollspyNavItemProps = {
  targetId: string;
  isActive?: boolean;
  onPress?: (targetId: string) => void;
  register: (targetId: string, targetRef: MutableRefObject<unknown>) => void;
  deregister: (targetId: string) => void;
} & Omit<ComponentProps<typeof Button>, 'palette' | 'onPress' | 'ref'>;

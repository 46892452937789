/* eslint-disable functional/immutable-data */

import React, { useCallback, useMemo, useRef, useState } from 'react';
import type { ViewProps } from 'react-native';

import { LaunchDarklyClient } from './client';
import type { LDClient, LDContext } from './client/launch-darkly.types';
import { featureFlagDefaults as defaults } from './feature-flags';
import type { LaunchDarklyContextType } from './LaunchDarklyProvider.context';
import { LaunchDarklyContext } from './LaunchDarklyProvider.context';
import { useOverrides } from './LaunchDarklyProvider.overrides';
import {
  generateAnonymousUserContext,
  generateUserContext,
} from './LaunchDarklyProvider.utils';
import type {
  Customer,
  FeatureFlagChangeListener,
  FeatureFlags,
} from './types';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * Launch Darkly context provider
 *
 * @see {@link https://www.launchdarkly.com/ Launch Darkly}
 */
export const LaunchDarklyProvider = (props: Pick<ViewProps, 'children'>) => {
  const clientRef = useRef<LDClient>();
  const userContextRef = useRef<LDContext>();
  const { current: client } = clientRef;

  // ─── State ───────────────────────────────────────────────────────────

  const [isReady, setIsReady] = useState(false);
  const [initialFeatureFlags, setInitialFeatureFlags] = useState<
    FeatureFlags | undefined
  >();

  const { overrides, setFeatureFlagOverrides, clearFeatureFlagOverrides } =
    useOverrides();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const overrideFeatureFlag = useCallback(
    (flagName: keyof FeatureFlags, flagValue: unknown) => {
      if (initialFeatureFlags?.[flagName] === flagValue) {
        setFeatureFlagOverrides({ ...overrides, [flagName]: undefined });

        return;
      }

      setFeatureFlagOverrides({ ...overrides, [flagName]: flagValue });
    },
    [initialFeatureFlags, overrides, setFeatureFlagOverrides],
  );

  const init = useCallback(
    async (customer?: Customer) => {
      const isInitialized = client !== undefined;

      if (isInitialized) return;

      // ─────────────────────────────────────────────

      const userContext = customer?.id
        ? generateUserContext(customer)
        : generateAnonymousUserContext();

      const ldClient = await LaunchDarklyClient.init(userContext);

      if (!ldClient) return;

      // ─────────────────────────────────────────────

      clientRef.current = ldClient;
      userContextRef.current = userContext;

      setInitialFeatureFlags(await ldClient.getAllFlags());
      setIsReady(true);
    },
    [client],
  );

  /**
   * Adds additional context to the user's context object.
   */
  const addContext = useCallback(
    async (context: Record<string, unknown>) => {
      const updatedContext: LDContext = {
        kind: 'user',
        ...userContextRef.current,
        ...context,
      };

      return client?.identify?.(updatedContext);
    },
    [client],
  );

  const identifyCustomer = useCallback(
    async (customer: Customer) => {
      if (!client || !customer.id) return;

      const userContext = generateUserContext(customer);
      const hasAlreadySynced = userContextRef.current?.key === userContext.key;

      if (hasAlreadySynced) return;

      await client.identify?.(userContext);

      userContextRef.current = userContext;
      setInitialFeatureFlags(await client.getAllFlags());
    },
    [client],
  );

  const resetCustomer = useCallback(async () => {
    if (!client) return;

    const anonymousUserContext = generateAnonymousUserContext();

    await client.identify?.(anonymousUserContext);

    userContextRef.current = anonymousUserContext;
    setInitialFeatureFlags(await client.getAllFlags());
  }, [client]);

  const registerFeatureFlagListener = useCallback<FeatureFlagChangeListener>(
    async (flagKey, callback) => {
      await client?.registerFeatureFlagListener?.(flagKey, callback);
    },
    [client],
  );

  const unregisterFeatureFlagListener = useCallback<FeatureFlagChangeListener>(
    async (flagKey, callback) => {
      await client?.unregisterFeatureFlagListener?.(flagKey, callback);
    },
    [client],
  );

  /**
   * Track events to use in metrics or Experimentation.
   */
  const track = useCallback<NonNullable<LDClient['track']>>(
    async (eventName, data, metricValue) => {
      client?.track?.(eventName, data, metricValue);
    },
    [client],
  );

  const close = useCallback(async () => {
    if (!client) return;

    await client?.close?.();
  }, [client]);

  // ────────────────────────────────────────────────────────────────────

  const value = useMemo<LaunchDarklyContextType>(
    () => ({
      isReady,
      defaults,
      overrides,
      initialFeatureFlags,

      init,
      addContext,
      identifyCustomer,
      resetCustomer,
      registerFeatureFlagListener,
      unregisterFeatureFlagListener,
      overrideFeatureFlag,
      clearFeatureFlagOverrides,
      track,
      close,
    }),
    [
      isReady,
      overrides,
      initialFeatureFlags,
      init,
      addContext,
      identifyCustomer,
      resetCustomer,
      registerFeatureFlagListener,
      unregisterFeatureFlagListener,
      overrideFeatureFlag,
      clearFeatureFlagOverrides,
      track,
      close,
    ],
  );

  return (
    <LaunchDarklyContext.Provider value={value}>
      {props.children}
    </LaunchDarklyContext.Provider>
  );
};

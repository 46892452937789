import React, { useMemo } from 'react';
import type { ImageSourcePropType, ViewProps } from 'react-native';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import {
  getContentfulImageUrl,
  HeroCardLoading,
  Image,
  useHeroCardResponsiveHeight,
  useHeroCardResponsiveStyles,
} from '@sg/garnish';
import { HeroCard, HeroCardFlip, theme, useResponsive } from '@sg/garnish';

import { useSweetpassTier } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import { ActiveOrderFallbackIllus } from './assets';
import { NoActiveOrderFallbackIllus } from './assets/index.web';
import { useHomeScreenHeroCardContent } from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const HomeScreenHeroCard = (props: ShortHomeScreenActionCardProps) => {
  const { isLoadingMostRecentOrderStatus, hasActiveOrder } = props;

  const { t } = useLocalizationContext();
  const { scale } = useWindowDimensions();

  const { sweetpassTierName } = useSweetpassTier();

  // ─── Content ─────────────────────────────────────────────────────────

  const { singleSideCard, doubleSidedCard } = useHomeScreenHeroCardContent({
    sweetpassTierName,
    hasActiveOrder,
    shouldPauseContentLoading: isLoadingMostRecentOrderStatus,
  });

  const isLoadingContent = singleSideCard.fetching || doubleSidedCard.fetching;

  const singleSidedCardFields = singleSideCard.data?.fields;
  const doubleSidedCardFields = doubleSidedCard.data?.fields;

  const singleSidedCardImage = singleSidedCardFields?.imageFrontSide.fields;
  const singleSidedCardImageUrl = singleSidedCardImage?.file.url;
  const singleSidedCardImageA11yLabel = singleSidedCardImage?.description;

  const doubleSidedCardImage = doubleSidedCardFields?.imageFrontSide.fields;
  const doubleSidedCardImageUrl = doubleSidedCardImage?.file.url;
  const doubleSidedCardImageA11yLabel = doubleSidedCardImage?.description;
  const shouldRenderFallbackHeroCard =
    !hasActiveOrder && !singleSideCard.fetching && !singleSidedCardFields;
  const shouldRenderFallbackHeroFlipCard =
    hasActiveOrder && !doubleSidedCard.fetching && !doubleSidedCardFields;

  // ─── Assets ──────────────────────────────────────────────────────────

  const displayScale = Math.ceil(scale);

  const heroCardResponsiveHeight = useHeroCardResponsiveHeight();
  const heroCardAssetScaledHeight = heroCardResponsiveHeight * displayScale;

  const assetTransforms = useMemo(
    () => ({ fm: 'webp', q: 90, h: heroCardAssetScaledHeight }) as const,
    [heroCardAssetScaledHeight],
  );

  const singleSidedCardImageSrc = useMemo<MaybeImageSourcePropType>(() => {
    if (!singleSidedCardImageUrl) return;

    return {
      uri: getContentfulImageUrl(singleSidedCardImageUrl, assetTransforms),
    };
  }, [assetTransforms, singleSidedCardImageUrl]);

  const doubleSidedCardImageSrc = useMemo<MaybeImageSourcePropType>(() => {
    if (!doubleSidedCardImageUrl) return;

    return {
      uri: getContentfulImageUrl(doubleSidedCardImageUrl, assetTransforms),
    };
  }, [assetTransforms, doubleSidedCardImageUrl]);

  // ─────────────────────────────────────────────────────────────────────

  const shouldRenderLoadingComponent =
    isLoadingMostRecentOrderStatus || isLoadingContent;

  // ─────────────────────────────────────────────────────────────────────

  if (shouldRenderLoadingComponent) {
    return (
      <HomeScreenHeroCardContainer>
        <HeroCardLoading />
      </HomeScreenHeroCardContainer>
    );
  }

  // ─────────────────────────────────────────────────────────────────────

  if (shouldRenderFallbackHeroCard) {
    return (
      <HomeScreenHeroCardContainer>
        <HeroCard
          title={t('home.hero-card.fallback.title')}
          imageSrc={NoActiveOrderFallbackIllus}
          imageA11yLabel={t('home.hero-card.fallback.image.a11y-label')}
          ctaLabel={t('home.hero-card.fallback.ctaButton')}
          url="/menu"
        />
      </HomeScreenHeroCardContainer>
    );
  }

  if (shouldRenderFallbackHeroFlipCard) {
    return (
      <HomeScreenHeroCardContainer>
        <HomeScreenHeroCardFallback />
      </HomeScreenHeroCardContainer>
    );
  }

  if (hasActiveOrder && doubleSidedCardFields !== undefined) {
    return (
      <HomeScreenHeroCardContainer>
        <HeroCardFlip
          title={doubleSidedCardFields.titleFrontSide}
          imageSrc={doubleSidedCardImageSrc}
          imageA11yLabel={doubleSidedCardImageA11yLabel}
          ctaLabel={doubleSidedCardFields.ctaLabelFrontSide}
          backSideCtaLabel={doubleSidedCardFields.ctaLabelBackSide}
          backSideDescription={doubleSidedCardFields.descriptionBackSide}
          backSideTitle={doubleSidedCardFields.titleBackSide}
          backSideInlineLinkLabel={
            doubleSidedCardFields?.inlineLinkLabelBackSide
          }
          backSideInlineLinkURL={doubleSidedCardFields.inlineLinkUrlBackSide}
        />
      </HomeScreenHeroCardContainer>
    );
  }

  if (!hasActiveOrder && singleSidedCardFields !== undefined) {
    return (
      <HomeScreenHeroCardContainer>
        <HeroCard
          title={singleSidedCardFields.titleFrontSide}
          imageSrc={singleSidedCardImageSrc}
          imageA11yLabel={singleSidedCardImageA11yLabel}
          ctaLabel={singleSidedCardFields.ctaLabelFrontSide}
          url={singleSidedCardFields?.ctaUrlFrontSide}
        />
      </HomeScreenHeroCardContainer>
    );
  }

  return null;
};

// ─────────────────────────────────────────────────────────────────────────────

const HomeScreenHeroCardFallback = () => {
  const style = useHeroCardResponsiveStyles();
  const { t } = useLocalizationContext();

  return (
    <Image
      aria-label={t('home.hero-card.fallback.image.a11y-label')}
      style={style}
      source={ActiveOrderFallbackIllus}
      contentFit="contain"
    />
  );
};

const HomeScreenHeroCardContainer = (props: Pick<ViewProps, 'children'>) => {
  const { children } = props;

  const { match } = useResponsive();

  const containerStyle = match([styles.containerXS, undefined]);

  return <View style={containerStyle}>{children}</View>;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ShortHomeScreenActionCardProps = Readonly<{
  isLoadingMostRecentOrderStatus: boolean;
  hasActiveOrder: boolean;
}>;

type MaybeImageSourcePropType = ImageSourcePropType | undefined;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    marginTop: theme.spacing['6'],
  },
});

import React, { useCallback, useLayoutEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { useResponsive } from '@sg/garnish';

import { Loyalty } from '@order/features/loyalty';
import { useOptInUserV2Mutation } from '@order/graphql';
import { useTelemetry } from '@order/Telemetry';

import { useJoinSgRewardsContent } from './hooks';

export const JoinSgRewardsScreen = () => {
  const { heroSection, lineItems } = useJoinSgRewardsContent();
  const { formatMessage } = useIntl();
  const navigation = useNavigation();
  const { track } = useTelemetry();
  const { minWidth } = useResponsive();
  const [optInMutationResponse, optInMutation] = useOptInUserV2Mutation();

  const onPressCta = useCallback(async () => {
    track('sgrewards.optedout.join.cta_tapped');
    await optInMutation({});
  }, [optInMutation, track]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: minWidth.isSM,
    });
  }, [navigation, minWidth]);

  return (
    <Loyalty.JoinSgRewards.Container>
      {heroSection?.heroImage?.image && heroSection?.heroLogo?.image ? (
        <Loyalty.JoinSgRewards.Hero
          heroImage={heroSection?.heroImage?.image}
          logoImage={heroSection.heroLogo?.image}
        />
      ) : null}
      {heroSection?.heroImage?.title ? (
        <Loyalty.JoinSgRewards.Title>
          {heroSection?.heroImage?.title}
        </Loyalty.JoinSgRewards.Title>
      ) : null}

      <Loyalty.JoinSgRewards.BodyContainer>
        {lineItems.map((item) => (
          <Loyalty.JoinSgRewards.RowItem
            key={item.title}
            title={item.title}
            image={item.image}
          />
        ))}
        <Loyalty.JoinSgRewards.Cta
          onPress={onPressCta}
          text={formatMessage(messages.buttonCta)}
          isLoading={optInMutationResponse.fetching}
        />
      </Loyalty.JoinSgRewards.BodyContainer>
    </Loyalty.JoinSgRewards.Container>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  buttonCta: {
    defaultMessage: 'Join SgRewards',
    description: 'Join SgRewards | Loyalty | Button CTA',
  },
});

import { Platform } from 'react-native';
import { slugify } from '@sg/garnish';

import type { TranslationKeys } from '@order/Localization';
import { useLocalizationContext } from '@order/Localization';

import { useProductDetailsScreenContext } from '../../ProductDetailsScreen.provider';
import type { IngredientModificationWithQuantity } from '../../types';

// ────────────────────────────────────────────────────────────────────────────────

export const useIngredientModificationQty = (
  ingredientModification: IngredientModificationWithQuantity,
) => {
  const { isCustomizationActive, activeIngredientsModificationsMap } =
    useProductDetailsScreenContext();

  const { kind, quantity, ingredient } = ingredientModification;

  const activeIngredientModification = activeIngredientsModificationsMap.get(
    ingredient.id,
  );
  const activeIngredientModificationQty =
    activeIngredientModification?.quantity;
  const qty = activeIngredientModificationQty ?? quantity ?? 0;

  // quantity should not be shown for certain kinds
  const shouldDisplayQtyForKind = kind !== 'dressings';

  const shouldDisplayQtyInIdleMode =
    shouldDisplayQtyForKind && !isCustomizationActive && qty > 1;
  const shouldDisplayQtyInCustomizationMode =
    shouldDisplayQtyForKind && isCustomizationActive;
  const shouldDisplayQty =
    shouldDisplayQtyInIdleMode || shouldDisplayQtyInCustomizationMode;

  return shouldDisplayQty ? qty : 0;
};

// ────────────────────────────────────────────────────────────────────────────────

export const useIngredientModificationA11yLabels = (
  params: UseIngredientModificationA11yLabelsParams,
) => {
  const { ingredientModification, ingredientQty, isUnavailableIngredient } =
    params;

  const { ingredient } = ingredientModification;
  const { name } = ingredient;
  const { t } = useLocalizationContext();

  const canDecreaseQty = ingredientQty > 1;

  // ─── a11y Label ──────────────────────────────

  const singleQtyLabel: TranslationKeys =
    'pdp.modifications.ingredient-modification.a11y.label';
  const multipleQtyLabel: TranslationKeys =
    'pdp.modifications.ingredient-modification.a11y.label-with-qty';

  const a11yLabelBase = t(
    ingredientQty > 0 ? multipleQtyLabel : singleQtyLabel,
    {
      ingredientModification: name,
      qty: ingredientQty,
    },
  );
  const unavailableA11yLabel = isUnavailableIngredient
    ? t('pdp.modifications.ingredient-modification.a11y.unavailable')
    : '';
  const a11yLabel = `${a11yLabelBase} ${unavailableA11yLabel}`.trimEnd();

  // ─── a11y Hint ───────────────────────────────

  const a11yHintForPlatform = Platform.select<TranslationKeys | undefined>({
    ios: 'pdp.modifications.ingredient-modification.a11y.hint.ios',
    android: 'pdp.modifications.ingredient-modification.a11y.hint.android',
  });
  const shouldAddA11yHint =
    a11yHintForPlatform !== undefined && !isUnavailableIngredient;
  const a11yHint = shouldAddA11yHint ? t(a11yHintForPlatform) : undefined;

  // ─── Decrease Qty a11y Label ─────────────────

  const decreaseQtyA11yLabel = t(
    canDecreaseQty
      ? 'pdp.modifications.ingredient-modification.a11y.decrease-qty'
      : 'pdp.modifications.ingredient-modification.a11y.remove',
    {
      ingredientModification: name,
      qty: ingredientQty,
    },
  );

  // ─────────────────────────────────────────────

  return {
    a11yLabel,
    a11yHint,
    decreaseQtyA11yLabel,
  };
};

// ────────────────────────────────────────────────────────────────────────────────

export const useIngredientModificationCardTestID = (
  ingredientModification: IngredientModificationWithQuantity,
  testIdPrefix = 'ingredient-modification-card',
) => {
  const { ingredient } = ingredientModification;
  const { name } = ingredient;
  const ingredientSlug = slugify(name);

  return `${testIdPrefix}-${ingredientSlug}`;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseIngredientModificationA11yLabelsParams = Readonly<{
  ingredientModification: IngredientModificationWithQuantity;
  ingredientQty: number;
  isUnavailableIngredient: boolean;
}>;

import { Platform, StyleSheet } from 'react-native';

export const menuCategoriesGridProductCardLinkStyles = StyleSheet.create({
  container: Platform.select({
    // NOTE: `Link` component doesn't provide RN default styles like `display: flex`
    web: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    default: {
      flex: 1,
    },
  }),
});

import React, { type ComponentProps, useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { theme } from '@garnish/constants';
import { differenceInHours } from 'date-fns';
import {
  BodyText,
  Button,
  formatDate,
  IllusRewardCardCarrot,
  IllusRewardCardCarrotPremium,
  IllusRewardCardMushroom,
  IllusRewardCardMushroomPremium,
  IllusRewardCardPeas,
  IllusRewardCardPeasPremium,
  IllusRewardCardTomato,
  IllusRewardCardTomatoPremium,
  Image,
} from '@sg/garnish';

import { ignoreTimezone } from '@order/utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * The reward card for the bag.
 * It contains an "Apply" / "Applied" button which either applies or removed the reward from the bag.
 */
export const BagReward = (props: BagRewardProps) => {
  const {
    index,
    id,
    title,
    expirationDate,
    redeemableAt,
    isPremium,
    isApplied,
    isLoading,
    isDisabled,
    onPress,
  } = props;

  const validExpirationDate = expirationDate
    ? ignoreTimezone(expirationDate)
    : null;

  const { fontScale } = useWindowDimensions();
  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    onPress(id);
  }, [id, onPress]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View
      style={[
        styles.container,
        isPremium ? styles.containerPremium : null,
        redeemableAt || isDisabled ? styles.containerNotRedeemable : null,
      ]}
    >
      <View style={styles.rewardRow}>
        <View style={styles.rewardColumn}>
          <BodyText
            style={[
              styles.title,
              isPremium && !isDisabled ? styles.premiumText : null,
              redeemableAt ? styles.nonRedeemableTitle : null,
            ]}
            numberOfLines={fontScale > 1.1 ? 2 : 3}
            ellipsizeMode="tail"
            sizeMatch={['18']}
          >
            {title}
          </BodyText>

          {validExpirationDate && !isPremium ? (
            <BodyText style={styles.expiration} sizeMatch={['14']}>
              <FormattedMessage
                defaultMessage="Ends {date}"
                description="Bag | Reward | Expiration date"
                values={{ date: formatDate(validExpirationDate, 'MM/dd') }}
              />
            </BodyText>
          ) : null}
        </View>

        <Image
          contentFit="cover"
          source={selectIllustration(index, isPremium && !isDisabled)}
          style={styles.illustration}
        />
      </View>

      {redeemableAt ? (
        <BodyText
          sizeMatch={['14']}
          style={[
            styles.expiration,
            isPremium && !isDisabled ? styles.premiumText : null,
          ]}
        >
          <FormattedMessage
            defaultMessage="Use again in {date} hrs"
            description="Bag | Reward | Expiration date"
            values={{
              date: differenceInHours(new Date(redeemableAt), new Date()),
            }}
          />
        </BodyText>
      ) : null}

      {!isApplied && !redeemableAt ? (
        <Button
          size="small"
          palette={getButtonPalette({ isApplied, isPremium, isDisabled })}
          onPress={handleOnPress}
          accessibilityLabel={formatMessage(messages.applyA11yLabel)}
          isLoading={isLoading}
          disabled={isDisabled}
        >
          <FormattedMessage {...messages.applyCta} />
        </Button>
      ) : null}

      {isApplied && !redeemableAt ? (
        <Button
          size="small"
          palette={getButtonPalette({ isApplied, isPremium, isDisabled })}
          onPress={handleOnPress}
          accessibilityLabel={formatMessage(messages.appliedA11yLabel)}
          isLoading={isLoading}
          disabled={isDisabled}
        >
          <FormattedMessage {...messages.appliedCta} />
        </Button>
      ) : null}
    </View>
  );
};

// ─── Palettes ───────────────────────────────────────────────────────────────

const getButtonPalette = (params: {
  isApplied: boolean;
  isPremium: boolean;
  isDisabled: boolean;
}): ComponentProps<typeof Button>['palette'] => {
  const { isApplied, isPremium, isDisabled } = params;

  if (isPremium && (isDisabled || !isApplied)) {
    return 'white';
  }

  if (isPremium && isApplied) {
    return 'lime';
  }

  if (isApplied) {
    return 'spinach';
  }

  return 'spinachMuted';
};

// ─── Illustrations ──────────────────────────────────────────────────────────

const illustrations = [
  IllusRewardCardCarrot,
  IllusRewardCardMushroom,
  IllusRewardCardPeas,
  IllusRewardCardTomato,
];

const premiumIllustrations = [
  IllusRewardCardCarrotPremium,
  IllusRewardCardMushroomPremium,
  IllusRewardCardPeasPremium,
  IllusRewardCardTomatoPremium,
];

const selectIllustration = (index: number, isPremium: boolean) => {
  if (isPremium) {
    return premiumIllustrations[index % premiumIllustrations.length];
  }

  return illustrations[index % illustrations.length];
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  applyA11yLabel: {
    defaultMessage: 'Applies this reward to your order',
    description: 'Bag | Rewards | Apply CTA a11y',
  },
  appliedA11yLabel: {
    defaultMessage: 'Removes this reward from your order',
    description: 'Bag | Rewards | Apply CTA a11y',
  },
  applyCta: {
    defaultMessage: 'Apply',
    description: 'Bag | Rewards | Apply CTA',
  },
  appliedCta: {
    defaultMessage: 'Applied',
    description: 'Bag | Rewards | Apply CTA',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: 310,
    minWidth: 310,
    maxWidth: 310,
    minHeight: 126,
    backgroundColor: theme.colors.OPACITY.SPINACH.NEAR_LIGHTEST,
    paddingVertical: theme.spacing['4'],
    paddingHorizontal: theme.spacing['6'],
    gap: theme.spacing['4'],
    borderRadius: theme.radius.large,
  },
  containerPremium: {
    backgroundColor: theme.colors.SPINACH,
  },
  containerNotRedeemable: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
  rewardRow: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: theme.spacing['4'],
  },
  title: {
    flexWrap: 'wrap',
  },
  rewardColumn: {
    flex: 1,
  },
  expiration: {
    textTransform: 'uppercase',
    color: theme.colors.CHARCOAL,
  },
  illustration: {
    width: 40,
    height: 40,
  },
  premiumText: {
    color: theme.colors.WHITE,
  },
  nonRedeemableTitle: {
    color: theme.colors.CHARCOAL,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagRewardProps = Readonly<{
  index: number;
  id: string;
  title: string;
  expirationDate: string | undefined | null;
  redeemableAt: string | undefined | null;
  isPremium: boolean;
  isApplied: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  onPress: (id: string) => void;
}>;

import React, { memo } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';
import { BodyText, Icon, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import {
  LOCATION_RESULTS_BORDER_RADIUS,
  LOCATION_RESULTS_THEME_BG_COLOR,
  LOCATION_RESULTS_TOGGLE_BTN_HEIGHT,
} from '../../LocationResults.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsMoreLocationsToggle = memo(
  (props: LocationResultsMoreLocationsToggleProps) => {
    const { isToggled, onPress } = props;

    return (
      <TouchableOpacity
        accessibilityRole="button"
        style={styles.toggle}
        activeOpacity={0.6}
        onPress={onPress}
      >
        <LocationResultsToggleIcon isToggled={isToggled} />
        <LocationResultsToggleLabel isToggled={isToggled} />
      </TouchableOpacity>
    );
  },
);

// ─── Subcomponents ───────────────────────────────────────────────────────────

const LocationResultsToggleIcon = memo(
  (props: Pick<LocationResultsMoreLocationsToggleProps, 'isToggled'>) => {
    const iconRotateZ = useDerivedValue(() =>
      withTiming(props.isToggled ? 180 : 0),
    );
    const iconAnimatedStyle = useAnimatedStyle(() => {
      return { transform: [{ rotateZ: `${iconRotateZ.value}deg` }] };
    });

    return (
      <Animated.View
        style={iconAnimatedStyle}
        accessibilityValue={{ text: props.isToggled ? 'Toggled' : 'Idle' }}
      >
        <Icon name="IconCaretUp" />
      </Animated.View>
    );
  },
);

const LocationResultsToggleLabel = memo(
  (props: Pick<LocationResultsMoreLocationsToggleProps, 'isToggled'>) => {
    const { t } = useLocalizationContext();

    const label = props.isToggled
      ? t('location.results.less-locations')
      : t('location.results.more-locations');

    return <BodyText style={styles.toggleText}>{label}</BodyText>;
  },
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  toggle: {
    height: LOCATION_RESULTS_TOGGLE_BTN_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: LOCATION_RESULTS_THEME_BG_COLOR,
    paddingHorizontal: theme.spacing['2'],
    borderTopRightRadius: LOCATION_RESULTS_BORDER_RADIUS,
    borderTopLeftRadius: LOCATION_RESULTS_BORDER_RADIUS,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.LIGHT,
  },

  toggleText: {
    marginLeft: theme.spacing['2'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsMoreLocationsToggleProps = Readonly<{
  isToggled: boolean;
  onPress: () => void;
}>;

import React, { type ComponentProps, useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Text } from 'react-native';
import type { SharedValue } from 'react-native-reanimated';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { type To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import { CustomNavigationHeader, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuContentNavigationHeader = (
  props: MenuContentNavigationHeaderProps,
) => {
  const {
    orderChannel,
    locationName,
    scrollOffsetSV,
    safeAreaEdges,
    testID,
    onBackBtnPressOverride,
    onCloseBtnPressOverride,
  } = props;

  const { minWidth } = useResponsive();
  const { formatMessage } = useIntl();

  // ─── Navigation ──────────────────────────────────────────────────────

  const navigation = useNavigation();
  const navigateTo = useLinkTo();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const goBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }

    navigateTo(createLocationScreenLink());
  }, [navigateTo, navigation]);

  const onBackBtnPress = useCallback(() => {
    if (onBackBtnPressOverride) {
      onBackBtnPressOverride();

      return;
    }

    goBack();
  }, [goBack, onBackBtnPressOverride]);

  const onCloseBtnPress = useCallback(() => {
    if (onCloseBtnPressOverride) {
      onCloseBtnPressOverride();

      return;
    }

    goBack();
  }, [goBack, onCloseBtnPressOverride]);

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldRenderBackButton =
    onBackBtnPressOverride !== undefined || navigation.canGoBack();

  // ─────────────────────────────────────────────────────────────────────

  // NOTE: Custom header is only used on the smallest breakpoint.
  if (minWidth.isSM) {
    return null;
  }

  return (
    <CustomNavigationHeader.Container
      testID={testID}
      safeAreaEdges={safeAreaEdges}
      scrollOffsetSV={scrollOffsetSV}
    >
      {shouldRenderBackButton ? (
        <CustomNavigationHeader.Button.Left
          name="IconArrowLeft"
          accessibilityLabel={formatMessage(messages.goBackButtonA11yLabel)}
          onPress={onBackBtnPress}
        />
      ) : null}

      <CustomNavigationHeader.Text textAlignment="center" numberOfLines={1}>
        <CustomNavigationHeader.Link to={createLocationScreenLink()}>
          <FormattedMessage
            {...messages.orderChannelText}
            values={{ order_channel: orderChannel }}
          />
        </CustomNavigationHeader.Link>

        <Text>
          <FormattedMessage
            {...messages.orderChannelSucceedingText}
            values={{ order_channel: orderChannel }}
          />
        </Text>

        <CustomNavigationHeader.Link to={createLocationScreenLink()}>
          {locationName}
        </CustomNavigationHeader.Link>
      </CustomNavigationHeader.Text>

      <CustomNavigationHeader.Button.Right
        testID="menu-content.navigation-header.close-button"
        name="IconClose"
        accessibilityLabel={formatMessage(messages.closeButtonA11yLabel)}
        onPress={onCloseBtnPress}
      />
    </CustomNavigationHeader.Container>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  orderChannelText: {
    defaultMessage: `{order_channel, select,
      pickup {Pickup}
      delivery {Delivery}
      outpost {Outpost}
      other {}
    }`,
    description: 'Menu | Navigation Header | Text | Order channel after text',
  },
  orderChannelSucceedingText: {
    defaultMessage: `{order_channel, select,
      pickup { from }
      delivery { to }
      outpost { at }
      other { }
    }`,
    description:
      'Menu | Navigation Header | Text | Order channel succeeding text',
  },
  goBackButtonA11yLabel: {
    defaultMessage: 'Go back',
    description: 'Menu | Navigation Header | Back button | a11y label',
  },
  closeButtonA11yLabel: {
    defaultMessage: 'Close',
    description: 'Menu | Navigation Header | Close button | a11y label',
  },
});

// ─── Helpers ─────────────────────────────────────────────────────────────────

// For some reason, RN Navigation does not properly handle static links defined
// outside the component (at the top level) or with `useMemo`.
// It only navigates when we use inline links that are recreated on each render.
function createLocationScreenLink(): To {
  return {
    screen: 'MainTabs',
    params: { screen: 'MenuTab', params: { screen: 'Locations' } },
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuContentNavigationHeaderProps = {
  testID?: string;
  orderChannel: 'pickup' | 'outpost' | 'delivery';
  locationName: string;
  onBackBtnPressOverride?: () => void;
  onCloseBtnPressOverride?: () => void;

  /**
   * An optional shared value of the associated scroll view offset, which can be
   * used for animation.
   */
  scrollOffsetSV?: SharedValue<number>;
  safeAreaEdges?: ComponentProps<
    typeof CustomNavigationHeader.Container
  >['safeAreaEdges'];
};

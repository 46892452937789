import { getAppName, getEnvVars } from '@order/utils';

/**
 * This helps enable certain Apollo Server/Studio features
 * https://www.apollographql.com/docs/studio/metrics/client-awareness/#using-apollo-server-and-apollo-client
 */

const { APP_VERSION_GQL_CLIENT = '0.0.1' } = getEnvVars();

const APP_NAME = getAppName();

export const getClientIDHeaders = (): Headers => {
  return {
    'apollographql-client-name': APP_NAME,
    'apollographql-client-version': APP_VERSION_GQL_CLIENT,
  };
};

// ────────────────────────────────────────────────────────────────────────────────

type Headers = Readonly<{
  'apollographql-client-name': string;
  'apollographql-client-version': string;
}>;

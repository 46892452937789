/* istanbul ignore file */

import React, { memo, useEffect } from 'react';
import type { ImageSourcePropType } from 'react-native';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Image,
  theme,
  TitleText,
  useNoticeBannersStackContext,
  useResponsive,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsListInfoMessage = memo(
  (props: LocationSearchInfoMessageProps) => {
    const { title, text, illustration, illustrationAriaLabel } = props;

    const { match } = useResponsive();

    const ResponsiveComponent = match([
      LocationResultsListInfoMessageXS,
      LocationResultsListInfoMessageSM,
    ]);

    return (
      <ResponsiveComponent
        illustration={illustration}
        illustrationAriaLabel={illustrationAriaLabel}
        title={title}
        text={text}
      />
    );
  },
);

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsListInfoMessageXS = (
  props: LocationSearchInfoMessageProps,
) => {
  const { title = '', text = '' } = props;
  const message = `${title} ${text}`.trim();

  const { push: addBanner, remove: removeBanner } =
    useNoticeBannersStackContext();

  // ─────────────────────────────────────────────────────────────────────

  // show message as a banner on mount
  useEffect(() => {
    addBanner(
      { text: message, palette: 'neutral', id: 'location-result-info-message' },
      true,
    );

    return () => {
      removeBanner('location-result-info-message');
    };
  }, [addBanner, removeBanner, message]);

  return null;
};

export const LocationResultsListInfoMessageSM = (
  props: LocationSearchInfoMessageProps,
) => {
  const { title, text, illustration, illustrationAriaLabel } = props;

  return (
    <View style={styles.container}>
      <Image
        source={illustration}
        style={styles.illustration}
        aria-label={illustrationAriaLabel}
        contentFit="contain"
      />

      <TitleText size={6} style={styles.title}>
        {title}
      </TitleText>

      <BodyText size={3} style={styles.text}>
        {text}
      </BodyText>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['2'],
  },
  illustration: {
    width: 240,
    height: 240,
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing['4'],
  },
  text: {
    textAlign: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationSearchInfoMessageProps = Readonly<{
  illustration: ImageSourcePropType;
  illustrationAriaLabel: string;
  title?: string;
  text?: string;
}>;

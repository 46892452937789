/* eslint-disable unicorn/no-array-for-each */

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { ViewProps } from 'react-native';
import { Platform } from 'react-native';

import { useLaunchDarkly } from '@order/LaunchDarkly';
import { getAppVersion, getCurrentUrl } from '@order/utils';

import {
  type AppsFlyerEvent,
  type LaunchDarklyCustomEvent,
  launchDarklyEventOverrides,
  type MixpanelEvent,
} from './events-overrides';
import {
  appsFlyerEventOverrides,
  googleAnalyticsEventOverrides,
  mixpanelEventOverrides,
} from './events-overrides';
import {
  telemetryEventLogger as eventLogger,
  telemetryLogger as logger,
} from './Telemetry.logger';
import type {
  TelemetryEvent,
  TelemetryEventName,
  TelemetryTrackParams,
} from './TelemetryEvent';
import type { TelemetryEventHandlerProps } from './TelemetryEventHandlers';
import { postEventHandlers, preEventHandlers } from './TelemetryEventHandlers';
import type {
  TelemetryEventOverride,
  TelemetryEventOverrideProps,
} from './TelemetryEventOverride';
import {
  googleAnalytics,
  MIXPANEL_USER_PROPERTY_NAMES,
  useAppsFlyer,
  useMixpanel,
} from './vendors';

// ────────────────────────────────────────────────────────────────────────────────

const TelemetryContext = createContext<Telemetry | undefined>(undefined);

export const TelemetryProvider = (props: Pick<ViewProps, 'children'>) => {
  const [user, setUser] = useState<TelemetryUser | undefined>();

  const userId = user?.id;

  // ─── Vendors ─────────────────────────────────────────────────────────

  // ─── Launch Darkly ──────────────────────

  const { track: launchDarklyTrack } = useLaunchDarkly();

  // ─── Mixpanel ───────────────────────────

  const {
    initialize: mixpanelInitialize,
    identify: mixpanelIdentify,
    reset: mixpanelReset,
    setSuperProperty: mixpanelSetSuperProperty,
    setUserProperty: mixpanelSetUserProperty,
    track: mixpanelTrack,
  } = useMixpanel();

  // ─── AppsFlyer ───────────────────────────────

  const {
    initialize: appsFlyerInitialize,
    setCustomerUserId: appsFlyerSetCustomerUserId,
    logEvent: appsFlyerLogEvent,
  } = useAppsFlyer();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const setSuperProperty = useCallback(
    (key: string, value: unknown) => {
      logger.info(`setSuperProperty(${key}, ${JSON.stringify(value)})`);

      mixpanelSetSuperProperty(key, value);
    },
    [mixpanelSetSuperProperty],
  );

  const setCustomerProperty = useCallback(
    (key: string, value: unknown) => {
      logger.info(`setCustomerProperty(${key}, ${JSON.stringify(value)})`);

      mixpanelSetUserProperty(key, value);
    },
    [mixpanelSetUserProperty],
  );

  const setPersistentSuperProperties = useCallback(() => {
    logger.info('setPersistentSuperProperties()');

    setSuperProperty('platform', Platform.OS);
  }, [setSuperProperty]);

  const initialize = useCallback(async () => {
    logger.info('initialize()');

    try {
      await mixpanelInitialize();
    } catch (error: unknown) {
      logger.error(error);
    }

    try {
      await appsFlyerInitialize();
    } catch (error: unknown) {
      logger.error(error);
    }

    googleAnalytics.initialize();

    setPersistentSuperProperties();
  }, [appsFlyerInitialize, mixpanelInitialize, setPersistentSuperProperties]);

  const identify = useCallback(
    (telemetryUser: TelemetryUser) => {
      logger.info('identify()');

      setUser(telemetryUser);

      const { id, firstName, lastName, email, trackingUuid } = telemetryUser;

      if (id) {
        mixpanelIdentify(id);

        const name = [firstName, lastName].filter(Boolean).join(' ');

        mixpanelSetUserProperty(
          MIXPANEL_USER_PROPERTY_NAMES.appVersion,
          getAppVersion(),
        );

        if (name !== '') {
          mixpanelSetUserProperty(MIXPANEL_USER_PROPERTY_NAMES.name, name);
        }

        if (email) {
          mixpanelSetUserProperty(MIXPANEL_USER_PROPERTY_NAMES.email, email);
        }

        if (trackingUuid) {
          appsFlyerSetCustomerUserId(trackingUuid);
          mixpanelSetUserProperty(
            MIXPANEL_USER_PROPERTY_NAMES.trackingUuid,
            trackingUuid,
          );
        }
      }
    },
    [appsFlyerSetCustomerUserId, mixpanelIdentify, mixpanelSetUserProperty],
  );

  const reset = useCallback(() => {
    logger.info('reset');

    setUser(undefined);

    mixpanelReset();
    appsFlyerSetCustomerUserId(null);
    googleAnalytics.resetAnalyticsData();

    setPersistentSuperProperties();
  }, [appsFlyerSetCustomerUserId, mixpanelReset, setPersistentSuperProperties]);

  const trackEventContext = useMemo(
    () => ({ userId, setSuperProperty, setCustomerProperty }),
    [userId, setSuperProperty, setCustomerProperty],
  );

  const handleMixpanelEvent = useCallback(
    (event: MixpanelEvent) => {
      mixpanelTrack(event.name, event.payload);
    },
    [mixpanelTrack],
  );

  const handleDefaultMixpanelEvent = useCallback(
    <Name extends TelemetryEventName>(event: TelemetryEvent<Name>) => {
      mixpanelTrack(event.name, event.payload);
    },
    [mixpanelTrack],
  );

  const handleLaunchDarklyEvent = useCallback(
    (event: LaunchDarklyCustomEvent) => {
      launchDarklyTrack(event.name, event.payload);
    },
    [launchDarklyTrack],
  );

  const handleAppsFlyerEvent = useCallback(
    (appsFlyerEvent: AppsFlyerEvent) => {
      appsFlyerLogEvent(appsFlyerEvent.eventName, appsFlyerEvent.eventValues);
    },
    [appsFlyerLogEvent],
  );

  const trackEvent = useCallback(
    <Name extends TelemetryEventName>(event: TelemetryEvent<Name>) => {
      const { name, payload } = event;

      eventLogger.info(name, payload);
      logger.info(`┌─ trackEvent(${name}, ...)`);

      const overrideProps: TelemetryEventOverrideProps<Name> = {
        context: { ...trackEventContext, ...payload },
        event,
      };

      const generalProps: TelemetryEventHandlerProps = {
        context: { ...trackEventContext, ...payload },
        name,
        payload,
      };

      // ─── Pre-event handlers ──────────────────────

      preEventHandlers.forEach((handler) => {
        handler(generalProps);
      });

      // ─── Launch Darkly ───────────────────────────

      handleTelemetryEvent(
        overrideProps,
        launchDarklyEventOverrides[name],
        handleLaunchDarklyEvent,
      );

      // ─── Mixpanel ────────────────────────────────

      handleTelemetryEvent(
        overrideProps,
        mixpanelEventOverrides[name],
        handleMixpanelEvent,
        handleDefaultMixpanelEvent,
      );

      // ─── Google Analytics ────────────────────────

      handleTelemetryEvent(
        overrideProps,
        googleAnalyticsEventOverrides[name],
        googleAnalytics.track,
      );

      // ─── AppsFlyer ───────────────────────────────

      handleTelemetryEvent(
        overrideProps,
        appsFlyerEventOverrides[name],
        handleAppsFlyerEvent,
      );

      // ─── Post-event handlers ─────────────────────

      postEventHandlers.forEach((handler) => {
        handler(generalProps);
      });

      logger.info('└─');
    },
    [
      handleAppsFlyerEvent,
      handleDefaultMixpanelEvent,
      handleLaunchDarklyEvent,
      handleMixpanelEvent,
      trackEventContext,
    ],
  );

  const track = useCallback(
    <Name extends TelemetryEventName>(
      ...[name, payload]: TelemetryTrackParams<Name>
    ) => {
      trackEvent({ name, payload } as TelemetryEvent<Name>);
    },
    [trackEvent],
  );

  const value = useMemo(
    () => ({
      user,
      initialize,
      identify,
      reset,
      setSuperProperty,
      setCustomerProperty,
      trackEvent,
      track,
    }),
    [
      user,
      initialize,
      identify,
      reset,
      setSuperProperty,
      setCustomerProperty,
      trackEvent,
      track,
    ],
  );

  return (
    <TelemetryContext.Provider value={value}>
      {props.children}
    </TelemetryContext.Provider>
  );
};

/**
 * Handles a telemetry event for a vendor.
 *
 * @param props Object containing the handler context and the telemetry event.
 * @param telemetryEventOverride A function that returns an object with a pre- and post-event handler, and a vendor-specific event object.
 * @param vendorEventHandler Performs sending the vendor-specific event.
 * @param defaultHandler Performs sending the vendor-specific event if `telemetryEventHandler` is undefined.
 */
const handleTelemetryEvent = <Name extends TelemetryEventName, VendorEvent>(
  props: TelemetryEventOverrideProps<Name>,
  telemetryEventOverride: TelemetryEventOverride<Name, VendorEvent> | undefined,
  vendorEventHandler: (vendorEvent: VendorEvent) => void,
  defaultHandler?: (telemetryEvent: TelemetryEvent<Name>) => void,
) => {
  // If no override is defined, use the default handler, if specified.
  if (!telemetryEventOverride) {
    defaultHandler?.(props.event);

    return;
  }

  const overrideResult = telemetryEventOverride(props);

  // If the override result is undefined, skip handling of the event altogether.
  if (!overrideResult) return;

  const {
    pre: preEventHandler,
    event: vendorEvent,
    post: postEventHandler,
  } = overrideResult;

  // Execute the pre-event handler, if defined.
  preEventHandler?.();

  // Execute the vendor event handler, if defined.
  if (vendorEvent) {
    vendorEventHandler(vendorEvent);
  }

  // Execute the post-event handler, if defined.
  postEventHandler?.();
};

export const useTelemetry = () => {
  const context = useContext(TelemetryContext);

  if (context === undefined) {
    throw new Error('useTelemetry must be used within a <TelemetryProvider>');
  }

  return context;
};

export const useTrackEffect = <Name extends TelemetryEventName>(
  ...[name, payload]: TelemetryTrackParams<Name>
) => {
  useTrackEventEffect({ name, payload } as TelemetryEvent<Name> &
    TelemetrySkip);
};

export const useTrackEventEffect = <Name extends TelemetryEventName>(
  event: TelemetryEvent<Name> & TelemetrySkip,
) => {
  const { trackEvent } = useTelemetry();
  const { skip, ...rest } = event;

  useEffect(() => {
    if (skip) return;

    trackEvent(rest as TelemetryEvent<Name>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);
};

export function useTrackScreen() {
  const { track } = useTelemetry();

  const trackScreen = useCallback(
    (screen: string) => {
      track('app.page_view', {
        pageTitle: screen,
        pageLocation: getCurrentUrl(),
      });

      switch (screen) {
        case 'Login': {
          track('sign-in.view');
          break;
        }

        case 'Home': {
          track('home.view');
          break;
        }

        case 'ScanToPay': {
          track('scan-to-pay.view');
          break;
        }

        case 'SweetpassHome': {
          track('sweetpass.view');
          break;
        }

        case 'Challenges': {
          track('challenges.view');
          break;
        }

        case 'DietaryRestrictions': {
          track('dietary-restrictions.view');
          break;
        }

        case 'Profile': {
          track('account.view', { screen: 'profile' });
          break;
        }

        case 'SweetpassMembership': {
          track('account.view', { screen: 'sweetpass-membership' });
          break;
        }

        case 'Addresses': {
          track('account.view', { screen: 'addresses' });
          break;
        }

        case 'Orders': {
          track('account.view', { screen: 'orders' });
          break;
        }

        case 'Favorites': {
          track('account.view', { screen: 'favorites' });
          break;
        }

        case 'ReferFriend': {
          track('account.view', { screen: 'refer-friend' });
          break;
        }

        case 'AccountDietaryRestrictions': {
          track('account.view', { screen: 'dietary-restrictions' });
          break;
        }

        default: {
          break;
        }
      }
    },
    [track],
  );

  return { trackScreen };
}

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type Telemetry = Readonly<{
  user?: TelemetryUser;
  initialize: () => Promise<void>;
  identify: (user: TelemetryUser) => void;
  reset: () => void;
  setSuperProperty: (key: string, value: unknown) => void;
  setCustomerProperty: (key: string, value: unknown) => void;
  trackEvent: <Name extends TelemetryEventName>(
    event: TelemetryEvent<Name>,
  ) => void;
  track: <Name extends TelemetryEventName>(
    ...[name, payload]: TelemetryTrackParams<Name>
  ) => void;
}>;

type TelemetryUser = Readonly<{
  id?: string;
  trackingUuid?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}>;

export type TelemetrySkip = Readonly<{
  skip?: boolean;
}>;

import {
  ScanAtCheckoutHowItWorksContainer,
  ScanAtCheckoutHowItWorksItem,
  ScanAtCheckoutHowItWorksTitle,
} from './components';

export const ScanAtCheckoutHowItWorks = {
  Container: ScanAtCheckoutHowItWorksContainer,
  Title: ScanAtCheckoutHowItWorksTitle,
  Item: ScanAtCheckoutHowItWorksItem,
};

import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { Header } from '@expo/html-elements';
import { type CustomNavigationHeader, theme } from '@sg/garnish';

export const HowItWorksHeader = (props: HowItWorksHeaderProps) => {
  const { children } = props;
  return <Header style={styles.header}>{children}</Header>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: theme.spacing['4'],
    minHeight: 72,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type HowItWorksHeaderProps = ComponentProps<
  typeof CustomNavigationHeader.Container
>;

import { datadogRum } from '@datadog/browser-rum';

import { type FlagEval } from './addFeatureFlagEvaluation.types';

// NOTE: we're forwarding args, into a promise to standardize with natives (which is async)
// * web.......`const addFeatureFlagEvaluation: (key: string, value: any) => void`
// * native....`const addFeatureFlagEvaluation: (name: string, value: unknown) => Promise<void>`

export const addFeatureFlagEvaluation: FlagEval = async (...args) => {
  datadogRum.addFeatureFlagEvaluation(...args);
};

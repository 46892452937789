import React from 'react';
import { type ViewStyle } from 'react-native';

import { Loyalty } from '@order/features/loyalty';
import { useLoyaltyInfoBenefits } from '@order/hooks';

/**
 * Hooks up the loyalty profile points and rewards to the <Loyalty.InfoBenefits /> component.
 */
export const LoyaltyInfoBenefits = (props: LoyaltyInfoBenefitsProps) => {
  const { availablePoints, benefits, fetching } = useLoyaltyInfoBenefits();

  return (
    <Loyalty.InfoBenefits
      availablePoints={availablePoints}
      loyaltyInfoBenefits={benefits}
      isLoading={fetching}
      style={props.style}
    />
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyInfoBenefitsProps = {
  style?: ViewStyle;
};

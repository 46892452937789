import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { H1 } from '@expo/html-elements';
import { DisplayText, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsHeading = (props: PropsWithChildren) => {
  const { children } = props;

  const { match } = useResponsive();

  const headingStyles = [styles.heading, match([undefined, styles.headingSM])];

  return (
    <H1 style={headingStyles}>
      <DisplayText sizeMatch={['32', '48', '56']} style={styles.headingText}>
        {children}
      </DisplayText>
    </H1>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  heading: {
    marginVertical: 0, // reset default margin
  },
  headingSM: {
    textAlign: 'center',
  },
  headingText: {
    color: theme.colors.KALE,
  },
});

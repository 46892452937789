import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, type TextStyle, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import { SectionHeader } from '../../Header';
import { type IconName } from '../../Icon';
import { HorizontalScrollRailNav } from './HorizontalScrollRailNav';

// ────────────────────────────────────────────────────────────────────────────────

export const HorizontalScrollRailHeader = (
  props: HorizontalScrollRailHeaderProps,
) => {
  const {
    heading = '',
    headingIcon,
    subtitle = '',
    headerVariation,
    withoutHeaderBorder,
    count,
    countPosition,
    headerPalette,
    headerBottomSpacing = theme.spacing['4'],
    titleStyle,
    ...railNavProps
  } = props;

  const headerWrapperDynamicStyles = useStyle(
    () => ({ marginBottom: headerBottomSpacing }),
    [headerBottomSpacing],
  );

  return (
    <View style={headerWrapperDynamicStyles}>
      <SectionHeader
        heading={heading}
        headingIcon={headingIcon}
        subtitle={subtitle}
        subtitleStyle={styles.subtitle}
        variation={headerVariation}
        withoutBorder={withoutHeaderBorder}
        count={countPosition === 'left' ? count : 0}
        palette={headerPalette}
        titleStyle={titleStyle}
      >
        <HorizontalScrollRailNav
          {...railNavProps}
          headerPalette={headerPalette}
          count={countPosition === 'right' ? count : 0}
        />
      </SectionHeader>
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  subtitle: {
    color: theme.colors.CHARCOAL,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type HorizontalScrollRailHeaderProps = Readonly<{
  heading?: string;
  headingIcon?: IconName;
  headerPalette?: 'dark' | 'light';
  headerBottomSpacing?: number;
  count?: number;
  subtitle?: string;
  countPosition?: 'left' | 'right';
  headerVariation?: ComponentProps<typeof SectionHeader>['variation'];
  withoutHeaderBorder?: ComponentProps<typeof SectionHeader>['withoutBorder'];
  titleStyle?: TextStyle;
}> &
  ComponentProps<typeof HorizontalScrollRailNav>;

import type { MarkerProps, PinType } from '../types';

export const getPinType = ({
  isOutpost,
  isCourier,
  isCustomer,
  isDeliveryAddress,
}: MarkerProps): PinType => {
  if (isDeliveryAddress) return 'deliveryAddress';

  if (isCustomer) return 'customer';

  if (isCourier) return 'courier';

  return isOutpost ? 'outpost' : 'pickup';
};

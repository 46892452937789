import {
  ActiveIngredientContainer,
  ActiveIngredientPressable,
  ActiveIngredientPressableContainer,
  ActiveIngredientQuantityStepper,
  ActiveIngredientRemoveButton,
  ActiveIngredientText,
} from './components';

export const ActiveIngredient = {
  Container: ActiveIngredientContainer,
  PressableContainer: ActiveIngredientPressableContainer,
  Pressable: ActiveIngredientPressable,
  Text: ActiveIngredientText,
  RemoveButton: ActiveIngredientRemoveButton,
  QuantityStepper: ActiveIngredientQuantityStepper,
};

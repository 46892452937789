import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoNotice = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <View style={styles.container}>
      <BodyText sizeMatch={['14']}>{children}</BodyText>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.CUCUMBER,
    borderRadius: theme.radius.medium,
    marginTop: theme.spacing['3'],
    padding: theme.spacing['4'],
  },
});

import React from 'react';
import { StyleSheet } from 'react-native';
import { BodyText, theme } from '@sg/garnish';

/**
 * Subtitle for Loyalty on Bag.
 */
export const BagLoyaltySubtitle = (props: BagLoyaltySubtitleProps) => {
  const { subtitle } = props;

  return (
    <BodyText style={styles.subtitle} sizeMatch={['14']}>
      {subtitle}
    </BodyText>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  subtitle: {
    color: theme.colors.CHARCOAL,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagLoyaltySubtitleProps = {
  subtitle: string;
};

import React from 'react';
import type { ViewStyle } from 'react-native';
import { Pressable, StyleSheet, View } from 'react-native';

import { usePressableState } from '../../hooks';
import type { IconName } from '../Icon';
import { Icon } from '../Icon';
import { TextLink } from '../TextLink';

export const BackButton = ({
  testID: baseTestID,
  containerStyle,
  label,
  icon,
  uppercase,
  onPress,
  accessibilityLabel,
}: BackButtonProps) => {
  const reference = React.useRef(null);
  const pressableState = usePressableState(reference);
  const labelStyle = [styles.label, uppercase && styles.labelUppercase];
  const testID = baseTestID ?? 'sg-back-button';

  return (
    <Pressable
      ref={reference}
      accessibilityRole="button"
      accessibilityLabel={accessibilityLabel ?? label ?? 'Go back'}
      testID={testID}
      style={[styles.container, containerStyle]}
      onPress={onPress}
    >
      <View style={styles.iconWrapper}>
        <Icon name={icon ?? 'IconLinkBack'} width={24} height={24} />
      </View>

      {label ? (
        <TextLink
          testID={testID?.concat('label')}
          selectable={false}
          style={labelStyle}
          numberOfLines={1}
          underline={false}
          {...pressableState}
        >
          {label}
        </TextLink>
      ) : null}
    </Pressable>
  );
};

type BackButtonProps = Readonly<{
  label?: string;
  icon?: IconName;
  onPress: () => void;
  accessibilityLabel?: string;
  uppercase?: boolean;
  isDisabled?: boolean;
  containerStyle?: ViewStyle;
  testID?: string;
}>;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    marginLeft: 8,
  },
  labelUppercase: {
    textTransform: 'uppercase',
  },
  iconWrapper: {
    flexShrink: 0,
  },
});

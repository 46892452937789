import type { LoyaltyChallengeCardVariantsType } from './LoyaltyChallengeCard.types';

export const LoyaltyChallengeCardVariants: Record<
  LoyaltyChallengeCardVariantsType,
  LoyaltyChallengeCardVariantsType
> = {
  New: 'New',
  Ongoing: 'Ongoing',
  Completed: 'Completed',
};

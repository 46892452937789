import type { ComponentProps } from 'react';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Link } from '@react-navigation/native';
import {
  BodyText,
  Icon,
  TagLabel,
  theme,
  usePressableState,
  useResponsive,
} from '@sg/garnish';

import { type AccountTabStackParamList } from '../../../../../navigation/AppNavigation.props';

// ────────────────────────────────────────────────────────────────────────────────

export const AccountMenuLink = (props: AccountMenuLinkProps) => {
  const { testID, label, iconName, active, to: target, tag } = props;

  const ref = React.useRef(null);
  const { currentBreakpoint, match } = useResponsive();
  const { isInteracting } = usePressableState(ref);

  const shouldShowIcon = !currentBreakpoint.isSM;
  const iOsOnlyRowStyle = Platform.OS === 'ios' ? styles.rowIos : {};
  const rowStyles = [styles.row, iOsOnlyRowStyle];

  return (
    <Link
      to={target}
      testID={testID}
      accessibilityRole="link"
      accessibilityLabel={label}
    >
      <View ref={ref} style={rowStyles}>
        {shouldShowIcon ? (
          <View style={styles.iconWrapper}>
            <Icon name={iconName} />
          </View>
        ) : null}

        <BodyText size={match([2, 3])} underline={isInteracting || active}>
          {label}
        </BodyText>
        {tag ? (
          <View style={styles.tagContainer}>
            <TagLabel size={3} palette={tag.palette}>
              {tag.title}
            </TagLabel>
          </View>
        ) : null}
      </View>
    </Link>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.spacing['4'],
  },
  rowIos: {
    // Because iOS does not handle nested elements well in the 'Link' component,
    // we must remove extra space.
    marginBottom: -6,
  },
  iconWrapper: {
    paddingRight: theme.spacing['4'],
  },
  tagContainer: {
    marginLeft: theme.spacing['2'],
  },
});

// ────────────────────────────────────────────────────────────────────────────────

type AccountMenuLinkProps = Readonly<{
  to: React.ComponentProps<
    typeof Link<ReactNavigation.RootParamList & AccountTabStackParamList>
  >['to'];
  label: string;
  iconName: ComponentProps<typeof Icon>['name'];
  testID?: string;
  active?: boolean;
  tag?: {
    title: string;
    palette: ComponentProps<typeof TagLabel>['palette'];
  };
}>;

import { logger } from '@garnish/logger';

import type { FeatureFlags } from '@order/LaunchDarkly';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns a list of locations with enabled tipping with a fallback value based on
 * the provided list.
 *
 * NOTE: If the provided stores list is not in valid format (Array),
 *       the fallback value will be returned, and the corresponding message
 *       will be logged.
 */
export function getListOfLocationsWithEnabledTipping(
  maybeTargetTippingStores: unknown,
): TargetTippingStores {
  const isValidListProvided = Array.isArray(maybeTargetTippingStores);

  if (!isValidListProvided) {
    logInvalidTargetTippingStoresMessage(maybeTargetTippingStores);

    return [];
  }

  return maybeTargetTippingStores.map(String);
}

/**
 * Returns a `boolean` indicating whether tipping is enabled for all stores,
 * by checking if the first list item is equal to 'all'.
 */
export function checkIfTippingEnabledForAllStores(
  targetTippingStores: readonly string[],
): boolean {
  return targetTippingStores[0] === 'all';
}

/**
 * Logs a message indicating that the provided targeted tipping
 * stores list is invalid.
 */
export function logInvalidTargetTippingStoresMessage(
  targetTippingStores: unknown,
) {
  messageLogger.debug(
    `Target tipping stores list should be provided as an array of strings. Received value: \`${targetTippingStores}\`.`,
  );
}

// ─── Logger ──────────────────────────────────────────────────────────────────

logger.enable('useRestaurantTippingState');

const messageLogger = logger.extend('useRestaurantTippingState');

// ─── Types ───────────────────────────────────────────────────────────────────

type TargetTippingStores =
  FeatureFlags['CELS-1149-short-target-tipping-by-location-enabled'];

import type { ComponentProps } from 'react';
import React from 'react';

import { Button } from '../../../../Button';

// ─────────────────────────────────────────────────────────────────────────────

export const PageHeroHeaderCta = (props: PageHeroHeaderContainerProps) => {
  const { children, palette, onPress } = props;

  return (
    <Button size="large-wide" palette={palette} onPress={onPress}>
      {children}
    </Button>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type PageHeroHeaderContainerProps = Readonly<{
  palette: ButtonPalette;
  onPress: () => void;
  children: string;
}>;

type ButtonPalette = ComponentProps<typeof Button>['palette'];

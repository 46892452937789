import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { Container, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationSelectedIngredientsContainer = (
  props: CustomizationSelectedIngredientsContainerProps,
) => {
  const { children, style, wrapperStyle, ...restProps } = props;

  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const outerContainerStyles = [
    styles.outerContainer,
    match([styles.outerContainerXS, styles.outerContainerSM]),
    wrapperStyle,
  ];
  const containerStyles = [styles.container, style];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Container
      wrapperStyle={outerContainerStyles}
      style={containerStyles}
      {...restProps}
    >
      {children}
    </Container>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
  },
  outerContainerXS: {
    marginTop: theme.spacing['3'],
    marginBottom: theme.spacing['4'],
  },
  outerContainerSM: {
    marginVertical: theme.spacing['6'],
  },
  container: {
    rowGap: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationSelectedIngredientsContainerProps = ComponentProps<
  typeof Container
>;

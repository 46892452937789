import React, {
  type ComponentProps,
  type PropsWithChildren,
  type ReactNode,
  useRef,
} from 'react';
import { StyleSheet, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { Link } from '@react-navigation/native';
import { theme } from '@garnish/constants';

import { webOnlyStyles } from '../../utils';
import { Icon } from '../Icon';
import { BodyText } from '../Text';

// ─────────────────────────────────────────────────────────────────────────────

const GiftCardOrderCardContainer = (props: PropsWithChildren) => {
  return (
    <View style={styles.container} pointerEvents="none">
      {props.children}
    </View>
  );
};

const GiftCardOrderCardContentContainer = (props: PropsWithChildren) => {
  return <View style={styles.contentContainer}>{props.children}</View>;
};

const GiftCardOrderCardExtraDetailsContainer = (props: PropsWithChildren) => {
  return <View style={styles.extraDetailsContainer}>{props.children}</View>;
};

/**
 * Renders React Navigation `Link` component with predefined styles.
 *
 * NOTE: This component should be used as an absolutely positioned pressable
 * component that will take its parent's full width and height.
 *
 * Use it on the same level as `GiftCardOrderCard.Container`
 */
const GiftCardOrderCardLink = (props: GiftCardOrderCardLinkProps) => {
  const { style, ...restProps } = props;

  const pressableRef = useRef(null);
  const isHovered = useHover(pressableRef);

  const pressableStyles = [
    styles.pressable,
    pressableWebStyles,
    isHovered ? styles.pressableHover : undefined,
    style,
  ];

  return (
    <Link style={pressableStyles} {...restProps}>
      <View style={StyleSheet.absoluteFill} ref={pressableRef} />
    </Link>
  );
};

const GiftCardOrderCardIcon = () => {
  return (
    <View style={styles.iconContainer}>
      <Icon name="IconPromo" />
    </View>
  );
};

const GiftCardOrderCardText = (props: GiftCardOrderCardTextProps) => {
  const { children, isBold } = props;

  const textStyles = isBold ? styles.textBold : styles.text;

  return (
    <BodyText
      sizeMatch={['12', '16']}
      bold={isBold}
      style={textStyles}
      maxFontSizeMultiplier={1.3}
    >
      {children}
    </BodyText>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardOrderCard = {
  Container: GiftCardOrderCardContainer,
  ContentContainer: GiftCardOrderCardContentContainer,
  ExtraDetailsContainer: GiftCardOrderCardExtraDetailsContainer,
  Link: GiftCardOrderCardLink,
  Icon: GiftCardOrderCardIcon,
  Text: GiftCardOrderCardText,
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    columnGap: theme.spacing['4'],
    paddingVertical: theme.spacing['2'],
  },
  contentContainer: {
    flex: 1,
  },
  extraDetailsContainer: {
    alignItems: 'flex-end',
  },
  pressable: {
    ...StyleSheet.absoluteFillObject,
    margin: -theme.spacing['2'],
    borderRadius: theme.radius.medium,
  },
  pressableHover: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
  iconContainer: {
    alignSelf: 'center',
  },
  textBold: {
    color: theme.colors.TEXT_COLOR,
  },
  text: {
    color: theme.colors.CHARCOAL,
  },
});

const pressableWebStyles = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: theme.spacing['1'],
  outlineWidth: 2,
  transition: `background-color ${theme.transitions.base}ms`,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardOrderCardLinkProps = Omit<ComponentProps<typeof Link>, 'children'>;

type GiftCardOrderCardTextProps = {
  children: ReactNode;
  isBold?: boolean;
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UnableToGetGiftCardBalanceErrorFieldsFragment = {
  readonly __typename?: 'UnableToGetGiftCardBalanceError';
  readonly errorMessage: string;
};

export type GiftCardBalanceFieldsFragment = {
  readonly __typename?: 'GiftCardBalance';
  readonly customerId: string;
  readonly giftCardBalance: number;
};

export type GiftCardBalanceQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GiftCardBalanceQuery = {
  readonly __typename?: 'Query';
  readonly giftCardBalance:
    | {
        readonly __typename: 'GiftCardBalance';
        readonly customerId: string;
        readonly giftCardBalance: number;
      }
    | {
        readonly __typename: 'UnableToGetGiftCardBalanceError';
        readonly errorMessage: string;
      };
};

export const UnableToGetGiftCardBalanceErrorFieldsFragmentDoc = gql`
  fragment UnableToGetGiftCardBalanceErrorFields on UnableToGetGiftCardBalanceError {
    errorMessage
  }
`;
export const GiftCardBalanceFieldsFragmentDoc = gql`
  fragment GiftCardBalanceFields on GiftCardBalance {
    customerId
    giftCardBalance
  }
`;
export const GiftCardBalanceDocument = gql`
  query GiftCardBalance {
    giftCardBalance {
      __typename
      ...UnableToGetGiftCardBalanceErrorFields
      ...GiftCardBalanceFields
    }
  }
  ${UnableToGetGiftCardBalanceErrorFieldsFragmentDoc}
  ${GiftCardBalanceFieldsFragmentDoc}
`;

export function useGiftCardBalanceQuery(
  options?: Omit<Urql.UseQueryArgs<GiftCardBalanceQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GiftCardBalanceQuery, GiftCardBalanceQueryVariables>({
    query: GiftCardBalanceDocument,
    ...options,
  });
}

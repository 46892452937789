import { useResponsive } from '../useResponsive';
import { styles } from './useResponsiveStyles.styles';
import type { MobileStyles } from './useResponsiveStyles.types';

export const useResponsiveStyles = (): MobileStyles => {
  const { currentBreakpoint } = useResponsive();
  const { mobileDirection, mobileAlignment, mobileTextAlignment } = styles;
  const { desktopDirection, desktopAlignment, desktopTextAlignment } = styles;

  return currentBreakpoint.isXS
    ? {
        direction: mobileDirection,
        alignment: mobileAlignment,
        textAlignment: mobileTextAlignment,
      }
    : {
        direction: desktopDirection,
        alignment: desktopAlignment,
        textAlignment: desktopTextAlignment,
      };
};

import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';

// ─────────────────────────────────────────────────────────────────────────────

export const useProductDetailsContentNavigation = (
  params: UseProductDetailsContentNavigationParams,
) => {
  const { restaurantSlug, addressId } = params;

  const { canGoBack, goBack, navigate } = useNavigation();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToMenuScreen = useCallback(() => {
    if (addressId) {
      navigate('MainTabs', {
        screen: 'MenuTab',
        params: {
          screen: 'DeliveryMenu',
          params: { addressId },
        },
      });

      return;
    }

    if (restaurantSlug) {
      navigate('MainTabs', {
        screen: 'MenuTab',
        params: {
          screen: 'Menu',
          params: { restaurantSlug },
        },
      });
    }
  }, [addressId, navigate, restaurantSlug]);

  const navigateToPreviousScreen = useCallback(() => {
    if (canGoBack()) {
      goBack();

      return;
    }

    navigateToMenuScreen();
  }, [canGoBack, goBack, navigateToMenuScreen]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    navigateToPreviousScreen,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseProductDetailsContentNavigationParams = {
  restaurantSlug: string | undefined;
  addressId: string | undefined;
};

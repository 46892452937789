import { useMemo } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { type RequestPolicy } from 'urql';

import { useIsLoggedIn } from '@order/AuthMachine';
import { OrderType } from '@order/graphql';
import {
  checkIfExpiredOrder,
  checkIfLateOrder,
  checkIfPastOrder,
  getOrderTime,
} from '@order/OrderStatus';
import { getOrderTimeRange, ignoreTimezone } from '@order/utils';

import { useLastOrderStatusQuery } from './LastOrderStatus.query.generated';

export const useLastOrderStatus = (requestPolicy: RequestPolicy) => {
  const isScreenFocused = useIsFocused();
  const isLoggedIn = useIsLoggedIn();

  // ─── Order Status Query ───────────────────────────────────────────────────

  const response = useLastOrderStatusQuery({
    requestPolicy,
    pause: !isScreenFocused || !isLoggedIn,
  });

  const {
    orderId,
    orderType,
    orderStatus,
    orderTime,
    startTime,
    endTime,
    isRecent,
    isLate,
    isPendingFeedback,
    isRated,
    isTrackable,
    isLoadingLastOrder,
  } = getLastOrderInfo(response);

  return useMemo(
    () => ({
      orderId,
      orderType,
      orderStatus,
      orderTime,
      startTime,
      endTime,
      isRecent,
      isLate,
      isPendingFeedback,
      isRated,
      isTrackable,
      isLoadingLastOrder,
    }),
    [
      orderId,
      orderType,
      orderStatus,
      orderTime,
      startTime,
      endTime,
      isRecent,
      isLate,
      isPendingFeedback,
      isRated,
      isTrackable,
      isLoadingLastOrder,
    ],
  );
};

const getLastOrderInfo = (
  response: ReturnType<typeof useLastOrderStatusQuery>,
) => {
  const [{ data: status, fetching: isLoadingLastOrder }] = response;

  if (status?.orderStatus?.__typename !== 'OrderStatus') {
    return {
      orderId: null,
      orderType: OrderType.Pickup,
      orderStatus: null,
      orderTime: null,
      isRecent: false,
      isLate: false,
      isPendingFeedback: false,
      isTrackable: false,
      isLoadingLastOrder,
    };
  }

  const lastOrder = status.orderStatus;

  // ─── Recent ───────────────────────────────────────────────────────────────

  const isRecent = !checkIfExpiredOrder({
    order: lastOrder.order,
    hoursInThePast: 3,
  });

  // ─── Late ─────────────────────────────────────────────────────────────────

  const isLate = checkIfLateOrder(lastOrder);

  // ─── Pending Feedback ─────────────────────────────────────────────────────

  const isLastOrderCompleted = checkIfPastOrder({
    order: lastOrder.order,
    flattenedOrderStatus: lastOrder.flattenedOrderStatus,
  });

  const isLastOrderExpired = checkIfExpiredOrder(lastOrder);

  const isRated = !lastOrder.order.isPendingFeedback;
  const isPendingFeedback =
    !isLastOrderExpired && isLastOrderCompleted && !isRated;

  // ─── Trackable ────────────────────────────────────────────────────────────

  const isTrackable = Boolean(lastOrder.order.canTrackOrderStatus);

  // ─── Last Order Info ──────────────────────────────────────────────────────

  const orderId = lastOrder.order.id;
  const { orderType } = lastOrder.order;
  const orderStatus = lastOrder.flattenedOrderStatus;
  const timeWithTimezone = getOrderTime(lastOrder.order);
  const orderTime = timeWithTimezone ? ignoreTimezone(timeWithTimezone) : null;
  const { startTime = '', endTime = '' } = orderTime
    ? getOrderTimeRange(orderTime)
    : {};

  return {
    orderId,
    orderType,
    orderStatus,
    orderTime,
    startTime,
    endTime,
    isRecent,
    isLate,
    isPendingFeedback,
    isRated,
    isTrackable,
    isLoadingLastOrder,
  };
};

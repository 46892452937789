import React, { useCallback, useMemo, useState } from 'react';
import type { LayoutChangeEvent } from 'react-native';
import { StyleSheet } from 'react-native';

import { LoadingPlaceholder } from '../LoadingPlaceholder';

// ────────────────────────────────────────────────────────────────────────────────

export const CollectionHeroLoadingPlaceholder = (
  props: CollectionHeroLoadingPlaceholderProps,
) => {
  const { height, speed } = props;

  return (
    <LoadingPlaceholder
      borderRadius={0}
      palette="cream"
      rowHeight={height}
      speed={speed}
      style={styles.loadingPlaceholder}
    />
  );
};

export const useCollectionHeroLoadingPlaceholder = () => {
  const [wrapperHeight, setWrapperHeight] = useState(0);

  const updateWrapperHeight = useCallback((event: LayoutChangeEvent) => {
    setWrapperHeight(event.nativeEvent.layout.height);
  }, []);

  return useMemo(
    () => ({ wrapperHeight, updateWrapperHeight }),
    [updateWrapperHeight, wrapperHeight],
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  loadingPlaceholder: {
    flexGrow: 1,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type CollectionHeroLoadingPlaceholderProps = Readonly<{
  height: number;
  speed?: number;
}>;

import type React from 'react';
import type { Link } from '@react-navigation/native';

import { getAppNavigationContainerRef } from '../../navigation';
import type { AccountTabStackParamList } from '../../navigation/AppNavigation.props';

// Logging out requires resetting the stacks from the container reference.
// Otherwise the previous user's screens will still be present in the stacks.
export const resetStacksPostLogout = () => {
  getAppNavigationContainerRef().current?.reset({
    routes: [
      {
        name: 'MainTabs',
        params: {
          screen: 'AccountTab',
          params: { screen: 'AccountMenu' },
        },
      },
    ],
  });
};

// ─── Static Links Config ──────────────────────────────────────────────────────

export const accountMenuStaticLinkConfig: Record<
  string,
  React.ComponentProps<
    typeof Link<ReactNavigation.RootParamList & AccountTabStackParamList>
  >['to']
> = {
  profileLinkConfig: {
    screen: 'Profile',
  },
  sweetpassJoinLinkConfig: {
    screen: 'Modal',
    params: { screen: 'SweetpassUpgrade' },
  },
  paymentAndGiftCardsLinkConfig: {
    screen: 'PaymentAndGiftCards',
  },
  creditAndPromoCodesLinkConfig: {
    screen: 'CreditAndPromoCodes',
  },
  sweetpassMembershipLinkConfig: {
    screen: 'SweetpassMembership',
  },
  addressesLinkConfig: {
    screen: 'Addresses',
  },
  ordersLinkConfig: {
    screen: 'Orders',
  },
  favoritesLinkConfig: {
    screen: 'Favorites',
  },
  referFriendLinkConfig: {
    screen: 'ReferFriend',
  },
  dietaryRestrictionsLinkConfig: {
    screen: 'AccountDietaryRestrictions',
  },
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View, type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';

import { Button } from '../Button';
import { FadeView } from '../FadeView';
import { IconLink } from '../Icon';
import { LoadingDots } from '../LoadingDots';
import { QuantityStepper } from './QuantityStepper';

export const ButtonQuantityStepper = (props: ButtonQuantityStepperProps) => {
  const {
    onSubmit,
    size = 'md',
    iconName,
    iconSize,
    isDisabled,
    isLoading,
    onStepperVisibilityChange,
    onQuantityIncrease,
    onQuantityDecrease,
    initialQuantity = 1,
    autoCloseTimeout = 2000,
    accessibilityLabel,
    accessibilityHint,
    loadingAccessibilityLabel,
    toggleTestID = 'stepper-toggle',
    ...rest
  } = props;

  // ─── State ─────────────────────────────────────────────────────────────────────

  const [showStepper, setShowStepper] = useState(false);
  const [quantity, setQuantity] = useState(initialQuantity);

  // ─── Callbacks ─────────────────────────────────────────────────────────────────────

  const openStepper = useCallback(async () => {
    setShowStepper(true);
  }, []);

  const closeStepper = useCallback(() => {
    setShowStepper(false);
  }, [setShowStepper]);

  const increaseQuantity = useCallback(
    (updatedQuantity: number) => {
      setQuantity(updatedQuantity);
      onQuantityIncrease?.(updatedQuantity);
    },
    [onQuantityIncrease],
  );

  const decreaseQuantity = useCallback(
    (updatedQuantity: number) => {
      setQuantity(updatedQuantity);
      onQuantityDecrease?.(updatedQuantity);
    },
    [onQuantityDecrease],
  );

  const handleSubmit = useCallback(
    (submittedQuantity: number) => {
      onSubmit(submittedQuantity);
      setShowStepper(false);
    },
    [onSubmit, setShowStepper],
  );

  useEffect(() => {
    onStepperVisibilityChange?.(showStepper);
  }, [showStepper, onStepperVisibilityChange]);

  const stepperChangesTimeout = useRef<ReturnType<typeof setTimeout>>();

  // If there is no action after specified time, hide the stepper if it's open and
  // call the onSubmit callback with the quantity.
  useEffect(() => {
    if (stepperChangesTimeout.current) {
      clearTimeout(stepperChangesTimeout.current);
    }

    if (showStepper) {
      // eslint-disable-next-line functional/immutable-data
      stepperChangesTimeout.current = setTimeout(() => {
        handleSubmit(quantity);
      }, autoCloseTimeout);
    }

    return () => {
      clearTimeout(stepperChangesTimeout.current);
    };
  }, [
    stepperChangesTimeout,
    quantity,
    showStepper,
    autoCloseTimeout,
    handleSubmit,
  ]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const toggleContainerSize = SIZE_STYLE_PROPS[size].TOGGLE_SIZE;
  const toggleIconSize = iconSize ?? SIZE_STYLE_PROPS[size].TOGGLE_SIZE;

  const quantityStepperSizeStyles = sizeStyles[size];

  // ─────────────────────────────────────────────────────────────────────

  if (isLoading) {
    return (
      <View
        testID="button-quantity-stepper-loading-dots"
        style={[
          styles.loadingWrapper,
          quantityStepperSizeStyles.loadingWrapper,
        ]}
        accessibilityLabel={loadingAccessibilityLabel}
        accessibilityRole="alert"
      >
        <LoadingDots color={theme.colors.WHITE} />
      </View>
    );
  }

  if (!showStepper && iconName) {
    return (
      <IconLink
        name={iconName}
        width={toggleContainerSize}
        height={toggleContainerSize}
        iconSize={toggleIconSize}
        onPress={openStepper}
        disabled={isDisabled}
        style={styles.button}
        testID={`${toggleTestID}-icon`}
        accessibilityLabel={accessibilityLabel}
        accessibilityHint={accessibilityHint}
        accessibilityRole="button"
      />
    );
  }

  if (!showStepper) {
    return (
      <Button
        palette="primary"
        style={[styles.quantityView, quantityStepperSizeStyles.quantityView]}
        onPress={openStepper}
        disabled={isDisabled}
        testID={toggleTestID}
        accessibilityLabel={accessibilityLabel}
        accessibilityHint={accessibilityHint}
      >
        {quantity > 99 ? '99+' : `${quantity}×`}
      </Button>
    );
  }

  return (
    <View
      style={[styles.stepperWrapper, quantityStepperSizeStyles.stepperWrapper]}
    >
      <FadeView
        show={showStepper}
        style={[
          styles.quantityStepper,
          quantityStepperSizeStyles.quantityStepper,
        ]}
      >
        <QuantityStepper
          onQuantityDecrease={decreaseQuantity}
          onQuantityIncrease={increaseQuantity}
          initialQuantity={initialQuantity}
          onClose={closeStepper}
          {...rest}
        />
      </FadeView>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const SIZE_STYLE_PROPS: ButtonQuantityStepperSizeStyleProps = {
  sm: {
    TOGGLE_SIZE: 32,
    STEPPER_WIDTH: 102,
    STEPPER_HEIGHT: 40,
    STEPPER_BORDER_RADIUS: 68,
  },
  md: {
    TOGGLE_SIZE: 40,
    STEPPER_WIDTH: 122,
    STEPPER_HEIGHT: 48,
    STEPPER_BORDER_RADIUS: 62,
  },
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  quantityView: {
    alignItems: 'center',
    justifyContent: 'center',

    // reset button padding
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  loadingWrapper: {
    backgroundColor: theme.colors.GREEN_2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepperWrapper: {
    position: 'relative',
    zIndex: 100,
  },
  quantityStepper: {
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: theme.colors.KALE,
    overflow: 'hidden',
    ...theme.elevations['3'],
  },
});

const sizeStyles: ButtonQuantityStepperSizeStyles = {
  sm: getStylesForSize('sm'),
  md: getStylesForSize('md'),
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function getStylesForSize(size: ButtonQuantityStepperSize): SizeStylesheets {
  return {
    quantityView: {
      width: SIZE_STYLE_PROPS[size].TOGGLE_SIZE,
      height: SIZE_STYLE_PROPS[size].TOGGLE_SIZE,
      borderRadius: SIZE_STYLE_PROPS[size].TOGGLE_SIZE,
    },
    loadingWrapper: {
      borderRadius: SIZE_STYLE_PROPS[size].STEPPER_BORDER_RADIUS,
      width: SIZE_STYLE_PROPS[size].TOGGLE_SIZE,
      height: SIZE_STYLE_PROPS[size].TOGGLE_SIZE,
    },
    stepperWrapper: {
      height: SIZE_STYLE_PROPS[size].TOGGLE_SIZE,
    },
    quantityStepper: {
      top: SIZE_STYLE_PROPS[size].TOGGLE_SIZE / 2,
      left: -(SIZE_STYLE_PROPS[size].TOGGLE_SIZE * 1.5),
      borderRadius: SIZE_STYLE_PROPS[size].STEPPER_BORDER_RADIUS,
      width: SIZE_STYLE_PROPS[size].STEPPER_WIDTH,
      height: SIZE_STYLE_PROPS[size].STEPPER_HEIGHT,
      transform: [
        { translateX: -(SIZE_STYLE_PROPS[size].STEPPER_WIDTH / 2) },
        { translateY: -(SIZE_STYLE_PROPS[size].STEPPER_HEIGHT / 2) },
      ],
    },
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type ButtonQuantityStepperProps = Readonly<{
  onSubmit: (quantity: number) => void;
  size?: ButtonQuantityStepperSize;
  iconName?: React.ComponentProps<typeof IconLink>['name'];
  iconSize?: number;
  initialQuantity?: number;
  isDisabled?: boolean;
  isLoading?: boolean;
  onStepperVisibilityChange?: (isVisible: boolean) => void;
  autoCloseTimeout?: number;
  accessibilityLabel?: string;
  accessibilityHint?: string;
  loadingAccessibilityLabel?: string;
  toggleTestID?: string;
}> &
  React.ComponentProps<typeof QuantityStepper>;

type ButtonQuantityStepperSizeStyles = Record<
  ButtonQuantityStepperSize,
  ReturnType<typeof StyleSheet.create<SizeStylesheets>>
>;

type SizeStylesheets = Record<
  'quantityView' | 'loadingWrapper' | 'stepperWrapper' | 'quantityStepper',
  ViewStyle
>;

type ButtonQuantityStepperSizeStyleProps = Record<
  ButtonQuantityStepperSize,
  Record<
    | 'TOGGLE_SIZE'
    | 'STEPPER_BORDER_RADIUS'
    | 'STEPPER_WIDTH'
    | 'STEPPER_HEIGHT',
    number
  >
>;

type ButtonQuantityStepperSize = 'sm' | 'md';

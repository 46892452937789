import React from 'react';
import { type ImageSourcePropType, StyleSheet, View } from 'react-native';
import { Image } from 'expo-image';
import { ContentfulImage, Text, theme } from '@sg/garnish';

export const JoinSgRewardsRowItem = (props: JoinSgRewardsRowItemProps) => {
  const { title, image } = props;

  return (
    <View style={styles.row}>
      {image.url ? (
        <ContentfulImage
          options={{ w: 40, h: 40 }}
          style={styles.image}
          baseUrl={image.url}
        />
      ) : (
        <Image style={styles.image} source={image.fallbackSource} />
      )}
      <Text style={styles.text}>{title}</Text>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    paddingHorizontal: theme.spacing['6'],
    gap: theme.spacing['3'],
    alignItems: 'center',
  },
  text: {
    color: theme.colors.OATMEAL,
  },
  image: {
    width: 40,
    height: 40,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type JoinSgRewardsRowItemProps = {
  title: string;
  image: {
    url?: string;
    fallbackSource?: ImageSourcePropType;
    description: string;
  };
};

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { MenuHeaderDetailsTextItem } from '../MenuHeaderDetailsTextItem';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuHeaderDetailsLocationText = (
  props: MenuHeaderDetailsTextProps,
) => {
  const { locationName, orderChannel } = props;

  const { formatMessage } = useIntl();

  const orderChannelText = formatMessage(messages.locationText, {
    order_channel: orderChannel,
  });

  return (
    <MenuHeaderDetailsTextItem>
      {orderChannelText ? `${orderChannelText} ${locationName}` : locationName}
    </MenuHeaderDetailsTextItem>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  locationText: {
    defaultMessage: `{order_channel, select,
      pickup {Pickup from}
      delivery {Delivery to}
      outpost {Outpost at}
      other {}
    }`,
    description: 'Menu | Header | Location text | Pickup',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuHeaderDetailsTextProps = {
  orderChannel: 'pickup' | 'outpost' | 'delivery';
  locationName: string;
};

import React, { type ReactNode } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { H3 } from '@expo/html-elements';
import { BodyText, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardRedemptionNumberSectionHeading = (
  props: GiftCardRedemptionNumberAndCodeCopyButtonProps,
) => {
  const { children } = props;

  return (
    <H3 style={styles.sectionHeading}>
      <BodyText sizeMatch={['14']} style={styles.sectionHeadingText} bold>
        {children}
      </BodyText>
    </H3>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  sectionHeading: {
    // NOTE: Because bold and non-bold fonts originally have a different
    //       line height on the web platform, we add extra spacing to
    //       align them.
    paddingTop: Platform.select({
      web: theme.spacing['1'] + 1,
      default: theme.spacing['1'],
    }),
    paddingBottom: theme.spacing['1'],

    // NOTE: Resets default styles

    display: 'flex',
    marginVertical: 0,
  },
  sectionHeadingText: {
    color: theme.colors.KALE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardRedemptionNumberAndCodeCopyButtonProps = {
  children: ReactNode;
};

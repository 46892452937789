import {
  createMatchByBreakpointIndex,
  getBreakpointsByMinWidth,
  getCurrentBreakpoint,
  getScreenOrientation,
} from './utils';

/**
 * Returns different utilities and values to manage responsiveness.
 */
export function getResponsiveHelpers(screenDimensions: ScreenDimensions) {
  const { width: screenWidth, height: screenHeight } = screenDimensions;

  const minWidth = getBreakpointsByMinWidth(screenWidth);
  const currentBreakpoint = getCurrentBreakpoint(minWidth);
  const match = createMatchByBreakpointIndex(currentBreakpoint);
  const orientation = getScreenOrientation(screenWidth, screenHeight);

  return {
    screenDimensions,
    minWidth,
    currentBreakpoint,
    orientation,
    match,
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type ScreenDimensions = Readonly<{
  width: number;
  height: number;
}>;

import type { ComponentProps } from 'react';
import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import {
  BodyText,
  Button,
  formatDate,
  Modal,
  ModalDialogue,
  theme,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { useNavigateToMainTabs } from '../../navigation';
import type { ChallengeCompletionModal } from './ChallengeCompletionModal';
import { useChallengeCompletionTelemetry } from './hooks';

// ─────────────────────────────────────────────────────────────────────────

export const ChallengeCelebrationLegacyModal = (
  props: ComponentProps<typeof ChallengeCompletionModal>,
) => {
  const { challengeCompletionDetails } = props;

  // ─── State ───────────────────────────────────────────────────────────

  const [isModalVisible, setIsModalVisible] = useState(true);

  const {
    trackNavigateToLoyaltyTab,
    trackChallengeCompletionModalShow,
    trackChallengeCompletionModalDismiss,
  } = useChallengeCompletionTelemetry();

  const { t } = useLocalizationContext();
  const navigateToMainTabs = useNavigateToMainTabs();

  // ─── Derived data ────────────────────────────────────────────────────

  const expirationDate = challengeCompletionDetails.expirationDate
    ? new Date(challengeCompletionDetails.expirationDate)
    : null;
  const formattedExpirationDate = formatDate(expirationDate);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToLoyaltyTab = useCallback(() => {
    trackNavigateToLoyaltyTab();

    void navigateToMainTabs('LoyaltyTab', {
      screen: 'SweetpassHome',
      params: { target: 'rewards' },
    });
  }, [navigateToMainTabs, trackNavigateToLoyaltyTab]);

  const dismissChallengeCompletionModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleNavigateToLoyaltyTab = useCallback(() => {
    dismissChallengeCompletionModal();
    navigateToLoyaltyTab();
  }, [dismissChallengeCompletionModal, navigateToLoyaltyTab]);

  const onChallengeCompletionModalShown = useCallback(() => {
    trackChallengeCompletionModalShow(challengeCompletionDetails);
  }, [challengeCompletionDetails, trackChallengeCompletionModalShow]);

  const onChallengeCompletionModalDismissed = useCallback(() => {
    trackChallengeCompletionModalDismiss();
  }, [trackChallengeCompletionModalDismiss]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ModalDialogue
      size="small"
      animationType="fade"
      palette="secondary"
      accessibilityLabel={t('challenges.rewards.celebration.a11y-title')}
      visible={isModalVisible}
      headline={challengeCompletionDetails.celebrationCopy}
      subhead={challengeCompletionDetails.title ?? undefined}
      onRequestClose={dismissChallengeCompletionModal}
      onShown={onChallengeCompletionModalShown}
      onDismissed={onChallengeCompletionModalDismissed}
    >
      {formattedExpirationDate ? (
        <Modal.Row style={[styles.expirationRow, styles.row]}>
          <BodyText size={3} style={styles.textWhite}>
            {t('general.ends-on-date', { date: formattedExpirationDate })}.
          </BodyText>
        </Modal.Row>
      ) : null}

      <Modal.Row style={styles.row}>
        <BodyText size={3} style={styles.textWhite}>
          {challengeCompletionDetails.rewardUsageDisclaimer}
        </BodyText>
      </Modal.Row>

      <Modal.Footer withoutTopBorder style={styles.footer}>
        <Button
          palette="primary"
          onPress={handleNavigateToLoyaltyTab}
          testID="challenge.celebration.modal.cta"
        >
          {t('challenges.rewards.see-my-rewards')}
        </Button>
      </Modal.Footer>
    </ModalDialogue>
  );
};

// ─── STYLES ──────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  footer: {
    paddingTop: theme.spacing['6'],
  },
  expirationRow: {
    paddingBottom: theme.spacing['6'],
  },
  row: {
    paddingTop: theme.spacing['4'],
  },
  textWhite: {
    color: theme.colors.WHITE,
  },
});

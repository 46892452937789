import type { ComponentProps } from 'react';
import React, { useMemo } from 'react';
import type { ImageSourcePropType } from 'react-native';
import { getContentfulImageUrl, PageHeroHeader } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const SweetpassLandingPageHeader = (
  props: SweetpassLandingPageHeaderProps,
) => {
  const {
    palette,
    title,
    text,
    imgUrl,
    imgA11yLabel,
    ctaLabel,
    withTopPadding,
    onCtaPress,
  } = props;

  // ─────────────────────────────────────────────────────────────────────

  const imgSource: ImageSourcePropType | undefined = useMemo(() => {
    if (!imgUrl) return;

    return { uri: getContentfulImageUrl(imgUrl, CONTENTFUL_IMAGE_CONFIG) };
  }, [imgUrl]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <PageHeroHeader
      title={title}
      text={text}
      ctaLabel={ctaLabel}
      palette={palette}
      imgSource={imgSource}
      imgA11yLabel={imgA11yLabel}
      withTopPadding={withTopPadding}
      onCtaPress={onCtaPress}
    />
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const CONTENTFUL_IMAGE_CONFIG = { fm: 'webp', q: 90, h: 500 } as const;

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassLandingPageHeaderProps = PageHeroHeaderPartialProps &
  Readonly<{
    imgUrl?: string;
  }>;

type PageHeroHeaderPartialProps = Omit<
  ComponentProps<typeof PageHeroHeader>,
  'imgSource'
>;

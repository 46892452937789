/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ApplyPromoCodeMutationVariables = Types.Exact<{
  input: Types.SubmitPromoOrGiftCardCodeInput;
}>;

export type ApplyPromoCodeMutation = {
  readonly __typename?: 'Mutation';
  readonly submitPromoOrGiftCardCode:
    | {
        readonly __typename: 'InvalidPromoCode';
        readonly message: string;
        readonly status: number;
      }
    | { readonly __typename: 'PromoCodeAlreadyApplied' }
    | { readonly __typename: 'SubmitGiftCardCodeSuccess' }
    | { readonly __typename: 'SubmitPromoCodeSuccessV2' }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export const ApplyPromoCodeDocument = gql`
  mutation applyPromoCode($input: SubmitPromoOrGiftCardCodeInput!) {
    submitPromoOrGiftCardCode(input: $input) {
      __typename
      ... on InvalidPromoCode {
        message
        status
      }
      ... on ValidationError {
        message
        status
        fieldErrors {
          field
          message
        }
      }
    }
  }
`;

export function useApplyPromoCodeMutation() {
  return Urql.useMutation<
    ApplyPromoCodeMutation,
    ApplyPromoCodeMutationVariables
  >(ApplyPromoCodeDocument);
}

import React, { type ComponentProps } from 'react';
import { theme } from '@garnish/constants';

import { HStack } from '../../../Stack';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders a `View` component with the predefined `radiogroup` accessibility role.
 * Should be used as a wrapper to render radio elements.
 */
export const RadioGroup = (props: RadioContainerGroupProps) => {
  const { children, ...restProps } = props;

  return (
    <HStack
      accessibilityRole="radiogroup"
      gap={theme.spacing['3']}
      {...restProps}
    >
      {children}
    </HStack>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type RadioContainerGroupProps = Omit<
  ComponentProps<typeof HStack>,
  'accessibilityRole'
>;

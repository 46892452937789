import React, { useCallback, useLayoutEffect } from 'react';
import { SectionHeader } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import {
  ApplePay,
  CheckoutLayout,
  CheckoutLayoutBlock,
  CheckoutLayoutColumn,
  LoadingAnimation,
  NotFoundView,
  PaymentBlockCardIcons,
  SignedOutView,
  useApplePay,
} from '@order/components';
import { useLocalizationContext } from '@order/Localization';

import { useNavigateToMainTabs } from '../../navigation';
import type { CheckoutScreenProps } from '../../navigation/AppNavigation.props';
import { useReorderState } from '../ReorderingScreen';
import {
  CheckoutContextProvider,
  useCheckoutContext,
} from './CheckoutProvider';
import {
  CheckoutAddressForm,
  CheckoutBlockVStack,
  CheckoutWrapper,
  ConnectedCheckoutDeliveryTipBlock,
  ConnectedCheckoutExtrasBlock,
  ConnectedCheckoutHeader,
  ConnectedCheckoutOrderBlock,
  ConnectedCheckoutPaymentBlockAvailableCredit,
  ConnectedCheckoutPaymentBlockCreditCardPicker,
  ConnectedCheckoutPaymentBlockPromoCode,
  ConnectedCheckoutRestaurantTipBlock,
  ConnectedCustomerOptions,
  ConnectedDeliveryOptions,
  ConnectedFutureDateModal,
  ConnectedOutpostOptions,
  ConnectedPickupOptions,
  ConnectedRewardExceptionModal,
} from './components';
import {
  useCheckoutViewEventEffect,
  useDeliveryReorder,
  useSyncDeliveryOrderDetails,
} from './hooks';

export const CheckoutScreen = (props: CheckoutScreenProps) => {
  const {
    applePayHostedFrameRef,
    applePayHandlersRef,
    isSettingUpApplePay,
    isApplePayReady,
    sendShowPaymentSheetRequest,
    handleApplePayMessages,
  } = useApplePay();

  return (
    <>
      <CheckoutContextProvider
        applePayHandlersRef={applePayHandlersRef}
        isSettingUpApplePay={isSettingUpApplePay}
        isApplePayReady={isApplePayReady}
        sendShowPaymentSheetRequest={sendShowPaymentSheetRequest}
      >
        <CheckoutScreenContent {...props} />
      </CheckoutContextProvider>

      <ApplePay.HostedFrame
        ref={applePayHostedFrameRef}
        onMessage={handleApplePayMessages}
      />
    </>
  );
};

export const CheckoutScreenContent = (props: CheckoutScreenProps) => {
  const { navigation } = props;

  const { t } = useLocalizationContext();
  const checkoutContext = useCheckoutContext();

  const {
    customer,
    cart,
    cartOrderType,
    isCartValid,
    orderAddress,
    addressId,
    isCartPickup,
    isCartOutpost,
    isCartDelivery,
    hasCompletedOrderAddress,
    total,
    payload,
    setPayload,
  } = checkoutContext;

  // ───── Render Control ───────────────────────────────────────────

  const { reordering: isReordering } = useReorderState();
  const isLoggedIn = useIsLoggedIn();

  // ───── Checkout Title ───────────────────────────────────────────

  const title = t('checkout.finish-order', { cartOrderType });

  useLayoutEffect(() => {
    navigation.setOptions({ title });
  }, [navigation, title]);

  // ───── Effects ──────────────────────────────────────────────────

  useDeliveryReorder({
    cart,
    customer,
    orderAddress,
  });

  useSyncDeliveryOrderDetails({
    cart,
    addressId,
    setPayload,
  });

  // ───── Telemetry ────────────────────────────────────────────────

  useCheckoutViewEventEffect({
    customer,
    cart,
    cartOrderType,
    total,
    payload,
  });

  // ───── Not Found View ───────────────────────────────────────────

  const navigateToMainTabs = useNavigateToMainTabs(navigation);
  const dismissAndNavigateToLocations = useCallback(() => {
    void navigateToMainTabs('MenuTab', { screen: 'Locations' });
  }, [navigateToMainTabs]);

  // ───── Logged Out -> Login ──────────────────────────────────────

  if (!isLoggedIn) {
    return <SignedOutView />;
  }

  // ───── Render Control ───────────────────────────────────────────

  if (isReordering) {
    return <LoadingAnimation />;
  }

  // ───── Empty Cart -> Location ───────────────────────────────────

  if (!isCartValid) {
    return (
      <NotFoundView
        btnLabel={t('not-found-view.empty-cart')}
        description={t('bag.empty')}
        onBtnPress={dismissAndNavigateToLocations}
      />
    );
  }

  if (isCartDelivery && !hasCompletedOrderAddress) {
    return <CheckoutAddressForm orderAddress={orderAddress ?? undefined} />;
  }

  return (
    <CheckoutWrapper>
      {/* Header */}
      <ConnectedCheckoutHeader />

      <CheckoutLayout>
        <CheckoutLayoutColumn>
          {/* Details Block */}
          <CheckoutLayoutBlock>
            <SectionHeader
              heading={t('checkout.block-details-title', { cartOrderType })}
            />
            <CheckoutBlockVStack>
              {isCartPickup ? <ConnectedPickupOptions /> : null}
              {isCartOutpost ? <ConnectedOutpostOptions /> : null}
              {isCartDelivery ? <ConnectedDeliveryOptions /> : null}
              {customer ? <ConnectedCustomerOptions /> : null}
            </CheckoutBlockVStack>
          </CheckoutLayoutBlock>

          {/* Payment Block */}
          <CheckoutLayoutBlock>
            <PaymentBlockCardIcons />
            <CheckoutBlockVStack>
              <ConnectedCheckoutPaymentBlockCreditCardPicker />
              <ConnectedCheckoutPaymentBlockAvailableCredit />
              <ConnectedCheckoutPaymentBlockPromoCode />
            </CheckoutBlockVStack>
          </CheckoutLayoutBlock>

          {/* Pickup Block */}
          {isCartPickup ? <ConnectedCheckoutRestaurantTipBlock /> : null}

          {/* Delivery Block */}
          {isCartDelivery ? (
            <>
              <ConnectedCheckoutDeliveryTipBlock />
              <ConnectedCheckoutExtrasBlock />
            </>
          ) : null}
        </CheckoutLayoutColumn>

        {/* OrderBlock */}
        <CheckoutLayoutColumn>
          <ConnectedCheckoutOrderBlock />
        </CheckoutLayoutColumn>
      </CheckoutLayout>

      {/* Modals */}
      <ConnectedRewardExceptionModal />
      <ConnectedFutureDateModal />
    </CheckoutWrapper>
  );
};

import { useCallback, useEffect } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

// ────────────────────────────────────────────────────────────────────────────────

export const useCartMutationErrorNotice = (
  responseData: LineItemErrorResponseData,
) => {
  const errorMessage = responseData?.message;
  const responseType = responseData?.__typename;

  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  // ─── ERRORS ──────────────────────────────────────────────────────

  const isRestaurantMaxQuantityExceeded =
    responseType === 'RestaurantMaxQuantityExceeded';
  const isRestaurantMaxDeliveryQuantityExceeded =
    responseType === 'RestaurantMaxDeliveryQuantityExceeded';
  const isMaxQuantityErrorOccurred =
    isRestaurantMaxQuantityExceeded || isRestaurantMaxDeliveryQuantityExceeded;
  const isMaxModificationLimitExceeded =
    responseType === 'MaxModificationsExceeded';
  const hasValidationErrors = responseType === 'ValidationError';
  const hasProductOutOfStockErrors = responseType === 'ProductOutOfStock';

  // ─── HELPERS ─────────────────────────────────────────────────────

  const handleRestaurantMaxQuantityError = useCallback(() => {
    if (!errorMessage) return;

    addNoticeBanner({ text: errorMessage, palette: 'caution' });
  }, [errorMessage, addNoticeBanner]);

  const handleMaxModificationLimitExceededError = useCallback(() => {
    if (!errorMessage) return;

    addNoticeBanner({ text: errorMessage, palette: 'neutral' });
  }, [errorMessage, addNoticeBanner]);

  const handleValidationErrors = useCallback(() => {
    const fieldErrorMessage = responseData?.fieldErrors?.[0]?.message;

    if (!fieldErrorMessage) return;

    addNoticeBanner({ text: fieldErrorMessage, palette: 'caution' });
  }, [responseData, addNoticeBanner]);

  const handleProductOutOfStockError = useCallback(() => {
    if (!errorMessage) return;

    addNoticeBanner({ text: errorMessage, palette: 'caution' });
  }, [errorMessage, addNoticeBanner]);

  // ─── EFFECTS ─────────────────────────────────────────────────────

  useEffect(() => {
    if (isMaxQuantityErrorOccurred) {
      handleRestaurantMaxQuantityError();
    }

    if (isMaxModificationLimitExceeded) {
      handleMaxModificationLimitExceededError();
    }

    if (hasValidationErrors) {
      handleValidationErrors();
    }

    if (hasProductOutOfStockErrors) {
      handleProductOutOfStockError();
    }
  }, [
    addNoticeBanner,
    responseData,
    isMaxModificationLimitExceeded,
    isRestaurantMaxDeliveryQuantityExceeded,
    isRestaurantMaxQuantityExceeded,
    hasValidationErrors,
    isMaxQuantityErrorOccurred,
    hasProductOutOfStockErrors,
    handleRestaurantMaxQuantityError,
    handleMaxModificationLimitExceededError,
    handleValidationErrors,
    handleProductOutOfStockError,
  ]);
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type LineItemErrorResponseData =
  | Readonly<{
      __typename?: string;
      message?: string;
      fieldErrors?: ReadonlyArray<
        Readonly<{
          __typename?: 'FieldValidationError';
          field: string;
          message: string;
        }>
      >;
    }>
  | undefined;

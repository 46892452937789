export const ORDER_AGAIN_TABS = {
  ORDERS: {
    ID: 'order-again-tab-orders',
    PANEL_ID: 'order-again-tab-panel-orders',
  },
  FAVORITES: {
    ID: 'order-again-tab-favorites',
    PANEL_ID: 'order-again-tab-panel-favorites',
  },
};

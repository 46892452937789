import {
  CustomizationIngredientsGridContainer,
  CustomizationIngredientsGridNav,
  CustomizationIngredientsGridNavItem,
  CustomizationIngredientsGridSection,
  CustomizationIngredientsGridSectionStack,
} from './components';

export const CustomizationIngredientsGrid = {
  Nav: CustomizationIngredientsGridNav,
  NavItem: CustomizationIngredientsGridNavItem,
  Container: CustomizationIngredientsGridContainer,
  Section: CustomizationIngredientsGridSection,
  SectionStack: CustomizationIngredientsGridSectionStack,
};

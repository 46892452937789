/* istanbul ignore file */

import type { RefObject } from 'react';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import {
  Button,
  LoadingPlaceholder,
  theme,
  usePressableState,
  useResponsive,
  webOnlyStyles,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { getLocationOrderChannel } from '../../../../helpers';
import { useNewLocationsLayout } from '../../../../hooks';
import { type Location } from '../../../../machine';
import { useLocationResultsFeeDisclosure } from '../../hooks';
import {
  LOCATION_RESULTS_CARD_HEIGHT_PADDING_VERTICAL_SM,
  LOCATION_RESULTS_CARD_HEIGHT_PADDING_VERTICAL_XS,
  LOCATION_RESULTS_CARD_HEIGHT_WITH_DISCLAIMER,
  LOCATION_RESULTS_CARD_HEIGHT_WITHOUT_PADDING,
  LOCATION_RESULTS_CARD_HEIGHT_WITHOUT_PADDING_SM,
  LOCATION_RESULTS_CARD_HEIGHT_XS,
  LOCATION_RESULTS_CARD_MIN_HEIGHT_SM,
} from '../../LocationResults.constants';
import type { OnSelectedLocationCardPress } from './LocationResultCard.types';
import {
  LocationResultCardDetailsModal,
  LocationResultCardFeeDisclosure,
} from './subcomponents';
import {
  LocationResultCardContent,
  LocationResultCardContentV2,
} from './variations';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsCard = memo((props: LocationResultsCardProps) => {
  const {
    location,
    withBottomBorder,
    withDetailsModal,
    withoutOrderNowBtn,
    shouldShowLocationOrderChannel = false,
    isLoading = false,
    isDisabled = false,
    onPress,
    onLayout,
    onFocus,
    onUmount,
  } = props;

  const {
    id,
    name,
    address,
    city,
    state,
    zipCode,
    imageUrl,
    estimatedDeliveryTime,
    restaurantSlug,
    isClosed,
    acceptingOrders = true,
  } = location;

  const locationOrderChannel = getLocationOrderChannel(location);
  const isDeliveryLocation = locationOrderChannel === 'delivery';

  // ─────────────────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const isVisible = useIsFocused();
  const { match, minWidth } = useResponsive();

  // ─── State ───────────────────────────────────────────────────────────

  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);

  const cardRef = useRef(null);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const toggleDetailsModal = useCallback(() => {
    setIsDetailsModalVisible((currentValue) => !currentValue);
  }, []);

  const handleOnLayout = useCallback(() => {
    onLayout?.(cardRef, id);
  }, [id, onLayout]);

  const handleOnPress = useCallback(() => {
    const entryPoint = isDeliveryLocation ? 'delivery-cta' : 'card';

    onPress(
      { restaurantId: id, restaurantSlug, restaurantName: name },
      { entryPoint },
    );
  }, [isDeliveryLocation, onPress, id, restaurantSlug, name]);

  const handleOnFocus = useCallback(() => {
    onFocus?.(id);
  }, [id, onFocus]);

  const handleOnUnmount = useCallback(() => {
    onUmount?.(id);
  }, [id, onUmount]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => handleOnUnmount, [handleOnUnmount]);

  // ─────────────────────────────────────────────────────────────────────

  const shouldUseNewLocationsLayout = useNewLocationsLayout();
  const shouldDisplayDetailsModal = Boolean(
    withDetailsModal && isDetailsModalVisible && isVisible,
  );
  const shouldDisableControls = isDisabled || isLoading;
  const shouldDisplayClosedNotice =
    locationOrderChannel !== 'delivery' && !acceptingOrders;
  const shouldDisplayETA = estimatedDeliveryTime !== undefined;
  const shouldDisplayAddressDetails = locationOrderChannel !== 'delivery';
  const shouldDisplayOrderCTA =
    minWidth.isSM && isDeliveryLocation && !withoutOrderNowBtn;

  // ─────────────────────────────────────────────────────────────────────

  const ctaLabel = isClosed
    ? t('location.results.card.order-later')
    : t('location.results.card.order-now');

  const hasAddressDetails = Boolean(city && state && zipCode);
  const addressDetails = hasAddressDetails
    ? `${city}, ${state} ${zipCode}`
    : undefined;
  const locationAddressDetails = shouldDisplayAddressDetails
    ? addressDetails
    : undefined;

  const locationResultA11yName = isDeliveryLocation
    ? t('general.delivery')
    : name;

  // ─── Fee Disclaimer ──────────────────────────────────────────────────

  const { shouldShowDeliveryFeeDisclaimer, shouldShowOutpostFeeDisclaimer } =
    useLocationResultsFeeDisclosure({ location });
  const shouldShowFeeDisclaimer =
    shouldShowDeliveryFeeDisclaimer || shouldShowOutpostFeeDisclaimer;

  // ─── Styles ──────────────────────────────────────────────────────────

  const { isHovered, isFocused } = usePressableState(cardRef);

  const cardStyle = [
    shouldUseNewLocationsLayout
      ? match([styles.cardV2XS, styles.cardV2SM])
      : match([
          shouldShowFeeDisclaimer ? styles.cardXSWithDisclaimer : styles.cardXS,
          styles.cardSM,
        ]),
    !shouldUseNewLocationsLayout && withBottomBorder
      ? styles.cardWithBorder
      : undefined,
    isDisabled ? styles.cardDisabled : undefined,
    !shouldDisableControls && (isHovered || isFocused)
      ? styles.cardHover
      : undefined,
    isFocused ? webOnlyStyles({ outlineStyle: 'dotted' }) : undefined,
    webOnlyStyles({
      outlineColor: theme.colors.GRAY,
      outlineOffset: theme.spacing['1'],
      outlineWidth: 2,
      transition: `opacity ${theme.transitions.base}ms`,
    }),
  ];
  const feeDisclosureContainerStyles = isDeliveryLocation
    ? match([undefined, styles.deliveryFeeDisclaimerContainerSM])
    : undefined;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <TouchableOpacity
        ref={cardRef}
        style={cardStyle}
        accessibilityRole="button"
        accessibilityLabel={t('location.results.card.a11y', {
          restaurant: locationResultA11yName,
        })}
        disabled={shouldDisableControls}
        onLayout={handleOnLayout}
        onPress={handleOnPress}
        onFocus={onFocus ? handleOnFocus : undefined}
        accessibilityState={{ disabled: isDisabled, busy: isLoading }}
      >
        {shouldUseNewLocationsLayout ? (
          <LocationResultCardContentV2
            locationName={name}
            locationOrderChannel={locationOrderChannel}
            address={address}
            addressDetails={locationAddressDetails}
            estimatedDeliveryTime={
              shouldDisplayETA ? estimatedDeliveryTime : undefined
            }
            isLoading={isLoading}
            withLocationOrderChannel={shouldShowLocationOrderChannel}
            withDetailsModal={withDetailsModal}
            toggleDetailsModal={toggleDetailsModal}
            shouldShowDeliveryFeeDisclaimer={shouldShowDeliveryFeeDisclaimer}
            shouldShowOutpostFeeDisclaimer={shouldShowOutpostFeeDisclaimer}
            shouldDisableControls={shouldDisableControls}
            shouldDisplayClosedNotice={shouldDisplayClosedNotice}
          />
        ) : (
          <LocationResultCardContent
            locationName={name}
            locationOrderChannel={locationOrderChannel}
            imageUrl={imageUrl}
            address={address}
            addressDetails={locationAddressDetails}
            estimatedDeliveryTime={
              shouldDisplayETA ? estimatedDeliveryTime : undefined
            }
            withDetailsModal={withDetailsModal}
            toggleDetailsModal={toggleDetailsModal}
            isLoading={isLoading}
            shouldShowDeliveryFeeDisclaimer={shouldShowDeliveryFeeDisclaimer}
            shouldShowOutpostFeeDisclaimer={shouldShowOutpostFeeDisclaimer}
            shouldDisableControls={shouldDisableControls}
            shouldDisplayClosedNotice={shouldDisplayClosedNotice}
          />
        )}
      </TouchableOpacity>

      {shouldDisplayOrderCTA ? (
        <View style={styles.ctaContainer}>
          <Button size="large" isLoading={isLoading} onPress={handleOnPress}>
            {ctaLabel}
          </Button>
        </View>
      ) : null}

      {/* ─── Fee Disclosure ─────────────────────────────────────────────── */}

      {shouldUseNewLocationsLayout ? null : (
        <>
          {shouldShowDeliveryFeeDisclaimer && minWidth.isSM ? (
            <View style={feeDisclosureContainerStyles}>
              <LocationResultCardFeeDisclosure.Delivery />
            </View>
          ) : null}

          {shouldShowOutpostFeeDisclaimer && minWidth.isSM ? (
            <LocationResultCardFeeDisclosure.Outpost />
          ) : null}
        </>
      )}

      {/* ─── Details Modal ──────────────────────────────────────────────── */}

      <LocationResultCardDetailsModal
        visible={shouldDisplayDetailsModal}
        location={location}
        locationOrderChannel={locationOrderChannel}
        addressDetails={addressDetails}
        isLoading={isLoading}
        isDisabled={isDisabled}
        onRequestClose={toggleDetailsModal}
        onOrderNowBtnPress={onPress}
      />
    </>
  );
});

// ─── Variations ──────────────────────────────────────────────────────────────

export const LocationResultCardLoading = memo(
  (props: Pick<LocationResultsCardProps, 'withBottomBorder'>) => {
    const { withBottomBorder } = props;

    const { match } = useResponsive();

    // ─── Flags ───────────────────────────────────────────────────────────

    const shouldUseNewLocationsLayout = useNewLocationsLayout();

    // ─── Styles ──────────────────────────────────────────────────────────

    const cardStyle = [
      match([styles.cardXS, styles.cardSM]),
      withBottomBorder ? styles.cardWithBorder : undefined,
    ];
    const cardV2Style = match([styles.cardV2XS, styles.cardV2SM]);

    // ─────────────────────────────────────────────────────────────────────

    if (shouldUseNewLocationsLayout) {
      return (
        <View style={cardV2Style}>
          <LoadingPlaceholder
            rows={1}
            columns={1}
            rowHeight={100}
            borderRadius={theme.spacing['2']}
            palette="cream"
          />
        </View>
      );
    }

    return (
      <View style={cardStyle}>
        <LoadingPlaceholder
          rows={1}
          columns={1}
          rowHeight={match([
            LOCATION_RESULTS_CARD_HEIGHT_WITHOUT_PADDING,
            LOCATION_RESULTS_CARD_HEIGHT_WITHOUT_PADDING_SM,
          ])}
          borderRadius={theme.spacing['2']}
          palette="cream"
        />
      </View>
    );
  },
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cardXS: {
    height: LOCATION_RESULTS_CARD_HEIGHT_XS,
    paddingVertical: LOCATION_RESULTS_CARD_HEIGHT_PADDING_VERTICAL_XS,
  },
  cardXSWithDisclaimer: {
    height: LOCATION_RESULTS_CARD_HEIGHT_WITH_DISCLAIMER,
    paddingVertical: LOCATION_RESULTS_CARD_HEIGHT_PADDING_VERTICAL_XS,
  },
  cardSM: {
    minHeight: LOCATION_RESULTS_CARD_MIN_HEIGHT_SM,
    paddingVertical: LOCATION_RESULTS_CARD_HEIGHT_PADDING_VERTICAL_SM,
  },
  cardV2XS: {
    paddingVertical: LOCATION_RESULTS_CARD_HEIGHT_PADDING_VERTICAL_XS,
  },
  cardV2SM: {
    paddingVertical: LOCATION_RESULTS_CARD_HEIGHT_PADDING_VERTICAL_SM,
  },
  cardWithBorder: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.LIGHT,
  },
  cardHover: {
    opacity: 0.7,
  },
  cardDisabled: {
    opacity: 0.6,
  },
  ctaContainer: {
    paddingTop: theme.spacing['1'],
  },
  deliveryFeeDisclaimerContainerSM: {
    marginTop: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsCardProps = Readonly<{
  location: Location;
  withBottomBorder?: boolean;
  withDetailsModal?: boolean;
  withoutOrderNowBtn?: boolean;
  shouldShowLocationOrderChannel?: boolean;
  locationSearchType?: 'pickup' | 'delivery' | 'outpost';
  isDisabled?: boolean;
  isLoading?: boolean;
  onLayout?: (listItemRef: RefObject<View>, listItemId: string) => void;
  onPress: OnSelectedLocationCardPress;
  onFocus?: (id: string | undefined) => void;
  onUmount?: (id: Location['id']) => void;
}>;

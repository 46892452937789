import { useMemo } from 'react';

import { type Ingredient, type IngredientModification } from '@order/graphql';
import { useTrackEventEffect } from '@order/Telemetry';

// ────────────────────────────────────────────────────────────────────────────────

export const usePersonalizedRecommendationWarningTelemetry = (
  props: PersonalizedRecommendationWarningTelemetryProps,
) => {
  const {
    fetching,
    ingredientModifications,
    ingredientIds,
    customIngredients,
  } = props;

  const hasRecommendations = (ingredientIds ?? []).length > 0;

  const currentIngredientIds = useMemo(
    () => new Set((customIngredients ?? []).map((ingredient) => ingredient.id)),
    [customIngredients],
  );

  const missingIngredientIds = useMemo(
    () => (ingredientIds ?? []).filter((id) => !currentIngredientIds.has(id)),
    [ingredientIds, currentIngredientIds],
  );

  const outOfStockIngredientIds = useMemo(
    () =>
      ingredientModifications
        .filter(
          (modification: IngredientModification) =>
            (ingredientIds ?? []).includes(modification?.ingredient?.id) &&
            modification?.outOfStock,
        )
        .map(
          (modification: IngredientModification) => modification.ingredient.id,
        ),
    [ingredientModifications, ingredientIds],
  );

  const numberOfMissingIngredients =
    missingIngredientIds.length + outOfStockIngredientIds.length;

  useTrackEventEffect({
    name: 'menu.personalization.recommendations.missing',
    skip: fetching || !hasRecommendations || numberOfMissingIngredients === 0,
    payload: {
      missingIngredientIds,
      outOfStockIngredientIds,
      numberOfMissingIngredients,
    },
  });
};

type PersonalizedRecommendationWarningTelemetryProps = Readonly<{
  fetching: boolean;
  ingredientIds?: readonly string[];
  customIngredients?: readonly Ingredient[];
  ingredientModifications: readonly IngredientModification[];
}>;

import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { Circle, Path } from 'react-native-svg';
import { theme } from '@garnish/constants';

import { MARKER_ICON_SIZE, MARKER_SIZE } from '../constants';
import { PinWrapper } from './PinWrapper';

export const DeliveryPinHome = (props: DeliveryPinProps) => {
  const {
    balloonColor = theme.colors.GREEN_2,
    iconColor = theme.colors.WHITE,
  } = props;

  return (
    <PinWrapper>
      <Svg width={MARKER_SIZE} height={MARKER_SIZE} viewBox="0 0 36 46">
        <Path
          d="M26.9516 3.29337C21.4209 0.0633422 14.5791 0.0633428 9.04841 3.29337C3.59974 6.47552 0.25 12.311 0.25 18.6209V20.1401C0.25 23.0335 1.40001 25.8435 3.061 28.399C4.7228 30.9558 6.90697 33.2747 9.00224 35.191C12.8543 38.7141 16.5137 43.725 17.0216 44.4297C17.07 44.4969 17.131 44.5712 17.2166 44.6351C17.6806 44.9815 18.3194 44.9815 18.7834 44.6351C18.869 44.5712 18.93 44.4969 18.9784 44.4297C19.4863 43.725 23.1457 38.7141 26.9978 35.191C29.093 33.2747 31.2772 30.9558 32.939 28.399C34.6 25.8435 35.75 23.0335 35.75 20.1401V18.6209C35.75 12.311 32.4002 6.47551 26.9516 3.29337Z"
          fill={balloonColor}
          stroke={theme.colors.OATMEAL}
          strokeWidth="1"
        />
      </Svg>
      <View style={styles.homeIcon}>
        <Svg
          width={MARKER_ICON_SIZE}
          height={MARKER_ICON_SIZE}
          viewBox={`0 0 ${MARKER_ICON_SIZE} ${MARKER_ICON_SIZE}`}
        >
          <Path
            d="M5.29688 13.2857H2.25L12 3L21.75 13.2857H18.7031V21H14V16H10V21H5.29688V13.2857Z"
            fill={iconColor}
          />
        </Svg>
      </View>
    </PinWrapper>
  );
};

export const DeliveryPinWork = (props: DeliveryPinProps) => {
  const {
    balloonColor = theme.colors.GREEN_2,
    iconColor = theme.colors.WHITE,
  } = props;

  return (
    <PinWrapper>
      <Svg width={MARKER_SIZE} height={MARKER_SIZE} viewBox="0 0 36 46">
        <Path
          d="M26.9516 3.29337C21.4209 0.0633422 14.5791 0.0633428 9.04841 3.29337C3.59974 6.47552 0.25 12.311 0.25 18.6209V20.1401C0.25 23.0335 1.40001 25.8435 3.061 28.399C4.7228 30.9558 6.90697 33.2747 9.00224 35.191C12.8543 38.7141 16.5137 43.725 17.0216 44.4297C17.07 44.4969 17.131 44.5712 17.2166 44.6351C17.6806 44.9815 18.3194 44.9815 18.7834 44.6351C18.869 44.5712 18.93 44.4969 18.9784 44.4297C19.4863 43.725 23.1457 38.7141 26.9978 35.191C29.093 33.2747 31.2772 30.9558 32.939 28.399C34.6 25.8435 35.75 23.0335 35.75 20.1401V18.6209C35.75 12.311 32.4002 6.47551 26.9516 3.29337Z"
          fill={balloonColor}
          stroke={theme.colors.OATMEAL}
          strokeWidth="1"
        />
      </Svg>
      <View style={styles.workIcon}>
        <Svg
          width={MARKER_ICON_SIZE}
          height={MARKER_ICON_SIZE}
          viewBox={`0 0 ${MARKER_ICON_SIZE} ${MARKER_ICON_SIZE}`}
        >
          <Path
            d="M20 2.5H4V21H1.5V22H10V17H14V22H22.5V21H20V2.5Z"
            fill={iconColor}
          />
        </Svg>
      </View>
    </PinWrapper>
  );
};

export const DeliveryPinCustom = (props: DeliveryPinProps) => {
  const {
    balloonColor = theme.colors.GREEN_2,
    iconColor = theme.colors.WHITE,
  } = props;

  return (
    <PinWrapper>
      <Svg width={MARKER_SIZE} height={MARKER_SIZE} viewBox="0 0 36 46">
        <Path
          d="M26.9516 3.29337C21.4209 0.0633422 14.5791 0.0633428 9.04841 3.29337C3.59974 6.47552 0.25 12.311 0.25 18.6209V20.1401C0.25 23.0335 1.40001 25.8435 3.061 28.399C4.7228 30.9558 6.90697 33.2747 9.00224 35.191C12.8543 38.7141 16.5137 43.725 17.0216 44.4297C17.07 44.4969 17.131 44.5712 17.2166 44.6351C17.6806 44.9815 18.3194 44.9815 18.7834 44.6351C18.869 44.5712 18.93 44.4969 18.9784 44.4297C19.4863 43.725 23.1457 38.7141 26.9978 35.191C29.093 33.2747 31.2772 30.9558 32.939 28.399C34.6 25.8435 35.75 23.0335 35.75 20.1401V18.6209C35.75 12.311 32.4002 6.47551 26.9516 3.29337Z"
          fill={balloonColor}
          stroke={theme.colors.OATMEAL}
          strokeWidth="1"
        />
      </Svg>
      <View style={styles.customIcon}>
        <Svg
          width={MARKER_ICON_SIZE}
          height={MARKER_ICON_SIZE}
          viewBox="0 0 24 24"
        >
          <Path
            d="M20 19V2.39014L4 5.59014V19H1.5V20H10V15.8108H14V20H22.5V19H20Z"
            fill={iconColor}
          />
        </Svg>
      </View>
    </PinWrapper>
  );
};

export const DeliveryPinUndefined = (props: DeliveryPinProps) => {
  const {
    balloonColor = theme.colors.GREEN_2,
    iconColor = theme.colors.WHITE,
  } = props;

  return (
    <PinWrapper>
      <Svg width={MARKER_SIZE} height={MARKER_SIZE} viewBox="0 0 36 46">
        <Path
          d="M26.9516 3.29337C21.4209 0.0633422 14.5791 0.0633428 9.04841 3.29337C3.59974 6.47552 0.25 12.311 0.25 18.6209V20.1401C0.25 23.0335 1.40001 25.8435 3.061 28.399C4.7228 30.9558 6.90697 33.2747 9.00224 35.191C12.8543 38.7141 16.5137 43.725 17.0216 44.4297C17.07 44.4969 17.131 44.5712 17.2166 44.6351C17.6806 44.9815 18.3194 44.9815 18.7834 44.6351C18.869 44.5712 18.93 44.4969 18.9784 44.4297C19.4863 43.725 23.1457 38.7141 26.9978 35.191C29.093 33.2747 31.2772 30.9558 32.939 28.399C34.6 25.8435 35.75 23.0335 35.75 20.1401V18.6209C35.75 12.311 32.4002 6.47551 26.9516 3.29337Z"
          fill={balloonColor}
          stroke={theme.colors.OATMEAL}
          strokeWidth="1"
        />
      </Svg>
      <View style={styles.circle}>
        <Svg width={14} height={14} viewBox="0 0 14 14">
          <Circle cx="7" cy="6.99994" r="7" fill={iconColor} />
        </Svg>
      </View>
    </PinWrapper>
  );
};

type DeliveryPinProps = Readonly<{
  balloonColor?: string;
  iconColor?: string;
}>;

const styles = StyleSheet.create({
  homeIcon: {
    position: 'absolute',
    top: 6,
    left: 12,
  },
  workIcon: {
    position: 'absolute',
    top: 8,
    left: 12,
  },
  customIcon: {
    position: 'absolute',
    top: 8,
    left: 12,
  },
  circle: {
    position: 'absolute',
    top: 12,
    left: 17,
  },
});

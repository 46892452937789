import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { CheckboxButton } from '@sg/garnish';

/**
 * "Add utensils to my bag" checkbox in the bottom of the bag.
 */
export const BagUtensilsCta = (props: BagUtensilsCtaProps) => {
  const { shouldIncludeUtensils, isDisabled, onChange } = props;

  const { formatMessage } = useIntl();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnChange = useCallback(() => {
    onChange({ shouldIncludeUtensils: !shouldIncludeUtensils });
  }, [shouldIncludeUtensils, onChange]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <CheckboxButton
        heading={formatMessage(messages.utensilsCta)}
        subheading={formatMessage(messages.utensilsHint)}
        isChecked={shouldIncludeUtensils}
        onChange={handleOnChange}
        isDisabled={isDisabled}
      />
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  utensilsCta: {
    defaultMessage: 'Add utensils to my bag',
    description: 'Bag | Utensils | Cta (on)',
  },
  utensilsHint: {
    defaultMessage: 'Our utensils and napkins are 100% compostable',
    description: 'Bag | Utensils | Hint',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    marginHorizontal: -theme.spacing['4'],
    padding: theme.spacing['2'],
    borderBottomWidth: theme.spacing['2'],
    borderBottomColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BagUtensilsCtaProps = {
  shouldIncludeUtensils: boolean;
  isDisabled: boolean;
  onChange: (payload: { shouldIncludeUtensils: boolean }) => void;
};

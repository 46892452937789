import { useCallback, useEffect, useState } from 'react';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns helpers for controlling the expanded/collapsed states of Locations Results component.
 */
export const useLocationResultsExpandCollapse = (
  props: UseLocationResultsExpandCollapseProps,
) => {
  const {
    hasSingleResult,
    locationSearchType,
    onLocationResultsExpandCollapse,
  } = props;

  // ─── State ───────────────────────────────────────────────────────────

  const [isExpanded, setIsExpanded] = useState(false);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const toggleExpandedState = useCallback(() => {
    setIsExpanded((currentState) => {
      onLocationResultsExpandCollapse?.(!currentState);

      return !currentState;
    });
  }, [onLocationResultsExpandCollapse]);

  const collapseResults = () => {
    setIsExpanded(false);
  };

  // ─── Effects ─────────────────────────────────────────────────────────

  // Collapse the results, when the location search type changes
  useEffect(() => {
    collapseResults();
  }, [locationSearchType]);

  // Collapse the results, if there is only one result.
  useEffect(() => {
    if (!hasSingleResult) return;

    collapseResults();
  }, [hasSingleResult]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    isExpanded,
    toggleExpandedState,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseLocationResultsExpandCollapseProps = Readonly<{
  locationSearchType?: string;
  hasSingleResult: boolean;
  onLocationResultsExpandCollapse?: (isExpanded: boolean) => void;
}>;

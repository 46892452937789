import type { MutableRefObject } from 'react';
import { createContext, useContext } from 'react';
import type { CombinedError, Operation } from 'urql';

export const UrqlContext = createContext<Urql | undefined>(undefined);

export function useUrqlContext() {
  const context = useContext(UrqlContext);

  if (context === undefined) {
    throw new Error('useUrqlContext must be used within a <UrqlProvider>');
  }

  return context;
}

// ─── Types ─────────────────────────────────────────────────────────────

export type UrqlErrorHandler = (
  error: CombinedError,
  operation: Operation,
) => void;

export type ErrorHandlerReference = MutableRefObject<UrqlErrorHandler | null>;

type Urql = Readonly<{
  registerUrqlErrorTracker: (errorHandler: UrqlErrorHandler) => void;
  registerUrqlLogOutHandler: (logOutHandler: () => Promise<void>) => void;
  resetClient: () => Promise<void>;
}>;

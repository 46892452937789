import { useWindowDimensions } from 'react-native';
import { useStyle } from 'react-native-style-utilities';

export type ProportionSizeProps = Readonly<{
  proportion: number;
  aspectRatio: number;
}>;

export type ProportionSizeType = Readonly<{
  width: number;
  height: number;
}>;

export function useProportionSize(
  props: ProportionSizeProps,
): ProportionSizeType {
  const { proportion, aspectRatio } = props;
  const { width } = useWindowDimensions();

  return useStyle(() => {
    return {
      width: width * proportion,
      height: width * proportion * aspectRatio,
    };
  }, [width, proportion, aspectRatio]);
}

import type { ReorderEvent } from '../../machines/Reorder.machine';
import type {
  ReorderCart,
  ReorderDeliveryDetails,
  ReorderRestaurant,
} from './useReorderIfRequired.types';

// ─── Utils ──────────────────────────────────────────────────────────────────────

export function getBaseReorderDetails(
  input: GetBaseReorderDetailsInput,
): ReorderEvent {
  const { activeCart, activeRestaurant } = input;

  return {
    orderId: activeCart?.id,
    restaurantId: activeRestaurant?.id,
    restaurantSlug: activeRestaurant?.slug,
    restaurantName: activeRestaurant?.name,
    dismissToBagMenu: true,
    finishOnMenu: true,
  };
}

export function getDeliveryReorderDetails(
  activeRestaurant: ReorderRestaurant | undefined,
  activeDeliveryDetails: ReorderDeliveryDetails | undefined,
):
  | Pick<
      ReorderEvent,
      'orderType' | 'deliveryOrderDetail' | 'deliveryAddressName'
    >
  | undefined {
  if (!activeRestaurant || !activeDeliveryDetails) return;

  const activeDeliveryOrderDetail = {
    addressId: activeDeliveryDetails?.addressId,
    vendor: activeDeliveryDetails?.vendor,
    vendorRestaurantId: activeDeliveryDetails?.vendorRestaurantId,
    deliveryFee: activeRestaurant?.deliveryFee ?? 0,
    tip: 0,
  };

  return {
    orderType: 'delivery',
    deliveryOrderDetail: activeDeliveryOrderDetail,
    deliveryAddressName: activeDeliveryDetails?.addressName,
  };
}

export function getPickupOutpostReorderDetails(
  activeRestaurant: ReorderRestaurant | undefined,
): Pick<ReorderEvent, 'orderType'> | undefined {
  if (!activeRestaurant) return;

  const orderType = activeRestaurant?.isOutpost ? 'outpost' : 'pickup';

  return { orderType };
}

// ────────────────────────────────────────────────────────────────────────────────

export function checkIfShouldStartReorder(
  input: CheckIfShouldStartReorderInput,
) {
  const { activeCart, activeRestaurant } = input;

  if (!activeRestaurant) return false;

  const isCartNotEmpty = !activeCart.isEmpty;

  const areDifferentRestaurants = checkIfRestaurantsDiffer({
    activeRestaurant,
    activeCart,
  });
  const areDifferentOrderTypes = checkIfOrderTypesDiffer({
    activeCartOrderType: activeCart.orderType,
    activeRestaurant,
  });

  return isCartNotEmpty && (areDifferentRestaurants || areDifferentOrderTypes);
}

// ────────────────────────────────────────────────────────────────────────────────

function checkIfRestaurantsDiffer(input: CheckIfRestaurantsDifferInput) {
  const { activeRestaurant, activeCart } = input;

  return activeRestaurant?.id !== activeCart.restaurantId;
}

function checkIfOrderTypesDiffer(input: CheckIfOrderTypesDifferInput) {
  const { activeCartOrderType, activeRestaurant } = input;

  // There is no need to check the `outpost` order type because
  // we are working with a separate restaurant in that scenario.
  const isActiveCartOrderTypeIsDelivery = activeCartOrderType === 'delivery';
  const isActiveOrderTypeDelivery = activeRestaurant?.isDelivery;

  return (
    (isActiveCartOrderTypeIsDelivery && !isActiveOrderTypeDelivery) ||
    (!isActiveCartOrderTypeIsDelivery && isActiveOrderTypeDelivery)
  );
}

// ─── Types ──────────────────────────────────────────────────────────────────────

type GetBaseReorderDetailsInput = Readonly<{
  activeCart: ReorderCart;
  activeRestaurant?: ReorderRestaurant;
}>;

type CheckIfShouldStartReorderInput = Readonly<{
  activeCart: ReorderCart;
  activeRestaurant?: ReorderRestaurant;
}>;

type CheckIfRestaurantsDifferInput = Readonly<{
  activeCart: ReorderCart;
  activeRestaurant: ReorderRestaurant;
}>;

type CheckIfOrderTypesDifferInput = Readonly<{
  activeCartOrderType: ReorderCart['orderType'];
  activeRestaurant: ReorderRestaurant;
}>;

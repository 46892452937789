/**
 * One Signal related constants
 */

/**
 * @see {https://documentation.onesignal.com/docs/add-user-data-tags}
 */
export const ONE_SIGNAL_DATA_TAGS = {
  LIVE_ACTIVITY_ENABLED: {
    KEY: 'live-activity-enabled',
    VALUES: {
      ENABLED: 'true',
      DISABLED: 'false',
    },
  },
};

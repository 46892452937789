import React from 'react';
import { Button, ModalDialogue, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { Modal } from '../Modal';

// ─────────────────────────────────────────────────────────────────────────────

export const SweetpassKeepSubscriptionModal = (
  props: SweetpassKeepSubscriptionModalProps,
) => {
  const {
    changePlanForwardsLabel,
    changePlanBackwardsLabel,
    isSwitchingBack,
    visible,
    onChangeBack,
    onRequestClose,
  } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  // ─── Localization ────────────────────────────────────────────────────

  const modalTitle = t('sweetpass.keep-subscription.title', {
    changeBack: changePlanBackwardsLabel,
  });
  const modalBody = t('sweetpass.keep-subscription.body', {
    changeTo: changePlanForwardsLabel,
    changeBack: changePlanBackwardsLabel,
  });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ModalDialogue
      visible={visible}
      accessibilityLabel={modalTitle}
      headline={modalTitle}
      body={modalBody}
      size={match(['small', 'medium'])}
      onRequestClose={onRequestClose}
      onDismissed={onRequestClose}
    >
      <Modal.Footer withoutTopBorder>
        <Button
          palette="primary"
          size="large"
          isLoading={isSwitchingBack}
          onPress={onChangeBack}
        >
          {t('general.confirm')}
        </Button>
      </Modal.Footer>
    </ModalDialogue>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type SweetpassKeepSubscriptionModalProps = Readonly<{
  changePlanForwardsLabel: string;
  changePlanBackwardsLabel: string;
  isSwitchingBack: boolean;
  visible: boolean;
  onChangeBack: () => void;
  onRequestClose: () => void;
}>;

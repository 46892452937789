export const addresses = {
  'addresses.title': 'Addresses',

  // ─── Address Selection ───────────────────────────────────────────────

  'addresses.address-label': 'Select {address} address',
  'addresses.address-hint': 'Selects address',

  // ─── Address Form ────────────────────────────────────────────────────

  'addresses.select-location.label': 'Select {text}',
  'addresses.select-location.hint': 'Selects location',
  'addresses.custom-name': 'Custom Name',
  'addresses.headline.edit': 'Edit Address',
  'addresses.headline.add': 'Add Address',
  'addresses.add-new': 'Add new address',
  'addresses.placeholder.delivery-note-label': 'Delivery Notes',
  'addresses.placeholder.delivery-note':
    'Security code, building details, etc.',
  'addresses.address-type.home': 'Home',
  'addresses.address-type.work': 'Work',
  'addresses.address-type.custom': 'Custom',
  'addresses.address-type.home.a11y': 'Address type, home',
  'addresses.address-type.work.a11y': 'Address type, work',
  'addresses.address-type.custom.a11y': 'Address type, custom',
  'addresses.address-type.home.disabled-hint':
    'You already have a home address saved',
  'addresses.address-type.work.disabled-hint':
    'You already have a work address saved',
  'addresses.delivery-preference.leave-at-door': 'Leave at door',
  'addresses.delivery-preference.meet-at-door': 'Meet at door',
  'addresses.delivery-preference.meet-outside': 'Meet outside',
  'addresses.delivery-preference.leave-at-door.a11y':
    'Drop-off option, leave at door',
  'addresses.delivery-preference.meet-at-door.a11y':
    'Drop-off option, meet at door',
  'addresses.delivery-preference.meet-outside.a11y':
    'Drop-off option, meet outside',
  'addresses.secondary-street.placeholder': 'Apt number, suite, company',
  'addresses.drop-off': 'Drop-Off',
  'addresses.required': 'Required',
  'addresses.save': 'Save',
  'addresses.save.a11y': 'Submit address form',
  'addresses.remove-address.label': 'Remove',
  'addresses.remove-address.a11y': 'Remove address',
  'addresses.street.a11y': 'Delivery address',
  'addresses.secondary-street.a11y': 'Secondary street',
  'addresses.delivery-notes.a11y':
    'Delivery notes for courier, max of {maxLength} characters...',
  'addresses.preferences.a11y': 'Delivery preferences',

  // ─── Address With Active Bag ─────────────────────────────────────────

  'addresses.active-bag-warning.title':
    'You have an active bag with this address.',
  'addresses.active-bag-warning.subtitle':
    'If you proceed to edit or remove this address your bag will be cleared.',
  'addresses.active-bag-warning.cta': 'Edit address',

  // ─── Addresses Errors ────────────────────────────────────────────────

  'addresses.error.name.duplicate':
    'You already have an address with this name',
  'addresses.error.address.duplicate': 'You already have this address on file',
  'addresses.error.address.invalid':
    'This address is not valid, please provide a street number.',
  'addresses.error.zip.invalid':
    'This address is not valid, please provide a zip code.',
  'addresses.error.save.failure':
    "Oops, we couldn't save your address, please try again.",
  'addresses.error.delete.failure':
    "Oops, we couldn't remove your address, please try again.",
};

import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { BodyText, theme, useResponsive, VStack } from '@sg/garnish';

import { CreditMenuItem } from '@order/components';
import { useLocalizationContext } from '@order/Localization';

import type { ModalStackParamList } from '../../../../../../navigation/AppNavigation.props';
import type { useCheckoutContext } from '../../../../CheckoutProvider';
import { CheckoutPaymentBlockGiftCardInput } from './CheckoutPaymentBlockGiftCardInput';
import { CheckoutPaymentBlockPromoCodeInput } from './CheckoutPaymentBlockPromoCodeInput';

export const CheckoutPaymentBlockPromoCodeWithSeparateGiftCard = (
  props: Pick<ReturnType<typeof useCheckoutContext>, 'refetchCredits'>,
) => {
  const { currentBreakpoint } = useResponsive();

  if (currentBreakpoint.isXS) {
    return <CheckoutPaymentBlockPromoCodeWithSeparateGiftCardXS />;
  }

  return <CheckoutPaymentBlockPromoCodeWithSeparateGiftCardMD {...props} />;
};

const CheckoutPaymentBlockPromoCodeWithSeparateGiftCardXS = () => {
  const { t } = useLocalizationContext();
  const { navigate } =
    useNavigation<NativeStackNavigationProp<ModalStackParamList>>();

  const navigateToAddGiftCard = useCallback(() => {
    navigate('AddGiftCard');
  }, [navigate]);

  const navigateToEnterPromoCode = useCallback(() => {
    navigate('CreditAdd');
  }, [navigate]);

  return (
    <VStack hasDivider dividerColor={theme.colors.LIGHT} gap={0}>
      <CreditMenuItem
        title={t('checkout.payment.gift-card.label')}
        accessibilityLabel={t('checkout.payment.gift-card.a11y')}
        iconName="IconCreditCard"
        onPress={navigateToAddGiftCard}
      />

      <CreditMenuItem
        title={t('checkout.payment.promo-code.label')}
        accessibilityLabel={t('checkout.payment.promo-code.a11y')}
        iconName="IconPromo"
        onPress={navigateToEnterPromoCode}
      />
    </VStack>
  );
};

const CheckoutPaymentBlockPromoCodeWithSeparateGiftCardMD = (
  props: Pick<ReturnType<typeof useCheckoutContext>, 'refetchCredits'>,
) => {
  const { t } = useLocalizationContext();

  return (
    <>
      <VStack hasDivider dividerColor={theme.colors.LIGHT} gap={0}>
        <CheckoutPaymentBlockGiftCardInput {...props} />

        <CheckoutPaymentBlockPromoCodeInput {...props} />
      </VStack>

      <BodyText size={4}>{t('account.credit-promo.helper-text')}</BodyText>
    </>
  );
};

import { useEffect } from 'react';

import { getEnvVars } from '@order/utils';

import { useCreatePaymentIntentMutation } from '../graphql/PaymentIntent.generated';

export function useStripeKeys() {
  const [response, createPaymentIntent] = useCreatePaymentIntentMutation();

  useEffect(() => {
    // @ts-expect-error TS(2554): Expected 1-2 arguments, but got 0.
    void createPaymentIntent();
  }, [createPaymentIntent]);

  const stripeKey = getEnvVars().STRIPE_PUBLIC_KEY;
  const clientSecret = response?.data?.createPaymentIntent;
  const isFetchingStripeKeys = response.fetching;
  const isMissingStripeKeys = !stripeKey || response.error;

  return { stripeKey, clientSecret, isFetchingStripeKeys, isMissingStripeKeys };
}

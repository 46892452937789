import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { BackHandler, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useResponsive } from '@sg/garnish';

import {
  type HeaderIconItems,
  useHeaderLeft,
  useHeaderRight,
} from '@order/components';

import { type AuthStackParamList } from '../../../../../../navigation/AppNavigation.props';
import { joinOrSignScreenHeaderButtonsMessages as messages } from './useJoinOrSignScreenHeaderButtons.messages';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A custom hook that renders custom navigation header controls and conditionally
 * toggles various navigation options to improve user experience during loading.
 */
export const useJoinOrSignScreenHeaderButtons = (
  params: UseJoinOrSignScreenHeaderButtonsParams,
) => {
  const { isLoading } = params;

  const navigation =
    useNavigation<NativeStackNavigationProp<AuthStackParamList>>();
  const parentStack = navigation.getParent();

  const { currentBreakpoint } = useResponsive();
  const { formatMessage } = useIntl();

  // ─── Header Controls ─────────────────────────────────────────────────

  const headerLeftItems = useMemo<HeaderIconItems>(() => {
    // NOTE: On iOS, we render the "Join or Sign In" screen as a modal,
    //       therefore, rendering back button is not needed.
    if (Platform.OS === 'ios') return [];

    return [
      {
        key: 'join-or-sign-in-screen-header-back-btn',
        icon: 'IconArrowLeft',
        accessibilityLabel: formatMessage(messages.backBtnLabel),
        onPress: navigation.goBack,
      },
    ];
  }, [formatMessage, navigation.goBack]);

  const headerRightItems = useMemo<HeaderIconItems>(
    () => [
      {
        key: 'join-or-sign-in-screen-header-close-btn',
        icon: 'IconClose',
        accessibilityLabel: formatMessage(messages.closeBtnLabel),
        onPress: navigation.goBack,
      },
    ],
    [formatMessage, navigation.goBack],
  );

  // ─────────────────────────────────────────────────────────────────────

  const shouldHideControls = !currentBreakpoint.isXS || isLoading;

  const headerLeftXS = useHeaderLeft({ items: headerLeftItems });
  const headerRightXS = useHeaderRight({ items: headerRightItems });

  const headerLeft = shouldHideControls ? undefined : headerLeftXS;
  const headerRight = shouldHideControls ? undefined : headerRightXS;

  // ─── Effects ─────────────────────────────────────────────────────────

  /**
   * Conditionally disable hardware back button on Android.
   */
  useEffect(() => {
    const { remove: removeListener } = BackHandler.addEventListener(
      'hardwareBackPress',
      () => isLoading,
    );

    return removeListener;
  }, [isLoading]);

  /**
   * Set header controls.
   */
  useEffect(() => {
    navigation.setOptions({
      headerLeft,
      headerRight,

      // NOTE: We use a custom back button, thus we need to hide the default one.
      headerBackVisible: false,
    });
  }, [headerLeft, headerRight, isLoading, navigation]);

  /**
   * Conditionally enable "Swipe to close" functionality.
   */
  useEffect(() => {
    parentStack?.setOptions({
      gestureEnabled: !isLoading,
    });
  }, [isLoading, parentStack]);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseJoinOrSignScreenHeaderButtonsParams = {
  isLoading: boolean;
};

import React from 'react';

import { useTelemetry } from '@order/Telemetry';

export const useRewardDetailsModalTelemetry = () => {
  const { track } = useTelemetry();

  const handleRewardDetailsTelemetry = React.useCallback(
    (rewardId: string) => {
      track('challenges.reward_details', { rewardId });
    },
    [track],
  );

  const handleRewardLinkTelemetry = React.useCallback(() => {
    track('challenges.reward_link');
  }, [track]);

  const handleRewardClosedTelemetry = React.useCallback(() => {
    track('modal.close');
  }, [track]);

  return React.useMemo(
    () => ({
      handleRewardDetailsTelemetry,
      handleRewardLinkTelemetry,
      handleRewardClosedTelemetry,
    }),
    [
      handleRewardDetailsTelemetry,
      handleRewardLinkTelemetry,
      handleRewardClosedTelemetry,
    ],
  );
};

import React from 'react';
import { Platform } from 'react-native';

const FACEBOOK_PIXEL_SCRIPT = `!(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js',
  );
  fbq('init', '1444601085568058');
  fbq('track', 'PageView');`;

export const useFacebookPixel = () => {
  React.useEffect(() => {
    // NOTE: only display the FB pixel for web
    if (Platform.OS !== 'web') return;

    const fbScript = document.createElement('script');

    fbScript.append(document.createTextNode(FACEBOOK_PIXEL_SCRIPT));
    document.head.append(fbScript);
  }, []);
};

/* istanbul ignore file */

import type { Client } from 'urql';
import { convertGooglePlacesAddress } from '@sg/garnish';
import { formatGoogleAddress } from '@sg/garnish';
import { type LocationStatus } from '@sg/graphql-schema';

import type { CustomerAddressesQuery } from '../../graphql/CustomerDeliveryDetails.generated';
import type { DeliveryLocationByAddressQueryVariables } from '../../graphql/DeliveryLocationByAddress.generated';
import type { LastPlacedOrderDetailsQuery } from '../../graphql/LastPlacedOrders.generated';
import type {
  DeliveryLocationWithoutNearbyStore,
  Location,
} from '../../LocationSearch.types';
import {
  activeCartDeliveryDetailsQuery,
  customerAddressesQuery,
  deliveryLocationByAddressQuery,
  deliveryLocationByAddressWithDisclosureFieldsQuery,
  lastPlacedOrdersDetailsQuery,
} from '../../queries';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Fetches the delivery location using the provided Google place ID.
 */
export async function getDeliveryLocationByPlaceId(
  props: GetDeliveryLocationByPlaceIdProps,
): Promise<Location | DeliveryLocationWithoutNearbyStore | undefined> {
  const {
    client,
    googlePlacesApiKey,
    googlePlaceId,
    shouldUseDisclosureFields,
  } = props;

  if (!googlePlaceId) return;

  const googlePlaceAddress = await convertGooglePlacesAddress({
    placeId: googlePlaceId,
    apiKey: googlePlacesApiKey,
  });

  return getDeliveryLocationDetails({
    client,
    googlePlaceAddress,
    shouldUseDisclosureFields,
  });
}

/**
 * Fetches delivery location details based on the provided address.
 */
export async function getDeliveryLocationDetails(
  params: GetDeliveryLocationDetailsParams,
): Promise<Location | DeliveryLocationWithoutNearbyStore> {
  const { client, googlePlaceAddress, shouldUseDisclosureFields } = params;

  const deliveryLocation = await getDeliveryLocation({
    client,
    input: convertAddressToDeliveryLocationInput(googlePlaceAddress),
    shouldUseDisclosureFields,
  });

  if (!deliveryLocation) {
    throw new Error('Failed to get delivery location details');
  }

  // ─── Delivery Location Without Nearby Store ──────────────────────────

  if (deliveryLocation.__typename === 'NoValidRestaurants') {
    return getWithoutNearbyStoreDeliveryLocationDetails(googlePlaceAddress);
  }

  // ─── Delivery Location With Nearby Store ──────────────────────────

  return getWithNearbyStoreDeliveryLocationDetails({
    client,
    googlePlaceAddress,
    deliveryLocation,
  });
}

/**
 * Fetches a nearby location for the specified delivery address.
 */
async function getDeliveryLocation(params: GetDeliveryLocationParams) {
  const { client, input, shouldUseDisclosureFields } = params;

  // NOTE: Conditionally fetch extra disclosure fields.
  const query = shouldUseDisclosureFields
    ? deliveryLocationByAddressWithDisclosureFieldsQuery
    : deliveryLocationByAddressQuery;

  const { data } = await query(client, { input });
  const { locationByAddress } = data ?? {};

  if (
    locationByAddress?.__typename !== 'Location' &&
    locationByAddress?.__typename !== 'NoValidRestaurants'
  ) {
    throw new Error('Failed to get delivery location');
  }

  return locationByAddress;
}

/**
 * Fetches the active cart delivery location (if available).
 */
export async function getActiveCartDeliveryLocation(
  props: GetActiveCartDeliveryLocationProps,
) {
  const { client, googlePlacesApiKey, shouldUseDisclosureFields } = props;

  const { data } = await activeCartDeliveryDetailsQuery(client);
  const { cart } = data ?? {};

  const activeCartDeliveryLocationGooglePlaceId =
    cart?.deliveryOrderDetail?.address?.googlePlaceId;

  if (!activeCartDeliveryLocationGooglePlaceId) return;

  return getDeliveryLocationByPlaceId({
    client,
    googlePlaceId: activeCartDeliveryLocationGooglePlaceId,
    googlePlacesApiKey,
    shouldUseDisclosureFields,
  });
}

/**
 * Fetches the last interacted store delivery location (if available).
 */
export async function getLastInteractedStoreDeliveryLocation(
  props: GetLastInteractedStoreDeliveryLocation,
) {
  const {
    client,
    googlePlacesApiKey,
    lastInteractedStoreAddressId,
    shouldUseDisclosureFields,
  } = props;

  const lastInteractedStore = await getCustomerDeliveryAddressByAddressId(
    client,
    lastInteractedStoreAddressId,
  );
  const lastInteractedStoreGooglePlaceId = lastInteractedStore?.googlePlaceId;

  if (!lastInteractedStoreGooglePlaceId) return;

  return getDeliveryLocationByPlaceId({
    client,
    googlePlaceId: lastInteractedStoreGooglePlaceId,
    googlePlacesApiKey,
    shouldUseDisclosureFields,
  });
}

/**
 * Attempts to get the last placed order delivery address ID.
 */
export async function getLastPlacedOrderDeliveryLocation(
  props: GetLastPlacedOrderDeliveryLocation,
) {
  const { client, googlePlacesApiKey, shouldUseDisclosureFields } = props;

  const { data: customerData } = await customerAddressesQuery(client);
  const isSignedInCustomer = customerData?.currentCustomer?.id !== undefined;

  // NOTE: We only query the last placed order only for signed-in customers.
  if (!isSignedInCustomer) return;

  const customerDeliveryAddress = await getCustomerDeliveryAddresses(client);
  const { data } = await lastPlacedOrdersDetailsQuery(client);
  const { orders: ordersData } = data ?? {};

  const hasLastPlacedOrder = ordersData?.__typename === 'OrdersResponseSuccess';
  const lastPlacedOrder = hasLastPlacedOrder
    ? findLastPlacedValidDeliveryOrder(
        ordersData.orders,
        customerDeliveryAddress,
      )
    : undefined;

  const lastPlacedOrderGooglePlaceId = (
    lastPlacedOrder?.deliveryOrderDetail as any
  )?.address?.googlePlaceId;

  if (!lastPlacedOrderGooglePlaceId) return;

  return getDeliveryLocationByPlaceId({
    client,
    googlePlaceId: lastPlacedOrderGooglePlaceId,
    googlePlacesApiKey,
    shouldUseDisclosureFields,
  });
}

/**
 * Attempts to get the customer first previously saved delivery location.
 */
export async function getCustomerFirstDeliveryLocation(
  props: GetCustomerFirstDeliveryLocation,
) {
  const { client, googlePlacesApiKey, shouldUseDisclosureFields } = props;

  const customerDeliveryAddresses = await getCustomerDeliveryAddresses(client);

  const customerFirstDeliveryAddressGooglePlaceId =
    customerDeliveryAddresses?.[0]?.googlePlaceId;

  if (!customerFirstDeliveryAddressGooglePlaceId) return;

  return getDeliveryLocationByPlaceId({
    client,
    googlePlaceId: customerFirstDeliveryAddressGooglePlaceId,
    googlePlacesApiKey,
    shouldUseDisclosureFields,
  });
}

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Attempts to get customer's delivery addresses.
 */
export async function getCustomerDeliveryAddresses(client: Client) {
  const { data } = await customerAddressesQuery(client);

  return data?.currentCustomer?.addresses ?? [];
}

/**
 * Attempts to get the customer's delivery address based on the provided address ID.
 */
export async function getCustomerDeliveryAddressByAddressId(
  client: Client,
  addressId: string,
) {
  const customerAddresses = await getCustomerDeliveryAddresses(client);

  return customerAddresses.find(
    (customerAddress) => customerAddress.id === addressId,
  );
}

/**
 * Attempts to get the customer's delivery address based on the provided Google place ID.
 */
export async function getCustomerDeliveryAddressByGooglePlaceId(
  client: Client,
  googlePlaceId: string,
) {
  const customerAddresses = await getCustomerDeliveryAddresses(client);

  return customerAddresses.find(
    (customerAddress) => customerAddress.googlePlaceId === googlePlaceId,
  );
}

/**
 * Attempts to get an existing customer delivery address name, based on the provided Google place ID.
 */
export async function getDeliveryAddressName(
  client: Client,
  googlePlaceId: string,
) {
  const existingAddress = await getCustomerDeliveryAddressByGooglePlaceId(
    client,
    googlePlaceId,
  );

  return existingAddress?.name;
}

/**
 * Attempts to get an existing customer delivery address ID, based on the provided Google place ID.
 */
export async function getExistingCustomerDeliveryAddressId(
  client: Client,
  googlePlaceId: string,
) {
  const existingAddress = await getCustomerDeliveryAddressByGooglePlaceId(
    client,
    googlePlaceId,
  );

  return existingAddress?.id;
}

/**
 * Attempts to find a previously placed delivery order.
 */
function findLastPlacedValidDeliveryOrder(
  lastPlacedOrders: LastPlacedOrders,
  customDeliveryAddresses: CustomerDeliveryAddresses,
) {
  const customDeliveryAddressesSet = new Set(
    customDeliveryAddresses.map(({ id }) => id),
  );

  return lastPlacedOrders.find(({ deliveryOrderDetail }) => {
    const deliveryAddressId = (deliveryOrderDetail as any)?.address?.id;

    return (
      deliveryAddressId !== undefined &&
      typeof deliveryAddressId === 'string' &&
      customDeliveryAddressesSet.has(deliveryAddressId)
    );
  });
}

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns delivery location (WITH nearby store) details based on the provided
 * Google Place address.
 */
async function getWithNearbyStoreDeliveryLocationDetails(
  props: GetWithNearbyStoreDeliveryLocationDetails,
): Promise<Location> {
  const { client, googlePlaceAddress, deliveryLocation } = props;

  const { googlePlaceId } = googlePlaceAddress;

  const deliveryAddressId = await getExistingCustomerDeliveryAddressId(
    client,
    googlePlaceId,
  );
  const deliveryAddressName = await getDeliveryAddressName(
    client,
    googlePlaceId,
  );

  // ─────────────────────────────────────────────────────────────────────

  return extractDeliveryLocationDetails({
    deliveryLocation,
    deliveryAddressId,
    deliveryAddressName,
    googlePlaceAddress,
  });
}

/**
 * Returns delivery location (WITH nearby store) details based on the provided
 * Google Place address.
 */
export function extractDeliveryLocationDetails(
  params: ExtractDeliveryLocationDetailsParams,
): Location {
  const {
    googlePlaceAddress,
    deliveryAddressId,
    deliveryAddressName,
    deliveryLocation,
  } = params;

  const {
    googlePlaceId,
    longitude,
    latitude,
    city,
    state,
    country,
    zipCode,
    street,
  } = googlePlaceAddress;

  const estimatedDeliveryTime = getDeliveryLocationETA(deliveryLocation);
  const deliveryFormattedAddress = formatGoogleAddress(googlePlaceAddress);
  const isDeliveryLocationClosed = deliveryLocation?.status === 'CLOSED';

  const deliveryLocationRestaurant = deliveryLocation?.restaurant ?? {};
  const deliveryLocationRestaurantWithDisclosure =
    deliveryLocationRestaurant as unknown as ValidDeliveryLocation & {
      showDeliveryPriceDifferenciationDisclosure: boolean;
    };

  const {
    id: restaurantId,
    name: restaurantName,
    slug: restaurantSlug,
    flexMessage,
    phone,
    deliveryFee,
  } = deliveryLocationRestaurant;
  const { name: deliveryVendorName, restaurantId: deliveryVendorRestaurantId } =
    deliveryLocation.vendor;

  // ─────────────────────────────────────────────────────────────────────

  return {
    isDelivery: true,
    id: googlePlaceId,
    addressId: deliveryAddressId ?? '',
    name: deliveryAddressName ?? '',
    address: deliveryFormattedAddress ?? '',
    estimatedDeliveryTime,
    flexMessage,
    phone,
    isClosed: isDeliveryLocationClosed,
    lat: latitude,
    lng: longitude,
    street,
    zipCode,
    city,
    state,
    country,
    restaurantSlug,
    restaurantName,
    restaurantId,
    restaurantDeliveryFee: deliveryFee,
    deliveryVendorName,
    deliveryVendorRestaurantId,
    showDeliveryPriceDifferenciationDisclosure:
      deliveryLocationRestaurantWithDisclosure.showDeliveryPriceDifferenciationDisclosure ??
      false,
  };
}

/**
 * Returns delivery location (WITHOUT nearby store) details based on the provided
 * Google Place address.
 */
function getWithoutNearbyStoreDeliveryLocationDetails(
  googlePlaceAddress: GooglePlaceAddress,
): DeliveryLocationWithoutNearbyStore {
  const deliveryFormattedAddress = formatGoogleAddress(googlePlaceAddress);

  return {
    id: googlePlaceAddress.googlePlaceId,
    lat: googlePlaceAddress.latitude,
    lng: googlePlaceAddress.longitude,
    street: googlePlaceAddress.street,
    zipCode: googlePlaceAddress.zipCode,
    city: googlePlaceAddress.city,
    state: googlePlaceAddress.state,
    country: googlePlaceAddress.country,
    address: deliveryFormattedAddress,
    name: '',
    restaurantSlug: undefined,
    restaurantId: '',
    restaurantName: '',
    addressId: undefined,
    deliveryVendorName: undefined,
    deliveryVendorRestaurantId: undefined,
    restaurantDeliveryFee: undefined,
  };
}

/**
 * Returns a formatted location address that does not include the country term.
 */
function withoutCountryTerm(
  address: string,
  terms: ReadonlyArray<Readonly<{ value: string }>>,
) {
  const countryTerm = terms.at(-1)?.value;
  const countrySubstring = `, ${countryTerm}`;

  if (address.endsWith(countrySubstring)) {
    return address.replace(countrySubstring, '');
  }

  return address;
}

/**
 * Returns a formatter function for Google Autocomplete Predictions
 */
export function formatDeliveryAddressPrediction(
  customerDeliveryAddresses: CustomerDeliveryAddresses,
) {
  return function (predictionAddress: AutocompletePrediction) {
    const {
      place_id: id,
      description,
      terms,
      matched_substrings: matchedSubstrings,
    } = predictionAddress;

    const customerExistingDeliveryAddressName =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      getCustomerExistingDeliveryAddressName(customerDeliveryAddresses, id);
    const name = customerExistingDeliveryAddressName ?? '';
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const address = withoutCountryTerm(description, terms);

    return { id, name, address, matchedSubstrings };
  };
}

/**
 * Returns estimated delivery time for the provided delivery location.
 */
function getDeliveryLocationETA(
  deliveryLocation: MaybeDeliveryLocationWithETA,
) {
  if (!deliveryLocation?.estimateRange) return;

  const { start = 0, end = 0 } = deliveryLocation.estimateRange;

  return start === end ? `${start}` : `${start}-${end}`;
}

/**
 * Attempts to get the name of customer's matching delivery address based on the provided Google Place ID.
 */
function getCustomerExistingDeliveryAddressName(
  customerDeliveryAddresses: CustomerDeliveryAddresses,
  googlePlaceId: string,
) {
  return customerDeliveryAddresses?.find(
    (customerDeliveryAddress) =>
      customerDeliveryAddress.googlePlaceId === googlePlaceId,
  )?.name;
}

/**
 * Convert the specified delivery address into an input
 * object needed to retrieve the delivery location.
 */
function convertAddressToDeliveryLocationInput(
  deliveryAddress: GooglePlaceAddress,
): DeliveryLocationByAddressQueryVariables['input'] {
  const {
    googlePlaceId,
    longitude: lon,
    latitude: lat,
    street: address1,
    city,
    state,
    zipCode,
  } = deliveryAddress;
  const maybeZipCode = Number.isNaN(zipCode)
    ? {}
    : { zipcode: Number(zipCode) };

  return {
    address1,
    city,
    lat,
    lon,
    state,
    googlePlaceId,
    ...maybeZipCode,
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type GetDeliveryLocationByPlaceIdProps = Readonly<{
  client: Client;
  googlePlacesApiKey: string;
  googlePlaceId: string | undefined;
}> &
  DisclosureFieldsParams;

type GetDeliveryLocationDetailsParams = {
  client: Client;
  googlePlaceAddress: GooglePlaceAddress;
} & DisclosureFieldsParams;

type GetDeliveryLocationParams = {
  client: Client;
  input: DeliveryLocationByAddressQueryVariables['input'];
} & DisclosureFieldsParams;

type GetActiveCartDeliveryLocationProps = Readonly<{
  client: Client;
  googlePlacesApiKey: string;
}> &
  DisclosureFieldsParams;

type GetLastInteractedStoreDeliveryLocation = Readonly<{
  client: Client;
  googlePlacesApiKey: string;
  lastInteractedStoreAddressId: string;
}> &
  DisclosureFieldsParams;

type GetLastPlacedOrderDeliveryLocation = Readonly<{
  client: Client;
  googlePlacesApiKey: string;
}> &
  DisclosureFieldsParams;

type GetCustomerFirstDeliveryLocation = Readonly<{
  client: Client;
  googlePlacesApiKey: string;
}> &
  DisclosureFieldsParams;

type GetWithNearbyStoreDeliveryLocationDetails = Readonly<{
  client: Client;
  googlePlaceAddress: GooglePlaceAddress;
  deliveryLocation: ValidDeliveryLocation;
}>;

type ExtractDeliveryLocationDetailsParams = {
  deliveryAddressId: string | undefined;
  deliveryAddressName: string | undefined | null;
  deliveryLocation: Omit<
    ValidDeliveryLocation,
    '__typename' | 'status' | 'estimateRange'
  > & {
    status?: LocationStatus;
    estimateRange?: { start: number; end: number };
  };
  googlePlaceAddress: Omit<GooglePlaceAddress, 'formattedAddress'>;
};

type ValidDeliveryLocation = Extract<
  Awaited<ReturnType<typeof getDeliveryLocation>>,
  Readonly<{ __typename: 'Location' }>
>;

// @ts-expect-error TS(2503): Cannot find namespace 'google'.
type AutocompletePrediction = google.maps.places.AutocompletePrediction;

type GooglePlaceAddress = Awaited<
  ReturnType<typeof convertGooglePlacesAddress>
>;

type CustomerDeliveryAddresses = NonNullable<
  CustomerAddressesQuery['currentCustomer']
>['addresses'];

type MaybeDeliveryLocationWithETA = Readonly<{
  estimateRange?: {
    start: number;
    end: number;
  };
}>;

type LastPlacedOrders = Extract<
  LastPlacedOrderDetailsQuery['orders'],
  Readonly<{ __typename: 'OrdersResponseSuccess' }>
>['orders'];

type DisclosureFieldsParams = {
  /**
   * A flag to fetch extra upcharge disclosure fields from BE.
   */
  shouldUseDisclosureFields: boolean;
};

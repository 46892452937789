/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReorderOrderFragment = {
  readonly __typename?: 'Order';
  readonly id: string;
  readonly wantedTime: string;
  readonly deliveryOrderDetail: {
    readonly __typename?: 'DeliveryOrderDetail';
    readonly id: string;
    readonly deliveryFee: number;
    readonly tip: number;
    readonly vendor: string;
    readonly vendorRestaurantId: string;
    readonly address: {
      readonly __typename?: 'Address';
      readonly id: string;
      readonly name: string | null;
    } | null;
  } | null;
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
    readonly slug: string;
    readonly name: string;
  };
  readonly lineItems: ReadonlyArray<{
    readonly __typename?: 'LineItem';
    readonly id: string;
    readonly customName: string | null;
    readonly dressingMode: Types.DressingMode;
    readonly mixedDressingDetails: ReadonlyArray<{
      readonly __typename?: 'MixedDressingDetails';
      readonly weight: Types.DressingWeight;
      readonly ingredientId: string;
    }>;
    readonly removedIngredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly kind: Types.IngredientKind | null;
    }>;
    readonly addedIngredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly kind: Types.IngredientKind | null;
    }>;
    readonly product: {
      readonly __typename?: 'Product';
      readonly id: string;
      readonly slug: string;
      readonly name: string;
      readonly isCustom: boolean;
      readonly categoryName: string;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly ingredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly kind: Types.IngredientKind | null;
      }>;
    };
  }>;
};

export type OrdersV2ForReorderQueryVariables = Types.Exact<{
  input: Types.OrdersInputV2;
}>;

export type OrdersV2ForReorderQuery = {
  readonly __typename?: 'Query';
  readonly ordersV2:
    | {
        readonly __typename: 'OrdersResponseSuccess';
        readonly pagination: {
          readonly __typename?: 'Pagination';
          readonly total: number;
          readonly last: number;
        };
        readonly orders: ReadonlyArray<{
          readonly __typename?: 'Order';
          readonly id: string;
          readonly wantedTime: string;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly deliveryFee: number;
            readonly tip: number;
            readonly vendor: string;
            readonly vendorRestaurantId: string;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly name: string | null;
            } | null;
          } | null;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly customName: string | null;
            readonly dressingMode: Types.DressingMode;
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly weight: Types.DressingWeight;
              readonly ingredientId: string;
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
            }>;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly slug: string;
              readonly name: string;
              readonly isCustom: boolean;
              readonly categoryName: string;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly kind: Types.IngredientKind | null;
              }>;
            };
          }>;
        }>;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export const ReorderOrderFragmentDoc = gql`
  fragment ReorderOrder on Order {
    id
    wantedTime
    deliveryOrderDetail {
      id
      address {
        id
        name
      }
      deliveryFee
      tip
      vendor
      vendorRestaurantId
    }
    restaurant {
      id
      slug
    }
    lineItems {
      id
      customName
      mixedDressingDetails {
        weight
        ingredientId
      }
      removedIngredients {
        id
        name
        kind
      }
      addedIngredients {
        id
        name
        kind
      }
      dressingMode
      product {
        id
        slug
        name
        isCustom
        categoryName
        asset {
          url
        }
        ingredients {
          id
          kind
        }
      }
    }
    restaurant {
      id
      name
    }
  }
`;
export const OrdersV2ForReorderDocument = gql`
  query ordersV2ForReorder($input: OrdersInputV2!) {
    ordersV2(input: $input) {
      __typename
      ... on OrdersResponseSuccess {
        pagination {
          total
          last
        }
        orders {
          ...ReorderOrder
        }
      }
      ... on ValidationError {
        fieldErrors {
          field
          message
        }
      }
    }
  }
  ${ReorderOrderFragmentDoc}
`;

export function useOrdersV2ForReorderQuery(
  options: Omit<Urql.UseQueryArgs<OrdersV2ForReorderQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    OrdersV2ForReorderQuery,
    OrdersV2ForReorderQueryVariables
  >({ query: OrdersV2ForReorderDocument, ...options });
}

import React from 'react';
import { useNavigation } from '@react-navigation/native';

import { useNavigateBack } from '../../navigation';

export const useBagNavigation = () => {
  const navigation = useNavigation();

  // This just opens the bag screen on the modal stack.
  const handleOpenBag = React.useCallback(() => {
    navigation.navigate('Modal', { screen: 'Bag' });
  }, [navigation]);

  // This will go back on the parent (dismiss the modal stack),
  // It will go back in the modal stack if needed.
  const handleCloseBag = useNavigateBack();

  return {
    handleOpenBag,
    handleCloseBag,
  };
};

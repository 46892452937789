import type { ComponentProps } from 'react';
import React from 'react';

import type { Image } from '../Image';
import type { RewardsRailProps } from '../RewardsRail';
import { RewardsRail } from '../RewardsRail';
import { LoyaltyRewardsRailEmptyState } from './subcomponents';

export const LoyaltyRewardsRail = (props: LoyaltyRewardsRailProps) => {
  if (!props.isLoading && !props.rewards?.length) {
    return (
      <LoyaltyRewardsRailEmptyState
        image={props.emptyStateImage}
        description={props.emptyStateText}
        headerText={props.headerText}
        headerVariation={props.headerVariation}
        subtitle={props.subtitle}
      />
    );
  }

  return <RewardsRail {...props} count={props.availableCount} />;
};

// ─── TYPES ─────────────────────────────────────────────────────────────────

type LoyaltyRewardsRailProps = Readonly<{
  /**
   * Available Rewards count
   */
  availableCount?: number;
  /**
   * Image to display when there are no rewards
   */
  emptyStateImage: ComponentProps<typeof Image>['source'];
  /**
   * Text to display when there are no rewards
   */
  emptyStateText: string;
}> &
  Omit<RewardsRailProps, 'count'>;

import { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import type {
  AppStackParamList,
  NotFoundWithRestaurantSlugScreenProps,
} from '../../../../navigation/AppNavigation.props';
import { useMenuRedirectQuery } from '../../GraphQL/MenuRedirect.generated';

// Here we handle the short menu link redirects (/west-3rd -> /west-3rd/menu).
export const useMenuRedirect = (
  skip: boolean,
  { navigation, route }: NotFoundWithRestaurantSlugScreenProps,
) => {
  const restaurantSlug = route.params?.restaurantSlug;

  const [restaurantQueryResponse] = useMenuRedirectQuery({
    variables: { id: restaurantSlug },
    requestPolicy: 'cache-and-network',
    pause: !restaurantSlug || skip,
  });

  const isShortMenuLink = Boolean(restaurantQueryResponse?.data?.restaurant);

  useFocusEffect(
    useCallback(() => {
      if (!isShortMenuLink) return;

      const redirectTo = getMenuRedirect(restaurantSlug);

      navigation.replace(redirectTo.screen, redirectTo.params);
    }, [isShortMenuLink, restaurantSlug, navigation]),
  );

  return restaurantQueryResponse.fetching;
};

const getMenuRedirect = (restaurantSlug: string): MenuRedirect => {
  return {
    screen: 'MainTabs',
    params: {
      screen: 'MenuTab',
      params: {
        screen: 'Menu',
        params: { restaurantSlug },
      },
    },
  };
};

type MenuRedirect = Readonly<{
  screen: keyof AppStackParamList;
  params: AppStackParamList[keyof AppStackParamList];
}>;

import { addMinutes, isPast, isToday } from 'date-fns';
import { NotificationFeedbackType } from 'expo-haptics';
import type { AddressType } from '@sg/garnish';
import {
  AddressTypes,
  getRoundedValueOfPercentage,
  triggerHapticNotificationFeedback,
} from '@sg/garnish';

import { APPLE_PAY, type PAYMENT_ADD } from '@order/constants';
import type {
  AvailableWantedTime,
  CardType,
  DeliveryOrderDetailInput,
  EstimateRange,
  Ledger,
  PaymentMethodCard,
} from '@order/graphql';
import type { useCart } from '@order/hooks';
import type { useLocalizationContext } from '@order/Localization';
import type { WeekdayTranslationKey } from '@order/utils';
import { getDay, ignoreTimezone } from '@order/utils';

export const isTimeAvailable = ({ disabled }: WantedTimeOption) => !disabled;

export const mapAvailableTimes = ({
  t,
  orderType,
  times,
  deliveryEstimate,
}: WantedTimeMessage): WantedTimeOptions => {
  if (!times || !orderType) {
    return {};
  }

  // ───────────────────────────────────────────────────────────────

  let hasValidAsap = false;

  return times.reduce<WantedTimeOptions>(
    (groups, { time: value, deliveryOffset }) => {
      const availableDateWithoutDeliveryOffset = ignoreTimezone(value);

      if (!availableDateWithoutDeliveryOffset) {
        return groups;
      }

      // Add the delivery offset to the time, it'll be 0 for pickup orders.
      const availableDate = addMinutes(
        availableDateWithoutDeliveryOffset,
        deliveryOffset,
      );

      const { day, clock, formattedHours, formattedMinutes } =
        formatTimeParts(availableDate);

      // 10 minutes delivery window
      const deliveryWindowEnd = addMinutes(availableDate, 10);
      const {
        day: endDay,
        clock: endClock,
        formattedHours: formattedHoursEnd,
        formattedMinutes: formattedMinutesEnd,
      } = formatTimeParts(deliveryWindowEnd);

      // ────────────────────────────────────────────────────────────────────

      const hasPast = isPast(new Date(value));
      const isAsap = !hasValidAsap && !hasPast;

      // Displays as "ASAP - 35-45 MINS".
      const isAsapWithDeliveryEstimate = isAsap && deliveryEstimate;

      if (isAsap) {
        hasValidAsap = true;
      }

      const isAvailableDateToday = isToday(availableDate);

      // ────────────────────────────────────────────────────────────────────

      const labelTranslationKey = generateTimeLabelKey(
        day,
        isAvailableDateToday,
      );
      const asapLabel = isAsap ? `${t('general.asap')} - ` : '';
      const asapDeliveryLabel = isAsapWithDeliveryEstimate
        ? t('general.asap-delivery', deliveryEstimate)
        : '';
      const groupLabel = isAsapWithDeliveryEstimate
        ? asapDeliveryLabel
        : `${asapLabel}${t(labelTranslationKey, { day })}`;
      const groupOptions = groups[groupLabel] ?? [];

      // ────────────────────────────────────────────────────────────────────

      const pickupTimeDetails = t('general.available-time', {
        day: t(day),
        hours: formattedHours,
        minutes: formattedMinutes,
        clock,
      });

      const deliveryStartTimeDetails = t('general.available-start-time', {
        day: t(day),
        hours: formattedHours,
        minutes: formattedMinutes,
      });

      const deliveryEndTimeDetails = t('general.available-time', {
        day: t(endDay),
        hours: formattedHoursEnd,
        minutes: formattedMinutesEnd,
        clock: endClock,
      });

      const timeDetailsWithPickupLabel = `${pickupTimeDetails}`;
      const timeDetailsWithDeliveryLabel = isAsapWithDeliveryEstimate
        ? ''
        : `${deliveryStartTimeDetails}-${deliveryEndTimeDetails}`;

      // ────────────────────────────────────────────────────────────────────

      const timeOption = {
        value,
        disabled: hasPast,
        label:
          orderType === 'delivery'
            ? timeDetailsWithDeliveryLabel
            : timeDetailsWithPickupLabel,
      };

      return {
        ...groups,
        [groupLabel]: [...groupOptions, timeOption],
      };
    },
    {},
  );
};

export const getWantedTimeFormatting = (
  value: string,
  t: ReturnType<typeof useLocalizationContext>['t'],
) => {
  const availableDate = ignoreTimezone(value);

  if (!availableDate) {
    return;
  }

  const { clock, formattedHours, formattedMinutes } =
    formatTimeParts(availableDate);

  return t('general.available-time', {
    hours: formattedHours,
    minutes: formattedMinutes,
    clock,
  });
};

const formatTimeParts = (date: Date) => {
  const day = getDay(`${date.getDay()}`);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const clock = hours >= 12 ? 'pm' : 'am';
  // eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10
  const formattedHours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;
  const formattedMinutes = String(minutes).padStart(2, '0');

  return {
    day,
    clock,
    formattedHours,
    formattedMinutes,
  };
};

const generateTimeLabelKey = (
  day: WeekdayTranslationKey,
  isAvailableTimeToday: boolean,
) => (isAvailableTimeToday ? 'general.available-time-today' : day);

export const applyCredit = (
  ledger: Partial<Ledger> | undefined,
  creditAmount: number,
): Partial<Ledger> | undefined => {
  const credits = ledger?.credits?.map((credit) =>
    credit.name === 'Credit' ? { ...credit, amount: creditAmount } : credit,
  );

  return (
    ledger && {
      ...ledger,
      credits,
      creditsTotal: credits?.reduce((prev, credit) => prev + credit.amount, 0),
    }
  );
};

const applyDeliveryTip = (
  ledger: NonNullable<Cart>['ledger'],
  payloadDeliveryOrderDetail: CheckoutPayload['deliveryOrderDetail'],
): Ledger | undefined => {
  const deliveryTip = payloadDeliveryOrderDetail?.tip;
  const subtotal = ledger.subtotal ?? 0;
  const defaultDeliveryTip = getRoundedValueOfPercentage(
    DEFAULT_DELIVERY_TIP_PERCENTAGE,
    subtotal,
  );

  const tip = deliveryTip ?? defaultDeliveryTip;

  return { ...ledger, tip };
};

const applyPickupTip = (
  ledger: NonNullable<Cart>['ledger'],
  payloadTipAmount: number | undefined,
): Ledger | undefined => {
  const subtotal = ledger.subtotal ?? 0;
  const defaultPickupTipAmount = getRoundedValueOfPercentage(
    DEFAULT_PICKUP_TIP_PERCENTAGE,
    subtotal,
  );

  const tip = payloadTipAmount ?? defaultPickupTipAmount;

  return { ...ledger, tip };
};

export function withTip(props: ApplyTipParams): Ledger | undefined {
  const { payload, shouldApplyPickupTip, cart } = props;

  const ledger = cart?.ledger;
  const orderType = cart?.orderType;

  if (!ledger) {
    return;
  }

  if (shouldApplyPickupTip && orderType === 'PICKUP') {
    return applyPickupTip(ledger, payload.pickupTip);
  }

  if (orderType === 'DELIVERY') {
    return applyDeliveryTip(ledger, payload.deliveryOrderDetail);
  }

  return ledger;
}

export function getOrderTip(
  payload: GetOrderTipPayload,
  orderType: AddressType,
): string {
  if (orderType === 'delivery') {
    const deliveryTip = payload.deliveryOrderDetail?.tip ?? 0;

    return formatCurrencyAmount(deliveryTip);
  }

  if (orderType === 'pickup') {
    const pickupTip = payload.pickupTip ?? 0;

    return formatCurrencyAmount(pickupTip);
  }

  return '0';
}

function formatCurrencyAmount(currencyAmountInCents: number) {
  const formattedAmount = Number(currencyAmountInCents) / 100;

  return formattedAmount.toFixed(2);
}

// The default option for delivery tips is 15%.
const DEFAULT_DELIVERY_TIP_PERCENTAGE = 15;
const DEFAULT_PICKUP_TIP_PERCENTAGE = 0;

export const calculateTip = (
  subtotal: number | undefined,
  percentage?: number,
) => {
  return Math.round(
    ((percentage ?? DEFAULT_DELIVERY_TIP_PERCENTAGE) / 100) * (subtotal ?? 0),
  );
};

export const triggerCheckoutHapticFeedback = async (
  isCheckoutSuccess: boolean,
) => {
  const feedbackType = isCheckoutSuccess
    ? NotificationFeedbackType.Success
    : NotificationFeedbackType.Warning;

  await triggerHapticNotificationFeedback(feedbackType);
};

/**
 * If apple pay is the default payment method, return it instead of the regular payment methods.
 * Otherwise, return the default regular payment method if it exists, falling back to the first available one otherwise.
 * Finally, if there are no payment methods and Apple Pay is supported, use it as the default payment method.
 */
export function getDefaultPaymentMethod(params: {
  paymentMethods: readonly PaymentMethodCard[];
  isApplePayDefault: boolean;
  isApplePayReady?: boolean;
}) {
  const { isApplePayDefault, isApplePayReady, paymentMethods } = params;

  const hasNoPaymentMethods = paymentMethods.length === 0;
  const shouldDefaultToApplePay = isApplePayDefault || hasNoPaymentMethods;
  const shouldUseApplePay = isApplePayReady && shouldDefaultToApplePay;

  if (shouldUseApplePay) return getDefaultApplePayPaymentMethod();

  return paymentMethods.find(({ isDefault }) => isDefault) ?? paymentMethods[0];
}

/**
 * Returns the default apple pay payment method.
 */
export function getDefaultApplePayPaymentMethod() {
  return {
    id: APPLE_PAY,
    cardType: APPLE_PAY,
    __typename: 'PaymentMethodCard' as const,
    isDefault: true,
  };
}

/**
 * Returns the first available dropoff location if the restaurant is an outpost.
 */
export function getDefaultDropoffLocation(
  cart: Cart,
  cartOrderType: AddressType,
) {
  const restaurant = cart?.restaurant;
  const isOutpost = cartOrderType === AddressTypes.outpost;
  const dropOffLocations = restaurant?.availableDropOffLocations ?? [];

  return isOutpost ? dropOffLocations[0] : undefined;
}

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type CheckoutPayload = Readonly<{
  wantedTime: string;
  paymentMethodId: string;
  paymentMethodType: PaymentMethodType;
  useCredit: boolean;
  dropoffLocationId: string | null;
  contactNumber: string | null;
  includeUtensils: boolean;
  deliveryOrderDetail?: DeliveryOrderDetailInput | null;
  pickupTip?: number; // restaurant tip
}>;

// Payment methods can be of one of these types.
export type PaymentMethodType =
  | 'BillingAccount'
  | 'PaymentMethodCard'
  | 'PaymentMethodBillingAccount';

type WantedTimeOptions = Record<string, readonly WantedTimeOption[]>;

type WantedTimeOption = Readonly<{
  value: string;
  label: string;
  disabled: boolean;
}>;

type WantedTimeMessage = Readonly<{
  t: ReturnType<typeof useLocalizationContext>['t'];
  orderType?: AddressType;
  times?: readonly AvailableWantedTime[];
  deliveryEstimate?: EstimateRange | null;
}>;

export type CreditCardOption = Readonly<{
  value: string;
  label: string;
  cardType: CardType | typeof PAYMENT_ADD | typeof APPLE_PAY;
  paymentMethodType: PaymentMethodType;
  isDefault: boolean;
}>;

export type SelectedTimeProps = Readonly<{
  selectedTime: string;
  setSelectedTime: (time: string) => void;
}>;

type ApplyTipParams = Readonly<{
  cart: Cart;
  payload: CheckoutPayload;
  shouldApplyPickupTip: boolean;
}>;

type Cart = ReturnType<typeof useCart>['cart'];

type GetOrderTipPayload = Readonly<{
  deliveryOrderDetail?: Partial<
    Pick<NonNullable<CheckoutPayload['deliveryOrderDetail']>, 'tip'>
  > | null;
  pickupTip?: CheckoutPayload['pickupTip'];
}>;

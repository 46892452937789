import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View, type ViewProps } from 'react-native';
import { Header } from '@expo/html-elements';
import { theme } from '@garnish/constants';
import { BodyText } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

const FeatureFlagsListHeaderContainer = (
  props: Pick<ViewProps, 'children'>,
) => {
  return <Header style={styles.container}>{props.children}</Header>;
};

const FeatureFlagsListHeaderTitle = () => {
  return (
    <BodyText sizeMatch={['18']} bold style={styles.title}>
      <FormattedMessage
        defaultMessage="Feature flag switcher"
        description="Feature flag switcher title"
      />
    </BodyText>
  );
};

const FeatureFlagsListHeaderControlsContainer = (
  props: Pick<ViewProps, 'children'>,
) => {
  return <View style={styles.controlsContainer}>{props.children}</View>;
};

// ─────────────────────────────────────────────────────────────────────────────

export const FeatureFlagsListHeader = {
  Container: FeatureFlagsListHeaderContainer,
  ControlsContainer: FeatureFlagsListHeaderControlsContainer,
  Title: FeatureFlagsListHeaderTitle,
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing['6'],
    paddingBottom: theme.spacing['4'],
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.GRAY,
  },
  controlsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: theme.spacing['2'],
  },
  title: {
    flex: 1,
  },
});

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { BodyText, theme } from '@sg/garnish';

import { giftCardRedemptionMessageMessages as messages } from './GiftCardRedemptionMessage.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardRedemptionMessage = (
  props: GiftCardRedemptionMessageProps,
) => {
  const { recipientMessage, senderName, recipientName } = props;

  // ─── Custom Message ──────────────────────────────────────────────────

  if (recipientMessage) {
    return (
      <BodyText sizeMatch={['14']} style={styles.text}>
        <FormattedMessage
          {...messages.heading}
          values={{ recipient: recipientName, sender: senderName }}
        />
        {'\n\n'}

        {recipientMessage}
      </BodyText>
    );
  }

  // ─── Short Message ───────────────────────────────────────────────────

  return (
    <BodyText sizeMatch={['16']} style={[styles.text, styles.textShort]}>
      <FormattedMessage
        {...messages.heading}
        values={{ recipient: recipientName, sender: senderName }}
      />
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    color: theme.colors.KALE,
    textAlign: 'center',
  },
  textShort: {
    textAlign: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardRedemptionMessageProps = {
  senderName: string;
  recipientName: string;
  recipientMessage?: string | null;
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SweetpassBillingHistoryQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SweetpassBillingHistoryQuery = {
  readonly __typename?: 'Query';
  readonly paymentSubscriptionProfile: {
    readonly __typename?: 'PaymentSubscriptionProfile';
    readonly id: string;
    readonly transactionHistory: ReadonlyArray<{
      readonly __typename?: 'SubscriptionTransactions';
      readonly id: string;
      readonly date: string;
      readonly amount: string;
      readonly refund: boolean;
      readonly transactionCreditCard: {
        readonly __typename?: 'TransactionCreditCard';
        readonly id: string;
        readonly cardType: Types.BraintreeCardType;
        readonly lastFour: string;
      };
    }>;
  } | null;
};

export const SweetpassBillingHistoryDocument = gql`
  query SweetpassBillingHistory {
    paymentSubscriptionProfile {
      id
      transactionHistory {
        id
        transactionCreditCard {
          id
          cardType
          lastFour
        }
        date
        amount
        refund
      }
    }
  }
`;

export function useSweetpassBillingHistoryQuery(
  options?: Omit<
    Urql.UseQueryArgs<SweetpassBillingHistoryQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    SweetpassBillingHistoryQuery,
    SweetpassBillingHistoryQueryVariables
  >({ query: SweetpassBillingHistoryDocument, ...options });
}

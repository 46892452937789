import { useCallback, useState } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useSweetpassSubscribe } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { type SweetpassMembershipScreenProps } from '../../../../navigation/AppNavigation.props';
import { type useSweetpassMembershipData } from '../useSweetpassMembershipData';

export function useSweetpassSubscriptionSwitch(
  props: UseSweetpassSubscriptionSwitchProps,
) {
  const {
    navigation,
    subscriptionPlanId = '',
    activePaymentMethodId = '',
    hasPendingSubscription,
    isSubscriptionAnnual,
    planIdAfterSwitch,
    refetchMembership,
  } = props;

  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { track } = useTelemetry();

  const { subscribe: switchSubscriptionBack, isSubscribing: isSwitchingBack } =
    useSweetpassSubscribe();

  // ─── State ───────────────────────────────────────────────────────────

  const [isKeepingSubscription, setIsKeepingSubscription] = useState(false);
  const planIdToBeSwitchedTo = hasPendingSubscription
    ? planIdAfterSwitch
    : subscriptionPlanId;

  // ─── Switch Subscription Back ────────────────────────────────────────

  const handleSwitchSubscriptionBack = useCallback(async () => {
    const { isSuccess } = await switchSubscriptionBack({
      planId: subscriptionPlanId,
      paymentId: activePaymentMethodId,
    });

    if (isSuccess) {
      const annual = t('account.sweetpass-membership.membership-level.annual');

      const monthly = t(
        'account.sweetpass-membership.membership-level.monthly',
      );

      const currentPlanRecurrence = isSubscriptionAnnual ? annual : monthly;
      const requestedPlanRecurrence = isSubscriptionAnnual ? monthly : annual;

      addNoticeBanner({
        text: t('account.sweetpass-membership.membership-level.undo-success', {
          currentPlanRecurrence,
          requestedPlanRecurrence,
        }),
        palette: 'success',
      });

      refetchMembership();

      return;
    }

    addNoticeBanner({
      text: t('account.sweetpass-membership.membership-level.change-error'),
      palette: 'caution',
    });
  }, [
    subscriptionPlanId,
    activePaymentMethodId,
    isSubscriptionAnnual,
    t,
    switchSubscriptionBack,
    addNoticeBanner,
    refetchMembership,
  ]);

  // ─── Switch Subscription ─────────────────────────────────────────────

  const switchSubscriptionHandler = useCallback(async () => {
    track('sweetpass_account.change_cta.tapped');

    if (hasPendingSubscription) {
      setIsKeepingSubscription(true);

      return;
    }

    navigation.navigate('Modal', {
      screen: 'SweetpassSwitchSubscription',
      params: {
        planId: planIdAfterSwitch ?? '',
        redirectToAfterUpgrade: 'account-membership',
      },
    });
  }, [track, hasPendingSubscription, navigation, planIdAfterSwitch]);

  const closeKeepingSubscriptionModal = useCallback(() => {
    setIsKeepingSubscription(false);
  }, []);

  return {
    isSwitchingBack,
    isKeepingSubscription,
    planIdToBeSwitchedTo,
    switchSubscriptionHandler,
    closeKeepingSubscriptionModal,
    handleSwitchSubscriptionBack,
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type UseSweetpassSubscriptionSwitchProps = SweetpassMembershipScreenProps &
  Omit<
    ReturnType<typeof useSweetpassMembershipData>,
    | 'loyaltyProfileError'
    | 'hasMembershipError'
    | 'isSweetpassMembershipLoading'
  >;

import React, { memo } from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import {
  CloudinaryImage,
  IllusDelivery_1,
  IllusOutpost_1,
  Image,
  LoadingDots,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import {
  LOCATION_CARD_IMG_BG_COLOR,
  LOCATION_CARD_IMG_CLOUDINARY_CONFIG_SM,
  LOCATION_CARD_IMG_CLOUDINARY_CONFIG_XS,
  LOCATION_RESULTS_CARD_ILLUSTRATION_SIZE_SM,
  LOCATION_RESULTS_CARD_ILLUSTRATION_SIZE_XS,
  LOCATION_RESULTS_CARD_IMG_SIZE_SM,
  LOCATION_RESULTS_CARD_IMG_SIZE_XS,
} from '../../../../LocationResults.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultCardImg = memo(
  (props: LocationResultCardImgProps) => {
    const { locationName, locationSearchType, imageUrl, isLoading } = props;

    const { t } = useLocalizationContext();
    const { match } = useResponsive();

    // NOTE: For outpost and delivery locations, we use static illustrations,
    //       and for pickup locations, we use a remote image.

    // ─── Image alt text ──────────────────────────────────────────────────

    const shouldUseDynamicImageAlt =
      locationSearchType === 'pickup' && Boolean(locationName);
    const imageAlt = shouldUseDynamicImageAlt
      ? locationName
      : t('location.result.img-alt', { locationSearchType });

    // ─── Render conditions ───────────────────────────────────────────────

    const shouldRenderPickupImg =
      locationSearchType === 'pickup' && imageUrl !== undefined;
    const shouldRenderDeliveryIllustration = locationSearchType === 'delivery';
    const shouldRenderOutpostIllustration = locationSearchType === 'outpost';

    // ─────────────────────────────────────────────────────────────────────

    const imgStyle = match([styles.imgXS, styles.imgSM]);
    const illustrationStyle = match([
      styles.illustrationXS,
      styles.illustrationSM,
    ]);

    if (isLoading) {
      return (
        <LocationResultCardImgContainer>
          <View>
            <LoadingDots />
          </View>
        </LocationResultCardImgContainer>
      );
    }

    if (shouldRenderPickupImg) {
      return (
        <LocationResultCardImgContainer>
          <CloudinaryImage
            baseUrl={imageUrl}
            config={match([
              LOCATION_CARD_IMG_CLOUDINARY_CONFIG_XS,
              LOCATION_CARD_IMG_CLOUDINARY_CONFIG_SM,
            ])}
            resizeMode="cover"
            style={imgStyle}
            aria-label={imageAlt}
          />
        </LocationResultCardImgContainer>
      );
    }

    if (shouldRenderDeliveryIllustration) {
      return (
        <LocationResultCardImgContainer>
          <Image
            source={IllusDelivery_1}
            style={illustrationStyle}
            contentFit="contain"
            aria-label={imageAlt}
          />
        </LocationResultCardImgContainer>
      );
    }

    if (shouldRenderOutpostIllustration) {
      return (
        <LocationResultCardImgContainer>
          <Image
            source={IllusOutpost_1}
            style={illustrationStyle}
            contentFit="contain"
            aria-label={imageAlt}
          />
        </LocationResultCardImgContainer>
      );
    }

    return null;
  },
);

// ─── Subcomponents ───────────────────────────────────────────────────────────

const LocationResultCardImgContainer = memo(
  (props: LocationResultCardImgContainerProps) => {
    const { children } = props;

    const { match } = useResponsive();

    const imgContainerStyles = [
      styles.imgContainer,
      match([styles.imgContainerXS, styles.imgContainerSM]),
      styles.imgContainerWithBg,
    ];

    return <View style={imgContainerStyles}>{children}</View>;
  },
);

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultCardImgProps = Readonly<{
  locationName: string;
  locationSearchType: 'pickup' | 'delivery' | 'outpost' | undefined;
  imageUrl?: string;
  isLoading?: boolean;
}>;

type LocationResultCardImgContainerProps = Readonly<{
  children: ViewProps['children'];
}>;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  imgContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgContainerXS: {
    width: LOCATION_RESULTS_CARD_IMG_SIZE_XS,
    height: LOCATION_RESULTS_CARD_IMG_SIZE_XS,
    borderRadius: theme.spacing['2'],
    marginRight: 12,
  },
  imgContainerSM: {
    width: '100%',
    height: LOCATION_RESULTS_CARD_IMG_SIZE_SM,
    borderRadius: theme.spacing['2'],
    marginBottom: theme.spacing['2'],
  },
  imgContainerWithBg: {
    backgroundColor: LOCATION_CARD_IMG_BG_COLOR,
  },
  imgXS: {
    width: LOCATION_RESULTS_CARD_IMG_SIZE_XS,
    height: LOCATION_RESULTS_CARD_IMG_SIZE_XS,
    borderRadius: theme.spacing['2'],
  },
  imgSM: {
    width: '100%',
    height: LOCATION_RESULTS_CARD_IMG_SIZE_SM,
    borderRadius: theme.spacing['2'],
  },
  illustrationXS: {
    width: LOCATION_RESULTS_CARD_ILLUSTRATION_SIZE_XS,
    height: LOCATION_RESULTS_CARD_ILLUSTRATION_SIZE_XS,
    borderRadius: theme.spacing['2'],
  },
  illustrationSM: {
    width: '100%',
    height: LOCATION_RESULTS_CARD_ILLUSTRATION_SIZE_SM,
    borderRadius: theme.spacing['2'],
  },
});

export const product = {
  // ─── DRESSING & DETAILS ─────────────────────────────────────────────

  'pdp.dressing-and-details.title': 'Dressing & details',

  'pdp.dressing-and-details.dressing.title': 'Dressing',
  'pdp.dressing-and-details.dressing.on-the-side': 'On the side',
  'pdp.dressing-and-details.dressing.mixed-in': 'Mixed in',
  'pdp.dressing-and-details.dressing.max-portions-text': '{max} portions max.',
  'pdp.dressing-and-details.dressing.optimal-time-message':
    'For optimal freshness eat within 15 minutes of pickup time.',

  // ────────────────────────────────────────────────────────────────────

  'pdp.dressing-and-details.dressing.portion': 'portion',
  'pdp.dressing-and-details.dressing.portions': 'portions',
  'pdp.dressing-and-details.dressing.increase-portion':
    'Increase portions number, currently {value} {label}',
  'pdp.dressing-and-details.dressing.decrease-portion':
    'Decrease portions number, currently {value} {label}',

  'pdp.dressing-and-details.dressing.weight.light': 'Light',
  'pdp.dressing-and-details.dressing.weight.medium': 'Medium',
  'pdp.dressing-and-details.dressing.weight.heavy': 'Heavy',

  'pdp.dressing-and-details.dressing.increase-weight':
    'Increase dressing weight, currently {label}',
  'pdp.dressing-and-details.dressing.decrease-weight':
    'Decrease dressing weight, currently {label}',

  // ────────────────────────────────────────────────────────────────────

  'pdp.dressing-and-details.bread.title': 'Add bread',
  'pdp.dressing-and-details.bread.calories': 'cals',

  // ────────────────────────────────────────────────────────────────────

  'pdp.dressing-and-details.quantity.title': 'Quantity',

  'pdp.notice.no-product-selected': 'No product selected.',

  // ─── INGREDIENT MODIFICATION ────────────────────────────────────────

  'pdp.modifications.each': 'ea',
  'pdp.modifications.maxModificationsExceeded':
    'You’ve reached the maximum amount of modifications.',
  'pdp.modifications.maxKindLimitExceeded':
    'You’ve selected the maximum amount of {kind} ({kindMaxLimit})',
  'pdp.modifications.maxKindQtyExceeded': `{kind, select,
      bases {You may only have one of each base.}
      toppings {You may only have one of each topping.}
      premiums {You may only have one of each premium.}
      dressings {You may only have one of each dressing.}
      other {The selected kind does not allow multiple quantities.}
    }`,
  'pdp.modifications.minUnmet':
    'Hey, you need at least {kindMinLimit} selection from {kind}!',
  'pdp.modifications.dressings.max-portions':
    'You may have up to {max} portion(s) of dressing with dressing "on the side."',
  'pdp.modifications.outOfStock':
    'We’re currently out of {outOfStockIngredients}! Apologies for the inconvenience. Customize to add a new ingredient of your choice.',
  'pdp.modifications.bases': 'Bases',
  'pdp.modifications.toppings': 'Toppings',
  'pdp.modifications.premiums': 'Premiums',
  'pdp.modifications.dressings': 'Dressings',
  'pdp.modifications.bread': 'Bread',
  'pdp.modifications.start-customization': 'Start customization',
  'pdp.modifications.add-ingredient-modification':
    'Add {ingredientModification}',
  'pdp.modifications.ingredient-modification-qty': 'Quantity',
  'pdp.modifications.no-dressing-modal.heading':
    'Heads up—no dressing selected',
  'pdp.modifications.no-dressing-modal.text':
    'Are you sure you’d like to continue?',
  'pdp.modifications.item-image.alt': '{ingredientName} image',
  'pdp.modifications.too-few-bases-modal.heading':
    'Heads up - only {activeBaseCount} base portion selected',
  'pdp.modifications.too-few-bases-modal.text':
    'Get a full portion by adding another base. You can add another portion of the same base or mix it up with a different base.',

  // ─── a11y ────────────────────────────────────────────────────────────

  'pdp.modifications.ingredient-modification.a11y.label':
    '{ingredientModification}.',
  'pdp.modifications.ingredient-modification.a11y.label-with-qty':
    '{ingredientModification}. Portions number: {qty}.',

  'pdp.modifications.ingredient-modification.a11y.unavailable':
    '(Currently unavailable).',

  'pdp.modifications.ingredient-modification.a11y.added':
    'Added {ingredientName}.',
  'pdp.modifications.ingredient-modification.a11y.removed':
    'Removed {ingredientName}.',
  'pdp.modifications.ingredient-modification.a11y.increased':
    'Increased "{ingredientName}" portions number to {qty}.',
  'pdp.modifications.ingredient-modification.a11y.decreased':
    'Decreased "{ingredientName}" portions number to {qty}.',

  'pdp.modifications.ingredient-modification.a11y.hint.ios':
    'Double tap to increase portions number. Triple tap to decrease portions number.',
  'pdp.modifications.ingredient-modification.a11y.hint.android':
    'Double tap to increase portions number. Double tap and hold to decrease portions number.',
  'pdp.modifications.ingredient-modification.a11y.decrease-qty':
    'Decrease portions number of {ingredientModification}.',
  'pdp.modifications.ingredient-modification.a11y.remove':
    'Remove {ingredientModification}.',

  // ─── CTAs ───────────────────────────────────────────────────────────

  'pdp.details.customize': 'Customize',
  'pdp.details.customize-line-item-name': 'Customize product name',
  'pdp.details.cancel': 'Cancel',
  'pdp.details.done': "I'm done",
  'pdp.details.add-to-bag': 'Add to Bag',
  'pdp.details.update': 'Update',
  'pdp.details.remove-from-bag': 'Remove from bag',

  // ─── CYO ────────────────────────────────────────────────────────────

  'pdp.details.cyo.heading': 'Add some ingredients to get started',

  // ────────────────────────────────────────────────────────────────────

  'product-added-ingredients-label': 'Added:',
  'product-removed-ingredients-label': 'Removed:',
};

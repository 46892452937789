import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import type { CollectionCardProps } from '../../CollectionCard';
import { CollectionCard, CollectionCardLoading } from '../../CollectionCard';

// ────────────────────────────────────────────────────────────────────

export const CollectionsRailItem = (props: CollectionRailItemProps) => {
  const { collection, isLoading, width, gap, onPress } = props;
  const {
    id = '',
    title = '',
    description = '',
    imageUrl = '',
    testID,
  } = collection ?? {};

  const dynamicStyles = useStyle(
    () => ({ width, paddingRight: gap }),
    [width, gap],
  );
  const style = [styles.item, dynamicStyles];

  return (
    <View style={style} testID="collection-card">
      {isLoading ? (
        <CollectionCardLoading />
      ) : (
        <CollectionCard
          onPress={onPress}
          description={description}
          imageUrl={imageUrl}
          id={id}
          title={title}
          testID={testID}
        />
      )}
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  item: {
    paddingVertical: theme.spacing['1'],
  },
});

// ─── TYPES ─────────────────────────────────────────────────────────────────

type CollectionRailItemProps = Readonly<{
  width: number;
  gap: number;
  collection?: Omit<CollectionCardProps, 'onPress'>;
  isLoading?: boolean;
  onPress?: (collectionId: string) => void;
}>;

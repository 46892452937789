import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import {
  AddressTypes,
  Ledger,
  ScrollViewWithHeaderTitle,
  SectionHeader,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useChallengesAndRewards } from '@order/ChallengesAndRewards';
import { useHeaderRight } from '@order/components';
import { useCart } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';
import { telemetryProductFromInput, useTelemetry } from '@order/Telemetry';
import { convertPriceToFloat } from '@order/utils';

import { DeliveryAddressListAction } from '../DeliveryAddressListAction';
import { RestaurantListAction } from '../RestaurantListAction';
import { RewardExceptionModal } from '../RewardExceptionModal';
import { BagNavigation, BagRewardNotice, Header } from './Bag.components';
import { BagEmpty } from './Bag.empty';
import { useLedgerItems } from './Bag.hooks';
import { useBagSweetpassUpgradeFlow } from './hooks';
import {
  BagAddonsRail,
  BagContentBlock,
  BagFocusScope,
  BagLineItems,
  BagRewardsRail,
  BagSweetpassUpgradeCta,
  useShouldRenderBagSweetpassUpsellCta,
} from './subcomponents';

// ────────────────────────────────────────────────────────────────────────────────

export const BagContents = (props: BagContentsProps) => {
  const { navigateToCheckout, navigateToMenu, canChangeLocation } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const { minWidth, match } = useResponsive();
  const { cart, cartOrderType, isCartEmpty, total } = useCart();
  const lineItems = cart?.lineItems;
  const { track } = useTelemetry();
  const navigation = useNavigation();

  const { applyRewardExceptionMessage, applyRewardHasNewException } =
    useChallengesAndRewards();
  const { hasFailedToFetchNewRewards, isFetchingUpgradedRewards } =
    useBagSweetpassUpgradeFlow();

  // ─── Navigation Controls ─────────────────────────────────────────────

  const navigationHeaderRightItems = useMemo<NavigationHeaderRightItems>(
    () => [
      {
        key: 'bag.close-btn',
        icon: 'IconClose',
        accessibilityLabel: t('general.close'),
        onPress: navigation.goBack,
      },
    ],
    [navigation.goBack, t],
  );

  const navigationHeaderRight = useHeaderRight({
    items: navigationHeaderRightItems,
  });

  // ─── Effects ─────────────────────────────────────────────────────────

  useLayoutEffect(() => {
    navigation.setOptions({
      title: t('bag-title', { orderType: cartOrderType }),
      headerRight: match([navigationHeaderRight, () => null]),
    });
  }, [t, navigation, cartOrderType, navigationHeaderRight, match]);

  useFocusEffect(
    useCallback(() => {
      track('bag.view', {
        orderType: cartOrderType,
        orderValue: convertPriceToFloat(total),
        products: lineItems?.map(({ product, cost, quantity, customName }) =>
          telemetryProductFromInput({
            ...product,
            cost,
            quantity,
            name: customName ?? product.name,
          }),
        ),
      });
    }, [cartOrderType, total, lineItems, track]),
  );

  const isRemoveLedgerFromBagEnabled = useFeatureFlag(
    'CELS-2364-remove-ledger-from-bag',
  );

  // ─── Styles ──────────────────────────────────────────────────────

  const contentsStyle = [
    styles.content,
    !isRemoveLedgerFromBagEnabled && styles.contentWithLedger,
    minWidth.isSM && styles.contentSM,
  ];

  // ─────────────────────────────────────────────────────────────────

  return (
    <BagFocusScope>
      <ScrollViewWithHeaderTitle
        testID="bag-contents"
        style={contentsStyle}
        contentContainerStyle={styles.grow}
      >
        {isCartEmpty ? (
          <BagEmpty onPressViewMenu={navigateToMenu} />
        ) : (
          <BagInnerContents
            canChangeLocation={canChangeLocation}
            isFetchingUpgradedRewards={isFetchingUpgradedRewards}
            hasFailedToFetchNewRewards={hasFailedToFetchNewRewards}
          />
        )}
      </ScrollViewWithHeaderTitle>

      <BagNavigation
        navigateToCheckout={navigateToCheckout}
        navigateToMenu={navigateToMenu}
        isFetchingUpgradedRewards={isFetchingUpgradedRewards}
      />

      <RewardExceptionModal
        visible={applyRewardHasNewException}
        message={applyRewardExceptionMessage}
      />
    </BagFocusScope>
  );
};

export const BagInnerContents = (props: BagInnerContentsProps) => {
  const {
    canChangeLocation,
    isFetchingUpgradedRewards,
    hasFailedToFetchNewRewards,
  } = props;

  const { t } = useLocalizationContext();
  const { cart, cartOrderType } = useCart();
  const { id: orderId, ledger, restaurant, deliveryOrderDetail } = cart ?? {};

  const showTip = false;
  const ledgerItems = useLedgerItems({
    ledger,
    showTip,
    showReward: false,
  });

  const isRemoveLedgerFromBagEnabled = useFeatureFlag(
    'CELS-2364-remove-ledger-from-bag',
  );
  const shouldRenderAddOnsRail = useFeatureFlag(
    'mnu-5612-enable-bag-add-ons-rail',
  );

  // ─── Sweetpass Upsell CTA ────────────────────

  const {
    shouldRenderAfterRewards: shouldRenderSweetpassCtaAfterRewards,
    shouldRenderBeforeLedger: shouldRenderSweetpassCtaBeforeLedger,
  } = useShouldRenderBagSweetpassUpsellCta();
  const shouldRenderSweetpassUpsellCta =
    shouldRenderSweetpassCtaAfterRewards ||
    shouldRenderSweetpassCtaBeforeLedger;

  // ─────────────────────────────────────────────

  return (
    <View style={styles.innerContentsWrapper}>
      <BagContentBlock>
        <Header orderType={cartOrderType} />
      </BagContentBlock>

      <BagContentBlock>
        {cartOrderType === AddressTypes.delivery ? (
          <DeliveryAddressListAction
            orderId={orderId}
            deliveryAddress={deliveryOrderDetail?.address}
            deliveryOrderDetail={deliveryOrderDetail ?? {}}
            restaurant={restaurant}
            orderType={cartOrderType}
            canChangeLocation={canChangeLocation}
          />
        ) : (
          <RestaurantListAction
            orderId={orderId}
            restaurant={restaurant}
            orderType={cartOrderType}
            canChangeLocation={canChangeLocation}
          />
        )}
      </BagContentBlock>

      <BagContentBlock style={styles.rewardsRailBlock}>
        <BagRewardsRail
          isFetchingUpgradedRewards={isFetchingUpgradedRewards}
          hasFailedToFetchNewRewards={hasFailedToFetchNewRewards}
          shouldUseCompactEmptyMode={shouldRenderSweetpassUpsellCta}
        />

        {shouldRenderSweetpassCtaAfterRewards ? (
          <BagSweetpassUpgradeCta location="below-rewards-rail" />
        ) : null}
      </BagContentBlock>

      <BagContentBlock>
        <SectionHeader heading={t('general.your-order')} />
      </BagContentBlock>

      <BagContentBlock>
        <BagLineItems />
      </BagContentBlock>

      {shouldRenderAddOnsRail ? (
        <BagContentBlock style={styles.addonRailBlock}>
          <BagAddonsRail isDelivery={cartOrderType === 'delivery'} />
        </BagContentBlock>
      ) : null}

      {isRemoveLedgerFromBagEnabled ? null : (
        <BagContentBlock style={styles.ledgerBlock}>
          {shouldRenderSweetpassCtaBeforeLedger ? (
            <BagSweetpassUpgradeCta location="above-ledger" />
          ) : null}
          <Ledger items={ledgerItems} testID="bag.ledger" />
        </BagContentBlock>
      )}

      {isRemoveLedgerFromBagEnabled ? null : (
        <BagContentBlock>
          <BagRewardNotice />
        </BagContentBlock>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexGrow: 1,
  },
  contentWithLedger: {
    paddingBottom: theme.spacing['20'],
  },
  contentSM: {
    paddingVertical: theme.spacing['6'],
  },
  grow: {
    flexGrow: 1,
  },
  innerContentsWrapper: {
    paddingBottom: theme.spacing['4'],
  },
  rewardsRailBlock: {
    marginBottom: theme.spacing['10'],
  },
  addonRailBlock: {
    backgroundColor: theme.colors.QUINOA,
    marginTop: theme.spacing['6'],
    marginBottom: theme.spacing['10'],
  },
  ledgerBlock: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.DARK_KALE,
  },
});

// ––– TYPES ––––––––––––––––––––––––––––––––––––––––––––––––––

type BagContentsProps = Readonly<{
  navigateToCheckout: () => void;
  navigateToMenu: () => void;
  canChangeLocation: () => boolean;
}>;

type BagInnerContentsProps = Readonly<{
  canChangeLocation: () => boolean;

  // Indicates whether the app is fetching new rewards following the Sweetpass upgrade.
  isFetchingUpgradedRewards: boolean;

  // Indicates whether the new premium awards were unable to be obtained.
  hasFailedToFetchNewRewards?: boolean;
}>;

type NavigationHeaderRightItems = Parameters<typeof useHeaderRight>[0]['items'];

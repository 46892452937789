/* istanbul ignore file */

import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useClient } from 'urql';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useFavorites } from '@order/features/orders';

import {
  ReorderFavoritesDocument,
  type ReorderFavoritesQuery,
  type ReorderFavoritesQueryVariables,
  useToggleReorderLineItemFavoriteStateMutation,
} from '../../graphql/ReorderFavorites.generated';
import { reorderFavoritesMessages as messages } from './useReorderFavorites.messages';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A hook for fetching the user's favorites with pagination support.
 */
export const useReorderFavorites = () => {
  const client = useClient();
  const isLoggedIn = useIsLoggedIn();
  const { push: addBanner } = useNoticeBannersStackContext();
  const { formatMessage } = useIntl();

  // ─── Queries and Mutations ───────────────────────────────────────────

  const favoritesQuery = useCallback(
    async (input: ReorderFavoritesQueryVariables['input']) => {
      const query = client.query<
        ReorderFavoritesQuery,
        ReorderFavoritesQueryVariables
      >;

      return query(
        ReorderFavoritesDocument,
        { input },
        { requestPolicy: 'network-only' },
      ).toPromise();
    },
    [client.query],
  );

  const [, toggleReorderLineItemFavoriteState] =
    useToggleReorderLineItemFavoriteStateMutation();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const fetchFavorites = useCallback<FetchFavorites>(
    async (context) => {
      const { page } = context;

      if (!isLoggedIn) return { favorites: [] };

      const { data } = await favoritesQuery({ page });

      const favoritesData = data?.favoritedLineItems;
      const hasResolvedFavorites = Array.isArray(favoritesData);

      if (!hasResolvedFavorites) {
        return { favorites: [] };
      }

      return { favorites: favoritesData };
    },
    [favoritesQuery, isLoggedIn],
  );

  const toggleFavorite = useCallback<ToggleFavorite>(
    async (_context, event) => {
      const { id, isFavorite, productId } = event;

      const response = await toggleReorderLineItemFavoriteState({
        input: { favorited: isFavorite, lineItemId: id, productId },
      });
      const data = response.data?.favoriteLineItem;
      const hasUpdatedFavoriteState = data?.__typename === 'LineItem';

      if (!hasUpdatedFavoriteState) {
        addBanner({
          palette: 'caution',
          text: formatMessage(messages.failedToToggleFavoriteStateMessage),
        });

        return null;
      }

      return { id: data?.id, isFavorite: data?.favorited };
    },
    [toggleReorderLineItemFavoriteState, addBanner, formatMessage],
  );

  // ─────────────────────────────────────────────────────────────────────

  return useFavorites<Favorite>({ fetchFavorites, toggleFavorite });
};

// ─── Types ───────────────────────────────────────────────────────────────────

type FetchFavorites = UseFavoritesParams['fetchFavorites'];

type ToggleFavorite = UseFavoritesParams['toggleFavorite'];

type Favorite = Favorites[number];

type Favorites = NonNullable<ReorderFavoritesQuery['favoritedLineItems']>;

type UseFavoritesParams = Parameters<typeof useFavorites<Favorite>>[0];

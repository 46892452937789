import { useContext } from 'react';

import { LaunchDarklyContext } from './LaunchDarklyProvider.context';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Launch Darkly context hook.
 *
 * @example
 * const { initialFeatureFlags } = useLaunchDarkly();
 *
 * @see {@link https://www.launchdarkly.com/ Launch Darkly}
 */
export const useLaunchDarkly = () => {
  const context = useContext(LaunchDarklyContext);

  if (context === undefined) {
    throw new Error(
      'useLaunchDarkly must be used within a <LaunchDarklyProvider>',
    );
  }

  return context;
};

import React, { useCallback, useContext, useState } from 'react';
import { StyleSheet } from 'react-native';
import {
  Container,
  JumpNavigationContext,
  JumpNavigator,
  slugify,
  Sticky,
  TabNav,
  theme,
} from '@sg/garnish';

import { DeliveryFeeDisclosure } from '@order/components';
import { useTelemetry } from '@order/Telemetry';

import type { CategoryFragment } from '../../GraphQL/Restaurant.query.generated';

export const MenuCategoriesNav = (props: CategoriesNavigationProps) => {
  const { categories, restaurantSlug, deliveryFeeDisclosure } = props;
  const { track } = useTelemetry();

  // Select Category by Scrolling
  const jumpContext = useContext(JumpNavigationContext);
  const { currentTarget, setCurrentTarget } = jumpContext;
  const scrolledIndex = useCategoryById(categories, currentTarget);

  // Select Category by Clicking
  const [selectedIndex, selectIndex] = useState(-1);
  const categoryIndex = selectedIndex > -1 ? selectedIndex : scrolledIndex;

  // Reset Selected Category when Scrolling
  React.useEffect(() => {
    if (scrolledIndex !== selectedIndex) return;
    selectIndex(-1);
  }, [scrolledIndex, selectedIndex]);

  // Category Selection
  const onChange = useCallback(
    (index: number) => {
      const categoryId = categories[index].id;

      selectIndex(index);
      setCurrentTarget(categoryId);

      if (!categoryId) return;

      // ─────────────────────── Telemetry ──────────────────────────────────

      track('menu.jump_nav', {
        id: restaurantSlug,
        tabName: categories[index].name,
      });

      // ────────────────────────────────────────────────────────────────────
    },
    [categories, restaurantSlug, setCurrentTarget, track],
  );

  return (
    <Sticky>
      {deliveryFeeDisclosure ? (
        <Container style={styles.discloseFeeContainer}>
          <DeliveryFeeDisclosure text={deliveryFeeDisclosure} />
        </Container>
      ) : null}

      <JumpNavigator jumpContext={jumpContext} style={styles.container}>
        <TabNav
          disableAnimation
          value={categoryIndex}
          onChange={onChange}
          testID="menupage.category-container"
        >
          {categories.map((category: CategoryFragment) => (
            <TabNav.NavItem
              key={category.id}
              accessibilityLabel={`Navigate to ${category.name}`}
              accessibilityHint={`Navigate to ${category.name}`}
              testID={`menupage.${slugify(category.name)}-category`}
            >
              {category.name}
            </TabNav.NavItem>
          ))}
        </TabNav>
      </JumpNavigator>
    </Sticky>
  );
};

const useCategoryById = (
  categories: readonly CategoryFragment[],
  categoryId: string,
) => {
  return categories?.findIndex(({ id }: CategoryFragment) => id === categoryId);
};

type CategoriesNavigationProps = Readonly<{
  categories: readonly CategoryFragment[];
  restaurantSlug?: string;
  deliveryFeeDisclosure?: string;
}>;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.colors.APP_BACKGROUND,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.OPACITY.DARK_KALE.DARK,
  },
  discloseFeeContainer: {
    flexDirection: 'row',
    padding: theme.spacing['4'],
  },
});

/* eslint-disable no-await-in-loop */
/* eslint-disable functional/no-loop-statements */

import React from 'react';
import type { AddressType, PinData } from '@sg/garnish';
import { findCenterRegion, getCustomerGeolocationPin } from '@sg/garnish';

import { LocationsMap } from '@order/components';
import type { CourierDetails } from '@order/graphql';
import { FlattenedOrderStatuses } from '@order/graphql';
import { usePrevious } from '@order/hooks';

export const OrderStatusMap = (props: OrderStatusMapProps) => {
  const [selectedPin, setSelectedPin] = React.useState('');

  const filteredProps = usePropsByOrderType(props);
  const pinCoordinates = useAnimatedPins(filteredProps);
  const animatedProps = { ...filteredProps, ...pinCoordinates };
  const region = useRegion(animatedProps);
  const pins = usePins(selectedPin, animatedProps);

  return (
    <LocationsMap region={region} pins={pins} onPinPress={setSelectedPin} />
  );
};

const usePropsByOrderType = ({
  orderType,
  orderStatus,
  locationName,
  locationLatitude,
  locationLongitude,
  customerName,
  customerLatitude,
  customerLongitude,
  deliveryAddressName,
  deliveryAddressType,
  deliveryAddressLatitude,
  deliveryAddressLongitude,
  courierName,
  courierLatitude,
  courierLongitude,
}: OrderStatusMapProps): OrderStatusMapProps => {
  if (orderType === 'pickup') {
    return {
      orderType,
      locationName,
      locationLatitude,
      locationLongitude,
      customerName,
      customerLatitude,
      customerLongitude,
    };
  }

  if (orderType === 'outpost') {
    return {
      orderType,
      locationName,
      locationLatitude,
      locationLongitude,
    };
  }

  if (orderStatus === FlattenedOrderStatuses.Delivering) {
    return {
      orderType,
      deliveryAddressName,
      deliveryAddressType,
      deliveryAddressLatitude,
      deliveryAddressLongitude,
      courierName,
      courierLatitude,
      courierLongitude,
    };
  }

  return {
    orderType,
    deliveryAddressName,
    deliveryAddressType,
    deliveryAddressLatitude,
    deliveryAddressLongitude,
  };
};

const useRegion = ({
  locationLatitude,
  locationLongitude,
  customerLatitude,
  customerLongitude,
  deliveryAddressLatitude,
  deliveryAddressLongitude,
  courierLatitude,
  courierLongitude,
}: OrderStatusMapProps) => {
  return React.useMemo(() => {
    const center = findCenterRegion(
      [
        {
          lat: locationLatitude ?? 0,
          lng: locationLongitude ?? 0,
        },
        {
          lat: customerLatitude ?? 0,
          lng: customerLongitude ?? 0,
        },
        {
          lat: deliveryAddressLatitude ?? 0,
          lng: deliveryAddressLongitude ?? 0,
        },
        {
          lat: courierLatitude ?? 0,
          lng: courierLongitude ?? 0,
        },
      ],
      { width: 300, height: 200 },
    );
    const centerZoom = center.zoom ? center.zoom - 1 : 21;
    const zoom = Math.min(centerZoom, 16);

    return { ...center, zoom };
  }, [
    locationLatitude,
    locationLongitude,
    customerLatitude,
    customerLongitude,
    deliveryAddressLatitude,
    deliveryAddressLongitude,
    courierLatitude,
    courierLongitude,
  ]);
};

const usePins = (
  selectedPin: string,
  {
    orderType,
    locationName,
    locationLatitude,
    locationLongitude,
    customerName,
    customerLatitude,
    customerLongitude,
    deliveryAddressName,
    deliveryAddressType,
    deliveryAddressLatitude,
    deliveryAddressLongitude,
    courierName,
    courierLatitude,
    courierLongitude,
  }: OrderStatusMapProps,
): readonly PinData[] => {
  const customerGeoPositionPin = getCustomerGeolocationPin({
    geolocation: { latitude: customerLatitude, longitude: customerLongitude },
    customerName,
    selectedPin,
  });

  return React.useMemo(() => {
    const pins = [
      Boolean(locationLatitude) && {
        id: 'location',
        active: selectedPin === 'location',
        name: locationName,
        lat: locationLatitude,
        lng: locationLongitude,
        acceptingOrders: true,
        isOutpost: orderType === 'outpost',
      },
      customerGeoPositionPin,
      Boolean(deliveryAddressLatitude) && {
        id: 'deliveryAddress',
        active: selectedPin === 'deliveryAddress',
        name: deliveryAddressName,
        lat: deliveryAddressLatitude,
        lng: deliveryAddressLongitude,
        deliveryAddressType,
        isDeliveryAddress: true,
      },
      Boolean(courierLatitude) && {
        id: 'courier',
        active: selectedPin === 'courier',
        name: courierName,
        lat: courierLatitude,
        lng: courierLongitude,
        isCourier: true,
      },
    ].filter(Boolean);

    return pins as readonly PinData[];
  }, [
    locationLatitude,
    selectedPin,
    locationName,
    locationLongitude,
    orderType,
    customerGeoPositionPin,
    deliveryAddressLatitude,
    deliveryAddressName,
    deliveryAddressLongitude,
    deliveryAddressType,
    courierLatitude,
    courierName,
    courierLongitude,
  ]);
};

type OrderStatusMapProps = Readonly<{
  orderType?: AddressType;
  orderStatus?: FlattenedOrderStatuses;

  locationName?: string;
  locationLatitude?: number;
  locationLongitude?: number;

  customerName?: string;
  customerLatitude?: number;
  customerLongitude?: number;

  deliveryAddressName?: string;
  deliveryAddressType?: 'home' | 'work' | 'custom';
  deliveryAddressLatitude?: number;
  deliveryAddressLongitude?: number;

  courierName?: CourierDetails['name'];
  courierLatitude?: CourierDetails['latitude'];
  courierLongitude?: CourierDetails['longitude'];
}>;

// ─────────────────────────── Pins Animation ───────────────────────────
// `google-map-react` doesn't support animated markers and we cannot use react-native-reanimated
// since the markers position is controlled by the library, so we "animate" the coordinates.
const useAnimatedPins = (props: OrderStatusMapProps) => {
  const {
    locationLatitude,
    locationLongitude,
    customerLatitude,
    customerLongitude,
    deliveryAddressLatitude,
    deliveryAddressLongitude,
    courierLatitude,
    courierLongitude,
  } = props;

  const hasCourierGeolocation = courierLatitude && courierLongitude;
  const courierGeolocation = hasCourierGeolocation
    ? { courierLatitude, courierLongitude }
    : {};

  return {
    locationLatitude: usePinAnimation(locationLatitude),
    locationLongitude: usePinAnimation(locationLongitude),
    customerLatitude: usePinAnimation(customerLatitude),
    customerLongitude: usePinAnimation(customerLongitude),
    deliveryAddressLatitude: usePinAnimation(deliveryAddressLatitude),
    deliveryAddressLongitude: usePinAnimation(deliveryAddressLongitude),
    ...courierGeolocation,
  };
};

const usePinAnimation = (coordinate?: number) => {
  const previous = usePrevious(coordinate);
  const [result, setResult] = React.useState(previous);

  React.useEffect(() => {
    if (!coordinate || !previous) return;
    void animateCoordinate(previous, coordinate, setResult);
  }, [coordinate, previous]);

  if (!coordinate) return undefined;

  return result ?? coordinate;
};

const animateCoordinate = async (
  start: number,
  end: number,
  callback: (result: number) => void,
) => {
  if (end > start) {
    const increment = (end - start) / 100;
    let current = start;

    while (current < end) {
      current += increment;
      callback(current);
      await delay();
    }
  }

  if (start > end) {
    const decrement = (start - end) / 100;
    let current = start;

    while (current > end) {
      current -= decrement;
      callback(current);
      await delay();
    }
  }
};

const ANIMATION_DELAY = 1;
const delay = async () => {
  return new Promise((resolve) => {
    setTimeout(resolve, ANIMATION_DELAY);
  });
};

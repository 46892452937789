import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { APPLE_PAY } from '@order/constants';
import { type usePaymentMethods } from '@order/hooks';

import { type CheckoutPayload } from '../../Checkout.utils';

// ────────────────────────────────────────────────────────────────────────────

export const usePlaceOrder = (props: PlaceOrderProps) => {
  const {
    payload,
    orderId,
    cannotSubmitOrderReason,
    shouldBlockCheckoutOnPress,
    handleInvalidWantedTime,
    validateCheckoutFields,
    submitOrder,
  } = props;

  // ─── Context ──────────────────────────────────────────────────────────────

  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  // ─── Validation ───────────────────────────────────────────────────────────

  const validateCanPlaceOrder = useCallback(() => {
    validateCheckoutFields(payload);

    const shouldShowError = !orderId || shouldBlockCheckoutOnPress;

    if (shouldShowError) {
      addNoticeBanner({ text: cannotSubmitOrderReason, palette: 'caution' });

      return false;
    }

    return true;
  }, [
    payload,
    cannotSubmitOrderReason,
    orderId,
    shouldBlockCheckoutOnPress,
    validateCheckoutFields,
    addNoticeBanner,
  ]);

  // ─── Submit Order ─────────────────────────────────────────────────────────

  const validateAndSubmitOrder = useCallback(
    async (paymentMethodId?: string) => {
      if (orderId && validateCanPlaceOrder()) {
        await submitOrder({
          input: {
            ...payload,
            paymentMethodId: paymentMethodId ?? payload.paymentMethodId,
            paymentMethodType: 'PaymentMethodCard',
            orderId,
          },
          handleInvalidWantedTime,
        });
      }
    },
    [
      orderId,
      payload,
      validateCanPlaceOrder,
      handleInvalidWantedTime,
      submitOrder,
    ],
  );

  return {
    validateCanPlaceOrder,
    validateAndSubmitOrder,
    isCheckoutWithApplePay,
  };
};

// ─── Helpers ────────────────────────────────────────────────────────────────

function isCheckoutWithApplePay(props: PlaceOrderProps) {
  const { payload, paymentMethods } = props;

  return (
    payload.paymentMethodId !== 'ADD' &&
    (payload.paymentMethodId === APPLE_PAY ||
      !paymentMethods.some((pm) => pm.id === payload.paymentMethodId))
  );
}

// ─── Types ──────────────────────────────────────────────────────────────────

type PlaceOrderProps = {
  payload: CheckoutPayload;
  orderId: string;
  cannotSubmitOrderReason: string;
  shouldBlockCheckoutOnPress: boolean;
  paymentMethods: ReturnType<typeof usePaymentMethods>['paymentMethods'];
  handleInvalidWantedTime: () => void;
  validateCheckoutFields: (payload: CheckoutPayload) => void;
  submitOrder: (payload: {
    input: CheckoutPayload & {
      orderId: string;
    };
    handleInvalidWantedTime: () => void;
  }) => Promise<void>;
};

import React from 'react';
import type { PressableProps } from 'react-native';
import { Animated, Platform, Pressable, StyleSheet, View } from 'react-native';
import { useSwitch } from '@react-native-aria/switch';
import { COLORS } from '@garnish/constants';

import { useStyleSwitch } from '../../hooks/useStyleSwitch';
import { Icon } from '../Icon';
import type { StateTypes, SwitchProps } from './Switch.types';

export const sizeStylesConfigs = {
  large: {
    width: 64,
    height: 32,
    borderRadius: 16,
  },
  small: {
    width: 48,
    height: 24,
    borderRadius: 12,
  },
};

export const thumbStylesConfigs = {
  large: {
    width: 28,
    height: 28,
  },
  small: {
    width: 20,
    height: 20,
  },
};
export const iconsStylesConfigs = {
  large: {
    paddingHorizontal: 8,
    paddingVertical: 8,
  },
  small: {
    paddingHorizontal: 4,
    paddingVertical: 4,
  },
};

export const Switch = (props: SwitchProps & PressableProps) => {
  const {
    value,
    onChange,
    disabled = false,
    showIcons = true,
    size = 'small',
    accessibilityLabel = 'Garnish Switch',
    accessibilityHint = 'Garnish Switch',
    testID,
  } = props;

  const { height: transitionWidth } = sizeStylesConfigs[size] || {};

  const useSizeStyles = useStyleSwitch<StateTypes>('size', sizeStylesConfigs);
  const useThumbStyles = useStyleSwitch<StateTypes>('size', thumbStylesConfigs);
  const useIconStyles = useStyleSwitch<StateTypes>('size', iconsStylesConfigs);

  const inputRef = React.useRef(null);
  const offsetX = React.useRef(new Animated.Value(0));
  const { inputProps } = useSwitch(
    {
      'aria-label': accessibilityLabel,
      accessibilityLabel,
      accessibilityHint,
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      toggle() {}, // @REVIEW
      isSelected: value,
      setSelected: onChange,
    },
    inputRef,
  );

  const handleChange = React.useCallback(() => {
    onChange(!value);
  }, [onChange, value]);

  Animated.timing(offsetX.current, {
    toValue: value ? transitionWidth : 0,
    duration: 250,
    useNativeDriver: Platform.OS !== 'web',
  }).start();

  const pressableStyles = [
    styles.switchPosition,
    disabled
      ? { backgroundColor: COLORS.OPACITY.DARK_KALE.LIGHTER }
      : { backgroundColor: value ? COLORS.GREEN_2 : COLORS.NEUTRAL_4 },
    useSizeStyles(size),
  ];

  const thumbStyles = [
    styles.thumb,
    useThumbStyles(size),
    disabled
      ? { backgroundColor: COLORS.OPACITY.DARK_KALE.LIGHT }
      : { backgroundColor: COLORS.WHITE },
  ];

  const thumbPositionStyle = [
    styles.thumbPosition,
    {
      transform: [{ translateX: offsetX.current }],
    },
  ];

  const iconStyles = [styles.iconGroup, useIconStyles(size)];

  const iconColor = disabled ? COLORS.OPACITY.DARK_KALE.LIGHT : COLORS.WHITE;

  return (
    <View style={styles.container}>
      {/* ===== Pressable Switch component ===== */}
      <Pressable
        {...inputProps}
        style={pressableStyles}
        testID={testID}
        disabled={disabled}
        onPress={handleChange}
        accessibilityRole="switch"
        accessibilityLabel={accessibilityLabel}
        accessibilityChecked={value}
      >
        {/* ===== Switch Thumb component ===== */}
        <Animated.View style={thumbPositionStyle}>
          <View style={thumbStyles} />
        </Animated.View>

        {/* ===== Switch Icon Group component ===== */}
        {showIcons ? (
          <View style={iconStyles}>
            <Icon name="IconCheck" color={iconColor} width={16} height={16} />
            <Icon name="IconClose" color={iconColor} width={16} height={16} />
          </View>
        ) : null}
      </Pressable>
    </View>
  );
};

export const Spacer = ({ space }: Readonly<{ space: number }>) => {
  return <View style={{ width: space }} />;
};

const styles = StyleSheet.create({
  iconGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switchPosition: {
    position: 'relative',
  },
  thumbPosition: {
    position: 'absolute',
    top: 2,
    left: 2,
    zIndex: 1,
  },
  thumb: {
    backgroundColor: COLORS.WHITE,
    borderRadius: 33,
  },
});

import React from 'react';
import { ListActionSwitch } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { useCheckoutContext } from '../../../CheckoutProvider';

export const ConnectedCheckoutPaymentBlockAvailableCredit = () => {
  const context = useCheckoutContext();

  return <CheckoutPaymentBlockAvailableCredit {...context} />;
};

export const CheckoutPaymentBlockAvailableCredit = (
  props: Pick<
    ReturnType<typeof useCheckoutContext>,
    | 'availableCredit'
    | 'payload'
    | 'checkoutCredit'
    | 'handleAvailableCreditSelected'
  >,
) => {
  const {
    availableCredit,
    payload,
    checkoutCredit,
    handleAvailableCreditSelected,
  } = props;
  const { useCredit } = payload;

  const { t, formatPrice } = useLocalizationContext();

  if (!availableCredit) return null;

  const creditText = t('checkout.available-credit', {
    amount: formatPrice(checkoutCredit, 'USD'),
    available: formatPrice(availableCredit, 'USD'),
  });

  return (
    <ListActionSwitch
      iconName="IconBadgeScan"
      title={t('checkout.use-credit')}
      text={creditText}
      value={useCredit}
      onChange={handleAvailableCreditSelected}
    />
  );
};

import { useCallback } from 'react';
import { Linking } from 'react-native';
import { useLinkTo } from '@react-navigation/native';
import { logger } from '@garnish/logger';

import { useNoticeBannersStackContext } from '../../components/Banners/NoticeBannersStack/NoticeBannersStackProvider';
import {
  checkIfInternalUrl,
  formatUniversalURL,
  getAppAssociatedDomains,
} from '../../utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A universal (aka cross-platform) URLs handler.
 *
 * Returns a helper that can be used to open both internal and external URLs
 * such as 'http://www.example.com', '/in-app-route', .etc.
 *
 * Dependencies:
 *
 * - `NoticeBannerStackProvider` (Garnish)
 * - `NavigationContainer` (React Native Navigation)
 *
 * App associated domains
 *
 * This hook additionally supports internal URLs that begin with one of the
 * associated app domains, which may be provided as an Expo app env variable.
 * Hook will check for the mentioned env variable, which can be specified
 * in the `expo.extra` object of the Expo `app.config` file.
 *
 * @see {@link https://docs.expo.dev/guides/environment-variables/#reading-environment-variables}
 */
export const useOpenURL = () => {
  const linkTo = useLinkTo();
  const { push: addBanner } = useNoticeBannersStackContext();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const showErrorBanner = useCallback(
    (errorMessage: string) => {
      addBanner({ palette: 'caution', text: errorMessage });
    },
    [addBanner],
  );

  /**
   * Tries to open external URL using system browser.
   */
  const openExternalUrl = useCallback<UrlHandler>(
    async (url, errorMessage) => {
      const canOpenUrl = await Linking.canOpenURL(url);

      if (!canOpenUrl) {
        if (errorMessage) showErrorBanner(errorMessage);

        return;
      }

      await Linking.openURL(url);
    },
    [showErrorBanner],
  );

  /**
   * Tries to open provided internal URL using React Navigation.
   */
  const openInternalUrl = useCallback<UrlHandler>(
    (url, errorMessage) => {
      try {
        linkTo(url);
      } catch (error: unknown) {
        if (errorMessage) showErrorBanner(errorMessage);

        messageLogger.error(error);
      }
    },
    [linkTo, showErrorBanner],
  );

  // ─────────────────────────────────────────────────────────────────────

  return useCallback<UrlHandler>(
    (url, errorMessage) => {
      const formattedUrl = formatUniversalURL(url, APP_ASSOCIATED_DOMAINS);
      const isInternalURL = checkIfInternalUrl(formattedUrl);

      if (isInternalURL) {
        openInternalUrl(formattedUrl, errorMessage);

        return;
      }

      openExternalUrl(url, errorMessage);
    },
    [openExternalUrl, openInternalUrl],
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const APP_ASSOCIATED_DOMAINS = getAppAssociatedDomains();

// ─── Logger ──────────────────────────────────────────────────────────────────

logger.enable('useOpenURL');

const messageLogger = logger.extend('useOpenURL');

// ─── Types ───────────────────────────────────────────────────────────────────

type UrlHandler = (url: string, errorMessage?: string) => void;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SweetpassNextBillingDateQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SweetpassNextBillingDateQuery = {
  readonly __typename?: 'Query';
  readonly getSubscriptions: ReadonlyArray<{
    readonly __typename?: 'PaymentSubscription';
    readonly id: string;
    readonly nextBillingDate: string;
  }>;
};

export const SweetpassNextBillingDateDocument = gql`
  query SweetpassNextBillingDate {
    getSubscriptions(status: [ACTIVE, PAST_DUE]) {
      id
      nextBillingDate
    }
  }
`;

export function useSweetpassNextBillingDateQuery(
  options?: Omit<
    Urql.UseQueryArgs<SweetpassNextBillingDateQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    SweetpassNextBillingDateQuery,
    SweetpassNextBillingDateQueryVariables
  >({ query: SweetpassNextBillingDateDocument, ...options });
}

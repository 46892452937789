import React from 'react';
import type { ScrollView } from 'react-native';
import { Platform, StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';
import { useIsFocused } from '@react-navigation/native';
import { Main } from '@expo/html-elements';
import { PortalProvider } from '@gorhom/portal';
import type { KeyboardAvoiderScrollProps } from '@sg/garnish';
import {
  KeyboardAvoiderProvider,
  KeyboardAvoiderScrollView,
  ScrollViewWithHeaderTitle,
  webOnlyStyles,
} from '@sg/garnish';

import { AppFooter } from '../AppFooter';
import { PageWrapperSafeAreaView } from './PageWrapperSafeAreaView';

// ────────────────────────────────────────────────────────────────────────────────

// Thin wrapper for scroll views that optionally adds the app footer to the bottom.
export const PageWrapper = ({
  children,
  contentContainerStyle,
  safeAreaEdges,
  style,
  withHeaderTitle,
  withoutFooter,
  ...rest
}: PageWrapperProps) => {
  const localContentContainerStyle = [styles.grow, contentContainerStyle];
  const scrollViewWebOnlyStyle = webOnlyStyles({ userSelect: 'auto' });
  const scrollViewStyle = [scrollViewWebOnlyStyle, style];
  const shouldHideFromA11y = useShouldHideFromA11y();

  const ScrollViewWrapper = (
    withHeaderTitle ? ScrollViewWithHeaderTitle : Animated.ScrollView
  ) as (props: KeyboardAvoiderScrollProps) => React.ReactElement;

  return (
    <PortalProvider>
      <KeyboardAvoiderProvider>
        <KeyboardAvoiderScrollView
          ExistingScrollView={ScrollViewWrapper}
          style={scrollViewStyle}
          contentContainerStyle={localContentContainerStyle}
          {...{ accessibilityHidden: shouldHideFromA11y }}
          {...rest}
        >
          <PageWrapperSafeAreaView edges={safeAreaEdges}>
            <Main style={localContentContainerStyle}>{children}</Main>
            {withoutFooter ? null : <AppFooter />}
          </PageWrapperSafeAreaView>
        </KeyboardAvoiderScrollView>
      </KeyboardAvoiderProvider>
    </PortalProvider>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

// Screens should be hidden from accessibility when not focused.
// This is because modals show up on top of main stack screens.
// And both modals and main stack screens can have `<main />` tags.
// And only one `<main />` tag is allowed per html render.
const useShouldHideFromA11y = () => {
  const isScreenFocused = useIsFocused();

  return Platform.OS === 'web' && !isScreenFocused;
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  grow: {
    flexGrow: 1,
  },
});

// ────────────────────────────────────────────────────────────────────────────────

type PageWrapperProps = Readonly<{
  withHeaderTitle?: boolean;
  withoutFooter?: boolean;
  safeAreaEdges?: React.ComponentProps<typeof PageWrapperSafeAreaView>['edges'];
}> &
  React.ComponentProps<typeof ScrollView> &
  React.ComponentProps<typeof ScrollViewWithHeaderTitle>;

import AsyncStorage from '@react-native-async-storage/async-storage';
import { logger as LOG } from '@garnish/logger';

import { CSRF_KEY } from './constants';

// ────────────────────────────────────────────────────────────────────────────────
// LOG.enable('AUTH_STORAGE');
const logger = LOG.extend('AUTH_STORAGE');
// ────────────────────────────────────────────────────────────────────────────────

export const AuthStorageWeb = {
  async getToken() {
    const token = await AsyncStorage.getItem(CSRF_KEY);

    logger.debug('AuthStorage.getToken', token);

    return token;
  },
  async deleteToken() {
    await AsyncStorage.removeItem(CSRF_KEY);
    logger.debug('AuthStorage.deleteToken');
  },
  async setToken(csrfToken: string) {
    await AsyncStorage.setItem(CSRF_KEY, csrfToken);
    logger.debug('AuthStorage.setToken', csrfToken);
  },
};

export const AuthStorage = {
  ...AuthStorageWeb,
};

import { useEffect } from 'react';
import { useUserLocation as useUserLocationGarnish } from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';

/**
 * Wrapper around garnish's useUserLocation.
 * It hooks up the geolocation result with the Mixpanel profile.
 */
export const useUserLocation = (
  options: Parameters<typeof useUserLocationGarnish>[0],
) => {
  const { setCustomerProperty } = useTelemetry();

  const userLocationResult = useUserLocationGarnish(options);
  const lat = userLocationResult.geolocation?.latitude ?? 0;
  const lng = userLocationResult.geolocation?.longitude ?? 0;
  const hasUserLocation = lat !== 0 && lng !== 0;

  useEffect(() => {
    if (hasUserLocation) {
      setCustomerProperty?.('lat', lat);
      setCustomerProperty?.('lng', lng);
    }
  }, [hasUserLocation, lat, lng, setCustomerProperty]);

  return userLocationResult;
};

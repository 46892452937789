import { useTrackEventEffect } from '@order/Telemetry';

/**
 * Handles success / failure telemetry events for sweetpass home content.
 * @see: https://sweetgreen.atlassian.net/wiki/spaces/CEL/pages/4608294913/Mixpanel+Events+for+Loyalty
 */
export const useSweetpassHomeTelemetry = ({
  hasContentfulData,
  hasSweetpassHomeStats,
  hasSweetpassHomeActionsRail,
}: UseSweetpassHomeTelemetryProps) => {
  useTrackEventEffect({
    name: 'sweetpass_home.view.success',
    skip: !hasContentfulData,
  });

  useTrackEventEffect({
    name: 'sweetpass_home.view.failure',
    skip: hasContentfulData,
    payload: { systemError: 'Invalid Contentful Response' },
  });

  useTrackEventEffect({
    name: 'sweetpass_home.hero_stats.success',
    skip: !hasSweetpassHomeStats,
  });

  useTrackEventEffect({
    name: 'sweetpass_home.hero_stats.failure',
    skip: hasSweetpassHomeStats,
    payload: { systemError: 'Missing Hero Stats' },
  });

  useTrackEventEffect({
    name: 'sweetpass_home.action_card.success',
    skip: !hasSweetpassHomeActionsRail,
  });

  useTrackEventEffect({
    name: 'sweetpass_home.action_card.failure',
    skip: hasSweetpassHomeActionsRail,
    payload: { systemError: 'Missing Action Cards' },
  });
};

// ─── Types ─────────────────────────────────────────────────────────────

type UseSweetpassHomeTelemetryProps = Readonly<{
  hasContentfulData: boolean;
  hasSweetpassHomeStats: boolean;
  hasSweetpassHomeActionsRail: boolean;
}>;

import React, { type ComponentProps, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import { Main } from '@expo/html-elements';
import {
  Container,
  type TextLinkifyTags,
  theme,
  useResponsive,
} from '@sg/garnish';

import {
  CheckoutLayout,
  CheckoutLayoutColumn,
  PageWrapper,
  SweetpassPaymentMethodAddModal,
} from '@order/components';
import { type DurationUnit, type SubscriptionCreditCard } from '@order/graphql';
import type { SweetpassMembershipPlans } from '@order/hooks';
import {
  useTelemetry,
  useTrackEffect,
  useTrackEventEffect,
} from '@order/Telemetry';

import {
  SweetpassCheckoutBlock,
  SweetpassCheckoutCardBlock,
  SweetpassCheckoutHeader,
} from './components';
import { type SweetpassFormData } from './types';

// ────────────────────────────────────────────────────────────────────────────────

export const SweetpassCheckoutForm = (props: SweetpassCheckoutFormProps) => {
  const {
    title,
    termsAndConditions,
    agreementText,
    agreementTextLinks,
    ctaLabel,
    planPrice,
    hasFreeTrial,
    isSwitchingPlans,
    creditCardsData,
    membershipPlansData,
    billingFrequencyUnit,
    ctaHandler,
  } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { track } = useTelemetry();
  const { match } = useResponsive();

  // ─── State ───────────────────────────────────────────────────────────

  const form = useForm<SweetpassFormData>({
    defaultValues: {
      isAddingPaymentMethod: false,
      hasAcceptedTerms: false,
      isLoading: false,
    },
  });

  const { isAddingPaymentMethod, hasAcceptedTerms, isLoading } = form.watch();
  const hasCreditCards = (creditCardsData.creditCards?.length ?? 0) > 0;
  const canCheckout = hasAcceptedTerms && hasCreditCards;

  // ─── Telemetry ───────────────────────────────────────────────────────

  useTrackEffect('sweetpass.checkout.view', {
    isFreeTrial: hasFreeTrial,
    isMonthlySubscription: true,
    isAnnualSubscription: false,
  });

  useTrackEventEffect({
    name: 'sweetpass_account.terms.viewed',
    skip: !hasAcceptedTerms,
  });

  // ─── Callbacks ───────────────────────────────────────────────────────

  const handleCta = useCallback(async () => {
    form.setValue('isLoading', true);
    try {
      await ctaHandler();
    } finally {
      form.setValue('isLoading', false);
    }
  }, [form, ctaHandler]);

  const handleAddCreditCard = useCallback(() => {
    track('sweetpass_account.add_payment_cta.tapped');
    form.setValue('isAddingPaymentMethod', true);
  }, [form, track]);

  const closeAddingPaymentMethodModal = useCallback(() => {
    form.setValue('isAddingPaymentMethod', false);
  }, [form]);

  // ─── Styling ─────────────────────────────────────────────────────────

  const responsiveStyle = match([
    styles.wrapperXS,
    styles.wrapperSM,
    styles.wrapperMD,
  ]);
  const style = [styles.wrapper, responsiveStyle];

  return (
    <PageWrapper withHeaderTitle withoutFooter contentContainerStyle={style}>
      <Main>
        <Container style={styles.container}>
          <SweetpassCheckoutHeader>{title}</SweetpassCheckoutHeader>

          <CheckoutLayout>
            <CheckoutLayoutColumn shouldFitToFill>
              <SweetpassCheckoutBlock
                billingFrequencyUnit={billingFrequencyUnit}
                termsAndConditions={termsAndConditions}
                planPrice={planPrice}
                hasFreeTrial={hasFreeTrial}
                isSwitchingPlans={isSwitchingPlans}
                isLoading={isLoading}
                membershipPlansData={membershipPlansData}
                control={form.control}
              />
            </CheckoutLayoutColumn>

            <CheckoutLayoutColumn>
              <SweetpassCheckoutCardBlock
                selectedCreditCard={creditCardsData.selectedCreditCard}
                creditCards={creditCardsData.creditCards}
                handleSelectCreditCard={
                  creditCardsData.onSelectedCreditCardChange
                }
                handleAddCreditCard={handleAddCreditCard}
                isLoading={isLoading}
                agreementText={agreementText}
                agreementTextLinks={agreementTextLinks}
                canCheckout={canCheckout}
                ctaLabel={ctaLabel}
                handleCta={handleCta}
              />
            </CheckoutLayoutColumn>
          </CheckoutLayout>

          <SweetpassPaymentMethodAddModal
            visible={isAddingPaymentMethod}
            onRequestClose={closeAddingPaymentMethodModal}
          />
        </Container>
      </Main>
    </PageWrapper>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type SweetpassCheckoutFormProps = Readonly<{
  title: string;
  termsAndConditions: string;
  agreementText: string;
  agreementTextLinks: ComponentProps<typeof TextLinkifyTags>['links'];
  ctaLabel: string;
  planPrice: number;
  hasFreeTrial: boolean;
  isSwitchingPlans?: boolean;
  billingFrequencyUnit?: DurationUnit;
  creditCardsData: CreditCardsData;
  membershipPlansData?: MembershipPlansData;
  ctaHandler: (() => Promise<void>) | (() => void);
}>;

type CreditCardsData = {
  creditCards: ReadonlyArray<Partial<SubscriptionCreditCard>>;
  selectedCreditCard?: Partial<SubscriptionCreditCard>;
  onSelectedCreditCardChange: (
    creditCard: Partial<SubscriptionCreditCard>,
  ) => void;
};

type MembershipPlansData = {
  plans: SweetpassMembershipPlans;
  selectedPlanId?: string;
  onSelectedPlanIdChange: (planId: string) => void;
  freeTrialNote?: string;
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flexGrow: 1,
    backgroundColor: theme.colors.GREEN_2,
  },
  wrapperXS: {
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  wrapperSM: {
    paddingTop: theme.spacing['6'],
  },
  wrapperMD: {
    paddingTop: theme.spacing['10'],
  },
  container: {
    maxWidth: 1024,
  },
});

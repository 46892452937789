import { useMemo } from 'react';

import type { ValidateRewardFailureCode } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

export const useValidateRewardException = (
  rewardFailureCode: ValidateRewardFailureCode | undefined,
) => {
  const { t } = useLocalizationContext();

  // @ts-expect-error TS(2740): Type '{ REWARD_NOT_APPLICABLE: string; LOYALTY_PRO... Remove this comment to see the full error message
  const knownExceptions: Record<ValidateRewardFailureCode, string> = useMemo(
    () => ({
      REWARD_NOT_APPLICABLE: t('challenges.rewards.error.not-applicable'),
      LOYALTY_PROGRAM_UNAVAILABLE: t(
        'challenges.rewards.error.loyalty-program-unavailable',
      ),
      REWARD_EXPIRED: t('challenges.rewards.error.expired'),
      LOYALTY_NOT_PROCESSED: t(
        'challenges.rewards.error.loyalty-not-processed',
      ),
    }),
    [t],
  );

  return useMemo(() => {
    if (!rewardFailureCode) return undefined;

    const message = knownExceptions?.[rewardFailureCode];

    return { message };
  }, [rewardFailureCode, knownExceptions]);
};

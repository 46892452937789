import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { Button } from '@sg/garnish';

/**
 * Cta for starting the order.
 */
export const LoyaltyRewardStartOrderCta = (
  props: LoyaltyRewardStartOrderCtaProps,
) => {
  const { isDisabled, onPress } = props;

  const { formatMessage } = useIntl();
  const startOrder = formatMessage(messages.startOrder);

  return (
    <Button
      size="small"
      palette="secondary"
      style={styles.cta}
      accessibilityLabel={startOrder}
      disabled={isDisabled}
      onPress={onPress}
    >
      {startOrder}
    </Button>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  startOrder: {
    defaultMessage: 'Start order',
    description: 'Loyalty | Reward | Start order CTA',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cta: {
    alignSelf: 'flex-start',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyRewardStartOrderCtaProps = {
  isDisabled: boolean;
  onPress: () => void;
};

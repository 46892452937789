import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { ButtonBag, theme, useResponsive } from '@sg/garnish';

import { orderAgainBagButtonMessages as messages } from './OrderAgainBagButton.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainBagButton = (props: OrderAgainBagButtonProps) => {
  const { lineItemsCount, onPress } = props;

  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    match([styles.containerXS, styles.containerSM]),
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles}>
      <ButtonBag bagCount={lineItemsCount} onPress={onPress} size="large">
        <FormattedMessage {...messages.viewBagLabel} />
      </ButtonBag>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  containerXS: {
    marginBottom: theme.spacing['10'],
    marginRight: theme.spacing['4'],
  },
  containerSM: {
    marginBottom: theme.spacing['8'],
    marginRight: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderAgainBagButtonProps = {
  onPress: () => void;
  lineItemsCount: number;
};

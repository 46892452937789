import React, { type ComponentProps, useMemo, useState } from 'react';
import { type RouteProp, useRoute } from '@react-navigation/native';

import {
  LoadingAnimation,
  NotFoundView,
  RefreshErrorState,
  SweetpassCheckoutForm,
} from '@order/components';
import {
  type SweetpassMembershipPlans,
  useSubscribeToSweetpassPlus,
  useSweetpassCheckoutContentfulData,
  useSweetpassPlusPlans,
} from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import type { ModalStackParamList } from '../../../navigation/AppNavigation.props';
import { type SweetpassCheckoutVariationProps } from '../SweetpassCheckoutVariations.types';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * Renders Sweetpass Checkout components for multiple membership plans.
 */
export const SweetpassCheckoutMultiplePlans = (
  props: SweetpassCheckoutVariationProps,
) => {
  const { isFetchingBaseData } = props;

  // ─── Membership Plans ────────────────────────────────────────────────

  const {
    plans,
    isLoadingPlans: isFetchingPlans,
    hasUnknownPlansError,
    defaultPlan,
  } = useSweetpassPlusPlans();
  const { monthly: monthlyPlan, annual: annualPlan } = plans;

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldRenderLoadingState = isFetchingBaseData || isFetchingPlans;

  // ─────────────────────────────────────────────────────────────────────

  if (shouldRenderLoadingState) {
    return <LoadingAnimation />;
  }

  if (hasUnknownPlansError) {
    return <RefreshErrorState />;
  }

  if (
    monthlyPlan === undefined ||
    annualPlan === undefined ||
    defaultPlan === undefined
  ) {
    return <NotFoundView />;
  }

  // ─────────────────────────────────────────────────────────────────────

  return (
    <SweetpassCheckoutMultiplePlansFulfilled
      annualPlan={annualPlan}
      monthlyPlan={monthlyPlan}
      defaultPlan={defaultPlan}
      {...props}
    />
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const SweetpassCheckoutMultiplePlansFulfilled = (
  props: SweetpassCheckoutMultiplePlansFulfilledProps,
) => {
  const {
    currentTierData,
    creditCardsData,
    nextBillingDate,
    annualPlan,
    monthlyPlan,
    defaultPlan,
    isSwitchingPlans,
    onUpgradeSuccess,
  } = props;

  const { t } = useLocalizationContext();

  // ─── Navigation ──────────────────────────────────────────────────────

  const route = useRoute<RouteProp<ModalStackParamList, 'SweetpassCheckout'>>();

  // ─── Default Membership Plan ID ──────────────────────────────────────

  const {
    planId = '',
    campaignId,
    redirectToAfterUpgrade,
  } = route.params ?? {};

  const annualPlanID = annualPlan.id;
  const monthlyPlanID = monthlyPlan.id;

  const isProvidedPlanIdMatchesOneOfThePlans =
    annualPlanID === planId || monthlyPlanID === planId;
  const defaultMembershipPlanId = isProvidedPlanIdMatchesOneOfThePlans
    ? planId
    : defaultPlan.id;

  // ─── State ───────────────────────────────────────────────────────────

  const [selectedMembershipPlanId, setSelectedMembershipPlanId] =
    useState<string>(defaultMembershipPlanId);

  const selectedMembershipPlan =
    selectedMembershipPlanId === monthlyPlanID ? monthlyPlan : annualPlan;
  const selectedMembershipPlanPrice = Number(selectedMembershipPlan.price);

  const requestedPlanRecurrence =
    planId === monthlyPlanID
      ? t('sweetpass.membership-plan.monthly.title')
      : t('sweetpass.membership-plan.annual.title');

  // ─── Free Trial ──────────────────────────────────────────────────────

  const hasFreeTrial = Boolean(selectedMembershipPlan.hasFreeTrial);

  const isCustomerEligibleForFreeTrial = currentTierData.isEligibleForFreeTrial;
  const isFreeTrialEnabled =
    (hasFreeTrial && isCustomerEligibleForFreeTrial) ||
    Boolean(currentTierData.hasTrialMembership);

  // ─── Content ─────────────────────────────────────────────────────────

  const { trialDuration, trialDurationSingular } = currentTierData;
  const { content, isLoading: isFetchingContent } =
    useSweetpassCheckoutContentfulData({
      isFreeTrialEnabled,
      isSwitchingPlans,
      nextBillingDate,
      requestedPlanRecurrence,
      planPrice: selectedMembershipPlanPrice,
      trialDuration,
      trialDurationSingular,
      billingFrequencyUnit: selectedMembershipPlan.billingFrequencyUnit,
      billingFrequencyMembershipName: t(
        'sweetpass.checkout.billing-frequency.membership-plan-name',
        {
          billingFrequency:
            selectedMembershipPlan.billingFrequencyUnit.toLowerCase(),
        },
      ),
    });

  const {
    title,
    termsAndConditions,
    agreementText,
    agreementTextLinks,
    ctaLabel,
    freeTrialNote,
    subscriptionSwitchSuccessBanner,
  } = content;

  // ─────────────────────────────────────────────────────────────────────

  const membershipPlansData = useMemo(() => {
    return {
      plans: { annual: annualPlan, monthly: monthlyPlan },
      selectedPlanId: selectedMembershipPlanId,
      onSelectedPlanIdChange: setSelectedMembershipPlanId,
      freeTrialNote,
    };
  }, [annualPlan, freeTrialNote, monthlyPlan, selectedMembershipPlanId]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleCta = useSubscribeToSweetpassPlus({
    planId: selectedMembershipPlan.id,
    campaignId,
    redirectToAfterUpgrade,
    hasFreeTrial,
    paymentId: creditCardsData.selectedCreditCard?.id,
    subscriptionSwitchSuccessBanner,
    setHasCustomerBeenUpgraded: onUpgradeSuccess,
  });

  // ─── Flags ───────────────────────────────────────────────────────────

  const isInvalidPlan = !selectedMembershipPlan.id;

  // ─────────────────────────────────────────────────────────────────────

  if (isFetchingContent) {
    return <LoadingAnimation />;
  }

  if (isInvalidPlan) {
    return <NotFoundView />;
  }

  return (
    <SweetpassCheckoutForm
      title={title}
      termsAndConditions={termsAndConditions}
      agreementText={agreementText}
      agreementTextLinks={agreementTextLinks}
      ctaLabel={ctaLabel}
      planPrice={selectedMembershipPlanPrice}
      membershipPlansData={membershipPlansData}
      hasFreeTrial={isFreeTrialEnabled}
      isSwitchingPlans={isSwitchingPlans}
      creditCardsData={creditCardsData}
      ctaHandler={handleCta}
    />
  );
};

// ─────────────────────────────────────────────────────────────────────────────

type SweetpassCheckoutMultiplePlansFulfilledProps = ComponentProps<
  typeof SweetpassCheckoutMultiplePlans
> & {
  defaultPlan: NonNullable<SweetpassMembershipPlans['monthly']>;
  annualPlan: NonNullable<SweetpassMembershipPlans['annual']>;
  monthlyPlan: NonNullable<SweetpassMembershipPlans['monthly']>;
  isSwitchingPlans?: boolean;
};

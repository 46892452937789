import type { RefObject } from 'react';
import { useEffect } from 'react';

import { isWebBrowser } from '../../utils';

export const useBrowserEventListener = <
  Target extends EventTarget,
  EventName extends keyof WindowEventMap & keyof DocumentEventMap,
>(
  target: Target | RefObject<unknown> | null,
  eventName: EventName,
  callback: (this: Target, ev: EventMap<Target>[EventName]) => unknown,
  options?: AddEventListenerOptions | boolean, // useCapture boolean
  skip?: boolean,
) => {
  useEffect(() => {
    if (!isWebBrowser() || !target || skip) return;

    const refTarget = (target as RefObject<unknown>)?.current as EventTarget;
    const eventTarget = refTarget ?? target;
    const eventTargetIsValid = Boolean(eventTarget?.addEventListener);

    if (!eventTargetIsValid) return;

    eventTarget.addEventListener(eventName, callback as EventListener, options);

    return () => {
      eventTarget.removeEventListener(
        eventName,
        callback as EventListener,
        options,
      );
    };
  }, [callback, eventName, options, skip, target]);
};

// ────────────────────────────────────────────────────────────────────────────────

export type EventMap<EventName> = EventName extends Window
  ? WindowEventMap
  : EventName extends Document
    ? DocumentEventMap
    : Readonly<Record<string, Event>>;

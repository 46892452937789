import React from 'react';

import type { ProductDetailsScreenProps } from '../../navigation/AppNavigation.props';
import { ProductDetails } from './components';

export const ProductDetailsScreen = ({
  navigation,
  route,
}: ProductDetailsScreenProps) => {
  const {
    restaurantSlug,
    productSlug,
    ingredientIds,
    name: encodedName,
    reorderLineItemId,
  } = route.params ?? {};

  const name = encodedName ? decodeURIComponent(encodedName) : null;
  const decodedIngredientIds = ingredientIds
    ? decodeURIComponent(ingredientIds)
    : null;

  const handleAddLineItemToCart = React.useCallback(() => {
    navigation.replace('Bag', { referrer: 'Menu' });
  }, [navigation]);

  return (
    <ProductDetails
      restaurantSlug={restaurantSlug}
      productSlug={productSlug}
      ingredientIds={decodedIngredientIds?.split(',')}
      onAddLineItemToCart={handleAddLineItemToCart}
      reorderLineItemId={reorderLineItemId}
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      name={name}
    />
  );
};

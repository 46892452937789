/**
 * Returns a computed number that may be used in conjunction with
 * `translate` values to emulate `transform-origin: center` behavior by
 * multiplying active slide index by the difference in width between
 * standard and scaled slides.
 *
 * NOTE: React Native does not support `transform-origin`.
 */
export function calculateCarouselScaleOffset(
  params: CalculateCarouselScaleOffsetParams,
) {
  'worklet';

  const { activeSlideIndex, slideWidth, scale } = params;

  const itemWidthAfterScale = slideWidth * scale;
  const widthDiffAfterScale = slideWidth - itemWidthAfterScale;

  return activeSlideIndex * widthDiffAfterScale;
}

/**
 * Returns the width of a carousel item without any extra space (stage padding and gap).
 */
export function getCarouselSlideWidthWithoutExtraSpace(
  params: GetCarouselItemWidthWithoutExtraSpaceParams,
) {
  'worklet';

  const { slideWidth, stagePadding, gap } = params;

  const bothSidesStagePadding = stagePadding * 2;
  const extraSpace = bothSidesStagePadding + gap;

  return slideWidth - extraSpace;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type CalculateCarouselScaleOffsetParams = Readonly<{
  scale: number;
  activeSlideIndex: number;
  slideWidth: number;
}>;

type GetCarouselItemWidthWithoutExtraSpaceParams = Readonly<{
  stagePadding: number;
  gap: number;
  slideWidth: number;
}>;

import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import type {
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native';
import { Platform } from 'react-native';

import { useDebounceFn, usePressableState } from '../../hooks';
import {
  HapticFeedbackStyle,
  HapticNotificationFeedbackStyle,
  triggerHapticFeedback,
  triggerHapticNotificationFeedback,
} from '../../utils';
import { FLOATING_LABEL_OFFSET } from './TextField.constants';
import { styles } from './TextField.styles';
import type {
  UseConditionalStylesProps,
  UseHoverAndFocusStateProps,
  UseTextFieldHelpersProps,
} from './TextField.types';

/**
 * Returns hover and focus state and corresponding listeners with extended functionality.
 */
export const useHoverAndFocusState = (props: UseHoverAndFocusStateProps) => {
  const { inputRef } = props;
  const { isHovered, isFocused } = usePressableState(inputRef);

  return { isHovered, isFocused };
};

/**
 * Returns conditional styles based on hover/focus state and responsive breakpoint.
 */
export const useConditionalStyles = (props: UseConditionalStylesProps) => {
  const {
    isActive,
    hasFloatingLabel,
    isInvalid,
    isDisabled,
    labelOffset,
    isMultiline,
  } = props;
  const hasLabelOffset = typeof labelOffset === 'number';

  const container = [
    hasFloatingLabel && styles.containerWithLabel,
    hasLabelOffset && {
      paddingTop: FLOATING_LABEL_OFFSET + Number(labelOffset),
    },
  ];

  const wrapper = [
    isActive && styles.wrapperActive,
    isInvalid && styles.wrapperInvalid,
    isDisabled && styles.wrapperDisabled,
    isActive && isMultiline && styles.multilineWrapperActive,
    isInvalid && isMultiline && styles.multilineWrapperInvalid,
    isDisabled && isMultiline && styles.multilineWrapperDisabled,
  ];

  return { container, wrapper };
};

/**
 * Returns event handlers to extend text input functionality.
 */
export const useTextFieldHelpers = (props: UseTextFieldHelpersProps) => {
  const {
    inputRef,
    onChangeTextGuard,
    value,
    defaultValue,
    onChangeText,
    onClear,
    onSubmit,
    resetOnSubmit,
  } = props;

  // keep local value state to handle both controlled and uncontrolled input cases
  const [formattedValue, setFormattedValue] = useState<string>(
    value ?? defaultValue ?? '',
  );
  const [hasAutofill, setHasAutofill] = useState(false);

  // ─── HELPERS ────────────────────────────────────────────────────────

  const handleTextChange = useCallback(
    (text: string) => {
      const isValidText = onChangeTextGuard?.(text) ?? true;

      setHasAutofill(false);

      if (!isValidText) return;

      setFormattedValue(text);
      onChangeText?.(text);
    },
    [onChangeText, onChangeTextGuard],
  );

  const clearText = useCallback(
    async (withHaptic = true) => {
      handleTextChange('');

      if (!inputRef.current) return;

      inputRef.current.clear();
      inputRef.current.focus();

      onClear?.();

      if (withHaptic) {
        await triggerHapticFeedback(HapticFeedbackStyle.Light);
      }
    },
    [handleTextChange, inputRef, onClear],
  );

  const handleSubmit = useCallback(async () => {
    if (!onSubmit) return;

    onSubmit(formattedValue);

    await triggerHapticNotificationFeedback(
      HapticNotificationFeedbackStyle.Success,
    );

    if (resetOnSubmit) {
      await clearText(false);
    }
  }, [clearText, formattedValue, onSubmit, resetOnSubmit]);

  // debounce the submit event handler, to avoid multiple calls
  const handleSubmitDebounced = useDebounceFn(handleSubmit, 50);

  // On Android Web, pressing the submit keyboard button only
  // triggers an enter keydown, but not the actual submit callback.
  const handleEnterKey = useCallback(
    (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      if (event.nativeEvent.key === 'Enter') void handleSubmitDebounced();
    },
    [handleSubmitDebounced],
  );

  // ─── EFFECTS ────────────────────────────────────────────────────────

  // detect webkit browser's autofill state to set floating label position
  useLayoutEffect(() => {
    if (Platform.OS !== 'web') return;

    const inputNode = inputRef?.current as unknown as HTMLInputElement;

    const timeout = setTimeout(() => {
      const hasAutoFill = inputNode?.matches(':-webkit-autofill');

      if (hasAutoFill) setHasAutofill(true);
    }, 400);

    return () => {
      clearTimeout(timeout);
    };
  }, [inputRef]);

  // sync external value change
  useEffect(() => {
    if (value === undefined) return;
    setFormattedValue(value ?? '');
  }, [value]);

  // ────────────────────────────────────────────────────────────────────

  const hasContent = hasAutofill || formattedValue.length > 0;

  return {
    formattedValue,
    hasContent,
    clearText,
    handleTextChange,
    handleSubmit: handleSubmitDebounced,
    handleEnterKey,
  };
};

export const usePasswordHelpers = () => {
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  const toggleShouldShowPassword = useCallback(() => {
    setShouldShowPassword((currentValue) => !currentValue);
  }, []);

  return { shouldShowPassword, toggleShouldShowPassword };
};

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';
import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const HeroCardCta = (props: HeroCardCtaProps) => {
  const { label } = props;

  const { match } = useResponsive();

  const ctaResponsiveStyles = match([
    styles.ctaXS,
    styles.ctaSM,
    styles.ctaSM,
    styles.ctaLG,
  ]);
  const ctaStyles = [styles.cta, ctaResponsiveStyles];

  return (
    <View style={ctaStyles}>
      <BodyText
        size={3}
        style={styles.ctaLabel}
        numberOfLines={1}
        maxFontSizeMultiplier={1.1}
      >
        {label}
      </BodyText>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const HERO_CTA_BUTTON_SIZE_XS = 56;
const HERO_CTA_BUTTON_SIZE_SM = 64;
const HERO_CTA_BUTTON_SIZE_LG = 80;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cta: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.OATMEAL,
    borderColor: theme.colors.DARK_KALE,
    borderWidth: 1,
  },
  ctaXS: {
    width: HERO_CTA_BUTTON_SIZE_XS,
    height: HERO_CTA_BUTTON_SIZE_XS,
    borderRadius: HERO_CTA_BUTTON_SIZE_XS,
  },
  ctaSM: {
    width: HERO_CTA_BUTTON_SIZE_SM,
    height: HERO_CTA_BUTTON_SIZE_SM,
    borderRadius: HERO_CTA_BUTTON_SIZE_SM,
  },
  ctaLG: {
    width: HERO_CTA_BUTTON_SIZE_LG,
    height: HERO_CTA_BUTTON_SIZE_LG,
    borderRadius: HERO_CTA_BUTTON_SIZE_LG,
  },
  ctaLabel: {
    color: theme.colors.DARK_KALE,
    padding: theme.spacing['1'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type HeroCardCtaProps = Readonly<{
  label: string;
}>;

import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useClient } from 'urql';
import { useNoticeBannersStackContext } from '@sg/garnish';
import { type DeliveryOrderDetail } from '@sg/graphql-schema';

import {
  adjustWantedTime,
  type OrderingContext,
  type SubmitOrderParams,
  sumLedgerTotal,
} from '@order/features/ordering';
import { type PartialLineItem } from '@order/graphql';
import { useTelemetry } from '@order/Telemetry';

import {
  SubmitBagOrderDocument,
  type SubmitBagOrderMutation,
  type SubmitBagOrderMutationVariables,
} from '../../GraphQL/BagSubmitOrder.generated';
import { getCheckoutTelemetryData } from '../../utils';
import { type useDefaultPaymentMethod } from '../useDefaultPaymentMethod';
import { usePostOrder } from '../usePostOrder';

// ────────────────────────────────────────────────────────────────────────────

/**
 * A hook for submitting an order.
 */
export const useSubmitOrder = (
  setDefaultPaymentMethod: ReturnType<
    typeof useDefaultPaymentMethod
  >['setDefaultPaymentMethod'],
) => {
  const client = useClient();
  const { track } = useTelemetry();
  const { formatMessage, formatNumber } = useIntl();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  const handlePostOrder = usePostOrder();

  // ─── Mutations ────────────────────────────────────────────────────────────

  const submitOrderMutation = useCallback(
    async (input: SubmitBagOrderMutationVariables['input']) => {
      const mutation = client.mutation<
        SubmitBagOrderMutation,
        SubmitBagOrderMutationVariables
      >;

      const response = await mutation(
        SubmitBagOrderDocument,
        { input },
        { requestPolicy: 'network-only' },
      ).toPromise();

      return response.data?.submitOrderUsingPaymentMethod;
    },
    [client.mutation],
  );

  const submitOrder = useCallback(
    async (
      context: OrderingContext<PartialLineItem, DeliveryOrderDetail>,
      { params }: SubmitOrderParams,
    ) => {
      const {
        cart,
        paymentMethodId = '',
        dropoffLocation: dropoffLocationId,
        wantedTime,
        selectedTip,
        availableCredit,
        shouldUseCredit,
        shouldIncludeUtensils,
      } = context;

      if (!wantedTime) {
        addNoticeBanner({
          text: formatMessage(messages.missingWantedTime),
          palette: 'caution',
        });

        return {
          success: false,
          error: 'MissingWantedTime',
        };
      }

      const selectedPaymentMethodId =
        params?.paymentMethodId ?? paymentMethodId;

      const {
        orderId = '',
        restaurantId = '',
        orderChannel = 'pickup',
        locationName = 'sweetgreen',
        deliveryOrderDetail,
        deliveryMinSubtotal,
        canTrackOrderStatus,
        ledger,
        lineItems,
      } = cart;

      const { total, subtotal, discountTotal } = sumLedgerTotal(
        ledger,
        selectedTip,
        availableCredit,
        shouldUseCredit,
      );

      const result = await submitOrderMutation({
        dropoffLocationId,
        orderId,
        paymentMethodId: selectedPaymentMethodId,
        pickupTip: selectedTip,
        wantedTime: wantedTime.time.slice(0, 19).replace(' ', 'T'),
        useCredit: shouldUseCredit,
        includeUtensils: shouldIncludeUtensils,
        deliveryOrderDetail:
          orderChannel === 'delivery' && deliveryOrderDetail.address?.id
            ? {
                addressId: deliveryOrderDetail.address.id,
                deliveryFee: deliveryOrderDetail.deliveryFee,
                vendor: deliveryOrderDetail.vendor,
                vendorRestaurantId: deliveryOrderDetail.vendorRestaurantId,
                tip: selectedTip,
              }
            : undefined,
      });

      const typename = result?.__typename;

      if (typename === 'SubmitOrderUsingPaymentMethodSuccess') {
        track('checkout.success', getCheckoutTelemetryData(context));

        void setDefaultPaymentMethod(context, {
          type: 'SET_DEFAULT_PAYMENT_METHOD',
          params: { paymentMethodId },
        });

        handlePostOrder({
          restaurantId,
          orderId,
          orderChannel,
          canTrackOrderStatus,
          locationName,
          wantedTime: adjustWantedTime(wantedTime) ?? '',
          subtotal,
          discountTotal,
          total,
          lineItemsCount: lineItems.length,
        });

        return {
          success: true,
          isTimeslotUnavailable: false,
          error: undefined,
        };
      }

      track('checkout.failure', { systemError: typename });

      if (typename === 'TimeslotUnavailable') {
        addNoticeBanner({
          text: formatMessage(messages.timeslotUnavailable),
          palette: 'neutral',
        });

        return {
          success: false,
          isTimeslotUnavailable: true,
          error: 'TimeslotUnavailable',
        };
      }

      if (typename === 'MinimumDeliverySubtotalNotMet') {
        const minimum = formatNumber((deliveryMinSubtotal ?? 0) / 100, {
          style: 'currency',
          currency: 'USD',
        });

        addNoticeBanner({
          palette: 'caution',
          text: formatMessage(messages.minimumDeliverySubtotalNotMet, {
            minimum,
          }),
        });

        return {
          success: false,
          isTimeslotUnavailable: false,
          error: 'MinimumDeliverySubtotalNotMet',
        };
      }

      addNoticeBanner({
        text: formatMessage(messages.orderSubmissionError, { typename }),
        palette: 'caution',
      });

      return {
        success: false,
        isTimeslotUnavailable: false,
        error: undefined,
      };
    },
    [
      track,
      submitOrderMutation,
      addNoticeBanner,
      formatMessage,
      setDefaultPaymentMethod,
      handlePostOrder,
      formatNumber,
    ],
  );

  // ──────────────────────────────────────────────────────────────────────────

  return { submitOrder };
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  missingWantedTime: {
    defaultMessage: 'Please select a time for this order',
    description: 'Bag | Order submission error | Missing wanted time',
  },
  orderSubmissionError: {
    defaultMessage: `{typename, select,
    IncorrectPaymentMethod {The card information provided is invalid.
If the issue persists, please contact your card issuer for further assistance.}
    DeclinedPaymentMethod {We're sorry but this transaction has been declined.
Please contact your card issuer or choose an alternative payment method.}
    InvalidCustomerPhone {Your phone number is invalid. Please use a 10 digit US phone number, without country code.}
    InvalidTip {Selected tip cannot be applied. Please choose another option.}
    DeliveryAlreadyInFlight {Sorry, you already have an active delivery order. Please try Pickup, or check the status of your active delivery order.}
    DeliverySpecifyAddress {More than one result was returned for this address. Please make this address as specific as possible.}
    other {Something went wrong, please try again later.}
  }`,
    description: 'Bag | Order submission error',
  },
  timeslotUnavailable: {
    defaultMessage:
      'The selected timeslot window is no longer available. Your order has been updated to the next available time.',
    description: 'Bag | Order submission error | Timeslot unavailable',
  },
  minimumDeliverySubtotalNotMet: {
    defaultMessage: `You haven't met the {minimum} delivery minimum yet.`,
    description:
      'Bag | Order submission error | Minimum delivery subtotal not met',
  },
});

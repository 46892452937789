import React, { useCallback } from 'react';

import { PaymentMethodListItem } from '@order/components';

import type { SweetpassPaymentMethodsSectionProps } from '../hooks';
import { SweetpassMembershipSection } from './SweetpassMembershipScreenSection';

export const SweetpassPaymentMethodsSection = (
  props: SweetpassPaymentMethodsSectionProps,
) => {
  const { testID, title, addItem, items } = props;

  const handlePressAdd = useCallback(() => {
    addItem.handler();
  }, [addItem]);

  return (
    <SweetpassMembershipSection testID={testID} content="list" title={title}>
      <SweetpassMembershipSection.ActionList>
        {items.map((item) => (
          <PaymentMethodListItem
            key={item.id}
            testID={testID?.concat(
              `.edit-payment.${item.title?.split(' ').join('-') ?? item.id}`,
            )}
            iconName={item.icon}
            title={item.title}
            subtitle={item.subtitle}
            tag={item.tag}
            notice={item.notice}
            accessibilityLabel={item.accessibilityLabel}
            accessibilityHint={item.accessibilityHint}
            onPress={() => {
              item.handler();
            }}
          />
        ))}
        <PaymentMethodListItem
          testID={testID?.concat('.edit-payment.new')}
          iconName={addItem.icon}
          title={addItem.title}
          accessibilityLabel={addItem.accessibilityLabel}
          accessibilityHint={addItem.accessibilityHint}
          onPress={handlePressAdd}
        />
      </SweetpassMembershipSection.ActionList>
    </SweetpassMembershipSection>
  );
};

/* cSpell:ignore libphonenumber */

import { format } from 'date-fns';
import type { CountryCode } from 'libphonenumber-js';
import parsePhoneNumber from 'libphonenumber-js';

/**
 * Returns formatted phone numbers in national and international formats.
 */
export function formatPhoneNumber(props: FormatPhoneNumberProps) {
  const {
    phoneNumber,
    countryCode = 'US',
    shouldMinify: shouldMinifyPhoneNumber,
  } = props;

  const phoneNumberAsString = String(phoneNumber);
  const parsedPhoneNumber = parsePhoneNumber(phoneNumberAsString, countryCode);

  // ────────────────────────────────────────────────────────────────────────────────

  if (!parsedPhoneNumber) {
    return {
      national: phoneNumberAsString,
      international: phoneNumberAsString,
    };
  }

  // ────────────────────────────────────────────────────────────────────────────────

  const national = parsedPhoneNumber.formatNational();
  const international = parsedPhoneNumber.formatInternational();

  if (shouldMinifyPhoneNumber) {
    return {
      national: minifyPhoneNumber(national),
      international: minifyPhoneNumber(international),
    };
  }

  return { national, international };
}

/**
 * Returns a formatted phone number without brackets and dashes in place of whitespace.
 */
export function minifyPhoneNumber(phoneNumber: string) {
  return phoneNumber.replaceAll(/\s/g, '-').replaceAll(/[()]/g, '');
}

export function removeExtraWhitespace(text: string) {
  return text.replaceAll(/\s\s+/g, ' ').trim();
}

export function replaceLineBreaksWithSpaces(text: string) {
  return text.replaceAll(/\n|\r/g, ' ');
}

export function truncateText(text: string, maxLength: number) {
  return text.length > maxLength
    ? `${text.slice(0, maxLength).trimEnd()}...`
    : text;
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function clamp(value: number, min: number, max: number) {
  if (value < min) return min;

  if (value > max) return max;

  return value;
}

export function getFormattedExpirationDate(
  expirationDate: string | null | undefined,
  prefix = '',
) {
  if (!expirationDate) return '';

  const date = new Date(expirationDate);
  const formatted = formatDate(date);

  if (!formatted) return '';

  return `${prefix} ${formatted}`.trimStart();
}

export const formatDate = (
  date: Date | null,
  formatOption = 'MM/dd',
): string => {
  const isValidDate = date && checkIfDateIsValid(date);
  const formattedDate = isValidDate ? format(date, formatOption) : '';

  return formattedDate;
};

export function checkIfDateIsValid(date: Date) {
  return !Number.isNaN(date.getTime());
}

export function slugify(str: string) {
  // we use `replace` instead of `replaceAll` to support native platforms without additional polyfill
  return str.trim().toLowerCase().replaceAll(/\s/g, '-');
}

//
// ─── TYPES ──────────────────────────────────────────────────────────────────────
//

type FormatPhoneNumberProps = Readonly<{
  phoneNumber: string | number;
  countryCode?: CountryCode;
  shouldMinify?: boolean;
}>;

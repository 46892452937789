import type { ComponentProps } from 'react';
import React, { useRef } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import {
  BodyText,
  LabelText,
  noop,
  theme,
  truncateText,
  usePressableState,
  useResponsive,
  webOnlyStyles,
} from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';
import type { useOrders } from '@order/Order';
import { useLineItemsNames } from '@order/shared/hooks';

import { useReorderFromOrder } from '../../../screens/ReorderingScreen';
import {
  REORDER_RAIL_CARD_SIZE_LG,
  REORDER_RAIL_CARD_SIZE_XS,
} from './constants';
import { ReorderRailCardImage } from './ReorderRailCardImage';
import {
  CustomOrderShape,
  CustomOrderWithCustomNameShape,
  StandardOrderShape,
} from './ReorderRailCardShapes';

export const ReorderRailCard = (props: OrderCardProps) => {
  const { order, orderType = 'custom' } = props;
  const productsCount = countProducts(props);

  const ref = useRef(null);
  const { isInteracting } = usePressableState(ref);
  const { match } = useResponsive();
  const { t, formatPrice } = useLocalizationContext();

  // ─── Responsive Styles ───────────────────────────────────────────────

  const responsiveCardTextSize: ComponentProps<typeof BodyText>['size'] = match(
    [4, 3],
  );
  const responsiveCardWrapper = match([styles.wrapperXs, styles.wrapperLg]);
  const responsiveImageWrapper = match([
    styles.imageWrapperXs,
    styles.imageWrapperLg,
  ]);

  // ────────────────────────────────────────────────────────────────────

  const orderLineItemsNames = useLineItemsNames(order.lineItems);
  const truncatedOrderLineItemsNames = truncateText(orderLineItemsNames, 40);
  const accessibilityLabel = t('home.reorder-rail.a11y', {
    order: truncatedOrderLineItemsNames,
  });

  const isShowPriceOnReorderRailEnabled = useFeatureFlag(
    'CELS-2365-show-price-on-reorder-rail',
  );

  // NOTE: Because we use the first product image as an order image, we need
  //       to use the corresponding product name as the image a11y label.
  const imgA11yLabel = order.lineItems[0]?.product?.name;

  const onReorder = useReorderFromOrder(order);

  return (
    <Pressable
      ref={ref}
      accessibilityRole="button"
      accessibilityLabel={accessibilityLabel}
      delayLongPress={100}
      style={({ pressed }) => [
        wrapperWebStyles,
        responsiveCardWrapper,
        { opacity: pressed ? 0.7 : 1 },
      ]}
      onLongPress={noop} // prevent unwanted {press} actions while scrolling
      onPress={onReorder}
    >
      <View style={[styles.imageWrapper, responsiveImageWrapper]}>
        <View style={styles.imageDecalWrapper}>
          {orderType === 'standard' && (
            <StandardOrderShape isInteracting={isInteracting} />
          )}
          {orderType === 'custom' && (
            <CustomOrderShape isInteracting={isInteracting} />
          )}
          {orderType === 'custom-with-name' && (
            <CustomOrderWithCustomNameShape isInteracting={isInteracting} />
          )}
        </View>

        {productsCount > 1 ? (
          <View style={styles.productsCount}>
            <LabelText size={2}>{productsCount}</LabelText>
          </View>
        ) : null}

        <ReorderRailCardImage
          baseUrl={order.asset.url}
          accessibilityLabel={imgA11yLabel}
        />
      </View>

      <BodyText
        size={responsiveCardTextSize}
        style={styles.description}
        numberOfLines={2}
        accessibilityLabel={orderLineItemsNames}
      >
        {truncatedOrderLineItemsNames}
      </BodyText>

      {isShowPriceOnReorderRailEnabled && order.total ? (
        <BodyText size={responsiveCardTextSize}>
          {formatPrice(order.total, 'USD')}
        </BodyText>
      ) : null}
    </Pressable>
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────────

type OrderCardProps = Readonly<{
  order: ReturnType<typeof useOrders>['orders'][number];
  orderType?: 'standard' | 'custom' | 'custom-with-name';
}>;

// ─── Utils ───────────────────────────────────────────────────────────────────

function countProducts(props: OrderCardProps) {
  const { order, orderType } = props;

  return orderType === 'standard'
    ? order.lineItems.reduce(
        (total, lineItem) => Number(total) + Number(lineItem.quantity),
        0,
      )
    : 0;
}

// ─── Styles ──────────────────────────────────────────────────────────────────

const wrapperWebStyles = webOnlyStyles({ cursor: 'pointer' });
const styles = StyleSheet.create({
  wrapperXs: {
    width: REORDER_RAIL_CARD_SIZE_XS,
  },

  wrapperLg: {
    width: REORDER_RAIL_CARD_SIZE_LG,
  },

  imageWrapper: {
    position: 'relative',
    width: '100%',
    marginBottom: theme.spacing['4'],
  },

  imageWrapperXs: {
    height: REORDER_RAIL_CARD_SIZE_XS,
  },

  imageWrapperLg: {
    height: REORDER_RAIL_CARD_SIZE_LG,
  },

  imageDecalWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    ...StyleSheet.absoluteFillObject,
  },

  productsCount: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: theme.spacing['2'],
    left: theme.spacing['2'],
    width: 24,
    height: 24,
    borderRadius: 48,
    borderWidth: 1,
    color: theme.colors.TEXT_COLOR,
    borderColor: theme.colors.TEXT_COLOR,
  },

  description: {
    textTransform: 'capitalize',
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, Button, TextField, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type { CreditSectionProps } from '../CreditSectionProps';

// ─────────────────────────────────────────────────────────────────────────────

export const CreditOverviewPromoCodeForm = (
  props: CreditOverviewPromoCodeFormProps,
) => {
  const {
    buttonPlacement = 'right',
    onPressApplyPromoCode,
    hasPromoCodeError,
    isPromoCodeApplied,
    promoCode,
    isSubmittingPromoCode,
    setPromoCode,
    handleShowScanner,
  } = props;

  const onPress = React.useCallback(() => {
    onPressApplyPromoCode(promoCode);
  }, [promoCode, onPressApplyPromoCode]);

  const shouldDisableApplyBtn = promoCode.trim() === '';
  const { t } = useLocalizationContext();
  const isBottomButton = buttonPlacement === 'bottom';
  const cameraIcon = isBottomButton ? 'IconCamera' : undefined;
  const inputPadding = isBottomButton ? undefined : styles.inputPadding;

  const buttonA11yLabel = t('account.credit.apply-promo-code-btn-a11y-label');

  const buttonProps: React.ComponentProps<typeof Button> = {
    testID: 'account.promo-code-button',
    palette: 'primary',
    accessibilityLabel: buttonA11yLabel,
    accessibilityHint: buttonA11yLabel,
    accessibilityRole: 'button',
    accessibilityState: { disabled: shouldDisableApplyBtn },
    disabled: shouldDisableApplyBtn,
    isLoading: isSubmittingPromoCode,
    onPress,
  };

  const promoCodeAlreadyAppliedNoticeText = t(
    'account.notice.credit-promo-code-already-applied',
  );
  const promoCodeAlreadyAppliedNotice = isPromoCodeApplied
    ? promoCodeAlreadyAppliedNoticeText
    : undefined;

  const invalidPromoCodeNoticeText = t(
    'account.notice.credit-promo-code-invalid',
  );
  const invalidPromoCodeNotice = hasPromoCodeError
    ? invalidPromoCodeNoticeText
    : undefined;

  const placeholder = t('account.credit.field-promo-code-placeholder');
  const helperText = t('account.credit-promo.helper-text-promo-code');

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View>
      <View style={styles.promoCodeWrapper}>
        <View style={[styles.promoCodeInputWrapper, inputPadding]}>
          <TextField
            testID="account.promo-code-field"
            placeholder={placeholder}
            accessibilityLabel={placeholder}
            value={promoCode}
            fieldOptionIcon={cameraIcon}
            fieldOptionOnPress={handleShowScanner}
            onChangeText={setPromoCode}
            onSubmit={onPress}
            notice={promoCodeAlreadyAppliedNotice ?? invalidPromoCodeNotice}
            invalid={hasPromoCodeError}
            noticePalette="caution"
          />
        </View>

        {!isBottomButton && (
          <Button width={125} {...buttonProps}>
            {t('general.apply')}
          </Button>
        )}
      </View>

      <BodyText size={4}>{helperText}</BodyText>

      {isBottomButton ? (
        <Button size="large" style={styles.buttonMargin} {...buttonProps}>
          {t('general.apply')}
        </Button>
      ) : null}
    </View>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

export type CreditOverviewPromoCodeFormProps = Readonly<{
  buttonPlacement?: 'right' | 'bottom';
  handleShowScanner?: () => void;
}> &
  Pick<
    CreditSectionProps,
    | 'onPressApplyPromoCode'
    | 'hasPromoCodeError'
    | 'isPromoCodeApplied'
    | 'promoCode'
    | 'setPromoCode'
    | 'isSubmittingPromoCode'
  >;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  promoCodeWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.spacing['4'],
  },
  promoCodeInputWrapper: {
    flexGrow: 1,
    marginTop: theme.spacing['6'],
  },
  inputPadding: {
    paddingRight: theme.spacing['8'],
  },
  buttonMargin: {
    marginTop: theme.spacing['10'],
  },
});

import type { ContextFrom } from 'xstate';
import { createModel } from 'xstate/lib/model';

// ─── CONTEXT ────────────────────────────────────────────────────────────────────

const initialContext: CartContext = {
  pendingMutations: {
    'add-line-item': 0,
    'edit-line-item': 0,
    'remove-line-item': 0,
  },
  // stores view counts in a hashmap keyed by cartId
  cartViewCounts: {},
};

// ─── MODEL ──────────────────────────────────────────────────────────────────────

export const cartModel = createModel(initialContext, {
  events: {
    REGISTER_PENDING_MUTATION: (mutationName: CartMutation) => ({
      mutationName,
    }),
    UNREGISTER_PENDING_MUTATION: (mutationName: CartMutation) => ({
      mutationName,
    }),
    ENABLE_CART_CONTROLS: () => ({}),
    DISABLE_CART_CONTROLS: () => ({}),
    REGISTER_CART_VIEW: (cartId: string) => ({ cartId }),
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type CartContext = Readonly<{
  pendingMutations: Record<CartMutation, number>;
  cartViewCounts: Record<string, number>;
}>;

export type CartMutation =
  | 'add-line-item'
  | 'edit-line-item'
  | 'remove-line-item';

export type CartModelContext = ContextFrom<typeof cartModel>;

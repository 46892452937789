import type { Client } from 'urql';

import type {
  Location,
  LocationGroups,
  PositionCoordinates,
} from '../../LocationSearch.types';
import { safelyGetCurrentPosition } from '../current-position';
import { searchLocationsByCurrentPosition } from '../locations';
import { getRecentLocations } from '../recent-locations';

// ─────────────────────────────────────────────────────────────────────────────

export async function getRecentAndNearbyLocations(
  params: GetInitialLocationsParams,
): Promise<RecentAndNearbyLocations> {
  const {
    client,
    shouldUseDeliveryDisclosureFields,
    shouldUseOutpostDisclosureFields,
  } = params;

  // ─── Locations ───────────────────────────────────────────────────────

  const nearbyLocationsPromise = getNearbyLocations({
    client,
    shouldUseDeliveryDisclosureFields,
    shouldUseOutpostDisclosureFields,
  });
  const recentLocationsPromise = getRecentLocations({
    client,
    shouldUseDeliveryDisclosureFields,
    shouldUseOutpostDisclosureFields,
  });

  const [nearbyLocationsData, recentLocationsData] = await Promise.all([
    nearbyLocationsPromise,
    recentLocationsPromise,
  ]);

  return {
    currentPositionCoordinates: nearbyLocationsData.currentPositionCoordinates,
    nearbyLocations: nearbyLocationsData.nearbyLocations,
    recentLocations: recentLocationsData.recentLocations,
  };
}

export async function getNearbyLocations(
  params: GetInitialLocationsParams,
): Promise<NearbyLocations & CurrentPositionCoordinates> {
  const { client } = params;

  // ─── Current Position ────────────────────────────────────────────────

  const currentPosition = await safelyGetCurrentPosition();
  const currentPositionCoordinates = currentPosition?.coords;

  // ─── Locations ───────────────────────────────────────────────────────

  const { nearbyLocations } = await searchLocationsByCurrentPosition({
    client,
    currentPositionCoordinates,
  });

  return {
    nearbyLocations,
    currentPositionCoordinates,
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type GetInitialLocationsParams = {
  client: Client;
  shouldUseDeliveryDisclosureFields: boolean;
  shouldUseOutpostDisclosureFields: boolean;
};

type RecentAndNearbyLocations = RecentLocations &
  NearbyLocations &
  CurrentPositionCoordinates;

type RecentLocations = { recentLocations: Location[] };

type NearbyLocations = { nearbyLocations: LocationGroups };

type CurrentPositionCoordinates = {
  currentPositionCoordinates: PositionCoordinates | undefined;
};

import { useCallback, useState } from 'react';

import { isRunningEndToEnd } from '@order/utils';

export const useFutureDateModal = () => {
  const [shouldShowFutureDateModal, setShouldShowFutureDateModal] =
    useState(false);

  const handleDismissFutureModal = useCallback(() => {
    setShouldShowFutureDateModal(false);
  }, [setShouldShowFutureDateModal]);

  // Disable future date modal during e2e tests.
  if (isRunningEndToEnd()) {
    return {
      shouldShowFutureDateModal: false,
      setShouldShowFutureDateModal,
      handleDismissFutureModal,
    };
  }

  return {
    shouldShowFutureDateModal,
    setShouldShowFutureDateModal,
    handleDismissFutureModal,
  };
};

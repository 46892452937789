import React, { useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { type TextInput } from 'react-native';

import {
  DeliveryMethods,
  type GiftCardCheckoutFormReturn,
  type GiftCardCheckoutFormState,
} from '../../../../form';
import { GiftCardCheckoutFormGroup } from '../../components';
import { GiftCardCheckoutDeliveryMethodPicker } from './components';
import { GiftCardCheckoutDeliveryMethodEmail } from './components/GiftCardCheckoutDeliveryMethodEmail';
import { GiftCardCheckoutDeliveryMethodMessageCard } from './components/GiftCardCheckoutDeliveryMethodMessageCard';
import { giftCardCheckoutDeliveryMethodMessages as messages } from './GiftCardCheckoutDeliveryMethod.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutDeliveryMethod = (
  props: GiftCardCheckoutDeliveryMethodProps,
) => {
  const { form } = props;

  const deliveryDateOption = form.watch('deliveryDateOption');
  const deliveryMethod = form.watch('deliveryMethod');

  // ─── Refs ────────────────────────────────────────────────────────────

  const recipientEmailFieldRef = useRef<TextInput>(null);

  // ─── Flags ───────────────────────────────────────────────────────────

  // NOTE: When a user selects "Schedule" as a delivery method, the "Message"
  //       option is not available.
  const shouldHideMessageOption = deliveryDateOption === 'SCHEDULE';
  const shouldRenderMessageCard =
    !shouldHideMessageOption && deliveryMethod === 'MESSAGE';

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onDeliveryMethodChange = useCallback(async (option: DeliveryOption) => {
    if (option !== DELIVERY_OPTION_EMAIL) return;

    // Focus recipient email after selecting "Email" delivery option.
    //
    // NOTE: Timeout is needed, since the filed will be rendered on
    //       the next tick.
    setTimeout(() => {
      recipientEmailFieldRef.current?.focus();
    });
  }, []);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <GiftCardCheckoutFormGroup.Container>
      <GiftCardCheckoutFormGroup.Title>
        <FormattedMessage
          {...(shouldHideMessageOption
            ? messages.sectionTitleEmailOnly
            : messages.sectionTitle)}
        />
      </GiftCardCheckoutFormGroup.Title>

      {shouldHideMessageOption ? null : (
        <GiftCardCheckoutDeliveryMethodPicker
          form={form}
          onDeliveryMethodChange={onDeliveryMethodChange}
        />
      )}

      {shouldRenderMessageCard ? (
        <GiftCardCheckoutDeliveryMethodMessageCard />
      ) : null}

      {deliveryMethod === DELIVERY_OPTION_EMAIL ? (
        <GiftCardCheckoutDeliveryMethodEmail
          form={form}
          ref={recipientEmailFieldRef}
        />
      ) : null}
    </GiftCardCheckoutFormGroup.Container>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const DELIVERY_OPTION_EMAIL = DeliveryMethods.Email;

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutDeliveryMethodProps = {
  sectionTitle?: string;
  sectionTitleEmailOnly?: string;
  form: GiftCardCheckoutFormReturn;
};

type DeliveryOption = GiftCardCheckoutFormState['deliveryMethod'];

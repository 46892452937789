import React, { useCallback } from 'react';
import { BagIcon, useResponsive } from '@sg/garnish';

import { useCart } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import { useOpenBag } from '../../../Bag/Bag.hooks';
import { FloatingButton } from '../FloatingButton';

// ─────────────────────────────────────────────────────────────────────────────

export const BagFloatingButton = (props: BagFloatingButtonProps) => {
  const { onPress } = props;

  // ─────────────────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();
  const { lineItemsCount, isCartEmpty } = useCart();
  const openBag = useOpenBag({ shouldUpdateCart: true });

  // ─────────────────────────────────────────────────────────────────────

  const handleOpenBag = useCallback(() => {
    onPress?.();
    openBag();
  }, [onPress, openBag]);

  const BagIconWithCount = useCallback(
    () => <BagIcon bagCount={lineItemsCount} />,
    [lineItemsCount],
  );

  // ─────────────────────────────────────────────────────────────────────

  if (isCartEmpty || !currentBreakpoint.isXS) return null;

  return (
    <FloatingButton
      testID="bag.access"
      size="large"
      onPress={handleOpenBag}
      rightElement={BagIconWithCount}
    >
      {t('floating-buttons.bag.title')}
    </FloatingButton>
  );
};

type BagFloatingButtonProps = {
  onPress?: () => void;
};

import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import {
  Container,
  theme,
  useContainerSpacing,
  useResponsive,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainRailContainer = (props: PropsWithChildren) => {
  const { children } = props;

  const { match } = useResponsive();
  const containerSpacing = useContainerSpacing();

  // ─── Styles ──────────────────────────────────────────────────────────

  const innerContainerRightSpacing = match([0, 0, 0, containerSpacing]);

  const innerContainerDynamicStyles = useStyle(
    () => ({
      paddingRight: innerContainerRightSpacing,
      paddingLeft: containerSpacing,

      // NOTE: Override default styles
      paddingHorizontal: undefined,
    }),
    [containerSpacing, innerContainerRightSpacing],
  );
  const innerContainerStyles = [
    styles.innerContainer,
    innerContainerDynamicStyles,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.outerContainer}>
      <Container style={innerContainerStyles}>{children}</Container>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    alignItems: 'center',
    paddingVertical: theme.spacing['16'],
    backgroundColor: theme.colors.CUCUMBER,
    borderTopWidth: 1,
    borderTopColor: theme.colors.DARK_KALE,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.DARK_KALE,
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing['10'],
  },
});

/* istanbul ignore file */

import type { ComponentProps } from 'react';
import React, { memo, useCallback } from 'react';
import type { LayoutChangeEvent, ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import type { SharedValue, useAnimatedStyle } from 'react-native-reanimated';
import Animated, { withTiming } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { theme, useResponsive } from '@sg/garnish';

import { useNewLocationsLayout } from '../../hooks';
import {
  useLocationResultsExpandCollapse,
  useLocationResultsFeeDisclosure,
  useLocationResultsScrolling,
} from './hooks';
import { useLocationResultsExpandCollapseStyles } from './hooks/useLocationResultsExpandCollapseStyle';
import { LOCATION_RESULTS_BORDER_RADIUS } from './LocationResults.constants';
import {
  LocationResultsBackdrop,
  LocationResultsBottomShadow,
  LocationResultsLists,
  LocationResultsListsV2,
  LocationResultsMoreLocationsToggle,
} from './subcomponents';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders location lists in a dynamic container that can be expanded and collapsed.
 * It employs a two-container (outer and inner) approach to correctly calculate
 * collapsed and expanded layouts, as well as smooth animations utilizing the
 * `translateY` animated style to avoid repainting.
 *
 * It supports the following modes/states:
 *
 * - Loading state
 * - A single location view
 * - Multiple locations
 * - Multiple locations + other locations
 */
export const LocationResults = memo((props: LocationResultsProps) => {
  const {
    locations = [],
    otherLocations = [],
    locationSearchType,
    activePinLocationId,
    interactedLocationRestaurantSlug,
    interactedLocationDeliveryAddressId,
    isLoading = false,
    isUsingSearchField,
    shouldHideDetailsModal,
    withSafeAreaInsets = false,
    onCardPress,
    onCardFocus,
    onLocationResultsExpandCollapse,
  } = props;

  const { minWidth } = useResponsive();

  // ─────────────────────────────────────────────────────────────────────

  const shouldUseNewVersion = useNewLocationsLayout();

  const allLocations = [...locations, ...otherLocations];

  const totalLocationsNumber = allLocations.length;
  const hasNoResults =
    !isLoading &&
    (shouldUseNewVersion ? locations.length === 0 : totalLocationsNumber === 0);
  const hasSingleResult = totalLocationsNumber === 1;
  const hasSingleOtherResult = hasSingleResult && otherLocations.length === 1;
  const hasMultipleResults = totalLocationsNumber > 1;
  const shouldUseDetailsModal =
    locationSearchType !== 'delivery' && !shouldHideDetailsModal;
  const firstLocation = allLocations?.[0];

  // ─── Fee Disclaimer ──────────────────────────────────────────────────

  const { shouldShowDeliveryFeeDisclaimer, shouldShowOutpostFeeDisclaimer } =
    useLocationResultsFeeDisclosure({ location: firstLocation });
  const shouldShowFeeDisclaimer =
    shouldShowDeliveryFeeDisclaimer || shouldShowOutpostFeeDisclaimer;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const { isExpanded, toggleExpandedState } = useLocationResultsExpandCollapse({
    locationSearchType,
    hasSingleResult,
    onLocationResultsExpandCollapse,
  });

  const {
    outerContainerHeight,
    innerContainerAnimatedStyle,
    innerContainerCollapsedStyle,
  } = useLocationResultsExpandCollapseStyles({
    isExpanded,
    hasMultipleResults,
    hasSingleOtherResult,
    shouldShowFeeDisclaimer,
  });

  const {
    listScrollViewRef,
    setListItemScrollPosition,
    deleteListItemScrollPosition,
  } = useLocationResultsScrolling({
    activePinLocationId,
    isExpanded,
    locationSearchType,
  });

  // ─────────────────────────────────────────────────────────────────────

  if (hasNoResults) return null;

  // ─── SM ──────────────────────────────────────────────────────────────

  if (minWidth.isSM) {
    return shouldUseNewVersion ? (
      <LocationResultsListsV2.SM
        variation="standard"
        selectedLocationSearchType={locationSearchType}
        onLocationCardFocus={onCardFocus}
        focusedLocationId={activePinLocationId}
        interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
        interactedLocationDeliveryAddressId={
          interactedLocationDeliveryAddressId
        }
        isLoading={isLoading}
        isUsingSearchField={isUsingSearchField}
        locations={locations}
        onLocationCardPress={onCardPress}
      />
    ) : (
      <LocationResultsLists
        ref={listScrollViewRef}
        locationSearchType={locationSearchType}
        locations={locations}
        otherLocations={otherLocations}
        interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
        isLoading={isLoading}
        withDetailsModal={shouldUseDetailsModal}
        containerHeightSharedValue={outerContainerHeight}
        hasSingleResult={hasSingleResult}
        isExpanded={true}
        onCardLayout={setListItemScrollPosition}
        onCardPress={onCardPress}
        onCardFocus={onCardFocus}
        onCardUnmount={deleteListItemScrollPosition}
      />
    );
  }

  // ─── XS ──────────────────────────────────────────────────────────────

  return shouldUseNewVersion ? (
    <LocationResultsListsV2.XS
      variation="standard"
      selectedLocationSearchType={locationSearchType}
      onLocationCardFocus={onCardFocus}
      focusedLocationId={activePinLocationId}
      interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
      interactedLocationDeliveryAddressId={interactedLocationDeliveryAddressId}
      isLoading={isLoading}
      isUsingSearchField={isUsingSearchField}
      locations={locations}
      onLocationCardPress={onCardPress}
    />
  ) : (
    <>
      <LocationResultsBackdrop isVisible={isExpanded} />
      <LocationResultsBottomShadow
        innerContainerCollapsedStyle={innerContainerCollapsedStyle}
        innerContainerAnimatedStyle={innerContainerAnimatedStyle}
      />

      <LocationResultsOuterContainer
        outerContainerHeight={outerContainerHeight}
        withSafeAreaInsets={withSafeAreaInsets}
      >
        <LocationResultsInnerContainer
          innerContainerAnimatedStyle={innerContainerAnimatedStyle}
        >
          {hasMultipleResults ? (
            <LocationResultsMoreLocationsToggle
              isToggled={isExpanded}
              onPress={toggleExpandedState}
            />
          ) : null}

          <LocationResultsLists
            ref={listScrollViewRef}
            locationSearchType={locationSearchType}
            locations={locations}
            otherLocations={otherLocations}
            interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
            isLoading={isLoading}
            withDetailsModal={shouldUseDetailsModal}
            containerHeightSharedValue={outerContainerHeight}
            hasSingleResult={hasSingleResult}
            isExpanded={isExpanded}
            onCardLayout={setListItemScrollPosition}
            onCardPress={onCardPress}
            onCardFocus={onCardFocus}
            onCardUnmount={deleteListItemScrollPosition}
          />
        </LocationResultsInnerContainer>
      </LocationResultsOuterContainer>
    </>
  );
});

// ─── Subcomponents ───────────────────────────────────────────────────────────

/**
 * Renders a `View` container component that fills available space and synchronizes
 * its height with the associated shared value for subsequent calculations.
 */
const LocationResultsOuterContainer = memo(
  (props: LocationResultsOuterContainerProps) => {
    const { children, outerContainerHeight, withSafeAreaInsets } = props;

    const { bottom: safeAreaBottom } = useSafeAreaInsets();
    const shouldUseSafeAreaInsets = safeAreaBottom > 0 && withSafeAreaInsets;

    const setContainerHeightValue = useCallback(
      (event: LayoutChangeEvent) => {
        const { layout } = event.nativeEvent;

        // eslint-disable-next-line functional/immutable-data
        outerContainerHeight.value = withTiming(layout.height);
      },
      [outerContainerHeight],
    );

    const outerContainerDynamicStyles = useStyle(
      () => ({
        marginBottom: shouldUseSafeAreaInsets
          ? safeAreaBottom
          : theme.spacing['4'],
      }),
      [safeAreaBottom],
    );

    return (
      <View
        style={[styles.outerContainer, outerContainerDynamicStyles]}
        onLayout={setContainerHeightValue}
        pointerEvents="box-none"
      >
        {children}
      </View>
    );
  },
);

/**
 * Renders an animated `View` component that can be expanded/collapsed externally using
 * animated styles to use smooth animations and prevent repainting.
 */
const LocationResultsInnerContainer = memo(
  (props: LocationResultsInnerContainerProps) => {
    const { children, innerContainerAnimatedStyle } = props;

    const innerContainerStyles = [
      styles.innerContainer,
      innerContainerAnimatedStyle,
    ];

    return (
      <Animated.View style={innerContainerStyles} pointerEvents="box-none">
        {children}
      </Animated.View>
    );
  },
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    ...StyleSheet.absoluteFillObject,
    zIndex: theme.zIndex.fixed,
    borderRadius: LOCATION_RESULTS_BORDER_RADIUS,
    marginTop: theme.spacing['4'],
    marginHorizontal: theme.spacing['4'],
    overflow: 'hidden',
  },
  innerContainer: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsProps = Readonly<{
  activePinLocationId: string | undefined;
  shouldHideDetailsModal: boolean;
  onLocationResultsExpandCollapse?: (isExpanded: boolean) => void;
  withSafeAreaInsets?: boolean;
  interactedLocationDeliveryAddressId?: string;
  isUsingSearchField: boolean;
}> &
  Pick<
    ComponentProps<typeof LocationResultsLists>,
    | 'locations'
    | 'otherLocations'
    | 'locationSearchType'
    | 'interactedLocationRestaurantSlug'
    | 'isLoading'
    | 'onCardPress'
    | 'onCardFocus'
  >;

type LocationResultsOuterContainerProps = Readonly<{
  children: ViewProps['children'];
  outerContainerHeight: SharedValue<number>;
  withSafeAreaInsets: boolean;
}>;

type LocationResultsInnerContainerProps = Readonly<{
  children: ViewProps['children'];
  innerContainerAnimatedStyle: ReturnType<typeof useAnimatedStyle>;
}>;

import { useCallback, useState } from 'react';
import {
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';

// ─────────────────────────────────────────────────────────────────────────────

export const useHeroCardFlipAnimation = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const toggleFlipState = useCallback(() => {
    setIsFlipped((currentValue) => !currentValue);
  }, []);

  // ─────────────────────────────────────────────────────────────────────

  const progress = useDerivedValue(() => {
    const value = isFlipped ? 1 : 0;

    return withTiming(value, { duration: DURATION });
  });

  // ─── Styles ──────────────────────────────────────────────────────────

  const cardFrontSideAnimatedStyle = useAnimatedStyle(() => {
    const computed = progress.value * ROTATION_Y;
    const skewY = -progress.value * SKEW_Y;

    return {
      transform: [{ rotateY: `${computed}deg` }, { skewY: `${skewY}deg` }],
    };
  }, [progress]);

  const cardBackSideAnimatedStyle = useAnimatedStyle(() => {
    const computed = progress.value * ROTATION_Y + ROTATION_Y;
    const skewY = -progress.value * SKEW_Y + SKEW_Y;

    return {
      transform: [{ rotateY: `${computed}deg` }, { skewY: `${skewY}deg` }],
    };
  }, [progress]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    isFlipped,
    toggleFlipState,
    cardFrontSideAnimatedStyle,
    cardBackSideAnimatedStyle,
  };
};

// ─── Constants ───────────────────────────────────────────────────────────────

const DURATION = 1000;
const ROTATION_Y = 180;
const SKEW_Y = 30;

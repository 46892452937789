/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BagTimesPollingQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type BagTimesPollingQuery = {
  readonly __typename?: 'Query';
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly availableWantedTimes: ReadonlyArray<{
      readonly __typename?: 'AvailableWantedTime';
      readonly time: string;
      readonly deliveryOffset: number;
    }>;
  } | null;
};

export const BagTimesPollingDocument = gql`
  query BagTimesPolling {
    cart {
      id
      availableWantedTimes {
        time
        deliveryOffset
      }
    }
  }
`;

export function useBagTimesPollingQuery(
  options?: Omit<Urql.UseQueryArgs<BagTimesPollingQueryVariables>, 'query'>,
) {
  return Urql.useQuery<BagTimesPollingQuery, BagTimesPollingQueryVariables>({
    query: BagTimesPollingDocument,
    ...options,
  });
}

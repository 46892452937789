import React from 'react';
import { StyleSheet } from 'react-native';
import { BREAKPOINTS, theme, useFluidSize, useResponsive } from '@sg/garnish';

export const useDynamicStyles = () => {
  const fluid = useFluidSize({
    min: BREAKPOINTS[1].MIN,
    max: BREAKPOINTS[3].MIN,
  });
  const { currentBreakpoint } = useResponsive();

  const cardHeight = fluid(278, 412);
  const containerMargin = fluid(24, 40);
  const containerVertical = fluid(16, 32);
  const containerHorizontal = currentBreakpoint.isXS ? 16 : fluid(10, 24);
  const cardGap = currentBreakpoint.isXS ? theme.spacing['4'] : fluid(10, 40);
  const labelSpacing = fluid(12, 16);
  const nameSpacing = fluid(2, 8);
  const titleSpacing = fluid(12, 24);
  const buttonSpacing = fluid(16, 24);
  const imageSize = fluid(120, 200);

  // ────────────────── Custom Font Sizes ──────────────────
  // The font sizes for the featured channel do not conform to our standards.

  // Label 3 -> Label 1, but Tablet -> Desktop.
  const customLabelFontSize = fluid(10, 14);
  const customLabelLineHeight = fluid(16, 18);

  // Body 4 -> Body 2, but Tablet -> Desktop.
  const customBodyFontSize = fluid(14, 18);
  const customBodyLineHeight = fluid(18, 24);

  // ───────────────────────────────────────────────────────

  return React.useMemo(
    () => ({
      cardGap,
      cardHeight,
      containerMarginStyle: {
        marginTop: containerMargin,
        marginBottom: 0,
      },
      containerStyle: [
        styles.container,
        {
          paddingVertical: containerVertical,
          paddingHorizontal: containerHorizontal,
        },
      ],
      labelStyle: [
        styles.centerText,
        styles.labelText,
        {
          marginTop: labelSpacing,
          fontSize: customLabelFontSize,
          lineHeight: customLabelLineHeight,
        },
      ],
      nameStyle: [
        styles.centerText,
        {
          marginTop: nameSpacing,
          marginBottom: buttonSpacing,
          fontSize: customBodyFontSize,
          lineHeight: customBodyLineHeight,
        },
      ],
      titleStyle: [
        styles.centerText,
        {
          marginTop: titleSpacing,
          marginBottom: buttonSpacing,
          fontSize: customBodyFontSize,
          lineHeight: customBodyLineHeight,
        },
      ],
      buttonStyle: styles.button,
      imageStyle: {
        width: imageSize,
        height: imageSize,
      },
    }),
    [
      cardGap,
      cardHeight,
      buttonSpacing,
      containerHorizontal,
      containerMargin,
      containerVertical,
      customBodyFontSize,
      customBodyLineHeight,
      customLabelFontSize,
      customLabelLineHeight,
      imageSize,
      labelSpacing,
      nameSpacing,
      titleSpacing,
    ],
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.QUINOA,
    borderRadius: theme.radius.large,
  },
  centerText: {
    textAlign: 'center',
  },
  labelText: {
    color: theme.colors.CHARCOAL,
  },
  button: {
    marginTop: 'auto',
    width: '100%',
    maxWidth: 240,
  },
});

import React, { type ComponentProps } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, Text } from 'react-native';
import { Button, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuTopDownCategoriesRecentOrdersButton = (
  props: MenuTopDownCategoriesRecentOrdersButtonProps,
) => {
  const { style, ...restProps } = props;

  const { formatMessage } = useIntl();

  return (
    <Button
      accessibilityLabel={formatMessage(messages.reorderButtonA11yLabel)}
      size="medium"
      palette="kaleLightest"
      style={[styles.button, style]}
      onPress={props.onPress}
      {...restProps}
    >
      <Text style={styles.text}>
        {formatMessage(messages.reorderButtonLabel)}
      </Text>
    </Button>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  button: {
    alignSelf: 'center',
  },
  text: {
    color: theme.colors.KALE,
  },
});

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  reorderButtonLabel: {
    defaultMessage: 'Recent orders + favorites',
    description:
      'Menu categories modal | Recent orders + favorites button | label',
  },
  reorderButtonA11yLabel: {
    defaultMessage: 'Navigate to "Recent orders + favorites"',
    description:
      'Menu categories modal | Recent orders and favorites button | a11y label',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuTopDownCategoriesRecentOrdersButtonProps = Omit<
  ComponentProps<typeof Button>,
  'children'
>;

import type { getCurrentBreakpoint } from '../getCurrentBreakpoint';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A small helper that returns the provided value based on its index and active breakpoint.
 */
export function createMatchByBreakpointIndex(
  currentBreakpoint: CurrentBreakpoint,
): CreateMatchByBreakpoint {
  // @ts-expect-error TS(2322): Type '<T>(values: readonly T[]) => T | undefined' ... Remove this comment to see the full error message
  return function matchByBreakpointIndex(values) {
    const [xsValue, smValue, mdValue, lgValue] = values;
    const lastValue = values.at(-1);

    // ─────────────────────────────────────────────

    if (currentBreakpoint.isXS) return xsValue;

    if (currentBreakpoint.isSM) return smValue ?? lastValue;

    if (currentBreakpoint.isMD) return mdValue ?? lastValue;

    if (currentBreakpoint.isLG) return lgValue ?? lastValue;

    return xsValue;
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type CreateMatchByBreakpoint = <T>(values: readonly T[]) => T;

type CurrentBreakpoint = ReturnType<typeof getCurrentBreakpoint>;

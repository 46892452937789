import {
  type Configuration,
  PublicClientApplication,
} from '@azure/msal-browser';

import { config } from '../config/config';

// ─── Config ──────────────────────────────────────────────────────────────────

const { clientId, authority, tenantHost, redirectUri, cacheLocation } = config;

/**
 * Configuration object to be passed to MSAL instance on creation.
 *
 * See {@link https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md}
 * See {@link https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md}
 */
const msalConfig: Configuration = {
  auth: {
    clientId,
    authority,
    redirectUri,
    knownAuthorities: [tenantHost],
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation,
  },
};

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Creates a public client application instance that can be used for authentication
 * interactions on the web platform.
 *
 * @see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md
 */
export const clientInstance = new PublicClientApplication(msalConfig);

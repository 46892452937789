import { useCallback } from 'react';
import {
  type RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';

import { useNavigateToMainTabs } from '../../navigation';
import type {
  AppStackParamList,
  ModalStackParamList,
} from '../../navigation/AppNavigation.props';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Various navigation helpers for the "Sweetpass Checkout" screen.
 *
 * NOTE: We use `replace` method in some helpers since we don't want to
 *       keep the "Sweetpass Checkout" screen in the navigation history because
 *       it will be unavailable after the update.
 */
export const useSweetpassCheckoutNavigation = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<AppStackParamList>>();
  const { params } =
    useRoute<RouteProp<ModalStackParamList, 'SweetpassCheckout'>>();

  const navigateToMainTabs = useNavigateToMainTabs();

  // ─────────────────────────────────────────────────────────────────────

  const {
    planId: planIdFromParams,
    campaignId,
    redirectToAfterUpgrade,
    redirectToOrderId,
  } = params ?? {};

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldRedirectToOrderStatusAfterUpgrade =
    redirectToAfterUpgrade === 'order-status' &&
    redirectToOrderId !== undefined;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateAfterUpgrade = useCallback(() => {
    if (shouldRedirectToOrderStatusAfterUpgrade) {
      navigation.navigate('Modal', {
        screen: 'OrderStatus',
        params: { orderId: redirectToOrderId },
      });

      return;
    }

    void navigateToMainTabs('LoyaltyTab', {
      screen: 'SweetpassHome',
    });
  }, [
    navigation,
    redirectToOrderId,
    shouldRedirectToOrderStatusAfterUpgrade,
    navigateToMainTabs,
  ]);

  const navigateToHomeScreen = useCallback(() => {
    void navigateToMainTabs('HomeTab', {
      screen: 'Home',
    });
  }, [navigateToMainTabs]);

  const navigateToSweetpassHomeScreen = useCallback(() => {
    void navigateToMainTabs('LoyaltyTab', {
      screen: 'SweetpassHome',
    });
  }, [navigateToMainTabs]);

  const redirectToLoginScreen = useCallback(() => {
    navigation.replace('Auth', {
      screen: 'Login',
      params: { redirect: 'sweetpassUpgrade' },
    });
  }, [navigation]);

  const redirectToJoinScreen = useCallback(() => {
    navigation.replace('Auth', {
      screen: 'Join',
      params: {
        redirect: 'sweetpassUpgrade',
        redirectToPlanId: planIdFromParams,
        redirectToCampaignId: campaignId,
      },
    });
  }, [navigation, planIdFromParams, campaignId]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    redirectToLoginScreen,
    redirectToJoinScreen,
    navigateAfterUpgrade,
    navigateToHomeScreen,
    navigateToSweetpassHomeScreen,
  };
};

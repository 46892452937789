import { useCallback } from 'react';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useOrdering } from '@order/features/ordering';
import type { DeliveryOrderDetail, PartialLineItem } from '@order/graphql';

import { useAddUpsellToBag } from '../useAddUpsellToBag';
import { useBagAvailablePoints } from '../useBagAvailablePoints';
import { useBagLoyaltyRewards } from '../useBagLoyaltyRewards/useBagLoyaltyRewards';
import { useBagTracking } from '../useBagTracking';
import { useCart } from '../useCart';
import { useChangeChannelOrLocation } from '../useChangeChannelOrLocation';
import { useCredits } from '../useCredits';
import { useDefaultPaymentMethod } from '../useDefaultPaymentMethod';
import { useFetchTelemetryData } from '../useFetchTelemetryData';
import { useFetchWantedTimes } from '../useFetchWantedTimes';
import { useGiftCards } from '../useGiftCards';
import { useNavigatePostOrder } from '../useNavigatePostOrder';
import { usePaymentMethods } from '../usePaymentMethods';
import { usePromoCode } from '../usePromoCode';
import { useRewards } from '../useRewards';
import { useSubmitOrder } from '../useSubmitOrder';
import { useUpdateLineItem } from '../useUpdateLineItem';

/**
 * Hook that binds the ordering machine with the external data.
 */
export const useOrderState = (params: UseOrderStateParams) => {
  const { isLoyaltyV2Enabled, isExpressPaymentsEnabled } = params;
  const isLoggedIn = useIsLoggedIn();
  const bagTracking = useBagTracking();

  // ─── Loyalty ──────────────────────────────────────────────────────────────

  const { fetchBagAvailablePoints } = useBagAvailablePoints(isLoyaltyV2Enabled);
  const { fetchBagLoyaltyRewards } = useBagLoyaltyRewards();
  const { fetchRewardsV1, applyReward, removeReward } = useRewards();

  const fetchRewards = isLoyaltyV2Enabled
    ? fetchBagLoyaltyRewards
    : fetchRewardsV1;

  // ──────────────────────────────────────────────────────────────────────────

  const { fetchCart } = useCart();
  const { fetchCredits } = useCredits();
  const { fetchWantedTimes } = useFetchWantedTimes();
  const { addUpsellToBag } = useAddUpsellToBag();
  const { fetchGiftCardBalance, applyGiftCard } = useGiftCards(fetchCredits);
  const { fetchPaymentMethods, addPaymentMethod } = usePaymentMethods();
  const { fetchDefaultPaymentMethod, setDefaultPaymentMethod } =
    useDefaultPaymentMethod();
  const { updateLineItem } = useUpdateLineItem();
  const { applyPromoCode } = usePromoCode(fetchRewards);
  const { changeChannelOrLocation } = useChangeChannelOrLocation();
  const { submitOrder } = useSubmitOrder(setDefaultPaymentMethod);
  const { fetchTelemetryData } = useFetchTelemetryData();
  const { navigatePostOrder } = useNavigatePostOrder();

  const fetchInitialData = useCallback(async () => {
    if (!isLoggedIn) {
      return {
        cart: await fetchCart(),
        paymentMethods: [],
        defaultPaymentMethodId: undefined,
        availablePoints: 0,
        availableCredit: 0,
        giftCardBalance: 0,
        customerId: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        isNewCustomer: true,
      };
    }

    const [
      cart,
      paymentMethods,
      defaultPaymentMethodId,
      availableCredit,
      giftCardBalance,
      telemetryData,
      { availablePoints },
    ] = await Promise.all([
      fetchCart(),
      fetchPaymentMethods(),
      fetchDefaultPaymentMethod(),
      fetchCredits(),
      fetchGiftCardBalance(),
      fetchTelemetryData(),
      fetchBagAvailablePoints(),
    ]);

    return {
      cart,
      paymentMethods,
      defaultPaymentMethodId,
      availablePoints,
      availableCredit,
      giftCardBalance,
      ...telemetryData,
    };
  }, [
    isLoggedIn,
    fetchCart,
    fetchPaymentMethods,
    fetchDefaultPaymentMethod,
    fetchCredits,
    fetchGiftCardBalance,
    fetchTelemetryData,
    fetchBagAvailablePoints,
  ]);

  return useOrdering<PartialLineItem, DeliveryOrderDetail>({
    isLoggedIn,
    isExpressPaymentsEnabled,
    bagTracking,
    navigatePostOrder,
    fetchInitialData,
    fetchRewards,
    fetchWantedTimes,
    addUpsellToBag,
    updateLineItem,
    changeChannelOrLocation,
    applyReward,
    removeReward,
    applyPromoCode,
    addPaymentMethod,
    setDefaultPaymentMethod,
    applyGiftCard,
    submitOrder,
  });
};

type UseOrderStateParams = {
  isLoyaltyV2Enabled: boolean;
  isExpressPaymentsEnabled: boolean;
};

import { useCallback, useMemo, useRef } from 'react';
import { useInterpret } from '@xstate/react';
import type { State } from 'xstate';

import type { ModalMachineContext, ModalMachineEvents } from './modal-machine';
import { createModalMachine } from './modal-machine';

export const useModalMachine = (props: UseModalMachineProps) => {
  const {
    initial = 'dismissed',
    onShowing,
    onShown,
    onDismissing,
    onDismissed,
  } = props;

  // ──────────────────────────────────────────────────────────────────────────────

  const { current: modalMachine } = useRef(createModalMachine({ initial }));

  const eventHandlersMapping = useMemo<ModalEventHandlersMapping>(
    () => ({
      START_SHOW: onShowing,
      SHOW: onShown,
      START_DISMISS: onDismissing,
      DISMISS: onDismissed,
    }),
    [onDismissed, onDismissing, onShowing, onShown],
  );

  const modalServiceObserver = useCallback<ModalServiceEventHandler>(
    (state, event) => {
      if (!event || !state.changed) return;

      const eventHandler = eventHandlersMapping[event.type];

      eventHandler?.();
    },
    [eventHandlersMapping],
  );

  const modalService = useInterpret(
    modalMachine,
    {},
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    modalServiceObserver as any,
  );

  //
  // ─── EVENT DISPATCHERS ────────────────────────────────────────────────────────
  //

  const sendStartShowEvent = useCallback(() => {
    modalService.send('START_SHOW');
  }, [modalService]);

  const sendShowEvent = useCallback(() => {
    modalService.send('SHOW');
  }, [modalService]);

  const sendDismissEvent = useCallback(() => {
    modalService.send('DISMISS');
  }, [modalService]);

  const sendStartDismissEvent = useCallback(() => {
    modalService.send('START_DISMISS');
  }, [modalService]);

  // ──────────────────────────────────────────────────────────────────────────────

  return useMemo(
    () => ({
      modalService,
      helpers: {
        sendStartShowEvent,
        sendShowEvent,
        sendDismissEvent,
        sendStartDismissEvent,
      },
    }),
    [
      modalService,
      sendDismissEvent,
      sendShowEvent,
      sendStartDismissEvent,
      sendStartShowEvent,
    ],
  );
};

//
// ─── TYPES ──────────────────────────────────────────────────────────────────────
//

type UseModalMachineProps = Readonly<{
  initial: 'shown' | 'dismissed';
  onShowing?: ModalEventHandler;
  onShown?: ModalEventHandler;
  onDismissing?: ModalEventHandler;
  onDismissed?: ModalEventHandler;
}>;

type ModalEventHandler = () => void;

type ModalEventHandlersMapping = Record<string, ModalEventHandler | undefined>;

type ModalServiceEventHandler = (
  state: State<ModalMachineContext>,
  event: ModalMachineEvents,
) => void;

import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useClient } from 'urql';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useCustomer } from '@order/Customer';
import { useTelemetry } from '@order/Telemetry';

import { CancelOrderV2 } from '../../graphql';
import {
  OrderCancelationStatusDocument,
  type OrderCancelationStatusQuery,
  type OrderCancelationStatusQueryVariables,
} from '../../graphql/OrderCancelationStatus.generated';
import { orderCancellationMessages as messages } from '../../order-cancellation-messages';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns helpers to handle in-app order cancellations
 * (with async cancellation support).
 */
export const useCancelOrderV2 = (params: UseOrderCancelParams) => {
  const { orderId, restaurantId, onSuccess, onAsyncCancelation, onError } =
    params;

  const client = useClient();

  // ─────────────────────────────────────────────────────────────────────

  const { track } = useTelemetry();

  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { customer } = useCustomer();
  const isLoggedIn = useIsLoggedIn();
  const { formatMessage } = useIntl();

  const customerId = customer.id ?? '';

  // ─── Refs ────────────────────────────────────────────────────────────

  const asyncCancelationTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  // ─── Data ────────────────────────────────────────────────────────────

  const [cancelPendingOrderResponse, cancelPendingOrder] =
    CancelOrderV2.useCancelOrderV2Mutation();

  const { fetching: isCancellingOrder } = cancelPendingOrderResponse;

  const orderCancelationStatusQuery = useCallback(async () => {
    const queryCancellableOrders = client.query<
      OrderCancelationStatusQuery,
      OrderCancelationStatusQueryVariables
    >;

    return queryCancellableOrders(
      OrderCancelationStatusDocument,
      { id: orderId },
      { requestPolicy: 'network-only' },
    ).toPromise();
  }, [client.query, orderId]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  /**
   * Handles successful order cancellation event by displaying the associated
   * notice banner, tracking the event, and triggering the optional
   * `onSuccess` callback.
   */
  const handleSuccess = useCallback(() => {
    addNoticeBanner({
      text: formatMessage(messages.bannerSuccessText),
      palette: 'success',
    });
    track('order_cancel.success');

    onSuccess?.();
  }, [addNoticeBanner, onSuccess, formatMessage, track]);

  const clearHandleSuccessAsyncTimeout = useCallback(() => {
    if (!asyncCancelationTimeoutRef.current) return;

    clearTimeout(asyncCancelationTimeoutRef.current);
  }, []);

  /**
   * Handles failed order cancellation event by displaying the associated
   * notice banner, tracking the event, and triggering the optional
   * `onError` callback.
   */
  const handleError = useCallback(
    (errorParams: UseOrderCancelHandleErrorParams) => {
      const { id, text, reason } = errorParams;

      addNoticeBanner({ id, text, palette: 'caution' }, true);
      track('order_cancel.failure', { reason });

      onError?.();
    },
    [addNoticeBanner, onError, track],
  );

  /**
   * Handles failed order async cancellation event by displaying the associated
   * notice banner and tracking the event.
   */
  const handleAsyncCancelationError = useCallback(() => {
    addNoticeBanner(
      {
        id: 'in-app-cancellation.async-cancellation-failure',
        text: formatMessage(messages.bannerPendingCancellationFailedText),
        palette: 'caution',
      },
      true,
    );

    track('order_cancel.async-cancelation.failure');
  }, [addNoticeBanner, formatMessage, track]);

  /**
   * Starts a timeout to check the order cancelation status after async cancelation.
   */
  const startAsyncCancelationStatusCheckTimeout = useCallback(() => {
    // eslint-disable-next-line functional/immutable-data
    asyncCancelationTimeoutRef.current = setTimeout(async () => {
      const { data } = await orderCancelationStatusQuery();

      const responseData = data?.orderStatus;

      const hasSucceedToCancelOrder =
        responseData?.__typename === 'OrderStatus' &&
        responseData?.canceledStatus === ASYNC_CANCELATION_SUCCESS_STATUS;
      const hasFailedToCancelOrder =
        responseData?.__typename === 'OrderStatus' &&
        responseData?.canceledStatus === ASYNC_CANCELATION_FAILURE_STATUS;

      if (hasSucceedToCancelOrder) {
        handleSuccess();
      }

      if (hasFailedToCancelOrder) {
        handleAsyncCancelationError();
      }
    }, ASYNC_CANCELLATION_TIMEOUT);
  }, [handleAsyncCancelationError, handleSuccess, orderCancelationStatusQuery]);

  /**
   * Handles async order cancellation event by displaying the associated
   * notice banner, tracking the event, and triggering the optional
   * `onAsyncCancelation` callback.
   */
  const handleAsyncCancelation = useCallback(() => {
    addNoticeBanner({
      text: formatMessage(messages.bannerPendingCancellationText),
      palette: 'neutral',
      autoHideTimeout: 5000,
    });
    track('order_cancel.async-cancelation.start');

    onAsyncCancelation?.();
    startAsyncCancelationStatusCheckTimeout?.();
  }, [
    addNoticeBanner,
    formatMessage,
    onAsyncCancelation,
    startAsyncCancelationStatusCheckTimeout,
    track,
  ]);

  /**
   * Attempts to cancel the order using the associated mutation.
   * - Handles both successful and unsuccessful responses.
   * - Returns the resolved data.
   */
  const cancelOrder = useCallback(async () => {
    const hasRequiredParams = Boolean(customerId && orderId && restaurantId);

    if (!hasRequiredParams) return;

    const response = await cancelPendingOrder(
      {
        input: {
          orderId,
          customerId,
          restaurantId,
          reason: 'customer initiated cancel',
          origin: 'customer cancel',
          refund: true,
        },
      },
      { requestPolicy: 'cache-and-network' },
    );

    const responseData = response.data?.cancelOrderV2;
    const responseTypename = responseData?.__typename ?? '';

    // ─── Async Cancelation ───────────────────────────────────────────────

    // NOTE: Some orders can be canceled asynchronously, which can take ~10 seconds.
    const hasSuccessfullyCanceledAsyncOrder =
      responseData?.__typename === 'CancelOrderSuccessAsync' &&
      responseData.success;

    if (hasSuccessfullyCanceledAsyncOrder) {
      handleAsyncCancelation?.();

      return;
    }

    // ─── Success ─────────────────────────────────────────────────────────

    const hasSuccessfullyCanceledOrder =
      responseData?.__typename === 'CancelOrderSuccess' && responseData.success;

    if (hasSuccessfullyCanceledOrder) {
      handleSuccess();

      return responseData;
    }

    /*
      This is a partially successful case in which we need to display an
      error banner but also call the `onSuccess` callback because the
      order was canceled but the refund failed.
     */
    if (responseData?.__typename === 'CancelSuccessRefundFailed') {
      handleError({
        id: responseTypename,
        text: formatMessage(messages.bannerSuccessButRefundFailedText),
        reason: responseData.errorMessage,
      });
      onSuccess?.();

      return;
    }

    // ─── Errors ──────────────────────────────────────────────────────────

    const isSpecialCaseError =
      responseData?.__typename === 'CancellationFailedWithDelivery' ||
      responseData?.__typename === 'CancellationLimitExceeded' ||
      responseData?.__typename === 'OrderAlreadyCanceled';
    const isGenericError = responseData?.__typename === 'CancelOrderFailed';

    if (isSpecialCaseError) {
      handleError({
        id: responseTypename,
        text: formatMessage(messages.bannerErrorCustomerSupportText),
        reason: responseData.errorMessage,
      });

      return;
    }

    if (responseData?.__typename === 'OrderAlreadySentToKitchen') {
      handleError({
        id: responseTypename,
        text: formatMessage(messages.bannerErrorAlreadyPreparingText),
        reason: responseData.errorMessage,
      });

      return;
    }

    const errorMessage = isGenericError
      ? responseData.errorMessage
      : formatMessage(messages.bannerGenericErrorText);

    handleError({
      id: responseTypename,
      text: formatMessage(messages.bannerGenericErrorText),
      reason: errorMessage,
    });
  }, [
    customerId,
    orderId,
    restaurantId,
    cancelPendingOrder,
    formatMessage,
    handleError,
    handleAsyncCancelation,
    handleSuccess,
    onSuccess,
  ]);

  // ─── Effects ─────────────────────────────────────────────────────────

  // Cleanup on logout
  useEffect(() => {
    if (isLoggedIn) return;

    clearHandleSuccessAsyncTimeout();
  }, [clearHandleSuccessAsyncTimeout, isLoggedIn]);

  // ─────────────────────────────────────────────────────────────────────

  return { cancelOrder, isCancellingOrder };
};

// ─── Constants ───────────────────────────────────────────────────────────────

const ASYNC_CANCELLATION_TIMEOUT = 10_000;
const ASYNC_CANCELATION_SUCCESS_STATUS = 'CANCELED_SUCCESS';
const ASYNC_CANCELATION_FAILURE_STATUS = 'ORDER_ALREADY_STARTED';

// ─── Types ───────────────────────────────────────────────────────────────────

type UseOrderCancelParams = Readonly<{
  orderId: string;
  restaurantId: string;
  onSuccess?: () => void;
  onAsyncCancelation?: () => void;
  onError?: () => void;
}>;

type UseOrderCancelHandleErrorParams = Readonly<{
  id: string;
  text: string;
  reason: string;
}>;

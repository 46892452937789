import { useCallback } from 'react';
import { useActor } from '@xstate/react';

import { useGlobalAppState } from '@order/GlobalAppState';

import { useCloseBag } from '../../../../components/Bag/Bag.hooks';
import type { ReorderEvent } from '../../machines/Reorder.machine';
import { useReorderNavigation } from '../useReorderNavigation';
import { useReorderTelemetry } from '../useReorderTelemetry';
import {
  cameFromLocationConfirmation,
  getValidEventKeys,
  justStartedReorder,
  logger,
} from '../utils';

export const useChangeLocation = () => {
  const { reorderMachineRef } = useGlobalAppState();
  const [state, sendReorderEvent] = useActor(reorderMachineRef);
  const { startReorderTelemetry, changeLocationTelemetry } =
    useReorderTelemetry();
  const { navigateToChangeLocation, replaceWithChangeLocation } =
    useReorderNavigation();
  const closeBag = useCloseBag();

  return useCallback(
    (event?: ReorderEvent) => {
      logger.info('Change Location', event);

      // ─── State Machine ──────────────────────────────────────────
      sendReorderEvent({
        ...getValidEventKeys(event),
        type: 'CHANGE_LOCATION',
      });

      // ─── Extra Telemetry Event ──────────────────────────────────
      if (cameFromLocationConfirmation(state.value)) {
        changeLocationTelemetry(event ?? state.context);
      }

      // ─── Just Started Reorder ───────────────────────────────────
      if (justStartedReorder(state.value)) {
        // ─── Start Reorder Telemetry ──────────────────────────────
        startReorderTelemetry(
          event ?? state.context,
          'confirm_location',
          event?.userInitiated,
        );

        // ─── Navigation ───────────────────────────────────────────
        closeBag();
        setTimeout(navigateToChangeLocation);

        return;
      }

      // ─── Continuing Reorder ─────────────────────────────────────
      replaceWithChangeLocation();
    },
    [
      state.value,
      state.context,
      sendReorderEvent,
      closeBag,
      navigateToChangeLocation,
      replaceWithChangeLocation,
      changeLocationTelemetry,
      startReorderTelemetry,
    ],
  );
};

import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { Container, useResponsive } from '@sg/garnish';

import { useLargeDesktopBreakpoint } from '../../../hooks';

export const ProductModificationsContainer = (
  props: Pick<ViewProps, 'children'>,
) => {
  const { match, minWidth } = useResponsive();
  const isLargeDesktop = useLargeDesktopBreakpoint();

  const containerMaxWidthStyle = useStyle(() => ({
    maxWidth: isLargeDesktop ? 528 : match(['100%', '100%', 460, 750]),
  }));
  const containerStyle = [
    styles.container,
    containerMaxWidthStyle,
    minWidth.isMD && styles.containerDesktop,
  ];

  return <Container style={containerStyle}>{props.children}</Container>;
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    marginHorizontal: 'auto',
  },
  containerDesktop: {
    paddingHorizontal: 0,
  },
});

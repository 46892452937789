import { useCallback } from 'react';

import { useIsLoggedIn } from '@order/AuthMachine';
import { APPLE_PAY } from '@order/constants';

import {
  usePaymentProfileQuery,
  useSetDefaultPaymentMethodMutation,
} from '../graphql/PaymentProfile.generated';
import { useIsApplePaySupported } from './useIsApplePaySupported';

/**
 * Handling querying for `isApplePayDefault`,
 * And updating it with `updateDefaultPaymentMethodToApplePay`.
 * This is a direct link to the customer's payment profile "default_card_id".
 */

export function useApplePayPaymentMethod() {
  const isLoggedIn = useIsLoggedIn();
  const isApplePaySupported = useIsApplePaySupported();

  // ─── Graphql ─────────────────────────────────────────────────────────

  const [{ data, fetching: isFetching }] = usePaymentProfileQuery({
    pause: !isLoggedIn || !isApplePaySupported,
    requestPolicy: 'network-only',
  });

  const [{ fetching: isUpdating }, setDefaultPaymentMethod] =
    useSetDefaultPaymentMethodMutation();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const updateDefaultPaymentMethodToApplePay = useCallback(async () => {
    return setDefaultPaymentMethod({ paymentMethodId: APPLE_PAY });
  }, [setDefaultPaymentMethod]);

  const defaultId = data?.paymentProfile?.defaultCardId;

  // ─── Flags ───────────────────────────────────────────────────────────

  const isApplePayDefault = isApplePaySupported && defaultId === APPLE_PAY;

  return {
    isLoadingDefaultPaymentMethodId: isFetching,
    isUpdatingDefaultPaymentMethodId: isUpdating,
    isApplePayDefault,
    updateDefaultPaymentMethodToApplePay,
  };
}

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, Icon, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const DeliveryFeeDisclosure = (props: DiscloseDeliveryTipProps) => {
  const { text } = props;

  return (
    <View style={styles.container}>
      <View>
        <Icon
          name="IconBadgeInfo"
          width={16}
          height={16}
          color={theme.colors.TEXT_COLOR}
        />
      </View>

      <BodyText
        sizeMatch={['12']}
        style={styles.containerText}
        maxFontSizeMultiplier={1.6}
      >
        {text}
      </BodyText>
    </View>
  );
};

// ─── STYLES ──────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexShrink: 1,
    flexDirection: 'row',
    borderRadius: theme.radius.large,
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTEST,
    columnGap: theme.spacing['2'],
    paddingVertical: theme.spacing['2'],
    paddingHorizontal: theme.spacing['4'],
  },
  containerText: {
    flexGrow: 1,
    flexShrink: 1,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type DiscloseDeliveryTipProps = Readonly<{
  text: string;
}>;

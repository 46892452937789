/* eslint-disable @typescript-eslint/consistent-type-imports, @typescript-eslint/consistent-type-assertions */

import { createMachine } from 'xstate';
import { assign } from 'xstate/lib/actions';

import {
  OrdersMachineContext,
  OrdersMachineEvents,
  OrdersMachineServices,
  OrderWithIdAndWantedTime,
} from './orders-machine.types';
import {
  ordersMachineLogger,
  sortOrdersById,
  withoutDuplicateOrders,
} from './orders-machine.utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A state machine to manage the user's placed orders history with
 * pagination support.
 *
 * NOTE: The machine is also "restartable", which means it can be restarted
 *       at any point during its flow by resetting the context and fetching
 *       the initial orders.
 *
 * NOTE: The machine requires external services to retrieve orders and
 *       cancellation statuses.
 *
 * NOTE: The initial state can be passed to the machine as an argument, however,
 *       it is usually used for testing.
 */
export const createOrdersMachine = <Order extends OrderWithIdAndWantedTime>(
  initialState:
    | 'waiting'
    | 'idle'
    | 'fetchingInitialOrders'
    | 'fetchingMoreOrders' = 'waiting',
) => {
  return createMachine(
    {
      tsTypes: {} as import('./orders-machine.typegen').Typegen0,
      predictableActionArguments: true,
      preserveActionOrder: true,
      id: 'orders-state-machine',
      schema: {
        context: {} as OrdersMachineContext<Order>,
        events: {} as OrdersMachineEvents,
        services: {} as OrdersMachineServices<Order>,
      },
      context: {
        orders: [],
        ordersPerPage: 10,
        page: 1,
        canFetchMoreOrders: false,
      },
      initial: initialState,
      states: {
        waiting: {
          description: 'An empty state used for the initial setup.',
        },
        idle: {
          id: 'idle',
          description: 'An idle state that awaits the next event.',
          on: {
            REFETCH_ORDERS: {
              description: 'Refetch current orders.',
              target: '#refetching-orders',
            },
            FETCH_MORE_ORDERS: {
              description: 'Fetch more orders if possible.',
              target: '#fetching-more-orders',
              actions: ['increasePage'],
              cond: 'checkIfCanFetchMoreOrders',
            },
          },
        },
        fetchingInitialOrders: {
          id: 'fetching-initial-orders',
          initial: 'orders',
          states: {
            orders: {
              description: 'A state for fetching initial orders.',
              invoke: {
                src: 'fetchOrders',
                onDone: {
                  target: '#idle',
                  actions: ['onFetchOrdersDone'],
                },
              },
            },
          },
        },
        refetchingOrders: {
          id: 'refetching-orders',
          initial: 'orders',
          states: {
            orders: {
              description: 'A state for refetching current orders.',
              invoke: {
                src: 'fetchOrders',
                onDone: {
                  target: '#idle',
                  actions: ['onFetchOrdersDone'],
                },
              },
            },
          },
        },
        fetchingMoreOrders: {
          id: 'fetching-more-orders',
          initial: 'orders',
          states: {
            orders: {
              description: 'A state for fetching more orders.',
              invoke: {
                src: 'fetchOrders',
                onDone: {
                  target: '#idle',
                  actions: ['onFetchOrdersDone'],
                },
              },
            },
          },
        },
      },
      on: {
        START: {
          description:
            'Starts/restarts the machine by fetching the initial orders and clearing the state.',
          target: '#fetching-initial-orders',
          actions: ['resetContext'],
        },
      },
    },
    {
      actions: {
        /**
         * Stores the resolved orders in the context.
         */
        onFetchOrdersDone: assign({
          orders(context, event) {
            const { orders: existingOrders } = context;
            const { orders } = event.data;

            const updatedOrders = [...existingOrders, ...orders];

            return sortOrdersById(withoutDuplicateOrders(updatedOrders));
          },
          canFetchMoreOrders(context, event) {
            const { page } = context;
            const { lastPage } = event.data;

            return page < lastPage;
          },
        }),

        increasePage: assign({
          page: (context) => context.page + 1,
        }),

        /**
         * Resets the context of the machine.
         *
         * Can be used to clear the state before restarting the machine.
         */
        resetContext: assign({
          orders: [],
          page: 1,
          canFetchMoreOrders: false,
        }),
      },
      services: {
        async fetchOrders(_context) {
          ordersMachineLogger.debug('Please provide `fetchOrders` service.');

          return { orders: [], lastPage: 1 };
        },
      },
      guards: {
        checkIfCanFetchMoreOrders: (context) => context.canFetchMoreOrders,
      },
    },
  );
};

import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { COLORS, theme } from '@garnish/constants';

import { Icon } from '../Icon';
import { QRCodeGenerator } from './QRCodeGenerator';

type QRCodeProps = Readonly<{
  value: string;
}> &
  ViewProps;

/**
 * QRCode
 */
export const QRCode = (props: QRCodeProps) => {
  const { value, style, ...rest } = props;

  return (
    <View style={[styles.qrImageWrap, style]} {...rest}>
      <Icon
        name="IconHexagon"
        width="100%"
        height="100%"
        color={theme.colors.KALE}
      />

      <View
        testID="sg-qrcode"
        style={[StyleSheet.absoluteFill, styles.qrImageWrap]}
      >
        <View style={styles.sizeReduction}>
          <QRCodeGenerator
            value={value}
            color={COLORS.KHAKI_3}
            backgroundColor={COLORS.OPACITY.TRANSPARENT}
            style={styles.full}
          />
        </View>
      </View>
    </View>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  full: { width: '100%', height: '100%' },
  sizeReduction: {
    width: '55%',
    maxHeight: '55%', // max-height because if it's regular height it will be slightly off-v-center
  },
  qrImageWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '100%',
    maxWidth: '100%',
  },
});

// ────────────────────────────────────────────────────────────────────────────────

export const useWidthMeasurement = () => {
  const viewRef = React.useRef<View>(null);
  const [viewWidth, setViewWidth] = React.useState(0);

  const getViewMeasurement = React.useCallback(() => {
    viewRef.current?.measure((_x, _y, _width, height) => {
      const qrCodeWidth = height * 0.5;

      setViewWidth(qrCodeWidth);
    });
  }, [viewRef, setViewWidth]);

  return { viewRef, qrCodeWidth: viewWidth, getViewMeasurement };
};

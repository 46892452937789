import { useWindowDimensions } from 'react-native';

import type { CoordinatePair, LockRange } from './useFluidSize.types';
import {
  getLockRange,
  getPointSlopeFn,
  makeCoordinatePairFn,
  makeLockedWidthFn,
} from './utils';

export const useLockFn = (lockedWidth: number) => {
  return (getCoordinates: (min: number, max: number) => CoordinatePair) => {
    return (min: number, max: number) => {
      const coordinatePair = getCoordinates(min, max);

      return getPointSlopeFn(coordinatePair)(lockedWidth);
    };
  };
};

const useLockedWidth = (config: LockRange): number => {
  const { width } = useWindowDimensions();

  return makeLockedWidthFn(config)(width);
};

export const useFluidSize = (
  config?: LockRange,
): ((min: number, max: number) => number) => {
  const lockRangeConfig = getLockRange(config);
  const lockedWidthWindow = useLockedWidth(lockRangeConfig);
  const getCoordinates = makeCoordinatePairFn(lockRangeConfig);

  return useLockFn(lockedWidthWindow)(getCoordinates);
};

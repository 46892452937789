import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useResponsive } from '@sg/garnish';

import { type useCreditCardOptions } from '@order/hooks';

import {
  type GiftCardCheckoutFormFieldRules,
  type GiftCardCheckoutFormReturn,
} from '../../form';
import { GiftCardCheckoutFormContainer } from './components';
import {
  GiftCardCheckoutAmount,
  GiftCardCheckoutDeliveryDate,
  GiftCardCheckoutDeliveryMethod,
  GiftCardCheckoutDetails,
  GiftCardCheckoutLedger,
  GiftCardCheckoutPaymentMethod,
  GiftCardCheckoutSubmit,
} from './sections';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutForm = (props: GiftCardCheckoutFormProps) => {
  const { sectionTitles, form, rules, paymentMethods, submitForm } = props;

  const { currentBreakpoint } = useResponsive();

  const containerStyles = currentBreakpoint.isSM && styles.containerSM;

  return (
    <View style={containerStyles}>
      <GiftCardCheckoutFormContainer>
        <GiftCardCheckoutAmount
          sectionTitle={sectionTitles?.amount}
          form={form}
          rules={rules}
        />
        <GiftCardCheckoutDetails
          sectionTitle={sectionTitles?.details}
          form={form}
          rules={rules}
        />
        <GiftCardCheckoutDeliveryDate
          sectionTitle={sectionTitles?.deliveryDate}
          form={form}
          rules={rules}
        />
        <GiftCardCheckoutDeliveryMethod
          sectionTitle={sectionTitles?.deliveryMethod}
          sectionTitleEmailOnly={sectionTitles?.recipientEmail}
          form={form}
        />
        <GiftCardCheckoutPaymentMethod
          sectionTitle={sectionTitles?.paymentMethod}
          form={form}
          paymentMethods={paymentMethods}
        />
      </GiftCardCheckoutFormContainer>

      <GiftCardCheckoutLedger form={form} />
      <GiftCardCheckoutSubmit form={form} onPress={submitForm} />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerSM: {
    width: '100%',
    maxWidth: 600,
    alignSelf: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutFormProps = {
  form: GiftCardCheckoutFormReturn;
  rules: GiftCardCheckoutFormFieldRules;
  paymentMethods: ReturnType<typeof useCreditCardOptions>['creditCardOptions'];
  submitForm: () => Promise<void>;
  sectionTitles?: {
    amount: string;
    details: string;
    deliveryDate: string;
    deliveryMethod: string;
    recipientEmail: string;
    paymentMethod: string;
  };
};

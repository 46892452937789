/* cSpell:ignore libphonenumber */

import type { ComponentProps } from 'react';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import type { TextInput } from 'react-native';
import { Platform } from 'react-native';
import type { CountryCode } from 'libphonenumber-js';
import { getCountryCallingCode } from 'libphonenumber-js';

import { usePrevious } from '../../../../hooks/usePrevious';
import { TextField } from '../../TextField';
import {
  formatAndMinifyPhoneNumber,
  getCaretPositionAfterFormatting,
  parsePhoneNumber,
  validatePhoneNumber,
} from './TextFieldPhoneNumber.utils';

export const TextFieldPhoneNumber = (props: TextFieldPhoneNumberProps) => {
  const {
    value,
    defaultValue,
    onChangeText,
    delimiter = '-',
    countryCode = 'US',
    maxLength = Number.POSITIVE_INFINITY,
    ...restProps
  } = props;
  const [formattedValue, setFormattedValue] = useState(
    formatAndMinifyPhoneNumber(value ?? defaultValue ?? '', countryCode),
  );
  const { textFieldRef } = useTextFieldCaretPosition(value, formattedValue);
  const countryCallingCode = getCountryCallingCode(countryCode) as string;

  // ─── HELPERS ────────────────────────────────────────────────────────
  React.useEffect(() => {
    const formattedIncomingValue = formatAndMinifyPhoneNumber(
      value ?? '',
      countryCode,
    );

    if (
      formattedIncomingValue !== '' &&
      formattedIncomingValue !== formattedValue
    ) {
      setFormattedValue(formattedIncomingValue);
    }
  }, [countryCode, formattedValue, value]);

  const handleChangeText = useCallback(
    (phoneNumber: string) => {
      const phoneNumbers = parsePhoneNumber(phoneNumber, countryCode);

      setFormattedValue(phoneNumbers.formatted);
      onChangeText?.(phoneNumbers.parsed);
    },
    [countryCode, onChangeText],
  );

  const validateChangedText = useCallback(
    (phoneNumber: string) => {
      return validatePhoneNumber({
        phoneNumber,
        maxLength,
        delimiter,
        countryCallingCode,
      });
    },
    [countryCallingCode, delimiter, maxLength],
  );

  // ────────────────────────────────────────────────────────────────────

  return (
    <TextField
      ref={textFieldRef}
      value={formattedValue}
      onChangeText={handleChangeText}
      onChangeTextGuard={validateChangedText}
      autoCapitalize="none"
      autoCorrect={false}
      textContentType="telephoneNumber"
      keyboardType="phone-pad"
      {...restProps}
    />
  );
};

// ─── HOOKS ──────────────────────────────────────────────────────────────────────

const useTextFieldCaretPosition = (value = '', formattedValue = '') => {
  const textFieldRef = useRef<TextInput>(null);

  const prevValue = usePrevious(value) ?? '';
  const prevFormattedValue = usePrevious(formattedValue) ?? '';

  // ─── HELPERS ────────────────────────────────────────────────────────────────────

  const getTextFieldEl = useCallback(() => {
    return textFieldRef?.current as unknown as HTMLInputElement;
  }, []);

  const setCaretPosition = useCallback(
    (position: number) => {
      const textFieldEl = getTextFieldEl();

      if (!textFieldEl) return;

      window.requestAnimationFrame(() => {
        const isFocused = document.activeElement === textFieldEl;

        if (!isFocused) return;
        textFieldEl.setSelectionRange(position, position);
      });
    },
    [getTextFieldEl],
  );

  // ─── EFFECTS ────────────────────────────────────────────────────────

  useLayoutEffect(() => {
    const textFieldEl = getTextFieldEl();

    if (Platform.OS !== 'web' || !textFieldEl) return;

    const caretPosition = getCaretPositionAfterFormatting({
      textFieldEl,
      prevFormattedValue,
      formattedValue,
      prevValue,
      value,
    });

    setCaretPosition(caretPosition);
  }, [
    prevValue,
    value,
    formattedValue,
    prevFormattedValue,
    setCaretPosition,
    getTextFieldEl,
  ]);

  return { textFieldRef };
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type TextFieldPhoneNumberProps = Readonly<{
  delimiter?: string;
  countryCode?: CountryCode;
}> &
  Omit<
    ComponentProps<typeof TextField>,
    | 'onChangeTextGuard'
    | 'autoCapitalize'
    | 'autoCorrect'
    | 'textContentType'
    | 'keyboardType'
  >;

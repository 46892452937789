import { LoyaltyHomeBody } from './LoyaltyHomeBody';
import { LoyaltyHomeChallenges } from './LoyaltyHomeChallenges';
import { LoyaltyHomeChallengesEmptyState } from './LoyaltyHomeChallengesEmptyState/LoyaltyHomeChallengesEmptyState';
import { LoyaltyHomeContainer } from './LoyaltyHomeContainer';
import { LoyaltyHomeFooter } from './LoyaltyHomeFooter';
import { LoyaltyHomeHeader } from './LoyaltyHomeHeader';
import { LoyaltyHomeProgressBar } from './LoyaltyHomeProgressBar';
import { LoyaltyHomeProgressBenefit } from './LoyaltyHomeProgressBenefit';
import { LoyaltyHomeRewards } from './LoyaltyHomeRewards';
import { LoyaltyHomeRewardsEmptyState } from './LoyaltyHomeRewardsEmptyState';

export const LoyaltyHome = {
  Container: LoyaltyHomeContainer,
  Header: LoyaltyHomeHeader,
  Body: LoyaltyHomeBody,
  ProgressBenefit: LoyaltyHomeProgressBenefit,
  ProgressBar: LoyaltyHomeProgressBar,
  Footer: LoyaltyHomeFooter,
  Challenges: LoyaltyHomeChallenges,
  ChallengesEmptyState: LoyaltyHomeChallengesEmptyState,
  Rewards: LoyaltyHomeRewards,
  RewardsEmptyState: LoyaltyHomeRewardsEmptyState,
};

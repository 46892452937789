import { useMachine } from '@xstate/react';

import { LastInteractedStoreMachine } from './LastInteractedStore.machine';

// ────────────────────────────────────────────────────────────────────────────────

export const useInitLastInteractedStoreMachine = () =>
  useMachine(LastInteractedStoreMachine);

export type LastInteractedStoreMachineRefType = ReturnType<
  typeof useInitLastInteractedStoreMachine
>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import { ValidationErrorFieldsFragmentDoc } from '../../../graphql/fragments/ValidationErrorFields.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CartLedgerFragment = {
  readonly __typename?: 'Ledger';
  readonly tax: number;
  readonly subtotal: number;
  readonly feesTotal: number;
  readonly discountsTotal: number;
  readonly creditsTotal: number;
  readonly tip: number;
  readonly discounts: ReadonlyArray<{
    readonly __typename?: 'LedgerItem';
    readonly name: string;
    readonly amount: number;
    readonly description: string | null;
  }>;
  readonly credits: ReadonlyArray<{
    readonly __typename?: 'LedgerItem';
    readonly name: string;
    readonly amount: number;
    readonly description: string | null;
  }>;
  readonly fees: ReadonlyArray<{
    readonly __typename?: 'LedgerItem';
    readonly name: string;
    readonly amount: number;
    readonly description: string | null;
  }>;
};

export type CartLineItemFragment = {
  readonly __typename?: 'LineItem';
  readonly id: string;
  readonly slug: string;
  readonly quantity: number;
  readonly cost: number;
  readonly customName: string | null;
  readonly perItemCost: number;
  readonly favorited: boolean;
  readonly isCustom: boolean;
  readonly dressingMode: Types.DressingMode;
  readonly addedIngredients: ReadonlyArray<{
    readonly __typename?: 'Ingredient';
    readonly id: string;
    readonly name: string;
    readonly kind: Types.IngredientKind | null;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  }>;
  readonly removedIngredients: ReadonlyArray<{
    readonly __typename?: 'Ingredient';
    readonly id: string;
    readonly name: string;
    readonly kind: Types.IngredientKind | null;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  }>;
  readonly product: {
    readonly __typename?: 'Product';
    readonly id: string;
    readonly name: string;
    readonly slug: string;
    readonly isCustom: boolean;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    readonly ingredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly kind: Types.IngredientKind | null;
    }>;
  };
  readonly mixedDressingDetails: ReadonlyArray<{
    readonly __typename?: 'MixedDressingDetails';
    readonly ingredientId: string;
    readonly weight: Types.DressingWeight;
  }>;
};

export type CartRestaurantFragment = {
  readonly __typename?: 'Restaurant';
  readonly id: string;
  readonly slug: string;
  readonly name: string;
  readonly city: string;
  readonly state: string;
  readonly address: string;
  readonly zipCode: string;
  readonly isOutpost: boolean;
  readonly deliveryMinSubtotal: number;
  readonly showDeliveryFeeDisclosure: boolean;
  readonly deliveryFee: number;
  readonly availableDropOffLocations: ReadonlyArray<{
    readonly __typename?: 'DropOffLocation';
    readonly id: string;
    readonly name: string;
  }>;
  readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
};

export type AvailableWantedTimeFragment = {
  readonly __typename?: 'AvailableWantedTime';
  readonly time: string;
  readonly deliveryOffset: number;
};

export type DeliveryOrderDetailFragment = {
  readonly __typename?: 'DeliveryOrderDetail';
  readonly id: string;
  readonly tip: number;
  readonly deliveryFee: number;
  readonly vendor: string;
  readonly orderId: string;
  readonly vendorRestaurantId: string;
  readonly estimatedDeliveryTime: string | null;
  readonly address: {
    readonly __typename?: 'Address';
    readonly id: string;
    readonly street: string;
    readonly secondaryStreet: string | null;
    readonly city: string;
    readonly state: string;
    readonly country: string;
    readonly zipCode: string;
    readonly deliveryPreference: Types.DeliveryPreferenceType;
    readonly googlePlaceId: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly name: string | null;
    readonly notes: string | null;
  } | null;
};

export type CartDataFragment = {
  readonly __typename?: 'Order';
  readonly id: string;
  readonly orderType: Types.OrderType;
  readonly canTrackOrderStatus: boolean;
  readonly ledger: {
    readonly __typename?: 'Ledger';
    readonly tax: number;
    readonly subtotal: number;
    readonly feesTotal: number;
    readonly discountsTotal: number;
    readonly creditsTotal: number;
    readonly tip: number;
    readonly discounts: ReadonlyArray<{
      readonly __typename?: 'LedgerItem';
      readonly name: string;
      readonly amount: number;
      readonly description: string | null;
    }>;
    readonly credits: ReadonlyArray<{
      readonly __typename?: 'LedgerItem';
      readonly name: string;
      readonly amount: number;
      readonly description: string | null;
    }>;
    readonly fees: ReadonlyArray<{
      readonly __typename?: 'LedgerItem';
      readonly name: string;
      readonly amount: number;
      readonly description: string | null;
    }>;
  };
  readonly lineItems: ReadonlyArray<{
    readonly __typename?: 'LineItem';
    readonly id: string;
    readonly slug: string;
    readonly quantity: number;
    readonly cost: number;
    readonly customName: string | null;
    readonly perItemCost: number;
    readonly favorited: boolean;
    readonly isCustom: boolean;
    readonly dressingMode: Types.DressingMode;
    readonly addedIngredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly kind: Types.IngredientKind | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    }>;
    readonly removedIngredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly kind: Types.IngredientKind | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    }>;
    readonly product: {
      readonly __typename?: 'Product';
      readonly id: string;
      readonly name: string;
      readonly slug: string;
      readonly isCustom: boolean;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly ingredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: Types.IngredientKind | null;
      }>;
    };
    readonly mixedDressingDetails: ReadonlyArray<{
      readonly __typename?: 'MixedDressingDetails';
      readonly ingredientId: string;
      readonly weight: Types.DressingWeight;
    }>;
  }>;
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
    readonly slug: string;
    readonly name: string;
    readonly city: string;
    readonly state: string;
    readonly address: string;
    readonly zipCode: string;
    readonly isOutpost: boolean;
    readonly deliveryMinSubtotal: number;
    readonly showDeliveryFeeDisclosure: boolean;
    readonly deliveryFee: number;
    readonly availableDropOffLocations: ReadonlyArray<{
      readonly __typename?: 'DropOffLocation';
      readonly id: string;
      readonly name: string;
    }>;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  };
  readonly availableWantedTimes: ReadonlyArray<{
    readonly __typename?: 'AvailableWantedTime';
    readonly time: string;
    readonly deliveryOffset: number;
  }>;
  readonly deliveryOrderDetail: {
    readonly __typename?: 'DeliveryOrderDetail';
    readonly id: string;
    readonly tip: number;
    readonly deliveryFee: number;
    readonly vendor: string;
    readonly orderId: string;
    readonly vendorRestaurantId: string;
    readonly estimatedDeliveryTime: string | null;
    readonly address: {
      readonly __typename?: 'Address';
      readonly id: string;
      readonly street: string;
      readonly secondaryStreet: string | null;
      readonly city: string;
      readonly state: string;
      readonly country: string;
      readonly zipCode: string;
      readonly deliveryPreference: Types.DeliveryPreferenceType;
      readonly googlePlaceId: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly name: string | null;
      readonly notes: string | null;
    } | null;
  } | null;
};

export type CartQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CartQuery = {
  readonly __typename?: 'Query';
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly orderType: Types.OrderType;
    readonly canTrackOrderStatus: boolean;
    readonly ledger: {
      readonly __typename?: 'Ledger';
      readonly tax: number;
      readonly subtotal: number;
      readonly feesTotal: number;
      readonly discountsTotal: number;
      readonly creditsTotal: number;
      readonly tip: number;
      readonly discounts: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly credits: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly fees: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
    };
    readonly lineItems: ReadonlyArray<{
      readonly __typename?: 'LineItem';
      readonly id: string;
      readonly slug: string;
      readonly quantity: number;
      readonly cost: number;
      readonly customName: string | null;
      readonly perItemCost: number;
      readonly favorited: boolean;
      readonly isCustom: boolean;
      readonly dressingMode: Types.DressingMode;
      readonly addedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: Types.IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly removedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: Types.IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly product: {
        readonly __typename?: 'Product';
        readonly id: string;
        readonly name: string;
        readonly slug: string;
        readonly isCustom: boolean;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
        readonly ingredients: ReadonlyArray<{
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly kind: Types.IngredientKind | null;
        }>;
      };
      readonly mixedDressingDetails: ReadonlyArray<{
        readonly __typename?: 'MixedDressingDetails';
        readonly ingredientId: string;
        readonly weight: Types.DressingWeight;
      }>;
    }>;
    readonly restaurant: {
      readonly __typename?: 'Restaurant';
      readonly id: string;
      readonly slug: string;
      readonly name: string;
      readonly city: string;
      readonly state: string;
      readonly address: string;
      readonly zipCode: string;
      readonly isOutpost: boolean;
      readonly deliveryMinSubtotal: number;
      readonly showDeliveryFeeDisclosure: boolean;
      readonly deliveryFee: number;
      readonly availableDropOffLocations: ReadonlyArray<{
        readonly __typename?: 'DropOffLocation';
        readonly id: string;
        readonly name: string;
      }>;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    };
    readonly availableWantedTimes: ReadonlyArray<{
      readonly __typename?: 'AvailableWantedTime';
      readonly time: string;
      readonly deliveryOffset: number;
    }>;
    readonly deliveryOrderDetail: {
      readonly __typename?: 'DeliveryOrderDetail';
      readonly id: string;
      readonly tip: number;
      readonly deliveryFee: number;
      readonly vendor: string;
      readonly orderId: string;
      readonly vendorRestaurantId: string;
      readonly estimatedDeliveryTime: string | null;
      readonly address: {
        readonly __typename?: 'Address';
        readonly id: string;
        readonly street: string;
        readonly secondaryStreet: string | null;
        readonly city: string;
        readonly state: string;
        readonly country: string;
        readonly zipCode: string;
        readonly deliveryPreference: Types.DeliveryPreferenceType;
        readonly googlePlaceId: string;
        readonly latitude: number;
        readonly longitude: number;
        readonly name: string | null;
        readonly notes: string | null;
      } | null;
    } | null;
  } | null;
};

export type AddLineItemToCartSuccessFieldsFragment = {
  readonly __typename?: 'AddLineItemToCartSuccess';
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly orderType: Types.OrderType;
    readonly canTrackOrderStatus: boolean;
    readonly ledger: {
      readonly __typename?: 'Ledger';
      readonly tax: number;
      readonly subtotal: number;
      readonly feesTotal: number;
      readonly discountsTotal: number;
      readonly creditsTotal: number;
      readonly tip: number;
      readonly discounts: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly credits: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly fees: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
    };
    readonly lineItems: ReadonlyArray<{
      readonly __typename?: 'LineItem';
      readonly id: string;
      readonly slug: string;
      readonly quantity: number;
      readonly cost: number;
      readonly customName: string | null;
      readonly perItemCost: number;
      readonly favorited: boolean;
      readonly isCustom: boolean;
      readonly dressingMode: Types.DressingMode;
      readonly addedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: Types.IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly removedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: Types.IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly product: {
        readonly __typename?: 'Product';
        readonly id: string;
        readonly name: string;
        readonly slug: string;
        readonly isCustom: boolean;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
        readonly ingredients: ReadonlyArray<{
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly kind: Types.IngredientKind | null;
        }>;
      };
      readonly mixedDressingDetails: ReadonlyArray<{
        readonly __typename?: 'MixedDressingDetails';
        readonly ingredientId: string;
        readonly weight: Types.DressingWeight;
      }>;
    }>;
    readonly restaurant: {
      readonly __typename?: 'Restaurant';
      readonly id: string;
      readonly slug: string;
      readonly name: string;
      readonly city: string;
      readonly state: string;
      readonly address: string;
      readonly zipCode: string;
      readonly isOutpost: boolean;
      readonly deliveryMinSubtotal: number;
      readonly showDeliveryFeeDisclosure: boolean;
      readonly deliveryFee: number;
      readonly availableDropOffLocations: ReadonlyArray<{
        readonly __typename?: 'DropOffLocation';
        readonly id: string;
        readonly name: string;
      }>;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    };
    readonly availableWantedTimes: ReadonlyArray<{
      readonly __typename?: 'AvailableWantedTime';
      readonly time: string;
      readonly deliveryOffset: number;
    }>;
    readonly deliveryOrderDetail: {
      readonly __typename?: 'DeliveryOrderDetail';
      readonly id: string;
      readonly tip: number;
      readonly deliveryFee: number;
      readonly vendor: string;
      readonly orderId: string;
      readonly vendorRestaurantId: string;
      readonly estimatedDeliveryTime: string | null;
      readonly address: {
        readonly __typename?: 'Address';
        readonly id: string;
        readonly street: string;
        readonly secondaryStreet: string | null;
        readonly city: string;
        readonly state: string;
        readonly country: string;
        readonly zipCode: string;
        readonly deliveryPreference: Types.DeliveryPreferenceType;
        readonly googlePlaceId: string;
        readonly latitude: number;
        readonly longitude: number;
        readonly name: string | null;
        readonly notes: string | null;
      } | null;
    } | null;
  };
};

export type EditLineItemInCartSuccessFieldsFragment = {
  readonly __typename?: 'EditLineItemInCartSuccess';
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly orderType: Types.OrderType;
    readonly canTrackOrderStatus: boolean;
    readonly ledger: {
      readonly __typename?: 'Ledger';
      readonly tax: number;
      readonly subtotal: number;
      readonly feesTotal: number;
      readonly discountsTotal: number;
      readonly creditsTotal: number;
      readonly tip: number;
      readonly discounts: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly credits: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly fees: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
    };
    readonly lineItems: ReadonlyArray<{
      readonly __typename?: 'LineItem';
      readonly id: string;
      readonly slug: string;
      readonly quantity: number;
      readonly cost: number;
      readonly customName: string | null;
      readonly perItemCost: number;
      readonly favorited: boolean;
      readonly isCustom: boolean;
      readonly dressingMode: Types.DressingMode;
      readonly addedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: Types.IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly removedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: Types.IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly product: {
        readonly __typename?: 'Product';
        readonly id: string;
        readonly name: string;
        readonly slug: string;
        readonly isCustom: boolean;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
        readonly ingredients: ReadonlyArray<{
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly kind: Types.IngredientKind | null;
        }>;
      };
      readonly mixedDressingDetails: ReadonlyArray<{
        readonly __typename?: 'MixedDressingDetails';
        readonly ingredientId: string;
        readonly weight: Types.DressingWeight;
      }>;
    }>;
    readonly restaurant: {
      readonly __typename?: 'Restaurant';
      readonly id: string;
      readonly slug: string;
      readonly name: string;
      readonly city: string;
      readonly state: string;
      readonly address: string;
      readonly zipCode: string;
      readonly isOutpost: boolean;
      readonly deliveryMinSubtotal: number;
      readonly showDeliveryFeeDisclosure: boolean;
      readonly deliveryFee: number;
      readonly availableDropOffLocations: ReadonlyArray<{
        readonly __typename?: 'DropOffLocation';
        readonly id: string;
        readonly name: string;
      }>;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    };
    readonly availableWantedTimes: ReadonlyArray<{
      readonly __typename?: 'AvailableWantedTime';
      readonly time: string;
      readonly deliveryOffset: number;
    }>;
    readonly deliveryOrderDetail: {
      readonly __typename?: 'DeliveryOrderDetail';
      readonly id: string;
      readonly tip: number;
      readonly deliveryFee: number;
      readonly vendor: string;
      readonly orderId: string;
      readonly vendorRestaurantId: string;
      readonly estimatedDeliveryTime: string | null;
      readonly address: {
        readonly __typename?: 'Address';
        readonly id: string;
        readonly street: string;
        readonly secondaryStreet: string | null;
        readonly city: string;
        readonly state: string;
        readonly country: string;
        readonly zipCode: string;
        readonly deliveryPreference: Types.DeliveryPreferenceType;
        readonly googlePlaceId: string;
        readonly latitude: number;
        readonly longitude: number;
        readonly name: string | null;
        readonly notes: string | null;
      } | null;
    } | null;
  };
};

export type RemoveFromCartSuccessFieldsFragment = {
  readonly __typename?: 'RemoveFromCartSuccess';
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly orderType: Types.OrderType;
    readonly canTrackOrderStatus: boolean;
    readonly ledger: {
      readonly __typename?: 'Ledger';
      readonly tax: number;
      readonly subtotal: number;
      readonly feesTotal: number;
      readonly discountsTotal: number;
      readonly creditsTotal: number;
      readonly tip: number;
      readonly discounts: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly credits: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly fees: ReadonlyArray<{
        readonly __typename?: 'LedgerItem';
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
    };
    readonly lineItems: ReadonlyArray<{
      readonly __typename?: 'LineItem';
      readonly id: string;
      readonly slug: string;
      readonly quantity: number;
      readonly cost: number;
      readonly customName: string | null;
      readonly perItemCost: number;
      readonly favorited: boolean;
      readonly isCustom: boolean;
      readonly dressingMode: Types.DressingMode;
      readonly addedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: Types.IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly removedIngredients: ReadonlyArray<{
        readonly __typename?: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: Types.IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly product: {
        readonly __typename?: 'Product';
        readonly id: string;
        readonly name: string;
        readonly slug: string;
        readonly isCustom: boolean;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
        readonly ingredients: ReadonlyArray<{
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly kind: Types.IngredientKind | null;
        }>;
      };
      readonly mixedDressingDetails: ReadonlyArray<{
        readonly __typename?: 'MixedDressingDetails';
        readonly ingredientId: string;
        readonly weight: Types.DressingWeight;
      }>;
    }>;
    readonly restaurant: {
      readonly __typename?: 'Restaurant';
      readonly id: string;
      readonly slug: string;
      readonly name: string;
      readonly city: string;
      readonly state: string;
      readonly address: string;
      readonly zipCode: string;
      readonly isOutpost: boolean;
      readonly deliveryMinSubtotal: number;
      readonly showDeliveryFeeDisclosure: boolean;
      readonly deliveryFee: number;
      readonly availableDropOffLocations: ReadonlyArray<{
        readonly __typename?: 'DropOffLocation';
        readonly id: string;
        readonly name: string;
      }>;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    };
    readonly availableWantedTimes: ReadonlyArray<{
      readonly __typename?: 'AvailableWantedTime';
      readonly time: string;
      readonly deliveryOffset: number;
    }>;
    readonly deliveryOrderDetail: {
      readonly __typename?: 'DeliveryOrderDetail';
      readonly id: string;
      readonly tip: number;
      readonly deliveryFee: number;
      readonly vendor: string;
      readonly orderId: string;
      readonly vendorRestaurantId: string;
      readonly estimatedDeliveryTime: string | null;
      readonly address: {
        readonly __typename?: 'Address';
        readonly id: string;
        readonly street: string;
        readonly secondaryStreet: string | null;
        readonly city: string;
        readonly state: string;
        readonly country: string;
        readonly zipCode: string;
        readonly deliveryPreference: Types.DeliveryPreferenceType;
        readonly googlePlaceId: string;
        readonly latitude: number;
        readonly longitude: number;
        readonly name: string | null;
        readonly notes: string | null;
      } | null;
    } | null;
  };
};

export type RestaurantMaxQuantityExceededFieldsFragment = {
  readonly __typename?: 'RestaurantMaxQuantityExceeded';
  readonly message: string;
  readonly status: number;
};

export type MaxModificationsExceededFieldsFragment = {
  readonly __typename?: 'MaxModificationsExceeded';
  readonly message: string;
  readonly status: number;
};

export type RestaurantMaxDeliveryQuantityExceededFieldsFragment = {
  readonly __typename?: 'RestaurantMaxDeliveryQuantityExceeded';
  readonly message: string;
  readonly status: number;
};

export type ProductOutOfStockFieldsFragment = {
  readonly __typename?: 'ProductOutOfStock';
  readonly message: string;
  readonly status: number;
};

export type AddLineItemToCartMutationVariables = Types.Exact<{
  input: Types.AddLineItemToCartInput;
}>;

export type AddLineItemToCartMutation = {
  readonly __typename?: 'Mutation';
  readonly addLineItemToCart:
    | {
        readonly __typename: 'AddLineItemToCartSuccess';
        readonly cart: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly canTrackOrderStatus: boolean;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: Types.DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: Types.IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: Types.DressingWeight;
            }>;
          }>;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename?: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: Types.DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | { readonly __typename: 'LineItemModificationsInvalidException' }
    | {
        readonly __typename: 'MaxModificationsExceeded';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'ProductOutOfStock';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RestaurantMaxDeliveryQuantityExceeded';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RestaurantMaxQuantityExceeded';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type EditLineItemInCartMutationVariables = Types.Exact<{
  input: Types.EditLineItemInCartInput;
}>;

export type EditLineItemInCartMutation = {
  readonly __typename?: 'Mutation';
  readonly editLineItemInCart:
    | {
        readonly __typename: 'EditLineItemInCartSuccess';
        readonly cart: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly canTrackOrderStatus: boolean;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: Types.DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: Types.IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: Types.DressingWeight;
            }>;
          }>;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename?: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: Types.DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | {
        readonly __typename: 'MaxModificationsExceeded';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RestaurantMaxDeliveryQuantityExceeded';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RestaurantMaxQuantityExceeded';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type RemoveFromCartMutationVariables = Types.Exact<{
  input: Types.RemoveFromCartInput;
}>;

export type RemoveFromCartMutation = {
  readonly __typename?: 'Mutation';
  readonly removeFromCart:
    | {
        readonly __typename: 'RemoveFromCartSuccess';
        readonly cart: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly canTrackOrderStatus: boolean;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: Types.DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: Types.IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: Types.DressingWeight;
            }>;
          }>;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename?: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: Types.DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export const CartLedgerFragmentDoc = gql`
  fragment CartLedger on Ledger {
    tax
    subtotal
    feesTotal
    discountsTotal
    creditsTotal
    tip
    discounts {
      name
      amount
      description
    }
    credits {
      name
      amount
      description
    }
    fees {
      name
      amount
      description
    }
  }
`;
export const CartLineItemFragmentDoc = gql`
  fragment CartLineItem on LineItem {
    id
    slug
    quantity
    cost
    customName
    perItemCost
    favorited
    isCustom
    addedIngredients {
      id
      name
      kind
      asset {
        url
      }
    }
    removedIngredients {
      id
      name
      kind
      asset {
        url
      }
    }
    product {
      id
      name
      slug
      isCustom
      asset {
        url
      }
      ingredients {
        id
        name
        kind
      }
    }
    mixedDressingDetails {
      ingredientId
      weight
    }
    dressingMode
  }
`;
export const CartRestaurantFragmentDoc = gql`
  fragment CartRestaurant on Restaurant {
    id
    slug
    name
    city
    state
    address
    zipCode
    isOutpost
    deliveryMinSubtotal
    isOutpost
    showDeliveryFeeDisclosure
    deliveryFee
    availableDropOffLocations {
      id
      name
    }
    asset {
      url
    }
  }
`;
export const AvailableWantedTimeFragmentDoc = gql`
  fragment AvailableWantedTime on AvailableWantedTime {
    time
    deliveryOffset
  }
`;
export const DeliveryOrderDetailFragmentDoc = gql`
  fragment DeliveryOrderDetail on DeliveryOrderDetail {
    id
    tip
    deliveryFee
    vendor
    orderId
    vendorRestaurantId
    estimatedDeliveryTime
    address {
      id
      street
      secondaryStreet
      city
      state
      country
      zipCode
      deliveryPreference
      googlePlaceId
      latitude
      longitude
      name
      notes
    }
  }
`;
export const CartDataFragmentDoc = gql`
  fragment CartData on Order {
    id
    orderType
    canTrackOrderStatus
    ledger {
      ...CartLedger
    }
    lineItems {
      ...CartLineItem
    }
    restaurant {
      ...CartRestaurant
    }
    availableWantedTimes {
      ...AvailableWantedTime
    }
    deliveryOrderDetail {
      ...DeliveryOrderDetail
    }
  }
  ${CartLedgerFragmentDoc}
  ${CartLineItemFragmentDoc}
  ${CartRestaurantFragmentDoc}
  ${AvailableWantedTimeFragmentDoc}
  ${DeliveryOrderDetailFragmentDoc}
`;
export const AddLineItemToCartSuccessFieldsFragmentDoc = gql`
  fragment AddLineItemToCartSuccessFields on AddLineItemToCartSuccess {
    cart {
      ...CartData
    }
  }
  ${CartDataFragmentDoc}
`;
export const EditLineItemInCartSuccessFieldsFragmentDoc = gql`
  fragment EditLineItemInCartSuccessFields on EditLineItemInCartSuccess {
    cart {
      ...CartData
    }
  }
  ${CartDataFragmentDoc}
`;
export const RemoveFromCartSuccessFieldsFragmentDoc = gql`
  fragment RemoveFromCartSuccessFields on RemoveFromCartSuccess {
    cart {
      ...CartData
    }
  }
  ${CartDataFragmentDoc}
`;
export const RestaurantMaxQuantityExceededFieldsFragmentDoc = gql`
  fragment RestaurantMaxQuantityExceededFields on RestaurantMaxQuantityExceeded {
    message
    status
  }
`;
export const MaxModificationsExceededFieldsFragmentDoc = gql`
  fragment MaxModificationsExceededFields on MaxModificationsExceeded {
    message
    status
  }
`;
export const RestaurantMaxDeliveryQuantityExceededFieldsFragmentDoc = gql`
  fragment RestaurantMaxDeliveryQuantityExceededFields on RestaurantMaxDeliveryQuantityExceeded {
    message
    status
  }
`;
export const ProductOutOfStockFieldsFragmentDoc = gql`
  fragment ProductOutOfStockFields on ProductOutOfStock {
    message
    status
  }
`;
export const CartDocument = gql`
  query Cart {
    cart {
      ...CartData
    }
  }
  ${CartDataFragmentDoc}
`;

export function useCartQuery(
  options?: Omit<Urql.UseQueryArgs<CartQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CartQuery, CartQueryVariables>({
    query: CartDocument,
    ...options,
  });
}
export const AddLineItemToCartDocument = gql`
  mutation AddLineItemToCart($input: AddLineItemToCartInput!) {
    addLineItemToCart(input: $input) {
      __typename
      ...AddLineItemToCartSuccessFields
      ...RestaurantMaxQuantityExceededFields
      ...RestaurantMaxDeliveryQuantityExceededFields
      ...MaxModificationsExceededFields
      ...ValidationErrorFields
      ...ProductOutOfStockFields
    }
  }
  ${AddLineItemToCartSuccessFieldsFragmentDoc}
  ${RestaurantMaxQuantityExceededFieldsFragmentDoc}
  ${RestaurantMaxDeliveryQuantityExceededFieldsFragmentDoc}
  ${MaxModificationsExceededFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
  ${ProductOutOfStockFieldsFragmentDoc}
`;

export function useAddLineItemToCartMutation() {
  return Urql.useMutation<
    AddLineItemToCartMutation,
    AddLineItemToCartMutationVariables
  >(AddLineItemToCartDocument);
}
export const EditLineItemInCartDocument = gql`
  mutation EditLineItemInCart($input: EditLineItemInCartInput!) {
    editLineItemInCart(input: $input) {
      __typename
      ...EditLineItemInCartSuccessFields
      ...RestaurantMaxQuantityExceededFields
      ...RestaurantMaxDeliveryQuantityExceededFields
      ...MaxModificationsExceededFields
      ...ValidationErrorFields
    }
  }
  ${EditLineItemInCartSuccessFieldsFragmentDoc}
  ${RestaurantMaxQuantityExceededFieldsFragmentDoc}
  ${RestaurantMaxDeliveryQuantityExceededFieldsFragmentDoc}
  ${MaxModificationsExceededFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useEditLineItemInCartMutation() {
  return Urql.useMutation<
    EditLineItemInCartMutation,
    EditLineItemInCartMutationVariables
  >(EditLineItemInCartDocument);
}
export const RemoveFromCartDocument = gql`
  mutation RemoveFromCart($input: RemoveFromCartInput!) {
    removeFromCart(input: $input) {
      __typename
      ...RemoveFromCartSuccessFields
      ...ValidationErrorFields
    }
  }
  ${RemoveFromCartSuccessFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useRemoveFromCartMutation() {
  return Urql.useMutation<
    RemoveFromCartMutation,
    RemoveFromCartMutationVariables
  >(RemoveFromCartDocument);
}

import { useCallback } from 'react';

import { useBrowserEventListener } from '../useBrowserEventListener';

// Triggers {onKeyPress} when the {key} key has been pressed in web.
export const useWebKeyListener = (
  key: string,
  onKeyPress: () => void,
  skip?: boolean,
) => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key) onKeyPress();
    },
    [key, onKeyPress],
  );

  useBrowserEventListener(window, 'keydown', onKeyDown, {}, skip);
};

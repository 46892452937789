import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardLabel = (props: LoyaltyOfferCardLabelProps) => {
  const { style, ...restProps } = props;

  return (
    <BodyText style={[styles.text, style]} sizeMatch={['18']} {...restProps} />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    color: theme.colors.SWEET_CORN,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardLabelProps = ComponentProps<typeof BodyText>;

import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { type RouteProp, useRoute } from '@react-navigation/native';
import { theme, useResponsive } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import {
  AccountPaymentGiftCardLoading,
  AccountScreenContainer,
  AccountScreenTitle,
  GiftCardAccountSection,
  PaymentAccountSection,
  SignedOutView,
  useRedeemGiftCardDefaultValues,
} from '@order/components';
import { usePaymentMethods } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

import type { AccountTabStackParamList } from '../../navigation/AppNavigation.props';

// ─────────────────────────────────────────────────────────────────────────────

export const PaymentAndGiftCardsScreen = () => {
  const { t } = useLocalizationContext();
  const { match } = useResponsive();
  const { paymentMethods, isLoadingPaymentMethods } = usePaymentMethods();

  const { params } =
    useRoute<RouteProp<AccountTabStackParamList, 'PaymentAndGiftCards'>>();
  const giftCardRedemptionEncodedData = params?.giftCardId;

  const defaultRedemptionValues = useRedeemGiftCardDefaultValues({
    giftCardRedemptionEncodedData,
  });

  // ─── Derived Data ────────────────────────────────────────────────────

  const redirectToParams = useMemo(
    () => ({ redirectToGiftCardId: params?.giftCardId }),
    [params?.giftCardId],
  );

  // ─── Flags ───────────────────────────────────────────────────────────

  const isLoggedIn = useIsLoggedIn();

  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );

  // ─────────────────────────────────────────────────────────────────────

  if (!isLoggedIn) {
    return (
      <SignedOutView
        redirectTo="payment-and-gift-cards"
        redirectToParams={redirectToParams}
        withoutFooter
      />
    );
  }

  if (isLoadingPaymentMethods) {
    return <AccountPaymentGiftCardLoading />;
  }

  return (
    <AccountScreenContainer screen="payment-and-gift-cards">
      <AccountScreenTitle
        visibility="COMPACT"
        title={
          isInAppGiftCardEnabled
            ? t('account.payments.title')
            : t('account.payment-and-gift-cards.title')
        }
      />

      <View style={match([styles.paymentSectionContainerXS, undefined])}>
        <PaymentAccountSection paymentMethods={paymentMethods} />
      </View>

      <GiftCardAccountSection
        giftCardCode={defaultRedemptionValues?.giftCardCode}
        giftCardRegistrationCode={
          defaultRedemptionValues?.giftCardRegistrationCode
        }
      />
    </AccountScreenContainer>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  paymentSectionContainerXS: {
    marginBottom: theme.spacing['10'],
  },
});

import type { ComponentProps } from 'react';
import React, { useLayoutEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { useNavigation } from '@react-navigation/native';
import {
  Button,
  Container,
  ScrollViewWithHeaderTitle,
  theme,
  useResponsive,
} from '@sg/garnish';

import { AppFooter } from '../AppFooter';
import { SweetpassLandingPageBenefits } from './components/SweetpassLandingPageBenefits';
import { SweetpassLandingPageHeader } from './components/SweetpassLandingPageHeader';

// ─────────────────────────────────────────────────────────────────────────────

export const SweetpassLandingPage = (props: SweetpassLandingPageProps) => {
  const {
    title,
    ctaContent,
    headerContent,
    benefitsContent,
    withTopPadding,
    withSafeAreaInset = true,
  } = props;

  const navigation = useNavigation();
  const { currentBreakpoint, minWidth, match } = useResponsive();
  const { bottom: bottomSafeAreaInset } = useSafeAreaInsets();

  // ─── Styles ──────────────────────────────────────────────────────────

  const headerBgColor = headerContent?.palette
    ? HEADER_BG_COLOR[headerContent.palette] ?? DEFAULT_HEADER_BG_COLOR
    : DEFAULT_HEADER_BG_COLOR;
  const headerTintColor = headerContent?.palette
    ? HEADER_TINT_COLOR[headerContent.palette] ?? DEFAULT_HEADER_TINT_COLOR
    : DEFAULT_HEADER_TINT_COLOR;
  const ctaPalette = ctaContent?.palette
    ? CTA_PALETTE[ctaContent.palette] ?? DEFAULT_CTA_PALETTE
    : DEFAULT_CTA_PALETTE;

  const ctaContainerResponsiveStyles = match([
    styles.ctaContainerXS,
    styles.ctaContainerSM,
  ]);
  const ctaContainerStyles = [
    styles.ctaContainer,
    ctaContainerResponsiveStyles,
  ];

  const shouldUseSafeAreaInset = bottomSafeAreaInset > 0 && withSafeAreaInset;
  const stickyCtaContainerPaddingStyles = useStyle(
    () => ({
      paddingBottom: shouldUseSafeAreaInset
        ? bottomSafeAreaInset
        : theme.spacing['4'],
    }),
    [shouldUseSafeAreaInset, bottomSafeAreaInset],
  );
  const stickyCtaContainerStyles = [
    styles.stickyCtaContainer,
    stickyCtaContainerPaddingStyles,
  ];

  // ─── Flags ───────────────────────────────────────────────────────────

  const hasHeaderContent = headerContent !== undefined;
  const hasBenefitsContent = benefitsContent !== undefined;
  const hasCtaContent = ctaContent !== undefined;

  const shouldRenderCta = !currentBreakpoint.isXS && hasCtaContent;
  const shouldRenderStickyCta = currentBreakpoint.isXS && hasCtaContent;
  const shouldRenderAppFooter = minWidth.isSM;

  // ─── Effects ─────────────────────────────────────────────────────────

  useLayoutEffect(() => {
    navigation.setOptions({
      headerStyle: { backgroundColor: headerBgColor },
      headerTintColor,
      title,
    });
  }, [headerBgColor, headerTintColor, navigation, title]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <ScrollViewWithHeaderTitle
        borderBottomColor={theme.colors.KALE}
        contentContainerStyle={styles.contentContainer}
        bounces={false}
      >
        {hasHeaderContent ? (
          <SweetpassLandingPageHeader
            palette={headerContent.palette}
            title={match([headerContent.titleXs, headerContent.title])}
            text={headerContent.text}
            imgUrl={headerContent.imgUrl}
            imgA11yLabel={headerContent.imgA11yLabel}
            withTopPadding={withTopPadding}
            ctaLabel={headerContent?.ctaLabel}
            onCtaPress={headerContent?.onCtaPress}
          />
        ) : null}

        {hasBenefitsContent ? (
          <SweetpassLandingPageBenefits
            benefits={benefitsContent.benefits}
            itemsPerRowMD={benefitsContent.itemsPerRowMD}
          />
        ) : null}

        {shouldRenderCta ? (
          <View style={ctaContainerStyles}>
            <Button
              palette={ctaPalette}
              onPress={ctaContent.onPress}
              size="large-wide"
              style={styles.cta}
              testID="sweetpass-landing-page.header.cta"
            >
              {ctaContent.label}
            </Button>
          </View>
        ) : null}

        {shouldRenderAppFooter ? <AppFooter /> : null}
      </ScrollViewWithHeaderTitle>

      {shouldRenderStickyCta ? (
        <Container style={stickyCtaContainerStyles}>
          <Button
            palette={ctaPalette}
            onPress={ctaContent.onPress}
            size="large"
            style={styles.cta}
            testID="sweetpass-landing-page.sticky-cta"
          >
            {ctaContent.label}
          </Button>
        </Container>
      ) : null}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  contentContainer: {
    flexGrow: 1,
  },
  cta: {
    borderWidth: 1,
    borderColor: theme.colors.KALE,
  },
  ctaContainer: {
    alignItems: 'center',
  },
  ctaContainerXS: {
    paddingBottom: theme.spacing['6'],
  },
  ctaContainerSM: {
    paddingBottom: theme.spacing['16'],
  },
  stickyCtaContainer: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.SPINACH,
    paddingTop: theme.spacing['4'],
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const HEADER_BG_COLOR: Record<SweetpassLandingPagePalette, string> = {
  kale: theme.colors.KALE,
  lime: theme.colors.LIME,
};
const HEADER_TINT_COLOR: Record<SweetpassLandingPagePalette, string> = {
  kale: theme.colors.WHITE,
  lime: theme.colors.BLACK,
};
const CTA_PALETTE: Record<SweetpassLandingPagePalette, ButtonPalette> = {
  kale: 'primaryLime',
  lime: 'lime',
};

const DEFAULT_HEADER_BG_COLOR = HEADER_BG_COLOR.kale;
const DEFAULT_HEADER_TINT_COLOR = HEADER_TINT_COLOR.kale;
const DEFAULT_CTA_PALETTE = CTA_PALETTE.kale;

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassLandingPageProps = Readonly<{
  title: string;
  headerContent?: SweetpassLandingPageHeaderContent;
  ctaContent?: SweetpassLandingPageCtaContent;
  benefitsContent?: SweetpassLandingPageBenefitsContent;
  withTopPadding?: boolean;
  withSafeAreaInset?: boolean;
}>;

type SweetpassLandingPageHeaderContent = Readonly<{
  palette: SweetpassLandingPagePalette;
  title: string;
  titleXs: string;
  text: string;
  imgUrl?: string;
  imgA11yLabel?: string;
  ctaLabel: string;
  onCtaPress: () => void;
}>;

type SweetpassLandingPageCtaContent = Readonly<{
  palette: SweetpassLandingPagePalette;
  label: string;
  onPress: () => void;
  action: string;
}>;

type SweetpassLandingPageBenefitsContent = ComponentProps<
  typeof SweetpassLandingPageBenefits
>;

type SweetpassLandingPagePalette = 'kale' | 'lime';

type ButtonPalette = ComponentProps<typeof Button>['palette'];

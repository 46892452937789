import { addMinutes, formatISO } from 'date-fns';

import { type WantedTime } from '@order/features/ordering';
import { ignoreTimezone } from '@order/utils';

/**
 * The wanted time shown to the customer needs to be adjusted by its delivery offset.
 * However, the wanted time sent to the submit order request must not be adjusted.
 * This helper is used to adjust it on the components.
 */
export const adjustWantedTime = (wantedTime?: WantedTime) => {
  if (!wantedTime) return undefined;

  const { time, offset } = wantedTime;

  const validTime = ignoreTimezone(time);

  if (!validTime) return undefined;

  return formatISO(addMinutes(validTime, offset));
};

import React, { useCallback, useMemo, useRef } from 'react';
import type { ViewToken } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { AddonsRail, slugify, theme } from '@sg/garnish';

import type { DeliveryOrderDetailInput } from '@order/graphql';
import { useAddLineItemToCart, useCart } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { useAddons } from './BagAddonsRail.hooks';
import type { AddonsQuery } from './GraphQL/Addons.generated';

// ────────────────────────────────────────────────────────────────────────────────

export const BagAddonsRail = ({ isDelivery }: BagAddonsRailProps) => {
  const { t, formatPrice } = useLocalizationContext();

  const { addons, isLoadingAddons } = useAddons({ isDelivery });
  const { cart, isFetchingCart } = useCart();
  const { lineItems = [], deliveryOrderDetail } = cart ?? {};

  const { addLineItemToCart } = useAddLineItemToCart();

  // ─────────────────────────────────────────────────────────────────

  const formattedAddons = useMemo(() => {
    const existingLineItemsProductsIds = new Set(
      lineItems.map(({ product }) => product?.id),
    );
    const addonProducts = addons?.products ?? [];

    // hide already added add-ons
    const filteredAddonProducts = addonProducts.filter(
      (product) => !existingLineItemsProductsIds.has(product.id),
    );

    return filteredAddonProducts.map((product: AddonsProduct) => {
      const { id, name, cost, asset } = product;
      const { url: imageUrl } = asset;

      const price = formatPrice(cost, 'USD');
      const testID = `addon-rail.product-card-${slugify(name)}`;

      return {
        id,
        name,
        price,
        imageUrl,
        testID,
      };
    });
  }, [addons?.products, formatPrice, lineItems]);

  const deliveryDetails = useMemo<DeliveryOrderDetailInput | undefined>(() => {
    if (!deliveryOrderDetail) return;

    return {
      ...deliveryOrderDetail,
      addressId: deliveryOrderDetail.address.id,
    };
  }, [deliveryOrderDetail]);

  // ─────────────────────────────────────────────────────────────────

  const onPress = useCallback(
    async (addonId: any) => {
      await addLineItemToCart({
        product: { id: addonId },
        quantity: 1,
        customName: null,
        deliveryDetails,
      });
    },
    [addLineItemToCart, deliveryDetails],
  );

  // ─────── Telemetry ───────────────────────────────────────────────
  const onAddOnScroll = useAddOnScrollTelemetry();

  // ─────────────────────────────────────────────────────────────────

  if (!isLoadingAddons && formattedAddons.length === 0) return null;

  return (
    <View style={styles.wrapper}>
      <AddonsRail
        isLoading={isLoadingAddons}
        addons={formattedAddons}
        trackViewableState={onAddOnScroll}
        withoutHeaderBorder={true}
        isDisabled={isFetchingCart}
        onPress={onPress}
        headerText={t('bag.upsells.header')}
      />
    </View>
  );
};

// The track reference is needed to avoid recreating the callback on login.
// This would break the FlatList with "Changing onViewableItemsChanged on the fly is not supported".
const useAddOnScrollTelemetry = () => {
  const { track } = useTelemetry();
  const trackReference = useRef(track);
  const viewableItems = useRef<readonly number[]>([]);

  return useCallback(
    (event: Readonly<{ viewableItems: readonly ViewToken[] }>) => {
      const viewableAddOns = event.viewableItems.map(
        ({ index }) => index,
      ) as readonly number[];

      const hasScrolled =
        viewableAddOns.length > 1 &&
        viewableItems.current.length > 0 &&
        viewableItems.current[0] !== viewableAddOns[0];

      // eslint-disable-next-line functional/immutable-data
      viewableItems.current = viewableAddOns;

      // Only trigger telemetry event if the bag addons were scrolled.
      if (hasScrolled) {
        trackReference.current('bag.add_on.scroll', { viewableAddOns });
      }
    },
    [],
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    marginHorizontal: -theme.spacing['6'],
    padding: theme.spacing['6'],
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type BagAddonsRailProps = { isDelivery: boolean };

type AddonsProducts = AddonsQuery['addons']['products'];
type AddonsProduct = AddonsProducts[number];

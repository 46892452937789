import type { ComponentProps } from 'react';
import { useCallback } from 'react';

import { useTelemetry } from '@order/Telemetry';

import type { ChallengeCompletionModal } from '../../ChallengeCompletionModal';

// ─────────────────────────────────────────────────────────────────────────────

export const useChallengeCompletionTelemetry = () => {
  const { track } = useTelemetry();

  // ─────────────────────────────────────────────────────────────────────

  const trackNavigateToLoyaltyTab = useCallback(() => {
    track('challenges.my_rewards');
  }, [track]);

  const trackChallengeCompletionModalShow = useCallback(
    (
      challengeCompletionDetails: ComponentProps<
        typeof ChallengeCompletionModal
      >['challengeCompletionDetails'],
    ) => {
      const { celebrationCopy, challengesIds, expirationDate, title } =
        challengeCompletionDetails;

      track('challenges.completed', {
        celebrationCopy,
        challengesIds,
        expirationDate,
        title,
      });
    },
    [track],
  );

  const trackChallengeCompletionModalDismiss = useCallback(() => {
    track('challenges.closed');
  }, [track]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    trackNavigateToLoyaltyTab,
    trackChallengeCompletionModalShow,
    trackChallengeCompletionModalDismiss,
  };
};

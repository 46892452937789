import React, { type ComponentProps, useCallback } from 'react';
import { type NativeSyntheticEvent, Platform } from 'react-native';
import { Modal as GarnishModal } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { NoticeBannersOverlay } from '../NoticeBannersOverlay';
import { useModalClearNoticeBannersOnClose } from './hooks';

/**
 * Wrapper around garnish modal.
 * - Hooks into Telemetry.
 * - Provides the backdrop a11y label localization.
 * - Ensures the a11y label for the modal is present.
 * - Renders the `NoticeBannersStack` on native so banners appear within modals.
 */

// @ts-expect-error TS(2740): Type '{ (props: ModalProps): Element; Header: (pro... Remove this comment to see the full error message
export const Modal: typeof GarnishModal = (props: ModalProps) => {
  const { children, onRequestClose } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const { track } = useTelemetry();
  const handleCloseRequest = useModalClearNoticeBannersOnClose(onRequestClose);

  // On web we are using a react-dom portal to render it over the backdrop, and on iOS it renders correctly.
  const shouldRenderInnerNoticeBanners = Platform.OS === 'android';

  // ─── Localization ────────────────────────────────────────────────────

  const backdropA11yLabel = t('general.modal.backdrop.a11y-label');

  // ─── Callbacks ───────────────────────────────────────────────────────

  const handleClose = useCallback(
    (event: NativeSyntheticEvent<Event>) => {
      track('modal.close');
      handleCloseRequest?.(event);
    },
    [track, handleCloseRequest],
  );

  return (
    <GarnishModal
      backdropAccessibilityLabel={backdropA11yLabel}
      {...props}
      onRequestClose={handleClose}
    >
      {shouldRenderInnerNoticeBanners ? <NoticeBannersOverlay /> : null}
      {children}
    </GarnishModal>
  );
};

const ModalHeader = (props: ModalHeaderProps) => {
  const { accessibilityLabel, onClose, ...rest } = props;
  const handleCloseRequest = useModalClearNoticeBannersOnClose(onClose);

  const { t } = useLocalizationContext();
  const closeButtonA11yLabel =
    accessibilityLabel ?? t('general.modal.close-button.a11y-label');

  return (
    <GarnishModal.Header
      accessibilityLabel={closeButtonA11yLabel}
      onClose={handleCloseRequest}
      {...rest}
    />
  );
};

// ─── Compound Components ───────────────────────────────────────────────

Object.assign(Modal, GarnishModal); // eslint-disable-line functional/immutable-data
Modal.Header = ModalHeader; // eslint-disable-line functional/immutable-data

// ─── Types ─────────────────────────────────────────────────────────────
// This setup allows the current Modal component to be used as normal.
// But new implementations with this component will require the a11y label.

type ModalProps = React.ComponentProps<typeof GarnishModal> &
  Readonly<{
    accessibilityLabel: string;
  }>;

type ModalHeaderProps = {
  accessibilityLabel?: string;
} & Omit<ComponentProps<typeof GarnishModal.Header>, 'accessibilityLabel'>;

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { type Asset } from 'contentful';
import { getContentfulImageUrl, theme, useResponsive } from '@sg/garnish';

import { GiftCardRedemptionBalance } from '../GiftCardRedemptionBalance';
import { GiftCardRedemptionCardImage } from '../GiftCardRedemptionCardImage';
import { GiftCardRedemptionCta } from '../GiftCardRedemptionCta';
import { GiftCardRedemptionHeader } from '../GiftCardRedemptionHeader';
import { GiftCardRedemptionMessage } from '../GiftCardRedemptionMessage';
import { GiftCardRedemptionNumberAndCode } from '../GiftCardRedemptionNumberAndCode';
import { giftCardRedemptionContentMessages as messages } from './GiftCardRedemptionContent.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardRedemptionContent = (
  props: GiftCardRedemptionContentProps,
) => {
  const {
    recipientMessage,
    balance,
    giftCardImage,
    redeemId,
    cardNumber,
    cardRegCode,
    senderName,
    recipientName,
  } = props;

  const { formatNumber } = useIntl();
  const { scale } = useWindowDimensions();
  const { match } = useResponsive();

  // ─── Derived Data ────────────────────────────────────────────────────

  const maybeGiftCardImageUrl = giftCardImage?.cover.fields.file.url;
  const maybeGiftCardTitle = giftCardImage?.title;

  const giftCardImgSrc = maybeGiftCardImageUrl
    ? getContentfulImageUrl(maybeGiftCardImageUrl, {
        w: Math.max(400 * scale, 800),
      })
    : null;

  const balanceInDollars = balance / 100;
  const formattedBalance = formatNumber(balanceInDollars, {
    currency: 'USD',
    style: 'currency',
  });

  // ─── Styles ──────────────────────────────────────────────────────────

  const innerContainerStyles = [
    styles.innerContainer,
    match([undefined, styles.innerContainerSM]),
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={innerContainerStyles}>
      <GiftCardRedemptionHeader>
        <FormattedMessage {...messages.heading} />
      </GiftCardRedemptionHeader>

      {giftCardImgSrc ? (
        <View style={match([undefined, styles.imgContainerSM])}>
          <GiftCardRedemptionCardImage
            imgSrc={giftCardImgSrc}
            imgAccessibilityLabel={maybeGiftCardTitle}
          />
        </View>
      ) : null}

      <View style={match([undefined, styles.messageContainerSM])}>
        <GiftCardRedemptionMessage
          recipientMessage={recipientMessage}
          senderName={senderName}
          recipientName={recipientName}
        />
      </View>

      <GiftCardRedemptionBalance>{formattedBalance}</GiftCardRedemptionBalance>

      <View style={styles.ctaContainer}>
        <GiftCardRedemptionCta redeemId={redeemId} />
      </View>

      <GiftCardRedemptionNumberAndCode
        cardNumber={cardNumber}
        cardRegCode={cardRegCode}
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  innerContainer: {
    width: '100%',
    maxWidth: 392,
    rowGap: theme.spacing['6'],
  },
  innerContainerSM: {
    paddingHorizontal: theme.spacing['4'],
  },
  imgContainerSM: {
    paddingHorizontal: theme.spacing['2'],
  },
  messageContainerSM: {
    paddingHorizontal: theme.spacing['2'],
  },
  ctaContainer: {
    alignItems: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardRedemptionContentProps = {
  balance: number;
  cardNumber: string;
  cardRegCode: string;
  recipientMessage?: string | null;
  redeemId: string;
  senderName: string;
  recipientName: string;
  giftCardImage?: {
    id: string;
    title: string;
    cover: Asset;
  };
};

/* eslint-disable functional/immutable-data */

import React, { Fragment, useCallback } from 'react';
import {
  type LayoutChangeEvent,
  Platform,
  StyleSheet,
  View,
  type ViewProps,
} from 'react-native';
import Animated, {
  type SharedValue,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { theme } from '@garnish/constants';
import { Portal } from '@gorhom/portal';

import { useResponsive } from '../../hooks';
import { FocusScope } from '../FocusScope';
import { ANIMATED_DIALOG_ANIMATION_CONFIG } from './AnimatedDialog.constants';
import { AnimatedDialogOverlay } from './AnimatedDialogOverlay';

// ─────────────────────────────────────────────────────────────────────────────
// This is a react-native-reanimated view that behaves like a modal.
// ─────────────────────────────────────────────────────────────────────────────

export const AnimatedDialog = (props: AnimatedDialogProps) => {
  const {
    children,
    show,
    maxHeight,
    style,
    dismissOverlayAccessibilityLabel = 'Dismiss modal',
    onLayout,
    dismissModal,
  } = props;

  // ─── Portal ───────────────────────────────────────────────────────────────
  // On XS breakpoints, we use a Portal to get the overlay to go over the header.

  const { match } = useResponsive();
  const MaybePortal = Platform.select({
    web: match([Portal, Fragment]),
    default: Fragment,
  });

  // ─── Height Animation ─────────────────────────────────────────────────────

  const height = useSharedValue(0);

  const heightAnimation = useAnimatedStyle(() => {
    return {
      height: maxHeight?.value
        ? Math.min(height.value, maxHeight.value)
        : height.value,
    };
  });

  const syncHeight = useCallback(
    (event: LayoutChangeEvent) => {
      const layoutHeight = event.nativeEvent.layout.height;

      height.value = withTiming(layoutHeight, ANIMATED_DIALOG_ANIMATION_CONFIG);
      onLayout?.(event);
    },
    [height, onLayout],
  );

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <MaybePortal>
      <View
        style={styles.modalContainer}
        pointerEvents={show ? 'auto' : 'none'}
      >
        <AnimatedDialogOverlay
          show={show}
          accessibilityLabel={dismissOverlayAccessibilityLabel}
          dismissModal={dismissModal}
        />

        <Animated.View
          pointerEvents="none"
          style={[styles.modalBackground, heightAnimation]}
        />

        <View onLayout={syncHeight} style={[styles.dialogContent, style]}>
          <FocusScope enabled={show}>{children}</FocusScope>
        </View>
      </View>
    </MaybePortal>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  modalContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    justifyContent: 'flex-end',
    zIndex: theme.zIndex.modal,
  },
  modalBackground: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: 0,
    borderTopLeftRadius: theme.radius.large,
    borderTopRightRadius: theme.radius.large,
    backgroundColor: theme.colors.CREAM,
    zIndex: theme.zIndex.modalBackdrop,
    ...theme.elevations[2],
  },
  dialogContent: {
    flexShrink: 1,
    marginTop: 120,
    zIndex: theme.zIndex.modal,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type AnimatedDialogProps = {
  children: React.ReactNode;
  show: boolean;
  style?: ViewProps['style'];
  maxHeight?: SharedValue<number>;
  dismissOverlayAccessibilityLabel?: string;
  onLayout?: ViewProps['onLayout'];
  dismissModal: () => void;
};

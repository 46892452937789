import React from 'react';
import { ScanCard } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanInStorePayGiftCard = (props: ScanInStorePayGiftCardProps) => {
  const { name, balance, qrCodeValue } = props;

  const { formatPrice } = useLocalizationContext();

  const hasName = name !== undefined;
  const hasBalance = balance !== undefined;

  const formattedBalance = hasBalance ? formatPrice(balance, 'USD') : undefined;

  const hasFormattedBalance = formattedBalance !== undefined;
  const hasValidCardInfo = hasName && hasFormattedBalance;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ScanCard.Container>
      {hasValidCardInfo ? (
        <ScanCard.CardBalance balance={formattedBalance} cardName={name} />
      ) : null}

      {qrCodeValue ? <ScanCard.QrCode value={qrCodeValue} /> : null}
    </ScanCard.Container>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanInStorePayGiftCardProps = Readonly<{
  name: string | undefined;
  balance: number | undefined;
  qrCodeValue: string | undefined;
}>;

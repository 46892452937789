import type { ComponentProps, ReactNode } from 'react';
import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';

// ─────────────────────────────────────────────────────────────────────────────

export const CarouselItem = memo((props: CarouselItemProps) => {
  const { children, style } = props;

  const itemStyles = [styles.item, style];

  return <Animated.View style={itemStyles}>{children}</Animated.View>;
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  item: {
    width: '100%',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CarouselItemProps = Readonly<{
  children: ReactNode;
  style: ComponentProps<typeof Animated.View>['style'];
}>;

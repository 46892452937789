import { defineMessages } from 'react-intl';

export const giftCardCheckoutScreenMessages = defineMessages({
  screenTitle: {
    defaultMessage: 'Customize your gift',
    description: 'Gift Card Checkout Screen | Title',
  },
  screenCustomDocumentTitle: {
    defaultMessage: 'Customize your gift | Gift Card | sweetgreen',
    description: 'Gift Card Checkout Screen | Custom Document Title',
  },
});

import React, { useCallback, useRef } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../../../hooks';
import { webOnlyStyles } from '../../../../utils';
import { LabelText } from '../../../Text';
import type { TabItem } from '../../Tabs.types';

// ─────────────────────────────────────────────────────────────────────────────

export const Tab = (props: TabProps) => {
  const {
    id,
    panelId,
    isActive,
    isDisabled,
    isFluid = true,
    label,
    'aria-label': ariaLabel,
    onPress,
    palette = 'primary',
  } = props;

  const tabRef = useRef(null);
  const { isHovered } = usePressableState(tabRef);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onTabPress = useCallback(() => {
    if (isActive) return;

    onPress(id);
  }, [isActive, onPress, id]);

  // ─── Animated Values ─────────────────────────────────────────────────

  const active = useDerivedValue(() => {
    return withTransition(isActive ? 1 : 0);
  });

  const opacity = useDerivedValue(() => {
    const shouldUseLightStyle = isHovered && !isActive;

    return withTransition(shouldUseLightStyle ? 0.8 : 1);
  });

  // ─── Animated Styles ─────────────────────────────────────────────────

  const tabAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
      borderBottomColor: interpolateColor(
        active.value,
        [0, 1],
        [
          paletteStyles[palette].tabNonActiveColor,
          paletteStyles[palette].tabActiveColor,
        ],
      ),
    };
  });

  const labelAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
      color: interpolateColor(
        active.value,
        [0, 1],
        [
          paletteStyles[palette].labelNonActiveColor,
          paletteStyles[palette].labelActiveColor,
        ],
      ),
    };
  });

  // ─── Styles ──────────────────────────────────────────────────────────

  const tabStyles = [
    styles.tab,
    isFluid ? styles.tabFluid : styles.tabCompact,
    isDisabled ? styles.tabDisabled : tabAnimatedStyle,
    tabWebStyles,
    isActive ? tabActiveWebStyles : undefined,
  ];
  const labelStyles = [
    styles.label,
    isDisabled ? styles.labelDisabled : labelAnimatedStyle,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <AnimatedPressable
      ref={tabRef}
      nativeID={id}
      testID={id}
      accessibilityRole="tab"
      disabled={isDisabled}
      style={tabStyles}
      onPress={onTabPress}
      accessibilityState={{ selected: isActive }}
      aria-label={ariaLabel}
      {...{
        accessibilitySelected: isActive,
        accessibilityControls: panelId,
      }}
    >
      <AnimatedLabelText size={2} style={labelStyles}>
        {label}
      </AnimatedLabelText>
    </AnimatedPressable>
  );
};

// ─── Animated Components ─────────────────────────────────────────────────────

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);
const AnimatedLabelText = Animated.createAnimatedComponent(LabelText);

// ─── Utils ───────────────────────────────────────────────────────────────────

function withTransition(value: number) {
  'worklet';

  return withTiming(value, { duration: theme.transitions.base });
}

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  tab: {
    paddingVertical: theme.spacing['4'],
    paddingHorizontal: theme.spacing['2'],
    marginHorizontal: theme.spacing['3'],
    marginBottom: -1,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.OPACITY.TRANSPARENT,
  },
  tabCompact: {
    minWidth: 126,
    maxWidth: '100%',
  },
  tabFluid: {
    flex: 1,
  },
  tabDisabled: {
    borderBottomColor: theme.colors.LIGHT,
  },
  label: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  labelDisabled: {
    color: theme.colors.LIGHT,
  },
});

const tabWebStyles = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: theme.spacing['2'],
});

const tabActiveWebStyles = webOnlyStyles({
  cursor: 'default',
});

const paletteStyles = {
  primary: {
    labelActiveColor: theme.colors.DARK_KALE,
    labelNonActiveColor: theme.colors.CHARCOAL,
    tabActiveColor: theme.colors.DARK_KALE,
    tabNonActiveColor: theme.colors.OPACITY.TRANSPARENT,
  },
  premium: {
    labelActiveColor: theme.colors.OATMEAL,
    labelNonActiveColor: theme.colors.OPACITY.OATMEAL.DARK,
    tabActiveColor: theme.colors.SWEET_CORN,
    tabNonActiveColor: theme.colors.KALE,
  },
};

// ─── Types ───────────────────────────────────────────────────────────────────

type TabProps = Readonly<{
  isActive: boolean;
  onPress: (value: string) => void;
  'aria-label'?: string;
  palette?: 'primary' | 'premium';
}> &
  Pick<TabItem, 'id' | 'panelId' | 'label' | 'isDisabled' | 'isFluid'>;

/**
 * @see https://usehooks.com/usePrevious/
 */

import { useEffect, useRef } from 'react';

export function usePrevious<ValueType>(
  value: ValueType,
  useInitialValue?: boolean,
) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<ValueType | undefined>(
    useInitialValue ? value : undefined,
  );

  // Store current value in ref
  useEffect(() => {
    // eslint-disable-next-line functional/immutable-data
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

const ALLOWED_URL_PATTERNS: RegExp[] = [
  /\.sweetgreen\.com/, // Allows any subdomain or path under sweetgreen.com
  /^https?:\/\/localhost/, // Matches localhost with http or https
  /\.contentful\.com/, // Allows any subdomain or path under contentful.com
];

export function shouldExcludeResourceFromPageLoadActivity(
  url: string,
): boolean {
  // Check if the URL matches any of the allowed patterns
  const isAllowed = ALLOWED_URL_PATTERNS.some((pattern) => pattern.test(url));

  // Return true to exclude if it does not match any allowed pattern
  return !isAllowed;
}

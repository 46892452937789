import React from 'react';
import type { ImageStyle } from 'react-native';
import { StyleSheet } from 'react-native';
import {
  IllusDelivery_1,
  LocationCard,
  theme,
  useResponsive,
} from '@sg/garnish';

import type { Address } from '@order/graphql';

export const DeliveryLocationCard = ({
  deliveryAddress,
}: DeliveryLocationCardProps) => {
  const { match } = useResponsive();
  const deliveryAddressStreet = `${deliveryAddress?.street}${
    deliveryAddress?.secondaryStreet
      ? `, ${deliveryAddress.secondaryStreet}`
      : ''
  }`;

  return (
    <LocationCard
      id={deliveryAddress.id}
      acceptingOrders={true}
      notAcceptingOrdersReason=""
      restaurantSlug=""
      address={deliveryAddressStreet}
      city={deliveryAddress?.city ?? ''}
      state={deliveryAddress?.state ?? ''}
      zipCode={deliveryAddress?.zipCode ?? ''}
      name={deliveryAddress?.name ?? ''}
      flexMessage=""
      lat={deliveryAddress?.latitude ?? 0}
      lng={deliveryAddress?.longitude ?? 0}
      storeHours=""
      imageUrl={IllusDelivery_1}
      containerStyle={match([styles.containerStyleXS, {}])}
      imageStyle={
        match([styles.imageStyleXS, styles.imageStyleSM]) as ImageStyle
      }
    />
  );
};

export type DeliveryLocationCardProps = Readonly<{
  deliveryAddress: Pick<
    Address,
    | 'id'
    | 'street'
    | 'secondaryStreet'
    | 'city'
    | 'state'
    | 'zipCode'
    | 'name'
    | 'latitude'
    | 'longitude'
  >;
}>;

const styles = StyleSheet.create({
  containerStyleXS: {
    padding: theme.spacing['3'],
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.GRAY,
    borderRadius: theme.radius.medium,
  },
  imageStyleXS: {
    width: 100,
    height: 100,
    // @ts-expect-error TS(2322): Type '"12px"' is not assignable to type 'Dimension... Remove this comment to see the full error message
    marginRight: '12px',
  },
  imageStyleSM: {
    width: '100%',
  },
});

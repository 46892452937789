import { useIsLoggedIn } from '@order/AuthMachine';

import { useInStoreOrderLastPendingFeedbackQuery } from './OrderLastPendingFeedback.query.generated';

/**
 * We do not fully support in-store orders.
 * Yet we still need to allow them to be rated.
 *
 * Rather than returning those orders in the history,
 * we return only the Order ID related to it.
 */
export const useCachedInStoreOrderPendingFeedback = () => {
  const isLoggedIn = useIsLoggedIn();

  const [response] = useInStoreOrderLastPendingFeedbackQuery({
    requestPolicy: 'cache-only',
    pause: !isLoggedIn,
  });

  const inStoreOrderId = response?.data?.inStoreOrderLastPendingFeedback?.id;
  const inStoreOrderWantedTime =
    response?.data?.inStoreOrderLastPendingFeedback?.wantedTime;

  return { inStoreOrderId, inStoreOrderWantedTime };
};

import React from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  capitalizeFirstLetter,
  FadeView,
  IconLink,
  LabelText,
  LoadingDots,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { LocationResultCardFeeDisclosure } from '../../subcomponents';
import { locationResultCardContentMessages as messages } from './LocationResultCardContentV2.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultCardContentV2 = (
  props: LocationResultCardContentV2Props,
) => {
  const {
    locationName,
    locationOrderChannel,
    shouldShowDeliveryFeeDisclaimer,
    shouldShowOutpostFeeDisclaimer,
    estimatedDeliveryTime,
    shouldDisplayClosedNotice,
    shouldDisableControls,
    address,
    addressDetails,
    withLocationOrderChannel,
    toggleDetailsModal,
    withDetailsModal,
    isLoading,
  } = props;

  const { match } = useResponsive();
  const { t } = useLocalizationContext();
  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      {/* ─── Main Info ──────────────────────────────────────────────────── */}

      <View style={match([styles.infoContainerXS, styles.infoContainerSM])}>
        {withLocationOrderChannel ? (
          <LabelText sizeMatch={['10']}>
            {formatMessage(messages.orderChannel, {
              channel: locationOrderChannel,
            })}
          </LabelText>
        ) : null}

        <BodyText
          bold
          sizeMatch={['24']}
          numberOfLines={2}
          style={styles.heading}
        >
          {capitalizeFirstLetter(locationName)}
        </BodyText>

        <BodyText sizeMatch={['14']} numberOfLines={1} style={styles.address}>
          {address}
        </BodyText>

        {addressDetails ? (
          <BodyText sizeMatch={['14']} numberOfLines={1}>
            {addressDetails}
          </BodyText>
        ) : null}

        {estimatedDeliveryTime ? (
          <BodyText sizeMatch={['14']} numberOfLines={1} bold>
            {t('delivery.estimate', { estimate: estimatedDeliveryTime })}
          </BodyText>
        ) : null}

        {shouldDisplayClosedNotice ? (
          <BodyText
            sizeMatch={['14']}
            numberOfLines={1}
            style={styles.closedLabel}
          >
            {t('location.closed')}
          </BodyText>
        ) : null}

        {shouldShowDeliveryFeeDisclaimer ? (
          <LocationResultCardFeeDisclosure.Delivery variation="simple" />
        ) : null}

        {shouldShowOutpostFeeDisclaimer ? (
          <LocationResultCardFeeDisclosure.Outpost variation="simple" />
        ) : null}
      </View>

      {/* ─── Additional Details ─────────────────────────────────────────── */}

      <View
        style={match([
          styles.extraControlsContainerXS,
          styles.extraControlsContainerSM,
        ])}
      >
        <FadeView show={isLoading} style={styles.loadingIndicatorContainer}>
          <View style={styles.loadingIndicator}>
            <LoadingDots color={theme.colors.WHITE} />
          </View>
        </FadeView>

        {withDetailsModal ? (
          <IconLink
            name="IconBadgeInfo"
            onPress={toggleDetailsModal}
            disabled={shouldDisableControls}
            hitSlop={theme.spacing['1']}
          />
        ) : null}
      </View>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  loadingIndicatorContainer: {
    position: 'absolute',
    top: -5,
    right: -5,
    zIndex: 1,
  },
  loadingIndicator: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.KALE,
    width: 32,
    height: 32,
    borderRadius: theme.radius.xlarge,
  },
  heading: {
    color: theme.colors.KALE,
  },
  address: {
    color: theme.colors.CHARCOAL,
  },
  infoContainerXS: {
    flex: 1,
  },
  infoContainerSM: {
    flexBasis: '90%',
  },
  extraControlsContainerXS: {
    marginLeft: 12,
  },
  extraControlsContainerSM: {
    marginLeft: 'auto',
  },
  closedLabel: {
    color: theme.colors.CAUTION,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultCardContentV2Props = {
  locationName: string;
  locationOrderChannel: string;
  withLocationOrderChannel: boolean;
  address: string;
  isLoading: boolean;
  addressDetails?: string;
  estimatedDeliveryTime?: string;
  shouldDisplayClosedNotice: boolean;
  toggleDetailsModal: () => void;
  withDetailsModal?: boolean;
  shouldDisableControls: boolean;
  shouldShowDeliveryFeeDisclaimer: boolean;
  shouldShowOutpostFeeDisclaimer: boolean;
};

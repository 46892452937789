import React, { forwardRef, type PropsWithChildren } from 'react';
import {
  Pressable,
  type PressableProps,
  StyleSheet,
  type View,
} from 'react-native';
import { theme } from '@garnish/constants';

import { webOnlyStyles } from '../../../../../../utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders a `Pressable` component with predefined props to match radio button.
 */
export const RadioContainerItemPressable = forwardRef<
  View,
  RadioContainerItemPressableProps
>((props, ref) => {
  const {
    children,
    isSelected,
    isDisabled,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    onPress,
  } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const pressableContainerStyles = [
    pressableWebStyles,
    styles.pressableContainer,
    isSelected ? pressableSelectedWebStyles : undefined,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      ref={ref}
      accessibilityRole="radio"
      aria-label={ariaLabel}
      aria-selected={isSelected}
      aria-labelledby={ariaLabelledBy}
      disabled={isDisabled}
      onPress={onPress}
      style={pressableContainerStyles}
    >
      {children}
    </Pressable>
  );
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressableContainer: {
    borderRadius: theme.radius.large,
  },
});

const pressableWebStyles = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: theme.spacing['1'],
  userSelect: 'none',
});

const pressableSelectedWebStyles = webOnlyStyles({
  cursor: 'default',
});

// ─── Types ───────────────────────────────────────────────────────────────────

type RadioContainerItemPressableProps = PropsWithChildren<{
  isSelected: boolean;
  isDisabled?: boolean;
}> &
  Pick<PressableProps, 'aria-labelledby' | 'aria-label' | 'onPress'>;

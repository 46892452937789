import type { RefObject } from 'react';
import { useCallback } from 'react';
import { type HostedFrameRef, useNoticeBannersStackContext } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { injectTokenizeRequest } from '../injection';

/**
 * Validates the Braintree Form.
 * If there are errors, show appropriate notice banners for them.
 * Otherwise, inject the tokenize request into the Hosted Frame.
 */
export function useBraintreeFormSubmission(
  props: BraintreeFormSubmissionProps,
) {
  const {
    isLoadingFields,
    hasCardNumber,
    hasExpDate,
    hasCvv,
    hasPostalCode,
    hostedFrameRef,
  } = props;

  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { t } = useLocalizationContext();

  return useCallback(() => {
    (async () => {
      if (isLoadingFields) {
        addNoticeBanner({
          palette: 'caution',
          text: t('credit-card-form.error.submit.loading'),
        });

        return;
      }

      if (!hasCardNumber) {
        addNoticeBanner({
          palette: 'caution',
          text: t('credit-card-form.error.submit.number'),
        });

        return;
      }

      if (!hasExpDate) {
        addNoticeBanner({
          palette: 'caution',
          text: t('credit-card-form.error.submit.expiration'),
        });

        return;
      }

      if (!hasCvv) {
        addNoticeBanner({
          palette: 'caution',
          text: t('credit-card-form.error.submit.cvv'),
        });

        return;
      }

      if (!hasPostalCode) {
        addNoticeBanner({
          palette: 'caution',
          text: t('credit-card-form.error.submit.zipCode'),
        });

        return;
      }

      injectTokenizeRequest(hostedFrameRef);
    })();
  }, [
    isLoadingFields,
    hasCardNumber,
    hasExpDate,
    hasCvv,
    hasPostalCode,
    hostedFrameRef,
    addNoticeBanner,
    t,
  ]);
}

// ─── Types ─────────────────────────────────────────────────────────────

type BraintreeFormSubmissionProps = Readonly<{
  isLoadingFields: boolean;
  hasCardNumber: boolean;
  hasExpDate: boolean;
  hasCvv: boolean;
  hasPostalCode: boolean;
  hostedFrameRef: RefObject<HostedFrameRef>;
}>;

import React, { useCallback, useContext, useEffect, useRef } from 'react';
import type { NativeScrollEvent, NativeSyntheticEvent } from 'react-native';
import { ScrollView } from 'react-native';

import { BoundScrollContext } from './BoundScrollView.context';
import type { BoundScrollViewProps } from './BoundScrollView.types';

export const BoundScrollView = (
  props: BoundScrollViewProps,
): React.ReactElement => {
  const { children, ...rest } = props;
  const { addScrollReference, clearScrollReference, onScroll } =
    useContext(BoundScrollContext);
  const scrollReference = useRef(null);

  const handleOnScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      onScroll(event);
      props.onScroll?.(event);
    },
    [onScroll, props],
  );

  useEffect(() => {
    addScrollReference(scrollReference);

    return () => {
      clearScrollReference();
    };
  }, [scrollReference, addScrollReference, clearScrollReference]);

  return (
    <ScrollView
      {...rest}
      ref={scrollReference}
      onScroll={handleOnScroll}
      scrollEventThrottle={16}
    >
      {children}
    </ScrollView>
  );
};

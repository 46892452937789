import React, { type ComponentProps, forwardRef, useMemo } from 'react';
import { Platform, ScrollView, StyleSheet, type ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Aside } from '@expo/html-elements';
import { LinearGradient } from 'expo-linear-gradient';
import { theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationDetailsContainer = forwardRef<
  ScrollView,
  CustomizationDetailsContainerProps
>((props, ref) => {
  const {
    children,
    style,
    contentContainerStyle,
    isFluid = false,
    ...restProps
  } = props;

  const { match, currentBreakpoint } = useResponsive();
  const { top: safeAreaTopInset } = useSafeAreaInsets();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerDynamicStylesXS = useMemo<ViewStyle>(() => {
    if (isFluid) return {};

    const shouldUseSafeArea = Platform.OS === 'android';

    return {
      maxHeight: (shouldUseSafeArea ? safeAreaTopInset : 0) + 275,
    };
  }, [isFluid, safeAreaTopInset]);

  const outerContainerStyles = [
    match([
      isFluid ? styles.outerContainerFluidXS : undefined,
      styles.outerContainerSM,
    ]),
  ];
  const containerStyles = [
    styles.container,
    match([[styles.containerXS, containerDynamicStylesXS], styles.containerSM]),
    style,
  ];
  const contentContainerStyles = [
    styles.contentContainer,
    contentContainerStyle,
    match([styles.contentContainerXS, undefined]),
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Aside style={outerContainerStyles}>
      <ScrollView
        ref={ref}
        style={containerStyles}
        contentContainerStyle={contentContainerStyles}
        {...restProps}
      >
        {children}
      </ScrollView>

      {currentBreakpoint.isXS ? (
        <LinearGradient
          locations={SHADOW_GRADIENT_LOCATIONS}
          pointerEvents="none"
          colors={SHADOW_GRADIENT_COLORS}
          style={styles.shadowGradient}
        />
      ) : null}
    </Aside>
  );
});

// ─── Constants ───────────────────────────────────────────────────────────────

const SHADOW_GRADIENT_LOCATIONS = [0, 1];
const SHADOW_GRADIENT_COLORS = [
  theme.colors.OPACITY.OATMEAL.TRANSPARENT,
  theme.colors.OATMEAL,
];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainerFluidXS: {
    flex: 1,
  },
  outerContainerSM: {
    width: '100%',
    maxWidth: 400,
  },
  container: {
    flexGrow: 1,
    backgroundColor: theme.colors.CREAM,
  },
  containerXS: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.DARK_KALE,
  },
  containerSM: {
    paddingTop: theme.spacing['1'],
    borderRightWidth: 1,
    borderRightColor: theme.colors.DARK_KALE,
  },
  contentContainer: {
    flexGrow: 1,
  },
  contentContainerXS: {
    paddingBottom: theme.spacing['6'],
  },
  shadowGradient: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 1, // to make the border more visible
    height: 60,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationDetailsContainerProps = {
  isFluid?: boolean;
} & Omit<ComponentProps<typeof ScrollView>, 'ref'>;

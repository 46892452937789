import type { Asset } from 'contentful';

import { useContentfulContentTypeEntry } from '@order/Contentful';
import type { useSweetpassTier } from '@order/hooks';

import { getHomeScreenHeroCardContentTag } from './utils';

// ─────────────────────────────────────────────────────────────────────────────

export const useHomeScreenHeroCardContent = (
  props: UseShortHomeScreenActionCardProps,
) => {
  const { hasActiveOrder, sweetpassTierName, shouldPauseContentLoading } =
    props;

  const contentTag = getHomeScreenHeroCardContentTag({
    sweetpassTierName,
    hasActiveOrder,
  });

  const singleSideCard =
    useContentfulContentTypeEntry<HeroCardContentSingleSided>({
      contentType: CONTENT_TYPE_ID,
      tags: [contentTag],
      pause: shouldPauseContentLoading || hasActiveOrder,
    });

  const doubleSidedCard =
    useContentfulContentTypeEntry<HeroCardContentDoubleSided>({
      contentType: CONTENT_TYPE_ID,
      tags: [contentTag],
      pause: shouldPauseContentLoading || !hasActiveOrder,
    });

  return { singleSideCard, doubleSidedCard };
};

// ─── Constants ───────────────────────────────────────────────────────────────

const CONTENT_TYPE_ID = 'heroCard';

// ─── Types ───────────────────────────────────────────────────────────────────

type UseShortHomeScreenActionCardProps = Readonly<{
  hasActiveOrder: boolean;
  shouldPauseContentLoading: boolean;
  sweetpassTierName: ReturnType<
    typeof useSweetpassTier
  >['sweetpassNextTierName'];
}>;

type HeroCardContentSingleSided = Readonly<{
  cardType: 'single-sided' | 'double-sided';

  titleFrontSide: string;
  imageFrontSide: Asset;
  ctaLabelFrontSide: string;
  ctaUrlFrontSide?: string; // URL
}>;

type HeroCardContentDoubleSided = Readonly<{
  titleBackSide: string;
  descriptionBackSide: string;
  inlineLinkUrlBackSide: string; // URL
  inlineLinkLabelBackSide: string;
  ctaLabelBackSide: string;
}> &
  HeroCardContentSingleSided;

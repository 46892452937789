import React, { useCallback, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  Button,
  IllusStoreOpening,
  Image,
  theme,
  TitleText,
} from '@sg/garnish';

import {
  type AuthRedirectTarget,
  type AuthStackRedirectParams,
} from '../../navigation/AppNavigation.props';
import { PageWrapper } from '../PageWrapper';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * "Signed-out view" component wrapped in `PageWrapper` to make it easier
 * to render it at the screen level.
 */
export const SignedOutView = (props: SignedOutViewProps) => {
  const {
    redirectTo,
    redirectToParams,
    onMount,
    onJoinOrSignInaBtnPress,
    withoutFooter,
    title,
  } = props;

  const onMountCallbackRef = useRef<SignedOutViewProps['onMount']>(onMount);

  // eslint-disable-next-line functional/immutable-data
  onMountCallbackRef.current = onMount;

  // ─── Effects ─────────────────────────────────────────────────────

  // triggers the `onMount` callback on component mount (if provided)
  useEffect(() => {
    const { current: onMountCallback } = onMountCallbackRef;

    if (onMountCallback) onMountCallback();
  }, []);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <PageWrapper safeAreaEdges={['top']} withoutFooter={withoutFooter}>
      <View testID="signed-out-view" style={styles.outerContainer}>
        <SignedOutViewContent
          title={title}
          redirectTo={redirectTo}
          redirectToParams={redirectToParams}
          onJoinOrSignInaBtnPress={onJoinOrSignInaBtnPress}
        />
      </View>
    </PageWrapper>
  );
};

/**
 * "Signed-out view" component with redirect support.
 */
export const SignedOutViewContent = (props: SignedOutViewContentProps) => {
  const {
    redirectTo = 'home',
    redirectToParams = {},
    title,
    onJoinOrSignInaBtnPress,
  } = props;

  const { navigate } = useNavigation();

  // ─── Helpers ─────────────────────────────────────────────────────

  const navigateToJoinOrSignIn = useCallback(() => {
    onJoinOrSignInaBtnPress?.();

    navigate('Auth', {
      screen: 'JoinOrSignIn',
      params: { redirect: redirectTo, ...redirectToParams },
    });
  }, [navigate, redirectToParams, onJoinOrSignInaBtnPress, redirectTo]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <View style={styles.info}>
        <Image
          source={IllusStoreOpening}
          style={styles.image}
          contentFit="contain"
        />

        <TitleText size={5}>
          {title ?? (
            <FormattedMessage
              defaultMessage="Welcome to the sweet life"
              description="Signed out view | Title"
            />
          )}
        </TitleText>
      </View>

      <View style={styles.row}>
        <Button
          onPress={navigateToJoinOrSignIn}
          style={styles.signInButton}
          size="large"
          palette="primary"
        >
          <FormattedMessage
            defaultMessage="Join or sign in"
            description="Signed out view | Join or sign in"
          />
        </Button>
      </View>
    </View>
  );
};

// ─── Props ───────────────────────────────────────────────────────────────────

type SignedOutViewProps = Readonly<{
  title?: string;
  redirectTo?: AuthRedirectTarget;
  redirectToParams?: AuthStackRedirectParams;
  onJoinOrSignInaBtnPress?: () => void;
  onMount?: () => void;
  withoutFooter?: boolean;
}>;

type SignedOutViewContentProps = Readonly<{
  title?: string;
  redirectTo?: AuthRedirectTarget;
  redirectToParams?: AuthStackRedirectParams;
  onJoinOrSignInaBtnPress?: () => void;
}>;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    paddingBottom: theme.spacing['4'],
  },
  container: {
    flexGrow: 1,
    justifyContent: 'center',
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['4'],
  },
  info: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  signInButton: {
    marginTop: theme.spacing['6'],
    marginHorizontal: 'auto',
    maxWidth: '100%',
    width: 340,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  image: {
    height: 240,
    width: 240,
    marginBottom: theme.spacing['6'],
  },
});

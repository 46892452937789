import { createModel } from 'xstate/lib/model';

import { DietaryPropertyKind } from '@order/graphql';
import { mergeKnownProperties } from '@order/utils';

// ─── MODEL ──────────────────────────────────────────────────────────────────────

const initialDietaryRestrictions: DietaryRestrictionsTypes = {
  [DietaryPropertyKind.Dairy]: false,
  [DietaryPropertyKind.Meat]: false,
  [DietaryPropertyKind.Nuts]: false,
  [DietaryPropertyKind.Gluten]: false,
  [DietaryPropertyKind.Fish]: false,
  [DietaryPropertyKind.Soy]: false,
};

const modelInitialContext: DietaryRestrictionInitialContext = {
  restrictions: initialDietaryRestrictions,
  lastKnownRestrictions: initialDietaryRestrictions,
};

const modelCreators = {
  events: {
    TOGGLE_RESTRICTION: (restriction: DietaryPropertyKind) => ({
      restriction,
    }),
    SET_RESTRICTIONS: (restrictions: Partial<DietaryRestrictionsTypes>) => ({
      restrictions,
    }),
    REVERT_RESTRICTIONS: () => ({}),
    CLEAR_RESTRICTIONS: () => ({}),
    CONFIRM_RESTRICTIONS: () => ({}),
  },
  actions: {
    onConfirmRestrictions: () => ({}),
  },
};

export const dietaryRestrictionsModel = createModel(
  modelInitialContext,
  modelCreators,
);

// ─── ACTIONS ────────────────────────────────────────────────────────────────────

const toggleRestriction = dietaryRestrictionsModel.assign((ctx, event) => {
  const { restriction } = event;

  const noRestrictionFound = ctx.restrictions[restriction] === undefined;

  return {
    restrictions: noRestrictionFound
      ? ctx.restrictions
      : { ...ctx.restrictions, [restriction]: !ctx.restrictions[restriction] },
  };
}, 'TOGGLE_RESTRICTION');

const setRestriction = dietaryRestrictionsModel.assign((ctx, event) => {
  const { restrictions = {} } = event;
  const updatedRestrictions = mergeKnownProperties<
    DietaryRestrictionsTypes,
    Partial<DietaryRestrictionsTypes>
  >(ctx.restrictions, restrictions);

  return {
    restrictions: updatedRestrictions,
    lastKnownRestrictions: updatedRestrictions,
  };
}, 'SET_RESTRICTIONS');

const revertRestrictions = dietaryRestrictionsModel.assign((ctx) => {
  return { restrictions: ctx.lastKnownRestrictions };
}, 'REVERT_RESTRICTIONS');

const clearRestrictions = dietaryRestrictionsModel.assign(
  () => ({ ...modelInitialContext }),
  'CLEAR_RESTRICTIONS',
);

const confirmRestrictions = dietaryRestrictionsModel.assign((ctx) => {
  return { lastKnownRestrictions: ctx.restrictions };
}, 'CONFIRM_RESTRICTIONS');

// ─── MACHINE ────────────────────────────────────────────────────────────────────

export const dietaryRestrictionsMachine =
  dietaryRestrictionsModel.createMachine({
    predictableActionArguments: true, // https://xstate.js.org/docs/guides/actions.html
    id: 'sgDietaryPreferencesMachine',
    context: dietaryRestrictionsModel.initialContext,
    on: {
      TOGGLE_RESTRICTION: {
        actions: toggleRestriction,
      },
      SET_RESTRICTIONS: {
        actions: setRestriction,
      },
      REVERT_RESTRICTIONS: {
        actions: revertRestrictions,
      },
      CLEAR_RESTRICTIONS: {
        actions: clearRestrictions,
      },
      CONFIRM_RESTRICTIONS: {
        actions: [confirmRestrictions, 'onConfirmRestrictions'],
      },
    },
  });

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type DietaryRestrictionsTypes = Record<DietaryPropertyKind, boolean>;

type DietaryRestrictionInitialContext = Readonly<{
  restrictions: DietaryRestrictionsTypes;
  lastKnownRestrictions: DietaryRestrictionsTypes;
}>;

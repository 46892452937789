import React, { type ComponentProps, memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { LocationResultCardLoading } from '../../../../../LocationResultCard';
import { LocationResultsListsV2Card } from '../../../../components';
import { type LocationResultsListsV2Props } from '../../../../LocationResultsListsV2.types';
import { LocationResultsListsV2ExpandButton } from '../LocationResultsListsV2ExpandButton';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsListsV2Collapsed = memo(
  (props: LocationResultsListsV2CollapsedProps) => {
    const {
      variation,
      isLoading,
      focusedLocation,
      canBeExpanded,
      interactedLocationRestaurantSlug,
      interactedLocationDeliveryAddressId,
      onExpand,
      onRecentOrNearbyLocationCardPress,
      onLocationCardPress,
      onDeliveryLocationCardPress,
    } = props;

    // ─── Styles ──────────────────────────────────────────────────────────

    const containerStyles = [
      styles.collapsedCardContainer,
      isLoading ? undefined : styles.collapsedCardContainerIdle,
    ];

    // ─────────────────────────────────────────────────────────────────────

    return (
      <View pointerEvents="box-none" style={styles.innerContainerCollapsed}>
        {canBeExpanded ? (
          <LocationResultsListsV2ExpandButton onPress={onExpand} />
        ) : null}

        <View style={containerStyles}>
          {isLoading ? (
            <LocationResultCardLoading />
          ) : (
            <LocationResultsListsV2Card
              variation={variation}
              location={focusedLocation}
              onRecentOrNearbyLocationCardPress={
                onRecentOrNearbyLocationCardPress
              }
              onLocationCardPress={onLocationCardPress}
              onDeliveryLocationCardPress={onDeliveryLocationCardPress}
              withoutOrderNowBtn={variation === 'recent-and-nearby'}
              shouldShowLocationOrderChannel={false}
              interactedLocationRestaurantSlug={
                interactedLocationRestaurantSlug
              }
              interactedLocationDeliveryAddressId={
                interactedLocationDeliveryAddressId
              }
            />
          )}
        </View>
      </View>
    );
  },
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  collapsedCardContainer: {
    backgroundColor: theme.colors.APP_BACKGROUND,
    paddingHorizontal: theme.spacing[4],
    borderWidth: 1,
    borderColor: theme.colors.LIGHT,
    borderRadius: theme.radius.large,
    ...theme.elevations[4],
  },
  collapsedCardContainerIdle: {
    paddingVertical: theme.spacing[2],
  },
  innerContainerCollapsed: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'flex-end',
    rowGap: theme.spacing[4],
    padding: theme.spacing[4],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsListsV2CollapsedProps = {
  focusedLocation: ComponentProps<
    typeof LocationResultsListsV2Card
  >['location'];
  canBeExpanded: boolean;
  onExpand: () => void;
} & Omit<
  LocationResultsListsV2Props,
  | 'isExpandedByDefault'
  | 'onLocationCardFocus'
  | 'locations'
  | 'nearbyLocations'
  | 'focusedLocationId'
  | 'isUsingSearchField'
>;

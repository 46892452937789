import React, { type ComponentProps, type ReactNode, useCallback } from 'react';
import { type ScrollView } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { theme, useOnScrollEndReached, useResponsive } from '@sg/garnish';

import { ReorderOrderLineItem } from '@order/components';
import {
  ORDER_AGAIN_TABS,
  OrderAgain,
  OrdersList,
} from '@order/features/orders';

import { ReorderTabContainer } from '../../components';
import { type useReorderOrders } from '../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const ReorderScreenOrdersTabPanel = (
  props: ReorderScreenOrdersTabPanelProps,
) => {
  const { activeTabId, reorderOrders } = props;

  // ─── Remote Data ─────────────────────────────────────────────────────

  const {
    orders,
    fetchInitialOrders,
    isFetchingInitialOrders,
    fetchMoreOrders,
    isFetchingMoreOrders,
    canFetchMoreOrders,
    mostRecentOrder,
  } = reorderOrders;

  const { match } = useResponsive();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isActiveTab = ORDER_AGAIN_TABS.ORDERS.ID === activeTabId;

  const shouldRenderEmptyView = !isFetchingInitialOrders && orders.length === 0;
  const shouldRenderLoadingPlaceholders = isFetchingInitialOrders;
  const shouldShowLoadingIndicator = isFetchingMoreOrders;
  const shouldPauseMoreOrdersFetching =
    !isActiveTab ||
    !canFetchMoreOrders ||
    shouldRenderEmptyView ||
    shouldRenderLoadingPlaceholders;

  // NOTE: Reorder can be presented in a small modal on large breakpoints and a
  // fullscreen one on the smallest breakpoint which introduces some issues
  // We use different methods of navigation per breakpoint to avoid unexpected
  // issues.
  const shouldReplaceScreenOnNavigation = match([false, true]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const fetchMoreOrdersOnScroll = useOnScrollEndReached({
    debounce: 16,
    onScrollEndReachedY: fetchMoreOrders,
    endThresholdY: theme.spacing['20'],
    pause: shouldPauseMoreOrdersFetching,
  });

  const onScroll = useCallback<OnScroll>(
    (event) => {
      fetchMoreOrdersOnScroll(event.nativeEvent);
    },
    [fetchMoreOrdersOnScroll],
  );

  // ─── Effects ─────────────────────────────────────────────────────────

  // Fetch orders on screen focus
  useFocusEffect(
    useCallback(() => {
      fetchInitialOrders();
    }, [fetchInitialOrders]),
  );

  // ─── Loading View ────────────────────────────────────────────────────

  if (shouldRenderLoadingPlaceholders) {
    return (
      <ReorderScreenOrdersTabPanelContainer
        activeTabId={activeTabId}
        shouldShowLoadingIndicator={shouldShowLoadingIndicator}
        onScroll={onScroll}
      >
        <OrdersList.FavoritesContainer>
          <OrderAgain.LoadingPlaceholder size="lg" />
        </OrdersList.FavoritesContainer>
      </ReorderScreenOrdersTabPanelContainer>
    );
  }

  // ─── Empty View ──────────────────────────────────────────────────────

  if (shouldRenderEmptyView) {
    return (
      <ReorderScreenOrdersTabPanelContainer
        activeTabId={activeTabId}
        shouldShowLoadingIndicator={shouldShowLoadingIndicator}
        onScroll={onScroll}
      >
        <OrderAgain.EmptyView.Orders />
      </ReorderScreenOrdersTabPanelContainer>
    );
  }

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ReorderScreenOrdersTabPanelContainer
      activeTabId={activeTabId}
      onScroll={onScroll}
      shouldShowLoadingIndicator={isFetchingMoreOrders}
    >
      <OrdersList.Container>
        {isFetchingInitialOrders ? (
          <OrderAgain.LoadingPlaceholder />
        ) : (
          orders.map((order) => {
            const location =
              order.deliveryOrderDetail?.address?.name ?? order.restaurant.name;

            return (
              <OrdersList.OrderContainer key={order.id}>
                <OrdersList.OrderHeader
                  date={order.wantedTime}
                  location={location}
                />

                <OrdersList.OrderLineItemsContainer>
                  {order.lineItems.map((lineItem) => {
                    return (
                      <ReorderOrderLineItem
                        key={lineItem.id}
                        shouldUseModalNavigation={true}
                        lineItem={lineItem}
                        orderDetails={order}
                        mostRecentOrder={mostRecentOrder}
                        shouldReplaceScreen={shouldReplaceScreenOnNavigation}
                      />
                    );
                  })}
                </OrdersList.OrderLineItemsContainer>
              </OrdersList.OrderContainer>
            );
          })
        )}
      </OrdersList.Container>
    </ReorderScreenOrdersTabPanelContainer>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const ReorderScreenOrdersTabPanelContainer = (
  props: ReorderScreenOrdersTabPanelContainerProps,
) => {
  const { activeTabId, children, shouldShowLoadingIndicator, onScroll } = props;

  const isActiveTab = ORDER_AGAIN_TABS.ORDERS.ID === activeTabId;

  return (
    <ReorderTabContainer
      onScroll={onScroll}
      isActive={isActiveTab}
      shouldShowLoadingIndicator={shouldShowLoadingIndicator}
    >
      <OrderAgain.OrdersTab activeTabId={activeTabId}>
        {children}
      </OrderAgain.OrdersTab>
    </ReorderTabContainer>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ReorderScreenOrdersTabPanelProps = {
  activeTabId: string;
  reorderOrders: ReturnType<typeof useReorderOrders>;
};

type ReorderScreenOrdersTabPanelContainerProps = {
  activeTabId: string;
  shouldShowLoadingIndicator: boolean;
  children: ReactNode;
  onScroll: OnScroll;
};

type OnScroll = NonNullable<ComponentProps<typeof ScrollView>['onScroll']>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BagGiftCardBalanceQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type BagGiftCardBalanceQuery = {
  readonly __typename?: 'Query';
  readonly giftCardBalance:
    | {
        readonly __typename: 'GiftCardBalance';
        readonly customerId: string;
        readonly giftCardBalance: number;
      }
    | {
        readonly __typename: 'UnableToGetGiftCardBalanceError';
        readonly errorMessage: string;
      };
};

export type RedeemGiftCardInBagMutationVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
  regCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type RedeemGiftCardInBagMutation = {
  readonly __typename?: 'Mutation';
  readonly redeemGiftCard:
    | {
        readonly __typename: 'GiftCardAssociatedWithAnotherAccountError';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'GiftCardBalance';
        readonly customerId: string;
        readonly giftCardBalance: number;
      }
    | {
        readonly __typename: 'InvalidGiftCardError';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'NoBalanceGiftCardError';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'UnableToRedeemGiftCardError';
        readonly errorMessage: string;
      };
};

export const BagGiftCardBalanceDocument = gql`
  query BagGiftCardBalance {
    giftCardBalance {
      __typename
      ... on GiftCardBalance {
        customerId
        giftCardBalance
      }
      ... on UnableToGetGiftCardBalanceError {
        errorMessage
      }
    }
  }
`;

export function useBagGiftCardBalanceQuery(
  options?: Omit<Urql.UseQueryArgs<BagGiftCardBalanceQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    BagGiftCardBalanceQuery,
    BagGiftCardBalanceQueryVariables
  >({ query: BagGiftCardBalanceDocument, ...options });
}
export const RedeemGiftCardInBagDocument = gql`
  mutation RedeemGiftCardInBag($code: String!, $regCode: String) {
    redeemGiftCard(code: $code, regCode: $regCode) {
      __typename
      ... on GiftCardBalance {
        customerId
        giftCardBalance
      }
      ... on InvalidGiftCardError {
        errorMessage
      }
      ... on GiftCardAssociatedWithAnotherAccountError {
        errorMessage
      }
      ... on UnableToRedeemGiftCardError {
        errorMessage
      }
      ... on NoBalanceGiftCardError {
        errorMessage
      }
    }
  }
`;

export function useRedeemGiftCardInBagMutation() {
  return Urql.useMutation<
    RedeemGiftCardInBagMutation,
    RedeemGiftCardInBagMutationVariables
  >(RedeemGiftCardInBagDocument);
}

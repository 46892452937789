import { useMemo } from 'react';
import { Platform } from 'react-native';
import type { Point } from 'react-native-maps';

import { MARKER_SIZE } from '../../constants';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * We utilize different marker sizes depending on whether they are minimized or not.
 * As a result, we must apply the correct vertical offset for callout components.
 *
 * NOTE: Because different props/values (see below) are used to set callout offset
 * in `react-native-maps` depending on platform OS, this hook will return the corresponding
 * values for the active platform OS.
 *
 * @see { @link https://github.com/react-native-maps/react-native-maps/blob/master/docs/marker.md }
 *
 * - iOS: `calloutOffset`
 * - Android: `calloutAnchor`
 * - Web: raw values
 */
export const useCalloutOffset = (props: UseCalloutOffset) => {
  const { isMinimized = false } = props;

  return useMemo(() => getCalloutOffset(isMinimized), [isMinimized]);
};

// ─── Constants ──────────────────────────────────────────────────────────────────

const MINIMIZED_MARKER_CALLOUT_OFFSET_Y = 0.6;
const MINIMIZED_MARKER_CALLOUT_OFFSET_Y_PX = Math.floor(
  MARKER_SIZE * MINIMIZED_MARKER_CALLOUT_OFFSET_Y,
);

// ─── Utils ──────────────────────────────────────────────────────────────────────

function getCalloutOffset(isMinimized: boolean) {
  return (
    Platform.select<Point>({
      android: {
        x: 0.5, // default value
        y: isMinimized ? MINIMIZED_MARKER_CALLOUT_OFFSET_Y : 0,
      },
      ios: {
        x: 0,
        y: isMinimized ? MINIMIZED_MARKER_CALLOUT_OFFSET_Y_PX : 0,
      },
      web: {
        x: 0,
        y: isMinimized ? MINIMIZED_MARKER_CALLOUT_OFFSET_Y_PX : 0,
      },
    }) ?? { x: 0, y: 0 }
  );
}

// ─── Types ──────────────────────────────────────────────────────────────────────

type UseCalloutOffset = Readonly<{
  isMinimized?: boolean;
}>;

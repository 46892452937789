import React from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';
import type { ImageTransform } from 'cloudinary-tiny-js';

import { IllusEmpty_2 } from '../../../assets';
import { useResponsive } from '../../../hooks';
import { FallbackImage } from '../../Image';

// ────────────────────────────────────────────────────────────────────────────────

export const LineItemThumbnail = (props: LineItemThumbnailProps) => {
  const { cloudinaryImageUrl } = props;
  const { match } = useResponsive();

  return (
    <FallbackImage
      baseUrl={cloudinaryImageUrl}
      defaultImage={IllusEmpty_2}
      contentFit="contain"
      style={match([styles.imageXs, styles.imageSm])}
      cloudinaryConfig={match([CLOUDINARY_CONFIG_XS, CLOUDINARY_CONFIG_SM])}
    />
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────────

export const THUMBNAIL_SIZE_XS = 80;
export const THUMBNAIL_SIZE_SM = 120;

const CLOUDINARY_CONFIG_XS: readonly ImageTransform[] = [
  { crop: 'crop', height: 0.6, width: 0.4 },
  { width: THUMBNAIL_SIZE_XS },
];

const CLOUDINARY_CONFIG_SM: readonly ImageTransform[] = [
  { crop: 'crop', height: 0.6, width: 0.4 },
  { width: THUMBNAIL_SIZE_SM },
];

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  imageXs: {
    marginTop: theme.spacing['1'],
    width: 80,
    height: 80,
  },
  imageSm: {
    marginTop: theme.spacing['1'],
    width: 120,
    height: 120,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────────

type LineItemThumbnailProps = Readonly<{
  cloudinaryImageUrl: string;
}>;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { useSocialLinks } from '../../hooks/useSocialLinks';
import { AccountMenuIconItem } from './AccountMenuIconItem';

export const AccountMenuIconRow = () => {
  const { t } = useLocalizationContext();
  const socialLinks = useSocialLinks(true);

  return (
    <View style={styles.iconItemsContainer}>
      <AccountMenuIconItem
        testID="account.menu.icon-item.instagram"
        label={t('account.sidebar.instagram')}
        iconName="IconInstagram"
        onPress={socialLinks.handleInstagramTelemetry}
      />
      <AccountMenuIconItem
        testID="account.menu.icon-item.facebook"
        label={t('account.sidebar.facebook')}
        iconName="IconFacebook"
        onPress={socialLinks.handleFacebookTelemetry}
      />
      <AccountMenuIconItem
        testID="account.menu.icon-item.tiktok"
        label={t('account.sidebar.tiktok')}
        iconName="IconTikTok"
        onPress={socialLinks.handleTiktokTelemetry}
      />
      <AccountMenuIconItem
        testID="account.menu.icon-item.twitter"
        label={t('account.sidebar.twitter')}
        iconName="IconTwitter"
        onPress={socialLinks.handleTwitterTelemetry}
      />
      <AccountMenuIconItem
        testID="account.menu.icon-item.spotify"
        label={t('account.sidebar.spotify')}
        iconName="IconSpotify"
        onPress={socialLinks.handleSpotifyTelemetry}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  iconItemsContainer: {
    marginTop: theme.spacing['2'],
    flexDirection: 'row',
    alignItems: 'center',
  },
});

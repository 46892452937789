import type { ComponentProps } from 'react';
import React, { forwardRef, memo } from 'react';
import { ScrollView, StyleSheet } from 'react-native';

import {
  LOCATION_RESULTS_BORDER_RADIUS,
  LOCATION_RESULTS_THEME_BG_COLOR,
} from '../../LocationResults.constants';
import { LocationResultsOtherLocationsTitle } from '../LocationResultsMoreLocationsTitle';
import {
  LocationResultsExtraScrollArea,
  LocationResultsList,
} from './subcomponents';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsLists = memo(
  forwardRef<ScrollView, LocationResultsListsProps>((props, ref) => {
    const {
      locationSearchType,
      locations = [],
      otherLocations = [],
      isExpanded,
      isLoading,
      withDetailsModal,
      containerHeightSharedValue,
      hasSingleResult,
      interactedLocationRestaurantSlug,
      onCardPress,
      onCardFocus,
      onCardLayout,
      onCardUnmount,
    } = props;

    const otherLocationsSearchType =
      locationSearchType === 'pickup' ? 'outpost' : 'pickup';

    const listsContainerStyles = [
      styles.listContainer,
      hasSingleResult ? styles.listContainerWithSingleResult : undefined,
    ];

    // ─────────────────────────────────────────────────────────────

    return (
      <ScrollView
        ref={ref}
        style={listsContainerStyles}
        scrollEnabled={isExpanded}
      >
        {/* ─── Main Locations ────────────────────────────────────────────────── */}

        <LocationResultsList
          locations={locations}
          withDetailsModal={withDetailsModal}
          interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
          onCardPress={onCardPress}
          onCardFocus={onCardFocus}
          onCardLayout={onCardLayout}
          onCardUnmount={onCardUnmount}
          isLoading={isLoading}
        />

        {/* ─── Other Locations ───────────────────────────────────────────────── */}

        {otherLocations.length > 0 && !isLoading ? (
          <>
            <LocationResultsOtherLocationsTitle
              otherLocationSearchType={otherLocationsSearchType}
            />

            <LocationResultsList
              locations={otherLocations}
              withDetailsModal={withDetailsModal}
              interactedLocationRestaurantSlug={
                interactedLocationRestaurantSlug
              }
              onCardPress={onCardPress}
              onCardFocus={onCardFocus}
              onCardLayout={onCardLayout}
              onCardUnmount={onCardUnmount}
              isLoading={isLoading}
            />
          </>
        ) : null}

        {/* ─── Extra Scroll Area ─────────────────────────────────────────────── */}

        {isExpanded ? null : (
          <LocationResultsExtraScrollArea
            containerHeightSharedValue={containerHeightSharedValue}
          />
        )}
      </ScrollView>
    );
  }),
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  listContainer: {
    backgroundColor: LOCATION_RESULTS_THEME_BG_COLOR,
  },
  listContainerWithSingleResult: {
    borderTopRightRadius: LOCATION_RESULTS_BORDER_RADIUS,
    borderTopLeftRadius: LOCATION_RESULTS_BORDER_RADIUS,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsListsProps = Readonly<{
  otherLocations?: LocationResultsListProps['locations'];
  isExpanded: boolean;
  hasSingleResult: boolean;
  locationSearchType: 'pickup' | 'delivery' | 'outpost' | undefined;
}> &
  LocationResultsListProps &
  LocationResultsExtraScrollAreaProps;

type LocationResultsListProps = ComponentProps<typeof LocationResultsList>;

type LocationResultsExtraScrollAreaProps = ComponentProps<
  typeof LocationResultsExtraScrollArea
>;

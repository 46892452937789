import type { NavigationProp } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';

import type {
  MenuTabStackParamList,
  MenuTabStackScreenName,
  ModalStackParamList,
  ModalStackScreenName,
} from '../../../../navigation/AppNavigation.props';

// The reorder flow should finish in menu if it's started on a menu screen.
export const useShouldFinishReorderInMenu = () => {
  // This needs to be the Modal Stack and will break otherwise.
  const navigation = useNavigation<NavigationProp<ModalStackParamList>>();

  // The Modal stack contains the PDP screens.
  const modalStack = navigation.getState();
  const modalRoutes = modalStack?.routes ?? [];
  const previousModalRoute = modalRoutes.at(-2);
  const previousModalRouteName = previousModalRoute?.name;

  // This only applies to the bag or reorder modals.
  const currentModalRoute = modalRoutes.at(-1);
  const currentModalRouteName = currentModalRoute?.name;
  const validModalRoutes: readonly ModalStackScreenName[] = [
    'Bag',
    'ReorderChangeLocation',
    'ReorderConfirmLocation',
    'ReorderConflictReview',
  ];
  // @ts-expect-error TS(2345): Argument of type '"ProductDetails" | "EditProductD... Remove this comment to see the full error message
  const isInValidModalRoute = validModalRoutes.includes(currentModalRouteName);

  if (!isInValidModalRoute) return false;

  // Above the Modal Stack sits the App Stack.
  const appStack = navigation.getParent()?.getState();
  const appStackRoutes = appStack?.routes ?? [];

  // Find the MainTabs Stack.
  const mainTabsStack = appStackRoutes.find(({ name }) => name === 'MainTabs');
  const mainTabsStackRoutes = (mainTabsStack?.state?.routes ??
    []) as ReadonlyArray<
    Readonly<{
      name: string;
      params: { screen: keyof MenuTabStackParamList };
    }>
  >;

  // Find the MenuTab Stack screen.
  const menuTabStack = mainTabsStackRoutes.find(
    ({ name }) => name === 'MenuTab',
  );
  const menuTabStackScreen = menuTabStack?.params?.screen;

  const menuScreenNames: readonly MenuTabStackScreenName[] = [
    'Menu',
    'DeliveryMenu',
  ];
  const menuTabContainsMenu = Boolean(
    menuTabStackScreen && menuScreenNames.includes(menuTabStackScreen),
  );

  const pdpScreenNames: readonly ModalStackScreenName[] = [
    'ProductDetails',
    'EditProductDetails',
    'DeliveryProductDetails',
    'DeliveryEditProductDetails',
  ];
  // @ts-expect-error TS(2345): Argument of type '"ProductDetails" | "EditProductD... Remove this comment to see the full error message
  const modalStackContainsPdp = pdpScreenNames.includes(previousModalRouteName);

  return menuTabContainsMenu || modalStackContainsPdp;
};

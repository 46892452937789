import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ScrollView, StyleSheet } from 'react-native';
import {
  CustomNavigationHeader,
  LoadingDots,
  Modal,
  theme,
  useResponsive,
} from '@sg/garnish';

import { Loyalty } from '@order/features/loyalty';
import { useLoyaltyContent } from '@order/hooks';

import { useLoyaltyPointHistory } from '../../hooks';

export const LoyaltyPointHistoryModal = (
  props: LoyaltyPointHistoryModalProps,
) => {
  const { availablePoints, visible, startOrder, onRequestClose } = props;
  const { match } = useResponsive();
  const { formatMessage } = useIntl();

  // ─── Query ────────────────────────────────────────────────────────────────

  const {
    pointHistory,
    pointExpirationEntries,
    canLoadMorePointHistory,
    isLoadingPointHistory,
    handleLoadMorePointHistory,
  } = useLoyaltyPointHistory();

  const hasPointsHistory = pointHistory.length > 0;

  // ─── Faq ──────────────────────────────────────────────────────────────────

  const { faqPointHistory } = useLoyaltyContent({ availablePoints: undefined });

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <Modal
      fitHeight
      size={match(['full', 'medium'])}
      maxHeight={match([undefined, '80%'])}
      presentationStyle="pageSheet"
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <ScrollView
        stickyHeaderIndices={[0]}
        contentContainerStyle={styles.container}
      >
        <CustomNavigationHeader.Container safeAreaEdges={[]}>
          <CustomNavigationHeader.Text sizeMatch={['16']}>
            {formatMessage(messages.title)}
          </CustomNavigationHeader.Text>

          <CustomNavigationHeader.Button.Right
            name="IconClose"
            accessibilityLabel={formatMessage(messages.close)}
            onPress={onRequestClose}
          />
        </CustomNavigationHeader.Container>

        <Loyalty.PointsHistory.Header availablePoints={availablePoints} />

        {isLoadingPointHistory && !hasPointsHistory ? <LoadingDots /> : null}

        {!isLoadingPointHistory && !hasPointsHistory ? (
          <Loyalty.PointsHistory.EmptyState onPress={startOrder} />
        ) : null}

        {pointExpirationEntries?.map((pointExpirationEntry) => (
          <Loyalty.PointsHistory.ExpirationRow
            key={pointExpirationEntry.id}
            {...pointExpirationEntry}
          />
        ))}

        {pointHistory.map((pointHistoryEntry) => (
          <Loyalty.PointsHistory.Row
            key={pointHistoryEntry.id}
            {...pointHistoryEntry}
          />
        ))}

        {canLoadMorePointHistory && hasPointsHistory ? (
          <Loyalty.PointsHistory.SeeMoreCta
            isLoading={isLoadingPointHistory}
            onPress={handleLoadMorePointHistory}
          />
        ) : null}

        {faqPointHistory.length > 0 ? (
          <Loyalty.FAQ entries={faqPointHistory} />
        ) : null}
      </ScrollView>
    </Modal>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Points history',
    description: 'Loyalty | Points History | Title',
  },
  close: {
    defaultMessage: 'Close',
    description: 'Loyalty | Points History | Close',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingBottom: theme.spacing['10'],
    gap: theme.spacing['6'],
    backgroundColor: theme.colors.CREAM,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyPointHistoryModalProps = {
  availablePoints: number;
  visible: boolean;
  startOrder: () => void;
  onRequestClose: () => void;
};

import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme, TitleText, useResponsive } from '@sg/garnish';

import { AddressForm, LoadingAnimation, PageWrapper } from '@order/components';
import type { Address } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

export const CheckoutAddressFormContent = (props: ContentProps) => {
  const { currentBreakpoint } = useResponsive();

  if (currentBreakpoint.isXS) {
    return <CheckoutAddressFormContentXs {...props} />;
  }

  return <CheckoutAddressFormContentSm {...props} />;
};

const CheckoutAddressFormContentXs = (props: ContentProps) => {
  const { t } = useLocalizationContext();

  return (
    <PageWrapper withHeaderTitle withoutFooter>
      <View style={styles.contentXs}>
        <TitleText style={styles.titleXs} size={5}>
          {t('delivery.complete-form.title')}
        </TitleText>

        <CheckoutAddressFormInnerContent {...props} />
      </View>
    </PageWrapper>
  );
};

const CheckoutAddressFormContentSm = (props: ContentProps) => {
  const { t } = useLocalizationContext();
  const contentMargin = useContentMarginSm();

  return (
    <PageWrapper withHeaderTitle withoutFooter>
      <View style={styles.contentWrapperSm}>
        <View style={[styles.contentSm, contentMargin]}>
          <TitleText size={4} style={styles.titleSm}>
            {t('delivery.complete-form.title')}
          </TitleText>

          <View style={styles.formWrapperSm}>
            <CheckoutAddressFormInnerContent {...props} />
          </View>
        </View>
      </View>
    </PageWrapper>
  );
};

const CheckoutAddressFormInnerContent = (props: ContentProps) => {
  const { orderAddress, isUpdatingAddress, handleOnEdit } = props;
  const { t } = useLocalizationContext();

  if (!orderAddress) return <LoadingAnimation />;

  return (
    <AddressForm
      disableAddress
      visible
      editing
      submitCta={t('delivery.complete-form.cta')}
      address={orderAddress}
      loading={isUpdatingAddress}
      onEdit={handleOnEdit}
    />
  );
};

// ─── Hooks ─────────────────────────────────────────────────────────────

const useContentMarginSm = () => {
  const { match } = useResponsive();
  const marginVertical = match([
    theme.spacing['6'],
    theme.spacing['6'],
    theme.spacing['10'],
    theme.spacing['20'],
  ]);

  return useStyle(() => ({ marginVertical }), [marginVertical]);
};

// ─── Types ─────────────────────────────────────────────────────────────

type ContentProps = Readonly<{
  orderAddress?: Partial<Address>;
  isUpdatingAddress: boolean;
  handleOnEdit: ComponentProps<typeof AddressForm>['onEdit'];
}>;

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  contentWrapperSm: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: theme.colors.KHAKI_2,
  },
  contentSm: {
    width: 484,
  },
  formWrapperSm: {
    paddingTop: theme.spacing['6'],
    backgroundColor: theme.colors.APP_BACKGROUND,
    borderRadius: theme.radius.medium,
  },
  titleSm: {
    marginBottom: theme.spacing['10'],
  },
  contentXs: {
    flex: 1,
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  titleXs: {
    marginBottom: theme.spacing['6'],
    marginHorizontal: theme.spacing['4'],
  },
});

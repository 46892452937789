import { StyleSheet } from 'react-native';
import { COLORS } from '@garnish/constants';

import {
  TagAlertPalette as Palette,
  TagAlertSize as Size,
} from '../TagAlert.types';

// -----------------------------------------------------------------------------
// ---- TEXT AND ICON STYLES ---------------------------------------------------
// -----------------------------------------------------------------------------

export const SHARED_TEXT_COLOR_STYLES = {
  // -----------------------------------------
  // ---- NEUTRAL ----------------------------
  [`${Palette.neutral}`]: { color: COLORS.NEUTRAL_2 },

  // -----------------------------------------
  // ---- NOTICE -----------------------------
  [`${Palette.notice}`]: { color: COLORS.GREEN_2 },

  // -----------------------------------------
  // ---- INVERTED ---------------------------
  [`${Palette.success}`]: { color: COLORS.SUCCESS },

  // -----------------------------------------
  // ---- CAUTION ----------------------------
  [`${Palette.caution}`]: { color: COLORS.CAUTION },

  // -----------------------------------------
  // ---- CAUTION DARK -----------------------
  [`${Palette.cautionDark}`]: { color: COLORS.TEXT_COLOR },
};

export const textStyles = StyleSheet.create({
  ...SHARED_TEXT_COLOR_STYLES,

  // -----------------------------------------
  // ---- SIZES ------------------------------
  [Size.small]: {
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: 0.2,
    left: 4,
  },
  [Size.medium]: {
    fontSize: 14,
    lineHeight: 18,
    letterSpacing: 0.1,
    left: 8,
  },
});

export const SHARED_ICON_SIZE_STYLES = {
  // -----------------------------------------
  // ---- SIZES ------------------------------
  [Size.small]: { width: 16, height: 16 },
  [Size.medium]: { width: 16, height: 16 },
};

export const iconStyles = StyleSheet.create({
  ...SHARED_TEXT_COLOR_STYLES,
  ...SHARED_ICON_SIZE_STYLES,
});

import React, { type ComponentProps } from 'react';
import { type ImageStyle, StyleSheet } from 'react-native';
import { CloudinaryImage } from '@sg/garnish';

import { ORDER_LINE_ITEM_IMAGE } from '../../OrderLineItem.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderLineItemImage = (props: OrderLineItemImageProps) => {
  const { size = 'sm', assetUrl, 'aria-label': ariaLabel } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const imageStyles = sizeStyles[size];
  const imageConfig = IMAGE_CONFIG[size];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <CloudinaryImage
      baseUrl={assetUrl}
      config={imageConfig}
      aria-label={ariaLabel}
      style={imageStyles}
    />
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const IMAGE_CONFIG: Record<OrderLineItemImageVariation, CloudinaryImageConfig> =
  {
    sm: [
      { crop: 'crop', width: 0.7, height: 0.7 },

      // NOTE: Using a little higher resolution to compensate for cropping.
      { width: ORDER_LINE_ITEM_IMAGE.SIZE.SM * 1.5 },
    ],
    md: [
      { crop: 'crop', width: 0.65, height: 0.65 },
      { width: ORDER_LINE_ITEM_IMAGE.SIZE.MD * 1.5 },
    ],
  };

// ─── Styles ──────────────────────────────────────────────────────────────────

const sizeStyles = StyleSheet.create<
  Record<OrderLineItemImageVariation, ImageStyle>
>({
  // eslint-disable-next-line react-native/no-unused-styles
  sm: {
    width: ORDER_LINE_ITEM_IMAGE.SIZE.SM,
    height: ORDER_LINE_ITEM_IMAGE.SIZE.SM,
  },
  // eslint-disable-next-line react-native/no-unused-styles
  md: {
    width: ORDER_LINE_ITEM_IMAGE.SIZE.MD,
    height: ORDER_LINE_ITEM_IMAGE.SIZE.MD,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderLineItemImageProps = {
  assetUrl: string;
  'aria-label'?: string;
  size?: OrderLineItemImageVariation;
};

type OrderLineItemImageVariation = 'sm' | 'md';

type CloudinaryImageConfig = ComponentProps<typeof CloudinaryImage>['config'];

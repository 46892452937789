import { type RefObject, useCallback } from 'react';
import { type HostedFrameRef } from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';

/**
 * Triggers the express checkout payment sheet.
 */
export const useExpressCheckout = (params: ExpressCheckoutParams) => {
  const { stripeReference, total } = params;

  const { track } = useTelemetry();

  const triggerExpressCheckout = useCallback(() => {
    stripeReference?.current?.postMessage?.(
      JSON.stringify({ type: 'SHOW_PAYMENT_SHEET', amount: total }),
    );

    track('bag.express-checkout.pressed');
  }, [stripeReference, total, track]);

  return { triggerExpressCheckout };
};

// ─── Types ──────────────────────────────────────────────────────────────────

type ExpressCheckoutParams = {
  stripeReference: RefObject<HostedFrameRef>;
  total: number;
};

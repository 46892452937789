import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardContainer = (
  props: LoyaltyOfferCardContainerProps,
) => {
  const { rounded = true, children } = props;

  return (
    <View
      style={[styles.container, rounded ? styles.containerRounded : undefined]}
    >
      {children}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: theme.colors.KALE,
  },
  containerRounded: {
    borderRadius: 10,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardContainerProps = PropsWithChildren<{
  rounded?: boolean;
}>;

/**
 * Actions for the "Home Hero CTA".
 *
 * view_menu          | opens the last order's menu
 * upgrade_sweetpass  | takes you to upgrade screen
 * explore_sweetpass  | opens the sweetpass home screen
 * explore_benefits   | takes you to sweetpass benefits screen
 * explore_challenges | takes you to sweetpass home and scrolls you down to challenges section
 * explore_rewards    | takes you to sweetpass home and scrolls you down to rewards section
 */

export const SupportedHomeHeroActions = {
  ViewMenu: 'view_menu',
  UpgradeSweetpass: 'upgrade_sweetpass',
  ExploreSweetpass: 'explore_sweetpass',
  ExploreBenefits: 'explore_benefits',
  ExploreChallenges: 'explore_challenges',
  ExploreRewards: 'explore_rewards',
};

export function isHomeHeroActionSupported(action?: string) {
  return Boolean(
    action && Object.values(SupportedHomeHeroActions).includes(action),
  );
}

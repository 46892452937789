import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { ShareIcon } from '../../ShareIcon';
import { HStack } from '../../Stack';
import { LineItemFavoriteBtn } from './LineItemFavoriteBtn';
import { LineItemQuantity } from './LineItemQuantity';
import { LineItemRemoveBtn } from './LineItemRemoveBtn';

// ────────────────────────────────────────────────────────────────────────────────

export const LineItemFooter = (props: ProductFooterProps) => {
  const {
    quantity = 0,
    onQuantityChange,
    isFavorite,
    isFetchingFavorite,
    shareBtnLabel,
    shareTooltipLabel,
    productId,
    removeBtnLabel,
    isDisabled,
    productSlug,
    onFavorite,
    onShare,
    onRemove,
  } = props;

  return (
    <View style={styles.wrapper}>
      <HStack>
        {quantity ? (
          <View style={styles.stackItem}>
            {/* @ts-expect-error TS(2741): Property 'children' is missing in type '{ isDisabl... Remove this comment to see the full error message */}
            <LineItemQuantity
              isDisabled={isDisabled}
              quantity={quantity}
              onQuantityChange={onQuantityChange}
              productSlug={productSlug}
            />
          </View>
        ) : null}

        {onFavorite ? (
          <View style={styles.stackItem}>
            <LineItemFavoriteBtn
              isActive={isFavorite}
              disabled={isFetchingFavorite}
              onPress={onFavorite}
            />
          </View>
        ) : null}

        {onShare ? (
          <View style={styles.stackItem}>
            <ShareIcon
              shareBtnLabel={shareBtnLabel}
              shareTooltipLabel={shareTooltipLabel}
              onShare={onShare}
            />
          </View>
        ) : null}
      </HStack>

      {onRemove ? (
        <LineItemRemoveBtn
          productId={productId}
          accessibilityLabel={removeBtnLabel}
          disabled={isDisabled}
          testID={`sg-line-item-card-remove-${productSlug}`}
          onPress={onRemove}
        />
      ) : null}
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing['2'],
  },
  stackItem: {
    flex: 1,
    justifyContent: 'center',
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ProductFooterProps = Readonly<{
  productId: string;
  productSlug: string;
  quantity?: number;
  removeBtnLabel?: string;
  shareTooltipLabel?: string;
  shareBtnLabel?: string;
  isFetchingFavorite?: boolean;
  isFavorite?: boolean;
  isDisabled?: boolean;
  onRemove?: (productId: string) => void;
  onShare?: () => void;
  onFavorite?: () => void;
  onQuantityChange?:
    | ((selectedQuantity: number) => void)
    | ((selectedQuantity: number) => Promise<void>);
}>;

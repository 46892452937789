import { type OperationResult } from 'urql';
import { createModel } from 'xstate/lib/model';

import { type CustomerQuery } from './graphql/customer.generated';
import { initAuthBroadcastChannel } from './utils';

// ─────────────────────────────────────────────────────────────────────────────

export const AuthModel = createModel(
  {
    authBroadcastChannel: initAuthBroadcastChannel(),
    currentCustomer: null as CurrentCustomerContextData,
  },
  {
    events: {
      LOGOUT: (csrfToken?: string) => ({ csrfToken }),
      LOGIN: (csrfToken?: string) => ({ csrfToken }),
      INIT_LOGGED_IN_SESSION: (csrfToken: string) => ({ csrfToken }),
      INIT_GUEST_SESSION: (csrfToken: string) => ({ csrfToken }),
      UPDATE_CSRF_TOKEN: (csrfToken: string) => ({ csrfToken }),
    },
  },
);

// ─── Types ───────────────────────────────────────────────────────────────────

export type CurrentCustomerContextData =
  | NonNullable<
      OperationResult<CustomerQuery, Record<string, unknown>>['data']
    >['currentCustomer']
  | null;

import { Platform } from 'react-native';

import {
  checkIfInLocalDevelopmentMode,
  getEnvVars,
  getLocalDevelopmentHost,
} from '@order/utils';

const REMOTE_ENDPOINT = 'https://order-development.sweetgreen.com/graphql';

const { GRAPHQL_ENDPOINT } = getEnvVars();

export const getGraphQLEndpoint = () => {
  return getLocalEndpoint() ?? REMOTE_ENDPOINT;
};

// For Expo to connect to local graphql, it needs to use the dynamic debugger host.
const getLocalEndpoint = (): string | undefined => {
  const endpoint = GRAPHQL_ENDPOINT as string | undefined;

  if (!endpoint) return undefined;

  const isLocalHost = endpoint?.includes('localhost');
  const isNativePlatform = Platform.OS !== 'web';
  const isDevEnvironment = checkIfInLocalDevelopmentMode();
  const isDeveloperDevice = isNativePlatform && isLocalHost && isDevEnvironment;

  if (isDeveloperDevice) {
    const host = getLocalDevelopmentHost();
    const port = 4001; // non-secure `http` apollo router

    return `http://${host}:${port}/graphql`;
  }

  return endpoint;
};

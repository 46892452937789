export const footer = {
  'footer.support-and-services.title': 'Support + Services',
  'footer.support-and-services.sweetgreen': 'sweetgreen.com',
  'footer.support-and-services.faqs': 'FAQs',
  'footer.support-and-services.contact-us': 'Contact us',
  'footer.social-media.title': 'Social Media',
  'footer.social-media.instagram': 'Instagram',
  'footer.social-media.twitter': 'Twitter',
  'footer.social-media.tiktok': 'Tiktok',
  'footer.social-media.spotify': 'Spotify',
  'footer.social-media.facebook': 'Facebook',
  'footer.terms-and-conditions.title': 'Terms + Conditions',
  'footer.terms-and-conditions.privacy': 'Privacy Policy',
  'footer.terms-and-conditions.terms': 'Terms and Conditions',
  'footer.terms-and-conditions.personal': 'Do not sell my personal information',
  'footer.download-the-app.title': 'Download the App',
  'footer.download-the-app.ios': 'iOS',
  'footer.download-the-app.android': 'Android',
  'footer.copyright': '©sweetgreen {year}',
};

/* istanbul ignore file */

import { useCallback } from 'react';
import {
  type SubmitErrorHandler,
  type SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNoticeBannersStackContext } from '@sg/garnish';

import {
  DeliveryMethods,
  type GiftCardCheckoutFormDefaultValues,
  type GiftCardCheckoutFormFieldRules,
  type GiftCardCheckoutFormReturn,
  type GiftCardCheckoutFormState,
} from '../../gift-card-checkout-form.types';
import { useGiftCardCheckout } from '../useGiftCardCheckout';
import { useGiftCardCheckoutFormValidationSchema } from '../useGiftCardCheckoutFormValidationSchema';
import { giftCardCheckoutFormMessages as messages } from './useGiftCardCheckoutForm.messages';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Provides Gift Card Checkout form helpers
 */
export const useGiftCardCheckoutForm = (
  options: UseGiftCardCheckoutFormOptions,
): UseGiftCardCheckoutFormReturnType => {
  const { rules, defaultValues, resolveNormalizedPaymentMethodId } = options;

  const { checkoutGiftCard } = useGiftCardCheckout();
  const { push: createNoticeBanner, remove: removeNoticeBanner } =
    useNoticeBannersStackContext();
  const { formatMessage } = useIntl();

  // ─── Validation ──────────────────────────────────────────────────────

  const validationSchema = useGiftCardCheckoutFormValidationSchema({
    rules,
    defaultValues,
  });

  // ─── Form ────────────────────────────────────────────────────────────

  /**
   * @see {@link https://react-hook-form.com/}
   */
  const form = useForm<GiftCardCheckoutFormState>({
    defaultValues: {
      amountVariation: 'standard',
      deliveryDateOption: 'NOW',
      deliveryMethod: DeliveryMethods.Message,
      quantity: 1,
      utcOffset: new Date().getTimezoneOffset() / 60,
      ...defaultValues,
    },
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  // ─── Helpers | Notice Banners ────────────────────────────────────────

  /**
   * Displays a custom error notice banner with predefined options.
   */
  const showErrorNoticeBanner = useCallback(() => {
    createNoticeBanner(
      {
        id: FORM_ERROR_NOTICE_BANNER_ID,
        autoHideTimeout: FORM_NOTICE_BANNER_AUTO_HIDE_TIMEOUT,
        palette: 'caution',
        text: formatMessage(messages.formSubmissionError),
      },
      true,
    );
  }, [createNoticeBanner, formatMessage]);

  /**
   * Removes a pending error notice banner.
   */
  const removeErrorNoticeBanner = useCallback(() => {
    removeNoticeBanner(FORM_ERROR_NOTICE_BANNER_ID);
  }, [removeNoticeBanner]);

  // ─── Helpers | Form Submission ───────────────────────────────────────

  /**
   * Removes all pending notice banners and submits the checkout form.
   */
  const onSubmit = useCallback<SubmitHandler<GiftCardCheckoutFormState>>(
    async (formData) => {
      // NOTE: Remove any pending error banners
      removeErrorNoticeBanner();

      const {
        deliveryDateTime,
        assetId,
        quantity,
        recipientName,
        recipientEmail,
        message,
        senderName,
        amount,
        deliveryDateOption,
        utcOffset,
        deliveryMethod,
        paymentMethodId,
      } = formData;

      const resolvedPaymentMethod = await resolveNormalizedPaymentMethodId(
        paymentMethodId,
        amount,
      );

      if (!resolvedPaymentMethod.isSuccess) {
        return;
      }

      const { paymentMethodId: resolvedPaymentMethodId } =
        resolvedPaymentMethod;

      await checkoutGiftCard({
        input: {
          assetId,
          quantity,
          recipientName,
          recipientEmail,
          message,
          senderName,
          paymentMethodId: resolvedPaymentMethodId,
          deliveryDateTime,
          utcOffset,
          balance: amount,
          isAsap: deliveryDateOption === 'NOW',
          deliveryMethods: [deliveryMethod],
        },
      });
    },
    [
      checkoutGiftCard,
      removeErrorNoticeBanner,
      resolveNormalizedPaymentMethodId,
    ],
  );

  /**
   * Handles errors on submission.
   */
  const onSubmitError = useCallback<
    SubmitErrorHandler<GiftCardCheckoutFormState>
  >(
    (errors) => {
      const errorValues = Object.values(errors);
      const firstErrorMessage = errorValues?.[0]?.message;

      if (!firstErrorMessage) return;

      showErrorNoticeBanner();
    },
    [showErrorNoticeBanner],
  );

  /**
   * @see {@link https://react-hook-form.com/docs/useform/handlesubmit}
   */
  const submitForm = form.handleSubmit(onSubmit, onSubmitError);

  // ─────────────────────────────────────────────────────────────────────

  return { form, submitForm };
};

// ─── Constants ───────────────────────────────────────────────────────────────

const FORM_NOTICE_BANNER_AUTO_HIDE_TIMEOUT = 4000;
const FORM_ERROR_NOTICE_BANNER_ID = 'gift-card-checkout-form-submit-error';

// ─── Types ───────────────────────────────────────────────────────────────────

type UseGiftCardCheckoutFormOptions = {
  resolveNormalizedPaymentMethodId: (
    currentPaymentMethodId: string,
    amount: number,
  ) => Promise<
    { isSuccess: true; paymentMethodId: string } | { isSuccess: false }
  >;
  rules: GiftCardCheckoutFormFieldRules;
  defaultValues: Partial<GiftCardCheckoutFormDefaultValues>;
};

type UseGiftCardCheckoutFormReturnType = {
  form: GiftCardCheckoutFormReturn;
  submitForm: () => Promise<void>;
};

import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';

import type { JumpNavigatorProps } from './JumpNavigation.types';

export const JumpNavigator = (props: JumpNavigatorProps) => {
  const { params } = useRoute();
  const { jumpContext, children } = props;
  const {
    hasJumpTargets,
    hasScrolledTargetsOnce,
    currentTarget,
    setCurrentTarget,
    setOffset,
  } = jumpContext;

  const navigation = useNavigation<NativeStackNavigationProp<any>>();

  // Jump Navigator Container
  const containerRef = React.useRef<View>(null);

  // Measuring Jump Offset
  const measureView = React.useCallback(() => {
    containerRef.current?.measure((_x, _y, _w, height) => {
      setOffset(height);
    });
  }, [containerRef, setOffset]);

  // Monitoring Target Switches for Anchor Links.
  useEffect(() => {
    if (!hasScrolledTargetsOnce) return;
    navigation.setParams({ target: currentTarget });

    return () => {
      const isComponentInActiveRoute = navigation.isFocused();

      if (isComponentInActiveRoute) navigation.setParams({ target: undefined });
    };
  }, [hasScrolledTargetsOnce, currentTarget, navigation]);

  // Monitoring Anchor Links.
  useEffect(() => {
    if (!hasJumpTargets || !params || hasScrolledTargetsOnce) return;
    const { target } = params as Readonly<{ target: string }>;

    setCurrentTarget(target);
  }, [params, hasScrolledTargetsOnce, hasJumpTargets, setCurrentTarget]);

  return (
    <View ref={containerRef} onLayout={measureView} {...props}>
      {children}
    </View>
  );
};

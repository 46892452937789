import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Footer as HTMLFooter } from '@expo/html-elements';
import {
  Container,
  HStack,
  theme,
  useContainerSpacing,
  useResponsive,
} from '@sg/garnish';

import { useOrganizationLinksFooterContentfulData } from '@order/hooks';

import {
  OrganizationLinksFooterCopyright,
  OrganizationLinksFooterSection,
} from './components';

// ────────────────────────────────────────────────────────────────────────────────

export const OrganizationLinksFooter = () => {
  const contentfulFooter = useOrganizationLinksFooterContentfulData();

  const containerSpacing = useContainerSpacing();
  const { currentBreakpoint, match } = useResponsive();
  const { isSM } = currentBreakpoint;

  const itemsPerRow = match([1, 2, contentfulFooter.sections.length + 2]);
  const contentStyles = match([undefined, styles.contentMarginSM, undefined]);
  const footerStyles = [styles.footer, { paddingVertical: containerSpacing }];

  return (
    <HTMLFooter style={footerStyles}>
      <Container style={styles.containerFlexDirection}>
        <HStack itemsPerRow={itemsPerRow} style={contentStyles}>
          {contentfulFooter.sections.map((section) => (
            <OrganizationLinksFooterSection
              key={section.name}
              section={section}
            />
          ))}
          {isSM ? null : <EmptyColumn />}
          {isSM ? null : <OrganizationLinksFooterCopyright />}
        </HStack>
        {isSM ? <OrganizationLinksFooterCopyright /> : null}
      </Container>
    </HTMLFooter>
  );
};

// ─── Helpers ────────────────────────────────────────────────────────────────────

const EmptyColumn = View; // For Layout with ItemsPerRow.

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  footer: {
    marginTop: 'auto',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.SKY,
  },
  containerFlexDirection: {
    flexDirection: 'row',
  },
  contentMarginSM: {
    marginRight: '33%',
  },
});

import { type Order } from '@sg/graphql-schema';

import { type WantedTime } from '@order/features/ordering';

export const mapWantedTimes = (
  availableWantedTimes: Order['availableWantedTimes'] | null | undefined,
): WantedTime[] => {
  return (
    availableWantedTimes?.map((wantedTime) => ({
      time: wantedTime.time,
      offset: wantedTime.deliveryOffset,
    })) ?? defaultWantedTimes
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const defaultWantedTimes: WantedTime[] = [];

import type { ViewStyle } from 'react-native';
import { useWindowDimensions } from 'react-native';
import {
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { LOCATION_RESULTS_COLLAPSED_HEIGHT } from '../../LocationResults.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const useLocationResultsExpandCollapseStyles = (
  props: UseLocationResultsExpandCollapseStylesProps,
) => {
  const {
    isExpanded,
    hasMultipleResults,
    hasSingleOtherResult,
    shouldShowFeeDisclaimer,
  } = props;

  // NOTE: We utilize viewport height as a default value to completely hide the results
  //       container on initial render until it is managed by the associated component.
  const { height: viewportHeight } = useWindowDimensions();
  const outerContainerHeight = useSharedValue(viewportHeight);

  const locationCardVariation = getLocationResultCardVariation({
    hasMultipleResults,
    hasSingleOtherResult,
  });
  const innerContainerCollapsedHeight = getLocationResultsCollapsedHeight({
    locationCardVariation,
    shouldShowFeeDisclaimer,
  });

  const innerContainerTranslateY = useDerivedValue(() => {
    const expandedViewTranslateY = 0;
    const collapsedViewTranslateY =
      outerContainerHeight.value - innerContainerCollapsedHeight;

    return withTiming(
      isExpanded ? expandedViewTranslateY : collapsedViewTranslateY,
    );
  });

  const innerContainerAnimatedStyle = useAnimatedStyle(() => {
    return { transform: [{ translateY: innerContainerTranslateY.value }] };
  });
  const innerContainerCollapsedStyle: ViewStyle = {
    height: innerContainerCollapsedHeight,
  };

  // ─────────────────────────────────────────────────────────────────────

  return {
    outerContainerHeight,
    innerContainerCollapsedStyle,
    innerContainerAnimatedStyle,
  };
};

// ─── Utils ───────────────────────────────────────────────────────────────────

/**
 * Returns location results collapsed container height depending on multiple conditions:
 *
 * - Whether a single location result is displayed
 * - Whether a single other location result is displayed
 */
export function getLocationResultsCollapsedHeight(
  params: GetLocationResultsCollapsedHeightParams,
) {
  const { locationCardVariation, shouldShowFeeDisclaimer } = params;

  if (shouldShowFeeDisclaimer) {
    return LOCATION_RESULTS_COLLAPSED_HEIGHT.WITH_DISCLOSURE[
      locationCardVariation
    ];
  }

  return LOCATION_RESULTS_COLLAPSED_HEIGHT.DEFAULT[locationCardVariation];
}

function getLocationResultCardVariation(
  params: GetLocationResultCardVariationParams,
): LocationCardVariation {
  const { hasMultipleResults, hasSingleOtherResult } = params;

  if (hasMultipleResults) return 'multiple-results';

  if (hasSingleOtherResult) return 'single-result-other';

  return 'single-result';
}

// ─── Types ───────────────────────────────────────────────────────────────────

type UseLocationResultsExpandCollapseStylesProps = Readonly<{
  hasMultipleResults: boolean;
  hasSingleOtherResult: boolean;
  shouldShowFeeDisclaimer: boolean;
  isExpanded: boolean;
}>;

type GetLocationResultsCollapsedHeightParams = {
  locationCardVariation: LocationCardVariation;
  shouldShowFeeDisclaimer: boolean;
};

type LocationCardVariation =
  | 'single-result'
  | 'single-result-other'
  | 'multiple-results';

type GetLocationResultCardVariationParams = {
  hasMultipleResults: boolean;
  hasSingleOtherResult: boolean;
};

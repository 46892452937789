/**
 * The options used by Contentful to transform the served image.
 */
export type ContentfulImageTransformOptions = Readonly<{
  /**
   * Desired width in pixels. Defaults to the original image width (1-4000).
   */
  w: number;

  /**
   * Desired height in pixels. Defaults to the original image height (1-4000).
   */
  h: number;

  /**
   * Desired quality of the image in percents (1-100).
   * Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  q: number;

  /**
   * Desired corner radius in pixels.
   * Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   * Defaults to `0`. Uses desired background color as padding color,
   * unless the format is `jpg` and fit is `pad`, then defaults to white.
   */
  r: number;

  /**
   * Desired resize strategy. Defaults to fitting the image into the specified dimensions.
   *
   * - `pad`: Resizes the image to the specified dimensions, padding the image if needed. Uses desired background color as padding color.
   * - `fill`: Resizes the image to the specified dimensions, cropping the image if needed.
   * - `scale`: Resizes the image to the specified dimensions, changing the original aspect ratio if needed.
   * - `crop`: Crops a part of the original image to fit into the specified dimensions.
   * - `thumb`: Creates a thumbnail from the image.
   */
  fit: 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';

  /**
   * Desired resize focus area. Defaults to `center`.
   *
   * - `center`: Focus the resizing on the center.
   * - `top`: Focus the resizing on the top.
   * - `top_right`: Focus the resizing on the top right.
   * - `right`: Focus the resizing on the right.
   * - `bottom_right`: Focus the resizing on the bottom right.
   * - `bottom`: Focus the resizing on the bottom.
   * - `bottom_left`: Focus the resizing on the bottom left.
   * - `left`: Focus the resizing on the left.
   * - `top_left`: Focus the resizing on the top left.
   * - `face`: Focus the resizing on the largest face.
   * - `faces`: Focus the resizing on the area containing all the faces.
   */
  f:
    | 'center'
    | 'top'
    | 'top_right'
    | 'right'
    | 'bottom_right'
    | 'bottom'
    | 'bottom_left'
    | 'left'
    | 'top_left'
    | 'face'
    | 'faces';

  /**
   * Desired background color, used with corner radius or `PAD` resize strategy (eg. 'ff0033').
   * Defaults to transparent (for `png` and `webp`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  bg: string;

  /**
   * Desired image format. Defaults to the original image format.
   *
   * - `jpg`: JPEG image format. To request a progressive JPEG, set `fl: 'progressive'`.
   * - `png`: PNG image format. To request an 8-bit PNG, set `fl: 'png8'`
   * - `webp`: WebP image format.
   * - `avif`: AVIF image format.
   */
  fm: 'jpg' | 'png' | 'webp' | 'gif' | 'avif';

  /**
   * - `progressive`: Progressive JPG format stores multiple passes of an image in progressively higher detail. When a progressive image is loading, the viewer will first see a lower quality pixelated version which will gradually improve in detail, until the image is fully downloaded. This is to display an image as early as possible to make the layout look as designed. Works only with `fm: 'jpg'`.
   * - `png8`: 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent. The 8-bit PNG format is mostly used for simple images, such as icons or logos. Works only with `fm: 'png'`.
   */
  fl: 'progressive' | 'png8';
}>;

import React from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const RadioContainerItemTitle = (
  props: RadioContainerItemTitleProps,
) => {
  const { children, nativeID, isDisabled } = props;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <BodyText
      nativeID={nativeID}
      bold
      sizeMatch={['14']}
      style={[styles.title, isDisabled ? styles.titleDisabled : undefined]}
    >
      {children}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  title: {
    flexShrink: 1,
  },
  titleDisabled: {
    color: theme.colors.CHARCOAL,
  },
});
// ─── Types ───────────────────────────────────────────────────────────────────

type RadioContainerItemTitleProps = Readonly<{
  children: string;
  nativeID: string;
  isDisabled?: boolean;
}>;

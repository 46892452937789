import React, { type ComponentProps, useCallback } from 'react';

import { getLocationOrderChannel } from '../../../../../../helpers';
import { type Location } from '../../../../../../machine';
import { LocationResultsCard } from '../../../LocationResultCard';
import { type LocationResultsListsV2Props } from '../../LocationResultsListsV2.types';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsListsV2Card = (
  props: LocationResultsListsV2LocationCardProps,
) => {
  const {
    variation = 'standard',
    location,
    shouldShowLocationOrderChannel,
    interactedLocationDeliveryAddressId,
    interactedLocationRestaurantSlug,
    onRecentOrNearbyLocationCardPress,
    onLocationCardPress,
    onFocus,
    onLayout,
    onUmount,
    onDeliveryLocationCardPress,
  } = props;

  // ─── Derived Data ────────────────────────────────────────────────────

  const locationOrderChannel = getLocationOrderChannel(location);
  const locationRestaurantSlug = location.restaurantSlug;
  const locationDeliveryAddressId = location.addressId;
  const isDeliveryLocation = locationDeliveryAddressId !== undefined;

  const isReordering = isDeliveryLocation
    ? locationDeliveryAddressId === interactedLocationDeliveryAddressId
    : locationRestaurantSlug === interactedLocationRestaurantSlug;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleCardPress = useCallback<OnLocationResultsCardPress>(
    (_, options) => {
      if (variation === 'recent-and-nearby') {
        onRecentOrNearbyLocationCardPress?.({ location, options });

        return;
      }

      if (locationOrderChannel === 'delivery') {
        onDeliveryLocationCardPress?.(location);
      }

      onLocationCardPress?.(location, options);
    },
    [
      location,
      locationOrderChannel,
      onDeliveryLocationCardPress,
      onLocationCardPress,
      onRecentOrNearbyLocationCardPress,
      variation,
    ],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <LocationResultsCard
      withoutOrderNowBtn={variation === 'recent-and-nearby'}
      location={location}
      shouldShowLocationOrderChannel={shouldShowLocationOrderChannel}
      key={location.id}
      onPress={handleCardPress}
      onLayout={onLayout}
      onFocus={onFocus}
      onUmount={onUmount}
      withDetailsModal={locationOrderChannel !== 'delivery'}
      isDisabled={isReordering}
      isLoading={isReordering}
    />
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsListsV2LocationCardProps = {
  variation?: LocationResultsListsV2Props['variation'];
  location: Location;
  shouldShowLocationOrderChannel: boolean;
  interactedLocationRestaurantSlug?: string;
  interactedLocationDeliveryAddressId?: string;
  onRecentOrNearbyLocationCardPress?: LocationResultsListsV2Props['onRecentOrNearbyLocationCardPress'];
  onLocationCardPress?: LocationResultsListsV2Props['onLocationCardPress'];
  onDeliveryLocationCardPress?: LocationResultsListsV2Props['onDeliveryLocationCardPress'];
} & Omit<
  LocationResultsCardProps,
  | 'locationSearchType'
  | 'withDetailsModal'
  | 'withBottomBorder'
  | 'onPress'
  | 'isLoading'
  | 'isDisabled'
>;

type OnLocationResultsCardPress = LocationResultsCardProps['onPress'];

type LocationResultsCardProps = ComponentProps<typeof LocationResultsCard>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import { CustomerDataFragmentDoc } from '../../../graphql/fragments/CustomerData.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CurrentCustomerQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CurrentCustomerQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename?: 'Customer';
    readonly id: string;
    readonly trackingUuid: string | null;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly phoneNumber: string | null;
    readonly email: string | null;
    readonly useCredit: boolean;
    readonly availableCredit: number;
    readonly addresses: ReadonlyArray<{
      readonly __typename?: 'Address';
      readonly id: string;
      readonly street: string;
      readonly secondaryStreet: string | null;
      readonly name: string | null;
      readonly city: string;
      readonly state: string;
      readonly zipCode: string;
      readonly country: string;
      readonly deliveryPreference: Types.DeliveryPreferenceType;
      readonly googlePlaceId: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly notes: string | null;
    }>;
    readonly billingAccounts: ReadonlyArray<{
      readonly __typename: 'BillingAccount';
      readonly id: string;
      readonly description: string;
      readonly nickname: string | null;
      readonly isDefault: boolean;
      readonly cardType: Types.CardType;
      readonly expirationState: Types.ExpirationState;
    }>;
    readonly credits: ReadonlyArray<{
      readonly __typename?: 'Credit';
      readonly id: string;
      readonly title: string;
      readonly description: string | null;
      readonly amount: number;
      readonly remainingAmount: number;
      readonly createdAt: string;
      readonly expiresAt: string | null;
    }>;
  } | null;
};

export const CurrentCustomerDocument = gql`
  query currentCustomer {
    currentCustomer {
      ...CustomerData
    }
  }
  ${CustomerDataFragmentDoc}
`;

export function useCurrentCustomerQuery(
  options?: Omit<Urql.UseQueryArgs<CurrentCustomerQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CurrentCustomerQuery, CurrentCustomerQueryVariables>({
    query: CurrentCustomerDocument,
    ...options,
  });
}

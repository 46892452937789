import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';
import { ImageBackground } from 'expo-image';

// ─────────────────────────────────────────────────────────────────────────────

export const AspectRatioImageBackground = (props: AspectRatioImageProps) => {
  const { style, ...imageProps } = props;

  const imgBackgroundStyles = [styles.img, style];

  return (
    <ImageBackground
      transition={theme.transitions.image}
      imageStyle={styles.imgContainer}
      style={imgBackgroundStyles}
      {...imageProps}
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  img: {
    width: '100%',
    height: '100%',
  },
  imgContainer: {
    backgroundColor: theme.colors.LINEN,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type AspectRatioImageProps = ComponentProps<typeof ImageBackground>;

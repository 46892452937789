import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { Button, useResponsive } from '@sg/garnish';

import { giftCardRedemptionCtaMessages as messages } from './GiftCardRedemptionCta.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardRedemptionCta = (props: GiftCardRedemptionCtaProps) => {
  const { redeemId } = props;

  const { navigate } = useNavigation();
  const { currentBreakpoint } = useResponsive();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToPaymentAndGiftCardScreen = useCallback(() => {
    if (currentBreakpoint.isXS) {
      navigate('Modal', {
        screen: 'RedeemGiftCard',
        params: { giftCardId: redeemId },
      });

      return;
    }

    navigate('MainTabs', {
      screen: 'AccountTab',
      params: {
        screen: 'PaymentAndGiftCards',
        params: { giftCardId: redeemId },
      },
    });
  }, [currentBreakpoint.isXS, navigate, redeemId]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Button size="large" onPress={navigateToPaymentAndGiftCardScreen}>
      <FormattedMessage {...messages.label} />
    </Button>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardRedemptionCtaProps = {
  redeemId: string;
};

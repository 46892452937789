import React, { type ReactNode } from 'react';
import { BodyText } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuHeaderDetailsText = (props: MenuHeaderDetailsTextProps) => {
  const { children } = props;

  return <BodyText sizeMatch={['16']}>{children}</BodyText>;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuHeaderDetailsTextProps = {
  children?: ReactNode;
};

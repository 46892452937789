import type { Breakpoint } from '@garnish/constants';

import type { getBreakpointsByMinWidth } from '../getBreakpointsByMinWidth';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A small helper that returns the current breakpoint as a booleans object.
 */
export function getCurrentBreakpoint(
  breakpointsByMinWidth: BreakpointsByMinWidth,
): CurrentBreakpoint {
  const { isSM, isMD, isLG } = breakpointsByMinWidth;

  return {
    isXS: !isSM,
    isSM: isSM && !isMD,
    isMD: isMD && !isLG,
    isLG,
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type CurrentBreakpoint = Record<`is${Breakpoint}`, boolean>;

type BreakpointsByMinWidth = ReturnType<typeof getBreakpointsByMinWidth>;

import {
  MenuCategoriesGridCategory,
  MenuCategoriesGridCategoryHeading,
  MenuCategoriesGridContainer,
  MenuCategoriesGridCustomProductCard,
  MenuCategoriesGridNav,
  MenuCategoriesGridNavItem,
  MenuCategoriesGridProductCardLink,
  MenuCategoriesGridProductCardPressable,
  MenuCategoriesGridProductsStack,
} from './components';

export const MenuCategoriesGrid = {
  Nav: MenuCategoriesGridNav,
  NavItem: MenuCategoriesGridNavItem,
  Container: MenuCategoriesGridContainer,
  Category: MenuCategoriesGridCategory,
  CategoryHeading: MenuCategoriesGridCategoryHeading,
  ProductsStack: MenuCategoriesGridProductsStack,
  ProductCardLink: MenuCategoriesGridProductCardLink,
  ProductCardPressable: MenuCategoriesGridProductCardPressable,
  CustomProductCard: MenuCategoriesGridCustomProductCard,
};

import React, { useCallback, useState } from 'react';
import { useToggleState } from '@sg/garnish';

import {
  AccountScreenContainer,
  SweetpassBillingHistoryModal,
  SweetpassKeepSubscriptionModal,
  SweetpassPaymentMethodAddModal,
  SweetpassPaymentMethodEditModal,
} from '@order/components';
import { useTelemetry } from '@order/Telemetry';

import type { SweetpassMembershipScreenProps } from '../../navigation/AppNavigation.props';
import {
  type useSweetpassAccountContentfulData,
  useSweetpassMembershipContent,
  type useSweetpassMembershipData,
  useSweetpassSubscriptionSwitch,
} from './hooks';
import { SweetpassMembershipScreenContent } from './SweetpassMembershipScreenContent';

export const SweetpassMembershipScreenContainer = (
  props: SweetpassMembershipScreenContainerProps,
) => {
  const { navigation, changePlanForwardsLabel, changePlanBackwardsLabel } =
    props;

  const { track } = useTelemetry();

  // ─── State ───────────────────────────────────────────────────────────

  const [editingPaymentMethodId, setEditingPaymentMethodId] = useState('');
  const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(false);
  const {
    value: isShowingBillingHistory,
    toggleOn: showBillingHistory,
    toggleOff: hideBillingHistory,
  } = useToggleState();

  // ─── Subscription Switch ─────────────────────────────────────────────

  const {
    isSwitchingBack,
    isKeepingSubscription,
    switchSubscriptionHandler,
    handleSwitchSubscriptionBack,
    closeKeepingSubscriptionModal,
  } = useSweetpassSubscriptionSwitch(props);

  // ─── Callbacks ───────────────────────────────────────────────────────

  const subscribeHandler = useCallback(
    async (planId?: string) => {
      navigation.navigate('Modal', {
        screen: 'SweetpassUpgrade',
        params: { planId },
      });
      track('sweetpass_account.upgrade_cta.tapped');
    },
    [navigation, track],
  );

  const viewBillingHistoryHandler = useCallback(() => {
    showBillingHistory();
    track('sweetpass_account.billing_history.tapped');
  }, [showBillingHistory, track]);

  // ─── Add Payment Method Modal ────────────────────────────────────────

  const addPaymentMethodHandler = useCallback(() => {
    track('sweetpass_account.add_payment_cta.tapped');
    setIsAddingPaymentMethod(true);
  }, [track]);

  const closeAddingPaymentMethodModal = useCallback(() => {
    setIsAddingPaymentMethod(false);
  }, []);

  // ─── Edit Payment Method Modal ───────────────────────────────────────

  const viewPaymentMethodHandler = useCallback(
    (paymentMethodId: string) => {
      track('sweetpass_account.add_payment_cta.tapped');
      setEditingPaymentMethodId(paymentMethodId);
    },
    [track],
  );

  const closeEditingPaymentMethodModal = useCallback(() => {
    setEditingPaymentMethodId('');
  }, []);

  // ─── Contentful ──────────────────────────────────────────────────────

  const sweetpassMembershipContent = useSweetpassMembershipContent({
    ...props,
    subscribeHandler,
    viewPaymentMethodHandler,
    addPaymentMethodHandler,
    switchSubscriptionHandler,
    viewBillingHistoryHandler,
  });

  return (
    <AccountScreenContainer screen="sweetpass-membership">
      <SweetpassMembershipScreenContent
        testID="sweetpass-membership" // this testID goes deep, will remove in a follow up PR.
        sweetpassMembershipContent={sweetpassMembershipContent}
      />

      <SweetpassPaymentMethodAddModal
        visible={isAddingPaymentMethod}
        onRequestClose={closeAddingPaymentMethodModal}
      />

      <SweetpassPaymentMethodEditModal
        paymentMethodId={editingPaymentMethodId}
        visible={Boolean(editingPaymentMethodId)}
        onRequestClose={closeEditingPaymentMethodModal}
      />

      <SweetpassKeepSubscriptionModal
        changePlanForwardsLabel={changePlanForwardsLabel}
        changePlanBackwardsLabel={changePlanBackwardsLabel}
        isSwitchingBack={isSwitchingBack}
        visible={isKeepingSubscription}
        onChangeBack={handleSwitchSubscriptionBack}
        onRequestClose={closeKeepingSubscriptionModal}
      />

      <SweetpassBillingHistoryModal
        visible={isShowingBillingHistory}
        onRequestClose={hideBillingHistory}
      />
    </AccountScreenContainer>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassMembershipScreenContainerProps = SweetpassMembershipScreenProps &
  Omit<
    ReturnType<typeof useSweetpassMembershipData>,
    | 'loyaltyProfileError'
    | 'hasMembershipError'
    | 'isSweetpassMembershipLoading'
  > &
  Omit<
    ReturnType<typeof useSweetpassAccountContentfulData>,
    'isLoadingContentfulData'
  >;

import type {
  GetContentFallbackPlacement,
  GetContentPlacementCoordinates,
  GetContentPossiblePlacements,
  GetElementCoordinates,
  MeasureElementInWindow,
  PlacementOptions,
} from './useAutomaticPlacement.types';

/**
 * Returns x and y coordinates tuple required for content placement
 * based on the corresponding measurements.
 */
export const getContentPlacementCoordinates: GetContentPlacementCoordinates = (
  props,
) => {
  const { placement, triggerMeasurements, contentMeasurements } = props;
  const { width: contentWidth, height: contentHeight } = contentMeasurements;
  const { width: triggerWidth, height: triggerHeight } = triggerMeasurements;
  const trigger = getElementCoordinates(triggerMeasurements);

  let x = 0;
  let y = 0;

  if (placement === 'top') {
    x = trigger.xStart + triggerWidth / 2 - contentWidth / 2;
    y = trigger.yStart - contentHeight;
  }

  if (placement === 'right') {
    x = trigger.xEnd;
    y = trigger.yStart + triggerHeight / 2 - contentHeight / 2;
  }

  if (placement === 'bottom') {
    x = trigger.xStart + triggerWidth / 2 - contentWidth / 2;
    y = trigger.yEnd;
  }

  if (placement === 'left') {
    x = trigger.xStart - contentWidth;
    y = trigger.yStart + triggerHeight / 2 - contentHeight / 2;
  }

  return [x, y];
};

/**
 * Returns content possible locations based on elements measurements
 * and window dimensions.
 */
export const getContentPossiblePlacements: GetContentPossiblePlacements = (
  props,
) => {
  const { contentMeasurements, triggerMeasurements, windowDimensions } = props;
  const { width: contentWidth, height: contentHeight } = contentMeasurements;
  const { width: windowWidth, height: windowHeight } = windowDimensions;
  const wrapper = getElementCoordinates(triggerMeasurements);

  return {
    top: wrapper.yStart > contentHeight,
    right: windowWidth - wrapper.xEnd > contentWidth,
    bottom: windowHeight - wrapper.yEnd > contentHeight,
    left: wrapper.xStart > contentWidth,
  };
};

/**
 * Returns the provided placement's fallback placement.
 * Ex. 'top' -> 'bottom', 'right' -> 'left'
 */
export const getFallbackPlacement: GetContentFallbackPlacement = (
  placement,
) => {
  const CONTENTS_FALLBACK_POSITIONS: Record<
    PlacementOptions,
    PlacementOptions
  > = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  };

  return CONTENTS_FALLBACK_POSITIONS[placement];
};

/**
 * Returns the element's coordinates based on its measurements,
 * start and end coordinates represented in X and Y axis.
 */
export const getElementCoordinates: GetElementCoordinates = (
  elementMeasurements,
) => {
  const { x, y, width, height } = elementMeasurements;

  return {
    xStart: x,
    xEnd: x + width,
    yStart: y,
    yEnd: y + height,
  };
};

/**
 * Returns the element's measurements using its ref.
 */
export const measureElementInWindow: MeasureElementInWindow = async (
  viewRef,
) => {
  return new Promise((resolve) => {
    viewRef?.current?.measure((_x, _y, width, height, pageX, pageY) => {
      resolve({ x: pageX, y: pageY, width, height });
    });
  });
};

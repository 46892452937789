import type { Asset } from 'contentful';

import { useContentfulContentTypeEntry } from '@order/Contentful';
import { useFeatureFlag } from '@order/LaunchDarkly';

// ─────────────────────────────────────────────────────────────────────────────

export const useGiftCards = () => {
  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );

  const { data, error, fetching } = useContentfulContentTypeEntry<GiftCards>({
    contentType: 'giftCardsScreen',
    include: 3,
    pause: !isInAppGiftCardEnabled,
  });

  const {
    heading,
    subheading,
    subheadingLinkText,
    giftCardCategories = [],
  } = data?.fields ?? {};

  return {
    error,
    fetching,
    data: {
      giftCardCategories,
      heading,
      subheading,
      subheadingLinkText,
    },
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCards = {
  heading: string;
  subheading: string;
  subheadingLinkText: string;
  giftCardCategories: {
    fields: {
      category: string;
      giftCards: {
        fields: {
          id: string;
          title: string;
          cover: Asset;
        };
      }[];
      id: string;
    };
  }[];
};

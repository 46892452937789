import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useCustomer } from '@order/Customer';
import { useTelemetry } from '@order/Telemetry';

import { useCancelOrderMutation } from '../../graphql';
import { orderCancellationMessages as messages } from '../../order-cancellation-messages';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns helpers to handle in-app order cancellations.
 */
export const useCancelOrder = (params: UseOrderCancelParams) => {
  const { orderId, restaurantId, onSuccess, onError } = params;

  // ─────────────────────────────────────────────────────────────────────

  const { track } = useTelemetry();

  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { customer } = useCustomer();
  const { formatMessage } = useIntl();

  const customerId = customer.id ?? '';

  // ─── Data ────────────────────────────────────────────────────────────

  const [cancelPendingOrderResponse, cancelPendingOrder] =
    useCancelOrderMutation();

  const { fetching: isCancellingOrder } = cancelPendingOrderResponse;

  // ─── Helpers ─────────────────────────────────────────────────────────

  /**
   * Handles successful order cancellation event by displaying the associated
   * notice banner, tracking the event, and triggering the optional
   * `onSuccess` callback.
   */
  const handleSuccess = useCallback(() => {
    addNoticeBanner({
      text: formatMessage(messages.bannerSuccessText),
      palette: 'success',
    });
    track('order_cancel.success');

    onSuccess?.();
  }, [addNoticeBanner, onSuccess, formatMessage, track]);

  /**
   * Handles failed order cancellation event by displaying the associated
   * notice banner, tracking the event, and triggering the optional
   * `onError` callback.
   */
  const handleError = useCallback(
    (errorParams: UseOrderCancelHandleErrorParams) => {
      const { id, text, reason } = errorParams;

      addNoticeBanner({ id, text, palette: 'caution' }, true);
      track('order_cancel.failure', { reason });

      onError?.();
    },
    [addNoticeBanner, onError, track],
  );

  /**
   * Attempts to cancel the order using the associated mutation.
   * - Handles both successful and unsuccessful responses.
   * - Returns the resolved data.
   */
  const cancelOrder = useCallback(async () => {
    const hasRequiredParams = Boolean(customerId && orderId && restaurantId);

    if (!hasRequiredParams) return;

    const response = await cancelPendingOrder(
      {
        input: {
          orderId,
          customerId,
          restaurantId,
          reason: 'customer initiated cancel',
          origin: 'customer cancel',
          refund: true,
        },
      },
      { requestPolicy: 'cache-and-network' },
    );

    const responseData = response.data?.cancelOrder;
    const responseTypename = responseData?.__typename ?? '';

    // ─── Success ─────────────────────────────────────────────────────────

    const hasSuccessfullyCanceledOrder =
      responseData?.__typename === 'CancelOrderSuccess' && responseData.success;

    if (hasSuccessfullyCanceledOrder) {
      handleSuccess();

      return responseData;
    }

    /*
      This is a partially successful case in which we need to display an
      error banner but also call the `onSuccess` callback because the
      order was canceled but the refund failed.
     */
    if (responseData?.__typename === 'CancelSuccessRefundFailed') {
      handleError({
        id: responseTypename,
        text: formatMessage(messages.bannerSuccessButRefundFailedText),
        reason: responseData.errorMessage,
      });
      onSuccess?.();

      return;
    }

    // ─── Errors ──────────────────────────────────────────────────────────

    const isSpecialCaseError =
      responseData?.__typename === 'CancellationFailedWithDelivery' ||
      responseData?.__typename === 'CancellationLimitExceeded' ||
      responseData?.__typename === 'OrderAlreadyCanceled';
    const isGenericError = responseData?.__typename === 'CancelOrderFailed';

    if (isSpecialCaseError) {
      handleError({
        id: responseTypename,
        text: formatMessage(messages.bannerErrorCustomerSupportText),
        reason: responseData.errorMessage,
      });

      return;
    }

    if (responseData?.__typename === 'OrderAlreadySentToKitchen') {
      handleError({
        id: responseTypename,
        text: formatMessage(messages.bannerErrorAlreadyPreparingText),
        reason: responseData.errorMessage,
      });

      return;
    }

    const errorMessage = isGenericError
      ? responseData.errorMessage
      : formatMessage(messages.bannerGenericErrorText);

    handleError({
      id: responseTypename,
      text: formatMessage(messages.bannerGenericErrorText),
      reason: errorMessage,
    });
  }, [
    customerId,
    handleError,
    handleSuccess,
    orderId,
    restaurantId,
    formatMessage,
    cancelPendingOrder,
    onSuccess,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return { cancelOrder, isCancellingOrder };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseOrderCancelParams = Readonly<{
  orderId: string;
  restaurantId: string;
  onSuccess?: () => void;
  onError?: () => void;
}>;

type UseOrderCancelHandleErrorParams = Readonly<{
  id: string;
  text: string;
  reason: string;
}>;

/* istanbul ignore file */

import {
  OrderAgainBagButton,
  OrderAgainCloseButton,
  OrderAgainConflictReview,
  OrderAgainEmptyView,
  OrderAgainFavoritesTab,
  OrderAgainHeader,
  OrderAgainHeaderTitle,
  OrderAgainInfiniteLoadingIndicator,
  OrderAgainLoadingPlaceholder,
  OrderAgainOrderTab,
  OrderAgainTabs,
} from './components';

export const OrderAgain = {
  LoadingPlaceholder: OrderAgainLoadingPlaceholder,
  CloseButton: OrderAgainCloseButton,
  EmptyView: OrderAgainEmptyView,
  Header: OrderAgainHeader,
  HeaderTitle: OrderAgainHeaderTitle,
  Tabs: OrderAgainTabs,
  OrdersTab: OrderAgainOrderTab,
  FavoritesTab: OrderAgainFavoritesTab,
  BagButton: OrderAgainBagButton,
  InfiniteLoadingIndicator: OrderAgainInfiniteLoadingIndicator,
  ConflictReview: OrderAgainConflictReview,
};

import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, theme } from '@sg/garnish';

export const JoinSgRewardsCta = (props: JoinSgRewardsCtaProps) => {
  const { text, onPress, isLoading = false } = props;

  return (
    <Button
      style={styles.button}
      size="large-wide"
      palette="sweetCorn"
      onPress={onPress}
      isLoading={isLoading}
    >
      {text}
    </Button>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  button: {
    marginHorizontal: theme.spacing['14'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type JoinSgRewardsCtaProps = {
  text: string;
  onPress: () => void;
  isLoading?: boolean;
};

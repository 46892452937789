import type { ComponentProps } from 'react';
import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { BodyText } from '../../Text';
import { NOTICE_BANNER_PADDING, NoticeBanner } from '../NoticeBanner';
import { NoticeBannersStackWrapper } from './components';
import type { NoticeBannersStackItems } from './NoticeBannersStackProvider';
import { useNoticeBannersStackContext } from './NoticeBannersStackProvider';

// ────────────────────────────────────────────────────────────────────────────────

export const NoticeBannersStack = (props: NoticeBannersStackProps) => {
  const {
    targetId,
    bannerPadding = NOTICE_BANNER_PADDING,
    style,
    ...restProps
  } = props;

  const { stack, pop } = useNoticeBannersStackContext();
  const filteredStack = stack.filter((banner) => banner.targetId === targetId);
  const activeBanner = filteredStack[0];

  if (!activeBanner) return null;

  const {
    id,
    onClose,
    autoHideTimeout = 'auto',
    ...restBannerProps
  } = activeBanner;

  // ─── HELPERS ─────────────────────────────────────────────────────

  const popActiveBanner = () => {
    pop();
    onClose?.();
  };

  return (
    <NoticeBannersStackWrapper style={style} {...restProps}>
      <NoticeBanner
        key={id}
        autoHideTimeout={autoHideTimeout}
        onClose={popActiveBanner}
        padding={bannerPadding}
        {...restBannerProps}
      />
      <NoticeBannersStackMoreBanners stack={filteredStack} />
    </NoticeBannersStackWrapper>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const NoticeBannersStackMoreBanners = (
  props: Readonly<{
    stack: NoticeBannersStackItems;
  }>,
) => {
  const { currentBreakpoint } = useResponsive();
  const { stack } = props;

  const wrapperResponsiveStyles = currentBreakpoint.isXS
    ? styles.moreBannersXSmall
    : styles.moreBannersSmallUp;
  const wrapperStyle = [styles.moreBanners, wrapperResponsiveStyles];

  return stack.length > 1 ? (
    <View style={wrapperStyle}>
      <View style={styles.moreBannersContent}>
        <BodyText size={5} style={styles.moreBannersText}>
          +{stack.length - 1}
        </BodyText>
      </View>
    </View>
  ) : null;
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  moreBanners: {
    alignItems: 'center',
  },
  moreBannersXSmall: {
    marginBottom: theme.spacing['4'],
  },
  moreBannersSmallUp: {
    marginTop: theme.spacing['4'],
  },
  moreBannersContent: {
    minWidth: 45,
    backgroundColor: theme.colors.NEUTRAL_7,
    borderRadius: theme.radius.medium,
    padding: theme.spacing['2'],
    ...theme.elevations['4'],
  },
  moreBannersText: {
    textAlign: 'center',
  },
});

// ────────────────────────────────────────────────────────────────────────────────

type NoticeBannersStackProps = Readonly<{
  targetId?: string;
  bannerPadding?: ComponentProps<typeof NoticeBanner>['padding'];
}> &
  ViewProps;

import { useMemo } from 'react';

import type { Address } from '@order/graphql';

import {
  useReorderDeliveryAddressDataQuery,
  useReorderDeliveryRestaurantDataQuery,
} from '../GraphQL/ReorderData.generated';
import type { ReorderDeliveryDetails } from '../useReorderIfRequired.types';

/**
 * Resolves target delivery details' necessary fields.
 */
export const useDeliveryDetailsForReorder = (
  addressId: Address['id'] | undefined,
) => {
  const shouldPauseDeliveryRestaurantQuery = !addressId;

  const [{ data }] = useReorderDeliveryRestaurantDataQuery({
    variables: { input: { addressId } },
    requestPolicy: 'cache-first',
    pause: shouldPauseDeliveryRestaurantQuery,
  });
  const [{ data: deliveryAddressData }] = useReorderDeliveryAddressDataQuery({
    variables: { addressId: addressId ?? '' },
    requestPolicy: 'cache-first',
    pause: shouldPauseDeliveryRestaurantQuery,
  });

  const deliveryLocationData = data?.locationByAddress;
  const restaurantData =
    deliveryLocationData?.__typename === 'Location'
      ? deliveryLocationData
      : undefined;
  const deliveryAddressId = deliveryAddressData?.address?.id;
  const deliveryAddressName = deliveryAddressData?.address?.name ?? '';
  const restaurantVendorName = restaurantData?.vendor?.name;
  const restaurantVendorRestaurantId = restaurantData?.vendor?.restaurantId;

  // ─────────────────────────────────────────────────────────────────
  return useMemo<ReorderDeliveryDetails | undefined>(() => {
    if (!deliveryAddressId) return;

    return {
      addressId,
      addressName: deliveryAddressName,
      vendor: restaurantVendorName,
      vendorRestaurantId: restaurantVendorRestaurantId,
    };
  }, [
    addressId,
    deliveryAddressId,
    deliveryAddressName,
    restaurantVendorName,
    restaurantVendorRestaurantId,
  ]);
};

import React from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const RadioContainerItemFeaturedText = (
  props: RadioContainerItemFeaturedTextProps,
) => {
  const { children } = props;

  return (
    <BodyText sizeMatch={['14']} style={styles.featuredText}>
      {children}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  featuredText: {
    color: theme.colors.SPINACH,
  },
});
// ─── Types ───────────────────────────────────────────────────────────────────

type RadioContainerItemFeaturedTextProps = Readonly<{
  children: string;
}>;

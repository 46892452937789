import { defineMessages, useIntl } from 'react-intl';

/**
 * Returns different verbiages of promo codes or rewards depending on what is available.
 */
export const useRewardsPromoHeading = (props: UseRewardsPromoHeadingProps) => {
  const { shouldShowPromoCodeField } = props;
  const { formatMessage } = useIntl();

  if (shouldShowPromoCodeField) {
    return formatMessage(messages.promoRewardHeading);
  }

  return formatMessage(messages.rewardHeading);
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  promoRewardHeading: {
    defaultMessage: 'Apply promo or reward',
    description: 'Bag | Promo Rewards | Title',
  },
  rewardHeading: {
    defaultMessage: 'Apply a reward',
    description: 'Bag | Rewards | Title',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

export type UseRewardsPromoHeadingProps = {
  shouldShowPromoCodeField: boolean;
};

import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { Button, theme, useResponsive } from '@sg/garnish';

import type {
  SweetpassBenefitsCta,
  SweetpassBenefitsPageUpgradeCtaHandler,
} from './hooks';

export const SweetpassBenefitsPageCtaContent = ({
  content,
  ctaHandler,
}: SweetpassBenefitsPageCtaContentProps) => {
  const { match } = useResponsive();
  const paddingHorizontal = useCtaPadding();
  const ctaStyle = useStyle(() => ({ paddingHorizontal }), [paddingHorizontal]);

  const handleOnPress = useCallback(() => {
    ctaHandler(content.action);
  }, [content.action, ctaHandler]);

  return (
    <View style={styles.ctaWrapper}>
      <Button
        testID="sweetpass-benefits-page-cta"
        size="large"
        width={match(['100%', undefined])}
        palette={content.palette}
        style={ctaStyle}
        onPress={handleOnPress}
      >
        {content.title}
      </Button>
    </View>
  );
};

// ─── Hooks ─────────────────────────────────────────────────────────────

/**
 * The text for this button is very long, so we reduce padding to compensate.
 */
const useCtaPadding = () => {
  // Design requirements.
  const maxPadding = 48;
  const minPadding = 6;

  // How much space we have for the button.
  const { screenDimensions } = useResponsive();

  // Width after which we start to need padding.
  const maxWidth = 420;

  // How much we lack in space that we need to compensate by reducing padding.
  const lackOfSpace = maxWidth - screenDimensions.width;

  // Padding reduction needed to compensate for lack of space.
  const spaceForPadding = Math.max(maxPadding - lackOfSpace, 0);

  // Minimum padding required.
  const requiredPadding = spaceForPadding + minPadding;

  // Maximum padding allowed.
  const limitedPadding = Math.min(requiredPadding, maxPadding);

  return limitedPadding;
};

// ─── Types ─────────────────────────────────────────────────────────────

type SweetpassBenefitsPageCtaContentProps = Readonly<{
  content: SweetpassBenefitsCta;
  ctaHandler: SweetpassBenefitsPageUpgradeCtaHandler;
}>;

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  ctaWrapper: {
    marginTop: theme.spacing['10'],
    alignItems: 'center',
    justifyContent: 'center',
  },
});

import React, { useCallback, useMemo, useRef } from 'react';
import { StyleSheet } from 'react-native';
import useMergedRef from '@react-hook/merged-ref';
import Constants from 'expo-constants';
import GoogleMapReact from 'google-map-react';

import { useMapAutoBounds } from '../hooks';
import { Marker } from '../Marker';
import type { GoogleMapRef, MapProps } from '../types';
import { DEFAULT_ZOOM_LEVEL, MAX_ZOOM_LEVEL } from '../utils';
import { MAP_STYLES } from './map-styles';

// ─────────────────────────────────────────────────────────────────────────────

export const Map = React.forwardRef((props: MapProps, ref) => {
  const {
    draggable = true,
    onPanDrag,
    onRegionChange,
    onMapPress,
    onPinPress,
    onCalloutPress,
    onZoomChange,
    region,
    pins,
    showControls = true,
    edgePadding,
    isOffsetEnabled = false,
    isAutoFitBoundsEnabled = false,
  } = props;

  const mapRef = useRef<GoogleMapRef>(null);
  const multiRef = useMergedRef(ref, mapRef);
  const googleMapRef = mapRef.current?.map_;

  const options = useMemo<GoogleMapReact.MapOptions>(
    () => ({ ...MAP_OPTIONS, disableDefaultUI: !showControls }),
    [showControls],
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onChange = useCallback(
    (event: GoogleMapReact.ChangeEventValue) => {
      const { center, zoom, bounds } = event;

      const topLeft = bounds.nw;
      const bottomRight = bounds.se;

      onRegionChange?.({
        ...center,
        zoom,
        topLeft: {
          longitude: topLeft.lng,
          latitude: topLeft.lat,
        },
        bottomRight: {
          longitude: bottomRight.lng,
          latitude: bottomRight.lat,
        },
      });
    },
    [onRegionChange],
  );

  // ─── Effects ─────────────────────────────────────────────────────────

  useMapAutoBounds({
    isEnabled: isAutoFitBoundsEnabled,
    mapRef: googleMapRef,
    region,
    pins,
    edgePadding: isOffsetEnabled ? edgePadding : undefined,
  });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <GoogleMapReact
      draggable={draggable}
      ref={multiRef}
      options={options}
      style={StyleSheet.absoluteFillObject}
      bootstrapURLKeys={GOOGLE_MAPS_BOOTSTRAP_URL_KEYS}
      onClick={onMapPress}
      zoom={getMapZoomLevel(region.zoom)}
      center={region}
      onDrag={onPanDrag}
      onZoomAnimationStart={onZoomChange}
      onChange={onChange}
    >
      {pins.map((pin) => (
        <Marker
          key={pin.id}
          {...pin}
          onPinPress={onPinPress}
          onCalloutPress={onCalloutPress}
        />
      ))}
    </GoogleMapReact>
  );
});

// ─── Constants ───────────────────────────────────────────────────────────────

const MAP_OPTIONS: GoogleMapReact.MapOptions = {
  clickableIcons: false,
  styles: MAP_STYLES,
  maxZoom: MAX_ZOOM_LEVEL,
};

const GOOGLE_API_KEY =
  Constants.expoConfig?.extra?.GOOGLE_API_KEY ??
  /* cspell:disable */
  'AIzaSyAOwUvcwH8zVTzod168FnuwZemtL6fBjaA';

const GOOGLE_MAPS_BOOTSTRAP_URL_KEYS: GoogleMapReact.BootstrapURLKeys = {
  key: GOOGLE_API_KEY,
  libraries: ['places'],
};

// ─── Utils ───────────────────────────────────────────────────────────────────

function getMapZoomLevel(regionZoomLevel?: number) {
  return Math.min(regionZoomLevel ?? DEFAULT_ZOOM_LEVEL, MAX_ZOOM_LEVEL);
}

import React from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';
import { Link } from '@react-navigation/native';
import { slugify } from '@sg/garnish';

import { menuCategoriesGridProductCardLinkMessages as messages } from './MenuCategoriesGridProductCardLink.messages';
import { menuCategoriesGridProductCardLinkStyles as styles } from './MenuCategoriesGridProductCardLink.styles';
import { type MenuCategoriesGridProductCardLinkProps } from './MenuCategoriesGridProductCardLink.types';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuCategoriesGridProductCardLink = (
  props: MenuCategoriesGridProductCardLinkProps,
) => {
  const { to, productName, isNavigationEnabled, children, onPress } = props;

  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const a11yLabel = formatMessage(messages.a11yLabel, {
    product_name: productName,
  });

  // ─────────────────────────────────────────────────────────────────────

  if (!isNavigationEnabled) {
    return <View style={styles.container}>{children}</View>;
  }

  return (
    <Link
      testID={`${slugify(productName)}-product-card-pressable`}
      to={to}
      onPress={isNavigationEnabled ? onPress : undefined}
      aria-label={a11yLabel}
      style={styles.container}
    >
      {children}
    </Link>
  );
};

export type TabItems = readonly TabItem[];

export type TabItem = Readonly<{
  id: TabItemId;
  panelId: string;
  label: string;
  isDisabled?: boolean;
  isFluid?: boolean;
}>;

export type TabItemId = string;

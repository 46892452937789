import { useFeatureFlag } from '@order/LaunchDarkly';

import { useLineItemRecommendationsQuery } from '../../../../components/MenuPersonalizationRail/GraphQL';

// ────────────────────────────────────────────────────────────────────────────────

export const usePersonalizedRecommendations = () => {
  const isRecommendationsEnabled = useFeatureFlag(
    'em-716-personalized-bowl-ux',
  );

  const [menuPersonalizationResponse] = useLineItemRecommendationsQuery({
    requestPolicy: 'cache-and-network',
    pause: !isRecommendationsEnabled,
  });

  const { fetching: isFetching, data } = menuPersonalizationResponse;

  const menuRecommendationsList =
    data?.lineItemRecommendations ?? MENU_RECOMMENDATIONS_LIST_FALLBACK;

  return { menuRecommendationsList, isFetching };
};

const MENU_RECOMMENDATIONS_LIST_FALLBACK: never[] = [];

import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Scrollspy, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationIngredientsGridContainer = (
  props: CustomizationIngredientsGridSectionProps,
) => {
  const { children, ...restProps } = props;

  return (
    <View style={styles.outerContainer}>
      <Scrollspy.ScrollView
        contentContainerStyle={styles.contentContainer}
        {...restProps}
      >
        {children}
      </Scrollspy.ScrollView>

      <LinearGradient
        locations={SHADOW_GRADIENT_LOCATIONS}
        pointerEvents="none"
        colors={SHADOW_GRADIENT_COLORS}
        style={styles.shadowGradient}
      />
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const SHADOW_GRADIENT_LOCATIONS = [0, 1];
const SHADOW_GRADIENT_COLORS = [
  theme.colors.OPACITY.OATMEAL.TRANSPARENT,
  theme.colors.OATMEAL,
];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    backgroundColor: theme.colors.OATMEAL,
  },
  contentContainer: {
    maxWidth: 1040,
    paddingBottom: theme.spacing['10'],
  },
  shadowGradient: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    height: 60,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationIngredientsGridSectionProps = ComponentProps<
  typeof Scrollspy.ScrollView
>;

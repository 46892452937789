import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import type { AddressType } from '@sg/garnish';
import {
  BodyText,
  Button,
  FixedNoticeBanner,
  theme,
  TitleText,
  useResponsive,
} from '@sg/garnish';

import { useChallengesAndRewards } from '@order/ChallengesAndRewards';
import { useCart } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

export const Header = ({ orderType }: HeaderProps) => {
  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  const headerStyle = match([styles.headerXS, styles.headerSM]);

  return (
    <View style={headerStyle}>
      <TitleText size={5}>{t('bag-title', { orderType })}</TitleText>
    </View>
  );
};

export const BagNavigation = (props: BagNavigationProps) => {
  const { navigateToCheckout, navigateToMenu, isFetchingUpgradedRewards } =
    props;

  const { isCartEmpty, isFetchingCart, total, cartOrderType, deliveryDetails } =
    useCart();
  const { t } = useLocalizationContext();
  const { track } = useTelemetry();
  const { applyReward, isFetchingRewards, isApplyingReward, isRemovingReward } =
    useChallengesAndRewards();

  const handlePressCheckOut = useCallback(async () => {
    const { hasApplyRewardFailed } = await applyReward();

    if (hasApplyRewardFailed) return;

    track('bag.checkout', { total });

    navigateToCheckout();
  }, [total, applyReward, track, navigateToCheckout]);

  const viewMenuTestId = `bag.view-menu${isFetchingCart ? '-loading' : ''}`;
  const continueTestId = `bag.checkout${isFetchingCart ? '-loading' : ''}`;

  const canCheckout =
    cartOrderType !== 'delivery' || !deliveryDetails.hasNotMetDeliveryMinimum;
  const shouldShowLoadingCheckoutBtn = isApplyingReward || isRemovingReward;
  const shouldDisableCheckoutBtn =
    !canCheckout ||
    isFetchingCart ||
    isFetchingRewards ||
    isFetchingUpgradedRewards;
  const safeAreaInsets = useSafeAreaInsets();

  const wrapperSafeAreaPaddingStyle = useStyle(
    () => ({
      paddingTop: WRAPPER_PADDING,
      paddingLeft: WRAPPER_PADDING + safeAreaInsets.left,
      paddingRight: WRAPPER_PADDING + safeAreaInsets.right,
      paddingBottom: Math.max(WRAPPER_PADDING, safeAreaInsets.bottom),
    }),
    [safeAreaInsets],
  );
  const wrapperStyles = [styles.wrapper, wrapperSafeAreaPaddingStyle];

  if (isCartEmpty) return null;

  return (
    <>
      <BagWarning />

      <View style={wrapperStyles}>
        <View style={styles.button}>
          <Button
            size="large"
            testID={viewMenuTestId}
            accessibilityLabel={t('bag.view-menu')}
            onPress={navigateToMenu}
            palette="secondary"
            isLoading={isFetchingCart}
          >
            {t('bag.view-menu')}
          </Button>
        </View>
        <View style={styles.separator} />
        <View style={styles.button}>
          <Button
            size="large"
            testID={continueTestId}
            accessibilityLabel={t('general.check-out')}
            onPress={handlePressCheckOut}
            disabled={shouldDisableCheckoutBtn}
            isLoading={shouldShowLoadingCheckoutBtn}
          >
            {t('general.check-out')}
          </Button>
        </View>
      </View>
    </>
  );
};

const BagWarning = () => {
  const { t, formatPrice } = useLocalizationContext();
  const { deliveryDetails } = useCart();

  if (
    !deliveryDetails?.hasNotMetDeliveryMinimum ||
    !deliveryDetails?.minSubtotal
  ) {
    return null;
  }

  return (
    <FixedNoticeBanner
      palette="caution"
      text={t('bag.delivery-minimum', {
        minimum: formatPrice(deliveryDetails.minSubtotal, 'USD'),
      })}
    />
  );
};

export const BagRewardNotice = () => {
  const { t } = useLocalizationContext();

  return (
    <BodyText size={4} style={styles.rewardNotice}>
      {t('bag.rewards.disclaimer')}
    </BodyText>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTopWidth: 1,
    borderTopColor: theme.colors.OPACITY.DARK_KALE.DARK,
  },
  button: {
    flex: 1,
  },
  separator: {
    marginHorizontal: theme.spacing['1'],
  },
  headerXS: {
    paddingBottom: theme.spacing['2'],
  },
  headerSM: {
    paddingBottom: theme.spacing['4'],
  },
  rewardNotice: {
    color: theme.colors.CHARCOAL,
  },
});

const WRAPPER_PADDING = theme.spacing['4'];

// ─── TYPES ─────────────────────────────────────────────────────────────────────

type HeaderProps = Readonly<{
  orderType: AddressType;
}>;

type BagNavigationProps = Readonly<{
  navigateToCheckout: () => void;
  navigateToMenu: () => void;

  // Indicates whether the app is fetching new rewards following the Sweetpass upgrade.
  isFetchingUpgradedRewards: boolean;
}>;

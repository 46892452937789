import React, {
  type ComponentProps,
  type MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  type LayoutChangeEvent,
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  ScrollView,
  type ScrollViewProps,
  type ViewStyle,
} from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const ScrollspyNav = (props: ScrollspyNavProps) => {
  const {
    children,
    gap = theme.spacing['1'],
    stagePadding,
    contentContainerStyle,
    register,
    deregister,
    storeSize,
    trackScroll,
    onLayout,
    onScroll,
    ...scrollViewProps
  } = props;

  // ─── Refs ────────────────────────────────────────────────────────────

  const ref = useRef<ScrollView>(null);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnLayout = useCallback(
    (event: LayoutChangeEvent) => {
      storeSize?.(event);
      onLayout?.(event);
    },
    [onLayout, storeSize],
  );

  const handleOnScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      trackScroll?.(event);
      onScroll?.(event);
    },
    [onScroll, trackScroll],
  );

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    register(ref);

    return () => {
      deregister();
    };
  }, [deregister, register]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const dynamicStyles = useMemo<ViewStyle>(
    () => ({
      columnGap: gap,
      paddingHorizontal: stagePadding,
    }),
    [gap, stagePadding],
  );
  const contentContainerStyles = [dynamicStyles, contentContainerStyle];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ScrollView
      ref={ref}
      showsHorizontalScrollIndicator={false}
      horizontal
      contentContainerStyle={contentContainerStyles}
      onLayout={handleOnLayout}
      onScroll={handleOnScroll}
      scrollEventThrottle={1}
      {...scrollViewProps}
    >
      {children}
    </ScrollView>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ScrollspyNavProps = {
  /**
   * @default 4(px)
   */
  gap?: ViewStyle['gap'];
  stagePadding?: number;

  register: (ref: MutableRefObject<ScrollView | null>) => void;
  deregister: () => void;
  trackScroll: ScrollViewProps['onScroll'];
  storeSize: ScrollViewProps['onLayout'];
} & Omit<
  ComponentProps<typeof ScrollView>,
  'horizontal' | 'scrollEventThrottle'
>;

import React, { useCallback } from 'react';
import { openUrl } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { ShareOption } from './ShareOption';
import type { ShareOptionProps } from './types';

export const TwitterShareOption = ({ shareLink }: ShareOptionProps) => {
  const { t } = useLocalizationContext();

  const handleShare = useCallback(async () => {
    const shareUrl = `https://twitter.com/intent/tweet?text=${t(
      'account.refer-friend.share.content',
      { shareLink },
    )}`;

    await openUrl(shareUrl, t('general.error'));
  }, [t, shareLink]);

  return (
    <ShareOption
      testID="account.refer-friend.share.twitter"
      hint={t('account.refer-friend.share.twitter')}
      iconName="IconTwitter"
      iconSize={40}
      bgColor="#55ACEE"
      handleShare={handleShare}
    />
  );
};

import React, { useCallback, useRef } from 'react';
import { Pressable, StyleSheet, type View } from 'react-native';
import { Icon, theme, usePressableState, webOnlyStyles } from '@sg/garnish';

import { openLaunchDarklyUrl } from './helpers';

// ─────────────────────────────────────────────────────────────────────────────

export const FeatureFlagOpenUrlButton = (
  props: FeatureFlagOpenUrlButtonProps,
) => {
  const { name } = props;

  // ─── Refs ────────────────────────────────────────────────────────────

  const ref = useRef<View>(null);
  const { isHovered, isActive } = usePressableState(ref);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onPress = useCallback(() => {
    openLaunchDarklyUrl(name);
  }, [name]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const buttonStyles = [
    styles.button,
    buttonWebStyles,
    isHovered ? styles.buttonHover : undefined,
    isActive ? buttonActiveWebStyles : undefined,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      accessibilityRole="button"
      ref={ref}
      style={buttonStyles}
      onPress={onPress}
    >
      <Icon name="IconLinkOut" color={theme.colors.WHITE} />
    </Pressable>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  button: {
    backgroundColor: theme.colors.DARK_KALE,
    color: theme.colors.WHITE,
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing['3'],
    borderRadius: theme.radius.medium,
  },
  buttonHover: {
    opacity: 0.7,
  },
});

const buttonWebStyles = webOnlyStyles({
  transition: `
    background-color ${theme.transitions.base}ms,
    opacity ${theme.transitions.base}ms,
  `,
});
const buttonActiveWebStyles = webOnlyStyles({
  transform: 'translateY(3px)',
});

// ─── Types ───────────────────────────────────────────────────────────────────

type FeatureFlagOpenUrlButtonProps = {
  name: string;
};

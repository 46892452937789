import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { CloudinaryImage } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainConflictReviewImage = (
  props: OrderAgainConflictReviewImageProps,
) => {
  const { imageUrl, alt } = props;

  return (
    <View style={styles.container}>
      <CloudinaryImage
        baseUrl={imageUrl}
        contentFit="contain"
        config={IMAGE_CONFIG}
        alt={alt}
        style={styles.productImage}
      />
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const PRODUCT_IMAGE = {
  WIDTH: 343,
  HEIGHT: 273,
};

const IMAGE_CONFIG: CloudinaryImageConfig = [
  { crop: 'crop', width: 0.6, height: 0.6 },

  // NOTE: Using a little higher resolution to compensate for cropping.
  { width: PRODUCT_IMAGE.WIDTH },
];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  productImage: {
    width: '100%',
    maxWidth: PRODUCT_IMAGE.WIDTH,
    height: PRODUCT_IMAGE.HEIGHT,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderAgainConflictReviewImageProps = {
  imageUrl: string;
  alt?: string;
};

type CloudinaryImageConfig = ComponentProps<typeof CloudinaryImage>['config'];

import React from 'react';
import { noop, useAsyncStorage } from '@sg/garnish';

// We require a context provider because updating the async storage in
// one component won't update the reference to its value in another component.
export const AsyncStorageProvider = ({
  children,
}: AsyncStorageContextProps) => {
  const [firstOpen, setFirstOpen] = useAsyncStorage(FIRST_OPEN);
  const [lastSession, setLastSession] = useAsyncStorage(LAST_SESSION);

  return (
    <AsyncStorageContext.Provider
      value={React.useMemo(
        () => ({
          firstOpen,
          lastSession,
          setFirstOpen,
          setLastSession,
        }),
        [firstOpen, lastSession, setFirstOpen, setLastSession],
      )}
    >
      {children}
    </AsyncStorageContext.Provider>
  );
};

export const AsyncStorageContext = React.createContext({
  firstOpen: '',
  lastSession: '',
  setFirstOpen: noop,
  setLastSession: noop,
} as AsyncStorageContextValue);

export const useAsyncStorageContext = () =>
  React.useContext(AsyncStorageContext);

type AsyncStorageContextProps = Readonly<{
  children: React.ReactNode;
}>;

type AsyncStorageContextValue = Readonly<{
  firstOpen: string | undefined;
  lastSession: string | undefined;
  setFirstOpen: (key: string) => void;
  setLastSession: (key: string) => void;
}>;

// Async Storage Keys
export const FIRST_OPEN = 'first_open';
export const LAST_SESSION = 'last_session';

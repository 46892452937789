import React, { useCallback, useMemo, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { theme } from '@sg/garnish';

import { useLaunchDarkly } from '@order/LaunchDarkly';

import {
  FeatureFlagsClearButton,
  FeatureFlagsListFilterField,
  FeatureFlagsListHeader,
} from './components';
import { FeatureFlag } from './FeatureFlag';
import { FeatureFlagEditor } from './FeatureFlagEditor';

// ─────────────────────────────────────────────────────────────────────────────

export const FeatureFlagSwitcher = () => {
  const {
    initialFeatureFlags,
    overrides,
    overrideFeatureFlag,
    clearFeatureFlagOverrides,
  } = useLaunchDarkly();

  // ─── State ───────────────────────────────────────────────────────────

  const [searchQuery, setSearchQuery] = useState('');

  // ─── Derived Data ────────────────────────────────────────────────────

  const [sortedFlags, setSortedFlags] = useState(
    sortFeatureFlags(initialFeatureFlags, overrides),
  );

  const filteredFlags = useMemo(() => {
    return sortedFlags.filter((flag) =>
      flag.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [searchQuery, sortedFlags]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const updateFeatureFlag = useCallback(
    (flagName: FeatureFlagKey, flagValue: boolean) => {
      overrideFeatureFlag(flagName, flagValue);
    },
    [overrideFeatureFlag],
  );

  const clearOverrides = useCallback(() => {
    clearFeatureFlagOverrides();
    setSortedFlags(sortFeatureFlags(initialFeatureFlags, {}));
  }, [clearFeatureFlagOverrides, initialFeatureFlags]);

  const hasOverrides = Object.keys(overrides).length > 0;

  // ─── Editing Feature Flag ────────────────────────────────────────────

  const {
    featureFlagToEdit,
    newFlagValue,
    editFlag,
    updateEditValue,
    cancelEdit,
  } = useFeatureFlagEditing(initialFeatureFlags, overrides);

  const confirmFeatureFlagEdit = useCallback(() => {
    if (!featureFlagToEdit) return;

    overrideFeatureFlag(featureFlagToEdit, getFlagValue(newFlagValue));
    cancelEdit();
  }, [featureFlagToEdit, newFlagValue, overrideFeatureFlag, cancelEdit]);

  if (featureFlagToEdit) {
    return (
      <View style={styles.container}>
        <FeatureFlagsListHeader.Container>
          <FeatureFlagsListHeader.ControlsContainer>
            <FeatureFlagsListHeader.Title />
            <FeatureFlagsClearButton
              onPress={clearOverrides}
              isDisabled={!hasOverrides}
            />
          </FeatureFlagsListHeader.ControlsContainer>
        </FeatureFlagsListHeader.Container>

        <View style={styles.contentContainer}>
          <FeatureFlagEditor
            featureFlagToEdit={featureFlagToEdit}
            newFlagValue={newFlagValue}
            updateEditValue={updateEditValue}
            confirmFeatureFlagEdit={confirmFeatureFlagEdit}
            cancelEdit={cancelEdit}
          />
        </View>
      </View>
    );
  }

  // ─── Listing Feature Flags ───────────────────────────────────────────

  return (
    <View style={styles.container}>
      <FeatureFlagsListHeader.Container>
        <FeatureFlagsListHeader.ControlsContainer>
          <FeatureFlagsListHeader.Title />
          <FeatureFlagsClearButton
            onPress={clearOverrides}
            isDisabled={!hasOverrides}
          />
        </FeatureFlagsListHeader.ControlsContainer>

        <FeatureFlagsListFilterField
          value={searchQuery}
          onChange={setSearchQuery}
        />
      </FeatureFlagsListHeader.Container>

      <ScrollView contentContainerStyle={styles.contentContainer}>
        {filteredFlags.map((featureFlag) => {
          const key = featureFlag as FeatureFlagKey;

          const overrideValue = overrides?.[key];
          const originalValue = initialFeatureFlags?.[key];
          const flagValue = overrideValue ?? originalValue;

          const isOverwritten =
            overrideValue !== undefined && overrideValue !== originalValue;

          return (
            <FeatureFlag
              key={key}
              flagName={featureFlag as FeatureFlagKey}
              flagValue={flagValue}
              isOverwritten={isOverwritten}
              updateFeatureFlag={updateFeatureFlag}
              editFeatureFlag={editFlag}
            />
          );
        })}

        {/* NOTE: On web `ScrollView` bottom padding is ignored, so we have to add an explicit spacer */}
        <View style={styles.listPlaceholder} />
      </ScrollView>
    </View>
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

// Sorts the flags alphabetically and then by value, then by overwritten or not.
function sortFeatureFlags(
  initialFeatureFlags: ReturnType<
    typeof useLaunchDarkly
  >['initialFeatureFlags'],
  overrides: ReturnType<typeof useLaunchDarkly>['overrides'],
) {
  // eslint-disable-next-line functional/immutable-data
  return Object.keys(initialFeatureFlags ?? {})
    .sort(sortFeatureFlagsAlphabetically)
    .sort(sortFeatureFlagsByOverrides(initialFeatureFlags, overrides));
}

function sortFeatureFlagsAlphabetically(f1: string, f2: string) {
  return f1.localeCompare(f2);
}

function sortFeatureFlagsByOverrides(
  initialFeatureFlags: ReturnType<
    typeof useLaunchDarkly
  >['initialFeatureFlags'],
  overrides: ReturnType<typeof useLaunchDarkly>['overrides'],
) {
  return (featureFlag1: string, featureFlag2: string) => {
    const key1 = featureFlag1 as FeatureFlagKey;
    const key2 = featureFlag2 as FeatureFlagKey;

    const original1 = initialFeatureFlags?.[key1];
    const original2 = initialFeatureFlags?.[key2];
    const override1 = overrides?.[key1];
    const override2 = overrides?.[key2];

    const hasOverride1 = override1 !== undefined && override1 !== original1;
    const hasOverride2 = override2 !== undefined && override2 !== original2;

    const isEqualValue = hasOverride1 === hasOverride2;

    if (isEqualValue) return 0;

    return hasOverride1 ? -1 : 1;
  };
}

function useFeatureFlagEditing(
  initialFeatureFlags: ReturnType<
    typeof useLaunchDarkly
  >['initialFeatureFlags'],
  overrides: ReturnType<typeof useLaunchDarkly>['overrides'],
) {
  const [featureFlagToEdit, setEditingFeatureFlag] =
    useState<FeatureFlagKey | null>(null);
  const [newFlagValue, setNewFlagValue] = useState('');

  const editFlag = useCallback(
    (flagName: FeatureFlagKey) => {
      setEditingFeatureFlag(flagName);
      setNewFlagValue(overrides[flagName] ?? initialFeatureFlags?.[flagName]);
    },
    [initialFeatureFlags, overrides],
  );

  const updateEditValue = useCallback((flagValue: string) => {
    setNewFlagValue(flagValue);
  }, []);

  const cancelEdit = useCallback(() => {
    setEditingFeatureFlag(null);
    setNewFlagValue('');
  }, []);

  return {
    featureFlagToEdit,
    newFlagValue,
    editFlag,
    updateEditValue,
    cancelEdit,
  };
}

function getFlagValue(flagValue: string) {
  try {
    return JSON.parse(flagValue);
  } catch {
    return flagValue;
  }
}

// ─── Types ───────────────────────────────────────────────────────────────────

type FeatureFlagKey = keyof ReturnType<
  typeof useLaunchDarkly
>['initialFeatureFlags'];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    gap: theme.spacing['2'],
    minHeight: 320,
    paddingHorizontal: theme.spacing['6'],
    paddingVertical: theme.spacing['4'],
  },
  listPlaceholder: {
    height: theme.spacing['3'],
  },
});

import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useClient } from 'urql';
import { useNoticeBannersStackContext } from '@sg/garnish';
import { type DeliveryOrderDetail } from '@sg/graphql-schema';

import {
  type ApplyPromoCodeParams,
  type BagReward,
  type OrderingContext,
} from '@order/features/ordering';
import { type PartialLineItem } from '@order/graphql';

import {
  ApplyPromoCodeDocument,
  type ApplyPromoCodeMutation,
  type ApplyPromoCodeMutationVariables,
} from '../../GraphQL/BagPromoCode.generated';

// ────────────────────────────────────────────────────────────────────────────

/**
 * A hook for applying promo codes in the bag.
 */
export const usePromoCode = (
  fetchRewards: () => Promise<{ rewards: readonly BagReward[] }>,
) => {
  const client = useClient();
  const { formatMessage } = useIntl();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  // ─── Mutations ────────────────────────────────────────────────────────────

  const applyPromoCode = useCallback(
    async (
      _context: OrderingContext<PartialLineItem, DeliveryOrderDetail>,
      { params }: ApplyPromoCodeParams,
    ) => {
      const mutation = client.mutation<
        ApplyPromoCodeMutation,
        ApplyPromoCodeMutationVariables
      >;

      const response = await mutation(
        ApplyPromoCodeDocument,
        { input: params },
        { requestPolicy: 'network-only' },
      ).toPromise();

      const result = response.data?.submitPromoOrGiftCardCode.__typename;

      if (
        result === 'SubmitPromoCodeSuccessV2' ||
        result === 'SubmitGiftCardCodeSuccess'
      ) {
        const { rewards } = await fetchRewards();

        addNoticeBanner({
          text: formatMessage(messages.promoCodeApplied),
          palette: 'success',
        });

        return { rewards, success: true, error: undefined };
      }

      if (result === 'PromoCodeAlreadyApplied') {
        return {
          rewards: [],
          success: false,
          error: formatMessage(messages.promoCodeAlreadyApplied),
        };
      }

      if (result === 'InvalidPromoCode') {
        return {
          rewards: [],
          success: false,
          error: formatMessage(messages.invalidPromoCode),
        };
      }

      return {
        rewards: [],
        success: false,
        error: formatMessage(messages.validationError),
      };
    },
    [client.mutation, fetchRewards, formatMessage, addNoticeBanner],
  );

  // ──────────────────────────────────────────────────────────────────────────

  return { applyPromoCode };
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  promoCodeApplied: {
    defaultMessage:
      'Promo code was successfully redeemed. A new reward has been added to your account.',
    description: 'Bag | Promo code | Promo code applied',
  },
  promoCodeAlreadyApplied: {
    defaultMessage: 'The promo code you entered has already been redeemed.',
    description: 'Bag | Promo code | Promo code already applied',
  },
  invalidPromoCode: {
    defaultMessage: 'Your promo code could not be added, it is invalid.',
    description: 'Bag | Promo code | Invalid promo code',
  },
  validationError: {
    defaultMessage:
      'Your promo code could not be added, check if it is correct.',
    description: 'Bag | Promo code | Validation error',
  },
});

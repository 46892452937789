import { CONTENTFUL_CACHE_STORAGE_KEY } from '../../contentful-content-type-entries-machine.constants';
import { getAllKeys, multiRemove } from '../cache';

/**
 * Removes all contentful cache entries.
 */
export async function removeContentfulCache() {
  try {
    const storageKeys = await getAllKeys();
    const contentfulCacheEntries = storageKeys.filter((key) =>
      key.startsWith(CONTENTFUL_CACHE_STORAGE_KEY),
    );

    if (contentfulCacheEntries.length === 0) return;

    await multiRemove(contentfulCacheEntries);
  } catch {}
}

import React from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import type { HeaderTitleProps } from '@react-navigation/elements';

import { BodyText } from '../../Text';

export const HeaderTitle = ({ children, tintColor }: HeaderTitleProps) => {
  const { width: screenWidth } = useWindowDimensions();

  // NOTE: We limit header title width to prevent other possible header components from being covered.
  const headerMaxWidth = screenWidth - SCREEN_HEADER_CONTROLS_SAFE_AREA;

  const dynamicStyle = useStyle(
    () => ({ color: tintColor, maxWidth: headerMaxWidth }),
    [tintColor, headerMaxWidth],
  );

  return (
    <BodyText
      testID="sg-scrollview.header.title"
      size={1}
      style={[styles.headerTitle, dynamicStyle]}
      numberOfLines={2}
      maxFontSizeMultiplier={1.1}
    >
      {children}
    </BodyText>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const SCREEN_HEADER_CONTROLS_SAFE_AREA = 130;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  headerTitle: {
    textAlign: 'center',
  },
});

import React from 'react';
import type {
  PressableStateCallbackType as UIEventState,
  View,
} from 'react-native';
import { Platform, Pressable, StyleSheet } from 'react-native';

import type { ButtonProps, PaletteTypes, SizeTypes } from './Button.types';
import { ButtonPalette, ButtonSize } from './Button.types';
import { Box, ButtonIcon, LoadingDots, TextParent } from './subcomponents';

const IS_LOADING_LABEL = 'loading...';

export const Button = React.memo(
  React.forwardRef<View, ButtonProps>((props, ref) => {
    const {
      palette = ButtonPalette.primary as PaletteTypes,
      size = ButtonSize.medium as SizeTypes,
      isLoading = false,
      disabled = false,
      width,
      centerIcon,
      leftIcon,
      rightIcon,
      rightElement,
      children,
      style,
      accessibilityRole,
      accessibilityState,
      ...rest
    } = props;

    const hasDisabledStyle = Boolean(disabled);
    const garnishProps = { palette, size, hasDisabledStyle, isLoading };
    const disabledish = Boolean(disabled) || Boolean(isLoading);

    return (
      <Pressable
        ref={ref}
        {...rest}
        {...(isLoading && { accessibilityLabel: IS_LOADING_LABEL })} // I think this can be over written?
        style={[styles.pressableReset, { width }]}
        disabled={disabledish}
        aria-busy={isLoading}
        accessible={true}
        accessibilityRole={accessibilityRole ?? 'button'}
        accessibilityState={{
          disabled: disabledish,
          busy: isLoading,
          ...accessibilityState,
        }}
        pointerEvents="box-only" // prevents event propagation failure because of children/icon
      >
        {(state: UIEventState) => {
          return (
            <Box {...garnishProps} uiEventState={state} style={style}>
              {isLoading && !disabled ? (
                <LoadingDots {...garnishProps} uiEventState={state} />
              ) : (
                <>
                  {/* Icon Before Text */}
                  {leftIcon ? (
                    <ButtonIcon
                      {...garnishProps}
                      size="large"
                      leftIcon={leftIcon}
                      uiEventState={state}
                    />
                  ) : undefined}

                  {/* Standalone Center Icon */}
                  {centerIcon ? (
                    <ButtonIcon
                      centerIcon={centerIcon}
                      {...garnishProps}
                      uiEventState={state}
                    />
                  ) : null}

                  {/* Text */}
                  {children ? (
                    <TextParent {...garnishProps} uiEventState={state}>
                      {children}
                    </TextParent>
                  ) : null}

                  {/* Icon After Text */}
                  {rightIcon ? (
                    <ButtonIcon
                      rightIcon={rightIcon}
                      {...garnishProps}
                      uiEventState={state}
                    />
                  ) : undefined}

                  {/* Arbitrary Element After Text */}
                  {rightElement ? rightElement(state) : undefined}
                </>
              )}
            </Box>
          );
        }}
      </Pressable>
    );
  }),
);

const styles = StyleSheet.create({
  pressableReset: {
    .../* istanbul ignore next */ (Platform.OS === 'web' && {
      userSelect: 'none',
    }),
    display: 'flex', // only purpose is to avoid TS `undefined` warns
  },
});

import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useCustomer } from '@order/Customer';

import { isAddressDuplicateByName } from '../../utils';

/**
 * Returns a memoed result of address duplication by name.
 */
export function useAddressDuplicateByName(props: AddressDuplicateByNameProps) {
  const { addressId, addressName, isCustomNameTouched } = props;

  const { customer } = useCustomer();
  const addresses = customer?.addresses;

  const { formatMessage } = useIntl();

  return useMemo(() => {
    const hasHomeAddress = isAddressDuplicateByName({
      addressId,
      addressName: 'home',
      addresses: addresses ?? [],
    });

    const hasWorkAddress = isAddressDuplicateByName({
      addressId,
      addressName: 'work',
      addresses: addresses ?? [],
    });

    const isCustomNameMissing = isCustomNameTouched && !addressName;
    const isCustomNameDuplicate =
      isCustomNameTouched &&
      isAddressDuplicateByName({
        addressId,
        addressName,
        addresses: addresses ?? [],
      });

    const isCustomNameInvalid = isCustomNameDuplicate || isCustomNameMissing;

    const customNameNotice = getCustomNameNotice(formatMessage, {
      isCustomNameDuplicate,
      isCustomNameMissing,
    });

    return {
      hasHomeAddress,
      hasWorkAddress,
      isCustomNameInvalid,
      customNameNotice,
    };
  }, [addressId, addressName, addresses, isCustomNameTouched, formatMessage]);
}

// ─── Helpers ────────────────────────────────────────────────────────────────

const getCustomNameNotice = (
  formatMessage: ReturnType<typeof useIntl>['formatMessage'],
  props: CustomNameNoticeProps,
) => {
  if (props.isCustomNameDuplicate) {
    return formatMessage(messages.nameDuplicate);
  }

  if (props.isCustomNameMissing) {
    return formatMessage(messages.addressDuplicate);
  }
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  nameDuplicate: {
    defaultMessage: 'You already have an address with this name',
    description: 'Complete address form | Duplicate name',
  },
  addressDuplicate: {
    defaultMessage: 'You already have this address on file',
    description: 'Complete address form | Duplicate address',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type AddressDuplicateByNameProps = Readonly<{
  addressId?: string;
  addressName?: string;
  isCustomNameTouched: boolean;
}>;

type CustomNameNoticeProps = Readonly<{
  isCustomNameDuplicate?: boolean;
  isCustomNameMissing?: boolean;
}>;

import React from 'react';

import { LoadingPlaceholder } from '../LoadingPlaceholder';
import { HERO_CARD_BORDER_RADIUS } from './HeroCard.constants';
import { useHeroCardResponsiveHeight } from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const HeroCardLoading = () => {
  const heroCardResponsiveHeight = useHeroCardResponsiveHeight();

  return (
    <LoadingPlaceholder
      rows={1}
      palette="cream"
      borderRadius={HERO_CARD_BORDER_RADIUS}
      rowHeight={heroCardResponsiveHeight}
    />
  );
};

import { useEffect, useMemo } from 'react';

import {
  useCustomerData,
  useHasLoggedIn,
  useHasLoggedOut,
  useIsLoggingIn,
} from '@order/AuthMachine';
import { useBraze } from '@order/Braze';
import { useLaunchDarkly } from '@order/LaunchDarkly';
import { useOneSignal } from '@order/OneSignal';
import { useTelemetry } from '@order/Telemetry';

import { useLogoutKustomer } from '../../integrations/kustomer';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Initializes third-party vendors integrations and synchronizes current customer data
 */
export const use3rdPartyIntegrations = () => {
  const isLoggingIn = useIsLoggingIn();
  const hasLoggedIn = useHasLoggedIn();
  const hasLoggedOut = useHasLoggedOut();

  const customerData = useCustomerData();
  const { id, trackingUuid, firstName, lastName, email } = customerData ?? {};

  // NOTE: We extract required customer data for 3rd party vendors to prevent
  //       extra effects
  const customer = useMemo(
    () => ({ id, trackingUuid, firstName, lastName, email }),
    [id, trackingUuid, firstName, lastName, email],
  );

  // ─── Flags ───────────────────────────────────────────────────────────

  // A simple flag to detect anonymous users (aka initially signed-out users)
  const isAnonymousUser = !isLoggingIn && id === undefined && !hasLoggedOut;

  // ─── Helpers ─────────────────────────────────────────────────────────

  // NOTE: WE extract helpers individually to prevent unnecessary effects
  //       because of internal state updates

  const {
    initialize: initTelemetry,
    identify: syncTelemetryCustomer,
    reset: resetTelemetryCustomer,
  } = useTelemetry();
  const {
    isReady: isLaunchDarklyReady,
    init,
    identifyCustomer: identifyLaunchDarklyCustomer,
    resetCustomer: resetLaunchDarklyCustomer,
  } = useLaunchDarkly();
  const {
    init: initOneSignal,
    syncCustomer: syncOneSignalCustomer,
    resetCustomer: resetOneSignalCustomer,
  } = useOneSignal();
  const {
    init: initBraze,
    destroy: destroyBraze,
    syncCustomer: syncBrazeCustomer,
  } = useBraze();
  const resetKustomerUser = useLogoutKustomer();

  // ─── Launch Darkly ───────────────────────────────────────────────────

  useEffect(() => {
    if (isLaunchDarklyReady || isLoggingIn) return;

    void init(customer);
  }, [customer, init, isLoggingIn, isLaunchDarklyReady]);

  useEffect(() => {
    if (!isLaunchDarklyReady || !customer.id) return;

    void identifyLaunchDarklyCustomer(customer);

    return () => {
      void resetLaunchDarklyCustomer();
    };
  }, [
    customer,
    identifyLaunchDarklyCustomer,
    resetLaunchDarklyCustomer,
    isLaunchDarklyReady,
  ]);

  // ─── One Signal ──────────────────────────────────────────────────────

  // Initialize client
  useEffect(() => {
    initOneSignal();
  }, [initOneSignal]);

  // Start a new session
  useEffect(() => {
    if (!hasLoggedIn) return;

    syncOneSignalCustomer(customer);
  }, [customer, hasLoggedIn, syncOneSignalCustomer]);

  // Reset the current session
  useEffect(() => {
    if (!hasLoggedOut) return;

    resetOneSignalCustomer();
  }, [hasLoggedOut, resetOneSignalCustomer]);

  /**
   * Reset a possible stale session
   *
   * NOTE: We can directly call reset helper because the One Signal SDKs will
   *       verify if there is a signed-in session and only then make a logout
   *       request.
   */
  useEffect(() => {
    if (!isAnonymousUser) return;

    resetOneSignalCustomer();
  }, [isAnonymousUser, resetOneSignalCustomer]);

  // ─── Braze ───────────────────────────────────────────────────────────

  useEffect(() => {
    initBraze?.();

    return () => {
      destroyBraze?.();
    };
  }, [destroyBraze, initBraze]);

  useEffect(() => {
    if (!customer.id) return;

    syncBrazeCustomer(customer);
  }, [customer, syncBrazeCustomer]);

  // ─── Kustomer ────────────────────────────────────────────────────────

  useEffect(() => {
    if (!customer.id) return;

    return () => {
      void resetKustomerUser();
    };
  }, [customer, resetKustomerUser]);

  // ─── Telemetry ───────────────────────────────────────────────────────

  useEffect(() => {
    void initTelemetry();
  }, [initTelemetry]);

  useEffect(() => {
    if (!customer.id) return;

    syncTelemetryCustomer(customer);

    return () => {
      resetTelemetryCustomer();
    };
  }, [customer, resetTelemetryCustomer, syncTelemetryCustomer]);
};

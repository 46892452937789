import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Tabs, theme } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { SignedOutView } from '@order/components';

import { useScanInStoreTabs } from './hooks';
import { ScanInStoreEarnAndRedeem, ScanInStorePay } from './tabs';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Currently `ScanInStore` screen is using testing data coming from LD,
 * since the corresponding back end data is not yet available.
 */
export const ScanInStore = () => {
  const isLoggedIn = useIsLoggedIn();

  const {
    activeTabItemId,
    earnAndRedeemTab,
    payTab,
    tabs,
    setActiveTabItemId,
  } = useScanInStoreTabs();

  // ─────────────────────────────────────────────────────────────────────

  if (!isLoggedIn) {
    return (
      <View style={styles.container}>
        <SignedOutView />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Tabs.Bar
        tabs={tabs}
        activeTabId={activeTabItemId}
        onChange={setActiveTabItemId}
      />

      <ScrollView>
        <Tabs.Panel
          isActive={activeTabItemId === earnAndRedeemTab.id}
          tabId={earnAndRedeemTab.id}
          panelId={earnAndRedeemTab.panelId}
        >
          <ScanInStoreEarnAndRedeem />
        </Tabs.Panel>

        <Tabs.Panel
          isActive={activeTabItemId === payTab.id}
          tabId={payTab.id}
          panelId={payTab.panelId}
        >
          <ScanInStorePay />
        </Tabs.Panel>
      </ScrollView>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
});

import React from 'react';
import { Button } from '@sg/garnish';

export const SweetpassCheckoutCta = (props: SweetpassCheckoutCtaProps) => {
  const { cta, ...buttonProps } = props;

  return (
    <Button size="large" palette="primary" {...buttonProps}>
      {cta}
    </Button>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassCheckoutCtaProps = Readonly<{
  cta: string;
}> &
  React.ComponentProps<typeof Button>;

import React from 'react';
import type { Route } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';

import { useNavigateToMainTabs } from '../../navigation';
import type {
  AppStackParamList,
  DeliveryMenuScreenProps,
  MenuScreenProps,
  MenuTabStackParamList,
} from '../../navigation/AppNavigation.props';

export const useMenuNavigation = (
  passedNavigation?: NativeStackNavigationProp<AppStackParamList>,
) => {
  const fallback =
    useNavigation<NativeStackNavigationProp<AppStackParamList>>();
  const navigation = passedNavigation ?? fallback;
  const navigateToMainTabs = useNavigateToMainTabs(navigation);

  const handleGoToMenu = React.useCallback(
    async (restaurantSlug: string) => {
      return navigateToMainTabs('MenuTab', {
        screen: 'Menu',
        params: { restaurantSlug },
      });
    },
    [navigateToMainTabs],
  );

  const handleGoToDeliveryMenu = React.useCallback(
    async (addressId: string) => {
      return navigateToMainTabs('MenuTab', {
        screen: 'DeliveryMenu',
        params: { addressId },
      });
    },
    [navigateToMainTabs],
  );

  const handleGoToProductDetails = React.useCallback(
    (productSlug: string, restaurantSlug: string) => {
      navigation.navigate('Modal', {
        screen: 'ProductDetails',
        params: { productSlug, restaurantSlug },
      });
    },
    [navigation],
  );

  const handleGoToDeliveryProductDetails = React.useCallback(
    (productSlug: string, addressId: string) => {
      navigation.navigate('Modal', {
        screen: 'DeliveryProductDetails',
        params: { productSlug, addressId },
      });
    },
    [navigation],
  );

  const handleGoToLocations = React.useCallback(() => {
    navigation.navigate('MainTabs', {
      screen: 'MenuTab',
      params: {
        screen: 'Locations',
      },
    });
  }, [navigation]);

  const handleGoToCheckout = React.useCallback(() => {
    navigation.navigate('Modal', { screen: 'Checkout' });
  }, [navigation]);

  const handlePressDietaryRestrictions = React.useCallback(() => {
    navigation.navigate('Modal', { screen: 'DietaryRestrictions' });
  }, [navigation]);

  return {
    handleGoToMenu,
    handleGoToDeliveryMenu,
    handleGoToProductDetails,
    handleGoToDeliveryProductDetails,
    handleGoToLocations,
    handleGoToCheckout,
    handlePressDietaryRestrictions,
  };
};

// https://sweetgreen.atlassian.net/browse/XP-2787
// When a menu screen mounts, reset the MenuTab stack so that the only screen is the menu screen.
// This is done to remove the location screen from the stack so that the customer cannot go back to it.
export const useMenuScreenReset = (
  navigation:
    | MenuScreenProps['navigation']
    | DeliveryMenuScreenProps['navigation'],
) => {
  React.useLayoutEffect(() => {
    const routes = navigation.getState()?.routes ?? [];
    const menuRoutes = routes.filter(({ name }) =>
      ['Menu', 'DeliveryMenu'].includes(name),
    );

    // If there is more than one menu route, use the last one,
    // this will happen when switching menus.
    const lastMenuRoute = menuRoutes.at(-1) as Route<
      keyof MenuTabStackParamList
    >;

    // If there is a Menu / Delivery Menu route,
    // but it is not the only route in the MenuTab stack,
    // reset the MenuTab stack with it so that it is.
    if (lastMenuRoute && routes.length > 1) {
      navigation.reset({ routes: [lastMenuRoute] });
    }
  }, [navigation]);
};

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, IllusEmpty_1, Image, theme, TitleText } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const NoResults = ({ message = '' }) => {
  const { t } = useLocalizationContext();

  return (
    <View style={styles.wrapper} testID="location-no-results">
      <Image source={IllusEmpty_1} style={styles.image} />

      <TitleText size={6} style={styles.title}>
        {message}
      </TitleText>

      <BodyText size={2}>{t('location.try-another-search')}</BodyText>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    textAlign: 'center',
    paddingVertical: theme.spacing['10'],
    paddingHorizontal: theme.spacing['6'],
  },
  image: {
    width: 248,
    height: 248,
    resizeMode: 'contain',
  },
  title: {
    marginBottom: theme.spacing['4'],
  },
});

import React from 'react';
import { StyleSheet } from 'react-native';
import { LoadingPlaceholder, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardRedemptionLoadingPlaceholder = () => {
  return (
    <LoadingPlaceholder
      style={styles.loadingContainer}
      rows={6}
      rowHeight={[44, 258, 120, 28, 48, 94]}
      gridGap={theme.spacing['6']}
      palette="cream"
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  loadingContainer: {
    width: '100%',
    maxWidth: 384,
  },
});

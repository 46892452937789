import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { theme } from '@garnish/constants';
import * as Clipboard from 'expo-clipboard';
import { NotificationFeedbackType } from 'expo-haptics';
import {
  BodyText,
  Button,
  Icon,
  triggerHapticNotificationFeedback,
  useNoticeBannersStackContext,
  useShareTextDialog,
} from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';

import { giftCardConfirmationDeliveryInfoCTAsMessages as messages } from './GiftCardConfirmationDeliveryInfoCTAs.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardConfirmationDeliveryInfoCTAs = (
  props: GiftCardConfirmationDeliveryInfoCTAsProps,
) => {
  const { redemptionURL, message } = props;

  const { formatMessage } = useIntl();
  const { push: addBanner } = useNoticeBannersStackContext();
  const { canShare, share } = useShareTextDialog();
  const { track } = useTelemetry();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const shareGiftCardDetails = useCallback(async () => {
    if (message) {
      const formattedMessage = `${message}\n\n${redemptionURL}`;

      await share({ message: formattedMessage });

      return;
    }

    await share({ url: redemptionURL, message: message ?? '' });

    track('gift.confirmation_send_cta_pressed');
  }, [message, redemptionURL, share, track]);

  const copyUrlToClipboard = useCallback(async () => {
    const hasCopied = await Clipboard.setStringAsync(redemptionURL);

    if (!hasCopied) return;

    track('gift.confirmation_copy_cta_pressed');
    await triggerHapticNotificationFeedback(NotificationFeedbackType.Success);

    addBanner(
      {
        id: 'gift-card-confirmation-copy-url-banner',
        palette: 'success',
        text: formatMessage(messages.urlCopiedMessage),
        autoHideTimeout: 1800,
      },
      true,
    );
  }, [addBanner, formatMessage, redemptionURL, track]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      {canShare ? (
        <>
          <Button palette="primary" onPress={shareGiftCardDetails}>
            <FormattedMessage {...messages.sendGift} />
          </Button>

          <TouchableOpacity
            accessibilityRole="button"
            onPress={copyUrlToClipboard}
            style={styles.copyLinkBtn}
          >
            <BodyText sizeMatch={['14']} style={styles.copyLinkBtnText}>
              <FormattedMessage {...messages.copyLink} />
            </BodyText>

            <Icon name="IconAttach" />
          </TouchableOpacity>
        </>
      ) : (
        <Button
          palette="primary"
          rightIcon="IconAttach"
          onPress={copyUrlToClipboard}
        >
          <FormattedMessage {...messages.copyLink} />
        </Button>
      )}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['4'],
    marginTop: theme.spacing['6'],
  },
  copyLinkBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing['2'],
  },
  copyLinkBtnText: {
    color: theme.colors.CHARCOAL,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardConfirmationDeliveryInfoCTAsProps = {
  redemptionURL: string;
  message: string | undefined | null;
};

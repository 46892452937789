import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import type { TranslationKeys } from '@order/Localization';
import { useLocalizationContext } from '@order/Localization';

import type { AddSweetpassPaymentMethodMutation } from './graphql/SweetpassPaymentMethod.generated';
import { useAddSweetpassPaymentMethodMutation } from './graphql/SweetpassPaymentMethod.generated';

// ─────────────────────────────────────────────────────────────────────────────

export const useAddSweetpassPaymentMethod = () => {
  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  // ─── Mutations ───────────────────────────────────────────────────────

  const [response, addSweetpassPaymentMethodMutation] =
    useAddSweetpassPaymentMethodMutation();
  const isLoading = response.fetching;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleAddSweetpassPaymentMethodError = useCallback(
    (result?: AddSubscriptionPaymentMethodErrorResponseType) => {
      const userErrorMessage =
        t(USER_ERROR_MESSAGES[result ?? 'default']) ??
        t(USER_ERROR_MESSAGES.default);

      addNoticeBanner({ text: userErrorMessage, palette: 'caution' });
    },
    [addNoticeBanner, t],
  );

  const addSweetpassPaymentMethod = useCallback(
    async (props: AddSweetpassPaymentMethodProps) => {
      const { nonce, onSuccess } = props;
      const { data } = await addSweetpassPaymentMethodMutation({ nonce });

      const result = data?.addSubscriptionPaymentMethod.__typename;
      const hasSuccessfullyAddedPaymentMethod =
        result === 'AddSubscriptionPaymentMethodSuccessResponse';

      if (!hasSuccessfullyAddedPaymentMethod) {
        handleAddSweetpassPaymentMethodError(result);

        return;
      }

      onSuccess();
    },
    [addSweetpassPaymentMethodMutation, handleAddSweetpassPaymentMethodError],
  );

  // ─────────────────────────────────────────────────────────────────────

  return {
    addSweetpassPaymentMethod,
    isLoading,
  };
};

// ─── Constants ───────────────────────────────────────────────────────────────

const USER_ERROR_MESSAGES: AddSubscriptionPaymentMethodUserErrorMessages = {
  InvalidPaymentMethod: 'sweetpass.payment-method.invalid-payment-method',
  PaymentMethodAlreadyExistsError: 'sweetpass.payment-method.duplicate',
  UnknownUserError: 'sweetpass.payment-method.generic-payment-method-error',
  default: 'sweetpass.payment-method.generic-payment-method-error',
};

// ─── Types ───────────────────────────────────────────────────────────────────

type AddSubscriptionPaymentMethodUserErrorMessages = Record<
  AddSubscriptionPaymentMethodErrorResponseType | 'default',
  TranslationKeys
>;

type AddSubscriptionPaymentMethodErrorResponseType = Exclude<
  AddSubscriptionPaymentMethodResponseType,
  'AddSubscriptionPaymentMethodSuccessResponse'
>;

type AddSubscriptionPaymentMethodResponseType =
  AddSweetpassPaymentMethodMutation['addSubscriptionPaymentMethod']['__typename'];

type AddSweetpassPaymentMethodProps = Readonly<{
  nonce: string;
  onSuccess: () => void;
}>;

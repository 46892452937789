import React, { type ComponentProps } from 'react';
import { Scrollspy } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuCategoriesGridContainer = (
  props: MenuCategoriesGridContainerProps,
) => {
  const { children, ...restProps } = props;

  return <Scrollspy.ScrollView {...restProps}>{children}</Scrollspy.ScrollView>;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuCategoriesGridContainerProps = ComponentProps<
  typeof Scrollspy.ScrollView
>;

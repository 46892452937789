import React from 'react';
import { StyleSheet, View } from 'react-native';
import { DisplayText, IconLink, theme, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const DeliveryPreferencesFormHeader = (
  props: DeliveryPreferencesFormHeaderProps,
) => {
  const { onClose } = props;

  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();
  const { isXS } = currentBreakpoint;

  return (
    <View style={isXS ? styles.column : styles.row}>
      {!isXS && (
        <DisplayText size={3}>{t('delivery.preferences.title')}</DisplayText>
      )}

      <View style={styles.icon}>
        <IconLink
          testID="delivery.preferences.close-button"
          name={isXS ? 'IconArrowLeft' : 'IconClose'}
          iconSize={24}
          accessibilityRole="button"
          accessibilityLabel={t('general.close')}
          accessibilityHint={t('general.close')}
          onPress={onClose}
        />
      </View>

      {isXS ? (
        <DisplayText style={styles.header} size={3}>
          {t('delivery.preferences.title')}
        </DisplayText>
      ) : null}
    </View>
  );
};

type DeliveryPreferencesFormHeaderProps = Readonly<{
  onClose: () => void;
}>;

const styles = StyleSheet.create({
  icon: {
    width: 24,
    height: 24,
  },
  header: {
    marginTop: theme.spacing['6'],
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  column: {
    flexDirection: 'column',
  },
});

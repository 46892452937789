/* eslint-disable react-native/no-unused-styles */

import React, { type ComponentProps } from 'react';
import { StyleSheet, type TextStyle } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomNavigationHeaderText = (
  props: CustomNavigationHeaderButtonProps,
) => {
  const {
    children,
    isLink,
    style,
    textAlignment = 'center',
    ...restProps
  } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const textStyles = [
    styles.text,
    isLink ? styles.link : undefined,
    textAlignmentStyles[textAlignment],
    style,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <BodyText
      sizeMatch={['22']}
      underline={isLink}
      style={textStyles}
      maxFontSizeMultiplier={1}
      {...restProps}
    >
      {children}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    flex: 1,
  },
  link: {
    color: theme.colors.SPINACH,
  },
});

const textAlignmentStyles = StyleSheet.create<Record<TextAlignment, TextStyle>>(
  {
    center: { textAlign: 'center' },
    right: { textAlign: 'right' },
    left: { textAlign: 'left' },
  },
);

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomNavigationHeaderButtonProps = {
  isLink?: boolean;
  textAlignment?: TextAlignment;
} & ComponentProps<typeof BodyText>;

type TextAlignment = 'right' | 'center' | 'left';

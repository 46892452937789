import { useCallback, useRef } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { useInterpret, useSelector } from '@xstate/react';
import { useClient } from 'urql';
import { type ContextFrom } from 'xstate';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useCustomer } from '@order/Customer';
import { useFeatureFlag } from '@order/LaunchDarkly';

import {
  CanCancelOrdersDocument,
  type CanCancelOrdersQuery,
  type CanCancelOrdersQueryVariables,
  CanCancelOrdersV2Document,
  type CanCancelOrdersV2Query,
  OrdersV2Document,
  type OrdersV2Query,
  type OrdersV2QueryVariables,
} from '../../GraphQL/Orders.generated';
import { createOrdersHistoryMachine } from '../../orders-history-machine';

// ─────────────────────────────────────────────────────────────────────────────

export const usePaginatedOrdersWithMachine = () => {
  const client = useClient();
  const { customer } = useCustomer();
  const isLoggedIn = useIsLoggedIn();

  const { current: ordersHistoryMachine } = useRef(
    createOrdersHistoryMachine<Order>(),
  );

  // ─── Flags ───────────────────────────────────────────────────────────

  const isOrderAsyncCancellationEnabled = useFeatureFlag(
    'CELS-2829-in-app-order-async-cancellation-enabled',
  );

  // ─── Queries ─────────────────────────────────────────────────────────

  const ordersQuery = useCallback(
    async (input: OrdersV2QueryVariables['input']) => {
      const queryOrders = client.query<OrdersV2Query, OrdersV2QueryVariables>;

      return queryOrders(
        OrdersV2Document,
        { input },
        { requestPolicy: 'cache-and-network' },
      ).toPromise();
    },
    [client.query],
  );

  const cancellableOrdersQuery = useCallback(
    async (input: CanCancelOrdersQueryVariables['input']) => {
      const queryCancellableOrders = client.query<
        CanCancelOrdersQuery,
        CanCancelOrdersQueryVariables
      >;

      return queryCancellableOrders(
        CanCancelOrdersDocument,
        { input },
        { requestPolicy: 'network-only' },
      ).toPromise();
    },
    [client.query],
  );

  const cancellableOrdersV2Query = useCallback(
    async (input: CanCancelOrdersQueryVariables['input']) => {
      const queryCancellableOrders = client.query<
        CanCancelOrdersV2Query,
        CanCancelOrdersQueryVariables
      >;

      return queryCancellableOrders(
        CanCancelOrdersV2Document,
        { input },
        { requestPolicy: 'network-only' },
      ).toPromise();
    },
    [client.query],
  );

  // ─── Helpers ─────────────────────────────────────────────────────────────────

  const fetchOrders = useCallback(
    async (context: ContextFrom<typeof ordersHistoryMachine>) => {
      const { page, ordersPerPage } = context;

      if (!isLoggedIn) return { orders: [], lastPage: page };

      const { data } = await ordersQuery({
        take: ordersPerPage,
        page,
        includePlacedOrders: true,
        includeCanceledOrders: true,
      });

      const response = data?.ordersV2;
      const hasResolvedData = response?.__typename === 'OrdersResponseSuccess';

      if (!hasResolvedData) return { orders: [], lastPage: page };

      // NOTE: BE pagination utilizes a zero-based counter (e.g., 0 == 1, 1 == 2, etc.),
      //       therefore, we increase the number by one to match the state machine model.
      const lastPage = response.pagination.last + 1;

      return { orders: response.orders, lastPage };
    },
    [isLoggedIn, ordersQuery],
  );

  const fetchCancellableOrders = useCallback(
    async (context: ContextFrom<typeof ordersHistoryMachine>) => {
      if (!isLoggedIn) return [];

      const { futureOrders } = context;
      const customerId = customer.id;

      if (futureOrders.length === 0 || !customerId) return [];

      const input = futureOrders.map((order) => ({
        customerId,
        orderId: order.id,
        restaurantId: order.restaurant.id,
        wantedTime: order.wantedTime,
        origin: 'customer',
      }));

      // ─── New Query ───────────────────────────────────────────────────────

      if (isOrderAsyncCancellationEnabled) {
        const { data } = await cancellableOrdersV2Query(input);

        const hasResolvedData = data?.canCancelV2;

        if (!hasResolvedData) return [];

        return extractCancellableOrders(data.canCancelV2);
      }

      // ─── Legacy Query ────────────────────────────────────────────────────

      const { data } = await cancellableOrdersQuery(input);

      const hasResolvedData = data?.canCancel;

      if (!hasResolvedData) return [];

      return extractCancellableOrders(data.canCancel);
    },
    [
      cancellableOrdersQuery,
      cancellableOrdersV2Query,
      customer.id,
      isLoggedIn,
      isOrderAsyncCancellationEnabled,
    ],
  );

  // ─── State Machine ───────────────────────────────────────────────────────────

  const ordersHistoryMachineService = useInterpret(ordersHistoryMachine, {
    services: { fetchOrders, fetchCancellableOrders },
  });

  const orders = useSelector(
    ordersHistoryMachineService,
    (currentState) => currentState.context.orders,
  );
  const canFetchMoreOrders = useSelector(
    ordersHistoryMachineService,
    (currentState) => currentState.context.canFetchMoreOrders,
  );
  const isFetchingInitialOrders = useSelector(
    ordersHistoryMachineService,
    (currentState) =>
      currentState.matches('waiting') ||
      currentState.matches('fetchingInitialOrders'),
  );
  const isFetchingMoreOrders = useSelector(
    ordersHistoryMachineService,
    (currentState) => currentState.matches('fetchingMoreOrders'),
  );

  // ─── Machine Helpers ─────────────────────────────────────────────────────────

  const updateCancelledOrder = useCallback(
    (orderId: string) => {
      ordersHistoryMachineService.send({
        type: 'UPDATE_CANCELLED_ORDER',
        orderId,
      });
    },
    [ordersHistoryMachineService],
  );

  const startOrderHistoryMachine = useCallback(() => {
    ordersHistoryMachineService.send('START');
  }, [ordersHistoryMachineService]);

  const fetchMoreOrders = useCallback(() => {
    ordersHistoryMachineService.send('FETCH_MORE_ORDERS');
  }, [ordersHistoryMachineService]);

  // ─── Effects ─────────────────────────────────────────────────────────────────

  // NOTE: Starts (or restarts) the order history machine on screen focus
  useFocusEffect(startOrderHistoryMachine);

  // ─────────────────────────────────────────────────────────────────────────────

  return {
    orders,
    canFetchMoreOrders,
    isFetchingInitialOrders,
    isFetchingMoreOrders,
    updateCancelledOrder,
    fetchMoreOrders,
  };
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function extractCancellableOrders(
  cancellableOrdersList: CanCancelOrdersQuery['canCancel'],
) {
  return cancellableOrdersList.reduce<string[]>(
    (validOrdersList, orderStatus) => {
      const isValidCancellableOrder =
        orderStatus.__typename === 'CanCancel' && orderStatus.canCancel;

      return isValidCancellableOrder
        ? [...validOrdersList, `${orderStatus.orderId}`]
        : validOrdersList;
    },
    [],
  );
}

// ─── Types ───────────────────────────────────────────────────────────────────

type Order = Orders[number];

type Orders = NonNullable<
  Extract<
    OrdersV2Query['ordersV2'],
    { __typename: 'OrdersResponseSuccess' }
  >['orders']
>;

import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { TagLabel } from '../../TagLabel';
import type { LoyaltyChallengeTheme } from '../LoyaltyChallengeCard.types';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyChallengeBadge = (props: LoyaltyChallengeBadgeProps) => {
  const { label, theme } = props;

  const badgePalette = theme
    ? loyaltyChallengeCardBadgePalettes[theme]
    : 'primary';

  return (
    <View style={styles.wrapper}>
      <TagLabel size={3} palette={badgePalette} {...props}>
        {label}
      </TagLabel>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
});

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * Loyalty challenge cards can have different badge styles based on
 * their theme (`FLASH`, etc.) and type (`New`, `Ongoing`, `Completed`).
 */
const loyaltyChallengeCardBadgePalettes: Record<
  LoyaltyChallengeTheme,
  TagLabelPalette
> = {
  DEFAULT: 'primary',
  FLASH: 'light',
};

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyChallengeBadgeProps = Readonly<{
  label: string;
  theme: LoyaltyChallengeTheme;
}>;

type TagLabelPalette = ComponentProps<typeof TagLabel>['palette'];

import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

export const HowItWorksCardContainer = (
  props: HowItWorksCardContainerProps,
) => {
  const { children, style, containerColor } = props;
  const containerBackgroundColor = useStyle(
    () => ({ backgroundColor: containerColor }),
    [],
  );

  return (
    <View style={[styles.cardContainer, containerBackgroundColor, style]}>
      {children}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cardContainer: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: theme.spacing['4'],
    gap: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type HowItWorksCardContainerProps = { containerColor: string } & ComponentProps<
  typeof View
>;

import { getInitialUrlWithoutFeatureFlagsOverrides } from '../getInitialUrlWithoutFeatureFlagsOverrides';

/**
 * Updates the URL by deleting the feature flag overrides query parameters.
 *
 * NOTE: Web only
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState}
 */
export async function deleteFeatureFlagOverridesQueryParams() {
  const urlWithoutFeatureFlagsOverrides =
    await getInitialUrlWithoutFeatureFlagsOverrides();

  if (!urlWithoutFeatureFlagsOverrides) return;

  window.history.replaceState({}, '', urlWithoutFeatureFlagsOverrides);
}

/* eslint-disable @typescript-eslint/no-var-requires */

export const IllusBlueberryLarge = require('./blueberry-large.png');
export const IllusBlueberryMedium = require('./blueberry-medium.png');
export const IllusBlueberrySmall = require('./blueberry-small.png');

export const IllusGrapefruitLarge = require('./grapefruit-large.png');
export const IllusGrapefruitMedium = require('./grapefruit-medium.png');
export const IllusGrapefruitSmall = require('./grapefruit-small.png');

export const IllusTangerineLarge = require('./tangerine-large.png');
export const IllusTangerineMedium = require('./tangerine-medium.png');
export const IllusTangerineSmall = require('./tangerine-small.png');

export const IllusWhiteSmall = require('./white-small.png');
export const IllusWhiteMedium = require('./white-medium.png');
export const IllusWhiteLarge = require('./white-large.png');

import React from 'react';
import { View, type ViewProps } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders a `View` component with the predefined `radiogroup` accessibility role.
 * Should be used as a wrapper to render radio elements.
 */
export const RadioContainerGroup = (props: RadioContainerGroupProps) => {
  const { gap = theme.spacing['2'], style, children, ...restProps } = props;

  const containerGap = useStyle(() => ({ gap }), [gap]);
  const containerStyles = [containerGap, style];

  return (
    <View accessibilityRole="radiogroup" style={containerStyles} {...restProps}>
      {children}
    </View>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type RadioContainerGroupProps = Readonly<{
  gap?: number;
}> &
  Omit<ViewProps, 'accessibilityRole'>;

import React, { type ComponentProps } from 'react';
import { Scrollspy, useContainerSpacing, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuCategoriesGridNav = (
  props: MenuCategoriesGridContainerProps,
) => {
  const { children, ...restProps } = props;

  const { currentBreakpoint } = useResponsive();
  const containerSpacing = useContainerSpacing();

  return (
    <Scrollspy.Nav
      stagePadding={currentBreakpoint.isLG ? undefined : containerSpacing}
      {...restProps}
    >
      {children}
    </Scrollspy.Nav>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuCategoriesGridContainerProps = ComponentProps<typeof Scrollspy.Nav>;

import '@expo/metro-runtime';

import React from 'react';
import { registerRootComponent } from 'expo';
import 'expo-asset';
import 'expo-dev-client';

// This polyfill is essential to ensure that urql (>= v4) (and wonka) work on
// native platforms.
//
// See: https://github.com/aws-amplify/amplify-js/issues/10764#issuecomment-1363140556
//
// The issue listed above recommends simply updating wonka, but this did not
// resolve the issue in our case, thus we had to add this polyfill.
//
// This can be removed if Hermes supports 'AsyncGenerator' out of the box.
import 'core-js/full/symbol/async-iterator';

import { Root } from './src/Root';

const Index = () => <Root />;

registerRootComponent(Index);

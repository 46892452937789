import {
  MenuMoreInfoButtonCopyAddress,
  MenuMoreInfoButtonDirection,
  MenuMoreInfoButtonsContainer,
  MenuMoreInfoContainer,
  MenuMoreInfoContentContainer,
  MenuMoreInfoHeading,
  MenuMoreInfoModalCloseButton,
  MenuMoreInfoModalHeader,
  MenuMoreInfoNotice,
  MenuMoreInfoRestaurantAddress,
  MenuMoreInfoRestaurantPhoneNumber,
  MenuMoreInfoText,
  MenuMoreInfoTimetable,
} from './components';

export { generateStoreHoursTimetable } from './components';

export const MenuMoreInfo = {
  Container: MenuMoreInfoContainer,
  Heading: MenuMoreInfoHeading,
  ModalCloseButton: MenuMoreInfoModalCloseButton,
  ModalHeader: MenuMoreInfoModalHeader,
  Notice: MenuMoreInfoNotice,
  ContentContainer: MenuMoreInfoContentContainer,
  Timetable: MenuMoreInfoTimetable,
  Text: MenuMoreInfoText,
  ButtonsContainer: MenuMoreInfoButtonsContainer,
  Button: {
    Direction: MenuMoreInfoButtonDirection,
    CopyAddress: MenuMoreInfoButtonCopyAddress,
  },
  RestaurantAddress: MenuMoreInfoRestaurantAddress,
  RestaurantPhoneNumber: MenuMoreInfoRestaurantPhoneNumber,
};

export const date = {
  //
  // ─── Units ───────────────────────────────────

  'date.month': 'month',
  'date.months': 'months',
  'date.year': 'year',
  'date.years': 'years',
  'date.day': 'day',
  'date.days': 'days',
  'date.hour': 'hour',
  'date.hours': 'hours',
  'date.minute': 'minute',
  'date.minutes': 'minutes',
  'date.second': 'second',
  'date.seconds': 'seconds',

  // ─────────────────────────────────────────────

  'date.in-a-second': 'in a second',
  'date.date-distance': '{numericDate} {dateUnit}',
  'date.date-distance-with-prefix': 'in {numericDate} {dateUnit}',
};

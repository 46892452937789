import {
  ChallengeCardCompleted,
  ChallengeCardNew,
  ChallengeCardOngoing,
} from './ChallengeCard';

// ────────────────────────────────────────────────────────────────────────────────

export const ChallengeCard = {
  New: ChallengeCardNew,
  Ongoing: ChallengeCardOngoing,
  Completed: ChallengeCardCompleted,
};

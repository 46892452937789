import React from 'react';
import type { AccessibilityProps, ImageSourcePropType } from 'react-native';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../../hooks';
import { Image } from '../../../../Image';

// ─────────────────────────────────────────────────────────────────────────────

export const PageHeroHeaderImg = (props: PageHeroHeaderImgProps) => {
  const { source, accessibilityLabel } = props;

  const { match } = useResponsive();

  const imgResponsiveStyles = match([
    styles.imgXS,
    styles.imgSM,
    styles.imgSM,
    styles.imgLG,
  ]);
  const imgStyles = [styles.img, imgResponsiveStyles];

  return (
    <Image
      source={source}
      style={imgStyles}
      aria-label={accessibilityLabel}
      contentFit="contain"
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  img: {
    maxWidth: '100%',
  },
  imgXS: {
    width: 350,
    height: 160,
    marginBottom: theme.spacing['10'],
  },
  imgSM: {
    width: 510,
    height: 160,
    marginBottom: theme.spacing['10'],
  },
  imgLG: {
    width: 700,
    height: 200,
    marginBottom: theme.spacing['10'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type PageHeroHeaderImgProps = Readonly<{
  source: ImageSourcePropType;
  accessibilityLabel?: AccessibilityProps['accessibilityLabel'];
}>;

import React from 'react';
import { StyleSheet, View } from 'react-native';

import { BodyText } from '../../Text';
import type { CalloutContentProps } from '../types';

export const CalloutContent = (props: CalloutContentProps) => {
  const { name, style, contentRef, ...rest } = props;

  return (
    <View {...rest} ref={contentRef} style={[styles.content, style]}>
      <BodyText style={styles.text} size={3} numberOfLines={1}>
        {name}
      </BodyText>
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  text: {
    width: '100%',
    textAlign: 'left',
  },
});

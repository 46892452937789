import { type UseLiveActivity } from './useLiveActivity.types';

/**
 * NOTE: Live activities widget is iOS only.
 */
export const useLiveActivity: UseLiveActivity = () => {
  return {
    startLiveActivity: undefined,
    cancelLiveActivity: undefined,
    checkLiveActivitiesStatus: () => 'not-supported',
  };
};

import React from 'react';
import { Pressable, ScrollView, StyleSheet, View } from 'react-native';
import {
  BodyText,
  Button,
  HStack,
  Rating,
  theme,
  TitleText,
  usePressableState,
  useResponsive,
} from '@sg/garnish';

import { PageWrapper } from '@order/components';

// ─────────────────────────────────────────────────────────────────────────────

export const RatingContent = React.memo((props: RatingContentProps) => {
  const {
    ratingTitle,
    ratingSubtitle,
    ratingHint,
    ratingCta,
    cards,
    rating,
    submitting,
    onRating,
    onHoverRating,
    onSelectCard,
    onSubmit,
  } = props;

  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const contentContainerStyles = [
    styles.contentContainer,
    match([
      styles.contentContainerXS,
      styles.contentContainerSM,
      styles.contentContainerMD,
    ]),
  ];
  const ratingCardsContainerStyles = [
    styles.ratingCardsContainer,
    match([undefined, styles.ratingCardsContainerSM]),
  ];
  const ctaContainerStyles = [
    styles.ctaContainer,
    match([undefined, styles.ctaContainerSM]),
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <PageWrapper
      withoutFooter
      withHeaderTitle
      contentContainerStyle={styles.pageWrapper}
      bounces={false}
    >
      <View style={contentContainerStyles}>
        <RatingWithTitle
          rating={rating}
          ratingTitle={ratingTitle}
          onRating={onRating}
          onHoverRating={onHoverRating}
        />

        <TitleText size={5} style={styles.ratingSubtitle}>
          {ratingSubtitle}
        </TitleText>

        <BodyText size={5} style={styles.ratingHint}>
          {ratingHint}
        </BodyText>

        <ScrollView style={ratingCardsContainerStyles} bounces={false}>
          <HStack itemsPerRow={match([2, 3])} gap={theme.spacing['4']}>
            {cards.map((card) => (
              <RatingCardComponent
                key={card.id}
                {...card}
                onSelectCard={onSelectCard}
              />
            ))}
          </HStack>
        </ScrollView>

        <View style={ctaContainerStyles}>
          <Button
            testID="rate-order.submit"
            width="100%"
            accessibilityRole="button"
            accessibilityLabel={ratingCta}
            size="large"
            isLoading={submitting}
            onPress={onSubmit}
          >
            {ratingCta}
          </Button>
        </View>
      </View>
    </PageWrapper>
  );
});

export const RatingWithTitle = (props: RatingWithTitleProps) => {
  const { rating, ratingTitle, onRating, onHoverRating } = props;

  return (
    <>
      <Rating value={rating} onChange={onRating} onHover={onHoverRating} />
      <BodyText style={styles.ratingTitle}>{ratingTitle}</BodyText>
    </>
  );
};

// ─── Subcomponents ───────────────────────────────────────────────────────────

const RatingCardComponent = ({
  id,
  label,
  selected,
  onSelectCard,
}: RatingCardProps) => {
  const ref = React.useRef(null);
  const { isInteracting } = usePressableState(ref);
  const handleOnPress = React.useCallback(() => {
    onSelectCard(id, !selected);
  }, [id, selected, onSelectCard]);

  return (
    <Pressable
      ref={ref}
      testID={`rate-order.card.${id}`}
      accessibilityRole="button"
      accessibilityLabel={label}
      onPress={handleOnPress}
      style={[
        styles.card,
        selected ? styles.cardSelected : isInteracting && styles.cardHovered,
      ]}
    >
      <BodyText size={4} style={styles.cardLabel}>
        {label}
      </BodyText>
    </Pressable>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type RatingContentProps = Readonly<{
  ratingSubtitle: string;
  ratingHint: string;
  ratingCta: string;
  cards: readonly RatingCard[];
  submitting: boolean;
  onSelectCard: (id: string, selected: boolean) => void;
  onSubmit: () => void;
}> &
  RatingWithTitleProps;

type RatingWithTitleProps = Readonly<{
  ratingTitle: string;
  rating: number;
  onHoverRating: (rating: number) => void;
  onRating: (rating: number) => void;
}>;

type RatingCardProps = Readonly<{
  id: string;
  label: string;
  selected: boolean;
  onSelectCard: (id: string, selected: boolean) => void;
}>;

type RatingCard = Readonly<{
  id: string;
  label: string;
  selected: boolean;
}>;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pageWrapper: {
    maxHeight: '100%',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
  },
  contentContainerXS: {
    paddingTop: theme.spacing['20'],
    paddingHorizontal: theme.spacing['4'],
    paddingBottom: theme.spacing['6'],
  },
  contentContainerSM: {
    paddingTop: theme.spacing['6'],
    paddingHorizontal: theme.spacing['6'],
    paddingBottom: theme.spacing['10'],
  },
  contentContainerMD: {
    paddingTop: theme.spacing['6'],
    paddingHorizontal: theme.spacing['10'],
    paddingBottom: theme.spacing['10'],
  },
  ratingCardsContainer: {
    flex: 1,
  },
  ratingCardsContainerSM: {
    minHeight: 240,
  },
  ratingTitle: {
    marginTop: theme.spacing['4'],
    color: theme.colors.KALE,
  },
  ratingSubtitle: {
    marginTop: theme.spacing['6'],
  },
  ratingHint: {
    color: theme.colors.CHARCOAL,
    marginVertical: theme.spacing['6'],
  },
  card: {
    minHeight: 64,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    borderRadius: theme.radius.xxlarge,
    paddingVertical: theme.spacing['2'],
    paddingHorizontal: theme.spacing['4'],
  },
  cardLabel: {
    textAlign: 'center',
  },
  cardHovered: {
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTEST,
  },
  cardSelected: {
    backgroundColor: theme.colors.OPACITY.SPINACH.LIGHT,
    borderWidth: 1,
    borderColor: theme.colors.GREEN_2,
  },
  ctaContainer: {
    width: '100%',
    marginTop: theme.spacing['6'],
  },
  ctaContainerSM: {
    maxWidth: 400,
  },
});

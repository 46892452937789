/* istanbul ignore file */

import type { ComponentProps } from 'react';
import React, { memo } from 'react';

import type { LocationSearchMachineContext } from '../../machine';
import {
  LocationSearchDeliveryResult,
  LocationSearchLocationsResults,
} from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationSearchResults = memo(
  (props: LocationSearchResultsProps) => {
    const {
      locationSearchType,
      locations,
      deliveryLocation,
      deliverySearchString,
      predictionDeliveryAddresses,
      focusedLocationId,
      isLoadingPredictions,
      isLoadingLocations,
      isUsingSearchField,
      interactedLocationRestaurantSlug,
      interactedLocationDeliveryAddressId,
      minimalCharactersForSearch,
      withSafeAreaInsets,
      onLocationCardPress,
      onLocationCardFocus,
      onDeliveryLocationCardPress,
      onPredictionPress,
      onTryNewAddressBtnPress,
      onChangeToPickupSearchTypeBtnPress,
      onLocationResultsExpandCollapse,
    } = props;

    if (locationSearchType === 'delivery') {
      return (
        <LocationSearchDeliveryResult
          deliveryLocation={deliveryLocation}
          deliverySearchString={deliverySearchString}
          minimalCharactersForSearch={minimalCharactersForSearch}
          predictionDeliveryAddresses={predictionDeliveryAddresses}
          focusedLocationId={focusedLocationId}
          isLoadingPredictions={isLoadingPredictions}
          interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
          interactedLocationDeliveryAddressId={
            interactedLocationDeliveryAddressId
          }
          isLoadingLocation={isLoadingLocations}
          withSafeAreaInsets={withSafeAreaInsets}
          onPredictionPress={onPredictionPress}
          onDeliveryLocationCardPress={onDeliveryLocationCardPress}
          onTryNewAddressBtnPress={onTryNewAddressBtnPress}
          onChangeToPickupSearchTypeBtnPress={
            onChangeToPickupSearchTypeBtnPress
          }
          onLocationResultsExpandCollapse={onLocationResultsExpandCollapse}
        />
      );
    }

    return (
      <LocationSearchLocationsResults
        locationSearchType={locationSearchType}
        locations={locations}
        focusedLocationId={focusedLocationId}
        isLoading={isLoadingLocations}
        isUsingSearchField={isUsingSearchField}
        interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
        withSafeAreaInsets={withSafeAreaInsets}
        onLocationCardPress={onLocationCardPress}
        onLocationCardFocus={onLocationCardFocus}
        onLocationResultsExpandCollapse={onLocationResultsExpandCollapse}
      />
    );
  },
);

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationSearchResultsProps = Readonly<{
  locationSearchType: LocationSearchMachineContext['locationSearchType'];
  deliverySearchString: LocationSearchMachineContext['deliverySearchString'];
  locations: LocationSearchMachineContext['locations'];
  deliveryLocation: LocationSearchMachineContext['deliveryLocation'];
  minimalCharactersForSearch: number;
  predictionDeliveryAddresses: LocationSearchMachineContext['deliveryAddressPredictions'];
  focusedLocationId: string | undefined;
  interactedLocationRestaurantSlug: string | undefined;
  interactedLocationDeliveryAddressId: string | undefined;
  isLoadingPredictions: boolean;
  isLoadingLocations: boolean;
  isUsingSearchField: boolean;
  withSafeAreaInsets?: boolean;
  onPredictionPress: (placeId: string) => void;
  onLocationCardPress: ComponentProps<
    typeof LocationSearchLocationsResults
  >['onLocationCardPress'];
  onLocationCardFocus: ComponentProps<
    typeof LocationSearchLocationsResults
  >['onLocationCardFocus'];
  onDeliveryLocationCardPress: ComponentProps<
    typeof LocationSearchDeliveryResult
  >['onDeliveryLocationCardPress'];
  onTryNewAddressBtnPress: () => void;
  onLocationResultsExpandCollapse: (isExpanded: boolean) => void;
  onChangeToPickupSearchTypeBtnPress: () => void;
}>;

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, type TextProps, View } from 'react-native';
import {
  BodyText,
  type CloudinaryTransformConfig,
  FallbackImage,
  IllusEmpty_2,
  theme,
} from '@sg/garnish';

export const LoyaltyHomeProgressBarReward = (reward: PointsReward) => {
  const { displayName, name, assetUrl, points, textStyle } = reward;

  const { formatMessage } = useIntl();

  const rewardName = displayName ?? name;
  const shouldRenderImage = points > 0;

  return (
    <View style={styles.container}>
      <BodyText style={[styles.label, textStyle]} sizeMatch={['18']}>
        {points}
      </BodyText>

      {shouldRenderImage ? (
        <FallbackImage
          contentFit="contain"
          style={styles.image}
          defaultImage={IllusEmpty_2}
          baseUrl={assetUrl}
          cloudinaryConfig={cloudinaryConfig}
          aria-label={formatMessage(messages.accessibilityLabel, { points })}
        />
      ) : null}

      {rewardName ? (
        <BodyText
          style={[styles.label, textStyle]}
          numberOfLines={3}
          sizeMatch={['18', '18', '22']}
        >
          {rewardName}
        </BodyText>
      ) : null}
    </View>
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const WIDTH = 100;
const HEIGHT = 80;

const cloudinaryConfig: CloudinaryTransformConfig = {
  crop: 'crop',
  height: 0.3,
  width: 0.3,
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Reward milestone, {points} points',
    description: 'Loyalty | Reward | Reward A11y',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    width: '100%',
  },
  label: {
    textAlign: 'center',
    marginBottom: theme.spacing['4'],
  },
  image: {
    width: WIDTH,
    maxWidth: '100%',
    height: HEIGHT,
    marginBottom: theme.spacing['2'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type PointsReward = {
  points: number;
  name?: string;
  assetUrl?: string | null;
  displayName?: string | null;
  textStyle?: TextProps['style'];
};

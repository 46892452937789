import { theme } from '@garnish/constants';

export const ACTIVE_INGREDIENT_REMOVE_BUTTON_SIZE = 24;

export const ACTIVE_INGREDIENT_PRESSABLE = {
  PADDING: {
    VERTICAL: theme.spacing['1'],
    HORIZONTAL: theme.spacing['2'],
    HORIZONTAL_WITH_BUTTON:
      ACTIVE_INGREDIENT_REMOVE_BUTTON_SIZE + theme.spacing['4'], // "Remove" button size + padding + gap
  },
};

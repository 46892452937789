/* istanbul ignore file */

import { defineMessages } from 'react-intl';

export const giftCardCheckoutFormMessages = defineMessages({
  invalidAmount: {
    defaultMessage: 'Enter a whole amount between {min} and {max}',
    description: 'Gift Card Checkout Form | Validation | Invalid amount',
  },
  recipientEmailRequired: {
    defaultMessage: 'Enter a valid email',
    description:
      'Gift Card Checkout Form | Validation | Required recipient email field',
  },
  recipientEmailInvalid: {
    defaultMessage: 'Enter a valid email',
    description:
      'Gift Card Checkout Form | Validation | Invalid recipient email field',
  },
  senderNameRequired: {
    defaultMessage: 'Sender name is required',
    description:
      'Gift Card Checkout Form | Validation | Required sender name field',
  },
  recipientNameRequired: {
    defaultMessage: 'Recipient name is required',
    description:
      'Gift Card Checkout Form | Validation | Required recipient name field',
  },
  paymentMethodRequired: {
    defaultMessage: 'Payment method is required',
    description:
      'Gift Card Checkout Form | Validation | Required payment method field',
  },
});

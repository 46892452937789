import AsyncStorage from '@react-native-async-storage/async-storage';
import { fromPromise } from 'xstate5';

// ─────────────────────────────────────────────────────────────────────────────

const getOfferIdsFromStorage = fromPromise<{ offerIds: string[] }>(async () => {
  const storedIds = await AsyncStorage.getItem(OFFER_IDS_STORAGE_KEY);
  const offerIds: string[] = JSON.parse(storedIds || '[]');

  return { offerIds };
});

const setOfferIdsInStorage = fromPromise<void, { offerIds: string[] }>(
  async ({ input }) => {
    const { offerIds } = input;

    if (offerIds.length === 0) return;

    const stringifiedOfferIds = JSON.stringify(offerIds);

    await AsyncStorage.setItem(OFFER_IDS_STORAGE_KEY, stringifiedOfferIds);
  },
);

// ─────────────────────────────────────────────────────────────────────────────

export const Actors = {
  getOfferIdsFromStorage,
  setOfferIdsInStorage,
};

// ─── Constants ───────────────────────────────────────────────────────────────

export const OFFER_IDS_STORAGE_KEY = 'LOYALTY.SEEN_OFFER_IDS';

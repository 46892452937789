import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { Stepper } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type { DressingWeight } from '../../../../../graphql/types';
import { useProductDetailsScreenContext } from '../../../ProductDetailsScreen.provider';
import type { DressingDetails } from '../../../state';
import { getWeightValue } from '../../../state';

// ────────────────────────────────────────────────────────────────────────────────

export const ProductDressingAndDetailsDressingStepper = (
  props: ProductDressingAndDetailsDressingStepperProps,
) => {
  const { dressingDetails } = props;
  const { dressingMode } = useProductDetailsScreenContext();

  const isDressingMixedIn = dressingMode === 'mixedIn';
  const isDressingOnTheSide = dressingMode === 'onTheSide';
  const isDressingSupportsWeight = dressingDetails.weight !== undefined;

  const shouldUseWeightStepper = isDressingMixedIn && isDressingSupportsWeight;
  const shouldUsePortionStepper =
    isDressingOnTheSide || !isDressingSupportsWeight;

  if (shouldUseWeightStepper) {
    return (
      <ProductDressingAndDetailsDressingWeightStepper
        dressingDetails={dressingDetails}
      />
    );
  }

  if (shouldUsePortionStepper) {
    return (
      <ProductDressingAndDetailsDressingPortionStepper
        dressingDetails={dressingDetails}
      />
    );
  }

  return null;
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const ProductDressingAndDetailsDressingPortionStepper = (
  props: ProductDressingAndDetailsDressingStepperProps,
) => {
  const { t } = useLocalizationContext();
  const { increaseDressingPortionsNumber, decreaseDressingPortionsNumber } =
    useProductDetailsScreenContext();

  const { dressingDetails } = props;
  const { portionsNumber, ingredientModification } = dressingDetails;

  // ─── HELPERS ────────────────────────────────────────────────────────

  const onIncrement = useCallback(() => {
    increaseDressingPortionsNumber(ingredientModification);
  }, [increaseDressingPortionsNumber, ingredientModification]);

  const onDecrement = useCallback(() => {
    decreaseDressingPortionsNumber(ingredientModification);
  }, [decreaseDressingPortionsNumber, ingredientModification]);

  // ────────────────────────────────────────────────────────────────────

  const value = portionsNumber;
  const label = t('pdp.dressing-and-details.dressing.portion');

  const accessibilityLabelForIncrease = t(
    'pdp.dressing-and-details.dressing.increase-portion',
    { value, label },
  );

  const accessibilityLabelForDecrease = t(
    'pdp.dressing-and-details.dressing.decrease-portion',
    { value, label },
  );

  // ────────────────────────────────────────────────────────────────────

  return (
    <View testID="pdp-dressing-portion-select">
      <Stepper
        value={portionsNumber}
        min={1}
        onIncrement={onIncrement}
        onDecrement={onDecrement}
        labelWidth={80}
        label={label}
        plural={t('pdp.dressing-and-details.dressing.portions')}
        accessibilityLabelForIncrease={accessibilityLabelForIncrease}
        accessibilityLabelForDecrease={accessibilityLabelForDecrease}
      />
    </View>
  );
};

const ProductDressingAndDetailsDressingWeightStepper = (
  props: ProductDressingAndDetailsDressingStepperProps,
) => {
  const { t } = useLocalizationContext();
  const { increaseDressingWeight, decreaseDressingWeight } =
    useProductDetailsScreenContext();

  const { dressingDetails } = props;
  const { weight, ingredientModification } = dressingDetails;

  const dressingWeightLabels = useMemo<Record<DressingWeight, string>>(
    () => ({
      LIGHT: t('pdp.dressing-and-details.dressing.weight.light'),
      MEDIUM: t('pdp.dressing-and-details.dressing.weight.medium'),
      HEAVY: t('pdp.dressing-and-details.dressing.weight.heavy'),
    }),
    [t],
  );

  // ─── HELPERS ────────────────────────────────────────────────────────

  const onIncrement = useCallback(() => {
    increaseDressingWeight(ingredientModification);
  }, [increaseDressingWeight, ingredientModification]);

  const onDecrement = useCallback(() => {
    decreaseDressingWeight(ingredientModification);
  }, [decreaseDressingWeight, ingredientModification]);

  // ────────────────────────────────────────────────────────────────────

  if (weight === undefined) return null;

  const value = getWeightValue(weight);
  const label = dressingWeightLabels[weight];

  const accessibilityLabelForIncrease = t(
    'pdp.dressing-and-details.dressing.increase-weight',
    { value, label },
  );

  const accessibilityLabelForDecrease = t(
    'pdp.dressing-and-details.dressing.decrease-weight',
    { value, label },
  );

  // ────────────────────────────────────────────────────────────────────

  return (
    <View testID="pdp-dressing-weight-select">
      <Stepper
        value={value}
        min={1}
        max={3}
        onIncrement={onIncrement}
        onDecrement={onDecrement}
        label={label}
        labelWidth={80}
        shouldHideValue
        accessibilityLabelForIncrease={accessibilityLabelForIncrease}
        accessibilityLabelForDecrease={accessibilityLabelForDecrease}
      />
    </View>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ProductDressingAndDetailsDressingStepperProps = Readonly<{
  dressingDetails: DressingDetails;
}>;

import React, { useLayoutEffect, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import type { RouteProp } from '@react-navigation/native';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  IllusDressing_1,
  ModalCloseBtn,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import {
  LoadingAnimation,
  SignedOutView,
  TransparentModal,
} from '@order/components';
import { useLocalizationContext } from '@order/Localization';

import { useHeaderRight } from '../../components/Header/Header.hooks';
import type { ModalStackParamList } from '../../navigation/AppNavigation.props';
import { useDerivedRateOrderStatus } from './hooks';
import { useRateOrderNavigation } from './RateOrder.navigation';
import { useRateOrder } from './RateOrderScreen.data';
import { LandingContent } from './RateOrderScreen.landing';
import { RatingContent, RatingWithTitle } from './RateOrderScreen.rating';

// ─────────────────────────────────────────────────────────────────────────────

export const RateOrderScreen = () => {
  const { dismissRateOrder } = useRateOrderNavigation();
  const isLoggedIn = useIsLoggedIn();

  const { minWidth, match } = useResponsive();
  const { t } = useLocalizationContext();

  // ─── Navigation Helpers ──────────────────────────────────────────────

  const navigation = useNavigation();

  const navigationHeaderRightItems = useMemo<NavigationHeaderRightItems>(
    () => [
      {
        key: 'account.gift-code.header.close-btn',
        icon: 'IconClose',
        accessibilityLabel: t('general.close'),
        onPress: dismissRateOrder,
      },
    ],
    [dismissRateOrder, t],
  );
  const navigationHeaderRight = useHeaderRight({
    items: navigationHeaderRightItems,
  });

  useLayoutEffect(() => {
    navigation.setOptions({ headerRight: navigationHeaderRight });
  }, [navigation, navigationHeaderRight]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <TransparentModal
      size={match(['full', 'medium'])}
      onClose={dismissRateOrder}
    >
      {minWidth.isSM ? <ModalCloseBtn onPress={dismissRateOrder} /> : null}

      {isLoggedIn ? (
        <RateOrderScreenSignedInContent />
      ) : (
        <RateOrderScreenSignedOutContent />
      )}
    </TransparentModal>
  );
};

// ─── Components ──────────────────────────────────────────────────────────────

const RateOrderScreenSignedOutContent = () => {
  return <SignedOutView redirectTo="orders" withoutFooter />;
};

const RateOrderScreenSignedInContent = () => {
  const { params } = useRoute<RouteProp<ModalStackParamList, 'RateOrder'>>();
  const { orderId } = params ?? {};

  const { t } = useLocalizationContext();

  const { isFetching, isInvalidOrder, isRatedOrder, isPendingOrder } =
    useDerivedRateOrderStatus(orderId);

  // ─────────────────────────────────────────────────────────────────────

  if (isFetching) {
    return (
      <View style={styles.loadingContainer}>
        <LoadingAnimation />
      </View>
    );
  }

  if (isInvalidOrder) {
    return (
      <LandingContent>
        <LandingContent.Illustration
          source={IllusDressing_1}
          style={styles.notFoundViewIllustration}
        />

        <LandingContent.Title>
          {t('rate.order.not-found-order-view.title')}
        </LandingContent.Title>

        <LandingContent.Subtitle>
          {t('rate.order.not-found-order-view.subtitle')}
        </LandingContent.Subtitle>
      </LandingContent>
    );
  }

  if (isPendingOrder) {
    return (
      <LandingContent>
        <LandingContent.Illustration />

        <LandingContent.Title>
          {t('rate.order.pending-order-view.title')}
        </LandingContent.Title>

        <LandingContent.Subtitle>
          {t('rate.order.pending-order-view.subtitle')}
        </LandingContent.Subtitle>
      </LandingContent>
    );
  }

  if (isRatedOrder) {
    return (
      <LandingContent>
        <LandingContent.Illustration />

        <LandingContent.Title>
          {t('rate.order.rated-order-view.title')}
        </LandingContent.Title>

        <LandingContent.Subtitle>
          {t('rate.order.rated-order-view.subtitle')}
        </LandingContent.Subtitle>
      </LandingContent>
    );
  }

  return <RateOrderScreenSignedInContentRating />;
};

const RateOrderScreenSignedInContentRating = () => {
  const {
    loading,
    submitting,
    landingTitle,
    landingSubtitle,
    ratingTitle,
    ratingSubtitle,
    ratingHint,
    ratingCta,
    cards,
    rating,
    handleHoverRating,
    handleRating,
    handleCardSelection,
    handleSubmit,
  } = useRateOrder();

  // ─────────────────────────────────────────────

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <LoadingAnimation />
      </View>
    );
  }

  return (
    <>
      {rating === 0 && landingTitle ? (
        <LandingContent>
          <LandingContent.Illustration />

          <LandingContent.Title>{landingTitle}</LandingContent.Title>

          <LandingContent.Subtitle>{landingSubtitle}</LandingContent.Subtitle>

          <RatingWithTitle
            rating={rating}
            ratingTitle={ratingTitle}
            onRating={handleRating}
            onHoverRating={handleHoverRating}
          />
        </LandingContent>
      ) : null}

      {rating > 0 && ratingSubtitle ? (
        <RatingContent
          ratingTitle={ratingTitle}
          ratingSubtitle={ratingSubtitle}
          ratingHint={ratingHint}
          ratingCta={ratingCta}
          cards={cards}
          rating={rating}
          submitting={submitting}
          onHoverRating={handleHoverRating}
          onRating={handleRating}
          onSelectCard={handleCardSelection}
          onSubmit={handleSubmit}
        />
      ) : null}
    </>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    paddingTop: theme.spacing['10'],
    paddingBottom: theme.spacing['20'],
  },
  notFoundViewIllustration: {
    width: 200,
    height: 200,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type NavigationHeaderRightItems = Parameters<typeof useHeaderRight>[0]['items'];

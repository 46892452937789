import type { ReactNode } from 'react';
import React from 'react';
import type { PressableProps } from 'react-native';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { theme } from '@garnish/constants';

import { webOnlyStyles } from '../../../../utils';
import { PressableLink } from '../../../PressableLink';
import { useHeroCardResponsiveStyles } from '../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const HeroCardPressable = (props: HeroCardPressableProps) => {
  const { url, urlOpenErrorMessage, onPress, children } = props;

  // ─────────────────────────────────────────────────────────────────────

  const cardContainerResponsiveStyle = useHeroCardResponsiveStyles();

  const pressableStyles = [
    styles.pressable,
    cardContainerResponsiveStyle,
    pressableWebStyles,
  ];

  // ─────────────────────────────────────────────────────────────────────

  if (url) {
    return (
      <PressableLink
        to={url}
        style={pressableStyles}
        errorMessage={urlOpenErrorMessage}
      >
        {children}
      </PressableLink>
    );
  }

  return (
    <TouchableOpacity
      accessibilityRole="button"
      onPress={onPress}
      style={pressableStyles}
      activeOpacity={0.8}
    >
      {children}
    </TouchableOpacity>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    borderRadius: theme.radius.large,
  },
});

const pressableWebStyles = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: theme.spacing['2'],
  transition: `opacity ${theme.transitions.base}ms`,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type HeroCardPressableProps = Readonly<{
  url?: string;
  urlOpenErrorMessage?: string;
  children?: ReactNode;
  onPress?: () => void;
}> &
  Omit<PressableProps, 'children' | 'onPress'>;

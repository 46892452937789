import React from 'react';
import { StyleSheet, View } from 'react-native';
import { FadeView, LoadingDots, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainInfiniteLoadingIndicator = (
  props: OrderAgainInfiniteLoadingIndicatorProps,
) => {
  const { isVisible } = props;

  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    match([styles.containerXS, styles.containerSM]),
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <FadeView show={isVisible} style={containerStyles}>
      <View style={styles.iconContainer}>
        <LoadingDots color={theme.colors.WHITE} />
      </View>
    </FadeView>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const ICON_CONTAINER_SIZE = 40;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  containerXS: {
    marginBottom: theme.spacing['10'],
    marginLeft: theme.spacing['4'],
  },
  containerSM: {
    marginBottom: theme.spacing['8'],
    marginLeft: theme.spacing['6'],
  },
  iconContainer: {
    width: ICON_CONTAINER_SIZE,
    height: ICON_CONTAINER_SIZE,
    borderRadius: ICON_CONTAINER_SIZE,
    backgroundColor: theme.colors.KALE,
    ...theme.elevations['2'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderAgainInfiniteLoadingIndicatorProps = {
  isVisible: boolean;
};

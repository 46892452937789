import React from 'react';
import { StyleSheet } from 'react-native';
import {
  BodyText,
  Button,
  Card,
  HStack,
  theme,
  TitleText,
  useResponsive,
} from '@sg/garnish';

import type {
  SweetpassBenefitsCardsSection,
  SweetpassBenefitsPageCardCtaHandler,
} from './hooks';
import { getSweetpassBenefitsScreenCardPaletteForTag } from './SweetpassBenefitScreenPalette';

export const SweetpassBenefitsPageCardsContent = ({
  content,
  ctaHandler,
}: SweetpassBenefitsPageCardsContentProps) => {
  const { currentBreakpoint, match } = useResponsive();

  return (
    <HStack
      style={styles.container}
      testID="sweetpass-benefits-cards"
      itemsPerRow={2}
    >
      {content.cards.map((card) => {
        const palette = getSweetpassBenefitsScreenCardPaletteForTag(
          card.palette,
        );

        return (
          <Card
            key={card.id}
            testID={`sweetpass-benefits-card-${card.id}`}
            style={[
              styles.card,
              currentBreakpoint.isXS ? styles.cardMobile : {},
            ]}
            backgroundColor={palette.backgroundColor}
          >
            <BodyText style={styles.cardTitle} size={match([5, 5, 4, 4])}>
              {card.title}
            </BodyText>
            <TitleText style={styles.cardText} size={match([6, 5, 4, 4])}>
              {card.description}
            </TitleText>

            <Button
              style={match([styles.ctaMobile, styles.ctaDesktop])}
              testID={`sweetpass-benefits-card-${card.id}-cta`}
              palette="secondary"
              size={match(['small', 'medium', 'large', 'large'])}
              onPress={() => {
                ctaHandler(card.cta.action);
              }}
            >
              {card.cta.title}
            </Button>
          </Card>
        );
      })}
    </HStack>
  );
};

type SweetpassBenefitsPageCardsContentProps = Readonly<{
  content: SweetpassBenefitsCardsSection;
  ctaHandler: SweetpassBenefitsPageCardCtaHandler;
}>;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingTop: theme.spacing['10'],
  },
  card: {
    alignItems: 'center',
    padding: theme.spacing['16'],
  },
  cardMobile: {
    padding: theme.spacing['4'],
  },
  cardTitle: {
    textTransform: 'uppercase',
  },
  cardText: {
    paddingTop: theme.spacing['2'],
    marginBottom: theme.spacing['6'],
    textAlign: 'center',
  },
  ctaDesktop: {
    paddingHorizontal: theme.spacing['10'],
  },
  ctaMobile: {
    paddingHorizontal: theme.spacing['4'],
  },
});

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { type AddressType, BodyText, theme } from '@sg/garnish';

import { TipsGroupCustomTip } from '@order/components';

import { useBagTips, useTippingEnabled } from '../../hooks';
import { BagTip } from '../BagTip';

// ────────────────────────────────────────────────────────────────────────────

export const BagTipping = forwardRef<View, BagTippingProps>((props, ref) => {
  const {
    restaurantId,
    selectedTip,
    subtotal,
    orderChannel,
    isDisabled,
    onTipChange,
  } = props;

  const { formatMessage } = useIntl();
  const { fontScale } = useWindowDimensions();

  const [isCustomTipSelected, setIsCustomTipSelected] = useState(false);

  const isTippingEnabled = useTippingEnabled({ restaurantId, orderChannel });

  const { tips, defaultTipValue } = useBagTips({
    subtotal,
    orderChannel,
  });

  // ─── Accessibility ────────────────────────────────────────────────────────

  const { firstTipsLine, secondTipsLine } = useMemo(() => {
    const firstLine = tips.filter(
      (tip) => tip.value !== '0' && tip.value !== 'custom',
    );

    const secondLine = tips.filter(
      (tip) => tip.value === '0' || tip.value === 'custom',
    );

    if (fontScale <= 1) {
      return {
        firstTipsLine: tips,
        secondTipsLine: [],
      };
    }

    return {
      firstTipsLine: firstLine,
      secondTipsLine: secondLine,
    };
  }, [fontScale, tips]);

  // ─── Tips ─────────────────────────────────────────────────────────────────

  const selectedTipTypeValueOnAmount =
    getTipValueBasedOnAmount(tips, selectedTip) ?? defaultTipValue;
  const selectedTipValue = isCustomTipSelected
    ? 'custom'
    : selectedTipTypeValueOnAmount;

  // ──────────────────────────────────────────────────────────────────────────

  const onTipValueChange = useCallback(
    (tipValue: string) => {
      const hasSelectedCustomTip = tipValue === 'custom';
      const tipAmount = tips.find((tip) => tip.value === tipValue)?.amount ?? 0;

      onTipChange(hasSelectedCustomTip ? 0 : tipAmount);

      // without this timeout, the custom tip component will be initialized with the previous amount.
      setTimeout(() => {
        setIsCustomTipSelected(hasSelectedCustomTip);
      });
    },
    [tips, onTipChange],
  );

  // ──────────────────────────────────────────────────────────────────────────
  // set the default tip value on mount
  useEffect(() => {
    onTipValueChange(defaultTipValue);
  }, [defaultTipValue, onTipValueChange]);

  // ──────────────────────────────────────────────────────────────────────────

  if (!isTippingEnabled || tips.length === 0) return null;

  const sectionTitle =
    orderChannel === 'delivery'
      ? formatMessage(messages.deliveryTipTitle)
      : formatMessage(messages.restaurantTipTitle);

  const sectionDescription =
    orderChannel === 'delivery'
      ? formatMessage(messages.deliveryTipDescription)
      : formatMessage(messages.restaurantTipDescription);

  return (
    <View ref={ref} style={styles.container}>
      <View style={styles.titleContainer}>
        <BodyText sizeMatch={['14']}>{sectionTitle}</BodyText>

        <BodyText sizeMatch={['12']} style={styles.description}>
          {sectionDescription}
        </BodyText>
      </View>

      {/* ─── Standard tips ────────────────────────── */}

      <View style={styles.tipsContainer} accessibilityRole="radiogroup">
        {firstTipsLine.map((item) => {
          const { value, label, helperText, accessibilityHint } = item;

          return (
            <BagTip
              key={value}
              value={value}
              label={label}
              helperText={helperText}
              accessibilityLabel={label}
              accessibilityHint={accessibilityHint}
              isActive={value === selectedTipValue}
              isDisabled={isDisabled}
              setValue={onTipValueChange}
            />
          );
        })}
      </View>

      {secondTipsLine.length > 0 ? (
        <View style={styles.tipsContainer} accessibilityRole="radiogroup">
          {secondTipsLine.map((item) => {
            const { value, label, helperText, accessibilityHint } = item;

            return (
              <BagTip
                key={value}
                value={value}
                label={label}
                helperText={helperText}
                accessibilityLabel={label}
                accessibilityHint={accessibilityHint}
                isActive={value === selectedTipValue}
                isDisabled={isDisabled}
                setValue={onTipValueChange}
              />
            );
          })}
        </View>
      ) : null}

      {/* ─── Custom tip ───────────────────────────── */}

      {isCustomTipSelected ? (
        <TipsGroupCustomTip
          tipAmountInCents={selectedTip}
          orderSubtotalInCents={subtotal}
          onChange={onTipChange}
        />
      ) : null}
    </View>
  );
});

// ─── Helpers ────────────────────────────────────────────────────────────────

function getTipValueBasedOnAmount(
  tipsGroup: readonly Tip[],
  tipAmount: number,
) {
  return tipsGroup.find((tip) => tip.amount === tipAmount)?.value;
}

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  deliveryTipTitle: {
    defaultMessage: 'Thank your driver with a tip',
    description: 'Bag | Tipping | Delivery tip title',
  },
  deliveryTipDescription: {
    defaultMessage:
      "Optional, 100% of the tip goes to your courier. To modify, select 'Custom' or another amount.",
    description: 'Bag | Tipping | Delivery tip description',
  },
  restaurantTipTitle: {
    defaultMessage: 'Restaurant tip',
    description: 'Bag | Tipping | Restaurant tip title',
  },
  restaurantTipDescription: {
    defaultMessage:
      'Tipping for great service is always appreciated. It’ll be shared across our team!',
    description: 'Bag | Tipping | Restaurant tip description',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    gap: theme.spacing['4'],
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['4'],
    marginHorizontal: -theme.spacing['4'],
    borderTopWidth: theme.spacing['2'],
    borderTopColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
  titleContainer: {
    gap: theme.spacing['1'],
  },
  description: {
    color: theme.colors.CHARCOAL,
  },
  tipsContainer: {
    flexDirection: 'row',
    gap: theme.spacing['2'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagTippingProps = {
  restaurantId: string;
  selectedTip: number;
  subtotal: number;
  orderChannel: AddressType;
  isDisabled: boolean;
  onTipChange: (tip: number) => void;
};

type Tip = Readonly<{
  value: string;
  label: string;
  helperText?: string;
  amount?: number;
  accessibilityHint?: string;
}>;

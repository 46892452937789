import { useCallback } from 'react';
import { type OperationResult } from 'urql';

import { type ApplyRewardInput, type Exact } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';
import { getDay } from '@order/utils';

import type { ApplyRewardMutation } from '../GraphQL/Rewards.generated';
import { useApplyRewardErrorMessages } from './useApplyRewardErrorMessages';

/** Given a response that could be for apply reward v1 or v2, return the error message. */
export const useApplyRewardErrorMessage = () => {
  const daysOfTheWeek = useDaysOfTheWeek();
  const {
    defaultError,
    networkError,
    multipleErrors,
    getApplyRewardFailureCode,
    getApplyRewardFailureReason,
  } = useApplyRewardErrorMessages();

  return useCallback(
    (response: ApplyRewardResponse) => {
      const error = response?.error;

      if (error) {
        return networkError;
      }

      const data = response?.data?.applyReward;

      if (!data) return;

      const typename = data?.__typename;
      const isSuccess = typename === 'ApplyRewardSuccess';
      const isNotApplied = typename === 'RewardNotApplied';

      if (isSuccess) return;

      if (!isNotApplied) {
        return defaultError;
      }

      const failureCode = data?.failureCode;
      const failureReasons = data?.failureReasons ?? [];
      const failureMetadata = data?.failureMetadata ?? {};

      if (failureReasons.length > 1) {
        return multipleErrors;
      }

      if (failureReasons.length === 1 && failureReasons[0]) {
        const channel = failureMetadata?.requiredChannel ?? '';
        const requiredDays = (failureMetadata?.requiredDays ?? []) as number[];
        const days = getDays(requiredDays, daysOfTheWeek);

        const message = getApplyRewardFailureReason(String(failureReasons[0]));

        return message
          .replaceAll('(channel)', String(channel))
          .replaceAll('(days)', String(days));
      }

      if (failureCode) {
        return getApplyRewardFailureCode(failureCode);
      }

      return defaultError;
    },
    [
      defaultError,
      networkError,
      getApplyRewardFailureCode,
      multipleErrors,
      getApplyRewardFailureReason,
      daysOfTheWeek,
    ],
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

// SessionM Days are offset by one (0-6, 0=Monday, 6=Sunday).
function useDaysOfTheWeek() {
  const { t } = useLocalizationContext();

  return {
    0: t(getDay('1')),
    1: t(getDay('2')),
    2: t(getDay('3')),
    3: t(getDay('4')),
    4: t(getDay('5')),
    5: t(getDay('6')),
    6: t(getDay('0')),
  };
}

// Given an array of SessionM days, return the localized representation for it.
function getDays(
  requiredDays: readonly number[],
  daysOfTheWeek: ReturnType<typeof useDaysOfTheWeek>,
) {
  return (
    requiredDays
      ?.map((day) => daysOfTheWeek[day as 0 | 1 | 2 | 3 | 4 | 5 | 6])
      ?.join(', ') ?? ''
  );
}

// ─── Types ───────────────────────────────────────────────────────────────────

type ApplyRewardResponse = Partial<
  OperationResult<ApplyRewardMutation, Exact<{ input: ApplyRewardInput }>>
>;

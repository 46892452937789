/* istanbul ignore file */
import Constants from 'expo-constants';
import * as Device from 'expo-device';

/**
 * Returns whether app is running in Simulator (Expo Go or Dev Client).
 */
export function checkIfDeviceNotSimulator() {
  const isDeviceNotSimulator = Device.isDevice;

  return isDeviceNotSimulator;
}

/**
 * Returns whether app is running in Expo Go client.
 * Can be useful for conditional native modules' integration.
 */
export function checkIfInExpoGoClient() {
  return Constants.appOwnership === 'expo';
}

/**
 * Returns a boolean indicating whether the app is being developed locally (Expo Go, dev client, etc.)
 */
export function checkIfInLocalDevelopmentMode() {
  const { manifest2 } = Constants;

  return Boolean(manifest2?.extra?.expoGo?.packagerOpts?.dev);
}

export function getLocalDevelopmentHost() {
  const { manifest2 } = Constants;

  const host = manifest2?.extra?.expoGo?.debuggerHost ?? '';

  // extract host without port
  return host.split(':')[0];
}

/**
 * Returns the current application version from the Expo app config
 */
export function getAppVersion() {
  return Constants.expoConfig?.version ?? '0.0.0';
}

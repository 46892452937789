export const menu = {
  'menu.hero.title.standard': 'Ordering from',
  'menu.hero.title.delivery': 'Delivery to',
  'menu.hero.illustration.alt.standard': 'Sweetgreen salad bowl',
  'menu.hero.illustration.alt.delivery': 'Sweetgreen courier',
  'menu.hero.store-information': 'Store information',
  'menu.hero.change-location-label': 'Change location',
  'menu.cyo.title': 'Create your own',
  'menu.cyo.description':
    'Be your own chef and build your perfect salad, bowl or plate.',
  'menu.cyo.cta': 'Get Started',
  'menu.cyo.bowl.title': 'Custom bowl',
  'menu.cyo.bowl.description':
    'For the innovator — personalize your bowl any which way you like.',
  'menu.cyo.bowl.cta': 'Create your own',
  'menu.cyo.plate.title': 'Custom plate',
  'menu.cyo.plate.description':
    "Create a plate for a warm and healthy meal that'll satisfy your heartiest craving.",
  'menu.cyo.plate.cta': 'Get started',
  'menu.cyo.out-of-stock.cta': 'Currently unavailable',
  'menu.contains-restrictions': 'Contains {restrictions}',
  'menu.hero.estimate': 'Delivers in {ETA}',
  'menu.collections': 'Collections',
  'menu.personalization': 'Your personalized picks',
  'menu.personalization.recommendation.title': 'Personalized Pick',
  'menu.personalization.recommendation.label': 'CUSTOM',
  'menu.one-click-success': '{quantity}x {productName} added to your bag!',

  // ─── Product Card ────────────────────────────

  'menu.product-card.a11y.label':
    '{productName}. Description: {description}. Price: {price}. {extra}',
  'menu.product-card.a11y.hint':
    'Navigates to {productName} product details page',

  'menu.product-card.one-click-add-to-bag.a11y.label':
    'Add 1 {productName} to your bag',
  'menu.product-card.one-click-add-to-bag.a11y.hint':
    '1 {productName} will be added to your bag',
  'menu.product-card.one-click-add-to-bag-loading.a11y.label':
    'Adding 1 {productName} to your bag',
  'menu.product-card.out-of-stock-notice': 'Currently out of stock',
};

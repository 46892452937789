import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { Header } from '@expo/html-elements';
import { Container, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsHeaderContainer = (props: PropsWithChildren) => {
  const { children } = props;

  const { match } = useResponsive();

  return (
    <Header>
      <Container
        style={match([styles.headerContainerXS, styles.headerContainerSM])}
      >
        {children}
      </Container>
    </Header>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  headerContainerXS: {
    paddingTop: theme.spacing['6'],
    paddingBottom: theme.spacing['4'],
    rowGap: theme.spacing['4'],
  },
  headerContainerSM: {
    paddingTop: theme.spacing['16'],
    paddingBottom: theme.spacing['10'],
    rowGap: theme.spacing['6'],
  },
});

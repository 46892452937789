/* istanbul ignore file */

import {
  type AddressType,
  capitalizeFirstLetter,
  type PinData,
} from '@sg/garnish';

import type {
  DeliveryLocationWithoutNearbyStore,
  Location,
  LocationSearchMachineContext,
  PositionCoordinates,
} from '../../machine';

// ─────────────────────────────────────────────────────────────────────────────

export function generateMapPinsForLocations(
  props: GenerateMapPinsForLocationsProps,
): readonly PinData[] {
  const {
    currentPosition,
    customerName = '',
    locations,
    deliveryLocation,
    locationSearchType,
    focusedPinId,
  } = props;
  const { pickup = [], outpost = [] } = locations ?? {};

  // ─── Current Position ────────────────────────────────────────────────

  const currentPositionPin = generateCurrentPositionPin(
    currentPosition,
    customerName,
  );

  // ─── Delivery Location ───────────────────────────────────────────────

  if (locationSearchType === 'delivery') {
    const deliveryLocationPin = generateLocationPin({
      isDeliveryAddress: true,
      focusedPinId: deliveryLocation?.id,
    })(deliveryLocation);

    return [currentPositionPin, deliveryLocationPin].filter(
      withoutInvalidLocationsPins,
    );
  }

  // ─── Locations ───────────────────────────────────────────────────────

  const shouldMinimizePickupLocationsPins = locationSearchType === 'outpost';
  const shouldMinimizeOutpostLocationsPins = locationSearchType === 'pickup';

  const pickupLocationsPins = pickup.map(
    generateLocationPin({
      shouldMinimize: shouldMinimizePickupLocationsPins,
      focusedPinId,
    }),
  );
  const outpostLocationsPins = outpost.map(
    generateLocationPin({
      shouldMinimize: shouldMinimizeOutpostLocationsPins,
      focusedPinId,
    }),
  );

  return [
    currentPositionPin,
    ...pickupLocationsPins,
    ...outpostLocationsPins,
  ].filter(withoutInvalidLocationsPins);
}

export function getActiveLocationsCoordinates(
  props: GetActiveLocationsCoordinatesProps,
): readonly LocationCoordinate[] {
  const { locationSearchType, locations, deliveryLocation } = props;

  const shouldGeneratePickupLocationsCoordinates =
    locationSearchType === 'pickup' && locations?.pickup !== undefined;
  const shouldGenerateOutpostLocationsCoordinates =
    locationSearchType === 'outpost' && locations?.outpost !== undefined;
  const shouldGenerateDeliveryLocationCoordinates =
    locationSearchType === 'delivery' && deliveryLocation !== undefined;

  // ─────────────────────────────────────────────────────────────────────

  if (shouldGeneratePickupLocationsCoordinates) {
    return locations.pickup.map(extractLocationCoordinates);
  }

  if (shouldGenerateOutpostLocationsCoordinates) {
    return locations?.outpost.map(extractLocationCoordinates);
  }

  if (shouldGenerateDeliveryLocationCoordinates) {
    return [{ lat: deliveryLocation?.lat, lng: deliveryLocation?.lng }];
  }

  return [];
}

// ─────────────────────────────────────────────────────────────────────────────

function generateCurrentPositionPin(
  currentPosition: PositionCoordinates | undefined,
  customerName: string,
) {
  if (!currentPosition) return;

  const currentPositionPin: PinData = {
    id: 'customer',
    name: customerName,
    lat: currentPosition?.latitude,
    lng: currentPosition?.longitude,
    isCustomer: true,
  };

  return currentPositionPin;
}

function generateLocationPin(props: GenerateLocationPinProps) {
  const { shouldMinimize = false, focusedPinId, isDeliveryAddress } = props;

  return (
    location: Location | DeliveryLocationWithoutNearbyStore | undefined,
  ): PinData | undefined => {
    if (!location) return;

    const isActive = focusedPinId === location.id;
    const deliveryAddressType = getDeliveryAddressType(location.name);

    return {
      id: location.id,
      name: capitalizeFirstLetter(location.name),
      lat: location.lat,
      lng: location.lng,
      address: location.address,
      restaurantSlug: location.restaurantSlug,
      restaurantName: location.name,
      acceptingOrders: location.acceptingOrders,
      isOutpost: location.isOutpost,
      active: isActive,
      isMinimized: shouldMinimize,
      deliveryAddressType,
      isDeliveryAddress,
    };
  };
}

function getDeliveryAddressType(
  locationName: string,
): PinData['deliveryAddressType'] {
  if (locationName === 'home') return 'home';

  if (locationName === 'work') return 'work';

  return 'custom';
}

function withoutInvalidLocationsPins(pin: PinData | undefined): pin is PinData {
  return pin !== undefined;
}

function extractLocationCoordinates(
  location: Location | DeliveryLocationWithoutNearbyStore,
): LocationCoordinate {
  return { lat: location.lat, lng: location.lng };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type GenerateLocationPinProps = Readonly<{
  shouldMinimize?: boolean;
  isDeliveryAddress?: boolean;
  focusedPinId?: string;
}>;

type GetActiveLocationsCoordinatesProps = Readonly<{
  locationSearchType?: AddressType;
  locations?: LocationSearchMachineContext['locations'];
  deliveryLocation?: Location | DeliveryLocationWithoutNearbyStore;
}>;

type GenerateMapPinsForLocationsProps = Readonly<{
  locationSearchType?: AddressType;
  locations?: LocationSearchMachineContext['locations'];
  deliveryLocation?: Location | DeliveryLocationWithoutNearbyStore;
  focusedPinId?: string;
  customerName?: string;
  currentPosition?: PositionCoordinates;
}>;

type LocationCoordinate = Readonly<{
  lat: number;
  lng: number;
}>;

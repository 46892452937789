import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Button,
  HStack,
  IllusEmpty_2,
  Image,
  Modal,
  TextLink,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { TransparentModal } from '../../../../components/TransparentModal';
import type { ModalStackChildProps } from '../../../../navigation/AppNavigation.props';
import { useReorderRightIcon } from '../ReorderConfirmLocation/ReorderConfirmLocation.components';

const illustration = IllusEmpty_2;

export const NotOnMenu = ({
  navigation,
  deliveryAddressName,
  restaurantName,
  isLoadingMenu,
  cancelReorder,
  changeLocation,
  viewMenu,
}: Readonly<{
  navigation: ModalStackChildProps<'ReorderConflictReview'>['navigation'];
  deliveryAddressName?: string;
  restaurantName?: string;
  isLoadingMenu: boolean;
  cancelReorder: () => void;
  changeLocation: () => void;
  viewMenu: () => void;
}>) => {
  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();

  const Layout = currentBreakpoint.isXS ? MobileLayout : DesktopLayout;

  return (
    <TransparentModal
      testID="reorder-not-on-menu"
      size="medium"
      onClose={cancelReorder}
      mobileEdges={['bottom']}
    >
      <Layout
        navigation={navigation}
        isLoadingMenu={isLoadingMenu}
        cancelReorder={cancelReorder}
        changeLocation={changeLocation}
        viewMenu={viewMenu}
      >
        <Modal.Headline>
          {t('reorder.conflicts.not-on-menu.headline')}
        </Modal.Headline>

        <Modal.Row>
          <BodyText size={3} style={styles.bodyText}>
            {deliveryAddressName
              ? t('reorder.conflicts.reordering-to')
              : t('reorder.conflicts.reordering-from')}
            <TextLink style={styles.textUnderline} onPress={changeLocation}>
              {deliveryAddressName ? deliveryAddressName : restaurantName}
            </TextLink>
          </BodyText>
        </Modal.Row>

        <Modal.BodyText>
          {t('reorder.conflicts.not-on-menu.notice')}
        </Modal.BodyText>
      </Layout>
    </TransparentModal>
  );
};

const DesktopLayout = ({
  children,
  isLoadingMenu,
  changeLocation,
  viewMenu,
  cancelReorder,
}: Readonly<{
  children?: React.ReactNode;
  isLoadingMenu?: boolean;
  cancelReorder: () => void;
  changeLocation: () => void;
  viewMenu: () => void;
}>) => {
  const { t } = useLocalizationContext();

  return (
    <Layout
      isLoadingMenu={isLoadingMenu}
      changeLocation={changeLocation}
      viewMenu={viewMenu}
    >
      <View style={styles.desktopImageContainer}>
        <Image style={styles.image} source={illustration} />
      </View>
      <View style={styles.content}>
        <Modal.CloseBtn
          onPress={cancelReorder}
          accessibilityHint=""
          accessibilityLabel={t('general.close')}
          accessibilityRole="imagebutton"
          testID="reorder.close-button"
        />
        {children}
      </View>
    </Layout>
  );
};

const MobileLayout = ({
  navigation,
  children,
  isLoadingMenu,
  changeLocation,
  viewMenu,
}: Readonly<{
  navigation: ModalStackChildProps<'ReorderConflictReview'>['navigation'];
  children?: React.ReactNode;
  isLoadingMenu?: boolean;
  changeLocation: () => void;
  viewMenu: () => void;
}>) => {
  const rightIcon = useReorderRightIcon();

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => rightIcon,
      headerShown: true,
    });
  }, [navigation, rightIcon]);

  return (
    <Layout
      isLoadingMenu={isLoadingMenu}
      changeLocation={changeLocation}
      viewMenu={viewMenu}
    >
      <View style={styles.mobileImageContainer}>
        <Image style={styles.image} source={illustration} />
      </View>
      <View style={styles.content}>{children}</View>
    </Layout>
  );
};

const Layout = ({
  children,
  isLoadingMenu,
  changeLocation,
  viewMenu,
}: Readonly<{
  isLoadingMenu?: boolean;
  children?: React.ReactNode;
  changeLocation: () => void;
  viewMenu: () => void;
}>) => {
  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();

  return (
    <>
      <View style={currentBreakpoint.isXS ? styles.column : styles.row}>
        {children}
      </View>
      <Modal.Footer style={styles.footer} withoutTopBorder>
        <HStack itemsPerRow={2} gap={theme.spacing['4']}>
          <Button
            size="large"
            palette="secondary"
            onPress={changeLocation}
            accessibilityLabel={t('reorder.change-location')}
            accessibilityHint={t('reorder.change-location')}
            accessibilityRole="button"
            testID="reorder.change-location"
          >
            {t('reorder.change-location')}
          </Button>
          <Button
            size="large"
            onPress={viewMenu}
            accessibilityLabel={t('reorder.view-menu')}
            accessibilityHint={t('reorder.view-menu')}
            accessibilityRole="button"
            testID="reorder.view-menu"
            isLoading={isLoadingMenu}
          >
            {t('reorder.view-menu')}
          </Button>
        </HStack>
      </Modal.Footer>
    </>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  footer: {
    paddingTop: theme.spacing['6'],
  },
  mobileImageContainer: { alignItems: 'center' },
  desktopImageContainer: {
    paddingTop: theme.spacing['6'],
    paddingLeft: theme.spacing['6'],
  },
  image: {
    height: 212,
    width: 212,
  },
  bodyText: {
    paddingBottom: theme.spacing['4'],
  },
  textUnderline: {
    color: theme.colors.KALE,
    textDecorationLine: 'underline',
    textDecorationColor: theme.colors.KALE,
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flex: 1,
    flexDirection: 'column',
  },
});

import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useClient } from 'urql';
import { useNoticeBannersStackContext } from '@sg/garnish';
import {
  type DeliveryOrderDetail,
  type PaymentMethodCard,
} from '@sg/graphql-schema';

import {
  type AddPaymentMethodParams,
  type OrderingContext,
  type PaymentMethod,
} from '@order/features/ordering';
import { type PartialLineItem } from '@order/graphql';

import {
  BagAddPaymentMethodDocument,
  type BagAddPaymentMethodMutation,
  type BagAddPaymentMethodMutationVariables,
  BagPaymentMethodsDocument,
  type BagPaymentMethodsQuery,
} from '../../GraphQL/BagPaymentMethods.generated';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A hook for fetching the customer's payment methods.
 */
export const usePaymentMethods = () => {
  const client = useClient();
  const { formatMessage } = useIntl();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  // ─── Queries ──────────────────────────────────────────────────────────────

  const paymentMethodsQuery = useCallback(async () => {
    const queryPaymentMethods = client.query<BagPaymentMethodsQuery>;

    return queryPaymentMethods(
      BagPaymentMethodsDocument,
      {},
      { requestPolicy: 'network-only' },
    ).toPromise();
  }, [client.query]);

  // ─── Mutations ────────────────────────────────────────────────────────────

  const addPaymentMethod = useCallback(
    async (
      _context: OrderingContext<PartialLineItem, DeliveryOrderDetail>,
      { params }: AddPaymentMethodParams,
    ) => {
      const mutation = client.mutation<
        BagAddPaymentMethodMutation,
        BagAddPaymentMethodMutationVariables
      >;

      const response = await mutation(
        BagAddPaymentMethodDocument,
        {
          input: params,
        },
        {
          requestPolicy: 'network-only',
        },
      ).toPromise();

      if (
        response.data?.updatePaymentMethod.__typename !== 'PaymentMethodCard'
      ) {
        addNoticeBanner({
          text: formatMessage(messages.paymentMethodError),
          palette: 'caution',
        });

        return null;
      }

      const paymentMethod = response.data.updatePaymentMethod;

      return convertPaymentMethod(paymentMethod);
    },
    [client.mutation, addNoticeBanner, formatMessage],
  );

  // ─── Helpers ──────────────────────────────────────────────────────────────

  const fetchPaymentMethods = useCallback(async () => {
    const response = await paymentMethodsQuery();
    const paymentMethods = response.data?.paymentMethodCards ?? [];

    return paymentMethods.map((paymentMethod) => {
      if (paymentMethod.__typename !== 'PaymentMethodCard') {
        throw new Error('Invalid payment method');
      }

      return convertPaymentMethod(paymentMethod);
    });
  }, [paymentMethodsQuery]);

  // ──────────────────────────────────────────────────────────────────────────

  return { fetchPaymentMethods, addPaymentMethod };
};

const convertPaymentMethod = (paymentMethod: PaymentMethodCard) => {
  const { cardType, expirationState: exp } = paymentMethod;

  return {
    ...paymentMethod,
    cardType: cardType as unknown as PaymentMethod['cardType'],
    expirationState: exp as unknown as PaymentMethod['expirationState'],
  };
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  paymentMethodError: {
    defaultMessage:
      'We were unable to save your card information. Please try again.',
    description: 'Bag | Payment method | Payment method error',
  },
});

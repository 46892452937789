import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useContentfulContentTypeEntry } from '@order/Contentful';
import { useFeatureFlag } from '@order/LaunchDarkly';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns delivery disclosure for the provided variation if it's enabled.
 */
export const useDeliveryDisclosure = (params: useDeliveryDisclosureParams) => {
  const { variation, restaurantDeliveryFee = 0 } = params;

  const { formatNumber, formatMessage } = useIntl();

  // ─── Feature Flags ───────────────────────────────────────────────────

  const isDisclosureBannerEnabledForMenu = useFeatureFlag(
    'CELS-2359-delivery-fee-disclosure-banner',
  );
  const isDisclosureBannerEnabledForCheckout = useFeatureFlag(
    'CELS-2760-delivery-fee-disclosure-banner-checkout',
  );

  // ─── Data ────────────────────────────────────────────────────────────

  const fallbackDataFields = useMemo<DeliveryDisclosuresEntryFields>(
    () => ({
      menuPageDisclosure: formatMessage(messages.menuPageDisclosure),
      checkoutPageDisclosure: formatMessage(messages.checkoutPageDisclosure),
      deliveryTipDescription: formatMessage(messages.deliveryTipDescription),
    }),
    [formatMessage],
  );

  const { data, fetching: isFetching } =
    useContentfulContentTypeEntry<DeliveryDisclosuresEntryFields>({
      contentType: 'deliveryTipDisclosure',
    });

  const menuPageDisclosure =
    data?.fields?.menuPageDisclosure ?? fallbackDataFields.menuPageDisclosure;
  const checkoutPageDisclosure =
    data?.fields?.checkoutPageDisclosure ??
    fallbackDataFields.checkoutPageDisclosure;
  const deliveryTipDescription =
    data?.fields?.deliveryTipDescription ??
    fallbackDataFields.deliveryTipDescription;

  // ─── Derived Data ────────────────────────────────────────────────────

  const restaurantDeliveryFeeInCents = restaurantDeliveryFee / 100;

  // NOTE: We use `replace` instead of `trailingZeroDisplay` and
  //      `maximumFractionDigits` since they are working on Android.
  const formattedDeliveryFee = formatNumber(restaurantDeliveryFeeInCents, {
    style: 'currency',
    currency: 'USD',
  }).replace('.00', '');

  const deliveryDisclosure = useMemo(() => {
    if (variation === 'menu' && isDisclosureBannerEnabledForMenu) {
      return formatDisclosure(menuPageDisclosure, formattedDeliveryFee);
    }

    if (variation === 'checkout' && isDisclosureBannerEnabledForCheckout) {
      return formatDisclosure(checkoutPageDisclosure, formattedDeliveryFee);
    }

    if (variation === 'delivery-tip') {
      return formatDisclosure(deliveryTipDescription, formattedDeliveryFee);
    }
  }, [
    variation,
    isDisclosureBannerEnabledForMenu,
    isDisclosureBannerEnabledForCheckout,
    menuPageDisclosure,
    formattedDeliveryFee,
    checkoutPageDisclosure,
    deliveryTipDescription,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return { isFetching, deliveryDisclosure };
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function formatDisclosure(
  disclosureMessage: string,
  restaurantDeliveryFee: string,
) {
  return disclosureMessage.replaceAll(
    '[locationDeliveryFee]',
    restaurantDeliveryFee,
  );
}

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages<keyof DeliveryDisclosuresEntryFields>({
  menuPageDisclosure: {
    description: 'Delivery disclosures | Delivery fee | Menu disclosure',
    defaultMessage:
      'Thanks for choosing delivery. [locationDeliveryFee] goes directly to our delivery partner. No service fee. Tips are separate + optional.',
  },
  checkoutPageDisclosure: {
    description: 'Delivery disclosures | Delivery fee | Checkout ',
    defaultMessage:
      'Thanks for choosing delivery. [locationDeliveryFee] goes directly to our delivery partner. No service fee. Tips are separate + optional.',
  },
  deliveryTipDescription: {
    description: 'Delivery tip disclosure | Checkout page',
    defaultMessage:
      "100% of the tip goes to your driver. Thank them with a tip today! To modify, select 'Custom' or another amount.",
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type useDeliveryDisclosureParams = {
  variation: 'menu' | 'checkout' | 'delivery-tip';
  restaurantDeliveryFee?: number;
};

export type DeliveryDisclosuresEntryFields = {
  menuPageDisclosure: string;
  checkoutPageDisclosure: string;
  deliveryTipDescription: string;
};

/* istanbul ignore file */

import { useCallback, useMemo } from 'react';
import { useClient } from 'urql';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useOrders } from '@order/features/orders';

import {
  OrdersV2ForReorderRailDocument,
  type OrdersV2ForReorderRailQuery,
  type OrdersV2ForReorderRailQueryVariables,
} from '../../graphql/RecentOrders.generated';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A hook for fetching the user's most recent orders.
 */
export const useRecentOrders = () => {
  const client = useClient();
  const isLoggedIn = useIsLoggedIn();

  // ─── Queries ─────────────────────────────────────────────────────────

  const ordersQuery = useCallback(
    async (input: OrdersV2ForReorderRailQueryVariables['input']) => {
      const queryOrders = client.query<
        OrdersV2ForReorderRailQuery,
        OrdersV2ForReorderRailQueryVariables
      >;

      return queryOrders(
        OrdersV2ForReorderRailDocument,
        { input },
        { requestPolicy: 'network-only' },
      ).toPromise();
    },
    [client.query],
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  const fetchOrders = useCallback<FetchOrders>(
    async (context) => {
      const { page, ordersPerPage } = context;

      if (!isLoggedIn) return { orders: [], lastPage: page };

      const { data } = await ordersQuery({ take: ordersPerPage, page });

      const ordersData = data?.ordersV2;
      const hasResolvedOrders =
        ordersData?.__typename === 'OrdersResponseSuccess';

      if (!hasResolvedOrders) {
        return {
          orders: [],
          lastPage: page,
          includeCanceledOrders: true,
          includePlacedOrders: true,
        };
      }

      // NOTE: BE pagination utilizes a zero-based counter (e.g., 0 == 1, 1 == 2, etc.),
      //       therefore, we increase the number by one to match the state machine model.
      const lastPage = (ordersData.pagination.last ?? 0) + 1;

      return { orders: ordersData.orders, lastPage };
    },
    [isLoggedIn, ordersQuery],
  );

  // ─────────────────────────────────────────────────────────────────────

  const { orders, isFetchingInitialOrders, fetchInitialOrders, refetchOrders } =
    useOrders<Order>({
      fetchOrders,
      ordersPerPage: 4,
    });

  const mostRecentLineItems = useMemo(() => {
    return orders
      .flatMap((order) =>
        order.lineItems.map((lineItem) => ({ ...lineItem, order })),
      )
      .slice(0, 4);
  }, [orders]);

  // ─────────────────────────────────────────────────────────────────────

  const mostRecentOrder = orders[0];

  return {
    mostRecentOrder,
    mostRecentLineItems,
    isFetchingInitialOrders,
    fetchInitialOrders,
    refetchOrders,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type FetchOrders = UseOrdersParams['fetchOrders'];

type UseOrdersParams = Parameters<typeof useOrders<Order>>[0];

type Order = Orders[number];

type Orders = NonNullable<
  Extract<
    OrdersV2ForReorderRailQuery['ordersV2'],
    { __typename: 'OrdersResponseSuccess' }
  >['orders']
>;

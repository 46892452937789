import type { ComponentProps, ReactNode } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import Animated from 'react-native-reanimated';

import { CarouselItem } from '../CarouselItem';

// ─────────────────────────────────────────────────────────────────────────────

export const CarouselStage = (props: CarouselStageProps) => {
  const { children, style, itemStyle, onLayout } = props;

  const stageStyles = [styles.stage, style];

  return (
    <View style={styles.container}>
      <Animated.View style={stageStyles} onLayout={onLayout}>
        {React.Children.map(children, (child) => (
          <CarouselItem style={itemStyle}>{child}</CarouselItem>
        ))}
      </Animated.View>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
  },
  stage: {
    flexDirection: 'row',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CarouselStageProps = Readonly<{
  children: ReactNode;
  style: AnimatedViewProps['style'];
  itemStyle: AnimatedViewProps['style'];
  onLayout: AnimatedViewProps['onLayout'];
}>;

type AnimatedViewProps = ComponentProps<typeof Animated.View>;

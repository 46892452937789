import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { CollapseIndicatorIcon } from '../../../Collapsible';
import { Icon } from '../../../Icon';
import { BodyText } from '../../../Text';
import type { PickerTriggerProps } from '../Picker.types';

// ────────────────────────────────────────────────────────────────────────────────

export const PickerTrigger = (props: PickerTriggerProps) => {
  const {
    label,
    accessibilityLabel,
    selectedOption,
    expanded,
    variation = 'standard',
    iconLeft,
    iconRight,
    bold,
    onPress,
  } = props;

  const isBoxVariation = variation === 'box';
  const triggerStyle = [styles.trigger, isBoxVariation && styles.triggerBox];

  return (
    <View>
      {label ? (
        <BodyText
          size={4}
          style={isBoxVariation ? styles.labelWithSpacing : undefined}
        >
          {label}
        </BodyText>
      ) : null}

      <Pressable
        style={triggerStyle}
        onPress={onPress}
        accessibilityRole="button"
        accessibilityLabel={`${accessibilityLabel}, ${selectedOption}`}
        {...{ accessibilityExpanded: expanded }}
      >
        {iconLeft ? (
          <Icon
            name={iconLeft}
            width={CUSTOM_ICON_SIZE}
            height={CUSTOM_ICON_SIZE}
          />
        ) : null}

        <BodyText
          size={4}
          style={styles.triggerText}
          bold={bold ?? !isBoxVariation}
        >
          {selectedOption}
        </BodyText>

        {iconRight ? (
          <Icon
            name={iconRight}
            width={CUSTOM_ICON_SIZE}
            height={CUSTOM_ICON_SIZE}
            color={CUSTOM_ICON_COLOR}
          />
        ) : (
          <CollapseIndicatorIcon expanded={expanded} />
        )}
      </Pressable>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const CUSTOM_ICON_SIZE = 18;
const CUSTOM_ICON_COLOR = theme.colors.CHARCOAL;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  labelWithSpacing: {
    paddingBottom: theme.spacing['2'],
  },
  trigger: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing['4'],
  },
  triggerBox: {
    borderRadius: theme.radius.small,
    borderWidth: 1,
    borderColor: theme.colors.GRAY,
    paddingVertical: 0,
    paddingHorizontal: theme.spacing['2'],
    minHeight: 32,
  },
  triggerText: {
    flex: 1,
  },
});

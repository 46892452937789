import React, { type ComponentProps } from 'react';

import { Button } from '../../../Button';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductCardV2AddToBagButton = (
  props: ComponentProps<typeof Button>,
) => {
  const { children, ...restProps } = props;

  return (
    <Button palette="spinachMuted" size="large" {...restProps}>
      {children}
    </Button>
  );
};

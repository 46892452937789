import React, { useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View, type ViewProps } from 'react-native';
import { theme } from '@garnish/constants';
import {
  BodyText,
  DisplayText,
  TextLinkifyTags,
  useResponsive,
} from '@sg/garnish';

import { LoyaltyIconSgReward } from '../../LoyaltyIconSgReward';

export const LoyaltyHomeFooter = (props: ViewProps) => {
  const { currentBreakpoint, match } = useResponsive();
  const containerStyle = [
    match([styles.containerXS, styles.containerSM]),
    props.style,
  ];

  return (
    <View style={containerStyle}>
      <View style={match([styles.wrapperXs, styles.wrapperSm])}>
        {currentBreakpoint.isXS ? <Terms /> : <RewardsTitle />}

        {currentBreakpoint.isXS ? <RewardsTitle /> : <Terms />}
      </View>
    </View>
  );
};

const RewardsTitle = () => {
  const { match } = useResponsive();

  return (
    <View style={match([styles.rewardsTitleXs, styles.rewardsTitleSm])}>
      <LoyaltyIconSgReward scale={match([2, 3])} palette="white-outline" />

      <DisplayText style={styles.label} sizeMatch={['48', '48']}>
        <FormattedMessage {...messages.rewards} />
      </DisplayText>
    </View>
  );
};

const Terms = () => {
  const { formatMessage } = useIntl();
  const links = useMemo(
    () => [
      {
        tag: 'terms',
        description: formatMessage(messages.termsTag),
        href: 'https://www.sweetgreen.com/sweetpass-program-agreement',
        underline: true,
      },
    ],
    [formatMessage],
  );

  return (
    <BodyText style={styles.label} sizeMatch={['12', '22']}>
      <TextLinkifyTags
        palette="sweet-corn"
        sizeMatch={['12', '22']}
        links={links}
      >
        {formatMessage(messages.terms, { terms: '{terms}' })}
      </TextLinkifyTags>
    </BodyText>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  rewards: {
    defaultMessage: 'rewards',
    description: 'Loyalty Home | Footer | Title',
  },
  termsTag: {
    defaultMessage: 'Terms',
    description: 'Loyalty Home | Footer | subtitle',
  },
  terms: {
    defaultMessage: '*See full {terms} for more details',
    description: 'Loyalty Home | Footer | subtitle',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    flexGrow: 1,
    backgroundColor: theme.colors.FOREST,
    gap: theme.spacing['10'],
    padding: theme.spacing['10'],
  },
  containerSM: {
    flexGrow: 1,
    backgroundColor: theme.colors.FOREST,
    paddingVertical: theme.spacing['10'],
    paddingHorizontal: theme.spacing['30'],
  },
  wrapperXs: {
    marginTop: 'auto',
    alignItems: 'center',
    gap: theme.spacing['6'],
  },
  wrapperSm: {
    marginTop: 'auto',
    alignItems: 'center',
    gap: theme.spacing['12'],
  },
  rewardsTitleXs: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing['8'],
  },
  rewardsTitleSm: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing['12'],
  },
  label: {
    color: theme.colors.CREAM,
  },
});

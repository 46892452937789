import type { ComponentProps } from 'react';
import React from 'react';
import type { ViewProps } from 'react-native';

import { BodyText } from '../Text';
import { getProductIngredientsList } from './ProductIngredientsList.utils';

// ────────────────────────────────────────────────────────────────────────────────

export const ProductIngredientsList = (props: ProductIngredientsListProps) => {
  const {
    addedIngredients = [],
    addedIngredientsLabel = 'Add:',
    addedIngredientsListTestID,
    removedIngredients = [],
    removedIngredientsLabel = 'Remove:',
    removedIngredientsListTestID,
    shouldCapitalizeFirstIngredient,
    sizeMatch,
  } = props;

  return (
    <>
      {addedIngredients.length > 0 ? (
        <IngredientsList
          ingredients={addedIngredients}
          label={addedIngredientsLabel}
          testID={addedIngredientsListTestID}
          shouldCapitalizeFirstIngredient={shouldCapitalizeFirstIngredient}
          sizeMatch={sizeMatch}
        />
      ) : null}

      {removedIngredients.length > 0 ? (
        <IngredientsList
          ingredients={removedIngredients}
          label={removedIngredientsLabel}
          testID={removedIngredientsListTestID}
          shouldCapitalizeFirstIngredient={shouldCapitalizeFirstIngredient}
          sizeMatch={sizeMatch}
        />
      ) : null}
    </>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const IngredientsList = (props: LineItemIngredientsListProps) => {
  const {
    ingredients = [],
    label,
    testID,
    sizeMatch,
    shouldCapitalizeFirstIngredient,
  } = props;

  if (ingredients.length === 0) return null;

  const formattedIngredients = getProductIngredientsList(
    ingredients,
    shouldCapitalizeFirstIngredient,
  );

  if (label) {
    return (
      <BodyText sizeMatch={sizeMatch} testID={testID}>
        {label} {formattedIngredients}
      </BodyText>
    );
  }

  return (
    <BodyText sizeMatch={sizeMatch} testID={testID}>
      {formattedIngredients}
    </BodyText>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ProductIngredientsListProps = Readonly<{
  addedIngredients?: Ingredients;
  addedIngredientsLabel?: string;
  addedIngredientsListTestID?: ViewProps['testID'];
  removedIngredients?: Ingredients;
  removedIngredientsLabel?: string;
  removedIngredientsListTestID?: ViewProps['testID'];
  shouldCapitalizeFirstIngredient?: boolean;
  sizeMatch?: ComponentProps<typeof BodyText>['sizeMatch'];
}>;

type LineItemIngredientsListProps = Readonly<{
  label: string;
  ingredients: Ingredients;
  testID?: ViewProps['testID'];
  shouldCapitalizeFirstIngredient?: boolean;
  sizeMatch?: ComponentProps<typeof BodyText>['sizeMatch'];
}>;

type Ingredients = ReadonlyArray<
  Readonly<{
    name?: string;
  }>
>;

import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { LoadingPlaceholder, theme } from '@sg/garnish';

/**
 * Components for the account skeleton loading states.
 */

export const SkeletonLoadingHeaderXS = (props: SkeletonLoadingProps) => {
  return (
    <LoadingPlaceholder
      style={styles.headerSpacingXS}
      rowHeight={baseHeight}
      palette="quinoa"
      {...props}
    />
  );
};

export const SkeletonLoadingContent = (props: SkeletonLoadingProps) => {
  return <LoadingPlaceholder palette="quinoa" {...props} />;
};

export const SkeletonLoadingContentSM = (props: SkeletonLoadingProps) => {
  return (
    <LoadingPlaceholder
      style={styles.contentSpacing}
      rowHeight={contentHeightSM}
      palette="quinoa"
      {...props}
    />
  );
};

export const SkeletonLoadingContentLG = (props: SkeletonLoadingProps) => {
  return (
    <LoadingPlaceholder
      style={styles.contentSpacing}
      rowHeight={contentHeightLG}
      palette="quinoa"
      {...props}
    />
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const baseHeight = 32;
const contentHeightSM = baseHeight * 2;
const contentHeightLG = baseHeight * 4;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  headerSpacingXS: {
    marginVertical: theme.spacing['2'],
  },
  contentSpacing: {
    marginBottom: theme.spacing['2'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type SkeletonLoadingProps = ComponentProps<typeof LoadingPlaceholder>;

import React from 'react';
import { StyleSheet } from 'react-native';

import { BodyText as Text } from '../../Text';
import type { TextParentProps } from '../Button.types';
import { useTextStyles } from '../styles';

export const TextParent = (props: TextParentProps) => {
  const { children, palette, size, hasDisabledStyle, uiEventState } = props;
  const useStyleState = useTextStyles(palette, size);
  const styledState = useStyleState(hasDisabledStyle, uiEventState);

  return <Text style={[styles.buttonText, styledState]}>{children}</Text>;
};

const styles = StyleSheet.create({
  buttonText: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
});

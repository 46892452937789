import { useCallback } from 'react';
import { useClient } from 'urql';

import { type Order } from '@order/graphql';

import {
  BagCartDocument,
  type BagCartQuery,
} from '../../GraphQL/BagCart.generated';
import { prepareCart } from '../../utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A hook for fetching the customer's cart data.
 */
export const useCart = () => {
  const client = useClient();

  // ─── Queries ──────────────────────────────────────────────────────────────

  const cartQuery = useCallback(async () => {
    const queryCart = client.query<BagCartQuery>;

    return queryCart(
      BagCartDocument,
      {},
      { requestPolicy: 'network-only' },
    ).toPromise();
  }, [client.query]);

  // ─── Helpers ──────────────────────────────────────────────────────────────

  const fetchCart = useCallback(async () => {
    const response = await cartQuery();
    const cart = response.data?.cart ?? {};

    return prepareCart(cart as Partial<Order>);
  }, [cartQuery]);

  // ──────────────────────────────────────────────────────────────────────────

  return { fetchCart };
};

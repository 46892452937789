/* eslint-disable functional/immutable-data */

import type { Cache } from '@urql/exchange-graphcache';

import type {
  AddAddressInput,
  AddAddressMutation,
  AddAddressSuccess,
  Address,
  Customer,
  DeleteAddressMutation,
  DeleteAddressSuccess,
  UpdateAddressMutation,
  UpdateAddressSuccess,
} from '@order/graphql';
import { CurrentCustomerDocument } from '@order/graphql';

/**
 * Changing addresses returns an address object.
 * This is fine for when the address is updated,
 * But if it is added or removed, this is needed to update
 * the current customer's object's addresses in URQL cache.
 *
 * Additionally, when we add an address without a name,
 * it's a different operation under the same mutation,
 * as the resulting address is provisional and
 * won't be available in `customer.addresses`.
 *
 * Finally, the `updateAddress` can be called with a
 * provisional address, so it must also add the address
 * to the `customer.addresses` cache if it has a name.
 */

export const addAddress = (
  result: AddAddressMutation,
  _args: AddAddressInput,
  cache: Cache,
) => {
  const response = result.addAddress;
  const data = response as AddAddressSuccess;
  const wasSuccess = response.__typename === 'AddAddressSuccess';
  const isProvisionalAddress = !data?.address?.name;

  // Ignore unsuccessful mutations or provisional addresses.
  if (!wasSuccess || isProvisionalAddress) return;

  updateCacheWithAddressChanges(cache, data);
};

export const updateAddress = (
  result: UpdateAddressMutation,
  _args: AddAddressInput,
  cache: Cache,
) => {
  const response = result.updateAddress;
  const data = response as UpdateAddressSuccess;
  const wasSuccess = response.__typename === 'UpdateAddressSuccess';
  const isProvisionalAddress = !data?.address?.name;

  // Ignore unsuccessful mutations or provisional addresses.
  if (!wasSuccess || isProvisionalAddress) return;

  updateCacheWithAddressChanges(cache, data);
};

export const deleteAddress = (
  result: DeleteAddressMutation,
  args: Readonly<{ addressId: string }>,
  cache: Cache,
) => {
  const deletedId = args?.addressId;
  const data = result.deleteAddress as DeleteAddressSuccess;

  // Ignore unsuccessful mutations.
  if (!deletedId || !data?.success) return;

  updateCacheWithAddressChanges(cache, {
    address: { id: String(deletedId) },
    removed: true,
  });
};

const updateCacheWithAddressChanges = (
  customerCache: Cache,
  addressChanges: AddressChanges,
) => {
  customerCache.updateQuery({ query: CurrentCustomerDocument }, (data) => {
    if (!data) return data;

    // Get cached addresses.
    const cachedCustomer = data?.currentCustomer as Customer;
    const addresses = cachedCustomer?.addresses ?? [];
    const withoutUpdated = addresses.filter(
      ({ id }) => id !== addressChanges.address.id,
    );

    // If the address was removed, remove it from the cache.
    if (addressChanges.removed) {
      data.currentCustomer = {
        ...cachedCustomer,
        addresses: withoutUpdated,
      };

      return data;
    }

    // If the address was added, add it and sort by id.
    data.currentCustomer = {
      ...cachedCustomer,
      addresses: [...withoutUpdated, addressChanges.address as Address].sort(
        (c1, c2) => c1.id.localeCompare(c2.id),
      ),
    };

    return data;
  });
};

type AddressChanges = Readonly<{
  address: Partial<Address>;
  removed?: boolean;
}>;

import React from 'react';
import type { TextProps } from 'react-native';
import { StyleSheet, TouchableHighlight, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import { Icon, type IconName } from '../Icon';
import { NavbarLinkBadge } from '../NavbarLinkBadge';
import { BodyText } from '../Text';

export const MainTabBarItem = (props: MainTabBarItemProps) => {
  const {
    testID,
    iconName,
    title,
    accessibilityLabel,
    accessibilityHint,
    isActive,
    hasBadge,
    maxFontSizeMultiplier,
    onPress,
    onLongPress,
  } = props;

  const { bottom: safeAreBottomInset } = useSafeAreaInsets();
  const hasIcon = iconName !== undefined;

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerDynamicStyles = useStyle(() => {
    // NOTE: Because the safe area bottom inset may be too big, we reduce it
    //       to avoid taking up too much extra space.
    const paddingBottom =
      safeAreBottomInset > CONTAINER_PADDING_BOTTOM
        ? safeAreBottomInset - 10
        : CONTAINER_PADDING_BOTTOM;

    return { paddingBottom };
  }, [safeAreBottomInset]);

  const containerStyle = [styles.container, containerDynamicStyles];
  const labelStyles = [
    styles.label,
    hasIcon ? styles.labelWithIcon : styles.labelWithoutIcon,
    isActive ? styles.labelSelected : undefined,
  ];

  const iconColor = isActive ? theme.colors.KALE : theme.colors.CHARCOAL;
  const indicatorStyles = [
    styles.indicator,
    isActive && styles.indicatorSelected,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <TouchableHighlight
      testID={testID}
      accessibilityLabel={accessibilityLabel ?? title}
      accessibilityHint={accessibilityHint}
      accessibilityRole="tab"
      accessibilityState={{ selected: isActive }}
      onPress={onPress}
      onLongPress={onLongPress}
      style={containerStyle}
      underlayColor={theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT}
    >
      <View style={styles.innerContainer}>
        {hasIcon ? (
          <View style={styles.iconContainer}>
            <Icon name={iconName} color={iconColor} strokeWidth={1.5} />
          </View>
        ) : null}

        <View>
          <BodyText
            sizeMatch={['12']}
            style={labelStyles}
            maxFontSizeMultiplier={maxFontSizeMultiplier}
          >
            {title}
          </BodyText>

          {hasBadge ? (
            <NavbarLinkBadge
              badgeType="mobile"
              testID={testID?.concat('.badge')}
            />
          ) : null}
        </View>

        <View style={indicatorStyles} />
      </View>
    </TouchableHighlight>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const CONTAINER_PADDING_BOTTOM = theme.spacing['3'];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingTop: theme.spacing['3'],
    paddingHorizontal: 2,
  },

  innerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing['1'],
  },

  iconContainer: {
    marginBottom: theme.spacing['1'],
  },

  label: {
    textAlign: 'center',
    paddingHorizontal: theme.spacing['2'],
    color: theme.colors.CHARCOAL,
  },
  labelWithIcon: {
    marginBottom: theme.spacing['1'],
  },
  labelWithoutIcon: {
    marginVertical: theme.spacing['2'],
  },
  labelSelected: {
    color: theme.colors.KALE,
  },

  indicator: {
    width: theme.spacing['6'],
    height: 1,
  },
  indicatorSelected: {
    backgroundColor: theme.colors.KALE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MainTabBarItemProps = Readonly<{
  testID?: string;
  iconName?: IconName;
  title: string;
  accessibilityLabel?: string;
  accessibilityHint?: string;
  isActive: boolean;
  hasBadge: boolean;
  onPress: () => void;
  onLongPress: () => void;
  maxFontSizeMultiplier?: TextProps['maxFontSizeMultiplier'];
}>;

import { useCallback } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { type RequestPolicy } from '@urql/core';

import { useIsLoggedIn } from '@order/AuthMachine';

import {
  type CustomerCreditsQuery,
  useCustomerCreditsQuery,
} from './GraphQL/Credit.generated';

/**
 * Some information on credit use throughout the app.
 *
 * availableCredit: Amount of credit that is available.
 * checkoutCredit: Amount of credit applied to checkout.
 * useCredit: Whether credit should apply to checkout.
 * credits: Itemized list of operations that form the total credit amount.
 */
export const useCustomerCredit = (options?: UseCustomerCreditOptions) => {
  const { requestPolicy = 'network-only', shouldRefetchOnFocus = false } =
    options ?? {};

  const isScreenFocused = useIsFocused();
  const isLoggedIn = useIsLoggedIn();

  // ─────────────────────────────────────────────────────────────────────

  const [response, refetchCustomerCredits] = useCustomerCreditsQuery({
    requestPolicy,
    pause: !isLoggedIn || (shouldRefetchOnFocus && !isScreenFocused),
  });

  // ─── Helpers ─────────────────────────────────────────────────────────

  const refetchCredits = useCallback(() => {
    if (!isLoggedIn) return;

    refetchCustomerCredits();
  }, [isLoggedIn, refetchCustomerCredits]);

  // ─────────────────────────────────────────────────────────────────────

  const customer = response?.data?.currentCustomer;
  const { availableCredit, useCredit, credits } = customer ?? defaults;

  const isLoadingCredits = response.fetching || response.stale;

  // ─────────────────────────────────────────────────────────────────────

  return {
    availableCredit,
    useCredit,
    credits,
    isLoadingCredits,
    refetchCredits,
  };
};

// ─── Defaults ────────────────────────────────────────────────────────────────

const defaults = {
  credits: [] as Credits,
  availableCredit: 0,
  useCredit: false,
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseCustomerCreditOptions = Readonly<{
  requestPolicy?: RequestPolicy;
  shouldRefetchOnFocus?: boolean;
}>;

type Credits = NonNullable<
  NonNullable<CustomerCreditsQuery['currentCustomer']>['credits']
>;

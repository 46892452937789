import React, { useCallback } from 'react';
import { isBoolean } from 'lodash';

import { type useLaunchDarkly } from '@order/LaunchDarkly';

import { FeatureFlagEditButton, FeatureFlagToggleButton } from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const FeatureFlag = (props: FeatureFlagProps) => {
  const {
    flagName,
    flagValue,
    isOverwritten,
    updateFeatureFlag,
    editFeatureFlag,
  } = props;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnChange = useCallback(
    (value: boolean) => {
      updateFeatureFlag(flagName, value);
    },
    [flagName, updateFeatureFlag],
  );

  const handleOnEdit = useCallback(() => {
    editFeatureFlag(flagName);
  }, [editFeatureFlag, flagName]);

  // ─────────────────────────────────────────────────────────────────────

  if (!isBoolean(flagValue)) {
    return (
      <FeatureFlagEditButton
        name={flagName}
        isOverwritten={isOverwritten}
        onPress={handleOnEdit}
      />
    );
  }

  return (
    <FeatureFlagToggleButton
      name={flagName}
      value={flagValue}
      isOverwritten={isOverwritten}
      onChange={handleOnChange}
    />
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type FeatureFlagKey = keyof ReturnType<
  typeof useLaunchDarkly
>['initialFeatureFlags'];

type FeatureFlagProps = {
  flagName: FeatureFlagKey;
  flagValue?: unknown;
  isOverwritten: boolean;
  updateFeatureFlag: (flagName: FeatureFlagKey, flagValue: boolean) => void;
  editFeatureFlag: (flagName: FeatureFlagKey) => void;
};

import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme, TYPE_CONFIG } from '@garnish/constants';

import { BodyText, HighlightWords } from '../../../Text';
import { UpsellCtaHexagonIcon } from '../UpsellCtaHexagonIcon';

// ─────────────────────────────────────────────────────────────────────────────

export const UpsellCtaContent = (props: UpsellCtaContentProps) => {
  const { text, textHighlights, actionText, iconText } = props;

  return (
    <>
      <View style={styles.textContentContainer} pointerEvents="none">
        {text ? (
          <HighlightWords
            style={styles.text}
            text={text}
            highlights={textHighlights}
          />
        ) : null}

        {actionText ? (
          <BodyText underline style={[styles.text, styles.textLink]}>
            {actionText}
          </BodyText>
        ) : null}
      </View>

      {iconText ? (
        <View style={styles.iconContainer} pointerEvents="none">
          <UpsellCtaHexagonIcon />

          <View style={styles.iconTextContainer}>
            <BodyText style={styles.iconText}>{iconText}</BodyText>
          </View>
        </View>
      ) : null}
    </>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { fontWeight, ...TEXT_STYLES } = TYPE_CONFIG.BODY['14'];
const ICON_TEXT_STYLES = TYPE_CONFIG.BODY['18'];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  textContentContainer: {
    flex: 1,
  },
  text: {
    ...TEXT_STYLES,
    color: theme.colors.KALE,
  },
  textLink: {
    marginTop: theme.spacing['2'],
  },
  iconContainer: {
    marginLeft: theme.spacing['4'],
  },
  iconTextContainer: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconText: {
    ...ICON_TEXT_STYLES,
    color: theme.colors.KALE,
    textAlign: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type UpsellCtaContentProps = Readonly<{
  text: string;
  textHighlights?: ComponentProps<typeof HighlightWords>['highlights'];
  iconText?: string;
  actionText?: string;
}>;

/**
 * Actions for the "Action Rails" action cards.
 *
 * explore_menu       | opens the last order's menu
 * upgrade_sweetpass  | takes you to upgrade screen
 * explore_benefits   | takes you to sweetpass benefits screen
 * explore_challenges | scrolls you down to challenges section
 * explore_rewards    | scrolls you down to rewards section
 */

export const SupportedSweetpassHomeActions = {
  UpgradeSweetpass: 'upgrade_sweetpass',
  ExploreMenu: 'explore_menu',
  ExploreBenefits: 'explore_benefits',
  ExploreChallenges: 'explore_challenges',
  ExploreRewards: 'explore_rewards',
};

export function isSweetpassHomeActionSupported(action?: string) {
  return Boolean(
    action && Object.values(SupportedSweetpassHomeActions).includes(action),
  );
}

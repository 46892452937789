import { theme } from '@garnish/constants';

import type {
  ColoringConfigMap,
  UseChallengeBackgroundColor,
} from './ChallengeCard.types';

const coloringMap: ColoringConfigMap = {
  New: {
    backgroundColor: theme.colors.QUINOA,
  },
  Completed: {
    backgroundColor: theme.colors.OATMEAL,
    borderColor: theme.colors.GRAY,
  },
  Ongoing: {
    backgroundColor: theme.colors.CUCUMBER,
  },
};

export const useChallengeCardVariantStyles = ({
  variant,
}: UseChallengeBackgroundColor) => {
  return coloringMap[variant];
};

import { defineMessages } from 'react-intl';

export const giftCardCheckoutDeliveryDateMessages = defineMessages({
  sectionTitle: {
    defaultMessage: 'When would you like to send it?',
    description: 'Gift Card Checkout Form | Delivery Options | Section title',
  },
  nowOptionLabel: {
    defaultMessage: 'Now',
    description: 'Gift Card Checkout Form | Delivery Date | "Now" option label',
  },
  scheduleOptionLabel: {
    defaultMessage: 'Schedule',
    description:
      'Gift Card Checkout Form | Delivery Date | "Schedule" option label',
  },
  deliveryDayLabel: {
    defaultMessage: 'Day',
    description: 'Gift Card Checkout Form | Delivery Date | "Day" field label',
  },
  deliveryTimeLabel: {
    defaultMessage: 'Time',
    description: 'Gift Card Checkout Form | Delivery Date | "Time" field label',
  },
  deliveryDateAndTimeLabel: {
    defaultMessage: 'Date and time',
    description:
      'Gift Card Checkout Form | Delivery Date | "Date and time" field label',
  },
  todayLabel: {
    defaultMessage: 'Today',
    description: 'Gift Card Checkout Form | Delivery Date | Today option label',
  },
  changeDeliveryDateA11yLabel: {
    defaultMessage: 'Change delivery date. Selected date: {date}',
    description:
      'Gift Card Checkout Form | Delivery Date | "Change delivery date" a11y label',
  },
  changeDeliveryDateA11yLabelGeneric: {
    defaultMessage: 'Change delivery date',
    description:
      'Gift Card Checkout Form | Delivery Date | "Change delivery date" generic a11y label',
  },
});

import React, { type ComponentProps, forwardRef } from 'react';
import { type StyleProp, StyleSheet, View, type ViewStyle } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { type ImageBackground } from 'expo-image';

import { AspectRatioImageBackground } from './components';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders an image component (+ its containers) with aspect ratio support.
 *
 * NOTE: On the web, we use the "padded-box" technique because the `aspect-ratio`
 *       CSS attribute is not yet supported everywhere as a moment of writing.
 *
 * @see {@link https://css-tricks.com/aspect-ratio-boxes/}
 */
export const AspectRatioImage = forwardRef<View, AspectRatioImageProps>(
  (props, ref) => {
    const {
      aspectRatioY,
      aspectRatioX,
      minHeight,
      containerStyle,
      borderRadius = 0,
      ...imgProps
    } = props;

    // ─── Styles ──────────────────────────────────────────────────────────

    const containerDynamicBorderRadiusStyle = useStyle(
      () => ({ borderRadius }),
      [borderRadius],
    );
    const containerDynamicStyles = useStyle(
      () => ({
        paddingTop: `${(aspectRatioX / aspectRatioY) * 100}%`,
        minHeight,
      }),
      [aspectRatioY, aspectRatioX, minHeight],
    );

    const containerStyles = [
      styles.container,
      containerDynamicStyles,
      containerDynamicBorderRadiusStyle,
      containerStyle,
    ];

    // ─────────────────────────────────────────────────────────────────────

    return (
      <View ref={ref} style={containerStyles}>
        <View style={styles.innerContainer}>
          <AspectRatioImageBackground {...imgProps} />
        </View>
      </View>
    );
  },
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    width: '100%',
    height: 0,
  },
  innerContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type AspectRatioImageProps = {
  aspectRatioY: number;
  aspectRatioX: number;
  minHeight?: number;
  containerStyle?: StyleProp<ViewStyle>;
  borderRadius?: number;
} & ComponentProps<typeof ImageBackground>;

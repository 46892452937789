// @ts-expect-error TS(2307): Cannot find module '@urql/core/dist/types/types' o... Remove this comment to see the full error message
import type { RequestPolicy } from '@urql/core/dist/types/types';
import type { Client } from 'urql';

import type { CustomerQuery } from '../../graphql/customer.generated';
import { CustomerDocument } from '../../graphql/customer.generated';

// ─────────────────────────────────────────────────────────────────────────────

export async function customerQuery(
  client: Client,
  requestPolicy: RequestPolicy = 'cache-and-network',
) {
  return client
    .query<CustomerQuery>(CustomerDocument, {}, { requestPolicy })
    .toPromise();
}

import type { ComponentProps } from 'react';
import React from 'react';
import {
  BREAKPOINTS,
  DisplayText,
  TYPE_CONFIG,
  useFluidSize,
} from '@sg/garnish';

export const GreetingText = React.memo((props: GreetingTextProps) => {
  const { children, style, ...rest } = props;

  const fluid = useFluidSize(getLockMinMax(BREAKPOINTS));

  const fluidStyles = {
    fontSize: fluid(TYPE_CONFIG.DISPLAY['32'].fontSize ?? 32, 56),
    lineHeight: fluid(TYPE_CONFIG.DISPLAY['32'].lineHeight ?? 36, 56),
    letterSpacing: fluid(0, 1),
  };

  const styles = [fluidStyles, style];

  return (
    <DisplayText
      accessibilityRole="header"
      // @ts-expect-error TS(2322): Type '{ children: ReactText & ReactNode; bold?: bo... Remove this comment to see the full error message
      accessibilityLevel={1}
      style={styles}
      maxFontSizeMultiplier={1.1}
      {...rest}
    >
      {children}
    </DisplayText>
  );
});

// ─── Utils ────────────────────────────────────────────────────────

const getLockMinMax = (breakpoints: typeof BREAKPOINTS) => {
  const minBreakpoint = breakpoints[0];
  const maxBreakpoint = breakpoints[3];

  return { min: minBreakpoint.MAX, max: maxBreakpoint.BODY as number };
};

// ─── Types ────────────────────────────────────────────────────────

type GreetingTextProps = Readonly<{
  children: React.ReactText;
}> &
  ComponentProps<typeof DisplayText>;

export const sweetpass = {
  sweetpass: 'Sweetpass',
  'sweetpass-plus': 'Sweetpass+',
  'sweetpass-plus-trial': 'Sweetpass+ trial',
  'sweetpass-opted-out': 'Sweetpass (opted-out)',
  'sweetpass-change-plan': 'Change Plan',

  // ─── Signed Out ──────────────────────────────────────────────────────

  'sweetpass.sign-out.text':
    'You must sign in to your account to see this page.',
  'sweetpass.sign-out.sign-in': 'Sign in',
  'sweetpass.sign-out.not-member': "Don't have an account?",
  'sweetpass.sign-out.join-now': 'Join now',

  // ─── Signed In ───────────────────────────────────────────────────────

  'sweetpass.signed-in.sweetpass+.text': 'Review and purchase Sweetpass+',
  'sweetpass.signed-in.payment-method': '{type} ending in {lastFour}',
  'sweetpass.signed-in.add-payment-method': 'Add payment method',
  'sweetpass.signed-in.add-payment-method-title': 'Add card for Sweetpass+',
  'sweetpass.signed-in.edit-payment-method-title': 'Edit card for Sweetpass+',
  'sweetpass.signed-in.credit-card': 'Credit or debit card',
  'sweetpass.signed-in.paypal': 'PayPal',
  'sweetpass.signed-in.apple-pay': 'Apple Pay',
  'sweetpass.signed-in.venmo': 'Venmo',

  // ─── Join ────────────────────────────────────────────────────────────

  'sweetpass-join.title': 'Join sweetpass',

  // ─── Celebration ─────────────────────────────────────────────────────

  'sweetpass-celebration.a11y': 'Sweetpass+ Celebration',
  'sweetpass-celebration.title': 'Welcome to\nSweetpass+',
  'sweetpass-celebration.subtitle': 'Ready to watch\nyour\ngreens\ngrow?',
  'sweetpass-celebration.button-text': 'Explore Sweetpass+',

  // ─── Upgrade ─────────────────────────────────────────────────────────

  'sweetpass-upgrade.title': 'Upgrade to Sweetpass+',
  'sweetpass-upgrade.confirm-modal.title': 'Review and join Sweetpass',
  'sweetpass-upgrade.confirm-modal.button': 'Join Sweetpass',
  'sweetpass-upgrade.confirm-modal':
    'By joining Sweetpass you agree to {terms}. Opt out at any time by reaching out to our CX team at {email}.',
  'sweetpass-upgrade.confirm-modal.terms': 'sweetgreen’s terms and conditions',
  'sweetpass-upgrade.confirm-modal.terms-url':
    'https://www.sweetgreen.com/terms',
  'sweetpass-upgrade.confirm-modal.email': 'hello@sweetgreen.com',
  'sweetpass-upgrade.confirm-modal.email-url': 'mailto:hello@sweetgreen.com',
  'sweetpass-free-trial-not-eligible-title':
    'The free trial promotion is not available for your account.',
  'sweetpass-free-trial-not-eligible-body':
    'We appreciate your excitement about Sweetpass+. {learn_more} about the premium perks or return home.',
  'sweetpass-free-trial-not-eligible-learn-more': 'Learn more',

  // ─── Checkout ────────────────────────────────────────────────────────

  'sweetpass.checkout.membership-plan': 'Membership plan',
  'sweetpass.checkout.free-trial': 'Free trial ',
  'sweetpass.checkout.membership-price': '${planPrice}/{billingFrequencyUnit}',
  'sweetpass.checkout.credit-card.description':
    '{cardType} ending in {lastFour}',
  'sweetpass.checkout.credit-card.new': 'Add payment method',
  'sweetpass.checkout.credit-card.replace': 'Replace',
  'sweetpass.checkout.upgrade-cta-notice':
    'By clicking “Upgrade to Sweetpass+”, you also agree to our {terms}.',
  'sweetpass.checkout.upgrade-cta-notice.terms-description':
    'terms and conditions',
  'sweetpass.checkout.terms.sweetpass-program-agreement':
    'Sweetpass Program Agreement',
  'sweetpass.checkout.billing-frequency.membership-plan-name': `{billingFrequency, select,
    day {daily}
    month {monthly}
    week {weekly}
    year {annual}
    other {}
  }`,

  // ─── Change Subscription ─────────────────────────────────────────────────────

  'sweetpass.change-subscription.title': 'Change plan',

  'sweetpass.change-subscription.change-date':
    'Your plan will switch on {nextBillingDate}.',
  'sweetpass.change-subscription.confirm': 'Confirm',
  'sweetpass.change-subscription.confirm.a11y': 'Confirm changing subscription',
  'sweetpass.change-subscription.terms-fallback':
    'By checking this box and clicking “Confirm”, you agree to our Sweetpass Program Agreement and authorize sweetgreen to charge your designated payment method {price} plus taxes on {nextBillingDate} and every {billingFrequency} after until you timely cancel. Cancel in the “Sweetpass membership” section of your Account tab or by emailing hello@sweetgreen.com up to 48 hours before your membership is set to renew for another month.',

  // ─── Keep Subscription ─────────────────────────────────────────────────────

  'sweetpass.keep-subscription.title': 'Keep {changeBack} plan',
  'sweetpass.keep-subscription.body':
    'When you click “Confirm”, your membership plan change to {changeTo} will be canceled and your {changeBack} plan will resume.',

  // ─── Payment Methods ─────────────────────────────────────────────────

  'sweetpass.credit-card-form.edit': 'Edit card',
  'sweetpass.credit-card-form.add': 'Add card',

  // ─── Home Page ───────────────────────────────────────────────────────

  'sweetpass.home.header-name': '{firstName}’s ',
  'sweetpass.home.header-membership': '{membership}',
  'sweetpass.home.header-tier-illustration': '"{tierName}" tier illustration',
  'sweetpass.home.stats.member-since': 'Member since {sweetpass_join_date}',
  'sweetpass.home.stats.detailed.saved': '{amount} saved',
  'sweetpass.home.stats.reward': 'Reward\navailable',
  'sweetpass.home.stats.rewards': 'Rewards\navailable',
  'sweetpass.home.stats.challenge': 'Challenge\navailable',
  'sweetpass.home.stats.challenges': 'Challenges\navailable',
  'sweetpass.home.stats.cta': 'Explore your benefits',
  'sweetpass.home.rewards-rail.deadline-prefix': 'Use by',
  'sweetpass.home.challenges-rail.deadline-prefix': 'Ends',
  'sweetpass.home.challenges-rail.completion-text': 'Challenge completed',
  'sweetpass.home.challenges-rail.badge.new': 'New',
  'sweetpass.home.challenges-rail.badge.flash': 'Flash',

  // ─── Home Page | Signed out ──────────────────────────────────────────

  'sweetpass.home.signed-out.header.title': 'A delicious life\nbegins here',
  'sweetpass.home.signed-out.header.text':
    'Join Sweetpass—our new, free loyalty program. Explore the 2023 benefits.',
  'sweetpass.home.signed-out.cta.label': 'Create an account to join Sweetpass',

  'sweetpass.home.signed-out.benefits.rewards.title': 'Rewards and Challenges',
  'sweetpass.home.signed-out.benefits.rewards.text':
    'Opt in and complete delicious challenges to savor more rewards.',

  'sweetpass.home.signed-out.benefits.birthday-gift.title': 'Birthday\nGift',
  'sweetpass.home.signed-out.benefits.birthday-gift.text':
    'Celebrate your special day with something sweet from us.',

  'sweetpass.home.signed-out.benefits.bowl-drops.title': 'Sweetpass Bowl Drops',
  'sweetpass.home.signed-out.benefits.bowl-drops.text':
    'Get special access to online-only menu drops exclusive to Sweetpass members.',

  'sweetpass.home.signed-out.benefits.merch-drops.title':
    'Merch Drops (Coming Soon)',
  'sweetpass.home.signed-out.benefits.merch-drops.text':
    'Get first access to limited-edition sweetgreen merch.',

  // ─── Upsell CTA ──────────────────────────────────────────────────────

  'sweetpass.upsell.cta.fallback.text': 'Save $3 on this order with Sweetpass+',
  'sweetpass.upsell.cta.fallback.text-highlight': '$3',
  'sweetpass.upsell.cta.fallback.action-text': 'Upgrade now',
  'sweetpass.upsell.cta.fallback.icon-text': '$3',
  'sweetpass.upsell.cta.fallback.a11y-label': 'Upgrade to Sweetpass+',

  // ─── Checkout Fallback ───────────────────────────────────────────────

  'sweetpass-checkout-contentful-fallback-name': 'Sweetpass Plus Checkout Page',
  'sweetpass-checkout-contentful-fallback-title':
    'Review and purchase Sweetpass+',
  'sweetpass-checkout-contentful-fallback-termsAndConditionsV3':
    'By checking this box and clicking “{cta}”, you authorize sweetgreen to automatically charge your designated payment method {price} plus applicable taxes today and in advance for each {billingFrequencyMembershipName} membership period and agree that your membership will continue until you timely cancel.\n' +
    '\n' +
    'To avoid being charged for another {billingFrequencyUnit} of membership, you must cancel at least 48 hours before your membership is set to renew in the “Sweetpass membership” section of your Account tab or by emailing hello@sweetgreen.com. When you cancel, you will not receive a refund but can continue to use membership benefits until the end of the current membership period.',
  'sweetpass-checkout-contentful-fallback-sweetpassProgramAgreementUrlDisplayName':
    'Sweetpass Program Agreement',
  'sweetpass-checkout-contentful-fallback-sweetpassProgramAgreementUrl':
    'https://www.sweetgreen.com/sweetpass-program-agreement',
  'sweetpass-checkout-contentful-fallback-agreementText':
    'By clicking “{cta}” below, you agree to the Sweetpass Program Agreement and authorize sweetgreen to charge your designated payment method {price} plus applicable taxes for your first {billingFrequencyMembershipName} membership period.',
  'sweetpass-checkout-contentful-fallback-cta': 'Agree and purchase',
  'sweetpass-checkout-contentful-fallback-freeTrialTitle':
    'Review and start Sweetpass+ free trial',
  'sweetpass-checkout-contentful-fallback-freeTrialTermsAndConditionsV3':
    'By checking this box and clicking “{cta}”, you authorize sweetgreen to automatically charge your designated payment method {price} plus applicable taxes at the end of your trial and in advance for each {billingFrequencyMembershipName} membership period thereafter, and agree that your membership will continue until you timely cancel.\n' +
    '\n' +
    'To avoid being charged at the end of your trial, or for another {billingFrequencyUnit} of membership, you must cancel at least 48 hours before your trial ends or your membership is set to renew in the “Sweetpass membership” section of your Account tab or by emailing hello@sweetgreen.com. If you cancel during your free trial, you will lose your membership benefits at the time of cancellation. If you cancel after your free trial, you will not receive a refund but can continue to use membership benefits until the end of the current membership period.',
  'sweetpass-checkout-contentful-fallback-freeTrialAgreementText':
    'By clicking “{cta}” below, you agree to the Sweetpass Program Agreement.',
  'sweetpass-checkout-contentful-fallback-freeTrialCta':
    'Agree and start trial',
  'sweetpass-checkout-contentful-fallback-freeTrialNote':
    'First {trialDuration} free',
  'sweetpass-checkout-contentful-fallback-planSwitchTitle': 'Change plan',
  'sweetpass-checkout-contentful-fallback-planSwitchCta': 'Agree and continue',
  'sweetpass-checkout-contentful-fallback-planSwitchTermsAndConditions': `By checking this box and clicking “Agree and confirm”, you authorize sweetgreen to automatically charge your designated payment method {price} plus applicable taxes on {nextBillingDate} and in advance for each monthly membership period and agree that your membership will continue until you timely cancel.

  To avoid being charged for another month of membership, you must cancel at least 48 hours before your membership is set to renew in the “Sweetpass membership” section of your Account tab or by emailing hello@sweetgreen.com. When you cancel, you will not receive a refund but can continue to use membership benefits until the end of the current membership period.`,
  'sweetpass-checkout-contentful-fallback-subscriptionSwitchSuccessBanner':
    'Your membership plan is scheduled to change to {requestedPlanRecurrence} on {switchDate}',

  // ─── Sweetpass Errors ────────────────────────────────────────────────

  'sweetpass.checkout.join-sweetpass-plus.notice.failure':
    'Sorry something went wrong with creating your subscription, please try again in a few minutes',
  'sweetpass.payment-method.invalid-payment-method':
    'We were unable to add your credit card. Please check your card information and try again.',
  'sweetpass.payment-method.generic-payment-method-error':
    'We were unable to save your card information. Please try again.',
  'sweetpass.payment-method.duplicate':
    "We couldn't add your card because that card already exists in your account",
  'sweetpass.subscription.cannot-reactivate':
    'This subscription cannot be rejoined because it is already canceled',
};

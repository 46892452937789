import React, { type ComponentProps } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View, type ViewProps } from 'react-native';
import { theme, TYPE_CONFIG } from '@garnish/constants';
import {
  Container as GarnishContainer,
  HorizontalScrollRail,
  useContainerSpacing,
  useResponsive,
  VStack,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

const Container = (props: ViewProps) => {
  const { match, minWidth } = useResponsive();

  const containerStyle = [
    match([styles.containerXS, styles.containerSM]),
    minWidth.isLG && styles.containerStaticSpacingLG,
    props.style,
  ];

  if (minWidth.isLG) {
    return <View style={containerStyle}>{props.children}</View>;
  }

  return (
    <GarnishContainer style={containerStyle}>{props.children}</GarnishContainer>
  );
};

const Rail = (props: RailProps) => {
  const { currentBreakpoint, match } = useResponsive();
  const containerSpacing = useContainerSpacing();
  const { formatMessage } = useIntl();

  if (currentBreakpoint.isXS) {
    return <VStack>{props.children}</VStack>;
  }

  return (
    <HorizontalScrollRail
      showNavControls={true}
      withoutHeaderBorder
      heading={formatMessage(messages.offers)}
      headerBottomSpacing={theme.spacing['6']}
      headerVariation="display"
      gap={theme.spacing['4']}
      outerOffset={
        -match([
          containerSpacing,
          containerSpacing,
          containerSpacing,
          RAIL_SPACING_LG,
        ])
      }
      itemVisiblePercentThreshold={99}
      headerPalette="light"
      titleStyle={styles.title}
    >
      {props.children}
    </HorizontalScrollRail>
  );
};

export const LoyaltyHomeChallenges = {
  Container,
  Rail,
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  offers: {
    defaultMessage: 'Offers',
    description: 'Loyalty Home | Offers | Title',
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const RAIL_SPACING_LG = theme.spacing['30'];

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    backgroundColor: theme.colors.FOREST,
    paddingVertical: theme.spacing['10'],
  },
  containerSM: {
    backgroundColor: theme.colors.FOREST,
    paddingVertical: theme.spacing['10'],
    gap: theme.spacing['6'],
  },
  containerStaticSpacingLG: {
    paddingHorizontal: RAIL_SPACING_LG,
  },
  title: {
    ...TYPE_CONFIG.DISPLAY['24'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type RailProps = Pick<
  ComponentProps<typeof HorizontalScrollRail>,
  'children' | 'style'
>;

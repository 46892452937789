/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LocationDetailsFragment = {
  readonly __typename?: 'StoreLocation';
  readonly id: string;
  readonly name: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly slug: string;
  readonly address: string;
  readonly city: string;
  readonly state: string;
  readonly zipCode: string;
  readonly isOutpost: boolean | null;
  readonly phone: string | null;
  readonly storeHours: string | null;
  readonly flexMessage: string | null;
  readonly enabled: boolean;
  readonly acceptingOrders: boolean;
  readonly notAcceptingOrdersReason: string | null;
  readonly imageUrl: string;
  readonly hidden: boolean;
  readonly showWarningDialog: boolean;
  readonly warningDialogDescription: string | null;
  readonly warningDialogTimeout: number | null;
  readonly warningDialogTitle: string | null;
};

export type LocationsSearchByAreaQueryVariables = Types.Exact<{
  topLeft: Types.GeoCoordinates;
  bottomRight: Types.GeoCoordinates;
  showHidden?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type LocationsSearchByAreaQuery = {
  readonly __typename?: 'Query';
  readonly searchLocationsByBoundingBox: ReadonlyArray<{
    readonly __typename?: 'LocationSearchResult';
    readonly location: {
      readonly __typename?: 'StoreLocation';
      readonly id: string;
      readonly name: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly slug: string;
      readonly address: string;
      readonly city: string;
      readonly state: string;
      readonly zipCode: string;
      readonly isOutpost: boolean | null;
      readonly phone: string | null;
      readonly storeHours: string | null;
      readonly flexMessage: string | null;
      readonly enabled: boolean;
      readonly acceptingOrders: boolean;
      readonly notAcceptingOrdersReason: string | null;
      readonly imageUrl: string;
      readonly hidden: boolean;
      readonly showWarningDialog: boolean;
      readonly warningDialogDescription: string | null;
      readonly warningDialogTimeout: number | null;
      readonly warningDialogTitle: string | null;
    };
  } | null> | null;
};

export type LocationsSearchByAreaWithDisclosureFieldsQueryVariables =
  Types.Exact<{
    topLeft: Types.GeoCoordinates;
    bottomRight: Types.GeoCoordinates;
    showHidden?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  }>;

export type LocationsSearchByAreaWithDisclosureFieldsQuery = {
  readonly __typename?: 'Query';
  readonly searchLocationsByBoundingBox: ReadonlyArray<{
    readonly __typename?: 'LocationSearchResult';
    readonly location: {
      readonly __typename?: 'StoreLocation';
      readonly outpostPriceDifferentiationEnabled: boolean | null;
      readonly id: string;
      readonly name: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly slug: string;
      readonly address: string;
      readonly city: string;
      readonly state: string;
      readonly zipCode: string;
      readonly isOutpost: boolean | null;
      readonly phone: string | null;
      readonly storeHours: string | null;
      readonly flexMessage: string | null;
      readonly enabled: boolean;
      readonly acceptingOrders: boolean;
      readonly notAcceptingOrdersReason: string | null;
      readonly imageUrl: string;
      readonly hidden: boolean;
      readonly showWarningDialog: boolean;
      readonly warningDialogDescription: string | null;
      readonly warningDialogTimeout: number | null;
      readonly warningDialogTitle: string | null;
    };
  } | null> | null;
};

export const LocationDetailsFragmentDoc = gql`
  fragment LocationDetails on StoreLocation {
    id
    name
    latitude
    longitude
    slug
    address
    city
    state
    zipCode
    isOutpost
    phone
    storeHours
    flexMessage
    enabled
    acceptingOrders
    notAcceptingOrdersReason
    imageUrl
    hidden
    showWarningDialog
    warningDialogDescription
    warningDialogTimeout
    warningDialogTitle
  }
`;
export const LocationsSearchByAreaDocument = gql`
  query LocationsSearchByArea(
    $topLeft: GeoCoordinates!
    $bottomRight: GeoCoordinates!
    $showHidden: Boolean
  ) {
    searchLocationsByBoundingBox(
      topLeft: $topLeft
      bottomRight: $bottomRight
      showHidden: $showHidden
    ) {
      location {
        ...LocationDetails
      }
    }
  }
  ${LocationDetailsFragmentDoc}
`;

export function useLocationsSearchByAreaQuery(
  options: Omit<
    Urql.UseQueryArgs<LocationsSearchByAreaQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LocationsSearchByAreaQuery,
    LocationsSearchByAreaQueryVariables
  >({ query: LocationsSearchByAreaDocument, ...options });
}
export const LocationsSearchByAreaWithDisclosureFieldsDocument = gql`
  query LocationsSearchByAreaWithDisclosureFields(
    $topLeft: GeoCoordinates!
    $bottomRight: GeoCoordinates!
    $showHidden: Boolean
  ) {
    searchLocationsByBoundingBox(
      topLeft: $topLeft
      bottomRight: $bottomRight
      showHidden: $showHidden
    ) {
      location {
        ...LocationDetails
        outpostPriceDifferentiationEnabled
      }
    }
  }
  ${LocationDetailsFragmentDoc}
`;

export function useLocationsSearchByAreaWithDisclosureFieldsQuery(
  options: Omit<
    Urql.UseQueryArgs<LocationsSearchByAreaWithDisclosureFieldsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LocationsSearchByAreaWithDisclosureFieldsQuery,
    LocationsSearchByAreaWithDisclosureFieldsQueryVariables
  >({ query: LocationsSearchByAreaWithDisclosureFieldsDocument, ...options });
}

import { useEffect, useRef } from 'react';
import { datadog } from '@sg/expo-datadog-client';

import { useAsyncStorageContext } from '@order/AsyncStorage';
import { useIsLoggedIn, useIsLoggingIn } from '@order/AuthMachine';
import { useTelemetry } from '@order/Telemetry';

import { useCustomerData } from './useCustomerData';

export const useCustomerTelemetry = () => {
  const { customer } = useCustomerData();
  const isLoggingIn = useIsLoggingIn();
  const isLoggedIn = useIsLoggedIn();

  const customerId = customer?.id ?? '';
  const isLoadingCustomer = isLoggingIn || (isLoggedIn && !customerId);
  const sessionStarted = useRef(false);
  const { firstOpen, lastSession, setFirstOpen, setLastSession } =
    useAsyncStorageContext();
  const { track } = useTelemetry();

  // Sending first open event to telemetry machine.
  useEffect(() => {
    // Do not send the first_open event if it was opened before.
    // Or if it's undefined, which signifies it's still being loaded.
    if (Boolean(firstOpen) || firstOpen === undefined) return;

    track('app.first_open', { userId: customerId ?? '' });
    setFirstOpen(new Date().toISOString());
  }, [customerId, firstOpen, setFirstOpen, track]);

  // Sending session event to telemetry machine.
  useEffect(() => {
    if (isLoadingCustomer || sessionStarted.current) return;

    const previousLastSession = lastSession;

    // eslint-disable-next-line functional/immutable-data
    sessionStarted.current = true; // session should only "start" once.

    setLastSession(new Date().toISOString());
    track('app.session', {
      userId: customerId,
      lastSession: previousLastSession ?? '',
    });
  }, [lastSession, isLoadingCustomer, customerId, setLastSession, track]);

  // Setting customerId to Datadog.
  useEffect(() => {
    datadog.setUser(customerId);
  }, [customerId]);
};

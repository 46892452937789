import type { useCart } from '@order/hooks';
import {
  getCartLedgerTotal,
  useTippingEnabledForCurrentOrderType,
} from '@order/hooks';

import type { CheckoutPayload } from '../../Checkout.utils';
import { applyCredit, withTip } from '../../Checkout.utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Reusable hook that returns the current order ledger along with additional tip and credit info.
 * The total needs to be recalculated after that, otherwise it won't contain the correct value.
 */
export const useCheckoutLedger = (params: UseCheckoutLedgerParams) => {
  const { cart, payload, credit } = params;

  const isTippingEnabled = useTippingEnabledForCurrentOrderType();
  const shouldApplyPickupTip = cart?.orderType === 'PICKUP' && isTippingEnabled;

  const withAppliedTip = withTip({ cart, payload, shouldApplyPickupTip });
  const withAppliedCredit = applyCredit(withAppliedTip, credit);

  const total = getCartLedgerTotal(withAppliedCredit);

  return { ledger: withAppliedCredit, total };
};

// ─── UseCheckoutLedgerParams ─────────────────────────────────────────────────

type UseCheckoutLedgerParams = Readonly<{
  cart: ReturnType<typeof useCart>['cart'];
  payload: CheckoutPayload;
  credit: number;
}>;

import { defineMessages } from 'react-intl';

export const giftCardRedemptionContentMessages = defineMessages({
  heading: {
    defaultMessage: 'A sweet gift for you',
    description: 'Gift Card Redemption Screen | Heading',
  },
  ctaLabel: {
    defaultMessage: 'Add to your account',
    description:
      'Gift Card Redemption Screen | "Add to your account" CTA label',
  },
});

import {
  ProductCardV2AddToBagButton,
  ProductCardV2AddToBagContainer,
  ProductCardV2AllergenNotice,
  ProductCardV2ButtonsContainer,
  ProductCardV2Container,
  ProductCardV2Description,
  ProductCardV2FavoriteToggle,
  ProductCardV2Footer,
  ProductCardV2Heading,
  ProductCardV2Image,
  ProductCardV2Label,
  ProductCardV2LabelContainer,
  ProductCardV2ProductDetail,
  ProductCardV2ProductDetailsContainer,
  ProductCardV2Tag,
  ProductCardV2UnavailableLabel,
} from './components';

export const ProductCardV2 = {
  AddToBagButton: ProductCardV2AddToBagButton,
  AddToBagContainer: ProductCardV2AddToBagContainer,
  AllergenNotice: ProductCardV2AllergenNotice,
  ButtonsContainer: ProductCardV2ButtonsContainer,
  Container: ProductCardV2Container,
  Description: ProductCardV2Description,
  FavoriteToggle: ProductCardV2FavoriteToggle,
  Footer: ProductCardV2Footer,
  Heading: ProductCardV2Heading,
  Image: ProductCardV2Image,
  Label: ProductCardV2Label,
  LabelContainer: ProductCardV2LabelContainer,
  ProductDetail: ProductCardV2ProductDetail,
  ProductDetailsContainer: ProductCardV2ProductDetailsContainer,
  Tag: ProductCardV2Tag,
  UnavailableLabel: ProductCardV2UnavailableLabel,
};

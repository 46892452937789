import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  LabelText,
  MapWithControls,
  useInaccessibleElement,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { LoadingAnimation } from '../LoadingAnimation';
import { LocationsMapSearchArea } from './LocationsMapSearchArea';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationsMap = (props: MapViewProps) => {
  const {
    isLoadingMap,
    isLoadingMapLocations,
    isSearchAreaBtnEnabled,
    onSearchAreaPress,
    onGeolocateUserBtnPress,
    ...mapProps
  } = props;
  const { t } = useLocalizationContext();

  // The map should be not be accessible to screen readers -
  // an invisible LabelText is provided instead.
  const mapAltText = t('location.map-a11y-alt-text');
  const mapA11yProps = useInaccessibleElement(true);

  return (
    <>
      <LabelText
        testID="map-a11y-alt-text"
        style={styles.mapAltText}
        accessible={true}
        accessibilityRole="text"
        accessibilityLabel={mapAltText}
        accessibilityHint={mapAltText}
      >
        {mapAltText}
      </LabelText>

      <View testID="map-view" style={styles.mapViewContainer} {...mapA11yProps}>
        {onSearchAreaPress ? (
          <LocationsMapSearchArea
            isLoading={isLoadingMapLocations}
            disabled={!isSearchAreaBtnEnabled}
            onPress={onSearchAreaPress}
          />
        ) : null}

        {isLoadingMap ? (
          <LoadingAnimation />
        ) : (
          <MapWithControls
            centerLocationAccessibilityLabel={t(
              'location.map-center-location-label',
            )}
            centerLocationAccessibilityHint={t(
              'location.map-center-location-hint',
            )}
            zoomInAccessibilityLabel={t('location.map-zoom-in-label')}
            zoomInAccessibilityHint={t('location.map-zoom-in-hint')}
            zoomOutAccessibilityLabel={t('location.map-zoom-out-label')}
            zoomOutAccessibilityHint={t('location.map-zoom-out-hint')}
            disableGeolocateUserBtn={isLoadingMapLocations}
            onGeolocateUserBtnPress={onGeolocateUserBtnPress}
            {...mapProps}
          />
        )}
      </View>
    </>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  mapViewContainer: {
    flex: 1,
  },

  // hide map alt text label from users but leave it visible to screen readers
  mapAltText: {
    position: 'absolute',
    overflow: 'hidden',
    width: 1,
    height: 1,
    margin: -1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MapViewProps = Readonly<{
  isLoadingMap?: boolean;
  isLoadingMapLocations?: boolean;
  isSearchAreaBtnEnabled?: boolean;
  onSearchAreaPress?: () => void;
}> &
  React.ComponentProps<typeof MapWithControls>;

import { useMemo } from 'react';

import { useBaseProductQuery } from '@order/graphql';

export const useOrderProductConfirmProductScreenData = ({
  productSlug,
}: UseOrderProductConfirmProductScreenDataParams) => {
  const [{ data, fetching }] = useBaseProductQuery({
    variables: {
      slug: productSlug,
    },
    requestPolicy: 'cache-and-network',
  });

  return useMemo(
    () => ({
      isFetchingBaseProduct: fetching,
      baseProduct: data ? data.baseProduct : null,
    }),
    [data, fetching],
  );
};

type UseOrderProductConfirmProductScreenDataParams = Readonly<{
  productSlug: string;
}>;

import React, { useCallback } from 'react';
import type { PressableProps, ViewProps } from 'react-native';
import { Pressable, StyleSheet, View } from 'react-native';

import type { SweetpassHomeActionCardItem } from '../../hooks';
import { isSweetpassHomeActionSupported } from '../../utils';
import { SweetpassHomeActionCardLayout } from './SweetpassHomeActionCardLayout';

export const SweetpassHomeActionCard = (
  props: SweetpassHomeActionCardProps,
) => {
  const { testID, action, actionUrl, actionHandler, ...layoutProps } = props;

  const pressHandler = useCallback(async () => {
    if (!action) return;
    await actionHandler(action, actionUrl);
  }, [actionHandler, action, actionUrl]);

  return (
    <PressableWrapper testID={testID} onPress={pressHandler}>
      <SweetpassHomeActionCardLayout
        {...layoutProps}
        testID={testID?.concat('.card')}
        hasAction={isSweetpassHomeActionSupported(action)}
      />
    </PressableWrapper>
  );
};

const PressableWrapper = ({
  testID,
  onPress,
  children,
}: PressableWrapperProps) =>
  onPress ? (
    <Pressable
      testID={testID}
      accessibilityRole="button"
      onPress={onPress}
      style={styles.pressable}
    >
      {children}
    </Pressable>
  ) : (
    <View testID={testID}>{children}</View>
  );

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassHomeActionCardProps = Readonly<{
  actionHandler: (action: string, actionUrl?: string) => Promise<void>;
}> &
  Omit<SweetpassHomeActionCardItem, 'id'> &
  ViewProps;

type PressableWrapperProps = Pick<ViewProps, 'testID' | 'children'> &
  Pick<PressableProps, 'onPress'>;

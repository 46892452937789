import { useCallback } from 'react';
import type { AddressType } from '@sg/garnish';

import type { Order } from '@order/graphql';
import { useIsCustomerAddress } from '@order/hooks';
import type { useOrders } from '@order/Order';

import type { ReorderEvent } from '../../machines/Reorder.machine';
import { useActiveBagWarning } from '../useActiveBagWarning';
import { useChangeLocation } from '../useChangeLocation';
import { useConfirmLocation } from '../useConfirmLocation';
import { useReorderState } from '../useReorderState';
import { logger } from '../utils';

export const useReorderFromOrder = (
  order: Partial<Order> | ReturnType<typeof useOrders>['orders'][number],
) => {
  const { hasBag } = useReorderState();
  const activeBagWarning = useActiveBagWarning();
  const confirmLocation = useConfirmLocation();
  const changeLocation = useChangeLocation();

  // ─── Check if address was deleted ────────────────────────────────────
  const addressId = order?.deliveryOrderDetail?.address?.id;
  const isCustomerAddress = useIsCustomerAddress(addressId);

  return useCallback(() => {
    logger.info('Start Reorder from Rail', {
      order,
      hasBag,
      addressId,
      isCustomerAddress,
    });

    const reorderType: AddressType = order?.restaurant?.isOutpost
      ? 'outpost'
      : ((order?.orderType ?? '').toLowerCase() as AddressType);

    const reorderEvent: ReorderEvent = {
      orderId: order?.id,
      orderType: reorderType,
      deliveryOrderDetail: addressId
        ? {
            addressId,
            deliveryFee: order?.deliveryOrderDetail?.deliveryFee,
            tip: order?.deliveryOrderDetail?.tip,
            vendor: order?.deliveryOrderDetail?.vendor,
            vendorRestaurantId: order?.deliveryOrderDetail?.vendorRestaurantId,
          }
        : undefined,
      restaurantId: order?.restaurant?.id,
      restaurantSlug: order?.restaurant?.slug,
      restaurantName: order?.restaurant?.name,
      orderTotal: Number(order?.total),
      userInitiated: true,
    };

    // having an active bag means reorder would overwrite it, show warning first.
    if (hasBag) {
      activeBagWarning(reorderEvent);

      return;
    }

    // reorder delivery to since-deleted orders opens change location instead.
    if (addressId && !isCustomerAddress) {
      changeLocation(reorderEvent);

      return;
    }

    // default reorder behavior is to ask the customer to confirm the location.
    confirmLocation(reorderEvent);
  }, [
    order,
    hasBag,
    addressId,
    isCustomerAddress,
    confirmLocation,
    activeBagWarning,
    changeLocation,
  ]);
};

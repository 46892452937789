import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';
import { ModalSizesStyles } from '../../Modal.sizes';
import type { BaseModalProps } from '../../Modal.types';
import { ModalAnimatedContent } from '../ModalAnimatedContent';

export const ModalContainer = (props: ModalContainerProps) => {
  const {
    children,
    style,
    offset,
    size = 'small',
    fitHeight = false,
    maxWidth,
    maxHeight,
    ...restProps
  } = props;

  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const responsiveStyles = match([styles.dialogMobile, styles.dialogTabletUp]);

  const sizeSpecificStyles = ModalSizesStyles[size];
  const xsFullWidthStyle = match([styles.dialogMobileFullWidth, undefined]);
  const fitHeightStyles = fitHeight ? styles.dialogFitHeight : undefined;

  // ─── Dynamic Styles ──────────────────────────────────────────────────

  const offsetStyle = useStyle(() => {
    if (!offset) return;

    const formattedOffset = Number.parseInt(offset, 10);
    const sizeAfterOffset = `${100 - formattedOffset}%` as const;

    return {
      width: sizeAfterOffset,
      maxWidth,
      height: sizeAfterOffset,
      maxHeight: '100%',
      justifyContent: 'center',
    };
  }, [offset, maxWidth]);

  const dynamicStyles = useStyle(() => {
    return {
      ...(maxWidth ? { maxWidth } : {}),
      ...(maxHeight ? { maxHeight } : {}),
    };
  }, [maxWidth]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ModalAnimatedContent
      style={[
        styles.dialog,
        responsiveStyles,
        sizeSpecificStyles,
        xsFullWidthStyle,
        fitHeightStyles,
        offsetStyle,
        dynamicStyles,
        style,
      ]}
      pointerEvents="box-none"
      {...restProps}
    >
      {children}
    </ModalAnimatedContent>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type ModalContainerProps = {
  isModalShown?: boolean;
  isAnimated?: boolean;
} & ViewProps &
  Pick<
    BaseModalProps,
    'size' | 'fitHeight' | 'offset' | 'maxWidth' | 'maxHeight'
  >;

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  dialog: {
    maxWidth: '100%',
    marginHorizontal: 'auto',
    zIndex: theme.zIndex.modal,
  },
  dialogMobile: {
    marginTop: 'auto',
    paddingTop: 80,
  },
  dialogMobileFullWidth: {
    width: '100%',
  },
  dialogTabletUp: {
    marginVertical: 'auto',
    paddingVertical: 80,
  },
  dialogFitHeight: {
    maxHeight: '100%',

    // NOTE: In order to override all possible cases of padding
    paddingTop: 0,
    paddingBottom: 0,
    paddingVertical: 0,
  },
});

import { defineMessages } from 'react-intl';

export const giftCardCheckoutDeliveryDateMessages = defineMessages({
  nowOptionLabel: {
    defaultMessage: 'Now',
    description: 'Gift Card Checkout Form | Delivery Date | "Now" option label',
  },
  scheduleOptionLabel: {
    defaultMessage: 'Schedule',
    description:
      'Gift Card Checkout Form | Delivery Date | "Schedule" option label',
  },
});

import React, { type ComponentProps } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { IconLink } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuDietaryPreferencesModalCloseButton = (
  props: MenuDietaryPreferencesModalCloseButtonProps,
) => {
  const { formatMessage } = useIntl();

  return (
    <IconLink
      name="IconClose"
      width={40}
      height={40}
      palette="muted"
      accessibilityLabel={formatMessage(messages.a11yLabel)}
      {...props}
    />
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  a11yLabel: {
    defaultMessage: 'Close dietary preferences modal',
    description: 'Menu | Dietary preferences | Modal | close button',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuDietaryPreferencesModalCloseButtonProps = Omit<
  ComponentProps<typeof IconLink>,
  'name' | 'palette' | 'width' | 'height' | 'accessibilityLabel'
>;

import React, { useCallback, useMemo } from 'react';
import { AddressTypes, ListActionSelect } from '@sg/garnish';

import { RestaurantListAction } from '@order/components';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { mapAvailableTimes } from '../../../Checkout.utils';
import { useCheckoutContext } from '../../../CheckoutProvider';
import { useFirstAvailableOption } from '../../../hooks';
import { CheckoutBlockVStack } from '../CheckoutBlockVStack';

export const ConnectedOutpostOptions = () => {
  const context = useCheckoutContext();

  return <OutpostOptions {...context} />;
};

export const OutpostOptions = (
  props: Pick<
    ReturnType<typeof useCheckoutContext>,
    | 'orderId'
    | 'cart'
    | 'cartOrderType'
    | 'payload'
    | 'loadingAvailableWantedTimes'
    | 'availableWantedTimes'
    | 'handleWantedTimeSelected'
    | 'handleDropoffLocationSelected'
  >,
) => {
  const {
    orderId,
    cart,
    cartOrderType,
    payload,
    loadingAvailableWantedTimes,
    availableWantedTimes: availableWantedTimeOptions,
    handleWantedTimeSelected: setSelectedTime,
    handleDropoffLocationSelected: setDropoffLocation,
  } = props;
  const restaurant = cart?.restaurant;
  const { wantedTime: selectedTime, dropoffLocationId: dropoffLocation } =
    payload;

  const { t } = useLocalizationContext();

  const timeOptionsGroups = useMemo(
    () =>
      mapAvailableTimes({
        t,
        orderType: cartOrderType,
        times: availableWantedTimeOptions,
      }),
    [t, cartOrderType, availableWantedTimeOptions],
  );

  useFirstAvailableOption({
    timeOptionsGroups,
    selectedTime,
    setSelectedTime,
  });

  // ──────── Telemetry ─────────

  const { track } = useTelemetry();

  const onTimeChange = useCallback(
    (time: string | number) => {
      const delta = new Date(time).getTime() - new Date(selectedTime).getTime();

      track('checkout.time_changed', { timeChanged: delta / (60 * 1000) });

      setSelectedTime(String(time));
    },
    [selectedTime, setSelectedTime, track],
  );
  // ────────────────────────────

  const availableDropOffLocations = useMemo(() => {
    return restaurant?.availableDropOffLocations ?? [];
  }, [restaurant?.availableDropOffLocations]);
  const hasMultipleDropOffLocations = availableDropOffLocations.length > 1;

  if (cartOrderType !== AddressTypes.outpost) return null;

  return (
    <CheckoutBlockVStack testID="checkout-block-outpost">
      <ListActionSelect
        iconName="IconClock"
        title={t('checkout.pickup-time')}
        modalTitle={t('checkout.pickup-time.title')}
        accessibilityLabel={t('checkout.pickup-time')}
        accessibilityLabelLeftGroup={t('checkout.pickup-time.day')}
        accessibilityLabelRightGroup={t('checkout.pickup-time.hour')}
        value={selectedTime}
        options={timeOptionsGroups}
        isLoading={loadingAvailableWantedTimes}
        onSelect={onTimeChange}
      />

      <RestaurantListAction
        orderId={orderId}
        restaurant={restaurant}
        orderType={cartOrderType}
      />

      {hasMultipleDropOffLocations ? (
        <ListActionSelect
          iconName="IconFloor"
          title={t('checkout.dropoff-location')}
          modalTitle={t('checkout.dropoff-location.title')}
          accessibilityLabel={t('checkout.dropoff-location')}
          value={dropoffLocation ?? 'placeholder'}
          onSelect={setDropoffLocation}
          options={[
            {
              value: 'placeholder',
              label: t('checkout.dropoff-location'),
              disabled: true,
            },
            ...availableDropOffLocations.map((location) => ({
              value: location.id,
              label: location.name,
            })),
          ]}
        />
      ) : null}
    </CheckoutBlockVStack>
  );
};

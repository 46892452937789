import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { IconLink } from '../../../Icon';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductCardV2FavoriteToggle = (
  props: ProductCardV2FavoriteToggleProps,
) => {
  const { isActive, ...restProps } = props;

  return (
    <View style={styles.container}>
      <IconLink
        name={isActive ? 'IconFaveFill' : 'IconFaveStroke'}
        color={theme.colors.KALE}
        {...restProps}
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: theme.spacing['4'],
    right: theme.spacing['4'],
    zIndex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ProductCardV2FavoriteToggleProps = {
  isActive: boolean;
} & Omit<ComponentProps<typeof IconLink>, 'name'>;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText, TitleText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanCardBalance = (props: ScanCardBaseProps) => {
  const { cardName, balance } = props;

  return (
    <View style={styles.container}>
      <BodyText sizeMatch={['24']} style={styles.cardName}>
        {cardName}
      </BodyText>

      <TitleText sizeMatch={['40']} style={styles.balance}>
        {balance}
      </TitleText>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    marginBottom: theme.spacing['12'],
  },
  cardName: {
    textAlign: 'center',
    marginBottom: theme.spacing['3'],
  },
  balance: {
    textAlign: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanCardBaseProps = Readonly<{
  cardName: string;
  balance: string;
}>;

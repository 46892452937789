/* istanbul ignore file */ /* plugin extension code */

export const intlPolyfill = () => {
  fixReanimatedFrameTimestamp();
};

// ─────────────────────────────────────────────────────────────────────────────

/**
 * @see {@link: https://github.com/software-mansion/react-native-reanimated/issues/3355}
 */
function fixReanimatedFrameTimestamp() {
  // eslint-disable-next-line functional/immutable-data
  (window as unknown as { _frameTimestamp: unknown })._frameTimestamp = null;
}

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, Icon, IconLink, theme } from '@sg/garnish';

/**
 * Header for Loyalty on Bag.
 */
export const BagLoyaltyHeader = (props: BagLoyaltyHeaderProps) => {
  const { title, onPressInfo } = props;
  const { formatMessage } = useIntl();

  return (
    <View style={styles.container}>
      <View style={styles.container}>
        <Icon name="IconSgRewardBlackFill" width={32} height={32} />

        <BodyText style={styles.title} sizeMatch={['18']} bold>
          {title}
        </BodyText>
      </View>

      {onPressInfo ? (
        <IconLink
          name="IconInfo"
          width={32}
          height={32}
          iconSize={16}
          accessibilityLabel={formatMessage(messages.infoIconA11y)}
          style={styles.infoIcon}
          onPress={onPressInfo}
        />
      ) : null}
    </View>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing['3'],
  },
  title: {
    marginTop: theme.spacing['1'],
  },
  infoIcon: {
    width: 32,
    height: 32,
  },
});

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  infoIconA11y: {
    defaultMessage: 'See more info',
    description: 'Bag | Loyalty Header | Info Icon A11y',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagLoyaltyHeaderProps = {
  title: string;
  onPressInfo?: () => void;
};

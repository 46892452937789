import { useCallback } from 'react';

import type { DietaryProperty } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import type { DietaryRestrictionsTypes } from './dietaryRestrictionsMachine';
import { useDietaryRestrictionsMachine } from './dietaryRestrictionsMachine';

/**
 * Returns a formatted string of matched dietary restrictions
 * between user dietary restrictions and provided dietary properties.
 */
export const getProductDietaryRestrictionsNames = (
  userRestrictions: DietaryRestrictionsTypes,
  dietaryProperties: readonly DietaryProperty[] | undefined,
) => {
  const activeRestrictions = (dietaryProperties ?? []).filter(
    (property) => userRestrictions[property.name],
  );
  const restrictionNames = activeRestrictions.map((restriction) =>
    restriction.name.toLowerCase(),
  );

  return restrictionNames.join(', ');
};

/**
 * Returns a function that generates the "Contains {restrictions}" label based on provided restrictions.
 */
export const useDietaryRestrictions = () => {
  const { t } = useLocalizationContext();
  const { restrictions } = useDietaryRestrictionsMachine();

  return useCallback(
    (dietaryProperties: readonly DietaryProperty[] | undefined) => {
      const activeDietaryRestrictions = getProductDietaryRestrictionsNames(
        restrictions,
        dietaryProperties,
      );

      if (!activeDietaryRestrictions) return '';

      return t('menu.contains-restrictions', {
        restrictions: activeDietaryRestrictions,
      });
    },
    [restrictions, t],
  );
};

import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import type { TabItem, TabItemId, TabItems } from '@sg/garnish';

import { type AccountTabStackParamList } from '../../../../navigation/AppNavigation.props';
import { ordersTabsMessages as messages } from './useOrdersTabs.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const useOrdersTabs = (params: UseOrdersTabsParams) => {
  const { tabIdParam } = params;

  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const ordersTab = useMemo<TabItem>(
    () => ({
      id: 'orders',
      panelId: 'orders.orders-tab-panel',
      label: formatMessage(messages.ordersTabLabel),
      'aria-label': formatMessage(messages.ordersTabA11yLabel),
    }),
    [formatMessage],
  );
  const giftCardsTab = useMemo<TabItem>(
    () => ({
      id: 'gift-cards',
      panelId: 'orders.gift-cards-tab-panel',
      label: formatMessage(messages.giftCardsTabLabel),
      'aria-label': formatMessage(messages.giftCardsTabA11yLabel),
    }),
    [formatMessage],
  );

  const tabs = useMemo<TabItems>(
    () => [ordersTab, giftCardsTab],
    [ordersTab, giftCardsTab],
  );

  const [activeTabItemId, setActiveTabItemId] = useState<TabItemId>(
    tabIdParam ?? ordersTab.id,
  );

  // ─────────────────────────────────────────────────────────────────────

  return {
    activeTabItemId,
    setActiveTabItemId,
    tabs,
    ordersTab,
    giftCardsTab,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseOrdersTabsParams = {
  tabIdParam?: OrderTabId;
};

type OrderTabId = NonNullable<
  NonNullable<AccountTabStackParamList['Orders']>['tabId']
>;

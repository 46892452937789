import { useIsLoggingIn } from '@order/AuthMachine';
import type { Customer } from '@order/graphql';

import { useCurrentCustomerQuery } from '../GraphQL/CurrentCustomer.query.generated';

export const useCustomerData = () => {
  const isLoggingIn = useIsLoggingIn();

  // NOTE: `Customer` network requests are managed by the `Auth` state machine
  const [customerResponse] = useCurrentCustomerQuery({
    requestPolicy: 'cache-only',
  });

  const customer = customerResponse.data?.currentCustomer || emptyCustomer;
  const isDataResolved = customerResponse.data !== undefined;
  const isFetchingCustomer = isLoggingIn;
  const isCustomerLoaded =
    isDataResolved &&
    !customerResponse.fetching &&
    Boolean(customerResponse.data?.currentCustomer?.id) &&
    Boolean(customer?.id);

  // ────────────────────────────────────────────────────────────────────

  return {
    customer,
    isFetchingCustomer,
    isCustomerLoaded,
  };
};

// ────────────────────────────────────────────────────────────────────────────────

export const emptyCustomer: Partial<Customer> = {};

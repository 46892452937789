import React, { type ComponentProps, useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Linking, StyleSheet } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import {
  BodyText,
  Button,
  getLocationAddressGeolocationLink,
  theme,
  useNoticeBannersStackContext,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoButtonDirection = (
  props: MenuMoreInfoButtonDirectionProps,
) => {
  const { restaurantAddress } = props;

  const completeAddress = getRestaurantFormattedAddress(restaurantAddress);

  const openDirectionURL = useCallback(async () => {
    await Linking.openURL(getLocationAddressGeolocationLink(completeAddress));
  }, [completeAddress]);

  return (
    <MenuMoreInfoButton onPress={openDirectionURL}>
      <FormattedMessage {...messages.getDirectionsButtonLabel} />
    </MenuMoreInfoButton>
  );
};

export const MenuMoreInfoButtonCopyAddress = (
  props: MenuMoreInfoButtonCopyAddressProps,
) => {
  const { restaurantAddress } = props;

  const { formatMessage } = useIntl();
  const { push: addBanner } = useNoticeBannersStackContext();

  const completeAddress = getRestaurantFormattedAddress(restaurantAddress);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const copyAddress = useCallback(async () => {
    await Clipboard.setStringAsync(completeAddress);

    addBanner(
      {
        id: 'menu.more-info.address-copied',
        palette: 'success',
        text: formatMessage(messages.addressCopiedText),
        autoHideTimeout: 3000,
      },
      true,
    );
  }, [addBanner, completeAddress, formatMessage]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <MenuMoreInfoButton onPress={copyAddress}>
      <FormattedMessage {...messages.copyAddressButtonLabel} />
    </MenuMoreInfoButton>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const MenuMoreInfoButton = (props: ComponentProps<typeof Button>) => {
  const { children, ...restProps } = props;

  return (
    <Button palette="kaleLightest" size="large" {...restProps}>
      <BodyText sizeMatch={['18']} style={styles.text}>
        {children}
      </BodyText>
    </Button>
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * Returns formatted complete address of the restaurant by combining address, city,
 * state and zip code.
 */
function getRestaurantFormattedAddress(addressData: AddressData) {
  const { address, city, state, zipCode } = addressData;

  return [address, city, zipCode ? `${state} ${zipCode}` : state]
    .filter(Boolean)
    .join(', ')
    .trim();
}

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  getDirectionsButtonLabel: {
    defaultMessage: 'Get directions',
    description: 'Menu | More info | "Get directions" button label',
  },
  copyAddressButtonLabel: {
    defaultMessage: 'Copy address',
    description: 'Menu | More info | "Copy address" button label',
  },
  addressCopiedText: {
    defaultMessage: 'Address copied to your clipboard',
    description: 'Menu | More info | Address copied text',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    color: theme.colors.KALE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuMoreInfoButtonCopyAddressProps = {
  restaurantAddress: AddressData;
};

type MenuMoreInfoButtonDirectionProps = {
  restaurantAddress: AddressData;
};

type AddressData = {
  address: string;
  city: string;
  state: string;
  zipCode: string;
};

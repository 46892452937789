import { useCustomer } from '@order/Customer';

/**
 * Past orders and/or interactions might be done with now-deleted addresses.
 * These addresses will no longer be valid and should not be used.
 */
export const useIsCustomerAddress = (addressId?: string) => {
  const { customer } = useCustomer();
  const addresses = customer?.addresses ?? [];

  return addresses.some((address) => address.id === addressId);
};

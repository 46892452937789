import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

export const useAddressTypes = (params: UseAddressTypesParams) => {
  const { addressType, hasHomeAddress, hasWorkAddress } = params;

  const shouldDisableHomeChip = addressType !== 'home' && hasHomeAddress;
  const shouldDisableWorkChip = addressType !== 'work' && hasWorkAddress;

  const { formatMessage } = useIntl();

  return useMemo(
    () => [
      {
        value: 'home',
        disabled: shouldDisableHomeChip,
        label: formatMessage(messages.home),
        accessibilityLabel: formatMessage(messages.homeA11y),
        helperText: shouldDisableHomeChip
          ? formatMessage(messages.homeDisabledHint)
          : undefined,
      },
      {
        value: 'work',
        disabled: shouldDisableWorkChip,
        label: formatMessage(messages.work),
        accessibilityLabel: formatMessage(messages.workA11y),
        helperText: shouldDisableWorkChip
          ? formatMessage(messages.workDisabledHint)
          : undefined,
      },
      {
        value: 'custom',
        label: formatMessage(messages.custom),
        accessibilityLabel: formatMessage(messages.customA11y),
      },
    ],
    [shouldDisableHomeChip, shouldDisableWorkChip, formatMessage],
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  home: {
    defaultMessage: 'Home',
    description: 'Address Type | Home',
  },
  work: {
    defaultMessage: 'Work',
    description: 'Address Type | Work',
  },
  custom: {
    defaultMessage: 'Custom',
    description: 'Address Type | Custom',
  },
  homeA11y: {
    defaultMessage: 'Address type, home',
    description: 'Address Type | Home A11y',
  },
  workA11y: {
    defaultMessage: 'Address type, work',
    description: 'Address Type | Work A11y',
  },
  customA11y: {
    defaultMessage: 'Address type, custom',
    description: 'Address Type | Custom A11y',
  },
  homeDisabledHint: {
    defaultMessage: 'You already have a home address saved',
    description: 'Address Type | Home disabled hint',
  },
  workDisabledHint: {
    defaultMessage: 'You already have a work address saved',
    description: 'Address Type | Work disabled hint',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type UseAddressTypesParams = {
  addressType: string;
  hasHomeAddress: boolean;
  hasWorkAddress: boolean;
};

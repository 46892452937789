import React from 'react';
import { View, type ViewProps } from 'react-native';

import {
  type AlertContainerPalette,
  paletteStyles,
} from './AlertContainer.palettes';
import { type AlertContainerSize, sizeStyles } from './AlertContainer.sizes';

// ─────────────────────────────────────────────────────────────────────────────

export const AlertContainer = (props: AlertContainerProps) => {
  const { palette, children, style, size = 'md', ...restProps } = props;

  const containerStyles = [sizeStyles[size], paletteStyles[palette], style];

  return (
    <View style={containerStyles} {...restProps}>
      {children}
    </View>
  );
};

// ─── Types ────── ─────────────────────────────────────────────────────────────

type AlertContainerProps = {
  size?: AlertContainerSize;
  palette: AlertContainerPalette;
} & ViewProps;

import { useCallback } from 'react';
import { AccessibilityInfo } from 'react-native';

import { useLocalizationContext } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

export const useProductModificationsAnnouncements = () => {
  const { t } = useLocalizationContext();

  // ─── Added/increased ingredients ─────────────

  const announceAddedIngredientModification = useCallback(
    (ingredientName: string) => {
      announceToScreenReader(
        t('pdp.modifications.ingredient-modification.a11y.added', {
          ingredientName,
        }),
      );
    },
    [t],
  );

  const announceIncreasedIngredientModification = useCallback(
    (ingredientName: string, qty: number) => {
      announceToScreenReader(
        t('pdp.modifications.ingredient-modification.a11y.increased', {
          ingredientName,
          qty,
        }),
      );
    },
    [t],
  );

  // ─── Decreased/removed ingredients ───────────

  const announceDecreasedIngredientModification = useCallback(
    (ingredientName: string, qty: number) => {
      announceToScreenReader(
        t('pdp.modifications.ingredient-modification.a11y.decreased', {
          ingredientName,
          qty,
        }),
      );
    },
    [t],
  );

  const announceRemovedIngredientModification = useCallback(
    (ingredientName: string) => {
      announceToScreenReader(
        t('pdp.modifications.ingredient-modification.a11y.removed', {
          ingredientName,
        }),
      );
    },
    [t],
  );

  // ─────────────────────────────────────────────────────────────────────

  return {
    announceAddedIngredientModification,
    announceIncreasedIngredientModification,
    announceDecreasedIngredientModification,
    announceRemovedIngredientModification,
  };
};

// ─── Utils ───────────────────────────────────────────────────────────────────

/**
 * Calls the 'announceForAccessibility' helper with a minimal delay to
 * avoid overriding with pending announcements.
 */
function announceToScreenReader(message: string) {
  setTimeout(() => {
    AccessibilityInfo.announceForAccessibility(message);
  });
}

/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-var-requires */

import { Platform } from 'react-native';

import { checkIfDeviceNotSimulator, checkIfInExpoGoClient } from '@order/utils';

import type { LDClientModule } from './launch-darkly.types';

/*

  Lazy loads a Launch Darkly client initializer based on the current platform and env.

  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  🖥️ Web

  Uses our custom abstraction using Launch Darkly JS SDK
  (https://docs.launchdarkly.com/sdk/client-side/javascript).

  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  📲 Native (Expo Go)

  Also uses our JS client, since the native client requires
  additional native modules support, that are not included in Expo Go.

  NOTE: JS SDK feature flags changes detection is not working in
        Expo Go, since it uses custom web socket connection.

  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  📱 Native

  Uses our custom abstraction using Launch Darkly Native SDK
  (https://docs.launchdarkly.com/sdk/client-side/react/react-native).

  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 */
const isNativePlatform = Platform.OS === 'ios' || Platform.OS === 'android';
const isDeviceNotSimulator =
  checkIfDeviceNotSimulator() && !checkIfInExpoGoClient();
const shouldUseNativeClient = isNativePlatform && isDeviceNotSimulator;

export const LaunchDarklyClient: LDClientModule = shouldUseNativeClient
  ? require('./launch-darkly-native-client').LaunchDarklyClientModule
  : require('./launch-darkly-js-client').LaunchDarklyClientModule;

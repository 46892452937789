import React from 'react';
import { logger as LOG } from '@garnish/logger';
import { useActor } from '@xstate/react';

import { useGlobalAppState } from '../../GlobalAppState';
import { AuthModel } from '../AuthMachine.model';

// ────────────────────────────────────────────────────────────────────────────────
// LOG.enable('AUTH');
const logger = LOG.extend('AUTH');
// ────────────────────────────────────────────────────────────────────────────────

export const useSendLogoutToAuthMachine = () => {
  const { authMachineRef } = useGlobalAppState();
  const authMachine = useActor(authMachineRef);
  const { 1: send } = authMachine;

  const sendLogoutAction = React.useCallback(
    (csrf?: string) => {
      logger.debug('sending LOGOUT event to AuthMachine', csrf);
      send(AuthModel.events.LOGOUT(csrf));
    },
    [send],
  );

  return sendLogoutAction;
};

import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useNavigateToMenu, useNavigateToProductDetails } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { useProductForRestaurantQuery } from '../graphql/ProductForRestaurant.query.generated';

// ─────────────────────────────────────────────────────────────────────────────

export const useLocationConfirm = (
  props: UseNavigateToBaseProductDetailsScreenProps,
) => {
  const {
    restaurantId = '',
    restaurantSlug,
    deliveryAddressId,
    baseProductSlug,
  } = props;

  const orderType = deliveryAddressId ? 'delivery' : 'pickup';

  const navigateToMenu = useNavigateToMenu();
  const { track } = useTelemetry();
  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  // ─── Query ───────────────────────────────────────────────────────────

  const [productForRestaurantResponse] = useProductForRestaurantQuery({
    requestPolicy: 'cache-and-network',
    variables: { baseProductSlug, restaurantId },
    pause: !restaurantId,
  });

  // ─── Flags ───────────────────────────────────────────────────────────

  const hasRestaurantData = Boolean(restaurantId);
  const productForRestaurantData =
    productForRestaurantResponse.data?.productForRestaurant;
  const productSlug = productForRestaurantData?.slug;

  const isFetchingProduct =
    productForRestaurantResponse.fetching || productForRestaurantResponse.stale;

  const isProductNotAvailable =
    hasRestaurantData && !isFetchingProduct && !productForRestaurantData;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToPDP = useNavigateToProductDetails({
    restaurantSlug,
    deliveryAddressId,
  });

  const handleLocationConfirm = useCallback(async () => {
    if (isProductNotAvailable) {
      navigateToMenu({
        orderType,
        restaurantSlug,
        deliveryOrderDetail: {
          addressId: deliveryAddressId,
        },
      });

      setTimeout(() => {
        addNoticeBanner({
          text: t('location.product-not-available'),
          palette: 'caution',
        });
      });

      return;
    }

    track('deep-linking.continue_pdp_flow');
    navigateToPDP({ slug: productSlug });
  }, [
    addNoticeBanner,
    deliveryAddressId,
    isProductNotAvailable,
    navigateToMenu,
    navigateToPDP,
    orderType,
    productSlug,
    restaurantSlug,
    t,
    track,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    handleLocationConfirm,
    isProductNotAvailable,
    isFetchingProduct,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseNavigateToBaseProductDetailsScreenProps = Readonly<{
  baseProductSlug: string;
  restaurantId?: string;
  restaurantSlug?: string;
  deliveryAddressId?: string;
  deliveryVendorName?: string;
  deliveryVendorRestaurantId?: string;
  deliveryFee?: number;
}>;

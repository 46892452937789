import { useMemo } from 'react';

import type { UseCollapsibleMachineProps } from '../Collapsible.types';

export const useCollapsibleMachine = (props: UseCollapsibleMachineProps) => {
  const { onExpanded, onCollapsed } = props;

  return useMemo(
    () => ({
      helpers: {
        sendExpandEvent: onExpanded,
        sendCollapseEvent: onCollapsed,
      },
    }),
    [onExpanded, onCollapsed],
  );
};

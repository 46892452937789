import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationDetailsContainer = (props: PropsWithChildren) => {
  return <View style={styles.container}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: theme.spacing['6'],
    padding: theme.spacing['4'],
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { NoticeBannersStack, useResponsive, Z_INDEX } from '@sg/garnish';

export const ProductNoticeBannersMobile = () => {
  const { currentBreakpoint } = useResponsive();

  if (!currentBreakpoint.isXS) return null;

  return (
    <View testID="pdp-notice-banners-mobile">
      <NoticeBannersStack />
    </View>
  );
};

export const ProductNoticeBannersTablet = () => {
  const { currentBreakpoint } = useResponsive();

  if (currentBreakpoint.isXS) return null;

  return (
    <View style={styles.wrapperTablet} testID="pdp-notice-banners-tablet">
      <NoticeBannersStack />
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapperTablet: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: Z_INDEX.fixed,
  },
});

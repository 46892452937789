import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

import type { PickerContainerProps } from '../Picker.types';
import { PickerModalDialogue } from './PickerModalDialogue';
import { PickerTrigger } from './PickerTrigger';

export const PickerContainer = (props: PickerContainerProps) => {
  const {
    label,
    modalTitle,
    submitBtnLabel = 'Submit',
    labelNativeID,
    selectedOptionLabel,
    variation,
    children,
    accessibilityLabel,
    triggerIconLeft,
    triggerIconRight,
    boldTriggerText,
    onSubmitBtnPress,
    onDismiss,
  } = props;

  const [isPickerModalShown, setIsPickerModalShown] = useState(false);

  // ─── HELPERS ────────────────────────────────────────────────────────

  const showModal = useCallback(() => {
    setIsPickerModalShown(true);
  }, []);

  const hideModal = useCallback(() => {
    onDismiss();
    setIsPickerModalShown(false);
  }, [onDismiss]);

  const handleOnSubmit = useCallback(() => {
    onSubmitBtnPress();
    setIsPickerModalShown(false);
  }, [onSubmitBtnPress]);

  // ────────────────────────────────────────────────────────────────────

  return (
    <View accessible={true}>
      <PickerTrigger
        label={label}
        accessibilityLabel={accessibilityLabel}
        selectedOption={selectedOptionLabel}
        expanded={isPickerModalShown}
        variation={variation}
        iconLeft={triggerIconLeft}
        iconRight={triggerIconRight}
        bold={boldTriggerText}
        onPress={showModal}
      />

      <PickerModalDialogue
        label={modalTitle ?? label}
        labelNativeID={labelNativeID}
        submitBtnLabel={submitBtnLabel}
        show={isPickerModalShown}
        onSubmit={handleOnSubmit}
        onDismiss={hideModal}
      >
        {children}
      </PickerModalDialogue>
    </View>
  );
};

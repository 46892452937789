/* eslint-disable functional/immutable-data */

import type {
  Component,
  ComponentProps,
  Dispatch,
  ReactNode,
  SetStateAction,
} from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { ViewStyle } from 'react-native';
import { AccessibilityInfo, StyleSheet, View } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import { useReduceMotionStatus, useResponsive } from '../../../hooks';
import { focusOnElement } from '../../../utils';
import { IconLink } from '../../Icon';
import { HighlightWords } from '../../Text';

export const NoticeBanner = (props: NoticeBannerProps) => {
  const {
    palette = 'neutral',
    padding = NOTICE_BANNER_PADDING,
    text,
    ContentComponent,
    highlights,
    autoHideTimeout,
    onClose,
    withCloseBtn = true,
    autoFocusCloseBtn,
    closeBtnAccessibilityLabel = NOTICE_BANNER_CLOSE_BTN_A11Y_LABEL,
    closeBtnAccessibilityHint = NOTICE_BANNER_CLOSE_BTN_A11Y_HINT,
  } = props;
  const closeBtnRef = useRef(null);

  const { currentBreakpoint } = useResponsive();

  const slideInTransitionDirection: NoticeBannerSlideInDirection =
    currentBreakpoint.isXS ? 'from-bottom' : 'from-top';

  // ─── Flags ───────────────────────────────────────────────────────────

  const hasCustomContent = ContentComponent !== undefined;

  // ─── Styles ──────────────────────────────────────────────────────────

  const bannerPaddingStyles = useBannerPaddingStyles(padding);
  const bannerPaletteStyles = useBannerPaletteStyles(palette);
  const bannerResponsiveStyles = useBannerResponsiveStyles();
  const bannerAnimatedStyles = useBannerSlideInAnimation(
    slideInTransitionDirection,
  );
  const { textSize, textStyle } = useResponsiveTextStyles();

  const contentContainerStyles = [
    styles.contentContainer,
    hasCustomContent ? undefined : styles.textContentContainer,
  ];

  // ─── Helpers ─────────────────────────────────────────────────────────

  const [isBannerShown, setIsBannerShown] = useToggleBanner({
    autoHideTimeout,
    text,
    onClose,
  });

  const handleClose = useCallback(() => {
    (setIsBannerShown as Dispatch<SetStateAction<boolean>>)(false);
    onClose?.();
  }, [onClose, setIsBannerShown]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useA11yBanner({ text, closeBtnRef, autoFocusCloseBtn });

  // ─────────────────────────────────────────────────────────────────────

  if (!isBannerShown) return null;

  return (
    <Animated.View style={bannerAnimatedStyles}>
      <View
        testID="sg-notice-banner"
        style={[
          styles.banner,
          bannerPaddingStyles,
          bannerResponsiveStyles,
          bannerPaletteStyles,
        ]}
      >
        <View style={contentContainerStyles}>
          {ContentComponent ? <ContentComponent /> : null}

          {text ? (
            <HighlightWords
              text={text}
              highlights={highlights}
              size={textSize}
              style={textStyle}
              accessibilityRole="alert"
            />
          ) : null}
        </View>

        {withCloseBtn ? (
          <View style={styles.closeBtnWrapper}>
            <IconLink
              ref={closeBtnRef}
              name="IconBadgeClose"
              width={CLOSE_BTN_SIZE}
              height={CLOSE_BTN_SIZE}
              onPress={handleClose}
              accessibilityRole="button"
              accessibilityLabel={closeBtnAccessibilityLabel}
              accessibilityHint={closeBtnAccessibilityHint}
            />
          </View>
        ) : null}
      </View>
    </Animated.View>
  );
};

export const FixedNoticeBanner = (props: FixedNoticeBannerProps) => {
  const { palette, text, highlights, padding = NOTICE_BANNER_PADDING } = props;

  // ─── STYLES ─────────────────────────────────────────────────────────────────────

  const bannerPaddingStyles = useBannerPaddingStyles(padding);
  const bannerPaletteStyles = useBannerPaletteStyles(palette);
  const bannerResponsiveStyles = useBannerResponsiveStyles();
  const { textSize, textStyle } = useResponsiveTextStyles();

  // ────────────────────────────────────────────────────────────────────────────────

  return (
    <View
      style={[
        styles.banner,
        styles.fixedBanner,
        bannerPaddingStyles,
        bannerResponsiveStyles,
        bannerPaletteStyles,
      ]}
    >
      <HighlightWords
        text={text}
        highlights={highlights}
        size={textSize}
        style={textStyle}
        accessibilityRole="alert"
      />
    </View>
  );
};

//
// ─── TYPES ──────────────────────────────────────────────────────────────────────
//

type FixedNoticeBannerProps = Readonly<{
  palette: NoticeBannerPalette;
  padding?: NoticeBannerPadding;
  text: string;
}> &
  Pick<ComponentProps<typeof HighlightWords>, 'text' | 'highlights'>;

type NoticeBannerProps = Readonly<{
  /**
   * Banner message/text.
   */
  text?: string;

  /**
   * React component that can be rendered as banner content.
   */
  ContentComponent?: () => ReactNode;
  palette?: NoticeBannerPalette;
  padding?: NoticeBannerPadding;
  onClose?: () => void;
  autoFocusCloseBtn?: boolean;
  closeBtnAccessibilityLabel?: string;
  closeBtnAccessibilityHint?: string;
  autoHideTimeout?: number | 'auto' | 'never';
  withCloseBtn?: boolean;
}> &
  Pick<ComponentProps<typeof HighlightWords>, 'highlights'>;

type UseToggleBannerProps = Pick<
  NoticeBannerProps,
  'text' | 'autoHideTimeout' | 'onClose'
>;

type UseA11YBannerProps = Readonly<{
  closeBtnRef: React.RefObject<Component>;
}> &
  Pick<NoticeBannerProps, 'text' | 'autoFocusCloseBtn'>;

type NoticeBannerPalette = 'success' | 'caution' | 'neutral' | 'cream';

//
// ─── CONSTANTS ──────────────────────────────────────────────────────────────────
//

const NOTICE_BANNER_CLOSE_BTN_A11Y_LABEL = 'Close';
const NOTICE_BANNER_CLOSE_BTN_A11Y_HINT = 'Closes notice banner';

type NoticeBannerPadding = Readonly<{
  top: number;
  right: number;
  bottom: number;
  left: number;
}>;

export const NOTICE_BANNER_PADDING: NoticeBannerPadding = {
  top: theme.spacing['6'],
  right: theme.spacing['4'],
  bottom: theme.spacing['6'],
  left: theme.spacing['4'],
};

const CLOSE_BTN_SIZE = 24;
const CLOSE_BTN_SPACER = theme.spacing['4'];
const PALETTES: Record<NoticeBannerPalette, string> = {
  neutral: theme.colors.LINEN,
  cream: theme.colors.CREAM,
  success: theme.colors.CUCUMBER,
  caution: theme.colors.PEACH,
};

const AVERAGE_WORDS_PER_MINUTE = 120;
const SLIDE_IN_ANIMATION_DISTANCE = 200;

//
// ─── STYLES ─────────────────────────────────────────────────────────────────────
//

const styles = StyleSheet.create({
  banner: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bannerXSmall: {
    justifyContent: 'space-between',
    borderTopWidth: 1,
    borderTopColor: theme.colors.GREEN_1,
  },
  bannerSmallUp: {
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.GREEN_1,
  },
  fixedBanner: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.GREEN_1,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.GREEN_1,
  },
  contentContainer: {
    flex: 1,
  },
  textContentContainer: {
    maxWidth: 600,
  },
  textBlockSM: {
    textAlign: 'center',
  },
  closeBtnWrapper: {
    marginLeft: CLOSE_BTN_SPACER,
    flexShrink: 0,
  },
});

const useBannerPaletteStyles = (palette: NoticeBannerPalette): ViewStyle => {
  return { backgroundColor: PALETTES[palette] };
};

const useBannerResponsiveStyles = () => {
  const { currentBreakpoint } = useResponsive();

  return currentBreakpoint.isXS ? styles.bannerXSmall : styles.bannerSmallUp;
};

const useBannerPaddingStyles = (padding: NoticeBannerPadding) => {
  return useStyle(
    () => ({
      paddingTop: padding.top,
      paddingBottom: padding.bottom,
      paddingLeft: padding.left,
      paddingRight: padding.right,
    }),
    [padding],
  );
};

type NoticeBannerSlideInDirection = 'from-top' | 'from-bottom';

const useBannerSlideInAnimation = (direction: NoticeBannerSlideInDirection) => {
  const reduceMotionEnabled = useReduceMotionStatus();
  const topOffset = useSharedValue(
    direction === 'from-top'
      ? -SLIDE_IN_ANIMATION_DISTANCE
      : SLIDE_IN_ANIMATION_DISTANCE,
  );
  const opacity = useSharedValue(0);

  useEffect(() => {
    if (reduceMotionEnabled === 'undetermined') return;

    topOffset.value = reduceMotionEnabled === 'disabled' ? withTiming(0) : 0;
    opacity.value = reduceMotionEnabled === 'disabled' ? withTiming(1) : 1;
  }, [opacity, topOffset, reduceMotionEnabled]);

  return useAnimatedStyle(() => {
    return {
      transform: [{ translateY: topOffset.value }],
      opacity: opacity.value,
    };
  });
};

const useResponsiveTextStyles = () => {
  const { minWidth } = useResponsive();

  const textSize: ComponentProps<typeof HighlightWords>['size'] = minWidth.isSM
    ? 3
    : 5;
  const textStyle = minWidth.isSM && styles.textBlockSM;

  return { textSize, textStyle };
};

//
// ─── HOOKS ──────────────────────────────────────────────────────────────────────
//

const useToggleBanner = ({
  autoHideTimeout,
  text,
  onClose,
}: UseToggleBannerProps) => {
  const [isBannerShown, setIsBannerShown] = useState(true);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>();
  const timeout =
    autoHideTimeout === 'auto' && text !== undefined
      ? calculateEstimatedReadingTimeInMs(text)
      : autoHideTimeout;

  useEffect(() => {
    if (timeout === undefined || timeout === 'never') return;

    const timeoutWithFallback = typeof timeout === 'number' ? timeout : 3000;

    timeoutRef.current = setTimeout(() => {
      setIsBannerShown(false);
      onClose?.();
    }, timeoutWithFallback);

    // clear timer on unmount or {timeout} property change
    return () => {
      if (!timeoutRef.current) return;
      clearTimeout(timeoutRef.current);
    };
  }, [onClose, timeout]);

  return [isBannerShown, setIsBannerShown];
};

const useA11yBanner = ({
  text,
  closeBtnRef,
  autoFocusCloseBtn,
}: UseA11YBannerProps) => {
  // announce the text on component mount for a11y purpose
  useEffect(() => {
    if (!text) return;

    AccessibilityInfo.announceForAccessibility(text);
  }, [text]);

  // optionally focus close button on component mount
  useEffect(() => {
    if (!autoFocusCloseBtn) return;
    focusOnElement(closeBtnRef);
  }, [autoFocusCloseBtn, closeBtnRef]);
};

//
// ─── HELPERS ────────────────────────────────────────────────────────────────────
//

/**
 * Returns estimated reading time of the provided text in milliseconds.
 */
export const calculateEstimatedReadingTimeInMs = (
  text: string,
  wordsPerMinute = AVERAGE_WORDS_PER_MINUTE,
) => {
  const wordsNumber = text.trim().split(/\s+/).length;
  const minimalWaitTime = 2000;

  // eslint-disable-next-line unicorn/numeric-separators-style
  return (wordsNumber / wordsPerMinute) * 60000 + minimalWaitTime;
};

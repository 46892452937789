import type { ComponentProps } from 'react';
import { useCallback, useMemo } from 'react';
import { getRoundedValueOfPercentage } from '@sg/garnish';

import type { TipsGroup } from '@order/components';
import type { useCart } from '@order/hooks';
import type { FeatureFlags } from '@order/LaunchDarkly';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

import { useCheckoutContext } from '../../../../../CheckoutProvider';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns checkout tips and helpers based on the active cart,
 * dynamic tip values, checkout payload, and other parameters.
 */
export const useCheckoutTips = () => {
  const dynamicTips = useFeatureFlag('CELS-1191-permanent-dynamic-tip-values');
  const { cart, cartOrderType } = useCheckoutContext();
  const { t, formatPrice } = useLocalizationContext();

  // ─────────────────────────────────────────────

  const orderSubtotal = cart?.ledger?.subtotal ?? 0;

  const tipsForOrderType = useMemo(
    () => getDynamicTipValues(dynamicTips, cartOrderType),
    [cartOrderType, dynamicTips],
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  /**
   * Returns a formatted tip item after doing multiple safety checks to
   * guarantee that the tip item is valid.
   */
  const safelyGetTip = useCallback(
    (dynamicTip: DynamicTipForOrderType): Tip | undefined => {
      const { value: tipValue } = dynamicTip ?? {};
      const hasValidTipValue =
        typeof tipValue === 'number' || tipValue === 'CUSTOM';

      if (!hasValidTipValue) return;

      // ─── "No Tip" option ─────────────────────────

      if (tipValue === 0) {
        return {
          value: '0',
          label: t('checkout.tips.no-tip-label'),
          amount: 0,
          accessibilityHint: t('checkout.tips.no-tip-a11y-hint'),
        };
      }

      // ─── "Custom" tip option ─────────────────────

      if (tipValue === 'CUSTOM') {
        return {
          value: 'custom',
          label: t('checkout.tip.custom'),
          accessibilityHint: t('checkout.tips.custom-tip-a11y-hint'),
        };
      }

      // ─── Standard tip option ─────────────────────

      const value = `${tipValue}`;
      const label = `${tipValue}%`;
      const amount = getRoundedValueOfPercentage(tipValue, orderSubtotal);
      const helperText = formatPrice(amount, 'USD');
      const accessibilityHint = t('checkout.tips.tip-a11y-hint', {
        tip: label,
        amount: helperText,
      });

      return { value, label, helperText, amount, accessibilityHint };
    },
    [formatPrice, orderSubtotal, t],
  );

  /**
   * Returns the default tip value if one is set, or the first available one.
   */
  const defaultTipValue = useMemo<string>(() => {
    const defaultTip =
      tipsForOrderType.find((tip) => tip?.isDefault) ?? tipsForOrderType[0];

    return defaultTip?.value ? `${defaultTip.value}` : '';
  }, [tipsForOrderType]);

  // ─── Derived data ────────────────────────────────────────────────────

  /**
   * Formatted valid tips.
   */
  const tips = useMemo<Tips>(() => {
    const tipsWithExtraDetails = tipsForOrderType.map(safelyGetTip);

    return tipsWithExtraDetails.filter(Boolean) as Tips;
  }, [safelyGetTip, tipsForOrderType]);

  // ─────────────────────────────────────────────────────────────────────

  return { orderSubtotal, tips, defaultTipValue };
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * Returns dynamic tips for the current order type (if any are available).
 */
function getDynamicTipValues(
  dynamicTips: DynamicTips,
  orderType: OrderType,
): DynamicTipsForOrderType {
  if (typeof dynamicTips !== 'object') return [];

  // ─────────────────────────────────────────────

  const { pickup: pickupTips, delivery: deliveryTips } = dynamicTips;

  const shouldReturnPickupTips =
    orderType === 'pickup' && pickupTips !== undefined;
  const shouldReturnDeliveryTips =
    orderType === 'delivery' && deliveryTips !== undefined;

  // ─────────────────────────────────────────────

  if (shouldReturnPickupTips) return pickupTips;

  if (shouldReturnDeliveryTips) return deliveryTips;

  return [];
}

// ─── Types ───────────────────────────────────────────────────────────────────

type Tips = ComponentProps<typeof TipsGroup>['tips'];

type Tip = Tips[number];

type OrderType = ReturnType<typeof useCart>['cartOrderType'];

type DynamicTips = FeatureFlags['CELS-1191-permanent-dynamic-tip-values'];

type DynamicTipsForOrderType = NonNullable<
  ValidDynamicTips['pickup'] | ValidDynamicTips['delivery']
>;

type DynamicTipForOrderType = DynamicTipsForOrderType[number];

type ValidDynamicTips = NonNullable<DynamicTips>;

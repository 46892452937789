import { defineMessages } from 'react-intl';

export const giftCardCheckoutDeliveryMethodMessages = defineMessages({
  sectionTitle: {
    defaultMessage: 'How would you like to send it?',
    description: 'Gift Card Checkout Form | Delivery Options | Section title',
  },
  sectionTitleEmailOnly: {
    defaultMessage: 'What is your recipient’s email?',
    description:
      'Gift Card Checkout Form | Delivery Options | Section title (Email only)',
  },
  messageOptionLabel: {
    defaultMessage: 'Message',
    description:
      'Gift Card Checkout Form | Delivery Options | "Message" option label',
  },
  emailOptionLabel: {
    defaultMessage: 'Email',
    description:
      'Gift Card Checkout Form | Delivery Options | "Email" option label',
  },
});

import { type LineItemRecommendation } from '@order/graphql';
import { useTrackEventEffect } from '@order/Telemetry';

// ────────────────────────────────────────────────────────────────────────────────

export const usePersonalizedMenuRecommendationsTelemetry = (
  props: PersonalizationMenuTelemetryProps,
) => {
  const { isFetching, hasRecommendations, recommendations, cardVariant } =
    props;

  useTrackEventEffect({
    name: 'menu.personalization.recommendations.success',
    skip: isFetching || !hasRecommendations,
    payload: {
      recommendations,
      numRecommendations: recommendations?.length,
      cardVariant,
    },
  });

  useTrackEventEffect({
    name: 'menu.personalization.recommendations.failure',
    skip: isFetching || hasRecommendations,
    payload: { systemError: 'Unable to fetch recommendations.' },
  });
};

type PersonalizationMenuTelemetryProps = Readonly<{
  isFetching: boolean;
  hasRecommendations: boolean;
  recommendations?: readonly LineItemRecommendation[];
  cardVariant: string;
}>;

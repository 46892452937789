import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { uniqBy } from 'lodash';
import {
  BodyText,
  Button,
  HStack,
  IllusEmployeeBag,
  Image,
  theme,
  useResponsive,
} from '@sg/garnish';

import {
  AccountFavoritesLoading,
  AccountScreenContainer,
  AccountScreenSection,
  AccountScreenTitle,
  SharedLineItem,
} from '@order/components';
import { type PartialLineItem } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { useFavoriteLineItemMutation } from '../OrderStatusScreen/graphql/OrderStatus.generated';
import { useReorderLineItem } from '../ReorderingScreen/hooks';
import type { FavoritesQuery } from './GraphQL/Favorites.generated';
import { useFavoritesQuery } from './GraphQL/Favorites.generated';

export const FavoritesScreen = (): React.ReactElement => {
  const { t } = useLocalizationContext();
  const { favorites, canLoadMore, isLoading, handleLoadMore, toggleFavorite } =
    usePaginatedFavorites();

  // ─── Styles ──────────────────────────────────────────────────────────

  const { match } = useResponsive();
  const screenSectionStyles = match([undefined, styles.screenSectionSM]);

  // ─────────────────────────────────────────────────────────────────────

  if (isLoading && favorites.length === 0) return <AccountFavoritesLoading />;

  return (
    <AccountScreenContainer screen="favorites">
      <AccountScreenTitle title={t('account.favorites.title')} />
      <AccountScreenSection style={screenSectionStyles}>
        <FavoritesScreenContent
          favorites={favorites}
          canLoadMore={canLoadMore}
          isLoading={isLoading}
          handleLoadMore={handleLoadMore}
          toggleFavorite={toggleFavorite}
        />
      </AccountScreenSection>
    </AccountScreenContainer>
  );
};

export const FavoritesScreenContent = (
  props: ReturnType<typeof usePaginatedFavorites>,
) => {
  const { favorites, canLoadMore, isLoading, handleLoadMore, toggleFavorite } =
    props;

  const { t } = useLocalizationContext();
  const itemsPerRow = useItemsPerRow();

  if (!isLoading && favorites.length === 0) return <EmptyState />;

  return (
    <>
      <HStack itemsPerRow={itemsPerRow} style={styles.favoritesStack}>
        {favorites.map((favorite) => (
          <ConnectedFavorite
            key={favorite.id}
            favorite={favorite}
            toggleFavorite={toggleFavorite}
          />
        ))}
      </HStack>

      {favorites.length > 0 && canLoadMore ? (
        <Button
          testID="account.favorites.load-more"
          palette="secondary"
          accessibilityRole="button"
          accessibilityLabel={t('account.favorites.load-more')}
          accessibilityHint={t('account.favorites.load-more')}
          style={styles.loadMoreButton}
          isLoading={isLoading}
          onPress={handleLoadMore}
        >
          {t('account.favorites.load-more')}
        </Button>
      ) : null}
    </>
  );
};

const ConnectedFavorite = (props: ConnectedFavoriteProps) => {
  const { favorite, toggleFavorite } = props;

  const startReorderLineItem = useReorderLineItem(favorite);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onFavorite = useCallback(async () => {
    await toggleFavorite(favorite);
  }, [favorite, toggleFavorite]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <SharedLineItem
      lineItem={favorite as PartialLineItem}
      onAddToBag={startReorderLineItem}
      onFavorite={onFavorite}
    />
  );
};

const EmptyState = () => {
  const { t } = useLocalizationContext();

  return (
    <View style={styles.emptyContainer}>
      <Image
        source={IllusEmployeeBag}
        style={styles.emptyImage}
        aria-label={t('account.favorites.illustration.alt')}
      />
      <BodyText style={styles.noResultsText}>
        {t('account.favorites.empty')}
      </BodyText>
    </View>
  );
};

const usePaginatedFavorites = () => {
  const [page, setPage] = React.useState(0);
  const [favorites, setFavorites] = React.useState<Favorites>([]);

  const variables = { input: { page } };

  const isScreenFocused = useIsFocused();

  const [response, fetchFavorites] = useFavoritesQuery({
    variables,
    pause: !isScreenFocused,
    requestPolicy: 'cache-and-network',
  });
  const [, favoriteLineItem] = useFavoriteLineItemMutation();

  const isLoading = response.fetching;

  // ─── Derived Data ────────────────────────────────────────────────────

  const favoritedLineItems = useMemo(
    () => response.data?.favoritedLineItems ?? FAVORITES_LIST_FALLBACK,
    [response?.data?.favoritedLineItems],
  );

  const currentFavorites = useRef(favorites);

  // eslint-disable-next-line functional/immutable-data
  currentFavorites.current = favorites;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleLoadMore = React.useCallback(() => {
    setPage(Number(page) + 1);
    fetchFavorites();
  }, [page, fetchFavorites, setPage]);

  const toggleFavorite = useCallback(
    async (lineItem: PartialLineItem) => {
      const { id, product, favorited } = lineItem;

      const hasMissingData =
        favorited === undefined || id === undefined || product.id === undefined;

      if (hasMissingData) return;

      const { data } = await favoriteLineItem({
        input: {
          lineItemId: id,
          productId: product.id,
          favorited: !favorited,
        },
      });

      const updatedLineItem = data?.favoriteLineItem;

      if (updatedLineItem?.__typename !== 'LineItem') return;

      setFavorites((currentList) => {
        return currentList.map((item) => {
          if (item.id === updatedLineItem.id) {
            return { ...item, favorited: updatedLineItem.favorited };
          }

          return item;
        });
      });
    },
    [favoriteLineItem],
  );

  // ─── Effects ─────────────────────────────────────────────────────────

  // NOTE: Reset state on focus
  useFocusEffect(
    useCallback(() => {
      setFavorites(FAVORITES_LIST_FALLBACK);
      setPage(0);
    }, []),
  );

  // Update favorites list
  useLayoutEffect(() => {
    if (!isScreenFocused || isLoading) return;

    const updatedItems = new Map(
      favoritedLineItems.map((item) => [item.id, item]),
    );

    setFavorites((currentList) => {
      const allItems = uniqBy([...currentList, ...favoritedLineItems], 'id');

      return allItems.map((item) => {
        const updatedItem = updatedItems.get(item.id);
        const favorited = updatedItem?.favorited ?? item.favorited;

        return { ...item, favorited };
      });
    });
  }, [favoritedLineItems, isLoading, isScreenFocused]);

  const canLoadMore = favorites.length / (page + 1) === FAVORITES_PER_PAGE;

  // ─────────────────────────────────────────────────────────────────────

  return { favorites, canLoadMore, isLoading, handleLoadMore, toggleFavorite };
};

const useItemsPerRow = () => {
  const { match } = useResponsive();

  return match([1, 1, 2]);
};

const styles = StyleSheet.create({
  screenSectionSM: {
    maxWidth: 710,
  },
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyImage: {
    width: 220,
    height: 220,
    marginBottom: theme.spacing['4'],
  },
  noResultsText: {
    textAlign: 'center',
  },
  loadMoreButton: {
    minWidth: 200,
    maxWidth: 200,
    marginHorizontal: 'auto',
    marginTop: theme.spacing['6'],
  },
  favoritesStack: {
    marginTop: theme.spacing['4'],
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const FAVORITES_PER_PAGE = 10;
const FAVORITES_LIST_FALLBACK: Favorites = [];

// ─── Types ───────────────────────────────────────────────────────────────────

type Favorites = FavoritesQuery['favoritedLineItems'];

type ConnectedFavoriteProps = {
  favorite: ReturnType<typeof usePaginatedFavorites>['favorites'][number];
  toggleFavorite: (lineItem: PartialLineItem) => Promise<void>;
};

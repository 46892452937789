import React from 'react';
import type { PressableProps, ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { IconLink } from '../../../Icon';
import type { PaletteProps } from '../../Modal.types';

export const ModalFloatingCloseBtn = (props: ModalFloatingCloseBtnProps) => {
  const { style, palette, onPress } = props;
  const buttonStyles = [styles.closeBtnFloating, style];
  const paletteSpecificColor = palette ? iconColors[palette] : undefined;

  return (
    <IconLink
      palette="muted"
      width={40}
      height={40}
      style={buttonStyles}
      color={paletteSpecificColor}
      name="IconClose"
      onPress={onPress}
      accessibilityLabel="Close dialog"
      accessibilityRole="imagebutton"
    />
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type ModalFloatingCloseBtnProps = Readonly<{
  style?: ViewProps['style'];
  onPress: PressableProps['onPress'];
}> &
  PaletteProps;

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  closeBtnFloating: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: theme.spacing['4'],
    zIndex: theme.zIndex.popover,
  },
});

// ─── Palette Styles ────────────────────────────────────────────────────

const iconColors = {
  primary: undefined,
  muted: theme.colors.BLACK,
  neutral: theme.colors.BLACK,
  secondary: theme.colors.WHITE,
  lime: theme.colors.WHITE,
};

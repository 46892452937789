export const home = {
  'home.greeting.signedOut': 'Bienvenidos',
  'test.plural': 'Lechuga',
  'sidebar.header.heading': 'Vamos a cocinar',
  'sidebar.header.heading-with-variable': 'Vamos a {var}',
  'sidebar.header.heading-with-multiple-variables': '{var1} a {var2}',
  'sidebar.header.sub-heading': '¿Cómo le gustaría que su Sweetgreen hoy?',
  'sidebar.header.login-or-sign-up': 'Login / Unirse',
  'sidebar.search.pickup-or-outpost': 'RECOGER + OUTPOST',
  'sidebar.search.search-option.delivery': 'ENTREGA',
  'sidebar.search.search-option.placeholder':
    'Buscar tienda, ciudad, estado o código postal',
  'home.hero-cta': 'Explorar Menú',
};

/* eslint-disable functional/immutable-data */

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import {
  runOnJS,
  useAnimatedGestureHandler,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';

import { PICKER_OPTION_HEIGHT } from './Picker.constants';
import type {
  GestureHandler,
  PickerOptionValue,
  UsePickerGesturesEventsHandlerProps,
  UsePickerHelpersProps,
} from './Picker.types';
import {
  calculateMomentumScrollDistance,
  findClosestOptionIndex,
  getOptionLayoutCoordinates,
  triggerHaptic,
} from './Picker.utils';

// ────────────────────────────────────────────────────────────────────────────────

export const usePickerState = (props: UsePickerHelpersProps) => {
  const { value, onSubmit, options } = props;

  const [selectedOptionValue, setSelectedOptionValue] =
    useState<PickerOptionValue>(value);

  const handleOnSubmit = useCallback(() => {
    onSubmit(selectedOptionValue);
  }, [onSubmit, selectedOptionValue]);

  const resetSelectedOptionValue = useCallback(() => {
    setSelectedOptionValue(value);
  }, [value]);

  // ────────────────────────────────────────────────────────────────────

  // {selectedOptionLabel} will be changed only after {onSubmit} event
  const selectedOptionLabel =
    options.find((option) => option.value === value)?.label ?? '';

  // ────────────────────────────────────────────────────────────────────

  // sync external and local values
  useEffect(() => {
    setSelectedOptionValue(value);
  }, [value]);

  // ────────────────────────────────────────────────────────────────────

  return {
    selectedOptionValue,
    selectedOptionLabel,
    setSelectedOptionValue,
    handleSubmit: handleOnSubmit,
    resetSelectedOptionValue,
  };
};

// ────────────────────────────────────────────────────────────────────────────────

export const usePickerGesturesEventsHandler = (
  props: UsePickerGesturesEventsHandlerProps,
) => {
  const { options, optionsWrapperRef, activeOptionIndex, onChange } = props;
  const optionsCoordinates = useMemo(
    () => options.map(getOptionLayoutCoordinates),
    [options],
  );

  // ─── DYNAMIC VALUES ─────────────────────────────────────────────────

  const [isInteractionInAction, setIsInteractionInAction] = useState(false);
  const [hoveredOptionIndex, setHoveredOptionIndex] =
    useState(activeOptionIndex);

  const initialSwipeDistance = -(activeOptionIndex * PICKER_OPTION_HEIGHT);
  const swipedDistance = useSharedValue(initialSwipeDistance);

  // ─── HELPERS ────────────────────────────────────────────────────────

  // As soon as the element is focused, the browser scrolls to that element's position.
  // Therefore, we must reset scroll position, since we "focus" the element by modifying {translateY} property
  // of the wrapper and not the scroll position.
  const restoreWrapperScrollOnFocus = useCallback(() => {
    const wrapper = optionsWrapperRef?.current as unknown as HTMLElement;

    if (Platform.OS !== 'web' || !wrapper) return;
    wrapper?.scrollTo?.({ top: 0 });
  }, [optionsWrapperRef]);

  const selectOptionByIndex = useCallback(
    (optionIndex: number) => {
      const optionLayoutStartCoordinate =
        optionsCoordinates?.[optionIndex]?.start;
      const shouldPreventFurtherActions =
        optionLayoutStartCoordinate === undefined || isInteractionInAction;

      if (shouldPreventFurtherActions) return;

      swipedDistance.value = withTiming(-optionLayoutStartCoordinate, {
        duration: 150,
      });

      triggerHaptic();
      onChange(options[optionIndex].value);
      restoreWrapperScrollOnFocus();
    },
    [
      onChange,
      isInteractionInAction,
      options,
      optionsCoordinates,
      restoreWrapperScrollOnFocus,
      swipedDistance,
    ],
  );

  const trackPreviousSwipeDistance = useCallback<GestureHandler>(
    (_, ctx) => {
      'worklet';

      ctx.offsetY = swipedDistance.value;
    },
    [swipedDistance.value],
  );

  const trackActiveSwipeDistance = useCallback<GestureHandler>(
    ({ translationY }, ctx) => {
      'worklet';

      const swipeDistance = ctx.offsetY + translationY;
      const closestOptionIndex = findClosestOptionIndex(
        -swipeDistance,
        optionsCoordinates,
      );

      swipedDistance.value = swipeDistance;

      runOnJS(setIsInteractionInAction)(true);
      runOnJS(setHoveredOptionIndex)(closestOptionIndex);
    },
    [optionsCoordinates, swipedDistance],
  );

  const swipeToTheClosestOption = useCallback<GestureHandler>(
    ({ translationY: currentDistance, velocityY }, ctx) => {
      'worklet';

      const previousDistance = ctx.offsetY;
      const momentumScrollDistance = calculateMomentumScrollDistance(velocityY);
      const targetSwipeDistance = -(
        previousDistance +
        currentDistance +
        momentumScrollDistance
      );

      const closestOptionIndex = findClosestOptionIndex(
        targetSwipeDistance,
        optionsCoordinates,
        true,
      );
      const closestOptionValue = options[closestOptionIndex].value;
      const closestOptionStartCoordinate =
        optionsCoordinates[closestOptionIndex].start;

      // ────────────────────────────────────────────────────────────────────

      const springConfig = { damping: 5000, velocity: velocityY };

      swipedDistance.value = withSpring(
        -closestOptionStartCoordinate,
        springConfig,
      );

      runOnJS(setIsInteractionInAction)(false);
      runOnJS(onChange)(closestOptionValue);
    },
    [optionsCoordinates, options, swipedDistance, onChange],
  );

  const animatedGestureHandler = useAnimatedGestureHandler(
    {
      onStart: trackPreviousSwipeDistance,
      onActive: trackActiveSwipeDistance,
      onEnd: swipeToTheClosestOption,
    },
    [optionsCoordinates],
  );

  // ─── EFFECTS ────────────────────────────────────────────────────────

  useEffect(triggerHaptic, [hoveredOptionIndex]);

  // ────────────────────────────────────────────────────────────────────

  return useMemo(
    () => ({
      swipedDistance,
      animatedGestureHandler,
      selectOptionByIndex,
    }),
    [swipedDistance, animatedGestureHandler, selectOptionByIndex],
  );
};

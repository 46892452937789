import { account } from './account';
import { addresses } from './addresses';
import { bag } from './bag';
import { challenges } from './challenges';
import { checkout } from './checkout';
import { creditCardForm } from './credit-card-form';
import { date } from './date';
import { delivery } from './delivery';
import { dietaryRestrictions } from './dietary-restrictions';
import { errors } from './errors';
import { floatingButtons } from './floating-buttons';
import { footer } from './footer';
import { forcedAppUpdate } from './forced-app-update';
import { form } from './form';
import { general } from './general';
import { home } from './home';
import { join } from './join';
import { location } from './location';
import { login } from './login';
import { menu } from './menu';
import { menuCollection } from './menu-collection';
import { menuPersonalization } from './menu-personalization';
import { navbar } from './navbar';
import { notFoundView } from './not-found-view';
import { orderProduct } from './order-product';
import { orderStatus } from './order-status';
import { orderStatusInfo } from './order-status-info';
import { otaUpdate } from './ota-update';
import { pageTitles } from './page-titles';
import { personalData } from './personal-data';
import { product } from './product';
import { rateOrder } from './rate-order';
import { redeemGiftCardForm } from './redeem-gift-card-form';
import { reorder } from './reorder';
import { scanInStore } from './scan-in-store';
import { scanToPay } from './scan-to-pay';
import { signedOutView } from './signed-out-view';
import { sweetpass } from './sweetpass';
import { sweetpassMembership } from './sweetpass-membership';
import { sweetpassMembershipPlan } from './sweetpass-membership-plan';

export const enUs = {
  ...general,
  ...date,
  ...account,
  ...addresses,
  ...bag,
  ...checkout,
  ...creditCardForm,
  ...delivery,
  ...home,
  ...menu,
  ...menuCollection,
  ...menuPersonalization,
  ...location,
  ...login,
  ...join,
  ...product,
  ...personalData,
  ...challenges,
  ...navbar,
  ...errors,
  ...footer,
  ...form,
  ...dietaryRestrictions,
  ...orderStatusInfo,
  ...orderStatus,
  ...otaUpdate,
  ...pageTitles,
  ...rateOrder,
  ...redeemGiftCardForm,
  ...reorder,
  ...scanInStore,
  ...scanToPay,
  ...sweetpass,
  ...sweetpassMembership,
  ...sweetpassMembershipPlan,
  ...notFoundView,
  ...signedOutView,
  ...orderProduct,
  ...forcedAppUpdate,
  ...floatingButtons,
};

// We only need the keys from en-US since we
// are making the assumption that all languages
// will be using the same keys in this early
// naive implementation
export type TranslationKeys = keyof typeof enUs;

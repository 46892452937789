/* eslint-disable functional/immutable-data */

import { getEnvVars } from '@order/utils';

import { onetrustStyles } from './styles';

export const loadOneTrust = () => {
  // NOTE: If the `HideCookiesBanner` cookie is present, we should not load the
  //       OneTrust to prevent the banner from showing up.
  const shouldPreventLoading = document.cookie.includes('HideCookiesBanner');

  if (shouldPreventLoading) return;

  const scripts = getScripts();

  scripts.map((script) =>
    document.head.insertBefore(script, document.head.childNodes[0]),
  );

  const style = document.createElement('style');

  style.textContent = onetrustStyles;

  document.head.append(style);
};

const getScripts = () => {
  const { ONE_TRUST_SCRIPT_DOMAIN_KEY = '' } = getEnvVars();

  const cookieConsentScript = document.createElement('script');

  cookieConsentScript.src = `https://cdn.cookielaw.org/consent/${ONE_TRUST_SCRIPT_DOMAIN_KEY}/OtAutoBlock.js`;

  const templateScript = document.createElement('script');

  templateScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  templateScript.dataset.domainScript = `${ONE_TRUST_SCRIPT_DOMAIN_KEY}`;

  const optAnonWrapperScript = document.createElement('script');
  const code = 'function OptanonWrapper() { }';

  optAnonWrapperScript.append(document.createTextNode(code));

  return [optAnonWrapperScript, templateScript, cookieConsentScript];
};

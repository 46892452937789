import {
  eachHourOfInterval,
  endOfDay,
  format,
  max,
  min,
  startOfDay,
} from 'date-fns';

/**
 * Returns hour options of the provided date.
 */
export function getHoursOfDate(params: GetHoursOfDateParams): HourOption[] {
  const { minDate, maxDate, date } = params;

  const startOfDate = max([startOfDay(date), minDate]);
  const endOfDate = min([endOfDay(date), maxDate]);

  const hourOptionDates = eachHourOfInterval({
    start: startOfDate,
    end: endOfDate,
  });

  return hourOptionDates.map((optionDate) => ({
    value: optionDate.toISOString(),
    label: format(optionDate, 'h:mmaaa'),
  }));
}

// ─── Types ───────────────────────────────────────────────────────────────────

type GetHoursOfDateParams = {
  date: Date;
  minDate: Date;
  maxDate: Date;
};

type HourOption = {
  value: string; // ISO Date String
  label: string;
};

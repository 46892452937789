/* eslint-disable react/no-array-index-key */

import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../hooks';
import { slugify } from '../../utils';
import { HorizontalScrollRail } from '../HorizontalScrollRail';
import { LoadingPlaceholder } from '../LoadingPlaceholder';
import { RewardCard } from '../RewardCard';
import { getRewardIllustrationByPalette } from './RewardsRail.utils';

// ────────────────────────────────────────────────────────────────────────────────

export const RewardsRail = (props: RewardsRailProps) => {
  const {
    isLoading,
    headerText,
    subtitle,
    headerVariation,
    outerOffset,
    rewards,
    withoutHeaderBorder,
  } = props;

  if (isLoading) {
    return (
      <RewardsRailLoading
        headerText={headerText}
        subtitle={subtitle}
        headerVariation={headerVariation}
        outerOffset={outerOffset}
        withoutHeaderBorder={withoutHeaderBorder}
        testID="rewards-rail.loading"
      />
    );
  }

  if (!rewards?.length) return null;

  return <RewardsRailFulfilled {...props} />;
};

// ─── TEMPLATES ──────────────────────────────────────────────────────────────────

const RewardsRailLoading = (props: RewardsRailBaseProps) => {
  const loadingPlaceholders = Array.from({ length: 3 });

  const { match } = useResponsive();
  const railLoadingSpaceStyle = match([
    styles.railLoadingSpaceXS,
    styles.railLoadingSpaceSM,
  ]);

  return (
    <>
      <RewardsRailBase scrollEnabled={false} showNavControls={false} {...props}>
        {loadingPlaceholders.map((_, index) => (
          <RewardsRailItemLoading key={index} />
        ))}
      </RewardsRailBase>

      <View style={railLoadingSpaceStyle} />
    </>
  );
};

const RewardsRailFulfilled = (props: Omit<RewardsRailProps, 'isLoading'>) => {
  const {
    rewards,
    selectedRewardId,
    headerText,
    footerText,
    onPress,
    ...restProps
  } = props;

  const { minWidth } = useResponsive();

  return (
    <RewardsRailBase
      headerText={headerText}
      footerText={footerText}
      showNavControls={minWidth.isSM}
      {...restProps}
    >
      {rewards?.map((reward, index) => {
        const { id, palette = 'quinoa' } = reward;

        const isSelected = selectedRewardId === id;
        const imageSource = getRewardIllustrationByPalette(palette, index);

        return (
          <RewardsRailItem
            key={id}
            reward={reward}
            onPress={onPress}
            isSelected={isSelected}
            imageSource={imageSource}
          />
        );
      })}
    </RewardsRailBase>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const RewardsRailBase = (props: RewardsRailBaseProps) => {
  const {
    headerText,
    subtitle,
    footerText,
    children,
    outerOffset = -theme.spacing['6'],
    ...restProps
  } = props;

  const { minWidth } = useResponsive();

  return (
    <HorizontalScrollRail
      heading={headerText}
      subtitle={subtitle}
      footerText={footerText}
      showNavControls={minWidth.isSM}
      gap={theme.spacing['4']}
      outerOffset={outerOffset}
      itemVisiblePercentThreshold={99}
      countPosition={minWidth.isSM ? 'left' : 'right'}
      {...restProps}
    >
      {children as readonly React.ReactNode[]}
    </HorizontalScrollRail>
  );
};

const RewardsRailItem = (props: RewardsRailItemProps) => {
  const { reward, imageSource, isSelected, onPress } = props;
  const { id, name, extraDetails, palette, tag, isDisabled } = reward;

  const rewardNameSlug = slugify(name);
  const rewardCardTestID = isSelected
    ? `reward-card-selected-${rewardNameSlug}`
    : `reward-card-${rewardNameSlug}`;

  return (
    <View style={styles.railItem}>
      <RewardCard
        testID={rewardCardTestID}
        id={id}
        description={name}
        tag={tag}
        onPress={onPress}
        imageSource={imageSource}
        isSelected={isSelected}
        isDisabled={isDisabled}
        extraDetails={extraDetails}
        palette={palette}
      />
    </View>
  );
};

const RewardsRailItemLoading = () => {
  return (
    <View style={styles.railItem}>
      <LoadingPlaceholder rowHeight={120} palette="cream" />
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  railLoadingSpaceXS: {
    height: 26,
  },
  railLoadingSpaceSM: {
    height: 34,
  },
  railItem: {
    width: 310,
    minHeight: 120,
    marginVertical: theme.spacing['1'],
    flex: 1,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type RewardsRailProps = Readonly<{
  rewards?: readonly Reward[];
  selectedRewardId?: Reward['id'];
  onPress?: (rewardId: Reward['id']) => void;
  isLoading?: boolean;
}> &
  Pick<RewardsRailBaseProps, 'headerText' | 'subtitle' | 'footerText'> &
  Pick<
    ComponentProps<typeof HorizontalScrollRail>,
    | 'initialScrollIndex'
    | 'headerVariation'
    | 'outerOffset'
    | 'withoutHeaderBorder'
    | 'count'
  >;

type RewardsRailBaseProps = Readonly<{
  headerText: string;
  footerText?: string;
}> &
  Partial<ComponentProps<typeof HorizontalScrollRail>>;

type RewardsRailItemProps = Readonly<{
  reward: Reward;
}> &
  Pick<RewardCardProps, 'isSelected' | 'imageSource' | 'onPress'>;

type Reward = Readonly<{
  id: string;
  name: string;
}> &
  Pick<RewardCardProps, 'palette' | 'extraDetails' | 'tag' | 'isDisabled'>;

type RewardCardProps = ComponentProps<typeof RewardCard>;

import React, { useLayoutEffect } from 'react';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { COLORS } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { Icon } from '../../Icon';

export const CollapseIndicatorIcon = (props: IconProps) => {
  const { expanded, iconName, iconColor, iconStrokeWidth, ...restProps } =
    props;

  const { currentBreakpoint } = useResponsive();
  const expandedAnimatedValue = useSharedValue(0);
  const animatedStyles = useAnimatedStyle(() => {
    const rotateDegree = interpolate(
      expandedAnimatedValue.value,
      [0, 1],
      [0, 180],
    );

    return { transform: [{ rotate: `${rotateDegree}deg` }] };
  }, []);

  // rotate icon on {expanded} prop change
  useLayoutEffect(() => {
    // eslint-disable-next-line functional/immutable-data
    expandedAnimatedValue.value = withTiming(Number(expanded), {
      duration: 200,
    });
  }, [expanded, expandedAnimatedValue]);

  const dynamicIcon = currentBreakpoint.isXS
    ? 'IconCaretRight'
    : 'IconCaretDown';

  return (
    <Animated.View style={animatedStyles} testID="sg-collapse-indicator-icon">
      <Icon
        name={iconName === undefined ? dynamicIcon : iconName}
        color={iconColor ?? COLORS.NEUTRAL_2}
        strokeWidth={iconStrokeWidth ?? 1}
        width={24}
        height={24}
        {...restProps}
      />
    </Animated.View>
  );
};

//
// ─── TYPES ──────────────────────────────────────────────────────────────────────
//

type IconProps = Readonly<{
  expanded: boolean;
  iconName?: React.ComponentProps<typeof Icon>['name'];
  iconColor?: React.ComponentProps<typeof Icon>['color'];
  iconStrokeWidth?: React.ComponentProps<typeof Icon>['strokeWidth'];
}> &
  Omit<React.ComponentProps<typeof Icon>, 'name' | 'color' | 'strokeWidth'>;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { theme } from '@garnish/constants';

import { useIsLoggedIn } from '@order/AuthMachine';
import { Rail, ReorderRailHeader } from '@order/components';
import { useOrders } from '@order/Order';

export const HomeReorderRailLoader = () => {
  const isScreenFocused = useIsFocused();
  const isLoggedIn = useIsLoggedIn();

  const { orders, isFetchingOrders, hasErrorOrders } = useOrders({
    pause: !isScreenFocused || !isLoggedIn,
  });

  return (
    <View style={styles.wrapper}>
      <ReorderRailHeader
        hasOrders={orders.length > 0}
        isLoading={isFetchingOrders}
      />

      <Rail
        orders={orders}
        isLoading={isFetchingOrders}
        hasError={hasErrorOrders}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    flexGrow: 1,
    backgroundColor: theme.colors.AVOCADO,
    paddingVertical: theme.spacing['10'],
  },
});

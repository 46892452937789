import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { ViewStyle } from 'react-native';
import {
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import { useSharedValue } from 'react-native-reanimated';
import { theme } from '@garnish/constants';
import { nanoid } from 'nanoid/non-secure';

import { useCallbackIfMounted, usePressableState } from '../../hooks';
import { AutomaticallyPlacedView, useAutomaticPlacement } from '../../hooks';
import { FadeView } from '../FadeView';
import { Overlay } from '../Overlay';
import { BodyText } from '../Text';
import type {
  TooltipPlacement,
  TooltipProps,
  WithTooltipProps,
} from './WithTooltip.types';
import { checkIfHasPointerDevice } from './WithTooltip.utils';

// ────────────────────────────────────────────────────────────────────────────────

export const WithTooltip = ({
  children,
  text,
  triggerMode = 'press',
  placement = 'top',
  toggleAccessibilityLabel = 'Toggle tooltip',
  testID = 'sg-with-tooltip-toggle',
  offsetX = 0,
  offsetY = 0,
  autoDismissTimeout,
  onShow,
}: WithTooltipProps) => {
  const tooltipNativeID = useMemo(() => `tooltip-${nanoid()}`, []);
  const shouldShowTooltipOnHover =
    checkIfHasPointerDevice() && triggerMode === 'hover';

  const toggleRef = useRef<TouchableOpacity>(null);
  const tooltipRef = useRef<View>(null);

  const windowDimensions = useWindowDimensions();
  const togglePressableState = usePressableState(toggleRef);

  const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
  const [currentPlacement, setCurrentPlacement] = useState(placement);

  //
  // ─── HELPERS ──────────────────────────────────────────────────────────────────
  //

  // Reference to clear timeout in case the component unmounts.
  const autoDismissTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const dismissTooltip = useCallbackIfMounted(() => {
    setShouldShowTooltip(false);
  });

  const toggleTooltip = useCallback(() => {
    setShouldShowTooltip((current) => !current);

    // Automatically dismiss tooltip after some time.
    if (autoDismissTimeout) {
      // eslint-disable-next-line functional/immutable-data
      autoDismissTimeoutRef.current = setTimeout(
        dismissTooltip,
        autoDismissTimeout,
      );
    }
  }, [autoDismissTimeout, dismissTooltip]);

  // Clear possible timeout if the component unmounts.
  useEffect(() => {
    return () => {
      if (autoDismissTimeoutRef.current) {
        clearTimeout(autoDismissTimeoutRef.current);
      }
    };
  }, []);

  // automatic tooltip placement
  const placementX = useSharedValue(0);
  const placementY = useSharedValue(0);
  const {
    updatePlacement: updateTooltipLocation,
    updatePlacementDebounced: updateTooltipLocationDebounced,
  } = useAutomaticPlacement({
    placementX,
    placementY,
    // @ts-expect-error TS(2322): Type 'RefObject<TouchableOpacity>' is not assignab... Remove this comment to see the full error message
    triggerRef: toggleRef,
    contentRef: tooltipRef,
    placement,
    setCurrentPlacement,
  });

  //
  // ─── EFFECTS ──────────────────────────────────────────────────────────────────
  //

  // sync the tooltip's placement if it has been changed externally
  useEffect(() => {
    setCurrentPlacement(placement);
    updateTooltipLocation();
  }, [placement, updateTooltipLocation]);

  useLayoutEffect(() => {
    if (!shouldShowTooltip) return;
    updateTooltipLocationDebounced();
  }, [
    updateTooltipLocationDebounced,
    shouldShowTooltip,
    windowDimensions.width,
    windowDimensions.height,
  ]);

  // show/hide tooltip on hover/focus (web)
  useEffect(() => {
    if (!shouldShowTooltipOnHover) return;
    setShouldShowTooltip(togglePressableState.isHovered);
  }, [togglePressableState.isHovered, shouldShowTooltipOnHover]);

  useEffect(() => {
    if (shouldShowTooltip) onShow?.();
  }, [onShow, shouldShowTooltip]);

  // ──────────────────────────────────────────────────────────────────────────────

  return (
    <>
      {/* ========== toggle ========== */}
      <TouchableOpacity
        ref={toggleRef}
        style={styles.toggle}
        testID={testID}
        onLayout={updateTooltipLocation}
        onPress={toggleTooltip}
        accessibilityRole="button"
        accessibilityLabel={toggleAccessibilityLabel}
        // @ts-expect-error TS(2769): No overload matches this call.
        accessibilityDescribedBy={
          shouldShowTooltip ? tooltipNativeID : undefined
        }
      >
        {children}
      </TouchableOpacity>

      {/* ========== tooltip ========== */}
      <Overlay
        // @ts-expect-error TS(2322): Type 'RefObject<TouchableOpacity>' is not assignab... Remove this comment to see the full error message
        triggerRef={toggleRef}
        contentRef={tooltipRef}
        dismiss={dismissTooltip}
        show={shouldShowTooltip}
      >
        <AutomaticallyPlacedView
          placementX={placementX}
          placementY={placementY}
          offsetX={offsetX}
          offsetY={offsetY}
          style={styles.tooltipWrapper}
        >
          <Tooltip
            ref={tooltipRef}
            text={text}
            placement={currentPlacement}
            onLayout={updateTooltipLocationDebounced}
            nativeID={tooltipNativeID}
            shouldShowTooltip={shouldShowTooltip}
          />
        </AutomaticallyPlacedView>
      </Overlay>
    </>
  );
};

//
// ─── SUB-COMPONENTS ─────────────────────────────────────────────────────────────
//

export const Tooltip = React.memo(
  React.forwardRef<View, TooltipProps>((props, ref) => {
    const { shouldShowTooltip, placement, text, nativeID, onLayout } = props;

    return (
      <FadeView show={shouldShowTooltip} duration={100}>
        <View
          style={[
            styles.tooltipWrapperInner,
            TooltipWrapperLayoutStyles[placement],
          ]}
          onLayout={onLayout}
          ref={ref}
        >
          <View style={[styles.tooltipElement, styles.tooltipTextWrapper]}>
            <BodyText
              size={4}
              nativeID={nativeID}
              // @ts-expect-error TS(2322): Type '{ children: string; size: number; nativeID: ... Remove this comment to see the full error message
              accessibilityHidden={!shouldShowTooltip}
              testID="sg-with-tooltip-text"
            >
              {text}
            </BodyText>
          </View>

          <TooltipArrow placement={placement} />
        </View>
      </FadeView>
    );
  }),
);

// ────────────────────────────────────────────────────────────────────────────────

export const TooltipArrow = ({
  placement,
}: Required<Pick<WithTooltipProps, 'placement'>>) => {
  const layoutStyles = ArrowLayoutStyles[placement];

  return (
    <View
      testID="sg-tooltip-arrow"
      style={[styles.tooltipElement, styles.arrow, layoutStyles]}
      pointerEvents="none"
    />
  );
};

//
// ─── CONSTANTS ──────────────────────────────────────────────────────────────────
//

const TOOLTIP_OFFSET = 10;
const TOOLTIP_CONTENT_MAX_WIDTH = 180;

const TOOLTIP_ARROW_SIZE = 14;
const TOOLTIP_ARROW_SHADOW_OFFSET = 3;

// negative offset is used to make the rectangle look like a triangle / arrow
export const TOOLTIP_ARROW_OFFSET = -(TOOLTIP_ARROW_SIZE / 2 + 2);

//
// ─── STYLES ─────────────────────────────────────────────────────────────────────
//

const styles = StyleSheet.create({
  toggle: {
    flexShrink: 1,
  },
  tooltipWrapper: {
    top: 0,
    left: 0,
    zIndex: theme.zIndex.tooltip,
  },
  tooltipWrapperInner: {
    alignItems: 'center',
    padding: TOOLTIP_OFFSET,
  },
  tooltipWrapperTopPlacement: {
    flexDirection: 'column',
  },
  tooltipWrapperRightPlacement: {
    flexDirection: 'row-reverse',
  },
  tooltipWrapperBottomPlacement: {
    flexDirection: 'column-reverse',
  },
  tooltipWrapperLeftPlacement: {
    flexDirection: 'row',
  },
  tooltipElement: {
    position: 'relative',
    backgroundColor: theme.colors.NEUTRAL_7,
    borderRadius: theme.radius.small,
  },
  tooltipTextWrapper: {
    color: theme.colors.GREEN_1,
    paddingVertical: theme.spacing['2'],
    paddingHorizontal: theme.spacing['3'],
    maxWidth: TOOLTIP_CONTENT_MAX_WIDTH,
    ...theme.elevations['4'],
  },

  //
  // ─── ARROW ────────────────────────────────────────────────────────────────────
  //

  arrow: {
    width: TOOLTIP_ARROW_SIZE,
    height: TOOLTIP_ARROW_SIZE,
    flexShrink: 0,
    transform: [{ rotate: '45deg' }],
    borderRadius: 2,
    ...theme.elevations['4'],
  },
  arrowTopPlacement: {
    marginTop: TOOLTIP_ARROW_OFFSET,
    shadowOffset: {
      width: TOOLTIP_ARROW_SHADOW_OFFSET,
      height: TOOLTIP_ARROW_SHADOW_OFFSET,
    },
    shadowOpacity: 0.15,
  },
  arrowRightPlacement: {
    marginRight: TOOLTIP_ARROW_OFFSET,
    shadowOffset: {
      width: -TOOLTIP_ARROW_SHADOW_OFFSET,
      height: TOOLTIP_ARROW_SHADOW_OFFSET,
    },
    shadowOpacity: 0.06,
  },
  arrowBottomPlacement: {
    marginBottom: TOOLTIP_ARROW_OFFSET,
    shadowOffset: {
      width: -TOOLTIP_ARROW_SHADOW_OFFSET,
      height: -TOOLTIP_ARROW_SHADOW_OFFSET,
    },
    shadowOpacity: 0.03,
  },
  arrowLeftPlacement: {
    marginLeft: TOOLTIP_ARROW_OFFSET,
    shadowOffset: {
      width: TOOLTIP_ARROW_SHADOW_OFFSET,
      height: -TOOLTIP_ARROW_SHADOW_OFFSET,
    },
    shadowOpacity: 0.06,
  },
});

const TooltipWrapperLayoutStyles: Record<TooltipPlacement, ViewStyle> = {
  top: styles.tooltipWrapperTopPlacement,
  right: styles.tooltipWrapperRightPlacement,
  bottom: styles.tooltipWrapperBottomPlacement,
  left: styles.tooltipWrapperLeftPlacement,
};

const ArrowLayoutStyles: Record<TooltipPlacement, ViewStyle> = {
  top: styles.arrowTopPlacement,
  right: styles.arrowRightPlacement,
  bottom: styles.arrowBottomPlacement,
  left: styles.arrowLeftPlacement,
};

import {
  CustomizationSelectedIngredient,
  CustomizationSelectedIngredientsContainer,
  CustomizationSelectedIngredientsHeading,
  CustomizationSelectedIngredientsList,
  CustomizationSelectedIngredientsListItem,
} from './components';

export const CustomizationSelectedIngredients = {
  Container: CustomizationSelectedIngredientsContainer,
  Heading: CustomizationSelectedIngredientsHeading,
  List: CustomizationSelectedIngredientsList,
  ListItem: CustomizationSelectedIngredientsListItem,
  Ingredient: CustomizationSelectedIngredient,
};

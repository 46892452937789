import { useMemo, useRef } from 'react';
import { useMachine } from '@xstate/react';

import { createReCaptchaMachine } from './machine';
import type { ReCaptchaContext } from './ReCaptcha.types';

/**
 * Returns helpers for interactions with the ReCaptcha component
 */
export const useReCaptcha = (options: UseReCaptchaOptions) => {
  const { isEnabled } = options;

  const reCaptchaRef = useRef<ReCaptchaContext>(null);

  const machine = useMemo(
    () => createReCaptchaMachine({ isEnabled }),
    [isEnabled],
  );
  const reCaptchaMachine = useMachine(machine);

  return { reCaptchaRef, reCaptchaMachine };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseReCaptchaOptions = {
  /**
   * Determines if Re-Captcha is enabled in the app.
   */
  isEnabled: boolean;
};

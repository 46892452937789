import React, {
  type ComponentProps,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import type { ImageSourcePropType } from 'react-native';
import { Image as RNImage, Platform } from 'react-native';

import { CloudinaryImage } from './CloudinaryImage';
import { ContentfulImage } from './ContentfulImage';
import { Image } from './Image';
import { type ImageProps } from './Image.types';

/**
 * Loads remote images through cloudinary and falls back to local image.
 */
export const FallbackImage = memo((props: FallbackImageProps) => {
  const {
    baseUrl,
    defaultImage,
    resizeMode,
    style,
    cloudinaryConfig,
    contentfulOptions,
    ...rest
  } = props;

  // Image Error
  const [imageLoadError, setImageLoadError] = useState(false);
  const handleImageLoadError = useCallback(() => {
    setImageLoadError(true);
  }, [setImageLoadError]);

  const isRemote = baseUrl && !imageLoadError;

  const imageProps: Omit<ComponentProps<typeof Image>, 'source'> = useMemo(
    () => ({
      style,
      resizeMode,
      onError: handleImageLoadError,
      ...rest,
    }),
    [resizeMode, rest, style, handleImageLoadError],
  );

  // If the {baseUrl} is not provided, just use the default image.
  if (!baseUrl) {
    return <Image source={defaultImage} {...imageProps} />;
  }

  // When Contentful options are specified, load remote image from Contentful
  if (isRemote && contentfulOptions) {
    return (
      <ContentfulImage
        baseUrl={baseUrl}
        options={contentfulOptions}
        {...imageProps}
      />
    );
  }

  // When Cloudinary config is specified, load remote image from Contentful
  if (isRemote && cloudinaryConfig) {
    return (
      <CloudinaryImage
        baseUrl={baseUrl}
        config={cloudinaryConfig}
        {...imageProps}
      />
    );
  }

  // return null if remote image could not be loaded and no default image was specified
  if (!isRemote && !defaultImage) {
    return null;
  }

  // load image directly if neither Cloudinary config nor Contentful options were specified,
  // or use default image if remote image could not be loaded
  return (
    <Image
      source={isRemote ? { uri: baseUrl } : defaultImage}
      {...imageProps}
    />
  );
});

if (Platform.OS === 'web') {
  // eslint-disable-next-line functional/immutable-data
  RNImage.resolveAssetSource = (source) => {
    return {
      uri: String(source),
      width: 1,
      height: 1,
      scale: 1,
    };
  };
}

type FallbackImageProps = Readonly<{
  baseUrl: string | undefined | null;
  source?: string;
  defaultImage?: ImageSourcePropType;
  contentfulOptions?: ComponentProps<typeof ContentfulImage>['options'];
  cloudinaryConfig?: ComponentProps<typeof CloudinaryImage>['config'];
}> &
  Omit<ImageProps, 'source'>;

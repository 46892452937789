import { useCallback, useEffect, useState } from 'react';

import { useTelemetry } from '@order/Telemetry';

import type { MenuContentCategories } from '../../../../MenuContent.types';

// ─────────────────────────────────────────────────────────────────────────────

export const useTrackAssetsLoad = (params: UseTrackAssetsLoadParams) => {
  const { categories } = params;

  const { trackEvent } = useTelemetry();

  // ─── State ───────────────────────────────────────────────────────────

  const [loadedAssetCategoryIds, setLoadedAssetCategoryIds] = useState<
    string[]
  >([]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const trackAssetLoad = useCallback((categoryId: string) => {
    setLoadedAssetCategoryIds((currentValues) => [
      ...currentValues,
      categoryId,
    ]);
  }, []);

  // ─── Effects ─────────────────────────────────────────────────────────

  const hasLoadedAllAssets =
    categories.length === loadedAssetCategoryIds.length;

  useEffect(() => {
    if (!hasLoadedAllAssets) return;

    trackEvent({ name: 'menu.top-down-categories.assets-loaded' });
  }, [hasLoadedAllAssets, trackEvent]);

  // ─────────────────────────────────────────────────────────────────────

  return { trackAssetLoad };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseTrackAssetsLoadParams = {
  categories: readonly Pick<MenuContentCategories[number], 'id'>[];
};

import type { ReactNode, ReactText } from 'react';
import type { ViewProps } from 'react-native';

import type { IconName } from '../Icon';

export const TagAlertPalette = {
  neutral: 'neutral',
  notice: 'notice',
  success: 'success',
  caution: 'caution',
  cautionDark: 'cautionDark',
};

export const TagAlertSize = {
  small: 'small',
  medium: 'medium',
};

/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
export type TagAlertIconMapType = Readonly<{
  [key: string]: {
    name: IconName;
  };
}>;

export type GarnishProps = Readonly<{
  size?: SizeTypes;
  palette?: PaletteTypes;
}> &
  Pick<ViewProps, 'testID'>;

export type TagAlertChildren = Readonly<{
  children: ReactText | ReactNode;
}>;

export type TagAlertProps = GarnishProps & TagAlertChildren;
export type TagAlertTextProps = GarnishProps & TagAlertChildren;
export type TagAlertBoxProps = GarnishProps & TagAlertChildren;

export type PaletteTypes = Required<keyof typeof TagAlertPalette>;
export type SizeTypes = Required<keyof typeof TagAlertSize>;

import { useGiftCardOrderDetailQuery } from '../../graphql/GiftCardOrderDetail.generated';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A small hook for fetching gift card order confirmation data.
 */
export const useGiftCardConfirmationData = (
  params: UseGiftCardConfirmationDataParams,
) => {
  const { orderId, pause } = params;

  // ─── Query ───────────────────────────────────────────────────────────

  const [response] = useGiftCardOrderDetailQuery({
    pause,
    variables: { orderId },
    requestPolicy: 'network-only',
  });

  // ─── Derived Data ────────────────────────────────────────────────────

  const responseData = response.data?.giftCardOrderDetail;
  const responseTypename = responseData?.__typename;
  const hasFetchedData = responseTypename === 'GiftCardOrderDetail';
  const data = hasFetchedData ? responseData : null;

  const isFetching = response.fetching;
  const hasSystemError = response.error !== undefined;
  const hasUserError = responseTypename !== undefined && !hasFetchedData;
  const hasError = hasSystemError || hasUserError;

  // ─────────────────────────────────────────────────────────────────────

  return { data, isFetching, hasError };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseGiftCardConfirmationDataParams = {
  orderId: string;
  pause: boolean;
};

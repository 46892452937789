/* istanbul ignore file */

import { defineMessages } from 'react-intl';

export const giftCardCheckoutLeaveScreenMessages = defineMessages({
  modalHeadline: {
    defaultMessage: 'Leave this page?',
    description: 'Gift Card Checkout Leave Screen | Modal | Headline',
  },
  modalBody: {
    defaultMessage:
      'Are you sure you want to leave this page? Changes you made will not be saved.',
    description: 'Gift Card Checkout Leave Screen | Modal | Body',
  },
  modalA11yLabel: {
    defaultMessage: 'Confirm exiting screen modal',
    description: 'Gift Card Checkout Leave Screen | Modal | a11y label',
  },
  modalCancelCtaLabel: {
    defaultMessage: 'Cancel',
    description: 'Gift Card Checkout Leave Screen | Modal | Cancel CTA label',
  },
  modalLeaveCtaLabel: {
    defaultMessage: 'Leave',
    description: 'Gift Card Checkout Leave Screen | Modal | Leave CTA label',
  },
  navigateToGiftCardsScreen: {
    defaultMessage: 'Navigate to gift cards screen',
    description:
      'Gift Card Checkout Leave Screen | Navigate to gift cards screen',
  },
});

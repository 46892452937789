import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  capitalizeFirstLetter,
  IconLink,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import {
  LocationResultCardFeeDisclosure,
  LocationResultCardImg,
} from '../../subcomponents';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultCardContent = (
  props: LocationResultCardContentProps,
) => {
  const {
    locationName,
    shouldShowDeliveryFeeDisclaimer,
    shouldShowOutpostFeeDisclaimer,
    estimatedDeliveryTime,
    shouldDisplayClosedNotice,
    shouldDisableControls,
    address,
    addressDetails,
    toggleDetailsModal,
    imageUrl,
    locationOrderChannel,
    isLoading,
    withDetailsModal,
  } = props;

  const { match, minWidth } = useResponsive();
  const { t } = useLocalizationContext();

  return (
    <>
      <View style={match([styles.innerContainerXS, styles.innerContainerSM])}>
        {/* ─── Main Info ─────────────────────────────────────────────────── */}

        <LocationResultCardImg
          locationName={locationName}
          locationSearchType={locationOrderChannel}
          imageUrl={imageUrl}
          isLoading={isLoading}
        />

        <View style={match([styles.infoContainerXS, styles.infoContainerSM])}>
          <BodyText
            bold
            size={match([2, 3])}
            numberOfLines={2}
            style={styles.heading}
            accessibilityLabel={locationName}
          >
            {capitalizeFirstLetter(locationName)}
          </BodyText>

          <BodyText
            size={match([3, 4])}
            numberOfLines={1}
            accessibilityLabel={address}
          >
            {address}
          </BodyText>

          {addressDetails ? (
            <BodyText
              size={match([3, 4])}
              numberOfLines={1}
              accessibilityLabel={addressDetails}
            >
              {addressDetails}
            </BodyText>
          ) : null}

          {estimatedDeliveryTime ? (
            <BodyText
              size={match([3, 4])}
              numberOfLines={1}
              accessibilityLabel={estimatedDeliveryTime}
              bold
            >
              {t('delivery.estimate', { estimate: estimatedDeliveryTime })}
            </BodyText>
          ) : null}

          {shouldDisplayClosedNotice ? (
            <BodyText
              size={match([3, 4])}
              numberOfLines={1}
              style={styles.closedLabel}
            >
              {t('location.closed')}
            </BodyText>
          ) : null}
        </View>

        {/* ─── Additional Details ───────────────────────────────────────── */}

        {withDetailsModal ? (
          <View
            style={match([
              styles.detailsBtnContainerXS,
              styles.detailsBtnContainerSM,
            ])}
          >
            <IconLink
              name="IconBadgeInfo"
              onPress={toggleDetailsModal}
              disabled={shouldDisableControls}
              hitSlop={theme.spacing['1']}
            />
          </View>
        ) : null}
      </View>

      {/* ─── Fee Disclosure ─────────────────────────────────────────────── */}

      {shouldShowDeliveryFeeDisclaimer && !minWidth.isSM ? (
        <LocationResultCardFeeDisclosure.Delivery />
      ) : null}
      {shouldShowOutpostFeeDisclaimer && !minWidth.isSM ? (
        <LocationResultCardFeeDisclosure.Outpost />
      ) : null}
    </>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  innerContainerXS: {
    flexDirection: 'row',
  },
  innerContainerSM: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  heading: {
    marginBottom: 2,
  },
  infoContainerXS: {
    flex: 1,
  },
  infoContainerSM: {
    flexBasis: '90%',
  },
  detailsBtnContainerXS: {
    marginLeft: 12,
  },
  detailsBtnContainerSM: {
    marginLeft: 'auto',
  },
  closedLabel: {
    color: theme.colors.CAUTION,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultCardContentProps = {
  locationName: string;
  locationOrderChannel: 'pickup' | 'delivery' | 'outpost' | undefined;
  imageUrl?: string;
  address: string;
  addressDetails?: string;
  estimatedDeliveryTime?: string;
  shouldDisplayClosedNotice: boolean;
  toggleDetailsModal: () => void;
  isLoading?: boolean;
  withDetailsModal?: boolean;
  shouldDisableControls: boolean;
  shouldShowDeliveryFeeDisclaimer: boolean;
  shouldShowOutpostFeeDisclaimer: boolean;
};

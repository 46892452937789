import { useCallback, useMemo } from 'react';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useChallengesWithThemeQuery } from '@order/graphql';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { useChallengesQuery } from '../GraphQL/Challenges.generated';
import { useHasUnseenChallengesQuery } from '../GraphQL/HasUnseenChallenges.generated';

// ─────────────────────────────────────────────────────────────────────────────

export const useChallenges = () => {
  const isLoggedIn = useIsLoggedIn();

  // ─── Challenges ──────────────────────────────────────────────────────

  const shouldEnableFlashChallengeTheme = useFeatureFlag(
    'CELS-796-short-flash-challenge-card-theme-enabled',
  );

  const [challengesWithoutThemeResponse, fetchChallengesWithoutTheme] =
    useChallengesQuery({
      requestPolicy: 'cache-and-network',
      pause: true,
    });
  const [challengesWithThemeResponse, fetchChallengesWithTheme] =
    useChallengesWithThemeQuery({
      requestPolicy: 'cache-and-network',
      pause: true,
    });

  const challengesResponse = shouldEnableFlashChallengeTheme
    ? challengesWithThemeResponse
    : challengesWithoutThemeResponse;

  // ─── Unseen Challenges ───────────────────────────────────────────────

  const [hasUnseenChallengesResponse, fetchHasUnseenChallengesQuery] =
    useHasUnseenChallengesQuery({
      requestPolicy: 'network-only',
      pause: !isLoggedIn,
    });

  // ─── Helpers ─────────────────────────────────────────────────────────

  const fetchHasUnseenChallenges = useCallback(() => {
    if (!isLoggedIn) return;

    fetchHasUnseenChallengesQuery({ requestPolicy: 'network-only' });
  }, [fetchHasUnseenChallengesQuery, isLoggedIn]);

  const fetchChallenges = useCallback(() => {
    const fetchActualChallenges = shouldEnableFlashChallengeTheme
      ? fetchChallengesWithTheme
      : fetchChallengesWithoutTheme;

    fetchActualChallenges();
    fetchHasUnseenChallenges();
  }, [
    fetchChallengesWithTheme,
    fetchChallengesWithoutTheme,
    fetchHasUnseenChallenges,
    shouldEnableFlashChallengeTheme,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  const hasUnseenChallenges =
    hasUnseenChallengesResponse.data?.hasUnseenChallenges
      ?.hasUnseenChallenges ?? false;

  const challenges = challengesResponse.data?.challenges;
  const isFetchingChallenges = challengesResponse.fetching;
  const challengesError = challengesResponse.error;

  return useMemo(
    () => ({
      hasUnseenChallenges,
      challenges,
      isFetchingChallenges,
      challengesError,
      fetchChallenges,
    }),
    [
      challenges,
      challengesError,
      hasUnseenChallenges,
      isFetchingChallenges,
      fetchChallenges,
    ],
  );
};

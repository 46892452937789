import {
  impactAsync,
  ImpactFeedbackStyle,
  notificationAsync,
  NotificationFeedbackType,
} from 'expo-haptics';

import { isNativePlatform } from './platform';

/**
 * Triggers device haptic (iOS) / vibration (Android) feedback if supported.
 * Optionally receives the type of the feedback.
 *
 * @see https://docs.expo.dev/versions/latest/sdk/haptics/#hapticsimpactasyncstyle
 */
export async function triggerHapticFeedback(
  feedbackStyle: ImpactFeedbackStyle = ImpactFeedbackStyle.Light,
) {
  if (!isNativePlatform()) return;
  await impactAsync(feedbackStyle);
}

/**
 * Triggers device haptic (iOS) / vibration (Android) notification feedback if supported.
 * Optionally receives the type of the feedback.
 *
 * @see https://docs.expo.dev/versions/latest/sdk/haptics/#hapticsnotificationasynctype
 */
export async function triggerHapticNotificationFeedback(
  feedbackStyle: NotificationFeedbackType = NotificationFeedbackType.Success,
) {
  if (!isNativePlatform()) return;
  await notificationAsync(feedbackStyle);
}

export {
  ImpactFeedbackStyle as HapticFeedbackStyle,
  NotificationFeedbackType as HapticNotificationFeedbackStyle,
} from 'expo-haptics';

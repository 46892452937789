import type { ComponentProps } from 'react';
import React, { forwardRef, useRef } from 'react';
import type { ViewProps } from 'react-native';
import { Pressable, StyleSheet, type View } from 'react-native';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';
import useMergedRef from '@react-hook/merged-ref';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../hooks';
import { webOnlyStyles } from '../../utils';

export const Card = forwardRef<View, CardProps>((props, ref) => {
  const {
    style: externalStyle,
    children,
    isSelected,
    disabled,
    onPress,
    backgroundColor,
    backgroundColorHover,
    selectedBackgroundColor,
    selectedBackgroundColorHover,
    selectedBorderColor,
    hasHoverState,
    ...restProps
  } = props;

  // ─── PRESSABLE STATE ─────────────────────────────────────────────

  const cardLocalRef = useRef(null);
  const cardRef = useMergedRef(cardLocalRef, ref);

  const { isHovered } = usePressableState(cardLocalRef);
  const hasOnPress = Boolean(onPress && !disabled);
  const hasHoverStateSupport = hasHoverState || hasOnPress;

  // ─── STYLES ──────────────────────────────────────────────────────

  const cardAnimatedStyles = useCardAnimatedStyles({
    isHovered,
    isSelected,
    hasHoverState: hasHoverStateSupport,
    disabled,
    backgroundColor,
    backgroundColorHover,
    selectedBackgroundColor,
    selectedBackgroundColorHover,
    selectedBorderColor,
  });

  const cardWebOnlyStyles = webOnlyStyles({
    outlineColor: theme.colors.GRAY,
    outlineOffset: 3,
    cursor: hasHoverStateSupport ? 'pointer' : 'default',
  });
  const cardDisabledStyle = disabled && [
    webOnlyStyles({ cursor: 'not-allowed' }),
    {
      backgroundColor: theme.colors.LINEN,
    },
  ];
  const cardStyle = [
    styles.card,
    cardWebOnlyStyles,
    cardAnimatedStyles,
    cardDisabledStyle,
    externalStyle,
  ];

  // ─────────────────────────────────────────────────────────────────────

  const InnerContainer = hasOnPress ? AnimatedPressable : Animated.View;

  // ─────────────────────────────────────────────────────────────────

  return (
    <InnerContainer
      ref={cardRef}
      style={cardStyle}
      disabled={disabled}
      accessibilityRole={hasOnPress ? 'button' : undefined}
      onPress={hasOnPress ? onPress : undefined}
      {...restProps}
    >
      {children}
    </InnerContainer>
  );
});

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

// ─── HOOKS ──────────────────────────────────────────────────────────────────────

const useCardAnimatedStyles = (props: UseCardStylesProps) => {
  const { isHovered, isSelected, disabled, hasHoverState } = props;

  // ─── Colors ──────────────────────────────────────────────────────────

  const {
    QUINOA,
    QUINOA_HOVER,
    CUCUMBER,
    CUCUMBER_HOVER,
    LINEN,
    SPINACH,
    OPACITY,
  } = theme.colors;

  const {
    backgroundColor = QUINOA,
    backgroundColorHover = QUINOA_HOVER,
    selectedBackgroundColor = CUCUMBER,
    selectedBackgroundColorHover = CUCUMBER_HOVER,
    selectedBorderColor = SPINACH,
  } = props;

  // ─────────────────────────────────────────────────────────────────────

  const animatedBackgroundColor = useDerivedValue(() => {
    const idleValues = isHovered ? 1 : 2;
    const selectedValues = isHovered ? 4 : 3;

    const pressableValue = isSelected ? selectedValues : idleValues;
    const defaultValue = disabled ? 0 : 2;
    const animatedValue = hasHoverState ? pressableValue : defaultValue;

    return withTiming(animatedValue, { duration: theme.transitions.base });
  }, [isHovered, hasHoverState, isSelected, disabled]);

  const animatedBorderColor = useDerivedValue(() => {
    return withTiming(isSelected ? 1 : 0, { duration: theme.transitions.base });
  }, [isSelected]);

  return useAnimatedStyle(() => {
    return {
      backgroundColor: interpolateColor(
        animatedBackgroundColor.value,
        [0, 1, 2, 3, 4],
        [
          LINEN,
          backgroundColorHover,
          backgroundColor,
          selectedBackgroundColor,
          selectedBackgroundColorHover,
        ],
      ),

      borderColor: interpolateColor(
        animatedBorderColor.value,
        [0, 1],
        [OPACITY.TRANSPARENT, selectedBorderColor],
      ),
    };
  }, [backgroundColor, backgroundColorHover]);
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  card: {
    width: '100%',
    flexGrow: 1,
    alignItems: 'flex-start',
    backgroundColor: theme.colors.QUINOA,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.colors.OPACITY.TRANSPARENT,
    borderRadius: theme.radius.medium,
  },
  cardContainer: {
    flexGrow: 1,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type CardProps = Readonly<{
  isSelected?: boolean;
  disabled?: boolean;
  onPress?: ComponentProps<typeof Pressable>['onPress'];
  onLongPress?: ComponentProps<typeof Pressable>['onLongPress'];
  style?: ViewProps['style'];
  backgroundColor?: string;
  backgroundColorHover?: string;
  selectedBackgroundColor?: string;
  selectedBackgroundColorHover?: string;
  selectedBorderColor?: string;

  /**
   * A flag that enables the hover state even if `onPress` is not provided.
   */
  hasHoverState?: boolean;
}> &
  ComponentProps<typeof View>;

type UseCardStylesProps = Readonly<{
  isHovered: boolean;
  hasHoverState: boolean;
  disabled?: boolean;
  isSelected?: boolean;
}> &
  Pick<
    CardProps,
    | 'backgroundColor'
    | 'backgroundColorHover'
    | 'selectedBackgroundColor'
    | 'selectedBackgroundColorHover'
    | 'selectedBorderColor'
  >;

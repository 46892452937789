import { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import { type RedeemGiftCardFormReturn } from '../../RedeemGiftCardForm.types';

export const useRedeemGiftCardAutoSubmit = (
  params: UseRedeemGiftCardAutoSubmitParams,
) => {
  const { giftCardCode, giftCardRegistrationCode, form, submitForm } = params;

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldAutoSubmit =
    typeof giftCardCode === 'string' && giftCardCode.length > 0;

  // ─── Helpers ─────────────────────────────────────────────────────────

  /**
   * Auto-submits form if gift card redemption data is provided
   */
  const autoSubmitForm = useCallback(() => {
    if (!shouldAutoSubmit || form.formState.isSubmitting) return;

    form.setValue('giftCardCode', giftCardCode);
    form.setValue('registrationCode', giftCardRegistrationCode);

    void submitForm();
  }, [
    form,
    giftCardCode,
    giftCardRegistrationCode,
    shouldAutoSubmit,
    submitForm,
  ]);

  // Auto-submit form if the default gift card code is provided
  useFocusEffect(autoSubmitForm);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseRedeemGiftCardAutoSubmitParams = {
  giftCardCode?: string;
  giftCardRegistrationCode?: string;
  form: RedeemGiftCardFormReturn;
  submitForm: () => Promise<void>;
};

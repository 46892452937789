/* istanbul ignore file */

import React from 'react';
import type { FocusScopeProps } from '@react-aria/focus';
import { FocusScope as AriaFocusScope } from '@react-aria/focus';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * Keeps the focus trapped in the contents so that tabbing through the controls doesn't take the customer outside of it.
 */
export const FocusScope = (props: FocusScopeProps & { enabled?: boolean }) => {
  const { children, enabled = true, ...restProps } = props;

  return (
    <AriaFocusScope
      autoFocus={enabled}
      restoreFocus={enabled}
      contain={enabled}
      {...restProps}
    >
      {children}
    </AriaFocusScope>
  );
};

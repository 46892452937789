import { useCallback } from 'react';
import { Platform } from 'react-native';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export function useApplePayErrorHandling() {
  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  const onApplePayError = useCallback(
    (_error?: unknown) => {
      addNoticeBanner({
        text: t('general.apple-pay-error'),
        palette: 'caution',
      });
    },
    [t, addNoticeBanner],
  );

  const onApplePayCancellation = useCallback(() => {
    // ────────────────────────────────────────────────────────────────────────────
    // If you're on safari and you don't have a card in your Apple Wallet,
    // and your Apple Id for your MacOS is not also present on an iPhone,
    // then Stripe automatically cancels the request without any feedback.
    // If you're on iOS or on a MacOS with the same Apple Id as an iPhone,
    // you'd get a message from Apple telling you to add a card to your wallet.
    // Therefore we have this banner here just for web to cover this edge case.
    // ────────────────────────────────────────────────────────────────────────────

    if (Platform.OS === 'web') {
      addNoticeBanner({
        text: t('general.apple-pay-canceled'),
        palette: 'caution',
      });
    }
  }, [addNoticeBanner, t]);

  return {
    onApplePayError,
    onApplePayCancellation,
  };
}

import { clientInstance } from '../../client';
import { config } from '../../config';

export async function getHybridFlowApiAccessToken(): Promise<
  string | null | undefined
> {
  const { apiLoginRequest } = config.scopes;

  await clientInstance?.initialize();

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const account = clientInstance?.getActiveAccount()!;
  const apiAccessTokenRequest = { ...apiLoginRequest, account };

  const response = await clientInstance?.acquireTokenSilent(
    apiAccessTokenRequest,
  );

  return response?.accessToken;
}

import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@sg/garnish';

import type { PartialLineItem, PartialProduct } from '@order/graphql';
import { OrderType } from '@order/graphql';
import {
  useCart,
  useEditLineItemInCart,
  useRemoveLineItemFromCart,
} from '@order/hooks';

import { LineItemCard } from '../../../LineItemCard';
import { useBagLineItemsNavigation } from './BagLineItems.navigation';
import { useBagLineItemsTelemetry } from './BagLineItems.telemetry';

// ────────────────────────────────────────────────────────────────────────────────

export const BagLineItems = () => {
  const { cart, isFetchingCart } = useCart();
  const { removeLineItemFromCart } = useRemoveLineItemFromCart();
  const { editLineItemInCart } = useEditLineItemInCart();

  // ─────────────────────────────────────────────────────────────────

  const { lineItems = [], restaurant } = cart ?? {};
  const restaurantSlug = restaurant?.slug ?? '';
  const addressId = cart?.deliveryOrderDetail?.address?.id ?? '';
  const isDelivery = cart?.orderType === OrderType.Delivery;

  // ─────────────────────────────────────────────────────────────────

  const { navigateToEditProductDetails } = useBagLineItemsNavigation({
    restaurantSlug,
    addressId,
    isDelivery,
  });

  const {
    trackModifyLineItemQuantityEvent,
    trackNavigateToEditPdpEvent,
    trackRemoveLineItemEvent,
  } = useBagLineItemsTelemetry({ restaurantSlug });

  // ─── HELPERS ─────────────────────────────────────────────────────

  const handleNavigateToEditProductDetails = useCallback(
    (lineItemId: string, product: PartialProduct) => {
      navigateToEditProductDetails({
        lineItemId,
        product,
        onNavigate() {
          trackNavigateToEditPdpEvent(product);
        },
      });
    },
    [navigateToEditProductDetails, trackNavigateToEditPdpEvent],
  );

  const modifyLineItemQuantity = useCallback(
    async (
      lineItem: Omit<PartialLineItem, 'ingredients'>,
      selectedQuantity: number,
    ) => {
      trackModifyLineItemQuantityEvent(lineItem, selectedQuantity);

      // ─────────────────────────────────────────────────────────────────

      const {
        id: lineItemId = '',
        product,
        addedIngredients = [],
        removedIngredients = [],
        mixedDressingDetails = [],
        customName,
      } = lineItem;

      const response = await editLineItemInCart({
        product,
        lineItemId,
        customName,
        quantity: selectedQuantity,
        modifications: {
          additions: addedIngredients,
          removals: removedIngredients,
          mixedDressingDetails,
        },
      });

      // ─────────────────────────────────────────────────────────────────

      return new Promise((resolve, reject) => {
        const lineItemHasBeenUpdated =
          response.data?.editLineItemInCart.__typename ===
          'EditLineItemInCartSuccess';

        if (lineItemHasBeenUpdated) resolve(response.data);
        reject();
      });
    },
    [editLineItemInCart, trackModifyLineItemQuantityEvent],
  );

  const removeLineItem = useCallback(
    async (lineItemId: string, product: PartialProduct) => {
      trackRemoveLineItemEvent(product);
      await removeLineItemFromCart(lineItemId);
    },
    [removeLineItemFromCart, trackRemoveLineItemEvent],
  );

  // ────────────────────────────────────────────────────────────────────

  return (
    <View>
      {lineItems.map((lineItem, index) => {
        const lineItemId = lineItem.id ?? '';
        const isLastLineItem = lineItems.length - 1 === index;
        const cardWrapperStyle = isLastLineItem
          ? {}
          : styles.cardWrapperWithSeparator;

        return (
          <View key={lineItemId} style={cardWrapperStyle}>
            <LineItemCard
              lineItem={lineItem}
              isDisabled={isFetchingCart}
              onPress={handleNavigateToEditProductDetails}
              onRemove={removeLineItem}
              onQuantityChange={modifyLineItemQuantity}
            />
          </View>
        );
      })}
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cardWrapperWithSeparator: {
    borderBottomWidth: 1,
    borderColor: theme.colors.LIGHT,
  },
});

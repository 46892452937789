import React, { useRef } from 'react';
import { StyleSheet } from 'react-native';
import {
  BodyText,
  ListActionPressable,
  usePressableState,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type { DeliveryPreferenceType } from '../../graphql/types';
import { usePreferencesText } from './hooks';

export const DeliveryPreferencesItem = ({
  preferences,
  onPress,
}: DeliveryPreferencesItemProps) => {
  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();

  const preferencesText = usePreferencesText(preferences.type);

  const pressableRef = useRef(null);
  const { isActive, isFocused, isHovered } = usePressableState(pressableRef);
  const active = isActive || isFocused || isHovered;

  return (
    <ListActionPressable
      testID="delivery.preferences.trigger"
      ref={pressableRef}
      withCaret={currentBreakpoint.isXS}
      accessibilityRole="button"
      accessibilityLabel={t('delivery.preferences.title')}
      accessibilityHint={t('delivery.preferences.title')}
      onPress={onPress}
      iconName="IconBagStroke"
    >
      <BodyText size={4} bold style={[styles.title, active && styles.active]}>
        {preferencesText}
      </BodyText>
      <BodyText size={4} lineBreakMode="head">
        {}
        {preferences.notes || t('delivery.preferences.note-hint')}
      </BodyText>
    </ListActionPressable>
  );
};

type DeliveryPreferences = Readonly<{
  type: DeliveryPreferenceType;
  notes?: string;
}>;

type DeliveryPreferencesItemProps = Readonly<{
  preferences: DeliveryPreferences;
  onPress: () => void;
}>;

const styles = StyleSheet.create({
  title: {
    textTransform: 'capitalize',
  },
  active: {
    textDecorationLine: 'underline',
  },
});

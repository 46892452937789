import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { ANIMATED_DIALOG_ITEM_PADDING_STYLE, DisplayText } from '@sg/garnish';

import { DeliveryPreferencesForm } from '@order/components';
import {
  type DeliveryOrderDetail,
  type DeliveryPreferenceType,
} from '@order/graphql';

import { useUpdateDeliveryPreferences } from '../../hooks';

export const BagDeliveryPreferencesForm = (
  props: BagDeliveryPreferencesFormProps,
) => {
  const { deliveryOrderDetail, deliveryPreferences, onSubmit } = props;

  const { isUpdatingDeliveryPreferences, updateDeliveryPreferences } =
    useUpdateDeliveryPreferences();

  const handleSubmission = useCallback(
    async (payload: DeliveryPreferences) => {
      await updateDeliveryPreferences({
        deliveryPreference: payload.type as DeliveryPreferenceType,
        notes: payload.notes,
        id: deliveryOrderDetail.address?.id ?? '',
        name: deliveryOrderDetail.address?.name ?? '',
        city: deliveryOrderDetail.address?.city ?? '',
        country: deliveryOrderDetail.address?.country ?? '',
        googlePlaceId: deliveryOrderDetail.address?.googlePlaceId ?? '',
        latitude: deliveryOrderDetail.address?.latitude ?? 0,
        longitude: deliveryOrderDetail.address?.longitude ?? 0,
        state: deliveryOrderDetail.address?.state ?? '',
        street: deliveryOrderDetail.address?.street ?? '',
        secondaryStreet: deliveryOrderDetail.address?.street ?? '',
        zipCode: deliveryOrderDetail.address?.zipCode ?? '',
      });

      onSubmit(payload);
    },
    [deliveryOrderDetail, onSubmit, updateDeliveryPreferences],
  );

  const { localType, localNotes, setLocalType, setLocalNotes, submitForm } =
    DeliveryPreferencesForm.useForm({
      preferences: deliveryPreferences,
      onSave: handleSubmission,
    });

  return (
    <View style={styles.container}>
      <DisplayText sizeMatch={['32']}>
        <FormattedMessage {...messages.deliveryPreferencesTitle} />
      </DisplayText>

      <DeliveryPreferencesForm.TypeSelector
        localType={localType}
        setLocalType={setLocalType}
      />

      <DeliveryPreferencesForm.NotesField
        localNotes={localNotes}
        setLocalNotes={setLocalNotes}
      />

      <DeliveryPreferencesForm.SubmitButton
        isSubmitting={isUpdatingDeliveryPreferences}
        submitForm={submitForm}
      />
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  deliveryPreferencesTitle: {
    defaultMessage: 'Edit delivery details',
    description: 'Bag | Delivery Preferences | Title',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: ANIMATED_DIALOG_ITEM_PADDING_STYLE,
});

// ─── Types ──────────────────────────────────────────────────────────────────

type DeliveryPreferences = { type: string; notes: string };
type BagDeliveryPreferencesFormProps = {
  deliveryOrderDetail: DeliveryOrderDetail;
  deliveryPreferences: DeliveryPreferences;
  onSubmit: (deliveryPreferences: DeliveryPreferences) => void;
};

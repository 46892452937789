import {
  ButtonFluidIcon,
  ButtonFluidLabel,
  ButtonFluidPressable,
  ButtonFluidText,
} from './components';

export const ButtonFluid = {
  Pressable: ButtonFluidPressable,
  Label: ButtonFluidLabel,
  Text: ButtonFluidText,
  Icon: ButtonFluidIcon,
};

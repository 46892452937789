import React from 'react';

import { useFeatureFlag } from '@order/LaunchDarkly';

import { FeatureFlagSwitcher } from './FeatureFlagSwitcher';

export const DebugMenu = () => {
  const canOverrideFeatureFlags = useFeatureFlag(
    'permanent-feature-flag-overrides-enabled',
  );

  if (!canOverrideFeatureFlags) return null;

  return <FeatureFlagSwitcher />;
};

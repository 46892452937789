import React, { memo } from 'react';
import { View } from 'react-native';
import type { AddressType } from '@sg/garnish';
import {
  Container,
  IllusBowlFull,
  IllusDelivery_1,
  Image,
  TitleText,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type { RestaurantQuery } from '../../GraphQL/Restaurant.query.generated';
import { styles } from './MenuHero.styles';
import {
  MenuHeroInfo,
  MenuHeroLocation,
  MenuHeroNotice,
} from './subcomponents';

/**
 * "Menu Hero" presentation component.
 *
 * Shows the following information about store or delivery location:
 *
 * - Name or delivery address
 * - Additional information like store address, phone number, working hours, estimated delivery time, etc.
 * - Optional notification about the store (The store is currently not working, etc.)
 *
 * 💡 Additional information block can be collapsed/expanded on smaller screens
 *    if the order type is "standard" (not delivery).
 */
export const MenuHero = memo((props: MenuHeroProps) => {
  const {
    restaurant,
    deliveryLocation,
    deliveryAddress,
    deliveryETA,
    locationSelectionType,
    goToLocations,
  } = props;
  const { t } = useLocalizationContext();
  const { minWidth } = useResponsive();
  const isDesktop = minWidth.isMD;
  const isDeliveryMenu = Boolean(deliveryETA);

  // ────────────────────────────────────────────────────────────────────

  const title = deliveryLocation
    ? t('menu.hero.title.delivery')
    : t('menu.hero.title.standard');
  const illustration = deliveryLocation ? IllusDelivery_1 : IllusBowlFull;
  const illustrationAlt = deliveryLocation
    ? t('menu.hero.illustration.alt.standard')
    : t('menu.hero.illustration.alt.delivery');

  // ────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.heroWrapper}>
      <Container style={styles.heroInnerContainer}>
        <View
          style={isDesktop ? styles.infoColumnDesktop : styles.infoColumnTablet}
        >
          <TitleText size={3}>{title}</TitleText>

          <MenuHeroLocation
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            location={deliveryLocation ?? restaurant.name}
            goToLocations={goToLocations}
          />

          {/* Flex message should not be present in delivery menus. */}
          {locationSelectionType === 'delivery' ? null : (
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            <MenuHeroNotice text={restaurant.flexMessage} />
          )}

          {isDeliveryMenu ? (
            <MenuHeroInfo
              restaurant={restaurant}
              deliveryAddress={deliveryAddress}
              deliveryETA={deliveryETA}
            />
          ) : null}

          {!isDesktop && !isDeliveryMenu ? (
            <MenuHeroInfo restaurant={restaurant} />
          ) : null}
        </View>

        {isDesktop ? (
          <View style={styles.restaurantColumn}>
            {isDeliveryMenu ? null : <MenuHeroInfo restaurant={restaurant} />}
          </View>
        ) : null}

        <View style={styles.illustrationColumn}>
          <Image
            source={illustration}
            style={styles.illustration}
            aria-label={illustrationAlt}
          />
        </View>
      </Container>
    </View>
  );
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type MenuHeroProps = Readonly<{
  restaurant: RestaurantQuery['restaurant'];
  deliveryLocation?: string;
  deliveryAddress?: string;
  deliveryETA?: string;
  locationSelectionType: AddressType;
  goToLocations: () => void;
}>;

import React, {
  type MutableRefObject,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useWindowDimensions, View } from 'react-native';
import { useSharedValue } from 'react-native-reanimated';

import type { PlacementOptions } from '../../hooks';
import {
  useMeasuredViewStyle,
  usePressableState,
  useUniqueNativeID,
} from '../../hooks';
import { AutomaticallyPlacedView, useAutomaticPlacement } from '../../hooks';
import { Overlay } from '../Overlay';
import { BodyText } from '../Text';
import { SelectContent } from './Select.content';
import { styles } from './Select.styles';
import { Trigger } from './Select.trigger';
import type { SelectProps, ValueModel } from './Select.types';

export const Select = (props: SelectProps) => {
  const {
    label,
    value,
    options,
    variation = 'standard',
    isLoading,
    accessibilityLabel,
    onSelect,
  } = props;

  // ─── State ───────────────────────────────────────────────────────────

  const selectId = useUniqueNativeID('sg-select');
  const selectedOption = options.find((opt) => opt.value === value)?.label;
  const [opened, setShowing] = useState(false);
  const triggerRef = useRef(null);
  const contentRef = useRef(null);
  const { isInteracting } = usePressableState(triggerRef);

  // ─── Dropdown Width & Placement ──────────────────────────────────────

  const { onLayout, measuredViewStyle } = useDropdownWidth({ triggerRef });
  const { placementX, placementY, updatePlacementDebounced, updatePlacement } =
    useDropdownPlacement({ opened, triggerRef, contentRef });

  // ─── Callbacks ───────────────────────────────────────────────────────

  const toggleSelect = useCallback(() => {
    setShowing(!opened);
  }, [opened, setShowing]);

  const closeSelect = useCallback(() => {
    setShowing(false);
  }, [setShowing]);

  const selectOption = useCallback(
    (option: ValueModel) => {
      onSelect(option);
      closeSelect();
    },
    [onSelect, closeSelect],
  );

  const handleOnLayout = useCallback(() => {
    onLayout();
    updatePlacement();
  }, [onLayout, updatePlacement]);

  return (
    <View onLayout={updatePlacementDebounced}>
      {label ? (
        <BodyText
          size={4}
          style={variation === 'box' && styles.labelWithSpacing}
        >
          {label}
        </BodyText>
      ) : null}

      <Trigger
        selectId={selectId}
        ref={triggerRef}
        opened={opened}
        selectedOption={selectedOption}
        isInteracting={isInteracting}
        variation={variation}
        isLoading={isLoading}
        accessibilityLabel={accessibilityLabel}
        onPress={toggleSelect}
        onLayout={handleOnLayout}
      />

      <Overlay
        show={opened}
        triggerRef={triggerRef}
        contentRef={contentRef}
        dismiss={closeSelect}
      >
        <AutomaticallyPlacedView
          placementX={placementX}
          placementY={placementY}
          style={styles.select}
        >
          <SelectContent
            selectId={selectId}
            measuredViewStyle={measuredViewStyle}
            opened={opened}
            contentRef={contentRef}
            value={value}
            options={options}
            accessibilityLabel={accessibilityLabel}
            onLayout={updatePlacementDebounced}
            onSelect={selectOption}
          />
        </AutomaticallyPlacedView>
      </Overlay>
    </View>
  );
};

// ─── Hooks ───────────────────────────────────────────────────────────────────

/**
 * On layout, sets the dropdown width to be the same as the trigger.
 */
function useDropdownWidth(props: DropdownWidthProps) {
  const { triggerRef } = props;

  const [width, setWidth] = useState(0);

  const { measuredViewStyle, onLayout } = useMeasuredViewStyle({
    reference: triggerRef,
    width,
    setWidth,
  });

  return {
    measuredViewStyle,
    onLayout,
  };
}

/**
 * On layout, sets the dropdown placement to be next to the trigger.
 */
function useDropdownPlacement(props: DropdownPlacementProps) {
  const { opened, triggerRef, contentRef } = props;

  const windowDimensions = useWindowDimensions();
  const [placement, setCurrentPlacement] = useState<PlacementOptions>('bottom');
  const placementX = useSharedValue(0);
  const placementY = useSharedValue(0);

  const { updatePlacement, updatePlacementDebounced } = useAutomaticPlacement({
    placementX,
    placementY,
    triggerRef,
    contentRef,
    placement,
    setCurrentPlacement,
  });

  useLayoutEffect(() => {
    if (!opened) return;
    updatePlacement();
  }, [
    opened,
    windowDimensions.width,
    windowDimensions.height,
    updatePlacement,
  ]);

  return {
    placementX,
    placementY,
    updatePlacementDebounced,
    updatePlacement,
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type DropdownWidthProps = Readonly<{
  triggerRef: MutableRefObject<null>;
}>;

type DropdownPlacementProps = Readonly<{
  opened: boolean;
  triggerRef: MutableRefObject<null>;
  contentRef: MutableRefObject<null>;
}>;

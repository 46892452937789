import { defineMessages } from 'react-intl';

export const reorderOrderLineItemMessages = defineMessages({
  unavailableProductMessage: {
    defaultMessage:
      'Sorry, the {product} is temporarily unavailable at your location. Please select a different option.',
    description: 'Reorder | "Unavailable product" message',
  },
  failedToReorderMessage: {
    defaultMessage:
      'Unfortunately, there is an issue with reordering at this time. Please try a different product or order through the regular ordering flow.',
    description: 'Reorder | "Failed to reorder line item" message',
  },
  product: {
    defaultMessage: 'product',
    description: 'Reorder | Product',
  },
});

import React, { type ComponentProps } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, type TextProps, View, type ViewProps } from 'react-native';
import { theme } from '@garnish/constants';
import {
  Container as GarnishContainer,
  DisplayText,
  HorizontalScrollRail,
  useContainerSpacing,
  useResponsive,
} from '@sg/garnish';

const Container = (props: ViewProps) => {
  const { match, minWidth } = useResponsive();

  const containerStyle = [
    match([styles.containerXS, styles.containerSM]),
    minWidth.isLG && styles.containerStaticSpacingLG,
    props.style,
  ];

  if (minWidth.isLG) {
    return <View style={containerStyle}>{props.children}</View>;
  }

  return (
    <GarnishContainer style={containerStyle}>{props.children}</GarnishContainer>
  );
};

const Title = (props: TextProps) => {
  return (
    <DisplayText style={[styles.title, props.style]} sizeMatch={['24']}>
      <FormattedMessage {...messages.rewards} />
    </DisplayText>
  );
};

const Rail = (props: RailProps) => {
  const { match } = useResponsive();
  const containerSpacing = useContainerSpacing();

  return (
    <HorizontalScrollRail
      showNavControls={false}
      withoutHeaderBorder
      gap={theme.spacing['4']}
      outerOffset={
        -match([
          containerSpacing,
          containerSpacing,
          containerSpacing,
          RAIL_SPACING_LG,
        ])
      }
      itemVisiblePercentThreshold={99}
    >
      {props.children}
    </HorizontalScrollRail>
  );
};

export const LoyaltyHomeRewards = {
  Container,
  Title,
  Rail,
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  rewards: {
    defaultMessage: 'Rewards',
    description: 'Loyalty Home | Rewards | Title',
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const RAIL_SPACING_LG = theme.spacing['30'];

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    backgroundColor: theme.colors.FOREST,
    paddingVertical: theme.spacing['10'],
  },
  containerSM: {
    backgroundColor: theme.colors.FOREST,
    paddingVertical: theme.spacing['10'],
    gap: theme.spacing['6'],
  },
  containerStaticSpacingLG: {
    paddingHorizontal: RAIL_SPACING_LG,
  },
  title: {
    color: theme.colors.WHITE,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type RailProps = Pick<
  ComponentProps<typeof HorizontalScrollRail>,
  'children' | 'style'
>;

import React, { type PropsWithChildren } from 'react';

import { LabelText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductCardV2Label = (props: PropsWithChildren) => {
  return (
    <LabelText sizeMatch={['14']} maxFontSizeMultiplier={1.2}>
      {props.children}
    </LabelText>
  );
};

import React from 'react';
import type { TextProps, ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { DisplayText, MobileHeader, theme, useResponsive } from '@sg/garnish';

export type AccountScreenTitleVisibility = 'ALWAYS' | 'COMPACT';
export type AccountScreenTitleProps = Readonly<{
  title?: string;
  visibility?: AccountScreenTitleVisibility;
}> &
  ViewProps &
  TextProps;

export const AccountScreenTitle = (
  props: AccountScreenTitleProps,
): React.ReactElement<Text> | null => {
  const { testID, visibility = 'ALWAYS', title } = props;
  const { match, currentBreakpoint } = useResponsive();

  if (currentBreakpoint.isXS) {
    return <MobileHeader testID={testID} title={title} />;
  }

  if (visibility === 'COMPACT') return null;

  return (
    <View style={styles.row}>
      <DisplayText
        testID={testID}
        // @ts-expect-error TS(2322): Type '{ children: string | undefined; testID: stri... Remove this comment to see the full error message
        accessibilityLevel={1}
        accessibilityRole="header"
        style={styles.regularWidthTitle}
        size={match([4, 4, 3])}
      >
        {title}
      </DisplayText>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  regularWidthTitle: {
    marginBottom: theme.spacing['4'],
  },
});

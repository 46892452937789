import React from 'react';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { theme, useResponsive } from '@sg/garnish';

import { type DeliveryPreferenceType } from '@order/graphql';

import { DeliveryPreferencesFormHeader } from './DeliveryPreferencesFormHeader';
import { DeliveryPreferencesFormNotesField } from './DeliveryPreferencesFormNotesField';
import { DeliveryPreferencesFormSubmitButton } from './DeliveryPreferencesFormSubmitButton';
import { DeliveryPreferencesFormTypeSelector } from './DeliveryPreferencesFormTypeSelector';
import { useDeliveryPreferencesForm } from './hooks';

export const DeliveryPreferencesForm = (
  props: DeliveryPreferencesFormProps,
) => {
  const { preferences, submitting, onClose, onSave } = props;

  const { localType, localNotes, setLocalType, setLocalNotes, submitForm } =
    useDeliveryPreferencesForm({ preferences, onSave });

  const { match } = useResponsive();

  return (
    <SafeAreaView style={match([styles.formXs, styles.formSm])}>
      <DeliveryPreferencesFormHeader onClose={onClose} />

      <DeliveryPreferencesFormTypeSelector
        localType={localType}
        setLocalType={setLocalType}
      />

      <DeliveryPreferencesFormNotesField
        localNotes={localNotes}
        setLocalNotes={setLocalNotes}
      />

      <DeliveryPreferencesFormSubmitButton
        isSubmitting={submitting}
        submitForm={submitForm}
      />
    </SafeAreaView>
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────

type DeliveryPreferences = Readonly<{
  type: DeliveryPreferenceType;
  notes?: string;
}>;

type DeliveryPreferencesFormProps = Readonly<{
  preferences: { type: string; notes?: string };
  submitting?: boolean;
  onClose: () => void;
  onSave: (preferences: DeliveryPreferences) => void;
}>;

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  formSm: {
    flex: 1,
    padding: theme.spacing['6'],
  },
  formXs: {
    flex: 1,
    padding: theme.spacing['4'],
  },
});

// ─── Compound Components ────────────────────────────────────────────────────
/* eslint-disable functional/immutable-data */

DeliveryPreferencesForm.Header = DeliveryPreferencesFormHeader;
DeliveryPreferencesForm.NotesField = DeliveryPreferencesFormNotesField;
DeliveryPreferencesForm.SubmitButton = DeliveryPreferencesFormSubmitButton;
DeliveryPreferencesForm.TypeSelector = DeliveryPreferencesFormTypeSelector;
DeliveryPreferencesForm.useForm = useDeliveryPreferencesForm;

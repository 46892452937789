import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders an animated `View` component to indicate different states
 * of the radio button.
 */
export const RadioContainerItemAnimatedContainer = (
  props: RadioContainerItemAnimatedContainerProps,
) => {
  const { children, isSelected, isHovered, isDisabled } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerBg = useDerivedValue(() => {
    const { TRANSPARENT, KALE } = theme.colors.OPACITY;

    if (isDisabled) return withTransition(TRANSPARENT);

    if (isSelected) return withTransition(KALE.LIGHTER);

    if (isHovered) return withTransition(KALE.LIGHT);

    return withTransition(TRANSPARENT);
  });

  const animatedStyle = useAnimatedStyle(() => {
    return { backgroundColor: containerBg.value };
  });
  const innerContainerStyles = [styles.container, animatedStyle];

  // ─────────────────────────────────────────────────────────────────────

  return <Animated.View style={innerContainerStyles}>{children}</Animated.View>;
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function withTransition(value: number | string) {
  'worklet';

  return withTiming(value, { duration: theme.transitions.base });
}

// ─── Constants ───────────────────────────────────────────────────────────────

const CONTAINER_BORDER_RADIUS = theme.radius.large;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: CONTAINER_BORDER_RADIUS,
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['4'],
    columnGap: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type RadioContainerItemAnimatedContainerProps = PropsWithChildren<{
  isSelected: boolean;
  isHovered: boolean;
  isDisabled?: boolean;
}>;

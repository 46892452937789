import React, { type ComponentProps, useRef } from 'react';
import type { View, ViewProps } from 'react-native';
import { Pressable, StyleSheet } from 'react-native';
import {
  BodyText,
  Icon,
  NavbarLinkBadge,
  theme,
  usePressableState,
} from '@sg/garnish';

import { DESKTOP_HEADER_ITEM_SPACING } from '../../constants';
import type { DesktopHeaderBaseItemProps } from '../../DesktopHeader.types';

// ─────────────────────────────────────────────────────────────────────────────

export const DesktopHeaderItem = (props: DesktopHeaderItemProps) => {
  const {
    title,
    isActive,
    iconName,
    hasBadge,
    style,
    accessibilityLabel,
    testID,
    ...restProps
  } = props;

  const pressableRef = useRef<View>(null);
  const pressableState = usePressableState(pressableRef);

  const shouldHighlightItem = pressableState.isHovered || isActive;

  // ─── Styles ──────────────────────────────────────────────────────────

  const pressableStyles = [styles.pressable, style];
  const textStyles = [
    styles.text,
    shouldHighlightItem ? styles.textActive : {},
  ];
  const iconColor = shouldHighlightItem
    ? theme.colors.KALE
    : theme.colors.BLACK;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      ref={pressableRef}
      testID={testID}
      accessibilityRole="button"
      accessibilityLabel={accessibilityLabel ?? title}
      style={pressableStyles}
      {...restProps}
    >
      {title ? (
        <BodyText sizeMatch={['16']} style={textStyles} numberOfLines={1}>
          {title}
        </BodyText>
      ) : null}

      {iconName ? <Icon name={iconName} color={iconColor} /> : null}

      {hasBadge ? <NavbarLinkBadge testID={`${testID}.badge`} /> : null}
    </Pressable>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    marginHorizontal: DESKTOP_HEADER_ITEM_SPACING,
  },
  text: {
    textTransform: 'uppercase',
  },
  textActive: {
    textDecorationLine: 'underline',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type DesktopHeaderItemProps = Readonly<{
  title?: string;
  isActive: boolean;
  iconName?: ComponentProps<typeof Icon>['name'];
  hasBadge?: boolean;
  style?: ViewProps['style'];
}> &
  DesktopHeaderBaseItemProps;

import { defineMessages } from 'react-intl';

export const giftCardOrderMessages = defineMessages({
  title: {
    defaultMessage: 'Gift card order',
    description: 'Gift card order | Title',
  },
  recipientNameWithPrefix: {
    defaultMessage: 'To {recipient}',
    description: 'Gift card order | Recipient name with prefix',
  },
  viaMessage: {
    defaultMessage: 'Via message',
    description: 'Gift card order | Via message',
  },
  viaEmail: {
    defaultMessage: 'Via email',
    description: 'Gift card order | Via message',
  },
  pressableA11yLabel: {
    defaultMessage:
      'Navigate to the gift card confirmation screen (order ID: {id})',
    description:
      'Gift card order | Navigate to the gift card confirmation screen',
  },
});

/* istanbul ignore file */

import type { Client } from 'urql';

import {
  LocationsSearchByAreaWithDisclosureFieldsDocument,
  type LocationsSearchByAreaWithDisclosureFieldsQuery,
} from '@order/graphql';

import type { LastPlacedOrderDetailsQuery } from '../../graphql/LastPlacedOrders.generated';
import { LastPlacedOrderDetailsDocument } from '../../graphql/LastPlacedOrders.generated';
import type {
  LocationsSearchByAreaQuery,
  LocationsSearchByAreaQueryVariables,
} from '../../graphql/LocationsSearchByArea.generated';
import { LocationsSearchByAreaDocument } from '../../graphql/LocationsSearchByArea.generated';
import type {
  LocationsSearchBySearchStringQuery,
  LocationsSearchBySearchStringQueryVariables,
  LocationsSearchBySearchStringWithDisclosureFieldsQuery,
} from '../../graphql/LocationsSearchBySearchString.generated';
import {
  LocationsSearchBySearchStringDocument,
  LocationsSearchBySearchStringWithDisclosureFieldsDocument,
} from '../../graphql/LocationsSearchBySearchString.generated';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Queries locations list based on provided search string
 */
export async function locationsSearchByStringQuery(
  client: Client,
  variables: LocationsSearchBySearchStringQueryVariables,
) {
  const { searchString } = variables;
  const queryLocations = client.query<LocationsSearchBySearchStringQuery>;

  return queryLocations(
    LocationsSearchBySearchStringDocument,
    { searchString },
    { requestPolicy: 'cache-and-network' },
  ).toPromise();
}

/**
 * Queries locations list (+ extra disclosure fields) based on provided search string
 */
export async function locationsSearchByStringWithDisclosureFieldsQuery(
  client: Client,
  variables: LocationsSearchBySearchStringQueryVariables,
) {
  const { searchString } = variables;
  const queryLocations =
    client.query<LocationsSearchBySearchStringWithDisclosureFieldsQuery>;

  return queryLocations(
    LocationsSearchBySearchStringWithDisclosureFieldsDocument,
    { searchString },
    { requestPolicy: 'cache-and-network' },
  ).toPromise();
}

/**
 * Queries locations based on provided search area
 */
export async function locationsSearchByAreaQuery(
  client: Client,
  variables: LocationsSearchByAreaQueryVariables,
) {
  const queryLocations = client.query<LocationsSearchByAreaQuery>;

  return queryLocations(LocationsSearchByAreaDocument, variables, {
    requestPolicy: 'cache-and-network',
  }).toPromise();
}

/**
 * Queries locations (+ extra disclosure fields) based on provided search area
 */
export async function locationsSearchByAreaWithDisclosureFieldsQuery(
  client: Client,
  variables: LocationsSearchByAreaQueryVariables,
) {
  const queryLocations =
    client.query<LocationsSearchByAreaWithDisclosureFieldsQuery>;

  return queryLocations(
    LocationsSearchByAreaWithDisclosureFieldsDocument,
    variables,
    {
      requestPolicy: 'cache-and-network',
    },
  ).toPromise();
}

/**
 * Queries last placed orders details
 */
export async function lastPlacedOrdersDetailsQuery(client: Client) {
  return client
    .query<LastPlacedOrderDetailsQuery>(
      LastPlacedOrderDetailsDocument,
      { input: { page: 1, take: 10 } },
      { requestPolicy: 'cache-first' },
    )
    .toPromise();
}

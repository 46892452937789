import type { ReactElement } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { LoadingDots as LoadingDotAni } from '../../LoadingDots';
import type { LoadingDotsProps } from '../Button.types';
import { useTextStyles } from '../styles';

export const LOADING_DOTS_TEST_ID = 'button-focus-ring';

export const LoadingDots = (props: LoadingDotsProps): ReactElement => {
  const { palette, size, hasDisabledStyle, uiEventState } = props;

  const useStyleState = useTextStyles(palette, size);
  // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ViewStyle... Remove this comment to see the full error message
  const { color } = StyleSheet.flatten(
    useStyleState(hasDisabledStyle, uiEventState),
  );

  return (
    <View testID={LOADING_DOTS_TEST_ID}>
      <LoadingDotAni color={color} />
    </View>
  );
};

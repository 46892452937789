import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderLineItemDetails = (props: OrderLineItemDetailsProps) => {
  const {
    lineItemName,
    lineItemDescription,
    removedIngredientsText,
    addedIngredientsText,
    textAlignment = 'left',
  } = props;

  const textStyles = textAlignment === 'center' ? styles.textCenter : undefined;

  return (
    <View style={styles.container}>
      <BodyText sizeMatch={['18']} style={textStyles}>
        {lineItemName}
      </BodyText>

      <View>
        {addedIngredientsText ? (
          <BodyText sizeMatch={['14']} style={textStyles}>
            {addedIngredientsText}
          </BodyText>
        ) : null}

        {removedIngredientsText ? (
          <BodyText sizeMatch={['14']} style={textStyles}>
            {removedIngredientsText}
          </BodyText>
        ) : null}

        {lineItemDescription ? (
          <BodyText sizeMatch={['14']} style={textStyles}>
            {lineItemDescription}
          </BodyText>
        ) : null}
      </View>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: theme.spacing['1'],
  },
  textCenter: {
    textAlign: 'center',
  },
});

// ─────────────────────────────────────────────────────────────────────────────

type OrderLineItemDetailsProps = {
  lineItemName: string;
  lineItemDescription: string;
  addedIngredientsText: string | null;
  removedIngredientsText: string | null;
  textAlignment?: 'left' | 'center';
};

/* cSpell:ignore Slidedown */

import { type NotificationClickEvent } from 'react-native-onesignal';
import OneSignal from 'react-onesignal';

import { getEnvVars } from '@order/utils';

import { ONE_SIGNAL } from './one-signal.constants';
import type { OneSignalUser } from './one-signal.types';

// ─── Session ─────────────────────────────────────────────────────────────────

export async function initOneSignal(
  // NOTE: We maintain '_onNotificationOpen' here for the sake of TS.
  //       That listener is only utilized in native platforms.
  _onNotificationOpen?: OnNotificationOpen,
): Promise<boolean> {
  if (!checkIfCanEnableOneSignal()) return false;

  const { ONE_SIGNAL_APP_ID } = getEnvVars();

  await OneSignal.init({ appId: ONE_SIGNAL_APP_ID as string });

  return true;
}

export async function startOneSignalUserSession(user: OneSignalUser) {
  if (!checkIfCanEnableOneSignal()) return;

  if (!user.id) return;

  await OneSignal.login(user.id);
  OneSignal?.User.addTag(ONE_SIGNAL.TAGS.EXTERNAL_ID, user.id);

  if (user.email) {
    OneSignal.User.addEmail(user.email);
  }
}

export async function resetOneSignalUserSession() {
  if (!checkIfCanEnableOneSignal()) return;

  await OneSignal.logout();
}

// ─── Tags ────────────────────────────────────────────────────────────────────

/**
 * Adds a data tag to the current One Signal customer.
 *
 * @see {https://documentation.onesignal.com/docs/add-user-data-tags}
 * @see {https://documentation.onesignal.com/docs/react-native-expo-sdk-setup#add-data-tags}
 */
export function addDataTag(key: string, value: string) {
  if (!checkIfCanEnableOneSignal()) return;

  OneSignal?.User.addTag(key, value);
}

/**
 * Removes the provided data tag from the current One Signal customer.
 *
 * @see {https://documentation.onesignal.com/docs/add-user-data-tags}
 */
export function removeDataTag(key: string) {
  if (!checkIfCanEnableOneSignal()) return;

  OneSignal?.User.removeTag(key);
}

// ─────────────────────────────────────────────────────────────────────────────

export { default as OneSignal } from 'react-onesignal';

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * A small helper that checks if One Signal can be enabled in the
 * current environment.
 */
function checkIfCanEnableOneSignal() {
  const { ONE_SIGNAL_APP_ID } = getEnvVars();
  const isAppIdProvided = typeof ONE_SIGNAL_APP_ID === 'string';

  // NOTE: Our One Signal setup only supports remote domains, therefore, we
  //       disable it for local development to avoid errors.
  const isRunningLocally = window.location.hostname === 'localhost';

  return isAppIdProvided && !isRunningLocally;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type OnNotificationOpen = (openedEvent: NotificationClickEvent) => void;

import { useMemo, useState } from 'react';
import type { TabItem, TabItemId, TabItems } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

export const useScanInStoreTabs = () => {
  const { t } = useLocalizationContext();

  // ─────────────────────────────────────────────────────────────────────

  const earnAndRedeemTab = useMemo<TabItem>(
    () => ({
      id: 'scan-in-store.earn-and-redeem-tab',
      panelId: 'scan-in-store.earn-and-redeem-tab-panel',
      label: t('scan-in-store.tabs.earn-and-redeem.title'),
    }),
    [t],
  );
  const payTab = useMemo<TabItem>(
    () => ({
      id: 'scan-in-store.pay-tab',
      panelId: 'scan-in-store.pay-tab-panel',
      label: t('scan-in-store.tabs.pay.title'),
    }),
    [t],
  );

  const tabs = useMemo<TabItems>(
    () => [earnAndRedeemTab, payTab],
    [earnAndRedeemTab, payTab],
  );

  const [activeTabItemId, setActiveTabItemId] = useState<TabItemId>(
    earnAndRedeemTab.id,
  );

  // ─────────────────────────────────────────────────────────────────────

  return {
    activeTabItemId,
    earnAndRedeemTab,
    payTab,
    tabs,
    setActiveTabItemId,
  };
};

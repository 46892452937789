import { useCallback } from 'react';
import { useToggleState } from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';

// ─────────────────────────────────────────────────────────────────────────────

export const useOrderCancellationModal = () => {
  const { toggleOn, toggleOff, value } = useToggleState();
  const { track } = useTelemetry();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const showOrderCancellationModal = useCallback(() => {
    toggleOn();

    track('order_cancel.tapped');
  }, [toggleOn, track]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    isOrderCancellationModalVisible: value,
    showOrderCancellationModal,
    hideOrderCancellationModal: toggleOff,
  };
};

import React, { type ComponentProps, useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { theme } from '@garnish/constants';

import { webOnlyStyles } from '../../../../utils';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductCardV2Container = (props: ProductCardV2ContainerProps) => {
  const { children, canHover, style, ...viewProps } = props;

  const ref = useRef(null);
  const isHovered = useHover(ref);

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    canHover && isHovered ? styles.containerHover : undefined,
    canHover ? containerWebStyles : undefined,
    style,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View ref={ref} style={containerStyles} {...viewProps}>
      {children}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    borderRadius: theme.radius.xlarge,
    paddingTop: theme.spacing['2'],
    paddingHorizontal: theme.spacing['4'],
    paddingBottom: theme.spacing['6'],
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    rowGap: theme.spacing['1'],
  },
  containerHover: {
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTEST,
  },
});

const containerWebStyles = webOnlyStyles({
  cursor: 'pointer',
  transition: `background-color ${theme.transitions.base}ms`,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ProductCardV2ContainerProps = ComponentProps<typeof View> & {
  canHover: boolean;
};

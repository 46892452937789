import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View, type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';
import {
  BodyText,
  type CloudinaryTransformConfig,
  FallbackImage,
  IllusEmpty_2,
  LoadingAnimation,
} from '@sg/garnish';

/**
 * Info regarding loyalty rewards availability.
 */
export const LoyaltyInfoBenefits = (props: LoyaltyInfoBenefitsProps) => {
  const {
    palette = 'cream',
    loyaltyInfoBenefits,
    availablePoints,
    style,
    isLoading,
  } = props;
  const paletteStyle = palette ? paletteStyles[palette] : {};

  return (
    <View style={[styles.container, paletteStyle, style]}>
      {isLoading && loyaltyInfoBenefits.length === 0 ? (
        <LoadingAnimation />
      ) : null}

      {loyaltyInfoBenefits.map((loyaltyInfoBenefit) => (
        <LoyaltyInfoBenefitsRow
          key={loyaltyInfoBenefit.title}
          availablePoints={availablePoints}
          loyaltyInfoBenefit={loyaltyInfoBenefit}
        />
      ))}
    </View>
  );
};

const LoyaltyInfoBenefitsRow = (props: LoyaltyInfoBenefitsRowProps) => {
  const {
    availablePoints,
    loyaltyInfoBenefit: { title, points, imageUrl },
  } = props;

  const hasEnoughPoints = availablePoints >= points;

  const wrapperStyle = [
    styles.pointsWrapper,
    hasEnoughPoints ? null : styles.pointsWrapperUnusable,
  ];

  const pointsStyle = [
    styles.points,
    hasEnoughPoints ? null : styles.lockedPoints,
  ];

  const imageStyle = [
    styles.image,
    hasEnoughPoints ? null : styles.lockedImage,
  ];

  const titleStyle = [
    styles.title,
    hasEnoughPoints ? null : styles.lockedTitle,
  ];

  const { formatMessage } = useIntl();

  return (
    <View style={styles.reward}>
      <View style={wrapperStyle}>
        <BodyText bold sizeMatch={['12']} style={pointsStyle}>
          {points}
        </BodyText>
      </View>

      <FallbackImage
        contentFit="contain"
        style={imageStyle}
        defaultImage={IllusEmpty_2}
        baseUrl={imageUrl}
        cloudinaryConfig={cloudinaryConfig}
        aria-label={formatMessage(messages.accessibilityLabel)}
      />

      <BodyText style={titleStyle} sizeMatch={['16']}>
        {title}
      </BodyText>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Loyalty reward',
    description: 'Bag | Loyalty info | Accessibility Label',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.CREAM,
    borderColor: theme.colors.KALE,
    borderRadius: theme.radius.medium,
    borderWidth: 1,
    margin: theme.spacing['4'],
    marginTop: theme.spacing['16'],
    minHeight: theme.spacing['24'],
  },
  reward: {
    flexDirection: 'row',
    gap: theme.spacing['2'],
    paddingHorizontal: theme.spacing['4'],
    alignItems: 'center',
  },
  pointsWrapper: {
    height: theme.spacing['6'],
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: theme.spacing['2'],
    borderRadius: theme.radius.xxxlarge,
    backgroundColor: theme.colors.BASIL,
  },
  pointsWrapperUnusable: {
    backgroundColor: theme.colors.CREAM,
    borderColor: theme.colors.BASIL,
    borderWidth: 1,
  },
  points: {
    marginTop: 2,
    textAlign: 'center',
    minWidth: 50,
    color: theme.colors.CREAM,
  },
  lockedPoints: {
    color: theme.colors.BASIL,
  },
  image: {
    width: 60,
    height: 60,
  },
  lockedImage: {
    opacity: 0.8,
  },
  title: {
    flex: 1,
  },
  lockedTitle: {
    color: theme.colors.CHARCOAL,
  },
  cream: {
    backgroundColor: theme.colors.CREAM,
  },
  oatmeal: {
    backgroundColor: theme.colors.OATMEAL,
  },
});

const cloudinaryConfig: CloudinaryTransformConfig = {
  crop: 'crop',
  height: 0.3,
  width: 0.3,
};

const paletteStyles = {
  cream: styles.cream,
  oatmeal: styles.oatmeal,
};

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyInfoBenefitsProps = {
  availablePoints: number;
  loyaltyInfoBenefits: LoyaltyInfoBenefit[];
  isLoading: boolean;
  palette?: 'cream' | 'oatmeal';
  style?: ViewStyle;
};

type LoyaltyInfoBenefitsRowProps = {
  availablePoints: number;
  loyaltyInfoBenefit: LoyaltyInfoBenefit;
};

type LoyaltyInfoBenefit = {
  title: string;
  points: number;
  imageUrl?: string;
};

import React from 'react';

import { useLocalizationContext } from '@order/Localization';

import {
  AccountScreenContainer,
  AccountScreenSection,
  AccountScreenTitle,
} from '../AccountScreenContainer';
import {
  SkeletonLoadingContentLG,
  SkeletonLoadingContentSM,
  SkeletonLoadingHeaderXS,
} from './AccountSkeletonLoading';

export const AccountReferFriendLoading = () => {
  const { t } = useLocalizationContext();

  return (
    <AccountScreenContainer screen="refer-friend">
      <AccountScreenTitle title={t('account.refer-friend.title')} />

      <AccountScreenSection>
        {/* Image */}
        <SkeletonLoadingContentLG />

        {/* Info */}
        <SkeletonLoadingHeaderXS />
        <SkeletonLoadingContentSM />

        {/* Share */}
        <SkeletonLoadingHeaderXS />
        <SkeletonLoadingContentSM />

        {/* Copy */}
        <SkeletonLoadingHeaderXS />
        <SkeletonLoadingContentSM />
      </AccountScreenSection>
    </AccountScreenContainer>
  );
};

import React, { useCallback } from 'react';
import type { ViewProps } from 'react-native';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { UpsellCta } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useSweetpassTier } from '@order/hooks';
import type { TelemetryEventPayload } from '@order/Telemetry';
import { useTelemetry } from '@order/Telemetry';

import type {
  AuthStackParamList,
  ModalStackParamList,
} from '../../navigation/AppNavigation.props';
import { useSweetpassUpsellCtaContentType } from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders Sweetpass Upsell CTA using remote content from Contentful.
 * It has a `loading` state and fallback values.
 */
export const SweetpassUpsellCta = (props: SweetpassUpsellCtaProps) => {
  const {
    redirectParamsForSweetpassUpgradeScreen,
    redirectParamsForLoginScreen = { redirect: 'sweetpassUpgrade' },
    containerStyle,
    testID,
    contentTag,
    contentFallback,
    telemetryPayload,
  } = props;

  const { navigate } = useNavigation();
  const isLoggedIn = useIsLoggedIn();
  const { track } = useTelemetry();
  const { sweetpassTierName } = useSweetpassTier();
  const { fields, fetching } = useSweetpassUpsellCtaContentType({
    contentTag,
    contentFallback,
  });

  const {
    text,
    textHighlights,
    iconText,
    actionText,
    accessibilityLabel,
    accessibilityHint,
  } = fields;

  // ─── Helpers ─────────────────────────────────

  const navigateToSweetpassUpgradeScreen = useCallback(() => {
    if (isLoggedIn) {
      navigate('Modal', {
        screen: 'SweetpassUpgrade',
        params: redirectParamsForSweetpassUpgradeScreen,
      });

      return;
    }

    navigate('Auth', {
      screen: 'Login',
      params: redirectParamsForLoginScreen,
    });
  }, [
    isLoggedIn,
    navigate,
    redirectParamsForLoginScreen,
    redirectParamsForSweetpassUpgradeScreen,
  ]);

  const trackOnPressEvent = useCallback(() => {
    track('sweetpass_upsell_cta.press', telemetryPayload);
  }, [telemetryPayload, track]);

  const onPress = useCallback(() => {
    trackOnPressEvent();
    navigateToSweetpassUpgradeScreen();
  }, [navigateToSweetpassUpgradeScreen, trackOnPressEvent]);

  // ─────────────────────────────────────────────

  // Prevent component rendering if the customer's Sweetpass tier is not upgradeable.
  if (isLoggedIn && sweetpassTierName !== 'SWEETPASS') return null;

  return (
    <View style={containerStyle}>
      <UpsellCta
        isLoading={fetching}
        text={text}
        textHighlights={textHighlights}
        actionText={actionText}
        iconText={iconText}
        accessibilityLabel={accessibilityLabel}
        accessibilityHint={accessibilityHint}
        onPress={onPress}
        testID={testID}
      />
    </View>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassUpsellCtaProps = Readonly<{
  redirectParamsForSweetpassUpgradeScreen?: ModalStackParamList['SweetpassUpgrade'];
  redirectParamsForLoginScreen?: AuthStackParamList['Login'];
  containerStyle?: ViewProps['style'];
  contentTag?: SweetpassUpsellCtaContentParams['contentTag'];
  contentFallback?: SweetpassUpsellCtaContentParams['contentFallback'];
  testID?: string;

  /**
   * An optional payload that can be used to track the
   * Sweetpass Upsell CTA `onPress` event.
   */
  telemetryPayload?: TelemetryEventPayload<'sweetpass_upsell_cta.press'>;
}>;

type SweetpassUpsellCtaContentParams = Parameters<
  typeof useSweetpassUpsellCtaContentType
>[0];

import { checkIfInExpoGoClient, getEnvVars } from '@order/utils';

// ────────────────────────────────────────────────────────────────────────────────

export function runIfBrazeCredentialsProvided<Callback>(cb: Callback) {
  const { BRAZE_APP_ID_WEB, BRAZE_APP_SDK_ENDPOINT } = getEnvVars();
  const areCredentialsProvided = Boolean(
    BRAZE_APP_ID_WEB && BRAZE_APP_SDK_ENDPOINT,
  );

  if (!areCredentialsProvided) return;

  return cb;
}

export function runIfInNativeApp<Callback>(cb: Callback) {
  if (checkIfInExpoGoClient()) return;

  return cb;
}

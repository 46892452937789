/* eslint-disable functional/immutable-data */

import React, { type ComponentProps, useRef } from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../hooks';
import { webOnlyStyles } from '../../utils';
import { FadeView } from '../FadeView';
import { Icon } from '../Icon';
import { TagAlert } from '../TagAlert';
import { BodyText } from '../Text';

export const ListAction = (props: ListActionProps) => {
  const { iconName, children, ...wrapperProps } = props;

  return (
    <ListActionWrapper {...wrapperProps}>
      <ListActionIcon iconName={iconName} />
      <ListActionContent>{children}</ListActionContent>
    </ListActionWrapper>
  );
};

//
// ─── TYPES ──────────────────────────────────────────────────────────────────────
//

export type ListActionProps = Pick<ListActionIconProps, 'iconName'> &
  ListActionWrapperProps &
  ViewProps;

type ListActionIconProps = Readonly<{
  iconName?: ComponentProps<typeof Icon>['name'];
}> &
  Omit<ComponentProps<typeof Icon>, 'name'>;

type ListActionTextProps = ComponentProps<typeof BodyText>;

type ListActionWrapperProps = Readonly<{
  // sets whether a pressable states (hover, active, etc.) styles should be used
  hasPressableStyles?: boolean;
}> &
  ViewProps;

//
// ─── CONSTANTS ──────────────────────────────────────────────────────────────────
//

const LIST_ACTION_WRAPPER_HOVER_OFFSET = -4;

//
// ─── STYLES ─────────────────────────────────────────────────────────────────────
//

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: theme.spacing['6'],
  },
  wrapperInner: {
    position: 'relative',
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperPressableBackdrop: {
    position: 'absolute',
    top: LIST_ACTION_WRAPPER_HOVER_OFFSET,
    right: LIST_ACTION_WRAPPER_HOVER_OFFSET,
    bottom: LIST_ACTION_WRAPPER_HOVER_OFFSET,
    left: LIST_ACTION_WRAPPER_HOVER_OFFSET,
  },
  wrapperPressableBackdropInner: {
    flexGrow: 1,
    borderRadius: theme.radius.small,
  },
  wrapperHoverBackground: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
  wrapperPressedBackground: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
  },

  iconWrapper: {
    flexShrink: 0,
    paddingRight: theme.spacing['4'],
  },
  contentWrapper: {
    flex: 1,
  },
  tag: {
    color: theme.colors.CHARCOAL,
    marginHorizontal: theme.spacing['2'],
  },
});

//
// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────
//

const ListActionWrapper = (props: ListActionWrapperProps) => {
  const {
    children,
    style,
    hasPressableStyles,
    testID = 'sg-list-action-wrapper',
    ...pressableProps
  } = props;

  const wrapperRef = useRef<View>(null);

  const { isHovered, isActive } = usePressableState(wrapperRef);
  const shouldUsePressableStyles = Boolean(
    hasPressableStyles && (isHovered || isActive),
  );

  const wrapperStyles = [
    styles.wrapper,
    shouldUsePressableStyles && webOnlyStyles({ userSelect: 'none' }),
    style,
  ];
  const wrapperPressableStyles = [
    styles.wrapperPressableBackdropInner,
    styles.wrapperHoverBackground,
    isActive && styles.wrapperPressedBackground,
  ];

  return (
    <View
      {...pressableProps}
      ref={wrapperRef}
      testID={testID}
      style={wrapperStyles}
    >
      <View style={styles.wrapperInner}>
        {/* the separate backdrop element is used to match the design,
            where hovered/active background should be scaled a little */}
        <FadeView
          show={shouldUsePressableStyles}
          style={styles.wrapperPressableBackdrop}
          pointerEvents="none"
        >
          <View style={wrapperPressableStyles} pointerEvents="none" />
        </FadeView>

        {children}
      </View>
    </View>
  );
};

const ListActionIcon = (props: ListActionIconProps) => {
  const { iconName, ...rest } = props;

  if (!iconName) return null;

  return (
    <View style={styles.iconWrapper}>
      <Icon name={iconName} {...rest} />
    </View>
  );
};

const ListActionContent = (props: ViewProps) => {
  const { children, style, ...viewProps } = props;
  const contentStyles = [styles.contentWrapper, style];

  if (!children) return null;

  return (
    <View style={contentStyles} {...viewProps}>
      {children}
    </View>
  );
};

const ListActionTitle = (props: ListActionTextProps) => {
  const { children, bold = true, ...textProps } = props;

  if (!children) return null;

  return (
    <BodyText size={4} bold={bold} {...textProps}>
      {children}
    </BodyText>
  );
};

const ListActionText = (props: ListActionTextProps) => {
  const { children, size = 4, ...textProps } = props;

  if (!children) return null;

  return (
    <BodyText size={size} {...textProps}>
      {children}
    </BodyText>
  );
};

const ListActionTag = (props: ListActionTextProps) => {
  const { children, ...textProps } = props;

  if (!children) return null;

  return (
    <BodyText size={5} style={styles.tag} {...textProps}>
      {children}
    </BodyText>
  );
};

const ListActionNotice = (props: ComponentProps<typeof TagAlert>) => {
  const { children, ...tagAlertProps } = props;

  if (!children) return null;

  return (
    <TagAlert size="small" palette="neutral" {...tagAlertProps}>
      {children}
    </TagAlert>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

ListAction.Title = ListActionTitle;
ListAction.Text = ListActionText;
ListAction.Tag = ListActionTag;
ListAction.Notice = ListActionNotice;
ListAction.Wrapper = ListActionWrapper;
ListAction.Icon = ListActionIcon;
ListAction.Content = ListActionContent;

import type { SVGProps } from 'react';
import type React from 'react';
import { createElement } from 'react';
import type { ViewProps } from 'react-native';

import {
  LoyaltyIcons,
  MapIcons,
  MapLocationsIcons,
  PaymentIcons,
  ProgressIcons,
  SocialIcons,
  StandardIcons,
  UnsortedIcons,
} from '../../assets/images/svgs';

//
// ─── Component ───────────────────────────────────────────────────────────────
//

export const Icon = (props: IconProps): React.ReactElement => {
  const { name, color = 'black', ...passThruProps } = props;

  return createElement(allIcons[name], { color, ...passThruProps });
};

//
// ─── TYPES ───────────────────────────────────────────────────────────────────
//

export type IconName = keyof typeof allIcons;

type IconProps = Readonly<{
  name: IconName;
  color?: string;
}> &
  SVGProps<SVGSVGElement> &
  Pick<ViewProps, 'testID'>;

//
// ─── Icon Aliases ────────────────────────────────────────────────────────────
//

const IconAliases = {
  Bag: StandardIcons.IconBagFill,
  IconAdd: StandardIcons.IconBadgePlus,
  IconAlert: StandardIcons.IconBadgeAlert,
  IconBack: StandardIcons.IconBadgeCaretLeft,
  IconCircleClose: StandardIcons.IconBadgeClose,
  IconDelivery: StandardIcons.IconCar,
  IconEdit: StandardIcons.IconEdit,
  IconFaveFill: StandardIcons.IconHeartFill,
  IconFaveStroke: StandardIcons.IconHeartStroke,
  IconFb: SocialIcons.IconFacebook,
  IconFilter: StandardIcons.IconFilter,
  IconHamburger: StandardIcons.IconMenu,
  IconHelp: StandardIcons.IconBadgeHelp,
  IconInfo: StandardIcons.IconBadgeInfo,
  IconLinkBack: StandardIcons.IconArrowLeft,
  IconLinkGo: StandardIcons.IconArrowRight,
  IconLinkOut: StandardIcons.IconArrowOut,
  IconMinusDisabled: StandardIcons.IconBadgeMinus,
  IconNavClose: StandardIcons.IconBadgeClose,
  IconNavDown: StandardIcons.IconBadgeCaretDown,
  IconNavLeft: StandardIcons.IconBadgeCaretLeft,
  IconNavRight: StandardIcons.IconBadgeCaretRight,
  IconNavUp: StandardIcons.IconBadgeCaretUp,
  IconNotice: StandardIcons.IconBell,
  IconRemove: StandardIcons.IconBadgeTrash,
  IconScan: PaymentIcons.IconBadgeScan,
  IconSubtitles: StandardIcons.IconText,
  IconSubtract: StandardIcons.IconBadgeMinus,
  IconSuccess: StandardIcons.IconBadgeMinus,
};

//
// ─── Re-export Groups ────────────────────────────────────────────────────────
//

export { IconAliases };

export const allIcons = {
  ...ProgressIcons,
  ...IconAliases,
  ...StandardIcons,
  ...PaymentIcons,
  ...SocialIcons,
  ...MapLocationsIcons,
  ...MapIcons,
  ...LoyaltyIcons,
  ...UnsortedIcons,
};

export {
  LoyaltyIcons,
  MapIcons,
  MapLocationsIcons,
  PaymentIcons,
  ProgressIcons,
  SocialIcons,
  StandardIcons,
  UnsortedIcons,
} from '../../assets/images/svgs';

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  BodyText,
  DisplayText,
  Modal,
  ScrollViewWithHeaderTitle,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import { useLineItemName } from '@order/shared/hooks';

import { AppFooter, LoadingAnimation } from '../../components';
import { ErrorFallback } from '../../components/ErrorBoundary';
import { SharedLineItem } from '../../components/SharedLineItem';
import type { LineItem, LineItemSuccess } from '../../graphql/types';
import type { ModalStackParamList } from '../../navigation/AppNavigation.props';
import { useReorderLineItem } from '../ReorderingScreen/hooks';
import { useGetSharedLineItemQuery } from './GraphQL/ShareLineItemScreen.generated';

export const ShareLineItemScreen = ({
  route,
}: NativeStackScreenProps<ModalStackParamList, 'ShareLineItem'>) => {
  const { t } = useLocalizationContext();
  const { lineItemSlug } = route.params ?? {};

  // ─────────── Queries ──────────
  const [{ fetching, data }] = useGetSharedLineItemQuery({
    variables: { input: { slug: lineItemSlug } },
  });

  // ─────────── Data ─────────────
  const lineItemBySlug = data?.lineItemBySlug as LineItemSuccess;
  const error = t('general.error');

  // ────── Render Control ────────
  if (fetching) return <LoadingAnimation />;

  if (!lineItemBySlug) return <ErrorFallback error={error} />;

  return <ShareLineItemContentScreen lineItem={lineItemBySlug.lineItem} />;
};

const ShareLineItemContentScreen = ({
  lineItem,
}: Readonly<{
  lineItem: LineItem;
}>) => {
  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();
  const navigation = useNavigation();

  const startReorderLineItem = useReorderLineItem(lineItem);
  const productName = useLineItemName(lineItem.product, lineItem);

  React.useLayoutEffect(() => {
    navigation.setOptions({ title: productName });
  }, [navigation, productName]);

  return (
    <ScrollViewWithHeaderTitle
      style={styles.body}
      contentContainerStyle={styles.grow}
    >
      <Modal.Row
        style={
          currentBreakpoint.isXS ? styles.headerMobile : styles.headerDesktop
        }
      >
        <DisplayText style={styles.title} size={3}>
          {productName}
        </DisplayText>
        <BodyText style={styles.customerName} size={3}>
          {t('account.shared-by', { name: lineItem.customerName })}
        </BodyText>
      </Modal.Row>
      <Modal.Row
        style={[styles.content, currentBreakpoint.isXS && styles.contentMobile]}
      >
        <View style={!currentBreakpoint.isXS && styles.productDesktop}>
          <SharedLineItem
            lineItem={lineItem}
            onAddToBag={startReorderLineItem}
          />
        </View>
      </Modal.Row>
      <AppFooter />
    </ScrollViewWithHeaderTitle>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  grow: {
    flexGrow: 1,
  },
  body: {
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  headerDesktop: {
    paddingTop: theme.spacing['4'],
    alignItems: 'center',
  },
  headerMobile: {
    alignItems: 'flex-start',
  },
  title: {
    paddingTop: theme.spacing['6'],
  },
  customerName: {
    paddingTop: theme.spacing['3'],
  },
  content: {
    alignItems: 'center',
    paddingVertical: theme.spacing['10'],
  },
  contentMobile: {
    paddingVertical: theme.spacing['6'],
  },
  productDesktop: {
    maxWidth: 382,
  },
});

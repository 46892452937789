/**
 * Returns count as a number based on the async storage value.
 *
 * NOTE: If the corresponding storage entry is unavailable or invalid, `0` will be returned.
 */
export function getCounterValueWithFallback(maybeCountAsString: string | null) {
  if (typeof maybeCountAsString !== 'string') return 0;

  const countAsNumber = Number(maybeCountAsString);
  const isValidCount = !Number.isNaN(countAsNumber);

  return isValidCount ? countAsNumber : 0;
}

/**
 * Returns incremented counter value (by one) as a string and a number based on the original value.
 */
export function getIncrementedCounterValue(currentCounterValue: number) {
  const incrementedValue = currentCounterValue + 1;
  const incrementedValueAsString = `${incrementedValue}`;

  return { value: incrementedValue, valueAsString: incrementedValueAsString };
}

/**
 * Returns decremented counter value (by one) as a string and a number based on the original value.
 */
export function getDecrementedCounterValue(currentCounterValue: number) {
  const decrementedValue = currentCounterValue - 1;
  const decrementedValueAsString = `${decrementedValue}`;

  return { value: decrementedValue, valueAsString: decrementedValueAsString };
}

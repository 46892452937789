import React, { type PropsWithChildren } from 'react';
import { BodyText } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsSubheading = (props: PropsWithChildren) => {
  const { children } = props;

  return <BodyText sizeMatch={['14', '18']}>{children}</BodyText>;
};

import { useCallback } from 'react';

import type { DeliveryOrderDetailInput, PartialProduct } from '@order/graphql';
import {
  useAddLineItemToCart,
  useNavigateToProductDetails,
} from '@order/hooks';

export const useProductCallbacks = ({
  product,
  restaurantSlug,
  deliveryAddressId,
  deliveryDetails,
  onSuccess,
  onError,
}: UseProductCallbacksProps) => {
  const { addLineItemToCart, fetching } = useAddLineItemToCart({
    onSuccess,
    onError,
  });
  const navigateToProduct = useNavigateToProductDetails({
    restaurantSlug,
    deliveryAddressId,
  });

  const handleCardPress = useCallback(() => {
    navigateToProduct(product);
  }, [product, navigateToProduct]);

  const handleAddToBag = useCallback(
    async (quantity: number) => {
      await addLineItemToCart({ product, deliveryDetails, quantity });
    },
    [product, deliveryDetails, addLineItemToCart],
  );

  return { handleCardPress, handleAddToBag, isLoading: fetching };
};

type AddLineItemToCartProps = NonNullable<
  Parameters<typeof useAddLineItemToCart>[0]
>;

type UseProductCallbacksProps = Readonly<{
  product: PartialProduct;
  restaurantSlug?: string;
  deliveryAddressId?: string;
  deliveryDetails?: DeliveryOrderDetailInput;
}> &
  AddLineItemToCartProps;

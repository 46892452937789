import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  Scrollspy,
  theme,
  useContainerSpacing,
  useResponsive,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationIngredientsGridNav = (
  props: CustomizationIngredientsGridNavProps,
) => {
  const { children, ...restProps } = props;

  const { match } = useResponsive();
  const containerSpacing = useContainerSpacing();

  return (
    <View
      style={[
        styles.container,
        match([styles.containerXS, styles.containerSM]),
      ]}
    >
      <Scrollspy.Nav
        gap={theme.spacing['4']}
        stagePadding={containerSpacing}
        {...restProps}
      >
        {children}
      </Scrollspy.Nav>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.DARK_KALE,
    backgroundColor: theme.colors.CREAM,
  },
  containerXS: {
    paddingVertical: theme.spacing['4'],
  },
  containerSM: {
    paddingVertical: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationIngredientsGridNavProps = ComponentProps<
  typeof Scrollspy.Nav
>;

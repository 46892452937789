import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Pressable, StyleSheet, View } from 'react-native';
import { type AddressType, useToggleState } from '@sg/garnish';
import { Button, useResponsive } from '@sg/garnish';
import { BodyText, capitalizeFirstLetter, Card, theme } from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';
import { ignoreTimezone } from '@order/utils';

import {
  useKustomerAvailability,
  useKustomerContactUsHandler,
} from '../../integrations/kustomer';
import {
  OrderCancellationModal,
  useOrderCancellationModal,
} from '../OrderCancellationModal';
import { OrderCardImage } from './components';

export const OrderCard = (props: OrderCardProps) => {
  const {
    orderId,
    restaurantId,
    isCanceled,
    isCancellable,
    onPress,
    onOrderCancellationSuccess,
  } = props;

  const { formatMessage } = useIntl();

  const shouldRenderCanceledLabel = isCanceled && !isCancellable;

  return (
    <Card hasHoverState>
      <Pressable
        accessibilityRole="button"
        aria-label={formatMessage(messages.orderCardPressableA11yLabel, {
          order_id: orderId,
        })}
        onPress={onPress}
        style={styles.cardPressable}
      />

      <View style={styles.content} pointerEvents="box-none">
        <View pointerEvents="none">
          <OrderCardImage {...props} />
        </View>

        <View style={styles.info} pointerEvents="box-none">
          <View pointerEvents="none">
            <OrderCardTitle {...props} />
            <OrderCardLocation {...props} />
            <OrderCardDescription {...props} />

            <View style={styles.priceAndStatusContainer}>
              {shouldRenderCanceledLabel ? <OrderCardCanceledLabel /> : null}
              <OrderCardTotal {...props} />
            </View>
          </View>

          {isCancellable ? (
            <OrderCardQuickCancel
              orderId={orderId}
              restaurantId={restaurantId}
              onOrderCancellationSuccess={onOrderCancellationSuccess}
            />
          ) : null}
        </View>
      </View>
    </Card>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const OrderCardTitle = ({
  wantedTime,
  orderType,
}: Pick<OrderCardProps, 'wantedTime' | 'orderType'>) => {
  const { t } = useLocalizationContext();

  const normalizedWantedTime = ignoreTimezone(wantedTime);
  const capitalizedOrderType = capitalizeFirstLetter(orderType);
  const title = normalizedWantedTime
    ? t('account.order-card.title', {
        wantedTime: normalizedWantedTime,
        orderType: capitalizedOrderType,
      })
    : capitalizedOrderType;

  return <BodyText bold>{title}</BodyText>;
};

// ────────────────────────────────────────────────────────────────────────────────

const OrderCardLocation = ({
  orderType,
  restaurantName,
  addressName,
}: Pick<OrderCardProps, 'orderType' | 'restaurantName' | 'addressName'>) => (
  <BodyText sizeMatch={['14', '16']}>
    {orderType === 'delivery' && addressName
      ? capitalizeFirstLetter(addressName)
      : restaurantName}
  </BodyText>
);
// ────────────────────────────────────────────────────────────────────────────────

const OrderCardDescription = ({
  description,
}: Pick<OrderCardProps, 'description'>) => (
  <View style={styles.description}>
    <BodyText numberOfLines={2} ellipsizeMode="tail" sizeMatch={['14', '16']}>
      {description}
    </BodyText>
  </View>
);

// ────────────────────────────────────────────────────────────────────────────────

const OrderCardTotal = ({ total }: Pick<OrderCardProps, 'total'>) => {
  const { formatPrice } = useLocalizationContext();

  return (
    <BodyText sizeMatch={['14', '16']}>{formatPrice(total, 'USD')}</BodyText>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const OrderCardCanceledLabel = () => {
  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  return (
    <BodyText
      bold
      style={match([styles.isCanceledLabelXS, styles.isCanceledLabelSM])}
      sizeMatch={['14', '16']}
    >
      {t('account.orders.canceled')}
    </BodyText>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const OrderCardQuickCancel = (props: OrderCardQuickCancelProps) => {
  const { orderId, restaurantId, onOrderCancellationSuccess } = props;

  const { t } = useLocalizationContext();
  const { handleContactUs, handlingContactUs } = useKustomerContactUsHandler(
    useKustomerAvailability(),
    'order-history',
  );

  // ─── Flags ───────────────────────────────────────────────────────────

  const isInAppOrderCancellationEnabled = useFeatureFlag(
    'CELS-1476-in-app-order-cancellation',
  );

  // ─── State ───────────────────────────────────────────────────────────

  const {
    isOrderCancellationModalVisible,
    showOrderCancellationModal,
    hideOrderCancellationModal,
  } = useOrderCancellationModal();

  const {
    value: shouldHideCancelDuringAsyncCancelation,
    toggleOn: hideCancelDuringAsyncCancelation,
  } = useToggleState();

  // ─────────────────────────────────────────────────────────────────────

  const isLoading = isInAppOrderCancellationEnabled ? false : handlingContactUs;
  const onPress = isInAppOrderCancellationEnabled
    ? showOrderCancellationModal
    : handleContactUs;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      {shouldHideCancelDuringAsyncCancelation ? null : (
        <View style={styles.cancelButtonContainer} pointerEvents="auto">
          <Button palette="secondary" isLoading={isLoading} onPress={onPress}>
            {t('account.orders.cancel')}
          </Button>
        </View>
      )}

      {isInAppOrderCancellationEnabled ? (
        <OrderCancellationModal
          orderId={orderId}
          restaurantId={restaurantId}
          isVisible={isOrderCancellationModalVisible}
          onSuccess={onOrderCancellationSuccess}
          onAsyncCancelation={hideCancelDuringAsyncCancelation}
          onRequestClose={hideOrderCancellationModal}
        />
      ) : null}
    </>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  orderCardPressableA11yLabel: {
    defaultMessage: 'Open the {order_id} order status screen.',
    description: 'Order Card | Accessiblity label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing['6'],
    zIndex: 1,
  },
  cardPressable: {
    ...StyleSheet.absoluteFillObject,
    width: '100%',
  },
  info: {
    flex: 1,
    marginLeft: theme.spacing['4'],
  },
  description: {
    marginVertical: theme.spacing['2'],
  },
  cancelButtonContainer: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing['2'],
  },
  priceAndStatusContainer: {
    flexDirection: 'row',
    gap: theme.spacing['1'],
  },
  isCanceledLabelXS: {
    lineHeight: 20, // to match other text elements baseline
  },
  isCanceledLabelSM: {
    lineHeight: 24, // to match other text elements baseline
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderCardProps = Readonly<{
  orderId: string;
  orderType: AddressType;
  wantedTime: string;
  restaurantId: string;
  restaurantName?: string;
  addressName?: string;
  description: string;
  total: number;
  assetUrl: string;
  isCanceled: boolean;
  isCancellable?: boolean;
  onPress: () => void;
  onOrderCancellationSuccess: (orderId: string) => void;
}>;

type OrderCardQuickCancelProps = Readonly<{
  orderId: string;
  restaurantId: string;
  onOrderCancellationSuccess: (orderId: string) => void;
}>;

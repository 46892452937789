import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { type ImageSourcePropType } from 'react-native';
import { type Entry } from 'contentful';

import { useContentfulContentTypeEntry } from '@order/Contentful';

import heroImageFallback from '../assets/hero-image-fallback.png';
import heroLogoFallback from '../assets/hero-logo-fallback.png';
import lockIconFallback from '../assets/lock-icon-fallback.png';
import phoneIconFallback from '../assets/phone-icon-fallback.png';
import sgRewardsIconFallback from '../assets/sgrewards-icon-fallback.png';
import trophyIconFallback from '../assets/trophy-icon-fallback.png';
import { IMAGE_IDS } from '../constants';

export const useJoinSgRewardsContent = () => {
  const { formatMessage } = useIntl();

  const content = useContentfulContentTypeEntry<LoyaltyContainer>({
    contentType: 'loyalty',
  });

  const fallbackData: { heroSection: HeroSection; lineItems: LineItem[] } =
    useMemo(() => {
      const heroSection = {
        heroImage: {
          id: 'Opted Out | Hero Image + Screen Title',
          title: formatMessage(fallbacks.screenTitle),
          image: {
            url: '',
            fallbackSource: heroImageFallback as ImageSourcePropType,
            description: 'Hero image',
          },
        },
        heroLogo: {
          id: 'Opted Out | Hero Logo | Sg Rewards',
          image: {
            url: '',
            fallbackSource: heroLogoFallback as ImageSourcePropType,
            description: 'Hero logo',
          },
        },
      };

      const lineItems = [
        {
          id: 'Opted Out | Line Item | Earn points',
          title: formatMessage(fallbacks.earnPoints),
          image: {
            url: '',
            fallbackSource: sgRewardsIconFallback as ImageSourcePropType,
            description: '',
          },
        },
        {
          id: 'Opted Out | Line Item | Create combos',
          title: formatMessage(fallbacks.createCombos),
          image: {
            url: '',
            fallbackSource: phoneIconFallback as ImageSourcePropType,
            description: '',
          },
        },
        {
          id: 'Opted Out | Line Item | Redeem rewards',
          title: formatMessage(fallbacks.redeemRewards),
          image: {
            url: '',
            fallbackSource: trophyIconFallback as ImageSourcePropType,
            description: '',
          },
        },
        {
          id: 'Opted Out | Line Item | Unlock perks',
          title: formatMessage(fallbacks.unlockPerks),
          image: {
            url: '',
            fallbackSource: lockIconFallback as ImageSourcePropType,
            description: '',
          },
        },
      ];

      return { heroSection, lineItems };
    }, [formatMessage]);

  const contentfulData = content.data?.fields?.optedOut;
  const optedOutContent = useMemo(() => {
    if (contentfulData?.length) {
      const transformedData = contentfulData.map((item) => ({
        ...item.fields,
        title: item?.fields?.title,
        image: {
          url: item.fields?.image?.fields?.file?.url,
          description: item.fields?.image?.fields?.description,
        },
      }));

      const heroImage = transformedData.find((item) =>
        item.id?.includes(IMAGE_IDS.HERO_IMAGE),
      );

      const heroLogo = transformedData.find((item) =>
        item.id?.includes(IMAGE_IDS.HERO_LOGO),
      );

      const lineItems = transformedData.filter((item) =>
        item.id?.includes(IMAGE_IDS.LINE_ITEM),
      );

      return {
        heroSection: {
          heroImage,
          heroLogo,
        },
        lineItems,
      };
    }

    return fallbackData;
  }, [contentfulData, fallbackData]);

  return optedOutContent;
};

// ─── Fallbacks ──────────────────────────────────────────────────────────────

const fallbacks = defineMessages({
  screenTitle: {
    defaultMessage: 'The easiest way to earn free Sweetgreen.',
    description:
      'SG Rewards | Join screen | Opted Out | Hero Image + Screen Title',
  },
  earnPoints: {
    defaultMessage: 'Earn points + get free Sweetgreen',
    description: 'SG Rewards | Join screen | Opted Out | Earn Points',
  },
  createCombos: {
    defaultMessage: 'Create endless flavor combos',
    description: 'SG Rewards | Join screen | Opted Out | Create combos',
  },
  redeemRewards: {
    defaultMessage: 'Redeem exclusive rewards',
    description: 'SG Rewards | Join screen | Opted Out | Redeem rewards',
  },
  unlockPerks: {
    defaultMessage: 'Unlock members-only perks',
    description: 'SG Rewards | Join screen | Opted Out | Unlock perks',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyContainer = {
  optedOut: Entry<ContentfulOptedOutItem>[];
};

type ContentfulOptedOutItem = {
  id: string;
  title: string;
  image: {
    fields: {
      description: string;
      file: {
        url: string;
      };
    };
  };
};

export type Image = {
  fallbackSource?: ImageSourcePropType;
  url?: string;
  description: string;
};

type HeroSection = {
  heroImage: {
    id: string;
    title: string;
    image: Image;
  };
  heroLogo: {
    id: string;
    image: Image;
  };
};

type LineItem = {
  id: string;
  title: string;
  image: Image;
};

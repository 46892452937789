import type { ComponentProps } from 'react';
import React, { useCallback } from 'react';
import type { ImageSourcePropType, TextStyle } from 'react-native';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { theme } from '@garnish/constants';

import { Card } from '../Card';
import { Image } from '../Image';
import { TagLabel } from '../TagLabel';
import { BodyText } from '../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const RewardCard = (props: RewardCardProps) => {
  const {
    id,
    description,
    isSelected,
    isDisabled,
    extraDetails,
    tag,
    imageSource,
    palette = 'quinoa',
    onPress,
    ...restProps
  } = props;

  const { fontScale } = useWindowDimensions();

  // ─── Styles ──────────────────────────────────────────────────────────

  const descriptionStyles = [[palettePresetsForText[palette].description]];
  const extraDetailsStyles = [
    styles.extraDetails,
    palettePresetsForText[palette].extraDetails,
  ];

  // ─────────────────────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    onPress?.(id);
  }, [id, onPress]);

  const onPressHandler = onPress ? handleOnPress : undefined;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Card
      testID={`sg-add-on-card-${id}`}
      style={styles.cardContainer}
      accessibilityLabel={description}
      accessibilityRole="button"
      onPress={onPressHandler}
      isSelected={isSelected}
      disabled={isDisabled}
      {...palettePresetsForCard[palette]}
      {...restProps}
    >
      <View style={styles.cardInner}>
        <Image
          source={imageSource}
          resizeMode="cover"
          style={styles.illustration}
        />

        <View style={styles.infoContainer}>
          <View style={styles.infoContainerTextContent}>
            <BodyText
              numberOfLines={fontScale > 1.1 ? 2 : 3}
              ellipsizeMode="tail"
              sizeMatch={['16']}
              style={descriptionStyles}
            >
              {description}
            </BodyText>

            {extraDetails ? (
              <BodyText size={5} style={extraDetailsStyles}>
                {extraDetails}
              </BodyText>
            ) : null}
          </View>

          {tag ? (
            <TagLabel palette="kiwi" sizeMatch={['10']}>
              {tag}
            </TagLabel>
          ) : null}
        </View>
      </View>
    </Card>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cardContainer: {
    minHeight: 120,
    flex: 1,
    paddingHorizontal: theme.spacing['4'],
    borderRadius: theme.radius.large,
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardInner: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
    columnGap: theme.spacing['4'],
  },
  infoContainer: {
    flex: 1,
    alignItems: 'flex-start',
    paddingVertical: theme.spacing['4'],
    rowGap: theme.spacing['4'],
  },
  infoContainerTextContent: {
    rowGap: theme.spacing['1'],
  },
  extraDetails: {
    textTransform: 'uppercase',
  },
  illustration: {
    width: 80,
    height: 80,
  },
});

// ─── Palettes ────────────────────────────────────────────────────────────────

const palettePresetsForCard: Record<RewardCardPalette, CardStyleProps> = {
  quinoa: {
    backgroundColor: theme.colors.QUINOA,
    backgroundColorHover: theme.colors.QUINOA_HOVER,
    selectedBackgroundColor: theme.colors.CUCUMBER,
    selectedBackgroundColorHover: theme.colors.CUCUMBER_HOVER,
    selectedBorderColor: theme.colors.SPINACH,
  },
  kale: {
    backgroundColor: theme.colors.KALE,
    backgroundColorHover: theme.colors.KALE_HOVER,
    selectedBackgroundColor: theme.colors.KALE,
    selectedBackgroundColorHover: theme.colors.KALE_HOVER,
    selectedBorderColor: theme.colors.LIME,
  },
  cucumber: {
    backgroundColor: theme.colors.CUCUMBER,
    backgroundColorHover: theme.colors.CUCUMBER_HOVER,
    selectedBackgroundColor: theme.colors.CUCUMBER,
    selectedBackgroundColorHover: theme.colors.CUCUMBER_HOVER,
    selectedBorderColor: theme.colors.SPINACH,
  },
};

const palettePresetsForText: Record<RewardCardPalette, TextStyleProps> = {
  quinoa: StyleSheet.create({
    description: { color: theme.colors.DARK_KALE },
    extraDetails: { color: theme.colors.CHARCOAL },
  }),
  kale: StyleSheet.create({
    description: { color: theme.colors.LIME },
    extraDetails: { color: theme.colors.LIME },
  }),
  cucumber: StyleSheet.create({
    // eslint-disable-next-line react-native/no-unused-styles
    description: { color: theme.colors.KALE },
    // eslint-disable-next-line react-native/no-unused-styles
    extraDetails: { color: theme.colors.KALE },
  }),
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type RewardCardPalette = 'quinoa' | 'kale' | 'cucumber';

type RewardCardProps = Readonly<{
  id: string;
  imageSource: ImageSourcePropType;
  description: string;
  extraDetails?: string;
  tag?: string;
  palette?: RewardCardPalette;
  onPress?: (id: string) => void;
  isDisabled?: boolean;
}> &
  Omit<CardProps, 'onPress' | 'disabled' | CardStyleProp>;

type TextStyleProps = StyleSheet.NamedStyles<
  Readonly<{
    description: TextStyle;
    extraDetails: TextStyle;
  }>
>;

type CardStyleProps = Pick<CardProps, CardStyleProp>;

type CardStyleProp =
  | 'backgroundColor'
  | 'backgroundColorHover'
  | 'selectedBackgroundColor'
  | 'selectedBackgroundColorHover'
  | 'selectedBorderColor';

type CardProps = ComponentProps<typeof Card>;

import React, { memo, useCallback } from 'react';
import { Button } from '@sg/garnish';

import { ApplePay } from '@order/components';
import { APPLE_PAY } from '@order/constants';
import { useLocalizationContext } from '@order/Localization';

import { type useCheckoutContext } from '../../CheckoutProvider';

// ────────────────────────────────────────────────────────────────────────────

export const CheckoutPlaceOrderButton = memo(
  (props: CheckoutOrderBlockProps) => {
    const {
      total,
      isSubmittingOrder,
      validateCanPlaceOrder,
      validateAndSubmitOrder,
      sendShowPaymentSheetRequest,
    } = props;

    // ─── Context ──────────────────────────────────────────────────────────────

    const { t } = useLocalizationContext();
    const handleRegularOrder = useCallback(() => {
      void validateAndSubmitOrder();
    }, [validateAndSubmitOrder]);

    // ─── Apple Pay ────────────────────────────────────────────────────────────

    const handleOnApplePayCheckout = useCallback(() => {
      if (!validateCanPlaceOrder()) return;
      sendShowPaymentSheetRequest?.(total);
    }, [total, sendShowPaymentSheetRequest, validateCanPlaceOrder]);

    if (isCheckoutWithApplePay(props)) {
      return (
        <ApplePay.Checkout
          isLoading={isSubmittingOrder}
          onPress={handleOnApplePayCheckout}
        />
      );
    }

    // ─── Regular ──────────────────────────────────────────────────────────────

    return (
      <Button
        onPress={handleRegularOrder}
        isLoading={isSubmittingOrder}
        accessibilityLabel={t('checkout.place-order')}
        size="large"
        palette="primary"
      >
        {t('checkout.place-order')}
      </Button>
    );
  },
);

// ─── Helpers ────────────────────────────────────────────────────────────────

function isCheckoutWithApplePay(props: CheckoutOrderBlockProps) {
  const { payload, paymentMethods, isApplePayReady } = props;

  return (
    isApplePayReady &&
    payload.paymentMethodId !== 'ADD' &&
    (payload.paymentMethodId === APPLE_PAY ||
      !paymentMethods.some((pm) => pm.id === payload.paymentMethodId))
  );
}

// ─── Types ──────────────────────────────────────────────────────────────────

type CheckoutOrderBlockProps = Pick<
  ReturnType<typeof useCheckoutContext>,
  | 'payload'
  | 'paymentMethods'
  | 'isApplePayReady'
  | 'total'
  | 'isSubmittingOrder'
  | 'validateCanPlaceOrder'
  | 'validateAndSubmitOrder'
  | 'sendShowPaymentSheetRequest'
>;

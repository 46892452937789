import type { TranslationKeys } from '../locales';
import type { FormatMessage } from '../types';
import { DateDurationUnit } from '../types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns a localized string representing a duration unit name.
 *
 * @example
 * getDurationUnitName({ durationUnit: DateDurationUnit.Day, formatMessage }); // => 'day'
 * getDurationUnitName({ durationUnit: DateDurationUnit.Month, formatMessage }); // => 'month'
 * getDurationUnitName({ durationUnit: Other, formatMessage }); // => 'other'
 */
export function getDurationUnitName(props: GetDurationUnitNameProps): string {
  const { durationUnit, formatMessage } = props;

  const durationUnitName = DURATION_UNIT_NAMES[durationUnit];

  if (durationUnitName) return formatMessage(durationUnitName);

  // If an applicable localized string cannot be found,
  // the duration unit name in lowercase will be returned.
  return durationUnit.toLowerCase();
}

// ─── Constants ───────────────────────────────────────────────────────────────

const DURATION_UNIT_NAMES: Record<DateDurationUnit, TranslationKeys> = {
  [DateDurationUnit.Day]: 'duration-unit-name.day',
  [DateDurationUnit.Week]: 'duration-unit-name.week',
  [DateDurationUnit.Month]: 'duration-unit-name.month',
  [DateDurationUnit.Year]: 'duration-unit-name.year',
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GetDurationUnitNameProps = Readonly<{
  durationUnit: DateDurationUnit;
  formatMessage: FormatMessage;
}>;

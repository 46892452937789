/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BagPaymentMethodsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type BagPaymentMethodsQuery = {
  readonly __typename?: 'Query';
  readonly paymentMethodCards: ReadonlyArray<
    | { readonly __typename?: 'PaymentMethodBillingAccount' }
    | {
        readonly __typename: 'PaymentMethodCard';
        readonly id: string;
        readonly description: string | null;
        readonly nickname: string | null;
        readonly isDefault: boolean;
        readonly cardType: Types.CardType;
        readonly expirationState: Types.CardExpirationState;
      }
  >;
};

export type BagAddPaymentMethodMutationVariables = Types.Exact<{
  input: Types.UpdatePaymentMethodInput;
}>;

export type BagAddPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly updatePaymentMethod: {
    readonly __typename: 'PaymentMethodCard';
    readonly id: string;
    readonly description: string | null;
    readonly nickname: string | null;
    readonly isDefault: boolean;
    readonly cardType: Types.CardType;
    readonly expirationState: Types.CardExpirationState;
  };
};

export const BagPaymentMethodsDocument = gql`
  query BagPaymentMethods {
    paymentMethodCards {
      ... on PaymentMethodCard {
        __typename
        id
        description
        nickname
        isDefault
        cardType
        expirationState
      }
    }
  }
`;

export function useBagPaymentMethodsQuery(
  options?: Omit<Urql.UseQueryArgs<BagPaymentMethodsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<BagPaymentMethodsQuery, BagPaymentMethodsQueryVariables>(
    { query: BagPaymentMethodsDocument, ...options },
  );
}
export const BagAddPaymentMethodDocument = gql`
  mutation BagAddPaymentMethod($input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(input: $input) {
      id
      description
      nickname
      isDefault
      cardType
      expirationState
      __typename
    }
  }
`;

export function useBagAddPaymentMethodMutation() {
  return Urql.useMutation<
    BagAddPaymentMethodMutation,
    BagAddPaymentMethodMutationVariables
  >(BagAddPaymentMethodDocument);
}

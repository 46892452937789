import React, { type ComponentProps } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { type TextStyle } from 'react-native';
import { Button, TextLink, useResponsive } from '@sg/garnish';

export const LoyaltyPointsHistoryCtaButton = (
  props: LoyaltyPointsHistoryCtaButtonProps,
) => {
  const { palette = 'secondary', onPress } = props;

  const { match } = useResponsive();
  const { formatMessage } = useIntl();

  const label = match([
    formatMessage(messages.viewHistory),
    formatMessage(messages.pointsHistory),
  ]);

  return (
    <Button
      palette={palette}
      accessibilityRole="button"
      accessibilityLabel={formatMessage(messages.accessibilityLabel)}
      onPress={onPress}
    >
      {label}
    </Button>
  );
};

export const LoyaltyPointsHistoryCtaLink = (
  props: LoyaltyPointsHistoryCtaLinkProps,
) => {
  const { style, onPress } = props;

  const { match } = useResponsive();
  const { formatMessage } = useIntl();

  const label = match([
    formatMessage(messages.viewHistory),
    formatMessage(messages.pointsHistory),
  ]);

  return (
    <TextLink
      style={style}
      underline
      sizeMatch={['14']}
      accessibilityRole="button"
      accessibilityLabel={formatMessage(messages.accessibilityLabel)}
      onPress={onPress}
    >
      {label}
    </TextLink>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Open points history screen',
    description: 'Loyalty | Points History | Accessibility label',
  },
  pointsHistory: {
    defaultMessage: 'Points history',
    description: 'Loyalty | Points History | Points history',
  },
  viewHistory: {
    defaultMessage: 'View history',
    description: 'Loyalty | Points History | View history',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyPointsHistoryCtaButtonProps = {
  palette?: ComponentProps<typeof Button>['palette'];
  onPress: () => void;
};

type LoyaltyPointsHistoryCtaLinkProps = {
  style?: TextStyle;
  onPress: () => void;
};

import React, { type PropsWithChildren, useCallback, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useResponsive } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { NotFoundView, TransparentModal } from '@order/components';
import { ORDER_AGAIN_TABS, OrderAgain } from '@order/features/orders';
import { useCart } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry } from '@order/Telemetry';

import type { ModalStackParamList } from '../../navigation/AppNavigation.props';
import { useReorderOrders } from './hooks';
import {
  ReorderScreenFavoritesTabPanel,
  ReorderScreenOrdersTabPanel,
} from './tabs';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A modal-screen that provides a simple user interface to reorder previous
 * orders and favorites.
 */
export const ReorderScreen = () => {
  const isReorderModalEnabled = useFeatureFlag(
    'CELS-2534-reorder-modal-enabled',
    { listenForChanges: true },
  );
  const isLoggedIn = useIsLoggedIn();
  const shouldRenderNotFoundView = !isLoggedIn || !isReorderModalEnabled;

  if (shouldRenderNotFoundView) {
    return (
      <ReorderModal>
        <NotFoundView withoutFooter />
      </ReorderModal>
    );
  }

  return <ReorderScreenReady />;
};

// ─────────────────────────────────────────────────────────────────────────────

const ReorderScreenReady = () => {
  const { goBack } = useNavigation();
  const { lineItemsCount } = useCart();
  const { match } = useResponsive();
  const { track } = useTelemetry();

  // ─── Navigation ──────────────────────────────────────────────────────

  const { navigate, replace } = useNavigation<ModalStackParams>();

  // NOTE: Reorder modal is represented as a small modal on large breakpoints
  // and a fullscreen one on the smallest breakpoint which introduces some issues
  // We use different methods of navigation per breakpoint to avoid unexpected
  // issues.
  const navigationMethod = match([navigate, replace]);

  const navigateToBag = useCallback(() => {
    navigationMethod('Bag');
  }, [navigationMethod]);

  // ─── Remote Data ─────────────────────────────────────────────────────

  const reorderOrders = useReorderOrders();

  // ─── State ───────────────────────────────────────────────────────────

  const [activeTabId, setActiveTabId] = useState(ORDER_AGAIN_TABS.ORDERS.ID);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onTabChange = useCallback(
    (tabId: string) => {
      if (tabId === ORDER_AGAIN_TABS.ORDERS.ID) {
        track('reorder.orders.tab.tapped');
      }

      if (tabId === ORDER_AGAIN_TABS.FAVORITES.ID) {
        track('reorder.favorites.tab.tapped');
      }

      setActiveTabId(tabId);
    },
    [track],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ReorderModal>
      <OrderAgain.Header>
        <OrderAgain.HeaderTitle />
        <OrderAgain.CloseButton onPress={goBack} />
      </OrderAgain.Header>

      <OrderAgain.Tabs activeTabId={activeTabId} setActiveTabId={onTabChange} />

      <ReorderScreenOrdersTabPanel
        activeTabId={activeTabId}
        reorderOrders={reorderOrders}
      />
      <ReorderScreenFavoritesTabPanel
        activeTabId={activeTabId}
        mostRecentOrder={reorderOrders.mostRecentOrder}
      />

      {lineItemsCount > 0 ? (
        <OrderAgain.BagButton
          lineItemsCount={lineItemsCount}
          onPress={navigateToBag}
        />
      ) : null}
    </ReorderModal>
  );
};

// ─── Components ──────────────────────────────────────────────────────────────

const ReorderModal = (props: PropsWithChildren) => {
  const { children } = props;

  const { goBack } = useNavigation();

  return (
    <TransparentModal onClose={goBack} size="small" dialogMaxHeight={844}>
      {children}
    </TransparentModal>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ModalStackParams = NativeStackNavigationProp<ModalStackParamList>;

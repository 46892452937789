import React from 'react';
import { Controller } from 'react-hook-form';

import { Chip as GarnishChip } from '../Chip';
import type { ControlledProps, FormProps } from './types';

type ComponentProps = React.ComponentProps<typeof GarnishChip>;
type OmittedProps = ControlledProps | 'onSubmit';
type Props = FormProps & Omit<ComponentProps, OmittedProps>;

export const Chip = ({ name, control, rules, ...rest }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <GarnishChip
          value={value}
          onChange={(state) => {
            onChange(state);
            onBlur();
          }}
          {...rest}
        />
      )}
    />
  );
};

export const ChipMenu = ({
  name,
  control,
  rules,
  children,
  ...rest
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <GarnishChip.Menu
          value={value}
          onChange={(state) => {
            onChange(state);
            onBlur();
          }}
          {...rest}
        >
          {/* @ts-expect-error TS(2322): Type 'ReactNode | ((state: PressableStateCallbackT... Remove this comment to see the full error message */}
          {children}
        </GarnishChip.Menu>
      )}
    />
  );
};

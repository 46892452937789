import { defineMessages } from 'react-intl';

export const giftCardsErrorViewMessages = defineMessages({
  title: {
    defaultMessage: 'Something went wrong',
    description: 'Gift Cards Screen | Network error title',
  },
  text: {
    defaultMessage:
      'There was an error while getting gift cards, please try later.',
    description: 'Gift Cards Screen | Network error text',
  },
});

import React from 'react';
import type { ViewProps } from 'react-native';
import Animated, {
  Easing,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';

import { useResponsive } from '../../../../hooks';

/** Animates the vertical position of the modal wrapper so it slides up when visible. */
export const ModalAnimatedContent = (props: ModalAnimatedContentProps) => {
  const { isModalShown, isAnimated, style, children, ...restProps } = props;

  const { screenDimensions } = useResponsive();
  const offScreen = screenDimensions.height;

  const yPosition = useDerivedValue(() => {
    if (isModalShown) {
      return withTiming(0, ANIMATE_UP);
    }

    return withTiming(offScreen, ANIMATE_DOWN);
  });

  const animatedStyle = useAnimatedStyle(
    () => ({
      transform: [{ translateY: yPosition.value }],
    }),
    [],
  );

  return (
    <Animated.View
      style={[style, isAnimated ? animatedStyle : null]}
      {...restProps}
    >
      {children}
    </Animated.View>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type ModalAnimatedContentProps = {
  isAnimated?: boolean;
  isModalShown?: boolean;
} & ViewProps;

// ─── Constants ─────────────────────────────────────────────────────────

const ANIMATE_UP = { duration: 500, easing: Easing.out(Easing.exp) };
const ANIMATE_DOWN = { duration: 100, easing: Easing.in(Easing.exp) };

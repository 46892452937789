import type { StyleProp, TextStyle } from 'react-native';
import { StyleSheet } from 'react-native';
import {
  DEFAULT_FONT_FAMILY,
  FONT_FACE_MAP,
  WEIGHT_MAP,
} from '@garnish/constants';

import type {
  FontNamespaces,
  SGFonts,
  SGTextProps,
  SGTextStyle,
  WeightedFonts,
  WeightKeywords,
  WeightNumerical,
} from './BaseText.types';

// ────────────────────────────────────────────────────────────────────────────────

export const getAdjustedFontFamilyStyles = (
  styleProps: StyleProp<SGTextStyle | TextStyle>,
) => {
  const styles = normalizeStyles(styleProps);
  const numericWeight = getNumericFontWeight(styles.fontWeight);
  const weightSpecificFont = getWeightSpecificFont(
    styles.fontFamily,
    numericWeight,
  );

  return {
    ...styles,
    fontFamily: weightSpecificFont,
    // Prescribed weight is not needed, and causes render issues on safari
    // https://stackoverflow.com/questions/28833958/why-is-ios-safari-adding-extra-letter-spacing
    // fontWeight: numericWeight,
    fontWeight: 'normal',
  };
};

// ────────────────────────────────────────────────────────────────────────────────

export const normalizeStyles = (
  styleProps: StyleProp<SGTextStyle | TextStyle>,
): NormalizedTextStyles => {
  const styles = StyleSheet.flatten(styleProps) as SGTextStyle;

  const FONT_DEFAULTS = {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontWeight: WEIGHT_MAP.normal,
  };

  return {
    ...FONT_DEFAULTS,
    ...styles,
  };
};

type NormalizedTextStyles = SGTextStyle & Required<SGTextProps>;

// ────────────────────────────────────────────────────────────────────────────────

// https://fettblog.eu/typescript-array-includes/
function includes<T extends U, U>(list: readonly T[], el: U): el is T {
  return list.includes(el as T);
}

/**
 * getNumericFontWeight
 *
 * This normalizes the fontWeight to be the numerical form
 */
export const getNumericFontWeight = (
  weightValue: WeightKeywords | WeightNumerical,
): WeightNumerical => {
  // Numerical weights
  if (includes(Object.values(WEIGHT_MAP), weightValue)) {
    return weightValue;
  }

  // Keyword weight
  if (
    includes(Object.keys(WEIGHT_MAP) as readonly WeightKeywords[], weightValue)
  ) {
    return WEIGHT_MAP[weightValue];
  }

  // Default weight (400)
  return WEIGHT_MAP.normal as WeightNumerical;
};

// ────────────────────────────────────────────────────────────────────────────────

export const getWeightSpecificFont = (
  fontFamily: SGFonts,
  weight: WeightNumerical,
): WeightedFonts => {
  const fontNamespaces = Object.keys(
    FONT_FACE_MAP,
  ) as readonly FontNamespaces[];

  // return early if its already a weight-specific font
  if (!includes(fontNamespaces, fontFamily)) {
    return fontFamily;
  }

  const familyWeightMap = FONT_FACE_MAP[fontFamily];
  // @ts-expect-error TS(2352): Conversion of type 'string[]' to type '"700"' may ... Remove this comment to see the full error message
  const familyWeightKeys = Object.keys(
    familyWeightMap,
  ) as keyof typeof familyWeightMap;

  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  if (includes(familyWeightKeys, weight)) {
    // sweet return Object.values(familyWeightMap)[0];
    return familyWeightMap[weight as typeof familyWeightKeys];
  }

  // return first font in namespace (TODO: get "closest" supported type)
  return Object.values(familyWeightMap)[0];
};

import React, { type ComponentProps } from 'react';

import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const AlertText = (props: AlertTextProps) => {
  const { children, ...restProps } = props;

  return (
    <BodyText sizeMatch={['14']} {...restProps}>
      {children}
    </BodyText>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type AlertTextProps = ComponentProps<typeof BodyText>;

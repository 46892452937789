import type { ComponentProps } from 'react';
import { useCallback } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { LoadingPlaceholder } from '../../LoadingPlaceholder';
import { LoyaltyChallengeCard } from '../../LoyaltyChallengeCard';
import type {
  LoyaltyChallenge,
  LoyaltyChallengeCardProps,
} from '../../LoyaltyChallengeCard/LoyaltyChallengeCard.types';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyChallengeCardItem = (
  props: LoyaltyChallengeCardItemProps,
) => {
  const {
    isLoading,
    challenge,
    terms,
    termsUrl,
    badgeText,
    applyText,
    completionText,
    deadlinePrefixText,
    index: itemIndex,
    onStartChallenge,
    onPressTermsAndConditions,
  } = props;

  const onStart = useCallback(() => {
    if (challenge) onStartChallenge?.(challenge.id);
  }, [challenge, onStartChallenge]);

  return (
    <View style={styles.item} testID="loyalty-challenge-card-item">
      {/* eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10 */}
      {isLoading ? (
        Array.of({ length: 3 }).map((_, index) => (
          <LoadingPlaceholder
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            rows={1}
            rowHeight={CARD_LOADING_PLACEHOLDER_HEIGHT}
            palette="cream"
          />
        ))
      ) : challenge ? (
        <LoyaltyChallengeCard
          {...(challenge as LoyaltyChallengeCardProps)}
          id={challenge.id}
          index={itemIndex}
          terms={terms ?? ''}
          termsUrl={termsUrl ?? ''}
          badgeText={badgeText ?? ''}
          completionText={completionText ?? ''}
          deadlinePrefixText={deadlinePrefixText ?? ''}
          applyText={applyText ?? ''}
          onPressTermsAndConditions={onPressTermsAndConditions}
          onStartChallenge={onStart}
        />
      ) : null}
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const CARD_WIDTH = 310;
const CARD_LOADING_PLACEHOLDER_HEIGHT = 400;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  item: {
    flex: 1,
    width: CARD_WIDTH,
    paddingVertical: theme.spacing['1'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyChallengeCardItemProps = Readonly<{
  isLoading: boolean;
  index?: number;
  terms?: string;
  termsUrl?: string;
  badgeText?: string;
  deadlinePrefixText?: string;
  completionText?: string;
  applyText?: string;
  onStartChallenge?: ComponentProps<
    typeof LoyaltyChallengeCard
  >['onStartChallenge'];
  onPressTermsAndConditions?: ComponentProps<
    typeof LoyaltyChallengeCard
  >['onPressTermsAndConditions'];
  challenge?: Omit<LoyaltyChallenge, 'terms' | 'applyText'>;
}>;

export const location = {
  'location.no-results.banner':
    "Oops, we couldn't find any sweetgreen locations. Try another search",
  'location.input-invalid-address-notice': 'Enter a valid address',
  'location.search-placeholder': 'Search store, city, state, or zip',
  'location.delivery-search-placeholder': 'Enter your delivery address',
  'location.map-center-location-label': 'Center location',
  'location.map-center-location-hint': 'Centers map to user location',
  'location.map-zoom-in-label': 'Zoom In',
  'location.map-zoom-in-hint': 'Increase zoom level',
  'location.map-zoom-out-label': 'Zoom Out',
  'location.map-zoom-out-hint': 'Decrease zoom level',
  'location.map-search-area': 'Search area',
  'location.map-search-area-a11y-hint': 'Search area',
  'location.map-a11y-alt-text': 'Interactive map',
  'location.view-menu': 'View Menu',
  'location.order-now': 'Order now',
  'location.closed': 'Closed',
  'location.details.title': 'Location details',
  'location.details.call': 'Call',
  'location.details.directions': 'Directions',
  'location.details.uber': 'Request Uber',
  'location.details.copy': 'Copy Address',
  'location.details.feedback': 'Address copied to your clipboard',
  'location.warning-modal.cta-change': 'Change location',
  'location.warning-modal.cta-confirm': 'Yes, I am',
  'location.result.a11y-hint': '{name}. {address}. {city}. {state}. {zipCode}.',
  'location.result.divider': `{invertedOrderType, select,
    pickup {Outpost locations}
    outpost {Sweetgreen locations}
    other {}
  }`,
  'location.result.other-locations': `{locationSearchType, select,
    pickup {Sweetgreen locations}
    delivery {Delivery locations}
    outpost {Outpost locations}
    other {Other locations}
  }`,
  'location.result.img-alt': `{locationSearchType, select,
    pickup {Pickup location illustration}
    outpost {Outpost location illustration}
    delivery {Delivery location illustration}
    other {Location illustration}
  }`,
  'location.result.outpost-hint':
    'Sweetgreen Outpost is a batch delivery program that brings locally sourced food to your office every day.',
  'location.enter-address': 'Enter an address above to get started.',
  'location.enable-location-services':
    'Enter an address above to get started. Enable location services to find nearby locations.',
  'location.location-illustration.alt':
    'Sweetgreen customer with the location pin',
  'location.empty-results-illustration.alt': 'Seedless pea pod',
  'location.more-locations': 'More locations',
  'location.confirm-location': 'Confirm {channel} location',
  'location.continue': 'Continue',
  'location.results.card.a11y': 'Location result for {restaurant}',
  'location.results.more-locations': 'more locations',
  'location.results.less-locations': 'less locations',
  'location.results.card.order-now': 'Order now',
  'location.results.card.order-later': 'Schedule for later',

  // ─── Location Errors ─────────────────────────────────────────────────

  'location.no-locations-result':
    "Ah snap peas! We couldn't find any sweetgreen locations.",
  'location.no-autocomplete-result':
    "Ah snap peas! We couldn't find any locations.",
  'location.try-another-search': 'Try another search',
  'location.product-not-available':
    "We're sorry, this item is no longer available.",
  'location.delivery.zip-code-not-available-title':
    'Sorry, the address you provided is not valid.',
  'location.delivery.zip-code-not-available-body':
    'Please make sure to include the complete street address, including the zip code',
};

import { useCallback } from 'react';
import { logger as LOG } from '@garnish/logger';
import { useActor, useSelector } from '@xstate/react';

import { useGlobalAppState } from '../../GlobalAppState';
import { AuthModel } from '../AuthMachine.model';

// ────────────────────────────────────────────────────────────────────────────────
// LOG.enable('AUTH');
const logger = LOG.extend('AUTH');
// ────────────────────────────────────────────────────────────────────────────────

export const useSendSessionInitToAuthMachine = () => {
  const { authMachineRef } = useGlobalAppState();
  const { 1: send } = useActor(authMachineRef);

  const hasAlreadyInitializedSession = useSelector(authMachineRef, (state) => {
    return state.matches('session');
  });

  return useCallback(
    (isLoggedIn?: boolean, csrf?: string) => {
      logger.debug(
        'hasAlreadyInitializedSession',
        hasAlreadyInitializedSession,
      );

      if (hasAlreadyInitializedSession) return;

      if (typeof isLoggedIn !== 'boolean' || !csrf) return;

      logger.debug('isLoggedIn', isLoggedIn, 'csrf', csrf);

      isLoggedIn
        ? send(AuthModel.events.INIT_LOGGED_IN_SESSION(csrf))
        : send(AuthModel.events.INIT_GUEST_SESSION(csrf));
    },
    [hasAlreadyInitializedSession, send],
  );
};

import React, { type ReactNode } from 'react';
import { StyleSheet } from 'react-native';
import { Container, theme, useResponsive } from '@sg/garnish';

import { PageWrapper } from '@order/components';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardRedemptionContainer = (
  props: GiftCardRedemptionContainerProps,
) => {
  const { currentBreakpoint } = useResponsive();

  return (
    <PageWrapper safeAreaEdges={['top']} withoutFooter={currentBreakpoint.isXS}>
      <Container
        wrapperStyle={styles.outerContainer}
        style={styles.innerContainer}
      >
        {props.children}
      </Container>
    </PageWrapper>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    alignItems: 'center',
    paddingTop: theme.spacing['8'],
    paddingBottom: theme.spacing['12'],
    minHeight: 300,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardRedemptionContainerProps = {
  children: ReactNode;
};

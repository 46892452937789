import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { HorizontalScrollRailHeader } from '../../HorizontalScrollRail/subcomponents';
import { Image } from '../../Image';
import { BodyText } from '../../Text';
import type { LoyaltyChallengeCardsRailProps } from '../LoyaltyChallengeCardsRail';

export const LoyaltyChallengesEmptyState = ({
  description,
  image,
  ...headerProps
}: LoyaltyChallengeCardsRailEmptyStateProps) => {
  return (
    <View>
      <HorizontalScrollRailHeader
        count={0}
        heading={headerProps.headerText}
        headerVariation={headerProps.headerVariation}
      />
      <View style={styles.wrapper}>
        <Image style={styles.image} source={image} contentFit="contain" />
        <BodyText size={3}>{description}</BodyText>
      </View>
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 220,
    height: 130,
  },
});

// ─── TYPES ─────────────────────────────────────────────────────────────────

export type LoyaltyChallengeCardsRailEmptyStateProps = Readonly<{
  image: ComponentProps<typeof Image>['source'];
  description: string;
}> &
  Omit<
    LoyaltyChallengeCardsRailProps,
    'challenges' | 'emptyStateImage' | 'emptyStateDescription'
  >;

import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { Icon, IconLink, theme } from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';

import { useNavigateToMainTabs } from '../../../../navigation';
import { homeScreenMobileHeaderMessages as messages } from './HomeScreenMobileHeader.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const HomeScreenMobileHeader = () => {
  const navigateToMainTabs = useNavigateToMainTabs();
  const { formatMessage } = useIntl();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToAccountScreen = useCallback(() => {
    void navigateToMainTabs('AccountTab');
  }, [navigateToMainTabs]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    isInAppGiftCardEnabled
      ? styles.containerJustifyBetween
      : styles.containerJustifyEnd,
  ];

  const sgIconSize = isInAppGiftCardEnabled ? 32 : 40;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View testID="home.mobile-header" style={containerStyles}>
      <Icon
        name="IconSweetgreen"
        color={theme.colors.KALE}
        width={sgIconSize}
        height={sgIconSize}
      />

      {isInAppGiftCardEnabled ? (
        <IconLink
          onPress={navigateToAccountScreen}
          name="IconProfileStroke"
          accessibilityLabel={formatMessage(
            messages.navigateToAccountScreenBtnLabel,
          )}
        />
      ) : null}
    </View>
  );
};

// ─── Styles ───────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing['1'],
    marginBottom: theme.spacing['4'],
  },
  containerJustifyBetween: {
    justifyContent: 'space-between',
  },
  containerJustifyEnd: {
    justifyContent: 'flex-end',
  },
});

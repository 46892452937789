import { Platform } from 'react-native';

import { useFeatureFlag } from '@order/LaunchDarkly';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * @see https://developer.apple.com/documentation/authenticationservices/aswebauthenticationsession/3237231-prefersephemeralwebbrowsersessio
 * @see https://docs.expo.dev/versions/latest/sdk/webbrowser/#authsessionopenoptions
 */
export const useShouldPreferEphemeralSession = () => {
  const isPreferEphemeralSessionFlagEnabled = useFeatureFlag(
    'CELS-2107-auth-use-prefer-ephemeral-session',
    { listenForChanges: true },
  );

  return isPreferEphemeralSessionFlagEnabled && Platform.OS === 'ios';
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CustomerNameQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CustomerNameQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename?: 'Customer';
    readonly id: string;
    readonly firstName: string | null;
  } | null;
};

export const CustomerNameDocument = gql`
  query customerName {
    currentCustomer {
      id
      firstName
    }
  }
`;

export function useCustomerNameQuery(
  options?: Omit<Urql.UseQueryArgs<CustomerNameQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CustomerNameQuery, CustomerNameQueryVariables>({
    query: CustomerNameDocument,
    ...options,
  });
}

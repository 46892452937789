import React, { useCallback } from 'react';
import { type IconName } from '@sg/garnish';

import { PaymentMethodSelectorModal } from '@order/components';
import { getCreditCardIcon } from '@order/constants';
import { useCreditCardOptions } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import type { CreditCardOption } from '../../../Checkout.utils';
import { useCheckoutContext } from '../../../CheckoutProvider';

export const ConnectedCheckoutPaymentBlockCreditCardPicker = () => {
  const { t } = useLocalizationContext();
  const {
    paymentMethods,
    payload,
    checkoutFieldsValidation,
    isApplePayReady = false,
    handleCreditCardSelected,
    resetCheckoutFieldValidation,
  } = useCheckoutContext();

  // ─── Credit Card Options ─────────────────────────────────────────────

  const selectedPaymentMethodId = payload.paymentMethodId ?? '';

  const { creditCardOptions } = useCreditCardOptions({
    paymentMethods,
    shouldUseApplePay: isApplePayReady,
  });

  const { selectedPaymentMethodLabel, selectedPaymentMethodIcon } =
    getSelectedPaymentMethodInfo(selectedPaymentMethodId, creditCardOptions);

  // ─── Required Credit Card Notice ─────────────────────────────────────

  const isPaymentMethodValid = checkoutFieldsValidation.paymentMethodId;

  const paymentMethodSelectionNotice = isPaymentMethodValid
    ? undefined
    : t('checkout.required-field');

  // ─── Callbacks ───────────────────────────────────────────────────────

  const handlePaymentMethodSelected = useCallback(
    (paymentMethodId: string) => {
      resetCheckoutFieldValidation('paymentMethodId');
      handleCreditCardSelected({
        paymentMethodId,
        paymentMethodType: 'PaymentMethodCard',
      });
    },
    [resetCheckoutFieldValidation, handleCreditCardSelected],
  );

  return (
    <PaymentMethodSelectorModal
      selectedPaymentMethodId={selectedPaymentMethodId}
      selectedPaymentMethodLabel={selectedPaymentMethodLabel}
      selectedPaymentMethodIcon={selectedPaymentMethodIcon}
      paymentMethodOptions={creditCardOptions}
      paymentMethodSelectionNotice={paymentMethodSelectionNotice}
      isApplePayReady={isApplePayReady}
      onPaymentMethodSelected={handlePaymentMethodSelected}
    />
  );
};

// ─── Helpers ───────────────────────────────────────────────────────────

function getSelectedPaymentMethodInfo(
  paymentMethodId: string,
  options: readonly CreditCardOption[],
) {
  const selectedCard = options.find(({ value }) => value === paymentMethodId);
  const selectedLabel = selectedCard?.label ?? '';
  const selectedIcon = getCreditCardIcon(selectedCard?.cardType) as IconName;

  return {
    selectedPaymentMethodLabel: selectedLabel,
    selectedPaymentMethodIcon: selectedIcon,
  };
}

/**
 * Formats provided universal URL by removing any extra parts.
 *
 * If the specified URL begins with app-associated domain
 * (e.g. 'https://app.associated.url/route-path'), it will be returned
 * without it, leaving only the internal URL (e.g '/route-path').
 *
 * Otherwise, the specified URL will be returned without modifications.
 * Useful for handling universal URLs coming from a remote source.
 *
 * @example
 * formatUniversalURL('https://app.associated.url/route-path', ['https://app.associated.url); // '/route-path'
 * formatUniversalURL('https://other.url/route-path', ['https://app.associated.url); // 'https://other.url/route-path'
 */
export function formatUniversalURL(
  url: string,
  associatedUrls: AppAssociatedUrls,
): string {
  const appAssociatedDomain = getAssociatedAppDomain(url, associatedUrls);

  if (appAssociatedDomain) {
    return convertToInternalLink(url, appAssociatedDomain);
  }

  return url;
}

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Converts an external URL to an internal URL by removing the app-associated domain.
 */
function convertToInternalLink(url: string, appAssociatedDomain: string) {
  const urlWithoutAppAssociatedDomain = url.split(appAssociatedDomain)[1];
  const isMissingSlash = !urlWithoutAppAssociatedDomain.startsWith('/');

  if (isMissingSlash) return `/${urlWithoutAppAssociatedDomain}`;

  return urlWithoutAppAssociatedDomain;
}

/**
 * Returns the related app domain if the provided URL begins with it.
 */
function getAssociatedAppDomain(
  url: string,
  associatedUrls: AppAssociatedUrls,
): string | undefined {
  return associatedUrls.find((associatedUrl) => url.startsWith(associatedUrl));
}

// ─── Types ───────────────────────────────────────────────────────────────────

type AppAssociatedUrls = readonly string[];

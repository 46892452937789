import extractHighlightedWords from 'highlight-words';
import escapeRegExp from 'lodash.escaperegexp';

/**
 * Returns an array of highlighted/standard words based on provided text and words that need to be highlighted.
 *
 * @usage
 * ```js
 * highlightWords('New York', [ { offset: 0, length: 3 } ]);
 * // => [{key: UUID, substring: 'New', highlight: true}, {key: UUID, substring: ' York', highlight: false}]
 * ```
 */
export function highlightWords(
  text: string,
  wordsToHighlight: readonly string[],
): HighlightedWordsList {
  const chunks = extractHighlightedWords({
    text,
    query: createSubstringsRegExp(wordsToHighlight),
  });

  return chunks.map(({ key, text: substring, match: shouldHighlight }) => ({
    key,
    substring,
    shouldHighlight,
  }));
}

/* Returns a regular expression for 'highlight-words' util based on the provided words array.
 *
 * @usage
 * ```js
 * createSubstringsRegExp(['quick', 'brown', 'fox']);
 * // => '/(quick|brown|fox)/'
 * ```
 */
export function createSubstringsRegExp(words: readonly string[]) {
  return words.length > 0 ? `/(${words.map(escapeRegExp).join('|')})/` : '';
}

/**
 * Returns an array of substrings based on the provided positions.
 *
 * @usage
 * ```js
 * getSubstringsByPositions('California', [{ offset: 0, length: 2 }, { offset: 4, length: 3 } ]);
 * // => ["Ca", "for"]
 * ```
 */
export function getSubstringsByPositions(
  str: string,
  substringsPositions: SubstringsPositions,
) {
  return substringsPositions.map(({ offset, length }) =>
    str.slice(offset, offset + length),
  );
}

// ------------------------------
// ---- types
// ------------------------------

export type HighlightedWordsList = ReadonlyArray<
  Readonly<{
    key: string;
    substring: string;
    shouldHighlight: boolean;
  }>
>;

export type SubstringsPositions = ReadonlyArray<
  Readonly<{
    offset: number;
    length: number;
  }>
>;

import React, { forwardRef } from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';

// ─────────────────────────────────────────────────────────────────────────────

export const ActiveIngredientPressableContainer = forwardRef<View, ViewProps>(
  (props, ref) => {
    const { children, style, ...restProps } = props;

    return (
      <View
        ref={ref}
        style={[styles.container, style]}
        // See: https://github.com/facebook/react-native/issues/3282#issuecomment-201934117
        collapsable={false}
        {...restProps}
      >
        {children}
      </View>
    );
  },
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
});

/* eslint-disable react/no-array-index-key,@typescript-eslint/no-shadow */

import React, { useCallback, useState } from 'react';
import { Rect } from 'react-content-loader/native';
import type { LayoutChangeEvent, ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../hooks';
import { Container } from '../Container';
import { VStack } from '../Stack';
import ContentLoader from './ContentLoader';

export const LoadingPlaceholder = (props: LoadingPlaceholderProps) => {
  const {
    rows = 1,
    columns = 1,
    columnWidth,
    rowHeight = ROW_FALLBACK_HEIGHT,
    container,
    gridGap = 15,
    gridGapY,
    gridGapX,
    speed = 1.5,
    borderRadius = theme.radius.medium,
    palette = 'gray',
    bgColor,
    fgColor,
    style,
  } = props;
  const { match } = useResponsive();

  const [containerWidth, setContainerWidth] = useState(0);

  const [paletteBgColor, paletteFgColor] = PALETTES_MAP[palette];

  // ────────────────────────────────────────────────────────────────────

  const rowsNumber = Array.isArray(rows) ? match(rows) ?? rows.at(-1) : rows;
  const columnsNumber = Array.isArray(columns)
    ? match(columns) ?? columns.at(-1)
    : columns;

  const rowElements = Array.from({ length: rowsNumber });
  const columnElements = Array.from({ length: columnsNumber });

  const visibleGuttersSum = columnsNumber * gridGap - gridGap;
  const columnsWidth =
    columnWidth ?? (containerWidth - visibleGuttersSum) / columnsNumber;

  // ─── HELPERS ────────────────────────────────────────────────────────

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width);
  }, []);

  // ────────────────────────────────────────────────────────────────────

  const Wrapper = container ? Container : View;
  const wrapperTestID = `sg-loading-placeholder-${
    container ? 'container' : 'view'
  }`;

  return (
    <Wrapper testID={wrapperTestID} style={style}>
      <VStack gap={gridGapY ?? gridGap} onLayout={onLayout}>
        {rowElements.map((_, ind) => {
          const currentRowHeight = getRowHeight(rowHeight, ind);

          return (
            <View key={ind} testID="sg-loading-placeholder-row">
              <ContentLoader
                speed={speed}
                width="100%"
                height={currentRowHeight}
                backgroundColor={bgColor ?? paletteBgColor}
                foregroundColor={fgColor ?? paletteFgColor}
                preserveAspectRatio="none"
              >
                {columnElements.map((_, ind) => {
                  const x = ind * (columnsWidth + (gridGapX ?? gridGap));

                  return (
                    <Rect
                      key={ind}
                      x={x}
                      rx={borderRadius}
                      ry={borderRadius}
                      width={columnsWidth > 0 ? columnsWidth : '100%'}
                      height="100%"
                    />
                  );
                })}
              </ContentLoader>
            </View>
          );
        })}
      </VStack>
    </Wrapper>
  );
};

export const LoadingPlaceholderFitToView = ({
  bgColor,
  fgColor,
  borderRadius = theme.radius.medium,
  palette = 'lightGray',
  speed = 1.5,
}: Pick<
  LoadingPlaceholderProps,
  'bgColor' | 'fgColor' | 'borderRadius' | 'palette' | 'speed'
>) => {
  const [layout, onLayout] = React.useState<LayoutChangeEvent>();

  const [paletteBgColor, paletteFgColor] = PALETTES_MAP[palette];

  return (
    <View style={StyleSheet.absoluteFill} onLayout={onLayout}>
      <ContentLoader
        speed={speed}
        width="100%"
        backgroundColor={bgColor ?? paletteBgColor}
        foregroundColor={fgColor ?? paletteFgColor}
      >
        <Rect
          x={0}
          y={0}
          width={layout?.nativeEvent.layout.width ?? 0}
          height={layout?.nativeEvent.layout.height ?? 0}
          rx={borderRadius}
        />
      </ContentLoader>
    </View>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type LoadingPlaceholderProps = Readonly<{
  rows?: GridElementsNumber;
  columns?: GridElementsNumber;
  gridGap?: number;
  gridGapX?: number;
  gridGapY?: number;
  borderRadius?: number;
  rowHeight?: RowHeight;
  columnWidth?: number;
  container?: boolean;
  palette?: Palettes;
  speed?: number;
  bgColor?: string;
  fgColor?: string;
  style?: ViewProps['style'];
}>;

type GridElementsNumber = number | readonly number[];

type RowHeight = number | readonly number[];

type PalettesMap = Record<Palettes, ColorsTuple>;

type Palettes =
  | 'darkGray'
  | 'gray'
  | 'cream'
  | 'quinoa'
  | 'darkGreen'
  | 'lightGreen'
  | 'lightestGreen'
  | 'lightGray'
  | 'cucumber';

type ColorsTuple = readonly [string, string];

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

const ROW_FALLBACK_HEIGHT = 100;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const PALETTES_MAP: PalettesMap = {
  darkGray: ['#f2f1e5', '#e2e1d5'],
  gray: ['#f3f3f3', '#ecebeb'],
  cream: ['#f0eee2', '#e7e6dd'],
  quinoa: ['#e8dcc6', '#f1eadc'],
  darkGreen: ['#00473c', '#9cb9a1'],
  lightGreen: ['#92b19a', '#9cb9a1'],
  cucumber: ['#cddbcc', '#d5e1d3'],
  lightestGreen: ['#d0d7c9', '#c5d2c4'],
  lightGray: ['#00000000', '#0e150e3d'],
};

// ─── UTILS ──────────────────────────────────────────────────────────────────────

const getRowHeight = (
  rowHeight: LoadingPlaceholderProps['rowHeight'],
  rowIndex: number,
) => {
  // variable heights case
  if (Array.isArray(rowHeight)) {
    return rowHeight[rowIndex] ?? rowHeight.at(-1) ?? ROW_FALLBACK_HEIGHT;
  }

  // standard case
  return rowHeight ?? ROW_FALLBACK_HEIGHT;
};

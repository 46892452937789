import { LoyaltyPointsExpirationRow } from './LoyaltyPointsExpirationRow';
import {
  LoyaltyPointsHistoryCtaButton,
  LoyaltyPointsHistoryCtaLink,
} from './LoyaltyPointsHistoryCta';
import { LoyaltyPointsHistoryEmptyState } from './LoyaltyPointsHistoryEmptyState';
import { LoyaltyPointsHistoryHeader } from './LoyaltyPointsHistoryHeader';
import { LoyaltyPointsHistoryRow } from './LoyaltyPointsHistoryRow';
import { LoyaltyPointsHistorySeeMoreCta } from './LoyaltyPointsHistorySeeMoreCta';

export const LoyaltyPointsHistory = {
  Header: LoyaltyPointsHistoryHeader,
  Row: LoyaltyPointsHistoryRow,
  ExpirationRow: LoyaltyPointsExpirationRow,
  CtaButton: LoyaltyPointsHistoryCtaButton,
  CtaLink: LoyaltyPointsHistoryCtaLink,
  SeeMoreCta: LoyaltyPointsHistorySeeMoreCta,
  EmptyState: LoyaltyPointsHistoryEmptyState,
};

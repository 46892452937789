import { useCallback } from 'react';

import { useSubscribeMutation } from './graphql/SweetpassSubscribe.generated';

export function useSweetpassSubscribe() {
  const [{ fetching: isSubscribing }, subscribeMutation] =
    useSubscribeMutation();

  const subscribe = useCallback(
    async (params: SubscriptionParameters) => {
      const response = await subscribeMutation(params);
      const result = response?.data?.createSubscriptionV2?.__typename;

      const isSuccess = result === 'CreateSubscriptionResult';
      const { error } = response;

      return { isSuccess, error };
    },
    [subscribeMutation],
  );

  return { subscribe, isSubscribing };
}

// ─── Types ─────────────────────────────────────────────────────────────

type SubscriptionParameters = Readonly<{
  planId: string;
  paymentId?: string;
  campaignId?: string;
}>;

import React from 'react';
import type { TextStyle, ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';
import { theme, TYPE_CONFIG } from '@garnish/constants';

import { useResponsive } from '../../../../../hooks';
import { BodyText } from '../../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const PageHeroHeaderText = (props: PageHeroHeaderTextProps) => {
  const { children, paletteStyle } = props;

  const { match } = useResponsive();

  const textResponsiveStyles = match([
    styles.textXS,
    styles.textSM,
    styles.textSM,
    styles.textLG,
  ]);
  const textStyles = [styles.text, textResponsiveStyles, paletteStyle];
  const textSize = match([2, 2, 1] as const);

  return (
    <BodyText size={textSize} style={textStyles}>
      {children}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    textAlign: 'center',
  },
  textXS: {
    ...TYPE_CONFIG.BODY['16'],
  },
  textSM: {
    ...TYPE_CONFIG.BODY['18'],
    marginBottom: theme.spacing['10'],
  },
  textLG: {
    ...TYPE_CONFIG.BODY['24'],
    marginBottom: theme.spacing['10'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type PageHeroHeaderTextProps = Readonly<{
  children: ViewProps['children'];
  paletteStyle: TextStyle;
}>;

import React from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { SweetgreenLogoSvg } from '@sg/garnish';

import type { DesktopHeaderBaseItemProps } from './DesktopHeader.types';

type DesktopHeaderLogoItemProps = DesktopHeaderBaseItemProps;

export const DesktopHeaderLogoItem = (props: DesktopHeaderLogoItemProps) => (
  <Pressable accessibilityRole="button" {...props} style={styles.logoItem}>
    <SweetgreenLogoSvg />
  </Pressable>
);

const styles = StyleSheet.create({
  logoItem: {
    marginHorizontal: 20,
  },
});

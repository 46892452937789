import type { StyleProp, TextStyle, ViewStyle } from 'react-native';

import type {
  TagAlertPalette as Palette,
  TagAlertSize as Size,
} from '../../TagAlert.types';
import type { iconStyles, textStyles } from '../styles';

/**
 * This is used to create style hooks for each button component: Box, Text, Icon
 * - First it is initialized with the stylesheet specific to the component
 * - Next it accepts garnish configuration props (palette, size)
 * - Last is a function which will respond to state changes hovered/pressed
 *
 */

export const createUseTagAlertStylesHook: CreateUseTagAlertStylesHook = (
  styleGroup,
) => {
  const getPaletteStyles = makeGetPalette(styleGroup);
  const getSizeStyles = makeGetSize(styleGroup);

  return (palette, size) => {
    const sizeStyles = getSizeStyles(size);
    const paletteStyles = getPaletteStyles(palette);

    return () => [sizeStyles, paletteStyles];
  };
};

// -----------------------------------------------------------------------------
// ---- Helpers ----------------------------------------------------------------
// -----------------------------------------------------------------------------

const makeGetPalette: MakeGetPalette = (styleGroup) => (palette) => {
  return styleGroup[palette];
};

const makeGetSize: MakeGetSize = (styleGroup) => (size) => styleGroup[size];

// -----------------------------------------------------------------------------
// ---- Types ------------------------------------------------------------------
// -----------------------------------------------------------------------------

type CreateUseTagAlertStylesHook = (
  sg: StyleGroups,
) => (p: PaletteTypes, s: SizeTypes) => () => ReturnedStyles;

type MakeGetPalette = (sg: StyleGroups) => (p: PaletteTypes) => ReturnedStyles;

type MakeGetSize = (sg: StyleGroups) => (s: SizeTypes) => ReturnedStyles;

type SizeTypes = Required<keyof typeof Size>;
type PaletteTypes = Required<keyof typeof Palette>;
type ReturnedStyles = StyleProp<ViewStyle | TextStyle>;

type StyleGroups = typeof textStyles | typeof iconStyles;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import {
  BodyText,
  Container,
  DisplayText,
  IllusDressing_1,
  Image,
  theme,
} from '@sg/garnish';

import { giftCardsErrorViewMessages } from './GiftCardsErrorView.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsErrorView = () => {
  return (
    <Container style={styles.errorContainer}>
      <Image
        source={IllusDressing_1}
        style={styles.errorIllustration}
        contentFit="contain"
      />

      <DisplayText sizeMatch={['32']} style={styles.errorHeading}>
        <FormattedMessage {...giftCardsErrorViewMessages.title} />
      </DisplayText>

      <BodyText sizeMatch={['18']} style={styles.errorText}>
        <FormattedMessage {...giftCardsErrorViewMessages.text} />
      </BodyText>
    </Container>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  errorContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: theme.spacing['3'],
    paddingTop: theme.spacing['20'],
  },
  errorIllustration: {
    width: 200,
    height: 200,
  },
  errorHeading: {
    textAlign: 'center',
  },
  errorText: {
    textAlign: 'center',
    marginBottom: theme.spacing['2'],
  },
});

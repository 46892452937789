/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: string; output: string };
  join__FieldSet: { input: string; output: string };
  link__Import: { input: string; output: string };
};

/** Error when a user has an active payment method */
export type ActivePaymentMethodError = UserError & {
  readonly __typename?: 'ActivePaymentMethodError';
  readonly errorMessage: Scalars['String']['output'];
};

export type AddAddressInput = {
  readonly city: Scalars['String']['input'];
  readonly country: Scalars['String']['input'];
  readonly deliveryPreference: DeliveryPreferenceType;
  readonly googlePlaceId: Scalars['String']['input'];
  readonly latitude: Scalars['Float']['input'];
  readonly longitude: Scalars['Float']['input'];
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly secondaryStreet?: InputMaybe<Scalars['String']['input']>;
  readonly state: Scalars['String']['input'];
  readonly street: Scalars['String']['input'];
  readonly zipCode: Scalars['String']['input'];
};

export type AddAddressResponseUnion =
  | AddAddressSuccess
  | InvalidAddress
  | ValidationError;

export type AddAddressSuccess = {
  readonly __typename?: 'AddAddressSuccess';
  readonly address: Address;
};

export type AddLineItemBatchToCartInput = {
  readonly lineItems: ReadonlyArray<CartInput>;
};

export type AddLineItemToCartInput = {
  readonly additions: ReadonlyArray<IngredientModificationInput>;
  readonly customName?: InputMaybe<Scalars['String']['input']>;
  readonly deliveryOrderDetails?: InputMaybe<DeliveryOrderDetailInput>;
  readonly mixedDressingDetails: ReadonlyArray<MixedDressingDetailsInput>;
  readonly productId: Scalars['ID']['input'];
  readonly quantity: Scalars['Int']['input'];
  readonly removals: ReadonlyArray<IngredientModificationInput>;
  readonly restaurantId?: InputMaybe<Scalars['Int']['input']>;
  readonly substitutions: ReadonlyArray<IngredientSubstitutionModificationInput>;
};

export type AddLineItemToCartResponseUnion =
  | AddLineItemToCartSuccess
  | LineItemModificationsInvalidException
  | MaxModificationsExceeded
  | ProductOutOfStock
  | RestaurantMaxDeliveryQuantityExceeded
  | RestaurantMaxQuantityExceeded
  | ValidationError;

export type AddLineItemToCartSuccess = {
  readonly __typename?: 'AddLineItemToCartSuccess';
  readonly cart: Order;
};

export type AddSubscriptionPaymentMethodResponseUnion =
  | AddSubscriptionPaymentMethodSuccessResponse
  | InvalidPaymentMethod
  | PaymentMethodAlreadyExistsError
  | UnknownUserError;

/** The result of adding a payment method to a subscription */
export type AddSubscriptionPaymentMethodSuccessResponse = {
  readonly __typename?: 'AddSubscriptionPaymentMethodSuccessResponse';
  /** Payment Method details */
  readonly creditCard: SubscriptionCreditCard;
  /** Payment Method Token */
  readonly paymentMethodToken: Scalars['String']['output'];
};

export type Addons = {
  readonly __typename?: 'Addons';
  readonly products: ReadonlyArray<Product>;
};

export type Address = {
  readonly __typename?: 'Address';
  readonly city: Scalars['String']['output'];
  readonly country: Scalars['String']['output'];
  readonly deliveryPreference: DeliveryPreferenceType;
  readonly googlePlaceId: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly latitude: Scalars['Float']['output'];
  readonly longitude: Scalars['Float']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  readonly notes: Maybe<Scalars['String']['output']>;
  /** Address Line 2 */
  readonly secondaryStreet: Maybe<Scalars['String']['output']>;
  readonly state: Scalars['String']['output'];
  /** Address Line 1 */
  readonly street: Scalars['String']['output'];
  readonly zipCode: Scalars['String']['output'];
};

export enum ApplyRewardFailureCode {
  LoyaltyNotProcessed = 'LOYALTY_NOT_PROCESSED',
  LoyaltyProgramUnavailable = 'LOYALTY_PROGRAM_UNAVAILABLE',
  RestaurantNotAcceptingOrders = 'RESTAURANT_NOT_ACCEPTING_ORDERS',
  RewardExpired = 'REWARD_EXPIRED',
  RewardNotApplicable = 'REWARD_NOT_APPLICABLE',
  RewardNotFound = 'REWARD_NOT_FOUND',
}

/** Validate and preview rewards success response */
export type ApplyRewardFailureMetadata = {
  readonly __typename?: 'ApplyRewardFailureMetadata';
  readonly requiredChannel: Maybe<Scalars['String']['output']>;
  readonly requiredDays: Maybe<ReadonlyArray<Scalars['Float']['output']>>;
};

export type ApplyRewardInput = {
  /** The ID of the order the reward is being applied to */
  readonly orderId: Scalars['ID']['input'];
  /** The ID of the reward being applied to the order */
  readonly rewardId: Scalars['ID']['input'];
};

export type ApplyRewardResponseUnion =
  | ApplyRewardSuccess
  | RewardNotApplied
  | ValidationError;

export type ApplyRewardSuccess = {
  readonly __typename?: 'ApplyRewardSuccess';
  readonly order: Order;
};

export type Asset = {
  readonly __typename?: 'Asset';
  readonly url: Scalars['String']['output'];
};

/** Async cancelation failed */
export type AsyncCancelFailed = UserError & {
  readonly __typename?: 'AsyncCancelFailed';
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

/** The error response for available customer support points. */
export type AvailableCustomerSupportPointsError = UserError & {
  readonly __typename?: 'AvailableCustomerSupportPointsError';
  /** The error message. */
  readonly errorMessage: Scalars['String']['output'];
};

export type AvailableCustomerSupportPointsResponseUnion =
  | AvailableCustomerSupportPointsError
  | AvailableCustomerSupportPointsSuccess;

/** The response for available customer support points. */
export type AvailableCustomerSupportPointsSuccess = {
  readonly __typename?: 'AvailableCustomerSupportPointsSuccess';
  /** The available customer support points. */
  readonly points: ReadonlyArray<CustomerSupportPoint>;
};

export type AvailableWantedTime = {
  readonly __typename?: 'AvailableWantedTime';
  readonly deliveryOffset: Scalars['Int']['output'];
  readonly time: Scalars['String']['output'];
};

export type BaseProduct = {
  readonly __typename?: 'BaseProduct';
  readonly asset: Asset;
  readonly description: Scalars['String']['output'];
  readonly heading: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly isDeepLinkingEnabled: Scalars['Boolean']['output'];
  readonly isSeasonal: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly slug: Scalars['String']['output'];
  readonly subheading: Scalars['String']['output'];
};

export type BillingAccount = {
  readonly __typename?: 'BillingAccount';
  readonly cardType: CardType;
  /** Computed display name containing card type and last 4 digits */
  readonly description: Scalars['String']['output'];
  readonly expirationState: ExpirationState;
  readonly id: Scalars['ID']['output'];
  readonly isDefault: Scalars['Boolean']['output'];
  /** User-supplied alias */
  readonly nickname: Maybe<Scalars['String']['output']>;
};

export enum BraintreeCardType {
  AmEx = 'AmEx',
  CarteBlanche = 'CarteBlanche',
  ChinaUnionPay = 'ChinaUnionPay',
  DinersClubInternational = 'DinersClubInternational',
  Discover = 'Discover',
  Elo = 'Elo',
  Jcb = 'JCB',
  Laser = 'Laser',
  Maestro = 'Maestro',
  MasterCard = 'MasterCard',
  Solo = 'Solo',
  Switch = 'Switch',
  UkMaestro = 'UKMaestro',
  Unknown = 'Unknown',
  Visa = 'Visa',
}

/** CX Credit */
export type CxCredit = {
  readonly __typename?: 'CXCredit';
  /** Display name of offer */
  readonly displayName: Scalars['String']['output'];
  /** ID of offer from loyalty vendor */
  readonly id: Scalars['ID']['output'];
};

export type CanCancel = {
  readonly __typename?: 'CanCancel';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  /** Order ID validating on */
  readonly orderId: Scalars['Float']['output'];
  /**
   * Time in minutes before the order can no longer be canceled (sent to store)
   * @deprecated no longer supported.
   */
  readonly timeToFire: Scalars['Float']['output'];
};

/** Cannot find order to check if cancelation is allowed */
export type CanCancelAlreadySent = UserError & {
  readonly __typename?: 'CanCancelAlreadySent';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['Float']['output'];
};

/** Generic Error for a failed can cancel query */
export type CanCancelFailed = UserError & {
  readonly __typename?: 'CanCancelFailed';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['Float']['output'];
};

export type CanCancelInput = {
  /** Gravy customer ID parent to the order being canceled. */
  readonly customerId: Scalars['String']['input'];
  /** Gravy order ID to be canceled. */
  readonly orderId: Scalars['String']['input'];
  /** Callers origin (eg kustomer, customer, etc.). */
  readonly origin: Scalars['String']['input'];
  /** Gravy restaurant ID parent to the order being canceled. */
  readonly restaurantId: Scalars['String']['input'];
  /** Watned time of order in ISO format. */
  readonly wantedTime: Scalars['DateTime']['input'];
};

/** Cannot find order to check if cancelation is allowed */
export type CanCancelNotFound = UserError & {
  readonly __typename?: 'CanCancelNotFound';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['Float']['output'];
};

export type CanCancelResponseUnion =
  | CanCancel
  | CanCancelAlreadySent
  | CanCancelFailed
  | CanCancelNotFound
  | CanCancelUnauthorized
  | CantCancelAlreadyCanceled
  | CantCancelLimitExcceeded
  | CantCancelOldOrders;

/** Unauthorized */
export type CanCancelUnauthorized = UserError & {
  readonly __typename?: 'CanCancelUnauthorized';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['Float']['output'];
};

export type CanNotReorder = {
  readonly __typename?: 'CanNotReorder';
  readonly conflicts: ReadonlyArray<OrderConflict>;
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

/** Generic Error for a failed cancellation */
export type CancelOrderFailed = UserError & {
  readonly __typename?: 'CancelOrderFailed';
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

export type CancelOrderInput = {
  /** Gravy customer ID parent to the order being canceled. */
  readonly customerId: Scalars['String']['input'];
  /** Gravy order ID to be canceled. */
  readonly orderId: Scalars['String']['input'];
  /** Callers origin (eg kustomer, customer, etc.). */
  readonly origin: Scalars['String']['input'];
  /** Description for why the order is being canceled. */
  readonly reason: Scalars['String']['input'];
  /** Refund the order if cancel succeeds?. */
  readonly refund: Scalars['Boolean']['input'];
  /** Gravy restaurant ID parent to the order being canceled. */
  readonly restaurantId: Scalars['String']['input'];
};

export type CancelOrderResponseUnion =
  | AsyncCancelFailed
  | CancelOrderFailed
  | CancelOrderSuccess
  | CancelOrderSuccessAsync
  | CancelSuccessRefundFailed
  | CancellationFailedWithDelivery
  | CancellationLimitExceeded
  | OrderAlreadyCanceled
  | OrderAlreadySentToKitchen
  | OrderNotFound;

export type CancelOrderSuccess = {
  readonly __typename?: 'CancelOrderSuccess';
  readonly orderId: Scalars['String']['output'];
  /** Reason for a failed order or a success string. */
  readonly reason: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

export type CancelOrderSuccessAsync = {
  readonly __typename?: 'CancelOrderSuccessAsync';
  readonly orderId: Scalars['String']['output'];
  /** Async order successfully canceled. */
  readonly reason: Scalars['String']['output'];
  /** Boolean representing if the async order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

export type CancelSubscriptionAtCycleEndResponseUnion =
  | CancelSubscriptionAtCycleEndSuccessResponse
  | UnknownUserError;

export type CancelSubscriptionAtCycleEndSuccessResponse = {
  readonly __typename?: 'CancelSubscriptionAtCycleEndSuccessResponse';
  /** Canceled Subscription */
  readonly subscription: PaymentSubscription;
};

export type CancelSubscriptionForCustomerResponseUnion =
  | CancelSubscriptionForCustomerSuccessResponse
  | UnknownUserError;

export type CancelSubscriptionForCustomerSuccessResponse = {
  readonly __typename?: 'CancelSubscriptionForCustomerSuccessResponse';
  /** Indicates if the subscription was successfully cancelled */
  readonly success: Scalars['Boolean']['output'];
};

export type CancelSubscriptionResponseUnion =
  | CancelSubscriptionSuccessResponse
  | UnknownUserError;

export type CancelSubscriptionSuccessResponse = {
  readonly __typename?: 'CancelSubscriptionSuccessResponse';
  /** Canceled Subscription */
  readonly subscription: PaymentSubscription;
};

export type CancelSuccessRefundFailed = {
  readonly __typename?: 'CancelSuccessRefundFailed';
  /** Cancel succeeded but refund failed. */
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

/** Cancellation failed with delivery service */
export type CancellationFailedWithDelivery = UserError & {
  readonly __typename?: 'CancellationFailedWithDelivery';
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

/** Cannot cancel more then one order per user per day */
export type CancellationLimitExceeded = UserError & {
  readonly __typename?: 'CancellationLimitExceeded';
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

/** Cannot refund subscription transaction user error */
export type CannotRefundSubscriptionTransactionUserError = UserError & {
  readonly __typename?: 'CannotRefundSubscriptionTransactionUserError';
  readonly errorMessage: Scalars['String']['output'];
};

/** Order has already been canceled */
export type CantCancelAlreadyCanceled = UserError & {
  readonly __typename?: 'CantCancelAlreadyCanceled';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['Float']['output'];
};

/** Cannot cancel more then one order per user per day */
export type CantCancelLimitExcceeded = UserError & {
  readonly __typename?: 'CantCancelLimitExcceeded';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['Float']['output'];
};

/** Order is greater then 2 days old */
export type CantCancelOldOrders = UserError & {
  readonly __typename?: 'CantCancelOldOrders';
  /** Boolean representing if the order can be canceled or not. */
  readonly canCancel: Scalars['Boolean']['output'];
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['Float']['output'];
};

export enum CardExpirationState {
  Expired = 'EXPIRED',
  Nearing = 'NEARING',
  Ok = 'OK',
}

export enum CardType {
  AmericanExpress = 'AMERICAN_EXPRESS',
  Amex = 'AMEX',
  Discover = 'DISCOVER',
  MasterCard = 'MASTER_CARD',
  Unsupported = 'UNSUPPORTED',
  Visa = 'VISA',
}

export type CartInput = {
  readonly additions: ReadonlyArray<IngredientModificationInput>;
  readonly customName?: InputMaybe<Scalars['String']['input']>;
  readonly mixedDressingDetails: ReadonlyArray<MixedDressingDetailsInput>;
  readonly productId: Scalars['ID']['input'];
  readonly quantity: Scalars['Int']['input'];
  readonly removals: ReadonlyArray<IngredientModificationInput>;
  readonly substitutions: ReadonlyArray<IngredientSubstitutionModificationInput>;
};

/** A Challenge is a configured SessionM Campaign with the "is_challenge: true" flag set in the campaign custom payload */
export type Challenge = {
  readonly __typename?: 'Challenge';
  /** Field is integer representation of the total amount of times the challenge behavior was completed by the customer. */
  readonly achieved: Scalars['Float']['output'];
  readonly assetUrl: Maybe<Scalars['String']['output']>;
  /** Field originates from SessionM Activity feed configuration message description. */
  readonly celebrationCopy: Scalars['String']['output'];
  /** Field originates from SessionM Activity feed configuration message subheader. */
  readonly descriptiveTitle: Scalars['String']['output'];
  readonly expirationDate: Scalars['DateTime']['output'];
  /** Field is a number representation of the challenge goal. Ex. A two STEP challenge would have a goal of `2.` A SPEND $20 challenge would have a goal of `2000` */
  readonly goal: Scalars['Float']['output'];
  /** Field is the SessionM campaign id */
  readonly id: Scalars['ID']['output'];
  readonly legalTerms: Scalars['String']['output'];
  /** Field is `progress` divided by `goal` times 100. To be used to render progress bar. */
  readonly percentageCompleted: Scalars['Float']['output'];
  readonly permalink: Scalars['String']['output'];
  /** Field indicates the current count completed toward challenge goal. */
  readonly progress: Scalars['Float']['output'];
  /** Field is copy to show on the challenge card to indicate progress. Determined by challenge status and progress fields. */
  readonly progressCopy: Scalars['String']['output'];
  /** Field is the progress type of the challenge */
  readonly progressType: ChallengeProgressType;
  readonly status: ChallengeStatus;
  /** Field represents the type of challenge, e.g. FLASH. Defaults to DEFAULT */
  readonly theme: ChallengeTheme;
  /** Field originates from SessionM Activity feed configuration message header. */
  readonly title: Scalars['String']['output'];
};

/** Challenge celebration data originating from campaign information on SessionM. To be used to render challenge celebration messaging. */
export type ChallengeCelebration = {
  readonly __typename?: 'ChallengeCelebration';
  /** Field originates from SessionM Activity feed configuration message description. When a user has multiple rewards to be "celebrated" this field is hardcoded. */
  readonly celebrationCopy: Scalars['String']['output'];
  /** Completed challenges ids */
  readonly challengesIds: ReadonlyArray<Scalars['String']['output']>;
  /** Expiration date for reward. When a user has multiple rewards available to be "celebrated" this field will return null. */
  readonly expirationDate: Maybe<Scalars['DateTime']['output']>;
  /** Field is hardcoded in challenges-svc */
  readonly rewardUsageDisclaimer: Scalars['String']['output'];
  /** Field originates from SessionM Activity Feed configuration message header. When a user has multiple rewards available to be "celebrated" this field will return null. */
  readonly title: Maybe<Scalars['String']['output']>;
};

/** Challenge celebration data originating from campaign information on SessionM. To be used to render challenge celebration messaging. */
export type ChallengeCelebrationV2 = {
  readonly __typename?: 'ChallengeCelebrationV2';
  /** Field originates from SessionM Activity feed configuration message description. When a user has multiple rewards to be "celebrated" this field is hardcoded. */
  readonly celebrationCopy: Scalars['String']['output'];
  /** Completed challenges ids */
  readonly challengesIds: ReadonlyArray<Scalars['String']['output']>;
  /** Expiration date for reward. When a user has multiple rewards available to be "celebrated" this field will return null. */
  readonly expirationDate: Maybe<Scalars['DateTime']['output']>;
  /** Field originates from SessionM Activity Feed configuration message header. When a user has multiple rewards available to be "celebrated" this field will return null. */
  readonly title: Scalars['String']['output'];
};

export type ChallengeOptInResultUnion = Challenge | LoyaltyOptInFailed;

/** Step = step challenge, Spend = spend challenge */
export enum ChallengeProgressType {
  Spend = 'Spend',
  Step = 'Step',
}

/** Reward available to the customer */
export type ChallengeReward = {
  readonly __typename?: 'ChallengeReward';
  readonly expiresAt: Maybe<Scalars['DateTime']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  /** The Premium reward is only available to a customer 1x every twelve hours. The redeemable field allows the UI to indicate whether the reward is currently available to be used. */
  readonly redeemable: Maybe<Scalars['Boolean']['output']>;
  /** The Premium reward is only available to a customer 1x every twelve hours. The redeemableAt will populate if reward is not redeemable with the next available date. */
  readonly redeemableAt: Maybe<Scalars['DateTime']['output']>;
  /** The channel(s) in which the reward can be redeemed */
  readonly redemptionChannel: RedemptionChannel;
  /** Type PREMIUM refers to Premium Subscription Reward, NON_PREMIUM rewards not associated with loyalty program */
  readonly rewardType: RewardType;
  /** Field references the offer terms and conditions configured in SessionM. If that field is not configured, returns the default SG terms url. */
  readonly terms: Scalars['String']['output'];
};

/** NOT_STARTED = Customer is qualified for challenge, STARTED = Customer has opted in, IN_PROGRESS: challenge is in progress, COMPLETED: customer has completed the challenge */
export enum ChallengeStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
  Started = 'STARTED',
}

/** FLASH = a flash challenge, DEFAULT = default theme */
export enum ChallengeTheme {
  Default = 'DEFAULT',
  Flash = 'FLASH',
}

export type ClosedLoyaltyTransactionDetailsInput = {
  readonly createdAt: Scalars['String']['input'];
  readonly id: Scalars['String']['input'];
  readonly isInFlight: Scalars['Boolean']['input'];
  readonly isInFlightDelivery: Scalars['Boolean']['input'];
  readonly lineItems: ReadonlyArray<OrderLineItemInput>;
  readonly orderType: Scalars['String']['input'];
  readonly payments: ReadonlyArray<OrderPaymentInput>;
  readonly restaurantEntityId?: InputMaybe<Scalars['Float']['input']>;
  readonly restaurantParentId?: InputMaybe<Scalars['Float']['input']>;
  readonly restaurantUtcOffset?: InputMaybe<Scalars['Float']['input']>;
  readonly selectedRewards?: InputMaybe<
    ReadonlyArray<Scalars['String']['input']>
  >;
  /** The selected reward with root offer id */
  readonly selectedRewardsWithDetails?: InputMaybe<
    ReadonlyArray<SelectedRewardsWithDetails>
  >;
  readonly subtotal: Scalars['Float']['input'];
  readonly tax: Scalars['Float']['input'];
  readonly updatedAt?: InputMaybe<Scalars['String']['input']>;
  readonly wantedTime?: InputMaybe<Scalars['String']['input']>;
};

export enum ConflictType {
  DeliveryIneligible = 'DELIVERY_INELIGIBLE',
  IngredientUnavailable = 'INGREDIENT_UNAVAILABLE',
  NoConflict = 'NO_CONFLICT',
  ProductUnavailable = 'PRODUCT_UNAVAILABLE',
}

/** The cost of the product may vary depending on the channel of the order. Use DELIVERY_COST_CHANNEL if the current order is delivery, and DEFAULT_COST_CHANNEL otherwise. Marketplace and Outpost cost channels are implicit based on the request and restaurant. */
export enum CostChannel {
  DefaultCostChannel = 'DEFAULT_COST_CHANNEL',
  DeliveryCostChannel = 'DELIVERY_COST_CHANNEL',
  PickupCostChannel = 'PICKUP_COST_CHANNEL',
}

export type CourierDetails = {
  readonly __typename?: 'CourierDetails';
  readonly latitude: Maybe<Scalars['Float']['output']>;
  readonly longitude: Maybe<Scalars['Float']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly phone: Maybe<Scalars['String']['output']>;
};

export type CreateAccountInput = {
  /** Must be in YYYY-MM-DD format. */
  readonly birthday?: InputMaybe<Scalars['String']['input']>;
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  /** Must be a valid US phone number, exactly 10 digits with no formatting. */
  readonly phoneNumber: Scalars['String']['input'];
};

export type CreateAccountResponseUnion = CreateAccountSuccess | ValidationError;

export type CreateAccountSuccess = {
  readonly __typename?: 'CreateAccountSuccess';
  readonly customer: Customer;
};

/** Successful connection token response */
export type CreateConnectionTokenResponse = {
  readonly __typename?: 'CreateConnectionTokenResponse';
  /** A token to pass token to the Stripe Terminal SDK */
  readonly secret: Scalars['String']['output'];
};

/** Successful location creation response */
export type CreateKioskReaderLocationIdResponse = {
  readonly __typename?: 'CreateKioskReaderLocationIdResponse';
  readonly address: RestaurantAddress;
  readonly display_name: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly livemode: Scalars['Boolean']['output'];
  readonly metadata: LocationMetadata;
  readonly object: Scalars['String']['output'];
};

export type CreateSetupIntent = {
  readonly __typename?: 'CreateSetupIntent';
  /** The Stripe created setup intent secret, required for the client to confirm its creation */
  readonly clientSecret: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
};

export type CreateSubscriptionResponseUnion =
  | CreateSubscriptionResult
  | FreeTrialNotEligible
  | UnknownUserError;

export type CreateSubscriptionResult = {
  readonly __typename?: 'CreateSubscriptionResult';
  /** First date that billing should happen */
  readonly firstBillingDate: Maybe<Scalars['DateTime']['output']>;
  /** Indicates if the subscription has a free trial associated with it. */
  readonly hasFreeTrial: Scalars['Boolean']['output'];
  /** The ID of the subscription */
  readonly id: Scalars['ID']['output'];
  /** Indicates if the subscription is currently on a free trial (was not paid for yet). */
  readonly isTrialActive: Scalars['Boolean']['output'];
  /** The status of the subscription */
  readonly status: Scalars['String']['output'];
  /** The amount that will be charged */
  readonly subscriptionAmount: Scalars['String']['output'];
  /**
   * Indicates if the subscription has trial period
   * @deprecated Use "hasFreeTrial" instead
   */
  readonly trialDuration: Scalars['Boolean']['output'];
};

export type Credit = {
  readonly __typename?: 'Credit';
  readonly amount: Scalars['Float']['output'];
  readonly createdAt: Scalars['String']['output'];
  /** The type of credit */
  readonly creditType: CreditType;
  readonly description: Maybe<Scalars['String']['output']>;
  readonly expiresAt: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly remainingAmount: Scalars['Float']['output'];
  readonly storefrontEligibilityTitle: Scalars['String']['output'];
  readonly title: Scalars['String']['output'];
};

export enum CreditType {
  AllNativeStorefronts = 'ALL_NATIVE_STOREFRONTS',
  InStoreOnly = 'IN_STORE_ONLY',
  OnlineOnly = 'ONLINE_ONLY',
}

export enum CustomProductType {
  Bowl = 'BOWL',
  Plate = 'PLATE',
}

/** Customer details including available credits */
export type Customer = {
  readonly __typename?: 'Customer';
  readonly addresses: ReadonlyArray<Address>;
  /** The amount of credit available to the customer. Credit is applied after tax. */
  readonly availableCredit: Scalars['Int']['output'];
  readonly billingAccounts: ReadonlyArray<BillingAccount>;
  readonly birthday: Maybe<Scalars['String']['output']>;
  /** A detail list of available credits to the customer. */
  readonly credits: ReadonlyArray<Credit>;
  /**
   * Is this user marked for deletion.
   * @deprecated Clients have no use for this field.
   */
  readonly deletionInProgress: Maybe<Scalars['Boolean']['output']>;
  readonly email: Maybe<Scalars['String']['output']>;
  readonly firstName: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly lastName: Maybe<Scalars['String']['output']>;
  readonly phoneNumber: Maybe<Scalars['String']['output']>;
  /** Level Up / Paytronix Payment Token for the customer, used to generate QR codes. */
  readonly qrCode: Scalars['String']['output'];
  /** Level Up Share URL to be used when sharing by email. */
  readonly referralShareUrlEmail: Scalars['String']['output'];
  /** Gravy-generated UUID used for analytics purposes */
  readonly trackingUuid: Maybe<Scalars['String']['output']>;
  /** Whether or not the customer will use any available credit on their next transaction. */
  readonly useCredit: Scalars['Boolean']['output'];
};

export type CustomerNotFound = UserError & {
  readonly __typename?: 'CustomerNotFound';
  readonly errorMessage: Scalars['String']['output'];
};

export type CustomerPass = {
  readonly __typename?: 'CustomerPass';
  readonly authenticationToken: Maybe<Scalars['String']['output']>;
  readonly url: Maybe<Scalars['String']['output']>;
};

export type CustomerPointsForCustomerSupportFailureError = UserError & {
  readonly __typename?: 'CustomerPointsForCustomerSupportFailureError';
  readonly errorMessage: Scalars['String']['output'];
};

export type CustomerPointsForCustomerSupportResponseUnion =
  | CustomerPointsForCustomerSupportFailureError
  | CustomerPointsForCustomerSupportSuccessResponse;

/** Successful response for customer points for customer support. */
export type CustomerPointsForCustomerSupportSuccessResponse = {
  readonly __typename?: 'CustomerPointsForCustomerSupportSuccessResponse';
  readonly points: Scalars['Int']['output'];
};

/** Failure to fetch Customer Support Credits */
export type CustomerSupportCreditsFailureError = UserError & {
  readonly __typename?: 'CustomerSupportCreditsFailureError';
  readonly errorMessage: Scalars['String']['output'];
};

export type CustomerSupportCreditsResponseUnion =
  | CustomerSupportCreditsFailureError
  | CustomerSupportCreditsSuccessResponse;

/** Customer Support Credits Result */
export type CustomerSupportCreditsSuccessResponse = {
  readonly __typename?: 'CustomerSupportCreditsSuccessResponse';
  /** Credits allocated for customer support team to issue to customers */
  readonly credits: ReadonlyArray<CxCredit>;
};

/** A customer support point. */
export type CustomerSupportPoint = {
  readonly __typename?: 'CustomerSupportPoint';
  /** The display value of the points available. */
  readonly displayValue: Scalars['String']['output'];
  /** The number of points available for customer support to issue. */
  readonly value: Scalars['Float']['output'];
};

export type DeclinedPaymentMethod = UserError & {
  readonly __typename?: 'DeclinedPaymentMethod';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type DeleteAddressResponseUnion =
  | DeleteAddressSuccess
  | InvalidAddress
  | ValidationError;

export type DeleteAddressSuccess = {
  readonly __typename?: 'DeleteAddressSuccess';
  readonly success: Scalars['Boolean']['output'];
};

export type DeletePaymentMethodCardInput = {
  /** Id of card to delete */
  readonly id: Scalars['String']['input'];
};

export type DeleteSubscriptionPaymentMethodResponseUnion =
  | ActivePaymentMethodError
  | DeleteSubscriptionPaymentMethodSuccess
  | UnknownUserError;

/** The result of deleting a payment method from a subscription */
export type DeleteSubscriptionPaymentMethodSuccess = {
  readonly __typename?: 'DeleteSubscriptionPaymentMethodSuccess';
  /** Payment Methods */
  readonly creditCards: ReadonlyArray<SubscriptionCreditCard>;
};

export type DeliveryAlreadyInFlight = UserError & {
  readonly __typename?: 'DeliveryAlreadyInFlight';
  readonly errorMessage: Scalars['String']['output'];
};

export type DeliveryOrderDetail = {
  readonly __typename?: 'DeliveryOrderDetail';
  readonly address: Maybe<Address>;
  /** Delivery Fee in cents */
  readonly deliveryFee: Scalars['Float']['output'];
  /** Estimated delivery time for an delivery order, usually used as a range with +- 15mins offset. For outpost orders this is represented by the order "wantedTime". */
  readonly estimatedDeliveryTime: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly orderId: Scalars['ID']['output'];
  /** Courier tip in cents */
  readonly tip: Scalars['Float']['output'];
  readonly vendor: Scalars['String']['output'];
  readonly vendorRestaurantId: Scalars['String']['output'];
};

export type DeliveryOrderDetailInput = {
  readonly addressId: Scalars['ID']['input'];
  readonly deliveryFee: Scalars['Float']['input'];
  readonly tip: Scalars['Float']['input'];
  readonly vendor: Scalars['String']['input'];
  readonly vendorRestaurantId: Scalars['String']['input'];
};

export enum DeliveryPreferenceType {
  Curbside = 'CURBSIDE',
  Door = 'DOOR',
  LeaveAtDoor = 'LEAVE_AT_DOOR',
}

export type DeliverySpecifyAddress = UserError & {
  readonly __typename?: 'DeliverySpecifyAddress';
  readonly errorMessage: Scalars['String']['output'];
};

export type DietaryProperty = {
  readonly __typename?: 'DietaryProperty';
  readonly id: Scalars['ID']['output'];
  readonly name: DietaryPropertyKind;
};

export enum DietaryPropertyKind {
  Dairy = 'DAIRY',
  Fish = 'FISH',
  Gluten = 'GLUTEN',
  Meat = 'MEAT',
  Nuts = 'NUTS',
  Soy = 'SOY',
}

/** Discount on payment plan */
export type Discount = {
  readonly __typename?: 'Discount';
  /** The quantity of the discount */
  readonly amount: Maybe<Scalars['String']['output']>;
  /** The description of the discount */
  readonly description: Maybe<Scalars['String']['output']>;
  /** Discount identifier */
  readonly id: Scalars['ID']['output'];
  /** The name of the discount */
  readonly name: Scalars['ID']['output'];
};

export enum DressingMode {
  Mixed = 'MIXED',
  NotDressable = 'NOT_DRESSABLE',
  OnTheSide = 'ON_THE_SIDE',
  Removed = 'REMOVED',
}

export enum DressingWeight {
  Heavy = 'HEAVY',
  Light = 'LIGHT',
  Medium = 'MEDIUM',
}

export type DropOffLocation = {
  readonly __typename?: 'DropOffLocation';
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
};

/** Unit used for handling duration types. */
export enum DurationUnit {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type EditAddressInput = {
  readonly city: Scalars['String']['input'];
  readonly country: Scalars['String']['input'];
  readonly deliveryPreference: DeliveryPreferenceType;
  readonly googlePlaceId: Scalars['String']['input'];
  readonly id: Scalars['ID']['input'];
  readonly latitude: Scalars['Float']['input'];
  readonly longitude: Scalars['Float']['input'];
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly notes?: InputMaybe<Scalars['String']['input']>;
  readonly secondaryStreet?: InputMaybe<Scalars['String']['input']>;
  readonly state: Scalars['String']['input'];
  readonly street: Scalars['String']['input'];
  readonly zipCode: Scalars['String']['input'];
};

export type EditLineItemInCartInput = {
  readonly additions: ReadonlyArray<IngredientModificationInput>;
  readonly customName?: InputMaybe<Scalars['String']['input']>;
  readonly lineItemId: Scalars['ID']['input'];
  readonly mixedDressingDetails: ReadonlyArray<MixedDressingDetailsInput>;
  readonly productId: Scalars['ID']['input'];
  readonly quantity: Scalars['Int']['input'];
  readonly removals: ReadonlyArray<IngredientModificationInput>;
  readonly substitutions: ReadonlyArray<IngredientSubstitutionModificationInput>;
};

export type EditLineItemInCartResponseUnion =
  | EditLineItemInCartSuccess
  | MaxModificationsExceeded
  | RestaurantMaxDeliveryQuantityExceeded
  | RestaurantMaxQuantityExceeded
  | ValidationError;

export type EditLineItemInCartSuccess = {
  readonly __typename?: 'EditLineItemInCartSuccess';
  readonly cart: Order;
};

export type EstimateRange = {
  readonly __typename?: 'EstimateRange';
  readonly end: Scalars['Int']['output'];
  readonly start: Scalars['Int']['output'];
};

export enum ExpirationState {
  Expired = 'EXPIRED',
  Nearing = 'NEARING',
  Ok = 'OK',
}

export type FavoriteLineItemInput = {
  readonly favorited: Scalars['Boolean']['input'];
  readonly lineItemId: Scalars['ID']['input'];
  readonly productId: Scalars['ID']['input'];
};

export type FavoritedLineItemsInput = {
  readonly page: Scalars['Int']['input'];
};

export type FeedbackInput = {
  readonly id: Scalars['ID']['input'];
  readonly isFeedbackComplete?: InputMaybe<Scalars['Boolean']['input']>;
  readonly questionId: Scalars['ID']['input'];
  readonly responseIds: ReadonlyArray<Scalars['ID']['input']>;
};

export type FeedbackQuestion = {
  readonly __typename?: 'FeedbackQuestion';
  readonly id: Scalars['ID']['output'];
  readonly responses: Maybe<ReadonlyArray<FeedbackResponse>>;
  readonly slug: Maybe<Scalars['String']['output']>;
  readonly text: Maybe<Scalars['String']['output']>;
};

export type FeedbackResponse = {
  readonly __typename?: 'FeedbackResponse';
  readonly id: Scalars['ID']['output'];
  readonly text: Scalars['String']['output'];
};

export type FeedbackSuccess = {
  readonly __typename?: 'FeedbackSuccess';
  readonly success: Scalars['Boolean']['output'];
};

export type FieldValidationError = {
  readonly __typename?: 'FieldValidationError';
  readonly field: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
};

export enum FlattenedOrderStatuses {
  Completed = 'completed',
  Delivering = 'delivering',
  Failed = 'failed',
  Preparing = 'preparing',
  ReadyForCourier = 'readyForCourier',
  Received = 'received',
}

/** An error that occurs as a result of attempting to subscribe to a free trial plan without being eligible. */
export type FreeTrialNotEligible = UserError & {
  readonly __typename?: 'FreeTrialNotEligible';
  readonly errorMessage: Scalars['String']['output'];
};

/** input argument for geo distance based location search querys */
export type GeoCoordinates = {
  readonly latitude: Scalars['Float']['input'];
  readonly longitude: Scalars['Float']['input'];
};

/** Successful kiosk reader location fetch */
export type GetKioskReaderLocationIdResponse = {
  readonly __typename?: 'GetKioskReaderLocationIdResponse';
  readonly id: Scalars['String']['output'];
};

/** "Gift card associated with another account" user error */
export type GiftCardAssociatedWithAnotherAccountError = UserError & {
  readonly __typename?: 'GiftCardAssociatedWithAnotherAccountError';
  readonly errorMessage: Scalars['String']['output'];
};

/** The customer's gift card balance result */
export type GiftCardBalance = {
  readonly __typename?: 'GiftCardBalance';
  /** Customer's ID. NOTE: It can be useful for client-side caching. */
  readonly customerId: Scalars['String']['output'];
  /** Customer's gift card balance in cents */
  readonly giftCardBalance: Scalars['Float']['output'];
};

export type GiftCardBalanceResponseUnion =
  | GiftCardBalance
  | UnableToGetGiftCardBalanceError;

/** The gift card order's delivery details */
export type GiftCardDeliveryDetail = {
  readonly __typename?: 'GiftCardDeliveryDetail';
  readonly deliveryDateTime: Maybe<Scalars['DateTime']['output']>;
  readonly deliveryMethods: ReadonlyArray<Scalars['String']['output']>;
  readonly isAsap: Scalars['Boolean']['output'];
};

/** Gift card was not found */
export type GiftCardNotFoundError = UserError & {
  readonly __typename?: 'GiftCardNotFoundError';
  readonly errorMessage: Scalars['String']['output'];
};

/** Successful gift card order details */
export type GiftCardOrderDetail = {
  readonly __typename?: 'GiftCardOrderDetail';
  /** Delivery options for the gift card order. */
  readonly deliveryDetail: GiftCardDeliveryDetail;
  /** Gift card order ID. */
  readonly id: Scalars['String']['output'];
  /** Gift card order purchase time. */
  readonly purchasedAt: Scalars['DateTime']['output'];
  /** Purchased gift cards. */
  readonly userGiftCards: ReadonlyArray<UserGiftCard>;
};

export type GiftCardOrderDetailResponseUnion =
  | GiftCardOrderDetail
  | GiftCardOrderNotFoundError;

/** Gift card order was not found */
export type GiftCardOrderNotFoundError = UserError & {
  readonly __typename?: 'GiftCardOrderNotFoundError';
  readonly errorMessage: Scalars['String']['output'];
};

/** Successful gift card purchase history */
export type GiftCardPurchaseHistory = {
  readonly __typename?: 'GiftCardPurchaseHistory';
  /** Customer's ID. It can be useful for client-side caching. */
  readonly customerId: Scalars['String']['output'];
  /** A list of gift card orders for this user. */
  readonly giftCardOrders: ReadonlyArray<GiftCardOrderDetail>;
};

export type GiftCardRedemptionDetailResponseUnion =
  | GiftCardNotFoundError
  | GiftCardRedemptionDetailSuccess;

/** Successful gift card redemption details */
export type GiftCardRedemptionDetailSuccess = {
  readonly __typename?: 'GiftCardRedemptionDetailSuccess';
  /** Gift card asset id. */
  readonly assetId: Maybe<Scalars['String']['output']>;
  /** Gift card balance. */
  readonly balance: Scalars['Float']['output'];
  /** Gift card number. */
  readonly cardNumber: Scalars['String']['output'];
  /** Gift card reg code. */
  readonly cardRegCode: Scalars['String']['output'];
  /** Message for gift card recipient. */
  readonly recipientMessage: Maybe<Scalars['String']['output']>;
  /** Gift card recipient's name. */
  readonly recipientName: Scalars['String']['output'];
  /** Gift card redemption id. */
  readonly redeemId: Scalars['String']['output'];
  /** Gift card sender's name. */
  readonly senderName: Scalars['String']['output'];
};

export enum GravyStatusType {
  ArrivedAtDropoff = 'ARRIVED_AT_DROPOFF',
  DeliveryCompleted = 'DELIVERY_COMPLETED',
  DeliveryFailed = 'DELIVERY_FAILED',
  KitchenCompleted = 'KITCHEN_COMPLETED',
  OrderCreated = 'ORDER_CREATED',
  OrderStatusFailed = 'ORDER_STATUS_FAILED',
  OrderStatusPreorderFailed = 'ORDER_STATUS_PREORDER_FAILED',
  OrderSubmitted = 'ORDER_SUBMITTED',
  SubmitFailed = 'SUBMIT_FAILED',
  Unknown = 'UNKNOWN',
}

export type Guest = {
  readonly __typename?: 'Guest';
  readonly email: Maybe<Scalars['String']['output']>;
  readonly firstName: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly lastName: Maybe<Scalars['String']['output']>;
};

/** Used to determine if the user has unseen challenges, meaning they haven't yet queried for the challenge. */
export type HasUnseenChallenges = {
  readonly __typename?: 'HasUnseenChallenges';
  readonly hasUnseenChallenges: Scalars['Boolean']['output'];
};

export type InStoreOrder = {
  readonly __typename?: 'InStoreOrder';
  readonly id: Scalars['ID']['output'];
  readonly wantedTime: Scalars['String']['output'];
};

export type IncorrectPaymentMethod = UserError & {
  readonly __typename?: 'IncorrectPaymentMethod';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type Ingredient = {
  readonly __typename?: 'Ingredient';
  readonly asset: Asset;
  readonly dietaryProperties: ReadonlyArray<DietaryProperty>;
  readonly id: Scalars['ID']['output'];
  readonly kind: Maybe<IngredientKind>;
  readonly name: Scalars['String']['output'];
};

export enum IngredientKind {
  Base = 'BASE',
  Dressing = 'DRESSING',
  Premium = 'PREMIUM',
  Topping = 'TOPPING',
}

export type IngredientModification = {
  readonly __typename?: 'IngredientModification';
  readonly additionCost: Scalars['Float']['output'];
  readonly calories: Scalars['Float']['output'];
  readonly ingredient: Ingredient;
  /** Asserts whether the ingredient modification is a "grain" type (like "rice," "quinoa," etc.) */
  readonly isGrain: Scalars['Boolean']['output'];
  readonly kind: Maybe<Scalars['String']['output']>;
  /** Is the modification able to be mixed-in to the salad, primarily used for dressings */
  readonly mixable: Scalars['Boolean']['output'];
  readonly outOfStock: Scalars['Boolean']['output'];
  readonly removalCost: Scalars['Float']['output'];
  readonly subKind: Maybe<Scalars['String']['output']>;
  readonly substitutionCost: Scalars['Float']['output'];
};

export type IngredientModificationInput = {
  readonly ingredientId: Scalars['ID']['input'];
};

export type IngredientModificationKind = {
  readonly __typename?: 'IngredientModificationKind';
  /** The kind allows multiple quantity selections for a modification. */
  readonly allowMultipleQuantity: Scalars['Boolean']['output'];
  /** The number of free ingredient modifications that can be added of this kind. */
  readonly freeAggregateQuantity: Scalars['Int']['output'];
  /** The maximum number of ingredient modifications that can be of this kind. */
  readonly maxAggregateQuantity: Scalars['Int']['output'];
  /** The minimum number of ingredient modifications that can be of this kind. */
  readonly minAggregateQuantity: Scalars['Int']['output'];
  readonly modifications: ReadonlyArray<IngredientModification>;
};

export type IngredientModifications = {
  readonly __typename?: 'IngredientModifications';
  readonly bases: IngredientModificationKind;
  readonly bread: IngredientModificationKind;
  readonly dressings: IngredientModificationKind;
  readonly premiums: IngredientModificationKind;
  readonly toppings: IngredientModificationKind;
};

export type IngredientSubstitutionModificationInput = {
  readonly addedIngredientId: Scalars['ID']['input'];
  readonly removedIngredientId: Scalars['ID']['input'];
};

export type InvalidAddress = {
  readonly __typename?: 'InvalidAddress';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type InvalidBillingAccount = UserError & {
  readonly __typename?: 'InvalidBillingAccount';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type InvalidCustomerPhone = UserError & {
  readonly __typename?: 'InvalidCustomerPhone';
  readonly errorMessage: Scalars['String']['output'];
};

/** "Invalid gift card" user error */
export type InvalidGiftCardError = UserError & {
  readonly __typename?: 'InvalidGiftCardError';
  readonly errorMessage: Scalars['String']['output'];
};

export type InvalidInput = {
  readonly __typename?: 'InvalidInput';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type InvalidOrder = {
  readonly __typename?: 'InvalidOrder';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

/** An error that occurs as a result of submitting an invalid payment method. */
export type InvalidPaymentMethod = UserError & {
  readonly __typename?: 'InvalidPaymentMethod';
  readonly errorMessage: Scalars['String']['output'];
};

export type InvalidPromoCode = {
  readonly __typename?: 'InvalidPromoCode';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type InvalidTip = UserError & {
  readonly __typename?: 'InvalidTip';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type IssueCustomerSupportCreditResult = {
  readonly __typename?: 'IssueCustomerSupportCreditResult';
  readonly success: Scalars['Boolean']['output'];
};

export type IssueCustomerSupportPointsFailureError = UserError & {
  readonly __typename?: 'IssueCustomerSupportPointsFailureError';
  readonly errorMessage: Scalars['String']['output'];
};

/** Response for issuing customer support points */
export type IssueCustomerSupportPointsResponse =
  | IssueCustomerSupportPointsFailureError
  | IssueCustomerSupportPointsSuccessResponse;

/** Success response for issuing customer support points */
export type IssueCustomerSupportPointsSuccessResponse = {
  readonly __typename?: 'IssueCustomerSupportPointsSuccessResponse';
  readonly credits: Scalars['Int']['output'];
};

/** JWT token used to login a user in Kustomer */
export type Kustomer = {
  readonly __typename?: 'Kustomer';
  readonly token: Scalars['String']['output'];
};

export type Ledger = {
  readonly __typename?: 'Ledger';
  /** Items that reduce the post-tax cost (i.e. total) of the order. */
  readonly credits: ReadonlyArray<LedgerItem>;
  /** Sum of all credits */
  readonly creditsTotal: Scalars['Int']['output'];
  /** Items that reduce the pre-tax cost of the order, usually the result of applying offers. */
  readonly discounts: ReadonlyArray<LedgerItem>;
  /** Sum of all discounts */
  readonly discountsTotal: Scalars['Int']['output'];
  readonly fees: ReadonlyArray<LedgerItem>;
  /** Sum of all fees */
  readonly feesTotal: Scalars['Int']['output'];
  /** Sum of the cost of all line items. */
  readonly subtotal: Scalars['Int']['output'];
  readonly tax: Scalars['Int']['output'];
  /** Tip applied to order in cents */
  readonly tip: Scalars['Int']['output'];
};

export type LedgerItem = {
  readonly __typename?: 'LedgerItem';
  readonly amount: Scalars['Int']['output'];
  /** May be shown as a tooltip or flyout in clients */
  readonly description: Maybe<Scalars['String']['output']>;
  readonly name: Scalars['String']['output'];
};

export type LineItem = {
  readonly __typename?: 'LineItem';
  /** The ingredients that have been added on to the line item when compared to the product's default ingredients. */
  readonly addedIngredients: ReadonlyArray<Ingredient>;
  /** The total number of calories in the line item */
  readonly calories: Scalars['Int']['output'];
  /** The per item cost multiplied by the quantity, in cents. */
  readonly cost: Scalars['Int']['output'];
  /** The custom name for the line item that has been specified by the user. */
  readonly customName: Maybe<Scalars['String']['output']>;
  /** Name of the customer who ordered this lineItem */
  readonly customerName: Scalars['String']['output'];
  /** Dressing status, if the product is dressable. Otherwise NOT_DRESSABLE. */
  readonly dressingMode: DressingMode;
  /** Whether the line item is marked as favorite. */
  readonly favorited: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  /** The ingredients that make up the line item. These ingredients include any added or removed ingredients compared to the product's default ingredients. */
  readonly ingredients: ReadonlyArray<Ingredient>;
  /** Whether the line item has been customized. */
  readonly isCustom: Scalars['Boolean']['output'];
  /** Information outlining which dressings are mixed into the line item and their associated weights. */
  readonly mixedDressingDetails: ReadonlyArray<MixedDressingDetails>;
  /** Line item modifiers. */
  readonly modifiers: ReadonlyArray<Modifier>;
  /** The per item cost of the product as configured, in cents. */
  readonly perItemCost: Scalars['Int']['output'];
  /** The product associated with a line item. */
  readonly product: Product;
  /** The number of products in the line item. */
  readonly quantity: Scalars['Int']['output'];
  /** The ingredients that have been removed from the line item when compared to the product's default ingredients. */
  readonly removedIngredients: ReadonlyArray<Ingredient>;
  readonly slug: Scalars['ID']['output'];
};

export type LineItemBySlugInput = {
  readonly slug: Scalars['ID']['input'];
};

export type LineItemInput = {
  readonly id: Scalars['ID']['input'];
};

export type LineItemModificationsInvalidException = UserError & {
  readonly __typename?: 'LineItemModificationsInvalidException';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type LineItemNotFound = {
  readonly __typename?: 'LineItemNotFound';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type LineItemRecommendation = {
  readonly __typename?: 'LineItemRecommendation';
  /** The date and time the recommendation was created. */
  readonly createdAt: Scalars['String']['output'];
  /** The description of the recommendation - commonly the list of ingredients. */
  readonly description: Scalars['String']['output'];
  /** The identifier for the complete item provided by this recommendation. */
  readonly id: Scalars['String']['output'];
  /** The ingredients that make up this recommendation. */
  readonly ingredientIds: ReadonlyArray<Scalars['String']['output']>;
  readonly ingredients: ReadonlyArray<Ingredient>;
  /** The name of the recommendation. */
  readonly name: Scalars['String']['output'];
  /** Whether the recommendation is based on a previous ordered item or is a new item */
  readonly recommendationType: RecommendationType;
};

export type LineItemResponseUnion =
  | LineItemNotFound
  | LineItemSuccess
  | ValidationError;

export type LineItemSuccess = {
  readonly __typename?: 'LineItemSuccess';
  readonly lineItem: LineItem;
};

export type Location = {
  readonly __typename?: 'Location';
  readonly estimateRange: EstimateRange;
  readonly restaurant: Restaurant;
  readonly status: LocationStatus;
  readonly vendor: Vendor;
};

export type LocationByAddressInput = {
  readonly address1?: InputMaybe<Scalars['String']['input']>;
  readonly address2?: InputMaybe<Scalars['String']['input']>;
  /** id for an address present in the current session. */
  readonly addressId?: InputMaybe<Scalars['ID']['input']>;
  readonly city?: InputMaybe<Scalars['String']['input']>;
  /** google places id used for delivery locations search */
  readonly googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  readonly lat?: InputMaybe<Scalars['Float']['input']>;
  readonly lon?: InputMaybe<Scalars['Float']['input']>;
  readonly state?: InputMaybe<Scalars['String']['input']>;
  readonly zipcode?: InputMaybe<Scalars['Int']['input']>;
};

export type LocationByAddressResponseUnion =
  | InvalidInput
  | Location
  | NoValidRestaurants
  | ValidationError;

/** Stripe Location Metadata */
export type LocationMetadata = {
  readonly __typename?: 'LocationMetadata';
  readonly restaurant_slug: Scalars['String']['output'];
};

/**
 * Combines search result meta for match score and with location data
 * Note:
 * - when allLocations are queried the score is 1.0 for every result
 * - for geo-distance queries the score is the distance from the base coodinates
 */
export type LocationSearchResult = {
  readonly __typename?: 'LocationSearchResult';
  readonly location: StoreLocation;
  readonly score: Scalars['Float']['output'];
};

export enum LocationStatus {
  Closed = 'CLOSED',
  Invalid = 'INVALID',
  Open = 'OPEN',
}

export type LogoutInput = {
  readonly customerId: Scalars['ID']['input'];
};

export type LogoutResponseUnion = LogoutSuccess | ValidationError;

export type LogoutSuccess = {
  readonly __typename?: 'LogoutSuccess';
  readonly csrf: Scalars['String']['output'];
};

/** Error thrown when a user fails to opt into a challenge */
export type LoyaltyOptInFailed = UserError & {
  readonly __typename?: 'LoyaltyOptInFailed';
  readonly errorMessage: Scalars['String']['output'];
};

/** Customer Loyalty Profile */
export type LoyaltyProfile = {
  readonly __typename?: 'LoyaltyProfile';
  /** External user id */
  readonly customerId: Scalars['ID']['output'];
  /** Customer has subscribed before */
  readonly hasPaidTierHistory: Scalars['Boolean']['output'];
  /** Date the loyalty profile was created */
  readonly joinDate: Scalars['DateTime']['output'];
  /** Loyalty ID (SessionM Profile ID) of the current customer */
  readonly loyaltyId: Maybe<Scalars['String']['output']>;
  readonly points: Maybe<UserPointsStatus>;
  readonly pointsRewards: Maybe<ReadonlyArray<PointsReward>>;
  readonly rewards: Maybe<ReadonlyArray<Reward>>;
  readonly rewardsPremiumStatus: Maybe<RewardsPremiumStatus>;
  /** Customer Tier */
  readonly tier: Maybe<Tier>;
  /** Customer Tier */
  readonly tierStatus: Maybe<TierStatus>;
  /** User trial eligibility. If empty then the user is not eligible */
  readonly trialEligible: Maybe<ReadonlyArray<TrialEligibility>>;
};

export type MaxModificationsExceeded = {
  readonly __typename?: 'MaxModificationsExceeded';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type Menu = {
  readonly __typename?: 'Menu';
  readonly categories: ReadonlyArray<MenuCategory>;
  readonly id: Scalars['String']['output'];
};

export type MenuCategory = {
  readonly __typename?: 'MenuCategory';
  readonly description: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly isCustom: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly products: ReadonlyArray<Product>;
};

export type MenuCollection = {
  readonly __typename?: 'MenuCollection';
  readonly color: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly primaryDescription: Scalars['String']['output'];
  readonly primaryImageUrl: Scalars['String']['output'];
  readonly primaryTitle: Scalars['String']['output'];
  readonly products: ReadonlyArray<Product>;
  readonly secondaryDescription: Maybe<Scalars['String']['output']>;
  readonly secondaryTitle: Maybe<Scalars['String']['output']>;
};

export type MinimumDeliverySubtotalNotMet = UserError & {
  readonly __typename?: 'MinimumDeliverySubtotalNotMet';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type MixedDressingDetails = {
  readonly __typename?: 'MixedDressingDetails';
  readonly ingredientId: Scalars['ID']['output'];
  readonly weight: DressingWeight;
};

export type MixedDressingDetailsInput = {
  readonly ingredientId: Scalars['ID']['input'];
  readonly weight: DressingWeight;
};

export type Modifier = {
  readonly __typename?: 'Modifier';
  readonly brinkPlu: Scalars['Float']['output'];
  readonly id: Scalars['Float']['output'];
  readonly perItemCost: Scalars['Float']['output'];
  readonly quantity: Scalars['Float']['output'];
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  /** Adds an address to the customer's list of addresses. */
  readonly addAddress: AddAddressResponseUnion;
  readonly addLineItemBatchToCart: AddLineItemToCartResponseUnion;
  readonly addLineItemToCart: AddLineItemToCartResponseUnion;
  /** Add a payment method to a subscription */
  readonly addSubscriptionPaymentMethod: AddSubscriptionPaymentMethodResponseUnion;
  readonly applyReward: ApplyRewardResponseUnion;
  /**
   * Attempts to cancel and refund an order.
   * @deprecated Use cancelOrderV2 instead.
   */
  readonly cancelOrder: CancelOrderResponseUnion;
  /** Attempts to cancel and refund an order. */
  readonly cancelOrderV2: CancelOrderResponseUnion;
  /** Cancel a subscription */
  readonly cancelSubscription: CancelSubscriptionResponseUnion;
  /** Cancel a subscription at cycle end */
  readonly cancelSubscriptionAtCycleEnd: CancelSubscriptionAtCycleEndResponseUnion;
  /** Vendor endpoint to cancel subscription, requires bearer auth token */
  readonly cancelSubscriptionForCustomer: CancelSubscriptionForCustomerResponseUnion;
  /**
   * Takes a challenge ID (a.k.a. SessionM campaign ID) as an argument and opts customer into the identified challenge.  Returns the challenge details upon success. If an error occurs, the error field will be populated in the response: errors[0].message = modal title, errors[0].extension.detailedMessage = modal description/friendly message detailing what went wrong
   * @deprecated Use challengeOptInV2
   */
  readonly challengeOptIn: Challenge;
  /** Takes a challenge ID (a.k.a. SessionM campaign ID) as an argument and opts customer into the identified challenge.  Returns the challenge details upon success. If an error occurs, LoyaltyOptInFailed will be returned with the error details in the error field. */
  readonly challengeOptInV2: ChallengeOptInResultUnion;
  /** Creates a new account for the customer. */
  readonly createAccount: CreateAccountResponseUnion;
  /** Creates a new connection token to pass to the Stripe Terminal SDK */
  readonly createConnectionToken: CreateConnectionTokenResponse;
  /** Creates a new location in Stripe */
  readonly createKioskReaderLocation: CreateKioskReaderLocationIdResponse;
  readonly createPaymentIntent: Scalars['String']['output'];
  readonly createPaymentSubscriptionProfile: PaymentSubscriptionProfile;
  readonly createSetupPaymentIntent: CreateSetupIntent;
  /**
   * Create a subscription
   * @deprecated Sunsetting sweetpass+ loyalty program
   */
  readonly createSubscription: CreateSubscriptionResult;
  /**
   * Create a subscription
   * @deprecated Sunsetting sweetpass+ loyalty program
   */
  readonly createSubscriptionV2: CreateSubscriptionResponseUnion;
  /** Removes an address from the customer's list of addresses. */
  readonly deleteAddress: DeleteAddressResponseUnion;
  readonly deletePaymentMethodCard: PaymentMethodCard;
  readonly editLineItemInCart: EditLineItemInCartResponseUnion;
  /** Favorites a Line Item. */
  readonly favoriteLineItem: LineItem;
  /** Issue credit (aka sessionm offers) as sweet touches within the Kustomer platform. */
  readonly issueCustomerSupportCredit: IssueCustomerSupportCreditResult;
  /** Issues a points to a user. */
  readonly issueCustomerSupportPoints: IssueCustomerSupportPointsResponse;
  /** Logs the user out. */
  readonly logout: LogoutResponseUnion;
  /**
   * Opts into the free tier.
   * @deprecated Use optInFreeTierV2
   */
  readonly optInFreeTier: Scalars['Boolean']['output'];
  /** Opts into the free tier. */
  readonly optInFreeTierV2: Maybe<LoyaltyProfile>;
  /** Opts out of loyalty program. */
  readonly optOutLoyalty: OptOutLoyaltyResponseUnion;
  /** Opts a user out of loyalty. Requires a bearer auth token */
  readonly optOutLoyaltyForCustomer: OptOutLoyaltyForCustomerResponseUnion;
  /** Redemption of a gift card */
  readonly redeemGiftCard: RedeemGiftCardResponseUnion;
  /** Refund a transaction for a customer */
  readonly refundLoyaltyTransactionForCustomer: RefundLoyaltyTransactionResponseUnion;
  /** Takes an order refund request input and attempts to refund said order returning success or failure. */
  readonly refundOrder: RefundOrderSuccess;
  readonly registerGuest: Maybe<RegisterGuestResponseUnion>;
  /**
   * Rejoin a previously canceled subscription
   * @deprecated Sunsetting sweetpass+ loyalty program
   */
  readonly rejoinSubscription: RejoinSubscriptionResponseUnion;
  readonly removeFromCart: RemoveFromCartResponseUnion;
  readonly removeReward: RemoveRewardResponseUnion;
  /** Remove a payment method from a subscription */
  readonly removeSubscriptionPaymentMethod: DeleteSubscriptionPaymentMethodResponseUnion;
  /** @deprecated Deprecated in favor of the "reorderOrder" mutation. */
  readonly reorder: ReorderResponseUnion;
  readonly reorderLineItem: ReorderResponseUnion;
  readonly reorderOrder: ReorderResponseUnion;
  readonly requestAccountDeletion: Maybe<RequestAccountDeletionResponseUnion>;
  readonly sendLoyaltyTransaction: Scalars['Boolean']['output'];
  readonly setDefaultPaymentMethod: PaymentProfile;
  /** Signs the user in. */
  readonly signIn: SignInResponseUnion;
  readonly submitFeedback: FeedbackSuccess;
  /** Order a gift card */
  readonly submitGiftCardOrderUsingPaymentMethod: SubmitGiftCardOrderUsingPaymentMethodResponseUnion;
  readonly submitInStoreFeedback: FeedbackSuccess;
  /** Submits an order paid for via a separate workflow i.e. marketplace. */
  readonly submitOrderAsPayInStore: SubmitOrderWithPaymentMethodResponseUnion;
  /** Submits an order paid for via a separate workflow i.e. marketplace without a wanted time */
  readonly submitOrderAsPayInStoreASAP: SubmitOrderWithPaymentMethodResponseUnion;
  /** Submits an order paid for via a separate workflow i.e. marketplace. */
  readonly submitOrderAsPrepaid: SubmitOrderWithPaymentMethodResponseUnion;
  /** Submits an order paid for via a separate workflow i.e. marketplace without a wanted time */
  readonly submitOrderAsPrepaidASAP: SubmitOrderWithPaymentMethodResponseUnion;
  /** Submits an order, paid for using one of the customer's existing payment methods. */
  readonly submitOrderUsingPaymentMethod: SubmitOrderUsingPaymentMethodResponseUnion;
  /**
   * Submits an order, paid for using one of the customer's existing billing accounts.
   * @deprecated Use the submitOrderUsingPaymentMethod mutation instead
   */
  readonly submitOrderUsingPaymentMethodCard: SubmitOrderUsingBillingAccountResponseUnion;
  /** Submits an order paid for via a separate workflow i.e. marketplace. */
  readonly submitOrderWithPaymentMethod: SubmitOrderWithPaymentMethodResponseUnion;
  /** Submits a gift card code. */
  readonly submitPromoCode: SubmitPromoCodeResponseUnion;
  /** Submits code to SessionM. If unsuccessful, code is submitted to LevelUp. */
  readonly submitPromoOrGiftCardCode: SubmitPromoCodeOrGiftCardResponseUnion;
  readonly unlockLoyaltyTransaction: Scalars['Boolean']['output'];
  /** Updates an address form the customer's list of addresses. */
  readonly updateAddress: UpdateAddressResponseUnion;
  readonly updateCustomer: Maybe<UpdateCustomerResponse>;
  readonly updateKioskCustomer: Maybe<UpdateKioskCustomerResponse>;
  readonly updatePaymentMethod: PaymentMethodCard;
  /** Update the payment method for a subscription */
  readonly updateSubscriptionPaymentMethod: UpdateSubscriptionPaymentMethodSuccessResponse;
  /** Sets whether to use credit on next purchase. */
  readonly updateUseCredit: UpdateUseCreditResponse;
  readonly validateAndPreviewRewards: RewardsValidateAndPreviewUnion;
  /** Returns whether a redemption token is valid and generates a new token */
  readonly validateAndRefreshGiftCardRedemptionTokenForCustomer: ValidateAndRefreshGiftCardRedemptionTokenForCustomerResponse;
  readonly validateAndReserveRewards: RewardsValidateAndReserveUnion;
};

export type MutationAddAddressArgs = {
  input: AddAddressInput;
};

export type MutationAddLineItemBatchToCartArgs = {
  input: AddLineItemBatchToCartInput;
};

export type MutationAddLineItemToCartArgs = {
  input: AddLineItemToCartInput;
};

export type MutationAddSubscriptionPaymentMethodArgs = {
  paymentNonce: Scalars['String']['input'];
};

export type MutationApplyRewardArgs = {
  input: ApplyRewardInput;
};

export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};

export type MutationCancelOrderV2Args = {
  input: CancelOrderInput;
};

export type MutationCancelSubscriptionArgs = {
  subscriptionId: Scalars['String']['input'];
};

export type MutationCancelSubscriptionAtCycleEndArgs = {
  subscriptionId: Scalars['String']['input'];
};

export type MutationCancelSubscriptionForCustomerArgs = {
  uuid: Scalars['String']['input'];
};

export type MutationChallengeOptInArgs = {
  challengeId: Scalars['String']['input'];
};

export type MutationChallengeOptInV2Args = {
  challengeId: Scalars['String']['input'];
};

export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};

export type MutationCreateKioskReaderLocationArgs = {
  restaurantInfo: RestaurantLocationInfoInputDto;
};

export type MutationCreateSubscriptionArgs = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  paymentId?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateSubscriptionV2Args = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  paymentId?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDeleteAddressArgs = {
  addressId: Scalars['ID']['input'];
};

export type MutationDeletePaymentMethodCardArgs = {
  input: DeletePaymentMethodCardInput;
};

export type MutationEditLineItemInCartArgs = {
  input: EditLineItemInCartInput;
};

export type MutationFavoriteLineItemArgs = {
  input: FavoriteLineItemInput;
};

export type MutationIssueCustomerSupportCreditArgs = {
  creditId: Scalars['String']['input'];
  legacyInternalId: Scalars['String']['input'];
};

export type MutationIssueCustomerSupportPointsArgs = {
  legacyInternalId: Scalars['ID']['input'];
  numberOfPoints: Scalars['Int']['input'];
};

export type MutationLogoutArgs = {
  input: LogoutInput;
};

export type MutationOptOutLoyaltyForCustomerArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  legacyInternalId?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRedeemGiftCardArgs = {
  code: Scalars['String']['input'];
  regCode?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRefundLoyaltyTransactionForCustomerArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  customerId: Scalars['String']['input'];
  transactionId: Scalars['String']['input'];
};

export type MutationRefundOrderArgs = {
  input: RefundOrderInput;
};

export type MutationRegisterGuestArgs = {
  input: RegisterGuestInput;
};

export type MutationRejoinSubscriptionArgs = {
  subscriptionId: Scalars['String']['input'];
};

export type MutationRemoveFromCartArgs = {
  input: RemoveFromCartInput;
};

export type MutationRemoveRewardArgs = {
  input: RemoveRewardInput;
};

export type MutationRemoveSubscriptionPaymentMethodArgs = {
  paymentMethodId: Scalars['String']['input'];
};

export type MutationReorderArgs = {
  input: ReorderInput;
};

export type MutationReorderLineItemArgs = {
  input: ReorderInput;
};

export type MutationReorderOrderArgs = {
  input: ReorderInput;
};

export type MutationSendLoyaltyTransactionArgs = {
  input: ClosedLoyaltyTransactionDetailsInput;
};

export type MutationSetDefaultPaymentMethodArgs = {
  paymentMethodId: Scalars['String']['input'];
};

export type MutationSubmitFeedbackArgs = {
  input: FeedbackInput;
};

export type MutationSubmitGiftCardOrderUsingPaymentMethodArgs = {
  input: SubmitGiftCardOrderUsingPaymentMethodInput;
};

export type MutationSubmitInStoreFeedbackArgs = {
  input: FeedbackInput;
};

export type MutationSubmitOrderAsPayInStoreArgs = {
  input: SubmitOrderInput;
};

export type MutationSubmitOrderAsPayInStoreAsapArgs = {
  input: SubmitOrderAsapInput;
};

export type MutationSubmitOrderAsPrepaidArgs = {
  input: SubmitOrderInput;
};

export type MutationSubmitOrderAsPrepaidAsapArgs = {
  input: SubmitOrderAsapInput;
};

export type MutationSubmitOrderUsingPaymentMethodArgs = {
  input: SubmitOrderUsingPaymentMethodInput;
};

export type MutationSubmitOrderUsingPaymentMethodCardArgs = {
  input: SubmitOrderUsingPaymentMethodCardInput;
};

export type MutationSubmitOrderWithPaymentMethodArgs = {
  input: SubmitOrderWithPaymentMethodInput;
};

export type MutationSubmitPromoCodeArgs = {
  input: SubmitPromoCodeInput;
};

export type MutationSubmitPromoOrGiftCardCodeArgs = {
  input: SubmitPromoOrGiftCardCodeInput;
};

export type MutationUnlockLoyaltyTransactionArgs = {
  input: ClosedLoyaltyTransactionDetailsInput;
};

export type MutationUpdateAddressArgs = {
  input: EditAddressInput;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateKioskCustomerArgs = {
  input: UpdateKioskCustomerInput;
};

export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};

export type MutationUpdateSubscriptionPaymentMethodArgs = {
  paymentMethodId: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type MutationUpdateUseCreditArgs = {
  useCredit: Scalars['Boolean']['input'];
};

export type MutationValidateAndPreviewRewardsArgs = {
  input: RewardsValidationInput;
};

export type MutationValidateAndRefreshGiftCardRedemptionTokenForCustomerArgs = {
  input: ValidateAndRefreshGiftCardRedemptionTokenForCustomerInput;
};

export type MutationValidateAndReserveRewardsArgs = {
  input: RewardsValidationInput;
};

/** "No Balance Gift card" user error */
export type NoBalanceGiftCardError = UserError & {
  readonly __typename?: 'NoBalanceGiftCardError';
  readonly errorMessage: Scalars['String']['output'];
};

export type NoValidRestaurants = {
  readonly __typename?: 'NoValidRestaurants';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type OpenLoyaltyTransactionDetailsInput = {
  readonly createdAt: Scalars['String']['input'];
  readonly id: Scalars['String']['input'];
  readonly isInFlight: Scalars['Boolean']['input'];
  readonly isInFlightDelivery: Scalars['Boolean']['input'];
  readonly lineItems: ReadonlyArray<OrderLineItemInput>;
  readonly orderType: Scalars['String']['input'];
  readonly restaurantEntityId?: InputMaybe<Scalars['Float']['input']>;
  readonly restaurantParentId?: InputMaybe<Scalars['Float']['input']>;
  readonly restaurantUtcOffset?: InputMaybe<Scalars['Float']['input']>;
  readonly selectedRewards?: InputMaybe<
    ReadonlyArray<Scalars['String']['input']>
  >;
  readonly subtotal: Scalars['Float']['input'];
  readonly tax: Scalars['Float']['input'];
  readonly updatedAt?: InputMaybe<Scalars['String']['input']>;
  readonly wantedTime?: InputMaybe<Scalars['String']['input']>;
};

export type OptOutLoyaltyForCustomerResponseUnion =
  | OptOutLoyaltyForCustomerSuccessResponse
  | UnknownUserError;

/** Opt Out Loyalty For Customer Result */
export type OptOutLoyaltyForCustomerSuccessResponse = {
  readonly __typename?: 'OptOutLoyaltyForCustomerSuccessResponse';
  /** Indicates if the user has successfully been opted out */
  readonly success: Scalars['Boolean']['output'];
};

export type OptOutLoyaltyResponseUnion =
  | OptOutLoyaltySuccessResponse
  | UnknownUserError;

/** Opt Out Loyalty Result */
export type OptOutLoyaltySuccessResponse = {
  readonly __typename?: 'OptOutLoyaltySuccessResponse';
  /** Indicates if the user has successfully been opted out */
  readonly success: Scalars['Boolean']['output'];
};

/** Order */
export type Order = {
  readonly __typename?: 'Order';
  readonly asset: Asset;
  /** All non points rewards that are issued to this customer, regardless of eligibility to this order. */
  readonly availableRewards: Maybe<ReadonlyArray<Reward>>;
  readonly availableWantedTimes: ReadonlyArray<AvailableWantedTime>;
  /** Checks if this order is in the received state, but only if the wanted time is recent (12hrs). */
  readonly canCancel: Scalars['Boolean']['output'];
  /** Whether or not this order belongs to a restaurant with support for tracking the orders with a QSR system. This is always true for delivery orders, for pickup or outpost, it checks the tracking status of the restaurant. This flag affects what we should the customer in the order status feature. */
  readonly canTrackOrderStatus: Scalars['Boolean']['output'];
  readonly createdAt: Scalars['DateTime']['output'];
  readonly deliveryOrderDetail: Maybe<DeliveryOrderDetail>;
  readonly description: Scalars['String']['output'];
  readonly dropoffLocation: Maybe<Scalars['String']['output']>;
  /** All points rewards that are issued to this customer and are eligible to this order. */
  readonly eligiblePointRewards: Maybe<ReadonlyArray<PointsReward>>;
  readonly id: Scalars['ID']['output'];
  readonly includeUtensils: Scalars['Boolean']['output'];
  /** Indicates whether the order was canceled. */
  readonly isCanceled: Scalars['Boolean']['output'];
  /** Whether or not this order is an inflight order, this is done by checking the session.inFlightOrderId on a field resolver. */
  readonly isInFlight: Scalars['Boolean']['output'];
  /** Whether or not this order is an inflight delivery order, this is done by checking the session.inFlightDeliveryOrderId on a field resolver. */
  readonly isInFlightDelivery: Scalars['Boolean']['output'];
  /** Whether or not this order is pending the feedback from "rate your order". */
  readonly isPendingFeedback: Scalars['Boolean']['output'];
  readonly ledger: Ledger;
  readonly lineItems: ReadonlyArray<LineItem>;
  readonly orderType: OrderType;
  readonly payments: Maybe<ReadonlyArray<Payment>>;
  readonly restaurant: Restaurant;
  readonly selectedRewards: ReadonlyArray<Scalars['String']['output']>;
  readonly subtotal: Scalars['Int']['output'];
  readonly tax: Scalars['Float']['output'];
  readonly total: Maybe<Scalars['Int']['output']>;
  readonly updatedAt: Maybe<Scalars['DateTime']['output']>;
  /** Used by customers to reference the order when speaking to our support team. */
  readonly vendorId: Scalars['String']['output'];
  readonly wantedTime: Scalars['DateTime']['output'];
};

/** Order has already been canceled */
export type OrderAlreadyCanceled = UserError & {
  readonly __typename?: 'OrderAlreadyCanceled';
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

/** Order sent to kitchen */
export type OrderAlreadySentToKitchen = UserError & {
  readonly __typename?: 'OrderAlreadySentToKitchen';
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

export type OrderConflict = {
  readonly __typename?: 'OrderConflict';
  readonly product: Product;
  /** Reorder conflict type */
  readonly type: ConflictType;
  readonly unavailableIngredients: ReadonlyArray<Ingredient>;
};

export type OrderLineItemInput = {
  readonly brinkPlu: Scalars['Float']['input'];
  readonly cost: Scalars['Float']['input'];
  readonly perItemCost: Scalars['Float']['input'];
  readonly quantity: Scalars['Float']['input'];
};

/** Order not found */
export type OrderNotFound = UserError & {
  readonly __typename?: 'OrderNotFound';
  readonly errorMessage: Scalars['String']['output'];
  readonly orderId: Scalars['String']['output'];
  /** Boolean representing if the order successfully canceled or not. */
  readonly success: Scalars['Boolean']['output'];
};

export type OrderPaymentInput = {
  readonly amountEligibleForPointsInCents?: InputMaybe<
    Scalars['Float']['input']
  >;
  readonly amountInCents: Scalars['Float']['input'];
  readonly id: Scalars['String']['input'];
  readonly paymentTime: Scalars['String']['input'];
  readonly processor: Scalars['String']['input'];
};

export type OrderStatus = {
  readonly __typename?: 'OrderStatus';
  readonly canceledStatus: Maybe<Scalars['String']['output']>;
  readonly courierDetails: Maybe<CourierDetails>;
  readonly customerId: Scalars['ID']['output'];
  readonly deliveryAddressName: Maybe<Scalars['String']['output']>;
  /** Flattened list of order statuses, which collapses the different per order type order statuses into a standard list. */
  readonly flattenedOrderStatus: FlattenedOrderStatuses;
  readonly gravyStatus: GravyStatusType;
  readonly gravyUpdatedAt: Scalars['String']['output'];
  readonly order: Order;
  readonly orderId: Maybe<Scalars['ID']['output']>;
  readonly orderOccasion: Scalars['String']['output'];
  /** Raw list of order statuses, each status comes with the order type and there are multiple variants per each step. */
  readonly orderStatus: Scalars['String']['output'];
  readonly orderWantedTime: Maybe<Scalars['String']['output']>;
  readonly restaurantName: Maybe<Scalars['String']['output']>;
};

export type OrderStatusResponseUnion = InvalidOrder | OrderStatus;

export enum OrderType {
  Delivery = 'DELIVERY',
  InStore = 'IN_STORE',
  Pickup = 'PICKUP',
}

export type OrdersInput = {
  readonly page: Scalars['Int']['input'];
  readonly take: Scalars['Int']['input'];
};

export type OrdersInputV2 = {
  readonly includeCanceledOrders?: InputMaybe<Scalars['Boolean']['input']>;
  readonly includePlacedOrders?: InputMaybe<Scalars['Boolean']['input']>;
  readonly page: Scalars['Int']['input'];
  readonly take: Scalars['Int']['input'];
};

export type OrdersResponseSuccess = {
  readonly __typename?: 'OrdersResponseSuccess';
  readonly orders: ReadonlyArray<Order>;
  readonly pagination: Pagination;
};

export type OrdersResponseUnion = OrdersResponseSuccess | ValidationError;

export type Pagination = {
  readonly __typename?: 'Pagination';
  /** Last available page. */
  readonly last: Scalars['Int']['output'];
  /** The page requested. */
  readonly page: Scalars['Int']['output'];
  /** Items per page. */
  readonly take: Scalars['Int']['output'];
  /** Total number of items available. */
  readonly total: Scalars['Int']['output'];
};

/** Payment entity */
export type Payment = {
  readonly __typename?: 'Payment';
  readonly amountInCents: Scalars['Float']['output'];
  readonly id: Scalars['ID']['output'];
  readonly paymentTime: Scalars['DateTime']['output'];
  readonly processor: Scalars['String']['output'];
};

/** Payment method already exists */
export type PaymentMethodAlreadyExistsError = UserError & {
  readonly __typename?: 'PaymentMethodAlreadyExistsError';
  readonly errorMessage: Scalars['String']['output'];
};

export type PaymentMethodBillingAccount = {
  readonly __typename?: 'PaymentMethodBillingAccount';
  /** The type of card */
  readonly cardType: CardType;
  /** Computed display name containing card type and last 4 digits */
  readonly description: Maybe<Scalars['String']['output']>;
  readonly expirationState: CardExpirationState;
  readonly id: Scalars['ID']['output'];
  readonly isDefault: Scalars['Boolean']['output'];
  /** User-supplied alias */
  readonly nickname: Maybe<Scalars['String']['output']>;
};

export type PaymentMethodCard = {
  readonly __typename?: 'PaymentMethodCard';
  /** The type of card */
  readonly cardType: CardType;
  /** Computed display name containing card type and last 4 digits */
  readonly description: Maybe<Scalars['String']['output']>;
  readonly expirationState: CardExpirationState;
  readonly id: Scalars['ID']['output'];
  readonly isDefault: Scalars['Boolean']['output'];
  /** User-supplied alias */
  readonly nickname: Maybe<Scalars['String']['output']>;
};

export type PaymentMethodCardUnion =
  | PaymentMethodBillingAccount
  | PaymentMethodCard;

export enum PaymentMethods {
  PaymentMethodCard = 'PAYMENT_METHOD_CARD',
  PayInStore = 'PAY_IN_STORE',
  Prepaid = 'PREPAID',
}

/** Payment Plan */
export type PaymentPlan = {
  readonly __typename?: 'PaymentPlan';
  /** ID of plan from Braintree */
  readonly braintreePlanId: Scalars['ID']['output'];
  /** Tier plan belongs to */
  readonly tier: Tier;
};

/** Customer Payment Profile */
export type PaymentProfile = {
  readonly __typename?: 'PaymentProfile';
  readonly defaultCardId: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
};

/** Customer Subscription object */
export type PaymentSubscription = {
  readonly __typename?: 'PaymentSubscription';
  readonly balance: Scalars['String']['output'];
  /**
   * The frequency in which subscription payments should be made.
   * @deprecated Deprecated in favor of `billingRecurrence`
   */
  readonly billingFrequencyUnit: Maybe<DurationUnit>;
  readonly billingHistory: ReadonlyArray<SubscriptionTransactions>;
  readonly billingPeriodEndDate: Maybe<Scalars['String']['output']>;
  readonly billingPeriodStartDate: Maybe<Scalars['String']['output']>;
  /** The recurrence in which subscription payments should be made. */
  readonly billingRecurrence: Maybe<DurationUnit>;
  readonly createdAt: Scalars['String']['output'];
  /** The first billing date of this subscription. */
  readonly firstBillingDate: Maybe<Scalars['DateTime']['output']>;
  /** Indicates if the subscription has a free trial associated with it. */
  readonly hasFreeTrial: Scalars['Boolean']['output'];
  /** The ID of the subscription */
  readonly id: Scalars['ID']['output'];
  /** Indicates if the subscription is currently on a free trial (was not paid for yet). */
  readonly isTrialActive: Scalars['Boolean']['output'];
  readonly nextBillingDate: Scalars['String']['output'];
  readonly nextBillingPeriodAmount: Scalars['String']['output'];
  readonly paidThroughDate: Maybe<Scalars['DateTime']['output']>;
  readonly planId: Scalars['String']['output'];
  readonly price: Maybe<Scalars['String']['output']>;
  readonly status: PaymentSubscriptionStatus;
};

/** Braintree Customer Payment Subscription Profile */
export type PaymentSubscriptionProfile = {
  readonly __typename?: 'PaymentSubscriptionProfile';
  readonly creditCards: Maybe<ReadonlyArray<SubscriptionCreditCard>>;
  readonly id: Scalars['ID']['output'];
  readonly transactionHistory: ReadonlyArray<SubscriptionTransactions>;
};

export enum PaymentSubscriptionStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Expired = 'Expired',
  PastDue = 'PastDue',
  Pending = 'Pending',
}

/** Point expiration information */
export type PointExpirationEntry = {
  readonly __typename?: 'PointExpirationEntry';
  readonly amountExpiring: Scalars['Int']['output'];
  readonly id: Scalars['Int']['output'];
  readonly numOfDaysUntilExpiration: Scalars['Int']['output'];
};

/** Point History Entry */
export type PointHistoryEntry = {
  readonly __typename?: 'PointHistoryEntry';
  readonly date: Scalars['DateTime']['output'];
  readonly id: Scalars['String']['output'];
  readonly source: Maybe<PointIssuanceSource>;
  readonly type: Maybe<PointHistoryEntryType>;
  readonly value: Scalars['Int']['output'];
};

/** The type of the point history entry */
export enum PointHistoryEntryType {
  Earned = 'EARNED',
  Redeemed = 'REDEEMED',
  Removed = 'REMOVED',
}

/** Point History For User Error */
export type PointHistoryForUserError = {
  readonly __typename?: 'PointHistoryForUserError';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type PointHistoryForUserResponseUnion =
  | PointHistoryForUserError
  | PointHistoryForUserSuccess;

/** Point History For User Success */
export type PointHistoryForUserSuccess = {
  readonly __typename?: 'PointHistoryForUserSuccess';
  readonly pagination: Pagination;
  readonly pointExpirationEntries: ReadonlyArray<PointExpirationEntry>;
  readonly pointHistoryEntries: ReadonlyArray<PointHistoryEntry>;
};

/** The source of the points issuance */
export enum PointIssuanceSource {
  BonusPoints = 'BONUS_POINTS',
  CustomerSupport = 'CUSTOMER_SUPPORT',
  OfferCompletion = 'OFFER_COMPLETION',
  Order = 'ORDER',
  Sweetgreen = 'SWEETGREEN',
  VoidedOrder = 'VOIDED_ORDER',
}

export type PointsReward = {
  readonly __typename?: 'PointsReward';
  readonly assetUrl: Maybe<Scalars['String']['output']>;
  readonly displayName: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly points: Scalars['Float']['output'];
  readonly redeemable: Scalars['Boolean']['output'];
};

export type Product = {
  readonly __typename?: 'Product';
  readonly asset: Asset;
  readonly brinkPlu: Maybe<Scalars['Int']['output']>;
  readonly calories: Scalars['Int']['output'];
  /** @deprecated Not being used anymore by the client. */
  readonly categoryId: Scalars['String']['output'];
  /** @deprecated Not being used anymore by the client. */
  readonly categoryName: Scalars['String']['output'];
  /** The cost of the product may vary depending on the channel of the order. Use DELIVERY_COST_CHANNEL if the current order is delivery, and DEFAULT_COST_CHANNEL otherwise. Marketplace and Outpost cost channels are is implicit based on the request and restaurant. */
  readonly channelCost: Scalars['Int']['output'];
  /** Base cost, in cents */
  readonly cost: Scalars['Int']['output'];
  /** If a product is custom, this field provides the type of that product, otherwise the field will be null. Clients must only use this field for cosmetic purposes; for example, displaying a custom product card on the menu page. */
  readonly customType: Maybe<CustomProductType>;
  readonly description: Scalars['String']['output'];
  readonly dietaryProperties: ReadonlyArray<DietaryProperty>;
  /** Date that product will no longer be valid */
  readonly expirationDate: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly ingredientModifications: IngredientModifications;
  readonly ingredients: ReadonlyArray<Ingredient>;
  readonly isCustom: Scalars['Boolean']['output'];
  readonly isModifiable: Scalars['Boolean']['output'];
  readonly isSalad: Scalars['Boolean']['output'];
  readonly label: Maybe<ProductLabel>;
  /** The total number of additions, removals, and substitutions that are allowed to be modified for this product */
  readonly maxModifications: Scalars['Int']['output'];
  readonly name: Scalars['String']['output'];
  readonly outOfStock: Scalars['Boolean']['output'];
  readonly restaurant: Restaurant;
  readonly restaurantId: Scalars['ID']['output'];
  readonly slug: Scalars['String']['output'];
  readonly throttleItem: Scalars['Boolean']['output'];
};

export type ProductChannelCostArgs = {
  costChannel?: InputMaybe<CostChannel>;
};

export type ProductLabel = {
  readonly __typename?: 'ProductLabel';
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
};

export type ProductOutOfStock = UserError & {
  readonly __typename?: 'ProductOutOfStock';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type ProductUnavailable = UserError & {
  readonly __typename?: 'ProductUnavailable';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

/** Promo code has already been applied */
export type PromoCodeAlreadyApplied = UserError & {
  readonly __typename?: 'PromoCodeAlreadyApplied';
  readonly errorMessage: Scalars['String']['output'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly addons: Addons;
  readonly address: Maybe<Address>;
  /** Returns all sweetgreen locations (that aren't hidden etc) */
  readonly allLocations: Maybe<ReadonlyArray<Maybe<LocationSearchResult>>>;
  /** Returns available loyalty point options */
  readonly availableCustomerSupportPoints: AvailableCustomerSupportPointsResponseUnion;
  readonly baseProduct: Maybe<BaseProduct>;
  /**
   * Returns if an order can be canceled and the amount of minutes before the order cannot be canceled.
   * @deprecated Use canCancelV2 instead.
   */
  readonly canCancel: ReadonlyArray<CanCancelResponseUnion>;
  /** Returns if an order can be canceled and the amount of minutes before the order cannot be canceled. */
  readonly canCancelV2: ReadonlyArray<CanCancelResponseUnion>;
  readonly cart: Maybe<Order>;
  readonly categoryUpsells: ReadonlyArray<UpsellCategory>;
  /**
   * Returns the challenge celebration details available for a customer from newly completed challenges. If multiple challenges have been newly completed, returns hardcoded description and disclaimer with no title or expirationDate. Returns null if customer has already viewed celebration messaging.
   * @deprecated Deprecated in favor of the "challengeCelebrationModal" query.
   */
  readonly challengeCelebration: Maybe<ChallengeCelebration>;
  /** Returns the challenge celebration details available for a customer from newly completed challenges. Returns null if customer has already viewed celebration messaging. */
  readonly challengeCelebrationV2: Maybe<ChallengeCelebrationV2>;
  /**
   * Returns all eligible rewards available to the customer
   * @deprecated Fetching rewards for user should be done through loyaltyProfile
   */
  readonly challengeRewards: ReadonlyArray<ChallengeReward>;
  /** Returns customers eligible challenges */
  readonly challenges: ReadonlyArray<Challenge>;
  readonly currentCustomer: Maybe<Customer>;
  /** Pass card for scan to pay, usually for adding to apple wallet */
  readonly customerPass: Maybe<CustomerPass>;
  /** Returns the loyalty points for a user. */
  readonly customerPointsForCustomerSupport: CustomerPointsForCustomerSupportResponseUnion;
  /** Returns available credits allocated for customer support team to issue. Requires a bearer auth token. */
  readonly customerSupportCredits: CustomerSupportCreditsResponseUnion;
  readonly favoritedLineItems: ReadonlyArray<LineItem>;
  readonly feedbackQuestions: ReadonlyArray<FeedbackQuestion>;
  /** Returns all plans associated to tierId */
  readonly fetchPlansByTierId: Maybe<ReadonlyArray<PaymentPlan>>;
  /** Get the Kiosk Reader Location ID */
  readonly getKioskReaderLocationId: Maybe<GetKioskReaderLocationIdResponse>;
  readonly getSubscriptions: ReadonlyArray<PaymentSubscription>;
  /** Customer's gift card balance */
  readonly giftCardBalance: GiftCardBalanceResponseUnion;
  /** Customer's gift card order detail */
  readonly giftCardOrderDetail: GiftCardOrderDetailResponseUnion;
  /** Customer's gift card purchase history */
  readonly giftCardPurchaseHistory: GiftCardPurchaseHistory;
  /** Gift card redepmtion details */
  readonly giftCardRedemptionDetail: GiftCardRedemptionDetailResponseUnion;
  /** Returns a boolean indicating if user has any unseen challenges */
  readonly hasUnseenChallenges: HasUnseenChallenges;
  readonly inStoreFeedbackQuestions: ReadonlyArray<FeedbackQuestion>;
  readonly inStoreOrderLastPendingFeedback: Maybe<InStoreOrder>;
  readonly inStorePaymentMethods: ReadonlyArray<Credit>;
  /** Kustomer JWT token used to login the user in Kustomer */
  readonly kustomerToken: Maybe<Kustomer>;
  readonly lineItem: Maybe<LineItemResponseUnion>;
  readonly lineItemBySlug: Maybe<LineItemResponseUnion>;
  readonly lineItemRecommendations: ReadonlyArray<LineItemRecommendation>;
  readonly locationByAddress: LocationByAddressResponseUnion;
  /** Returns existing loyalty profile, otherwise creates one */
  readonly loyaltyProfile: Maybe<LoyaltyProfile>;
  readonly menuCollection: MenuCollection;
  readonly menuCollections: ReadonlyArray<MenuCollection>;
  /** Get all non-refunded transactions for a customer */
  readonly nonRefundedLoyaltyTransactionsForCustomer: ReadonlyArray<SubscriptionTransactions>;
  readonly order: Order;
  readonly orderLastPendingFeedback: Maybe<Order>;
  readonly orderStatus: Maybe<OrderStatusResponseUnion>;
  readonly orders: OrdersResponseUnion;
  readonly ordersV2: OrdersResponseUnion;
  readonly paymentMethodCards: ReadonlyArray<PaymentMethodCardUnion>;
  /** Returns a payment profile entry matching the customer customerId provided */
  readonly paymentProfile: Maybe<PaymentProfile>;
  /** Returns a payment subscription profile matching the customer customerId provided */
  readonly paymentSubscriptionProfile: Maybe<PaymentSubscriptionProfile>;
  /** Returns the point history for a user. */
  readonly pointHistoryForUser: PointHistoryForUserResponseUnion;
  readonly product: Product;
  readonly productForRestaurant: Maybe<Product>;
  readonly restaurant: Maybe<Restaurant>;
  readonly restaurantCapacity: RestaurantCapacity;
  readonly rewards: ReadonlyArray<Reward>;
  /** Returns all sweetgreen locations within topLeft and bottomRight map coordinates~ */
  readonly searchLocationsByBoundingBox: Maybe<
    ReadonlyArray<Maybe<LocationSearchResult>>
  >;
  /** Returns the sweetgreen location matching the entityId */
  readonly searchLocationsByEntityId: Maybe<
    ReadonlyArray<Maybe<LocationSearchResult>>
  >;
  /** Returns sweetgreen locations by string weighted by keyword match */
  readonly searchLocationsByString: Maybe<
    ReadonlyArray<Maybe<LocationSearchResult>>
  >;
  /**
   *   Returns all sweetgreen locations within a given kilometer radius, in ascending
   * order of proximity
   */
  readonly searchLocationsInKilometerRadius: Maybe<
    ReadonlyArray<Maybe<LocationSearchResult>>
  >;
  /**
   *   Returns all sweetgreen locations within a given kilometer radius, in ascending
   * order of proximity
   */
  readonly searchLocationsInMeterRadius: Maybe<
    ReadonlyArray<Maybe<LocationSearchResult>>
  >;
  /**
   *   Returns all sweetgreen locations within a given mile radius, in ascending
   * order of proximity
   */
  readonly searchLocationsInMileRadius: Maybe<
    ReadonlyArray<Maybe<LocationSearchResult>>
  >;
  readonly session: Session;
  /** Returns a subscription plan matching the planId provided */
  readonly subscriptionPlanById: Maybe<SubscriptionPlan>;
  /** Returns an array of subscription plans matching the planIds provided */
  readonly subscriptionPlansByIds: Maybe<ReadonlyArray<SubscriptionPlan>>;
};

export type QueryAddressArgs = {
  addressId: Scalars['ID']['input'];
};

export type QueryAllLocationsArgs = {
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBaseProductArgs = {
  slug: Scalars['String']['input'];
};

export type QueryCanCancelArgs = {
  input: ReadonlyArray<CanCancelInput>;
};

export type QueryCanCancelV2Args = {
  input: ReadonlyArray<CanCancelInput>;
};

export type QueryCategoryUpsellsArgs = {
  restaurantId: Scalars['String']['input'];
};

export type QueryCustomerPointsForCustomerSupportArgs = {
  legacyInternalId: Scalars['ID']['input'];
};

export type QueryFavoritedLineItemsArgs = {
  input: FavoritedLineItemsInput;
};

export type QueryFeedbackQuestionsArgs = {
  orderId: Scalars['ID']['input'];
};

export type QueryFetchPlansByTierIdArgs = {
  tierId: Scalars['ID']['input'];
};

export type QueryGetKioskReaderLocationIdArgs = {
  restaurantSlug: Scalars['String']['input'];
};

export type QueryGetSubscriptionsArgs = {
  status?: InputMaybe<ReadonlyArray<SubscriptionStatus>>;
};

export type QueryGiftCardOrderDetailArgs = {
  orderId: Scalars['String']['input'];
};

export type QueryGiftCardRedemptionDetailArgs = {
  id: Scalars['String']['input'];
};

export type QueryInStoreFeedbackQuestionsArgs = {
  orderId: Scalars['ID']['input'];
};

export type QueryLineItemArgs = {
  input: LineItemInput;
};

export type QueryLineItemBySlugArgs = {
  input: LineItemBySlugInput;
};

export type QueryLocationByAddressArgs = {
  input: LocationByAddressInput;
};

export type QueryMenuCollectionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMenuCollectionsArgs = {
  menuId: Scalars['ID']['input'];
};

export type QueryNonRefundedLoyaltyTransactionsForCustomerArgs = {
  uuid: Scalars['String']['input'];
};

export type QueryOrderArgs = {
  orderId: Scalars['ID']['input'];
};

export type QueryOrderStatusArgs = {
  orderId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOrdersArgs = {
  input: OrdersInput;
};

export type QueryOrdersV2Args = {
  input: OrdersInputV2;
};

export type QueryPointHistoryForUserArgs = {
  page: Scalars['Int']['input'];
  take: Scalars['Int']['input'];
};

export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProductForRestaurantArgs = {
  baseProductSlug: Scalars['String']['input'];
  restaurantId: Scalars['String']['input'];
};

export type QueryRestaurantArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRestaurantCapacityArgs = {
  endTime?: InputMaybe<Scalars['String']['input']>;
  minimumLineItemCapacity?: InputMaybe<Scalars['Int']['input']>;
  restaurantId: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySearchLocationsByBoundingBoxArgs = {
  bottomRight: GeoCoordinates;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  topLeft: GeoCoordinates;
};

export type QuerySearchLocationsByEntityIdArgs = {
  entityId: Scalars['String']['input'];
};

export type QuerySearchLocationsByStringArgs = {
  searchString: Scalars['String']['input'];
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySearchLocationsInKilometerRadiusArgs = {
  latLong: GeoCoordinates;
  radius?: InputMaybe<Scalars['Float']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySearchLocationsInMeterRadiusArgs = {
  latLong: GeoCoordinates;
  radius?: InputMaybe<Scalars['Float']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySearchLocationsInMileRadiusArgs = {
  latLong: GeoCoordinates;
  radius?: InputMaybe<Scalars['Float']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QuerySubscriptionPlanByIdArgs = {
  planId: Scalars['ID']['input'];
};

export type QuerySubscriptionPlansByIdsArgs = {
  planIds: ReadonlyArray<Scalars['ID']['input']>;
};

export enum RecommendationType {
  Historical = 'HISTORICAL',
  New = 'NEW',
}

export type RedeemGiftCardResponseUnion =
  | GiftCardAssociatedWithAnotherAccountError
  | GiftCardBalance
  | InvalidGiftCardError
  | NoBalanceGiftCardError
  | UnableToRedeemGiftCardError;

/** InStore = Reward can be redeemed in store, Delivery = Reward can be redeemed in delivery, Pickup = Reward can be redeemed in pickup, All = Reward can be redeemed in all channels */
export enum RedemptionChannel {
  All = 'All',
  Delivery = 'Delivery',
  InStore = 'InStore',
  Outpost = 'Outpost',
  Pickup = 'Pickup',
}

export type RefundLoyaltyTransactionResponseUnion =
  | CannotRefundSubscriptionTransactionUserError
  | RefundLoyaltyTransactionSuccessResponse;

/** The result of adding a payment method to a subscription */
export type RefundLoyaltyTransactionSuccessResponse = {
  readonly __typename?: 'RefundLoyaltyTransactionSuccessResponse';
  /** Whether the refund was successful or not */
  readonly success: Scalars['Boolean']['output'];
};

export type RefundOrderInput = {
  /** Person making the adjustment to the order payment. */
  readonly adjuster: Scalars['String']['input'];
  /** Array of Gravy IDs to be refunded. */
  readonly order_ids: ReadonlyArray<Scalars['String']['input']>;
  /** Callers origin (eg kustomer, customer, etc.). */
  readonly origin: Scalars['String']['input'];
  /** Description for why the order is being refunded. */
  readonly reason: Scalars['String']['input'];
};

export type RefundOrderSuccess = {
  readonly __typename?: 'RefundOrderSuccess';
  /** Array of refund results for each refunded order. */
  readonly refundResults: ReadonlyArray<RefundResult>;
};

export type RefundResult = {
  readonly __typename?: 'RefundResult';
  /** Gravy order ID being refunded. */
  readonly id: Scalars['String']['output'];
  /** Reason for a failed order or a success string. */
  readonly reason: Scalars['String']['output'];
  /** Boolean representing if the order successfully refunded or not. */
  readonly success: Scalars['Boolean']['output'];
};

export type RegisterGuestFailed = {
  readonly __typename?: 'RegisterGuestFailed';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type RegisterGuestInput = {
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly firstName: Scalars['String']['input'];
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterGuestResponseUnion =
  | RegisterGuestFailed
  | RegisterGuestSuccess
  | ValidationError;

export type RegisterGuestSuccess = {
  readonly __typename?: 'RegisterGuestSuccess';
  readonly csrf: Scalars['String']['output'];
  /** Guest details. */
  readonly guest: Guest;
};

export type RejoinSubscriptionResponseUnion =
  | RejoinSubscriptionSuccessResponse
  | SubscriptionCannotBeReactivatedError
  | UnknownUserError;

export type RejoinSubscriptionSuccessResponse = {
  readonly __typename?: 'RejoinSubscriptionSuccessResponse';
  /** Rejoined Subscription */
  readonly subscription: PaymentSubscription;
};

export type RemoveFromCartInput = {
  readonly lineItemId: Scalars['ID']['input'];
};

export type RemoveFromCartResponseUnion =
  | RemoveFromCartSuccess
  | ValidationError;

export type RemoveFromCartSuccess = {
  readonly __typename?: 'RemoveFromCartSuccess';
  readonly cart: Order;
  readonly success: Scalars['Boolean']['output'];
};

export type RemoveRewardInput = {
  /** The ID of the order the reward is being removed from */
  readonly orderId: Scalars['ID']['input'];
};

export type RemoveRewardResponseUnion =
  | RemoveRewardSuccess
  | RewardNotRemoved
  | ValidationError;

export type RemoveRewardSuccess = {
  readonly __typename?: 'RemoveRewardSuccess';
  readonly order: Order;
};

export type ReorderConflict = {
  readonly __typename?: 'ReorderConflict';
  readonly conflicts: ReadonlyArray<OrderConflict>;
  /** Indicates if all products in the order are unavailable */
  readonly isNothingAvailable: Scalars['Boolean']['output'];
};

export type ReorderInput = {
  readonly deliveryOrderDetails?: InputMaybe<DeliveryOrderDetailInput>;
  readonly destinationRestaurantId: Scalars['ID']['input'];
  readonly id: Scalars['ID']['input'];
  /** Ignore order conflicts and submit reorder */
  readonly ignoreConflicts?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReorderResponseUnion =
  | CanNotReorder
  | ReorderConflict
  | ReorderSuccess
  | RestaurantMaxDeliveryQuantityExceeded
  | RestaurantMaxQuantityExceeded
  | ValidationError;

export type ReorderSuccess = {
  readonly __typename?: 'ReorderSuccess';
  readonly cart: Order;
  /** Indicates if the reorder supports mixing */
  readonly isMixingSupported: Scalars['Boolean']['output'];
};

export type RequestAccountDeletionFailed = {
  readonly __typename?: 'RequestAccountDeletionFailed';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type RequestAccountDeletionResponseUnion =
  | RequestAccountDeletionFailed
  | RequestAccountDeletionSuccess;

export type RequestAccountDeletionSuccess = {
  readonly __typename?: 'RequestAccountDeletionSuccess';
  readonly success: Scalars['Boolean']['output'];
};

export type Restaurant = {
  readonly __typename?: 'Restaurant';
  readonly address: Scalars['String']['output'];
  readonly asset: Asset;
  /** Some outposts have multiple shelves within them (say, on different floors), and allow customers to select one */
  readonly availableDropOffLocations: ReadonlyArray<DropOffLocation>;
  readonly city: Scalars['String']['output'];
  readonly deliveryFee: Scalars['Int']['output'];
  readonly deliveryMinSubtotal: Scalars['Float']['output'];
  readonly entityId: Maybe<Scalars['Int']['output']>;
  readonly flexMessage: Maybe<Scalars['String']['output']>;
  readonly hours: RestaurantHours;
  readonly id: Scalars['ID']['output'];
  readonly isAcceptingOrders: Scalars['Boolean']['output'];
  readonly isOutpost: Scalars['Boolean']['output'];
  readonly latitude: Scalars['Float']['output'];
  readonly longitude: Scalars['Float']['output'];
  readonly menu: Menu;
  readonly name: Scalars['String']['output'];
  readonly notAcceptingOrdersReason: Scalars['String']['output'];
  readonly parentId: Maybe<Scalars['Int']['output']>;
  readonly phone: Scalars['String']['output'];
  readonly showDeliveryFeeDisclosure: Scalars['Boolean']['output'];
  readonly showDeliveryPriceDifferenciationDisclosure: Scalars['Boolean']['output'];
  readonly showOutpostPriceDifferenciationDisclosure: Scalars['Boolean']['output'];
  readonly slug: Scalars['String']['output'];
  readonly state: Scalars['String']['output'];
  readonly utcOffset: Maybe<Scalars['Int']['output']>;
  readonly zipCode: Scalars['String']['output'];
};

/** Stripe Address Object */
export type RestaurantAddress = {
  readonly __typename?: 'RestaurantAddress';
  readonly city: Maybe<Scalars['String']['output']>;
  readonly country: Maybe<Scalars['String']['output']>;
  readonly line1: Maybe<Scalars['String']['output']>;
  readonly line2: Maybe<Scalars['String']['output']>;
  readonly postal_code: Maybe<Scalars['String']['output']>;
  readonly state: Maybe<Scalars['String']['output']>;
};

export type RestaurantCapacity = {
  readonly __typename?: 'RestaurantCapacity';
  /** available capacity slots */
  readonly availableWantedTimes: ReadonlyArray<RestaurantCapacitySlot>;
  readonly restaurantAddress: Scalars['String']['output'];
  readonly restaurantHours: Scalars['String']['output'];
  readonly restaurantId: Scalars['ID']['output'];
};

export type RestaurantCapacitySlot = {
  readonly __typename?: 'RestaurantCapacitySlot';
  readonly availableLineItems: Scalars['Float']['output'];
  /** A timeslot in Day, HH:MM format using timezone of the store's location */
  readonly formatted: Scalars['String']['output'];
  readonly maxLineItems: Scalars['Float']['output'];
  /** A timeslot in ISO8601 date time format using timezone of the store's location */
  readonly original: Scalars['String']['output'];
};

export type RestaurantHours = {
  readonly __typename?: 'RestaurantHours';
  readonly formatted: Maybe<Scalars['String']['output']>;
  readonly permanent: ReadonlyArray<RestaurantStoreHours>;
  readonly store: ReadonlyArray<RestaurantStoreHours>;
};

export type RestaurantIsNotAcceptingOrders = UserError & {
  readonly __typename?: 'RestaurantIsNotAcceptingOrders';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type RestaurantLocationInfoInputDto = {
  readonly city: Scalars['String']['input'];
  readonly country: Scalars['String']['input'];
  readonly display_name: Scalars['String']['input'];
  readonly line1: Scalars['String']['input'];
  readonly line2?: InputMaybe<Scalars['String']['input']>;
  readonly postal_code: Scalars['String']['input'];
  readonly restaurant_slug: Scalars['String']['input'];
  readonly state: Scalars['String']['input'];
};

export type RestaurantMaxDeliveryQuantityExceeded = {
  readonly __typename?: 'RestaurantMaxDeliveryQuantityExceeded';
  readonly conflicts: ReadonlyArray<OrderConflict>;
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type RestaurantMaxQuantityExceeded = {
  readonly __typename?: 'RestaurantMaxQuantityExceeded';
  readonly conflicts: ReadonlyArray<OrderConflict>;
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type RestaurantStoreHours = {
  readonly __typename?: 'RestaurantStoreHours';
  /** Day of the week */
  readonly day: Scalars['String']['output'];
  /** Time formatted as hh:mm (ex. 10:00). Will show as "CLOSED" if the store is closed for the day */
  readonly end: Scalars['String']['output'];
  /** Time formatted as hh:mm (ex. 10:00). Will show as "CLOSED" if the store is closed for the day */
  readonly start: Scalars['String']['output'];
};

/** Entity contributes to the Rewards entity in Monograph with fields provided by Challenges SVC */
export type Reward = {
  readonly __typename?: 'Reward';
  /** URL for the reward image */
  readonly assetUrl: Maybe<Scalars['String']['output']>;
  /** Date the reward expires */
  readonly expirationDate: Maybe<Scalars['String']['output']>;
  /** SessionM Offer ID - Not direct match to ID in Reward entity in Monograph but included in the serialized value */
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  /** If true, reward can be used and UI should allow it to be selected on the bag */
  readonly redeemable: Scalars['Boolean']['output'];
  /** If redeemable is false, this will populate with the next timestamp in UTC when the offer can be used. */
  readonly redeemableAt: Maybe<Scalars['DateTime']['output']>;
  /** The channel(s) in which the reward can be redeemed */
  readonly redemptionChannel: RedemptionChannel;
  readonly rewardType: RewardType;
  /** Field references the offer terms and conditions configured in SessionM. If that field is not configured, returns the default SG terms url. */
  readonly terms: Scalars['String']['output'];
};

/** Reward discount */
export type RewardDiscount = {
  readonly __typename?: 'RewardDiscount';
  readonly amount: Scalars['Float']['output'];
  readonly appliedTime: Scalars['String']['output'];
  readonly discountSource: Scalars['String']['output'];
  readonly discountedLineIdQuantities: Maybe<
    ReadonlyArray<Scalars['Float']['output']>
  >;
  readonly discountedLineIds: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly displayName: Scalars['String']['output'];
  readonly imageUrl: Maybe<Scalars['String']['output']>;
  readonly isLoyaltyReward: Scalars['Boolean']['output'];
  readonly offerCustomData: Maybe<Scalars['String']['output']>;
  readonly posDiscountId: Maybe<Scalars['String']['output']>;
  readonly referenceId: Scalars['String']['output'];
  readonly referenceIdType: Scalars['String']['output'];
  readonly stackOrder: Scalars['Float']['output'];
  readonly status: Maybe<Scalars['String']['output']>;
  readonly userId: Maybe<Scalars['String']['output']>;
  readonly userOfferCustomData: Maybe<Scalars['String']['output']>;
};

/** Indicates that adding, removing, or validating a reward failed and that an error should be shown to the customer. The end result is always that the cart's current reward is cleared; clients should update their internal state to reflect this. */
export type RewardNotApplied = {
  readonly __typename?: 'RewardNotApplied';
  /**
   * Provides more detail about the reason reward application failed. Clients should map these to customer-facing error messages, with a generic default for an unrecognized value.
   * @deprecated Use failureReasons and failureMetadata instead
   */
  readonly failureCode: ApplyRewardFailureCode;
  /** Provides even more detail about the reason reward application failed. Clients use these for more refined messaging. */
  readonly failureMetadata: ApplyRewardFailureMetadata;
  /** Provides more detail about the reason reward application failed. Clients should map these to customer-facing error messages, with a generic default for an unrecognized value. */
  readonly failureReasons: ReadonlyArray<Scalars['String']['output']>;
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type RewardNotRemoved = {
  readonly __typename?: 'RewardNotRemoved';
  /** Provides more detail about the reason reward removal failed. Clients should map these to customer-facing error messages, with a generic default for an unrecognized value. */
  readonly failureCode: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type RewardNotValidated = UserError & {
  readonly __typename?: 'RewardNotValidated';
  readonly errorMessage: Scalars['String']['output'];
  readonly failureCode: ValidateRewardFailureCode;
  readonly failureMetadata: Maybe<ValidateRewardFailureMetadata>;
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

/** PREMIUM = Reward is part of loyalty program, NON_PREMIUM = Reward is outcome of challenge/other outlet but not loyalty program */
export enum RewardType {
  NonPremium = 'NON_PREMIUM',
  Premium = 'PREMIUM',
}

/** Validate and preview rewards error response */
export type RewardValidateAndPreviewError = {
  readonly __typename?: 'RewardValidateAndPreviewError';
  readonly errorMessage: Scalars['String']['output'];
  readonly failureMetadata: Maybe<RewardValidationFailureMetadata>;
  readonly failureReasons: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly offerId: Maybe<Scalars['ID']['output']>;
};

/** Validate and reserve rewards error response */
export type RewardValidateAndReserveError = {
  readonly __typename?: 'RewardValidateAndReserveError';
  readonly errorMessage: Scalars['String']['output'];
  readonly failureMetadata: Maybe<RewardValidationFailureMetadata>;
  readonly failureReasons: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly offerId: Maybe<Scalars['ID']['output']>;
};

/** Reward Validation Failure Metadata */
export type RewardValidationFailureMetadata = {
  readonly __typename?: 'RewardValidationFailureMetadata';
  readonly requiredChannel: Maybe<Scalars['String']['output']>;
  readonly requiredDays: Maybe<ReadonlyArray<Scalars['Float']['output']>>;
};

/** Validation error response */
export type RewardValidationGeneralError = {
  readonly __typename?: 'RewardValidationGeneralError';
  readonly errorMessage: Scalars['String']['output'];
};

export type RewardsPremiumStatus = {
  readonly __typename?: 'RewardsPremiumStatus';
  readonly expirationDate: Scalars['String']['output'];
  readonly isActive: Scalars['Boolean']['output'];
};

/** Validate and preview rewards success response */
export type RewardsValidateAndPreviewSuccess = {
  readonly __typename?: 'RewardsValidateAndPreviewSuccess';
  readonly rewardDiscountPreviews: ReadonlyArray<RewardDiscount>;
};

export type RewardsValidateAndPreviewUnion =
  | RewardValidateAndPreviewError
  | RewardValidationGeneralError
  | RewardsValidateAndPreviewSuccess;

/** Validate and reserve rewards success response */
export type RewardsValidateAndReserveSuccess = {
  readonly __typename?: 'RewardsValidateAndReserveSuccess';
  readonly reservedRewardDiscounts: ReadonlyArray<RewardDiscount>;
};

export type RewardsValidateAndReserveUnion =
  | RewardValidateAndReserveError
  | RewardValidationGeneralError
  | RewardsValidateAndReserveSuccess;

export type RewardsValidationInput = {
  readonly rewardIds: ReadonlyArray<Scalars['String']['input']>;
  readonly transaction: OpenLoyaltyTransactionDetailsInput;
};

export type SelectedRewardsWithDetails = {
  readonly id: Scalars['String']['input'];
  readonly offerId: Scalars['String']['input'];
};

export type Session = {
  readonly __typename?: 'Session';
  readonly cart: Maybe<Order>;
  readonly csrf: Scalars['String']['output'];
  /** ID of the order currently in-flight, restricted to delivery orders. */
  readonly inFlightDeliveryOrderId: Maybe<Scalars['ID']['output']>;
  /** ID of the order currently in-flight. */
  readonly inFlightOrderId: Maybe<Scalars['ID']['output']>;
  readonly isLoggedIn: Scalars['Boolean']['output'];
};

export type SignInFailed = {
  readonly __typename?: 'SignInFailed';
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type SignInResponseUnion =
  | CustomerNotFound
  | SignInFailed
  | SignInSuccess
  | ValidationError;

export type SignInSuccess = {
  readonly __typename?: 'SignInSuccess';
  readonly customer: Customer;
};

/** Reduced version of Restaurant, with fields required for location search. */
export type StoreLocation = {
  readonly __typename?: 'StoreLocation';
  readonly acceptingOrders: Scalars['Boolean']['output'];
  readonly address: Scalars['String']['output'];
  readonly city: Scalars['String']['output'];
  readonly comingSoon: Maybe<Scalars['Boolean']['output']>;
  readonly crossStreet: Maybe<Scalars['String']['output']>;
  readonly enabled: Scalars['Boolean']['output'];
  readonly entityId: Maybe<Scalars['ID']['output']>;
  readonly flexMessage: Maybe<Scalars['String']['output']>;
  readonly hidden: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly imageUrl: Scalars['String']['output'];
  readonly isOutpost: Maybe<Scalars['Boolean']['output']>;
  readonly latitude: Scalars['Float']['output'];
  readonly longitude: Scalars['Float']['output'];
  readonly menuId: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly nativeDeliveryPriceDifferentiationEnabled: Maybe<
    Scalars['Boolean']['output']
  >;
  readonly notAcceptingOrdersReason: Maybe<Scalars['String']['output']>;
  readonly oloId: Scalars['ID']['output'];
  readonly outpostPriceDifferentiationEnabled: Maybe<
    Scalars['Boolean']['output']
  >;
  readonly phone: Maybe<Scalars['String']['output']>;
  readonly pickupInstructions: Maybe<Scalars['String']['output']>;
  readonly regionId: Maybe<Scalars['ID']['output']>;
  readonly showWarningDialog: Scalars['Boolean']['output'];
  readonly slug: Scalars['String']['output'];
  readonly state: Scalars['String']['output'];
  readonly storeHours: Maybe<Scalars['String']['output']>;
  readonly warningDialogDescription: Maybe<Scalars['String']['output']>;
  readonly warningDialogTimeout: Maybe<Scalars['Float']['output']>;
  readonly warningDialogTitle: Maybe<Scalars['String']['output']>;
  readonly zipCode: Scalars['String']['output'];
};

export type StoreOffline = UserError & {
  readonly __typename?: 'StoreOffline';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type SubmitGiftCardCodeSuccess = {
  readonly __typename?: 'SubmitGiftCardCodeSuccess';
  /** Total credit after applying gift card */
  readonly totalCredit: Scalars['Float']['output'];
};

/** "Unable to purchase gift card" user error */
export type SubmitGiftCardOrderError = UserError & {
  readonly __typename?: 'SubmitGiftCardOrderError';
  readonly errorMessage: Scalars['String']['output'];
};

export type SubmitGiftCardOrderUsingPaymentMethodInput = {
  readonly assetId: Scalars['String']['input'];
  readonly balance: Scalars['Float']['input'];
  readonly deliveryDateTime?: InputMaybe<Scalars['String']['input']>;
  readonly deliveryMethods: ReadonlyArray<Scalars['String']['input']>;
  readonly isAsap: Scalars['Boolean']['input'];
  readonly message?: InputMaybe<Scalars['String']['input']>;
  readonly paymentMethodId: Scalars['String']['input'];
  readonly quantity: Scalars['Float']['input'];
  readonly recipientEmail?: InputMaybe<Scalars['String']['input']>;
  readonly recipientName: Scalars['String']['input'];
  readonly senderName: Scalars['String']['input'];
  readonly utcOffset: Scalars['Float']['input'];
};

export type SubmitGiftCardOrderUsingPaymentMethodResponseUnion =
  | GiftCardOrderDetail
  | SubmitGiftCardOrderError;

export type SubmitOrderAsapInput = {
  readonly includeUtensils?: InputMaybe<Scalars['Boolean']['input']>;
  readonly orderId: Scalars['ID']['input'];
  /** the order type to be submitted, ie: pickup */
  readonly orderType: OrderType;
  readonly pickupTip?: InputMaybe<Scalars['Float']['input']>;
};

export type SubmitOrderInput = {
  /** If the client has their own identifier for this order, they should provide it here for potential correlation on sweetgreen's end. For example, delivery marketplaces should provide their order id. */
  readonly externalReferenceId?: InputMaybe<Scalars['String']['input']>;
  readonly includeUtensils?: InputMaybe<Scalars['Boolean']['input']>;
  readonly orderId: Scalars['ID']['input'];
  /** the order type to be submitted, ie: pickup */
  readonly orderType: OrderType;
  readonly pickupTip?: InputMaybe<Scalars['Float']['input']>;
  /** The time at which the customer expects to receive their order, selected from order.availableWantedTimes */
  readonly wantedTime: Scalars['String']['input'];
};

export type SubmitOrderUsingBillingAccountResponseUnion =
  | DeliveryAlreadyInFlight
  | DeliverySpecifyAddress
  | InvalidBillingAccount
  | InvalidCustomerPhone
  | InvalidTip
  | MinimumDeliverySubtotalNotMet
  | ProductUnavailable
  | RestaurantIsNotAcceptingOrders
  | RewardNotValidated
  | StoreOffline
  | SubmitOrderUsingBillingAccountSuccess
  | SubmitOrderUsingPaymentMethodCardSuccess
  | TimeslotUnavailable
  | ValidationError;

export type SubmitOrderUsingBillingAccountSuccess = {
  readonly __typename?: 'SubmitOrderUsingBillingAccountSuccess';
  readonly customer: Customer;
  readonly order: Order;
};

export type SubmitOrderUsingPaymentMethodCardInput = {
  /** Shortcut for updating the customer's contact number */
  readonly contactNumber?: InputMaybe<Scalars['String']['input']>;
  readonly deliveryOrderDetail?: InputMaybe<DeliveryOrderDetailInput>;
  /** Selected from restaurant.availableDropoffLocations, if it exists */
  readonly dropoffLocationId?: InputMaybe<Scalars['ID']['input']>;
  readonly includeUtensils?: InputMaybe<Scalars['Boolean']['input']>;
  readonly orderId: Scalars['ID']['input'];
  /** Customer's saved Payment Method card */
  readonly paymentMethodCardId: Scalars['ID']['input'];
  readonly pickupTip?: InputMaybe<Scalars['Float']['input']>;
  /** Whether or not to apply the customer's available credit towards the transaction */
  readonly useCredit: Scalars['Boolean']['input'];
  /** The time at which the customer expects to receive their order, selected from order.availableWantedTimes */
  readonly wantedTime: Scalars['String']['input'];
};

export type SubmitOrderUsingPaymentMethodCardSuccess = {
  readonly __typename?: 'SubmitOrderUsingPaymentMethodCardSuccess';
  readonly customer: Customer;
  readonly order: Order;
};

export type SubmitOrderUsingPaymentMethodInput = {
  /** Shortcut for updating the customer's contact number */
  readonly contactNumber?: InputMaybe<Scalars['String']['input']>;
  readonly deliveryOrderDetail?: InputMaybe<DeliveryOrderDetailInput>;
  /** Selected from restaurant.availableDropoffLocations, if it exists */
  readonly dropoffLocationId?: InputMaybe<Scalars['ID']['input']>;
  readonly includeUtensils?: InputMaybe<Scalars['Boolean']['input']>;
  readonly orderId: Scalars['ID']['input'];
  /** Customer's saved Payment Method */
  readonly paymentMethodId: Scalars['ID']['input'];
  readonly pickupTip?: InputMaybe<Scalars['Float']['input']>;
  /** Whether or not to apply the customer's available credit towards the transaction */
  readonly useCredit: Scalars['Boolean']['input'];
  /** The time at which the customer expects to receive their order, selected from order.availableWantedTimes */
  readonly wantedTime: Scalars['String']['input'];
};

export type SubmitOrderUsingPaymentMethodResponseUnion =
  | DeclinedPaymentMethod
  | DeliveryAlreadyInFlight
  | DeliverySpecifyAddress
  | IncorrectPaymentMethod
  | InvalidCustomerPhone
  | InvalidTip
  | MinimumDeliverySubtotalNotMet
  | ProductUnavailable
  | RestaurantIsNotAcceptingOrders
  | RewardNotValidated
  | StoreOffline
  | SubmitOrderUsingPaymentMethodSuccess
  | TimeslotUnavailable
  | ValidationError;

export type SubmitOrderUsingPaymentMethodSuccess = {
  readonly __typename?: 'SubmitOrderUsingPaymentMethodSuccess';
  readonly customer: Customer;
  readonly order: Order;
};

export type SubmitOrderWithPaymentMethodInput = {
  /** If the client has their own identifier for this order, they should provide it here for potential correlation on sweetgreen's end. For example, delivery marketplaces should provide their order id. */
  readonly externalReferenceId?: InputMaybe<Scalars['String']['input']>;
  readonly includeUtensils?: InputMaybe<Scalars['Boolean']['input']>;
  readonly orderId: Scalars['ID']['input'];
  /** the order type to be submitted, ie: pickup */
  readonly orderType: Scalars['String']['input'];
  /** the order payment method, ie: prepaid or cash */
  readonly paymentMethod: PaymentMethods;
  readonly pickupTip?: InputMaybe<Scalars['Float']['input']>;
  /** The time at which the customer expects to receive their order, selected from order.availableWantedTimes */
  readonly wantedTime: Scalars['String']['input'];
};

export type SubmitOrderWithPaymentMethodResponseUnion =
  | MinimumDeliverySubtotalNotMet
  | StoreOffline
  | SubmitOrderWithPaymentMethodSuccess
  | TimeslotUnavailable
  | ValidationError;

export type SubmitOrderWithPaymentMethodSuccess = {
  readonly __typename?: 'SubmitOrderWithPaymentMethodSuccess';
  readonly order: Order;
};

export type SubmitPromoCodeInput = {
  /** LevelUp gift card code. */
  readonly code: Scalars['String']['input'];
};

export type SubmitPromoCodeOrGiftCardResponseUnion =
  | InvalidPromoCode
  | PromoCodeAlreadyApplied
  | SubmitGiftCardCodeSuccess
  | SubmitPromoCodeSuccessV2
  | ValidationError;

export type SubmitPromoCodeResponseUnion =
  | InvalidPromoCode
  | SubmitPromoCodeSuccess
  | ValidationError;

export type SubmitPromoCodeSuccess = {
  readonly __typename?: 'SubmitPromoCodeSuccess';
  /** LevelUp loyalty id associated with promo code. */
  readonly loyaltyId: Scalars['Float']['output'];
};

export type SubmitPromoCodeSuccessV2 = {
  readonly __typename?: 'SubmitPromoCodeSuccessV2';
  /** Placeholder value that always returns true. Use `__typename` to identify success */
  readonly placeholder: Scalars['Boolean']['output'];
};

export type SubmitPromoOrGiftCardCodeInput = {
  /** Promotion or gift card code */
  readonly code: Scalars['String']['input'];
};

/** Error when a user is trying to rejoin a subscription that is already canceled */
export type SubscriptionCannotBeReactivatedError = UserError & {
  readonly __typename?: 'SubscriptionCannotBeReactivatedError';
  readonly errorMessage: Scalars['String']['output'];
};

export type SubscriptionCreditCard = {
  readonly __typename?: 'SubscriptionCreditCard';
  readonly cardType: BraintreeCardType;
  readonly expirationMonth: Maybe<Scalars['String']['output']>;
  readonly expirationState: CardExpirationState;
  readonly expirationYear: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly isActive: Scalars['Boolean']['output'];
  readonly lastFour: Scalars['String']['output'];
};

/** Customer Subscription Plan */
export type SubscriptionPlan = {
  readonly __typename?: 'SubscriptionPlan';
  /** The value that specifies the day of the month that the Braintree gateway will charge the subscription each billing cycle */
  readonly billingDayOfMonth: Maybe<Scalars['String']['output']>;
  /** Specifies the billing interval of the plan in months */
  readonly billingFrequency: Scalars['String']['output'];
  /**
   * The frequency in which subscription payments should be made.
   * @deprecated Deprecated in favor of `billingRecurrence`
   */
  readonly billingFrequencyUnit: Maybe<DurationUnit>;
  /** The recurrence in which subscription payments should be made. */
  readonly billingRecurrence: Maybe<DurationUnit>;
  /** The date/time the plan was created in UTC format */
  readonly createdAt: Scalars['String']['output'];
  /** The currency for the transaction */
  readonly currencyIsoCode: Scalars['String']['output'];
  /** The description of the plan */
  readonly description: Maybe<Scalars['String']['output']>;
  /** The plan identifier */
  readonly discounts: Maybe<ReadonlyArray<Discount>>;
  /** Whether this plan supports a free trial */
  readonly hasFreeTrial: Scalars['Boolean']['output'];
  /** The plan identifier */
  readonly id: Scalars['String']['output'];
  /** The name of the plan */
  readonly name: Scalars['String']['output'];
  /** The base price for the plan */
  readonly price: Scalars['String']['output'];
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  PastDue = 'PAST_DUE',
  Pending = 'PENDING',
}

export type SubscriptionTransactions = {
  readonly __typename?: 'SubscriptionTransactions';
  readonly amount: Scalars['String']['output'];
  readonly creditCard: SubscriptionCreditCard;
  readonly date: Scalars['DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  readonly refund: Scalars['Boolean']['output'];
  readonly transactionCreditCard: TransactionCreditCard;
};

/** Loyalty Tier */
export type Tier = {
  readonly __typename?: 'Tier';
  /** Loyalty Tier Display Name */
  readonly displayName: Scalars['String']['output'];
  /** Tier ID */
  readonly id: Scalars['ID']['output'];
  /** Loyalty Tier Name */
  readonly name: TierName;
  /** Loyalty Tier Name */
  readonly nameV2: TierName;
  /** Next tier */
  readonly nextTier: Maybe<Tier>;
  /** Tier Group */
  readonly tierGroup: TierGroup;
};

/** Tier Group */
export type TierGroup = {
  readonly __typename?: 'TierGroup';
  /** Tier Group ID */
  readonly id: Scalars['ID']['output'];
  /** Tier Group Name */
  readonly name: Scalars['ID']['output'];
};

export enum TierName {
  OptedOut = 'OPTED_OUT',
  RewardsBase = 'REWARDS_BASE',
  RewardsOptedOut = 'REWARDS_OPTED_OUT',
  Sweetpass = 'SWEETPASS',
  SweetpassPlus = 'SWEETPASS_PLUS',
}

/** Loyalty Tier */
export type TierStatus = {
  readonly __typename?: 'TierStatus';
  /** Tier ID */
  readonly id: Scalars['ID']['output'];
  /** Tier Status Name */
  readonly name: TierStatusName;
};

export enum TierStatusName {
  Active = 'ACTIVE',
  CancellationPending = 'CANCELLATION_PENDING',
  FreeTrial = 'FREE_TRIAL',
  PastDue = 'PAST_DUE',
  Processing = 'PROCESSING',
}

export type TimeslotUnavailable = UserError & {
  readonly __typename?: 'TimeslotUnavailable';
  readonly errorMessage: Scalars['String']['output'];
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type TransactionCreditCard = {
  readonly __typename?: 'TransactionCreditCard';
  readonly cardType: BraintreeCardType;
  /** Credit card ID, if the Credit Card was removed Transaction ID */
  readonly id: Scalars['ID']['output'];
  readonly lastFour: Scalars['String']['output'];
};

/** Trial Eligibility */
export type TrialEligibility = {
  readonly __typename?: 'TrialEligibility';
  /** The trial duration length */
  readonly duration: Scalars['Int']['output'];
  /** The trial duration unit */
  readonly durationUnit: DurationUnit;
};

/** "Unable to get gift card balance" user error */
export type UnableToGetGiftCardBalanceError = UserError & {
  readonly __typename?: 'UnableToGetGiftCardBalanceError';
  readonly errorMessage: Scalars['String']['output'];
};

/** "Unable to redeem gift card" user error */
export type UnableToRedeemGiftCardError = UserError & {
  readonly __typename?: 'UnableToRedeemGiftCardError';
  readonly errorMessage: Scalars['String']['output'];
};

/** Generic user error */
export type UnknownUserError = UserError & {
  readonly __typename?: 'UnknownUserError';
  readonly errorMessage: Scalars['String']['output'];
};

export type UpdateAddressResponseUnion =
  | InvalidAddress
  | UpdateAddressSuccess
  | ValidationError;

export type UpdateAddressSuccess = {
  readonly __typename?: 'UpdateAddressSuccess';
  readonly address: Address;
};

export type UpdateCustomerInput = {
  readonly birthday?: InputMaybe<Scalars['String']['input']>;
  readonly contactNumber?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly password?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerResponse = {
  readonly __typename?: 'UpdateCustomerResponse';
  readonly customer: Customer;
};

export type UpdateKioskCustomerInput = {
  readonly contactNumber?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateKioskCustomerResponse = {
  readonly __typename?: 'UpdateKioskCustomerResponse';
  readonly customer: Customer;
};

export type UpdatePaymentMethodInput = {
  /** Id of card to update */
  readonly id: Scalars['String']['input'];
  /** New default flag value of card to update */
  readonly isDefault: Scalars['Boolean']['input'];
  /** New nickname of card to update */
  readonly nickname: Scalars['String']['input'];
};

export type UpdateSubscriptionPaymentMethodSuccessResponse = {
  readonly __typename?: 'UpdateSubscriptionPaymentMethodSuccessResponse';
  /** Updated Subscription */
  readonly subscription: PaymentSubscription;
};

export type UpdateUseCreditResponse = {
  readonly __typename?: 'UpdateUseCreditResponse';
  readonly customer: Customer;
};

export type UpsellCategory = {
  readonly __typename?: 'UpsellCategory';
  readonly categoryRanking: Scalars['Int']['output'];
  readonly id: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly products: ReadonlyArray<UpsellCategoryProduct>;
  readonly restaurantId: Scalars['String']['output'];
};

export type UpsellCategoryProduct = {
  readonly __typename?: 'UpsellCategoryProduct';
  /** The cost of the product may vary depending on the channel of the order. Use DELIVERY_COST_CHANNEL if the current order is delivery, and DEFAULT_COST_CHANNEL otherwise. Marketplace and Outpost cost channels are implicit based on the request and restaurant. */
  readonly channelCost: Scalars['Int']['output'];
  readonly cost: Scalars['Int']['output'];
  readonly id: Scalars['ID']['output'];
  readonly imageUrl: Scalars['String']['output'];
  readonly menuId: Scalars['Int']['output'];
  readonly name: Scalars['String']['output'];
  readonly outOfStock: Scalars['Boolean']['output'];
};

export type UpsellCategoryProductChannelCostArgs = {
  costChannel?: InputMaybe<CostChannel>;
};

export type UserError = {
  readonly errorMessage: Scalars['String']['output'];
};

/** The purchased gift card details */
export type UserGiftCard = {
  readonly __typename?: 'UserGiftCard';
  /** Gift card asset id. */
  readonly assetId: Maybe<Scalars['String']['output']>;
  /** Gift card balance. */
  readonly balance: Scalars['Float']['output'];
  /** Gift card id. */
  readonly id: Scalars['String']['output'];
  /** User provided message sent to the gift card recipient. */
  readonly message: Maybe<Scalars['String']['output']>;
  /** User provided email of the gift card recipient. */
  readonly recipientEmail: Maybe<Scalars['String']['output']>;
  /** User provided name of the gift card recipient. */
  readonly recipientName: Scalars['String']['output'];
  /** Gift card redemption token. */
  readonly redemptionToken: Maybe<Scalars['String']['output']>;
};

export type UserPointsStatus = {
  readonly __typename?: 'UserPointsStatus';
  readonly available: Scalars['Float']['output'];
  readonly pending: Scalars['Float']['output'];
  readonly total: Scalars['Float']['output'];
};

export type ValidateAndRefreshGiftCardRedemptionTokenForCustomerInput = {
  readonly recipientEmail?: InputMaybe<Scalars['String']['input']>;
  readonly redemptionToken: Scalars['String']['input'];
};

export type ValidateAndRefreshGiftCardRedemptionTokenForCustomerResponse = {
  readonly __typename?: 'ValidateAndRefreshGiftCardRedemptionTokenForCustomerResponse';
  /** A valid redemption token generated with the new secret. */
  readonly redemptionToken: Maybe<Scalars['String']['output']>;
  /** Whether the redemption token is valid. */
  readonly valid: Scalars['Boolean']['output'];
};

export enum ValidateRewardFailureCode {
  CartNotFound = 'CART_NOT_FOUND',
  ChannelRestriction = 'CHANNEL_RESTRICTION',
  DayRestriction = 'DAY_RESTRICTION',
  LoyaltyLocationNotFound = 'LOYALTY_LOCATION_NOT_FOUND',
  LoyaltyNotProcessed = 'LOYALTY_NOT_PROCESSED',
  LoyaltyProgramUnavailable = 'LOYALTY_PROGRAM_UNAVAILABLE',
  MissingSku = 'MISSING_SKU',
  PriceMaximum = 'PRICE_MAXIMUM',
  PriceMinimum = 'PRICE_MINIMUM',
  RestaurantNotAcceptingOrders = 'RESTAURANT_NOT_ACCEPTING_ORDERS',
  RewardExpired = 'REWARD_EXPIRED',
  RewardNotApplicable = 'REWARD_NOT_APPLICABLE',
  RewardNotFound = 'REWARD_NOT_FOUND',
  TimeRestriction = 'TIME_RESTRICTION',
}

/** Reward Validation Metadata */
export type ValidateRewardFailureMetadata = {
  readonly __typename?: 'ValidateRewardFailureMetadata';
  readonly requiredChannel: Maybe<Scalars['String']['output']>;
  readonly requiredDays: Maybe<ReadonlyArray<Scalars['Float']['output']>>;
};

export type ValidationError = {
  readonly __typename?: 'ValidationError';
  readonly fieldErrors: ReadonlyArray<FieldValidationError>;
  readonly message: Scalars['String']['output'];
  readonly status: Scalars['Int']['output'];
};

export type Vendor = {
  readonly __typename?: 'Vendor';
  readonly name: Scalars['String']['output'];
  readonly restaurantId: Scalars['String']['output'];
};

export enum Join__Graph {
  DodGraphql = 'DOD_GRAPHQL',
  FulfillmentSvc = 'FULFILLMENT_SVC',
  LoyaltySvc = 'LOYALTY_SVC',
  MonographSvc = 'MONOGRAPH_SVC',
  PaymentsSvc = 'PAYMENTS_SVC',
}

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY',
}

import { useMemo } from 'react';
import type { Asset } from 'contentful';

import { useContentfulContentTypeEntry } from '@order/Contentful';

// ─────────────────────────────────────────────────────────────────────────────

export const useGiftCardData = (params: UseGiftCardParams) => {
  const { assetId, pause } = params;

  // ─── Remote Data ─────────────────────────────────────────────────────

  const searchParams = useMemo(() => {
    return assetId ? { 'fields.id': assetId } : undefined;
  }, [assetId]);

  const { data, error, fetching } = useContentfulContentTypeEntry<GiftCard>({
    contentType: 'giftCard',
    include: 3,
    pause: !assetId || pause,
    searchParams,
  });

  // ─────────────────────────────────────────────────────────────────────

  return {
    error,
    fetching,
    data: data?.fields,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCard = {
  id: string;
  title: string;
  cover: Asset;
};

type UseGiftCardParams = {
  assetId: string | undefined;
  pause: boolean;
};

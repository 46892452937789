import { defineMessages } from 'react-intl';

export const giftCardRedemptionNumberAndCodeMessages = defineMessages({
  cardNumberLabel: {
    defaultMessage: 'Card:',
    description: 'Gift Card Redemption Screen | Gift card number label',
  },
  cardNumberCopiedMessage: {
    defaultMessage: 'Gift card number copied to your clipboard',
    description:
      'Gift Card Redemption Screen | Gift card number copied message',
  },
  cardNumberCopyButtonA11yLabel: {
    defaultMessage: 'Copy gift card number to your clipboard',
    description:
      'Gift Card Redemption Screen | Gift card number copy button a11y label',
  },
  cardCodeLabel: {
    defaultMessage: 'Code:',
    description: 'Gift Card Redemption Screen | Gift card code label',
  },
  cardCodeCopiedMessage: {
    defaultMessage: 'Gift card number copied to your clipboard',
    description: 'Gift Card Redemption Screen | Gift card code copied message',
  },
  cardCodeCopyButtonA11yLabel: {
    defaultMessage: 'Copy gift card code to your clipboard',
    description:
      'Gift Card Redemption Screen | Gift card code copy button a11y label',
  },
});

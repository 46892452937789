/* eslint-disable functional/immutable-data */

import { useCallback, useMemo } from 'react';
import type { LayoutChangeEvent } from 'react-native';
import type {
  GestureUpdateEvent,
  PanGestureHandlerEventPayload,
} from 'react-native-gesture-handler';
import { Gesture } from 'react-native-gesture-handler';
import type { SharedValue } from 'react-native-reanimated';
import { withSpring, withTiming } from 'react-native-reanimated';

import { getCarouselSlideWidthWithoutExtraSpace } from '../../Carousel.utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns helpers to handle the carousel's pan gestures.
 */
export const useCarouselGestures = (params: UseCarouselGesturesParams) => {
  const {
    carouselTranslateX,
    carouselTranslateXStart,
    carouselScale,
    carouselActiveSlideIndexSharedValue,
    carouselSlideWidth,
    stagePadding,
    gap,
    slidesCount,
  } = params;

  // ─── Helpers ─────────────────────────────────────────────────────────

  /**
   * Swipes to the carousel's active slide position.
   */
  const swipeToSlide = useCallback(
    (slideIndex: number) => {
      'worklet';

      carouselActiveSlideIndexSharedValue.value = slideIndex;

      const slideWidthWithoutExtraSpace =
        getCarouselSlideWidthWithoutExtraSpace({
          gap,
          stagePadding,
          slideWidth: carouselSlideWidth.value,
        });

      const activeSlidePosition = slideIndex * slideWidthWithoutExtraSpace;

      carouselTranslateX.value = withSpring(-activeSlidePosition, {
        stiffness: 35,
        mass: 0.5,
      });
    },
    [
      carouselActiveSlideIndexSharedValue,
      carouselSlideWidth.value,
      carouselTranslateX,
      gap,
      stagePadding,
    ],
  );

  /**
   * Swipes to the active layout on carousel layout change (resize, rerender).
   */
  const swipeToActiveSlideOnCarouselLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const { layout } = event.nativeEvent;

      carouselSlideWidth.value = layout.width;

      swipeToSlide(carouselActiveSlideIndexSharedValue.value);
    },
    [
      carouselActiveSlideIndexSharedValue.value,
      carouselSlideWidth,
      swipeToSlide,
    ],
  );

  /**
   * Swipes to the previous carousel slide, preventing over-swiping.
   */
  const swipeToThePrevSlide = useCallback(() => {
    'worklet';

    const thePrevSlideIndex = carouselActiveSlideIndexSharedValue.value - 1;
    const minSlideIndex = 0;
    const targetSlideIndex = Math.max(minSlideIndex, thePrevSlideIndex);

    swipeToSlide(targetSlideIndex);
  }, [carouselActiveSlideIndexSharedValue, swipeToSlide]);

  /**
   * Swipes to the next carousel slide, preventing over-swiping.
   */
  const swipeToTheNextSlide = useCallback(() => {
    'worklet';

    const theNextSlideIndex = carouselActiveSlideIndexSharedValue.value + 1;
    const maxSlideIndex = slidesCount - 1;
    const targetSlideIndex = Math.min(maxSlideIndex, theNextSlideIndex);

    swipeToSlide(targetSlideIndex);
  }, [carouselActiveSlideIndexSharedValue, slidesCount, swipeToSlide]);

  // ─── Gesture Handlers ────────────────────────────────────────────────

  /**
   * Handles the first carousel gesture interaction by storing the carousel's
   * current `translation` value and scaling the carousel to produce
   * the pressed effect.
   */
  const onBegin = useCallback<GestureHandler>(() => {
    'worklet';

    carouselTranslateXStart.value = carouselTranslateX.value;
    carouselScale.value = withTiming(0.95);
  }, [carouselScale, carouselTranslateX.value, carouselTranslateXStart]);

  /**
   * Handles the continuous carousel gesture by storing the current
   * carousel's `translation` value.
   */
  const onUpdate = useCallback<GestureHandler>(
    (event) => {
      'worklet';

      const { translationX } = event;

      carouselTranslateX.value = translationX + carouselTranslateXStart.value;
    },
    [carouselTranslateX, carouselTranslateXStart.value],
  );

  /**
   * Handles the final carousel gesture by swiping to the active slide position.
   */
  const onEnd = useCallback<GestureHandler>(
    (event) => {
      'worklet';

      const { translationX } = event;

      const minDistanceToSwipe = CAROUSEL_MIN_DISTANCE_TO_SWIPE;
      const shouldSwipeToTheNextSlide = translationX < -minDistanceToSwipe;
      const shouldSwipeToThePrevSlide = translationX > minDistanceToSwipe;

      // ─────────────────────────────────────────────

      if (shouldSwipeToTheNextSlide) {
        swipeToTheNextSlide();

        return;
      }

      if (shouldSwipeToThePrevSlide) {
        swipeToThePrevSlide();

        return;
      }

      swipeToSlide(carouselActiveSlideIndexSharedValue.value);
    },
    [
      carouselActiveSlideIndexSharedValue.value,
      swipeToSlide,
      swipeToTheNextSlide,
      swipeToThePrevSlide,
    ],
  );

  /**
   * Resets the carousel scale value after the final interaction.
   */
  const onFinalize = useCallback<GestureHandler>(() => {
    'worklet';

    carouselScale.value = withTiming(1);
  }, [carouselScale]);

  // ─── Gesture Handler ─────────────────────────────────────────────────

  /**
   * Handles carousel pan gestures.
   *
   * @see {@link https://docs.swmansion.com/react-native-gesture-handler/docs/quickstart/}
   */
  const gestureHandler = useMemo(
    () =>
      Gesture.Pan()
        .onBegin(onBegin)
        .onUpdate(onUpdate)
        .onEnd(onEnd)
        .onFinalize(onFinalize),
    [onBegin, onEnd, onFinalize, onUpdate],
  );

  // ─────────────────────────────────────────────────────────────────────

  return {
    swipeToActiveSlideOnCarouselLayout,
    swipeToSlide,
    swipeToThePrevSlide,
    swipeToTheNextSlide,
    gestureHandler,
  };
};

// ─── Constants ───────────────────────────────────────────────────────────────

const CAROUSEL_MIN_DISTANCE_TO_SWIPE = 50; // px

// ─── Types ───────────────────────────────────────────────────────────────────

type UseCarouselGesturesParams = Readonly<{
  carouselActiveSlideIndexSharedValue: SharedValue<number>;
  carouselScale: SharedValue<number>;
  carouselTranslateXStart: SharedValue<number>;
  carouselTranslateX: SharedValue<number>;
  carouselSlideWidth: SharedValue<number>;
  stagePadding: number;
  gap: number;
  slidesCount: number;
}>;

type GestureHandler = (
  event: GestureUpdateEvent<PanGestureHandlerEventPayload>,
) => void;

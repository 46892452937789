import type { ComponentProps } from 'react';
import React from 'react';
import type { PressableProps, ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme, TYPE_CONFIG } from '@garnish/constants';

import { useResponsive } from '../../hooks';
import { Button } from '../Button';
import { Image } from '../Image';
import { BodyText, TitleText } from '../Text';
import { IllusBowl, IllusPlate } from './assets';

// ────────────────────────────────────────────────────────────────────────────────

export const ProductBannerCard = (props: ProductBannerCardProps) => {
  const {
    illustration = 'bowl',
    title,
    description,
    onCtaPress,
    ctaLabel = 'Get started',
    ctaTestID,
    isDisabled,
    ...viewProps
  } = props;

  const { minWidth } = useResponsive();

  // ─────────────────────────────────────────────────────────────────

  const illustrationSrc = illustration === 'bowl' ? IllusBowl : IllusPlate;
  const ctaSize: ComponentProps<typeof Button>['size'] = minWidth.isMD
    ? 'large'
    : 'medium';

  // ─── STYLES ──────────────────────────────────────────────────────

  const wrapperStyle = [
    styles.wrapper,
    minWidth.isLG ? styles.wrapperMD : styles.wrapperXS,
  ];
  const illustrationStyle = minWidth.isLG
    ? styles.illustrationMD
    : styles.illustrationXS;
  const textContentWrapper = minWidth.isLG
    ? styles.textContentWrapperMD
    : styles.textContentWrapperXS;
  const titleStyle = [
    styles.title,
    minWidth.isMD ? styles.titleMD : styles.titleXS,
  ];
  const descriptionStyle = minWidth.isLG
    ? styles.descriptionLG
    : styles.descriptionXS;

  // ─────────────────────────────────────────────────────────────────

  return (
    <View style={wrapperStyle} {...viewProps}>
      <Image
        source={illustrationSrc}
        aria-label={title}
        contentFit="contain"
        style={illustrationStyle}
      />

      <View style={textContentWrapper}>
        <TitleText accessibilityRole="header" aria-level={2} style={titleStyle}>
          {title}
        </TitleText>

        <BodyText size={2} style={descriptionStyle}>
          {description}
        </BodyText>

        <Button
          size={ctaSize}
          rightIcon="IconArrowRight"
          onPress={onCtaPress}
          testID={ctaTestID}
          disabled={isDisabled}
        >
          {ctaLabel}
        </Button>
      </View>
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  //
  // ─── WRAPPER ─────────────────────────────────────────────────────

  wrapper: {
    flexGrow: 1,
    backgroundColor: theme.colors.CUCUMBER,
  },
  wrapperXS: {
    alignItems: 'center',
    padding: theme.spacing['6'],
    borderRadius: theme.radius.medium,
  },
  wrapperMD: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: theme.spacing['4'],
    paddingHorizontal: theme.spacing['6'],
    borderRadius: theme.radius.large,
  },

  // ─── ILLUSTRATION ────────────────────────────────────────────────

  illustrationXS: {
    width: 280,
    height: 198,
  },
  illustrationMD: {
    width: 300,
    height: 300,
  },

  // ─── TEXT CONTENT ────────────────────────────────────────────────

  textContentWrapperXS: {
    alignItems: 'center',
  },
  textContentWrapperMD: {
    alignItems: 'flex-start',
    alignSelf: 'center',
    flexShrink: 1,
    maxWidth: 700,
    paddingVertical: theme.spacing['4'],
    paddingLeft: theme.spacing['6'],
  },

  // ─── TITLE ───────────────────────────────────────────────────────

  title: {
    marginBottom: theme.spacing['4'],
  },
  titleXS: {
    ...TYPE_CONFIG.TITLE['40'],
  },
  titleMD: {
    ...TYPE_CONFIG.TITLE['48'],
  },

  // ─── DESCRIPTION ─────────────────────────────────────────────────

  descriptionXS: {
    textAlign: 'center',
    marginBottom: theme.spacing['4'],
  },
  descriptionLG: {
    marginBottom: theme.spacing['6'],
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ProductBannerCardProps = Readonly<{
  title: string;
  description: string;
  onCtaPress: PressableProps['onPress'];
  ctaLabel?: string;
  ctaTestID?: string;
  illustration?: 'bowl' | 'plate';
  isDisabled?: boolean;
}> &
  Omit<ViewProps, 'children'>;

import React from 'react';

import { FutureDateModal } from '../../../../components/FutureDateModal';
import { useCheckoutContext } from '../../CheckoutProvider';

export const ConnectedFutureDateModal = () => {
  const { shouldShowFutureDateModal, handleDismissFutureModal } =
    useCheckoutContext();

  return (
    <FutureDateModal
      visible={shouldShowFutureDateModal}
      onConfirm={handleDismissFutureModal}
    />
  );
};

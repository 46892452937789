import React, { type ComponentProps, useEffect, useState } from 'react';

import { Icon, type IconName } from '../Icon';

/**
 * SVG animation of a bowl flapping its wings.
 */
export const FlappyBowl = (props: FlappyBowlProps) => {
  const {
    width = 160,
    height = 100,
    flappingSpeed = 300,
    ...iconProps
  } = props;

  const [flappyStep, setFlappyStep] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFlappyStep((step) => (step === flappySteps.length - 1 ? 0 : step + 1));
    }, flappingSpeed);

    return () => {
      clearInterval(intervalId);
    };
  }, [flappingSpeed]);

  return (
    <Icon
      width={width}
      height={height}
      name={flappySteps[flappyStep]}
      {...iconProps}
    />
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const flappySteps: IconName[] = [
  'IconFlappyBowl1',
  'IconFlappyBowl2',
  'IconFlappyBowl3',
];

// ─── Types ──────────────────────────────────────────────────────────────────

type FlappyBowlProps = {
  flappingSpeed?: number;
} & Omit<ComponentProps<typeof Icon>, 'name'>;

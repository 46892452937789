import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../../Text';
import {
  type ButtonFluidLabelPalette,
  paletteStyles,
} from './ButtonFluidText.pallets';

// ─────────────────────────────────────────────────────────────────────────────

export const ButtonFluidText = (props: ButtonFluidLabelProps) => {
  const { children, palette = 'lime', disabled, style, ...restProps } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const textPaletteStyles = disabled
    ? paletteStyles.disabled
    : paletteStyles[palette];
  const textStyles = [styles.text, textPaletteStyles, style];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <BodyText sizeMatch={['16']} style={textStyles} {...restProps}>
      {children}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    color: theme.colors.LIME, // default color
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ButtonFluidLabelProps = {
  /**
   * @default "lime"
   */
  palette?: ButtonFluidLabelPalette;
  disabled?: boolean | null;
} & Omit<ComponentProps<typeof BodyText>, 'disabled'>;

import { BraintreeCardType, CardType } from '@order/graphql';

// Representing an apple pay payment method.
export const APPLE_PAY = 'ApplePay';

// Representing an apple pay payment method.
export const PAYMENT_ADD = 'ADD';

/**
 * The card type can be from any provider, so we map it here.
 */
export function getCreditCardIcon(
  cardType:
    | CardType
    | BraintreeCardType
    | typeof PAYMENT_ADD
    | typeof APPLE_PAY = CardType.Unsupported,
  shouldUseAddIcon?: boolean,
) {
  switch (cardType) {
    case CardType.Amex:
    case CardType.AmericanExpress:
    case BraintreeCardType.AmEx: {
      return 'IconCcAmex';
    }

    case CardType.Discover:
    case BraintreeCardType.Discover: {
      return 'IconCcDiscover';
    }

    case CardType.MasterCard:
    case BraintreeCardType.MasterCard: {
      return 'IconCcMasterCard';
    }

    case CardType.Visa:
    case BraintreeCardType.Visa: {
      return 'IconCcVisa';
    }

    case APPLE_PAY: {
      return 'IconApplePayPayment';
    }

    case PAYMENT_ADD: {
      return shouldUseAddIcon ? 'IconPlus' : 'IconCreditCard';
    }

    default: {
      return 'IconCreditCard';
    }
  }
}

import React from 'react';
import { Controller } from 'react-hook-form';

import { Stepper as GarnishStepper } from '../Stepper';
import type { ControlledProps, FormProps } from './types';

type ComponentProps = React.ComponentProps<typeof GarnishStepper>;
type OmittedProps = ControlledProps | 'noticePalette';
type Props = FormProps &
  Omit<ComponentProps, OmittedProps> &
  Readonly<{
    incrementAmount?: number;
    decrementAmount?: number;
  }>;

export const Stepper = ({
  name,
  control,
  rules,
  incrementAmount,
  decrementAmount,
  ...rest
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <GarnishStepper
          value={value}
          onIncrement={() => {
            onChange(Number(value) + (incrementAmount ?? 1));
            onBlur();
          }}
          onDecrement={() => {
            onChange(Number(value) - (decrementAmount ?? 1));
            onBlur();
          }}
          {...rest}
        />
      )}
    />
  );
};

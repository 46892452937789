import { defineMessages } from 'react-intl';

export const giftCardCheckoutAmountMessages = defineMessages({
  sectionTitle: {
    defaultMessage: 'Amount',
    description: 'Gift Card Checkout Form | Amount | Section title',
  },
  customOptionTitle: {
    defaultMessage: 'Custom',
    description: 'Gift Card Checkout Form | Amount | Custom option title',
  },
  customOptionFieldLabel: {
    defaultMessage: 'Enter amount between {min} and {max}',
    description: 'Gift Card Checkout Form | Amount | Custom option field label',
  },
});

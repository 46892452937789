import type { AccountTabStackScreenName } from '../../AppNavigation.props';

/**
 * Returns true if the specified route name is in the account tab.
 */
export const checkIfAccountTabScreen = (activeScreen: string) => {
  const collectionScreens: readonly AccountTabStackScreenName[] = [
    'AccountMenu',
    'Profile',
    'SweetpassMembership',
    'Addresses',
    'Orders',
    'Favorites',
    'ReferFriend',
    'AccountDietaryRestrictions',
  ];

  return collectionScreens.includes(activeScreen as AccountTabStackScreenName);
};

import { useCallback, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useActor } from '@xstate/react';

import { useHasLoggedOut } from '@order/AuthMachine';
import { useGlobalAppState } from '@order/GlobalAppState';

import { HAS_OPENED_DIETARY_PREFERENCES_MODAL_STORAGE_KEY } from './DietaryRestrictions.constants';
import { dietaryRestrictionsModel } from './dietaryRestrictionsMachine';

// ────────────────────────────────────────────────────────────────────────────────

export const useResetDietaryRestrictionsOnLogout = () => {
  const hasLoggedOut = useHasLoggedOut();
  const { dietaryRestrictionsMachineRef } = useGlobalAppState();

  const [, send] = useActor(dietaryRestrictionsMachineRef);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const resetHasOpenedDietaryPreferencesState = useCallback(async () => {
    await AsyncStorage.removeItem(
      HAS_OPENED_DIETARY_PREFERENCES_MODAL_STORAGE_KEY,
    );
  }, []);

  // ─────────────────────────────────────────────────────────────────

  useEffect(() => {
    if (!hasLoggedOut) return;

    send(dietaryRestrictionsModel.events.CLEAR_RESTRICTIONS());
    void resetHasOpenedDietaryPreferencesState();
  }, [hasLoggedOut, resetHasOpenedDietaryPreferencesState, send]);
};

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { HStack } from '../../../Stack';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardProgressStepper = (
  props: LoyaltyOfferCardProgressStepperProps,
) => {
  const { steps, currentStep } = props;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <HStack itemsPerRow={steps} gap={theme.spacing['1']}>
        {Array.from({ length: steps }).map((_item, index) => {
          const step = index + 1;
          const isActive = step <= currentStep;

          const itemStyles = [
            styles.item,
            isActive ? styles.itemActive : styles.itemInactive,
          ];

          return <View key={step} style={itemStyles} />;
        })}
      </HStack>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const PROGRESS_BAR_HEIGHT = 8;
const PROGRESS_BAR_BORDER_RADIUS = 100;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginTop: theme.spacing['4'],
  },
  item: {
    width: '100%',
    height: PROGRESS_BAR_HEIGHT,
    borderRadius: PROGRESS_BAR_BORDER_RADIUS,
  },
  itemActive: {
    backgroundColor: theme.colors.SWEET_CORN,
  },
  // eslint-disable-next-line react-native/no-color-literals
  itemInactive: {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardProgressStepperProps = {
  steps: number;
  currentStep: number;
};

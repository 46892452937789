import * as RedirectAfterAuthStateChange from './useRedirectAfterAuthStateChange';

// ─────────────────────────────────────────────────────────────────────────────

export function mockUseRedirectAfterAuthStateChangeOnce(): Mock {
  // @ts-expect-error TS(2708): Cannot use namespace 'jest' as a value.
  const redirectMock = jest.fn();

  // @ts-expect-error TS(2708): Cannot use namespace 'jest' as a value.
  jest
    .spyOn(RedirectAfterAuthStateChange, 'useRedirectAfterAuthStateChange')
    .mockReturnValueOnce(redirectMock);

  return redirectMock;
}

// ─── Types ───────────────────────────────────────────────────────────────────

// @ts-expect-error TS(2708): Cannot use namespace 'jest' as a value.
type Mock = ReturnType<typeof jest.fn>;

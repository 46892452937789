import React, { useCallback } from 'react';
import { TransparentModal } from '@sg/garnish';

import type { DeliveryOrderInFlightScreenProps } from '../../navigation/AppNavigation.props';
import { DeliveryOrderInFlightContent } from './DeliveryOrderInFlightContent';

export const DeliveryOrderInFlightScreen = ({
  navigation,
  route,
}: DeliveryOrderInFlightScreenProps) => {
  const handleClose = useCallback(() => {
    navigation.getParent()?.goBack?.();
  }, [navigation]);

  const handleOrderStatus = useCallback(() => {
    navigation.replace('OrderStatus', { orderId: route?.params?.orderId });
  }, [navigation, route]);

  return (
    <TransparentModal
      testID="delivery-order-in-flight"
      size="medium"
      onClose={handleClose}
    >
      <DeliveryOrderInFlightContent
        onClose={handleClose}
        onOrderStatus={handleOrderStatus}
      />
    </TransparentModal>
  );
};

import { isFuture, isValid } from 'date-fns';

import type { CacheEntry } from '../contentful-content-type-entries-machine.types';

/**
 * A small utility that validates the cache entry provided by checking
 * its expiration date and data.
 */
export function checkIfCacheEntryIsValid<Fields>(
  cacheEntry: CacheEntry<Fields> | undefined,
): boolean {
  if (!cacheEntry || typeof cacheEntry !== 'object') return false;

  const { expirationDate = '', data } = cacheEntry;

  const hasValidData = Boolean(data) && typeof data === 'object';
  const hasValidEntry =
    hasValidData &&
    Array.isArray(data) &&
    data.every((entry) => !entry.tags || Array.isArray(entry.tags));

  if (!hasValidEntry) return false;

  const expirationDateInstance = new Date(expirationDate);
  const isExpirationDateValid = isValid(expirationDateInstance);
  const isExpirationDateFuture = isFuture(expirationDateInstance);

  return isExpirationDateValid && isExpirationDateFuture;
}

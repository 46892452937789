import { useIntl } from 'react-intl';
import { type Ingredient, type LineItem } from '@sg/graphql-schema';

import { lineItemDescriptionMessages as messages } from './useLineItemDescription.messages';

// ────────────────────────────────────────────────────────────────────────────────

export const useLineItemDescription = (lineItem: PartialLineItem) => {
  const { formatMessage } = useIntl();

  const { addedIngredients, dressingMode } = lineItem;

  // ─── Flags ───────────────────────────────────────────────────────────

  const isBreadIncluded = addedIngredients?.some(
    (ingredient) => ingredient?.name === BREAD_INGREDIENT_NAME,
  );

  // ─── Messages ────────────────────────────────────────────────────────

  const dressingModeText = formatMessage(messages.dressingModes, {
    dressing_mode: dressingMode,
  });
  const breadOptionText = formatMessage(messages.plusBreadText);

  // ────────────────────────────────────────────────────────────────────

  if (isBreadIncluded) {
    return `${dressingModeText} ${breadOptionText}`.trim();
  }

  return dressingModeText;
};

// ─── Constants ───────────────────────────────────────────────────────────────

const BREAD_INGREDIENT_NAME = 'Bread';

// ─── Types ───────────────────────────────────────────────────────────────────

type PartialLineItem = {
  dressingMode?: LineItem['dressingMode'];
  addedIngredients?: ReadonlyArray<
    Partial<Pick<Ingredient, 'name'>> | undefined
  >;
};

import React, { useRef } from 'react';
import type { ImageSourcePropType, ViewStyle } from 'react-native';
import { ImageBackground, StyleSheet } from 'react-native';
import type { AnimatedStyleProp } from 'react-native-reanimated';
import { theme } from '@garnish/constants';

import { usePressableState, useResponsive } from '../../../../hooks';
import { TitleText } from '../../../Text';
import { GradientOverlay } from '../../assets';
import { HeroCardContainer } from '../HeroCardContainer';
import { HeroCardCta } from '../HeroCardCta';

// ─────────────────────────────────────────────────────────────────────────────

export const HeroCardFrontSide = (props: HeroCardFlipFrontProps) => {
  const {
    animatedStyle,
    ctaLabel,
    imageUrl,
    imageA11yLabel,
    title,
    isVisible = true,
  } = props;

  const { match } = useResponsive();

  const cardContainerRef = useRef(null);

  // ─── Styles ──────────────────────────────────────────────────────────

  const innerContainerResponsiveStyle = match([
    styles.innerContainerXS,
    styles.innerContainerSM,
    styles.innerContainerLG,
  ]);
  const innerContainerStyles = [
    styles.innerContainer,
    innerContainerResponsiveStyle,
  ];

  const { isHovered } = usePressableState(cardContainerRef);
  const cardContainerHoverOpacityStyle = isHovered
    ? styles.cardContainerHover
    : undefined;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <HeroCardContainer
      style={cardContainerHoverOpacityStyle}
      ref={cardContainerRef}
      palette="kale"
      animatedStyle={animatedStyle}
      hasPointerEvents={isVisible}
    >
      <ImageBackground
        source={imageUrl}
        style={styles.outerContainer}
        accessibilityLabel={imageA11yLabel}
      >
        <ImageBackground style={innerContainerStyles} source={GradientOverlay}>
          <TitleText size={4} style={styles.title}>
            {title}
          </TitleText>

          <HeroCardCta label={ctaLabel} />
        </ImageBackground>
      </ImageBackground>
    </HeroCardContainer>
  );
};

// ─── STYLES ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cardContainerHover: {
    opacity: 0.9,
  },

  outerContainer: {
    flex: 1,
  },

  innerContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    padding: theme.spacing['6'],
  },
  innerContainerXS: {
    padding: theme.spacing['4'],
  },
  innerContainerSM: {
    padding: theme.spacing['6'],
  },
  innerContainerLG: {
    padding: theme.spacing['8'],
  },

  title: {
    color: theme.colors.WHITE,
    marginRight: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

export type HeroCardFlipFrontProps = Readonly<{
  title: string;
  ctaLabel: string;
  imageUrl: ImageSourcePropType | undefined;
  imageA11yLabel?: string;
  animatedStyle?: AnimatedStyleProp<ViewStyle>;
  isVisible?: boolean;
}>;

import type {
  FilteredIngredientModifications,
  IngredientModificationWithQuantity,
} from '../../types';
import type {
  ProductModificationsModelContext,
  ProductModificationsModelEvent,
} from '../ProductModifications.machine';
import type {
  IngredientModificationLimitations,
  IngredientsModificationsWithQuantities,
} from '../ProductModifications.types';
import {
  getOutOfStockIngredientsNames,
  withActiveModification,
  withoutActiveModification,
} from './activeModifications';
import { getActiveCountByKind, getModificationKindLimit } from './common';
import {
  getComputedModifications,
  getNumberOfModifications,
} from './computedModifications';

// ────────────────────────────────────────────────────────────────────────────────

export const checkIfMaxModificationLimitExceededOnChange = (
  ctx: ProductModificationsModelContext,
  event: ProductModificationsModelEvent,
) => {
  const {
    defaults,
    active: activeIngredientsModifications,
    maxModifications,
  } = ctx;
  const { type } = event;

  const isAdditionEvent =
    type === 'ADD_INGREDIENT_MODIFICATION' ||
    type === 'INCREASE_DRESSING_PORTION_NUMBER';
  const isRemovalEvent =
    type === 'REMOVE_INGREDIENT_MODIFICATION' ||
    type === 'REMOVE_INGREDIENT_MODIFICATION_COMPLETELY' ||
    type === 'DECREASE_DRESSING_PORTION_NUMBER';

  const ingredientModification =
    (isAdditionEvent || isRemovalEvent) && event.ingredientModification;

  if (!ingredientModification) return false;

  const updatedActives = isAdditionEvent
    ? withActiveModification({
        ingredientsModifications: activeIngredientsModifications,
        ingredientModification,
      })
    : withoutActiveModification({
        ingredientsModifications: activeIngredientsModifications,
        ingredientModification,
      });

  const computedModifications = getComputedModifications(
    defaults,
    updatedActives,
  );

  return checkIfMaxModificationLimitExceeded({
    ...computedModifications,
    maxModifications,
  });
};

export const checkIfMaxModificationLimitExceeded = (
  ctx: Partial<ProductModificationsModelContext>,
) => {
  const {
    additions = [],
    removals = [],
    substitutions = [],
    maxModifications = 0,
  } = ctx;

  // We have no limit to the number of modifications we can have
  if (maxModifications === -1) return false;

  const potentialModificationCount = getNumberOfModifications(
    additions,
    removals,
    substitutions,
  );

  return potentialModificationCount > maxModifications;
};

export const checkIfMaxKindLimitExceeded = (
  ctx: ProductModificationsModelContext,
  event: ProductModificationsModelEvent,
) => {
  const ingredientModification =
    (event.type === 'ADD_INGREDIENT_MODIFICATION' ||
      event.type === 'INCREASE_DRESSING_PORTION_NUMBER') &&
    event.ingredientModification;

  if (!ingredientModification) return false;

  const { active, limitations } = ctx;

  const { kindMaxLimit } = getModificationKindLimit(
    ingredientModification.kind ?? '',
    limitations,
  );

  const activeKindsCount = getActiveCountByKind(active);
  const kindCount = activeKindsCount[ingredientModification.kind ?? ''];

  return kindCount === kindMaxLimit;
};

export const checkIfMinLimitExceeded = (
  ctx: ProductModificationsModelContext,
) => {
  const { active, limitations } = ctx;

  return getUnderTheLimitKinds(active, limitations).length > 0;
};

export const checkIfModificationIsInStock = (
  ctx: ProductModificationsModelContext,
  event: ProductModificationsModelEvent,
) => {
  const newIngredientModification =
    event.type === 'ADD_INGREDIENT_MODIFICATION' &&
    event.ingredientModification;

  if (!newIngredientModification) return false;

  return !newIngredientModification.outOfStock;
};

export const checkIfModificationIsSelected = (
  ctx: ProductModificationsModelContext,
  ingredientModification: IngredientModificationWithQuantity,
) => {
  return ctx.active.some(
    (active) => active.ingredient.id === ingredientModification.ingredient.id,
  );
};

export const checkIfContainsOutOfStockIngredients = (
  ctx: ProductModificationsModelContext,
) => {
  return getOutOfStockIngredientsNames(ctx).length > 0;
};

export const checkIfMultipleQtyForKindExceeded = (
  ctx: ProductModificationsModelContext,
  event: ProductModificationsModelEvent,
) => {
  const newIngredientModification =
    event.type === 'ADD_INGREDIENT_MODIFICATION' &&
    event.ingredientModification;

  if (!newIngredientModification) return false;

  const { kind } = newIngredientModification;

  const isAlreadySelected = checkIfModificationIsSelected(
    ctx,
    newIngredientModification,
  );
  const isMultipleQtyForKindExceeded =
    isAlreadySelected && !checkIfMultipleQtyForKindAllowed(ctx, kind);

  return isMultipleQtyForKindExceeded;
};

export const checkIfMultipleQtyForKindAllowed = (
  ctx: ProductModificationsModelContext,
  kind: string | null,
) => {
  const ingredientKind = (kind ?? '') as keyof FilteredIngredientModifications;
  const kindCtx = ctx.ingredientsModifications?.[ingredientKind];

  return Boolean(kindCtx?.allowMultipleQuantity);
};

export const checkIfBreadIsAvailable = (
  ctx: ProductModificationsModelContext,
) => {
  if (!ctx?.ingredientsModifications) return false;

  return ctx.ingredientsModifications?.bread?.modifications?.length > 0;
};

// ─── OTHER ─────────────────────────────────────────────────────────── ───────────

export const getUnderTheLimitKinds = (
  active: IngredientsModificationsWithQuantities,
  limitations: IngredientModificationLimitations,
) => {
  const activeKindsCount = getActiveCountByKind(active);

  return Object.entries(limitations).reduce<readonly string[]>(
    (state, [kind, limitation]) => {
      if (limitation.min <= Number(activeKindsCount[kind] ?? 0)) return state;

      return [...state, kind];
    },
    [],
  );
};

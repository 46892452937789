import React from 'react';
import { StyleSheet, View } from 'react-native';

import type { ContainerProps } from './Rating.types';

export const RatingContainer = React.memo((props: ContainerProps) => {
  const { minValue, value, starCount, children } = props;

  return (
    <View
      testID="sg-rating"
      accessibilityRole="radiogroup"
      accessibilityValue={{
        min: minValue,
        now: value,
        max: starCount,
      }}
      style={styles.container}
    >
      {children}
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
});

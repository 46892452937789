import React from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { Container, theme } from '@sg/garnish';

export const HomeContainerXS = (props: HomeContainerProps) => {
  const screenDimensions = useWindowDimensions();

  const fullScreenHeight = useStyle(
    () => ({ minHeight: screenDimensions.height }),
    [screenDimensions.height],
  );

  return (
    <View style={[styles.containerXS, fullScreenHeight]}>{props.children}</View>
  );
};

export const HomeContentWrapperXS = (props: HomeContainerProps) => {
  const { top } = useSafeAreaInsets();

  const safeAreaAdjustedPaddingTop = useStyle(
    () => ({
      paddingTop: Math.max(theme.spacing['6'] - top, 0),
    }),
    [top],
  );

  return (
    <Container style={[styles.contentWrapper, safeAreaAdjustedPaddingTop]}>
      {props.children}
    </Container>
  );
};

export const HomeContainerSM = (props: HomeContainerProps) => {
  return <Container style={styles.containerSM}>{props.children}</Container>;
};

const styles = StyleSheet.create({
  containerXS: {
    flex: 1,
    backgroundColor: theme.colors.SPINACH,
  },
  containerSM: {
    flex: 1,
  },
  contentWrapper: {
    paddingBottom: theme.spacing['2'],
  },
});

type HomeContainerProps = {
  children: React.ReactNode;
};

import React, { type ReactNode } from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, Image, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainEmptyViewContainer = (
  props: OrderAgainEmptyViewContainerProps,
) => {
  const { children, illustrationSrc, illustrationAlt } = props;

  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    match([styles.containerXS, styles.containerSM]),
  ];
  const illustrationStyles = match([
    styles.illustrationXS,
    styles.illustrationSM,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles}>
      <Image
        contentFit="contain"
        source={illustrationSrc}
        style={illustrationStyles}
        aria-label={illustrationAlt}
        accessibilityIgnoresInvertColors
      />

      <BodyText sizeMatch={['16']} style={styles.text}>
        {children}
      </BodyText>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: theme.spacing['20'],
  },
  containerXS: {
    paddingHorizontal: theme.spacing['10'],
  },
  containerSM: {
    paddingHorizontal: theme.spacing['16'],
  },
  illustrationXS: {
    width: 200,
    height: 200,
  },
  illustrationSM: {
    width: 240,
    height: 240,
  },
  text: {
    marginTop: theme.spacing['2'],
    textAlign: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderAgainEmptyViewContainerProps = {
  illustrationSrc: string;
  illustrationAlt: string;
  children: ReactNode;
};

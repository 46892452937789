import { noop } from '@sg/garnish';

import type { LocalOrder } from './useLocalOrderHistory.types';

// Mocked version for web usage.
export const useLocalOrderHistory = () => {
  const orderHistory: readonly LocalOrder[] = [];
  const addToLocalOrderHistory = noop;
  const updateLocalOrderHistory = noop;

  return { orderHistory, addToLocalOrderHistory, updateLocalOrderHistory };
};

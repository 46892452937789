import {
  MenuContentError,
  MenuContentLoading,
  MenuContentReady,
} from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuContent = {
  Error: MenuContentError,
  Loading: MenuContentLoading,
  Ready: MenuContentReady,
};

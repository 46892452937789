import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, theme, useResponsive } from '@sg/garnish';

import {
  SupportedOrganizationLinkFooterActions,
  type useOrganizationLinksFooterContentfulData,
} from '@order/hooks';

import { OrganizationLink } from '../../OrganizationLink';

export const OrganizationLinksFooterSection = (props: SectionProps) => {
  const { section } = props;

  const { match } = useResponsive();

  return (
    <View style={match([styles.xsMargin, undefined])}>
      <BodyText style={styles.title}>{section.name}</BodyText>

      {section.links.map((link) => {
        const Link = getOrganizationLink(link);

        return <Link key={link.label} link={link} />;
      })}
    </View>
  );
};

// ─── Utils ───────────────────────────────────────────────────────────────

function getOrganizationLink(link: SectionProps['section']['links'][0]) {
  switch (link.specialHandler) {
    case SupportedOrganizationLinkFooterActions.Kustomer: {
      return OrganizationLink.Kustomer;
    }

    default: {
      return OrganizationLink;
    }
  }
}

// ─── Types ───────────────────────────────────────────────────────────────

type SectionProps = Readonly<{
  section: ReturnType<
    typeof useOrganizationLinksFooterContentfulData
  >['sections'][0];
}>;

// ─── Styles ──────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  title: {
    marginBottom: theme.spacing['4'],
    textTransform: 'uppercase',
  },
  xsMargin: {
    marginBottom: theme.spacing['4'],
  },
});

import {
  ScrollspyNav,
  ScrollspyNavItem,
  ScrollspyScrollView,
  ScrollspyTargetView,
} from './components';

export const Scrollspy = {
  ScrollView: ScrollspyScrollView,
  TargetView: ScrollspyTargetView,
  Nav: ScrollspyNav,
  NavItem: ScrollspyNavItem,
};

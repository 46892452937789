import { useMemo } from 'react';

import type { Address } from '@order/graphql';

import {
  useReorderDeliveryRestaurantDataQuery,
  useReorderRestaurantDataQuery,
} from '../GraphQL/ReorderData.generated';
import type { ReorderRestaurant } from '../useReorderIfRequired.types';

/**
 * Resolves target restaurant's necessary fields.
 */
export const useRestaurantForReorder = (
  restaurantSlug: ReorderRestaurant['slug'] | undefined,
  addressId: Address['id'] | undefined,
) => {
  const shouldPauseRestaurantQuery = Boolean(addressId) || !restaurantSlug;
  const shouldPauseDeliveryRestaurantQuery = !addressId;

  // ─────────────────────────────────────────────────────────────────

  const [{ data: restaurantData }] = useReorderRestaurantDataQuery({
    variables: { id: restaurantSlug ?? '' },
    requestPolicy: 'cache-first',
    pause: shouldPauseRestaurantQuery,
  });
  const [{ data: deliveryRestaurantSlug }] =
    useReorderDeliveryRestaurantDataQuery({
      variables: { input: { addressId } },
      requestPolicy: 'cache-first',
      pause: shouldPauseDeliveryRestaurantQuery,
    });

  // ─────────────────────────────────────────────────────────────────

  const deliveryLocationData = deliveryRestaurantSlug?.locationByAddress;
  const deliveryRestaurantData =
    deliveryLocationData?.__typename === 'Location'
      ? deliveryLocationData.restaurant
      : undefined;
  const isDeliveryRestaurant = Boolean(addressId);

  const restaurant = isDeliveryRestaurant
    ? deliveryRestaurantData
    : restaurantData?.restaurant;

  // ─────────────────────────────────────────────────────────────────

  return useMemo<ReorderRestaurant | undefined>(() => {
    if (!restaurant?.id) return;

    return {
      id: restaurant?.id,
      slug: restaurant?.slug,
      name: restaurant?.name,
      isOutpost: restaurant?.isOutpost,
      deliveryFee: restaurant?.deliveryFee,
      isDelivery: isDeliveryRestaurant,
    };
  }, [
    restaurant?.id,
    restaurant?.slug,
    restaurant?.name,
    restaurant?.isOutpost,
    restaurant?.deliveryFee,
    isDeliveryRestaurant,
  ]);
};

import React from 'react';
import { type RouteProp, useRoute } from '@react-navigation/native';
import type { Asset } from 'contentful';
import { useResponsive } from '@sg/garnish';

import { NotFoundView } from '@order/components';

import type { GiftCardsTabStackParamList } from '../../navigation/AppNavigation.props';
import {
  GiftCardRedemptionContainer,
  GiftCardRedemptionContent,
  GiftCardRedemptionLoadingPlaceholder,
} from './components';
import {
  useGiftCardRedemptionCardImg,
  useGiftCardRedemptionDetails,
} from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardRedemptionScreen = () => {
  const route =
    useRoute<RouteProp<GiftCardsTabStackParamList, 'GiftCardRedemption'>>();
  const { giftCardId = '' } = route.params ?? {};

  // ─── Remote Data ─────────────────────────────────────────────────────

  const redemptionData = useGiftCardRedemptionDetails({
    giftCardId,
    pause: !giftCardId,
  });
  const redemptionImgData = useGiftCardRedemptionCardImg({
    assetId: redemptionData.data?.assetId ?? '',
    pause: !giftCardId || !redemptionData.data?.assetId,
  });

  const isFetchingRemoteData =
    redemptionData.isFetching || redemptionImgData.isFetching;

  // ─────────────────────────────────────────────────────────────────────

  if (isFetchingRemoteData) {
    return <GiftCardRedemptionScreenLoading />;
  }

  if (!giftCardId || redemptionData.hasError || !redemptionData.data) {
    return <GiftCardRedemptionScreenError />;
  }

  // ─────────────────────────────────────────────────────────────────────

  const {
    balance,
    cardRegCode,
    redeemId,
    recipientMessage,
    cardNumber,
    senderName,
    recipientName,
  } = redemptionData.data;
  const giftCardImage = redemptionImgData.data;

  return (
    <GiftCardRedemptionScreenReady
      cardNumber={cardNumber}
      cardRegCode={cardRegCode}
      redeemId={redeemId}
      balance={balance}
      recipientMessage={recipientMessage}
      giftCardImage={giftCardImage}
      senderName={senderName}
      recipientName={recipientName}
    />
  );
};

// ─── Variations ──────────────────────────────────────────────────────────────

const GiftCardRedemptionScreenReady = (
  props: GiftCardRedemptionScreenReadyProps,
) => {
  const {
    recipientMessage,
    balance,
    giftCardImage,
    cardNumber,
    cardRegCode,
    redeemId,
    senderName,
    recipientName,
  } = props;

  return (
    <GiftCardRedemptionContainer>
      <GiftCardRedemptionContent
        recipientMessage={recipientMessage}
        balance={balance}
        giftCardImage={giftCardImage}
        cardNumber={cardNumber}
        cardRegCode={cardRegCode}
        redeemId={redeemId}
        senderName={senderName}
        recipientName={recipientName}
      />
    </GiftCardRedemptionContainer>
  );
};

const GiftCardRedemptionScreenLoading = () => {
  return (
    <GiftCardRedemptionContainer>
      <GiftCardRedemptionLoadingPlaceholder />
    </GiftCardRedemptionContainer>
  );
};

const GiftCardRedemptionScreenError = () => {
  const { currentBreakpoint } = useResponsive();

  return <NotFoundView withoutFooter={currentBreakpoint.isXS} />;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardRedemptionScreenReadyProps = {
  balance: number;
  cardNumber: string;
  cardRegCode: string;
  recipientMessage?: string | null;
  redeemId: string;
  senderName: string;
  recipientName: string;
  giftCardImage?: {
    id: string;
    title: string;
    cover: Asset;
  };
};

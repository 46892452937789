import * as DateFns from 'date-fns';

import { FlattenedOrderStatuses } from '@order/graphql';
import { ignoreTimezone } from '@order/utils';

import { getOrderTime } from './getOrderTime';

/**
 * Orders are considered late if their completed time is 10mins in the past.
 */
export const checkIfLateOrder = ({
  order,
  flattenedOrderStatus = FlattenedOrderStatuses.Failed,
}: IsOrderLateProps) => {
  const canBeLate = !['completed', 'failed'].includes(flattenedOrderStatus);

  const orderTime = order ? getOrderTime(order) ?? '' : '';
  const orderTimeDate = orderTime ? ignoreTimezone(orderTime) : undefined;

  const tenMinsAgo = DateFns.subMinutes(new Date(), 10);
  const isLate = orderTimeDate
    ? DateFns.isBefore(orderTimeDate, tenMinsAgo)
    : false;

  return canBeLate && isLate;
};

type IsOrderLateProps = Readonly<{
  order?: {
    wantedTime?: string | null;
    deliveryOrderDetail?: {
      estimatedDeliveryTime?: string | null;
    } | null;
  };
  flattenedOrderStatus?: FlattenedOrderStatuses;
}>;

import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { type TextInput } from 'react-native';

import {
  TextField as GarnishTextField,
  TextFieldPassword as GarnishTextFieldPassword,
  TextFieldPhoneNumber as GarnishTextFieldPhoneNumber,
  TextFieldWithBackground as GarnishTextFieldWithBackground,
  TextFieldZipCode as GarnishTextFieldZipCode,
} from '../TextField';
import type { ControlledProps, FormProps } from './types';

type ComponentProps = React.ComponentProps<typeof GarnishTextField>;
type OmittedProps = ControlledProps | 'noticePalette';
type Props = FormProps & Omit<ComponentProps, OmittedProps>;

export const TextField = forwardRef<TextInput, Props>(
  ({ name, control, rules, notice, onChangeText, ...rest }, ref) => {
    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { invalid, error },
        }) => {
          const handleTextChange = (currentValue: string) => {
            onChange(currentValue);
            onChangeText?.(currentValue);
          };

          return (
            <GarnishTextField
              ref={ref}
              value={value}
              invalid={invalid}
              notice={notice ?? error?.message}
              noticePalette={invalid ? 'caution' : undefined}
              onBlur={onBlur}
              onChangeText={handleTextChange}
              autoCorrect={false}
              autoCapitalize="none"
              {...rest}
            />
          );
        }}
      />
    );
  },
);

export const TextFieldWithBackground = forwardRef<TextInput, Props>(
  ({ name, control, rules, notice, onChangeText, ...rest }, ref) => {
    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { invalid, error },
        }) => {
          const handleTextChange = (currentValue: string) => {
            onChange(currentValue);
            onChangeText?.(currentValue);
          };

          return (
            <GarnishTextFieldWithBackground
              ref={ref}
              value={value}
              invalid={invalid}
              notice={notice ?? error?.message}
              noticePalette={invalid ? 'caution' : undefined}
              onBlur={onBlur}
              onChangeText={handleTextChange}
              autoCorrect={false}
              autoCapitalize="none"
              {...rest}
            />
          );
        }}
      />
    );
  },
);

export const TextFieldPassword = forwardRef<
  TextInput,
  Props & Readonly<{ toggleTestID?: string }>
>(({ name, control, rules, notice, ...rest }, ref) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { invalid, error },
      }) => (
        <GarnishTextFieldPassword
          ref={ref}
          value={value}
          invalid={invalid}
          notice={notice ?? error?.message}
          noticePalette={invalid ? 'caution' : undefined}
          onBlur={onBlur}
          onChangeText={onChange}
          {...rest}
        />
      )}
    />
  );
});

export const TextFieldPhoneNumber = forwardRef<TextInput, Props>(
  ({ name, control, rules, notice, ...rest }, ref) => {
    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { invalid, error },
        }) => (
          <GarnishTextFieldPhoneNumber
            ref={ref}
            value={value}
            invalid={invalid}
            notice={notice ?? error?.message}
            noticePalette={invalid ? 'caution' : undefined}
            onBlur={onBlur}
            onChangeText={onChange}
            {...rest}
          />
        )}
      />
    );
  },
);

export const TextFieldZipCode = forwardRef<TextInput, Props>(
  ({ name, control, rules, notice, ...rest }, ref) => {
    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { invalid, error },
        }) => (
          <GarnishTextFieldZipCode
            ref={ref}
            value={value}
            invalid={invalid}
            notice={notice ?? error?.message}
            noticePalette={invalid ? 'caution' : undefined}
            onBlur={onBlur}
            onChangeText={onChange}
            {...rest}
          />
        )}
      />
    );
  },
);

export const TextFieldUppercase = forwardRef<TextInput, Props>(
  ({ name, control, rules, notice, ...rest }, ref) => {
    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { invalid, error },
        }) => (
          <GarnishTextField
            ref={ref}
            value={value?.toUpperCase()}
            invalid={invalid}
            notice={notice ?? error?.message}
            noticePalette={invalid ? 'caution' : undefined}
            onBlur={onBlur}
            onChangeText={onChangeUppercaseText(onChange)}
            autoCorrect={false}
            autoCapitalize="none"
            {...rest}
          />
        )}
      />
    );
  },
);

const onChangeUppercaseText =
  (onChange: (text: string) => void) => (text: string) => {
    onChange(text.toUpperCase());
  };

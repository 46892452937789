import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { AspectRatioImage, getCloudinaryUrl } from '../../../Image';

// ─────────────────────────────────────────────────────────────────────────────

export const IngredientCardImage = (props: IngredientCardImageProps) => {
  const { source, imageWidth = 230, ...restProps } = props;

  const formattedSource = getCloudinaryUrl(source, {
    crop: 'scale',
    width: imageWidth,
  });

  return (
    <AspectRatioImage
      aspectRatioX={26}
      aspectRatioY={23}
      source={formattedSource}
      style={styles.image}
      {...restProps}
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  image: {
    overflow: 'hidden',
    borderRadius: theme.radius.large,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type IngredientCardImageProps = {
  source: string;
  imageWidth?: number;
} & Omit<
  ComponentProps<typeof AspectRatioImage>,
  'aspectRatioX' | 'aspectRatioY'
>;

import React, { useCallback } from 'react';
import type { ViewProps } from 'react-native';
import { theme, VStack } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type { AddressFragment } from '../../graphql/types';
import { AccountScreenBorderedSection } from '../Account';
import { AddressListItem } from './AddressListItem';

export type AddressListProps = Readonly<{
  items: readonly AddressFragment[];
  onSelectItem: (addressId: string) => void;
  onSelectAdd: () => void;
}> &
  ViewProps;

export const AddressList = ({
  items,
  onSelectItem,
  onSelectAdd,
}: AddressListProps): React.ReactElement => {
  const { t } = useLocalizationContext();

  const getTitle = useCallback(({ name, street }: AddressFragment): string => {
    if (name) return name;

    if (street) return street;

    return '';
  }, []);

  const getSubtitle = useCallback(
    ({
      street,
      secondaryStreet,
      city,
      state,
      zipCode,
    }: AddressFragment): string => {
      return [
        street,
        secondaryStreet,
        [city, [state, zipCode].filter(Boolean).join(' ')]
          .filter(Boolean)
          .join(', '),
      ]
        .filter(Boolean)
        .join('\n');
    },
    [],
  );

  return (
    <AccountScreenBorderedSection>
      <VStack gap={0} hasDivider={true} dividerColor={theme.colors.LIGHT}>
        {items.map((address) => (
          <AddressListItem
            key={address.id}
            testID={`addresses.${address.name}-card-pressable`}
            accessibilityLabel={t('addresses.address-label', {
              address: address.name,
            })}
            iconName={
              // eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10
              address.name
                ? // eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10
                  address.name === 'home'
                  ? 'IconHomeStrokeSm'
                  : address.name === 'work'
                    ? 'IconWorkStrokeSm'
                    : 'IconCustomStrokeSm'
                : 'IconPickupStrokeSm'
            }
            title={getTitle(address)}
            subtitle={getSubtitle(address)}
            onPress={() => {
              onSelectItem(address.id);
            }}
          />
        ))}
        <AddressListItem
          testID="account.edit-address.add"
          accessibilityLabel={t('addresses.add-new')}
          accessibilityHint={t('addresses.title')}
          iconName="IconAdd"
          title={t('addresses.add-new')}
          onPress={onSelectAdd}
        />
      </VStack>
    </AccountScreenBorderedSection>
  );
};

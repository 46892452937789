import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { DeliveryPreferenceType } from '@order/graphql';

export const useDeliveryPreferences = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => [
      {
        value: DeliveryPreferenceType.LeaveAtDoor,
        label: formatMessage(messages.leaveAtDoor),
        accessibilityLabel: formatMessage(messages.leaveAtDoorA11y),
      },
      {
        value: DeliveryPreferenceType.Door,
        label: formatMessage(messages.meetAtDoor),
        accessibilityLabel: formatMessage(messages.meetAtDoorA11y),
      },
      {
        value: DeliveryPreferenceType.Curbside,
        label: formatMessage(messages.meetOutside),
        accessibilityLabel: formatMessage(messages.meetOutsideA11y),
      },
    ],
    [formatMessage],
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  leaveAtDoor: {
    defaultMessage: 'Leave at door',
    description: 'Delivery Preferences | Leave at door',
  },
  meetAtDoor: {
    defaultMessage: 'Meet at door',
    description: 'Delivery Preferences | Meet at door',
  },
  meetOutside: {
    defaultMessage: 'Meet outside',
    description: 'Delivery Preferences | Meet outside',
  },
  leaveAtDoorA11y: {
    defaultMessage: 'Drop-off option, leave at door',
    description: 'Delivery Preferences | Leave at door a11y',
  },
  meetAtDoorA11y: {
    defaultMessage: 'Drop-off option, meet at door',
    description: 'Delivery Preferences | Meet at door a11y',
  },
  meetOutsideA11y: {
    defaultMessage: 'Drop-off option, meet outside',
    description: 'Delivery Preferences | Meet outside a11y',
  },
});

import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { TYPE_CONFIG } from '@garnish/constants';

import { IconLink } from '../../Icon';
import { BodyText } from '../../Text';
import { TextField } from '../../TextField';

// ────────────────────────────────────────────────────────────────────────────

export const LineItemEditableHeader = (props: LineItemEditableHeaderProps) => {
  const { productName, isDisabled, isEditing, unsetEditing, onNameChange } =
    props;

  const [editableName, setEditableName] = useState(productName);

  const resetName = useCallback(() => {
    setEditableName(productName);
    unsetEditing();
  }, [productName, unsetEditing]);

  const handleOnSubmit = useCallback(
    (updatedName: string) => {
      onNameChange(updatedName);
      unsetEditing();
    },
    [onNameChange, unsetEditing],
  );

  if (isEditing) {
    return (
      <TextField
        hideNoticeSpace
        style={styles.editableHeaderField}
        clearButtonType="icon"
        submitButtonIcon="IconCheck"
        value={editableName}
        disabled={isDisabled}
        onChangeText={setEditableName}
        onClear={resetName}
        onSubmit={handleOnSubmit}
      />
    );
  }

  return (
    <View style={styles.editableHeader}>
      <BodyText size={4} sizeMatch={['18']} numberOfLines={2}>
        {productName}
      </BodyText>
    </View>
  );
};

// ─── Compound Components ────────────────────────────────────────────────────

const LineItemEditableHeaderIcon = (props: LineItemEditableHeaderIconProps) => {
  const { isDisabled, isModifiable, setEditing } = props;

  if (!isModifiable) return null;

  return (
    <IconLink
      name="IconEdit2"
      width={20}
      height={20}
      disabled={isDisabled}
      onPress={setEditing}
    />
  );
};

// eslint-disable-next-line functional/immutable-data
LineItemEditableHeader.Icon = LineItemEditableHeaderIcon;

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  editableHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 35,
  },
  editableHeaderField: {
    ...TYPE_CONFIG.BODY['18'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LineItemEditableHeaderProps = {
  productName: string;
  isDisabled: boolean;
  isEditing: boolean;
  unsetEditing: () => void;
  onNameChange: (updatedName: string) => void;
};

type LineItemEditableHeaderIconProps = {
  isModifiable: boolean;
  isDisabled: boolean;
  setEditing: () => void;
};

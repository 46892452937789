/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-unused-styles */

import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, type TextStyle, View, type ViewStyle } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  LoadingAnimation,
  ScrollViewWithHeaderTitle,
  Tabs,
  theme,
  VStack,
} from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { SignedOutView, useHeaderRight } from '@order/components';
import { ScanAtCheckout } from '@order/features/loyalty';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry } from '@order/Telemetry';

import {
  useKustomerAvailability,
  useKustomerContactUsHandler,
} from '../../integrations/kustomer';
import { useNavigateToMainTabs } from '../../navigation';
import { ScanInStoreCta } from './components/ScanInStoreCta';
import { useScanInStoreTabs, useUserRewardsPoints } from './hooks';
import { useEarnRedeemContent } from './hooks';
import { ScanInStoreEarnAndRedeem, ScanInStorePay } from './tabs';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanAtCheckoutScreen = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { formatMessage } = useIntl();
  const isLoggedIn = useIsLoggedIn();
  const isKustomerAvailable = useKustomerAvailability();
  const { handleContactUs, handlingContactUs } = useKustomerContactUsHandler(
    isKustomerAvailable,
    'account',
  );
  const {
    activeTabItemId,
    earnAndRedeemTab,
    payTab,
    tabs,
    setActiveTabItemId,
  } = useScanInStoreTabs();
  const { fetching, loyaltyId, availablePoints } = useUserRewardsPoints();
  const content = useEarnRedeemContent();
  const { track } = useTelemetry();

  const isSgRewardsPremiumEnabled = useFeatureFlag(
    'CELS-3031-sg-rewards-premium-enabled',
  );

  const handleSeeRewardOptionsPress = () => {
    setIsModalVisible(true);
    track('scan.seerewards.cta_tapped');
  };
  // ─── Navigation Helpers ──────────────────────────────────────────────

  const navigation = useNavigation();
  const navigateToMainTabs = useNavigateToMainTabs();

  const navigateToLoyalty = useCallback(() => {
    track('seerewards.sgrewards.cta_tapped');
    void navigateToMainTabs('LoyaltyTab', {
      screen: 'SweetpassHome',
    });
  }, [navigateToMainTabs, track]);

  const navigationHeaderRightItems = useMemo<NavigationHeaderRightItems>(
    () => [
      {
        key: 'account.gift-code.header.close-btn',
        icon: 'IconClose',
        palette: isSgRewardsPremiumEnabled ? 'premium' : 'muted',
        color: isSgRewardsPremiumEnabled
          ? theme.colors.NEUTRAL_6
          : theme.colors.NEUTRAL_1,
        width: 40,
        height: 40,
        accessibilityLabel: formatMessage(messages.close),
        onPress: navigation.goBack,
      },
    ],
    [formatMessage, isSgRewardsPremiumEnabled, navigation.goBack],
  );
  const navigationHeaderRight = useHeaderRight({
    items: navigationHeaderRightItems,
  });

  useLayoutEffect(() => {
    navigation.setOptions({ headerRight: navigationHeaderRight });
  }, [navigation, navigationHeaderRight]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const palette = isSgRewardsPremiumEnabled ? 'premium' : 'primary';

  const containerStyles = [styles.container, containerPaletteStyles[palette]];
  const textStyles = textPaletteStyles[palette];

  // ─────────────────────────────────────────────────────────────────────

  if (!isLoggedIn) {
    return (
      <View style={styles.container}>
        <SignedOutView />
      </View>
    );
  }

  if (fetching) {
    return <LoadingAnimation />;
  }

  return (
    <View testID="scan-at-checkout-container" style={containerStyles}>
      <ScrollViewWithHeaderTitle borderBottomColor={theme.colors.BLACK}>
        <ScanAtCheckout.HeaderTitle style={[styles.header, textStyles]}>
          <FormattedMessage {...messages.header} />
        </ScanAtCheckout.HeaderTitle>

        <Tabs.Bar
          tabs={tabs}
          activeTabId={activeTabItemId}
          onChange={setActiveTabItemId}
          palette={palette}
        />
        <Tabs.Panel
          isActive={activeTabItemId === earnAndRedeemTab.id}
          tabId={earnAndRedeemTab.id}
          panelId={earnAndRedeemTab.panelId}
        >
          {loyaltyId ? (
            <ScanInStoreEarnAndRedeem
              onPress={handleSeeRewardOptionsPress}
              title={formatMessage(messages.points, {
                rewards_points: availablePoints,
              })}
              loyaltyId={loyaltyId}
              palette={isSgRewardsPremiumEnabled ? 'premium' : 'darkGreen'}
            />
          ) : null}

          <ScanAtCheckout.HowItWorks.Container>
            <ScanAtCheckout.HowItWorks.Title style={textStyles}>
              <FormattedMessage {...messages.howItWorks} />
            </ScanAtCheckout.HowItWorks.Title>
            <VStack hasDivider gap={4}>
              {content.map((item) => (
                <ScanAtCheckout.HowItWorks.Item
                  key={item.title}
                  image={item.image}
                  description={item.title}
                  textStyle={textStyles}
                  tintColor={tintColorStyles[palette].tintColor}
                />
              ))}
            </VStack>
          </ScanAtCheckout.HowItWorks.Container>

          <ScanAtCheckout.Footer>
            <ScanAtCheckout.FooterText>
              <FormattedMessage {...messages.haveQuestions} />
            </ScanAtCheckout.FooterText>
            <ScanInStoreCta
              onPress={handleContactUs}
              palette="white"
              isLoading={handlingContactUs}
            >
              {formatMessage(messages.contactSupport)}
            </ScanInStoreCta>
          </ScanAtCheckout.Footer>
          <ScanAtCheckout.RewardOptionsModal
            onRequestClose={() => {
              setIsModalVisible(false);
            }}
            isVisible={isModalVisible}
            onPressClose={() => {
              setIsModalVisible(false);
            }}
            onPressButton={navigateToLoyalty}
          />
        </Tabs.Panel>
        <Tabs.Panel
          isActive={activeTabItemId === payTab.id}
          tabId={payTab.id}
          panelId={payTab.panelId}
        >
          <ScanInStorePay />
        </Tabs.Panel>
      </ScrollViewWithHeaderTitle>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  header: {
    defaultMessage: 'Scan at checkout',
    description: 'Earn + Redeem | Loyalty Rewards | Header',
  },
  contactSupport: {
    defaultMessage: 'Contact support',
    description: 'Earn + Redeem | Loyalty Rewards | Footer CTA',
  },
  haveQuestions: {
    defaultMessage: 'Have questions?',
    description: 'Earn + Redeem | Loyalty Rewards | Footer Text',
  },
  points: {
    defaultMessage: '{rewards_points} points',
    description: 'Earn + Redeem | Loyalty Rewards | Points',
  },
  howItWorks: {
    defaultMessage: 'How it works',
    description: 'Earn + Redeem | Loyalty Rewards | How it works Title',
  },
  close: {
    defaultMessage: 'Close',
    description: 'Earn + Redeem | Loyalty Rewards | Close',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    padding: theme.spacing['4'],
  },
});

const containerPaletteStyles = StyleSheet.create<
  Record<ScanScreenPalette, ViewStyle>
>({
  primary: {
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  premium: {
    backgroundColor: theme.colors.OLIVE,
  },
});

const textPaletteStyles = StyleSheet.create<
  Record<ScanScreenPalette, TextStyle>
>({
  primary: {
    color: theme.colors.GREEN_1,
  },
  premium: {
    color: theme.colors.LIGHT,
  },
});

const tintColorStyles = StyleSheet.create<
  Record<ScanScreenPalette, { tintColor: string }>
>({
  primary: { tintColor: '#0E150E' },
  premium: { tintColor: '#F8F7F0' },
});

export type ScanScreenPalette = 'primary' | 'premium';

// ─── Types ───────────────────────────────────────────────────────────────────

type NavigationHeaderRightItems = Parameters<typeof useHeaderRight>[0]['items'];

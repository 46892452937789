import React from 'react';
import type { AddressType } from '@sg/garnish';
import { HStack } from '@sg/garnish';

import { useCustomer } from '@order/Customer';
import type { DeliveryOrderDetail, Restaurant, Vendor } from '@order/graphql';
import { useLastValidOrder } from '@order/Order';
import { useTelemetry } from '@order/Telemetry';

import { useReorderState } from '../../screens/ReorderingScreen/hooks';
import { EvergreenChannel } from './EvergreenChannel';
import { useDynamicStyles } from './FeaturedChannels.styles';
import { LoadingChannels } from './LoadingChannels';
import { PrimaryChannel } from './PrimaryChannel';
import { SecondaryChannel } from './SecondaryChannel';

export const FeaturedChannels = React.memo(
  ({
    deliveryRestaurant,
    deliveryVendor,
    isLoadingOrders,
    onNavigateToLocations,
    onFeaturedChannelSelection,
  }: FeaturedChannelsProps) => {
    const { track } = useTelemetry();
    const { reordering: isReordering } = useReorderState();
    const { cardGap, containerMarginStyle } = useDynamicStyles();

    // ─── Customer Addresses ─────────────────────────────────────────────────
    const { customer } = useCustomer();
    const customerAddresses = customer?.addresses ?? [];

    // ─── Last Order & Type ──────────────────────────────────────────────────
    const { lastValidOrder: lastPlacedOrder } = useLastValidOrder();
    const { lastOrder, lastOrderType, lastOrderName } =
      getLastOrderInfo(lastPlacedOrder);

    // ─── Order Payload ──────────────────────────────────────────────────────
    const customerAddress = customerAddresses.find(
      (address) => address.id === lastOrder?.deliveryOrderDetail?.address?.id,
    );
    const restaurantId = lastOrder?.restaurant?.id ?? deliveryRestaurant?.id;
    const restaurantSlug =
      lastOrder?.restaurant?.slug ?? deliveryRestaurant?.slug;
    const restaurantName =
      lastOrder?.restaurant?.name ?? deliveryRestaurant?.name;
    const deliveryOrderDetail = React.useMemo(
      () =>
        lastOrderType === 'delivery'
          ? lastOrder?.deliveryOrderDetail ?? {
              address: customerAddress,
              vendor: deliveryVendor?.name,
              vendorRestaurantId: deliveryVendor?.restaurantId,
            }
          : undefined,
      [
        lastOrderType,
        lastOrder?.deliveryOrderDetail,
        customerAddress,
        deliveryVendor?.name,
        deliveryVendor?.restaurantId,
      ],
    );

    // ──── Primary Callback ──────────────────────────────────────────────────
    const onOrder = React.useCallback(() => {
      track('home.primary_channel', {
        channel: lastOrderType,
        locationPrePopulated: true,
      });

      onFeaturedChannelSelection({
        orderType: lastOrderType,
        restaurantId,
        restaurantSlug,
        restaurantName,
        deliveryOrderDetail,
      });
    }, [
      lastOrderType,
      restaurantId,
      restaurantSlug,
      restaurantName,
      deliveryOrderDetail,
      onFeaturedChannelSelection,
      track,
    ]);

    // ──── Secondary Callback ────────────────────────────────────────────────
    const onSeeMoreLocations = React.useCallback(() => {
      track('home.secondary_channel', {});
      onNavigateToLocations();
    }, [track, onNavigateToLocations]);

    // ──── Evergreen Callback ────────────────────────────────────────────────
    const onEvergreenOrderNow = React.useCallback(() => {
      track('home.evergreen_channel');
      onNavigateToLocations();
    }, [track, onNavigateToLocations]);

    // ─── Loading Orders - Placeholders ──────────────────────────────────────
    if (isLoadingOrders) {
      return <LoadingChannels />;
    }

    // ─── No Last Valid Order - Evergreen Channel ────────────────────────────
    if (!lastPlacedOrder) {
      return <EvergreenChannel onOrderNow={onEvergreenOrderNow} />;
    }

    // ─── Valid Orders - Primary & Secondary Channels ────────────────────────
    return (
      <HStack itemsPerRow={2} gap={cardGap} style={containerMarginStyle}>
        <PrimaryChannel
          name={lastOrderName}
          orderType={lastOrderType}
          isLoading={Boolean(isReordering)}
          onOrder={onOrder}
        />
        <SecondaryChannel onSeeMoreLocations={onSeeMoreLocations} />
      </HStack>
    );
  },
);

export const getLastOrderInfo = (
  lastOrder: ReturnType<typeof useLastValidOrder>['lastValidOrder'],
) => {
  // ─── Last Order Type ────────────────────────────────────────────────────
  const lastOrderTypeRaw = lastOrder?.orderType.toLowerCase();
  const lastOrderType: AddressType = (
    lastOrder?.restaurant?.isOutpost ? 'outpost' : lastOrderTypeRaw ?? 'pickup'
  ) as AddressType;

  // ─── Last Order Name ────────────────────────────────────────────────────
  const pickupOutpostName = lastOrder?.restaurant?.name ?? '';
  const deliveryAddressName =
    lastOrder?.deliveryOrderDetail?.address?.name ?? '';
  const lastOrderName =
    lastOrderType === 'delivery' ? deliveryAddressName : pickupOutpostName;

  return {
    lastOrder,
    lastOrderType,
    lastOrderName,
  };
};

// ────────────────── Types ──────────────────

type FeaturedChannelsProps = Readonly<{
  deliveryRestaurant?: Partial<Restaurant>;
  deliveryVendor?: Partial<Vendor>;
  isLoadingOrders: boolean;
  onNavigateToLocations: () => void;
  onFeaturedChannelSelection: (payload: {
    orderType: AddressType;
    restaurantId?: string;
    restaurantSlug?: string;
    restaurantName?: string;
    deliveryOrderDetail?: Partial<DeliveryOrderDetail>;
  }) => void;
}>;

import * as DateFns from 'date-fns';

import { ignoreTimezone } from '@order/utils';

import { getOrderTime } from './getOrderTime';

/**
 * Orders are considered expired if they are completed before the specified
 * number of hours (72hrs by default).
 */
export const checkIfExpiredOrder = (params: IsOrderExpiredParams) => {
  const { order, hoursInThePast = 72 } = params;

  const orderTime = order ? getOrderTime(order) ?? '' : '';
  const orderTimeDate = orderTime ? ignoreTimezone(orderTime) : undefined;

  if (!orderTimeDate) return false;

  const dateInThePast = DateFns.subHours(new Date(), hoursInThePast);

  return DateFns.isBefore(orderTimeDate, dateInThePast);
};

type IsOrderExpiredParams = Readonly<{
  order:
    | {
        wantedTime?: string | null;
        deliveryOrderDetail?: {
          estimatedDeliveryTime?: string | null;
        } | null;
      }
    | undefined;
  hoursInThePast?: number;
}>;

import React, { forwardRef, useCallback } from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { IngredientCard } from '@sg/garnish';

import { useDietaryRestrictions } from '@order/components';
import { useLocalizationContext } from '@order/Localization';

import type { IngredientModificationWithQuantity } from '../../types';
import {
  useIngredientModificationA11yLabels,
  useIngredientModificationCardTestID,
  useIngredientModificationQty,
} from './IngredientModificationCard.hooks';

export const IngredientModificationCard = forwardRef<
  View,
  IngredientModificationCardProps
>((props, ref) => {
  const {
    ingredientModification,
    testIdPrefix,
    isSelected,
    isUnavailable = false,
    isDisabled = false,
    accessibilityHint: customAccessibilityHint,
    style,
    onPress,
    onDecreaseQuantity,
  } = props;
  const { ingredient } = ingredientModification;
  const { name: ingredientName, asset, dietaryProperties } = ingredient;
  const getRestrictionNotice = useDietaryRestrictions();

  const { t } = useLocalizationContext();

  // ────────────────────────────────────────────────────────────────────

  const ingredientAssetUrl = asset.url;
  const ingredientQty = useIngredientModificationQty(ingredientModification);
  const testID = useIngredientModificationCardTestID(
    ingredientModification,
    testIdPrefix,
  );

  // ─── A11Y ───────────────────────────────────────────────────────────

  const { a11yLabel, a11yHint, decreaseQtyA11yLabel } =
    useIngredientModificationA11yLabels({
      ingredientModification,
      ingredientQty,
      isUnavailableIngredient: isDisabled || isUnavailable,
    });

  // ─── HELPERS ────────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    onPress?.(ingredientModification);
  }, [ingredientModification, onPress]);

  const handleOnDecreaseQuantity = useCallback(() => {
    onDecreaseQuantity?.(ingredientModification);
  }, [ingredientModification, onDecreaseQuantity]);

  // ────────────────────────────────────────────────────────────────────

  const wrapperStyle = [styles.wrapper, style];

  return (
    <View style={wrapperStyle}>
      <IngredientCard
        testID={testID}
        ref={ref}
        title={ingredientName}
        imgUrl={ingredientAssetUrl}
        imgAriaLabel={t('pdp.modifications.item-image.alt', { ingredientName })}
        isSelected={isSelected}
        isUnavailable={isUnavailable}
        isDisabled={isDisabled}
        quantity={ingredientQty}
        unavailableNotice={t('general.unavailable')}
        onPress={onPress ? handleOnPress : undefined}
        onDecreaseQuantity={
          onDecreaseQuantity ? handleOnDecreaseQuantity : undefined
        }
        accessibilityLabel={a11yLabel}
        accessibilityHint={customAccessibilityHint ?? a11yHint}
        decreaseQuantityAccessibilityLabel={decreaseQtyA11yLabel}
        restrictionsNotice={getRestrictionNotice(dietaryProperties)}
      />
    </View>
  );
});

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flexGrow: 1,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type IngredientModificationCardProps = Readonly<{
  ingredientModification: IngredientModificationWithQuantity;
  testIdPrefix?: string;
  isUnavailable?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  accessibilityHint?: ViewProps['accessibilityHint'];
  onPress?: (
    ingredientModification: IngredientModificationWithQuantity,
  ) => void;
  onDecreaseQuantity?: (
    ingredientModification: IngredientModificationWithQuantity,
  ) => void;
  style?: ViewProps['style'];
}>;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { FadeView, Icon, LoadingDots, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import {
  useKustomerAvailability,
  useKustomerContactUsHandler,
} from '../../../../../integrations/kustomer';
import { AccountMenuPrimaryItem } from '../AccountMenuPrimaryItem';

export const AccountContactUsOption = () => {
  const { t } = useLocalizationContext();

  const isKustomerAvailable = useKustomerAvailability();

  const { handleContactUs, handlingContactUs } = useKustomerContactUsHandler(
    isKustomerAvailable,
    'account',
  );

  return (
    <AccountMenuPrimaryItem
      testID="account.sidebar-tab.contact-us"
      label={t('account.sidebar.contact-us')}
      iconName="IconText"
      onPress={handleContactUs}
    >
      {isKustomerAvailable ? null : (
        <View style={styles.kustomerIcon}>
          <Icon name="IconShare2" />
        </View>
      )}
      <FadeView show={handlingContactUs} style={styles.kustomerIcon}>
        <LoadingDots />
      </FadeView>
    </AccountMenuPrimaryItem>
  );
};

const styles = StyleSheet.create({
  kustomerIcon: {
    marginLeft: theme.spacing['2'],
  },
});

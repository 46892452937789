import type { Entry, TagLink } from 'contentful';

import type { ContentfulContentTypeEntry } from '../contentful-content-type-entries-machine.types';

// ─── Entries ─────────────────────────────────────────────────────────────────

/**
 * Extracts required data from content type entry.
 */
export function extractContentTypeEntryData<ContentTypeFields>(
  entry: Entry<ContentTypeFields>,
): ContentfulContentTypeEntry<ContentTypeFields> {
  return {
    fields: entry?.fields,
    tags: generateTagsIDsList(entry.metadata.tags),
  };
}

// ─── Tags ────────────────────────────────────────────────────────────────────

function generateTagsIDsList(tags: readonly TagLink[]): string[] {
  return tags.map((tag) => tag.sys.id);
}

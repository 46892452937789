import React from 'react';
import { useResponsive } from '@sg/garnish';

import { CreditMenu } from './CreditMenu/CreditMenu';
import { CreditOverview } from './CreditOverview/CreditOverview';
import type { CreditSectionProps } from './CreditSectionProps';

export const CreditSection = (
  props: CreditSectionProps,
): React.ReactElement => {
  const { currentBreakpoint } = useResponsive();

  return currentBreakpoint.isXS ? (
    <CreditMenu {...props} />
  ) : (
    <CreditOverview {...props} />
  );
};

import React, { useCallback, useMemo, useRef } from 'react';
import type { AccessibilityProps, ViewProps } from 'react-native';
import { Pressable, StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../hooks';
import { webOnlyStyles } from '../../utils';
import { BodyText } from '../Text';
import { CHECKBOX_DISABLED_COLOR } from './Checkbox.constants';
import { CheckboxIcon } from './components';

// ────────────────────────────────────────────────────────────────────────────────

export const Checkbox = (props: CheckboxProps) => {
  const {
    checked,
    alignment = 'center',
    accessibilityLabel,
    isBoxed,
    disabled = false,
    children,
    onChange,
    onBlur,
    testID,
  } = props;

  // ─────────────────────────────────────────────────────────────────────

  const toggleRef = useRef(null);
  const { isHovered: hovered } = usePressableState(toggleRef);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleChange = useCallback(() => {
    if (disabled) {
      return;
    }

    onChange(!checked);
  }, [disabled, onChange, checked]);

  // ─────────────────────────────────────────────────────────────────────

  const checkboxState = useMemo<CheckboxState>(
    () => ({ hovered, checked, disabled }),
    [checked, disabled, hovered],
  );

  // ─── Styles ──────────────────────────────────────────────────────────

  const isAlignmentStart = alignment === 'start';

  const containerStyles = [
    styles.container,
    isAlignmentStart ? styles.containerAlignStart : styles.containerAlignCenter,
    containerWebStyles,

    isBoxed && styles.containerWithBox,
    isBoxed && checked ? styles.containerWithBoxSelected : undefined,
    isBoxed && hovered ? styles.containerWithBoxHover : undefined,
    isBoxed && disabled ? styles.containerWithBoxDisabled : undefined,
  ];

  const iconContainerStyles =
    isAlignmentStart && styles.iconContainerWithExtraSpacing;

  const contentContainerStyles = [
    styles.contentText,
    hovered && !isBoxed ? styles.contentTextHover : undefined,
    disabled && styles.contentTextDisabled,
    contentTextWebStyles,
  ];

  // ────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      ref={toggleRef}
      testID={testID}
      disabled={disabled}
      style={containerStyles}
      onBlur={onBlur}
      onPress={handleChange}
      role="checkbox"
      accessibilityRole="checkbox"
      aria-checked={checked}
      aria-label={accessibilityLabel}
    >
      <View style={iconContainerStyles}>
        <CheckboxIcon state={checkboxState} />
      </View>

      {children ? (
        <BodyText sizeMatch={['16']} style={contentContainerStyles}>
          {children}
        </BodyText>
      ) : null}
    </Pressable>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: theme.spacing['4'],
  },
  containerAlignCenter: {
    alignItems: 'center',
  },
  containerAlignStart: {
    alignItems: 'flex-start',
  },

  containerWithBox: {
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTEST,
    padding: theme.spacing['4'],
    borderRadius: theme.radius.medium,
  },
  containerWithBoxSelected: {
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTER,
  },
  containerWithBoxHover: {
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTER,
  },
  containerWithBoxDisabled: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },

  // NOTE: When the checkbox content aligns to "start," we must add a slight
  //       top spacing to the icon to match the text, which adds extra space
  //       due to line height.
  iconContainerWithExtraSpacing: {
    marginTop: theme.spacing['1'],
  },
  contentText: {
    flex: 1,
  },
  contentTextHover: {
    opacity: 0.75,
  },
  contentTextDisabled: {
    color: CHECKBOX_DISABLED_COLOR,
  },
});

const containerWebStyles = webOnlyStyles({
  userSelect: 'none',
  outlineColor: theme.colors.GRAY,
  outlineOffset: theme.spacing['1'],
  transition: `background-color ${theme.transitions.base}ms`,
});
const contentTextWebStyles = webOnlyStyles({
  transition: `opacity ${theme.transitions.base}ms`,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CheckboxProps = Readonly<{
  checked: boolean;
  alignment?: 'center' | 'start';
  disabled?: boolean;
  onChange: (value?: boolean) => void;
  onBlur?: () => void;
  testID?: string;
  bold?: boolean;
  children?: ViewProps['children'];

  /**
   * Applies extra spacing and background color to highlight checkbox.
   */
  isBoxed?: boolean;
}> &
  Pick<AccessibilityProps, 'accessibilityLabel'>;

type CheckboxState = {
  hovered: boolean;
  checked: boolean;
  disabled: boolean;
};

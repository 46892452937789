/* istanbul ignore file */

import type { ComponentProps } from 'react';
import React, { forwardRef, memo } from 'react';
import type { TextInput } from 'react-native';
import { TextField } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

// ────────────────────────────────────────────────────────────────────────────────

export const LocationSearchField = memo(
  forwardRef<TextInput, LocationSearchFieldProps>((props, ref) => {
    const {
      value,
      onSubmit,
      onChangeText,
      onClear,
      isDisabled = false,
    } = props;

    const { t } = useLocalizationContext();

    return (
      <TextField
        ref={ref}
        containerTestID="locationsearch.location-search-bar"
        submitButtonTestID="locationsearch.submit-button"
        testID="location-search-input"
        accessibilityLabel={t('location.search-placeholder')}
        placeholder={t('location.search-placeholder')}
        clearButtonType="text"
        value={value}
        onChangeText={onChangeText}
        onClear={onClear}
        onSubmit={onSubmit}
        disabled={isDisabled}
        clearBtnLabel={t('general.clear')}
        clearBtnAccessibilityHint={t('general.clear-a11y-hint')}
        submitBtnAccessibilityLabel={t('general.submit')}
        submitAccessibilityHint={t('general.submit-a11y-hint')}
      />
    );
  }),
);

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationSearchFieldProps = Readonly<{
  value: string;
  isDisabled?: boolean;
  onClear?: () => void;
}> &
  Pick<ComponentProps<typeof TextField>, 'onSubmit' | 'onChangeText'>;

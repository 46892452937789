import type { ReactNode } from 'react';
import React, { forwardRef } from 'react';
import type { ViewProps, ViewStyle } from 'react-native';
import { StyleSheet } from 'react-native';
import type { AnimatedStyleProp } from 'react-native-reanimated';
import Animated from 'react-native-reanimated';
import { theme } from '@garnish/constants';

import { webOnlyStyles } from '../../../../utils';
import { HERO_CARD_BORDER_RADIUS } from '../../HeroCard.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const HeroCardContainer = forwardRef<
  Animated.View,
  HeroCardContainerProps
>((props, ref) => {
  const {
    palette,
    style,
    animatedStyle,
    children,
    hasPointerEvents = true,
  } = props;

  // NOTE: Because this container can be used for both sides of a flippable
  //       hero card, we must disable pointer events on the hidden side to
  //       avoid additional events.
  const pointerEvents: ViewProps['pointerEvents'] = hasPointerEvents
    ? 'auto'
    : 'none';

  const paletteStyle = paletteStyles[palette];

  const containerStyles = [
    styles.container,
    paletteStyle,
    containerWebStyles,
    style,
    animatedStyle,
  ];

  return (
    <Animated.View
      ref={ref}
      style={containerStyles}
      pointerEvents={pointerEvents}
    >
      {children}
    </Animated.View>
  );
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    backfaceVisibility: 'hidden',
    overflow: 'hidden',
    borderRadius: HERO_CARD_BORDER_RADIUS,
  },
});

const containerWebStyles = webOnlyStyles({
  willChange: 'transform',
  transition: `opacity ${theme.transitions.base}ms`,
});

const paletteStyles = StyleSheet.create<
  Record<HeroCardContainerPalette, ViewStyle>
>({
  quinoa: {
    backgroundColor: theme.colors.QUINOA,
  },
  kale: {
    backgroundColor: theme.colors.KALE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type HeroCardContainerProps = Readonly<{
  palette: HeroCardContainerPalette;
  children: ReactNode;
  style?: ViewProps['style'];
  animatedStyle?: AnimatedStyleProp<ViewStyle>;
  hasPointerEvents?: boolean;
}>;

type HeroCardContainerPalette = 'quinoa' | 'kale';

import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { Container, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuHeaderContentContainer = (props: PropsWithChildren) => {
  const { children } = props;

  const { currentBreakpoint } = useResponsive();

  return (
    <Container removeSpacing={currentBreakpoint.isLG} style={styles.container}>
      {children}
    </Container>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing['4'],
    marginBottom: theme.spacing['4'],
  },
});

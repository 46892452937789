import React from 'react';
import type * as Urql from 'urql';

// Executes a query in a ms {interval} with the given {input} and the specified {useQuery}.
export function useUrqlPolling<T>(
  interval: number,
  input: T,
  useQuery: (input: T) => Urql.UseQueryResponse,
  skip?: boolean,
) {
  const [response, reexecuteQuery] = useQuery(input);

  const handleReExecute = React.useCallback(() => {
    reexecuteQuery({ requestPolicy: 'network-only' });
  }, [reexecuteQuery]);

  React.useEffect(() => {
    if (skip || response.fetching) return;

    const timerId = setTimeout(handleReExecute, interval);

    return () => {
      clearTimeout(timerId);
    };
  }, [response.fetching, interval, skip, handleReExecute]);

  return { response, reexecuteQuery: handleReExecute };
}

// Executes a {pollingFunction} in a ms {interval}.
export function useFunctionPolling(
  interval: number,
  pollingFunction: (() => Promise<unknown>) | (() => void),
  skip?: boolean,
) {
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (skip) return;

    if (loading) return;
    const timerId = setTimeout(async () => {
      setLoading(true);
      await pollingFunction();
      setLoading(false);
    }, interval);

    return () => {
      clearTimeout(timerId);
    };
  }, [interval, skip, loading, pollingFunction]);
}

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { FadeView, IconLink, LoadingDots, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const UpsellProductBagIconLink = (
  props: UpsellProductBagIconLinkProps,
) => {
  const {
    isLoading = false,
    isDisabled = false,
    'aria-label': ariaLabel,
    onPress,
  } = props;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <FadeView
        show={!isDisabled && isLoading}
        style={[styles.container, styles.loadingContainer]}
      >
        <LoadingDots color={theme.colors.WHITE} />
      </FadeView>

      <IconLink
        name="IconPlus2"
        iconSize={ICON_SIZE}
        disabled={isLoading || isDisabled}
        aria-label={ariaLabel}
        onPress={onPress}
      />
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const ICON_SIZE = 24;
const ICON_CONTAINER_SIZE = 40;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    width: ICON_CONTAINER_SIZE,
    height: ICON_CONTAINER_SIZE,
    borderRadius: ICON_CONTAINER_SIZE,
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
  loadingContainer: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: theme.colors.KALE,
    ...theme.elevations['2'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type UpsellProductBagIconLinkProps = {
  'aria-label': string;
  onPress: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
};

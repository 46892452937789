import React, { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import { LoyaltyOffersModal } from './components';
import { useLoyaltyOffers } from './useLoyaltyOffers';

// ─────────────────────────────────────────────────────────────────────────────

const LoyaltyOffersModalContainer = () => {
  const {
    filteredOffers,
    isShowingOffersModal,
    isOffersModalEnabled,
    hideOffersModal,
    showOffersModal,
  } = useLoyaltyOffers();

  // ─── Effects ─────────────────────────────────────────────────────────

  useFocusEffect(showOffersModal);
  useFocusEffect(useCallback(() => hideOffersModal, [hideOffersModal]));

  // ─────────────────────────────────────────────────────────────────────

  if (!isOffersModalEnabled) return null;

  return (
    <LoyaltyOffersModal
      offers={filteredOffers}
      visible={isShowingOffersModal}
      hideOffersModal={hideOffersModal}
    />
  );
};

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOffers = {
  Modal: LoyaltyOffersModalContainer,
};

import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardText = (props: LoyaltyOfferCardTextProps) => {
  const { style, ...restProps } = props;

  return (
    <BodyText style={[styles.text, style]} sizeMatch={['16']} {...restProps} />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    color: theme.colors.CREAM,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardTextProps = ComponentProps<typeof BodyText>;

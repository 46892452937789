import { useMemo } from 'react';

import { APPLE_PAY, PAYMENT_ADD } from '@order/constants';
import type { CardType, PaymentMethodCard } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

export function useCreditCardOptions(params: UseCreditCardOptionsParams) {
  const { shouldUseApplePay, paymentMethods } = params;

  const { t } = useLocalizationContext();
  const paymentAddLabel = t('checkout.select-payment-method');
  const applePayLabel = t('general.apple-pay');

  const creditCardOptions: readonly CreditCardOption[] = useMemo(() => {
    const applePayOptions = shouldUseApplePay
      ? [
          {
            label: applePayLabel,
            value: APPLE_PAY,
            cardType: 'ApplePay' as const,
            paymentMethodType: 'PaymentMethodCard' as const,
            isDefault: true,
          },
        ]
      : [];

    const regularOptions =
      paymentMethods.map((paymentMethod) => ({
        value: paymentMethod.id,
        label: getPaymentMethodLabel(paymentMethod),
        cardType: paymentMethod.cardType,
        isDefault: paymentMethod.isDefault,
        paymentMethodType: 'PaymentMethodCard' as const,
      })) ?? [];

    return [
      {
        label: paymentAddLabel,
        value: PAYMENT_ADD,
        cardType: 'ADD' as const,
        paymentMethodType: 'PaymentMethodCard' as const,
        isDefault: false,
      },
      ...applePayOptions,
      ...regularOptions,
    ];
  }, [shouldUseApplePay, paymentMethods, paymentAddLabel, applePayLabel]);

  return { creditCardOptions };
}

function getPaymentMethodLabel(paymentMethod: PaymentMethodCard) {
  if (paymentMethod.nickname) {
    return `${paymentMethod.nickname} - ${paymentMethod.description}`;
  }

  return paymentMethod.description ?? '';
}

// ─── Types ───────────────────────────────────────────────────────────────────

type UseCreditCardOptionsParams = {
  paymentMethods: readonly PaymentMethodCard[];
  shouldUseApplePay: boolean;
};

type CreditCardOption = Readonly<{
  value: string;
  label: string;
  cardType: CardType | 'ADD' | 'ApplePay';
  paymentMethodType: 'PaymentMethodCard';
  isDefault: boolean;
}>;

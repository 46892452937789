import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { getFormattedExpirationDate } from '../../../utils';
import { LabelText, TitleText } from '../../Text';

export const LoyaltyChallengeContent = ({
  title,
  expirationDate,
  deadlinePrefixText,
  children,
}: LoyaltyChallengeContentProps) => {
  const endsText = getFormattedExpirationDate(
    expirationDate,
    deadlinePrefixText,
  );

  return (
    <View style={styles.container}>
      <LabelText size={2} style={styles.expirationText}>
        {endsText}
      </LabelText>
      <TitleText size={5} style={styles.title}>
        {title}
      </TitleText>
      <View style={styles.descriptionWrapper}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  title: {
    color: theme.colors.KALE,
  },
  expirationText: {
    color: theme.colors.KALE,
    paddingBottom: theme.spacing['4'],
  },
  descriptionWrapper: {
    flex: 1,
    marginTop: theme.spacing['4'],
  },
});

// -------------------- TYPES --------------------

export type LoyaltyChallengeContentProps = Readonly<{
  title: string;
  deadlinePrefixText: string;
  expirationDate: string;
  children: React.ReactNode;
}>;

import type { RefObject } from 'react';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import type {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  ViewProps,
} from 'react-native';

import { noop } from '../../utils/noop';

export const KeyboardAvoiderProvider = ({
  children,
}: ViewProps): React.ReactElement => {
  const scrollViewRef = useRef<ScrollView | null>();
  const scrollPositionRef = useRef(0);

  const registerScrollView = useCallback((ref: RefObject<ScrollView>) => {
    // eslint-disable-next-line functional/immutable-data
    scrollViewRef.current = ref.current;
  }, []);

  const setScrollPosition = useCallback(
    (event: NativeScrollEvent | NativeSyntheticEvent<NativeScrollEvent>) => {
      const position =
        (event as NativeScrollEvent)?.contentOffset?.y ??
        (event as NativeSyntheticEvent<NativeScrollEvent>)?.nativeEvent
          ?.contentOffset?.y ??
        0;

      // eslint-disable-next-line functional/immutable-data
      scrollPositionRef.current = position;
    },
    [],
  );

  const providerValue = useMemo(
    () => ({
      scrollViewRef,
      scrollPositionRef,
      registerScrollView,
      setScrollPosition,
    }),
    [scrollViewRef, scrollPositionRef, registerScrollView, setScrollPosition],
  );

  return (
    <KeyboardAvoiderContext.Provider value={providerValue}>
      {children}
    </KeyboardAvoiderContext.Provider>
  );
};

export const useKeyboardAvoiderContext = () => {
  return useContext(KeyboardAvoiderContext);
};

const KeyboardAvoiderContext = createContext<KeyboardAvoiderProps>({
  scrollViewRef: undefined,
  scrollPositionRef: undefined,
  registerScrollView: noop,
  setScrollPosition: noop,
});

// ─── Types ──────────────────────────────────────────────────────
type KeyboardAvoiderProps = Readonly<{
  scrollViewRef?: RefObject<ScrollView | null | undefined>;
  scrollPositionRef?: RefObject<number>;
  registerScrollView: (ref: RefObject<ScrollView>) => void;
  setScrollPosition: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
}>;

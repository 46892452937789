import { useState } from 'react';
import type { LayoutChangeEvent, LayoutRectangle } from 'react-native';
import { StyleSheet } from 'react-native';

import type {
  HelperReturn,
  MaybeDims,
  PassedViewProp,
  SetLayout,
  UseRatioLayout,
  UseRatioLayoutStateType,
} from './AspectView.types';

const useRatioFromLayoutHandler = (styleProp: PassedViewProp): HelperReturn => {
  // normalize styles
  const { aspectRatio, passThruStyles } = useRatioFromStyles(styleProp);

  // setup onLayout state and event handler
  const { ratioDims, handleNativeLayout } = useRatioLayoutState(aspectRatio);

  // compose styles array
  const ratioStyles = [passThruStyles, { aspectRatio }, ratioDims];

  // return prepared styles, and event handler
  return { ratioStyles, handleNativeLayout };
};

const useRatioLayoutState = (aspectRatio: number): UseRatioLayoutStateType => {
  const [layout, setLayout] = useState<LayoutRectangle | undefined>();
  const ratioDims = useLayoutAwareRatioDims({ layout, aspectRatio });
  const handleNativeLayout = useNativeLayoutEvent(setLayout);

  return { ratioDims, handleNativeLayout };
};

const useLayoutAwareRatioDims = (props: UseRatioLayout): MaybeDims => {
  const { layout, aspectRatio } = props;

  if (!layout) return {};

  const { width = 0, height = 0 } = layout;

  return width === 0
    ? { width: height * aspectRatio, height }
    : { width, height: width * aspectRatio };
};

const useNativeLayoutEvent =
  (setLayout: SetLayout) =>
  ({ nativeEvent }: LayoutChangeEvent): void => {
    setLayout(nativeEvent.layout);
  };

const useRatioFromStyles = (
  styleProp: PassedViewProp,
): Readonly<{
  passThruStyles: PassedViewProp;
  aspectRatio: number;
}> => {
  // get aspectRatio from style props passed to the component
  const flattenedStyleProp = StyleSheet.flatten(styleProp) || {};

  // extract `aspectRatio` prop if exists, assign default value 1 if not provided
  const { aspectRatio = 1, ...passThruStyles } = flattenedStyleProp;

  // return aspect ratio style separate from the rest
  // @ts-expect-error TS(2322): Type 'string | number' is not assignable to type '... Remove this comment to see the full error message
  return { aspectRatio, passThruStyles };
};

export {
  useLayoutAwareRatioDims,
  useNativeLayoutEvent,
  useRatioFromLayoutHandler,
  useRatioFromStyles,
  useRatioLayoutState,
};

import { useCallback, useMemo } from 'react';
import { useActor, useSelector } from '@xstate/react';

import { useGlobalAppState } from '@order/GlobalAppState';

import type { CartMutation } from './Cart.machine.model';
import { cartModel } from './Cart.machine.model';
import { getPendingMutationsNames } from './Cart.machine.utils';

// ────────────────────────────────────────────────────────────────────────────────

export const useCartMachine = () => {
  const { cartMachineRef: cartService } = useGlobalAppState();
  const [state, send] = useActor(cartService);

  const shouldDisableCartControls = useSelector(cartService, (currentState) =>
    currentState.matches('controls.disabled'),
  );
  const pendingCartMutations = getPendingMutationsNames(state.context);
  const cartViewCounts = useSelector(
    cartService,
    (currentState) => currentState.context.cartViewCounts,
  );

  // ─── HELPERS ─────────────────────────────────────────────────────

  /**
   * Tracks pending cart mutation's fetching state.
   *
   * @example
   * const addLineItemResult = await executeCartMutation(addLineItemMutation);
   */
  const executeCartMutation = useCallback<ExecuteCartMutation>(
    async (mutation, mutationName) => {
      const { events } = cartModel;
      const { REGISTER_PENDING_MUTATION, UNREGISTER_PENDING_MUTATION } = events;

      // ------ ------ ------ ------ ------ ------

      send(REGISTER_PENDING_MUTATION(mutationName));
      const result = await mutation();

      send(UNREGISTER_PENDING_MUTATION(mutationName));

      // ------ ------ ------ ------ ------ ------

      return result;
    },
    [send],
  );

  const registerCartView = useCallback(
    (cartId: string) => {
      const { events } = cartModel;
      const { REGISTER_CART_VIEW } = events;

      send(REGISTER_CART_VIEW(cartId));
    },
    [send],
  );

  // ─────────────────────────────────────────────────────────────────

  return useMemo(
    () => ({
      executeCartMutation,
      pendingCartMutations,
      shouldDisableCartControls,
      registerCartView,
      cartViewCounts,
    }),
    [
      executeCartMutation,
      pendingCartMutations,
      shouldDisableCartControls,
      registerCartView,
      cartViewCounts,
    ],
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ExecuteCartMutation = <DataType>(
  mutation: () => Promise<DataType>,
  mutationName: CartMutation,
) => Promise<DataType>;

import type { IngredientModifications } from '@order/graphql';

import type {
  FilteredIngredientModifications,
  FilteredIngredientModificationsKind,
  IngredientModificationWithQuantity,
} from '../../types';
import type {
  IngredientModificationChange,
  IngredientsModificationsAdditions,
  IngredientsModificationsRemovals,
  IngredientsModificationsSubstitutions,
  IngredientsModificationsWithQuantities,
  ProductIngredientComputedModifications,
} from '../ProductModifications.types';

// ────────────────────────────────────────────────────────────────────────────────

export const getComputedModifications = (
  defaults: ReadonlyMap<string, IngredientModificationWithQuantity>,
  actives: IngredientsModificationsWithQuantities,
) => {
  const initialAdditions = getAdditions(defaults, actives);
  const initialRemovals = getRemovals(defaults, actives);

  return initialAdditions.reduce<ProductIngredientComputedModifications>(
    (modifications, addition) => {
      const { additions, removals, substitutions } = modifications;
      const { index: sameKindRemovalIndex, removal: sameKindRemoval } =
        getSubSameKindRemoval(removals, addition.subKind);

      // ────────────────────────────────────────────────────────────────────

      if (!sameKindRemoval) {
        return {
          ...modifications,
          additions: withAddition(additions, addition),
        };
      }

      // ────────────────────────────────────────────────────────────────────

      const substitution = createSubstitution(sameKindRemoval, addition);

      return {
        ...modifications,
        removals: withoutModification(removals, sameKindRemovalIndex),
        substitutions: withSubstitution(substitutions, substitution),
      };
    },
    {
      additions: [],
      removals: [...initialRemovals],
      substitutions: [],
    },
  );
};

export const getNetPriceChange = (
  ingredientModifications: IngredientModifications,
  additions: IngredientsModificationsAdditions,
  removals: IngredientsModificationsRemovals,
  substitutions: IngredientsModificationsSubstitutions,
) => {
  const additionsByKind = getAdditionsByKind(additions);

  const additionsTotalCost = Object.entries(additionsByKind).reduce<number>(
    (
      total,
      [kind, additionByKind]: readonly [
        FilteredIngredientModificationsKind,
        IngredientsModificationsAdditions,
      ],
    ) => {
      const freeKindTotal =
        ingredientModifications[kind as keyof FilteredIngredientModifications]
          .freeAggregateQuantity;

      return (
        total +
        additionByKind.slice(freeKindTotal).reduce((t, addition) => {
          return t + addition.additionCost;
        }, 0)
      );
    },
    0,
  );

  const removalsTotalCost = removals.reduce((total, removal) => {
    return total + removal.removalCost;
  }, 0);
  const substitutionsTotalCost = substitutions.reduce((total, substitution) => {
    return total + substitution.removalCost + substitution.substitutionCost;
  }, 0);

  return additionsTotalCost + removalsTotalCost + substitutionsTotalCost;
};

const getAdditionsByKind = (additions: IngredientsModificationsAdditions) => {
  return additions.reduce<
    Record<
      FilteredIngredientModificationsKind,
      IngredientsModificationsAdditions
    >
  >(
    (state, addition) => {
      if (!addition.kind) return state;

      const existing = state[addition.kind];

      if (!existing) {
        return {
          ...state,
          [addition.kind]: [addition],
        };
      }

      return {
        ...state,
        [addition.kind]: [...state[addition.kind], addition],
      };
    },
    {
      bases: [],
      toppings: [],
      premiums: [],
      dressings: [],
      bread: [],
    },
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const withSubstitution = (
  substitutions: IngredientsModificationsSubstitutions,
  substitution: IngredientsModificationsSubstitutions[number],
): IngredientsModificationsSubstitutions => {
  return [...substitutions, substitution];
};

const withAddition = (
  additions: IngredientsModificationsAdditions,
  addition: IngredientsModificationsAdditions[number],
): IngredientsModificationsAdditions => {
  return [...additions, addition];
};

const withoutModification = (
  modifications:
    | IngredientsModificationsAdditions
    | IngredientsModificationsRemovals,
  targetIndex: number,
) => {
  return modifications.filter((_, index) => index !== targetIndex);
};

// ────────────────────────────────────────────────────────────────────────────────

// Compute the addition modifications--without respect to substitutions--by
// looking for active ingredient portions that are not present in the defaults.
const getAdditions = (
  defaults: ReadonlyMap<string, IngredientModificationWithQuantity>,
  actives: IngredientsModificationsWithQuantities,
): IngredientsModificationsAdditions => {
  return actives.flatMap((active) => {
    const existingDefault = defaults.get(active.ingredient.id);

    const netAddedQuantity = getModificationsQuantitiesDifference(
      active,
      existingDefault,
    );

    if (netAddedQuantity <= 0) return [];

    return generateIngredientModificationChangesList(active, netAddedQuantity);
  });
};

// Compute the removals modifications--without respect to substitutions--by
// looking for default ingredient portions that are not present in actives.
const getRemovals = (
  defaults: ReadonlyMap<string, IngredientModificationWithQuantity>,
  actives: IngredientsModificationsWithQuantities,
): IngredientsModificationsRemovals => {
  return [...defaults].flatMap(([defaultIngredientId, defaultIngredient]) => {
    const existingActive = actives.find(
      (active) => defaultIngredientId === active.ingredient.id,
    );

    const netRemovedQuantity = getModificationsQuantitiesDifference(
      defaultIngredient,
      existingActive,
    );

    if (netRemovedQuantity <= 0) return [];

    return generateIngredientModificationChangesList(
      defaultIngredient,
      netRemovedQuantity,
    );
  });
};

const generateIngredientModificationChangesList = (
  ingredientModification: IngredientModificationWithQuantity,
  changesQuantity = 1,
) => {
  const {
    ingredient,
    subKind,
    kind,
    additionCost,
    removalCost,
    substitutionCost,
  } = ingredientModification;
  const ingredientId = ingredient.id;

  return Array.from<IngredientModificationChange>({
    length: changesQuantity,
  }).fill({
    ingredientId,
    subKind,
    kind,
    additionCost,
    removalCost,
    substitutionCost,
  }) as IngredientsModificationsRemovals;
};

const getModificationsQuantitiesDifference = (
  firstModification?: IngredientModificationWithQuantity,
  secondModification?: IngredientModificationWithQuantity,
) => {
  return (
    (firstModification?.quantity ?? 0) - (secondModification?.quantity ?? 0)
  );
};

const getSubSameKindRemoval = (
  removals: IngredientsModificationsRemovals,
  targetSubKind: IngredientModificationWithQuantity['subKind'],
) => {
  const sameKindRemovalIndex = removals.findIndex(
    (removal) => removal.subKind === targetSubKind,
  );
  const sameKindRemoval = removals[sameKindRemovalIndex];

  return { index: sameKindRemovalIndex, removal: sameKindRemoval };
};

const createSubstitution = (
  removal: IngredientsModificationsRemovals[number],
  addition: IngredientsModificationsAdditions[number],
) => {
  return {
    removedIngredientId: removal.ingredientId,
    addedIngredientId: addition.ingredientId,
    subKind: addition.subKind,
    removalCost: removal.removalCost,
    substitutionCost: addition.substitutionCost,
  };
};

export const getNumberOfModifications = (
  additions: IngredientsModificationsAdditions,
  removals: IngredientsModificationsRemovals,
  substitutions: IngredientsModificationsSubstitutions,
) => {
  return additions.length + removals.length + substitutions.length;
};

export const checkIfBaseKind = (
  ingredientModification: IngredientModificationWithQuantity,
) => {
  return ingredientModification.kind === 'bases';
};

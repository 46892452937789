import type { AddressType } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import type { OrderStatus } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';
import { getOrderType } from '@order/OrderStatus';
import { getDay } from '@order/utils';

import { getGreetingMode, getTimeEstimate } from '../../utils';
import { useHomeOrderStatusData } from '../useHomeOrderStatusData';

/**
 * Localization parameters for rendering the home welcome pane.
 */
export const useHomeLocalization = (props: HomeLocalizationProps) => {
  const {
    isLoading,
    hasCart,
    orderStatus,
    inStoreOrderPendingFeedbackId,
    inStoreOrderPendingFeedbackWantedTime,
  } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const isLoggedIn = useIsLoggedIn();

  // ─── Status Data ─────────────────────────────────────────────────────

  const {
    order,
    orderId,
    isPast,
    isExpired,
    isPendingFeedback,
    enhancedOrderStatus,
    orderTimeDate,
    orderTimeRange,
  } = useHomeOrderStatusData({
    isLoading,
    hasCart,
    orderStatus,
    inStoreOrderPendingFeedbackId,
    inStoreOrderPendingFeedbackWantedTime,
  });

  // ─── Greeting ────────────────────────────────────────────────────────

  const greetingMode = getGreetingMode({
    isLoggedIn,
    hasActiveOrder: hasCart,
  });

  // ──── Cart State ─────────────────────────────────────────────────────
  // Only show the cart state if customer has cart in larger view ports.
  // Mobile viewports use a floating button that isn't part of the home screen.

  if (hasCart) {
    return {
      ...defaultTranslations,
      greetingMode,
      shouldShowCartAction: true,
    };
  }

  // ──── Empty State ────────────────────────────────────────────────────
  // In this scenario the app isn't ready for showing the home status card.

  if (isLoading || !isLoggedIn) {
    return {
      ...defaultTranslations,
      greetingMode,
    };
  }

  // ──── No Actionable Orders ───────────────────────────────────────────
  // New users won't have an orderId due to not having any order history.
  // Ignore last order if it is past (>1hrs) and they've already rated it.
  // Ignore last order if it is expired (>72hrs).

  if (!orderId || (isPast && !isPendingFeedback) || isExpired) {
    return {
      ...defaultTranslations,
      greetingMode,
    };
  }

  // ──── Order Status ───────────────────────────────────────────────────
  // The state for the order status card is derived from these properties.

  return {
    ...defaultTranslations,
    greetingMode,
    orderId,
    orderType: getOrderType(order),
    enhancedOrderStatus,
    restaurantName: order?.restaurant?.name,
    dropoffLocation: order?.dropoffLocation ?? '',
    deliveryAddressName: order?.deliveryOrderDetail?.address.name ?? '',
    timeEstimate: getTimeEstimate(orderTimeDate),
    dayOfWeek: orderTimeDate ? t(getDay(`${orderTimeDate.getDay()}`)) : '-',
    wantedTimeTs: orderTimeDate,
    timeRangeStartTs: orderTimeRange.startTime,
    timeRangeEndTs: orderTimeRange.endTime,
    shouldShowStatusAction: true,
  };
};

// ─── Defaults ──────────────────────────────────────────────────────────

const defaultTranslations = {
  shouldShowCartAction: false,
  shouldShowStatusAction: false,
  orderId: null,
  orderType: 'pickup' as AddressType,
  enhancedOrderStatus: 'received',
  restaurantName: null,
  dropoffLocation: null,
  deliveryAddressName: null,
  greetingMode: 'signedOut',
  dayOfWeek: '',
  timeEstimate: 'today',
  wantedTimeTs: new Date(),
  timeRangeStartTs: new Date(),
  timeRangeEndTs: new Date(),
};

// ─── Types ─────────────────────────────────────────────────────────────

type HomeLocalizationProps = Readonly<{
  isLoading: boolean;
  hasCart: boolean;
  orderStatus?: OrderStatus | null;
  inStoreOrderPendingFeedbackId?: string;
  inStoreOrderPendingFeedbackWantedTime?: string;
}>;

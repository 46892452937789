/* istanbul ignore file */

import type { LocationObjectCoords } from 'expo-location';
import type { Client } from 'urql';

import { getSearchAreaBasedOnCurrentPosition } from '../../actions';
import type {
  LocationsSearchType,
  MapBounds,
  PositionCoordinates,
} from '../../LocationSearch.types';
import type { LocationGroups } from '../../LocationSearch.types';
import {
  customerAddressesQuery,
  lastPlacedOrdersDetailsQuery,
  locationsSearchByAreaQuery,
  locationsSearchByAreaWithDisclosureFieldsQuery,
  locationsSearchByStringQuery,
  locationsSearchByStringWithDisclosureFieldsQuery,
} from '../../queries';
import {
  checkIfNonNullable,
  extractLocation,
  formatAndSplitLocations,
  getOrderLocationType,
} from './locations.helpers';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Fetches locations based on a search string
 */
export async function searchLocationsByString(
  params: SearchLocationsByStringParams,
): Promise<LocationGroups> {
  const { client, context, shouldUseDisclosureFields } = params;
  const { currentPosition, searchString } = context;

  if (!searchString) return { pickup: [], outpost: [] };

  // NOTE: Conditionally fetch extra disclosure fields.
  const query = shouldUseDisclosureFields
    ? locationsSearchByStringWithDisclosureFieldsQuery
    : locationsSearchByStringQuery;

  const { data } = await query(client, { searchString });
  const storeLocations = data?.searchLocationsByString;

  if (!storeLocations) {
    throw new Error('Failed to fetch locations by string');
  }

  const locations = storeLocations
    .filter(checkIfNonNullable)
    .map(extractLocation);

  return formatAndSplitLocations(locations, currentPosition);
}

/**
 * Fetches locations based on a search area
 */
export async function searchLocationsByBoundingBox(
  params: SearchLocationsByBoundingBoxParams,
): Promise<LocationGroups> {
  const { client, context, shouldUseDisclosureFields } = params;
  const { currentPosition, searchArea } = context;

  if (!searchArea) return { pickup: [], outpost: [] };

  // NOTE: Conditionally fetch extra disclosure fields.
  const query = shouldUseDisclosureFields
    ? locationsSearchByAreaWithDisclosureFieldsQuery
    : locationsSearchByAreaQuery;

  const { data } = await query(client, searchArea);
  const storeLocations = data?.searchLocationsByBoundingBox;

  if (!storeLocations) {
    throw new Error('Failed to fetch locations by bound box');
  }

  const locations = storeLocations
    .filter(checkIfNonNullable)
    .map(extractLocation);

  return formatAndSplitLocations(locations, currentPosition);
}

/**
 * Fetches locations based on the current position
 */
export async function searchLocationsByCurrentPosition(
  params: SearchLocationsByCurrentPositionParams,
): Promise<{ nearbyLocations: LocationGroups }> {
  const { client, currentPositionCoordinates } = params;

  const searchArea = currentPositionCoordinates
    ? getSearchAreaBasedOnCurrentPosition({ currentPositionCoordinates })
    : null;

  if (!searchArea) {
    return { nearbyLocations: { pickup: [], outpost: [] } };
  }

  const { data } = await locationsSearchByAreaQuery(client, searchArea);
  const storeLocations = data?.searchLocationsByBoundingBox;

  if (!storeLocations) {
    return { nearbyLocations: { pickup: [], outpost: [] } };
  }

  const locations = storeLocations
    .filter(checkIfNonNullable)
    .map(extractLocation);

  return {
    nearbyLocations: formatAndSplitLocations(
      locations,
      currentPositionCoordinates,
    ),
  };
}

export async function getLastPlacedOrderLocation(
  params: GetLastPlacedOrderLocationParams,
): Promise<
  Readonly<{
    locations: LocationGroups;
    locationType: LocationsSearchType;
  }>
> {
  const { client, context, shouldUseDisclosureFields } = params;
  const { currentPosition } = context;

  const { data: customerData } = await customerAddressesQuery(client);
  const isSignedInCustomer = customerData?.currentCustomer?.id !== undefined;

  // NOTE: We only query the last placed order only for signed-in customers.
  if (!isSignedInCustomer) throw new Error('Unauthorized user!');

  const { data } = await lastPlacedOrdersDetailsQuery(client);
  const resolvedData = data?.orders;

  const mostRecentOrder =
    resolvedData?.__typename === 'OrdersResponseSuccess'
      ? resolvedData.orders[0]
      : undefined;

  if (!mostRecentOrder) throw new Error('Cannot fetch the most recent order!');

  const searchString = mostRecentOrder.restaurant.name;

  // NOTE: Conditionally fetch extra upcharge disclosure fields.
  const query = shouldUseDisclosureFields
    ? locationsSearchByStringWithDisclosureFieldsQuery
    : locationsSearchByStringQuery;

  const { data: locationsData } = await query(client, { searchString });

  const storeLocations = locationsData?.searchLocationsByString?.filter(
    (storeLocation) => storeLocation?.location.name === searchString,
  );

  if (!storeLocations) throw new Error('No location were found!');

  const locations = storeLocations
    .filter(checkIfNonNullable)
    .map(extractLocation);

  return {
    locations: formatAndSplitLocations(locations, currentPosition),
    locationType: getOrderLocationType(mostRecentOrder),
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type SearchLocationsByStringParams = {
  client: Client;
  context: SearchLocationsByStringContext;
} & DisclosureFieldsParams;

type SearchLocationsByBoundingBoxParams = {
  client: Client;
  context: SearchLocationsByBoundingBoxContext;
} & DisclosureFieldsParams;

type GetLastPlacedOrderLocationParams = {
  client: Client;
  context: SearchLocationsByBoundingBoxContext;
} & DisclosureFieldsParams;

type SearchLocationsByStringContext = Readonly<{
  searchString: string;
  currentPosition: PositionCoordinates | undefined;
}>;

type SearchLocationsByBoundingBoxContext = Readonly<{
  searchArea: MapBounds | undefined;
  currentPosition: PositionCoordinates | undefined;
}>;

type SearchLocationsByCurrentPositionParams = {
  client: Client;
  currentPositionCoordinates: LocationObjectCoords | undefined;
};

type DisclosureFieldsParams = {
  /**
   * A flag to fetch extra upcharge disclosure fields from BE.
   */
  shouldUseDisclosureFields: boolean;
};

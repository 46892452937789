// /* eslint-disable unicorn/no-array-for-each */

import { Platform } from 'react-native';
import type { TriggerNotification } from '@notifee/react-native';

import { notifee } from '@order/integrations';
import { checkIfInExpoGoClient } from '@order/utils';

// ─────────────────────────────────────────────────────────────────────────────

export function checkIfOrderFeedbackNotificationsEnabled() {
  return (
    (Platform.OS === 'ios' || Platform.OS === 'android') &&
    !checkIfInExpoGoClient()
  );
}

export function getOrderFeedbackNotificationId(orderId: string) {
  return `${ORDER_FEEDBACK_NOTIFICATION_PREFIX}-${orderId}`;
}

export async function cancelAllOrderFeedbackNotifications() {
  if (!checkIfOrderFeedbackNotificationsEnabled()) return;

  const allScheduledNotifications =
    (await notifee?.getTriggerNotifications()) ?? [];
  const allScheduledNotificationsIds = allScheduledNotifications
    .filter(checkIfOrderFeedbackNotification)
    .map(extractOrderFeedbackNotificationIdentifier);

  if (allScheduledNotificationsIds.length === 0) return;

  await notifee?.cancelTriggerNotifications(allScheduledNotificationsIds);
}

export function checkIfOrderFeedbackNotification(
  notificationData: NotificationData,
) {
  const notificationId = notificationData.notification?.id;

  return notificationId?.startsWith(ORDER_FEEDBACK_NOTIFICATION_PREFIX);
}

function extractOrderFeedbackNotificationIdentifier(
  triggerNotification: TriggerNotification,
) {
  return triggerNotification.notification.id ?? '';
}

// ─── Constants ───────────────────────────────────────────────────────────────

const ORDER_FEEDBACK_NOTIFICATION_PREFIX = 'order-feedback';

// ─── Types ───────────────────────────────────────────────────────────────────

type NotificationData = Readonly<{
  notification?: { id?: string };
}>;

import React, { type PropsWithChildren, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { View } from 'react-native';
import { Button, Container, useResponsive } from '@sg/garnish';

import { ApplePay } from '@order/components';
import { APPLE_PAY } from '@order/constants';
import { useTelemetry } from '@order/Telemetry';

import type { GiftCardCheckoutFormReturn } from '../../../../form';
import { giftCardCheckoutSubmitMessages } from './GiftCardCheckoutSubmit.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutSubmit = (props: GiftCardCheckoutSubmitProps) => {
  const { form, onPress } = props;

  const paymentmethodId = form.watch('paymentMethodId');
  const isApplePaySelected = paymentmethodId === APPLE_PAY;

  const { isSubmitting } = form.formState;

  const { track } = useTelemetry();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onBtnPress = useCallback(async () => {
    track('gift.purchase_cta_tapped');
    await onPress();
  }, [onPress, track]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <GiftCardCheckoutSubmitContainer>
      {isApplePaySelected ? (
        <ApplePay.Checkout onPress={onPress} isLoading={isSubmitting} />
      ) : (
        <Button
          size="large"
          palette="primary"
          onPress={onBtnPress}
          isLoading={isSubmitting}
        >
          <FormattedMessage {...giftCardCheckoutSubmitMessages.total} />
        </Button>
      )}
    </GiftCardCheckoutSubmitContainer>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const GiftCardCheckoutSubmitContainer = (props: PropsWithChildren) => {
  const { currentBreakpoint } = useResponsive();

  if (currentBreakpoint.isXS) {
    return <Container>{props.children}</Container>;
  }

  return <View>{props.children}</View>;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutSubmitProps = {
  form: GiftCardCheckoutFormReturn;
  onPress: () => Promise<void>;
};

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { BagWarning } from '../BagWarning';

/**
 * Warning message that the reward was not applied.
 */
export const BagRewardWarning = (props: BagRewardWarningProps) => {
  const { formatMessage } = useIntl();

  return (
    <BagWarning
      message={props.message ?? formatMessage(messages.fallback)}
      accessibilityLabel={formatMessage(messages.accessibilityLabel)}
      cta={formatMessage(messages.cta)}
      onClose={props.onClose}
    />
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  fallback: {
    defaultMessage: 'Something went wrong, please try again later.',
    description: 'Bag | Reward warning | Fallback message',
  },
  cta: {
    defaultMessage: 'Got it',
    description: 'Bag | Reward warning | CTA',
  },
  accessibilityLabel: {
    defaultMessage: 'Confirm reward not applied',
    description: 'Bag | Reward warning | a11y',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BagRewardWarningProps = {
  message?: string;
  onClose: () => void;
};

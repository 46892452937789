import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import {
  theme,
  useContainerSpacing,
  useResponsive,
  VStack,
  webOnlyStyles,
} from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useCustomer } from '@order/Customer';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { useKustomerAutoStart } from '../../../integrations/kustomer';
import type { AccountTabScreenId } from '../../../navigation/AppNavigation.props';
import { resetStacksPostLogout } from '../../../screens/AccountScreen/AccountScreen.navigation';
import { DebugMenuTrigger } from '../../DebugMenu';
import { AccountSignedOutMessage } from '../AccountSignedOutMessage';
import { AccountCustomerName } from './components/AccountCustomerName';
import { AccountMenuCrashOptions } from './components/AccountMenuCrashOptions';
import { AccountMenuSignOutItem } from './components/AccountMenuSignOutItem';
import { AccountPrimaryMenu } from './components/AccountPrimaryMenu';
import { AccountSecondaryMenu } from './components/AccountSecondaryMenu';

export const AccountMenu = (props: AccountMenuProps) => {
  const { screen: screenId } = props;
  const { currentBreakpoint, match } = useResponsive();
  const { isXS } = currentBreakpoint;
  const { isSigningOut, signOut } = useCustomer();
  const isLoggedIn = useIsLoggedIn();
  const isCrashMenuEnabled = useFeatureFlag(
    'permanent-order-crash-option-enabled',
  );

  // ─── Callbacks ───────────────────────────────────────────────────────

  const signOutAndReset = React.useCallback(async () => {
    await signOut();
    resetStacksPostLogout();
  }, [signOut]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useKustomerAutoStart();

  // ─── Menu Styles ─────────────────────────────────────────────────────

  const dividerColor = isXS ? theme.colors.DARK_KALE : theme.colors.LIGHT;
  const menuHorizontalSpacing = useContainerSpacing();
  const menuDividerSpacing = match([24, 32]);
  const menuContainerStyle = [
    useStyle(
      () => ({
        paddingVertical: match([40, 48]),
        paddingHorizontal: isXS ? 0 : menuHorizontalSpacing,
      }),
      [isXS, menuHorizontalSpacing],
    ),
    isXS ? null : menuContainerWebStyle,
  ];

  // ─── Name Styles ─────────────────────────────────────────────────────

  const nameSpacing = match([20, 16]);
  const nameContainerStyle = useStyle(
    () => ({
      paddingBottom: nameSpacing,
    }),
    [nameSpacing],
  );

  // ─── Logged In ───────────────────────────────────────────────────────

  if (isLoggedIn) {
    return (
      <VStack
        testID="account-menu"
        style={menuContainerStyle}
        gap={menuDividerSpacing}
        hasDivider
        dividerColor={dividerColor}
      >
        <View style={nameContainerStyle}>
          <AccountCustomerName />
        </View>

        <AccountPrimaryMenu
          screenId={screenId}
          gap={match([theme.spacing['4'], 0])}
          hasDivider={isXS}
          dividerColor={theme.colors.LIGHT}
        />

        {Platform.OS === 'web' ? null : (
          <VStack gap={0}>
            <AccountSecondaryMenu />
          </VStack>
        )}

        <VStack gap={0}>
          <AccountMenuSignOutItem
            isSigningOut={isSigningOut}
            onSignOut={signOutAndReset}
            resetNavigation={resetStacksPostLogout}
          />
        </VStack>

        <VStack style={styles.menuFooterSpacing} gap={0}>
          {isCrashMenuEnabled ? <AccountMenuCrashOptions /> : null}
          <DebugMenuTrigger />
        </VStack>
      </VStack>
    );
  }

  // ─── Logged Out ──────────────────────────────────────────────────────

  return (
    <VStack
      testID="account-menu"
      style={menuContainerStyle}
      gap={menuDividerSpacing}
      hasDivider
      dividerColor={dividerColor}
    >
      <AccountSignedOutMessage />

      {Platform.OS === 'web' ? null : (
        <VStack gap={0}>
          <AccountSecondaryMenu />
        </VStack>
      )}

      <VStack style={styles.menuFooterSpacing} gap={0}>
        {isCrashMenuEnabled ? <AccountMenuCrashOptions /> : null}
        <DebugMenuTrigger />
      </VStack>
    </VStack>
  );
};

type AccountMenuProps = Readonly<{
  screen?: AccountTabScreenId;
}>;

const styles = StyleSheet.create({
  menuFooterSpacing: {
    marginTop: theme.spacing['6'],
  },
});

const menuContainerWebStyle = webOnlyStyles({
  position: 'sticky',
  top: 0,
} as unknown as Parameters<typeof webOnlyStyles>[0]);

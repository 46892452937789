/* istanbul ignore file */

import * as Updates from 'expo-updates';

/**
 * Checks for and fetches updates using the `expo-updates` methods.
 */
export async function checkForUpdate() {
  const checkForUpdateResult = await Updates.checkForUpdateAsync();

  if (!checkForUpdateResult.isAvailable) {
    return;
  }

  return Updates.fetchUpdateAsync();
}

/* eslint-disable functional/no-loop-statements,no-bitwise */

/**
 * Generates a hash code from a given `string` using the same approach as
 * the related Java function.
 *
 * @see {@link https://docs.oracle.com/javase/8/docs/api/java/lang/Integer.html#hashCode--}
 */
export function hashCode(string: string): number {
  if (string.length === 0) return 0;

  // NOTE: Since `reduce` requires additional memory allocation for
  //       iterating over the character array, the `let` and `for of` loop
  //       are used to enhance operation performance.
  let hash = 0;

  for (const character of string) {
    const charCode = character.codePointAt(0) ?? 0;
    const updatedHash = (hash << 5) - hash + charCode;

    hash = Math.trunc(updatedHash);
  }

  return hash;
}

import type { ComponentProps } from 'react';
import React from 'react';
import { ImageBackground, StyleSheet, View } from 'react-native';
import { Button, theme, useResponsive } from '@sg/garnish';

export const HomeScreenHero = ({
  isLoading,
  imageURL,
  ctaText,
  onPressCTA,
}: HomeScreenHeroProps) => {
  const { minWidth, currentBreakpoint, match } = useResponsive();

  // ────────────────────────────────────────────────────────────────────

  const heroWrapperStyles = [
    styles.heroWrapper,
    match([styles.heroWrapperXS, styles.heroWrapperSM, styles.heroWrapperMD]),
  ];

  const heroCTAWrapperStyles = [
    styles.heroCTAWrapper,
    currentBreakpoint.isXS && styles.heroCTAWrapperMobile,
  ];
  const heroCTASize: ComponentProps<typeof Button>['size'] = minWidth.isMD
    ? 'large'
    : 'medium';

  const heroCTAStyles = minWidth.isMD
    ? styles.heroCTADesktop
    : styles.heroCTAMobile;

  // ────────────────────────────────────────────────────────────────────

  return (
    <View style={heroWrapperStyles}>
      <ImageBackground
        style={styles.heroImage}
        source={{
          uri: imageURL,
        }}
      />

      {!isLoading && ctaText !== undefined && (
        <View style={heroCTAWrapperStyles}>
          <Button
            testID="home.hero-cta"
            size={heroCTASize}
            palette="quinoa"
            style={heroCTAStyles}
            onPress={onPressCTA}
          >
            {ctaText}
          </Button>
        </View>
      )}
    </View>
  );
};

// ─── TYPES ─────────────────────────────────────────────────────────────────────

export type HomeScreenHeroProps = Readonly<{
  isLoading: boolean;
  imageURL?: string;
  ctaText?: string;
  onPressCTA: () => void;
}>;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  heroWrapper: {
    position: 'relative',
    backgroundColor: theme.colors.OPACITY.KALE.DARKEST,
    borderRadius: theme.radius.large,
    overflow: 'hidden',
    height: 340,
  },
  heroWrapperXS: {
    marginTop: theme.spacing['6'],
  },
  heroWrapperSM: {
    minHeight: 440,
    flexGrow: 1,
  },
  heroWrapperMD: {
    minHeight: 624,
    flexGrow: 1,
  },
  heroCTAWrapper: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    alignItems: 'flex-start',
    padding: theme.spacing['10'],
  },
  heroCTAWrapperMobile: {
    alignItems: 'center',
    padding: theme.spacing['6'],
  },

  heroCTAMobile: {
    minWidth: 135,
  },
  heroCTADesktop: {
    minWidth: 200,
  },

  heroImage: { flexGrow: 1 },
});

import { useCallback } from 'react';

import { useTelemetry, useTrackEventEffect } from '@order/Telemetry';

export const useHomeInStoreOrderTelemetry = (
  wasRateInStoreCardPresented: boolean,
) => {
  const { track } = useTelemetry();

  useTrackEventEffect({
    name: 'rate_order.in_store.view',
    skip: !wasRateInStoreCardPresented,
  });

  const handleInStoreRateOrderPress = useCallback(() => {
    track('rate_order.in_store.press');
  }, [track]);

  return {
    handleInStoreRateOrderPress,
  };
};

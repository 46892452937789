import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useClient } from 'urql';
import { useNoticeBannersStackContext } from '@sg/garnish';
import { type DeliveryOrderDetail } from '@sg/graphql-schema';

import {
  type ApplyGiftCardParams,
  type OrderingContext,
} from '@order/features/ordering';
import { type PartialLineItem } from '@order/graphql';

import {
  BagGiftCardBalanceDocument,
  type BagGiftCardBalanceQuery,
  RedeemGiftCardInBagDocument,
  type RedeemGiftCardInBagMutation,
  type RedeemGiftCardInBagMutationVariables,
} from '../../GraphQL/BagGiftCard.generated';

// ────────────────────────────────────────────────────────────────────────────

/**
 * A hook for fetching gift card balance and applying gift cards in the bag.
 */
export const useGiftCards = (fetchCredits: () => Promise<number>) => {
  const client = useClient();
  const { formatMessage } = useIntl();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  // ─── Queries ──────────────────────────────────────────────────────────────

  const fetchGiftCardBalance = useCallback(async () => {
    const queryRewards = client.query<BagGiftCardBalanceQuery>;

    const response = await queryRewards(
      BagGiftCardBalanceDocument,
      {},
      { requestPolicy: 'network-only' },
    ).toPromise();

    if (response.data?.giftCardBalance.__typename !== 'GiftCardBalance') {
      addNoticeBanner({
        text: formatMessage(messages.fetchGiftCardsError),
        palette: 'caution',
      });

      return 0;
    }

    return response.data.giftCardBalance.giftCardBalance;
  }, [client.query, formatMessage, addNoticeBanner]);

  // ─── Mutations ────────────────────────────────────────────────────────────

  const applyGiftCard = useCallback(
    async (
      _context: OrderingContext<PartialLineItem, DeliveryOrderDetail>,
      { params }: ApplyGiftCardParams,
    ) => {
      const mutation = client.mutation<
        RedeemGiftCardInBagMutation,
        RedeemGiftCardInBagMutationVariables
      >;

      const response = await mutation(RedeemGiftCardInBagDocument, params, {
        requestPolicy: 'network-only',
      }).toPromise();

      if (response.data?.redeemGiftCard.__typename === 'GiftCardBalance') {
        const availableCredit = await fetchCredits();
        const { giftCardBalance } = response.data.redeemGiftCard;

        addNoticeBanner({
          text: formatMessage(messages.giftCardApplied),
          palette: 'success',
        });

        return { giftCardBalance, availableCredit };
      }

      addNoticeBanner({
        text: formatMessage(messages.giftCardInvalid),
        palette: 'caution',
      });

      return null;
    },
    [client.mutation, addNoticeBanner, formatMessage, fetchCredits],
  );

  // ──────────────────────────────────────────────────────────────────────────

  return { fetchGiftCardBalance, applyGiftCard };
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  fetchGiftCardsError: {
    defaultMessage: 'Something went wrong fetching your gift cards.',
    description: 'Bag | Gift card | Gift card fetch error',
  },
  giftCardApplied: {
    defaultMessage: 'Credit added successfully.',
    description: 'Bag | Gift card | Gift card applied',
  },
  giftCardInvalid: {
    defaultMessage: 'This code or gift card is not valid.',
    description: 'Bag | Gift card | Gift card invalid',
  },
});

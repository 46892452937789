import { useStyle } from 'react-native-style-utilities';
import { Z_INDEX } from '@garnish/constants';

/**
 * Returns the `zIndex` style based on the `isActive` (with displayed callout) and `isMinimized` states of the marker
 */
export const useMarkerZIndexStyle = (props: UseMarkerZIndexStyleProps) => {
  const { isActive, isMinimized, id } = props;

  return useStyle(() => {
    if (id === 'customer') return { zIndex: Z_INDEX_CUSTOMER };

    if (isActive) return { zIndex: Z_INDEX_ACTIVE };

    if (isMinimized) return { zIndex: Z_INDEX_MINIMIZED };

    return { zIndex: Z_INDEX_INACTIVE };
  }, [isActive, isMinimized, id]);
};

// ─── Types ──────────────────────────────────────────────────────────────────────

type UseMarkerZIndexStyleProps = Readonly<{
  isActive?: boolean;
  isMinimized?: boolean;
  id?: string;
}>;

// ─── Constants ──────────────────────────────────────────────────────────────────

const Z_INDEX_INACTIVE = Z_INDEX.popover;
const Z_INDEX_MINIMIZED = Z_INDEX_INACTIVE - 1;
const Z_INDEX_ACTIVE = Z_INDEX_INACTIVE + 1;
const Z_INDEX_CUSTOMER = Z_INDEX_INACTIVE + 2;

import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

const WRAPPER_PADDING_XS = theme.spacing['2'];
const WRAPPER_PADDING_SM = 12;

const CARD_BORDER_RADIUS = theme.radius.large;
const CARD_SHADOW_BORDER_RADIUS = CARD_BORDER_RADIUS + 4; // to make shadow corners smoother
const CARD_SHADOW_TOP_OFFSET = 3;

// ────────────────────────────────────────────────────────────────────────────────

export const styles = StyleSheet.create({
  cardWrapper: {
    flexGrow: 1,
  },
  wrapper: {
    position: 'relative',
    flexGrow: 1,
    alignItems: 'center',
    borderWidth: 0,
    borderRadius: CARD_BORDER_RADIUS,
  },
  wrapperXS: {
    paddingVertical: WRAPPER_PADDING_XS,
    paddingHorizontal: WRAPPER_PADDING_XS,
  },
  wrapperSM: {
    paddingVertical: WRAPPER_PADDING_SM,
    paddingHorizontal: WRAPPER_PADDING_SM,
  },
  wrapperDisabled: {
    backgroundColor: theme.colors.LINEN,
  },

  titleWrapper: {
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    paddingHorizontal: 2,
  },
  titleMobile: {
    lineHeight: 14,
  },
  titleTablet: {
    lineHeight: 16,
  },

  controlsWrapper: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexGrow: 1,
  },
  controlsWrapperXS: {
    paddingBottom: 12,
  },
  controlsWrapperSM: {
    paddingBottom: theme.spacing['2'],
  },
  controlsBar: {
    ...StyleSheet.absoluteFillObject,
    top: undefined,
    bottom: -WRAPPER_PADDING_SM / 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  controlBarXS: {
    marginHorizontal: -WRAPPER_PADDING_XS / 2,
  },
  controlBarSM: {
    marginHorizontal: -WRAPPER_PADDING_SM / 2,
  },
  controlsBarBtn: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 32,
    borderWidth: 2,
    borderColor: theme.colors.QUINOA,
  },
  controlsBarBtnSelected: {
    borderColor: theme.colors.CUCUMBER,
  },
  controlsBarBtnXS: { width: 28, height: 28 },
  controlsBarBtnSM: { width: 32, height: 32 },

  decreaseQuantityBtn: {
    backgroundColor: theme.colors.OATMEAL,
  },
  decreaseQuantityIcon: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  imgUnavailable: {
    opacity: 0.5,
  },

  count: {
    backgroundColor: theme.colors.KALE,
    marginLeft: 'auto',
  },
  countText: {
    color: theme.colors.WHITE,
    paddingHorizontal: theme.spacing['1'],
  },

  notice: {
    textAlign: 'center',
    color: theme.colors.CHARCOAL,
    paddingTop: theme.spacing['2'],
  },
  noticeRestrictions: {
    color: theme.colors.CAUTION,
    lineHeight: 14,
  },

  // ─── Shadow ──────────────────────────────────────────────────────

  cardShadowOuterContainer: {
    ...StyleSheet.absoluteFillObject,
    top: CARD_SHADOW_TOP_OFFSET,
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.colors.OATMEAL,
    borderRadius: CARD_SHADOW_BORDER_RADIUS,
  },
});

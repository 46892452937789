import { useCallback, useEffect } from 'react';
import type { Event } from '@notifee/react-native';
import type { InitialNotification } from '@notifee/react-native';
import { createURL, openURL } from 'expo-linking';

import { useHasLoggedOut } from '@order/AuthMachine';
import { notifee } from '@order/integrations';

import {
  ORDER_FEEDBACK_NOTIFICATION_ANDROID_IMPORTANCE_HIGH,
  ORDER_FEEDBACK_NOTIFICATION_ANDROID_VISIBILITY_PUBLIC,
  ORDER_FEEDBACK_NOTIFICATION_PRESS_EVENT,
  ORDER_FEEDBACK_NOTIFICATIONS_CHANNEL_ID,
} from './useOrderFeedbackNotifications.constants';
import {
  cancelAllOrderFeedbackNotifications,
  checkIfOrderFeedbackNotification,
  checkIfOrderFeedbackNotificationsEnabled,
} from './useOrderFeedbackNotifications.utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Handles incoming order feedback notifications
 *
 * NOTE: Native platforms only
 */
export const useHandleOrderFeedbackNotifications = () => {
  const hasCustomerLoggedOut = useHasLoggedOut();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToRateOrderScreen = useCallback((orderId: string) => {
    if (!orderId) return;

    void openURL(createURL(`feedback/${orderId}`));
  }, []);

  const handleOrderFeedbackNotification = useCallback(
    (notificationEvent: Event) => {
      const { detail } = notificationEvent;
      const { data, id } = detail.notification ?? {};

      const isOrderFeedbackNotification =
        id !== undefined && checkIfOrderFeedbackNotification(detail);

      if (isOrderFeedbackNotification) {
        navigateToRateOrderScreen(data?.orderId as string);
      }
    },
    [navigateToRateOrderScreen],
  );

  const handleIncomingOrderFeedbackNotification = useCallback(
    async (notificationEvent: Event) => {
      const { type } = notificationEvent;

      if (type !== ORDER_FEEDBACK_NOTIFICATION_PRESS_EVENT) return;

      handleOrderFeedbackNotification(notificationEvent);
    },
    [handleOrderFeedbackNotification],
  );

  /**
   * @see {@link https://notifee.app/react-native/docs/events#app-open-events | Notifee docs | App open events}
   */
  const handleInitialOrderFeedbackNotification = useCallback(
    async (initialNotification: InitialNotification) => {
      handleOrderFeedbackNotification({
        detail: initialNotification,
        type: ORDER_FEEDBACK_NOTIFICATION_PRESS_EVENT,
      });
    },
    [handleOrderFeedbackNotification],
  );

  // ─── Effects ─────────────────────────────────────────────────────────

  // create notifications channel (required for Android) on mount
  useEffect(() => {
    void notifee?.createChannel({
      id: ORDER_FEEDBACK_NOTIFICATIONS_CHANNEL_ID,
      name: 'Order feedback notifications',
      visibility: ORDER_FEEDBACK_NOTIFICATION_ANDROID_VISIBILITY_PUBLIC,
      importance: ORDER_FEEDBACK_NOTIFICATION_ANDROID_IMPORTANCE_HIGH,
      sound: 'default',
    });
  }, []);

  // cancel all scheduled notifications on customer logout
  useEffect(() => {
    if (!checkIfOrderFeedbackNotificationsEnabled()) return;

    if (hasCustomerLoggedOut) void cancelAllOrderFeedbackNotifications();
  }, [hasCustomerLoggedOut]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    handleIncomingOrderFeedbackNotification,
    handleInitialOrderFeedbackNotification,
  };
};

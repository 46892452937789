import React from 'react';
import NetInfo from '@react-native-community/netinfo';
import type { ActorRefFrom } from 'xstate';

import type { networkMonitorMachine } from './NetworkMonitor.machine';

type Machine = ActorRefFrom<typeof networkMonitorMachine>;

export const useNetworkMonitor = (machine: Machine) => {
  React.useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      machine.send(state.isConnected ? 'CONNECT' : 'DISCONNECT');
    });

    return unsubscribe;
  }, [machine]);

  return useNetworkStatus(machine);
};

export const useNetworkStatus = (machine: Machine) => {
  const [connected, setConnected] = React.useState(false);

  React.useEffect(() => {
    const { unsubscribe } = machine.subscribe((state) => {
      setConnected(state.value === 'connected');
    });

    return unsubscribe;
  }, [machine]);

  return connected;
};

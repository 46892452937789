import React from 'react';

import { useNewException } from '@order/hooks';

import { RewardExceptionModal } from '../../../../components/RewardExceptionModal';
import { useCheckoutContext } from '../../CheckoutProvider';

export const ConnectedRewardExceptionModal = () => {
  const { validateRewardException } = useCheckoutContext();

  const {
    exceptionMessage: validateRewardExceptionMessage,
    hasNewException: shouldShowValidateRewardExceptionModal,
  } = useNewException(validateRewardException);

  return (
    <RewardExceptionModal
      visible={shouldShowValidateRewardExceptionModal}
      message={validateRewardExceptionMessage}
    />
  );
};

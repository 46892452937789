import React, { useRef } from 'react';
import type { PressableProps } from 'react-native';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../../hooks';
import { webOnlyStyles } from '../../../utils';
import { BodyText } from '../../Text';

// ────────────────────────────────────────────────────────────────────────────────

export const LineItemHeader = ({
  productSlug,
  productName,
}: CustomizableProductHeaderProps) => {
  const testID = `${TEST_ID_BASE}-${productSlug}`;

  return (
    <View style={headerStyle} testID={testID}>
      <BodyText size={4} bold numberOfLines={2}>
        <Text selectable>{productName}</Text>
      </BodyText>
    </View>
  );
};

export const LineItemPressableHeader = (
  props: CustomizableProductHeaderProps & PressableProps,
) => {
  const {
    productName,
    productSlug,
    disabled,
    onPress,
    accessibilityLabel,
    ...pressableProps
  } = props;

  const ref = useRef(null);
  const pressableState = usePressableState(ref);
  const isPressable = Boolean(!disabled && onPress);
  const isHovered = isPressable && pressableState.isHovered;

  const testID = `${TEST_ID_BASE}-${productSlug}`;

  return (
    <Pressable
      ref={ref}
      style={headerStyle}
      onPress={onPress}
      disabled={disabled}
      testID={testID}
      accessibilityLabel={isPressable ? accessibilityLabel : undefined}
      accessibilityRole={isPressable ? 'button' : undefined}
      {...pressableProps}
    >
      <BodyText size={4} bold underline={isHovered} numberOfLines={2}>
        <Text>{productName}</Text>
      </BodyText>
    </Pressable>
  );
};

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

const TEST_ID_BASE = 'sg-line-item-card-product-name';

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    alignSelf: 'flex-start',
    paddingBottom: theme.spacing['1'],
    maxWidth: '100%',
  },
});
const headerWebOnlyStyle = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: 2,
});
const headerStyle = [styles.header, headerWebOnlyStyle];

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type CustomizableProductHeaderProps = Readonly<{
  productSlug: string;
  productName: string;
}>;

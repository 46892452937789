import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { Header } from '@expo/html-elements';
import { theme } from '@garnish/constants';
import { BodyText, TitleText } from '@sg/garnish';

import {
  JOIN_OR_SIGN_IN_SCREEN_BODY_MAX_FONT_SIZE_MULTIPLIER,
  JOIN_OR_SIGN_IN_SCREEN_TITLE_MAX_FONT_SIZE_MULTIPLIER,
} from '../../JoinOrSignInScreen.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const JoinOrSignInEmailStepHeader = () => {
  return (
    <Header style={styles.header}>
      <TitleText
        sizeMatch={['32', '48']}
        maxFontSizeMultiplier={
          JOIN_OR_SIGN_IN_SCREEN_TITLE_MAX_FONT_SIZE_MULTIPLIER
        }
      >
        <FormattedMessage
          description="Join or Sign In | Heading"
          defaultMessage="Your greens are getting closer"
        />
      </TitleText>

      <BodyText
        sizeMatch={['14', '16']}
        maxFontSizeMultiplier={
          JOIN_OR_SIGN_IN_SCREEN_BODY_MAX_FONT_SIZE_MULTIPLIER
        }
      >
        <FormattedMessage
          description="Join or Sign In | Subheading"
          defaultMessage="Sign in or create an account"
        />
      </BodyText>
    </Header>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    rowGap: theme.spacing['4'],
    marginBottom: theme.spacing['10'],
  },
});

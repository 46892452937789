/**
 * Returns 'LatLngBounds' based on the provided pin positions.
 */
export function getPinsBounds(pins: readonly Pin[]): LatLngBounds | null {
  try {
    const LatLngBounds = getGoogleMapsLatLngBound();

    if (!LatLngBounds) return null;

    const bounds = new LatLngBounds();

    return pins.map(extractPinPosition).reduce(extendPinsBounds, bounds);
  } catch {
    return null;
  }
}

// ─────────────────────────────────────────────────────────────────────────────

function extendPinsBounds(
  bounds: LatLngBounds,
  pinPosition: LatLngLiteral | null,
) {
  if (pinPosition) bounds.extend(pinPosition);

  return bounds;
}

function extractPinPosition(pin: Pin) {
  const { lat, lng } = pin;

  const hasPositions = typeof lat === 'number' && typeof lng === 'number';
  const hasValidPositions = !Number.isNaN(lat) && !Number.isNaN(lng);

  if (hasPositions && hasValidPositions) return { lat, lng };

  return null;
}

function getGoogleMapsLatLngBound():
  | typeof google.maps.LatLngBounds
  | undefined {
  return google?.maps?.LatLngBounds;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type Pin = Partial<LatLngLiteral> & Record<string, unknown>;

type LatLngBounds = google.maps.LatLngBounds;

type LatLngLiteral = google.maps.LatLngLiteral;

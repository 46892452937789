import { useMemo } from 'react';
import type { PinData, Region } from '@sg/garnish';

import type { Address, Restaurant } from '@order/graphql';
import {
  useAddressQuery,
  useOrderProductRestaurantQuery,
} from '@order/graphql';

export const useOrderProductConfirmLocationScreenData = ({
  addressId = '',
  restaurantSlug = '',
}: UseOrderProductConfirmLocationScreenDataProps) => {
  const [addressResponse] = useAddressQuery({
    variables: { addressId },
    requestPolicy: 'cache-and-network',
    pause: !addressId,
  });

  const [restaurantResponse] = useOrderProductRestaurantQuery({
    variables: { id: restaurantSlug },
    requestPolicy: 'cache-and-network',
    pause: !restaurantSlug,
  });

  // ─────────────────────────────────────────────────────────────────────

  const isDeliveryLocation = Boolean(addressResponse.data?.address);

  const restaurant = restaurantResponse.data?.restaurant;
  const deliveryAddress = addressResponse.data?.address;
  const locationWithRegion = isDeliveryLocation ? deliveryAddress : restaurant;

  const region = getLocationRegion(locationWithRegion);
  const locationPin = getLocationPin({ restaurant, deliveryAddress, region });

  // ─────────────────────────────────────────────────────────────────────

  return useMemo(
    () => ({
      isFetching: addressResponse.fetching || restaurantResponse.fetching,
      region,
      restaurant,
      deliveryAddress,
      pins: locationPin ? [locationPin] : [],
    }),
    [
      addressResponse.fetching,
      restaurantResponse.fetching,
      region,
      restaurant,
      deliveryAddress,
      locationPin,
    ],
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

const getLocationRegion = (
  location?: LocationWithRegion | null,
): Region | undefined => {
  if (!location) return;

  return {
    lat: location.latitude,
    lng: location.longitude,
  };
};

const getLocationPin = (props: GetLocationPinProps) => {
  const { restaurant, deliveryAddress, region } = props;
  const isDeliveryAddress = Boolean(deliveryAddress);

  if (!region || !restaurant) return null;

  const locationPin: PinData = {
    id: deliveryAddress ? deliveryAddress.id : restaurant.id,
    lat: deliveryAddress ? deliveryAddress.latitude : restaurant.latitude,
    lng: deliveryAddress ? deliveryAddress.longitude : restaurant.longitude,
    name: deliveryAddress ? deliveryAddress.name ?? '' : restaurant.name,
    isDeliveryAddress,
    acceptingOrders: restaurant.isAcceptingOrders,
    isOutpost: restaurant.isOutpost,
  };

  return locationPin;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseOrderProductConfirmLocationScreenDataProps = Readonly<{
  addressId?: string;
  restaurantSlug?: string;
}>;

type LocationWithRegion = Readonly<{
  latitude: number;
  longitude: number;
}>;

type RestaurantWithPin = Pick<
  Restaurant,
  'id' | 'name' | 'isAcceptingOrders' | 'isOutpost' | 'latitude' | 'longitude'
>;

type GetLocationPinProps = Readonly<{
  region?: Region;
  restaurant?: RestaurantWithPin | null;
  deliveryAddress?: Address | null;
}>;

import { useMemo } from 'react';

import { useCustomer } from '@order/Customer';

import type { AddressForm } from '../../AddressForm.types';
import { getAddressDuplicateByLocation } from '../../utils';

/**
 * Returns a memoed result of address duplication by location.
 */
export function useAddressDuplicateByLocation(
  props: AddressDuplicateByLocationProps,
) {
  const { addressId, addressForm } = props;

  const { customer } = useCustomer();
  const addresses = customer?.addresses;

  return useMemo(() => {
    return getAddressDuplicateByLocation({
      addresses: addresses ?? [],
      addressToCheck: { ...addressForm, id: addressId },
    });
  }, [addressId, addressForm, addresses]);
}

// ─── Types ─────────────────────────────────────────────────────────────

type AddressDuplicateByLocationProps = Readonly<{
  addressId?: string;
  addressForm?: AddressForm;
}>;

import React, { type ComponentProps } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { DisplayText, theme } from '@sg/garnish';

import { orderAgainConflictReviewTextMessages as messages } from './OrderAgainConflictReviewText.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainConflictReviewText = (
  props: ReorderConflictModalConflictDescriptionProps,
) => {
  const { isDeliveryOrder, locationName, unavailableIngredients } = props;

  const { formatMessage } = useIntl();

  // ─── Text Partials ───────────────────────────────────────────────────

  const unfortunately = formatMessage(messages.unfortunately);
  const unavailable = formatMessage(messages.currentlyUnavailable);
  const atThe = formatMessage(messages.atThe);
  const store = formatMessage(messages.store);
  const forDeliveryAddress = formatMessage(messages.forDeliveryAddress);

  // ─────────────────────────────────────────────────────────────────────────────

  if (unavailableIngredients.length === 0) return;

  // ─── Delivery Order ──────────────────────────────────────────────────

  if (isDeliveryOrder) {
    return (
      <Text>
        <Text>{unfortunately}</Text>
        <Text> </Text>
        <MissingIngredients unavailableIngredients={unavailableIngredients} />
        <Text> </Text>
        <Text style={styles.currentlyUnavailableText}>{unavailable}</Text>
        <Text> </Text>
        <Text>{forDeliveryAddress}</Text>
      </Text>
    );
  }

  // ─── Pickup or Outpost order ─────────────────────────────────────────

  return (
    <Text>
      <Text>{unfortunately}</Text>
      <Text> </Text>
      <MissingIngredients unavailableIngredients={unavailableIngredients} />
      <Text> </Text>
      <Text style={styles.currentlyUnavailableText}>{unavailable}</Text>
      <Text> </Text>
      <Text>{atThe}</Text>
      <Text> </Text>
      <Text underline>{locationName}</Text>
      <Text> </Text>
      <Text>{store}</Text>
    </Text>
  );
};

// ─── Components ──────────────────────────────────────────────────────────────

const MissingIngredients = (
  props: DescriptionMultipleMissingIngredientsProps,
) => {
  const { unavailableIngredients } = props;

  const { formatMessage } = useIntl();

  // ─── Multiple Ingredients ────────────────────────────────────────────

  if (unavailableIngredients.length > 1) {
    return (
      <>
        {unavailableIngredients.map((ingredient, index) => {
          const isLastItem = index === unavailableIngredients.length - 1;
          const isBeforeLastItem = index === unavailableIngredients.length - 2;
          const ingredientName = ingredient.name.toLowerCase();

          return (
            <Text key={ingredient.id}>
              {isLastItem ? (
                <>
                  <Text>{formatMessage(messages.and)}</Text>
                  <Text> </Text>
                  <Text underline>{ingredientName}</Text>
                  <Text> </Text>
                </>
              ) : (
                <>
                  <Text underline>{ingredientName}</Text>
                  <Text>{isBeforeLastItem ? ' ' : ', '}</Text>
                </>
              )}
            </Text>
          );
        })}

        <Text>{formatMessage(messages.are)}</Text>
      </>
    );
  }

  // ─── Single Ingredient ───────────────────────────────────────────────

  const ingredientName = unavailableIngredients[0].name.toLowerCase();

  return (
    <>
      <Text underline>{ingredientName}</Text>
      <Text> {formatMessage(messages.is)}</Text>
    </>
  );
};

const Text = (props: DisplayTextProps) => {
  const { children, ...restProps } = props;

  return (
    <DisplayText sizeMatch={['24']} {...restProps}>
      {children}
    </DisplayText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  currentlyUnavailableText: {
    color: theme.colors.CAUTION,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ReorderConflictModalConflictDescriptionProps = {
  locationName: string;
  isDeliveryOrder: boolean;
  unavailableIngredients: Ingredients;
};

type DescriptionMultipleMissingIngredientsProps = {
  unavailableIngredients: Ingredients;
};

type DisplayTextProps = Omit<ComponentProps<typeof DisplayText>, 'sizeMatch'>;

type Ingredients = readonly { id: string; name: string }[];

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetPaymentSubscriptionProfileQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetPaymentSubscriptionProfileQuery = {
  readonly __typename?: 'Query';
  readonly paymentSubscriptionProfile: {
    readonly __typename?: 'PaymentSubscriptionProfile';
    readonly id: string;
    readonly creditCards: ReadonlyArray<{
      readonly __typename?: 'SubscriptionCreditCard';
      readonly id: string;
      readonly cardType: Types.BraintreeCardType;
      readonly lastFour: string;
    }> | null;
  } | null;
};

export const GetPaymentSubscriptionProfileDocument = gql`
  query getPaymentSubscriptionProfile {
    paymentSubscriptionProfile {
      id
      creditCards {
        id
        cardType
        lastFour
      }
    }
  }
`;

export function useGetPaymentSubscriptionProfileQuery(
  options?: Omit<
    Urql.UseQueryArgs<GetPaymentSubscriptionProfileQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetPaymentSubscriptionProfileQuery,
    GetPaymentSubscriptionProfileQueryVariables
  >({ query: GetPaymentSubscriptionProfileDocument, ...options });
}

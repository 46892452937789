import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { DisplayText, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardConfirmationTitle = () => {
  const { match } = useResponsive();

  const titleStyles = [styles.title, match([styles.titleXS, styles.titleSM])];

  return (
    <DisplayText sizeMatch={['24', '40', '48']} style={titleStyles}>
      <FormattedMessage {...messages.title} />
    </DisplayText>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Thank you for your order!',
    description: 'Gift Card Confirmation Screen | Title',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  title: {
    alignSelf: 'center',
    width: '100%',
    marginBottom: theme.spacing['6'],
  },
  titleXS: {
    maxWidth: 400,
  },
  titleSM: {
    textAlign: 'center',
  },
});

import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Button,
  theme,
  useNoticeBannersStackContext,
} from '@sg/garnish';

// ────────────────────────────────────────────────────────────────────────────

export const BagCheckoutCta = (props: BagCheckoutCtaProps) => {
  const { paymentMethodId, isLoading, isDisabled, onCheckout } = props;

  const { formatMessage } = useIntl();
  const cta = formatMessage(messages.placeOrder);
  const paymentMethodMissingWarning = formatMessage(
    messages.paymentMethodMissingWarning,
  );

  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  const ctaStyle = isDisabled
    ? styles.checkoutCtaDisabled
    : styles.checkoutCtaLabel;

  const handleOnPress = useCallback(() => {
    if (!paymentMethodId) {
      addNoticeBanner({
        text: paymentMethodMissingWarning,
        palette: 'caution',
      });
      return;
    }

    onCheckout();
  }, [
    paymentMethodId,
    paymentMethodMissingWarning,
    onCheckout,
    addNoticeBanner,
  ]);

  return (
    <View style={styles.checkoutCtaContainer}>
      <Button
        palette="primaryLime"
        size="large"
        style={styles.checkoutCta}
        isLoading={isLoading}
        disabled={Boolean(isDisabled && !isLoading)}
        accessibilityLabel={cta}
        onPress={handleOnPress}
      >
        <BodyText sizeMatch={['18']} style={ctaStyle}>
          {cta}
        </BodyText>
      </Button>
    </View>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  placeOrder: {
    defaultMessage: 'Place order',
    description: 'Bag | CTAs | Place order',
  },
  paymentMethodMissingWarning: {
    defaultMessage: 'Please add a payment method to place your order',
    description: 'Bag | Payment method missing warning',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  checkoutCtaContainer: {
    marginHorizontal: theme.spacing['4'],
    marginBottom: theme.spacing['4'],
    minHeight: theme.spacing['16'],
  },
  checkoutCta: {
    minHeight: theme.spacing['16'],
  },
  checkoutCtaLabel: {
    color: theme.colors.LIME,
  },
  checkoutCtaDisabled: {
    color: theme.colors.LINEN,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagCheckoutCtaProps = {
  paymentMethodId: string | undefined;
  isLoading: boolean;
  isDisabled: boolean;
  onCheckout: () => void;
};

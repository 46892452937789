import type { AddressType } from '@sg/garnish';
import { useUrqlPolling } from '@sg/garnish';

import type { CourierDetails } from '@order/graphql';
import { FlattenedOrderStatuses } from '@order/graphql';

import { useOrderStatusPollingQuery } from '../../graphql/OrderStatus.generated';

/**
 * Polling for the Order Status Screen.
 * Will have different polling intervals depending on order status.
 */
export function useOrderStatusPolling(
  orderId: string,
  orderType: AddressType,
  orderStatus: FlattenedOrderStatuses,
  courierDetails?: CourierDetails,
) {
  const input = { variables: { id: orderId } };
  const timing = useOrderStatusTiming(orderType, orderStatus);
  const [, refetchOrderStatus] = useOrderStatusPollingQuery({
    variables: input.variables,
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  const { response } = useUrqlPolling(
    timing,
    input,
    useOrderStatusPollingQuery,
  );

  const polledStatus = response?.data?.orderStatus?.flattenedOrderStatus;
  const status = polledStatus ?? orderStatus;
  const courier = response?.data?.orderStatus?.courierDetails ?? courierDetails;

  return { status, courier, refetchOrderStatus };
}

// ─── Helpers ───────────────────────────────────────────────────────────

function useOrderStatusTiming(
  orderType: AddressType,
  orderStatus: FlattenedOrderStatuses,
) {
  if (orderStatus === FlattenedOrderStatuses.Delivering) {
    return IN_DELIVERY_POLLING_INTERVAL;
  }

  if (orderType === 'delivery') {
    return PRE_DELIVERY_POLLING_INTERVAL;
  }

  return DEFAULT_POLLING_INTERVAL;
}

// ─── Constants ─────────────────────────────────────────────────────────

const DEFAULT_POLLING_INTERVAL = 1000 * 60; // 60 seconds.
const IN_DELIVERY_POLLING_INTERVAL = 1000 * 10; // 10 seconds.
const PRE_DELIVERY_POLLING_INTERVAL = 1000 * 30; // 30 seconds.

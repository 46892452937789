import type { ComponentProps } from 'react';
import React, { useEffect } from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import Animated from 'react-native-reanimated';
import {
  Button,
  CloudinaryImage,
  Image,
  Modal,
  theme,
  TitleText,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { useUpgradeCelebrationModalContentfulData } from './hooks';
import {
  TIER_UPGRADE_MODAL_IMAGE_HEIGHT,
  useTierUpgradeCelebrationModal,
} from './useTierUpgradeCelebrationModal';

const AnimatedDisplayText = Animated.createAnimatedComponent(TitleText);

export const TierUpgradeCelebrationModal = (
  props: TierUpgradeCelebrationModalProps,
) => {
  const { testID, visible, onDismiss, onExplore } = props;

  const { t } = useLocalizationContext();

  const {
    headingText,
    footerText,
    buttonText,
    image,
    cloudinaryImageUrl,
    isLoadingContentfulData,
  } = useUpgradeCelebrationModalContentfulData();

  const isReadyToShow = visible && !isLoadingContentfulData;

  const {
    startAnimation,
    stopAnimation,
    onContentLayout,
    onFooterTextLayout,
    onHeadingTextLayout,
    opacityAnimatedStyle,
    footerTextAnimatedStyle,
    headingTextAnimatedStyle,
    textContainerAnimatedStyle,
    fontStyleProps,
  } = useTierUpgradeCelebrationModal();

  useEffect(() => {
    if (isReadyToShow) {
      startAnimation();

      return;
    }

    stopAnimation();
  }, [startAnimation, stopAnimation, isReadyToShow]);

  return (
    <Modal
      testID={testID}
      size="full"
      palette="lime"
      visible={visible}
      animationType="fade"
      accessibilityLabel={t('sweetpass-celebration.a11y')}
      transparent
      fitHeight
      onRequestClose={onDismiss}
    >
      <View style={styles.wrapper}>
        <Modal.CloseBtn
          onPress={onDismiss}
          color={theme.colors.BLACK}
          testID={`${testID}.closeBtn`}
        />
        <View style={styles.modal} onLayout={onContentLayout}>
          <Animated.View style={textContainerAnimatedStyle}>
            <AnimatedDisplayText
              style={[styles.text, headingTextAnimatedStyle, fontStyleProps]}
              onLayout={onHeadingTextLayout}
              testID={`${testID}.headingText`}
            >
              {headingText}
            </AnimatedDisplayText>
          </Animated.View>

          <Animated.View style={opacityAnimatedStyle}>
            {image ? (
              <Image
                contentFit="contain"
                source={image}
                style={styles.hero}
                testID={`${testID}.image`}
              />
            ) : (
              <CloudinaryImage
                contentFit="contain"
                style={styles.hero}
                config={IMG_CONFIG}
                baseUrl={cloudinaryImageUrl}
                testID={`${testID}.image`}
              />
            )}
          </Animated.View>

          <Animated.View style={textContainerAnimatedStyle}>
            <AnimatedDisplayText
              style={[styles.text, footerTextAnimatedStyle, fontStyleProps]}
              onLayout={onFooterTextLayout}
              testID={`${testID}.footerText`}
            >
              {footerText}
            </AnimatedDisplayText>
          </Animated.View>
        </View>
        <Modal.Footer style={styles.footer} withoutTopBorder>
          <Animated.View style={opacityAnimatedStyle}>
            <Button
              testID={`${testID}.btn`}
              size="large-wide"
              rightIcon="IconArrowRight"
              palette="limeOnGreen"
              onPress={onExplore}
            >
              {buttonText}
            </Button>
          </Animated.View>
        </Modal.Footer>
      </View>
    </Modal>
  );
};

// ─── Config ──────────────────────────────────────────────────────────────────

const IMG_CONFIG: ComponentProps<typeof CloudinaryImage>['config'] = {
  height: TIER_UPGRADE_MODAL_IMAGE_HEIGHT,
  crop: 'fit',
};

// ─── STYLES ──────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    height: '100%',
  },
  text: {
    color: theme.colors.KALE,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  hero: {
    width: '100%',
    height: TIER_UPGRADE_MODAL_IMAGE_HEIGHT,
  },
  footer: {
    alignItems: 'center',
  },
  modal: {
    flex: 1,
    paddingHorizontal: theme.spacing['4'],
    justifyContent: 'center',
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type TierUpgradeCelebrationModalProps = Readonly<{
  visible: boolean;
  onDismiss: () => void;
  onExplore: () => void;
}> &
  Pick<ViewProps, 'testID'>;

import type { TranslationKeys } from '../locales';

// ─────────────────────────────────────────────────────────────────────────────

export type FormatMessage = (
  id: TranslationKeys,
  values?: TranslationValues,
) => string;

// ─────────────────────────────────────────────────────────────────────────────

type TranslationValues = Record<
  string,
  PrimitiveType | FormatXMLElementFn<string, string>
>;

type FormatXMLElementFn<T, R = string | T | ReadonlyArray<string | T>> = (
  parts: ReadonlyArray<string | T>,
) => R;

type PrimitiveType = string | number | boolean | null | undefined | Date;

/* eslint-disable functional/immutable-data */

import React from 'react';
import { useWindowDimensions } from 'react-native';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import { theme } from '@garnish/constants';

import { isWebBrowser } from '../../utils';
import { useDebounceFn } from '../useDebounceFn';
import type {
  AutomaticPlacedViewProps,
  AutomaticPlacement,
} from './useAutomaticPlacement.types';
import {
  getContentPlacementCoordinates,
  getContentPossiblePlacements,
  getFallbackPlacement,
  measureElementInWindow,
} from './useAutomaticPlacement.utils';

export const useAutomaticPlacement = (props: AutomaticPlacement) => {
  const {
    placementX,
    placementY,
    triggerRef,
    contentRef,
    placement = 'bottom',
    setCurrentPlacement,
  } = props;
  const windowDimensions = useWindowDimensions();
  const updateContentPlacement = React.useCallback(() => {
    (async () => {
      const triggerMeasurements = await measureElementInWindow(triggerRef);
      const contentMeasurements = await measureElementInWindow(contentRef);
      const contentPossiblePlacements = getContentPossiblePlacements({
        contentMeasurements,
        triggerMeasurements,
        windowDimensions,
      });
      const fallbackPlacement = getFallbackPlacement(placement);
      const shouldUseFallbackPlacement = Boolean(
        !contentPossiblePlacements[placement] && fallbackPlacement,
      );
      const targetPlacement = shouldUseFallbackPlacement
        ? fallbackPlacement
        : placement;
      const [x, y] = getContentPlacementCoordinates({
        placement: targetPlacement,
        contentMeasurements,
        triggerMeasurements,
      });

      placementX.value = x;
      placementY.value = y;
      setCurrentPlacement(targetPlacement);
    })();
  }, [
    triggerRef,
    contentRef,
    windowDimensions,
    placement,
    placementX,
    placementY,
    setCurrentPlacement,
  ]);
  const updateContentPlacementDebounced = useDebounceFn(
    updateContentPlacement,
    16,
  );

  return {
    updatePlacementDebounced: updateContentPlacementDebounced,
    updatePlacement: updateContentPlacement,
  };
};

export const AutomaticallyPlacedView = (props: AutomaticPlacedViewProps) => {
  const {
    placementX,
    placementY,
    children,
    style,
    offsetX = 0,
    offsetY = 0,
    ...rest
  } = props;
  const position = isWebBrowser() ? 'fixed' : 'absolute';
  const animatedStyle = useAnimatedStyle(
    () => ({
      zIndex: theme.zIndex.overModal,
      position: position as unknown as undefined,
      transform: [
        { translateX: placementX.value + offsetX },
        { translateY: placementY.value + offsetY },
      ],
    }),
    [placementX.value, placementY.value, offsetX, offsetY],
  );

  return (
    <Animated.View style={[animatedStyle, style]} {...rest}>
      {children}
    </Animated.View>
  );
};

import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { theme, useResponsive } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import {
  AccountScreenBorderedSection,
  AccountScreenContainer,
  AccountScreenSection,
  AccountScreenSectionTitle,
  AccountScreenTitle,
  CreditSection,
  SignedOutView,
} from '@order/components';
import {
  useCustomerCredit,
  usePromoCode,
  useStipendCredits,
} from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

export const CreditAndPromoCodesScreen = () => {
  const { navigate } = useNavigation();
  const { t } = useLocalizationContext();
  const { match, minWidth } = useResponsive();

  const { credits, isLoadingCredits, refetchCredits, availableCredit } =
    useCustomerCredit({
      requestPolicy: 'cache-and-network',
      shouldRefetchOnFocus: true,
    });

  // NOTE: We only use stipend credits.
  const { filteredCredits, totalCreditAmount } = useStipendCredits({
    credits,
    availableCredit,
  });

  const isLoggedIn = useIsLoggedIn();

  // ─── Promo Code ──────────────────────────────────────────────────────

  const {
    promoCode,
    promoCodeInvalid,
    isSubmittingPromoCode,
    isPromoCodeApplied,
    setPromoCode,
    submitPromoCode,
  } = usePromoCode(refetchCredits);

  // ─── Callbacks ───────────────────────────────────────────────────────

  const handlePressAvailableCredit = useCallback(() => {
    navigate('Modal', { screen: 'CreditDetails' });
  }, [navigate]);

  const handlePressAddPromoCode = useCallback(() => {
    navigate('Modal', { screen: 'CreditAdd' });
  }, [navigate]);

  // ─────────────────────────────────────────────────────────────────────

  const title = t('account.credit-and-promo-codes.title');

  // ─────────────────────────────────────────────────────────────────────

  if (!isLoggedIn) {
    return <SignedOutView redirectTo="credit-and-promo-codes" withoutFooter />;
  }

  return (
    <AccountScreenContainer screen="credit-and-promo-codes">
      <AccountScreenTitle visibility="COMPACT" title={title} />
      {minWidth.isSM ? <AccountScreenSectionTitle title={title} /> : null}

      <AccountScreenSection>
        <AccountScreenBorderedSection
          style={match([undefined, styles.sectionContainerSM])}
        >
          <CreditSection
            creditAmount={totalCreditAmount}
            // @ts-expect-error TS(2322): Type 'readonly { readonly __typename?: "Credit" | ... Remove this comment to see the full error message
            creditDetails={filteredCredits}
            promoCode={promoCode}
            isLoadingCredits={isLoadingCredits}
            isSubmittingPromoCode={isSubmittingPromoCode}
            hasPromoCodeError={promoCodeInvalid}
            isPromoCodeApplied={isPromoCodeApplied}
            onPressAvailableCredit={handlePressAvailableCredit}
            onPressAddPromoCode={handlePressAddPromoCode}
            setPromoCode={setPromoCode}
            onPressApplyPromoCode={submitPromoCode}
            isCollapsible={false}
          />
        </AccountScreenBorderedSection>
      </AccountScreenSection>
    </AccountScreenContainer>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  sectionContainerSM: {
    maxWidth: 600,
    paddingVertical: theme.spacing['6'],
  },
});

import type { ComponentProps } from 'react';
import React from 'react';

import { useHasLoggedOut } from '@order/AuthMachine';
import { useTrackEventEffect } from '@order/Telemetry';

import { SignedOutView } from '../../SignedOutView';

export const AccountSignedOutMessage = (
  props: ComponentProps<typeof SignedOutView>,
) => {
  const hasLoggedOut = useHasLoggedOut();

  useTrackEventEffect({ name: 'account.logged_out_view', skip: hasLoggedOut });

  return <SignedOutView {...props} />;
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
export type ValidationErrorFieldsFragment = {
  readonly __typename?: 'ValidationError';
  readonly message: string;
  readonly status: number;
  readonly fieldErrors: ReadonlyArray<{
    readonly __typename?: 'FieldValidationError';
    readonly field: string;
    readonly message: string;
  }>;
};

export const ValidationErrorFieldsFragmentDoc = gql`
  fragment ValidationErrorFields on ValidationError {
    message
    status
    fieldErrors {
      field
      message
    }
  }
`;

import { DurationUnit } from '@order/graphql';

import { type SubscriptionPlansByIdsQuery } from '../graphql/Plan.generated';
import { type SweetpassMembershipPlans } from '../useSweetpassPlusPlans.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Extracts required information (plus adds extra derived data) of the membership plans.
 */
export function extractAvailablePlans(plans: Plans): SweetpassMembershipPlans {
  const { annualPlan, monthlyPlan } = plans;

  // ─── Annual Plan ─────────────────────────────────────────────────────

  const annualPlanId = annualPlan?.id;
  const annualPlanPrice = annualPlan?.price;
  const hasValidAnnualPlanPrice = !Number.isNaN(Number(annualPlanPrice));
  const hasValidAnnualPlan =
    annualPlanId !== undefined &&
    annualPlanPrice !== undefined &&
    hasValidAnnualPlanPrice;

  const maybeAnnualPlan: SweetpassMembershipPlans = hasValidAnnualPlan
    ? {
        annual: {
          id: annualPlanId,
          price: annualPlanPrice,
          priceAsNumber: Number(annualPlanPrice),
          pricePerMonth: calculateAnnualPlanMonthlyPrice(annualPlan),
          savingAmount: calculateAnnualPlanSavingAmount(plans),
          billingFrequencyUnit: DurationUnit.Year,
          hasFreeTrial: true,
        },
      }
    : {};

  // ─── Monthly Plan ────────────────────────────────────────────────────

  const monthlyPlanId = monthlyPlan?.id;
  const monthlyPlanPrice = monthlyPlan?.price;
  const hasValidMonthlyPlanPrice = !Number.isNaN(Number(monthlyPlanPrice));
  const hasValidMonthlyPlan =
    monthlyPlanId !== undefined &&
    monthlyPlanPrice !== undefined &&
    hasValidMonthlyPlanPrice;

  const maybeMonthlyPlan: SweetpassMembershipPlans = hasValidMonthlyPlan
    ? {
        monthly: {
          id: monthlyPlanId,
          price: monthlyPlanPrice,
          priceAsNumber: Number(monthlyPlanPrice),
          billingFrequencyUnit: DurationUnit.Month,
          hasFreeTrial: true,
        },
      }
    : {};

  // ─────────────────────────────────────────────────────────────────────

  return {
    ...maybeAnnualPlan,
    ...maybeMonthlyPlan,
  };
}

// ─── Internal Utils ──────────────────────────────────────────────────────────

/**
 * Calculates the monthly price of annual subscription in cents.
 */
function calculateAnnualPlanMonthlyPrice(
  annualPlan: Plan | undefined,
): string | undefined {
  const annualPlanPrice = Number(annualPlan?.price);
  const amountPerMonth = annualPlanPrice / 12;

  return amountPerMonth >= 0 ? amountPerMonth.toFixed(2) : undefined;
}

/**
 * Calculates the percentage of savings between monthly and annual subscriptions.
 */
function calculateAnnualPlanSavingAmount(
  plans: Plans,
): `${string}%` | undefined {
  const { annualPlan, monthlyPlan } = plans;

  const annualPlanPrice = Number(annualPlan?.price);
  const monthlyPlanPrice = Number(monthlyPlan?.price);

  if (Number.isNaN(annualPlanPrice) || Number.isNaN(monthlyPlanPrice)) return;

  const monthlyPlanPriceOverYear = monthlyPlanPrice * 12;
  const savingAmountPercentage = Math.floor(
    (1 - annualPlanPrice / monthlyPlanPriceOverYear) * 100,
  );

  if (savingAmountPercentage <= 0) return;

  return `${savingAmountPercentage.toFixed(0)}%`;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type Plans = {
  annualPlan?: Plan;
  monthlyPlan?: Plan;
};

type Plan = {
  id: SubscriptionPlans['id'];
  price: SubscriptionPlans['price'];
};

type SubscriptionPlans = NonNullable<
  SubscriptionPlansByIdsQuery['subscriptionPlansByIds']
>[number];

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ActiveCartDeliveryDetailsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ActiveCartDeliveryDetailsQuery = {
  readonly __typename?: 'Query';
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly deliveryOrderDetail: {
      readonly __typename?: 'DeliveryOrderDetail';
      readonly id: string;
      readonly address: {
        readonly __typename?: 'Address';
        readonly id: string;
        readonly googlePlaceId: string;
        readonly name: string | null;
      } | null;
    } | null;
  } | null;
};

export const ActiveCartDeliveryDetailsDocument = gql`
  query ActiveCartDeliveryDetails {
    cart {
      id
      deliveryOrderDetail {
        id
        address {
          id
          googlePlaceId
          name
        }
      }
    }
  }
`;

export function useActiveCartDeliveryDetailsQuery(
  options?: Omit<
    Urql.UseQueryArgs<ActiveCartDeliveryDetailsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ActiveCartDeliveryDetailsQuery,
    ActiveCartDeliveryDetailsQueryVariables
  >({ query: ActiveCartDeliveryDetailsDocument, ...options });
}

import React, { useCallback } from 'react';
import type { AccessibilityProps } from 'react-native';
import { Pressable, StyleSheet } from 'react-native';
import { BodyText, theme, useUniqueNativeID } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const BagTip = (props: BagTipProps) => {
  const {
    value,
    isActive,
    isDisabled,
    label,
    helperText,
    accessibilityLabel,
    accessibilityHint,
    setValue,
  } = props;

  const helperTextNativeID = useUniqueNativeID('bag-tip-helper-text');

  const handleOnPress = useCallback(() => {
    setValue(value);
  }, [setValue, value]);

  // ─────────────────────────────────────────────────────────────────────

  const { containerStyle, textStyle } = getDynamicStyle(isActive, isDisabled);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      accessibilityRole="radio"
      disabled={isDisabled}
      accessibilityLabel={accessibilityLabel}
      accessibilityHint={accessibilityHint}
      style={[styles.container, containerStyle]}
      onPress={isActive ? undefined : handleOnPress}
      {...{
        accessibilityChecked: isActive,
        accessibilityDisabled: isDisabled,
        accessibilityDescribedBy: helperText ? helperTextNativeID : undefined,
      }}
    >
      <BodyText style={textStyle} sizeMatch={['16']} numberOfLines={1}>
        {label}
      </BodyText>

      {helperText ? (
        <BodyText
          style={textStyle}
          nativeID={helperTextNativeID}
          numberOfLines={1}
          sizeMatch={['12']}
        >
          {helperText}
        </BodyText>
      ) : null}
    </Pressable>
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

const getDynamicStyle = (isActive: boolean, isDisabled: boolean) => {
  if (isDisabled) {
    return {
      containerStyle: styles.containerDisabled,
      textStyle: styles.textDisabled,
    };
  }

  if (isActive) {
    return {
      containerStyle: styles.containerActive,
      textStyle: styles.textActive,
    };
  }

  return {
    containerStyle: styles.containerInactive,
    textStyle: styles.textInactive,
  };
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: theme.radius.medium,
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing['1'],
  },
  containerInactive: {
    backgroundColor: theme.colors.OPACITY.SPINACH.NEAR_LIGHTEST,
  },
  containerActive: {
    backgroundColor: theme.colors.KALE,
  },
  containerDisabled: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.LIGHT,
  },
  textInactive: {
    color: theme.colors.BLACK,
  },
  textActive: {
    color: theme.colors.WHITE,
  },
  textDisabled: {
    color: theme.colors.WHITE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BagTipProps = Readonly<{
  value: string;
  label: string;
  isActive: boolean;
  isDisabled: boolean;
  helperText?: string;
  accessibilityLabel?: AccessibilityProps['accessibilityLabel'];
  accessibilityHint?: AccessibilityProps['accessibilityHint'];
  setValue: (value: string) => void;
}>;

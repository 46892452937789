import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, Icon } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { TooltipWithBanner } from '../../../../../TooltipWithBanner';
import { LOCATION_RESULTS_OTHER_LOCATIONS_TITLE_HEIGHT } from '../../LocationResults.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsOtherLocationsTitle = memo(
  (props: LocationResultsOtherLocationsTitleProps) => {
    const { otherLocationSearchType } = props;

    const { t } = useLocalizationContext();

    return (
      <View style={styles.container}>
        <BodyText size={2} numberOfLines={1} style={styles.label}>
          {t('location.result.other-locations', {
            locationSearchType: otherLocationSearchType,
          })}
        </BodyText>

        {otherLocationSearchType === 'outpost' ? (
          <TooltipWithBanner label={t('location.result.outpost-hint')}>
            <Icon name="IconHelp" />
          </TooltipWithBanner>
        ) : null}
      </View>
    );
  },
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: LOCATION_RESULTS_OTHER_LOCATIONS_TITLE_HEIGHT,
    marginHorizontal: theme.spacing['4'],
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.GRAY,
  },
  label: {
    marginRight: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsOtherLocationsTitleProps = Readonly<{
  otherLocationSearchType: 'pickup' | 'delivery' | 'outpost';
}>;

import { useEffect, useMemo } from 'react';
import { useMachine } from '@xstate/react';

import { useFeatureFlag } from '@order/LaunchDarkly';

import { useContentfulClient } from '../useContentfulClient';
import { createContentfulContentTypeEntriesMachine } from './machine';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns Contentful entries for the provided content type.
 *
 * Additionally, returns `fetching` and `error` states.
 *
 * @example
 *
 * const { data, fetching, error } = useContentfulContentTypeEntries({
 *   contentType: 'contentfulContentTypeID',
 * });
 * const [firstEntry, secondEntry] = data ?? [];
 *
 * const { fields, tags } = firstEntry ?? {};
 * const { image, bodyText, headingText } = fields ?? {};
 * const hasSuperTag = tags?.includes('SUPER');
 *
 * const { headingText } = secondEntry.fields ?? {};
 *
 * @see {@link https://www.contentful.com/developers/docs/javascript/tutorials/using-js-cda-sdk/#retrieve-all-entries-of-a-space Contentful Docs}
 */
export const useContentfulContentTypeEntries = <ContentTypeFields>(
  params: UseContentfulContentTypeEntriesParams,
) => {
  const {
    limit,
    order = 'updated-at',
    include,
    contentType,
    tags = [],
    pause = false,
    ttl = DEFAULT_TTL,
    searchParams,
  } = params;

  const contentfulClient = useContentfulClient();
  const isContentfulClientAvailable = contentfulClient !== undefined;

  /**
   * A flag indicating whether the app uses Contentful in preview mode to validate pending changes
   */
  const isPreviewModeEnabled = useFeatureFlag(
    'permanent-contentful-preview-enabled',
  );

  const queryOrder =
    order === 'updated-at' ? '-sys.updatedAt' : '-sys.createdAt';

  // ─── Query ───────────────────────────────────────────────────────────

  const tagsString = tags.toString();

  // NOTE: Use only primitive and/or memoized non-primitive values in this list to avoid
  //       an infinite data fetching loop.
  const queryInput = useMemo<ContentfulQueryInput>(() => {
    return {
      content_type: contentType,
      include,
      limit,
      order: queryOrder,
      ...(tagsString ? { 'metadata.tags.sys.id[in]': tagsString } : {}),
      ...searchParams,
    };
  }, [contentType, searchParams, include, limit, queryOrder, tagsString]);

  // ─── Machine ─────────────────────────────────────────────────────────

  const shouldPreventGetEntries = !isContentfulClientAvailable || pause;

  const [state, send] = useMachine(
    createContentfulContentTypeEntriesMachine<ContentTypeFields>,
    {
      context: {
        isPreviewMode: isPreviewModeEnabled,
        error: isContentfulClientAvailable
          ? undefined
          : new Error('Contentful client was not found!'),
      },
      services: {
        getEntries: async (context) =>
          contentfulClient?.getEntries?.(context.input.query),
      },
    },
  );

  // Get entries when the query input changes.
  useEffect(() => {
    if (shouldPreventGetEntries) return;

    send({
      type: 'GET_ENTRIES',
      input: { query: queryInput, ttl },
    });
  }, [queryInput, shouldPreventGetEntries, ttl, send]);

  // ─────────────────────────────────────────────────────────────────────

  const isFetching = shouldPreventGetEntries
    ? false
    : state.matches('waiting') ||
      state.matches('searching') ||
      state.matches('reading') ||
      state.matches('fetching');

  return {
    data: state.context.data,
    error: state.context.error,
    fetching: isFetching,
  };
};

// ─── Constants ───────────────────────────────────────────────────────────────

const DEFAULT_TTL = 15 * 60 * 1000; // 15 minutes

// ─── Types ───────────────────────────────────────────────────────────────────

type UseContentfulContentTypeEntriesParams = Readonly<{
  contentType: string;

  /**
   * Depth of linked entries.
   */
  include?: number;
  limit?: number;
  order?: 'created-at' | 'updated-at';
  pause?: boolean;
  tags?: readonly string[];

  /**
   * Extra search parameters to narrow down the search results.
   *
   * NOTE: Always "memoize" the parameters object to avoid infinite loops.
   *
   * @example
   * {
   *   searchParams: {
   *     'fields.<field-name>': '<value>',
   *     'fields.<field-name>[within]': '1,2,3,4,5',
   *   }
   * }
   *
   * @see {@link https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters/equality-operator}
   */
  searchParams?: Record<string, string>;

  /**
   * TTL - Time (in milliseconds) to live
   * Specifies how long the data should be cached.
   *
   * @default 60 * 60 * 1000
   */
  ttl?: number;
}>;

type ContentfulQueryInput = Readonly<{
  content_type: string;

  /**
   * Depth of linked entries.
   */
  include?: number;
  limit?: number;
  order?: '-sys.updatedAt' | '-sys.createdAt';
  'metadata.tags.sys.id[in]'?: string;
}>;

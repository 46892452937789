import { Platform } from 'react-native';
import { FONTS } from '@garnish/constants';
import { theme } from '@sg/garnish';

import { getBaseUrl } from '@order/utils';

import type { BraintreeVault } from '../utils';
import { FRAME_HEIGHT } from '../utils';
import {
  BadgeAlertIcon,
  CreditCardIconAmex,
  CreditCardIconDiscover,
  CreditCardIconEmpty,
  CreditCardIconMaster,
  CreditCardIconVisa,
  getBraintreeTokenizationKey,
} from '../utils';
import { useBraintreeHostedFieldsErrors } from './useBraintreeHostedFieldsErrors';
import { useBraintreeHostedFieldsPlaceholders } from './useBraintreeHostedFieldsPlaceholders';

const brainTreeClientSource =
  'https://js.braintreegateway.com/web/3.87.0/js/client.min.js';
const brainTreeHostedFieldsSource =
  'https://js.braintreegateway.com/web/3.87.0/js/hosted-fields.min.js';
const hostedFieldsStyles = `
  html {
    height: ${FRAME_HEIGHT}px;
  }
  body {
    background-color: ${theme.colors.APP_BACKGROUND};
    font-family: ${FONTS.SWEET_SANS_TEXT_REGULAR};
    margin: 0;
  }
  .hosted-field {
    height: 32px;
    margin-bottom: 1em;
    width: 100%;
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.26);
    outline: 0;
    font-size: 16px;
    padding: 0;
    position: relative;
  }
  .card-number-row {
    display: flex;
  }
  .card-icon {
    margin-top: 4px;
    padding-right: 8px;
    height: 28px;
    border-bottom: 1px solid rgb(189, 189, 177);
  }
  .card-icon > .icon {
    display: none;
  }
  .card-icon[data-type="empty"] > .icon-empty {
    display: inline-block;
  }
  .card-icon[data-type="master"] > .icon-master {
    display: inline-block;
  }
  .card-icon[data-type="visa"] > .icon-visa {
    display: inline-block;
  }
  .card-icon[data-type="amex"] > .icon-amex {
    display: inline-block;
  }
  .card-icon[data-type="discover"] > .icon-discover {
    display: inline-block;
  }
  .row {
    display: flex;
  }
  .spacer {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .row-item-left {
    flex: 1;
    margin-right: 4px;
  }
  .row-item-right {
    flex: 1;
    margin-left: 4px;
  }
  .error-wrapper {
    display: flex;
    margin-top: -8px;
  }
  .hidden-error {
    visibility: hidden;
  }
  .error-badge {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  .error-message {
    font-size: 14px;
    margin-top: -2px;
    color: ${theme.colors.CAUTION}
  }
  .hosted-field-label {
    font-size: 14px;
    font-variant-ligatures: none;
    line-height: 18px;
    text-decoration-line: none;
  }
`;

export const useBraintreeSource = (vault: BraintreeVault) => {
  const tokenizationKey = getBraintreeTokenizationKey(vault);
  const {
    numberPlaceholder,
    expirationDatePlaceholder,
    cvvPlaceholder,
    postalCodePlaceholder,
  } = useBraintreeHostedFieldsPlaceholders();
  const { numberError, expirationDateError, cvvError, postalCodeError } =
    useBraintreeHostedFieldsErrors();

  // conditional `viewport` meta tag to render the correct layout size and prevent zooming on native platforms
  const viewPortMetaTag = Platform.select({
    native:
      '<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />',
    default: '<meta content="width=width, initial-scale=1" name="viewport" />',
  });

  return {
    baseUrl: getBaseUrl(),
    html: `
      <html lang="en">
      <head>
        ${viewPortMetaTag}
        <title>Braintree</title>

        <!-- Braintree Scripts -->
        <script src="${brainTreeClientSource}"></script>
        <script src="${brainTreeHostedFieldsSource}"></script>

        <!-- Braintree Initialization -->
        <script type="text/javascript">

          // Sending messages back to React.
          function postMessage(payload) {
            const isInWebView = window.ReactNativeWebView !== undefined;
            const message = JSON.stringify({ ...payload, fromHostedFrame: !isInWebView });

            if (isInWebView) {
              window.ReactNativeWebView.postMessage(message);
              return;
            }

            window.parent.postMessage(message);
          }

          // React-started tokenization request.
          async function tokenizeCreditCard() {
            postMessage({ log: 'Started tokenization', saving: true });
            try {
              const response = await window.hostedFieldsInstance.tokenize();
              postMessage({ ...response, log: 'Tokenization completed' });
            } catch (error) {
              postMessage({ error });
            }
          }

          braintree.client.create({ authorization: '${tokenizationKey}' },
            (clientErr, clientInstance) => {

              // Error initializing braintree client.
              if (clientErr) {
                postMessage({
                  error: clientErr,
                  log: 'Failed client creation.',
                });
                return;
              }

              // Loading hosted fields.
              braintree.hostedFields.create(
                {
                  client: clientInstance,
                  styles: {
                    input: {
                      'font-size': '1rem',
                      '-webkit-font-smoothing': 'antialiased',
                      height: '32px',
                      color: '#484D48',
                    },
                  },
                  fields: {
                    number: {
                      selector:
                        '[data-testid="credit-card-form.card-number"]',
                      placeholder: '${numberPlaceholder}',
                    },
                    expirationDate: {
                      selector:
                        '[data-testid="credit-card-form.expiration-date"]',
                      placeholder: '${expirationDatePlaceholder}',
                    },
                    cvv: {
                      selector: '[data-testid="credit-card-form.cvv"]',
                      placeholder: '${cvvPlaceholder}',
                    },
                    postalCode: {
                      selector: '[data-testid="credit-card-form.zip-code"]',
                      placeholder: '${postalCodePlaceholder}',
                      minlength: 5,
                      maxlength: 5,
                    },
                  },
                },
                (hostedFieldsErr, hostedFieldsInstance) => {

                  // Error loading hosted fields.
                  if (hostedFieldsErr) {
                    postMessage({
                      error: hostedFieldsErr,
                      log: 'Failed hosted fields creation.',
                    });
                    return;
                  }

                  // Hosted fields are loaded
                  postMessage({ loaded: true });

                  // Card Type
                  hostedFieldsInstance.on('cardTypeChange', (event) => {
                    const type = event.fields.number.isEmpty ? 'empty' : event?.cards?.[0]?.type || 'empty';
                    const iconElement = document.getElementById('card-icon');
                    if (type === 'master-card') iconElement.dataset.type = "master";
                    else if (type === 'american-express') iconElement.dataset.type = "amex";
                    else if (type === 'visa') iconElement.dataset.type = "visa";
                    else if (type === 'discover') iconElement.dataset.type = "discover";
                    else iconElement.dataset.type = "empty";
                  });

                  // Validity Updates
                  const toggleError = (field, state) => {
                    if (state) document.getElementById(\`error-\${field}\`).classList.remove('hidden-error');
                    else document.getElementById(\`error-\${field}\`).classList.add('hidden-error');
                  }

                  const onValidityChange = (event) => {
                    postMessage({ fields: event.fields });
                    const fields = Object.keys(event.fields);
                    fields.forEach((field) => {
                      const { isEmpty, isValid } = event.fields[field];
                      toggleError(field, !isEmpty && !isValid);
                    });
                  };

                  hostedFieldsInstance.on('empty', onValidityChange);
                  hostedFieldsInstance.on('blur', onValidityChange);
                  hostedFieldsInstance.on('validityChange', onValidityChange);

                  // Storing hosted fields instance for using in the tokenize credit card function.
                  window.hostedFieldsInstance = hostedFieldsInstance;
                },
              );
            },
          );
        </script>

        <!-- Styles -->
        <style>
          @font-face {
            font-family: ${FONTS.SWEET_SANS_TEXT_REGULAR};
            font-display: auto;
            src: url(https://res.cloudinary.com/sweetgreen/raw/upload/v1663095073/fonts/woff2/SweetSansText-Regular_b8o7du.woff2);
          }
          ${hostedFieldsStyles}
        </style>

      </head>

      <body>
        <label class="hosted-field-label" for="card-number">Card number</label>
        <div class="card-number-row">
          <div id="card-icon" class="card-icon" data-type="empty">
            <div class="icon icon-empty">
              ${CreditCardIconEmpty}
            </div>
            <div class="icon icon-amex">
              ${CreditCardIconAmex}
            </div>
            <div class="icon icon-discover">
              ${CreditCardIconDiscover}
            </div>
            <div class="icon icon-visa">
              ${CreditCardIconVisa}
            </div>
            <div class="icon icon-master">
              ${CreditCardIconMaster}
            </div>
          </div>
          <div
            id="card-number"
            class="hosted-field"
            data-testid="credit-card-form.card-number"
          ></div>
        </div>
        <div class="error-wrapper">
          <div id="error-number" class="row hidden-error">
            <div class="error-badge">
              ${BadgeAlertIcon}
            </div>
            <div class="error-message">
              ${numberError}
            </div>
          </div>
        </div>

        <div class="row spacer">
          <div class="row-item-left">
            <label class="hosted-field-label" for="expiration-date">Exp. date</label>
            <div
              id="expiration-date"
              class="hosted-field"
              data-testid="credit-card-form.expiration-date"
            ></div>
            <div class="error-wrapper">
              <div id="error-expirationDate" class="row hidden-error">
                <div class="error-badge">
                  ${BadgeAlertIcon}
                </div>
                <div class="error-message">
                  ${expirationDateError}
                </div>
              </div>
            </div>
          </div>

          <div class="row-item-right">
            <label class="hosted-field-label" for="cvv">CVV</label>
            <div
              id="cvv"
              class="hosted-field"
              data-testid="credit-card-form.cvv"
            ></div>
            <div class="error-wrapper">
              <div id="error-cvv" class="row hidden-error">
                <div class="error-badge">
                  ${BadgeAlertIcon}
                </div>
                <div class="error-message">
                  ${cvvError}
                </div>
              </div>
            </div>
          </div>
        </div>

        <label class="hosted-field-label" for="zip-code">Zip Code</label>
        <div
          id="zip-code"
          class="hosted-field"
          data-testid="credit-card-form.zip-code"
        ></div>
        <div class="error-wrapper">
          <div id="error-postalCode" class="row hidden-error">
            <div class="error-badge">
              ${BadgeAlertIcon}
            </div>
            <div class="error-message">
              ${postalCodeError}
            </div>
          </div>
        </div>
      </body>
    </html>
    `,
  };
};

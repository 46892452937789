import { useCallback } from 'react';
import { useClient } from 'urql';
import { type DeliveryOrderDetail } from '@sg/graphql-schema';

import {
  type OrderingContext,
  type SetDefaultPaymentMethodParams,
} from '@order/features/ordering';
import { type PartialLineItem } from '@order/graphql';

import {
  BagDefaultPaymentMethodDocument,
  type BagDefaultPaymentMethodQuery,
  BagSetDefaultPaymentMethodDocument,
  type BagSetDefaultPaymentMethodMutation,
  type BagSetDefaultPaymentMethodMutationVariables,
} from '../../GraphQL/BagDefaultPaymentMethod.generated';

// ────────────────────────────────────────────────────────────────────────────

/**
 * A hook for fetching and setting the default payment method.
 */
export const useDefaultPaymentMethod = () => {
  const client = useClient();

  // ─── Queries ──────────────────────────────────────────────────────────────

  const fetchDefaultPaymentMethod = useCallback(async () => {
    const queryDefaultPaymentMethod =
      client.query<BagDefaultPaymentMethodQuery>;

    const response = await queryDefaultPaymentMethod(
      BagDefaultPaymentMethodDocument,
      {},
      { requestPolicy: 'network-only' },
    ).toPromise();

    return response.data?.paymentProfile?.defaultCardId ?? undefined;
  }, [client.query]);

  // ─── Mutations ────────────────────────────────────────────────────────────

  const setDefaultPaymentMethod = useCallback(
    async (
      _context: OrderingContext<PartialLineItem, DeliveryOrderDetail>,
      { params }: SetDefaultPaymentMethodParams,
    ) => {
      const mutation = client.mutation<
        BagSetDefaultPaymentMethodMutation,
        BagSetDefaultPaymentMethodMutationVariables
      >;

      const response = await mutation(
        BagSetDefaultPaymentMethodDocument,
        params,
        { requestPolicy: 'network-only' },
      ).toPromise();

      return response.data?.setDefaultPaymentMethod.defaultCardId ?? undefined;
    },
    [client.mutation],
  );

  // ──────────────────────────────────────────────────────────────────────────

  return { fetchDefaultPaymentMethod, setDefaultPaymentMethod };
};

export const dietaryRestrictions = {
  'dietary-restrictions.toggle.label': 'Toggle dietary preferences',
  'dietary-restrictions.title': 'Dietary restrictions',
  'dietary-restrictions.description-start':
    "Select the items you can't or don't want to eat. Items that contain significant amounts of these properties will be flagged in",
  'dietary-restrictions.illustration.alt': 'Sweetgreen customers',
  'dietary-restrictions.description-highlight': 'red',
  'dietary-restrictions.description-end': '.*',
  'dietary-restrictions.toggle-restriction':
    'Toggle "{restriction}" dietary restriction',
  'dietary-restrictions.notice-1':
    '* At sweetgreen we use all major allergens in our kitchens, so we cannot guarantee that our food is completely free of any allergen. If you have a severe allergy, we recommend not ordering from our restaurant.',
  'dietary-restrictions.notice-2':
    'Please note that your online dietary preferences are not communicated to our in-store teams.',
  'dietary-restrictions.successful-change':
    'Your dietary restrictions are saved!',
};

import type { ComponentProps } from 'react';
import { useCallback, useMemo } from 'react';
import type { Asset, Entry } from 'contentful';

import { useContentfulContentTypeEntry } from '@order/Contentful';

import { DurationUnit } from '../../graphql/types';
import type { SweetpassLandingPage } from './SweetpassLandingPage';
import type { SweetpassUpgradePageTag } from './SweetpassLandingPage.types';
import { sweetpassLandingPageContentfulFallback } from './utils';

// ─────────────────────────────────────────────────────────────────────────────

export const useSweetpassLandingPageContent = (
  props: UseSweetpassLandingPageProps,
) => {
  const {
    tag,
    planPrice,
    billingFrequencyUnit = DurationUnit.Month,
    trialDuration = '',
    trialDurationSingular = '',
    pause,
    contentFallback,
    shouldUseGenericContent,
    onCtaPress,
  } = props;

  const { data, error, fetching } = useContentfulDataWithFallback({
    tag,
    pause,
  });

  const {
    header: headerContentFallback,
    benefits: benefitsContentFallback,
    cta: ctaContentFallback,
  } = contentFallback ?? {};

  const hasData = data !== undefined;

  // ─────────────────────────────────────────────────────────────────────

  const maybeHeaderContentFields = getContentFields<
    SweetpassLandingPageHeaderData['fields']
  >(data, 'sweetpassUpgradeHeader');
  const maybeBenefitsContentFields = getContentFields<
    SweetpassLandingPageBenefitsData['fields']
  >(data, 'sweetpassUpgradeBenefitList');
  const maybeCtaContentFields = data?.fields?.cta?.fields;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleHeaderCtaPress = useCallback(() => {
    if (!maybeHeaderContentFields?.cta.fields.action) return;

    onCtaPress(maybeHeaderContentFields.cta.fields.action);
  }, [maybeHeaderContentFields?.cta.fields.action, onCtaPress]);

  const handleCtaPress = useCallback(() => {
    if (!maybeCtaContentFields?.action) return;

    onCtaPress(maybeCtaContentFields.action);
  }, [maybeCtaContentFields?.action, onCtaPress]);

  // ─── Derived Data ────────────────────────────────────────────────────

  const headerContent = useMemo<
    SweetpassLandingPageProps['headerContent']
  >(() => {
    if (!maybeHeaderContentFields) return headerContentFallback;

    // ─────────────────────────────────────────────

    const {
      palette,
      headingText,
      headingTextXs,
      bodyText,
      bodyTextGeneric,
      heroImage,
      cta,
    } = maybeHeaderContentFields;

    const title = headingText;
    const titleXs = headingTextXs;
    const text = shouldUseGenericContent
      ? bodyTextGeneric
      : bodyText
          .replaceAll('{planPrice}', `${planPrice ?? ''}`)
          .replaceAll(
            '{billingFrequencyUnit}',
            billingFrequencyUnit?.toLowerCase(),
          )
          .replaceAll('{trialDuration}', trialDuration)
          .replaceAll('{trialDurationSingular}', trialDurationSingular);
    const img = heroImage;
    const imgUrl = img.fields.file.url;
    const imgA11yLabel = img.fields.description;
    const ctaLabel = shouldUseGenericContent
      ? cta.fields.titleGeneric
      : cta.fields.titleV3
          .replaceAll('{planPrice}', `${planPrice}`)
          .replaceAll(
            '{billingFrequencyUnit}',
            billingFrequencyUnit?.toLowerCase(),
          )
          .replaceAll('{trialDuration}', trialDuration)
          .replaceAll('{trialDurationSingular}', trialDurationSingular);

    // ─────────────────────────────────────────────

    return {
      title,
      titleXs,
      text,
      imgUrl,
      imgA11yLabel,
      palette,
      ctaLabel,
      onCtaPress: handleHeaderCtaPress,
    };
  }, [
    maybeHeaderContentFields,
    headerContentFallback,
    shouldUseGenericContent,
    planPrice,
    billingFrequencyUnit,
    trialDuration,
    trialDurationSingular,
    handleHeaderCtaPress,
  ]);

  const benefitsContent = useMemo<
    SweetpassLandingPageProps['benefitsContent']
  >(() => {
    if (!maybeBenefitsContentFields) return benefitsContentFallback;

    // ─────────────────────────────────────────────

    const { benefitList, itemsPerRow: itemsPerRowMD } =
      maybeBenefitsContentFields;
    const benefits = benefitList.map(getBenefit);

    // ─────────────────────────────────────────────

    return { benefits, itemsPerRowMD };
  }, [benefitsContentFallback, maybeBenefitsContentFields]);

  const ctaContent = useMemo<SweetpassLandingPageProps['ctaContent']>(() => {
    if (!maybeCtaContentFields) return ctaContentFallback;

    // ─────────────────────────────────────────────

    const ctaLabel = shouldUseGenericContent
      ? maybeCtaContentFields.titleGeneric
      : maybeCtaContentFields.titleV3;

    const label = ctaLabel
      .replaceAll('{planPrice}', `${planPrice}`)
      .replaceAll('{billingFrequencyUnit}', billingFrequencyUnit?.toLowerCase())
      .replaceAll('{trialDuration}', trialDuration)
      .replaceAll('{trialDurationSingular}', trialDurationSingular);

    const { palette, action } = maybeCtaContentFields;

    return {
      label,
      palette,
      action,
      onPress: handleCtaPress,
    };
  }, [
    maybeCtaContentFields,
    ctaContentFallback,
    shouldUseGenericContent,
    planPrice,
    billingFrequencyUnit,
    trialDuration,
    trialDurationSingular,
    handleCtaPress,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    fetching,
    error,
    hasContent: hasData,
    content: {
      header: headerContent,
      benefits: benefitsContent,
      cta: ctaContent,
    },
  };
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function getContentFields<ContentType>(
  data: MaybeContentfulSweetpassLandingPageDate,
  contentId: string,
): ContentType | undefined {
  const { content } = data?.fields ?? {};

  return content?.find(
    (contentEntry) => getContentTypeId(contentEntry) === contentId,
  )?.fields as ContentType | undefined;
}

function getContentTypeId(contentEntry: SweetpassLandingPageDataContent) {
  return contentEntry.sys.contentType.sys.id;
}

function getBenefit(
  benefit: SweetpassLandingPageBenefitsData['fields']['benefitList'][number],
) {
  const { fields, sys } = benefit;

  // ─────────────────────────────────────────────

  const { id } = sys;
  const { titleText: title, bodyText: text, image } = fields;
  const imgUrl = image.fields.file.url;
  const imgDescription = image.fields.description;

  // ─────────────────────────────────────────────

  return {
    id,
    title,
    text,
    image: { url: imgUrl, description: imgDescription },
  };
}

/**
 * Handles the contentful data fetching for the sweetpass upgrade page.
 * Will fetch both the requested content through its tag.
 * If it's not found, will fetch the fallback content through the default campaign tag.
 */
function useContentfulDataWithFallback(
  props: Pick<UseSweetpassLandingPageProps, 'tag' | 'pause'>,
) {
  const { tag = DEFAULT_CAMPAIGN_TAG, pause = false } = props;

  const standardResponse =
    useContentfulContentTypeEntry<SweetpassLandingPageData>({
      contentType: 'sweetpassUpgradePage',
      include: 2,
      pause,
      tags: [tag],
    });

  const {
    data: standardData,
    error: standardError,
    fetching: standardFetching,
  } = standardResponse;

  const isMissingStandardData =
    Boolean(tag) && !standardFetching && !standardData?.fields;

  const fallbackResponse =
    useContentfulContentTypeEntry<SweetpassLandingPageData>({
      contentType: CONTENT_TYPE,
      include: 2,
      pause: pause || !isMissingStandardData,
      tags: [DEFAULT_CAMPAIGN_TAG],
    });

  const {
    data: fallbackData,
    error: fallbackError,
    fetching: fallbackFetching,
  } = fallbackResponse;

  const fallbackContent = getFallbackContentForTag(tag);

  const error = standardError ?? fallbackError;
  const fetching = standardFetching || fallbackFetching;
  const data = standardData ?? fallbackData ?? fallbackContent;

  return {
    data,
    error,
    fetching,
  };
}

function getFallbackContentForTag(tag: SweetpassUpgradePageTag) {
  const fallbackContentForTag = sweetpassLandingPageContentfulFallback[tag];
  const fallbackContentForDefault =
    sweetpassLandingPageContentfulFallback[DEFAULT_CAMPAIGN_TAG];
  const fallbackContent = fallbackContentForTag ?? fallbackContentForDefault;

  return fallbackContent as never as ReturnType<
    typeof useContentfulContentTypeEntry<SweetpassLandingPageData>
  >['data'];
}

// ─── Constants ───────────────────────────────────────────────────────────────

const CONTENT_TYPE = 'sweetpassUpgradePage';
const DEFAULT_CAMPAIGN_TAG = 'campaign-default';

// ─── Types ───────────────────────────────────────────────────────────────────

type UseSweetpassLandingPageProps = Readonly<{
  pause?: boolean;
  tag?: SweetpassUpgradePageTag;
  planPrice?: number;
  trialDuration?: string;
  trialDurationSingular?: string;
  billingFrequencyUnit?: DurationUnit;
  shouldUseGenericContent?: boolean;
  onCtaPress: (action: SweetpassLandingPageCtaAction) => void;
  contentFallback?: {
    header: SweetpassLandingPageProps['headerContent'];
    benefits: SweetpassLandingPageProps['benefitsContent'];
    cta: SweetpassLandingPageProps['ctaContent'];
  };
}>;

type SweetpassLandingPageData = Readonly<{
  content: readonly SweetpassLandingPageDataContent[];
  cta: Entry<
    Readonly<{
      palette: SweetpassLandingPagePalette;
      titleV3: string;
      titleGeneric: string;
      action: SweetpassLandingPageCtaAction;
    }>
  >;
}>;

// ─────────────────────────────────────────────────────────────────────────────

type MaybeContentfulSweetpassLandingPageDate = ReturnType<
  typeof useContentfulContentTypeEntry<SweetpassLandingPageData>
>['data'];

type SweetpassLandingPageDataContent =
  | SweetpassLandingPageHeaderData
  | SweetpassLandingPageBenefitsData;

type SweetpassLandingPageHeaderData = Entry<
  Readonly<{
    bodyText: string;
    bodyTextGeneric: string;
    cta: Entry<
      Readonly<{
        palette: SweetpassLandingPagePalette;
        titleV3: string;
        titleGeneric: string;
        action: SweetpassLandingPageCtaAction;
      }>
    >;
    headingText: string;
    headingTextXs: string;
    heroImage: Asset;
    palette: SweetpassLandingPagePalette;
  }>
>;

type SweetpassLandingPageBenefitsData = Entry<
  Readonly<{
    itemsPerRow: number | undefined;
    benefitList: readonly SweetpassLandingPageBenefit[];
  }>
>;

type SweetpassLandingPageBenefit = Entry<
  Readonly<{
    image: Asset;
    titleText: string;
    bodyText: string;
  }>
>;

export type SweetpassLandingPageCtaAction =
  | 'join_sweetpass'
  | 'upgrade_to_sweetpass_plus';

type SweetpassLandingPagePalette = 'kale' | 'lime';

type SweetpassLandingPageProps = ComponentProps<typeof SweetpassLandingPage>;

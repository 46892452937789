/* eslint-disable functional/immutable-data */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { logger } from '@garnish/logger';

import { webOnlyStyles } from '../../../utils';
import { Button } from '../../Button';
import { FadeView } from '../../FadeView';
import { IconLink } from '../../Icon';
import { LoadingDots } from '../../LoadingDots';
import { BodyText } from '../../Text';

export const LineItemQuantity = (props: LineItemQuantityProps) => {
  const { quantity, productSlug, isDisabled, onQuantityChange } = props;

  const formattedQuantity = quantity > 99 ? '99+' : `${quantity}×`;

  if (onQuantityChange) {
    return (
      <LineItemQuantityBtn
        isDisabled={isDisabled}
        quantity={quantity}
        onQuantityChange={onQuantityChange}
        productSlug={productSlug}
      >
        {formattedQuantity}
      </LineItemQuantityBtn>
    );
  }

  return (
    <LineItemQuantityView productSlug={productSlug}>
      {formattedQuantity}
    </LineItemQuantityView>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const LineItemQuantityView = (props: LineItemQuantityViewProps) => {
  const { children, productSlug } = props;

  return (
    <View
      style={[styles.quantityView, styles.quantityViewDefault]}
      testID={`sg-line-item-card-quantity-${productSlug}`}
    >
      <BodyText size={4}>{children}</BodyText>
    </View>
  );
};

const LineItemQuantityBtn = (props: LineItemQuantityProps) => {
  const { children, quantity, productSlug, isDisabled, onQuantityChange } =
    props;

  const onQuantityChangeRef = useRef(onQuantityChange);

  onQuantityChangeRef.current = onQuantityChange;

  const testIdBase = 'sg-line-item-card-quantity';
  const toggleTestId = `${testIdBase}-toggle-${productSlug}`;
  const increaseQtyTestId = `${testIdBase}-increase-${productSlug}`;
  const decreaseQtyTestId = `${testIdBase}-decrease-${productSlug}`;

  // ─────────────────────────────────────────────────────────────────

  const [selectedQuantity, setSelectedQuantity] = useState(quantity);
  const [isStepperOpen, setIsStepperOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const stepperChangesTimeout = useRef<ReturnType<typeof setTimeout>>();

  // ─────────────────────────────────────────────────────────────────

  const showStepper = useCallback(() => {
    setIsStepperOpen(true);
  }, []);

  const hideStepper = useCallback(() => {
    setIsStepperOpen(false);
  }, []);

  const increaseQuantity = useCallback(() => {
    setSelectedQuantity((currentQuantity) => currentQuantity + 1);
  }, []);

  const decreaseQuantity = useCallback(() => {
    setSelectedQuantity((currentQuantity) =>
      currentQuantity > 1 ? currentQuantity - 1 : currentQuantity,
    );
  }, []);

  const submitQuantityChange = useCallback(async () => {
    setIsSubmitting(true);

    try {
      await onQuantityChangeRef.current?.(selectedQuantity);
    } catch (error: unknown) {
      logger.error(error);
      setSelectedQuantity(quantity);
    }

    hideStepper();
    setIsSubmitting(false);
  }, [hideStepper, quantity, selectedQuantity]);

  // ─────────────────────────────────────────────────────────────────

  useEffect(() => {
    if (!isStepperOpen) return;

    if (stepperChangesTimeout.current) {
      clearTimeout(stepperChangesTimeout.current);
    }

    stepperChangesTimeout.current = setTimeout(hideStepper, 3000);

    return () => {
      if (stepperChangesTimeout.current) {
        clearTimeout(stepperChangesTimeout.current);
      }
    };
  }, [isStepperOpen, hideStepper]);

  useEffect(() => {
    if (stepperChangesTimeout.current) {
      clearTimeout(stepperChangesTimeout.current);
    }

    if (selectedQuantity === quantity) {
      stepperChangesTimeout.current = setTimeout(hideStepper, 3000);

      return;
    }

    stepperChangesTimeout.current = setTimeout(submitQuantityChange, 2000);

    return () => {
      if (stepperChangesTimeout.current) {
        clearTimeout(stepperChangesTimeout.current);
      }
    };
  }, [quantity, selectedQuantity, hideStepper, submitQuantityChange]);

  // ─────────────────────────────────────────────────────────────────

  const wrapperStyle = [
    styles.quantityViewWrapper,
    webOnlyStyles({ userSelect: 'none', cursor: 'default' }),
  ];

  // ─────────────────────────────────────────────────────────────────

  return (
    <View style={wrapperStyle}>
      <Button
        palette="primary"
        style={styles.quantityView}
        onPress={showStepper}
        disabled={isDisabled}
        testID={toggleTestId}
      >
        {children as string}
      </Button>

      <FadeView show={isStepperOpen} style={styles.quantityStepper}>
        <IconLink
          name="IconMinus"
          palette="dark"
          iconSize={18}
          disabled={selectedQuantity <= 1 || isSubmitting}
          onPress={decreaseQuantity}
          style={[styles.stepperControl, styles.stepperControlLeft]}
          testID={decreaseQtyTestId}
        />

        {isSubmitting ? (
          <LoadingDots color={theme.colors.WHITE} />
        ) : (
          <BodyText style={styles.stepperControlQuantity} size={3}>
            {selectedQuantity}×
          </BodyText>
        )}

        <IconLink
          name="IconPlus"
          palette="dark"
          iconSize={18}
          onPress={increaseQuantity}
          disabled={isSubmitting}
          style={[styles.stepperControl, styles.stepperControlRight]}
          testID={increaseQtyTestId}
        />
      </FadeView>
    </View>
  );
};

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

const QUANTITY_VIEW_SIZE = 34;
const STEPPER_WIDTH = 122;
const STEPPER_HEIGHT = 48;

// MOBILE ONLY

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  quantityView: {
    alignItems: 'center',
    justifyContent: 'center',
    width: QUANTITY_VIEW_SIZE,
    height: QUANTITY_VIEW_SIZE,
    borderRadius: QUANTITY_VIEW_SIZE,

    // reset button padding
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  quantityViewDefault: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
  },
  quantityViewWrapper: {
    position: 'relative',
    zIndex: 100,
  },
  quantityStepper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'absolute',
    top: QUANTITY_VIEW_SIZE / 2,
    left: QUANTITY_VIEW_SIZE / 2,
    backgroundColor: theme.colors.GREEN_2,
    borderRadius: 62,
    width: STEPPER_WIDTH,
    height: STEPPER_HEIGHT,
    transform: [
      { translateX: -(STEPPER_WIDTH / 2) },
      { translateY: -(STEPPER_HEIGHT / 2) },
    ],
    overflow: 'hidden',
    ...theme.elevations['3'],
  },
  stepperControlQuantity: {
    alignSelf: 'center',
    color: theme.colors.OATMEAL,
  },
  stepperControl: {
    width: 40,
    borderRadius: 0,
  },
  stepperControlLeft: {
    paddingLeft: theme.spacing['2'],
    paddingRight: theme.spacing['1'],
  },
  stepperControlRight: {
    paddingRight: theme.spacing['2'],
    paddingLeft: theme.spacing['1'],
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type LineItemQuantityProps = Readonly<{
  isDisabled?: boolean;
  quantity: number;
  children: React.ReactNode;
  onQuantityChange?:
    | ((selectedQuantity: number) => Promise<void>)
    | ((selectedQuantity: number) => void);
}> &
  LineItemQuantityViewProps;

type LineItemQuantityViewProps = Readonly<{
  productSlug: string;
  children: React.ReactNode;
}>;

import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { ELEVATIONS, theme } from '@garnish/constants';

import { Image } from '../Image';
import { LoadingPlaceholder } from '../LoadingPlaceholder';
import { BodyText, DisplayText } from '../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const BalanceCard = (props: BalanceCardProps) => {
  const { title, balance, illustrationSource, isLoading } = props;

  return (
    <View style={styles.card}>
      <Image
        source={illustrationSource}
        style={styles.illustration}
        aria-label={title}
        contentFit="contain"
      />

      <View>
        <BodyText sizeMatch={['18']}>{title}</BodyText>

        <View style={styles.balanceContainer}>
          {isLoading ? (
            <LoadingPlaceholder
              rows={1}
              rowHeight={36}
              style={styles.loadingContainer}
              palette="lightestGreen"
            />
          ) : (
            <DisplayText maxFontSizeMultiplier={1} sizeMatch={['32']}>
              {balance}
            </DisplayText>
          )}
        </View>
      </View>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const ILLUSTRATION_SIZE = 110;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  card: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing['10'],
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.CUCUMBER,
    ...ELEVATIONS['3'],
  },
  illustration: {
    height: ILLUSTRATION_SIZE,
    width: ILLUSTRATION_SIZE,
    marginRight: theme.spacing['4'],
  },
  balanceContainer: {
    minWidth: 110,
  },
  loadingContainer: {
    width: 100,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BalanceCardProps = Readonly<{
  title: string;
  balance: string;
  illustrationSource: ComponentProps<typeof Image>['source'];
  isLoading?: boolean;
}>;

import { useLayoutEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useResponsive } from '@sg/garnish';

/**
 * Clears the header show option if the viewport is extra small (XS).
 */
export const useToggleScreenHeader = () => {
  const navigation = useNavigation();
  const { currentBreakpoint } = useResponsive();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: !currentBreakpoint.isXS,
    });
  }, [navigation, currentBreakpoint.isXS]);
};

import React from 'react';

import { LoadingAnimation, NotFoundView } from '@order/components';
import { useTrackEffect } from '@order/Telemetry';

import type {
  NotFoundScreenProps,
  NotFoundWithRestaurantSlugScreenProps,
} from '../../navigation/AppNavigation.props';
import { useMenuRedirect, usePermanentRedirects } from './hooks';

export const NotFoundScreen = (props: NotFoundScreenProps) => {
  useTrackEffect('app.not_found', { path: props.route.path });

  const hasPermanentRedirect = usePermanentRedirects(props);

  const isLoadingMenuRedirect = useMenuRedirect(
    hasPermanentRedirect,
    props as unknown as NotFoundWithRestaurantSlugScreenProps,
  );

  if (isLoadingMenuRedirect || hasPermanentRedirect) {
    return <LoadingAnimation />;
  }

  return <NotFoundView />;
};

import React, { type ReactNode, useRef } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { theme, usePressableState, webOnlyStyles } from '@sg/garnish';

import { FeatureFlagOpenUrlButton } from '../FeatureFlagOpenUrlButton';

// ─────────────────────────────────────────────────────────────────────────────

export const FeatureFlagButton = (props: FeatureFlagToggleButtonProps) => {
  const { name, onPress, children, isSelected } = props;

  // ─── Refs ────────────────────────────────────────────────────────────

  const ref = useRef<View>(null);
  const { isHovered, isActive } = usePressableState(ref);

  // ─── Styles ──────────────────────────────────────────────────────────

  const buttonStyles = [
    styles.button,
    buttonWebStyles,
    isSelected ? styles.buttonActive : undefined,
    isHovered ? styles.buttonHover : undefined,
    isActive ? buttonActiveWebStyles : undefined,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <Pressable
        accessibilityRole="button"
        ref={ref}
        style={buttonStyles}
        onPress={onPress}
      >
        {children}
      </Pressable>

      <FeatureFlagOpenUrlButton name={name} />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    columnGap: 8,
  },
  button: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['2'],
    padding: theme.spacing['3'],
    borderRadius: theme.radius.medium,
    borderWidth: 1,
    borderColor: theme.colors.NEUTRAL_3,
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  buttonActive: {
    backgroundColor: theme.colors.GREEN_4,
    borderColor: theme.colors.GREEN_4,
  },
  buttonHover: {
    borderColor: theme.colors.NEUTRAL_1,
  },
});

const buttonWebStyles = webOnlyStyles({
  transition: `
    background-color ${theme.transitions.base}ms,
    border-color ${theme.transitions.base}ms,
    opacity ${theme.transitions.base}ms,
    transform ${theme.transitions.base}ms
  `,
});
const buttonActiveWebStyles = webOnlyStyles({
  transform: 'translateY(3px)',
});

// ─── Types ───────────────────────────────────────────────────────────────────

type FeatureFlagToggleButtonProps = {
  name: string;
  onPress: () => void;
  children: ReactNode;
  isSelected?: boolean;
};

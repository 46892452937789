/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MenuRedirectQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type MenuRedirectQuery = {
  readonly __typename?: 'Query';
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
  } | null;
};

export const MenuRedirectDocument = gql`
  query MenuRedirect($id: ID!) {
    restaurant(id: $id) {
      id
    }
  }
`;

export function useMenuRedirectQuery(
  options: Omit<Urql.UseQueryArgs<MenuRedirectQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MenuRedirectQuery, MenuRedirectQueryVariables>({
    query: MenuRedirectDocument,
    ...options,
  });
}

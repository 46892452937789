import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, Modal, TextLinkify, theme, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const AccountDeletionFirstStep = (props: FirstStepProps) => {
  const { body, isCancellingSubscription, requestCancelSubscription } = props;

  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  return (
    <>
      <Modal.Headline>{t('account.delete.headline')}</Modal.Headline>
      <Modal.Subhead>{t('account.delete.subhead.step1')}</Modal.Subhead>
      <Modal.BodyText>
        <TextLinkify>{body}</TextLinkify>
      </Modal.BodyText>

      <Modal.Footer withoutTopBorder>
        <Button
          testID="account.subscription.cancel"
          size={match(['medium', 'large'])}
          style={styles.cancelSubscriptionCTA}
          isLoading={isCancellingSubscription}
          onPress={requestCancelSubscription}
        >
          {t('account.delete.cancelSubscription')}
        </Button>
      </Modal.Footer>
    </>
  );
};

type FirstStepProps = Readonly<{
  body: string;
  isCancellingSubscription: boolean;
  requestCancelSubscription: () => void;
}>;

const styles = StyleSheet.create({
  cancelSubscriptionCTA: {
    marginTop: theme.spacing['4'],
  },
});

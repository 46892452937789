import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  type AddressType,
  LoadingPlaceholder,
  useResponsive,
} from '@sg/garnish';
import {
  BodyText,
  Button,
  IllusEmployeeBag,
  Image,
  theme,
  VStack,
} from '@sg/garnish';

import { OrderCard } from '@order/components';
import type { OrderHistoryQuery } from '@order/graphql';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';
import { useLineItemsNames } from '@order/shared/hooks';

import { useOrderStatusNavigation } from '../../../OrderStatusScreen/OrderStatus.navigation';
import { useOrderStatusTelemetry } from '../../../OrderStatusScreen/OrderStatus.telemetry';
import { usePaginatedOrders } from './hooks';
import { usePaginatedOrdersWithMachine } from './hooks/usePaginatedOrdersWithMachine';

// ─────────────────────────────────────────────────────────────────────────────

export const Orders = () => {
  const isInAppOrderCancellationEnabled = useFeatureFlag(
    'CELS-1476-in-app-order-cancellation',
  );

  if (isInAppOrderCancellationEnabled) {
    return <OrdersScreenWithInAppCancellation />;
  }

  return <OrdersScreenWithLegacyInAppCancellation />;
};

// ─── Variations ──────────────────────────────────────────────────────────────

const OrdersScreenWithInAppCancellation = () => {
  const {
    orders,
    isFetchingInitialOrders,
    isFetchingMoreOrders,
    canFetchMoreOrders,
    fetchMoreOrders,
    updateCancelledOrder,
  } = usePaginatedOrdersWithMachine();

  return (
    <OrdersScreenAccountContainer
      // @ts-expect-error TS(2322): Type '{ readonly __typename?: "Order" | undefined;... Remove this comment to see the full error message
      orders={orders}
      fetchMoreOrders={fetchMoreOrders}
      isFetchingInitialOrders={isFetchingInitialOrders}
      isFetchingMoreOrders={isFetchingMoreOrders}
      onCancelOrder={updateCancelledOrder}
      canFetchMoreOrders={canFetchMoreOrders}
    />
  );
};

const OrdersScreenWithLegacyInAppCancellation = () => {
  const {
    orders,
    canLoadMore,
    isLoading,
    refetchOrderHistory,
    handleLoadMore,
  } = usePaginatedOrders();

  const isLoadingInitialOrders = isLoading && orders.length === 0;

  return (
    <OrdersScreenAccountContainer
      orders={orders}
      fetchMoreOrders={handleLoadMore}
      isFetchingInitialOrders={isLoadingInitialOrders}
      isFetchingMoreOrders={isLoading}
      onCancelOrder={refetchOrderHistory}
      canFetchMoreOrders={canLoadMore}
    />
  );
};

// ─── Components ──────────────────────────────────────────────────────────────

const OrdersScreenAccountContainer = (
  props: OrdersScreenAccountContainerProps,
) => {
  const { isFetchingInitialOrders, ...ordersScreenContentProps } = props;

  if (isFetchingInitialOrders) return <OrdersLoadingView />;

  return <OrdersScreenContent {...ordersScreenContentProps} />;
};

export const OrdersScreenContent = (props: OrdersScreenContentProps) => {
  const {
    orders,
    canFetchMoreOrders,
    fetchMoreOrders,
    isFetchingMoreOrders,
    onCancelOrder,
  } = props;

  const { t } = useLocalizationContext();

  if (orders.length === 0) return <EmptyState />;

  return (
    <>
      <VStack accessibilityLabel={t('account.orders.list')}>
        {orders.map((order) => (
          <ConnectedOrderCard
            key={order.id}
            order={order}
            onOrderCancellationSuccess={onCancelOrder}
          />
        ))}
      </VStack>

      {orders.length > 0 && canFetchMoreOrders ? (
        <Button
          palette="secondary"
          testID="account.orders.load-more"
          accessibilityRole="button"
          accessibilityLabel={t('account.orders.load-more')}
          accessibilityHint={t('account.orders.load-more')}
          style={styles.button}
          isLoading={isFetchingMoreOrders}
          onPress={fetchMoreOrders}
        >
          {t('account.orders.load-more')}
        </Button>
      ) : null}
    </>
  );
};

const ConnectedOrderCard = (props: ConnectedOrderCardProps) => {
  const { order, onOrderCancellationSuccess } = props;

  const orderLineItemsNames = useLineItemsNames(order?.lineItems);
  // @ts-expect-error TS(2339): Property 'isCanceled' does not exist on type '{ re... Remove this comment to see the full error message
  const isOrderCanceled = Boolean(order?.isCanceled);

  const { navigateToOrderStatusScreen } = useOrderStatusNavigation();
  const { handleOrderStatusEvent } = useOrderStatusTelemetry();

  const handlePress = useCallback(() => {
    navigateToOrderStatusScreen(order.id);
    handleOrderStatusEvent('account.orders');
  }, [order.id, navigateToOrderStatusScreen, handleOrderStatusEvent]);

  return (
    <OrderCard
      orderId={order.id}
      orderType={convertOrderType(order)}
      wantedTime={order.wantedTime}
      restaurantId={order.restaurant.id}
      restaurantName={order.restaurant.name}
      addressName={order.deliveryOrderDetail?.address?.name ?? undefined}
      description={orderLineItemsNames}
      total={order.total ?? 0}
      assetUrl={order.asset?.url ?? ''}
      isCancellable={order.canCancel}
      isCanceled={isOrderCanceled}
      onPress={handlePress}
      onOrderCancellationSuccess={onOrderCancellationSuccess}
    />
  );
};

const convertOrderType = (order: Order): AddressType => {
  if (order.restaurant.isOutpost) return 'outpost';

  return order.orderType.toLowerCase() as AddressType;
};

const EmptyState = () => {
  const { t } = useLocalizationContext();

  return (
    <View style={styles.emptyContainer}>
      <Image
        source={IllusEmployeeBag}
        style={styles.emptyImage}
        aria-label={t('account.orders.empty.illustration-alt')}
      />
      <BodyText style={styles.noResultsText}>
        {t('account.orders.empty')}
      </BodyText>
    </View>
  );
};

const OrdersLoadingView = () => {
  const { match } = useResponsive();

  return (
    <LoadingPlaceholder
      palette="cream"
      rowHeight={ORDER_CARD_HEIGHT}
      rows={5}
      gridGap={match([theme.spacing[4], theme.spacing[6]])}
    />
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const ORDER_CARD_HEIGHT = 170;

// ─── Types ─────────────────────────────────────────────────────────────

type OrdersScreenAccountContainerProps = OrdersScreenContentProps &
  Readonly<{
    isFetchingInitialOrders: boolean;
  }>;

type OrdersScreenContentProps = Readonly<{
  orders: readonly Order[];
  canFetchMoreOrders: boolean;
  isFetchingMoreOrders: boolean;
  onCancelOrder: (orderId: string) => void;
  fetchMoreOrders: () => void;
}>;

type ConnectedOrderCardProps = Readonly<{
  order: Order;
  onOrderCancellationSuccess: (orderId: string) => void;
}>;

type Order = PlacedOrders[number];

type PlacedOrders = Extract<
  OrderHistoryQuery['orders'],
  Readonly<{ __typename: 'OrdersResponseSuccess' }>
>['orders'];

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyImage: {
    width: 220,
    height: 220,
    marginBottom: theme.spacing['4'],
  },
  noResultsText: {
    textAlign: 'center',
  },
  button: {
    minWidth: 200,
    maxWidth: 200,
    marginHorizontal: 'auto',
    marginTop: theme.spacing['6'],
  },
});

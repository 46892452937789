import { defineMessages } from 'react-intl';

export const addGiftCardScreenMessages = defineMessages({
  title: {
    defaultMessage: 'Redeem gift card',
    description: 'Add Gift Card Screen | Screen title',
  },
  titleLegacy: {
    defaultMessage: 'Add gift card',
    description: 'Add Gift Card Screen | Legacy screen title',
  },
});

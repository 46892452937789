import React, { useCallback } from 'react';
import type { TextProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import type {
  BottomTabBarProps,
  BottomTabNavigationOptions,
} from '@react-navigation/bottom-tabs';
import { theme } from '@garnish/constants';

import { type IconName } from '../Icon';
import { MainTabBarItem } from './MainTabBarItem';

// ─────────────────────────────────────────────────────────────────────────────

export const MainTabBar = (props: MainTabBarProps) => {
  const {
    state,
    descriptors,
    navigation,
    hiddenTabs,
    tabsDisplayingBadge = [],
    maxFontSizeMultiplier,
    ...viewProps
  } = props;

  return (
    <View style={styles.container} accessibilityRole="tablist" {...viewProps}>
      {state.routes.map((route, index) => {
        const { key, name } = route;

        if (hiddenTabs?.includes(name)) {
          return null;
        }

        const { options } = descriptors[key];

        const isActive = state.index === index;
        const hasBadge = tabsDisplayingBadge.includes(name);

        return (
          <MainTabBarItemContainer
            key={key}
            screenKey={key}
            name={name}
            options={options}
            isActive={isActive}
            hasBadge={hasBadge}
            navigation={navigation}
            maxFontSizeMultiplier={maxFontSizeMultiplier}
          />
        );
      })}
    </View>
  );
};

const MainTabBarItemContainer = (props: MainTabBarItemContainerProps) => {
  const {
    screenKey,
    name,
    options,
    isActive,
    hasBadge,
    navigation,
    maxFontSizeMultiplier,
  } = props;

  const {
    tabBarTestID,
    iconName,
    title,
    tabBarAccessibilityLabel,
    customNavigate,
    shouldForceOnPress,
    onPress,
  } = options;

  const itemTitle = title ?? name ?? '';

  // ─── Callbacks ───────────────────────────────────────────────────────

  const handlePress = useCallback(() => {
    const event = navigation.emit({
      type: 'tabPress',
      target: screenKey,
      canPreventDefault: true,
    });

    if (isActive && !shouldForceOnPress) return;

    if (event.defaultPrevented) return;

    onPress?.();

    // Optional navigation override for this tab.
    if (customNavigate) {
      customNavigate();

      return;
    }

    // The `merge: true` option makes sure that the params inside the tab screen are preserved
    navigation.navigate({ key: screenKey, merge: true });
  }, [
    navigation,
    screenKey,
    isActive,
    shouldForceOnPress,
    onPress,
    customNavigate,
  ]);

  const handleLongPress = useCallback(() => {
    navigation.emit({ type: 'tabLongPress', target: screenKey });
  }, [navigation, screenKey]);

  return (
    <MainTabBarItem
      testID={tabBarTestID}
      title={itemTitle}
      iconName={iconName}
      accessibilityLabel={tabBarAccessibilityLabel}
      isActive={isActive}
      hasBadge={hasBadge}
      maxFontSizeMultiplier={maxFontSizeMultiplier}
      onPress={handlePress}
      onLongPress={handleLongPress}
    />
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type MainTabBarProps = Readonly<{
  hiddenTabs?: readonly string[];
  tabsDisplayingBadge?: readonly string[];
  maxFontSizeMultiplier?: TextProps['maxFontSizeMultiplier'];
}> &
  BottomTabBarProps;

type MainTabBarItemContainerProps = Readonly<{
  screenKey: string;
  name: string;
  options: ItemOptions & BottomTabNavigationOptions;
  isActive: boolean;
  hasBadge: boolean;
  navigation: BottomTabBarProps['navigation'];
  maxFontSizeMultiplier?: TextProps['maxFontSizeMultiplier'];
}>;

type ItemOptions = Readonly<{
  iconName?: IconName;

  /**
   * A flag that allows the press event to be triggered even when the item is active.
   */
  shouldForceOnPress?: boolean;

  onPress?: () => void;

  /**
   * Optional callback used to override the default navigation behavior for this tab.
   */
  customNavigate?: () => void;
}>;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.APP_BACKGROUND,
    borderTopColor: theme.colors.OPACITY.DARK_KALE.DARK,
    borderTopWidth: 1,
    flexDirection: 'row',
  },
});

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { Header } from '@expo/html-elements';
import { theme } from '@garnish/constants';
import { BodyText, TitleText } from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';

import {
  JOIN_OR_SIGN_IN_SCREEN_BODY_MAX_FONT_SIZE_MULTIPLIER,
  JOIN_OR_SIGN_IN_SCREEN_TITLE_MAX_FONT_SIZE_MULTIPLIER,
} from '../../JoinOrSignInScreen.constants';
import { JoinOrSignInOpenEmailButton } from '../JoinOrSignInOpenEmailButton';

// ─────────────────────────────────────────────────────────────────────────────

export const JoinOrSignInVerificationStepHeader = (
  props: JoinOrSignInVerificationStepHeaderProps,
) => {
  const isOpenEmailBtnEnabled = useFeatureFlag(
    'CELS-1883-open-email-button-enabled',
    { listenForChanges: true },
  );

  return (
    <Header style={styles.header}>
      <TitleText
        sizeMatch={['32', '48']}
        maxFontSizeMultiplier={
          JOIN_OR_SIGN_IN_SCREEN_TITLE_MAX_FONT_SIZE_MULTIPLIER
        }
      >
        <FormattedMessage
          description="Join or Sign In | Verification step heading"
          defaultMessage="Please verify your email address"
        />
      </TitleText>

      <BodyText
        sizeMatch={['14', '16']}
        maxFontSizeMultiplier={
          JOIN_OR_SIGN_IN_SCREEN_BODY_MAX_FONT_SIZE_MULTIPLIER
        }
      >
        <FormattedMessage
          description="Join or Sign In | Verification step subheading start part"
          defaultMessage="We’ve sent an one-time passcode to"
        />
        <BodyText sizeMatch={['14', '16']} bold>
          {' '}
          <FormattedMessage
            description="Join or Sign In | Verification step subheading email part"
            defaultMessage="{email}."
            values={{ email: props.email }}
          />{' '}
        </BodyText>
        <FormattedMessage
          description="Join or Sign In | Verification step subheading end part"
          defaultMessage="Enter the code below in the next 20 minutes."
        />
      </BodyText>

      {isOpenEmailBtnEnabled ? <JoinOrSignInOpenEmailButton /> : null}
    </Header>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    alignItems: 'flex-start',
    rowGap: theme.spacing['4'],
    marginBottom: theme.spacing['10'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type JoinOrSignInVerificationStepHeaderProps = {
  email: string;
};

import React from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { theme } from '@sg/garnish';

import { REORDER_RAIL_CARD_SIZE_LG } from './constants';

export const StandardOrderShape = ({
  isInteracting,
}: Readonly<{
  isInteracting: boolean;
}>) => {
  return (
    <View
      style={[
        styles.standardOrderShape,
        isInteracting && styles.interactingStandard,
      ]}
    />
  );
};

export const CustomOrderShape = ({
  isInteracting,
}: Readonly<{
  isInteracting: boolean;
}>) => {
  return (
    <View
      style={[
        styles.customOrderShape,
        isInteracting && styles.interactingCustom,
      ]}
    />
  );
};

export const CustomOrderWithCustomNameShape = ({
  isInteracting,
}: Readonly<{
  isInteracting: boolean;
}>) => {
  return (
    <View>
      <Svg width="100%" viewBox="0 0 240 215" fill="none">
        <Path
          d="M236.32 93.9265C241.227 102.407 241.227 112.856 236.32 121.337L190.055 201.295C185.148 209.776 176.079 215 166.265 215L73.7351 215C63.9209 215 54.8523 209.776 49.9452 201.295L3.68032 121.337C-1.22675 112.856 -1.22675 102.407 3.68031 93.9265L49.9452 13.9683C54.8523 5.48753 63.921 0.263183 73.7351 0.263184L166.265 0.263192C176.079 0.263193 185.148 5.48754 190.055 13.9683L236.32 93.9265Z"
          fill={
            isInteracting ? theme.colors.CUCUMBER_HOVER : theme.colors.CUCUMBER
          }
        />
      </Svg>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  standardOrderShape: {
    width: '100%',
    height: '100%',
    borderRadius: theme.radius.large,
    backgroundColor: theme.colors.QUINOA,
  },

  customOrderShape: {
    width: '100%',
    height: '100%',
    borderRadius: REORDER_RAIL_CARD_SIZE_LG,
    backgroundColor: theme.colors.SKY,
  },

  interactingStandard: {
    backgroundColor: theme.colors.QUINOA_HOVER,
  },

  interactingCustom: {
    backgroundColor: theme.colors.SKY_HOVER,
  },
});

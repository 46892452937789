import React from 'react';

import { AccountSweetpassMembershipLoading } from '@order/components';

import type { SweetpassMembershipScreenProps } from '../../navigation/AppNavigation.props';
import { AccountSweetpassMembershipError } from './components';
import {
  useSweetpassAccountContentfulData,
  useSweetpassMembershipData,
  useSweetpassMembershipFailureEvent,
  useSweetpassMembershipSuccessEvent,
} from './hooks';
import { SweetpassMembershipScreenContainer } from './SweetpassMembershipScreenContainer';

export const SweetpassMembershipScreen = (
  props: SweetpassMembershipScreenProps,
) => {
  // ─── Contentful Data ─────────────────────────────────────────────────

  const { content, isLoadingContentfulData } =
    useSweetpassAccountContentfulData();

  // ─── Membership Data ─────────────────────────────────────────────────

  const {
    tierStatus,
    tier,
    nextTier,
    freeTrialPlanId,
    subscriptionPlanId,
    nextBillingDate,
    planPrice,
    savingAmount,
    changePlanForwardsLabel,
    changePlanBackwardsLabel,
    billingFrequencyUnit,
    hasPaidTierHistory,
    isEligibleToFreeTrial,
    joinDate,
    switchSubscriptionPrice,
    planIdAfterSwitch,
    activeSubscription,
    pendingSubscription,
    paymentMethods,
    activePaymentMethodId,
    loyaltyProfileError,
    hasMembershipError,
    isSweetpassMembershipLoading,
    isSubscriptionAnnual,
    hasPendingSubscription,
    cancelSubscriptionAtCycleEnd,
    reactivateSubscription,
    refetchMembership,
  } = useSweetpassMembershipData();

  // ─── Telemetry ───────────────────────────────────────────────────────

  useSweetpassMembershipSuccessEvent({
    isLoading: isSweetpassMembershipLoading,
    hasLoyaltyProfile: Boolean(tier) && !hasMembershipError,
  });

  useSweetpassMembershipFailureEvent({
    isLoading: isSweetpassMembershipLoading,
    hasLoyaltyProfile: Boolean(tier) && !hasMembershipError,
    loyaltyProfileError,
  });

  if (isLoadingContentfulData || isSweetpassMembershipLoading) {
    return <AccountSweetpassMembershipLoading />;
  }

  if (hasMembershipError) {
    return <AccountSweetpassMembershipError />;
  }

  return (
    <SweetpassMembershipScreenContainer
      {...props}
      content={content}
      tierStatus={tierStatus}
      tier={tier}
      nextTier={nextTier}
      freeTrialPlanId={freeTrialPlanId}
      subscriptionPlanId={subscriptionPlanId}
      nextBillingDate={nextBillingDate}
      planPrice={planPrice}
      savingAmount={savingAmount}
      changePlanForwardsLabel={changePlanForwardsLabel}
      changePlanBackwardsLabel={changePlanBackwardsLabel}
      billingFrequencyUnit={billingFrequencyUnit}
      hasPaidTierHistory={hasPaidTierHistory}
      isEligibleToFreeTrial={isEligibleToFreeTrial}
      joinDate={joinDate}
      activeSubscription={activeSubscription}
      paymentMethods={paymentMethods}
      activePaymentMethodId={activePaymentMethodId}
      switchSubscriptionPrice={switchSubscriptionPrice}
      planIdAfterSwitch={planIdAfterSwitch}
      isSubscriptionAnnual={isSubscriptionAnnual}
      hasPendingSubscription={hasPendingSubscription}
      pendingSubscription={pendingSubscription}
      cancelSubscriptionAtCycleEnd={cancelSubscriptionAtCycleEnd}
      reactivateSubscription={reactivateSubscription}
      refetchMembership={refetchMembership}
    />
  );
};

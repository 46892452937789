import { useMemo } from 'react';
import { type RequestPolicy } from 'urql';

import { useIsLoggedIn } from '@order/AuthMachine';
import { type PaymentMethodCard } from '@order/graphql';

import { usePaymentMethodCardsQuery } from './graphql/PaymentMethodCards.generated';

export const usePaymentMethods = (
  props: UsePaymentMethodCardsDataProps = {},
) => {
  const { pause = false, requestPolicy = 'cache-and-network' } = props;

  const isLoggedIn = useIsLoggedIn();

  const [{ data, fetching: isLoadingPaymentMethods }] =
    usePaymentMethodCardsQuery({
      requestPolicy,
      pause: !isLoggedIn || pause,
    });

  const paymentMethods = useMemo(
    () => data?.paymentMethodCards ?? [],
    [data?.paymentMethodCards],
  ) as PaymentMethodCard[];

  return {
    paymentMethods,
    isLoadingPaymentMethods,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UsePaymentMethodCardsDataProps = Readonly<{
  pause?: boolean;
  requestPolicy?: RequestPolicy;
}>;

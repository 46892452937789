import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { ELEVATIONS } from '@garnish/constants';

import { Button } from '../Button';
import type { ButtonProps } from '../Button.types';

export const ButtonCenterLocation = (props: ButtonCenterLocationProps) => {
  const {
    onPress,
    disabled,
    style,
    accessibilityLabel = DEFAULT_A11Y_LABEL,
    accessibilityHint = DEFAULT_A11Y_HINT,
    ...wrapperProps
  } = props;

  return (
    <View {...wrapperProps} style={[styles.wrapper, style]}>
      <Button
        {...BUTTON_BASIC_PROPS}
        accessibilityLabel={accessibilityLabel}
        accessibilityHint={accessibilityHint}
        style={styles.button}
        disabled={disabled}
        onPress={onPress}
        centerIcon="IconTarget"
      />
    </View>
  );
};

//
// ─── TYPES ──────────────────────────────────────────────────────────────────────
//

type ButtonCenterLocationProps = Readonly<{
  onPress?: () => void;
  disabled?: boolean;
}> &
  ViewProps;

//
// ─── CONSTANTS ──────────────────────────────────────────────────────────────────
//

export const DEFAULT_A11Y_LABEL = 'Center map';
export const DEFAULT_A11Y_HINT = 'Centers map to user location';

const BUTTON_BASIC_PROPS: ButtonProps = {
  accessibilityRole: 'button',
  palette: 'light',
  size: 'large',
};

//
// ─── STYLES ─────────────────────────────────────────────────────────────────────
//

const styles = StyleSheet.create({
  wrapper: {
    width: 40,
  },
  button: {
    height: 40,
    borderRadius: 40,
    paddingHorizontal: 0,
    ...ELEVATIONS['8'],
  },
});

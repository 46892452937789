import type { ReactNode } from 'react';
import React from 'react';
import { StyleSheet } from 'react-native';
import { BodyText, LoadingPlaceholder, theme, VStack } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type { SweetpassBillingHistoryQuery } from '../../graphql/SweetpassBillingHistory.generated';
import { SweetpassBillingHistoryTransaction } from '../SweetpassBillingHistoryTransaction';

// ─────────────────────────────────────────────────────────────────────────────

export const SweetpassBillingHistory = (
  props: SweetpassTransactionHistoryProps,
) => {
  const { billingHistory, isFetching } = props;

  if (isFetching) return <SweetpassTransactionHistoryLoading />;

  if (billingHistory.length === 0) return <SweetpassTransactionHistoryEmpty />;

  return (
    <SweetpassTransactionHistoryFulfilled billingHistory={billingHistory} />
  );
};

// ─── Components ──────────────────────────────────────────────────────────────

const SweetpassTransactionHistoryLoading = () => {
  return (
    <SweetpassTransactionHistoryStack>
      <LoadingPlaceholder
        palette="cream"
        rows={6}
        rowHeight={80}
        gridGap={10}
      />
    </SweetpassTransactionHistoryStack>
  );
};

const SweetpassTransactionHistoryFulfilled = (
  props: Pick<SweetpassTransactionHistoryProps, 'billingHistory'>,
) => {
  const { billingHistory } = props;

  return (
    <SweetpassTransactionHistoryStack withBorder>
      {billingHistory.map((transaction) => (
        <SweetpassBillingHistoryTransaction
          key={transaction.id}
          transaction={transaction}
        />
      ))}
    </SweetpassTransactionHistoryStack>
  );
};

const SweetpassTransactionHistoryEmpty = () => {
  const { t } = useLocalizationContext();

  return (
    <BodyText size={2} style={styles.noTransactionsText}>
      {t('account.sweetpass-membership.billing-history.no-transactions')}
    </BodyText>
  );
};

const SweetpassTransactionHistoryStack = (
  props: SweetpassTransactionHistoryStackProps,
) => {
  const { withBorder, children } = props;

  const stackStyles = withBorder
    ? styles.transactionsStackWithBorder
    : undefined;

  return (
    <VStack style={stackStyles} hasDivider gap={0}>
      {children}
    </VStack>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  transactionsStackWithBorder: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.DARK_KALE,
  },
  noTransactionsText: {
    textAlign: 'center',
    marginVertical: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassTransactionHistoryProps = Readonly<{
  billingHistory: TransactionsHistory;
  isFetching: boolean;
}>;

type SweetpassTransactionHistoryStackProps = Readonly<{
  withBorder?: boolean;
  children: ReactNode;
}>;

type TransactionsHistory = NonNullable<
  SweetpassBillingHistoryQuery['paymentSubscriptionProfile']
>['transactionHistory'];

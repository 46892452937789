import React from 'react';
import { StyleSheet, View } from 'react-native';
import { H1 } from '@expo/html-elements';
import { theme, TitleText, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { useCheckoutContext } from '../../CheckoutProvider';

export const ConnectedCheckoutHeader = () => {
  const context = useCheckoutContext();

  return <CheckoutHeader {...context} />;
};

export const CheckoutHeader = (
  props: Pick<ReturnType<typeof useCheckoutContext>, 'cartOrderType'>,
) => {
  const { cartOrderType } = props;

  const { t } = useLocalizationContext();
  const { match, minWidth } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const headerStyle = [match([styles.headerMobile, styles.headerTabletUp])];
  const headingStyles = [
    styles.heading,
    minWidth.isSM ? styles.headingSM : undefined,
  ];

  // ─────────────────────────────────────────────────────────────────────

  const orderTypeBasedHeading = t(`checkout.finish-order-${cartOrderType}`);

  return (
    <View style={headerStyle}>
      <H1 style={headingStyles}>
        <TitleText size={match([3, 4])} style={headingStyles}>
          {orderTypeBasedHeading}
        </TitleText>
      </H1>
    </View>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  headerMobile: {
    paddingBottom: theme.spacing['10'],
  },
  headerTabletUp: {
    paddingVertical: theme.spacing['10'],
  },
  heading: {
    marginVertical: 0,
  },
  headingSM: {
    textAlign: 'center',
    marginVertical: 0,
  },
});

import { useCallback } from 'react';
import { type NativeScrollEvent } from 'react-native/Libraries/Components/ScrollView/ScrollView';

import { useDebounceFn } from '../useDebounceFn';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A simple hook that returns a custom scroll handler that can be used with
 * `ScrollView` components, triggering callbacks when the list's end is reached.
 *
 * Pass the `event.nativeEvent` property, and it will trigger provided callbacks
 * based on the current scroll distance.
 *
 * - Supports both vertical and horizontal directions.
 * - Optional thresholds (for both directions) can be supplied.
 * - Supports a `pause` flag to temporarily disable listeners (helpful for infinite loading).
 * - Supports debouncing.
 *
 * NOTE: We are passing `event.nativeEvent` instead of whole event because of
 *       React event polling.
 */
export const useOnScrollEndReached = (params?: UseOnScrollEndReachedParams) => {
  const {
    pause = false,
    debounce = 100,
    endThresholdY = 0,
    endThresholdX = 0,
    onScrollEndReachedY,
    onScrollEndReachedX,
  } = params ?? {};

  // ─────────────────────────────────────────────────────────────────────

  const onScroll = useCallback(
    (nativeEvent: NativeScrollEvent) => {
      if (pause) return;

      const { contentSize, contentOffset, layoutMeasurement } = nativeEvent;

      const { width: contentWidth, height: contentHeight } = contentSize;

      const containerWidth = layoutMeasurement.width;
      const containerHeight = layoutMeasurement.height;

      // NOTE: Rounding is required because `contentOffset` sometimes returns
      //       float values.

      const currentScrollY = Math.round(contentOffset.y);
      const currentScrollX = Math.round(contentOffset.x);

      const distanceToEndY = contentHeight - currentScrollY - containerHeight;
      const distanceToEndX = contentWidth - currentScrollX - containerWidth;

      const hasReachedEndY = distanceToEndY <= endThresholdY;
      const hasReachedEndX = distanceToEndX <= endThresholdX;

      // ─────────────────────────────────────────────

      if (hasReachedEndY && onScrollEndReachedY !== undefined) {
        onScrollEndReachedY();
      }

      if (hasReachedEndX && onScrollEndReachedX !== undefined) {
        onScrollEndReachedX();
      }
    },
    [
      endThresholdX,
      endThresholdY,
      onScrollEndReachedX,
      onScrollEndReachedY,
      pause,
    ],
  );

  return useDebounceFn(onScroll, debounce);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseOnScrollEndReachedParams = {
  pause?: boolean;
  debounce?: number;
  endThresholdY?: number;
  endThresholdX?: number;
  onScrollEndReachedY?: () => void;
  onScrollEndReachedX?: () => void;
};

import { clamp } from 'date-fns';

/**
 * A small helper that handles date change event
 */
export function clampDate(params: ClampDateParams): Date {
  const { date, fromDate, toDate } = params;

  return clamp(date, { start: fromDate, end: toDate });
}

// ─── Types ───────────────────────────────────────────────────────────────────

type ClampDateParams = {
  date: Date;
  fromDate: Date;
  toDate: Date;
};

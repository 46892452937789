import React, { forwardRef } from 'react';
import { useCallback } from 'react';
import { logger as LOG } from '@garnish/logger';
import {
  HostedFrame,
  type HostedFrameMessageEvent,
  type HostedFrameRef,
  theme,
} from '@sg/garnish';

import { getEnvVars } from '@order/utils';

LOG.disable('BagStripe');
const logger = LOG.extend('BagStripe');

/**
 * Wrapper for the stripe hosted field.
 */
export const BagStripe = forwardRef<HostedFrameRef, BagStripeProps>(
  (props, ref) => {
    const { setAvailableExpressPayments, onPaymentMethodId } = props;

    const handleMessages = useCallback(
      (event: HostedFrameMessageEvent) => {
        const message = parseMessage(event);

        // Logging messages.
        if (message.log) logger.info(message);

        // Payment request setup result.
        if (message.paymentRequestReady || message.stripeFailure) {
          setAvailableExpressPayments({
            canApplePay: Boolean(message.canApplePay),
            canGooglePay: Boolean(message.canGooglePay),
          });
        }

        // Payment processing result.
        if (message.paymentMethodId) {
          onPaymentMethodId(message.paymentMethodId);
        }
      },
      [setAvailableExpressPayments, onPaymentMethodId],
    );

    return (
      <HostedFrame
        ref={ref}
        frameId="BAG.STRIPE"
        frameHeight={theme.spacing['24']}
        source={source}
        onMessage={handleMessages}
      />
    );
  },
);

// ─── Helpers ────────────────────────────────────────────────────────────────

const parseMessage = (event: HostedFrameMessageEvent) => {
  const dataString = event.nativeEvent.data ?? '{}';
  const parsedData = JSON.parse(dataString) as HostedFieldsPayload;

  return parsedData;
};

// ─── Types ──────────────────────────────────────────────────────────────────

type HostedFieldsPayload = Readonly<{
  log?: string;
  paymentMethodId?: string;
  paymentRequestReady?: boolean;
  stripeFailure?: boolean;
  canApplePay?: boolean;
  canGooglePay?: boolean;
}>;

type BagStripeProps = {
  onPaymentMethodId: (paymentMethodId: string) => void;
  setAvailableExpressPayments: (params: PaymentAvailabilityParams) => void;
};

type PaymentAvailabilityParams = {
  canApplePay: boolean;
  canGooglePay: boolean;
};

// ─── Source ─────────────────────────────────────────────────────────────────

const source = {
  uri: getEnvVars().STRIPE_EMBEDDED_PAYMENT_URL,
};

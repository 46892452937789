import type { ReactNode, RefObject } from 'react';
import React, { useCallback, useEffect, useRef } from 'react';
import type { View } from 'react-native';

export const useRailItemsRefs = (railItems: readonly ReactNode[]) => {
  const { current: railItemsRefsMap } = useRef<RailItemsRefsMap>(new Map());

  // ─────────────────────────────────────────────────────────────────

  const setRailItemRef = useCallback(
    (_: any, index: number) => {
      railItemsRefsMap.set(index, React.createRef<View>());
    },
    [railItemsRefsMap],
  );

  useEffect(() => {
    railItemsRefsMap.clear();
    React.Children.forEach(railItems, setRailItemRef);
  }, [railItems, railItemsRefsMap, setRailItemRef]);

  // ─────────────────────────────────────────────────────────────────

  return railItemsRefsMap;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type RailItemsRefsMap = Map<number, RefObject<View>>;

import React, { type ComponentProps, forwardRef } from 'react';
import type { Text as RNText } from 'react-native';
import { StyleSheet } from 'react-native';
import { TYPE_CONFIG, WEIGHT_MAP } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { Text } from '../Text';
import { useFluidTextStyles } from '../useFluidTextStyles';

// ─────────────────────────────────────────────────────────────────────────────

export const DisplayWideText = forwardRef<RNText, DisplayWideTextProps>(
  (props, ref) => {
    const { size, sizeMatch = [], children, ...restProps } = props;

    if (sizeMatch.length > 0) {
      return (
        <DisplayWideTextStatic ref={ref} sizeMatch={sizeMatch} {...restProps}>
          {children}
        </DisplayWideTextStatic>
      );
    }

    return (
      <DisplayWideTextFluid ref={ref} size={size} {...restProps}>
        {children}
      </DisplayWideTextFluid>
    );
  },
);

// ─────────────────────────────────────────────────────────────────────────────

const DisplayWideTextStatic = forwardRef<RNText, DisplayWideTextStaticProps>(
  (props, ref) => {
    const { children, sizeMatch, bold, style, ...restProps } = props;

    const { match } = useResponsive();

    const fontSize = match(sizeMatch);
    const textStyles =
      DISPLAY_WIDE_TEXT_STYLES[fontSize] ??
      DISPLAY_WIDE_TEXT_DEFAULT_FONT_STYLES;
    const boldTextStyles = bold ? styles.boldText : undefined;
    const allStyles = [textStyles, boldTextStyles, style];

    return (
      <Text ref={ref} style={allStyles} bold={bold} {...restProps}>
        {children}
      </Text>
    );
  },
);

const DisplayWideTextFluid = forwardRef<RNText, DisplayWideTextFluidProps>(
  (props, ref) => {
    const {
      children,
      size = DISPLAY_WIDE_TEXT_DEFAULT_ORDINAL_SIZE,
      bold,
      style,
      ...restProps
    } = props;

    const fontSize = DISPLAY_WIDE_TEXT_PRESET_MAP[size];
    const textStyles =
      DISPLAY_WIDE_TEXT_STYLES[fontSize] ??
      DISPLAY_WIDE_TEXT_DEFAULT_FONT_STYLES;
    const fluidTextStyles = useFluidTextStyles(
      DISPLAY_WIDE_TEXT_PRESET_MAP[size],
      'DISPLAY_WIDE',
    );
    const boldTextStyles = bold ? styles.boldText : undefined;
    const allStyles = [textStyles, fluidTextStyles, boldTextStyles, style];

    return (
      <Text ref={ref} style={allStyles} bold={bold} {...restProps}>
        {children}
      </Text>
    );
  },
);

// ─── Constants ───────────────────────────────────────────────────────────────

const DISPLAY_WIDE_TEXT_STYLES = TYPE_CONFIG.DISPLAY_WIDE;

const DISPLAY_WIDE_TEXT_PRESET_MAP: Record<number, DisplayWideTextFontSize> = {
  1: '80',
  2: '40',
  3: '32',
  4: '32',
};

const DISPLAY_WIDE_TEXT_DEFAULT_ORDINAL_SIZE: DisplayWideTextSizeOrdinal = 1;
const DISPLAY_WIDE_TEXT_DEFAULT_FONT_SIZE: DisplayWideTextFontSize = '80';

const DISPLAY_WIDE_TEXT_DEFAULT_FONT_STYLES =
  DISPLAY_WIDE_TEXT_STYLES[DISPLAY_WIDE_TEXT_DEFAULT_FONT_SIZE];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  boldText: {
    fontWeight: WEIGHT_MAP.bold,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type DisplayWideTextProps = Readonly<{
  size?: DisplayWideTextSizeOrdinal;
  sizeMatch?: DisplayWideTextFontSize[];
}> &
  DisplayWideTextBaseProps;

type DisplayWideTextStaticProps = Readonly<{
  sizeMatch: DisplayWideTextFontSize[];
}> &
  DisplayWideTextBaseProps;

type DisplayWideTextFluidProps = Readonly<{
  size?: DisplayWideTextSizeOrdinal;
}> &
  DisplayWideTextBaseProps;

type DisplayWideTextBaseProps = ComponentProps<typeof Text>;

type DisplayWideTextSizeOrdinal = keyof typeof DISPLAY_WIDE_TEXT_PRESET_MAP;

type DisplayWideTextFontSize = keyof typeof DISPLAY_WIDE_TEXT_STYLES;

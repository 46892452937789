/* istanbul ignore file */
import type { EasingFunction } from 'react-native';
import { Platform } from 'react-native';
import { withTiming } from 'react-native-reanimated';

// Easing is breaking in native platforms.
export const withWebTiming = (
  value: number,
  options: Readonly<{
    duration: number;
    easing?: EasingFunction;
  }>,
) => {
  'worklet';

  return Platform.OS === 'web'
    ? withTiming(value, options)
    : withTiming(value, { duration: options.duration });
};

import { useEffect, useMemo } from 'react';

import { useFeatureFlag } from '@order/LaunchDarkly';

import { useChallengeCelebrationQuery } from '../../graphql/ChallengesCelebration.query.generated';
import { useChallengeCelebrationModalQuery } from '../../graphql/ChallengesCelebrationModal.query.generated';

export function useChallengeCompletionModal(orderId: string) {
  const shouldUseNewCelebrationModal = useFeatureFlag(
    'CELS-308-short-new-challenges-completion-modal-enabled',
  );

  // ─── Legacy Data ─────────────────────────────────────────────────────

  const [
    { data: legacyCelebration, fetching: fetchingLegacyCelebration },
    fetchLegacyChallengeCelebration,
  ] = useChallengeCelebrationQuery({
    pause: true,
    requestPolicy: 'cache-and-network',
  });

  // ─── Current Data ────────────────────────────────────────────────────

  const [
    { data: celebrationModal, fetching: fetchingCelebrationModal },
    fetchChallengeCelebrationModal,
    ,
  ] = useChallengeCelebrationModalQuery({
    pause: true,
    requestPolicy: 'cache-and-network',
  });

  // ─── Derived Data ────────────────────────────────────────────────────

  const celebration = shouldUseNewCelebrationModal
    ? celebrationModal?.challengeCelebrationV2
    : legacyCelebration?.challengeCelebration;

  const fetchingCelebration = shouldUseNewCelebrationModal
    ? fetchingCelebrationModal
    : fetchingLegacyCelebration;

  // ─── Effects ─────────────────────────────────────────────────────────
  // the useEffect is used to prevent duplicated network requests.

  useEffect(() => {
    const fetchCelebrationModal = shouldUseNewCelebrationModal
      ? fetchChallengeCelebrationModal
      : fetchLegacyChallengeCelebration;

    fetchCelebrationModal();
  }, [
    orderId,
    shouldUseNewCelebrationModal,
    fetchChallengeCelebrationModal,
    fetchLegacyChallengeCelebration,
  ]);

  return useMemo(
    () => ({ celebration, fetchingCelebration }),
    [celebration, fetchingCelebration],
  );
}

import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { Icon } from '../../../Icon';
import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const IngredientCardQuantity = (props: IngredientCardQuantityProps) => {
  const { children, iconName = 'IconCheck', shouldUseIcon } = props;

  return (
    <View style={styles.container} pointerEvents="none">
      <View style={styles.quantityContainer}>
        {shouldUseIcon ? (
          <Icon
            name={iconName}
            width={24}
            height={24}
            color={theme.colors.DARK_KALE}
          />
        ) : (
          <BodyText sizeMatch={['24']} maxFontSizeMultiplier={1.2}>
            {children}
          </BodyText>
        )}
      </View>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const QUANTITY_CONTAINER_SIZE = 48;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.large,
    backgroundColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
  },
  quantityContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: QUANTITY_CONTAINER_SIZE,
    height: QUANTITY_CONTAINER_SIZE,
    borderRadius: QUANTITY_CONTAINER_SIZE,
    borderWidth: 1.125,
    borderColor: theme.colors.DARK_KALE,
    backgroundColor: theme.colors.LIME,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type IngredientCardQuantityProps = {
  children?: string | number;
  iconName?: ComponentProps<typeof Icon>['name'];
  shouldUseIcon?: boolean;
};

import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button, Modal, ModalDialogue } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const FutureDateModal = ({
  visible,
  onConfirm,
}: FutureDateModalProps) => {
  const { t } = useLocalizationContext();
  const [isVisible, setIsVisible] = useState(visible);

  const handleDismiss = useCallback(() => {
    setIsVisible(false);
    onConfirm();
  }, [onConfirm]);

  useEffect(() => {
    if (!visible) return;
    setIsVisible(visible);
  }, [visible]);

  // ────────────────────────────────────────────────────────────────────────────────

  return (
    <View>
      <ModalDialogue
        visible={isVisible}
        size="small"
        testID="future-date-modal"
        accessibilityLabel={t('checkout.future-date-header')}
        headline={t('checkout.future-date-header')}
        body={t('checkout.future-date-message')}
      >
        <Modal.Footer withoutTopBorder>
          <Button
            testID="future-date-modal.close-btn"
            size="large"
            palette="primary"
            onPress={handleDismiss}
          >
            {t('general.got-it')}
          </Button>
        </Modal.Footer>
      </ModalDialogue>
    </View>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type FutureDateModalProps = Readonly<{
  visible: boolean;
  onConfirm: () => void;
}>;

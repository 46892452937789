import {
  HomeBanner,
  HomeColumnLeftSM,
  HomeColumnsContainerSM,
  HomeContainerSM,
  HomeContainerXS,
  HomeContentWrapperXS,
  HomeEyebrowText,
  HomeFloatingButtons,
  HomeFooterXS,
  HomeHeaderCta,
  HomeHeaderXS,
  HomeHeroCta,
  HomeHeroImageSM,
  HomeHeroImageXS,
  HomeHeroText,
} from './components';

export const Home = {
  Banner: HomeBanner,
  ContentWrapperXS: HomeContentWrapperXS,
  ContainerXS: HomeContainerXS,
  ContainerSM: HomeContainerSM,
  EyebrowText: HomeEyebrowText,
  FloatingButtons: HomeFloatingButtons,
  FooterXS: HomeFooterXS,
  HeaderCta: HomeHeaderCta,
  HeaderXS: HomeHeaderXS,
  HeroCta: HomeHeroCta,
  HeroImageXS: HomeHeroImageXS,
  HeroImageSM: HomeHeroImageSM,
  HeroText: HomeHeroText,
  ColumnsContainerSM: HomeColumnsContainerSM,
  ColumnLeftSM: HomeColumnLeftSM,
};

import React, { type ComponentProps, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View, type ViewStyle } from 'react-native';
import {
  BodyText,
  DisplayText,
  Scrollspy,
  theme,
  useContainerSpacing,
  useResponsive,
} from '@sg/garnish';

import { messages as globalMessages } from '../../CustomizationIngredientsGrid.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationIngredientsGridSection = (
  props: CustomizationIngredientsGridSectionProps,
) => {
  const { ingredientKindName, children, ...restProps } = props;

  const { match } = useResponsive();
  const containerSpacing = useContainerSpacing();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerDynamicStyles = useMemo<ViewStyle>(() => {
    return { paddingHorizontal: containerSpacing };
  }, [containerSpacing]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Scrollspy.TargetView
      style={[
        match([styles.containerXS, styles.containerSM]),
        containerDynamicStyles,
      ]}
      targetId={ingredientKindName}
      {...restProps}
    >
      <DisplayText sizeMatch={['24']} bold>
        <FormattedMessage
          {...globalMessages.ingredientKind}
          values={{ ingredient_kind: ingredientKindName }}
        />
      </DisplayText>

      <BodyText>
        <FormattedMessage
          {...globalMessages.ingredientKindHelperText}
          values={{ ingredient_kind: ingredientKindName }}
        />
      </BodyText>

      <View style={styles.contentContainer}>{children}</View>
    </Scrollspy.TargetView>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    paddingTop: theme.spacing['6'],
  },
  containerSM: {
    paddingTop: theme.spacing['10'],
  },
  contentContainer: {
    marginTop: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationIngredientsGridSectionProps = {
  ingredientKindName: string;
} & Omit<ComponentProps<typeof Scrollspy.TargetView>, 'targetId'>;

import React from 'react';
import type { ViewProps } from 'react-native';
import { theme, VStack } from '@sg/garnish';

import { getCreditCardIcon, PAYMENT_ADD } from '@order/constants';
import type { PaymentMethodCard } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { PaymentMethodListItem } from './PaymentMethodListItem';

export type PaymentMethodListProps = Readonly<{
  items: readonly PaymentMethodCard[];
  onSelectItem: (account: PaymentMethodCard) => void;
  onSelectAdd: () => void;
}> &
  ViewProps;

export const PaymentMethodList = ({
  items,
  onSelectItem,
  onSelectAdd,
}: PaymentMethodListProps): React.ReactElement => {
  const { t } = useLocalizationContext();

  return (
    <VStack gap={0} hasDivider={true} dividerColor={theme.colors.LIGHT}>
      {items.map((account) => (
        <PaymentMethodListItem
          key={account.id}
          testID={`account.edit-payment.${
            account.nickname?.split(' ')?.join('-') ?? account.id
          }`}
          accessibilityLabel={t('account.payment.title')}
          accessibilityHint={t('account.payment.title')}
          iconName={getCreditCardIcon(account.cardType)}
          title={account.nickname}
          subtitle={account.description}
          notice={
            // eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10
            account.expirationState === 'EXPIRED'
              ? t('account.payment.expired')
              : account.expirationState === 'NEARING'
                ? t('account.payment.expiring')
                : null
          }
          tag={account.isDefault ? t('account.payment.default') : null}
          onPress={() => {
            onSelectItem(account);
          }}
        />
      ))}
      <PaymentMethodListItem
        testID="account.edit-payment.new"
        accessibilityLabel={t('account.payment.title')}
        accessibilityHint={t('account.payment.title')}
        iconName={getCreditCardIcon(PAYMENT_ADD)}
        title={t('account.payment.new')}
        onPress={onSelectAdd}
      />
    </VStack>
  );
};

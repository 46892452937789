import type { LocationObject } from 'expo-location';

import { type DeliveryPreferenceType } from '@order/graphql';

import { type OrdersV2ForLocationQuery } from '../../../graphql/operations.generated';
import type { LocationsSearchByAreaQuery } from './graphql/LocationsSearchByArea.generated';
import type { LocationsSearchBySearchStringQuery } from './graphql/LocationsSearchBySearchString.generated';

// ─── Context ─────────────────────────────────────────────────────────────────

export type LocationSearchMachineContext = LocationSearchTypeContext &
  LocationsContext &
  DeliveryLocationContext;

type LocationSearchTypeContext = Readonly<{
  locationSearchType?: LocationsSearchType;
}>;

type LocationsContext = Readonly<{
  currentPosition: PositionCoordinates | undefined;
  searchArea: MapBounds | undefined;
  searchString: string;
  locations: LocationGroups | undefined;
  recentLocations: Location[] | undefined;
  nearbyLocations: LocationGroups | undefined;
  activeLocation: Location | undefined;
  focusedPinId: string | undefined;
  focusedLocationId: string | undefined;
}>;

type DeliveryLocationContext = Readonly<{
  deliverySearchString: string;
  deliveryLocation: Location | DeliveryLocationWithoutNearbyStore | undefined;
  deliveryAddressPredictions: ReadonlyArray<
    Readonly<{
      id: string;
      name: string;
      address: string;
    }>
  >;
  selectedDeliveryAddressPredictionId: string | undefined;
}>;

// ─── Events ──────────────────────────────────────────────────────────────────

export type LocationSearchMachineEvents =
  | LocationSearchTypeEvents[keyof LocationSearchTypeEvents]
  | LocationsEvents[keyof LocationsEvents]
  | DeliveryLocationEvents[keyof DeliveryLocationEvents]
  | DeliveryAddressFormEvents[keyof DeliveryAddressFormEvents]
  | RecentAndNearbyEvents[keyof RecentAndNearbyEvents];

type LocationSearchTypeEvents = Readonly<{
  SET_LOCATION_SEARCH_TYPE_PICKUP: {
    type: 'SET_LOCATION_SEARCH_TYPE_PICKUP';
  };
  SET_LOCATION_SEARCH_TYPE_DELIVERY: {
    type: 'SET_LOCATION_SEARCH_TYPE_DELIVERY';
  };
  SET_LOCATION_SEARCH_TYPE_OUTPOST: {
    type: 'SET_LOCATION_SEARCH_TYPE_OUTPOST';
  };
}>;

type LocationsEvents = Readonly<{
  SEARCH_BY_STRING: {
    type: 'SEARCH_BY_STRING';
    searchString: string;
  };
  SEARCH_BY_AREA: {
    type: 'SEARCH_BY_AREA';
    latitude: number;
    longitude: number;
  };
  SEARCH_BY_CURRENT_POSITION: {
    type: 'SEARCH_BY_CURRENT_POSITION';
  };
  SEARCH_FOR_INITIAL_LOCATIONS_BY_CURRENT_POSITION: {
    type: 'SEARCH_FOR_INITIAL_LOCATIONS_BY_CURRENT_POSITION';
  };
  SET_SEARCH_AREA: {
    type: 'SET_SEARCH_AREA';
    searchArea: MapBounds;
  };
  SET_FOCUSED_PIN_ID: {
    type: 'SET_FOCUSED_PIN_ID';
    id: string;
  };
  SET_FOCUSED_CARD_ID: {
    type: 'SET_FOCUSED_CARD_ID';
    id: string;
  };
  NAVIGATE_TO_LOCATION: {
    type: 'NAVIGATE_TO_LOCATION';
    restaurantSlug: string;
    entryPoint?: LocationNavigationEntryPoint;
  };
  LOCATION_WARNING_CANCEL: {
    type: 'LOCATION_WARNING_CANCEL';
  };
  LOCATION_WARNING_CONFIRM: {
    type: 'LOCATION_WARNING_CONFIRM';
  };
}>;

type DeliveryLocationEvents = Readonly<{
  SET_DELIVERY_SEARCH_STRING: {
    type: 'SET_DELIVERY_SEARCH_STRING';
    searchString: string;
  };
  SEARCH_BY_DELIVERY_ADDRESS: {
    type: 'SEARCH_BY_DELIVERY_ADDRESS';
    addressId?: string;
  };
  SET_DEFAULT_DELIVERY_LOCATION: {
    type: 'SET_DEFAULT_DELIVERY_LOCATION';
  };
  SET_DELIVERY_PREDICTION_PLACE_ID: {
    type: 'SET_DELIVERY_PREDICTION_PLACE_ID';
    placeId: string;
  };
  NAVIGATE_TO_DELIVERY_LOCATION: {
    type: 'NAVIGATE_TO_DELIVERY_LOCATION';
    entryPoint?: LocationNavigationEntryPoint;
  };
}>;

type DeliveryAddressFormEvents = Readonly<{
  CANCEL_DELIVERY_ADDRESS_FORM: {
    type: 'CANCEL_DELIVERY_ADDRESS_FORM';
  };
  SUBMIT_DELIVERY_ADDRESS_FORM: {
    type: 'SUBMIT_DELIVERY_ADDRESS_FORM';
    address: Location;
  };
}>;

type RecentAndNearbyEvents = {
  SHOW_RECENT_AND_NEARBY_LOCATIONS: {
    type: 'SHOW_RECENT_AND_NEARBY_LOCATIONS';
  };
  NAVIGATE_TO_RECENT_PICKUP_LOCATION: {
    type: 'NAVIGATE_TO_RECENT_PICKUP_LOCATION';
    restaurantSlug: string;
    entryPoint?: LocationNavigationEntryPoint;
  };
  NAVIGATE_TO_RECENT_OUTPOST_LOCATION: {
    type: 'NAVIGATE_TO_RECENT_OUTPOST_LOCATION';
    restaurantSlug: string;
    entryPoint?: LocationNavigationEntryPoint;
  };
  NAVIGATE_TO_RECENT_DELIVERY_LOCATION: {
    type: 'NAVIGATE_TO_RECENT_DELIVERY_LOCATION';
    addressId: string;
    entryPoint?: LocationNavigationEntryPoint;
  };
};

// ─── Services ────────────────────────────────────────────────────────────────

export type LocationSearchMachineServices = LocationsServices &
  DeliveryLocationServices &
  RecentAndNearbyLocations;

type LocationsServices = Readonly<{
  getCurrentPosition: {
    data: LocationObject;
  };
  getLastPlacedOrderLocation: {
    data: {
      locations: LocationGroups;
      locationType: LocationsSearchType;
    };
  };
  searchByString: {
    data: LocationGroups;
  };
  searchByArea: {
    data: LocationGroups;
  };
  checkIfShouldShowLocationWarning: {
    data: boolean;
  };
}>;

type RecentAndNearbyLocations = {
  getRecentAndNearbyLocations: {
    data: {
      currentPositionCoordinates: PositionCoordinates | undefined;
      recentLocations: Location[];
      nearbyLocations: LocationGroups;
    };
  };
  getNearbyLocations: {
    data: {
      currentPositionCoordinates: PositionCoordinates | undefined;
      nearbyLocations: LocationGroups;
    };
  };
};

type DeliveryLocationServices = Readonly<{
  searchByDeliveryLocationPlaceId: {
    data: Location | DeliveryLocationWithoutNearbyStore | undefined;
  };
  getDefaultDeliveryLocation: {
    data: Location | DeliveryLocationWithoutNearbyStore | undefined;
  };
  searchDeliveryAddresses: {
    data: ReadonlyArray<
      Readonly<{
        id: string;
        name: string;
        address: string;
      }>
    >;
  };
  addNewDeliveryAddress: {
    data: string; // Address ID
  };
}>;

// ─────────────────────────────────────────────────────────────────────────────

/**
 * 3 available location search types
 */
export type LocationsSearchType = 'pickup' | 'delivery' | 'outpost';

/**
 *  Universal representation of pickup, delivery or outpost location
 */
export type Location = {
  //
  // ─── General ──────────────────────────────────────────────────────

  id: string;
  name: string;
  imageUrl?: string;

  // ─── Geolocation Details ──────────────────────────────────────────

  lat: number;
  lng: number;
  street?: string;
  secondaryStreet?: string;
  notes?: string;
  city?: string;
  zipCode?: string;
  state?: string;
  country?: string;
  distance?: string;

  // ─── Restaurant Details ───────────────────────────────────────────

  restaurantSlug: string;
  restaurantName: string;
  restaurantId: string;
  storeHours?: string;
  acceptingOrders?: boolean;
  flexMessage?: string | null;
  notAcceptingOrdersReason?: string;
  isOutpost?: boolean;
  showDeliveryPriceDifferenciationDisclosure?: boolean;
  outpostPriceDifferentiationEnabled?: boolean;
  phone?: string;

  // ─── Delivery Details ─────────────────────────────────────────────

  isDelivery?: boolean;
  address: string;
  addressId?: string;
  estimatedDeliveryTime?: string;
  isClosed?: boolean;
  restaurantDeliveryFee?: number;
  deliveryVendorName?: string;
  deliveryVendorRestaurantId?: string;
  deliveryPreference?: DeliveryPreferenceType;

  // ─── Possible Warning Information ─────────────────────────────────

  showWarningDialog?: boolean;
  warningDialogTitle?: string;
  warningDialogDescription?: string;
  warningDialogTimeout?: number;

  // ─── Other Data ───────────────────────────────────────────────────

  attachedOrderId?: string;
};

export type DeliveryLocationWithoutNearbyStore = Pick<
  Location,
  | 'id'
  | 'addressId'
  | 'name'
  | 'lat'
  | 'lng'
  | 'address'
  | 'street'
  | 'secondaryStreet'
  | 'notes'
  | 'deliveryPreference'
  | 'city'
  | 'zipCode'
  | 'state'
  | 'country'
  | 'restaurantId'
  | 'restaurantName'
  | 'restaurantDeliveryFee'
  | 'deliveryVendorName'
  | 'deliveryVendorRestaurantId'
> &
  Readonly<{
    restaurantSlug: string | undefined;
    acceptingOrders?: boolean;
    isOutpost?: boolean;
  }>;

export type LocationGroups = Readonly<{
  pickup: Location[];
  outpost: Location[];
}>;

export type MapBounds = Readonly<{
  topLeft: PositionCoordinates;
  bottomRight: PositionCoordinates;
}>;

export type LocationNavigationEntryPoint =
  | 'pin'
  | 'card'
  | 'details'
  | 'delivery-cta';

export type PositionCoordinates = Readonly<{
  latitude: number;
  longitude: number;
}>;

/**
 * Model of Store Location returned from the back end
 */
export type StoreLocation = NonNullable<
  NonNullable<
    LocationsSearchByAreaQuery['searchLocationsByBoundingBox']
  >[number]
>['location'] &
  NonNullable<
    NonNullable<
      LocationsSearchBySearchStringQuery['searchLocationsByString']
    >[number]
  >['location'] & {
    showDeliveryPriceDifferenciationDisclosure?: boolean;
    outpostPriceDifferentiationEnabled?: boolean;
  };

export type RecentOrder = RecentOrders[number] & {
  restaurant: RecentOrders[number]['restaurant'] & {
    showDeliveryPriceDifferenciationDisclosure?: boolean;
    showOutpostPriceDifferenciationDisclosure?: boolean;
  };
};

type RecentOrders = Extract<
  NonNullable<NonNullable<OrdersV2ForLocationQuery['ordersV2']>>,
  { __typename: 'OrdersResponseSuccess' }
>['orders'];

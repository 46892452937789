import React from 'react';
import { StyleSheet, View } from 'react-native';
import { LoadingPlaceholder, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainRailLoadingPlaceholder = () => {
  return (
    <View style={styles.container}>
      <LoadingPlaceholder
        palette="cucumber"
        rows={2}
        rowHeight={[180, 64]}
        columns={4}
        columnWidth={216}
        gridGapX={theme.spacing['12']}
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

import React, { useCallback } from 'react';
import { Platform, StyleSheet, Text, View, type ViewProps } from 'react-native';
import {
  BodyText,
  Button,
  DisplayText,
  HStack,
  Image,
  theme,
  useResponsive,
} from '@sg/garnish';

import type { DietaryPropertyKind } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { IllusDietaryRestrictions } from './assets';
import { useDietaryRestrictionsMachine } from './dietaryRestrictionsMachine';

// ────────────────────────────────────────────────────────────────────────────────

export const DietaryRestrictions = (props: DietaryRestrictionsProps) => {
  const { title, shouldHideIllustration } = props;
  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();
  const { sortedRestrictionsNames, restrictions } =
    useDietaryRestrictionsMachine();

  const descriptionSize = currentBreakpoint.isXS ? 4 : 3;

  // ─────────────────────────────────────────────────────────────────

  return (
    <View
      testID="dietary-restrictions.container"
      style={styles.contentContainer}
    >
      {shouldHideIllustration ? null : (
        <View style={styles.illustrationWrapper}>
          <Image
            testID="dietary-restrictions.illustration"
            source={IllusDietaryRestrictions}
            style={styles.illustration}
            contentFit="contain"
            aria-label={t('dietary-restrictions.illustration.alt')}
          />
        </View>
      )}

      {title ? (
        <DietaryRestrictionsTitle>{title}</DietaryRestrictionsTitle>
      ) : null}

      <BodyText size={descriptionSize}>
        {t('dietary-restrictions.description-start')}
        <Text> </Text>
        <Text style={styles.descriptionHighlight}>
          {t('dietary-restrictions.description-highlight')}
        </Text>
        <Text>{t('dietary-restrictions.description-end')}</Text>
      </BodyText>

      <View style={styles.togglesWrapper}>
        <HStack
          itemsPerRow={3}
          style={styles.togglesStack}
          gap={theme.spacing['4']}
        >
          {sortedRestrictionsNames.map((restriction) => (
            <DietaryRestrictionToggle
              key={restriction}
              restriction={restriction}
              checked={restrictions[restriction]}
            />
          ))}
        </HStack>
      </View>

      <View>
        <View style={styles.noticeWrapper}>
          <BodyText size={5}>{t('dietary-restrictions.notice-1')}</BodyText>
        </View>

        <View>
          <BodyText size={5}>{t('dietary-restrictions.notice-2')}</BodyText>
        </View>
      </View>
    </View>
  );
};
// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const DietaryRestrictionsTitle = (props: ViewProps) => {
  const { currentBreakpoint } = useResponsive();

  const titleSize = currentBreakpoint.isXS ? 4 : 3;

  return (
    <View style={styles.titleWrapper}>
      <DisplayText size={titleSize}>{props.children}</DisplayText>
    </View>
  );
};

const DietaryRestrictionToggle = (props: DietaryRestrictionsToggleProps) => {
  const { t } = useLocalizationContext();
  const { toggleRestriction } = useDietaryRestrictionsMachine();
  const { restriction, checked } = props;

  const formattedRestrictionName = restriction.toLowerCase();

  // ─── HELPERS ──────────────────────────────────────────────────────────

  const onPress = useCallback(() => {
    toggleRestriction(restriction);
  }, [toggleRestriction, restriction]);

  // ────────────────────────────────────────────────────────────────────

  const accessibilityLabel = t('dietary-restrictions.toggle-restriction', {
    restriction: formattedRestrictionName,
  });

  const togglePalette = checked ? 'primaryOutline' : 'muted';

  return (
    <Button
      testID={`dietary.restriction.toggle.${formattedRestrictionName}`}
      palette={togglePalette}
      onPress={onPress}
      // @ts-expect-error TS(2322): Type '{ children: Element; accessibilityChecked?: ... Remove this comment to see the full error message
      focusOutlineColor={theme.colors.GRAY}
      style={styles.toggle}
      size="large"
      accessibilityLabel={accessibilityLabel}
      accessibilityState={{ checked }}
      accessibilityRole="checkbox"
      {...Platform.select({ web: { accessibilityChecked: checked } })}
    >
      <Text style={styles.toggleText} numberOfLines={1}>
        {formattedRestrictionName}
      </Text>
    </Button>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  contentContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    maxWidth: '100%',
  },
  illustrationWrapper: {
    paddingBottom: theme.spacing['6'],
  },
  illustration: {
    width: 232,
    height: 124,
  },
  titleWrapper: {
    paddingBottom: theme.spacing['6'],
  },
  descriptionHighlight: {
    color: theme.colors.CAUTION,
  },
  togglesWrapper: {
    width: '100%',
    paddingVertical: theme.spacing['6'],
  },
  togglesStack: {
    paddingVertical: theme.spacing['6'],
  },
  toggle: {
    paddingHorizontal: theme.spacing['1'],
  },
  noticeWrapper: {
    paddingBottom: theme.spacing['4'],
  },
  toggleText: {
    textTransform: 'capitalize',
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type DietaryRestrictionsProps = Readonly<{
  shouldHideIllustration?: boolean;
  title?: string;
}>;

type DietaryRestrictionsToggleProps = Readonly<{
  restriction: DietaryPropertyKind;
  checked: boolean;
}>;

import React, { type ComponentProps, useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { eachDayOfInterval } from 'date-fns';

import { PickersGroup } from '../../../Pickers/PickersGroup';
import { getFormattedLabelOfDate, getHoursOfDate } from '../../utils';

// ─────────────────────────────────────────────────────────────────────────────

export const DateAndHourPickerGroup = (props: DatePickerGroupProps) => {
  const {
    fromDate,
    toDate,
    selectedDate,
    'aria-label': ariaLabel,
    labels,
    onChange,
  } = props;

  // ─── Derived Data ────────────────────────────────────────────────────

  /**
   * Returns all available date and hour options within the specified range.
   */
  const selectedDateTimeOptions = useMemo<PickersOptionGroups>(() => {
    const allDays = eachDayOfInterval({ start: fromDate, end: toDate });

    return allDays.reduce((allDaysList, date) => {
      const dateFormattedLabel = getFormattedLabelOfDate({
        date,
        labels: { today: labels.today },
      });
      const hoursOfDate = getHoursOfDate({
        date,
        minDate: fromDate,
        maxDate: toDate,
      });

      return { ...allDaysList, [dateFormattedLabel]: hoursOfDate };
    }, {});
  }, [fromDate, labels.today, toDate]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onDateChange = useCallback(
    (value: string | number) => {
      onChange(new Date(value));
    },
    [onChange],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View>
      <PickersGroup
        modalTitle={labels.pickerModalTitle}
        submitBtnLabel={labels.pickerModalCtaLabel}
        optionsGroups={selectedDateTimeOptions}
        value={selectedDate.toISOString()}
        onSubmit={onDateChange}
        triggerIconLeft="IconClock"
        triggerIconRight="IconCaretRight"
        boldTriggerText={false}
        accessibilityLabel={ariaLabel}
      />
    </View>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type DatePickerGroupProps = {
  fromDate: Date;
  toDate: Date;
  selectedDate: Date;
  onChange: (date: Date | undefined) => void;
  'aria-label': string;
  labels: {
    today: string;
    pickerModalTitle: string;
    pickerModalCtaLabel: string;
  };
};

type PickersOptionGroups = ComponentProps<typeof PickersGroup>['optionsGroups'];

import { type TextStyle } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const paletteStyles: ButtonFluidIconPaletteStyles = {
  lime: {
    iconColor: theme.colors.LIME,
    iconText: { color: theme.colors.KALE },
  },
  disabled: {
    iconColor: theme.colors.OPACITY.DARK_KALE.DARK,
    iconText: { color: theme.colors.LIGHT },
  },
};

// ─── Types ───────────────────────────────────────────────────────────────────

export type ButtonFluidIconPalette = 'lime';

type ButtonFluidIconPaletteStyles = Record<
  ButtonFluidIconPalette | 'disabled',
  { iconColor: string; iconText: TextStyle }
>;

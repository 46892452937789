import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import { useCalloutOffset } from '../hooks';
import type { MarkerProps } from '../types';
import { CalloutContent } from './Callout.content';

// ────────────────────────────────────────────────────────────────────────────────

export const Callout = (props: MarkerProps) => {
  const {
    id: restaurantId,
    name: restaurantName,
    restaurantSlug,
    isMinimized,
    onCalloutPress,
  } = props;

  // Centering Web Tooltip.
  const [tooltipWidth, setTooltipWidth] = React.useState(0);
  const viewRef = React.useRef<View>(null);

  // ─── Helpers ─────────────────────────────────────────────────────

  const measureView = React.useCallback(() => {
    viewRef.current?.measure((_x, _y, width) => {
      setTooltipWidth(width);
    });
  }, [viewRef, setTooltipWidth]);

  const onPress = React.useCallback(() => {
    onCalloutPress?.({
      restaurantId,
      restaurantSlug,
      restaurantName,
    });
  }, [onCalloutPress, restaurantId, restaurantSlug, restaurantName]);

  // ─── Styles ──────────────────────────────────────────────────────

  const { y: calloutOffsetY } = useCalloutOffset({ isMinimized });
  const tooltipContainerOffsetStyle = useStyle(
    () => ({ top: calloutOffsetY }),
    [calloutOffsetY],
  );
  const tooltipContainerStyles = [
    styles.tooltipContainer,
    tooltipContainerOffsetStyle,
  ];
  const tooltipStyle = useStyle(
    () => ({ left: -(tooltipWidth / 2) }),
    [tooltipWidth],
  );

  // ─────────────────────────────────────────────────────────────────

  return (
    <Pressable
      accessibilityRole="button"
      testID={`callout-${restaurantId}`}
      onPress={onPress}
      style={tooltipContainerStyles}
    >
      <CalloutContent
        {...props}
        contentRef={viewRef}
        onLayout={measureView}
        style={[styles.tooltip, tooltipStyle]}
      />
      <View style={styles.tooltipArrow} />
      <View style={styles.tooltipArrowBorder} />
    </Pressable>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  tooltipContainer: {
    position: 'relative',
    zIndex: theme.zIndex.tooltip,
    width: 0,
    height: 0,
  },
  tooltip: {
    position: 'absolute',
    zIndex: theme.zIndex.tooltip,
    bottom: 10,
    paddingVertical: 8,
    paddingHorizontal: 10,
    borderRadius: 8,
    backgroundColor: theme.colors.WHITE,
    ...theme.elevations['2'],
  },
  tooltipArrow: {
    position: 'absolute',
    zIndex: theme.zIndex.tooltip,
    bottom: 0,
    left: -10,
    borderLeftWidth: 10,
    borderRightWidth: 10,
    borderTopWidth: 10,
    borderStyle: 'solid',
    borderLeftColor: theme.colors.OPACITY.TRANSPARENT,
    borderRightColor: theme.colors.OPACITY.TRANSPARENT,
    borderTopColor: theme.colors.WHITE,
  },
  tooltipArrowBorder: {
    position: 'absolute',
    zIndex: theme.zIndex.tooltip - 1,
    bottom: 0,
    left: -11,
    borderLeftWidth: 11,
    borderRightWidth: 11,
    borderTopWidth: 11,
    borderStyle: 'solid',
    borderLeftColor: theme.colors.OPACITY.TRANSPARENT,
    borderRightColor: theme.colors.OPACITY.TRANSPARENT,
    borderTopColor: theme.colors.CHARCOAL,
  },
});

import {
  OrderAgainRailAllOrdersCTA,
  OrderAgainRailContainer,
  OrderAgainRailHeader,
  OrderAgainRailHeading,
  OrderAgainRailList,
  OrderAgainRailLoadingPlaceholder,
} from './components';

export const OrderAgainRail = {
  Container: OrderAgainRailContainer,
  Header: OrderAgainRailHeader,
  Heading: OrderAgainRailHeading,
  ViewAllOrdersCTA: OrderAgainRailAllOrdersCTA,
  List: OrderAgainRailList,
  LoadingPlaceholder: OrderAgainRailLoadingPlaceholder,
};

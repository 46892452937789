import { useCallback, useState } from 'react';
import { logger as LOG } from '@garnish/logger';
import {
  type HostedFrameMessageEvent,
  useNoticeBannersStackContext,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

LOG.enable('BRAINTREE');
const logger = LOG.extend('BRAINTREE');

export const useBraintreeMessages = ({
  onSuccess,
  onError,
}: BraintreeMessagesHandlerProps) => {
  const [isLoadingFields, setLoadingFields] = useState(true);
  const [isTokenizing, setIsTokenizing] = useState(false);
  const [hasCardNumber, setHasCardNumber] = useState(false);
  const [hasExpDate, setHasExpDate] = useState(false);
  const [hasCvv, setHasCvv] = useState(false);
  const [hasPostalCode, setHasPostalCode] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const handleBraintreeError = useBraintreeErrorHandling({ onError });

  const handleBraintreeMessages = useCallback(
    (event: HostedFrameMessageEvent) => {
      const {
        nonce: receivedNonce,
        error,
        loaded,
        saving,
        fields,
        log,
      } = parseBraintreeMessage(event);

      // Clear tokenizing flag.
      setIsTokenizing(false);

      // Generic logging data from braintree.
      if (log) logger.info(log);

      // Error handling from braintree (Banner + Telemetry).
      if (error) handleBraintreeError(error);

      // Fields are ready to be used.
      if (loaded) setLoadingFields(false);

      // Credit card is being tokenized.
      if (saving) setIsTokenizing(true);

      // Update validity based on form content, toggles submit button.
      if (fields) {
        handleBraintreeValidation(fields, setCanSubmit);
        setHasCardNumber(Boolean(fields.number?.isValid));
        setHasExpDate(Boolean(fields.expirationDate?.isValid));
        setHasCvv(Boolean(fields.cvv?.isValid));
        setHasPostalCode(Boolean(fields.postalCode?.isValid));
      }

      // Nonce generated by the tokenize card function from braintree.
      if (receivedNonce) onSuccess(receivedNonce);
    },
    [handleBraintreeError, onSuccess],
  );

  return {
    isLoadingFields,
    isTokenizing,
    hasCardNumber,
    hasExpDate,
    hasCvv,
    hasPostalCode,
    canSubmit,
    handleBraintreeMessages,
  };
};

const useBraintreeErrorHandling = ({
  onError,
}: Pick<BraintreeMessagesHandlerProps, 'onError'>) => {
  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  return useCallback(
    (error: Readonly<{ message?: string }>) => {
      logger.error(error);

      addNoticeBanner({
        text: t('credit-card-form.error.save'),
        palette: 'caution',
      });

      onError(t('credit-card-form.error.save'), `Braintree: ${error?.message}`);
    },
    [t, addNoticeBanner, onError],
  );
};

const parseBraintreeMessage = (event: HostedFrameMessageEvent) => {
  const dataString = event.nativeEvent.data ?? '{}';
  const parsedData = JSON.parse(dataString) as HostedFieldsPayload;

  return parsedData;
};

const handleBraintreeValidation = (
  fields: Record<string, HostedFieldValidity>,
  setCanSubmit: (isValid: boolean) => void,
) => {
  const values = Object.values(fields);
  const isValid = values.every((field) => field?.isValid);

  setCanSubmit(isValid);
};

// ─── Types ─────────────────────────────────────────────────────────────────────

type BraintreeMessagesHandlerProps = Readonly<{
  onError: (userError?: string, systemError?: string) => void;
  onSuccess: (nonce: string) => void;
}>;

type HostedFieldValidity = Readonly<{
  isValid: boolean;
}>;
type HostedFieldsPayload = Readonly<{
  nonce?: string;
  error?: { message?: string };
  loaded?: boolean;
  saving?: boolean;
  fields?: Record<string, HostedFieldValidity>;
  log?: string;
}>;

import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import type { CloudinaryTransformConfig } from '@sg/garnish';
import { CloudinaryImage, useResponsive } from '@sg/garnish';

import { useProductDetailsScreenContext } from '../../ProductDetailsScreen.provider';

// ────────────────────────────────────────────────────────────────────────────────

export const ProductBanner = (props: ProductBannerProps) => {
  const { imgSrc, imgAriaLabel } = props;

  const { match, minWidth } = useResponsive();
  const { isCustomizationActive } = useProductDetailsScreenContext();

  // ─── Style ──────────────────────────────────────────────────────────

  const imageStyle = [
    styles.image,
    match([styles.imageMobile, styles.imageTablet, styles.imageDesktop]),
  ];

  // ─── Image ──────────────────────────────────────────────────────────────────────

  const imageWidth = match([400, 800, 1000]);
  const imageTransformConfig = useMemo<CloudinaryTransformConfig>(
    () => [
      match([CLOUDINARY_CONFIG_LARGE, CLOUDINARY_CONFIG_REGULAR]),
      { width: imageWidth },
    ],
    [imageWidth, match],
  );

  // ────────────────────────────────────────────────────────────────────

  if (isCustomizationActive) return null;

  return imgSrc ? (
    <View style={minWidth.isMD ? styles.wrapperDesktop : undefined}>
      <CloudinaryImage
        testID="product-banner-image"
        style={imageStyle}
        baseUrl={imgSrc}
        aria-label={imgAriaLabel}
        config={imageTransformConfig}
        contentFit="contain"
      />
    </View>
  ) : null;
};

// ─── Responsive Components ──────────────────────────────────────────────────────

export const ProductBannerMobile = (props: ProductBannerProps) => {
  const { minWidth } = useResponsive();

  if (minWidth.isMD) return null;

  return <ProductBanner {...props} />;
};

export const ProductBannerDesktop = (props: ProductBannerProps) => {
  const { minWidth } = useResponsive();

  if (!minWidth.isMD) return null;

  return <ProductBanner {...props} />;
};

// ─── Constants ───────────────────────────────────────────────────────────────

const CLOUDINARY_CONFIG_REGULAR = {
  crop: 'crop' as const,
  height: 0.66,
  width: 0.66,
};

const CLOUDINARY_CONFIG_LARGE = {
  crop: 'crop' as const,
  height: 0.6,
  width: 0.4,
};

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapperDesktop: {
    flex: 1,
  },
  image: {
    width: '100%',
    maxWidth: 1200,
    marginHorizontal: 'auto',
  },
  imageMobile: {
    height: 284,
    maxHeight: 284,
    flexShrink: 0,
  },
  imageTablet: {
    height: 512,
    maxHeight: 512,
  },
  imageDesktop: {
    flex: 1,
    height: undefined,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────────

type ProductBannerProps = Readonly<{
  imgAriaLabel?: string;
  imgSrc?: string;
}>;

import React, { memo } from 'react';
import { StyleSheet, View, type ViewStyle } from 'react-native';
import { format, isValid, parseISO } from 'date-fns';
import { BodyText, TagLabel, theme } from '@sg/garnish';

import { CreditType } from '@order/graphql';
import {
  type TranslationKeys,
  useLocalizationContext,
} from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

export const CreditsListItem = memo((props: CreditsListItemProps) => {
  const {
    createdAtDate,
    expirationDate,
    title,
    isTitleBold,
    amount,
    creditType,
    hasTopBorder,
    borderTopColor = 'GRAY',
  } = props;

  const { t, formatPrice } = useLocalizationContext();

  // ─────────────────────────────────────────────────────────────────────

  const formattedAmount = formatPrice(amount, 'USD');

  const createdAtDateFormatted = getExpirationDate(createdAtDate);
  const expirationDateObj = getExpirationDate(expirationDate);

  const expirationDateFormatted = expirationDateObj
    ? t('account.credit.expires', { date: expirationDateObj })
    : null;

  const label = creditType ? CREDITS_LIST_ITEM_LABEL[creditType] : null;
  const labelLocalized = label ? t(label) : null;

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerBorderStyles = hasTopBorder
    ? [styles.containerWithBorder, borderStyles[borderTopColor]]
    : undefined;
  const containerStyles = [styles.container, containerBorderStyles];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles}>
      <View style={styles.contentContainer}>
        {createdAtDateFormatted ? (
          <BodyText size={4} bold>
            {createdAtDateFormatted}
          </BodyText>
        ) : null}

        <BodyText size={4} bold={isTitleBold}>
          {title}
        </BodyText>

        {expirationDateFormatted ? (
          <BodyText size={5} style={styles.expirationDate}>
            {expirationDateFormatted}
          </BodyText>
        ) : null}

        {labelLocalized ? (
          <View style={styles.labelContainer}>
            <TagLabel>{labelLocalized}</TagLabel>
          </View>
        ) : null}
      </View>

      <BodyText size={4} bold>
        {formattedAmount}
      </BodyText>
    </View>
  );
});

// ─── Helpers ─────────────────────────────────────────────────────────────────

function getExpirationDate(
  expirationDate: CreditsListItemProps['expirationDate'],
): string | null {
  const isStringDate = typeof expirationDate === 'string';
  const expirationDateObj = isStringDate ? parseISO(expirationDate) : null;
  const hasValidExpirationDate =
    expirationDateObj !== null && isValid(expirationDateObj);

  if (!hasValidExpirationDate) return null;

  return format(expirationDateObj, 'MM/dd/yyyy');
}

// ─── Constants ───────────────────────────────────────────────────────────────

const CREDITS_LIST_ITEM_LABEL: Record<CreditType, TranslationKeys | null> = {
  [CreditType.OnlineOnly]: 'account.credit.list-item.label.online-only',
  [CreditType.InStoreOnly]: 'account.credit.list-item.label.in-store-only',
  [CreditType.AllNativeStorefronts]: null,
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: theme.spacing['6'],
  },
  containerWithBorder: {
    borderTopWidth: 1,
  },
  contentContainer: {
    flex: 1,
    alignItems: 'flex-start',
    marginRight: theme.spacing['2'],
  },
  expirationDate: {
    color: theme.colors.CHARCOAL,
    marginTop: theme.spacing['1'],
  },
  labelContainer: {
    marginTop: theme.spacing['2'],
  },
});

const borderStyles = StyleSheet.create<
  Record<CreditsListItemBorderColor, ViewStyle>
>({
  GRAY: {
    borderTopColor: theme.colors.GRAY,
  },
  DARK_KALE: {
    borderTopColor: theme.colors.DARK_KALE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CreditsListItemProps = Readonly<{
  title: string;
  isTitleBold?: boolean;
  amount: number;
  createdAtDate?: string | null;
  expirationDate?: string | null;
  creditType?: CreditType;
  hasTopBorder?: boolean;
  borderTopColor?: CreditsListItemBorderColor;
}>;

type CreditsListItemBorderColor = 'GRAY' | 'DARK_KALE';

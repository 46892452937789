import React, {
  type ComponentProps,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  type GestureResponderEvent,
  Platform,
  type TextStyle,
} from 'react-native';
import { A } from '@expo/html-elements';
import * as WebBrowser from 'expo-web-browser';

import { usePressableState } from '../../hooks';
import { webOnlyStyles } from '../../utils';
import { BodyText } from '../Text/BodyText';
import type { TextLinkPalette } from './TextLink.styles';
import { getConditionalStyles } from './TextLink.styles';

// ────────────────────────────────────────────────────────────────────────────────

export const TextLink = (props: TextLinkProps) => {
  const {
    testID,
    children,
    href = DEFAULT_HREF,
    newTab,
    disabled,
    active,
    onPress,
    palette = 'primary',
    style: externalStyle,
    underline = true,
    accessibilityLabel,
    accessibilityHint,
    ...restProps
  } = props;

  const textLinkRef = useRef(null);
  const pressableState = usePressableState(textLinkRef);
  const hasOnPress = !disabled && (href !== undefined || onPress !== undefined);

  // ─────────────────────────────────────────────────────────────────────

  const hrefAttrs = useMemo<ComponentProps<typeof A>['hrefAttrs']>(() => {
    if (!newTab) return;

    return { target: '_blank', rel: 'noopener noreferrer' };
  }, [newTab]);

  // ─── STYLES ──────────────────────────────────────────────────────

  const conditionalStyles = getConditionalStyles({
    pressableState,
    palette,
    disabled,
    active,
    hasOnPress,
  });
  const style = [conditionalStyles, linkWebStyles, externalStyle];
  const linkStyle = disabled && disabledLinkWebStyles;

  // ─── HELPERS ─────────────────────────────────────────────────────

  const handleOnPress = useCallback(
    async (event: GestureResponderEvent) => {
      onPress?.(event);

      // NOTE: Web only
      event?.preventDefault();

      if (href && href !== DEFAULT_HREF) {
        await WebBrowser.openBrowserAsync(href, {
          windowFeatures: newTab ? { target: '_blank' } : undefined,
        });
      }
    },
    [newTab, href, onPress],
  );

  // ─────────────────────────────────────────────────────────────────

  return (
    <BodyText
      underline={underline}
      accessibilityState={{ disabled }}
      style={style}
      {...restProps}
    >
      <A
        testID={testID}
        href={Platform.select({ web: href })}
        hrefAttrs={hrefAttrs}
        ref={textLinkRef}
        style={linkStyle}
        aria-disabled={disabled}
        accessibilityLabel={accessibilityLabel}
        accessibilityHint={accessibilityHint}
        onPress={hasOnPress ? handleOnPress : undefined}
      >
        {children}
      </A>
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const linkWebStyles = webOnlyStyles({
  textUnderlineOffset: 3,
}) as TextStyle;

const disabledLinkWebStyles = webOnlyStyles({
  cursor: 'not-allowed',
}) as TextStyle;

// ─── Constants ───────────────────────────────────────────────────────────────

// NOTE: The default `href` (`#`) is used to force `A` component to use
//       anchor instead of span on the web platform.
const DEFAULT_HREF = '#';

// ─── Types ───────────────────────────────────────────────────────────────────

type TextLinkProps = Readonly<{
  href?: string;
  newTab?: boolean;
  palette?: TextLinkPalette;
  style?: TextStyle;
  disabled?: boolean;
  active?: boolean;
  children: React.ReactNode;
}> &
  Omit<ComponentProps<typeof BodyText>, 'role'>;

import React, { useCallback, useEffect } from 'react';
import { RewardsRail } from '@sg/garnish';

import { useChallengesAndRewards } from '@order/ChallengesAndRewards';
import {
  useChallengesTelemetry,
  useRewardsWithDerivedData,
} from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import { BagRailContainer } from '../BagRailContainer';

// ────────────────────────────────────────────────────────────────────────────────

export const BagRewardsRail = (props: BagRewardsRailProps) => {
  const {
    isFetchingUpgradedRewards = false,
    hasFailedToFetchNewRewards = false,
    shouldUseCompactEmptyMode,
  } = props;

  const { t } = useLocalizationContext();
  const {
    selectedRewardId,
    selectedRewardIndex,
    setSelectedRewardId,
    rewards,
    isFetchingRewards,
    rewardsError,
  } = useChallengesAndRewards();

  // @ts-expect-error TS(2345): Argument of type 'readonly { readonly __typename?:... Remove this comment to see the full error message
  const rewardsWithExtraDetails = useRewardsWithDerivedData(rewards ?? []);

  const { handleRewardSelectedTelemetry } = useChallengesTelemetry();

  const hasNoRewards =
    !isFetchingRewards &&
    (!rewardsWithExtraDetails || rewardsWithExtraDetails?.length === 0);
  const hasError = Boolean(rewardsError);

  // ─────────────────────────────────────────────────────────────────

  const toggleReward = useCallback(
    (rewardId: string) => {
      setSelectedRewardId((currentlySelectedRewardId) =>
        currentlySelectedRewardId === rewardId ? '' : rewardId,
      );
    },
    [setSelectedRewardId],
  );

  // ─────────────────────────────────────────────────────────────────

  useEffect(() => {
    if (!selectedRewardId) return;
    handleRewardSelectedTelemetry(selectedRewardId);
  }, [selectedRewardId, handleRewardSelectedTelemetry]);

  // ─────────────────────────────────────────────────────────────────

  return (
    <BagRailContainer
      headerText={t('bag.rewards.header')}
      isEmpty={hasNoRewards}
      shouldUseCompactEmptyMode={shouldUseCompactEmptyMode}
      hasError={hasFailedToFetchNewRewards || hasError}
    >
      <RewardsRail
        rewards={rewardsWithExtraDetails}
        onPress={toggleReward}
        headerText={t('bag.rewards.header')}
        subtitle={t('bag.rewards.footer')}
        selectedRewardId={selectedRewardId}
        isLoading={isFetchingUpgradedRewards || isFetchingRewards}
        initialScrollIndex={selectedRewardIndex}
      />
    </BagRailContainer>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type BagRewardsRailProps = Readonly<{
  shouldUseCompactEmptyMode: boolean;

  // Indicates whether the app is fetching new rewards following the Sweetpass upgrade.
  isFetchingUpgradedRewards?: boolean;

  // Indicates whether the new premium awards were unable to be obtained.
  hasFailedToFetchNewRewards?: boolean;
}>;

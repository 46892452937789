import React, { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, Icon, theme, useResponsive } from '@sg/garnish';

import { LOCATION_RESULTS_CARD_DISCLAIMER_HEIGHT } from '../../../../LocationResults.constants';

// ─── Variations ──────────────────────────────────────────────────────────────

const LocationResultCardFeeDisclosureDelivery = (
  props: LocationResultCardFeeDisclosureProps,
) => {
  return (
    <LocationResultCardFeeDisclosureContent variation={props.variation}>
      <FormattedMessage
        defaultMessage="Higher menu prices apply for delivery"
        description="Location Search | Result Card | Delivery fee disclosure"
      />
    </LocationResultCardFeeDisclosureContent>
  );
};

const LocationResultCardFeeDisclosureOutpost = (
  props: LocationResultCardFeeDisclosureProps,
) => {
  return (
    <LocationResultCardFeeDisclosureContent variation={props.variation}>
      <FormattedMessage
        defaultMessage="Higher menu prices apply for outpost"
        description="Location Search | Result Card | Outpost fee disclosure"
      />
    </LocationResultCardFeeDisclosureContent>
  );
};

export const LocationResultCardFeeDisclosure = {
  Delivery: LocationResultCardFeeDisclosureDelivery,
  Outpost: LocationResultCardFeeDisclosureOutpost,
};

// ─── Components ──────────────────────────────────────────────────────────────

const LocationResultCardFeeDisclosureContent = (
  props: LocationResultCardFeeDisclosureContentProps,
) => {
  const { variation = 'with-container', children } = props;

  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const outerContainerStyles = match([
    styles.outerContainerXS,
    styles.outerContainerSM,
  ]);
  const containerStyles = [
    styles.container,
    match([styles.containerXS, styles.containerSM]),
  ];

  // ─────────────────────────────────────────────────────────────────────

  if (variation === 'simple') {
    return (
      <BodyText
        sizeMatch={['14']}
        numberOfLines={1}
        maxFontSizeMultiplier={1.2}
      >
        {children}
      </BodyText>
    );
  }

  return (
    <View style={outerContainerStyles}>
      <View style={containerStyles}>
        <View>
          <Icon name="IconBadgeInfo" width={14} height={14} />
        </View>

        <BodyText
          sizeMatch={['12', '14']}
          numberOfLines={1}
          maxFontSizeMultiplier={1.2}
        >
          {children}
        </BodyText>
      </View>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainerXS: {
    height: LOCATION_RESULTS_CARD_DISCLAIMER_HEIGHT,
    justifyContent: 'flex-end',
  },
  outerContainerSM: {
    marginBottom: theme.spacing['3'],
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: theme.colors.LIGHT,
    columnGap: theme.spacing['2'],
    paddingTop: theme.spacing['3'],
  },
  containerXS: {
    marginTop: theme.spacing['4'],
  },
  containerSM: {
    marginTop: theme.spacing['1'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultCardFeeDisclosureProps = {
  variation?: LocationResultCardFeeDisclosureVariation;
};

type LocationResultCardFeeDisclosureContentProps = {
  variation?: LocationResultCardFeeDisclosureVariation;
  children: ReactNode;
};

type LocationResultCardFeeDisclosureVariation = 'simple' | 'with-container';

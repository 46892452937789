import React, { useCallback, useRef } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { Link } from '@react-navigation/native';
import { theme, usePressableState, webOnlyStyles } from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';

import { type GiftCardsGridItemLinkProps } from './GiftCardsGridItemLink.types';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsGridItemLink = (props: GiftCardsGridItemLinkProps) => {
  const { children } = props;

  const cardRef = useRef<View>(null);
  const { isHovered, isFocused } = usePressableState(cardRef);

  // ─── Styles ──────────────────────────────────────────────────────────

  const gridItemPressableContainerStyles = [
    gridItemPressableContainerWebStyles,
    isHovered || isFocused ? styles.pressableContainerHover : undefined,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={gridItemPressableContainerStyles}>
      <View ref={cardRef}>
        <GiftCardsGridItemLinkPressable {...props}>
          {children}
        </GiftCardsGridItemLinkPressable>
      </View>
    </View>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const GiftCardsGridItemLinkPressable = (props: GiftCardsGridItemLinkProps) => {
  const {
    id,
    coverSrc,
    children,
    accessibilityLabel,
    isSignedIn,
    onSignedOutCardPress,
  } = props;

  const { track } = useTelemetry();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const trackGiftCardLinkPress = useCallback(() => {
    track('gift.skin_tapped', { skinID: id, skinURL: coverSrc });
  }, [coverSrc, id, track]);

  const onSignedOutGiftCardLinkPress = useCallback(() => {
    trackGiftCardLinkPress();
    onSignedOutCardPress();
  }, [onSignedOutCardPress, trackGiftCardLinkPress]);

  // ─────────────────────────────────────────────────────────────────────

  const pressableStyles = [gridItemPressableWebStyles, styles.pressable];

  if (!isSignedIn) {
    return (
      <Pressable
        aria-label={accessibilityLabel}
        accessibilityRole="button"
        style={pressableStyles}
        onPress={onSignedOutGiftCardLinkPress}
      >
        {children}
      </Pressable>
    );
  }

  return (
    <Link
      aria-label={accessibilityLabel}
      style={pressableStyles}
      onPress={trackGiftCardLinkPress}
      to={{
        screen: 'MainTabs',
        params: {
          screen: 'GiftCardsTab',
          params: { screen: 'GiftCardCheckout', params: { assetId: id } },
        },
      }}
    >
      {children}
    </Link>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressableContainerHover: {
    transform: [{ scale: 1.03 }],
    opacity: 0.9,
  },
  pressable: {
    display: 'flex',
    flexDirection: 'column',
  },
});

// ─── Web Styles ──────────────────────────────────────────────────────────────

const gridItemPressableContainerWebStyles = webOnlyStyles({
  willChange: 'transform, opacity',
  transition: `transform ${theme.transitions.base}ms ease-in-out, opacity ${theme.transitions.base}ms ease-in-out`,
});

const gridItemPressableWebStyles = webOnlyStyles({
  outlineOffset: 8,
});

import React, { useCallback } from 'react';

import { PaymentMethodListItem } from '@order/components';

import type { SweetpassBillingHistorySectionProps } from '../hooks';
import { SweetpassMembershipSection } from './SweetpassMembershipScreenSection';

export const SweetpassBillingHistorySection = (
  props: SweetpassBillingHistorySectionProps,
) => {
  const { testID, title, viewItem } = props;

  const handlePressView = useCallback(() => {
    viewItem.handler();
  }, [viewItem]);

  return (
    <SweetpassMembershipSection testID={testID} content="list" title={title}>
      <SweetpassMembershipSection.ActionList>
        <PaymentMethodListItem
          testID={testID?.concat('.full-history')}
          title={viewItem.title}
          accessibilityLabel={viewItem.accessibilityLabel}
          accessibilityHint={viewItem.accessibilityHint}
          iconName={viewItem.icon}
          onPress={handlePressView}
        />
      </SweetpassMembershipSection.ActionList>
    </SweetpassMembershipSection>
  );
};

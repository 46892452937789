import type { PathConfig } from '@react-navigation/native';

import { appNavigationLinking } from '../../AppNavigation.linking';
import type {
  MainTabsParamList,
  MenuTabStackParamList,
} from '../../AppNavigation.props';

/**
 * @returns List of screens that are in the menu stack.
 * e.g.: ['Locations', 'Menu', 'DeliveryMenu'].
 */
export const getMenuStackScreensNames = () => {
  const stackScreens = appNavigationLinking.config?.screens?.MainTabs;
  const menuTabScreens = (
    (stackScreens as PathConfig<MainTabsParamList>)?.screens
      ?.MenuTab as PathConfig<MenuTabStackParamList>
  )?.screens;

  return new Set(Object.keys(menuTabScreens ?? {}));
};

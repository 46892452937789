import { useCallback, useState } from 'react';
import { logger as LOG } from '@garnish/logger';
import {
  type HostedFrameMessageEvent,
  useNoticeBannersStackContext,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

LOG.enable('STRIPE');
const logger = LOG.extend('STRIPE');

export const useStripeMessages = ({
  onSuccess,
  onError,
}: StripeMessagesHandlerProps) => {
  const [isSubmittingStripe, setIsSubmittingStripe] = useState(false);
  const handleStripeError = useStripeErrorHandling({ onError });

  const handleStripeMessages = useCallback(
    (event: HostedFrameMessageEvent) => {
      const message = parseStripeMessage(event);
      const { paymentMethodId, error, saving } = message;

      // Generic logging data from stripe.
      logger.info(message);

      // Clear saving flag.
      setIsSubmittingStripe(false);

      // Error handling from braintree (Banner + Telemetry).
      if (error) handleStripeError(error);

      // Credit card is being tokenized.
      if (saving) setIsSubmittingStripe(true);

      // Payment Method ID generated by the submit function from stripe.
      if (paymentMethodId) onSuccess(paymentMethodId);
    },
    [handleStripeError, onSuccess],
  );

  return {
    isSubmittingStripe,
    handleStripeMessages,
  };
};

const useStripeErrorHandling = ({
  onError,
}: Pick<StripeMessagesHandlerProps, 'onError'>) => {
  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  return useCallback(
    (error: Readonly<{ message?: string }>) => {
      logger.error(error);

      addNoticeBanner({
        text: error?.message ?? t('credit-card-form.error.save'),
        palette: 'caution',
      });

      onError(t('credit-card-form.error.save'), `Stripe: ${error?.message}`);
    },
    [t, addNoticeBanner, onError],
  );
};

const parseStripeMessage = (event: HostedFrameMessageEvent) => {
  const dataString = event.nativeEvent.data ?? '{}';
  const parsedData = JSON.parse(dataString) as HostedFieldsPayload;

  return parsedData;
};

// ─── Types ─────────────────────────────────────────────────────────────────────

type StripeMessagesHandlerProps = Readonly<{
  onError: (userError?: string, systemError?: string) => void;
  onSuccess: (paymentMethodId: string) => void;
}>;

type HostedFieldsPayload = Readonly<{
  log?: string;
  loaded?: boolean;
  saving?: boolean;
  paymentMethodId?: string;
  error?: { message?: string };
}>;

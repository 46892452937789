import React from 'react';
import { HStack, LoadingPlaceholder } from '@sg/garnish';

import { useDynamicStyles } from '../FeaturedChannels.styles';

export const LoadingChannels = () => {
  const { cardGap, cardHeight, containerMarginStyle } = useDynamicStyles();

  return (
    <HStack
      testID="featured-channel.loading"
      itemsPerRow={2}
      gap={cardGap}
      style={containerMarginStyle}
    >
      <LoadingPlaceholder palette="quinoa" rowHeight={cardHeight} />
      <LoadingPlaceholder palette="quinoa" rowHeight={cardHeight} />
    </HStack>
  );
};

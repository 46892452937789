import { useState } from 'react';
import {
  runOnJS,
  useAnimatedReaction,
  useSharedValue,
} from 'react-native-reanimated';

/**
 * A small hook that tracks the active slide of the carousel in both the JS and
 * UI threads to avoid blocking that results in better animation.
 */
export const useTrackCarouselActiveSlide = (
  params: UseTrackActiveSlideParams,
) => {
  const { initialSlideIndex } = params;

  const carouselActiveSlideIndexSharedValue = useSharedValue(initialSlideIndex);
  const [carouselActiveSlideIndex, setCarouselActiveSlideIndex] =
    useState(initialSlideIndex);

  // ─────────────────────────────────────────────────────────────────────

  // Sync the UI thread state with its JS equivalent.
  useAnimatedReaction(
    () => carouselActiveSlideIndexSharedValue.value,
    (result) => {
      runOnJS(setCarouselActiveSlideIndex)(result);
    },
    [carouselActiveSlideIndexSharedValue],
  );

  // ─────────────────────────────────────────────────────────────────────

  return { carouselActiveSlideIndex, carouselActiveSlideIndexSharedValue };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseTrackActiveSlideParams = Readonly<{
  initialSlideIndex: number;
}>;

import type { FormatMessage } from '../types';
import { DateDurationUnit } from '../types';

/**
 * Returns a nicely formatted string representing a duration.
 */
export function getDurationFromUnit(
  props: DurationFromUnitProps,
): string | undefined {
  const { duration, durationUnit, singular, formatMessage } = props;

  if (!duration || !durationUnit) return;

  const isDurationInDaysOverOneWeek =
    durationUnit === DateDurationUnit.Day && Math.floor(duration / 7) >= 1;

  const durationValue = isDurationInDaysOverOneWeek
    ? Math.floor(duration / 7)
    : duration;

  if (durationValue === 1 || singular) {
    return getDurationFromUnitSingular({
      duration: durationValue,
      durationUnit: isDurationInDaysOverOneWeek
        ? DateDurationUnit.Week
        : durationUnit,
      formatMessage,
    });
  }

  return getDurationFromUnitPlural({
    duration: durationValue,
    durationUnit: isDurationInDaysOverOneWeek
      ? DateDurationUnit.Week
      : durationUnit,
    formatMessage,
  });
}

export function getDurationFromUnitSingular(props: PostValidationProps) {
  const { duration, durationUnit, formatMessage } = props;

  if (durationUnit === DateDurationUnit.Day) {
    return formatMessage('duration-unit.day', { duration });
  }

  if (durationUnit === DateDurationUnit.Week) {
    return formatMessage('duration-unit.week', { duration });
  }

  if (durationUnit === DateDurationUnit.Month) {
    return formatMessage('duration-unit.month', { duration });
  }

  return formatMessage('duration-unit.year', { duration });
}

export function getDurationFromUnitPlural(props: PostValidationProps) {
  const { duration, durationUnit, formatMessage } = props;

  if (durationUnit === DateDurationUnit.Day) {
    return formatMessage('duration-unit.days', { duration });
  }

  if (durationUnit === DateDurationUnit.Week) {
    return formatMessage('duration-unit.weeks', { duration });
  }

  if (durationUnit === DateDurationUnit.Month) {
    return formatMessage('duration-unit.months', { duration });
  }

  return formatMessage('duration-unit.years', { duration });
}

// ─── Types ───────────────────────────────────────────────────────────────────

type DurationFromUnitProps = Readonly<{
  duration?: number | null;
  durationUnit?: DateDurationUnit | null;
  singular?: boolean;
  formatMessage: FormatMessage;
}>;

type PostValidationProps = Readonly<{
  duration: number;
  durationUnit: DateDurationUnit;
  formatMessage: FormatMessage;
}>;

import type { MutableRefObject } from 'react';
import { useActive, useFocus, useHover } from 'react-native-web-hooks';

export const usePressableState = (ref: MutableRefObject<unknown>) => {
  const isHovered = useHover(ref);
  const isFocused = useFocus(ref);
  const isActive = useActive(ref);
  const isInteracting = isHovered || isActive;

  return { isInteracting, isHovered, isFocused, isActive };
};

export type PressableState = Readonly<{
  isPressed?: boolean;
  isHovered?: boolean;
  isActive?: boolean;
  isFocused?: boolean;
  isDisabled?: boolean;
}>;

import React from 'react';
import { Text } from 'react-native';

import { MenuMoreInfoText } from '../MenuMoreInfoText';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoRestaurantAddress = (
  props: MenuMoreInfoRestaurantAddressProps,
) => {
  const { shortAddress, city, state, zipCode } = props;

  const extraAddress = getRestaurantAddress({ city, state, zipCode });

  return (
    <MenuMoreInfoText>
      <Text>{shortAddress}</Text>

      {extraAddress ? (
        <>
          <Text>{'\n'}</Text>
          <Text>{extraAddress}</Text>
        </>
      ) : null}
    </MenuMoreInfoText>
  );
};

/**
 * Returns formatted address (extra data) of the restaurant by combining city,
 * state and zip code.
 */
function getRestaurantAddress(addressData: RestaurantAddressData) {
  const { city = '', state = '', zipCode = '' } = addressData;

  return [city, zipCode ? `${state} ${zipCode}` : state]
    .filter(Boolean)
    .join(', ')
    .trim();
}

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuMoreInfoRestaurantAddressProps = {
  shortAddress: string;
} & RestaurantAddressData;

type RestaurantAddressData = {
  city?: string;
  state?: string;
  zipCode?: string;
};

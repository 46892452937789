import { useCallback } from 'react';

import {
  type SubmitOrderUsingPaymentMethodCardInput,
  type ValidateRewardFailureCode,
} from '@order/graphql';

import { type PaymentMethodType } from '../../Checkout.utils';
import {
  type SubmitOrderUsingPaymentMethodCardMutation,
  type SubmitOrderUsingPaymentMethodMutation,
  useSubmitOrderUsingPaymentMethodMutation,
} from '../../graphql/Checkout.generated';

/**
 * Uses a {paymentMethodId} to submit an order.
 */
export function useSubmitOrderMutation(): CheckoutResponse {
  const [response, mutation] = useSubmitOrderUsingPaymentMethodMutation();

  const submitOrderMutation = useCallback(
    async (input: CheckoutInput) => {
      if (!input.paymentMethodId) return VALIDATION_ERROR;

      const submitResponse = await mutation({
        input: {
          paymentMethodId: input.paymentMethodId,
          orderId: input.orderId,
          wantedTime: input.wantedTime,
          useCredit: input.useCredit,
          pickupTip: input.pickupTip,
          dropoffLocationId: input.dropoffLocationId,
          contactNumber: input.contactNumber,
          includeUtensils: input.includeUtensils,
          deliveryOrderDetail: input.deliveryOrderDetail,
        },
      });
      const data = submitResponse.data?.submitOrderUsingPaymentMethod;
      const typename: CheckoutOutput['typename'] =
        data?.__typename ?? 'ValidationError';

      return {
        typename,
        isSuccess: /SubmitOrder.*Success/.test(typename as string),
        userError: (data as Readonly<{ message: string }>)?.message ?? '',
      };
    },
    [mutation],
  );

  const isSubmittingOrder = response.fetching;
  const result = response.data?.submitOrderUsingPaymentMethod;
  const rewardFailureCode =
    result?.__typename === 'RewardNotValidated'
      ? result.failureCode
      : undefined;

  return {
    isSubmittingOrder,
    rewardFailureCode,
    submitOrderMutation,
  };
}

// ─── Constants ───────────────────────────────────────────────────────────────

const VALIDATION_ERROR: CheckoutOutput = {
  typename: 'ValidationError',
  isSuccess: false,
  userError: '',
};

// ─── Types ───────────────────────────────────────────────────────────────────

type CheckoutInput = Readonly<{
  paymentMethodId: string;
  paymentMethodType: PaymentMethodType;
}> &
  Omit<SubmitOrderUsingPaymentMethodCardInput, 'paymentMethodCardId'>;

type CheckoutOutput = Readonly<{
  typename:
    | SubmitOrderUsingPaymentMethodCardMutation['submitOrderUsingPaymentMethodCard']['__typename']
    | SubmitOrderUsingPaymentMethodMutation['submitOrderUsingPaymentMethod']['__typename'];
  isSuccess: boolean;
  userError: string;
}>;

type CheckoutResponse = {
  isSubmittingOrder: boolean;
  rewardFailureCode: ValidateRewardFailureCode | undefined;
  submitOrderMutation: (input: CheckoutInput) => Promise<CheckoutOutput>;
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetOrderStatusQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetOrderStatusQuery = {
  readonly __typename?: 'Query';
  readonly orderStatus:
    | { readonly __typename?: 'InvalidOrder' }
    | {
        readonly __typename: 'OrderStatus';
        readonly flattenedOrderStatus: Types.FlattenedOrderStatuses;
        readonly order: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly wantedTime: string;
          readonly orderType: Types.OrderType;
          readonly canTrackOrderStatus: boolean;
          readonly isPendingFeedback: boolean;
          readonly dropoffLocation: string | null;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly name: string;
            readonly isOutpost: boolean;
          };
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly name: string | null;
            } | null;
          } | null;
        };
      }
    | null;
};

export type GetOrderStatusFieldsFragment = {
  readonly __typename: 'OrderStatus';
  readonly flattenedOrderStatus: Types.FlattenedOrderStatuses;
  readonly order: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly wantedTime: string;
    readonly orderType: Types.OrderType;
    readonly canTrackOrderStatus: boolean;
    readonly isPendingFeedback: boolean;
    readonly dropoffLocation: string | null;
    readonly restaurant: {
      readonly __typename?: 'Restaurant';
      readonly id: string;
      readonly name: string;
      readonly isOutpost: boolean;
    };
    readonly deliveryOrderDetail: {
      readonly __typename?: 'DeliveryOrderDetail';
      readonly estimatedDeliveryTime: string | null;
      readonly address: {
        readonly __typename?: 'Address';
        readonly id: string;
        readonly name: string | null;
      } | null;
    } | null;
  };
};

export const GetOrderStatusFieldsFragmentDoc = gql`
  fragment GetOrderStatusFields on OrderStatus {
    __typename
    flattenedOrderStatus
    order {
      id
      wantedTime
      orderType
      canTrackOrderStatus
      isPendingFeedback
      dropoffLocation
      restaurant {
        id
        name
        isOutpost
      }
      deliveryOrderDetail {
        estimatedDeliveryTime
        address {
          id
          name
        }
      }
    }
  }
`;
export const GetOrderStatusDocument = gql`
  query GetOrderStatus {
    orderStatus {
      ...GetOrderStatusFields
    }
  }
  ${GetOrderStatusFieldsFragmentDoc}
`;

export function useGetOrderStatusQuery(
  options?: Omit<Urql.UseQueryArgs<GetOrderStatusQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetOrderStatusQuery, GetOrderStatusQueryVariables>({
    query: GetOrderStatusDocument,
    ...options,
  });
}

import type { ComponentProps } from 'react';
import React, { useRef } from 'react';
import type { ViewStyle } from 'react-native';
import { Pressable, StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { BodyText, Icon, theme, usePressableState } from '@sg/garnish';

export const ShareOption = ({
  testID,
  hint,
  style,
  iconName,
  iconColor = 'white',
  iconSize,
  bgColor,
  borderColor,
  handleShare,
}: InternalShareOptionProps) => {
  const ref = useRef(null);
  const { isInteracting } = usePressableState(ref);

  const backgroundStyle = useStyle(
    () => ({ backgroundColor: bgColor, borderColor }),
    [bgColor, borderColor],
  );

  return (
    <Pressable
      ref={ref}
      testID={testID}
      accessibilityRole="button"
      accessibilityLabel={hint}
      style={styles.container}
      onPress={handleShare}
    >
      <View
        style={[
          styles.iconContainer,
          Boolean(borderColor) && styles.iconBorder,
          backgroundStyle,
          style,
        ]}
      >
        <Icon
          color={iconColor}
          name={iconName}
          width={iconSize}
          height={iconSize}
        />
      </View>
      <BodyText underline={isInteracting} size={5}>
        {hint}
      </BodyText>
    </Pressable>
  );
};

type InternalShareOptionProps = Readonly<{
  testID: string;
  hint: string;
  bgColor?: string;
  borderColor?: string;
  style?: ViewStyle;
  iconName: ComponentProps<typeof Icon>['name'];
  iconSize?: number;
  iconColor?: ComponentProps<typeof Icon>['color'];
  handleShare: () => void;
}>;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainer: {
    width: 48,
    height: 48,
    borderRadius: 48,
    marginVertical: theme.spacing['2'],
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconBorder: {
    borderWidth: 1,
  },
});

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import { ValidationErrorFieldsFragmentDoc } from '../../../graphql/fragments/ValidationErrorFields.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PdpLineItemDetailsFragment = {
  readonly __typename?: 'LineItem';
  readonly id: string;
  readonly quantity: number;
  readonly isCustom: boolean;
  readonly customName: string | null;
  readonly ingredients: ReadonlyArray<{
    readonly __typename?: 'Ingredient';
    readonly id: string;
    readonly name: string;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    readonly dietaryProperties: ReadonlyArray<{
      readonly __typename?: 'DietaryProperty';
      readonly id: string;
      readonly name: Types.DietaryPropertyKind;
    }>;
  }>;
  readonly mixedDressingDetails: ReadonlyArray<{
    readonly __typename?: 'MixedDressingDetails';
    readonly ingredientId: string;
    readonly weight: Types.DressingWeight;
  }>;
};

export type LineItemSuccessFieldsFragment = {
  readonly __typename?: 'LineItemSuccess';
  readonly lineItem: {
    readonly __typename?: 'LineItem';
    readonly id: string;
    readonly quantity: number;
    readonly isCustom: boolean;
    readonly customName: string | null;
    readonly ingredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename?: 'DietaryProperty';
        readonly id: string;
        readonly name: Types.DietaryPropertyKind;
      }>;
    }>;
    readonly mixedDressingDetails: ReadonlyArray<{
      readonly __typename?: 'MixedDressingDetails';
      readonly ingredientId: string;
      readonly weight: Types.DressingWeight;
    }>;
  };
};

export type LineItemNotFoundFieldsFragment = {
  readonly __typename?: 'LineItemNotFound';
  readonly message: string;
  readonly status: number;
};

export type GetLineItemQueryVariables = Types.Exact<{
  input: Types.LineItemInput;
}>;

export type GetLineItemQuery = {
  readonly __typename?: 'Query';
  readonly getLineItem:
    | {
        readonly __typename: 'LineItemNotFound';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'LineItemSuccess';
        readonly lineItem: {
          readonly __typename?: 'LineItem';
          readonly id: string;
          readonly quantity: number;
          readonly isCustom: boolean;
          readonly customName: string | null;
          readonly ingredients: ReadonlyArray<{
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: Types.DietaryPropertyKind;
            }>;
          }>;
          readonly mixedDressingDetails: ReadonlyArray<{
            readonly __typename?: 'MixedDressingDetails';
            readonly ingredientId: string;
            readonly weight: Types.DressingWeight;
          }>;
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      }
    | null;
};

export const PdpLineItemDetailsFragmentDoc = gql`
  fragment PDPLineItemDetails on LineItem {
    id
    quantity
    isCustom
    customName
    ingredients {
      id
      name
      asset {
        url
      }
      dietaryProperties {
        id
        name
      }
    }
    mixedDressingDetails {
      ingredientId
      weight
    }
  }
`;
export const LineItemSuccessFieldsFragmentDoc = gql`
  fragment LineItemSuccessFields on LineItemSuccess {
    lineItem {
      ...PDPLineItemDetails
    }
  }
  ${PdpLineItemDetailsFragmentDoc}
`;
export const LineItemNotFoundFieldsFragmentDoc = gql`
  fragment LineItemNotFoundFields on LineItemNotFound {
    message
    status
  }
`;
export const GetLineItemDocument = gql`
  query GetLineItem($input: LineItemInput!) {
    getLineItem: lineItem(input: $input) {
      __typename
      ...LineItemSuccessFields
      ...LineItemNotFoundFields
      ...ValidationErrorFields
    }
  }
  ${LineItemSuccessFieldsFragmentDoc}
  ${LineItemNotFoundFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useGetLineItemQuery(
  options: Omit<Urql.UseQueryArgs<GetLineItemQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetLineItemQuery, GetLineItemQueryVariables>({
    query: GetLineItemDocument,
    ...options,
  });
}

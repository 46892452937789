import React from 'react';
import { useIntl } from 'react-intl';
import { useWindowDimensions } from 'react-native';
import type { Asset } from 'contentful';
import { getContentfulImageUrl } from '@sg/garnish';

import { GiftCardsGrid } from '../../components';
import { giftCardsScreenCategoriesGridMessages as messages } from './GiftCardsScreenCategoriesGrid.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsScreenCategoriesGrid = (
  props: GiftCardsScreenHeaderProps,
) => {
  const { categories, isSignedIn, onSignedOutCardPress } = props;

  const { scale } = useWindowDimensions();

  const { formatMessage } = useIntl();

  const shouldJustifyCenter =
    categories.flatMap((category) => category.fields.giftCards).length < 3;

  return (
    <GiftCardsGrid.Container shouldJustifyCenter={shouldJustifyCenter}>
      {categories.map((category) => {
        const { giftCards } = category.fields;

        return giftCards.map((giftCard) => {
          const { id, title, cover } = giftCard.fields;
          const coverSrc = getContentfulImageUrl(cover.fields.file.url, {
            w: scale === 1 ? 400 : 800,
          });

          if (!coverSrc) return null;

          return (
            <GiftCardsGrid.Item
              key={id}
              id={id}
              coverSrc={coverSrc}
              accessibilityLabel={formatMessage(messages.gridItemA11yLabel, {
                gift_card: title,
              })}
              isSignedIn={isSignedIn}
              onSignedOutCardPress={onSignedOutCardPress}
              imgAccessibilityLabel={title}
            />
          );
        });
      })}
    </GiftCardsGrid.Container>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardsScreenHeaderProps = {
  categories: {
    fields: {
      giftCards: {
        fields: {
          id: string;
          title: string;
          cover: Asset;
        };
      }[];
    };
  }[];
  isSignedIn: boolean;
  onSignedOutCardPress: () => void;
};

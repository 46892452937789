import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { COLORS, ELEVATIONS, RADIUS } from '@garnish/constants';

import { Button } from '../Button';

export const ButtonZoom = (props: ButtonZoomProps) => {
  const { zoomInProps, zoomOutProps, ...wrapperProps } = props;

  return (
    <View style={styles.wrapper} {...wrapperProps}>
      <Button
        {...BUTTON_BASIC_PROPS}
        {...(BUTTON_PROPS_BY_TYPE.zoomIn as ButtonProps)}
        {...zoomInProps}
        style={[styles.button, styles.buttonZoomIn]}
      />

      {/* ------- separator ------- */}
      <View style={styles.separator} />

      <Button
        {...BUTTON_BASIC_PROPS}
        {...(BUTTON_PROPS_BY_TYPE.zoomOut as ButtonProps)}
        {...zoomOutProps}
        style={[styles.button, styles.buttonZoomOut]}
      />
    </View>
  );
};

//
// ─── TYPES ──────────────────────────────────────────────────────────────────────
//

type ButtonZoomProps = Readonly<{
  zoomInProps?: ZoomButtonProps;
  zoomOutProps?: ZoomButtonProps;
}> &
  ViewProps;
type ZoomButtonProps = Readonly<{
  onPress?: () => void;
  disabled?: boolean;
}> &
  Pick<ButtonProps, 'accessibilityLabel' | 'accessibilityHint'>;
type ButtonProps = React.ComponentProps<typeof Button>;

//
// ─── CONSTANTS ──────────────────────────────────────────────────────────────────
//

const BUTTON_BASIC_PROPS: ButtonProps = {
  accessibilityRole: 'button',
  pointerEvents: 'box-only', // prevents event propagation failure because of children/icon
  palette: 'light',
  size: 'large',
  // @ts-expect-error TS(2322): Type '{ accessibilityRole: "button"; pointerEvents... Remove this comment to see the full error message
  focusOutlineType: 'rectangular',
};

export const BUTTON_PROPS_BY_TYPE = {
  zoomIn: {
    centerIcon: 'IconPlus',
    accessibilityLabel: 'Zoom In',
    accessibilityHint: 'Increase zoom level',
  },
  zoomOut: {
    centerIcon: 'IconMinus',
    accessibilityLabel: 'Zoom Out',
    accessibilityHint: 'Decrease zoom level',
  },
};

//
// ─── STYLES ─────────────────────────────────────────────────────────────────────
//

const styles = StyleSheet.create({
  wrapper: {
    width: 40,
    borderRadius: RADIUS.medium,
    ...ELEVATIONS['8'],
  },
  separator: {
    height: 1,
    backgroundColor: COLORS.NEUTRAL_3,
  },
  button: {
    height: 40,
    borderRadius: 0,
    paddingHorizontal: 0,
  },
  buttonZoomIn: {
    borderTopStartRadius: RADIUS.medium,
    borderTopEndRadius: RADIUS.medium,
    borderBottomStartRadius: 0,
    borderBottomEndRadius: 0,
  },
  buttonZoomOut: {
    borderTopStartRadius: 0,
    borderTopEndRadius: 0,
    borderBottomStartRadius: RADIUS.medium,
    borderBottomEndRadius: RADIUS.medium,
  },
});

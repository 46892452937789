import React, { useCallback } from 'react';
import { Platform, ScrollView } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import * as Clipboard from 'expo-clipboard';
import {
  BodyText,
  HStack,
  IllusCredit_2,
  Image,
  ShareIcon,
  theme,
  useContainerSpacing,
  useNoticeBannersStackContext,
} from '@sg/garnish';

import {
  AccountReferFriendLoading,
  AccountScreenContainer,
  AccountScreenSection,
  AccountScreenTitle,
  NotFoundView,
} from '@order/components';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

import { useReferralShareUrlQuery } from './GraphQL/ReferFriend.generated';
import {
  EmailShareOption,
  FacebookShareOption,
  MoreShareOption,
  TwitterShareOption,
  WhatsappShareOption,
} from './shareOptions';

export const ReferFriendScreen = () => {
  const { t } = useLocalizationContext();
  const isFeatureEnabled = useFeatureFlag('permanent-refer-friend-enabled');

  // ─── Query ───────────────────────────────────────────────────────────

  const [response] = useReferralShareUrlQuery({ pause: !isFeatureEnabled });
  const { data, fetching } = response;
  const shareLink = data?.currentCustomer?.referralShareUrlEmail ?? '';

  if (!isFeatureEnabled) return <NotFoundView />;

  if (fetching) return <AccountReferFriendLoading />;

  return (
    <AccountScreenContainer screen="refer-friend">
      <AccountScreenTitle title={t('account.refer-friend.title')} />
      <AccountScreenSection>
        <ReferFriendScreenContent shareLink={shareLink} />
      </AccountScreenSection>
    </AccountScreenContainer>
  );
};

export const ReferFriendScreenContent = (props: ReferFriendScreenProps) => {
  const { shareLink } = props;

  const { t } = useLocalizationContext();
  const { push: addBanner } = useNoticeBannersStackContext();

  // ─── Share Options ───────────────────────────────────────────────────

  const canShowMore = Boolean(Platform.OS !== 'web' || navigator.share);

  // ─── Copy Callback ───────────────────────────────────────────────────

  // NOTE: iOS-only, since we're using a tooltip for `Web` and `Android` shows a native popup
  const showCopySuccessBanner = useCallback(() => {
    if (Platform.OS !== 'ios') return;

    addBanner(
      {
        id: 'refer-friend-copy-success',
        palette: 'success',
        text: t('general.link-copy-message'),
      },
      true,
    );
  }, [addBanner, t]);

  const handleCopyLink = useCallback(async () => {
    await Clipboard.setStringAsync(shareLink);
    showCopySuccessBanner();
  }, [shareLink, showCopySuccessBanner]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const containSpacing = useContainerSpacing();
  const shareLinksContainerDynamicStyle = useStyle(
    () => ({
      marginHorizontal: -containSpacing,
      paddingHorizontal: containSpacing,
    }),
    [containSpacing],
  );
  const shareLinksContainerStyle = [
    styles.sectionMargin,
    shareLinksContainerDynamicStyle,
  ];

  return (
    <View style={styles.container}>
      {/* Image */}
      <View style={styles.imageContainer}>
        <Image style={styles.image} source={IllusCredit_2} />
      </View>

      {/* Information */}
      <BodyText style={styles.titleMargin} size={2} bold>
        {t('account.refer-friend.subtitle')}
      </BodyText>

      <BodyText style={styles.sectionMargin}>
        {t('account.refer-friend.description')}
      </BodyText>

      {/* Share Icons Row */}
      <BodyText style={styles.titleMargin}>
        {t('account.refer-friend.share.hint')}
      </BodyText>

      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        style={shareLinksContainerStyle}
      >
        <HStack>
          <EmailShareOption shareLink={shareLink} />
          <FacebookShareOption shareLink={shareLink} />
          <TwitterShareOption shareLink={shareLink} />
          <WhatsappShareOption shareLink={shareLink} />
          {canShowMore ? <MoreShareOption shareLink={shareLink} /> : null}
        </HStack>
      </ScrollView>

      {/* Copy Link Row */}
      <BodyText style={styles.titleMargin}>
        {t('account.refer-friend.copy.hint')}
      </BodyText>

      <View style={styles.copyRow}>
        <BodyText numberOfLines={1} style={styles.shareLink} size={4}>
          {shareLink}
        </BodyText>

        <ShareIcon
          style={styles.copyIconMargin}
          iconName="IconCopy"
          onShare={handleCopyLink}
          placement="left"
        />
      </View>
    </View>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ReferFriendScreenProps = Readonly<{
  shareLink: string;
}>;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    maxWidth: 600,
  },
  imageContainer: {
    marginBottom: theme.spacing['6'],
    alignItems: 'center',
  },
  image: {
    width: 232,
    height: 200,
  },
  titleMargin: {
    marginBottom: theme.spacing['2'],
  },
  sectionMargin: {
    marginBottom: theme.spacing['10'],
  },
  shareLink: {
    flex: 1,
  },
  copyRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 32,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.GRAY,
  },
  copyIconMargin: {
    marginLeft: theme.spacing['2'],
  },
});

import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { type PaymentMethodCard } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { useSubmitPaymentMethodMutation } from './graphql/SubmitPaymentMethod.generated';

/**
 * Depending on the feature flag, this will either:
 * - Submit a Billing Account through monograph-svc.
 * - Submit both Billing Accounts and Payment Method Card through payments-svc.
 */
export function useSubmitPaymentMethod(props: SubmitPaymentMethodProps) {
  const { onSuccess } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { track } = useTelemetry();
  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  // ─── Payment Method Card Submission ──────────────────────────────────

  const [submitPaymentMethodCardResponse, submitPaymentMethodCardMutation] =
    useSubmitPaymentMethodMutation();

  const submitPaymentMethodCard = useCallback(
    async (payload: SubmitPaymentMethodPayload) => {
      const response = await submitPaymentMethodCardMutation({
        input: payload,
      });

      const paymentMethod = response.data?.updatePaymentMethod;
      const isSuccess = paymentMethod?.__typename === 'PaymentMethodCard';
      const userError = t('credit-card-form.error.save');

      if (isSuccess) {
        return { isSuccess, userError, paymentMethod };
      }

      return { isSuccess, userError, paymentMethod: undefined };
    },
    [submitPaymentMethodCardMutation, t],
  );

  // ─── Hybrid Payment Method Submission ────────────────────────────────

  const submitPaymentMethod = useCallback(
    async (payload: SubmitPaymentMethodPayload) => {
      const { isSuccess, userError, paymentMethod } =
        await submitPaymentMethodCard(payload);

      if (isSuccess) {
        track('payment_credit.save_card');
        addNoticeBanner({
          text: t('credit-card-form.save.success'),
          palette: 'success',
        });

        onSuccess(paymentMethod);

        return;
      }

      track('payment_credit.save_card_failed', { userError });
      addNoticeBanner({ text: userError, palette: 'caution' });
    },
    [t, track, addNoticeBanner, submitPaymentMethodCard, onSuccess],
  );

  return {
    submitPaymentMethod,
    isSubmitting: submitPaymentMethodCardResponse.fetching,
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type SubmitPaymentMethodProps = Readonly<{
  onSuccess: (paymentMethod: PaymentMethodCard) => void;
}>;

type SubmitPaymentMethodPayload = Readonly<{
  id: string;
  nickname: string;
  isDefault: boolean;
}>;

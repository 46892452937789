import { format, isToday } from 'date-fns';

/**
 * Returns a formatted label/name of the provided date.
 *
 * E.g. "Tue, Apr 23", "Sat, Aug 10"
 */
export function getFormattedLabelOfDate(
  params: GetFormattedLabelOfDateParams,
): string {
  const { date, labels } = params;

  const shouldUseTodayLabel = isToday(date) && labels?.today !== undefined;

  return shouldUseTodayLabel ? labels.today : format(date, 'E, LLL d');
}

// ─── Types ───────────────────────────────────────────────────────────────────

type GetFormattedLabelOfDateParams = {
  date: Date;
  labels?: {
    today: string;
  };
};

import { useMemo } from 'react';
import { type RequestPolicy } from 'urql';

import { useLoyaltyInfoBenefitsQuery } from './GraphQL/LoyaltyInfoBenefits.generated';

/**
 * Hooks up the loyalty profile points and rewards to the <Loyalty.InfoBenefits /> component.
 */
export const useLoyaltyInfoBenefits = (props?: UseLoyaltyInfoBenefits) => {
  const { requestPolicy = 'cache-and-network', pause = false } = props ?? {};

  const [{ data, fetching }] = useLoyaltyInfoBenefitsQuery({
    requestPolicy,
    pause,
  });

  return useMemo(() => {
    return {
      fetching,
      availablePoints: data?.loyaltyProfile?.points?.available ?? 0,
      benefits: (data?.loyaltyProfile?.pointsRewards ?? []).map(toBenefit),
    };
  }, [data?.loyaltyProfile, fetching]);
};

// ─── Helpers ───────────────────────────────────────────────────────────────

const toBenefit = (pointsReward: PointsReward) => {
  return {
    points: pointsReward.points,
    title: pointsReward.displayName,
    imageUrl: pointsReward.assetUrl ?? undefined,
  };
};

// ─── Types ──────────────────────────────────────────────────────────────────

type UseLoyaltyInfoBenefits = {
  requestPolicy?: RequestPolicy;
  pause?: boolean;
};

type PointsReward = Readonly<{
  id: string;
  points: number;
  assetUrl: string | null;
  displayName: string;
}>;

import type { RefObject } from 'react';
import { useCallback } from 'react';
import { type HostedFrameRef } from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';

import { injectSubmitRequest } from '../injection';

/**
 * Callbacks for submitting and handling the response from the Stripe SDK.
 */
export function useStripeFormSubmission(props: StripeFormSubmissionProps) {
  const { hostedFrameRef } = props;

  const { track } = useTelemetry();
  const handleSubmitStripeForm = useCallback(() => {
    injectSubmitRequest(hostedFrameRef);
  }, [hostedFrameRef]);

  const handleStripeError = useCallback(
    (userError?: string, systemError?: string) => {
      track('payment_credit.save_card_failed', { userError, systemError });
    },
    [track],
  );

  return {
    handleSubmitStripeForm,
    handleStripeError,
  };
}

// ─── Types ─────────────────────────────────────────────────────────────

type StripeFormSubmissionProps = Readonly<{
  hostedFrameRef: RefObject<HostedFrameRef>;
}>;

import { useLastInteractedStoreMachine } from './useLastInteractedStoreMachine';

/**
 * Returns last interacted store slug or delivery address ID
 * based on the following order with corresponding fallbacks.
 *
 * 1. Active cart's store.
 * 2. Last opened/visited menu store.
 * 3. Last order store.
 */
export const useLastInteractedStore = () => {
  const [state] = useLastInteractedStoreMachine();

  return state.context.lastInteractedStore;
};

import React, { type ComponentProps, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ScrollView, StyleSheet, View } from 'react-native';
import {
  type RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { theme } from '@garnish/constants';
import { Container, useResponsive } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import {
  AccountScreenTitle,
  RedeemGiftCardForm,
  SignedOutView,
  TransparentModal,
  useRedeemGiftCardDefaultValues,
} from '@order/components';
import { useCustomerCredit } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry } from '@order/Telemetry';

import type { ModalStackParamList } from '../../navigation/AppNavigation.props';
import { addGiftCardScreenMessages as messages } from './AddGiftCardScreen.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const AddGiftCardScreen = () => {
  const { formatMessage } = useIntl();
  const navigation = useNavigation();
  const { track } = useTelemetry();
  const { params } = useRoute<RouteProp<ModalStackParamList, 'AddGiftCard'>>();
  const { match } = useResponsive();

  const giftCardRedemptionEncodedData = params?.giftCardId;
  const defaultRedemptionValues = useRedeemGiftCardDefaultValues({
    giftCardRedemptionEncodedData,
  });

  const isLoggedIn = useIsLoggedIn();
  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );

  const { refetchCredits } = useCustomerCredit({
    requestPolicy: 'cache-and-network',
  });

  // ─── Derived Data ────────────────────────────────────────────────────

  const redirectToParams = useMemo(
    () => ({ redirectToGiftCardId: params?.giftCardId }),
    [params?.giftCardId],
  );

  // ─────────────────────────────────────────────────────────────────────

  const screenTitle = isInAppGiftCardEnabled
    ? formatMessage(messages.title)
    : formatMessage(messages.titleLegacy);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onRedeemSubmit = useCallback(() => {
    track('account.gift.redeem_form_submit');
  }, [track]);

  const onRedeemError = useCallback<OnRedeemError>(
    (payload) => {
      const { systemError, userError } = payload;

      track('account.gift.redeem_failure', { systemError, userError });
    },
    [track],
  );

  const onRedeemSuccess = useCallback(() => {
    track('account.gift.redeem_success');
    refetchCredits();
    navigation.navigate('MainTabs', {
      screen: 'AccountTab',
      params: { screen: 'PaymentAndGiftCards' },
    });
  }, [navigation, refetchCredits, track]);

  // ─────────────────────────────────────────────────────────────────────

  if (!isLoggedIn) {
    return (
      <SignedOutView
        redirectTo="add-gift-card"
        redirectToParams={redirectToParams}
        withoutFooter
      />
    );
  }

  return (
    <TransparentModal size="full" onClose={navigation.goBack} disableAnimation>
      <ScrollView>
        <Container wrapperStyle={styles.container} style={styles.container}>
          <AccountScreenTitle title={screenTitle} />

          <View style={match([styles.formContainerXS, styles.formContainerSM])}>
            <RedeemGiftCardForm
              giftCardCode={defaultRedemptionValues?.giftCardCode}
              giftCardRegistrationCode={
                defaultRedemptionValues?.giftCardRegistrationCode
              }
              onRedeemSubmit={onRedeemSubmit}
              onRedeemError={onRedeemError}
              onRedeemSuccess={onRedeemSuccess}
            />
          </View>
        </Container>
      </ScrollView>
    </TransparentModal>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  formContainerXS: {
    paddingTop: theme.spacing['6'],
    paddingBottom: theme.spacing['8'],
  },
  formContainerSM: {
    paddingVertical: theme.spacing['8'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OnRedeemError = NonNullable<RedeemGiftCardFormProps['onRedeemError']>;

type RedeemGiftCardFormProps = ComponentProps<typeof RedeemGiftCardForm>;

import { type KeyboardEvent } from 'react-native';

/**
 * Listens for the keyboard actions and returns whether it is open and its height.
 * (not needed on web)
 */
export const useKeyboardListener = (_props: KeyboardListenerProps = {}) => {
  return { openKeyboardHeight: 0, isKeyboardOpen: false };
};

// ─── Types ──────────────────────────────────────────────────────────────────

type KeyboardListenerProps = {
  onShow?: (event: KeyboardEvent) => void;
  onHide?: () => void;
};

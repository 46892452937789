import React, { useMemo } from 'react';
import type { ScrollViewProps, ViewProps } from 'react-native';
import { PixelRatio, ScrollView, StyleSheet, View } from 'react-native';
import {
  BodyText,
  Container,
  HStack,
  Image,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import { telemetryProductFromInput, useTelemetry } from '@order/Telemetry';

import { useProductDetailsScreenContext } from '../../../../ProductDetailsScreen.provider';
import { getActiveAndOutOfStockIngredientsModifications } from '../../../../state';
import type { IngredientModificationWithQuantity } from '../../../../types';
import { IngredientModificationCard } from '../../../IngredientModificationCard';
import { IllusBowlEmptySmall } from './assets';

export const ProductDetailsIngredients = () => {
  const { isCustomizationActive, modifications } =
    useProductDetailsScreenContext();
  const { minWidth } = useResponsive();

  const ingredients = useMemo(
    () => getActiveAndOutOfStockIngredientsModifications(modifications),
    [modifications],
  );
  const hasNoIngredients = ingredients.length === 0;
  const hasNoActiveModifications = isCustomizationActive && hasNoIngredients;

  const wrapperStyle = [
    hasNoActiveModifications && minWidth.isMD && styles.wrapperEmptyDesktop,
  ];

  return (
    <View style={wrapperStyle}>
      {isCustomizationActive ? (
        <ProductDetailsModificationIngredients ingredients={ingredients} />
      ) : (
        <ProductDetailsStandardIngredients ingredients={ingredients} />
      )}
    </View>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const ProductDetailsIngredientsStack = (props: ViewProps) => {
  const { match } = useResponsive();
  const deviceFontScale = PixelRatio.getFontScale();

  const itemsPerRowXS = deviceFontScale >= 1.1 ? 2 : 3;
  const itemsPerRow = match([itemsPerRowXS, 4, 3]);

  return (
    <Container style={styles.ingredientsStack}>
      <HStack gap={theme.spacing['4']} itemsPerRow={itemsPerRow}>
        {props.children}
      </HStack>
    </Container>
  );
};

const ProductDetailsIngredientsRail = (props: ScrollViewProps) => {
  const {
    ingredientsMobileScrollViewRef,
    setIngredientsScrollXsWrappersOffset,
  } = useProductDetailsScreenContext();

  return (
    <View style={styles.railWrapper}>
      <ScrollView
        ref={ingredientsMobileScrollViewRef}
        onScroll={setIngredientsScrollXsWrappersOffset}
        horizontal
        decelerationRate="fast"
        contentContainerStyle={styles.railScrollContentContainer}
        scrollEventThrottle={16}
        showsHorizontalScrollIndicator={false}
      >
        {props.children}
      </ScrollView>
    </View>
  );
};

const ActiveIngredientModificationCard = (
  props: ActiveIngredientModificationCardProps,
) => {
  const { ingredientModification } = props;
  const ingredientId = ingredientModification.ingredient.id;

  const { minWidth, match } = useResponsive();
  const {
    activeIngredientsCardsRefs,
    addIngredientModification,
    removeIngredientModification,
  } = useProductDetailsScreenContext();

  const deviceFontScale = PixelRatio.getFontScale();

  // ─── STYLES ──────────────────────────────────────────────────────

  const xsScaledStyle =
    deviceFontScale >= 1.1
      ? styles.railItemMobileScaled
      : styles.railItemMobile;
  const xsStyle = [
    styles.railItem,
    match([xsScaledStyle, styles.railItemTablet, styles.railItemDesktop]),
  ];
  const mdStyle = styles.ingredientCardWrapper;
  const style = minWidth.isMD ? mdStyle : xsStyle;

  // ─────────────────────────────────────────────────────────────────

  return (
    <IngredientModificationCard
      ref={activeIngredientsCardsRefs.get(ingredientId)}
      style={style}
      testIdPrefix="active-ingredient-card"
      ingredientModification={ingredientModification}
      onPress={addIngredientModification}
      onDecreaseQuantity={removeIngredientModification}
      isUnavailable={ingredientModification.outOfStock}
      isSelected={!ingredientModification.outOfStock}
    />
  );
};

// ─── TEMPLATES ──────────────────────────────────────────────────────────────────

const ProductDetailsStandardIngredients = (
  props: Required<ProductDetailsIngredientsListProps>,
) => {
  const { ingredients } = props;

  const { t } = useLocalizationContext();
  // @ts-expect-error TS(2339): Property 'hasLineItem' does not exist on type 'Pro... Remove this comment to see the full error message
  const { startCustomization, product, hasLineItem } =
    useProductDetailsScreenContext();

  // ──────────────── Telemetry ─────────────────

  const { track } = useTelemetry();

  const onCustomize = React.useCallback(() => {
    track('modify.view', {
      selectedProduct: telemetryProductFromInput(product ?? {}),
      entryPoint: hasLineItem ? 'bag' : 'pdp',
    });

    startCustomization();
  }, [product, hasLineItem, track, startCustomization]);

  if (ingredients.length === 0) return null;

  return (
    <ProductDetailsIngredientsStack>
      {ingredients.map((ingredientModification) => (
        <IngredientModificationCard
          key={ingredientModification.ingredient.id}
          style={styles.ingredientCardWrapper}
          ingredientModification={ingredientModification}
          accessibilityHint={t('pdp.modifications.start-customization')}
          isUnavailable={ingredientModification.outOfStock}
          onPress={onCustomize}
        />
      ))}
    </ProductDetailsIngredientsStack>
  );
};

const ProductDetailsModificationIngredients = (
  props: Required<ProductDetailsIngredientsListProps>,
) => {
  const { ingredients } = props;

  const { t } = useLocalizationContext();
  const { minWidth, match } = useResponsive();

  const hasNoModifications = ingredients.length === 0;
  const shouldUseRail = !minWidth.isMD;

  // ────────────────────────────────────────────────────────────────────

  const noModificationsIllustrationStyle = match([
    styles.noModificationsIllustration,
    styles.noModificationsIllustrationTablet,
  ]);

  const noModificationsWrapperStyle = [
    styles.noModificationsIllustrationWrapper,
    minWidth.isMD && styles.noModificationsIllustrationWrapperDesktop,
  ];

  // ────────────────────────────────────────────────────────────────────

  if (hasNoModifications) {
    return (
      <View style={noModificationsWrapperStyle}>
        {minWidth.isMD ? (
          <BodyText
            size={2}
            style={styles.cyoHeading}
            accessibilityRole="header"
            // @ts-expect-error TS(2322): Type '{ children: string; size: number; style: { c... Remove this comment to see the full error message
            accessibilityLevel={3}
          >
            {t('pdp.details.cyo.heading')}
          </BodyText>
        ) : null}

        <Image
          source={IllusBowlEmptySmall}
          style={noModificationsIllustrationStyle}
          contentFit="contain"
        />
      </View>
    );
  }

  // ─────────────────────────────────────────────────────────────────

  if (shouldUseRail) {
    return (
      <ProductDetailsIngredientsRail>
        {ingredients.map((ingredientModification, index) => {
          const isLastItem = index === ingredients.length - 1;
          const ingredientId = ingredientModification.ingredient.id;

          const style = {
            paddingLeft: theme.spacing['4'],
            paddingRight: isLastItem ? theme.spacing['4'] : 0,
          };

          return (
            <View key={ingredientId} style={style}>
              <ActiveIngredientModificationCard
                ingredientModification={ingredientModification}
              />
            </View>
          );
        })}
      </ProductDetailsIngredientsRail>
    );
  }

  // ─────────────────────────────────────────────────────────────────

  return (
    <ProductDetailsIngredientsStack>
      {ingredients.map((ingredientModification) => {
        const ingredientId = ingredientModification.ingredient.id;

        return (
          <ActiveIngredientModificationCard
            key={ingredientId}
            ingredientModification={ingredientModification}
          />
        );
      })}
    </ProductDetailsIngredientsStack>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  noModificationsIllustrationWrapper: {
    alignItems: 'center',
    marginVertical: 'auto',
    borderBottomColor: theme.colors.OPACITY.DARK_KALE.DARK,
    borderBottomWidth: 1,
    minHeight: 143,
  },
  noModificationsIllustrationWrapperDesktop: {
    borderBottomWidth: 0,
  },
  noModificationsIllustration: {
    width: 277,
    height: 128,
  },
  noModificationsIllustrationTablet: {
    width: 336,
    height: 157,
  },

  wrapperEmptyDesktop: {
    flexGrow: 1,
  },
  ingredientsStack: {
    paddingBottom: theme.spacing['6'],
  },
  ingredientCardWrapper: {
    paddingTop: theme.spacing['1'], // add space to prevent box shadow from being cut
  },
  railWrapper: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.OPACITY.DARK_KALE.DARK,
  },
  railScrollContentContainer: {
    paddingBottom: theme.spacing['4'],
  },
  railItem: {
    paddingVertical: theme.spacing['1'],
  },
  railItemMobile: {
    width: 102,
  },
  railItemMobileScaled: {
    width: 160,
  },
  railItemTablet: {
    width: 150,
  },
  railItemDesktop: {
    width: undefined,
  },

  cyoHeading: {
    color: theme.colors.CHARCOAL,
    paddingBottom: theme.spacing['4'],
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ProductDetailsIngredientsListProps = Readonly<{
  ingredients?: ProductDetailsIngredientsList;
}>;

type ProductDetailsIngredientsList =
  readonly IngredientModificationWithQuantity[];

type ActiveIngredientModificationCardProps = Readonly<{
  ingredientModification: IngredientModificationWithQuantity;
}>;

import { useCallback, useState } from 'react';
import type { RouteProp } from '@react-navigation/native';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import { useFunctionPolling, useNoticeBannersStackContext } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useChallengesAndRewards } from '@order/ChallengesAndRewards';
import { useRewardsWithDerivedData, useSweetpassTier } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

import type { ModalStackParamList } from '../../../../navigation/AppNavigation.props';
import {
  REWARDS_REFETCH_MAX_ATTEMPTS,
  REWARDS_REFETCH_POLLING_INTERVAL,
} from './useBagSweetpassUpgradeFlow.constants';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A custom hook to handle the sweetpass upgrade flow from the bag.
 *
 * When a customer starts the Sweetpass upgrade flow from the bag, they are redirected to the Sweetpass Upgrade screen,
 * and following successful upgrade, they are returned to the bag. From the bag we must fetch the upgraded tier
 * information and new premium rewards. Because rewards are fetched using third-party service, there is a chance that
 * premium rewards may not be available immediately, thus in those circumstances, we refetch those for
 * N times (see `REWARDS_REFETCH_MAX_ATTEMPTS`) and if those are fail again we should display a state, in which we warn
 * customers that we failed to fetch new premium rewards and give them the option to refetch them.
 *
 * This hook manages that entire process and returns the necessary helpers so that we may display the appropriate UI.
 */
export const useBagSweetpassUpgradeFlow = () => {
  const { params } = useRoute<RouteProp<ModalStackParamList, 'Bag'>>();
  const { referrer } = params ?? {};

  const { push: addBanner } = useNoticeBannersStackContext();
  const { t } = useLocalizationContext();

  // ─── State ───────────────────────────────────

  const [refetchAttempts, setRefetchAttempts] = useState(0);

  // ─── Data ────────────────────────────────────

  const { rewards, fetchRewards, isFetchingRewards } =
    useChallengesAndRewards();
  const isLoggedIn = useIsLoggedIn();
  const isSweetpassUpsellEnabled = useFeatureFlag(
    'CELS-683-long-sweetpass-upsell-in-bag-enabled',
  );

  // NOTE: This `referrer` is utilized to determine whether the customer was returned to the bag following the Sweetpass
  //       upgrade flow, so that we may do the necessary actions after it.
  const isComingFromSweetpassUpgradeFlow = referrer === 'sweetpass-upgrade';

  const shouldFetchUpgradedSweetpassTier =
    isLoggedIn && isSweetpassUpsellEnabled && isComingFromSweetpassUpgradeFlow;

  const sweetpassTierRequestPolicy = shouldFetchUpgradedSweetpassTier
    ? 'network-only'
    : 'cache-only';

  const { sweetpassTierName } = useSweetpassTier(sweetpassTierRequestPolicy);

  // ─── Derived data ────────────────────────────

  // @ts-expect-error TS(2345): Argument of type 'readonly { readonly __typename?:... Remove this comment to see the full error message
  const rewardsWithDerivedData = useRewardsWithDerivedData(rewards ?? []);

  const hasUpgradedTier = sweetpassTierName === 'SWEETPASS_PLUS';
  const shouldFetchNewRewards =
    shouldFetchUpgradedSweetpassTier && hasUpgradedTier;

  const hasNewRewards = rewardsWithDerivedData.some(
    ({ rewardType }) => rewardType === 'PREMIUM',
  );
  const hasReachedMaxRefetchAttempts =
    refetchAttempts === REWARDS_REFETCH_MAX_ATTEMPTS;
  const hasFailedToFetchNewRewards =
    !isFetchingRewards && !hasNewRewards && hasReachedMaxRefetchAttempts;
  const isFetchingUpgradedRewards =
    shouldFetchNewRewards && !hasNewRewards && !hasFailedToFetchNewRewards;

  const shouldPauseTierStatusPolling =
    !shouldFetchNewRewards ||
    isFetchingRewards ||
    hasNewRewards ||
    hasReachedMaxRefetchAttempts;

  // ─── Helpers ─────────────────────────────────

  const refetchSweetpassRewards = useCallback(() => {
    setRefetchAttempts((currentValue) => currentValue + 1);
    fetchRewards();
  }, [fetchRewards]);

  // ─── Effects ─────────────────────────────────

  // NOTE: The Bag component initiates the initial rewards fetch.

  // Retry fetch attempts at intervals, if the new rewards are not resolved.
  useFunctionPolling(
    REWARDS_REFETCH_POLLING_INTERVAL,
    refetchSweetpassRewards,
    shouldPauseTierStatusPolling,
  );

  // Show notice banner after tier upgrade process
  useFocusEffect(
    useCallback(() => {
      if (!shouldFetchUpgradedSweetpassTier) return;

      setTimeout(() => {
        addBanner({
          palette: 'success',
          text: t('bag.sweetpass.post-upgrade-banner.text'),
        });
      }, 500);
    }, [addBanner, shouldFetchUpgradedSweetpassTier, t]),
  );

  // ─────────────────────────────────────────────

  return {
    hasNewRewards,
    hasFailedToFetchNewRewards,
    isFetchingUpgradedRewards,
  };
};

import type { Dispatch, SetStateAction } from 'react';
import { useCallback } from 'react';
import { isToday } from 'date-fns';

import { ignoreTimezone } from '@order/utils';

import type { CheckoutPayload, PaymentMethodType } from '../../Checkout.utils';

/**
 * Callbacks for interacting with the checkout payload.
 */
export const useCheckoutHandlers = (
  props: CheckoutHandlersProps,
): CheckoutHandlers => {
  const { setPayload, setShouldShowFutureDateModal } = props;

  const handleWantedTimeSelected = useCallback(
    (wantedTime: string) => {
      const normalizedWantedTime = ignoreTimezone(wantedTime);
      const isWantedTimeToday = normalizedWantedTime
        ? isToday(normalizedWantedTime)
        : false;

      if (!isWantedTimeToday) setShouldShowFutureDateModal(true);

      setPayload((payload) => ({
        ...payload,
        wantedTime,
      }));
    },
    [setShouldShowFutureDateModal, setPayload],
  );

  const handleDropoffLocationSelected = useCallback(
    (dropoffLocationId: string | number) => {
      setPayload((payload) => ({
        ...payload,
        dropoffLocationId: String(dropoffLocationId),
      }));
    },
    [setPayload],
  );

  const handleCreditCardSelected = useCallback(
    (creditCardSelectedPayload: HandleCreditCardSelectedPayload) => {
      setPayload((payload) => ({
        ...payload,
        ...creditCardSelectedPayload,
      }));
    },
    [setPayload],
  );

  const handleAvailableCreditSelected = useCallback(() => {
    setPayload((payload) => ({
      ...payload,
      useCredit: !payload.useCredit,
    }));
  }, [setPayload]);

  const handleUtensilsToggle = useCallback(() => {
    setPayload((payload) => ({
      ...payload,
      includeUtensils: !payload.includeUtensils,
    }));
  }, [setPayload]);

  const handleRestaurantTip = useCallback(
    (tip: number) => {
      setPayload((payload) => ({
        ...payload,
        pickupTip: Math.round(tip),
      }));
    },
    [setPayload],
  );

  const handleDeliveryTip = useCallback(
    (tip: number) => {
      setPayload((payload) => {
        return {
          ...payload,
          deliveryOrderDetail: payload.deliveryOrderDetail && {
            ...payload.deliveryOrderDetail,
            tip: Math.round(tip),
          },
        };
      });
    },
    [setPayload],
  );

  return {
    handleWantedTimeSelected,
    handleDropoffLocationSelected,
    handleCreditCardSelected,
    handleAvailableCreditSelected,
    handleRestaurantTip,
    handleDeliveryTip,
    handleUtensilsToggle,
  };
};

// ───── Types ──────────────────────────────────────────────────────

type HandleCreditCardSelectedPayload = Readonly<{
  paymentMethodId: string;
  paymentMethodType: PaymentMethodType;
}>;

type CheckoutHandlersProps = Readonly<{
  setPayload: Dispatch<SetStateAction<CheckoutPayload>>;
  setShouldShowFutureDateModal: (state: boolean) => void;
}>;

type CheckoutHandlers = Readonly<{
  handleWantedTimeSelected: (wantedTime: string) => void;
  handleDropoffLocationSelected: (dropoffLocationId: string | number) => void;
  handleCreditCardSelected: (payload: HandleCreditCardSelectedPayload) => void;
  handleAvailableCreditSelected: () => void;
  handleRestaurantTip: (val: number) => void;
  handleDeliveryTip: (val: number) => void;
  handleUtensilsToggle: () => void;
}>;

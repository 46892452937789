import { createModel } from 'xstate/lib/model';
import type { AddressType } from '@sg/garnish';
import { emptyEvent, passthroughEvent } from '@sg/garnish';

import type {
  DeliveryOrderDetailInput,
  ReorderLineItemMutation,
  ReorderOrderMutation,
} from '../../../graphql/types';

export type ReorderStep =
  | 'idle'
  | 'activeBagWarning'
  | 'locationSearch'
  | 'locationConfirmation'
  | 'conflictReview'
  | undefined;

export type ReorderEvent = Readonly<{
  // reorder mutation response with conflicts / success data.
  response?: ReorderOrderMutation | ReorderLineItemMutation;

  // order information.
  orderId?: string;
  lineItemId?: string;
  orderType?: AddressType;

  // restaurant / delivery information.
  deliveryAddressName?: string;
  deliveryOrderDetail?: Partial<DeliveryOrderDetailInput>;
  restaurantId?: string;
  restaurantSlug?: string;
  restaurantName?: string;

  // for loading indicators
  reordering?: boolean;

  // variables used only to meet telemetry requirements.
  reorderResult?: string; // reorder.__typename
  orderTotal?: number; // total cost of the order
  conflictType?: string; // reorder.conflict.type
  userInitiated?: boolean; // as opposed to automatic reorder

  // conditional step for which to execute this transition.
  step?: ReorderStep;

  // If you start a delivery order while unauthenticated then when you log-in,
  // it turns out you already had that address, we need to reorder to the
  // new address while still keeping you in the checkout screen.
  remainOnCheckout?: boolean;

  // If you change your restaurant through your bag, you get the reorder flow,
  // starting it on a different menu and finishing it takes you to a new menu.
  finishOnMenu?: boolean;

  // If you open a menu for a restaurant different from the one in your bag,
  // Then you get the reorder flow starting on the confirm location modal,
  // If you dismiss it, you'll go to your bag restaurant menu.
  dismissToBagMenu?: boolean;
}>;

export type ReorderEventKey<
  Keys extends keyof ReorderEvent = keyof ReorderEvent,
> = keyof Pick<ReorderEvent, Keys>;

export const validReorderEventKeys: readonly ReorderEventKey[] = [
  'response',
  'orderId',
  'lineItemId',
  'orderType',
  'deliveryAddressName',
  'deliveryOrderDetail',
  'restaurantId',
  'restaurantSlug',
  'restaurantName',
  'reordering',
  'reorderResult',
  'orderTotal',
  'conflictType',
  'userInitiated',
  'remainOnCheckout',
  'finishOnMenu',
  'dismissToBagMenu',
];

const initialContext = {
  reordering: false,
  response: undefined as
    | ReorderOrderMutation
    | ReorderLineItemMutation
    | undefined,
  orderId: '',
  lineItemId: '',
  orderType: 'pickup' as AddressType,
  deliveryAddressName: '',
  deliveryOrderDetail: undefined as
    | Partial<DeliveryOrderDetailInput>
    | undefined,
  restaurantId: '',
  restaurantSlug: '',
  restaurantName: '',
  previousStep: undefined as ReorderStep,
  finishOnMenu: false,
  remainOnCheckout: false,
  dismissToBagMenu: false,
};

export const reorderModel = createModel(initialContext, {
  events: {
    ACTIVE_BAG_WARNING: passthroughEvent as (
      event: ReorderEvent,
    ) => ReorderEvent,
    CHANGE_LOCATION: passthroughEvent as (event: ReorderEvent) => ReorderEvent,
    CONFIRM_LOCATION: passthroughEvent as (event: ReorderEvent) => ReorderEvent,
    CONFLICT_REVIEW: passthroughEvent as (event: ReorderEvent) => ReorderEvent,
    SET_REORDERING: passthroughEvent as (event: ReorderEvent) => ReorderEvent,
    FINISH_REORDER: emptyEvent,
    CANCEL_REORDER: passthroughEvent as (event: ReorderEvent) => ReorderEvent,
  },
});

export const reorderMachine = reorderModel.createMachine({
  predictableActionArguments: true, // https://xstate.js.org/docs/guides/actions.html
  id: 'sgReorderMachine',
  context: reorderModel.initialContext,
  initial: 'idle',
  states: {
    idle: {
      on: {
        ACTIVE_BAG_WARNING: {
          target: 'activeBagWarning',
          actions: reorderModel.assign({
            orderId(context, event) {
              const { orderId, lineItemId } = event;

              return lineItemId ? '' : orderId ?? context.orderId;
            },
            lineItemId(context, event) {
              const { orderId, lineItemId } = event;

              return orderId ? '' : lineItemId ?? context.lineItemId;
            },
            orderType(context, event) {
              const { orderType } = event;

              return orderType ?? context.orderType;
            },
            deliveryAddressName(context, event) {
              const { deliveryAddressName } = event;

              return deliveryAddressName ?? context.deliveryAddressName;
            },
            deliveryOrderDetail(context, event) {
              const { deliveryOrderDetail } = event;

              return deliveryOrderDetail ?? context.deliveryOrderDetail;
            },
            restaurantId(context, event) {
              const { restaurantId } = event;

              return restaurantId ?? context.restaurantId;
            },
            restaurantSlug(context, event) {
              const { restaurantSlug } = event;

              return restaurantSlug ?? context.restaurantSlug;
            },
            restaurantName(context, event) {
              const { restaurantName } = event;

              return restaurantName ?? context.restaurantName;
            },
            remainOnCheckout(context, event) {
              const { remainOnCheckout } = event;

              return remainOnCheckout ?? context.remainOnCheckout;
            },
            finishOnMenu(context, event) {
              const { finishOnMenu } = event;

              return finishOnMenu ?? context.finishOnMenu;
            },
            previousStep: undefined,
          }),
        },
        CHANGE_LOCATION: {
          target: 'locationSearch',
          actions: reorderModel.assign({
            orderId(context, event) {
              const { orderId, lineItemId } = event;

              return lineItemId ? '' : orderId ?? context.orderId;
            },
            lineItemId(context, event) {
              const { orderId, lineItemId } = event;

              return orderId ? '' : lineItemId ?? context.lineItemId;
            },
            orderType(context, event) {
              const { orderType } = event;

              return orderType ?? context.orderType;
            },
            deliveryAddressName(context, event) {
              const { deliveryAddressName } = event;

              return deliveryAddressName ?? context.deliveryAddressName;
            },
            deliveryOrderDetail(context, event) {
              const { deliveryOrderDetail } = event;

              return deliveryOrderDetail ?? context.deliveryOrderDetail;
            },
            restaurantId(context, event) {
              const { restaurantId } = event;

              return restaurantId ?? context.restaurantId;
            },
            restaurantSlug(context, event) {
              const { restaurantSlug } = event;

              return restaurantSlug ?? context.restaurantSlug;
            },
            restaurantName(context, event) {
              const { restaurantName } = event;

              return restaurantName ?? context.restaurantName;
            },
            remainOnCheckout(context, event) {
              const { remainOnCheckout } = event;

              return remainOnCheckout ?? context.remainOnCheckout;
            },
            finishOnMenu(context, event) {
              const { finishOnMenu } = event;

              return finishOnMenu ?? context.finishOnMenu;
            },
            previousStep: undefined,
          }),
        },
        CONFIRM_LOCATION: {
          target: 'locationConfirmation',
          actions: reorderModel.assign({
            orderId(context, event) {
              const { orderId, lineItemId } = event;

              return lineItemId ? '' : orderId ?? context.orderId;
            },
            lineItemId(context, event) {
              const { orderId, lineItemId } = event;

              return orderId ? '' : lineItemId ?? context.lineItemId;
            },
            orderType(context, event) {
              const { orderType } = event;

              return orderType ?? context.orderType;
            },
            deliveryAddressName(context, event) {
              const { deliveryAddressName } = event;

              return deliveryAddressName ?? context.deliveryAddressName;
            },
            deliveryOrderDetail(context, event) {
              const { deliveryOrderDetail } = event;

              return deliveryOrderDetail ?? context.deliveryOrderDetail;
            },
            restaurantId(context, event) {
              const { restaurantId } = event;

              return restaurantId ?? context.restaurantId;
            },
            restaurantSlug(context, event) {
              const { restaurantSlug } = event;

              return restaurantSlug ?? context.restaurantSlug;
            },
            restaurantName(context, event) {
              const { restaurantName } = event;

              return restaurantName ?? context.restaurantName;
            },
            remainOnCheckout(context, event) {
              const { remainOnCheckout } = event;

              return remainOnCheckout ?? context.remainOnCheckout;
            },
            finishOnMenu(context, event) {
              const { finishOnMenu } = event;

              return finishOnMenu ?? context.finishOnMenu;
            },
            dismissToBagMenu(context, event) {
              const { dismissToBagMenu } = event;

              return dismissToBagMenu ?? context.dismissToBagMenu;
            },
            previousStep: undefined,
          }),
        },
        CONFLICT_REVIEW: {
          target: 'conflictReview',
          actions: reorderModel.assign({
            orderId(context, event) {
              const { orderId, lineItemId } = event;

              return lineItemId ? '' : orderId ?? context.orderId;
            },
            lineItemId(context, event) {
              const { orderId, lineItemId } = event;

              return orderId ? '' : lineItemId ?? context.lineItemId;
            },
            orderType(context, event) {
              const { orderType } = event;

              return orderType ?? context.orderType;
            },
            deliveryAddressName(context, event) {
              const { deliveryAddressName } = event;

              return deliveryAddressName ?? context.deliveryAddressName;
            },
            deliveryOrderDetail(context, event) {
              const { deliveryOrderDetail } = event;

              return deliveryOrderDetail ?? context.deliveryOrderDetail;
            },
            restaurantId(context, event) {
              const { restaurantId } = event;

              return restaurantId ?? context.restaurantId;
            },
            restaurantSlug(context, event) {
              const { restaurantSlug } = event;

              return restaurantSlug ?? context.restaurantSlug;
            },
            restaurantName(context, event) {
              const { restaurantName } = event;

              return restaurantName ?? context.restaurantName;
            },
            remainOnCheckout(context, event) {
              const { remainOnCheckout } = event;

              return remainOnCheckout ?? context.remainOnCheckout;
            },
            finishOnMenu(context, event) {
              const { finishOnMenu } = event;

              return finishOnMenu ?? context.finishOnMenu;
            },
            response(context, event) {
              const { response } = event;

              return response ?? context.response;
            },
            reordering: false,
            previousStep: undefined,
          }),
        },
        SET_REORDERING: {
          target: 'locationSearch',
          actions: reorderModel.assign({
            orderId(context, event) {
              const { orderId, lineItemId } = event;

              return lineItemId ? '' : orderId ?? context.orderId;
            },
            lineItemId(context, event) {
              const { orderId, lineItemId } = event;

              return orderId ? '' : lineItemId ?? context.lineItemId;
            },
            orderType(context, event) {
              const { orderType } = event;

              return orderType ?? context.orderType;
            },
            deliveryAddressName(context, event) {
              const { deliveryAddressName } = event;

              return deliveryAddressName ?? context.deliveryAddressName;
            },
            deliveryOrderDetail(context, event) {
              const { deliveryOrderDetail } = event;

              return deliveryOrderDetail ?? context.deliveryOrderDetail;
            },
            restaurantId(context, event) {
              const { restaurantId } = event;

              return restaurantId ?? context.restaurantId;
            },
            restaurantSlug(context, event) {
              const { restaurantSlug } = event;

              return restaurantSlug ?? context.restaurantSlug;
            },
            restaurantName(context, event) {
              const { restaurantName } = event;

              return restaurantName ?? context.restaurantName;
            },
            remainOnCheckout(context, event) {
              const { remainOnCheckout } = event;

              return remainOnCheckout ?? context.remainOnCheckout;
            },
            finishOnMenu(context, event) {
              const { finishOnMenu } = event;

              return finishOnMenu ?? context.finishOnMenu;
            },
            response(context, event) {
              const { response } = event;

              return response ?? context.response;
            },
            previousStep: undefined,
            reordering: true,
          }),
        },
      },
    },
    locationSearch: {
      on: {
        CONFIRM_LOCATION: {
          target: 'locationConfirmation',
          actions: reorderModel.assign({
            previousStep: undefined,
          }),
        },
        CONFLICT_REVIEW: {
          target: 'conflictReview',
          actions: reorderModel.assign({
            orderId(context, event) {
              const { orderId, lineItemId } = event;

              return lineItemId ? '' : orderId ?? context.orderId;
            },
            lineItemId(context, event) {
              const { orderId, lineItemId } = event;

              return orderId ? '' : lineItemId ?? context.lineItemId;
            },
            orderType(context, event) {
              const { orderType } = event;

              return orderType ?? context.orderType;
            },
            deliveryAddressName(_context, event) {
              const { deliveryAddressName } = event;

              return deliveryAddressName ?? '';
            },
            deliveryOrderDetail(_context, event) {
              const { deliveryOrderDetail } = event;

              return deliveryOrderDetail;
            },
            restaurantId(context, event) {
              const { restaurantId } = event;

              return restaurantId ?? context.restaurantId;
            },
            restaurantSlug(context, event) {
              const { restaurantSlug } = event;

              return restaurantSlug ?? context.restaurantSlug;
            },
            restaurantName(context, event) {
              const { restaurantName } = event;

              return restaurantName ?? context.restaurantName;
            },
            response(context, event) {
              const { response } = event;

              return response ?? context.response;
            },
            reordering: false,
            previousStep: 'locationSearch',
          }),
        },
        SET_REORDERING: {
          target: 'locationSearch',
          actions: reorderModel.assign({
            orderId(context, event) {
              const { orderId, lineItemId } = event;

              return lineItemId ? '' : orderId ?? context.orderId;
            },
            lineItemId(context, event) {
              const { orderId, lineItemId } = event;

              return orderId ? '' : lineItemId ?? context.lineItemId;
            },
            orderType(context, event) {
              const { orderType } = event;

              return orderType ?? context.orderType;
            },
            deliveryAddressName(context, event) {
              const { deliveryAddressName } = event;

              return deliveryAddressName ?? context.deliveryAddressName;
            },
            deliveryOrderDetail(context, event) {
              const { deliveryOrderDetail } = event;

              return deliveryOrderDetail ?? context.deliveryOrderDetail;
            },
            restaurantId(context, event) {
              const { restaurantId } = event;

              return restaurantId ?? context.restaurantId;
            },
            restaurantSlug(context, event) {
              const { restaurantSlug } = event;

              return restaurantSlug ?? context.restaurantSlug;
            },
            restaurantName(context, event) {
              const { restaurantName } = event;

              return restaurantName ?? context.restaurantName;
            },
            response(context, event) {
              const { response } = event;

              return response ?? context.response;
            },
            reordering: true,
          }),
        },
        CANCEL_REORDER: {
          target: 'idle',
          actions: reorderModel.assign(initialContext),
          cond: (_context, event: ReorderEvent) =>
            !event.step || event.step === 'locationSearch',
        },
      },
    },
    locationConfirmation: {
      on: {
        CHANGE_LOCATION: {
          target: 'locationSearch',
          actions: reorderModel.assign({
            previousStep: 'locationConfirmation',
          }),
        },
        CONFLICT_REVIEW: {
          target: 'conflictReview',
          actions: reorderModel.assign({
            response(context, event) {
              const { response } = event;

              return response ?? context.response;
            },
            reordering: false,
            previousStep: 'locationConfirmation',
          }),
        },
        SET_REORDERING: {
          target: 'locationConfirmation',
          actions: reorderModel.assign({
            orderId(context, event) {
              const { orderId, lineItemId } = event;

              return lineItemId ? '' : orderId ?? context.orderId;
            },
            lineItemId(context, event) {
              const { orderId, lineItemId } = event;

              return orderId ? '' : lineItemId ?? context.lineItemId;
            },
            orderType(context, event) {
              const { orderType } = event;

              return orderType ?? context.orderType;
            },
            deliveryAddressName(context, event) {
              const { deliveryAddressName } = event;

              return deliveryAddressName ?? context.deliveryAddressName;
            },
            deliveryOrderDetail(context, event) {
              const { deliveryOrderDetail } = event;

              return deliveryOrderDetail ?? context.deliveryOrderDetail;
            },
            restaurantId(context, event) {
              const { restaurantId } = event;

              return restaurantId ?? context.restaurantId;
            },
            restaurantSlug(context, event) {
              const { restaurantSlug } = event;

              return restaurantSlug ?? context.restaurantSlug;
            },
            restaurantName(context, event) {
              const { restaurantName } = event;

              return restaurantName ?? context.restaurantName;
            },
            response(context, event) {
              const { response } = event;

              return response ?? context.response;
            },
            reordering: true,
          }),
        },
        CANCEL_REORDER: {
          target: 'idle',
          actions: reorderModel.assign(initialContext),
          cond: (_context, event: ReorderEvent) =>
            !event.step || event.step === 'locationConfirmation',
        },
      },
    },
    conflictReview: {
      on: {
        CHANGE_LOCATION: {
          target: 'locationSearch',
          actions: reorderModel.assign({
            previousStep: 'conflictReview',
          }),
        },
        FINISH_REORDER: {
          target: 'idle',
          actions: reorderModel.assign(initialContext),
        },
        CANCEL_REORDER: {
          target: 'idle',
          actions: reorderModel.assign(initialContext),
          cond: (_context, event: ReorderEvent) =>
            !event.step || event.step === 'conflictReview',
        },
      },
    },
    activeBagWarning: {
      on: {
        CONFIRM_LOCATION: {
          target: 'locationConfirmation',
          actions: reorderModel.assign({
            previousStep: 'activeBagWarning',
          }),
        },
        CANCEL_REORDER: {
          target: 'idle',
          actions: reorderModel.assign(initialContext),
          cond: (_context, event: ReorderEvent) =>
            !event.step || event.step === 'activeBagWarning',
        },
      },
    },
  },
});

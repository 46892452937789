/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PaymentMethodCardsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type PaymentMethodCardsQuery = {
  readonly __typename?: 'Query';
  readonly paymentMethodCards: ReadonlyArray<
    | { readonly __typename?: 'PaymentMethodBillingAccount' }
    | {
        readonly __typename: 'PaymentMethodCard';
        readonly id: string;
        readonly description: string | null;
        readonly nickname: string | null;
        readonly isDefault: boolean;
        readonly cardType: Types.CardType;
        readonly expirationState: Types.CardExpirationState;
      }
  >;
};

export const PaymentMethodCardsDocument = gql`
  query paymentMethodCards {
    paymentMethodCards {
      ... on PaymentMethodCard {
        __typename
        id
        description
        nickname
        isDefault
        cardType
        expirationState
      }
    }
  }
`;

export function usePaymentMethodCardsQuery(
  options?: Omit<Urql.UseQueryArgs<PaymentMethodCardsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    PaymentMethodCardsQuery,
    PaymentMethodCardsQueryVariables
  >({ query: PaymentMethodCardsDocument, ...options });
}

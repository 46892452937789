import React from 'react';
import { Platform } from 'react-native';

import { OrganizationLinksFooter } from '../OrganizationLinksFooter';

// This footer component is only present on web, not on native.
export const AppFooter = () => {
  if (Platform.OS !== 'web') return null;

  return <OrganizationLinksFooter />;
};

import { useMemo } from 'react';

import { useGiftCardCheckoutContent } from '../useGiftCardCheckoutContent';
import { useGiftCardData } from '../useGiftCardData';

// ─────────────────────────────────────────────────────────────────────────────

export const useGiftCardContent = (params: useGiftCardContentParams) => {
  const { assetId, pause } = params;

  // ─────────────────────────────────────────────────────────────────────

  const giftCard = useGiftCardData({ assetId, pause });
  const giftCardCheckoutContent = useGiftCardCheckoutContent({ pause });

  // ─── Derived Data ────────────────────────────────────────────────────

  const isFetching = giftCard.fetching || giftCardCheckoutContent.fetching;

  const imgData = useMemo(() => {
    if (!giftCard.data) return;

    return {
      url: giftCard.data?.cover.fields.file.url,
      a11yLabel: giftCard.data?.title,
    };
  }, [giftCard.data]);

  const screenTitle = giftCardCheckoutContent.data?.screenTitle;

  const sectionTitles = useMemo(() => {
    if (!giftCardCheckoutContent.data) return;

    return {
      amount: giftCardCheckoutContent.data.sectionTitleAmount,
      details: giftCardCheckoutContent.data.sectionTitleDetails,
      deliveryDate: giftCardCheckoutContent.data.sectionTitleDeliveryDate,
      deliveryMethod: giftCardCheckoutContent.data.sectionTitleDeliveryMethod,
      recipientEmail: giftCardCheckoutContent.data.sectionTitleRecipientEmail,
      paymentMethod: giftCardCheckoutContent.data.sectionTitlePaymentMethod,
    };
  }, [giftCardCheckoutContent.data]);

  const amountData = useMemo(() => {
    if (!giftCardCheckoutContent.data) return;

    return {
      min: giftCardCheckoutContent.data.amountMinAmount,
      max: giftCardCheckoutContent.data.amountMaxAmount,
      options: giftCardCheckoutContent.data.amountOptions.map(Number),
    };
  }, [giftCardCheckoutContent.data]);

  const messageMaxChars = giftCardCheckoutContent.data?.messageMaxCharacters;

  // ─────────────────────────────────────────────────────────────────────

  return {
    data: {
      imgData,
      screenTitle,
      sectionTitles,
      amountData,
      messageMaxChars,
    },
    isFetching,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type useGiftCardContentParams = {
  assetId: string;
  pause: boolean;
};

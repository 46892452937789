import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { VStack } from '@sg/garnish';

import { HomeHeroCta } from './HomeHeroCta';

export const HomeHeaderXS = (props: HomeHeaderXSProps) => {
  const { formatMessage } = useIntl();

  return (
    <View style={styles.header}>
      <View style={styles.headerRow}>
        <View style={styles.contents}>{props.children}</View>
        <VStack>
          {props.onPressAccount ? (
            <HomeHeroCta
              iconName="IconAccount"
              accessibilityLabel={formatMessage(messages.homeHeroCta)}
              onPress={props.onPressAccount}
            />
          ) : null}
          {props.onPressScan ? (
            <HomeHeroCta
              iconName="IconScan"
              accessibilityLabel={formatMessage(messages.homeScanCta)}
              onPress={props.onPressScan}
            />
          ) : null}
        </VStack>
      </View>
    </View>
  );
};

const messages = defineMessages({
  homeHeroCta: {
    defaultMessage: 'Open account screen',
    description: 'Home | Hero CTA | Opens the account screen',
  },
  homeScanCta: {
    defaultMessage: 'Open scan modal',
    description: 'Home | Scan CTA | Opens the scan modal',
  },
});

const styles = StyleSheet.create({
  header: {
    flexGrow: 1,
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing['2'],
  },
  contents: {
    flex: 1,
    gap: theme.spacing['2'],
  },
});

type HomeHeaderXSProps = {
  children: React.ReactNode;
  onPressAccount?: () => void;
  onPressScan?: () => void;
};

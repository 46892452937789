import React from 'react';
import { Controller } from 'react-hook-form';

import { Picker as GarnishPicker } from '../Pickers/Picker';
import type { ControlledProps, FormProps } from './types';

type ComponentProps = React.ComponentProps<typeof GarnishPicker>;
type OmittedProps = ControlledProps | 'onSubmit';
type Props = FormProps & Omit<ComponentProps, OmittedProps>;

export const Picker = ({ name, control, rules, ...rest }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, onBlur, value } }) => (
        <GarnishPicker
          value={value}
          onSubmit={(selected) => {
            onChange(selected);
            onBlur();
          }}
          {...rest}
        />
      )}
    />
  );
};

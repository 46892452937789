import { CONTENTFUL_CACHE_STORAGE_KEY } from '../../contentful-content-type-entries-machine.constants';
import type { CacheEntry } from '../../contentful-content-type-entries-machine.types';
import { checkIfCacheEntryIsValid } from '../../utils';
import { getAllKeys, multiGet, multiRemove } from '../cache';

/**
 * Removes contentful cache items that are expired or invalid.
 */
export async function removeExpiredCacheEntries<Fields>() {
  try {
    // ─── Get Contentful cache entries ────────────────────────────────────

    const storageKeys = await getAllKeys();
    const contentfulCacheEntries = storageKeys.filter((key) =>
      key.startsWith(CONTENTFUL_CACHE_STORAGE_KEY),
    );

    if (contentfulCacheEntries.length === 0) return;

    // ─── Find expired entries ────────────────────────────────────────────

    const entries = await multiGet(contentfulCacheEntries);

    const invalidEntries = entries.reduce<readonly string[]>(
      (invalidEntriesList, [key, entry]) => {
        const entryParsed = JSON.parse(entry ?? '{}');
        const cacheEntry = entryParsed as CacheEntry<Fields>;
        const isValidCacheEntry = checkIfCacheEntryIsValid<Fields>(cacheEntry);

        if (isValidCacheEntry) return invalidEntriesList;

        return [...invalidEntriesList, key];
      },
      [],
    );

    if (invalidEntries.length === 0) return;

    // ─── Remove expired entries ──────────────────────────────────────────

    await multiRemove(invalidEntries);
  } catch {}
}

import React from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { Container, useResponsive } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useLocalizationContext } from '@order/Localization';
import { useTrackEventEffect } from '@order/Telemetry';

import { Modal } from '../Modal';
import { SweetpassBillingHistory } from './components';
import { useSweetpassBillingHistory } from './hooks';

// ───────────────────────────────────────────────────────────────────────

export const SweetpassBillingHistoryModal = (
  props: SweetpassBillingHistoryModalProps,
) => {
  const { visible, onRequestClose } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const isScreenFocused = useIsFocused();
  const isLoggedIn = useIsLoggedIn();
  const { t } = useLocalizationContext();
  const { match } = useResponsive();
  const header = t('account.sweetpass-membership.billing-history.header');

  // ─── Query ───────────────────────────────────────────────────────────

  const { billingHistory, isFetching } = useSweetpassBillingHistory({
    pause: !isLoggedIn || !isScreenFocused,
  });

  // ─── Telemetry ───────────────────────────────────────────────────────

  useTrackEventEffect({
    name: 'sweetpass_account.billing_history.viewed',
    skip: !visible || billingHistory.length === 0,
  });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Modal
      visible={visible}
      accessibilityLabel={header}
      presentationStyle="pageSheet"
      size={match(['full', 'small'])}
      onRequestClose={onRequestClose}
    >
      <Modal.Header onClose={onRequestClose}>{header}</Modal.Header>
      <ScrollView contentContainerStyle={styles.content}>
        <Container>
          <SweetpassBillingHistory
            billingHistory={billingHistory}
            isFetching={isFetching}
          />
        </Container>
      </ScrollView>
    </Modal>
  );
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  content: {
    flexGrow: 1,
  },
});

// ─── Types ─────────────────────────────────────────────────────────────

type SweetpassBillingHistoryModalProps = Readonly<{
  visible: boolean;
  onRequestClose: () => void;
}>;

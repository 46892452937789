import type {
  Ingredient,
  IngredientModification,
  IngredientModificationKind,
  IngredientModifications,
} from '../../../../graphql/types';
import type { IngredientModificationWithQuantity } from '../../types';
import type {
  DefaultIngredientModificationMap,
  IngredientModificationLimitations,
} from '../ProductModifications.types';
import {
  sortActiveIngredients,
  withoutOutOfStockActiveModifications,
} from './activeModifications';
import { getComputedModifications } from './computedModifications';

// ────────────────────────────────────────────────────────────────────────────────

export const generateInitialModifications = (
  ingredientModifications: IngredientModifications,
  defaultIngredients: readonly Ingredient[],
  activeIngredients: readonly Ingredient[],
) => {
  const allIngredientModifications = getAllIngredientModifications(
    ingredientModifications,
  );
  const defaultIngredientModifications =
    getIngredientModificationsForIngredients(
      defaultIngredients,
      allIngredientModifications,
    );

  const activeIngredientModifications =
    getIngredientModificationsForIngredients(
      activeIngredients,
      allIngredientModifications,
    );

  const modificationsToUse =
    activeIngredients.length > 0
      ? activeIngredientModifications
      : defaultIngredientModifications;

  const activeWithoutOutOfStock =
    withoutOutOfStockActiveModifications(modificationsToUse);

  // ────────────────────────────────────────────────────────────────────

  const defaultIngredientModificationsMap =
    defaultIngredientModifications.reduce<DefaultIngredientModificationMap>(
      (defaultsMap, defaultIngredientModification) => {
        defaultsMap.set(
          defaultIngredientModification.ingredient.id,
          defaultIngredientModification,
        );

        return defaultsMap;
      },
      new Map(),
    );

  // ────────────────────────────────────────────────────────────────────

  const sortedActives = sortActiveIngredients(activeWithoutOutOfStock);
  const computedModifications = getComputedModifications(
    defaultIngredientModificationsMap,
    sortedActives,
  );

  return {
    defaults: defaultIngredientModificationsMap,
    active: sortedActives,
    ...computedModifications,
  };
};

export const getIngredientModificationsLimitations = (
  ingredientModifications: IngredientModifications,
) => {
  return Object.entries(
    ingredientModifications,
  ).reduce<IngredientModificationLimitations>(
    (limitations, [kind, options]) => {
      if (typeof options === 'string') return limitations;

      const { minAggregateQuantity: min, maxAggregateQuantity: max } = options;

      return {
        ...limitations,
        [kind]: { min, max },
      };
    },
    {},
  );
};

// ────────────────────────────────────────────────────────────────────────────────

export const getAllIngredientModifications = (
  ingredientModifications: IngredientModifications,
) => {
  return Object.entries(ingredientModifications).reduce<
    readonly IngredientModification[]
  >((state, [kind, ingredientModificationKind]) => {
    if (kind === '__typename') return state;

    const castedKind = ingredientModificationKind as IngredientModificationKind;

    return [...state, ...castedKind.modifications];
  }, []);
};

const getIngredientModificationsForIngredients = (
  ingredients: readonly Ingredient[],
  allIngredientModifications: readonly IngredientModification[],
) => {
  return ingredients.reduce<readonly IngredientModificationWithQuantity[]>(
    (state, ingredient) => {
      const possibleEntry = state.find(
        (ingredientModification) =>
          ingredientModification.ingredient.id === ingredient.id,
      );

      if (!possibleEntry) {
        const ingredientModification = allIngredientModifications.find(
          (modification) => modification.ingredient.id === ingredient.id,
        );

        if (!ingredientModification) return state;

        return [
          ...state,
          {
            ...ingredientModification,
            additionCost: Number(ingredientModification?.additionCost ?? '0'),
            quantity: 1,
          },
        ];
      }

      return state.map((modification) => {
        if (modification.ingredient.id === possibleEntry.ingredient.id) {
          return {
            ...modification,
            quantity: Number(possibleEntry.quantity) + 1,
          };
        }

        return modification;
      });
    },
    [],
  );
};

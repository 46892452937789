import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import {
  DisplayText,
  theme,
  useContainerSpacing,
  useResponsive,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutTitle = (props: GiftCardCheckoutTitleProps) => {
  const { children } = props;

  const { match } = useResponsive();
  const containerSpacing = useContainerSpacing();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerHorizontalSpacing = match([containerSpacing, 0]);

  const containerStyle = useStyle(
    () => ({ paddingHorizontal: containerHorizontalSpacing }),
    [containerHorizontalSpacing],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyle}>
      <DisplayText
        sizeMatch={['24', '40', '40', '48']}
        style={match([styles.titleXS, styles.titleSM, styles.titleMD])}
      >
        {children ?? (
          <FormattedMessage
            description="Gift Card Checkout | Screen title"
            defaultMessage="Customize your gift"
          />
        )}
      </DisplayText>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  titleXS: {
    marginBottom: theme.spacing['6'],
  },
  titleSM: {
    marginBottom: theme.spacing['10'],
    textAlign: 'center',
  },
  titleMD: {
    marginBottom: theme.spacing['10'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutTitleProps = {
  children: string | undefined;
};

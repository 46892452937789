import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardConfirmationOrderNumber = (
  props: GiftCardConfirmationOrderNumberProps,
) => {
  const { orderId } = props;

  return (
    <View style={styles.container}>
      <BodyText sizeMatch={['12']} style={styles.text}>
        <FormattedMessage
          {...messages.orderNumberText}
          values={{ order_id: orderId }}
        />
      </BodyText>
    </View>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  orderNumberText: {
    defaultMessage: 'Order #: {order_id}',
    description: 'Gift Card Confirmation Screen | Order Number Text',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    marginTop: theme.spacing['4'],
    padding: theme.spacing['4'],
  },
  text: {
    textAlign: 'center',
    color: theme.colors.CHARCOAL,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardConfirmationOrderNumberProps = {
  orderId: string;
};

export const checkout = {
  'checkout.not-logged-in': 'Please sign in to complete your order.',
  'checkout.required-field': 'required field',

  // ─── Header ──────────────────────────────────────────────────────────

  'checkout.finish-order-pickup': 'Finish up your pickup order',
  'checkout.finish-order-outpost': 'Finish up your Outpost order',
  'checkout.finish-order-delivery': 'Finish up your delivery order',
  'checkout.finish-order': `{cartOrderType, select,
    pickup {Finish up your pickup order}
    outpost {Finish up your Outpost order}
    delivery {Finish up your delivery order}
    other {}
  }`,

  // ─── Details Block ───────────────────────────────────────────────────

  'checkout.restaurant-change-location': 'Change Location: {location}',
  'checkout.block-details-title-pickup': 'Pickup details',
  'checkout.block-details-title-outpost': 'Outpost details',
  'checkout.block-details-title-delivery': 'Delivery details',
  'checkout.block-details-title': `{cartOrderType, select,
    pickup {Pickup details}
    outpost {Outpost details}
    delivery {Delivery details}
    other {}
  }`,

  // ─── Payments Block ──────────────────────────────────────────────────

  'checkout.payment.promo-code.label': 'Enter promo code',
  'checkout.payment.promo-code.placeholder': 'Promo code',
  'checkout.payment.promo-code.a11y': 'Opens a form to enter a promo code',
  'checkout.payment.promo-code.apply': 'Apply promo code',
  'checkout.payment.gift-card.label': 'Add gift card',
  'checkout.payment.gift-card.a11y': 'Opens a form to add a gift card',

  // ─── Checkout Selectors ──────────────────────────────────────────────

  'checkout.pickup-time': 'Pickup time',
  'checkout.pickup-time.day': 'Pickup time, Day',
  'checkout.pickup-time.hour': 'Pickup time, Hour',
  'checkout.pickup-time.title': 'Choose a pickup time',
  'checkout.delivery-time': 'Delivery time',
  'checkout.delivery-time.day': 'Delivery time, Day',
  'checkout.delivery-time.hour': 'Delivery time, Hour',
  'checkout.delivery-time.title': 'Choose a delivery time',
  'checkout.dropoff-location': 'Drop-off location',
  'checkout.dropoff-location.title': 'Choose a drop-off location',
  'checkout.pickup-location': 'Pickup location',
  'checkout.pickup-location.title': 'Choose a pickup location',
  'checkout.payment-method': 'Payment method',
  'checkout.payment-method.title': 'Choose a payment method',

  // ─── Credit ──────────────────────────────────────────────────────────

  'checkout.use-credit': 'Use credit?',
  'checkout.available-credit': '{amount} of {available}',
  'checkout.gift-card-note':
    'If entering gift-card code, your gift-card amount will be transferred to your online credit card and you will no longer be able to use your physical gift card',
  'checkout.notice.promo-applied':
    'Promo code has been successfully added as a new reward. Navigate back to your bag to apply it to your active order.',

  // ─── Extra Utensils ──────────────────────────────────────────────────

  'checkout.extras.utensils-title': 'Want utensils in your bag?',
  'checkout.extras.utensils-description':
    'Our utensils and napkins are 100% compostable',

  // ─── Credit Card ─────────────────────────────────────────────────────

  'checkout.add-card': 'Add card',
  'checkout.add-new-payment-method': 'Add new payment method',
  'checkout.select-payment-method': 'Select payment method',

  // ─── Future Date Warning ─────────────────────────────────────────────

  'checkout.future-date-header': 'Heads up!',
  'checkout.future-date-message': 'This order is scheduled for a future date.',

  // ─── Place Order ─────────────────────────────────────────────────────

  'checkout.place-order': 'Place order',
  'checkout.place-order.apple-pay': 'Order with ',
  'checkout.place-order.apple-pay.a11y': 'Order with Apple Pay',
  'checkout.placing-order-banner':
    "Your order is being placed; please don't refresh or navigate away",
  'checkout.allergens-disclaimer': 'Allergens Disclaimer',
  'checkout.allergens-disclaimer-message':
    'At sweetgreen we use all major allergens in our kitchens, so we cannot guarantee that our food is completely free of any allergen. If you have a severe allergy, we recommend not ordering from our restaurant.',
  'checkout.delivery-provider': 'Delivery Provider',
  'checkout.delivery-provider-message':
    'You authorize sweetgreen to share your contact and location data with our delivery service provider.',
  'checkout.cancellation-policy': 'Cancellation Policy',
  'checkout.cancellation-policy-message':
    'No changes to the order can be made after placing your order.',

  // ─── Feedback ────────────────────────────────────────────────────────

  'checkout.order-feedback.title': 'How was your 🥗 experience?',
  'checkout.order-feedback.body':
    'Lettuce know your thoughts. Give it a quick rating!',

  // ─── Tips ────────────────────────────────────

  'checkout.tip.custom': 'Custom',
  'checkout.tip.custom-field-a11y': 'Enter custom tip',
  'checkout.tip.custom-field': 'Enter a custom amount',

  'checkout.tips.no-tip-label': 'No tip',
  'checkout.tips.tip-a11y-hint': 'Selects "{tip} ({amount})" tip option',
  'checkout.tips.no-tip-a11y-hint': 'Selects "No tip" option',
  'checkout.tips.custom-tip-a11y-hint': 'Selects "Custom" tip option',
  'checkout.tips.custom-amount-hint': 'Enter custom amount',
  'checkout.tip.custom-field-max-notice':
    'The maximum tip for this order is 100% or {maxAmount}, but our team appreciates your generosity!',

  'checkout.tips.delivery-tip.title': 'Delivery tip',
  'checkout.tips.delivery-tip.description':
    "100% of the tip goes to your courier. Thank them with a tip today! To modify, select 'Custom' or another amount.",

  'checkout.tips.restaurant-tip.title': 'Restaurant tip',
  'checkout.tips.restaurant-tip.description':
    'Tipping for great service is always appreciated. It’ll be shared across our team!',

  // ─── Checkout Errors ─────────────────────────────────────────────────

  'checkout.timeslot-unavailable':
    'That time is no longer available. Please select another.',
  'checkout.timeslot-unavailable-with-timeslot':
    'The {timeslot} window is no longer available. Your order has been updated to the next available time.',
  'checkout.timeslot-unavailable-with-timeslot-static':
    'The selected timeslot window is no longer available. Your order has been updated to the next available time.',
  'checkout.billing-account.invalid':
    'We were unable to bill your order. Please check that your card on file is valid.',
  'checkout.incorrect-payment-method': `The card information provided is invalid.
If the issue persists, please contact your card issuer for further assistance.`,
  'checkout.payment-method-declined': `We're sorry but this transaction has been declined.
Please contact your card issuer or choose an alternative payment method.`,
  'checkout.customer-phone.invalid':
    'Your phone number is invalid. Please use a 10 digit US phone number, without country code.',
  'checkout.tip.invalid':
    'Selected tip cannot be applied. Please choose another option.',
  'checkout.delivery-in-flight':
    'Sorry, you already have an active delivery order. Please try Pickup, or check the status of your active delivery order.',
  'checkout.delivery-specify-address':
    'More than one result was returned for this address. Please make this address as specific as possible.',
  'checkout.cannot-submit.wanted-time': 'Please select a time for your order.',
  'checkout.cannot-submit.max-tip': 'Please enter a valid tip amount.',
  'checkout.cannot-submit.payment-method':
    'Please select a payment method for your order.',
  'checkout.cannot-submit.drop-off':
    'Please select a dropoff location for your order.',
  'checkout.cannot-submit.pickup':
    'Please select a pickup location for your order.',
};

import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { DisplayText, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuCategoriesGridCategoryHeading = (props: PropsWithChildren) => {
  const { children } = props;

  return (
    <View style={styles.container}>
      <DisplayText sizeMatch={['40']}>{children}</DisplayText>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    marginBottom: theme.spacing['6'],
  },
});

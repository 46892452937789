import type { ComponentProps } from 'react';
import React, { useCallback } from 'react';
import { ProductLineItemCard } from '@sg/garnish';

import type { PartialLineItem, PartialProduct } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';
import {
  useLineItemDescription,
  useLineItemIngredients,
  useLineItemName,
  useLineItemPrice,
} from '@order/shared/hooks';

export const LineItemCard = (props: LineItemCardProps) => {
  const {
    lineItem,
    isDisabled,
    onPress,
    onRemove,
    onQuantityChange,
    ...restProps
  } = props;
  const { id = '', quantity = 0, product } = lineItem;

  const { t } = useLocalizationContext();

  const lineItemName = useLineItemName(product, lineItem);
  const lineItemA11yLabel = t('bag.lineItem.navigate-to', { lineItemName });
  const lineItemPrice = useLineItemPrice(lineItem);
  const lineItemDescription = useLineItemDescription(lineItem);
  const lineItemAssetUrl = product?.asset?.url ?? '';

  const { addedIngredients, removedIngredients } =
    useLineItemIngredients(lineItem);

  // ─── HELPERS ────────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    onPress?.(id, product);
  }, [onPress, product, id]);

  const handleOnRemove = useCallback(() => {
    onRemove?.(id, product);
  }, [onRemove, product, id]);

  const handleQuantityChange = useCallback(
    async (updatedQuantity: number) => {
      await onQuantityChange?.(lineItem, updatedQuantity);
    },
    [lineItem, onQuantityChange],
  );

  // ────────────────────────────────────────────────────────────────────

  return (
    <ProductLineItemCard
      productId={id}
      productName={lineItemName}
      price={lineItemPrice}
      cloudinaryImageUrl={lineItemAssetUrl}
      imageSize="large"
      addedIngredients={addedIngredients}
      addedIngredientsLabel={t('product-added-ingredients-label')}
      removedIngredients={removedIngredients}
      removedIngredientsLabel={t('product-removed-ingredients-label')}
      description={lineItemDescription}
      quantity={quantity}
      onPress={onPress ? handleOnPress : undefined}
      onRemove={onRemove ? handleOnRemove : undefined}
      onQuantityChange={onQuantityChange ? handleQuantityChange : undefined}
      isDisabled={isDisabled}
      removeBtnLabel={t('general.remove')}
      accessibilityLabel={lineItemA11yLabel}
      {...restProps}
    />
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type LineItemCardProps = Readonly<{
  lineItem: LineItem;
  isDisabled?: boolean;
  onPress?: (lineItemId: string, product: PartialProduct) => void;
  onRemove?: (lineItemId: string, product: PartialProduct) => void;
  onQuantityChange?: (lineItem: LineItem, quantity: number) => Promise<unknown>;
}> &
  Partial<
    Pick<
      ComponentProps<typeof ProductLineItemCard>,
      | 'onFavorite'
      | 'isFetchingFavorite'
      | 'isFavorite'
      | 'onShare'
      | 'shareBtnLabel'
      | 'shareTooltipLabel'
      | 'shareUrl'
    >
  >;

type LineItem = Omit<PartialLineItem, 'ingredients'>;

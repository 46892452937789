/* cSpell:ignore builtins */
/* eslint-disable unicorn/prefer-spread */
/* eslint-disable unicorn/new-for-builtins */

import React from 'react';

import { useDebounceFn } from '../../hooks';
import { RatingContainer } from './Rating.container';
import { Star } from './Rating.star';
import type { RatingProps } from './Rating.types';

export const Rating = ({
  minValue,
  value,
  starCount,
  accessibility,
  onHover,
  onChange,
}: RatingProps) => {
  const [hoverRating, setHoverRating] = React.useState(0);
  const handleHover = React.useCallback(
    (rating: number) => {
      setHoverRating(rating);
      onHover?.(rating);
    },
    [onHover],
  );
  const handleHoverDebounced = useDebounceFn(handleHover, 16);
  const ratingOptions = Array.from(Array(starCount ?? 5));

  return (
    <RatingContainer
      value={value}
      minValue={minValue ?? 1}
      starCount={starCount ?? 5}
    >
      {ratingOptions.map((_, optionIndex) => {
        const starValue = optionIndex + 1;
        const hasHoverIndex = hoverRating > 0;
        const isStarHovered = hoverRating >= starValue;
        const isStarSelected = value >= starValue;
        const checked = hasHoverIndex ? isStarHovered : isStarSelected;

        return (
          <Star
            key={starValue}
            value={value}
            starValue={starValue}
            checked={checked}
            accessibility={accessibility}
            onHover={handleHoverDebounced}
            onChange={onChange}
          />
        );
      })}
    </RatingContainer>
  );
};

/* eslint-disable functional/immutable-data */
Rating.Container = RatingContainer;
Rating.Star = Star;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CancelOrderSuccessFieldsFragment = {
  readonly __typename?: 'CancelOrderSuccess';
  readonly success: boolean;
  readonly reason: string;
};

export type CancelSuccessRefundFailedFieldsFragment = {
  readonly __typename?: 'CancelSuccessRefundFailed';
  readonly errorMessage: string;
};

export type CancelOrderFailedFieldsFragment = {
  readonly __typename?: 'CancelOrderFailed';
  readonly errorMessage: string;
};

export type CancellationLimitExceededFieldsFragment = {
  readonly __typename?: 'CancellationLimitExceeded';
  readonly errorMessage: string;
};

export type CancellationFailedWithDeliveryFieldsFragment = {
  readonly __typename?: 'CancellationFailedWithDelivery';
  readonly errorMessage: string;
};

export type OrderAlreadyCanceledFieldsFragment = {
  readonly __typename?: 'OrderAlreadyCanceled';
  readonly errorMessage: string;
};

export type OrderNotFoundFieldsFragment = {
  readonly __typename?: 'OrderNotFound';
  readonly errorMessage: string;
};

export type OrderAlreadySentToKitchenFieldsFragment = {
  readonly __typename?: 'OrderAlreadySentToKitchen';
  readonly errorMessage: string;
};

export type CancelOrderMutationVariables = Types.Exact<{
  input: Types.CancelOrderInput;
}>;

export type CancelOrderMutation = {
  readonly __typename?: 'Mutation';
  readonly cancelOrder:
    | { readonly __typename: 'AsyncCancelFailed' }
    | {
        readonly __typename: 'CancelOrderFailed';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'CancelOrderSuccess';
        readonly success: boolean;
        readonly reason: string;
      }
    | { readonly __typename: 'CancelOrderSuccessAsync' }
    | {
        readonly __typename: 'CancelSuccessRefundFailed';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'CancellationFailedWithDelivery';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'CancellationLimitExceeded';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'OrderAlreadyCanceled';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'OrderAlreadySentToKitchen';
        readonly errorMessage: string;
      }
    | { readonly __typename: 'OrderNotFound'; readonly errorMessage: string };
};

export const CancelOrderSuccessFieldsFragmentDoc = gql`
  fragment CancelOrderSuccessFields on CancelOrderSuccess {
    success
    reason
  }
`;
export const CancelSuccessRefundFailedFieldsFragmentDoc = gql`
  fragment CancelSuccessRefundFailedFields on CancelSuccessRefundFailed {
    errorMessage
  }
`;
export const CancelOrderFailedFieldsFragmentDoc = gql`
  fragment CancelOrderFailedFields on CancelOrderFailed {
    errorMessage
  }
`;
export const CancellationLimitExceededFieldsFragmentDoc = gql`
  fragment CancellationLimitExceededFields on CancellationLimitExceeded {
    errorMessage
  }
`;
export const CancellationFailedWithDeliveryFieldsFragmentDoc = gql`
  fragment CancellationFailedWithDeliveryFields on CancellationFailedWithDelivery {
    errorMessage
  }
`;
export const OrderAlreadyCanceledFieldsFragmentDoc = gql`
  fragment OrderAlreadyCanceledFields on OrderAlreadyCanceled {
    errorMessage
  }
`;
export const OrderNotFoundFieldsFragmentDoc = gql`
  fragment OrderNotFoundFields on OrderNotFound {
    errorMessage
  }
`;
export const OrderAlreadySentToKitchenFieldsFragmentDoc = gql`
  fragment OrderAlreadySentToKitchenFields on OrderAlreadySentToKitchen {
    errorMessage
  }
`;
export const CancelOrderDocument = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    cancelOrder(input: $input) {
      __typename
      ...CancelOrderSuccessFields
      ...CancelSuccessRefundFailedFields
      ...CancelOrderFailedFields
      ...CancellationLimitExceededFields
      ...CancellationFailedWithDeliveryFields
      ...OrderAlreadyCanceledFields
      ...OrderNotFoundFields
      ...OrderAlreadySentToKitchenFields
    }
  }
  ${CancelOrderSuccessFieldsFragmentDoc}
  ${CancelSuccessRefundFailedFieldsFragmentDoc}
  ${CancelOrderFailedFieldsFragmentDoc}
  ${CancellationLimitExceededFieldsFragmentDoc}
  ${CancellationFailedWithDeliveryFieldsFragmentDoc}
  ${OrderAlreadyCanceledFieldsFragmentDoc}
  ${OrderNotFoundFieldsFragmentDoc}
  ${OrderAlreadySentToKitchenFieldsFragmentDoc}
`;

export function useCancelOrderMutation() {
  return Urql.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(
    CancelOrderDocument,
  );
}

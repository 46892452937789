/**
 * A small helper to return current screen orientation as a booleans object.
 *
 * NOTE: When the `width` and `height` of the screen are equal, `isPortrait` orientation
 *       option will be `true`, since we want to avoid situations where both `isPortrait`
 *       and `isLandscape` are `false`.
 */
export function getScreenOrientation(
  screenWidth: number,
  screenHeight: number,
) {
  return {
    isPortrait: screenWidth <= screenHeight,
    isLandscape: screenWidth > screenHeight,
  };
}

import type { ComponentProps } from 'react';
import React, { useCallback, useRef } from 'react';
import type { ViewProps } from 'react-native';
import { Pressable, StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../hooks';
import { getItemByIndex, webOnlyStyles } from '../../utils';
import type { CloudinaryTransformConfig } from '../Image';
import { CloudinaryImage } from '../Image';
import { LoadingPlaceholder } from '../LoadingPlaceholder';
import { BodyText } from '../Text';

// ────────────────────────────────────────────────────────────────────────────────

export const ProductAddonCard = (props: ProductAddonProps) => {
  const {
    id,
    shapeIndex = 0,
    name,
    price,
    imageUrl = '',
    testID,
    isDisabled,
    onPress,
  } = props;

  const pressableRef = useRef<View>(null);
  const { isHovered } = usePressableState(pressableRef);

  // ─────────────────────────────────────────────────────────────────

  const ShapeComponent = getItemByIndex(
    [ProductAddonCardShapeCircle, ProductAddonCardShapeSquare],
    shapeIndex,
  );

  const pressableStyle = [
    isDisabled && styles.pressableDisabled,
    webOnlyStyles({
      outlineColor: theme.colors.GRAY,
      outlineOffset: 4,
      transition: `opacity ${theme.transitions.base}ms`,
    }),
  ];

  // ─── HELPERS ─────────────────────────────────────────────────────

  const handlePress = useCallback(() => {
    if (!id || !onPress) return;
    onPress(id);
  }, [id, onPress]);

  // ─────────────────────────────────────────────────────────────────

  return (
    <View style={styles.wrapper}>
      <Pressable
        ref={pressableRef}
        style={pressableStyle}
        accessibilityLabel={name}
        accessibilityRole="button"
        onPress={onPress ? handlePress : null}
        testID={testID}
        disabled={isDisabled}
      >
        <ShapeComponent style={isHovered ? styles.shapeHover : undefined}>
          <CloudinaryImage
            style={styles.image}
            baseUrl={imageUrl}
            config={CLOUDINARY_IMG_CONFIG}
            contentFit="contain"
            testID={`${testID}-image`}
          />
        </ShapeComponent>

        <View style={styles.footer}>
          <BodyText size={4}>{name}</BodyText>
          <BodyText size={4}>{price}</BodyText>
        </View>
      </Pressable>
    </View>
  );
};

export const ProductAddonCardLoading = () => {
  return (
    <View style={styles.wrapper}>
      <LoadingPlaceholder
        palette="gray"
        rows={2}
        rowHeight={[CARD_SIZE, theme.spacing['4']]}
        gridGap={theme.spacing['4']}
        bgColor="#ebe2d2"
        fgColor="#f1e7d8"
      />
    </View>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const ProductAddonCardShapeCircle = (props: ViewProps) => {
  const { style, children, ...viewProps } = props;

  const shapeWebOnlyStyle = webOnlyStyles({
    transition: `background-color ${theme.transitions.base}ms`,
  });
  const shapeStyle = [
    styles.shape,
    styles.shapeCircle,
    shapeWebOnlyStyle,
    style,
  ];

  return (
    <View style={shapeStyle} {...viewProps}>
      {children}
    </View>
  );
};

const ProductAddonCardShapeSquare = (props: ViewProps) => {
  const { style, children, ...viewProps } = props;

  const shapeWebOnlyStyle = webOnlyStyles({
    transition: `background-color ${theme.transitions.base}ms`,
  });
  const shapeStyle = [
    styles.shape,
    styles.shapeSquare,
    shapeWebOnlyStyle,
    style,
  ];

  return (
    <View style={shapeStyle} {...viewProps}>
      {children}
    </View>
  );
};

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

const CARD_SIZE = 152;
const CARD_IMG_SIZE = CARD_SIZE - theme.spacing['6'];
const SHAPE_BG_COLOR = theme.colors.OATMEAL;
const SHAPE_BG_COLOR_HOVER = theme.colors.OPACITY.OATMEAL.DARKER;
const CLOUDINARY_IMG_CONFIG: CloudinaryTransformConfig = [
  { crop: 'crop', width: 0.5, height: 0.7 },
  { width: CARD_IMG_SIZE },
];

// ────── STYLES ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    width: CARD_SIZE,
  },
  pressableDisabled: {
    opacity: 0.5,
  },
  image: {
    width: CARD_IMG_SIZE,
    height: CARD_IMG_SIZE,
  },

  // ─── SHAPES ──────────────────────────────────────────────────────

  shape: {
    width: CARD_SIZE,
    height: CARD_SIZE,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: SHAPE_BG_COLOR,
  },
  shapeHover: {
    backgroundColor: SHAPE_BG_COLOR_HOVER,
  },
  shapeCircle: {
    borderRadius: CARD_SIZE,
  },
  shapeSquare: {
    borderRadius: theme.radius.large,
  },

  // ─────────────────────────────────────────────────────────────────

  footer: {
    paddingTop: theme.spacing['4'],
  },
});

// ────── TYPES ──────────────────────────────────────────────────────────────────

type ProductAddonProps = Readonly<{
  id?: string;
  shapeIndex?: number;
  name?: string;
  imageUrl?: ComponentProps<typeof CloudinaryImage>['baseUrl'];
  price?: string;
  testID?: string;
  isDisabled?: boolean;
  onPress?: (id: string) => void;
}>;

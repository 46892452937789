/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import { ValidationErrorFieldsFragmentDoc } from '../../../graphql/fragments/ValidationErrorFields.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InvalidPromoCodeFieldsFragment = {
  readonly __typename?: 'InvalidPromoCode';
  readonly message: string;
  readonly status: number;
};

export type SubmitPromoOrGiftCardCodeMutationVariables = Types.Exact<{
  input: Types.SubmitPromoOrGiftCardCodeInput;
}>;

export type SubmitPromoOrGiftCardCodeMutation = {
  readonly __typename?: 'Mutation';
  readonly submitPromoOrGiftCardCode:
    | {
        readonly __typename: 'InvalidPromoCode';
        readonly message: string;
        readonly status: number;
      }
    | { readonly __typename: 'PromoCodeAlreadyApplied' }
    | { readonly __typename: 'SubmitGiftCardCodeSuccess' }
    | { readonly __typename: 'SubmitPromoCodeSuccessV2' }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export const InvalidPromoCodeFieldsFragmentDoc = gql`
  fragment InvalidPromoCodeFields on InvalidPromoCode {
    message
    status
  }
`;
export const SubmitPromoOrGiftCardCodeDocument = gql`
  mutation SubmitPromoOrGiftCardCode($input: SubmitPromoOrGiftCardCodeInput!) {
    submitPromoOrGiftCardCode(input: $input) {
      __typename
      ...ValidationErrorFields
      ...InvalidPromoCodeFields
    }
  }
  ${ValidationErrorFieldsFragmentDoc}
  ${InvalidPromoCodeFieldsFragmentDoc}
`;

export function useSubmitPromoOrGiftCardCodeMutation() {
  return Urql.useMutation<
    SubmitPromoOrGiftCardCodeMutation,
    SubmitPromoOrGiftCardCodeMutationVariables
  >(SubmitPromoOrGiftCardCodeDocument);
}

import React from 'react';
import { StyleSheet } from 'react-native';
import { ELEVATIONS, TYPE_CONFIG } from '@garnish/constants';

import { Button } from '../Button';
import type { ButtonProps } from '../Button.types';

export const ButtonSearch = (props: React.ComponentProps<typeof Button>) => {
  const {
    children = BUTTON_SEARCH_DEFAULT_TEXT,
    accessibilityLabel = BUTTON_SEARCH_DEFAULT_A11Y_LABEL,
    accessibilityHint = BUTTON_SEARCH_DEFAULT_A11Y_HINT,
    style,
    ...restProps
  } = props;

  return (
    <Button
      {...BUTTON_BASIC_PROPS}
      accessibilityLabel={accessibilityLabel}
      accessibilityHint={accessibilityHint}
      style={[styles.button, style]}
      {...restProps}
    >
      {children}
    </Button>
  );
};

//
// ─── CONSTANTS ──────────────────────────────────────────────────────────────────
//

export const BUTTON_SEARCH_DEFAULT_TEXT = 'Search area';
export const BUTTON_SEARCH_DEFAULT_A11Y_LABEL = BUTTON_SEARCH_DEFAULT_TEXT;
export const BUTTON_SEARCH_DEFAULT_A11Y_HINT =
  'Searches for location in selected map area';

const BUTTON_BASIC_PROPS: ButtonProps = {
  accessibilityRole: 'button',
  palette: 'light',
};

//
// ─── STYLES ─────────────────────────────────────────────────────────────────────
//

const styles = StyleSheet.create({
  button: {
    ...TYPE_CONFIG.BODY['14'],
    ...ELEVATIONS['4'],
  },
});

import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductCardV2LabelContainer = (props: PropsWithChildren) => {
  return <View style={styles.container}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-end',
    minHeight: 22,
  },
});

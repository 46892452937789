import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, Button, Image, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { EarnAndRedeemEmptyStateIllustration } from '../../../../assets';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanInStoreEarnAndRedeemEmptyState = (
  props: ScanInStorePayEmptyStateProps,
) => {
  const { onPressCta } = props;

  const { t } = useLocalizationContext();

  return (
    <View style={styles.container}>
      <Image
        source={EarnAndRedeemEmptyStateIllustration}
        style={styles.illustration}
        resizeMode="cover"
        aria-label={t(
          'scan-in-store.tabs.earn-and-redeem.card.illustration-alt',
        )}
      />

      <BodyText sizeMatch={['18']} style={styles.body}>
        {t('scan-in-store.tabs.earn-and-redeem.card.without-subscription.text')}
      </BodyText>

      <Button size="large" onPress={onPressCta} style={styles.cta}>
        {t(
          'scan-in-store.tabs.earn-and-redeem.card.without-subscription.cta.title',
        )}
      </Button>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    borderRadius: theme.radius.large,
    backgroundColor: theme.colors.CUCUMBER,
  },
  body: {
    marginVertical: theme.spacing['8'],
    marginHorizontal: theme.spacing['6'],
    textAlign: 'center',
  },
  cta: {
    marginBottom: theme.spacing['8'],
  },
  illustration: {
    width: '100%',
    height: 206,
    borderTopLeftRadius: theme.radius.large,
    borderTopRightRadius: theme.radius.large,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanInStorePayEmptyStateProps = Readonly<{
  onPressCta: () => void;
}>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ChallengeDataFragment = {
  readonly __typename: 'Challenge';
  readonly achieved: number;
  readonly celebrationCopy: string;
  readonly descriptiveTitle: string;
  readonly expirationDate: string;
  readonly goal: number;
  readonly id: string;
  readonly legalTerms: string;
  readonly percentageCompleted: number;
  readonly permalink: string;
  readonly progress: number;
  readonly progressCopy: string;
  readonly status: Types.ChallengeStatus;
  readonly title: string;
};

export type ChallengesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ChallengesQuery = {
  readonly __typename?: 'Query';
  readonly challenges: ReadonlyArray<{
    readonly __typename: 'Challenge';
    readonly achieved: number;
    readonly celebrationCopy: string;
    readonly descriptiveTitle: string;
    readonly expirationDate: string;
    readonly goal: number;
    readonly id: string;
    readonly legalTerms: string;
    readonly percentageCompleted: number;
    readonly permalink: string;
    readonly progress: number;
    readonly progressCopy: string;
    readonly status: Types.ChallengeStatus;
    readonly title: string;
  }>;
};

export type ChallengesWithThemeQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ChallengesWithThemeQuery = {
  readonly __typename?: 'Query';
  readonly challenges: ReadonlyArray<{
    readonly __typename: 'Challenge';
    readonly theme: Types.ChallengeTheme;
    readonly achieved: number;
    readonly celebrationCopy: string;
    readonly descriptiveTitle: string;
    readonly expirationDate: string;
    readonly goal: number;
    readonly id: string;
    readonly legalTerms: string;
    readonly percentageCompleted: number;
    readonly permalink: string;
    readonly progress: number;
    readonly progressCopy: string;
    readonly status: Types.ChallengeStatus;
    readonly title: string;
  }>;
};

export type ChallengeOptInMutationVariables = Types.Exact<{
  challengeId: Types.Scalars['String']['input'];
}>;

export type ChallengeOptInMutation = {
  readonly __typename?: 'Mutation';
  readonly challengeOptIn: {
    readonly __typename: 'Challenge';
    readonly theme: Types.ChallengeTheme;
    readonly achieved: number;
    readonly celebrationCopy: string;
    readonly descriptiveTitle: string;
    readonly expirationDate: string;
    readonly goal: number;
    readonly id: string;
    readonly legalTerms: string;
    readonly percentageCompleted: number;
    readonly permalink: string;
    readonly progress: number;
    readonly progressCopy: string;
    readonly status: Types.ChallengeStatus;
    readonly title: string;
  };
};

export type ChallengeOptInV2MutationVariables = Types.Exact<{
  challengeId: Types.Scalars['String']['input'];
}>;

export type ChallengeOptInV2Mutation = {
  readonly __typename?: 'Mutation';
  readonly challengeOptInV2:
    | {
        readonly __typename: 'Challenge';
        readonly theme: Types.ChallengeTheme;
        readonly achieved: number;
        readonly celebrationCopy: string;
        readonly descriptiveTitle: string;
        readonly expirationDate: string;
        readonly goal: number;
        readonly id: string;
        readonly legalTerms: string;
        readonly percentageCompleted: number;
        readonly permalink: string;
        readonly progress: number;
        readonly progressCopy: string;
        readonly status: Types.ChallengeStatus;
        readonly title: string;
      }
    | {
        readonly __typename: 'LoyaltyOptInFailed';
        readonly errorMessage: string;
      };
};

export const ChallengeDataFragmentDoc = gql`
  fragment ChallengeData on Challenge {
    achieved
    celebrationCopy
    descriptiveTitle
    expirationDate
    goal
    id
    legalTerms
    percentageCompleted
    permalink
    progress
    progressCopy
    status
    title
    __typename
  }
`;
export const ChallengesDocument = gql`
  query Challenges {
    challenges {
      ...ChallengeData
    }
  }
  ${ChallengeDataFragmentDoc}
`;

export function useChallengesQuery(
  options?: Omit<Urql.UseQueryArgs<ChallengesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ChallengesQuery, ChallengesQueryVariables>({
    query: ChallengesDocument,
    ...options,
  });
}
export const ChallengesWithThemeDocument = gql`
  query ChallengesWithTheme {
    challenges {
      ...ChallengeData
      theme
    }
  }
  ${ChallengeDataFragmentDoc}
`;

export function useChallengesWithThemeQuery(
  options?: Omit<Urql.UseQueryArgs<ChallengesWithThemeQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    ChallengesWithThemeQuery,
    ChallengesWithThemeQueryVariables
  >({ query: ChallengesWithThemeDocument, ...options });
}
export const ChallengeOptInDocument = gql`
  mutation challengeOptIn($challengeId: String!) {
    challengeOptIn(challengeId: $challengeId) {
      ...ChallengeData
      theme
    }
  }
  ${ChallengeDataFragmentDoc}
`;

export function useChallengeOptInMutation() {
  return Urql.useMutation<
    ChallengeOptInMutation,
    ChallengeOptInMutationVariables
  >(ChallengeOptInDocument);
}
export const ChallengeOptInV2Document = gql`
  mutation challengeOptInV2($challengeId: String!) {
    challengeOptInV2(challengeId: $challengeId) {
      __typename
      ... on Challenge {
        ...ChallengeData
        theme
      }
      ... on LoyaltyOptInFailed {
        errorMessage
        __typename
      }
    }
  }
  ${ChallengeDataFragmentDoc}
`;

export function useChallengeOptInV2Mutation() {
  return Urql.useMutation<
    ChallengeOptInV2Mutation,
    ChallengeOptInV2MutationVariables
  >(ChallengeOptInV2Document);
}

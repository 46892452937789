import type { ComponentProps } from 'react';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import type { LayoutChangeEvent, ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import type { RouteProp } from '@react-navigation/native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useRoute } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import {
  BoundScrollView,
  Container,
  FLOATING_HEADER_HEIGHT,
  HStack,
  JumpTarget,
  theme,
  useFloatingHeaderScrollDistance,
  useJumpNavigationCtx,
  useResponsive,
  VStack,
} from '@sg/garnish';

import { SweetpassFloatingHeader } from '@order/components';
import type { Challenge } from '@order/graphql';

import type { LoyaltyTabStackParamList } from '../../../../navigation/AppNavigation.props';
import {
  type SweetpassHomeChallengesRailContent,
  type SweetpassHomeRewardsRailContent,
  type useSweetpassChallengesAndRewards,
  type useSweetpassHomeContentfulData,
} from '../../hooks';
import { SweetpassHomeActionsRail } from '../SweetpassHomeActionsRail';
import { SweetpassHomeChallengesRail } from '../SweetpassHomeChallengesRail';
import type { SweetpassHeroStatsPalette } from '../SweetpassHomeHeroStats';
import { SweetpassHomeHeroStats } from '../SweetpassHomeHeroStats';
import { SweetpassHomeRewardsRail } from '../SweetpassHomeRewardsRail';

// ─────────────────────────────────────────────────────────────────────────────

export const SweetpassHomeContent = memo(
  ({
    testID,
    palette,
    detailedHeroStats,
    sweetpassHomeStats,
    sweetpassHomeActionsRail,
    contentItems,
    challenges,
    challengeRewards,
    isFetchingChallenges,
    isFetchingChallengeRewards,
    tierLevel,
    navigateToBenefits,
    navigateToUpgrade,
  }: SweetpassHomeContentProps) => {
    const jumpContext = useJumpNavigationCtx();
    const { match } = useResponsive();

    // ─── Helpers ─────────────────────────────────────────────────────────

    const scrollToRewards = useCallback(() => {
      jumpContext.setCurrentTarget('rewards-rail');
    }, [jumpContext]);

    const scrollToChallenges = useCallback(() => {
      jumpContext.setCurrentTarget('challenges-rail');
    }, [jumpContext]);

    // @ts-expect-error TS(2345): Argument of type 'readonly { readonly __typename: ... Remove this comment to see the full error message
    const availableChallengesCount = useAvailableChallengesCount(challenges);

    // ─── Styles ──────────────────────────────────────────────────────────

    const contentWrapperStyle = [
      styles.contentWrapper,
      match([styles.contentWrapperXS, styles.contentWrapperSM]),
    ];

    const hasTwoHeroCards = Boolean(
      sweetpassHomeStats && sweetpassHomeActionsRail,
    );
    const heroCardsPerRow = match([1, hasTwoHeroCards ? 2 : 1]);

    // ─── Floating Header Helpers ─────────────────────────────────────────

    const [heroStatsContainerHeight, setHeroStatsContainerHeight] = useState(0);

    const storeHeroStatsHeight = useCallback((event: LayoutChangeEvent) => {
      setHeroStatsContainerHeight(event.nativeEvent.layout.height);
    }, []);

    const [scrollDistance, setScrollDistance] =
      useFloatingHeaderScrollDistance();

    // ─── Scroll To Navigation Target ─────────────────────────────────────

    const navigation =
      useNavigation<NativeStackNavigationProp<LoyaltyTabStackParamList>>();
    const route = useRoute<RouteProp<LoyaltyTabStackParamList>>();
    const target = route?.params?.target;

    useFocusEffect(
      useCallback(() => {
        if (target === 'rewards') scrollToRewards();

        if (target === 'challenges') scrollToChallenges();

        if (target) navigation?.setParams?.({ target: undefined });
      }, [target, navigation, scrollToRewards, scrollToChallenges]),
    );

    // ─── Effects ─────────────────────────────────────────────────────────

    // Set the scroll context offset to avoid content hiding due to the
    // sweetpass floating header.
    useEffect(() => {
      // NOTE: Type casting is used, to avoid the "Operands of '+' operation
      //       with any" eslint error.
      const floatingHeaderHeight = FLOATING_HEADER_HEIGHT as number;
      const xsOffset = floatingHeaderHeight + theme.spacing['4'];

      jumpContext.setOffset(match([xsOffset, 0]));
    }, [jumpContext, match]);

    // ─────────────────────────────────────────────────────────────────────

    return (
      <View style={styles.outerContainer}>
        <SweetpassFloatingHeader
          scrollDistance={scrollDistance}
          offset={heroStatsContainerHeight / 1.5}
        />

        <BoundScrollView
          style={styles.container}
          testID={testID}
          onScroll={setScrollDistance}
        >
          <Container
            style={styles.container}
            wrapperStyle={contentWrapperStyle}
            removeSpacing={match([true, false])}
          >
            <VStack gap={0}>
              <HStack
                itemsPerRow={heroCardsPerRow}
                gap={match([0, theme.spacing['6'], theme.spacing['10']])}
              >
                {sweetpassHomeStats?.type ? (
                  <View
                    onLayout={storeHeroStatsHeight}
                    style={match([
                      styles.heroStatsContainerXS,
                      styles.heroStatsContainerSM,
                    ])}
                  >
                    <SweetpassHomeHeroStats
                      content={sweetpassHomeStats}
                      palette={palette}
                      amountSaved={2700}
                      rewardsCount={challengeRewards?.length ?? 0}
                      challengesCount={availableChallengesCount}
                      detailedHeroStats={detailedHeroStats}
                      navigateToBenefits={navigateToBenefits}
                      navigateToUpgrade={navigateToUpgrade}
                      navigateToRewards={scrollToRewards}
                      navigateToChallenges={scrollToChallenges}
                    />
                  </View>
                ) : null}

                {sweetpassHomeActionsRail ? (
                  <Container
                    wrapperStyle={styles.containerWrapper}
                    style={styles.container}
                    removeSpacing={match([false, true])}
                  >
                    <SweetpassHomeActionsRail
                      content={sweetpassHomeActionsRail}
                      tierLevel={tierLevel}
                    />
                  </Container>
                ) : null}
              </HStack>

              {contentItems?.map((item) => {
                if (item.type === 'sweetpassHomeChallengesRail') {
                  return (
                    <JumpTarget key={item.id} id="challenges-rail">
                      <Container
                        style={styles.container}
                        wrapperStyle={contentWrapperStyle}
                        removeSpacing={match([false, true])}
                      >
                        <SweetpassHomeChallengesRail
                          content={item as SweetpassHomeChallengesRailContent}
                          challenges={challenges ?? []}
                          availableCount={availableChallengesCount}
                          isFetching={isFetchingChallenges}
                        />
                      </Container>
                    </JumpTarget>
                  );
                }

                if (item.type === 'sweetpassHomeRewardsRail') {
                  return (
                    <JumpTarget key={item.id} id="rewards-rail">
                      <Container
                        style={styles.container}
                        wrapperStyle={contentWrapperStyle}
                        removeSpacing={match([false, true])}
                      >
                        <SweetpassHomeRewardsRail
                          content={item as SweetpassHomeRewardsRailContent}
                          rewards={challengeRewards ?? []}
                          isFetching={isFetchingChallengeRewards}
                        />
                      </Container>
                    </JumpTarget>
                  );
                }

                return null;
              })}
            </VStack>
          </Container>
        </BoundScrollView>
      </View>
    );
  },
);

// ─── Hooks ───────────────────────────────────────────────────────────────────

/**
 * Only non completed (achieved === 0) challenges are considered available.
 */
const useAvailableChallengesCount = (challenges: readonly Challenge[] = []) => {
  return useMemo(
    () => challenges.filter((challenge) => challenge.achieved === 0).length,
    [challenges],
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
  },
  contentWrapper: {
    flex: 1,
  },
  contentWrapperXS: {
    paddingBottom: theme.spacing['6'],
  },
  contentWrapperSM: {
    paddingVertical: theme.spacing['10'],
  },
  containerWrapper: {
    flex: 1,
  },
  heroStatsContainerXS: {
    marginBottom: theme.spacing['6'],
  },
  heroStatsContainerSM: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassHomeContentProps = Readonly<{
  palette: SweetpassHeroStatsPalette;
  detailedHeroStats: boolean;
  navigateToBenefits: () => void;
  navigateToUpgrade: () => void;
}> &
  Pick<ComponentProps<typeof SweetpassHomeActionsRail>, 'tierLevel'> &
  Pick<ViewProps, 'testID'> &
  ReturnType<
    typeof useSweetpassHomeContentfulData
  >['sweetpassHomeContentfulData'] &
  ReturnType<typeof useSweetpassChallengesAndRewards>;

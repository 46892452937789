import type { ReactElement } from 'react';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';

import { Icon } from '../../Icon';
import {
  SHARED_ICON_SIZE_STYLES,
  SHARED_TEXT_COLOR_STYLES,
  useIconStyles,
} from '../styles';
import type {
  GarnishProps as TagAlertIconProps,
  PaletteTypes,
  SizeTypes,
  TagAlertIconMapType,
} from '../TagAlert.types';
import { TagAlertPalette, TagAlertSize } from '../TagAlert.types';

const TagAlertIconMaps: TagAlertIconMapType = {
  [TagAlertPalette.neutral]: {
    name: 'IconAlert',
  },
  [TagAlertPalette.notice]: {
    name: 'IconAlert',
  },
  [TagAlertPalette.success]: {
    name: 'IconBadgeCheck',
  },
  [TagAlertPalette.caution]: {
    name: 'IconAlert',
  },
  [TagAlertPalette.cautionDark]: {
    name: 'IconAlert',
  },
};

export const AlertIcon = (props: TagAlertIconProps): ReactElement => {
  const {
    palette = TagAlertPalette.success as PaletteTypes,
    size = TagAlertSize.medium as SizeTypes,
  } = props;
  const useStyleState = useIconStyles(palette, size);
  const { name } = TagAlertIconMaps[palette];
  const iconColor =
    Platform.OS === 'web'
      ? 'currentColor'
      : SHARED_TEXT_COLOR_STYLES[palette].color;

  return (
    <View style={[styles.tagAlertIcon, useStyleState()]}>
      <Icon name={name} color={iconColor} {...SHARED_ICON_SIZE_STYLES[size]} />
    </View>
  );
};

const styles = StyleSheet.create({
  tagAlertIcon: {
    alignSelf: 'flex-start',
    textAlign: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
});

import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { formatPhoneNumber } from '@sg/garnish';

import { MenuMoreInfoText } from '../MenuMoreInfoText';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoRestaurantPhoneNumber = (
  props: MenuMoreInfoRestaurantPhoneNumberProps,
) => {
  const { phoneNumber } = props;

  const formattedPhoneNumber = formatPhoneNumber({
    phoneNumber,
    shouldMinify: true,
  }).national;

  return (
    <MenuMoreInfoText>
      <FormattedMessage
        {...messages.phoneNumber}
        values={{ phone_number: formattedPhoneNumber }}
      />
    </MenuMoreInfoText>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  phoneNumber: {
    defaultMessage: 'Call: {phone_number}',
    description: 'Message | Location',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuMoreInfoRestaurantPhoneNumberProps = {
  phoneNumber: string;
};

import React, { useCallback } from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';
import {
  type IconName,
  SelectList,
  theme,
  TYPE_CONFIG,
  useResponsive,
} from '@sg/garnish';

import { Modal } from '../Modal';

/**
 * Modal component for option selection.
 */
export const SelectModal = (props: SelectModalProps) => {
  const {
    visible,
    title,
    selectedOption,
    options,
    onOptionSelected,
    onRequestClose,
  } = props;

  const handleOptionSelected = useCallback(
    (optionId: string) => {
      onOptionSelected(optionId);
      onRequestClose();
    },
    [onOptionSelected, onRequestClose],
  );

  return (
    <SelectModalWrapper
      title={title}
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <SelectModalHeader title={title} onRequestClose={onRequestClose} />

      <SelectList.Container>
        {options.map((option) => (
          <SelectList.Option
            key={option.value}
            value={option.value}
            label={option.label}
            isSelected={selectedOption === option.value}
            onSelect={handleOptionSelected}
          >
            <SelectList.OptionIcon name={option.icon} />
            <SelectList.OptionLabel>{option.label}</SelectList.OptionLabel>
          </SelectList.Option>
        ))}
      </SelectList.Container>
    </SelectModalWrapper>
  );
};

// ─── Components ────────────────────────────────────────────────────────

const SelectModalWrapper = (props: SelectModalWrapperProps) => {
  const { title, visible, children, onRequestClose } = props;
  const { match } = useResponsive();

  return (
    <Modal
      size="small"
      visible={visible}
      accessibilityLabel={title}
      onRequestClose={onRequestClose}
    >
      <View style={match([styles.wrapperXs, styles.wrapperSm])}>
        {children}
      </View>
    </Modal>
  );
};

const SelectModalHeader = (props: SelectModalHeaderProps) => {
  const { title, onRequestClose } = props;

  return (
    <Modal.Header style={styles.headerFont} onClose={onRequestClose}>
      {title}
    </Modal.Header>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type SelectModalOptionPayload = Readonly<{
  value: string;
  label: string;
  icon: IconName;
}>;

type SelectModalWrapperProps = Readonly<{
  visible: boolean;
  title: string;
  children: ViewProps['children'];
  onRequestClose: () => void;
}>;

type SelectModalProps = Readonly<{
  visible: boolean;
  title: string;
  selectedOption: string | undefined;
  options: Readonly<SelectModalOptionPayload[]>;
  onOptionSelected: (id: string) => void;
  onRequestClose: () => void;
}>;

type SelectModalHeaderProps = Readonly<{
  title: string;
  onRequestClose: () => void;
}>;

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapperXs: {
    paddingBottom: theme.spacing['10'],
  },
  wrapperSm: {
    paddingBottom: theme.spacing['4'],
  },
  // @ts-expect-error TS(2322): Type '{ fontSize: number; fontFamily: string; font... Remove this comment to see the full error message
  headerFont: {
    // size match is sadly overwritten by Modal.Header component
    ...TYPE_CONFIG.DISPLAY['24'],
  },
});

// ─── Compound Components ───────────────────────────────────────────────
/* eslint-disable functional/immutable-data */
SelectModal.Wrapper = SelectModalWrapper;
SelectModal.Header = SelectModalHeader;

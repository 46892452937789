import { useCallback, useState } from 'react';

/**
 * Small utility to reuse simple toggle logic.
 * For example for controlling a Modal's visibility.
 */
export function useToggleState(defaultValue = false) {
  const [value, setValue] = useState(defaultValue);

  const toggle = useCallback(() => {
    setValue((current) => !current);
  }, []);

  const toggleOn = useCallback(() => {
    setValue(true);
  }, []);

  const toggleOff = useCallback(() => {
    setValue(false);
  }, []);

  return { value, toggle, toggleOn, toggleOff };
}

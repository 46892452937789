import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { ViewProps } from 'react-native';

import { useCustomer } from '@order/Customer';
import type { Vendor } from '@order/graphql';
import { useLineItemName } from '@order/shared/hooks';
import { telemetryProductFromInput, useTelemetry } from '@order/Telemetry';

import {
  useAddLineItemToCart,
  useCart,
  useDeliveryOrderInFlight,
  useEditLineItemInCart,
  useRemoveLineItemFromCart,
} from '../../hooks';
import { useCheckoutTelemetry } from '../CheckoutScreen/hooks/useCheckoutTelemetry/useCheckoutTelemetry';
import { useProduct, useScrollToAddedIngredient } from './hooks';
import { useProductModificationsMachine } from './state';

// ────────────────────────────────────────────────────────────────────────────────

const ProductDetailsScreenContext = createContext<
  ProductDetailsScreenCtx | undefined
>(undefined);

export const ProductDetailsScreenProvider = (
  props: ProductDetailsScreenProviderProps,
) => {
  const { customer } = useCustomer();
  const { cart, cartOrderType, total } = useCart();
  const {
    restaurantSlug,
    addressId,
    ingredientIds,
    productSlug,
    lineItemId,
    reorderLineItemId,
    name,
    vendor,
    deliveryFee = 0,
    isDelivery,
    onAddLineItemToCart,
    onEditLineItemInCart,
    onRemoveLineItemFromCart,
    children,
  } = props;

  // ─── NAVIGATION ─────────────────────────────────────────────────────

  const productResponse = useProduct({
    productSlug,
    lineItemId: lineItemId ?? reorderLineItemId,
    ingredientIds,
    isDelivery,
  });
  const { product, lineItem, ingredients, stale } = productResponse;

  const addLineItemToCartMutation = useAddLineItemToCart({
    onSuccess: onAddLineItemToCart,
  });
  const editLineItemInCartMutation = useEditLineItemInCart({
    onSuccess: onEditLineItemInCart,
  });
  const removeFromCartMutation = useRemoveLineItemFromCart({
    onSuccess: onRemoveLineItemFromCart,
  });

  const { addLineItemToCart } = addLineItemToCartMutation;
  const { editLineItemInCart } = editLineItemInCartMutation;
  const { removeLineItemFromCart } = removeFromCartMutation;

  // ────────────────────────────────────────────────────────────────────

  const productQuantity = lineItem?.quantity ?? 1;
  const productName = useLineItemName(product, lineItem, name);

  const isCustomProduct = product?.isCustom;
  const isModifiableProduct = Boolean(product?.isModifiable);

  // ─── STATE ──────────────────────────────────────────────────────────

  const [quantity, setQuantity] = useState(productQuantity);

  const productModificationMachineHelpers = useProductModificationsMachine({
    // @ts-expect-error TS(2322): Type '{ ingredientsModifications: { readonly __typ... Remove this comment to see the full error message
    ingredients,
    isCustomProduct,
    isModifiableProduct,
    productName: name ?? lineItem?.customName ?? product?.name,
    calories: product?.calories,
    hasStaleData: stale,
  });
  const { modifications } = productModificationMachineHelpers;

  const scrollToAddedIngredientHelpers = useScrollToAddedIngredient({
    activeIngredientModifications: modifications.active,
  });

  // ─── DELIVERY ────────────────────────────────────────────────────────

  const deliveryDetails = useMemo(() => {
    const { name: vendorName = '', restaurantId: vendorRestaurantId = '' } =
      vendor ?? {};

    return addressId
      ? {
          tip: 0,
          addressId,
          vendor: vendorName,
          vendorRestaurantId,
          deliveryFee,
        }
      : undefined;
  }, [vendor, addressId, deliveryFee]);

  // ─── TELEMETRY ───────────────────────────────────────────────────

  const { data: telemetryData } = useCheckoutTelemetry({
    customer,
    cart,
    cartOrderType: addressId ? 'delivery' : cartOrderType,
    total,
  });

  const { track } = useTelemetry();

  const onAddLineItemToCartTelemetry = useCallback(() => {
    track('pdp.add_to_bag', {
      ...telemetryData,
      selectedProduct: telemetryProductFromInput(
        { ...product, quantity, location: restaurantSlug },
        modifications,
      ),
    });
  }, [modifications, product, quantity, restaurantSlug, telemetryData, track]);

  // ─── Delivery Order In Flight ──────────────────────────────────────

  useDeliveryOrderInFlight({ pause: !addressId }); // results later used from cache.

  // ─── HELPERS ────────────────────────────────────────────────────────

  const modifyCart = useCallback(async () => {
    if (!product) return;

    onAddLineItemToCartTelemetry();
    const { customName } = modifications;

    if (lineItemId) {
      await editLineItemInCart({
        // @ts-expect-error TS(2322): Type '{ readonly __typename?: "Product" | undefine... Remove this comment to see the full error message
        product,
        lineItemId,
        quantity,
        customName,
        modifications,
      });

      return;
    }

    await addLineItemToCart({
      // @ts-expect-error TS(2322): Type '{ readonly __typename?: "Product" | undefine... Remove this comment to see the full error message
      product,
      quantity,
      deliveryDetails,
      customName,
      modifications,
    });
  }, [
    product,
    modifications,
    lineItemId,
    quantity,
    deliveryDetails,
    addLineItemToCart,
    editLineItemInCart,
    onAddLineItemToCartTelemetry,
  ]);

  // ─── EFFECTS ────────────────────────────────────────────────────────

  // synchronize local quantity with product quantity on change
  useEffect(() => {
    setQuantity(productQuantity);
  }, [productQuantity]);

  // ────────────────────────────────────────────────────────────────────

  const value = useMemo(
    () => ({
      ...productModificationMachineHelpers,
      ...productResponse,
      ...scrollToAddedIngredientHelpers,
      productName,
      isModifiableProduct,
      restaurantSlug,
      addressId,
      lineItemId,
      quantity,
      modifyCart,
      removeLineItemFromCart,
      setQuantity,
    }),
    [
      productModificationMachineHelpers,
      productResponse,
      scrollToAddedIngredientHelpers,
      productName,
      isModifiableProduct,
      restaurantSlug,
      addressId,
      lineItemId,
      quantity,
      modifyCart,
      removeLineItemFromCart,
    ],
  );

  return (
    <ProductDetailsScreenContext.Provider value={value}>
      {children}
    </ProductDetailsScreenContext.Provider>
  );
};

// ─── HOOKS ──────────────────────────────────────────────────────────────────────

export const useProductDetailsScreenContext = () => {
  const context = useContext(ProductDetailsScreenContext);

  if (context === undefined) {
    throw new Error(
      'useProductDetailsScreenContext must be used within a <ProductDetailsScreenProvider>',
    );
  }

  return context;
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ProductDetailsScreenCtx = Readonly<{
  isModifiableProduct: boolean;
  lineItemId?: string;
  restaurantSlug?: string;
  addressId?: string;
  modifyCart: () => Promise<void>;
  quantity: number;
  setQuantity: (quantity: number) => void;
}> &
  ReturnType<typeof useProduct> &
  ReturnType<typeof useProductModificationsMachine> &
  Pick<ReturnType<typeof useRemoveLineItemFromCart>, 'removeLineItemFromCart'> &
  ReturnType<typeof useScrollToAddedIngredient>;

type ProductDetailsScreenHandlers = Readonly<{
  onAddLineItemToCart?: () => void;
  onEditLineItemInCart?: () => void;
  onRemoveLineItemFromCart?: () => void;
}>;

type ProductDetailsScreenProviderProps = Readonly<{
  restaurantSlug?: string;
  addressId?: string;
  ingredientIds?: string[];
  productSlug: string;
  lineItemId?: string;

  /**
   * Used to add a new product in the bag based on a previously modified line item.
   */
  reorderLineItemId?: string;
  deliveryFee?: number;
  vendor?: Vendor;
  name?: string;
  isDelivery?: boolean;
}> &
  ProductDetailsScreenHandlers &
  Pick<ViewProps, 'children'>;

import {
  GiftCardsHeaderContainer,
  GiftCardsHeading,
  GiftCardsSubheading,
  GiftCardsSubheadingLink,
  GiftCardsSubheadingTextWrapper,
} from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsHeader = {
  Container: GiftCardsHeaderContainer,
  Heading: GiftCardsHeading,
  SubheadingTextWrapper: GiftCardsSubheadingTextWrapper,
  Subheading: GiftCardsSubheading,
  SubheadingLink: GiftCardsSubheadingLink,
};

/* eslint-disable functional/immutable-data */

import React, { forwardRef, useCallback, useRef, useState } from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import Animated from 'react-native-reanimated';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';
import { Header } from '@expo/html-elements';
import { theme } from '@garnish/constants';

import { useUniqueNativeID } from '../../hooks';
import { Card } from '../Card';
import { IconLink } from '../Icon';
import { CloudinaryImage } from '../Image';
import { BodyText } from '../Text';
import {
  useCardStyles,
  useControlsStyle,
  useImgStyle,
  useTitleStyle,
  useWrapperAnimatedStyle,
} from './IngredientCard.hooks';
import { styles } from './IngredientCard.styles';

// ────────────────────────────────────────────────────────────────────────────────

export const IngredientCard = forwardRef<View, IngredientCardProps>(
  (props, ref) => {
    const {
      imgUrl,
      imgAriaLabel,
      title,
      quantity = 0,
      isSelected,
      isUnavailable,
      isDisabled,
      accessibilityLabel,
      accessibilityHint,
      unavailableNotice,
      restrictionsNotice,
      decreaseQuantityAccessibilityLabel,
      testID,
      onPress,
      onDecreaseQuantity,
    } = props;

    // ────────────────────────────────────────────────────────────────────

    const [cardAnimation, setCardAnimation] =
      useState<IngredientCardAnimation>(null);
    const cardAnimationTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const animateCard = useCallback(
      (shouldTriggerSuccessAnimation: boolean | undefined | void) => {
        if (typeof shouldTriggerSuccessAnimation !== 'boolean') return;

        const animation = shouldTriggerSuccessAnimation ? 'pulse' : 'shake';

        setCardAnimation(animation);

        cardAnimationTimeoutRef.current = setTimeout(() => {
          setCardAnimation(null);
        }, theme.transitions.base);
      },
      [],
    );

    // ─── Helpers ────────────────────────────────────────────────────────

    const handleOnPress = useCallback(() => {
      if (!onPress) return;

      const response = onPress();

      animateCard(response);
    }, [animateCard, onPress]);

    const handleOnDecreaseQuantity = useCallback(() => {
      if (!onDecreaseQuantity) return;

      const response = onDecreaseQuantity();

      animateCard(response);
    }, [animateCard, onDecreaseQuantity]);

    // ─── Styles ─────────────────────────────────────────────────────────

    const wrapperAnimatedStyle = useWrapperAnimatedStyle({ cardAnimation });
    const wrapperStyles = [styles.cardWrapper, wrapperAnimatedStyle];
    const cardStyle = useCardStyles();
    const { imgStyle, imgCloudinaryConfig } = useImgStyle({ isUnavailable });
    const { titleWrapperStyle, titleStyle, titleSize } = useTitleStyle();
    const { controlsWrapperStyle } = useControlsStyle({ isSelected });

    // ────────────────────────────────────────────────────────────────────

    const shouldShowControlsBar =
      !isUnavailable && (isSelected || (!isSelected && quantity > 1));

    return (
      <Animated.View style={wrapperStyles}>
        <IngredientCardShadow />

        <Card
          testID={testID}
          ref={ref}
          onPress={handleOnPress}
          accessibilityRole="button"
          style={cardStyle}
          accessibilityLabel={accessibilityLabel}
          accessibilityHint={accessibilityHint}
          disabled={isDisabled ?? isUnavailable}
          isSelected={isSelected}
          onLongPress={handleOnDecreaseQuantity}
        >
          <Header
            accessibilityRole="header"
            aria-level={2}
            style={titleWrapperStyle}
          >
            <BodyText size={titleSize} style={titleStyle} numberOfLines={2}>
              {title}
            </BodyText>
          </Header>

          <View style={controlsWrapperStyle}>
            <CloudinaryImage
              baseUrl={imgUrl}
              config={imgCloudinaryConfig}
              style={imgStyle}
              aria-label={imgAriaLabel}
              contentFit="contain"
            />

            <IngredientCardControlsBar
              testID={testID}
              show={shouldShowControlsBar}
              isSelected={isSelected}
              quantity={quantity}
              onDecreaseQuantity={handleOnDecreaseQuantity}
              decreaseQuantityAccessibilityLabel={
                decreaseQuantityAccessibilityLabel
              }
              shouldShowDecreaseQuantity={Boolean(onDecreaseQuantity)}
            />
          </View>

          <IngredientCardNotice
            isUnavailable={isUnavailable}
            unavailableNotice={unavailableNotice}
            restrictionsNotice={restrictionsNotice}
          />
        </Card>
      </Animated.View>
    );
  },
);

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const IngredientCardControlsBar = (props: IngredientCardControlsProps) => {
  const {
    show,
    quantity,
    isSelected,
    decreaseQuantityAccessibilityLabel = 'Decrease quantity',
    shouldShowDecreaseQuantity,
    onDecreaseQuantity,
    testID,
  } = props;

  const {
    controlsBarStyle,
    decreaseQuantityBtnStyle,
    decreaseQuantityIconStyle,
    countStyle,
    countTextStyle,
  } = useControlsStyle({ isSelected });

  if (!show) return null;

  return (
    <View style={controlsBarStyle}>
      {shouldShowDecreaseQuantity ? (
        <View style={decreaseQuantityBtnStyle}>
          <IconLink
            testID={`${testID}-decrease-quantity`}
            name="IconMinus"
            iconSize={24}
            style={decreaseQuantityIconStyle}
            accessibilityRole="button"
            accessibilityLabel={decreaseQuantityAccessibilityLabel}
            onPress={onDecreaseQuantity}
            hitSlop={theme.spacing['1']}
          />
        </View>
      ) : null}

      {quantity ? (
        <View style={countStyle}>
          <BodyText size={5} style={countTextStyle}>
            {quantity}
          </BodyText>
        </View>
      ) : null}
    </View>
  );
};

const IngredientCardNotice = (props: IngredientCardNoticeProps) => {
  const {
    isUnavailable,
    unavailableNotice = 'Unavailable',
    restrictionsNotice,
  } = props;
  const notice = isUnavailable ? unavailableNotice : restrictionsNotice;
  const noticeStyle = [
    styles.notice,
    !isUnavailable && styles.noticeRestrictions,
  ];

  if (!notice) return null;

  return (
    <BodyText style={noticeStyle} size={5}>
      {notice}
    </BodyText>
  );
};

/**
 * Renders pseudo-shadow effect to the Card component using SVG gradient.
 *
 * NOTE: Card components can be used in various layout animations that do not function
 * correctly with shadow styles (particularly on Android), therefore we must
 * emulate those using other elements such as SVG to avoid UI issues.
 */
const IngredientCardShadow = () => {
  // To avoid problems when there are several `IngredientCardShadow` components
  // on the same screen, we must use unique IDs for each component.
  const gradientId = useUniqueNativeID('ingredient-shadow-gradient');

  return (
    <View style={styles.cardShadowOuterContainer}>
      <Svg height="100%" width="100%" style={StyleSheet.absoluteFillObject}>
        <Defs>
          <LinearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
            <Stop
              offset="0.98"
              stopColor={theme.colors.GREEN_1}
              stopOpacity={0.15}
            />
            <Stop offset="1" stopColor={theme.colors.GREEN_1} stopOpacity={0} />
          </LinearGradient>
        </Defs>

        <Rect width="100%" height="100%" fill={`url(#${gradientId})`} />
      </Svg>
    </View>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type IngredientCardProps = Readonly<{
  testID?: string;
  title: string;
  imgUrl: string;
  imgAriaLabel: string;
  quantity?: number;
  isUnavailable?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  unavailableNotice?: string;
  restrictionsNotice?: string;
  decreaseQuantityAccessibilityLabel?: string;
  onPress?: () => boolean | void;
  onDecreaseQuantity?: () => boolean | void;
}> &
  Pick<ViewProps, 'accessibilityLabel' | 'accessibilityHint'>;

type IngredientCardControlsProps = Readonly<{
  testID?: string;
  show: boolean;
  isSelected?: boolean;
  quantity?: number;
  decreaseQuantityAccessibilityLabel?: string;
  shouldShowDecreaseQuantity: boolean;
  onDecreaseQuantity?: () => void;
}>;

type IngredientCardNoticeProps = Readonly<{
  isUnavailable?: boolean;
  unavailableNotice?: string;
  restrictionsNotice?: string;
}>;

type IngredientCardAnimation = 'pulse' | 'shake' | null;

import React, { type ReactNode, useRef } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { theme } from '@garnish/constants';
import { webOnlyStyles } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderLineItemPressable = (props: OrderLineItemPressableProps) => {
  const {
    children,
    variation = 'horizontal',
    'aria-label': ariaLabel,
    isDisabled,
    onPress,
  } = props;

  const pressableRef = useRef(null);
  const isHovered = useHover(pressableRef);

  // ─── Styles ──────────────────────────────────────────────────────────

  const pressableStyles = [
    styles.pressable,
    variation === 'horizontal' ? styles.pressableHorizontal : undefined,
    pressableWebStyles,
  ];

  const pressableBackdropStyles = [
    styles.pressableBackdrop,
    variation === 'horizontal'
      ? styles.pressableBackdropSM
      : styles.pressableBackdropMD,
    isHovered ? styles.pressableBackdropHover : undefined,
    pressableBackdropWebStyles,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <TouchableOpacity
      ref={pressableRef}
      accessibilityRole="button"
      aria-label={ariaLabel}
      onPress={onPress}
      disabled={isDisabled}
      style={pressableStyles}
    >
      <View style={pressableBackdropStyles} />

      {children}
    </TouchableOpacity>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    flex: 1,
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing['2'],
  },
  pressableHorizontal: {
    flexDirection: 'row',
  },
  pressableBackdrop: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: theme.radius.medium,
  },
  pressableBackdropSM: {
    margin: -theme.spacing['1'],
  },
  pressableBackdropMD: {
    margin: -theme.spacing['2'],
  },
  pressableBackdropHover: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
});

const pressableWebStyles = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: theme.spacing['2'],
  outlineWidth: 2,
});

const pressableBackdropWebStyles = webOnlyStyles({
  transition: `background-color ${theme.transitions.base}ms`,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderLineItemPressableProps = {
  onPress: () => void;
  'aria-label': string;
  variation?: 'vertical' | 'horizontal';
  isDisabled?: boolean;
  children: ReactNode;
};

import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import {
  Container,
  DisplayText,
  FlappyBowl,
  theme,
  TitleText,
  useResponsive,
} from '@sg/garnish';

export const LoyaltyHomeChallengesEmptyState = () => {
  const { match, minWidth } = useResponsive();

  return (
    <View style={match([styles.containerXS, styles.containerSM])}>
      <Container style={match([styles.contentXs, styles.contentSm])}>
        {minWidth.isSM ? (
          <DisplayText style={styles.whiteLabel} sizeMatch={['24']}>
            <FormattedMessage {...messages.title} />
          </DisplayText>
        ) : null}

        <FlappyBowl style={styles.flappyBowl} />

        <TitleText
          style={match([styles.kaleLabel, styles.sweetCornLabel])}
          sizeMatch={['32']}
        >
          <FormattedMessage {...messages.emptyState} />
        </TitleText>
      </Container>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Offers',
    description: 'Loyalty | Home | Challenges | Title',
  },
  emptyState: {
    defaultMessage: "We're growing new offers. Check back soon",
    description: 'Loyalty | Home | Challenges | Empty State',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    marginHorizontal: theme.spacing['4'],
    marginBottom: theme.spacing['4'],
  },
  containerSM: {
    backgroundColor: theme.colors.FOREST,
  },
  contentXs: {
    backgroundColor: theme.colors.SWEET_CORN,
    borderColor: theme.colors.KALE,
    borderWidth: 2,
    borderRadius: theme.radius.medium,
    paddingVertical: theme.spacing['10'],
    paddingHorizontal: theme.spacing['6'],
    gap: theme.spacing['2'],
  },
  contentSm: {
    paddingVertical: theme.spacing['10'],
    gap: theme.spacing['2'],
  },
  flappyBowl: {
    alignSelf: 'center',
  },
  whiteLabel: {
    color: theme.colors.WHITE,
  },
  kaleLabel: {
    color: theme.colors.KALE,
    textAlign: 'center',
  },
  sweetCornLabel: {
    color: theme.colors.SWEET_CORN,
    textAlign: 'center',
  },
});

import type { ComponentProps } from 'react';
import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { BodyText, theme, useUniqueNativeID } from '@sg/garnish';

export const ProductDressingAndDetailsRow = (
  props: ProductDressingAndDetailsRowProps,
) => {
  const { title, helperText, children, style, ...viewProps } = props;

  const wrapperStyle = [styles.wrapper, style];
  const helperTextNativeID = useUniqueNativeID('pdp-helper-text');

  return (
    <View style={wrapperStyle} {...viewProps}>
      <View style={styles.innerWrapper}>
        <BodyText
          style={styles.title}
          size={4}
          // @ts-expect-error TS(2322): Type '{ children: string; style: { flex: number; p... Remove this comment to see the full error message
          accessibilityDescribedBy={helperText ? helperTextNativeID : undefined}
        >
          {title}
        </BodyText>

        <View>{children}</View>
      </View>

      <HelperText nativeID={helperTextNativeID}>{helperText}</HelperText>
    </View>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const HelperText = (props: HelperTextProps) => {
  const { children } = props;

  if (!children) return null;

  return (
    <BodyText size={5} style={styles.helperText}>
      {children}
    </BodyText>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: theme.spacing['6'],
  },

  innerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  title: {
    flex: 1,
    paddingRight: theme.spacing['4'],
  },

  helperText: {
    paddingTop: theme.spacing['4'],
    color: theme.colors.CHARCOAL,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ProductDressingAndDetailsRowProps = Readonly<{
  title: string;
  helperText?: string;
  children: ViewProps['children'];
}> &
  ViewProps;

type HelperTextProps = ComponentProps<typeof BodyText>;

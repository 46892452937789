import { useWindowDimensions } from 'react-native';
import { useStyle } from 'react-native-style-utilities';

const MIN_WINDOW_WIDTH = 320;
const MAX_WINDOW_WIDTH = 1440;

export type DynamicGrowthSizeProps = Readonly<{
  min: number;
  max: number;
  aspectRatio: number;
}>;

export type DynamicGrowthSizeType = Readonly<{
  width: number;
  height: number;
}>;

// Grows a given size from min to max following window size and the aspect ratio.
export function useDynamicGrowthSize(
  props: DynamicGrowthSizeProps,
): DynamicGrowthSizeType {
  const { min, max, aspectRatio } = props;
  const { width } = useWindowDimensions();

  return useStyle(() => {
    // How much the window size can grow.
    const windowVariation = MAX_WINDOW_WIDTH - MIN_WINDOW_WIDTH;

    // How much the image size can grow.
    const imageVariation = max - min;

    // How much the window has grown.
    const windowGrowth = width - MIN_WINDOW_WIDTH;

    // How much the image size should grow in relation to the window size.
    const growthMultiplier = windowVariation / imageVariation;

    // How much the image needs to be increase by comparatively.
    const imageIncrease = windowGrowth / growthMultiplier;

    // New image size proportional to the window size increase and aspect ratio.
    const newWidth = min + imageIncrease;
    const newHeight = newWidth * aspectRatio;

    return {
      width: newWidth,
      height: newHeight,
    };
  }, [width, min, max, aspectRatio]);
}

import { useCallback } from 'react';

import { useIsLoggedIn } from '@order/AuthMachine';

import { useOrdersQuery } from '../GraphQL/Orders.generated';
import { ORDERS_QUERY_VARIABLES } from './constants';

export const useOrders = (params?: UseOrdersParams) => {
  const { pause = true } = params ?? {};

  const [ordersResponse, reFetchOrders] = useOrdersQuery({
    variables: ORDERS_QUERY_VARIABLES,
    requestPolicy: 'cache-and-network',
    pause,
  });

  // Only allow fetching orders if the customer is logged in.
  const isLoggedIn = useIsLoggedIn();
  const fetchOrders = useCallback(() => {
    if (isLoggedIn) reFetchOrders();
  }, [isLoggedIn, reFetchOrders]);

  const ordersResponseData = ordersResponse.data?.orders;
  const isSuccess = ordersResponseData?.__typename === 'OrdersResponseSuccess';
  const hasErrorOrders = ordersResponseData?.__typename === 'ValidationError';
  const isLoadingOrders = ordersResponse.fetching || ordersResponse.stale;
  const isFetchingOrders = ordersResponse.fetching;
  const orders = isSuccess ? ordersResponseData.orders : [];

  return {
    orders,
    isLoadingOrders,
    isFetchingOrders,
    hasErrorOrders,
    fetchOrders,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseOrdersParams = {
  pause?: boolean;
};

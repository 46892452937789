import React from 'react';
import type { ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';
import Animated from 'react-native-reanimated';
import { theme } from '@garnish/constants';

import { clamp } from '../../utils';
import { BodyText } from '../Text';
import { useProgressBarStyle } from './LinearProgressBar.hooks';

// ─── Static ──────────────────────────────────────────────────────────────

export const LinearProgressBar = (props: LinearProgressBarProps) => {
  const { label, size, progress, animated, progressStyles, barStyles, testID } =
    props;

  const clampedProgress = clamp(progress, MIN_VALUE, MAX_VALUE);
  const a11yValue = Number(clampedProgress.toFixed(2));

  const { style, onLayout } = useProgressBarStyle(clampedProgress, animated);

  const containerStyle = label ? styles.barWithLabel : styles.bar;
  const sizeStyles = label ? styles[size ?? 'medium'] : {};

  const progressBarStyle = label
    ? styles.progressBarWithLabel
    : styles.progressBar;

  return (
    <View
      style={[containerStyle, sizeStyles, barStyles]}
      onLayout={onLayout}
      testID={testID}
    >
      <Animated.View
        accessibilityValue={{
          min: MIN_VALUE,
          max: MAX_VALUE,
          now: a11yValue,
        }}
        accessibilityRole="progressbar"
        style={[progressBarStyle, progressStyles, style]}
      />
      {label ? <BodyText style={styles.text}>{label}</BodyText> : null}
    </View>
  );
};

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

export const Sizes = {
  xsmall: 'xsmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge',
};

const MIN_VALUE = 0;
const MAX_VALUE = 100;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  bar: {
    flexGrow: 1,
    minHeight: 6,
    backgroundColor: theme.colors.GRAY,
    borderRadius: theme.radius.large,
    overflow: 'hidden',
  },
  text: {
    color: theme.colors.KALE,
    alignSelf: 'center',
    zIndex: 0,
  },
  barWithLabel: {
    flexGrow: 1,
    height: 48,
    justifyContent: 'center',
    borderColor: theme.colors.KALE,
    borderWidth: 1,
    borderRadius: theme.radius.xxxlarge,
    overflow: 'hidden',
  },
  progressBarWithLabel: {
    flexGrow: 1,
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.CUCUMBER,
  },
  progressBar: {
    flexGrow: 1,
    borderRadius: theme.radius.large,
    backgroundColor: theme.colors.KALE,
  },

  // -----------------------------------------
  // ---- SIZES ------------------------------
  // eslint-disable-next-line react-native/no-unused-styles
  [Sizes.xsmall]: { paddingHorizontal: 12, height: 24 },
  // eslint-disable-next-line react-native/no-unused-styles
  [Sizes.small]: { paddingHorizontal: 12, height: 32 },
  // eslint-disable-next-line react-native/no-unused-styles
  [Sizes.medium]: { paddingHorizontal: 24, height: 40 },
  // eslint-disable-next-line react-native/no-unused-styles
  [Sizes.large]: { paddingHorizontal: 24, height: 48 },
  // eslint-disable-next-line react-native/no-unused-styles
  [Sizes.xlarge]: { paddingHorizontal: 32, height: 100 },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type LinearProgressBarProps = Readonly<{
  progress: number;
  animated?: boolean;
  testID?: string;
  label?: string;
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  progressStyles?: ViewStyle;
  barStyles?: ViewStyle;
}>;

import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useLogoutMutation } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';
import { getUrqlError } from '@order/utils';

import type { useCustomerData } from './useCustomerData';

export const useSignOut = (props: UseSignOutProps) => {
  const { customer } = props;

  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  const [signOutResponse, signOutMutation] = useLogoutMutation();
  const signOutResponseData = signOutResponse.data?.logout;

  const isSigningOut = signOutResponse.fetching;
  const isSignedOut = !customer?.id;

  // ─────────────────────── Telemetry ──────────────────────────────────

  const { track } = useTelemetry();

  // ────────────────────────────────────────────────────────────────────

  const signOut = useCallback(async (): Promise<boolean> => {
    if (!customer?.id) return false;

    const response = await signOutMutation({
      input: { customerId: customer.id },
    });
    const logoutData = response?.data?.logout;
    const hasError =
      logoutData?.__typename === 'ValidationError' || response?.error;

    if (!logoutData || hasError) {
      const userError = t('account.sign-out.error');
      const systemError = getUrqlError(response.error);

      track('logout.failure', { userError, systemError });
      addNoticeBanner({ text: userError, palette: 'caution' });

      return false;
    }

    track('logout.success');
    track('account.logged_out_view');

    return true;
  }, [customer.id, signOutMutation, t, track, addNoticeBanner]);

  // ────────────────────────────────────────────────────────────────────

  return { signOutResponseData, isSigningOut, isSignedOut, signOut };
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type UseSignOutProps = Pick<ReturnType<typeof useCustomerData>, 'customer'>;

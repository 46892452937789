import React from 'react';
import { StyleSheet, View } from 'react-native';
import { FadeView, IconLink, LoadingDots, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderLineItemBagIconLink = (
  props: OrderLineItemBagIconLinkProps,
) => {
  const {
    variation = 'static',
    isLoading = false,
    'aria-label': ariaLabel,
    onPress,
  } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles =
    variation === 'floating' ? styles.containerFloating : undefined;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles}>
      <FadeView show={isLoading} style={styles.iconContainer}>
        <LoadingDots color={theme.colors.WHITE} />
      </FadeView>

      <IconLink
        name="IconBagPlus"
        iconSize={ICON_SIZE}
        disabled={isLoading}
        aria-label={ariaLabel}
        onPress={onPress}
      />
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const ICON_SIZE = 32;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerFloating: {
    position: 'absolute',
    right: -(ICON_SIZE / 3),
    bottom: -(ICON_SIZE / 3),
    zIndex: 1,
  },
  iconContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2,
    width: ICON_SIZE,
    height: ICON_SIZE,
    borderRadius: ICON_SIZE,
    backgroundColor: theme.colors.KALE,
    ...theme.elevations['2'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderLineItemBagIconLinkProps = {
  variation?: 'floating' | 'static';
  isLoading?: boolean;
  'aria-label': string;
  onPress: () => void;
};

import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import {
  CloudinaryImage,
  type CloudinaryTransformConfig,
} from '../../../Image';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductCardV2Image = (props: ProductCardV2ImageProps) => {
  const { source, alt, isUnavailable = false, priority } = props;

  return (
    <View style={styles.container}>
      <CloudinaryImage
        baseUrl={source}
        config={IMAGE_TRANSFORM_CONFIG}
        contentFit="contain"
        aria-label={alt}
        priority={priority}
        style={[
          styles.image,
          isUnavailable ? styles.imageUnavailable : undefined,
        ]}
      />
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const IMAGE_TRANSFORM_CONFIG: CloudinaryTransformConfig = [
  { crop: 'crop', height: 0.7, width: 0.4 },
  { width: 343 },
];

const IMAGE_SIZE = 343;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: theme.radius.medium,
  },
  image: {
    width: '100%',
    height: IMAGE_SIZE,
  },
  imageUnavailable: {
    opacity: 0.6,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ProductCardV2ImageProps = {
  source: string;
  alt?: string;
  isUnavailable?: boolean;
  priority?: ComponentProps<typeof CloudinaryImage>['priority'];
};

/* istanbul ignore file */

export const EarnAndRedeemIllustration = [
  require('./earn-and-redeem-illustration.png'),
  require('./earn-and-redeem-illustration@2x.png'),
];
export const PayEmptyStateIllustration = [
  require('./pay-empty-state-illustration.png'),
  require('./pay-empty-state-illustration@2x.png'),
];
export const EarnAndRedeemEmptyStateIllustration = [
  require('./earn-and-redeem-empty-state-illustration.png'),
  require('./earn-and-redeem-empty-state-illustration@2x.png'),
];
export const PayIllustration = [
  require('./pay-illustration.png'),
  require('./pay-illustration@2x.png'),
];

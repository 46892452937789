/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SweetpassPaymentMethodsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SweetpassPaymentMethodsQuery = {
  readonly __typename?: 'Query';
  readonly getSubscriptions: ReadonlyArray<{
    readonly __typename?: 'PaymentSubscription';
    readonly id: string;
    readonly status: Types.PaymentSubscriptionStatus;
  }>;
  readonly paymentSubscriptionProfile: {
    readonly __typename?: 'PaymentSubscriptionProfile';
    readonly id: string;
    readonly creditCards: ReadonlyArray<{
      readonly __typename?: 'SubscriptionCreditCard';
      readonly id: string;
      readonly cardType: Types.BraintreeCardType;
      readonly lastFour: string;
      readonly expirationYear: string | null;
      readonly expirationMonth: string | null;
      readonly isActive: boolean;
    }> | null;
  } | null;
};

export type AddSweetpassPaymentMethodMutationVariables = Types.Exact<{
  nonce: Types.Scalars['String']['input'];
}>;

export type AddSweetpassPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly addSubscriptionPaymentMethod:
    | {
        readonly __typename: 'AddSubscriptionPaymentMethodSuccessResponse';
        readonly paymentMethodToken: string;
        readonly creditCard: {
          readonly __typename?: 'SubscriptionCreditCard';
          readonly id: string;
          readonly cardType: Types.BraintreeCardType;
          readonly lastFour: string;
          readonly expirationMonth: string | null;
          readonly expirationYear: string | null;
          readonly isActive: boolean;
        };
      }
    | {
        readonly __typename: 'InvalidPaymentMethod';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'PaymentMethodAlreadyExistsError';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'UnknownUserError';
        readonly errorMessage: string;
      };
};

export type UpdateSweetpassPaymentMethodMutationVariables = Types.Exact<{
  subscriptionId: Types.Scalars['String']['input'];
  paymentMethodId: Types.Scalars['String']['input'];
}>;

export type UpdateSweetpassPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly updateSubscriptionPaymentMethod: {
    readonly __typename: 'UpdateSubscriptionPaymentMethodSuccessResponse';
    readonly subscription: {
      readonly __typename?: 'PaymentSubscription';
      readonly id: string;
    };
  };
};

export type RemoveSweetpassPaymentMethodMutationVariables = Types.Exact<{
  paymentMethodId: Types.Scalars['String']['input'];
}>;

export type RemoveSweetpassPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly removeSubscriptionPaymentMethod:
    | {
        readonly __typename: 'ActivePaymentMethodError';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'DeleteSubscriptionPaymentMethodSuccess';
        readonly creditCards: ReadonlyArray<{
          readonly __typename: 'SubscriptionCreditCard';
          readonly id: string;
          readonly cardType: Types.BraintreeCardType;
          readonly lastFour: string;
        }>;
      }
    | {
        readonly __typename: 'UnknownUserError';
        readonly errorMessage: string;
      };
};

export type AddSubscriptionPaymentMethodSuccessResponseFieldsFragment = {
  readonly __typename: 'AddSubscriptionPaymentMethodSuccessResponse';
  readonly paymentMethodToken: string;
  readonly creditCard: {
    readonly __typename?: 'SubscriptionCreditCard';
    readonly id: string;
    readonly cardType: Types.BraintreeCardType;
    readonly lastFour: string;
    readonly expirationMonth: string | null;
    readonly expirationYear: string | null;
    readonly isActive: boolean;
  };
};

export type UpdateSubscriptionPaymentMethodSuccessResponseFieldsFragment = {
  readonly __typename: 'UpdateSubscriptionPaymentMethodSuccessResponse';
  readonly subscription: {
    readonly __typename?: 'PaymentSubscription';
    readonly id: string;
  };
};

export type DeleteSubscriptionPaymentMethodSuccessFieldsFragment = {
  readonly __typename: 'DeleteSubscriptionPaymentMethodSuccess';
  readonly creditCards: ReadonlyArray<{
    readonly __typename: 'SubscriptionCreditCard';
    readonly id: string;
    readonly cardType: Types.BraintreeCardType;
    readonly lastFour: string;
  }>;
};

export type PaymentMethodAlreadyExistsErrorFieldsFragment = {
  readonly __typename: 'PaymentMethodAlreadyExistsError';
  readonly errorMessage: string;
};

export type ActivePaymentMethodErrorFieldsFragment = {
  readonly __typename: 'ActivePaymentMethodError';
  readonly errorMessage: string;
};

export type UnknownUserErrorFieldsFragment = {
  readonly __typename: 'UnknownUserError';
  readonly errorMessage: string;
};

export type UserErrorFields_ActivePaymentMethodError_Fragment = {
  readonly __typename: 'ActivePaymentMethodError';
  readonly errorMessage: string;
};

export type UserErrorFields_AsyncCancelFailed_Fragment = {
  readonly __typename: 'AsyncCancelFailed';
  readonly errorMessage: string;
};

export type UserErrorFields_AvailableCustomerSupportPointsError_Fragment = {
  readonly __typename: 'AvailableCustomerSupportPointsError';
  readonly errorMessage: string;
};

export type UserErrorFields_CanCancelAlreadySent_Fragment = {
  readonly __typename: 'CanCancelAlreadySent';
  readonly errorMessage: string;
};

export type UserErrorFields_CanCancelFailed_Fragment = {
  readonly __typename: 'CanCancelFailed';
  readonly errorMessage: string;
};

export type UserErrorFields_CanCancelNotFound_Fragment = {
  readonly __typename: 'CanCancelNotFound';
  readonly errorMessage: string;
};

export type UserErrorFields_CanCancelUnauthorized_Fragment = {
  readonly __typename: 'CanCancelUnauthorized';
  readonly errorMessage: string;
};

export type UserErrorFields_CancelOrderFailed_Fragment = {
  readonly __typename: 'CancelOrderFailed';
  readonly errorMessage: string;
};

export type UserErrorFields_CancellationFailedWithDelivery_Fragment = {
  readonly __typename: 'CancellationFailedWithDelivery';
  readonly errorMessage: string;
};

export type UserErrorFields_CancellationLimitExceeded_Fragment = {
  readonly __typename: 'CancellationLimitExceeded';
  readonly errorMessage: string;
};

export type UserErrorFields_CannotRefundSubscriptionTransactionUserError_Fragment =
  {
    readonly __typename: 'CannotRefundSubscriptionTransactionUserError';
    readonly errorMessage: string;
  };

export type UserErrorFields_CantCancelAlreadyCanceled_Fragment = {
  readonly __typename: 'CantCancelAlreadyCanceled';
  readonly errorMessage: string;
};

export type UserErrorFields_CantCancelLimitExcceeded_Fragment = {
  readonly __typename: 'CantCancelLimitExcceeded';
  readonly errorMessage: string;
};

export type UserErrorFields_CantCancelOldOrders_Fragment = {
  readonly __typename: 'CantCancelOldOrders';
  readonly errorMessage: string;
};

export type UserErrorFields_CustomerNotFound_Fragment = {
  readonly __typename: 'CustomerNotFound';
  readonly errorMessage: string;
};

export type UserErrorFields_CustomerPointsForCustomerSupportFailureError_Fragment =
  {
    readonly __typename: 'CustomerPointsForCustomerSupportFailureError';
    readonly errorMessage: string;
  };

export type UserErrorFields_CustomerSupportCreditsFailureError_Fragment = {
  readonly __typename: 'CustomerSupportCreditsFailureError';
  readonly errorMessage: string;
};

export type UserErrorFields_DeclinedPaymentMethod_Fragment = {
  readonly __typename: 'DeclinedPaymentMethod';
  readonly errorMessage: string;
};

export type UserErrorFields_DeliveryAlreadyInFlight_Fragment = {
  readonly __typename: 'DeliveryAlreadyInFlight';
  readonly errorMessage: string;
};

export type UserErrorFields_DeliverySpecifyAddress_Fragment = {
  readonly __typename: 'DeliverySpecifyAddress';
  readonly errorMessage: string;
};

export type UserErrorFields_FreeTrialNotEligible_Fragment = {
  readonly __typename: 'FreeTrialNotEligible';
  readonly errorMessage: string;
};

export type UserErrorFields_GiftCardAssociatedWithAnotherAccountError_Fragment =
  {
    readonly __typename: 'GiftCardAssociatedWithAnotherAccountError';
    readonly errorMessage: string;
  };

export type UserErrorFields_GiftCardNotFoundError_Fragment = {
  readonly __typename: 'GiftCardNotFoundError';
  readonly errorMessage: string;
};

export type UserErrorFields_GiftCardOrderNotFoundError_Fragment = {
  readonly __typename: 'GiftCardOrderNotFoundError';
  readonly errorMessage: string;
};

export type UserErrorFields_IncorrectPaymentMethod_Fragment = {
  readonly __typename: 'IncorrectPaymentMethod';
  readonly errorMessage: string;
};

export type UserErrorFields_InvalidBillingAccount_Fragment = {
  readonly __typename: 'InvalidBillingAccount';
  readonly errorMessage: string;
};

export type UserErrorFields_InvalidCustomerPhone_Fragment = {
  readonly __typename: 'InvalidCustomerPhone';
  readonly errorMessage: string;
};

export type UserErrorFields_InvalidGiftCardError_Fragment = {
  readonly __typename: 'InvalidGiftCardError';
  readonly errorMessage: string;
};

export type UserErrorFields_InvalidPaymentMethod_Fragment = {
  readonly __typename: 'InvalidPaymentMethod';
  readonly errorMessage: string;
};

export type UserErrorFields_InvalidTip_Fragment = {
  readonly __typename: 'InvalidTip';
  readonly errorMessage: string;
};

export type UserErrorFields_IssueCustomerSupportPointsFailureError_Fragment = {
  readonly __typename: 'IssueCustomerSupportPointsFailureError';
  readonly errorMessage: string;
};

export type UserErrorFields_LineItemModificationsInvalidException_Fragment = {
  readonly __typename: 'LineItemModificationsInvalidException';
  readonly errorMessage: string;
};

export type UserErrorFields_LoyaltyOptInFailed_Fragment = {
  readonly __typename: 'LoyaltyOptInFailed';
  readonly errorMessage: string;
};

export type UserErrorFields_MinimumDeliverySubtotalNotMet_Fragment = {
  readonly __typename: 'MinimumDeliverySubtotalNotMet';
  readonly errorMessage: string;
};

export type UserErrorFields_NoBalanceGiftCardError_Fragment = {
  readonly __typename: 'NoBalanceGiftCardError';
  readonly errorMessage: string;
};

export type UserErrorFields_OrderAlreadyCanceled_Fragment = {
  readonly __typename: 'OrderAlreadyCanceled';
  readonly errorMessage: string;
};

export type UserErrorFields_OrderAlreadySentToKitchen_Fragment = {
  readonly __typename: 'OrderAlreadySentToKitchen';
  readonly errorMessage: string;
};

export type UserErrorFields_OrderNotFound_Fragment = {
  readonly __typename: 'OrderNotFound';
  readonly errorMessage: string;
};

export type UserErrorFields_PaymentMethodAlreadyExistsError_Fragment = {
  readonly __typename: 'PaymentMethodAlreadyExistsError';
  readonly errorMessage: string;
};

export type UserErrorFields_ProductOutOfStock_Fragment = {
  readonly __typename: 'ProductOutOfStock';
  readonly errorMessage: string;
};

export type UserErrorFields_ProductUnavailable_Fragment = {
  readonly __typename: 'ProductUnavailable';
  readonly errorMessage: string;
};

export type UserErrorFields_PromoCodeAlreadyApplied_Fragment = {
  readonly __typename: 'PromoCodeAlreadyApplied';
  readonly errorMessage: string;
};

export type UserErrorFields_RestaurantIsNotAcceptingOrders_Fragment = {
  readonly __typename: 'RestaurantIsNotAcceptingOrders';
  readonly errorMessage: string;
};

export type UserErrorFields_RewardNotValidated_Fragment = {
  readonly __typename: 'RewardNotValidated';
  readonly errorMessage: string;
};

export type UserErrorFields_StoreOffline_Fragment = {
  readonly __typename: 'StoreOffline';
  readonly errorMessage: string;
};

export type UserErrorFields_SubmitGiftCardOrderError_Fragment = {
  readonly __typename: 'SubmitGiftCardOrderError';
  readonly errorMessage: string;
};

export type UserErrorFields_SubscriptionCannotBeReactivatedError_Fragment = {
  readonly __typename: 'SubscriptionCannotBeReactivatedError';
  readonly errorMessage: string;
};

export type UserErrorFields_TimeslotUnavailable_Fragment = {
  readonly __typename: 'TimeslotUnavailable';
  readonly errorMessage: string;
};

export type UserErrorFields_UnableToGetGiftCardBalanceError_Fragment = {
  readonly __typename: 'UnableToGetGiftCardBalanceError';
  readonly errorMessage: string;
};

export type UserErrorFields_UnableToRedeemGiftCardError_Fragment = {
  readonly __typename: 'UnableToRedeemGiftCardError';
  readonly errorMessage: string;
};

export type UserErrorFields_UnknownUserError_Fragment = {
  readonly __typename: 'UnknownUserError';
  readonly errorMessage: string;
};

export type UserErrorFieldsFragment =
  | UserErrorFields_ActivePaymentMethodError_Fragment
  | UserErrorFields_AsyncCancelFailed_Fragment
  | UserErrorFields_AvailableCustomerSupportPointsError_Fragment
  | UserErrorFields_CanCancelAlreadySent_Fragment
  | UserErrorFields_CanCancelFailed_Fragment
  | UserErrorFields_CanCancelNotFound_Fragment
  | UserErrorFields_CanCancelUnauthorized_Fragment
  | UserErrorFields_CancelOrderFailed_Fragment
  | UserErrorFields_CancellationFailedWithDelivery_Fragment
  | UserErrorFields_CancellationLimitExceeded_Fragment
  | UserErrorFields_CannotRefundSubscriptionTransactionUserError_Fragment
  | UserErrorFields_CantCancelAlreadyCanceled_Fragment
  | UserErrorFields_CantCancelLimitExcceeded_Fragment
  | UserErrorFields_CantCancelOldOrders_Fragment
  | UserErrorFields_CustomerNotFound_Fragment
  | UserErrorFields_CustomerPointsForCustomerSupportFailureError_Fragment
  | UserErrorFields_CustomerSupportCreditsFailureError_Fragment
  | UserErrorFields_DeclinedPaymentMethod_Fragment
  | UserErrorFields_DeliveryAlreadyInFlight_Fragment
  | UserErrorFields_DeliverySpecifyAddress_Fragment
  | UserErrorFields_FreeTrialNotEligible_Fragment
  | UserErrorFields_GiftCardAssociatedWithAnotherAccountError_Fragment
  | UserErrorFields_GiftCardNotFoundError_Fragment
  | UserErrorFields_GiftCardOrderNotFoundError_Fragment
  | UserErrorFields_IncorrectPaymentMethod_Fragment
  | UserErrorFields_InvalidBillingAccount_Fragment
  | UserErrorFields_InvalidCustomerPhone_Fragment
  | UserErrorFields_InvalidGiftCardError_Fragment
  | UserErrorFields_InvalidPaymentMethod_Fragment
  | UserErrorFields_InvalidTip_Fragment
  | UserErrorFields_IssueCustomerSupportPointsFailureError_Fragment
  | UserErrorFields_LineItemModificationsInvalidException_Fragment
  | UserErrorFields_LoyaltyOptInFailed_Fragment
  | UserErrorFields_MinimumDeliverySubtotalNotMet_Fragment
  | UserErrorFields_NoBalanceGiftCardError_Fragment
  | UserErrorFields_OrderAlreadyCanceled_Fragment
  | UserErrorFields_OrderAlreadySentToKitchen_Fragment
  | UserErrorFields_OrderNotFound_Fragment
  | UserErrorFields_PaymentMethodAlreadyExistsError_Fragment
  | UserErrorFields_ProductOutOfStock_Fragment
  | UserErrorFields_ProductUnavailable_Fragment
  | UserErrorFields_PromoCodeAlreadyApplied_Fragment
  | UserErrorFields_RestaurantIsNotAcceptingOrders_Fragment
  | UserErrorFields_RewardNotValidated_Fragment
  | UserErrorFields_StoreOffline_Fragment
  | UserErrorFields_SubmitGiftCardOrderError_Fragment
  | UserErrorFields_SubscriptionCannotBeReactivatedError_Fragment
  | UserErrorFields_TimeslotUnavailable_Fragment
  | UserErrorFields_UnableToGetGiftCardBalanceError_Fragment
  | UserErrorFields_UnableToRedeemGiftCardError_Fragment
  | UserErrorFields_UnknownUserError_Fragment;

export type InvalidPaymentMethodFieldsFragment = {
  readonly __typename: 'InvalidPaymentMethod';
  readonly errorMessage: string;
};

export const AddSubscriptionPaymentMethodSuccessResponseFieldsFragmentDoc = gql`
  fragment AddSubscriptionPaymentMethodSuccessResponseFields on AddSubscriptionPaymentMethodSuccessResponse {
    paymentMethodToken
    creditCard {
      id
      cardType
      lastFour
      expirationMonth
      expirationYear
      isActive
    }
    __typename
  }
`;
export const UpdateSubscriptionPaymentMethodSuccessResponseFieldsFragmentDoc = gql`
  fragment UpdateSubscriptionPaymentMethodSuccessResponseFields on UpdateSubscriptionPaymentMethodSuccessResponse {
    subscription {
      id
    }
    __typename
  }
`;
export const DeleteSubscriptionPaymentMethodSuccessFieldsFragmentDoc = gql`
  fragment DeleteSubscriptionPaymentMethodSuccessFields on DeleteSubscriptionPaymentMethodSuccess {
    creditCards {
      id
      cardType
      lastFour
      __typename
    }
    __typename
  }
`;
export const PaymentMethodAlreadyExistsErrorFieldsFragmentDoc = gql`
  fragment PaymentMethodAlreadyExistsErrorFields on PaymentMethodAlreadyExistsError {
    errorMessage
    __typename
  }
`;
export const ActivePaymentMethodErrorFieldsFragmentDoc = gql`
  fragment ActivePaymentMethodErrorFields on ActivePaymentMethodError {
    errorMessage
    __typename
  }
`;
export const UnknownUserErrorFieldsFragmentDoc = gql`
  fragment UnknownUserErrorFields on UnknownUserError {
    errorMessage
    __typename
  }
`;
export const UserErrorFieldsFragmentDoc = gql`
  fragment UserErrorFields on UserError {
    errorMessage
    __typename
  }
`;
export const InvalidPaymentMethodFieldsFragmentDoc = gql`
  fragment InvalidPaymentMethodFields on InvalidPaymentMethod {
    errorMessage
    __typename
  }
`;
export const SweetpassPaymentMethodsDocument = gql`
  query SweetpassPaymentMethods {
    getSubscriptions(status: [ACTIVE, PAST_DUE]) {
      id
      status
    }
    paymentSubscriptionProfile {
      id
      creditCards {
        id
        cardType
        lastFour
        expirationYear
        expirationMonth
        isActive
      }
    }
  }
`;

export function useSweetpassPaymentMethodsQuery(
  options?: Omit<
    Urql.UseQueryArgs<SweetpassPaymentMethodsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    SweetpassPaymentMethodsQuery,
    SweetpassPaymentMethodsQueryVariables
  >({ query: SweetpassPaymentMethodsDocument, ...options });
}
export const AddSweetpassPaymentMethodDocument = gql`
  mutation AddSweetpassPaymentMethod($nonce: String!) {
    addSubscriptionPaymentMethod(paymentNonce: $nonce) {
      ...AddSubscriptionPaymentMethodSuccessResponseFields
      ...PaymentMethodAlreadyExistsErrorFields
      ...UnknownUserErrorFields
      ...InvalidPaymentMethodFields
    }
  }
  ${AddSubscriptionPaymentMethodSuccessResponseFieldsFragmentDoc}
  ${PaymentMethodAlreadyExistsErrorFieldsFragmentDoc}
  ${UnknownUserErrorFieldsFragmentDoc}
  ${InvalidPaymentMethodFieldsFragmentDoc}
`;

export function useAddSweetpassPaymentMethodMutation() {
  return Urql.useMutation<
    AddSweetpassPaymentMethodMutation,
    AddSweetpassPaymentMethodMutationVariables
  >(AddSweetpassPaymentMethodDocument);
}
export const UpdateSweetpassPaymentMethodDocument = gql`
  mutation UpdateSweetpassPaymentMethod(
    $subscriptionId: String!
    $paymentMethodId: String!
  ) {
    updateSubscriptionPaymentMethod(
      subscriptionId: $subscriptionId
      paymentMethodId: $paymentMethodId
    ) {
      ...UpdateSubscriptionPaymentMethodSuccessResponseFields
    }
  }
  ${UpdateSubscriptionPaymentMethodSuccessResponseFieldsFragmentDoc}
`;

export function useUpdateSweetpassPaymentMethodMutation() {
  return Urql.useMutation<
    UpdateSweetpassPaymentMethodMutation,
    UpdateSweetpassPaymentMethodMutationVariables
  >(UpdateSweetpassPaymentMethodDocument);
}
export const RemoveSweetpassPaymentMethodDocument = gql`
  mutation RemoveSweetpassPaymentMethod($paymentMethodId: String!) {
    removeSubscriptionPaymentMethod(paymentMethodId: $paymentMethodId) {
      ...DeleteSubscriptionPaymentMethodSuccessFields
      ...ActivePaymentMethodErrorFields
      ...UnknownUserErrorFields
      ...UserErrorFields
    }
  }
  ${DeleteSubscriptionPaymentMethodSuccessFieldsFragmentDoc}
  ${ActivePaymentMethodErrorFieldsFragmentDoc}
  ${UnknownUserErrorFieldsFragmentDoc}
  ${UserErrorFieldsFragmentDoc}
`;

export function useRemoveSweetpassPaymentMethodMutation() {
  return Urql.useMutation<
    RemoveSweetpassPaymentMethodMutation,
    RemoveSweetpassPaymentMethodMutationVariables
  >(RemoveSweetpassPaymentMethodDocument);
}

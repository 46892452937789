import React from 'react';
import { getIosModalPresentationConfig, ModalDialogue } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { LoadingAnimation } from '../LoadingAnimation';
import {
  AccountDeletionFirstStep,
  AccountDeletionSecondStep,
} from './components';
import {
  useAccountDeletion,
  useAccountDeletionContentfulData,
  useCancelSweetpassSubscription,
  useSweetpassAccountDeletionData,
} from './hooks';

export const RequestAccountDeletionModal = (
  props: RequestAccountDeletionModalProps,
) => {
  const { visible, onClose, onSuccess } = props;

  const { t } = useLocalizationContext();

  const {
    isFetchingSweetpassData,
    canCancelSweetpass,
    nextBillingDate,
    subscriptionId,
    isSubscriptionFreeTrial,
    isSubscriptionPastDue,
  } = useSweetpassAccountDeletionData();

  const { isLoadingContentfulData, step1, step2 } =
    useAccountDeletionContentfulData({
      nextBillingDate,
    });

  const {
    isCancellingSubscription,
    hasCancelledSubscription,
    requestCancelSubscription,
  } = useCancelSweetpassSubscription({
    subscriptionId,
    isSubscriptionFreeTrial,
    isSubscriptionPastDue,
    onClose,
  });

  const { isDeletingAccount, requestAccountDeletion } = useAccountDeletion({
    onClose,
    onSuccess,
  });

  const shouldShowLoading = isFetchingSweetpassData || isLoadingContentfulData;
  const shouldShowCancel = canCancelSweetpass && !hasCancelledSubscription;
  const shouldShowDelete = !canCancelSweetpass || hasCancelledSubscription;

  return (
    <ModalDialogue
      size="medium"
      accessibilityLabel={t('account.delete.headline')}
      visible={visible}
      onRequestClose={onClose}
      fitHeight
      {...getIosModalPresentationConfig()}
    >
      {shouldShowLoading ? <LoadingAnimation /> : null}

      {!shouldShowLoading && shouldShowCancel ? (
        <AccountDeletionFirstStep
          body={step1}
          isCancellingSubscription={isCancellingSubscription}
          requestCancelSubscription={requestCancelSubscription}
        />
      ) : null}

      {!shouldShowLoading && shouldShowDelete ? (
        <AccountDeletionSecondStep
          body={step2}
          isDeletingAccount={isDeletingAccount}
          hasCancelledSubscription={hasCancelledSubscription}
          requestAccountDeletion={requestAccountDeletion}
        />
      ) : null}
    </ModalDialogue>
  );
};

type RequestAccountDeletionModalProps = Readonly<{
  visible: boolean;
  onSuccess: () => void;
  onClose: () => void;
}>;

import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { Container } from '@sg/garnish';

export const HowItWorksContainer = (props: HowItWorksContainerProps) => {
  const { children, style } = props;

  return (
    <Container
      style={[styles.container, style]}
      wrapperStyle={styles.container}
    >
      {children}
    </Container>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type HowItWorksContainerProps = ComponentProps<typeof Container>;

import { Platform, StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

export const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  labelWithSpacing: {
    paddingBottom: theme.spacing['2'],
  },
  triggerWrapper: {
    position: 'relative',
  },
  trigger: {
    minWidth: 100,
    paddingVertical: theme.spacing['1'],
    borderRadius: theme.radius.medium,
  },
  triggerHover: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    borderRadius: theme.radius.medium,
    marginHorizontal: -theme.spacing['2'],
  },
  triggerHoverBox: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    borderRadius: theme.radius.medium,
  },
  triggerBox: {
    borderRadius: theme.radius.small,
    borderWidth: 1,
    borderColor: theme.colors.GRAY,
    paddingVertical: 0,
    paddingLeft: theme.spacing['2'],
    paddingRight: 6,
    minHeight: 32,
  },
  selectedOptionPlaceholderText: {
    color: theme.colors.CHARCOAL,
  },
  selectedOptionText: {
    color: theme.colors.BLACK,
  },
  select: {
    top: 0,
    left: 0,
    zIndex: theme.zIndex.dropdown,
  },
  scrollView: {
    overflow: 'scroll',
    maxHeight: 200,
  },
  options: {
    overflow: 'hidden',
    marginTop:
      Platform.OS === 'android' ? -theme.spacing['4'] : theme.spacing['1'],
    marginBottom: theme.spacing['2'],
    backgroundColor: theme.colors.KHAKI_3,
    borderColor: theme.colors.GREEN_1,
    borderRadius: theme.radius.medium,
    borderWidth: 1,
  },
  option: {
    paddingHorizontal: 12,
    paddingVertical: 10,
  },
  optionDisabled: {
    backgroundColor: theme.colors.OPACITY.TRANSPARENT,
  },
  hovered: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
  pressed: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.LIGHTER,
  },
  selected: {
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTER,
  },
  disabled: {
    color: theme.colors.OPACITY.DARK_KALE.LIGHT,
  },
});

import { useCallback, useEffect, useState } from 'react';
import * as Location from 'expo-location';

import { useCallbackIfMounted } from '../useCallbackIfMounted';
import { useFunctionPolling } from '../usePolling';

// Returns the user location - https://docs.expo.dev/versions/latest/sdk/location/
// Receives an optional {pollingInterval} to update the geolocation every X ms.

const config = { accuracy: Location.Accuracy.Balanced };

/**
 * Returns the user geolocation and the associated helpers.
 *
 * Receives an optional `pollingInterval` to update the geolocation every X ms
 * and `pause` to prevent getting user's location on mount.
 *
 * @see https://docs.expo.dev/versions/latest/sdk/location/
 */
export const useUserLocation = (props?: UseUserLocationProps) => {
  const { pollingInterval = 0, pause = false } = props ?? {};

  const [permissionStatus, setPermissionStatus] =
    useState<PermissionStatus>('undetermined');
  const [loading, setLoading] = useState(false);
  const [geolocation, setGeolocation] =
    useState<Location.LocationObject['coords']>();

  // ─────────────────────────────────────────────────────────────────

  const setPermissionStatusIfMounted = useCallbackIfMounted(
    (status: Location.PermissionStatus) => {
      setPermissionStatus(status);
    },
  );

  const setLoadingIfMounted = useCallbackIfMounted((isLoading: boolean) => {
    setLoading(isLoading);
  });

  const setGeolocationIfMounted = useCallbackIfMounted(
    (currentLocation: Location.LocationObject['coords']) => {
      setGeolocation(currentLocation);
    },
  );

  // ─── HELPERS ────────────────────────────────────────────────────────────────────

  const getUserGeolocation = useCallback(async () => {
    setLoadingIfMounted(true);

    const { status } = await Location.requestForegroundPermissionsAsync();

    const currentLocation =
      status === 'granted'
        ? await Location.getCurrentPositionAsync(config)
        : undefined;

    setPermissionStatusIfMounted(status);
    setGeolocationIfMounted(currentLocation?.coords);
    setLoadingIfMounted(false);

    return currentLocation;
  }, [
    setLoadingIfMounted,
    setGeolocationIfMounted,
    setPermissionStatusIfMounted,
  ]);

  const getUserGeolocationIfMounted = useCallbackIfMounted(getUserGeolocation);

  // ─────────────────────────────────────────────────────────────────

  useEffect(() => {
    if (pause) return;

    getUserGeolocationIfMounted();
  }, [getUserGeolocationIfMounted, pause]);

  useFunctionPolling(
    pollingInterval ?? 1000 * 60,
    async () => {
      const currentLocation = await Location.getCurrentPositionAsync(config);

      setGeolocationIfMounted(currentLocation);
    },
    !pollingInterval || permissionStatus !== 'granted',
  );

  // ─────────────────────────────────────────────────────────────────

  return {
    permissionStatus,
    loading,
    getUserGeolocation,
    geolocation,
  };
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type UseUserLocationProps = Readonly<{
  pollingInterval?: number;
  pause?: boolean;
}>;

type PermissionStatus = 'undetermined' | 'granted' | 'denied';

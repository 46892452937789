import React from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { View } from 'react-native';
import { BodyText, Icon, ListAction, theme } from '@sg/garnish';

import { getCreditCardIcon, PAYMENT_ADD } from '@order/constants';
import { useLocalizationContext } from '@order/Localization';

/**
 * For when the only option for selecting a credit card is "Add card".
 */
export const SingleOptionCreditCardPicker = (
  props: SingleOptionCreditCardPickerProps,
) => {
  const { notice, onPress } = props;

  const { t } = useLocalizationContext();
  const label = t('checkout.add-card');

  return (
    <Pressable accessibilityLabel={label} onPress={onPress}>
      <ListAction hasPressableStyles iconName={getCreditCardIcon(PAYMENT_ADD)}>
        <View style={styles.listActionContent}>
          <BodyText size={4} bold>
            {label}
          </BodyText>

          <Icon color={theme.colors.CHARCOAL} name="IconCaretRight" />
        </View>

        <ListAction.Notice palette="caution">{notice}</ListAction.Notice>
      </ListAction>
    </Pressable>
  );
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  listActionContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

// ─── Types ─────────────────────────────────────────────────────────────

type SingleOptionCreditCardPickerProps = Readonly<{
  notice?: string;
  onPress: () => void;
}>;

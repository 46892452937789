import { defineMessages } from 'react-intl';

export const orderLineItemMessages = defineMessages({
  addToBagButtonA11yLabel: {
    defaultMessage: 'Add {line_item_name} to bag',
    description: 'Order Line Item | "Add to bag" button a11y label',
  },
  openProductDetailsButtonA11yLabel: {
    defaultMessage: 'Open {line_item_name} details',
    description: 'Order Line Item | "Open product details" button a11y label',
  },
  favoriteLineItemButtonA11yLabel: {
    defaultMessage: 'Add {line_item_name} to favorites',
    description: 'Order Line Item | "Favorite" button a11y label',
  },
  unFavoriteLineItemButtonA11yLabel: {
    defaultMessage: 'Remove {line_item_name} from favorites',
    description: 'Order Line Item | Remove Favorite" button a11y label',
  },
});

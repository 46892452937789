import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { type Text, theme, TitleText } from '@sg/garnish';

export const ScanAtCheckoutFooterText = (
  props: ScanAtCheckoutFooterTextProps,
) => {
  const { children } = props;

  return (
    <TitleText sizeMatch={['24']} style={styles.text}>
      {children}
    </TitleText>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanAtCheckoutFooterTextProps = ComponentProps<typeof Text>;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    color: theme.colors.NEUTRAL_7,
    fontFamily: 'Grenette Pro',
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { AspectRatioImage, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardRedemptionCardImage = (
  props: GiftCardRedemptionCardImageProps,
) => {
  const { imgSrc, imgAccessibilityLabel } = props;

  return (
    <View>
      <AspectRatioImage
        source={imgSrc}
        borderRadius={theme.radius.xlarge}
        aspectRatioY={4}
        aspectRatioX={3}
        aria-label={imgAccessibilityLabel}
        containerStyle={styles.imgContainer}
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  imgContainer: {
    ...theme.elevations['2'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardRedemptionCardImageProps = {
  imgSrc: string;
  imgAccessibilityLabel?: string;
};

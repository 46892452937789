import React from 'react';
import type { ViewProps } from 'react-native';
import { Pressable, StyleSheet, View } from 'react-native';
import type { IconName } from '@sg/garnish';
import {
  Icon,
  ListAction,
  theme,
  usePressableState,
  useResponsive,
} from '@sg/garnish';

export type PaymentMethodListItemProps = Readonly<{
  iconName: IconName;
  title?: string | null;
  subtitle?: string | null;
  tag?: string | null;
  notice?: string | null;
  onPress: () => void;
}> &
  ViewProps;

export const PaymentMethodListItem = ({
  testID,
  iconName,
  title,
  subtitle,
  tag,
  notice,
  onPress,
  accessibilityLabel,
  accessibilityHint,
  style,
}: PaymentMethodListItemProps) => {
  const ref = React.useRef(null);
  const { isInteracting } = usePressableState(ref);
  const { currentBreakpoint } = useResponsive();

  return (
    <Pressable
      testID={testID}
      accessibilityRole="link"
      accessibilityLabel={accessibilityLabel}
      accessibilityHint={accessibilityHint}
      onPress={onPress}
      ref={ref}
    >
      <ListAction.Wrapper style={style}>
        <View style={styles.container}>
          <ListAction.Icon iconName={iconName} />
          <View style={styles.content}>
            <ListAction.Text
              testID={testID?.concat('.title')}
              underline={isInteracting}
            >
              {[title, subtitle]
                .filter(Boolean)
                .join(currentBreakpoint.isXS ? '\n' : ' – ')}
            </ListAction.Text>
            <ListAction.Notice
              testID={testID?.concat('.notice')}
              palette="caution"
              size="medium"
            >
              {notice}
            </ListAction.Notice>
          </View>
          <ListAction.Tag testID={testID?.concat('.tag')}>{tag}</ListAction.Tag>
          <Icon name="IconCaretRight" width={18} height={18} />
        </View>
      </ListAction.Wrapper>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    paddingVertical: theme.spacing['2'],
  },
  content: {
    flex: 1,
  },
});

import React from 'react';
import { useIntl } from 'react-intl';
import { IconLink } from '@sg/garnish';

import { orderAgainMessages as messages } from '../../OrderAgain.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainCloseButton = (props: ReorderModalCloseButtonProps) => {
  const { onPress } = props;

  const { formatMessage } = useIntl();

  return (
    <IconLink
      name="IconClose"
      palette="muted"
      accessibilityLabel={formatMessage(messages.closeButtonA11yLabel)}
      width={40}
      height={40}
      onPress={onPress}
    />
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ReorderModalCloseButtonProps = {
  onPress: () => void;
};

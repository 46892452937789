import React from 'react';
import type { TextStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../Text';
import {
  type LedgerItem,
  type LedgerItemLabelProps,
  type UseBodyTextProps,
} from './Ledger.types';

// ────────────────────────────────────────────────────────────────────────────────

export const LedgerLabelText = (props: LedgerItemLabelProps) => {
  const {
    helperText,
    palette,
    bold,
    label,
    alignment = 'left',
    style,
    ...rest
  } = props;

  // ──────────────────────────────────────────────────────────────────────────────

  const textProps = useBodyTextProps({ palette, bold });
  const shouldUseUnderline = typeof helperText === 'string';

  // ──────────────────────────────────────────────────────────────────────────────

  return (
    <View style={[styles.ledgerLabelContainer, style]}>
      <BodyText
        {...textProps}
        {...rest}
        numberOfLines={1}
        underline={shouldUseUnderline}
        style={[
          textProps.style,
          alignment === 'right' && styles.ledgerItemRightAlignment,
        ]}
      >
        {label}
      </BodyText>
    </View>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  ledgerLabelContainer: {
    flexShrink: 1,
  },
  ledgerItemRightAlignment: {
    textAlign: 'right',
  },
});

const PALETTES_MAP = {
  success: theme.colors.SUCCESS,
  standard: theme.colors.TEXT_COLOR,
};

// ─── Helpers ────────────────────────────────────────────────────────────────

const useBodyTextProps = (
  props: UseBodyTextProps,
): Partial<React.ComponentProps<typeof BodyText>> => {
  const { palette = 'standard', bold } = props;
  const style = getTextStylesFromPresets(palette);
  const size = 4;

  return { size, style, bold };
};

const getTextStylesFromPresets = (
  palette: LedgerItem['palette'] = 'standard',
): TextStyle => {
  return { color: PALETTES_MAP[palette] };
};

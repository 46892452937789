import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BackHandler, StyleSheet, View } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import {
  Button,
  Modal,
  ModalDialogue,
  NavigationHeaderButton,
  theme,
} from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';

import type { GiftCardsTabStackParamList } from '../../../../navigation/AppNavigation.props';
import type { GiftCardCheckoutFormReturn } from '../../form';
import { giftCardCheckoutLeaveScreenMessages as messages } from './GiftCardCheckoutLeaveScreen.messages';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A smart component that handles gift card checkout screen "leaving" process
 * by preventing navigation is user has unsaved changes.
 */
export const GiftCardCheckoutLeaveScreen = (
  props: GiftCardCheckoutLeaveScreenProps,
) => {
  const { form } = props;

  const navigation =
    useNavigation<NativeStackNavigationProp<GiftCardsTabStackParamList>>();
  const { formatMessage } = useIntl();
  const { track } = useTelemetry();

  // ─── State ───────────────────────────────────────────────────────────

  const [shouldPreventScreenRemoval, setShouldPreventScreenRemoval] =
    useState(false);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToGiftCardsScreen = useCallback(() => {
    setShouldPreventScreenRemoval(false);
    navigation.navigate('GiftCards');
  }, [navigation]);

  const beforeScreenRemoval = useCallback(() => {
    if (form.formState.isDirty) {
      setShouldPreventScreenRemoval(true);

      return true;
    }

    navigateToGiftCardsScreen();

    return false;
  }, [form.formState.isDirty, navigateToGiftCardsScreen]);

  const preventScreenRemoval = useCallback(() => {
    setShouldPreventScreenRemoval(false);
  }, []);

  const onModalShown = useCallback(() => {
    track('gift.abandon_modal');
  }, [track]);

  const onLeaveCtaPress = useCallback(() => {
    track('gift.abandon_modal_cta_tapped');
    navigateToGiftCardsScreen();
  }, [navigateToGiftCardsScreen, track]);

  // ─── Inline Components ───────────────────────────────────────────────

  /**
   * Custom back button component.
   */
  const CustomBackButton = useMemo(
    () => (
      <NavigationHeaderButton
        iconName="IconArrowLeft"
        accessibilityLabel={formatMessage(messages.navigateToGiftCardsScreen)}
        onPress={beforeScreenRemoval}
      />
    ),
    [beforeScreenRemoval, formatMessage],
  );

  // ─── Effects ─────────────────────────────────────────────────────────

  // Handle Android back button press
  useFocusEffect(
    useCallback(() => {
      const subscription = BackHandler.addEventListener(
        'hardwareBackPress',
        beforeScreenRemoval,
      );

      return subscription.remove;
    }, [beforeScreenRemoval]),
  );

  // Set custom back button
  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft() {
        return CustomBackButton;
      },

      // NOTE: We disable gesture navigation whenever there are
      //       any unsaved changes.
      gestureEnabled: !form.formState.isDirty,
    });
  }, [
    CustomBackButton,
    beforeScreenRemoval,
    form.formState.isDirty,
    navigation,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ModalDialogue
      visible={shouldPreventScreenRemoval}
      size="small"
      headline={formatMessage(messages.modalHeadline)}
      body={formatMessage(messages.modalBody)}
      onShown={onModalShown}
      accessibilityLabel={formatMessage(messages.modalA11yLabel)}
      onRequestClose={preventScreenRemoval}
    >
      <Modal.Footer withoutTopBorder style={styles.ctasContainer}>
        <View style={styles.ctaContainer}>
          <Button
            size="large"
            palette="secondary"
            onPress={preventScreenRemoval}
          >
            <FormattedMessage {...messages.modalCancelCtaLabel} />
          </Button>
        </View>

        <View style={styles.ctaContainer}>
          <Button size="large" palette="primary" onPress={onLeaveCtaPress}>
            <FormattedMessage {...messages.modalLeaveCtaLabel} />
          </Button>
        </View>
      </Modal.Footer>
    </ModalDialogue>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  ctasContainer: {
    flexDirection: 'row',
    gap: theme.spacing['4'],
  },
  ctaContainer: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutLeaveScreenProps = {
  form: GiftCardCheckoutFormReturn;
};

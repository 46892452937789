import { logger as LOG, logger } from '@garnish/logger';
import { uniqBy } from 'lodash';

import { type OrderWithIdAndWantedTime } from './orders-history-machine.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A tiny utility that removes potential duplicates from the order list,
 * which can occur if the user loads more orders with a delay.
 */
export function withoutDuplicateOrders<Order extends OrderWithIdAndWantedTime>(
  orders: Order[],
): Order[] {
  return uniqBy(orders, 'id');
}

/**
 * A utility for sorting order lists based on the IDs of the orders.
 */
export function sortOrdersById<Order extends OrderWithIdAndWantedTime>(
  orders: Order[],
): Order[] {
  return [...orders].sort(
    (currentOrder, nextOrder) => Number(nextOrder.id) - Number(currentOrder.id),
  );
}

// ─── Logger ──────────────────────────────────────────────────────────────────

LOG.enable('ORDERS HISTORY STATE MACHINE');

export const ordersHistoryMachineLogger = logger.extend(
  'ORDERS HISTORY STATE MACHINE',
);

/* eslint-disable functional/immutable-data */

import { hashCode } from '../hashCode';

/**
 * Generates a hash code from a given object.
 *
 * - Optionally can receive options like `shouldSortKeys`.
 * - Can be utilized to generate unique IDs based on object entries.
 */
export function hashObject(
  obj: Record<string, unknown>,
  options?: HashObjectOptions,
): number {
  const { shouldSortKeys = false } = options ?? {};

  const entries = Object.entries(obj);
  const formattedEntries = shouldSortKeys
    ? entries.sort(sortEntriesByKey)
    : entries;
  const stringifiedEntries = JSON.stringify(formattedEntries);

  return hashCode(stringifiedEntries);
}

/**
 * Sorts object entries by their respective key names.
 */
function sortEntriesByKey(
  [entryKey]: ObjectEntry,
  [nextEntryKey]: ObjectEntry,
) {
  return entryKey.localeCompare(nextEntryKey);
}

// ─── Types ───────────────────────────────────────────────────────────────────

type HashObjectOptions = Readonly<{
  shouldSortKeys?: boolean;
}>;

type ObjectEntry = readonly [string, unknown];

import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { View } from 'react-native';
import { BodyText, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuDietaryPreferencesNotices = () => {
  return (
    <View style={styles.container}>
      <BodyText sizeMatch={['14']}>
        <FormattedMessage {...messages.majorAllergensNotice} />
      </BodyText>

      <BodyText sizeMatch={['14']}>
        <FormattedMessage {...messages.onlineVsStoreNotice} />
      </BodyText>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    rowGap: theme.spacing['4'],
  },
});

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  majorAllergensNotice: {
    defaultMessage:
      '* At sweetgreen we use all major allergens in our kitchens, so we cannot guarantee that our food is completely free of any allergen. If you have a severe allergy, we recommend not ordering from our restaurant.',
    description: 'Menu | Dietary preferences | Major allergens notice',
  },
  onlineVsStoreNotice: {
    defaultMessage:
      'Please note that your online dietary preferences are not communicated to our in-store teams.',
    description:
      'Menu | Dietary preferences | Online and store dietary preferences notice',
  },
});

import React, { useCallback } from 'react';
import { type RouteProp, useRoute } from '@react-navigation/native';

import { useIsLoggedIn, useIsLoggingIn } from '@order/AuthMachine';
import { NotFoundView } from '@order/components';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry } from '@order/Telemetry';
import { getEnvVars } from '@order/utils';

import { type GiftCardsTabStackParamList } from '../../navigation/AppNavigation.props';
import {
  GiftCardConfirmationLoadingPlaceholder,
  GiftCardDetailsScreenContent,
} from './components';
import { useGiftCardConfirmationData, useGiftCardData } from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardConfirmationScreen = () => {
  const { params } = useRoute<GiftCardConfirmationRouteProps>();
  const { orderId } = params ?? {};

  const { track } = useTelemetry();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );

  const isLoggedIn = useIsLoggedIn();
  const isLoggingIn = useIsLoggingIn();

  // ─── Remote Data ─────────────────────────────────────────────────────

  const giftCardConfirmationData = useGiftCardConfirmationData({
    pause: !isInAppGiftCardEnabled || !isLoggedIn,
    orderId,
  });
  const giftCard = giftCardConfirmationData.data?.userGiftCards?.[0];
  const giftCardData = useGiftCardData({
    assetId: giftCard?.assetId ?? '',
    pause: !isInAppGiftCardEnabled || !giftCard?.assetId,
  });

  const giftCardImgUrl = giftCardData.data?.cover.fields.file.url;
  const giftCardImgA11yLabel = giftCardData.data?.title;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const trackScreenLoadingError = useCallback(() => {
    if (!isInAppGiftCardEnabled) {
      track('gift.confirmation_failure', {
        error: 'The corresponding feature flag is disabled',
      });
    }

    if (!isLoggedIn) {
      track('gift.confirmation_failure', { error: 'User is not logged in' });
    }

    if (!giftCardConfirmationData.data) {
      track('gift.confirmation_failure', {
        error: 'Unable to fetch required data',
      });
    }
  }, [
    giftCardConfirmationData.data,
    isInAppGiftCardEnabled,
    isLoggedIn,
    track,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  const shouldRenderLoadingView =
    giftCardConfirmationData.isFetching || giftCardData.fetching || isLoggingIn;

  // ─────────────────────────────────────────────────────────────────────

  if (shouldRenderLoadingView) {
    return <GiftCardConfirmationLoadingPlaceholder />;
  }

  if (!isLoggedIn || !isInAppGiftCardEnabled || !giftCard) {
    return <NotFoundView onMount={trackScreenLoadingError} />;
  }

  // ─── Derived Data ────────────────────────────────────────────────────

  const { redemptionToken, recipientName, recipientEmail, balance, message } =
    giftCard;
  const date = giftCardConfirmationData?.data?.deliveryDetail?.deliveryDateTime;

  const redemptionURL = getGiftRedemptionUrl(redemptionToken!);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <GiftCardDetailsScreenContent
      orderId={orderId}
      recipientName={recipientName}
      recipientEmail={recipientEmail}
      balance={balance}
      date={date}
      message={message}
      assetUrl={giftCardImgUrl}
      assetA11yLabel={giftCardImgA11yLabel}
      redemptionURL={redemptionURL}
    />
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * A small helper that, given the relevant hash, returns the gift card
 * redemption URL.
 */
function getGiftRedemptionUrl(redemptionHash: string) {
  const appUrl = getEnvVars().APP_URL as string;

  return new URL(`/gift/redeem/${redemptionHash}`, appUrl).toString();
}

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardConfirmationRouteProps = RouteProp<
  GiftCardsTabStackParamList,
  'GiftCardConfirmation'
>;

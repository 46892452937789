import type { ComponentProps } from 'react';
import React, { useCallback } from 'react';
import type { TouchableOpacityProps } from 'react-native';
import { Platform, StyleSheet, TouchableOpacity } from 'react-native';
import { A } from '@expo/html-elements';
import * as Linking from 'expo-linking';
import { BodyText, Icon, theme } from '@sg/garnish';

import { getLinkWebAttributes } from '@order/utils';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsCardCTALink = (
  props: LocationResultsCardCTALinkProps,
) => {
  const openURL = useCallback(() => {
    void Linking.openURL(props.href);
  }, [props.href]);

  // ─── Web Version ─────────────────────────────────────────────────────

  if (Platform.OS === 'web') {
    return (
      // eslint-disable-next-line react-native-a11y/has-valid-accessibility-descriptors
      <TouchableOpacity>
        <A href={props.href} style={styles.infoCTA} {...LINK_WEB_ATTRIBUTES}>
          <Icon name={props.iconName} />
          <BodyText style={styles.infoCTALabel}>{props.label}</BodyText>
        </A>
      </TouchableOpacity>
    );
  }

  // ─── Native Fallback ─────────────────────────────────────────────────

  return (
    <LocationResultsCardCTAButton
      iconName={props.iconName}
      label={props.label}
      onPress={openURL}
    />
  );
};

export const LocationResultsCardCTAButton = (
  props: LocationResultsCardCTAButtonProps,
) => (
  <TouchableOpacity
    onPress={props.onPress}
    style={styles.infoCTA}
    accessibilityRole="button"
  >
    <Icon name={props.iconName} />
    <BodyText style={styles.infoCTALabel}>{props.label}</BodyText>
  </TouchableOpacity>
);

// ─── Helpers ─────────────────────────────────────────────────────────────────

const LINK_WEB_ATTRIBUTES = getLinkWebAttributes();

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  infoCTA: {
    display: 'flex', // required for web `<A>` component
    flexDirection: 'row',
    alignItems: 'center',
    height: 32,
    paddingHorizontal: theme.spacing['4'],
    borderRadius: theme.radius.xlarge,
    borderWidth: 1,
    borderColor: theme.colors.TEXT_COLOR,
  },
  infoCTALabel: {
    marginLeft: 6,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsCardCTAButtonProps = LocationResultsCardCTAProps &
  Required<Pick<TouchableOpacityProps, 'onPress'>>;

type LocationResultsCardCTALinkProps = LocationResultsCardCTAProps &
  Required<Pick<ComponentProps<typeof A>, 'href'>>;

type LocationResultsCardCTAProps = Readonly<{
  iconName: ComponentProps<typeof Icon>['name'];
  label: string;
}>;

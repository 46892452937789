import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { Asset, Entry } from 'contentful';

import { useContentfulContentTypeEntry } from '@order/Contentful';

import { fallbacks } from './fallbacks';

/**
 * Returns the contentful data for the bag.
 */
export const useLoyaltyContent = (params: UseLoyaltyContentParams) => {
  const { availablePoints = 0 } = params ?? {};

  const content = useContentfulContentTypeEntry<LoyaltyContainer>({
    contentType: 'loyalty',
  });

  const { formatMessage } = useIntl();

  return useMemo(() => {
    const contentfulData = content.data?.fields;

    const fallbackData = {
      bagHeaderLoggedOut: formatMessage(fallbacks.bagHeaderLoggedOut),
      bagHeaderNewUser: formatMessage(fallbacks.bagHeaderNewUser),
      bagHeaderRecurringUser: formatMessage(fallbacks.bagHeaderRecurringUser, {
        points: availablePoints,
      }),
      bagHeaderSubtitle: formatMessage(fallbacks.bagHeaderSubtitle),
      bagHeaderLoggedOutImageMessage: formatMessage(
        fallbacks.bagHeaderLoggedOutImageMessage,
      ),
    };

    const faqLoyaltyHome = (contentfulData?.faqLoyaltyHome ?? EMPTY_FAQ)
      ?.map(extractLoyaltyFAQ)
      ?.filter(filterOutInvalidLoyaltyFAQ);

    const faqPointHistory = (contentfulData?.faqPointHistory ?? EMPTY_FAQ)
      ?.map(extractLoyaltyFAQ)
      ?.filter(filterOutInvalidLoyaltyFAQ);

    const bagHeaderLoggedOut =
      contentfulData?.bagHeaderLoggedOut ?? fallbackData?.bagHeaderLoggedOut;
    const bagHeaderNewUser =
      contentfulData?.bagHeaderNewUser ?? fallbackData?.bagHeaderNewUser;
    const bagHeaderRecurringUser =
      contentfulData?.bagHeaderRecurringUser ??
      fallbackData?.bagHeaderRecurringUser;
    const bagHeaderSubtitle =
      contentfulData?.bagHeaderSubtitle ?? fallbackData?.bagHeaderSubtitle;
    const bagHeaderLoggedOutImageMessage =
      contentfulData?.bagLoggedOutImageMessage ??
      fallbackData?.bagHeaderLoggedOutImageMessage;
    const bagHeaderLoggedOutImage =
      contentfulData?.bagHeaderLoggedOutImage?.fields?.file?.url;

    const homeProgressBenefits = (
      contentfulData?.homeProgressBenefits ?? EMPTY_HOME_PROGRESS_BENEFITS
    )
      .map(extractHomeProgressBenefit)
      .filter(filterOutInvalidHomeProgressBenefit);

    return {
      faqLoyaltyHome,
      faqPointHistory,
      bagHeaderLoggedOut,
      bagHeaderNewUser,
      bagHeaderRecurringUser,
      bagHeaderSubtitle,
      bagHeaderLoggedOutImageMessage,
      bagHeaderLoggedOutImage,
      homeProgressBenefits,
    };
  }, [availablePoints, content.data?.fields, formatMessage]);
};

// ─── Container ──────────────────────────────────────────────────────────────

type LoyaltyContainer = {
  faqLoyaltyHome: Entry<LoyaltyFAQ>[];
  faqPointHistory: Entry<LoyaltyFAQ>[];
  homeProgressBenefits: Entry<ProgressBenefit>[];

  bagHeaderLoggedOut?: string;
  bagHeaderNewUser?: string;
  bagHeaderRecurringUser?: string;
  bagHeaderSubtitle?: string;
  bagLoggedOutImageMessage?: string;
  bagHeaderLoggedOutImage?: Asset;
};

// ─── Loyalty FAQ ────────────────────────────────────────────────────────────

type LoyaltyFAQ = {
  title?: string;
  description?: string;
};

const extractLoyaltyFAQ = (loyaltyFAQ?: Entry<LoyaltyFAQ>) => {
  return {
    title: loyaltyFAQ?.fields?.title,
    description: loyaltyFAQ?.fields?.description,
  };
};

const filterOutInvalidLoyaltyFAQ = (
  entry: Partial<LoyaltyFAQ>,
): entry is Required<LoyaltyFAQ> => {
  return Boolean(entry.title && entry.description);
};

const EMPTY_FAQ: Entry<LoyaltyFAQ>[] = [];

// ─── Progress Benefits ──────────────────────────────────────────────────────

type ProgressBenefit = {
  title?: string;
  description?: string;
};

export type MappedProgressBenefit = {
  title: string;
  maxPoints: number;
  isPendingBenefit: boolean;
};

const extractHomeProgressBenefit = (
  benefit?: Entry<ProgressBenefit>,
): Partial<MappedProgressBenefit> => {
  return {
    title: benefit?.fields?.title,
    maxPoints: Number(benefit?.fields?.description),
    isPendingBenefit: benefit?.fields?.description === 'Pending',
  };
};

const filterOutInvalidHomeProgressBenefit = (
  entry: Partial<MappedProgressBenefit>,
): entry is Required<MappedProgressBenefit> => {
  return (
    entry.title !== undefined &&
    (Number.isInteger(entry.maxPoints) || entry.isPendingBenefit === true) &&
    entry.isPendingBenefit !== undefined
  );
};

const EMPTY_HOME_PROGRESS_BENEFITS: Entry<ProgressBenefit>[] = [];

// ─── Params ─────────────────────────────────────────────────────────────────

type UseLoyaltyContentParams = {
  availablePoints: number | undefined;
};

import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  desktopDirection: {
    flexDirection: 'row',
  },
  mobileDirection: {
    flexDirection: 'column',
  },
  desktopAlignment: {
    alignItems: 'flex-start',
  },
  mobileAlignment: {
    alignItems: 'center',
  },
  desktopTextAlignment: {
    textAlign: 'left',
  },
  mobileTextAlignment: {
    textAlign: 'center',
  },
});

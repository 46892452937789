import { useCallback, useMemo } from 'react';
import { useInterpret, useSelector } from '@xstate/react';
import { type ContextFrom } from 'xstate';

import { createOrdersMachine } from '../../orders-machine';
import { type OrderWithIdAndWantedTime } from '../../orders-machine.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A hook to provide an interface to communicate with the Orders state machine.
 */
export const useOrders = <Order extends OrderWithIdAndWantedTime>(
  params: UseReorderOrdersParams<Order>,
) => {
  const { fetchOrders, ordersPerPage = 10 } = params;

  // ─── State Machine ───────────────────────────────────────────────────

  const reorderOrdersMachine = useMemo(
    () => createOrdersMachine<Order>('waiting'),
    [],
  );

  // ─── Machine Service ─────────────────────────────────────────────────

  const service = useInterpret(reorderOrdersMachine, {
    services: { fetchOrders },
    context: { ordersPerPage },
  });

  // ─── Context ─────────────────────────────────────────────────────────

  const orders = useSelector(service, (currentState) => {
    const { context } = currentState;

    return context.orders;
  });

  const canFetchMoreOrders = useSelector(service, (currentState) => {
    const { context } = currentState;

    return context.canFetchMoreOrders;
  });

  const mostRecentOrder = orders[0];

  // ─── Flags ───────────────────────────────────────────────────────────

  const isFetchingInitialOrders = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('waiting') || matches('fetchingInitialOrders');
  });

  const isReFetchingOrders = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('refetchingOrders');
  });

  const isFetchingMoreOrders = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('fetchingMoreOrders');
  });

  // ─── Machine Helpers ─────────────────────────────────────────────────

  const fetchInitialOrders = useCallback(() => {
    service.send('START');
  }, [service]);

  const fetchMoreOrders = useCallback(() => {
    service.send('FETCH_MORE_ORDERS');
  }, [service]);

  const refetchOrders = useCallback(() => {
    service.send('REFETCH_ORDERS');
  }, [service]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    orders,
    mostRecentOrder,
    isFetchingInitialOrders,
    isFetchingMoreOrders,
    isReFetchingOrders,
    fetchInitialOrders,
    fetchMoreOrders,
    refetchOrders,
    canFetchMoreOrders,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseReorderOrdersParams<Order extends OrderWithIdAndWantedTime> = {
  fetchOrders: (
    context: ContextFrom<ReturnType<typeof createOrdersMachine<Order>>>,
  ) => Promise<{
    orders: ReadonlyArray<Order>;
    lastPage: number;
  }>;
  ordersPerPage?: number;
};

import { useSelector } from '@xstate/react';

import { useGlobalAppState } from '../../GlobalAppState';

// TODO: deprecate
export const useCustomerData = () => {
  const { authMachineRef } = useGlobalAppState();

  return useSelector(authMachineRef, (state) => {
    return state.context.currentCustomer;
  });
};

import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { BodyText, theme } from '@sg/garnish';

export const MenuHeaderTitle = ({
  label,
  locationName,
  estimateHeader,
  onPress,
}: MenuHeaderTitleProps) => {
  const maxFontSizeMultiplier = 1.05;

  if (locationName === undefined) {
    return null;
  }

  return (
    <Pressable
      accessibilityRole="button"
      accessibilityLabel={[label, locationName].filter(Boolean).join(' ')}
      onPress={onPress}
    >
      {label ? (
        <BodyText
          size={2}
          style={styles.headerNavbarTitle}
          maxFontSizeMultiplier={maxFontSizeMultiplier}
        >
          {label}
        </BodyText>
      ) : null}

      <View style={styles.headerRow}>
        <BodyText
          size={2}
          style={[styles.headerNavbarTitle, styles.headerNavbarRestaurantName]}
          underline
          numberOfLines={1}
          ellipsizeMode="tail"
          maxFontSizeMultiplier={maxFontSizeMultiplier}
        >
          {locationName}
        </BodyText>

        {estimateHeader ? (
          <BodyText
            size={2}
            style={[styles.headerNavbarTitle, styles.headerNavbarEstimatedText]}
            maxFontSizeMultiplier={maxFontSizeMultiplier}
          >
            {estimateHeader}
          </BodyText>
        ) : null}
      </View>
    </Pressable>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type MenuHeaderTitleProps = Readonly<{
  label?: string;
  locationName?: string;
  estimateHeader?: string;
  onPress?: () => void;
}>;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  headerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerNavbarTitle: {
    flexShrink: 1,
    textAlign: 'center',
  },
  headerNavbarEstimatedText: {
    marginLeft: theme.spacing['1'],
  },
  headerNavbarRestaurantName: {
    color: theme.colors.KALE,
  },
});

import { useLocalizationContext } from '@order/Localization';

export const useBraintreeHostedFieldsPlaceholders = () => {
  const { t } = useLocalizationContext();

  return {
    numberPlaceholder: t('credit-card-form.card-number.placeholder'),
    expirationDatePlaceholder: t(
      'credit-card-form.expiration-date.placeholder',
    ),
    cvvPlaceholder: t('credit-card-form.cvv.placeholder'),
    postalCodePlaceholder: t('credit-card-form.zip-code.placeholder'),
  };
};

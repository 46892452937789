import React from 'react';
import type { PressableProps } from 'react-native';
import { Pressable } from 'react-native';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';
import { FadeView } from '../../../FadeView';

export const ModalBackdrop = (props: ModalBackdropProps) => {
  const { currentBreakpoint } = useResponsive();

  // This is a workaround to prevent the backdrop sliding up in XS.
  if (currentBreakpoint.isXS) {
    return (
      <FadeView show duration={500} style={StyleSheet.absoluteFill}>
        <Backdrop {...props} />
      </FadeView>
    );
  }

  return <Backdrop {...props} />;
};

const Backdrop = (props: ModalBackdropProps) => (
  <Pressable
    accessibilityRole="button"
    accessibilityLabel={props.backdropAccessibilityLabel ?? 'dismiss dialog'}
    onPress={props.onPress}
    style={[StyleSheet.absoluteFill, styles.backdrop]}
  />
);

// ─── Types ─────────────────────────────────────────────────────────────

type ModalBackdropProps = Readonly<{
  backdropAccessibilityLabel?: string;
  onPress: PressableProps['onPress'];
}>;

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  backdrop: {
    width: '100%',
    backgroundColor: theme.colors.OPACITY.DARK_KALE.DARKER,
    zIndex: theme.zIndex.modalBackdrop,
  },
});

import { useFeatureFlag } from '@order/LaunchDarkly';

import { useCart } from '../useCart';
import { useLocationTippingEnabled } from '../useLocationTippingEnabled';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A small hook that checks whether order tipping is available for the active
 * cart by looking at the order type, the availability of associated dynamic tips,
 * and whether tipping is enabled for the current location (pickup only).
 */
export const useTippingEnabledForCurrentOrderType = (): boolean => {
  const { isCartPickup, isCartOutpost, isCartDelivery } = useCart();

  const isTippingEnabledForLocation = useLocationTippingEnabled();
  const dynamicTips =
    useFeatureFlag('CELS-1191-permanent-dynamic-tip-values') ?? {};
  const availableTips = typeof dynamicTips === 'object' ? dynamicTips : {};

  const {
    pickup: pickupTips = [],
    delivery: deliveryTips = [],
    outpost: outpostTips = [],
  } = availableTips;

  // ─────────────────────────────────────────────────────────────────────

  if (isCartPickup) return isTippingEnabledForLocation && pickupTips.length > 0;

  if (isCartDelivery) return deliveryTips.length > 0;

  if (isCartOutpost) return outpostTips.length > 0;

  return false;
};

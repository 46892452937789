import React from 'react';
import type { ViewProps } from 'react-native';

import { BodyText } from '../../Text';

// ────────────────────────────────────────────────────────────────────────────────

export const LineItemDescription = (props: LineItemDescriptionProps) => {
  const { children, productSlug, selectable } = props;

  return (
    <BodyText
      selectable={selectable}
      size={4}
      testID={`sg-line-item-card-description-${productSlug}`}
    >
      {children}
    </BodyText>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

type LineItemDescriptionProps = Readonly<{
  productSlug: string;
  selectable?: boolean;
  children: ViewProps['children'];
}>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProductIngredientFragment = {
  readonly __typename?: 'Ingredient';
  readonly id: string;
  readonly name: string;
  readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  readonly dietaryProperties: ReadonlyArray<{
    readonly __typename?: 'DietaryProperty';
    readonly id: string;
    readonly name: Types.DietaryPropertyKind;
  }>;
};

export type IngredientModificationFragment = {
  readonly __typename?: 'IngredientModification';
  readonly calories: number;
  readonly removalCost: number;
  readonly additionCost: number;
  readonly substitutionCost: number;
  readonly kind: string | null;
  readonly subKind: string | null;
  readonly outOfStock: boolean;
  readonly mixable: boolean;
  readonly isGrain: boolean;
  readonly ingredient: {
    readonly __typename?: 'Ingredient';
    readonly id: string;
    readonly name: string;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    readonly dietaryProperties: ReadonlyArray<{
      readonly __typename?: 'DietaryProperty';
      readonly id: string;
      readonly name: Types.DietaryPropertyKind;
    }>;
  };
};

export type IngredientModificationKindFragment = {
  readonly __typename?: 'IngredientModificationKind';
  readonly minAggregateQuantity: number;
  readonly maxAggregateQuantity: number;
  readonly freeAggregateQuantity: number;
  readonly allowMultipleQuantity: boolean;
  readonly modifications: ReadonlyArray<{
    readonly __typename?: 'IngredientModification';
    readonly calories: number;
    readonly removalCost: number;
    readonly additionCost: number;
    readonly substitutionCost: number;
    readonly kind: string | null;
    readonly subKind: string | null;
    readonly outOfStock: boolean;
    readonly mixable: boolean;
    readonly isGrain: boolean;
    readonly ingredient: {
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename?: 'DietaryProperty';
        readonly id: string;
        readonly name: Types.DietaryPropertyKind;
      }>;
    };
  }>;
};

export type ProductDetailsFragment = {
  readonly __typename?: 'Product';
  readonly id: string;
  readonly slug: string;
  readonly restaurantId: string;
  readonly name: string;
  readonly categoryId: string;
  readonly description: string;
  readonly calories: number;
  readonly outOfStock: boolean;
  readonly throttleItem: boolean;
  readonly maxModifications: number;
  readonly isModifiable: boolean;
  readonly isCustom: boolean;
  readonly cost: number;
  readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  readonly restaurant: {
    readonly __typename?: 'Restaurant';
    readonly id: string;
    readonly isOutpost: boolean;
  };
  readonly dietaryProperties: ReadonlyArray<{
    readonly __typename?: 'DietaryProperty';
    readonly id: string;
    readonly name: Types.DietaryPropertyKind;
  }>;
  readonly ingredients: ReadonlyArray<{
    readonly __typename?: 'Ingredient';
    readonly id: string;
    readonly name: string;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    readonly dietaryProperties: ReadonlyArray<{
      readonly __typename?: 'DietaryProperty';
      readonly id: string;
      readonly name: Types.DietaryPropertyKind;
    }>;
  }>;
  readonly ingredientModifications: {
    readonly __typename?: 'IngredientModifications';
    readonly bases: {
      readonly __typename?: 'IngredientModificationKind';
      readonly minAggregateQuantity: number;
      readonly maxAggregateQuantity: number;
      readonly freeAggregateQuantity: number;
      readonly allowMultipleQuantity: boolean;
      readonly modifications: ReadonlyArray<{
        readonly __typename?: 'IngredientModification';
        readonly calories: number;
        readonly removalCost: number;
        readonly additionCost: number;
        readonly substitutionCost: number;
        readonly kind: string | null;
        readonly subKind: string | null;
        readonly outOfStock: boolean;
        readonly mixable: boolean;
        readonly isGrain: boolean;
        readonly ingredient: {
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly dietaryProperties: ReadonlyArray<{
            readonly __typename?: 'DietaryProperty';
            readonly id: string;
            readonly name: Types.DietaryPropertyKind;
          }>;
        };
      }>;
    };
    readonly toppings: {
      readonly __typename?: 'IngredientModificationKind';
      readonly minAggregateQuantity: number;
      readonly maxAggregateQuantity: number;
      readonly freeAggregateQuantity: number;
      readonly allowMultipleQuantity: boolean;
      readonly modifications: ReadonlyArray<{
        readonly __typename?: 'IngredientModification';
        readonly calories: number;
        readonly removalCost: number;
        readonly additionCost: number;
        readonly substitutionCost: number;
        readonly kind: string | null;
        readonly subKind: string | null;
        readonly outOfStock: boolean;
        readonly mixable: boolean;
        readonly isGrain: boolean;
        readonly ingredient: {
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly dietaryProperties: ReadonlyArray<{
            readonly __typename?: 'DietaryProperty';
            readonly id: string;
            readonly name: Types.DietaryPropertyKind;
          }>;
        };
      }>;
    };
    readonly premiums: {
      readonly __typename?: 'IngredientModificationKind';
      readonly minAggregateQuantity: number;
      readonly maxAggregateQuantity: number;
      readonly freeAggregateQuantity: number;
      readonly allowMultipleQuantity: boolean;
      readonly modifications: ReadonlyArray<{
        readonly __typename?: 'IngredientModification';
        readonly calories: number;
        readonly removalCost: number;
        readonly additionCost: number;
        readonly substitutionCost: number;
        readonly kind: string | null;
        readonly subKind: string | null;
        readonly outOfStock: boolean;
        readonly mixable: boolean;
        readonly isGrain: boolean;
        readonly ingredient: {
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly dietaryProperties: ReadonlyArray<{
            readonly __typename?: 'DietaryProperty';
            readonly id: string;
            readonly name: Types.DietaryPropertyKind;
          }>;
        };
      }>;
    };
    readonly dressings: {
      readonly __typename?: 'IngredientModificationKind';
      readonly minAggregateQuantity: number;
      readonly maxAggregateQuantity: number;
      readonly freeAggregateQuantity: number;
      readonly allowMultipleQuantity: boolean;
      readonly modifications: ReadonlyArray<{
        readonly __typename?: 'IngredientModification';
        readonly calories: number;
        readonly removalCost: number;
        readonly additionCost: number;
        readonly substitutionCost: number;
        readonly kind: string | null;
        readonly subKind: string | null;
        readonly outOfStock: boolean;
        readonly mixable: boolean;
        readonly isGrain: boolean;
        readonly ingredient: {
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly dietaryProperties: ReadonlyArray<{
            readonly __typename?: 'DietaryProperty';
            readonly id: string;
            readonly name: Types.DietaryPropertyKind;
          }>;
        };
      }>;
    };
    readonly bread: {
      readonly __typename?: 'IngredientModificationKind';
      readonly minAggregateQuantity: number;
      readonly maxAggregateQuantity: number;
      readonly freeAggregateQuantity: number;
      readonly allowMultipleQuantity: boolean;
      readonly modifications: ReadonlyArray<{
        readonly __typename?: 'IngredientModification';
        readonly calories: number;
        readonly removalCost: number;
        readonly additionCost: number;
        readonly substitutionCost: number;
        readonly kind: string | null;
        readonly subKind: string | null;
        readonly outOfStock: boolean;
        readonly mixable: boolean;
        readonly isGrain: boolean;
        readonly ingredient: {
          readonly __typename?: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly dietaryProperties: ReadonlyArray<{
            readonly __typename?: 'DietaryProperty';
            readonly id: string;
            readonly name: Types.DietaryPropertyKind;
          }>;
        };
      }>;
    };
  };
};

export type GetProductQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  costChannel?: Types.InputMaybe<Types.CostChannel>;
}>;

export type GetProductQuery = {
  readonly __typename?: 'Query';
  readonly product: {
    readonly __typename?: 'Product';
    readonly id: string;
    readonly slug: string;
    readonly restaurantId: string;
    readonly name: string;
    readonly categoryId: string;
    readonly description: string;
    readonly calories: number;
    readonly outOfStock: boolean;
    readonly throttleItem: boolean;
    readonly maxModifications: number;
    readonly isModifiable: boolean;
    readonly isCustom: boolean;
    readonly cost: number;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    readonly restaurant: {
      readonly __typename?: 'Restaurant';
      readonly id: string;
      readonly isOutpost: boolean;
    };
    readonly dietaryProperties: ReadonlyArray<{
      readonly __typename?: 'DietaryProperty';
      readonly id: string;
      readonly name: Types.DietaryPropertyKind;
    }>;
    readonly ingredients: ReadonlyArray<{
      readonly __typename?: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename?: 'DietaryProperty';
        readonly id: string;
        readonly name: Types.DietaryPropertyKind;
      }>;
    }>;
    readonly ingredientModifications: {
      readonly __typename?: 'IngredientModifications';
      readonly bases: {
        readonly __typename?: 'IngredientModificationKind';
        readonly minAggregateQuantity: number;
        readonly maxAggregateQuantity: number;
        readonly freeAggregateQuantity: number;
        readonly allowMultipleQuantity: boolean;
        readonly modifications: ReadonlyArray<{
          readonly __typename?: 'IngredientModification';
          readonly calories: number;
          readonly removalCost: number;
          readonly additionCost: number;
          readonly substitutionCost: number;
          readonly kind: string | null;
          readonly subKind: string | null;
          readonly outOfStock: boolean;
          readonly mixable: boolean;
          readonly isGrain: boolean;
          readonly ingredient: {
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: Types.DietaryPropertyKind;
            }>;
          };
        }>;
      };
      readonly toppings: {
        readonly __typename?: 'IngredientModificationKind';
        readonly minAggregateQuantity: number;
        readonly maxAggregateQuantity: number;
        readonly freeAggregateQuantity: number;
        readonly allowMultipleQuantity: boolean;
        readonly modifications: ReadonlyArray<{
          readonly __typename?: 'IngredientModification';
          readonly calories: number;
          readonly removalCost: number;
          readonly additionCost: number;
          readonly substitutionCost: number;
          readonly kind: string | null;
          readonly subKind: string | null;
          readonly outOfStock: boolean;
          readonly mixable: boolean;
          readonly isGrain: boolean;
          readonly ingredient: {
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: Types.DietaryPropertyKind;
            }>;
          };
        }>;
      };
      readonly premiums: {
        readonly __typename?: 'IngredientModificationKind';
        readonly minAggregateQuantity: number;
        readonly maxAggregateQuantity: number;
        readonly freeAggregateQuantity: number;
        readonly allowMultipleQuantity: boolean;
        readonly modifications: ReadonlyArray<{
          readonly __typename?: 'IngredientModification';
          readonly calories: number;
          readonly removalCost: number;
          readonly additionCost: number;
          readonly substitutionCost: number;
          readonly kind: string | null;
          readonly subKind: string | null;
          readonly outOfStock: boolean;
          readonly mixable: boolean;
          readonly isGrain: boolean;
          readonly ingredient: {
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: Types.DietaryPropertyKind;
            }>;
          };
        }>;
      };
      readonly dressings: {
        readonly __typename?: 'IngredientModificationKind';
        readonly minAggregateQuantity: number;
        readonly maxAggregateQuantity: number;
        readonly freeAggregateQuantity: number;
        readonly allowMultipleQuantity: boolean;
        readonly modifications: ReadonlyArray<{
          readonly __typename?: 'IngredientModification';
          readonly calories: number;
          readonly removalCost: number;
          readonly additionCost: number;
          readonly substitutionCost: number;
          readonly kind: string | null;
          readonly subKind: string | null;
          readonly outOfStock: boolean;
          readonly mixable: boolean;
          readonly isGrain: boolean;
          readonly ingredient: {
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: Types.DietaryPropertyKind;
            }>;
          };
        }>;
      };
      readonly bread: {
        readonly __typename?: 'IngredientModificationKind';
        readonly minAggregateQuantity: number;
        readonly maxAggregateQuantity: number;
        readonly freeAggregateQuantity: number;
        readonly allowMultipleQuantity: boolean;
        readonly modifications: ReadonlyArray<{
          readonly __typename?: 'IngredientModification';
          readonly calories: number;
          readonly removalCost: number;
          readonly additionCost: number;
          readonly substitutionCost: number;
          readonly kind: string | null;
          readonly subKind: string | null;
          readonly outOfStock: boolean;
          readonly mixable: boolean;
          readonly isGrain: boolean;
          readonly ingredient: {
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename?: 'DietaryProperty';
              readonly id: string;
              readonly name: Types.DietaryPropertyKind;
            }>;
          };
        }>;
      };
    };
  };
};

export const ProductIngredientFragmentDoc = gql`
  fragment ProductIngredient on Ingredient {
    id
    name
    asset {
      url
    }
    dietaryProperties {
      id
      name
    }
  }
`;
export const IngredientModificationFragmentDoc = gql`
  fragment IngredientModification on IngredientModification {
    calories
    removalCost
    additionCost
    substitutionCost
    kind
    subKind
    outOfStock
    ingredient {
      ...ProductIngredient
    }
    mixable
    isGrain
  }
  ${ProductIngredientFragmentDoc}
`;
export const IngredientModificationKindFragmentDoc = gql`
  fragment IngredientModificationKind on IngredientModificationKind {
    minAggregateQuantity
    maxAggregateQuantity
    freeAggregateQuantity
    allowMultipleQuantity
    modifications {
      ...IngredientModification
    }
  }
  ${IngredientModificationFragmentDoc}
`;
export const ProductDetailsFragmentDoc = gql`
  fragment ProductDetails on Product {
    id
    slug
    restaurantId
    name
    categoryId
    description
    cost: channelCost(costChannel: $costChannel)
    calories
    outOfStock
    throttleItem
    maxModifications
    isModifiable
    isCustom
    asset {
      url
    }
    restaurant {
      id
      isOutpost
    }
    dietaryProperties {
      id
      name
    }
    ingredients {
      ...ProductIngredient
    }
    ingredientModifications {
      bases {
        ...IngredientModificationKind
      }
      toppings {
        ...IngredientModificationKind
      }
      premiums {
        ...IngredientModificationKind
      }
      dressings {
        ...IngredientModificationKind
      }
      bread {
        ...IngredientModificationKind
      }
    }
  }
  ${ProductIngredientFragmentDoc}
  ${IngredientModificationKindFragmentDoc}
`;
export const GetProductDocument = gql`
  query GetProduct($id: ID!, $costChannel: CostChannel) {
    product(id: $id) {
      ...ProductDetails
    }
  }
  ${ProductDetailsFragmentDoc}
`;

export function useGetProductQuery(
  options: Omit<Urql.UseQueryArgs<GetProductQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetProductQuery, GetProductQueryVariables>({
    query: GetProductDocument,
    ...options,
  });
}

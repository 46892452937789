import * as DateFns from 'date-fns';

import { FlattenedOrderStatuses } from '@order/graphql';
import { ignoreTimezone } from '@order/utils';

import { getOrderTime } from './getOrderTime';

/**
 * Orders are considered past if their completed time is 1hrs in the past.
 */
export const checkIfPastOrder = ({
  order,
  flattenedOrderStatus = FlattenedOrderStatuses.Failed,
}: IsOrderPastProps) => {
  const canBePast = flattenedOrderStatus === 'completed';

  const orderTime = order ? getOrderTime(order) ?? '' : '';
  const orderTimeDate = orderTime ? ignoreTimezone(orderTime) : undefined;

  const oneHourAgo = DateFns.subHours(new Date(), 1);
  const isPast = orderTimeDate
    ? DateFns.isBefore(orderTimeDate, oneHourAgo)
    : false;

  return canBePast && isPast;
};

type IsOrderPastProps = Readonly<{
  order?: {
    wantedTime?: string | null;
    deliveryOrderDetail?: {
      estimatedDeliveryTime?: string | null;
    } | null;
  };
  flattenedOrderStatus?: FlattenedOrderStatuses;
}>;

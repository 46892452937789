/**
 * @description Returns same result for same array and index
 */
export function getItemByIndex<T>(items: readonly T[], index: number): T {
  return items[Math.abs(index % items.length)];
}

/**
 * @description Returns random item from the array
 */
export function getRandomItem<T>(items: readonly T[]): T {
  return items[Math.floor(Math.random() * items.length)];
}

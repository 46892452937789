import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { LoadingPlaceholder, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainLoadingPlaceholder = (
  props: OrderAgainLoadingPlaceholderProps,
) => {
  const { size = 'lg' } = props;

  const { match } = useResponsive();

  return (
    <View style={match([styles.containerXS, styles.containerSM])}>
      <LoadingPlaceholder
        rows={PLACEHOLDER_ROWS_NUMBER[size]}
        rowHeight={PLACEHOLDER_ROW_HEIGHTS[size]}
        palette="cream"
      />
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const PLACEHOLDER_ROW_HEIGHTS: Record<
  OrderAgainLoadingPlaceholderSize,
  number
> = {
  md: 100,
  lg: 180,
};

const PLACEHOLDER_ROWS_NUMBER: Record<
  OrderAgainLoadingPlaceholderSize,
  number
> = {
  md: 10,
  lg: 5,
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    paddingHorizontal: theme.spacing['4'],
  },
  containerSM: {
    paddingHorizontal: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderAgainLoadingPlaceholderProps = {
  size?: OrderAgainLoadingPlaceholderSize;
};

type OrderAgainLoadingPlaceholderSize = 'md' | 'lg';

import React from 'react';
import { useNoticeBannersStackContext, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { NoResults as NoResultsDesktop } from './NoResults.desktop';

export const NoResults = ({ noAutocompleteResults = false }) => {
  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();

  const text = t('location.no-results.banner');
  const message = noAutocompleteResults
    ? t('location.no-autocomplete-result')
    : t('location.no-locations-result');

  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  React.useEffect(() => {
    if (!currentBreakpoint.isXS) return;
    addNoticeBanner({ text, palette: 'neutral' });
  }, [text, currentBreakpoint.isXS, addNoticeBanner]);

  if (currentBreakpoint.isXS) return null;

  return <NoResultsDesktop message={message} />;
};

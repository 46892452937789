import React, { type PropsWithChildren } from 'react';
import { KeyboardAvoidingView, Platform, StyleSheet, View } from 'react-native';
import { Main } from '@expo/html-elements';
import { ScrollViewWithHeaderTitle, theme, useResponsive } from '@sg/garnish';

import { AppFooter } from '@order/components';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutContainer = (props: PropsWithChildren) => {
  const { children } = props;

  const { minWidth, match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerDynamicStyles = match([
    styles.containerXS,
    styles.containerSM,
    styles.containerMD,
  ]);
  const containerStyles = [styles.container, containerDynamicStyles];

  // ─────────────────────────────────────────────────────────────────────

  if (minWidth.isMD) {
    return (
      <ScrollViewWithHeaderTitle
        style={styles.innerContentContainerMD}
        contentContainerStyle={styles.innerContentContainerMD}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.innerContentContainerMD}>
          <Main style={containerStyles}>{children}</Main>
        </View>

        <AppFooter />
      </ScrollViewWithHeaderTitle>
    );
  }

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior="height"
      // NOTE: On iOS we rely on `automaticallyAdjustKeyboardInsets` property.
      enabled={Platform.OS === 'android'}
      keyboardVerticalOffset={190}
    >
      <ScrollViewWithHeaderTitle
        automaticallyAdjustKeyboardInsets
        contentContainerStyle={match([
          styles.scrollContainerXS,
          styles.scrollContainerSM,
        ])}
      >
        {children}
      </ScrollViewWithHeaderTitle>
    </KeyboardAvoidingView>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerXS: {
    paddingTop: theme.spacing['12'],
  },
  containerSM: {
    paddingTop: theme.spacing['16'],
  },
  containerMD: {
    flexDirection: 'row',
  },
  scrollContainerXS: {
    paddingVertical: theme.spacing['6'],
  },
  scrollContainerSM: {
    paddingTop: theme.spacing['12'],
    paddingBottom: theme.spacing['10'],
  },
  innerContentContainerMD: {
    height: '100%',
  },
});

import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { H1, Header } from '@expo/html-elements';
import { DisplayText, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardRedemptionHeader = (props: PropsWithChildren) => {
  return (
    <Header style={styles.header}>
      <H1 style={styles.heading}>
        <DisplayText
          style={styles.headingText}
          bold
          sizeMatch={['32']}
          maxFontSizeMultiplier={1.2}
        >
          {props.children}
        </DisplayText>
      </H1>
    </Header>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    marginBottom: theme.spacing['2'],
  },
  heading: {
    textAlign: 'center',

    // NOTE: Resets default styles

    display: 'flex',
    justifyContent: 'center',
    marginVertical: 0,
  },
  headingText: {
    color: theme.colors.KALE,
  },
});

import { useCallback, useMemo, useRef } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import type { useCustomer } from '@order/Customer';
import type { useCart } from '@order/hooks';
import type { TelemetryEvent } from '@order/Telemetry';
import { telemetryProductFromInput, useTelemetry } from '@order/Telemetry';
import { convertPriceToFloat } from '@order/utils';

import { useOrderStatusTelemetry } from '../../../OrderStatusScreen/OrderStatus.telemetry';
import { getOrderTip } from '../../Checkout.utils';
import type { useCheckoutPayload } from '../useCheckoutPayload';

export const useCheckoutTelemetry = (props: CheckoutTelemetryProps) => {
  const {
    customer,
    cart,
    cartOrderType: orderType,
    total,
    payload,
    hasPreviousOrders = false,
  } = props;

  // Cart
  const orderId = cart?.id;
  const restaurantId = cart?.restaurant?.id;
  const restaurantName = cart?.restaurant?.name;

  const orderTip = payload ? getOrderTip(payload, orderType) : '0';

  // Line Items
  const products = useMemo(
    () =>
      cart?.lineItems
        .map(({ product, cost, quantity, customName }) =>
          telemetryProductFromInput({
            ...product,
            cost,
            quantity,
            name: customName ?? product?.name,
          }),
        )
        ?.filter(Boolean),
    [cart?.lineItems],
  );

  const bagCount = useMemo(
    () =>
      cart?.lineItems
        .map(({ quantity }) => quantity ?? 0)
        .reduce((count: number, quantity: number) => count + quantity, 0),
    [cart?.lineItems],
  );

  // Restaurant Address
  const restaurantAddress = useMemo(
    () => ({
      street: cart?.restaurant?.address ?? '',
      city: cart?.restaurant?.city ?? '',
      state: cart?.restaurant?.state ?? '',
      zipCode: cart?.restaurant?.zipCode ?? '',
      country: 'US',
    }),
    [
      cart?.restaurant?.address,
      cart?.restaurant?.city,
      cart?.restaurant?.state,
      cart?.restaurant?.zipCode,
    ],
  );

  // Delivery Order Detail
  const deliveryOrderDetail = useMemo(
    () => ({
      street: cart?.deliveryOrderDetail?.address?.street ?? '',
      city: cart?.deliveryOrderDetail?.address?.city ?? '',
      state: cart?.deliveryOrderDetail?.address?.state ?? '',
      zipCode: cart?.deliveryOrderDetail?.address?.zipCode ?? '',
      country: cart?.deliveryOrderDetail?.address?.country ?? 'US',
    }),
    [
      cart?.deliveryOrderDetail?.address?.city,
      cart?.deliveryOrderDetail?.address?.country,
      cart?.deliveryOrderDetail?.address?.state,
      cart?.deliveryOrderDetail?.address?.street,
      cart?.deliveryOrderDetail?.address?.zipCode,
    ],
  );

  // Customer
  const customerTelemetry = useMemo(() => {
    const addresses = getAddressesNames(customer);

    return {
      userId: customer?.id ?? '',
      firstName: customer?.firstName ?? '',
      lastName: customer?.lastName ?? '',
      email: customer?.email ?? '',
      phoneNumber: customer?.phoneNumber ?? '',
      addresses,
      hasDuplicateNameAddresses: hasDuplicateNameAddresses(addresses),
    };
  }, [customer]);

  const data: TelemetryEvent<'checkout.view'>['payload'] = useMemo(
    () => ({
      orderId,
      restaurantId,
      restaurantName,
      orderType,
      products,
      bagCount,
      orderValue: convertPriceToFloat(total),
      orderTotal: convertPriceToFloat(total),
      orderTip,
      customer: customerTelemetry,
      isDelivery: orderType === 'delivery',
      isNewCustomer: !hasPreviousOrders,
      ...(orderType === 'delivery' ? deliveryOrderDetail : restaurantAddress),
    }),
    [
      orderId,
      restaurantId,
      restaurantName,
      orderType,
      products,
      bagCount,
      total,
      orderTip,
      customerTelemetry,
      deliveryOrderDetail,
      restaurantAddress,
      hasPreviousOrders,
    ],
  );

  const checkoutViewEventRef = useRef<TelemetryEvent<'checkout.view'>>();
  const checkoutViewEvent: TelemetryEvent<'checkout.view'> = useMemo(
    () => ({
      name: 'checkout.view',
      payload: data,
    }),
    [data],
  );

  // eslint-disable-next-line functional/immutable-data
  checkoutViewEventRef.current = checkoutViewEvent;

  const checkoutStartEvent: TelemetryEvent<'checkout.start'> = useMemo(
    () => ({
      name: 'checkout.start',
      payload: data,
    }),
    [data],
  );

  const checkoutSuccessEvent: TelemetryEvent<'checkout.success'> = useMemo(
    () => ({
      name: 'checkout.success',
      payload: data,
    }),
    [data],
  );

  return {
    data,
    checkoutViewEvent: checkoutViewEventRef,
    checkoutStartEvent,
    checkoutSuccessEvent,
  };
};

export const useCheckoutTelemetryEvents = (
  checkoutTelemetryProps: Parameters<typeof useCheckoutTelemetry>[0],
) => {
  const { trackEvent } = useTelemetry();
  const { handleOrderStatusEvent } = useOrderStatusTelemetry();
  const { checkoutStartEvent, checkoutSuccessEvent } = useCheckoutTelemetry(
    checkoutTelemetryProps,
  );

  const checkoutStart = useCallback(() => {
    trackEvent(checkoutStartEvent);
  }, [checkoutStartEvent, trackEvent]);

  const checkoutSuccess = useCallback(() => {
    handleOrderStatusEvent('checkout.success');
    trackEvent(checkoutSuccessEvent);
  }, [checkoutSuccessEvent, handleOrderStatusEvent, trackEvent]);

  return useMemo(
    () => ({
      checkoutStart,
      checkoutSuccess,
    }),
    [checkoutStart, checkoutSuccess],
  );
};

export const useCheckoutViewEventEffect = (props: CheckoutTelemetryProps) => {
  const { customer, cart, payload, cartOrderType, total } = props;

  const { trackEvent } = useTelemetry();

  const { checkoutViewEvent } = useCheckoutTelemetry({
    customer,
    cart,
    cartOrderType,
    total,
    payload,
  });

  const trackCheckoutView = useCallback(() => {
    if (checkoutViewEvent.current) {
      trackEvent(checkoutViewEvent.current);
    }
  }, [checkoutViewEvent, trackEvent]);

  useFocusEffect(trackCheckoutView);
};

// ─── Helpers ───────────────────────────────────────────────────────────

function getAddressesNames(
  customer: ReturnType<typeof useCustomer>['customer'],
) {
  return (customer?.addresses?.map(({ name }) => name)?.filter(Boolean) ??
    []) as readonly string[];
}

function hasDuplicateNameAddresses(addresses: readonly string[]) {
  const uniqueAddresses = new Set(addresses);

  return uniqueAddresses.size !== addresses.length;
}

// ─── Types ─────────────────────────────────────────────────────────────

type CheckoutTelemetryProps = Readonly<{
  customer: ReturnType<typeof useCustomer>['customer'];
  cart: ReturnType<typeof useCart>['cart'];
  cartOrderType: ReturnType<typeof useCart>['cartOrderType'];
  total: ReturnType<typeof useCart>['total'];
  payload?: ReturnType<typeof useCheckoutPayload>['payload'];
  hasPreviousOrders?: boolean;
}>;

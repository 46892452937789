import React, { useLayoutEffect } from 'react';
import type { ViewProps } from 'react-native';
import {
  Platform,
  ScrollView,
  StyleSheet,
  useWindowDimensions,
  View,
} from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import type { AddressType, MapProps } from '@sg/garnish';
import {
  AddressTypes,
  LocationCard,
  Map,
  ModalRow,
  theme,
  useResponsive,
} from '@sg/garnish';

import { LoadingAnimation, TransparentModal } from '@order/components';

import type { ModalStackChildProps } from '../../../../navigation/AppNavigation.props';
import type { AddressQuery } from '../../../DeliveryMenuScreen/GraphQL/DeliveryRestaurant.query.generated';
import { useAddressQuery } from '../../../DeliveryMenuScreen/GraphQL/DeliveryRestaurant.query.generated';
import type { ReorderRestaurantQuery } from '../../GraphQL/ReorderConfirmLocation.generated';
import {
  useCancelReorder,
  useChangeLocation,
  useConflictReview,
  useReorderState,
} from '../../hooks';
import {
  ReorderModalFooter,
  ReorderModalHeader,
  ReorderTitle,
  useReorderRightIcon,
} from './ReorderConfirmLocation.components';
import { useReorderConfirmLocationData } from './ReorderConfirmLocation.data';

export const ReorderConfirmLocation = ({
  navigation,
}: ModalStackChildProps<'ReorderConfirmLocation'>) => {
  const {
    currentStep,
    reordering,
    restaurantId,
    orderType,
    deliveryOrderDetail,
  } = useReorderState();
  const cancelReorder = useCancelReorder();
  const changeLocation = useChangeLocation();
  const conflictReview = useConflictReview();

  const { fetching, region, pins, restaurant } = useReorderConfirmLocationData(
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    restaurantId,
    orderType,
  );

  if (currentStep !== 'locationConfirmation' && Platform.OS === 'web') {
    return null;
  }

  if (!restaurant) return <LoadingAnimation />;

  return (
    <ReorderConfirmLocationContent
      navigation={navigation}
      fetching={fetching}
      // @ts-expect-error TS(2322): Type 'AddressType | undefined' is not assignable t... Remove this comment to see the full error message
      orderType={orderType}
      restaurant={restaurant}
      region={region}
      pins={pins}
      isLoading={reordering}
      cancelReorder={cancelReorder}
      onClose={cancelReorder}
      onContinue={conflictReview}
      onMoreLocations={changeLocation}
      deliveryAddressId={deliveryOrderDetail?.addressId}
    />
  );
};

export const ReorderConfirmLocationContent = (
  props: ReorderConfirmLocationContentProps,
) => {
  const { currentBreakpoint } = useResponsive();
  const Layout = currentBreakpoint.isXS ? MobileLayout : DesktopLayout;
  const modalSize = currentBreakpoint.isXS ? 'full' : 'large';

  const addressId = props.deliveryAddressId ?? '';

  const [{ data: addressData }] = useAddressQuery({
    variables: { addressId },
    pause: !addressId,
  });

  if (props.fetching) {
    return (
      <TransparentModal
        testID="reorder-confirm-location"
        size={modalSize}
        onClose={props.cancelReorder}
        mobileEdges={['bottom']}
      >
        <View style={styles.loadingContainer}>
          <LoadingAnimation />
        </View>
      </TransparentModal>
    );
  }

  return (
    <TransparentModal
      size={modalSize}
      onClose={props.cancelReorder}
      mobileEdges={['bottom']}
    >
      <Layout {...props} deliveryAddress={addressData?.address}>
        {/* @ts-expect-error TS(2322): Type '{ fetching?: boolean | undefined; deliveryAd... Remove this comment to see the full error message */}
        <Map draggable={false} showControls={false} {...props} />
      </Layout>
    </TransparentModal>
  );
};

const DesktopLayout = ({
  orderType,
  restaurant,
  deliveryAddress,
  isLoading,
  children,
  onClose,
  onMoreLocations,
  onContinue,
}: Omit<ReorderConfirmLocationLayoutProps, 'navigation'>) => {
  const isDelivery = orderType === AddressTypes.delivery;

  return (
    <View
      testID="reordering.confirm-location.container"
      style={styles.desktopContainer}
    >
      <View style={styles.desktopMapContainer}>{children}</View>
      <View style={styles.desktopContentContainer}>
        <ReorderModalHeader orderType={orderType} onClose={onClose} />
        <ModalRow>
          {isDelivery ? (
            <DeliveryLocationCard deliveryAddress={deliveryAddress} />
          ) : (
            <RestaurantLocationCard
              orderType={orderType}
              restaurant={restaurant}
            />
          )}
        </ModalRow>
        <ReorderModalFooter
          isLoading={isLoading}
          onMoreLocations={onMoreLocations}
          onContinue={onContinue}
        />
      </View>
    </View>
  );
};

const MobileLayout = ({
  navigation,
  orderType,
  restaurant,
  deliveryAddress,
  isLoading,
  children,
  onMoreLocations,
  onContinue,
}: Omit<ReorderConfirmLocationLayoutProps, 'onClose'>) => {
  const { width } = useWindowDimensions();
  const dynamicHeight = useStyle(() => ({ height: width * 0.5 }), [width]);
  const isDelivery = orderType === AddressTypes.delivery;

  // Mobile Header
  const rightIcon = useReorderRightIcon();

  useLayoutEffect(() => {
    navigation.setOptions({
      // eslint-disable-next-line react/no-unstable-nested-components -- @REVIEW
      headerTitle: () => <ReorderTitle orderType={orderType} />,
      headerRight: () => rightIcon,
      headerShown: true,
    });
  }, [navigation, orderType, rightIcon]);

  return (
    <View
      testID="reordering.confirm-location.container"
      style={styles.mobileContainer}
    >
      <ScrollView
        testID="reordering.confirm-location.contents"
        style={styles.mobileContent}
        contentContainerStyle={styles.grow}
      >
        <ModalRow>
          <View style={[styles.mobileMapContainer, dynamicHeight]}>
            {children}
          </View>
        </ModalRow>
        <ModalRow style={styles.mobileLocationContainer}>
          {isDelivery ? (
            <DeliveryLocationCard deliveryAddress={deliveryAddress} />
          ) : (
            <RestaurantLocationCard
              orderType={orderType}
              restaurant={restaurant}
            />
          )}
        </ModalRow>
      </ScrollView>
      <ReorderModalFooter
        isLoading={isLoading}
        onMoreLocations={onMoreLocations}
        onContinue={onContinue}
      />
    </View>
  );
};

const RestaurantLocationCard = ({
  restaurant,
  orderType,
}: RestaurantLocationCardProps) => {
  return (
    <LocationCard
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      id={restaurant.id}
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      acceptingOrders={restaurant.isAcceptingOrders}
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      notAcceptingOrdersReason={restaurant.notAcceptingOrdersReason}
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      restaurantSlug={restaurant.slug}
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      address={restaurant.address}
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      city={restaurant.city}
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      state={restaurant.state}
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      zipCode={restaurant.zipCode}
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      name={restaurant.name}
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      flexMessage={restaurant.flexMessage ?? ''}
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      lat={restaurant.latitude}
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      lng={restaurant.longitude}
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      storeHours={restaurant.hours?.formatted ?? ''}
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      imageUrl={orderType === 'outpost' ? undefined : restaurant.asset?.url}
    />
  );
};

const DeliveryLocationCard = ({
  deliveryAddress,
}: DeliveryLocationCardProps) => {
  if (!deliveryAddress) return null;

  const deliveryAddressStreet = `${deliveryAddress?.street}${
    deliveryAddress?.secondaryStreet
      ? `, ${deliveryAddress?.secondaryStreet}`
      : ''
  }`;

  return (
    <LocationCard
      id={deliveryAddress?.id ?? ''}
      acceptingOrders={true}
      notAcceptingOrdersReason=""
      restaurantSlug=""
      address={deliveryAddressStreet}
      city={deliveryAddress?.city ?? ''}
      state={deliveryAddress?.state ?? ''}
      zipCode={deliveryAddress?.zipCode ?? ''}
      name={deliveryAddress?.name ?? ''}
      flexMessage=""
      lat={deliveryAddress?.latitude ?? 0}
      lng={deliveryAddress?.longitude ?? 0}
      storeHours=""
    />
  );
};

type ReorderConfirmLocationContentProps = Readonly<{
  fetching?: boolean;
  deliveryAddressId?: string;
  cancelReorder: () => void;
}> &
  MapProps &
  ReorderConfirmLocationLayoutProps;

type ReorderConfirmLocationLayoutProps = Readonly<{
  navigation: ModalStackChildProps<'ReorderConfirmLocation'>['navigation'];
  orderType: AddressType;
  restaurant: ReorderRestaurantQuery['restaurant'];
  isLoading?: boolean;
  children?: ViewProps['children'];
  deliveryAddress?: AddressQuery['address'];
  onClose: () => void;
  onMoreLocations: () => void;
  onContinue: () => void;
}>;

type RestaurantLocationCardProps = Readonly<{
  restaurant: ReorderRestaurantQuery['restaurant'];
  orderType: AddressType;
}>;

type DeliveryLocationCardProps = Readonly<{
  deliveryAddress?: AddressQuery['address'];
}>;

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    height: '100%',
    padding: theme.spacing['20'],
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  desktopContainer: {
    minHeight: 504,
    flex: 1,
    flexDirection: 'row',
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  mobileContainer: {
    flex: 1,
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  mobileContent: {
    flex: 1,
  },
  grow: {
    flexGrow: 1,
  },
  desktopMapContainer: {
    borderRightWidth: 1,
    borderRightColor: theme.colors.DARK_KALE,
    width: '50%',
    height: '100%',
  },
  desktopContentContainer: {
    flex: 1,
    width: '50%',
    height: '100%',
    justifyContent: 'space-around',
  },
  mobileMapContainer: {
    height: 192,
    borderRadius: theme.radius.medium,
    borderWidth: 1,
    borderColor: theme.colors.NEUTRAL_3,
    overflow: 'hidden',
  },
  mobileLocationContainer: {
    paddingVertical: theme.spacing['4'],
  },
});

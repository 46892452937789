import type { ComponentProps } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, ButtonGroup, SectionHeader } from '@sg/garnish';

import { TipsGroupCustomTip } from '../TipsGroupCustomTip';
import { useTipTelemetry } from './hooks/useTipTelemetry/useTipTelemetry';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders the tips group component based on the provided list and additional settings.
 */
export const TipsGroup = (props: TipsGroupProps) => {
  const {
    tips,
    defaultTipValue,
    orderSubtotalInCents,
    selectedTipAmountInCents,
    setTipAmount,
    sectionTitle,
    sectionDescription,
    fluidTipButtons,
    tipButtonsHorizontalPadding,
  } = props;

  // ─── State ───────────────────────────────────────────────────────────

  const [isCustomTipSelected, setIsCustomTipSelected] = useState(false);

  // ─── Tips ────────────────────────────────────────────────────────────

  const selectedTipTypeValueOnAmount =
    getTipValueBasedOnAmount(tips, selectedTipAmountInCents) ?? defaultTipValue;
  const selectedTipValue = isCustomTipSelected
    ? 'custom'
    : selectedTipTypeValueOnAmount;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const { monitorPaymentTipChanged } = useTipTelemetry();

  const onTipValueChange = useCallback(
    (tipValue: string, shouldTrackChange = true) => {
      const hasSelectedCustomTip = tipValue === 'custom';
      const tipAmount = tips.find((tip) => tip.value === tipValue)?.amount ?? 0;

      setIsCustomTipSelected(hasSelectedCustomTip);
      setTipAmount(tipAmount);

      if (shouldTrackChange) {
        monitorPaymentTipChanged(tipValue, tipAmount, orderSubtotalInCents);
      }
    },
    [tips, monitorPaymentTipChanged, orderSubtotalInCents, setTipAmount],
  );

  // ─────────────────────────────────────────────────────────────────────

  // set the default tip value on mount
  useEffect(() => {
    onTipValueChange(defaultTipValue, false);
  }, [defaultTipValue, onTipValueChange]);

  if (tips.length === 0) return null;

  return (
    <View style={styles.container}>
      <SectionHeader heading={sectionTitle} />

      {sectionDescription ? (
        <BodyText size={4}>{sectionDescription}</BodyText>
      ) : null}

      {/* ─── Standard tips ────────────────────────── */}

      <ButtonGroup
        value={selectedTipValue}
        items={tips}
        onChange={onTipValueChange}
        fluid={fluidTipButtons}
        itemsHorizontalPadding={tipButtonsHorizontalPadding}
      />

      {/* ─── Custom tip ───────────────────────────── */}

      {isCustomTipSelected ? (
        <TipsGroupCustomTip
          tipAmountInCents={selectedTipAmountInCents}
          orderSubtotalInCents={orderSubtotalInCents}
          onChange={setTipAmount}
        />
      ) : null}
    </View>
  );
};

// ─── Utils ───────────────────────────────────────────────────────────────────

/**
 * Returns tip value from tips group based on the provided tip amount.
 */
function getTipValueBasedOnAmount(
  tipsGroup: readonly Tip[],
  tipAmount: number,
) {
  return tipsGroup.find((tip) => tip.amount === tipAmount)?.value;
}

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    gap: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type TipsGroupProps = Readonly<{
  tips: readonly Tip[];
  defaultTipValue: string;
  selectedTipAmountInCents: number;
  setTipAmount: (tipAmount: number) => void;
  sectionTitle: string;
  sectionDescription?: string;
  orderSubtotalInCents: number;
  fluidTipButtons?: boolean;
  tipButtonsHorizontalPadding?: ComponentProps<
    typeof ButtonGroup
  >['itemsHorizontalPadding'];
}>;

type Tip = Readonly<{
  value: string;
  label: string;
  helperText?: string;
  amount?: number;
  accessibilityHint?: string;
}>;

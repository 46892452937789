import React from 'react';
import type { ViewProps } from 'react-native';
import { Pressable } from 'react-native';
import {
  useNoticeBannersStackContext,
  useResponsive,
  WithTooltip,
} from '@sg/garnish';

// Wrapper for Tooltip on desktop and Banner on mobile.
export const TooltipWithBanner = ({
  label,
  disableTooltip,
  offsetX,
  offsetY,
  children,
  palette = 'neutral',
}: TooltipWithBannerProps) => {
  const { currentBreakpoint } = useResponsive();

  // mobile view uses a notice banner instead of a tooltip.
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const showNoticeBanner = React.useCallback(() => {
    addNoticeBanner({ text: label, palette });
  }, [addNoticeBanner, label, palette]);

  // should not show tooltip by prop override.
  if (disableTooltip) {
    return children as React.ReactElement;
  }

  // should show banner trigger on mobile.
  if (currentBreakpoint.isXS) {
    return (
      <Pressable
        onPress={showNoticeBanner}
        accessibilityRole="button"
        accessibilityLabel={label}
      >
        {children}
      </Pressable>
    );
  }

  // should show tooltip on desktop.
  return (
    <WithTooltip
      text={label}
      placement="top"
      triggerMode="hover"
      offsetX={offsetX}
      offsetY={offsetY}
      toggleAccessibilityLabel={label}
    >
      {children}
    </WithTooltip>
  );
};

type TooltipWithBannerProps = Readonly<{
  label: string;
  disableTooltip?: boolean;
  palette?: 'success' | 'neutral' | 'caution';
}> &
  Pick<ViewProps, 'children'> &
  Pick<React.ComponentProps<typeof WithTooltip>, 'offsetX' | 'offsetY'>;

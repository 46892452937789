// -----------------------------------
// ---- config
// -----------------------------------

export const PLACES_AUTOCOMPLETE_API_URL =
  'https://maps.googleapis.com/maps/api/place/autocomplete/json';
export const PLACES_LIBRARY_URL =
  'https://maps.googleapis.com/maps/api/js?libraries=places';
export const PLACES_GEOCODE_URL =
  'https://maps.googleapis.com/maps/api/geocode/json';
export const PLACES_API_CONFIG = { type: 'address', country: 'us' };

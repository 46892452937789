import type { ForwardedRef, ReactElement } from 'react';
import React, { cloneElement, forwardRef } from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import { StyleSheet, View } from 'react-native';

import { useFluidSize } from '../../../hooks';
import type { ChipMenuProps, ChipProps } from '../Chip.types';

export const Spacer = () => <View style={{ width: useFluidSize()(8, 12) }} />;

const isFirst = (index: number): boolean => index === 0;

export type ChipItemProps = ReactElement<
  ChipProps & Readonly<{ ref: React.Ref<unknown> }>
>;

export const Menu = forwardRef<View, ChipMenuProps>(
  (props: ChipMenuProps, ref: ForwardedRef<View>): ReactElement => {
    const { children, value: selectedValue, onChange, style } = props;
    const flatChildren = flattenChildren(children);

    return (
      <View
        ref={ref}
        style={[styles.default, style]}
        accessibilityRole="radiogroup"
      >
        {flatChildren.map((child, index) => {
          const item = child as ChipItemProps;

          return (
            <View
              style={styles.item}
              // eslint-disable-next-line react/no-array-index-key
              key={`radio-item-${index}`}
            >
              {isFirst(index) ? undefined : <Spacer />}
              {cloneElement(item, {
                onChange,
                selectedValue,
                accessibilityRole: 'radio',
                accessibilityState: {
                  checked: item.props.value === selectedValue,
                },
              })}
            </View>
          );
        })}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  item: { flexDirection: 'row' },
  default: {
    width: '100%',
    flexDirection: 'row',
  },
});

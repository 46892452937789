import { useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useChallenges } from '@order/ChallengesAndRewards';
import { useFeatureFlag } from '@order/LaunchDarkly';

import {
  useChallengeRewardsQuery,
  useChallengeRewardsWithRedemptionChannelQuery,
} from './graphql/ChallengeRewards.generated';

/**
 * Refreshes challenges and rewards when the screen gains/regains focus.
 * This necessary because submitting orders can change the status of challenges and rewards.
 * Since the submit order mutation doesn't update the cache, we update it upon re-entering the screen.
 */
export const useSweetpassChallengesAndRewards = () => {
  const isLoggedIn = useIsLoggedIn();
  const isScreenFocused = useIsFocused();

  const { challenges, isFetchingChallenges, fetchChallenges } = useChallenges();

  const shouldUseNewRewardsTypes = useFeatureFlag('CELS-1700-in-store-rewards');

  // ─── Queries ─────────────────────────────────────────────────────────

  const [legacyChallengeRewards] = useChallengeRewardsQuery({
    requestPolicy: 'network-only',
    pause: shouldUseNewRewardsTypes || !isLoggedIn || !isScreenFocused,
  });
  const [challengeRewardsWithRedemptionChannel] =
    useChallengeRewardsWithRedemptionChannelQuery({
      requestPolicy: 'network-only',
      pause: !shouldUseNewRewardsTypes || !isLoggedIn || !isScreenFocused,
    });

  // ─── Derived Data ────────────────────────────────────────────────────

  const challengeRewardsResponse = shouldUseNewRewardsTypes
    ? challengeRewardsWithRedemptionChannel
    : legacyChallengeRewards;
  const challengeRewards = challengeRewardsResponse.data?.challengeRewards;
  const isFetchingChallengeRewards = challengeRewardsResponse.fetching;

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    if (!isLoggedIn || !isScreenFocused) return;

    fetchChallenges();
  }, [fetchChallenges, isLoggedIn, isScreenFocused]);

  return {
    challenges,
    challengeRewards,
    isFetchingChallenges,
    isFetchingChallengeRewards,
  };
};

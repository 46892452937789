export const redeemGiftCardForm = {
  'redeem-gift-card-form.gift-card-number': 'Gift card number',
  'redeem-gift-card-form.registration-code': 'Registration code',
  'redeem-gift-card-form.apply-a11y-label': 'Apply gift card',

  // ─── Helper Texts ────────────────────────────────────────────────────

  'redeem-gift-card-form.helper-text':
    'If entering gift card number, your gift card amount will be transferred to your online credit card and you will no longer be able to use your physical gift card',

  // ─── Inline Errors ───────────────────────────────────────────────────

  'redeem-gift-card-form.error.inline.gift-card-number':
    'Gift card number is required',
  'redeem-gift-card-form.error.inline.registration-code':
    'Registration code is required',

  // ─── Form Submit ─────────────────────────────────────────────────────

  'redeem-gift-card-form.submit.success': 'Credit added successfully',
  'redeem-gift-card-form.submit.invalid-gift-card-error':
    'This code or gift card is not valid.',
  'redeem-gift-card-form.submit.already-applied-error':
    'This code or gift card has already been applied.',
  'redeem-gift-card-form.submit.unable-to-redeem-error':
    'Unable to redeem gift card. Please try again later.',
  'redeem-gift-card-form.submit.no-remaining-balance-error':
    'This gift card has no remaining balance to transfer to your digital account.',
};

import React, { type PropsWithChildren } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { Header } from '@expo/html-elements';
import { theme } from '@garnish/constants';
import { useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainHeader = (props: PropsWithChildren) => {
  const { match } = useResponsive();
  const { top: safeAreaTop } = useSafeAreaInsets();

  // ─── Styles ──────────────────────────────────────────────────────────

  const headerDynamicStyles = useStyle(() => {
    return {
      paddingTop: Platform.select({
        // NOTE: We only require a safe area on Android because the corresponding
        //       parent modal is always displayed below the top status bar on other
        //       platforms.
        android: safeAreaTop + theme.spacing['8'],
        default: theme.spacing['4'],
      }),
    };
  }, [safeAreaTop]);

  const headerStyles = [
    styles.header,
    match([styles.headerXS, styles.headerSM]),
    headerDynamicStyles,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return <Header style={headerStyles}>{props.children}</Header>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing['1'],
    paddingBottom: theme.spacing['4'],
  },
  headerXS: {
    paddingHorizontal: theme.spacing['4'],
  },
  headerSM: {
    paddingHorizontal: theme.spacing['6'],
  },
});

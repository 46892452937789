import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { BodyText, theme } from '@sg/garnish';

import type { DesktopHeaderBaseItemProps } from './DesktopHeader.types';

type DesktopHeaderBagItemProps = Readonly<{
  count: number;
  isOpen: boolean;
}> &
  DesktopHeaderBaseItemProps;

export const DesktopHeaderBagItem = ({
  count,
  isOpen,
  ...props
}: DesktopHeaderBagItemProps) => {
  return (
    <Pressable accessibilityRole="button" {...props} style={styles.container}>
      <BagIcon count={count} isHighlighted={isOpen} />
    </Pressable>
  );
};

type BagIconProps = Readonly<{
  count: number;
  isHighlighted: boolean;
}>;

const BagIcon = (props: BagIconProps) => (
  <View>
    <BagIconSvg {...props} />
    {props.count > 0 && <BagCountIndicator {...props} />}
  </View>
);

const BagIconSvg = ({ count, isHighlighted }: BagIconProps) => {
  const stroke = theme.colors.BLACK;
  // eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10
  const fill = isHighlighted
    ? theme.colors.BLACK
    : count > 0
      ? theme.colors.LIME
      : undefined;

  return (
    <Svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      focusable={false}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 9.33398V29.334H28V9.33398H4Z"
        fill={fill}
        stroke={stroke}
      />
      <Path
        d="M10 9.33268C10 8.66602 10 7.59935 10 5.99935C10 3.99935 13.3333 2.66602 16 2.66602C18.6667 2.66602 22 3.99935 22 5.99935C22 7.59935 22 8.66602 22 9.33268"
        stroke={stroke}
      />
    </Svg>
  );
};

const BagCountIndicator = ({ isHighlighted, count }: BagIconProps) => (
  <View pointerEvents="none" style={styles.countContainer}>
    <BodyText
      size={5}
      style={isHighlighted ? styles.countHighlighted : styles.countNormal}
    >
      {count}
    </BodyText>
  </View>
);

const styles = StyleSheet.create({
  container: {
    borderRadius: theme.radius.small,
    position: 'relative',
    width: 32,
    marginHorizontal: theme.spacing['1'],
  },

  countContainer: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing['2'],
  },
  countHighlighted: {
    color: theme.colors.WHITE,
  },
  countNormal: {
    color: theme.colors.BLACK,
  },
});

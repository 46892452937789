import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { Button, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { useApplePayErrorHandling, useApplePayPaymentMethod } from '../hooks';

/**
 * This is *not* the pay with Apple Pay button.
 * Instead it is a button that sets Apple Pay as the default payment method.
 */
export const ApplePaySetAsDefaultButton = (
  props: ApplePaySetAsDefaultButtonProps,
) => {
  const { onPress } = props;

  const handleApplePayError = useApplePayErrorHandling();

  // ─── Default Payment Method ──────────────────────────────────

  const {
    isUpdatingDefaultPaymentMethodId,
    updateDefaultPaymentMethodToApplePay,
  } = useApplePayPaymentMethod();

  const handleOnPress = useCallback(async () => {
    const response = await updateDefaultPaymentMethodToApplePay();

    if (response.error) {
      // @ts-expect-error TS(2349): This expression is not callable.
      handleApplePayError();

      return;
    }

    onPress?.();
  }, [updateDefaultPaymentMethodToApplePay, onPress, handleApplePayError]);

  // ─── Localization ────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const addApplePay = t('credit-card-form.add-apple-pay');

  return (
    <Button
      style={styles.applePayButton}
      palette="secondary"
      size="large"
      rightIcon="IconAppleBlack"
      isLoading={isUpdatingDefaultPaymentMethodId}
      onPress={handleOnPress}
    >
      {addApplePay}
    </Button>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  applePayButton: {
    marginHorizontal: theme.spacing['4'],
    marginBottom: theme.spacing['10'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ApplePaySetAsDefaultButtonProps = {
  onPress?: () => void;
};

import { useCallback } from 'react';

import type { UseNavigateToMenuProps } from '@order/hooks';
import { useCart, useNavigateToMenu } from '@order/hooks';

import {
  useConflictReview,
  useReorderState,
} from '../../../../screens/ReorderingScreen';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Navigate to restaurant and register last visited store.
 */
export const useNavigateToMenuFromLocation = () => {
  const navigateToMenu = useNavigateToMenu();
  const { currentStep } = useReorderState();
  const conflictReview = useConflictReview();
  const { isCartEmpty, cart } = useCart();

  const restaurantSlug = cart?.restaurant?.slug;
  const addressId = cart?.deliveryOrderDetail?.address?.id;

  const cartRestaurantSlug = isCartEmpty ? undefined : restaurantSlug;
  const cartDeliveryAddressId = isCartEmpty ? undefined : addressId;

  const isReordering = currentStep !== 'idle';

  // ─────────────────────────────────────────────────────────────────────

  return useCallback(
    (props: UseNavigateToMenuProps) => {
      // if you are already reordering then go to the next step in the flow.
      if (isReordering) {
        void conflictReview({
          orderType: props.orderType,
          deliveryAddressName: props.deliveryAddressName,
          deliveryOrderDetail: props.deliveryOrderDetail,
          restaurantId: props.restaurantId,
          restaurantSlug: props.restaurantSlug,
          restaurantName: props.restaurantName,
        });

        return;
      }

      const isSameAddressId =
        cartDeliveryAddressId === props.deliveryOrderDetail?.addressId;
      const hasBag =
        Boolean(cartRestaurantSlug) || Boolean(cartDeliveryAddressId);
      const needsToReorder = hasBag && !isSameAddressId;

      // if your bag belongs to a different restaurant, start reorder flow
      if (needsToReorder) {
        void conflictReview({
          orderId: cart?.id,
          orderType: props.orderType,
          deliveryAddressName: props.deliveryAddressName,
          deliveryOrderDetail: props.deliveryOrderDetail,
          restaurantId: props.restaurantId,
          restaurantSlug: props.restaurantSlug,
          restaurantName: props.restaurantName,
          finishOnMenu: true,
        });

        return;
      }

      navigateToMenu(props);
    },
    [
      isReordering,
      cartDeliveryAddressId,
      cartRestaurantSlug,
      navigateToMenu,
      conflictReview,
      cart?.id,
    ],
  );
};

import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardFloatingLabel = (
  props: LoyaltyOfferCardFloatingLabelProps,
) => {
  return (
    <View style={styles.container}>
      <BodyText sizeMatch={['14']} {...props} />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: theme.spacing['4'],
    right: 0,
    zIndex: 1,
    backgroundColor: theme.colors.SWEET_CORN,
    borderTopLeftRadius: theme.radius.small,
    borderBottomLeftRadius: theme.radius.small,
    paddingVertical: theme.spacing['2'],
    paddingHorizontal: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardFloatingLabelProps = ComponentProps<typeof BodyText>;

import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';
import { BodyText, useContainerSpacing, useResponsive } from '@sg/garnish';

import { type GiftCardCheckoutFormReturn } from '../../../../form';
import { giftCardCheckoutLedgerMessages as messages } from './GiftCardCheckoutLedger.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutLedger = (props: GiftCardCheckoutLedgerProps) => {
  const { form } = props;

  const { match } = useResponsive();
  const containerSpacing = useContainerSpacing();

  // ─── Derived Data ────────────────────────────────────────────────────

  const amountInDollars = (form.watch('amount') ?? 0) / 100;

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerHorizontalSpacing = match([
    containerSpacing,
    theme.spacing['6'],
  ]);

  const containerDynamicStyle = useStyle(
    () => ({ paddingHorizontal: containerHorizontalSpacing }),
    [containerHorizontalSpacing],
  );
  const containerStyles = [
    styles.container,
    match([styles.containerXS, styles.containerSM]),
    containerDynamicStyle,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles}>
      <View style={styles.labelContainer}>
        <BodyText bold sizeMatch={['18']}>
          <FormattedMessage {...messages.total} />
        </BodyText>
      </View>

      <View style={styles.amountContainer}>
        <BodyText bold sizeMatch={['18']}>
          <FormattedNumber
            value={amountInDollars}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency="USD"
          />
        </BodyText>
      </View>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: theme.spacing['2'],
  },
  containerXS: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
    paddingTop: theme.spacing['6'],
    paddingBottom: theme.spacing['12'],
  },
  containerSM: {
    paddingVertical: theme.spacing['10'],
  },
  labelContainer: {
    flex: 1,
  },
  amountContainer: {
    flex: 1,
    alignItems: 'flex-end',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutLedgerProps = {
  form: GiftCardCheckoutFormReturn;
};

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { TextLink, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const RequestAccountDeletionTrigger = (
  props: RequestAccountDeletionTriggerProps,
) => {
  const { isDisabled, onRequestAccountDeletion } = props;
  const { t } = useLocalizationContext();

  return (
    <View style={styles.triggerContainer}>
      <TextLink
        testID="account.delete.trigger"
        palette="caution"
        disabled={isDisabled}
        onPress={onRequestAccountDeletion}
      >
        {t('account.delete.trigger')}
      </TextLink>
    </View>
  );
};

type RequestAccountDeletionTriggerProps = Readonly<{
  onRequestAccountDeletion: () => void;
  isDisabled?: boolean;
}>;

const styles = StyleSheet.create({
  triggerContainer: {
    alignItems: 'center',
    marginTop: theme.spacing['10'],
  },
});

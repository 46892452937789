import { addMilliseconds } from 'date-fns';

import { CONTENTFUL_CACHE_STORAGE_KEY } from '../../contentful-content-type-entries-machine.constants';
import type {
  CacheEntry,
  ContentfulContentTypeEntries,
} from '../../contentful-content-type-entries-machine.types';
import { setItem } from '../cache';

/**
 * Writes given data to the cache using the provided entry ID and ttl.
 */
export async function writeToCacheOrFail<Fields>(
  params: WriteToCacheParams<Fields>,
) {
  const { data = [], queryHash, ttl = 0 } = params;

  if (!queryHash) {
    throw new Error('The query hash is either missing or invalid.');
  }

  const timestamp = new Date();
  const expirationDate = addMilliseconds(timestamp, ttl).toISOString();

  const cacheEntryId = `${CONTENTFUL_CACHE_STORAGE_KEY}.${queryHash}`;
  const cacheEntry: CacheEntry<Fields> = { data, expirationDate };
  const cacheEntryStringified = JSON.stringify(cacheEntry);

  return setItem(cacheEntryId, cacheEntryStringified);
}

// ─── Types ───────────────────────────────────────────────────────────────────

type WriteToCacheParams<Fields> = Readonly<{
  data: ContentfulContentTypeEntries<Fields> | undefined;
  ttl: number | undefined;
  queryHash: string | undefined;
}>;

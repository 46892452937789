import { useMemo } from 'react';

import type { useSweetpassLandingPageContent } from '@order/components';
import { useLocalizationContext } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A small hook that returns fallback content for `SweetpassHomeScreen` to be
 * utilized when the main remote content is unavailable for any reason,
 * or during e2e tests.
 */
export const useSweetpassHomeScreenContentFallback = (
  params: UseSweetpassHomeScreenContentFallbackParams,
) => {
  const { onCtaPress } = params;
  const { t } = useLocalizationContext();

  // ─────────────────────────────────────────────────────────────────────

  return useMemo<SweetpassLandingPageContentFallback>(
    () => ({
      // @ts-expect-error TS(2741): Property 'titleXs' is missing in type '{ title: st... Remove this comment to see the full error message
      header: {
        title: t('sweetpass.home.signed-out.header.title'),
        text: t('sweetpass.home.signed-out.header.text'),
        palette: 'kale',
        ctaLabel: t('sweetpass.home.signed-out.cta.label'),
        onCtaPress,
      },
      benefits: {
        benefits: [
          {
            id: 'benefit-rewards',
            title: t('sweetpass.home.signed-out.benefits.rewards.title'),
            text: t('sweetpass.home.signed-out.benefits.rewards.text'),
          },
          {
            id: 'benefit-birthday-gift',
            title: t('sweetpass.home.signed-out.benefits.birthday-gift.title'),
            text: t('sweetpass.home.signed-out.benefits.birthday-gift.text'),
          },
          {
            id: 'benefit-bowl-drops',
            title: t('sweetpass.home.signed-out.benefits.bowl-drops.title'),
            text: t('sweetpass.home.signed-out.benefits.bowl-drops.text'),
          },
          {
            id: 'benefit-merch-drops',
            title: t('sweetpass.home.signed-out.benefits.merch-drops.title'),
            text: t('sweetpass.home.signed-out.benefits.merch-drops.text'),
          },
        ],
        itemsPerRow: 3,
      },
      cta: {
        action: 'join_sweetpass',
        palette: 'lime',
        label: t('sweetpass.home.signed-out.cta.label'),
        onPress: onCtaPress,
      },
    }),
    [onCtaPress, t],
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseSweetpassHomeScreenContentFallbackParams = Readonly<{
  onCtaPress: () => void;
}>;

type SweetpassLandingPageContentFallback = Parameters<
  typeof useSweetpassLandingPageContent
>[0]['contentFallback'];

import type { Ref } from 'react';
import React, { forwardRef, useCallback } from 'react';
import type { Text } from 'react-native';
import { Pressable, StyleSheet } from 'react-native';

import { useFluidSize } from '../../../hooks';
import type { LayoutMeasure, TabNavItemProps } from '../TabNav.types';
import { TabText } from './Text';

export const Item = forwardRef((props: TabNavItemProps, ref: Ref<Text>) => {
  const {
    children,
    onPress,
    active,
    index,
    onChangeMeasures,
    accessibilityHint,
    accessibilityLabel,
    testID,
  } = props;

  const handleChangeMeasure = useCallback(
    (measures: LayoutMeasure) => {
      if (onChangeMeasures) {
        onChangeMeasures(measures, index ?? 0);
      }
    },
    [onChangeMeasures, index],
  );

  const fluid = useFluidSize();

  const style = React.useMemo(
    () => ({ paddingVertical: fluid(8, 4) }),
    [fluid],
  );

  return (
    <Pressable
      accessibilityRole="button"
      onPress={onPress}
      style={[style, styles.pressable]}
      accessibilityHint={accessibilityHint}
      accessibilityLabel={accessibilityLabel}
      testID={testID}
    >
      <TabText onChangeMeasures={handleChangeMeasure} ref={ref} active={active}>
        {children}
      </TabText>
    </Pressable>
  );
});

const styles = StyleSheet.create({
  pressable: {
    paddingVertical: 12,
  },
});

import { useCallback, useEffect, useState } from 'react';
import type { NativeStackNavigationOptions } from '@react-navigation/native-stack';

import { getAppNavigationContainerRef } from '../../navigation';

/**
 * Returns the active screen navigation options.
 */
export const useActiveScreenOptions = () => {
  const { current: appNavigation } = getAppNavigationContainerRef();

  const [screenOptions, setScreenOptions] = useState<ScreenOptions>({});

  // ─── HELPERS ─────────────────────────────────────────────────────

  const setActiveScreenOptions = useCallback(() => {
    if (!appNavigation) return {};

    setScreenOptions(appNavigation.getCurrentOptions() as ScreenOptions);
  }, [appNavigation]);

  // ─── EFFECTS ─────────────────────────────────────────────────────

  // sets the corresponding top offset on the initial mount and screen change (SM+)
  useEffect(() => {
    if (!appNavigation) return;

    setActiveScreenOptions();
    appNavigation?.addListener('state', setActiveScreenOptions);
    appNavigation?.addListener('options', setActiveScreenOptions);

    return () => {
      appNavigation?.removeListener('state', setActiveScreenOptions);
      appNavigation?.removeListener('options', setActiveScreenOptions);
    };
  }, [appNavigation, setActiveScreenOptions]);

  // ─────────────────────────────────────────────────────────────────

  return screenOptions;
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ScreenOptions = Partial<NativeStackNavigationOptions> &
  Record<string, unknown>;

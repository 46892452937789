import React, { useCallback, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import type { GooglePlacesAddress } from '@sg/garnish';
import { Form } from '@sg/garnish';
import { theme, useNoticeBannersStackContext } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { useAddressesTelemetry } from './AddressForm.telemetry';
import type { AddressField, FormProps } from './AddressForm.types';
import {
  AddressType,
  CustomNameField,
  DeliveryNotes,
  DeliveryPreference,
  Footer,
} from './components/AddressForm.components';
import { AddressFormPredictions } from './components/AddressForm.components.predictions';
import {
  useAddressDuplicateByLocation,
  useAddressDuplicateByName,
  useAddressFormState,
} from './hooks';

export const AddressForm = (props: FormProps) => {
  const {
    address,
    visible,
    editing,
    loading,
    deleting,
    disableAddress,
    submitCta,
    editingAddressId,
    onDelete,
    onEdit,
    onSave,
  } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { t } = useLocalizationContext();
  const duplicateAddressLocationNotice = t('addresses.error.address.duplicate');

  // ─── State ───────────────────────────────────────────────────────────

  const [placeId, setPlaceId] = useState('');
  const form = useAddressFormState(address);
  const formValues = form.watch();
  const { addressType, name = '', notes, zipCode } = formValues;

  // ─── Form Validation ─────────────────────────────────────────────────────

  const isFormChanged = Boolean(form.formState.isDirty);
  const isCustomNameTouched = Boolean(form.formState.dirtyFields.name);
  const isAddressTouched = Boolean(form.formState.dirtyFields.street);
  const isValidStreet =
    Boolean(placeId) || (!isAddressTouched && Boolean(address?.id));
  const hasValidAddressName = addressType !== 'custom' || Boolean(name);
  const isZipCodeMissing = !zipCode;

  // ─── Address Duplication Checks ──────────────────────────────────────────

  const isAddressDuplicateByLocation = Boolean(
    useAddressDuplicateByLocation({
      addressId: editingAddressId ?? address?.id,
      addressForm: formValues,
    }),
  );

  const {
    hasHomeAddress,
    hasWorkAddress,
    isCustomNameInvalid,
    customNameNotice,
  } = useAddressDuplicateByName({
    addressId: editingAddressId ?? address?.id,
    addressName: name,
    isCustomNameTouched,
  });

  // ─── Telemetry ──────────────────────────────────────────────────

  const { monitoredOnSave, monitoredOnEdit, monitoredOnDelete } =
    useAddressesTelemetry({
      editing,
      onDelete,
      onEdit,
      onSave,
    });

  // ─── Callbacks ──────────────────────────────────────────────────

  const updateField = useCallback(
    (field: AddressField) => (value: string | number | undefined) => {
      form.setValue(field, value ?? '', { shouldDirty: true });
    },
    [form],
  );

  const submitForm = useCallback(() => {
    (() => {
      if (isAddressDuplicateByLocation) {
        addNoticeBanner({
          text: duplicateAddressLocationNotice,
          palette: 'caution',
        });

        return;
      }

      if (editing) {
        monitoredOnEdit(formValues);

        return;
      }

      monitoredOnSave(formValues);
    })();
  }, [
    editing,
    formValues,
    isAddressDuplicateByLocation,
    duplicateAddressLocationNotice,
    addNoticeBanner,
    monitoredOnEdit,
    monitoredOnSave,
  ]);

  // ─── Submit Control ─────────────────────────────────────────────

  const preventSubmittingForUnchangedForm =
    Boolean(address?.name) && !isFormChanged;
  const preventSubmittingForInvalidFields =
    isCustomNameInvalid || isZipCodeMissing;
  const canSubmitAfterValidation =
    Boolean(addressType) &&
    isValidStreet &&
    hasValidAddressName &&
    !preventSubmittingForUnchangedForm &&
    !preventSubmittingForInvalidFields;

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.content}>
        <AddressFormPredictions
          placeId={placeId}
          address={address as GooglePlacesAddress}
          visible={visible}
          disabled={disableAddress}
          setPlaceId={setPlaceId}
          setValue={form.setValue}
          accessibilityLabel={t('addresses.street.a11y')}
        />

        <Form.TextField
          name="secondaryStreet"
          control={form.control}
          testID="account.address-form.secondary-street"
          accessibilityLabel={t('addresses.secondary-street.a11y')}
          label={t('addresses.secondary-street.placeholder')}
        />

        <DeliveryPreference form={formValues} updateField={updateField} />

        <DeliveryNotes form={form} notes={notes} maxLength={128} />

        <AddressType
          addressType={addressType}
          hasHomeAddress={hasHomeAddress}
          hasWorkAddress={hasWorkAddress}
          updateField={updateField}
          setAddressType={updateField('addressType')}
        />

        {addressType === 'custom' && (
          <CustomNameField
            name={name}
            customNameNotice={customNameNotice}
            isCustomNameInvalid={isCustomNameInvalid}
            updateField={updateField}
          />
        )}
      </ScrollView>

      <Footer
        submitCta={submitCta}
        editing={editing}
        deleting={deleting}
        loading={loading}
        canSubmit={canSubmitAfterValidation}
        submitForm={submitForm}
        onDelete={monitoredOnDelete}
      />
    </View>
  );
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing['2'],
    paddingBottom: theme.spacing['6'],
    paddingHorizontal: theme.spacing['6'],
  },
});

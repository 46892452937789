import { CONTENTFUL_CACHE_STORAGE_KEY } from '../../contentful-content-type-entries-machine.constants';
import type {
  CacheEntry,
  ContentfulContentTypeEntries,
} from '../../contentful-content-type-entries-machine.types';
import { checkIfCacheEntryIsValid } from '../../utils';
import { getItem } from '../cache';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * An async service tries to read the cached data of the Contentful content
 * type entry.
 */
export async function readFromCacheOrFail<Fields>(
  cacheEntryId = '',
): Promise<ContentfulContentTypeEntries<Fields>> {
  const entry = await getCacheEntry<Fields>(cacheEntryId);
  const cacheEntry = entry as CacheEntry<Fields>;
  const isValidCacheEntry = checkIfCacheEntryIsValid<Fields>(cacheEntry);

  if (!isValidCacheEntry) {
    throw new Error('Cached data is not valid or expired');
  }

  return cacheEntry.data;
}

// ─── Utils ───────────────────────────────────────────────────────────────────

/**
 * Gets a cache entry based on the provided query hash.
 */
async function getCacheEntry<Fields>(
  queryHash: string,
): Promise<Partial<CacheEntry<Fields>>> {
  const cacheEntryId = `${CONTENTFUL_CACHE_STORAGE_KEY}.${queryHash}`;
  const cacheEntry = await getItem(cacheEntryId);

  return JSON.parse(cacheEntry ?? '{}');
}

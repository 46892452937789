import { defineMessages } from 'react-intl';

export const giftCardsStackScreenMessages = defineMessages({
  screenTitle: {
    defaultMessage: 'Gift',
    description: 'Gift Cards Screen | Title',
  },
  screenCustomDocumentTitle: {
    defaultMessage: 'Gift Cards | sweetgreen',
    description: 'Gift Cards Screen | Custom Document Title',
  },
});

import type { ComponentProps } from 'react';
import React from 'react';

import { useFeatureFlag } from '@order/LaunchDarkly';

import { ChallengeCelebrationLegacyModal } from './ChallengeCelebrationLegacyModal';
import { ChallengeCompletionModal } from './ChallengeCompletionModal';

// ─────────────────────────────────────────────────────────────────────────────

export const ChallengeCompletionModalWithFF = (
  props: ComponentProps<typeof ChallengeCompletionModal>,
) => {
  const isNewChallengesCompletionModalEnabled = useFeatureFlag(
    'CELS-308-short-new-challenges-completion-modal-enabled',
  );

  if (isNewChallengesCompletionModalEnabled) {
    return (
      <ChallengeCompletionModal
        challengeCompletionDetails={props.challengeCompletionDetails}
      />
    );
  }

  return (
    <ChallengeCelebrationLegacyModal
      challengeCompletionDetails={props.challengeCompletionDetails}
    />
  );
};

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { FadeView } from '../../../../../FadeView';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders a radio button icon/indicator utilizing animated `View` components.
 */
export const RadioContainerItemIcon = (props: RadioContainerItemIconProps) => {
  const { isActive, isDisabled } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const outerContainerStyles = [
    styles.iconOuter,
    isDisabled ? styles.iconOuterDisabled : undefined,
  ];
  const innerContainerStyles = [
    styles.iconInner,
    isDisabled ? styles.iconInnerDisabled : undefined,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View accessibilityRole="image" style={outerContainerStyles}>
      <FadeView show={isActive} style={innerContainerStyles} />
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const ICON_SIZE = 24;
const ICON_INNER_CONTAINER_SIZE = ICON_SIZE / 2;
const ICON_COLOR = theme.colors.KALE;
const ICON_COLOR_DISABLED = theme.colors.GRAY;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  iconOuter: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: ICON_COLOR,
    borderRadius: theme.radius.xlarge,
  },
  iconOuterDisabled: {
    borderColor: ICON_COLOR_DISABLED,
  },
  iconInner: {
    width: ICON_INNER_CONTAINER_SIZE,
    height: ICON_INNER_CONTAINER_SIZE,
    borderRadius: ICON_INNER_CONTAINER_SIZE,
    backgroundColor: ICON_COLOR,
  },
  iconInnerDisabled: {
    backgroundColor: ICON_COLOR_DISABLED,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type RadioContainerItemIconProps = Readonly<{
  isActive: boolean;
  isDisabled?: boolean;
}>;

import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive } from '@sg/garnish';

// ────────────────────────────────────────────────────────────────────────────────

export const BagContentBlock = (props: ViewProps) => {
  const { children, style, ...restProps } = props;

  const { match } = useResponsive();
  const blockStyle = [style, match(blockResponsiveStyle)];

  return (
    <View style={blockStyle} {...restProps}>
      {children}
    </View>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  blockXS: {
    paddingHorizontal: theme.spacing['4'],
  },
  blockSM: {
    paddingHorizontal: theme.spacing['6'],
  },
});

const blockResponsiveStyle = [styles.blockXS, styles.blockSM];

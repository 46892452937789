import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

export function useCreditCardForm(props: UseCreditCardFormProps) {
  const form = useForm<CreditCardFormData>({
    defaultValues: {
      nickname: props.nickname ?? '',
      default: props.default ?? false,
    },
  });

  const formData = form.watch();

  const updateForm = useCallback(
    (payload: CreditCardFormData) => {
      form.setValue('nickname', payload.nickname, { shouldDirty: true });
      form.setValue('default', payload.default, { shouldDirty: true });
    },
    [form],
  );

  return { form, formData, updateForm };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type CreditCardFormData = Readonly<{
  nickname: string;
  default: boolean;
}>;

type UseCreditCardFormProps = Readonly<{
  nickname?: string | null;
  default?: boolean | null;
}>;

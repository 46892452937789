import React from 'react';
import type { AddressType } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { FeaturedChannel } from '../FeaturedChannel';

export const PrimaryChannel = (props: PrimaryChannelProps) => {
  const { labelCopy, buttonCopy, a11yCopy } = usePrimaryCopy(props.orderType);

  return (
    <FeaturedChannel
      theme="cucumber"
      label={labelCopy}
      title={props.name}
      action={buttonCopy}
      accessibilityLabel={a11yCopy}
      isLoading={props.isLoading}
      onPress={props.onOrder}
    />
  );
};

// ────────────────── Hooks ──────────────────

const usePrimaryCopy = (orderType: AddressType) => {
  const { t } = useLocalizationContext();

  return {
    labelCopy: t('featured-channel.primary.label'),
    buttonCopy: t('featured-channel.primary.button', { orderType }),
    a11yCopy: t('featured-channel.primary.a11y', { orderType }),
  };
};

// ────────────────── Types ──────────────────

type PrimaryChannelProps = Readonly<{
  orderType: AddressType;
  name: string;
  isLoading: boolean;
  onOrder: () => void;
}>;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, theme } from '@sg/garnish';
import {
  type Ingredient,
  type LineItem,
  type Product,
} from '@sg/graphql-schema';

import {
  useLineItemDescription,
  useLineItemIngredients,
  useLineItemName,
} from '@order/shared/hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainConflictReviewLineItemDetails = (
  props: OrderAgainConflictReviewLineItemDetailsProps,
) => {
  const { lineItem } = props;

  // ─── Formatted Data ──────────────────────────────────────────────────

  const lineItemName = useLineItemName(lineItem.product, lineItem);
  const lineItemDescription = useLineItemDescription(lineItem);
  const { addedIngredientsText, removedIngredientsText } =
    useLineItemIngredients(lineItem);

  return (
    <View style={styles.container}>
      <BodyText sizeMatch={['18']} style={styles.name}>
        {lineItemName}
      </BodyText>

      {addedIngredientsText ? (
        <BodyText sizeMatch={['14']}>{addedIngredientsText}</BodyText>
      ) : null}

      {removedIngredientsText ? (
        <BodyText sizeMatch={['14']}>{removedIngredientsText}</BodyText>
      ) : null}

      {lineItemDescription ? (
        <BodyText sizeMatch={['14']}>{lineItemDescription}</BodyText>
      ) : null}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    marginBottom: theme.spacing['10'],
  },
  name: {
    marginBottom: theme.spacing['1'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderAgainConflictReviewLineItemDetailsProps = {
  lineItem: {
    id: LineItem['id'];
    product: Partial<Omit<Product, 'ingredients'>> &
      Readonly<{
        ingredients?: ReadonlyArray<Pick<Ingredient, 'id' | 'kind' | 'name'>>;
      }>;
    customName?: LineItem['customName'];
    dressingMode?: LineItem['dressingMode'];
    mixedDressingDetails?: LineItem['mixedDressingDetails'];
    addedIngredients?: ReadonlyArray<
      Pick<LineItem['addedIngredients'][number], 'id' | 'name' | 'kind'>
    >;
    removedIngredients?: ReadonlyArray<
      Pick<LineItem['removedIngredients'][number], 'id' | 'name' | 'kind'>
    >;
  };
};

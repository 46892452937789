import { useEffect } from 'react';

import { useIsLoggedIn, useIsLoggingIn } from '@order/AuthMachine';

import { useCart } from '../useCart';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * Handles initial cart fetching (and re-fetching) based on user's logged-in/logged-out status.
 */
export const useFetchUserCart = () => {
  const { fetchCart } = useCart();
  const isLoggingIn = useIsLoggingIn();
  const isLoggedIn = useIsLoggedIn();

  const shouldFetchCartForLoggedInUser = !isLoggingIn && isLoggedIn;
  const shouldFetchCartForGuestUser = !isLoggingIn && !isLoggedIn;

  // ─────────────────────────────────────────────────────────────────

  useEffect(() => {
    if (shouldFetchCartForLoggedInUser) fetchCart();
  }, [fetchCart, shouldFetchCartForLoggedInUser]);

  useEffect(() => {
    if (shouldFetchCartForGuestUser) fetchCart();
  }, [fetchCart, shouldFetchCartForGuestUser]);
};

import { type TextStyle } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const paletteStyles: ButtonFluidLabelPaletteStyles = {
  lime: {
    color: theme.colors.LIME,
  },
  disabled: {
    color: theme.colors.OPACITY.DARK_KALE.DARK,
  },
};

// ─── Types ───────────────────────────────────────────────────────────────────

export type ButtonFluidLabelPalette = 'lime';

type ButtonFluidLabelPaletteStyles = Record<
  ButtonFluidLabelPalette | 'disabled',
  TextStyle
>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BagTelemetryQueryVariables = Types.Exact<{ [key: string]: never }>;

export type BagTelemetryQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename?: 'Customer';
    readonly id: string;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly phoneNumber: string | null;
    readonly email: string | null;
  } | null;
  readonly orders:
    | {
        readonly __typename: 'OrdersResponseSuccess';
        readonly orders: ReadonlyArray<{
          readonly __typename?: 'Order';
          readonly id: string;
        }>;
      }
    | { readonly __typename: 'ValidationError' };
};

export const BagTelemetryDocument = gql`
  query BagTelemetry {
    currentCustomer {
      id
      firstName
      lastName
      phoneNumber
      email
    }
    orders(input: { page: 1, take: 1 }) {
      __typename
      ... on OrdersResponseSuccess {
        orders {
          id
        }
      }
    }
  }
`;

export function useBagTelemetryQuery(
  options?: Omit<Urql.UseQueryArgs<BagTelemetryQueryVariables>, 'query'>,
) {
  return Urql.useQuery<BagTelemetryQuery, BagTelemetryQueryVariables>({
    query: BagTelemetryDocument,
    ...options,
  });
}

import type { ComponentProps } from 'react';
import React, { useRef } from 'react';
import type { AccessibilityProps, TouchableOpacityProps } from 'react-native';
import {
  ImageBackground,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../hooks';
import { webOnlyStyles } from '../../utils';
import type { HighlightWords } from '../Text';
import { IllusUpsellCtaBg } from './assets';
import { UpsellCtaContent, UpsellCtaContentLoading } from './components';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders Upsell CTA component with the provided text properties.
 */
export const UpsellCta = (props: UpsellCtaProps) => {
  const {
    isLoading,
    text,
    textHighlights,
    iconText,
    actionText,
    accessibilityLabel,
    accessibilityHint,
    onPress,
    testID,
  } = props;

  const containerRef = useRef(null);
  const { isHovered } = usePressableState(containerRef);

  // ─── Styles ──────────────────────────────────

  const shouldShowHoverOverlay = !isLoading && isHovered;

  const hoverOverlayStyles = [
    styles.hoverOverlay,
    shouldShowHoverOverlay ? styles.hoverOverlayActive : undefined,
    webOnlyStyles({
      transition: `background-color ${theme.transitions.base}ms`,
    }),
  ];

  // ─────────────────────────────────────────────

  return (
    <TouchableOpacity
      ref={containerRef}
      accessibilityRole="button"
      style={styles.container}
      activeOpacity={0.7}
      onPress={isLoading ? undefined : onPress}
      disabled={isLoading}
      accessibilityLabel={accessibilityLabel}
      accessibilityHint={accessibilityHint}
      accessibilityState={{ busy: isLoading }}
      testID={testID}
    >
      <ImageBackground
        source={IllusUpsellCtaBg}
        resizeMode="stretch"
        style={styles.contentContainer}
      >
        <View style={hoverOverlayStyles} pointerEvents="none" />

        {isLoading ? (
          <UpsellCtaContentLoading />
        ) : (
          <UpsellCtaContent
            text={text}
            textHighlights={textHighlights}
            iconText={iconText}
            actionText={actionText}
          />
        )}
      </ImageBackground>
    </TouchableOpacity>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const UPSELL_CTA_BORDER_RADIUS = theme.radius.large;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: UPSELL_CTA_BORDER_RADIUS,
    borderWidth: 1,
    borderColor: theme.colors.KALE,
    backgroundColor: theme.colors.OPACITY.OATMEAL.DARK,
  },
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: UPSELL_CTA_BORDER_RADIUS,
    padding: theme.spacing['4'],
  },
  hoverOverlay: {
    ...StyleSheet.absoluteFillObject,
    borderRadius: UPSELL_CTA_BORDER_RADIUS,
  },
  hoverOverlayActive: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type UpsellCtaProps = Readonly<{
  text: string;
  textHighlights?: ComponentProps<typeof HighlightWords>['highlights'];
  iconText?: string;
  actionText?: string;
  accessibilityLabel?: AccessibilityProps['accessibilityLabel'];
  accessibilityHint?: AccessibilityProps['accessibilityHint'];
  testID?: string;
  isLoading?: boolean;
  onPress?: TouchableOpacityProps['onPress'];
}>;

import React, { useRef } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import {
  theme,
  TitleText,
  usePressableState,
  webOnlyStyles,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

// ────────────────────────────────────────────────────────────────────────────────

export const MenuHeroLocation = (props: MenuHeroRestaurantNameProps) => {
  const { location, goToLocations } = props;

  const { t } = useLocalizationContext();

  const ref = useRef(null);
  const { isHovered } = usePressableState(ref);

  // ─── STYLES ─────────────────────────────────────────────────────────

  const locationStyles = [
    styles.locationLink,
    isHovered && styles.locationLinkHover,
    webOnlyStyles({
      textDecorationThickness: '2px',
      transition:
        'color 0.24s ease-in-out, text-decoration-color 0.24s ease-in-out',
    }),
  ];

  // ────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      accessibilityRole="button"
      accessibilityLabel={t('menu.hero.change-location-label')}
      testID="menu.hero.change-location-label"
      onPress={goToLocations}
      ref={ref}
    >
      <TitleText
        size={3}
        style={locationStyles}
        testID="menu.hero.location-name"
      >
        {location}
      </TitleText>
    </Pressable>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type MenuHeroRestaurantNameProps = Readonly<{
  location: string;
  goToLocations: () => void;
}>;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  locationLink: {
    color: theme.colors.GREEN_2,
    textDecorationColor: theme.colors.GREEN_2,
    textDecorationLine: 'underline',
  },
  locationLinkHover: {
    color: theme.colors.GREEN_3,
    textDecorationColor: theme.colors.GREEN_3,
  },
});

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BodyText } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const BagRewardsEmptyState = () => {
  return (
    <BodyText sizeMatch={['14']}>
      <FormattedMessage
        defaultMessage="No rewards are available at the moment."
        description="Bag | Rewards | Empty State"
      />
    </BodyText>
  );
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { A } from '@expo/html-elements';
import View from '@expo/html-elements/build/primitives/View';
import { theme } from '@garnish/constants';
import { BodyText, TextLink } from '@sg/garnish';

import { SG_SUPPORT_EMAIL } from '@order/constants';

import { JOIN_OR_SIGN_IN_SCREEN_BODY_MAX_FONT_SIZE_MULTIPLIER } from '../../JoinOrSignInScreen.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const JoinOrSignInVerificationStepFooter = (
  props: JoinOrSignInVerificationStepFooterProps,
) => {
  const { isLoading, resendCode } = props;

  const maxFontSizeMultiplier =
    JOIN_OR_SIGN_IN_SCREEN_BODY_MAX_FONT_SIZE_MULTIPLIER;

  return (
    <>
      <View style={styles.footerRow}>
        <BodyText
          sizeMatch={['14']}
          maxFontSizeMultiplier={maxFontSizeMultiplier}
        >
          <FormattedMessage
            description="Join or Sign In | Don't see an email text"
            defaultMessage="Don’t see an email?"
          />
        </BodyText>

        <TextLink
          sizeMatch={['14']}
          disabled={isLoading}
          onPress={resendCode}
          maxFontSizeMultiplier={maxFontSizeMultiplier}
        >
          <FormattedMessage
            description="Join or Sign In | Resend"
            defaultMessage="Resend"
          />
        </TextLink>
      </View>

      <View style={styles.footerRow}>
        <BodyText
          sizeMatch={['14']}
          maxFontSizeMultiplier={maxFontSizeMultiplier}
        >
          <FormattedMessage
            description="Join or Sign In | Contact us at text"
            defaultMessage="Having trouble? Contact us at"
          />
        </BodyText>

        <A href={`mailto:${SG_SUPPORT_EMAIL}`}>
          <BodyText
            underline
            sizeMatch={['14']}
            maxFontSizeMultiplier={maxFontSizeMultiplier}
          >
            {SG_SUPPORT_EMAIL}
          </BodyText>
        </A>
      </View>
    </>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  footerRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing['1'],
    marginBottom: theme.spacing['2'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type JoinOrSignInVerificationStepFooterProps = {
  isLoading: boolean;
  resendCode: () => void;
};

/* eslint-disable react-native/no-unused-styles */

import type { ComponentProps, ReactNode } from 'react';
import React from 'react';
import type { TextStyle } from 'react-native';
import { StyleSheet } from 'react-native';
import { BodyText, TextLink, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const BagRailInfoPanelText = (props: BagRailInfoPanelTextProps) => {
  const { children, palette = 'primary' } = props;

  const styles = paletteStyles[palette];

  return (
    <BodyText size={4} style={styles}>
      {children}
    </BodyText>
  );
};

export const BagRailInfoPanelTextLink = (
  props: BagRailInfoPanelTextLinkProps,
) => {
  const { children, onPress } = props;

  return (
    <TextLink size={4} onPress={onPress}>
      {children}
    </TextLink>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const paletteStyles = StyleSheet.create<
  Record<BagRailInfoPanelTextPalette, TextStyle>
>({
  primary: {
    color: theme.colors.DARK_KALE,
  },
  secondary: {
    color: theme.colors.OPACITY.DARK_KALE.DARKER,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BagRailInfoPanelTextProps = Readonly<{
  children: ReactNode;
  palette?: BagRailInfoPanelTextPalette;
}>;
type BagRailInfoPanelTextLinkProps = Readonly<{
  children: ReactNode;
  onPress: ComponentProps<typeof TextLink>['onPress'];
}>;

type BagRailInfoPanelTextPalette = 'primary' | 'secondary';

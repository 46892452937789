export const challenges = {
  // ─── Signed Out ──────────────────────────────────────────────────────

  'challenges.sign-out.text':
    'Sign in to see all your challenges and get special rewards ',
  'challenges.sign-out.sign-in-or-join': 'Sign in or join',

  // ─── Signed In ───────────────────────────────────────────────────────

  'challenges.signed-in.header': 'Rewards & Challenges',

  // ─── Rewards ─────────────────────────────────────────────────────────

  'challenges.rewards.modal.text':
    'After you start your order, you will be able to apply this reward at the register. Please make sure you tell the team member you want to apply a reward before proceeding to checkout.',
  'challenges.rewards.modal.terms':
    'Additional terms and conditions apply to the use of such reward.',
  'challenges.rewards.description.a11y-title': 'Reward description',
  'challenges.rewards.celebration.a11y-title': 'New reward',
  'challenges.rewards.see-my-rewards': 'See my rewards',
  'challenges.rewards.use-again': 'Use again {distanceToDate}',
  'challenges.rewards.premium-label': 'Sweetpass+ Benefit',
  'challenges.rewards.deadline-prefix': 'Ends',
  'challenges.rewards.sweetpass-deadline-prefix': 'Use by',
  'challenges.rewards.tag': `{redemptionChannel, select,
    delivery {Delivery only}
    instore {In-store only}
    outpost {Outpost only}
    pickup {Pickup only}
    other {}
  }`,

  'challenges.opt-in.success': 'You have successfully joined the challenge!',
  'challenges.opt-in.not-found':
    'The challenge you are trying to join does not exist.',
  'challenges.opt-in.generic-error':
    'The challenge you are trying to join was not found.',

  // ─── Reward Errors ───────────────────────────────────────────────────

  'challenges.rewards.error.not-applicable':
    'This order might not meet the conditions of the reward selected, so it will not be applied at checkout.',
  'challenges.rewards.error.loyalty-program-unavailable':
    "We're experiencing issues with our rewards program at the moment. Apologies for the inconvenience.",
  'challenges.rewards.error.loyalty-not-processed':
    "We're experiencing issues with our rewards program at the moment. Apologies for the inconvenience.",
  'challenges.rewards.error.expired':
    'Your selected reward has expired and will not be applied at checkout.',
  'challenges.rewards.error.not-found':
    'We were unable to apply your selected reward, so it will not be applied at checkout.',
  'challenges.rewards.error.restaurant-not-accepting-orders':
    'Online ordering is currently unavailable at this location.',
};

import React, { useCallback, useRef } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import * as Clipboard from 'expo-clipboard';
import {
  BodyText,
  Icon,
  theme,
  useNoticeBannersStackContext,
  useUniqueNativeID,
  webOnlyStyles,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardRedemptionNumberAndCodeCopyButton = (
  props: GiftCardRedemptionNumberAndCodeCopyButtonProps,
) => {
  const { text, 'aria-label': a11yLabel, bannerText } = props;

  const { push: addBanner } = useNoticeBannersStackContext();

  // ─────────────────────────────────────────────────────────────────────

  const bannerId = useUniqueNativeID();

  const pressableRef = useRef<TouchableOpacity>(null);
  const isPressableHovered = useHover(pressableRef);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const copyToClipboard = useCallback(async () => {
    const hasCopied = await Clipboard.setStringAsync(text);

    if (!hasCopied) return;

    addBanner(
      {
        id: bannerId,
        palette: 'success',
        text: bannerText,
        autoHideTimeout: 2000,
      },
      true,
    );
  }, [addBanner, bannerId, bannerText, text]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const pressableStyles = [
    styles.pressable,
    pressableWebStyles,
    isPressableHovered ? styles.pressableHover : undefined,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <TouchableOpacity
      ref={pressableRef}
      accessibilityRole="button"
      activeOpacity={0.7}
      style={pressableStyles}
      onPress={copyToClipboard}
      aria-label={a11yLabel}
    >
      <BodyText sizeMatch={['14']} numberOfLines={1} style={styles.text}>
        {text}
      </BodyText>

      <Icon
        name="IconCopy"
        color={theme.colors.KALE}
        width={16}
        height={16}
        style={styles.icon}
      />
    </TouchableOpacity>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing['1'],
    borderRadius: theme.radius.medium,
    padding: theme.spacing['1'],
    alignSelf: 'flex-start',
    maxWidth: '100%',
  },
  pressableHover: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
  text: {
    color: theme.colors.KALE,
  },
  icon: {
    flexShrink: 0,
  },
});

const pressableWebStyles = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: theme.spacing['1'],
  outlineWidth: 2,
  transition: `background-color ${theme.transitions.base}ms`,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardRedemptionNumberAndCodeCopyButtonProps = {
  text: string;
  bannerText: string;
  'aria-label': string;
};

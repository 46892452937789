import React from 'react';
import type { ViewProps } from 'react-native';
import { Pressable, StyleSheet, View } from 'react-native';
import type { IconName } from '@sg/garnish';
import { Icon, ListAction, theme, useResponsive } from '@sg/garnish';

export type CreditMenuProps = Readonly<{
  iconName: IconName;
  title?: string | null;
  subtitle?: string | null;
  onPress: () => void;
}> &
  ViewProps;

export const CreditMenuItem = ({
  testID,
  iconName,
  title,
  subtitle,
  onPress,
  accessibilityLabel,
  accessibilityHint,
}: CreditMenuProps) => {
  const { currentBreakpoint } = useResponsive();

  return (
    <Pressable
      testID={testID}
      accessibilityRole="link"
      accessibilityLabel={accessibilityLabel}
      accessibilityHint={accessibilityHint}
      onPress={onPress}
    >
      <ListAction.Wrapper hasPressableStyles>
        <View style={styles.container}>
          <ListAction.Icon iconName={iconName} />
          <View style={styles.content}>
            <ListAction.Text>
              {[title, subtitle]
                .filter(Boolean)
                .join(currentBreakpoint.isXS ? '\n' : ' – ')}
            </ListAction.Text>
          </View>
          <Icon
            name="IconCaretRight"
            color={theme.colors.NEUTRAL_2}
            width={18}
            height={18}
          />
        </View>
      </ListAction.Wrapper>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    paddingVertical: theme.spacing['2'],
  },
  content: {
    flex: 1,
  },
});

import { useIsFocused } from '@react-navigation/native';
import { formatGoogleAddress } from '@sg/garnish';
import { CostChannel } from '@sg/graphql-schema';

import {
  type DeliveryMenuContentAddressQuery,
  type DeliveryMenuContentRestaurantQuery,
  useDeliveryMenuContentAddressQuery,
  useDeliveryMenuContentRestaurantQuery,
} from '../../graphql/DeliveryMenuContentRestaurant.generated';

// ─────────────────────────────────────────────────────────────────────────────

export const useDeliveryMenuContentData = (
  params: UseDeliveryMenuContentDataParams,
) => {
  const { addressId } = params;

  const isScreenFocused = useIsFocused();

  // ─── Remote Data ─────────────────────────────────────────────────────

  const [restaurantQueryResponse] = useDeliveryMenuContentRestaurantQuery({
    variables: {
      input: { addressId },
      costChannel: CostChannel.DeliveryCostChannel,
    },
    requestPolicy: 'cache-and-network',
    pause: !isScreenFocused || !addressId,
  });

  const [deliveryAddressQueryResponse] = useDeliveryMenuContentAddressQuery({
    variables: { addressId: addressId! },
    requestPolicy: 'cache-and-network',
    pause: !isScreenFocused || !addressId,
  });

  // ─── Derived Data ────────────────────────────────────────────────────

  const restaurantData =
    restaurantQueryResponse.data?.locationByAddress.__typename === 'Location'
      ? restaurantQueryResponse.data?.locationByAddress
      : null;
  const deliveryAddressData = deliveryAddressQueryResponse.data?.address;

  const hasRequiredData = restaurantData && deliveryAddressData;
  const deliveryDetailsData = hasRequiredData
    ? serializeDeliveryDetailsData({ deliveryAddressData, restaurantData })
    : null;

  const categories = restaurantData?.restaurant.menu.categories ?? [];

  // ─── Flags ───────────────────────────────────────────────────────────

  const isFetching =
    restaurantQueryResponse.fetching || deliveryAddressQueryResponse.fetching;
  const hasError = Boolean(
    restaurantQueryResponse.error || deliveryAddressQueryResponse.error,
  );

  const hasNoProducts = isFetching
    ? false
    : categories.every((category) => category.products.length === 0);

  // ─────────────────────────────────────────────────────────────────────

  return {
    isFetching,
    hasError,
    hasNoProducts,
    deliveryDetailsData,
    restaurantData: restaurantData?.restaurant,
  };
};

// ─── Utils ───────────────────────────────────────────────────────────────────

function serializeDeliveryDetailsData(
  params: SerializeDeliveryDetailsDataParams,
) {
  const { deliveryAddressData, restaurantData } = params;

  if (!deliveryAddressData || !restaurantData) return null;

  const { restaurant, vendor, estimateRange } = restaurantData;

  // ─── Delivery Address ────────────────────────

  const deliveryAddressComplete = formatGoogleAddress(
    deliveryAddressData,
    false,
    false,
  );
  const deliveryAddressShort = formatGoogleAddress(
    deliveryAddressData,
    false,
    true,
  );
  const deliveryAddressNickname = deliveryAddressData.name;

  // ─── Delivery ETA ────────────────────────────

  const { start, end } = estimateRange ?? {};
  const deliveryEstimateFormatted =
    start === end ? `${start}` : `${start}-${end}`;
  const deliveryEstimate =
    estimateRange.start && estimateRange.end ? deliveryEstimateFormatted : null;

  // ─── Other Info ──────────────────────────────

  const deliveryFee = restaurant.deliveryFee ?? 0;

  // ─────────────────────────────────────────────

  return {
    addressId: deliveryAddressData.id,
    addressName: deliveryAddressNickname, // nickname (e.g. "home", "work")
    completeAddress: deliveryAddressComplete, // address as on the map (e.g. "26 Broadway, New York")
    shortAddress: deliveryAddressShort, // address as on the map (only street and unit) (e.g. "26 Broadway")
    vendorRestaurantId: vendor.restaurantId,
    vendor: vendor.name,
    deliveryFee,
    deliveryEstimate,
    tip: 0,
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type UseDeliveryMenuContentDataParams = {
  addressId: string | undefined;
};

type SerializeDeliveryDetailsDataParams = {
  deliveryAddressData: MaybeDeliveryAddressData;
  restaurantData: MaybeDeliveryRestaurantData;
};

type MaybeDeliveryAddressData = DeliveryMenuContentAddressQuery['address'];

type MaybeDeliveryRestaurantData = Extract<
  DeliveryMenuContentRestaurantQuery['locationByAddress'],
  { __typename: 'Location' }
>;

// 'delivery.estimate': '{estimate} minutes',
//   'delivery.estimate-header': ' · {estimate} min',

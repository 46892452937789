import { telemetryEventLogger } from './Telemetry.logger';
import type { TelemetryContext } from './TelemetryContext';
import type { TelemetryOrderChannel } from './TelemetryEvent.types';

export const preEventHandlers: TelemetryEventHandlers = [];

export const postEventHandlers: TelemetryEventHandlers = [
  ({ context, payload }) => {
    const orderChannelPayload = payload as TelemetryOrderChannel;
    const orderChannel =
      orderChannelPayload?.channel ?? orderChannelPayload?.orderType;

    if (orderChannel) {
      telemetryEventLogger.info('Setting orderChannel', orderChannel);
      context.setSuperProperty('orderChannel', orderChannel);
    }
  },
];

// ––– TYPES –––––––––––––––––––––––––––––––––––––––––––––––

export type TelemetryEventHandler = (props: TelemetryEventHandlerProps) => void;

export type TelemetryEventHandlers = readonly TelemetryEventHandler[];

export type TelemetryEventHandlerProps = Readonly<{
  context: TelemetryContext;
  name: string;
  payload: unknown;
}>;

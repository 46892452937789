import React from 'react';
import { Animated, StyleSheet } from 'react-native';
import { COLORS } from '@garnish/constants';

import { useIndicatorAnimation } from '../hooks';
import type { TabNavIndicatorProps } from '../TabNav.types';

const TEST_ID = 'active-tab-indicator';

export const Indicator = (props: TabNavIndicatorProps) => {
  const { measures, currentIndex, disableAnimation } = props;

  const { scaleX, translateX } = useIndicatorAnimation({
    measures,
    currentIndex,
    disableAnimation,
  });

  const style = [styles.indicator, { transform: [{ translateX }, { scaleX }] }];

  return <Animated.View testID={TEST_ID} style={style} />;
};

const styles = StyleSheet.create({
  indicator: {
    backgroundColor: COLORS.GREEN_2,
    position: 'relative',
    height: 1,
    width: 1,
  },
});

import React, { useCallback, useState } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Button,
  Container,
  DisplayText,
  IllusEmpty_2,
  Image,
  reloadApp,
  theme,
} from '@sg/garnish';

import { removeContentfulCache } from '@order/Contentful';

import { useLogError } from './utils';

// ────────────────────────────────────────────────────────────────────────────────

export const ErrorBoundary = (props: ErrorBoundaryProps) => {
  const { children, ...restProps } = props;

  const logError = useLogError();

  const onError = useCallback<ErrorBoundaryOnError>(
    (error, info) => {
      void removeContentfulCache();
      void logError(error, info);
    },
    [logError],
  );

  return (
    <ReactErrorBoundary
      onError={onError}
      FallbackComponent={ErrorFallback}
      {...restProps}
    >
      {children}
    </ReactErrorBoundary>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

export const ErrorFallback = ({ error }: ErrorFallbackProps) => {
  const [isRestartInProgress, setIsRestartInProgress] = useState(false);

  // ────────────────────────────────────────────────────────────────────

  const handleAppReload = useCallback(async () => {
    setIsRestartInProgress(true);
    await reloadApp();
  }, []);

  // ────────────────────────────────────────────────────────────────────

  const errorMessage = typeof error === 'string' ? error : error?.message ?? '';

  return (
    <View style={styles.wrapper}>
      <Container accessibilityRole="alert">
        <Image
          source={IllusEmpty_2}
          style={styles.illustration}
          testID="error-boundary-fallback-illustration"
        />

        <DisplayText
          size={2}
          style={styles.heading}
          testID="error-boundary-fallback-title"
        >
          Something went wrong
        </DisplayText>

        <BodyText style={styles.text} testID="error-boundary-fallback-text">
          The app ran into a problem and could not continue. Press the button
          below to restart the app.
        </BodyText>

        {Boolean(errorMessage) && Boolean(__DEV__) ? (
          <BodyText style={styles.text}>{errorMessage}</BodyText>
        ) : null}

        <Button
          // {handleAppReload} is used instead of {resetErrorBoundary} to prevent screen flickering issue
          onPress={handleAppReload}
          style={styles.button}
          isLoading={isRestartInProgress}
          accessibilityRole="button"
        >
          Restart application
        </Button>
      </Container>
    </View>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ErrorFallbackProps = Readonly<{
  error?: string | { message: string };
}>;

type ErrorBoundaryProps = Omit<
  React.ComponentProps<typeof ReactErrorBoundary>,
  'fallback' | 'fallbackRender'
>;

type ErrorBoundaryOnError = NonNullable<ErrorBoundaryProps['onError']>;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  illustration: {
    width: 200,
    height: 200,
    alignSelf: 'center', // { alignItems: 'center' } on the container causes layout issues on iOS
  },
  heading: {
    marginBottom: theme.spacing['4'],
    textAlign: 'center',
  },
  text: {
    marginBottom: theme.spacing['10'],
    textAlign: 'center',
  },
  button: {
    alignSelf: 'center',
  },
});

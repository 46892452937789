import { defineMessages } from 'react-intl';

export const giftCardCheckoutDeliveryMethodMessageCardMessages = defineMessages(
  {
    messageOptionHelperText: {
      defaultMessage:
        'You’ll get a link to send to your recipient after checkout.',
      description:
        'Gift Card Checkout Form | Delivery Options | "Message" option helper text (Web)',
    },
    messageOptionHelperTextMessagingApps: {
      defaultMessage: 'You can select a messaging app after payment.',
      description:
        'Gift Card Checkout Form | Delivery Options | "Message" option helper text',
    },
  },
);

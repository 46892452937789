/**
 * See this for details: https://sweetgreen.atlassian.net/wiki/spaces/CA/pages/4375871493/Home+Copy+Replatform
 * > 1. Smart greeting 2. Hero headline 3. Hero asset 4. Hero CTA 5. Order status headline 6. Order status deck
 */

export const orderStatus = {
  // ─── Greeting  ─────────
  'home.welcome.greeting': `{greetingMode, select,
    loggedOut {Welcome.}
    newCustomer {Welcome, {customerName}.}
    morning {Morning, {customerName}.}
    afternoon {Ready for lunch, {customerName}?}
    evening {Got dinner plans, {customerName}?}
    specialDay {Happy Holidays, {customerName}!}
    generic {Hey, {customerName}.}
    other {Welcome.}
  }`,

  'home.welcome.bag-cta.title': 'Finish up your {orderType} order',
  'home.welcome.evergreen': "It's a good day for greens.",
  'home.headline.cta': 'Start order',

  // ─── Order Status Messaging: Hero Headline, orderStatusHeadline, orderStatusBody, a11yHint ───
  'home.welcome.heroHeadline': `{orderType, select,
      pickup {{enhancedOrderStatus, select,
        received {Your greens are getting closer.}
        preparing {Your order is being prepared.}
        readyForCourier {}
        delivering {}
        completed {Your order is complete.}
        feedback {How was it?}
        noTrackingData {Your order has been received.}
        failed {Sorry, your order was canceled.}
        pending {It’s a good day for greens.}
        lateOrder {}
        other {}
      }}
      outpost {{enhancedOrderStatus, select,
        received {Your greens are getting closer.}
        delivering {Your order is on its way.}
        completed {Your order has arrived.}
        feedback {How was it?}
        noTrackingData {Your order has been received.}
        failed {Sorry, your order was canceled.}
        pending {It’s a good day for greens.}
        lateOrder {}
        other {}
      }}
      delivery {{enhancedOrderStatus, select,
        received {Your greens are getting closer.}
        preparing {Your order is being prepared.}
        readyForCourier {Your order is ready for the courier.}
        delivering {Your order is on its way.}
        completed {Your order has been delivered.}
        feedback {How was it?}
        noTrackingData {Your order has been received.}
        failed {Sorry, your order was canceled.}
        pending {It’s a good day for greens.}
        lateOrder {}
        other {}
      }}
      other {{enhancedOrderStatus, select,
        other {}
      }}
  }`,

  'home.welcome.cta.orderStatusHeadline': `{orderType, select,
      pickup {{enhancedOrderStatus, select,
        received {Order received — ETA {timeEstimate, select,
          today {}
          tomorrow {tomorrow, }
          afterTomorrow {{dayOfWeek}, }
          other {}
        }{wantedTimeTs, time, short}.}
        preparing {Preparing order — ETA {wantedTimeTs, time, short}.}
        completed {Head to {restaurantName}.}
        feedback {Rate your last order.}
        noTrackingData {Order ETA {wantedTimeTs, time, short}.}
        lateOrder {Sorry, your order is running late.}
        failed {We will immediately refund you.}
        other {}
      }}
      outpost {{enhancedOrderStatus, select,
        received {Order received — ETA {timeEstimate, select,
          today {}
          tomorrow {tomorrow, }
          afterTomorrow {{dayOfWeek}, }
          other {}
        }{timeRangeStartTs} - {timeRangeEndTs}.}
        delivering {Order arriving — ETA {timeRangeStartTs} - {timeRangeEndTs}.}
        completed {Go get your greens.}
        feedback {Rate your last order.}
        noTrackingData {Order ETA {timeRangeStartTs} - {timeRangeEndTs}.}
        lateOrder {Sorry, your order is running late.}
        failed {We will immediately refund you.}
        other {}
      }}
      delivery {{enhancedOrderStatus, select,
        received {Order received — ETA {timeEstimate, select,
          today {}
          tomorrow {tomorrow, }
          afterTomorrow {{dayOfWeek}, }
          other {}
        }{timeRangeStartTs} - {timeRangeEndTs}.}
        preparing {Preparing order — ETA {timeRangeStartTs} - {timeRangeEndTs}.}
        readyForCourier {Order ETA {timeRangeStartTs} - {timeRangeEndTs}.}
        delivering {Delivering order — ETA {timeRangeStartTs} - {timeRangeEndTs}.}
        completed {Go get your greens.}
        feedback {Rate your last order.}
        noTrackingData {Order ETA {timeRangeStartTs} - {timeRangeEndTs}.}
        lateOrder {Sorry, your order is running late.}
        failed {We will immediately refund you. }
        other {}
      }}
      other {}
  }`,

  'home.welcome.cta.orderStatusBody': `{orderType, select,
      pickup {{enhancedOrderStatus, select,
        received {Pickup at {restaurantName}.}
        preparing {Pickup from {restaurantName}.}
        completed {}
        feedback {}
        noTrackingData {Pickup from {restaurantName}.}
        lateOrder {Check your order status for updates.}
        failed {If you still have concerns, please contact us.}
        other {}
      }}
      outpost {{enhancedOrderStatus, select,
        received {Outpost at {restaurantName} - {dropoffLocation}}
        delivering {Outpost at {restaurantName} - {dropoffLocation}}
        completed {Outpost at {restaurantName} - {dropoffLocation}}
        feedback {}
        noTrackingData {Outpost at {restaurantName} - {dropoffLocation}}
        lateOrder {Check your order status for updates.}
        failed {If you still have concerns, please contact us.}
        other {}
      }}
      delivery {{enhancedOrderStatus, select,
        received {Deliver to {deliveryAddressName}}
        preparing {Deliver to {deliveryAddressName}}
        readyForCourier {Deliver to {deliveryAddressName}}
        delivering {Deliver to {deliveryAddressName}}
        completed {Delivered to {deliveryAddressName}}
        feedback {}
        noTrackingData {Deliver to {deliveryAddressName}}
        lateOrder {Check your order status for updates.}
        failed {If you still have concerns, please contact us.}
        other {}
      }}
      other {}
  }`,

  'home.welcome.cta.a11yHint': `{orderType, select,
      pickup {{enhancedOrderStatus, select,
        received {Click here to see details about your order status}
        preparing {Click here to see details about your order status}
        completed {Click here to see details about your order status}
        feedback {Click here to rate your order}
        noTrackingData {Click here to see details about your order status}
        lateOrder {Click here to see details about your order status}
        failed {Click here to contact us}
        other {}
      }}
      delivery {{enhancedOrderStatus, select,
        received {Click here to see details about your order status}
        preparing {Click here to see details about your order status}
        readyForCourier {Click here to see details about your order status}
        delivering {Click here to see details about your order status}
        completed {Click here to see details about your order status}
        feedback {Click here to rate your order}
        noTrackingData {Click here to see details about your order status}
        lateOrder {Click here to see details about your order status}
        failed {Click here to contact us}
        other {}
      }}
      outpost {{enhancedOrderStatus, select,
        received {Click here to see details about your order status}
        delivering {Click here to see details about your order status}
        completed {Click here to see details about your order status}
        feedback {Click here to rate your order}
        noTrackingData {Click here to see details about your order status}
        lateOrder {Click here to see details about your order status}
        failed {Click here to contact us}
        other {}
      }}
      other {}
  }`,

  // ─── Order to Health Kit ─────────────────────────────────────────────

  'order-to-health-kit.confirm.success':
    'The order record has been successfully saved in the Health app.',
  'order-to-health-kit.toggle.a11y-label':
    "Show the 'Add order to Health Kit' modal",
  'order-to-health-kit.toggle.icon-a11y-label': 'Heartkit icon',
  'order-to-health-kit.calories': '{calories} cals',
  'order-to-health-kit.title': 'Add to your Healthkit',
  'order-to-health-kit.helper-text':
    'Tap to add nutritional information from your order to Healthkit',
  'order-to-health-kit.modal.helper-text':
    'Select the items below you would like to add to your Apple Healthkit.',
  'order-to-health-kit.modal.cancel': 'Cancel',
  'order-to-health-kit.modal.confirm': 'Confirm',

  // ─── Order to Health Kit Errors ──────────────────────────────────────

  'order-to-health-kit.confirm.error':
    'Food records cannot be saved in the Health app. Please activate the corresponding permission for the sweetgreen app in the Health app.',

  // ─── Sweetpass Upsell CTA ────────────────────────────────────────────

  'order-status.sweetpass-upsell-cta.fallback.text':
    'Learn how you could have saved $3 on this order with Sweetpass+.',
  'order-status.sweetpass-upsell-cta.fallback.text-highlight': '$3',
  'order-status.sweetpass-upsell-cta.fallback.icon-text': '$3',
};

import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { theme } from '@garnish/constants';
import { Carousel, LoadingPlaceholder } from '@sg/garnish';

import { NotFoundView } from '@order/components';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import {
  ScanInStoreCta,
  ScanInStoreTabContentContainer,
} from '../../components/';
import { useScanInStoreGiftCards } from '../../hooks';
import { ScanInStorePayEmptyState, ScanInStorePayGiftCard } from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanInStorePay = () => {
  const { t } = useLocalizationContext();
  const { navigate } = useNavigation();
  const { track } = useTelemetry();

  const {
    isLoadingGiftCards,
    hasGiftCardsError,
    giftCards,
    hasGiftCardBalance,
  } = useScanInStoreGiftCards();

  // ─── Dynamic Styles ──────────────────────────────────────────────────

  const isSingleGiftCard = giftCards.length === 1;
  const ctaGap = isSingleGiftCard ? styles.singleGiftCardGap : undefined;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToAddGiftCard = useCallback(() => {
    track('scan_addgiftcard_cta.press');

    navigate('Modal', { screen: 'AddGiftCard' });
  }, [navigate, track]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ScanInStoreTabContentContainer>
      <Carousel
        navControlAccessibilityLabel={t(
          'scan-in-store.tabs.pay.carousel.nav-control-a11y-label',
        )}
        gap={CAROUSEL_ITEMS_GAP}
        stagePadding={CAROUSEL_ITEMS_GAP}
      >
        {hasGiftCardBalance ? (
          giftCards.map((giftCard) => (
            <ScanInStorePayGiftCard
              key={giftCard.id}
              qrCodeValue={giftCard.id}
              name={giftCard.title}
              balance={giftCard.remainingAmount}
            />
          ))
        ) : (
          // @ts-expect-error TS(2786): 'ScanInStorePayEmptyContent' cannot be used as a J... Remove this comment to see the full error message
          <ScanInStorePayEmptyContent
            isLoadingGiftCards={isLoadingGiftCards}
            hasGiftCardsError={hasGiftCardsError}
            hasGiftCardBalance={hasGiftCardBalance}
            onPressCta={navigateToAddGiftCard}
          />
        )}
      </Carousel>

      {hasGiftCardBalance ? (
        <ScanInStoreCta containerStyle={ctaGap} onPress={navigateToAddGiftCard}>
          {t('scan-in-store.tabs.pay.cta.title')}
        </ScanInStoreCta>
      ) : null}
    </ScanInStoreTabContentContainer>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const ScanInStorePayEmptyContent = (props: ScanInStorePayEmptyContentProps) => {
  const {
    isLoadingGiftCards,
    hasGiftCardsError,
    hasGiftCardBalance,
    onPressCta,
  } = props;

  // ─── Loading State ───────────────────────────────────────────────────

  if (isLoadingGiftCards) {
    return <LoadingPlaceholder rowHeight={CAROUSEL_HEIGHT} palette="cream" />;
  }

  // ─── Error State ─────────────────────────────────────────────────────

  if (hasGiftCardsError) {
    return <NotFoundView withoutFooter />;
  }

  // ─── Empty State ─────────────────────────────────────────────────────

  if (!hasGiftCardBalance) {
    return <ScanInStorePayEmptyState onPressCta={onPressCta} />;
  }
};

// ─── Constants ───────────────────────────────────────────────────────────────

const CAROUSEL_ITEMS_GAP = theme.spacing['4'];
const CAROUSEL_HEIGHT = 332;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  singleGiftCardGap: {
    marginTop: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanInStorePayEmptyContentProps = Readonly<{
  isLoadingGiftCards: boolean;
  hasGiftCardsError: boolean;
  hasGiftCardBalance: boolean;
  onPressCta: () => void;
}>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InvalidGiftCardErrorFieldsFragment = {
  readonly __typename?: 'InvalidGiftCardError';
  readonly errorMessage: string;
};

export type GiftCardAssociatedWithAnotherAccountErrorFieldsFragment = {
  readonly __typename?: 'GiftCardAssociatedWithAnotherAccountError';
  readonly errorMessage: string;
};

export type UnableToRedeemGiftCardErrorFieldsFragment = {
  readonly __typename?: 'UnableToRedeemGiftCardError';
  readonly errorMessage: string;
};

export type GiftCardBalanceResponseFieldsFragment = {
  readonly __typename?: 'GiftCardBalance';
  readonly customerId: string;
  readonly giftCardBalance: number;
};

export type NoBalanceGiftCardErrorFieldsFragment = {
  readonly __typename?: 'NoBalanceGiftCardError';
  readonly errorMessage: string;
};

export type RedeemGiftCardMutationVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
  regCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type RedeemGiftCardMutation = {
  readonly __typename?: 'Mutation';
  readonly redeemGiftCard:
    | {
        readonly __typename: 'GiftCardAssociatedWithAnotherAccountError';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'GiftCardBalance';
        readonly customerId: string;
        readonly giftCardBalance: number;
      }
    | {
        readonly __typename: 'InvalidGiftCardError';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'NoBalanceGiftCardError';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'UnableToRedeemGiftCardError';
        readonly errorMessage: string;
      };
};

export const InvalidGiftCardErrorFieldsFragmentDoc = gql`
  fragment InvalidGiftCardErrorFields on InvalidGiftCardError {
    errorMessage
  }
`;
export const GiftCardAssociatedWithAnotherAccountErrorFieldsFragmentDoc = gql`
  fragment GiftCardAssociatedWithAnotherAccountErrorFields on GiftCardAssociatedWithAnotherAccountError {
    errorMessage
  }
`;
export const UnableToRedeemGiftCardErrorFieldsFragmentDoc = gql`
  fragment UnableToRedeemGiftCardErrorFields on UnableToRedeemGiftCardError {
    errorMessage
  }
`;
export const GiftCardBalanceResponseFieldsFragmentDoc = gql`
  fragment GiftCardBalanceResponseFields on GiftCardBalance {
    customerId
    giftCardBalance
  }
`;
export const NoBalanceGiftCardErrorFieldsFragmentDoc = gql`
  fragment NoBalanceGiftCardErrorFields on NoBalanceGiftCardError {
    errorMessage
  }
`;
export const RedeemGiftCardDocument = gql`
  mutation RedeemGiftCard($code: String!, $regCode: String) {
    redeemGiftCard(code: $code, regCode: $regCode) {
      __typename
      ...InvalidGiftCardErrorFields
      ...GiftCardAssociatedWithAnotherAccountErrorFields
      ...UnableToRedeemGiftCardErrorFields
      ...NoBalanceGiftCardErrorFields
      ...GiftCardBalanceResponseFields
    }
  }
  ${InvalidGiftCardErrorFieldsFragmentDoc}
  ${GiftCardAssociatedWithAnotherAccountErrorFieldsFragmentDoc}
  ${UnableToRedeemGiftCardErrorFieldsFragmentDoc}
  ${NoBalanceGiftCardErrorFieldsFragmentDoc}
  ${GiftCardBalanceResponseFieldsFragmentDoc}
`;

export function useRedeemGiftCardMutation() {
  return Urql.useMutation<
    RedeemGiftCardMutation,
    RedeemGiftCardMutationVariables
  >(RedeemGiftCardDocument);
}

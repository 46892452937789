import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutFormContainer = (props: PropsWithChildren) => {
  const { match } = useResponsive();

  const formContainerStyles = [
    styles.formContainer,
    match([
      styles.formContainerContainerXS,
      styles.formContainerContainerSM,
      styles.formContainerContainerMD,
    ]),
  ];

  return <View style={formContainerStyles}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  formContainer: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    rowGap: theme.spacing['2'],
  },
  formContainerContainerXS: {
    marginTop: theme.spacing['10'],
    borderTopWidth: 1,
    borderTopColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
    paddingBottom: theme.spacing['2'],
  },
  formContainerContainerSM: {
    borderWidth: 1,
    borderColor: theme.colors.GRAY,
    borderRadius: theme.radius.large,
    marginTop: theme.spacing['10'],
  },
  formContainerContainerMD: {
    borderWidth: 1,
    borderColor: theme.colors.GRAY,
    borderRadius: theme.radius.large,
  },
});

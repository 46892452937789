import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import type { CloudinaryTransformConfig } from '@sg/garnish';
import { CloudinaryImage, useResponsive } from '@sg/garnish';

import {
  REORDER_RAIL_CARD_SIZE_LG,
  REORDER_RAIL_CARD_SIZE_XS,
} from './constants';

export const ReorderRailCardImage = (props: ReorderRailCardImageProps) => {
  const { baseUrl, accessibilityLabel } = props;
  const { match } = useResponsive();

  const responsiveAssetWrapper = match([
    styles.assetWrapperXs,
    styles.assetWrapperLg,
  ]);

  const responsiveImageSize = match([
    REORDER_RAIL_CARD_SIZE_XS,
    REORDER_RAIL_CARD_SIZE_LG,
  ]);

  const imageTransformConfig = useCloudinaryConfig(responsiveImageSize);

  return (
    <CloudinaryImage
      baseUrl={baseUrl}
      contentFit="contain"
      aria-label={accessibilityLabel}
      config={imageTransformConfig}
      style={responsiveAssetWrapper}
    />
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type ReorderRailCardImageProps = Readonly<{
  baseUrl: string;
  accessibilityLabel: string;
}>;

// ─── Hooks ─────────────────────────────────────────────────────────────

function useCloudinaryConfig(responsiveImageSize: number) {
  const cloudinaryConfig = useMemo<CloudinaryTransformConfig>(
    () => [
      { crop: 'crop', height: 0.6, width: 0.5 },
      { width: responsiveImageSize },
    ],
    [responsiveImageSize],
  );

  return cloudinaryConfig;
}

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  assetWrapperLg: {
    width: REORDER_RAIL_CARD_SIZE_LG,
    height: REORDER_RAIL_CARD_SIZE_LG,
  },
  assetWrapperXs: {
    width: REORDER_RAIL_CARD_SIZE_XS,
    height: REORDER_RAIL_CARD_SIZE_XS,
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { DebugMenuTrigger } from '../../DebugMenu';

export const OrganizationLinksFooterCopyright = () => {
  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  const textStyle = match([undefined, styles.copyrightAndAppVersionText]);

  return (
    <View>
      <BodyText style={textStyle}>
        {t('footer.copyright', { year: CURRENT_YEAR })}
      </BodyText>

      <BodyText style={textStyle}>
        <DebugMenuTrigger />
      </BodyText>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const CURRENT_YEAR = new Date().getFullYear();

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  copyrightAndAppVersionText: {
    textAlign: 'right',
  },
});

import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuTopDownCategoriesItemDetailsContainer = (
  props: PropsWithChildren,
) => {
  return <View style={styles.container}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

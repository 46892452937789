import { useGiftCardRedemptionDetailQuery } from '../../graphql/GiftCardRedemptionDetail.generated';

/**
 * A small hook for fetching gift card redemption details.
 */
export const useGiftCardRedemptionDetails = (
  params: UseGiftCardRedemptionDetailsParams,
) => {
  const { giftCardId, pause } = params;

  // ─── Query ───────────────────────────────────────────────────────────

  const [response] = useGiftCardRedemptionDetailQuery({
    pause,
    variables: { giftCardRedemptionDetailId: giftCardId },
    requestPolicy: 'network-only',
  });

  // ─── Derived Data ────────────────────────────────────────────────────

  const responseData = response.data?.giftCardRedemptionDetail;
  const responseTypename = responseData?.__typename;
  const hasFetchedData = responseTypename === 'GiftCardRedemptionDetailSuccess';
  const data = hasFetchedData ? responseData : null;

  const isFetching = response.fetching;
  const hasSystemError = response.error !== undefined;
  const hasUserError = responseTypename !== undefined && !hasFetchedData;
  const hasError = hasSystemError || hasUserError;

  // ─────────────────────────────────────────────────────────────────────

  return { data, isFetching, hasError };
};

// ─── Type ────────────────────────────────────────────────────────────────────

type UseGiftCardRedemptionDetailsParams = {
  giftCardId: string;
  pause: boolean;
};

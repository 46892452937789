import { useEffect, useMemo } from 'react';

import { useHasLoggedOut } from '@order/AuthMachine';
import { useCart } from '@order/hooks';
import { useLastValidOrder } from '@order/Order';

import type { LastInteractedStore } from './machine/LastInteractedStore.model';
import { LastInteractedStoreModel } from './machine/LastInteractedStore.model';
import { useLastInteractedStoreMachine } from './useLastInteractedStoreMachine';

/**
 * Tracks and synchronizes the last interacted stores based on the following sources:
 *
 * - active cart
 * - the most recently placed order
 *
 * NOTE: intended to be used just once each app session
 */
export const useTrackLastInteractedStore = () => {
  const [, send] = useLastInteractedStoreMachine();
  const hasLoggedOut = useHasLoggedOut();

  // ─── TRACK CHANGES ───────────────────────────────────────────────

  const cartStore = useCartStore();
  const lastPlacedOrderStore = useLastPlacedOrderStore();

  // ─── SYNC CHANGES ────────────────────────────────────────────────

  useEffect(() => {
    send(SET_ACTIVE_CART_STORE(cartStore));
  }, [cartStore, send]);

  useEffect(() => {
    send(SET_LAST_PLACED_ORDER_STORE(lastPlacedOrderStore));
  }, [lastPlacedOrderStore, send]);

  useEffect(() => {
    if (hasLoggedOut) send(RESET_LAST_INTERACTED_MENU_STORE());
  }, [hasLoggedOut, send]);
};

// ─── HELPERS ────────────────────────────────────────────────────────────────────

const useCartStore = (): LastInteractedStore => {
  const { cart, isCartEmpty } = useCart();
  const { restaurant, deliveryOrderDetail } = cart ?? {};

  const restaurantSlug = restaurant?.slug;
  const addressId = deliveryOrderDetail?.address.id;

  return useMemo(() => {
    if (isCartEmpty) return;

    return { restaurantSlug, addressId };
  }, [addressId, isCartEmpty, restaurantSlug]);
};

const useLastPlacedOrderStore = (): LastInteractedStore => {
  const { lastValidOrder: lastPlacedOrder } = useLastValidOrder();

  const restaurantSlug = lastPlacedOrder?.restaurant.slug;
  const addressId = lastPlacedOrder?.deliveryOrderDetail?.address.id;

  return useMemo(() => {
    if (!restaurantSlug && !addressId) return;

    return { restaurantSlug, addressId };
  }, [addressId, restaurantSlug]);
};

// ────────────────────────────────────────────────────────────────────────────────

const {
  SET_ACTIVE_CART_STORE,
  SET_LAST_PLACED_ORDER_STORE,
  RESET_LAST_INTERACTED_MENU_STORE,
} = LastInteractedStoreModel.events;

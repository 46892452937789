import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';
import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const IngredientCardNoticeLabel = (props: IngredientCardLabelProps) => {
  const { children, style, ...restProps } = props;

  const { match } = useResponsive();

  return (
    <BodyText
      sizeMatch={match([['12'], ['14'], ['16']])}
      style={[styles.text, style]}
      {...restProps}
    >
      {children}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    textAlign: 'center',
    color: theme.colors.CAUTION,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type IngredientCardLabelProps = ComponentProps<typeof BodyText>;

import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { LoyaltyRewardsRail, theme } from '@sg/garnish';

import { RewardDetailsModal } from '@order/components';
import type { ChallengeRewardsQuery } from '@order/graphql';
import { useRewardsWithDerivedData } from '@order/hooks';

export const SweetpassHomeRewardsRail = ({
  content,
  rewards,
  isFetching,
}: SweetpassHomeRewardsRailProps) => {
  const rewardsWithExtraDetails = useRewardsWithDerivedData(rewards);

  const [selected, setSelected] = useState<string | undefined>();

  const handleRewardPress = useCallback(
    (id: string) => {
      if (selected === id) {
        setSelected(undefined);

        return;
      }

      setSelected(id);
    },
    [selected],
  );

  const resetActiveRewardId = useCallback(() => {
    setSelected(undefined);
  }, []);

  const activeReward = useMemo(
    () => rewardsWithExtraDetails?.find((reward) => reward.id === selected),
    [rewardsWithExtraDetails, selected],
  );

  return (
    <View style={styles.container}>
      <LoyaltyRewardsRail
        isLoading={isFetching}
        rewards={rewardsWithExtraDetails}
        headerVariation="display"
        availableCount={rewardsWithExtraDetails?.length}
        headerText={content.titleText}
        subtitle={content.bodyText}
        emptyStateImage={{ uri: content.emptyContentImage.url }}
        emptyStateText={content.emptyContentText}
        selectedRewardId={selected}
        onPress={handleRewardPress}
      />

      <RewardDetailsModal
        reward={activeReward}
        onDismiss={resetActiveRewardId}
      />
    </View>
  );
};

// ––– STYLES –––––––––––––––––––––––––––––

const styles = StyleSheet.create({
  container: {
    marginBottom: theme.spacing['6'],
  },
});

// ––– TYPES –––––––––––––––––––––––––––––

type SweetpassHomeRewardsRailProps = Readonly<{
  content: SweetpassHomeRewardsRailContent;
  isFetching: boolean;
  rewards: ChallengeRewardsQuery['challengeRewards'];
}>;

type SweetpassHomeRewardsRailContent = Readonly<{
  type: 'sweetpassHomeRewardsRail';
  id: string;
  titleText: string;
  bodyText?: string;
  emptyContentText: string;
  emptyContentImage: {
    url: string;
    description: string;
  };
}>;

// @ts-expect-error TS(2307): Cannot find module '../../../Checkout.hooks' or it... Remove this comment to see the full error message
import type { CheckoutPayload } from '../../../Checkout.hooks';

export type CheckoutFieldsValidationState = Readonly<{
  fieldsValidation: Partial<Record<CheckoutFieldName, IsValidCheckoutField>>;
  invalidValues: CheckoutFieldsInvalidValues;
}>;

export type CheckoutFieldName = keyof CheckoutPartialPayload;

export type CheckoutFieldsInvalidValues = Partial<
  Record<
    CheckoutFieldName,
    ReadonlyArray<CheckoutPartialPayload[CheckoutFieldName]>
  >
>;

export type CheckoutPartialPayload = Partial<CheckoutPayload>;

type IsValidCheckoutField = boolean;

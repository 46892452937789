import React, { type ComponentProps, forwardRef } from 'react';
import type { Text as RNText } from 'react-native';
import { StyleSheet } from 'react-native';
import { TYPE_CONFIG, WEIGHT_MAP } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { Text } from '../Text';
import { useFluidTextStyles } from '../useFluidTextStyles';

// ─────────────────────────────────────────────────────────────────────────────

export const DisplayText = forwardRef<RNText, DisplayTextProps>(
  (props, ref) => {
    const { children, size, sizeMatch = [], ...restProps } = props;

    if (sizeMatch.length > 0) {
      return (
        <DisplayTextStatic ref={ref} sizeMatch={sizeMatch} {...restProps}>
          {children}
        </DisplayTextStatic>
      );
    }

    return (
      <DisplayTextFluid ref={ref} size={size} {...restProps}>
        {children}
      </DisplayTextFluid>
    );
  },
);

// ─────────────────────────────────────────────────────────────────────────────

const DisplayTextStatic = forwardRef<RNText, DisplayTextStaticProps>(
  (props, ref) => {
    const { children, sizeMatch, bold, style, ...restProps } = props;

    const { match } = useResponsive();

    const fontSize = match(sizeMatch);
    const textStyles =
      DISPLAY_TEXT_STYLES[fontSize] ?? DISPLAY_TEXT_DEFAULT_FONT_STYLES;
    const boldTextStyles = bold ? styles.boldText : undefined;
    const allStyles = [textStyles, boldTextStyles, style];

    return (
      <Text ref={ref} style={allStyles} bold={bold} {...restProps}>
        {children}
      </Text>
    );
  },
);

const DisplayTextFluid = forwardRef<RNText, DisplayTextFluidProps>(
  (props, ref) => {
    const {
      children,
      size = DISPLAY_TEXT_DEFAULT_ORDINAL_SIZE,
      bold,
      style,
      ...restProps
    } = props;

    const fontSize = DISPLAY_TEXT_PRESET_MAP[size];
    const textStyles =
      DISPLAY_TEXT_STYLES[fontSize] ?? DISPLAY_TEXT_DEFAULT_FONT_STYLES;
    const fluidTextStyles = useFluidTextStyles(fontSize, 'DISPLAY');
    const boldTextStyles = bold ? styles.boldText : undefined;
    const allStyles = [textStyles, boldTextStyles, fluidTextStyles, style];

    return (
      <Text ref={ref} style={allStyles} bold={bold} {...restProps}>
        {children}
      </Text>
    );
  },
);

// ─── Constants ───────────────────────────────────────────────────────────────

const DISPLAY_TEXT_STYLES = TYPE_CONFIG.DISPLAY;

const DISPLAY_TEXT_PRESET_MAP: Record<number, DisplayTextFontSize> = {
  1: '48',
  2: '40',
  3: '32',
  4: '24',
};

const DISPLAY_TEXT_DEFAULT_ORDINAL_SIZE: DisplayTextSizeOrdinal = 1;
const DISPLAY_TEXT_DEFAULT_FONT_SIZE: DisplayTextFontSize = '48';

const DISPLAY_TEXT_DEFAULT_FONT_STYLES =
  DISPLAY_TEXT_STYLES[DISPLAY_TEXT_DEFAULT_FONT_SIZE];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  boldText: {
    fontWeight: WEIGHT_MAP.bold,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type DisplayTextProps = Readonly<{
  size?: DisplayTextSizeOrdinal;
  sizeMatch?: DisplayTextFontSize[];
}> &
  DisplayTextBaseProps;

type DisplayTextStaticProps = Readonly<{
  sizeMatch: DisplayTextFontSize[];
}> &
  DisplayTextBaseProps;

type DisplayTextFluidProps = Readonly<{
  size?: DisplayTextSizeOrdinal;
}> &
  DisplayTextBaseProps;

type DisplayTextBaseProps = ComponentProps<typeof Text>;

type DisplayTextSizeOrdinal = keyof typeof DISPLAY_TEXT_PRESET_MAP;

type DisplayTextFontSize = keyof typeof DISPLAY_TEXT_STYLES;

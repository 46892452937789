import React from 'react';

import { BodyText } from '../../Text';
import { styles } from '../TextField.styles';

export const TextFieldHelperText = (
  props: React.ComponentProps<typeof BodyText>,
) => {
  const { children, ...restProps } = props;

  if (!children) return null;

  return (
    <BodyText size={5} style={styles.helperText} {...restProps}>
      {children}
    </BodyText>
  );
};

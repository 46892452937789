import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, Icon, theme } from '@sg/garnish';

export const MenuHeroNotice = (props: NotificationProps) => {
  const { text } = props;

  if (!text) return null;

  return (
    <View style={styles.notification}>
      <BodyText size={4} style={styles.notificationText}>
        <View style={styles.notificationIcon}>
          <Icon
            name="IconBadgeAlert"
            width={16}
            height={16}
            color={theme.colors.TEXT_COLOR}
          />
        </View>

        {text}
      </BodyText>
    </View>
  );
};

// ─── STYLES ──────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  notification: {
    flexDirection: 'row',
    borderRadius: theme.radius.small,
    marginTop: theme.spacing['6'],
    padding: theme.spacing['4'],
    backgroundColor: theme.colors.GREEN_5,
  },
  notificationText: {
    display: 'flex',
    alignItems: 'center',
  },
  notificationIcon: {
    paddingRight: theme.spacing['2'],
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type NotificationProps = Readonly<{
  text?: string | null;
}>;

import { useCallback } from 'react';
import { Platform } from 'react-native';
import { datadog } from '@sg/expo-datadog-client';

import { useMixpanel } from '@order/Telemetry';

import { getUserId } from '../getUserId';

export const useLogError = () => {
  const { track: mixpanelTrack } = useMixpanel();

  return useCallback(
    async (error: Error, info: Readonly<{ componentStack: string }>) => {
      const userId = await getUserId();
      const platform = Platform.OS;
      const { name, message, stack } = error;
      const context = { name, message, stack, info, userId, platform };

      await datadog.logError('App crash', context);

      mixpanelTrack('app.error-boundary.crash', {
        error: message,
        stack,
        info,
      });
    },
    [mixpanelTrack],
  );
};

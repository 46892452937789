import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';

export const HomeFooterXS = (props: HomeFooterXSProps) => {
  const navBarAdjustment = useStyle(
    () => ({
      marginBottom: props.marginBottom,
    }),
    [props.marginBottom],
  );

  return (
    <View style={[styles.footer, navBarAdjustment]}>{props.children}</View>
  );
};

const styles = StyleSheet.create({
  footer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

type HomeFooterXSProps = {
  children: React.ReactNode;
  marginBottom: number;
};

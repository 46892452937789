import type { ComponentProps } from 'react';
import React from 'react';

import { useResponsive } from '../../hooks';
import type { CollectionCardProps } from '../CollectionCard';
import type { HorizontalScrollRail } from '../HorizontalScrollRail';
import { useCollectionsRailDynamicProps } from './hooks';
import type { CollectionsRailBaseProps } from './subcomponents/CollectionsRailBase';
import { CollectionsRailBase } from './subcomponents/CollectionsRailBase';
import { CollectionsRailItem } from './subcomponents/CollectionsRailItem';

// ────────────────────────────────────────────────────────────────────

export const CollectionsRail = (props: CollectionsRailProps) => {
  const {
    collections,
    isLoading,
    headerText,
    headerVariation,
    withoutHeaderBorder,
  } = props;

  if (isLoading) {
    return (
      <CollectionsRailLoading
        headerText={headerText}
        headerVariation={headerVariation}
        withoutHeaderBorder={withoutHeaderBorder}
      />
    );
  }

  if (!collections?.length) return null;

  return <CollectionsRailFulfilled {...props} />;
};

// ─── COMPONENT STATES ─────────────────────────────────────────────────────────────────

const CollectionsRailFulfilled = (
  props: Omit<CollectionsRailProps, 'isLoading'>,
) => {
  const { collections, headerText, footerText, onPress, ...restProps } = props;

  const { minWidth } = useResponsive();
  const { railItemWidth, railOuterOffset, railItemGap } =
    useCollectionsRailDynamicProps();

  return (
    <CollectionsRailBase
      headerText={headerText}
      footerText={footerText}
      showNavControls={minWidth.isSM}
      outerOffset={railOuterOffset}
      {...restProps}
    >
      {collections?.map((collection) => (
        <CollectionsRailItem
          key={collection.id}
          width={railItemWidth}
          gap={railItemGap}
          collection={collection}
          onPress={onPress}
        />
      ))}
    </CollectionsRailBase>
  );
};

const CollectionsRailLoading = (props: CollectionsRailBaseProps) => {
  const loadingPlaceholders = Array.from({ length: 3 });

  const { railItemWidth, railOuterOffset, railItemGap } =
    useCollectionsRailDynamicProps();

  return (
    <CollectionsRailBase
      scrollEnabled={false}
      showNavControls={false}
      outerOffset={railOuterOffset}
      {...props}
    >
      {loadingPlaceholders.map((_, index) => (
        <CollectionsRailItem
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          width={railItemWidth}
          gap={railItemGap}
          isLoading={true}
        />
      ))}
    </CollectionsRailBase>
  );
};

// ─── TYPES ─────────────────────────────────────────────────────────────────

export type CollectionsRailProps = Readonly<{
  collections: ReadonlyArray<Omit<CollectionCardProps, 'onPress'>>;
  onPress: (collectionId: CollectionCardProps['id']) => void;
  isLoading?: boolean;
  testID?: string;
}> &
  Pick<CollectionsRailBaseProps, 'headerText' | 'footerText'> &
  Pick<
    ComponentProps<typeof HorizontalScrollRail>,
    'headerVariation' | 'withoutHeaderBorder'
  >;

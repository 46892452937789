import React from 'react';
import type ReactNative from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';

export const ModalRow = (props: ReactNative.ViewProps) => {
  const { children, style, ...restProps } = props;
  const { match } = useResponsive();
  const responsiveStyles = match([
    styles.bodyElementMobile,
    styles.bodyElementTabletUp,
  ]);

  return (
    <View style={[responsiveStyles, style]} {...restProps}>
      {children}
    </View>
  );
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  bodyElementMobile: {
    paddingHorizontal: theme.spacing['4'],
  },
  bodyElementTabletUp: {
    paddingHorizontal: theme.spacing['6'],
  },
});

import type { StarProps } from './Rating.types';

export const getAccessibilityLabel = (
  props: Pick<StarProps, 'value' | 'starValue' | 'accessibility'>,
) => {
  const { value, starValue, accessibility } = props;
  const accessibilityLabelTemplate =
    accessibility?.ratingLabel ?? 'Rate as {starValue}, currently {value}';

  return accessibilityLabelTemplate
    .replace('{starValue}', `${starValue}`)
    .replace('{value}', `${value}`);
};

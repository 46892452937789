import React, { type ComponentProps, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { theme } from '@garnish/constants';

import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardPressableText = (
  props: LoyaltyOfferCardTextProps,
) => {
  const { style, ...restProps } = props;

  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <BodyText
      ref={ref}
      accessibilityRole="button"
      style={[styles.text, isHovered ? styles.textHover : undefined, style]}
      underline
      sizeMatch={['16']}
      {...restProps}
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    color: theme.colors.CREAM,
  },
  textHover: {
    color: theme.colors.GRAY,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardTextProps = ComponentProps<typeof BodyText>;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { TitleText } from '../Text';

export const Title = (props: FormTitleProps) => (
  <View style={styles.wrapper}>
    <TitleText size={3}>{props.children}</TitleText>
  </View>
);

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type FormTitleProps = Readonly<{
  children: string;
}>;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    paddingBottom: theme.spacing['4'],
  },
});

import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

export const ScanAtCheckoutHowItWorksContainer = (
  props: ScanAtCheckoutHowItWorksContainerProps,
) => {
  const { children } = props;

  return <View style={styles.container}>{children}</View>;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanAtCheckoutHowItWorksContainerProps = ComponentProps<typeof View>;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    paddingVertical: theme.spacing[10],
    paddingHorizontal: theme.spacing[6],
  },
});

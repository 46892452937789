import React, { memo } from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  COLORS,
  Ledger as LedgerComponent,
  SectionHeader,
  theme,
  VStack,
} from '@sg/garnish';

import {
  CheckoutLayoutBlock,
  DeliveryFeeDisclosure,
  LineItemCard,
} from '@order/components';
import { useTippingEnabledForCurrentOrderType } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { useDeliveryDisclosure } from '@order/shared/hooks';

import { useLedgerItems } from '../../../../../components/Bag/Bag.hooks';
import { useCheckoutContext } from '../../../CheckoutProvider';
import { CheckoutPlaceOrderButton } from '../../CheckoutPlaceOrderButton';

// ─────────────────────────────────────────────────────────────────────────────

export const ConnectedCheckoutOrderBlock = () => {
  const context = useCheckoutContext();

  return <CheckoutOrderBlock {...context} />;
};

export const CheckoutOrderBlock = memo((props: CheckoutOrderBlockProps) => {
  const { cart, ledger, isCartDelivery } = props;

  const { lineItems = [] } = cart ?? {};

  const { t } = useLocalizationContext();
  const isTippingEnabled = useTippingEnabledForCurrentOrderType();

  // ─── Delivery Fee Disclosure ─────────────────────────────────────────

  const { deliveryDisclosure } = useDeliveryDisclosure({
    variation: 'checkout',
    restaurantDeliveryFee: cart?.deliveryOrderDetail?.deliveryFee,
  });

  const shouldShowDeliveryFeeDisclosure =
    deliveryDisclosure !== undefined &&
    isCartDelivery &&
    cart?.restaurant.showDeliveryFeeDisclosure;

  // ───── Ledger ───────────────────────────────────────────────────

  const ledgerItems = useLedgerItems({
    ledger,
    showTip: isTippingEnabled,
    showTotal: true,
    showTaxes: true,
    showReward: true,
  });

  return (
    <CheckoutLayoutBlock>
      <SectionHeader heading={t('general.your-order')} />

      {/* ========== ========== ========== ========== */}

      <VStack gap={0} hasDivider={true} dividerColor={COLORS.LIGHT}>
        {lineItems.map((lineItem) => (
          <LineItemCard key={lineItem.id} lineItem={lineItem} />
        ))}
      </VStack>

      {/* ========== ========== ========== ========== */}

      <LedgerComponent
        style={styles.ledger}
        hasTopBorder
        items={ledgerItems}
        testID="checkout.ledger"
      />

      {/* ========== ========== ========== ========== */}

      {shouldShowDeliveryFeeDisclosure ? (
        <View style={styles.deliveryFeeDisclosureContainer}>
          <DeliveryFeeDisclosure text={deliveryDisclosure} />
        </View>
      ) : null}

      {/* ========== ========== ========== ========== */}

      <View style={styles.orderButtonWrapper}>
        <CheckoutPlaceOrderButton {...props} />
      </View>

      {/* ========== ========== ========== ========== */}

      <VStack gap={theme.spacing['4']} style={styles.helperTextsStack}>
        <CheckoutOrderBlockHelperText
          title={t('checkout.allergens-disclaimer')}
          text={t('checkout.allergens-disclaimer-message')}
        />
        {isCartDelivery ? (
          <CheckoutOrderBlockHelperText
            title={t('checkout.delivery-provider')}
            text={t('checkout.delivery-provider-message')}
          />
        ) : null}
        <CheckoutOrderBlockHelperText
          title={t('checkout.cancellation-policy')}
          text={t('checkout.cancellation-policy-message')}
        />
      </VStack>
    </CheckoutLayoutBlock>
  );
});

// ─── Types ──────────────────────────────────────────────────────────────────────

type CheckoutOrderBlockProps = Pick<
  ReturnType<typeof useCheckoutContext>,
  | 'payload'
  | 'orderId'
  | 'cart'
  | 'checkoutCredit'
  | 'isSubmittingOrder'
  | 'isCartDelivery'
  | 'cannotSubmitOrderReason'
  | 'shouldBlockCheckoutOnPress'
  | 'handleInvalidWantedTime'
  | 'handleCreditCardSelected'
  | 'validateCheckoutFields'
  | 'submitOrder'
  | 'ledger'
  | 'total'
  | 'paymentMethods'
  | 'isApplePayReady'
  | 'validateCanPlaceOrder'
  | 'validateAndSubmitOrder'
  | 'sendShowPaymentSheetRequest'
  | 'cancelApplePayCheckout'
>;

type CheckoutOrderBlockInfoMessageProps = Readonly<{
  title?: string;
  text: string;
}> &
  Omit<ViewProps, 'children'>;

// ─── Styles ──────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  ledger: {
    marginTop: theme.spacing['2'],
  },
  orderButtonWrapper: {
    paddingTop: theme.spacing['1'],
  },
  helperTextsStack: {
    paddingTop: theme.spacing['10'],
  },
  deliveryFeeDisclosureContainer: {
    paddingBottom: theme.spacing['4'],
  },
});

// ─── Subcomponents ──────────────────────────────────────────────────────────────

export const CheckoutOrderBlockHelperText = (
  props: CheckoutOrderBlockInfoMessageProps,
) => {
  const { title, text, ...restProps } = props;

  return (
    <View {...restProps}>
      {title ? (
        <BodyText size={5} bold>
          {title}
        </BodyText>
      ) : null}
      <BodyText size={5}>{text}</BodyText>
    </View>
  );
};

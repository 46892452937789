import type { ComponentProps } from 'react';
import React, { useCallback } from 'react';

import { IconLink } from '../../Icon';

// ────────────────────────────────────────────────────────────────────────────────

export const LineItemRemoveBtn = (props: LineItemRemoveBtnProps) => {
  const { onPress, disabled, accessibilityLabel, testID, productId } = props;

  const handleOnPress = useCallback(() => {
    onPress?.(productId);
  }, [onPress, productId]);

  return (
    <IconLink
      name="IconBadgeTrash"
      iconSize={32}
      onPress={handleOnPress}
      disabled={disabled}
      accessibilityLabel={accessibilityLabel}
      testID={testID}
    />
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type LineItemRemoveBtnProps = Readonly<{
  productId: string;
  onPress: (productId: string) => void;
}> &
  Pick<
    ComponentProps<typeof IconLink>,
    'disabled' | 'accessibilityLabel' | 'testID'
  >;

import { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useBraze } from '@order/Braze';
import { SWEETPASS_BRAZE_CUSTOM_EVENTS } from '@order/constants';
import { useSweetpassTier } from '@order/hooks';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Handles Braze custom event/s for Sweetpass Home screen
 */
export const useSweetpassHomeBrazeEvents = () => {
  const { logCustomEvent } = useBraze();
  const isLoggedIn = useIsLoggedIn();
  const { sweetpassTierName, joinDate } = useSweetpassTier();

  const shouldLogNavigatedToSweetpassHomeEvent =
    isLoggedIn && sweetpassTierName !== undefined;

  // ─── Effects ─────────────────────────────────────────────────────────

  useFocusEffect(
    useCallback(() => {
      if (!shouldLogNavigatedToSweetpassHomeEvent) return;

      if (sweetpassTierName === 'SWEETPASS') {
        logCustomEvent(
          SWEETPASS_BRAZE_CUSTOM_EVENTS.UserNavigatedToSweetpassHome,
          { joinDate },
        );
      }

      if (sweetpassTierName === 'SWEETPASS_PLUS') {
        logCustomEvent(
          SWEETPASS_BRAZE_CUSTOM_EVENTS.UserNavigatedToSweetpassPlusHome,
          { joinDate },
        );
      }
    }, [
      joinDate,
      logCustomEvent,
      shouldLogNavigatedToSweetpassHomeEvent,
      sweetpassTierName,
    ]),
  );
};

import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import type { TextInput } from 'react-native';

import { NumberFieldCode as GarnishNumberFieldCode } from '../NumberField';
import type { ControlledProps, FormProps } from './types';

type ComponentProps = React.ComponentProps<typeof GarnishNumberFieldCode>;
type OmittedProps = ControlledProps | 'noticePalette';
type Props = FormProps & Omit<ComponentProps, OmittedProps>;

export const NumberFieldCode = forwardRef<TextInput, Props>(
  ({ name, control, rules, notice, ...rest }, ref) => {
    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { invalid, error },
        }) => (
          <GarnishNumberFieldCode
            ref={ref}
            value={value}
            invalid={invalid}
            notice={notice ?? error?.message}
            noticePalette={invalid ? 'caution' : undefined}
            onBlur={onBlur}
            onChangeText={onChange}
            {...rest}
          />
        )}
      />
    );
  },
);

import React, { type ComponentProps, type ReactNode, useCallback } from 'react';
import { type ScrollView } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { theme, useOnScrollEndReached } from '@sg/garnish';

import {
  ORDER_AGAIN_TABS,
  OrderAgain,
  OrdersList,
} from '@order/features/orders';

import {
  ReorderOrderFavoriteLineItem,
  ReorderTabContainer,
} from '../../components';
import { useReorderFavorites, type useReorderOrders } from '../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const ReorderScreenFavoritesTabPanel = (
  props: ReorderScreenFavoritesTabPanelProps,
) => {
  const { activeTabId, mostRecentOrder } = props;

  // ─── Remote Data ─────────────────────────────────────────────────────

  const {
    favorites,
    isFetchingInitialFavorites,
    isFetchingMoreFavorites,
    isTogglingFavoriteState,
    canFetchMoreFavorites,
    fetchInitialFavorites,
    fetchMoreFavorites,
    toggleFavoriteLineItem,
  } = useReorderFavorites();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isActiveTab = ORDER_AGAIN_TABS.FAVORITES.ID === activeTabId;

  const shouldRenderEmptyView =
    !isFetchingInitialFavorites && favorites.length === 0;
  const shouldRenderLoadingPlaceholders = isFetchingInitialFavorites;
  const shouldShowLoadingIndicator = isFetchingMoreFavorites;
  const shouldPauseMoreFavoritesFetching =
    !isActiveTab ||
    !canFetchMoreFavorites ||
    shouldRenderEmptyView ||
    shouldRenderLoadingPlaceholders;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const fetchMoreFavoritesOnScroll = useOnScrollEndReached({
    debounce: 16,
    onScrollEndReachedY: fetchMoreFavorites,
    endThresholdY: theme.spacing['20'],
    pause: shouldPauseMoreFavoritesFetching,
  });

  const onScroll = useCallback<OnScroll>(
    (event) => {
      fetchMoreFavoritesOnScroll(event.nativeEvent);
    },
    [fetchMoreFavoritesOnScroll],
  );

  // ─── Effects ─────────────────────────────────────────────────────────

  // Fetch orders on screen focus
  useFocusEffect(
    useCallback(() => {
      fetchInitialFavorites();
    }, [fetchInitialFavorites]),
  );

  // ─── Loading View ────────────────────────────────────────────────────

  if (shouldRenderLoadingPlaceholders) {
    return (
      <ReorderScreenFavoritesTabPanelContainer
        activeTabId={activeTabId}
        shouldShowLoadingIndicator={shouldShowLoadingIndicator}
        onScroll={onScroll}
      >
        <OrdersList.FavoritesContainer>
          <OrderAgain.LoadingPlaceholder size="md" />
        </OrdersList.FavoritesContainer>
      </ReorderScreenFavoritesTabPanelContainer>
    );
  }

  // ─── Empty View ──────────────────────────────────────────────────────

  if (shouldRenderEmptyView) {
    return (
      <ReorderScreenFavoritesTabPanelContainer
        activeTabId={activeTabId}
        shouldShowLoadingIndicator={shouldShowLoadingIndicator}
        onScroll={onScroll}
      >
        <OrderAgain.EmptyView.Favorites />
      </ReorderScreenFavoritesTabPanelContainer>
    );
  }

  // ─── Fulfilled View ──────────────────────────────────────────────────

  return (
    <ReorderScreenFavoritesTabPanelContainer
      activeTabId={activeTabId}
      shouldShowLoadingIndicator={shouldShowLoadingIndicator}
      onScroll={onScroll}
    >
      <OrdersList.FavoritesContainer>
        {favorites.map((favorite) => (
          <ReorderOrderFavoriteLineItem
            key={favorite.id}
            favoriteLineItem={favorite}
            isTogglingFavoriteState={isTogglingFavoriteState}
            toggleFavoriteLineItem={toggleFavoriteLineItem}
            mostRecentOrder={mostRecentOrder}
          />
        ))}
      </OrdersList.FavoritesContainer>
    </ReorderScreenFavoritesTabPanelContainer>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const ReorderScreenFavoritesTabPanelContainer = (
  props: ReorderScreenFavoritesTabPanelContainerProps,
) => {
  const { activeTabId, children, shouldShowLoadingIndicator, onScroll } = props;

  const isActiveTab = ORDER_AGAIN_TABS.FAVORITES.ID === activeTabId;

  return (
    <ReorderTabContainer
      onScroll={onScroll}
      isActive={isActiveTab}
      shouldShowLoadingIndicator={shouldShowLoadingIndicator}
    >
      <OrderAgain.FavoritesTab activeTabId={activeTabId}>
        {children}
      </OrderAgain.FavoritesTab>
    </ReorderTabContainer>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ReorderScreenFavoritesTabPanelProps = {
  activeTabId: string;
  mostRecentOrder: ReturnType<typeof useReorderOrders>['mostRecentOrder'];
};

type ReorderScreenFavoritesTabPanelContainerProps = {
  activeTabId: string;
  shouldShowLoadingIndicator: boolean;
  children: ReactNode;
  onScroll: OnScroll;
};

type OnScroll = NonNullable<ComponentProps<typeof ScrollView>['onScroll']>;

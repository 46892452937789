import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  View,
} from 'react-native';
import { useSharedValue } from 'react-native-reanimated';
import { theme, useResponsive } from '@sg/garnish';

import {
  CustomizationContainer,
  CustomizationCTA,
  CustomizationDetails,
  CustomizationDetailsContainer,
} from '@order/features/customization';
import { useCart } from '@order/hooks';

import { useProductDetailsScreenContext } from '../../../../ProductDetailsScreen.provider';
import { ProductBanner } from '../../../ProductBanner';
import { ProductDetailsContentNavigationHeader } from '../ProductDetailsNavigationHeader';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductDetailsDressingDetailsView = () => {
  const {
    lineItemId,
    addressId,
    restaurantSlug,
    product,
    quantity,
    startCustomization,
    dressingMode,
    setDressingModeToOnTheSide,
    setDressingModeToMixedIn,
    selectedPortionsNumber,
    maxDressingsPortionsNumber,
    modifications,
    dressingsDetails,
    changeDressingWeight,
    changeDressingPortion,
    isBreadActive,
    isBreadAvailable,
    breadIngredientModification,
    toggleBread,
    modifyCart,
  } = useProductDetailsScreenContext();

  const { isFetchingCart } = useCart();

  const { calories, netPriceChange, active } = modifications;

  const { currentBreakpoint, minWidth, match } = useResponsive();
  const { formatMessage } = useIntl();

  // ─── Data ────────────────────────────────────────────────────────────

  const productCost = (product?.cost ?? 0) + netPriceChange;

  // ─── Flags ───────────────────────────────────────────────────────────

  const isEditProductMode = lineItemId !== undefined;

  // delivery and outpost orders cannot have mixed-in dressing
  const isDelivery = Boolean(addressId);
  const isOutpost = Boolean(product?.restaurant?.isOutpost);

  const hasMixableIngredient = active?.some(
    (modification) => modification.mixable,
  );
  const hasNoSelectedDressing = dressingsDetails.length === 0;
  const hasGreenBase = active?.some(
    (modification) => !modification.isGrain && modification.kind === 'bases',
  );

  const isDressingModeChangeNotAllowed =
    hasNoSelectedDressing ||
    isDelivery ||
    isOutpost ||
    !hasMixableIngredient ||
    !hasGreenBase;

  const isBreadOutOfStock = breadIngredientModification?.outOfStock === true;

  // ─── Styles ──────────────────────────────────────────────────────────

  const selectedIngredientsScrollSV = useSharedValue(0);

  const updateIngredientsScrollSV = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      // eslint-disable-next-line functional/immutable-data
      selectedIngredientsScrollSV.value = event.nativeEvent.contentOffset.y;
    },
    [selectedIngredientsScrollSV],
  );

  // ─────────────────────────────────────────────────────────────────────

  if (!product) return null;

  return (
    <CustomizationContainer>
      <CustomizationDetailsContainer
        isFluid
        stickyHeaderIndices={[0]}
        onScroll={updateIngredientsScrollSV}
        scrollEventThrottle={16}
        bounces={false}
      >
        <ProductDetailsContentNavigationHeader
          scrollOffsetSV={selectedIngredientsScrollSV}
          restaurantSlug={restaurantSlug}
          addressId={addressId}
          text={formatMessage(messages.dressingDetailsViewTitle)}
          spacing="standard"
          isShowingText={match([true, false])}
          onBackButtonPress={startCustomization}
        />

        <CustomizationDetails.Container>
          {isDressingModeChangeNotAllowed ? null : (
            <CustomizationDetails.DressingMode
              dressingMode={dressingMode}
              maxDressingsPortions={maxDressingsPortionsNumber}
              setDressingModeToOnTheSide={setDressingModeToOnTheSide}
              setDressingModeToMixedIn={setDressingModeToMixedIn}
              isDisabled={isFetchingCart}
            />
          )}

          {dressingMode === 'mixedIn' ? (
            <>
              {dressingsDetails.map((dressingDetails) => (
                <CustomizationDetails.DressingWeight
                  key={dressingDetails.ingredientModification.ingredient.id}
                  ingredientModification={
                    dressingDetails.ingredientModification
                  }
                  activeDressingsDetails={dressingsDetails}
                  setDressingWeight={changeDressingWeight}
                  isDisabled={isFetchingCart}
                />
              ))}
            </>
          ) : null}

          {dressingMode === 'onTheSide' ? (
            <>
              {dressingsDetails.map((dressingDetails) => (
                <CustomizationDetails.DressingPortion
                  key={dressingDetails.ingredientModification.ingredient.id}
                  ingredientModification={
                    dressingDetails.ingredientModification
                  }
                  activeDressingsDetails={dressingsDetails}
                  maxPortions={maxDressingsPortionsNumber}
                  remainingPortions={
                    maxDressingsPortionsNumber - selectedPortionsNumber
                  }
                  setDressingPortion={changeDressingPortion}
                  isDisabled={isFetchingCart}
                />
              ))}
            </>
          ) : null}

          {isBreadAvailable ? (
            <CustomizationDetails.BreadToggle
              isActive={isBreadActive}
              isDisabled={isBreadOutOfStock || isFetchingCart}
              onChange={toggleBread}
            />
          ) : null}
        </CustomizationDetails.Container>

        {minWidth.isSM ? (
          <CustomizationCTA
            variation={isEditProductMode ? 'update' : 'add-to-bag'}
            cost={productCost}
            quantity={quantity}
            calories={calories}
            disabled={isFetchingCart}
            isLoading={isFetchingCart}
            onPress={modifyCart}
          />
        ) : null}
      </CustomizationDetailsContainer>

      {minWidth.isSM ? (
        <View style={styles.ingredientsGridContainer}>
          <ProductBanner
            imgSrc={product.asset.url}
            imgAriaLabel={product.name}
          />
        </View>
      ) : null}

      {currentBreakpoint.isXS ? (
        <CustomizationCTA
          variation={isEditProductMode ? 'update' : 'add-to-bag'}
          disabled={isFetchingCart}
          cost={productCost}
          quantity={quantity}
          calories={calories}
          withSafeAreaEdge
          isLoading={isFetchingCart}
          onPress={modifyCart}
        />
      ) : null}
    </CustomizationContainer>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  dressingDetailsViewTitle: {
    defaultMessage: 'Dressing details',
    description: 'Customization | Dressing and details view | Title',
  },
});

// ─────────────────────────────────────────────────────────────────────────────

const styles = {
  ingredientsGridContainer: {
    flex: 1,
    backgroundColor: theme.colors.CREAM,
  },
};

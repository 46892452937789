import React from 'react';
import type { TextStyle, ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';

import { useMeasuredViewStyle } from '../../hooks';
import { removeExtraWhitespace } from '../../utils';
import { IconLink } from '../Icon';
import { BodyText } from '../Text';

export const ManagedStepper = (props: StepperProps) => {
  const { min, onDecrement, onIncrement } = props;
  const [value, setValue] = React.useState<number>(min ?? 0);
  const onDecrementManaged = React.useCallback(() => {
    setValue(Number(value) - 1);
    onDecrement?.();
  }, [value, onDecrement]);
  const onIncrementManaged = React.useCallback(() => {
    setValue(Number(value) + 1);
    onIncrement?.();
  }, [value, onIncrement]);

  return (
    <Stepper
      {...props}
      value={value}
      onDecrement={onDecrementManaged}
      onIncrement={onIncrementManaged}
    />
  );
};

export const Stepper = (props: StepperProps) => {
  const {
    label,
    plural,
    value = 0,
    min,
    max,
    labelWidth = 0,
    containerStyle,
    labelStyle,
    shouldHideValue,
    accessibilityLabelForDecrease,
    accessibilityHintForDecrease,
    accessibilityLabelForIncrease,
    accessibilityHintForIncrease,
    onDecrement,
    onIncrement,
  } = props;
  const disableDecrease = min !== undefined && value <= min;
  const disableIncrease = max !== undefined && value >= max;

  const content = React.useMemo(
    () =>
      generateStepperContent({
        label,
        plural,
        value,
        shouldHideValue,
      }),
    [label, plural, shouldHideValue, value],
  );

  // Adds a horizontal padding to the initial label width to prevent it from resizing.
  const [width, setWidth] = React.useState(labelWidth);
  const labelRef = React.useRef<View>(null);
  const { onLayout, measuredViewStyle } = useMeasuredViewStyle({
    reference: labelRef,
    initialMeasureOnly: true,
    width,
    padding: measuredViewPadding,
    setWidth,
  });

  // ─── A11Y HELPER TEXTS  ─────────────────────────────────────────────────────────

  const defaultA11yLabelForDecrease = removeExtraWhitespace(
    `decrease ${label ?? ''} stepper, currently ${content}`,
  );
  const defaultA11yHintForDecrease = removeExtraWhitespace(
    `Decrease the amount ${label ? `of ${plural ?? label}` : ''}`,
  );
  const defaultA11yLabelForIncrease = removeExtraWhitespace(
    `increase ${label ?? ''} stepper, currently ${content}`,
  );
  const defaultA11yHintForIncrease = removeExtraWhitespace(
    `Increase the amount ${label ? `of ${plural ?? label}` : ''}`,
  );

  return (
    <View style={[styles.container, containerStyle]}>
      <IconLink
        testID="stepper-decrease"
        name="IconSubtract"
        accessibilityLabel={
          accessibilityLabelForDecrease ?? defaultA11yLabelForDecrease
        }
        accessibilityHint={
          accessibilityHintForDecrease ?? defaultA11yHintForDecrease
        }
        accessibilityRole="button"
        disabled={disableDecrease}
        onPress={onDecrement}
      />
      <View ref={labelRef} onLayout={onLayout} style={measuredViewStyle}>
        <BodyText
          size={4}
          testID="stepper-label"
          accessibilityLiveRegion="polite"
          selectable={false}
          style={[styles.label, labelStyle]}
        >
          {content}
        </BodyText>
      </View>
      <IconLink
        testID="stepper-increase"
        name="IconAdd"
        accessibilityLabel={
          accessibilityLabelForIncrease ?? defaultA11yLabelForIncrease
        }
        accessibilityHint={
          accessibilityHintForIncrease ?? defaultA11yHintForIncrease
        }
        accessibilityRole="button"
        disabled={disableIncrease}
        onPress={onIncrement}
      />
    </View>
  );
};

const measuredViewPadding = 16;
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    textAlign: 'center',
    marginHorizontal: 12,
  },
});

type StepperProps = Readonly<{
  label?: string;
  plural?: string;
  value?: number;
  min?: number;
  max?: number;
  containerStyle?: ViewStyle;
  labelStyle?: TextStyle;
  labelWidth?: number;
  shouldHideValue?: boolean;
  accessibilityLabelForDecrease?: string;
  accessibilityHintForDecrease?: string;
  accessibilityLabelForIncrease?: string;
  accessibilityHintForIncrease?: string;
  onDecrement?: () => void;
  onIncrement?: () => void;
}>;

type GenerateStepperContentProps = Readonly<{
  value: number;
}> &
  Pick<StepperProps, 'label' | 'plural' | 'shouldHideValue'>;

//
// ─── HELPERS ────────────────────────────────────────────────────────────────────
//

export const generateStepperContent = ({
  label,
  plural,
  value,
  shouldHideValue,
}: GenerateStepperContentProps) => {
  const hasLabel = label !== undefined;
  const shouldUsePluralLabel = value !== 1 && Boolean(plural);
  // eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10
  const hint = hasLabel ? (shouldUsePluralLabel ? plural : label) : '';

  return `${shouldHideValue ? '' : value}${hint ? ` ${hint}` : ''}`;
};

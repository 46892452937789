import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { Button } from '@sg/garnish';

import { orderAgainRailAllOrdersCTAMessages as messages } from './OrderAgainRailAllOrdersCTA.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainRailAllOrdersCTA = (
  props: OrderAgainRailViewAllOrdersCTAProps,
) => {
  const { disabled, onPress } = props;

  return (
    <View style={styles.container}>
      <Button
        size="small"
        palette="secondary"
        onPress={onPress}
        disabled={disabled}
      >
        <FormattedMessage {...messages.buttonLabel} />
      </Button>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    minWidth: 153,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderAgainRailViewAllOrdersCTAProps = {
  onPress: () => void;
  disabled?: boolean;
};

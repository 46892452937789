import type {
  PickerOptionsGroups,
  PickerOptionValue,
} from '../Picker/Picker.types';

export const getOptionGroupsLabels = (optionsGroups: PickerOptionsGroups) => {
  return Object.keys(optionsGroups).map((label) => ({
    label,
    value: label,
  }));
};

export const getSelectedValueParentGroup = (
  optionsGroups: PickerOptionsGroups,
  value: PickerOptionValue,
) => {
  return (
    Object.entries(optionsGroups).find(([_, options]) => {
      return options.find((option) => option.value === value);
    }) ?? []
  );
};

export const extractAllGroupsOptions = (optionsGroups: PickerOptionsGroups) => {
  return Object.values(optionsGroups).flat();
};

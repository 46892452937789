import type { CSSProperties } from 'react';
import type { ImageStyle, TextStyle, ViewStyle } from 'react-native';
import { Platform } from 'react-native';

/**
 * Returns styles object only for web platform.
 *
 * @example
 *
 * button: {
 *  color: 'red',
 *  ...webOnlyStyles({
 *  cursor: pointer
 *  })
 * }
 */
export function webOnlyStyles(
  style: CSSProperties & ViewStyle & TextStyle & ImageStyle,
) {
  return Platform.OS === 'web' ? style : {};
}

import { useMemo } from 'react';

import { CostChannel } from '@order/graphql';

import { useAddonsQuery } from './GraphQL/Addons.generated';

export const useAddons = ({ isDelivery }: { isDelivery: boolean }) => {
  const [addonsResponse, refetchAddons] = useAddonsQuery({
    requestPolicy: 'network-only',
    variables: {
      costChannel: isDelivery
        ? CostChannel.DeliveryCostChannel
        : CostChannel.DefaultCostChannel,
    },
  });

  const isLoadingAddons = addonsResponse.fetching || addonsResponse.stale;
  const addons = addonsResponse.data?.addons;

  return useMemo(
    () => ({ addons, isLoadingAddons, refetchAddons }),
    [addons, isLoadingAddons, refetchAddons],
  );
};

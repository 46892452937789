import React, { useMemo } from 'react';
import type { StyleProp, ViewStyle } from 'react-native';
import Svg, {
  Defs,
  G,
  LinearGradient,
  Path,
  Rect,
  Stop,
} from 'react-native-svg';

import genMatrix from './genMatrix';
import transformMatrixIntoPath from './transformMatrixIntoPath';

/**
 * NOTE: This code is adapted from `react-native-qrcode-svg`
 * https://github.com/awesomejerry/react-native-qrcode-svg
 *
 * Modifications
 * - Added support for responsive workflows
 * - removed "Logo" related functionality to simplify
 */

export const QRCodeGenerator = ({
  value = 'this is a QR code',
  size = 100,
  color = 'black',
  backgroundColor = 'white',
  quietZone = 0,
  enableLinearGradient = false,
  gradientDirection = ['0%', '0%', '100%', '100%'],
  linearGradient = ['rgb(255,0,0)', 'rgb(0,255,255)'],
  ecl = 'M',
  getRef,
  onError,
  preserveAspectRatio,
  style,
}: QRCodeProps) => {
  const result = useMemo(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return transformMatrixIntoPath(genMatrix(value, ecl), size);
    } catch (error: any) {
      if (onError && typeof onError === 'function') {
        onError(error);
      } else {
        // Pass the error when no handler presented
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw error;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, size, ecl]);

  if (!result) {
    return null;
  }

  const { path, cellSize } = result;

  return (
    <Svg
      ref={getRef}
      viewBox={[
        -quietZone,
        -quietZone,
        size + quietZone * 2,
        size + quietZone * 2,
      ].join(' ')}
      style={style}
      preserveAspectRatio={preserveAspectRatio}
    >
      <Defs>
        <LinearGradient
          id="grad"
          x1={gradientDirection[0]}
          y1={gradientDirection[1]}
          x2={gradientDirection[2]}
          y2={gradientDirection[3]}
        >
          <Stop offset="0" stopColor={linearGradient[0]} stopOpacity="1" />
          <Stop offset="1" stopColor={linearGradient[1]} stopOpacity="1" />
        </LinearGradient>
      </Defs>
      <G>
        <Rect
          x={-quietZone}
          y={-quietZone}
          width={size + quietZone * 2}
          height={size + quietZone * 2}
          fill={backgroundColor}
        />
      </G>
      <G>
        <Path
          d={path}
          strokeLinecap="butt"
          stroke={enableLinearGradient ? 'url(#grad)' : color}
          strokeWidth={cellSize}
        />
      </G>
    </Svg>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

export type QRCodeProps = Readonly<{
  /* what the qr code stands for */
  value?: string;
  /* the whole component size */
  size?: number;
  /* the color of the cell */
  color?: string;
  /* the color of the background */
  backgroundColor?: string;
  /* quiet zone in pixels */
  quietZone?: number;
  /* enable linear gradient effect */
  enableLinearGradient?: boolean;
  /* linear gradient direction */
  gradientDirection?: readonly string[];
  /* linear gradient color */
  linearGradient?: readonly string[];
  /* get svg ref for further usage */
  getRef?: (c: any) => any;
  /* error correction level */
  ecl?: 'L' | 'M' | 'Q' | 'H';
  /* error handler called when matrix fails to generate */
  // eslint-disable-next-line @typescript-eslint/ban-types
  onError?: Function;
  /* style prop; note: overwrites width/height attrs */
  style?: StyleProp<ViewStyle>;
  /* positioning and scaling around viewbox */
  preserveAspectRatio?: string;
}>;

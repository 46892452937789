import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { Scrollspy, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuCategoriesGridCategory = (
  props: MenuCategoriesGridCategoryProps,
) => {
  const { children, style, ...restProps } = props;

  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = match([styles.containerXS, styles.containerSM]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Scrollspy.TargetView style={[containerStyles, style]} {...restProps}>
      {children}
    </Scrollspy.TargetView>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    paddingTop: theme.spacing['6'],
    paddingBottom: theme.spacing['10'],
  },
  containerSM: {
    paddingTop: theme.spacing['14'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuCategoriesGridCategoryProps = ComponentProps<
  typeof Scrollspy.TargetView
>;

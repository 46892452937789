export type Point = Readonly<{
  x: number;
  y: number;
}>;

export type CoordinatePair = Readonly<{
  p1: Point;
  p2: Point;
}>;

export type LockRange = Readonly<{
  min: number;
  max: number;
}>;

export const DEFAULT_LOCK_RANGE: LockRange = {
  min: 400,
  max: 1000,
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';
import { useResponsive } from '@sg/garnish';

import {
  type GiftCardCheckoutFormFieldRules,
  type GiftCardCheckoutFormReturn,
} from '../../../../form';
import { GiftCardCheckoutFormGroup } from '../../components';
import {
  GiftCardCheckoutAmountCustomAmount,
  GiftCardCheckoutAmountOptionPicker,
} from './components';
import { giftCardCheckoutAmountMessages as messages } from './GiftCardCheckoutAmount.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutAmount = (props: GiftCardCheckoutAmountProps) => {
  const { form, rules } = props;

  const { match } = useResponsive();

  // ─── Flags ───────────────────────────────────────────────────────────

  const amountVariation = form.watch('amountVariation');
  const shouldRenderCustomOption = amountVariation === 'custom';

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = match([styles.containerXS, styles.containerSM]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <GiftCardCheckoutFormGroup.Container style={containerStyles}>
      <GiftCardCheckoutFormGroup.Title>
        <FormattedMessage {...messages.sectionTitle} />
      </GiftCardCheckoutFormGroup.Title>

      <GiftCardCheckoutAmountOptionPicker form={form} rules={rules} />

      {shouldRenderCustomOption ? (
        <GiftCardCheckoutAmountCustomAmount form={form} rules={rules} />
      ) : null}
    </GiftCardCheckoutFormGroup.Container>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    marginTop: theme.spacing['2'],
  },
  containerSM: {
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderTopStartRadius: theme.radius.large,
    borderTopEndRadius: theme.radius.large,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutAmountProps = {
  sectionTitle?: string;
  form: GiftCardCheckoutFormReturn;
  rules: GiftCardCheckoutFormFieldRules;
};

import React from 'react';

import { useIsLoggedIn } from '@order/AuthMachine';
import { PageWrapper, RecentOrdersRail } from '@order/components';
import { Home } from '@order/features/home';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { type useHomeContent } from '../../hooks';
import { HomeReorderRailLoader } from '../HomeReorderRailLoader';
import { HomeScreenBanner } from '../HomeScreenBanner';

export const HomeScreenContentV2SM = (props: HomeScreenContentPropsSM) => {
  const { homeContent, hasPlacedOrder, ctaPressed } = props;

  // ─── Flags ───────────────────────────────────────────────────────────

  const isReorderModalEnabled = useFeatureFlag(
    'CELS-2534-reorder-modal-enabled',
  );
  const isLoggedIn = useIsLoggedIn();

  const shouldShowRecentOrdersRail =
    isReorderModalEnabled && isLoggedIn && hasPlacedOrder;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <PageWrapper>
      <HomeScreenBanner />

      <Home.ContainerSM>
        <Home.ColumnsContainerSM>
          <Home.ColumnLeftSM>
            <Home.EyebrowText
              text={homeContent.eyebrowText}
              fontColor={homeContent.eyebrowTextColorSM}
            />

            <Home.HeroText
              text={homeContent.heroText}
              fontColor={homeContent.heroTextColorSM}
            />

            <Home.HeaderCta
              accessibilityLabel={homeContent.ctaText}
              deepLink={homeContent.ctaDeepLink}
              onPressCta={ctaPressed}
            >
              {homeContent.ctaText}
            </Home.HeaderCta>
          </Home.ColumnLeftSM>

          <Home.HeroImageSM
            imageUrl={homeContent.mainImageSM}
            imageA11yLabel={homeContent.mainImageA11ySM}
          />

          <Home.HeroImageSM.Placeholder />
        </Home.ColumnsContainerSM>
      </Home.ContainerSM>

      {shouldShowRecentOrdersRail ? (
        <RecentOrdersRail shouldRefetchOnFocus />
      ) : null}

      {isReorderModalEnabled ? null : <HomeReorderRailLoader />}
    </PageWrapper>
  );
};

type HomeScreenContentPropsSM = {
  homeContent: ReturnType<typeof useHomeContent>['homeContent'];
  hasPlacedOrder: boolean;
  ctaPressed: () => void;
};

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { type SharedValue } from 'react-native-reanimated';
import { useResponsive } from '@sg/garnish';

import { CustomizationNavigationHeader } from '@order/features/customization';

import { useProductDetailsContentNavigation } from '../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductDetailsContentNavigationHeader = (
  props: ProductDetailsContentNavigationHeaderProps,
) => {
  const {
    scrollOffsetSV,
    text,
    restaurantSlug,
    addressId,
    spacing,
    isShowingText = true,
    onBackButtonPress,
  } = props;

  const { currentBreakpoint } = useResponsive();
  const { formatMessage } = useIntl();

  const shouldRenderText = Boolean(isShowingText && text);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const { navigateToPreviousScreen } = useProductDetailsContentNavigation({
    restaurantSlug,
    addressId,
  });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <CustomizationNavigationHeader.Container
      spacing={spacing}
      scrollOffsetSV={scrollOffsetSV}
    >
      <CustomizationNavigationHeader.Button.Left
        name="IconArrowLeft"
        onPress={onBackButtonPress ?? navigateToPreviousScreen}
        accessibilityLabel={formatMessage(messages.backBtnA11yLabel)}
      />

      {shouldRenderText ? (
        <CustomizationNavigationHeader.Text>
          {text}
        </CustomizationNavigationHeader.Text>
      ) : null}

      {currentBreakpoint.isXS ? (
        <CustomizationNavigationHeader.Button.Right
          name="IconClose"
          onPress={navigateToPreviousScreen}
          accessibilityLabel={formatMessage(messages.closeBtnA11yLabel)}
        />
      ) : null}
    </CustomizationNavigationHeader.Container>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  backBtnA11yLabel: {
    defaultMessage: 'Go back',
    description: 'Customization | Navigation header | Back button a11y label',
  },
  closeBtnA11yLabel: {
    defaultMessage: 'Close',
    description: 'Customization | Navigation header | Close button a11y label',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ProductDetailsContentNavigationHeaderProps = {
  restaurantSlug: string | undefined;
  addressId: string | undefined;
  text?: string;
  isShowingText?: boolean;
  scrollOffsetSV: SharedValue<number>;
  spacing?: 'standard' | 'narrow';
  onBackButtonPress?: () => void;
};

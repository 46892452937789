export const validContentfulData = {
  fields: {
    name: 'Order App Footer',
    sections: [
      {
        metadata: { tags: [] },
        sys: {
          space: {
            sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
          },
          id: '1iwfkgdlXG9sF4fnaUcddD',
          type: 'Entry',
          createdAt: '2023-04-06T14:36:55.981Z',
          updatedAt: '2023-04-06T14:36:55.981Z',
          environment: {
            sys: { id: 'master', type: 'Link', linkType: 'Environment' },
          },
          revision: 1,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'organizationLinksFooterSection',
            },
          },
          locale: 'en-US',
        },
        fields: {
          name: 'Support + Services',
          links: [
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
                },
                id: '5sZBL4PFvJeMVDA5EaZDjW',
                type: 'Entry',
                createdAt: '2023-04-06T14:32:03.216Z',
                updatedAt: '2023-04-06T14:32:03.216Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'orgLinksFooterLink',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                label: 'sweetgreen.com',
                url: 'https://www.sweetgreen.com/',
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
                },
                id: '5Pwjht8R62aareODtZQLAJ',
                type: 'Entry',
                createdAt: '2023-04-06T14:32:23.392Z',
                updatedAt: '2023-04-06T14:32:23.392Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'orgLinksFooterLink',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                label: 'sweetgreen Shop',
                url: 'https://shop.sweetgreen.com/',
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
                },
                id: '7InftUs3tKuf4M19DSnvNO',
                type: 'Entry',
                createdAt: '2023-04-06T14:33:00.704Z',
                updatedAt: '2023-04-06T14:33:00.704Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'orgLinksFooterLink',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                label: 'Catering',
                url: 'https://www.sweetgreen.com/catering',
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
                },
                id: '3lVmVH6qzuhaJPsKCJbFp0',
                type: 'Entry',
                createdAt: '2023-04-06T14:33:29.390Z',
                updatedAt: '2023-04-06T14:33:29.390Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'orgLinksFooterLink',
                  },
                },
                locale: 'en-US',
              },
              fields: { label: 'FAQs', url: 'https://faq.sweetgreen.com/' },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
                },
                id: '1dnUeipdD1X2yQQ156HBCJ',
                type: 'Entry',
                createdAt: '2023-04-06T14:33:51.825Z',
                updatedAt: '2023-04-06T14:33:51.825Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'orgLinksFooterLink',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                label: 'Contact Us',
                url: 'https://www.sweetgreen.com/contact',
                specialHandler: 'kustomer' as const,
              },
            },
          ],
        },
      },
      {
        metadata: { tags: [] },
        sys: {
          space: {
            sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
          },
          id: 'mB5KABdrdQUaGXHPhX0Eu',
          type: 'Entry',
          createdAt: '2023-04-06T14:37:01.543Z',
          updatedAt: '2023-04-06T14:37:01.543Z',
          environment: {
            sys: { id: 'master', type: 'Link', linkType: 'Environment' },
          },
          revision: 1,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'organizationLinksFooterSection',
            },
          },
          locale: 'en-US',
        },
        fields: {
          name: 'Social Media',
          links: [
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
                },
                id: '6uCoPRZ1BnnoAcGlVxshsg',
                type: 'Entry',
                createdAt: '2023-04-06T14:34:34.129Z',
                updatedAt: '2023-04-06T14:34:34.129Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'orgLinksFooterLink',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                label: 'Instagram',
                url: 'https://www.instagram.com/sweetgreen/',
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
                },
                id: '7uI6iUWh7YN9R7rA658Wra',
                type: 'Entry',
                createdAt: '2023-04-06T14:35:00.098Z',
                updatedAt: '2023-04-06T14:35:00.098Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'orgLinksFooterLink',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                label: 'Twitter',
                url: 'https://twitter.com/sweetgreen',
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
                },
                id: 'zeHBI1BMYPeDMcatb62zB',
                type: 'Entry',
                createdAt: '2023-04-06T14:35:15.014Z',
                updatedAt: '2023-04-06T14:35:15.014Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'orgLinksFooterLink',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                label: 'Tiktok',
                url: 'https://www.tiktok.com/@sweetgreen',
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
                },
                id: '5JUEXQxdAs9LcxSN2iNMqi',
                type: 'Entry',
                createdAt: '2023-04-06T14:35:29.691Z',
                updatedAt: '2023-04-06T14:35:29.691Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'orgLinksFooterLink',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                label: 'Spotify',
                url: 'https://open.spotify.com/user/1224421164',
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
                },
                id: '3sAa6HWhccIE1DE38ba9fD',
                type: 'Entry',
                createdAt: '2023-04-06T14:35:55.330Z',
                updatedAt: '2023-04-06T14:35:55.330Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'orgLinksFooterLink',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                label: 'Facebook',
                url: 'https://www.facebook.com/sweetgreen',
              },
            },
          ],
        },
      },
      {
        metadata: { tags: [] },
        sys: {
          space: {
            sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
          },
          id: '1phbLcUNyQZmYog8TFqwB5',
          type: 'Entry',
          createdAt: '2023-04-06T14:38:47.682Z',
          updatedAt: '2023-04-06T14:38:47.682Z',
          environment: {
            sys: { id: 'master', type: 'Link', linkType: 'Environment' },
          },
          revision: 1,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'organizationLinksFooterSection',
            },
          },
          locale: 'en-US',
        },
        fields: {
          name: 'Terms + Conditions',
          links: [
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
                },
                id: '3JjgG3TEY5TvJ7WnKwtZRz',
                type: 'Entry',
                createdAt: '2023-04-06T14:37:36.542Z',
                updatedAt: '2023-04-06T14:37:36.542Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'orgLinksFooterLink',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                label: 'Privacy Policy',
                url: 'https://www.sweetgreen.com/privacy-policy',
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
                },
                id: '4JrnNEX6oWY9AMXSeax6bl',
                type: 'Entry',
                createdAt: '2023-04-06T14:37:52.250Z',
                updatedAt: '2023-04-06T14:37:52.250Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'orgLinksFooterLink',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                label: 'Terms and Conditions',
                url: 'https://www.sweetgreen.com/terms',
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
                },
                id: '4VobC27iEOFX6qc11rw9Pw',
                type: 'Entry',
                createdAt: '2023-04-06T14:38:17.776Z',
                updatedAt: '2023-04-06T14:38:17.776Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'orgLinksFooterLink',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                label: 'Your privacy choices',
                url: 'https://www.sweetgreen.com/privacy-policy/ca-data-opt-out',
              },
            },
          ],
        },
      },
      {
        metadata: { tags: [] },
        sys: {
          space: {
            sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
          },
          id: '1E7Wo9naBQmeo5TmOqwt3d',
          type: 'Entry',
          createdAt: '2023-04-06T14:39:48.131Z',
          updatedAt: '2023-04-06T14:39:48.131Z',
          environment: {
            sys: { id: 'master', type: 'Link', linkType: 'Environment' },
          },
          revision: 1,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'organizationLinksFooterSection',
            },
          },
          locale: 'en-US',
        },
        fields: {
          name: 'Download the App',
          links: [
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
                },
                id: '3cw81Z0VKEGKgrI0PaY0bZ',
                type: 'Entry',
                createdAt: '2023-04-06T14:39:24.378Z',
                updatedAt: '2023-04-06T14:39:24.378Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'orgLinksFooterLink',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                label: 'iOS',
                url: 'https://apps.apple.com/us/app/sweetgreen/id594329490',
              },
            },
            {
              metadata: { tags: [] },
              sys: {
                space: {
                  sys: { type: 'Link', linkType: 'Space', id: 'wme4s8lvzccr' },
                },
                id: '78ogNhAVV8UfBl4tQFKKCl',
                type: 'Entry',
                createdAt: '2023-04-06T14:39:44.536Z',
                updatedAt: '2023-04-06T14:39:44.536Z',
                environment: {
                  sys: { id: 'master', type: 'Link', linkType: 'Environment' },
                },
                revision: 1,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'orgLinksFooterLink',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                label: 'Android',
                url: 'https://play.google.com/store/apps/details?id=com.sweetgreen.android.app&hl=en',
              },
            },
          ],
        },
      },
    ],
  },
};

export const validAccountMenuData = {
  fields: {
    name: 'Account Menu',
    links: [
      {
        metadata: {
          tags: [],
        },
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'wme4s8lvzccr',
            },
          },
          id: '6XLUpIHBOfyQzH3sXDXFaS',
          type: 'Entry',
          createdAt: '2024-02-15T14:15:27.876Z',
          updatedAt: '2024-02-15T14:15:27.876Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 1,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'orgLinksFooterLink',
            },
          },
          locale: 'en-US',
        },
        fields: {
          label: 'Terms',
          url: 'https://www.sweetgreen.com/terms',
        },
      },
      {
        metadata: {
          tags: [],
        },
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'wme4s8lvzccr',
            },
          },
          id: '48OCqOqYRzGSXZJ2QgNv0w',
          type: 'Entry',
          createdAt: '2024-02-15T14:16:40.841Z',
          updatedAt: '2024-02-15T14:16:40.841Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 1,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'orgLinksFooterLink',
            },
          },
          locale: 'en-US',
        },
        fields: {
          label: 'Privacy policy',
          url: 'https://www.sweetgreen.com/privacy-policy',
        },
      },
      {
        metadata: {
          tags: [],
        },
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'wme4s8lvzccr',
            },
          },
          id: '4VobC27iEOFX6qc11rw9Pw',
          type: 'Entry',
          createdAt: '2023-04-06T14:38:17.776Z',
          updatedAt: '2024-02-15T14:12:49.797Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 4,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'orgLinksFooterLink',
            },
          },
          locale: 'en-US',
        },
        fields: {
          label: 'Your privacy choices',
          url: 'https://www.sweetgreen.com/privacy-policy/ca-data-opt-out',
          icon: {
            metadata: {
              tags: [],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              id: 'jPDZBdi4zfHE75JovvSPB',
              type: 'Asset',
              createdAt: '2024-02-15T14:12:40.164Z',
              updatedAt: '2024-02-15T14:12:40.164Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              revision: 1,
              locale: 'en-US',
            },
            fields: {
              title: 'Privacy Opt Out Icon',
              description: '',
              file: {
                url: '//images.ctfassets.net/wme4s8lvzccr/jPDZBdi4zfHE75JovvSPB/5c862859fbb2ded4194732759918d973/privacy.svg',
                details: {
                  size: 1678,
                  image: {
                    width: 30,
                    height: 14,
                  },
                },
                fileName: 'privacy.svg',
                contentType: 'image/svg+xml',
              },
            },
          },
        },
      },
    ],
  },
  tags: {},
};

export const emptyContentfulData = {
  fields: {
    name: 'Order App Footer',
    sections: [{}],
  },
};

import { defineMessages } from 'react-intl';

export const orderAgainConflictReviewCloseButtonMessages = defineMessages({
  modalA11yLabel: {
    defaultMessage: 'Review conflicts modal',
    description: 'Order again | Conflict review | a11y label',
  },
  close: {
    defaultMessage: 'Close',
    description: 'Order again | Conflict review | Close',
  },
});

import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useActor } from '@xstate/react';
import { type AddressType } from '@sg/garnish';
import { type DeliveryOrderDetailInput } from '@sg/graphql-schema';

import { useGlobalAppState } from '@order/GlobalAppState';

import { getValidEventKeys } from '../../../../ReorderingScreen/hooks/utils';

// ────────────────────────────────────────────────────────────────────────────

/**
 * One of the requirements is that if you go back on reorder, you must go back to the bag.
 * This will navigate to reorder without replacing the bag with the change location modal.
 *
 * For that it must navigate by `navigation.navigate` instead of `navigation.replace`.
 * Note that using `navigation.navigate("ReorderChangeLocation")` somehow results in the location search not working.
 *
 * The existing `changeLocation` function from reorder will either replace or navigate outside of the original modal.
 * This is because it is too generic, lacking context from where reorder was started.
 * At a future date, we might wish to replace this reorder navigation with a global modal.
 */
export const useNavigateToReorderWithinModalStack = () => {
  const navigation = useNavigation();
  const { reorderMachineRef } = useGlobalAppState();
  const [_, sendReorderEvent] = useActor(reorderMachineRef);

  return useCallback(
    (params: StartReorderFromBagParams = {}) => {
      const {
        orderId,
        orderChannel,
        restaurantId,
        locationName,
        restaurantSlug,
        addressId,
      } = params;

      sendReorderEvent({
        type: 'CHANGE_LOCATION',
        ...getValidEventKeys({
          orderId,
          orderType: orderChannel,
          deliveryOrderDetail: { addressId },
          restaurantId,
          restaurantName: locationName,
          deliveryAddressName: locationName,
          restaurantSlug,
          userInitiated: true,
        }),
      });

      navigation.navigate('Modal', { screen: 'ReorderChangeLocation' });
    },
    [navigation, sendReorderEvent],
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────

export type StartReorderFromBagParams = {
  orderId?: string;
  orderChannel?: AddressType;
  deliveryOrderDetail?: Partial<DeliveryOrderDetailInput>;
  restaurantId?: string;
  locationName?: string;
  restaurantSlug?: string;
  addressId?: string;
};

import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, Text } from 'react-native';
import {
  BodyText,
  getFormattedExpirationDate,
  ModalDialogue,
  ModalRow,
  TextLink,
  TextLinkify,
  theme,
} from '@sg/garnish';

import type { ChallengeRewardsQuery } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { useRewardDetailsModalTelemetry } from './RewardDetailsModal.telemetry';

export const RewardDetailsModal = (props: ChallengesScreenRewardModalProps) => {
  const { reward, onDismiss } = props;
  const { t } = useLocalizationContext();
  const {
    handleRewardDetailsTelemetry,
    handleRewardClosedTelemetry,
    handleRewardLinkTelemetry,
  } = useRewardDetailsModalTelemetry();

  const [isVisible, setIsVisible] = useState(Boolean(reward));

  const rewardName = reward?.name ?? '';
  const rewardExpirationDate =
    getFormattedExpirationDate(reward?.expiresAt) ?? '';

  // ─── Terms Interpolation ─────────────────────────────────────────────────────

  const rawTerms = (reward?.terms ?? '') || t('challenges.rewards.modal.terms');
  const terms = rawTerms
    .replaceAll('{expirationDate}', rewardExpirationDate)
    .replaceAll('XX/XX/XX', rewardExpirationDate)
    .replaceAll('XX/XX', rewardExpirationDate)
    .replaceAll('MM/dd', rewardExpirationDate);

  // ─── HELPERS ─────────────────────────────────────────────────────

  const hideModal = useCallback(() => {
    setIsVisible(false);
    handleRewardClosedTelemetry();
  }, [handleRewardClosedTelemetry]);

  // ─── EFFECTS ─────────────────────────────────────────────────────

  useEffect(() => {
    setIsVisible(Boolean(reward));

    if (reward) handleRewardDetailsTelemetry(reward.id);
  }, [reward, handleRewardDetailsTelemetry]);

  // ─────────────────────────────────────────────────────────────────

  return (
    <ModalDialogue
      style={styles.modalRow}
      visible={isVisible}
      size="small"
      testID="challenges.rewards.modal"
      accessibilityLabel={t('challenges.rewards.description.a11y-title')}
      headline={rewardName}
      subhead={rewardExpirationDate}
      tag={reward?.tag}
      onRequestClose={hideModal}
      onDismissed={onDismiss}
      withBottomPadding
    >
      <ModalRow style={styles.modalRow}>
        <BodyText size={3} style={styles.rewardModalText}>
          {t('challenges.rewards.modal.text')}
        </BodyText>

        <BodyText size={3} style={styles.rewardModalText}>
          <TextLinkify>{terms}</TextLinkify>
        </BodyText>

        <BodyText size={3}>
          {t('general.see')}
          <Text> </Text>
          <TextLink
            href={REWARDS_TERMS_LINK}
            onPress={handleRewardLinkTelemetry}
          >
            {REWARDS_TERMS_LINK}
            <Text>.</Text>
          </TextLink>
        </BodyText>
      </ModalRow>
    </ModalDialogue>
  );
};

const styles = StyleSheet.create({
  modalRow: {
    paddingBottom: theme.spacing['6'],
  },
  rewardModalText: {
    paddingBottom: theme.spacing['4'],
  },
});

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

const REWARDS_TERMS_LINK = 'https://www.sweetgreen.com/sweet-rewards';

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ChallengesScreenRewardModalProps = Readonly<{
  reward:
    | (Pick<
        ChallengeRewardsQuery['challengeRewards'][number],
        'name' | 'expiresAt' | 'terms' | 'id'
      > & { tag?: string })
    | undefined;
  onDismiss: () => void;
}>;

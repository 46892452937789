import { useSweetpassNextBillingDateQuery } from './graphql/SweetpassNextBillingDate.generated';

export function useSweetpassNextBillingDate(pause: boolean) {
  const [response] = useSweetpassNextBillingDateQuery({
    pause,
    requestPolicy: 'cache-and-network',
  });

  const nextBillingDate = response.data?.getSubscriptions?.[0]?.nextBillingDate;
  const isFetchingNextBillingDate = response.fetching;

  return { nextBillingDate, isFetchingNextBillingDate };
}

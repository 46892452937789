import React, {
  createContext,
  type MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import type { ViewProps } from 'react-native';

import { useIsLoggingIn } from '@order/AuthMachine';
import {
  type ApplePayHandlersReference,
  LoadingAnimation,
  useApplePayPaymentMethod,
} from '@order/components';
import { useCustomer } from '@order/Customer';
import { useCart, useCustomerCredit, usePaymentMethods } from '@order/hooks';
import { useTelemetry } from '@order/Telemetry';
import { convertPriceToFloat } from '@order/utils';

import {
  getDefaultDropoffLocation,
  getDefaultPaymentMethod,
} from './Checkout.utils';
import {
  useAvailableWantedTimes,
  useCancelApplePayCheckout,
  useCheckoutCredit,
  useCheckoutFieldsValidation,
  useCheckoutHandlers,
  useCheckoutLedger,
  useCheckoutPayload,
  useDeliveryEstimate,
  useFutureDateModal,
  useOrderAddress,
  useOrderValidation,
  usePlaceOrder,
  useSubmitOrder,
} from './hooks';

// ─── Context ──────────────────────────────────────────────────────

const CheckoutContext = createContext<CheckoutCtx | undefined>(undefined);

export const CheckoutContextProvider = (props: CheckoutProviderProps) => {
  const { children, ...applePaySetupData } = props;

  const customerData = useCustomer();
  const cartData = useCart();
  const wantedTimesData = useAvailableWantedTimes();
  const paymentMethodsData = usePaymentMethods();
  const creditData = useCustomerCredit({ requestPolicy: 'cache-and-network' });
  const deliveryEstimateData = useDeliveryEstimate(cartData.cart);
  const applePayData = useApplePayPaymentMethod();

  // ─── Loading States ─────────────────────────────────────────────

  const isLoggingIn = useIsLoggingIn();
  const isLoadingCustomer = customerData.isFetchingCustomer;
  const isLoadingCart = cartData.isFetchingCart;
  const { isLoadingPaymentMethods } = paymentMethodsData;
  const { isLoadingCredits } = creditData;
  const { isLoadingDefaultPaymentMethodId } = applePayData;
  const { isSettingUpApplePay } = applePaySetupData;
  const isLoadingCheckout =
    isLoggingIn ||
    isLoadingCustomer ||
    isLoadingCart ||
    isLoadingPaymentMethods ||
    isLoadingCredits ||
    isLoadingDefaultPaymentMethodId ||
    isSettingUpApplePay;

  // ─── Prevent State Initialization ───────────────────────────────

  if (isLoadingCheckout) {
    return <LoadingAnimation />;
  }

  // ─── Context State ──────────────────────────────────────────────

  return (
    <CheckoutContextState
      customerData={customerData}
      cartData={cartData}
      wantedTimesData={wantedTimesData}
      paymentMethodsData={paymentMethodsData}
      creditData={creditData}
      deliveryEstimateData={deliveryEstimateData}
      applePayData={applePayData}
      applePaySetupData={applePaySetupData}
    >
      {children}
    </CheckoutContextState>
  );
};

const CheckoutContextState = (props: CheckoutStateCtx & ViewProps) => {
  const {
    customerData,
    cartData,
    wantedTimesData,
    paymentMethodsData,
    creditData,
    deliveryEstimateData,
    applePayData,
    applePaySetupData,
    children,
  } = props;

  // ─── Checkout Payload ────────────────────────────────────────────────

  // @ts-expect-error TS(2345): Argument of type '{ applePayHandlersRef?: React.Mu... Remove this comment to see the full error message
  const paymentMethod = getDefaultPaymentMethod({
    ...paymentMethodsData,
    ...applePayData,
    ...applePaySetupData,
  });

  const dropoffLocation = getDefaultDropoffLocation(
    cartData.cart,
    cartData.cartOrderType,
  );

  const payloadState = useCheckoutPayload({
    wantedTime: cartData?.cart?.availableWantedTimes?.[0]?.time,
    paymentMethodId: paymentMethod?.id,
    paymentMethodType: paymentMethod?.__typename,
    useCredit: creditData?.useCredit,
    dropoffLocationId: dropoffLocation?.id,
    contactNumber: customerData?.customer?.phoneNumber,
  });

  // ─── Query Derived Data ─────────────────────────────────────────

  const addressData = useOrderAddress(cartData.cart);

  // ─── Checkout State ─────────────────────────────────────────────

  const futureDateModalState = useFutureDateModal();
  const validationState = useCheckoutFieldsValidation(cartData.cartOrderType);
  const payloadHandlers = useCheckoutHandlers({
    setPayload: payloadState.setPayload,
    setShouldShowFutureDateModal:
      futureDateModalState.setShouldShowFutureDateModal,
  });

  // ─── State Derived Data ─────────────────────────────────────────

  const checkoutCreditData = useCheckoutCredit({
    ...cartData,
    ...creditData,
    ...payloadState,
  });

  const orderValidation = useOrderValidation({
    ...cartData,
    ...payloadState.payload,
  });

  const ledgerData = useCheckoutLedger({
    cart: cartData.cart,
    payload: payloadState.payload,
    credit: checkoutCreditData.checkoutCredit,
  });

  // ────────────────────────────────────────────────────────────────

  const submitOrderData = useSubmitOrder({
    cartData,
    payload: payloadState.payload,
    ledger: ledgerData.ledger,
  });

  const placeOrderData = usePlaceOrder({
    payload: payloadState.payload,
    orderId: cartData?.cart?.id ?? '',
    cannotSubmitOrderReason: orderValidation.cannotSubmitOrderReason,
    shouldBlockCheckoutOnPress: orderValidation.shouldBlockCheckoutOnPress,
    paymentMethods: paymentMethodsData.paymentMethods,
    handleInvalidWantedTime: wantedTimesData.handleInvalidWantedTime,
    validateCheckoutFields: validationState.validateCheckoutFields,
    submitOrder: submitOrderData.submitOrder,
  });

  // ─── Sync Apple Pay References ──────────────────────────────────

  const cancelApplePayData = useCancelApplePayCheckout({
    paymentMethods: paymentMethodsData.paymentMethods,
    handleCreditCardSelected: payloadHandlers.handleCreditCardSelected,
  });

  const { track } = useTelemetry();

  const handleApplePayOrder = useCallback(
    async (paymentMethodId: string) => {
      track('checkout.apple-pay', {
        ...payloadState.payload,
        orderValue: convertPriceToFloat(ledgerData.total),
      });

      await placeOrderData.validateAndSubmitOrder(paymentMethodId);
    },
    [ledgerData.total, payloadState.payload, placeOrderData, track],
  );

  useEffect(() => {
    if (!applePaySetupData?.applePayHandlersRef) return;

    // eslint-disable-next-line functional/immutable-data
    applePaySetupData.applePayHandlersRef.current = {
      onApplePayError: cancelApplePayData.cancelApplePayCheckout,
      onCapturePaymentMethodId: handleApplePayOrder,
    };
  }, [
    applePaySetupData.applePayHandlersRef,
    cancelApplePayData.cancelApplePayCheckout,
    handleApplePayOrder,
  ]);

  // ─── Memoized Data ──────────────────────────────────────────────

  const value: CheckoutCtx = useMemo(
    () => ({
      ...customerData,
      ...cartData,
      ...wantedTimesData,
      ...paymentMethodsData,
      ...creditData,
      ...submitOrderData,
      ...placeOrderData,
      ...deliveryEstimateData,
      ...applePayData,
      ...applePaySetupData,
      ...cancelApplePayData,
      ...addressData,
      ...payloadState,
      ...futureDateModalState,
      ...validationState,
      ...payloadHandlers,
      ...checkoutCreditData,
      ...orderValidation,
      ...ledgerData,
    }),
    [
      customerData,
      cartData,
      wantedTimesData,
      paymentMethodsData,
      creditData,
      submitOrderData,
      placeOrderData,
      deliveryEstimateData,
      applePayData,
      applePaySetupData,
      cancelApplePayData,
      addressData,
      payloadState,
      futureDateModalState,
      validationState,
      payloadHandlers,
      checkoutCreditData,
      orderValidation,
      ledgerData,
    ],
  );

  // ─── Context Provider ───────────────────────────────────────────

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
};

// ─── Hooks ────────────────────────────────────────────────────────

export const useCheckoutContext = () => {
  const context = useContext(CheckoutContext);

  if (context === undefined) {
    throw new Error(
      'useCheckoutContext must be used within a <CheckoutContextProvider>',
    );
  }

  return context;
};

// ─── Types ────────────────────────────────────────────────────────

type ApplePaySetupData = {
  applePayHandlersRef?: MutableRefObject<ApplePayHandlersReference>;
  isSettingUpApplePay?: boolean;
  isApplePayReady?: boolean;
  sendShowPaymentSheetRequest?: (amount: number) => void;
};

type CheckoutProviderProps = ViewProps & ApplePaySetupData;

type CheckoutStateCtx = Readonly<{
  customerData: ReturnType<typeof useCustomer>;
  cartData: ReturnType<typeof useCart>;
  wantedTimesData: ReturnType<typeof useAvailableWantedTimes>;
  paymentMethodsData: ReturnType<typeof usePaymentMethods>;
  creditData: ReturnType<typeof useCustomerCredit>;
  deliveryEstimateData: ReturnType<typeof useDeliveryEstimate>;
  applePayData: ReturnType<typeof useApplePayPaymentMethod>;
  applePaySetupData: ApplePaySetupData;
}>;

type CheckoutQueries = ReturnType<typeof useCustomer> &
  ReturnType<typeof useCart> &
  ReturnType<typeof useAvailableWantedTimes> &
  ReturnType<typeof usePaymentMethods> &
  ReturnType<typeof useCustomerCredit> &
  ReturnType<typeof useDeliveryEstimate>;

type CheckoutCtx = CheckoutQueries &
  ApplePaySetupData &
  ReturnType<typeof useCheckoutPayload> &
  ReturnType<typeof useFutureDateModal> &
  ReturnType<typeof useCheckoutFieldsValidation> &
  ReturnType<typeof useCheckoutHandlers> &
  ReturnType<typeof useApplePayPaymentMethod> &
  ReturnType<typeof useCancelApplePayCheckout> &
  ReturnType<typeof useOrderAddress> &
  ReturnType<typeof useSubmitOrder> &
  ReturnType<typeof usePlaceOrder> &
  ReturnType<typeof useCheckoutCredit> &
  ReturnType<typeof useOrderValidation> &
  ReturnType<typeof useCheckoutLedger>;

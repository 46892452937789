import type { CartModelContext, CartMutation } from './Cart.machine.model';
import { cartModel } from './Cart.machine.model';

// ─── ACTIONS ────────────────────────────────────────────────────────────────────

export const registerCartMutation = cartModel.assign((context, event) => {
  const { mutationName } = event;
  const { pendingMutations } = context;

  const currentValue = pendingMutations[mutationName];

  return {
    pendingMutations: {
      ...pendingMutations,
      [mutationName]: currentValue + 1,
    },
  };
}, 'REGISTER_PENDING_MUTATION');

export const unregisterPendingCartMutation = cartModel.assign(
  (context, event) => {
    const { mutationName } = event;
    const { pendingMutations } = context;

    const currentValue = pendingMutations[mutationName];

    if (currentValue === 0) return {};

    return {
      pendingMutations: {
        ...pendingMutations,
        [mutationName]: currentValue - 1,
      },
    };
  },
  'UNREGISTER_PENDING_MUTATION',
);

export const registerCartView = cartModel.assign((context, event) => {
  const { cartId } = event;
  const { cartViewCounts } = context;

  const currentValue = cartViewCounts[cartId] ?? 0;

  return {
    cartViewCounts: {
      ...cartViewCounts,
      [cartId]: currentValue + 1,
    },
  };
}, 'REGISTER_CART_VIEW');

// ─── GUARDS ─────────────────────────────────────────────────────────────────────

export function checkIfHasPendingMutation(context: CartModelContext) {
  const pendingMutationsTotal = getPendingMutationsTotal(context);

  return pendingMutationsTotal > 0;
}

export function checkIfFinishedFetching(context: CartModelContext) {
  const pendingMutationsTotal = getPendingMutationsTotal(context);

  return pendingMutationsTotal === 0;
}

// ─── OTHER HELPERS ──────────────────────────────────────────────────────────────

export function getPendingMutationsNames(context: CartModelContext) {
  const pendingMutations = Object.entries(context.pendingMutations);

  return pendingMutations.reduce((names, [name, mutationsNumber]) => {
    const cartMutationName = name as CartMutation;

    if (mutationsNumber > 0) return names.add(cartMutationName);

    return names;
  }, new Set<CartMutation>());
}

function getPendingMutationsTotal(context: CartModelContext) {
  const pendingMutations = Object.values(context.pendingMutations);

  return pendingMutations.reduce(
    (total, currentValue) => total + currentValue,
    0,
  );
}

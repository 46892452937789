import React, { memo, useRef } from 'react';
import type { PressableProps } from 'react-native';
import { Pressable, StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';
import type { AddressType } from '@sg/garnish';
import {
  theme,
  useActiveAnimation,
  usePressableState,
  webOnlyStyles,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import { useTrackEventEffect } from '@order/Telemetry';

import { HomeScreenCTAContent } from './HomeScreenCTAContent';

export const HomeScreenCTA = memo((props: HomeScreenCTAProps) => {
  const {
    isPendingFeedbackStatus,
    shouldShowCartAction,
    shouldShowStatusAction,
    cartOrderType,
    orderType,
    enhancedOrderStatus,
    restaurantName,
    dropoffLocation,
    deliveryAddressName,
    timeEstimate,
    dayOfWeek,
    wantedTimeTs,
    timeRangeStartTs,
    timeRangeEndTs,
    ...pressableProps
  } = props;

  const pressableRef = useRef(null);

  const { isInteracting } = usePressableState(pressableRef);
  const { t } = useLocalizationContext();

  // ─────────────────────────────────────────────────────────────────

  const title = shouldShowCartAction
    ? t('home.welcome.bag-cta.title', { orderType: cartOrderType })
    : t('home.welcome.cta.orderStatusHeadline', {
        orderType,
        enhancedOrderStatus,
        timeEstimate,
        dayOfWeek,
        wantedTimeTs,
        timeRangeStartTs,
        timeRangeEndTs,
        restaurantName,
      });

  const subtitle = shouldShowStatusAction
    ? t('home.welcome.cta.orderStatusBody', {
        orderType,
        enhancedOrderStatus,
        restaurantName,
        dropoffLocation,
        deliveryAddressName,
      })
    : '';

  const a11yHint = shouldShowCartAction
    ? t('home.welcome.bag-cta.title', { orderType: cartOrderType })
    : t('home.welcome.cta.a11yHint', { orderType, enhancedOrderStatus });

  // ─────────────────────────────────────────────────────────────────

  const pressableAnimatedStyle = useActiveAnimation(
    isInteracting,
    isPendingFeedbackStatus
      ? theme.colors.OPACITY.TRANSPARENT
      : theme.colors.KALE,
    isPendingFeedbackStatus
      ? theme.colors.OPACITY.KALE.LIGHTER
      : theme.colors.SPINACH,
  );
  const pressableStyles = [
    styles.pressable,
    webOnlyStyles({
      outlineColor: theme.colors.GRAY,
      outlineOffset: theme.spacing['2'],
    }),
  ];

  const cardStyles = [styles.card, pressableAnimatedStyle];

  const hasUnrecognizedOrderStatus = !title && !subtitle;

  useTrackEventEffect({
    name: 'home.cta.unrecognized-order-status',
    payload: { status: enhancedOrderStatus, orderType },
    skip: !hasUnrecognizedOrderStatus,
  });

  if (hasUnrecognizedOrderStatus) {
    return null;
  }

  // ─────────────────────────────────────────────────────────────────

  return (
    <Animated.View testID="home.dynamic-cta" style={cardStyles}>
      <Pressable
        style={pressableStyles}
        testID="home.dynamic-Callout"
        accessibilityRole="button"
        accessibilityLabel={title}
        accessibilityHint={a11yHint}
        ref={pressableRef}
        {...pressableProps}
      >
        <HomeScreenCTAContent
          testID="home.welcome.cta-card"
          title={title}
          subtitle={subtitle}
          palette={isPendingFeedbackStatus ? 'secondary' : 'primary'}
        />
      </Pressable>
    </Animated.View>
  );
});

// ─── STYLES ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  card: {
    borderRadius: theme.radius.large,
  },
  pressable: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type HomeScreenCTAProps = Readonly<{
  isPendingFeedbackStatus: boolean;
  shouldShowCartAction: boolean;
  shouldShowStatusAction: boolean;
  cartOrderType: AddressType;
  restaurantName?: string | null;
  dropoffLocation?: string | null;
  deliveryAddressName?: string | null;
  orderType: AddressType;
  enhancedOrderStatus: string;
  timeEstimate: string;
  dayOfWeek: string;
  wantedTimeTs?: Date;
  timeRangeStartTs?: string | Date;
  timeRangeEndTs?: string | Date;
}> &
  PressableProps;

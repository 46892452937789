import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { Button } from '../../../Button';
import { Modal, ModalDialogue } from '../../../Modal';
import { TitleText } from '../../../Text';
import type { PickerModalProps } from '../Picker.types';

export const PickerModalDialogue = (props: PickerModalProps) => {
  const {
    show,
    label,
    submitBtnLabel,
    labelNativeID,
    children,
    onSubmit,
    onDismiss,
  } = props;

  return (
    <ModalDialogue visible={show} onRequestClose={onDismiss}>
      <View style={styles.modalContentWrapper}>
        {label ? (
          <TitleText
            nativeID={labelNativeID}
            size={6}
            style={styles.modalTitle}
          >
            {label}
          </TitleText>
        ) : null}

        {children}
      </View>

      <Modal.Footer>
        <Button onPress={onSubmit} accessibilityRole="button" size="large">
          {submitBtnLabel}
        </Button>
      </Modal.Footer>
    </ModalDialogue>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  modalContentWrapper: {
    paddingHorizontal: theme.spacing['4'],
  },
  modalTitle: {
    paddingBottom: theme.spacing['2'],
  },
});

import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, theme } from '@sg/garnish';

import { ApplePay, useApplePayPaymentMethod } from '../ApplePay';

/**
 * This is a wrapper around the `ApplePay.SetAsDefaultButton`.
 * It adds a divider line with "Or enter card manually" below it.
 */
export const CreditCardFormApplePay = (props: CreditCardFormApplePayProps) => {
  const { onSetApplePayAsDefault } = props;
  const { isApplePayDefault } = useApplePayPaymentMethod();

  if (isApplePayDefault || !onSetApplePayAsDefault) {
    return null;
  }

  return (
    <View>
      <ApplePay.SetAsDefaultButton onPress={onSetApplePayAsDefault} />

      <View style={styles.dividerRow}>
        <View style={styles.dividerLine} />
        <BodyText style={styles.dividerTitle}>
          <FormattedMessage {...messages.enterManuallyHint} />
        </BodyText>
        <View style={styles.dividerLine} />
      </View>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  dividerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing['4'],
  },
  dividerTitle: {
    flex: 2,
    textAlign: 'center',
  },
  dividerLine: {
    flex: 1,
    height: 1,
    backgroundColor: theme.colors.BLACK,
    marginHorizontal: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CreditCardFormApplePayProps = {
  onSetApplePayAsDefault?: () => void;
};

// ─── Localization ────────────────────────────────────────────────────────────

const messages = defineMessages({
  enterManuallyHint: {
    defaultMessage: 'Or enter card manually',
    description:
      'Explains to the customer they can add the card via form instead of Apple Pay.',
  },
});

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { type AddressType, BodyText, TextSpace } from '@sg/garnish';

import {
  BagChannelHeader,
  BagLocationFromConnector,
  BagLocationHeader,
  BagLocationToConnector,
} from '../../Header';

/**
 * Shortened header for order channel and location.
 */
export const BagChannelAndLocationInfo = (
  props: BagChannelAndLocationInfoProps,
) => {
  const {
    orderChannel,
    locationName,
    requestChannelOrLocationChange,
    requestDeliveryPreferencesChange,
  } = props;

  const { formatMessage } = useIntl();

  const changeChannelA11yLabel = formatMessage(
    messages.changeChannelA11yLabel,
    { channel: orderChannel },
  );

  const changeLocationA11yLabel = formatMessage(
    messages.changeLocationA11yLabel,
    { location: locationName },
  );

  return (
    <BodyText style={styles.headerText} sizeMatch={['16']} numberOfLines={1}>
      {orderChannel === 'outpost' ? null : (
        <BagChannelHeader
          accessibilityLabel={changeChannelA11yLabel}
          orderChannel={orderChannel}
          requestChannelOrLocationChange={requestChannelOrLocationChange}
        />
      )}

      {orderChannel === 'outpost' ? null : <TextSpace />}

      {orderChannel === 'pickup' ? <BagLocationFromConnector /> : null}

      {orderChannel === 'delivery' ? <BagLocationToConnector /> : null}

      {orderChannel === 'outpost' ? null : <TextSpace />}

      <BagLocationHeader
        accessibilityLabel={changeLocationA11yLabel}
        orderChannel={orderChannel}
        locationName={locationName}
        requestDeliveryPreferencesChange={requestDeliveryPreferencesChange}
        requestChannelOrLocationChange={requestChannelOrLocationChange}
      />
    </BodyText>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  changeChannelA11yLabel: {
    defaultMessage: 'Change channel shortcut: {channel}',
    description: 'Bag | Header | Change channel shortcut',
  },
  changeLocationA11yLabel: {
    defaultMessage: 'Change location shortcut: {location}',
    description: 'Bag | Header | Change location shortcut',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  headerText: {
    textAlign: 'center',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagChannelAndLocationInfoProps = {
  orderChannel: AddressType;
  locationName: string;
  requestChannelOrLocationChange: () => void;
  requestDeliveryPreferencesChange: () => void;
};

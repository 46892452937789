import type { TelemetryEventName } from '../../TelemetryEvent';
import type { TelemetrySelectedProduct } from '../../TelemetryEvent.types';
import type { TelemetryEventOverride } from '../../TelemetryEventOverride';
import { shouldHandleAppSessionEvent } from '../../TelemetryEventOverride';
import type { AppsFlyer } from '../../vendors/apps-flyer/AppsFlyer.types';

export const appsFlyerEventOverrides: AppsFlyerEventOverrides = {
  'app.session': ({ event }) =>
    shouldHandleAppSessionEvent(event)
      ? { event: { eventName: 'session' } }
      : undefined,
  'app.first_open': () => ({
    event: {
      eventName: 'first_open',
    },
  }),
  'join.success': () => ({
    event: {
      eventName: 'af_complete_registration',
    },
  }),
  'sign-in.success': () => ({
    event: {
      eventName: 'af_login',
    },
  }),
  'sweetpass.view': () => ({
    event: {
      eventName: 'af_view_sweetpass',
    },
  }),
  'sweetpass_benefits.view.success': () => ({
    event: {
      eventName: 'af_explore_sweetpass',
    },
  }),
  'sweetpass.checkout.view': () => ({
    event: {
      eventName: 'af_add_to_cart_sweetpass+',
    },
  }),
  'sweetpass_account.upgrade.success': () => ({
    event: {
      eventName: 'af_upgrade_sweetpass+_success',
    },
  }),
  'home.primary_channel': () => ({
    event: {
      eventName: 'order_now',
    },
  }),
  'home.secondary_channel': () => ({
    event: {
      eventName: 'order_now',
    },
  }),
  'home.evergreen_channel': () => ({
    event: {
      eventName: 'order_now',
    },
  }),
  'scan-to-pay.view': () => ({
    event: {
      eventName: 'scan_to_pay',
    },
  }),
  'location.search': () => ({
    event: {
      eventName: 'af_search',
    },
  }),
  'menu.view': () => ({
    event: {
      eventName: 'af_list_view',
    },
  }),
  'pdp.view': ({ event: { payload } }) => ({
    event: {
      eventName: 'af_content_view',
      eventValues: formatSelectedProductPayload(payload),
    },
  }),
  'menu.view_collection': () => ({
    event: {
      eventName: 'popular_collections',
    },
  }),
  'pdp.add_to_bag': ({ event: { payload } }) => ({
    event: {
      eventName: 'af_add_to_cart',
      eventValues: {
        ...formatBagPayload(payload),
        ...formatSelectedProductPayload(payload),
      },
    },
  }),
  'menu.quick_add_to_bag': ({ event: { payload } }) => ({
    event: {
      eventName: 'af_add_to_cart',
      eventValues: formatSelectedProductPayload(payload),
    },
  }),
  'menu.one_click_add_to_bag_submit': ({ event: { payload } }) => ({
    event: {
      eventName: 'af_add_to_cart',
      eventValues: formatSelectedProductPayload(payload),
    },
  }),
  'checkout.view': ({ event: { payload } }) => ({
    event: {
      eventName: 'af_initiated_checkout',
      eventValues: formatBagPayload(payload),
    },
  }),
  'checkout.success': ({ event: { payload } }) => ({
    event: {
      eventName: 'af_purchase',
      eventValues: {
        ...formatBagPayload(payload),
        af_new_customer: payload?.isNewCustomer,
        af_revenue: payload.orderValue,
      },
    },
  }),
  contact_us: () => ({
    event: {
      eventName: 'contact_us',
    },
  }),
  social_media_links: ({ event }) => ({
    event: {
      eventName: 'social_media_links',
      eventValues: {
        link: event.payload.link,
      },
    },
  }),
  'rate_order.success': () => ({
    event: {
      eventName: 'rate_order',
    },
  }),
  'dietary-restrictions.select': ({ event }) => ({
    event: {
      eventName: 'dietary-restriction',
      eventValues: {
        restriction: event.payload.restriction,
        selected: event.payload.selected,
      },
    },
  }),
};

// –– HELPERS –––––––––––––––––––––––––

export const formatSelectedProductPayload = ({
  selectedProduct,
}: SelectedProductPayload) => {
  if (!selectedProduct) return {};

  return {
    af_price: selectedProduct.cost,
    af_content_id: selectedProduct.id,
    af_content: selectedProduct.name,
    af_currency: 'USD',
  };
};

export const formatBagPayload = (payload: BagPayload) => ({
  af_order_id: payload.orderId,
  af_quantity: payload.bagCount,
  af_price: payload.orderValue,
  af_currency: 'USD',
  af_content_id: payload.products?.map(({ id }) => id).join(','),
});

// –– TYPES –––––––––––––––––––––––––––

export type AppsFlyerEventOverrides = {
  readonly [Name in TelemetryEventName]?: TelemetryEventOverride<
    Name,
    AppsFlyerEvent
  >;
};

type AppsFlyerLogEventParams = Parameters<AppsFlyer['logEvent']>;

export type AppsFlyerEvent = Readonly<{
  eventName: AppsFlyerLogEventParams[0];
  eventValues?: AppsFlyerLogEventParams[1];
}>;

type SelectedProductPayload = Readonly<{
  selectedProduct?: TelemetrySelectedProduct;
}>;

type BagPayload = Readonly<{
  orderId?: string;
  bagCount?: number;
  orderValue?: number;
  products?: readonly TelemetrySelectedProduct[];
}>;

import type { MutableRefObject } from 'react';
import { devtoolsExchange } from '@urql/devtools';
import { Client, fetchExchange } from 'urql';

import { logger } from '../logger';
import type { ErrorHandlerReference } from '../UrqlProvider';
import { getFetchOptions, getGraphQLEndpoint } from '../utils';
import { authExchange } from './auth-exchange';
import { errorTrackingExchange } from './errorTrackingExchange';
import { graphcacheExchange } from './graphcache-exchange';
import { requestPolicyExchange } from './requestPolicyExchange';

/**
 * 📣 PLEASE DO NOT ADD CODE DIRECTLY TO THIS FILE 📣
 * The intention of this file is to exhibit just the high-level urql config
 * so it can be quickly understood, without noise from implementation code 🤘
 */

export const createClient = (params: CreateClientParams): Client => {
  const { errorHandlerReference, logOutHandlerReference } = params;

  logger.debug('Created urql client');

  return new Client({
    url: getGraphQLEndpoint(),

    /**
     * NOTE: There are some type differences across urql packages that cause
     *       TypeScript issues, therefore, we're casting some exchanges as
     *       `Exchange` types from the main urql package, to hide such errors.
     *
     *       Issues are limited to the type system and do not cause any
     *       functional concerns.
     *
     * IMPORTANT: Check those exchanges before each urql package upgrade
     *            to ensure that they work as intended.
     */
    exchanges: [
      devtoolsExchange,
      graphcacheExchange,
      errorTrackingExchange({ errorHandlerReference, logOutHandlerReference }),
      authExchange,
      fetchExchange,
      requestPolicyExchange,
    ],

    fetchOptions: getFetchOptions(),
  });
};

// ─── Types ───────────────────────────────────────────────────────────────────

type CreateClientParams = {
  errorHandlerReference: ErrorHandlerReference;
  logOutHandlerReference: MutableRefObject<(() => Promise<void>) | null>;
};

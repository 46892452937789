import QRCode from 'qrcode';

/**
 * NOTE: This code is adapted from `react-native-qrcode-svg`
 * https://github.com/awesomejerry/react-native-qrcode-svg
 */

type ErrCorrectionLevel = 'L' | 'M' | 'Q' | 'H';

export default (value: string, errorCorrectionLevel?: ErrCorrectionLevel) => {
  const arr = Array.prototype.slice.call(
    QRCode.create(value, { errorCorrectionLevel }).modules.data,
    0,
  );
  const sqrt = Math.sqrt(arr.length);

  return arr.reduce(
    (rows, key, index) =>
      (index % sqrt === 0 ? rows.push([key]) : rows.at(-1).push(key)) && rows,
    [],
  );
};

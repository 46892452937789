/* istanbul ignore file */

import { checkIfNewLocationWarningIsRequired } from './location-warning.helpers';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Determines whether the location warning should be displayed.
 */
export async function checkIfShouldShowLocationWarning(
  location: MaybeLocationWithWarning,
) {
  if (!location) return;

  const { id, showWarningDialog, warningDialogTitle } = location;

  const shouldShowLocationWarning =
    Boolean(showWarningDialog) && warningDialogTitle !== undefined;

  if (!shouldShowLocationWarning) return false;

  return checkIfNewLocationWarningIsRequired(id);
}

// ─── Types ───────────────────────────────────────────────────────────────────

type MaybeLocationWithWarning =
  | Readonly<{
      id: string;
      showWarningDialog?: boolean;
      warningDialogTitle?: string;
      warningDialogTimeout?: number;
    }>
  | undefined;

import { useRef } from 'react';
import type { View } from 'react-native';

// Stubbed version for web
export const useKeyboardAvoiderScroll = (_isFocused: boolean) => {
  const avoiderRef = useRef<View>(null);

  return { avoiderRef };
};

// Stubbed version for web
export const useKeyboardAvoiderPadding = () => {
  return { keyboardPadding: null };
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddonsQueryVariables = Types.Exact<{
  costChannel?: Types.InputMaybe<Types.CostChannel>;
}>;

export type AddonsQuery = {
  readonly __typename?: 'Query';
  readonly addons: {
    readonly __typename?: 'Addons';
    readonly products: ReadonlyArray<{
      readonly __typename?: 'Product';
      readonly id: string;
      readonly name: string;
      readonly cost: number;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    }>;
  };
};

export const AddonsDocument = gql`
  query addons($costChannel: CostChannel) {
    addons {
      products {
        id
        name
        cost: channelCost(costChannel: $costChannel)
        asset {
          url
        }
      }
    }
  }
`;

export function useAddonsQuery(
  options?: Omit<Urql.UseQueryArgs<AddonsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AddonsQuery, AddonsQueryVariables>({
    query: AddonsDocument,
    ...options,
  });
}

import {
  type OrderStatusLiveActivityParams,
  type StartOrderStatusLiveActivity,
} from './useOrderStatusLiveActivity.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * NOTE: Order status live activities widget is iOS only.
 */
export const useOrderStatusLiveActivity = (
  _params: OrderStatusLiveActivityParams,
) => {
  return { startOrderStatusLiveActivity: startOrderStatusLiveActivityNoOp };
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const startOrderStatusLiveActivityNoOp: StartOrderStatusLiveActivity = () => {};

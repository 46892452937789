/* istanbul ignore file */

import { convertDistance, getDistance } from 'geolib';

import type {
  Location,
  LocationSearchMachineContext,
  LocationsSearchType,
  PositionCoordinates,
  StoreLocation,
} from '../../LocationSearch.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns formatted locations divided into groups according to their type (pickup, outpost).
 */
export function formatAndSplitLocations(
  locations: readonly StoreLocation[],
  currentPosition: PositionCoordinates | undefined,
) {
  return splitLocationsByTypes(formatLocations(locations, currentPosition));
}

export function extractLocation<
  LocationData extends Readonly<{ location: unknown }>,
>(locationData: LocationData): LocationData['location'] {
  return locationData.location;
}

export function checkIfNonNullable<Value>(value: Value | null): value is Value {
  return value !== null;
}

export function getOrderLocationType(order: Order): LocationsSearchType {
  const isDeliveryOrder = Boolean(order?.deliveryOrderDetail);
  const isOutpostOrder = Boolean(order.restaurant.isOutpost);

  if (isDeliveryOrder) return 'delivery';

  if (isOutpostOrder) return 'outpost';

  return 'pickup';
}

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Splits provided locations based on their type (pickup, outpost).
 */
function splitLocationsByTypes(
  locations: Location[],
): NonNullable<LocationSearchMachineContext['locations']> {
  return {
    pickup: locations.filter((location) => !location.isOutpost),
    outpost: locations.filter((location) => location.isOutpost),
  };
}

/**
 * Formats provided locations by extracting relevant data and calculating
 * distance from the customer's position (if available).
 */
function formatLocations(
  locations: readonly StoreLocation[],
  currentPositionCoordinates?: PositionCoordinates,
) {
  return locations
    .map(extractLocationDetails)
    .map(addDistanceToLocation(currentPositionCoordinates))
    .sort(compareLocationsByDistance);
}

/**
 * Extracts relevant data from locations query data.
 */
function extractLocationDetails(location: StoreLocation): Location {
  return {
    id: location.id,
    name: location.name,
    address: location.address,

    storeHours: location.storeHours || undefined,
    acceptingOrders: location.acceptingOrders,
    restaurantId: location.id,
    restaurantSlug: location.slug,
    restaurantName: location.name,
    flexMessage: location.flexMessage,

    notAcceptingOrdersReason: location.notAcceptingOrdersReason || undefined,
    imageUrl: location.imageUrl,
    city: location.city,
    state: location.state,
    zipCode: location.zipCode,

    phone: location.phone || undefined,
    lat: location.latitude,
    lng: location.longitude,
    showWarningDialog: location.showWarningDialog,

    warningDialogTitle: location.warningDialogTitle || undefined,
    warningDialogDescription: location.warningDialogDescription || undefined,
    warningDialogTimeout: location.warningDialogTimeout || 0,

    isOutpost: location.isOutpost || false,

    outpostPriceDifferentiationEnabled:
      location?.outpostPriceDifferentiationEnabled ?? false,
  };
}

/**
 * Adds location distance from current position in miles.
 */
function addDistanceToLocation(
  currentPositionCoordinates?: PositionCoordinates,
) {
  return function (location: Location): Location {
    const locationPositionCoordinates =
      getLocationPositionCoordinates(location);
    const canDetermineDistance =
      currentPositionCoordinates !== undefined &&
      locationPositionCoordinates !== undefined;

    if (!canDetermineDistance) return location;

    return {
      ...location,
      distance: getLocationDistance(
        currentPositionCoordinates,
        locationPositionCoordinates,
      ),
    };
  };
}

/**
 * Returns location position coordinates if those are available.
 */
function getLocationPositionCoordinates(
  location: Location,
): PositionCoordinates | undefined {
  const { lat, lng } = location;

  const hasValidCoordinates = lat !== undefined && lng !== undefined;

  return hasValidCoordinates ? { latitude: lat, longitude: lng } : undefined;
}

/**
 * Returns location distance (in miles) from the current position.
 */
function getLocationDistance(
  currentPosition: PositionCoordinates,
  locationPosition: PositionCoordinates,
) {
  const distance = getDistance(currentPosition, locationPosition);
  const distanceInMiles = convertDistance(distance, 'mi');

  return distanceInMiles.toFixed(2);
}

/**
 * Compares two locations depending on their distance.
 */
function compareLocationsByDistance(
  currentLocation: MaybeLocationWithDistance,
  nextLocation: MaybeLocationWithDistance,
) {
  const { distance: currentLocationDistance = '0' } = currentLocation;
  const { distance: nextLocationDistance = '0' } = nextLocation;

  return Number(currentLocationDistance) - Number(nextLocationDistance);
}

// ─── Types ───────────────────────────────────────────────────────────────────

type MaybeLocationWithDistance = Readonly<{
  distance?: string;
}>;

type Order = Readonly<{
  restaurant: {
    isOutpost: boolean;
  };
  deliveryOrderDetail: {
    id: string;
  } | null;
}>;

import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Button } from '../../../Button';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanCardCta = (props: ScanCardBaseProps) => {
  const { children, accessibilityLabel, accessibilityHint, onPress } = props;

  return (
    <View style={styles.container}>
      <Button
        size="large"
        accessibilityLabel={accessibilityLabel}
        accessibilityHint={accessibilityHint}
        onPress={onPress}
      >
        {children}
      </Button>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginTop: 'auto',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanCardBaseProps = Pick<
  ComponentProps<typeof Button>,
  'children' | 'onPress' | 'accessibilityLabel' | 'accessibilityHint'
>;

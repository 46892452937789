import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { BodyText, SectionHeader, TagLabel, theme } from '@sg/garnish';

import { type SweetpassMembershipPlans } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

export const SweetpassMembershipPlan = (
  props: SweetpassMembershipPlanProps,
) => {
  const { selectedPlanId, plans } = props;

  const { t } = useLocalizationContext();

  // ─── Plans ───────────────────────────────────────────────────────────

  const annualPlan = plans?.annual;
  const monthlyPlan = plans?.monthly;

  const isAnnualPlanSelected = selectedPlanId === annualPlan?.id;
  const selectedPlan = isAnnualPlanSelected ? annualPlan : monthlyPlan;

  // @ts-expect-error TS(2339): Property 'price' does not exist on type '{ id: str... Remove this comment to see the full error message
  const { price, pricePerMonth, savingAmount } = selectedPlan;

  // ─── Localization ────────────────────────────────────────────────────

  const title = isAnnualPlanSelected
    ? t('sweetpass.membership-plan.annual.title-plan')
    : t('sweetpass.membership-plan.monthly.title-plan');

  const subtitle = isAnnualPlanSelected
    ? t('sweetpass.membership-plan.annual.subtitle', {
        price: `$${price}`,
        pricePerMonth: `$${pricePerMonth}`,
      })
    : t('sweetpass.membership-plan.monthly.subtitle', {
        price: `$${price}`,
      });

  const saveLabel = t('sweetpass.membership-plan.save-label', {
    amount: savingAmount,
  });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View>
      <SectionHeader
        heading={t('sweetpass.membership-plan.heading')}
        style={styles.header}
      />
      <View style={styles.titleRow}>
        <BodyText sizeMatch={['14']} bold>
          {title}
        </BodyText>

        {isAnnualPlanSelected ? (
          <TagLabel size={3}>{saveLabel}</TagLabel>
        ) : null}
      </View>

      <BodyText sizeMatch={['14']}>{subtitle}</BodyText>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    marginBottom: theme.spacing['4'],
  },
  titleRow: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: Platform.select({ default: 'center', web: 'baseline' }),
    columnGap: theme.spacing['2'],
    marginBottom: theme.spacing['1'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassMembershipPlanProps = {
  selectedPlanId?: string;
  plans: SweetpassMembershipPlans;
};

import React from 'react';
import * as Clipboard from 'expo-clipboard';
import { openNativeShare, theme, VStack } from '@sg/garnish';

import { LineItemCard } from '@order/components';
import type { PartialLineItem } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';
import { getHostName } from '@order/utils';

import { useFavoriteLineItemMutation } from './graphql/OrderStatus.generated';

export const OrderStatusLineItems = ({
  lineItems,
}: Readonly<{
  lineItems: readonly PartialLineItem[];
}>) => {
  const { t } = useLocalizationContext();
  const [{ fetching: isFetchingFavorite }, favoriteLineItem] =
    useFavoriteLineItemMutation();

  const toggleFavorite = React.useCallback(
    async ({ lineItemId, productId, favorited }: ToggleFavoriteType) => {
      if (!productId || !lineItemId) return;
      await favoriteLineItem({
        input: {
          favorited,
          lineItemId,
          productId,
        },
      });
    },
    [favoriteLineItem],
  );

  const handleShareFavoritePress = React.useCallback(
    (url: string, name: string) => {
      const nativeText = t('general.share-favorite', { productName: name });

      void openNativeShare({
        message: nativeText,
        url,
        options: {
          subject: nativeText,
        },
      });
      void Clipboard.setStringAsync(url);
    },
    [t],
  );

  return (
    <VStack
      gap={0}
      hasDivider={true}
      dividerColor={theme.colors.LIGHT}
      testID="order-status-details-line-items"
    >
      {lineItems.map((lineItem) => {
        const url = `${getHostName()}/share/${lineItem.slug}`;

        return (
          <LineItemCard
            key={lineItem.id}
            lineItem={lineItem}
            onFavorite={() => {
              void toggleFavorite({
                lineItemId: lineItem.id,
                productId: lineItem.product.id,
                favorited: !lineItem.favorited,
              });
            }}
            isFetchingFavorite={isFetchingFavorite}
            isFavorite={lineItem.favorited}
            onShare={handleShareFavoritePress}
            shareBtnLabel={t('order-status-info.share-product')}
            shareTooltipLabel={t('order-status-info.share-product-tooltip')}
            shareUrl={url}
          />
        );
      })}
    </VStack>
  );
};

type ToggleFavoriteType = Readonly<{
  lineItemId?: string;
  productId?: string;
  favorited: boolean;
}>;

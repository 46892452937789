import React, { type ComponentProps } from 'react';
import { Platform, View, type ViewProps } from 'react-native';
import { useStyle } from 'react-native-style-utilities';

import { useResponsive } from '../../hooks';
import { useContainerSpacing } from '../Container';
import { IconLink } from '../Icon';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders a button for the navigation header on a small breakpoint.
 */
export const NavigationHeaderButton = (props: NavigationHeaderButtonProps) => {
  const { iconName, iconSize = 24, ...iconLinkProps } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const { currentBreakpoint } = useResponsive();
  const containerSpacing = useContainerSpacing();

  const containerStyles = useStyle(
    () => ({ paddingHorizontal: Platform.select({ web: containerSpacing }) }),
    [containerSpacing],
  );

  // ─────────────────────────────────────────────────────────────────────

  if (!currentBreakpoint.isXS) {
    return null;
  }

  return (
    <View style={containerStyles}>
      <IconLink name={iconName} iconSize={iconSize} {...iconLinkProps} />
    </View>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type NavigationHeaderButtonProps = {
  iconName: ComponentProps<typeof IconLink>['name'];
  iconSize?: number;
  styles?: ViewProps['style'];
} & Omit<IconLinkProps, 'style' | 'width' | 'height' | 'name' | 'ref'>;

type IconLinkProps = ComponentProps<typeof IconLink>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeletePaymentMethodCardMutationVariables = Types.Exact<{
  input: Types.DeletePaymentMethodCardInput;
}>;

export type DeletePaymentMethodCardMutation = {
  readonly __typename?: 'Mutation';
  readonly deletePaymentMethodCard: {
    readonly __typename: 'PaymentMethodCard';
    readonly id: string;
  };
};

export const DeletePaymentMethodCardDocument = gql`
  mutation deletePaymentMethodCard($input: DeletePaymentMethodCardInput!) {
    deletePaymentMethodCard(input: $input) {
      __typename
      id
    }
  }
`;

export function useDeletePaymentMethodCardMutation() {
  return Urql.useMutation<
    DeletePaymentMethodCardMutation,
    DeletePaymentMethodCardMutationVariables
  >(DeletePaymentMethodCardDocument);
}

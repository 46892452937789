import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * The container for the payment header above the ledger.
 */
export const BagPaymentHeaderContainer = (props: PropsWithChildren) => {
  return <View style={styles.container}>{props.children}</View>;
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingTop: theme.spacing['1'],
  },
});

import { useCallback } from 'react';
import ReactDOM from 'react-dom';

import { useBrowserEventListener, useWebKeyListener } from '../../hooks';
import type { OverlayProps } from './Overlay.types';

export const Overlay = (props: OverlayProps) => {
  const { dismiss, triggerRef, contentRef, show, children } = props;

  //
  // ─── HELPERS ──────────────────────────────────────────────────────────────────
  //

  const dismissOnPressOutside = useCallback(
    async (event: any) => {
      const targetNode = event.target as Node;
      const elementEl = contentRef.current as unknown as HTMLElement;
      const toggleEl = triggerRef.current as unknown as HTMLElement;

      if (!elementEl || !toggleEl) return;

      const shouldDismiss =
        !elementEl.contains(targetNode) && !toggleEl.contains(targetNode);

      if (shouldDismiss) {
        dismiss();
      }
    },
    [contentRef, triggerRef, dismiss],
  );

  //
  // ─── EFFECTS ──────────────────────────────────────────────────────────────────
  //

  useBrowserEventListener(
    window,
    'click',
    dismissOnPressOutside,
    CLICK_EVENT_LISTENER_OPTIONS,
    !show,
  );
  useBrowserEventListener(window, 'scroll', dismissOnPressOutside, true, !show);
  useWebKeyListener('Escape', dismiss, !show);

  // ────────────────────────────────────────────────────────────────────────────────

  return ReactDOM.createPortal(children, document.body);
};

// ─── Constants ───────────────────────────────────────────────────────────────

const CLICK_EVENT_LISTENER_OPTIONS: AddEventListenerOptions = {
  capture: true, // Capture all `click` events, even those that should not be propagated.
};

import React from 'react';
import { StyleSheet } from 'react-native';
import { theme, useResponsive, VStack } from '@sg/garnish';

import type { CreditSectionProps } from '../CreditSectionProps';
import { CreditOverviewBalance } from './CreditOverviewBalance';
import { CreditOverviewPromoCodeForm } from './CreditOverviewPromoCodeForm';

export const CreditOverview = (props: CreditSectionProps) => {
  const { currentBreakpoint } = useResponsive();

  return (
    <VStack
      gap={0}
      hasDivider={currentBreakpoint.isXS}
      dividerColor={theme.colors.LIGHT}
      style={!currentBreakpoint.isXS && styles.container}
    >
      <CreditOverviewBalance {...props} />
      <CreditOverviewPromoCodeForm {...props} />
    </VStack>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'stretch',
    justifyContent: 'center',
  },
});

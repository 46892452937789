import type { OrderStatus } from '@order/graphql';
import {
  checkIfExpiredOrder,
  checkIfLateOrder,
  checkIfPastOrder,
  getEnhancedOrderStatus,
  getOrderTime,
} from '@order/OrderStatus';
import { getOrderTimeRange, ignoreTimezone } from '@order/utils';

/**
 * Derives home data from current order status.
 */
export const useHomeOrderStatusData = (props: HomeOrderStatusDataProps) => {
  const {
    isLoading,
    orderStatus,
    hasCart,
    inStoreOrderPendingFeedbackId,
    inStoreOrderPendingFeedbackWantedTime,
  } = props;

  // ──── Query Data ─────────────────────────────────────────────────────

  const orderId = orderStatus?.order?.id;
  const order = orderStatus?.order;
  const flattenedOrderStatus = orderStatus?.flattenedOrderStatus;
  const isPendingFeedback = order?.isPendingFeedback;

  // ──── No Previous Order ──────────────────────────────────────────────

  if (isLoading || !order || !orderId || !flattenedOrderStatus) {
    return {};
  }

  // ──── Time Range ─────────────────────────────────────────────────────

  const orderTimeString = getOrderTime(order);
  const orderTimeDate = orderTimeString
    ? ignoreTimezone(orderTimeString)
    : undefined;
  const orderTimeRange = getOrderTimeRange(orderTimeDate);

  // ──── Timed Statuses ─────────────────────────────────────────────────

  const isLate = checkIfLateOrder({ order, flattenedOrderStatus });
  const isPast = checkIfPastOrder({ order, flattenedOrderStatus });
  const isExpired = checkIfExpiredOrder({ order });

  // ──── Derived Statuses ───────────────────────────────────────────────

  const enhancedOrderStatus = getEnhancedOrderStatus({
    orderStatus: flattenedOrderStatus,
    hasCart,
    isLate,
    isPast,
    isPendingFeedback,
    isTrackable: order?.canTrackOrderStatus,
  });

  // ──── Rating In-Store Order ──────────────────────────────────────────
  // If there is an in-store order pending feedback.
  // And no higher priority orders to be shown instead of it.
  // Then we return a forced feedback state for it.

  const inStoreOrder = { wantedTime: inStoreOrderPendingFeedbackWantedTime };
  const isInStoreOrderExpired = checkIfExpiredOrder({ order: inStoreOrder });
  const canRateInStoreOrder =
    inStoreOrderPendingFeedbackId && !isInStoreOrderExpired;
  const hasHigherPriorityOrders = checkIfHasHighPriorityStatus(
    flattenedOrderStatus,
    hasCart,
    isPast,
  );

  if (canRateInStoreOrder && !hasHigherPriorityOrders) {
    return getInStoreOrderPendingFeedbackForcedState(
      inStoreOrderPendingFeedbackId,
      order,
      orderTimeRange,
      orderTimeDate,
    );
  }

  return {
    order,
    orderId,
    isPast,
    isExpired,
    isPendingFeedback,
    enhancedOrderStatus,
    orderTimeDate,
    orderTimeRange,
  };
};

/**
 *  We consider ongoing orders as high priority, these will be shown before rate order requests.
 */
function checkIfHasHighPriorityStatus(
  flattenedOrderStatus: string,
  hasCart: boolean,
  isPast: boolean,
) {
  const isOngoingStatus = [
    'received',
    'preparing',
    'readyForCourier',
    'delivering',
  ].includes(flattenedOrderStatus);

  const isRecentlyCompleted = flattenedOrderStatus === 'completed' && !isPast;

  // These flags represent an ongoing order.
  return hasCart || isOngoingStatus || isRecentlyCompleted;
}

/**
 * Forced feedback state for in store orders pending feedback.
 */
function getInStoreOrderPendingFeedbackForcedState(
  inStoreOrderPendingFeedbackId: string,
  order: OrderStatus['order'],
  orderTimeRange: ReturnType<typeof getOrderTimeRange>,
  orderTimeDate?: Date,
) {
  // These fields are not necessary for in-store rating.
  const dataNotNeededForInStoreRating = {
    order,
    orderTimeDate,
    orderTimeRange,
  };

  return {
    orderId: inStoreOrderPendingFeedbackId,
    isPast: true,
    isExpired: false,
    isPendingFeedback: true,
    enhancedOrderStatus: 'feedback',
    ...dataNotNeededForInStoreRating,
  };
}

// ─── Types ────────────────────────────────────────────────────────

type HomeOrderStatusDataProps = Readonly<{
  isLoading: boolean;
  hasCart: boolean;
  orderStatus?: OrderStatus | null;
  inStoreOrderPendingFeedbackId?: string;
  inStoreOrderPendingFeedbackWantedTime?: string;
}>;

/* istanbul ignore file */ /* plugin extension code */
// cSpell:ignore appboy

import * as Braze from '@braze/web-sdk';

import { getEnvVars } from '@order/utils';

import type { BrazeClient, BrazeUser } from './braze.types';
import { runIfBrazeCredentialsProvided } from './braze.utils';

// ────────────────────────────────────────────────────────────────────────────────

const { BRAZE_APP_ID_WEB, BRAZE_APP_SDK_ENDPOINT } = getEnvVars();

const init = runIfBrazeCredentialsProvided(() => {
  Braze.initialize(BRAZE_APP_ID_WEB as string, {
    baseUrl: BRAZE_APP_SDK_ENDPOINT,
    allowUserSuppliedJavascript: true,
  });
  Braze.automaticallyShowInAppMessages();
});

const destroy = runIfBrazeCredentialsProvided(Braze.destroy);

const changeUser = runIfBrazeCredentialsProvided((user: BrazeUser) => {
  const { id, trackingUuid, email, firstName, lastName } = user;

  if (!id) return;

  Braze.changeUser(trackingUuid ?? id);

  const brazeUser = Braze.getUser();

  if (email) brazeUser?.setEmail(email);

  if (firstName) brazeUser?.setFirstName(firstName);

  if (lastName) brazeUser?.setLastName(lastName);

  Braze.openSession();
});

const logCustomEvent: BrazeClient['logCustomEvent'] =
  runIfBrazeCredentialsProvided((eventName, eventPayload) => {
    Braze?.logCustomEvent?.(eventName, eventPayload);
  });

/**
 * Record in-app purchases so that you can track your revenue over time and across revenue sources,
 * as well as segment your users by their lifetime value (NOTE: from Braze docs).
 *
 * @see {@link https://www.braze.com/docs/developer_guide/platform_integration_guides/react_native/analytics/#logging-purchases Logging purchases | React Native}
 * @see {@link https://www.braze.com/docs/developer_guide/platform_integration_guides/web/analytics/logging_purchases/ Logging purchases | Web}
 */
const logPurchase: BrazeClient['logPurchase'] = runIfBrazeCredentialsProvided(
  (...params) => {
    Braze?.logPurchase(...params);
  },
);

// ────────────────────────────────────────────────────────────────────────────────

export const client: BrazeClient = {
  init,
  destroy,
  changeUser,
  logCustomEvent,
  logPurchase,
};

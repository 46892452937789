/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import { ValidationErrorFieldsFragmentDoc } from '../../../graphql/fragments/ValidationErrorFields.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetAvailableWantedTimesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetAvailableWantedTimesQuery = {
  readonly __typename?: 'Query';
  readonly cart: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly availableWantedTimes: ReadonlyArray<{
      readonly __typename?: 'AvailableWantedTime';
      readonly time: string;
      readonly deliveryOffset: number;
    }>;
  } | null;
};

export type DeliveryEstimateQueryVariables = Types.Exact<{
  input: Types.LocationByAddressInput;
}>;

export type DeliveryEstimateQuery = {
  readonly __typename?: 'Query';
  readonly locationByAddress:
    | { readonly __typename: 'InvalidInput' }
    | {
        readonly __typename: 'Location';
        readonly estimateRange: {
          readonly __typename?: 'EstimateRange';
          readonly start: number;
          readonly end: number;
        };
      }
    | { readonly __typename: 'NoValidRestaurants' }
    | { readonly __typename: 'ValidationError' };
};

export type SubmitOrderUsingPaymentMethodCardMutationVariables = Types.Exact<{
  input: Types.SubmitOrderUsingPaymentMethodCardInput;
}>;

export type SubmitOrderUsingPaymentMethodCardMutation = {
  readonly __typename?: 'Mutation';
  readonly submitOrderUsingPaymentMethodCard:
    | { readonly __typename: 'DeliveryAlreadyInFlight' }
    | { readonly __typename: 'DeliverySpecifyAddress' }
    | { readonly __typename: 'InvalidBillingAccount' }
    | { readonly __typename: 'InvalidCustomerPhone' }
    | {
        readonly __typename: 'InvalidTip';
        readonly message: string;
        readonly status: number;
      }
    | { readonly __typename: 'MinimumDeliverySubtotalNotMet' }
    | {
        readonly __typename: 'ProductUnavailable';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RestaurantIsNotAcceptingOrders';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RewardNotValidated';
        readonly message: string;
        readonly status: number;
        readonly failureCode: Types.ValidateRewardFailureCode;
      }
    | { readonly __typename: 'StoreOffline' }
    | { readonly __typename: 'SubmitOrderUsingBillingAccountSuccess' }
    | {
        readonly __typename: 'SubmitOrderUsingPaymentMethodCardSuccess';
        readonly order: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly wantedTime: string;
        };
      }
    | { readonly __typename: 'TimeslotUnavailable' }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type SubmitOrderUsingPaymentMethodMutationVariables = Types.Exact<{
  input: Types.SubmitOrderUsingPaymentMethodInput;
}>;

export type SubmitOrderUsingPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly submitOrderUsingPaymentMethod:
    | { readonly __typename: 'DeclinedPaymentMethod' }
    | { readonly __typename: 'DeliveryAlreadyInFlight' }
    | { readonly __typename: 'DeliverySpecifyAddress' }
    | { readonly __typename: 'IncorrectPaymentMethod' }
    | { readonly __typename: 'InvalidCustomerPhone' }
    | {
        readonly __typename: 'InvalidTip';
        readonly message: string;
        readonly status: number;
      }
    | { readonly __typename: 'MinimumDeliverySubtotalNotMet' }
    | {
        readonly __typename: 'ProductUnavailable';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RestaurantIsNotAcceptingOrders';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RewardNotValidated';
        readonly message: string;
        readonly status: number;
        readonly failureCode: Types.ValidateRewardFailureCode;
      }
    | { readonly __typename: 'StoreOffline' }
    | {
        readonly __typename: 'SubmitOrderUsingPaymentMethodSuccess';
        readonly order: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly wantedTime: string;
        };
      }
    | { readonly __typename: 'TimeslotUnavailable' }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type SubmitOrderUsingPaymentMethodSuccessFragment = {
  readonly __typename?: 'SubmitOrderUsingPaymentMethodSuccess';
  readonly order: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly wantedTime: string;
  };
};

export type SubmitOrderSuccessFragment = {
  readonly __typename?: 'SubmitOrderUsingPaymentMethodCardSuccess';
  readonly order: {
    readonly __typename?: 'Order';
    readonly id: string;
    readonly wantedTime: string;
  };
};

export type RewardNotValidatedFieldsFragment = {
  readonly __typename?: 'RewardNotValidated';
  readonly message: string;
  readonly status: number;
  readonly failureCode: Types.ValidateRewardFailureCode;
};

export type ProductUnavailableFieldsFragment = {
  readonly __typename?: 'ProductUnavailable';
  readonly message: string;
  readonly status: number;
};

export type RestaurantIsNotAcceptingOrdersFieldsFragment = {
  readonly __typename?: 'RestaurantIsNotAcceptingOrders';
  readonly message: string;
  readonly status: number;
};

export type InvalidTipFieldsFragment = {
  readonly __typename?: 'InvalidTip';
  readonly message: string;
  readonly status: number;
};

export const SubmitOrderUsingPaymentMethodSuccessFragmentDoc = gql`
  fragment SubmitOrderUsingPaymentMethodSuccess on SubmitOrderUsingPaymentMethodSuccess {
    order {
      id
      wantedTime
    }
  }
`;
export const SubmitOrderSuccessFragmentDoc = gql`
  fragment SubmitOrderSuccess on SubmitOrderUsingPaymentMethodCardSuccess {
    order {
      id
      wantedTime
    }
  }
`;
export const RewardNotValidatedFieldsFragmentDoc = gql`
  fragment RewardNotValidatedFields on RewardNotValidated {
    message
    status
    failureCode
  }
`;
export const ProductUnavailableFieldsFragmentDoc = gql`
  fragment ProductUnavailableFields on ProductUnavailable {
    message
    status
  }
`;
export const RestaurantIsNotAcceptingOrdersFieldsFragmentDoc = gql`
  fragment RestaurantIsNotAcceptingOrdersFields on RestaurantIsNotAcceptingOrders {
    message
    status
  }
`;
export const InvalidTipFieldsFragmentDoc = gql`
  fragment InvalidTipFields on InvalidTip {
    message
    status
  }
`;
export const GetAvailableWantedTimesDocument = gql`
  query getAvailableWantedTimes {
    cart {
      id
      availableWantedTimes {
        time
        deliveryOffset
      }
    }
  }
`;

export function useGetAvailableWantedTimesQuery(
  options?: Omit<
    Urql.UseQueryArgs<GetAvailableWantedTimesQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetAvailableWantedTimesQuery,
    GetAvailableWantedTimesQueryVariables
  >({ query: GetAvailableWantedTimesDocument, ...options });
}
export const DeliveryEstimateDocument = gql`
  query DeliveryEstimate($input: LocationByAddressInput!) {
    locationByAddress(input: $input) {
      __typename
      ... on Location {
        estimateRange {
          start
          end
        }
      }
    }
  }
`;

export function useDeliveryEstimateQuery(
  options: Omit<Urql.UseQueryArgs<DeliveryEstimateQueryVariables>, 'query'>,
) {
  return Urql.useQuery<DeliveryEstimateQuery, DeliveryEstimateQueryVariables>({
    query: DeliveryEstimateDocument,
    ...options,
  });
}
export const SubmitOrderUsingPaymentMethodCardDocument = gql`
  mutation SubmitOrderUsingPaymentMethodCard(
    $input: SubmitOrderUsingPaymentMethodCardInput!
  ) {
    submitOrderUsingPaymentMethodCard(input: $input) {
      __typename
      ...SubmitOrderSuccess
      ...ValidationErrorFields
      ...RewardNotValidatedFields
      ...ProductUnavailableFields
      ...RestaurantIsNotAcceptingOrdersFields
      ...InvalidTipFields
    }
  }
  ${SubmitOrderSuccessFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
  ${RewardNotValidatedFieldsFragmentDoc}
  ${ProductUnavailableFieldsFragmentDoc}
  ${RestaurantIsNotAcceptingOrdersFieldsFragmentDoc}
  ${InvalidTipFieldsFragmentDoc}
`;

export function useSubmitOrderUsingPaymentMethodCardMutation() {
  return Urql.useMutation<
    SubmitOrderUsingPaymentMethodCardMutation,
    SubmitOrderUsingPaymentMethodCardMutationVariables
  >(SubmitOrderUsingPaymentMethodCardDocument);
}
export const SubmitOrderUsingPaymentMethodDocument = gql`
  mutation SubmitOrderUsingPaymentMethod(
    $input: SubmitOrderUsingPaymentMethodInput!
  ) {
    submitOrderUsingPaymentMethod(input: $input) {
      __typename
      ...SubmitOrderUsingPaymentMethodSuccess
      ...ValidationErrorFields
      ...RewardNotValidatedFields
      ...ProductUnavailableFields
      ...RestaurantIsNotAcceptingOrdersFields
      ...InvalidTipFields
    }
  }
  ${SubmitOrderUsingPaymentMethodSuccessFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
  ${RewardNotValidatedFieldsFragmentDoc}
  ${ProductUnavailableFieldsFragmentDoc}
  ${RestaurantIsNotAcceptingOrdersFieldsFragmentDoc}
  ${InvalidTipFieldsFragmentDoc}
`;

export function useSubmitOrderUsingPaymentMethodMutation() {
  return Urql.useMutation<
    SubmitOrderUsingPaymentMethodMutation,
    SubmitOrderUsingPaymentMethodMutationVariables
  >(SubmitOrderUsingPaymentMethodDocument);
}

import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import {
  type RedeemGiftCardMutationVariables,
  useRedeemGiftCardMutation,
} from '../../graphql/RedeemGiftCard.generated';

// ────────────────────────────────────────────────────────────────────────────────

export const useRedeemGiftCard = () => {
  const [, redeemGiftCardMutation] = useRedeemGiftCardMutation();

  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const addErrorBanner = useCallback(
    (id: string, text: string) => {
      addNoticeBanner({ id, text, palette: 'caution' }, true);
    },
    [addNoticeBanner],
  );

  const addSuccessBanner = useCallback(
    (text: string) => {
      addNoticeBanner({ text, palette: 'success' });
    },
    [addNoticeBanner],
  );

  const redeemGiftCard = useCallback(
    async (variables: RedeemGiftCardMutationVariables) => {
      const { data, error } = await redeemGiftCardMutation(variables);

      const redeemGiftCardData = data?.redeemGiftCard;
      const responseTypename = redeemGiftCardData?.__typename;

      // ─────────────────────────────────────────────────────────────────────

      const hasNetworkError = error !== undefined;
      const isUnableToRedeemGiftCard =
        hasNetworkError || responseTypename === 'UnableToRedeemGiftCardError';
      const isInvalidGiftCard = responseTypename === 'InvalidGiftCardError';
      const isAlreadyRedeemedGiftCard =
        responseTypename === 'GiftCardAssociatedWithAnotherAccountError';
      const hasRedeemedGiftCard = responseTypename === 'GiftCardBalance';
      const isNoBalanceError = responseTypename === 'NoBalanceGiftCardError';

      // ─────────────────────────────────────────────────────────────────────

      if (isUnableToRedeemGiftCard) {
        addErrorBanner(
          'unable-to-redeem-gift-card-error-banner',
          t('redeem-gift-card-form.submit.unable-to-redeem-error'),
        );

        return;
      }

      if (isNoBalanceError) {
        addErrorBanner(
          'no-balance-gift-card-error-banner',
          t('redeem-gift-card-form.submit.no-remaining-balance-error'),
        );

        return;
      }

      if (isInvalidGiftCard) {
        addErrorBanner(
          'invalid-gift-card-error-banner',
          t('redeem-gift-card-form.submit.invalid-gift-card-error'),
        );

        return;
      }

      if (isAlreadyRedeemedGiftCard) {
        addErrorBanner(
          'already-applied-gift-card-error-banner',
          t('redeem-gift-card-form.submit.already-applied-error'),
        );

        return;
      }

      if (hasRedeemedGiftCard) {
        addSuccessBanner(t('redeem-gift-card-form.submit.success'));
      }

      return { data, error };
    },
    [redeemGiftCardMutation, addErrorBanner, t, addSuccessBanner],
  );

  return { redeemGiftCard };
};

import { useCallback } from 'react';

import { useCart } from '@order/hooks';

import type { PartialLineItem } from '../../../../graphql/partials';
import { useConfirmLocation } from '../useConfirmLocation';
import { useConflictReview } from '../useConflictReview';

// If there is no current bag, show location confirmation modal.
// Otherwise, check if there would be conflicts.
export const useReorderLineItem = (lineItem: PartialLineItem) => {
  const { cart, isCartEmpty, cartOrderType } = useCart();
  const { deliveryOrderDetail, restaurant } = cart ?? {};

  const confirmLocation = useConfirmLocation();
  const conflictReview = useConflictReview();

  return useCallback(async () => {
    if (isCartEmpty) {
      confirmLocation({
        lineItemId: lineItem?.id,
        restaurantId: lineItem?.product?.restaurant?.id,
        restaurantSlug: lineItem?.product?.restaurant?.slug,
        restaurantName: lineItem?.product?.restaurant?.name,
        orderType: 'pickup',
        userInitiated: true,
      });

      return;
    }

    void conflictReview({
      lineItemId: lineItem?.id,
      orderType: cartOrderType,
      deliveryAddressName: deliveryOrderDetail?.address?.name ?? undefined,
      deliveryOrderDetail: deliveryOrderDetail?.address?.id
        ? {
            addressId: deliveryOrderDetail?.address?.id,
            deliveryFee: deliveryOrderDetail?.deliveryFee,
            tip: deliveryOrderDetail?.tip,
            vendor: deliveryOrderDetail?.vendor,
            vendorRestaurantId: deliveryOrderDetail?.vendorRestaurantId,
          }
        : undefined,
      restaurantId: restaurant?.id ?? '',
      restaurantSlug: restaurant?.slug ?? '',
      restaurantName: restaurant?.name ?? '',
      userInitiated: true,
    });
  }, [
    lineItem?.id,
    lineItem?.product?.restaurant?.id,
    lineItem?.product?.restaurant?.slug,
    lineItem?.product?.restaurant?.name,
    isCartEmpty,
    cartOrderType,
    deliveryOrderDetail?.address?.name,
    deliveryOrderDetail?.address?.id,
    deliveryOrderDetail?.deliveryFee,
    deliveryOrderDetail?.tip,
    deliveryOrderDetail?.vendor,
    deliveryOrderDetail?.vendorRestaurantId,
    restaurant?.id,
    restaurant?.slug,
    restaurant?.name,
    conflictReview,
    confirmLocation,
  ]);
};

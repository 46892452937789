import type { ComponentProps } from 'react';
import React, { useCallback, useLayoutEffect } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import type { Asset } from 'contentful';
import { openURL } from 'expo-linking';
import {
  BodyText,
  Button,
  Container,
  DisplayText,
  FallbackImage,
  IllusEmpty_2,
  theme,
  useNoticeBannersStackContext,
} from '@sg/garnish';

import { SG_APP_STORE_APP_URL, SG_PLAY_MARKET_APP_URL } from '@order/constants';
import { useContentfulContentTypeEntry } from '@order/Contentful';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry, useTrackEffect } from '@order/Telemetry';
import { getAppVersion } from '@order/utils';

import { LoadingAnimation } from '../LoadingAnimation';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * Renders force update view with the corresponding message and CTA.
 *
 * NOTE: This mode is intended to be used on native platforms using conditional rendering.
 */
export const ForceUpdateMode = () => {
  const { t } = useLocalizationContext();
  const { track } = useTelemetry();
  const { disable: disableNoticeBanners, enable: enableNoticeBanners } =
    useNoticeBannersStackContext();

  // ─── Remote Data ─────────────────────────────────────────────────────

  const { data, fetching } =
    useContentfulContentTypeEntry<ContentfulForceAppUpdateFields>({
      contentType: FORCE_UPDATE_CONTENTFUL_CONTENT_ID,
    });

  const { cta, image, bodyText, headingText } = data?.fields ?? {};
  const { appStoreUrl, playMarketUrl, title: ctaTitle } = cta?.fields ?? {};
  const { file: imageFile } = image?.fields ?? {};

  // ─── Helpers ─────────────────────────────────────────────────────────

  const openSgAppStorePage = useCallback(() => {
    if (!IS_FORCE_UPDATE_MODE_AVAILABLE) return;

    track('forced_update.cta_tapped', { appVersion: getAppVersion() });

    const sgAppStoreUrl = Platform.select({
      ios: appStoreUrl ?? SG_APP_STORE_APP_URL,
      android: playMarketUrl ?? SG_PLAY_MARKET_APP_URL,
    });

    if (sgAppStoreUrl) void openURL(sgAppStoreUrl);
  }, [appStoreUrl, playMarketUrl, track]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useTrackEffect('forced_update.viewed', { appVersion: getAppVersion() });

  // block new notifications, while in force update mode
  useLayoutEffect(() => {
    disableNoticeBanners();

    return enableNoticeBanners;
  }, [disableNoticeBanners, enableNoticeBanners]);

  // ─────────────────────────────────────────────────────────────────────

  if (fetching) {
    return (
      <LoadingAnimation
        withNavbarOffset
        backgroundColor={theme.colors.APP_BACKGROUND}
      />
    );
  }

  return (
    <Container
      style={styles.contentContainer}
      wrapperStyle={styles.contentContainerWrapperStyle}
    >
      <FallbackImage
        style={styles.img}
        baseUrl={imageFile?.url ?? ''}
        defaultImage={IllusEmpty_2}
        contentfulOptions={REMOTE_IMG_CONFIG}
        contentFit="contain"
      />

      <DisplayText size={2} style={styles.heading} accessibilityRole="header">
        {headingText ?? t('force-update.title')}
      </DisplayText>

      <View style={styles.textContainer}>
        <BodyText style={styles.text} size={2}>
          {bodyText ?? t('force-update.text')}
        </BodyText>
      </View>

      {IS_FORCE_UPDATE_MODE_AVAILABLE ? (
        <Button onPress={openSgAppStorePage} size="large">
          {ctaTitle ?? t('force-update.cta')}
        </Button>
      ) : null}
    </Container>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const IS_FORCE_UPDATE_MODE_AVAILABLE =
  Platform.OS === 'ios' || Platform.OS === 'android';

const FORCE_UPDATE_CONTENTFUL_CONTENT_ID = 'nativeAppForceAppUpdate';

const IMG_SIZE = 200;
const REMOTE_IMG_CONFIG: ComponentProps<
  typeof FallbackImage
>['contentfulOptions'] = {
  w: IMG_SIZE,
  h: IMG_SIZE,
  fit: 'pad',
  fm: 'webp',
  q: 90,
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  contentContainerWrapperStyle: {
    flex: 1,
    justifyContent: 'center',
    paddingVertical: theme.spacing['4'],
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  contentContainer: {
    alignItems: 'center',
  },
  img: {
    width: IMG_SIZE,
    height: IMG_SIZE,
    marginBottom: theme.spacing['4'],
  },
  heading: {
    textAlign: 'center',
    marginBottom: theme.spacing['4'],
  },
  textContainer: {
    marginBottom: theme.spacing['6'],
  },
  text: {
    textAlign: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ContentfulForceAppUpdateFields = Readonly<{
  headingText?: string;
  bodyText?: string;
  image?: Asset;
  cta?: {
    fields?: {
      title?: string;
      appStoreUrl?: string;
      playMarketUrl?: string;
    };
  };
}>;

export const getGreetingMode = (props?: GetGreetingMode): GreetingMode => {
  const { isLoggedIn = false, hasActiveOrder = false } = props ?? {};

  if (!isLoggedIn) return 'loggedOut';

  if (hasActiveOrder) return 'newCustomer';

  if (isSpecialDay()) return 'specialDay';

  return getTimeOfDay();
};

// ─── UTILS ───────────────────────────────────────────────────────────────────

export const getTimeOfDay = (): GreetingMode => {
  const time = getCurrentTime();

  if (isMorning(time)) return 'morning';

  if (isAfternoon(time)) return 'afternoon';

  if (isEvening(time)) return 'evening';

  return 'morning';
};

const getCurrentTime = () => {
  const currentTime = new Date();
  const hour = currentTime.getHours();
  const minuteFraction = currentTime.getMinutes() / 60;
  const time = hour + minuteFraction;

  return time;
};

const isMorning = (time: number) => time >= 4 && time < 12;
const isAfternoon = (time: number) => time >= 12 && time < 16.5;
const isEvening = (time: number) => time >= 16.5 || time < 4;

// ──────────────────────────────────────────────────────

const isSpecialDay = () => false;

// ─── TYPES ───────────────────────────────────────────────────────────────────

type GetGreetingMode = Readonly<{
  isLoggedIn?: boolean;
  hasActiveOrder?: boolean;
}>;

type GreetingMode =
  | 'loggedOut'
  | 'newCustomer'
  | 'morning'
  | 'afternoon'
  | 'evening'
  | 'specialDay'
  | 'generic';

import React, { useCallback, useEffect } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';

import { Bag } from '@order/features/ordering';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { useCloseBag } from '../hooks';
import { BagAnimatedDialog, BagContent } from './components';
import { useOrderState } from './hooks';

export const BagScreenV2 = () => {
  const isLoyaltyV2Enabled = useFeatureFlag('CELS-2685-loyalty-v2-enabled');
  const isExpressPaymentsEnabled = useFeatureFlag(
    'permanent-order-stripe-express-checkout-enabled',
  );

  const orderState = useOrderState({
    isLoyaltyV2Enabled,
    isExpressPaymentsEnabled,
  });

  const { isShowingModal } = orderState;
  const { closeBag } = useCloseBag();

  // ─── Navigation Options ───────────────────────────────────────────────────
  // Here we remove the header, as we use a custom one, it can be removed in navigation options once we delete the previous screen.
  // Additionally, we are disabling gestures when the animated dialog is shown, as we don't want users to dismiss the bag when attempting to dismiss it.
  // When the screen is unfocused, we re-enable the gestures so it doesn't break other screens in the modal stack.

  const navigation = useNavigation();

  useEffect(() => {
    navigation.setOptions({ headerShown: false });
    navigation.getParent()?.setOptions({ gestureEnabled: !isShowingModal });
  }, [navigation, isShowingModal]);

  useFocusEffect(
    useCallback(() => {
      return () => {
        navigation.getParent()?.setOptions({ gestureEnabled: true });
      };
    }, [navigation]),
  );

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <Bag.DynamicModal onClose={closeBag}>
      <BagContent orderState={orderState} />

      <BagAnimatedDialog orderState={orderState} />
    </Bag.DynamicModal>
  );
};

import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { BodyText, Button, Form, theme, useResponsive } from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

import { useRedeemGiftCardAutoSubmit, useRedeemGiftCardForm } from './hooks';
import { redeemGiftCardFormMessages as messages } from './RedeemGiftCardForm.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const RedeemGiftCardForm = (props: RedeemGiftCardFormProps) => {
  const {
    withHelperText = true,
    shouldWrapInlineCta,
    shouldDisplayCtaInline,
    giftCardCode,
    giftCardRegistrationCode,
    onRedeemSubmit,
    onRedeemSuccess,
    onRedeemError,
  } = props;

  const { t } = useLocalizationContext();
  const { match, minWidth } = useResponsive();

  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );
  const isGiftCardRegCodeEnabled = useFeatureFlag(
    'CELS-1410-enable-gift-card-reg-code',
  );

  // ─────────────────────────────────────────────────────────────────────

  const { form, submitForm } = useRedeemGiftCardForm({
    onRedeemSubmit,
    onRedeemSuccess,
    onRedeemError,
  });
  const { control, formState, reset: resetForm } = form;
  const { errors, isSubmitting, dirtyFields } = formState;

  // ─── Styles ──────────────────────────────────────────────────────────

  const formContainerStyles = match([undefined, styles.formContainerSM]);
  const registrationCodeContainer = [
    styles.fieldContainer,
    match([
      styles.registrationCodeContainerXS,
      styles.registrationCodeContainerSM,
    ]),
  ];
  const helperTextContainerStyles = match([
    styles.helperTextContainerXS,
    styles.helperTextContainerXS,
    styles.helperTextContainerMD,
  ]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useRedeemGiftCardAutoSubmit({
    giftCardCode,
    giftCardRegistrationCode,
    form,
    submitForm,
  });

  // Reset the form on the screen change
  useFocusEffect(useCallback(() => resetForm, [resetForm]));

  // ─────────────────────────────────────────────────────────────────────

  const shouldDisplayApplyCtaInline = shouldDisplayCtaInline ?? minWidth.isMD;
  const shouldDisableApplyCta = isGiftCardRegCodeEnabled
    ? !giftCardCode &&
      !(dirtyFields.giftCardCode && dirtyFields.registrationCode)
    : !giftCardCode && !dirtyFields.giftCardCode;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View>
      <View style={formContainerStyles}>
        <View style={styles.fieldContainer}>
          <Form.TextField
            name="giftCardCode"
            control={control}
            rules={{ required: true }}
            label={t('redeem-gift-card-form.gift-card-number')}
            accessibilityLabel={t('redeem-gift-card-form.gift-card-number')}
            notice={errors.giftCardCode?.message}
            hideSubmitButton
            disabled={isSubmitting}
            onSubmit={submitForm}
          />
        </View>

        {isGiftCardRegCodeEnabled ? (
          <View style={registrationCodeContainer}>
            <Form.TextField
              name="registrationCode"
              control={control}
              rules={{ required: true }}
              label={t('redeem-gift-card-form.registration-code')}
              accessibilityLabel={t('redeem-gift-card-form.registration-code')}
              hideSubmitButton
              notice={errors.registrationCode?.message}
              disabled={isSubmitting}
              onSubmit={submitForm}
            />
          </View>
        ) : null}

        {shouldWrapInlineCta && shouldDisplayApplyCtaInline ? (
          <View style={styles.wrapContainer} />
        ) : null}

        {shouldDisplayApplyCtaInline ? (
          <View
            style={
              shouldWrapInlineCta
                ? styles.ctaContainerInlineWrapped
                : styles.ctaContainerInline
            }
          >
            <Button
              size="small"
              style={styles.cta}
              onPress={submitForm}
              isLoading={isSubmitting}
              disabled={shouldDisableApplyCta}
              accessibilityLabel={t('redeem-gift-card-form.apply-a11y-label')}
            >
              {isInAppGiftCardEnabled ? (
                <FormattedMessage {...messages.submitButtonLabel} />
              ) : (
                <FormattedMessage {...messages.submitButtonLabelLegacy} />
              )}
            </Button>
          </View>
        ) : null}
      </View>

      {withHelperText ? (
        <View style={helperTextContainerStyles}>
          <BodyText sizeMatch={['12', '14']}>
            {t('redeem-gift-card-form.helper-text')}
          </BodyText>
        </View>
      ) : null}

      {shouldDisplayApplyCtaInline ? null : (
        <View style={styles.ctaContainer}>
          <Button
            size="large"
            onPress={submitForm}
            isLoading={isSubmitting}
            disabled={shouldDisableApplyCta}
            accessibilityLabel={t('redeem-gift-card-form.apply-a11y-label')}
          >
            {isInAppGiftCardEnabled ? (
              <FormattedMessage {...messages.submitButtonLabel} />
            ) : (
              <FormattedMessage {...messages.submitButtonLabelLegacy} />
            )}
          </Button>
        </View>
      )}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  formContainerSM: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  fieldContainer: {
    flexGrow: 1,
  },
  registrationCodeContainerXS: {
    paddingTop: theme.spacing['4'],
  },
  registrationCodeContainerSM: {
    marginLeft: theme.spacing['8'],
  },
  ctaContainer: {
    paddingTop: theme.spacing['4'],
  },
  ctaContainerInline: {
    alignSelf: 'flex-start',
    marginLeft: theme.spacing['8'],
  },
  ctaContainerInlineWrapped: {
    alignSelf: 'flex-start',
  },
  wrapContainer: {
    width: '100%',
    marginBottom: theme.spacing['2'],
  },
  cta: {
    minWidth: 123,
  },
  helperTextContainerXS: {
    marginTop: theme.spacing['4'],
    marginBottom: theme.spacing['6'],
  },
  helperTextContainerMD: {
    marginTop: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type RedeemGiftCardFormProps = Readonly<{
  withHelperText?: boolean;
  shouldWrapInlineCta?: boolean;
  shouldDisplayCtaInline?: boolean;
  giftCardCode?: string;
  giftCardRegistrationCode?: string;
  onRedeemSubmit?: () => void;
  onRedeemSuccess?: () => void;
  onRedeemError?: (payload: {
    userError?: string;
    systemError?: string;
  }) => void;
}>;

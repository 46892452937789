import type {
  Address,
  Asset,
  Customer,
  Ingredient,
  LineItem,
  Menu,
  OrderConflict,
  Product,
  ProductLabel,
  Restaurant,
} from './types';

/**
 * Partial versions of complex GraphQL types.
 * Needed for conversion between fragments and types.
 */

export type PartialIngredient = Partial<
  Readonly<{
    asset: Partial<Asset>;
  }> &
    Omit<Ingredient, 'asset'>
>;

export type PartialProduct = Partial<
  Readonly<{
    label: Partial<ProductLabel> | null;
    restaurant: Partial<Restaurant>;
    ingredients: ReadonlyArray<Partial<PartialIngredient>>;
  }> &
    Omit<Product, 'restaurant' | 'ingredients' | 'label'>
>;

export type PartialLineItem = Readonly<{
  product: Partial<PartialProduct>;
  ingredients?: ReadonlyArray<Partial<PartialIngredient>>;
  addedIngredients?: ReadonlyArray<Partial<PartialIngredient>>;
  removedIngredients?: ReadonlyArray<Partial<PartialIngredient>>;
}> &
  Partial<
    Omit<
      LineItem,
      'product' | 'ingredients' | 'addedIngredients' | 'removedIngredients'
    >
  >;

export type PartialRestaurant = Readonly<{
  menu: Partial<Menu>;
}> &
  Partial<Omit<Restaurant, 'menu'>>;

export type PartialCustomer = Readonly<{
  addresses: ReadonlyArray<Partial<Address>>;
}> &
  Partial<Omit<Customer, 'addresses'>>;

export type PartialOrderConflict = Readonly<{
  product: PartialProduct;
  unavailableIngredients: ReadonlyArray<Partial<PartialIngredient>>;
}> &
  Omit<OrderConflict, 'product' | 'unavailableIngredients'>;

import { type init as datadogInit } from '../init';

/**
 * This adds GraphQL-specific data to the resources
 *
 * NOTE: This is only supported for web at the moment
 * NOTE: We can deprecate this when Datadog adds support for these features
 *
 * TODO:
 * - Do we want this? (a bit hefty)
 *   `body: operation.query,`
 * - We'd need to sanitize this
 *   `variables: operation.variables,`
 * - Add support for errors? (can we make it red in DD?)
 *   `hasSchemaError`? `hasGraphqlError`? `hasNetworkError`?
 * - Follow up on type issues (which aren't supported by default yet)
 */

export function extendEventWithGraphQLResourceData(
  event: beforeSendParams[0],
  context: beforeSendParams[1],
) {
  // @ts-expect-error Property 'requestInit' does not exist on type (blah blah narrowing)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const operation = JSON.parse(context.requestInit.body);

  const graphqlData = {
    operationName: operation.operationName,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    operationType: getOperationTypeFromQuery(operation.query), // eg query, mutation, etc
  };

  // @ts-expect-error Cannot assign to 'graphql' because it is a read-only property.ts(2540)
  // eslint-disable-next-line functional/immutable-data
  event.resource.graphql = graphqlData;
}

export function isGraphQLResource(
  event: beforeSendParams[0],
  _context: beforeSendParams[1],
): boolean {
  return (
    event.type === 'resource' &&
    event.resource.type === 'fetch' &&
    event.resource.url.endsWith('sweetgreen.com/graphql')
  );
}

// ─────────────────────────────────────────────────────────────────────────────

function getOperationTypeFromQuery(
  query: string,
): 'query' | 'mutation' | 'subscription' | 'unknown' {
  if (query.startsWith('query')) return 'query';
  if (query.startsWith('mutation')) return 'mutation';
  if (query.startsWith('subscription')) return 'subscription';
  return 'unknown';
}

// ─────────────────────────────────────────────────────────────────────────────

type beforeSendParams = Parameters<
  NonNullable<
    NonNullable<
      NonNullable<Parameters<typeof datadogInit>[1]>['rumWeb']
    >['beforeSend']
  >
>;

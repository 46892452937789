import type { AppStackParamList } from './AppNavigation.props';

/**
 * Mapping used to handle 301 redirects.
 */

export const redirectsMapping: Record<
  string,
  Readonly<{
    screen: keyof AppStackParamList;
    params: AppStackParamList[keyof AppStackParamList];
  }>
> = {
  '/location': {
    screen: 'MainTabs',
    params: {
      screen: 'MenuTab',
      params: { screen: 'Locations' },
    },
  },
  '/menu': {
    screen: 'MainTabs',
    params: {
      screen: 'AccountTab',
      params: {
        screen: 'AccountMenu',
      },
    },
  },
  '/profile': {
    screen: 'MainTabs',
    params: {
      screen: 'AccountTab',
      params: {
        screen: 'Profile',
        initial: false,
      },
    },
  },
  '/account/profile': {
    screen: 'MainTabs',
    params: {
      screen: 'AccountTab',
      params: {
        screen: 'Profile',
        initial: false,
      },
    },
  },
  '/challenges': {
    screen: 'MainTabs',
    params: {
      screen: 'LoyaltyTab',
      params: {
        screen: 'SweetpassHome',
      },
    },
  },
  '/sweetpass/membership': {
    screen: 'MainTabs',
    params: {
      screen: 'AccountTab',
      params: {
        screen: 'SweetpassMembership',
        initial: false,
      },
    },
  },
  '/payment': {
    screen: 'MainTabs',
    params: {
      screen: 'AccountTab',
      params: {
        screen: 'PaymentAndGiftCards',
        initial: false,
      },
    },
  },
  '/addresses': {
    screen: 'MainTabs',
    params: {
      screen: 'AccountTab',
      params: {
        screen: 'Addresses',
        initial: false,
      },
    },
  },
  '/orders': {
    screen: 'MainTabs',
    params: {
      screen: 'AccountTab',
      params: {
        screen: 'Orders',
        initial: false,
      },
    },
  },
  '/favorites': {
    screen: 'MainTabs',
    params: {
      screen: 'AccountTab',
      params: {
        screen: 'Favorites',
        initial: false,
      },
    },
  },
  '/refer-friend': {
    screen: 'MainTabs',
    params: {
      screen: 'AccountTab',
      params: {
        screen: 'ReferFriend',
        initial: false,
      },
    },
  },
  '/account-dietary-restrictions': {
    screen: 'MainTabs',
    params: {
      screen: 'AccountTab',
      params: {
        screen: 'AccountDietaryRestrictions',
        initial: false,
      },
    },
  },
};

import React, { memo } from 'react';
import type { ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';
import type { useAnimatedStyle } from 'react-native-reanimated';
import Animated from 'react-native-reanimated';
import { theme } from '@sg/garnish';

import {
  LOCATION_RESULTS_BORDER_RADIUS,
  LOCATION_RESULTS_THEME_BG_COLOR,
} from '../../LocationResults.constants';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * We utilize a separate `Animated.View` component with custom styles to add a shadow
 * effect because we can't apply it directly, since Locations Results container is
 * modified by `translateY` transform property to expand/collapse.
 */
export const LocationResultsBottomShadow = memo(
  (props: LocationResultsShadowViewProps) => {
    const { innerContainerAnimatedStyle, innerContainerCollapsedStyle } = props;

    const containerStyles = [styles.container, innerContainerAnimatedStyle];
    const shadowStyles = [styles.shadow, innerContainerCollapsedStyle];

    return (
      <Animated.View style={containerStyles} pointerEvents="none">
        <View style={shadowStyles} needsOffscreenAlphaCompositing />
      </Animated.View>
    );
  },
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...StyleSheet.absoluteFillObject,
    margin: theme.spacing['4'],
  },
  shadow: {
    borderRadius: LOCATION_RESULTS_BORDER_RADIUS,

    // NOTE: `backgroundColor` is required to apply elevation on native platforms
    backgroundColor: LOCATION_RESULTS_THEME_BG_COLOR,
    ...theme.elevations['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsShadowViewProps = Readonly<{
  innerContainerCollapsedStyle: ViewStyle;
  innerContainerAnimatedStyle: ReturnType<typeof useAnimatedStyle>;
}>;

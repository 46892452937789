import {
  MenuTopDownCategoriesContainer,
  MenuTopDownCategoriesItemDescription,
  MenuTopDownCategoriesItemDetailsContainer,
  MenuTopDownCategoriesItemImage,
  MenuTopDownCategoriesItemPressable,
  MenuTopDownCategoriesItemTitle,
  MenuTopDownCategoriesRecentOrdersButton,
} from './components';

export const MenuTopDownCategories = {
  Container: MenuTopDownCategoriesContainer,
  RecentOrdersButton: MenuTopDownCategoriesRecentOrdersButton,
  Item: {
    Pressable: MenuTopDownCategoriesItemPressable,
    Image: MenuTopDownCategoriesItemImage,
    Title: MenuTopDownCategoriesItemTitle,
    Description: MenuTopDownCategoriesItemDescription,
    DetailsContainer: MenuTopDownCategoriesItemDetailsContainer,
  },
};

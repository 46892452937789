import React, { useCallback } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import { Callout } from '../Callout';
import { MARKER_SIZE } from '../constants';
import { Pin } from '../Pin';
import type { MarkerProps } from '../types';
import { useMarkerZIndexStyle } from './hooks';
import { getPinType } from './utils';

// ────────────────────────────────────────────────────────────────────────────────

export const Marker = (props: MarkerProps) => {
  const { active: isActive, isMinimized, name: restaurantName, id } = props;

  const shouldShowCallout = Boolean(isActive && restaurantName);

  const markerZIndexStyle = useMarkerZIndexStyle({
    isActive,
    isMinimized,
    id,
  });
  const wrapperStyle = [styles.offset, markerZIndexStyle];

  return (
    <View style={wrapperStyle}>
      {shouldShowCallout ? <Callout {...props} /> : null}
      <PinContainer {...props} />
    </View>
  );
};

const PinContainer = (props: MarkerProps) => {
  const {
    id: restaurantId,
    deliveryAddressType,
    onPinPress,
    active,
    acceptingOrders,
    isMinimized,
  } = props;

  // ─── Helpers ─────────────────────────────────────────────────────

  const onPress = useCallback(() => {
    onPinPress?.(restaurantId);
  }, [restaurantId, onPinPress]);

  // ─────────────────────────────────────────────────────────────────

  return (
    <Pressable
      accessibilityRole="button"
      focusable={false}
      style={styles.pin}
      testID={`pin-${restaurantId}`}
      onPress={onPress}
    >
      <Pin
        variation={getPinType(props)}
        isMinimized={isMinimized}
        isSelected={Boolean(active)}
        isOpen={Boolean(acceptingOrders)}
        deliveryAddressType={deliveryAddressType}
      />
    </Pressable>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pin: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  offset: {
    height: MARKER_SIZE,
    marginTop: -MARKER_SIZE,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

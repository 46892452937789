import {
  CustomizationDetailsBreadToggle,
  CustomizationDetailsContainer,
  CustomizationDetailsDressingMode,
  CustomizationDetailsDressingPortion,
  CustomizationDetailsDressingWeight,
} from './components';

export const CustomizationDetails = {
  Container: CustomizationDetailsContainer,
  DressingMode: CustomizationDetailsDressingMode,
  DressingWeight: CustomizationDetailsDressingWeight,
  DressingPortion: CustomizationDetailsDressingPortion,
  BreadToggle: CustomizationDetailsBreadToggle,
};

import { useCallback, useRef } from 'react';
import type { FlatList } from 'react-native';

import { useReduceMotionStatus } from '../../../hooks';
import type { useRailViewableState } from './useRailViewableState';

export const useRailNavigation = ({
  viewableState,
  outerOffset,
  initialScrollIndex,
}: UseRailNavigationProps) => {
  const reduceMotionStatus = useReduceMotionStatus();

  const railRef = useRef<FlatList>(null);
  const initialScrollIndexRef = useRef({ initialScrollIndex, scrolled: false });

  // eslint-disable-next-line functional/immutable-data
  initialScrollIndexRef.current.initialScrollIndex = initialScrollIndex;

  // ─── HELPERS ─────────────────────────────────────────────────────

  const scrollToIndex = useCallback(
    (params: ScrollToIndexProps) => {
      const {
        index,
        viewPosition,
        viewOffset = Math.abs(outerOffset),
        animated = reduceMotionStatus === 'disabled',
      } = params;

      if (index === null) return;

      railRef.current?.scrollToIndex({
        index,
        animated,
        viewOffset,
        viewPosition,
      });
    },
    [outerOffset, reduceMotionStatus],
  );

  const scrollToPrevItem = useCallback(() => {
    scrollToIndex({
      index: viewableState.prevItemIndex,
    });
  }, [scrollToIndex, viewableState.prevItemIndex]);

  const scrollToNextItem = useCallback(() => {
    scrollToIndex({
      index: viewableState.nextItemIndex,
      viewPosition: 0.95,
      viewOffset: 0,
    });
  }, [scrollToIndex, viewableState.nextItemIndex]);

  const scrollToInitialIndex = useCallback(() => {
    const scrollIndex = initialScrollIndexRef.current.initialScrollIndex;
    const initialScrollHappened = initialScrollIndexRef.current.scrolled;

    if (!scrollIndex || initialScrollHappened) return;
    scrollToIndex({ index: scrollIndex, animated: false });
  }, [scrollToIndex]);

  // ─────────────────────────────────────────────────────────────────

  return {
    railRef,
    scrollToPrevItem,
    scrollToNextItem,
    scrollToInitialIndex,
  };
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type UseRailNavigationProps = Readonly<{
  viewableState: ReturnType<typeof useRailViewableState>['viewableState'];
  outerOffset: number;
  initialScrollIndex?: number;
}>;

type ScrollToIndexProps = Omit<
  Parameters<typeof FlatList.prototype.scrollToIndex>[0],
  'index'
> &
  Readonly<{
    index: number | null;
  }>;

import { useMemo } from 'react';
import { Platform } from 'react-native';

import type { Order } from '@order/graphql';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useFormatLineItemName } from '@order/shared/hooks';

/**
 * HealthKit integration for order status.
 * Returns line items properly formatted to be sent to HealthKit.
 */
export function useOrderHealthKit(order: Partial<Order>) {
  const formatLineItemName = useFormatLineItemName();

  const isOrderToHealthKitEnabled =
    useFeatureFlag('XP-3232-permanent-order-to-healthkit-enabled') &&
    Platform.OS === 'ios';

  const orderToHealthKitLineItems = useMemo(() => {
    return (order?.lineItems ?? []).map((lineItem) => {
      const { id, product, calories } = lineItem;
      const name = formatLineItemName(product, lineItem);

      return { id, name, calories };
    });
  }, [order?.lineItems, formatLineItemName]);

  return { isOrderToHealthKitEnabled, orderToHealthKitLineItems };
}

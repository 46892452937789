import { useMemo } from 'react';

import { useLocalizationContext } from '@order/Localization';

import { usePrevious } from '../usePrevious';

// ────────────────────────────────────────────────────────────────────────────────

export const useNewException = (exception?: Exception) => {
  const { t } = useLocalizationContext();
  const prevException = usePrevious(exception);

  const hasNewException = Boolean(!prevException && exception);
  const exceptionMessage = exception?.message ?? t('general.error');

  return useMemo(
    () => ({ hasNewException, exceptionMessage }),
    [exceptionMessage, hasNewException],
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type Exception = Readonly<{
  message?: string;
}>;

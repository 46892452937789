import React, { memo, useRef } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';
import {
  theme,
  useActiveAnimation,
  usePressableState,
  webOnlyStyles,
} from '@sg/garnish';

import { useNavigateToLastInteractedStore } from '@order/LastInteractedStore';
import { useLocalizationContext } from '@order/Localization';

import { HomeScreenCTAContent } from '../HomeScreenCTA/HomeScreenCTAContent';

export const HeroHeadlineCta = memo((props: HeroHeadlineCtaProps) => {
  const pressableRef = useRef(null);

  const { isInteracting } = usePressableState(pressableRef);
  const { t } = useLocalizationContext();
  const handleOnPress = useNavigateToLastInteractedStore();

  // ─────────────────────────────────────────────────────────────────

  const pressableAnimatedStyle = useActiveAnimation(
    isInteracting,
    theme.colors.OPACITY.TRANSPARENT,
    theme.colors.OPACITY.KALE.LIGHTER,
  );
  const pressableStyles = [
    styles.pressable,
    webOnlyStyles({
      outlineColor: theme.colors.GRAY,
      outlineOffset: theme.spacing['2'],
    }),
  ];
  const cardStyles = [styles.card, pressableAnimatedStyle];

  // ─────────────────────────────────────────────────────────────────

  return (
    <Pressable
      style={pressableStyles}
      accessibilityRole="button"
      accessibilityLabel={t('home.headline.cta')}
      ref={pressableRef}
      onPress={handleOnPress}
    >
      <Animated.View style={cardStyles}>
        <HomeScreenCTAContent
          testID="home.headline.cta"
          palette="kale"
          title={props.headline}
          subtitle={t('home.headline.cta')}
        />
      </Animated.View>
    </Pressable>
  );
});

// ─── STYLES ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  card: {
    flex: 1,
    borderRadius: theme.radius.large,
  },
  pressable: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type HeroHeadlineCtaProps = Readonly<{
  headline: string;
}>;

import { useWindowDimensions } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { useContainerMaxWidth, useContainerSpacing } from '../../Container';

// ────────────────────────────────────────────────────────────────────────────────

export const useCollectionsRailDynamicProps = () => {
  const railMaxWidth = useRailMaxWidth();
  // @ts-expect-error TS(2345): Argument of type 'number | "auto" | AnimatedNode |... Remove this comment to see the full error message
  const railItemWidth = useRailItemWidth(railMaxWidth);
  const railOuterOffset = useRailOuterOffset();

  return {
    railOuterOffset,
    railItemWidth,
    railItemGap: RAIL_ITEM_GAP,
  };
};

// ────────────────────────────────────────────────────────────────────────────────

const useRailMaxWidth = () => {
  const { maxWidth: containerMaxWidth } = useContainerMaxWidth('large');

  const containerSpacing = useContainerSpacing();
  const containerSpacingsSum = containerSpacing * 2; // from both sides

  return typeof containerMaxWidth === 'number' // `maxWidth` can be given in string format too
    ? containerMaxWidth - containerSpacingsSum + RAIL_ITEM_GAP
    : containerMaxWidth;
};

const useRailOuterOffset = () => {
  const { width: windowWidth } = useWindowDimensions();
  const { maxWidth: containerMaxWidth } = useContainerMaxWidth('large');
  const containerSpacing = useContainerSpacing();

  const outerOffsetForLargeScreens = 0;
  const outerOffsetForSmallerScreens = -containerSpacing;

  // @ts-expect-error TS(2531): Object is possibly 'null'.
  return windowWidth > containerMaxWidth
    ? outerOffsetForLargeScreens
    : outerOffsetForSmallerScreens;
};

const useRailItemWidth = (railMaxWidth: number | string) => {
  const { match } = useResponsive();

  const railWidthMd =
    typeof railMaxWidth === 'number'
      ? Math.floor(railMaxWidth / RAIL_VISIBLE_ITEMS_NUMBER_LG)
      : RAIL_ITEM_WIDTH_SM;

  return match([RAIL_ITEM_WIDTH_XS, RAIL_ITEM_WIDTH_SM, railWidthMd]);
};

// ────────────────────────────────────────────────────────────────────────────────

const RAIL_ITEM_WIDTH_XS = 240;
const RAIL_ITEM_WIDTH_SM = 436;
const RAIL_ITEM_GAP = theme.spacing['6'];
const RAIL_VISIBLE_ITEMS_NUMBER_LG = 3;

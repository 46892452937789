import React from 'react';

import { ApplePay, type useApplePay } from '@order/components';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutApplePay = (
  props: GiftCardCheckoutApplePayProps,
) => {
  const { hostedFrameRef, onMessage } = props;

  return <ApplePay.HostedFrame ref={hostedFrameRef} onMessage={onMessage} />;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutApplePayProps = {
  hostedFrameRef: ReturnType<typeof useApplePay>['applePayHostedFrameRef'];
  onMessage: ReturnType<typeof useApplePay>['handleApplePayMessages'];
};

/* istanbul ignore file */

import type { ComponentProps } from 'react';
import React, { memo, useMemo } from 'react';
import {
  formatGoogleAddress,
  IllusDelivery_1,
  IllusEmpty_1,
} from '@sg/garnish';

import { useCustomer } from '@order/Customer';
import { useLocalizationContext } from '@order/Localization';

import type { LocationSearchMachineContext } from '../../../../machine';
import { LocationResults } from '../../../LocationResults';
import { LocationResultsListInfoMessage } from '../../../LocationResultsListInfoMessage';
import { LocationSearchAddressesList } from '../../../LocationSearchAddressesList';
import type { LocationSearchLocationsResults } from '../LocationSearchLocationsResults';
import { LocationSearchDeliveryResultWithoutNearbyStore } from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationSearchDeliveryResult = memo(
  (props: LocationSearchDeliveryResultProps) => {
    const {
      deliverySearchString,
      deliveryLocation,
      predictionDeliveryAddresses,
      minimalCharactersForSearch,
      focusedLocationId,
      isLoadingPredictions,
      isLoadingLocation,
      interactedLocationRestaurantSlug,
      interactedLocationDeliveryAddressId,
      withSafeAreaInsets,
      onDeliveryLocationCardPress,
      onPredictionPress,
      onTryNewAddressBtnPress,
      onChangeToPickupSearchTypeBtnPress,
      onLocationResultsExpandCollapse,
    } = props;

    const { t } = useLocalizationContext();
    const { customer } = useCustomer();
    const { addresses: customerAddresses = [] } = customer;

    // ─── Derived Data ────────────────────────────────────────────────────

    const customerDeliveryAddresses = useMemo(() => {
      return customerAddresses.map((customerAddress) => {
        const { googlePlaceId } = customerAddress;

        const name = customerAddress.name ?? '';
        const address = formatGoogleAddress(customerAddress);

        return { id: googlePlaceId, name, address };
      });
    }, [customerAddresses]);

    const deliveryLocations = useMemo(
      () => (deliveryLocation ? [deliveryLocation] : []),
      [deliveryLocation],
    );

    // ─── Flags ───────────────────────────────────────────────────────────────────

    const hasEnteredRequiredCharsForAutocomplete =
      deliverySearchString.length >= minimalCharactersForSearch;
    const hasCustomerAddresses = customerDeliveryAddresses.length > 0;
    const hasNoDeliveryLocation = !isLoadingLocation && !deliveryLocation;
    const hasPredictions = predictionDeliveryAddresses.length > 0;

    const shouldRenderCustomerAddresses =
      hasNoDeliveryLocation &&
      !hasEnteredRequiredCharsForAutocomplete &&
      hasCustomerAddresses;
    const shouldRenderPredictions =
      hasNoDeliveryLocation &&
      hasEnteredRequiredCharsForAutocomplete &&
      hasPredictions;
    const shouldRenderNoPredictionsView =
      hasNoDeliveryLocation &&
      hasEnteredRequiredCharsForAutocomplete &&
      !isLoadingPredictions &&
      !hasPredictions;
    const shouldRenderIntroMessage =
      hasNoDeliveryLocation && !isLoadingPredictions;

    // ─────────────────────────────────────────────────────────────────────

    if (shouldRenderCustomerAddresses) {
      return (
        <LocationSearchAddressesList
          addresses={customerDeliveryAddresses}
          onPress={onPredictionPress}
        />
      );
    }

    if (shouldRenderPredictions) {
      return (
        <LocationSearchAddressesList
          addresses={predictionDeliveryAddresses}
          onPress={onPredictionPress}
        />
      );
    }

    if (shouldRenderNoPredictionsView) {
      return (
        // @ts-expect-error TS(2741): Property 'illustrationAriaLabel' is missing in typ... Remove this comment to see the full error message
        <LocationResultsListInfoMessage
          illustration={IllusEmpty_1}
          title={t('location.no-autocomplete-result')}
          text={t('location.try-another-search')}
        />
      );
    }

    if (shouldRenderIntroMessage) {
      return (
        // @ts-expect-error TS(2741): Property 'illustrationAriaLabel' is missing in typ... Remove this comment to see the full error message
        <LocationResultsListInfoMessage
          illustration={IllusDelivery_1}
          text={t('location.enter-address')}
        />
      );
    }

    // If the searched address is too vague, it may not include a zip code,
    // which makes it impossible for us to deliver to that location.
    // In such cases, we want to prevent the user from proceeding.
    if (deliveryLocation && !deliveryLocation?.zipCode) {
      return (
        // @ts-expect-error TS(2741): Property 'illustrationAriaLabel' is missing in typ... Remove this comment to see the full error message
        <LocationResultsListInfoMessage
          illustration={IllusEmpty_1}
          title={t('location.delivery.zip-code-not-available-title')}
          text={t('location.delivery.zip-code-not-available-body')}
        />
      );
    }

    if (deliveryLocation && !deliveryLocation?.restaurantSlug) {
      return (
        <LocationSearchDeliveryResultWithoutNearbyStore
          deliveryLocation={deliveryLocation}
          onTryNewAddressBtnPress={onTryNewAddressBtnPress}
          onChangeToPickupSearchTypeBtnPress={
            onChangeToPickupSearchTypeBtnPress
          }
        />
      );
    }

    // ─────────────────────────────────────────────────────────────────────

    return (
      <LocationResults
        activePinLocationId={focusedLocationId}
        interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
        interactedLocationDeliveryAddressId={
          interactedLocationDeliveryAddressId
        }
        locationSearchType="delivery"
        locations={deliveryLocations}
        shouldHideDetailsModal
        isLoading={isLoadingLocation}
        onCardPress={onDeliveryLocationCardPress}
        onLocationResultsExpandCollapse={onLocationResultsExpandCollapse}
        withSafeAreaInsets={withSafeAreaInsets}
      />
    );
  },
);

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationSearchDeliveryResultProps = Readonly<{
  deliverySearchString: LocationSearchMachineContext['deliverySearchString'];
  deliveryLocation: LocationSearchMachineContext['deliveryLocation'];
  predictionDeliveryAddresses: LocationSearchMachineContext['deliveryAddressPredictions'];
  minimalCharactersForSearch: number;
  focusedLocationId?: string;
  isLoadingPredictions: boolean;
  isLoadingLocation: boolean;
  interactedLocationRestaurantSlug: string | undefined;
  interactedLocationDeliveryAddressId: string | undefined;
  withSafeAreaInsets?: boolean;
  onDeliveryLocationCardPress:
    | ((props: {
        restaurantId?: string;
        restaurantSlug?: string;
        restaurantName?: string;
      }) => void)
    | (() => void);
  onPredictionPress: (placeId: string) => void;
  onTryNewAddressBtnPress: () => void;
  onChangeToPickupSearchTypeBtnPress: () => void;
  onLocationResultsExpandCollapse: ComponentProps<
    typeof LocationSearchLocationsResults
  >['onLocationResultsExpandCollapse'];
}>;

import { isTomorrow } from 'date-fns';

import { isDayAfterTomorrow } from '@order/utils';

export function getTimeEstimate(orderTimeDate?: Date) {
  if (isTomorrow(orderTimeDate ?? new Date())) return 'tomorrow';

  if (isDayAfterTomorrow(orderTimeDate ?? new Date())) return 'afterTomorrow';

  return 'today';
}

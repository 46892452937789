import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { Button } from '../Button';

export const SubmitButton = (props: SubmitButtonProps) => (
  <View style={[styles.wrapper, props.style]}>
    <Button size="large" {...props} />
  </View>
);

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type SubmitButtonProps = React.ComponentProps<typeof Button>;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    paddingTop: theme.spacing['10'],
  },
});

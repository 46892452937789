/* eslint-disable functional/immutable-data */

import { useCallback, useEffect, useRef } from 'react';

export const useCallbackIfMounted = (
  callback: (...args: readonly any[]) => void,
) => {
  const isMounted = useRef(true);

  const callbackRef = useRef(callback);

  callbackRef.current = callback;

  // ─────────────────────────────────────────────────────────────────

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // ─────────────────────────────────────────────────────────────────

  return useCallback((...args: any) => {
    if (!isMounted.current) return;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    callbackRef.current?.(...args);
  }, []);
};

import React, { type ComponentProps, forwardRef, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, type TextInput, View } from 'react-native';
import { Form, Icon, type TextField, theme } from '@sg/garnish';

import { type GiftCardCheckoutFormReturn } from '../../../../../../form';
import { giftCardCheckoutDeliveryMethodEmailMessages as messages } from './GiftCardCheckoutDeliveryMethodEmail.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutDeliveryMethodEmail = forwardRef<
  TextInput,
  GiftCardCheckoutDeliveryMethodEmailProps
>((props, ref) => {
  const { form } = props;

  const { formatMessage } = useIntl();

  const { isSubmitting } = form.formState;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const clearRecipientEmailError = useCallback(() => {
    form.clearErrors('recipientEmail');
  }, [form]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.emailFieldContainer}>
      <Icon
        name="IconEmail"
        width={24}
        height={24}
        pointerEvents="none"
        style={styles.emailFieldIcon}
      />

      <Form.TextField
        ref={ref}
        labelOffset={EMAIL_FIELD_LABEL_OFFSET}
        name="recipientEmail"
        control={form.control}
        hideSubmitButton
        autoComplete="email"
        returnKeyType="done"
        style={styles.emailField}
        onChangeText={clearRecipientEmailError}
        disabled={isSubmitting}
        label={formatMessage(messages.recipientEmailFieldPlaceholder)}
      />
    </View>
  );
});

// ─── Constants ───────────────────────────────────────────────────────────────

const EMAIL_FIELD_LEFT_OFFSET = theme.spacing['10'];
const EMAIL_FIELD_LABEL_OFFSET: TextFieldLabelOffset = {
  idle: { left: EMAIL_FIELD_LEFT_OFFSET },
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  emailFieldContainer: {
    marginTop: theme.spacing['6'],
  },
  emailFieldIcon: {
    position: 'absolute',
    top: 17,
    left: 0,
  },
  emailField: {
    paddingLeft: EMAIL_FIELD_LEFT_OFFSET,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutDeliveryMethodEmailProps = {
  form: GiftCardCheckoutFormReturn;
};

type TextFieldLabelOffset = ComponentProps<typeof TextField>['labelOffset'];

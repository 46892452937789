import { useLayoutEffect } from 'react';
import {
  type RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';

import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry } from '@order/Telemetry';

import { type AuthStackParamList } from '../../../../navigation/AppNavigation.props';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A small hook that redirects the user to the new "Join or Sign In" screen if
 * the corresponding feature flag is enabled.
 *
 * Can be used on legacy authentication routes.
 */
export const useAzureAuthScreenRedirect = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<AuthStackParamList>>();
  const { params } = useRoute<AuthRoutesProps>();
  const { track } = useTelemetry();

  const isAuthMigrationEnabled = useFeatureFlag(
    'CELS-1883-auth-migration-enabled',
    { listenForChanges: true },
  );
  const isHybridAuthFlowEnabled = useFeatureFlag('cels-2041-auth-hybrid-flow', {
    listenForChanges: true,
  });
  const shouldRedirect = isAuthMigrationEnabled || isHybridAuthFlowEnabled;

  // ─── Effects ─────────────────────────────────────────────────────────

  useLayoutEffect(() => {
    if (!shouldRedirect) return;

    const routeParams = params ?? ROUTE_PARAMS_FALLBACK;

    if (isHybridAuthFlowEnabled) {
      // NOTE: We track the "view" event here since `JoinOrSignScreen` is also used as a redirect target
      //       when employing authentication "Redirect" flow, which can result in additional telemetry events.
      track('join-or-sign-in.view', { referrer: routeParams.redirect });
    }

    navigation.replace('JoinOrSignIn', params);
  }, [shouldRedirect, navigation, params, track, isHybridAuthFlowEnabled]);
};

// ─── Constants ───────────────────────────────────────────────────────────────

const ROUTE_PARAMS_FALLBACK = {};

// ─── Types ───────────────────────────────────────────────────────────────────

type AuthRoutesProps =
  | RouteProp<AuthStackParamList, 'Login'>
  | RouteProp<AuthStackParamList, 'Join'>;

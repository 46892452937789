import type { ReactNode } from 'react';
import React from 'react';
import type { ViewProps } from 'react-native';
import { Platform } from 'react-native';

import { FadeView } from '../../../FadeView';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders a `View` component with predefined props for a "tabpanel"
 * container to render content connected with a "tab".
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tabpanel_role ARIA: tabpanel role}
 */
export const TabPanel = (props: TabPanelProps) => {
  const { children, isActive, tabId, panelId, ...restProps } = props;

  return (
    <FadeView
      show={isActive}
      nativeID={panelId}
      accessibilityLabelledBy={tabId}
      accessibilityElementsHidden={!isActive}
      {...Platform.select({
        web: {
          accessibilityRole: 'tabpanel' as never,
          accessibilityHidden: !isActive,
        },
      })}
      {...restProps}
    >
      {isActive ? children : null}
    </FadeView>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type TabPanelProps = Readonly<{
  isActive: boolean;
  children: ReactNode;
  tabId: string;
  panelId: string;
}> &
  Omit<
    ViewProps,
    | 'children'
    | 'nativeID'
    | 'accessibilityState'
    | 'accessibilityLabelledBy'
    | 'accessibilityElementsHidden'
  >;

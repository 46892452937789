import { useCallback, useRef } from 'react';
import type { RouteProp } from '@react-navigation/native';
import {
  CommonActions,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';

import { APP_STACK_NAVIGATOR_ID } from '../../AppNavigation.constants';
import type { AuthStackParamList } from '../../AppNavigation.props';
import type { AppStackParamList } from '../../AppNavigation.props';

/**
 * Returns a helper function that redirects to the corresponding screen provided
 * as a route for `Auth` related screens.
 */
export const useRedirectAfterAuthStateChange = () => {
  const { navigate, getParent } =
    useNavigation<NativeStackNavigationProp<AppStackParamList>>();
  const { params = {} } = useRoute<AuthScreenRouteProp>();

  const {
    redirect: redirectToPath = 'home',
    redirectToPlanId,
    redirectToGiftCardId,
  } = params;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const { current: redirects } = useRef<RedirectHandlers>({
    home() {
      navigate('MainTabs', {
        screen: 'HomeTab',
        params: { screen: 'Home' },
        initial: false,
      });
    },
    locations() {
      navigate('MainTabs', {
        screen: 'MenuTab',
        params: { screen: 'Locations' },
      });
    },
    sweetpass() {
      navigate('MainTabs', {
        screen: 'LoyaltyTab',
        params: { screen: 'SweetpassHome' },
      });
    },
    sweetpassBenefits() {
      navigate('MainTabs', {
        screen: 'LoyaltyTab',
        params: { screen: 'SweetpassHome' },
      });
      // With timeout to open the modal post auth stack dismissal.
      setTimeout(() => {
        navigate('Modal', {
          screen: 'SweetpassBenefits',
        });
      }, 500);
    },
    sweetpassUpgrade() {
      navigate('MainTabs', {
        screen: 'LoyaltyTab',
        params: { screen: 'SweetpassHome' },
      });
      // With timeout to open the modal post auth stack dismissal.
      setTimeout(() => {
        navigate('Modal', {
          screen: 'SweetpassUpgrade',
          params: { planId: redirectToPlanId },
        });
      }, 500);
    },
    sweetpassUpgradeBag() {
      navigate('MainTabs', {
        screen: 'LoyaltyTab',
        params: { screen: 'SweetpassHome' },
      });
      // With timeout to open the modal post auth stack dismissal.
      setTimeout(() => {
        navigate('Modal', {
          screen: 'SweetpassUpgrade',
          params: { redirectToAfterUpgrade: 'bag' },
        });
      }, 500);
    },
    profile() {
      navigate('MainTabs', {
        screen: 'AccountTab',
        params: { screen: 'Profile', initial: false },
      });
    },
    'sweetpass-membership'() {
      navigate('MainTabs', {
        screen: 'AccountTab',
        params: { screen: 'SweetpassMembership', initial: false },
      });
    },
    orders() {
      navigate('MainTabs', {
        screen: 'AccountTab',
        params: { screen: 'Orders', initial: false },
      });
    },
    addresses() {
      navigate('MainTabs', {
        screen: 'AccountTab',
        params: { screen: 'Addresses', initial: false },
      });
    },
    favorites() {
      navigate('MainTabs', {
        screen: 'AccountTab',
        params: { screen: 'Favorites', initial: false },
      });
    },
    'payment-and-gift-cards'() {
      navigate('MainTabs', {
        screen: 'AccountTab',
        params: {
          screen: 'PaymentAndGiftCards',
          initial: false,
          params: { giftCardId: redirectToGiftCardId },
        },
      });
    },
    'credit-and-promo-codes'() {
      navigate('MainTabs', {
        screen: 'AccountTab',
        params: { screen: 'CreditAndPromoCodes', initial: false },
      });
    },
    'refer-friend'() {
      navigate('MainTabs', {
        screen: 'AccountTab',
        params: { screen: 'ReferFriend', initial: false },
      });
    },
    'dietary-restrictions'() {
      navigate('MainTabs', {
        screen: 'AccountTab',
        params: { screen: 'AccountDietaryRestrictions', initial: false },
      });
    },
    bag() {
      navigate('Modal', {
        screen: 'Bag',
      });
    },
    checkout() {
      navigate('Modal', {
        screen: 'Checkout',
      });
    },
    'add-gift-card'() {
      navigate('Modal', {
        screen: 'AddGiftCard',
        params: { giftCardId: redirectToGiftCardId },
      });
    },
    'gift-cards'() {
      navigate('MainTabs', {
        screen: 'GiftCardsTab',
        params: { screen: 'GiftCards' },
        initial: true,
      });
    },
    'gift-redeem'() {
      if (!redirectToGiftCardId) return;

      navigate('MainTabs', {
        screen: 'GiftCardsTab',
        params: {
          screen: 'GiftCardRedemption',
          params: { giftCardId: redirectToGiftCardId },
        },
        initial: false,
      });
    },
  });

  /**
   * Dismisses `Auth` stack after auth state change
   */
  const dismissAuthStack = useCallback(() => {
    const appStackNavigator = getParent(APP_STACK_NAVIGATOR_ID as never);

    appStackNavigator?.dispatch((state) => {
      const routesWithoutAuth = withoutAuthStack(state.routes);

      return CommonActions.reset({
        ...state,
        routes: routesWithoutAuth,
        index: routesWithoutAuth.length - 1,
      });
    });
  }, [getParent]);

  // ─────────────────────────────────────────────────────────────────────

  const redirectHandler = redirects[redirectToPath];

  return useCallback(() => {
    redirectHandler?.();
    dismissAuthStack();
  }, [dismissAuthStack, redirectHandler]);
};

// ─── Utils ───────────────────────────────────────────────────────────────────

/**
 * Dismisses `Auth` stack from `MainTabs` stack
 */
function withoutAuthStack(routes: readonly NavigationRoute[]) {
  return routes.filter((route) => route.name !== 'Auth');
}

// ─── Types ───────────────────────────────────────────────────────────────────

type AuthScreenRouteProp =
  | RouteProp<AuthStackParamList, 'Login'>
  | RouteProp<AuthStackParamList, 'Join'>;

type RedirectPath = NonNullable<AuthScreenRouteProp['params']['redirect']>;

type RedirectHandlers = Record<RedirectPath, () => void>;

type NavigationRoute = Readonly<{
  name: string;
}>;

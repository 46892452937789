import { useCallback, useMemo, useState } from 'react';
import type { LayoutChangeEvent } from 'react-native';
import { StyleSheet } from 'react-native';
import {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSequence,
  withTiming,
} from 'react-native-reanimated';
import { clamp, TITLE } from '@sg/garnish';
import { theme, useResponsive } from '@sg/garnish';

export const useTierUpgradeCelebrationModal = () => {
  const [containerHeight, setContainerHeight] = useState(0);
  const [headingTextHeight, setHeadingTextHeight] = useState(0);
  const [footerTextHeight, setFooterTextHeight] = useState(0);

  const { minWidth, match } = useResponsive();

  const shouldScaleText = minWidth.isMD;

  const transformMax = useMemo(() => {
    // Space to simulate padding on the top and bottom of the content
    const spaceVertical = theme.spacing['12'];

    // Remained height after subtracting the space
    const spacedArea = containerHeight - spaceVertical;

    // Available height for the animation
    const withTextInsets = spacedArea - headingTextHeight - footerTextHeight;

    // Available space for each text to move
    return clamp(
      withTextInsets / 2,
      TIER_UPGRADE_MODAL_IMAGE_HEIGHT / 2,
      TIER_UPGRADE_MODAL_IMAGE_HEIGHT / 2 + PADDING_BETWEEN_TEXT,
    );
  }, [containerHeight, headingTextHeight, footerTextHeight]);

  const progress = useSharedValue(0);

  // Heading text should became visible and move up
  const headingTextAnimatedStyle = useAnimatedStyle(
    () => ({
      opacity: progress.value > APPEARANCE_TILL / 2 ? 1 : 0,
      transform: [
        {
          translateY:
            interpolate(progress.value, [0, APPEARANCE_TILL, 1], [0, 0, 1]) *
              -transformMax +
            TIER_UPGRADE_MODAL_IMAGE_HEIGHT / 2,
        },
      ],
    }),
    [progress, transformMax],
  );

  // Image and button visibility transition
  const opacityAnimatedStyle = useAnimatedStyle(
    () => ({
      opacity: interpolate(progress.value, [0, APPEARANCE_TILL, 1], [0, 0, 1]),
    }),
    [progress],
  );

  // Footer text should became visible and move down
  const footerTextAnimatedStyle = useAnimatedStyle(
    () => ({
      opacity: progress.value >= APPEARANCE_TILL ? 1 : 0,
      transform: [
        {
          translateY:
            interpolate(progress.value, [0, APPEARANCE_TILL, 1], [0, 0, 1]) *
              transformMax -
            TIER_UPGRADE_MODAL_IMAGE_HEIGHT / 2,
        },
      ],
    }),
    [progress, transformMax],
  );

  // Scale text 50% when running on desktop
  const textContainerAnimatedStyle = useAnimatedStyle(() => {
    if (!shouldScaleText) return {};

    return {
      transform: [
        {
          scale: interpolate(
            progress.value,
            [0, APPEARANCE_TILL, 1],
            // Initial scale should be 1
            // And should remain 1 until text became visible
            // Then linearly scale down to SCALE_RATIO_DESKTOP
            [1, 1, SCALE_RATIO_DESKTOP],
          ),
        },
      ],
    };
  });

  // Font size changes based on the screen size
  const fontStyleProps = match([styles.textXS, styles.textMD, styles.textLG]);

  // ─── Layout Listeners ────────────────────────────────────────────────
  // ---------------------------------------------------------------------

  const onContentLayout = useCallback((e: LayoutChangeEvent) => {
    setContainerHeight(e.nativeEvent.layout.height);
  }, []);

  const onHeadingTextLayout = useCallback((e: LayoutChangeEvent) => {
    setHeadingTextHeight(e.nativeEvent.layout.height);
  }, []);

  const onFooterTextLayout = useCallback((e: LayoutChangeEvent) => {
    setFooterTextHeight(e.nativeEvent.layout.height);
  }, []);

  // ─── Animation Control ────────────────────────────────────────────────
  // ----------------------------------------------------------------------

  const startAnimation = useCallback(() => {
    const movementValue = withTiming(1, {
      duration: ANIMATION_DURATION,
    });
    const appearanceValue = withTiming(0.7, {
      duration: ANIMATION_DURATION,
    });

    // eslint-disable-next-line functional/immutable-data
    progress.value = withDelay(
      ANIMATION_DELAY,
      withSequence(appearanceValue, withDelay(ANIMATION_DELAY, movementValue)),
    );
  }, [progress]);

  const stopAnimation = useCallback(() => {
    // eslint-disable-next-line functional/immutable-data
    progress.value = 0;
  }, [progress]);

  return useMemo(
    () => ({
      startAnimation,
      stopAnimation,
      onContentLayout,
      onHeadingTextLayout,
      onFooterTextLayout,
      headingTextAnimatedStyle,
      opacityAnimatedStyle,
      footerTextAnimatedStyle,
      textContainerAnimatedStyle,
      fontStyleProps,
    }),
    [
      startAnimation,
      stopAnimation,
      onContentLayout,
      onHeadingTextLayout,
      onFooterTextLayout,
      headingTextAnimatedStyle,
      opacityAnimatedStyle,
      footerTextAnimatedStyle,
      textContainerAnimatedStyle,
      fontStyleProps,
    ],
  );
};

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────────

const FONT_SIZE_DESKTOP = TITLE['120'].fontSize;
const FONT_SIZE_TABLET = TITLE['80'].fontSize;
const SCALE_RATIO_DESKTOP = FONT_SIZE_TABLET / FONT_SIZE_DESKTOP;
const PADDING_BETWEEN_TEXT = 32;

const APPEARANCE_TILL = 0.7;
const ANIMATION_DURATION = 900;
const ANIMATION_DELAY = 300;

export const TIER_UPGRADE_MODAL_IMAGE_HEIGHT = 200;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  // @ts-expect-error TS(2322): Type '{ fontSize: number; fontFamily: string; font... Remove this comment to see the full error message
  textLG: {
    ...TITLE['120'],
  },
  textMD: {
    ...TITLE['80'],
    fontWeight: '400',
  },
  textXS: {
    ...TITLE['48'],
    fontWeight: '400',
  },
});

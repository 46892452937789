import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { ShadowDivider, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsListsContainer = (props: PropsWithChildren) => {
  const { minWidth, match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = match([styles.containerXS, styles.containerSM]);
  const containerPointerEvents = minWidth.isSM ? 'auto' : 'box-none';

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldRenderResultsTopShadow = minWidth.isSM;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles} pointerEvents={containerPointerEvents}>
      {shouldRenderResultsTopShadow ? (
        <View style={styles.resultsTopShadowContainer}>
          <ShadowDivider
            height={theme.spacing['4']}
            color={theme.colors.APP_BACKGROUND}
          />
        </View>
      ) : null}

      {props.children}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    ...StyleSheet.absoluteFillObject,
    zIndex: theme.zIndex.fixed,
  },
  containerSM: {
    flex: 1,
  },
  resultsTopShadowContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1,
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { ButtonCenterLocation, ButtonZoom } from '../../Button';
import type { MapControlsProps } from '../types';

// ────────────────────────────────────────────────────────────────────────────────

export const MapControls = (props: MapControlsProps) => {
  const {
    onZoomInPress,
    onZoomOutPress,
    onGeolocateUserBtnPress,
    disableGeolocateUserBtn,
    zoomInAccessibilityLabel,
    zoomInAccessibilityHint,
    zoomOutAccessibilityLabel,
    zoomOutAccessibilityHint,
    centerLocationAccessibilityLabel,
    centerLocationAccessibilityHint,
  } = props;
  const { currentBreakpoint } = useResponsive();

  // ─────────────────────────────────────────────────────────────────

  const mapControlsStyles = [
    styles.wrapper,
    currentBreakpoint.isXS ? styles.wrapperMobile : styles.wrapperTablet,
  ];

  // ─────────────────────────────────────────────────────────────────

  return (
    <View style={mapControlsStyles} testID="map-controls-wrapper">
      {onGeolocateUserBtnPress ? (
        <ButtonCenterLocation
          onPress={onGeolocateUserBtnPress}
          disabled={disableGeolocateUserBtn}
          style={styles.buttonMapFAB}
          accessibilityLabel={centerLocationAccessibilityLabel}
          accessibilityHint={centerLocationAccessibilityHint}
        />
      ) : null}

      {onZoomInPress && onZoomOutPress && !currentBreakpoint.isXS ? (
        <ButtonZoom
          zoomInProps={{
            onPress: onZoomInPress,
            accessibilityLabel: zoomInAccessibilityLabel,
            accessibilityHint: zoomInAccessibilityHint,
          }}
          zoomOutProps={{
            onPress: onZoomOutPress,
            accessibilityLabel: zoomOutAccessibilityLabel,
            accessibilityHint: zoomOutAccessibilityHint,
          }}
        />
      ) : null}
    </View>
  );
};

//
// ─── STYLES ─────────────────────────────────────────────────────────────────────
//

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    zIndex: 1,
  },
  wrapperMobile: {
    top: theme.spacing['4'],
    right: theme.spacing['4'],
  },
  wrapperTablet: {
    right: theme.spacing['10'],
    bottom: theme.spacing['6'],
  },
  buttonMapFAB: {
    marginBottom: 16,
  },
});

import React from 'react';
import type { Control } from 'react-hook-form';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  SectionHeader,
  theme,
  useResponsive,
  VStack,
} from '@sg/garnish';

import {
  CheckoutLayoutBlock,
  SweetpassCheckoutCheckbox,
  SweetpassMembershipPlan,
  SweetpassMembershipPlanSelector,
} from '@order/components';
import { DurationUnit } from '@order/graphql';
import { type SweetpassMembershipPlans } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import { type SweetpassFormData } from '../../types';
import { SweetpassCheckoutPrice } from '../SweetpassCheckoutPrice';

// ─────────────────────────────────────────────────────────────────────────────

export const SweetpassCheckoutBlock = (props: SweetpassCheckoutBlockProps) => {
  const {
    testID,
    termsAndConditions,
    planPrice,
    billingFrequencyUnit = DurationUnit.Month,
    hasFreeTrial,
    isSwitchingPlans,
    isLoading,
    control,
    membershipPlansData,
  } = props;

  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  // ─── Flags ───────────────────────────────────────────────────────────

  const hasMultiplePlans = membershipPlansData !== undefined;

  // ─── Styles ──────────────────────────────────────────────────────────

  const checkoutPriceStyles = [
    styles.lineItem,
    match([styles.lineItemXS, styles.lineItemSM]),
  ];

  const responsiveStackSpacing = match([
    theme.spacing['10'],
    theme.spacing['8'],
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <CheckoutLayoutBlock testID={testID}>
      <VStack gap={responsiveStackSpacing}>
        {/* ─── Multiple Membership Plans Selector ────── */}

        {hasMultiplePlans && !isSwitchingPlans ? (
          <SweetpassMembershipPlanSelector
            plans={membershipPlansData.plans}
            plansFeaturedText={membershipPlansData.freeTrialNote}
            selectedPlanId={membershipPlansData.selectedPlanId}
            onSelectedPlanChange={membershipPlansData.onSelectedPlanIdChange}
            shouldDisableAllControls={isLoading}
          />
        ) : null}

        {/* ─── Single Choice Membership Plan ─────────── */}

        {hasMultiplePlans && isSwitchingPlans ? (
          <SweetpassMembershipPlan
            selectedPlanId={membershipPlansData.selectedPlanId}
            plans={membershipPlansData.plans}
          />
        ) : null}

        {/* ─── Single Plan Summary  ──────────────────── */}

        {hasMultiplePlans ? null : (
          <View>
            <SectionHeader heading={t('sweetpass.checkout.membership-plan')} />

            <View style={checkoutPriceStyles}>
              <BodyText bold>{t('sweetpass-plus')}</BodyText>

              <SweetpassCheckoutPrice
                planPrice={planPrice}
                billingFrequencyUnit={billingFrequencyUnit}
                hasFreeTrial={hasFreeTrial}
              />
            </View>
          </View>
        )}

        {/* ─── Terms and Conditions ──────────────────── */}

        <SweetpassCheckoutCheckbox
          termsAndConditions={termsAndConditions}
          control={control}
        />
      </VStack>
    </CheckoutLayoutBlock>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassCheckoutBlockProps = Readonly<{
  termsAndConditions: string;
  planPrice: number;
  hasFreeTrial: boolean;
  isSwitchingPlans?: boolean;
  billingFrequencyUnit?: DurationUnit;
  isLoading: boolean;
  control: Control<SweetpassFormData>;
  membershipPlansData?: {
    plans: SweetpassMembershipPlans;
    selectedPlanId?: string;
    onSelectedPlanIdChange: (planId: string) => void;
    freeTrialNote?: string;
  };
}> &
  Pick<ViewProps, 'testID'>;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  lineItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  lineItemXS: {
    paddingVertical: theme.spacing['6'],
  },
  lineItemSM: {
    paddingTop: theme.spacing['10'],
    paddingBottom: theme.spacing['6'],
  },
});

import { config } from '../../config';
import type { StoreTokensParams } from './store-tokens.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Stores provided token in the local storage.
 */
export function storeHybridFlowTokens(params: StoreTokensParams) {
  const { refreshToken } = params;

  if (!refreshToken) return;

  // NOTE: We don't store access token in local storage on the Web platform
  //       because it is handled by MSAL library.
  localStorage.setItem(config.hybridFlowRefreshTokenStorageKey, refreshToken);
}

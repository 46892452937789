import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, type TextStyle, View } from 'react-native';
import { theme, TYPE_CONFIG } from '@garnish/constants';
import { BodyText, Collapsible, DisplayText, VStack } from '@sg/garnish';

export const LoyaltyFAQ = (props: LoyaltyFAQProps) => {
  const { entries, palette = 'dark-kale' } = props;

  return (
    <View style={styles.container}>
      <DisplayText style={palettes[palette]} sizeMatch={['32']}>
        <FormattedMessage {...messages.title} />
      </DisplayText>

      <VStack hasDivider gap={0}>
        {entries.map((entry) => (
          <LoyaltyFAQEntry key={entry.title} entry={entry} palette={palette} />
        ))}
      </VStack>
    </View>
  );
};

export const LoyaltyFAQEntry = (props: LoyaltyFAQEntryProps) => {
  const { entry, palette = 'dark-kale' } = props;

  const descriptionStyle = [styles.description, descriptionPalettes[palette]];

  return (
    <Collapsible style={styles.entry} options={{ iconName: 'IconCaretDown' }}>
      <Collapsible.Summary>
        <BodyText style={palettes[palette]} sizeMatch={['18']}>
          {entry.title}
        </BodyText>
      </Collapsible.Summary>

      <Collapsible.Details>
        <BodyText style={descriptionStyle} sizeMatch={['12']}>
          {entry.description}
        </BodyText>
      </Collapsible.Details>
    </Collapsible>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'FAQ',
    description: 'Loyalty | FAQ | Title',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing['6'],
    gap: theme.spacing['4'],
  },
  entry: {
    paddingVertical: theme.spacing['6'],
  },
  darkKaleText: {
    color: theme.colors.DARK_KALE,
  },
  kaleText: {
    color: theme.colors.KALE,
  },
  whiteText: {
    color: theme.colors.WHITE,
  },
  charcoalText: {
    color: theme.colors.CHARCOAL,
  },
  description: {
    minHeight: TYPE_CONFIG.BODY['12'].lineHeight,
  },
});

// ─── Palettes ───────────────────────────────────────────────────────────────

const palettes: Record<FAQPalette, TextStyle> = {
  'dark-kale': styles.darkKaleText,
  kale: styles.kaleText,
  white: styles.whiteText,
};

const descriptionPalettes: Record<FAQPalette, TextStyle> = {
  'dark-kale': styles.charcoalText,
  kale: styles.charcoalText,
  white: styles.whiteText,
};

// ─── Types ──────────────────────────────────────────────────────────────────

type FAQEntry = {
  title: string;
  description: string;
};

type FAQPalette = 'dark-kale' | 'kale' | 'white';

type LoyaltyFAQProps = {
  entries: FAQEntry[];
  palette?: FAQPalette;
};

type LoyaltyFAQEntryProps = {
  entry: FAQEntry;
  palette?: FAQPalette;
};

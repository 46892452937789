import { useState } from 'react';

import { getAppNavigationContainerRef } from '../../../../navigation';

// Returns whether the bag is opened by using the nav ref (works outside of navigation).
// It can either be { name: 'Modal', params: { screen: 'Bag'  } } or { name: 'Bag' }.
export const useIsBagOpenFromReference = () => {
  const [isBagOpen, setIsBagOpen] = useState(false);

  getAppNavigationContainerRef()?.current?.addListener('state', () => {
    const route = getAppNavigationContainerRef()?.current?.getCurrentRoute();
    const params = route?.params as Readonly<{ screen: string }>;

    setIsBagOpen(route?.name === 'Bag' || params?.screen === 'Bag');
  });

  return isBagOpen;
};

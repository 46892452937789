import { type SubscriptionCreditCard } from '@order/graphql';

import { useGetPaymentSubscriptionProfileQuery } from './graphql/SweetpassCreditCards.generated';

export function useSweetpassCreditCards() {
  const [paymentSubscriptionProfileResponse] =
    useGetPaymentSubscriptionProfileQuery({
      requestPolicy: 'cache-and-network',
    });

  const paymentSubscriptionProfile =
    paymentSubscriptionProfileResponse.data?.paymentSubscriptionProfile;

  const creditCards: ReadonlyArray<Partial<SubscriptionCreditCard>> =
    paymentSubscriptionProfile?.creditCards ?? [];

  const isFetchingSweetpassCreditCards =
    paymentSubscriptionProfileResponse.fetching;

  return { creditCards, isFetchingSweetpassCreditCards };
}

import { FlattenedOrderStatuses } from '@order/graphql';

/**
 * Enhanced order statuses:
 * - pending
 * - lateOrder
 * - feedback
 * - noTrackingData
 */
export function getEnhancedOrderStatus(props: GetSpecialOrderStatusesProps) {
  const {
    orderStatus = FlattenedOrderStatuses.Failed,
    hasCart,
    isLate,
    isPast,
    isPendingFeedback,
    isTrackable,
  } = props;

  const cannotBeTracked = UNTRACKABLE_STATUSES.has(orderStatus);

  if (hasCart) return 'pending';

  if (isLate) return 'lateOrder';

  if (isPendingFeedback && isPast) return 'feedback';

  if (!cannotBeTracked && !isTrackable) return 'noTrackingData';

  return orderStatus;
}

const UNTRACKABLE_STATUSES = new Set([
  FlattenedOrderStatuses.Failed,
  FlattenedOrderStatuses.Completed,
]);

type GetSpecialOrderStatusesProps = Readonly<{
  orderStatus?: FlattenedOrderStatuses;
  hasCart?: boolean;
  isLate?: boolean;
  isPast?: boolean;
  isPendingFeedback?: boolean;
  isTrackable?: boolean;
}>;

import { type CartDataFragment } from '@order/graphql';

import { useDeliveryEstimateQuery } from '../../graphql/Checkout.generated';

/**
 * Given a cart address id, return the estimate for its delivery.
 * This is only needed for delivery carts for the ASAP delivery time option.
 */
export const useDeliveryEstimate = (cart?: CartDataFragment | null) => {
  const addressId = cart?.deliveryOrderDetail?.address?.id;

  const [response] = useDeliveryEstimateQuery({
    variables: { input: { addressId } },
    pause: !addressId,
  });

  const result = response?.data?.locationByAddress;

  if (result?.__typename === 'Location') {
    return {
      deliveryEstimate: result.estimateRange,
      isLoadingDeliveryEstimate: false,
    };
  }

  return {
    deliveryEstimate: null,
    isLoadingDeliveryEstimate: response.fetching,
  };
};

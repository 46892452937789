/* eslint-disable unicorn/consistent-destructuring */
import React from 'react';
import type { StyleProp, ViewProps, ViewStyle } from 'react-native';
import { StyleSheet } from 'react-native';
import { Main as HTMLMain, Section as HTMLSection } from '@expo/html-elements';
import { isWebBrowser, useResponsive } from '@sg/garnish';

/**
 * # Layout
 * ---------------------
 * A (potentially over-documented) set of components to help with common order app layouts
 *
 *
 * ### Problem:
 * We needed a responsive two-column (sidebar) layout, with idiosyncratic (sticky) scroll logic, based on the old order-dot's home page
 *
 * ### Solution:
 * Set of a11y-enabled components that help with the layout styles.
 * 90% of the value here is styles + Responsive Logic.
 *
 * ## Includes
 * - Main - wrapping component for above the fold content
 * - PrimaryPane - used for splash, has "sticky" positioning hack [most complex]
 * - SecondaryPane - used for sidebar
 *
 * Example Usage:
 * ```
 *   const ExampleScreen = ()=> {
 *     return (
 *       <ScrollView>
 *         <Main>
 *           <PrimaryPane enableSketchyStickySplash={true}>
 *             ...
 *           </PrimaryPane>
 *
 *           <SecondaryPane>
 *             ...
 *           </SecondaryPane>
 *         </Main>
 *
 *         <SomethingBelowTheFold />
 *       </ScrollView>
 *     );
 *   };
 * ```
 *
 * @remarks
 * - These components are co-located in this file because the cross-platform styling is so inter-dependent, and sensitive.
 * - Currently the sticky feature relies on a hack, using `position: sticky`, and is not fully stable
 * - This is intended to be used in conjunction with page-level wrapping components like ScrollView and SafeAreaView
 * - These are just the "scaffolding" components -- the "inner" (e.g. centering, padding) are not included.
 * - These components use components from @expo/html-elements w/ appropriate aria role definitions
 *
 * POSSIBLE IMPROVEMENTS:
 * - refine resizing dynamic, currently it's slightly over-sensitive to flex sizing fluctuations due to varying content
 * - Combine primary and secondary Pane into one component with prop?
 * - move to garnish and document in storybook
 * - improve styling utils
 */

//
// ────────────────────────────────────────────────────────────────────────── I ──────────
//   :::::: L A Y O U T   C O M P O N E N T S : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────────────────────────────
//

const Main = (props: MainProps) => {
  const { minWidth } = useResponsive();
  const { children, style: propStyles, ...rest } = props;

  const responsiveStyles = minWidth.isSM ? styles.mainDesktop : {};
  const mainStyles = [styles.main, responsiveStyles, propStyles];

  return (
    <HTMLMain style={mainStyles} {...rest}>
      {children}
    </HTMLMain>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const PrimaryPane = (props: PrimaryPaneProps) => {
  const { currentBreakpoint, minWidth } = useResponsive();
  const {
    children,
    style: propStyles,
    enableSketchyStickySplash = false,
    ...rest
  } = props;

  const responsiveStyles = currentBreakpoint.isXS
    ? styles.primaryPaneMobile
    : styles.primaryPaneDesktop;

  // this is required for cheeky scroll behavior on desktop/web, where the splash sticks while the sidebar appears to scroll.
  // it uses web-only style properties and is not a very stable solution -- especially mobile-web
  const maybeStickySplash = enableSketchyStickySplash &&
    isWebBrowser() &&
    minWidth.isSM && {
      height: '100vh',
      position: 'sticky',
      top: 0,
      left: 0,
    };

  const paneStyles = [
    styles.primaryPane,
    responsiveStyles,
    maybeStickySplash,
    propStyles,
  ];

  return (
    // @ts-expect-error TS(2322): Type '(StyleProp<ViewStyle> | { flexGrow: number; ... Remove this comment to see the full error message
    <HTMLSection style={paneStyles} {...rest}>
      {children}
    </HTMLSection>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const SecondaryPane = (props: SecondaryPaneProps) => {
  const { minWidth } = useResponsive();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, style: propStyles, ...rest } = props;
  const responsiveStyles = minWidth.isSM ? styles.secondaryPaneDesktop : {};
  const paneStyles = [styles.secondaryPane, responsiveStyles, props.style];

  return (
    <HTMLSection style={paneStyles} {...rest}>
      {props.children}
    </HTMLSection>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

export const Layout = {
  Main,
  PrimaryPane,
  SecondaryPane,
};

//
// ──────────────────────────────────────────────────── II ─────────
//   :::::: S T Y L E S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────
//

const SIDEBAR_BASE_WIDTH = 376;

const styles = StyleSheet.create({
  // container
  main: { flexGrow: 1 },
  mainDesktop: { flexDirection: 'row' },

  // splash
  primaryPane: { flexGrow: 1 },
  primaryPaneMobile: { minHeight: 350, flexBasis: '40%', maxHeight: 600 }, // this feels fragile to me but it was the only thing I could figure out to solve the dynamic sizing
  primaryPaneDesktop: { flexBasis: '50%' },

  // sidebar
  secondaryPane: { flexGrow: 1, zIndex: 1 },
  secondaryPaneDesktop: { width: SIDEBAR_BASE_WIDTH },
});

//
// ────────────────────────────────────────────────── III ──────────
//   :::::: T Y P E S : :  :   :    :     :        :          :
// ────────────────────────────────────────────────────────────
//

type MainProps = Readonly<{
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}> &
  ViewProps;

type PrimaryPaneProps = Readonly<{
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  enableSketchyStickySplash?: boolean;
}> &
  ViewProps;

type SecondaryPaneProps = Readonly<{
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}> &
  ViewProps;

import React from 'react';
import { ListAction, ListActionInfo } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { useCheckoutContext } from '../../../CheckoutProvider';

export const ConnectedCustomerOptions = () => {
  const context = useCheckoutContext();

  return <CustomerOptions {...context} />;
};

export const CustomerOptions = (
  props: Pick<ReturnType<typeof useCheckoutContext>, 'customer'>,
) => {
  const { customer } = props;

  const { formatPhoneNumber } = useLocalizationContext();
  const phoneNumber = customer?.phoneNumber
    ? formatPhoneNumber({
        phoneNumber: customer.phoneNumber,
      }).national
    : undefined;

  return (
    <ListActionInfo iconName="IconProfileStroke">
      <ListAction.Text>
        {customer.firstName}
        {phoneNumber ? `, ${phoneNumber}` : null}
      </ListAction.Text>
    </ListActionInfo>
  );
};

import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Button, theme } from '@sg/garnish';

import type { SweetpassCancellationSectionProps } from '../hooks';
import { SweetpassMembershipSection } from './SweetpassMembershipScreenSection';

export const SweetpassCancellationSection = (
  props: SweetpassCancellationSectionProps,
) => {
  const { testID, title, bodyText, cta } = props;

  const [isCancelling, setCancelling] = useState(false);

  const handlePressCta = useCallback(() => {
    setCancelling(true);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Nx + ESLint Update 2023-12-10
    cta.handler().finally(() => {
      setCancelling(false);
    });
  }, [cta]);

  return (
    <SweetpassMembershipSection testID={testID} content="plain" title={title}>
      <SweetpassMembershipSection.HeadingText testID={testID?.concat('.body')}>
        {bodyText}
      </SweetpassMembershipSection.HeadingText>

      {cta ? (
        <Button
          testID={testID?.concat('.cta')}
          style={styles.cta}
          palette={cta.palette}
          size="large"
          accessibilityLabel={cta.accessibilityLabel}
          onPress={handlePressCta}
          isLoading={isCancelling}
        >
          {cta.title}
        </Button>
      ) : null}
    </SweetpassMembershipSection>
  );
};

// ––– Style –––––––––––––––––––––––––––––––––

const styles = StyleSheet.create({
  cta: {
    marginTop: theme.spacing['6'],
  },
});

import type {
  AccessibilityPropsAndroid,
  AccessibilityPropsIOS,
} from 'react-native';
import { Platform } from 'react-native';

// Hides this element from accessibility devices.
export function useInaccessibleElement(hidden: boolean): InaccessibleProps {
  const accessibilityElementsHidden = hidden;
  const importantForAccessibility = hidden ? 'no-hide-descendants' : 'auto';

  switch (Platform.OS) {
    case 'ios': {
      return { accessibilityElementsHidden };
    }

    case 'android': {
      return { importantForAccessibility };
    }

    case 'web':
    case 'windows':
    case 'macos': {
      return { accessibilityHidden: hidden };
    }
  }
}

type AccessibilityPropsWeb = Readonly<{ accessibilityHidden?: boolean }>;
type InaccessibleProps =
  | AccessibilityPropsWeb
  | AccessibilityPropsIOS
  | AccessibilityPropsAndroid;

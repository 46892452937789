import { useEffect } from 'react';
import type { useInterpret } from '@xstate/react';
import { iCloudStorage } from 'sg-react-native-icloud-storage';
import type { ContextFrom } from 'xstate';

import type { dietaryRestrictionsModel } from '../../dietaryRestrictionsMachine';
import { getMachinePersistedState } from '../../dietaryRestrictionsMachine.utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Reads the persisted context of a dietary restrictions from iCloud key-value storage
 */
export const useDietaryRestrictionsFromICloudStorage = <
  Interpreter extends XStateInterpreter,
>(
  interpreter: Interpreter,
) => {
  useEffect(() => {
    if (!iCloudStorage?.getEntries) return;

    (async () => {
      // if the persisted dietary preferences context is detected,
      // iCloud synchronization has already been performed
      const hasSynchronizedDietaryRestrictions = Boolean(
        await getMachinePersistedState(),
      );

      if (hasSynchronizedDietaryRestrictions) return;

      const iCloudStorageContext =
        (await iCloudStorage.getEntries()) as ICloudDietaryRestrictionsContext;

      if (!iCloudStorageContext) return;

      interpreter.send({
        type: 'SET_RESTRICTIONS',
        restrictions: getMappedDietaryRestrictionsContext(iCloudStorageContext),
      });

      interpreter.send({ type: 'CONFIRM_RESTRICTIONS' });
    })();
  }, [interpreter]);
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function getMappedDietaryRestrictionsContext(
  iCloudContext: ICloudDietaryRestrictionsContext,
): DietaryRestrictionsMachineContext {
  return {
    DAIRY: iCloudContext.DIARY_PREF ?? false,
    FISH: iCloudContext.FISH_PREF ?? false,
    GLUTEN: iCloudContext.GLUTEN_PREF ?? false,
    MEAT: iCloudContext.MEAT_PREF ?? false,
    NUTS: iCloudContext.NUTS_PREF ?? false,
    SOY: iCloudContext.SOY_PREF ?? false,
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type XStateInterpreter = ReturnType<typeof useInterpret>;

type DietaryRestrictionsMachineContext = ContextFrom<
  typeof dietaryRestrictionsModel
>['restrictions'];

type ICloudDietaryRestrictionsContext = Readonly<{
  DIARY_PREF: boolean;
  FISH_PREF: boolean;
  GLUTEN_PREF: boolean;
  MEAT_PREF: boolean;
  NUTS_PREF: boolean;
  SOY_PREF: boolean;
}>;

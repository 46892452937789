import React, { type ComponentProps, useCallback, useRef } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  Pressable,
  type PressableStateCallbackType,
  type StyleProp,
  StyleSheet,
  type TouchableOpacity,
  View,
  type ViewStyle,
} from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { ImpactFeedbackStyle } from 'expo-haptics';
import {
  BodyText,
  Icon,
  theme,
  triggerHapticFeedback,
  webOnlyStyles,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuDietaryPreferencesToggle = (
  props: MenuDietaryPreferencesToggleProps,
) => {
  const { style, onPress, ...restProps } = props;

  // ─────────────────────────────────────────────────────────────────────

  const ref = useRef(null);
  const isHovered = useHover(ref);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    onPress();
    void triggerHapticFeedback(ImpactFeedbackStyle.Soft);
  }, [onPress]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const pressableStyles = useCallback(
    (state: PressableStateCallbackType): StyleProp<ViewStyle> => {
      const { pressed } = state;

      return [
        styles.pressable,
        pressableWebStyles,
        isHovered ? styles.pressableHover : undefined,
        pressed ? styles.pressablePressed : undefined,
        style,
      ];
    },
    [isHovered, style],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      ref={ref}
      style={pressableStyles}
      accessibilityRole="button"
      onPress={handleOnPress}
      {...restProps}
    >
      <BodyText sizeMatch={['16']} style={styles.label}>
        <FormattedMessage {...messages.toggleLabel} />
      </BodyText>

      <View style={styles.iconContainer}>
        <Icon name="IconArrowRight" color={theme.colors.WHITE} />
      </View>
    </Pressable>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  toggleLabel: {
    defaultMessage: 'Set your dietary preferences here or in your account.',
    description: 'Menu | Dietary preferences | Toggle label',
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const ICON_CONTAINER_SIZE = 40;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing['2'],
    backgroundColor: theme.colors.CUCUMBER,
    borderRadius: theme.radius.medium,
    marginBottom: theme.spacing['8'],
    padding: theme.spacing['4'],
  },
  pressableHover: {
    backgroundColor: theme.colors.CUCUMBER_HOVER,
  },
  pressablePressed: {
    opacity: 0.7,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.KALE,
    width: ICON_CONTAINER_SIZE,
    height: ICON_CONTAINER_SIZE,
    borderRadius: ICON_CONTAINER_SIZE,
  },
  label: {
    flex: 1,
  },
});

const pressableWebStyles = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: theme.spacing['1'],
  outlineWidth: 2,
  transition: `background-color ${theme.transitions.base}ms, opacity ${theme.transitions.base}ms`,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuDietaryPreferencesToggleProps = {
  onPress: () => void;
} & Omit<
  ComponentProps<typeof TouchableOpacity>,
  'children' | 'ref' | 'accessibilityRole' | 'onPress'
>;

import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@sg/garnish';

import { LOCATION_RESULTS_THEME_BG_COLOR } from '../../../../../../LocationResults.constants';
import { LocationResultsListsV2Locations } from '../../../../components';
import { type LocationResultsListsV2Props } from '../../../../LocationResultsListsV2.types';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsListsV2Expanded = memo(
  (props: LocationResultsListsV2ExpandedProps) => {
    const {
      variation,
      selectedLocationSearchType,
      recentLocations = [],
      nearbyLocations = { pickup: [], outpost: [] },
      locations = [],
      isLoading,
      interactedLocationRestaurantSlug,
      interactedLocationDeliveryAddressId,
      focusedLocationId,
      onCollapse,
      onLocationCardFocus,
      onLocationCardPress,
      onDeliveryLocationCardPress,
      onRecentOrNearbyLocationCardPress,
    } = props;

    return (
      <View style={styles.outerContainer}>
        <View style={styles.shadow} needsOffscreenAlphaCompositing />

        <View style={styles.innerContainer}>
          <LocationResultsListsV2Locations
            variation={variation}
            selectedLocationSearchType={selectedLocationSearchType}
            recentLocations={recentLocations}
            nearbyLocations={nearbyLocations}
            locations={locations}
            focusedLocationId={focusedLocationId}
            isLoading={isLoading}
            interactedLocationDeliveryAddressId={
              interactedLocationDeliveryAddressId
            }
            interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
            onViewMapPress={onCollapse}
            onRecentOrNearbyLocationCardPress={
              onRecentOrNearbyLocationCardPress
            }
            onLocationCardPress={onLocationCardPress}
            onDeliveryLocationCardPress={onDeliveryLocationCardPress}
            onLocationCardFocus={onLocationCardFocus}
          />
        </View>
      </View>
    );
  },
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  shadow: {
    position: 'absolute',
    height: 5,
    top: -5,
    right: 0,
    left: 0,
    zIndex: 1,

    // NOTE: `backgroundColor` is required to apply elevation on native platforms
    backgroundColor: LOCATION_RESULTS_THEME_BG_COLOR,
    ...theme.elevations['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsListsV2ExpandedProps = Omit<
  LocationResultsListsV2Props,
  'isExpandedByDefault' | 'isUsingSearchField'
> & {
  onCollapse: () => void;
};

import { defineMessages } from 'react-intl';

export const loyaltyHomeScreenMessages = defineMessages({
  screenTitle: {
    defaultMessage: 'Rewards',
    description: 'Loyalty Home Screen | Title',
  },
  screenCustomDocumentTitle: {
    defaultMessage: 'Loyalty Home | sweetgreen',
    description: 'Loyalty Home Screen | Custom Document Title',
  },
});

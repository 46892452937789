import React, { type PropsWithChildren } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';
import { useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoContainer = (props: PropsWithChildren) => {
  const { children } = props;

  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    match([styles.containerXS, styles.containerSM]),
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ScrollView contentContainerStyle={containerStyles}>{children}</ScrollView>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    rowGap: theme.spacing['1'],
  },
  containerXS: {
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['4'],
  },
  containerSM: {
    padding: theme.spacing['6'],
  },
});

import Constants from 'expo-constants';

/**
 * Returns a list of app-associated domains (if any are accessible) by using
 * the provided Expo app env variable name (`APP_ASSOCIATED_DOMAINS` by default).
 *
 * @see {@link https://docs.expo.dev/guides/environment-variables/#reading-environment-variables}
 */
export function getAppAssociatedDomains(
  appAssociatedDomainsVariableName = 'APP_ASSOCIATED_DOMAINS',
): readonly string[] {
  const expoAppEnvVariables = Constants.expoConfig?.extra ?? {};
  const maybeAppAssociatedDomainsList =
    expoAppEnvVariables[appAssociatedDomainsVariableName];

  if (Array.isArray(maybeAppAssociatedDomainsList)) {
    return maybeAppAssociatedDomainsList.map(String);
  }

  return [];
}

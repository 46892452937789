import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from 'react-native';

import { GiftCardsHeader } from '../../components';
import { giftCardsScreenHeaderMessages } from './GiftCardsScreenHeader.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsScreenHeader = (props: GiftCardsScreenHeaderProps) => {
  const { heading, subheading, subheadingLinkText, onSubheadingLinkPress } =
    props;

  return (
    <GiftCardsHeader.Container>
      <GiftCardsHeader.Heading>
        {heading ?? (
          <FormattedMessage {...giftCardsScreenHeaderMessages.heading} />
        )}
      </GiftCardsHeader.Heading>

      <GiftCardsHeader.SubheadingTextWrapper>
        <GiftCardsHeader.Subheading>
          {subheading ?? (
            <FormattedMessage {...giftCardsScreenHeaderMessages.subheading} />
          )}
        </GiftCardsHeader.Subheading>

        {/* NOTE: This is required */}
        <Text> </Text>

        <GiftCardsHeader.SubheadingLink onPress={onSubheadingLinkPress}>
          {subheadingLinkText ?? (
            <FormattedMessage
              {...giftCardsScreenHeaderMessages.redeemNowLink}
            />
          )}
        </GiftCardsHeader.SubheadingLink>
      </GiftCardsHeader.SubheadingTextWrapper>
    </GiftCardsHeader.Container>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardsScreenHeaderProps = {
  heading?: string;
  subheading?: string;
  subheadingLinkText?: string;
  onSubheadingLinkPress: () => void;
};

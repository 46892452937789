/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProductForRestaurantQueryVariables = Types.Exact<{
  baseProductSlug: Types.Scalars['String']['input'];
  restaurantId: Types.Scalars['String']['input'];
}>;

export type ProductForRestaurantQuery = {
  readonly __typename?: 'Query';
  readonly productForRestaurant: {
    readonly __typename?: 'Product';
    readonly id: string;
    readonly slug: string;
  } | null;
};

export const ProductForRestaurantDocument = gql`
  query ProductForRestaurant(
    $baseProductSlug: String!
    $restaurantId: String!
  ) {
    productForRestaurant(
      baseProductSlug: $baseProductSlug
      restaurantId: $restaurantId
    ) {
      id
      slug
    }
  }
`;

export function useProductForRestaurantQuery(
  options: Omit<Urql.UseQueryArgs<ProductForRestaurantQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    ProductForRestaurantQuery,
    ProductForRestaurantQueryVariables
  >({ query: ProductForRestaurantDocument, ...options });
}

import React, { type ComponentProps, forwardRef } from 'react';
import type { Text as RNText } from 'react-native';
import { StyleSheet } from 'react-native';
import { TYPE_CONFIG, WEIGHT_MAP } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { Text } from '../Text';
import { useFluidTextStyles } from '../useFluidTextStyles';

// ─────────────────────────────────────────────────────────────────────────────

export const BodyText = forwardRef<RNText, BodyTextProps>((props, ref) => {
  const { children, size, sizeMatch = [], ...restProps } = props;

  if (sizeMatch.length > 0) {
    return (
      <BodyTextStatic ref={ref} sizeMatch={sizeMatch} {...restProps}>
        {children}
      </BodyTextStatic>
    );
  }

  return (
    <BodyTextFluid ref={ref} size={size} {...restProps}>
      {children}
    </BodyTextFluid>
  );
});

// ─────────────────────────────────────────────────────────────────────────────

const BodyTextStatic = forwardRef<RNText, BodyTextStaticProps>((props, ref) => {
  const { children, sizeMatch, bold, style, ...restProps } = props;

  const { match } = useResponsive();

  const fontSize = match(sizeMatch);
  const textStyles =
    BODY_TEXT_STYLES[fontSize] ?? BODY_TEXT_STYLES[BODY_TEXT_DEFAULT_FONT_SIZE];
  const boldTextStyles = bold ? styles.boldText : undefined;
  const allStyles = [textStyles, boldTextStyles, style];

  return (
    <Text ref={ref} style={allStyles} bold={bold} {...restProps}>
      {children}
    </Text>
  );
});

const BodyTextFluid = forwardRef<RNText, BodyTextFluidProps>((props, ref) => {
  const {
    children,
    size = BODY_TEXT_DEFAULT_ORDINAL_SIZE,
    bold,
    style,
    ...restProps
  } = props;

  const fontSize = BODY_TEXT_PRESET_MAP[size];
  const textStyles =
    BODY_TEXT_STYLES[fontSize] ?? BODY_TEXT_DEFAULT_FONT_STYLES;
  const boldTextStyles = bold ? styles.boldText : undefined;
  const fluidTextStyles = useFluidTextStyles(
    BODY_TEXT_PRESET_MAP[size],
    'BODY',
  );

  const allStyles = [textStyles, boldTextStyles, fluidTextStyles, style];

  return (
    <Text ref={ref} style={allStyles} bold={bold} {...restProps}>
      {children}
    </Text>
  );
});

// ─── Constants ───────────────────────────────────────────────────────────────

const BODY_TEXT_STYLES = TYPE_CONFIG.BODY;

const BODY_TEXT_PRESET_MAP: Record<number, BodyTextFontSize> = {
  1: '24',
  2: '18',
  3: '16',
  4: '14',
  5: '12',
};

const BODY_TEXT_DEFAULT_ORDINAL_SIZE: BodyTextSizeOrdinal = 3;
const BODY_TEXT_DEFAULT_FONT_SIZE: BodyTextFontSize = '16';

const BODY_TEXT_DEFAULT_FONT_STYLES =
  BODY_TEXT_STYLES[BODY_TEXT_DEFAULT_FONT_SIZE];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  boldText: {
    fontWeight: WEIGHT_MAP.bold,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BodyTextProps = Readonly<{
  size?: BodyTextSizeOrdinal;
  sizeMatch?: BodyTextFontSize[];
}> &
  BodyTextBaseProps;

type BodyTextStaticProps = Readonly<{
  sizeMatch: BodyTextFontSize[];
}> &
  BodyTextBaseProps;

type BodyTextFluidProps = Readonly<{
  size?: BodyTextSizeOrdinal;
}> &
  BodyTextBaseProps;

type BodyTextBaseProps = ComponentProps<typeof Text>;

type BodyTextSizeOrdinal = keyof typeof BODY_TEXT_PRESET_MAP;

type BodyTextFontSize = keyof typeof BODY_TEXT_STYLES;

import React, { useLayoutEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useResponsive } from '@sg/garnish';

import { useProductDetailsScreenContext } from '../../ProductDetailsScreen.provider';
import {
  ProductDetailsDressingDetailsView,
  ProductDetailsIngredientsView,
} from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductDetailsContentV2 = () => {
  const { isCustomizationActive } = useProductDetailsScreenContext();

  const { setOptions } = useNavigation();
  const { currentBreakpoint } = useResponsive();

  // ─── Effects ─────────────────────────────────────────────────────────

  useLayoutEffect(() => {
    setOptions({ headerShown: !currentBreakpoint.isXS });
  }, [currentBreakpoint.isXS, setOptions]);

  // ─────────────────────────────────────────────────────────────────────

  if (isCustomizationActive) {
    return <ProductDetailsIngredientsView />;
  }

  return <ProductDetailsDressingDetailsView />;
};

import React from 'react';

/* eslint-disable functional/immutable-data */

/* eslint-disable react-hooks/exhaustive-deps */

// Runs effect only after the first render.
export const useEffectSkipped = (effect: () => void, deps: any[]) => {
  const guard = React.useRef(true);

  React.useEffect(() => {
    if (guard.current) {
      guard.current = false;

      return;
    }

    effect();
  }, [JSON.stringify(deps)]);
};

import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { Header } from '@expo/html-elements';
import { theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuDietaryPreferencesModalHeader = (props: PropsWithChildren) => {
  const { children } = props;

  return <Header style={styles.header}>{children}</Header>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    alignItems: 'flex-end',
    gap: theme.spacing['2'],
    paddingVertical: theme.spacing['4'],
  },
});

import type React from 'react';
import { AccessibilityInfo, findNodeHandle, Platform } from 'react-native';

// Sets the accessibility focus on the element for this reference.
export const focusOnElement = (
  elementRef: React.RefObject<React.Component>,
) => {
  if (!elementRef.current) return;
  const node = elementRef.current;

  if (!node) return;

  if (Platform.OS === 'web') {
    (node as unknown as HTMLElement)?.focus();

    return;
  }

  const elementId = findNodeHandle(node);

  if (elementId) {
    AccessibilityInfo.setAccessibilityFocus(elementId);
  }
};

import { defineMessages } from 'react-intl';

export const fallbacks = defineMessages({
  bagHeaderLoggedOut: {
    defaultMessage: 'Complete your order + earn',
    description: 'Bag | Loyalty | Loyalty Title Logged Out',
  },
  bagHeaderNewUser: {
    defaultMessage: 'You’re about to get rewarded',
    description: 'Bag | Loyalty | Loyalty Title New User',
  },
  bagHeaderRecurringUser: {
    defaultMessage: 'You have {points} points',
    description: 'Bag | Loyalty | Loyalty Title Recurring User',
  },
  bagHeaderSubtitle: {
    defaultMessage: 'Apply one available reward to this order',
    description: 'Bag | Loyalty | Loyalty Subtitle',
  },
  bagHeaderLoggedOutImageMessage: {
    defaultMessage:
      'Get a free side of Ripple Fries when you create an account',
    description: 'Bag | Loyalty | Loyalty Image Text',
  },
});

import { checkIfHasHybridFlowRefreshToken } from '../check-if-has-refresh-token';
import { getHybridFlowApiAccessToken } from '../get-api-access-token';
import { logger } from './refresh-api-access-token.logger';

/**
 * Retrieves a fresh access token for our APIs, using the MSAL library.
 */
export async function refreshHybridFlowApiAccessToken(): Promise<
  string | null | undefined
> {
  try {
    const hasHybridFlowRefreshToken = await checkIfHasHybridFlowRefreshToken();

    if (!hasHybridFlowRefreshToken) return;

    return await getHybridFlowApiAccessToken();
  } catch (error) {
    logger.error(error);
  }
}

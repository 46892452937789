import type { ReactElement, ReactNode } from 'react';
import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';

import { useRatioFromLayoutHandler } from './hooks';

type AspectViewProps = Readonly<{ children?: ReactNode }> & ViewProps;

// This depends on `onLayout`, but that is not always called on resize.
export const AspectView = (props: AspectViewProps): ReactElement => {
  const { style, ...passThruProps } = props;
  const { handleNativeLayout, ratioStyles } = useRatioFromLayoutHandler(style);

  return (
    <View
      {...passThruProps}
      style={[styles.ratioWrap, ratioStyles]}
      onLayout={handleNativeLayout}
    />
  );
};

const styles = StyleSheet.create({
  ratioWrap: { maxWidth: '100%', position: 'relative' },
});

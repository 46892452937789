import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { useUpdatePaymentMethodMutation } from './graphql/UpdatePaymentMethod.generated';

/**
 * Depending on the feature flag, this will either:
 * - Update a Billing Account through monograph-svc.
 * - Update both Billing Accounts and Payment Method Card through payments-svc.
 */
export function useUpdatePaymentMethod(props: UpdatePaymentMethodProps) {
  const { onSuccess } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { track } = useTelemetry();
  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  // ─── Payment Method Card Update ──────────────────────────────────────

  const [updatePaymentMethodCardResponse, updatePaymentMethodCardMutation] =
    useUpdatePaymentMethodMutation();

  const updatePaymentMethod = useCallback(
    async (payload: UpdatePaymentMethodPayload) => {
      const response = await updatePaymentMethodCardMutation({
        input: castPayloadToPaymentMethodCard(payload),
      });

      const result = response.data?.updatePaymentMethod;
      const isSuccess = result?.__typename === 'PaymentMethodCard';

      if (isSuccess) {
        track('payment_credit.save_card');
        addNoticeBanner({
          text: t('credit-card-form.update.success'),
          palette: 'success',
        });

        onSuccess();

        return;
      }

      const userError = t('credit-card-form.error.save');

      track('payment_credit.save_card_failed', { userError });
      addNoticeBanner({ text: userError, palette: 'caution' });
    },
    [addNoticeBanner, onSuccess, t, track, updatePaymentMethodCardMutation],
  );

  return {
    updatePaymentMethod,
    isUpdating: updatePaymentMethodCardResponse.fetching,
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type UpdatePaymentMethodProps = Readonly<{
  onSuccess: () => void;
}>;

type UpdatePaymentMethodPayload = Readonly<{
  id: string;
  nickname: string;
  isDefault: boolean;
  paymentMethodType: 'PAYMENT_METHOD_CARD' | 'BILLING_ACCOUNT';
}>;

// ─── Helpers ─────────────────────────────────────────────────────────────────

function castPayloadToPaymentMethodCard(payload: UpdatePaymentMethodPayload) {
  return {
    id: payload.id,
    nickname: payload.nickname,
    isDefault: payload.isDefault,
  };
}

import { useCallback, useRef } from 'react';
import { Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useResponsive } from '@sg/garnish';

import { useKustomer } from '../../integration/kustomer';

/**
 * Auto start Kustomer.
 */
export const useKustomerAutoStart = () => {
  const { isKustomerEnabled, start } = useKustomer();
  const { currentBreakpoint } = useResponsive();
  const isMobileWeb = currentBreakpoint.isXS && Platform.OS === 'web';

  // Using reference to avoid re-render issues.
  const startReference = useRef<() => void>();

  // eslint-disable-next-line functional/immutable-data
  startReference.current = start;

  useFocusEffect(
    useCallback(() => {
      if (isMobileWeb || !isKustomerEnabled) return;
      startReference?.current?.();
    }, [isMobileWeb, isKustomerEnabled]),
  );
};

import { useCallback, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * The async storage value will be `undefined` while it's loading.
 * Once it has been loaded, it will contain an empty string if it was missing.
 * Otherwise it will contain the stored string value.
 *
 * This is so that its evaluation can be delayed by checking for `undefined`.
 */

export const useAsyncStorage = (key: string): AsyncStorageHook => {
  const [value, update] = useState<string | undefined>(undefined);

  // ─── Reads Value from Local Storage ─────────────────────────────
  useEffect(() => {
    void AsyncStorage.getItem(key).then((persisted) => {
      update(persisted ?? '');
    });
  }, [key]);

  // ─── Writes Value to Local Storage ──────────────────────────────
  const handleStorageUpdate = useCallback(
    (payload: string) => {
      update(payload);
      void AsyncStorage.setItem(key, payload);
    },
    [key],
  );

  return [value, handleStorageUpdate];
};

type AsyncStorageHook = readonly [string | undefined, (value: string) => void];

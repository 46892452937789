import { Dimensions } from 'react-native';
import { theme } from '@garnish/constants';

const { fontScale } = Dimensions.get('window');

// ─── Location Results Card ───────────────────────────────────────────────────

// NOTE: Because we use cards with dynamic height on higher breakpoints,
//       scaled size is only required for smaller devices (`XS` breakpoint).
export const LOCATION_RESULTS_CARD_IMG_SIZE_XS = Math.round(88 * fontScale);

export const LOCATION_RESULTS_CARD_IMG_SIZE_SM = 200;

export const LOCATION_RESULTS_CARD_ILLUSTRATION_SIZE_XS = Math.round(
  60 * fontScale,
);
export const LOCATION_RESULTS_CARD_ILLUSTRATION_SIZE_SM = 160;

export const LOCATION_CARD_IMG_CLOUDINARY_CONFIG_XS = {
  width: LOCATION_RESULTS_CARD_IMG_SIZE_XS,
  height: LOCATION_RESULTS_CARD_IMG_SIZE_XS,
  crop: 'fill',
} as const;
export const LOCATION_CARD_IMG_CLOUDINARY_CONFIG_SM = {
  height: LOCATION_RESULTS_CARD_IMG_SIZE_SM,
  crop: 'fill',
} as const;

export const LOCATION_CARD_IMG_BG_COLOR = theme.colors.OPACITY.QUINOA.DARKER;

// ─── Location Results ────────────────────────────────────────────────────────

export const LOCATION_RESULTS_THEME_BG_COLOR = theme.colors.APP_BACKGROUND;
export const LOCATION_RESULTS_BORDER_RADIUS = 12;
export const LOCATION_RESULTS_OTHER_LOCATIONS_TITLE_HEIGHT = 36;
export const LOCATION_RESULTS_TOGGLE_BTN_HEIGHT = 40;
export const LOCATION_RESULTS_CARD_HEIGHT_PADDING_VERTICAL_XS =
  theme.spacing['4'];
export const LOCATION_RESULTS_CARD_HEIGHT_PADDING_VERTICAL_SM = 12;
export const LOCATION_RESULTS_CARD_DISCLAIMER_HEIGHT = 44;

export const LOCATION_RESULTS_CARD_HEIGHT_XS =
  LOCATION_RESULTS_CARD_IMG_SIZE_XS +
  LOCATION_RESULTS_CARD_HEIGHT_PADDING_VERTICAL_XS * 2 +
  theme.spacing['1'];

export const LOCATION_RESULTS_CARD_HEIGHT_WITH_DISCLAIMER =
  LOCATION_RESULTS_CARD_IMG_SIZE_XS +
  LOCATION_RESULTS_CARD_HEIGHT_PADDING_VERTICAL_XS * 2 +
  LOCATION_RESULTS_CARD_DISCLAIMER_HEIGHT;

export const LOCATION_RESULTS_CARD_MIN_HEIGHT_SM =
  LOCATION_RESULTS_CARD_IMG_SIZE_SM +
  LOCATION_RESULTS_CARD_HEIGHT_PADDING_VERTICAL_SM * 2 +
  45;

export const LOCATION_RESULTS_CARD_HEIGHT_WITHOUT_PADDING =
  LOCATION_RESULTS_CARD_HEIGHT_XS -
  LOCATION_RESULTS_CARD_HEIGHT_PADDING_VERTICAL_XS * 2;
export const LOCATION_RESULTS_CARD_HEIGHT_WITH_DISCLAIMER_WITHOUT_PADDING =
  LOCATION_RESULTS_CARD_HEIGHT_WITH_DISCLAIMER -
  LOCATION_RESULTS_CARD_HEIGHT_PADDING_VERTICAL_XS * 2;
export const LOCATION_RESULTS_CARD_HEIGHT_WITHOUT_PADDING_SM =
  LOCATION_RESULTS_CARD_MIN_HEIGHT_SM -
  LOCATION_RESULTS_CARD_HEIGHT_PADDING_VERTICAL_SM * 2;

// ─── Standard Card ───────────────────────────────────────────────────────────

export const LOCATION_RESULTS_COLLAPSED_HEIGHT_SINGLE_RESULT =
  LOCATION_RESULTS_CARD_HEIGHT_XS;
export const LOCATION_RESULTS_COLLAPSED_HEIGHT_SINGLE_OTHER_LOCATION_RESULT =
  LOCATION_RESULTS_CARD_HEIGHT_XS +
  LOCATION_RESULTS_OTHER_LOCATIONS_TITLE_HEIGHT;
export const LOCATION_RESULTS_COLLAPSED_HEIGHT_MULTIPLE_RESULTS =
  LOCATION_RESULTS_CARD_HEIGHT_XS + LOCATION_RESULTS_TOGGLE_BTN_HEIGHT;

// ─── Card with delivery disclaimer ───────────────────────────────────────────

export const LOCATION_RESULTS_COLLAPSED_HEIGHT_SINGLE_RESULT_WITH_DISCLAIMER =
  LOCATION_RESULTS_COLLAPSED_HEIGHT_SINGLE_RESULT +
  LOCATION_RESULTS_CARD_DISCLAIMER_HEIGHT;
export const LOCATION_RESULTS_COLLAPSED_HEIGHT_SINGLE_OTHER_LOCATION_RESULT_WITH_DISCLAIMER =
  LOCATION_RESULTS_COLLAPSED_HEIGHT_SINGLE_OTHER_LOCATION_RESULT +
  LOCATION_RESULTS_CARD_DISCLAIMER_HEIGHT;
export const LOCATION_RESULTS_COLLAPSED_HEIGHT_MULTIPLE_RESULTS_WITH_DISCLAIMER =
  LOCATION_RESULTS_COLLAPSED_HEIGHT_MULTIPLE_RESULTS +
  LOCATION_RESULTS_CARD_DISCLAIMER_HEIGHT -
  6; // NOTE: Hides list items separator

// ─────────────────────────────────────────────────────────────────────────────

const LOCATION_CARD_HEIGHTS: Record<LocationCardVariations, number> = {
  'single-result': LOCATION_RESULTS_COLLAPSED_HEIGHT_SINGLE_RESULT,
  'single-result-other':
    LOCATION_RESULTS_COLLAPSED_HEIGHT_SINGLE_OTHER_LOCATION_RESULT,
  'multiple-results': LOCATION_RESULTS_COLLAPSED_HEIGHT_MULTIPLE_RESULTS,
};

const LOCATION_CARD_WITH_DISCLOSURE_HEIGHTS: Record<
  LocationCardVariations,
  number
> = {
  'single-result':
    LOCATION_RESULTS_COLLAPSED_HEIGHT_SINGLE_RESULT_WITH_DISCLAIMER,
  'single-result-other':
    LOCATION_RESULTS_COLLAPSED_HEIGHT_SINGLE_OTHER_LOCATION_RESULT_WITH_DISCLAIMER,
  'multiple-results':
    LOCATION_RESULTS_COLLAPSED_HEIGHT_MULTIPLE_RESULTS_WITH_DISCLAIMER,
};

export const LOCATION_RESULTS_COLLAPSED_HEIGHT = {
  DEFAULT: LOCATION_CARD_HEIGHTS,
  WITH_DISCLOSURE: LOCATION_CARD_WITH_DISCLOSURE_HEIGHTS,
};

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationCardVariations =
  | 'single-result'
  | 'single-result-other'
  | 'multiple-results';

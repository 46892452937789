/* cSpell:ignore viewability */

import { type ComponentProps, type ReactNode, useEffect } from 'react';
import React, { forwardRef, useCallback } from 'react';
import type { ListRenderItemInfo, ScrollViewProps } from 'react-native';
import { FlatList, View } from 'react-native';
import useMergedRef from '@react-hook/merged-ref';
import { theme } from '@garnish/constants';

import { noop } from '../../utils';
import {
  useRailItemsRefs,
  useRailNavigation,
  useRailOffsetStyles,
  useRailViewableState,
} from './hooks';
import {
  HorizontalScrollRailFooterText,
  HorizontalScrollRailHeader,
  useRailItemSeparator,
} from './subcomponents';

export const HorizontalScrollRail = forwardRef<
  FlatList,
  HorizontalScrollRailProps
>((props, ref) => {
  const {
    heading = '',
    headingIcon,
    headerPalette = 'dark',
    headerBottomSpacing,
    subtitle = '',
    footerText = '',
    titleStyle,
    headerVariation,
    gap = RAIL_DEFAULT_GAP,
    outerOffset = RAIL_DEFAULT_OUTER_OFFSET,
    initialScrollIndex = 0,
    showNavControls = true,
    children: railItems,
    withoutHeaderBorder,
    itemVisiblePercentThreshold = 90,
    onContentSizeChange,
    trackViewableState: trackViewableStateFromProps,
    count,
    countPosition,
    ...scrollViewProps
  } = props;

  // ─────────────────────────────────────────────────────────────────

  const railItemsRefsMap = useRailItemsRefs(railItems);
  const railOffsetStyles = useRailOffsetStyles(outerOffset);
  const ItemSeparatorComponent = useRailItemSeparator(gap);
  const railViewableState = useRailViewableState(railItems);
  const { viewableState, trackViewableState } = railViewableState;
  const { railRef, scrollToPrevItem, scrollToNextItem, scrollToInitialIndex } =
    useRailNavigation({
      viewableState,
      outerOffset,
      initialScrollIndex,
    });

  // ──────────────────────────────────────────────────────────────────────────

  const handleContentSizeChange = useCallback(
    (width: number, height: number) => {
      onContentSizeChange?.(width, height);
      scrollToInitialIndex();
    },
    [onContentSizeChange, scrollToInitialIndex],
  );

  const handleOnViewableItemsChanged = useCallback<OnViewableItemsChanged>(
    (event) => {
      trackViewableState(event);
      trackViewableStateFromProps?.(event);
    },
    [trackViewableStateFromProps, trackViewableState],
  );

  // That's an ugly workaround for the issue where the `onViewableItemsChanged`
  // call doesn't fire on the initial render, resulting in an incorrect condition
  // for presenting the nav controls.
  useEffect(() => {
    if (initialScrollIndex > 0) return;

    railRef.current?.scrollToOffset({ offset: 1 });
  }, [railRef, initialScrollIndex]);

  // ─────────────────────────────────────────────────────────────────

  const shouldRenderHeader = heading || showNavControls;
  const shouldShowNavControls =
    showNavControls && !viewableState.allItemsAreVisible;

  return (
    <View>
      {shouldRenderHeader ? (
        <HorizontalScrollRailHeader
          heading={heading}
          headingIcon={headingIcon}
          headerPalette={headerPalette}
          headerBottomSpacing={headerBottomSpacing}
          titleStyle={titleStyle}
          subtitle={subtitle}
          headerVariation={headerVariation}
          showNavControls={shouldShowNavControls}
          disablePrevBtn={viewableState.isAtStart}
          disableNextBtn={viewableState.isAtEnd}
          onPrevBtnPress={scrollToPrevItem}
          onNextBtnPress={scrollToNextItem}
          withoutHeaderBorder={withoutHeaderBorder}
          countPosition={countPosition}
          count={count ?? 0}
        />
      ) : null}

      <FlatList
        ref={useMergedRef(railRef, ref)}
        data={railItems}
        renderItem={renderRailItem(railItemsRefsMap)}
        ItemSeparatorComponent={ItemSeparatorComponent}
        viewabilityConfig={{ itemVisiblePercentThreshold }}
        style={railOffsetStyles.railStyle}
        scrollEventThrottle={16}
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={railOffsetStyles.containerStyle}
        onContentSizeChange={handleContentSizeChange}
        onViewableItemsChanged={handleOnViewableItemsChanged}
        //
        // Since we are using `scrollToIndex` functionality, we must also provide an
        // `onScrollToIndexFailed` handler to prevent crashes if `scrollToIndex` fails,
        // which can happen, because of active rerenders, etc.
        //
        // If you need to perform further actions on the failed `scrollToIndex` attempt,
        // you can pass your own `onScrollToIndexFailed` handler.
        onScrollToIndexFailed={noop}
        //
        // That's an ugly workaround for the issue where the
        // `onViewableItemsChanged` call doesn't fire on the initial render,
        // resulting in an incorrect condition for presenting the nav controls.
        // initialScrollIndex={0.01}
        {...scrollViewProps}
      >
        {railItems}
      </FlatList>

      {footerText ? (
        <HorizontalScrollRailFooterText>
          {footerText}
        </HorizontalScrollRailFooterText>
      ) : null}
    </View>
  );
});

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

const RAIL_DEFAULT_GAP = theme.spacing['4'];
const RAIL_DEFAULT_OUTER_OFFSET = 0;

// ─── UTILS ──────────────────────────────────────────────────────────────────────

function renderRailItem(railItemsRefsMap: ReturnType<typeof useRailItemsRefs>) {
  return (props: ListRenderItemInfo<ReactNode>) => {
    const { item, index } = props;
    const ref = railItemsRefsMap.get(index);

    return <View ref={ref}>{item}</View>;
  };
}

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type HorizontalScrollRailProps = Readonly<{
  children: readonly ReactNode[];
  gap?: number;
  outerOffset?: number;
  footerText?: string;
  count?: number;
  countPosition?: ComponentProps<
    typeof HorizontalScrollRailHeader
  >['countPosition'];
  initialScrollIndex?: number;
  showNavControls?: boolean;
  itemVisiblePercentThreshold?: number;
  onContentSizeChange?: (width: number, height: number) => void;
  trackViewableState?: React.ComponentProps<
    typeof FlatList
  >['onViewableItemsChanged'];
  headerPalette?: 'dark' | 'light';
}> &
  Omit<
    ComponentProps<typeof HorizontalScrollRailHeader>,
    | 'headerPalette'
    | 'disablePrevBtn'
    | 'disableNextBtn'
    | 'count'
    | 'countPosition'
  > &
  Omit<ScrollViewProps, 'ref' | 'horizontal'>;

type OnViewableItemsChanged = Extract<
  ComponentProps<typeof FlatList>['onViewableItemsChanged'],
  // eslint-disable-next-line @typescript-eslint/ban-types
  Function
>;

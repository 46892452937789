import type { ShareOptions } from 'react-native';
import { Alert } from 'react-native';
import { Linking } from 'react-native';
import { Platform, Share } from 'react-native';

//  https://reactnative.dev/docs/share#share
export const openNativeShare = async (props: OpenNativeShareProps) => {
  const { message, url, options } = props;

  if (Platform.OS === 'web') return;

  await Share.share(
    {
      message: `${message}\n${url}`,
      url,
    },
    options,
  );
};

/**
 * Opens the provided URL if it's supported.
 */
export async function openUrl(url: string, errorMessage: string) {
  const isSupported = await Linking.canOpenURL(url);

  if (!isSupported) {
    Alert.alert(errorMessage);

    return;
  }

  await Linking.openURL(url);
}

// Returns the "major" part of the version, e.g. "14" for "14.5".
export const getMajorPlatformVersion = (
  platform: typeof Platform = Platform,
) => {
  if (!platform.Version) return null;

  const version = String(platform.Version);

  return Number.parseInt(version, 10);
};

// Returns the "minor" part of the version, e.g. "5" for "14.5".
export const getMinorPlatformVersion = (
  platform: typeof Platform = Platform,
) => {
  if (!platform.Version) return null;

  const version = String(platform.Version);
  const afterSeparator = Math.max(0, version.indexOf('.') + 1);

  if (!version.includes('.')) return null;

  return Number.parseInt(version.slice(afterSeparator), 10);
};

export const hostedFieldPostMessageSnippet = `
  function postMessage(payload) {
    const isInWebView = window.ReactNativeWebView !== undefined;
    const message = JSON.stringify({ ...payload, fromHostedFrame: !isInWebView });
    
    if (isInWebView) {
      window.ReactNativeWebView.postMessage(message);
      return;
    }

    window.parent.postMessage(message);
  }
`;

//
// ─── TYPES ──────────────────────────────────────────────────────────────────────
//

type OpenNativeShareProps = Readonly<{
  message: string;
  url: string;
  options?: ShareOptions;
}>;

/* eslint-disable functional/immutable-data */

import type { ReactElement } from 'react';
import React, { Children, cloneElement } from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import type { ViewProps } from 'react-native';
import { View } from 'react-native';

import type {
  CollapsibleChildrenProps,
  CollapsibleProps,
} from './Collapsible.types';
import { useCollapsibleAnimation } from './hooks/useCollapsibleAnimation';
import { useCollapsibleMachine } from './hooks/useCollapsibleMachine';
import { CollapseIndicatorIcon, Details, Summary } from './subcomponents';
import { Button } from './subcomponents/Button';

const testID = 'sg-collapsible';

export const Collapsible = (props: CollapsibleProps & ViewProps) => {
  const { children, options, onChange, ...rest } = props;
  const {
    helpers: { sendCollapseEvent, sendExpandEvent },
  } = useCollapsibleMachine({
    initial: 'collapsed',
  });
  const [expanded, setExpanded] = React.useState(false);

  const { animation } = options ?? {};
  const flatChildren = flattenChildren(children);
  const transition = useCollapsibleAnimation({
    animation,
    expanded,
    sendCollapseEvent,
    sendExpandEvent,
  });

  const handleCollapse = React.useCallback(() => {
    const targetState = !expanded;

    setExpanded(targetState);
    onChange?.(targetState);
  }, [expanded, onChange]);

  return (
    <View testID={testID} {...rest}>
      {Children.map(flatChildren, (child) =>
        cloneElement(child as ReactElement<CollapsibleChildrenProps>, {
          transition,
          collapsed: !expanded,
          onCollapse: handleCollapse,
          options,
        }),
      )}
    </View>
  );
};

Collapsible.Summary = Summary;
Collapsible.Button = Button;
Collapsible.Details = Details;
Collapsible.Icon = CollapseIndicatorIcon;

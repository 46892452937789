import { useCallback, useState } from 'react';

export const useMenuContentDietaryPreferencesModal = () => {
  const [
    isDietaryPreferencesModalVisible,
    setIsDietaryPreferencesModalVisible,
  ] = useState(false);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const showDietaryPreferencesModal = useCallback(() => {
    setIsDietaryPreferencesModalVisible(true);
  }, []);

  const hideDietaryPreferencesModal = useCallback(() => {
    setIsDietaryPreferencesModalVisible(false);
  }, []);

  // ─────────────────────────────────────────────────────────────────────

  return {
    isDietaryPreferencesModalVisible,
    showDietaryPreferencesModal,
    hideDietaryPreferencesModal,
  };
};

import React from 'react';
import { StyleSheet } from 'react-native';
import { Ledger, theme } from '@sg/garnish';

import { type OrderingLedger } from '../../machines/ordering-machine.types';
import { useLedgerItems } from './useLedgerItems';

/**
 * Ledger for this order.
 */
export const BagLedger = (props: BagLedgerProps) => {
  const { ledger, tip, availableCredit, shouldUseCredit } = props;

  const ledgerItems = useLedgerItems({
    ledger,
    tip,
    availableCredit,
    shouldUseCredit,
  });

  return (
    <Ledger.Container style={styles.container}>
      {ledgerItems
        .filter(Boolean)
        .map((item) =>
          item.label.toLowerCase() === LEDGER_TOTAL_LABEL ? (
            <Ledger.Row
              style={styles.totalMargin}
              {...item}
              key={item.label}
              sizeMatch={['22']}
            />
          ) : (
            <Ledger.Row {...item} key={item.label} sizeMatch={['14']} />
          ),
        )}
    </Ledger.Container>
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const LEDGER_TOTAL_LABEL = 'total';

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingBottom: theme.spacing['6'],
    paddingHorizontal: theme.spacing['4'],
    marginHorizontal: -theme.spacing['4'],
    borderTopWidth: theme.spacing['2'],
    borderTopColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
  totalMargin: {
    marginTop: theme.spacing['1'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagLedgerProps = {
  ledger?: OrderingLedger;
  tip: number;
  availableCredit: number;
  shouldUseCredit: boolean;
};

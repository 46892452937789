export const home = {
  'home.navigation': 'open home page',
  'test.plural': `You have {numVegetables, plural,
    =0 {no vegetables.}
    =1 {1 vegetable.}
    other {# vegetables.}
  }`,

  // ─── Greetings ───────────────────────────────────────────────────────

  'home.hero-cta': 'Explore Menu',
  'home.greeting.signedOut': 'Welcome.',
  'home.greeting.new': 'Welcome, {customerName}.',
  'home.greeting.morning': 'Morning, {customerName}.',
  'home.greeting.afternoon': 'Ready for lunch, {customerName}?',
  'home.greeting.evening': 'Got dinner plans, {customerName}?',
  'home.greeting.specialDay': 'Happy Holidays, {customerName}!',

  // ─── Sidebar ─────────────────────────────────────────────────────────

  'sidebar.header.heading': "Let's get cooking",
  'sidebar.header.heading-with-variable': "Let's get {var}",
  'sidebar.header.heading-with-multiple-variables': '{var1} get {var2}',
  'sidebar.header.sub-heading': 'How would you like your sweetgreen today?',
  'sidebar.header.login-or-sign-up': 'Login / Join',
  'sidebar.search.pickup-or-outpost': 'PICKUP + OUTPOST',
  'sidebar.search.search-option.delivery': 'DELIVERY',
  'sidebar.search.search-option.placeholder':
    'Search store, city, state, or zip',

  // ─── Hero Card ────────────────────────────────────────────────────────

  'home.hero-card.fallback.title': 'Try these crowd-pleasers',
  'home.hero-card.fallback.ctaButton': 'Order',
  'home.hero-card.fallback.image.a11y-label': 'Sweetgreen salad',

  // ─── Reorder Rail ────────────────────────────────────────────────────

  'home.reorder-rail-title': 'Order again',
  'home.reorder-rail-all-orders': 'All Orders',
  'home.reorder-rail-empty-text':
    'Once you place an order your recent orders will appear here',
  'home.reorder-rail-error-text':
    'We ran into an issue. Please try again later.',
  'home.reorder-rail.a11y': 'Reorder {order}',

  // ─── Dynamic CTA ─────────────────────────────────────────────────────

  'home.dynamic-cta.bag.a11y-hint': 'Opens your bag',
  'home.dynamic-cta.active-order.title': 'Preparing - ETA {eta}',
  'home.dynamic-cta.active-order.subtitle': '{orderType} from {restaurant}',
  'home.dynamic-cta.active-order.a11y-hint': 'Opens your active order details',
  'home.dynamic-cta.rate-order.title': 'Let us know how we did',
  'home.dynamic-cta.rate-order.subtitle': 'Rate your last order - {summary}',
  'home.dynamic-cta.rate-order.a11y-hint': 'Opens your customer voice form',

  // ─── Featured Channels ───────────────────────────────────────────────

  'featured-channel.evergreen.title': 'Search for pickup, delivery and Outpost',
  'featured-channel.evergreen.illustration.alt':
    'Sweetgreen customer with their order',
  'featured-channel.evergreen.button': 'Order now',
  'featured-channel.primary.label': 'Recent Location',
  'featured-channel.primary.a11y': 'Start {orderType} order',
  'featured-channel.primary.button': `{orderType, select,
    pickup {Order pickup}
    outpost {Order Outpost}
    delivery {Order delivery}
    other {}
  }`,
  'featured-channel.secondary.optimized.label': 'Search for',
  'featured-channel.secondary.optimized.title': 'Pickup, delivery\n or Outpost',
  'featured-channel.secondary.title': 'Search for pickup, delivery and Outpost',
  'featured-channel.secondary.a11y': 'Start new order',
  'featured-channel.secondary.button-large': 'See more locations',
  'featured-channel.secondary.button-medium': 'Search',
};

import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';
import {
  type BarcodeScanningResult,
  CameraView,
  useCameraPermissions,
} from 'expo-camera';

type QRCodeScannerProps = Readonly<{
  onRequestPermissionResponse: (granted: boolean) => void;
  onScanSuccess: (code: string) => void;
}> &
  ViewProps;

/**
 * QRCodeScanner
 */
export const QRCodeScanner = ({
  onRequestPermissionResponse,
  onScanSuccess,
}: QRCodeScannerProps) => {
  const [permission, requestPermission] = useCameraPermissions();

  React.useEffect(() => {
    (async () => {
      await requestPermission();
    })();
  }, [requestPermission]);

  React.useEffect(() => {
    (async () => {
      if (!permission?.granted) return;
      onRequestPermissionResponse(Boolean(permission?.granted));
    })();
  }, [permission, onRequestPermissionResponse]);

  const handleBarCodeScanned = React.useCallback(
    ({ data }: BarcodeScanningResult) => {
      onScanSuccess(data);
    },
    [onScanSuccess],
  );

  if (!permission?.granted) return null;

  return (
    <CameraView
      style={StyleSheet.absoluteFillObject}
      onBarcodeScanned={handleBarCodeScanned}
      barcodeScannerSettings={{
        barcodeTypes: ['qr'],
      }}
    />
  );
};

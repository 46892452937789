/* cSpell:disable */

import { Platform } from 'react-native';

import { useFeatureFlag } from '@order/LaunchDarkly';
import { checkIfInExpoGoClient } from '@order/utils';

import { getKustomerKeys } from '../../utils';

/**
 * This feature should be behind the `OPS-kustomer-enabled` feature flag.
 * Additionally, it requires the `KUSTOMER_API_KEY` and `KUSTOMER_BRAND_ID` env vars.
 * Finally, it is disabled for Expo Go environments, requiring web or a native client.
 */
export const useKustomerAvailability = () => {
  const isFeatureFlagEnabled = useFeatureFlag('OPS-kustomer-enabled', {
    listenForChanges: true,
  });

  if (!canEnableKustomer()) return false;

  const { apiKey, brandId } = getKustomerKeys();
  const hasKustomerKeys = Boolean(apiKey && brandId);

  return isFeatureFlagEnabled && hasKustomerKeys;
};

const canEnableKustomer = () => {
  const isWeb = Platform.OS === 'web';
  const isOutsideExpoGo = !checkIfInExpoGoClient();

  return isWeb || isOutsideExpoGo;
};

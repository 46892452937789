/* eslint-disable @typescript-eslint/consistent-type-assertions */

import type {
  DynamicTips,
  SweetpassUpsellCtaLocationInBag,
  TargetTippingByLocation,
} from './defaults.types';

/**
 * Don't forget, the current naming convention is in the format of:
 * PROJECT-NUMBER-short-feature-enabled
 * PROJECT-NUMBER-permanent-feature-enabled
 * e.g. "XP-1234-short-ticket-name-enabled"
 * @see {@link https://sweetgreen.atlassian.net/wiki/spaces/TD/pages/4528570450/Feature+Flags#%F0%9F%93%9B-Naming-Conventions Naming Conventions}
 *
 * We track feature flag deletions in this spreadsheet:
 * @see {@link https://docs.google.com/spreadsheets/d/1gm_97S3nVXb2ptnaGkg7uCHRnZmkSr1XSnqG77Yj4ew/edit#gid=0 Feature Flag Removal}
 * Please create a ticket for removing short term feature flags that you created and include it there.
 */

export const featureFlagDefaults = {
  'CELS-440-long-sweetpass-detailed-hero-stats-enabled': false,
  'permanent-forced-app-update-enabled': false,
  'permanent-order-crash-option-enabled': false,
  'permanent-contentful-preview-enabled': false,
  'permanent-apple-pay-enabled': false,
  'permanent-feature-flag-overrides-enabled': false,
  'permanent-debug-menu-enabled': false,

  // !! ───────────────────────────────────────────────────────────────────────
  // !!                           W A R N I N G                              !!
  // !! ───────────────────────────────────────────────────────────────────────
  // !! Most flags should be `true` by default.                              !!
  // !! Flag that are `false` by default are very difficult to remove later. !!
  // !! Please consider if your flag should be `false` before committing it. !!
  // !! It should be reserved for flags that should be `false` if deleted.   !!
  // !! Usually this means special features that aren't always turned on.    !!
  // !! ───────────────────────────────────────────────────────────────────────

  'permanent-in-app-review-enabled': true,
  'permanent-app-tracking-transparency-enabled': true,
  'permanent-refer-friend-enabled': true,
  'permanent-order-stripe-express-checkout-enabled': true,
  'XP-3232-permanent-order-to-healthkit-enabled': true,

  'OPS-kustomer-enabled': true,
  'OPS-enable-maintenance-mode': false,
  'OPS-contentful-enabled': true,
  'OPS-ca-data-request': true,

  'MNU-834-short-term-sum-calories-enabled': true,
  'mnu-5612-short-enable-upsell-categories-modal': true,
  'mnu-5612-enable-bag-add-ons-rail': false,

  'CELS-1191-permanent-dynamic-tip-values': {} as DynamicTips,
  'CELS-683-long-sweetpass-upsell-in-order-status-enabled': true,
  'CELS-683-long-sweetpass-upsell-in-bag-enabled': true,

  'CELS-953-short-homescreen-action-card-enabled': true,
  'CELS-308-short-new-challenges-completion-modal-enabled': true,
  'CELS-796-short-flash-challenge-card-theme-enabled': true,
  'CELS-683-short-sweetpass-upsell-cta-location-in-bag':
    'after-rewards' as SweetpassUpsellCtaLocationInBag,
  'CELS-1179-challenge-opt-in-v2-enabled': true,
  'CELS-1149-short-target-tipping-by-location-enabled': [
    'all',
  ] as TargetTippingByLocation,
  'CELS-1410-enable-gift-card-reg-code': true,
  'CELS-1476-in-app-order-cancellation': true,
  'CELS-1260-enable-annual-subscriptions': true,
  'CELS-1700-in-store-rewards': true,
  'CELS-1883-auth-migration-enabled': true,
  'CELS-1883-open-email-button-enabled': false,
  'cels-2041-auth-hybrid-flow': false,
  'CELS-2107-auth-use-prefer-ephemeral-session': true,
  'CELS-2061-enable-generate-tokens-cta': false,
  'CELS-997-live-activities-enabled': true,
  'cels-2138-challenge-opt-in-url-query-params-poc': true,
  'CELS-1449-in-app-gift-cards-enabled': true,
  'CELS-1449-in-app-gift-cards-max-schedule-days': 60,
  'CELS-2365-show-price-on-reorder-rail': true,
  'CELS-2364-remove-ledger-from-bag': true,
  'CELS-2359-delivery-fee-disclosure-banner': true,
  'CELS-2439-enable-delivery-price-differentiation-disclosure': true,
  'CELS-2489-enable-outpost-price-differentiation-disclosure': true,
  'CELS-2569-hide-price-on-favorites-and-share': true,
  'CELS-683-sweetpass-upsell-in-homescreen-enabled': false,
  'cels-2385-short-home-screen-redesign-enabled': true,
  'CELS-2534-reorder-modal-enabled': true,
  'CELS-2508-location-selection-updates-enabled': true,
  'CELS-2627-order-ordering-redesign-enabled': true,
  'CELS-2678-navigation-redesign-enabled': false,
  'CELS-2760-delivery-fee-disclosure-banner-checkout': true,
  'CELS-2829-in-app-order-async-cancellation-enabled': true,
  'CELS-2852-redesign-menu-enabled': true,
  'CELS-2917-reject-promo-code-previous-orders-enabled': true,
  'CELS-2877-disable-sweetpass-upgrade': true,
  'CELS-3094-menu-top-down-categories-enabled': true,
  'CELS-2684-redesign-customization-enabled': false,
  'CELS-2685-loyalty-v2-enabled': false,
  'CELS-3031-sg-rewards-premium-enabled': false,
  'CELS-2957-home-offers-modal-enabled': true,
  'CELS-2957-home-in-progress-offers-modal-enabled': true,

  'em-716-personalized-bowl-ux': true,
  'em-873-product-recommendation-card-variant': 'A',
  'em-888-hide-outpost-from-map-pickup-section': true,

  // ─── OTA Update ──────────────────────────────────────────────────────

  'OTA-update-in-app-checker-enabled': true,
  'OTA-update-in-app-checker-initial-check-timeout-ms': 10_000, // 10 seconds
  'OTA-update-in-app-checker-polling-interval-ms': 300_000, // 5 minutes
  'OTA-update-is-force-update-enabled': true,
};

import React from 'react';
import type { TextProps, ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { BodyText, theme, useResponsive } from '@sg/garnish';

import { AccountScreenTitle } from './AccountScreenTitle';

export type AccountScreenSectionTitleProps = ViewProps &
  TextProps &
  Readonly<{
    title: string;
  }>;

export const AccountScreenSectionTitle = ({
  testID,
  title,
}: AccountScreenSectionTitleProps): React.ReactElement<Text> => {
  const { currentBreakpoint } = useResponsive();

  if (!currentBreakpoint.isXS) {
    return <AccountScreenTitle testID={testID} title={title} />;
  }

  return (
    <View style={styles.row}>
      <View style={styles.column}>
        <BodyText testID={testID} style={styles.compactWidthTitle}>
          {title}
        </BodyText>
        <View style={styles.hardDivider} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  column: {
    width: '100%',
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  hardDivider: {
    width: '100%',
    height: 1,
    marginTop: theme.spacing['4'],
    backgroundColor: theme.colors.DARK_KALE,
  },
  compactWidthTitle: {
    fontSize: 16,
  },
});

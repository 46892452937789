import React, { type PropsWithChildren, useCallback } from 'react';
import { StyleSheet, type ViewProps } from 'react-native';
import Animated, {
  interpolateColor,
  type SharedValue,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import { Header } from '@expo/html-elements';
import { Container, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuHeaderContainer = (
  props: PropsWithChildren<MenuHeaderContainerProps>,
) => {
  const { children, menuGridScrollOffsetSV } = props;

  const { minWidth, match } = useResponsive();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isMinWidthSm = minWidth.isSM;

  // ─── Shared Values ───────────────────────────────────────────────────

  const headerHeightSV = useSharedValue(0);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const trackLayoutChanges = useCallback<OnLayout>(
    (event) => {
      // eslint-disable-next-line functional/immutable-data
      headerHeightSV.value = event.nativeEvent.layout.height;
    },
    [headerHeightSV],
  );

  // ─── Styles ──────────────────────────────────────────────────────────

  const headerInnerAnimatedStyles = useAnimatedStyle(() => {
    // We only animate border color when the associated grid scroll offset
    // value is provided and the current breakpoint is the smallest.
    if (!menuGridScrollOffsetSV || isMinWidthSm) {
      return {
        borderBottomColor: MENU_HEADER_BORDER_COLOR.STICKY,
      };
    }

    return {
      borderBottomColor: interpolateColor(
        menuGridScrollOffsetSV.value,
        [0, headerHeightSV.value],
        [MENU_HEADER_BORDER_COLOR.INITIAL, MENU_HEADER_BORDER_COLOR.STICKY],
      ),
    };
  });

  const headerInnerResponsiveStyles = match([
    styles.headerInnerContainerXS,
    styles.headerInnerContainerSM,
  ]);

  const headerInnerContainerStyles = [
    styles.headerInnerContainer,
    headerInnerResponsiveStyles,
    headerInnerAnimatedStyles,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Header onLayout={trackLayoutChanges}>
      <Animated.View style={headerInnerContainerStyles}>
        <Container removeSpacing={!minWidth.isLG}>{children}</Container>
      </Animated.View>
    </Header>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const MENU_HEADER_BORDER_COLOR = {
  INITIAL: theme.colors.OPACITY.TRANSPARENT,
  STICKY: theme.colors.DARK_KALE,
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  headerInnerContainer: {
    borderBottomWidth: 1,
  },
  headerInnerContainerXS: {
    paddingBottom: theme.spacing['4'],
  },
  headerInnerContainerSM: {
    paddingVertical: theme.spacing['5'],
    borderTopWidth: 1,
    borderTopColor: MENU_HEADER_BORDER_COLOR.STICKY,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuHeaderContainerProps = {
  // An optional shared value of the associated menu grid scroll view offset,
  // which can be used to animate the bottom border color.
  menuGridScrollOffsetSV?: SharedValue<number>;
};

type OnLayout = NonNullable<ViewProps['onLayout']>;

import type { ContextFrom, EventFrom } from 'xstate';
import { createModel } from 'xstate/lib/model';

// ────────────────────────────────────────────────────────────────────────────────

const initialContext: LastInteractedStoreContext = {
  interactedStores: {
    menu: undefined,
    activeCart: undefined,
    lastPlacedOrder: undefined,
  },
  lastInteractedStore: undefined,
};

export const LastInteractedStoreModel = createModel(initialContext, {
  events: {
    //
    // ─────────────────────────────────────────────────────────────────

    SET_LAST_INTERACTED_STORE: () => ({}),

    SET_ACTIVE_CART_STORE: setLastInteractedStore,
    SET_LAST_INTERACTED_MENU_STORE: setLastInteractedStore,
    SET_LAST_PLACED_ORDER_STORE: setLastInteractedStore,

    // ─────────────────────────────────────────────────────────────────

    RESET_ACTIVE_CART_STORE: resetLastInteractedStore,
    RESET_LAST_INTERACTED_MENU_STORE: resetLastInteractedStore,
    RESET_LAST_PLACED_ORDER_STORE: resetLastInteractedStore,
  },
});

// ─── HELPERS ────────────────────────────────────────────────────────────────────

function setLastInteractedStore(store: LastInteractedStore) {
  return { store };
}

function resetLastInteractedStore() {
  return {};
}

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type LastInteractedStoreContext = Readonly<{
  interactedStores: {
    menu: LastInteractedStore;
    activeCart: LastInteractedStore;
    lastPlacedOrder: LastInteractedStore;
  };
  lastInteractedStore: LastInteractedStore;
}>;

export type LastInteractedStore =
  | Readonly<{ restaurantSlug?: string; addressId?: string }>
  | undefined;

export type LastInteractedStoresContext = ContextFrom<
  typeof LastInteractedStoreModel
>;

export type LastInteractedStoresEvent = EventFrom<
  typeof LastInteractedStoreModel
>;

/* eslint-disable functional/immutable-data */

import React, { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  type LayoutChangeEvent,
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  Platform,
  StyleSheet,
  useWindowDimensions,
  View,
} from 'react-native';
import type Animated from 'react-native-reanimated';
import {
  useAnimatedRef,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';
import { useStyle } from 'react-native-style-utilities';
import {
  AnimatedDialog,
  AnimatedDialogScrollView,
  AnimatedDialogScrollViewMounted,
  theme,
} from '@sg/garnish';

import { LoyaltyInfoBenefits } from '@order/components';
import {
  APPLE_PAY,
  Bag,
  GOOGLE_PAY,
  sumLedgerTotal,
} from '@order/features/ordering';
import { StripeCreditCardForm } from '@order/integrations';

import {
  useExpressPayments,
  type useOrderState,
  useUpdatePaymentAmount,
  useUpdateStripeLoading,
} from '../../hooks';
import { BagDeliveryPreferencesForm } from '../BagDeliveryPreferencesForm';
import { BagTipping } from '../BagTipping';
import { BagAnimatedDialogHeader } from './BagAnimatedDialogHeader';

/**
 * Animated dialog that shows up when choosing options in the bag.
 */
export const BagAnimatedDialog = (props: BagAnimatedDialogProps) => {
  const { stripeReference, isExpressPaymentsEnabled } = useExpressPayments();
  const { orderState } = props;

  // ─── Flags ────────────────────────────────────────────────────────────────

  const {
    isChangingTime,
    isChangingDeliveryPreferences,
    isSubmittingOrder,
    isAddingPaymentMethod,
    isApplyingGiftCard,
    isPerformingBagAction,
    isShowingCheckoutLedger,
    isChangingPaymentMethod,
    isShowingPaymentMethodForm,
    isShowingGiftCardForm,
    isShowingLoyaltyInfo,
    isShowingModal,
  } = orderState;

  // ─── Context ──────────────────────────────────────────────────────────────

  const {
    wantedTime,
    selectedTip,
    paymentMethodId,
    cart,
    paymentMethods,
    availableCredit,
    shouldUseCredit,
    expressPayments: { canApplePay, canGooglePay },
  } = orderState;

  // ─── Cart ─────────────────────────────────────────────────────────────────

  const {
    orderChannel = 'pickup',
    restaurantId = '',
    deliveryOrderDetail,
    deliveryPreferences,
    wantedTimes,
    ledger,
  } = cart;

  const { creditToBeApplied, total } = sumLedgerTotal(
    ledger,
    selectedTip,
    availableCredit,
    shouldUseCredit,
  );

  // ─── Actions ──────────────────────────────────────────────────────────────

  const {
    goBack,
    cancelRequest,
    changeDeliveryPreferences,
    changeTime,
    changeTip,
    requestPaymentMethodChange,
    changePaymentMethod,
    changeCreditUsage,
    requestPaymentMethodForm,
    submitPaymentMethodForm,
    requestGiftCardForm,
    submitGiftCardForm,
    setAvailableExpressPayments,
    requestOrderSubmission,
  } = orderState;

  // ─── Express Payments ─────────────────────────────────────────────────────
  // Whenever the total or loading state changes, we notify the Stripe hosted frame.

  const isPayingWithExpressPayment =
    paymentMethodId === APPLE_PAY || paymentMethodId === GOOGLE_PAY;

  const shouldShowExpressPayment =
    isShowingCheckoutLedger && isPayingWithExpressPayment;

  useUpdatePaymentAmount(stripeReference, total, isShowingCheckoutLedger);
  useUpdateStripeLoading(stripeReference, isSubmittingOrder);

  // ─── Animated Dialog Scrolling ────────────────────────────────────────────
  // We track how much the dialog was scrolled to animate the header opacity.

  const animatedDialogScrollRef = useAnimatedRef<Animated.ScrollView>();
  const scrollOffsetSV = useSharedValue(0);
  const handleOnScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      scrollOffsetSV.value = event.nativeEvent.contentOffset.y;
    },
    [scrollOffsetSV],
  );

  const resetScrollOffset = useCallback(() => {
    scrollOffsetSV.value = 0;
  }, [scrollOffsetSV]);

  // ─── Full Height ──────────────────────────────────────────────────────────

  const hasLargerContent = isShowingPaymentMethodForm || isShowingGiftCardForm;
  const { height: windowHeight } = useWindowDimensions();
  const fullHeight = useStyle(
    () => ({ minHeight: windowHeight }),
    [windowHeight],
  );

  // ─── Height Without Disclaimers ───────────────────────────────────────────
  // This section measures the checkout layout without the disclaimers at the bottom.
  // This will be the max height of the animated dialog, so disclaimers aren't immediately shown.

  const dialogHeightWithoutDisclaimers = useSharedValue(0);
  const scrollContentWithoutDisclaimers = useSharedValue(0);
  const scrollContentStyle = useAnimatedStyle(
    () => ({ maxHeight: scrollContentWithoutDisclaimers.value }),
    [scrollContentWithoutDisclaimers],
  );

  const maxHeight = isShowingCheckoutLedger
    ? dialogHeightWithoutDisclaimers
    : undefined;

  const scrollViewStyle = isShowingCheckoutLedger
    ? scrollContentStyle
    : undefined;

  const measureCheckoutWithoutDisclaimers = useCallback(
    (event: LayoutChangeEvent) => {
      const layoutHeight = event.nativeEvent.layout.height;
      const layoutWithExpressPayment = layoutHeight + theme.spacing['24'];

      const withExpressPayment = shouldShowExpressPayment
        ? layoutWithExpressPayment
        : layoutHeight;

      const withHeader = withExpressPayment + theme.spacing['18'];

      dialogHeightWithoutDisclaimers.value = withHeader;
      scrollContentWithoutDisclaimers.value = withExpressPayment;
    },
    [
      dialogHeightWithoutDisclaimers,
      scrollContentWithoutDisclaimers,
      shouldShowExpressPayment,
    ],
  );

  // ─── Scroll View ──────────────────────────────────────────────────────────

  const scrollViewProps = useMemo(
    () => ({
      ref: animatedDialogScrollRef,
      style: scrollViewStyle,
      contentContainerStyle: styles.dialogContentInnerContainer,
      automaticallyAdjustKeyboardInsets: true,
      stickyHeaderIndices: [0],
      onScroll: handleOnScroll,
    }),
    [animatedDialogScrollRef, scrollViewStyle, handleOnScroll],
  );

  const scrollViewFullHeightProps = useMemo(
    () => ({
      style: fullHeight,
      stickyHeaderIndices: undefined,
      automaticallyAdjustKeyboardInsets: false,
    }),
    [fullHeight],
  );

  // ─── Localization ────────────────────────────────────────────────────

  const { formatMessage } = useIntl();
  const dismissModal = formatMessage(messages.dismissModal);

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <AnimatedDialog
      show={isShowingModal}
      maxHeight={maxHeight}
      dismissOverlayAccessibilityLabel={dismissModal}
      style={hasLargerContent ? styles.largerContentMargin : null}
      onLayout={resetScrollOffset}
      dismissModal={cancelRequest}
    >
      {/* Wanted time selection */}
      {isChangingTime && wantedTime ? (
        <AnimatedDialogScrollView {...scrollViewProps}>
          <BagAnimatedDialogHeader
            orderState={orderState}
            scrollOffsetSV={scrollOffsetSV}
          />

          <Bag.TimePicker
            wantedTime={wantedTime}
            wantedTimes={wantedTimes}
            changeTime={changeTime}
          />
        </AnimatedDialogScrollView>
      ) : null}

      {/* Delivery preferences form */}
      {isChangingDeliveryPreferences ? (
        <AnimatedDialogScrollView {...scrollViewProps}>
          <BagAnimatedDialogHeader
            orderState={orderState}
            scrollOffsetSV={scrollOffsetSV}
          />

          <BagDeliveryPreferencesForm
            deliveryOrderDetail={deliveryOrderDetail}
            deliveryPreferences={deliveryPreferences}
            onSubmit={changeDeliveryPreferences}
          />
        </AnimatedDialogScrollView>
      ) : null}

      {/* Payment method picker */}
      {isChangingPaymentMethod ? (
        <AnimatedDialogScrollView {...scrollViewProps}>
          <BagAnimatedDialogHeader
            orderState={orderState}
            scrollOffsetSV={scrollOffsetSV}
          />

          <Bag.PaymentMethodPicker
            paymentMethods={paymentMethods}
            paymentMethodId={paymentMethodId}
            canApplePay={canApplePay}
            canGooglePay={canGooglePay}
            onRequestPaymentMethodForm={requestPaymentMethodForm}
            onRequestGiftCardForm={requestGiftCardForm}
            changePaymentMethod={changePaymentMethod}
          />
        </AnimatedDialogScrollView>
      ) : null}

      {/* Payment method form */}
      {isShowingPaymentMethodForm ? (
        <AnimatedDialogScrollView
          {...scrollViewProps}
          {...Platform.select({ native: scrollViewFullHeightProps })}
        >
          <Bag.PaymentMethodForm onBack={goBack} onClose={cancelRequest}>
            <StripeCreditCardForm
              excludedFields={['nickname', 'isDefault']}
              isSubmitting={isAddingPaymentMethod}
              handleOnSave={submitPaymentMethodForm}
            />
          </Bag.PaymentMethodForm>
        </AnimatedDialogScrollView>
      ) : null}

      {/* Giftcard form */}
      {isShowingGiftCardForm ? (
        <AnimatedDialogScrollView
          {...scrollViewProps}
          {...Platform.select({ native: scrollViewFullHeightProps })}
        >
          <Bag.GiftCardForm
            isLoading={isApplyingGiftCard}
            onSubmit={submitGiftCardForm}
            onBack={goBack}
            onClose={cancelRequest}
          />
        </AnimatedDialogScrollView>
      ) : null}

      {/* Loyalty info */}
      {isShowingLoyaltyInfo ? (
        <AnimatedDialogScrollView {...scrollViewProps}>
          <BagAnimatedDialogHeader
            orderState={orderState}
            scrollOffsetSV={scrollOffsetSV}
          />

          <LoyaltyInfoBenefits />
        </AnimatedDialogScrollView>
      ) : null}

      {/* Checkout ledger - always mounted due to the hosted frame */}
      <AnimatedDialogScrollViewMounted
        show={isShowingCheckoutLedger}
        {...scrollViewProps}
      >
        <BagAnimatedDialogHeader
          orderState={orderState}
          scrollOffsetSV={scrollOffsetSV}
          show={isShowingCheckoutLedger}
        />

        {isShowingCheckoutLedger ? (
          <Bag.CheckoutContainer onLayout={measureCheckoutWithoutDisclaimers}>
            <Bag.SelectedPaymentMethod
              paymentMethodId={paymentMethodId}
              paymentMethods={paymentMethods}
              isDisabled={isSubmittingOrder}
              requestPaymentMethodChange={requestPaymentMethodChange}
              requestPaymentMethodForm={requestPaymentMethodForm}
            />

            {availableCredit > 0 ? (
              <Bag.CreditToggle
                shouldUseCredit={shouldUseCredit}
                availableCredit={availableCredit}
                appliedCredit={creditToBeApplied}
                onChange={changeCreditUsage}
              />
            ) : null}

            <BagTipping
              restaurantId={restaurantId}
              orderChannel={orderChannel}
              selectedTip={selectedTip}
              subtotal={ledger?.subtotal ?? 0}
              isDisabled={isPerformingBagAction}
              onTipChange={changeTip}
            />

            <Bag.Ledger
              tip={selectedTip}
              ledger={ledger}
              availableCredit={availableCredit}
              shouldUseCredit={shouldUseCredit}
            />

            {shouldShowExpressPayment ? null : (
              <Bag.CheckoutCta
                paymentMethodId={paymentMethodId}
                isLoading={isSubmittingOrder}
                isDisabled={isPerformingBagAction}
                onCheckout={requestOrderSubmission}
              />
            )}
          </Bag.CheckoutContainer>
        ) : null}

        {isExpressPaymentsEnabled ? (
          <Bag.StripeOpacityAnimation show={shouldShowExpressPayment}>
            <Bag.Stripe
              ref={stripeReference}
              setAvailableExpressPayments={setAvailableExpressPayments}
              onPaymentMethodId={requestOrderSubmission}
            />
          </Bag.StripeOpacityAnimation>
        ) : null}

        {isShowingCheckoutLedger ? (
          <View style={styles.disclaimersPadding}>
            <Bag.Disclaimers orderChannel={orderChannel} />
          </View>
        ) : null}
      </AnimatedDialogScrollViewMounted>
    </AnimatedDialog>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  dismissModal: {
    defaultMessage: 'Dismiss modal',
    description: 'Bag | Animated Modal | Dismiss modal',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  dialogContentInnerContainer: {
    flexGrow: 1,
  },
  disclaimersPadding: {
    padding: theme.spacing['8'],
    backgroundColor: theme.colors.LINEN,
  },
  largerContentMargin: {
    marginTop: theme.spacing['20'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagAnimatedDialogProps = {
  orderState: ReturnType<typeof useOrderState>;
};

import { useCallback, useMemo } from 'react';
import { useActor } from '@xstate/react';

import { useGlobalAppState } from '@order/GlobalAppState';
import type { DietaryPropertyKind } from '@order/graphql';
import { useTelemetry } from '@order/Telemetry';
import { checkIfShallowlyEqual } from '@order/utils';

import { useActiveRouteName } from '../../../navigation';
import type { DietaryRestrictionsTypes } from './dietaryRestrictionsMachine';
import { dietaryRestrictionsModel } from './dietaryRestrictionsMachine';

// ────────────────────────────────────────────────────────────────────────────────

export const useDietaryRestrictionsMachine = () => {
  const { dietaryRestrictionsMachineRef } = useGlobalAppState();
  const activeRouteName = useActiveRouteName();
  const { track } = useTelemetry();

  const actor = useActor(dietaryRestrictionsMachineRef);
  const [state, send] = actor;

  const { restrictions, lastKnownRestrictions } = state.context;
  const isModified = !checkIfShallowlyEqual(
    restrictions,
    lastKnownRestrictions,
  );

  const sortedRestrictionsNames = useMemo(() => {
    const restrictionsNames = Object.keys(restrictions);
    const compareAlphabetically = (
      restriction: string,
      nextRestriction: string,
    ) => restriction.localeCompare(nextRestriction);

    return [...restrictionsNames].sort(
      compareAlphabetically,
    ) as readonly DietaryPropertyKind[];
  }, [restrictions]);

  // ─── HELPERS ────────────────────────────────────────────────────────

  const toggleRestriction = useCallback(
    (restriction: DietaryPropertyKind) => {
      const selected = !restrictions[restriction];

      track('dietary-restrictions.select', {
        route: activeRouteName,
        restriction,
        selected,
      });

      send(dietaryRestrictionsModel.events.TOGGLE_RESTRICTION(restriction));
    },
    [activeRouteName, restrictions, send, track],
  );

  const setRestrictions = useCallback(
    (updatedRestrictions: Partial<DietaryRestrictionsTypes>) => {
      send(
        dietaryRestrictionsModel.events.SET_RESTRICTIONS(updatedRestrictions),
      );
    },
    [send],
  );

  const revertRestrictions = useCallback(() => {
    send(dietaryRestrictionsModel.events.REVERT_RESTRICTIONS());
  }, [send]);

  const confirmRestrictions = useCallback(() => {
    send(dietaryRestrictionsModel.events.CONFIRM_RESTRICTIONS());
  }, [send]);

  // ────────────────────────────────────────────────────────────────────

  return {
    restrictions,
    sortedRestrictionsNames,
    toggleRestriction,
    setRestrictions,
    revertRestrictions,
    confirmRestrictions,
    isModified,
  };
};

/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { logger as LOG } from '@garnish/logger';
import { assign, createMachine } from 'xstate';

import { removeActiveInteractionState } from '../actions';
import {
  checkIfHasActiveSession,
  checkIfShouldHandleRedirectResponse,
} from '../guards';
import { removeTokens, storeHybridFlowTokens } from '../helpers';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A state machine that handles the various steps of the authentication process
 * by initiating multiple flows through internal services.
 */
export const azureAuthMachine = createMachine(
  {
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    tsTypes: {} as import('./azure-auth-machine.typegen').Typegen0,
    schema: {
      context: {} as AzureAuthStateMachineContext,
      events: {} as AzureAuthStateMachineEvents,
      services: {} as AzureAuthStateMachineServices,
    },
    predictableActionArguments: true,
    initial: 'waiting',
    states: {
      waiting: {
        description: 'An idle state, where the machine is ready to start.',
        on: {
          INITIALIZE: 'initializing',
        },
      },
      initializing: {
        description:
          'A state in which the machine is setting up any necessary operations before being able to handle authentication flows.',
        invoke: {
          src: 'initialize',
          onDone: [
            {
              target: 'handling-redirect',
              cond: 'checkIfNeedToHandleRedirect',
            },
            { target: 'authenticating' },
          ],
          onError: 'waiting',
        },
        on: {
          RESET: 'waiting',
        },
      },
      restarting: {
        entry: ['removeRefreshToken'],
        description:
          'A state in which the machine is restarting the auth flow.',
        always: ['authenticating'],
      },
      'handling-redirect': {
        description:
          'Web-only state, in which the machine handles authentication after redirection.',
        invoke: {
          src: 'handleRedirect',
          onDone: [
            {
              target: 'authenticating',
              cond: 'checkIfSignedOut',
            },
            {
              target: 'signing-in',
              actions: ['storeAccountData'],
            },
          ],
          onError: {
            target: 'ad-authentication-failed',
            actions: ['onSystemError'],
          },
        },
      },
      authenticating: {
        description:
          'A state where an authentication request is initiated. The web platform will utilize a redirect flow, while native will use a popup flow.',
        invoke: {
          src: 'authenticate',
          onDone: {
            target: 'signing-in',
            actions: ['storeAccountData'],
          },
          onError: {
            target: 'ad-authentication-failed',
            actions: ['onSystemError'],
          },
        },
        on: {
          RESET: 'waiting',
        },
      },
      'ad-authentication-failed': {
        description:
          'A status that signals a failed or interrupted authentication request while interacting with Azure AD.',
        entry: ['removeActiveInteractionState'],
        on: {
          RESET: 'waiting',
          RESTART: 'restarting',
        },
      },
      'authentication-failed': {
        description:
          'A status that signals a failed or interrupted authentication request while interacting with SG services.',
        on: {
          RESET: 'waiting',
          RESTART: 'restarting',
        },
      },
      'signing-in': {
        description:
          'A state in which the machine attempts to authenticate a user to determine whether they already exist. Otherwise, the user will be redirected to the new account creation step.',
        invoke: {
          src: 'signIn',
          onDone: [
            {
              target: 'redirect',
              cond: 'checkIfSignedIn',
            },
            { target: 'filling-account-data' },
          ],
          onError: {
            target: 'authentication-failed',
            actions: ['onSystemError'],
          },
        },
      },
      'filling-account-data': {
        description:
          'New account creation state in which the user enters needed information.',
        on: {
          SUBMIT_ACCOUNT_DATA: [
            {
              cond: 'checkIfNeedToRefreshTokens',
              target: 'refreshing-access-tokens',
              actions: ['storeAccountInput'],
            },
            {
              target: 'creating-account',
              actions: ['storeAccountInput'],
            },
          ],
          RESET: 'waiting',
          RESTART: 'restarting',
        },
      },
      'refreshing-access-tokens': {
        description:
          'A state in which a machine attempts to refresh access tokens using the stored refresh token.',
        invoke: {
          src: 'refreshAccessTokens',
          onDone: {
            target: 'creating-account',
            actions: 'storeAccessTokens',
          },
          onError: 'filling-account-data',
        },
      },
      'creating-account': {
        description:
          'A state in which a machine attempts to create a new account.',
        invoke: {
          src: 'createAccount',
          onDone: [
            {
              target: 'redirect',
              cond: 'checkIfSignedUp',
            },
            { target: 'filling-account-data' },
          ],
          onError: 'filling-account-data',
        },
      },
      redirect: {
        entry: ['storeTokens'],
        description:
          'A state that can be utilized to redirect the user following authentication.',
        on: {
          COMPLETE: 'waiting',
          RESTART: 'restarting',
        },
      },
    },
  },
  {
    actions: {
      onSystemError() {
        logger.debug('`onSystemError` action is not provided.');
      },
      storeTokens(context) {
        const { apiRefreshToken, apiAccessToken } = context;

        if (!apiRefreshToken || !apiAccessToken) return;

        void storeHybridFlowTokens({
          accessToken: apiAccessToken,
          refreshToken: apiRefreshToken,
        });
      },
      removeRefreshToken() {
        void removeTokens();
      },
      storeAccountData: assign((_context, event) => {
        const { data } = event;

        if (!data) return {};

        const { email, apiAccessToken, apiRefreshToken, vendorApiAccessToken } =
          data;

        return { email, apiAccessToken, apiRefreshToken, vendorApiAccessToken };
      }),
      storeAccessTokens: assign((_context, event) => {
        const { data } = event;

        if (!data) return {};

        const { apiAccessToken, vendorApiAccessToken } = data;

        return { apiAccessToken, vendorApiAccessToken };
      }),
      storeAccountInput: assign((_context, event) => ({
        createAccountInput: event.input,
      })),
      removeActiveInteractionState() {
        removeActiveInteractionState();
      },
    },
    services: {
      async initialize() {
        logger.debug('`initialize` service is not provided.');

        return undefined;
      },
      async handleRedirect() {
        logger.debug('`handleRedirect` service is not provided.');

        return undefined;
      },
      async authenticate() {
        logger.debug('`authenticate` service is not provided.');

        return undefined;
      },
      async signIn() {
        logger.debug('`signIn` service is not provided.');

        return { hasSignedIn: false };
      },
      async createAccount() {
        logger.debug('`createAccount` service is not provided.');

        return { hasCreatedAccount: false };
      },
      async refreshAccessTokens() {
        logger.debug('`refreshAccessTokens` service is not provided.');

        return undefined;
      },
    },
    guards: {
      checkIfSignedIn(_context, event) {
        return event.data.hasSignedIn;
      },
      checkIfSignedUp(_context, event) {
        return event.data.hasCreatedAccount;
      },
      checkIfSignedOut(_context) {
        return !checkIfHasActiveSession();
      },
      checkIfNeedToHandleRedirect(_context) {
        return checkIfShouldHandleRedirectResponse();
      },
      checkIfNeedToRefreshTokens() {
        logger.debug('`checkIfNeedToRefreshTokens` guard is not provided.');

        return true;
      },
    },
  },
);

// ─── Logger ──────────────────────────────────────────────────────────────────

LOG.enable('AZURE AUTH STATE MACHINE');

const logger = LOG.extend('AZURE AUTH STATE MACHINE');

// ─── Types ───────────────────────────────────────────────────────────────────

export type AzureAuthStateMachineContext = {
  email: string | undefined;
  apiAccessToken: string | undefined;
  apiRefreshToken: string | undefined;
  vendorApiAccessToken: string | undefined;
  createAccountInput: CreateAccountInput | undefined;
};

export type AzureAuthStateMachineServices = {
  initialize: {
    data: unknown;
  };
  handleRedirect: {
    data:
      | {
          apiAccessToken?: string;
          apiRefreshToken?: string;
          vendorApiAccessToken?: string;
          email?: string;
        }
      | undefined;
  };
  authenticate: {
    data:
      | {
          apiAccessToken?: string;
          apiRefreshToken?: string;
          vendorApiAccessToken?: string;
          email?: string;
        }
      | undefined;
  };
  refreshAccessTokens: {
    data:
      | {
          apiAccessToken?: string;
          vendorApiAccessToken?: string;
        }
      | undefined;
  };
  signIn: { data: { hasSignedIn: boolean } };
  signOut: { data: undefined };
  createAccount: { data: { hasCreatedAccount: boolean } };
};

export type AzureAuthStateMachineEvents =
  | { type: 'INITIALIZE' }
  | { type: 'SUBMIT_ACCOUNT_DATA'; input: CreateAccountInput }
  | { type: 'RESET' }
  | { type: 'RESTART' }
  | { type: 'SIGN_OUT' }
  | { type: 'COMPLETE' };

type CreateAccountInput = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  birthday: string;
};

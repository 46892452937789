import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNoticeBannersStackContext, useResponsive } from '@sg/garnish';

import { useAddLineItemToCart } from '@order/hooks';
import { telemetryProductFromInput, useTelemetry } from '@order/Telemetry';

import {
  type MenuContentDeliveryDetailsData,
  type MenuContentProduct,
} from '../../MenuContent.types';

// ─────────────────────────────────────────────────────────────────────────────

export const useOneClickAddToBag = (params: UseOneClickAddToBagParams) => {
  const { restaurantSlug, deliveryDetails } = params;

  const { currentBreakpoint } = useResponsive();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { track } = useTelemetry();
  const { formatMessage } = useIntl();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const trackProductAddSuccess = useCallback<AddLineItemToCartSuccess>(
    (payload) => {
      const { product, quantity } = payload;

      const mappedProduct = telemetryProductFromInput({
        ...product,
        quantity,
        location: restaurantSlug,
      });

      track('menu.one_click_add_to_bag_submit', {
        selectedProduct: mappedProduct,
      });
    },
    [restaurantSlug, track],
  );

  const showProductAddSuccessBanner = useCallback<AddLineItemToCartSuccess>(
    (payload) => {
      // NOTE: Banner should be shown only on the smallest breakpoint
      if (currentBreakpoint.isXS) return;

      const { product, quantity } = payload;

      const bannerText = formatMessage(messages.successMessage, {
        product_name: product.name,
        quantity,
      });

      addNoticeBanner({
        text: bannerText,
        autoHideTimeout: 2500,
        palette: 'success',
      });
    },
    [addNoticeBanner, currentBreakpoint.isXS, formatMessage],
  );

  const onProductAddSuccess = useCallback<AddLineItemToCartSuccess>(
    (payload) => {
      trackProductAddSuccess(payload);
      showProductAddSuccessBanner(payload);
    },
    [showProductAddSuccessBanner, trackProductAddSuccess],
  );

  const onProductAddSuccessFail = useCallback<AddLineItemToCartError>(
    (payload) => {
      track('menu.one_click_add_to_bag_fail', { message: payload.message });
    },
    [track],
  );

  const { addLineItemToCart, fetching } = useAddLineItemToCart({
    onSuccess: onProductAddSuccess,
    onError: onProductAddSuccessFail,
  });

  const addProductToBag = useCallback(
    async (product: MenuContentProduct, quantity: number) => {
      return addLineItemToCart({ product, quantity, deliveryDetails });
    },
    [addLineItemToCart, deliveryDetails],
  );

  const checkIfSupportsOneClickAddToBag = useCallback(
    (isProductModifiable: boolean) => {
      if (isProductModifiable) {
        return false;
      }

      return true;
    },
    [],
  );

  // ─────────────────────────────────────────────────────────────────────

  return {
    addProductToBag,
    isAddingProductToBag: fetching,
    checkIfSupportsOneClickAddToBag,
  };
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  successMessage: {
    defaultMessage: '{quantity}x {product_name} added to your bag!',
    description: 'Menu | 1-click add to bag | Success message',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type UseOneClickAddToBagParams = {
  restaurantSlug: string;
  deliveryDetails?: MenuContentDeliveryDetailsData;
};

type AddLineItemToCartSuccess = NonNullable<
  AddLineItemToCartParams['onSuccess']
>;

type AddLineItemToCartError = NonNullable<AddLineItemToCartParams['onError']>;

type AddLineItemToCartParams = NonNullable<
  Parameters<typeof useAddLineItemToCart>[0]
>;

import { useCallback } from 'react';

import { useTelemetry } from '@order/Telemetry';

// ─────────────────────────────────────────────────────────────────────────────

export const useChallengesTelemetry = () => {
  const { track } = useTelemetry();

  const handleRewardSelectedTelemetry = useCallback(
    (rewardId: string) => {
      track('challenges.reward_selected', { rewardId });
    },
    [track],
  );

  const handleRewardErrorTelemetry = useCallback(
    (userError?: string, systemError?: string) => {
      track('challenges.reward_error', { userError, systemError });
    },
    [track],
  );

  const handleChallengesTermsViewTelemetry = useCallback(() => {
    track('challenges.terms_view');
  }, [track]);

  const handleChallengesStartTelemetry = useCallback(
    (challengeId: string) => {
      track('challenges.start', { challengeId });
    },
    [track],
  );

  const handleChallengesFailedTelemetry = useCallback(
    (userError?: string, systemError?: string) => {
      track('challenges.start_failed', { userError, systemError });
    },
    [track],
  );

  return {
    handleRewardSelectedTelemetry,
    handleRewardErrorTelemetry,

    handleChallengesTermsViewTelemetry,
    handleChallengesStartTelemetry,
    handleChallengesFailedTelemetry,
  };
};

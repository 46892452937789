import type { Address } from '@order/graphql';

import type { AddressType } from '../AddressForm.types';

/**
 * The address type is derived from the name, either home/work or custom.
 */
export const getAddressTypeFromAddress = (
  address?: Pick<Partial<Address>, 'name'>,
): AddressType => {
  if (address?.name === 'home') return 'home';

  if (address?.name === 'work') return 'work';

  return 'custom';
};

import { useCallback } from 'react';
import type { ActorRefFrom, StateFrom } from 'xstate';
import { useSelector } from '@xstate/react';

import { createCounterMachine } from './counter-machine';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A small hook to interact with counter state machine in React.
 * Expects counter machine interpreter as an argument.
 */
export function useCounterMachineInterpreter(
  interpreter: CounterMachineInterpreter,
) {
  //
  // ─── Helpers ─────────────────────────────────────────────────────────

  const incrementCounter = useCallback(() => {
    interpreter.send('INCREMENT');
  }, [interpreter]);

  const decrementCounter = useCallback(() => {
    interpreter.send('DECREMENT');
  }, [interpreter]);

  const resetCounter = useCallback(() => {
    interpreter.send('RESET');
  }, [interpreter]);

  // ─── Data ────────────────────────────────────────────────────────────

  const counterValue = useSelector(interpreter, counterValueSelector);
  const isReady = useSelector(interpreter, isReadySelector);

  // ─────────────────────────────────────────────────────────────────────

  return {
    isReady,
    counterValue,
    incrementCounter,
    decrementCounter,
    resetCounter,
  };
}

// ─── Selectors ───────────────────────────────────────────────────────────────
//
// see: https://xstate.js.org/docs/recipes/react.html#improving-performance

function isReadySelector(state: CounterMachineState) {
  return state.matches('ready');
}

function counterValueSelector(state: CounterMachineState) {
  return state.context.value;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type CounterMachineState = StateFrom<ReturnType<typeof createCounterMachine>>;

type CounterMachineInterpreter = ActorRefFrom<
  ReturnType<typeof createCounterMachine>
>;

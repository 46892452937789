import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { IconLink, TitleText, useResponsive } from '@sg/garnish';

import { type DeliveryPreferenceType } from '@order/graphql';

import { AddressForm } from '../../../Address';
import { Modal } from '../../../Modal';

export const CompleteAddressFormModal = (
  props: CompleteAddressFormModalProps,
) => {
  const { address, isVisible, isAddingAddress, onRequestClose, onSubmit } =
    props;

  const { match } = useResponsive();
  const { formatMessage } = useIntl();

  return (
    <Modal
      fitHeight
      size={match(['full', 'small'])}
      accessibilityLabel={formatMessage(messages.a11yId)}
      visible={isVisible}
      onRequestClose={onRequestClose}
    >
      <View style={styles.header}>
        <IconLink
          name="IconArrowLeft"
          width={32}
          height={32}
          accessibilityLabel={formatMessage(messages.returnToLocations)}
          onPress={onRequestClose}
        />

        <TitleText sizeMatch={['32']}>
          <FormattedMessage {...messages.title} />
        </TitleText>
      </View>

      <AddressForm
        visible
        disableAddress
        address={address}
        loading={isAddingAddress}
        submitCta={formatMessage(messages.submitCta)}
        onSave={onSubmit}
      />
    </Modal>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  returnToLocations: {
    defaultMessage: 'Return to locations',
    description: 'Complete address form | Return to locations',
  },
  a11yId: {
    defaultMessage: 'Complete address form',
    description: 'Complete address form | a11y id',
  },
  title: {
    defaultMessage: 'Complete your delivery details',
    description: 'Complete address form | Title',
  },
  submitCta: {
    defaultMessage: 'Save & continue',
    description: 'Complete address form | Submit CTA',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['4'],
    gap: theme.spacing['4'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type CompleteAddressFormModalProps = {
  address: CompleteAddressForm;
  isVisible: boolean;
  isAddingAddress: boolean;
  onRequestClose: () => void;
  onSubmit: (address: CompleteAddressForm) => void;
};

export type CompleteAddressForm = {
  id?: string;
  googlePlaceId: string;

  name: string;
  notes: string;
  deliveryPreference: DeliveryPreferenceType;

  street: string;
  secondaryStreet: string;
  city: string;
  country: string;
  state: string;
  zipCode: string;
};

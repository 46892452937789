/* istanbul ignore file */ /* plugin extension code */
/* eslint-disable functional/immutable-data */

import { useCallback } from 'react';

import { useKustomerAvailability } from '../../hooks/useKustomerAvailability';
import { getKustomerKeys, logger } from '../../utils';

/**
 * Loads Kustomer script into HTML body.
 */
export function useLoadKustomer() {
  const isKustomerEnabled = useKustomerAvailability();

  return useCallback(async () => {
    if (!isKustomerEnabled) return;

    return new Promise<void>((resolve) => {
      const kustomerScript = getKustomerScript();

      if (kustomerScript) {
        logger.info('Kustomer Script already present');
        resolve();

        return;
      }

      logger.info('Inserting Kustomer Script');
      document.body.append(prepareKustomerScript(resolve));
    });
  }, [isKustomerEnabled]);
}

/**
 * Adds `role="region"` attribute to Kustomer `iframe` element.
 *
 * Kustomer sets the `role="region"` even when the iframe is empty.
 * This role should be reserved for important regions, which is not the case for an empty iframe.
 * However, we must later add it back when there is content.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/region_role
 */
export function addRegionRoleToKustomerFrame() {
  const kustomeIframe = getKustomerIframe();

  if (!kustomeIframe) return;

  kustomeIframe.setAttribute('role', 'region');
}

/**
 * Kustomer instance present in the window object.
 */
export function getKustomerInstance() {
  return (window as unknown as KustomerWindow).Kustomer;
}

/**
 * Removes `role="region"` attribute from Kustomer `iframe` element.
 *
 * Kustomer sets the `role="region"` even when the iframe is empty.
 * This role should be reserved for important regions, which is not the case for an empty iframe.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/region_role
 */
function removeInvalidAriaAttributes() {
  const kustomeIframe = getKustomerIframe();

  if (!kustomeIframe) return;

  kustomeIframe.removeAttribute('role');

  stopObservingKustomerIframe();
}

function prepareKustomerScript(onLoad: () => void) {
  const { apiKey } = getKustomerKeys();
  const script = document.createElement('script') as KustomerScript;

  script.id = KUSTOMER_SCRIPT_ID;
  script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js';
  script.dataset.kustomerApiKey = apiKey;
  script.addEventListener('load', () => {
    logger.info('Kustomer Script Inserted');
    onLoad();

    script.iframeObserver = new MutationObserver(removeInvalidAriaAttributes);
    startObservingKustomerIframe();
  });

  return script;
}

function startObservingKustomerIframe() {
  const kustomerScript = getKustomerScript();

  if (!kustomerScript) {
    logger.warn('Attempted to observer unexisting Kustomer script.');

    return;
  }

  kustomerScript?.iframeObserver?.observe(document.body, { childList: true });
}

function stopObservingKustomerIframe() {
  const kustomerScript = getKustomerScript();

  if (!kustomerScript?.iframeObserver) return;

  kustomerScript.iframeObserver?.disconnect();
  kustomerScript.iframeObserver = undefined;
}

function getKustomerScript() {
  const kustomerScript = document.querySelector(`#${KUSTOMER_SCRIPT_ID}`);

  if (!kustomerScript) return;

  return kustomerScript as KustomerScript;
}

function getKustomerIframe() {
  return document.querySelector('#kustomer-ui-sdk-iframe');
}

// ─── Constants ──────────────────────────────────────────────────────────────────

const KUSTOMER_SCRIPT_ID = 'kustomer-script';

// ─── Kustomer SDK Types ───────────────────────────────────────────────────────────────────

type KustomerScript = {
  iframeObserver?: MutationObserver;
} & HTMLScriptElement;

type KustomerWindow = Readonly<{ Kustomer: Kustomer }>;
type Kustomer = Readonly<{
  startFinished: boolean;
  start: (options: { brandId: string }, callback?: () => void) => void;
  stop: (callback?: () => void) => void;
  open: (callback?: () => void) => void;
  close: (callback?: () => void) => void;
  addListener: (event: string, callback?: () => void) => void;
  login: (
    options: { jwtToken: string },
    callback?: (
      loginCallbackResponse: KustomerCallbackResponse,
      error: unknown,
    ) => void,
  ) => void;
  logout: (
    callback?: (
      logoutCallbackResponse: KustomerCallbackResponse,
      error: unknown,
    ) => void,
  ) => void;
}>;

type KustomerCallbackResponse = Readonly<{
  identified: boolean;
  customerId: string;
  email?: string;
  externalId?: string;
}>;

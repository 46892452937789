import React, { useCallback } from 'react';
import { ScrollView } from 'react-native';
import { getIosModalPresentationConfig } from '@sg/garnish';
import { type DietaryPropertyKind } from '@sg/graphql-schema';

import { MenuDietaryPreferences } from '@order/components/menu';

import { useDietaryRestrictionsMachine } from '../../../DietaryRestrictions';
import { Modal } from '../../../Modal';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuContentDietaryPreferencesModal = (
  props: MenuContentDietaryPreferencesModalProps,
) => {
  const { isVisible, onClose } = props;

  const {
    sortedRestrictionsNames,
    toggleRestriction,
    restrictions,
    revertRestrictions,
    confirmRestrictions,
  } = useDietaryRestrictionsMachine();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const resetAndClose = useCallback(() => {
    revertRestrictions();
    onClose();
  }, [onClose, revertRestrictions]);

  const confirmAndClose = useCallback(() => {
    confirmRestrictions();
    onClose();
  }, [confirmRestrictions, onClose]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Modal
      visible={isVisible}
      size="medium"
      fitHeight
      onRequestClose={resetAndClose}
      {...getIosModalPresentationConfig()}
    >
      <ScrollView>
        <MenuDietaryPreferences.Container>
          <MenuDietaryPreferences.ModalHeader>
            <MenuDietaryPreferences.ModalCloseButton onPress={resetAndClose} />
          </MenuDietaryPreferences.ModalHeader>

          <MenuDietaryPreferences.Heading />
          <MenuDietaryPreferences.Description />

          <MenuDietaryPreferences.ButtonsContainer>
            {sortedRestrictionsNames.map((restriction) => (
              <MenuDietaryPreferences.Button<DietaryPropertyKind>
                key={restriction}
                restriction={restriction}
                isChecked={restrictions[restriction]}
                toggleRestriction={toggleRestriction}
              />
            ))}
          </MenuDietaryPreferences.ButtonsContainer>

          <MenuDietaryPreferences.Notices />
          <MenuDietaryPreferences.SubmitButton onPress={confirmAndClose} />
        </MenuDietaryPreferences.Container>
      </ScrollView>
    </Modal>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuContentDietaryPreferencesModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

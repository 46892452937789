import React from 'react';
import { StyleSheet } from 'react-native';
import { theme, TYPE_CONFIG } from '@garnish/constants';

import { NumberField } from '../../NumberField';

export const NumberFieldCode = (props: TextFieldCodeProps) => {
  const { style, label = 'Enter code', ...restProps } = props;
  const codeVariationStyles = [styles.codeVariation, style];

  return (
    <NumberField
      label={label}
      clearButtonType="none"
      style={codeVariationStyles}
      {...restProps}
    />
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type TextFieldCodeProps = React.ComponentProps<typeof NumberField>;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  codeVariation: {
    ...TYPE_CONFIG.DISPLAY['32'],
    fontFamily: TYPE_CONFIG.BODY['16'].fontFamily,
    letterSpacing: theme.spacing['4'],
    lineHeight: 40,
  },
});

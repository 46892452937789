import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { theme } from '@garnish/constants';

import { MARKER_SIZE } from '../constants';
import { MinimizingPin } from './MinimizedPin';
import { PinWrapper } from './PinWrapper';

export const PickupPin = (props: PickupPinProps) => {
  const { balloonColor, sgColor, isMinimized, isOpen } = props;

  return (
    <PinWrapper>
      <MinimizingPin isMinimized={isMinimized} isOpen={isOpen}>
        <Svg width={MARKER_SIZE} height={MARKER_SIZE} viewBox="0 0 36 46">
          <Path
            d="M26.9516 3.29337C21.4209 0.0633422 14.5791 0.0633428 9.04841 3.29337C3.59974 6.47552 0.25 12.311 0.25 18.6209V20.1401C0.25 23.0335 1.40001 25.8435 3.061 28.399C4.7228 30.9558 6.90697 33.2747 9.00224 35.191C12.8543 38.7141 16.5137 43.725 17.0216 44.4297C17.07 44.4969 17.131 44.5712 17.2166 44.6351C17.6806 44.9815 18.3194 44.9815 18.7834 44.6351C18.869 44.5712 18.93 44.4969 18.9784 44.4297C19.4863 43.725 23.1457 38.7141 26.9978 35.191C29.093 33.2747 31.2772 30.9558 32.939 28.399C34.6 25.8435 35.75 23.0335 35.75 20.1401V18.6209C35.75 12.311 32.4002 6.47551 26.9516 3.29337Z"
            fill={balloonColor}
            stroke={theme.colors.OATMEAL}
            strokeWidth="1"
          />
        </Svg>
        <View style={styles.pickIconSg}>
          <Svg width={MARKER_SIZE} height={MARKER_SIZE} viewBox="0 0 36 36">
            <Path
              d="M15.7984 12.6717C14.4107 12.6717 13.3841 11.8646 13.3841 10.4131C13.3841 8.91215 14.3895 8.09794 15.7984 8.09794C17.2074 8.09794 18.2127 8.91923 18.2127 10.4131C18.2127 11.8716 17.1932 12.6717 15.7984 12.6717ZM13.5611 15.2559H11.8123C12.2442 17.2667 13.9434 18.2083 16.2303 18.2083C18.5172 18.2083 20.0465 17.1605 20.0465 15.5675C20.0465 14.3143 19.1331 13.7408 17.7454 13.3585C19.1685 12.9549 19.9686 11.8433 19.9686 10.3777C19.9686 9.5706 19.6925 8.86259 19.211 8.2891C19.6146 8.19706 20.0323 8.1829 20.45 8.26078V6.71023C19.5721 6.68191 18.9066 6.9722 18.3968 7.54569C17.6676 7.06424 16.7613 6.78812 15.7843 6.78812C13.3558 6.78812 11.6282 8.27494 11.6282 10.3707C11.6282 13.139 13.9434 13.7833 16.1737 14.3001C17.9224 14.7037 18.2906 15.1073 18.2906 15.6878C18.2906 16.3534 17.7808 16.9056 16.202 16.9056C14.8426 16.9127 13.9151 16.4171 13.5611 15.2559ZM6.78542 16.3534C8.98026 16.3534 10.5379 15.2064 10.5379 13.493C10.5379 9.95293 4.24366 11.4398 4.24366 9.35112C4.24366 8.54398 4.92335 8.08378 6.16237 8.08378C7.55008 8.08378 8.44925 8.69975 8.7891 10.0025H10.4742C10.0848 7.94925 8.51297 6.78812 6.14821 6.78812C3.9817 6.78812 2.53027 7.87137 2.53027 9.50688C2.53027 13.0186 8.80326 11.4256 8.80326 13.6629C8.80326 14.5267 8.09525 15.0506 6.71462 15.0506C5.27736 15.0506 4.42775 14.4134 4.13038 13.0399H2.39575C2.81348 15.2418 4.39234 16.3534 6.78542 16.3534Z"
              fill={sgColor}
            />
          </Svg>
        </View>
      </MinimizingPin>
    </PinWrapper>
  );
};

export const PickupPinOpenSelected = (props: PickupPinProps) => (
  <PickupPin
    {...props}
    balloonColor={theme.colors.GREEN_3}
    sgColor={theme.colors.LIME}
  />
);

export const PickupPinOpenUnselected = (props: PickupPinProps) => (
  <PickupPin
    {...props}
    balloonColor={theme.colors.GREEN_2}
    sgColor={theme.colors.LIME}
  />
);

export const PickupPinClosedSelected = (props: PickupPinProps) => (
  <PickupPin
    {...props}
    balloonColor={theme.colors.NEUTRAL_4}
    sgColor={theme.colors.OPACITY.DARK_KALE.DARK}
  />
);

export const PickupPinClosedUnselected = (props: PickupPinProps) => (
  <PickupPin
    {...props}
    balloonColor={theme.colors.NEUTRAL_4}
    sgColor={theme.colors.OPACITY.DARK_KALE.DARK}
  />
);

type PickupPinProps = Readonly<{
  balloonColor?: string;
  sgColor?: string;
  isMinimized?: boolean;
  isOpen?: boolean;
}>;

const styles = StyleSheet.create({
  pickIconSg: {
    position: 'absolute',
    top: 4,
    left: 8,
  },
});

import type { ComponentProps } from 'react';
import React, { useMemo } from 'react';
import type { ViewProps } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import type { ContentfulImageTransformOptions } from '@sg/garnish';
import { FallbackImage, type Image } from '@sg/garnish';

import type { SweetpassHomeActionCardLayoutVariantProps } from './SweetpassHomeActionCardLayoutVariantProps';
import type { SweetpassHomeActionCardTheme } from './SweetpassHomeActionCardTheme';

export const SweetpassHomeActionCardImage = (
  props: SweetpassHomeActionCardImageProps,
) => {
  const {
    testID,
    style,
    width,
    height,
    image: { url, description },
    defaultImage,
    theme: { backgroundColor, foregroundColor },
  } = props;

  const contentfulOptions: Partial<ContentfulImageTransformOptions> = useMemo(
    () => ({
      w: width,
      h: height,
      fit: 'fill',
      fm: 'webp',
      q: 90,
    }),
    [width, height],
  );

  const imageStyle = useStyle(
    () => ({
      backgroundColor,
      borderColor: foregroundColor,
    }),
    [backgroundColor, foregroundColor],
  );

  return (
    <FallbackImage
      testID={testID}
      style={[style, imageStyle]}
      baseUrl={url}
      defaultImage={defaultImage}
      contentfulOptions={contentfulOptions}
      aria-label={description}
      resizeMode={url ? 'cover' : 'contain'}
    />
  );
};

type SweetpassHomeActionCardImageProps = Readonly<{
  theme: SweetpassHomeActionCardTheme;
  width: number;
  height: number;
}> &
  Pick<SweetpassHomeActionCardLayoutVariantProps['content'], 'image'> &
  Pick<ComponentProps<typeof FallbackImage>, 'defaultImage'> &
  Pick<ViewProps, 'testID'> &
  Pick<ComponentProps<typeof Image>, 'style'>;

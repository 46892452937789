import React from 'react';
import { DisplayText, useResponsive } from '@sg/garnish';

import { useCustomerNameQuery } from '../../GraphQL/AccountMenu.generated';

export const AccountCustomerName = () => {
  const { match } = useResponsive();
  const [response] = useCustomerNameQuery();
  const customerName = response.data?.currentCustomer?.firstName;

  // Both XS and LG should have 32px, but MD should have 24px.
  const nameSize = match([1, 4, 3] as const);

  return (
    <DisplayText testID="account.menu-customer-name" size={nameSize}>
      {customerName}
    </DisplayText>
  );
};

import type { CombinedError } from '@urql/core';
import type { AddressType, PinData, Region } from '@sg/garnish';

import { useTrackEventEffect } from '@order/Telemetry';

import type { ReorderRestaurantQuery } from '../../GraphQL/ReorderConfirmLocation.generated';
import { useReorderRestaurantQuery } from '../../GraphQL/ReorderConfirmLocation.generated';

export const useReorderConfirmLocationData = (
  restaurantId: string,
  orderType: AddressType,
): Readonly<{
  error?: CombinedError;
  fetching: boolean;
  restaurant?: ReorderRestaurantQuery['restaurant'];
  region: Region;
  pins: readonly PinData[];
}> => {
  useTrackEventEffect({
    name: 'reorder.missing_restaurant_id',
    skip: Boolean(restaurantId),
  });

  const [{ data, fetching, error }] = useReorderRestaurantQuery({
    variables: { id: restaurantId },
    pause: !restaurantId,
  });

  const restaurant = data?.restaurant;

  if (fetching || error || !data || !restaurant) {
    return {
      error,
      fetching,
      restaurant: undefined,
      region: { lat: 0, lng: 0 },
      pins: [],
    };
  }

  const region: Region = {
    lat: restaurant.latitude,
    lng: restaurant.longitude,
  };

  const pins: readonly PinData[] = [
    {
      id: restaurant.id,
      lat: restaurant.latitude,
      lng: restaurant.longitude,
      name: restaurant.name,
      isDeliveryAddress: orderType === 'delivery',
      acceptingOrders: restaurant.isAcceptingOrders,
      isOutpost: restaurant.isOutpost,
    },
  ];

  return {
    error,
    fetching,
    restaurant,
    region,
    pins,
  };
};

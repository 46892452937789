/* eslint-disable @typescript-eslint/no-shadow */

import React, { useCallback, useRef } from 'react';
import type { ViewStyle } from 'react-native';
import { Platform, StyleSheet, View } from 'react-native';
import { PanGestureHandler } from 'react-native-gesture-handler';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import { COLORS, RADIUS } from '@garnish/constants';

import { useBrowserEventListener } from '../../../../hooks';
import { List } from '../../../List';
import {
  PICKER_OPTION_HEIGHT,
  PICKER_OPTIONS_WRAPPER_HEIGHT,
  PICKER_OPTIONS_WRAPPER_TOP_OFFSET,
} from '../Picker.constants';
import { usePickerGesturesEventsHandler } from '../Picker.hooks';
import type {
  PickerControlProps,
  PickerSelectionBorderRadiusVariation,
} from '../Picker.types';
import { PickerOption } from './PickerOption';

export const PickerControl = (props: PickerControlProps) => {
  const {
    value,
    options = [],
    selectionBorderRadius = 'rounded',
    accessibilityLabel,
    accessibilityDescribedBy,
    onChange,
  } = props;

  const optionsWrapperRef = useRef<View>(null);
  const activeOptionIndex = options.findIndex(
    (option) => option.value === value,
  );

  // ─── HELPERS ────────────────────────────────────────────────────────

  const { swipedDistance, animatedGestureHandler, selectOptionByIndex } =
    usePickerGesturesEventsHandler({
      options,
      optionsWrapperRef,
      activeOptionIndex,
      onChange,
    });

  const selectNextOption = useCallback(() => {
    selectOptionByIndex(activeOptionIndex + 1);
  }, [activeOptionIndex, selectOptionByIndex]);

  const selectPrevOption = useCallback(() => {
    selectOptionByIndex(activeOptionIndex - 1);
  }, [activeOptionIndex, selectOptionByIndex]);

  const checkIfElementInsideWrapper = useCallback(
    (element: EventTarget | HTMLElement | Node | null) => {
      const optionsWrapperAsNode = optionsWrapperRef.current as unknown as Node;

      if (!element || !optionsWrapperAsNode) return false;

      const elementAsNode = element as Node;

      return optionsWrapperAsNode.contains(elementAsNode);
    },
    [],
  );

  const checkIfFocusInsideWrapper = useCallback(() => {
    if (Platform.OS !== 'web') return false;

    const focusedElement = document.activeElement;

    return checkIfElementInsideWrapper(focusedElement);
  }, [checkIfElementInsideWrapper]);

  const selectOptionOnKeyDown = useCallback(
    (event: Event) => {
      if (!checkIfElementInsideWrapper(event.target)) return;

      const { key } = event as KeyboardEvent;

      if (key === 'ArrowUp') selectPrevOption();

      if (key === 'ArrowDown') selectNextOption();
    },
    [checkIfElementInsideWrapper, selectNextOption, selectPrevOption],
  );

  // ─── STYLES ─────────────────────────────────────────────────────────

  const selectionStyle = [
    styles.optionsSelection,
    borderRadiusVariations[selectionBorderRadius],
  ];

  const animatedStyle = useAnimatedStyle(() => {
    return { transform: [{ translateY: swipedDistance.value }] };
  });

  // ────────────────────────────────────────────────────────────────────

  useBrowserEventListener(window, 'keydown', selectOptionOnKeyDown);
  const isFocusInsideWrapper = checkIfFocusInsideWrapper();

  // ────────────────────────────────────────────────────────────────────

  return (
    <View ref={optionsWrapperRef} style={styles.optionsWrapper}>
      <View style={selectionStyle} />

      <PanGestureHandler onGestureEvent={animatedGestureHandler}>
        <Animated.View style={animatedStyle}>
          <List style={styles.optionsList}>
            {options.map(({ value, label }, index) => (
              <PickerOption
                key={value}
                index={index}
                autofocus={isFocusInsideWrapper}
                activeOptionIndex={activeOptionIndex}
                label={label}
                accessibilityLabel={accessibilityLabel}
                accessibilityDescribedBy={accessibilityDescribedBy}
                swipedDistance={swipedDistance}
                selectOptionByIndex={selectOptionByIndex}
              />
            ))}
          </List>
        </Animated.View>
      </PanGestureHandler>
    </View>
  );
};

// ─── Styles ───────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  optionsWrapper: {
    position: 'relative',
    height: PICKER_OPTIONS_WRAPPER_HEIGHT,
    overflow: 'hidden',
  },
  optionsSelection: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    height: PICKER_OPTION_HEIGHT,
    transform: [{ translateY: -PICKER_OPTION_HEIGHT / 2 }],
    backgroundColor: COLORS.OPACITY.KALE.LIGHTEST,
  },
  optionsSelectionRightBorderRadius: {
    borderTopRightRadius: RADIUS.large,
    borderBottomRightRadius: RADIUS.large,
  },
  optionsSelectionLeftBorderRadius: {
    borderTopLeftRadius: RADIUS.large,
    borderBottomLeftRadius: RADIUS.large,
  },
  optionsSelectionRoundedBorderRadius: {
    borderRadius: RADIUS.large,
  },
  optionsList: {
    position: 'relative',
    zIndex: 1,
    paddingTop: PICKER_OPTIONS_WRAPPER_TOP_OFFSET,
  },
});

const borderRadiusVariations: Record<
  PickerSelectionBorderRadiusVariation,
  ViewStyle
> = {
  right: styles.optionsSelectionRightBorderRadius,
  left: styles.optionsSelectionLeftBorderRadius,
  rounded: styles.optionsSelectionRoundedBorderRadius,
};

import { decode } from 'base-64';

import { getGiftCardRedemptionDataParams } from '../get-gift-card-redemption-data-params';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns gift card number and registration code based on the provided
 * gift card redemption hash.
 */
export function decodeGiftCardRedemptionHash(
  params: DecodeGiftCardRedemptionHashParams,
): GiftCardRedemptionDecodedData {
  const { redemptionHash, onSuccess, onError } = params;

  try {
    const normalizedRedemptionHash = decodeURIComponent(redemptionHash);
    const decodedData = decode(normalizedRedemptionHash);
    const decodedDataParams = getGiftCardRedemptionDataParams(decodedData);

    const { giftCardCode, giftCardRegistrationCode } = decodedDataParams;

    const hasValidGiftCardCode =
      typeof giftCardCode === 'string' && giftCardCode.length > 0;

    // ─── Invalid Data ────────────────────────────

    if (!hasValidGiftCardCode) {
      onError('Invalid redemption data');

      return null;
    }

    // ─── Valid Data ──────────────────────────────

    onSuccess();

    return {
      giftCardCode,
      giftCardRegistrationCode,
    };
  } catch (error: unknown) {
    onError(error);

    return null;
  }
}

// ─── Types ───────────────────────────────────────────────────────────────────

type DecodeGiftCardRedemptionHashParams = {
  redemptionHash: string;
  onSuccess: () => void;
  onError: (error: unknown) => void;
};

type GiftCardRedemptionDecodedData = {
  giftCardCode: string;
  giftCardRegistrationCode?: string;
} | null;

import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';

import {
  type StartReorderFromBagParams,
  useNavigateToReorderWithinModalStack,
} from './useNavigateToReorderWithinModalStack';

/**
 * Hook to change the location or channel if logged in and navigate to auth otherwise.
 */
export const useChangeChannelOrLocation = () => {
  const isLoggedIn = useIsLoggedIn();
  const navigation = useNavigation();
  const startReorder = useNavigateToReorderWithinModalStack();
  const { formatMessage } = useIntl();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  const changeChannelOrLocation = useCallback(
    (params: StartReorderFromBagParams = {}) => {
      if (!isLoggedIn) {
        const text = formatMessage(messages.reorderLoggedOut);

        addNoticeBanner({ text, palette: 'neutral' });

        navigation.navigate('Auth', {
          screen: 'Login',
          params: { redirect: 'locations' },
        });

        return;
      }

      startReorder(params);
    },
    [isLoggedIn, navigation, startReorder, formatMessage, addNoticeBanner],
  );

  return { changeChannelOrLocation };
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  reorderLoggedOut: {
    defaultMessage: 'Please sign in to update your order location.',
    description: 'Bag | Header | Reorder Logged Out',
  },
});

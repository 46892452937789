import { useLocalizationContext } from '@order/Localization';

export const useBraintreeHostedFieldsErrors = () => {
  const { t } = useLocalizationContext();

  return {
    numberError: t('credit-card-form.error.inline.number'),
    expirationDateError: t('credit-card-form.error.inline.expiration'),
    cvvError: t('credit-card-form.error.inline.cvv'),
    postalCodeError: t('credit-card-form.error.inline.zipCode'),
  };
};

import { useSelector } from '@xstate/react';

import { useGlobalAppState } from '../../GlobalAppState';

export const useIsLoggingIn = () => {
  const { authMachineRef } = useGlobalAppState();

  const isLoggingIn = useSelector(authMachineRef, (state) => {
    const { matches } = state;

    const isFetchingSession = matches('idle');
    const isFetchingCustomer =
      !matches('session.loggedIn') && matches('fetching.customer');

    return isFetchingSession || isFetchingCustomer;
  });

  return Boolean(isLoggingIn);
};

export const useIsLoggedIn = () => {
  const { authMachineRef } = useGlobalAppState();

  const isLoggedIn = useSelector(authMachineRef, (state) => {
    const { matches } = state;

    return matches('session.loggedIn');
  });

  return Boolean(isLoggedIn);
};

import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuOnlineOrderingUnavailableDialog = (
  props: PropsWithChildren,
) => {
  const { match } = useResponsive();

  const containerStyles = [
    styles.container,
    match([styles.containerXS, styles.containerSM]),
  ];

  return <View style={containerStyles}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    rowGap: theme.spacing['6'],
    paddingVertical: theme.spacing['10'],
    backgroundColor: theme.colors.CREAM,
  },
  containerXS: {
    width: '100%',
    paddingHorizontal: theme.spacing['4'],
    borderTopStartRadius: theme.radius.large,
    borderTopEndRadius: theme.radius.large,
  },
  containerSM: {
    width: 420,
    maxWidth: '100%',
    paddingHorizontal: theme.spacing['6'],
    borderRadius: theme.radius.xlarge,
  },
});

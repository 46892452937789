/* eslint-disable functional/immutable-data */

import { useCallback, useEffect, useRef } from 'react';
import { reloadApp } from '@sg/garnish';

import { useSendLogoutToAuthMachine } from '@order/AuthMachine';
import { removeTokens } from '@order/AzureAuth';
import { useCustomer } from '@order/Customer';
import { useUrqlContext } from '@order/Urql';

import { getAppNavigationContainerRef } from '../../navigation';
import { useLogoutCustomerMutation } from './graphql/LogoutCustomer.generated';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A simple hook that registers a log-out handler to the Urql context. It may
 * then be used to handle log-out when the user's session is invalidated.
 */
export const useRegisterUrqlLogOutHandler = () => {
  const { registerUrqlLogOutHandler } = useUrqlContext();

  // ─────────────────────────────────────────────────────────────────────

  const { customer } = useCustomer();
  const [, logOut] = useLogoutCustomerMutation();
  const sendLogoutAction = useSendLogoutToAuthMachine();
  const navigationContainerRef = getAppNavigationContainerRef();

  // ─── Refs ────────────────────────────────────────────────────────────

  const customerIdRef = useRef(customer.id);

  // NOTE: To prevent extra callback recreations.
  customerIdRef.current = customer.id;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const logOutCustomer = useCallback(async () => {
    const customerId = customerIdRef.current;

    if (!customerId) return;

    const { data } = await logOut({ input: { customerId } });
    const hasLoggedOut = data?.logout.__typename === 'LogoutSuccess';

    return hasLoggedOut ? data.logout.csrf : undefined;
  }, [logOut]);

  const navigateToSignedOutAccountScreen = useCallback(() => {
    navigationContainerRef.current?.navigate('MainTabs', {
      screen: 'AccountTab',
      params: { screen: 'AccountMenu' },
    });
  }, [navigationContainerRef]);

  const handlePostLogOutState = useCallback(
    async (csrfToken: string) => {
      await removeTokens();
      sendLogoutAction(csrfToken);
      navigateToSignedOutAccountScreen();
    },
    [navigateToSignedOutAccountScreen, sendLogoutAction],
  );

  // ─────────────────────────────────────────────────────────────────────

  const logOutHandler = useCallback(async () => {
    const csrfToken = await logOutCustomer();

    // ─── Fallback State ──────────────────────────────────────────────────

    // NOTE: If the log-out mutation fails, we manually refresh the app
    //       as a fallback.
    //
    //       That will re-fetch the session.

    if (!csrfToken) {
      await reloadApp();

      return;
    }

    await handlePostLogOutState(csrfToken);
  }, [handlePostLogOutState, logOutCustomer]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    registerUrqlLogOutHandler(logOutHandler);
  }, [logOutHandler, registerUrqlLogOutHandler]);
};

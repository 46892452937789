import Progress0 from './Progress-0.svg';
import Progress1 from './Progress-1.svg';
import Progress2 from './Progress-2.svg';
import Progress3 from './Progress-3.svg';
import Progress4 from './Progress-4.svg';

export const ProgressIcons = {
  Progress0,
  Progress1,
  Progress2,
  Progress3,
  Progress4,
};

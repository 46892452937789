import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useBraze } from '@order/Braze';
import { SWEETPASS_BRAZE_CUSTOM_EVENTS } from '@order/constants';
import type { TierName } from '@order/graphql';
import {
  useCancelSubscriptionAtCycleEndMutation,
  useCancelSubscriptionMutation,
} from '@order/graphql';
import { useSweetpassTier } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import type { AccountSweetpassCancellationSection } from '../useSweetpassAccountContentfulData';
import {
  ACCOUNT_SWEETPASS_CANCELLATION_SECTION,
  useSweetpassAccountContentfulData,
} from '../useSweetpassAccountContentfulData';

export const useCancelSweetpassMembership = (
  props: CancelSweetpassMembershipProps,
) => {
  const {
    subscriptionId,
    nextBillingDate,
    tierName,
    isFreeTrial,
    isPastDue,
    refetchMembership,
  } = props;

  const { content } = useSweetpassAccountContentfulData();
  const cancellationSection = content?.find?.(
    (section) => section.name === ACCOUNT_SWEETPASS_CANCELLATION_SECTION,
  ) as AccountSweetpassCancellationSection;
  const {
    cancellationDataGenericErrorMessage,
    cancellationDataGenericSuccessMessage,
  } = cancellationSection ?? {};

  const { formatDate } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { formattedSweetpassTierName } = useSweetpassTier();
  const { logCustomEvent } = useBraze();

  const cancelSubscription = useCancelSubscription({ isFreeTrial, isPastDue });

  const logCancellationCustomBrazeEvent = useCallback(() => {
    if (tierName === 'SWEETPASS') {
      logCustomEvent(
        SWEETPASS_BRAZE_CUSTOM_EVENTS.UserCancelledSweetpassSubscription,
      );
    }

    if (tierName === 'SWEETPASS_PLUS') {
      logCustomEvent(
        SWEETPASS_BRAZE_CUSTOM_EVENTS.UserCancelledSweetpassPlusSubscription,
      );
    }
  }, [logCustomEvent, tierName]);

  const cancelSubscriptionAtCycleEnd = useCallback(async () => {
    if (!subscriptionId) return;

    const hasCanceled = await cancelSubscription(subscriptionId);

    if (!hasCanceled) {
      addNoticeBanner({
        text: cancellationDataGenericErrorMessage.replaceAll(
          '{tierName}',
          formattedSweetpassTierName,
        ),
        palette: 'caution',
      });

      return;
    }

    addNoticeBanner({
      text: cancellationDataGenericSuccessMessage
        .replaceAll('{tierName}', formattedSweetpassTierName)
        .replaceAll(
          '{nextBillingDate}',
          nextBillingDate ? formatDate(nextBillingDate) : '',
        ),
      palette: 'success',
    });

    logCancellationCustomBrazeEvent();

    refetchMembership();
  }, [
    subscriptionId,
    nextBillingDate,
    formattedSweetpassTierName,
    cancellationDataGenericSuccessMessage,
    cancellationDataGenericErrorMessage,
    cancelSubscription,
    addNoticeBanner,
    formatDate,
    logCancellationCustomBrazeEvent,
    refetchMembership,
  ]);

  return { cancelSubscriptionAtCycleEnd };
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function useCancelSubscription(
  props: Pick<CancelSweetpassMembershipProps, 'isFreeTrial' | 'isPastDue'>,
) {
  const { isFreeTrial, isPastDue } = props;

  const [, cancelImmediately] = useCancelSubscriptionMutation();
  const [, cancelAtCycleEnd] = useCancelSubscriptionAtCycleEndMutation();

  return useCallback(
    async (subscriptionId: string) => {
      if (isFreeTrial || isPastDue) {
        const response = await cancelImmediately({ subscriptionId });
        const result = response?.data?.cancelSubscription?.__typename;

        return result === 'CancelSubscriptionSuccessResponse';
      }

      const response = await cancelAtCycleEnd({ subscriptionId });
      const result = response?.data?.cancelSubscriptionAtCycleEnd?.__typename;

      return result === 'CancelSubscriptionAtCycleEndSuccessResponse';
    },
    [isFreeTrial, isPastDue, cancelImmediately, cancelAtCycleEnd],
  );
}

// ─── Types ───────────────────────────────────────────────────────────────────

type CancelSweetpassMembershipProps = Readonly<{
  tierName: TierName | undefined;
  subscriptionId?: string;
  nextBillingDate?: string;
  isFreeTrial?: boolean;
  isPastDue?: boolean;
  refetchMembership: () => void;
}>;

import React, { type ReactNode } from 'react';
import { Tabs } from '@sg/garnish';

import { ORDER_AGAIN_TABS } from '../../OrderAgain.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainOrderTab = (props: OrderAgainOrdersTabProps) => {
  const { children, activeTabId } = props;

  return (
    <Tabs.Panel
      tabId={ORDER_AGAIN_TABS.ORDERS.ID}
      panelId={ORDER_AGAIN_TABS.ORDERS.PANEL_ID}
      isActive={activeTabId === ORDER_AGAIN_TABS.ORDERS.ID}
    >
      {children}
    </Tabs.Panel>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderAgainOrdersTabProps = {
  activeTabId: string;
  children: ReactNode;
};

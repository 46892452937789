import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, ButtonGroup, theme, useResponsive } from '@sg/garnish';

import { DeliveryPreferenceType } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

export const DeliveryPreferencesFormTypeSelector = (
  props: DeliveryPreferencesFormTypeSelectorProps,
) => {
  const { localType, setLocalType } = props;

  const { t } = useLocalizationContext();
  const { match } = useResponsive();
  const itemsHorizontalPadding = match(['3', '4'] as const);

  const deliveryPreferences = useMemo(
    () => [
      {
        value: DeliveryPreferenceType.LeaveAtDoor,
        label: t('addresses.delivery-preference.leave-at-door'),
        accessibilityLabel: t(
          'addresses.delivery-preference.leave-at-door.a11y',
        ),
      },
      {
        value: DeliveryPreferenceType.Door,
        label: t('addresses.delivery-preference.meet-at-door'),
        accessibilityLabel: t(
          'addresses.delivery-preference.meet-at-door.a11y',
        ),
      },
      {
        value: DeliveryPreferenceType.Curbside,
        label: t('addresses.delivery-preference.meet-outside'),
        accessibilityLabel: t(
          'addresses.delivery-preference.meet-outside.a11y',
        ),
      },
    ],
    [t],
  );

  return (
    <View>
      <BodyText sizeMatch={['12']} style={styles.sectionLabel}>
        {t('delivery.preferences.dropoff-label')}
      </BodyText>

      <ButtonGroup
        value={localType}
        items={deliveryPreferences}
        itemsHorizontalPadding={itemsHorizontalPadding}
        onChange={setLocalType}
      />
    </View>
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────

type DeliveryPreferencesFormTypeSelectorProps = Readonly<{
  localType: string;
  setLocalType: (type: string) => void;
}>;

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  sectionLabel: {
    marginTop: theme.spacing['10'],
    marginBottom: theme.spacing['2'],
  },
});

/* eslint-disable @typescript-eslint/no-shadow */

import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { useUniqueNativeID } from '../../../hooks';
import { usePickerState } from '../Picker/Picker.hooks';
import type { PickersGroupProps } from '../Picker/Picker.types';
import { PickerContainer, PickerControl } from '../Picker/subcomponents';
import {
  extractAllGroupsOptions,
  getOptionGroupsLabels,
  getSelectedValueParentGroup,
} from './PickersGroup.utils';

// ────────────────────────────────────────────────────────────────────────────────

export const PickersGroup = (props: PickersGroupProps) => {
  const {
    label,
    modalTitle,
    submitBtnLabel,
    value,
    optionsGroups,
    variation,
    accessibilityLabel,
    accessibilityLabelLeft,
    accessibilityLabelRight,
    triggerIconLeft,
    triggerIconRight,
    boldTriggerText,
    onSubmit,
  } = props;

  const [valueParentLabel = ''] = getSelectedValueParentGroup(
    optionsGroups,
    value,
  );

  const allAvailableOptions = useMemo(
    () => extractAllGroupsOptions(optionsGroups),
    [optionsGroups],
  );

  const labelNativeID = useUniqueNativeID('picker-label');

  // ────────────────────────────────────────────────────────────────────

  const {
    selectedOptionValue,
    selectedOptionLabel,
    setSelectedOptionValue,
    handleSubmit,
    resetSelectedOptionValue,
  } = usePickerState({ value, options: allAvailableOptions, onSubmit });

  const groupsLabels = useMemo(
    () => getOptionGroupsLabels(optionsGroups),
    [optionsGroups],
  );
  const [selectedGroupLabel = ''] = useMemo(
    () => getSelectedValueParentGroup(optionsGroups, selectedOptionValue),
    [optionsGroups, selectedOptionValue],
  );
  const selectedGroupOptions = optionsGroups[selectedGroupLabel];

  // ─── Helpers ────────────────────────────────────────────────────────

  const handleGroupChange = useCallback(
    (label: string | number) => {
      const groupFirstOption = optionsGroups?.[label]?.[0] ?? { value: '' };

      setSelectedOptionValue(groupFirstOption.value);
    },
    [optionsGroups, setSelectedOptionValue],
  );

  // ────────────────────────────────────────────────────────────────────

  return (
    <PickerContainer
      label={label}
      modalTitle={modalTitle}
      labelNativeID={labelNativeID}
      accessibilityLabel={accessibilityLabel}
      selectedOptionLabel={
        selectedOptionLabel
          ? `${valueParentLabel}, ${selectedOptionLabel}`
          : valueParentLabel
      }
      submitBtnLabel={submitBtnLabel}
      variation={variation}
      triggerIconLeft={triggerIconLeft}
      triggerIconRight={triggerIconRight}
      boldTriggerText={boldTriggerText}
      onSubmitBtnPress={handleSubmit}
      onDismiss={resetSelectedOptionValue}
    >
      <View style={styles.wrapper}>
        <View style={styles.pickerControlWrapper}>
          <PickerControl
            value={selectedGroupLabel}
            options={groupsLabels}
            selectionBorderRadius="left"
            accessibilityLabel={accessibilityLabelLeft ?? accessibilityLabel}
            accessibilityDescribedBy={label ? labelNativeID : undefined}
            onChange={handleGroupChange}
          />
        </View>

        <View style={styles.pickerControlWrapper}>
          <PickerControl
            key={selectedGroupLabel}
            value={selectedOptionValue}
            options={selectedGroupOptions}
            selectionBorderRadius="right"
            accessibilityLabel={accessibilityLabelRight ?? accessibilityLabel}
            accessibilityDescribedBy={label ? labelNativeID : undefined}
            onChange={setSelectedOptionValue}
          />
        </View>
      </View>
    </PickerContainer>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
  },
  pickerControlWrapper: {
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 0,
  },
});

import { useFeatureFlag } from '@order/LaunchDarkly';

import { useCart } from '../useCart';
import {
  checkIfTippingEnabledForAllStores,
  getListOfLocationsWithEnabledTipping,
} from './useLocationTippingEnabled.utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A small hook that checks the corresponding list of location IDs from a remote
 * source to determine whether restaurant tipping is enabled for the current
 * location.
 *
 * The acceptable values are as follows:
 *
 * - `[]`                     No locations,
 * - `['all']`                All locations,
 * - `['33', '50', '62']`     List of locations:
 */
export const useLocationTippingEnabled = (): boolean => {
  const locationsWithEnabledTipping = useFeatureFlag(
    'CELS-1149-short-target-tipping-by-location-enabled',
  );
  const { cart, isCartPickup } = useCart();

  // ─────────────────────────────────────────────────────────────────────

  // NOTE: Location tipping is only available for pickup locations
  if (!isCartPickup) return false;

  // ─────────────────────────────────────────────────────────────────────

  const cartLocationId = cart?.restaurant.id;

  const listOfLocationsWithEnabledTipping =
    getListOfLocationsWithEnabledTipping(locationsWithEnabledTipping);
  const isTippingEnabledForAllLocations = checkIfTippingEnabledForAllStores(
    listOfLocationsWithEnabledTipping,
  );

  // ─────────────────────────────────────────────────────────────────────

  if (isTippingEnabledForAllLocations) return true;

  if (!cartLocationId) return false;

  return listOfLocationsWithEnabledTipping.includes(cartLocationId);
};

import { addAction } from './addAction';
import { addFeatureFlagEvaluation } from './addFeatureFlagEvaluation';
import { init } from './init';
import { logError } from './logError';
import { logger } from './logger';
import { setGlobalAttribute } from './setGlobalAttribute';
import { setUser } from './setUser';
import { stopSession } from './stopSession';
import { trackViews } from './trackViews';
export * from './helpers';
export { SessionReplay, SessionReplayPrivacy } from './nativeSessionReplay';

export const datadog = {
  init,
  logError,
  logger,
  addAction,
  setGlobalAttribute,
  setUser,
  stopSession,
  addFeatureFlagEvaluation,
  trackViews,
};

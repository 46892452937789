/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ChallengeCelebrationModalQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ChallengeCelebrationModalQuery = {
  readonly __typename?: 'Query';
  readonly challengeCelebrationV2: {
    readonly __typename?: 'ChallengeCelebrationV2';
    readonly title: string;
    readonly celebrationCopy: string;
    readonly expirationDate: string | null;
    readonly challengesIds: ReadonlyArray<string>;
  } | null;
};

export const ChallengeCelebrationModalDocument = gql`
  query ChallengeCelebrationModal {
    challengeCelebrationV2 {
      title
      celebrationCopy
      expirationDate
      challengesIds
    }
  }
`;

export function useChallengeCelebrationModalQuery(
  options?: Omit<
    Urql.UseQueryArgs<ChallengeCelebrationModalQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ChallengeCelebrationModalQuery,
    ChallengeCelebrationModalQueryVariables
  >({ query: ChallengeCelebrationModalDocument, ...options });
}

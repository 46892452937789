import {
  ScanCardBalance,
  ScanCardContainer,
  ScanCardCta,
  ScanCardIllustration,
  ScanCardQrCode,
  ScanCardText,
  ScanCardTitle,
} from './components';

export const ScanCard = {
  Container: ScanCardContainer,
  CardBalance: ScanCardBalance,
  Cta: ScanCardCta,
  Illustration: ScanCardIllustration,
  Text: ScanCardText,
  Title: ScanCardTitle,
  QrCode: ScanCardQrCode,
} as const;

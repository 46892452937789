import React from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import Svg, { Path } from 'react-native-svg';
import { theme } from '@garnish/constants';

export const LoyaltyIconSgReward = (props: LoyaltyIconSgRewardProps) => {
  const {
    palette,
    scale = 1,
    fillColor = theme.colors.BLACK,
    strokeColor = theme.colors.BLACK,
    textColor = theme.colors.OATMEAL,
    strokeWidth = '2',
    style,
  } = props;

  const fill = palette ? palettes[palette].fillColor : fillColor;
  const stroke = palette ? palettes[palette].strokeColor : strokeColor;
  const text = palette ? palettes[palette].textColor : textColor;
  const scaleStyle = useStyle(() => ({ transform: [{ scale }] }), [scale]);

  return (
    <View style={[scaleStyle, style]}>
      <Svg width="32" height="32" viewBox="-1 -1 34 34">
        <Path
          d="M9.60302 1.30809C10.6589 0.207201 12.2178 -0.242896 13.6926 0.127345L25.4381 3.07598C26.9129 3.44622 28.0795 4.58055 28.4985 6.05168L31.8351 17.7679C32.2541 19.239 31.8617 20.8234 30.8059 21.9243L22.397 30.6919C21.3411 31.7928 19.7822 32.2429 18.3074 31.8727L6.56188 28.924C5.08707 28.5538 3.92047 27.4194 3.50151 25.9483L0.164901 14.2321C-0.254055 12.761 0.138286 11.1766 1.19414 10.0757L9.60302 1.30809Z"
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      </Svg>

      <View style={styles.text}>
        <Svg width="19" height="12" viewBox="0 0 19 12" fill="none">
          <Path
            d="M13.5452 6.31048C12.1625 6.26219 11.1677 5.42226 11.2182 3.97608C11.2704 2.48052 12.3005 1.70423 13.7043 1.75325C15.1082 1.80228 16.0813 2.65558 16.0293 4.14409C15.9786 5.59732 14.9349 6.35901 13.5452 6.31048ZM11.226 8.80753L9.48354 8.74668C9.84391 10.7652 11.5042 11.7626 13.7828 11.8421C16.0615 11.9217 17.6217 10.9309 17.6771 9.34359C17.7207 8.09494 16.8306 7.49174 15.4613 7.06251C16.8933 6.70992 17.7291 5.6302 17.7801 4.16991C17.8082 3.36569 17.5577 2.65063 17.0979 2.06247C17.5032 1.9848 17.92 1.98523 18.3335 2.07736L18.3874 0.532419C17.5136 0.473654 16.8404 0.739732 16.3125 1.29341C15.6027 0.78833 14.7093 0.481671 13.7358 0.447674C11.3161 0.363176 9.54303 1.78452 9.47011 3.87266C9.37379 6.63097 11.6582 7.35349 13.8624 7.94607C15.5908 8.40903 15.9436 8.82395 15.9234 9.40242C15.9003 10.0655 15.3731 10.5981 13.8 10.5431C12.4452 10.5029 11.5383 9.97679 11.226 8.80753ZM4.43665 9.66523C6.62355 9.74159 8.21545 8.65296 8.27507 6.94576C8.39825 3.4185 2.07504 4.68095 2.14771 2.59986C2.1758 1.79565 2.86904 1.36075 4.10359 1.40386C5.48627 1.45215 6.36077 2.09718 6.65405 3.40703L8.33303 3.46567C8.01647 1.4063 6.49077 0.194673 4.13456 0.112393C1.97588 0.0370099 0.492006 1.06585 0.4351 2.69545C0.31291 6.19449 6.61865 4.82549 6.5408 7.05472C6.51075 7.91537 5.78706 8.41277 4.41143 8.36473C2.97936 8.31472 2.15499 7.65025 1.90649 6.27133L0.178135 6.21097C0.517738 8.41946 2.05222 9.58196 4.43665 9.66523Z"
            fill={text}
          />
        </Svg>
      </View>
    </View>
  );
};

// ─── Palettes ───────────────────────────────────────────────────────────────────

const palettes: Record<LoyaltyIconSgRewardPalette, VectorProps> = {
  'black-fill': {
    fillColor: theme.colors.BLACK,
    strokeColor: theme.colors.BLACK,
    textColor: theme.colors.OATMEAL,
  },
  'black-outline': {
    fillColor: theme.colors.OPACITY.TRANSPARENT,
    strokeColor: theme.colors.BLACK,
    textColor: theme.colors.BLACK,
  },
  'kale-fill': {
    fillColor: theme.colors.KALE,
    strokeColor: theme.colors.KALE,
    textColor: theme.colors.OATMEAL,
  },
  'kale-outline': {
    fillColor: theme.colors.OPACITY.TRANSPARENT,
    strokeColor: theme.colors.KALE,
    textColor: theme.colors.KALE,
  },
  'spinach-fill': {
    fillColor: theme.colors.SPINACH,
    strokeColor: theme.colors.SPINACH,
    textColor: theme.colors.OATMEAL,
  },
  'spinach-outline': {
    fillColor: theme.colors.OPACITY.TRANSPARENT,
    strokeColor: theme.colors.SPINACH,
    textColor: theme.colors.SPINACH,
  },
  'white-fill': {
    fillColor: theme.colors.WHITE,
    strokeColor: theme.colors.WHITE,
    textColor: theme.colors.OATMEAL,
  },
  'white-outline': {
    fillColor: theme.colors.OPACITY.TRANSPARENT,
    strokeColor: theme.colors.WHITE,
    textColor: theme.colors.WHITE,
  },
  'sweet-corn-fill': {
    fillColor: theme.colors.SWEET_CORN,
    strokeColor: theme.colors.SWEET_CORN,
    textColor: theme.colors.DARK_KALE,
  },
  'sweet-corn-outline': {
    fillColor: theme.colors.OPACITY.TRANSPARENT,
    strokeColor: theme.colors.SWEET_CORN,
    textColor: theme.colors.SWEET_CORN,
  },
};

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    position: 'absolute',
    left: 6,
    top: 11,
    transform: [{ rotateX: '2deg' }],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────────

type LoyaltyIconSgRewardPalette =
  | 'black-fill'
  | 'black-outline'
  | 'kale-fill'
  | 'kale-outline'
  | 'spinach-fill'
  | 'spinach-outline'
  | 'white-fill'
  | 'white-outline'
  | 'sweet-corn-fill'
  | 'sweet-corn-outline';

type LoyaltyIconSgRewardProps = {
  scale?: number;
  palette?: LoyaltyIconSgRewardPalette;
  fillColor?: string;
  strokeColor?: string;
  textColor?: string;
  strokeWidth?: string;
  style?: ViewProps['style'];
};

type VectorProps = {
  fillColor: string;
  strokeColor: string;
  textColor: string;
};

import type { ViewProps } from 'react-native';
import type { IconName } from '@sg/garnish';

import { getCreditCardIcon } from '@order/constants';
import { CardExpirationState, TierName } from '@order/graphql';

import type { AccountSweetpassPaymentMethodsSection } from '../../useSweetpassAccountContentfulData';
import type { GetSectionProps, SweetpassSectionProps } from '../types';

export const getPaymentMethodsSection = (
  props: GetPaymentMethodsSectionProps,
): SweetpassPaymentMethodsSectionProps | undefined => {
  const {
    name,
    paymentMethods,
    paymentMethodsDataTitle,
    tier,
    viewPaymentMethodHandler,
    addPaymentMethodHandler,
    formatMessage,
  } = props;

  if (tier?.name === TierName.OptedOut || !paymentMethods) {
    return undefined;
  }

  return {
    name,
    title: paymentMethodsDataTitle,
    items: paymentMethods.map((paymentMethod) => {
      const icon = getCreditCardIcon(paymentMethod.cardType);
      const title = formatMessage(
        'account.sweetpass-membership.payment-methods.item.card-ending-in',
        {
          cardType: paymentMethod.cardType,
          lastFour: paymentMethod.lastFour,
        },
      );
      const tag = paymentMethod.isActive
        ? formatMessage(
            'account.sweetpass-membership.payment-methods.item.tag.active',
          )
        : undefined;

      const accessibilityLabel = [title, tag].filter(Boolean).join('; ');

      const accessibilityHint = formatMessage(
        'account.sweetpass-membership.payment-methods.item.accessibility-hint',
      );

      const noticeMap: Record<CardExpirationState, string | undefined> = {
        [CardExpirationState.Expired]: formatMessage(
          'account.sweetpass-membership.payment-methods.item.notice.expired',
        ),
        [CardExpirationState.Nearing]: formatMessage(
          'account.sweetpass-membership.payment-methods.item.notice.expiring',
        ),
        [CardExpirationState.Ok]: undefined,
      };

      const notice = noticeMap[paymentMethod.expirationState];

      return {
        id: paymentMethod.id,
        isActive: paymentMethod.isActive,
        icon,
        title,
        accessibilityLabel,
        notice,
        accessibilityHint,
        tag,
        handler() {
          viewPaymentMethodHandler(paymentMethod.id);
        },
      };
    }),
    addItem: {
      icon: 'IconAdd',
      title: formatMessage(
        'account.sweetpass-membership.payment-methods.add-item.title',
      ),
      accessibilityLabel: formatMessage(
        'account.sweetpass-membership.payment-methods.add-item.accessibility-label',
      ),
      accessibilityHint: formatMessage(
        'account.sweetpass-membership.payment-methods.add-item.accessibility-hint',
      ),
      handler: addPaymentMethodHandler,
    },
  };
};

type GetPaymentMethodsSectionProps = Readonly<{
  viewPaymentMethodHandler: (paymentMethodId: string) => void;
  addPaymentMethodHandler: () => void;
}> &
  AccountSweetpassPaymentMethodsSection &
  GetSectionProps;

type SweetpassPaymentMethodItem = Readonly<{
  id: string;
  icon: IconName;
  title?: string;
  subtitle?: string;
  notice?: string;
  tag?: string;
  accessibilityLabel: string;
  accessibilityHint: string;
  handler: () => void;
}> &
  ViewProps;

type SweetpassPaymentMethodAddItem = Readonly<{
  icon: IconName;
  title: string;
  accessibilityLabel: string;
  accessibilityHint: string;
  handler: () => void;
}> &
  ViewProps;

export type SweetpassPaymentMethodsSectionProps = Readonly<{
  name: string;
  title: string;
  items: readonly SweetpassPaymentMethodItem[];
  addItem: SweetpassPaymentMethodAddItem;
}> &
  SweetpassSectionProps;

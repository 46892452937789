import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoContentContainer = (
  props: ComponentProps<typeof View>,
) => {
  const { children, style, ...restProps } = props;

  return (
    <View style={[styles.container, style]} {...restProps}>
      {children}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    rowGap: theme.spacing['4'],
    marginBottom: theme.spacing['6'],
  },
});

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeliveryInFlightQueryVariables = Types.Exact<{
  input: Types.OrdersInput;
}>;

export type DeliveryInFlightQuery = {
  readonly __typename?: 'Query';
  readonly orders:
    | {
        readonly __typename: 'OrdersResponseSuccess';
        readonly orders: ReadonlyArray<{
          readonly __typename?: 'Order';
          readonly id: string;
          readonly isInFlightDelivery: boolean;
        }>;
      }
    | { readonly __typename: 'ValidationError' };
};

export const DeliveryInFlightDocument = gql`
  query deliveryInFlight($input: OrdersInput!) {
    orders(input: $input) {
      __typename
      ... on OrdersResponseSuccess {
        orders {
          id
          isInFlightDelivery
        }
      }
    }
  }
`;

export function useDeliveryInFlightQuery(
  options: Omit<Urql.UseQueryArgs<DeliveryInFlightQueryVariables>, 'query'>,
) {
  return Urql.useQuery<DeliveryInFlightQuery, DeliveryInFlightQueryVariables>({
    query: DeliveryInFlightDocument,
    ...options,
  });
}

import { useCallback } from 'react';
import { type HostedFrameMessageEvent } from '@sg/garnish';

import { type useJoinOrSignInService } from '../../state';

// ─────────────────────────────────────────────────────────────────────────────

export const useJoinOrSignInFrameMessages = (
  params: UseJoinOrSignInFrameMessagesParams,
) => {
  const { joinOrSignInService, onFrameHeightChange } = params;

  const { send } = joinOrSignInService;

  /**
   * Handles incoming messages from the hosted auth frame.
   */
  return useCallback(
    (event: HostedFrameMessageEvent) => {
      const data = JSON.parse(event?.nativeEvent?.data ?? '{}');
      const eventType = data?.type;

      if (!eventType) return;

      switch (eventType) {
        case 'BODY_HEIGHT_CHANGE': {
          onFrameHeightChange(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            typeof data?.height === 'number' ? data.height : 0,
          );
          break;
        }

        case 'TOKEN_RECEIVED': {
          if (!data.apiAccessToken) {
            break;
          }

          send({
            type: 'TOKEN_RECEIVED',
            apiAccessToken: data.apiAccessToken,
            apiRefreshToken: data.apiRefreshToken,
            vendorApiAccessToken: data.vendorApiAccessToken,
            email: data.email,
          });
          break;
        }

        case 'EMAIL_ADDRESS_LOADED': {
          send('EMAIL_ADDRESS_LOADED');
          break;
        }

        case 'LOG_IN': {
          send('LOG_IN');
          break;
        }

        case 'VERIFICATION_CODE_LOADED': {
          send({ type: 'VERIFICATION_CODE_LOADED', email: data.email });
          break;
        }

        case 'VERIFY_CODE': {
          send('VERIFY_CODE');
          break;
        }

        case 'AUTH_ERROR': {
          send({ type: 'AUTH_ERROR', data: data?.reason });
          break;
        }

        case 'ERROR': {
          send({ type: 'ERROR', text: data?.text });
          break;
        }

        case 'ERROR_INLINE': {
          send({ type: 'ERROR' });
          break;
        }

        case 'SUCCESS': {
          send({ type: 'SUCCESS', text: data?.text });
          break;
        }

        case 'DEBUG_INFO': {
          send({ type: 'DEBUG_INFO', data });
          break;
        }
      }
    },
    [onFrameHeightChange, send],
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseJoinOrSignInFrameMessagesParams = {
  joinOrSignInService: ReturnType<typeof useJoinOrSignInService>;
  onFrameHeightChange: (height: number) => void;
};

import React from 'react';
import type { ViewStyle } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { LoyaltyChallengeCardVariants } from './LoyaltyChallengeCard.constants';
import type {
  LoyaltyChallengeCardBaseProps,
  LoyaltyChallengeCardCompletedProps,
  LoyaltyChallengeCardNewProps,
  LoyaltyChallengeCardOngoingProps,
  LoyaltyChallengeCardProps,
  LoyaltyChallengeCardVariantsType,
  LoyaltyChallengeTheme,
} from './LoyaltyChallengeCard.types';
import {
  LoyaltyChallengeBadge,
  LoyaltyChallengeContent,
  LoyaltyChallengeFooterCompleted,
  LoyaltyChallengeFooterNew,
  LoyaltyChallengeFooterOngoing,
  LoyaltyChallengeIllustration,
  LoyaltyChallengeTerms,
} from './subcomponents';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyChallengeCard = (props: LoyaltyChallengeCardProps) => {
  const { status } = props;

  if (status === 'New') return <LoyaltyChallengeCardNew {...props} />;

  if (status === 'Ongoing') return <LoyaltyChallengeCardOngoing {...props} />;

  if (status === 'Completed') {
    return <LoyaltyChallengeCardCompleted {...props} />;
  }

  return null;
};

// ─── Subcomponents ───────────────────────────────────────────────────────────

const LoyaltyChallengeCardBase = (props: LoyaltyChallengeCardBaseProps) => {
  const {
    id,
    variant,
    badgeText,
    deadlinePrefixText,
    title,
    theme: challengeTheme = 'DEFAULT',
    children,
    expirationDate,
    testID,
  } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const cardThemeStyles =
    loyaltyChallengeCardThemeStyles[challengeTheme][variant];
  const cardStyles = [styles.cardContainer, cardThemeStyles];

  // ─────────────────────────────────────────────────────────────────────

  const shouldDisplayBadge = variant === 'New';

  return (
    <View nativeID={`challenge-card-${id}`} testID={testID} style={cardStyles}>
      <View style={styles.innerContainer}>
        {shouldDisplayBadge ? (
          <LoyaltyChallengeBadge label={badgeText} theme={challengeTheme} />
        ) : null}

        <LoyaltyChallengeIllustration
          challengeTheme={challengeTheme}
          challengeVariant={variant}
          testID={`${testID}-image`}
        />

        <LoyaltyChallengeContent
          expirationDate={expirationDate}
          title={title}
          deadlinePrefixText={deadlinePrefixText}
        >
          {children}
        </LoyaltyChallengeContent>
      </View>
    </View>
  );
};

// ─── Variations ──────────────────────────────────────────────────────────────

const LoyaltyChallengeCardNew = (props: LoyaltyChallengeCardNewProps) => {
  const { onStartChallenge, disabled, isLoading, ...challengeCardProps } =
    props;

  return (
    <LoyaltyChallengeCardBase
      variant={LoyaltyChallengeCardVariants.New}
      {...challengeCardProps}
    >
      <View style={styles.contentContainer}>
        <LoyaltyChallengeTerms
          {...challengeCardProps}
          testID={`${props.testID}-content`}
        />
      </View>

      <LoyaltyChallengeFooterNew
        testID={`${props.testID}-footer`}
        onPress={onStartChallenge}
        disabled={disabled}
        isLoading={isLoading}
      />
    </LoyaltyChallengeCardBase>
  );
};

const LoyaltyChallengeCardOngoing = (
  props: LoyaltyChallengeCardOngoingProps,
) => {
  const {
    totalSteps,
    completedSteps,
    progressCopy,
    footerText,
    ...challengeCardProps
  } = props;

  return (
    <LoyaltyChallengeCardBase
      variant={LoyaltyChallengeCardVariants.Ongoing}
      {...challengeCardProps}
    >
      <View style={styles.contentContainer}>
        <LoyaltyChallengeTerms
          {...challengeCardProps}
          testID={`${props.testID}-content`}
        />
      </View>

      <LoyaltyChallengeFooterOngoing
        testID={`${props.testID}-footer`}
        totalSteps={totalSteps}
        progressCopy={progressCopy}
        completedSteps={completedSteps}
        footerText={footerText}
      />
    </LoyaltyChallengeCardBase>
  );
};

const LoyaltyChallengeCardCompleted = (
  props: LoyaltyChallengeCardCompletedProps,
) => {
  return (
    <LoyaltyChallengeCardBase
      variant={LoyaltyChallengeCardVariants.Completed}
      {...props}
    >
      <View style={styles.contentContainer}>
        <LoyaltyChallengeTerms {...props} testID={`${props.testID}-content`} />
      </View>

      <LoyaltyChallengeFooterCompleted
        completionText={props.completionText}
        testID={`${props.testID}-footer`}
      />
    </LoyaltyChallengeCardBase>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cardContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['4'],
    borderWidth: 1,
    borderColor: theme.colors.OPACITY.TRANSPARENT,
    borderRadius: theme.radius.large,
  },
  contentContainer: {
    flex: 1,
  },
  innerContainer: {
    flex: 1,
  },
});

/**
 * Loyalty challenge cards can have different styles based on
 * their theme (`FLASH`, etc.) and type (`New`, `Ongoing`, `Completed`).
 */
const loyaltyChallengeCardThemeStyles: Record<
  LoyaltyChallengeTheme,
  Record<LoyaltyChallengeCardVariantsType, ViewStyle>
> = {
  DEFAULT: {
    New: {
      backgroundColor: theme.colors.QUINOA,
    },
    Completed: {
      backgroundColor: theme.colors.CUCUMBER,
    },
    Ongoing: {
      backgroundColor: theme.colors.QUINOA,
    },
  },
  FLASH: {
    New: {
      backgroundColor: theme.colors.LIME,
      borderWidth: 1,
      borderColor: theme.colors.KALE,
    },
    Ongoing: {
      backgroundColor: theme.colors.LIME,
      borderWidth: 1,
      borderColor: theme.colors.KALE,
    },
    Completed: {
      backgroundColor: theme.colors.CUCUMBER,
    },
  },
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { DisplayText } from '@sg/garnish';

import { orderAgainMessages as messages } from '../../OrderAgain.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainHeaderTitle = () => {
  return (
    <DisplayText style={styles.headerTitle} sizeMatch={['32']}>
      <FormattedMessage {...messages.headerTitle} />
    </DisplayText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  headerTitle: {
    flex: 1,
  },
});

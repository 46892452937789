/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PaymentProfileQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type PaymentProfileQuery = {
  readonly __typename?: 'Query';
  readonly paymentProfile: {
    readonly __typename?: 'PaymentProfile';
    readonly id: string;
    readonly defaultCardId: string | null;
  } | null;
};

export type SetDefaultPaymentMethodMutationVariables = Types.Exact<{
  paymentMethodId: Types.Scalars['String']['input'];
}>;

export type SetDefaultPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly setDefaultPaymentMethod: {
    readonly __typename: 'PaymentProfile';
    readonly id: string;
    readonly defaultCardId: string | null;
  };
};

export const PaymentProfileDocument = gql`
  query PaymentProfile {
    paymentProfile {
      id
      defaultCardId
    }
  }
`;

export function usePaymentProfileQuery(
  options?: Omit<Urql.UseQueryArgs<PaymentProfileQueryVariables>, 'query'>,
) {
  return Urql.useQuery<PaymentProfileQuery, PaymentProfileQueryVariables>({
    query: PaymentProfileDocument,
    ...options,
  });
}
export const SetDefaultPaymentMethodDocument = gql`
  mutation SetDefaultPaymentMethod($paymentMethodId: String!) {
    setDefaultPaymentMethod(paymentMethodId: $paymentMethodId) {
      __typename
      id
      defaultCardId
    }
  }
`;

export function useSetDefaultPaymentMethodMutation() {
  return Urql.useMutation<
    SetDefaultPaymentMethodMutation,
    SetDefaultPaymentMethodMutationVariables
  >(SetDefaultPaymentMethodDocument);
}

import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View, type ViewProps } from 'react-native';
import { CustomNavigationHeader, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * The payment method form for the bag.
 */
export const BagPaymentMethodForm = (props: BagPaymentMethodFormProps) => {
  const { children, onBack, onClose } = props;
  const { match } = useResponsive();

  return (
    <View style={styles.container}>
      <CustomNavigationHeader.Container safeAreaEdges={[]}>
        <CustomNavigationHeader.Button.Left
          name="IconCaretLeft"
          onPress={onBack}
        />

        <CustomNavigationHeader.Text>
          <FormattedMessage {...messages.title} />
        </CustomNavigationHeader.Text>

        <CustomNavigationHeader.Button.Right
          name="IconClose"
          onPress={onClose}
        />
      </CustomNavigationHeader.Container>

      <View style={match([null, styles.contentMd])}>{children}</View>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Add card',
    description: 'Bag | Payment Method Form | Title',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    gap: theme.spacing['6'],
  },
  contentMd: {
    marginHorizontal: -theme.spacing['2'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagPaymentMethodFormProps = Readonly<{
  children: ViewProps['children'];
  onBack?: () => void;
  onClose?: () => void;
}>;

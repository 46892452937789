import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import type { PaymentMethodCard } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { useDeletePaymentMethodCardMutation } from './graphql/DeletePaymentMethod.generated';

export function useDeletePaymentMethod(props: DeletePaymentMethodProps) {
  const { onSuccess } = props;

  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  const [deletePaymentMethodCardResponse, deletePaymentMethodCardMutation] =
    useDeletePaymentMethodCardMutation();

  const deletePaymentMethod = useCallback(
    async (paymentMethod: PaymentMethodCard) => {
      const paymentMethodId = paymentMethod.id;

      const response = await deletePaymentMethodCardMutation({
        input: { id: paymentMethodId },
      });

      const result = response.data?.deletePaymentMethodCard;
      const isSuccess =
        result?.__typename === 'PaymentMethodCard' &&
        result?.id === paymentMethodId;

      if (isSuccess) {
        addNoticeBanner({
          text: t('credit-card-form.remove.success'),
          palette: 'success',
        });

        onSuccess();

        return;
      }

      const userError = t('credit-card-form.error.delete');

      addNoticeBanner({ text: userError, palette: 'caution' });
    },
    [deletePaymentMethodCardMutation, t, addNoticeBanner, onSuccess],
  );

  return {
    deletePaymentMethod,
    isDeleting: deletePaymentMethodCardResponse.fetching,
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type DeletePaymentMethodProps = Readonly<{
  onSuccess: () => void;
}>;

import React from 'react';
import { StyleSheet } from 'react-native';
import { BodyText } from '@sg/garnish';

import type { DurationUnit } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

export const SweetpassCheckoutPrice = (props: SweetpassCheckoutPriceProps) => {
  const { planPrice, billingFrequencyUnit, hasFreeTrial } = props;

  const { t } = useLocalizationContext();
  const formattedPrice = t('sweetpass.checkout.membership-price', {
    planPrice,
    billingFrequencyUnit: billingFrequencyUnit.toLowerCase(),
  });

  if (hasFreeTrial) {
    return (
      <BodyText>
        <BodyText>{t('sweetpass.checkout.free-trial')}</BodyText>
        <BodyText style={styles.dashedPriced}>{formattedPrice}</BodyText>
      </BodyText>
    );
  }

  return <BodyText>{formattedPrice}</BodyText>;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassCheckoutPriceProps = Readonly<{
  planPrice: number;
  hasFreeTrial: boolean;
  billingFrequencyUnit: DurationUnit;
}>;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  dashedPriced: {
    textDecorationLine: 'line-through',
  },
});

import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';

import { useNavigateToLastOrderMenu, useSweetpassTier } from '@order/hooks';
import { useTelemetry } from '@order/Telemetry';

import { SupportedHomeHeroActions } from '../../utils';

export const useHomeHeroCTA = (action?: HomeHeroAction) => {
  const handleViewMenu = useNavigateToLastOrderMenu();
  const navigation = useNavigation();
  const { track } = useTelemetry();

  // ─── Sweetpass ───────────────────────────────────────────────────────

  const { sweetpassTierName, isSweetpassTierLoading } = useSweetpassTier();
  const canUpgradeSweetpass =
    !isSweetpassTierLoading && sweetpassTierName !== 'SWEETPASS_PLUS';

  // ─────────────────────────────────────────────────────────────────────

  return useCallback(() => {
    track('home.hero_module');

    if (action === SupportedHomeHeroActions.ViewMenu) {
      handleViewMenu();

      return;
    }

    if (
      action === SupportedHomeHeroActions.UpgradeSweetpass &&
      canUpgradeSweetpass
    ) {
      navigation.navigate('Modal', { screen: 'SweetpassUpgrade' });

      return;
    }

    if (action === SupportedHomeHeroActions.ExploreBenefits) {
      navigation.navigate('Modal', { screen: 'SweetpassBenefits' });

      return;
    }

    if (action === SupportedHomeHeroActions.ExploreSweetpass) {
      navigation.navigate('MainTabs', {
        screen: 'LoyaltyTab',
        params: { screen: 'SweetpassHome' },
      });

      return;
    }

    if (action === SupportedHomeHeroActions.ExploreChallenges) {
      navigation.navigate('MainTabs', {
        screen: 'LoyaltyTab',
        params: { screen: 'SweetpassHome', params: { target: 'challenges' } },
      });

      return;
    }

    if (action === SupportedHomeHeroActions.ExploreRewards) {
      navigation.navigate('MainTabs', {
        screen: 'LoyaltyTab',
        params: { screen: 'SweetpassHome', params: { target: 'rewards' } },
      });

      return;
    }

    handleViewMenu(); // default handler.
  }, [action, canUpgradeSweetpass, navigation, track, handleViewMenu]);
};

// ─── Types / Constants ─────────────────────────────────────────────────

type ValueOf<T> = T[keyof T];
type HomeHeroAction = ValueOf<typeof SupportedHomeHeroActions>;

import { type GiftCardRedemptionDataParams } from './get-gift-card-redemption-data-params.types';

/**
 * Returns decoded gift card redemption data params.
 */
export function getGiftCardRedemptionDataParams(
  decodedData: string,
): GiftCardRedemptionDataParams {
  const decodedDataParams = new URLSearchParams(decodedData);

  const giftCardCode = decodedDataParams.get('gc')?.toString();
  const giftCardRegistrationCode = decodedDataParams.get('c')?.toString();

  return {
    giftCardCode,
    giftCardRegistrationCode,
  };
}

import React, { type ComponentProps } from 'react';
import { Link } from '@react-navigation/native';

import { CustomNavigationHeaderText } from '../CustomNavigationHeaderText';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomNavigationHeaderLink = (
  props: CustomNavigationHeaderButtonProps,
) => {
  const { children, ...restProps } = props;

  return (
    <Link {...restProps}>
      <CustomNavigationHeaderText isLink={true}>
        {children}
      </CustomNavigationHeaderText>
    </Link>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomNavigationHeaderButtonProps = ComponentProps<typeof Link>;

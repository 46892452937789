import type { ImageStyle, TextStyle, ViewStyle } from 'react-native';
import { StyleSheet } from 'react-native';

export const useStyleSwitch = <VarKeys extends string>(
  prop: string,
  stylesBlock: VariantStyleBlocks<VarKeys>,
) => {
  const varHashedStyleSheet = generateVariantStyleSheet(prop, stylesBlock);

  return makeUseStyleSwitch<VarKeys>(prop, varHashedStyleSheet);
};

export const makeUseStyleSwitch = <VarKeys extends string>(
  varProp: string,
  varStyle: VariantStyleBlocks<string>,
) => {
  return (variantKey: VarKeys) => {
    const key = getHashedKey<typeof varProp, VarKeys>(varProp)(variantKey);

    return varStyle[key];
  };
};

export const generateVariantStyleSheet = <VarKeys extends string>(
  prop: string,
  stylesBlock: VariantStyleBlocks<VarKeys>,
) => {
  const stylesObject = Object.entries(stylesBlock).reduce((acc, val) => {
    const [varKey, varStyle] = val;

    const hashedKey = getHashedKey<typeof prop, VarKeys>(prop)(
      varKey as VarKeys,
    );

    const newAcc = { ...acc, [hashedKey]: varStyle };

    return newAcc;
  }, {});

  return StyleSheet.create(stylesObject);
};

export const getHashedKey = <PropKey extends string, VarKey extends string>(
  prop: PropKey,
) => {
  return (variant: VarKey) => {
    return `_var_::${prop}::${variant}` as const;
  };
};

//
// ─── TYPES ──────────────────────────────────────────────────────────────────────
//

type Styles = ViewStyle | TextStyle | ImageStyle;

type VariantStyleBlocks<T extends string> = Styles & Record<T, Styles>;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { webOnlyStyles } from '../../../../utils';
import { Icon } from '../../../Icon';
import {
  CHECKBOX_COLOR,
  CHECKBOX_DISABLED_COLOR,
  CHECKBOX_HOVER_COLOR,
  CHECKBOX_ICON_COLOR,
  CHECKBOX_ICON_SIZE,
  CHECKBOX_SIZE,
} from '../../Checkbox.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const CheckboxIcon = (props: CheckboxIconProps) => {
  const { state } = props;

  const containerStyle = [
    styles.container,
    containerWebStyles,
    getCheckboxToggleIconStyles(state),
  ];

  const iconColor = getCheckboxIconColor(state);

  // ────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyle}>
      {state.checked ? (
        <Icon
          name="IconCheck"
          color={iconColor}
          width={CHECKBOX_ICON_SIZE}
          height={CHECKBOX_ICON_SIZE}
        />
      ) : null}
    </View>
  );
};

// ─── Utils ───────────────────────────────────────────────────────────────────

function getCheckboxToggleIconStyles(states: CheckboxState) {
  if (states.disabled) {
    return [styles.containerDisabled, webOnlyStyles({ cursor: 'not-allowed' })];
  }

  if (states.hovered) {
    return styles.containerHover;
  }

  if (states.checked) {
    return styles.containerChecked;
  }

  return styles.containerIdle;
}

function getCheckboxIconColor(states: CheckboxState) {
  if (states.disabled) {
    return CHECKBOX_DISABLED_COLOR;
  }

  if (states.hovered) {
    return CHECKBOX_COLOR;
  }

  return CHECKBOX_ICON_COLOR;
}

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: CHECKBOX_SIZE,
    height: CHECKBOX_SIZE,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: theme.radius.small,
  },
  containerIdle: {
    borderColor: CHECKBOX_COLOR,
  },
  containerChecked: {
    backgroundColor: CHECKBOX_COLOR,
    borderColor: CHECKBOX_COLOR,
  },
  containerHover: {
    backgroundColor: CHECKBOX_HOVER_COLOR,
    borderColor: CHECKBOX_COLOR,
  },
  containerDisabled: {
    borderColor: CHECKBOX_DISABLED_COLOR,
  },
});

const containerWebStyles = webOnlyStyles({
  transition: `background-color ${theme.transitions.base}ms, border-color ${theme.transitions.base}ms`,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CheckboxIconProps = Readonly<{
  state: CheckboxState;
}>;

type CheckboxState = {
  hovered: boolean;
  checked: boolean;
  disabled: boolean;
};

import {
  JoinSgRewardsBodyContainer,
  JoinSgRewardsContainer,
  JoinSgRewardsCta,
  JoinSgRewardsHero,
  JoinSgRewardsRowItem,
  JoinSgRewardsTitle,
} from './components';

export const JoinSgRewards = {
  Container: JoinSgRewardsContainer,
  Title: JoinSgRewardsTitle,
  Hero: JoinSgRewardsHero,
  RowItem: JoinSgRewardsRowItem,
  Cta: JoinSgRewardsCta,
  BodyContainer: JoinSgRewardsBodyContainer,
};

/* istanbul ignore file */

import { useCart } from '@order/hooks';

import { type ReorderOrder } from '../../useReorderOrderLineItem.types';

// ─────────────────────────────────────────────────────────────────────────────

export const useReorderOrderLineItemLocation = (
  params: UseReorderOrderLineItemParams,
) => {
  const { mostRecentOrder } = params;

  const { cart } = useCart();

  // ─── Derived Data ────────────────────────────────────────────────────

  const order = cart?.id ? cart : mostRecentOrder;

  const restaurantId = order?.restaurant?.id;
  const restaurantSlug = order?.restaurant?.slug;
  const restaurantName = order?.restaurant?.name;
  const deliveryOrderDetails = order?.deliveryOrderDetail;

  // ─────────────────────────────────────────────────────────────────────

  return {
    restaurantId,
    restaurantName,
    restaurantSlug,
    deliveryOrderDetails,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseReorderOrderLineItemParams = {
  mostRecentOrder: ReorderOrder | undefined;
};

import React from 'react';
import type { ImageSourcePropType } from 'react-native';
import { StyleSheet } from 'react-native';

import { Image } from '../../../Image';

export const ModalImage = (props: ModalImageProps) => {
  const { imageUrl, style, ...rest } = props;

  return (
    <Image
      source={imageUrl}
      contentFit="contain"
      style={[styles.illustration, style]}
      {...rest}
    />
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type ModalImageProps = Readonly<{
  imageUrl?: ImageSourcePropType;
}> &
  React.ComponentProps<typeof Image>;

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  illustration: {
    width: 200,
    height: 200,
    alignSelf: 'center',
  },
});

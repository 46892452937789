import { useMemo } from 'react';
import { type RequestPolicy } from 'urql';

import { useIsLoggedIn } from '@order/AuthMachine';
import {
  type FetchPlansByTierIdQuery,
  TierName,
  useFetchPlansByTierIdQuery,
} from '@order/graphql';

import { useSweetpassTier } from '../useSweetpassTier';
import {
  type SubscriptionPlansByIdsQuery,
  useSubscriptionPlansByIdsQuery,
} from './graphql/Plan.generated';
import { type SweetpassMembershipPlans } from './useSweetpassPlusPlans.types';
import { extractAvailablePlans } from './utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * The current setup considers one free trial monthly plan and one free trial annual plan.
 * All other plans will be ignored as we'll always use free trials if eligible going forwards.
 * The annual plan won't be returned by payments-svc if the feature flag is turned off.
 */
export function useSweetpassPlusPlans(
  requestPolicy: RequestPolicy = 'cache-first',
): AvailablePlansDetails {
  const isLoggedIn = useIsLoggedIn();

  const sweetpassPlusTierId = useSweetpassPlusTierId();

  // ─── Membership Plan IDs ─────────────────────────────────────────────

  const shouldPauseFetchPlansByTierIdQuery =
    !isLoggedIn || !sweetpassPlusTierId;

  const [plansByTierIdResponse] = useFetchPlansByTierIdQuery({
    variables: { tierId: sweetpassPlusTierId ?? '' },
    pause: shouldPauseFetchPlansByTierIdQuery,
    requestPolicy,
  });
  const { fetchPlansByTierId } = plansByTierIdResponse.data ?? {};

  const membershipPlansIdObjects =
    fetchPlansByTierId ?? MEMBERSHIP_PLAN_IDS_FALLBACK;
  const membershipPlansIds = useMemo<string[]>(
    () => membershipPlansIdObjects.map((plan) => plan.braintreePlanId),
    [membershipPlansIdObjects],
  );

  const isFetchingPlanIds = plansByTierIdResponse.fetching;

  // ─── Membership Plans ────────────────────────────────────────────────

  const shouldPauseSubscriptionPlansByIdsQuery =
    membershipPlansIds.length === 0 || !isLoggedIn || isFetchingPlanIds;

  const [plansResponse] = useSubscriptionPlansByIdsQuery({
    variables: { planIds: membershipPlansIds },
    pause: shouldPauseSubscriptionPlansByIdsQuery,
    requestPolicy,
  });
  const { subscriptionPlansByIds } = plansResponse.data ?? {};

  const membershipPlans = subscriptionPlansByIds ?? MEMBERSHIP_PLANS_FALLBACK;
  const isLoadingPlans = isFetchingPlanIds || plansResponse.fetching;

  // ─── Derived Data ────────────────────────────────────────────────────

  const plansWithFreeTrial = membershipPlans.filter(
    (plan) => plan.hasFreeTrial,
  );
  const monthlyPlan = plansWithFreeTrial.find(
    (plan) => plan.billingFrequency === '1',
  );
  const annualPlan = plansWithFreeTrial.find(
    (plan) => plan.billingFrequency === '12',
  );

  // ─── Derived Data ────────────────────────────────────────────────────

  const availablePlans = useMemo<SweetpassMembershipPlans>(
    () => extractAvailablePlans({ annualPlan, monthlyPlan }),
    [annualPlan, monthlyPlan],
  );

  const defaultPlan = availablePlans.monthly;

  // ─── Flags ───────────────────────────────────────────────────────────

  const hasUnknownPlansError = Boolean(
    plansByTierIdResponse?.error ?? plansResponse?.error,
  );

  // ─────────────────────────────────────────────────────────────────────

  return {
    isLoadingPlans,
    plans: availablePlans,
    defaultPlan,
    hasUnknownPlansError,
  };
}

// ─── Helper Hooks ────────────────────────────────────────────────────────────

function useSweetpassPlusTierId() {
  const {
    sweetpassTierId,
    sweetpassTierName,
    sweetpassNextTierId,
    sweetpassNextTierName,
  } = useSweetpassTier();

  if (sweetpassTierName === TierName.SweetpassPlus) {
    return sweetpassTierId;
  }

  if (sweetpassNextTierName === TierName.SweetpassPlus) {
    return sweetpassNextTierId;
  }
}

// ─── Constants ───────────────────────────────────────────────────────────────

const MEMBERSHIP_PLAN_IDS_FALLBACK: FetchPlansByTierIds = [];
const MEMBERSHIP_PLANS_FALLBACK: SubscriptionPlansByIds = [];

// ─── Types ───────────────────────────────────────────────────────────────────

type AvailablePlansDetails = {
  isLoadingPlans: boolean;
  hasUnknownPlansError: boolean;
  defaultPlan: SweetpassMembershipPlans['monthly'];
  plans: SweetpassMembershipPlans;
};

type FetchPlansByTierIds = NonNullable<
  FetchPlansByTierIdQuery['fetchPlansByTierId']
>;

type SubscriptionPlansByIds = NonNullable<
  SubscriptionPlansByIdsQuery['subscriptionPlansByIds']
>;

import { datadogLogs } from '@datadog/browser-logs';

const debug = (message: string, attributes?: Record<string, unknown>) => {
  datadogLogs.logger.debug(message, attributes);
};

const info = (message: string, attributes?: Record<string, unknown>) => {
  datadogLogs.logger.info(message, attributes);
};

const warn = (message: string, attributes?: Record<string, unknown>) => {
  datadogLogs.logger.warn(message, attributes);
};

const error = (message: string, attributes?: Record<string, unknown>) => {
  datadogLogs.logger.error(message, attributes);
};

export const logger = {
  debug,
  info,
  warn,
  error,
};

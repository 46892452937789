import React from 'react';
import { StyleSheet, View } from 'react-native';

import { getFormattedExpirationDate } from '../../../utils';
import { TagLabel } from '../../TagLabel';
import type { ChallengeExpirationBadgeProps } from '../ChallengeCard.types';

export const ChallengeExpirationBadge = ({
  expirationDate,
  isCompleted,
}: ChallengeExpirationBadgeProps) => {
  const endsText = getFormattedExpirationDate(expirationDate, 'Ends');

  return (
    <View style={styles.wrapper}>
      <TagLabel size={3} palette={isCompleted ? 'tertiary' : 'primary'}>
        {endsText}
      </TagLabel>
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    alignSelf: 'flex-start',
  },
});

import React, { useEffect } from 'react';
import {
  Button,
  HStack,
  IllusLocation,
  Modal,
  ModalDialogue,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useCart } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

// Warning for when the customer attempts to edit an address that has a order associated with it.
// Since the address is linked to the order object in gravy, changing the address requires clearing the bag.
export const ActiveBagWarning = ({
  visible,
  onClose,
  onConfirm,
}: ActiveBagWarningProps) => {
  const { t } = useLocalizationContext();
  const { track } = useTelemetry();
  const { currentBreakpoint } = useResponsive();

  useEffect(() => {
    if (!visible) return;
    track('addresses.active_bag');
  }, [track, visible]);

  return (
    <ModalDialogue
      testID="addresses.active-bag-warning.container"
      size="medium"
      imageSrc={currentBreakpoint.isXS ? undefined : IllusLocation}
      visible={visible}
      accessibilityLabel={t('addresses.active-bag-warning.title')}
      headline={t('addresses.active-bag-warning.title')}
      body={t('addresses.active-bag-warning.subtitle')}
      onRequestClose={onClose}
    >
      <WarningControls onClose={onClose} onConfirm={onConfirm} />
    </ModalDialogue>
  );
};

// Intercepts an attempt to edit an address.
// Will only allow it if it's not the address for the current bag.
// Returns callbacks to be used in the `<ActiveBagWarning />` modal.
export const useActiveBagWarning = (
  proceedToEdit: (addressId: string) => void,
) => {
  // ─────────── State ───────────
  const { cart, isCartEmpty } = useCart();
  const currentBagAddressId =
    !isCartEmpty && cart?.deliveryOrderDetail?.address?.id;
  const [activeBagWarning, setActiveBagWarning] = React.useState('');

  // ────── Modal Callbacks ──────
  const closeActiveBagWarning = React.useCallback(() => {
    setActiveBagWarning('');
  }, [setActiveBagWarning]);
  const confirmEditAddress = React.useCallback(() => {
    proceedToEdit(activeBagWarning);
    closeActiveBagWarning();
  }, [activeBagWarning, proceedToEdit, closeActiveBagWarning]);

  // ───── Actual Edit Request ─────
  const onEdit = React.useCallback(
    (addressId: string) => {
      if (addressId === currentBagAddressId) {
        setActiveBagWarning(addressId);

        return;
      }

      proceedToEdit(addressId);
    },
    [currentBagAddressId, proceedToEdit],
  );

  // ────── Component Usage ──────
  return {
    activeBagWarning,
    closeActiveBagWarning,
    confirmEditAddress,
    onEdit,
  };
};

const WarningControls = ({
  onClose,
  onConfirm,
}: Pick<ActiveBagWarningProps, 'onClose' | 'onConfirm'>) => {
  const { t } = useLocalizationContext();

  return (
    <Modal.Footer withoutTopBorder>
      <HStack itemsPerRow={2} gap={theme.spacing['4']}>
        <Button
          palette="secondary"
          size="large"
          testID="addresses.active-bag-warning.close"
          accessibilityLabel={t('addresses.active-bag-warning.cta')}
          accessibilityHint={t('addresses.active-bag-warning.cta')}
          accessibilityRole="button"
          onPress={onClose}
        >
          {t('general.close')}
        </Button>
        <Button
          size="large"
          testID="addresses.active-bag-warning.confirm"
          accessibilityLabel={t('addresses.active-bag-warning.cta')}
          accessibilityHint={t('addresses.active-bag-warning.cta')}
          accessibilityRole="button"
          onPress={onConfirm}
        >
          {t('addresses.active-bag-warning.cta')}
        </Button>
      </HStack>
    </Modal.Footer>
  );
};

type ActiveBagWarningProps = Readonly<{
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
}>;

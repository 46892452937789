import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useNoticeBannersStackContext, useResponsive } from '@sg/garnish';

import {
  AccountScreenContainer,
  AccountScreenSection,
  AccountScreenTitle,
} from '@order/components';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';
import { getUrqlError } from '@order/utils';

import {
  useCustomerProfileFieldsQuery,
  useUpdateCustomerProfileMutation,
} from './GraphQL/Profile.generated';
import { ProfileForm } from './ProfileForm';

// ────────────────────────────────────────────────────────────────────────────────

export const ProfileScreen = () => {
  const { goBack } = useNavigation();
  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();

  // ─── Network Requests ────────────────────────────────────────────────

  const [customerResponse] = useCustomerProfileFieldsQuery({
    requestPolicy: 'cache-and-network',
  });
  const { submitResponse, handleFormSubmit } = useFormSubmit();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isLoading =
    customerResponse.fetching || !customerResponse.data?.currentCustomer?.id;

  // NOTE: To improve UX, we need to close this screen at the smallest breakpoint (mobile device) on form reset.
  const shouldCloseTheScreenOnFormReset = currentBreakpoint.isXS;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onFormReset = useCallback(() => {
    if (shouldCloseTheScreenOnFormReset) goBack();
  }, [goBack, shouldCloseTheScreenOnFormReset]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <AccountScreenContainer screen="profile" testID="profile-screen">
      <AccountScreenTitle title={t('account.profile.title')} />
      <AccountScreenSection>
        <ProfileForm
          customer={customerResponse.data?.currentCustomer ?? null}
          isLoading={isLoading}
          isSubmitting={submitResponse.fetching}
          submitForm={handleFormSubmit}
          onFormReset={onFormReset}
        />
      </AccountScreenSection>
    </AccountScreenContainer>
  );
};

type UpdateCallback = ReturnType<typeof useUpdateCustomerProfileMutation>[1];

// ────────────────────────────────────────────────────────────────────────────────

const useFormSubmit = () => {
  const { t } = useLocalizationContext();
  const [submitResponse, updateMutation] = useUpdateCustomerProfileMutation();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { track } = useTelemetry();

  const handleFormSubmit = React.useCallback(
    async (input: Parameters<UpdateCallback>[0]) => {
      const response = await updateMutation(input);
      const systemError = getUrqlError(response.error);

      if (systemError) {
        const userError = t('account.profile.error');

        track('account.profile_save_failed', { userError, systemError });

        addNoticeBanner({
          text: userError,
          palette: 'caution',
        });
      }

      return response;
    },
    [updateMutation, t, track, addNoticeBanner],
  );

  return { submitResponse, handleFormSubmit };
};

import { useCallback, useState } from 'react';

import { type LoyaltyReward } from '../../GraphQL';

/**
 * Returns which term is being shown.
 */
export const useTermsBeingShown = () => {
  const [termsBeingShown, setTermsBeingShown] = useState<LoyaltyReward | null>(
    null,
  );

  const showTerms = useCallback((reward: LoyaltyReward) => {
    return () => {
      setTermsBeingShown(reward);
    };
  }, []);

  const hideTerms = useCallback(() => {
    setTermsBeingShown(null);
  }, []);

  return {
    termsBeingShown,
    showTerms,
    hideTerms,
  };
};

import { telemetryLogger as logger } from '../../Telemetry.logger';
import type { TelemetryEventName } from '../../TelemetryEvent';
import type { TelemetryEventOverride } from '../../TelemetryEventOverride';
import { shouldHandleAppSessionEvent } from '../../TelemetryEventOverride';

export const mixpanelEventOverrides: MixpanelEventOverrides = {
  'app.session'({ event }) {
    const shouldHandle = shouldHandleAppSessionEvent(event);

    logger.info(
      `'app.session' ${
        shouldHandle ? 'send' : 'ignore'
      } payload: ${JSON.stringify(event.payload)}`,
    );

    return { event: shouldHandle ? event : undefined };
  },

  'app.page_view': () => ({}), // not used for mixpanel.

  'dietary-restrictions.select': ({ context, event }) => ({
    // Sends the event.
    event,
    // Sets the dietary restriction user property.
    post() {
      const { restriction, selected } = event.payload;

      if (restriction) {
        context.setCustomerProperty(
          `dietary.${restriction}`,
          Boolean(selected),
        );
      }
    },
  }),
};

// –– TYPES –––––––––––––––––––––––––––

type MixpanelEventOverrides = {
  readonly [Name in TelemetryEventName]?: TelemetryEventOverride<
    Name,
    MixpanelEvent
  >;
};

export type MixpanelEvent = Readonly<{
  name: string;
  payload?: Record<string, unknown>;
}>;

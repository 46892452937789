import React from 'react';

import { useTelemetry } from '@order/Telemetry';

export const useViewDetailsTelemetry = (expanded: boolean) => {
  const { track } = useTelemetry();

  React.useEffect(() => {
    if (!expanded) return;
    track('payment_credit.view_credit');
  }, [expanded, track]);
};

import React, {
  type ComponentProps,
  type PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { Carousel, IconLink, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Component responsible for rendering a carousel of loyalty offers.
 *
 * It manages the state of the active slide and provides navigation controls
 * to move between slides.
 */
export const LoyaltyOffersCarousel = (props: LoyaltyOffersCarouselProps) => {
  const {
    children: slides,
    onActiveSlideIndexChange,
    onGoToPrevSlide,
    onGoToNextSlide,
  } = props;

  const { formatMessage } = useIntl();

  const slidesCount = React.Children.count(slides);

  // ─── State ───────────────────────────────────────────────────────────

  const [activeSlide, setActiveSlide] = useState(0);

  // ─────────────────────────────────────────────────────────────────────

  const { minWidth } = useResponsive();
  const carouselRef = useRef<CarouselRef>();

  const hasPrev = slidesCount > 1 && activeSlide > 0;
  const hasNext = slidesCount > 1 && activeSlide + 1 < slidesCount;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onActiveSlideChange = useCallback(
    (slideIndex: number) => {
      setActiveSlide(slideIndex);
      onActiveSlideIndexChange?.(slideIndex);
    },
    [onActiveSlideIndexChange],
  );

  const goToPrevSlide = useCallback(() => {
    carouselRef.current?.goToPrevSlide();
    onGoToPrevSlide?.();
  }, [onGoToPrevSlide]);

  const goToNextSlide = useCallback(() => {
    carouselRef.current?.goToNextSlide();
    onGoToNextSlide?.();
  }, [onGoToNextSlide]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View>
      {minWidth.isSM && hasPrev ? (
        <IconLink
          name="IconArrowLeft"
          palette="light-semi-transparent"
          width={CAROUSEL_NAV_BTN_SIZE}
          height={CAROUSEL_NAV_BTN_SIZE}
          iconSize={CAROUSEL_NAV_BTN_ICON_SIZE}
          onPress={goToPrevSlide}
          accessibilityLabel={formatMessage(messages.prevSlideBtnA11yLabel)}
          style={[styles.navBtn, styles.navBtnPrev]}
        />
      ) : null}

      {minWidth.isSM && hasNext ? (
        <IconLink
          name="IconArrowRight"
          palette="light-semi-transparent"
          width={CAROUSEL_NAV_BTN_SIZE}
          height={CAROUSEL_NAV_BTN_SIZE}
          iconSize={CAROUSEL_NAV_BTN_ICON_SIZE}
          onPress={goToNextSlide}
          accessibilityLabel={formatMessage(messages.nextSlideBtnA11yLabel)}
          style={[styles.navBtn, styles.navBtnNext]}
        />
      ) : null}

      <Carousel
        carouselRef={carouselRef}
        navControlAccessibilityLabel={formatMessage(
          messages.goToSlideA11yLabel,
        )}
        onActiveSlideChange={onActiveSlideChange}
        colors={CAROUSEL_COLORS}
        transitionEffect="none"
        layout="compact"
      >
        {slides}
      </Carousel>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const CAROUSEL_COLORS: CarouselColors = {
  active: {
    idle: theme.colors.WHITE,
    hover: theme.colors.CREAM,
  },
  inactive: {
    idle: theme.colors.OPACITY.OATMEAL.DARKER,
    hover: theme.colors.CREAM,
  },
};
const CAROUSEL_NAV_BTN_SIZE = 60;
const CAROUSEL_NAV_BTN_ICON_SIZE = 40;

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  prevSlideBtnA11yLabel: {
    defaultMessage: 'Go to previous offer',
    description:
      'Loyalty Offers | Carousel | Previous slide button | a11y label',
  },
  nextSlideBtnA11yLabel: {
    defaultMessage: 'Go to next offer',
    description: 'Loyalty Offers | Carousel | Next slide button | a11y label',
  },
  goToSlideA11yLabel: {
    defaultMessage: 'Go to slide',
    description: 'Loyalty Offers | Carousel | Go to slide a11y label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  navBtn: {
    position: 'absolute',
    top: '25%',
    zIndex: 1,
  },
  navBtnPrev: {
    left: theme.spacing['4'],
  },
  navBtnNext: {
    right: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOffersCarouselProps = PropsWithChildren<{
  onActiveSlideIndexChange?: (activeSlideIndex: number) => void;
  onGoToPrevSlide?: () => void;
  onGoToNextSlide?: () => void;
}>;

type CarouselColors = NonNullable<ComponentProps<typeof Carousel>['colors']>;

type CarouselRef = NonNullable<
  ComponentProps<typeof Carousel>['carouselRef']
>['current'];

/* eslint-disable functional/immutable-data */

import { BirthdayPicker } from './FormBirthdayPicker';
import { Checkbox } from './FormCheckbox';
import { Chip, ChipMenu } from './FormChip';
import { Container } from './FormContainer';
import { Group } from './FormGroup';
import { NumberField } from './FormNumberField';
import { NumberFieldCode } from './FormNumberFieldCode';
import { Picker } from './FormPicker';
import { PickersGroup } from './FormPickersGroup';
import { ResponsiveSelect } from './FormResponsiveSelect';
import { Select } from './FormSelect';
import { Stepper } from './FormStepper';
import { SubmitButton } from './FormSubmitButton';
import { Subtitle } from './FormSubtitle';
import { Switch } from './FormSwitch';
import {
  TextField,
  TextFieldPassword,
  TextFieldPhoneNumber,
  TextFieldUppercase,
  TextFieldWithBackground,
  TextFieldZipCode,
} from './FormTextField';
import { Title } from './FormTitle';

export const Form = () => null;
Form.Chip = Chip;
Form.ChipMenu = ChipMenu;
Form.Container = Container;
Form.Group = Group;
Form.NumberField = NumberField;
Form.NumberFieldCode = NumberFieldCode;
Form.BirthdayPicker = BirthdayPicker;
Form.Picker = Picker;
Form.PickersGroup = PickersGroup;
Form.ResponsiveSelect = ResponsiveSelect;
Form.Select = Select;
Form.SubmitButton = SubmitButton;
Form.Subtitle = Subtitle;
Form.Stepper = Stepper;
Form.Switch = Switch;
Form.Checkbox = Checkbox;
Form.TextField = TextField;
Form.TextFieldWithBackground = TextFieldWithBackground;
Form.TextFieldPassword = TextFieldPassword;
Form.TextFieldZipCode = TextFieldZipCode;
Form.TextFieldUppercase = TextFieldUppercase;
Form.TextFieldPhoneNumber = TextFieldPhoneNumber;
Form.Title = Title;

import React, { type ComponentProps } from 'react';
import { BodyText } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoText = (props: ComponentProps<typeof BodyText>) => {
  const { children, ...restProps } = props;

  return (
    <BodyText sizeMatch={['16']} {...restProps}>
      {children}
    </BodyText>
  );
};

import React, { type ReactNode } from 'react';
import { Text } from 'react-native';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuHeaderDetailsTextItem = (
  props: MenuHeaderDetailsTextItemProps,
) => {
  const { children, withSeparator } = props;

  return (
    <Text>
      {withSeparator ? <Text> • </Text> : null}
      <Text>{children}</Text>
    </Text>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuHeaderDetailsTextItemProps = {
  children: ReactNode;
  withSeparator?: boolean;
};

import React, { type PropsWithChildren } from 'react';
import { HStack, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuDietaryPreferencesButtonsContainer = (
  props: PropsWithChildren,
) => {
  const { match } = useResponsive();

  return (
    <HStack gap={theme.spacing['4']} itemsPerRow={match([2, 3])}>
      {props.children}
    </HStack>
  );
};

import { useEffect, useMemo, useState } from 'react';
import type {
  Asset,
  ContentfulClientApi,
  CreateClientParams,
  Entry,
  TagLink,
} from 'contentful';

import { useFeatureFlag } from '@order/LaunchDarkly';
import { getEnvVars } from '@order/utils';

export const useContentfulClient = () => {
  const isContentfulEnabled = useFeatureFlag('OPS-contentful-enabled');

  // Feature-flag based configuration for preview / environments.
  const contentfulParams = useContentfulParams();

  // Create contentful client instance based on feature flag.
  const [contentfulClient, setContentfulClient] = useState<
    ContentfulClientApi | undefined
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  >(isContentfulEnabled ? createClient(contentfulParams) : undefined);

  // Attempt to initialize contentful client in once feature flag is enabled.
  useEffect(() => {
    if (!isContentfulEnabled || contentfulClient !== undefined) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setContentfulClient(createClient(contentfulParams));
  }, [contentfulClient, contentfulParams, isContentfulEnabled]);

  return isContentfulEnabled ? contentfulClient : undefined;
};

// ─── Utils ───────────────────────────────────────────────────────────────────

const {
  CONTENTFUL_SWEETGREEN_SPACE_ID,
  CONTENTFUL_CONTENT_DELIVERY_API_ACCESS_TOKEN,
  CONTENTFUL_CONTENT_PREVIEW_API_ACCESS_TOKEN,
  CONTENTFUL_ENVIRONMENT,
} = getEnvVars();

const DEFAULT_CREATE_CONTENTFUL_CLIENT_PARAMS: CreateClientParams = {
  space: CONTENTFUL_SWEETGREEN_SPACE_ID,
  accessToken: CONTENTFUL_CONTENT_DELIVERY_API_ACCESS_TOKEN,
};

const PREVIEW_CREATE_CONTENTFUL_CLIENT_PARAMS: CreateClientParams = {
  host: 'preview.contentful.com',
  space: CONTENTFUL_SWEETGREEN_SPACE_ID,
  accessToken: CONTENTFUL_CONTENT_PREVIEW_API_ACCESS_TOKEN,
};

/**
 * Feature Flag / Environment based configuration for preview / environments.
 * Selects preview / non-preview based on permanent-contentful-preview-enabled.
 * Selects environment based on the CONTENTFUL_ENVIRONMENT env var.
 * Defaults to non-preview master.
 */
const useContentfulParams = () => {
  const isPreviewEnabled = useFeatureFlag(
    'permanent-contentful-preview-enabled',
  );

  return useMemo(
    () => ({
      ...(isPreviewEnabled
        ? PREVIEW_CREATE_CONTENTFUL_CLIENT_PARAMS
        : DEFAULT_CREATE_CONTENTFUL_CLIENT_PARAMS),
      environment: CONTENTFUL_ENVIRONMENT ?? 'development',
    }),
    [isPreviewEnabled],
  );
};

/**
 * Require instead of import to avoid issues with iOS simulator.
 * Furthermore, this loads the Contenful client conditionally, so it is not loaded for users who do not have access to it.
 */
const createClient = (params: CreateClientParams) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return require('contentful/dist/contentful.browser.min.js').createClient(
    params,
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

export type ContentfulAsset = Asset;
export type ContentfulEntry<T> = Entry<T>;
export type ContentfulTag = TagLink;

import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import type { IconName } from '../Icon';
import { IconLink } from '../Icon';

export enum SocialMedia {
  instagram = 'instagram',
  facebook = 'facebook',
  tiktok = 'tiktok',
  twitter = 'twitter',
  youtube = 'youtube',
}

export const SocialMediaButton = ({
  socialMedia,
  accessibilityLabel,
  accessibilityHint,
  onPress,
}: SocialMediaButtonProps) => {
  return (
    <View style={styles.socialMedia}>
      <IconLink
        testID={`${socialMedia}-button`}
        name={IconNameMapping[socialMedia]}
        color={theme.colors.NEUTRAL_1}
        width={24}
        height={24}
        accessibilityRole="button"
        accessibilityLabel={accessibilityLabel}
        accessibilityHint={accessibilityHint}
        onPress={onPress}
      />
    </View>
  );
};

const IconNameMapping: Record<SocialMedia, IconName> = {
  [SocialMedia.instagram]: 'IconInstagram',
  [SocialMedia.facebook]: 'IconFacebook',
  [SocialMedia.tiktok]: 'IconTikTok',
  [SocialMedia.twitter]: 'IconTwitter',
  [SocialMedia.youtube]: 'IconYouTube',
};

const styles = StyleSheet.create({
  socialMedia: {
    padding: theme.spacing['1'],
    marginHorizontal: theme.spacing['1'],
    borderRadius: theme.radius.xxxlarge,
    borderWidth: 1,
    borderColor: theme.colors.DARK_KALE,
  },
});

type SocialMediaButtonProps = Readonly<{
  socialMedia: SocialMedia;
  onPress: () => void;
}> &
  ViewProps;

export const sweetpassBenefitsTitleFallback = 'What you get with Sweetpass';
export const sweetpassBenefitsFallback = [
  {
    type: 'sweetpassBenefitsList',
    id: '5iGTIQmAaiS6bkKbsKV5i6',
    title: 'Your benefits',
    benefits: [
      {
        type: 'sweetpassBenefitsBenefit',
        id: '2ayetHYYSV2vL8s3iwPtne',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/5CZHiZQIKqZBu73Ow2BGjs/44f15982df62cec43c198230b708d9b1/upgrade-benefit.all-sweetpass-benefits.png',
        },
        titleText: 'Rewards + Challenges',
        bodyText:
          'Opt in and complete delicious challenges to savor more rewards.',
      },
      {
        type: 'sweetpassBenefitsBenefit',
        id: 'TbUnTR2dMQDOss2z6kWNd',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/2Cm6rx9eiqunKy7uj28ORZ/7d050c8df32495b8a110118e5ebdbf83/upgrade-benefit.birthday-gift.png',
        },
        titleText: 'Birthday\nGift',
        bodyText: 'Celebrate your special day with something sweet from us. ',
      },
      {
        type: 'sweetpassBenefitsBenefit',
        id: '1D5PNPrWRPKbqdKDMIhb1b',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/6FLiQI94jm6LTfCoq2TnWE/8925221ac656c4b84adf662a4c9dcf2e/upgrade-benefit.sweetgreen-exclusives.png',
        },
        titleText: 'Sweetpass Bowl Drops',
        bodyText:
          'Get special access to online-only menu drops exclusive to Sweetpass members.',
      },
      {
        type: 'sweetpassBenefitsBenefit',
        id: '2Pzk5yYjD27w8V194QKucK',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/6uJ1H7kwsPrQfIBNfv9WP1/125f29f4e6ed23d482244b32ddff66e8/upgrade-benefit.limited-edition-drops.png',
        },
        titleText: 'Merch\nDrops',
        bodyText: 'Get access to limited-edition sweetgreen merch.',
      },
    ],
    palette: 'regular',
  },
  {
    type: 'sweetpassBenefitsList',
    id: '4RNHolwcLnF4tfm2IwQWtG',
    title: 'Get more with Sweetpass+',
    benefits: [
      {
        type: 'sweetpassBenefitsBenefit',
        id: '1Ku3llsIWqpzRgdCd2fxdO',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/6rwpBaQXri88ZNj0Q3keom/6cd4464fe01d7fba6b1825da248416fe/upgrade-benefit.3-dollars-off-every-digital-order.png',
        },
        titleText: 'Daily $3\nOff\n',
        bodyText:
          'Unlock daily savings with us on eligible pick up, outpost, and delivery orders.',
      },
      {
        type: 'sweetpassBenefitsBenefit',
        id: '2WIi1gNvREZa1H7pyq3wiW',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/58L1vpwaiRzNIU8JHIXIFn/0505bd7283a661177816f8c6cde9c959/upgrade-benefit.friends-of-earth.png',
        },
        titleText: 'Friends of Earth Perk',
        bodyText:
          'Help us choose a carbon reduction partner to donate to on behalf of our Sweetpass+ members and participate in earth-friendly challenges.',
      },
      {
        type: 'sweetpassBenefitsBenefit',
        id: '3DCJL2iK3peRbs7uzviLro',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/1B8jsR58awsAvibsj9UrKo/316744308736a5d6742c19432e7e0116/upgrade-benefit.sweetgreen-insiders.png',
        },
        titleText: 'Sweetgreen Insiders',
        bodyText:
          'Join exclusive sweetgreen experiences and be a part of a community that shapes the future of food.',
      },
      {
        type: 'sweetpassBenefitsBenefit',
        id: '63axIJCSY0yXqGtU6tlMxl',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/4L4tS6Jk86hCQwtGGfCBmo/0eeb041c911d224833240aea17fb7f9d/PickUp1.png',
        },
        titleText: 'Sweetpass\nSupport',
        bodyText:
          'Serving up fast customer service response times and fresh solutions when you need assistance.',
      },
    ],
    palette: 'greyed-out',
  },
  {
    type: 'sweetpassBenefitsCta',
    id: '1c4Y168UsemH3paQ5rFztz',
    title: 'Upgrade to Sweetpass+',
    action: 'upgrade_to_sweetpass_plus',
    palette: 'limeOnGreen',
  },
  {
    type: 'sweetpassBenefitsCardsSection',
    id: '4FE1Z7aWztpNJjB3n77sMd',
    cards: [
      {
        type: 'sweetpassBenefitsCard',
        id: '6hJladNToQkgzghLAIbmJG',
        title: 'FAQ',
        description: 'You got questions? \nWe got answers. ',
        cta: {
          type: 'sweetpassBenefitsCardCta',
          id: '7DhkAnJMFpEVhaYD4d8iOU',
          title: 'View All',
          action: 'view-all',
        },
        palette: 'cucumber',
      },
      {
        type: 'sweetpassBenefitsCard',
        id: '41LNZz0vDmHdHq45Sqb19h',
        title: 'MANAGE',
        description: 'Edit your membership settings anytime',
        cta: {
          type: 'sweetpassBenefitsCardCta',
          id: '26JoGvoNi5dzI3j9rQedxP',
          title: 'Edit',
          action: 'edit',
        },
        palette: 'quinoa',
      },
    ],
  },
];

export const sweetpassPlusBenefitsTitleFallback =
  'What you get with Sweetpass+';
export const sweetpassPlusBenefitsFallback = [
  {
    type: 'sweetpassBenefitsList',
    id: '67AhIrGOPtewGfIugVC8ZU',
    title: 'Your benefits',
    benefits: [
      {
        type: 'sweetpassBenefitsBenefit',
        id: '1Ku3llsIWqpzRgdCd2fxdO',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/6rwpBaQXri88ZNj0Q3keom/6cd4464fe01d7fba6b1825da248416fe/upgrade-benefit.3-dollars-off-every-digital-order.png',
        },
        titleText: 'Daily $3\nOff\n',
        bodyText:
          'Unlock daily savings with us on eligible pick up, outpost, and delivery orders.',
      },
      {
        type: 'sweetpassBenefitsBenefit',
        id: '2WIi1gNvREZa1H7pyq3wiW',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/58L1vpwaiRzNIU8JHIXIFn/0505bd7283a661177816f8c6cde9c959/upgrade-benefit.friends-of-earth.png',
        },
        titleText: 'Friends of Earth Perk',
        bodyText:
          'Help us choose a carbon reduction partner to donate to on behalf of our Sweetpass+ members and participate in earth-friendly challenges.',
      },
      {
        type: 'sweetpassBenefitsBenefit',
        id: '3DCJL2iK3peRbs7uzviLro',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/1B8jsR58awsAvibsj9UrKo/316744308736a5d6742c19432e7e0116/upgrade-benefit.sweetgreen-insiders.png',
        },
        titleText: 'Sweetgreen Insiders',
        bodyText:
          'Join exclusive sweetgreen experiences and be a part of a community that shapes the future of food.',
      },
      {
        type: 'sweetpassBenefitsBenefit',
        id: '63axIJCSY0yXqGtU6tlMxl',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/4L4tS6Jk86hCQwtGGfCBmo/0eeb041c911d224833240aea17fb7f9d/PickUp1.png',
        },
        titleText: 'Sweetpass\nSupport',
        bodyText:
          'Serving up fast customer service response times and fresh solutions when you need assistance.',
      },
      {
        type: 'sweetpassBenefitsBenefit',
        id: '2ayetHYYSV2vL8s3iwPtne',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/5CZHiZQIKqZBu73Ow2BGjs/44f15982df62cec43c198230b708d9b1/upgrade-benefit.all-sweetpass-benefits.png',
        },
        titleText: 'Rewards + Challenges',
        bodyText:
          'Opt in and complete delicious challenges to savor more rewards.',
      },
      {
        type: 'sweetpassBenefitsBenefit',
        id: 'TbUnTR2dMQDOss2z6kWNd',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/2Cm6rx9eiqunKy7uj28ORZ/7d050c8df32495b8a110118e5ebdbf83/upgrade-benefit.birthday-gift.png',
        },
        titleText: 'Birthday\nGift',
        bodyText: 'Celebrate your special day with something sweet from us. ',
      },
      {
        type: 'sweetpassBenefitsBenefit',
        id: '1D5PNPrWRPKbqdKDMIhb1b',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/6FLiQI94jm6LTfCoq2TnWE/8925221ac656c4b84adf662a4c9dcf2e/upgrade-benefit.sweetgreen-exclusives.png',
        },
        titleText: 'Sweetpass Bowl Drops',
        bodyText:
          'Get special access to online-only menu drops exclusive to Sweetpass members.',
      },
      {
        type: 'sweetpassBenefitsBenefit',
        id: '2Pzk5yYjD27w8V194QKucK',
        image: {
          description: '',
          url: '//images.ctfassets.net/wme4s8lvzccr/6uJ1H7kwsPrQfIBNfv9WP1/125f29f4e6ed23d482244b32ddff66e8/upgrade-benefit.limited-edition-drops.png',
        },
        titleText: 'Merch\nDrops',
        bodyText: 'Get access to limited-edition sweetgreen merch.',
      },
    ],
    palette: 'regular',
  },
  {
    type: 'sweetpassBenefitsCardsSection',
    id: '4FE1Z7aWztpNJjB3n77sMd',
    cards: [
      {
        type: 'sweetpassBenefitsCard',
        id: '6hJladNToQkgzghLAIbmJG',
        title: 'FAQ',
        description: 'You got questions? \nWe got answers. ',
        cta: {
          type: 'sweetpassBenefitsCardCta',
          id: '7DhkAnJMFpEVhaYD4d8iOU',
          title: 'View All',
          action: 'view-all',
        },
        palette: 'cucumber',
      },
      {
        type: 'sweetpassBenefitsCard',
        id: '41LNZz0vDmHdHq45Sqb19h',
        title: 'MANAGE',
        description: 'Edit your membership settings anytime',
        cta: {
          type: 'sweetpassBenefitsCardCta',
          id: '26JoGvoNi5dzI3j9rQedxP',
          title: 'Edit',
          action: 'edit',
        },
        palette: 'quinoa',
      },
    ],
  },
];

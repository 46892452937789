/* eslint-disable functional/immutable-data */

import type { ReactNode } from 'react';
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { ViewToken } from 'react-native';

// ────────────────────────────────────────────────────────────────────────────────

export const useRailViewableState = (railItems: readonly ReactNode[]) => {
  const [viewableState, setViewableState] = useState(INITIAL_VIEWABLE_STATE);

  // ─────────────────────────────────────────────────────────────────

  const itemsCountRef = useRailItemsCount(railItems);

  const trackViewableState = useCallback(
    (viewableInfo: RailViewableInfo) => {
      const { viewableItems } = viewableInfo;

      if (viewableItems.length === 0) return;

      const { current: allItemsCount } = itemsCountRef;

      const allItemsAreVisible = allItemsCount === viewableItems.length;
      const prevItemIndex = getPrevRailItemIndex(viewableItems);
      const nextItemIndex = getNextRailItemIndex(viewableItems, allItemsCount);
      const isAtStart = prevItemIndex === null;
      const isAtEnd = nextItemIndex === null;

      setViewableState({
        allItemsAreVisible,
        prevItemIndex,
        nextItemIndex,
        isAtStart,
        isAtEnd,
      });
    },
    [itemsCountRef],
  );

  return useMemo(
    () => ({ viewableState, trackViewableState }),
    [trackViewableState, viewableState],
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const useRailItemsCount = (railItems: readonly ReactNode[]) => {
  const itemsCountRef = useRef<number>(React.Children.count(railItems));

  useLayoutEffect(() => {
    itemsCountRef.current = React.Children.count(railItems);
  }, [railItems]);

  return itemsCountRef;
};

// ─── UTILS ──────────────────────────────────────────────────────────────────────

function getPrevRailItemIndex(viewableItems: ViewTokens) {
  const firstViewableItemIndex = viewableItems?.[0]?.index ?? -1;

  return firstViewableItemIndex > 0 ? firstViewableItemIndex - 1 : null;
}

function getNextRailItemIndex(
  viewableItems: ViewTokens,
  allItemsCount: number,
) {
  const lastViewableItemIndex =
    viewableItems?.[viewableItems.length - 1]?.index;
  const lastItemIndex = allItemsCount - 1;

  if (lastViewableItemIndex === null) return null;

  return lastViewableItemIndex < lastItemIndex
    ? lastViewableItemIndex + 1
    : null;
}

// ─── CONSTANTS ──────────────────────────────────────────────────────────────────

const INITIAL_VIEWABLE_STATE: RailViewableState = {
  allItemsAreVisible: true,
  prevItemIndex: null,
  nextItemIndex: null,
  isAtStart: true,
  isAtEnd: true,
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type RailViewableState = Readonly<{
  allItemsAreVisible: boolean;
  prevItemIndex: number | null;
  nextItemIndex: number | null;
  isAtStart: boolean;
  isAtEnd: boolean;
}>;

type RailViewableInfo = Readonly<{
  viewableItems: ViewTokens;
}>;

type ViewTokens = readonly ViewToken[];

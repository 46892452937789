import {
  useContentfulContentTypeEntries,
  useContentfulContentTypeEntry,
} from '@order/Contentful';

import {
  validAccountMenuData as accountMenuDataFallback,
  validContentfulData as orgLinksDataFallback,
} from './fallback';

export function useOrganizationLinksFooterContentfulData() {
  const { data: possibleContentfulData } =
    useContentfulContentTypeEntry<ContentfulOrganizationLinksFooter>({
      contentType: ORGANIZATION_LINKS_FOOTER_CONTENT_ID,
      include: 2,
    });

  // ─── Live Contentful Footer Data ─────────────────────────────────────

  const liveFooter = convertFooter(possibleContentfulData?.fields);

  if (liveFooter.sections.length > 0) {
    return liveFooter;
  }

  // ─── Fallback Contentful Footer Data ─────────────────────────────────

  const fallbackFooter = convertFooter(orgLinksDataFallback.fields);

  return fallbackFooter;
}

export function useOrganizationLinksAccountMenuContentfulData() {
  const { data: possibleContentfulData } =
    useContentfulContentTypeEntries<ContentfulOrganizationLinksFooterSectionData>(
      {
        contentType: ORGANIZATION_LINKS_FOOTER_SECTION_CONTENT_ID,
        include: 2,
      },
    );

  const accountMenuData = possibleContentfulData?.find(
    (data) => data?.fields?.name === ORGANIZATION_LINKS_ACCOUNT_MENU,
  );

  if (!accountMenuData?.fields) {
    return convertSection(accountMenuDataFallback);
  }

  return convertSection(
    accountMenuData as { fields: ContentfulOrganizationLinksFooterSectionData },
  );
}

// ─── Converters ────────────────────────────────────────────────────────

function convertFooter(
  footer?: ContentfulOrganizationLinksFooter,
): ContentfulOrganizationLinksFooterData {
  const sections = footer?.sections ?? [];

  return { sections: sections.filter(hasSectionFields).map(convertSection) };
}

function convertSection(section: ContentfulOrganizationLinksFooterSection) {
  return {
    name: section.fields.name,
    links: section.fields.links.filter(isLinkValid).map(convertLink),
  };
}

function convertLink(link: ContentfulOrganizationLinksFooterLink) {
  return {
    label: link.fields.label,
    url: link.fields.url,
    specialHandler: link.fields.specialHandler,
    icon: link.fields.icon?.fields?.file?.url,
  };
}

function hasSectionFields(section: ContentfulOrganizationLinksFooterSection) {
  return Boolean(section.fields);
}

function isLinkValid(link: ContentfulOrganizationLinksFooterLink) {
  const isActionUnsupported = Boolean(
    link?.fields?.specialHandler &&
      !isOrganizationLinkFooterActionSupported(link.fields.specialHandler),
  );

  return Boolean(link.fields) && !isActionUnsupported;
}

// ─── Types ─────────────────────────────────────────────────────────────

type ContentfulOrganizationLinksFooter = Readonly<{
  name?: string;
  sections: readonly ContentfulOrganizationLinksFooterSection[];
}>;

type ContentfulOrganizationLinksFooterSectionData = Readonly<{
  name: string;
  links: readonly ContentfulOrganizationLinksFooterLink[];
}>;

type ContentfulOrganizationLinksFooterSection = Readonly<{
  name?: string;
  fields: ContentfulOrganizationLinksFooterSectionData;
}>;

type ContentfulOrganizationLinksFooterLink = Readonly<{
  name?: string;
  fields: {
    label: string;
    url: string;
    specialHandler?: 'kustomer';
    icon?: { fields?: { file?: { url?: string } } };
  };
}>;

type ContentfulOrganizationLinksFooterData = Readonly<{
  sections: ReadonlyArray<
    Readonly<{
      name: string;
      links: ReadonlyArray<
        Readonly<{
          label: string;
          url: string;
          specialHandler?: 'kustomer';
          icon?: string;
        }>
      >;
    }>
  >;
}>;

// ─── Constants ─────────────────────────────────────────────────────────

const ORGANIZATION_LINKS_FOOTER_CONTENT_ID = 'organizationLinksFooter';
const ORGANIZATION_LINKS_FOOTER_SECTION_CONTENT_ID =
  'organizationLinksFooterSection';
const ORGANIZATION_LINKS_ACCOUNT_MENU = 'Account Menu';

/**
 * Actions for the "Organization Links Footer".
 *
 * kustomer           | opens the kustomer chat integration
 */

export const SupportedOrganizationLinkFooterActions = {
  Kustomer: 'kustomer',
};

export function isOrganizationLinkFooterActionSupported(action?: string) {
  return Boolean(
    action &&
      Object.values(SupportedOrganizationLinkFooterActions).includes(action),
  );
}

/* eslint-disable react-native/no-unused-styles */

import React from 'react';
import type { TextStyle, ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { HorizontalScrollRailCount } from '../../HorizontalScrollRail/subcomponents/HorizontalScrollRailCount';
import { Icon, type IconName } from '../../Icon';
import type { TextProps } from '../../Text';
import { BodyText, DisplayText } from '../../Text';

// ────────────────────────────────────────────────────────────────────────────────

export const SectionHeader = (props: SectionHeaderProps) => {
  const {
    heading,
    headingIcon,
    subtitle,
    subtitleStyle,
    count,
    children,
    variation = 'body',
    withoutBorder,
    palette = 'dark',
    style,
    titleStyle,
  } = props;
  const hasCounter = Boolean(count);

  const TitleWrapper = hasCounter ? Row : React.Fragment;

  return (
    <>
      <Wrapper withoutBorder={withoutBorder} style={style}>
        <TitleWrapper>
          <View style={styles.titleLabel}>
            {headingIcon ? (
              <Icon
                name={headingIcon}
                color={theme.colors.DARK_KALE}
                width={24}
                height={24}
              />
            ) : null}

            <Title variation={variation} palette={palette} style={titleStyle}>
              {heading}
            </Title>
          </View>

          <HorizontalScrollRailCount count={count ?? 0} />
        </TitleWrapper>

        <ChildrenWrapper>{children}</ChildrenWrapper>
      </Wrapper>

      <Subtitle style={subtitleStyle} subtitle={subtitle} palette={palette} />
    </>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const Row = ({ children }: React.PropsWithChildren) => (
  <View style={styles.titleRuler}>{children}</View>
);

const Wrapper = (props: WrapperProps) => {
  const { withoutBorder, style, children } = props;
  const wrapperStyle = useWrapperStyle({ withoutBorder, style });

  return (
    <View style={wrapperStyle} accessibilityRole="header">
      {children}
    </View>
  );
};

const Subtitle = (props: SubtitleProps) => {
  const { subtitle, style, palette } = props;

  const paletteStyle = paletteTextStyles[palette];

  if (!subtitle) return null;

  return (
    <BodyText style={[styles.subtitle, paletteStyle, style]} size={3}>
      {subtitle}
    </BodyText>
  );
};

const Title = (props: React.PropsWithChildren<TitleProps>) => {
  const { children, variation, palette, style } = props;

  const paletteStyle = paletteTextStyles[palette];

  if (variation === 'display') {
    return (
      <DisplayText style={[paletteStyle, style]} sizeMatch={['32']}>
        {children}
      </DisplayText>
    );
  }

  return (
    <BodyText style={paletteStyle} sizeMatch={['14']}>
      {children}
    </BodyText>
  );
};

const ChildrenWrapper = (props: React.PropsWithChildren) => {
  if (!checkIfHasChildren(props.children)) return null;

  return <View style={styles.childrenWrapper}>{props.children}</View>;
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  wrapperWithBorder: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.GRAY,
    paddingBottom: 6,
  },

  titleRuler: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['4'],
  },
  titleLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['2'],
  },

  childrenWrapper: {
    paddingLeft: theme.spacing['4'],
  },
  subtitle: {
    paddingTop: theme.spacing['4'],
  },
});

const paletteTextStyles = StyleSheet.create<Record<Palette, TextStyle>>({
  light: {
    color: theme.colors.WHITE,
  },
  dark: {
    color: theme.colors.CHARCOAL,
  },
});

const useWrapperStyle = (props: Omit<WrapperProps, 'children'>) => {
  const { withoutBorder, style } = props;

  const optionalBorderStyle = withoutBorder ? {} : styles.wrapperWithBorder;

  return [styles.wrapper, optionalBorderStyle, style];
};

// ─── UTILS ──────────────────────────────────────────────────────────────────────

function checkIfHasChildren(children: React.ReactNode) {
  return React.Children.count(children) > 0;
}

// ────────────────────────────────────────────────────────────────────────────────

type SectionHeaderProps = Readonly<{
  heading: string;
  headingIcon?: IconName;
  subtitle?: string;
  subtitleStyle?: TextProps['style'];
  variation?: 'body' | 'display';
  withoutBorder?: boolean;
  count?: number;
  children?: React.ReactNode;
  palette?: Palette;
  titleStyle?: TextStyle;
  style?: ViewProps['style'];
}>;

type WrapperProps = Pick<
  SectionHeaderProps,
  'withoutBorder' | 'style' | 'children'
>;

type TitleProps = { palette: Palette; style?: TextStyle } & Pick<
  SectionHeaderProps,
  'variation'
>;

type SubtitleProps = { palette: Palette } & Pick<
  SectionHeaderProps,
  'subtitle'
> &
  Readonly<{
    style?: TextProps['style'];
  }>;

type Palette = 'light' | 'dark';

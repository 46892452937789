import React, { type ComponentProps, forwardRef } from 'react';
import type { Text as RNText } from 'react-native';
import { StyleSheet } from 'react-native';
import { TYPE_CONFIG, WEIGHT_MAP } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { Text } from '../Text';
import { useFluidTextStyles } from '../useFluidTextStyles';

// ─────────────────────────────────────────────────────────────────────────────

export const LabelText = forwardRef<RNText, LabelTextProps>((props, ref) => {
  const { size, sizeMatch = [], children, ...restProps } = props;

  if (sizeMatch.length > 0) {
    return (
      <LabelTextStatic ref={ref} sizeMatch={sizeMatch} {...restProps}>
        {children}
      </LabelTextStatic>
    );
  }

  return (
    <LabelTextFluid ref={ref} size={size} {...restProps}>
      {children}
    </LabelTextFluid>
  );
});

// ─────────────────────────────────────────────────────────────────────────────

const LabelTextStatic = forwardRef<RNText, LabelTextStaticProps>(
  (props, ref) => {
    const { children, sizeMatch, bold, style, ...restProps } = props;

    const { match } = useResponsive();

    const fontSize = match(sizeMatch);
    const fontSizeStyles =
      LABEL_TEXT_STYLES[fontSize] ?? LABEL_TEXT_DEFAULT_FONT_STYLES;
    const boldTextStyles = bold ? styles.boldText : undefined;
    const allStyles = [fontSizeStyles, boldTextStyles, style];

    return (
      <Text ref={ref} style={allStyles} {...restProps}>
        {children}
      </Text>
    );
  },
);

const LabelTextFluid = forwardRef<RNText, LabelTextFluidProps>((props, ref) => {
  const {
    children,
    size = LABEL_TEXT_DEFAULT_ORDINAL_SIZE,
    bold,
    style,
    ...restProps
  } = props;

  const fontSize = LABEL_TEXT_PRESET_MAP[size];
  const textStyles =
    LABEL_TEXT_STYLES[fontSize] ?? LABEL_TEXT_DEFAULT_FONT_STYLES;
  const fluidTextStyles = useFluidTextStyles(
    LABEL_TEXT_PRESET_MAP[size],
    'LABEL',
  );
  const boldTextStyles = bold ? styles.boldText : undefined;
  const allStyles = [textStyles, boldTextStyles, fluidTextStyles, style];

  return (
    <Text ref={ref} style={allStyles} {...restProps}>
      {children}
    </Text>
  );
});

// ─── Constants ───────────────────────────────────────────────────────────────

const LABEL_TEXT_STYLES = TYPE_CONFIG.LABEL;

const LABEL_TEXT_PRESET_MAP: Record<number, LabelTextFontSize> = {
  1: '14',
  2: '12',
  3: '10',
};

const LABEL_TEXT_DEFAULT_ORDINAL_SIZE: LabelTextSizeOrdinal = 1;
const LABEL_TEXT_DEFAULT_FONT_SIZE: LabelTextFontSize = '14';

const LABEL_TEXT_DEFAULT_FONT_STYLES =
  LABEL_TEXT_STYLES[LABEL_TEXT_DEFAULT_FONT_SIZE];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  boldText: {
    fontWeight: WEIGHT_MAP.bold,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LabelTextProps = Readonly<{
  size?: LabelTextSizeOrdinal;
  sizeMatch?: LabelTextFontSize[];
}> &
  LabelTextBaseProps;

type LabelTextStaticProps = Readonly<{
  sizeMatch: LabelTextFontSize[];
}> &
  LabelTextBaseProps;

type LabelTextFluidProps = Readonly<{
  size?: LabelTextSizeOrdinal;
}> &
  LabelTextBaseProps;

type LabelTextBaseProps = ComponentProps<typeof Text>;

type LabelTextSizeOrdinal = keyof typeof LABEL_TEXT_PRESET_MAP;

type LabelTextFontSize = keyof typeof LABEL_TEXT_STYLES;

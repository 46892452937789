import { useCallback, useMemo } from 'react';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useCustomer } from '@order/Customer';
import type { Order } from '@order/graphql';

import { useOrdersQuery } from '../GraphQL/Orders.generated';
import { ORDERS_QUERY_VARIABLES } from './constants';
import {
  checkIfDeliveryOrder,
  checkIfHasValidDeliveryAddress,
  getCustomerAddressesIds,
} from './useOrders.utils';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * Returns valid* orders from cache
 *
 * valid = either a pickup or outpost order or a delivery order with active delivery address.
 *
 * NOTE: Cache is used to prevent additional network requests.
 */
export const useValidOrders = () => {
  const isLoggedIn = useIsLoggedIn();

  const [ordersResponse, fetchValidOrders] = useOrdersQuery({
    variables: ORDERS_QUERY_VARIABLES,
    requestPolicy: 'cache-only',
    pause: !isLoggedIn,
  });

  const ordersData = ordersResponse.data?.orders;
  const orders = useMemo(() => {
    return ordersData?.__typename === 'OrdersResponseSuccess'
      ? ordersData.orders
      : [];
  }, [ordersData]);
  const isFetchingOrders = ordersResponse.fetching || ordersResponse.stale;

  const { customer } = useCustomer();

  const customerAddressesIds = useMemo(
    () => getCustomerAddressesIds(customer.addresses),
    [customer.addresses],
  );

  const fetchOrders = useCallback(() => {
    if (!isLoggedIn) return;

    fetchValidOrders({ requestPolicy: 'cache-and-network' });
  }, [fetchValidOrders, isLoggedIn]);

  // ─────────────────────────────────────────────────────────────────

  const validOrders = useMemo<Partial<readonly Order[]>>(() => {
    if (!Array.isArray(orders)) return [];

    return orders.filter((order) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const isDeliveryOrder = checkIfDeliveryOrder(order);
      const hasValidDeliveryAddress = checkIfHasValidDeliveryAddress(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        order,
        customerAddressesIds,
      );

      return !isDeliveryOrder || (isDeliveryOrder && hasValidDeliveryAddress);
    });
  }, [customerAddressesIds, orders]);

  return {
    orders: validOrders,
    fetchOrders,
    isFetchingOrders,
  };
};

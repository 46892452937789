import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { LabelText } from '../../../Text';
import type { PaletteProps } from '../../Modal.types';
import { ModalRow } from '../ModalRow';

export const ModalSubhead = (
  props: ComponentProps<typeof LabelText> & PaletteProps,
) => {
  const { children, palette, ...restProps } = props;

  const paletteSpecificTextStyles = palette
    ? paletteSubheadTextStyles[palette]
    : undefined;

  return (
    <ModalRow>
      <LabelText
        size={2}
        style={[styles.subhead, paletteSpecificTextStyles]}
        {...restProps}
      >
        {children}
      </LabelText>
    </ModalRow>
  );
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  subhead: {
    textTransform: 'uppercase',
    marginBottom: theme.spacing['4'],
  },
  subheadSecondary: {
    color: theme.colors.WHITE,
  },
});

// ─── Palette Styles ────────────────────────────────────────────────────

const paletteSubheadTextStyles = {
  primary: undefined,
  neutral: undefined,
  secondary: styles.subheadSecondary,
  lime: styles.subheadSecondary,
};

import type { Client } from 'urql';

import {
  addBroadcastEventsListener,
  broadcastLogInEvent,
  broadcastLogOutEvent,
  removeBroadcastEventsListener,
  resetCurrentCustomerData,
  setCurrentCustomerData,
} from './actions';
import { AuthModel } from './AuthMachine.model';
import { fetchCustomer } from './services';

// ─────────────────────────────────────────────────────────────────────────────

export const createAuthMachine = (options: CreateAuthMachineOptions) => {
  return AuthModel.createMachine(
    {
      predictableActionArguments: true, // https://xstate.js.org/docs/guides/actions.html
      context: AuthModel.initialContext,
      id: 'auth-machine',
      initial: 'idle',
      states: {
        idle: {
          on: {
            INIT_GUEST_SESSION: {
              target: '#auth-machine.session.loggedOut',
              actions: 'saveCsrfTokenToStorage',
            },
            INIT_LOGGED_IN_SESSION: {
              target: '#auth-machine.fetching.customer',
              actions: 'saveCsrfTokenToStorage',
            },
          },
        },
        fetching: {
          states: {
            customer: {
              id: 'fetching.customer',
              invoke: {
                id: 'fetchCustomer',
                src: async () => fetchCustomer(options),
                onDone: {
                  target: '#auth-machine.session.loggedIn',
                  actions: 'setCurrentCustomerData',
                },
                onError: {
                  target: '#auth-machine.session.loggedOut',
                },
              },
            },
          },
        },
        session: {
          states: {
            loggedOut: {
              on: {
                LOGIN: {
                  target: '#auth-machine.fetching.customer',
                  actions: ['saveCsrfTokenToStorage', 'broadcastLogInEvent'],
                },
              },
            },
            loggedIn: {
              on: {
                LOGOUT: {
                  target: 'loggedOut',
                  actions: [
                    'saveCsrfTokenToStorage',
                    'broadcastLogOutEvent',
                    'resetCurrentCustomerData',
                  ],
                },
              },
            },
          },
        },
      },
      entry: 'addBroadcastEventsListener',
      exit: 'removeBroadcastEventsListener',
      on: {
        UPDATE_CSRF_TOKEN: {
          actions: 'saveCsrfTokenToStorage',
        },
      },
    },
    {
      actions: {
        broadcastLogInEvent,
        broadcastLogOutEvent,
        addBroadcastEventsListener,
        removeBroadcastEventsListener,
        setCurrentCustomerData,
        // @ts-expect-error TS(2322): Type 'AssignAction<{ currentCustomer: null; }, Eve... Remove this comment to see the full error message
        resetCurrentCustomerData,
      },
    },
  );
};

// ─────────────────────────────────────────────────────────────────────────────

export type CreateAuthMachineOptions = {
  client: Client;
};

import React from 'react';

import { useTelemetry } from '@order/Telemetry';

import type { ReorderEvent } from '../../machines/Reorder.machine';

export const useReorderTelemetry = () => {
  const { track } = useTelemetry();

  const startReorderTelemetry = React.useCallback(
    (event: ReorderEvent, startingFrom: string, userInitiated?: boolean) => {
      track('reorder.start', {
        ...filterTelemetryData(event),
        startingFrom,
        userInitiated,
      });
    },
    [track],
  );

  const existingActiveBagTelemetry = React.useCallback(() => {
    track('reorder.existing_active_bag');
  }, [track]);

  const newOrderTelemetry = React.useCallback(() => {
    track('reorder.new_order_selected');
  }, [track]);

  const returnToBagTelemetry = React.useCallback(() => {
    track('reorder.return_to_bag_selected');
  }, [track]);

  const continueToBagTelemetry = React.useCallback(
    (event: ReorderEvent) => {
      track('reorder.continue_bag', filterTelemetryData(event));
    },
    [track],
  );

  const changeLocationTelemetry = React.useCallback(
    (event: ReorderEvent) => {
      track('reorder.change_location', filterTelemetryData(event));
    },
    [track],
  );

  const conflictReviewTelemetry = React.useCallback(
    (event: ReorderEvent) => {
      track('reorder.conflict_review', filterTelemetryData(event));
    },
    [track],
  );

  const cannotReorderTelemetry = React.useCallback(() => {
    track('reorder.cannot_reorder');
  }, [track]);

  const maxDeliveryTelemetry = React.useCallback(() => {
    track('reorder.max_delivery');
  }, [track]);

  const dressingUnavailableTelemetry = React.useCallback(() => {
    track('reorder.dressing_unavailable');
  }, [track]);

  const missingMenuTelemetry = React.useCallback(() => {
    track('reorder.missing_menu');
  }, [track]);

  const viewMenuTelemetry = React.useCallback(() => {
    track('reorder.view_menu');
  }, [track]);

  return {
    startReorderTelemetry,
    existingActiveBagTelemetry,
    newOrderTelemetry,
    returnToBagTelemetry,
    continueToBagTelemetry,
    changeLocationTelemetry,
    conflictReviewTelemetry,
    cannotReorderTelemetry,
    maxDeliveryTelemetry,
    dressingUnavailableTelemetry,
    missingMenuTelemetry,
    viewMenuTelemetry,
  };
};

const filterTelemetryData = (event: ReorderEvent) => {
  return {
    orderId: event.orderId,
    lineItemId: event.lineItemId,
    restaurantId: event.restaurantId,
    restaurantSlug: event.restaurantSlug,
    orderType: event.orderType,
    orderTotal: event.orderTotal,
    reorderResult: event.reorderResult,
    conflictType: event.conflictType,
  };
};

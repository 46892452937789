import { type RedirectRequest } from '@azure/msal-browser';
import { createURL } from 'expo-linking';

import { getAppVersion, getEnvVars } from '@order/utils';

// ─────────────────────────────────────────────────────────────────────────────

const {
  AZURE_AD_TENANT_ID,
  AZURE_AD_TENANT_HOST,
  AZURE_AD_ORDER_APP_CLIENT_ID,
  AZURE_AD_POLICY_NAME,
  AZURE_AD_API_APP_SCOPE_URL,
  AZURE_AD_VENDOR_API_APP_SCOPE_URL,
} = getEnvVars();

const authority = `https://${AZURE_AD_TENANT_HOST}/${AZURE_AD_TENANT_ID}/${AZURE_AD_POLICY_NAME}`;
const endpointBase = `${authority}/oauth2/v2.0`;

// ─── Constants ───────────────────────────────────────────────────────────────

export const AZURE_API_ACCESS_TOKEN_HEADER = 'ApiAuthorizationToken';
export const AZURE_VENDOR_ACCESS_TOKEN_HEADER = 'AuthorizationToken';
export const ORDER_APP_VERSION_SEARCH_PARAM = 'orderAppVersion';
export const ORDER_APP_BG_COLOR_SEARCH_PARAM = 'orderAppBgColor';

// ─────────────────────────────────────────────────────────────────────────────

export const config: Config = {
  clientId: AZURE_AD_ORDER_APP_CLIENT_ID,
  tenantHost: AZURE_AD_TENANT_HOST,
  tenantId: AZURE_AD_TENANT_ID,
  policyName: AZURE_AD_POLICY_NAME,
  authority,
  redirectUri: createURL('join-or-sign-in'),
  logoutRedirectUri: createURL('account/menu'),
  discovery: {
    tokenEndpoint: `${endpointBase}/token`,
    endSessionEndpoint: `${endpointBase}/logout`,
    authorizationEndpoint: `${endpointBase}/authorize`,
    userInfoEndpoint: `${authority}/openid/v2.0/userinfo`,
  },

  /**
   * See {@link https://docs.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes}
   */
  scopes: {
    apiLoginRequest: {
      scopes: ['openid', 'offline_access', AZURE_AD_API_APP_SCOPE_URL],
      extraQueryParameters: {
        [ORDER_APP_VERSION_SEARCH_PARAM]: getAppVersion(),
      },
    },
    vendorApiLoginRequest: {
      scopes: ['openid', AZURE_AD_VENDOR_API_APP_SCOPE_URL],
    },
  },
  cacheLocation: 'localStorage',

  // ─── Async Storage Keys ──────────────────────────────────────────────

  // Embedded Flow

  embeddedFlowRefreshTokenStorageKey: 'AD_EMBEDDED_FLOW_REFRESH_TOKEN',

  // Hybrid Flow

  hybridFlowRefreshTokenStorageKey: 'AD_HYBRID_FLOW_REFRESH_TOKEN',
  hybridFlowAccessTokenStorageKey: 'AD_HYBRID_FLOW_ACCESS_TOKEN',
  hybridFlowHasActiveAccountStorageKey: 'AD_HYBRID_FLOW_HAS_ACTIVE_ACCOUNT',
};

// ─── Types ───────────────────────────────────────────────────────────────────

type Config = {
  clientId: string;
  tenantHost: string;
  tenantId: string;
  policyName: string;
  authority: string;
  redirectUri: string;
  logoutRedirectUri: string;
  discovery: {
    tokenEndpoint: string;
    endSessionEndpoint: string;
    authorizationEndpoint: string;
    userInfoEndpoint: string;
  };
  scopes: Record<string, RedirectRequest>;
  cacheLocation: 'sessionStorage' | 'localStorage';
  embeddedFlowRefreshTokenStorageKey: string;
  hybridFlowRefreshTokenStorageKey: string;
  hybridFlowAccessTokenStorageKey: string;
  hybridFlowHasActiveAccountStorageKey: string;
};

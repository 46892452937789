import type { ReactNode } from 'react';
import React, { useCallback } from 'react';
import type { ImageSourcePropType, ViewProps } from 'react-native';
import { StyleSheet, Text, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import {
  IllusEmployeeFork,
  IllusEmpty_1,
  IllusEmpty_2,
  Image,
  SectionHeader,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useChallengesAndRewards } from '@order/ChallengesAndRewards';
import { useLocalizationContext } from '@order/Localization';

import { BagRailInfoPanelText, BagRailInfoPanelTextLink } from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const BagRailContainer = (props: BagRailContainerProps) => {
  const {
    hasError,
    isEmpty,
    shouldUseCompactEmptyMode,
    children,
    ...railProps
  } = props;
  const isLoggedIn = useIsLoggedIn();

  if (!isLoggedIn) {
    return <BagRailContainerSignedOut headerText={props.headerText} />;
  }

  if (hasError) return <BagRailContainerError {...railProps} />;

  if (isEmpty) {
    return (
      <BagRailContainerEmpty
        isCompact={shouldUseCompactEmptyMode}
        headerText={props.headerText}
        testID="bag.rewards-rail.empty"
      />
    );
  }

  return <BagRailContainerWrapper>{children}</BagRailContainerWrapper>;
};

// ─── Variations ──────────────────────────────────────────────────────────────

const BagRailContainerSignedOut = (props: BagRailContainerInfoProps) => {
  const { t } = useLocalizationContext();
  const { navigate } = useNavigation();

  // ─── Helpers ─────────────────────────────────

  const navigateToLogInScreen = useCallback(() => {
    navigate('Auth', {
      screen: 'Login',
      params: { redirect: 'bag' },
    });
  }, [navigate]);

  const navigateToJoinScreen = useCallback(() => {
    navigate('Auth', {
      screen: 'Join',
      params: { redirect: 'bag' },
    });
  }, [navigate]);

  // ─────────────────────────────────────────────

  return (
    <BagRailContainerInfoPanel
      headerText={props.headerText}
      imageAssetUrl={IllusEmpty_1}
    >
      <Text>
        <BagRailInfoPanelTextLink onPress={navigateToLogInScreen}>
          {t('bag.rewards.signed-out.sign-in-label')}
        </BagRailInfoPanelTextLink>

        <BagRailInfoPanelText>
          {t('bag.rewards.signed-out.sign-in-helper-text')}
        </BagRailInfoPanelText>

        <BagRailInfoPanelText>
          {t('bag.rewards.signed-out.sign-up-helper-text-start')}
        </BagRailInfoPanelText>

        <BagRailInfoPanelTextLink onPress={navigateToJoinScreen}>
          {t('bag.rewards.signed-out.sign-up-label')}
        </BagRailInfoPanelTextLink>

        <BagRailInfoPanelText>
          {t('bag.rewards.signed-out.sign-up-helper-text-end')}
        </BagRailInfoPanelText>
      </Text>
    </BagRailContainerInfoPanel>
  );
};

const BagRailContainerEmpty = (props: BagRailContainerEmptyProps) => {
  const { isCompact, headerText, testID } = props;

  const { t } = useLocalizationContext();

  // ─────────────────────────────────────────────

  if (isCompact) {
    return (
      <BagRailContainerWrapper testID={testID}>
        <SectionHeader heading={headerText} />

        <View style={styles.infoPanelContent}>
          <BagRailInfoPanelText palette="secondary">
            {t('bag.rewards.no-rewards.text')}
          </BagRailInfoPanelText>
        </View>
      </BagRailContainerWrapper>
    );
  }

  return (
    <BagRailContainerInfoPanel
      headerText={headerText}
      imageAssetUrl={IllusEmployeeFork}
    >
      <BagRailInfoPanelText>
        {t('bag.rewards.no-rewards.text')}
      </BagRailInfoPanelText>
    </BagRailContainerInfoPanel>
  );
};

const BagRailContainerError = (props: BagRailContainerInfoProps) => {
  const { t } = useLocalizationContext();
  const { fetchRewards } = useChallengesAndRewards();

  // ─────────────────────────────────────────────

  return (
    <BagRailContainerInfoPanel
      headerText={props.headerText}
      imageAssetUrl={IllusEmpty_2}
    >
      <Text>
        <BagRailInfoPanelText>
          {t('bag.rewards.error.text')}
        </BagRailInfoPanelText>

        <BagRailInfoPanelText>
          {t('bag.rewards.error.helper-text-start')}
        </BagRailInfoPanelText>

        <BagRailInfoPanelTextLink onPress={fetchRewards}>
          {t('bag.rewards.error.refresh-cta-label')}
        </BagRailInfoPanelTextLink>

        <BagRailInfoPanelText>
          {t('bag.rewards.error.helper-text-end')}
        </BagRailInfoPanelText>
      </Text>
    </BagRailContainerInfoPanel>
  );
};

// ─── Components ──────────────────────────────────────────────────────────────

const BagRailContainerInfoPanel = (props: BagRailInfoPanelProps) => {
  const { headerText, children, imageAssetUrl, testID } = props;

  return (
    <BagRailContainerWrapper testID={testID}>
      <SectionHeader heading={headerText} />

      <View style={styles.infoPanelContent}>
        <View style={styles.infoPanelDescription}>{children}</View>

        <Image
          source={imageAssetUrl as ImageSourcePropType}
          contentFit="contain"
          style={styles.infoPanelIllustration}
        />
      </View>
    </BagRailContainerWrapper>
  );
};

const BagRailContainerWrapper = (props: ViewProps) => {
  const { match } = useResponsive();

  const wrapperStyle = [
    match([styles.wrapperXS, styles.wrapperSM]),
    props.style,
  ];

  return (
    <View style={wrapperStyle} testID={props.testID}>
      {props.children}
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapperXS: {
    paddingTop: theme.spacing['2'],
  },
  wrapperSM: {
    paddingTop: theme.spacing['6'],
  },
  infoPanelContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing['4'],
  },
  infoPanelDescription: {
    flex: 1,
    paddingRight: theme.spacing['4'],
  },
  infoPanelIllustration: {
    width: 120,
    height: 120,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BagRailContainerProps = Readonly<{
  isEmpty?: boolean;
  shouldUseEmpty?: boolean;
  shouldUseCompactEmptyMode: boolean;
  hasError?: boolean;
  headerText: string;
  children: ReactNode;
}>;

type BagRailContainerEmptyProps = BagRailContainerInfoProps &
  Readonly<{
    isCompact: boolean;
  }>;

type BagRailContainerInfoProps = Readonly<{
  headerText: string;

  testID?: string;
}>;

type BagRailInfoPanelProps = Readonly<{
  imageAssetUrl: string | ImageSourcePropType;
  children: ReactNode;
}> &
  BagRailContainerInfoProps;

import {
  interpolateColor,
  type SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { theme } from '@garnish/constants';

import { CUSTOM_NAVIGATION_HEADER_HEIGHT } from '../CustomNavigationHeader.constants';
import { type CustomNavigationHeaderPalette } from '../CustomNavigationHeader.types';

// ─────────────────────────────────────────────────────────────────────────────
/**
 * Will animate the header background color as the customer scrolls the view.
 */
export const useBackgroundAnimation = (props: AnimationProps) => {
  const { scrollOffsetSV, palette = 'default' } = props;

  return useAnimatedStyle(
    () => ({
      maxHeight: CUSTOM_NAVIGATION_HEADER_HEIGHT,
      backgroundColor: interpolateColor(
        scrollOffsetSV?.value ?? 0,
        [0, CUSTOM_NAVIGATION_HEADER_HEIGHT],
        paletteColors[palette],
      ),
    }),
    [scrollOffsetSV],
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const paletteColors: Record<CustomNavigationHeaderPalette, string[]> = {
  default: [
    theme.colors.OPACITY.OATMEAL.ALMOST_TRANSPARENT,
    theme.colors.OATMEAL,
  ],
  oatmeal: [
    theme.colors.OPACITY.OATMEAL.ALMOST_TRANSPARENT,
    theme.colors.OATMEAL,
  ],
  cream: [theme.colors.OPACITY.CREAM.ALMOST_TRANSPARENT, theme.colors.CREAM],
  'dark-kale': [
    theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    theme.colors.DARK_KALE,
  ],
};

// ─── Types ───────────────────────────────────────────────────────────────────

type AnimationProps = {
  scrollOffsetSV?: SharedValue<number>;
  palette?: CustomNavigationHeaderPalette;
};

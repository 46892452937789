import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigation } from '@react-navigation/native';

import { NotFoundView } from '@order/components';
import { useResetLastInteractedMenuStore } from '@order/LastInteractedStore';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuContentError = () => {
  const { navigate } = useNavigation();
  const { formatMessage } = useIntl();

  const resetLastInteractedMenuStore = useResetLastInteractedMenuStore();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const changeLocation = useCallback(() => {
    navigate('MainTabs', {
      screen: 'MenuTab',
      params: { screen: 'Locations' },
    });
  }, [navigate]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <NotFoundView
      btnLabel={formatMessage(messages.ctaLabel)}
      onBtnPress={changeLocation}
      onMount={resetLastInteractedMenuStore}
    />
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  ctaLabel: {
    defaultMessage: 'Select another location',
    description: 'Menu | Error | Heading',
  },
});

import { useMemo } from 'react';

import { useUserRewardsPointsQuery } from '../../graphql/UserRewardsPoints.generated';

export const useUserRewardsPoints = () => {
  const [{ data, fetching }] = useUserRewardsPointsQuery({
    requestPolicy: 'cache-and-network',
  });

  return useMemo(() => {
    return {
      fetching,
      loyaltyId: data?.loyaltyProfile?.loyaltyId ?? null,
      availablePoints: data?.loyaltyProfile?.points?.available ?? 0,
    };
  }, [data?.loyaltyProfile, fetching]);
};

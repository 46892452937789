import React, { useRef } from 'react';
import { ScrollView, StyleSheet, View, type ViewStyle } from 'react-native';
import type { AnimatedStyleProp } from 'react-native-reanimated';
import { theme, TYPE_CONFIG } from '@garnish/constants';

import { usePressableState, useResponsive } from '../../../../hooks';
import { webOnlyStyles } from '../../../../utils';
import { Icon } from '../../../Icon';
import { PressableLink } from '../../../PressableLink';
import { BodyText, TitleText } from '../../../Text';
import { HeroCardContainer } from '../HeroCardContainer';
import { HeroCardCta } from '../HeroCardCta';

// ─────────────────────────────────────────────────────────────────────────────

export const HeroCardBackSide = (props: HeroCardFlipBackProps) => {
  const {
    title,
    description,
    ctaLabel,
    inlineLinkLabel,
    inlineLinkURL,
    animatedStyle,
    isVisible = false,
  } = props;

  const { match, currentBreakpoint, minWidth } = useResponsive();

  // ─────────────────────────────────────────────────────────────────────

  const shouldRenderInlineLink =
    inlineLinkLabel !== undefined && inlineLinkURL !== undefined;
  const shouldRenderInlineLinkXS =
    shouldRenderInlineLink && currentBreakpoint.isXS;
  const shouldRenderInlineLinkSM = shouldRenderInlineLink && minWidth.isSM;

  // ─── Styles ──────────────────────────────────────────────────────────

  const titleResponsiveStyle = match([
    styles.titleXS,
    styles.titleSM,
    styles.titleSM,
    styles.titleLG,
  ]);
  const descriptionContainerResponsiveStyle = match([
    styles.descriptionContainerXS,
    styles.descriptionContainerSM,
    styles.descriptionContainerSM,
    styles.descriptionContainerLG,
  ]);
  const descriptionContainerStyles = [
    styles.descriptionContainer,
    descriptionContainerResponsiveStyle,
  ];
  const descriptionResponsiveStyle = match([
    styles.descriptionXS,
    styles.descriptionSM,
    styles.descriptionSM,
    styles.descriptionLG,
  ]);
  const ctaResponsiveStyle = match([
    styles.ctaContainerXS,
    styles.ctaContainerSM,
    styles.ctaContainerSM,
    styles.ctaContainerLG,
  ]);
  const ctaContainerStyles = [styles.ctaContainer, ctaResponsiveStyle];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <HeroCardContainer
      palette="quinoa"
      animatedStyle={animatedStyle}
      hasPointerEvents={isVisible}
    >
      <ScrollView>
        <TitleText style={titleResponsiveStyle}>{title}</TitleText>

        <View style={descriptionContainerStyles}>
          <BodyText style={descriptionResponsiveStyle}>{description}</BodyText>

          {shouldRenderInlineLinkSM ? (
            <HeroCardBackSideInlineLink
              label={inlineLinkLabel}
              inlineLinkURL={inlineLinkURL}
            />
          ) : null}
        </View>
      </ScrollView>

      <View style={ctaContainerStyles}>
        {shouldRenderInlineLinkXS ? (
          <HeroCardBackSideInlineLink
            label={inlineLinkLabel}
            inlineLinkURL={inlineLinkURL}
          />
        ) : null}

        <HeroCardCta label={ctaLabel} />
      </View>
    </HeroCardContainer>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const HeroCardBackSideInlineLink = (props: HeroCardBackSideInlineLinkProps) => {
  const { label, inlineLinkURL } = props;

  const pressableRef = useRef(null);
  const { match } = useResponsive();
  const { isHovered } = usePressableState(pressableRef);

  // ─── Styles ──────────────────────────────────────────────────────────

  const inlineLinkResponsiveStyle = match([
    styles.inlineLinkXS,
    styles.inlineLinkSM,
  ]);
  const inlineLinkStyles = [
    styles.inlineLink,
    inlineLinkResponsiveStyle,
    isHovered ? styles.inlineLinkHover : undefined,
    webOnlyStyles({ transition: `opacity ${theme.transitions.base}ms` }),
  ];
  const inlineLinkLabelResponsiveStyles = match([
    styles.inlineLinkLabelXS,
    styles.inlineLinkLabelSM,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <PressableLink
      ref={pressableRef}
      style={inlineLinkStyles}
      accessibilityRole="button"
      to={inlineLinkURL}
    >
      <BodyText style={inlineLinkLabelResponsiveStyles}>{label}</BodyText>
      <Icon name="IconArrowRight" />
    </PressableLink>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  titleXS: {
    ...TYPE_CONFIG.TITLE['32'],
    paddingTop: theme.spacing['4'],
    paddingHorizontal: theme.spacing['4'],
  },
  titleSM: {
    ...TYPE_CONFIG.TITLE['40'],
    paddingTop: theme.spacing['10'],
    paddingHorizontal: theme.spacing['6'],
  },
  titleLG: {
    ...TYPE_CONFIG.TITLE['56'],
    paddingTop: theme.spacing['20'],
    paddingHorizontal: theme.spacing['8'],
  },

  descriptionContainer: {
    alignItems: 'flex-start',
  },
  descriptionContainerXS: {
    paddingTop: theme.spacing['4'],
    paddingHorizontal: theme.spacing['14'],
  },
  descriptionContainerSM: {
    paddingTop: theme.spacing['6'],
    paddingHorizontal: theme.spacing['14'],
  },
  descriptionContainerLG: {
    paddingTop: theme.spacing['10'],
    paddingHorizontal: theme.spacing['35'],
  },
  descriptionXS: {
    ...TYPE_CONFIG.BODY['14'],
  },
  descriptionSM: {
    ...TYPE_CONFIG.BODY['16'],
  },
  descriptionLG: {
    ...TYPE_CONFIG.BODY['18'],
  },

  inlineLink: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inlineLinkHover: {
    opacity: 0.64,
  },
  inlineLinkXS: {
    marginRight: 'auto',
  },
  inlineLinkSM: {
    marginTop: theme.spacing['4'],
  },
  inlineLinkLabelXS: {
    ...TYPE_CONFIG.BODY['16'],
    marginRight: theme.spacing['1'],
  },
  inlineLinkLabelSM: {
    ...TYPE_CONFIG.BODY['18'],
    marginRight: theme.spacing['1'],
  },

  ctaContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing['2'],
  },
  ctaContainerXS: {
    paddingRight: theme.spacing['4'],
    paddingBottom: theme.spacing['4'],
    paddingLeft: theme.spacing['14'],
  },
  ctaContainerSM: {
    paddingHorizontal: theme.spacing['6'],
    paddingBottom: theme.spacing['4'],
  },
  ctaContainerLG: {
    paddingHorizontal: theme.spacing['8'],
    paddingBottom: theme.spacing['8'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type HeroCardFlipBackProps = Readonly<{
  title: string;
  description: string;
  inlineLinkLabel?: string;
  inlineLinkURL?: string;
  ctaLabel: string;
  animatedStyle: AnimatedStyleProp<ViewStyle>;
  isVisible?: boolean;
}>;

type HeroCardBackSideInlineLinkProps = Readonly<{
  label: string;
  inlineLinkURL: string;
}>;

import { send } from 'xstate';

import {
  resetInteractedStore,
  setInteractedStore,
  setLastInteractedStore,
} from './LastInteractedStore.machine.actions';
import { LastInteractedStoreModel } from './LastInteractedStore.model';

// ─── MACHINE ────────────────────────────────────────────────────────────────────

const { createMachine, initialContext } = LastInteractedStoreModel;

/**
 * Stores last interacted store information (delivery address ID or store/restaurant slug)
 * based on the listed sources in the following order:
 *
 * 1. Active cart.
 * 2. Last interacted menu.
 * 3. Last placed order.
 *
 * If the top source isn't available, the one below it
 * will be used as a fallback (all the way down to "undefined").
 */
export const LastInteractedStoreMachine = createMachine(
  {
    predictableActionArguments: true, // https://xstate.js.org/docs/guides/actions.html
    context: initialContext,
    on: {
      //
      // ─────────────────────────────────────────────────────────────────

      SET_LAST_INTERACTED_MENU_STORE: {
        actions: ['setMenuStore', send('SET_LAST_INTERACTED_STORE')],
      },
      SET_ACTIVE_CART_STORE: {
        actions: ['setActiveCartStore', send('SET_LAST_INTERACTED_STORE')],
      },
      SET_LAST_PLACED_ORDER_STORE: {
        actions: ['setLastOrderStore', send('SET_LAST_INTERACTED_STORE')],
      },

      // ─────────────────────────────────────────────────────────────────

      SET_LAST_INTERACTED_STORE: {
        actions: 'setLastInteractedStore',
      },

      // ─────────────────────────────────────────────────────────────────

      RESET_LAST_INTERACTED_MENU_STORE: {
        actions: ['resetMenuStore', send('SET_LAST_INTERACTED_STORE')],
      },
      RESET_ACTIVE_CART_STORE: {
        actions: ['resetActiveCartStore', send('SET_LAST_INTERACTED_STORE')],
      },
      RESET_LAST_PLACED_ORDER_STORE: {
        actions: ['resetLastOrderStore', send('SET_LAST_INTERACTED_STORE')],
      },
    },
  },
  {
    actions: {
      setLastInteractedStore,

      setMenuStore: setInteractedStore('menu'),
      setActiveCartStore: setInteractedStore('activeCart'),
      setLastOrderStore: setInteractedStore('lastPlacedOrder'),

      resetMenuStore: resetInteractedStore('menu'),
      resetActiveCartStore: resetInteractedStore('activeCart'),
      resetLastOrderStore: resetInteractedStore('lastPlacedOrder'),
    },
  },
);

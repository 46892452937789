import { createURL, getInitialURL, parseInitialURLAsync } from 'expo-linking';
import type { QueryParams } from 'expo-linking/src/Linking.types';

import { FEATURE_FLAG_OVERRIDE_QUERY_PARAM_OVERRIDE } from '../../launch-darkly.constants';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns the initial URL without feature flag overrides query params.
 *
 * NOTE: Can be assigned to `getInitialURL`.
 * @see {@link https://reactnavigation.org/docs/navigation-container/#linkinggetinitialurl}
 */
export async function getInitialUrlWithoutFeatureFlagsOverrides(): Promise<
  string | undefined
> {
  const url = await getInitialURL();
  const initialURL = await parseInitialURLAsync();
  const { hostname, path, scheme, queryParams } = initialURL;

  const isUrlWithoutQueryParams =
    queryParams === null ||
    Object.keys(queryParams).length === 0 ||
    path === null;
  const isValidUrlWithoutQueryParams = isUrlWithoutQueryParams && url !== null;
  const isInvalidUrl = url === null;

  // ─────────────────────────────────────────────────────────────────────

  if (isValidUrlWithoutQueryParams) {
    return url;
  }

  if (isUrlWithoutQueryParams || isInvalidUrl) {
    return;
  }

  // ─────────────────────────────────────────────────────────────────────

  const filteredQueryParams = filterQueryParams(queryParams);
  const formattedUrl = hostname ? `${hostname}/${path}` : path;

  if (scheme) {
    return createURL(formattedUrl, {
      scheme,
      queryParams: filteredQueryParams,
    });
  }

  return createURL(formattedUrl, { queryParams: filteredQueryParams });
}

// ─────────────────────────────────────────────────────────────────────────────

function filterQueryParams(queryParams: QueryParams): QueryParams {
  const queryParamsEntries = Object.entries(queryParams);

  return queryParamsEntries.reduce((filteredEntries, [key, value]) => {
    const shouldFilterQueryParam = key.startsWith(
      FEATURE_FLAG_OVERRIDE_QUERY_PARAM_OVERRIDE,
    );

    if (shouldFilterQueryParam) {
      return filteredEntries;
    }

    return { ...filteredEntries, [key]: value };
  }, {});
}

import { useIntl } from 'react-intl';

import { lineItemPriceMessages as messages } from './useLineItemPrice.messages';

// ────────────────────────────────────────────────────────────────────────────────

export const useLineItemPrice = (lineItem: PartialLineItem) => {
  const { formatMessage, formatNumber } = useIntl();
  const { perItemCost = 0, quantity = 1 } = lineItem;

  const priceInDollars = perItemCost / 100;

  const formattedPrice = formatNumber(priceInDollars, {
    style: 'currency',
    currency: 'USD',
  });
  const shouldUsePricingPerItem = quantity > 1;

  return shouldUsePricingPerItem
    ? formatMessage(messages.pricePerEach, { price: formattedPrice })
    : formattedPrice;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type PartialLineItem = {
  perItemCost?: number; // in cents
  quantity?: number;
};

import React from 'react';

import { useTelemetry } from '@order/Telemetry';

export const usePromoCodeTelemetry = () => {
  const { track } = useTelemetry();

  const monitorPromoCodeApplied = React.useCallback(
    (codeType: string, entryPoint?: string) => {
      track('payment_promo.code_applied', { codeType, entryPoint });
    },
    [track],
  );

  const monitorPromoCodeFailed = React.useCallback(
    (userError?: string, systemError?: string, entryPoint?: string) => {
      track('payment_promo.codes_failed', {
        userError,
        systemError,
        entryPoint,
      });
    },
    [track],
  );

  const monitorPromoCodeEntered = React.useCallback(
    (entryPoint?: string) => {
      track('payment_promo.entered', { entryPoint });
    },
    [track],
  );

  const monitorPromoCodeAttemptApply = React.useCallback(
    (entryPoint?: string) => {
      track('payment_promo.apply_attempted', { entryPoint });
    },
    [track],
  );

  return {
    monitorPromoCodeApplied,
    monitorPromoCodeFailed,
    monitorPromoCodeEntered,
    monitorPromoCodeAttemptApply,
  };
};

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { MobileHeader, ModalRow, theme } from '@sg/garnish';

import type { Credit } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { CreditsList } from '../components/';

export const AvailableCredit = ({
  creditAmount,
  creditDetails,
}: AvailableCreditProps) => {
  const { t } = useLocalizationContext();

  return (
    <>
      <View style={styles.mobileHeaderPadding}>
        <MobileHeader title={t('account.credit.mobile-label')} />
      </View>

      <ModalRow>
        <CreditsList
          credits={creditDetails}
          totalCreditsAmount={creditAmount}
        />
      </ModalRow>
    </>
  );
};

type AvailableCreditProps = Readonly<{
  creditAmount: number;
  creditDetails: readonly Credit[];
}>;

const styles = StyleSheet.create({
  mobileHeaderPadding: {
    paddingTop: theme.spacing['4'],
    paddingHorizontal: theme.spacing['4'],
  },
});

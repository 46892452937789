import { parseInitialURLAsync } from 'expo-linking';
import type { QueryParams } from 'expo-linking/src/Linking.types';

import { FEATURE_FLAG_OVERRIDE_QUERY_PARAM_OVERRIDE } from '../../launch-darkly.constants';
import { safelyParseFeatureFlagValue } from '../safelyParseFeatureFlagValue';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns feature flag overrides query params from the initial URL.
 *
 * @see {@link https://docs.expo.dev/guides/linking/}
 * @see {@link https://docs.expo.dev/versions/latest/sdk/linking/#linkingparseinitialurlasync}
 */
export async function getFeatureFlagOverridesFromInitialURL(): Promise<
  QueryParams | undefined
> {
  const { queryParams } = await parseInitialURLAsync();

  if (!queryParams) return;

  const queryParamsEntries = Object.entries(queryParams);
  const hasFeatureFlagOverrides = queryParamsEntries.some(([key]) =>
    key.startsWith(FEATURE_FLAG_OVERRIDE_QUERY_PARAM_OVERRIDE),
  );

  if (!hasFeatureFlagOverrides) return;

  return queryParamsEntries.reduce((filteredEntries, [key, value]) => {
    const hasValidValue = typeof value === 'string';
    const shouldIncludeQueryParam =
      key.startsWith(FEATURE_FLAG_OVERRIDE_QUERY_PARAM_OVERRIDE) &&
      hasValidValue;
    const formattedKey = key.replace(
      FEATURE_FLAG_OVERRIDE_QUERY_PARAM_OVERRIDE,
      '',
    );

    if (shouldIncludeQueryParam) {
      return {
        ...filteredEntries,
        [formattedKey]: safelyParseFeatureFlagValue(value),
      };
    }

    return filteredEntries;
  }, {});
}

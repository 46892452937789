import { assign, createMachine } from 'xstate';

import {
  createDecrementCounterService,
  createGetCounterValueService,
  createIncrementCounterService,
  createResetCounterService,
} from './counter-machine.services';
import {
  CounterMachineContext,
  CounterMachineEvents,
  CounterMachineServices,
} from './counter-machine.types';
import { getCounterValueWithFallback } from './counter-machine.utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A factory function to create a state machine for managing a counter using async storage.
 *
 * NOTE: Async storage methods are promises and to handle their async behavior, we use
 *       services, as well as `waiting` and `ready` states.
 *
 * @param {String} asyncStorageKey A unique key to store counter value in async storage
 */
export function createCounterMachine(asyncStorageKey: string) {
  return createMachine(
    {
      schema: {
        context: {} as CounterMachineContext,
        events: {} as CounterMachineEvents,
        services: {} as CounterMachineServices,
      },
      tsTypes: {} as import('./counter-machine.typegen').Typegen0,
      predictableActionArguments: true,

      // ─── Context ─────────────────────────────────────────────────

      context: {
        value: 0,
      },

      // ─── States ──────────────────────────────────────────────────

      initial: 'waiting',
      states: {
        waiting: {
          invoke: {
            src: 'getCounterValue',
            onDone: {
              target: 'ready',
              actions: ['storeInitialCounterValue'],
            },
          },
        },
        ready: {
          initial: 'idle',
          states: {
            idle: {
              id: 'ready-idle',
              on: {
                INCREMENT: {
                  target: '#increment-counter',
                },
                DECREMENT: {
                  target: '#decrement-counter',
                },
                RESET: {
                  target: '#reset-counter',
                },
              },
            },
            loading: {
              initial: 'idle',
              states: {
                idle: {},
                incrementCounter: {
                  id: 'increment-counter',
                  invoke: {
                    src: 'incrementCounter',
                    onDone: {
                      target: '#ready-idle',
                      actions: ['storeCounterValue'],
                    },
                  },
                },
                decrementCounter: {
                  id: 'decrement-counter',
                  invoke: {
                    src: 'decrementCounter',
                    onDone: {
                      target: '#ready-idle',
                      actions: ['storeCounterValue'],
                    },
                  },
                },
                resetCounter: {
                  id: 'reset-counter',
                  invoke: {
                    src: 'resetCounter',
                    onDone: {
                      target: '#ready-idle',
                      actions: ['storeCounterValue'],
                    },
                  },
                },
              },
            },
          },
        },
      },
    },

    // ─── Helpers ─────────────────────────────────────────────────────────

    {
      services: {
        getCounterValue: createGetCounterValueService(asyncStorageKey),
        incrementCounter: createIncrementCounterService(asyncStorageKey),
        decrementCounter: createDecrementCounterService(asyncStorageKey),
        resetCounter: createResetCounterService(asyncStorageKey),
      },
      actions: {
        storeInitialCounterValue: assign({
          value: (_context, event) => getCounterValueWithFallback(event.data),
        }),
        storeCounterValue: assign({
          value: (_context, event) => event.data,
        }),
      },
    },
  );
}

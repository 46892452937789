import React, { type ReactNode } from 'react';
import { StyleSheet } from 'react-native';
import { theme, VStack } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrdersListOrderLineItemsContainer = (
  props: OrdersListOrderLineItemsContainerProps,
) => {
  const { children, gap = theme.spacing['6'] } = props;

  return (
    <VStack style={styles.container} gap={gap}>
      {children}
    </VStack>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingVertical: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrdersListOrderLineItemsContainerProps = {
  children: ReactNode;
  gap?: number;
};

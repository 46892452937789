import type { ComponentProps } from 'react';
import React from 'react';
import { View } from 'react-native';

import { GreetingText } from './GreetingText';

export const HomeScreenGreetings = React.memo(
  (props: HomeScreenGreetingsProps) => {
    const { greeting, style, ...rest } = props;

    return (
      <View style={style} {...rest}>
        <GreetingText testID="homepage.welcome-text">{greeting}</GreetingText>
      </View>
    );
  },
);

// ─── Types ────────────────────────────────────────────────────────

type HomeScreenGreetingsProps = Readonly<{
  greeting: string;
}> &
  ComponentProps<typeof View>;

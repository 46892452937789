import { Platform } from 'react-native';
import {
  Extrapolation,
  interpolate,
  type SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { CUSTOM_NAVIGATION_HEADER_HEIGHT } from '../CustomNavigationHeader.constants';

// ─────────────────────────────────────────────────────────────────────────────
/**
 * Will add a transparency to the header contents that show up as the customer scrolls the view.
 */
export const useOpacityAnimation = (props: AnimationProps) => {
  const { scrollOffsetSV } = props;

  const { top } = useSafeAreaInsets();
  const topInset = Platform.OS === 'android' ? top : 0;
  const headerHeight = CUSTOM_NAVIGATION_HEADER_HEIGHT + topInset;
  const amountNeededToShowHeader = headerHeight * HEADER_SCROLL_REQUIREMENT;

  return useAnimatedStyle(
    () => ({
      flex: 1,
      opacity: interpolate(
        scrollOffsetSV?.value ?? 0,
        [headerHeight, amountNeededToShowHeader],
        [0, 1],
        Extrapolation.CLAMP,
      ),
    }),
    [scrollOffsetSV, headerHeight, amountNeededToShowHeader],
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const HEADER_SCROLL_REQUIREMENT = 1.5;

// ─── Types ──────────────────────────────────────────────────────────────────

type AnimationProps = {
  scrollOffsetSV?: SharedValue<number>;
};

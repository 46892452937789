import React from 'react';
import { View } from 'react-native';
import { ListActionSwitch, SectionHeader } from '@sg/garnish';

import { CheckoutLayoutBlock } from '@order/components';
import { useLocalizationContext } from '@order/Localization';

import { useCheckoutContext } from '../../../CheckoutProvider';

export const ConnectedCheckoutExtrasBlock = () => {
  const context = useCheckoutContext();

  return <CheckoutExtrasBlock {...context} />;
};

export const CheckoutExtrasBlock = (
  props: Pick<
    ReturnType<typeof useCheckoutContext>,
    'payload' | 'handleUtensilsToggle'
  >,
) => {
  const { payload, handleUtensilsToggle } = props;
  const { includeUtensils } = payload;

  const { t } = useLocalizationContext();
  const heading = t('general.extras');

  return (
    <CheckoutLayoutBlock>
      <SectionHeader heading={heading} />
      <UtensilsSelector
        includeUtensils={includeUtensils}
        onUtensilsToggle={handleUtensilsToggle}
      />
    </CheckoutLayoutBlock>
  );
};

const UtensilsSelector = ({
  includeUtensils,
  onUtensilsToggle,
}: UtensilsSelectorProps) => {
  const { t } = useLocalizationContext();

  return (
    <View>
      <ListActionSwitch
        title={t('checkout.extras.utensils-title')}
        text={t('checkout.extras.utensils-description')}
        value={includeUtensils}
        onChange={onUtensilsToggle}
        testID="checkout.extras.utensils-toggle"
      />
    </View>
  );
};

type UtensilsSelectorProps = Readonly<{
  onUtensilsToggle: () => void;
  includeUtensils: boolean;
}>;

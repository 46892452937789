/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CancelOrderV2MutationVariables = Types.Exact<{
  input: Types.CancelOrderInput;
}>;

export type CancelOrderV2Mutation = {
  readonly __typename?: 'Mutation';
  readonly cancelOrderV2:
    | {
        readonly __typename: 'AsyncCancelFailed';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'CancelOrderFailed';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'CancelOrderSuccess';
        readonly success: boolean;
        readonly reason: string;
      }
    | {
        readonly __typename: 'CancelOrderSuccessAsync';
        readonly success: boolean;
        readonly reason: string;
      }
    | {
        readonly __typename: 'CancelSuccessRefundFailed';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'CancellationFailedWithDelivery';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'CancellationLimitExceeded';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'OrderAlreadyCanceled';
        readonly errorMessage: string;
      }
    | {
        readonly __typename: 'OrderAlreadySentToKitchen';
        readonly errorMessage: string;
      }
    | { readonly __typename: 'OrderNotFound'; readonly errorMessage: string };
};

export const CancelOrderV2Document = gql`
  mutation CancelOrderV2($input: CancelOrderInput!) {
    cancelOrderV2(input: $input) {
      __typename
      ... on CancelOrderSuccess {
        success
        reason
      }
      ... on CancelOrderSuccessAsync {
        success
        reason
      }
      ... on CancelSuccessRefundFailed {
        errorMessage
      }
      ... on CancelOrderFailed {
        errorMessage
      }
      ... on CancellationLimitExceeded {
        errorMessage
      }
      ... on CancellationFailedWithDelivery {
        errorMessage
      }
      ... on OrderAlreadyCanceled {
        errorMessage
      }
      ... on OrderNotFound {
        errorMessage
      }
      ... on AsyncCancelFailed {
        errorMessage
      }
      ... on OrderAlreadySentToKitchen {
        errorMessage
      }
    }
  }
`;

export function useCancelOrderV2Mutation() {
  return Urql.useMutation<
    CancelOrderV2Mutation,
    CancelOrderV2MutationVariables
  >(CancelOrderV2Document);
}

import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useAsyncStorage } from '@sg/garnish';

import { featureFlagOverrides } from './feature-flags';
import type { FeatureFlagOverrides } from './types';
import {
  deleteFeatureFlagOverridesQueryParams,
  getFeatureFlagOverridesFromInitialURL,
} from './utils';

/**
 * Returns the feature flag overrides with the correct priority.
 * Allows overriding and clearing overrides from code.
 *
 * 1. Local storage overrides (FEATURE_FLAG_OVERRIDES in local storage).
 * 2. Query overrides (?features.<feature-name>=<feature-value>).
 * 3. Local overrides (apps/order/src/context/LaunchDarkly/overrides.json).
 */
export const useOverrides = () => {
  const { localOverrides } = useLocalOverrides();

  const { localStorageOverrides, setLocalStorageOverrides } =
    useLocalStorageOverrides();

  const { queryOverrides } = useQueryOverrides(
    localStorageOverrides,
    setLocalStorageOverrides,
  );

  const overrides = useMemo(
    () => ({ ...localOverrides, ...queryOverrides, ...localStorageOverrides }),
    [localOverrides, localStorageOverrides, queryOverrides],
  );

  const setFeatureFlagOverrides = useCallback(
    (flags: FeatureFlagOverrides) => {
      setLocalStorageOverrides(JSON.stringify(flags));
    },
    [setLocalStorageOverrides],
  );

  const clearFeatureFlagOverrides = useCallback(() => {
    setLocalStorageOverrides(JSON.stringify({}));
  }, [setLocalStorageOverrides]);

  return { overrides, setFeatureFlagOverrides, clearFeatureFlagOverrides };
};

const useLocalStorageOverrides = () => {
  const [localStorageOverrides, setLocalStorageOverrides] = useAsyncStorage(
    'FEATURE_FLAG_OVERRIDES',
  );

  const parsedLocalStorageOverrides = useMemo(() => {
    return parseLocalStorageOverrides(localStorageOverrides);
  }, [localStorageOverrides]);

  return {
    localStorageOverrides: parsedLocalStorageOverrides,
    setLocalStorageOverrides,
  };
};

const useLocalOverrides = () => {
  const [localOverrides, setLocalOverrides] = useState(featureFlagOverrides);

  return { localOverrides, setLocalOverrides };
};

const useQueryOverrides = (
  localStorageOverrides: FeatureFlagOverrides,
  setLocalStorageOverrides: (payload: string) => void,
) => {
  const [queryOverrides, setQueryOverrides] = useState<FeatureFlagOverrides>();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const setFeatureFlagsOverrides = useCallback(async () => {
    const filteredQueryParams = await getFeatureFlagOverridesFromInitialURL();

    if (!filteredQueryParams) return;

    setQueryOverrides(filteredQueryParams);
    setLocalStorageOverrides(
      JSON.stringify({
        ...localStorageOverrides,
        ...filteredQueryParams,
      }),
    );
    await deleteFeatureFlagOverridesQueryParams();
  }, [localStorageOverrides, setLocalStorageOverrides]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useLayoutEffect(() => {
    void setFeatureFlagsOverrides();
  }, [setFeatureFlagsOverrides]);

  // ─────────────────────────────────────────────────────────────────────

  return { queryOverrides, setQueryOverrides };
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function parseLocalStorageOverrides(overrides?: string): FeatureFlagOverrides {
  return overrides ? (JSON.parse(overrides) as FeatureFlagOverrides) : {};
}

import * as React from 'react';

import { switchcase } from '../../../utils';
import type { PinType } from '../types';
import { CourierPin } from './CourierPin';
import { CustomerPin } from './CustomerPin';
import {
  DeliveryPinCustom,
  DeliveryPinHome,
  DeliveryPinUndefined,
  DeliveryPinWork,
} from './DeliveryPin';
import {
  OutpostPinClosedSelected,
  OutpostPinClosedUnselected,
  OutpostPinOpenSelected,
  OutpostPinOpenUnselected,
} from './OutpostPin';
import {
  PickupPinClosedSelected,
  PickupPinClosedUnselected,
  PickupPinOpenSelected,
  PickupPinOpenUnselected,
} from './PickupPin';

export const Pin = (props: PinProps) => {
  return switchcase({
    pickup: getPickupPin(props),
    outpost: getOutpostPin(props),
    deliveryAddress: getDeliveryPin(props),
    courier: getCourierPin(),
    customer: getCustomerPin(),
  })(getPickupPin(props))(props.variation);
};

const getPickupPin = (props: PinProps): React.ReactElement => {
  if (props.isOpen) {
    if (props.isSelected) return <PickupPinOpenSelected {...props} />;

    return <PickupPinOpenUnselected {...props} />;
  }

  if (props.isSelected) return <PickupPinClosedSelected {...props} />;

  return <PickupPinClosedUnselected {...props} />;
};

const getOutpostPin = (props: PinProps): React.ReactElement => {
  if (props.isOpen) {
    if (props.isSelected) return <OutpostPinOpenSelected {...props} />;

    return <OutpostPinOpenUnselected {...props} />;
  }

  if (props.isSelected) return <OutpostPinClosedSelected {...props} />;

  return <OutpostPinClosedUnselected {...props} />;
};

const getDeliveryPin = (props: PinProps): React.ReactElement => {
  if (props.deliveryAddressType === 'home') return <DeliveryPinHome />;

  if (props.deliveryAddressType === 'work') return <DeliveryPinWork />;

  if (props.deliveryAddressType === 'custom') return <DeliveryPinCustom />;

  return <DeliveryPinUndefined />;
};

const getCourierPin = (): React.ReactElement => {
  return <CourierPin />;
};

const getCustomerPin = (): React.ReactElement => {
  return <CustomerPin />;
};

export type PinProps = Readonly<{
  variation: PinType;
  deliveryAddressType?: 'home' | 'work' | 'custom';
  isOpen?: boolean;
  isSelected?: boolean;
  isMinimized?: boolean;
  damping?: number;
  stiffness?: number;
}>;

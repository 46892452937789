export const scanInStore = {
  //
  // ─── Screen ──────────────────────────────────────────────────────────

  'scan-in-store.title': 'Scan in store',

  // ─── "Earn & Redeem" tab ─────────────────────────────────────────────

  'scan-in-store.tabs.earn-and-redeem.title': 'Earn & redeem',
  'scan-in-store.tabs.earn-and-redeem.card.illustration-alt':
    '"Earn & redeem" illustration',
  'scan-in-store.tabs.earn-and-redeem.card.title': 'Scan to Earn and\nRedeem',
  'scan-in-store.tabs.earn-and-redeem.cta.title': 'View challenges',
  'scan-in-store.tabs.earn-and-redeem.card.without-subscription.text':
    'Join sweetpass for free to earn and redeem rewards!',
  'scan-in-store.tabs.earn-and-redeem.card.without-subscription.cta.title':
    'Join sweetpass',

  // ─── "Pay" tab ───────────────────────────────────────────────────────

  'scan-in-store.tabs.pay.title': 'Pay',
  'scan-in-store.tabs.pay.carousel.nav-control-a11y-label': 'Gift card',
  'scan-in-store.tabs.pay.card.illustration-alt': '"Pay" illustration',
  'scan-in-store.tabs.pay.empty-state-card.illustration-alt':
    '"Add gift card" illustration',
  'scan-in-store.tabs.pay.cta.title': 'Add gift card',
  'scan-in-store.tabs.pay.empty-state':
    'Have a gift card? Add it to your account and use it in-store or for digital orders.',
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SubmitBagOrderMutationVariables = Types.Exact<{
  input: Types.SubmitOrderUsingPaymentMethodInput;
}>;

export type SubmitBagOrderMutation = {
  readonly __typename?: 'Mutation';
  readonly submitOrderUsingPaymentMethod:
    | { readonly __typename: 'DeclinedPaymentMethod' }
    | { readonly __typename: 'DeliveryAlreadyInFlight' }
    | { readonly __typename: 'DeliverySpecifyAddress' }
    | { readonly __typename: 'IncorrectPaymentMethod' }
    | { readonly __typename: 'InvalidCustomerPhone' }
    | {
        readonly __typename: 'InvalidTip';
        readonly message: string;
        readonly status: number;
      }
    | { readonly __typename: 'MinimumDeliverySubtotalNotMet' }
    | {
        readonly __typename: 'ProductUnavailable';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RestaurantIsNotAcceptingOrders';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'RewardNotValidated';
        readonly message: string;
        readonly status: number;
        readonly failureCode: Types.ValidateRewardFailureCode;
      }
    | { readonly __typename: 'StoreOffline' }
    | {
        readonly __typename: 'SubmitOrderUsingPaymentMethodSuccess';
        readonly order: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly wantedTime: string;
        };
      }
    | { readonly __typename: 'TimeslotUnavailable' }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export const SubmitBagOrderDocument = gql`
  mutation SubmitBagOrder($input: SubmitOrderUsingPaymentMethodInput!) {
    submitOrderUsingPaymentMethod(input: $input) {
      __typename
      ... on SubmitOrderUsingPaymentMethodSuccess {
        order {
          id
          wantedTime
        }
      }
      ... on RewardNotValidated {
        message
        status
        failureCode
      }
      ... on ProductUnavailable {
        message
        status
      }
      ... on RestaurantIsNotAcceptingOrders {
        message
        status
      }
      ... on InvalidTip {
        message
        status
      }
      ... on ValidationError {
        message
        status
        fieldErrors {
          field
          message
        }
      }
    }
  }
`;

export function useSubmitBagOrderMutation() {
  return Urql.useMutation<
    SubmitBagOrderMutation,
    SubmitBagOrderMutationVariables
  >(SubmitBagOrderDocument);
}

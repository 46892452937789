import React, { memo, useCallback } from 'react';
import type { AddressType } from '@sg/garnish';
import { LoadingPlaceholder, theme, useResponsive } from '@sg/garnish';
import { VStack } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useCustomer } from '@order/Customer';
import type { OrderStatus } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { useOpenBag } from '../../../../components/Bag/Bag.hooks';
import { useOrderStatusNavigation } from '../../../OrderStatusScreen/OrderStatus.navigation';
import { useOrderStatusTelemetry } from '../../../OrderStatusScreen/OrderStatus.telemetry';
import type { GetOrderStatusQuery } from '../../GraphQL/OrderStatus.query.generated';
import { useHomeInStoreOrderTelemetry, useHomeLocalization } from '../../hooks';
import { HomeScreenCTA } from '../HomeScreenCTA';
import { HeroHeadlineCta, HomeScreenGreetings } from '../HomeScreenGreetings';

export const HomeScreenWelcomePane = memo(
  (props: HomeScreenWelcomePaneProps) => {
    const {
      data,
      isLoading,
      isLoadingStatus,
      cartOrderType,
      hasCart,
      seasonalHeroHeadlineText,
      inStoreOrderPendingFeedbackId,
      inStoreOrderPendingFeedbackWantedTime,
    } = props;

    // ─── Context ──────────────────────────────────────────────────

    const isLoggedIn = useIsLoggedIn();
    const { t } = useLocalizationContext();
    const { currentBreakpoint, match } = useResponsive();

    // ─── Localization ─────────────────────────────────────────────

    const {
      shouldShowCartAction,
      shouldShowStatusAction,
      greetingMode,
      orderId,
      orderType,
      enhancedOrderStatus,
      restaurantName,
      dropoffLocation,
      deliveryAddressName,
      timeEstimate,
      dayOfWeek,
      wantedTimeTs,
      timeRangeStartTs,
      timeRangeEndTs,
    } = useHomeLocalization({
      isLoading: isLoading || isLoadingStatus,
      hasCart,
      orderStatus: data?.orderStatus as OrderStatus,
      inStoreOrderPendingFeedbackId,
      inStoreOrderPendingFeedbackWantedTime,
    });

    // ─── Params ───────────────────────────────────────────────────

    const customerName = useCustomer().customer?.firstName;
    const greeting = t('home.welcome.greeting', { greetingMode, customerName });

    const heroHeadline = shouldShowStatusAction
      ? t('home.welcome.heroHeadline', { orderType, enhancedOrderStatus })
      : seasonalHeroHeadlineText;

    const hasCartOnMobile = shouldShowCartAction && currentBreakpoint.isXS;
    const shouldShowCallToAction =
      !hasCartOnMobile &&
      (isLoadingStatus || shouldShowCartAction || shouldShowStatusAction);

    const isPendingFeedbackStatus = enhancedOrderStatus === 'feedback';
    const shouldShowHeadlineCta = isLoggedIn;

    // ─── Telemetry ────────────────────────────────────────────────

    const isRateInStoreCardPresented = Boolean(
      shouldShowCallToAction &&
        !isLoadingStatus &&
        isPendingFeedbackStatus &&
        inStoreOrderPendingFeedbackId,
    );

    const { handleOrderStatusEvent } = useOrderStatusTelemetry();
    const { handleInStoreRateOrderPress } = useHomeInStoreOrderTelemetry(
      isRateInStoreCardPresented,
    );

    // ─── Callbacks ────────────────────────────────────────────────

    const openBag = useOpenBag({ shouldUpdateCart: true });
    const { navigateToRateOrderScreen, navigateToOrderStatusScreen } =
      useOrderStatusNavigation();

    const handleOnPress = useCallback(() => {
      if (shouldShowCartAction) {
        openBag();

        return;
      }

      if (isPendingFeedbackStatus && orderId && inStoreOrderPendingFeedbackId) {
        handleInStoreRateOrderPress();
      }

      if (isPendingFeedbackStatus && orderId) {
        navigateToRateOrderScreen(orderId);

        return;
      }

      if (orderId) {
        navigateToOrderStatusScreen(orderId);
        handleOrderStatusEvent('home.cta');
      }
    }, [
      isPendingFeedbackStatus,
      shouldShowCartAction,
      orderId,
      inStoreOrderPendingFeedbackId,
      navigateToRateOrderScreen,
      navigateToOrderStatusScreen,
      handleOrderStatusEvent,
      handleInStoreRateOrderPress,
      openBag,
    ]);

    return (
      <VStack gap={theme.spacing['4']} {...props}>
        <HomeScreenGreetings
          testID={customerName ? 'home.welcome.greetings-logged-in' : undefined}
          greeting={greeting}
        />
        {/* eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10 */}
        {isLoadingStatus ? (
          <LoadingPlaceholder
            palette="darkGreen"
            rowHeight={match([72, 100])}
            rows={1}
            columns={1}
            borderRadius={theme.radius.large}
          />
        ) : // eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10
        shouldShowCallToAction ? (
          <HomeScreenCTA
            isPendingFeedbackStatus={isPendingFeedbackStatus}
            shouldShowCartAction={shouldShowCartAction}
            shouldShowStatusAction={shouldShowStatusAction}
            cartOrderType={cartOrderType}
            orderType={orderType}
            enhancedOrderStatus={enhancedOrderStatus}
            restaurantName={restaurantName}
            dropoffLocation={dropoffLocation}
            deliveryAddressName={deliveryAddressName}
            timeEstimate={timeEstimate}
            dayOfWeek={dayOfWeek}
            wantedTimeTs={wantedTimeTs}
            timeRangeStartTs={timeRangeStartTs}
            timeRangeEndTs={timeRangeEndTs}
            onPress={handleOnPress}
          />
        ) : shouldShowHeadlineCta ? (
          <HeroHeadlineCta headline={heroHeadline} />
        ) : null}
      </VStack>
    );
  },
);

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type HomeScreenWelcomePaneProps = Readonly<{
  data?: GetOrderStatusQuery;
  isLoading: boolean;
  isLoadingStatus: boolean;
  hasCart: boolean;
  cartOrderType: AddressType;
  seasonalHeroHeadlineText: string;
  inStoreOrderPendingFeedbackId?: string;
  inStoreOrderPendingFeedbackWantedTime?: string;
}>;

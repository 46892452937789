import type { Client } from 'urql';

import {
  OrdersV2ForLocationDocument,
  type OrdersV2ForLocationQuery,
  OrdersV2ForLocationWithDisclosureDocument,
  type OrdersV2ForLocationWithDisclosureQuery,
} from '../../graphql/RecentOrdersV2ForLocation.generated';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Queries last placed orders details
 */
export async function recentLocationsQuery(client: Client) {
  return client
    .query<OrdersV2ForLocationQuery>(
      OrdersV2ForLocationDocument,
      {
        input: {
          page: 1,
          take: 10,
          includeCanceledOrders: true,
          includePlacedOrders: true,
        },
      },
      { requestPolicy: 'network-only' },
    )
    .toPromise();
}

/**
 * Queries last placed orders details with up-charge disclosure fields
 */
export async function recentLocationsWithDisclosureQuery(client: Client) {
  return client
    .query<OrdersV2ForLocationWithDisclosureQuery>(
      OrdersV2ForLocationWithDisclosureDocument,
      {
        input: {
          page: 1,
          take: 10,
          includeCanceledOrders: true,
          includePlacedOrders: true,
        },
      },
      { requestPolicy: 'network-only' },
    )
    .toPromise();
}

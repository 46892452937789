import React from 'react';
import type { NavigationProp } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';

import type { AppStackParamList } from '../../navigation/AppNavigation.props';

// ─────────────────────────────────────────────────────────────────────────────

export const useOrderStatusNavigation = () => {
  const navigation = useNavigation<NavigationProp<AppStackParamList>>();

  // ─────────────────────────────────────────────────────────────────────

  const navigateToOrderStatusScreen = React.useCallback(
    (orderId: string) => {
      navigation.navigate('Modal', {
        screen: 'OrderStatus',
        params: { orderId },
      });
    },
    [navigation],
  );

  const navigateToRateOrderScreen = React.useCallback(
    (orderId: string) => {
      navigation.navigate('Modal', {
        screen: 'RateOrder',
        params: { orderId },
      });
    },
    [navigation],
  );

  // ─────────────────────────────────────────────────────────────────────

  return {
    navigateToOrderStatusScreen,
    navigateToRateOrderScreen,
  };
};

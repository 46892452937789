import React from 'react';
import { Path, Svg } from 'react-native-svg';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const UpsellCtaHexagonIcon = () => {
  return (
    <Svg width={48} height={48} fill="none" viewBox="0 0 50 50">
      <Path
        fill={theme.colors.LIME}
        stroke={theme.colors.KALE}
        d="M1 14.215v21.57c.001.427.13.846.372 1.215.242.369.59.676 1.01.89l21.233 10.783c.421.214.899.327 1.385.327s.964-.113 1.385-.327L47.618 37.89c.42-.213.768-.52 1.01-.89.243-.369.371-.787.372-1.214v-21.57A2.218 2.218 0 0048.629 13a2.629 2.629 0 00-1.01-.89L26.384 1.328A3.065 3.065 0 0025 1c-.486 0-.964.113-1.385.327L2.382 12.11c-.42.213-.768.52-1.01.89-.243.369-.371.787-.372 1.214z"
      />
    </Svg>
  );
};

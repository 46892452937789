import { useWindowDimensions } from 'react-native';

import { getResponsiveHelpers } from './getResponsiveHelpers';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns different utilities and dynamic values to manage component responsiveness.
 *
 * @example
 *
 * const { minWidth, currentBreakpoint, orientation, match } = useResponsive();
 *
 * const shouldShowBtn = minWidth.SM;
 * const shouldCollapseNavbar = currentBreakpoint.isXS;
 * const shouldShowVideoInFullscreen = orientation.isLandscape;
 * const responsiveStyles = match([styles.containerXS, styles.containerSM, ...]);
 */
export const useResponsive = () => {
  const screenDimensions = useWindowDimensions();

  return getResponsiveHelpers(screenDimensions);
};

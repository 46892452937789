import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ListAction, ListActionInfo, theme, useResponsive } from '@sg/garnish';

import { getCreditCardIcon } from '@order/constants';
import { useLocalizationContext } from '@order/Localization';

import type { SweetpassBillingHistoryQuery } from '../../graphql/SweetpassBillingHistory.generated';

// ─────────────────────────────────────────────────────────────────────────────

export const SweetpassBillingHistoryTransaction = (
  props: SweetpassTransactionHistoryItemProps,
) => {
  const { transaction } = props;

  const {
    amount,
    date,
    refund: isRefunded,
    transactionCreditCard,
  } = transaction;
  const { cardType, lastFour } = transactionCreditCard;

  const { match } = useResponsive();
  const { t, formatDate, formatPrice } = useLocalizationContext();

  // ─────────────────────────────────────────────────────────────────────

  const hasValidAmount = !Number.isNaN(amount);
  const amountInCents = Number(amount) * 100;
  const amountBasedOnRefund = isRefunded ? amountInCents * -1 : amountInCents;

  // @ts-expect-error TS(2352): Conversion of type 'string' to type 'Date' may be ... Remove this comment to see the full error message
  const formattedDate = formatDate(date as Date);
  const formattedAmount = hasValidAmount
    ? formatPrice(amountBasedOnRefund, 'USD')
    : null;

  const transactionCreditCardIcon = getCreditCardIcon(cardType);
  const transactionCreditCardDetails = t(
    'account.sweetpass-membership.billing-history.credit-card.description',
    { cardType, lastFour },
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ListActionInfo iconName={match([undefined, transactionCreditCardIcon])}>
      <View style={styles.transactionContainer}>
        <View style={styles.transactionDetailsContainer}>
          {formattedDate ? (
            <ListAction.Text>{formattedDate}</ListAction.Text>
          ) : null}

          <ListAction.Text size={5} style={styles.transactionCardDetails}>
            {transactionCreditCardDetails}
          </ListAction.Text>
        </View>

        {formattedAmount ? (
          <ListAction.Text style={styles.transactionAmount}>
            {formattedAmount}
          </ListAction.Text>
        ) : null}
      </View>
    </ListActionInfo>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  transactionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  transactionCardDetails: {
    color: theme.colors.CHARCOAL,
    marginTop: theme.spacing['1'],
  },
  transactionDetailsContainer: {
    flex: 1,
  },
  transactionAmount: {
    marginLeft: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassTransactionHistoryItemProps = Readonly<{
  transaction: TransactionsHistory[number];
}>;

type TransactionsHistory = NonNullable<
  SweetpassBillingHistoryQuery['paymentSubscriptionProfile']
>['transactionHistory'];

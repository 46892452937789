import React, { Children, memo, useMemo } from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { LI, UL } from '@expo/html-elements';
import { LG, MD, SM, XS } from '@garnish/constants';

import { useResponsive } from '../../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * HStack component that renders the provided children components in
 * a horizontal stack using optional gap styles.
 */
export const HStack = memo((props: HStackProps) => {
  const { gap, children, ...stackProps } = props;

  // NOTE: To unwrap fragments
  const flattenedChildren = flattenChildren(children);

  if (gap === undefined) {
    return (
      <HStackWithDefaultGap {...stackProps}>
        {flattenedChildren}
      </HStackWithDefaultGap>
    );
  }

  return (
    <HStackWithCustomGap gap={gap} {...stackProps}>
      {flattenedChildren}
    </HStackWithCustomGap>
  );
});

// ─── Variations ──────────────────────────────────────────────────────────────

const HStackWithDefaultGap = memo((props: HStackBaseProps) => {
  const { match } = useResponsive();

  const spacing = match([XS.GUTTER, SM.GUTTER, MD.GUTTER, LG.GUTTER]);

  return <HStackContent gap={spacing} {...props} />;
});

const HStackWithCustomGap = memo((props: HStackWithGapProps) => {
  const { gap, ...restProps } = props;

  return <HStackContent gap={gap} {...restProps} />;
});

// ─── Components ──────────────────────────────────────────────────────────────

const HStackContent = memo((props: HStackWithGapProps) => {
  const { gap, children, style, itemsPerRow, ...restProps } = props;

  const gapWithLimit = Math.max(0, gap);

  // ─── Styles ──────────────────────────────────────────────────────────

  const outerContainerDynamicStyles = useStyle(
    () => ({
      paddingRight: gapWithLimit / 2,
      paddingLeft: gapWithLimit / 2,
    }),
    [gapWithLimit],
  );
  const outerContainerStyles = useMemo(
    () =>
      StyleSheet.flatten([styles.outerContainer, outerContainerDynamicStyles]),
    [outerContainerDynamicStyles],
  );

  const innerContainerDynamicStyles = useStyle(
    () => ({
      marginRight: -gapWithLimit,
      marginLeft: -gapWithLimit,
      rowGap: gapWithLimit,
    }),
    [gapWithLimit],
  );
  const innerContainerStyles = useMemo(
    () =>
      StyleSheet.flatten([
        styles.innerContainer,
        innerContainerDynamicStyles,
        style,
      ]),
    [innerContainerDynamicStyles, style],
  );

  const itemDynamicStyles = useStyle(
    () => ({
      width: itemsPerRow ? `${100 / itemsPerRow}%` : 'auto',
      paddingHorizontal: gapWithLimit / 2,
    }),
    [gapWithLimit, itemsPerRow],
  );
  const itemStyles = useMemo(
    () => StyleSheet.flatten([itemDynamicStyles, styles.itemContainer]),
    [itemDynamicStyles],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={outerContainerStyles}>
      <UL style={innerContainerStyles} {...restProps}>
        {Children.map(children, (child) => {
          return <LI style={itemStyles}>{child}</LI>;
        })}
      </UL>
    </View>
  );
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    width: '100%',
    flexShrink: 1,
  },
  innerContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  itemContainer: {
    flexShrink: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type HStackProps = Readonly<{
  gap?: number;
}> &
  HStackBaseProps;

type HStackWithGapProps = {
  gap: number;
} & HStackBaseProps;

type HStackBaseProps = Readonly<{
  itemsPerRow?: number;
}> &
  ViewProps;

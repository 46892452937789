import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { BodyText, Button, theme, useResponsive, VStack } from '@sg/garnish';

import {
  useRemoveSweetpassPaymentMethod,
  useSweetpassPaymentMethod,
  useSweetpassTier,
  useUpdateSweetpassPaymentMethod,
} from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import { LoadingAnimation } from '../LoadingAnimation';
import { Modal } from '../Modal';

/**
 * This modal shows the information for a payment method.
 * If this payment method is used in an active subscription, it has no actions.
 * If there is no active subscriptions, the only action is to remove.
 * If there is an active subscription with a different payment method,
 * Then there is also an action to update the subscription to this payment method.
 */
export const SweetpassPaymentMethodEditModal = (
  props: SweetpassPaymentMethodEditModalProps,
) => {
  const { paymentMethodId, visible, onRequestClose } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  // ─── Query ───────────────────────────────────────────────────────────

  const {
    paymentMethod,
    subscriptionId,
    isPaymentMethodUsedForSubscriptions,
    hasActiveSubscriptionWithDifferentPaymentMethod,
    isLoadingSweetpassPaymentMethods,
  } = useSweetpassPaymentMethod({ paymentMethodId });

  // ─── Derived Data ────────────────────────────────────────────────────

  const hasValidPaymentMethodInfo =
    typeof paymentMethod?.expirationMonth === 'string' &&
    typeof paymentMethod?.expirationYear === 'string' &&
    typeof paymentMethod?.lastFour === 'string';

  // ─── Mutations ───────────────────────────────────────────────────────

  const { isUpdatingSweetpassPaymentMethod, updateSweetpassPaymentMethod } =
    useUpdateSweetpassPaymentMethod();

  const { isRemovingSweetpassPaymentMethod, removeSweetpassPaymentMethod } =
    useRemoveSweetpassPaymentMethod();

  // ─── Mutation Helpers ────────────────────────────────────────────────

  const handleUpdateSweetpassPaymentMethod = useCallback(async () => {
    return updateSweetpassPaymentMethod({
      paymentMethodId,
      subscriptionId,
      onSuccess: onRequestClose,
    });
  }, [
    paymentMethodId,
    subscriptionId,
    updateSweetpassPaymentMethod,
    onRequestClose,
  ]);

  const handleRemoveSweetpassPaymentMethod = useCallback(async () => {
    return removeSweetpassPaymentMethod({
      paymentMethodId,
      onSuccess: onRequestClose,
    });
  }, [paymentMethodId, removeSweetpassPaymentMethod, onRequestClose]);

  // ─── Free Trial ──────────────────────────────────────────────────────

  const { hasTrialMembership: isFreeTrial } = useSweetpassTier();
  const deletionNotAllowedMessage = isFreeTrial
    ? t('account.sweetpass-membership.payment-methods.free-trial-message')
    : t('account.sweetpass-membership.payment-methods.method-active-message');

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Modal
      fitHeight
      visible={visible}
      accessibilityLabel={t('sweetpass.signed-in.edit-payment-method-title')}
      presentationStyle="pageSheet"
      size={match(['full', 'small'])}
      onRequestClose={onRequestClose}
    >
      {isLoadingSweetpassPaymentMethods || !paymentMethodId ? (
        <LoadingAnimation />
      ) : (
        <>
          <Modal.Header onClose={onRequestClose}>
            {paymentMethod?.cardType}
          </Modal.Header>

          {hasValidPaymentMethodInfo ? (
            <Modal.BodyText>
              {t('account.sweetpass-membership.payment-methods.credit-card', {
                lastFour: paymentMethod.lastFour,
                expiresAt: `${
                  paymentMethod.expirationMonth
                }/${paymentMethod.expirationYear?.slice(-2)}`,
              })}
            </Modal.BodyText>
          ) : null}

          <Modal.Footer>
            {isPaymentMethodUsedForSubscriptions ? (
              <BodyText style={styles.activeMessage} size={3}>
                {deletionNotAllowedMessage}
              </BodyText>
            ) : (
              <VStack gap={theme.spacing['4']}>
                {hasActiveSubscriptionWithDifferentPaymentMethod ? (
                  <Button
                    size="large"
                    palette="primary"
                    onPress={handleUpdateSweetpassPaymentMethod}
                    isLoading={isUpdatingSweetpassPaymentMethod}
                    disabled={isRemovingSweetpassPaymentMethod}
                  >
                    {t(
                      'account.sweetpass-membership.payment-methods.set-active-cta.title',
                    )}
                  </Button>
                ) : null}

                <Button
                  size="large"
                  palette="caution"
                  onPress={handleRemoveSweetpassPaymentMethod}
                  isLoading={isRemovingSweetpassPaymentMethod}
                  disabled={isUpdatingSweetpassPaymentMethod}
                >
                  {t(
                    'account.sweetpass-membership.payment-methods.remove-cta.title',
                  )}
                </Button>
              </VStack>
            )}
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type SweetpassPaymentMethodEditModalProps = Readonly<{
  paymentMethodId: string;
  visible: boolean;
  onRequestClose: () => void;
}>;

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  activeMessage: {
    color: theme.colors.CHARCOAL,
  },
});

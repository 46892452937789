import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

export const JoinSgRewardsBodyContainer = (props: PropsWithChildren) => {
  return <View style={styles.container}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    gap: theme.spacing['6'],
    marginVertical: theme.spacing['6'],
    flex: 1,
    justifyContent: 'space-between',
  },
});

import React from 'react';

import { CheckoutLayoutBlock, TipsGroup } from '@order/components';
import { useLocalizationContext } from '@order/Localization';
import { useDeliveryDisclosure } from '@order/shared/hooks';

import { useCheckoutContext } from '../../../../CheckoutProvider';
import { useCheckoutTips } from '../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const ConnectedCheckoutDeliveryTipBlock = () => {
  const { t } = useLocalizationContext();

  const { payload, handleDeliveryTip } = useCheckoutContext();
  const { tips, defaultTipValue, orderSubtotal } = useCheckoutTips();
  const { deliveryDisclosure } = useDeliveryDisclosure({
    variation: 'delivery-tip',
  });

  const selectedTipAmount = payload?.deliveryOrderDetail?.tip ?? 0;

  // ─────────────────────────────────────────────

  if (tips.length === 0) return null;

  return (
    <CheckoutLayoutBlock>
      <TipsGroup
        tips={tips}
        defaultTipValue={defaultTipValue}
        selectedTipAmountInCents={selectedTipAmount}
        setTipAmount={handleDeliveryTip}
        orderSubtotalInCents={orderSubtotal}
        sectionTitle={t('checkout.tips.delivery-tip.title')}
        sectionDescription={deliveryDisclosure}
      />
    </CheckoutLayoutBlock>
  );
};

import { config } from '@order/AzureAuth';

import { clientInstance } from '../../client';

export async function signOut() {
  await clientInstance?.initialize();

  const activeAccount = clientInstance?.getActiveAccount();

  // NOTE: This will force MSAL to invalidate any stale state
  sessionStorage.removeItem('msal.interaction.status');

  try {
    await clientInstance?.logoutRedirect({
      account: activeAccount,
      postLogoutRedirectUri: config.logoutRedirectUri,
    });

    return true;
  } catch {
    return false;
  }
}

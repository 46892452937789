/**
 * The purpose of this is that if the text will be larger than the container, we'll decrease its size from '32' to '24',
 * To avoid effects and measurements, we are using a simple character count.
 */
export const useDynamicHeaderSize = (props: DynamicHeaderSizeProps) => {
  const { orderChannel, locationName } = props;

  const charactersLength = orderChannel.length + locationName.length;

  return charactersLength > CHARACTER_LIMIT ? SMALLER_SIZE : LARGER_SIZE;
};

// ─── Constants ──────────────────────────────────────────────────────────────
// Pickup (6) + Sweetgreen Brink (16) = 24 (ok as 32)
// Pickup (6) + Sweetgreen Brink Demo (21) = 27 (needs to be 24)
const CHARACTER_LIMIT = 25;
const SMALLER_SIZE = '24';
const LARGER_SIZE = '32';

// ─── Types ──────────────────────────────────────────────────────────────────

type DynamicHeaderSizeProps = {
  orderChannel: string;
  locationName: string;
};

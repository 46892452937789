import { defineMessages } from 'react-intl';

export const orderAgainConflictReviewCTAsMessages = defineMessages({
  addToBagAnyway: {
    defaultMessage: 'Add to bag anyway',
    description: 'Order again | Conflict review | CTA | Add to bag anyway',
  },
  chooseNewBase: {
    defaultMessage: 'Choose a new base',
    description: 'Order again | Conflict review | CTA | Choose a new base',
  },
  customize: {
    defaultMessage: 'Customize',
    description: 'Order again | Conflict review | CTA | Customize',
  },
});

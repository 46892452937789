import React from 'react';
import { View } from 'react-native';

import { TagAlert } from '../../TagAlert';
import { styles } from '../TextField.styles';
import type { TextFieldNoticeProps } from '../TextField.types';

export const TextFieldNotice = (props: TextFieldNoticeProps) => {
  const {
    children: noticeText,
    nativeID,
    palette = 'neutral',
    ...restProps
  } = props;

  return (
    <View nativeID={nativeID} style={styles.noticeWrapper}>
      {noticeText ? (
        <TagAlert palette={palette} {...restProps}>
          {noticeText}
        </TagAlert>
      ) : null}
    </View>
  );
};

import * as Linking from 'expo-linking';

import { getInitialUrlWithoutFeatureFlagsOverrides } from '@order/LaunchDarkly';

import type { AppLinkingOptions } from './AppNavigation.props';

export const appNavigationLinking: AppLinkingOptions = {
  getInitialURL: getInitialUrlWithoutFeatureFlagsOverrides,
  prefixes: [
    Linking.createURL('/', { scheme: 'sweetgreen-order' }),
    'https://*.sweetgreen.com',
    'https://sweetgreen.com',
    'order://',
  ],
  config: {
    initialRouteName: 'MainTabs',
    screens: {
      MainTabs: {
        screens: {
          HomeTab: {
            screens: {
              Home: { path: '', exact: true },
            },
          },
          MenuTab: {
            screens: {
              Locations: { path: 'locations', exact: true },

              // A "placeholder" route that will always redirect users to the
              // menu or delivery menu route if the location slug or address ID
              // is available, or to the location selection route if it is not.
              MenuWithoutSlug: { path: 'menu', exact: true },

              Menu: { path: ':restaurantSlug/menu', exact: true },
              DeliveryMenu: {
                path: 'delivery/:addressId/menu',
                exact: true,
              },
            },
          },
          LoyaltyTab: {
            screens: {
              SweetpassHome: { path: 'sweetpass', exact: true },
              LoyaltyHome: { path: 'sg-rewards', exact: true },
            },
          },
          ScanTab: {
            screens: {
              ScanInStore: { path: 'scan-in-store', exact: true },
            },
          },
          AccountTab: {
            initialRouteName: 'AccountMenu',
            screens: {
              AccountMenu: { path: 'account/menu', exact: true },
              Profile: { path: 'account/info', exact: true },
              SweetpassMembership: {
                path: 'account/sweetpass/membership',
                exact: true,
              },
              CreditAndPromoCodes: {
                path: 'account/credit-and-promo-codes',
                exact: true,
              },
              PaymentAndGiftCards: {
                path: 'account/payment-and-gift-cards',
                exact: true,
              },
              Addresses: { path: 'account/addresses', exact: true },
              Orders: { path: 'account/orders/:tabId?', exact: true },
              Favorites: { path: 'account/favorites', exact: true },
              ReferFriend: { path: 'account/refer-friend', exact: true },
              AccountDietaryRestrictions: {
                path: 'account/dietary-restrictions',
                exact: true,
              },
            },
          },
          GiftCardsTab: {
            screens: {
              GiftCards: {
                path: 'gift',
                exact: true,
              },
              GiftCardCheckout: {
                path: '/gift/checkout/:assetId',
                exact: true,
              },
              GiftCardConfirmation: {
                path: '/gift/confirmation/:orderId',
                exact: true,
              },
              GiftCardRedemption: {
                path: '/gift/redeem/:giftCardId',
                exact: true,
              },
            },
          },
        },
      },
      Modal: {
        screens: {
          ProductDetails: {
            path: ':restaurantSlug/:productSlug/:ingredientIds?/:name?',
            exact: true,
          },
          EditProductDetails: {
            path: ':restaurantSlug/:productSlug/edit/:lineItemId',
            exact: true,
          },
          DeliveryProductDetails: {
            path: 'delivery/:addressId/:productSlug/:ingredientIds?/:name?',
            exact: true,
          },
          DeliveryEditProductDetails: {
            path: 'delivery/:addressId/:productSlug/edit/:lineItemId',
            exact: true,
          },

          DeliveryOrderInFlight: {},

          Reorder: { path: 'reorder', exact: true },
          ReorderConfirmLocation: {},
          ReorderChangeLocation: {},
          ReorderConflictReview: {},
          ReorderActiveBagWarning: {},

          PersonalData: { path: 'ca-data-requests', exact: true },
          PersonalDataDetails: {},

          Bag: {},
          Checkout: { path: 'checkout', exact: true },

          OrderStatus: { path: 'order-status/:orderId', exact: true },

          CreditAndPromoCodes: {
            path: 'credit-and-promo-codes',
            exact: true,
          },
          PaymentAndGiftCards: {
            path: 'payment-and-gift-cards/:giftCardId?',
            exact: true,
          },

          CreditAdd: {
            path: 'credit/add',
            exact: true,
          },
          CreditScan: {
            path: 'credit/scan',
            exact: true,
          },
          CreditDetails: {
            path: 'credit',
            exact: true,
          },
          AddGiftCard: {
            path: 'add-gift-card/:giftCardId?',
            exact: true,
          },
          RedeemGiftCard: {
            path: 'redeem-gift-card/:giftCardId?',
            exact: true,
          },

          DietaryRestrictions: {
            path: 'dietary-restrictions',
            exact: true,
          },
          RateOrder: { path: 'feedback/:orderId', exact: true },
          ShareLineItem: { path: 'share/:lineItemSlug', exact: true },

          SweetpassBenefits: { path: 'sweetpass/benefits', exact: true },
          SweetpassUpgrade: { path: 'sweetpass/upgrade', exact: true },
          SweetpassCheckout: { path: 'sweetpass/checkout', exact: true },
          SweetpassSwitchSubscription: {
            path: 'sweetpass/change-plan',
            exact: true,
          },

          OrderProductConfirmProduct: {
            path: '/order-product/:productSlug',
            exact: true,
          },
          OrderProductConfirmLocation: {
            path: '/order-product/:productSlug/confirm-location',
            exact: true,
          },
          OrderProductSearchLocation: {
            path: '/order-product/:productSlug/search-location',
            exact: true,
          },
        },
      },
      Auth: {
        screens: {
          JoinOrSignIn: { path: 'join-or-sign-in', exact: true },
          Login: { path: 'login', exact: true },
          Join: { path: 'join', exact: true },
        },
      },
      Error: {
        screens: {
          NotFoundWithRestaurantSlug: { path: ':restaurantSlug', exact: true },
          NotFound: { path: '*' },
        },
      },
    },
  },
};

import React, { type ComponentProps, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { ButtonGroup, theme } from '@sg/garnish';

import {
  DeliveryMethods,
  type GiftCardCheckoutFormReturn,
  type GiftCardCheckoutFormState,
} from '../../../../../../form';
import { giftCardCheckoutDeliveryMethodMessages as messages } from '../../GiftCardCheckoutDeliveryMethod.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutDeliveryMethodPicker = (
  props: GiftCardCheckoutDeliveryMethodPickerProps,
) => {
  const { form, onDeliveryMethodChange } = props;

  const { formatMessage } = useIntl();

  // ─── Derived Data ────────────────────────────────────────────────────

  const { isSubmitting } = form.formState;

  const deliveryMethod = form.watch('deliveryMethod');

  const deliveryOptions = useMemo<ButtonGroupItems>(() => {
    const { messageOptionLabel, emailOptionLabel } = messages;

    return [
      {
        value: DELIVERY_OPTION_MESSAGE,
        label: formatMessage(messageOptionLabel),
      },
      {
        value: DELIVERY_OPTION_EMAIL,
        label: formatMessage(emailOptionLabel),
      },
    ];
  }, [formatMessage]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onOptionChange = useCallback(
    async (option: string) => {
      const hasSelectedValidOption =
        option === DELIVERY_OPTION_MESSAGE || option === DELIVERY_OPTION_EMAIL;

      if (!hasSelectedValidOption) return;

      if (option === DELIVERY_OPTION_MESSAGE) {
        form.setValue('recipientEmail', undefined);
      }

      onDeliveryMethodChange(option);

      form.setValue('deliveryMethod', option);
      await form.trigger('deliveryMethod');
    },
    [form, onDeliveryMethodChange],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.optionsContainer}>
      <ButtonGroup
        value={deliveryMethod}
        gap="2"
        itemsHorizontalPadding="4"
        size="large"
        items={deliveryOptions}
        onChange={onOptionChange}
        isDisabled={isSubmitting}
      />
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const DELIVERY_OPTION_MESSAGE = DeliveryMethods.Message;
const DELIVERY_OPTION_EMAIL = DeliveryMethods.Email;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  optionsContainer: {
    paddingTop: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutDeliveryMethodPickerProps = {
  form: GiftCardCheckoutFormReturn;
  onDeliveryMethodChange: (option: DeliveryOption) => void;
};

type DeliveryOption = GiftCardCheckoutFormState['deliveryMethod'];

type ButtonGroupItems = ComponentProps<typeof ButtonGroup>['items'];

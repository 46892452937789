import React, { useCallback, useEffect, useState } from 'react';
import {
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  Platform,
} from 'react-native';
import { useSharedValue } from 'react-native-reanimated';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { getIosModalPresentationConfig, Modal } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { MenuTopDownCategories } from '@order/components/menu';
import { useTelemetry } from '@order/Telemetry';

import {
  type MenuContentCategories,
  type MenuContentOrderChannel,
} from '../../MenuContent.types';
import { MenuContentNavigationHeader } from '../MenuContentNavigationHeader';
import { useTrackAssetsLoad } from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuContentTopDownModal = (
  props: MenuContentTopDownModalProps,
) => {
  const {
    orderChannel,
    locationName,
    categories,
    isVisible,
    hideModal,
    onCategoryPress,
  } = props;

  const { navigate, goBack, canGoBack } = useNavigation();
  const isLoggedIn = useIsLoggedIn();
  const { trackEvent } = useTelemetry();
  const { trackAssetLoad } = useTrackAssetsLoad({ categories });

  // ─── State ───────────────────────────────────────────────────────────

  /**
   * For some reason, going from one modal to another causes a problem
   * where access to the target screen is lost.
   * To keep things working, we need to navigate to a different modal screen
   * when the current modal is completely dismissed.
   *
   * @see https://github.com/react-navigation/react-navigation/issues/11270
   */
  const [postDismissAction, setPostDismissAction] =
    useState<'navigate-to-reorder'>();

  // ─── Animation Helpers ───────────────────────────────────────────────

  const scrollOffsetSV = useSharedValue(0);

  const onContainerScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      // eslint-disable-next-line functional/immutable-data
      scrollOffsetSV.value = event.nativeEvent.contentOffset.y;
    },
    [scrollOffsetSV],
  );

  // Reset scroll value on toggle
  useEffect(() => {
    if (!isVisible) return;

    // eslint-disable-next-line functional/immutable-data
    scrollOffsetSV.value = 0;
  }, [isVisible, scrollOffsetSV]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onModalDismissed = useCallback(() => {
    if (postDismissAction === 'navigate-to-reorder') {
      navigate('Modal', { screen: 'Reorder' });
    }

    setPostDismissAction(undefined);
  }, [navigate, postDismissAction]);

  const onBackBtnPress = useCallback(() => {
    hideModal();
    setPostDismissAction(undefined);

    trackEvent({ name: 'menu.top-down-categories.back-button-press' });

    if (canGoBack()) {
      goBack();

      return;
    }

    navigate('MainTabs', {
      screen: 'MenuTab',
      params: { screen: 'Locations' },
    });
  }, [canGoBack, goBack, hideModal, navigate, trackEvent]);

  const closeModal = useCallback(() => {
    hideModal();
    trackEvent({ name: 'menu.top-down-categories.close-button-press' });
  }, [hideModal, trackEvent]);

  const navigateToRecentOrdersOnDismiss = useCallback(() => {
    setPostDismissAction('navigate-to-reorder');
    hideModal();
    trackEvent({
      name: 'menu.top-down-categories.navigate-to-reorder-button-press',
    });
  }, [hideModal, trackEvent]);

  const handleCategoryPress = useCallback(
    (categoryId: string) => {
      onCategoryPress(categoryId);
      hideModal();

      const targetCategoryIndex = categories.findIndex(
        (category) => category.id === categoryId,
      );
      const targetCategory = categories[targetCategoryIndex];

      if (!targetCategory) return;

      trackEvent({
        name: 'menu.top-down-categories.category-press',
        payload: {
          categoryName: targetCategory.name,
          categoryIndex: targetCategoryIndex,
        },
      });
    },
    [categories, hideModal, onCategoryPress, trackEvent],
  );

  // ─── Effects ─────────────────────────────────────────────────────────

  // Hide modal on blur
  useFocusEffect(useCallback(() => hideModal, [hideModal]));

  useEffect(() => {
    if (!isVisible) return;

    trackEvent({ name: 'menu.top-down-categories.view' });
  }, [isVisible, trackEvent]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Modal
      size="full"
      fitHeight
      visible={isVisible}
      onRequestClose={closeModal}
      onDismissed={onModalDismissed}
      {...getIosModalPresentationConfig()}
    >
      <MenuContentNavigationHeader
        orderChannel={orderChannel}
        locationName={locationName}
        onCloseBtnPressOverride={closeModal}
        onBackBtnPressOverride={onBackBtnPress}
        scrollOffsetSV={scrollOffsetSV}
        safeAreaEdges={safeAreaEdges}
        testID="menu-content.top-down-categories-modal.navigation-header"
      />

      <MenuTopDownCategories.Container
        onScroll={onContainerScroll}
        scrollEventThrottle={16}
      >
        {isLoggedIn ? (
          <MenuTopDownCategories.RecentOrdersButton
            onPress={navigateToRecentOrdersOnDismiss}
          />
        ) : null}

        {categories.map((category) => {
          const maybeCategoryImageSrc = category?.products?.[0]?.asset?.url;

          return (
            <MenuTopDownCategories.Item.Pressable
              key={category.id}
              categoryId={category.id}
              onPress={handleCategoryPress}
            >
              {maybeCategoryImageSrc ? (
                <MenuTopDownCategories.Item.Image
                  categoryId={category.id}
                  source={maybeCategoryImageSrc}
                  alt={category.name}
                  isCustomCategory={category.isCustom}
                  onLoad={trackAssetLoad}
                />
              ) : null}

              <MenuTopDownCategories.Item.DetailsContainer>
                <MenuTopDownCategories.Item.Title>
                  {category.name}
                </MenuTopDownCategories.Item.Title>
                {category.description ? (
                  <MenuTopDownCategories.Item.Description>
                    {category.description}
                  </MenuTopDownCategories.Item.Description>
                ) : null}
              </MenuTopDownCategories.Item.DetailsContainer>
            </MenuTopDownCategories.Item.Pressable>
          );
        })}
      </MenuTopDownCategories.Container>
    </Modal>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const safeAreaEdges = Platform.OS === 'android' ? (['top'] as const) : null;

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuContentTopDownModalProps = {
  isVisible: boolean;
  orderChannel: MenuContentOrderChannel;
  locationName: string;
  categories: MenuContentCategories;
  hideModal: () => void;
  onCategoryPress: (categoryId: string) => void;
};

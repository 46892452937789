/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import { CartDataFragmentDoc } from '../../../components/Bag/GraphQL/Cart.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReorderOrderLineItemMutationVariables = Types.Exact<{
  input: Types.ReorderInput;
}>;

export type ReorderOrderLineItemMutation = {
  readonly __typename?: 'Mutation';
  readonly reorderLineItem:
    | {
        readonly __typename: 'CanNotReorder';
        readonly message: string;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: Types.ConflictType;
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly kind: Types.IngredientKind | null;
          }>;
        }>;
      }
    | {
        readonly __typename: 'ReorderConflict';
        readonly isNothingAvailable: boolean;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: Types.ConflictType;
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly kind: Types.IngredientKind | null;
          }>;
        }>;
      }
    | {
        readonly __typename: 'ReorderSuccess';
        readonly isMixingSupported: boolean;
        readonly cart: {
          readonly __typename?: 'Order';
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly canTrackOrderStatus: boolean;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename?: 'LedgerItem';
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename?: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: Types.DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename?: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename?: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename?: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: Types.IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: Types.DressingWeight;
            }>;
          }>;
          readonly restaurant: {
            readonly __typename?: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename?: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename?: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename?: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: Types.DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | {
        readonly __typename: 'RestaurantMaxDeliveryQuantityExceeded';
        readonly message: string;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: Types.ConflictType;
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly kind: Types.IngredientKind | null;
          }>;
        }>;
      }
    | {
        readonly __typename: 'RestaurantMaxQuantityExceeded';
        readonly message: string;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: Types.ConflictType;
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename?: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly kind: Types.IngredientKind | null;
          }>;
        }>;
      }
    | { readonly __typename: 'ValidationError' };
};

export type ReorderConflictFragment = {
  readonly __typename?: 'OrderConflict';
  readonly type: Types.ConflictType;
  readonly unavailableIngredients: ReadonlyArray<{
    readonly __typename?: 'Ingredient';
    readonly id: string;
    readonly name: string;
    readonly kind: Types.IngredientKind | null;
  }>;
};

export const ReorderConflictFragmentDoc = gql`
  fragment ReorderConflict on OrderConflict {
    type
    unavailableIngredients {
      id
      name
      kind
    }
  }
`;
export const ReorderOrderLineItemDocument = gql`
  mutation reorderOrderLineItem($input: ReorderInput!) {
    reorderLineItem(input: $input) {
      __typename
      ... on ReorderSuccess {
        cart {
          ...CartData
        }
        isMixingSupported
      }
      ... on ReorderConflict {
        isNothingAvailable
        conflicts {
          ...ReorderConflict
        }
      }
      ... on RestaurantMaxDeliveryQuantityExceeded {
        message
        conflicts {
          ...ReorderConflict
        }
      }
      ... on RestaurantMaxQuantityExceeded {
        message
        conflicts {
          ...ReorderConflict
        }
      }
      ... on CanNotReorder {
        message
        conflicts {
          ...ReorderConflict
        }
      }
    }
  }
  ${CartDataFragmentDoc}
  ${ReorderConflictFragmentDoc}
`;

export function useReorderOrderLineItemMutation() {
  return Urql.useMutation<
    ReorderOrderLineItemMutation,
    ReorderOrderLineItemMutationVariables
  >(ReorderOrderLineItemDocument);
}

import { useIsFocused } from '@react-navigation/native';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useLocalizationContext } from '@order/Localization';

import { useGiftCardBalanceQuery } from '../../graphql/GiftCardBalance.generated';

// ─────────────────────────────────────────────────────────────────────────────

export const useGiftCardBalance = () => {
  const { formatPrice } = useLocalizationContext();

  const isLoggedIn = useIsLoggedIn();
  const isScreenFocused = useIsFocused();

  // ─────────────────────────────────────────────────────────────────────

  const [{ data, fetching: isFetching }] = useGiftCardBalanceQuery({
    requestPolicy: 'cache-and-network',
    pause: !isLoggedIn || !isScreenFocused,
  });

  const giftCardBalanceData = data?.giftCardBalance;
  const giftCardBalance =
    giftCardBalanceData?.__typename === 'GiftCardBalance'
      ? giftCardBalanceData.giftCardBalance
      : 0;
  const giftCardBalanceFormatted = formatPrice(giftCardBalance, 'USD');

  // ─────────────────────────────────────────────────────────────────────

  return { balance: giftCardBalanceFormatted, isFetching };
};

import { useCallback } from 'react';
import { useMemo } from 'react';

import { TierStatusName } from '@order/graphql';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import type {
  AccountSweetpassBillingHistorySection,
  AccountSweetpassCancellationSection,
  AccountSweetpassMembershipSection,
  AccountSweetpassPaymentMethodsSection,
} from '../useSweetpassAccountContentfulData';
import {
  ACCOUNT_SWEETPASS_BILLING_HISTORY_SECTION,
  ACCOUNT_SWEETPASS_CANCELLATION_SECTION,
  ACCOUNT_SWEETPASS_MEMBERSHIP_SECTION,
  ACCOUNT_SWEETPASS_PAYMENT_METHODS_SECTION,
  type useSweetpassAccountContentfulData,
} from '../useSweetpassAccountContentfulData';
import { type useSweetpassMembershipData } from '../useSweetpassMembershipData';
import {
  getBillingHistorySection,
  getCancellationSection,
  getMembershipLevelSection,
  getPaymentMethodsSection,
} from './utils';

export const useSweetpassMembershipContent = (
  props: UseSweetpassMembershipContentProps,
) => {
  const {
    content,
    cancelSubscriptionAtCycleEnd,
    reactivateSubscription,
    switchSubscriptionHandler,
    ...membership
  } = props;

  const { track } = useTelemetry();
  const {
    formatMessage,
    formatDurationUnitName,
    formatDistanceBetweenDatesInDays,
  } = useLocalizationContext();

  const canSwitchSubscription = useFeatureFlag(
    'CELS-1260-enable-annual-subscriptions',
  );

  const isSweetpassPlusDisabled = useFeatureFlag(
    'CELS-2877-disable-sweetpass-upgrade',
  );

  // ─── Callbacks ─────────────────────────────────────────────────────────

  const cancelSubscriptionHandler = useCallback(async () => {
    await cancelSubscriptionAtCycleEnd();
    track('sweetpass_account.cancel_cta.tapped', {
      isFreeTrial: membership.tierStatus === TierStatusName.FreeTrial,
      isMonthlySubscription: true,
      isAnnualSubscription: false,
    });
  }, [membership.tierStatus, cancelSubscriptionAtCycleEnd, track]);

  const reactivateSubscriptionHandler = useCallback(async () => {
    // Is there a reactivate subscription confirmation modal?
    track('sweetpass_account.rejoin_cta.tapped');
    await reactivateSubscription();
  }, [reactivateSubscription, track]);

  // ─────────────────────────────────────────────────────────────────────

  return useMemo(() => {
    return content?.map((section) => ({
      // ─── Membership Section ───────────────────────────────────

      ...(section.name === ACCOUNT_SWEETPASS_MEMBERSHIP_SECTION
        ? getMembershipLevelSection({
            ...(section as AccountSweetpassMembershipSection),
            ...membership,
            canSwitchSubscription,
            isSweetpassPlusDisabled,
            switchSubscriptionHandler,
            reactivateSubscriptionHandler,
            formatMessage,
            formatDurationUnitName,
            formatDistanceBetweenDatesInDays,
          })
        : {}),

      // ─── Cancellation Section ─────────────────────────────────

      ...(section.name === ACCOUNT_SWEETPASS_CANCELLATION_SECTION
        ? getCancellationSection({
            ...(section as AccountSweetpassCancellationSection),
            ...membership,
            formatMessage,
            cancelSubscriptionHandler,
          })
        : {}),

      // ─── Payment Methods Section ──────────────────────────────

      ...(section.name === ACCOUNT_SWEETPASS_PAYMENT_METHODS_SECTION
        ? getPaymentMethodsSection({
            ...(section as AccountSweetpassPaymentMethodsSection),
            ...membership,
            formatMessage,
          })
        : {}),

      // ─── Billing History Section ──────────────────────────────

      ...(section.name === ACCOUNT_SWEETPASS_BILLING_HISTORY_SECTION
        ? getBillingHistorySection({
            ...(section as AccountSweetpassBillingHistorySection),
            ...membership,
            formatMessage,
          })
        : {}),
    }));
  }, [
    content,
    membership,
    canSwitchSubscription,
    isSweetpassPlusDisabled,
    formatMessage,
    formatDurationUnitName,
    formatDistanceBetweenDatesInDays,
    switchSubscriptionHandler,
    reactivateSubscriptionHandler,
    cancelSubscriptionHandler,
  ]);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseSweetpassMembershipContentProps = Readonly<{
  subscribeHandler: () => Promise<void>;
  viewPaymentMethodHandler: (paymentMethodId: string) => void;
  addPaymentMethodHandler: () => void;
  viewBillingHistoryHandler: () => void;
  switchSubscriptionHandler: () => Promise<void>;
}> &
  Omit<
    ReturnType<typeof useSweetpassMembershipData>,
    | 'loyaltyProfileError'
    | 'hasMembershipError'
    | 'isSweetpassMembershipLoading'
  > &
  Omit<
    ReturnType<typeof useSweetpassAccountContentfulData>,
    'isLoadingContentfulData'
  >;

import type { ComponentProps } from 'react';
import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import type { Asset } from 'contentful';
import type { FloatingHeader } from '@sg/garnish';
import { useResponsive } from '@sg/garnish';

import { useCustomer } from '@order/Customer';
import { useSweetpassTier } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import { SweetpassHeader } from './SweetpassFloatingHeader.components';
import {
  useSweetpassPlusTierHeaderData,
  useSweetpassTierHeaderData,
} from './SweetpassFloatingHeader.data';

// ─────────────────────────────────────────────────────────────────────────────

export const SweetpassFloatingHeader = (
  props: SweetpassFloatingHeaderContentProps,
) => {
  const { scrollDistance, offset } = props;

  const { customer } = useCustomer();
  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();

  const { sweetpassTierName, joinDate } = useSweetpassTier();

  const shouldRender =
    currentBreakpoint.isXS && sweetpassTierName !== undefined;

  // ─────────────────────────────────────────────────────────────────────

  const isSweetpassCustomer = sweetpassTierName === 'SWEETPASS';
  const isSweetpassPlusCustomer =
    sweetpassTierName === 'SWEETPASS_PLUS' || sweetpassTierName === 'OPTED_OUT';
  const customerFirstName = t('sweetpass.home.header-name', {
    firstName: customer.firstName ?? '',
  });

  // ─────────────────────────────────────────────────────────────────────

  if (!shouldRender) return null;

  if (isSweetpassCustomer) {
    return (
      <SweetpassTierFloatingHeader
        customerFirstName={customerFirstName}
        scrollDistance={scrollDistance}
        offset={offset}
      />
    );
  }

  if (isSweetpassPlusCustomer) {
    return (
      <SweetpassPlusTierFloatingHeader
        customerFirstName={customerFirstName}
        scrollDistance={scrollDistance}
        offset={offset}
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        joinDate={joinDate}
      />
    );
  }

  return null;
};

// ─── Variations ──────────────────────────────────────────────────────────────

const SweetpassTierFloatingHeader = (props: SweetpassTierHeaderProps) => {
  const { customerFirstName, scrollDistance, offset } = props;

  const {
    upgradeCtaHint,
    upgradeCtaLabel,
    tierIllustrationURL,
    fetching,
    hasData,
  } = useSweetpassTierHeaderData();

  const tierName = 'sweetpass';

  // ─── Helpers ─────────────────────────────────────────────────────────

  const { navigate } = useNavigation();
  const navigateToUpgradeScreen = useCallback(() => {
    navigate('Modal', { screen: 'SweetpassUpgrade' });
  }, [navigate]);

  // ─────────────────────────────────────────────────────────────────────

  if (fetching) {
    return (
      <SweetpassHeader.Loading
        scrollDistance={scrollDistance}
        offset={offset}
      />
    );
  }

  if (hasData) {
    return (
      <SweetpassHeader.Container
        scrollDistance={scrollDistance}
        offset={offset}
      >
        <SweetpassHeader.ContentContainer>
          <SweetpassHeader.Title
            customerFirstName={customerFirstName}
            tierName={tierName}
          />

          {upgradeCtaLabel ? (
            <SweetpassHeader.UpgradeCta
              label={upgradeCtaLabel}
              hintText={upgradeCtaHint}
              onPress={navigateToUpgradeScreen}
            />
          ) : null}
        </SweetpassHeader.ContentContainer>

        {tierIllustrationURL ? (
          <SweetpassHeader.TierIllustration
            sourceURL={tierIllustrationURL}
            tierName={tierName}
          />
        ) : null}
      </SweetpassHeader.Container>
    );
  }

  return null;
};

const SweetpassPlusTierFloatingHeader = (
  props: SweetpassPlusTierHeaderProps,
) => {
  const { customerFirstName, joinDate, scrollDistance, offset } = props;

  const {
    formattedMemberSinceInfoText,
    tierIllustrationURL,
    fetching,
    hasData,
  } = useSweetpassPlusTierHeaderData({ joinDate });

  const tierName = 'sweetpass+';

  // ─────────────────────────────────────────────────────────────────────

  if (fetching) {
    return (
      <SweetpassHeader.Loading
        scrollDistance={scrollDistance}
        offset={offset}
      />
    );
  }

  if (hasData) {
    return (
      <SweetpassHeader.Container
        scrollDistance={scrollDistance}
        offset={offset}
      >
        <SweetpassHeader.ContentContainer>
          <SweetpassHeader.Title
            customerFirstName={customerFirstName}
            tierName={tierName}
          />

          {formattedMemberSinceInfoText ? (
            <SweetpassHeader.MemberSinceText
              memberSinceInfoText={formattedMemberSinceInfoText}
            />
          ) : null}
        </SweetpassHeader.ContentContainer>

        {tierIllustrationURL ? (
          <SweetpassHeader.TierIllustration
            sourceURL={tierIllustrationURL}
            tierName={tierName}
          />
        ) : null}
      </SweetpassHeader.Container>
    );
  }

  return null;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassFloatingHeaderContentProps = Pick<
  ComponentProps<typeof FloatingHeader>,
  'scrollDistance' | 'offset'
>;

type SweetpassTierHeaderProps = SweetpassFloatingHeaderContentProps &
  Readonly<{
    customerFirstName: string;
  }>;

type SweetpassPlusTierHeaderProps = SweetpassFloatingHeaderContentProps &
  Readonly<{
    customerFirstName: string;
    joinDate: string;
  }>;

export type SweetpassHeaderEntryFields = Readonly<{
  upgradeCtaLabel?: string;
  upgradeCtaHint?: string;
  memberSinceInfoText?: string;
  tierIllustration: Asset;
}>;

import type { MutableRefObject } from 'react';
import { type GraphQLErrorExtensions } from 'graphql';
import type { CombinedError, Exchange, Operation } from 'urql';
import { errorExchange } from 'urql';

import type { ErrorHandlerReference } from '../UrqlProvider';

/**
 * This allows tracking mutation errors through a reference.
 * This reference will later be loaded with a telemetry handler.
 * @see apps/order/src/context/Urql/hooks/useRegisterUrqlErrorTracker/useRegisterUrqlErrorTracker.ts
 * @see https://formidable.com/open-source/urql/docs/advanced/authoring-exchanges/#using-exchanges
 */
export const errorTrackingExchange = (
  params: ErrorTrackingExchangeParams,
): Exchange => {
  const { logOutHandlerReference, errorHandlerReference } = params;

  return errorExchange({
    async onError(error: CombinedError, operation: Operation) {
      const hasAuthError = checkIfDidAuthError(error);

      if (hasAuthError) {
        await logOutHandlerReference.current?.();
      }

      errorHandlerReference.current?.(error, operation);
    },
  });
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function checkIfDidAuthError(error: CombinedError) {
  const { message, graphQLErrors } = error;

  const hasGenericSignedOutError = message.includes(
    GENERIC_SIGNED_OUT_ERROR_MESSAGE,
  );
  const hasUnauthorizedResponse = graphQLErrors.some((graphQLError) => {
    const extensions =
      graphQLError.extensions as GraphQLErrorExtensionsWithErrorName;

    return (
      extensions?.code === UNAUTHENTICATED_CODE ||
      extensions?.error?.name === GRAVY_NOT_LOGGED_IN_ERROR_NAME
    );
  });

  return hasGenericSignedOutError || hasUnauthorizedResponse;
}

// ─── Constants ───────────────────────────────────────────────────────────────

const UNAUTHENTICATED_CODE = 'UNAUTHENTICATED';
const GENERIC_SIGNED_OUT_ERROR_MESSAGE = 'Please login to continue';
const GRAVY_NOT_LOGGED_IN_ERROR_NAME = 'GravyNotLoggedIn';

// ─── Types ───────────────────────────────────────────────────────────────────

type ErrorTrackingExchangeParams = {
  errorHandlerReference: ErrorHandlerReference;
  logOutHandlerReference: MutableRefObject<(() => Promise<void>) | null>;
};

type GraphQLErrorExtensionsWithErrorName = {
  error?: { name?: string };
} & GraphQLErrorExtensions;

import React from 'react';

import type {
  SweetpassBenefitList,
  SweetpassBenefitsCardsSection,
  SweetpassBenefitsContent,
  SweetpassBenefitsCta,
  SweetpassBenefitsPageBenefitCtaHandler,
  SweetpassBenefitsPageCardCtaHandler,
  SweetpassBenefitsPageUpgradeCtaHandler,
} from './hooks';
import { SweetpassBenefitsPageBenefitsContent } from './SweetpassBenefitsPageBenefitsContent';
import { SweetpassBenefitsPageCardsContent } from './SweetpassBenefitsPageCardsContent';
import { SweetpassBenefitsPageCtaContent } from './SweetpassBenefitsPageCtaContent';

// ─── COMPONENTS ─────────────────────────────────────────────────────────────────

export const SweetpassBenefitsInnerContent = ({
  content,
  benefitCtaHandler,
  upgradeCtaHandler,
  cardCtaHandler,
}: SweetpassBenefitsPageInnerContentProps) => {
  if (content.type === 'sweetpassBenefitsList') {
    const benefitsList = content as SweetpassBenefitList;

    return (
      <SweetpassBenefitsPageBenefitsContent
        key={content.id}
        content={benefitsList}
        ctaHandler={benefitCtaHandler}
      />
    );
  }

  if (content.type === 'sweetpassBenefitsCta') {
    const benefitsCtaContent = content as SweetpassBenefitsCta;

    return (
      <SweetpassBenefitsPageCtaContent
        key={content.id}
        content={benefitsCtaContent}
        ctaHandler={upgradeCtaHandler}
      />
    );
  }

  if (content.type === 'sweetpassBenefitsCardsSection') {
    const benefitsCardsSection = content as SweetpassBenefitsCardsSection;

    return (
      <SweetpassBenefitsPageCardsContent
        key={content.id}
        content={benefitsCardsSection}
        ctaHandler={cardCtaHandler}
      />
    );
  }

  return null;
};

type SweetpassBenefitsPageInnerContentProps = Readonly<{
  content: SweetpassBenefitsContent;
  upgradeCtaHandler: SweetpassBenefitsPageUpgradeCtaHandler;
  benefitCtaHandler: SweetpassBenefitsPageBenefitCtaHandler;
  cardCtaHandler: SweetpassBenefitsPageCardCtaHandler;
}>;

import { useMemo } from 'react';

import { CostChannel } from '@order/graphql';
import { useCart } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';

import {
  type CategoryUpsellsQuery,
  useCategoryUpsellsQuery,
} from '../../GraphQL/CategoryUpsells.generated';

// ─────────────────────────────────────────────────────────────────────────────

export const useCategoryUpsells = ({ isDelivery }: { isDelivery: boolean }) => {
  const { cart } = useCart();
  const isCategoryUpsellsEnabled = useFeatureFlag(
    'mnu-5612-short-enable-upsell-categories-modal',
  );

  const { lineItems = [], deliveryOrderDetail } = cart ?? {};

  const activeCartRestaurantId = cart?.restaurant.id ?? '';
  const variables = {
    restaurantId: activeCartRestaurantId,
    costChannel: isDelivery
      ? CostChannel.DeliveryCostChannel
      : CostChannel.DefaultCostChannel,
  };

  // ─── Queries ─────────────────────────────────────────────────────────

  const [response] = useCategoryUpsellsQuery({
    variables,
    pause: !isCategoryUpsellsEnabled || !activeCartRestaurantId,
    requestPolicy: 'cache-and-network',
  });

  const isLoading = response.fetching;

  // ─── Derived Data ────────────────────────────────────────────────────

  const categoryUpsells = response.data?.categoryUpsells;

  const filteredCategoryUpsells = useMemo(() => {
    const lineItemProductIds = lineItems.map(({ product }) => product?.id);
    const existingLineItemsProductsIds = new Set(lineItemProductIds);

    return categoryUpsells
      ?.map(filterCategoryProducts(existingLineItemsProductsIds))
      .filter(filterEmptyCategories);
  }, [categoryUpsells, lineItems]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    categoryUpsells: filteredCategoryUpsells,
    deliveryOrderDetail,
    isLoading,
  };
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function filterCategoryProducts(existingLineItemsProductsIds: Set<string>) {
  return function mapCategory(category: UpsellCategoryObject) {
    const { products } = category;
    const filteredProducts = products.filter(
      filterUnwantedProduct(existingLineItemsProductsIds),
    );

    return { ...category, products: filteredProducts };
  };
}

function filterEmptyCategories(category: UpsellCategoryObject) {
  return category.products.length > 0;
}

function filterUnwantedProduct(existingLineItemsProductsIds: Set<string>) {
  return function filterProduct(product: UpsellCategoryProduct) {
    return !product.outOfStock && !existingLineItemsProductsIds.has(product.id);
  };
}

// ─── Types ───────────────────────────────────────────────────────────────────

type UpsellCategoryProduct = UpsellCategoryObject['products'][number];

type UpsellCategoryObject = NonNullable<
  CategoryUpsellsQuery['categoryUpsells']
>[number];

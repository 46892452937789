import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import {
  Container,
  ScrollViewWithHeaderTitle,
  theme,
  useResponsive,
} from '@sg/garnish';

import { AppFooter } from '@order/components';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardConfirmationContainer = (props: PropsWithChildren) => {
  const { match, minWidth } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = match([styles.containerXS, styles.containerSM]);

  // ─────────────────────────────────────────────────────────────────────────────

  return (
    <ScrollViewWithHeaderTitle
      contentContainerStyle={styles.contentContainerStyle}
    >
      <Container style={containerStyles}>{props.children}</Container>

      {minWidth.isSM ? <AppFooter /> : null}
    </ScrollViewWithHeaderTitle>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  contentContainerStyle: {
    minHeight: '100%',
  },
  containerXS: {
    paddingBottom: theme.spacing['4'],
  },
  containerSM: {
    alignItems: 'center',
    paddingVertical: theme.spacing['12'],
  },
});

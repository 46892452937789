/* eslint-disable functional/immutable-data */

import type { Cache } from '@urql/exchange-graphcache';

import type {
  DeletePaymentMethodCardInput,
  DeletePaymentMethodCardMutation,
  PaymentMethodCard,
  UpdatePaymentMethodInput,
  UpdatePaymentMethodMutation,
} from '@order/graphql';
import { PaymentMethodCardsDocument } from '@order/graphql';

/**
 * Changing payment methods returns a payment method object.
 * This is fine for when the payment method is updated,
 * But if it is added or removed, this is needed to update
 * the current customer's object's payment methods in URQL cache.
 */

export const updatePaymentMethod = (
  result: UpdatePaymentMethodMutation,
  _args: UpdatePaymentMethodInput,
  cache: Cache,
) => {
  const response = result.updatePaymentMethod;
  const paymentMethod = response as PaymentMethodCard;

  // Ignore unsuccessful mutations.
  if (!paymentMethod?.id) return;

  updateCacheWithPaymentMethodCardChanges(cache, { paymentMethod });
};

export const deletePaymentMethodCard = (
  result: DeletePaymentMethodCardMutation,
  args: Readonly<{ input: DeletePaymentMethodCardInput }>,
  cache: Cache,
) => {
  const deletedId = args?.input.id;

  const response = result.deletePaymentMethodCard;
  const paymentMethod = response as PaymentMethodCard;

  // Ignore unsuccessful mutations.
  if (!deletedId || !paymentMethod?.id) return;

  updateCacheWithPaymentMethodCardChanges(cache, {
    paymentMethod: { id: String(deletedId) },
    removed: true,
  });
};

export const updateCacheWithPaymentMethodCardChanges = (
  paymentMethodCache: Cache,
  paymentMethodChanges: PaymentMethodCardChanges,
) => {
  paymentMethodCache.updateQuery(
    { query: PaymentMethodCardsDocument },
    (data) => {
      if (!data) return data;

      // Get cached payment methods.
      const paymentMethods =
        data?.paymentMethodCards as readonly PaymentMethodCard[];

      // Get stale payment method.
      const stalePaymentMethod = paymentMethods.find(
        ({ id }) => id === paymentMethodChanges.paymentMethod.id,
      );

      // Remove stale payment method from list.
      const withoutUpdated = paymentMethods.filter(
        ({ id }) => id !== paymentMethodChanges.paymentMethod.id,
      );

      // If the payment method being removed was default, set the first other as default.
      if (paymentMethodChanges.removed && stalePaymentMethod?.isDefault) {
        data.paymentMethodCards = withoutUpdated.map((paymentMethod, index) => {
          if (index === 0) return { ...paymentMethod, isDefault: true };

          return paymentMethod;
        });

        return data;
      }

      // If the payment method was removed, remove it from the cache.
      if (paymentMethodChanges.removed) {
        data.paymentMethodCards = withoutUpdated;

        return data;
      }

      // If the payment method was changed to default, remove other defaults from the list.
      if (paymentMethodChanges.paymentMethod?.isDefault) {
        data.paymentMethodCards = [
          ...withoutUpdated.map((paymentMethod) => ({
            ...paymentMethod,
            isDefault: false,
          })),
          paymentMethodChanges.paymentMethod as PaymentMethodCard,
        ].sort((c1, c2) => c1.id.localeCompare(c2.id));

        return data;
      }

      // If the payment method was added, add it and sort by id.
      data.paymentMethodCards = [
        ...withoutUpdated,
        paymentMethodChanges.paymentMethod as PaymentMethodCard,
      ].sort((c1, c2) => c1.id.localeCompare(c2.id));

      return data;
    },
  );
};

type PaymentMethodCardChanges = Readonly<{
  paymentMethod: Partial<PaymentMethodCard>;
  removed?: boolean;
}>;

import { getEnvVars } from '../common';

/**
 * A small utility that determines whether ReCaptcha should be enabled
 * based on an environment setting.
 */
export function checkIfRecaptchaEnabled() {
  const envVars = getEnvVars();

  return envVars.RECAPTCHA_ENABLED === 'true';
}

import { useCallback } from 'react';
import { useSelector } from '@xstate5/react';
import { type ActorRefFrom } from 'xstate5';

import { type loyaltyOffersModalMachine } from '../../machine';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A hook that provides state and various helpers to interact with "loyalty offers modal" machine.
 *
 * @param params.actorRef - A global reference to the actor managing the state machine for the modal.
 */
export const useLoyaltyOffersModalMachine = (
  params: UseLoyaltyOffersModalMachineParams,
) => {
  const { actorRef } = params;

  // ─── State ───────────────────────────────────────────────────────────

  const isReady = useSelector(actorRef, (snapshot) => {
    return !snapshot.matches('setup');
  });

  const isShowingModal = useSelector(actorRef, (snapshot) => {
    return snapshot.matches('showing');
  });

  const hasAlreadyShowedModal = useSelector(actorRef, (snapshot) => {
    return snapshot.matches('already-shown');
  });

  // ─── Context ─────────────────────────────────────────────────────────

  const alreadySeenOfferIds = useSelector(actorRef, (snapshot) => {
    return snapshot.context.alreadySeenOfferIds;
  });

  // ─── Helpers ─────────────────────────────────────────────────────────

  const showModal = useCallback(() => {
    actorRef.send({ type: 'show' });
  }, [actorRef]);

  const hideModal = useCallback(
    (seenOfferIds: string[] = []) => {
      actorRef.send({ type: 'hide', seenOfferIds });
    },
    [actorRef],
  );

  const resetState = useCallback(() => {
    actorRef.send({ type: 'reset' });
  }, [actorRef]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    context: {
      alreadySeenOfferIds,
    },
    state: {
      isReady,
      isShowingModal,
      hasAlreadyShowedModal,
    },
    helpers: {
      showModal,
      hideModal,
      resetState,
    },
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseLoyaltyOffersModalMachineParams = {
  actorRef: ActorRefFrom<typeof loyaltyOffersModalMachine>;
};

import { defineMessages } from 'react-intl';

export const giftCardRedemptionScreenMessages = defineMessages({
  screenTitle: {
    defaultMessage: 'Redemption details',
    description: 'Gift Card Redemption Screen | Title',
  },
  screenCustomDocumentTitle: {
    defaultMessage: 'Redemption details | Gift Card | sweetgreen',
    description: 'Gift Card Redemption Screen | Custom Document Title',
  },
});

/* eslint-disable @typescript-eslint/brace-style */
import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { View, ViewProps } from 'react-native';
import { Animated, Platform } from 'react-native';

import { useReduceMotionStatus } from '../../hooks';

export const FadeView = React.forwardRef<View, FadeViewProps>(
  (
    { show, duration = 200, style, children, keepMounted, ...restProps },
    ref,
  ) => {
    const [shouldRenderChildren, setShouldRenderChildren] = useState(show);
    const reduceMotionStatus = useReduceMotionStatus();

    // ────────────────────────────────────────────────────────────────────────────────

    const opacity = useRef(new Animated.Value(0)).current;
    const nativeDriverConfig = Platform.OS !== 'web';

    //
    // ─── HELPERS ────────────────────────────────────────────────────────────────────
    //

    const showChildren = useCallback(() => {
      if (reduceMotionStatus === 'undetermined') return;

      setShouldRenderChildren(true);
      Animated.timing(opacity, {
        toValue: 1,
        duration: reduceMotionStatus === 'disabled' ? duration : 0,
        useNativeDriver: nativeDriverConfig,
      }).start();
    }, [duration, nativeDriverConfig, opacity, reduceMotionStatus]);

    const hideChildren = useCallback(() => {
      if (reduceMotionStatus === 'undetermined') return;

      Animated.timing(opacity, {
        toValue: 0,
        duration: reduceMotionStatus === 'disabled' ? duration : 0,
        useNativeDriver: nativeDriverConfig,
      }).start(({ finished }) => {
        // unmount component if the animation was not interrupted...
        if (finished) {
          setShouldRenderChildren(false);
        }

        // ...otherwise reset the animation's initial state
        else {
          showChildren();
        }
      });
    }, [
      duration,
      nativeDriverConfig,
      opacity,
      reduceMotionStatus,
      showChildren,
    ]);

    // ────────────────────────────────────────────────────────────────────────────────

    useEffect(() => {
      let hideTimeoutRef: ReturnType<typeof setTimeout>;

      if (show) {
        showChildren();
      } else {
        hideTimeoutRef = setTimeout(hideChildren, 0); // prevent infinite loops in jest
      }

      return () => {
        clearTimeout(hideTimeoutRef);
      };
    }, [hideChildren, show, showChildren]);

    // ────────────────────────────────────────────────────────────────────────────────

    return shouldRenderChildren || keepMounted ? (
      <Animated.View ref={ref} style={[style, { opacity }]} {...restProps}>
        {children}
      </Animated.View>
    ) : null;
  },
);

//
// ─── TYPES ──────────────────────────────────────────────────────────────────────
//

type FadeViewProps = Readonly<{
  children?: ReactNode;
  show: boolean;
  duration?: number;
  keepMounted?: boolean;
}> &
  ViewProps;

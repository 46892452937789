import React, { forwardRef, memo, useRef } from 'react';
import { Platform, TextInput, View } from 'react-native';
import useMergedRef from '@react-hook/merged-ref';
import { COLORS } from '@garnish/constants';

import { useResponsive, useUniqueNativeID } from '../../hooks';
import { webOnlyStyles } from '../../utils';
import { useKeyboardAvoiderScroll } from '../KeyboardAvoider';
import { BodyText } from '../Text';
import { usePlatformSpecificAutocomplete } from './hooks';
import {
  TextFieldClearButton,
  TextFieldFloatingLabel,
  TextFieldHelperText,
  TextFieldIcon,
  TextFieldNotice,
  TextFieldPasswordToggle,
  TextFieldSubmitButton,
} from './subcomponents';
import {
  CLEAR_BTN_A11Y_HINT,
  CLEAR_BTN_LABEL,
  FIELD_OPTION_BTN_A11Y_HINT,
  FIELD_OPTION_BTN_LABEL,
  HIDE_PASSWORD_LABEL,
  SHOW_PASSWORD_LABEL,
  SUBMIT_A11Y_HINT,
  SUBMIT_BTN_A11Y_LABEL,
} from './TextField.constants';
import {
  useConditionalStyles,
  useHoverAndFocusState,
  usePasswordHelpers,
  useTextFieldHelpers,
} from './TextField.hooks';
import { styles } from './TextField.styles';
import type {
  CreateAccessibilityDescribedByListProps,
  TextFieldProps,
} from './TextField.types';

/**
 * "TextField" component
 *
 * Acts as a wrapper component for React Native "TextInput"
 * that adds additional functionality like clear and submit buttons, etc.
 */
export const TextField = memo(
  forwardRef((props: TextFieldProps, ref) => {
    const {
      label,
      labelSizeMatch,
      labelOffset,

      notice,
      noticePalette = 'neutral',

      helperText,
      fieldOptionIcon,

      clearButtonType = 'none',
      clearBtnLabel = CLEAR_BTN_LABEL,

      showPasswordLabel = SHOW_PASSWORD_LABEL,
      hidePasswordLabel = HIDE_PASSWORD_LABEL,
      submitButtonIcon,
      placeholder,
      autoComplete = 'off',
      textPrepend,

      style,
      containerStyle,
      wrapperStyle,

      hideSubmitButton,
      hideNoticeSpace,
      disabled: isDisabled = false,
      editable = true,
      invalid,
      secureTextEntry,
      multiline = false,

      testID = 'sg-text-field',
      containerTestID = 'sg-text-field-container',
      submitButtonTestID = 'sg-text-field-submit-btn',
      toggleTestID = 'sg-text-field-toggle',

      accessibilityLabel,
      accessibilityLabelledBy,
      accessibilityDescribedBy,
      accessibilityErrorMessage,
      accessibilityInvalid,
      fieldOptionAccessibilityLabel = FIELD_OPTION_BTN_LABEL,
      fieldOptionAccessibilityHint = FIELD_OPTION_BTN_A11Y_HINT,
      clearBtnAccessibilityHint = CLEAR_BTN_A11Y_HINT,
      submitBtnAccessibilityLabel = SUBMIT_BTN_A11Y_LABEL,
      submitAccessibilityHint = SUBMIT_A11Y_HINT,

      // These are like this to remove it from `...restProps`.
      value, // eslint-disable-line @typescript-eslint/no-unused-vars
      defaultValue, // eslint-disable-line @typescript-eslint/no-unused-vars
      resetOnSubmit, // eslint-disable-line @typescript-eslint/no-unused-vars
      onChangeText, // eslint-disable-line @typescript-eslint/no-unused-vars

      onChangeTextGuard,
      onFocus,
      onBlur,
      onSubmit,
      onClear,
      fieldOptionOnPress,

      ...restProps
    } = props;

    const inputRef = useRef<TextInput>(null);
    const multiRef = useMergedRef(ref, inputRef);

    const { currentBreakpoint } = useResponsive();

    // ─────────────────────────────────────────────────────────────────

    const isInvalid = typeof invalid === 'boolean' && invalid;

    const {
      formattedValue,
      clearText,
      handleTextChange,
      handleSubmit,
      handleEnterKey,
      hasContent,
    } = useTextFieldHelpers({ inputRef, onChangeTextGuard, onClear, ...props });

    const { isHovered, isFocused } = useHoverAndFocusState({
      inputRef,
      ...props,
    });

    const conditionalStyles = useConditionalStyles({
      isActive: isHovered || isFocused,
      isMultiline: multiline,
      isDisabled,
      isInvalid,
      hasFloatingLabel: label !== undefined,
      labelOffset,
    });

    const { shouldShowPassword, toggleShouldShowPassword } =
      usePasswordHelpers();

    // ─────────────────────────────────────────────────────────────────

    const shouldShowClearButton = hasContent && clearButtonType !== 'none';
    const shouldShowPasswordToggle = secureTextEntry !== undefined;
    const shouldFloatLabel = hasContent || isFocused;
    const shouldShowPrependedText =
      textPrepend !== undefined && shouldFloatLabel;

    // submit button is visible only on screens larger than mobile.
    // "Enter" key is used as an alternative on mobile devices.
    const shouldShowSubmitButton = Boolean(
      hasContent && onSubmit && !hideSubmitButton && !currentBreakpoint.isXS,
    );

    // ─────────────────────────────────────────────────────────────────

    const labelNativeID = useUniqueNativeID('text-field-label');
    const helperTextNativeID = useUniqueNativeID('text-field-helper-text');
    const noticeNativeID = useUniqueNativeID('text-field-notice');
    const platformSpecificAutocomplete =
      usePlatformSpecificAutocomplete(autoComplete);

    // ─────────────────────────────────────────────────────────────────

    // prioritize label over placeholder;
    const fieldPlaceholder = label ? undefined : placeholder;

    // prioritize a11y label over labelled by.
    const fieldAccessibilityLabelledBy = accessibilityLabel
      ? undefined
      : accessibilityLabelledBy ?? labelNativeID;

    const fieldAccessibilityDescribedBy =
      accessibilityDescribedBy ??
      createAccessibilityDescribedByList({
        isInvalid,
        noticeNativeID,
        helperTextNativeID,
      });

    // field needs to be non-editable on native platforms in order to support disabled mode.
    // use standard {editable} prop as a fallback if [disabled] prop is not provided.
    const fieldIsEditable = isDisabled ? false : editable;

    const fieldErrorMessageEl =
      accessibilityErrorMessage ?? (isInvalid ? noticeNativeID : undefined);

    // ─────────────────────────────────────────────────────────────────

    const textInputStyles = [
      styles.input,
      multiline ? styles.inputMultiline : undefined,
      webOnlyStyles({ outline: 'none' }), // we change the border color instead
      style,
    ];

    // an error message should be highlighted using the 'caution' palette when the field is invalid
    const conditionalNoticePalette = isInvalid ? 'caution' : noticePalette;

    // ───────── Avoiding Keyboard ────────────────────────────────────────
    const { avoiderRef } = useKeyboardAvoiderScroll(isFocused);

    // ────────────────────────────────────────────────────────────────────

    return (
      <View
        ref={avoiderRef}
        style={[conditionalStyles.container, containerStyle]}
        testID={containerTestID}
      >
        <View
          style={[
            styles.wrapper,
            multiline && styles.multilineWrapper,
            conditionalStyles.wrapper,
            wrapperStyle,
          ]}
        >
          <TextFieldFloatingLabel
            nativeID={labelNativeID}
            label={label}
            sizeMatch={labelSizeMatch}
            labelOffset={labelOffset}
            isFieldActive={shouldFloatLabel}
          />

          {shouldShowPrependedText ? (
            <View style={styles.inputPrependContainer}>
              <BodyText style={styles.inputPrepend}>{textPrepend}</BodyText>
            </View>
          ) : null}

          <TextInput
            ref={multiRef}
            placeholderTextColor={COLORS.CHARCOAL}
            autoComplete={platformSpecificAutocomplete}
            testID={testID}
            value={formattedValue}
            editable={fieldIsEditable}
            onChangeText={handleTextChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onSubmitEditing={handleSubmit}
            onKeyPress={handleEnterKey}
            placeholder={fieldPlaceholder}
            style={textInputStyles}
            secureTextEntry={shouldShowPassword ? false : secureTextEntry}
            accessibilityLabel={accessibilityLabel}
            accessibilityLabelledBy={fieldAccessibilityLabelledBy}
            // @ts-expect-error TS(2769): No overload matches this call.
            accessibilityDescribedBy={fieldAccessibilityDescribedBy}
            accessibilityErrorMessage={fieldErrorMessageEl}
            accessibilityInvalid={accessibilityInvalid ?? isInvalid}
            accessibilityState={{ disabled: isDisabled }}
            multiline={multiline}
            textAlignVertical={multiline ? 'top' : undefined}
            {...restProps}
          />

          <TextFieldClearButton
            show={!isDisabled && shouldShowClearButton}
            onPress={clearText}
            variation={clearButtonType}
            label={clearBtnLabel}
            accessibilityHint={clearBtnAccessibilityHint}
          />

          <TextFieldIcon
            show={Boolean(fieldOptionIcon)}
            icon={fieldOptionIcon}
            accessibilityLabel={fieldOptionAccessibilityLabel}
            accessibilityHint={fieldOptionAccessibilityHint}
            onPress={fieldOptionOnPress}
          />

          <TextFieldPasswordToggle
            show={shouldShowPasswordToggle}
            showPassword={shouldShowPassword}
            onPress={toggleShouldShowPassword}
            showPasswordLabel={showPasswordLabel}
            hidePasswordLabel={hidePasswordLabel}
            toggleTestID={toggleTestID}
          />

          <TextFieldSubmitButton
            testID={submitButtonTestID}
            isDisabled={isDisabled}
            show={shouldShowSubmitButton}
            icon={submitButtonIcon}
            onPress={handleSubmit}
            accessibilityLabel={submitBtnAccessibilityLabel}
            accessibilityHint={submitAccessibilityHint}
          />
        </View>

        {hideNoticeSpace && !notice ? null : (
          <TextFieldNotice
            palette={conditionalNoticePalette}
            nativeID={noticeNativeID}
          >
            {notice}
          </TextFieldNotice>
        )}

        <TextFieldHelperText nativeID={helperTextNativeID}>
          {helperText}
        </TextFieldHelperText>
      </View>
    );
  }),
);

//
// ─── UTILS ──────────────────────────────────────────────────────────────────────
//

const createAccessibilityDescribedByList = (
  props: CreateAccessibilityDescribedByListProps,
) => {
  // {notice} element should be referenced only if the field is valid or if native platform is used,
  // otherwise {accessibilityErrorMessage} will be used.
  const shouldReferenceNativeID = !props.isInvalid || Platform.OS !== 'web';
  const noticeNativeID = shouldReferenceNativeID
    ? props.noticeNativeID
    : undefined;

  return (
    [props.helperTextNativeID, noticeNativeID].join(' ').trim() || undefined
  );
};

import React from 'react';
import type { ViewProps } from 'react-native';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import {
  BodyText,
  Button,
  Container,
  DisplayText,
  HStack,
  Icon,
  TextLinkifyTags,
  theme,
  TitleText,
  useFluidTextStyles,
  useResponsive,
  VStack,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import type { SweetpassHomeStatsContent } from '../../hooks';
import type { SweetpassHeroStatsPalette } from './SweetpassHomeHeroStats.utils';

// ─────────────────────────────────────────────────────────────────────────────

export const SweetpassHomeHeroStats = (props: SweetpassHomeHeroStatsProps) => {
  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const paletteBackgroundStyle = useStyle(
    () => ({ backgroundColor: props.palette.backgroundColor }),
    [props.palette.backgroundColor],
  );
  const outerContainerStyles = [
    paletteBackgroundStyle,
    styles.outerContainer,
    match([styles.outerContainerXS, styles.outerContainerSM]),
  ];
  const wrapperResponsiveStyles = match([
    styles.wrapperXS,
    styles.wrapperSM,
    styles.wrapperMD,
  ]);
  const wrapperStyles = [wrapperResponsiveStyles];

  // ─────────────────────────────────────────────────────────────────────

  const wrapperTestID = match([
    'sweetpass.home-hero-stats.mobile',
    'sweetpass.home-hero-stats.desktop',
  ]);

  return (
    <SafeAreaView edges={['top']} style={outerContainerStyles}>
      <Container testID={wrapperTestID} style={wrapperStyles}>
        <HeroStatsCard {...props} />
      </Container>
    </SafeAreaView>
  );
};

const HeroStatsCard = ({
  testID,
  palette,
  content,
  detailedHeroStats,
  navigateToBenefits,
  navigateToUpgrade,
  ...rest
}: SweetpassHomeHeroStatsProps) => {
  const { currentBreakpoint } = useResponsive();

  const titleStyleNormal = useCustomTitleStyle(palette.textColor, false);
  const titleStyleHighlight = useCustomTitleStyle(palette.textColor, true);
  const textStyle = useStyle(
    () => ({ color: palette.textColor }),
    [palette.textColor],
  );

  return (
    <VStack hasDivider dividerColor={palette.dividerColor} gap={0}>
      <View
        style={
          currentBreakpoint.isXS
            ? styles.heroStatsColumnXS
            : styles.heroStatsColumnSM
        }
      >
        <View style={styles.heroStatsTitle}>
          <Text>
            <DisplayText style={titleStyleNormal} size={2}>
              {content.headerNameText}
            </DisplayText>

            <TitleText style={titleStyleHighlight} size={4}>
              {content.headerMembershipText}
            </TitleText>
          </Text>
        </View>

        <BodyText style={[styles.heroStatsInfo, textStyle]} size={2}>
          {content.infoText}
        </BodyText>
      </View>

      {detailedHeroStats ? (
        <HeroStatsDetailed testID={testID} palette={palette} {...rest} />
      ) : (
        <HeroStatsSimple testID={testID} palette={palette} {...rest} />
      )}

      <View
        style={
          currentBreakpoint.isXS
            ? styles.heroStatsColumnXS
            : styles.heroStatsColumnSM
        }
      >
        {content.noticeText &&
        content.noticeTextLink &&
        content.noticeTextLinkAction === 'upgrade_sweetpass' ? (
          <BodyText style={[styles.heroStatsNotice, textStyle]} size={3}>
            <TextLinkifyTags
              links={[
                {
                  tag: content.noticeTextLinkAction,
                  description: content.noticeTextLink,
                  onPress: navigateToUpgrade,
                },
              ]}
              style={[styles.heroStatsNotice, textStyle]}
              size={3}
            >
              {content.noticeText}
            </TextLinkifyTags>
          </BodyText>
        ) : null}

        {content.noticeText && !content.noticeTextLink ? (
          <BodyText style={[styles.heroStatsNotice, textStyle]} size={3}>
            {content.noticeText}
          </BodyText>
        ) : null}

        <Button
          testID="sweetpass.home-hero-stats.cta"
          palette={palette.ctaPalette}
          size={currentBreakpoint.isXS ? 'medium' : 'large'}
          onPress={navigateToBenefits}
        >
          {content.ctaText}
        </Button>
      </View>
    </VStack>
  );
};

const HeroStatsSimple = ({
  palette,
  rewardsCount,
  challengesCount,
  navigateToRewards,
  navigateToChallenges,
}: HeroStatsVariantProps) => {
  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  const borderStyle = useStyle(
    () => ({ borderRightColor: palette.dividerColor }),
    [palette.dividerColor],
  );

  const titleTextStyle = useFluidTextStyles('56', 'TITLE');
  const titleMargin = match([0, theme.spacing['2']]);
  const titleStyle = useStyle(
    () => ({
      marginTop: titleMargin,
    }),
    [titleMargin],
  );

  const bodyTextSize = match([4, 3, 2] as const);

  const heroStatsPadding = match([20, 16, 32]);
  const heroStatsPaddingStyle = useStyle(
    () => ({ paddingVertical: heroStatsPadding }),
    [heroStatsPadding],
  );

  const paletteTextStyle = useStyle(
    () => ({ color: palette.textColor }),
    [palette.textColor],
  );

  const iconStyle = useStyle(() => ({
    flexShrink: 0,
    marginLeft: match([
      theme.spacing['2'],
      theme.spacing['2'],
      theme.spacing['4'],
    ]),
  }));

  return (
    <VStack
      testID="sweetpass.home-hero-stats.simple"
      hasDivider
      dividerColor={palette.dividerColor}
      gap={0}
    >
      <HStack itemsPerRow={2} gap={0}>
        <Pressable
          testID="sweetpass.home-hero-stats.challenges"
          accessibilityRole="button"
          style={[styles.heroStatsRow, styles.heroStatsLeft, borderStyle]}
          onPress={navigateToChallenges}
        >
          <TitleText
            style={[paletteTextStyle, titleTextStyle, titleStyle]}
            size={2}
          >
            {challengesCount}
          </TitleText>

          <View
            style={[
              styles.heroStatsRow,
              styles.heroStatsAlignBottom,
              heroStatsPaddingStyle,
            ]}
          >
            <BodyText style={paletteTextStyle} size={bodyTextSize}>
              {challengesCount === 1
                ? t('sweetpass.home.stats.challenge')
                : t('sweetpass.home.stats.challenges')}
            </BodyText>

            <Icon
              name="IconArrowRight"
              style={iconStyle}
              color={palette.textColor}
              width={match([16, 24])}
              height={match([16, 24])}
            />
          </View>
        </Pressable>

        <Pressable
          testID="sweetpass.home-hero-stats.rewards"
          accessibilityRole="button"
          style={[styles.heroStatsRow, styles.heroStatsRight]}
          onPress={navigateToRewards}
        >
          <TitleText
            style={[paletteTextStyle, titleTextStyle, titleStyle]}
            size={2}
          >
            {rewardsCount}
          </TitleText>

          <View
            style={[
              styles.heroStatsRow,
              styles.heroStatsAlignBottom,
              heroStatsPaddingStyle,
            ]}
          >
            <BodyText style={paletteTextStyle} size={bodyTextSize}>
              {rewardsCount === 1
                ? t('sweetpass.home.stats.reward')
                : t('sweetpass.home.stats.rewards')}
            </BodyText>

            <Icon
              name="IconArrowRight"
              style={iconStyle}
              color={palette.textColor}
              width={match([16, 24])}
              height={match([16, 24])}
            />
          </View>
        </Pressable>
      </HStack>
    </VStack>
  );
};

const HeroStatsDetailed = ({
  palette,
  amountSaved,
  rewardsCount,
  challengesCount,
  navigateToRewards,
  navigateToChallenges,
}: HeroStatsVariantProps) => {
  const { match } = useResponsive();
  const { t, formatPrice } = useLocalizationContext();
  const textStyle = useStyle(
    () => ({ color: palette.textColor }),
    [palette.textColor],
  );
  const borderStyle = useStyle(
    () => ({ borderRightColor: palette.dividerColor }),
    [palette.dividerColor],
  );

  const iconStyle = useStyle(() => ({
    flexShrink: 0,
    paddingLeft: match([
      theme.spacing['2'],
      theme.spacing['2'],
      theme.spacing['4'],
    ]),
  }));

  return (
    <VStack
      testID="sweetpass.home-hero-stats.detailed"
      hasDivider
      dividerColor={palette.dividerColor}
      gap={0}
    >
      {amountSaved ? (
        <View style={styles.heroStatsRow}>
          <TitleText style={textStyle} size={match([3, 2])}>
            {t('sweetpass.home.stats.detailed.saved', {
              amount: formatPrice(amountSaved, 'USD').split('.')[0], // TODO: is there a better way without interpolating $ manually?
            })}
          </TitleText>
        </View>
      ) : null}

      <HStack itemsPerRow={2} gap={0}>
        <Pressable
          testID="sweetpass.home-hero-stats.challenges"
          accessibilityRole="button"
          style={[styles.heroStatsRow, styles.heroStatsLeft, borderStyle]}
          onPress={navigateToChallenges}
        >
          <TitleText style={textStyle}>{challengesCount}</TitleText>

          <View style={styles.heroStatsRow}>
            <TitleText style={textStyle} size={match([3, 2])}>
              {challengesCount === 1
                ? t('sweetpass.home.stats.challenge')
                : t('sweetpass.home.stats.challenges')}
            </TitleText>

            <Icon
              color={palette.textColor}
              style={iconStyle}
              name="IconArrowRight"
              height={24}
              width={24}
            />
          </View>
        </Pressable>

        <Pressable
          testID="sweetpass.home-hero-stats.rewards"
          accessibilityRole="button"
          style={[styles.heroStatsRow, styles.heroStatsRight]}
          onPress={navigateToRewards}
        >
          <TitleText style={textStyle}>{rewardsCount}</TitleText>

          <View style={styles.heroStatsRow}>
            <TitleText style={textStyle} size={match([3, 2])}>
              {rewardsCount === 1
                ? t('sweetpass.home.stats.reward')
                : t('sweetpass.home.stats.rewards')}
            </TitleText>

            <Icon
              color={palette.textColor}
              style={iconStyle}
              name="IconArrowRight"
              height={24}
              width={24}
            />
          </View>
        </Pressable>
      </HStack>
    </VStack>
  );
};

// ─── Hooks ──────────────────────────────────────────────────────────────────

/**
 * This title is being handled without responsiveness due to a design request.
 *
 * Desktop
 *   SweetSans, 56px font size, 0.6px letter spacing
 *   Grenette Pro, 56px font size, -2.7px letter spacing
 *
 * Tablet
 *   SweetSans, 40px font size, 0.8px letter spacing
 *   Grenette Pro, 40px font size, -2px letter spacing
 *
 * Mobile
 *   SweetSans, 32px font size, 1px letter spacing
 *   Grenette Pro, 32px font size, -1.6px letter spacing
 */
const useCustomTitleStyle = (textColor: string, highlight: boolean) => {
  const { match } = useResponsive();

  const highlightFontSize = match([32, 40, 40, 56]);
  const highlightLineHeight = match([36, 40, 40, 56]);
  const highlightLetterSpacing = match([-1.6, -2, -2, -2.7]);
  const highlightFontFamily = 'GrenettePro-Regular';

  const regularFontSize = match([32, 40, 40, 56]);
  const regularLineHeight = match([36, 40, 40, 56]);
  const regularLetterSpacing = match([1, 0.8, 0.8, 0.6]);
  const regularFontFamily = 'SweetSans-Regular';

  return useStyle(
    () => ({
      color: textColor,
      fontSize: highlight ? highlightFontSize : regularFontSize,
      lineHeight: highlight ? highlightLineHeight : regularLineHeight,
      letterSpacing: highlight ? highlightLetterSpacing : regularLetterSpacing,
      fontFamily: highlight ? highlightFontFamily : regularFontFamily,
    }),
    [
      textColor,
      highlight,
      highlightFontSize,
      highlightLineHeight,
      highlightLetterSpacing,
      highlightFontFamily,
      regularFontSize,
      regularLineHeight,
      regularLetterSpacing,
      regularFontFamily,
    ],
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
  },
  outerContainerXS: {
    marginBottom: theme.spacing['2'],
  },
  outerContainerSM: {
    borderRadius: theme.spacing['4'],
  },
  wrapperXS: {
    paddingVertical: theme.spacing['4'],
  },
  wrapperSM: {
    paddingTop: theme.spacing['2'],
    paddingHorizontal: theme.spacing['4'],
  },
  wrapperMD: {
    paddingTop: theme.spacing['6'],
    paddingHorizontal: theme.spacing['6'],
    paddingBottom: theme.spacing['4'],
  },
  heroStatsInfo: {
    paddingTop: theme.spacing['2'],
  },
  heroStatsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heroStatsAlignBottom: {
    alignItems: 'flex-end',
  },
  heroStatsColumnXS: {
    alignItems: 'flex-start',
    paddingVertical: theme.spacing['4'],
  },
  heroStatsColumnSM: {
    alignItems: 'flex-start',
    paddingVertical: theme.spacing['6'],
  },
  heroStatsTitle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  heroStatsNotice: {
    paddingTop: theme.spacing['2'],
    paddingBottom: theme.spacing['4'],
  },
  heroStatsLeft: {
    paddingRight: theme.spacing['4'],
    borderRightWidth: 1,
  },
  heroStatsRight: {
    paddingLeft: theme.spacing['4'],
  },
});

// ─── TYPES ─────────────────────────────────────────────────────────────────────

type HeroStatsVariantProps = Readonly<{
  palette: SweetpassHeroStatsPalette;

  amountSaved?: number;
  rewardsCount: number;
  challengesCount: number;
  navigateToRewards: () => void;
  navigateToChallenges: () => void;
}> &
  Pick<ViewProps, 'testID'>;

type SweetpassHomeHeroStatsProps = HeroStatsVariantProps &
  Readonly<{
    content: Partial<Omit<SweetpassHomeStatsContent, 'type'>>;
    detailedHeroStats: boolean;
    navigateToBenefits: () => void;
    navigateToUpgrade: () => void;
  }> &
  Pick<ViewProps, 'testID'>;

import { useContentfulContentTypeEntry } from '@order/Contentful';
import { useLocalizationContext } from '@order/Localization';

export function useSweetpassCheckoutContentfulQuery() {
  const { t } = useLocalizationContext();

  const { data: contentfulData, fetching: isLoading } =
    useContentfulContentTypeEntry<ContentfulSweetpassCheckoutPage>({
      contentType: CONTENT_TYPE,
      include: 2,
    });

  const requestedData = contentfulData?.fields;

  return {
    isLoading,
    title: requestedData?.title ?? t(FALLBACK.title),
    termsAndConditions:
      requestedData?.termsAndConditionsV3 ?? t(FALLBACK.termsAndConditionsV3),
    sweetpassProgramAgreementUrlDisplayName:
      requestedData?.sweetpassProgramAgreementUrlDisplayName ??
      t(FALLBACK.sweetpassProgramAgreementUrlDisplayName),
    sweetpassProgramAgreementUrl:
      requestedData?.sweetpassProgramAgreementUrl ??
      t(FALLBACK.sweetpassProgramAgreementUrl),
    agreementText: requestedData?.agreementText ?? t(FALLBACK.agreementText),
    cta: requestedData?.cta ?? t(FALLBACK.cta),
    freeTrialTitle: requestedData?.freeTrialTitle ?? t(FALLBACK.freeTrialTitle),
    freeTrialTermsAndConditions:
      requestedData?.freeTrialTermsAndConditionsV3 ??
      t(FALLBACK?.freeTrialTermsAndConditionsV3),
    freeTrialAgreementText:
      requestedData?.freeTrialAgreementText ??
      t(FALLBACK.freeTrialAgreementText),
    freeTrialCta: requestedData?.freeTrialCta ?? t(FALLBACK.freeTrialCta),
    freeTrialNote: requestedData?.freeTrialNote ?? t(FALLBACK.freeTrialNote),
    planSwitchTitle:
      requestedData?.planSwitchTitle ?? t(FALLBACK.planSwitchTitle),
    planSwitchCta: requestedData?.planSwitchCta ?? t(FALLBACK.planSwitchCta),
    planSwitchTermsAndConditions:
      requestedData?.planSwitchTermsAndConditions ??
      t(FALLBACK.planSwitchTermsAndConditions),
    subscriptionSwitchSuccessBanner:
      requestedData?.subscriptionSwitchSuccessBanner ??
      t(FALLBACK.subscriptionSwitchSuccessBanner),
  };
}

// ─── Constants ───────────────────────────────────────────────────────────────

const CONTENT_TYPE = 'sweetpassCheckoutPage';
const FALLBACK = {
  name: 'sweetpass-checkout-contentful-fallback-name' as const,
  title: 'sweetpass-checkout-contentful-fallback-title' as const,
  termsAndConditionsV3:
    'sweetpass-checkout-contentful-fallback-termsAndConditionsV3' as const,
  sweetpassProgramAgreementUrlDisplayName:
    'sweetpass-checkout-contentful-fallback-sweetpassProgramAgreementUrlDisplayName' as const,
  sweetpassProgramAgreementUrl:
    'sweetpass-checkout-contentful-fallback-sweetpassProgramAgreementUrl' as const,
  agreementText:
    'sweetpass-checkout-contentful-fallback-agreementText' as const,
  cta: 'sweetpass-checkout-contentful-fallback-cta' as const,
  freeTrialTitle:
    'sweetpass-checkout-contentful-fallback-freeTrialTitle' as const,
  freeTrialTermsAndConditionsV3:
    'sweetpass-checkout-contentful-fallback-freeTrialTermsAndConditionsV3' as const,
  freeTrialAgreementText:
    'sweetpass-checkout-contentful-fallback-freeTrialAgreementText' as const,
  freeTrialCta: 'sweetpass-checkout-contentful-fallback-freeTrialCta' as const,
  freeTrialNote:
    'sweetpass-checkout-contentful-fallback-freeTrialNote' as const,
  planSwitchTitle:
    'sweetpass-checkout-contentful-fallback-planSwitchTitle' as const,
  planSwitchCta:
    'sweetpass-checkout-contentful-fallback-planSwitchCta' as const,
  planSwitchTermsAndConditions:
    'sweetpass-checkout-contentful-fallback-planSwitchTermsAndConditions' as const,
  subscriptionSwitchSuccessBanner:
    'sweetpass-checkout-contentful-fallback-subscriptionSwitchSuccessBanner' as const,
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ContentfulSweetpassCheckoutPage = Readonly<{
  title: string;
  termsAndConditionsV3: string;
  agreementText: string;
  sweetpassProgramAgreementUrlDisplayName: string;
  sweetpassProgramAgreementUrl: string;
  cta: string;
  freeTrialTitle: string;
  freeTrialTermsAndConditionsV3: string;
  freeTrialAgreementText: string;
  freeTrialCta: string;
  planSwitchCta: string;
  freeTrialNote: string;
  planSwitchTitle: string;
  planSwitchTermsAndConditions: string;
  subscriptionSwitchSuccessBanner: string;
}>;

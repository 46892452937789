import React, { useCallback } from 'react';
import { useLinkTo } from '@react-navigation/native';
import { type To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import { ProductBannerCardWithOverlay } from '@sg/garnish';

import { useCustomProductContent } from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuCategoriesGridCustomProductCard = (
  props: MenuContentProductCardProps,
) => {
  const { to, product } = props;

  const linkTo = useLinkTo();

  // ─── Remote Data ─────────────────────────────────────────────────────

  const { content } = useCustomProductContent({
    customProductType: product.customType,
  });

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigate = useCallback(() => {
    linkTo(to);
  }, [linkTo, to]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ProductBannerCardWithOverlay
      title={content.heading}
      description={content.body}
      onCtaPress={navigate}
      ctaLabel={content.buttonLabel}
      ctaPalette={content.buttonPalette}
      isDisabled={product.outOfStock}
      backgroundImage={content.backgroundImage}
      backgroundOverlayColor={content.backgroundOverlayColor}
      textColor={content.textColor}
    />
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuContentProductCardProps = {
  to: To;
  product: {
    outOfStock: boolean;
    customType?: string | null;
  };
};

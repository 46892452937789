import React from 'react';
import { View } from 'react-native';

import type { Credit } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { CreditsListItem } from '../CreditsListItem';

// ─────────────────────────────────────────────────────────────────────────────

export const CreditsList = (props: CreditBalanceDetailsProps) => {
  const { credits, totalCreditsAmount } = props;

  const shouldRenderTotalCreditsAmount = typeof totalCreditsAmount === 'number';

  return (
    <View>
      {credits.map((credit, index) => (
        <CreditsListItem
          key={credit.id}
          title={credit.storefrontEligibilityTitle ?? credit.title}
          amount={credit.remainingAmount}
          createdAtDate={credit.createdAt}
          expirationDate={credit.expiresAt}
          creditType={credit.creditType}
          hasTopBorder={index > 0}
        />
      ))}

      {shouldRenderTotalCreditsAmount ? (
        <CreditsListTotalCreditsItem totalCreditsAmount={totalCreditsAmount} />
      ) : null}
    </View>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const CreditsListTotalCreditsItem = (
  props: CreditsListTotalCreditsItemProps,
) => {
  const { totalCreditsAmount } = props;

  const { t } = useLocalizationContext();

  return (
    <CreditsListItem
      title={t('account.credit.label')}
      amount={totalCreditsAmount}
      hasTopBorder
      borderTopColor="DARK_KALE"
      isTitleBold
    />
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type CreditBalanceDetailsProps = Readonly<{
  credits: readonly Credit[];
  totalCreditsAmount?: number;
}>;

type CreditsListTotalCreditsItemProps = Readonly<{
  totalCreditsAmount: number;
}>;

import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';
import { BodyText } from '../../../Text';
import type { PaletteProps } from '../../Modal.types';
import { ModalRow } from '../ModalRow';

export const ModalBodyText = (
  props: ComponentProps<typeof BodyText> & PaletteProps,
) => {
  const { children, ...restProps } = props;
  const { match } = useResponsive();

  return (
    <ModalRow>
      <BodyText size={match([4, 3])} style={styles.bodyText} {...restProps}>
        {children}
      </BodyText>
    </ModalRow>
  );
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  bodyText: {
    paddingBottom: theme.spacing['6'],
  },
});

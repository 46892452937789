import { useCallback } from 'react';
import { useActor } from '@xstate/react';

import { useGlobalAppState } from '@order/GlobalAppState';

import type { ReorderEvent } from '../../machines/Reorder.machine';
import { useReorderNavigation } from '../useReorderNavigation';
import { logger } from '../utils';

export const useFinishReorder = () => {
  const { reorderMachineRef } = useGlobalAppState();
  const [state, sendReorderEvent] = useActor(reorderMachineRef);
  const { finishReorder, finishReorderNewMenu, finishReorderCheckout } =
    useReorderNavigation();

  return useCallback(() => {
    logger.info('Finish Reorder');

    // ─── Reorder State ────────────────────────────────────────────
    const { slug, addressId } = getSlugAndAddressId(state.context);
    const { remainOnCheckout, finishOnMenu } = state.context;

    // ─── Navigation ───────────────────────────────────────────────
    if (remainOnCheckout) finishReorderCheckout();
    else if (finishOnMenu) finishReorderNewMenu(slug, addressId);
    else finishReorder();

    // ─── State Machine ──────────────────────────────────────────
    sendReorderEvent({ type: 'FINISH_REORDER' });
  }, [
    state.context,
    finishReorderCheckout,
    finishReorderNewMenu,
    finishReorder,
    sendReorderEvent,
  ]);
};

const getSlugAndAddressId = (event: ReorderEvent) => {
  return {
    slug: event.orderType === 'delivery' ? undefined : event.restaurantSlug,
    addressId:
      event.orderType === 'delivery'
        ? event.deliveryOrderDetail?.addressId
        : undefined,
  };
};

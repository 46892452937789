import type { PathConfig } from '@react-navigation/native';

import { appNavigationLinking } from '../../AppNavigation.linking';
import type {
  AppLinkingOptions,
  MainTabName,
  MainTabsParamList,
} from '../../AppNavigation.props';

/**
 * Returns true if the specified route name is a screen
 * in the specified tab's stack.
 *
 * @param routeName The route name to test.
 * @param tabName Name of the main tab.
 * @param linkingOptions
 * @returns Boolean indicating whether
 */
export const isRouteInMainTab = (
  tabName: MainTabName,
  routeName: string,
  linkingOptions: AppLinkingOptions = appNavigationLinking,
) => {
  const mainTabsConfig = linkingOptions.config?.screens.MainTabs;

  if (mainTabsConfig === undefined || typeof mainTabsConfig === 'string') {
    return false;
  }

  const mainTabsScreens = mainTabsConfig.screens;

  if (mainTabsScreens === undefined) return false;

  const stackConfig = mainTabsScreens[tabName] as PathConfig<MainTabsParamList>;

  if (stackConfig === undefined || typeof stackConfig === 'string') {
    return false;
  }

  const stackScreens = stackConfig.screens;

  if (stackScreens === undefined) return false;

  return Object.keys(stackScreens).includes(routeName);
};

import { logger as LOG } from '@garnish/logger';
import * as dateFns from 'date-fns';
import { formatISO } from 'date-fns';
import { switchcase } from '@sg/garnish';

export const getDayDateTimeYear = (
  formatMessage: (id: any) => string,
  inputDate: Date,
) => {
  const year = inputDate.getFullYear();
  const month = getMonth(`${inputDate.getMonth()}`);
  const day = formatMessage(getDay(`${inputDate.getDay()}`));
  const date = `${formatMessage(month)} ${inputDate.getDate()}`;
  const time = getReadableTime(inputDate);

  return { day, date, time, year };
};

export const isToday = (value?: string | number | Date) => {
  const date = new Date(value ?? 0);

  return dateFns.isToday(date);
};

export const isTomorrow = (value?: string | number | Date) => {
  const date = new Date(value ?? 0);

  return dateFns.isTomorrow(date);
};

export const isDayAfterTomorrow = (value?: string | number | Date) => {
  const date = new Date(value ?? 0);

  return dateFns.isAfter(date, dateFns.endOfTomorrow());
};

/**
 * Returns a new `Date` based on the supplied date string but without extra information (milliseconds, timezone, etc.)
 *
 * NOTE: If the provided value is not supported, `undefined` is returned
 *
 * Supported date string formats:
 *
 * - Any ISO date string:           2000-03-15T05:20:10...
 * - ISO date string without `T`:   2000-03-15 05:20:10...
 */
export const ignoreTimezone = (dateString: string) => {
  // leave out milliseconds, timezone and other extra information
  const dateStringWithoutExtras = dateString.slice(0, 19).replace(' ', 'T');
  const dateWithoutExtras = dateFns.parseISO(dateStringWithoutExtras);

  const hasValidDateStringLength = dateStringWithoutExtras.length === 19;
  const isValidDate =
    hasValidDateStringLength && dateFns.isValid(dateWithoutExtras);

  // ─────────────────────────────────────────────────────────────────

  if (!isValidDate) {
    ignoreTimezoneLogger?.error(`Unsupported date string ("${dateString}")`);

    return;
  }

  // ─────────────────────────────────────────────────────────────────

  return dateWithoutExtras;
};

/** Uses the `ignoreTimezone` function to return a formatted date */
export function formatDateWithoutTimezone(date = '', format = 'MM/dd/yyyy') {
  const dateWithoutTimezone = ignoreTimezone(date);

  const formattedDate = dateWithoutTimezone
    ? dateFns.format(dateWithoutTimezone, format)
    : '';

  return { dateWithoutTimezone, formattedDate };
}

/**
 * Returns an ISO date string without using time zone modifier.
 *
 * @example
 *
 * formatDateToISOWithoutTimezone(new Date(2077, 1, 1, 12, 0, 0))
 * //=> '2077-01-01T12:00:00Z'
 */
export function formatDateToISOWithoutTimezone(date: Date) {
  return formatISO(date).split('+')[0];
}

/**
 * Changes a {dateString} from a {fromFormat} to a {toFormat}.
 * Returns {null} if the date is invalid or not in the {fromFormat}.
 */
export function formatDateFromTo(
  dateString = '',
  fromFormat = 'yyyy-MM-DD',
  toFormat = 'MM/dd/yyyy',
) {
  const date = dateFns.parse(dateString, fromFormat, new Date());

  if (!dateFns.isValid(date)) return null;

  return dateFns.format(date, toFormat);
}

export const getReadableTime = (inputDate: Date) => {
  return dateFns.format(inputDate, 'h:mmaaa'); // e.g. 1:30pm, 11:00am
};

export const getTimeRange = (inputDate: Date, offsetMinutes: number) => {
  const startDate = dateFns.subMinutes(inputDate, offsetMinutes);
  const endDate = dateFns.addMinutes(inputDate, offsetMinutes);

  return {
    startTime: getReadableTime(startDate),
    endTime: getReadableTime(endDate),
  };
};

/**
 * We want to use the same 10 minute range in all locations in the app.
 * We should round up to the next 5 minute increment.
 *
 * Examples:
 *    For inputDate = 11:03, we want 11:05-11:15.
 *    For inputDate = 11:05, we want 11:05-11:15.
 *    For inputDate = 11:07, we want 11:10-11:20.
 *    For inputDate = 11:10, we want 11:10-11:20.
 */

export const getOrderTimeRange = (inputDate?: Date) => {
  if (!inputDate || !dateFns.isValid(inputDate)) {
    return { startTime: '', endTime: '' };
  }

  const startDate = getNextTimeRangeRoundedUp(inputDate);
  const endDate = dateFns.addMinutes(startDate, TIME_RANGE_OFFSET);

  return {
    startTime: getReadableTime(startDate),
    endTime: getReadableTime(endDate),
  };
};

const getNextTimeRangeRoundedUp = (inputDate: Date) => {
  return new Date(
    Math.ceil(inputDate.getTime() / FIVE_MINS_IN_MS) * FIVE_MINS_IN_MS,
  );
};

export const getDay = (day: string) => {
  return switchcase<WeekdayTranslationKey>({
    '0': 'general.weekday.sunday',
    '1': 'general.weekday.monday',
    '2': 'general.weekday.tuesday',
    '3': 'general.weekday.wednesday',
    '4': 'general.weekday.thursday',
    '5': 'general.weekday.friday',
    '6': 'general.weekday.saturday',
  })('general.weekday.monday')(day);
};

export const getMonth = (month: string) => {
  return switchcase<MonthTranslationKey>({
    '0': 'general.month.january',
    '1': 'general.month.february',
    '2': 'general.month.march',
    '3': 'general.month.april',
    '4': 'general.month.may',
    '5': 'general.month.june',
    '6': 'general.month.july',
    '7': 'general.month.august',
    '8': 'general.month.september',
    '9': 'general.month.october',
    '10': 'general.month.november',
    '11': 'general.month.december',
  })('general.month.january')(month);
};

export const getMonthNames = (): readonly MonthTranslationKey[] => {
  return [
    'general.month.january',
    'general.month.february',
    'general.month.march',
    'general.month.april',
    'general.month.may',
    'general.month.june',
    'general.month.july',
    'general.month.august',
    'general.month.september',
    'general.month.october',
    'general.month.november',
    'general.month.december',
  ];
};

// We only use the month and day for the birthday, defaulting the year to 1752.
export const formatBirthdayForSubmit = (birthday: string) => {
  if (!birthday) return '';
  const [month, day] = birthday.split('/');

  return `1752-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

// ─── Constants ──────────────────────────────────────────────────────────────

const FIVE_MINS_IN_MS = 5 * 60 * 1000; // used to round ranges in 5mins intervals.
const TIME_RANGE_OFFSET = 10; // 10 mins.

// ─── Helpers ────────────────────────────────────────────────────────────────

LOG.enable('DATE | IGNORE TIMEZONE');
const ignoreTimezoneLogger = LOG.extend('DATE | IGNORE TIMEZONE');

// ─── Types ──────────────────────────────────────────────────────────────────

export type WeekdayTranslationKey =
  | 'general.weekday.sunday'
  | 'general.weekday.monday'
  | 'general.weekday.tuesday'
  | 'general.weekday.wednesday'
  | 'general.weekday.thursday'
  | 'general.weekday.friday'
  | 'general.weekday.saturday';

export type MonthTranslationKey =
  | 'general.month.january'
  | 'general.month.february'
  | 'general.month.march'
  | 'general.month.april'
  | 'general.month.may'
  | 'general.month.june'
  | 'general.month.july'
  | 'general.month.august'
  | 'general.month.september'
  | 'general.month.october'
  | 'general.month.november'
  | 'general.month.december';

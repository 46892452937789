import React, { useCallback, useEffect, useMemo } from 'react';
import { ListActionSelect } from '@sg/garnish';

import { getCreditCardIcon } from '@order/constants';
import type { SubscriptionCreditCard } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { SingleOptionCreditCardPicker } from '../SingleOptionCreditCardPicker';

export const SweetpassCheckoutCardPicker = (
  props: SweetpassCheckoutCardPickerProps,
) => {
  const {
    selectedCreditCard,
    creditCards,
    handleAddCreditCard,
    handleSelectCreditCard,
  } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const creditCardIcon = getCreditCardIcon(selectedCreditCard?.cardType);

  // ─── Credit Card Options ─────────────────────────────────────────────

  const creditCardOptions = useCreditCardOptions(creditCards);

  // ─── Automatically Select First Credit Card If None Are Selected ─────

  useEffect(() => {
    if (!creditCards[0]) return;
    handleSelectCreditCard(creditCards[0]);
  }, [creditCards, handleSelectCreditCard]);

  // ─── Callbacks ───────────────────────────────────────────────────────

  const handleSelectedCardChange = useCallback(
    (cardId: string | number) => {
      const creditCard = creditCards.find((card) => card.id === cardId);

      if (!creditCard) {
        handleAddCreditCard();

        return;
      }

      handleSelectCreditCard(creditCard);
    },
    [creditCards, handleSelectCreditCard, handleAddCreditCard],
  );

  // ─── Only Add Card Option ────────────────────────────────────────────

  if (creditCards.length === 0) {
    return <SingleOptionCreditCardPicker onPress={handleAddCreditCard} />;
  }

  // ─── Multiple Credit Card Options ────────────────────────────────────

  return (
    <ListActionSelect
      noticePalette="caution"
      submitBtnLabel={t('general.select')}
      modalTitle={t('checkout.payment-method.title')}
      accessibilityLabel={t('checkout.payment-method')}
      iconName={creditCardIcon}
      value={selectedCreditCard?.id ?? 'ADD'}
      options={creditCardOptions}
      onSelect={handleSelectedCardChange}
    />
  );
};

// ─── Hooks ─────────────────────────────────────────────────────────────

const useCreditCardOptions = (
  creditCards: ReadonlyArray<Partial<SubscriptionCreditCard>>,
) => {
  const { t } = useLocalizationContext();

  const convertCreditCardIntoOption = useCallback(
    (creditCard: Partial<SubscriptionCreditCard>): CreditCardOption => ({
      value: creditCard.id ?? '',
      label: t('sweetpass.checkout.credit-card.description', {
        cardType: creditCard.cardType,
        lastFour: creditCard.lastFour,
      }),
    }),
    [t],
  );

  const creditCardOptions: readonly CreditCardOption[] = useMemo(
    () => [
      { label: t('checkout.add-card'), value: 'ADD' },
      ...creditCards.map(convertCreditCardIntoOption),
    ],
    [creditCards, convertCreditCardIntoOption, t],
  );

  return creditCardOptions;
};

// ─── Types ─────────────────────────────────────────────────────────────

type CreditCardOption = Readonly<{
  label: string;
  value: string;
}>;

type SweetpassCheckoutCardPickerProps = Readonly<{
  selectedCreditCard?: Partial<SubscriptionCreditCard>;
  creditCards: ReadonlyArray<Partial<SubscriptionCreditCard>>;
  handleAddCreditCard: () => void;
  handleSelectCreditCard: (creditCard: Partial<SubscriptionCreditCard>) => void;
}>;

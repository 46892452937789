import type { ComponentProps, ReactNode } from 'react';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import {
  BodyText,
  FloatingHeader,
  Image,
  LoadingPlaceholder,
  TextLink,
  theme,
  TitleText,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

const SweetpassHeaderContainer = (props: SweetpassHeaderContainerProps) => {
  const { children, scrollDistance, offset } = props;

  return (
    <FloatingHeader scrollDistance={scrollDistance} offset={offset}>
      <View style={styles.container}>{children}</View>
    </FloatingHeader>
  );
};

const SweetpassHeaderLoading = (
  props: Pick<SweetpassHeaderContainerProps, 'scrollDistance' | 'offset'>,
) => {
  const { scrollDistance, offset } = props;

  return (
    <SweetpassHeaderContainer scrollDistance={scrollDistance} offset={offset}>
      <LoadingPlaceholder palette="cream" rowHeight={56} />
    </SweetpassHeaderContainer>
  );
};

const SweetpassHeaderContentContainer = (
  props: SweetpassHeaderContentContainerProps,
) => {
  const { children } = props;

  return <View style={styles.contentContainer}>{children}</View>;
};

const SweetpassHeaderTitle = (props: SweetpassHeaderTitleProps) => {
  const { customerFirstName, tierName } = props;

  return (
    <View style={styles.titleContainer}>
      <Text>
        <BodyText size={1} numberOfLines={1} style={styles.titleName}>
          {customerFirstName}
        </BodyText>

        <TitleText size={7} numberOfLines={1}>
          {tierName}
        </TitleText>
      </Text>
    </View>
  );
};

const SweetpassHeaderUpgradeCta = (props: SweetpassHeaderCtaProps) => {
  const { label, hintText, onPress } = props;

  return (
    <View style={styles.contentExtraDetails}>
      <TextLink onPress={onPress} size={5} style={styles.upgradeCtaLink}>
        {label}
      </TextLink>

      {hintText ? (
        <BodyText size={5} numberOfLines={1} style={styles.upgradeCtaHint}>
          {hintText}
        </BodyText>
      ) : null}
    </View>
  );
};

const SweetpassHeaderMemberSinceText = (
  props: SweetpassHeaderMemberSinceTextProps,
) => {
  const { memberSinceInfoText } = props;

  return (
    <View style={styles.contentExtraDetails}>
      <BodyText numberOfLines={1}>{memberSinceInfoText}</BodyText>
    </View>
  );
};

const SweetpassHeaderTierIllustration = (
  props: SweetpassHeaderTierIllustrationProps,
) => {
  const { sourceURL, tierName } = props;

  const { t } = useLocalizationContext();

  return (
    <Image
      source={{ uri: sourceURL }}
      style={styles.tierIllustration}
      contentFit="contain"
      aria-label={t('sweetpass.home.header-tier-illustration', {
        tierName,
      })}
    />
  );
};

export const SweetpassHeader = {
  Container: SweetpassHeaderContainer,
  Loading: SweetpassHeaderLoading,
  ContentContainer: SweetpassHeaderContentContainer,
  Title: SweetpassHeaderTitle,
  UpgradeCta: SweetpassHeaderUpgradeCta,
  MemberSinceText: SweetpassHeaderMemberSinceText,
  TierIllustration: SweetpassHeaderTierIllustration,
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentContainer: {
    flex: 1,
    paddingRight: theme.spacing['2'],
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleName: {
    flexShrink: 0,
  },
  contentExtraDetails: {
    paddingTop: theme.spacing['1'],
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  upgradeCtaLink: {
    color: theme.colors.KALE,
  },
  upgradeCtaHint: {
    marginLeft: theme.spacing['1'],
  },
  tierIllustration: {
    width: 72,
    height: 48,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassHeaderContainerProps = Readonly<{
  children: ReactNode;
}> &
  Pick<ComponentProps<typeof FloatingHeader>, 'scrollDistance' | 'offset'>;

type SweetpassHeaderContentContainerProps = Readonly<{
  children: ReactNode;
}>;

type SweetpassHeaderTitleProps = Readonly<{
  customerFirstName: string;
  tierName: string;
}>;

type SweetpassHeaderCtaProps = Readonly<{
  label: string;
  hintText?: string;
  onPress: () => void;
}>;

type SweetpassHeaderMemberSinceTextProps = Readonly<{
  memberSinceInfoText: string;
}>;

type SweetpassHeaderTierIllustrationProps = Readonly<{
  sourceURL: string;
  tierName: string;
}>;

import React, { useCallback, useRef } from 'react';

import { MapControls } from '../MapControls';
import { Map } from '../MapView';
import type {
  GoogleMapsRef,
  MapApiWeb,
  MapWithControlsProps,
  SetMapCenterProps,
  SetMapZoomProps,
} from '../types';
import { MapWithControlsWrapper } from './MapWithControlsWrapper';

export const MapWithControls = (props: MapWithControlsProps) => {
  const mapRef = useRef<GoogleMapsRef>();
  const {
    onZoomInPress,
    onZoomOutPress,
    onGeolocateUserBtnPress,
    disableGeolocateUserBtn,
    zoomInAccessibilityHint,
    zoomInAccessibilityLabel,
    zoomOutAccessibilityHint,
    zoomOutAccessibilityLabel,
    centerLocationAccessibilityHint,
    centerLocationAccessibilityLabel,
    ...mapProps
  } = props;

  const handleZoomInPress = useCallback(() => {
    setMapZoom({ mapObject: mapRef.current?.map_, action: 'increase' });
    onZoomInPress?.();
  }, [onZoomInPress]);

  const handleZoomOutPress = useCallback(() => {
    setMapZoom({ mapObject: mapRef.current?.map_, action: 'decrease' });
    onZoomOutPress?.();
  }, [onZoomOutPress]);

  const handleGeolocateUserBtnPress = useCallback(async () => {
    const geolocation = await onGeolocateUserBtnPress?.();

    if (!geolocation?.coords) return;

    const { latitude: lat, longitude: lng } = geolocation.coords;

    setMapCenter({ mapObject: mapRef.current?.map_, lat, lng });
  }, [onGeolocateUserBtnPress]);

  return (
    <MapWithControlsWrapper>
      {/* @ts-expect-error TS(2322): Type 'MutableRefObject<Readonly<{ map_: Readonly<{... Remove this comment to see the full error message */}
      <Map {...mapProps} ref={mapRef} showControls={false} />
      <MapControls
        onGeolocateUserBtnPress={handleGeolocateUserBtnPress}
        onZoomInPress={handleZoomInPress}
        onZoomOutPress={handleZoomOutPress}
        zoomInAccessibilityHint={zoomInAccessibilityHint}
        zoomInAccessibilityLabel={zoomInAccessibilityLabel}
        zoomOutAccessibilityHint={zoomOutAccessibilityHint}
        zoomOutAccessibilityLabel={zoomOutAccessibilityLabel}
        centerLocationAccessibilityHint={centerLocationAccessibilityHint}
        centerLocationAccessibilityLabel={centerLocationAccessibilityLabel}
        disableGeolocateUserBtn={disableGeolocateUserBtn}
      />
    </MapWithControlsWrapper>
  );
};

//
// ─── HELPERS ────────────────────────────────────────────────────────────────────
//

export const setMapZoom = ({
  mapObject,
  action,
}: SetMapZoomProps<MapApiWeb>) => {
  if (!mapObject) return;

  const setZoom = mapObject?.setZoom?.bind(mapObject);

  if (!setZoom) return;

  const zoom = mapObject.zoom > 0 ? mapObject.zoom : 5;
  const minZoom = typeof mapObject.minZoom === 'number' ? mapObject.minZoom : 3;
  const maxZoom =
    typeof mapObject.maxZoom === 'number'
      ? mapObject.maxZoom
      : Number.POSITIVE_INFINITY;

  if (zoom === (action === 'increase' ? maxZoom : minZoom)) return;

  setZoom(action === 'increase' ? Number(zoom) + 1 : Number(zoom) - 1);
};

export const setMapCenter = ({
  mapObject,
  lat,
  lng,
}: SetMapCenterProps<MapApiWeb>) => {
  const setCenter = mapObject?.setCenter?.bind(mapObject);

  setCenter?.({ lat, lng });
};

import { BREAKPOINTS } from './breakpoints';
import { COLORS } from './colors';
import { ELEVATIONS } from './elevations';
import { RADIUS } from './radius';
import { SIZES } from './sizes';
import { TRANSITIONS } from './transitions';
import { Z_INDEX } from './zIndex';

export const theme = {
  colors: COLORS,
  breakpoints: BREAKPOINTS,
  spacing: SIZES,
  radius: RADIUS,
  elevations: ELEVATIONS,
  transitions: TRANSITIONS,
  zIndex: Z_INDEX,
};

export const getItem = async (key: string) => {
  const item = String(localStorage.getItem(key));

  if (item) {
    const parsed = JSON.parse(item);

    return parsed;
  }
};

export const removeItem = async (key: string) => {
  localStorage.removeItem(key);
};

export const setItem = async (key: string, item: string) => {
  localStorage.setItem(key, item);
};

/* istanbul ignore file */

import React, { forwardRef, memo } from 'react';
import type { TextInput } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { LocationSearchField } from '../LocationSearchField';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationSearchFieldForSearchType = memo(
  forwardRef<TextInput, LocationSearchFieldForSearchTypeProps>((props, ref) => {
    const {
      searchString,
      deliverySearchString,
      isLoadingLocations,
      locationSearchType,
      onClear,
      onLocationSearchStringSubmit,
      onDeliverySearchStringChange,
    } = props;

    // ─── Delivery ────────────────────────────────────────────────────────

    if (locationSearchType === 'delivery') {
      return (
        <View style={styles.container}>
          <LocationSearchField
            ref={ref}
            value={deliverySearchString}
            isDisabled={isLoadingLocations}
            onChangeText={onDeliverySearchStringChange}
          />
        </View>
      );
    }

    // ─── Pickup | Outpost ────────────────────────────────────────────────

    return (
      <View style={styles.container}>
        <LocationSearchField
          ref={ref}
          value={searchString}
          isDisabled={isLoadingLocations}
          onSubmit={onLocationSearchStringSubmit}
          onClear={onClear}
        />
      </View>
    );
  }),
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationSearchFieldForSearchTypeProps = Readonly<{
  searchString: string;
  deliverySearchString: string;
  locationSearchType?: string;
  isLoadingLocations: boolean;
  onClear: () => void;
  onLocationSearchStringSubmit: (searchString: string) => void;
  onDeliverySearchStringChange: (searchString: string) => void;
}>;

import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';
import { TitleText } from '@sg/garnish';

export const JoinSgRewardsTitle = (props: JoinSgRewardsTitleProps) => {
  const { children } = props;
  return (
    <TitleText sizeMatch={['32']} style={styles.title}>
      {children}
    </TitleText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  title: {
    color: theme.colors.SWEET_CORN,
    padding: theme.spacing['6'],
    textAlign: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type JoinSgRewardsTitleProps = ComponentProps<typeof TitleText>;

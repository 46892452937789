import type { Asset } from 'contentful';
import { getContentfulImageUrl } from '@sg/garnish';

import { useContentfulContentTypeEntries } from '@order/Contentful';
import { useCustomer } from '@order/Customer';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

/**
 * Returns contentful data for the Personalized Recommendation Product Card
 *
 * @see: https://app.contentful.com/spaces/wme4s8lvzccr/content_types/productBannerCard
 */
export function usePersonalizedRecommendationCardContentfulData({
  pause,
}: UsePersonalizedRecommendationCardContentfulDataProps) {
  const { t } = useLocalizationContext();
  const fallback: PersonalizedRecommendationProductCardData = {
    name: t('menu.personalization.recommendation.title'),
    tag: t('menu.personalization.recommendation.label'),
    variant: 'fallback',
  };

  const productCardVariant = useFeatureFlag(
    'em-873-product-recommendation-card-variant',
  );

  const customerName = useCustomer().customer?.firstName;

  // Sets the content of the tag based on the presence of a customer first name
  // and the contentful tag field
  const recommendationCardTag = (contentfulTagField?: string | null) => {
    if (!contentfulTagField) {
      return t('menu.personalization.recommendation.label');
    }

    return contentfulTagField.includes('{customer_first_name}') && customerName
      ? contentfulTagField.replace('{customer_first_name}', customerName)
      : contentfulTagField;
  };

  const { data, fetching: isLoadingContentfulData } =
    useContentfulContentTypeEntries<PersonalizedRecommendationProductCardData>({
      contentType: PRODUCT_BANNER_CARD_CONTENTFUL_ID,
      limit: 5,
      pause,
    });

  // We fetch the correct contentful data based on matching the variant field
  // present in the LaunchDarkly feature flag with the contentful variant field
  const contentfulData =
    data?.find((entry) => entry?.fields?.variant === productCardVariant) ??
    data?.[0];

  const bgImageUrl = contentfulData?.fields?.productImage?.fields?.file?.url;
  const backgroundImage = bgImageUrl
    ? getContentfulImageUrl(bgImageUrl)
    : undefined;

  return {
    isLoadingContentfulData,
    name: contentfulData?.fields?.name ?? fallback.name,
    productImageUrl: backgroundImage,
    tag: recommendationCardTag(contentfulData?.fields?.tag),
    variant: contentfulData?.fields?.variant ?? fallback.variant,
  };
}

// ─── Constants ────────────────────────────────────────────────────

const PRODUCT_BANNER_CARD_CONTENTFUL_ID =
  'personalizedRecommendationProductCard';

// ─── Types ────────────────────────────────────────────────────────

type UsePersonalizedRecommendationCardContentfulDataProps = {
  pause?: boolean;
};

export type PersonalizedRecommendationProductCardData = Readonly<{
  name: string;
  tag: string;
  variant: string;
  productImage?: Asset;
}>;

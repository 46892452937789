import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, Card, LabelText, theme, TitleText } from '@sg/garnish';

export const FeaturedChannel = (props: FeaturedChannelProps) => {
  return (
    <Card style={styles.card} backgroundColor={Palette[props.theme].cardColor}>
      <LabelText style={[styles.label, Palette[props.theme].text]} size={3}>
        {props.label}
      </LabelText>

      <TitleText style={[styles.title, Palette[props.theme].text]} size={6}>
        {props.title}
      </TitleText>

      <Button
        width="100%"
        style={styles.button}
        palette={Palette[props.theme].buttonPalette}
        accessibilityLabel={props.accessibilityLabel}
        isLoading={props.isLoading}
        onPress={props.onPress}
      >
        {props.action}
      </Button>
    </Card>
  );
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  card: {
    alignItems: 'center',
    paddingHorizontal: theme.spacing['4'],
    paddingVertical: theme.spacing['6'],
    borderRadius: theme.radius.large,
  },
  label: {
    marginBottom: theme.spacing['1'],
  },
  title: {
    marginBottom: 'auto',
    textAlign: 'center',
    minHeight: 56,
  },
  button: {
    marginTop: theme.spacing['2'],
  },
  textForQuinoa: {
    color: theme.colors.DARK_KALE,
  },
  textForCucumber: {
    color: theme.colors.KALE,
  },
});

const Palette = {
  cucumber: {
    text: styles.textForCucumber,
    cardColor: theme.colors.CUCUMBER,
    buttonPalette: 'primary' as const,
  },
  quinoa: {
    text: styles.textForQuinoa,
    cardColor: theme.colors.QUINOA,
    buttonPalette: 'secondary' as const,
  },
};

// ─── Types ─────────────────────────────────────────────────────────────

type FeaturedChannelProps = Readonly<{
  theme: 'cucumber' | 'quinoa';
  label: string;
  title: string;
  action: string;
  isLoading: boolean;
  accessibilityLabel: string;
  onPress: () => void;
}>;

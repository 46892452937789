import React, { createContext, useState } from 'react';

import { noop } from '../../utils/noop';
import type {
  StickyContextProps,
  StickyListener,
  StickyScrollViewProps,
} from './Sticky.types';

export const StickyContext = createContext<StickyContextProps>({
  onScroll: noop,

  sticky: false,
  setSticky: noop,

  offset: 0,
  setOffset: noop,

  header: null,
  setHeaderReference: noop,
  clearHeaderReference: noop,

  listener: null,
  setScrollListener: noop,
  clearScrollListener: noop,
});

export const StickyContextProvider = (
  props: StickyScrollViewProps,
): React.ReactElement => {
  const { children } = props;
  const [sticky, setSticky] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [header, setHeader] = useState<React.ReactNode | null>(null);
  const [listener, setListener] = useState<StickyListener | null>(null);

  // Header Reference - For cloning the Sticky component.
  const setHeaderReference = React.useCallback(
    (headerReference: React.ReactNode) => {
      setHeader(headerReference);
    },
    [setHeader],
  );
  const clearHeaderReference = React.useCallback(() => {
    setHeader(null);
  }, [setHeader]);

  // Scroll Listener - For the inside-of-the-scroll sticky component.
  const setScrollListener = React.useCallback(
    (scrollListener: StickyListener) => {
      setListener(scrollListener);
    },
    [setListener],
  );
  const clearScrollListener = React.useCallback(() => {
    setListener(null);
  }, [setListener]);

  // onScroll - Bind Scroll Receiver component to Scroll Listener component.
  const onScroll = React.useCallback(async () => {
    await listener?.onScroll();
  }, [listener]);

  return (
    <StickyContext.Provider
      value={React.useMemo(
        () => ({
          sticky,
          offset,
          header,
          listener,
          onScroll,
          setSticky,
          setOffset,
          setHeaderReference,
          clearHeaderReference,
          setScrollListener,
          clearScrollListener,
        }),
        [
          sticky,
          offset,
          header,
          listener,
          onScroll,
          setSticky,
          setOffset,
          setHeaderReference,
          clearHeaderReference,
          setScrollListener,
          clearScrollListener,
        ],
      )}
    >
      {children}
    </StickyContext.Provider>
  );
};

import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { lineItemNameMessages as messages } from './useLineItemName.messages';

// ────────────────────────────────────────────────────────────────────────────────

export const useLineItemName = (
  product: Product | null,
  lineItem: LineItem | null,
  customName?: string,
) => {
  const formatLineItemName = useFormatLineItemName();

  if (customName) return customName;

  return formatLineItemName(product, lineItem);
};

/**
 * Returns formatted order's line items names string based on their type.
 */
export const useLineItemsNames = (lineItems: readonly LineItem[] = []) => {
  const formatLineItemName = useFormatLineItemName();

  const sortedOrderLineItems = sortOrderLineItemsBasedOnCategory(lineItems);
  const formattedOrderLineItemsNames = sortedOrderLineItems.map((lineItem) =>
    formatLineItemName(lineItem?.product, lineItem),
  );

  return formattedOrderLineItemsNames.join(', ');
};

/**
 * Returns the helper function that returns the line item name,
 * based on the following guidelines and order:
 *
 * 1. Return user-defined custom name if it is set.
 * 2. Return the product name with the "custom" prefix if it was customized.
 * 3. Return product name.
 */
export const useFormatLineItemName = () => {
  const { formatMessage } = useIntl();

  return useCallback(
    (
      product: Product | undefined | null,
      lineItem: LineItem | undefined | null,
    ) => {
      const productName = product?.name ?? lineItem?.product?.name ?? '';
      const customName = lineItem?.customName;
      const isCustomized = Boolean(lineItem?.isCustom && !product?.isCustom);

      // ─────────────────────────────────────────────────────────────────

      if (customName) return customName;

      if (isCustomized) {
        return formatMessage(messages.customProductName, {
          product_name: productName,
        });
      }

      return productName;
    },
    [formatMessage],
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function sortOrderLineItemsBasedOnCategory(lineItems: readonly LineItem[]) {
  return [...lineItems].sort((currentLineItem, nextLineItem) => {
    const shouldMoveLineItemToBeginning =
      checkIfCoreLineItemProduct(currentLineItem) &&
      !checkIfCoreLineItemProduct(nextLineItem);

    return shouldMoveLineItemToBeginning ? -1 : 0;
  });
}

function checkIfCoreLineItemProduct(lineItem: LineItem) {
  const { categoryName } = lineItem.product ?? {};

  return categoryName === 'salads' || categoryName === 'bowls';
}

// ─── Types ───────────────────────────────────────────────────────────────────

type LineItem = Readonly<{
  customName?: string | null;
  product?: {
    name?: string;
    isCustom?: boolean;
    categoryName?: string;
  };
  isCustom?: boolean;
}>;

type Product = Readonly<{
  name?: string;
  categoryName?: string;
  isCustom?: boolean;
}>;

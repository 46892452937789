/**
 * Modals Sizes, as defined by Figma.
 * @see {@link https://www.figma.com/file/8rLhbY9578ml8urgV2NCpb/Garnish---React-Library?node-id=2427-0&t=QhpB14h8pqLQU4pM-0}
 * @see {@link https://sweetgreen.atlassian.net/browse/CELS-809}
 */

import type { ViewStyle } from 'react-native';
import { StyleSheet } from 'react-native';

export type ModalSize =
  | 'small' // ------ Pickers, Small Alerts
  | 'medium' // ----- Forms
  | 'large' // ------ Larger Content
  | 'full' // ------- Fullscreen In-Modal Content
  | 'drawer'; // ---- Full Height, animated from the right

export const MODAL_MAX_HEIGHT = 672;
export const MODAL_SIZE_SMALL = 480;
export const MODAL_SIZE_MEDIUM = 584;
export const MODAL_SIZE_LARGE = 800;
export const MODAL_SIZE_FULL = '100%';
export const MODAL_SIZE_DRAWER = 480;

const styles = StyleSheet.create<Record<ModalSize, ViewStyle>>({
  small: {
    width: MODAL_SIZE_SMALL,
    maxHeight: MODAL_MAX_HEIGHT,
  },
  medium: {
    width: MODAL_SIZE_MEDIUM,
    maxHeight: MODAL_MAX_HEIGHT,
  },
  large: {
    width: MODAL_SIZE_LARGE,
    maxHeight: MODAL_MAX_HEIGHT,
  },
  full: {
    flex: 1,
    width: MODAL_SIZE_FULL,
    height: MODAL_SIZE_FULL,
  },
  drawer: {
    flex: 1,
    width: MODAL_SIZE_DRAWER,
    maxWidth: '100%',
    height: '100%',

    // Drawer is absolutely positioned to the right.
    position: 'absolute',
    right: 0,
  },
});

export const ModalSizesStyles: Record<ModalSize, ViewStyle> = {
  small: styles.small,
  medium: styles.medium,
  large: styles.large,
  full: styles.full,
  drawer: styles.drawer,
};

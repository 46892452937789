import React from 'react';
import type { GestureResponderEvent } from 'react-native';
import { StyleSheet, View } from 'react-native';
import type { IconName } from '@sg/garnish';
import { IconLink, theme } from '@sg/garnish';

export type AccountMenuIconItemProps = Readonly<{
  testID: string;
  label: string;
  iconName: IconName;
  onPress: (event: GestureResponderEvent) => void;
}>;

export const AccountMenuIconItem = ({
  testID,
  label,
  iconName,
  onPress,
}: AccountMenuIconItemProps) => {
  return (
    <View style={styles.container}>
      <IconLink
        testID={testID}
        name={iconName}
        color={theme.colors.NEUTRAL_1}
        width={24}
        height={24}
        accessibilityRole="button"
        accessibilityLabel={label}
        accessibilityHint={label}
        onPress={onPress}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: theme.spacing['1'],
    marginHorizontal: theme.spacing['1'],
    borderRadius: theme.radius.xxxlarge,
    borderWidth: 1,
    borderColor: theme.colors.DARK_KALE,
  },
});

import { defineMessages } from 'react-intl';

export const joinOrSignScreenHeaderButtonsMessages = defineMessages({
  backBtnLabel: {
    defaultMessage: 'Navigate to the previous screen.',
    description: 'Join or Sign-in Screen | Header | Back button label',
  },
  closeBtnLabel: {
    defaultMessage: 'Close the current screen.',
    description: 'Join or Sign-in Screen | Header | Close button label',
  },
});

import React, { type ComponentProps, useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { TextField } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const FeatureFlagsListFilterField = (
  props: FeatureFlagsListFilterFieldProps,
) => {
  const { value, onChange } = props;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onTextChange = useCallback<OnTextFieldChange>(
    (event) => {
      onChange(event.nativeEvent.text);
    },
    [onChange],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <TextField
        placeholder="Filter by flag name"
        value={value}
        onChange={onTextChange}
        clearButtonType="icon"
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingVertical: theme.spacing['2'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type FeatureFlagsListFilterFieldProps = {
  value: string;
  onChange: (value: string) => void;
};

type OnTextFieldChange = NonNullable<
  ComponentProps<typeof TextField>['onChange']
>;

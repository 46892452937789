import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button } from '../../../Button';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardButton = (props: LoyaltyOfferCardButtonProps) => {
  const { fluid = false, ...restProps } = props;

  return (
    <View style={fluid ? styles.containerFluid : undefined}>
      <Button palette="light" {...restProps} />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerFluid: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardButtonProps = {
  fluid?: boolean;
} & ComponentProps<typeof Button>;

/* istanbul ignore file */ /* plugin extension code */

import { useCallback } from 'react';
import { useResponsive } from '@sg/garnish';

import { useKustomerAvailability } from '../../hooks/useKustomerAvailability';
import { getKustomerKeys, logger } from '../../utils';
import { getKustomerInstance } from '../kustomer-web-scripts';

export function useStartKustomer() {
  const isKustomerEnabled = useKustomerAvailability();
  const { brandId } = getKustomerKeys();

  return useCallback(async () => {
    if (!isKustomerEnabled || getKustomerInstance()?.startFinished) return;

    return new Promise<void>((resolve, reject) => {
      // ─── Safety Check ────────────────────────────────────────────────
      if (!checkIfKustomerMethodExists('start')) {
        logger.warn('Cannot Start Kustomer SDK');
        reject();

        return;
      }

      // ─── Response Handler ────────────────────────────────────────────
      const onStart = () => {
        logger.info('Kustomer SDK Started');
        resolve();
      };

      // ─── Execution ───────────────────────────────────────────────────
      logger.info('Starting Kustomer SDK');
      getKustomerInstance()?.start?.({ brandId }, onStart);
    });
  }, [isKustomerEnabled, brandId]);
}

export function useCloseKustomer() {
  const isKustomerEnabled = useKustomerAvailability();

  return useCallback(async () => {
    if (!isKustomerEnabled) return;

    return new Promise<void>((resolve) => {
      // ─── Safety Check ────────────────────────────────────────────────
      if (!checkIfKustomerMethodExists('close')) {
        return;
      }

      // ─── Response Handler ────────────────────────────────────────────
      const onClose = () => {
        logger.info('Kustomer SDK Closed');
        resolve();
      };

      // ─── Execution ───────────────────────────────────────────────────
      logger.info('Closing Kustomer SDK');
      getKustomerInstance()?.close?.(onClose);
    });
  }, [isKustomerEnabled]);
}

export function useStopKustomer() {
  const isKustomerEnabled = useKustomerAvailability();

  return useCallback(async () => {
    if (!isKustomerEnabled) return;

    return new Promise<void>((resolve) => {
      // ─── Safety Check ────────────────────────────────────────────────
      if (!checkIfKustomerMethodExists('stop')) {
        return;
      }

      // ─── Response Handler ────────────────────────────────────────────
      const onStop = () => {
        logger.info('Kustomer SDK Stopped');
        resolve();
      };

      // ─── Execution ───────────────────────────────────────────────────
      logger.info('Stopping Kustomer SDK');
      getKustomerInstance()?.stop?.(onStop);
    });
  }, [isKustomerEnabled]);
}

export function useOpenKustomer() {
  const isKustomerEnabled = useKustomerAvailability();

  return useCallback(async () => {
    if (!isKustomerEnabled) return;

    return new Promise<void>((resolve, reject) => {
      // ─── Safety Check ────────────────────────────────────────────────
      if (!checkIfKustomerMethodExists('open')) {
        logger.warn('Cannot Open Kustomer SDK');
        reject();

        return;
      }

      // ─── Response Handler ────────────────────────────────────────────
      const onOpen = () => {
        logger.info('Kustomer Opened');
        resolve();
      };

      // ─── Execution ───────────────────────────────────────────────────
      logger.info('Opening Kustomer');
      getKustomerInstance()?.open?.(onOpen);
    });
  }, [isKustomerEnabled]);
}

export function useLoginKustomer() {
  const isKustomerEnabled = useKustomerAvailability();

  return useCallback(
    async (props: LoginKustomerProps) => {
      const { kustomerToken } = props;

      if (!isKustomerEnabled) return;

      return new Promise<void>((resolve, reject) => {
        // ─── Safety Check ──────────────────────────────────────────────
        if (!checkIfKustomerMethodExists('login')) {
          logger.warn('Cannot Login Kustomer');
          reject();

          return;
        }

        // ─── Response Handler ──────────────────────────────────────────
        const onLogin = (
          loginCallbackResponse: KustomerCallbackResponse,
          error: unknown,
        ) => {
          if (error || !loginCallbackResponse?.identified) {
            logger.error('Failed to Log In Kustomer');
            reject();

            return;
          }

          logger.info('Kustomer Logged In');
          resolve();
        };

        // ─── Execution ─────────────────────────────────────────────────
        logger.info('Logging in Kustomer');
        getKustomerInstance()?.login?.({ jwtToken: kustomerToken }, onLogin);
      });
    },
    [isKustomerEnabled],
  );
}

export function useLogoutKustomer() {
  const isKustomerEnabled = useKustomerAvailability();

  return useCallback(async () => {
    if (!isKustomerEnabled) return;

    return new Promise<void>((resolve, reject) => {
      // ─── Safety Check ────────────────────────────────────────────────
      if (!checkIfKustomerMethodExists('logout')) {
        logger.warn('Cannot Log Out Kustomer');
        reject();

        return;
      }

      // ─── Response Handler ────────────────────────────────────────────
      const onLogout = () => {
        logger.info('Kustomer Logged Out');
        resolve();
      };

      // ─── Execution ───────────────────────────────────────────────────
      logger.info('Logging Out Kustomer');
      getKustomerInstance()?.logout?.(onLogout);
    });
  }, [isKustomerEnabled]);
}

/**
 * We must stop the Kustomer SDK upon closing it on mobile breakpoint (`XS`).
 */
export function useStopKustomerOnMobileClose() {
  const { currentBreakpoint } = useResponsive();
  const isKustomerEnabled = useKustomerAvailability();
  const stopKustomer = useStopKustomer();

  return useCallback(() => {
    if (!isKustomerEnabled || !currentBreakpoint.isXS) return;

    // ─── Safety Check ──────────────────────────────────────────────────
    if (!checkIfKustomerMethodExists('addListener')) {
      logger.warn('Cannot Listen for Kustomer Close');

      return;
    }

    // ─── Execution ─────────────────────────────────────────────────────
    logger.info('Listening for Kustomer Close');
    getKustomerInstance()?.addListener?.('onClose', stopKustomer);
  }, [isKustomerEnabled, currentBreakpoint.isXS, stopKustomer]);
}

function checkIfKustomerMethodExists(methodName: KustomerMethodName) {
  return typeof getKustomerInstance()?.[methodName] === 'function';
}

// ─── Internal Types ────────────────────────────────────────────────────

type KustomerMethodName =
  | 'start'
  | 'close'
  | 'stop'
  | 'open'
  | 'login'
  | 'logout'
  | 'addListener';
type LoginKustomerProps = Readonly<{
  kustomerToken: string;
}>;

// ─── Kustomer SDK Types ────────────────────────────────────────────────

type KustomerCallbackResponse = Readonly<{
  identified: boolean;
  customerId: string;
  email?: string;
  externalId?: string;
}>;

import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

export const HowItWorksCardsContainer = (
  props: HowItWorksCardsContainerProps,
) => {
  const { children } = props;
  return <View style={styles.cardsContainer}>{children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cardsContainer: {
    flex: 1,
    borderRadius: theme.spacing[3],
    overflow: 'hidden',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type HowItWorksCardsContainerProps = ComponentProps<typeof View>;

import React from 'react';
import { IllusEmployeeSpoon, reloadApp } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { NotFoundView } from '../NotFoundView';

/**
 * This page is taking longer than expected to load.
 * Please refresh and try again in a few moments.
 * <Refresh>
 *
 * Pressing refresh will reload the app.
 * @see {@link https://www.figma.com/file/X6JDYJlWII7fn1UUUsJ51B/Sweetpass-Account%2C-Upgrade-%2B-Downgrade?type=design&node-id=14037-178683&mode=design&t=EfuQnpuoGdnsMmEf-4}
 */
export const RefreshErrorState = () => {
  const { t } = useLocalizationContext();

  return (
    <NotFoundView
      illustration={IllusEmployeeSpoon}
      title={t('general.refresh.title')}
      description={t('general.refresh.body')}
      btnLabel={t('general.refresh.cta')}
      onBtnPress={reloadApp}
    />
  );
};

import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';
import { BodyText, TitleText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const BenefitCardTitle = (props: BenefitCardImgProps) => {
  const { children: title } = props;

  const { match } = useResponsive();
  const Text = match([TextXS, TextSM]);

  const textWithoutLineBreaks = title.replaceAll(/[\n\r]/g, ' ');
  const formattedText = match([textWithoutLineBreaks, title]);

  return <Text>{formattedText}</Text>;
};

const TextXS = (props: ComponentProps<typeof BodyText>) => {
  return (
    <BodyText style={styles.titleXS} sizeMatch={['14']} bold>
      {props.children}
    </BodyText>
  );
};

const TextSM = (props: ComponentProps<typeof TitleText>) => {
  return (
    <TitleText style={styles.titleSM} sizeMatch={['18', '24', '32']}>
      {props.children}
    </TitleText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  titleXS: {
    color: theme.colors.KALE,
    textAlign: 'left',
    textTransform: 'none',
    marginBottom: 0,
  },
  titleSM: {
    color: theme.colors.KALE,
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BenefitCardImgProps = Readonly<{
  children: string;
}>;

import { type RefObject, useEffect } from 'react';
import { type HostedFrameRef } from '@sg/garnish';

/**
 * Keeps the loading state in sync with the Stripe integration.
 */
export const useUpdateStripeLoading = (
  stripeReference: RefObject<HostedFrameRef>,
  isLoading: boolean,
) => {
  useEffect(() => {
    stripeReference?.current?.postMessage?.(
      JSON.stringify({ type: 'UPDATE_LOADING_STATE', isLoading }),
    );
  }, [isLoading, stripeReference]);
};

import React, { useCallback } from 'react';
import { openUrl } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { ShareOption } from './ShareOption';
import type { ShareOptionProps } from './types';

export const EmailShareOption = ({ shareLink }: ShareOptionProps) => {
  const { t } = useLocalizationContext();

  const handleShare = useCallback(async () => {
    const shareUrl = `mailto:?to=&subject=${t(
      'account.refer-friend.share.title',
    )}&body=${t('account.refer-friend.share.body', { shareLink })}`;

    await openUrl(shareUrl, t('general.error'));
  }, [t, shareLink]);

  return (
    <ShareOption
      testID="account.refer-friend.share.email"
      hint={t('account.refer-friend.share.email')}
      iconName="IconEmail"
      bgColor="#2E6B52"
      iconSize={24}
      handleShare={handleShare}
    />
  );
};

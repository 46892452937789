import { StyleSheet, type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const sizeStyles = StyleSheet.create<AlertContainerSizeStyles>({
  md: {
    gap: theme.spacing['2'],
    borderRadius: theme.radius.medium,
    paddingVertical: theme.spacing['2'],
    paddingHorizontal: theme.spacing['4'],
  },
  lg: {
    gap: theme.spacing['4'],
    borderRadius: theme.radius.large,
    paddingVertical: theme.spacing['4'],
    paddingHorizontal: theme.spacing['6'],
  },
});

// ─────────────────────────────────────────────────────────────────────────────

export type AlertContainerSize = 'md' | 'lg';

type AlertContainerSizeStyles = Record<AlertContainerSize, ViewStyle>;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BodyText, PressableLink, theme } from '@sg/garnish';

export const HomeHeaderCta = (props: HomeHeaderCtaProps) => {
  return (
    <View style={styles.headerCtaWrapper}>
      <PressableLink
        style={styles.headerCta}
        accessibilityLabel={props.accessibilityLabel}
        to={props.deepLink}
        onPress={props.onPressCta}
      >
        <BodyText sizeMatch={['16']}>{props.children}</BodyText>
      </PressableLink>
    </View>
  );
};

const styles = StyleSheet.create({
  headerCtaWrapper: {
    alignItems: 'flex-start',
  },
  headerCta: {
    flex: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 40,
    borderWidth: 1,
    borderRadius: theme.radius.xxxlarge,
    backgroundColor: theme.colors.LIME,
    borderColor: theme.colors.DARK_KALE,
    color: theme.colors.DARK_KALE,
    paddingHorizontal: theme.spacing['4'],
  },
});

type HomeHeaderCtaProps = {
  accessibilityLabel: string;
  deepLink: string;
  children: React.ReactNode;
  onPressCta: () => void;
};

import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import { ScrollView, type View } from 'react-native';
import useMergedRef from '@react-hook/merged-ref';

import { usePressableState } from '../../hooks';
import { webOnlyProps, webOnlyStyles } from '../../utils';
import { focusOnElement } from '../../utils/accessibility/focus/focusOnElement.web';
import { ListBox, ListBoxOption } from '../ListBox';
import { BodyText } from '../Text';
import { styles } from './Select.styles';
import type { OptionProps, OptionsProps } from './Select.types';

export const Options = forwardRef<View, OptionsProps>((props, ref) => {
  const {
    opened,
    selected,
    options,
    style,
    nativeID,
    accessibilityLabel,
    onLayout,
    onSelect,
  } = props;

  const firstElement = useRef(null);

  useEffect(() => {
    if (!opened) return;
    focusOnElement(firstElement);
  }, [firstElement, opened]);

  if (!opened) return null;

  return (
    <ListBox
      nativeID={nativeID}
      ref={ref}
      style={[styles.options, style]}
      selectedOption={String(selected)}
      onLayout={onLayout}
    >
      <ScrollView style={styles.scrollView}>
        {options.map((option, index) => (
          <Option
            ref={index === 0 ? firstElement : null}
            key={option.value}
            isSelected={option.value === selected}
            option={option}
            accessibilityLabel={accessibilityLabel}
            onSelect={onSelect}
          />
        ))}
      </ScrollView>
    </ListBox>
  );
});

const Option = forwardRef<View, OptionProps>((props, ref) => {
  const { isSelected = false, option, accessibilityLabel, onSelect } = props;

  const innerRef = useRef<View>(null);
  const multiRef = useMergedRef(ref, innerRef);
  const { isActive, isHovered } = usePressableState(innerRef);

  const selectOption = useCallback(() => {
    onSelect(option.value);
  }, [option, onSelect]);

  const hoveredStyle = isHovered ? styles.hovered : null;
  const activeStyle = isActive ? styles.pressed : null;
  const selectedStyle = isSelected ? styles.selected : null;
  const disabledStyle = option.disabled
    ? [
        styles.optionDisabled,
        webOnlyStyles({ userSelect: 'none', cursor: 'not-allowed' }),
      ]
    : null;
  const style = [
    styles.option,
    hoveredStyle,
    activeStyle,
    selectedStyle,
    disabledStyle,
  ];
  const labelStyle = option.disabled ? styles.disabled : null;

  return (
    <ListBoxOption
      nativeID={String(option.value)}
      ref={multiRef}
      style={style}
      disabled={option.disabled}
      isSelected={isSelected}
      accessibilityLabel={`${accessibilityLabel}, ${option.label}`}
      onPress={selectOption}
    >
      <BodyText
        style={labelStyle}
        size={4}
        {...webOnlyProps({ accessibilityHidden: true })} // so that the a11y label is read instead, only needed because the role is option instead of button.
      >
        {option.label}
      </BodyText>
    </ListBoxOption>
  );
});

import React from 'react';
import { StyleSheet } from 'react-native';
import { FadeView, theme } from '@sg/garnish';

import { Home } from '@order/features/home';

import { useHomeBannerInfo } from '../../hooks';

export const HomeScreenBanner = () => {
  const bannerInfo = useHomeBannerInfo();

  return (
    <FadeView
      duration={500}
      style={styles.fadeViewElevation}
      show={Boolean(bannerInfo)}
    >
      {bannerInfo ? <Home.Banner {...bannerInfo} /> : null}
    </FadeView>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  fadeViewElevation: {
    zIndex: theme.zIndex.fixed,
  },
});

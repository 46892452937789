import React, { type ComponentProps, forwardRef, useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BagIcon, Button } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuFloatingButtonsBagButton = forwardRef<
  View,
  MenuFloatingButtonsBagButtonProps
>((props, ref) => {
  const { lineItemsCount, disabled, style, ...restProps } = props;

  const BagIconWithCount = useCallback(
    () => <BagIcon bagCount={lineItemsCount} />,
    [lineItemsCount],
  );

  return (
    <View style={styles.container} pointerEvents="box-none">
      <Button
        ref={ref}
        size="large"
        palette="lime"
        style={[styles.button, style]}
        disabled={disabled}
        rightElement={BagIconWithCount}
        {...restProps}
      >
        <FormattedMessage {...messages.label} />
      </Button>
    </View>
  );
});

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  label: {
    defaultMessage: 'View bag',
    description: 'Menu | Floating Button | Bag | Label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    alignItems: 'flex-end',
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['4'],
  },
  button: {
    ...theme.elevations['12'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuFloatingButtonsBagButtonProps = {
  lineItemsCount: number;
} & Omit<ComponentProps<typeof Button>, 'ref' | 'children' | 'rightElement'>;

import type { ComponentProps } from 'react';
import React from 'react';
import type { ScrollViewProps, StyleProp, ViewStyle } from 'react-native';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../hooks';
import { Container as GarnishContainer } from '../Container';
import { IconLink } from '../Icon';
import type { useReCaptcha } from '../ReCaptcha';
import { ReCaptcha } from '../ReCaptcha';

// ────────────────────────────────────────────────────────────────────────────────

export const Container = (props: ContainerProps) => {
  const { currentBreakpoint, minWidth } = useResponsive();
  const {
    children,
    style,
    contentStyle,
    reCaptchaRef,
    reCaptchaMachine,
    onClose,
    onRecaptchaVerify,
    onRecaptchaError,
    removeSpacing = false,
    ...restProps
  } = props;

  const showCloseBtnBlock = currentBreakpoint.isXS;
  const shouldRenderRecaptcha =
    reCaptchaRef !== undefined && onRecaptchaVerify !== undefined;

  // ─── STYLES ─────────────────────────────────────────────────────────

  const wrapperStyle = [
    styles.wrapper,
    !currentBreakpoint.isXS && styles.wrapperTabletUp,
    style,
  ];
  const content = [
    styles.content,
    !currentBreakpoint.isXS && styles.contentTabletUp,
    contentStyle,
  ];
  const recaptchaStyle = Platform.OS === 'web' && [
    styles.recaptchaWeb,
    minWidth.isSM && styles.recaptchaWebSM,
  ];

  return (
    <ScrollView
      contentContainerStyle={wrapperStyle}
      {...restProps}
      style={content}
    >
      {showCloseBtnBlock && onClose ? (
        <GarnishContainer style={styles.header}>
          <IconLink name="IconClose" onPress={onClose} />
        </GarnishContainer>
      ) : null}

      <GarnishContainer removeSpacing={removeSpacing} size="medium">
        {children}
      </GarnishContainer>

      {shouldRenderRecaptcha ? (
        <View style={recaptchaStyle}>
          <ReCaptcha
            ref={reCaptchaRef}
            machine={reCaptchaMachine}
            onVerify={onRecaptchaVerify}
            onError={onRecaptchaError}
          />
        </View>
      ) : null}
    </ScrollView>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ContainerProps = Readonly<{
  onClose?: () => void;
  contentStyle?: StyleProp<ViewStyle>;
  removeSpacing?: boolean;
  reCaptchaRef?: ReturnType<typeof useReCaptcha>['reCaptchaRef'];
  reCaptchaMachine?: ComponentProps<typeof ReCaptcha>['machine'];
  onRecaptchaVerify?: ComponentProps<typeof ReCaptcha>['onVerify'];
  onRecaptchaError?: ComponentProps<typeof ReCaptcha>['onError'];
}> &
  ScrollViewProps;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    flexGrow: 1,
    paddingBottom: theme.spacing['6'],
  },
  wrapperTabletUp: {
    justifyContent: 'center',
  },
  content: {
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  contentTabletUp: {
    paddingVertical: theme.spacing['20'],
  },
  header: {
    minHeight: 48,
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: theme.spacing['4'],
  },
  recaptchaWeb: {
    marginTop: theme.spacing['6'],
  },
  recaptchaWebSM: {
    height: 70,
  },
});

import { useCallback } from 'react';
import { useClient } from 'urql';

import {
  BagTelemetryDocument,
  type BagTelemetryQuery,
} from '../../GraphQL/BagTelemetry.generated';

// ────────────────────────────────────────────────────────────────────────────

/**
 * A hook for fetching the telemetry data needed for checkout.
 */
export const useFetchTelemetryData = () => {
  const client = useClient();

  // ─── Queries ──────────────────────────────────────────────────────────────

  const fetchTelemetryData = useCallback(async () => {
    const queryTelemetryData = client.query<BagTelemetryQuery>;

    const response = await queryTelemetryData(
      BagTelemetryDocument,
      {},
      { requestPolicy: 'network-only' },
    ).toPromise();

    const lastOrderId =
      response.data?.orders.__typename === 'OrdersResponseSuccess'
        ? response.data?.orders?.orders?.[0]?.id
        : null;

    return {
      customerId: response.data?.currentCustomer?.id ?? '',
      firstName: response.data?.currentCustomer?.firstName ?? '',
      lastName: response.data?.currentCustomer?.lastName ?? '',
      email: response.data?.currentCustomer?.email ?? '',
      phoneNumber: response.data?.currentCustomer?.phoneNumber ?? '',
      isNewCustomer: !lastOrderId,
    };
  }, [client.query]);

  // ──────────────────────────────────────────────────────────────────────────

  return { fetchTelemetryData };
};

import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { Header } from '@expo/html-elements';
import { theme } from '@sg/garnish';

import { MORE_INFO_MODAL_HEADER_ICON_SIZE } from '../../MenuMoreInfo.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoModalHeader = (props: PropsWithChildren) => {
  const { children } = props;

  return <Header style={styles.header}>{children}</Header>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    display: 'flex', // required
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['4'],
    marginBottom: theme.spacing['2'],
    paddingLeft: MORE_INFO_MODAL_HEADER_ICON_SIZE + theme.spacing['4'],
  },
});

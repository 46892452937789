import { type ComponentProps } from 'react';
import { PixelRatio } from 'react-native';
import { type Image, type ImageSource } from 'expo-image';

/**
 * Returns asset/source based on current screen DPI.
 */
export function getImageForCurrentScreen(source?: ExpoImageSource) {
  if (!Array.isArray(source)) {
    return source;
  }

  const screenPixelRatio = Math.ceil(PixelRatio.get());
  const lastAvailableImage = source.at(-1);

  return source[screenPixelRatio - 1] ?? lastAvailableImage;
}

/**
 * A tiny utility that adds (if needed) the `https` prefix to remote asset links that
 * do not have the protocol.
 *
 * NOTE: Some remote links exclude protocol from their links so that protocol can be
 *       dynamically determined based on the consumer page protocol to avoid mixed content.
 *
 *       However, this will not work on native devices, where the correct protocol
 *       must be explicitly provided.
 *
 * @example
 *
 * withHttps('//example.com/image.png') -> 'https://example.com/image.png'
 * withHttps('https://example.com/image.png') -> 'https://example.com/image.png'
 */
export function withHttpsProtocol(
  imageSource: ExpoImageSource,
): ExpoImageSource {
  // invalid source
  if (!imageSource) {
    return;
  }

  // skip local assets
  if (typeof imageSource === 'number') {
    return imageSource;
  }

  // multiple assets case
  if (Array.isArray(imageSource)) {
    return imageSource.map(addHttpsProtocol) as ImageSource[];
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return addHttpsProtocol(imageSource);
}

function addHttpsProtocol(
  remoteImageSource: ImageSource | string,
): ImageSource | string {
  const isStringSourceWithoutProtocol =
    typeof remoteImageSource === 'string' &&
    checkIfSourceUriHasProtocol(remoteImageSource);

  const isObjectSourceWithoutProtocol =
    typeof remoteImageSource === 'object' &&
    typeof remoteImageSource?.uri === 'string' &&
    checkIfSourceUriHasProtocol(remoteImageSource.uri);

  if (isStringSourceWithoutProtocol) {
    return `https:${remoteImageSource}`;
  }

  if (isObjectSourceWithoutProtocol) {
    return { ...remoteImageSource, uri: `https:${remoteImageSource.uri}` };
  }

  return remoteImageSource;
}

function checkIfSourceUriHasProtocol(uri: string): boolean {
  return uri.startsWith('//');
}

// ─── Types ───────────────────────────────────────────────────────────────────

type ExpoImageSource = ComponentProps<typeof Image>['source'];

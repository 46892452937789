import type { RefObject } from 'react';
import React, { useContext, useEffect } from 'react';
import type { View } from 'react-native';

import { BoundScrollContext, BoundScrollView } from '../BoundScrollView';
import { Sticky } from './Sticky';
import type { StickyScrollViewProps } from './Sticky.types';
import { measurePagePosition } from './Sticky.utils';
import { StickyContext, StickyContextProvider } from './StickyContext';

// ────────────────────────────────────────────────────────────────────────────────

export const StickyScrollView = (props: StickyScrollViewProps) => (
  <StickyContextProvider>
    <StickyScrollViewContent {...props} />
  </StickyContextProvider>
);

// ────────────────────────────────────────────────────────────────────────────────

export const StickyScrollViewContent = (
  props: StickyScrollViewProps,
): React.ReactElement => {
  const { children, stuckStyle, unstuckStyle, style, ...rest } = props;
  const {
    sticky,
    header,
    offset,
    onScroll: stickyOnScroll,
    setOffset,
  } = useContext(StickyContext);
  const { scrollReference, addScrollListener, removeScrollListener } =
    useContext(BoundScrollContext);
  const missingOffset = !offset;
  const scrollListener = React.useMemo(
    () => ({
      source: 'StickyScrollView',
      async onScroll() {
        if (missingOffset && scrollReference) {
          const measurableReference =
            scrollReference as unknown as RefObject<View>;
          const measuredOffset = await measurePagePosition(measurableReference);

          setOffset(measuredOffset);
        }

        stickyOnScroll();
      },
    }),
    [missingOffset, scrollReference, stickyOnScroll, setOffset],
  );

  useEffect(() => {
    addScrollListener(scrollListener);

    return () => {
      removeScrollListener(scrollListener);
    };
  }, [scrollListener, addScrollListener, removeScrollListener]);

  return (
    <>
      {Boolean(header) && <Sticky outside>{header}</Sticky>}
      <BoundScrollView
        {...rest}
        style={[style, sticky ? stuckStyle : unstuckStyle]}
      >
        {children}
      </BoundScrollView>
    </>
  );
};

import React, { useCallback } from 'react';
import { useResponsive } from '@sg/garnish';

import {
  useDeletePaymentMethod,
  usePaymentMethods,
  useUpdatePaymentMethod,
} from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { useTrackEffect } from '@order/Telemetry';

import { CreditCardFormEdit } from '../CreditCard';
import { Modal } from '../Modal';

export const CreditCardEditModal = (props: CreditCardEditModalProps) => {
  const { visible, paymentMethodId, onRequestClose } = props;

  useTrackEffect('payment_credit.view_card');
  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  const { paymentMethods } = usePaymentMethods();

  const paymentMethod = paymentMethods?.find(
    (item) => item.id === paymentMethodId,
  );

  const paymentMethodType =
    paymentMethod?.__typename === 'PaymentMethodCard'
      ? 'PAYMENT_METHOD_CARD'
      : 'BILLING_ACCOUNT';

  const { updatePaymentMethod, isUpdating } = useUpdatePaymentMethod({
    onSuccess: onRequestClose,
  });

  const { deletePaymentMethod, isDeleting } = useDeletePaymentMethod({
    onSuccess: onRequestClose,
  });

  const handleDeletePaymentMethod = useCallback(async () => {
    if (!paymentMethod) return;

    return deletePaymentMethod(paymentMethod);
  }, [deletePaymentMethod, paymentMethod]);

  if (!paymentMethod) {
    return null;
  }

  return (
    <Modal
      fitHeight
      visible={visible}
      accessibilityLabel={t('credit-card-form.edit')}
      presentationStyle="pageSheet"
      size={match(['full', 'small'])}
      onRequestClose={onRequestClose}
    >
      <Modal.Header onClose={onRequestClose}>
        {t('credit-card-form.edit')}
      </Modal.Header>

      <CreditCardFormEdit
        paymentMethodId={paymentMethod?.id}
        paymentMethodType={paymentMethodType}
        cardType={paymentMethod?.cardType}
        description={paymentMethod?.description ?? ''}
        nickname={paymentMethod?.nickname ?? ''}
        isDefault={paymentMethod?.isDefault}
        isSaving={isUpdating}
        isDeleting={isDeleting}
        onEdit={updatePaymentMethod}
        onDelete={handleDeletePaymentMethod}
      />
    </Modal>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type CreditCardEditModalProps = Readonly<{
  paymentMethodId: string;
  visible: boolean;
  onRequestClose: () => void;
}>;

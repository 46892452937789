import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import { theme, TYPE_CONFIG } from '@garnish/constants';

import { usePressableState, useResponsive } from '../../../hooks';
import { webOnlyStyles } from '../../../utils';
import { Container } from '../../Container';
import { IconLink } from '../../Icon';
import { HStack } from '../../Stack';
import { BodyText, DisplayText } from '../../Text';

export const CustomizableProductHeader = (
  props: CustomizableProductHeaderProps,
) => {
  const {
    value,
    price,
    calories,
    description,
    isModifiableProduct,
    textInputAccessibilityLabel,
    palette = 'oatmeal',
    style,
    onCancelEditing,
    onStartEditing,
    onFinishEditing,
    onChangeText,
  } = props;

  const { textFontSize, descriptionFontSize } = useTextSizes();
  const headerWrapperDynamicStyles = useHeaderWrapperDynamicStyles();
  const headerWrapperStyles = [headerWrapperDynamicStyles, style];

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(value);

  // ─── HELPERS ────────────────────────────────────────────────────────

  const handleStartEditing = useCallback(() => {
    setIsEditing(true);
    onStartEditing?.();
  }, [onStartEditing]);

  const handleCancelEditing = useCallback(() => {
    setIsEditing(false);
    setName(value);
    onCancelEditing?.();
  }, [value, onCancelEditing]);

  const handleChangeText = useCallback(
    (text: string) => {
      setName(text);
      onChangeText?.(text);
    },
    [onChangeText],
  );

  const handleFinishEditing = useCallback(() => {
    const shouldResetName = name.length === 0;

    setIsEditing(false);

    if (shouldResetName) {
      setName(value);

      return;
    }

    onFinishEditing?.(name);
  }, [name, value, onFinishEditing]);

  // ─── STYLES ─────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    palette === 'oatmeal' ? styles.containerOatmeal : styles.containerCream,
  ];

  // ─── EFFECTS ────────────────────────────────────────────────────────

  // sync external value with local state
  useEffect(() => {
    setName(value);
  }, [value]);

  // ────────────────────────────────────────────────────────────────────

  return (
    <Container style={containerStyles}>
      <View style={headerWrapperStyles}>
        <View style={styles.headerTitleWrapper}>
          <CustomizableProductHeaderTitle
            show={!isEditing}
            title={name}
            onStartEditing={handleStartEditing}
            isModifiableProduct={Boolean(isModifiableProduct)}
          />

          <CustomizableProductHeaderTitleTextInput
            show={isEditing}
            defaultValue={name}
            textInputAccessibilityLabel={textInputAccessibilityLabel}
            onCancelEditing={handleCancelEditing}
            onChangeText={handleChangeText}
            onFinishEditing={handleFinishEditing}
          />
        </View>

        <Text style={styles.priceAndCaloriesWrapper}>
          <BodyText testID="pdp-product-price" size={textFontSize}>
            {price}
          </BodyText>
          <BodyText size={textFontSize}> - </BodyText>
          <BodyText size={textFontSize}>{calories}</BodyText>
        </Text>
      </View>

      {description ? (
        <View style={styles.descriptionWrapper}>
          <BodyText size={descriptionFontSize}>{description}</BodyText>
        </View>
      ) : null}
    </Container>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const CustomizableProductHeaderTitle = (
  props: CustomizableProductHeaderTitleProps,
) => {
  const {
    title,
    show,
    isModifiableProduct,
    editAccessibilityLabel = 'edit product name',
    onStartEditing,
  } = props;
  const { match } = useResponsive();
  const iconSize = useIconSize();

  const titleStyle = [
    styles.headerTitle,
    match([styles.titleMobile, styles.titleTablet]),
  ];

  if (!show) return null;

  return (
    <>
      <DisplayText
        accessibilityRole="header"
        aria-level={1}
        style={titleStyle}
        testID="product-header.name"
      >
        {title}
      </DisplayText>

      {isModifiableProduct ? (
        <IconLink
          testID="product-header.btn-edit"
          name="IconEdit"
          accessibilityLabel={editAccessibilityLabel}
          onPress={onStartEditing}
          iconSize={iconSize}
        />
      ) : null}
    </>
  );
};

const CustomizableProductHeaderTitleTextInput = (
  props: CustomizableProductHeaderTitleTextInputProps,
) => {
  const {
    show,
    defaultValue,
    textInputAccessibilityLabel,
    onCancelEditing,
    onChangeText,
    onFinishEditing,
  } = props;

  const { match } = useResponsive();

  const textInputStyle = [
    webOnlyStyles({ outline: theme.colors.GRAY, outlineOffset: 3 }),
    match([styles.titleMobile, styles.titleTablet]),
  ];

  // ────────────────────────────────────────────────────────────────────

  if (!show) return null;

  return (
    <View style={styles.headerTitleInputContainer}>
      <View style={styles.headerTitleInputWrapper}>
        <TextInput
          testID="product-header.text-input"
          accessibilityLabel={textInputAccessibilityLabel}
          autoFocus
          onChangeText={onChangeText}
          defaultValue={defaultValue}
          onSubmitEditing={onFinishEditing}
          style={textInputStyle}
          maxLength={40}
        />
      </View>

      <View>
        <CustomizableProductHeaderTextInputControls
          onCancel={onCancelEditing}
          onSubmit={onFinishEditing}
        />
      </View>
    </View>
  );
};

const CustomizableProductHeaderTextInputControls = (
  props: CustomizableProductHeaderTextInputControlsProps,
) => {
  const { onCancel, onSubmit } = props;

  const iconSize = useIconSize();

  const closeBtnRef = useRef(null);
  const closeBtnState = usePressableState(closeBtnRef);

  const submitBtnRef = useRef(null);
  const submitBtnState = usePressableState(submitBtnRef);

  // ────────────────────────────────────────────────────────────────────

  const defaultBtnColor = theme.colors.TEXT_COLOR;

  const closeBtnColor =
    closeBtnState.isFocused || closeBtnState.isHovered
      ? theme.colors.CAUTION
      : defaultBtnColor;
  const submitBtnColor =
    submitBtnState.isFocused || submitBtnState.isHovered
      ? theme.colors.SUCCESS
      : defaultBtnColor;

  // ────────────────────────────────────────────────────────────────────

  return (
    <HStack gap={theme.spacing['4']}>
      <IconLink
        ref={closeBtnRef}
        testID="product-header.btn-close"
        name="IconClose"
        onPress={onCancel}
        iconSize={iconSize}
        color={closeBtnColor}
      />
      <IconLink
        ref={submitBtnRef}
        testID="product-header.btn-submit"
        name="IconCheck"
        onPress={onSubmit}
        iconSize={iconSize}
        color={submitBtnColor}
      />
    </HStack>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingBottom: theme.spacing['6'],
  },
  containerOatmeal: {
    backgroundColor: theme.colors.OATMEAL,
  },
  containerCream: {
    backgroundColor: theme.colors.CREAM,
  },

  // ─── HEADER ─────────────────────────────────────────────────────────

  headerWrapperXSmall: {
    paddingTop: theme.spacing['6'],
  },
  headerWrapperSmallUp: {
    paddingTop: theme.spacing['10'],
  },
  headerTitleWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 36,
  },
  headerTitle: {
    paddingRight: theme.spacing['4'],
  },
  priceAndCaloriesWrapper: {
    paddingTop: theme.spacing['2'],
  },

  // ─── TITLE ──────────────────────────────────────────────────────────

  titleMobile: {
    ...TYPE_CONFIG.DISPLAY['24'],
  },
  titleTablet: {
    ...TYPE_CONFIG.DISPLAY['32'],
  },

  // ─── TEXT FIELD AND CTAs ────────────────────────────────────────────

  headerTitleInputContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTitleInputWrapper: {
    flex: 1,
    paddingRight: theme.spacing['4'],
  },

  // ─── DESCRIPTION ────────────────────────────────────────────────────

  descriptionWrapper: {
    paddingTop: theme.spacing['4'],
  },
});

// ─── HELPERS ────────────────────────────────────────────────────────────────────

const useTextSizes = (): CustomizableProductHeaderTextSizes => {
  const { currentBreakpoint } = useResponsive();

  return {
    textFontSize: currentBreakpoint.isXS ? 4 : 3,
    descriptionFontSize: 4,
  };
};

const useIconSize = () => {
  const { currentBreakpoint } = useResponsive();

  return currentBreakpoint.isXS ? 24 : 32;
};

const useHeaderWrapperDynamicStyles = () => {
  const { currentBreakpoint } = useResponsive();

  return currentBreakpoint.isXS
    ? styles.headerWrapperXSmall
    : styles.headerWrapperSmallUp;
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type CustomizableProductHeaderProps = Readonly<{
  value: string;
  price: string;
  calories: string;
  description?: string;
  isModifiableProduct?: boolean;
  palette?: 'oatmeal' | 'cream';
  textInputAccessibilityLabel: string;
  style?: ViewProps['style'];
  onFinishEditing?: (text: string) => void;
  onStartEditing?: () => void;
  onCancelEditing?: () => void;
  onChangeText?: (text: string) => void;
}>;

type CustomizableProductHeaderTextSizes = Readonly<{
  textFontSize: 3 | 4;
  descriptionFontSize: 4;
}>;

type CustomizableProductHeaderTitleProps = Readonly<{
  title: string;
  show: boolean;
  isModifiableProduct: boolean;
  editAccessibilityLabel?: string;
  onStartEditing: () => void;
}>;

type CustomizableProductHeaderTitleTextInputProps = Readonly<{
  defaultValue: string;
  show: boolean;
  textInputAccessibilityLabel: string;
  onCancelEditing: () => void;
  onFinishEditing: () => void;
  onChangeText: (text: string) => void;
}>;

type CustomizableProductHeaderTextInputControlsProps = Readonly<{
  onCancel: () => void;
  onSubmit: () => void;
}>;

import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ProductLineItemCardV2 } from '@sg/garnish';

import { type ChangeLineItemQuantityParams } from '@order/features/ordering';
import type { PartialLineItem, PartialProduct } from '@order/graphql';
import {
  useLineItemDescription,
  useLineItemIngredients,
  useLineItemName,
  useLineItemPrice,
} from '@order/shared/hooks';

/**
 * This component is a wrapper for the {ProductLineItemCardV2} from garnish.
 * It loads the messages and sets up the intermediate callbacks for it.
 */
export const BagLineItem = (props: BagLineItemProps) => {
  const {
    lineItem,
    isDisabled,
    startChangingLineItemQuantity,
    stopChangingLineItemQuantity,
    changeLineItemQuantity,
    onPress,
  } = props;

  // ─── Line Item Info ───────────────────────────────────────────────────────

  const { id: lineItemId = '', quantity = 0, product } = lineItem;

  const productId = product?.id ?? '';
  const productName = useLineItemName(product, lineItem);
  const lineItemPrice = useLineItemPrice(lineItem);
  const lineItemDescription = useLineItemDescription(lineItem);
  const lineItemAssetUrl = product?.asset?.url ?? '';

  const { addedIngredients, removedIngredients } =
    useLineItemIngredients(lineItem);

  // ─── Localization ────────────────────────────────────────────

  const { formatMessage } = useIntl();
  const accessibilityLabel = formatMessage(messages.accessibilityLabel);
  const addLabel = formatMessage(messages.addLabel);
  const removeLabel = formatMessage(messages.removeLabel);
  const currentQuantityA11y = formatMessage(messages.currentQuantityA11y);
  const increaseQuantityA11y = formatMessage(messages.increaseQuantityA11y);
  const decreaseQuantityA11y = formatMessage(messages.decreaseQuantityA11y);

  // ─── Callbacks ────────────────────────────────────────────────────────────

  const handleQuantityChange = useCallback(
    (updatedQuantity: number) => {
      changeLineItemQuantity({
        lineItemId,
        productId,
        quantity: updatedQuantity,
      });
    },
    [lineItemId, productId, changeLineItemQuantity],
  );

  const handleOnPress = useCallback(() => {
    onPress(lineItemId, product);
  }, [onPress, product, lineItemId]);

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <ProductLineItemCardV2
      productName={productName}
      price={lineItemPrice}
      cloudinaryImageUrl={lineItemAssetUrl}
      addedIngredients={addedIngredients}
      removedIngredients={removedIngredients}
      description={lineItemDescription}
      quantity={quantity}
      isDisabled={isDisabled}
      isCustom={product.isCustom}
      accessibilityLabel={accessibilityLabel}
      addLabel={addLabel}
      removeLabel={removeLabel}
      currentQuantityA11y={currentQuantityA11y}
      increaseQuantityA11y={increaseQuantityA11y}
      decreaseQuantityA11y={decreaseQuantityA11y}
      showLabels={!product.slug?.includes('create-your-own')}
      onQuantityChangeStart={startChangingLineItemQuantity}
      onQuantityChangeEnd={stopChangingLineItemQuantity}
      onQuantityChange={handleQuantityChange}
      onPress={handleOnPress}
    />
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Open product details',
    description: 'Bag | Product Line Item | Accessibility label',
  },
  addLabel: {
    defaultMessage: 'Add',
    description: 'Bag | Product Line Item | Add label',
  },
  removeLabel: {
    defaultMessage: 'Remove',
    description: 'Bag | Product Line Item | Remove label',
  },
  currentQuantityA11y: {
    defaultMessage: 'Current product quantity',
    description: 'Line Item Quantity Stepper | Current Quantity | a11y',
  },
  increaseQuantityA11y: {
    defaultMessage: 'Increase product quantity',
    description: 'Line Item Quantity Stepper | Increase Quantity | a11y',
  },
  decreaseQuantityA11y: {
    defaultMessage: 'Decrease product quantity',
    description: 'Line Item Quantity Stepper | Decrease Quantity | a11y',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagLineItemProps = Readonly<{
  lineItem: LineItem;
  isDisabled: boolean;
  startChangingLineItemQuantity: () => void;
  stopChangingLineItemQuantity: () => void;
  changeLineItemQuantity: (
    params: ChangeLineItemQuantityParams['params'],
  ) => void;
  onPress: (lineItemId: string, product: PartialProduct) => void;
}>;

type LineItem = Omit<PartialLineItem, 'ingredients'>;

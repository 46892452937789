import React, { Children, type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductCardV2ProductDetailsContainer = (
  props: PropsWithChildren,
) => {
  const { children } = props;

  // Prevent rendering if no children is provided
  if (Children.count(children) === 0) {
    return null;
  }

  return <View style={styles.container}>{children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing['4'],
    marginTop: theme.spacing['6'],
  },
});

import React from 'react';
import { LoadingPlaceholder, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardOrdersLoadingPlaceholder = () => {
  return (
    <LoadingPlaceholder
      rowHeight={100}
      rows={5}
      gridGap={theme.spacing['6']}
      palette="cream"
    />
  );
};

/**
 * This config is generated from the figma designs and documented here:
 * @see {@link https://sweetgreen.atlassian.net/wiki/spaces/DES/pages/2234515534/Scaling}
 *
 * Margin - horizontal margin around the "body" (i.e. container) that is otherwise expanding to fit the screen
 * Gutter - standard spacing between "columns" (e.g. card grids)
 * Body - the page's content container
 * Scaling - expands with the screen
 */

export const BREAKPOINTS = [
  {
    NAME: 'XS',
    LONG_NAME: 'X_SMALL',
    ALIAS: 'MOBILE',
    MIN: 0,
    MAX: 719,
    GUTTER: 16,
    MARGIN: 16,
    BODY: 'SCALING',
  },

  {
    NAME: 'SM',
    LONG_NAME: 'SMALL',
    ALIAS: 'TABLET',
    MIN: 720,
    MAX: 1023,
    GUTTER: 24,
    MARGIN: 40,
    BODY: 'SCALING',
  },

  {
    NAME: 'MD',
    LONG_NAME: 'MEDIUM',
    ALIAS: 'DESKTOP',
    MIN: 1024,
    MAX: 1439,
    GUTTER: 24,
    MARGIN: 40,
    BODY: 'SCALING',
  },

  {
    NAME: 'LG',
    LONG_NAME: 'LARGE',
    ALIAS: 'LARGE_DESKTOP',
    MIN: 1440,
    MAX: 0,
    GUTTER: 24,
    MARGIN: 40, // SCALING
    BODY: 1360,
  },
] as const;

export const [XS, SM, MD, LG] = BREAKPOINTS;

// ─── Types ───────────────────────────────────────────────────────────────────

export type Breakpoint = (typeof BREAKPOINTS)[number]['NAME'];

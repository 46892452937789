import { useCallback } from 'react';
import { CommonActions, useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';

import { APP_STACK_NAVIGATOR_ID } from '../../AppNavigation.constants';
import type { AppStackParamList } from '../../AppNavigation.props';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A custom hook to reset the initial state of navigation when changing the authentication status.
 *
 * To prevent additional network queries and state updates when the auth status changes,
 * it closes all main tabs screens that might be active in the background and replaces
 * the `MainTabs` screen with the initial (`Home`) screen.
 *
 * Should be initiated immediately following auth state change (sign-in, sign-up verification).
 */
export const useCloseActiveScreensOnAuthStatusChange = () => {
  const { getParent } =
    useNavigation<NativeStackNavigationProp<AppStackParamList>>();

  return useCallback(() => {
    // NOTE: To choose only the top level navigator, we specifically supply
    //       the app stack navigator ID.
    //
    //       We had to use "as never" type casting since for some reason React Navigation
    //       is unhappy with the type of the supplied ID.
    const appStackNavigator = getParent(APP_STACK_NAVIGATOR_ID as never);

    appStackNavigator?.dispatch((state) => {
      const currentRoutes = state.routes;
      const mainTabsRoute = getMainTabRoute(currentRoutes);

      // don't make any changes to the navigation state, if there isn't a main tab route active.
      if (mainTabsRoute === undefined) return state;

      // otherwise dismiss any background screens and reset the current `MainTab` navigation state.

      const filteredRoutes = [
        createHomeScreenEntry(),
        ...withoutMainTabsScreens(currentRoutes),
      ];

      return CommonActions.reset({
        ...state,
        routes: filteredRoutes,
        index: filteredRoutes.length - 1,
      });
    });
  }, [getParent]);
};

// ─── Utils ───────────────────────────────────────────────────────────────────

function getMainTabRoute(routes: readonly NavigationRoute[]) {
  return routes.find((route) => route.name === MAIN_TABS_ID);
}

/**
 * Replaces any active `MainTabs` screen with the initial (`Home`) screen
 */
function createHomeScreenEntry() {
  return {
    name: MAIN_TABS_ID,
    key: MAIN_TABS_ID,
    params: { screen: HOME_TAB_ID, params: { screen: HOME_SCREEN_ID } },
  };
}

/**
 * Closes all open screens in `MainTabs` in order to prevent any extra network queries and
 * state updates when the auth status changes.
 */
function withoutMainTabsScreens(routes: readonly NavigationRoute[]) {
  return routes.filter((route) => route.name !== MAIN_TABS_ID);
}

// ─── Constants ───────────────────────────────────────────────────────────────

const MAIN_TABS_ID = 'MainTabs';
const HOME_TAB_ID = 'HomeTab';
const HOME_SCREEN_ID = 'Home';

// ─── Types ───────────────────────────────────────────────────────────────────

type NavigationRoute = Readonly<{
  name: string;
}>;

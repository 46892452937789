import React, { useCallback, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { format, isToday } from 'date-fns';
import { uniq } from 'lodash';
import {
  ANIMATED_DIALOG_ITEM_PADDING_STYLE,
  BodyText,
  Button,
  PickerControl,
  theme,
  useUniqueNativeID,
} from '@sg/garnish';

import { ignoreTimezone } from '@order/utils';

import { type WantedTime } from '../../../machines/ordering-machine.types';
import { adjustWantedTime } from '../../../utils';

// ────────────────────────────────────────────────────────────────────────────

export const BagTimePicker = (props: BagTimePickerProps) => {
  const { wantedTime, wantedTimes, changeTime } = props;

  // ──────────────────────────────────────────────────────────────────────────

  const titleNativeId = useUniqueNativeID('bag-time-picker-label');
  const { formatMessage } = useIntl();

  // ─── Day Time Options ─────────────────────────────────────────────────────

  const { dayOptions, timeOptions } = useDayTimeOptions(wantedTimes);

  // ─── Picker State ─────────────────────────────────────────────────────────

  const dayForExternalTime = useDayForExternalTime(
    timeOptions,
    wantedTime.time,
  );

  const [selectedDay, selectDay] = useState<PickerValue>(dayForExternalTime);
  const [selectedTime, selectTime] = useState<PickerValue>(wantedTime.time);

  // ─── Day Specific Time Options ────────────────────────────────────────────

  const daySpecificTimeOptions = useDaySpecificTimeOptions(
    timeOptions,
    selectedDay,
  );

  // ─── Selection Handlers ───────────────────────────────────────────────────

  const handleSelectDay = useCallback(
    (chosenDay: PickerValue) => {
      const firstForDay = timeOptions.find(({ day }) => day === chosenDay);
      const firstTimeForChosenDay = firstForDay?.value ?? timeOptions[0].value;

      selectDay(chosenDay);
      selectTime(firstTimeForChosenDay);
    },
    [timeOptions],
  );

  // ─── CTA Callback ─────────────────────────────────────────────────────────

  const handleOnConfirm = useCallback(() => {
    const time = wantedTimes.find((entry) => entry.time === selectedTime);
    if (time) changeTime(time);
  }, [wantedTimes, selectedTime, changeTime]);

  // ─────────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <BodyText
        nativeID={titleNativeId}
        sizeMatch={['24']}
        style={styles.title}
      >
        <FormattedMessage {...messages.chooseTime} />
      </BodyText>

      <View style={styles.controlContainer}>
        <View style={styles.controlWrapper}>
          <PickerControl
            value={selectedDay}
            options={dayOptions}
            accessibilityLabel={formatMessage(messages.timeOptionDay)}
            selectionBorderRadius="left"
            accessibilityDescribedBy={titleNativeId}
            onChange={handleSelectDay}
          />
        </View>

        <View style={styles.controlWrapper}>
          <PickerControl
            value={selectedTime}
            options={daySpecificTimeOptions}
            accessibilityLabel={formatMessage(messages.timeOptionHour)}
            selectionBorderRadius="right"
            accessibilityDescribedBy={titleNativeId}
            onChange={selectTime}
          />
        </View>
      </View>

      <Button
        size="large-wide"
        style={styles.ctaWrapper}
        accessibilityLabel={formatMessage(messages.continueA11y)}
        onPress={handleOnConfirm}
      >
        <FormattedMessage {...messages.continueCta} />
      </Button>
    </View>
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * Given a list of available times, returns two lists.
 * One of {dayOptions}, in the format of [{ value: "ASAP - Today" }, { value: "Today" }, { value: "Monday" }, { value: "Tuesday" }].
 * One of {timeOptions}, in the format of [{ value: "ISO-DATE", day: "ASAP - Today" }, { value: "ISO-DATE", day: "Today" }].
 * To be used in the two picker component for day + time.
 */
const useDayTimeOptions = (wantedTimes: WantedTime[]) => {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    const timeOptions = wantedTimes.map((wantedTime, index) => {
      const adjustedTime = adjustWantedTime(wantedTime);
      const validTime = adjustedTime ? ignoreTimezone(adjustedTime) : null;

      if (!validTime) throw new Error('Invalid time');

      const isTimeToday = isToday(validTime);
      const isAsap = index === 0 && isTimeToday;

      const asap = isAsap ? formatMessage(messages.asapToday) : null;
      const today = isTimeToday ? formatMessage(messages.today) : null;
      const specialDayLabel = asap ?? today;

      return {
        value: wantedTime.time,
        label: format(validTime, 'h:mma'),
        day: specialDayLabel ?? format(validTime, 'eeee'),
      };
    });

    const dayOptions = uniq(timeOptions.flatMap(({ day }) => day)).map(
      (day) => ({ value: day, label: day }),
    );

    return { dayOptions, timeOptions };
  }, [wantedTimes, formatMessage]);
};

/**
 * Given a list of {timeOptions}, in the format of [{ value: "ISO-DATE", day: "ASAP - Today" }, { value: "ISO-DATE", day: "Today" }].
 * Returns the time options for a specific day.
 */
const useDaySpecificTimeOptions = (
  timeOptions: ReturnType<typeof useDayTimeOptions>['timeOptions'],
  selectedDay: PickerValue,
) => {
  return useMemo(() => {
    return timeOptions.filter((timeOption) => timeOption.day === selectedDay);
  }, [selectedDay, timeOptions]);
};

/**
 * Syncs the externally controlled time with the day options.
 */
const useDayForExternalTime = (
  timeOptions: ReturnType<typeof useDayTimeOptions>['timeOptions'],
  externalTime: string,
) => {
  return useMemo(() => {
    const optionForExternalTime = timeOptions.find(
      (option) => option.value === externalTime,
    );

    return optionForExternalTime?.day ?? timeOptions[0].day;
  }, [externalTime, timeOptions]);
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  chooseTime: {
    defaultMessage: 'Choose a time',
    description: 'Bag | Time Picker | Title',
  },
  timeOptionDay: {
    defaultMessage: 'Time option, Day',
    description: 'Bag | Time Picker | Option',
  },
  timeOptionHour: {
    defaultMessage: 'Time option, Hour',
    description: 'Bag | Time Picker | Option',
  },
  asapToday: {
    defaultMessage: 'ASAP - Today',
    description: 'Bag | Time Picker | ASAP Today',
  },
  today: {
    defaultMessage: 'Today',
    description: 'Bag | Time Picker | Today',
  },
  weekday: {
    defaultMessage: `{state, select,
        invalid {soon}
        asap {today at {time}}
        today {at {time}}
        future {on {time}}
        other {soon}
    }`,
    description: 'Bag | Time Picker | Weekday',
  },
  continueCta: {
    defaultMessage: 'Continue',
    description: 'Bag | Time Picker | Continue CTA',
  },
  continueA11y: {
    defaultMessage: 'Confirm selected time',
    description: 'Bag | Time Picker | Continue a11y',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    ...ANIMATED_DIALOG_ITEM_PADDING_STYLE,
    gap: theme.spacing['4'],
  },
  title: {
    paddingHorizontal: theme.spacing['2'],
  },
  controlContainer: {
    flexDirection: 'row',
  },
  controlWrapper: {
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 0,
  },
  ctaWrapper: {
    alignSelf: 'center',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type PickerValue = string | number;
type BagTimePickerProps = {
  wantedTime: WantedTime;
  wantedTimes: WantedTime[];
  changeTime: (time: WantedTime) => void;
};

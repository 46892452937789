import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import {
  type RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { type NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Header } from '@expo/html-elements';
import { Tabs, theme, useResponsive } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import {
  AccountScreenContainer,
  AccountScreenSection,
  AccountScreenTitle,
  SignedOutView,
} from '@order/components';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { type AccountTabStackParamList } from '../../navigation/AppNavigation.props';
import { useOrdersTabs } from './hooks';
import { ordersMessages as messages } from './OrdersScreen.messages';
import { GiftCardOrders, Orders } from './tabs';

// ─────────────────────────────────────────────────────────────────────────────

export const OrdersScreen = () => {
  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );
  const isLoggedIn = useIsLoggedIn();

  // ─────────────────────────────────────────────────────────────────────

  if (!isLoggedIn) return <SignedOutView />;

  if (isInAppGiftCardEnabled) {
    return <OrdersScreenWithGiftCards />;
  }

  return <OrdersScreenWithoutGiftCards />;
};

// ─── Variations ──────────────────────────────────────────────────────────────

export const OrdersScreenWithoutGiftCards = () => {
  const { formatMessage } = useIntl();

  return (
    <AccountScreenContainer screen="orders">
      <AccountScreenTitle title={formatMessage(messages.screenTitle)} />

      <AccountScreenSection>
        <Orders />
      </AccountScreenSection>
    </AccountScreenContainer>
  );
};

export const OrdersScreenWithGiftCards = () => {
  //
  // ─── Navigation ──────────────────────────────────────────────────────

  const { setParams } =
    useNavigation<
      NativeStackNavigationProp<AccountTabStackParamList, 'Orders'>
    >();
  const { params } = useRoute<RouteProp<AccountTabStackParamList, 'Orders'>>();
  const tabIdParam = params?.tabId;

  const { match } = useResponsive();
  const { formatMessage } = useIntl();

  // ─── Tabs ────────────────────────────────────────────────────────────

  const { activeTabItemId, setActiveTabItemId, ordersTab, giftCardsTab, tabs } =
    useOrdersTabs({ tabIdParam });

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onTabChange = useCallback(
    (tabId: string) => {
      const castedTabId = tabId as OrderTabId;

      setActiveTabItemId(tabId);
      setParams({ tabId: castedTabId });
    },
    [setActiveTabItemId, setParams],
  );

  // ─── Styles ──────────────────────────────────────────────────────────

  const innerContainerStyles = [
    styles.screenInnerContainer,
    match([styles.tabsContainerXS, styles.tabsContainerSM]),
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <AccountScreenContainer screen="orders">
      <Header>
        <AccountScreenTitle title={formatMessage(messages.screenTitle)} />

        <View style={innerContainerStyles}>
          <Tabs.Bar
            tabs={tabs}
            activeTabId={activeTabItemId}
            onChange={onTabChange}
          />
        </View>
      </Header>

      <View style={styles.screenInnerContainer}>
        <AccountScreenSection style={styles.accountScreenSection}>
          <Tabs.Panel
            isActive={activeTabItemId === ordersTab.id}
            tabId={ordersTab.id}
            panelId={ordersTab.panelId}
          >
            <Orders />
          </Tabs.Panel>

          <Tabs.Panel
            isActive={activeTabItemId === giftCardsTab.id}
            tabId={giftCardsTab.id}
            panelId={giftCardsTab.panelId}
          >
            <GiftCardOrders />
          </Tabs.Panel>
        </AccountScreenSection>
      </View>
    </AccountScreenContainer>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  screenInnerContainer: {
    width: '100%',
    maxWidth: 744,
  },
  accountScreenSection: {
    maxWidth: '100%',
  },
  tabsContainerXS: {
    marginBottom: theme.spacing['4'],
  },
  tabsContainerSM: {
    marginVertical: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderTabId = NonNullable<AccountTabStackParamList['Orders']>['tabId'];

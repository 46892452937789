import {
  MenuHeaderAlert,
  MenuHeaderAlertsContainer,
  MenuHeaderChangeLocationButton,
  MenuHeaderContainer,
  MenuHeaderContentContainer,
  MenuHeaderDetailsLocationText,
  MenuHeaderDetailsStoreHoursText,
  MenuHeaderDetailsText,
  MenuHeaderDetailsTextItem,
  MenuHeaderMoreInfoButton,
} from './components';

export const MenuHeader = {
  Container: MenuHeaderContainer,
  DetailsText: MenuHeaderDetailsText,
  DetailsTextItem: MenuHeaderDetailsTextItem,
  DetailsLocationText: MenuHeaderDetailsLocationText,
  DetailsStoreHoursText: MenuHeaderDetailsStoreHoursText,
  ChangeLocationButton: MenuHeaderChangeLocationButton,
  MoreInfoButton: MenuHeaderMoreInfoButton,
  ContentContainer: MenuHeaderContentContainer,
  AlertsContainer: MenuHeaderAlertsContainer,
  Alert: MenuHeaderAlert,
};

import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  HostedFrame,
  type HostedFrameMessageEvent,
  ModalDialogue,
  theme,
  useNoticeBannersStackContext,
} from '@sg/garnish';

import { useSendLogoutToAuthMachine } from '@order/AuthMachine';
import {
  getAzureAuthEmbeddedFrameLogoutUrl,
  removeTokens,
} from '@order/AzureAuth';
import { LoadingAnimation } from '@order/components';
import { useCustomer } from '@order/Customer';
import { useTelemetry } from '@order/Telemetry';

// ─────────────────────────────────────────────────────────────────────────────

export const AccountMenuSignOutItemEmbeddedModal = (
  props: AccountMenuSignOutItemEmbeddedProps,
) => {
  const { push: addBanner } = useNoticeBannersStackContext();
  const { track } = useTelemetry();
  const { signOut } = useCustomer();
  const sendLogoutAction = useSendLogoutToAuthMachine();

  const { isVisible, closeModal, resetAppState } = props;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const closeModalAndSignOut = useCallback(async () => {
    const hasSignedOut = await signOut();

    if (!hasSignedOut) return;

    sendLogoutAction();
    await removeTokens();
    resetAppState();
    closeModal();
  }, [closeModal, resetAppState, sendLogoutAction, signOut]);

  const onMessage = useCallback(
    (event: HostedFrameMessageEvent) => {
      const data = JSON.parse(event?.nativeEvent?.data ?? '{}');
      const eventType = data?.type;

      if (!eventType) return;

      switch (eventType) {
        case 'LOGOUT_SUCCESS': {
          void closeModalAndSignOut();
          track('azure-ad.logout.success');

          break;
        }

        case 'LOGOUT_ERROR': {
          closeModal();
          addBanner({
            palette: 'caution',
            text: 'Logging out failed. Please try again.',
          });
          track('azure-ad.logout.failure');

          break;
        }
      }
    },
    [closeModalAndSignOut, track, closeModal, addBanner],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ModalDialogue size="small" visible={isVisible}>
      <View style={styles.container}>
        <LoadingAnimation size="small" />
      </View>

      {isVisible ? (
        <HostedFrame
          frameId="azure-auth-sign-out-frame"
          frameHeight={0}
          source={AUTH_FRAME_SRC}
          onMessage={onMessage}
        />
      ) : null}
    </ModalDialogue>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing['6'],
    paddingBottom: theme.spacing['6'],
  },
});

// ─── Helpers ─────────────────────────────────────────────────────────────────

const AUTH_FRAME_SRC = {
  uri: getAzureAuthEmbeddedFrameLogoutUrl(),
};

// ─── Types ───────────────────────────────────────────────────────────────────

type AccountMenuSignOutItemEmbeddedProps = {
  isVisible: boolean;
  closeModal: () => void;
  resetAppState: () => void;
};

import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../hooks';
import { HorizontalScrollRail } from '../HorizontalScrollRail';
import { ProductAddonCard, ProductAddonCardLoading } from '../ProductAddonCard';

// ────────────────────────────────────────────────────────────────────────

export const AddonsRail = (props: AddonsRailProps) => {
  const {
    addons,
    isLoading,
    headerText,
    headerVariation,
    outerOffset,
    withoutHeaderBorder,
  } = props;

  if (isLoading) {
    return (
      <AddonsRailLoading
        headerText={headerText}
        headerVariation={headerVariation}
        outerOffset={outerOffset}
        withoutHeaderBorder={withoutHeaderBorder}
      />
    );
  }

  if (!addons?.length) return null;

  return <AddonsRailFulfilled {...props} />;
};

// ─── TEMPLATES ──────────────────────────────────────────────────────────────────

const AddonsRailBase = (props: AddonsRailBaseProps) => {
  const {
    headerText,
    footerText,
    children,
    outerOffset = -theme.spacing['6'],
    ...restProps
  } = props;

  const { minWidth } = useResponsive();

  return (
    <HorizontalScrollRail
      heading={headerText}
      footerText={footerText}
      showNavControls={minWidth.isSM}
      gap={theme.spacing['6']}
      outerOffset={outerOffset}
      itemVisiblePercentThreshold={80}
      {...restProps}
    >
      {children as readonly React.ReactNode[]}
    </HorizontalScrollRail>
  );
};

const AddonsRailFulfilled = (props: AddonsRailProps) => {
  const {
    addons = [],
    headerText,
    footerText,
    isDisabled,
    onPress,
    ...addonsRailBaseProps
  } = props;

  const { minWidth } = useResponsive();

  return (
    <AddonsRailBase
      headerText={headerText}
      footerText={footerText}
      showNavControls={minWidth.isSM}
      {...addonsRailBaseProps}
    >
      {addons.map((addon, index) => (
        <AddonsRailItem key={addon.id}>
          <ProductAddonCard
            id={addon.id}
            imageUrl={addon.imageUrl}
            name={addon.name}
            price={addon.price}
            testID={addon.testID}
            shapeIndex={index}
            isDisabled={isDisabled}
            onPress={onPress}
          />
        </AddonsRailItem>
      ))}
    </AddonsRailBase>
  );
};

const AddonsRailLoading = (props: AddonsRailBaseProps) => {
  const loadingPlaceholders = Array.from({ length: 3 });

  return (
    <AddonsRailBase scrollEnabled={false} showNavControls={false} {...props}>
      {loadingPlaceholders.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <AddonsRailItem key={index}>
          <ProductAddonCardLoading />
        </AddonsRailItem>
      ))}
    </AddonsRailBase>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const AddonsRailItem = (props: React.PropsWithChildren) => (
  <View style={styles.railItem}>{props.children}</View>
);

// ─── STYLES ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  railItem: {
    paddingVertical: theme.spacing['1'],
  },
});

// ────── TYPES ──────────────────────────────────────────────────────────────────

type AddonsRailProps = Readonly<{
  addons: readonly Addon[];
  onPress: ((addonId: string) => void) | ((addonId: string) => Promise<void>);
  isLoading?: boolean;
  isDisabled?: boolean;
}> &
  Pick<AddonsRailBaseProps, 'headerText' | 'footerText'> &
  Pick<
    ComponentProps<typeof HorizontalScrollRail>,
    | 'headerVariation'
    | 'outerOffset'
    | 'withoutHeaderBorder'
    | 'trackViewableState'
  >;

type AddonsRailBaseProps = Readonly<{
  headerText: string;
  footerText?: string;
}> &
  Partial<ComponentProps<typeof HorizontalScrollRail>>;

type Addon = Readonly<{
  id: string;
  name: string;
  price: string;
  imageUrl: string;
  testID?: string;
}>;

import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import {
  BodyText,
  Chip,
  Container,
  SectionHeader,
  Stepper,
  Switch,
  theme,
  VStack,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { useProductDetailsScreenContext } from '../../ProductDetailsScreen.provider';
import {
  ProductDressingAndDetailsDressingStepper,
  ProductDressingAndDetailsRow,
} from './subcomponents';

export const ProductDressingAndDetails = () => {
  const { isCustomizationActive } = useProductDetailsScreenContext();

  if (isCustomizationActive) return null;

  return (
    <Container style={styles.wrapper}>
      <ProductDressingAndDetailsHeader />

      <VStack hasDivider dividerColor={theme.colors.LIGHT} gap={0}>
        <ProductDressingAndDetailsDressingMode />
        <ProductDressingAndDetailsDressingsOptions />
        <ProductDressingAndDetailsBreadOption />
        <ProductDressingAndDetailsQuantity />
      </VStack>
    </Container>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

export const ProductDressingAndDetailsHeader = () => {
  const { isModifiableProduct } = useProductDetailsScreenContext();
  const { t } = useLocalizationContext();

  if (!isModifiableProduct) return null;

  return <SectionHeader heading={t('pdp.dressing-and-details.title')} />;
};

export const ProductDressingAndDetailsDressingMode = () => {
  const { t } = useLocalizationContext();
  const {
    dressingMode,
    toggleDressingMode,
    maxDressingsPortionsNumber,
    modifications,
    product,
    dressingsDetails,
    addressId,
  } = useProductDetailsScreenContext();

  // ────────────────────────────────────────────────────────────────────

  // delivery and outpost orders cannot have mixed-in dressing
  const isDelivery = Boolean(addressId);
  const isOutpost = Boolean(product?.restaurant?.isOutpost);
  const isMixable = modifications?.active?.some((active) => active.mixable);
  const hasNoSelectedDressing = dressingsDetails.length === 0;
  const hasGreenBase = modifications?.active?.some(
    (active) => !active.isGrain && active.kind === 'bases',
  );

  const isDressingModeChangeNotAllowed =
    hasNoSelectedDressing ||
    isDelivery ||
    isOutpost ||
    !isMixable ||
    !hasGreenBase;

  if (isDressingModeChangeNotAllowed) return null;

  // ────────────────────────────────────────────────────────────────────

  const isDressingOnTheSide = dressingMode === 'onTheSide';

  const maxPortionsText = t(
    'pdp.dressing-and-details.dressing.max-portions-text',
    { max: maxDressingsPortionsNumber },
  );
  const optimalTimeMessage = t(
    'pdp.dressing-and-details.dressing.optimal-time-message',
  );
  const helperText = isDressingOnTheSide
    ? `${maxPortionsText} ${optimalTimeMessage}`
    : optimalTimeMessage;

  return (
    <ProductDressingAndDetailsRow
      title={t('pdp.dressing-and-details.dressing.title')}
      helperText={helperText}
    >
      <Chip.Menu value={dressingMode} onChange={toggleDressingMode}>
        <Chip testID="dressing-mode.on-the-side" value="onTheSide">
          {t('pdp.dressing-and-details.dressing.on-the-side')}
        </Chip>

        <Chip testID="dressing-mode.mixed-in" value="mixedIn">
          {t('pdp.dressing-and-details.dressing.mixed-in')}
        </Chip>
      </Chip.Menu>
    </ProductDressingAndDetailsRow>
  );
};

export const ProductDressingAndDetailsDressingsOptions = () => {
  const { dressingsDetails } = useProductDetailsScreenContext();

  if (dressingsDetails.length === 0) return null;

  return (
    <VStack hasDivider dividerColor={theme.colors.LIGHT} gap={0}>
      {dressingsDetails.map((dressingDetails) => (
        <ProductDressingAndDetailsRow
          key={dressingDetails.id}
          title={dressingDetails.name}
        >
          <ProductDressingAndDetailsDressingStepper
            dressingDetails={dressingDetails}
          />
        </ProductDressingAndDetailsRow>
      ))}
    </VStack>
  );
};

export const ProductDressingAndDetailsBreadOption = () => {
  const { t } = useLocalizationContext();
  const {
    isBreadActive,
    isBreadAvailable,
    breadIngredientModification,
    toggleBread,
  } = useProductDetailsScreenContext();

  const { calories = 0, outOfStock } = breadIngredientModification ?? {};

  const addBreadLabel = t('pdp.dressing-and-details.bread.title');
  const caloriesLabel = t('general.calories-short');
  const title = `${addBreadLabel} (${calories} ${caloriesLabel})`;

  // ────────────────────────────────────────────────────────────────────

  if (!isBreadAvailable) return null;

  return (
    <ProductDressingAndDetailsRow title={title}>
      {outOfStock ? (
        <BodyText>{t('general.unavailable')}</BodyText>
      ) : (
        <Switch
          testID="product-details.bread-toggle"
          value={isBreadActive}
          onChange={toggleBread}
        />
      )}
    </ProductDressingAndDetailsRow>
  );
};

export const ProductDressingAndDetailsQuantity = () => {
  const { t } = useLocalizationContext();
  const { quantity, setQuantity } = useProductDetailsScreenContext();
  const { track } = useTelemetry();

  // ────────────────────────────────────────────────────────────────────

  const changeQuantity = useCallback(
    (qty: number) => {
      track('pdp.adjust_quantity', { quantity: qty });

      setQuantity(qty);
    },
    [track, setQuantity],
  );

  const incrementQuantity = useCallback(() => {
    changeQuantity(Number(quantity) + 1);
  }, [quantity, changeQuantity]);

  const decrementQuantity = useCallback(() => {
    changeQuantity(Number(quantity) - 1);
  }, [quantity, changeQuantity]);

  // ────────────────────────────────────────────────────────────────────

  return (
    <ProductDressingAndDetailsRow
      testID="pdp-quantity-row"
      title={t('pdp.dressing-and-details.quantity.title')}
    >
      <Stepper
        min={1}
        max={99}
        value={quantity}
        onIncrement={incrementQuantity}
        onDecrement={decrementQuantity}
      />
    </ProductDressingAndDetailsRow>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: theme.spacing['6'],
  },
});

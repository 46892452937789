import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import {
  BodyText,
  Button,
  Container,
  DisplayText,
  IllusDressing_1,
  Image,
  theme,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const JoinOrSignInErrorView = (props: JoinOrSignInErrorViewProps) => {
  const { onTryAgainButtonPress } = props;

  return (
    <Container
      style={styles.errorContainer}
      wrapperStyle={styles.errorContainerWrapper}
    >
      <Image
        source={IllusDressing_1}
        style={styles.errorIllustration}
        contentFit="contain"
      />

      <DisplayText sizeMatch={['32']} style={styles.errorHeading}>
        <FormattedMessage
          defaultMessage="Something went wrong"
          description="Join or Sign In | Failed authentication title"
        />
      </DisplayText>

      <BodyText sizeMatch={['18']} style={styles.errorText}>
        <FormattedMessage
          defaultMessage="There was an error while getting your account data, please try later."
          description="Join or Sign In | Failed authentication text"
        />
      </BodyText>

      <Button
        palette="primary"
        size="large"
        style={styles.errorBtn}
        onPress={onTryAgainButtonPress}
      >
        <FormattedMessage
          defaultMessage="Try again"
          description="Join or Sign In | Try again"
        />
      </Button>
    </Container>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  errorContainerWrapper: {
    flex: 1,
  },
  errorContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: theme.spacing['3'],
  },
  errorIllustration: {
    width: 200,
    height: 200,
  },
  errorHeading: {
    textAlign: 'center',
  },
  errorText: {
    textAlign: 'center',
    marginBottom: theme.spacing['2'],
  },
  errorBtn: {
    minWidth: 160,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type JoinOrSignInErrorViewProps = {
  onTryAgainButtonPress: () => void;
};

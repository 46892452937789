import { config } from '../../config';

/**
 * Removes stored tokens from the local storage.
 *
 * NOTE: It removes all tokens from both flows, preventing semi-signed-in states
 *       and requiring the user to sign in again.
 */
export function removeTokens() {
  localStorage.removeItem(config.hybridFlowRefreshTokenStorageKey);
  localStorage.removeItem(config.hybridFlowAccessTokenStorageKey);
  localStorage.removeItem(config.embeddedFlowRefreshTokenStorageKey);
}

import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useContentfulContentTypeEntry } from '@order/Contentful';

/**
 * Returns the contentful data for the bag.
 */
export const useBagContent = () => {
  const content = useContentfulContentTypeEntry<BagContent>({
    contentType: 'bag',
  });

  const contentfulData = content.data?.fields;

  const { formatMessage } = useIntl();

  const fallbackData = {
    viewMenuCta: formatMessage(fallbacks.viewMenuCta),
    viewMenuA11y: formatMessage(fallbacks.viewMenuA11y),
    continueToCheckoutCta: formatMessage(fallbacks.continueToCheckoutCta),
  };

  const viewMenuCta = contentfulData?.viewMenuCta ?? fallbackData.viewMenuCta;

  const viewMenuA11y =
    contentfulData?.viewMenuA11y ?? fallbackData.viewMenuA11y;

  const continueToCheckoutCta =
    contentfulData?.continueToCheckoutCta ?? fallbackData.continueToCheckoutCta;

  return useMemo(
    () => ({
      viewMenuCta,
      viewMenuA11y,
      continueToCheckoutCta,
    }),
    [viewMenuCta, viewMenuA11y, continueToCheckoutCta],
  );
};

// ─── Fallbacks ──────────────────────────────────────────────────────────────

const fallbacks = defineMessages({
  viewMenuCta: {
    defaultMessage: 'Menu',
    description: 'Bag | CTAs | Menu',
  },
  viewMenuA11y: {
    defaultMessage: 'View menu',
    description: 'Bag | CTAs | View menu',
  },
  continueToCheckoutCta: {
    defaultMessage: 'Continue to checkout',
    description: 'Bag | CTAs | Continue to checkout',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagContent = {
  viewMenuCta: string;
  viewMenuA11y: string;
  continueToCheckoutCta: string;
};

import { clientInstance } from '../../client';
import { config } from '../../config';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Initiates a new authentication session using the redirect flow.
 * The results will be handled after redirect.
 *
 * NOTE: `options` are native only
 */
export async function authenticate(_options?: AuthenticateOptions) {
  return clientInstance?.loginRedirect(config.scopes.apiLoginRequest);
}

// ─── Types ───────────────────────────────────────────────────────────────────

type AuthenticateOptions = {
  /**
   * @see https://developer.apple.com/documentation/authenticationservices/aswebauthenticationsession/3237231-prefersephemeralwebbrowsersessio
   * @see https://docs.expo.dev/versions/latest/sdk/webbrowser/#authsessionopenoptions
   */
  shouldPreferEphemeralSession: boolean;
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';
import { useResponsive } from '@sg/garnish';

import {
  type GiftCardCheckoutFormFieldRules,
  type GiftCardCheckoutFormReturn,
} from '../../../../form';
import { GiftCardCheckoutFormGroup } from '../../components';
import {
  GiftCardCheckoutDeliveryDateOptions,
  GiftCardCheckoutDeliveryDatePicker,
} from './components';
import { giftCardCheckoutDeliveryDateMessages as messages } from './GiftCardCheckoutDeliveryDate.messages';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders delivery date selectors with the corresponding handlers.
 */
export const GiftCardCheckoutDeliveryDate = (
  props: GiftCardCheckoutDeliveryDateProps,
) => {
  const { form, rules } = props;

  const { match } = useResponsive();

  const { fromDate, toDate } = rules.deliveryDate;

  const deliveryDateOption = form.watch('deliveryDateOption');
  const selectedDeliveryDate = form.watch('deliveryDateTime');

  // ─── Flags ───────────────────────────────────────────────────────────

  const hasSelectedScheduleOption = deliveryDateOption === 'SCHEDULE';
  const hasSelectedDeliveryDate = selectedDeliveryDate !== undefined;

  const shouldRenderDatePicker =
    hasSelectedScheduleOption && hasSelectedDeliveryDate;

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    shouldRenderDatePicker &&
      match([styles.containerCompactXS, styles.containerCompactSM]),
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <GiftCardCheckoutFormGroup.Container style={containerStyles}>
      <GiftCardCheckoutFormGroup.Title>
        <FormattedMessage {...messages.sectionTitle} />
      </GiftCardCheckoutFormGroup.Title>

      <GiftCardCheckoutDeliveryDateOptions form={form} fromDate={fromDate} />

      {shouldRenderDatePicker ? (
        <GiftCardCheckoutDeliveryDatePicker
          form={form}
          fromDate={fromDate}
          toDate={toDate}
        />
      ) : null}
    </GiftCardCheckoutFormGroup.Container>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
  },
  containerCompactXS: {
    paddingBottom: theme.spacing['2'],
  },
  containerCompactSM: {
    paddingBottom: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutDeliveryDateProps = {
  sectionTitle?: string;
  form: GiftCardCheckoutFormReturn;
  rules: GiftCardCheckoutFormFieldRules;
};

import React from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';
import { useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import {
  AccountScreenBorderedSection,
  AccountScreenSection,
  AccountScreenSectionTitle,
} from '../../../Account';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardAccountSectionContainer = (
  props: Pick<ViewProps, 'children'>,
) => {
  const { children } = props;

  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  const accountScreenSectionStyles = match([
    styles.sectionContainerXS,
    undefined,
  ]);

  return (
    <View>
      <AccountScreenSectionTitle title={t('account.gift-cards.title')} />
      <AccountScreenSection style={accountScreenSectionStyles}>
        <AccountScreenBorderedSection>{children}</AccountScreenBorderedSection>
      </AccountScreenSection>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  sectionContainerXS: {
    flexGrow: 0,
  },
});

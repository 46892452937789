import type { ComponentProps } from 'react';
import type { LoyaltyChallengeCardsRail } from '@sg/garnish';

import type { ChallengesQuery } from '@order/graphql';

type GraphQLChallenge = ChallengesQuery['challenges'][0];

const isNew = (challenge: GraphQLChallenge) => {
  return challenge.status === 'NOT_STARTED';
};

const isOngoing = (challenge: GraphQLChallenge) => {
  if (!challenge.status) return false;

  return ['STARTED', 'IN_PROGRESS'].includes(challenge.status);
};

const getChallengeStatus = (challenge: GraphQLChallenge) => {
  switch (true) {
    case isNew(challenge): {
      return 'New';
    }

    case isOngoing(challenge): {
      return 'Ongoing';
    }

    default: {
      return 'Completed';
    }
  }
};

type SerializedResult = Omit<
  ComponentProps<typeof LoyaltyChallengeCardsRail>['challenges'][0],
  'applyText' | 'terms'
>;

export function serializeChallenge(loadingChallengeId: string | null) {
  return (challenge: GraphQLChallenge): SerializedResult => ({
    ...challenge,
    title: challenge.title,
    descriptiveTitle: challenge.descriptiveTitle,
    status: getChallengeStatus(challenge),
    completedSteps: challenge.progress,
    totalSteps: challenge.goal,
    isLoading: loadingChallengeId === challenge.id,
  });
}

import { useMemo } from 'react';

import { type GetProductQuery } from '../GraphQL/ProductDetailsScreen.getProduct.generated';
import type { FilteredIngredientModifications } from '../types';

// ────────────────────────────────────────────────────────────────────────────────

export const useFilteredIngredientsModifications = (
  ingredientModifications?: GetProductQuery['product']['ingredientModifications'],
) => {
  // ────────────────────────────────────────────────────────────────────

  const filteredIngredientsModifications = useMemo(() => {
    if (!ingredientModifications) return {};

    return Object.entries(ingredientModifications).reduce<
      Partial<FilteredIngredientModifications>
    >((filteredGroups, currentGroup) => {
      const [kind, ingredientsModificationsList] = currentGroup;

      // handle "__typename" field
      if (typeof ingredientsModificationsList === 'string') {
        return filteredGroups;
      }

      // exclude "bread" ingredient kind
      if (kind === 'bread') return filteredGroups;

      const { modifications } = ingredientsModificationsList;

      if (modifications.length === 0) return filteredGroups;

      return { ...filteredGroups, [kind]: ingredientsModificationsList };
    }, {});
  }, [ingredientModifications]);

  // ────────────────────────────────────────────────────────────────────

  const filteredIngredientsModificationsKinds = useMemo(
    () => Object.keys(filteredIngredientsModifications),
    [filteredIngredientsModifications],
  );

  // ────────────────────────────────────────────────────────────────────

  return {
    ingredientsModifications: filteredIngredientsModifications,
    ingredientsModificationsKinds: filteredIngredientsModificationsKinds,
  };
};

/* istanbul ignore file */

import * as Updates from 'expo-updates';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns a "promisified" version of the Expo initial update check event listener
 * with a timeout guard.
 *
 * NOTE: The initial update check event may take way longer than planned for some reasons,
 *       so we use an extra timeout check to avoid those long checks.
 */
export async function checkForInitialUpdate(context: ContextWithDelayOption) {
  const timeoutRef: InitialUpdateEventTimeoutRef = { current: undefined };
  const { initialUpdateEventTimeout } = context.options;

  return Promise.race([
    fetchInitialUpdate(timeoutRef),
    initialUpdateFetchTimeout(initialUpdateEventTimeout, timeoutRef),
  ]);
}

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A "promisified" version of the Expo initial update check event listener.
 *
 * @see {@link https://docs.expo.dev/versions/latest/sdk/updates/#updatesaddlistenerlistener}
 */
async function fetchInitialUpdate(timeoutRef: InitialUpdateEventTimeoutRef) {
  return new Promise<Updates.UpdateEvent>((resolve, reject) => {
    try {
      Updates.addListener((updateEvent) => {
        if (timeoutRef.current) {
          clearInterval(timeoutRef.current);
        }

        resolve(updateEvent);
      });
    } catch (error) {
      reject(error);
    }
  });
}

/**
 * A simple timer that can be used to prevent long update checks.
 */
async function initialUpdateFetchTimeout(
  timeout: number,
  timeoutRef: InitialUpdateEventTimeoutRef,
) {
  return new Promise<undefined>((_, reject) => {
    // eslint-disable-next-line functional/immutable-data
    timeoutRef.current = setTimeout(reject, timeout);
  });
}

// ─── Types ───────────────────────────────────────────────────────────────────

type ContextWithDelayOption = {
  options: {
    initialUpdateEventTimeout: number;
  };
};

type InitialUpdateEventTimeoutRef = {
  current: ReturnType<typeof setTimeout> | undefined;
};

export const creditCardForm = {
  'credit-card-form.title': 'Credit card form',
  'credit-card-form.add': 'Add card',
  'credit-card-form.edit': 'Edit card',
  'credit-card-form.replace': 'Replace card',
  'credit-card-form.card-number': 'Card number',
  'credit-card-form.expiration-date': 'Exp. date',
  'credit-card-form.cvv': 'CVV',
  'credit-card-form.zip-code': 'Zip Code',
  'credit-card-form.nickname': 'Card nickname (optional)',
  'credit-card-form.default': 'Set as default payment method',
  'credit-card-form.close': 'Close credit card form',
  'credit-card-form.remove': 'Remove card',
  'credit-card-form.save': 'Save card',
  'credit-card-form.save.success': 'Payment method added successfully.',
  'credit-card-form.update.success': 'Payment method updated successfully.',
  'credit-card-form.remove.success': 'Payment method successfully removed.',
  'credit-card-form.add-apple-pay': 'Add Apple Pay',

  // ─── Placeholders ────────────────────────────────────────────────────

  'credit-card-form.card-number.placeholder': '4111 1111 1111 1111',
  'credit-card-form.expiration-date.placeholder': 'MM / YYYY',
  'credit-card-form.cvv.placeholder': '123',
  'credit-card-form.zip-code.placeholder': '12345',

  // ─── Credit Card Form Inline Errors ──────────────────────────────────

  'credit-card-form.error.inline.number': 'Card number is invalid',
  'credit-card-form.error.inline.expiration': 'Invalid MM/YYYY',
  'credit-card-form.error.inline.cvv': 'CVV code is invalid',
  'credit-card-form.error.inline.zipCode': 'Zip code is invalid',
  'credit-card-form.error.banner.zipCode': "Your card's zip code is invalid.",

  // ─── Credit Card Form Submit Errors ──────────────────────────────────

  'credit-card-form.error.submit.loading':
    'Please wait for the form to load or reload the app.',
  'credit-card-form.error.submit.number': 'Please provide a valid card number.',
  'credit-card-form.error.submit.expiration':
    'Please provide a valid expiration date.',
  'credit-card-form.error.submit.cvv': 'Please provide a valid cvv code.',
  'credit-card-form.error.submit.zipCode': 'Please provide a valid zip code.',

  // ─── Credit Card Form Network Errors ─────────────────────────────────

  'credit-card-form.error.deprecated':
    'Credit card not accepted. Update your application version to add new cards.',
  'credit-card-form.error.billing.invalid':
    'We were unable to add your credit card. Please check your card information and try again.',
  'credit-card-form.error.zipCode.invalid':
    'Oops, that postal code is not valid.',
  'credit-card-form.error.too-many-requests':
    'Too many requests made. Please wait a bit and try again.',
  'credit-card-form.error.save':
    'We were unable to save your card information. Please try again.',
  'credit-card-form.error.delete':
    "Oops, we couldn't remove your card, please try again.",
};

import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { Header } from '@expo/html-elements';
import { theme } from '@sg/garnish';

/**
 * Channel, location and time wrapper.
 */
export const BagHeaderContainer = (props: PropsWithChildren) => {
  const { children } = props;

  return <Header style={styles.container}>{children}</Header>;
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'flex-start',
    gap: theme.spacing['2'],
  },
});

import React, { type ComponentProps, useCallback, useMemo } from 'react';
import {
  type IconName,
  ListActionPressable,
  useToggleState,
} from '@sg/garnish';

import {
  CreditCardAddModal,
  SelectModal,
  useApplePayPaymentMethod,
} from '@order/components';
import { APPLE_PAY, getCreditCardIcon, PAYMENT_ADD } from '@order/constants';
import { type CardType, type PaymentMethodCard } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

/**
 * Payment method selector.
 * Rendered as a Modal on all viewports.
 */
export const PaymentMethodSelectorModal = (
  props: PaymentMethodSelectorProps,
) => {
  const {
    selectedPaymentMethodId,
    selectedPaymentMethodLabel,
    selectedPaymentMethodIcon,
    paymentMethodOptions,
    paymentMethodSelectionNotice,
    paymentMethodSelectionNoticePalette,
    isApplePayReady,
    hasBoldLabel,
    onPaymentMethodSelected,
  } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();
  const options = useSelectModalOptions(paymentMethodOptions);

  // ─── Apple Pay ───────────────────────────────────────────────────────

  const { updateDefaultPaymentMethodToApplePay } = useApplePayPaymentMethod();

  // ─── Callbacks ───────────────────────────────────────────────────────

  const {
    value: isShowingAddPaymentMethodModal,
    toggleOn: showAddPaymentMethodModal,
    toggleOff: hideAddPaymentMethodModal,
  } = useToggleState();

  const {
    value: isShowingSwitchPaymentMethodModal,
    toggleOn: showSwitchPaymentMethodModal,
    toggleOff: hideSwitchPaymentMethodModal,
  } = useToggleState();

  const handleSwitchPaymentMethod = useCallback(
    (id: string) => {
      const hasChosenAddPaymentMethod = id === PAYMENT_ADD;

      if (hasChosenAddPaymentMethod) {
        showAddPaymentMethodModal();

        return;
      }

      const paymentMethod = paymentMethodOptions.find(
        (option) => option.value === id,
      );

      if (!paymentMethod) return;

      onPaymentMethodSelected(paymentMethod.value);

      if (id === APPLE_PAY) void updateDefaultPaymentMethodToApplePay();
    },
    [
      paymentMethodOptions,
      onPaymentMethodSelected,
      updateDefaultPaymentMethodToApplePay,
      showAddPaymentMethodModal,
    ],
  );

  const handlePaymentMethodAdded = useCallback(
    (paymentMethod: PaymentMethodCard) => {
      onPaymentMethodSelected(paymentMethod.id);
      hideAddPaymentMethodModal();
    },
    [onPaymentMethodSelected, hideAddPaymentMethodModal],
  );

  const handleApplePayAdded = useCallback(() => {
    onPaymentMethodSelected(APPLE_PAY);
    hideAddPaymentMethodModal();
  }, [onPaymentMethodSelected, hideAddPaymentMethodModal]);

  const handleSelectorPressed = useCallback(() => {
    const shouldOpenCreditCardAddModal = paymentMethodOptions.length === 1;

    if (shouldOpenCreditCardAddModal) {
      showAddPaymentMethodModal();

      return;
    }

    showSwitchPaymentMethodModal();
  }, [
    paymentMethodOptions.length,
    showSwitchPaymentMethodModal,
    showAddPaymentMethodModal,
  ]);

  return (
    <>
      <ListActionPressable
        withCaret
        boldTitle={hasBoldLabel}
        accessibilityLabel={t('checkout.select-payment-method')}
        title={selectedPaymentMethodLabel}
        iconName={selectedPaymentMethodIcon}
        notice={paymentMethodSelectionNotice}
        noticePalette={paymentMethodSelectionNoticePalette}
        onPress={handleSelectorPressed}
      />

      <SelectModal
        title={t('checkout.select-payment-method')}
        selectedOption={selectedPaymentMethodId}
        options={options}
        visible={isShowingSwitchPaymentMethodModal}
        onOptionSelected={handleSwitchPaymentMethod}
        onRequestClose={hideSwitchPaymentMethodModal}
      />

      <CreditCardAddModal
        visible={isShowingAddPaymentMethodModal}
        isApplePayReady={isApplePayReady}
        onRequestClose={hideAddPaymentMethodModal}
        onSuccess={handlePaymentMethodAdded}
        onApplePay={handleApplePayAdded}
      />
    </>
  );
};

// ─── Helpers ───────────────────────────────────────────────────────────

function useSelectModalOptions(
  paymentMethodOptions: Readonly<CreditCardOption[]>,
): Readonly<ComponentProps<typeof SelectModal>['options']> {
  const { t } = useLocalizationContext();
  const addLabel = t('checkout.add-new-payment-method');
  const shouldUseAddIcon = true;

  return useMemo(
    () =>
      [...paymentMethodOptions].reverse().map((option) => ({
        value: option.value,
        label: option.value === PAYMENT_ADD ? addLabel : option.label,
        icon: getCreditCardIcon(option.cardType, shouldUseAddIcon),
      })),
    [paymentMethodOptions, shouldUseAddIcon, addLabel],
  );
}

// ─── Types ─────────────────────────────────────────────────────────────

type PaymentMethodSelectorProps = Readonly<{
  selectedPaymentMethodId: string | undefined;
  selectedPaymentMethodLabel: string;
  paymentMethodOptions: Readonly<CreditCardOption[]>;
  selectedPaymentMethodIcon: IconName;
  paymentMethodSelectionNotice?: string;
  paymentMethodSelectionNoticePalette?: ComponentProps<
    typeof ListActionPressable
  >['noticePalette'];
  isApplePayReady?: boolean;
  hasBoldLabel?: boolean;
  onPaymentMethodSelected: (paymentMethodId: string) => void;
}>;

type CreditCardOption = Readonly<{
  value: string;
  label: string;
  cardType: CardType | typeof PAYMENT_ADD | typeof APPLE_PAY;
  paymentMethodType:
    | 'BillingAccount'
    | 'PaymentMethodCard'
    | 'PaymentMethodBillingAccount';
  isDefault: boolean;
}>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type HasUnseenChallengesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type HasUnseenChallengesQuery = {
  readonly __typename?: 'Query';
  readonly hasUnseenChallenges: {
    readonly __typename?: 'HasUnseenChallenges';
    readonly hasUnseenChallenges: boolean;
  };
};

export const HasUnseenChallengesDocument = gql`
  query HasUnseenChallenges {
    hasUnseenChallenges {
      hasUnseenChallenges
    }
  }
`;

export function useHasUnseenChallengesQuery(
  options?: Omit<Urql.UseQueryArgs<HasUnseenChallengesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    HasUnseenChallengesQuery,
    HasUnseenChallengesQueryVariables
  >({ query: HasUnseenChallengesDocument, ...options });
}

import { useEffect, useMemo, useRef } from 'react';
import { Animated, Platform } from 'react-native';

// ─────────────────────────────────────────────────────────────────────────────

export const useDotAnimations = (): DotInterpolationsArray => {
  //
  // ─── Refs ────────────────────────────────────────────────────────────

  const animationValuesRef = useRef([
    new Animated.Value(0),
    new Animated.Value(0),
    new Animated.Value(0),
  ]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    const { current: animationValues } = animationValuesRef;

    const compositeAnimation = Animated.loop(
      Animated.stagger(
        150,
        animationValues.map((value) => Animated.timing(value, TIMING_CONFIG)),
      ),
    );

    compositeAnimation.start();

    return () => {
      compositeAnimation.reset();
    };
  }, []);

  // ─────────────────────────────────────────────────────────────────────

  return useMemo(
    () =>
      animationValuesRef.current.map((value) =>
        value.interpolate(INTERPOLATION_CONFIG),
      ),
    [],
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const TIMING_CONFIG = {
  toValue: 1,
  duration: 750,
  useNativeDriver: Platform.OS !== 'web',
};

const INTERPOLATION_CONFIG = {
  inputRange: [0, 0.3, 0.6, 1],
  outputRange: [0, -7, 0, 0],
};

// ─── Types ───────────────────────────────────────────────────────────────────

type DotInterpolationsArray = Animated.AnimatedInterpolation<number>[];

import { useMemo } from 'react';

import { appsFlyerLogger as logger } from './AppsFlyer.logger';
import type { AppsFlyer } from './AppsFlyer.types';

export const useAppsFlyer = (): AppsFlyer => {
  return useMemo(
    () => ({
      async initialize() {
        logger.info('skip initialize()');
      },
      setCustomerUserId(customerId) {
        logger.info(`skip setCustomerId(${customerId})`);
      },
      logEvent(name) {
        logger.info(`skip logEvent(${name}, ...)`);
      },
    }),
    [],
  );
};

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { IconLink } from '@sg/garnish';

/**
 * Cta for opening the terms.
 */
export const LoyaltyRewardTermsCta = (props: LoyaltyRewardTermsCtaProps) => {
  const { isDisabled, onPress } = props;

  const { formatMessage } = useIntl();
  const accessibilityLabel = formatMessage(messages.accessibilityLabel);

  return (
    <IconLink
      name="IconInfo"
      width={16}
      height={16}
      disabled={isDisabled}
      accessibilityLabel={accessibilityLabel}
      onPress={onPress}
    />
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Show terms for this reward',
    description: 'Loyalty | Reward | Terms cta a11y',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyRewardTermsCtaProps = {
  isDisabled: boolean;
  onPress: () => void;
};

import { useMemo } from 'react';
import { usePrevious } from '@sg/garnish';

import { useProductDetailsScreenContext } from '../../../../../../ProductDetailsScreen.provider';
import { getActiveIngredientsModifications } from '../../../../../../state';

// ─────────────────────────────────────────────────────────────────────────────

export const useAddedIngredientId = () => {
  const { modifications } = useProductDetailsScreenContext();

  // ─── Data ────────────────────────────────────────────────────────────

  const activeIngredientModifications = useMemo(
    () => getActiveIngredientsModifications(modifications),
    [modifications],
  );
  const previousIngredientModifications = usePrevious(
    activeIngredientModifications,
  );

  // ─────────────────────────────────────────────────────────────────────

  return useMemo(() => {
    if (!previousIngredientModifications) return;

    const previousModificationIds = previousIngredientModifications.map(
      ({ ingredient }) => ingredient.id,
    );
    const previousIngredientModificationsSet = new Set(previousModificationIds);

    const hasAddedNewIngredient =
      activeIngredientModifications.length >
      previousIngredientModifications.length;

    if (!hasAddedNewIngredient) return;

    const maybeAddedModification = activeIngredientModifications.find(
      (modification) =>
        !previousIngredientModificationsSet.has(modification.ingredient.id),
    );

    return maybeAddedModification?.ingredient.id;
  }, [activeIngredientModifications, previousIngredientModifications]);
};

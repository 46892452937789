import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { openUrl, useJumpNavigationCtx } from '@sg/garnish';

import { useNavigateToLastOrderMenu } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import { SupportedSweetpassHomeActions } from '../../utils';

export const useActionsRailHandler = () => {
  const { track } = useTelemetry();
  const { t } = useLocalizationContext();
  const navigation = useNavigation();
  const handleViewMenu = useNavigateToLastOrderMenu();
  const jumpContext = useJumpNavigationCtx();

  return useCallback(
    async (action: string, actionUrl?: string) => {
      track('sweetpass_home.action_card.tapped', { action, actionUrl });

      if (actionUrl) {
        await openUrl(actionUrl, t('general.error'));

        return;
      }

      if (action === SupportedSweetpassHomeActions.UpgradeSweetpass) {
        navigation.navigate('Modal', { screen: 'SweetpassUpgrade' });

        return;
      }

      if (action === SupportedSweetpassHomeActions.ExploreMenu) {
        handleViewMenu();

        return;
      }

      if (action === SupportedSweetpassHomeActions.ExploreBenefits) {
        navigation.navigate('Modal', { screen: 'SweetpassBenefits' });

        return;
      }

      if (action === SupportedSweetpassHomeActions.ExploreChallenges) {
        jumpContext.setCurrentTarget('challenges-rail');

        return;
      }

      if (action === SupportedSweetpassHomeActions.ExploreRewards) {
        jumpContext.setCurrentTarget('rewards-rail');
      }
    },
    [navigation, jumpContext, track, t, handleViewMenu],
  );
};

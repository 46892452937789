import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';

import type { StickyProps } from './Sticky.types';
import { stickComponent } from './Sticky.utils';
import { StickyContext } from './StickyContext';

export const Sticky = (props: StickyProps): React.ReactElement => {
  const {
    sticky,
    offset,
    setSticky,
    setHeaderReference,
    clearHeaderReference,
    setScrollListener,
    clearScrollListener,
  } = useContext(StickyContext);
  const { outside, children } = props;
  const [stickyComponentHeight, setStickyComponentHeight] = useState(0);
  const showComponent = (outside && sticky) ?? (!outside && !sticky);

  // References
  const stickyLineReference = React.useRef(null);
  const stickyComponentReference = React.useRef(null);

  // Scroll Listener -> Sticking Component.
  const scrollListener = React.useMemo(
    () => ({
      onScroll: async () =>
        stickComponent({
          sticky,
          offset,
          lineRef: stickyLineReference,
          compRef: stickyComponentReference,
          height: stickyComponentHeight,
          setStickyComponentHeight,
          setSticky,
        }),
    }),
    [
      sticky,
      stickyComponentHeight,
      offset,
      setSticky,
      setStickyComponentHeight,
    ],
  );

  // Bind/unbind Scroll Listener & Reference.
  useEffect(() => {
    if (outside) return;
    setHeaderReference(children);
    setScrollListener(scrollListener);

    return () => {
      clearScrollListener();
      clearHeaderReference();
    };
  }, [
    outside,
    scrollListener,
    children,
    setHeaderReference,
    setScrollListener,
    clearScrollListener,
    clearHeaderReference,
  ]);

  // Sticky Component: collapsable required for Android - https://github.com/facebook/react-native/issues/9382
  return (
    <View>
      {!outside && <View collapsable={false} ref={stickyLineReference} />}
      {Boolean(showComponent) && (
        <View collapsable={false} ref={stickyComponentReference}>
          {children}
        </View>
      )}
    </View>
  );
};

import { TierName } from '@order/graphql';
import type { TranslationKeys } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

export function getSweetpassTierNameLocalizationId(
  tierName: TierName | undefined,
  hasFreeTrial?: boolean,
): TranslationKeys {
  if (!tierName) return 'sweetpass';

  const isSweetpassPlusMembership = tierName === TierName.SweetpassPlus;
  const isFreeTrialMembership = isSweetpassPlusMembership && hasFreeTrial;

  if (isFreeTrialMembership) return 'sweetpass-plus-trial';

  return TIER_NAMES_LOCALIZATION_IDS[tierName];
}

// ─── Constants ───────────────────────────────────────────────────────────────

const TIER_NAMES_LOCALIZATION_IDS: Record<TierName, TranslationKeys> = {
  [TierName.Sweetpass]: 'sweetpass',
  [TierName.SweetpassPlus]: 'sweetpass-plus',
  [TierName.OptedOut]: 'sweetpass-opted-out',
};

import { useMemo } from 'react';
import { PixelRatio } from 'react-native';
import { getCloudinaryUrl, getContentfulImageUrl } from '@sg/garnish';

import type { ContentfulAsset } from '@order/Contentful';
import { useContentfulContentTypeEntry } from '@order/Contentful';
import { useLocalizationContext } from '@order/Localization';

import { isHomeHeroActionSupported } from '../../utils';

export const useHomeScreenLoadedContent = (): HomeScreenLoadedContent => {
  const {
    data: contentfulData,
    fetching: isLoadingContentfulData,
    error: contentfulError,
  } = useContentfulContentTypeEntry<ContentfulHomeScreenFields>({
    contentType: 'homeScreen',
    include: 2,
  });

  const content = useHomeScreenContent(
    contentFromEntry(contentfulData?.fields),
  );

  return {
    ...content,
    isLoadingContentfulData,
    contentfulError,
  };
};

const useHomeScreenContent = (
  content?: HomeScreenLoadedContent,
): HomeScreenLoadedContent => {
  const defaultContent = useHomeScreenDefaultContent();

  const isActionNotSupported =
    content?.heroCTA?.action &&
    !isHomeHeroActionSupported(content.heroCTA.action);

  if (!content || isActionNotSupported) {
    return defaultContent;
  }

  return content;
};

const useHomeScreenDefaultContent = (): HomeScreenLoadedContent => {
  const { t } = useLocalizationContext();

  return useMemo(
    () => ({
      heroHeadlineText: t('home.welcome.evergreen'),
      heroImageURL: getCloudinaryUrl(
        'https://res.cloudinary.com/sweetgreen/image/upload/v1667846265/210717_sweetgreen_sg_on_the_go_v9_5653_hdeqbz.jpg',
        { width: 700 },
      ),
      heroCTA: {
        text: t('home.hero-cta'),
        action: 'view_menu',
      },
    }),
    [t],
  );
};

const contentFromEntry = (
  fields?: ContentfulHomeScreenFields,
): HomeScreenLoadedContent | undefined => {
  const imageSize = PixelRatio.get() > 1 ? 1400 : 700;

  return fields
    ? {
        heroHeadlineText: fields.heroHeadlineText,
        heroCTA:
          fields.heroCtaAction !== undefined && fields.heroCtaText !== undefined
            ? {
                text: fields.heroCtaText,
                action: fields.heroCtaAction,
              }
            : undefined,
        heroImageURL: getContentfulImageUrl(fields.heroImage.fields.file.url, {
          fm: 'webp',
          q: 90,
          w: imageSize,
          h: imageSize,
          fit: 'fill',
        }),
      }
    : undefined;
};

type HomeScreenLoadedContent = Readonly<{
  isLoadingContentfulData?: boolean;
  contentfulError?: unknown;
  heroHeadlineText: string;
  heroImageURL?: string;
  heroCTA?: {
    text: string;
    action: string;
  };
}>;

type ContentfulHomeScreenFields = Readonly<{
  heroHeadlineText: string;
  heroImage: ContentfulAsset;
  heroCtaAction?: string;
  heroCtaText?: string;
}>;

import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import {
  type CancelSubscriptionAtCycleEndMutation,
  type CancelSubscriptionMutation,
  useCancelSubscriptionAtCycleEndMutation,
  useCancelSubscriptionMutation,
} from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

export const useCancelSweetpassSubscription = (
  props: UseCancelSweetpassSubscriptionProps,
) => {
  const {
    subscriptionId,
    isSubscriptionFreeTrial,
    isSubscriptionPastDue,
    onClose,
  } = props;

  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  const [cancelImmediatelyResponse, cancelImmediately] =
    useCancelSubscriptionMutation();
  const [cancelAtCycleEndResponse, cancelAtCycleEnd] =
    useCancelSubscriptionAtCycleEndMutation();

  const isCancellingSubscription =
    cancelImmediatelyResponse.fetching || cancelAtCycleEndResponse.fetching;

  const hasCancelledSubscription =
    cancelAtCycleEndResponse.data?.cancelSubscriptionAtCycleEnd?.__typename ===
      'CancelSubscriptionAtCycleEndSuccessResponse' ||
    cancelImmediatelyResponse.data?.cancelSubscription?.__typename ===
      'CancelSubscriptionSuccessResponse';

  const cancelSweetpassSubscription =
    isSubscriptionFreeTrial || isSubscriptionPastDue
      ? cancelImmediately
      : cancelAtCycleEnd;

  const requestCancelSubscription = useCallback(async () => {
    const { data } = await cancelSweetpassSubscription({ subscriptionId });

    if (!checkIfSubscriptionWasCancelled(isSubscriptionFreeTrial, data)) {
      addNoticeBanner({
        text: t('account.cancel-subscription.error'),
        palette: 'caution',
      });
      onClose();

      return;
    }

    addNoticeBanner({
      text: t('account.cancel-sweetpass-plus-subscription.success'),
      palette: 'success',
    });
  }, [
    subscriptionId,
    isSubscriptionFreeTrial,
    t,
    cancelSweetpassSubscription,
    addNoticeBanner,
    onClose,
  ]);

  return {
    isCancellingSubscription,
    hasCancelledSubscription,
    requestCancelSubscription,
  };
};

function checkIfSubscriptionWasCancelled(
  isSubscriptionFreeTrial: boolean,
  result?: CancelSubscriptionMutation | CancelSubscriptionAtCycleEndMutation,
) {
  if (isSubscriptionFreeTrial) {
    return (
      (result as CancelSubscriptionMutation)?.cancelSubscription.__typename ===
      'CancelSubscriptionSuccessResponse'
    );
  }

  return (
    (result as CancelSubscriptionAtCycleEndMutation)
      ?.cancelSubscriptionAtCycleEnd.__typename ===
    'CancelSubscriptionAtCycleEndSuccessResponse'
  );
}

type UseCancelSweetpassSubscriptionProps = Readonly<{
  subscriptionId: string;
  isSubscriptionFreeTrial: boolean;
  isSubscriptionPastDue: boolean;
  onClose: () => void;
}>;

/**
 * Returns the full address by combining street name,
 * building number, city, state and zip code
 */
export function formatAddress({
  city = '',
  state = '',
  zipCode = '',
  address = '',
} = {}) {
  return Object.entries({ city, state, zipCode }).reduce(
    (fullAddress, [type, partial]) => {
      const shouldSkipCommaBefore = type === 'zipCode' && zipCode.length > 0;

      return `${fullAddress}${
        partial ? `${shouldSkipCommaBefore ? '' : ','} ${partial}` : ''
      }`;
    },
    address,
  );
}

/**
 * Formats working hours by splitting hours string to array for each group
 */
export function formatWorkingHours(storeHours = '') {
  return storeHours.length > 0 ? storeHours.split(/\n/g) : [];
}

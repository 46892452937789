import { isAfter, isBefore, isWithinInterval, parse } from 'date-fns';
import {
  checkIfMatchesTimeFormat,
  convert24HoursTo12Hours,
  getCurrentWeekDay,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns the store's opening and closing hours for the closest operational day.
 */
export function getClosestStoreHoursInfo(
  params: GetClosestStoreHoursInfoParams,
) {
  const { permanentHours, storeHours } = params;

  const { weekDay: closestWeekDay, isToday = false } =
    getClosestStoreOpenDay(storeHours) ?? {};

  if (!closestWeekDay) {
    return null;
  }

  const storeHoursMap = convertToHoursMap(storeHours);
  const permanentHoursMap = convertToHoursMap(permanentHours);

  const maybeStoreHours = storeHoursMap.get(closestWeekDay);
  const maybePermanentHours = permanentHoursMap.get(closestWeekDay);

  if (!maybeStoreHours) {
    return null;
  }

  const formattedStoreHours = getFormattedStoreHours({
    selectedDayStoreHours: maybeStoreHours,
    selectedDayPermanentHours: maybePermanentHours,
  });
  const { isOpen, isNotYetOpen } = isToday
    ? checkIfStoreIsOpen(maybeStoreHours)
    : { isOpen: false, isNotYetOpen: false };

  // ─────────────────────────────────────────────────────────────────────

  return {
    storeHours: formattedStoreHours,
    weekDay: closestWeekDay,
    state: { isToday, isOpen, isNotYetOpen },
  };
}

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns the closest day when the store is open based on the provided hours.
 */
function getClosestStoreOpenDay(storeHours: readonly HoursGroup[]) {
  const currentWeekDay = getCurrentWeekDay().toLowerCase();
  const indexOfCurrentWeekDay = WEEKDAYS.indexOf(currentWeekDay);

  if (indexOfCurrentWeekDay === -1) {
    return null;
  }

  const storeHoursMap = convertToHoursMap(storeHours);

  // ─── Today Case ──────────────────────────────────────────────────────

  const currentStoreHours = storeHoursMap.get(currentWeekDay);

  if (
    currentStoreHours &&
    checkIfHasValidHoursGroup(currentStoreHours) &&
    !checkIfStoreIsOpen(currentStoreHours).isAlreadyClosed
  ) {
    return { weekDay: currentWeekDay, isToday: true };
  }

  // ─── Next Day Case ───────────────────────────────────────────────────

  const maybeNextWeekDaysBefore = WEEKDAYS.slice(indexOfCurrentWeekDay + 1);
  const maybeNextWeekDaysAfter = WEEKDAYS.slice(0, indexOfCurrentWeekDay);
  const maybeNextWeekDays = [
    ...maybeNextWeekDaysBefore,
    ...maybeNextWeekDaysAfter,
  ];

  const maybeNextWeekDay = maybeNextWeekDays.find((weekDay) => {
    const maybeStoreHours = storeHoursMap.get(weekDay);

    return maybeStoreHours && checkIfHasValidHoursGroup(maybeStoreHours);
  });

  if (maybeNextWeekDay) {
    return { weekDay: maybeNextWeekDay, isToday: false };
  }

  // ─────────────────────────────────────────────────────────────────────

  return null;
}

/**
 * Returns booleans indicating the current state of the store.
 */
function checkIfStoreIsOpen(hoursGroup: HoursGroup) {
  const { start, end } = hoursGroup;

  try {
    const currentDate = new Date();
    const openingDate = parse(start, STORE_HOURS_FORMAT, currentDate);
    const closingDate = parse(end, STORE_HOURS_FORMAT, currentDate);

    const isOpen = isWithinInterval(currentDate, {
      start: openingDate,
      end: closingDate,
    });
    const isNotYetOpen = isBefore(currentDate, openingDate);
    const isAlreadyClosed = isAfter(currentDate, closingDate);

    return { isOpen, isNotYetOpen, isAlreadyClosed };
  } catch {
    return { isOpen: false, isNotYetOpen: false, isAlreadyClosed: false };
  }
}

/**
 * Returns formatted store hours with a flag indicating whether hours ar e
 * only temporary.
 */
function getFormattedStoreHours(
  params: GetStoreHoursParams,
): FormattedHoursGroup {
  const { selectedDayStoreHours, selectedDayPermanentHours } = params;
  const { start, end } = selectedDayStoreHours;

  const startHourFormatted = convert24HoursTo12Hours(start);
  const endHourFormatted = convert24HoursTo12Hours(end);

  const hasTemporaryOpeningHours = start !== selectedDayPermanentHours?.start;
  const hasTemporaryClosingHours = end !== selectedDayPermanentHours?.end;

  return {
    start: { hour: startHourFormatted, isTemporary: hasTemporaryOpeningHours },
    end: { hour: endHourFormatted, isTemporary: hasTemporaryClosingHours },
  };
}

function checkIfHasValidHoursGroup(hoursGroup: HoursGroup) {
  return (
    checkIfMatchesTimeFormat(hoursGroup.start, STORE_HOURS_FORMAT) &&
    checkIfMatchesTimeFormat(hoursGroup.end, STORE_HOURS_FORMAT)
  );
}

/**
 * Converts provided hour groups array to a Map.
 */
function convertToHoursMap(hourGroups: readonly HoursGroup[]) {
  return new Map<string, HoursGroup>(
    hourGroups.map((group) => [group.day.toLowerCase(), group]),
  );
}

// ─── Constants ───────────────────────────────────────────────────────────────

const WEEKDAYS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
const STORE_HOURS_FORMAT = 'HH:mm';

// ─── Types ───────────────────────────────────────────────────────────────────

type GetStoreHoursParams = {
  selectedDayStoreHours: HoursGroup;
  selectedDayPermanentHours: HoursGroup | undefined;
};

type GetClosestStoreHoursInfoParams = {
  permanentHours: readonly HoursGroup[];
  storeHours: readonly HoursGroup[];
};

type FormattedHoursGroup = {
  start: {
    hour: string;
    isTemporary: boolean;
  };
  end: {
    hour: string;
    isTemporary: boolean;
  };
};

type HoursGroup = {
  day: string;
  start: string;
  end: string;
};

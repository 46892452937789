import type { ComponentProps } from 'react';
import { useCallback, useMemo } from 'react';
import type { Ledger as LedgerComponent } from '@sg/garnish';

import { useChallengesAndRewards } from '@order/ChallengesAndRewards';
import type { Ledger } from '@order/graphql';
import { useCart } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import { useActiveRouteName } from '../../navigation';
import { useBagNavigation } from './Bag.navigation';

export const useOpenBag = (props?: UseOpenBagProps) => {
  const isBagOpen = useIsBagOpen();
  const { handleOpenBag } = useBagNavigation();
  const { fetchCart } = useCart();

  return useCallback(
    () => {
      if (isBagOpen) return;

      if (props?.shouldUpdateCart) fetchCart();
      handleOpenBag();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleOpenBag, props?.shouldUpdateCart],
  );
};

export const useCloseBag = () => {
  const isBagOpen = useIsBagOpen();
  const { handleCloseBag } = useBagNavigation();

  return useCallback(() => {
    if (isBagOpen) handleCloseBag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCloseBag]);
};

// Returns whether the bag is opened by using the active route name.
export const useIsBagOpen = () => {
  return useActiveRouteName() === 'Bag';
};

// Extracts the restaurant ids (type/slug/address) from the bag restaurant.
export const useBagRestaurantIds = () => {
  const { isCartEmpty, cartOrderType, cart } = useCart();
  const restaurantSlug = cart?.restaurant?.slug;
  const addressId = cart?.deliveryOrderDetail?.address?.id;

  if (isCartEmpty) {
    return {
      bagOrderType: undefined,
      bagRestaurantSlug: undefined,
      bagAddressId: undefined,
    };
  }

  return {
    bagOrderType: cartOrderType,
    bagRestaurantSlug: addressId ? undefined : restaurantSlug,
    bagAddressId: addressId,
  };
};

const sumLedgerTotal = (ledger: Partial<Ledger> | undefined): number => {
  if (!ledger) return 0;

  const ledgerTotal =
    Number(ledger.subtotal ?? 0) +
    Number(ledger.tax ?? 0) +
    Number(ledger.tip ?? 0) +
    Number(ledger.feesTotal ?? 0) -
    Number(ledger.creditsTotal ?? 0) -
    Number(ledger.discountsTotal ?? 0);

  return ledgerTotal;
};

type UseLedgerItemsProps = Readonly<{
  ledger: Partial<Ledger> | undefined;
  showTip?: boolean;
  showReward?: boolean;
  showTaxes?: boolean;
  showTotal?: boolean;
}>;

type LedgerItem = ComponentProps<typeof LedgerComponent>['items'][0];

export const useLedgerItems = (props: UseLedgerItemsProps) => {
  const { ledger, showTaxes, showReward, showTotal, showTip } = props;

  const { t, formatPrice } = useLocalizationContext();
  const { selectedRewardIndex } = useChallengesAndRewards();

  return useMemo(() => {
    const total = sumLedgerTotal(ledger);

    const subtotalItem: LedgerItem = {
      amount: formatPrice(ledger?.subtotal ?? 0, 'USD'),
      label: t('general.subtotal-label'),
    };

    const taxesItem: LedgerItem = {
      amount: `${formatPrice(ledger?.tax ?? 0, 'USD')}`,
      label: t('general.tax-label'),
    };

    const feesItems =
      ledger?.fees
        ?.filter((fee) => fee.amount)
        .map((fee) => ({
          label: fee.name,
          amount: formatPrice(fee.amount ?? 0, 'USD'),
          helperText: fee.description ?? undefined,
        })) ?? [];

    const appliedRewardItem: LedgerItem = {
      label: t('bag.lineItem.reward'),
      amount: t('bag.lineItem.reward-calculated-at-checkout'),
    };

    const tipItem: LedgerItem = {
      label: 'Tip',
      amount: formatPrice(ledger?.tip ?? 0, 'USD'),
      helperText: undefined,
    };

    const creditsItems =
      ledger?.credits
        ?.filter((credit) => credit.amount > 0)
        .map((credit) => ({
          label: credit.name,
          amount: `-${formatPrice(credit.amount ?? 0, 'USD')}`,
          helperText: credit.description ?? undefined,
          palette: 'success' as never,
        })) ?? [];

    const discountsItems =
      ledger?.discounts
        ?.filter((discount) => discount.amount)
        .map((discount) => ({
          label: discount.name,
          amount: `-${formatPrice(discount.amount ?? 0, 'USD')}`,
          helperText: discount.description ?? undefined,
          palette: 'success' as never,
        })) ?? [];

    const totalItem: LedgerItem = {
      label: t('general.total-label'),
      amount: `${formatPrice(total ?? 0, 'USD')}`,
      bold: true,
      testID: 'ledger.total',
    };

    return [
      subtotalItem,
      showTaxes && taxesItem,
      ...feesItems,
      !showReward &&
        typeof selectedRewardIndex === 'number' &&
        appliedRewardItem,
      showTip && tipItem,
      ...(showReward ? discountsItems : []),
      ...creditsItems,
      showTotal && totalItem,
    ].filter(Boolean) as readonly LedgerItem[];
  }, [
    ledger,
    showTaxes,
    showReward,
    selectedRewardIndex,
    showTip,
    showTotal,
    t,
    formatPrice,
  ]);
};

// ─── PROPS ──────────────────────────────────────────────────────────────────────

type UseOpenBagProps = Readonly<{
  shouldUpdateCart?: boolean;
}>;

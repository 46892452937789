import { theme } from '@sg/garnish';

import type {
  SweetpassBenefitsPageBenefitListPaletteTag,
  SweetpassBenefitsPageCardPaletteTag,
} from './hooks';

// ––– BENEFIT –––––––––––––––––––––––––––––––––––––––––––––

const SWEETPASS_BENEFITS_PAGE_BENEFIT_PALETTES: SweetpassBenefitScreenBenefitPalettes =
  {
    regular: {
      titleColor: theme.colors.GREEN_2,
      bodyColor: theme.colors.GREEN_2,
      opacity: 1,
    },
    'greyed-out': {
      titleColor: theme.colors.GREEN_2,
      bodyColor: theme.colors.GREEN_2,
      opacity: 0.64,
    },
  };

export const getSweetpassBenefitsScreenBenefitPaletteForTag = (
  tag?: SweetpassBenefitsPageBenefitListPaletteTag,
) =>
  tag
    ? SWEETPASS_BENEFITS_PAGE_BENEFIT_PALETTES[tag]
    : SWEETPASS_BENEFITS_PAGE_BENEFIT_PALETTES.regular;

type SweetpassBenefitScreenBenefitPalettes = {
  readonly [Key in SweetpassBenefitsPageBenefitListPaletteTag]: SweetpassBenefitScreenBenefitPalette;
};

export type SweetpassBenefitScreenBenefitPalette = Readonly<{
  titleColor: string;
  bodyColor: string;
  opacity: number;
}>;

// ––– BENEFIT –––––––––––––––––––––––––––––––––––––––––––––

const SWEETPASS_BENEFITS_PAGE_CARD_PALETTES: SweetpassBenefitScreenCardPalettes =
  {
    cucumber: {
      backgroundColor: theme.colors.CUCUMBER,
    },
    quinoa: {
      backgroundColor: theme.colors.QUINOA,
    },
  };

export const getSweetpassBenefitsScreenCardPaletteForTag = (
  tag?: SweetpassBenefitsPageCardPaletteTag,
) =>
  tag
    ? SWEETPASS_BENEFITS_PAGE_CARD_PALETTES[tag]
    : SWEETPASS_BENEFITS_PAGE_CARD_PALETTES.cucumber;

type SweetpassBenefitScreenCardPalettes = {
  readonly [Key in SweetpassBenefitsPageCardPaletteTag]: SweetpassBenefitScreenCardPalette;
};

export type SweetpassBenefitScreenCardPalette = Readonly<{
  backgroundColor: string;
}>;

import React from 'react';

import { useCheckoutContext } from '../../../../CheckoutProvider';
import { CheckoutPaymentBlockPromoCodeWithSeparateGiftCard } from './CheckoutPaymentBlockPromoCodeWithSeparateGiftCard';

export const ConnectedCheckoutPaymentBlockPromoCode = () => {
  const context = useCheckoutContext();

  return <CheckoutPaymentBlockPromoCodeWithSeparateGiftCard {...context} />;
};

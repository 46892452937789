import React, { type ComponentProps, type ReactNode } from 'react';
import { theme, VStack } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsListsV2GroupStack = (
  props: LocationResultsListsV2GroupStackProps,
) => {
  const { children, gap = 0, withDivider } = props;

  return (
    <VStack
      gap={gap}
      hasDivider={withDivider}
      dividerColor={theme.colors.LIGHT}
    >
      {children}
    </VStack>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsListsV2GroupStackProps = {
  children: ReactNode;
  gap?: ComponentProps<typeof VStack>['gap'];
  withDivider?: boolean;
};

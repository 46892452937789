import { useEffect } from 'react';
import { useCounterMachineInterpreter } from '@sg/machines';

import { useHasLoggedOut } from '../AuthMachine';
import { useGlobalAppState } from '../GlobalAppState';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A simple custom hook that resets the current placed orders counter on logout.
 */
export const useResetPlacedOrdersCounterOnLogout = () => {
  const { placedOrderCounterMachineRef: interpreter } = useGlobalAppState();
  const hasLoggedOut = useHasLoggedOut();

  const { resetCounter } = useCounterMachineInterpreter(interpreter);

  // Reset placed orders counter on logout
  useEffect(() => {
    if (hasLoggedOut) resetCounter();
  }, [hasLoggedOut, resetCounter]);
};

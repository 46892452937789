import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';

export const MapWithControlsWrapper = (props: MapWithControlsWrapperProps) => {
  const { children, ...restProps } = props;

  return (
    <View style={styles.wrapper} {...restProps}>
      {children}
    </View>
  );
};

// ──────────────────────────────────────────────────────────────────────────────

type MapWithControlsWrapperProps = Readonly<{
  children: React.ReactNode;
}> &
  ViewProps;

// ──────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    flex: 1,
  },
});

import { useMemo } from 'react';

import { CostChannel } from '@order/graphql';

import { usePersonalizedRecommendationWarningTelemetry } from '../../../components/MenuPersonalizationRail/hooks/usePersonalizedRecommendationWarningTelemetry';
import { useGetLineItemQuery } from '../GraphQL/LineItemDetailsScreen.getLineItem.generated';
import { useGetProductQuery } from '../GraphQL/ProductDetailsScreen.getProduct.generated';
import { getAllIngredientModifications } from '../state';

export const useProduct = (props: UseProductProps) => {
  const { isDelivery, productSlug, lineItemId = '', ingredientIds } = props;

  // ─── QUERIES ────────────────────────────────────────────────────────

  const [productResponse] = useGetProductQuery({
    variables: {
      id: productSlug,
      costChannel: isDelivery
        ? CostChannel.DeliveryCostChannel
        : CostChannel.DefaultCostChannel,
    },
    requestPolicy: 'cache-and-network',
  });

  const [lineItemResponse] = useGetLineItemQuery({
    variables: { input: { id: lineItemId } },
    requestPolicy: 'cache-and-network',
    pause: !lineItemId,
  });

  // ─── DATA ───────────────────────────────────────────────────────────

  const product = useMemo(
    () => getProductCtx(productResponse),
    [productResponse],
  );
  const lineItem = useMemo(
    () => getLineItemCtx(lineItemResponse),
    [lineItemResponse],
  );
  const context = useMemo(
    () => getIngredientsCtx({ product, lineItem, ingredientIds }),
    [lineItem, product, ingredientIds],
  );

  const ingredients = context?.ingredients;

  const stale = product.stale || lineItem.stale;
  const fetching = product.fetching || lineItem.fetching;

  const errorMessage = product.errorMessage ?? lineItem.errorMessage ?? '';
  const error = useMemo(
    () => (errorMessage ? { message: errorMessage } : null),
    [errorMessage],
  );

  const customIngredients = context?.context.customIngredients;
  const allIngredientModifications =
    context?.context.allIngredientModifications ?? [];

  usePersonalizedRecommendationWarningTelemetry({
    fetching,
    ingredientIds,
    // @ts-expect-error TS(2322): Type '(Ingredient | undefined)[] | undefined' is n... Remove this comment to see the full error message
    customIngredients,
    ingredientModifications: allIngredientModifications,
  });

  // ────────────────────────────────────────────────────────────────────

  return useMemo(
    () => ({
      lineItem: lineItem.data,
      product: product.data,
      ingredients,
      fetching,
      stale,
      error,
    }),
    [error, fetching, ingredients, lineItem.data, product.data, stale],
  );
};

// ─── UTILS ──────────────────────────────────────────────────────────────────────

export const getProductCtx = (queryResponse: ProductQueryResponse) => {
  const data = queryResponse.data?.product ?? null;
  const errorMessage = queryResponse.error?.message ?? '';
  const { fetching = false, stale = false } = queryResponse;

  return { data, errorMessage, fetching, stale };
};

export const getLineItemCtx = (queryResponse: LineItemQueryResponse) => {
  const lineItemData = queryResponse.data?.getLineItem;

  const isLineItemResolved = lineItemData?.__typename === 'LineItemSuccess';
  const isLineItemNotFound = lineItemData?.__typename === 'LineItemNotFound';
  const isLineItemNotValid = lineItemData?.__typename === 'ValidationError';

  const responseErrorMessage = queryResponse.error?.message;
  const lineItemErrorMessage =
    isLineItemNotFound || isLineItemNotValid ? lineItemData.message : undefined;

  const data = isLineItemResolved ? lineItemData.lineItem : null;
  const errorMessage = responseErrorMessage ?? lineItemErrorMessage ?? '';
  const { fetching = false, stale = false } = queryResponse;

  return { data, errorMessage, fetching, stale };
};

export const getIngredientsCtx = (props: GetIngredientsCtxProps) => {
  const { product, lineItem, ingredientIds } = props;

  const areAllQueriesResolved = !product.fetching && !lineItem.fetching;

  const productIngredients = product.data?.ingredients ?? null;
  const maxModifications = Number(product.data?.maxModifications);
  const lineItemIngredients = lineItem.data?.ingredients ?? null;

  const ingredientsModifications =
    product.data?.ingredientModifications ?? null;

  const ingredientIdsToFilter: readonly string[] = ingredientIds ?? [];

  const allIngredientModifications = ingredientsModifications
    ? // @ts-expect-error TS(2345): Argument of type '{ readonly __typename?: "Ingredi... Remove this comment to see the full error message
      getAllIngredientModifications(ingredientsModifications)
    : [];

  // Finds ingredient modificiations matching the provided ingredient ids
  // Removes null values from the array if a given ingredient id is not present in the
  // modification list
  const customIngredients = ingredientIdsToFilter
    .map(
      (id) =>
        allIngredientModifications.find((mod) => mod.ingredient.id === id)
          ?.ingredient,
    )
    .filter(Boolean);

  const defaultIngredients = productIngredients;

  const activeIngredients =
    customIngredients.length > 0
      ? customIngredients
      : lineItemIngredients ?? productIngredients;

  const mixedDressingDetails = lineItem?.data?.mixedDressingDetails ?? [];

  // ────────────────────────────────────────────────────────────────────

  const isDataResolved =
    areAllQueriesResolved &&
    ingredientsModifications &&
    defaultIngredients &&
    activeIngredients &&
    customIngredients;

  if (isDataResolved) {
    return {
      ingredients: {
        ingredientsModifications,
        defaultIngredients,
        activeIngredients,
        maxModifications,
        mixedDressingDetails,
      },
      context: {
        allIngredientModifications,
        customIngredients,
      },
    };
  }

  return null;
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type UseProductProps = Readonly<{
  productSlug: string;
  lineItemId: string | undefined;
  ingredientIds?: readonly string[];
  isDelivery?: boolean;
}>;

export type GetIngredientsCtxProps = Readonly<{
  product: ReturnType<typeof getProductCtx>;
  lineItem: ReturnType<typeof getLineItemCtx>;
  ingredientIds?: readonly string[];
}>;

export type ProductQueryResponse = ReturnType<typeof useGetProductQuery>[0];

export type LineItemQueryResponse = ReturnType<typeof useGetLineItemQuery>[0];

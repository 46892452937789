import React from 'react';
import { useStyle } from 'react-native-style-utilities';

import type { MeasureProps } from './useMeasuredView.types';
import { measureView } from './useMeasuredView.utils';

export const useMeasuredViewStyle = (props: MeasureProps) => {
  const { width, height, padding = 0 } = props;
  const onLayout = React.useCallback(() => {
    measureView(props);
  }, [props]);
  const measuredViewStyle = useStyle(
    () => ({
      ...(Number(width) ? { width: Number(width) + Number(padding) } : {}),
      ...(Number(height) ? { height: Number(height) + Number(padding) } : {}),
    }),
    [width, height, padding],
  );

  return { onLayout, measuredViewStyle };
};

import { defineMessages } from 'react-intl';

export const ordersTabsMessages = defineMessages({
  ordersTabLabel: {
    defaultMessage: 'Orders',
    description: 'Orders Screen | Orders tab title',
  },
  ordersTabA11yLabel: {
    defaultMessage: 'Show orders tab',
    description: 'Orders Screen | Orders tab a11y label',
  },
  giftCardsTabLabel: {
    defaultMessage: 'Gift Cards',
    description: 'Orders Screen | Gift cards tab title',
  },
  giftCardsTabA11yLabel: {
    defaultMessage: 'Show gift cards tab',
    description: 'Orders Screen | Gift cards tab a11y label',
  },
});

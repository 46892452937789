import React from 'react';

import { MeasuredView } from './MeasuredView';
import type { MeasureViewResponse, ViewProps } from './useMeasuredView.types';

export const useMeasuredView = (): MeasureViewResponse => {
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  return {
    width,
    height,
    MeasuredView: (props: ViewProps) => (
      <MeasuredView
        {...props}
        width={width}
        height={height}
        setWidth={setWidth}
        setHeight={setHeight}
      />
    ),
  };
};

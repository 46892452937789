import React from 'react';
import { StyleSheet } from 'react-native';
import { FadeView, theme } from '@sg/garnish';

// ────────────────────────────────────────────────────────────────────────────

export const BagLoadingState = (props: BagLoadingStateProps) => {
  return <FadeView show={props.isLoading} style={styles.container} />;
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagLoadingStateProps = {
  isLoading: boolean;
};

import { defineMessages } from 'react-intl';

export const locationResultsListsV2Messages = defineMessages({
  recentLocationsHeading: {
    defaultMessage: 'Recent',
    description: 'Location Search | Recent locations | Heading',
  },
  nearbyLocationsHeading: {
    defaultMessage: 'Nearby',
    description: 'Location Search | Nearby locations | Heading',
  },
});

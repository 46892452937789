import { TierName, TierStatusName } from '@order/graphql';
import { formatDateWithoutTimezone } from '@order/utils';

import type { AccountSweetpassCancellationSection } from '../../useSweetpassAccountContentfulData';
import type {
  AsyncCta,
  GetSectionProps,
  SweetpassSectionProps,
} from '../types';

export const getCancellationSection = (
  props: GetCancellationSectionProps,
): SweetpassCancellationSectionProps | undefined => {
  const {
    name,
    tier,
    tierStatus,
    activeSubscription,
    cancellationDataTitle,
    cancellationDataBody,
    cancellationDataCancellationCta,
    cancelSubscriptionHandler,
    formatMessage,
  } = props;

  const hasCancellableTier = tier?.name === TierName.SweetpassPlus;
  const isCancellationPending =
    tierStatus === TierStatusName.CancellationPending;

  if (!activeSubscription || !hasCancellableTier || isCancellationPending) {
    return;
  }

  const isTrialMembership = tierStatus === TierStatusName.FreeTrial;
  const tierName = isTrialMembership
    ? formatMessage('sweetpass-plus-trial')
    : formatMessage('sweetpass-plus');

  const { nextBillingDate } = activeSubscription;

  const { formattedDate: formattedNextBillingDate } =
    formatDateWithoutTimezone(nextBillingDate);

  if (!formattedNextBillingDate) return;

  const bodyText = cancellationDataBody
    .replaceAll('{tierName}', tierName)
    .replaceAll('{nextBillingDate}', formattedNextBillingDate);
  const ctaTitle = cancellationDataCancellationCta.replaceAll(
    '{tierName}',
    tierName,
  );

  return {
    name,
    title: cancellationDataTitle,
    bodyText,
    cta: {
      palette: 'caution',
      title: ctaTitle,
      accessibilityLabel: ctaTitle,
      handler: cancelSubscriptionHandler,
    },
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type GetCancellationSectionProps = Readonly<{
  cancelSubscriptionHandler: () => Promise<void>;
}> &
  AccountSweetpassCancellationSection &
  Pick<
    GetSectionProps,
    'tier' | 'tierStatus' | 'activeSubscription' | 'formatMessage'
  >;

type SweetpassCancellationCta = AsyncCta;

export type SweetpassCancellationSectionProps = Readonly<{
  name: string;
  title: string;
  bodyText: string;
  cta: SweetpassCancellationCta;
}> &
  SweetpassSectionProps;

import { defineMessages } from 'react-intl';

export const lineItemIngredientsMessages = defineMessages({
  addedIngredients: {
    defaultMessage: 'Added: {ingredients}',
    description: 'Lite item | Ingredients | Added ingredients',
  },
  removedIngredients: {
    defaultMessage: 'Removed: {ingredients}',
    description: 'Lite item | Ingredients | Removed ingredients',
  },
});

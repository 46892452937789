/* istanbul ignore file */

import * as Contentful from './useContentfulContentTypeEntry';

/**
 * Mocks `useContentfulContentTypeEntry` hook using `jest.spyOn`.
 *
 * @example
 *
 * mockUseContentfulContentTypeEntry({
 *  shouldMockReturnValueOnce: true,
 *  returnValue: {
 *   data: {...},
 *   fetching: false,
 *   error: undefined
 *  }
 * });
 */
export function mockUseContentfulContentTypeEntry(
  props?: MockUseContentfulContentTypeEntryProps,
) {
  const { returnValue, shouldMockReturnValueOnce } = props ?? {};

  const returnValueMock = {
    data: undefined,
    fetching: false,
    error: undefined,
    ...returnValue,
  };

  // ─────────────────────────────────────────────────────────────────────

  if (shouldMockReturnValueOnce) {
    jest
      .spyOn(Contentful, 'useContentfulContentTypeEntry')
      .mockReturnValueOnce(returnValueMock);

    return;
  }

  jest
    .spyOn(Contentful, 'useContentfulContentTypeEntry')
    .mockReturnValue(returnValueMock);
}

// ─── Types ───────────────────────────────────────────────────────────────────

type MockUseContentfulContentTypeEntryProps = Readonly<{
  shouldMockReturnValueOnce?: boolean;
  returnValue?: Partial<
    ReturnType<typeof Contentful.useContentfulContentTypeEntry>
  >;
}>;

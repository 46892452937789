/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InStoreGiftCardsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type InStoreGiftCardsQuery = {
  readonly __typename?: 'Query';
  readonly inStorePaymentMethods: ReadonlyArray<{
    readonly __typename?: 'Credit';
    readonly id: string;
    readonly title: string;
    readonly remainingAmount: number;
  }>;
};

export const InStoreGiftCardsDocument = gql`
  query InStoreGiftCards {
    inStorePaymentMethods {
      id
      title
      remainingAmount
    }
  }
`;

export function useInStoreGiftCardsQuery(
  options?: Omit<Urql.UseQueryArgs<InStoreGiftCardsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<InStoreGiftCardsQuery, InStoreGiftCardsQueryVariables>({
    query: InStoreGiftCardsDocument,
    ...options,
  });
}

import { useActor } from '@xstate/react';

import { useGlobalAppState } from '@order/GlobalAppState';

import { useBagRestaurantIds } from '../../../../components/Bag/Bag.hooks';
import type { ReorderStep } from '../../machines/Reorder.machine';
import { getValidEventKeys } from '../utils';

export const useReorderState = () => {
  const [state] = useActor(useGlobalAppState().reorderMachineRef);
  const { bagRestaurantSlug, bagAddressId } = useBagRestaurantIds();

  return {
    ...getValidEventKeys(state.context),
    currentStep: state.value as ReorderStep,
    previousStep: state.context.previousStep,
    previousEvent: state.context,
    hasBag: Boolean(bagRestaurantSlug) || Boolean(bagAddressId),
  };
};

import type { Client } from 'urql';
import type { OperationResult } from 'urql';

import type { CustomerQuery } from '../../graphql/customer.generated';
import { customerQuery } from './customer.queries';

// ─────────────────────────────────────────────────────────────────────────────

export async function fetchCustomer(
  options: FetchCustomerOptions,
): Promise<CustomerData> {
  const { client } = options;

  const response = await customerQuery(client, 'network-only');
  const { currentCustomer } = response.data ?? {};

  const hasFailedToFetchCustomerData =
    response.error !== undefined || !currentCustomer;

  // NOTE: This error should be handled by the state machine service's `onError` handler, or it will fail silently.
  if (hasFailedToFetchCustomerData) {
    throw new Error('Failed to get customer data!');
  }

  return currentCustomer;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomerData = NonNullable<
  OperationResult<CustomerQuery, Record<string, unknown>>['data']
>['currentCustomer'];

export type FetchCustomerOptions = {
  client: Client;
};

import Constants from 'expo-constants';

const ENV_VARS = Constants.expoConfig?.extra;

export const RECAPTCHA_CONFIG = {
  domain: ENV_VARS?.RECAPTCHA_DOMAIN ?? '',
  siteKey: ENV_VARS?.RECAPTCHA_SITE_KEY ?? '',
};

export const RECAPTCHA_EVENTS = {
  LOAD: 'LOAD',
  CHALLENGE: 'CHALLENGE',
  VERIFIED: 'VERIFIED',
  ERROR: 'ERROR',
};

export const TEST_GRECAPTCHA = 'test-grecaptcha';

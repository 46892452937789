import React, { useLayoutEffect, useMemo } from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { useNavigation } from '@react-navigation/native';
import type { AddressType } from '@sg/garnish';
import {
  BodyText,
  DisplayText,
  ProgressBar,
  switchcase,
  theme,
  useFluidSize,
  useResponsive,
} from '@sg/garnish';

import { FlattenedOrderStatuses } from '@order/graphql';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';
import { getOrderTime } from '@order/OrderStatus';
import {
  getDayDateTimeYear,
  getOrderTimeRange,
  ignoreTimezone,
  isToday,
} from '@order/utils';

import { OrderStatusCancellationOption } from './components';
import { OrderStatusCTAs } from './OrderStatusCTAs';

// ────────────────────────────────────────────────────────────────────────────────

export const OrderStatusInfo = (props: OrderStatusInfoProps) => {
  const {
    orderId = '',
    orderStatus,
    orderType,
    orderTotal = 0,
    vendorId,
    isPast,
    isPendingFeedback,
    onReorder,
  } = props;

  const { minWidth, match } = useResponsive();

  // ─── In-app order cancellation ──────────────────────────────────

  const isInAppOrderCancellationEnabled = useFeatureFlag(
    'CELS-1476-in-app-order-cancellation',
  );

  // ────────────────────────────────────────────────────────────────

  const isInReceivedStatus = orderStatus === 'received';

  const shouldDisplayLegacyCancellationOption =
    !isInAppOrderCancellationEnabled && isInReceivedStatus;

  // ────────────────────────────────────────────────────────────────

  const shouldShowProgressBar = checkIfShouldShowProgressBar(orderStatus);
  const isOrderCancelled = orderStatus === 'failed';
  const canReorder = isOrderCancelled || isPast;
  const shouldRenderOrderStatusCTAs = canReorder && minWidth.isSM;

  const orderStatusTitle = useOrderStatusTitle(props);
  const orderStatusDetails = useOrderStatusDetails(props);

  useOrderStatusScreenTitle(orderStatusTitle);

  // ─── Styles ──────────────────────────────────────────────────────

  const ctasWrapperStyle = match([undefined, styles.ctasWrapperSM]);

  // ─────────────────────────────────────────────────────────────────

  return (
    <View testID="order-status-info">
      <OrderStatusInfoHeader title={orderStatusTitle} vendorId={vendorId} />
      <OrderStatusDetails details={orderStatusDetails} />

      {shouldDisplayLegacyCancellationOption ? (
        <OrderStatusCancellationOption />
      ) : null}

      {shouldShowProgressBar ? (
        <OrderProgressBar orderType={orderType} orderStatus={orderStatus} />
      ) : null}

      {shouldRenderOrderStatusCTAs ? (
        <View style={ctasWrapperStyle}>
          <OrderStatusCTAs
            orderId={orderId}
            orderTotal={orderTotal}
            isPendingFeedback={isPendingFeedback}
            onReorder={onReorder}
          />
        </View>
      ) : null}
    </View>
  );
};

// ─── Subcomponents ───────────────────────────────────────────────────────────

const OrderStatusInfoHeader = (props: OrderStatusInfoHeaderProps) => {
  const { title, vendorId, ...restProps } = props;

  const { match } = useResponsive();

  const headerStyles = match([undefined, styles.infoHeaderSM]);

  return (
    <View style={headerStyles} {...restProps}>
      <OrderStatusTitle title={title} />
      <OrderStatusNumber vendorId={vendorId} />
    </View>
  );
};

const OrderStatusNumber = (props: OrderStatusNumberProps) => {
  const { vendorId: orderId } = props;

  const { currentBreakpoint } = useResponsive();
  const { t } = useLocalizationContext();

  return (
    <BodyText size={4} bold={currentBreakpoint.isXS}>
      {t('order-status-info.order-number', { orderId })}
    </BodyText>
  );
};

const OrderStatusTitle = (props: OrderStatusTitleProps) => {
  const { match } = useResponsive();

  // ─────────────────────────────────────────────────────────────────

  const fluid = useFluidSize({ min: 1024, max: 1440 });
  const fontStyles = useStyle(
    () => ({ fontSize: fluid(32, 40), lineHeight: fluid(36, 40) }),
    [],
  );
  const titleStyles = [match([styles.titleXS, styles.titleSM]), fontStyles];

  // ─────────────────────────────────────────────────────────────────

  return (
    <DisplayText size={3} style={titleStyles}>
      {props.title}
    </DisplayText>
  );
};

const OrderStatusDetails = (props: OrderStatusDetailsProps) => {
  const { details } = props;

  return <BodyText>{details}</BodyText>;
};

const OrderProgressBar = (props: OrderProgressBarProps) => {
  const { orderStatus, orderType } = props;

  const progressNodes = useProgressNodes(orderType, orderStatus);

  return (
    <View style={styles.progressBar}>
      <ProgressBar nodes={progressNodes} maxFontSizeMultiplier={1.3} />
    </View>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  //
  // ─── Info Header ─────────────────────────────────────────────────

  infoHeaderSM: {
    flexDirection: 'column-reverse',
  },

  // ─── Progress Bar ────────────────────────────────────────────────

  progressBar: {
    marginTop: theme.spacing['4'],
    paddingTop: theme.spacing['4'],
    minHeight: theme.spacing['10'],
  },

  // ─── Title ───────────────────────────────────────────────────────

  titleXS: {
    marginBottom: theme.spacing['4'],
  },
  titleSM: {
    marginVertical: theme.spacing['4'],
  },

  // ─── CTAs ────────────────────────────────────────────────────────

  ctasWrapperSM: {
    paddingTop: theme.spacing['4'],
  },
});

// ─── Hooks ──────────────────────────────────────────────────────────────────────

const useOrderStatusScreenTitle = (orderStatusText: string) => {
  const navigation = useNavigation();

  useLayoutEffect(() => {
    navigation.setOptions({ title: orderStatusText });
  }, [navigation, orderStatusText]);
};

const useOrderStatusTitle = (props: UseOrderStatusTitleProps) => {
  const { orderType, orderStatus, isPast, isTrackable } = props;

  const { t } = useLocalizationContext();

  const isFailed = orderStatus === FlattenedOrderStatuses.Failed;
  const options = { orderType };

  // ─────────────────────────────────────────────────────────────────

  if (isFailed) return t('order-status-info.title.failed');

  if (isPast) return t('order-status-info.title.past');

  if (!isTrackable) return t('order-status-info.title.unavailable', options);

  if (orderType === 'pickup') {
    return switchcase({
      [FlattenedOrderStatuses.Received]: t(
        'order-status-info.title.pickup.received',
      ),
      [FlattenedOrderStatuses.Preparing]: t(
        'order-status-info.title.pickup.preparing',
      ),
      [FlattenedOrderStatuses.Completed]: t(
        'order-status-info.title.pickup.completed',
      ),
    })(t('order-status-info.title.pickup.unknown'))(
      orderStatus ?? FlattenedOrderStatuses.Failed,
    );
  }

  if (orderType === 'outpost') {
    return switchcase({
      [FlattenedOrderStatuses.Received]: t(
        'order-status-info.title.outpost.received',
      ),
      [FlattenedOrderStatuses.Delivering]: t(
        'order-status-info.title.outpost.delivering',
      ),
      [FlattenedOrderStatuses.Completed]: t(
        'order-status-info.title.outpost.completed',
      ),
    })(t('order-status-info.title.outpost.unknown'))(
      orderStatus ?? FlattenedOrderStatuses.Failed,
    );
  }

  if (orderType === 'delivery') {
    return switchcase({
      [FlattenedOrderStatuses.Received]: t(
        'order-status-info.title.delivery.received',
      ),
      [FlattenedOrderStatuses.Preparing]: t(
        'order-status-info.title.delivery.preparing',
      ),
      [FlattenedOrderStatuses.ReadyForCourier]: t(
        'order-status-info.title.delivery.courier',
      ),
      [FlattenedOrderStatuses.Delivering]: t(
        'order-status-info.title.delivery.delivering',
      ),
      [FlattenedOrderStatuses.Completed]: t(
        'order-status-info.title.delivery.completed',
      ),
    })(t('order-status-info.title.delivery.unknown'))(
      orderStatus ?? FlattenedOrderStatuses.Failed,
    );
  }

  return t('general.unavailable');
};

const useOrderStatusDetails = (props: UseOrderStatusDetailsProps) => {
  const {
    orderStatus,
    orderType,
    isPast,
    isTrackable,
    wantedTime,
    estimatedDeliveryTime = '',
    locationName: name,
    locationAddress: address,
  } = props;

  const { startTime, endTime, timeEstimate } = useMemo(() => {
    const orderTime = getOrderTime({
      wantedTime,
      deliveryOrderDetail: { estimatedDeliveryTime },
    });
    const estimate = orderTime ? ignoreTimezone(orderTime) : undefined;

    return { ...getOrderTimeRange(estimate), timeEstimate: estimate };
  }, [wantedTime, estimatedDeliveryTime]);

  const isFailed = orderStatus === FlattenedOrderStatuses.Failed;

  const { t } = useLocalizationContext();

  const {
    day = '',
    date = '',
    time = '',
    year = '',
  } = timeEstimate ? getDayDateTimeYear(t, timeEstimate) : {};

  const todayLabel = isToday(timeEstimate) ? t('general.today') : day;

  const localizationValues = {
    name,
    address,
    day: isPast ? day : todayLabel,
    date,
    time,
    startTime,
    endTime,
    year,
    orderType,
  };

  if (isFailed) {
    return t('order-status-info.subtitle.failed', localizationValues);
  }

  if (isPast) {
    return t('order-status-info.subtitle.past', localizationValues);
  }

  if (!isTrackable) {
    return t('order-status-info.subtitle.unavailable', localizationValues);
  }

  if (orderType === 'pickup') {
    return switchcase({
      [FlattenedOrderStatuses.Received]: t(
        'order-status-info.subtitle.pickup.received',
        localizationValues,
      ),
      [FlattenedOrderStatuses.Preparing]: t(
        'order-status-info.subtitle.pickup.preparing',
        localizationValues,
      ),
      [FlattenedOrderStatuses.Completed]: t(
        'order-status-info.subtitle.pickup.completed',
        localizationValues,
      ),
    })(t('order-status-info.subtitle.unavailable', localizationValues))(
      orderStatus ?? FlattenedOrderStatuses.Received,
    );
  }

  if (orderType === 'outpost') {
    return switchcase({
      [FlattenedOrderStatuses.Received]: t(
        'order-status-info.subtitle.outpost.received',
        localizationValues,
      ),
      [FlattenedOrderStatuses.Delivering]: t(
        'order-status-info.subtitle.outpost.delivering',
        localizationValues,
      ),
      [FlattenedOrderStatuses.Completed]: t(
        'order-status-info.subtitle.outpost.completed',
        localizationValues,
      ),
    })(t('order-status-info.subtitle.unavailable', localizationValues))(
      orderStatus ?? FlattenedOrderStatuses.Received,
    );
  }

  if (orderType === 'delivery') {
    return switchcase({
      [FlattenedOrderStatuses.Received]: t(
        'order-status-info.subtitle.delivery.received',
        localizationValues,
      ),
      [FlattenedOrderStatuses.Preparing]: t(
        'order-status-info.subtitle.delivery.preparing',
        localizationValues,
      ),
      [FlattenedOrderStatuses.ReadyForCourier]: t(
        'order-status-info.subtitle.delivery.courier',
        localizationValues,
      ),
      [FlattenedOrderStatuses.Delivering]: t(
        'order-status-info.subtitle.delivery.route',
        localizationValues,
      ),
      [FlattenedOrderStatuses.Completed]: t(
        'order-status-info.subtitle.delivery.completed',
        localizationValues,
      ),
    })(t('order-status-info.subtitle.unavailable', localizationValues))(
      orderStatus ?? FlattenedOrderStatuses.Received,
    );
  }

  return t('order-status-info.subtitle.unavailable', localizationValues);
};

const useProgressNodes = (
  orderType?: AddressType,
  orderStatus?: FlattenedOrderStatuses,
) => {
  const { t } = useLocalizationContext();

  if (orderType === 'pickup') {
    return [
      {
        id: FlattenedOrderStatuses.Received,
        label: t('order-status-info.progress.pickup.received'),
        completed: [
          FlattenedOrderStatuses.Received,
          FlattenedOrderStatuses.Preparing,
          FlattenedOrderStatuses.Completed,
        ].includes(orderStatus ?? FlattenedOrderStatuses.Failed),
      },
      {
        id: FlattenedOrderStatuses.Preparing,
        label: t('order-status-info.progress.pickup.preparing'),
        completed: [
          FlattenedOrderStatuses.Preparing,
          FlattenedOrderStatuses.Completed,
        ].includes(orderStatus ?? FlattenedOrderStatuses.Failed),
      },
      {
        id: FlattenedOrderStatuses.Completed,
        label: t('order-status-info.progress.pickup.completed'),
        completed: [FlattenedOrderStatuses.Completed].includes(
          orderStatus ?? FlattenedOrderStatuses.Failed,
        ),
      },
    ];
  }

  if (orderType === 'outpost') {
    return [
      {
        id: FlattenedOrderStatuses.Received,
        label: t('order-status-info.progress.outpost.received'),
        completed: [
          FlattenedOrderStatuses.Received,
          FlattenedOrderStatuses.Delivering,
          FlattenedOrderStatuses.Completed,
        ].includes(orderStatus ?? FlattenedOrderStatuses.Failed),
      },
      {
        id: FlattenedOrderStatuses.Delivering,
        label: t('order-status-info.progress.outpost.delivering'),
        completed: [
          FlattenedOrderStatuses.Delivering,
          FlattenedOrderStatuses.Completed,
        ].includes(orderStatus ?? FlattenedOrderStatuses.Failed),
      },
      {
        id: FlattenedOrderStatuses.Completed,
        label: t('order-status-info.progress.outpost.completed'),
        completed: [FlattenedOrderStatuses.Completed].includes(
          orderStatus ?? FlattenedOrderStatuses.Failed,
        ),
      },
    ];
  }

  if (orderType === 'delivery') {
    return [
      {
        id: FlattenedOrderStatuses.Received,
        label: t('order-status-info.progress.delivery.received'),
        completed: [
          FlattenedOrderStatuses.Received,
          FlattenedOrderStatuses.Preparing,
          FlattenedOrderStatuses.ReadyForCourier,
          FlattenedOrderStatuses.Delivering,
          FlattenedOrderStatuses.Completed,
        ].includes(orderStatus ?? FlattenedOrderStatuses.Failed),
      },
      {
        id: FlattenedOrderStatuses.Preparing,
        label: t('order-status-info.progress.delivery.preparing'),
        completed: [
          FlattenedOrderStatuses.Preparing,
          FlattenedOrderStatuses.ReadyForCourier,
          FlattenedOrderStatuses.Delivering,
          FlattenedOrderStatuses.Completed,
        ].includes(orderStatus ?? FlattenedOrderStatuses.Failed),
      },
      {
        id: FlattenedOrderStatuses.ReadyForCourier,
        label: t('order-status-info.progress.delivery.courier'),
        completed: [
          FlattenedOrderStatuses.ReadyForCourier,
          FlattenedOrderStatuses.Delivering,
          FlattenedOrderStatuses.Completed,
        ].includes(orderStatus ?? FlattenedOrderStatuses.Failed),
      },
      {
        id: FlattenedOrderStatuses.Delivering,
        label: t('order-status-info.progress.delivery.delivering'),
        completed: [
          FlattenedOrderStatuses.Delivering,
          FlattenedOrderStatuses.Completed,
        ].includes(orderStatus ?? FlattenedOrderStatuses.Failed),
      },
    ];
  }

  return [];
};

// ─── Utils ──────────────────────────────────────────────────────────────────────

const checkIfShouldShowProgressBar = (orderStatus?: FlattenedOrderStatuses) =>
  orderStatus !== FlattenedOrderStatuses.Completed &&
  orderStatus !== FlattenedOrderStatuses.Failed;

// ─── Types ──────────────────────────────────────────────────────────────────────

type OrderStatusInfoProps = Readonly<{
  orderId?: string;
  orderTotal?: number;
  orderType?: AddressType;
  orderStatus?: FlattenedOrderStatuses;
  vendorId?: string;
  wantedTime?: string;
  estimatedDeliveryTime?: string;
  locationName?: string;
  locationAddress?: string;
  isTrackable: boolean;
  isPendingFeedback: boolean;
  isPast: boolean;
  onReorder: () => void;
}>;

type OrderProgressBarProps = Pick<
  OrderStatusInfoProps,
  'orderType' | 'orderStatus'
>;

type OrderStatusInfoHeaderProps = ViewProps &
  OrderStatusTitleProps &
  OrderStatusNumberProps;

type OrderStatusTitleProps = Readonly<{
  title: string;
}>;

type OrderStatusDetailsProps = Readonly<{
  details: string;
}>;

type OrderStatusNumberProps = Pick<OrderStatusInfoProps, 'vendorId'>;

type UseOrderStatusTitleProps = Pick<
  OrderStatusInfoProps,
  'orderStatus' | 'orderType' | 'isTrackable' | 'isPast'
>;

type UseOrderStatusDetailsProps = Pick<
  OrderStatusInfoProps,
  | 'wantedTime'
  | 'estimatedDeliveryTime'
  | 'locationAddress'
  | 'locationName'
  | 'orderId'
  | 'orderType'
  | 'orderStatus'
  | 'orderTotal'
  | 'isTrackable'
  | 'isPendingFeedback'
  | 'isPast'
>;

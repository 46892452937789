import { type OrderingLedger } from '../../machines/ordering-machine.types';

export const sumLedgerTotal = (
  ledger: Partial<OrderingLedger> | undefined,
  tip: number,
  availableCredit: number,
  shouldUseCredit: boolean,
) => {
  const totalWithoutCredit = sumLedger(ledger, tip, 0);

  const creditToBeApplied = Math.min(totalWithoutCredit, availableCredit);
  const credit = shouldUseCredit ? creditToBeApplied : 0;

  const subtotal = ledger?.subtotal ?? 0;
  const discountTotal = ledger?.discountsTotal ?? 0;

  const total = sumLedger(ledger, tip, credit);

  return { total, subtotal, discountTotal, credit, creditToBeApplied };
};

const sumLedger = (
  ledger: Partial<OrderingLedger> | undefined,
  tip: number,
  credit: number,
) => {
  if (!ledger) return 0;

  return (
    Number(ledger.subtotal ?? 0) +
    Number(ledger.tax ?? 0) +
    tip +
    Number(ledger.feesTotal ?? 0) -
    credit -
    Number(ledger.discountsTotal ?? 0)
  );
};

import { useNetworkMonitor } from '@sg/garnish';

import { useGlobalAppState } from '../../context/GlobalAppState/GlobalAppState';

// To keep in line with the {useGlobalAppState} usage.
export const useNetworkSubscription = () => {
  const { networkMonitorMachineRef } = useGlobalAppState();

  useNetworkMonitor(networkMonitorMachineRef);
};

import React from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { format } from 'date-fns';
import { HighlightWords, theme, useResponsive } from '@sg/garnish';

import { GiftCardConfirmationDeliveryInfoCTAs } from './components';
import { giftCardConfirmationDeliveryInfoMessages as messages } from './GiftCardConfirmationDeliveryInfo.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardConfirmationDeliveryInfo = (
  props: GiftCardConfirmationDeliveryInfoProps,
) => {
  const { email, date, redemptionUrl, message } = props;

  const { match } = useResponsive();

  const shouldRenderMessageOptionCTAs = !email;

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerResponsiveStyles = match([
    styles.containerXS,
    styles.containerSM,
  ]);
  const containerStyles = [styles.container, containerResponsiveStyles];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles}>
      <GiftCardConfirmationDeliveryInfoText email={email} date={date} />

      {shouldRenderMessageOptionCTAs ? (
        <GiftCardConfirmationDeliveryInfoCTAs
          redemptionURL={redemptionUrl}
          message={message}
        />
      ) : null}
    </View>
  );
};

// ─────────────────────────────────────────────────────────────────────

export const GiftCardConfirmationDeliveryInfoText = (
  props: Pick<GiftCardConfirmationDeliveryInfoProps, 'email' | 'date'>,
) => {
  const { email, date } = props;

  const { formatMessage } = useIntl();

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldRenderEmailOptionText = typeof email === 'string';
  const shouldRenderScheduledEmailOptionText =
    shouldRenderEmailOptionText && typeof date === 'string';

  // ─── "Scheduled Email" option ────────────────────────────────────────

  if (shouldRenderScheduledEmailOptionText) {
    const parsedDate = new Date(date);
    const formattedDate = format(parsedDate, 'LLLL d, yyyy');
    const formattedTime = format(parsedDate, 'h:mmaaa');

    const text = formatMessage(messages.scheduledEmailOptionText, {
      email,
      date: formattedDate,
      time: formattedTime,
    });

    return (
      <HighlightWords sizeMatch={['14']} text={text} highlights={[email]} />
    );
  }

  // ─── "Email" option ──────────────────────────────────────────────────

  if (shouldRenderEmailOptionText) {
    const text = formatMessage(messages.emailOptionText, { email });

    return (
      <HighlightWords sizeMatch={['14']} text={text} highlights={[email]} />
    );
  }

  // ─── "Message" option ────────────────────────────────────────────────

  return (
    <HighlightWords
      sizeMatch={['14']}
      text={formatMessage(messages.messageOptionText)}
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTEST,
    borderRadius: theme.radius.large,
    padding: theme.spacing['6'],
  },
  containerXS: {
    marginBottom: theme.spacing['4'],
  },
  containerSM: {
    marginBottom: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardConfirmationDeliveryInfoProps = {
  email: string | undefined | null;
  date: string | undefined | null; // ISO date string
  redemptionUrl: string;
  message: string | undefined | null;
};

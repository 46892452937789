import type { ComponentProps } from 'react';
import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme, TYPE_CONFIG } from '@garnish/constants';

import { useResponsive } from '../../hooks';
import { slugify } from '../../utils';
import { Card } from '../Card';
import { Image } from '../Image';
import { ProductIngredientsList } from '../ProductIngredientsList';
import { TagAlert } from '../TagAlert';
import { TagLabel } from '../TagLabel';
import { BodyText } from '../Text';

export const RecommendationProductCard = React.memo(
  (props: RecommendationProductCardProps) => {
    const {
      name,
      label,
      description,
      restrictionNotice,
      imageSrc,
      addedIngredients = [],
      addedIngredientsLabel,
      removedIngredients = [],
      removedIngredientsLabel,
      FooterElement,
      accessibilityLabel,
      accessibilityHint,
      accessibilityRole = 'button',
      isDisabled,
      onCardPress,
    } = props;

    const { titleStyle, sizeStyles } = useConditionalCardStyles();

    const { match } = useResponsive();
    const testIDPrefix = slugify(name);
    const wrapperTestID = `${testIDPrefix}-product-card`;
    const pressableTestID = `${testIDPrefix}-product-card-pressable`;
    const productNameTestID = `${testIDPrefix}-product-name`;

    const hasIngredientsList =
      addedIngredients.length + removedIngredients.length > 0;

    // ─────────────────────────────────────────────────────────────────

    return (
      <View style={styles.cardWrapper} testID={wrapperTestID}>
        {/* ========== card area ========== */}

        <Card
          onPress={onCardPress}
          style={[styles.pressableWrapper, sizeStyles]}
          testID={pressableTestID}
          accessibilityRole={accessibilityRole}
          accessibilityLabel={accessibilityLabel}
          accessibilityHint={accessibilityHint}
          disabled={isDisabled}
          backgroundColor={theme.colors.GREEN_5}
          backgroundColorHover={theme.colors.GREEN_5_HOVER}
        >
          <View style={styles.header}>
            {/* ========== label ========== */}

            <View style={styles.row}>
              {label ? (
                <View style={styles.labelWrapper}>
                  <TagLabel
                    size={3}
                    palette={isDisabled ? 'tertiary' : 'primary'}
                  >
                    {label}
                  </TagLabel>
                </View>
              ) : null}
            </View>

            <Image
              source={{ uri: imageSrc }}
              aria-label={name}
              contentFit="contain"
              accessibilityHint={name}
              style={match([styles.imageXS_SM, styles.imageMD_LG])}
            />
          </View>

          {/* ========== product name ========== */}

          <BodyText
            size={2}
            testID={productNameTestID}
            bold={true}
            style={titleStyle}
          >
            {name}
          </BodyText>

          {/* ========== ========== ========== */}

          {hasIngredientsList || description ? (
            <View style={styles.ingredientsAndDescriptionWrapper}>
              {/* ========== ingredients ========== */}

              {hasIngredientsList ? (
                <View style={styles.ingredientsListWrapper}>
                  <ProductIngredientsList
                    addedIngredients={addedIngredients}
                    addedIngredientsLabel={addedIngredientsLabel}
                    removedIngredients={removedIngredients}
                    removedIngredientsLabel={removedIngredientsLabel}
                    sizeMatch={['14', '16']}
                  />
                </View>
              ) : null}

              {/* ========== description ========== */}

              {description ? (
                <BodyText sizeMatch={['14', '16']}>{description}</BodyText>
              ) : null}
            </View>
          ) : null}

          {/* ========== restriction notice ========== */}

          {restrictionNotice ? (
            <View style={styles.restrictionNoticeWrapper}>
              <TagAlert size="medium" palette="caution">
                {restrictionNotice}
              </TagAlert>
            </View>
          ) : null}

          {FooterElement}
        </Card>
      </View>
    );
  },
);

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type RecommendationProductCardProps = Readonly<{
  name: string;
  isDisabled?: boolean;

  /**
   * (e.g. "Seasonal", "Warm")
   */
  label?: string;

  /**
   * Product description or ingredients list
   */
  description?: string;

  /**
   * (e.g. "May contain gluten")
   */
  restrictionNotice?: string;

  /**
   * Product remote image URL (e.g. https://res.cloudinary.com/sweetgreen/image...)
   */
  imageSrc?: string;

  /**
   * Optional footer element.
   */
  FooterElement?: React.ReactNode;

  /**
   * Card click/press handler
   */
  onCardPress?: () => void;
}> &
  ViewProps &
  Partial<IngredientsListProps>;

type IngredientsListProps = Pick<
  ComponentProps<typeof ProductIngredientsList>,
  | 'addedIngredients'
  | 'addedIngredientsLabel'
  | 'removedIngredients'
  | 'removedIngredientsLabel'
>;

// ─── Constants ───────────────────────────────────────────────────────────────

const IMAGE_SIZE_XS_SM = 311;
const IMAGE_SIZE_MD_LG = 274;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  imageXS_SM: {
    width: '100%',
    height: IMAGE_SIZE_XS_SM,
  },
  imageMD_LG: {
    width: '100%',
    height: IMAGE_SIZE_MD_LG,
  },
  cardWrapper: {
    position: 'relative',
    flexGrow: 1,
  },
  titleXS: {
    ...TYPE_CONFIG.BODY['18'],
  },
  pressableWrapper: {
    width: '100%',
    padding: theme.spacing['4'],
    flexGrow: 1,
  },
  pressableWrapperTabletUp: {
    padding: theme.spacing['6'],
  },
  header: {
    position: 'relative',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing['4'],
  },
  headerIcon: {
    marginLeft: 'auto',
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  labelWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  restrictionNoticeWrapper: {
    paddingTop: theme.spacing['4'],
  },
  unavailableNoticeWrapper: {
    paddingTop: theme.spacing['2'],
  },
  footer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 'auto',
    paddingTop: theme.spacing['4'],
  },
  ingredientsAndDescriptionWrapper: {
    paddingTop: theme.spacing['2'],
  },
  ingredientsListWrapper: {
    paddingBottom: theme.spacing['1'],
  },
  quantity: {
    paddingTop: theme.spacing['1'],
  },
});

// ─── HOOKS ──────────────────────────────────────────────────────────────────────

/**
 * Returns styles based on viewport or hover/focus states.
 */
const useConditionalCardStyles = () => {
  const { match } = useResponsive();

  const titleStyle = match([styles.titleXS, {}]);
  const sizeStyles = match([{}, styles.pressableWrapperTabletUp]);

  return {
    sizeStyles,
    titleStyle,
  };
};

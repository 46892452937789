import React from 'react';

import { Image } from '../Image';
import type { ImageProps } from '../Image.types';
import { getContentfulImageSources } from './getContentfulImageSources';

// ────────────────────────────────────────────────────────────────────────────────

export const ContentfulImage = React.memo((props: ContentfulImageProps) => {
  const { baseUrl, options, ...rest } = props;

  const sources = React.useMemo(() => {
    return getContentfulImageSources(baseUrl, options);
  }, [baseUrl, options]);

  return <Image source={sources} {...rest} />;
});

// ─── Types ──────────────────────────────────────────────────────────────────────

type ContentfulImageProps = Readonly<{
  baseUrl: string;
  options: Parameters<typeof getContentfulImageSources>[1];
}> &
  Omit<ImageProps, 'source'>;

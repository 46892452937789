import React, { memo, useEffect } from 'react';
import { useToggleState } from '@sg/garnish';

import { type LocationResultsListsV2Props } from '../../LocationResultsListsV2.types';
import {
  LocationResultsListsV2Collapsed,
  LocationResultsListsV2Expanded,
} from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsListsV2XS = memo(
  (props: LocationResultsListsV2Props) => {
    const {
      variation,
      selectedLocationSearchType,
      recentLocations = [],
      nearbyLocations = { pickup: [], outpost: [] },
      locations = [],
      isLoading,
      isExpandedByDefault,
      interactedLocationRestaurantSlug,
      interactedLocationDeliveryAddressId,
      isUsingSearchField,
      focusedLocationId,
      onLocationCardFocus,
      onLocationCardPress,
      onDeliveryLocationCardPress,
      onRecentOrNearbyLocationCardPress,
    } = props;

    // ─── Derived Data ────────────────────────────────────────────────────

    const allLocations = [
      ...recentLocations,
      ...(selectedLocationSearchType === 'pickup'
        ? nearbyLocations.pickup
        : []),
      ...(selectedLocationSearchType === 'outpost'
        ? nearbyLocations.outpost
        : []),
      ...locations,
    ];
    const canBeExpanded =
      allLocations.length > 1 && selectedLocationSearchType !== 'delivery';

    const focusedLocation =
      allLocations.find((location) => location.id === focusedLocationId) ??
      allLocations[0];

    // ─── State ───────────────────────────────────────────────────────────

    const {
      value: isExpanded,
      toggleOn: expand,
      toggleOff: collapse,
    } = useToggleState(isExpandedByDefault || isUsingSearchField);

    // ─── Effects ─────────────────────────────────────────────────────────

    // Expand when the user searches for locations in the search field.
    useEffect(() => {
      if (!isUsingSearchField) return;

      expand();
    }, [expand, isUsingSearchField]);

    // ─────────────────────────────────────────────────────────────────────

    if (isExpanded) {
      return (
        <LocationResultsListsV2Expanded
          variation={variation}
          selectedLocationSearchType={selectedLocationSearchType}
          recentLocations={recentLocations}
          nearbyLocations={nearbyLocations}
          locations={locations}
          isLoading={isLoading}
          interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
          interactedLocationDeliveryAddressId={
            interactedLocationDeliveryAddressId
          }
          onCollapse={collapse}
          onRecentOrNearbyLocationCardPress={onRecentOrNearbyLocationCardPress}
          focusedLocationId={focusedLocationId}
          onLocationCardFocus={onLocationCardFocus}
          onLocationCardPress={onLocationCardPress}
          onDeliveryLocationCardPress={onDeliveryLocationCardPress}
        />
      );
    }

    return (
      <LocationResultsListsV2Collapsed
        variation={variation}
        selectedLocationSearchType={selectedLocationSearchType}
        focusedLocation={focusedLocation}
        canBeExpanded={canBeExpanded}
        isLoading={isLoading}
        interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
        interactedLocationDeliveryAddressId={
          interactedLocationDeliveryAddressId
        }
        onExpand={expand}
        onRecentOrNearbyLocationCardPress={onRecentOrNearbyLocationCardPress}
        onLocationCardPress={onLocationCardPress}
        onDeliveryLocationCardPress={onDeliveryLocationCardPress}
      />
    );
  },
);

import React, { useCallback } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { LI } from '@expo/html-elements';
import { Button, LabelText, theme, useResponsive } from '@sg/garnish';

import { useTelemetry } from '@order/Telemetry';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardsCategoriesNavListItem = (
  props: GiftCardsCategoriesNavListItemProps,
) => {
  const {
    children: filterName,
    id,
    isActive,
    accessibilityLabel,
    onPress,
  } = props;

  const { match } = useResponsive();
  const { track } = useTelemetry();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onNavItemPress = useCallback(() => {
    track('gift.filter_tapped', { filterName });

    if (isActive) {
      onPress(null);

      return;
    }

    onPress(id);
  }, [filterName, id, isActive, onPress, track]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <LI>
      <Button
        palette={isActive ? 'primaryOutline' : 'muted'}
        size={match(['small', 'medium'])}
        style={match([
          styles.navItemBtnXS,
          styles.navItemBtnSM,
          styles.navItemBtnMD,
        ])}
        accessibilityLabel={accessibilityLabel}
        onPress={onNavItemPress}
      >
        <LabelText sizeMatch={['10', '14']}>{filterName}</LabelText>
      </Button>
    </LI>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  navItemBtnXS: {
    paddingHorizontal: theme.spacing['4'],
    paddingBottom: Platform.select({
      web: theme.spacing['1'],
      default: undefined,
    }),
  },
  navItemBtnSM: {
    paddingHorizontal: theme.spacing['4'],
  },
  navItemBtnMD: {
    paddingHorizontal: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardsCategoriesNavListItemProps = {
  id: string;
  children: string;
  accessibilityLabel: string;
  isActive: boolean;
  onPress: (id: string | null) => void;
};

import React, { useCallback, useState } from 'react';
import type { PressableProps } from 'react-native';
import { Pressable, StyleSheet, View } from 'react-native';
import useMergedRef from '@react-hook/merged-ref';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../hooks';
import { CollapseIndicatorIcon, Collapsible } from '../Collapsible';
import { Icon } from '../Icon';
import { ResponsiveSelect } from '../ResponsiveSelect';
import { Stepper } from '../Stepper';
import { Switch } from '../Switch';
import type { TagAlert } from '../TagAlert';
import type { ListActionProps } from './ListAction';
import { ListAction } from './ListAction';

// ────────────────────────────────────────────────────────────────────────────────

export const ListActionInfo = (props: ListActionInfoProps) => {
  const { title, notice, children, ...listActionProps } = props;

  return (
    <ListAction {...listActionProps}>
      <ListAction.Title>{title}</ListAction.Title>
      {children}
      <ListAction.Notice>{notice}</ListAction.Notice>
    </ListAction>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

export const ListActionPressable = React.forwardRef<
  View,
  ListActionInfoProps & PressableProps
>((props, ref) => {
  const {
    testID,
    title,
    notice,
    noticePalette,
    iconName,
    withCaret,
    children,
    accessibilityLabel,
    accessibilityHint,
    boldTitle,
    onPress,
  } = props;
  const pressableRef = React.useRef(null);
  const { isInteracting } = usePressableState(pressableRef);

  return (
    <Pressable
      ref={useMergedRef(pressableRef, ref)}
      testID={testID ?? `sg-list-action-pressable-${title}`}
      accessibilityRole="button"
      accessibilityLabel={accessibilityLabel ?? title}
      accessibilityHint={accessibilityHint ?? title}
      onPress={onPress}
    >
      <ListAction.Wrapper>
        <ListAction.Icon iconName={iconName} />

        <ListAction.Content>
          <ListAction.Title underline={isInteracting} bold={boldTitle}>
            {title}
          </ListAction.Title>
          {children}
          <ListAction.Notice palette={noticePalette}>
            {notice}
          </ListAction.Notice>
        </ListAction.Content>

        {withCaret ? (
          <Icon
            width={18}
            height={18}
            name="IconCaretRight"
            color={theme.colors.NEUTRAL_2}
          />
        ) : null}
      </ListAction.Wrapper>
    </Pressable>
  );
});

// ────────────────────────────────────────────────────────────────────────────────

export const ListActionSwitch = (props: ListActionSwitchProps) => {
  const { iconName, title, text, notice, ...switchProps } = props;

  return (
    <ListAction iconName={iconName}>
      <View style={styles.templateContent}>
        <View style={styles.templateContentInner}>
          <ListAction.Title>{title}</ListAction.Title>
          <ListAction.Text>{text}</ListAction.Text>
          <ListAction.Notice>{notice}</ListAction.Notice>
        </View>

        <View>
          <Switch testID="sg-list-action-switch-control" {...switchProps} />
        </View>
      </View>
    </ListAction>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

export const ListActionStepper = (props: ListActionStepperProps) => {
  const { iconName, title, text, notice, ...stepperProps } = props;

  return (
    <ListAction iconName={iconName}>
      <View style={styles.templateContent}>
        <View style={styles.templateContentInner}>
          <ListAction.Title>{title}</ListAction.Title>
          <ListAction.Text>{text}</ListAction.Text>
          <ListAction.Notice>{notice}</ListAction.Notice>
        </View>

        <View>
          <Stepper {...stepperProps} />
        </View>
      </View>
    </ListAction>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

export const ListActionSelect = (props: ListActionSelectProps) => {
  const {
    iconName,
    title,
    modalTitle,
    notice,
    value,
    options,
    isLoading,
    noticePalette = 'neutral',
    submitBtnLabel,
    accessibilityLabel,
    accessibilityLabelLeftGroup,
    accessibilityLabelRightGroup,
    onSelect,
  } = props;

  return (
    <ListAction iconName={iconName}>
      <ResponsiveSelect
        value={value}
        label={title}
        modalTitle={modalTitle}
        options={options}
        submitBtnLabel={submitBtnLabel}
        isLoading={isLoading}
        accessibilityLabel={accessibilityLabel}
        accessibilityLabelLeftGroup={accessibilityLabelLeftGroup}
        accessibilityLabelRightGroup={accessibilityLabelRightGroup}
        onSelect={onSelect}
      />

      <ListAction.Notice palette={noticePalette}>{notice}</ListAction.Notice>
    </ListAction>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

export const ListActionCollapsible = (props: ListActionCollapsibleProps) => {
  const { title, text, notice, children, onChange, ...listActionProps } = props;
  const [expanded, setExpanded] = useState(false);
  const handleExpandedStateChange = useCallback(
    (expandedState: boolean) => {
      setExpanded(expandedState);
      onChange?.(expandedState);
    },
    [onChange],
  );

  return (
    <Collapsible
      options={{ hideIcon: true }}
      onChange={handleExpandedStateChange}
    >
      <Collapsible.Summary>
        <ListAction hasPressableStyles {...listActionProps}>
          <View style={styles.templateContent}>
            <View style={styles.templateContentInner}>
              <ListAction.Title>{title}</ListAction.Title>
              <ListAction.Text>{text}</ListAction.Text>
              <ListAction.Notice>{notice}</ListAction.Notice>
            </View>

            <CollapseIndicatorIcon expanded={expanded} />
          </View>
        </ListAction>
      </Collapsible.Summary>

      <Collapsible.Details>{children}</Collapsible.Details>
    </Collapsible>
  );
};

//
// ─── TYPES ──────────────────────────────────────────────────────────────────────
//

type ListActionTemplateProps = Readonly<{
  title?: string;
  text?: string;
  notice?: string;
  noticePalette?: React.ComponentProps<typeof TagAlert>['palette'];
  withCaret?: boolean;
  boldTitle?: boolean;
}> &
  Pick<ListActionProps, 'iconName' | 'children'>;

type ListActionInfoProps = Omit<ListActionTemplateProps, 'text'>;

type ListActionSwitchProps = React.ComponentProps<typeof Switch> &
  ListActionTemplateProps;

type ListActionStepperProps = ListActionTemplateProps &
  React.ComponentProps<typeof Stepper>;

type ListActionSelectProps = Omit<ListActionTemplateProps, 'text'> &
  Omit<React.ComponentProps<typeof ResponsiveSelect>, 'label'>;

type ListActionCollapsibleProps = Readonly<{
  onChange?: (expanded: boolean) => void;
}> &
  ListActionTemplateProps;

//
// ─── STYLES ─────────────────────────────────────────────────────────────────────
//

const styles = StyleSheet.create({
  templateContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  templateContentInner: {
    flex: 1,
    paddingRight: theme.spacing['2'],
  },
});

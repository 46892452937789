import { useCallback, useMemo } from 'react';
import { useInterpret, useSelector } from '@xstate/react';
import { type ContextFrom, type EventFrom } from 'xstate';

import { createFavoritesMachine } from '../../favorites-machine';
import { type FavoriteWithId } from '../../favorites-machine.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A hook to provide an interface to communicate with the favorites state machine.
 */
export const useFavorites = <Favorite extends FavoriteWithId>(
  params: UseFavoritesParams<Favorite>,
) => {
  const { fetchFavorites, toggleFavorite } = params;

  // ─── State Machine ───────────────────────────────────────────────────

  const favoritesMachine = useMemo(
    () => createFavoritesMachine<Favorite>('waiting'),
    [],
  );

  // ─── Machine Service ─────────────────────────────────────────────────

  const service = useInterpret(favoritesMachine, {
    services: { fetchFavorites, toggleFavorite },
  });

  // ─── Context ─────────────────────────────────────────────────────────

  const favorites = useSelector(service, (currentState) => {
    const { context } = currentState;

    return context.favorites;
  });

  const canFetchMoreFavorites = useSelector(service, (currentState) => {
    const { context } = currentState;

    return context.canFetchMoreFavorites;
  });

  const mostRecentFavorite = favorites[0];

  // ─── Flags ───────────────────────────────────────────────────────────

  const isFetchingInitialFavorites = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('waiting') || matches('fetchingInitialFavorites');
  });

  const isReFetchingFavorites = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('refetchingFavorites');
  });

  const isFetchingMoreFavorites = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('fetchingMoreFavorites');
  });

  const isTogglingFavoriteState = useSelector(service, (currentState) => {
    const { matches } = currentState;

    return matches('togglingFavoriteState');
  });

  // ─── Machine Helpers ─────────────────────────────────────────────────

  const fetchInitialFavorites = useCallback(() => {
    service.send('START');
  }, [service]);

  const fetchMoreFavorites = useCallback(() => {
    service.send('FETCH_MORE_FAVORITES');
  }, [service]);

  const refetchFavorites = useCallback(() => {
    service.send('REFETCH_FAVORITES');
  }, [service]);

  const toggleFavoriteLineItem = useCallback(
    (payload: ToggleFavoritePayload) => {
      const { id, productId, isFavorite } = payload;

      service.send({ type: 'TOGGLE_FAVORITE', id, isFavorite, productId });
    },
    [service],
  );

  // ─────────────────────────────────────────────────────────────────────

  return {
    favorites,
    mostRecentFavorite,
    isFetchingInitialFavorites,
    isFetchingMoreFavorites,
    isReFetchingFavorites,
    isTogglingFavoriteState,
    canFetchMoreFavorites,
    fetchMoreFavorites,
    fetchInitialFavorites,
    refetchFavorites,
    toggleFavoriteLineItem,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseFavoritesParams<Favorite extends FavoriteWithId> = {
  fetchFavorites: (
    context: FavoritesMachineContext<Favorite>,
  ) => Promise<{ favorites: Favorite[] }>;
  toggleFavorite: (
    context: FavoritesMachineContext<Favorite>,
    event: Extract<
      EventFrom<ReturnType<typeof createFavoritesMachine<Favorite>>>,
      { type: 'TOGGLE_FAVORITE' }
    >,
  ) => Promise<{ id: string; isFavorite: boolean } | null>;
};

type FavoritesMachineContext<Favorite extends FavoriteWithId> = ContextFrom<
  ReturnType<typeof createFavoritesMachine<Favorite>>
>;

type ToggleFavoritePayload = {
  id: string;
  productId: string;
  isFavorite: boolean;
};

export const otaUpdate = {
  'ota-update.title': 'A new update is available',
  'ota-update.description':
    'Update your app for the best experience and performance',
  'ota-update.restart-and-apply': 'Apply update',
  'ota-update.restart-failed':
    'Not able to restart the app. Please restart it manually to apply the update.',
  'ota-update.update-later': 'Update later',
  'ota-update.update-later-message':
    'The update will be applied the next time you restart the app.',
};

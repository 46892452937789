import { useCallback, useMemo } from 'react';
import mixpanelBrowser from 'mixpanel-browser';

import { getEnvVars } from '@order/utils';

import { mixpanelLogger as logger } from './Mixpanel.logger';
import type { Mixpanel } from './Mixpanel.types';

export const useMixpanel = (): Mixpanel => {
  const { MIXPANEL_PROJECT_TOKEN } = getEnvVars();

  const isDisabled = !MIXPANEL_PROJECT_TOKEN;

  const initialize: Mixpanel['initialize'] = useCallback(async () => {
    if (isDisabled) {
      logger.info('skip initialize()');

      return;
    }

    logger.info('initialize()');

    return new Promise<void>((resolve) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      mixpanelBrowser.init(MIXPANEL_PROJECT_TOKEN, {
        loaded() {
          resolve();
        },
      });
    });
  }, [MIXPANEL_PROJECT_TOKEN, isDisabled]);

  const identify: Mixpanel['identify'] = useCallback(
    (userId) => {
      if (isDisabled) {
        logger.info(`skip identify(${userId})`);

        return;
      }

      logger.info(`identify(${userId})`);

      mixpanelBrowser.identify(userId);
    },
    [isDisabled],
  );

  const reset: Mixpanel['reset'] = useCallback(() => {
    if (isDisabled) {
      logger.info('skip reset()');

      return;
    }

    logger.info('reset()');

    mixpanelBrowser.reset();
  }, [isDisabled]);

  const setSuperProperty: Mixpanel['setSuperProperty'] = useCallback(
    (key, value) => {
      if (isDisabled) {
        logger.info(`skip setSuperProperty(${key}, ${JSON.stringify(value)})`);

        return;
      }

      logger.info(`setSuperProperty(${key}, ${JSON.stringify(value)})`);

      mixpanelBrowser.register({ [key]: value });
    },
    [isDisabled],
  );

  const setUserProperty: Mixpanel['setUserProperty'] = useCallback(
    (key, value) => {
      if (isDisabled) {
        logger.info(`skip setUserProperty(${key}, ${JSON.stringify(value)})`);

        return;
      }

      logger.info(`setUserProperty(${key}, ${JSON.stringify(value)})`);

      mixpanelBrowser.people.set(key, value);
    },
    [isDisabled],
  );

  const track: Mixpanel['track'] = useCallback(
    (eventName, properties) => {
      if (isDisabled) {
        logger.info(`skip track(${eventName}, ...)`);

        return;
      }

      logger.info(`track(${eventName}, ...)`);

      mixpanelBrowser.track(eventName, properties);
    },
    [isDisabled],
  );

  return useMemo(
    () => ({
      initialize,
      identify,
      reset,
      setSuperProperty,
      setUserProperty,
      track,
    }),
    [initialize, identify, reset, setSuperProperty, setUserProperty, track],
  );
};

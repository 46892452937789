import React from 'react';
import { View } from 'react-native';

import type { MeasureViewProps } from './useMeasuredView.types';
import { measureView } from './useMeasuredView.utils';

export const MeasuredView = (props: MeasureViewProps) => {
  const { initialMeasureOnly, width, height, setWidth, setHeight } = props;
  const reference = React.useRef<View>(null);
  const onLayout = React.useCallback(() => {
    measureView({
      reference,
      initialMeasureOnly,
      width,
      height,
      setWidth,
      setHeight,
    });
  }, [reference, initialMeasureOnly, width, height, setWidth, setHeight]);

  return <View ref={reference} onLayout={onLayout} {...props} />;
};

import { useEffect } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

export const usePermanentNoticeBanner = (
  visibility: boolean,
  text: string,
  id: string,
) => {
  const { push: addNoticeBanner, remove: removeNoticeBanner } =
    useNoticeBannersStackContext();

  useEffect(() => {
    if (!visibility) return;

    addNoticeBanner(
      {
        id,
        text,
        palette: 'success',
        autoHideTimeout: 'never',
        // @ts-expect-error TS(2322): Type '(id: string) => void' is not assignable to t... Remove this comment to see the full error message
        onClose: removeNoticeBanner,
      },
      true,
    );

    return () => {
      removeNoticeBanner(id);
    };
  }, [id, visibility, text, addNoticeBanner, removeNoticeBanner]);

  return { dismiss: removeNoticeBanner };
};

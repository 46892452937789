import { Platform } from 'react-native';
import * as Location from 'expo-location';
import { getCurrentPositionAsync } from 'expo-location';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Attempts to resolve the current location by using a location service.
 *
 * @see https://docs.expo.dev/versions/latest/sdk/location/ Expo Location
 */
export async function getCurrentPosition(
  shouldUseLastKnownPosition?: boolean,
): Promise<Location.LocationObject> {
  const { granted } = await Location.requestForegroundPermissionsAsync();

  // The error should be caught in the state machine, so it can proceed
  // to another state.
  if (!granted) {
    throw new Error('The user did not provide location permissions.');
  }

  // ─────────────────────────────────────────────────────────────────────

  if (shouldUseLastKnownPosition) {
    const lastKnownPosition = await Location.getLastKnownPositionAsync({
      maxAge: 60 * 1000 * 1000, // 1 hour
      requiredAccuracy: 500, // meters
    });

    if (lastKnownPosition) {
      return lastKnownPosition;
    }
  }

  // ─────────────────────────────────────────────────────────────────────

  if (Platform.OS === 'android') {
    return getCurrentPositionAsyncWithTimeout();
  }

  return getCurrentPositionAsync();
}

/**
 * Attempts to resolve the current location by using a location service and
 * returns `null` as a fallback value.
 *
 * @see https://docs.expo.dev/versions/latest/sdk/location/ Expo Location
 */
export async function safelyGetCurrentPosition(): Promise<Location.LocationObject | null> {
  try {
    return await getCurrentPosition(true);
  } catch {
    return null;
  }
}

// ─────────────────────────────────────────────────────────────────────────────

/**
 * On Android, there are certain known flaws that cause the app to hang in the
 * position resolving state, which can be very problematic.
 *
 * To avoid having such "frozen" condition, we utilize a timeout and retry
 * strategy.
 *
 * - Timeout is used to prevent the state machine from hanging in that state.
 * - And retry to attempt to resolve the position one more time, because there
 *   are cases where the position is immediately resolved on the 2nd call.
 *
 * @see https://github.com/expo/expo/issues/10756
 * @see https://github.com/expo/expo/issues/15478
 */
async function getCurrentPositionAsyncWithTimeout(attempt = 1) {
  const location: Location.LocationObject | null = await Promise.race([
    getCurrentPositionAsync(),
    getCurrentPositionAsyncTimeout(),
  ]);

  const canDoAnotherAttempt = attempt < GET_CURRENT_POSITION_RETRY_MAX_ATTEMPTS;
  const shouldRetry = !location && canDoAnotherAttempt;

  if (shouldRetry) {
    return getCurrentPositionAsyncWithTimeout(attempt + 1);
  }

  if (!location) {
    throw new Error('Unable to get the current position.');
  }

  return location;
}

/**
 * A basic sleep function that generates a timeout for the current position
 * resolving process.
 */
async function getCurrentPositionAsyncTimeout() {
  return new Promise<null>((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, GET_CURRENT_POSITION_TIMEOUT);
  });
}

// ─── Constants ───────────────────────────────────────────────────────────────

const GET_CURRENT_POSITION_TIMEOUT = 2000;
const GET_CURRENT_POSITION_RETRY_MAX_ATTEMPTS = 2;

import { useCounterMachineInterpreter } from '@sg/machines';

import { useGlobalAppState } from '../GlobalAppState';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A simple custom hook for interacting with the placed orders counter machine.
 */
export const usePlacedOrdersCounter = () => {
  const { placedOrderCounterMachineRef: interpreter } = useGlobalAppState();
  const { counterValue, incrementCounter, isReady } =
    useCounterMachineInterpreter(interpreter);

  // ─────────────────────────────────────────────────────────────────────

  return {
    isReady,
    placedOrdersCount: counterValue,
    incrementPlacedOrdersCounter: incrementCounter,
  };
};

import React from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';

// ─────────────────────────────────────────────────────────────────────────────

export const ActiveIngredientContainer = (props: ViewProps) => {
  const { children, style, ...restProps } = props;

  return (
    <View style={[styles.container, style]} {...restProps}>
      {children}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
});

/* eslint-disable functional/immutable-data */

import { useRef } from 'react';
import { useInterpret } from '@xstate/react';
import { useClient } from 'urql';
import type { ActorRefFrom } from 'xstate';

import type { CreateAuthMachineOptions } from './AuthMachine';
import { createAuthMachine } from './AuthMachine';
import { AuthStorage } from './AuthStorage';

export type AuthMachineRefType = ActorRefFrom<
  ReturnType<typeof createAuthMachine>
>;

export const useInitAuthMachine = () => {
  const options = useCreateAuthMachineOptions();
  const { current: AuthMachine } = useRef(createAuthMachine(options));

  const authMachineRef = useInterpret(AuthMachine, {
    actions: {
      async saveCsrfTokenToStorage(_context, event) {
        if (!event.csrfToken) return;

        await AuthStorage.setToken(event.csrfToken);
      },
    },
  });

  return authMachineRef;
};

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A simple hook to return dynamic options (always up-to-date) for the `createAuthMachine`.
 */
const useCreateAuthMachineOptions = () => {
  const client = useClient();

  const options = useRef<CreateAuthMachineOptions>({ client });

  // NOTE: Because these values are dynamic, we update them on every render to ensure that
  //       the machine is utilizing the correct references over the time.

  options.current.client = client;

  return options.current;
};

import type { ComponentProps } from 'react';
import React, { memo, useCallback, useMemo } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import type { PinData, Region } from '@sg/garnish';
import {
  BodyText,
  Button,
  CloudinaryImage,
  findCenterRegion,
  getIosModalPresentationConfig,
  Map,
  Modal,
  TagAlert,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { LOCATION_CARD_IMG_BG_COLOR } from '../../../../LocationResults.constants';
import type {
  Location,
  OnSelectedLocationCardPress,
} from '../../LocationResultCard.types';
import { LocationResultCardCTAs } from '../LocationResultCardCTAs';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultCardDetailsModal = memo(
  (props: LocationResultCardModalProps) => {
    const {
      location,
      locationOrderChannel,
      addressDetails,
      visible,
      isLoading = false,
      isDisabled = false,
      onRequestClose,
      onOrderNowBtnPress,
    } = props;
    const {
      name,
      imageUrl,
      acceptingOrders: isAcceptingOrders = true,
      isClosed,
      isOutpost,
      flexMessage,
      notAcceptingOrdersReason,
    } = location;

    const { t } = useLocalizationContext();
    const { match, minWidth, currentBreakpoint } = useResponsive();

    const ctaLabelForPickupOutpostLocation = isAcceptingOrders
      ? t('location.order-now')
      : t('location.closed');
    const ctaLabelForDeliveryLocation = isClosed
      ? t('location.results.card.order-later')
      : t('location.results.card.order-now');
    const ctaLabel =
      locationOrderChannel === 'delivery'
        ? ctaLabelForDeliveryLocation
        : ctaLabelForPickupOutpostLocation;

    // NOTE: Because many outpost locations lack high-quality photos, we render only pickup locations images.
    const shouldRenderLocationImg = imageUrl !== undefined && !isOutpost;

    const shouldRenderMap = minWidth.isSM;
    const shouldRenderCTAs = currentBreakpoint.isXS;

    // ─── Helpers ─────────────────────────────────────────────────────────

    const handleOrderNowBtnPress = useCallback(() => {
      onOrderNowBtnPress(location, { entryPoint: 'details' });
    }, [location, onOrderNowBtnPress]);

    // ─── Styles ──────────────────────────────────────────────────────────

    const innerContainerStyles = [
      styles.modalInnerContainer,
      match([undefined, styles.modalInnerContainerSM]),
    ];

    // ─────────────────────────────────────────────────────────────────────

    return (
      <Modal
        visible={visible}
        accessibilityLabel={t('location.details.title')}
        size="large"
        offset={match([undefined, '10%'])}
        onRequestClose={onRequestClose}
        maxWidth={1024}
        {...getIosModalPresentationConfig()}
      >
        <View style={innerContainerStyles}>
          {shouldRenderMap ? (
            <View
              style={[
                styles.mapContainer,
                match([undefined, styles.mapContainerSM]),
              ]}
            >
              <LocationMap location={location} />
            </View>
          ) : null}

          <View
            style={match([
              styles.modalContentContainerXS,
              styles.modalContentContainerSM,
            ])}
          >
            <Modal.CloseBtn onPress={onRequestClose} />

            <ScrollView>
              {/*  ─── Location Image ────────────────────────────────────────────────── */}

              {shouldRenderLocationImg ? (
                <Modal.Row>
                  <View
                    style={[
                      styles.imgContainer,
                      styles.imgContainerWithBgColor,
                    ]}
                  >
                    <CloudinaryImage
                      baseUrl={imageUrl}
                      contentFit="cover"
                      style={styles.img}
                      aria-label={name}
                      config={IMG_CLOUDINARY_CONFIG}
                    />
                  </View>
                </Modal.Row>
              ) : null}

              {/*  ─── Location Info ─────────────────────────────────────────────────── */}

              <Modal.Row style={styles.locationInfoRow}>
                <BodyText
                  bold
                  size={match([1, 2])}
                  style={match([styles.sectionTextXS, styles.sectionTextSM])}
                >
                  {name}
                </BodyText>

                {isAcceptingOrders ? null : (
                  <LocationClosedNotice
                    notAcceptingOrdersReason={notAcceptingOrdersReason}
                  />
                )}

                <LocationInfo
                  location={location}
                  addressDetails={addressDetails}
                />

                {flexMessage ? (
                  <LocationFlexMessage flexMessage={flexMessage} />
                ) : null}
              </Modal.Row>

              {shouldRenderCTAs ? (
                <Modal.Row style={styles.ctaContainer}>
                  <LocationResultCardCTAs location={location} />
                </Modal.Row>
              ) : null}
            </ScrollView>

            {/*  ───────────────────────────────────────────────────────────────────── */}

            <Modal.Footer
              withoutTopBorder={minWidth.isSM}
              style={styles.footer}
            >
              <Button
                onPress={handleOrderNowBtnPress}
                size="large"
                isLoading={isLoading}
                disabled={isDisabled || !isAcceptingOrders}
              >
                {ctaLabel}
              </Button>
            </Modal.Footer>
          </View>
        </View>
      </Modal>
    );
  },
);

// ─── Subcomponents ───────────────────────────────────────────────────────────

const LocationMap = memo(
  (props: Pick<LocationResultCardModalProps, 'location'>) => {
    const { location } = props;

    const {
      name,
      acceptingOrders,
      address,
      id,
      isOutpost,
      lat = 0,
      lng = 0,
      restaurantSlug,
    } = location;

    // ─── Derived Data ────────────────────────────────────────────────────

    const locationPin = useMemo<readonly PinData[]>(() => {
      return [
        {
          id,
          name,
          lat,
          lng,
          address,
          restaurantSlug,
          restaurantName: name,
          acceptingOrders,
          isOutpost,
        },
      ];
    }, [
      acceptingOrders,
      address,
      id,
      isOutpost,
      lat,
      lng,
      name,
      restaurantSlug,
    ]);

    const mapRegion = useMemo<Region>(() => {
      return { ...findCenterRegion(locationPin), zoom: 14 };
    }, [locationPin]);

    return <Map region={mapRegion} pins={locationPin} showControls={false} />;
  },
);

const LocationClosedNotice = memo(
  (props: Pick<Location, 'notAcceptingOrdersReason'>) => {
    const { notAcceptingOrdersReason } = props;

    const { t } = useLocalizationContext();
    const { match } = useResponsive();

    return (
      <>
        <BodyText
          size={match([2, 3])}
          style={[
            styles.closedLabel,
            match([styles.sectionTextXS, styles.sectionTextSM]),
          ]}
        >
          {t('location.closed')}
        </BodyText>

        {notAcceptingOrdersReason ? (
          <View style={match([styles.sectionTextXS, styles.sectionTextSM])}>
            <TagAlert size="small" palette="caution">
              {notAcceptingOrdersReason}
            </TagAlert>
          </View>
        ) : null}
      </>
    );
  },
);

const LocationFlexMessage = memo((props: Pick<Location, 'flexMessage'>) => {
  const { flexMessage } = props;

  const { match } = useResponsive();

  return (
    <BodyText
      size={match([2, 3])}
      style={match([styles.sectionTextXS, styles.sectionTextSM])}
    >
      {flexMessage}
    </BodyText>
  );
});

const LocationInfo = memo(
  (
    props: Pick<LocationResultCardModalProps, 'addressDetails' | 'location'>,
  ) => {
    const { addressDetails, location } = props;
    const { address, storeHours, estimatedDeliveryTime } = location;

    const { match } = useResponsive();

    return (
      <View>
        <BodyText>{address}</BodyText>

        {addressDetails ? (
          <BodyText
            size={match([2, 3])}
            style={match([styles.sectionTextXS, styles.sectionTextSM])}
          >
            {addressDetails}
          </BodyText>
        ) : null}

        {storeHours ? (
          <BodyText
            size={match([2, 3])}
            style={match([styles.sectionTextXS, styles.sectionTextSM])}
          >
            {storeHours}
          </BodyText>
        ) : null}

        {estimatedDeliveryTime ? (
          <BodyText
            size={match([2, 3])}
            style={match([styles.sectionTextXS, styles.sectionTextSM])}
          >
            {estimatedDeliveryTime}
          </BodyText>
        ) : null}
      </View>
    );
  },
);

// ─── Constants ───────────────────────────────────────────────────────────────

const IMG_CLOUDINARY_CONFIG = { height: 200, crop: 'fill' } as const;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  modalInnerContainer: {
    flexDirection: 'row',
    height: '100%',
  },
  modalInnerContainerSM: {
    height: 500,
    maxHeight: '100%',
  },
  modalContentContainerXS: {
    width: '100%',
  },
  modalContentContainerSM: {
    width: 375,
  },
  mapContainer: {
    width: '50%',
    borderRightWidth: 1,
    borderRightColor: theme.colors.DARK_KALE,
  },
  mapContainerSM: {
    flex: 1,
    width: 'auto',
  },
  imgContainer: {
    width: '100%',
    borderRadius: theme.radius.medium,
    marginBottom: theme.spacing['4'],
  },
  imgContainerWithBgColor: {
    backgroundColor: LOCATION_CARD_IMG_BG_COLOR,
  },
  img: {
    width: '100%',
    height: 200,
    borderRadius: theme.radius.medium,
  },
  closedLabel: {
    color: theme.colors.CAUTION,
  },
  locationInfoRow: {
    marginBottom: 'auto',
  },
  sectionTextXS: {
    marginBottom: theme.spacing['2'],
  },
  sectionTextSM: {
    marginBottom: 2,
  },
  ctaContainer: {
    paddingVertical: theme.spacing['2'],
  },
  noticeBannersStack: {
    ...StyleSheet.absoluteFillObject,
    top: 'auto',
  },
  footer: {
    paddingTop: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultCardModalProps = Readonly<{
  location: Location;
  addressDetails?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  locationOrderChannel?: 'pickup' | 'delivery' | 'outpost';
  onOrderNowBtnPress: OnSelectedLocationCardPress;
}> &
  Pick<ComponentProps<typeof Modal>, 'visible' | 'onRequestClose'>;

import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { HorizontalScrollRail, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainRailList = (props: OrderAgainRailListProps) => {
  const { children } = props;

  return (
    <View style={styles.container}>
      <HorizontalScrollRail showNavControls={false} gap={theme.spacing['12']}>
        {children}
      </HorizontalScrollRail>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderAgainRailListProps = {
  children: ComponentProps<typeof HorizontalScrollRail>['children'];
};

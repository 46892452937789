import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, theme } from '@sg/garnish';

import type { SweetpassMembershipLevelSectionProps } from '../hooks';
import { SweetpassMembershipSection } from './SweetpassMembershipScreenSection';

export const SweetpassMembershipLevelSection = (
  props: SweetpassMembershipLevelSectionProps,
) => {
  const { testID, title, headingText, bodyText, planInfo, cta } = props;

  const [isLoading, setLoading] = useState(false);

  const handlePressCta = useCallback(() => {
    if (cta) {
      setLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Nx + ESLint Update 2023-12-10
      cta.handler().finally(() => {
        setLoading(false);
      });
    }
  }, [cta]);

  return (
    <SweetpassMembershipSection testID={testID} content="plain" title={title}>
      <View style={styles.contentRow}>
        <View style={styles.contentInfo}>
          <SweetpassMembershipSection.HeadingText>
            {headingText}
          </SweetpassMembershipSection.HeadingText>

          {planInfo ? (
            <SweetpassMembershipSection.BodyText>
              {planInfo}
            </SweetpassMembershipSection.BodyText>
          ) : null}

          <SweetpassMembershipSection.BodyText>
            {bodyText}
          </SweetpassMembershipSection.BodyText>
        </View>
      </View>

      {cta ? (
        <Button
          testID={testID?.concat('.cta')}
          style={styles.cta}
          palette={cta.palette}
          size="large"
          accessibilityLabel={cta.accessibilityLabel}
          onPress={handlePressCta}
          isLoading={isLoading}
        >
          {cta.title}
        </Button>
      ) : null}
    </SweetpassMembershipSection>
  );
};

// ––– Style –––––––––––––––––––––––––––––––––

const styles = StyleSheet.create({
  contentRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentInfo: {
    flexShrink: 1,
  },
  cta: {
    marginTop: theme.spacing['6'],
  },
});

import React, { useMemo } from 'react';
import { AddressTypes, ListActionSelect } from '@sg/garnish';

import type { DeliveryPreferences } from '@order/components';
import {
  DeliveryAddressListAction,
  DeliveryPreferencesSelector,
} from '@order/components';
import { DeliveryPreferenceType } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';

import type { AddressForm } from '../../../../../components/Address/AddressForm.types';
import { mapAvailableTimes } from '../../../Checkout.utils';
import { useCheckoutContext } from '../../../CheckoutProvider';
import { useFirstAvailableOption, useUpdateAddress } from '../../../hooks';
import { CheckoutBlockVStack } from '../CheckoutBlockVStack';

export const ConnectedDeliveryOptions = () => {
  const context = useCheckoutContext();

  return <DeliveryOptions {...context} />;
};

export const DeliveryOptions = (
  props: Pick<
    ReturnType<typeof useCheckoutContext>,
    | 'orderId'
    | 'cart'
    | 'cartOrderType'
    | 'payload'
    | 'orderAddress'
    | 'deliveryEstimate'
    | 'loadingAvailableWantedTimes'
    | 'availableWantedTimes'
    | 'handleWantedTimeSelected'
  >,
) => {
  const {
    orderId,
    cart,
    cartOrderType,
    payload,
    orderAddress,
    deliveryEstimate,
    loadingAvailableWantedTimes,
    availableWantedTimes: availableWantedTimeOptions,
    handleWantedTimeSelected: setSelectedTime,
  } = props;
  const restaurant = cart?.restaurant;
  const deliveryOrderDetail = cart?.deliveryOrderDetail ?? undefined;
  const { wantedTime: selectedTime } = payload;

  const { submittingAddress, handleUpdateAddress } = useUpdateAddress(
    orderAddress?.id,
  );

  const { t } = useLocalizationContext();

  const timeOptionsGroups = React.useMemo(
    () =>
      mapAvailableTimes({
        t,
        orderType: cartOrderType,
        times: availableWantedTimeOptions,
        deliveryEstimate,
      }),
    [t, cartOrderType, availableWantedTimeOptions, deliveryEstimate],
  );

  useFirstAvailableOption({
    timeOptionsGroups,
    selectedTime,
    setSelectedTime,
  });

  // ──────── Telemetry ─────────
  const { track } = useTelemetry();

  const onTimeChange = React.useCallback(
    (time: string | number) => {
      const delta = new Date(time).getTime() - new Date(selectedTime).getTime();

      track('checkout.time_changed', { timeChanged: delta / (60 * 1000) });

      setSelectedTime(String(time));
    },
    [selectedTime, setSelectedTime, track],
  );

  // ────────────────────────────

  const onSave = React.useCallback(
    (preferences: DeliveryPreferences) => {
      void handleUpdateAddress({
        ...orderAddress,
        deliveryPreference: preferences.type,
        notes: preferences.notes,
      } as AddressForm);
    },
    [orderAddress, handleUpdateAddress],
  );

  // ────────────────────────────

  const deliveryPreferences = useMemo(
    () => ({
      type:
        orderAddress?.deliveryPreference ?? DeliveryPreferenceType.LeaveAtDoor,
      notes: orderAddress?.notes ?? '',
    }),
    [orderAddress?.deliveryPreference, orderAddress?.notes],
  );

  // ────────────────────────────

  if (cartOrderType !== AddressTypes.delivery) return null;

  return (
    <CheckoutBlockVStack testID="checkout-block-delivery">
      <ListActionSelect
        iconName="IconClock"
        title={t('checkout.delivery-time')}
        modalTitle={t('checkout.delivery-time.title')}
        accessibilityLabel={t('checkout.delivery-time')}
        accessibilityLabelLeftGroup={t('checkout.delivery-time.day')}
        accessibilityLabelRightGroup={t('checkout.delivery-time.hour')}
        value={selectedTime}
        options={timeOptionsGroups}
        isLoading={loadingAvailableWantedTimes}
        onSelect={onTimeChange}
      />

      <DeliveryAddressListAction
        orderId={orderId}
        deliveryAddress={orderAddress}
        restaurant={restaurant}
        deliveryOrderDetail={deliveryOrderDetail}
        orderType={cartOrderType}
      />

      <DeliveryPreferencesSelector
        preferences={deliveryPreferences}
        submitting={submittingAddress}
        onSave={onSave}
      />
    </CheckoutBlockVStack>
  );
};

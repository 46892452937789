import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  ButtonQuantityStepper,
  slugify,
  theme,
  useNoticeBannersStackContext,
  useResponsive,
} from '@sg/garnish';

import { ProductCard } from '@order/components';
import { useDietaryRestrictions } from '@order/components';
import type { DeliveryOrderDetailInput, RestaurantQuery } from '@order/graphql';
import { useProductCallbacks } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';
import { telemetryProductFromInput, useTelemetry } from '@order/Telemetry';

// ────────────────────────────────────────────────────────────────────────────────

export const MenuCategoryProduct = (props: MenuCategoryProductProps) => {
  const { product, restaurantSlug, deliveryDetails } = props;

  const {
    name,
    description,
    label,
    asset,
    cost,
    dietaryProperties,
    calories = 0,
    isModifiable,
    outOfStock,
  } = product;

  // ─────────────────────────────────────────────────────────────────

  const { t, formatNumber, formatPrice } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const getRestrictionNotice = useDietaryRestrictions();
  const { track } = useTelemetry();
  const { currentBreakpoint } = useResponsive();

  // ─── HELPERS ─────────────────────────────────────────────────────

  const showProductAddBanner = useCallback(
    ({ product: productToAdd, quantity }: ShowProductAddBannerProps) => {
      if (!currentBreakpoint.isXS) {
        addNoticeBanner({
          text: t('menu.one-click-success', {
            quantity,
            productName: productToAdd.name,
          }),
          palette: 'success',
        });
      }

      const selectedProduct = telemetryProductFromInput({
        ...productToAdd,
        location: restaurantSlug,
        quantity,
      });

      track('menu.one_click_add_to_bag_submit', { selectedProduct });
    },
    [currentBreakpoint.isXS, restaurantSlug, track, addNoticeBanner, t],
  );

  const trackAddToCartFail = useCallback(
    ({ message }: TrackAddToCartFailProps) => {
      track('menu.one_click_add_to_bag_fail', { message });
    },
    [track],
  );

  const { handleCardPress, handleAddToBag, isLoading } = useProductCallbacks({
    product,
    restaurantSlug,
    deliveryAddressId: deliveryDetails?.addressId,
    deliveryDetails,
    onSuccess: showProductAddBanner,
    onError: trackAddToCartFail,
  });

  // ─────────────────────────────────────────────────────────────────

  const restrictionNotice = getRestrictionNotice(dietaryProperties);
  const price = formatPrice(cost, 'USD');
  const caloriesText = `${formatNumber(calories)} ${t(
    'general.calories-short',
  )}`;

  const accessibilityLabel = t('menu.product-card.a11y.label', {
    productName: name,
    description,
    price,
    extra: caloriesText,
  });
  const accessibilityHint = t('menu.product-card.a11y.hint', {
    productName: name,
  });

  // ─────────────────────────────────────────────────────────────────

  const oneClickAddToBagBtnAccessibilityLabel = t(
    'menu.product-card.one-click-add-to-bag.a11y.label',
    {
      productName: name,
    },
  );
  const oneClickAddToBagBtnAccessibilityHint = t(
    'menu.product-card.one-click-add-to-bag.a11y.hint',
    {
      productName: name,
    },
  );
  const oneClickAddToBagLoadingAccessibilityLabel = t(
    'menu.product-card.one-click-add-to-bag-loading.a11y.label',
    {
      productName: name,
    },
  );

  const [isStepperVisible, setIsStepperVisible] = useState(false);

  const handleStepperVisibilityChange = useCallback(
    (isVisible: boolean) => {
      if (isVisible) {
        track('menu.one_click_add_to_bag');
      }

      setIsStepperVisible(isVisible);
    },
    [setIsStepperVisible, track],
  );

  const handleQuantityIncrease = useCallback(() => {
    track('menu.one_click_add_to_bag_quantity_increase');
  }, [track]);

  const handleQuantityDecrease = useCallback(() => {
    track('menu.one_click_add_to_bag_quantity_decrease');
  }, [track]);

  // ─────────────────────────────────────────────────────────────────

  const shouldRenderOneClickAddToBag = !isModifiable;

  // ─────────────────────────────────────────────────────────────────

  return (
    <View style={styles.productCardWrapper}>
      <ProductCard
        restrictionNotice={restrictionNotice}
        name={name}
        description={description}
        label={label?.name}
        price={price}
        calories={caloriesText}
        cloudinaryImageSrc={asset?.url}
        accessibilityLabel={accessibilityLabel}
        accessibilityHint={accessibilityHint}
        onCardPress={
          isStepperVisible || isLoading ? undefined : handleCardPress
        }
        isDisabled={outOfStock}
        unavailableNotice={
          outOfStock ? t('menu.product-card.out-of-stock-notice') : undefined
        }
      />

      {shouldRenderOneClickAddToBag ? (
        <View style={styles.oneClickAddWrapper}>
          <ButtonQuantityStepper
            onSubmit={handleAddToBag}
            isDisabled={outOfStock}
            isLoading={isLoading}
            onStepperVisibilityChange={handleStepperVisibilityChange}
            iconName="IconBagPlus"
            toggleTestID={`${slugify(name)}-one-click-add-to-bag`}
            accessibilityLabel={oneClickAddToBagBtnAccessibilityLabel}
            accessibilityHint={oneClickAddToBagBtnAccessibilityHint}
            loadingAccessibilityLabel={
              oneClickAddToBagLoadingAccessibilityLabel
            }
            onQuantityIncrease={handleQuantityIncrease}
            onQuantityDecrease={handleQuantityDecrease}
          />
        </View>
      ) : null}
    </View>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type MenuCategoryProductProps = Readonly<{
  product: MenuCategories[number]['products'][number];
  restaurantSlug: string;
  deliveryDetails?: DeliveryOrderDetailInput;
}>;

type MenuCategories = NonNullable<
  RestaurantQuery['restaurant']
>['menu']['categories'];

type ShowProductAddBannerProps = Parameters<
  NonNullable<Parameters<typeof useProductCallbacks>[0]['onSuccess']>
>[0];

type TrackAddToCartFailProps = Parameters<
  NonNullable<Parameters<typeof useProductCallbacks>[0]['onError']>
>[0];

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  productCardWrapper: { flex: 1 },
  oneClickAddWrapper: {
    position: 'absolute',
    bottom: theme.spacing['4'],
    right: theme.spacing['6'],
  },
});

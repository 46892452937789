/* istanbul ignore file */

export const onetrustStyles = `
#onetrust-consent-sdk {
    --close-icon-size: 24px;
}
#onetrust-consent-sdk *,
#onetrust-consent-sdk :after,
#onetrust-consent-sdk :before {
    box-sizing: border-box !important;
}
#onetrust-consent-sdk #onetrust-banner-sdk {
    --font-family: SweetSans Text, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    --font-size: 12px;
    --font-weight: 400;
    --line-height: 1.334;
    --letter-spacing: 0.2px;
    --color: #0e150e;
    --background-color: #d8e5d6;
    --padding: 16px;
    background-color: var(--background-color) !important;
    padding: var(--padding);
    box-shadow: unset;
}
@media screen and (min-width: 45.063em) {
    #onetrust-consent-sdk #onetrust-banner-sdk {
        --padding: 24px;
    }
}
#onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container {
    position: unset;
    overflow: unset;
    padding: unset;
    width: 100%;
}
#onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container > .ot-sdk-row {
    display: flex;
    flex-direction: column;
}
@media screen and (min-width: 58.063em) {
    #onetrust-consent-sdk #onetrust-banner-sdk .ot-sdk-container > .ot-sdk-row {
        flex-direction: row;
        align-items: center;
    }
}
#onetrust-consent-sdk #onetrust-banner-sdk,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-policy-text {
    --font-family: SweetSans Text, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    --font-size: 12px;
    --font-weight: 400;
    --line-height: 1.334;
    --letter-spacing: 0.2px;
    --color: #0e150e;
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
    letter-spacing: var(--letter-spacing);
    color: var(--color);
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-close-btn-container,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-group-container {
    width: unset;
    min-width: 0;
    flex-shrink: 0;
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-group-container {
    --offset-padding: calc(var(--close-icon-size) + 10px);
    flex-shrink: 1;
    padding-right: var(--offset-padding);
}
@media screen and (min-width: 58.063em) {
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-group-container {
        --offset-padding: 0;
    }
}
@media screen and (min-width: 58.063em) {
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-group-container {
        padding-right: 0;
    }
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-group-container #onetrust-policy,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-group-container #onetrust-policy-text {
    margin: unset;
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent {
    --padding-top: 16px;
    --padding-left: 0;
    position: unset;
    -ms-transform: unset;
    transform: unset;
    margin-right: unset;
    margin-left: unset;
    padding-right: unset;
    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
}
@media screen and (min-width: 58.063em) {
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent {
        --padding-top: 0;
        --padding-left: 16px;
    }
}
@media screen and (min-width: 58.063em) {
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent {
        margin-left: auto;
    }
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group {
    display: flex;
    flex-direction: column;
    width: unset;
    margin-top: unset;
    margin-right: unset;
}
@media screen and (min-width: 45.063em) {
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group {
        flex-direction: row;
    }
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a {
    --focus-outline-color: #2d6b52;
    --bg-color: #00473c;
    --bg-color-hover: #2d6b52;
    --color: #f4f3e7;
    --color-hover: var(--color);
    --padding: 10px 24px;
    --height: 40px;
    --font-size: 14px;
    --line-height: 1.286;
    --border-radius: 20px;
    height: var(--height);
    font-family: "SweetSans Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
    font-size: var(--font-size) !important;
    line-height: var(--line-height);
    text-align: center;
    border-radius: var(--border-radius);
    border: 0;
    padding: 10px 24px !important;
    cursor: pointer;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: background 240ms ease-in-out, color 240ms ease-in-out, opacity 240ms ease-in-out !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: greyscale;
    background: var(--bg-color);
    color: var(--color);
    letter-spacing: unset;
    text-transform: unset;
    position: relative;
    opacity: 1 !important;
    width: auto !important;
    margin: unset;
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button.activated,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button.hovered,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button:active,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button:hover,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a.activated,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a.hovered,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a:active,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a:hover {
    background: var(--bg-color);
    color: var(--color);
}
@media screen and (hover: hover) and (pointer: fine) and (min-width: 45.063em) {
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button.activated,
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button.hovered,
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button:active,
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button:hover,
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a.activated,
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a.hovered,
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a:active,
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a:hover {
        background: var(--bg-color-hover);
        color: var(--color-hover);
    }
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group #onetrust-pc-btn-handler:after,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button.hollow:after,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button[class*="-hollow"]:after,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a.hollow:after,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a[class*="-hollow"]:after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 12H4' stroke='%230e150e'/%3E%3Cpath d='M12.5 5L20 12L12.5 19' stroke='%230e150e'/%3E%3C/svg%3E%0A")
        center center no-repeat;
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button:focus,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a:focus {
    outline-offset: 2px;
    outline: 0;
    box-shadow: none;
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button.activated,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button:active,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a.activated,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a:active {
    -ms-transform: unset;
    transform: unset;
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button.disabled,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button:disabled,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a.disabled,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a:disabled {
    --bg-color: #bdbdb1;
    --bg-color-hover: var(--bg-color);
    --border-color: var(--bg-color);
    --color: #484d48;
    --color-hover: var(--color);
    pointer-events: none;
    touch-action: none;
    cursor: not-allowed;
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button.hollow,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button[class*="-hollow"],
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a.hollow,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a[class*="-hollow"] {
    --bg-color: transparent;
    --bg-color-hover: rgba(0, 71, 60, 0.16);
    --color: #0e150e;
    --border-color: var(--color);
    border: 1px solid var(--border-color);
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button:before,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    touch-action: none;
    content: "";
    border-radius: calc(var(--border-radius) * 1.2);
    border: 1px solid #2d6b52;
    margin: -4px;
    opacity: 0;
    transition: opacity 240ms ease-in-out;
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button:focus:before,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a:focus:before {
    opacity: 1;
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button + a,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button + button,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a + a,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a + button {
    margin-top: 16px;
}
@media screen and (min-width: 45.063em) {
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button + a,
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group button + button,
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a + a,
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group > a + button {
        margin-top: 0;
        margin-left: 16px;
    }
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-button-group-parent #onetrust-button-group #onetrust-pc-btn-handler {
    --bg-color: transparent;
    --bg-color-hover: rgba(0, 71, 60, 0.16);
    --color: #0e150e;
    --border-color: var(--color);
    border: 1px solid var(--border-color);
}
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-close-btn-container,
#onetrust-consent-sdk #onetrust-banner-sdk #onetrust-close-btn-container-mobile {
    margin-left: unset;
}
@media screen and (min-width: 58.063em) {
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-close-btn-container,
    #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-close-btn-container-mobile {
        padding-left: 16px;
    }
}
#onetrust-consent-sdk #onetrust-pc-sdk {
    --color-green-1: #0e150e;
    --color-khaki-3: #f4f3e7;
    --shadow-dialog: 0 0 4px rgba(14, 21, 14, 0.24);
    --popup-width: 100%;
    --border-radius: unset;
    --background-color: var(--color-khaki-3);
    --color: var(--color-green-1);
    display: flex;
    flex-direction: column;
    background-color: var(--background-color) !important;
    box-shadow: var(--shadow-dialog);
    border-radius: var(--border-radius);
    max-width: var(--popup-width);
    min-width: var(--popup-width);
    overflow-y: auto;
}
@media (min-width: 600.98px) {
    #onetrust-consent-sdk #onetrust-pc-sdk {
        --popup-width: 560px;
        --border-radius-normal: 8px;
        --border-radius: var(--border-radius-normal);
    }
}
@media screen and (min-width: 58.063em) {
    #onetrust-consent-sdk #onetrust-pc-sdk {
        --popup-width: 710px;
    }
}
#onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-header {
    --spacer-sm: 16px;
    --spacer-md: 24px;
    --spacer-lg: 40px;
    display: flex;
    height: auto;
    padding: var(--spacer-sm) var(--spacer-md);
    border-bottom: unset;
    flex-shrink: 0;
    background-color: var(--background-color);
    position: sticky;
    top: 0;
    z-index: 2;
}
@media screen and (min-width: 45.063em) {
    #onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-header {
        padding-top: var(--spacer-lg);
    }
}
#onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-header #close-pc-btn-handler {
    position: relative;
    top: 0;
    right: 0;
    width: 24px !important;
    height: 24px !important;
    margin-left: auto;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content {
    --spacer-sm: 16px;
    --spacer-md: 24px;
    width: 100%;
    max-height: 100%;
    overflow: hidden auto;
    flex-grow: 1;
    padding: var(--spacer-sm) var(--spacer-md);
    position: static;
    margin: unset;
}
@media screen and (min-width: 45.063em) {
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-content,
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content {
        padding-bottom: var(--spacer-lg);
    }
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .pc-logo,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .pc-logo {
    margin-bottom: var(--spacer-sm);
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-pc-title,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #pc-title,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-title,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #pc-title {
    --font-family-default: system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
    --font-family-sweet-sans: 'SweetSans', var(--font-family-default);
    --fw-normal: 400;
    --fs-headline-4: 24px;

    font-family: var(--font-family-sweet-sans);
    font-weight: var(--fw-normal);
    font-size: var(--fs-headline-4);
    line-height: 1.167;
    letter-spacing: 1.2px;
    color: var(--color);
    margin-top: 0;
    margin-bottom: var(--spacer-sm);
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #cookie-preferences .category-header,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #manage-cookies-text,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-category-title,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-pc-desc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #pc-policy-text,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout .ot-acc-grpdesc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout .ot-category-desc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-always-active,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-cat-header,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-subgrp-cntr .ot-subgrp-desc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-subgrp-cntr h5,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #cookie-preferences .category-header,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #manage-cookies-text,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-category-title,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-desc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #pc-policy-text,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout .ot-acc-grpdesc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout .ot-category-desc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-always-active,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-cat-header,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-subgrp-cntr .ot-subgrp-desc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-subgrp-cntr h5 {
    --font-family-default: system-ui, -apple-system, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
    --font-family-base: 'SweetSans Text', var(--font-family-default);
    --fs-body-sm: 14px;
    --color-green-1: #0e150e;
    --color: var(--color-green-1);
    font-family: var(--font-family-base);
    font-size: var(--fs-body-sm);
    color: var(--color);
    line-height: 1.286;
    letter-spacing: 0.1px;
}
#onetrust-consent-sdk #accept-recommended-btn-handler,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-pc-desc a,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #pc-policy-text a,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-desc a,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #pc-policy-text a,
#onetrust-consent-sdk .save-preference-btn-handler {
    line-height: var(--line-height);
    font-family: "SweetSans Text", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-decoration: none;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-pc-desc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #pc-policy-text,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-desc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #pc-policy-text {
    font-weight: var(--fw-normal);
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-pc-desc a,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #pc-policy-text a,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-desc a,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #pc-policy-text a {
    --font-size: inherit !important;
    --line-height: inherit !important;
    --letter-spacing: inherit !important;
    margin: unset;
    --color: #0e150e;
    color: var(--color);
    font-weight: 400;
    font-size: var(--font-size);
    letter-spacing: var(--letter-spacing);
    border-bottom: 1px solid currentColor;
    transition: color var(--transition-base);
}
@media screen and (min-width: 45.063em) {
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-pc-desc a,
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-content #pc-policy-text a,
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-desc a,
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #pc-policy-text a {
        --font-size: 16px;
        --line-height: 1.375;
        --letter-spacing: unset;
    }
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-pc-desc a:active,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-pc-desc a:hover,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #pc-policy-text a:active,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #pc-policy-text a:hover,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-desc a:active,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-desc a:hover,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #pc-policy-text a:active,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #pc-policy-text a:hover {
    color: var(--color);
}
@media (hover: hover) and (pointer: fine) {
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-pc-desc a:active,
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-pc-desc a:hover,
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-content #pc-policy-text a:active,
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-content #pc-policy-text a:hover,
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-desc a:active,
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-desc a:hover,
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #pc-policy-text a:active,
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #pc-policy-text a:hover {
        --color: #2d6b52;
    }
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-pc-desc a:focus,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #pc-policy-text a:focus,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-desc a:focus,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #pc-policy-text a:focus {
    outline: #2d6b52 solid 1px;
    box-shadow: none;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-pc-desc a:active,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #pc-policy-text a:active,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-desc a:active,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #pc-policy-text a:active {
    -ms-transform: unset;
    transform: unset;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-pc-desc a.disabled,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-pc-desc a:disabled,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #pc-policy-text a.disabled,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #pc-policy-text a:disabled,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-desc a.disabled,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-desc a:disabled,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #pc-policy-text a.disabled,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #pc-policy-text a:disabled {
    --color: #484d48;
    pointer-events: none;
    touch-action: none;
    cursor: not-allowed;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-pc-desc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-pc-desc {
    margin-bottom: var(--spacer-sm);
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #manage-cookies-text,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-category-title,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #manage-cookies-text,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-category-title {
    --fs-body: 16px;
    font-size: var(--fs-body);
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-always-active,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-always-active {
    --color-green-2: #00473c;
    color: var(--color-green-2);
    right: 0;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #ot-sdk-row,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #ot-sdk-row {
    margin-top: 0;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout {
    --color-neutral-3: #bdbdb1;
    --border: 1px solid var(--color-neutral-3);
    border-bottom: var(--border);
    border-top: unset;
    border-right: unset;
    border-left: unset;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout:first-of-type,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout:first-of-type {
    border-top: var(--border);
    margin-top: unset;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout:last-of-type,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout:last-of-type {
    border-bottom: 0;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout > button:focus,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout > button:focus {
    outline: unset;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout > button[aria-expanded="true"] ~ .ot-acc-hdr .ot-plus-minus,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout > button[aria-expanded="true"] ~ .ot-acc-hdr .ot-plus-minus {
    -ms-transform: rotate(0);
    transform: rotate(0);
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout .ot-acc-hdr,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout .ot-acc-hdr {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: unset;
    padding: var(--spacer-sm);
    padding: 16px 0;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout .ot-acc-hdr .ot-plus-minus,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout .ot-acc-hdr .ot-plus-minus {
    --size: 24px;
    --spacer-sm: 16px;
    top: 0;
    width: var(--size);
    height: var(--size);
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.5 15.5L12 8L4.5 15.5' stroke='%230E150E'/%3E%3C/svg%3E%0A") no-repeat;
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    transition: transform var(--transition-base);
    margin-right: 4px;
}
@media (min-width: 600.98px) {
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout .ot-acc-hdr .ot-plus-minus,
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout .ot-acc-hdr .ot-plus-minus {
        margin-right: var(--spacer-sm);
    }
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout .ot-acc-hdr .ot-plus-minus > span,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout .ot-acc-hdr .ot-plus-minus > span {
    display: none;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout .ot-acc-grpcntr,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout .ot-acc-grpcntr {
    background-color: transparent;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout .ot-acc-grpdesc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout .ot-category-desc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout .ot-acc-grpdesc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout .ot-category-desc {
    padding: 0 0 var(--spacer-sm);
    width: unset;
    margin: unset;
    clear: unset;
    float: unset;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout .ot-subgrp-cntr,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout .ot-subgrp-cntr {
    width: 100%;
    border-color: var(--color-neutral-3) !important;
    padding-bottom: var(--spacer-sm);
}
@media (min-width: 600.98px) {
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-pc-footer-logo {
        width: auto;
        display: flex;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0;
    }
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .category-item .category-header {
    min-height: 24px;
    display: flex;
    align-items: center;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content #cookie-preferences {
    padding-top: var(--spacer-md);
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .save-preference-btn-container {
    text-align: center;
}
@media (min-width: 600.98px) {
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-content .save-preference-btn-container .save-preference-btn-handler {
        margin-bottom: var(--spacer-sm) !important;
    }
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content {
    display: flex;
    flex-direction: column;
    height: auto !important;
    max-height: unset !important;
    flex-shrink: 0;
    padding-top: 0;
    padding-bottom: var(--spacer-sm);
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content > * {
    flex-shrink: 0;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #accept-recommended-btn-handler {
    margin: var(--spacer-sm) 0;
}
@media (min-width: 600.98px) {
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content #accept-recommended-btn-handler {
        margin: var(--spacer-sm) 0 0 auto;
    }
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-tgl {
    right: 0;
    float: unset;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-tgl .ot-switch {
    --color-khaki-3: #f4f3e7;
    --color-caution: #ab3726;
    --toggle-wrapper-width: 48px;
    --toggle-wrapper-height: 24px;
    --toggle-offset: 2px;
    --toggle-indicator-size: 20px;
    --toggle-size: calc(var(--toggle-wrapper-height) - calc(var(--toggle-offset) * 2));
    --toggle-bg-color: var(--color-caution);
    --indicator-color: var(--color-khaki-3);
    width: var(--toggle-wrapper-width);
    height: var(--toggle-wrapper-height);
    border-radius: var(--border-radius-large);
}
@media (hover: hover) and (pointer: fine) {
    #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-tgl .ot-switch:hover {
        --indicator-color: var(--color-green-5);
    }
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-tgl .ot-switch .ot-switch-nob {
    --transition-base: 0.15s ease-in-out;
    border: 0 !important;
    background-color: var(--toggle-bg-color) !important;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.66675 3.66675L12.3334 12.3334' stroke='%23F4F3E7'/%3E%3Cpath d='M12.3334 3.66675L3.66675 12.3334' stroke='%23F4F3E7'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: calc(100% - 6px) center;
    transition: background-color var(--transition-base);
    margin: 0;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-tgl .ot-switch .ot-switch-nob:before {
    --color-green-5: #d8e5d6;
    --toggle-indicator-size: 20px;
    --indicator-color: var(--color-green-5);
    --transition-base: 0.15s ease-in-out;
    width: var(--toggle-indicator-size);
    height: var(--toggle-indicator-size);
    bottom: 2px;
    left: 2px;
    background-color: var(--indicator-color) !important;
    transition: background-color var(--transition-base), transform var(--transition-base);
}
#onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-footer {
    --spacer-sm: 16px;
    --spacer-md: 24px;
    position: sticky;
    z-index: 1;
    bottom: 0;
    max-height: unset;
    border-top: unset;
    background-color: var(--background-color);
    padding: var(--spacer-sm) var(--spacer-md) 0;
    flex-shrink: 0;
}
@media (min-width: 600.98px) {
    #onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-footer {
        position: unset;
        padding-top: 0;
    }
}
#onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-footer-logo {
    --spacer-sm: 16px;
    --spacer-md: 24px;
    padding-top: var(--spacer-sm);
    padding-bottom: var(--spacer-sm);
    height: unset;
    float: unset;
    background: unset;
    margin-top: unset;
}
@media (min-width: 600.98px) {
    #onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-footer-logo {
        padding-top: 30px;
    }
}
#onetrust-consent-sdk #onetrust-pc-sdk .ot-pc-footer-logo a {
    margin: unset;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content::-webkit-scrollbar,
#onetrust-consent-sdk #onetrust-pc-sdk #vendor-list-content::-webkit-scrollbar,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-group-options::-webkit-scrollbar {
    width: 13px !important;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content::-webkit-scrollbar-thumb,
#onetrust-consent-sdk #onetrust-pc-sdk #vendor-list-content::-webkit-scrollbar-thumb,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-group-options::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: var(--webkit-scrollbar-default-bg);
    border: 4px solid var(--background-color);
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content::-webkit-scrollbar-track,
#onetrust-consent-sdk #onetrust-pc-sdk #vendor-list-content::-webkit-scrollbar-track,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-group-options::-webkit-scrollbar-track {
    margin-right: unset !important;
    background: 0 0 !important;
}
#onetrust-consent-sdk #close-pc-btn-handler,
#onetrust-consent-sdk #onetrust-close-btn-container-mobile > a,
#onetrust-consent-sdk #onetrust-close-btn-container-mobile > button,
#onetrust-consent-sdk #onetrust-close-btn-container > a,
#onetrust-consent-sdk #onetrust-close-btn-container > button {
    --padding: 16px;
    position: absolute;
    top: var(--padding);
    right: var(--padding);
    color: #0e150e;
    transition: color 240ms ease-in-out;
    background: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;'%3E%3Cg transform='matrix(1,0,0,1,1.87527,1.87527)'%3E%3Cg transform='matrix(1.15217,0,0,1.15217,-0.076087,-0.076087)'%3E%3Ccircle cx='12' cy='12' r='11.5' style='fill:none;stroke:%230e150e;stroke-width:1px;'/%3E%3Cpath d='M7.667,7.667L16.333,16.334' style='fill:none;fill-rule:nonzero;stroke:%230e150e;stroke-width:1px;'/%3E%3Cpath d='M16.333,7.667L7.667,16.334' style='fill:none;fill-rule:nonzero;stroke:%230e150e;stroke-width:1px;'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A")
        center center no-repeat !important;
    opacity: 1 !important;
    background-color: var(--background-color) !important;
    border-radius: 50%;
    -ms-transform: unset;
    transform: unset;
    margin: unset;
}
#onetrust-consent-sdk #ot-pc-content .ot-tgl input:checked + .ot-switch .ot-switch-nob:before,
#onetrust-consent-sdk .ot-switch.ot-toggle input:checked + .ot-switch-label .ot-switch-nob {
    -ms-transform: translateX(24px) !important;
    transform: translateX(24px) !important;
}
@media (hover: hover) and (pointer: fine) {
    #onetrust-consent-sdk #close-pc-btn-handler:active,
    #onetrust-consent-sdk #close-pc-btn-handler:hover,
    #onetrust-consent-sdk #onetrust-close-btn-container-mobile > a:active,
    #onetrust-consent-sdk #onetrust-close-btn-container-mobile > a:hover,
    #onetrust-consent-sdk #onetrust-close-btn-container-mobile > button:active,
    #onetrust-consent-sdk #onetrust-close-btn-container-mobile > button:hover,
    #onetrust-consent-sdk #onetrust-close-btn-container > a:active,
    #onetrust-consent-sdk #onetrust-close-btn-container > a:hover,
    #onetrust-consent-sdk #onetrust-close-btn-container > button:active,
    #onetrust-consent-sdk #onetrust-close-btn-container > button:hover {
        color: #2d6b52;
    }
}
#onetrust-consent-sdk #close-pc-btn-handler:after,
#onetrust-consent-sdk #onetrust-close-btn-container-mobile > a:after,
#onetrust-consent-sdk #onetrust-close-btn-container-mobile > button:after,
#onetrust-consent-sdk #onetrust-close-btn-container > a:after,
#onetrust-consent-sdk #onetrust-close-btn-container > button:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    touch-action: none;
    content: "";
    border-radius: 50%;
    border: 1px solid #2d6b52;
    margin: -4px;
    opacity: 0;
    transition: opacity 240ms ease-in-out;
}
#onetrust-consent-sdk #close-pc-btn-handler:focus:after,
#onetrust-consent-sdk #onetrust-close-btn-container-mobile > a:focus:after,
#onetrust-consent-sdk #onetrust-close-btn-container-mobile > button:focus:after,
#onetrust-consent-sdk #onetrust-close-btn-container > a:focus:after,
#onetrust-consent-sdk #onetrust-close-btn-container > button:focus:after {
    opacity: 1;
}
#onetrust-consent-sdk #close-pc-btn-handler:focus,
#onetrust-consent-sdk #onetrust-close-btn-container-mobile > a:focus,
#onetrust-consent-sdk #onetrust-close-btn-container-mobile > button:focus,
#onetrust-consent-sdk #onetrust-close-btn-container > a:focus,
#onetrust-consent-sdk #onetrust-close-btn-container > button:focus {
    outline: 0;
    box-shadow: unset;
}
@media screen and (min-width: 58.063em) {
    #onetrust-consent-sdk #close-pc-btn-handler,
    #onetrust-consent-sdk #onetrust-close-btn-container-mobile > a,
    #onetrust-consent-sdk #onetrust-close-btn-container-mobile > button,
    #onetrust-consent-sdk #onetrust-close-btn-container > a,
    #onetrust-consent-sdk #onetrust-close-btn-container > button {
        position: relative;
        top: 0;
        right: 0;
    }
}
@media (hover: hover) and (pointer: fine) {
    #onetrust-consent-sdk #close-pc-btn-handler:hover:before,
    #onetrust-consent-sdk #onetrust-close-btn-container-mobile > a:hover:before,
    #onetrust-consent-sdk #onetrust-close-btn-container-mobile > button:hover:before,
    #onetrust-consent-sdk #onetrust-close-btn-container > a:hover:before,
    #onetrust-consent-sdk #onetrust-close-btn-container > button:hover:before {
        will-change: opacity;
        opacity: 1;
    }
}
#onetrust-consent-sdk #close-pc-btn-handler,
#onetrust-consent-sdk #close-pc-btn-handler:before,
#onetrust-consent-sdk #onetrust-close-btn-container-mobile > a,
#onetrust-consent-sdk #onetrust-close-btn-container-mobile > a:before,
#onetrust-consent-sdk #onetrust-close-btn-container-mobile > button,
#onetrust-consent-sdk #onetrust-close-btn-container-mobile > button:before,
#onetrust-consent-sdk #onetrust-close-btn-container > a,
#onetrust-consent-sdk #onetrust-close-btn-container > a:before,
#onetrust-consent-sdk #onetrust-close-btn-container > button,
#onetrust-consent-sdk #onetrust-close-btn-container > button:before {
    width: var(--close-icon-size) !important;
    height: var(--close-icon-size) !important;
    background-size: 100% 100% !important;
}
#onetrust-consent-sdk #close-pc-btn-handler:before,
#onetrust-consent-sdk #onetrust-close-btn-container-mobile > a:before,
#onetrust-consent-sdk #onetrust-close-btn-container-mobile > button:before,
#onetrust-consent-sdk #onetrust-close-btn-container > a:before,
#onetrust-consent-sdk #onetrust-close-btn-container > button:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;'%3E%3Cg transform='matrix(1,0,0,1,1.87527,1.87527)'%3E%3Cg transform='matrix(1.15217,0,0,1.15217,-0.076087,-0.076087)'%3E%3Ccircle cx='12' cy='12' r='11.5' style='fill:none;stroke:%232d6b52;stroke-width:1px;'/%3E%3Cpath d='M7.667,7.667L16.333,16.334' style='fill:none;fill-rule:nonzero;stroke:%232d6b52;stroke-width:1px;'/%3E%3Cpath d='M16.333,7.667L7.667,16.334' style='fill:none;fill-rule:nonzero;stroke:%232d6b52;stroke-width:1px;'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A")
        center center no-repeat;
    pointer-events: none;
    touch-action: none;
    content: "";
    opacity: 0;
    transition: opacity 240ms ease-in-out;
}
#onetrust-consent-sdk .ot-switch.ot-toggle {
    width: unset;
    float: unset;
}
#onetrust-consent-sdk .ot-switch.ot-toggle input:checked + .ot-switch-label {
    --color-green-2: #00473c;
    --toggle-bg-color: var(--color-green-2);
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.66663 8.33325L5.99996 11.6666L13.3333 4.33325' stroke='%23F4F3E7'/%3E%3C/svg%3E%0A");
    background-position: 6px center;
}
#onetrust-consent-sdk .ot-switch.ot-toggle input:focus + .ot-switch-label {
    outline: unset !important;
    outline-color: var(--link-outline-color) !important;
    --indicator-color: var(--color-green-5);
}
#onetrust-consent-sdk #ot-pc-content .ot-tgl {
    display: inline-flex;
}
#onetrust-consent-sdk #ot-pc-content .ot-tgl input:checked + .ot-switch {
    --color-green-2: #00473c;
    --toggle-bg-color: var(--color-green-2) !important;
}
#onetrust-consent-sdk #ot-pc-content .ot-tgl input:checked + .ot-switch .ot-switch-nob {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.66663 8.33325L5.99996 11.6666L13.3333 4.33325' stroke='%23F4F3E7'/%3E%3C/svg%3E%0A") !important;
    background-position: 6px center !important;
}
#onetrust-consent-sdk #accept-recommended-btn-handler.activated,
#onetrust-consent-sdk #accept-recommended-btn-handler:active,
#onetrust-consent-sdk .save-preference-btn-handler.activated,
#onetrust-consent-sdk .save-preference-btn-handler:active,
.ember-view.sg-rebranding-2021 .post-purchase .post-purchase-header .post-purchase-completed-actions a.activated,
.ember-view.sg-rebranding-2021 .post-purchase .post-purchase-header .post-purchase-completed-actions a:active,
.ember-view.sg-rebranding-2021 .post-purchase .post-purchase-header .post-purchase-completed-actions button.activated,
.ember-view.sg-rebranding-2021 .post-purchase .post-purchase-header .post-purchase-completed-actions button:active,
.ember-view.sg-rebranding-2021-clean-view .post-purchase .post-purchase-header .post-purchase-completed-actions a.activated,
.ember-view.sg-rebranding-2021-clean-view .post-purchase .post-purchase-header .post-purchase-completed-actions a:active,
.ember-view.sg-rebranding-2021-clean-view .post-purchase .post-purchase-header .post-purchase-completed-actions button.activated,
.ember-view.sg-rebranding-2021-clean-view .post-purchase .post-purchase-header .post-purchase-completed-actions button:active {
    -ms-transform: unset;
    transform: unset;
}
#onetrust-consent-sdk #ot-pc-content .ot-tgl input:focus + .ot-switch {
    outline: unset !important;
    --indicator-color: var(--color-green-5) !important;
}
#onetrust-consent-sdk #ot-pc-content .ot-tgl .ot-switch,
#onetrust-consent-sdk .ot-toggle .ot-switch-label {
    --color-khaki-3: #f4f3e7;
    --toggle-wrapper-width: 48px;
    --toggle-wrapper-height: 24px;
    --toggle-offset: 2px;
    --toggle-indicator-size: 20px;
    --toggle-size: calc(var(--toggle-wrapper-height) - calc(var(--toggle-offset) * 2));
    --toggle-bg-color: var(--color-caution);
    --indicator-color: var(--color-khaki-3);
    width: var(--toggle-wrapper-width);
    height: var(--toggle-wrapper-height);
    border-radius: var(--border-radius-large) !important;
    border: 0 !important;
    cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
    #onetrust-consent-sdk #ot-pc-content .ot-tgl .ot-switch:hover,
    #onetrust-consent-sdk .ot-toggle .ot-switch-label:hover {
        --indicator-color: var(--color-green-5);
    }
}
#onetrust-consent-sdk #ot-pc-content .ot-tgl .ot-switch .ot-switch-nob,
#onetrust-consent-sdk .ot-toggle .ot-switch-label {
    --color-caution: #ab3726;
    border: 0 !important;
    background-color: var(--toggle-bg-color) !important;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.66675 3.66675L12.3334 12.3334' stroke='%23F4F3E7'/%3E%3Cpath d='M12.3334 3.66675L3.66675 12.3334' stroke='%23F4F3E7'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: calc(100% - 6px) center;
    transition: background-color var(--transition-base);
    margin: 0;
}
#onetrust-consent-sdk #ot-pc-content .ot-tgl .ot-switch .ot-switch-nob:before,
#onetrust-consent-sdk .ot-toggle .ot-switch-nob {
    width: var(--toggle-indicator-size) !important;
    height: var(--toggle-indicator-size) !important;
    bottom: 2px;
    left: 2px;
    background-color: var(--indicator-color) !important;
    transition: background-color var(--transition-base), transform var(--transition-base);
    margin: unset;
    border: 0;
}
#onetrust-consent-sdk .ot-switch,
#onetrust-consent-sdk .ot-switch-label {
    background-color: unset !important;
}
#onetrust-consent-sdk #accept-recommended-btn-handler,
#onetrust-consent-sdk .save-preference-btn-handler {
    width: 100% !important;
    max-width: unset !important;
    --height: 48px !important;
    --font-size: 16px !important;
    --line-height: 1.375 !important;
    --border-radius: 24px !important;
    --focus-outline-color: #2d6b52;
    --bg-color: #00473c;
    --bg-color-hover: #2d6b52;
    --color: #f4f3e7;
    --color-hover: var(--color);
    --padding: 10px 24px;
    height: var(--height);
    font-weight: 400;
    font-size: var(--font-size) !important;
    text-align: center;
    border-radius: var(--border-radius);
    border: 0;
    padding: 10px 24px !important;
    cursor: pointer;
    text-overflow: ellipsis;
    transition: background 240ms ease-in-out, color 240ms ease-in-out, opacity 240ms ease-in-out !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: greyscale;
    background: var(--bg-color);
    color: var(--color);
    letter-spacing: unset;
    text-transform: unset;
    position: relative;
}
@media (min-width: 600.98px) {
    #onetrust-consent-sdk #accept-recommended-btn-handler,
    #onetrust-consent-sdk .save-preference-btn-handler {
        width: auto !important;
    }
}
@media screen and (min-width: 45.063em) {
    #onetrust-consent-sdk #accept-recommended-btn-handler,
    #onetrust-consent-sdk .save-preference-btn-handler {
        --height: 48px;
        --font-size: 16px;
        --line-height: 1.375;
        --border-radius: 24px;
    }
}
#onetrust-consent-sdk #accept-recommended-btn-handler.activated,
#onetrust-consent-sdk #accept-recommended-btn-handler.hovered,
#onetrust-consent-sdk #accept-recommended-btn-handler:active,
#onetrust-consent-sdk #accept-recommended-btn-handler:hover,
#onetrust-consent-sdk .save-preference-btn-handler.activated,
#onetrust-consent-sdk .save-preference-btn-handler.hovered,
#onetrust-consent-sdk .save-preference-btn-handler:active,
#onetrust-consent-sdk .save-preference-btn-handler:hover {
    --bg-color: #00473c;
    --color: #f4f3e7;
    background: var(--bg-color);
    color: var(--color);
}
@media screen and (hover: hover) and (pointer: fine) and (min-width: 45.063em) {
    #onetrust-consent-sdk #accept-recommended-btn-handler.activated,
    #onetrust-consent-sdk #accept-recommended-btn-handler.hovered,
    #onetrust-consent-sdk #accept-recommended-btn-handler:active,
    #onetrust-consent-sdk #accept-recommended-btn-handler:hover,
    #onetrust-consent-sdk .save-preference-btn-handler.activated,
    #onetrust-consent-sdk .save-preference-btn-handler.hovered,
    #onetrust-consent-sdk .save-preference-btn-handler:active,
    #onetrust-consent-sdk .save-preference-btn-handler:hover {
        --bg-color-hover: #2d6b52;
        background: var(--bg-color-hover);
        color: var(--color-hover);
    }
}
#onetrust-consent-sdk #accept-recommended-btn-handler:focus,
#onetrust-consent-sdk .save-preference-btn-handler:focus {
    outline-offset: 2px;
    outline: 0;
    box-shadow: none;
}
#onetrust-consent-sdk #accept-recommended-btn-handler.disabled,
#onetrust-consent-sdk #accept-recommended-btn-handler:disabled,
#onetrust-consent-sdk .save-preference-btn-handler.disabled,
#onetrust-consent-sdk .save-preference-btn-handler:disabled {
    --bg-color: #bdbdb1;
    --bg-color-hover: var(--bg-color);
    --border-color: var(--bg-color);
    --color: #484d48;
    --color-hover: var(--color);
    pointer-events: none;
    touch-action: none;
    cursor: not-allowed;
}
#onetrust-consent-sdk #accept-recommended-btn-handler.hollow,
#onetrust-consent-sdk #accept-recommended-btn-handler[class*="-hollow"],
#onetrust-consent-sdk .save-preference-btn-handler.hollow,
#onetrust-consent-sdk .save-preference-btn-handler[class*="-hollow"] {
    --bg-color: transparent;
    --bg-color-hover: rgba(0, 71, 60, 0.16);
    --color: #0e150e;
    --border-color: var(--color);
    border: 1px solid var(--border-color);
}
#onetrust-consent-sdk #accept-recommended-btn-handler.hollow:after,
#onetrust-consent-sdk #accept-recommended-btn-handler[class*="-hollow"]:after,
#onetrust-consent-sdk .save-preference-btn-handler.hollow:after,
#onetrust-consent-sdk .save-preference-btn-handler[class*="-hollow"]:after {
    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 12H4' stroke='%230e150e'/%3E%3Cpath d='M12.5 5L20 12L12.5 19' stroke='%230e150e'/%3E%3C/svg%3E%0A")
        center center no-repeat;
}
#onetrust-consent-sdk #accept-recommended-btn-handler:before,
#onetrust-consent-sdk .save-preference-btn-handler:before {
    --border-radius: 24px !important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    touch-action: none;
    content: "";
    border-radius: calc(var(--border-radius) * 1.2);
    border: 1px solid #2d6b52;
    margin: -4px;
    opacity: 0;
    transition: opacity 240ms ease-in-out;
}
#onetrust-consent-sdk #accept-recommended-btn-handler:focus,
#onetrust-consent-sdk #accept-recommended-btn-handler:focus:before,
#onetrust-consent-sdk #accept-recommended-btn-handler:hover,
#onetrust-consent-sdk .save-preference-btn-handler:focus,
#onetrust-consent-sdk .save-preference-btn-handler:focus:before,
#onetrust-consent-sdk .save-preference-btn-handler:hover {
    opacity: 1;
}
#onetrust-consent-sdk #accept-recommended-btn-handler:active,
#onetrust-consent-sdk #accept-recommended-btn-handler:hover,
#onetrust-consent-sdk .save-preference-btn-handler:active,
#onetrust-consent-sdk .save-preference-btn-handler:hover {
    background: var(--bg-color) !important;
    color: var(--color) !important;
}
@media screen and (hover: hover) and (pointer: fine) and (min-width: 45.063em) {
    #onetrust-consent-sdk #accept-recommended-btn-handler:active,
    #onetrust-consent-sdk #accept-recommended-btn-handler:hover,
    #onetrust-consent-sdk .save-preference-btn-handler:active,
    #onetrust-consent-sdk .save-preference-btn-handler:hover {
        background: var(--bg-color-hover) !important;
        color: var(--color-hover) !important;
    }
}
#onetrust-consent-sdk #accept-recommended-container button {
    float: right;
}
#onetrust-consent-sdk .save-preference-btn-handler {
    opacity: 1 !important;
    margin: unset !important;
    float: unset !important;
}
#onetrust-consent-sdk #onetrust-pc-btn-handler {
    border-width: 0 !important;
}
#onetrust-consent-sdk #onetrust-pc-sdk #ot-content .ot-accordion-layout > button:active,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-content .ot-accordion-layout > button:active,
.ember-view.sg-rebranding-2021 .map-component .info-window .close #close-infoWindow:active,
.ember-view.sg-rebranding-2021-clean-view .map-component .info-window .close #close-infoWindow:active {
    -ms-transform: none;
    transform: none;
}
#onetrust-consent-sdk .ot-switch.ot-toggle .ot-switch-inner,
.ember-view.sg-rebranding-2021 #customers-login form span.restricted-icon,
.ember-view.sg-rebranding-2021 #customers-signin form span.restricted-icon,
.ember-view.sg-rebranding-2021 #customers-verify form span.restricted-icon,
.ember-view.sg-rebranding-2021 #feedback #order-feedback .transition-2 #options-container,
.ember-view.sg-rebranding-2021-clean-view #customers-login form span.restricted-icon,
.ember-view.sg-rebranding-2021-clean-view #customers-signin form span.restricted-icon,
.ember-view.sg-rebranding-2021-clean-view #customers-verify form span.restricted-icon,
.ember-view.sg-rebranding-2021-clean-view #feedback #order-feedback .transition-2 #options-container,
.ember-view.sg-rebranding-2021 ~ .ember-view .unavailable-modal.ember-dialog-dialog .unavailable-item-or-ingredient-modal-body .unavailable-modal-content.right .line-item .card-actions,
.ember-view.sg-rebranding-2021 ~ .ember-view .unavailable-modal.ember-dialog-dialog .unavailable-item-or-ingredient-modal-body .unavailable-modal-content.right .line-item .card-image-wrap,
.ember-view.sg-rebranding-2021 ~ .ember-view .unavailable-modal.ember-dialog-dialog .unavailable-item-or-ingredient-modal-body .unavailable-modal-content.right .line-item .share-actions,
.ember-view.sg-rebranding-2021 ~ .ember-view .unavailable-modal.ember-dialog-dialog .unavailable-item-or-ingredient-modal-body .unavailable-modal-content.right .line-item .share-favorite-wrap {
    display: none !important;
}`;

import React, { useCallback, useState } from 'react';
import {
  type NativeScrollEvent,
  type NativeSyntheticEvent,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { DotsIndicator, LoadingDots } from '@sg/garnish';
import { RewardType } from '@sg/graphql-schema';

import { useIsLoggedIn } from '@order/AuthMachine';
import { Loyalty } from '@order/features/loyalty';
import { Bag } from '@order/features/ordering';
import { useLoyaltyContent, useLoyaltyInfoBenefits } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';

import {
  useBagLoyalty,
  useDynamicHeaderSubtitle,
  type useOrderState,
  useRewardsPromoHeading,
} from '../../hooks';

/**
 * The loyalty section on the bag.
 */
export const BagLoyalty = (props: BagLoyaltyProps) => {
  const { orderState } = props;

  // ─── Context ──────────────────────────────────────────────────────────────

  const {
    isFetchingRewards,
    isApplyingPromoCode,
    isPerformingBagAction,
    rewardIdBeingUpdated,
    rewards,
    appliedRewardId,
    availablePoints,
    customer: { isNewCustomer },
    bagTracking: { trackLoyaltySignedOutPressed },
  } = orderState;

  // ─── Actions ──────────────────────────────────────────────────────────────

  const {
    showLoyaltyInfo,
    applyBagReward,
    removeBagReward,
    applyBagPromoCode,
  } = orderState;

  // ─── Auth State ───────────────────────────────────────────────────────────

  const isLoggedIn = useIsLoggedIn();
  const navigation = useNavigation();

  const handleCreateAccount = useCallback(() => {
    trackLoyaltySignedOutPressed();
    navigation.navigate('Auth', {
      screen: 'Login',
      params: { redirect: 'bag' },
    });
  }, [navigation, trackLoyaltySignedOutPressed]);

  // ─── Promo Code New Customer Filtering ────────────────────────────────────

  const isPromoCodeNewCustomerFilteringEnabled = useFeatureFlag(
    'CELS-2917-reject-promo-code-previous-orders-enabled',
  );

  const shouldShowPromoCodeField =
    isLoggedIn && (!isPromoCodeNewCustomerFilteringEnabled || isNewCustomer);

  // ─── Rewards / Promo Heading ──────────────────────────────────────────────

  const rewardsPromoHeading = useRewardsPromoHeading({
    shouldShowPromoCodeField,
  });

  // ─── Contentful ───────────────────────────────────────────────────────────

  const {
    bagHeaderLoggedOut: loyaltyTitleLoggedOut,
    bagHeaderNewUser: loyaltyTitleNewUser,
    bagHeaderRecurringUser: loyaltyTitleRecurringUser,
    bagHeaderSubtitle: loyaltySubtitle,
    bagHeaderLoggedOutImageMessage: loyaltyImageText,
    bagHeaderLoggedOutImage: loyaltyImage,
  } = useLoyaltyContent({ availablePoints });

  const { title: loyaltyHeaderTitle } = useBagLoyalty({
    isLoggedIn,
    isNewCustomer: availablePoints === 0,
    loyaltyTitleLoggedOut,
    loyaltyTitleNewUser,
    loyaltyTitleRecurringUser,
    availablePoints,
  });

  // ─── Dynamic Subtitle ─────────────────────────────────────────────────────

  const { benefits } = useLoyaltyInfoBenefits({ pause: !isLoggedIn });
  const dynamicSubtitle = useDynamicHeaderSubtitle({
    availablePoints,
    benefits,
    defaultSubtitle: loyaltySubtitle,
  });

  // ─── Flags ────────────────────────────────────────────────────────────────

  const isLoyaltyV2Enabled = useFeatureFlag('CELS-2685-loyalty-v2-enabled');
  const isLoyaltyV2Disabled = !isLoyaltyV2Enabled;

  const hasRewards = rewards.length > 0;
  const shouldShowLoadingIndicator = !hasRewards && isFetchingRewards;
  const shouldShowEmptyState = isLoggedIn && !hasRewards && !isFetchingRewards;

  // ─── Scroll Indicator ─────────────────────────────────────────────────────

  const [currentRailWidth, setCurrentRailWidth] = useState(0);
  const [currentlyScrolledReward, setCurrentlyScrolledReward] = useState(0);
  const handleOnScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const scrollAmount = event.nativeEvent.contentOffset.x;
      const itemWidth = currentRailWidth / rewards.length;
      const halfWidth = itemWidth / 2;
      const scrolledReward = Math.floor((scrollAmount + halfWidth) / itemWidth);
      const isScrollingToLastReward = scrolledReward === rewards.length - 2;
      const shouldIndicateLastReward = currentRailWidth - scrollAmount < 500;
      const rewardToIndicate =
        isScrollingToLastReward && shouldIndicateLastReward
          ? rewards.length - 1
          : scrolledReward;

      setCurrentlyScrolledReward(rewardToIndicate);
    },
    [rewards.length, currentRailWidth],
  );

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <Bag.PromoRewardsContainer>
      {isLoyaltyV2Enabled ? (
        <Loyalty.Bag.LoyaltyHeader
          title={loyaltyHeaderTitle}
          onPressInfo={isLoggedIn ? showLoyaltyInfo : undefined}
        />
      ) : null}

      {isLoyaltyV2Enabled && !isLoggedIn ? (
        <Loyalty.Bag.LoyaltySignedOutCta
          message={loyaltyImageText}
          imageUrl={loyaltyImage}
          onPress={handleCreateAccount}
        />
      ) : null}

      {isLoyaltyV2Enabled && hasRewards ? (
        <Bag.RewardsRail
          heading={dynamicSubtitle}
          onScroll={handleOnScroll}
          onContentSizeChange={setCurrentRailWidth}
        >
          {rewards.map((reward) => {
            const isApplied = appliedRewardId === reward.id;
            const isLoading = rewardIdBeingUpdated === reward.id;
            const isDisabled = isPerformingBagAction && !isLoading;
            const onPress = isApplied ? removeBagReward : applyBagReward;

            return (
              <Loyalty.Reward
                key={reward.id}
                id={reward.id}
                title={reward.name}
                points={reward.points}
                imageUrl={reward.assetUrl}
                expirationDate={reward.expirationDate}
                redeemableAt={reward.redeemableAt}
                isApplied={isApplied}
                isLoading={isLoading}
                isDisabled={isDisabled}
                onPress={onPress}
              />
            );
          })}
        </Bag.RewardsRail>
      ) : null}

      {isLoyaltyV2Enabled && rewards.length > 1 ? (
        <DotsIndicator
          length={rewards.length}
          active={currentlyScrolledReward}
        />
      ) : null}

      {isLoyaltyV2Disabled && hasRewards ? (
        <Bag.RewardsRail
          heading={rewardsPromoHeading}
          headingIcon="IconRewardFill"
        >
          {rewards.map((reward, index) => {
            const isUnredeemable = Boolean(reward.redeemableAt);
            const isPremium = reward.rewardType === RewardType.Premium;
            const isApplied = appliedRewardId === reward.id;
            const isLoading = rewardIdBeingUpdated === reward.id;
            const isDisabled = isPerformingBagAction && !isLoading;
            const onPress = isApplied ? removeBagReward : applyBagReward;

            return (
              <Bag.Reward
                index={index}
                key={reward.id}
                id={reward.id}
                title={reward.name}
                expirationDate={reward.expirationDate}
                redeemableAt={reward.redeemableAt}
                isPremium={Boolean(isPremium && !isUnredeemable)}
                isApplied={isApplied}
                isLoading={isLoading}
                isDisabled={isDisabled}
                onPress={onPress}
              />
            );
          })}
        </Bag.RewardsRail>
      ) : null}

      {shouldShowLoadingIndicator ? <LoadingDots /> : null}

      {shouldShowEmptyState ? <Bag.RewardsEmptyState /> : null}

      {shouldShowPromoCodeField ? (
        <Bag.PromoField
          rewards={rewards}
          isLoading={isApplyingPromoCode}
          onPress={applyBagPromoCode}
          isDisabled={Boolean(isPerformingBagAction && !isApplyingPromoCode)}
        />
      ) : null}
    </Bag.PromoRewardsContainer>
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────

type BagLoyaltyProps = {
  orderState: ReturnType<typeof useOrderState>;
};

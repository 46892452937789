import React, { createContext, useContext, useMemo } from 'react';
import type { ViewProps } from 'react-native';

import { useCustomerData, useSignOut } from './hooks';

// ────────────────────────────────────────────────────────────────────────────────

const CustomerContext = createContext<CustomerCtx | undefined>(undefined);

export const CustomerProvider = (props: Pick<ViewProps, 'children'>) => {
  const customerDataCtx = useCustomerData();
  const { customer } = customerDataCtx;

  const signOutCtx = useSignOut({ customer });

  // ────────────────────────────────────────────────────────────────────

  const value = useMemo(
    () => ({
      ...customerDataCtx,
      ...signOutCtx,
    }),
    [customerDataCtx, signOutCtx],
  );

  return (
    <CustomerContext.Provider value={value}>
      {props.children}
    </CustomerContext.Provider>
  );
};
// ────────────────────────────────────────────────────────────────────────────────

export const useCustomer = () => {
  const context = useContext(CustomerContext);

  if (context === undefined) {
    throw new Error('useCustomer must be used within a <CustomerProvider>');
  }

  return context;
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type CustomerCtx = ReturnType<typeof useCustomerData> &
  ReturnType<typeof useSignOut>;

import React, { type ComponentProps } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Scrollspy } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuCategoriesGridNavItem = (
  props: MenuCategoriesGridContainerProps,
) => {
  const { category, ...restProps } = props;

  const { formatMessage } = useIntl();

  return (
    <Scrollspy.NavItem
      accessibilityLabel={formatMessage(messages.a11yLabel, { category })}
      {...restProps}
    >
      {category}
    </Scrollspy.NavItem>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  a11yLabel: {
    defaultMessage: 'Scroll to the "{category}" category',
    description: 'Menu | Categories Nav | Nav Item | a11y label',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuCategoriesGridContainerProps = { category: string } & Omit<
  ComponentProps<typeof Scrollspy.NavItem>,
  'children' | 'accessibilityLabel'
>;

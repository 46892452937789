import { clientInstance } from '../../client';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Initializes MSAL client instance.
 */
export async function initialize() {
  return clientInstance?.initialize();
}

import React, { useCallback } from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { BodyText, Switch, TextField, theme } from '@sg/garnish';

import { getCreditCardIcon, PAYMENT_ADD } from '@order/constants';
import type { CardType } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { CreditCardFormApplePay } from './CreditCardFormApplePay';
import { CreditCardFormBlockedFields } from './CreditCardFormBlockedFields';
import { CreditCardFormFooter } from './CreditCardFormFooter';
import { CreditCardFormLineItem } from './CreditCardFormLineItem';

/**
 * Section of the credit card form controlled by sweetgreen.
 * As opposed to the sections controlled by Stripe / Braintree.
 */
export const CreditCardFormControlledContent = (props: ControlledFormProps) => {
  const {
    children,
    description,
    cardType = PAYMENT_ADD,
    isSaving,
    isDeleting,
    isApplePayReady,
    mode,
    canSubmitForm,
    form,
    excludedFields = [],
    onFormChange,
    onSubmitForm,
    onApplePay,
    onDelete,
  } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();

  // ─── Callbacks ───────────────────────────────────────────────────────

  const updateFormField = useCallback(
    (field: CreditCardField) => (value: string | number | boolean) => {
      onFormChange({ ...form, [field]: value });
    },
    [form, onFormChange],
  );

  return (
    <View testID="credit-card-form" style={styles.container}>
      <View style={styles.content}>
        {mode === 'add' && isApplePayReady ? (
          <CreditCardFormApplePay onSetApplePayAsDefault={onApplePay} />
        ) : null}

        <View style={styles.frameContainer}>{children}</View>

        {/* Used when editing credit card, fields disabled */}
        <CreditCardFormBlockedFields
          show={mode === 'edit'}
          description={description}
          cardType={getCreditCardIcon(cardType)}
        />

        {/* Nickname field, not controlled by Braintree */}
        {!excludedFields.includes('nickname') && (
          <CreditCardFormLineItem>
            <TextField
              testID="credit-card-form.nickname"
              label={t('credit-card-form.nickname')}
              value={form.nickname}
              onChangeText={updateFormField('nickname')}
            />
          </CreditCardFormLineItem>
        )}

        {/* Default credit card switch, not controlled by Braintree */}
        {!excludedFields.includes('isDefault') && (
          <CreditCardFormLineItem>
            <View style={styles.row}>
              <View style={styles.column}>
                <BodyText size={3}>{t('credit-card-form.default')}</BodyText>
              </View>

              <Switch
                testID="credit-card-form.default"
                value={form.default}
                accessibilityLabel={t('credit-card-form.default')}
                onChange={updateFormField('default')}
              />
            </View>
          </CreditCardFormLineItem>
        )}
      </View>

      {/* Save / Remove footer, not controlled by Braintree */}
      <CreditCardFormFooter
        mode={mode}
        isSaving={isSaving}
        isDeleting={isDeleting}
        canSubmit={canSubmitForm}
        onDelete={onDelete}
        onSubmit={onSubmitForm}
      />
    </View>
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type CreditCardField = keyof CreditCardForm;
type CreditCardForm = Readonly<{
  nickname: string;
  default: boolean;
}>;

type ControlledFormProps = Readonly<{
  children?: ViewProps['children'];
  description?: string;
  cardType?: CardType;
  isSaving?: boolean;
  isDeleting?: boolean;
  isApplePayReady?: boolean;
  canSubmitForm: boolean;
  form: CreditCardForm;
  mode: 'add' | 'edit' | 'replace';
  excludedFields?: ReadonlyArray<'nickname' | 'isDefault'>;
  onFormChange: (form: CreditCardForm) => void;
  onSubmitForm: () => void;
  onApplePay?: () => void;
  onDelete?: () => void;
}>;

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    gap: theme.spacing['6'],
  },
  content: {
    flex: 1,
    gap: theme.spacing['6'],
  },
  frameContainer: {
    position: 'relative',
  },
  column: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
});

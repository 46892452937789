import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../hooks';
import { Container } from '../Container';
import { DisplayText } from '../Text';

// ────────────────────────────────────────────────────────────────────────────────

export const LargeHeaderTitle = (props: LargeHeaderTitleProps) => {
  const { testID, children, style } = props;

  const { minWidth, match } = useResponsive();

  const containerStyle = [
    styles.container,
    minWidth.isSM && styles.containerSM,
    style,
  ];
  const titleStyle = match([styles.titleXS, styles.titleSM]);

  // ─────────────────────────────────────────────────────────────────

  return (
    <Container style={containerStyle} testID={testID}>
      <DisplayText size={1} style={titleStyle}>
        {children}
      </DisplayText>
    </Container>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingBottom: theme.spacing['10'],
  },
  containerSM: {
    paddingTop: theme.spacing['10'],
  },
  titleXS: {
    paddingBottom: theme.spacing['2'],
  },
  titleSM: {
    paddingTop: theme.spacing['2'],
    paddingBottom: theme.spacing['2'],
  },
});

// ────────────────────────────────────────────────────────────────────────────────

type LargeHeaderTitleProps = Pick<ViewProps, 'testID' | 'children' | 'style'>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GiftCardRedemptionDetailQueryVariables = Types.Exact<{
  giftCardRedemptionDetailId: Types.Scalars['String']['input'];
}>;

export type GiftCardRedemptionDetailQuery = {
  readonly __typename?: 'Query';
  readonly giftCardRedemptionDetail:
    | { readonly __typename: 'GiftCardNotFoundError' }
    | {
        readonly __typename: 'GiftCardRedemptionDetailSuccess';
        readonly balance: number;
        readonly assetId: string | null;
        readonly cardNumber: string;
        readonly cardRegCode: string;
        readonly recipientName: string;
        readonly recipientMessage: string | null;
        readonly senderName: string;
        readonly redeemId: string;
      };
};

export const GiftCardRedemptionDetailDocument = gql`
  query GiftCardRedemptionDetail($giftCardRedemptionDetailId: String!) {
    giftCardRedemptionDetail(id: $giftCardRedemptionDetailId) {
      __typename
      ... on GiftCardRedemptionDetailSuccess {
        balance
        assetId
        cardNumber
        cardRegCode
        recipientName
        recipientMessage
        senderName
        redeemId
      }
    }
  }
`;

export function useGiftCardRedemptionDetailQuery(
  options: Omit<
    Urql.UseQueryArgs<GiftCardRedemptionDetailQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GiftCardRedemptionDetailQuery,
    GiftCardRedemptionDetailQueryVariables
  >({ query: GiftCardRedemptionDetailDocument, ...options });
}

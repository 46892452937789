import { useIsFocused } from '@react-navigation/native';

import { useIsLoggedIn, useIsLoggingIn } from '@order/AuthMachine';
import { useCustomer } from '@order/Customer';
import type { Location } from '@order/graphql';
import {
  useDeliveryRestaurantQuery,
  useInStoreOrderLastPendingFeedbackQuery,
} from '@order/graphql';
import { useCart } from '@order/hooks';
import { useOrders } from '@order/Order';
import {
  checkIfExpiredOrder,
  checkIfPastOrder,
  checkIfPendingOrder,
} from '@order/OrderStatus';

import { useGetOrderStatusQuery } from '../../GraphQL/OrderStatus.query.generated';

export const useHomeData = () => {
  const isScreenFocused = useIsFocused();

  // ─── Cart & Customer ────────────────────────────────────────────

  const { isCartEmpty, cartOrderType } = useCart();
  const { customer, isFetchingCustomer } = useCustomer();
  const customerAddress = customer?.addresses?.[0];

  // ─── Auth ────────────────────────────────────────────────────────

  const isLoggedIn = useIsLoggedIn();
  const isLoggingIn = useIsLoggingIn();
  const isCustomerReady =
    !isLoggingIn &&
    !isFetchingCustomer &&
    (!isLoggedIn || Boolean(customer?.id));

  // ─── Order History ──────────────────────────────────────────────

  const { orders, isFetchingOrders, hasErrorOrders } = useOrders({
    pause: !isScreenFocused || !isLoggedIn,
  });

  // ─── In Store Order Last Pending Feedback ───────────────────────

  const [response] = useInStoreOrderLastPendingFeedbackQuery({
    requestPolicy: 'cache-and-network',
    pause: !isScreenFocused || !isLoggedIn,
  });
  const inStoreOrderPendingFeedbackId =
    response?.data?.inStoreOrderLastPendingFeedback?.id;
  const inStoreOrderPendingFeedbackWantedTime =
    response?.data?.inStoreOrderLastPendingFeedback?.wantedTime;

  // ─── Order Status ───────────────────────────────────────────────

  const [statusResponse] = useGetOrderStatusQuery({
    requestPolicy: 'network-only',
    pause: !isScreenFocused || !isLoggedIn,
  });
  const hasErrorStatus = Boolean(statusResponse.error);
  const isLoadingStatus = statusResponse.fetching || statusResponse.stale;

  const status = isLoadingStatus ? undefined : statusResponse.data;

  // ─── Order Status Derived Data ──────────────────────────────────

  const { error: orderStatusError, data: orderStatusData } = statusResponse;

  const lastOrderResponse = orderStatusData?.orderStatus;
  const lastOrder =
    lastOrderResponse?.__typename === 'OrderStatus'
      ? lastOrderResponse
      : undefined;

  const isLoadingInitialOrderStatus = isLoggedIn
    ? !orderStatusError && orderStatusData === undefined
    : false;
  const isLastOrderCompleted = lastOrder
    ? checkIfPastOrder({
        order: lastOrder.order,
        flattenedOrderStatus: lastOrder.flattenedOrderStatus,
      })
    : false;
  const isLastOrderExpired = checkIfExpiredOrder({
    order: lastOrder?.order,
    hoursInThePast: 3,
  });
  const isLastOrderRecentlyCompleted =
    isLastOrderCompleted && !isLastOrderExpired;
  const isLastOrderPending = lastOrder?.flattenedOrderStatus
    ? checkIfPendingOrder(lastOrder.flattenedOrderStatus)
    : false;
  const isLastOrderPendingFeedback =
    isLastOrderRecentlyCompleted &&
    lastOrder?.order?.isPendingFeedback === true;
  const isLastOrderActive = isLastOrderPending || isLastOrderPendingFeedback;

  // ─── Delivery Restaurant ────────────────────────────────────────

  const customerDeliveryAddressId = customerAddress?.id ?? '';
  const [restaurantResponse] = useDeliveryRestaurantQuery({
    variables: { input: { addressId: customerDeliveryAddressId } },
    pause: !isScreenFocused || !customerDeliveryAddressId || !isLoggedIn,
  });

  const isLoadingDeliveryRestaurant =
    restaurantResponse.fetching || restaurantResponse.stale;
  const locationByAddressResponse = restaurantResponse.data
    ?.locationByAddress as Location;
  const deliveryRestaurant = locationByAddressResponse?.restaurant;
  const deliveryVendor = locationByAddressResponse?.vendor;

  return {
    orders,
    status,
    inStoreOrderPendingFeedbackId,
    inStoreOrderPendingFeedbackWantedTime,
    deliveryRestaurant,
    deliveryVendor,
    cartOrderType,
    isCartEmpty,
    isLoggedIn,
    isCustomerReady,
    isLoading: !isCustomerReady || isLoggingIn || isLoadingDeliveryRestaurant,
    isLoadingOrders: isFetchingOrders,
    isLoadingStatus,
    isLoadingInitialOrderStatus,
    hasErrors: hasErrorOrders || hasErrorStatus,
    isLastOrderActive,
  };
};

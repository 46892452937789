import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BenefitCard, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const SweetpassBenefitCard = (
  props: SweetpassLandingPageBenefitsCardProps,
) => {
  const { title, text, imgUrl, imgA11yLabel, withTopBorder, isUnavailable } =
    props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    withTopBorder ? styles.containerWithTopBorder : undefined,
  ];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={containerStyles}>
      <BenefitCard
        title={title}
        text={text}
        imgA11yLabel={imgA11yLabel}
        imgUrl={imgUrl}
        isUnavailable={isUnavailable}
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  containerWithTopBorder: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.LIGHT,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassLandingPageBenefitsCardProps = Omit<
  ComponentProps<typeof BenefitCard>,
  'imgSource'
> &
  Readonly<{
    imgUrl?: string;
    withTopBorder?: boolean;
  }>;

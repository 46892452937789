import type { ReactElement } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { Circle } from 'react-native-svg';
import { COLORS } from '@garnish/constants';

/**
 * Note: this is implemented as an SVG because border radius has rendering
 * problems that make small circles look "bumpy" on web -- and SVGs are smooth
 */
export const Dot = (props: Readonly<{ color?: string }>): ReactElement => {
  const { color = COLORS.WHITE } = props;

  return (
    <View style={styles.dot}>
      <Svg height="100%" width="100%" viewBox="0 0 100 100">
        <Circle cx="50" cy="50" r="50" fill={color} />
      </Svg>
    </View>
  );
};

const styles = StyleSheet.create({
  dot: {
    marginHorizontal: 1,
    width: 5,
    height: 5,
  },
});

import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { BodyText } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardConfirmationGiftCardSummary = (
  props: GiftCardConfirmationGiftSummaryProps,
) => {
  const { balance, recipientName } = props;

  const { formatNumber } = useIntl();

  const formattedBalance = formatNumber(balance / 100, {
    style: 'currency',
    currency: 'USD',
  }).split('.')?.[0];

  return (
    <BodyText sizeMatch={['16']} bold style={styles.text}>
      <FormattedMessage
        {...messages.summary}
        values={{ balance: formattedBalance, recipient: recipientName }}
      />
    </BodyText>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  summary: {
    defaultMessage: '{balance} gift card to {recipient}',
    description: 'Gift Card Confirmation Screen | Gift Card Summary Text',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  text: {
    textAlign: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardConfirmationGiftSummaryProps = {
  balance: number; // In cents
  recipientName: string;
};

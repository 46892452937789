import { type ComponentProps } from 'react';
import type { Asset } from 'contentful';
import { type Button, getContentfulImageUrl, useResponsive } from '@sg/garnish';

import { useContentfulContentTypeEntry } from '@order/Contentful';
import { CustomProductType, type PartialProduct } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

/**
 * Returns contentful data for the Custom Product (CYO) Banner Card
 *
 * @see: https://app.contentful.com/spaces/wme4s8lvzccr/content_types/productBannerCard
 */
export function useCustomProductBannerCardContentfulData({
  customProduct,
  pause,
}: UseCustomProductBannerCardContentfulDataProps) {
  const { t } = useLocalizationContext();
  const { match } = useResponsive();
  const customType = customProduct.customType ?? CustomProductType.Bowl;
  const fallback: ProductBannerCardContentfulData = {
    name: 'fallback',
    heading: t('menu.cyo.title'),
    body: t('menu.cyo.description'),
    buttonLabel: t('menu.cyo.cta'),
  };

  const { data: contentfulData, fetching: isLoadingContentfulData } =
    useContentfulContentTypeEntry<ProductBannerCardContentfulData>({
      contentType: PRODUCT_BANNER_CARD_CONTENTFUL_ID,
      tags: [`cyo_${customType.toLowerCase()}`],
      pause,
    });

  const bgImageUrl = contentfulData?.fields?.backgroundImage?.fields?.file?.url;
  const backgroundImage = bgImageUrl
    ? getContentfulImageUrl(bgImageUrl, {
        fit: 'fill',
        w: match([720, 1024, 1440]),
        h: match([720, 1024, 1440]) / 2,
        q: 80,
      })
    : undefined;

  return {
    isLoadingContentfulData,
    name: contentfulData?.fields?.name ?? fallback.name,
    heading: contentfulData?.fields?.heading ?? fallback.heading,
    body: contentfulData?.fields?.body ?? fallback.body,
    backgroundImage,
    backgroundOverlayColor: contentfulData?.fields?.backgroundOverlayColor,
    buttonLabel: contentfulData?.fields?.buttonLabel ?? fallback.buttonLabel,
    textColor: contentfulData?.fields?.textColor,
    buttonPalette: contentfulData?.fields?.buttonPalette,
  };
}

// ─── Constants ────────────────────────────────────────────────────

const PRODUCT_BANNER_CARD_CONTENTFUL_ID = 'productBannerCard';

// ─── Types ────────────────────────────────────────────────────────

type UseCustomProductBannerCardContentfulDataProps = {
  customProduct: PartialProduct;
  pause?: boolean;
};

export type ProductBannerCardContentfulData = Readonly<{
  name: string;
  heading: string;
  body: string;
  backgroundImage?: Asset;
  backgroundOverlayColor?: string;
  buttonLabel: string;
  textColor?: string;
  buttonPalette?: ComponentProps<typeof Button>['palette'];
}>;

import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { BodyText, HStack, theme, useResponsive, VStack } from '@sg/garnish';

import { SweetpassBenefitCard } from '@order/components';

import type {
  SweetpassBenefitList,
  SweetpassBenefitsPageBenefitCtaHandler,
} from './hooks';

export const SweetpassBenefitsPageBenefitsContent = ({
  content,
  ctaHandler,
}: SweetpassBenefitsPageBenefitsContentProps) => {
  const { currentBreakpoint, match } = useResponsive();
  const DynamicStack = currentBreakpoint.isXS ? VStack : HStack;
  const areBenefitsUnavailable = content.palette === 'greyed-out';

  const gridSpacing = match([0, theme.spacing['6'], theme.spacing['10']]);
  const cardContainerStyles = match([undefined, styles.cardContainerSM]);

  return (
    <View style={styles.container}>
      <BodyText size={1} style={styles.benefitTitle}>
        {content.title}
      </BodyText>
      <DynamicStack
        testID={`sweetpass-benefits-page-benefits-list-wrapper${
          currentBreakpoint.isXS ? '-mobile' : ''
        }`}
        gap={gridSpacing}
        itemsPerRow={3}
        dividerColor={theme.colors.LIGHT}
        hasDivider
      >
        {content.benefits.map((benefit) => (
          <View key={benefit.id} style={cardContainerStyles}>
            {benefit.action ? (
              <Pressable
                testID={`sweetpass-benefits-page-benefit-${benefit.id}-cta`}
                accessibilityRole="button"
                onPress={() => {
                  ctaHandler(benefit.action);
                }}
              >
                <SweetpassBenefitCard
                  title={benefit.titleText}
                  text={benefit.bodyText}
                  imgA11yLabel={benefit.image.description}
                  imgUrl={benefit.image.url}
                />
              </Pressable>
            ) : (
              <SweetpassBenefitCard
                title={benefit.titleText}
                text={benefit.bodyText}
                imgA11yLabel={benefit.image.description}
                imgUrl={benefit.image.url}
                isUnavailable={areBenefitsUnavailable}
              />
            )}
          </View>
        ))}
      </DynamicStack>
    </View>
  );
};

type SweetpassBenefitsPageBenefitsContentProps = Readonly<{
  content: SweetpassBenefitList;
  ctaHandler: SweetpassBenefitsPageBenefitCtaHandler;
}>;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingTop: theme.spacing['10'],
  },
  cardContainerSM: {
    alignItems: 'center',
    paddingTop: theme.spacing['2'],
    paddingBottom: theme.spacing['2'],
  },
  benefitTitle: {
    paddingBottom: theme.spacing['2'],
    marginBottom: theme.spacing['4'],
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.NEUTRAL_3,
    color: theme.colors.GREEN_1,
  },
});

import React from 'react';

import { useLocalizationContext } from '@order/Localization';

import {
  AccountScreenContainer,
  AccountScreenSection,
  AccountScreenTitle,
} from '../AccountScreenContainer';
import { SkeletonLoadingContentLG } from './AccountSkeletonLoading';

export const AccountFavoritesLoading = () => {
  const { t } = useLocalizationContext();

  return (
    <AccountScreenContainer screen="favorites">
      <AccountScreenTitle title={t('account.favorites.title')} />

      <AccountScreenSection>
        {/* Favorites */}
        <SkeletonLoadingContentLG />
        <SkeletonLoadingContentLG />
        <SkeletonLoadingContentLG />
        <SkeletonLoadingContentLG />
      </AccountScreenSection>
    </AccountScreenContainer>
  );
};

import React, { useMemo } from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  withSpring,
} from 'react-native-reanimated';
import Svg, { Path } from 'react-native-svg';
import { theme } from '@garnish/constants';

import { MARKER_SIZE } from '../constants';
import { PinWrapper } from './PinWrapper';

export const MinimizedPin = (props: MinimizedPinProps) => {
  const { strokeColor, balloonColor, ballColor } = props;

  return (
    <PinWrapper>
      <Svg
        width={MARKER_SIZE}
        height={MARKER_SIZE}
        viewBox={`0 0 ${MARKER_SIZE} ${MARKER_SIZE}`}
      >
        <Path
          d="M13.544 33.942C8.725 28.847 7.5 25.886 7.5 20.447 7.5 10.811 14.886 4 24 4c9.113 0 16.5 6.811 16.5 16.447 0 5.436-1.637 8.837-6.036 13.488L24 44 13.544 33.942z"
          fill={ballColor}
          stroke={strokeColor}
        />
        <Path
          d="M7.5 20.447c0 5.44 1.226 8.4 6.044 13.495L24 44l10.464-10.065c4.399-4.65 6.036-8.052 6.036-13.488C40.5 10.811 33.113 4 24 4 14.886 4 7.5 10.811 7.5 20.447zm22-.647c0 3.093-2.462 5.6-5.5 5.6-3.037 0-5.5-2.507-5.5-5.6 0-3.093 2.463-5.6 5.5-5.6 3.038 0 5.5 2.507 5.5 5.6z"
          fill={balloonColor}
          stroke={theme.colors.OATMEAL}
          strokeWidth="1"
        />
      </Svg>
    </PinWrapper>
  );
};

export const MinimizingPin = (props: MinimizingPinProps & ViewProps) => {
  const { isMinimized, isOpen, damping, stiffness, children } = props;

  // ─── Animated values ─────────────────────────────────────────────

  const sizeAnimationConfig = useMemo(
    () => getAnimationConfig({ damping, stiffness }),
    [damping, stiffness],
  );
  const size = useDerivedValue(() => {
    return withSpring(isMinimized ? 0.3 : 1, sizeAnimationConfig);
  }, [isMinimized, sizeAnimationConfig]);

  // ─── Styles ──────────────────────────────────────────────────────

  const minimizedPinAnimatedStyles = useAnimatedStyle(() => {
    return {
      transform: [
        { scaleX: size.value },
        { scaleY: size.value },
        { translateY: convertScaleToPosition(size.value) },
      ],
      opacity: invertOpacity(convertScaleToOpacity(size.value)),
    };
  });

  const pinAnimatedStyles = useAnimatedStyle(() => {
    return {
      transform: [
        { scaleX: size.value },
        { scaleY: size.value },
        { translateY: convertScaleToPosition(size.value) },
      ],
      opacity: convertScaleToOpacity(size.value),
    };
  });

  // ─────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.pin, minimizedPinAnimatedStyles]}>
        <MinimizedPin
          strokeColor={isOpen ? theme.colors.GREEN_4 : theme.colors.NEUTRAL_4}
          balloonColor={isOpen ? theme.colors.GREEN_4 : theme.colors.NEUTRAL_4}
          ballColor={isOpen ? MINIMIZED_PIN_OPEN : MINIMIZED_PIN_CLOSED}
        />
      </Animated.View>

      <Animated.View style={pinAnimatedStyles}>{children}</Animated.View>
    </View>
  );
};

// As the size decreases from x1 to x0.3, the position must increase from 0 to 48px.
// 1 -> 0 | 0.5 -> 24 | 0 -> 48.
const convertScaleToPosition = (
  scale: number,
  maxScale = 1,
  minScale = 0.3,
  maxPosition?: number,
) => {
  'worklet';

  return (1 - scale) * ((maxPosition ?? MARKER_SIZE) / (maxScale - minScale));
};

// As the size decreases from x1 to x0.3, the opacity must decrease from 1 to 0.
// 1 -> 1 | 0.5 -> 0.65 | 0.3 -> 0
const convertScaleToOpacity = (
  scale: number,
  maxScale = 1,
  minScale = 0.3,
  maxOpacity = 1,
) => {
  'worklet';

  return (
    (scale - minScale) * (maxOpacity * (maxOpacity / (maxScale - minScale)))
  );
};

// Inverts opacity value.
// 1 -> 0 | 0.5 -> 0.5 | 0 -> 1
const invertOpacity = (scale: number) => {
  'worklet';

  return Math.abs(1 - scale);
};

const getAnimationConfig = (
  options: Pick<MinimizingPinProps, 'stiffness' | 'damping'>,
) => {
  const { damping, stiffness } = options;

  return {
    ...ANIMATION_CONFIG,
    damping: damping ?? ANIMATION_CONFIG.damping,
    stiffness: stiffness ?? ANIMATION_CONFIG.stiffness,
  };
};

const MINIMIZED_PIN_OPEN = '#90A590';
const MINIMIZED_PIN_CLOSED = '#ADAFA5';
const ANIMATION_CONFIG = {
  damping: 18,
  stiffness: 300,
  mass: 1,
  overshootClamping: false,
  restSpeedThreshold: 0.5,
  restDisplacementThreshold: 0.01,
  velocity: 0.2,
};
const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flex: 1,
    width: MARKER_SIZE,
    height: MARKER_SIZE,
  },
  pin: {
    position: 'absolute',
  },
});

type MinimizedPinProps = Readonly<{
  strokeColor: string;
  balloonColor: string;
  ballColor: string;
}>;

type MinimizingPinProps = Readonly<{
  isMinimized?: boolean;
  isOpen?: boolean;
  damping?: number;
  stiffness?: number;
}>;

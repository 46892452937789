import { useCallback } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';

export const useCustomScreenTitle = (
  title: string,
  shouldUsePostfix = true,
) => {
  const navigation = useNavigation();

  useFocusEffect(
    useCallback(() => {
      if (!title) return;

      const formattedTitle = shouldUsePostfix ? `${title} | sweetgreen` : title;

      navigation.setOptions({ customDocumentTitle: formattedTitle });
    }, [navigation, title, shouldUsePostfix]),
  );
};

import { config } from '@order/AzureAuth';

import { clientInstance } from '../../client';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Reset a Possible Stale Session
 */
export async function removeStaleSession() {
  const activeAccount = clientInstance?.getActiveAccount();

  if (!activeAccount) return;

  // NOTE: This will force MSAL to invalidate any stale state
  sessionStorage.removeItem('msal.interaction.status');

  await clientInstance?.logoutRedirect({
    account: activeAccount,
    postLogoutRedirectUri: config.redirectUri,
  });
}

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../hooks';

/**
 * Returns Modal bottom spacing with respect of safe area bottom inset.
 * Since modal is rendered in the center for larger breakpoints,
 * we only use dynamic bottom spacing for the `XS` breakpoint.
 */
export const useModalBottomSpacingStyle = () => {
  const { currentBreakpoint } = useResponsive();
  const { isXS } = currentBreakpoint;
  const { bottom: safeInset } = useSafeAreaInsets();

  const shouldUseDynamicBottomSpacing = isXS && safeInset > 0;
  const isInsetLargerThanStandard = safeInset > STANDARD_SPACING;
  const insetDifference = STANDARD_SPACING - safeInset;
  const dynamicBottomSpacing = isInsetLargerThanStandard ? 0 : insetDifference;

  const bottomSpacing = shouldUseDynamicBottomSpacing
    ? dynamicBottomSpacing
    : STANDARD_SPACING;

  return useStyle(() => ({ paddingBottom: bottomSpacing }), [bottomSpacing]);
};

// ─── Constants ───────────────────────────────────────────────────────────────

const STANDARD_SPACING = theme.spacing['6'];

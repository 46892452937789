/* eslint-disable @typescript-eslint/consistent-type-imports, @typescript-eslint/consistent-type-assertions */

import { isFuture } from 'date-fns';
import { createMachine } from 'xstate';
import { assign } from 'xstate/lib/actions';

import { ignoreTimezone } from '@order/utils';

import {
  OrdersHistoryMachineContext,
  OrdersHistoryMachineEvents,
  OrdersHistoryMachineServices,
  OrderWithIdAndWantedTime,
} from './orders-history-machine.types';
import {
  ordersHistoryMachineLogger,
  sortOrdersById,
  withoutDuplicateOrders,
} from './orders-history-machine.utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A state machine to manage the user's placed orders history with
 * pagination support.
 *
 * It fetches the user's orders and determines whether any of them can be
 * canceled via provided services. It also uses guards and local-only actions
 * to prevent data over-fetching.
 *
 * NOTE: The machine is also "restartable", which means it can be restarted
 *       at any point during its flow by resetting the context and fetching
 *       the initial orders.
 *
 * NOTE: The machine requires external services to retrieve orders and
 *       cancellation statuses.
 *
 * NOTE: The initial state can be passed to the machine as an argument, however,
 *       it is usually used for testing.
 */
export const createOrdersHistoryMachine = <
  Order extends OrderWithIdAndWantedTime,
>(
  initialState:
    | 'waiting'
    | 'idle'
    | 'fetchingInitialOrders'
    | 'fetchingMoreOrders' = 'waiting',
) => {
  return createMachine(
    {
      tsTypes: {} as import('./orders-history-machine.typegen').Typegen0,
      predictableActionArguments: true,
      preserveActionOrder: true,
      id: 'orders-history-state-machine',
      schema: {
        context: {} as OrdersHistoryMachineContext<Order>,
        events: {} as OrdersHistoryMachineEvents,
        services: {} as OrdersHistoryMachineServices<Order>,
      },
      context: {
        orders: [],
        futureOrders: [],
        ordersPerPage: 10,
        page: 1,
        canFetchMoreOrders: false,
      },
      initial: initialState,
      states: {
        waiting: {
          description: 'An empty state used for the initial setup.',
        },
        idle: {
          id: 'idle',
          description: 'An idle state that awaits the next event.',
          on: {
            FETCH_MORE_ORDERS: {
              description: 'Fetch more orders if possible.',
              target: '#fetching-more-orders',
              actions: ['increasePage'],
              cond: 'checkIfCanFetchMoreOrders',
            },
            UPDATE_CANCELLED_ORDER: {
              description: 'Updates an existing order when it is cancelled.',
              actions: ['updateCancelledOrderStatus', 'setFutureOrders'],
            },
          },
        },
        fetchingInitialOrders: {
          id: 'fetching-initial-orders',
          initial: 'orders',
          states: {
            orders: {
              description: 'A state for fetching initial orders.',
              invoke: {
                src: 'fetchOrders',
                onDone: {
                  target: 'cancellableOrdersIfNeeded',
                  actions: ['onFetchOrdersDone', 'setFutureOrders'],
                },
              },
            },
            cancellableOrdersIfNeeded: {
              description:
                'A transient state that determines whether the machine should fetch future order cancellation states.',
              always: [
                {
                  target: 'cancellableOrders',
                  cond: 'checkIfHasFutureOrders',
                },
                { target: '#idle' },
              ],
            },
            cancellableOrders: {
              description:
                'A state for fetching future orders cancellation states.',
              invoke: {
                src: 'fetchCancellableOrders',
                onDone: {
                  target: '#idle',
                  actions: ['onFetchCancellableOrdersDone'],
                },
              },
            },
          },
        },
        fetchingMoreOrders: {
          id: 'fetching-more-orders',
          initial: 'orders',
          states: {
            orders: {
              description: 'A state for fetching more orders.',
              invoke: {
                src: 'fetchOrders',
                onDone: {
                  target: 'cancellableOrdersIfNeeded',
                  actions: ['onFetchOrdersDone', 'setFutureOrders'],
                },
              },
            },
            cancellableOrdersIfNeeded: {
              description:
                'A transient state that determines whether the machine should fetch future order cancellation states.',
              always: [
                {
                  target: 'cancellableOrders',
                  cond: 'checkIfHasFutureOrders',
                },
                { target: '#idle' },
              ],
            },
            cancellableOrders: {
              description:
                'A state for fetching future orders cancellation states.',
              invoke: {
                src: 'fetchCancellableOrders',
                onDone: {
                  target: '#idle',
                  actions: ['onFetchCancellableOrdersDone'],
                },
              },
            },
          },
        },
      },
      on: {
        START: {
          description:
            'Starts/restarts the machine by fetching the initial orders and clearing the state.',
          target: '#fetching-initial-orders',
          actions: ['resetContext'],
        },
      },
    },
    {
      actions: {
        /**
         * Stores the resolved orders in the context.
         */
        onFetchOrdersDone: assign({
          orders(context, event) {
            const { orders: existingOrders } = context;
            const { orders } = event.data;

            const updatedOrders = [...existingOrders, ...orders];

            return sortOrdersById(withoutDuplicateOrders(updatedOrders));
          },
          canFetchMoreOrders(context, event) {
            const { page } = context;
            const { lastPage } = event.data;

            return page < lastPage;
          },
        }),

        /**
         * Updates stored orders that can be canceled.
         */
        onFetchCancellableOrdersDone: assign({
          orders(context, event) {
            const { orders } = context;
            const { data: cancellableOrders } = event;

            if (cancellableOrders.length === 0) return orders;

            const ordersThatCanBeCancelled = new Set(cancellableOrders);

            return orders.map((order) => ({
              ...order,
              canCancel: ordersThatCanBeCancelled.has(order.id),
            }));
          },
        }),

        increasePage: assign({
          page: (context) => context.page + 1,
        }),

        /**
         * Sets future orders by checking the wanted time.
         *
         * These can be used to retrieve their cancellation status and
         * avoid over-fetching.
         */
        setFutureOrders: assign({
          futureOrders(context) {
            const { orders } = context;

            return orders.filter((order) => {
              const isOrderPending = !order.isCanceled;
              const wantedTime = ignoreTimezone(order.wantedTime);
              const isValidFutureOrder = Boolean(
                wantedTime && isFuture(wantedTime),
              );

              return isOrderPending && isValidFutureOrder;
            });
          },
        }),

        /**
         * Updates existing order based on its cancellation status.
         */
        updateCancelledOrderStatus: assign({
          orders(context, event) {
            const { orders } = context;
            const { orderId } = event;

            const targetOrder = orders.find((order) => order.id === orderId);

            if (targetOrder !== undefined) {
              return [...orders].map((order) => {
                if (order.id !== targetOrder.id) return order;

                return { ...order, canCancel: false, isCanceled: true };
              });
            }

            return orders;
          },
        }),

        /**
         * Resets the context of the machine.
         *
         * Can be used to clear the state before restarting the machine.
         */
        resetContext: assign({
          orders: [],
          futureOrders: [],
          page: 1,
          canFetchMoreOrders: false,
        }),
      },
      services: {
        async fetchOrders(_context) {
          ordersHistoryMachineLogger.debug(
            'Please provide `fetchOrders` service.',
          );

          return { orders: [], lastPage: 1 };
        },
        async fetchCancellableOrders(_context) {
          ordersHistoryMachineLogger.debug(
            'Please provide `fetchCancellableOrders` service.',
          );

          return [];
        },
      },
      guards: {
        checkIfCanFetchMoreOrders: (context) => context.canFetchMoreOrders,
        checkIfHasFutureOrders: (context) => context.futureOrders.length > 0,
      },
    },
  );
};

import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { useChallengesAndRewards } from '@order/ChallengesAndRewards';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { SweetpassUpsellCta } from '../../../SweetpassUpsellCta';

// ─────────────────────────────────────────────────────────────────────────────

export const BagSweetpassUpgradeCta = (props: BagSweetpassUpgradeCtaProps) => {
  const { location } = props;
  const { rewards = [] } = useChallengesAndRewards();

  const hasRewards = rewards.length > 0;

  // NOTE: We display different content depending on whether there are
  //       any available rewards.
  const contentTagBasedOnRewards: SweetpassUpsellCtaTag = hasRewards
    ? 'sweetpassUpsellCtaActiveOrder'
    : 'sweetpassUpsellCtaDefault';

  // ─── Telemetry Helpers ───────────────────────────────────────────────

  const telemetryPayloadBasedOnLocation: SweetpassUpsellCtaTelemetryPayload =
    location === 'below-rewards-rail'
      ? UPSELL_CTA_TELEMETRY_PAYLOAD_BELOW_REWARDS
      : UPSELL_CTA_TELEMETRY_PAYLOAD_ABOVE_LEDGER;
  const telemetryPayload: SweetpassUpsellCtaTelemetryPayload = hasRewards
    ? telemetryPayloadBasedOnLocation
    : UPSELL_CTA_TELEMETRY_PAYLOAD_NO_REWARDS;

  // ─────────────────────────────────────────────────────────────────────

  const isSweetpassPlusDisabled = useFeatureFlag(
    'CELS-2877-disable-sweetpass-upgrade',
  );

  if (isSweetpassPlusDisabled) return null;

  return (
    <SweetpassUpsellCta
      containerStyle={styles.container}
      redirectParamsForSweetpassUpgradeScreen={PARAMS_FOR_SWEETPASS_UPGRADE}
      redirectParamsForLoginScreen={PARAMS_FOR_LOGIN}
      telemetryPayload={telemetryPayload}
      contentTag={contentTagBasedOnRewards}
    />
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const PARAMS_FOR_SWEETPASS_UPGRADE = { redirectToAfterUpgrade: 'bag' } as const;
const PARAMS_FOR_LOGIN = { redirect: 'sweetpassUpgradeBag' } as const;

const UPSELL_CTA_TELEMETRY_PAYLOAD_NO_REWARDS: SweetpassUpsellCtaTelemetryPayload =
  { ctaLocation: 'Bag | Zero Rewards Rail' };
const UPSELL_CTA_TELEMETRY_PAYLOAD_BELOW_REWARDS: SweetpassUpsellCtaTelemetryPayload =
  { ctaLocation: 'Bag | Below Rewards Rail' };
const UPSELL_CTA_TELEMETRY_PAYLOAD_ABOVE_LEDGER: SweetpassUpsellCtaTelemetryPayload =
  { ctaLocation: 'Bag | Above Sub-ledger' };

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    marginTop: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BagSweetpassUpgradeCtaProps = Readonly<{
  location: 'below-rewards-rail' | 'above-ledger';
}>;

type SweetpassUpsellCtaTag = SweetpassUpsellCtaProps['contentTag'];

type SweetpassUpsellCtaTelemetryPayload =
  SweetpassUpsellCtaProps['telemetryPayload'];

type SweetpassUpsellCtaProps = ComponentProps<typeof SweetpassUpsellCta>;

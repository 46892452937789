import { useRef } from 'react';
import { type HostedFrameRef } from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';

export const useExpressPayments = () => {
  const stripeReference = useRef<HostedFrameRef>(null);
  const isExpressPaymentsEnabled = useFeatureFlag(
    'permanent-order-stripe-express-checkout-enabled',
  );

  return { stripeReference, isExpressPaymentsEnabled };
};

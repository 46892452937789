import React from 'react';

import { useAddressHandlers } from '@order/components';
import type { Address } from '@order/graphql';

import { CheckoutAddressFormContent } from './CheckoutAddressFormContent';

/**
 * Wrapper around `order/components/AddressForm`.
 * Styles and localizes it to match the design for the checkout page.
 */
export const CheckoutAddressForm = (props: CheckoutAddressFormProps) => {
  const { orderAddress } = props;
  const { isUpdatingAddress, handleOnEdit } = useAddressHandlers({
    editingAddressId: orderAddress?.id,
  });

  return (
    <CheckoutAddressFormContent
      orderAddress={orderAddress}
      isUpdatingAddress={isUpdatingAddress}
      handleOnEdit={handleOnEdit}
    />
  );
};

// ─── Types ─────────────────────────────────────────────────────────────

type CheckoutAddressFormProps = Readonly<{
  orderAddress?: Partial<Address>;
}>;

import { useCallback } from 'react';
import { openBrowserAsync } from 'expo-web-browser';

import { useTelemetry } from '@order/Telemetry';

import { useKustomer } from '../../integration/kustomer';

/**
 * Featured flagged contact us handler.
 * Will open Kustomer when its enabled.
 * Fallback to the https://www.sweetgreen.com/contact.
 */
export const useKustomerContactUsHandler = (
  isKustomerEnabled: boolean,
  source: 'account' | 'order-history' | 'org-links' | 'order-status',
) => {
  const { track } = useTelemetry();
  const { open: openKustomer, openingKustomer } = useKustomer();

  const handleContactUs = useCallback(async () => {
    track('contact_us', {
      source,
      destination: isKustomerEnabled ? 'kustomer' : 'website',
    });

    if (isKustomerEnabled) return openKustomer();

    await openBrowserAsync('https://www.sweetgreen.com/contact', {
      windowFeatures: { target: '_blank' },
    });
  }, [source, isKustomerEnabled, openKustomer, track]);

  return {
    handleContactUs,
    handlingContactUs: openingKustomer,
  };
};

import type { ComponentProps } from 'react';
import React from 'react';
import { LoadingAnimation as GarnishLoadingAnimation } from '@sg/garnish';

import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_NAVBAR_HEIGHT,
} from '../Header/constants';

export const LoadingAnimation = (
  props: ComponentProps<typeof GarnishLoadingAnimation>,
) => {
  return (
    <GarnishLoadingAnimation
      {...props}
      topOffset={DESKTOP_HEADER_HEIGHT}
      bottomOffset={MOBILE_NAVBAR_HEIGHT}
    />
  );
};

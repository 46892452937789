import { useCallback } from 'react';

import type { AddressForm } from '../../../../components/Address/AddressForm.types';
import { useUpdateAddressMutation } from '../../../../components/Address/GraphQL/Address.generated';

export const useUpdateAddress = (addressId?: string) => {
  const [updateAddressResponse, updateAddress] = useUpdateAddressMutation();
  const submittingAddress = updateAddressResponse?.fetching;

  const handleUpdateAddress = useCallback(
    async (form: AddressForm) => {
      const input = { ...form, id: addressId ?? '' };

      // eslint-disable-next-line functional/immutable-data
      delete (input as unknown as { __typename: never }).__typename;
      await updateAddress({ input });
    },
    [addressId, updateAddress],
  );

  return {
    submittingAddress,
    handleUpdateAddress,
  };
};

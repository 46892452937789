import React, { useCallback } from 'react';
import type { ViewProps } from 'react-native';
import { ScrollView, StyleSheet } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { theme } from '@garnish/constants';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useCustomer } from '@order/Customer';
import { TierName } from '@order/graphql';
import { useSweetpassPlusPlans, useSweetpassTier } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry } from '@order/Telemetry';

import { useToggleScreenHeader } from '../../navigation';
import type { SweetpassHomeScreenProps } from '../../navigation/AppNavigation.props';
import { LoyaltyHomeScreen } from '../LoyaltyHomeScreen';
import type { SweetpassHeroTierName } from './components';
import { SweetpassHomeContainer, SweetpassHomeLoading } from './components';
import { useSweetpassHomeContentfulData } from './hooks';
import { SweetpassHomeScreenSignedOut } from './SweetpassHomeScreenSignedOut';

/**
 * Renders the Sweetpass Home content if all of these are true.
 * - Feature flag is on.
 * - Customer is logged in.
 * - Tier is not opted-out.
 * - Contentful data is loaded.
 */
export const SweetpassHomeScreen = (props: SweetpassHomeScreenProps) => {
  return (
    <LoyaltyGuard>
      <ScrollView
        style={styles.scrollContainer}
        contentContainerStyle={styles.scrollContent}
      >
        <SweetpassAuthGuard>
          <SweetpassHomeScreenPostGuards {...props} />
        </SweetpassAuthGuard>
      </ScrollView>
    </LoyaltyGuard>
  );
};

/**
 * Renders Loyalty if Loyalty V2 is enabled.
 */
const LoyaltyGuard = (
  props: Readonly<{
    children: React.ReactElement;
  }>,
) => {
  const isLoyaltyV2Enabled = useFeatureFlag('CELS-2685-loyalty-v2-enabled');

  if (isLoyaltyV2Enabled) return <LoyaltyHomeScreen />;

  return props.children;
};

/**
 * Prevent render if customer is logged out.
 */
const SweetpassAuthGuard = (
  props: Readonly<{
    children: React.ReactElement;
  }>,
) => {
  useToggleScreenHeader();

  const isLoggedIn = useIsLoggedIn();

  if (!isLoggedIn) return <SweetpassHomeScreenSignedOut />;

  return props.children;
};

/**
 * Prevent render if tier is opted out.
 * Prevent render if contentful data isn't loaded.
 */
const SweetpassHomeScreenPostGuards = (
  props: SweetpassHomeScreenProps & ViewProps,
) => {
  const { customer } = useCustomer();

  const { plans, isLoadingPlans } = useSweetpassPlusPlans();
  const planId = plans.annual?.id ?? plans.monthly?.id;
  const { isSweetpassTierLoading, sweetpassTierName, joinDate } =
    useSweetpassTier();

  const { isLoadingContentfulData, sweetpassHomeContentfulData } =
    useSweetpassHomeContentfulData({
      firstName: customer.firstName,
      sweetpassTier: sweetpassTierName,
      joinDate,
    });

  // ─── Navigation ──────────────────────────────────────────────────────

  const { track } = useTelemetry();

  const navigateToBenefits = useCallback(() => {
    props.navigation.navigate('Modal', { screen: 'SweetpassBenefits' });
    track('sweetpass_home.benefits_cta.tapped');
  }, [props.navigation, track]);

  const navigateToUpgrade = useCallback(() => {
    props.navigation.navigate('Modal', {
      screen: 'SweetpassUpgrade',
      params: { planId },
    });
  }, [planId, props.navigation]);

  // ─── Effects ─────────────────────────────────────────────────────────

  // Requires focus effect to trigger on returning to the Loyalty Tab.
  useFocusEffect(
    useCallback(() => {
      if (sweetpassTierName !== TierName.OptedOut) return;

      // We replace first with home because closing the Sweetpass Upgrade screen would lead to an infinite loop.
      // It needs to be a replace instead of a navigate because opening this tab again won't trigger the focus effect.
      props.navigation.replace('MainTabs', {
        screen: 'HomeTab',
        params: { screen: 'Home' },
      });
      props.navigation.navigate('Modal', { screen: 'SweetpassUpgrade' });
    }, [props.navigation, sweetpassTierName]),
  );

  if (isSweetpassTierLoading || isLoadingContentfulData || isLoadingPlans) {
    return <SweetpassHomeLoading />;
  }

  if (sweetpassTierName === TierName.OptedOut) return null;

  return (
    <SweetpassHomeContainer
      sweetpassTierName={sweetpassTierName as SweetpassHeroTierName}
      navigateToBenefits={navigateToBenefits}
      navigateToUpgrade={navigateToUpgrade}
      {...sweetpassHomeContentfulData}
      {...props}
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  scrollContainer: {
    flexGrow: 1,
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  scrollContent: {
    height: '100%',
  },
});

import React from 'react';
import { LoadingPlaceholder, theme, VStack } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';
import { useTrackEffect } from '@order/Telemetry';

import { CreditMenuItem } from './CreditMenuItem';

// ─────────────────────────────────────────────────────────────────────────────

export const CreditMenu = (props: CreditMenuProps) => {
  const {
    creditAmount,
    isLoadingCredits,
    onPressAddPromoCode,
    onPressAvailableCredit,
  } = props;

  const { t, formatPrice } = useLocalizationContext();

  const addPromoCodeA11yLabel = t('account.credit.promo-code-a11y-label');
  const addPromoCodeLabel = t(
    'account.credit.field-add-promo-code-placeholder',
  );
  // ─── Effects ─────────────────────────────────────────────────────────

  useTrackEffect('payment_credit.view_credit');

  // ─────────────────────────────────────────────────────────────────────

  return (
    <VStack gap={0} hasDivider={true} dividerColor={theme.colors.LIGHT}>
      {isLoadingCredits ? (
        <LoadingPlaceholder rows={1} rowHeight={100} palette="darkGray" />
      ) : (
        <CreditMenuItem
          testID="account.available-credit-details-button"
          iconName="IconCredit"
          title={t('account.credit.mobile-label')}
          accessibilityLabel={t('account.credit.label')}
          subtitle={formatPrice(creditAmount, 'USD')}
          onPress={onPressAvailableCredit}
        />
      )}

      <CreditMenuItem
        testID="account.open-promo-code-selection-button"
        iconName="IconPromo"
        title={addPromoCodeLabel}
        accessibilityLabel={addPromoCodeA11yLabel}
        onPress={onPressAddPromoCode}
      />
    </VStack>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

export type CreditMenuProps = Readonly<{
  creditAmount: number;
  isLoadingCredits?: boolean;
  onPressAvailableCredit: () => void;
  onPressAddPromoCode: () => void;
}>;

import { useEffect, useMemo } from 'react';

import { getAddressDuplicateByLocation } from '@order/components';
import { type useCustomer } from '@order/Customer';
import { type useCart } from '@order/hooks';

import {
  useConflictReview,
  useReorderState,
} from '../../../ReorderingScreen/hooks';
import { type useOrderAddress } from '../useOrderAddress';

/**
 * Triggers delivery reorder based on an useEffect.
 * This happens if the cart address already exists in the customer with another id.
 * It's possible if the customer started a delivery order while unauthenticated
 * the proceeded to already have that address on their profile after authenticating,
 * or if they manage to delete their address without clearing the cart.
 *
 * If the order address already has a name,
 * Then proceed with it as it won't ask for it to be udpated.
 */

export const useDeliveryReorder = (props: UseDeliveryReorderProps) => {
  const { cart } = props;

  const conflictReview = useConflictReview();
  const { reordering } = useReorderState();
  const addressThatNeedsReorder = useDeliveryReorderCheck(props);

  useEffect(() => {
    if (!addressThatNeedsReorder || reordering) {
      return;
    }

    void conflictReview({
      remainOnCheckout: true,
      orderId: cart?.id,
      orderType: 'delivery',
      restaurantId: cart?.restaurant?.id ?? '',
      restaurantSlug: cart?.restaurant?.slug ?? '',
      restaurantName: cart?.restaurant?.name ?? '',
      deliveryAddressName: addressThatNeedsReorder?.name ?? '',
      deliveryOrderDetail: {
        addressId: addressThatNeedsReorder?.id ?? '',
        deliveryFee: cart?.deliveryOrderDetail?.deliveryFee ?? 0,
        tip: cart?.deliveryOrderDetail?.tip ?? 0,
        vendor: cart?.deliveryOrderDetail?.vendor ?? '',
        vendorRestaurantId: cart?.deliveryOrderDetail?.vendorRestaurantId ?? '',
      },
    });
  }, [
    addressThatNeedsReorder,
    cart?.id,
    cart?.restaurant?.id,
    cart?.restaurant?.slug,
    cart?.restaurant?.name,
    cart?.deliveryOrderDetail?.deliveryFee,
    cart?.deliveryOrderDetail?.tip,
    cart?.deliveryOrderDetail?.vendor,
    cart?.deliveryOrderDetail?.vendorRestaurantId,
    reordering,
    conflictReview,
  ]);
};

const useDeliveryReorderCheck = (props: UseDeliveryReorderProps) => {
  const { orderAddress, customer } = props;

  return useMemo(() => {
    if (orderAddress?.name) return undefined;

    return getAddressDuplicateByLocation({
      addresses: customer?.addresses ?? [],
      addressToCheck: orderAddress,
    });
  }, [customer?.addresses, orderAddress]);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseDeliveryReorderProps = {
  customer: ReturnType<typeof useCustomer>['customer'];
  cart: ReturnType<typeof useCart>['cart'];
  orderAddress: ReturnType<typeof useOrderAddress>['orderAddress'];
};

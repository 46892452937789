import type { NativeStackNavigationOptions } from '@react-navigation/native-stack';

/**
 * A small tool that returns if the current screen is a modal without a header,
 * indicating that it is a "true" modal that is placed over an existing non-modal
 * screen rather than a pseudo non-modal screen like PDP.
 *
 * Useful for initiating some actions dependent on the type of screen.
 */
export function checkIfPresentedAsModalWithoutHeader(
  screenPresentationOptions: ScreenNavigationOptions,
) {
  const { presentation, headerShown } = screenPresentationOptions ?? {};

  const isTransparentOrFullScreenModal =
    SCREEN_MODAL_PRESENTATIONS.has(presentation);
  const isWithoutHeader = !headerShown;

  return isTransparentOrFullScreenModal && isWithoutHeader;
}

// ─── Constants ───────────────────────────────────────────────────────────────

const SCREEN_MODAL_PRESENTATIONS: ScreenNavigationPresentationOptions = new Set(
  ['fullScreenModal', 'transparentModal'],
);

// ─── Types ───────────────────────────────────────────────────────────────────

type ScreenNavigationOptions =
  | Pick<NativeStackNavigationOptions, 'presentation' | 'headerShown'>
  | undefined;

type ScreenNavigationPresentationOptions = ReadonlySet<
  NativeStackNavigationOptions['presentation']
>;

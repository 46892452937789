/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SweetpassInfoForAccountDeletionQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type SweetpassInfoForAccountDeletionQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyProfile: {
    readonly __typename?: 'LoyaltyProfile';
    readonly customerId: string;
    readonly tier: {
      readonly __typename?: 'Tier';
      readonly id: string;
      readonly name: Types.TierName;
    } | null;
    readonly tierStatus: {
      readonly __typename?: 'TierStatus';
      readonly id: string;
      readonly name: Types.TierStatusName;
    } | null;
  } | null;
  readonly getSubscriptions: ReadonlyArray<{
    readonly __typename?: 'PaymentSubscription';
    readonly id: string;
    readonly status: Types.PaymentSubscriptionStatus;
    readonly nextBillingDate: string;
  }>;
};

export type RequestAccountDeletionMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type RequestAccountDeletionMutation = {
  readonly __typename?: 'Mutation';
  readonly requestAccountDeletion:
    | { readonly __typename?: 'RequestAccountDeletionFailed' }
    | {
        readonly __typename: 'RequestAccountDeletionSuccess';
        readonly success: boolean;
      }
    | null;
};

export const SweetpassInfoForAccountDeletionDocument = gql`
  query SweetpassInfoForAccountDeletion {
    loyaltyProfile {
      customerId
      tier {
        id
        name
      }
      tierStatus {
        id
        name
      }
    }
    getSubscriptions(status: [ACTIVE]) {
      id
      status
      nextBillingDate
    }
  }
`;

export function useSweetpassInfoForAccountDeletionQuery(
  options?: Omit<
    Urql.UseQueryArgs<SweetpassInfoForAccountDeletionQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    SweetpassInfoForAccountDeletionQuery,
    SweetpassInfoForAccountDeletionQueryVariables
  >({ query: SweetpassInfoForAccountDeletionDocument, ...options });
}
export const RequestAccountDeletionDocument = gql`
  mutation RequestAccountDeletion {
    requestAccountDeletion {
      ... on RequestAccountDeletionSuccess {
        success
        __typename
      }
    }
  }
`;

export function useRequestAccountDeletionMutation() {
  return Urql.useMutation<
    RequestAccountDeletionMutation,
    RequestAccountDeletionMutationVariables
  >(RequestAccountDeletionDocument);
}

import { type ComponentProps, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { Asset } from 'contentful';
import { type Button, getContentfulImageUrl, useResponsive } from '@sg/garnish';
import { CustomProductType } from '@sg/graphql-schema';

import { useContentfulContentTypeEntry } from '@order/Contentful';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns content for the custom product card.
 *
 * @see https://app.contentful.com/spaces/wme4s8lvzccr/content_types/productBannerCard
 */
export const useCustomProductContent = (
  params: useCustomProductContentParams,
) => {
  const { customProductType, pause } = params;

  const { match } = useResponsive();
  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const customType = customProductType ?? CustomProductType.Bowl;
  const contentTag = `cyo_${customType}`.toLowerCase();

  // ─── Remote Data ─────────────────────────────────────────────────────

  const fallbackFields: ProductBannerCardContentfulData = {
    heading: formatMessage(messages.fallbackHeading),
    body: formatMessage(messages.fallbackDescription),
    buttonLabel: formatMessage(messages.fallbackCtaLabel),
  };

  const { data, fetching: isFetching } =
    useContentfulContentTypeEntry<ProductBannerCardContentfulData>({
      contentType: PRODUCT_BANNER_CARD_CONTENTFUL_ID,
      tags: [contentTag],
      pause,
    });

  // ─── Derived Data ────────────────────────────────────────────────────

  const backgroundImageUrl = data?.fields?.backgroundImage?.fields?.file?.url;
  const backgroundImage = backgroundImageUrl
    ? getContentfulImageUrl(backgroundImageUrl, {
        fit: 'fill',
        w: match([720, 1024, 1440]),
        h: match([720, 1024, 1440]) / 2,
        q: 80,
      })
    : undefined;

  const fieldsWithFallback = data?.fields ?? fallbackFields;

  // ─────────────────────────────────────────────────────────────────────

  const content = useMemo(
    () => ({
      heading: fieldsWithFallback?.heading,
      body: fieldsWithFallback?.body,
      buttonLabel: fieldsWithFallback?.buttonLabel,
      buttonPalette: fieldsWithFallback?.buttonPalette,
      textColor: fieldsWithFallback?.textColor,
      backgroundImage,
      backgroundOverlayColor: fieldsWithFallback?.backgroundOverlayColor,
    }),
    [
      backgroundImage,
      fieldsWithFallback?.backgroundOverlayColor,
      fieldsWithFallback?.body,
      fieldsWithFallback?.buttonLabel,
      fieldsWithFallback?.buttonPalette,
      fieldsWithFallback?.heading,
      fieldsWithFallback?.textColor,
    ],
  );

  // ─────────────────────────────────────────────────────────────────────

  return { isFetching, content };
};

// ─── Constants ───────────────────────────────────────────────────────────────

const PRODUCT_BANNER_CARD_CONTENTFUL_ID = 'productBannerCard';

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  fallbackHeading: {
    defaultMessage: 'Create your own',
    description:
      'Menu | Categories grid | Custom product card | Fallback heading',
  },
  fallbackDescription: {
    defaultMessage:
      'Be your own chef and build your perfect salad, bowl or plate.',
    description:
      'Menu | Categories grid | Custom product card | Fallback description',
  },
  fallbackCtaLabel: {
    defaultMessage: 'Get Started',
    description:
      'Menu | Categories grid | Custom product card | Fallback CTA label',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type useCustomProductContentParams = {
  customProductType: string | undefined | null;
  pause?: boolean;
};

export type ProductBannerCardContentfulData = Readonly<{
  heading: string;
  body: string;
  backgroundImage?: Asset;
  backgroundOverlayColor?: string;
  buttonLabel: string;
  textColor?: string;
  buttonPalette?: ComponentProps<typeof Button>['palette'];
}>;

import React from 'react';

import { Box, Item } from './subcomponents';
import type { TabNavProps } from './TabNav.types';

export const TabNav = (props: TabNavProps) => {
  return <Box {...props} />;
};

// eslint-disable-next-line functional/immutable-data
TabNav.NavItem = Item;

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@sg/garnish';

import {
  GiftCardRedemptionNumberAndCodeCopyButton,
  GiftCardRedemptionNumberSectionHeading,
} from './components';
import { giftCardRedemptionNumberAndCodeMessages as messages } from './GiftCardRedemptionNumberAndCode.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardRedemptionNumberAndCode = (
  props: GiftCardRedemptionNumberAndRegCodeProps,
) => {
  const { cardNumber, cardRegCode } = props;

  const { formatMessage } = useIntl();

  return (
    <View style={styles.container}>
      <View style={styles.stack}>
        <View style={styles.stackItemContainer}>
          <GiftCardRedemptionNumberSectionHeading>
            <FormattedMessage {...messages.cardNumberLabel} />
          </GiftCardRedemptionNumberSectionHeading>

          <GiftCardRedemptionNumberAndCodeCopyButton
            text={cardNumber}
            bannerText={formatMessage(messages.cardNumberCopiedMessage)}
            aria-label={formatMessage(messages.cardNumberCopyButtonA11yLabel)}
          />
        </View>

        <View style={styles.stackItemContainer}>
          <GiftCardRedemptionNumberSectionHeading>
            <FormattedMessage {...messages.cardCodeLabel} />
          </GiftCardRedemptionNumberSectionHeading>

          <GiftCardRedemptionNumberAndCodeCopyButton
            text={cardRegCode}
            bannerText={formatMessage(messages.cardCodeCopiedMessage)}
            aria-label={formatMessage(messages.cardCodeCopyButtonA11yLabel)}
          />
        </View>
      </View>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['3'],
    borderRadius: theme.radius.large,
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTEST,
  },
  stack: {
    rowGap: theme.spacing['2'],
  },
  stackItemContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 2,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardRedemptionNumberAndRegCodeProps = {
  cardNumber: string;
  cardRegCode: string;
};

import { useFeedbackOrderStatusQuery } from '../../GraphQL/RateOrder.generated';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns the data derived from the order status response.
 */
export const useDerivedRateOrderStatus = (orderId: string) => {
  const [orderStatusResponse] = useFeedbackOrderStatusQuery({
    variables: { orderId },
    requestPolicy: 'network-only',
    pause: !orderId,
  });

  const { data, fetching } = orderStatusResponse;

  // ─── Derived Data ────────────────────────────

  const orderStatus = data?.orderStatus;
  const isValidOrder = orderStatus?.__typename === 'OrderStatus';

  const order = isValidOrder ? orderStatus.order : undefined;
  const flattenedOrderStatus = isValidOrder
    ? orderStatus.flattenedOrderStatus
    : undefined;

  const isCompletedOrder = flattenedOrderStatus === 'completed';
  const isCanceledOrder = flattenedOrderStatus === 'failed';

  const hasPendingFeedback = order?.isPendingFeedback === true;

  // ─────────────────────────────────────────────

  const isFetching = fetching;
  const isInvalidOrder =
    !isFetching && (!isValidOrder || (isValidOrder && isCanceledOrder));
  const isPendingOrder = isValidOrder && !isCanceledOrder && !isCompletedOrder;
  const isRatedOrder = isValidOrder && isCompletedOrder && !hasPendingFeedback;

  return {
    isFetching,
    isInvalidOrder,
    isPendingOrder,
    isRatedOrder,
  };
};

export const sweetpassLandingPageDefaultCampaignContentfulFallback = {
  fields: {
    name: 'Sweetpass Upgrade: Default Free Trial',
    content: [
      {
        metadata: {
          tags: [
            {
              sys: {
                type: 'Link',
                linkType: 'Tag',
                id: 'campaign-default',
              },
            },
          ],
        },
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'wme4s8lvzccr',
            },
          },
          id: '6MT6bbNsNYItxEeNjTM3LB',
          type: 'Entry',
          createdAt: '2023-06-30T12:50:55.770Z',
          updatedAt: '2023-06-30T12:50:55.770Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 1,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'sweetpassUpgradeHeader',
            },
          },
          locale: 'en-US',
        },
        fields: {
          name: 'Sweetpass Upgrade: Default Free Trial: Header',
          heroImage: {
            metadata: {
              tags: [],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              id: '1YahOuJeNcSEU1tRfwck2v',
              type: 'Asset',
              createdAt: '2023-01-20T16:16:41.299Z',
              updatedAt: '2023-05-03T11:00:02.856Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              revision: 2,
              locale: 'en-US',
            },
            fields: {
              title: 'sweetpass plus new image',
              description: 'Sweetpass+ illustration',
              file: {
                url: '//images.ctfassets.net/wme4s8lvzccr/1YahOuJeNcSEU1tRfwck2v/cefa3d73e185f42c894c5632129017ec/Card_Angle_5_Sweetpass_plus_Drop_Shadow.png',
                details: {
                  size: 172_592,
                  image: {
                    width: 701,
                    height: 345,
                  },
                },
                fileName: 'Card_Angle_5_Sweetpass plus_Drop_Shadow.png',
                contentType: 'image/png',
              },
            },
          },
          headingText: 'Take nature up a notch.',
          headingTextXs: 'Take nature \nup a notch.',
          headingTextV2: 'Take Nature \nUp a Notch',
          bodyText:
            'Unlock an even more delicious experience with a Sweetpass+ trial. Free {trialDuration} trial, ${planPrice}/${billingFrequencyUnit} after, easily cancel anytime.',
          bodyTextGeneric:
            'Unlock an even more delicious experience with a Sweetpass+ trial.',
          cta: {
            metadata: {
              tags: [
                {
                  sys: {
                    type: 'Link',
                    linkType: 'Tag',
                    id: 'campaign-default',
                  },
                },
              ],
            },
            sys: {
              space: {
                sys: {
                  type: 'Link',
                  linkType: 'Space',
                  id: 'wme4s8lvzccr',
                },
              },
              id: 'gbDlgG0LRLPEEARV4iXtK',
              type: 'Entry',
              createdAt: '2023-06-30T12:50:40.537Z',
              updatedAt: '2023-06-30T12:50:40.537Z',
              environment: {
                sys: {
                  id: 'master',
                  type: 'Link',
                  linkType: 'Environment',
                },
              },
              revision: 1,
              contentType: {
                sys: {
                  type: 'Link',
                  linkType: 'ContentType',
                  id: 'sweetpassUpgradeCTA',
                },
              },
              locale: 'en-US',
            },
            fields: {
              name: 'Sweetpass Upgrade: Default Free Trial: CTA',
              titleGeneric: 'Try Sweetpass+ free',
              titleV3: 'Try Sweetpass+ free for {trialDuration}',
              action: 'upgrade_to_sweetpass_plus',
              palette: 'kale',
            },
          },
          palette: 'kale',
        },
      },
      {
        metadata: {
          tags: [],
        },
        sys: {
          space: {
            sys: {
              type: 'Link',
              linkType: 'Space',
              id: 'wme4s8lvzccr',
            },
          },
          id: '5UDuxSRkluvPLRoPq7LGde',
          type: 'Entry',
          createdAt: '2022-10-27T18:46:30.042Z',
          updatedAt: '2023-06-23T17:29:13.065Z',
          environment: {
            sys: {
              id: 'master',
              type: 'Link',
              linkType: 'Environment',
            },
          },
          revision: 3,
          contentType: {
            sys: {
              type: 'Link',
              linkType: 'ContentType',
              id: 'sweetpassUpgradeBenefitList',
            },
          },
          locale: 'en-US',
        },
        fields: {
          name: 'Sweetpass Upgrade: Upgrade to Sweetpass+: Benefit List',
          benefitList: [
            {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'wme4s8lvzccr',
                  },
                },
                id: '6ekqyVXyYT7OhbrN0sWKm0',
                type: 'Entry',
                createdAt: '2022-10-27T18:39:09.974Z',
                updatedAt: '2023-03-27T17:27:16.606Z',
                environment: {
                  sys: {
                    id: 'master',
                    type: 'Link',
                    linkType: 'Environment',
                  },
                },
                revision: 9,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'sweetpassUpgradeBenefit',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                name: 'Sweetpass Upgrade Benefit: $3 off every digital order',
                image: {
                  metadata: {
                    tags: [],
                  },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'wme4s8lvzccr',
                      },
                    },
                    id: '6rwpBaQXri88ZNj0Q3keom',
                    type: 'Asset',
                    createdAt: '2022-10-27T18:38:54.534Z',
                    updatedAt: '2023-01-27T18:07:13.206Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 3,
                    locale: 'en-US',
                  },
                  fields: {
                    title:
                      'Sweetpass Upgrade Benefit: $3 off every digital order',
                    description: '',
                    file: {
                      url: '//images.ctfassets.net/wme4s8lvzccr/6rwpBaQXri88ZNj0Q3keom/6cd4464fe01d7fba6b1825da248416fe/upgrade-benefit.3-dollars-off-every-digital-order.png',
                      details: {
                        size: 5_492_805,
                        image: {
                          width: 2048,
                          height: 2048,
                        },
                      },
                      fileName:
                        'upgrade-benefit.3-dollars-off-every-digital-order.png',
                      contentType: 'image/png',
                    },
                  },
                },
                titleText: 'Daily $3\nOff',
                bodyText:
                  'Unlock daily savings with us on eligible pickup, outpost, and delivery orders.',
              },
            },
            {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'wme4s8lvzccr',
                  },
                },
                id: 'IdpIcmTWyEjj7fdsbFI2j',
                type: 'Entry',
                createdAt: '2022-10-27T18:42:02.050Z',
                updatedAt: '2023-03-27T15:38:07.492Z',
                environment: {
                  sys: {
                    id: 'master',
                    type: 'Link',
                    linkType: 'Environment',
                  },
                },
                revision: 6,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'sweetpassUpgradeBenefit',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                name: 'Sweetpass Upgrade Benefit: Friends of Earth',
                image: {
                  metadata: {
                    tags: [],
                  },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'wme4s8lvzccr',
                      },
                    },
                    id: '58L1vpwaiRzNIU8JHIXIFn',
                    type: 'Asset',
                    createdAt: '2022-10-27T18:41:50.291Z',
                    updatedAt: '2023-01-27T18:07:13.231Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 3,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Sweetgreen Upgrade Benefit: Friends of Earth',
                    description: '',
                    file: {
                      url: '//images.ctfassets.net/wme4s8lvzccr/58L1vpwaiRzNIU8JHIXIFn/0505bd7283a661177816f8c6cde9c959/upgrade-benefit.friends-of-earth.png',
                      details: {
                        size: 4_053_348,
                        image: {
                          width: 2048,
                          height: 2048,
                        },
                      },
                      fileName: 'upgrade-benefit.friends-of-earth.png',
                      contentType: 'image/png',
                    },
                  },
                },
                titleText: 'Friends of Earth Perk',
                bodyText:
                  'Help us choose a carbon reduction partner to donate to on behalf of our Sweetpass+ members and participate in earth-friendly challenges.',
              },
            },
            {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'wme4s8lvzccr',
                  },
                },
                id: '7AplYmPHJUMLkk4ivpg8rN',
                type: 'Entry',
                createdAt: '2022-10-27T18:43:27.761Z',
                updatedAt: '2023-03-17T14:30:19.223Z',
                environment: {
                  sys: {
                    id: 'master',
                    type: 'Link',
                    linkType: 'Environment',
                  },
                },
                revision: 4,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'sweetpassUpgradeBenefit',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                name: 'Sweetpass Upgrade Benefit: Sweetgreen insiders',
                image: {
                  metadata: {
                    tags: [],
                  },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'wme4s8lvzccr',
                      },
                    },
                    id: '1B8jsR58awsAvibsj9UrKo',
                    type: 'Asset',
                    createdAt: '2022-10-27T18:43:15.065Z',
                    updatedAt: '2023-01-27T18:07:13.239Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 3,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Sweetpass Upgrade Benefit: Sweetgreen insiders',
                    description: '',
                    file: {
                      url: '//images.ctfassets.net/wme4s8lvzccr/1B8jsR58awsAvibsj9UrKo/316744308736a5d6742c19432e7e0116/upgrade-benefit.sweetgreen-insiders.png',
                      details: {
                        size: 4_437_587,
                        image: {
                          width: 2048,
                          height: 2048,
                        },
                      },
                      fileName: 'upgrade-benefit.sweetgreen-insiders.png',
                      contentType: 'image/png',
                    },
                  },
                },
                titleText: 'Sweetgreen Insiders',
                bodyText:
                  'Join exclusive sweetgreen experiences and be a part of a community that shapes the future of food.',
              },
            },
            {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'wme4s8lvzccr',
                  },
                },
                id: '4dc55FF3XeRJDOGxAUXV1h',
                type: 'Entry',
                createdAt: '2022-10-27T18:44:45.981Z',
                updatedAt: '2023-03-17T14:30:21.071Z',
                environment: {
                  sys: {
                    id: 'master',
                    type: 'Link',
                    linkType: 'Environment',
                  },
                },
                revision: 4,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'sweetpassUpgradeBenefit',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                name: 'Sweetpass Upgrade Benefit: Sweetgreen concierge',
                image: {
                  metadata: {
                    tags: [],
                  },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'wme4s8lvzccr',
                      },
                    },
                    id: '6v6FXWPKcchV0jPm6qFl98',
                    type: 'Asset',
                    createdAt: '2022-10-27T18:44:36.045Z',
                    updatedAt: '2023-01-27T18:07:13.247Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 3,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Sweetpass Upgrade Benefit: Sweetgreen concierge',
                    description: '',
                    file: {
                      url: '//images.ctfassets.net/wme4s8lvzccr/6v6FXWPKcchV0jPm6qFl98/4612aed98b664c50bffcd2731e4d0a35/upgrade-benefit.sweetgreen-concierge.png',
                      details: {
                        size: 5_390_327,
                        image: {
                          width: 2048,
                          height: 2048,
                        },
                      },
                      fileName: 'upgrade-benefit.sweetgreen-concierge.png',
                      contentType: 'image/png',
                    },
                  },
                },
                titleText: 'Sweetpass\nSupport',
                bodyText:
                  'Serving up fast customer service response times and fresh solutions when you need assistance.',
              },
            },
            {
              metadata: {
                tags: [],
              },
              sys: {
                space: {
                  sys: {
                    type: 'Link',
                    linkType: 'Space',
                    id: 'wme4s8lvzccr',
                  },
                },
                id: '5RiGIyv5Bozj23A6igGSmT',
                type: 'Entry',
                createdAt: '2022-10-27T18:46:17.739Z',
                updatedAt: '2023-03-17T14:30:23.214Z',
                environment: {
                  sys: {
                    id: 'master',
                    type: 'Link',
                    linkType: 'Environment',
                  },
                },
                revision: 5,
                contentType: {
                  sys: {
                    type: 'Link',
                    linkType: 'ContentType',
                    id: 'sweetpassUpgradeBenefit',
                  },
                },
                locale: 'en-US',
              },
              fields: {
                name: 'Sweetpass Upgrade Benefit: All Sweetpass benefits',
                image: {
                  metadata: {
                    tags: [],
                  },
                  sys: {
                    space: {
                      sys: {
                        type: 'Link',
                        linkType: 'Space',
                        id: 'wme4s8lvzccr',
                      },
                    },
                    id: '5CZHiZQIKqZBu73Ow2BGjs',
                    type: 'Asset',
                    createdAt: '2022-10-27T18:46:07.993Z',
                    updatedAt: '2023-01-27T18:07:13.254Z',
                    environment: {
                      sys: {
                        id: 'master',
                        type: 'Link',
                        linkType: 'Environment',
                      },
                    },
                    revision: 3,
                    locale: 'en-US',
                  },
                  fields: {
                    title: 'Sweetpass Upgrade Benefit: All sweetpass benefits',
                    description: '',
                    file: {
                      url: '//images.ctfassets.net/wme4s8lvzccr/5CZHiZQIKqZBu73Ow2BGjs/44f15982df62cec43c198230b708d9b1/upgrade-benefit.all-sweetpass-benefits.png',
                      details: {
                        size: 4_095_845,
                        image: {
                          width: 2048,
                          height: 2048,
                        },
                      },
                      fileName: 'upgrade-benefit.all-sweetpass-benefits.png',
                      contentType: 'image/png',
                    },
                  },
                },
                titleText: 'All Sweetpass Benefits',
                bodyText:
                  'Continue to enjoy Sweetpass benefits like access to limited-edition giveaways, exclusive menu items and the ability to participate in challenges to earn rewards.',
              },
            },
          ],
        },
      },
    ],
    cta: {
      metadata: {
        tags: [
          {
            sys: {
              type: 'Link',
              linkType: 'Tag',
              id: 'campaign-default',
            },
          },
        ],
      },
      sys: {
        space: {
          sys: {
            type: 'Link',
            linkType: 'Space',
            id: 'wme4s8lvzccr',
          },
        },
        id: 'gbDlgG0LRLPEEARV4iXtK',
        type: 'Entry',
        createdAt: '2023-06-30T12:50:40.537Z',
        updatedAt: '2023-06-30T12:50:40.537Z',
        environment: {
          sys: {
            id: 'master',
            type: 'Link',
            linkType: 'Environment',
          },
        },
        revision: 1,
        contentType: {
          sys: {
            type: 'Link',
            linkType: 'ContentType',
            id: 'sweetpassUpgradeCTA',
          },
        },
        locale: 'en-US',
      },
      fields: {
        name: 'Sweetpass Upgrade: Default Free Trial: CTA',
        titleGeneric: 'Try Sweetpass+ free',
        titleV3: 'Try Sweetpass+ free for {trialDuration}',
        action: 'upgrade_to_sweetpass_plus',
        palette: 'kale',
      },
    },
  },
};

import { useEffect } from 'react';

import type { LastInteractedStore } from './machine/LastInteractedStore.model';
import { LastInteractedStoreModel } from './machine/LastInteractedStore.model';
import { useLastInteractedStoreMachine } from './useLastInteractedStoreMachine';

/**
 * Sets the last interacted menu store if it is resolved.
 */
export const useSetLastInteractedMenuStore = (
  lastInteractedMenuStore: LastInteractedStore = {},
) => {
  const [, send] = useLastInteractedStoreMachine();
  const { restaurantSlug, addressId } = lastInteractedMenuStore;

  useEffect(() => {
    if (!restaurantSlug && !addressId) return;

    send(SET_LAST_INTERACTED_MENU_STORE({ restaurantSlug, addressId }));
  }, [addressId, restaurantSlug, send]);
};

// ────────────────────────────────────────────────────────────────────────────────

const { SET_LAST_INTERACTED_MENU_STORE } = LastInteractedStoreModel.events;

import { type Location, type RecentOrder } from '../../LocationSearch.types';
import { extractDeliveryLocationDetails } from '../delivery-location/delivery-location.helpers';

/**
 * Maps order location data to the standard location.
 */
export function mapOrderLocation(order: RecentOrder): Location {
  const { id, restaurant, deliveryOrderDetail } = order;

  const {
    name,
    slug,
    latitude: lat,
    deliveryFee,
    longitude: lng,
    city,
    state,
    zipCode,
    hours,
    flexMessage,
    phone,
    address,
    isOutpost,
    notAcceptingOrdersReason,
    isAcceptingOrders,
    showOutpostPriceDifferenciationDisclosure,
    asset,
  } = restaurant;

  // ─── Delivery Location ───────────────────────────────────────────────

  const deliveryAddressId = deliveryOrderDetail?.address?.id;

  if (deliveryAddressId) {
    const deliveryAddressName = deliveryOrderDetail?.address?.name;

    return extractDeliveryLocationDetails({
      deliveryLocation: {
        restaurant,
        vendor: {
          name: deliveryOrderDetail?.vendor,
          restaurantId: deliveryOrderDetail?.vendorRestaurantId,
        },
      },
      deliveryAddressId,
      deliveryAddressName,
      googlePlaceAddress: deliveryOrderDetail.address,
    });
  }

  // ─── Pickup or Outpost Location ──────────────────────────────────────

  return {
    id,
    attachedOrderId: id,
    name,
    lat,
    lng,
    city,
    zipCode,
    state,
    imageUrl: asset.url,
    restaurantSlug: slug,
    restaurantName: name,
    restaurantId: restaurant.id,
    acceptingOrders: isAcceptingOrders,
    storeHours: hours.formatted ?? undefined,
    flexMessage,
    notAcceptingOrdersReason,
    isOutpost,
    outpostPriceDifferentiationEnabled:
      showOutpostPriceDifferenciationDisclosure ?? false,
    addressId: deliveryAddressId,
    phone,
    address,
    restaurantDeliveryFee: deliveryFee,
  };
}

import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuOnlineOrderingUnavailableContainer = (
  props: PropsWithChildren,
) => {
  const { match } = useResponsive();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    match([styles.containerXS, styles.containerSM]),
  ];

  // ─────────────────────────────────────────────────────────────────────

  return <View style={containerStyles}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.OPACITY.DARK_KALE.DARKER,
  },
  containerXS: {
    justifyContent: 'flex-end',
    paddingTop: theme.spacing['4'],
  },
  containerSM: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing['6'],
  },
});

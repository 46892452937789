/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SubscriptionPlanByIdQueryVariables = Types.Exact<{
  planId: Types.Scalars['ID']['input'];
}>;

export type SubscriptionPlanByIdQuery = {
  readonly __typename?: 'Query';
  readonly subscriptionPlanById: {
    readonly __typename?: 'SubscriptionPlan';
    readonly id: string;
    readonly price: string;
    readonly hasFreeTrial: boolean;
    readonly billingFrequencyUnit: Types.DurationUnit | null;
  } | null;
};

export type SubscriptionPlansByIdsQueryVariables = Types.Exact<{
  planIds:
    | ReadonlyArray<Types.Scalars['ID']['input']>
    | Types.Scalars['ID']['input'];
}>;

export type SubscriptionPlansByIdsQuery = {
  readonly __typename?: 'Query';
  readonly subscriptionPlansByIds: ReadonlyArray<{
    readonly __typename?: 'SubscriptionPlan';
    readonly id: string;
    readonly price: string;
    readonly hasFreeTrial: boolean;
    readonly billingFrequency: string;
    readonly billingFrequencyUnit: Types.DurationUnit | null;
  }> | null;
};

export const SubscriptionPlanByIdDocument = gql`
  query SubscriptionPlanById($planId: ID!) {
    subscriptionPlanById(planId: $planId) {
      id
      price
      hasFreeTrial
      billingFrequencyUnit
    }
  }
`;

export function useSubscriptionPlanByIdQuery(
  options: Omit<Urql.UseQueryArgs<SubscriptionPlanByIdQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    SubscriptionPlanByIdQuery,
    SubscriptionPlanByIdQueryVariables
  >({ query: SubscriptionPlanByIdDocument, ...options });
}
export const SubscriptionPlansByIdsDocument = gql`
  query SubscriptionPlansByIds($planIds: [ID!]!) {
    subscriptionPlansByIds(planIds: $planIds) {
      id
      price
      hasFreeTrial
      billingFrequency
      billingFrequencyUnit
    }
  }
`;

export function useSubscriptionPlansByIdsQuery(
  options: Omit<
    Urql.UseQueryArgs<SubscriptionPlansByIdsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    SubscriptionPlansByIdsQuery,
    SubscriptionPlansByIdsQueryVariables
  >({ query: SubscriptionPlansByIdsDocument, ...options });
}

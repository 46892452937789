import { type PaymentSubscription } from '@order/graphql';

import { getPreferredSubscription } from '../useSweetpassTier';
import { useSweetpassPaymentMethodsQuery } from './graphql/SweetpassPaymentMethod.generated';

export const useSweetpassPaymentMethod = (
  props: SweetpassPaymentMethodProps,
) => {
  const { paymentMethodId } = props;

  // ─── Query ───────────────────────────────────────────────────────────

  const [sweetpassPaymentMethodsResponse] = useSweetpassPaymentMethodsQuery({
    pause: !paymentMethodId,
  });

  // ─── Derived Data ────────────────────────────────────────────────────

  const {
    data: sweetpassPaymentMethodResponseData,
    fetching: isLoadingSweetpassPaymentMethods,
  } = sweetpassPaymentMethodsResponse;

  const subscriptions: ReadonlyArray<Partial<PaymentSubscription>> | undefined =
    sweetpassPaymentMethodResponseData?.getSubscriptions;
  const subscription = getPreferredSubscription(subscriptions);
  const subscriptionId = subscription?.id ?? '';

  const paymentMethod =
    sweetpassPaymentMethodResponseData?.paymentSubscriptionProfile?.creditCards?.find(
      (creditCard) => creditCard.id === paymentMethodId,
    );

  const isPaymentMethodUsedForSubscriptions = paymentMethod?.isActive;
  const hasActiveSubscriptionWithDifferentPaymentMethod =
    !isPaymentMethodUsedForSubscriptions && Boolean(subscriptionId);

  return {
    paymentMethod,
    subscriptionId,
    isPaymentMethodUsedForSubscriptions,
    hasActiveSubscriptionWithDifferentPaymentMethod,
    isLoadingSweetpassPaymentMethods,
  };
};

// ─── Types ─────────────────────────────────────────────────────────────

type SweetpassPaymentMethodProps = Readonly<{
  paymentMethodId: string;
}>;

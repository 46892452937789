import { useEffect, useState } from 'react';
import { datadog } from '@sg/expo-datadog-client';

import type { FeatureFlagKey, FeatureFlagValue } from './types';
import { useLaunchDarkly } from './useLaunchDarkly';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * Returns the value of a feature flag using the following sources (in that order):
 *
 * 1. Overrides (from `./feature-flags/overrides`)
 * 2. Actual values (from Launch Darkly)
 * 3. Local defaults (from `./feature-flags/defaults`)
 *
 * If none of these are available, `undefined` will be returned.
 *
 * The hook also supports optional live tracking, which ensures that the value is always up-to-date.
 */
export const useFeatureFlag = <Key extends FeatureFlagKey>(
  flagKey: Key,
  options?: UseFeatureFlagOptions,
) => {
  const {
    isReady,
    defaults,
    overrides,
    initialFeatureFlags,
    registerFeatureFlagListener,
    unregisterFeatureFlagListener,
  } = useLaunchDarkly();

  const shouldListenForChanges = Boolean(options?.listenForChanges);

  // ─── State ───────────────────────────────────────────────────────────

  const initialFeatureFlagValue = initialFeatureFlags?.[flagKey];
  const initialFeatureFlagValueWithDefaultFallback =
    initialFeatureFlagValue ?? defaults[flagKey];

  const [featureFlagValue, setFeatureFlagValue] = useState<
    FeatureFlagValue<Key>
  >(initialFeatureFlagValueWithDefaultFallback);

  // ─── Effects ─────────────────────────────────────────────────────────

  /**
   * Sets the feature flag's actual value, after the LD client
   * initialization and/or state change.
   */
  useEffect(() => {
    const shouldAssignActualFeatureFlagValue =
      isReady && initialFeatureFlagValue !== undefined;

    if (!shouldAssignActualFeatureFlagValue) return;

    setFeatureFlagValue(initialFeatureFlagValue);
  }, [initialFeatureFlagValue, isReady]);

  /**
   * Updates the feature flag value on change if the corresponding flag is enabled for it
   */
  useEffect(() => {
    if (!shouldListenForChanges) return;

    void registerFeatureFlagListener?.(flagKey, setFeatureFlagValue);

    return () => {
      void unregisterFeatureFlagListener?.(flagKey, setFeatureFlagValue);
    };
  }, [
    shouldListenForChanges,
    flagKey,
    registerFeatureFlagListener,
    unregisterFeatureFlagListener,
  ]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    datadog.addFeatureFlagEvaluation(flagKey, featureFlagValue);
  }, [featureFlagValue, flagKey]);

  // ─────────────────────────────────────────────────────────────────

  const localOverride = overrides[flagKey];
  const localDefault = defaults[flagKey];

  // ─────────────────────────────────────────────────────────────────

  const canOverride =
    initialFeatureFlags?.['permanent-feature-flag-overrides-enabled'];

  if (!canOverride) return featureFlagValue ?? localDefault;

  // ─────────────────────────────────────────────────────────────────

  return localOverride ?? featureFlagValue ?? localDefault;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseFeatureFlagOptions = Readonly<{
  listenForChanges: boolean;
}>;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { BodyText } from '../../Text';

// ────────────────────────────────────────────────────────────────────────────────

export const LineItemPrice = (props: ProductPriceProps) => {
  const { price } = props;

  return (
    <View style={styles.wrapper}>
      <BodyText size={4}>{price}</BodyText>
    </View>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    paddingLeft: theme.spacing['2'],
  },
});

// ────────────────────────────────────────────────────────────────────────────────

type ProductPriceProps = Readonly<{
  price: number | string;
}>;

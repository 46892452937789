import React from 'react';
import { type RouteProp, useRoute } from '@react-navigation/native';

import { MenuContent, useDeliveryMenuContentData } from '@order/components';

import type { MenuTabStackParamList } from '../../navigation/AppNavigation.props';

// ─────────────────────────────────────────────────────────────────────────────

export const DeliveryMenuScreenV2 = () => {
  //
  // ─── Navigation ──────────────────────────────────────────────────────

  const { params } =
    useRoute<RouteProp<MenuTabStackParamList, 'DeliveryMenu'>>();

  const { addressId } = params ?? {};

  // ─── Remote Data ─────────────────────────────────────────────────────

  const {
    restaurantData,
    deliveryDetailsData,
    isFetching,
    hasError,
    hasNoProducts,
  } = useDeliveryMenuContentData({ addressId });

  // ─────────────────────────────────────────────────────────────────────

  const shouldRenderErrorView =
    hasError || !restaurantData || !deliveryDetailsData || hasNoProducts;

  if (isFetching) {
    return <MenuContent.Loading />;
  }

  if (shouldRenderErrorView) {
    return <MenuContent.Error />;
  }

  // ─────────────────────────────────────────────────────────────────────

  const locationName =
    deliveryDetailsData.addressName ?? deliveryDetailsData?.shortAddress;

  return (
    <MenuContent.Ready
      restaurantData={restaurantData}
      deliveryDetailsData={deliveryDetailsData}
      orderChannel="delivery"
      locationName={locationName}
    />
  );
};

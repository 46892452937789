export const login = {
  'login.title': 'Sign in',
  'login.subtitle': "Don't have an account?",
  'login.join-button': 'Join now',
  'login.sign-in-button': 'Sign in',
  'login.forgot-password-button': 'Forgot password?',

  // ─── Login Errors ────────────────────────────────────────────────────

  'login.failed-to-fetch-customer-error':
    'There was an error while getting your account data, please try later.',
  'login.credentials-error': 'Invalid login credentials.',
  'login.account-locked-error':
    'Please wait 1 minute before attempting to log in again or use the Forgot Password link to reset your password. Accounts are locked after 5 unsuccessful login attempts.',
  'login.unknown-error':
    'Something went wrong while logging in, try again in a few moments...',
};

import React, { type ComponentProps } from 'react';
import { DisplayText } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuTopDownCategoriesItemTitle = (
  props: ComponentProps<typeof DisplayText>,
) => {
  const { children, ...restProps } = props;

  return (
    <DisplayText sizeMatch={['24']} {...restProps}>
      {children}
    </DisplayText>
  );
};

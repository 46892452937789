import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { useResponsive } from '@sg/garnish';

import { LocationSearch, PageWrapper } from '@order/components';
import { useTrackEventEffect } from '@order/Telemetry';

import { useReorderState } from '../ReorderingScreen';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationsScreen = memo(() => {
  const { currentBreakpoint } = useResponsive();
  const {
    reordering: isReordering,
    restaurantSlug: reorderRestaurantSlug,

    deliveryOrderDetail,
  } = useReorderState();

  const interactedLocationRestaurantSlug = isReordering
    ? reorderRestaurantSlug
    : undefined;
  const interactedLocationDeliveryAddressId = isReordering
    ? deliveryOrderDetail?.addressId
    : undefined;

  // ─── Effects ─────────────────────────────────────────────────────────

  useTrackEventEffect({
    name: 'location.view',
  });

  // ─────────────────────────────────────────────────────────────────────

  if (currentBreakpoint.isXS) {
    return (
      <View style={styles.wrapper}>
        <LocationSearch
          interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
          interactedLocationDeliveryAddressId={
            interactedLocationDeliveryAddressId
          }
        />
      </View>
    );
  }

  return (
    <PageWrapper contentContainerStyle={styles.wrapper}>
      <LocationSearch
        interactedLocationRestaurantSlug={interactedLocationRestaurantSlug}
        interactedLocationDeliveryAddressId={
          interactedLocationDeliveryAddressId
        }
      />
    </PageWrapper>
  );
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    height: '100%',
  },
});

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BalanceCard, Collapsible, IllusCredit_1, theme } from '@sg/garnish';

import type { Credit } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { CreditsList } from '../components';
import { useViewDetailsTelemetry } from './CreditOverview.telemetry';

// ─────────────────────────────────────────────────────────────────────────────

export const CreditOverviewBalance = (props: CreditOverviewBalanceProps) => {
  const {
    creditAmount,
    creditDetails,
    isLoadingCredits,
    isCollapsible = true,
  } = props;

  const { t, formatPrice } = useLocalizationContext();

  return (
    <View>
      <View style={styles.cardContainer}>
        <BalanceCard
          title={t('account.credit.label')}
          balance={formatPrice(creditAmount, 'USD')}
          illustrationSource={IllusCredit_1}
          isLoading={isLoadingCredits === true}
        />
      </View>

      {/* eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10 */}
      {creditAmount > 0 ? (
        isCollapsible ? (
          <CreditOverviewBalanceCollapsible creditDetails={creditDetails} />
        ) : (
          <CreditOverviewBalanceStatic creditDetails={creditDetails} />
        )
      ) : null}
    </View>
  );
};

// ─── Subcomponents ───────────────────────────────────────────────────────────

const CreditOverviewBalanceCollapsible = (
  props: Pick<CreditOverviewBalanceProps, 'creditDetails'>,
) => {
  const { creditDetails } = props;

  const [isExpanded, setIsExpanded] = React.useState(false);

  useViewDetailsTelemetry(isExpanded);

  const { t } = useLocalizationContext();

  return (
    <Collapsible
      style={styles.fullWidth}
      options={{ hideIcon: true }}
      onChange={setIsExpanded}
    >
      <Collapsible.Details>
        <CreditsList credits={creditDetails} />
      </Collapsible.Details>

      {isExpanded ? (
        <Collapsible.Button
          containerProps={styles.centerSelf}
          style={styles.closeDetails}
          width={136}
          testID="account.available-credit-close-details-button"
          palette="secondary"
          accessibilityRole="button"
          accessibilityLabel={t('account.credit.close-details')}
          accessibilityHint={t('account.credit.close-details')}
        >
          {t('account.credit.close-details')}
        </Collapsible.Button>
      ) : (
        <Collapsible.Button
          containerProps={styles.centerSelf}
          width={136}
          testID="account.available-credit-view-details-button"
          palette="secondary"
          accessibilityRole="button"
          accessibilityLabel={t('account.credit.view-details')}
          accessibilityHint={t('account.credit.view-details')}
        >
          {t('account.credit.view-details')}
        </Collapsible.Button>
      )}
    </Collapsible>
  );
};

const CreditOverviewBalanceStatic = (
  props: Pick<CreditOverviewBalanceProps, 'creditDetails'>,
) => {
  const { creditDetails } = props;

  return (
    <View style={styles.fullWidth}>
      <CreditsList credits={creditDetails} />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cardContainer: {
    alignItems: 'center',

    marginBottom: theme.spacing['8'],
  },
  centerSelf: {
    alignSelf: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  closeDetails: {
    marginTop: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CreditOverviewBalanceProps = Readonly<{
  creditAmount: number;
  creditDetails: readonly Credit[];
  isLoadingCredits?: boolean;
  isCollapsible?: boolean;
}>;

import React, { type ReactNode } from 'react';
import { type TextStyle } from 'react-native';
import { theme } from '@garnish/constants';

import { LabelText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductCardV2ProductDetail = (
  props: ProductCardV2ProductDetailProps,
) => {
  const { children, palette = 'light-neutral' } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const textStyles = paletteStyles[palette];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <LabelText sizeMatch={['14']} style={textStyles}>
      {children}
    </LabelText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const paletteStyles: Record<ProductCardV2ProductDetailPalette, TextStyle> = {
  'dark-kale': {
    color: theme.colors.DARK_KALE,
  },
  'light-neutral': {
    color: theme.colors['NEUTRAL_1.5'],
  },
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ProductCardV2ProductDetailProps = {
  children: ReactNode;
  palette?: ProductCardV2ProductDetailPalette;
};

type ProductCardV2ProductDetailPalette = 'dark-kale' | 'light-neutral';

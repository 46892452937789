import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, HStack, Modal, theme, useResponsive } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

export const ConfirmLocationLayoutFooter = ({
  isLoading,
  onContinue,
  onMoreLocations,
}: ConfirmLocationModalFooterProps) => {
  const { t } = useLocalizationContext();
  const { minWidth } = useResponsive();

  return (
    <Modal.Footer style={styles.footer} withoutTopBorder={minWidth.isSM}>
      <HStack itemsPerRow={onMoreLocations ? 2 : 1} gap={theme.spacing['4']}>
        {onMoreLocations ? (
          <Button
            size="large"
            palette="secondary"
            onPress={onMoreLocations}
            disabled={isLoading}
            testID="confirm-location.more-locations"
            accessibilityLabel={t('location.more-locations')}
            accessibilityHint={t('location.more-locations')}
          >
            {t('location.more-locations')}
          </Button>
        ) : null}

        <Button
          size="large"
          onPress={onContinue}
          isLoading={isLoading}
          testID="confirm-location.continue"
          accessibilityLabel={t('location.continue')}
          accessibilityHint={t('location.continue')}
        >
          {t('location.continue')}
        </Button>
      </HStack>
    </Modal.Footer>
  );
};

const styles = StyleSheet.create({
  footer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingTop: theme.spacing['4'],
    paddingHorizontal: theme.spacing['4'],
  },
});

export type ConfirmLocationModalFooterProps = Readonly<{
  isLoading: boolean;
  onContinue: () => void;
  onMoreLocations?: () => void;
}>;

import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';

import { IconLink } from '../../../Icon';
import { ACTIVE_INGREDIENT_PRESSABLE } from '../../ActiveIngredient.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const ActiveIngredientRemoveButton = (
  props: ActiveIngredientRemoveButtonProps,
) => {
  const { active, hovered, ...restProps } = props;

  return (
    <View style={styles.container}>
      <IconLink
        name="IconClose"
        iconSize={16}
        width={24}
        height={24}
        palette={active || hovered ? 'kale' : 'muted'}
        {...restProps}
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: ACTIVE_INGREDIENT_PRESSABLE.PADDING.VERTICAL,
    right: ACTIVE_INGREDIENT_PRESSABLE.PADDING.HORIZONTAL,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ActiveIngredientRemoveButtonProps = {
  active?: boolean;
  hovered?: boolean;
} & Omit<ComponentProps<typeof IconLink>, 'name'>;

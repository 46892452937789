import { AccessibilityInfo } from 'react-native';

// High order component that will check if the user has reduced animation enabled and decorate accordingly.
export async function withReducedScrollMotion(
  options: Record<string, unknown>,
): Promise<any> {
  const reduceMotionEnabled = await AccessibilityInfo.isReduceMotionEnabled();
  const hasNoReducedMotion = !reduceMotionEnabled;
  const animated = hasNoReducedMotion;

  return { ...options, animated };
}

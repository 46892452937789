import { config } from '../../config';
import type { StoreTokensParams } from './store-tokens.types';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Stores provided tokens in the local storage (embedded flow).
 *
 * NOTE: We don't store access token in async storage in case of embedded flow.
 */
export function storeEmbeddedFlowTokens(params: StoreTokensParams) {
  const { refreshToken } = params;

  if (!refreshToken) return;

  localStorage.setItem(config.embeddedFlowRefreshTokenStorageKey, refreshToken);
}

import React, { memo } from 'react';
import type { SharedValue } from 'react-native-reanimated';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';

/**
 * Since we use animated `translateY` style to expand/collapse location results,
 * we need to add extra scrolling area based on the main container height
 * to ensure that the scroll to list item functionality works properly
 * when the results container is expanded.
 */
export const LocationResultsExtraScrollArea = memo(
  (props: LocationResultsExtraScrollAreaProps) => {
    const { containerHeightSharedValue } = props;

    const containerOffsetStyle = useAnimatedStyle(() => {
      return { height: containerHeightSharedValue.value };
    });

    return (
      <Animated.View
        testID="location-results-extra-scroll-area"
        style={containerOffsetStyle}
      />
    );
  },
);

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsExtraScrollAreaProps = Readonly<{
  containerHeightSharedValue: SharedValue<number>;
}>;

import { offlineExchange } from '@urql/exchange-graphcache';
import { type Exchange } from 'urql';
import schema from '@sg/graphql-schema-introspection';

import { offlineStorage as storage } from '../../storage';
import { keys } from './keys';
import { resolvers } from './resolvers';
import { updates } from './updates';

export const graphcacheExchange: Exchange = offlineExchange({
  schema,
  storage,
  keys,
  resolvers,
  updates,
});

import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { format } from 'date-fns';
import { DateAndHourPicker, theme, useResponsive } from '@sg/garnish';

import type { GiftCardCheckoutFormReturn } from '../../../../../../form';
import { giftCardCheckoutDeliveryDatePickerMessages as messages } from './GiftCardCheckoutDeliveryDatePicker.messages';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders delivery date picker with the corresponding handlers.
 */
export const GiftCardCheckoutDeliveryDatePicker = (
  props: GiftCardCheckoutDeliveryDatePickerProps,
) => {
  const { form, fromDate, toDate } = props;

  const { match } = useResponsive();
  const { formatMessage } = useIntl();

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerStyles = [
    styles.container,
    match([styles.containerXS, styles.containerSM]),
  ];

  // ─── Derived Data ────────────────────────────────────────────────────

  const selectedDeliveryDate = form.watch('deliveryDateTime');
  const selectedDeliveryDateFormatted = selectedDeliveryDate
    ? format(new Date(selectedDeliveryDate), 'MM-dd-yyyy')
    : null;

  const dateAndTimeLabels = useMemo(() => {
    return {
      day: formatMessage(messages.deliveryDayLabel),
      time: formatMessage(messages.deliveryTimeLabel),
      dateAndTime: formatMessage(messages.deliveryDateAndTimeLabel),
      today: formatMessage(messages.todayLabel),
      pickerModalTitle: formatMessage(messages.modalTitle),
      pickerModalCtaLabel: formatMessage(messages.modalCta),
    };
  }, [formatMessage]);

  const dateAndTimeA11yLabel = selectedDeliveryDateFormatted
    ? formatMessage(messages.changeDeliveryDateA11yLabel, {
        date: selectedDeliveryDateFormatted,
      })
    : formatMessage(messages.changeDeliveryDateA11yLabelGeneric);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onChangeDeliveryDate = useCallback(
    async (date: Date | undefined) => {
      form.setValue('deliveryDateTime', date?.toISOString());
      await form.trigger('deliveryDateTime');
    },
    [form],
  );

  // ─────────────────────────────────────────────────────────────────────

  if (!selectedDeliveryDate) return null;

  return (
    <View style={containerStyles}>
      <DateAndHourPicker
        labels={dateAndTimeLabels}
        aria-label={dateAndTimeA11yLabel}
        selectedDate={new Date(selectedDeliveryDate)}
        onChange={onChangeDeliveryDate}
        fromDate={fromDate}
        toDate={toDate}
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingVertical: theme.spacing['4'],
  },
  containerXS: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.LIGHT,
    paddingTop: theme.spacing['6'],
    marginTop: theme.spacing['4'],
  },
  containerSM: {
    maxWidth: 424,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type GiftCardCheckoutDeliveryDatePickerProps = {
  form: GiftCardCheckoutFormReturn;
  fromDate: Date;
  toDate: Date;
};

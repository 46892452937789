/* istanbul ignore file */

import {
  OrdersFavoritesListContainer,
  OrdersListContainer,
  OrdersListOrderContainer,
  OrdersListOrderHeader,
  OrdersListOrderLineItemsContainer,
} from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const OrdersList = {
  Container: OrdersListContainer,
  FavoritesContainer: OrdersFavoritesListContainer,
  OrderContainer: OrdersListOrderContainer,
  OrderHeader: OrdersListOrderHeader,
  OrderLineItemsContainer: OrdersListOrderLineItemsContainer,
};

import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

import { useResponsive } from '../../../../hooks';

/**
 * This Gesture Handler is needed to prevent gestures
 * from withing the content interferring with the Modal.
 */
export const ModalWrapper = (props: ViewProps) => {
  const { children } = props;

  const { match } = useResponsive();
  const dialogWrapperStyles = [
    styles.dialogWrapper,
    match([styles.dialogWrapperMobile, styles.dialogWrapperTabletUp]),
  ];

  return (
    <GestureHandlerRootView
      pointerEvents="box-none"
      style={dialogWrapperStyles}
    >
      {children}
    </GestureHandlerRootView>
  );
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  dialogWrapper: {
    flexGrow: 1,
    maxHeight: '100%',
  },
  dialogWrapperMobile: {
    justifyContent: 'flex-end',
  },
  dialogWrapperTabletUp: {
    justifyContent: 'center',
  },
});

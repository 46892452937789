import type { CartDataFragment } from '@order/graphql';
import type { DeliveryOrderDetailInput } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

export const useOrderValidation = (props: CanSubmitOrderProps) => {
  const {
    wantedTime,
    dropoffLocationId,
    paymentMethodId,
    cart,
    pickupTip,
    deliveryOrderDetail,
  } = props;

  // ─── Wanted Time ─────────────────────────────────────────────────────

  const hasWantedTime = Boolean(wantedTime);

  // ─── Dropoff Locations ───────────────────────────────────────────────

  const hasMultipleDropoffLocations =
    (cart?.restaurant?.availableDropOffLocations ?? []).length > 1;
  const isNotDelivery = cart?.orderType !== 'DELIVERY';
  const needsDropoffLocation = isNotDelivery && hasMultipleDropoffLocations;
  const isDropoffSelected =
    Boolean(dropoffLocationId) && dropoffLocationId !== 'placeholder';
  const hasDropoffSelectionIfNeeded = needsDropoffLocation
    ? isDropoffSelected
    : true;

  // Submit Order Validations

  // ─── Tip ─────────────────────────────────────────────────────────────

  const orderType = cart?.orderType;
  const orderTip =
    orderType === 'DELIVERY' ? deliveryOrderDetail?.tip : pickupTip;
  const cartSubtotal = cart?.ledger?.subtotal ?? 0;
  const hasAcceptableTipAmount = checkIfHasAcceptableTipAmount({
    tip: orderTip,
    cartSubtotal,
  });

  // ─── Payment Method ──────────────────────────────────────────────────

  const hasPaymentMethodSelected = paymentMethodId !== 'ADD';

  // ─── Blocking Checkout ───────────────────────────────────────────────

  const shouldBlockCheckoutOnPress =
    !hasAcceptableTipAmount ||
    !hasWantedTime ||
    !hasDropoffSelectionIfNeeded ||
    !hasPaymentMethodSelected;

  const cannotSubmitOrderReason = useCannotSubmitOrderReason({
    hasWantedTime,
    hasDropoffSelectionIfNeeded,
    hasAcceptableTipAmount,
    hasPaymentMethodSelected,
    cart,
  });

  return {
    shouldBlockCheckoutOnPress,
    cannotSubmitOrderReason,
  };
};

const useCannotSubmitOrderReason = ({
  hasWantedTime,
  hasDropoffSelectionIfNeeded,
  hasPaymentMethodSelected,
  hasAcceptableTipAmount,
  cart,
}: CannotSubmitOrderReasonProps) => {
  const { t } = useLocalizationContext();

  if (!hasAcceptableTipAmount) {
    return t('checkout.cannot-submit.max-tip');
  }

  if (!hasWantedTime) {
    return t('checkout.cannot-submit.wanted-time');
  }

  if (!hasDropoffSelectionIfNeeded) {
    return cart?.restaurant?.isOutpost
      ? t('checkout.cannot-submit.drop-off')
      : t('checkout.cannot-submit.pickup');
  }

  if (!hasPaymentMethodSelected) {
    return t('checkout.cannot-submit.payment-method');
  }

  return t('general.error');
};

// ─── Utils ───────────────────────────────────────────────────────────────────

/**
 * Checks whether the provided order tip does not exceed order subtotal.
 *
 * NOTE: The maximum tip for the order is 100%.
 * NOTE: Tipping is available only for `pickup` and `delivery` orders.
 */
function checkIfHasAcceptableTipAmount(
  params: CheckIfHasAcceptableTipAmountParams,
) {
  const { cartSubtotal, tip } = params;
  const orderTip = typeof tip === 'number' ? tip : 0;

  return orderTip <= cartSubtotal;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type CanSubmitOrderProps = Readonly<{
  wantedTime?: string | null;
  dropoffLocationId?: string | null;
  paymentMethodId?: string | null;
  cart?: Partial<CartDataFragment> | null;
  pickupTip?: number | null;
  deliveryOrderDetail?: DeliveryOrderDetailInput | null;
}>;

type CannotSubmitOrderReasonProps = Readonly<{
  hasWantedTime: boolean;
  hasDropoffSelectionIfNeeded: boolean;
  hasPaymentMethodSelected: boolean;
  hasAcceptableTipAmount: boolean;
  cart?: Partial<CartDataFragment> | null;
}>;

type CheckIfHasAcceptableTipAmountParams = Readonly<{
  tip: number | null | undefined;
  cartSubtotal: number;
}>;

import { DeliveryPreferenceType } from '@order/graphql';

import { type CompleteAddressForm } from '../../components';
import { type DeliveryLocationWithoutNearbyStore } from '../../machine';

/**
 * Converts a {DeliveryLocationWithoutNearbyStore} into an {CompleteAddressForm}
 */
export const getAddressForm = (
  deliveryLocation: DeliveryLocationWithoutNearbyStore,
): CompleteAddressForm => {
  return {
    id: deliveryLocation.addressId,
    googlePlaceId: deliveryLocation.id,

    name: deliveryLocation.name ?? '',
    notes: deliveryLocation.notes ?? '',
    deliveryPreference:
      deliveryLocation.deliveryPreference ?? DeliveryPreferenceType.LeaveAtDoor,

    street: deliveryLocation.street ?? '',
    secondaryStreet: deliveryLocation.secondaryStreet ?? '',
    city: deliveryLocation.city ?? '',
    state: deliveryLocation.state ?? '',
    country: deliveryLocation.country ?? '',
    zipCode: deliveryLocation.zipCode ?? '',
  };
};

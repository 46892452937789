import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive } from '@sg/garnish';

export const HomeColumnsContainerSM = (props: HomeColumnsSMProps) => {
  return <View style={styles.columnsContainer}>{props.children}</View>;
};

export const HomeColumnLeftSM = (props: HomeColumnsSMProps) => {
  const { match } = useResponsive();

  const columnStyles = [
    styles.columnLeft,
    match([
      styles.columnLeftSM,
      styles.columnLeftSM,
      styles.columnLeftSM,
      styles.columnLeftLG,
    ]),
  ];

  return <View style={columnStyles}>{props.children}</View>;
};

const styles = StyleSheet.create({
  columnsContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  columnLeft: {
    flex: 1,
    paddingVertical: theme.spacing['10'],
    justifyContent: 'center',
    gap: theme.spacing['2'],
  },
  columnLeftSM: {
    padding: theme.spacing['10'],
  },
  columnLeftLG: {
    paddingVertical: theme.spacing['10'],
  },
});

type HomeColumnsSMProps = {
  children: React.ReactNode;
};

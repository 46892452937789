import React from 'react';
import { useResponsive } from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

import {
  AccountScreenContainer,
  AccountScreenSection,
  AccountScreenTitle,
} from '../AccountScreenContainer';
import {
  SkeletonLoadingContentLG,
  SkeletonLoadingContentSM,
  SkeletonLoadingHeaderXS,
} from './AccountSkeletonLoading';

export const AccountPaymentGiftCardLoading = () => {
  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isInAppGiftCardEnabled = useFeatureFlag(
    'CELS-1449-in-app-gift-cards-enabled',
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <AccountScreenContainer screen="payment-and-gift-cards">
      <AccountScreenTitle
        visibility="COMPACT"
        title={
          isInAppGiftCardEnabled
            ? t('account.payments.title')
            : t('account.payment-and-gift-cards.title')
        }
      />

      <AccountScreenSection>
        {/* Payment Methods */}
        <SkeletonLoadingHeaderXS />
        <SkeletonLoadingContentSM />
        <SkeletonLoadingContentSM />

        {currentBreakpoint.isXS ? (
          <>
            {/* Credit and Promo Code Links */}
            <SkeletonLoadingHeaderXS />
            <SkeletonLoadingContentSM />
            <SkeletonLoadingContentSM />
          </>
        ) : (
          <>
            {/* Credit and Promo Code Form */}
            <SkeletonLoadingHeaderXS />
            <SkeletonLoadingContentLG />
            <SkeletonLoadingContentSM />
            <SkeletonLoadingContentLG />
          </>
        )}
      </AccountScreenSection>
    </AccountScreenContainer>
  );
};

import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Button,
  TextLink,
  theme,
  TitleText,
  useResponsive,
  useToggleState,
} from '@sg/garnish';

import {
  OrderCancellationModal,
  useOrderCancellationModal,
} from '@order/components';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';

import {
  useKustomerAvailability,
  useKustomerContactUsHandler,
} from '../../../../integrations/kustomer';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderStatusContactUs = (props: OrderStatusContactUsBaseProps) => {
  const { orderId, refetchOrderStatus, restaurantId, canCancel } = props;

  const { currentBreakpoint } = useResponsive();

  const isInAppOrderCancellationEnabled = useFeatureFlag(
    'CELS-1476-in-app-order-cancellation',
  );

  const isKustomerAvailable = useKustomerAvailability();
  const { handleContactUs, handlingContactUs } = useKustomerContactUsHandler(
    isKustomerAvailable,
    'order-status',
  );

  // ─────────────────────────────────────────────────────────────────────

  const shouldUseCardLayout = isInAppOrderCancellationEnabled
    ? isInAppOrderCancellationEnabled
    : currentBreakpoint.isXS;

  if (shouldUseCardLayout) {
    return (
      <OrderStatusContactUsCard
        onContactUsPress={handleContactUs}
        isLoading={handlingContactUs}
        orderId={orderId}
        refetchOrderStatus={refetchOrderStatus}
        restaurantId={restaurantId}
        canCancel={canCancel}
      />
    );
  }

  return (
    <OrderStatusContactUsFloatingBar
      onContactUsPress={handleContactUs}
      isLoading={handlingContactUs}
    />
  );
};

// ─── Variations ──────────────────────────────────────────────────────────────

const OrderStatusContactUsFloatingBar = (props: OrderStatusContactUsProps) => {
  const { isLoading, onContactUsPress } = props;

  const { t } = useLocalizationContext();

  return (
    <View style={styles.floatingBarContainer}>
      <BodyText>
        <BodyText size={4} bold>
          {t('order-status-info.contact-us.heading')}
        </BodyText>

        <TextLink
          style={styles.floatingBarLink}
          size={4}
          disabled={isLoading}
          onPress={onContactUsPress}
        >
          {t('order-status-info.contact-us.cta')}
        </TextLink>
      </BodyText>
    </View>
  );
};

const OrderStatusContactUsCard = (
  props: OrderStatusContactUsProps & OrderStatusContactUsBaseProps,
) => {
  const {
    isLoading,
    orderId,
    canCancel,
    restaurantId,
    refetchOrderStatus,
    onContactUsPress,
  } = props;

  const { t } = useLocalizationContext();

  // ─── State ───────────────────────────────────────────────────────────

  const {
    value: shouldHideCancelDuringAsyncCancelation,
    toggleOn: hideCancelDuringAsyncCancelation,
  } = useToggleState();

  const {
    isOrderCancellationModalVisible,
    showOrderCancellationModal,
    hideOrderCancellationModal,
  } = useOrderCancellationModal();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isInAppOrderCancellationEnabled = useFeatureFlag(
    'CELS-1476-in-app-order-cancellation',
  );

  const shouldDisplayInAppCancellationOption =
    isInAppOrderCancellationEnabled &&
    canCancel &&
    orderId !== undefined &&
    restaurantId !== undefined;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <View style={styles.cardContainer}>
        <TitleText style={styles.cardTitle} sizeMatch={['24']}>
          {t('order-status-info.contact-us.heading')}
        </TitleText>

        <BodyText style={styles.cardBody} sizeMatch={['16']}>
          {t('order-status-info.contact-us.subheading')}
        </BodyText>

        <View style={styles.cardCtaContainer}>
          <Button
            palette="primary"
            onPress={onContactUsPress}
            isLoading={isLoading}
          >
            {t('order-status-info.contact-us.cta')}
          </Button>

          {shouldDisplayInAppCancellationOption &&
          !shouldHideCancelDuringAsyncCancelation ? (
            <Button palette="secondary" onPress={showOrderCancellationModal}>
              {t('order-status-info.contact-us.cancel-order-cta')}
            </Button>
          ) : null}
        </View>
      </View>

      {shouldDisplayInAppCancellationOption ? (
        <OrderCancellationModal
          isVisible={isOrderCancellationModalVisible}
          orderId={orderId}
          restaurantId={restaurantId}
          onSuccess={refetchOrderStatus}
          onAsyncCancelation={hideCancelDuringAsyncCancelation}
          onRequestClose={hideOrderCancellationModal}
        />
      ) : null}
    </>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  floatingBarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderTopWidth: 1,
    borderTopColor: theme.colors.DARK_KALE,
    backgroundColor: theme.colors.QUINOA,
    padding: theme.spacing['6'],
  },
  floatingBarLink: {
    marginLeft: theme.spacing['1'],
  },

  cardContainer: {
    alignItems: 'center',
    borderRadius: theme.radius.medium,
    backgroundColor: theme.colors.QUINOA,
    marginTop: theme.spacing['10'],
    marginBottom: theme.spacing['4'],
    padding: theme.spacing['6'],
  },
  cardTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing['4'],
  },
  cardBody: {
    textAlign: 'center',
    marginBottom: theme.spacing['4'],
  },
  cardCtaContainer: {
    flexDirection: 'row',
    gap: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderStatusContactUsBaseProps = {
  orderId?: string;
  restaurantId?: string;
  canCancel: boolean;
  refetchOrderStatus: () => void;
};

type OrderStatusContactUsProps = Readonly<{
  isLoading: boolean;
  onContactUsPress: () => void;
}>;

import React from 'react';
import { StyleSheet } from 'react-native';
import Animated, { type SharedValue } from 'react-native-reanimated';
import { theme } from '@garnish/constants';
import { LinearGradient } from 'expo-linear-gradient';

import { useGradientAnimation } from '../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomNavigationHeaderGradient = (
  props: CustomNavigationHeaderGradientProps,
) => {
  const {
    containerHeightSV,
    scrollOffsetSV,
    gradientColors = GRADIENT_DEFAULT_COLORS,
  } = props;

  // ─── Animated Styles ─────────────────────────────────────────────────

  const gradientAnimation = useGradientAnimation({
    containerHeightSV,
    scrollOffsetSV,
  });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Animated.View style={gradientAnimation}>
      <LinearGradient
        locations={[0, 1]}
        pointerEvents="none"
        colors={gradientColors}
        style={styles.gradient}
      />
    </Animated.View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const GRADIENT_HEIGHT = 25;
const GRADIENT_DEFAULT_COLORS = [
  theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  theme.colors.OPACITY.DARK_KALE.TRANSPARENT,
];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  gradient: {
    height: GRADIENT_HEIGHT,
    position: 'absolute',
    right: 0,
    bottom: -(GRADIENT_HEIGHT - 1), // to prevent 1px space that can occur on some browsers
    left: 0,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomNavigationHeaderGradientProps = {
  containerHeightSV: SharedValue<number>;
  scrollOffsetSV?: SharedValue<number>;
  gradientColors?: string[];
};

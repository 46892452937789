/* eslint-disable functional/immutable-data */
import type { ReactElement } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { PressableStateCallbackType as UIEventState } from 'react-native';
import { Pressable, StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';

import { webOnlyStyles } from '../../utils';
import { BodyText } from '../Text';
import type { ChipProps, ChipState } from './Chip.types';
import { ChipBadge, ChipBox, ChipText, Menu } from './subcomponents';

export const Chip = (props: ChipProps): ReactElement => {
  const {
    value,
    selectedValue,
    badge,
    palette = 'primary',
    label,
    accessibilityRole = 'button',
    accessibilityState = {},
    accessibilityLabel,
    testID,
    disabled = false,
    children,
    height,
    style,
    maxFontSizeMultiplier,
    onChange,
  } = props;

  const active = useMemo<boolean>(
    () => (props.active || selectedValue ? selectedValue === value : false),
    [selectedValue, props.active, value],
  );

  const heightStyle = useStyle(() => ({ height }), [height]);

  const getComputedTransitionState = useMemo(
    () =>
      (hovered: boolean): ChipState => {
        if (disabled) return 'disabled';

        if (hovered) return 'hovered';

        if (active) return 'active';

        return 'default';
      },
    [active, disabled],
  );

  const handlePress = useCallback(() => {
    if (value && onChange && !disabled) onChange(value);
  }, [onChange, value, disabled]);
  const a11yLabel = `${accessibilityLabel} ${disabled ? 'disabled' : 'button'}`;

  accessibilityState.disabled = Boolean(disabled);

  return (
    <Pressable
      disabled={disabled}
      accessibilityHint={a11yLabel}
      accessibilityLabel={a11yLabel}
      accessibilityState={{ ...accessibilityState }}
      accessibilityRole={accessibilityRole}
      testID={testID}
      onPress={handlePress}
      {...{ accessibilityChecked: accessibilityState.checked }}
      style={[{ ...webOnlyStyles({ outline: 0 }) }, heightStyle]}
    >
      {({
        hovered = false,
        focused,
      }: UIEventState &
        Readonly<{
          hovered?: boolean;
          focused?: boolean;
        }>) => {
        const state = getComputedTransitionState(hovered);

        return (
          <View testID="chip-wrapper">
            {/* ---- Badge Section ---- */}
            {badge ? (
              <View style={styles.badgePosition}>
                <ChipBadge />
              </View>
            ) : undefined}

            {/* ---- Chip Section ----- */}
            <ChipBox
              palette={palette}
              style={style}
              focused={focused}
              state={state}
            >
              <ChipText
                state={state}
                palette={palette}
                maxFontSizeMultiplier={maxFontSizeMultiplier}
              >
                {/* @ts-expect-error TS(2322): Type 'ReactNode | ((state: PressableStateCallbackT... Remove this comment to see the full error message */}
                {children}
              </ChipText>
            </ChipBox>

            {/* ---- Label Section ----- */}
            {label?.text ? (
              <View testID="chip-label" style={styles.label}>
                <BodyText size={5}>{label.text}</BodyText>
              </View>
            ) : null}
            {label?.spacing ? <View style={styles.emptyLabel} /> : null}
          </View>
        );
      }}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  badgePosition: {
    position: 'absolute',
    right: 3,
    zIndex: 1,
    top: 0,
  },
  label: {
    paddingTop: 8,
    alignItems: 'center',
  },
  emptyLabel: {
    marginTop: 24,
  },
});

Chip.Menu = Menu;

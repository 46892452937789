import { useMemo } from 'react';
import { nanoid } from 'nanoid/non-secure';

/**
 * A small hook that generates a memoized {nativeID}. Optional {prefix} can be provided.
 */
export const useUniqueNativeID = (prefix?: string) => {
  return useMemo(() => {
    const uniqueID = nanoid(5);

    return prefix ? `${prefix}-${uniqueID}` : uniqueID;
  }, [prefix]);
};

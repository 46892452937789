import React, { type ComponentProps, useCallback } from 'react';
import {
  Pressable,
  type PressableStateCallbackType,
  type StyleProp,
  StyleSheet,
  type ViewProps,
  type ViewStyle,
} from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuTopDownCategoriesItemPressable = (
  props: MenuTopDownCategoriesCategoryProps,
) => {
  const { children, categoryId, style, onPress, ...pressableProps } = props;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    onPress(categoryId);
  }, [onPress, categoryId]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const pressableStyles = useCallback(
    (state: PressableStateCallbackType): StyleProp<ViewStyle> => {
      const { pressed } = state;

      return [
        styles.pressable,
        pressed ? styles.pressablePressed : undefined,
        style,
      ];
    },
    [style],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      accessibilityRole="button"
      onPress={handleOnPress}
      style={pressableStyles}
      {...pressableProps}
    >
      {children}
    </Pressable>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['4'],
  },
  pressablePressed: {
    opacity: 0.7,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuTopDownCategoriesCategoryProps = Omit<
  ComponentProps<typeof Pressable>,
  'onPress' | 'style'
> & {
  categoryId: string;
  children: ViewProps['children'];
  style?: ViewProps['style'];
  onPress: (categoryId: string) => void;
};

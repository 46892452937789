import React from 'react';
import {
  Image,
  ImageBackground,
  type ImageSourcePropType,
  StyleSheet,
  View,
} from 'react-native';
import { getContentfulImageUrl } from '@sg/garnish';

export const JoinSgRewardsHero = (props: JoinSgRewardsHeroProps) => {
  const { heroImage, logoImage } = props;

  const heroImageSource = heroImage?.url
    ? { uri: getContentfulImageUrl(heroImage.url) }
    : heroImage.fallbackSource;
  const logoImageSource = logoImage?.url
    ? { uri: getContentfulImageUrl(logoImage.url) }
    : logoImage.fallbackSource;

  return (
    <View style={styles.heroImageWrapper}>
      <ImageBackground style={styles.heroImage} source={heroImageSource}>
        <Image style={styles.logo} source={logoImageSource} />
      </ImageBackground>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  heroImageWrapper: {
    flexShrink: 0,
  },
  heroImage: {
    width: 400,
    height: 230,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 140,
    height: 40,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type JoinSgRewardsHeroProps = {
  heroImage: {
    fallbackSource?: ImageSourcePropType;
    url?: string;
    description: string;
  };
  logoImage: {
    fallbackSource?: ImageSourcePropType;
    url?: string;
    description: string;
  };
};

import { createContext } from 'react';

import type {
  Customer,
  FeatureFlagChangeListener,
  FeatureFlagOverrides,
  FeatureFlags,
} from './types';

// ─────────────────────────────────────────────────────────────────────────────

export const LaunchDarklyContext = createContext<
  LaunchDarklyContextType | undefined
>(undefined);

// ─── Types ───────────────────────────────────────────────────────────────────

export type LaunchDarklyContextType = Readonly<{
  defaults: FeatureFlags;
  overrides: FeatureFlagOverrides;
  initialFeatureFlags: FeatureFlags | undefined;
  isReady: boolean;

  // ─────────────────────────────────────────────

  init: (customer?: Customer) => Promise<void>;
  addContext:
    | ((context: Record<string, unknown>) => Promise<unknown>)
    | undefined;
  identifyCustomer: (customer: Customer) => Promise<unknown> | undefined;
  resetCustomer: () => Promise<void>;
  registerFeatureFlagListener: FeatureFlagChangeListener;
  unregisterFeatureFlagListener: FeatureFlagChangeListener;
  overrideFeatureFlag: (
    flagName: keyof FeatureFlags,
    flagValue: unknown,
  ) => void;
  track: (
    eventName: string,
    data?: Record<string, unknown>,
    metricValue?: number,
  ) => void;
  clearFeatureFlagOverrides: () => void;
  close: () => Promise<void>;
}>;

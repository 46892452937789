import { useCallback } from 'react';
import { useNoticeBannersStackContext } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { useUpdateSweetpassPaymentMethodMutation } from './graphql/SweetpassPaymentMethod.generated';

export const useUpdateSweetpassPaymentMethod = () => {
  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();

  // ─── Mutations ───────────────────────────────────────────────────────

  const [
    { fetching: isUpdatingSweetpassPaymentMethod },
    updateSweetpassPaymentMethodMutation,
  ] = useUpdateSweetpassPaymentMethodMutation();

  // ─── Callbacks ───────────────────────────────────────────────────────

  const updateSweetpassPaymentMethod = useCallback(
    async (props: UpdateSweetpassPaymentMethodProps) => {
      const { paymentMethodId, subscriptionId, onSuccess } = props;

      const { data } = await updateSweetpassPaymentMethodMutation({
        subscriptionId,
        paymentMethodId,
      });

      const updatedSuccessfully =
        data?.updateSubscriptionPaymentMethod?.__typename ===
        'UpdateSubscriptionPaymentMethodSuccessResponse';

      if (!updatedSuccessfully) {
        addNoticeBanner({
          text: t('sweetpass-membership.payment-methods.update.error'),
          palette: 'caution',
        });

        return;
      }

      onSuccess();
    },
    [updateSweetpassPaymentMethodMutation, t, addNoticeBanner],
  );

  return {
    isUpdatingSweetpassPaymentMethod,
    updateSweetpassPaymentMethod,
  };
};

// ─── Types ─────────────────────────────────────────────────────────────

type UpdateSweetpassPaymentMethodProps = Readonly<{
  subscriptionId: string;
  paymentMethodId: string;
  onSuccess: () => void;
}>;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SuccessfulLogoutCustomerFieldsFragment = {
  readonly __typename?: 'LogoutSuccess';
  readonly csrf: string;
};

export type LogoutCustomerMutationVariables = Types.Exact<{
  input: Types.LogoutInput;
}>;

export type LogoutCustomerMutation = {
  readonly __typename?: 'Mutation';
  readonly logout:
    | { readonly __typename: 'LogoutSuccess'; readonly csrf: string }
    | { readonly __typename: 'ValidationError' };
};

export const SuccessfulLogoutCustomerFieldsFragmentDoc = gql`
  fragment SuccessfulLogoutCustomerFields on LogoutSuccess {
    csrf
  }
`;
export const LogoutCustomerDocument = gql`
  mutation LogoutCustomer($input: LogoutInput!) {
    logout(input: $input) {
      __typename
      ...SuccessfulLogoutCustomerFields
    }
  }
  ${SuccessfulLogoutCustomerFieldsFragmentDoc}
`;

export function useLogoutCustomerMutation() {
  return Urql.useMutation<
    LogoutCustomerMutation,
    LogoutCustomerMutationVariables
  >(LogoutCustomerDocument);
}

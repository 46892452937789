import { defineMessages } from 'react-intl';

export const giftCardCheckoutDeliveryDatePickerMessages = defineMessages({
  deliveryDayLabel: {
    defaultMessage: 'Day',
    description: 'Gift Card Checkout Form | Delivery Date | "Day" field label',
  },
  deliveryTimeLabel: {
    defaultMessage: 'Time',
    description: 'Gift Card Checkout Form | Delivery Date | "Time" field label',
  },
  deliveryDateAndTimeLabel: {
    defaultMessage: 'Date and time',
    description:
      'Gift Card Checkout Form | Delivery Date | "Date and time" field label',
  },
  todayLabel: {
    defaultMessage: 'Today',
    description: 'Gift Card Checkout Form | Delivery Date | Today option label',
  },
  changeDeliveryDateA11yLabel: {
    defaultMessage: 'Change delivery date. Selected date: {date}',
    description:
      'Gift Card Checkout Form | Delivery Date | "Change delivery date" a11y label',
  },
  changeDeliveryDateA11yLabelGeneric: {
    defaultMessage: 'Change delivery date',
    description:
      'Gift Card Checkout Form | Delivery Date | "Change delivery date" generic a11y label',
  },
  modalTitle: {
    defaultMessage: 'Select a date and time',
    description: 'Gift Card Checkout Form | Delivery Date | Picker modal title',
  },
  modalCta: {
    defaultMessage: 'Continue',
    description: 'Gift Card Checkout Form | Delivery Date | Picker modal CTA',
  },
});

import { isMatch } from 'date-fns';

/**
 * A wrapper for the `isMatch` `date-fns` method securely (since `isMatch` can
 * throw a `RangeError error) returns a boolean indicating if the time string
 * matches the format.
 */
export function checkIfMatchesTimeFormat(timeString: string, format: string) {
  try {
    return isMatch(timeString, format);
  } catch {
    return false;
  }
}

import React, { useRef } from 'react';
import type { ImageSourcePropType, ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { Image } from '../../Image';
import {
  IllusBlueberryLarge,
  IllusBlueberryMedium,
  IllusBlueberrySmall,
  IllusGrapefruitLarge,
  IllusGrapefruitMedium,
  IllusGrapefruitSmall,
  IllusTangerineLarge,
  IllusTangerineMedium,
  IllusTangerineSmall,
  IllusWhiteLarge,
  IllusWhiteMedium,
  IllusWhiteSmall,
} from '../assets';
import type {
  LoyaltyChallengeCardVariantsType,
  LoyaltyChallengeTheme,
} from '../LoyaltyChallengeCard.types';

// ────────────────────────────────────────────────────────────────────────────

export const LoyaltyChallengeIllustration = (
  props: LoyaltyChallengeImageProps,
) => {
  const { challengeTheme, challengeVariant, testID } = props;

  const illustration = useRef(
    getRandomIllustration(challengeTheme, challengeVariant),
  );

  return (
    <View style={styles.wrapper} testID={testID}>
      <Image
        style={styles.image}
        source={illustration.current}
        resizeMode="stretch"
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    marginBottom: theme.spacing['4'],
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.KALE,
  },
  image: {
    width: '100%',
    height: 130,
  },
});

// ─── Utils ───────────────────────────────────────────────────────────────────

function getRandomIllustration(
  challengeTheme: LoyaltyChallengeTheme,
  challengeVariant: LoyaltyChallengeCardVariantsType,
) {
  const imageGroup =
    loyaltyChallengeIllustration[challengeTheme][challengeVariant];

  return imageGroup[Math.floor(Math.random() * imageGroup.length)];
}

/**
 * Loyalty challenge cards can have different illustrations based on
 * their theme (`FLASH`, etc.) and type (`New`, `Ongoing`, `Completed`).
 */
const loyaltyChallengeIllustration: Record<
  LoyaltyChallengeTheme,
  Record<LoyaltyChallengeCardVariantsType, readonly ImageSourcePropType[]>
> = {
  DEFAULT: {
    New: [IllusBlueberrySmall, IllusGrapefruitSmall, IllusTangerineSmall],
    Ongoing: [
      IllusBlueberryMedium,
      IllusGrapefruitMedium,
      IllusTangerineMedium,
    ],
    Completed: [IllusBlueberryLarge, IllusGrapefruitLarge, IllusTangerineLarge],
  },
  FLASH: {
    New: [IllusWhiteSmall],
    Ongoing: [IllusWhiteMedium],
    Completed: [IllusWhiteLarge],
  },
};

// ─── Types ───────────────────────────────────────────────────────────────────

export type LoyaltyChallengeImageProps = Readonly<{
  challengeTheme: LoyaltyChallengeTheme;
  challengeVariant: LoyaltyChallengeCardVariantsType;
}> &
  Pick<ViewProps, 'testID'>;

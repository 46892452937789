import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ListItem } from '../List';
import { type LedgerRowProps } from './Ledger.types';
import { LedgerLabelText } from './LedgerLabelText';
import { LedgerRowLabel } from './LedgerRowLabel';

// ────────────────────────────────────────────────────────────────────────────

export const LedgerRow = (props: LedgerRowProps) => {
  const { label, amount, helperText, sizeMatch, testID, style, ...restProps } =
    props;

  return (
    <ListItem>
      <View style={[styles.ledgerItem, style]}>
        <LedgerRowLabel
          sizeMatch={sizeMatch}
          label={label}
          helperText={helperText}
          {...restProps}
        >
          <LedgerLabelText
            sizeMatch={sizeMatch}
            label={label}
            helperText={helperText}
            {...restProps}
          />
        </LedgerRowLabel>

        <LedgerRowLabel
          sizeMatch={sizeMatch}
          label={`${Array.isArray(amount) ? amount.join(' + ') : amount}`}
          alignment="right"
          testID={testID}
          style={styles.ledgerRowAmountItem}
          {...restProps}
        >
          <LedgerLabelText
            sizeMatch={sizeMatch}
            label={`${Array.isArray(amount) ? amount.join(' + ') : amount}`}
            alignment="right"
            testID={testID}
            style={styles.ledgerRowAmountItem}
            {...restProps}
          />
        </LedgerRowLabel>
      </View>
    </ListItem>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  ledgerItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  ledgerRowAmountItem: {
    flexShrink: 0,
  },
});

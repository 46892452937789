import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';
import { theme } from '@garnish/constants';

import { MARKER_SIZE } from '../constants';
import { MinimizingPin } from './MinimizedPin';
import { PinWrapper } from './PinWrapper';

export const OutpostPin = (props: OutpostPinProps) => {
  const { balloonColor, ballColor, fillOpacity } = props;

  return (
    <PinWrapper>
      <MinimizingPin {...props}>
        <Svg
          width={MARKER_SIZE}
          height={MARKER_SIZE}
          viewBox={`0 0 ${MARKER_SIZE} ${MARKER_SIZE}`}
        >
          <Path
            d="M32.9516 4.29337C27.4209 1.06334 20.5791 1.06334 15.0484 4.29337C9.59974 7.47552 6.25 13.311 6.25 19.6209V21.1401C6.25 24.0335 7.40001 26.8435 9.061 29.399C10.7228 31.9558 12.907 34.2747 15.0022 36.191C18.8543 39.7141 22.5137 44.725 23.0216 45.4297C23.07 45.4969 23.131 45.5712 23.2166 45.6351C23.6806 45.9815 24.3194 45.9815 24.7834 45.6351C24.869 45.5712 24.93 45.4969 24.9784 45.4297C25.4863 44.725 29.1457 39.7141 32.9978 36.191C35.093 34.2747 37.2772 31.9558 38.939 29.399C40.6 26.8435 41.75 24.0335 41.75 21.1401V19.6209C41.75 13.311 38.4002 7.47551 32.9516 4.29337Z"
            fill={balloonColor}
            stroke={theme.colors.OATMEAL}
            strokeWidth="1"
          />
          <Circle
            cx="24"
            cy="19.9999"
            r="7"
            fill={ballColor}
            fillOpacity={fillOpacity}
          />
        </Svg>
      </MinimizingPin>
    </PinWrapper>
  );
};

export const OutpostPinOpenSelected = (props: OutpostPinProps) => (
  <OutpostPin
    {...props}
    fillOpacity={1}
    balloonColor={theme.colors.GREEN_2}
    ballColor={theme.colors.KHAKI_3}
  />
);

export const OutpostPinOpenUnselected = (props: OutpostPinProps) => (
  <OutpostPin
    {...props}
    fillOpacity={1}
    balloonColor={theme.colors.GREEN_2}
    ballColor={theme.colors.KHAKI_3}
  />
);

export const OutpostPinClosedSelected = (props: OutpostPinProps) => (
  <OutpostPin
    {...props}
    fillOpacity={0.4}
    balloonColor={theme.colors.NEUTRAL_4}
    ballColor={theme.colors.OPACITY.DARK_KALE.DARK}
  />
);

export const OutpostPinClosedUnselected = (props: OutpostPinProps) => (
  <OutpostPin
    {...props}
    fillOpacity={0.4}
    balloonColor={theme.colors.NEUTRAL_4}
    ballColor={theme.colors.OPACITY.DARK_KALE.DARK}
  />
);

type OutpostPinProps = Readonly<{
  balloonColor?: string;
  ballColor?: string;
  fillOpacity?: number;
  isMinimized?: boolean;
  isOpen?: boolean;
}>;

import React, { Children, type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductCardV2Footer = (props: PropsWithChildren) => {
  const { children } = props;

  // Prevent rendering if no children are provided
  if (Children.count(children) === 0) {
    return null;
  }

  return <View style={styles.container}>{children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
    gap: theme.spacing['1'],
    marginTop: 'auto', // push to the bottom of the card
  },
});

import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getProductIngredientsList } from '@sg/garnish';
import { type Ingredient, IngredientKind } from '@sg/graphql-schema';

import { lineItemIngredientsMessages as messages } from './useLineItemIngredients.messages';

// ────────────────────────────────────────────────────────────────────────────────

/**
 * Returns line item added and removed ingredients with extra filtering
 */
export const useLineItemIngredients = (lineItem: PartialLineItem) => {
  const { removedIngredients = [] } = lineItem;

  const { formatMessage } = useIntl();

  const addedIngredients = useMemo(
    () => getAddedIngredients(lineItem) ?? [],
    [lineItem],
  );

  const addedIngredientsText =
    addedIngredients.length > 0
      ? formatMessage(messages.addedIngredients, {
          ingredients: getProductIngredientsList(addedIngredients),
        })
      : null;

  const removedIngredientsText =
    removedIngredients.length > 0
      ? formatMessage(messages.removedIngredients, {
          ingredients: getProductIngredientsList(removedIngredients),
        })
      : null;

  return {
    addedIngredients,
    addedIngredientsText,
    removedIngredients,
    removedIngredientsText,
  };
};

// ─── Utils ──────────────────────────────────────────────────────────────────────

function getAddedIngredients(lineItem: PartialLineItem) {
  const { addedIngredients } = lineItem;

  return withoutDefaultDressingIncreasedPortion(
    withoutAddedBreadIngredient(addedIngredients),
    getProductDefaultDressingsIds(lineItem),
  );
}

function getProductDefaultDressingsIds(
  lineItem: PartialLineItem,
): DefaultDressingsIds {
  const { product } = lineItem;
  const { ingredients: productIngredients = [] } = product;

  const productDressingIngredientsIds = productIngredients
    .filter(checkIfDressingIngredient)
    .map(extractIngredientId);

  return new Set(productDressingIngredientsIds);
}

/**
 * When a user increases a portion of the default dressing/s, it is displayed as extra added ingredient;
 * however, we do not want it to appear in the "Added" ingredients list, thus we must remove it from the list.
 */
function withoutDefaultDressingIncreasedPortion(
  addedIngredients: LineItemAddedIngredients,
  defaultDressingIngredientsIds: DefaultDressingsIds,
) {
  return addedIngredients?.filter((addedIngredient) => {
    const { id: addedIngredientId } = addedIngredient;

    return !defaultDressingIngredientsIds.has(addedIngredientId);
  });
}

/**
 * When a user adds the "Bread" option, it appears as a special comment in the Line Item card,
 * but it also appears in the "Added" ingredients list, thus we must remove it from the list
 * to avoid several locations indicating bread selection.
 */
function withoutAddedBreadIngredient(
  addedIngredients: LineItemAddedIngredients,
) {
  return addedIngredients?.filter((addedIngredient) => {
    const addedIngredientName = addedIngredient.name?.toLowerCase();

    return addedIngredientName !== BREAD_INGREDIENT_NAME;
  });
}

function checkIfDressingIngredient(
  ingredient: Partial<Pick<Ingredient, 'kind'>>,
) {
  return ingredient.kind === IngredientKind.Dressing;
}

function extractIngredientId(ingredient: IngredientWithId) {
  return ingredient.id;
}

// ─── Constants ──────────────────────────────────────────────────────────────────

const BREAD_INGREDIENT_NAME = 'bread';

// ─── Types ──────────────────────────────────────────────────────────────────────

type PartialLineItem = {
  addedIngredients?: ReadonlyArray<PartialIngredient>;
  removedIngredients?: ReadonlyArray<PartialIngredient>;
  product: {
    ingredients?: ReadonlyArray<PartialIngredient>;
  };
};

type LineItemAddedIngredients = PartialLineItem['addedIngredients'];

type DefaultDressingsIds = ReadonlySet<IngredientWithId['id']>;

type IngredientWithId = Partial<Pick<PartialIngredient, 'id'>>;

type PartialIngredient = Partial<Pick<Ingredient, 'id' | 'name' | 'kind'>>;

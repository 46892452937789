import type {
  LDClient as LDJSClient,
  LDContext,
} from 'launchdarkly-js-client-sdk';
import * as LDClientInstance from 'launchdarkly-js-client-sdk';
import 'react-native-get-random-values'; // NOTE: We need to polyfill browser specific features (like `crypto`, `document`) in order to run Launch Darkly JS client in Expo Go.
import '@expo/browser-polyfill';

// ─────────────────────────────────────────────────────────────────────────────
import { getEnvVars } from '@order/utils';

import { LD_CLIENT_INITIALIZATION_TIMEOUT_SECONDS } from './launch-darkly.constants';
import type {
  LDClient,
  LDFlagChangeEventListener,
} from './launch-darkly.types';
import type { LDClientModule } from './launch-darkly.types';
import { logger } from './launch-darkly.utils';

// ─────────────────────────────────────────────────────────────────────────────

const { LAUNCHDARKLY_CLIENT_ID } = getEnvVars();

/**
 * Creates a new Launch Darkly JS client adapter with the required methods.
 *
 * NOTE: `bind` is used since LD methods rely heavily on `this`.
 *
 * @see {@link https://docs.launchdarkly.com/sdk/client-side/react/react-native React Native SDK reference}
 */
const init = async (context: LDContext): Promise<LDClient | undefined> => {
  if (!LAUNCHDARKLY_CLIENT_ID) {
    logger.warn('LAUNCHDARKLY_CLIENT_ID is missing');

    return;
  }

  // ─────────────────────────────────────────────

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const client = LDClientInstance.initialize(LAUNCHDARKLY_CLIENT_ID, context);

  try {
    await client.waitForInitialization(
      LD_CLIENT_INITIALIZATION_TIMEOUT_SECONDS,
    );
  } catch (error) {
    logger.warn(`Web | The LD client's initialization failed. Error: ${error}`);
  }

  // ─────────────────────────────────────────────

  return {
    clientType: 'js',
    identify: client.identify.bind(client),
    close: client.close.bind(client),
    track: client.track.bind(client),
    getAllFlags: client.allFlags.bind(client) as LDClient['getAllFlags'],
    registerFeatureFlagListener: createRegisterFeatureFlagListener(client),
    unregisterFeatureFlagListener: createUnregisterFeatureFlagListener(client),
  };
};

export const LaunchDarklyClientModule: LDClientModule = {
  init,
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

const createRegisterFeatureFlagListener = (
  client: LDJSClient,
): LDFlagChangeEventListener => {
  return (flagKey, callback) => {
    client.on(`change:${flagKey}`, callback);
  };
};

const createUnregisterFeatureFlagListener = (
  client: LDJSClient,
): LDFlagChangeEventListener => {
  return (flagKey, callback) => {
    client.off(`change:${flagKey}`, callback);
  };
};

import { useLayoutEffect } from 'react';
import dedent from 'dedent-js';
import type { CombinedError } from 'urql';

import { useSendSessionInitToAuthMachine } from '@order/AuthMachine';
import { getGraphQLEndpoint } from '@order/Urql';

import { useGetSessionQuery } from './Session.query.generated';

export const useSession = () => {
  const [response, executeQuery] = useGetSessionQuery({
    pause: true,
    requestPolicy: 'network-only',
  });
  const sendSessionInitToAuthMachine = useSendSessionInitToAuthMachine();

  // ────────────────────────────────────────────────────────────────────

  const isLoggedIn = response.data?.session?.isLoggedIn;
  const csrf = response.data?.session?.csrf;

  // ────────────────────────────────────────────────────────────────────

  const sessionReady =
    !response?.fetching && Boolean(response?.data?.session?.csrf);
  const sessionError = generateSessionError(response?.error);

  // ────────────────────────────────────────────────────────────────────────────────

  // get session at render
  useLayoutEffect(() => {
    const isSessionResolved = response.data !== undefined;

    if (!isSessionResolved) executeQuery();
  }, [executeQuery, response.data]);

  // synchronize with auth machine
  useLayoutEffect(() => {
    sessionReady && sendSessionInitToAuthMachine(isLoggedIn, csrf);
  }, [sessionReady, sendSessionInitToAuthMachine, isLoggedIn, csrf]);

  return { sessionReady, sessionError };
};

// ────────────────────────────────────────────────────────────────────────────────

const generateSessionError = (error: CombinedError | undefined) => {
  if (!error) return;

  return dedent`
    - Network Error for Session Request - ${getGraphQLEndpoint()}
    ${error.message}
    ${error.networkError?.message}
    ${error.graphQLErrors?.[0]?.message}
  `;
};

import React from 'react';
import type { ViewProps } from 'react-native';
import { Platform, StyleSheet, View } from 'react-native';
import {
  Button,
  HStack,
  IllusEmployeeBag,
  Image,
  Modal,
  theme,
  useResponsive,
} from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

import { TransparentModal } from '../../../../components/TransparentModal';
import type { ModalStackChildProps } from '../../../../navigation/AppNavigation.props';
import {
  useCancelReorder,
  useConfirmLocation,
  useFinishReorder,
  useReorderState,
} from '../../hooks';
import { useReorderTelemetry } from '../../machines/Reorder.telemetry';
import { useReorderRightIcon } from '../ReorderConfirmLocation/ReorderConfirmLocation.components';

const illustration = IllusEmployeeBag;

export const ReorderActiveBagWarning = ({
  navigation,
}: ModalStackChildProps<'ReorderActiveBagWarning'>) => {
  const { previousEvent, currentStep } = useReorderState();
  const confirmLocation = useConfirmLocation();
  const cancelReorder = useCancelReorder();
  const finishReorder = useFinishReorder();
  const { t } = useLocalizationContext();
  const { currentBreakpoint } = useResponsive();
  const { newOrderTelemetry, returnToBagTelemetry } = useReorderTelemetry();

  const onNewOrder = React.useCallback(() => {
    newOrderTelemetry();
    confirmLocation(previousEvent);
  }, [newOrderTelemetry, confirmLocation, previousEvent]);

  const onReturnToBag = React.useCallback(() => {
    returnToBagTelemetry();
    finishReorder();
  }, [returnToBagTelemetry, finishReorder]);

  if (currentStep !== 'activeBagWarning' && Platform.OS === 'web') return null;

  const CurrentLayout = currentBreakpoint.isXS ? MobileLayout : DesktopLayout;

  return (
    <TransparentModal
      testID="reorder-active-bag-warning"
      size="medium"
      onClose={cancelReorder}
      mobileEdges={['bottom']}
    >
      <CurrentLayout
        // @ts-expect-error TS(2719): Type 'import("/Users/gregorywestneat/projects/work... Remove this comment to see the full error message
        navigation={navigation}
        newOrder={onNewOrder}
        returnToBag={onReturnToBag}
      >
        <Modal.Headline>
          {t('reorder.active-bag-warning.headline')}
        </Modal.Headline>

        <Modal.BodyText>
          {t('reorder.active-bag-warning.message')}
        </Modal.BodyText>
      </CurrentLayout>
    </TransparentModal>
  );
};

const DesktopLayout = ({
  children,
  returnToBag,
  newOrder,
}: Readonly<{
  children: ViewProps['children'];
  returnToBag: () => void;
  newOrder: () => void;
}>) => {
  const { t } = useLocalizationContext();

  return (
    <Layout returnToBag={returnToBag} newOrder={newOrder}>
      <View style={styles.desktopImageContainer}>
        <Image style={styles.image} source={illustration} />
      </View>
      <View style={styles.content}>
        <Modal.CloseBtn
          onPress={returnToBag}
          accessibilityHint={t('general.close.a11y-hint')}
          accessibilityLabel={t('general.close')}
          accessibilityRole="imagebutton"
          testID="reorder.close-button"
        />
        {children}
      </View>
    </Layout>
  );
};

const MobileLayout = ({
  navigation,
  children,
  returnToBag,
  newOrder,
}: Readonly<{
  navigation: ModalStackChildProps<'ReorderConflictReview'>['navigation'];
  children: ViewProps['children'];
  returnToBag: () => void;
  newOrder: () => void;
}>) => {
  const rightIcon = useReorderRightIcon();

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => rightIcon,
      headerShown: true,
    });
  }, [navigation, rightIcon]);

  return (
    <Layout returnToBag={returnToBag} newOrder={newOrder}>
      <View style={styles.mobileImageContainer}>
        <Image style={styles.image} source={illustration} />
      </View>
      <View style={styles.content}>{children}</View>
    </Layout>
  );
};

const Layout = ({
  children,
  returnToBag,
  newOrder,
}: Readonly<{
  children: ViewProps['children'];
  returnToBag: () => void;
  newOrder: () => void;
}>) => {
  const { t } = useLocalizationContext();
  const { match } = useResponsive();

  return (
    <>
      <View style={match([styles.column, styles.row])}>{children}</View>

      <Modal.Footer
        style={match([undefined, styles.desktopFooter])}
        withoutTopBorder
      >
        <HStack itemsPerRow={2} gap={theme.spacing['4']}>
          <Button
            size="large"
            palette="secondary"
            onPress={returnToBag}
            accessibilityLabel={t('reorder.active-bag-warning.return-to-bag')}
            accessibilityHint={t('reorder.active-bag-warning.return-to-bag')}
            accessibilityRole="button"
            testID="reorder.active-bag-warning.return-to-bag"
          >
            {t('reorder.active-bag-warning.return-to-bag')}
          </Button>
          <Button
            size="large"
            onPress={newOrder}
            accessibilityLabel={t('reorder.active-bag-warning.new-order')}
            accessibilityHint={t('reorder.active-bag-warning.new-order')}
            accessibilityRole="button"
            testID="reorder.active-bag-warning.new-order"
          >
            {t('reorder.active-bag-warning.new-order')}
          </Button>
        </HStack>
      </Modal.Footer>
    </>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  desktopFooter: {
    paddingTop: theme.spacing['6'],
  },
  mobileImageContainer: {
    minHeight: 212,
    paddingVertical: theme.spacing['6'],
    marginVertical: theme.spacing['6'],
    alignItems: 'center',
  },
  desktopImageContainer: {
    paddingTop: theme.spacing['6'],
    paddingLeft: theme.spacing['6'],
  },
  image: {
    height: 212,
    width: 212,
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flex: 1,
    flexDirection: 'column',
  },
});

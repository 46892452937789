import React from 'react';
import { StyleSheet, View } from 'react-native';
import Svg, { Circle } from 'react-native-svg';
import { COLORS, ELEVATIONS } from '@garnish/constants';

export const ChipBadge = () => (
  <View testID="chip-badge" style={styles.badge}>
    <Svg height="100%" width="100%" viewBox="0 0 100 100">
      <Circle cx="50" cy="50" r="50" fill={COLORS.GRAPEFRUIT} />
    </Svg>
  </View>
);

const styles = StyleSheet.create({
  badge: {
    backgroundColor: COLORS.GRAPEFRUIT,
    width: 8,
    height: 8,
    borderRadius: 4,
    ...ELEVATIONS[3],
  },
});

import { useCallback } from 'react';
import { openBrowserAsync } from 'expo-web-browser';

import { useTelemetry } from '@order/Telemetry';

export const useSocialLinks = (withHref?: boolean) => {
  const { track } = useTelemetry();

  const handleInstagramTelemetry = useCallback(() => {
    if (withHref) void openBrowserAsync(INSTAGRAM);

    track('social_media_links', { link: 'instagram' });
  }, [track, withHref]);

  const handleTwitterTelemetry = useCallback(() => {
    if (withHref) void openBrowserAsync(TWITTER);

    track('social_media_links', { link: 'twitter' });
  }, [track, withHref]);

  const handleTiktokTelemetry = useCallback(() => {
    if (withHref) void openBrowserAsync(TIKTOK);

    track('social_media_links', { link: 'tiktok' });
  }, [track, withHref]);

  const handleSpotifyTelemetry = useCallback(() => {
    if (withHref) void openBrowserAsync(SPOTIFY);

    track('social_media_links', { link: 'spotify' });
  }, [track, withHref]);

  const handleFacebookTelemetry = useCallback(() => {
    if (withHref) void openBrowserAsync(FACEBOOK);

    track('social_media_links', { link: 'facebook' });
  }, [track, withHref]);

  return {
    handleInstagramTelemetry,
    handleTwitterTelemetry,
    handleTiktokTelemetry,
    handleSpotifyTelemetry,
    handleFacebookTelemetry,
  };
};

const INSTAGRAM = 'https://www.instagram.com/sweetgreen/';
const TWITTER = 'https://twitter.com/sweetgreen';
const TIKTOK = 'https://www.tiktok.com/@sweetgreen';
const SPOTIFY = 'https://open.spotify.com/user/1224421164';
const FACEBOOK = 'http://facebook.com/sweetgreen';

import type {
  PressableStateCallbackType as UIEventStates,
  StyleProp,
  TextStyle,
  ViewStyle,
} from 'react-native';

import type {
  ButtonPalette as Palette,
  ButtonSize as Size,
} from '../../Button.types';
import type { boxStyles, iconStyles, textStyles } from '../styles';

/**
 * This is used to create style hooks for each button component: Box, Text, Icon
 * - First it is initialized with the stylesheet specific to the component
 * - Next it accepts garnish configuration props (palette, size)
 * - Last is a function which will respond to state changes hovered/pressed
 *
 */

export const createUseButtonStylesHook: CreateUseButtonStylesHook = (
  styleGroup,
) => {
  const getPaletteStyles = makeGetPalette(styleGroup);
  const getSizeStyles = makeGetSize(styleGroup);
  const getDisabledStyles = makeGetDisabled(styleGroup);

  return (palette, size) => {
    const sizeStyles = getSizeStyles(size);

    return (disabled, uiEventState) => {
      if (disabled) {
        return [sizeStyles, getDisabledStyles(palette)];
      }

      const paletteStyles = getPaletteStyles(palette, isPressy(uiEventState));

      return [sizeStyles, paletteStyles];
    };
  };
};

// -----------------------------------------------------------------------------
// ---- Helpers ----------------------------------------------------------------
// -----------------------------------------------------------------------------

const makeGetPalette: MakeGetPalette = (styleGroup) => (palette, isPressed) => {
  const selector = isPressed ? `${palette}:${PRESSED}` : palette;

  return styleGroup[selector];
};

const makeGetSize: MakeGetSize = (styleGroup) => (size) => styleGroup[size];

const makeGetDisabled: MakeGetDisabled = (styleGroup) => (palette) => {
  return styleGroup[`${palette}:${DISABLED}`] ?? styleGroup[DISABLED];
};

// the designs treat "pressed" and "hovered" states the same
export const isPressy: IsPressy = (uiEventState) => {
  // @ts-expect-error TS(2339): Property 'hovered' does not exist on type 'Pressab... Remove this comment to see the full error message
  return uiEventState.hovered ?? uiEventState.pressed ?? false;
};

// -----------------------------------------------------------------------------
// ---- Types ------------------------------------------------------------------
// -----------------------------------------------------------------------------

const PRESSED = 'pressed';
const DISABLED = 'disabled';

type CreateUseButtonStylesHook = (
  sg: StyleGroups,
) => (
  p: PaletteTypes,
  s: SizeTypes,
) => (d: boolean, ui: UIEventStates) => ReturnedStyles;

type MakeGetPalette = (
  sg: StyleGroups,
) => (p: PaletteTypes, ip: boolean) => ReturnedStyles;

type MakeGetSize = (sg: StyleGroups) => (s: SizeTypes) => ReturnedStyles;

type MakeGetDisabled = (sg: StyleGroups) => (p: PaletteTypes) => ReturnedStyles;

type IsPressy = (ui: UIEventStates) => boolean;

type SizeTypes = Required<keyof typeof Size>;
type PaletteTypes = Required<keyof typeof Palette>;
type ReturnedStyles = StyleProp<ViewStyle | TextStyle>;

type StyleGroups = typeof boxStyles | typeof textStyles | typeof iconStyles;

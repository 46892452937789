import type { ComponentProps, ReactNode } from 'react';
import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { theme, useResponsive, VStack } from '@sg/garnish';

import {
  LocationResultCardLoading,
  LocationResultsCard,
} from '../../../LocationResultCard';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationResultsList = memo((props: LocationResultsListProps) => {
  const {
    locations,
    withDetailsModal,
    interactedLocationRestaurantSlug,
    onCardPress,
    onCardLayout,
    onCardFocus,
    onCardUnmount,
    isLoading,
  } = props;

  const { currentBreakpoint } = useResponsive();

  // ─── Loading View ────────────────────────────────────────────────────

  if (isLoading) {
    return (
      <LocationResultsListStack>
        {CARD_LOADING_PLACEHOLDERS.map((placeholder) => (
          <LocationResultCardLoading key={placeholder} />
        ))}
      </LocationResultsListStack>
    );
  }

  // ─── Loaded View ─────────────────────────────────────────────────────

  return (
    <LocationResultsListStack>
      {locations.map((location, index) => {
        const { id, restaurantSlug } = location;

        const isSingleItem = locations.length === 0;
        const isLastItem = index === locations.length - 1;
        const isInteracted =
          interactedLocationRestaurantSlug !== undefined &&
          restaurantSlug !== undefined &&
          interactedLocationRestaurantSlug === restaurantSlug;
        const isWithBottomBorder =
          currentBreakpoint.isXS && !isSingleItem && !isLastItem;

        return (
          <LocationResultsCard
            key={id}
            location={location}
            withBottomBorder={isWithBottomBorder}
            withDetailsModal={withDetailsModal}
            isLoading={isInteracted}
            isDisabled={isInteracted}
            onLayout={onCardLayout}
            onUmount={onCardUnmount}
            onPress={onCardPress}
            onFocus={onCardFocus}
          />
        );
      })}
    </LocationResultsListStack>
  );
});

const LocationResultsListStack = (props: LocationResultsListStackProps) => {
  const { children, withDivider } = props;

  return (
    <VStack
      style={styles.list}
      gap={0}
      hasDivider={withDivider}
      dividerColor={theme.colors.LIGHT}
    >
      {children}
    </VStack>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const CARD_LOADING_PLACEHOLDERS = [1, 2, 3, 4, 5];

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  list: {
    paddingHorizontal: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationResultsListProps = Readonly<{
  locations: LocationResultCardProps['location'][];
  isLoading?: boolean;

  /**
   * While interacting with a location card (reordering, navigation, etc.),
   * we occasionally need to display the loading, disabled states.
   * To display the corresponding additional state, we match the target location
   * card restaurant slug to the interacted location restaurant slug.
   * */
  interactedLocationRestaurantSlug?: string;

  onCardPress: LocationResultCardProps['onPress'];
  onCardLayout: LocationResultCardProps['onLayout'];
  onCardFocus?: LocationResultCardProps['onFocus'];
  onCardUnmount?: LocationResultCardProps['onUmount'];
}> &
  Pick<LocationResultCardProps, 'withDetailsModal'>;

type LocationResultCardProps = ComponentProps<typeof LocationResultsCard>;

type LocationResultsListStackProps = {
  children: ReactNode;
  withDivider?: boolean;
};

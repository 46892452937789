import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Button,
  HStack,
  ScrollViewWithHeaderTitle,
  TextLinkifyTags,
  theme,
  TitleText,
  useResponsive,
  VStack,
} from '@sg/garnish';

import { NotFoundView } from '@order/components';
import { useLocalizationContext } from '@order/Localization';

import type { PersonalDataScreenProps } from '../../navigation/AppNavigation.props';
import { usePersonalDataAvailability } from './PersonalData.availability';

export const PersonalDataScreen = ({ navigation }: PersonalDataScreenProps) => {
  const isPersonalDataRequestEnabled = usePersonalDataAvailability();
  const { t } = useLocalizationContext();
  const { match, currentBreakpoint } = useResponsive();

  // ─── Links ───────────────────────────────────────────────────────────

  const links = useMemo(
    () => [
      {
        tag: 'privacy_policy',
        description: t('personal-data.privacy-policy.description'),
        href: t('personal-data.privacy-policy.href'),
        newTab: true,
      },
      {
        tag: 'email',
        description: t('personal-data.email.description'),
        href: t('personal-data.email.href'),
      },
      {
        tag: 'phone',
        description: t('personal-data.phone.description'),
        href: t('personal-data.phone.href'),
      },
      {
        tag: 'opt_out',
        description: t('personal-data.opt-out.description'),
        href: t('personal-data.opt-out.href'),
        newTab: true,
      },
    ],
    [t],
  );

  // ─── Navigation Callbacks ───────────────────────────────────────

  const { handleAccessRequest, handleDeleteRequest } =
    usePersonalDataRequestNavigation(navigation);

  // ─── Responsive Styles ──────────────────────────────────────────

  const Stack = currentBreakpoint.isXS ? VStack : HStack;
  const contentStyle = match([styles.contentSM, styles.contentLG]);
  const titleStyle = match([styles.titleSM, styles.titleLG]);

  // ─── Not Found View When Disabled ────────────────────────────────────

  if (!isPersonalDataRequestEnabled) {
    return <NotFoundView />;
  }

  return (
    <ScrollViewWithHeaderTitle>
      <View style={contentStyle}>
        <TitleText style={titleStyle} size={3}>
          {t('personal-data.title')}
        </TitleText>
        <BodyText size={3}>
          <TextLinkifyTags links={links}>
            {t('personal-data.body')}
          </TextLinkifyTags>
        </BodyText>
        <Stack style={styles.buttons} itemsPerRow={2}>
          <Button
            testID="personal-data.request-access"
            palette="secondary"
            onPress={handleAccessRequest}
          >
            {t('personal-data.access-cta')}
          </Button>
          <Button
            testID="personal-data.request-delete"
            palette="secondary"
            onPress={handleDeleteRequest}
          >
            {t('personal-data.delete-cta')}
          </Button>
        </Stack>
      </View>
    </ScrollViewWithHeaderTitle>
  );
};

// ─── Navigation Hooks ───────────────────────────────────────────────
const usePersonalDataRequestNavigation = (
  navigation: PersonalDataScreenProps['navigation'],
) => {
  const handleAccessRequest = useCallback(() => {
    navigation.navigate('PersonalDataDetails', { action: 'access' });
  }, [navigation]);

  const handleDeleteRequest = useCallback(() => {
    navigation.navigate('PersonalDataDetails', { action: 'delete' });
  }, [navigation]);

  return useMemo(
    () => ({ handleAccessRequest, handleDeleteRequest }),
    [handleAccessRequest, handleDeleteRequest],
  );
};

// ─── Styles ─────────────────────────────────────────────────────────
const styles = StyleSheet.create({
  contentLG: {
    width: '80%',
    maxWidth: 560,
    marginVertical: theme.spacing['20'],
    marginHorizontal: 'auto',
  },
  contentSM: {
    width: '90%',
    maxWidth: 560,
    marginVertical: theme.spacing['6'],
    marginHorizontal: 'auto',
  },
  titleLG: {
    marginBottom: theme.spacing['6'],
    textAlign: 'center',
  },
  titleSM: {
    marginBottom: theme.spacing['6'],
  },
  buttons: {
    marginTop: theme.spacing['6'],
  },
});

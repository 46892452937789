import React, {
  type ComponentProps,
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  type ScrollView,
  StyleSheet,
  View,
  type ViewProps,
  type ViewStyle,
} from 'react-native';
import Animated, { SequencedTransition } from 'react-native-reanimated';
import { theme } from '@garnish/constants';
import { FadeView } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationSelectedIngredientsList = (
  props: CustomizationSelectedIngredientsListProps,
) => {
  const { children, style, ...restProps } = props;

  return (
    <View style={[styles.container, style]} {...restProps}>
      {children}
    </View>
  );
};

export const CustomizationSelectedIngredientsListItem = (
  props: CustomizationSelectedIngredientsListItemProps,
) => {
  const {
    children,
    style,
    isActive,
    scrollViewRef,
    shouldScrollToSelf,
    ...restProps
  } = props;

  // ─── Refs ────────────────────────────────────────────────────────────

  const containerRef = useRef<View>(null);

  // ─── Styles ──────────────────────────────────────────────────────────

  const dynamicStyles = useMemo<ViewStyle>(
    () => ({ zIndex: isActive ? 1 : undefined }),
    [isActive],
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  const scrollToSelf = useCallback(
    (_x: number, y: number) => {
      const scrollOffset = 80;

      scrollViewRef?.current?.scrollTo({ y: y - scrollOffset });
    },
    [scrollViewRef],
  );

  const measureLayout = useCallback(
    (onMeasure: (x: number, y: number) => void) => {
      const scrollView = scrollViewRef?.current;

      if (!shouldScrollToSelf || !scrollView) return;

      containerRef.current?.measureLayout(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        scrollView?.getInnerViewNode(),
        onMeasure,
      );
    },
    [scrollViewRef, shouldScrollToSelf],
  );

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    if (!shouldScrollToSelf) return;

    measureLayout(scrollToSelf);
  }, [measureLayout, scrollToSelf, scrollViewRef, shouldScrollToSelf]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View
      ref={containerRef}
      style={[styles.listItem, dynamicStyles, style]}
      {...restProps}
    >
      <FadeView show={true}>
        <Animated.View layout={SequencedTransition}>{children}</Animated.View>
      </FadeView>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-start',
    gap: theme.spacing['3'],
  },
  listItem: {
    alignItems: 'flex-start',
    width: '100%',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationSelectedIngredientsListProps = ComponentProps<typeof View>;

type CustomizationSelectedIngredientsListItemProps = {
  // NOTE: For some reason, TS was not happy with `LI` component's default styles.
  style?: ViewProps['style'];
  isActive?: boolean;
  shouldScrollToSelf?: boolean;
  scrollViewRef?: RefObject<ScrollView>;
} & Omit<ComponentProps<typeof View>, 'style'>;

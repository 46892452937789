/* eslint-disable @typescript-eslint/consistent-type-imports, @typescript-eslint/consistent-type-assertions, object-shorthand */

import { type MutableRefObject } from 'react';
import {
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  type ScrollView,
} from 'react-native';
import { assign, createMachine, raise } from 'xstate';

import { getNavItemScrollOffset, getTargetsMeasurements } from './helpers';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A state machine with an interface to add a "scrollspy" feature with anchor scrolling support.
 */
export const scrollspyMachine = createMachine(
  {
    id: 'scrollspy-machine',
    preserveActionOrder: true,
    predictableActionArguments: true,
    tsTypes: {} as import('./scrollspy.machine.typegen').Typegen0,
    schema: {
      context: {} as ScrollspyMachineContext,
      events: {} as ScrollspyMachineEvents,
      services: {} as ScrollspyMachineServices,
    },
    context: {
      defaultActiveTargetId: undefined,

      refs: {
        navScrollView: null,
        scrollView: null,
        targets: {},
        navItems: {},
      },
      layout: {
        scrollViewSize: null,
        navScrollViewSize: null,
        scrollViewContentSize: null,
      },
      measurements: {
        navItems: {},
        targets: {},
      },
      state: {
        hasFinishedScrolling: false,
        activeTargetId: null,
        scrollOffsetY: 0,
        navScrollOffsetX: 0,
      },
    },
    initial: 'idle',
    states: {
      idle: {
        id: 'idle',
        description: 'An idle state where machine can track user scrolling',
        on: {
          'scroll-view.scroll': {
            actions: ['setScrollOffsetY', 'setActiveTargetBasedOnScrollOffset'],
          },
        },
      },
      'measuring-targets': {
        id: 'measuring-targets',
        description: 'An async state for measuring targets',
        invoke: {
          src: 'getAllTargetsMeasurements',
          onDone: {
            target: '#idle',
            actions: [
              'storeMeasurements',
              'setInitialActiveTarget',
              'scrollToDefaultTarget',
            ],
          },
          onError: { target: '#idle' },
        },
      },
      'checking-if-should-scroll-to-active-target': {
        id: 'checking-if-should-scroll-to-active-target',
        description:
          'A state to decide if the app should scroll to the newly specified active target.',
        always: [
          { target: '#idle', cond: 'checkIfTargetAlreadyVisible' },
          { target: '#scrolling-to-active-target-id' },
        ],
      },
      'scrolling-to-active-target': {
        id: 'scrolling-to-active-target-id',
        entry: ['scrollToActiveTarget'],
        initial: 'scrolling',
        after: {
          // If scrolling to the active target fails or is interrupted after a
          // second, return to the idle state to allow for future scroll events.
          1000: '#idle',
        },
        states: {
          scrolling: {
            id: 'scrolling-to-active-target.scrolling',

            on: {
              'scroll-view.scroll': {
                actions: [
                  'setScrollOffsetYForActiveTarget',
                  raise({ type: 'scroll-view.check-if-finished-scrolling' }),
                ],
              },
              'scroll-view.check-if-finished-scrolling': {
                target: '#scrolling-to-active-target.finished-scrolling',
                cond: 'checkIfFinishedScrolling',
              },
            },
          },
          'finished-scrolling': {
            id: 'scrolling-to-active-target.finished-scrolling',

            // Return to the idle state after the animation is completed.
            after: {
              // NOTE: Extra timeout to ignore any last milliseconds events.
              100: '#idle',
            },
          },
        },
      },
    },
    on: {
      'nav.register': {
        actions: ['registerNavRef'],
      },
      'nav.deregister': {
        actions: ['deregisterNavRef'],
      },
      'nav.store-size': {
        actions: ['storeNavLayout'],
      },
      'nav.scroll': {
        actions: ['setNavScrollOffsetX'],
      },
      'nav.scroll-to-active': {
        actions: ['scrollToActiveNavItem'],
      },

      'scroll-view.register': {
        actions: ['registerScrollViewRef'],
      },
      'scroll-view.deregister': {
        actions: ['deregisterScrollViewRef'],
      },
      'scroll-view.store-size': {
        actions: ['storeScrollViewLayout'],
      },
      'scroll-view.store-content-size': {
        target: '#measuring-targets',
        actions: ['storeScrollViewContentSize'],
      },

      'nav-item.register': { actions: ['registerNavItemRef'] },
      'nav-item.deregister': { actions: ['deregisterNavItemRef'] },

      'target.register': { actions: ['registerTargetRef'] },
      'target.deregister': { actions: ['deregisterTargetRef'] },
      'target.set-active': {
        target: '#checking-if-should-scroll-to-active-target',
        actions: ['setActiveTargetBasedOnId'],
      },
    },
  },
  {
    services: {
      async getAllTargetsMeasurements(context) {
        const { refs, state } = context;
        const { scrollView, navScrollView, targets, navItems } = refs;
        const { scrollOffsetY, navScrollOffsetX } = state;

        const navItemsMeasurements = await getTargetsMeasurements({
          scrollView: navScrollView,
          scrollOffset: { x: navScrollOffsetX, y: 0 },
          targets: navItems,
        });
        const targetsMeasurements = await getTargetsMeasurements({
          scrollView,
          scrollOffset: { x: 0, y: scrollOffsetY },
          targets,
        });

        return {
          navItems: navItemsMeasurements,
          targets: targetsMeasurements,
        };
      },
    },
    actions: {
      //
      // ─── Refs ────────────────────────────────────────────

      registerNavRef: assign({
        refs: (context, event) => ({
          ...context.refs,
          navScrollView: event.ref,
        }),
      }),
      deregisterNavRef: assign({
        refs: (context) => ({ ...context.refs, navScrollView: null }),
      }),

      registerScrollViewRef: assign({
        refs: (context, event) => ({ ...context.refs, scrollView: event.ref }),
      }),
      deregisterScrollViewRef: assign({
        refs: (context) => ({ ...context.refs, scrollView: null }),
      }),
      registerNavItemRef: assign({
        refs: (context, event) => ({
          ...context.refs,
          navItems: {
            ...context.refs.navItems,
            [event.itemId]: event.itemRef,
          },
        }),
      }),
      deregisterNavItemRef: assign({
        refs(context, event) {
          const { refs } = context;
          const { itemId } = event;

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [itemId]: _itemRefToFilter, ...restItemRefs } = refs.navItems;

          return { ...refs, navItems: restItemRefs };
        },
      }),
      registerTargetRef: assign({
        refs: (context, event) => ({
          ...context.refs,
          targets: {
            ...context.refs.targets,
            [event.targetId]: event.targetRef,
          },
        }),
      }),
      deregisterTargetRef: assign({
        refs(context, event) {
          const { refs } = context;
          const { targetId } = event;

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { [targetId]: _targetRefToFilter, ...restTargetRefs } =
            refs.targets;

          return { ...refs, targets: restTargetRefs };
        },
      }),

      // ─── Layout And Measurements ─────────────────────────

      storeNavLayout: assign({
        layout: (context, event) => ({
          ...context.layout,
          navScrollViewSize: { width: event.width, height: event.height },
        }),
      }),
      storeScrollViewLayout: assign({
        layout: (context, event) => ({
          ...context.layout,
          scrollViewSize: { width: event.width, height: event.height },
        }),
      }),
      storeScrollViewContentSize: assign({
        layout: (context, event) => ({
          ...context.layout,
          scrollViewContentSize: { width: event.width, height: event.height },
        }),
      }),
      storeMeasurements: assign({
        measurements: (context, event) => {
          const { measurements } = context;
          const { data: allMeasurements } = event;

          const { navItems, targets } = allMeasurements;

          if (!navItems && !targets) {
            return measurements;
          }

          return {
            ...measurements,
            navItems: navItems ?? measurements.navItems,
            targets: targets ?? measurements.targets,
          };
        },
      }),
      setScrollOffsetY: assign({
        state: (context, event) => {
          const { state } = context;
          const { contentOffset } = event.scrollEvent.nativeEvent;

          const updatedScrollOffsetY = contentOffset.y;

          return {
            ...state,
            // NOTE: `Math.max` is used to ignore negative values during momentum scroll.
            scrollOffsetY: Math.max(0, updatedScrollOffsetY),
          };
        },
      }),
      setScrollOffsetYForActiveTarget: assign({
        state: (context, event) => {
          const { state, measurements, layout } = context;
          const { activeTargetId } = state;
          const { scrollViewContentSize, scrollViewSize } = layout;
          const { contentOffset } = event.scrollEvent.nativeEvent;

          const target = activeTargetId
            ? measurements.targets[activeTargetId]
            : null;

          // Ignore unrecognized target
          if (!target) {
            return state;
          }

          // NOTE: `Math.ceil` is used to cover iOS, which allows offset to
          // be represented as a float.

          const updatedScrollOffsetY = Math.ceil(contentOffset.y);
          const scrollViewContentHeight = scrollViewContentSize?.height ?? 0;
          const scrollViewHeight = scrollViewSize?.height ?? 0;
          const maxScrollOffsetY = scrollViewContentHeight - scrollViewHeight;

          const targetY = Math.ceil(target.y);
          const targetScrollOffsetY = Math.min(maxScrollOffsetY, targetY);

          // Scroll is considered finished when it has reached the target scroll
          // position or the maximum allowed scroll offset.
          const hasFinishedScrolling =
            targetScrollOffsetY === updatedScrollOffsetY;

          return {
            ...state,
            scrollOffsetY: updatedScrollOffsetY,
            hasFinishedScrolling,
          };
        },
      }),
      setNavScrollOffsetX: assign({
        state: (context, event) => {
          const { state } = context;
          const { contentOffset } = event.scrollEvent.nativeEvent;

          const updatedScrollOffsetX = contentOffset.x;

          return {
            ...state,
            navScrollOffsetX: updatedScrollOffsetX,
          };
        },
      }),

      // ─── Active Target ───────────────────────────────────

      setInitialActiveTarget: assign({
        state: (context) => {
          const { defaultActiveTargetId = '', state, measurements } = context;
          const { targets } = measurements;

          const targetIds = Object.keys(targets);
          const maybeDefaultActiveTargetId = targetIds.includes(
            defaultActiveTargetId,
          )
            ? defaultActiveTargetId
            : null;
          const maybeFirstTargetId = Object.keys(targets)?.[0];

          return {
            ...state,
            activeTargetId:
              state.activeTargetId ??
              maybeDefaultActiveTargetId ??
              maybeFirstTargetId,
          };
        },
      }),
      scrollToActiveNavItem: (context) => {
        const { refs, measurements, layout, state } = context;

        const { activeTargetId, navScrollOffsetX } = state;
        const { navScrollView } = refs;
        const { navItems: navItemsMeasurements } = measurements;
        const { navScrollViewSize } = layout;

        if (!activeTargetId || !navScrollView?.current) return;

        const navItemScrollOffset = getNavItemScrollOffset({
          activeTargetId,
          navItemsMeasurements,
          navScrollOffsetX,
          navScrollViewSize,
        });

        if (typeof navItemScrollOffset !== 'number') return;

        navScrollView.current.scrollTo({
          x: navItemScrollOffset,
          animated: true,
        });
      },

      setActiveTargetBasedOnId: assign({
        state: (context, event) => ({
          ...context.state,
          activeTargetId: event.targetId,
        }),
      }),
      scrollToDefaultTarget: (context) => {
        const { refs, measurements, defaultActiveTargetId } = context;
        const { scrollView } = refs;
        const { targets } = measurements;

        const target = defaultActiveTargetId
          ? targets[defaultActiveTargetId]
          : null;

        if (!target) {
          return;
        }

        const scrollOffset = target.y ?? 0;

        scrollView?.current?.scrollTo({ y: scrollOffset, animated: false });
      },
      scrollToActiveTarget: (context) => {
        const { refs, measurements, state } = context;
        const { activeTargetId } = state;
        const { scrollView } = refs;
        const { targets } = measurements;

        const target = activeTargetId ? targets[activeTargetId] : null;

        if (!target) {
          return;
        }

        const scrollOffset = target.y ?? 0;

        scrollView?.current?.scrollTo({ y: scrollOffset, animated: true });
      },
      setActiveTargetBasedOnScrollOffset: assign({
        state(context) {
          const { measurements, state } = context;
          const { scrollOffsetY, activeTargetId: currentActiveTargetId } =
            state;

          const targets = Object.entries(measurements.targets);
          const sortedTargets = [...targets].sort(
            (currentTarget, nextTarget) => nextTarget[1].y - currentTarget[1].y,
          );
          const activeTargetBasedOnScrollPosition = sortedTargets.find(
            ([_, targetMeasurements]) => scrollOffsetY >= targetMeasurements.y,
          );

          const maybeFirstTargetId = targets?.[0]?.[0];
          const activeTargetId =
            activeTargetBasedOnScrollPosition?.[0] ?? maybeFirstTargetId;

          if (activeTargetId === currentActiveTargetId) return state;

          return { ...state, activeTargetId };
        },
      }),
    },
    guards: {
      checkIfTargetAlreadyVisible: (context) => {
        const { targets } = context.measurements;
        const { scrollViewSize, scrollViewContentSize } = context.layout;
        const { activeTargetId, scrollOffsetY } = context.state;

        // ─── Derived Data ────────────────────────────

        const activeTargetMeasurements = activeTargetId
          ? targets[activeTargetId]
          : null;
        const activeTargetY = activeTargetMeasurements?.y ?? 0;
        const scrollViewHeight = scrollViewSize?.height ?? 0;
        const scrollViewScrollBottomPosition = scrollOffsetY + scrollViewHeight;
        const scrollViewScrollHeight = scrollViewContentSize?.height ?? 0;

        // ─── Flags ───────────────────────────────────

        const isScrollViewCompletelyVisible =
          scrollViewScrollHeight <= scrollViewHeight;
        const isAtActiveTargetScrollPosition = activeTargetY === scrollOffsetY;
        const isActiveTargetIsPartiallyVisible = activeTargetY < scrollOffsetY;
        const hasReachedEnd =
          scrollViewScrollBottomPosition === scrollViewScrollHeight;

        // ─────────────────────────────────────────────

        if (isAtActiveTargetScrollPosition) {
          return true;
        }

        if (isScrollViewCompletelyVisible) {
          return true;
        }

        if (isActiveTargetIsPartiallyVisible) {
          return false;
        }

        return hasReachedEnd;
      },
      checkIfFinishedScrolling: (context) => context.state.hasFinishedScrolling,
    },
  },
);

// ─── Main Types ──────────────────────────────────────────────────────────────

type ScrollspyMachineContext = {
  defaultActiveTargetId: string | undefined;
  refs: {
    navScrollView: ScrollViewRef | null;
    navItems: Record<TargetId, TargetRef>;
    scrollView: ScrollViewRef | null;
    targets: Record<TargetId, TargetRef>;
  };
  layout: {
    navScrollViewSize: { width: number; height: number } | null;
    scrollViewSize: { width: number; height: number } | null;
    scrollViewContentSize: { width: number; height: number } | null;
  };
  measurements: {
    navItems: Record<TargetId, TargetMeasurements>;
    targets: Record<TargetId, TargetMeasurements>;
  };
  state: {
    activeTargetId: string | null;
    hasFinishedScrolling: boolean;
    scrollOffsetY: number;
    navScrollOffsetX: number;
  };
};

type ScrollspyMachineEvents =
  | { type: 'nav.register'; ref: ScrollViewRef }
  | { type: 'nav.deregister' }
  | { type: 'nav.scroll'; scrollEvent: ScrollEvent }
  | { type: 'nav.scroll-to-active' }
  | { type: 'nav.store-size'; width: number; height: number }
  | { type: 'nav-item.register'; itemId: string; itemRef: TargetRef }
  | { type: 'nav-item.deregister'; itemId: string }
  | { type: 'scroll-view.register'; ref: ScrollViewRef }
  | { type: 'scroll-view.deregister' }
  | { type: 'scroll-view.store-size'; width: number; height: number }
  | { type: 'scroll-view.store-content-size'; width: number; height: number }
  | { type: 'scroll-view.scroll'; scrollEvent: ScrollEvent }
  | { type: 'scroll-view.check-if-finished-scrolling' }
  | { type: 'target.register'; targetId: string; targetRef: TargetRef }
  | { type: 'target.deregister'; targetId: string }
  | { type: 'target.set-active'; targetId: string };

type ScrollspyMachineServices = {
  getAllTargetsMeasurements: {
    data: {
      navItems: Record<string, TargetMeasurements> | null;
      targets: Record<string, TargetMeasurements> | null;
    };
  };
};

// ─── Other Types ─────────────────────────────────────────────────────────────

type ScrollViewRef = MutableRefObject<ScrollView | null>;

type ScrollEvent = NativeSyntheticEvent<NativeScrollEvent>;

type TargetRef = MutableRefObject<unknown>;

type TargetId = string;

type TargetMeasurements = {
  x: number;
  y: number;
  width: number;
  height: number;
};

import type {
  LastInteractedStore,
  LastInteractedStoresContext,
  LastInteractedStoresEvent,
} from './LastInteractedStore.model';
import { LastInteractedStoreModel } from './LastInteractedStore.model';

// ─── ACTIONS ────────────────────────────────────────────────────────────────────

export const setLastInteractedStore = LastInteractedStoreModel.assign({
  lastInteractedStore(context) {
    const {
      activeCart: activeCartStore,
      menu: menuStore,
      lastPlacedOrder: lastPlacedOrderStore,
    } = context.interactedStores;

    return activeCartStore ?? menuStore ?? lastPlacedOrderStore;
  },
});

export const setInteractedStore = (
  storeKind: keyof LastInteractedStoresContext['interactedStores'],
) =>
  LastInteractedStoreModel.assign({
    interactedStores: (context, event) => ({
      ...context.interactedStores,
      [storeKind]: getInteractedStore(event),
    }),
  });

export const resetInteractedStore = (
  storeKind: keyof LastInteractedStoresContext['interactedStores'],
) =>
  LastInteractedStoreModel.assign({
    interactedStores: (context) => ({
      ...context.interactedStores,
      [storeKind]: undefined,
    }),
  });

// ─── UTILS ──────────────────────────────────────────────────────────────────────

export const getInteractedStore = (
  event: LastInteractedStoresEvent,
): LastInteractedStore => {
  const lastInteractedStore = getInteractedStoreFromEvent(event);

  // return delivery address for delivery type stores
  if (lastInteractedStore?.addressId) {
    return { addressId: lastInteractedStore.addressId };
  }

  // return restaurant slug for pickup and outpost type stores
  if (lastInteractedStore?.restaurantSlug) {
    return { restaurantSlug: lastInteractedStore.restaurantSlug };
  }

  return undefined; // to be explicit
};

export const getInteractedStoreFromEvent = (
  event: LastInteractedStoresEvent,
) => {
  const eventType = event.type;
  const isSetStoreEvent =
    eventType === 'SET_ACTIVE_CART_STORE' ||
    eventType === 'SET_LAST_INTERACTED_MENU_STORE' ||
    eventType === 'SET_LAST_PLACED_ORDER_STORE';

  if (!isSetStoreEvent) return;

  return event.store;
};

import React, { type ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, HStack, Icon, theme, useShareTextDialog } from '@sg/garnish';

import { giftCardCheckoutDeliveryMethodMessageCardMessages as messages } from './GiftCardCheckoutDeliveryMethodMessageCard.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardCheckoutDeliveryMethodMessageCard = () => {
  const { canShare } = useShareTextDialog();

  return (
    <View style={styles.messagingAppsCard}>
      {canShare ? (
        <HStack gap={0} style={styles.messagingAppsList}>
          {SOCIAL_NETWORK_ICONS.map((iconName) => (
            <Icon
              key={`gift-card-checkout-social-network-${iconName}`}
              name={iconName}
              width={MESSAGING_APPS_ICON_SIZE}
              height={MESSAGING_APPS_ICON_SIZE}
            />
          ))}
        </HStack>
      ) : null}

      <BodyText sizeMatch={['12']}>
        {canShare ? (
          <FormattedMessage
            {...messages.messageOptionHelperTextMessagingApps}
          />
        ) : (
          <FormattedMessage {...messages.messageOptionHelperText} />
        )}
      </BodyText>
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const SOCIAL_NETWORK_ICONS: IconName[] = [
  'IconWhatsapp',
  'IconMessenger',
  'IconTelegram',
  'IconMessage',
  'IconInstagram',
];

const MESSAGING_APPS_ICON_SIZE = 24;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  messagingAppsCard: {
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTEST,
    borderRadius: theme.radius.large,
    marginTop: theme.spacing['4'],
    padding: theme.spacing['4'],
  },
  messagingAppsList: {
    marginBottom: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type IconName = ComponentProps<typeof Icon>['name'];

import { useRef } from 'react';

import type { MapProps, PinData } from '../../types';

/**
 * Returns a memoized not minimized pins reference to avoid additional renders.
 */
export const useNotMinimizedPinsRef = (pins: MapProps['pins'] = []) => {
  const notMinimizedPins = pins.filter(checkIfPinIsNotMinimized);
  const pinsRef = useRef(notMinimizedPins);

  // eslint-disable-next-line functional/immutable-data
  pinsRef.current = notMinimizedPins;

  return pinsRef;
};

// ─── Utils ───────────────────────────────────────────────────────────────────

function checkIfPinIsNotMinimized(pin: PinData) {
  return !pin.isMinimized;
}

import React, { memo } from 'react';
import { StyleSheet, useWindowDimensions, type ViewProps } from 'react-native';
import { type AddressType, Chip, theme } from '@sg/garnish';

import { useLocalizationContext } from '@order/Localization';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationSearchTypePicker = memo(
  (props: LocationSearchTypePickerProps) => {
    const {
      locationSearchType = '',
      isDisabled = false,
      onLocationSearchTypeChange,
    } = props;
    const { t } = useLocalizationContext();
    const { width } = useWindowDimensions();

    // ─── Flags ───────────────────────────────────────────────────────────

    const isSmallScreen = width <= 375;

    // ─── Styles ──────────────────────────────────────────────────────────

    const chipStyles = [
      styles.chipReset,
      isSmallScreen ? styles.chipCompact : undefined,
    ];

    // ─────────────────────────────────────────────────────────────────────

    return (
      <Chip.Menu
        value={locationSearchType}
        onChange={onLocationSearchTypeChange}
        style={styles.menu}
      >
        {LocationSearchTypes.map((addressType) => {
          const isChecked = addressType === locationSearchType;

          return (
            <Chip
              value={addressType}
              key={addressType}
              disabled={isDisabled}
              accessibilityLabel={t('general.address-type', { addressType })}
              accessibilityState={{ checked: isChecked }}
              maxFontSizeMultiplier={1.2}
              palette="primary"
              testID={`search-bar.chip.${addressType}`}
              style={chipStyles}
            >
              {t('general.address-type', { addressType })}
            </Chip>
          );
        })}
      </Chip.Menu>
    );
  },
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  menu: {
    justifyContent: 'center',
  },
  chipReset: {
    flex: undefined,
  },
  chipCompact: {
    paddingHorizontal: theme.spacing['3'],
  },
});

// ─────────────────────────────────────────────────────────────────────────────

const LocationSearchTypes: readonly AddressType[] = [
  'pickup',
  'delivery',
  'outpost',
];

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationSearchTypePickerProps = Readonly<{
  locationSearchType?: string;
  isDisabled?: boolean;
  onLocationSearchTypeChange: (searchType: string) => void;
}> &
  Pick<ViewProps, 'testID'>;

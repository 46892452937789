import React, { type ComponentProps } from 'react';
import { theme } from '@garnish/constants';
import { HorizontalScrollRail, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * The rail that contains the reward cards for the bag.
 */
export const BagRewardsRail = (
  props: Pick<
    ComponentProps<typeof HorizontalScrollRail>,
    | 'children'
    | 'heading'
    | 'showNavControls'
    | 'headingIcon'
    | 'outerOffset'
    | 'onScroll'
    | 'onContentSizeChange'
  >,
) => {
  const { minWidth } = useResponsive();

  const {
    showNavControls = minWidth.isSM,
    outerOffset = -theme.spacing['4'],
    heading,
    headingIcon,
    children,
    onScroll,
    onContentSizeChange,
  } = props;

  return (
    <HorizontalScrollRail
      withoutHeaderBorder
      heading={heading}
      headingIcon={headingIcon}
      showNavControls={showNavControls}
      gap={theme.spacing['2']}
      outerOffset={outerOffset}
      itemVisiblePercentThreshold={99}
      countPosition={minWidth.isSM ? 'left' : 'right'}
      onScroll={onScroll}
      onContentSizeChange={onContentSizeChange}
    >
      {children}
    </HorizontalScrollRail>
  );
};

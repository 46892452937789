import { useEffect } from 'react';
import type { useInterpret } from '@xstate/react';

import { getMachinePersistedState } from '../../dietaryRestrictionsMachine.utils';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Reads the persisted context of a dietary restrictions from Async storage
 */
export const useDietaryRestrictionsFromAsyncStorage = <
  Interpreter extends XStateInterpreter,
>(
  interpreter: Interpreter,
) => {
  useEffect(() => {
    (async () => {
      const persistedContext = await getMachinePersistedState();

      if (!persistedContext) return;

      const restrictions = JSON.parse(persistedContext);

      interpreter.send({ type: 'SET_RESTRICTIONS', restrictions });
    })();
  }, [interpreter]);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type XStateInterpreter = ReturnType<typeof useInterpret>;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { Container, HStack, theme, useResponsive } from '@sg/garnish';

import { SweetpassBenefitCard } from '../../../SweetpassBenefitCard';

// ─────────────────────────────────────────────────────────────────────────────

export const SweetpassLandingPageBenefits = (
  props: SweetpassUpgradeBenefitListContentProps,
) => {
  const { benefits, itemsPerRowMD = 3 } = props;

  const { match, currentBreakpoint } = useResponsive();

  // ─── Container ───────────────────────────────────────────────────────

  const containerResponsiveStyles = match([undefined, styles.containerSM]);
  const containerStyles = [styles.container, containerResponsiveStyles];

  // ─── Grid ────────────────────────────────────────────────────────────

  const itemsPerRow = match([1, 2, itemsPerRowMD]);

  const gridSpacing = match([0, theme.spacing['10']]);
  const gridStyleSM = useBenefitsCardsGridStyle(itemsPerRow);
  const gridResponsiveStyles = match([undefined, gridStyleSM]);
  const gridStyles = [styles.grid, gridResponsiveStyles];

  const stackStyles = match([undefined, styles.stackSM]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Container testID="sweetpass-upgrade-benefits-list" style={containerStyles}>
      <View style={gridStyles}>
        <HStack itemsPerRow={itemsPerRow} gap={gridSpacing} style={stackStyles}>
          {benefits.map((benefitItem, index) => {
            const isFirstItem = index === 0;
            const shouldRenderDivider = !isFirstItem && currentBreakpoint.isXS;

            return (
              <SweetpassBenefitCard
                key={benefitItem.id}
                title={benefitItem.title}
                text={benefitItem.text}
                imgA11yLabel={benefitItem.image?.description}
                imgUrl={benefitItem.image?.url}
                withTopBorder={shouldRenderDivider}
              />
            );
          })}
        </HStack>
      </View>
    </Container>
  );
};

// ─── Hooks ─────────────────────────────────────────────────────────────

const useBenefitsCardsGridStyle = (itemsPerRow: number) => {
  const { match } = useResponsive();

  const itemSize = match([0, BENEFITS_CARD_WIDTH_SM, BENEFITS_CARD_WIDTH_LG]);
  const itemsSize = itemsPerRow * itemSize;
  const marginSize = itemsPerRow * 2 * theme.spacing['6'];
  const rowSize = itemsSize + marginSize;

  return useStyle(() => ({ width: rowSize }), [rowSize]);
};

// ─── Constants ───────────────────────────────────────────────────────────────

export const BENEFITS_CARD_WIDTH_SM = 252;
export const BENEFITS_CARD_WIDTH_LG = 336;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    maxWidth: '100%',
    alignItems: 'center',
  },
  containerSM: {
    paddingVertical: theme.spacing['10'],
  },
  grid: {
    maxWidth: '100%',
  },
  stackSM: {
    justifyContent: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassUpgradeBenefitListContentProps = Readonly<{
  benefits: readonly Benefit[];
  itemsPerRowMD?: number;
}>;

type Benefit = Readonly<{
  id: string;
  title: string;
  text: string;
  image?: {
    url: string;
    description: string;
  };
}>;

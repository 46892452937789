import { DeliveryPreferenceType } from '@order/graphql';

export const defaultAddressForm = {
  addressType: 'home',
  name: '',
  street: '',
  secondaryStreet: '',
  city: '',
  zipCode: '',
  state: '',
  country: '',
  googlePlaceId: '',
  latitude: 0,
  longitude: 0,
  notes: '',
  deliveryPreference: DeliveryPreferenceType.LeaveAtDoor,
};

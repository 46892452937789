import { type DeliveryOrderDetail } from '@sg/graphql-schema';

import { type OrderingContext, sumLedgerTotal } from '@order/features/ordering';
import { type PartialLineItem } from '@order/graphql';
import {
  type TelemetryEvent,
  telemetryProductFromInput,
} from '@order/Telemetry';
import { convertPriceToFloat } from '@order/utils';

/**
 * Converts the machine context in the telemetry event data for purchase events.
 */
export const getCheckoutTelemetryData = (
  context: OrderingContext<PartialLineItem, DeliveryOrderDetail>,
): TelemetryEvent<'checkout.success'>['payload'] => {
  const products = context.cart.lineItems?.map(
    ({ product, cost, quantity, customName }) =>
      telemetryProductFromInput({
        ...product,
        restaurantId: context.cart?.restaurantId,
        location: context.cart?.locationName,
        cost,
        quantity,
        name: customName ?? product?.name,
      }),
  );

  const bagCount = products
    ?.map(({ quantity }) => quantity)
    .reduce((a, b) => (a ?? 0) + (b ?? 0), 0);

  const { total } = sumLedgerTotal(
    context.cart?.ledger,
    context.selectedTip,
    context.availableCredit,
    context.shouldUseCredit,
  );

  return {
    orderId: context.cart?.orderId,
    restaurantId: context.cart?.restaurantId,
    restaurantName: context.cart?.locationName,
    orderType: context.cart?.orderChannel,
    products,
    bagCount,
    orderValue: convertPriceToFloat(total),
    street: context.cart?.deliveryOrderDetail?.address?.street,
    city: context.cart?.deliveryOrderDetail?.address?.city,
    state: context.cart?.deliveryOrderDetail?.address?.state,
    zipCode: context.cart?.deliveryOrderDetail?.address?.zipCode,
    country: context.cart?.deliveryOrderDetail?.address?.country,
    isNewCustomer: context.customer.isNewCustomer,
    customer: {
      firstName: context.customer.firstName,
      lastName: context.customer.lastName,
      email: context.customer.email,
      phoneNumber: context.customer.phoneNumber,
    },
    order: {
      id: context.cart?.orderId,
      locationId: context.cart?.restaurantId,
      orderType: context.cart?.orderChannel,
      wantedTime: context.wantedTime?.time,
      asap: context.isWantedTimeAsap,
      salesTax: context.cart?.ledger?.tax ?? 0,
      total,
      tip: context.selectedTip,
      products: products?.map(({ id }) => id ?? ''),
    },
  };
};

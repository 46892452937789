import type { ReactNode } from 'react';
import React from 'react';
import type { AccessibilityRole, ViewProps } from 'react-native';
import { Platform, View } from 'react-native';

type ListProps = Readonly<{
  children: ReactNode;
}> &
  ViewProps;

/**
 * List
 */
export const List = React.memo((props: ListProps) => {
  const { children, ...rest } = props;

  return (
    <View
      {...(Platform.OS === 'web' ? { accessibilityRole: 'list' } : {})}
      {...rest}
    >
      {children}
    </View>
  );
});

export const ListItem = React.memo((props: ListProps) => {
  const { children, ...rest } = props;

  return (
    <View
      {...(Platform.OS === 'web'
        ? { accessibilityRole: 'listitem' as AccessibilityRole }
        : {})}
      {...rest}
    >
      {children}
    </View>
  );
});

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoyaltyInfoBenefitsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type LoyaltyInfoBenefitsQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyProfile: {
    readonly __typename?: 'LoyaltyProfile';
    readonly customerId: string;
    readonly points: {
      readonly __typename?: 'UserPointsStatus';
      readonly available: number;
    } | null;
    readonly pointsRewards: ReadonlyArray<{
      readonly __typename?: 'PointsReward';
      readonly id: string;
      readonly points: number;
      readonly assetUrl: string | null;
      readonly displayName: string;
    }> | null;
  } | null;
};

export const LoyaltyInfoBenefitsDocument = gql`
  query LoyaltyInfoBenefits {
    loyaltyProfile {
      customerId
      points {
        available
      }
      pointsRewards {
        id
        points
        assetUrl
        displayName
      }
    }
  }
`;

export function useLoyaltyInfoBenefitsQuery(
  options?: Omit<Urql.UseQueryArgs<LoyaltyInfoBenefitsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    LoyaltyInfoBenefitsQuery,
    LoyaltyInfoBenefitsQueryVariables
  >({ query: LoyaltyInfoBenefitsDocument, ...options });
}

import type { ComponentProps } from 'react';
import React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme, TYPE_CONFIG } from '@garnish/constants';

import { useResponsive } from '../../hooks';
import { slugify } from '../../utils';
import { Card } from '../Card';
import { IconLink } from '../Icon';
import { ProductIngredientsList } from '../ProductIngredientsList';
import { ShareIcon } from '../ShareIcon';
import { TagAlert } from '../TagAlert';
import { TagLabel } from '../TagLabel';
import { BodyText } from '../Text';
import { ProductCardImage } from './components';

export const ProductCard = React.memo((props: ProductCardProps) => {
  const {
    name,
    label,
    description,
    restrictionNotice,
    unavailableNotice,
    price,
    calories,
    quantity,
    favorited,
    cloudinaryImageSrc,
    addedIngredients = [],
    addedIngredientsLabel,
    removedIngredients = [],
    removedIngredientsLabel,
    FooterElement,
    accessibilityLabel,
    accessibilityHint,
    accessibilityRole = 'button',
    isDisabled,
    onCardPress,
    onFavorite,
    onShare,
  } = props;

  const { titleStyle, sizeStyles } = useConditionalCardStyles();

  const testIDPrefix = slugify(name);
  const wrapperTestID = `${testIDPrefix}-product-card`;
  const pressableTestID = `${testIDPrefix}-product-card-pressable`;
  const productNameTestID = `${testIDPrefix}-product-name`;
  const favoriteButtonTestID = `${testIDPrefix}-product-favorite`;

  const hasIngredientsList =
    addedIngredients.length + removedIngredients.length > 0;

  // ─────────────────────────────────────────────────────────────────

  return (
    <View style={styles.cardWrapper} testID={wrapperTestID}>
      {/* ========== card area ========== */}

      <Card
        onPress={isDisabled ? null : onCardPress}
        style={[styles.pressableWrapper, sizeStyles]}
        testID={pressableTestID}
        accessibilityRole={accessibilityRole}
        accessibilityLabel={accessibilityLabel}
        accessibilityHint={accessibilityHint}
        disabled={isDisabled}
      >
        <View style={styles.header}>
          {/* ========== label ========== */}

          <View style={styles.row}>
            {label ? (
              <View style={styles.labelWrapper}>
                <TagLabel
                  size={3}
                  palette={isDisabled ? 'tertiary' : 'primary'}
                >
                  {label}
                </TagLabel>
              </View>
            ) : null}

            {onFavorite ? (
              <IconLink
                width={24}
                height={24}
                style={styles.headerIcon}
                color={theme.colors.KALE}
                accessibilityLabel="favorite product"
                name={favorited ? 'IconHeartFill' : 'IconHeartStroke'}
                onPress={onFavorite}
                testID={favoriteButtonTestID}
              />
            ) : null}
          </View>

          <ProductCardImage
            aria-label={name}
            baseUrl={cloudinaryImageSrc ?? ''}
          />
        </View>

        {/* ========== product name ========== */}

        <BodyText
          size={2}
          testID={productNameTestID}
          bold={true}
          style={titleStyle}
        >
          {name}
        </BodyText>

        {/* ========== ========== ========== */}

        {hasIngredientsList || description ? (
          <View style={styles.ingredientsAndDescriptionWrapper}>
            {/* ========== ingredients ========== */}

            {hasIngredientsList ? (
              <View style={styles.ingredientsListWrapper}>
                <ProductIngredientsList
                  addedIngredients={addedIngredients}
                  addedIngredientsLabel={addedIngredientsLabel}
                  removedIngredients={removedIngredients}
                  removedIngredientsLabel={removedIngredientsLabel}
                  sizeMatch={['14', '16']}
                />
              </View>
            ) : null}

            {/* ========== description ========== */}

            {description ? (
              <BodyText sizeMatch={['14', '16']}>{description}</BodyText>
            ) : null}
          </View>
        ) : null}

        {quantity ? (
          <BodyText sizeMatch={['14', '16']} style={styles.quantity}>
            {quantity}
          </BodyText>
        ) : null}

        {/* ========== restriction notice ========== */}

        {restrictionNotice ? (
          <View style={styles.restrictionNoticeWrapper}>
            <TagAlert size="medium" palette="caution">
              {restrictionNotice}
            </TagAlert>
          </View>
        ) : null}

        {/* ========== unavailable notice ========== */}
        {unavailableNotice ? (
          <View
            style={
              restrictionNotice
                ? styles.unavailableNoticeWrapper
                : styles.restrictionNoticeWrapper
            }
          >
            <TagAlert size="medium" palette="caution">
              {unavailableNotice}
            </TagAlert>
          </View>
        ) : null}

        <View style={styles.footer}>
          {/* ========== price and calories ========== */}

          {price ?? calories ? (
            <View>
              <TagLabel testID="product-price" size={2} palette="secondary">
                <>
                  {price}
                  {price && calories ? ' — ' : ''}
                  {calories}
                </>
              </TagLabel>
            </View>
          ) : null}

          {onShare ? <ShareIcon onShare={onShare} /> : null}
        </View>

        {FooterElement}
      </Card>
    </View>
  );
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type ProductCardProps = Readonly<{
  name: string;
  price?: string;
  calories?: string;
  quantity?: string;
  favorited?: boolean;
  isDisabled?: boolean;
  isFetchingFavorite?: boolean;

  /**
   * (e.g. "Seasonal", "Warm")
   */
  label?: string;

  /**
   * Product description or ingredients list
   */
  description?: string;

  /**
   * (e.g. "May contain gluten")
   */
  restrictionNotice?: string;

  /**
   * (e.g. "Product unavailable")
   */
  unavailableNotice?: string;

  /**
   * Product remote image URL (e.g. https://res.cloudinary.com/sweetgreen/image...)
   */
  cloudinaryImageSrc?: string;

  /**
   * Optional footer element.
   */
  FooterElement?: React.ReactNode;

  /**
   * Card click/press handler
   */
  onCardPress?: () => void;

  /**
   * Share action for the product
   */
  onShare?: () => void;

  /**
   * Favorite action for the product
   */
  onFavorite?: () => void;
}> &
  ViewProps &
  Partial<IngredientsListProps>;

type IngredientsListProps = Pick<
  ComponentProps<typeof ProductIngredientsList>,
  | 'addedIngredients'
  | 'addedIngredientsLabel'
  | 'removedIngredients'
  | 'removedIngredientsLabel'
>;

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  cardWrapper: {
    position: 'relative',
    flexGrow: 1,
  },
  titleXS: {
    ...TYPE_CONFIG.BODY['18'],
  },
  pressableWrapper: {
    width: '100%',
    padding: theme.spacing['4'],
    flexGrow: 1,
  },
  pressableWrapperTabletUp: {
    padding: theme.spacing['6'],
  },
  header: {
    position: 'relative',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing['4'],
  },
  headerIcon: {
    marginLeft: 'auto',
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  labelWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  restrictionNoticeWrapper: {
    paddingTop: theme.spacing['4'],
  },
  unavailableNoticeWrapper: {
    paddingTop: theme.spacing['2'],
  },
  footer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 'auto',
    paddingTop: theme.spacing['4'],
  },
  ingredientsAndDescriptionWrapper: {
    paddingTop: theme.spacing['2'],
  },
  ingredientsListWrapper: {
    paddingBottom: theme.spacing['1'],
  },
  quantity: {
    paddingTop: theme.spacing['1'],
  },
});

// ─── HOOKS ──────────────────────────────────────────────────────────────────────

/**
 * Returns styles based on viewport or hover/focus states.
 */
const useConditionalCardStyles = () => {
  const { match } = useResponsive();

  const titleStyle = match([styles.titleXS, {}]);
  const sizeStyles = match([{}, styles.pressableWrapperTabletUp]);

  return {
    sizeStyles,
    titleStyle,
  };
};

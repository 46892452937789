import React from 'react';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';

import {
  getModalScreensNames,
  useActiveRouteName,
} from '../../../../navigation';
import type {
  AppStackParamList,
  ModalStackParamList,
} from '../../../../navigation/AppNavigation.props';
import { logger } from '../utils';

// https://sweetgreen.atlassian.net/wiki/spaces/DES/pages/4469325825/Order+-+Reorder+Frontend+Flow
export const useReorderNavigation = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<AppStackParamList>>();
  const modalNavigation =
    useNavigation<NativeStackNavigationProp<ModalStackParamList>>();
  const activeRouteName = useActiveRouteName();

  // We should navigate to the modal stack upon starting the reorder flow.
  const navigateToActiveBagWarning = React.useCallback(() => {
    logger.info('Navigate to ReorderActiveBagWarning');
    navigation.navigate('Modal', { screen: 'ReorderActiveBagWarning' });
  }, [navigation]);
  const navigateToConfirmLocation = React.useCallback(() => {
    logger.info('Navigate to ReorderConfirmLocation');
    navigation.navigate('Modal', { screen: 'ReorderConfirmLocation' });
  }, [navigation]);
  const navigateToChangeLocation = React.useCallback(() => {
    logger.info('Navigate to ReorderChangeLocation');
    navigation.navigate('Modal', { screen: 'ReorderChangeLocation' });
  }, [navigation]);
  const navigateToConflictReview = React.useCallback(() => {
    logger.info('Navigate to ReorderConflictReview');
    navigation.navigate('Modal', { screen: 'ReorderConflictReview' });
  }, [navigation]);

  // We should replace the current route in the modal stack upon changing steps.
  const replaceWithConfirmLocation = React.useCallback(() => {
    logger.info('Replace with ReorderConfirmLocation');
    modalNavigation.replace('ReorderConfirmLocation');
  }, [modalNavigation]);
  const replaceWithChangeLocation = React.useCallback(() => {
    logger.info('Replace with ReorderChangeLocation');
    modalNavigation.replace('ReorderChangeLocation');
  }, [modalNavigation]);
  const replaceWithConflictReview = React.useCallback(() => {
    logger.info('Replace with ReorderConflictReview');
    modalNavigation.replace('ReorderConflictReview');
  }, [modalNavigation]);

  const finishReorder = React.useCallback(() => {
    const previousRoutes = modalNavigation.getState().routes;
    const previousRoute = previousRoutes.at(-2);

    // When reordering from the bag, we no longer replace the bag with the reorder, so we should go back to it instead.
    if (modalNavigation.canGoBack() && previousRoute?.name === 'Bag') {
      logger.info('Going back');
      modalNavigation.goBack();

      return;
    }

    // When reordering is happening from a modal, it should replace it with the bag.
    if (getModalScreensNames().has(activeRouteName)) {
      logger.info('Replace with Bag');
      modalNavigation.replace('Bag');

      return;
    }

    // When reordering is happening elsewhere, it should open the bag.
    logger.info('Navigate to Bag');
    modalNavigation.navigate('Bag');
  }, [activeRouteName, modalNavigation]);

  // If the reorder modal is opened on top of the main tabs, dismissing should close the stack.
  // If the reorder modal is opened on top of another modal screen, dismissing should go back.
  const dismissReorder = React.useCallback(() => {
    logger.info('Dismiss Reorder');

    if (navigation.canGoBack()) navigation.goBack();
    else navigation.getParent()?.goBack();
  }, [navigation]);

  // Changing restaurants while on a menu screen should take user to a new menu,
  // Changing restaurants with a current bag should take you to a new menu,
  // We can't replace with bag in here because we need to go back to a new menu.
  const finishReorderNewMenu = React.useCallback(
    (restaurantSlug?: string, addressId?: string) => {
      logger.info('Go Back To New Menu with Bag', restaurantSlug, addressId);
      goBackToNewMenu(navigation, restaurantSlug, addressId, true);
    },
    [navigation],
  );

  // Changing restaurants while on a menu screen should take user to a new menu.
  const dismissReorderNewMenu = React.useCallback(
    (restaurantSlug?: string, addressId?: string) => {
      logger.info('Go Back To New Menu without Bag', restaurantSlug, addressId);
      goBackToNewMenu(navigation, restaurantSlug, addressId);
    },
    [navigation],
  );

  // Replace reorder modal with checkout after finishing reordering.
  const finishReorderCheckout = React.useCallback(() => {
    logger.info('Replace with Checkout');
    modalNavigation.reset({ routes: [{ name: 'Checkout' }] });
  }, [modalNavigation]);

  return {
    navigateToActiveBagWarning,
    navigateToConfirmLocation,
    navigateToChangeLocation,
    navigateToConflictReview,
    replaceWithConfirmLocation,
    replaceWithChangeLocation,
    replaceWithConflictReview,
    dismissReorder,
    finishReorder,
    finishReorderNewMenu,
    finishReorderCheckout,
    dismissReorderNewMenu,
  };
};

// Dismisses reorder modal and navigates to a new menu.
const goBackToNewMenu = (
  navigation: NativeStackNavigationProp<AppStackParamList>,
  restaurantSlug?: string,
  addressId?: string,
  withBag?: boolean,
) => {
  navigation.reset({
    routes: [
      {
        name: 'MainTabs',
        params: {
          screen: 'HomeTab',
          params: {
            screen: 'Home',
          },
        },
      },
      {
        name: 'MainTabs',
        params: {
          screen: 'MenuTab',
          params: {
            screen: addressId ? 'DeliveryMenu' : 'Menu',
            params: addressId ? { addressId } : { restaurantSlug },
          },
        },
      },
      ...(withBag
        ? [
            {
              name: 'Modal' as keyof AppStackParamList,
              params: {
                screen: 'Bag',
              },
            },
          ]
        : []),
    ],
  });
};

import React, { useCallback, useState } from 'react';

import {
  LoadingAnimation,
  SweetpassLandingPage,
  type SweetpassLandingPageCtaAction,
  useSweetpassLandingPageContent,
} from '@order/components';
import { type DurationUnit, type TierName } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';
import { useTrackEffect } from '@order/Telemetry';

import { SweetpassConfirmationDialog } from '../../components/SweetpassConfirmationDialog';
import { getSweetpassUpgradeContentfulTag } from './utils';

// ────────────────────────────────────────────────────────────────────────────────

export const SweetpassUpgradeScreenContent = (
  props: SweetpassUpgradeScreenContentProps,
) => {
  const {
    campaignId,
    sweetpassNextTierName,
    isNextTierSweetpassPlus,
    isFreeTrialAvailable,
    planPrice,
    trialDuration,
    trialDurationSingular,
    billingFrequencyUnit,
    isConfirmingOptIn,
    shouldUseGenericContent,
    onCtaConfirmation,
  } = props;

  // ─── Context ─────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();

  // ─── State ───────────────────────────────────────────────────────────

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // ─── Callbacks ───────────────────────────────────────────────────────

  const handleDismissModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  const handleCtaPress = useCallback(
    (action: SweetpassLandingPageCtaAction) => {
      if (action === 'join_sweetpass') {
        setShowConfirmationModal(true);

        return;
      }

      onCtaConfirmation(action);
    },
    [onCtaConfirmation],
  );

  // ─────────────────────────────────────────────────────────────────────

  const headerTitle = isNextTierSweetpassPlus
    ? t('sweetpass-plus')
    : t('sweetpass');

  const tag = getSweetpassUpgradeContentfulTag({
    campaignId,
    sweetpassNextTierName,
    isFreeTrialAvailable,
  });

  const sweetpassLandingPageContent = useSweetpassLandingPageContent({
    tag,
    planPrice,
    trialDuration,
    trialDurationSingular,
    billingFrequencyUnit,
    shouldUseGenericContent,
    onCtaPress: handleCtaPress,
  });

  const { content, fetching: isLoadingContent } = sweetpassLandingPageContent;

  // ─────────────────────────────────────────────────────────────────────

  const onSweetpassDialogConfirm = useCallback(() => {
    onCtaConfirmation(content?.cta?.action);
  }, [content?.cta?.action, onCtaConfirmation]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useTrackEffect('sweetpass_account.review_modal.viewed');

  // ─────────────────────────────────────────────────────────────────────

  if (isLoadingContent) {
    return <LoadingAnimation />;
  }

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <SweetpassLandingPage
        title={headerTitle}
        headerContent={content.header}
        benefitsContent={content.benefits}
        ctaContent={content.cta}
      />

      <SweetpassConfirmationDialog
        visible={showConfirmationModal}
        isLoading={isConfirmingOptIn}
        onDismiss={handleDismissModal}
        onConfirm={onSweetpassDialogConfirm}
      />
    </>
  );
};

type SweetpassUpgradeScreenContentProps = Readonly<{
  campaignId?: string;
  sweetpassNextTierName?: TierName;
  isFreeTrialAvailable: boolean;
  isNextTierSweetpassPlus: boolean;
  planPrice?: number;
  trialDuration?: string;
  trialDurationSingular?: string;
  billingFrequencyUnit?: DurationUnit;
  isConfirmingOptIn: boolean;
  shouldUseGenericContent?: boolean;
  onCtaConfirmation: (action?: string) => void;
}>;

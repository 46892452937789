import { useMemo } from 'react';
import { switchcase } from '@sg/garnish';

import { CONTENTFUL_TAGS } from '@order/constants';
import { useContentfulContentTypeEntry } from '@order/Contentful';
import { useLocalizationContext } from '@order/Localization';

/**
 * Returns contentful data from 'Sweetpass Account Page'.
 * Falls back to localized strings.
 * @see: (@link: https://app.contentful.com/spaces/wme4s8lvzccr/content_types/accountSweetpassPage)
 */
export const useSweetpassAccountContentfulData = () => {
  const { t } = useLocalizationContext();

  const { data: contentfulData, fetching: isLoadingContentfulData } =
    useContentfulContentTypeEntry<ContentfulSweetpassAccountPageFields>({
      contentType: SWEETPASS_MEMBERSHIP_CONTENTFUL_CONTENT_ID,
      include: 2,
    });

  return {
    isLoadingContentfulData,
    content: useMemo(() => {
      const remoteContent = contentfulData?.fields?.content ?? [];
      const remoteSections = remoteContent?.map(
        ({ fields }) => fields.name,
      ) as readonly string[];
      const missingSections = REQUIRED_SECTIONS.filter(
        (section: string) => !remoteSections.includes(section),
      );
      const content = [
        ...remoteContent,
        ...missingSections.map((name: string) => ({ fields: { name } })),
      ];

      return content?.map(({ fields }) => {
        const contentfulFields = fields as ContentfulSweetpassAccountPageData;

        return switchcase({
          [ACCOUNT_SWEETPASS_MEMBERSHIP_SECTION]:
            fallbackMembershipContentfulValues(t, contentfulFields),
          [ACCOUNT_SWEETPASS_CANCELLATION_SECTION]:
            fallbackCancellationContentfulValues(t, contentfulFields),
          [ACCOUNT_SWEETPASS_PAYMENT_METHODS_SECTION]:
            fallbackPaymentMethodsContentfulValues(t, contentfulFields),
          [ACCOUNT_SWEETPASS_BILLING_HISTORY_SECTION]:
            fallbackBillingHistoryContentfulValues(t, contentfulFields),
        })({} as never)(fields?.name ?? '');
      });
    }, [contentfulData?.fields?.content, t]),
  };
};

// ─── Utils ────────────────────────────────────────────────────────

const fallbackMembershipContentfulValues = (
  t: ReturnType<typeof useLocalizationContext>['t'],
  data: ContentfulSweetpassAccountPageData,
) => {
  const variedBody = data?.body as VariedFieldArray;

  const sweetpassBody = variedBody?.find?.((entry) => {
    const tagIds = entry.metadata?.tags?.map((tag) => tag?.sys?.id);

    return (
      tagIds?.includes(CONTENTFUL_TAGS.Tier.Sweetpass) &&
      !tagIds?.includes(CONTENTFUL_TAGS.Status.Downgraded)
    );
  })?.fields;

  const sweetpassDowngradedBody = variedBody?.find?.((entry) => {
    const tagIds = entry.metadata?.tags?.map((tag) => tag?.sys?.id);

    return (
      tagIds?.includes(CONTENTFUL_TAGS.Tier.Sweetpass) &&
      tagIds?.includes(CONTENTFUL_TAGS.Status.Downgraded)
    );
  })?.fields;

  const sweetpassPlusBody = variedBody?.find?.((entry) => {
    const tagIds = entry.metadata?.tags?.map((tag) => tag?.sys?.id);

    return (
      tagIds?.includes(CONTENTFUL_TAGS.Tier.SweetpassPlus) &&
      !tagIds?.includes(CONTENTFUL_TAGS.Status.Cancelled)
    );
  })?.fields;

  const sweetpassPlusFreeTrialBody = variedBody?.find?.((entry) => {
    const tagIds = entry.metadata?.tags?.map((tag) => tag?.sys?.id);

    return (
      tagIds?.includes(CONTENTFUL_TAGS.Tier.SweetpassPlusFreeTrial) &&
      !tagIds?.includes(CONTENTFUL_TAGS.Status.Cancelled)
    );
  })?.fields;

  const sweetpassPlusCancelledBody = variedBody?.find?.((entry) => {
    const tagIds = entry.metadata?.tags?.map((tag) => tag?.sys?.id);

    return (
      tagIds?.includes(CONTENTFUL_TAGS.Tier.SweetpassPlus) &&
      tagIds?.includes(CONTENTFUL_TAGS.Status.Cancelled)
    );
  })?.fields;

  return {
    name: ACCOUNT_SWEETPASS_MEMBERSHIP_SECTION,
    membershipDataTitle: data?.title ?? t('membershipData.title'),
    membershipDataHeader: data?.header ?? t('membershipData.header'),

    membershipDataBodySweetpass:
      sweetpassBody?.value ?? t('membershipData.body.sweetpass'),
    membershipDataCtaSweetpass:
      sweetpassBody?.ctaV3 ??
      t('account.sweetpass-membership.membership-level.sweetpass.cta.title'),
    membershipDataCtaSweetpassFreeTrial:
      sweetpassBody?.ctaFreeTrial ??
      t(
        'account.sweetpass-membership.membership-level.sweetpass.cta-free-trial.title',
      ),
    membershipDataCtaSwitchSubscriptionToMonthlyTitle:
      sweetpassPlusBody?.ctaSwitchSubscriptionToMonthly ??
      t(
        'account.sweetpass-membership.membership-level.sweetpass.cta-switch-subscription-monthly.title',
      ),

    membershipDataPendingSubscription:
      sweetpassPlusBody?.valuePendingSubscription,
    membershipDataCtaSwitchSubscriptionToAnnualTitle:
      sweetpassPlusBody?.ctaSwitchSubscriptionToAnnual ??
      t(
        'account.sweetpass-membership.membership-level.sweetpass.cta-switch-subscription-annual.title',
      ),
    membershipDataCtaSwitchSubscriptionBackTitle:
      sweetpassPlusBody?.ctaSwitchBackSubscriptionV2 ??
      t(
        'account.sweetpass-membership.membership-level.sweetpass.cta-switch-back-subscription.title',
      ),
    membershipDataCtaSwitchSubscriptionA11y: t(
      'account.sweetpass-membership.membership-level.sweetpass.cta-switch-subscription.a11y',
    ),

    membershipDataBodySweetpassDowngraded: sweetpassDowngradedBody?.value ?? '',
    membershipDataCtaSweetpassDowngraded:
      sweetpassDowngradedBody?.ctaV3 ??
      t(
        'account.sweetpass-membership.membership-level.sweetpass-downgraded.cta.title',
      ),

    membershipDataBodySweetpassPlus:
      sweetpassPlusBody?.value ?? t('membershipData.body.sweetpassPlus'),
    membershipDataBodySweetpassPlusSwitchSubscription:
      sweetpassPlusBody?.valueSwitchSubscription ??
      t('membershipData.body.sweetpassPlus.changeSubscription'),
    membershipDataCtaSweetpassPlus: sweetpassPlusBody?.ctaV3,

    membershipDataBodySweetpassPlusFreeTrial:
      sweetpassPlusFreeTrialBody?.valueFreeTrial ??
      t('membershipData.body.sweetpassPlusFreeTrial'),

    membershipDataBodySweetpassPlusCancelled:
      sweetpassPlusCancelledBody?.value ??
      t('membershipData.body.sweetpassPlusCancelled'),
    membershipDataCtaSweetpassPlusCancelled:
      sweetpassPlusCancelledBody?.ctaV3 ??
      t(
        'account.sweetpass-membership.membership-level.sweetpass-plus-canceled.cta.title',
      ),
  };
};

const fallbackCancellationContentfulValues = (
  t: ReturnType<typeof useLocalizationContext>['t'],
  data: ContentfulSweetpassAccountPageData,
) => {
  return {
    name: ACCOUNT_SWEETPASS_CANCELLATION_SECTION,
    cancellationDataTitle: data?.title ?? t('cancellationData.title'),
    cancellationDataBody: data?.bodyV2 ?? t('cancellationData.body'),
    cancellationDataCancellationCta:
      data?.cancellationCtaV2 ?? t('cancellationData.cancellationCta'),
    cancellationDataGenericSuccessMessage:
      data?.genericSuccessMessageV2 ??
      t('cancellationData.genericSuccessMessage'),
    cancellationDataGenericErrorMessage:
      data?.genericErrorMessageV2 ??
      t('sweetpass-membership.cancellation.error'),
  };
};

const fallbackPaymentMethodsContentfulValues = (
  t: ReturnType<typeof useLocalizationContext>['t'],
  data: ContentfulSweetpassAccountPageData,
) => {
  return {
    name: ACCOUNT_SWEETPASS_PAYMENT_METHODS_SECTION,
    paymentMethodsDataTitle: data?.title ?? t('paymentMethodsData.title'),
  };
};

const fallbackBillingHistoryContentfulValues = (
  t: ReturnType<typeof useLocalizationContext>['t'],
  data: ContentfulSweetpassAccountPageData,
) => {
  return {
    name: ACCOUNT_SWEETPASS_BILLING_HISTORY_SECTION,
    billingHistoryDataTitle: data?.title ?? t('billingHistoryData.title'),
  };
};

// ─── Constants ────────────────────────────────────────────────────

const SWEETPASS_MEMBERSHIP_CONTENTFUL_CONTENT_ID = 'accountSweetpassPage';

export const ACCOUNT_SWEETPASS_MEMBERSHIP_SECTION =
  'Account Sweetpass Membership Section';
export const ACCOUNT_SWEETPASS_CANCELLATION_SECTION =
  'Account Sweetpass Cancellation Section';
export const ACCOUNT_SWEETPASS_PAYMENT_METHODS_SECTION =
  'Account Sweetpass Payment Methods Section';
export const ACCOUNT_SWEETPASS_BILLING_HISTORY_SECTION =
  'Account Sweetpass Billing History Section';

const REQUIRED_SECTIONS = [
  ACCOUNT_SWEETPASS_MEMBERSHIP_SECTION,
  ACCOUNT_SWEETPASS_CANCELLATION_SECTION,
  ACCOUNT_SWEETPASS_PAYMENT_METHODS_SECTION,
  ACCOUNT_SWEETPASS_BILLING_HISTORY_SECTION,
];

// ─── Types ────────────────────────────────────────────────────────

type ContentfulSweetpassAccountPageFields = Readonly<{
  content: ReadonlyArray<
    | Readonly<{
        fields: ContentfulSweetpassMembershipSectionFields;
      }>
    | Readonly<{
        fields: ContentfulSweetpassCancellationSectionFields;
      }>
    | Readonly<{
        fields: ContentfulSweetpassPaymentMethodsSectionFields;
      }>
    | Readonly<{
        fields: ContentfulSweetpassBillingHistorySectionFields;
      }>
  >;
}>;

type ContentfulSweetpassAccountPageData = Omit<
  ContentfulSweetpassMembershipSectionFields,
  'name'
> &
  Omit<ContentfulSweetpassCancellationSectionFields, 'name'> &
  Omit<ContentfulSweetpassPaymentMethodsSectionFields, 'name'> &
  Omit<ContentfulSweetpassBillingHistorySectionFields, 'name'>;

type ContentfulSweetpassMembershipSectionFields = Readonly<{
  name?: typeof ACCOUNT_SWEETPASS_MEMBERSHIP_SECTION;
  title?: string;
  header?: string;
  body?: VariedFieldArray | string;
}>;

type ContentfulSweetpassCancellationSectionFields = Readonly<{
  name?: typeof ACCOUNT_SWEETPASS_CANCELLATION_SECTION;
  title?: string;

  /**
   * @deprecated use `bodyV2`
   */
  body?: string;
  bodyV2: string;

  /**
   * @deprecated use `cancellationCtaV2`
   */
  cancellationCta?: string;
  cancellationCtaV2: string;

  /**
   * @deprecated use `genericSuccessMessageV2`
   */
  genericSuccessMessage?: string;
  genericSuccessMessageV2: string;

  /**
   * @deprecated use `genericErrorMessageV2`
   */
  genericErrorMessage?: string;
  genericErrorMessageV2: string;
}>;

type ContentfulSweetpassPaymentMethodsSectionFields = Readonly<{
  name?: typeof ACCOUNT_SWEETPASS_PAYMENT_METHODS_SECTION;
  title?: string;
}>;

type ContentfulSweetpassBillingHistorySectionFields = Readonly<{
  name?: typeof ACCOUNT_SWEETPASS_BILLING_HISTORY_SECTION;
  title?: string;
}>;

type VariedFieldArray = ReadonlyArray<
  Readonly<{
    fields: {
      name?: string;
      value?: string;
      valueFreeTrial?: string;
      valueSwitchSubscription?: string;
      ctaV3?: string;
      ctaFreeTrial?: string;
      ctaSwitchSubscriptionToMonthly?: string;
      ctaSwitchSubscriptionToAnnual?: string;
      ctaSwitchBackSubscriptionV2?: string;
      valuePendingSubscription?: string;
    };
    metadata: { tags: readonly ContentfulTag[] };
  }>
>;

type ContentfulTag = Readonly<{ sys?: { id?: string } }>;

export type AccountSweetpassMembershipSection = ReturnType<
  typeof fallbackMembershipContentfulValues
>;
export type AccountSweetpassCancellationSection = ReturnType<
  typeof fallbackCancellationContentfulValues
>;
export type AccountSweetpassPaymentMethodsSection = ReturnType<
  typeof fallbackPaymentMethodsContentfulValues
>;
export type AccountSweetpassBillingHistorySection = ReturnType<
  typeof fallbackBillingHistoryContentfulValues
>;

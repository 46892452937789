import { type ComponentProps, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';

import { type OrderLineItem } from '@order/features/orders';

import type { ModalStackParamList } from '../../../navigation/AppNavigation.props';

// ─────────────────────────────────────────────────────────────────────────────

export const useNavigateToReorderProductDetails = (
  params?: useNavigateToReorderProductDetailsParams,
) => {
  const { shouldUseModalNavigation, shouldReplaceScreen = false } =
    params ?? {};

  const navigation = useNavigation();
  const modalNavigation =
    useNavigation<NativeStackNavigationProp<ModalStackParamList>>();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToProductDetailsScreen = useCallback<
    OrderLineItemProps['onProductDetailsLinkPress']
  >(
    (details) => {
      const { lineItemId, addressId, restaurantSlug, customName, product } =
        details;
      const productSlug = product?.slug ?? '';

      if (addressId) {
        navigation.navigate('Modal', {
          screen: 'DeliveryProductDetails',
          params: {
            reorderLineItemId: lineItemId,
            addressId,
            productSlug,
            ...(customName ? { name: customName } : {}),
          },
        });

        return;
      }

      navigation.navigate('Modal', {
        screen: 'ProductDetails',
        params: {
          reorderLineItemId: lineItemId,
          restaurantSlug,
          productSlug,
          ...(customName ? { name: customName } : {}),
        },
      });
    },
    [navigation],
  );

  const navigateToProductDetailsModal = useCallback<
    OrderLineItemProps['onProductDetailsLinkPress']
  >(
    (details) => {
      const { navigate, replace } = modalNavigation;

      // NOTE: Reorder can be presented in a small modal on large breakpoints and a
      // fullscreen one on the smallest breakpoint which introduces some issues
      // We use different methods of navigation per breakpoint to avoid unexpected
      // issues.
      const navigationMethod = shouldReplaceScreen ? replace : navigate;

      const { lineItemId, addressId, restaurantSlug, customName, product } =
        details;
      const productSlug = product?.slug ?? '';

      if (addressId) {
        navigationMethod('DeliveryProductDetails', {
          reorderLineItemId: lineItemId,
          addressId,
          productSlug,
          ...(customName ? { name: customName } : {}),
        });

        return;
      }

      navigationMethod('ProductDetails', {
        reorderLineItemId: lineItemId,
        restaurantSlug,
        productSlug,
        ...(customName ? { name: customName } : {}),
      });
    },
    [modalNavigation, shouldReplaceScreen],
  );

  // ─────────────────────────────────────────────────────────────────────

  return useCallback<OrderLineItemProps['onProductDetailsLinkPress']>(
    (details) => {
      if (shouldUseModalNavigation) {
        navigateToProductDetailsModal(details);

        return;
      }

      navigateToProductDetailsScreen(details);
    },
    [
      navigateToProductDetailsModal,
      navigateToProductDetailsScreen,
      shouldUseModalNavigation,
    ],
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type useNavigateToReorderProductDetailsParams = {
  shouldUseModalNavigation: boolean;
  shouldReplaceScreen?: boolean;
};

type OrderLineItemProps = ComponentProps<typeof OrderLineItem>;

import React, { useCallback, useLayoutEffect } from 'react';
import {
  type RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { theme } from '@garnish/constants';

import { useIsLoggedIn } from '@order/AuthMachine';
import {
  LoadingAnimation,
  NotFoundView,
  RefreshErrorState,
  SweetpassSignedOut,
} from '@order/components';
import { TierName } from '@order/graphql';
import { useSweetpassTier } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';

import type {
  AppStackParamList,
  ModalStackParamList,
} from '../../navigation/AppNavigation.props';
import { useSweetpassOptIn } from './hooks';
import { SweetpassUpgradeGeneric, SweetpassUpgradeToPlan } from './variations';

// ────────────────────────────────────────────────────────────────────────────────

export const SweetpassUpgradeScreen = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<AppStackParamList>>();
  const { params } =
    useRoute<RouteProp<ModalStackParamList, 'SweetpassUpgrade'>>();

  // ─── Params ──────────────────────────────────────────────────────────

  const {
    redirectToAfterUpgrade,
    redirectToOrderId,
    planId: planIdFromParams,
    campaignId,
  } = params ?? {};

  // ─── Feature FLags ───────────────────────────────────────────────────

  const isAnnualSubscriptionEnabled = useFeatureFlag(
    'CELS-1260-enable-annual-subscriptions',
  );

  const isSweetpassPlusDisabled = useFeatureFlag(
    'CELS-2877-disable-sweetpass-upgrade',
  );

  // ─── Queries ─────────────────────────────────────────────────────────

  const {
    isSweetpassTierLoading,
    sweetpassNextTierId,
    isEligibleForFreeTrial,
    hasUnknownTierError,
    sweetpassNextTierName,
    trialDuration,
    trialDurationSingular,
  } = useSweetpassTier();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const { isConfirmingOptIn, optInSweetpass } = useSweetpassOptIn({
    planId: planIdFromParams,
    campaignId,
    redirectToAfterUpgrade,
    redirectToOrderId,
  });

  const redirectToLoginScreen = useCallback(() => {
    navigation.replace('Auth', {
      screen: 'Login',
      params: {
        redirect: 'sweetpassUpgrade',
        redirectToPlanId: planIdFromParams,
        redirectToCampaignId: campaignId,
      },
    });
  }, [navigation, planIdFromParams, campaignId]);

  const redirectToJoinScreen = useCallback(() => {
    navigation.replace('Auth', {
      screen: 'Join',
      params: {
        redirect: 'sweetpassUpgrade',
        redirectToPlanId: planIdFromParams,
        redirectToCampaignId: campaignId,
      },
    });
  }, [navigation, planIdFromParams, campaignId]);

  // ─── Flags ───────────────────────────────────────────────────────────

  const isLoggedIn = useIsLoggedIn();
  const isNextTierSweetpassPlus =
    sweetpassNextTierName === TierName.SweetpassPlus;
  const isLoading = isSweetpassTierLoading;

  const shouldRenderSweetpassPlusUpgradeGenericView =
    isAnnualSubscriptionEnabled && isNextTierSweetpassPlus && !planIdFromParams;

  // ─── Effects ─────────────────────────────────────────────────────────

  // Reset header background color
  useLayoutEffect(() => {
    navigation.setOptions({
      headerStyle: {
        backgroundColor: theme.colors.APP_BACKGROUND,
      },
    });
  }, [navigation]);

  // ─────────────────────────────────────────────────────────────────────

  if (isSweetpassPlusDisabled && isNextTierSweetpassPlus) {
    return <NotFoundView />;
  }

  if (!isLoggedIn) {
    return (
      <SweetpassSignedOut
        testID="sweetpass-signed-out"
        onPressSignIn={redirectToLoginScreen}
        onPressJoinNow={redirectToJoinScreen}
      />
    );
  }

  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (hasUnknownTierError) {
    return <RefreshErrorState />;
  }

  if (!sweetpassNextTierId) {
    return <NotFoundView />;
  }

  if (shouldRenderSweetpassPlusUpgradeGenericView) {
    return (
      <SweetpassUpgradeGeneric
        campaignId={campaignId}
        isCustomerEligibleForFreeTrial={isEligibleForFreeTrial}
        nextSweetpassTierName={sweetpassNextTierName}
        isConfirmingOptIn={isConfirmingOptIn}
        optInSweetpass={optInSweetpass}
      />
    );
  }

  // ─────────────────────────────────────────────────────────────────────

  return (
    <SweetpassUpgradeToPlan
      targetPlanId={planIdFromParams}
      campaignId={campaignId}
      nextSweetpassTierName={sweetpassNextTierName}
      nextSweetpassTierId={sweetpassNextTierId}
      optInSweetpass={optInSweetpass}
      isConfirmingOptIn={isConfirmingOptIn}
      isCustomerEligibleForFreeTrial={isEligibleForFreeTrial}
      trialDuration={trialDuration}
      trialDurationSingular={trialDurationSingular}
    />
  );
};

import React from 'react';

import { BodyText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductCardV2Heading = (props: ProductCardV2HeadingProps) => {
  const { children } = props;

  return (
    <BodyText bold sizeMatch={['24']}>
      {children}
    </BodyText>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ProductCardV2HeadingProps = {
  children: string;
};

import { TierName, TierStatusName } from '@order/graphql';
import {
  DateDurationUnit,
  type useLocalizationContext,
} from '@order/Localization';
import { formatDateFromTo, formatDateWithoutTimezone } from '@order/utils';

import type { AccountSweetpassMembershipSection } from '../../useSweetpassAccountContentfulData';
import type {
  AsyncCta,
  GetSectionProps,
  SweetpassSectionProps,
} from '../types';

// ─────────────────────────────────────────────────────────────────────────────

export const getMembershipLevelSection = (
  props: GetMembershipLevelSectionProps,
): SweetpassMembershipLevelSectionProps | undefined => {
  const {
    tier,
    tierStatus,
    hasPaidTierHistory,
    joinDate,
    activeSubscription,
    hasPendingSubscription,
    isSweetpassPlusDisabled,
  } = props;

  const { formattedDate: formattedJoinDate } = formatDateWithoutTimezone(
    joinDate ?? '',
  );

  const contentProps = {
    ...props,
    isSweetpassPlusDisabled,
    joinDate: formattedJoinDate ?? '',
  };

  const hasActiveSubscription = Boolean(activeSubscription);
  const isSweetpass =
    (tier?.name === TierName.SweetpassPlus && !hasActiveSubscription) ||
    tier?.name === TierName.Sweetpass;
  const isSweetpassPlus =
    hasActiveSubscription && tier?.name === TierName.SweetpassPlus;
  const isSweetpassPlusWithFreeTrial =
    isSweetpassPlus && tierStatus === TierStatusName.FreeTrial;

  const isDowngradedSweetpass = isSweetpass && hasPaidTierHistory;
  const isCancelledSweetpassPlus =
    isSweetpassPlus && tierStatus === TierStatusName.CancellationPending;

  const hasPendingOnlySweetpassPlusSubscription =
    !activeSubscription &&
    tier?.name === TierName.SweetpassPlus &&
    hasPendingSubscription;

  if (isCancelledSweetpassPlus) {
    return getMembershipLevelSectionContentForSweetpassPlusCanceled(
      contentProps,
    );
  }

  if (isSweetpassPlusWithFreeTrial) {
    return getMembershipLevelSectionContentForSweetpassPlusTrial(contentProps);
  }

  if (isSweetpassPlus) {
    return getMembershipLevelSectionContentForSweetpassPlusActive(contentProps);
  }

  if (hasPendingOnlySweetpassPlusSubscription) {
    return getMembershipLevelSectionContentForPendingOnlySweetpassPlus(
      contentProps,
    );
  }

  if (isDowngradedSweetpass) {
    return getMembershipLevelSectionContentForSweetpassDowngraded(contentProps);
  }

  if (isSweetpass) {
    return getMembershipLevelSectionContentForSweetpassInitial(contentProps);
  }
};

// ─── Tier: Sweetpass (Initial) ───────────────────────────────────────────────

const getMembershipLevelSectionContentForSweetpassInitial = (
  props: GetMembershipLevelSectionContentProps,
): SweetpassMembershipLevelSectionProps | undefined => {
  const {
    name,
    membershipDataTitle,
    membershipDataHeader,
    membershipDataBodySweetpass,
    membershipDataCtaSweetpass,
    membershipDataCtaSweetpassFreeTrial,
    planPrice,
    billingFrequencyUnit,
    joinDate,
    isEligibleToFreeTrial,
    freeTrialPlanId = '',
    isSweetpassPlusDisabled,
    subscribeHandler,
  } = props;

  const cta = isEligibleToFreeTrial
    ? membershipDataCtaSweetpassFreeTrial
    : membershipDataCtaSweetpass;

  const handler = async () => {
    const planId: string | undefined = isEligibleToFreeTrial
      ? freeTrialPlanId
      : undefined;

    return subscribeHandler(planId);
  };

  return {
    name,
    title: membershipDataTitle,
    headingText: membershipDataHeader.replaceAll(
      '{membershipLevel}',
      MEMBERSHIP_SWEETPASS,
    ),
    bodyText: joinDate
      ? membershipDataBodySweetpass.replaceAll('{joinDate}', joinDate)
      : '',
    cta: isSweetpassPlusDisabled
      ? undefined
      : {
          palette: 'limeOnGreen',
          title: cta
            .replaceAll('{planPrice}', `${planPrice}`)
            .replaceAll(
              '{billingFrequencyUnit}',
              billingFrequencyUnit?.toLowerCase(),
            ),
          accessibilityLabel: cta,
          handler,
        },
  };
};

// ─── Tier: Sweetpass (Downgraded) ────────────────────────────────────────────

const getMembershipLevelSectionContentForSweetpassDowngraded = (
  props: GetMembershipLevelSectionContentProps,
): SweetpassMembershipLevelSectionProps | undefined => {
  const {
    name,
    membershipDataTitle,
    membershipDataHeader,
    membershipDataCtaSweetpassFreeTrial,
    membershipDataCtaSweetpassDowngraded,
    planPrice,
    billingFrequencyUnit,
    isEligibleToFreeTrial,
    freeTrialPlanId = '',
    isSweetpassPlusDisabled,
    subscribeHandler,
  } = props;

  const cta = isEligibleToFreeTrial
    ? membershipDataCtaSweetpassFreeTrial
    : membershipDataCtaSweetpassDowngraded;

  const handler = async () => {
    const planId: string | undefined = isEligibleToFreeTrial
      ? freeTrialPlanId
      : undefined;

    return subscribeHandler(planId);
  };

  return {
    name,
    title: membershipDataTitle,
    headingText: membershipDataHeader.replaceAll(
      '{membershipLevel}',
      MEMBERSHIP_SWEETPASS,
    ),
    // if there is ever a requirement for a sweetpass downgraded body text, use this.
    // bodyText: membershipDataBodySweetpassDowngraded,
    cta: isSweetpassPlusDisabled
      ? undefined
      : {
          palette: isEligibleToFreeTrial ? 'limeOnGreen' : 'secondary',
          title: cta
            .replaceAll('{planPrice}', `${planPrice}`)
            .replaceAll(
              '{billingFrequencyUnit}',
              billingFrequencyUnit?.toLowerCase(),
            ),
          accessibilityLabel: cta,
          handler,
        },
  };
};

// ─── Tier: Sweetpass+ (Active) ───────────────────────────────────────────────

const getMembershipLevelSectionContentForSweetpassPlusActive = (
  props: GetMembershipLevelSectionContentProps,
): SweetpassMembershipLevelSectionProps | undefined => {
  const {
    name,
    membershipDataTitle: title,
    membershipDataHeader,
    membershipDataBodySweetpassPlus,
    membershipDataBodySweetpassPlusSwitchSubscription,
    membershipDataCtaSwitchSubscriptionToMonthlyTitle,
    membershipDataCtaSwitchSubscriptionToAnnualTitle,
    membershipDataCtaSwitchSubscriptionBackTitle,
    membershipDataCtaSwitchSubscriptionA11y,
    savingAmount = '',
    changePlanForwardsLabel,
    changePlanBackwardsLabel,
    activeSubscription,
    switchSubscriptionPrice = '',
    isSubscriptionAnnual,
    hasPendingSubscription,
    canSwitchSubscription,
    switchSubscriptionHandler,
    formatMessage,
    formatDurationUnitName,
  } = props;

  if (!activeSubscription) {
    return undefined;
  }

  const headingText = membershipDataHeader.replaceAll(
    '{membershipLevel}',
    MEMBERSHIP_SWEETPASS_PLUS,
  );

  const { nextBillingDate = '', nextBillingPeriodAmount = '' } =
    activeSubscription;

  const { formattedDate: formattedNextBillingDate } =
    formatDateWithoutTimezone(nextBillingDate);

  if (!formattedNextBillingDate) {
    return undefined;
  }

  const switchSubscriptionTitle = isSubscriptionAnnual
    ? membershipDataCtaSwitchSubscriptionToMonthlyTitle
    : membershipDataCtaSwitchSubscriptionToAnnualTitle;

  const changeCtaTitle = switchSubscriptionTitle.replaceAll(
    '{savingAmount}',
    savingAmount,
  );

  const changeCtaColor = isSubscriptionAnnual
    ? ('secondary' as const)
    : ('primaryLime' as const);

  const changeBackCtaTitle =
    membershipDataCtaSwitchSubscriptionBackTitle.replaceAll(
      '{billingRecurrence}',
      changePlanBackwardsLabel,
    );

  const changeBackCtaColor = 'secondary';

  const planInfoData = isSubscriptionAnnual
    ? formatMessage('account.sweetpass-membership.membership-level.annual-plan')
    : formatMessage(
        'account.sweetpass-membership.membership-level.monthly-plan',
      );

  const planInfo = canSwitchSubscription ? planInfoData : undefined;

  const nextBillingPeriodRecurrence = formatDurationUnitName(
    (isSubscriptionAnnual && !hasPendingSubscription) ||
      (!isSubscriptionAnnual && hasPendingSubscription)
      ? DateDurationUnit.Year
      : DateDurationUnit.Month,
  );

  const bodyText =
    canSwitchSubscription && hasPendingSubscription
      ? membershipDataBodySweetpassPlusSwitchSubscription
          .replaceAll('{changeTo}', changePlanForwardsLabel)
          .replaceAll('{nextBillingDate}', formattedNextBillingDate)
          .replaceAll('{nextBillingPeriodAmount}', switchSubscriptionPrice)
          .replaceAll(
            '{nextBillingPeriodRecurrence}',
            nextBillingPeriodRecurrence,
          )
      : membershipDataBodySweetpassPlus
          .replaceAll('{nextBillingDate}', formattedNextBillingDate)
          .replaceAll('{nextBillingPeriodAmount}', nextBillingPeriodAmount)
          .replaceAll(
            '{nextBillingPeriodRecurrence}',
            nextBillingPeriodRecurrence,
          );

  const cta = canSwitchSubscription
    ? {
        palette: hasPendingSubscription ? changeBackCtaColor : changeCtaColor,
        title: hasPendingSubscription ? changeBackCtaTitle : changeCtaTitle,
        accessibilityLabel: membershipDataCtaSwitchSubscriptionA11y,
        handler: switchSubscriptionHandler,
      }
    : undefined;

  return {
    name,
    title,
    headingText,
    planInfo,
    bodyText,
    cta,
  };
};

// ─── Tier: Sweetpass+ (Pending Only) ────────────────────────────────────────

const getMembershipLevelSectionContentForPendingOnlySweetpassPlus = (
  contentProps: GetMembershipLevelSectionContentProps,
) => {
  const {
    name,
    canSwitchSubscription,
    membershipDataTitle,
    membershipDataHeader,
    pendingSubscription,
    membershipDataPendingSubscription,
    billingFrequencyUnit,
    isSubscriptionAnnual,
    formatMessage,
  } = contentProps;

  if (!pendingSubscription) {
    return undefined;
  }

  const { nextBillingDate = '', nextBillingPeriodAmount = '' } =
    pendingSubscription;

  const { formattedDate: formattedNextBillingDate } =
    formatDateWithoutTimezone(nextBillingDate);

  if (!formattedNextBillingDate) {
    return undefined;
  }

  const headingText = membershipDataHeader.replaceAll(
    '{membershipLevel}',
    MEMBERSHIP_SWEETPASS_PLUS,
  );

  const planInfoData = isSubscriptionAnnual
    ? formatMessage('account.sweetpass-membership.membership-level.annual-plan')
    : formatMessage(
        'account.sweetpass-membership.membership-level.monthly-plan',
      );

  const planInfo = canSwitchSubscription ? planInfoData : undefined;

  const content: SweetpassMembershipLevelSectionProps = {
    name,
    title: membershipDataTitle,
    headingText,
    planInfo,
    bodyText: membershipDataPendingSubscription
      ?.replaceAll('{nextBillingDate}', formattedNextBillingDate)
      .replaceAll('{nextBillingPeriodAmount}', `${nextBillingPeriodAmount}`)
      .replaceAll(
        '{nextBillingPeriodRecurrence}',
        billingFrequencyUnit?.toLowerCase(),
      ),
  };

  return content;
};

// ─── Tier: Sweetpass+ (Canceled) ─────────────────────────────────────────────

const getMembershipLevelSectionContentForSweetpassPlusCanceled = (
  props: GetMembershipLevelSectionContentProps,
): SweetpassMembershipLevelSectionProps | undefined => {
  const {
    name,
    membershipDataTitle,
    membershipDataHeader,
    membershipDataBodySweetpassPlusCancelled,
    membershipDataCtaSweetpassPlusCancelled,
    activeSubscription,
    isSweetpassPlusDisabled,
    reactivateSubscriptionHandler,
  } = props;

  if (!activeSubscription) {
    return undefined;
  }

  const { paidThroughDate } = activeSubscription;

  const formattedPaidThroughDate = formatDateFromTo(
    paidThroughDate ?? '',
    'yyyy-MM-dd',
    'MM/dd/yyyy',
  );

  return {
    name,
    title: membershipDataTitle,
    headingText: membershipDataHeader.replaceAll(
      '{membershipLevel}',
      MEMBERSHIP_SWEETPASS,
    ),
    bodyText: formattedPaidThroughDate
      ? membershipDataBodySweetpassPlusCancelled.replaceAll(
          '{paidThroughDate}',
          formattedPaidThroughDate,
        )
      : undefined,
    cta: isSweetpassPlusDisabled
      ? undefined
      : {
          palette: 'secondary',
          title: membershipDataCtaSweetpassPlusCancelled,
          accessibilityLabel: membershipDataCtaSweetpassPlusCancelled,
          handler: reactivateSubscriptionHandler,
        },
  };
};

// ─── Tier: Sweetpass+ (Free Trial) ───────────────────────────────────────────

export const getMembershipLevelSectionContentForSweetpassPlusTrial = (
  params: GetMembershipLevelSectionContentForSweetpassPlusTrialProps,
): SweetpassMembershipLevelSectionProps | undefined => {
  const {
    name,
    membershipDataTitle: title,
    membershipDataHeader,
    membershipDataBodySweetpassPlusFreeTrial,
    membershipDataCtaSwitchSubscriptionToMonthlyTitle,
    membershipDataCtaSwitchSubscriptionToAnnualTitle,
    membershipDataCtaSwitchSubscriptionA11y,
    savingAmount = '',
    activeSubscription,
    canSwitchSubscription,
    isSubscriptionAnnual,
    formatMessage,
    formatDurationUnitName,
    formatDistanceBetweenDatesInDays,
    switchSubscriptionHandler,
  } = params;

  if (!activeSubscription) {
    return;
  }

  // ─────────────────────────────────────────────────────────────────────

  const headingText = membershipDataHeader.replaceAll(
    '{membershipLevel}',
    formatMessage(
      'account.sweetpass-membership.membership-level.sweetpass-plus-free-trial.name',
    ),
  );

  const planInfo = isSubscriptionAnnual
    ? formatMessage('account.sweetpass-membership.membership-level.annual-plan')
    : formatMessage(
        'account.sweetpass-membership.membership-level.monthly-plan',
      );

  // ─────────────────────────────────────────────────────────────────────

  const { price, firstBillingDate } = activeSubscription;

  const { dateWithoutTimezone, formattedDate: formattedFirstBillingDate } =
    formatDateWithoutTimezone(firstBillingDate ?? '');

  if (!price || !formattedFirstBillingDate) {
    return undefined;
  }

  const billingFrequencyUnit = formatDurationUnitName(
    isSubscriptionAnnual ? DateDurationUnit.Year : DateDurationUnit.Month,
  );

  const currentDate = new Date();

  const switchSubscriptionTitle = isSubscriptionAnnual
    ? membershipDataCtaSwitchSubscriptionToMonthlyTitle
    : membershipDataCtaSwitchSubscriptionToAnnualTitle;

  const cta = canSwitchSubscription
    ? {
        palette: isSubscriptionAnnual
          ? ('secondary' as const)
          : ('primaryLime' as const),
        title: switchSubscriptionTitle.replaceAll(
          '{savingAmount}',
          savingAmount,
        ),
        accessibilityLabel: membershipDataCtaSwitchSubscriptionA11y,
        handler: switchSubscriptionHandler,
      }
    : undefined;

  const remainingTime = dateWithoutTimezone
    ? formatDistanceBetweenDatesInDays({
        baseDate: currentDate,
        targetDate: dateWithoutTimezone,
        withoutPrefix: true,
        useMidnight: true,
        minDays: 1,
      })
    : '';

  const bodyText = membershipDataBodySweetpassPlusFreeTrial
    .replaceAll('{timeRemaining}', remainingTime)
    .replaceAll('{price}', price)
    .replaceAll('{billingFrequencyUnit}', billingFrequencyUnit)
    .replaceAll('{nextBillingDate}', formattedFirstBillingDate);

  return {
    name,
    title,
    planInfo,
    headingText,
    bodyText,
    cta,
  };
};

// ─── Constants ───────────────────────────────────────────────────────────────

const MEMBERSHIP_SWEETPASS = 'Sweetpass';
const MEMBERSHIP_SWEETPASS_PLUS = 'Sweetpass+';

// ─── Types ───────────────────────────────────────────────────────────────────

type SweetpassMembershipLevelCta = AsyncCta;

type GetMembershipLevelSectionProps = Readonly<{
  canSwitchSubscription: boolean;
  isSweetpassPlusDisabled: boolean;
  autoRenewInfo?: string;
  subscribeHandler: (planId?: string) => Promise<void>;
  reactivateSubscriptionHandler: () => Promise<void>;
  switchSubscriptionHandler: () => Promise<void>;
  formatDurationUnitName: ReturnType<
    typeof useLocalizationContext
  >['formatDurationUnitName'];
  formatDistanceBetweenDatesInDays: ReturnType<
    typeof useLocalizationContext
  >['formatDistanceBetweenDatesInDays'];
}> &
  AccountSweetpassMembershipSection &
  GetSectionProps;

type GetMembershipLevelSectionContentProps = GetMembershipLevelSectionProps &
  AccountSweetpassMembershipSection &
  Readonly<{
    joinDate: string | null;
  }>;

type GetMembershipLevelSectionContentForSweetpassPlusTrialProps = Pick<
  GetMembershipLevelSectionContentProps,
  | 'name'
  | 'membershipDataTitle'
  | 'membershipDataHeader'
  | 'membershipDataBodySweetpassPlusFreeTrial'
  | 'membershipDataCtaSwitchSubscriptionToMonthlyTitle'
  | 'membershipDataCtaSwitchSubscriptionToAnnualTitle'
  | 'membershipDataCtaSwitchSubscriptionA11y'
  | 'savingAmount'
  | 'activeSubscription'
  | 'formatMessage'
  | 'formatDurationUnitName'
  | 'formatDistanceBetweenDatesInDays'
  | 'canSwitchSubscription'
  | 'switchSubscriptionHandler'
  | 'isSubscriptionAnnual'
>;

export type SweetpassMembershipLevelSectionProps = Readonly<{
  name: string;
  title: string;
  planInfo?: string;
  headingText?: string;
  bodyText?: string;
  cta?: SweetpassMembershipLevelCta;
}> &
  SweetpassSectionProps;

import { useCallback } from 'react';
import { type AddressType } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns a utility function for logging a placed order to Braze.
 *
 * @see {@link https://www.braze.com/docs/developer_guide/platform_integration_guides/react_native/analytics/#logging-purchases Logging purchases | React Native}
 * @see {@link https://www.braze.com/docs/developer_guide/platform_integration_guides/web/analytics/logging_purchases/ Logging purchases | Web}
 */
export const useCheckoutBrazeLogPurchase = (props: BrazeLogPurchaseProps) => {
  const { logPurchase } = props;

  return useCallback(
    (params: BrazeLogPurchaseParams) => {
      const {
        restaurantId,
        subtotal,
        total,
        discountTotal,
        lineItemsCount,
        orderChannel,
      } = params;

      const currency = 'usd';
      const quantity = 1;
      const otherDetails = {
        restaurant_id: restaurantId,
        discount: formatAmountInDollars(discountTotal),
        order_net: formatAmountInDollars(subtotal),
        item_count: lineItemsCount,
      };

      logPurchase?.(
        orderChannel,
        formatAmountInDollars(total),
        currency,
        quantity,
        otherDetails,
      );
    },
    [logPurchase],
  );
};

// ─── Utils ───────────────────────────────────────────────────────────────────

function formatAmountInDollars(amount: number) {
  return amount / 100;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type BrazeLogPurchaseProps = {
  logPurchase: (
    productId: string,
    price: number,
    currencyCode?: string,
    quantity?: number,
    purchaseProperties?: Record<string, string | number | boolean | Date>,
  ) => void;
};

type BrazeLogPurchaseParams = Readonly<{
  restaurantId: string;
  subtotal: number;
  total: number;
  discountTotal: number;
  lineItemsCount: number;
  orderChannel: AddressType;
}>;

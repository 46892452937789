import { useCallback, useState } from 'react';
import type { LayoutChangeEvent, ViewStyle } from 'react-native';
import {
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';

export const useProgressBarStyle = (
  progress: number,
  shouldAnimate = false,
) => {
  const [barWidth, setBarWidth] = useState(0);

  const animated = useDerivedValue(() => {
    return withTiming(progress, { duration: shouldAnimate ? 300 : 0 });
  }, [progress, shouldAnimate]);

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      setBarWidth(event.nativeEvent.layout.width);
    },
    [setBarWidth],
  );

  const style = useAnimatedStyle<Pick<ViewStyle, 'transform'>>(() => {
    return {
      transform: [{ translateX: (barWidth * animated.value) / 100 - barWidth }],
    };
  });

  return {
    onLayout,
    style,
  };
};

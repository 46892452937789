import * as React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';

import { MARKER_SIZE } from '../constants';

export const PinWrapper = (props: ViewProps) => {
  return (
    <View focusable={false} style={styles.wrapper}>
      {props.children}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    flex: 1,
    width: MARKER_SIZE,
    height: MARKER_SIZE,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

import { useInterpret } from '@xstate/react';

import { dietaryRestrictionsMachine } from './dietaryRestrictionsMachine';
import { setMachinePersistedState } from './dietaryRestrictionsMachine.utils';
import {
  useDietaryRestrictionsFromAsyncStorage,
  useDietaryRestrictionsFromICloudStorage,
} from './hooks';

// ────────────────────────────────────────────────────────────────────────────────

export const useDietaryRestrictionsInterpret = () => {
  const interpreter = useInterpret(dietaryRestrictionsMachine, {
    actions: { onConfirmRestrictions: setMachinePersistedState },
  });

  useDietaryRestrictionsFromICloudStorage(interpreter);
  useDietaryRestrictionsFromAsyncStorage(interpreter);

  return interpreter;
};

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { LoadingPlaceholder, theme } from '@sg/garnish';

import { GiftCardConfirmationContainer } from '../GiftCardConfirmationContainer';

// ─────────────────────────────────────────────────────────────────────────────

export const GiftCardConfirmationLoadingPlaceholder = () => {
  return (
    <GiftCardConfirmationContainer>
      <View style={styles.container}>
        <LoadingPlaceholder
          style={styles.loadingContainer}
          rows={3}
          rowHeight={[48, 148, 400]}
          gridGap={theme.spacing['6']}
          palette="cream"
        />
      </View>
    </GiftCardConfirmationContainer>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 400,
  },
  loadingContainer: {
    width: '100%',
  },
});

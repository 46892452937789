import React, { type ComponentProps } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { IconLink } from '@sg/garnish';

import { MORE_INFO_MODAL_HEADER_ICON_SIZE } from '../../MenuMoreInfo.constants';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuMoreInfoModalCloseButton = (
  props: MenuMoreInfoModalCloseButtonProps,
) => {
  const { formatMessage } = useIntl();

  return (
    <IconLink
      name="IconClose"
      width={MORE_INFO_MODAL_HEADER_ICON_SIZE}
      height={MORE_INFO_MODAL_HEADER_ICON_SIZE}
      palette="muted"
      accessibilityLabel={formatMessage(messages.a11yLabel)}
      {...props}
    />
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  a11yLabel: {
    defaultMessage: 'Close modal',
    description: 'Menu | More Info | Modal | Close button label',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuMoreInfoModalCloseButtonProps = Omit<
  ComponentProps<typeof IconLink>,
  'name' | 'palette' | 'width' | 'height' | 'accessibilityLabel'
>;

import { useSelector } from '@xstate/react';

import { useGlobalAppState } from '../../GlobalAppState';

/**
 * Returns a `boolean` indicating the state of successful login.
 */
export const useHasLoggedIn = () => {
  const { authMachineRef } = useGlobalAppState();

  return useSelector(authMachineRef, (state) => {
    const { matches, history } = state;

    const wasFetchingCustomerData =
      history?.matches('fetching.customer') === true;
    const hasLoggedOut = matches('session.loggedIn');

    return wasFetchingCustomerData && hasLoggedOut;
  });
};

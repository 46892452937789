import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { BodyText } from '../../Text';

// ────────────────────────────────────────────────────────────────────────────────

export const HorizontalScrollRailFooterText = (
  props: HorizontalScrollRailFooterTextProps,
) => {
  const { children } = props;
  const { match } = useResponsive();

  const footerStyle = match([styles.footerXS, styles.footerSM]);

  return (
    <View style={footerStyle}>
      <BodyText style={styles.footerText} size={4}>
        {children}
      </BodyText>
    </View>
  );
};
// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  footerXS: {
    paddingTop: theme.spacing['2'],
  },
  footerSM: {
    paddingTop: theme.spacing['4'],
  },
  footerText: {
    color: theme.colors.OPACITY.DARK_KALE.DARKER,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type HorizontalScrollRailFooterTextProps = Readonly<{
  children: string;
}>;

import type { StateValue } from 'xstate';

import type {
  ReorderConflict,
  ReorderLineItemMutation,
  ReorderOrderMutation,
  ReorderSuccess,
} from '@order/graphql';

import type { ReorderEvent } from '../machines/Reorder.machine';
import { validReorderEventKeys } from '../machines/Reorder.machine';

export { reorderLogger as logger } from '../Reorder.logger';

// Extracts reorder values from event.
export const getValidEventKeys = (
  event?: Partial<ReorderEvent>,
): Partial<ReorderEvent> => {
  return validReorderEventKeys
    .map((key) => ({ [key]: event?.[key] }))
    .filter(Boolean)
    .reduce((acc, partial) => ({ ...acc, ...partial }), {});
};

export const justStartedReorder = (reorderStep?: StateValue) => {
  return (reorderStep as string) === 'idle';
};

export const cameFromLocationConfirmation = (reorderStep?: StateValue) => {
  return reorderStep === 'locationConfirmation';
};

export const cameFromLocationSearch = (reorderStep?: StateValue) => {
  return reorderStep === 'locationSearch';
};

export const handleConflictTelemetry = ({
  isCannotReorder,
  isMaxDelivery,
  isDressingUnavailable,
  isMissingMenu,
  cannotReorderTelemetry,
  maxDeliveryTelemetry,
  dressingUnavailableTelemetry,
  missingMenuTelemetry,
}: ConflictTelemetry) => {
  if (isCannotReorder) cannotReorderTelemetry();
  else if (isMaxDelivery) maxDeliveryTelemetry();
  else if (isMissingMenu) missingMenuTelemetry();
  else if (isDressingUnavailable) dressingUnavailableTelemetry();
};

// Extracts reorder result and conflict type from either reorder order or reorder line item.
export const parseConflictResults = (data: unknown) => {
  const reorderOrderResult = (data as ReorderOrderMutation)?.reorderOrder;
  const reorderItemResult = (data as ReorderLineItemMutation)?.reorderLineItem;
  const result = reorderOrderResult ?? reorderItemResult;
  const conflicts = (result as ReorderConflict)?.conflicts ?? [];
  const isMixingSupported = (result as ReorderSuccess)?.isMixingSupported;
  const reorderResult = result?.__typename;
  const conflictType = conflicts[0]?.type;

  return {
    conflictType,
    reorderResult,
    isDressingUnavailable: !isMixingSupported,
    isCannotReorder: reorderResult === 'CanNotReorder',
    isMaxDelivery: reorderResult === 'RestaurantMaxDeliveryQuantityExceeded',
    isMissingMenu: conflictType === 'PRODUCT_UNAVAILABLE',
  };
};

type ConflictTelemetry = Readonly<{
  isCannotReorder: boolean;
  isMaxDelivery: boolean;
  isDressingUnavailable: boolean;
  isMissingMenu: boolean;
  cannotReorderTelemetry: () => void;
  maxDeliveryTelemetry: () => void;
  dressingUnavailableTelemetry: () => void;
  missingMenuTelemetry: () => void;
}>;

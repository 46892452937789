import { useMemo } from 'react';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useCustomer } from '@order/Customer';
import { useFeatureFlag } from '@order/LaunchDarkly';

import {
  type CanCancelOrderQueryVariables,
  useCanCancelOrderQuery,
  useCanCancelOrderV2Query,
} from '../../graphql/OrderStatus.generated';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A custom hook that returns a boolean indicating whether the given order
 * can be canceled and a helper to refetch the status.
 * Based on the feature flag value, it uses two different sources of information
 * to determine the cancellation state:
 *
 * - `order.canCancel` field (non-reliable source that uses just the order status)
 * - `canCancel` query (reliable source that uses order real-time state
 *    and other factors to determine whether it can be canceled)
 */
export const useCanCancelOrder = (order: OrderToCancel | undefined) => {
  const { customer } = useCustomer();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isInAppOrderCancellationEnabled = useFeatureFlag(
    'CELS-1476-in-app-order-cancellation',
  );
  const isOrderAsyncCancellationEnabled = useFeatureFlag(
    'CELS-2829-in-app-order-async-cancellation-enabled',
  );
  const isLoggedIn = useIsLoggedIn();

  // ─── Derived Data ────────────────────────────────────────────────────

  const canCancelOrderVariables = useMemo(() => {
    if (!order || !customer.id) return;

    const variables: CanCancelOrderQueryVariables = {
      input: {
        customerId: customer.id,
        orderId: order.id,
        restaurantId: order.restaurant.id,
        wantedTime: order.wantedTime,
        origin: 'customer',
      },
    };

    return variables;
  }, [customer.id, order]);

  // ─── Query ───────────────────────────────────────────────────────────

  const shouldPauseCanCancelQuery =
    !isInAppOrderCancellationEnabled || !isLoggedIn || !canCancelOrderVariables;

  const canCancelOrderQuery = useCanCancelOrderQuery({
    // @ts-expect-error TS(2322): Type 'Exact<{ input: CanCancelInput | readonly Can... Remove this comment to see the full error message
    variables: canCancelOrderVariables,
    pause: shouldPauseCanCancelQuery || isOrderAsyncCancellationEnabled,
    requestPolicy: 'network-only',
  });
  const canCancelOrderV2Query = useCanCancelOrderV2Query({
    // @ts-expect-error TS(2322): Type 'Exact<{ input: CanCancelInput | readonly Can... Remove this comment to see the full error message
    variables: canCancelOrderVariables,
    pause: shouldPauseCanCancelQuery || !isOrderAsyncCancellationEnabled,
    requestPolicy: 'network-only',
  });

  const refetchCanCancelStatus = isOrderAsyncCancellationEnabled
    ? canCancelOrderV2Query?.[1]
    : canCancelOrderQuery?.[1];
  const resolvedData = isOrderAsyncCancellationEnabled
    ? canCancelOrderV2Query?.[0].data?.canCancelV2?.[0]
    : canCancelOrderQuery?.[0].data?.canCancel?.[0];

  // ─────────────────────────────────────────────────────────────────────

  // the new more reliable data source
  if (isInAppOrderCancellationEnabled) {
    return {
      canCancel:
        resolvedData?.__typename === 'CanCancel' && resolvedData.canCancel,
      refetchCanCancelStatus,
    };
  }

  // the deprecated unreliable data source
  return {
    canCancel: Boolean(order?.canCancel),
    refetchCanCancelStatus: () => undefined,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderToCancel = Readonly<{
  id: string;
  canCancel: boolean;
  restaurant: {
    id: string;
  };
  wantedTime: string; // ISO
}>;
